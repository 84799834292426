//@flow strict
import type {
  // $FlowFixMe[nonstrict-import]
  DynamicLabels,
  // $FlowFixMe[nonstrict-import]
  DynamicLabelExtra,
} from 'src/action-creators/dynamic-labels';

import {getFromRelationsForAttribute} from 'src/components/audience-genesis/rules/rule-picker.jsx';


export function dedupBridgedDynamicLabels(
  labels: Iterable<DynamicLabelExtra>,
): Iterator<DynamicLabelExtra> {
  // $FlowIssue iterator helpers
  return Iterator.from(labels)
    .reduce((map: Map<string, DynamicLabelExtra>, label: DynamicLabelExtra) => {
      const prevLabel = map.get(label.resolvedId);
      let nextLabel = label;
      if (
        prevLabel &&
        prevLabel.related_entities.length < label.related_entities.length
      ) {
        nextLabel = prevLabel;
      }
      return map.set(nextLabel.resolvedId, nextLabel);
    }, new Map())
    .values();
}

export const getDisplayLabelForField = (field: DynamicLabelExtra): string => {
  const fieldLabel = field?.entity_type
    ? field.name
    : field?.value != null
    ? field.value
    : '';
  return fieldLabel;
};

//TODO:(diwakersurya) these functions are also present in
// 'src/components/audience-genesis/rules/rule-picker.jsx';
// but Ideally they belong here.
export {getFromRelationsForAttribute};

export const getAttributeNameFromValue = (field: DynamicLabelExtra): string =>
  field.value.includes('/')
    ? field.value /** field value contains fully formed relationship names as well e.g. relation1/relation2/attributename */
        .split('/')
        .pop()
    : field.value;
