// @noflow

import typeof IndexStore from 'src/stores';
import type {Branch} from 'src/types/survey';
import type {SelectOptions} from 'src/components/lib/select/select.jsx';
import type {Agency} from 'src/api-parsers/index';
import type {State} from 'src/reducers';

import * as React from 'react';
import {connect} from 'react-redux';
import {splice} from 'sculpt';
import omit from 'lodash/omit';

import {classify} from 'src/utils/index';
import {
  conditionOptionsForBranch,
  ratingOptionsForBranch,
} from 'src/utils/branch';
import {BranchType} from 'src/stores/branch';
import {updateBranchSettings} from 'src/actions/branch';

import CloseIcon from 'src/images/close-icon.svg';
import CircledPlusSignFilled from 'src/images/circled-plus-sign-filled.svg';
import Select from 'src/components/lib/select/select.jsx';
import TokenListInput from 'src/components/lib/token-list-input';

import css from './branches.css';

export const ratingScaleConditionLabels = {
  responseGte: 'Answer is >=',
  responseLte: 'Answer is <=',
  responseRange: 'Between',
};

export const conditionLabels = {
  ...ratingScaleConditionLabels,
  responseIn: 'Answer is',
};

const _RatingScaleConditions = (props: {
  senseStore: IndexStore,
  agency: Agency,
  className?: string,
  ratingOptions: SelectOptions,
  ratingResponseCondition?:
    | 'responseGte'
    | 'responseLte'
    | 'responseIn'
    | 'responseRange',
  branch: Branch,
  branches: Branch[],
  showErrors: boolean,
}) => {
  const {
    senseStore,
    agency,
    ratingResponseCondition,
    className,
    branch,
    showErrors,
    branches,
  } = props;
  let {ratingOptions} = props;

  const conditionValue = ratingResponseCondition
    ? branch[ratingResponseCondition]
    : '';

  // FIXME(marcos): this is how we're currently gating access to ranged conditions for crawfordgroup
  // see https://github.com/Spaced-Out/sense/issues/5266
  const conditionOptions = ['crawfordgroup'].includes(
    (agency.name || '').toLowerCase(),
  )
    ? conditionOptionsForBranch(branch, branches, ratingScaleConditionLabels)
    : conditionOptionsForBranch(
        branch,
        branches,
        omit(ratingScaleConditionLabels, ['responseRange']),
      );

  ratingOptions =
    branch.branchType === 'module_branch'
      ? ratingOptionsForBranch(branch, branches, ratingOptions)
      : ratingOptions;

  return (
    <div className={css.inputGroup}>
      <Select
        className={className ? className : css.selectMedium}
        placeholder="Condition"
        options={conditionOptions}
        onChange={({value}) =>
          handleRatingConditionChange(
            props,
            conditionValue,
            ratingResponseCondition,
            value,
          )
        }
        value={ratingResponseCondition}
        clearable={false}
        error={showErrors && !ratingResponseCondition}
      />
      {branch.responseRange ? (
        <>
          {/*The weird placeholder is the unicode for long dash. As in design*/}
          <Select
            className={css.selectSmall}
            onChange={({value}) =>
              ratingResponseCondition &&
              updateBranchSettings(senseStore, {['responseGte']: {$set: value}})
            }
            placeholder="&#8212;"
            options={ratingOptions}
            value={branch.responseGte}
            clearable={false}
            error={showErrors && !conditionValue}
          />
          <Select
            className={css.selectSmall}
            onChange={({value}) =>
              ratingResponseCondition &&
              updateBranchSettings(senseStore, {['responseLte']: {$set: value}})
            }
            placeholder="&#8212;"
            options={ratingOptions}
            value={branch.responseLte}
            clearable={false}
            error={showErrors && !conditionValue}
          />
        </>
      ) : (
        /*The weird placeholder is the unicode for long dash. As in design*/
        <Select
          className={css.selectSmall}
          onChange={({value}) =>
            ratingResponseCondition &&
            updateBranchSettings(senseStore, {
              [ratingResponseCondition]: {$set: value},
            })
          }
          placeholder="&#8212;"
          options={ratingOptions}
          value={conditionValue}
          clearable={false}
          error={showErrors && !conditionValue}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({agency: {agency}}: State) => ({
  agency,
});

export const RatingScaleConditions = connect(mapStateToProps)(
  _RatingScaleConditions,
);

function handleRatingConditionChange(
  {senseStore, ratingOptions},
  conditionValue,
  prevCondition,
  newCondition,
) {
  if (!Number.isFinite(conditionValue)) {
    conditionValue = ratingOptions[0].value;
  }

  if (newCondition === 'responseRange') {
    updateBranchSettings(senseStore, {
      $unset: prevCondition,
      responseRange: {$set: true},
      responseGte: {
        $set: conditionValue,
      },
    });
  } else {
    updateBranchSettings(senseStore, {
      $unset: prevCondition,
      [newCondition]: {
        $set: conditionValue,
      },
    });
  }
}

export const MultipleChoiceConditions = (props: {
  store: IndexStore,
  branch: Branch,
  branches: Branch[],
  choices: {value: string, id: string}[],
  showErrors: boolean,
}) => {
  const {
    store,
    branch,
    branches,
    choices,
    showErrors,
    allowSelectMultiple,
  } = props;
  const branchedChoices = branches
    .filter(
      ({id, branchType}) => id !== branch.id && branchType === 'module_branch',
    )
    .reduce(
      (set, branch) =>
        branch.responseIn.reduce((set, response) => set.add(response), set),
      new Set(),
    );

  let fairChoices = choices;
  if (branch.branchType === 'module_branch') {
    fairChoices = choices.filter(choice => !branchedChoices.has(choice.value));
  }

  const options = fairChoices.map(choice => ({
    label: choice.value,
    value: choice.value,
  }));

  return (
    <div className={css.column}>
      {branch.responseIn.map((response, index) => {
        const chosenChoices = new Set(splice(branch.responseIn, [[index, 1]]));
        const possibleOptions = options.filter(
          ({value}) => !chosenChoices.has(value),
        );

        return (
          <div className={css.inputGroup} key={response}>
            <label>User Selects:</label>

            <div
              className={classify(css.selectBig, {
                [css.selectError]: showErrors && !response,
              })}
            >
              {allowSelectMultiple ? (
                <div className={css.tokenDropdownContainer}>
                  <TokenListInput
                    options={possibleOptions}
                    values={possibleOptions.filter(({value}) =>
                      response.includes(value),
                    )}
                    placeholder="Select all that apply"
                    allowArbitraryValues={false}
                    openOnFocus
                    verticalStretch
                    onChange={selected => {
                      handleChoiceValueChange(
                        props,
                        index,
                        selected.map(({value}) => value).join(', '),
                      );
                    }}
                    className={css.tokenDropdown}
                  />
                </div>
              ) : (
                <Select
                  className={css.selectBig}
                  placeholder="-"
                  options={possibleOptions}
                  value={response}
                  index={index}
                  onChange={({value}) =>
                    handleChoiceValueChange(props, index, value)
                  }
                  clearable={false}
                  error={showErrors && !response}
                />
              )}
            </div>
            {index === branch.responseIn.length - 1 && (
              <div
                className={css.addOption}
                onClick={() =>
                  updateBranchSettings(store, {responseIn: {$push: null}})
                }
              >
                <CircledPlusSignFilled
                  className={
                    branch.type === BranchType.ModuleBranch &&
                    css.buttonPlusSign
                  }
                />
              </div>
            )}
            {index > 0 && (
              <div className={css.closeButton}>
                <CloseIcon onClick={() => handleChoiceDelete(props, index)} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

function handleChoiceValueChange({store}, index, value) {
  updateBranchSettings(store, {
    responseIn: {
      [index]: {
        $set: value,
      },
    },
  });
}

function handleChoiceDelete({store}, index) {
  updateBranchSettings(store, {
    responseIn: {
      $splice: [[index, 1]],
    },
  });
}
