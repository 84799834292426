// @flow

import type {Agency} from 'src/api-parsers/index.types';
import type {AgencyConfig} from 'src/types/agency';
import type {Action} from 'src/action-creators/agency';

import {
  RECEIVE,
  RECEIVE_CONFIG,
  SET_MAINTENANCE_MODE,
} from 'src/action-creators/agency';


export type State = {
  agency: Agency,
  config: AgencyConfig,
  ats: {
    kind: string,
  },
  maintenanceMode: boolean,
};

const initialState: State = {
  agency: {
    id: '',
    name: '',
    slug: '',
    activeEventProcessor: false, // same default as server
    brandingSettings: {},
    allowSms: false, // same default as server
    timezone: '',
    twilioNumber: null, // server allows null
    multiEntityEnabled: false,
  },
  config: {
    onboardRequired: false,
    analyticsAtsSettingsEnabled: false,
    analyticsCohortsEnabled: false,
    analyticsInsightCountsEnabled: false,
    analyticsPerformancePageEnabled: false,
    analyticsPerformanceTouchesChartsEnabled: false,
    analyticsV3ApiEnabled: false,
    defaultPhoneCountry: 'US',
    enableDigestEmail: false,
    messagingThinNotifications: false,
    // multiEntityEnabled is deprecated, but kept for compat
    multiEntityEnabled: true,
    roiRuleSettingsEnabled: false,
    messagingDisableMms: false,
    atsFieldWritebackEnabled: false,
    userAccountManagementEnabled: false,
    agentDefaultFiltersEnabled: false,
    maxDailySmsTouchpointSendsAtActivation: 1000,
    integratedMessagingEnabled: false,
    maxContactsCsvUpload: 1000,
    campaignSchedulingEmailMaxSendsPerHour: 100000,
    campaignSchedulingEmailMaxSendsPerDay: 15000,
    campaignSchedulingEnabled: false,
    sendgridDnsV2: false,
    hidePlacementsInMessagingTimeline: false,
    enableQ22020EventPage: false,
    messagingRequireAtsSyncedCandidates: false,
    enableChatbot: false,
    broadcastRecipientLimit: 100,
    tollfreeBroadcastRecipientLimit: 1000,
    worknPushNotificationSupportedWorkflowIds: [],
    audienceBuilderV2Enabled: false,
    enableSchedulerProduct: false,
    chatbotSchedulerIntegration: false,
    chatbotPremiumBranding: false,
    chatbotT2AEnabled: true,
    chatbotSourcingWidgetEnabled: true,
    chatbotSourcingLinkEnabled: true,
    chatbotOutboundEnabled: true,
    smsBlackoutSettings: JSON.stringify({
      send: 'move_send',
      start_time: 'No blackout',
      end_time: 'No blackout',
    }), // same default as server
    emailBlackoutSettings: JSON.stringify({
      send: 'move_send',
      start_time: 'No blackout',
      end_time: 'No blackout',
    }), // same default as server
    // see server.js renderBaseTemplate
    disabledVendorKeys: [],
    maxTouchpointsPerJourney: 50,
    customerBusinessType: '',
    enableJobMatchForEngage: false,
    enableJobMatchForMessaging: false,
    enableJobMatchForOutboundChatbot: false,
    enableJobMatchForInboundChatbot: false,
    atsFieldTriggeredWritebackEnabled: false,
    enableSenseAi: false,
    enableSenseHiringEvents: false,
    showConversationContextEvents: true,
    atsWritebackToManyFieldsHandlingMode: 'REPLACE',
    customDailyFrequencyEnabled: false,
  },
  ats: {
    kind: 'bullhorn',
  },
  //NOTE:(diwakersurya)this is client side maintenance mode state
  maintenanceMode: false,
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case RECEIVE:
      // $FlowIssue: https://github.com/facebook/flow/issues/2405
      return {
        ...state,
        agency: action.payload,
        config: {
          ...state.config,
          // FIXME(marcos): stop shadowing this object. Many apologies, see #13592
          multiEntityEnabled: action.payload.multiEntityEnabled,
        },
      };

    case RECEIVE_CONFIG:
      return {
        ...state,
        config: {
          ...state.config,
          ...action.payload,
        },
      };

    case SET_MAINTENANCE_MODE:
      return {
        ...state,
        maintenanceMode: action.payload,
      };
  }

  return state;
};
