// @flow strict
import type {ReferralSetting, GenericError} from 'src/types/referral-v2';
import type {ReferralSettingsActions} from 'src/action-creators/referral-v2/referral-settings.js';
import {
  REFERRAL_SETTINGS_LOADING,
  REFERRAL_SETTINGS,
  REFERRAL_SETTINGS_ERROR,
  UPDATE_PAYOUT_RULE,
  UPDATE_ONE_TIME_SETTINGS,
} from 'src/action-creators/referral-v2/referral-settings.js';


export const ReferralCurrentState = {
  IDLE: 'idle',
  FETCHING_REFERRAL_SETTINGS: 'fetchingReferralSettings',
};

export type ReferralSettingsState = {
  current_state: string,
  referral_settings: ?ReferralSetting,
  referral_settings_error: ?GenericError,
};

const referralSettingInitialState = {
  current_state: '',
  referral_settings: null,
  referral_settings_error: null,
};

export default (
  state: ReferralSettingsState = referralSettingInitialState,
  action: ReferralSettingsActions,
): ReferralSettingsState => {
  switch (action.type) {
    case REFERRAL_SETTINGS: {
      return {
        ...state,
        referral_settings: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };
    }
    case UPDATE_PAYOUT_RULE:
      // $FlowFixMe[prop-missing]
      const referralSettings: ReferralSetting = {
        ...state.referral_settings,
        // $FlowFixMe[prop-missing]
        one_time_settings: {
          ...state.referral_settings?.one_time_settings,
          payout_rule: action.payload,
        },
      };
      return {
        ...state,
        referral_settings: referralSettings,
      };
    case UPDATE_ONE_TIME_SETTINGS:
      return {
        ...state,
        // $FlowFixMe[prop-missing]
        referral_settings: {
          ...state.referral_settings,
          one_time_settings: action.payload,
        },
      };
    case REFERRAL_SETTINGS_LOADING:
      return {
        ...state,
        current_state: ReferralCurrentState.FETCHING_REFERRAL_SETTINGS,
      };

    case REFERRAL_SETTINGS_ERROR:
      return {
        ...state,
        referral_settings_error: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };
    default:
      return state;
  }
};
