//@flow strict

export const SUB_DOMAIN_SUFFIX = 'sensehq.cloud';

export const SENSE_CANDIDATE_LEAD = 'sense_candidate_lead';

export const CANDIDATE_LEAD = 'candidate_lead';

export const LEAD_OPTIONS = [
  {
    key: 'Open',
    label: 'Open',
  },
  {
    key: 'Open - Working',
    label: 'Open - Working',
  },
  {
    key: 'Unresponsive',
    label: 'Unresponsive',
  },
  {
    key: 'Unqualified',
    label: 'Unqualified',
  },
  {
    key: 'Qualified',
    label: 'Qualified',
  },
  {
    key: 'Applied',
    label: 'Applied',
  },
  {
    key: 'Converted',
    label: 'Converted',
  },
  {
    key: 'Completed',
    label: 'Completed',
  },
];

export const PAGE_SIZE = 10;

export const LANDING_PAGE_STATUS = [
  {
    key: 'published',
    label: 'Published',
  },
  {
    key: 'draft',
    label: 'Draft',
  },
  {
    key: 'archived',
    label: 'Archived',
  },
];
