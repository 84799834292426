// @flow strict
// api error code and its description mapping
export const ERROR_CODES = {
  InvalidLoginAttempt: 'Incorrect email or password',
  ServerError: 'Failed to perform action, Please try after sometime',
  IncorrectOTP: 'Invalid OTP entered',
  InvalidPhoneEntered: 'Invalid number',
  NoValidAccounts: 'Incorrect email or password',
  PasswordIncorrect: 'Current password is incorrect',
  ExistingPasswordReused: "Can't reuse current password",
  AccountInviteNotFound: 'The invite code has either expired or is invalid',
  InvalidEmail: 'The email you entered is not a valid email address.',
  InvalidInviteCode: 'Invalid invite code',
  EmailAlreadyExists:
    'The email you entered already exists, Perhaps you meant to Sign In',
  InvalidPassword: 'You cannot use that password. Please try another password.',
  GenericSignUpError: 'Error signing up. Please try again.',
  InvalidResetId: 'Reset password link is expired',
  UserDoesNotExists: 'User does not exists',
  UserAlreadyExists: 'User already exists',
  InvalidReferrerEmail: 'Invalid Referrer Email',
};
