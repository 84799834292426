// @flow strict

import * as React from 'react';

import {Input} from 'src/designSystem2021Components/input.jsx';
import classify from 'src/utils/classify';
import ColorPickerIcon from 'src/images/icons/color-picker.svg';

import css from './color-picker.css';
import maincss from 'src/components/agency-onboard/agency-onboard.css';


type Props = {
  name: string,
  onChange: (name: string, value: string) => mixed,
  value?: string,
  boxClass?: string,
  colorPreviewClass?: string,
  textBoxClass?: string,
};

export const ColorPicker = ({
  boxClass,
  value,
  name,
  onChange,
  colorPreviewClass,
  textBoxClass,
}: Props): React.Node => {
  const [colorValue, setColorValue] = React.useState('');

  const onTextInputChange = (ev) => {
    const value = ev.currentTarget.value;
    setColorValue(value);
    onChange(name, value);
  };

  React.useEffect(() => {
    setColorValue(value ?? '');
  }, [value]);

  return (
    <div className={classify(css.picker, boxClass)}>
      <label className={css.label}>
        <input type="color" value={colorValue} onChange={onTextInputChange} />
        <div
          className={classify(css.preview, colorPreviewClass)}
          style={{backgroundColor: colorValue}}
        />
      </label>
      <ColorPickerIcon className={css.pickerIcon} />
      <div className={classify(css.text, textBoxClass)}>
        <Input
          type="text"
          value={colorValue}
          onChange={onTextInputChange}
          placeholder="Enter hex value"
        />
      </div>
    </div>
  );
};

export default ColorPicker;
