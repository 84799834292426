// @flow

import type {Router, Route, RouteLocation} from 'src/types/router';
import type {ModalStateEntry} from 'src/types/modal';

import * as React from 'react';
import {connect} from 'react-redux';
import defer from 'lodash/defer';
import flow from 'lodash/flow';
import {classify} from 'src/utils';
import {withHistory} from 'src/flux/withRouter.jsx';
import {TitleBarModal} from 'src/components/modals/base-modal.jsx';
import {
  TitleBarModal as TitleBarModalDS2021,
  ErrorTitleBarModal,
  WarningTitleBarModal,
  InfoTitleBarModal,
} from 'src/components/modals/base-modal-design-system-2021.jsx';

import AudienceCsvUploadModal from 'src/components/modals/audience-csv-upload-modal.jsx';
import AudienceCsvUploadErrorModal from 'src/components/modals/audience-csv-upload-error-modal.jsx';
import {DataExportInit, DataExportSuccess} from './data-export.jsx';
import GenericErrorModal from 'src/components/modals/generic-error-modal.jsx';
import GenericSidepanel from 'src/components/modals/generic-sidepanel.jsx';
import GenericSuccessModal from 'src/components/modals/generic-success-modal.jsx';
import WorkflowDeleteModal from 'src/components/modals/workflow-delete-modal.jsx';
import OrganizerWorkflowDeleteModal from 'src/components/modals/organizer-workflow-delete-modal.jsx';

import JobProgramsModal from 'src/components/referral-v2/programs-jobs/job-programs-modal.jsx';
import EventDeleteModal from 'src/components/modals/event-delete-modal.jsx';
import EventAudienceModal from 'src/components/modals/event-audience-modal.jsx';
import CreateEventModal from 'src/components/modals/create-event-modal.jsx';
import SendTestEmailSmsModal from 'src/components/modals/test-email-sms-modal.jsx';
import JobMatchParamsModal from 'src/components/modals/job-match-params-modal.jsx';
import ContactsCsvModal from 'src/components/contacts/csv-modal.jsx';
import ContactListEditModal from 'src/components/contacts/lists/edit.jsx';
import ScheduledMessagesForContactModal from 'src/components/messaging/scheduled-messages-for-contact.jsx';
import ChooseTimeZoneModal from 'src/components/modals/choose-time-zone-modal.jsx';
import BulkWritebackActivationModal from 'src/components/modals/bulk-writeback-activation-modal.jsx';
import DataReviewSidebar from 'src/components/conversation-builder/data-review-sidepanel.jsx';
import FeedBackContainer from 'src/components/modals/feedback.jsx';
import ReferralWithdrawContainer from 'src/components/modals/referral-withdraw.jsx';
import DefaultNumberForCategory from 'src/components/modals/default-number-for-category-modal.jsx';
import NewJourneyBuilder from 'src/components/modals/new-journey-builder-modal.jsx';
import ReferralBulkActionModal from 'src/components/referral-v2/configure/referral-bulk-action.jsx';
import AccountEdit from 'src/components/settings/accounts/account-edit.jsx';
import ImportEntityModal from 'src/components/modals/import-entity-warning.jsx';
import {TearsheetTypeSelectModal} from 'src/components/settings/ats/tearsheets/tearsheets.jsx';
import {
  VariablePickerModal,
  JobVariablePickerModal,
} from 'src/components/lib/variable-picker';
import BulkUnlinkJobsReferralPrograms from 'src/components/referral-v2/configure/bulk-unlink-jobs.jsx';
import {popModal, removeErrorModal} from 'src/action-creators/modal';
import {selectHead} from 'src/selectors/modal';

import css from './modal-root.css';


export type ModalType = $Keys<typeof MODAL_COMPONENTS>;

const MODAL_COMPONENTS = {
  AUDIENCE_CSV_UPLOAD: AudienceCsvUploadModal, // UNUSED MODAL
  AUDIENCE_CSV_UPLOAD_ERROR: AudienceCsvUploadErrorModal, // UNUSED MODAL
  GENERIC_ERROR: GenericErrorModal,
  GENERIC_SUCCESS: GenericSuccessModal,
  GENERIC_MODAL: TitleBarModal,
  GENERIC_MODAL_DS_2021: TitleBarModalDS2021,
  GENERIC_ERROR_DS_2021: ErrorTitleBarModal,
  GENERIC_WARNING_DS_2021: WarningTitleBarModal,
  GENERIC_INFO_DS_2021: InfoTitleBarModal,
  WORKFLOW_DELETE: WorkflowDeleteModal,
  ORGANIZER_WORKFLOW_DELETE: OrganizerWorkflowDeleteModal,
  EVENT_DELETE: EventDeleteModal,
  EVENT_AUDIENCE: EventAudienceModal,
  CREATE_EVENT: CreateEventModal,
  TEST_EMAIL_SMS: SendTestEmailSmsModal,
  CONTACTS_CSV: ContactsCsvModal,
  CONTACT_LIST_EDITOR: ContactListEditModal,
  SCHEDULED_MESSAGES_FOR_CONTACT: ScheduledMessagesForContactModal,
  CREATE_USER_MODAL: AccountEdit,
  CHOOSE_TIME_ZONE: ChooseTimeZoneModal,
  VARIABLE_PICKER: VariablePickerModal,
  JOB_VARIABLE_PICKER: JobVariablePickerModal,
  IMPORT_ENTITY_WARNING: ImportEntityModal,
  TEARSHEET_TYPE_SELECT: TearsheetTypeSelectModal,
  BULK_WRITEBACK_ACTIVATION_MODAL: BulkWritebackActivationModal,
  DATA_REVIEW_SIDEPANEL: DataReviewSidebar,
  JOB_MATCH_PARAMS_MODAL: JobMatchParamsModal,
  GENERIC_SIDEPANEL: GenericSidepanel,
  FEEDBACK_MODAL: FeedBackContainer,
  SELECT_DEFAULT_NUMBER: DefaultNumberForCategory,
  NEW_JOURNEY_BUILDER: NewJourneyBuilder,
  REFERRAL_WITHDRAW: ReferralWithdrawContainer,
  REFERRAL_BULK_ACTION_MODAL: ReferralBulkActionModal,
  JOB_PROGRAMS_MODAL: JobProgramsModal,
  BULK_UNLINK_JOBS_REFERRAL_PROGRAMS: BulkUnlinkJobsReferralPrograms,
};

const mapStateToProps = (state) => ({
  head: selectHead(state),
  isError: Boolean(state.modal.error),
});

const mapDispatchToProps = (dispatch) => ({
  removeModal: (isError, ...args) => {
    const actionCreator = isError ? removeErrorModal : popModal;
    dispatch(actionCreator(...args));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  removeModal: (...args) =>
    dispatchProps.removeModal(stateProps.isError, ...args),
});

type Props = {
  head: ModalStateEntry,
  isError: boolean,
  router: Router,
  location: RouteLocation,
  removeModal: () => void,
};

class ModalRoot extends React.Component<Props> {
  fixBody() {
    document.getElementsByTagName('body')[0].classList.add('fixed');
  }

  unfixBody() {
    document.getElementsByTagName('body')[0].classList.remove('fixed');
  }

  componentDidUpdate() {
    if (this.props.head) {
      this.fixBody();
    } else {
      this.unfixBody();
    }
  }

  render() {
    const {head} = this.props;

    if (!head) {
      return null;
    }

    let allowClickAway;
    let modal;
    if (head.render) {
      allowClickAway = head.allowClickAway ?? true;
      modal = head.render({removeModal: this.props.removeModal});
    } else {
      const {type, props} = head;
      const SpecificModal =
        typeof type === 'string' ? MODAL_COMPONENTS[type] : type;
      modal = (
        <SpecificModal
          className={css.modal}
          removeModal={this.props.removeModal}
          type={type}
          {...props}
        />
      );
      allowClickAway = props?.allowClickAway ?? true;
    }

    return (
      <div
        className={classify(css.bg, {
          [css.topMost]:
            head.props &&
            head.props.topmost &&
            head.props.modalVersion !== 'genesis',
        })}
        // $FlowFixMe[method-unbinding]
        onClick={allowClickAway ? this.handleBgClick.bind(this) : null}
      >
        <div className={css.container} role="dialog">
          {modal}
        </div>
      </div>
    );
  }

  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyUp);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyUp);
  }

  handleKeyUp = (evt: SyntheticKeyboardEvent<*>) => {
    // Pop modals on escape keypress _only_ when modal is active
    if (this.props.head && evt.key === 'Escape') {
      this.removeModal();
    }
  };
  handleBgClick(event) {
    // Must click bg directly. Ignore clicks on children
    if (event.currentTarget !== event.target) {
      return;
    }
    this.removeModal();
  }
  removeModal() {
    const {head, router} = this.props;
    if (!head) {
      return;
    }
    this.props.removeModal();
    if (router && head.props && head.props.routed) {
      // defer for more responsive modal popping
      defer(() => {
        if (head.props?.back) {
          router.goBack();
        } else {
          // use router.replace instead of router.push because navigating
          // on background click does not seem like an established UX pattern
          router.replace({
            ...router.location,
            // Go up one level in the URL hierarchy.
            // NOTE (gab): we can easily replace `routed` prop with next location
            // if we want to let each modal decide where to navigate on pop
            pathname: router.location.pathname.replace(/\/[^/]+\/?$/, ''),
          });
        }
      });
    }
  }
}

// TODO: (chris) We can get rid of the pure:false option when none of the
// child modal components are getting their data from Flux stores
export default (flow(
  connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    pure: false,
  }),
  withHistory,
)(ModalRoot): React.ComponentType<{}>);
