// @noflow

import Papa from 'papaparse';
import get from 'lodash/get';

import type {CsvResults} from 'src/types/contacts';
import type {RecordType} from 'src/action-creators/csv-import';


export const parseCsv = (
  file: File,
  {progress, ...config},
): Promise<CsvResults> =>
  // TODO (kyle): it occurs to me that the progress aspect of this lends itself more to an Observable than a Promise
  new Promise((resolve, reject) => {
    const {size} = file;
    let results;

    Papa.parse(file, {
      ...config,
      chunk: (resultChunk: CsvResults) => {
        if (!results) {
          results = resultChunk;
        } else {
          results.data.push(resultChunk.data);
        }
        progress(resultChunk.meta.cursor / size);
      },
      complete: () => {
        resolve(results);
      },
      error: error => reject(error),
    });
  });

export const getColumnData = (
  columnName: string,
  {data}: Object,
  limit: number,
) => ({
  columnName,
  values: (limit ? data.slice(0, limit) : data).map(row => row[columnName]),
});

export const getAtsIdFieldName = (
  recordType: RecordType,
  atsSystem: string = '',
) => get(ATS_ID_FIELDS, `${atsSystem.toLowerCase()}.${recordType}`);

export const validateDateFormat = (format: string, valueToTest: string) => {
  const regex = DATE_FORMAT_VALIDATORS[format];
  if (!regex || !valueToTest) {
    return true;
  }
  return regex.test(valueToTest);
};

/* ID field names for different ATS systems */
export const ATS_ID_FIELDS = {
  bullhorn: {
    member: 'Bullhorn Candidate ID',
    placement: 'Bullhorn Placement ID',
  },
  jobdiva: {member: 'Jobdiva Candidate ID', placement: 'Jobdiva Match ID'},
  erecruit: {member: 'eRecruit Candidate ID', placement: 'eRecruit Match ID'},
};

export const DATE_FORMAT_OPTIONS = [
  {value: 'YYYY-MM-DD', label: 'YYYY-MM-DD'},
  {value: 'MM-DD-YYYY', label: 'MM-DD-YYYY'},
  {value: 'MM/DD/YYYY', label: 'MM/DD/YYYY'},
  {value: 'DD-MM-YYYY', label: 'DD-MM-YYYY'},
  {value: 'DD/MM/YYYY', label: 'DD/MM/YYYY'},
  {value: 'MM-DD-YY', label: 'MM-DD-YY'},
  {value: 'MM/DD/YY', label: 'MM/DD/YY'},
  {value: 'DD-MM-YY', label: 'DD-MM-YY'},
  {value: 'DD/MM/YY', label: 'DD/MM/YY'},
  {value: 'MMM-DD-YYYY', label: 'MMM-DD-YYYY'},
];

const DATE_PATTERN = '(?:[1-2][0-9]|3[01]|0?[1-9])';
const MONTH_PATTERN = '(?:1[0-2]|0?[1-9])';
const FULL_YEAR_PATTERN = '\\d{4}';
const YEAR_PATTERN = '\\d{2}';

export const DATE_FORMAT_VALIDATORS = {
  'YYYY-MM-DD': new RegExp(
    `^${FULL_YEAR_PATTERN}-${MONTH_PATTERN}-${DATE_PATTERN}$`,
  ),
  'MM-DD-YYYY': new RegExp(
    `^${MONTH_PATTERN}-${DATE_PATTERN}-${FULL_YEAR_PATTERN}$`,
  ),
  'MM/DD/YYYY': new RegExp(
    `^${MONTH_PATTERN}/${DATE_PATTERN}/${FULL_YEAR_PATTERN}$`,
  ),
  'DD-MM-YYYY': new RegExp(
    `^${DATE_PATTERN}-${MONTH_PATTERN}-${FULL_YEAR_PATTERN}$`,
  ),
  'DD/MM/YYYY': new RegExp(
    `^${DATE_PATTERN}/${MONTH_PATTERN}/${FULL_YEAR_PATTERN}$`,
  ),
  'MM-DD-YY': new RegExp(`^${MONTH_PATTERN}-${DATE_PATTERN}-${YEAR_PATTERN}$`),
  'MM/DD/YY': new RegExp(`^${MONTH_PATTERN}/${DATE_PATTERN}/${YEAR_PATTERN}$`),
  'DD-MM-YY': new RegExp(`^${DATE_PATTERN}-${MONTH_PATTERN}-${YEAR_PATTERN}$`),
  'DD/MM/YY': new RegExp(`^${DATE_PATTERN}/${MONTH_PATTERN}/${YEAR_PATTERN}$`),
  'MMM-DD-YYYY': new RegExp(
    `^[a-z]{3}-${DATE_PATTERN}-${FULL_YEAR_PATTERN}$`,
    'i',
  ),
};
