// @flow

import * as React from 'react';

import {classify} from 'src/utils';
import ClickAway from 'src/components/lib/click-away';
import {FilterButton} from 'src/components/lib/analytics-filter-button';
import {FilterCard} from 'src/components/lib/analytics-filter-card';

import css from './index.css';


export function FilterDropdown({
  text,
  header,
  solid,
  children,
  direction = 'right',
}: {
  text: string,
  header: string,
  solid?: mixed,
  children: React.Node,
  direction?: 'left' | 'right',
}): React.Node {
  return (
    <ClickAway>
      {({isOpen, onOpen, cancelNext}) => (
        <div className={css.filter}>
          <FilterButton className={css.button} active={solid} onClick={onOpen}>
            {text}
          </FilterButton>
          {isOpen && (
            <div
              className={classify(css.filterCard, {
                [css.left]: direction === 'left',
              })}
              onClickCapture={cancelNext}
            >
              <FilterCard header={header}>
                <div className={css.filterContent}>{children}</div>
              </FilterCard>
            </div>
          )}
        </div>
      )}
    </ClickAway>
  );
}

export function FilterField({
  label,
  children,
}: {
  label: string,
  children: React.Node,
}): React.Node {
  return (
    <div className={css.field}>
      <div className={css.label}>{label}</div>
      {children}
    </div>
  );
}
