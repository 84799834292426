// @flow strict-local

import {type RequestOptions} from './redux-api';

import {captureSentryException} from 'src/utils/sentry';
import logger from './logger';


export class ApiError extends Error {
  options: RequestOptions;
  response: Response;
  responseBody: mixed;
  error: ?Error;
  fetchError: ?Error;

  constructor(
    options: RequestOptions,
    response: Response,
    responseBody?: mixed,
    error?: Error,
  ) {
    const {status} = response;
    const apiPath = response.headers.get('x-requested-route');

    let message = `ApiError - ${status}`;
    if (apiPath) {
      message += ` ${apiPath}`;
    }
    super(message);

    this.options = options;
    this.response = response;
    this.responseBody = responseBody;
    // TODO (kyle): change this to `fetchError`
    this.error = this.fetchError = error;
  }
}

export function captureApiError(
  error: ApiError,
  context?: {...},
  extras?: {...},
) {
  captureSentryException(error.error || error, {
    level: 'error',
    tags: {
      ...context,
      context: 'JS API',
      path: error.options.path,
      // this is set in the flask abort middleware and will return the generic route
      // for any request that is handled by an SoException
      apiPath: error.response.headers.get('x-requested-route'),
    },
    extra: {
      ...extras,
      response: error.responseBody,
    },
  });
}

/**
 * This function can be used to intelligently suppress errors
 * depending on who is at fault.
 */
export function handleGenericApiError(
  e: Error,
  ignoreStatus?: Array<number> = [],
) {
  // $FlowFixMe[class-object-subtyping]
  const error = wranglePossibleApiError(e);

  if (error instanceof ApiError) {
    const {status} = error.response;
    if (
      status < 500 &&
      status >= 300 &&
      status !== 401 &&
      !ignoreStatus.includes(status)
    ) {
      captureApiError(error);
    } else {
      logger.error(`ApiError (${status})`, error);
    }
  } else {
    captureSentryException(error, {
      level: 'error',
    });
  }
}

export function wranglePossibleApiError(error: ?{...}): mixed {
  let result = error;
  // NOTE (kyle): utils/api.js returns a weird legacy error object.
  // $FlowFixMe[prop-missing]
  if (error?.apiError) {
    result = error.apiError;
    // $FlowFixMe[prop-missing]
  } else if (error?.error) {
    result = error.error;
  }
  return result;
}

// TODO (kyle): change this to NotFoundError and possibly inherit from
// ApiError.
export class NotFoundError extends Error {
  reason: string;
  status: number;

  constructor(reason: string) {
    super('NotFound: ' + reason);
    this.reason = reason;
    this.status = 404;
  }
}
// NOTE: (diwakersurya) this handles partial error for the api calls
// from bff
export class PartialError extends ApiError {}
