// @flow strict

import type {NoteWritebackActions} from 'src/types/note-writeback-metadata';

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';
import logger from 'src/utils/logger';


type ReceiveNoteWritebackMetadata = {
  type: 'note-writeback-metadata/receive',
  payload: NoteWritebackActions,
};

export type NoteWritebackMetadataAction = ReceiveNoteWritebackMetadata;

export const NoteWritebackMetadataReceive = 'note-writeback-metadata/receive';

const receiveNoteWritebackMetadataAction = (
  metadata: NoteWritebackActions,
): ReceiveNoteWritebackMetadata => ({
  type: NoteWritebackMetadataReceive,
  payload: metadata,
});

export const getNoteWritebackMetadata =
  (
    entityType: string,
    relatedEntityTypes: string[],
    agencyId: string,
  ): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    try {
      const allAssociatedEntities = new Set([
        entityType,
        ...relatedEntityTypes,
      ]);
      // todo (nilarnab): remove me
      // supporting only staffmark right now
      // this api works only if entity is candidate or related entity has candidate
      if (allAssociatedEntities.has('er_candidate')) {
        const meta: NoteWritebackActions = await dispatch(
          reduxApi.get(
            'awb/writeback-metadata',
            {entity_type: 'er_candidate'},
            {
              headers: {
                'X-AgencyId': agencyId,
              },
            },
          ),
        );
        dispatch(receiveNoteWritebackMetadataAction(meta));
      }
    } catch (e) {
      logger.error(
        `Could not fetch note writeback metadata for entity ${entityType}`,
      );
    }
  };
