// @flow strict

export type AppConfigAction = SetFullscreenAction | ClearFullscreenAction;

type SetFullscreenAction = {
  type: 'app-config/set-fullscreen',
};

type ClearFullscreenAction = {
  type: 'app-config/clear-fullscreen',
};

export const SET_FULLSCREEN: 'app-config/set-fullscreen' =
  'app-config/set-fullscreen';
export const CLEAR_FULLSCREEN: 'app-config/clear-fullscreen' =
  'app-config/clear-fullscreen';

export const setFullscreen = (): SetFullscreenAction => ({
  type: SET_FULLSCREEN,
});

export const clearFullscreen = (): ClearFullscreenAction => ({
  type: CLEAR_FULLSCREEN,
});
