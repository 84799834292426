var React = require('react');

function ArrowLeft (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M5.097 1L1 5.113l4.097 3.811","strokeWidth":"2","fillRule":"evenodd","strokeLinecap":"round","strokeLinejoin":"round"}));
}

ArrowLeft.defaultProps = {"width":"7","height":"10","viewBox":"0 0 7 10"};

module.exports = ArrowLeft;

ArrowLeft.default = ArrowLeft;
