// @flow strict

import type {SmsAction} from 'src/action-creators/sms';
import type {SmsState} from 'src/types/sms';

import {
  RECEIVE_SMS_INFO,
  UPDATE_SMS_INFO,
  RESET,
} from 'src/action-creators/sms';


const initialState = {
  id: null,
  task_group_id: null,
  to_number: null,
  from_number: null,
  external_source_id: null,
  category_id: null,
  body: ``,
  sender_category: null,
};

export default (
  state: SmsState = initialState,
  action: SmsAction,
): SmsState => {
  switch (action.type) {
    case UPDATE_SMS_INFO:
      const updatePayload = action.payload;
      return {
        ...state,
        ...updatePayload,
      };
    case RECEIVE_SMS_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case RESET:
      return {...initialState};
  }
  return state;
};
