// @flow strict
import * as React from 'react';
import {useHistory} from 'src/rerouter';
import {useEnvironmentContext} from 'src/hooks/useEnvironmentContext';

import {CONSTANTS} from 'src/components/authentication/contants.js';

import ForgotPassword from 'src/components/authentication/forgot/forgot-password/forgot-password.jsx';
import ForgotPasswordConfirmation from 'src/components/authentication/forgot/forgot-confirmation/forgot-confirmation.jsx';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import type {ForgotPasswordPageProps} from 'src/types/authentication.js';
import Logo from 'src/images/sense-logo.svg';
import {classify} from 'src/utils/classify';
import css from './forgot-container.css';
import maincss from 'src/components/authentication/main.css';


const ForgotPasswordContainer = (
  props: ForgotPasswordPageProps,
): React.Node => {
  const {dynamicAgency} = props;
  const router = useHistory();
  const [curPage, setCurPage] = React.useState<string>('');

  const {isExtension} = useEnvironmentContext();

  const showConfirmation = () => {
    setCurPage('RESET_CONFIRMATION');
  };

  const handleBackBtnClick = () => {
    router.replace('/signin');
  };

  const getContent = () => {
    switch (curPage) {
      case 'RESET_CONFIRMATION':
        return <ForgotPasswordConfirmation />;
      default:
        return (
          <ForgotPassword
            dynamicAgency={dynamicAgency}
            showConfirmation={showConfirmation}
          />
        );
    }
  };

  return (
    <div className={maincss.contentSideBar}>
      {curPage === '' && (
        <>
          {isExtension ? (
            <div className={maincss.senseLogoWithBtn}>
              <Button
                ariaLabel="back"
                iconRightName="angle-left"
                onClick={handleBackBtnClick}
                size="medium"
                type="tertiary"
              />
              <Logo />
            </div>
          ) : (
            <Button
              iconLeftName="angle-left"
              onClick={handleBackBtnClick}
              size="medium"
              type="tertiary"
            >
              {CONSTANTS.BACK_BUTTON_TEXT}
            </Button>
          )}
        </>
      )}

      <div
        className={classify(
          curPage === 'RESET_CONFIRMATION'
            ? maincss.containerContentCentreAligned
            : maincss.containerContent,
          {
            [maincss.containerContentExt]: isExtension,
          },
        )}
      >
        <div className={css.resetSection}>{getContent()}</div>
      </div>
    </div>
  );
};

export default ForgotPasswordContainer;
