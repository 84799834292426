// @noflow

// TODO (kyle): this file is being slowly refactored into `date-time-2.js`,
// which is type safe and does not use `moment` wherever possible. Eventually
// we should remove `moment` entirely from our dependenvies.

import type {Range} from 'src/utils/date-range';

import moment, {isMoment} from 'moment';
import momentTZ from 'moment-timezone';
import pick from 'lodash/pick';

import {
  displayDateTime,
  displayDate,
  displayTime,
  formatIsoDate,
  compareTimestamps,
  DEFAULT_TIMEZONE,
} from './date-time-2';
import logger from 'src/utils/logger';


export const formatDateTime = displayDateTime;
export const formatDate = displayDate;
export const formatTime = displayTime;
export {formatIsoDate, compareTimestamps};

export type MonthIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 11;

export {DEFAULT_TIMEZONE};

type MomentInput = number | Date | moment$Moment | string;

const dates = {
  firstVisibleDay(date) {
    const firstOfMonth = moment(date).startOf('month');
    return moment(firstOfMonth).startOf('week');
  },

  lastVisibleDay(date) {
    const endOfMonth = moment(date).endOf('month');
    return moment(endOfMonth).endOf('week');
  },

  visibleDays(date) {
    let current = dates.firstVisibleDay(date);
    const last = dates.lastVisibleDay(date);
    const days = [];

    while (moment(current).isSameOrBefore(last, 'day')) {
      days.push(current);
      current = moment(current).add(1, 'day');
    }

    return days;
  },

  sameYear(dateA, dateB = new Date()) {
    return moment(dateA).isSame(dateB, 'year');
  },

  sameMonth(dateA, dateB) {
    return moment(dateA).isSame(dateB, 'month');
  },

  sameDay(dateA, dateB) {
    return moment(dateA).isSame(dateB, 'day');
  },

  today() {
    return moment().startOf('day');
  },

  yesterday() {
    return moment(this.today()).add(-1, 'day');
  },

  isElapsed(date) {
    return moment(date).isBefore(this.today(), 'day');
  },

  tomorrow() {
    return moment(this.today()).add(1, 'day');
  },

  dayCountBetween(dateA, dateB) {
    return Math.ceil(moment(dateB).diff(moment(dateA), 'days', true));
  },

  durationBetween(dateA, dateB) {
    return moment.duration(moment(dateB).diff(moment(dateA)));
  },

  yearBegin() {
    return moment().startOf('year');
  },

  yearEnd() {
    return moment().endOf('year');
  },
};

export default dates;

export function getLocationDateRange(
  location: Object,
  format?: string,
  pickProps?: string[],
): {start_date: ?string, end_date: ?string} {
  let query = {};

  if (!(location.query.start_date || location.query.end_date)) {
    const currentMonth = dates.visibleDays(moment());
    query = {
      start_date: currentMonth[0].format(format),
      end_date: currentMonth.slice(-1)[0].format(format),
    };
  } else {
    query = location.query;
  }

  return pick(query, pickProps);
}

export function getRangeAsUrlQuery(range: Range): string {
  //dates can either be string or moment.
  const startDate = isMoment(range.startDate)
    ? range.startDate.format()
    : range.startDate;
  const endDate = isMoment(range.endDate)
    ? range.endDate.format()
    : range.endDate;
  return `start_date=${startDate}&end_date=${endDate}`;
}

export function getValidRange(range: Range): Range {
  const startDate = moment(range.startDate);
  const endDate = moment(range.endDate);
  if (endDate < startDate) {
    return {
      startDate: range.startDate,
      endDate: range.startDate,
    };
  } else {
    return range;
  }
}

export function getMomentDate(date: string): moment$Moment {
  // Specifically date is a 'YYYY-MM-DD' formatted date string
  const mDate = date
    ? momentTZ.tz(date, 'YYYY-MM-DD', DEFAULT_TIMEZONE)
    : momentTZ.tz(new Date(), 'YYYY-MM-DD', DEFAULT_TIMEZONE);
  return mDate;
}

export const getDateOffsetBy = (
  date: string,
  startDate: string,
  timeUnit: string,
): number => moment(date).diff(moment(startDate), timeUnit);

export const getMonthOffset = (month: string, startMonth: string) =>
  getDateOffsetBy(month, startMonth, 'months');

export function getTimestamp(date: ?Date) {
  date = date || new Date();
  return date.toISOString();
}

export const supportedTimezones = [
  'America/St_Johns',
  'America/Halifax',
  'America/Puerto_Rico',
  'America/New_York',
  'America/Chicago',
  'America/Phoenix',
  'America/Denver',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Adak',
  'Pacific/Honolulu',
  'Europe/London',
  'Europe/Paris',
  'Asia/Kolkata',
  'Asia/Hong_Kong',
  'Asia/Tokyo',
];

// _names.map(n => n.standard) and _names.map(n => n.daylight) should not have
// any repeats, because they will be shown as choices to the user.
const _names = {
  'America/St_Johns': {
    standard: 'Newfoundland Standard Time',
    daylight: 'Newfoundland Daylight Time',
  },
  'America/Halifax': {
    standard: 'Atlantic Standard Time: Canada',
    daylight: 'Atlantic Daylight Time: Canada',
  },
  'America/Puerto_Rico': {
    standard: 'Atlantic Standard Time: Puerto Rico',
    daylight: 'Atlantic Standard Time: Puerto Rico',
  },
  'America/New_York': {
    standard: 'Eastern Standard Time',
    daylight: 'Eastern Daylight Time',
  },
  'America/Los_Angeles': {
    standard: 'Pacific Standard Time',
    daylight: 'Pacific Daylight Time',
  },
  'America/Chicago': {
    standard: 'Central Standard Time',
    daylight: 'Central Daylight Time',
  },
  'America/Denver': {
    standard: 'Mountain Standard Time',
    daylight: 'Mountain Daylight Time',
  },
  'America/Anchorage': {
    standard: 'Alaska Standard Time',
    daylight: 'Alaska Daylight Time',
  },
  // The Aleutian Islands observe DST but Hawaii does not.
  'America/Adak': {
    standard: 'Hawaii-Aleutian Standard Time: Aleutian Islands',
    daylight: 'Hawaii-Aleutian Daylight Time: Aleutian Islands',
  },
  'Pacific/Honolulu': {
    standard: 'Hawaii-Aleutian Standard Time: Hawaii',
    daylight: 'Hawaii-Aleutian Standard Time: Hawaii',
  },
  'America/Phoenix': {
    standard: 'Mountain Standard Time: Arizona',
    daylight: 'Mountain Standard Time: Arizona',
  },
  'Europe/London': {
    standard: 'Greenwich Mean Time',
    daylight: 'British Summer Time',
  },
  'Europe/Paris': {
    standard: 'Central European Time',
    daylight: 'Central European Time',
  },
  'Asia/Kolkata': {
    standard: 'Indian Standard Time',
    daylight: 'Indian Standard Time',
  },
  // Hong Kong, China, Malaysia, and Singapore all independently set their time to +08:00 UTC, so just using one for
  // UI simplicity.
  'Asia/Hong_Kong': {
    standard: 'China / Hong Kong / Malaysia / Singapore Time',
    daylight: 'China / Hong Kong / Malaysia / Singapore Time',
  },
  // Same with Korea and Japan.
  'Asia/Tokyo': {
    standard: 'Japan / Korea Time',
    daylight: 'Japan / Korea Time',
  },
};

export function getTimezoneDisplayNameAndOffset(timezone: string): {
  displayName: string,
  offset: string,
} {
  const dateObject = moment.tz(timezone);

  const namesForZone = _names[timezone];
  const isDST = dateObject.isDST();
  const displayName =
    namesForZone === undefined
      ? timezone
      : isDST
      ? namesForZone.daylight
      : namesForZone.standard;

  return {displayName, offset: dateObject.format('Z')};
}

/**
 * isIsoDate detects a %YYYY-%MM-%DD looking date string
 * @param  {string} value - a string representing a %YYYY-%MM-%DD date
 * @return {boolean} true if the value is a valid iso date, false otherwise
 */
export function isIsoDate(value: string): boolean {
  let [year, month, day] = value.trim().split('-');
  const rightLength =
    year?.length === 4 && month?.length === 2 && day?.length === 2;
  if (!rightLength) {
    return false;
  }

  [year, month, day] = [year, month, day].map(Number);

  const generatedDate = new Date(year, month - 1, day);
  return (
    generatedDate.getDate() === day &&
    generatedDate.getMonth() === month - 1 &&
    generatedDate.getFullYear() === year
  );
}

export function displayRelativeDate(
  value: MomentInput,
  relativeTo: MomentInput = moment(),
): string {
  const date = moment(value);

  const delta = dates.durationBetween(date, relativeTo);

  if (Math.abs(delta.asMinutes()) < 10) {
    return 'Now';
  } else if (dates.sameDay(date, relativeTo)) {
    return date.format('h:mma');
  } else if (Math.abs(delta.asDays()) < 8) {
    return date.format('ddd');
  } else {
    return date.format('M/D/YY');
  }
}

/**
 * Translates a subset of the python date format to a js/moment friendly
 * date format.
 *
 * Note that this will not handle all python date formats and is only
 * intended for use at Sense.
 *
 * Links:
 * https://docs.python.org/3/library/datetime.html#strftime-strptime-behavior
 * https://momentjs.com/docs/#/displaying/
 */
export function translatePythonDateFormat(dateFormat: string): string {
  return dateFormat.replace(/%([a-zA-Z])/g, (match, char) => {
    switch (char) {
      // year
      case 'y':
        return 'YY';
      case 'Y':
        return 'YYYY';
      // month
      case 'b':
        return 'MMM';
      case 'B':
        return 'MMMM';
      case 'm':
        return 'MM';
      // day
      case 'd':
        return 'DD';
      default:
        logger.error('Error parsing date format', dateFormat);
        return '';
    }
  });
}
