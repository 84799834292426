// @flow strict

import * as React from 'react';
import {Button} from 'src/designSystem2021Components/buttons.jsx';
import DefaultPrevIcon from 'src/images/designSystems2021/prev.svg';
import DefaultNextIcon from 'src/images/designSystems2021/next.svg';

import css from './carousel.css';


type Icon = typeof DefaultPrevIcon;

function calculateLeft({
  effectivePageNo,
  viewportWidth,
  width,
  lag,
  totalPages,
}: {
  effectivePageNo: number,
  viewportWidth: number,
  width: number,
  lag: number,
  totalPages: number,
}) {
  if (effectivePageNo === 0) {
    return 0;
  }
  if (effectivePageNo === totalPages - 1) {
    return viewportWidth - width;
  }
  return effectivePageNo * (width - lag);
}
export type CarouselProps = {
  children: ?React.Node,
  PrevIcon?: Icon,
  NextIcon?: Icon,
  currentPage?: number,
  onChange?: (page: number) => mixed,
  lag?: number,
};
/** for best result the combined width+margins of all the items shown on one page
 * should be equal to the width of the container in which this carousel is rendered
 * e.g. if there are 3 items on page, each 200px wide and there is a margin of 10px
 * for each item, then the container width should be 3*200 + 3*10 = 630px;
 */
export const Carousel = ({
  children,
  PrevIcon = DefaultPrevIcon,
  NextIcon = DefaultNextIcon,
  currentPage: controlledPageNo,
  onChange,
  lag = 40,
}: CarouselProps): React.Node => {
  const [width, setWidth] = React.useState(0);
  const [viewportWidth, setViewportWidth] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [pageNo, setPageNo] = React.useState(0);
  const containerRef = React.useRef(null);
  const viewportRef = React.useRef(null);

  const effectivePageNo = controlledPageNo != null ? controlledPageNo : pageNo;

  React.useLayoutEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.offsetWidth;
      setWidth(width);
      if (viewportRef.current) {
        const viewPortWidth = viewportRef.current.offsetWidth;
        setTotalPages(Math.ceil(viewPortWidth / width));
        setViewportWidth(viewPortWidth);
      }
    }
  });

  const moveNext = () => {
    if (effectivePageNo < totalPages - 1) {
      const newPageNo = pageNo + 1;
      setPageNo(newPageNo);
      onChange && onChange(newPageNo);
    }
  };

  const movePrev = () => {
    if (effectivePageNo > 0) {
      const newPageNo = pageNo - 1;
      setPageNo(newPageNo);
      onChange && onChange(newPageNo);
    }
  };

  const left = calculateLeft({
    effectivePageNo,
    width,
    viewportWidth,
    lag,
    totalPages,
  });

  const prevIconDisabled = effectivePageNo === 0;
  const nextIconDisabled = effectivePageNo === totalPages - 1;
  const isOverflowing = viewportWidth > width;

  return (
    <div className={css.wrapper}>
      {isOverflowing && (
        <Button
          type="tertiary"
          icon={<PrevIcon />}
          disabled={prevIconDisabled}
          onClick={movePrev}
        ></Button>
      )}
      <div ref={containerRef} className={css.container}>
        <div
          ref={viewportRef}
          className={css.viewport}
          style={{left: left !== 0 ? `-${left}px` : 0}}
        >
          {React.Children.map(children, (child) => (
            <div className={css.itemWrapper}>{child}</div>
          ))}
        </div>
      </div>
      {isOverflowing && (
        <Button
          type="tertiary"
          icon={<NextIcon />}
          disabled={nextIconDisabled}
          onClick={moveNext}
        ></Button>
      )}
    </div>
  );
};
