// @flow strict

// $FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';
import type {PhoneNumberSet} from 'src/types/chat/phone-number-sets';
import type {QueueBasedInbox} from 'src/types/chat/queues';
import {isQueueInbox, isPhoneInbox} from 'src/selectors/multi-number-inbox';
// $FlowFixMe[nonstrict-import]
import {selectLATEnabled} from 'src/hooks/product-flags';

import {createSelector} from 'reselect';

import {
  // $FlowFixMe[nonstrict-import]
  selectDefaultPhoneNumberSet,
  // $FlowFixMe[nonstrict-import]
  selectPrimaryPhoneNumberSet,
  // $FlowFixMe[nonstrict-import]
  selectFirstPhoneNumberSet,
} from 'src/selectors/phone-number-sets';
import {
  selectPrimaryQueue,
  selectFirstQueue,
} from 'src/selectors/messaging-queues';


export const selectCurrentlySelectedInboxId = (state: State): string => {
  return state.chat.multiNumberInbox.selectedPhoneNumberSetId;
};

export const selectCurrentInbox = (
  state: State,
): PhoneNumberSet | QueueBasedInbox => {
  const selectedInboxId = selectCurrentlySelectedInboxId(state);

  const isQueueInboxType = isQueueInbox(state);
  const isPhoneInboxType = isPhoneInbox(state);
  if (isQueueInboxType) {
    return (
      state.chat.queues.queue_based_inboxes[selectedInboxId] ||
      selectPrimaryQueue(state) ||
      selectFirstQueue(state)
    );
  } else if (isPhoneInboxType) {
    return (
      state.chat.phoneNumberSets[selectedInboxId] ||
      selectPrimaryPhoneNumberSet(state) ||
      selectFirstPhoneNumberSet(state)
    );
  } else {
    return (
      state.chat.phoneNumberSets[selectedInboxId] ||
      state.chat.queues.queue_based_inboxes[selectedInboxId] ||
      selectPrimaryPhoneNumberSet(state) ||
      selectPrimaryQueue(state) ||
      selectFirstPhoneNumberSet(state) ||
      selectFirstQueue(state)
    );
  }
};

export const selectCurrentInboxId: (State) => string = createSelector(
  selectCurrentInbox,
  selectLATEnabled,
  isQueueInbox,
  (inbox, isLATEnabled, isQueueInboxType) => {
    if (isLATEnabled) {
      if (isQueueInboxType) {
        return inbox && inbox.id;
      } else {
        // if it's not queue inbox and inbox is present - it will always be phone inbox and it will always have selected channel
        // $FlowFixMe[prop-missing]
        // $FlowFixMe[invalid-computed-prop]
        return inbox ? inbox[inbox.selected_channel]?.inbox_id : '';
      }
    } else {
      // this block is not required if LAT is enabled just to ensure nothing breaks for now, this block is added
      // $FlowFixMe[prop-missing]
      // $FlowFixMe[invalid-computed-prop]
      return inbox[inbox.selected_channel]?.id;
    }
  },
);

export const selectCurrentInboxAvailableChannels: (State) => string[] =
  createSelector(
    selectCurrentInbox,
    isQueueInbox,
    (inbox, isQueueInboxType) => {
      const channels = [];
      if (isQueueInboxType) {
        return channels;
      } else {
        if (inbox && inbox.sms) {
          channels.push('sms');
        }
        if (inbox && inbox.whatsapp) {
          channels.push('whatsapp');
        }
        return channels;
      }
    },
  );

export const selectCurrentInboxPhoneNumber: (State) => string = createSelector(
  selectCurrentInbox,
  isQueueInbox,
  (inbox, isQueueInboxType) => {
    if (isQueueInboxType) {
      return '';
    } else {
      // if it's not queue inbox and inbox is present - it will always be phone inbox and it will always have phone numbers
      // $FlowFixMe[prop-missing]
      // $FlowFixMe[incompatible-call]
      return inbox ? inbox.phone_numbers?.[0] : '';
    }
  },
);

export const selectAllInboxId: () => string[] = createSelector(
  selectCurrentInbox,
  isQueueInbox,
  selectCurrentInboxId,
  (inbox, isQueueInboxType, currentInboxId) => {
    if (isQueueInboxType) {
      return [inbox.id];
    } else {
      const inboxIds = [];
      if (inbox.sms) {
        inboxIds.push(inbox.sms.inbox_id);
      }
      if (inbox.whatsapp) {
        inboxIds.push(inbox.whatsapp.inbox_id);
      }
      !inboxIds.length && inboxIds.push(currentInboxId);
      return inboxIds;
    }
  },
);
