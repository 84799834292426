// @flow

import type {State} from 'src/reducers';
import type {
  AtsEntity,
  EntityType,
  AttributeDataType,
  RelatedEntityAttributeSchema,
  EntityMappingsByName,
} from 'src/types/ats-entities';
import type {DynamicLabels} from 'src/action-creators/dynamic-labels';
import type {NamespacedGlobalVariable} from 'src/types/namespaced-global-variables';
// $FlowFixMe[untyped-type-import]
import type {GlobalVariableState} from 'src/reducers/global-variables';

import {createSelector} from 'reselect';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';

import {peopleEntityTypesSet} from 'src/types/ats-entities';
import {
  selectEntityMappingsByName,
  eventEntityType,
  selectRelatedEntityAttributesForEntityType,
} from 'src/selectors/ats-entities';
import {getAttributeRelationshipPath} from 'src/utils/entities';

// $FlowFixMe[value-as-type]
export const getGlobalVariables = (state: State): GlobalVariableState =>
  state.globalVariables;

export const getNamespacedGlobalVariables: (State) => NamespacedGlobalVariable[] =
  (state) => state.namespacedGlobalVariables;

type VariableLevelDepthKeys = $Keys<typeof variableLevelDepths>;
type VariableLevelDepthValues = $Values<typeof variableLevelDepths>;

const variableLevelDepths = {
  scheduling: 1,
  globals: 0,
  audience: 1,
  rte: 2,
  header: 2,
  recipient: 1,
  sender: 2,
};

type DynamicLabelFilters = {
  dedup?: boolean,
  attributeSet?: 'all' | 'recipient',
  dataType?: AttributeDataType | AttributeDataType[],
  depth?: VariableLevelDepthKeys | VariableLevelDepthValues,
  hideGlobals?: boolean,
  hideHiddenFields?: boolean,
  onlyInternals?: boolean,
};

type DynamicLabelSelector = (State, EntityType) => DynamicLabels;

export const createDynamicLabelSelector = (
  filters: DynamicLabelFilters = Object.freeze({}),
): DynamicLabelSelector =>
  createSelector<
    State,
    EntityType,
    DynamicLabels,
    RelatedEntityAttributeSchema[],
    NamespacedGlobalVariable[],
    EntityMappingsByName,
    EntityType,
  >(
    selectRelatedEntityAttributesForEntityType,
    getNamespacedGlobalVariables,
    selectEntityMappingsByName,
    (_state, baseEntityType) => baseEntityType,
    (attributes, namespacedGlobalVariables, mappings, baseEntityType) => {
      const {attributeSet = 'all', hideHiddenFields = true} = filters;

      /*
      let fields =
        (baseEntityType &&
          attributes[baseEntityType] &&
          attributes[baseEntityType][attributeSet]) ||
        [];
      */

      // dedup and prefer shorter paths
      if (filters.dedup) {
        attributes = Array.from(
          attributes
            .reduce((map, attr) => {
              const key =
                attr.resolvedRelationshipPath
                  .map((rel) => String(rel.id))
                  .join('/') +
                '/' +
                attr.id;
              const prevAttr = map.get(key);
              if (
                prevAttr &&
                prevAttr.relationshipPath.length < attr.relationshipPath.length
              ) {
                attr = prevAttr;
              }
              return map.set(key, attr);
            }, new Map())
            .values(),
        );
      }

      let fields = attributes.map((field) => {
        // TODO: it would be nice to change this field's name from "relatedEntityType" to "entityType"
        const {entity_type, relationshipPath, resolvedRelationshipPath} = field;
        const fullyFormedDisplayName = [
          ...resolvedRelationshipPath.map(
            (relationship) => relationship.display_name,
          ),
          field.display_name,
        ].join('/');
        const fullyFormedName = [
          ...relationshipPath,
          field.attribute_name,
        ].join('/');
        const resolvedId =
          field.resolvedRelationshipPath
            .map((rel) => String(rel.id))
            .join('/') +
          '/' +
          field.id;

        return {
          attributeId: field.id,
          resolvedId,
          source: 'member',
          // TODO (rng) - Added this because some components use source and others use resource, clean up later
          resource: 'member',
          entity_type,
          // TODO (kyle): figure out if `mapping` is necessary
          sense_name: mappings[entity_type]?.sense_name,
          display_entity_type: entity_type,
          mapping: mappings[entity_type],
          type: field.data_type,
          name: fullyFormedDisplayName,
          label: fullyFormedDisplayName,
          value: fullyFormedName,
          fieldLabel: field.display_name,
          rteValue: `<${fullyFormedName}>`,
          template: '<$>',
          related_entities: field.relationshipPath,
          baseEntityType,
          entityRelationshipPath: getAttributeRelationshipPath(
            fullyFormedDisplayName,
            baseEntityType,
            mappings,
          ),
          hidden: field.hidden,
          attributeName: field.attribute_name,
          is_join_attribute: field.is_join_attribute,
          designations: field.designations,
        };
      });

      if (!filters.hideGlobals) {
        // global variables are wired up here
        //fields = fields.concat(
        const gvs = namespacedGlobalVariables
          .filter(
            ({anchor_entity_type}) => anchor_entity_type === baseEntityType,
          )
          .map(
            ({
              fully_formed_name,
              related_entities,
              global_variable: {id, type, entity_type},
              anchor_entity_type,
            }) => {
              //we are resolving the global variable id so that they don't get removed
              //during deduplication.
              //see AEP-2692
              const mapping = mappings[anchor_entity_type];
              const relatedEntitiesByName = new Map(
                mapping.related_entities.map((related_entity) => [
                  related_entity.name,
                  related_entity,
                ]),
              );
              const resolvedId =
                related_entities.length > 0
                  ? `${related_entities
                      .map(
                        (related_entity) =>
                          relatedEntitiesByName.get(related_entity)?.id,
                      )
                      .join('/')}/${id}`
                  : `global/${id}`;
              return {
                resolvedId,
                entity_type,

                // not using anchor_entity_type because we want global variables to be separate in modal
                display_entity_type: 'global_variable',
                source: 'global_variable',
                sense_name: 'global_variable',

                // TODO (rng) - Added this because some components use source and others use resource, clean up later
                resource: 'global_variable',
                type,
                mapping: {
                  name: 'global_variable',
                  display_name: 'Global Variable',
                  display_name_plural: 'Global Variables',
                  sense_name: 'global_variable',
                  url: 'global_variable',
                  event_entity: false,
                  is_person: false,
                  related_entities: [],
                },
                name: fully_formed_name,
                label: fully_formed_name,
                value: fully_formed_name,
                //NOTE:(diwakersurya) lets keep this fully formed name for now.
                //if required, need to figure out how to extract only the display
                //name for global variable
                fieldLabel: fully_formed_name,
                rteValue: `<${fully_formed_name}>`,
                template: '<$>',
                related_entities,
                baseEntityType,
                entityRelationshipPath: getAttributeRelationshipPath(
                  fully_formed_name,
                  baseEntityType,
                  mappings,
                ),
                hidden: false,
                is_join_attribute: false,
              };
            },
          );
        fields = fields.concat(gvs);
      }

      if (filters.onlyInternals) {
        fields = fields.filter((field) => field.mapping.url === 'internals');
      }

      if (filters.attributeSet === 'recipient') {
        fields = fields.filter(
          (field) =>
            field.entity_type && peopleEntityTypesSet.has(field.entity_type),
        );
      }

      if (filters.dataType) {
        let targetTypes = filters.dataType;
        if (isString(targetTypes)) {
          targetTypes = [targetTypes];
        }
        // This is a specific detail of global variables:
        // we allow them as long as their entity relation matches
        // even if their dataType is mismatched (ENGAGE-2263)
        fields = fields.filter(
          ({type, source}) =>
            source === 'global_variable' || targetTypes.includes(type),
        );
      }

      // TODO (kyle): this was invalidating '0'
      if ('depth' in filters) {
        // Default to the current limit of variable depth
        let targetDepth: VariableLevelDepthValues = 2;

        if (isNumber(filters.depth)) {
          // $FlowFixMe[incompatible-type]
          targetDepth = filters.depth;
        }
        if (isString(filters.depth)) {
          // $FlowFixMe[invalid-computed-prop]
          targetDepth = variableLevelDepths[filters.depth];
        }
        fields = fields.filter(
          ({related_entities}) =>
            related_entities && related_entities?.length <= targetDepth,
        );
      }

      if (hideHiddenFields) {
        fields = fields.filter(({hidden}) => !hidden);
      }

      fields.sort((a, b) => a.label.localeCompare(b.label));

      return fields;
    },
  );

const selectAllFieldsAtAudienceLevel = createDynamicLabelSelector({
  depth: 'audience',
});

const selectAllFieldsAtAudienceLevelWithoutGlobalVariables =
  createDynamicLabelSelector({
    depth: 'audience',
    hideGlobals: true,
  });

export const selectAllFieldsIncludingHidden: DynamicLabelSelector =
  createDynamicLabelSelector({
    hideHiddenFields: false,
  });

export const selectWorkflowEntityMapping: (State) => AtsEntity | Object =
  createSelector(
    eventEntityType,
    selectEntityMappingsByName,
    (entityType, mappings) =>
      mappings[entityType] ? mappings[entityType] : {},
  );

export const selectAudienceLabels: DynamicLabelSelector = createSelector<
  State,
  EntityType,
  DynamicLabels,
  DynamicLabels,
  DynamicLabels,
>(selectAllFieldsAtAudienceLevel, (dynamicLabels) =>
  dynamicLabels.filter(({type}) => type !== 'text' && type !== 'html'),
);

export const selectAudienceListFields: DynamicLabelSelector = createSelector<
  State,
  EntityType,
  DynamicLabels,
  DynamicLabels,
  DynamicLabels,
>(selectAllFieldsAtAudienceLevelWithoutGlobalVariables, (dynamicLabels) =>
  dynamicLabels.filter(({type}) => type !== 'text' && type !== 'html'),
);

export const selectDynamicLabels: DynamicLabelSelector =
  createDynamicLabelSelector();
