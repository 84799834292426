// @flow

import type {RoiSummary, RoiTimeUnit} from 'src/types/roi';

import * as React from 'react';
import meanBy from 'lodash/meanBy';
import memoize from 'lodash/memoize';

import BarChart from 'src/components/analytics/figures/bar-chart.jsx';
import {checkRoiV3ApiEnabled} from 'src/utils/analytics-api-migration';

import {prettifyNumber} from 'src/utils';

import css from '../index.css';


const NUM_DAYS_IN_MONTH = 30;

const PlacementDurationsChart = ({
  data,
  chartProps,
  loading,
  onUnitChange,
  timeUnit,
}: {
  data?: RoiSummary,
  chartProps?: mixed,
  loading?: boolean,
  onUnitChange: (timeUnit: RoiTimeUnit) => void,
  timeUnit: RoiTimeUnit,
}): React.Node => (
  <React.Fragment>
    <div className={css.subtitle}>
      Average Length of Assignment
      {(() => {
        if (data) {
          const placementDurationMean = getPlacementDurationMean(data);
          return `(Overall ${placementDurationMean} months)`;
        }
      })()}
    </div>
    <BarChart
      {...chartProps}
      categoryKey={timeUnit}
      dataKey={
        checkRoiV3ApiEnabled() ? 'assignmentLength' : 'placementDurations'
      }
      loading={loading}
      showEmptyBars={false}
      color={css.colorOrange}
      data={data ? data.summaryStats : []}
      timeUnit={timeUnit}
      onUnitChange={onUnitChange}
      getTooltipText={({value}) =>
        `Average assignment length of ${getDaysInMonths(value)} months`
      }
    />
  </React.Fragment>
);

const _getPlacementDurationMean = (data: RoiSummary): number => {
  const keyName = checkRoiV3ApiEnabled()
    ? 'assignmentLength'
    : 'placementDurations';
  return getDaysInMonths(
    // $FlowFixMe[incompatible-type]
    meanBy(data.summaryStats, ({[keyName]: {value}}) => value),
  );
};

export const getPlacementDurationMean = (memoize(
  _getPlacementDurationMean,
): (RoiSummary) => number);

const getDaysInMonths = (days: number) =>
  prettifyNumber(days / NUM_DAYS_IN_MONTH);

export default PlacementDurationsChart;
