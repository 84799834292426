// @flow strict

// $FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';
import type {ReferralSetting, GenericError} from 'src/types/referral-v2';
import {ReferralCurrentState} from 'src/reducers/referral-settings.js';


export const selectReferralSetting = (state: State): ?ReferralSetting =>
  state?.referralSettings?.referral_settings;

export const selectReferralSettingError = (
  state: State,
): ?GenericError | string => state?.referralSettings?.referral_settings_error;

export const selectReferralSettingLoading = (state: State): boolean =>
  state?.referralSettings?.current_state ===
  ReferralCurrentState.FETCHING_REFERRAL_SETTINGS;
