// @flow

import type {Router} from 'src/types/router';
import type {
  // $FlowFixMe[untyped-type-import]
  EventCalendarItem,
  // $FlowFixMe[untyped-type-import]
  EventCalendarRecipient,
  // $FlowFixMe[untyped-type-import]
  EventCalendarAudiencePreview,
} from 'src/api-parsers/events';

import * as React from 'react';
import {useSelector} from 'react-redux';

import {selectEntityMappingsByName} from 'src/selectors/ats-entities';
import {fluxify} from 'src/flux/component.jsx';
import Loading from 'src/components/lib/loading';
import Table, {Cells, Cell} from 'src/components/lib/table';
import {TitleBarModal} from 'src/components/modals/base-modal.jsx';

import {getEventCalendarAudiencePreviewPage} from 'src/actions/schedule';

import css from './event-audience-modal.css';


const tableHeader = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'deliveryAddress',
    label: 'Delivery Address',
  },
];

function EventAudienceModal(props: {
  store: any,
  router: Router,
  // $FlowFixMe[value-as-type] [v1.32.0]
  calendarEvent: EventCalendarItem,
  // $FlowFixMe[value-as-type] [v1.32.0]
  audiencePreview: EventCalendarAudiencePreview,
  removeModal: () => void,
  fetchAudiencePreview: (dateString: string, eventId: string) => void,
  loading: ?Promise<*>,
}) {
  const {
    router,
    loading,
    calendarEvent,
    audiencePreview,
    fetchAudiencePreview,
    removeModal,
  } = props;

  return (
    <TitleBarModal
      className={css.container}
      title={`Recipients of ${calendarEvent.eventTitle}`}
      type={'EVENT_AUDIENCE'}
      handleAbort={removeModal}
      handleConfirm={removeModal}
      confirmText={!loading ? 'Okay' : ''}
    >
      <Audience
        router={router}
        loading={loading}
        calendarEvent={calendarEvent}
        audiencePreview={audiencePreview}
        fetchAudiencePreview={fetchAudiencePreview}
        removeModal={removeModal}
      />
    </TitleBarModal>
  );
}

const mapper = ({store, calendarEvent: {dateString, eventId}}) => {
  const key = getEventCalendarAudiencePreviewPage.KEY(dateString, eventId);
  return {
    loading: store.request.inProgress(key) || null,
    audiencePreview: store.schedule.getAudiencePreview(dateString, eventId),
  };
};

export default (fluxify(mapper)(EventAudienceModal): React.ComponentType<any>);

const Recipient = ({
  data: {recipientId, name, deliveryAddress, entityUrl},
  extras: {router, removeModal},
}: {
  // $FlowFixMe[value-as-type] [v1.32.0]
  data: EventCalendarRecipient,
  extras: {router: Router, removeModal: () => mixed},
}) => (
  <Cells>
    <Cell>
      <span
        className={css.name}
        onClick={() => {
          if (entityUrl) {
            removeModal();
            router.push(`/people/${entityUrl}/${recipientId}`);
          }
        }}
      >
        {name}
      </span>
    </Cell>
    <Cell>{deliveryAddress}</Cell>
  </Cells>
);

function Audience(props: {
  // $FlowFixMe[value-as-type] [v1.32.0]
  audiencePreview: ?EventCalendarAudiencePreview,
  // $FlowFixMe[value-as-type] [v1.32.0]
  calendarEvent: EventCalendarItem,
  loading: ?Promise<*>,
  fetchAudiencePreview: (dateString: string, eventId: string) => void,
  router: Router,
  removeModal: () => mixed,
}) {
  let {loading} = props;
  const {
    audiencePreview,
    calendarEvent,
    fetchAudiencePreview,
    router,
    removeModal,
  } = props;

  const scrollerRef = React.useRef();
  const entitySchemas = useSelector(selectEntityMappingsByName);

  const recipients = React.useMemo(
    () =>
      audiencePreview &&
      audiencePreview.recipients.map((recipient) => ({
        ...recipient,
        entityUrl: entitySchemas[recipient.recipientEntityType]?.url,
      })),
    [entitySchemas, audiencePreview],
  );

  React.useEffect(() => {
    const {dateString, eventId} = calendarEvent;

    if (!audiencePreview) {
      fetchAudiencePreview(dateString, eventId);
    }
  }, []);

  const handleScroll = () => {
    const {dateString, eventId} = calendarEvent;
    const isDone = audiencePreview && audiencePreview.isDone;

    if (!scrollerRef.current) {
      return;
    }

    const {
      current: {scrollTop, scrollHeight, clientHeight},
    } = scrollerRef;

    if (!fetchAudiencePreview || isDone) {
      return;
    }

    if (scrollTop + clientHeight >= scrollHeight) {
      return fetchAudiencePreview(dateString, eventId);
    }
  };

  if (!audiencePreview) {
    loading = true;
  }

  return (
    <div className={css.scrollWrap} onScroll={handleScroll} ref={scrollerRef}>
      {recipients && (
        <Table
          className={css.table}
          header={tableHeader}
          Row={Recipient}
          entries={recipients}
          extras={{router, removeModal}}
          idName="deliveryAddress"
          sortable={false}
        />
      )}
      {loading && <Loading className={css.loader} animationDelay={0} />}
    </div>
  );
}
