import Store from './base';


export default class RequestHeaderStore extends Store {
  constructor() {
    super('requestHeaders');
    this.state = {
      headers: {},
      subdomains: [],
    };
  }

  fill({headers, subdomains}) {
    this.state = {
      headers,
      subdomains,
    };
  }

  serialize() {
    const userAgent = this.state.headers['user-agent'];

    if (!userAgent) {
      return {
        [this.key]: {
          headers: {},
          subdomains: [],
        },
      };
    }

    return {
      [this.key]: {
        headers: {
          'user-agent': userAgent,
        },
        subdomains: [],
      },
    };
  }
}
