// @flow

import type {Dispatch, GetState, ThunkAction} from 'src/reducers';
import type {Query} from 'src/types/router';
import type {SentEvents, AnalyticsQuery} from 'src/types/report';
import type {SentEventWithExternals} from 'src/api-parsers/events.types';

import {thunkify as flow} from 'src/utils/thunks';
import {camel} from 'src/utils';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api.js';
import {
  resolveAnalyticsQuery,
  resolveAnalyticsPath,
} from 'src/utils/analytics-query';
import {
  checkAnalyticsNpsV3Enabled,
  ANALYTICS_API_V3,
} from 'src/utils/analytics-api-migration';


const {stringify} = JSON;

export const RECEIVE_AGENCY = 'sentEvent/receiveAgency';
export const RECEIVE_WORKFLOW = 'sentEvent/receiveWorkflow';
export const RECEIVE_EVENT = 'sentEvent/receiveEvent';
export const RECEIVE_AUDIENCE_MEMBER = 'sentEvent/receiveAudienceMember';

type PaginatedSentEvents = {
  page: number,
  sentEvents: SentEvents,
};

export type AgencySentEventAction = {
  type: 'sentEvent/receiveAgency',
  payload: PaginatedSentEvents,
};
export type WorkflowSentEventAction = {
  type: 'sentEvent/receiveWorkflow',
  payload: {
    ...PaginatedSentEvents,
    workflowId: string,
  },
};
export type EventSentEventAction = {
  type: 'sentEvent/receiveEvent',
  payload: {
    ...PaginatedSentEvents,
    eventId: string,
  },
};
export type AudienceMemberSentEventAction = {
  type: 'sentEvent/receiveAudienceMember',
  payload: {
    page: number,
    // $FlowFixMe[value-as-type] [v1.32.0]
    sentEvents: SentEventWithExternals[],
    audienceMemberId: string,
  },
};
export type SentEventAction =
  | AgencySentEventAction
  | WorkflowSentEventAction
  | EventSentEventAction
  | AudienceMemberSentEventAction;

export const receiveAgency = (
  sentEvents: SentEvents,
  page: number,
): AgencySentEventAction => ({
  type: RECEIVE_AGENCY,
  payload: {
    sentEvents,
    page,
  },
});
export const receiveWorkflow = (
  sentEvents: SentEvents,
  workflowId: string,
  page: number,
): WorkflowSentEventAction => ({
  type: RECEIVE_WORKFLOW,
  payload: {
    workflowId,
    sentEvents,
    page,
  },
});
export const receiveEvent = (
  sentEvents: SentEvents,
  eventId: string,
  page: number,
): EventSentEventAction => ({
  type: RECEIVE_EVENT,
  payload: {
    eventId,
    sentEvents,
    page,
  },
});
export const receiveAudienceMember = (
  sentEvents: SentEventWithExternals[],
  audienceMemberId: string,
  page: number,
): AudienceMemberSentEventAction => ({
  type: RECEIVE_AUDIENCE_MEMBER,
  payload: {
    audienceMemberId,
    sentEvents,
    page,
  },
});

export const PAGINATION_LIMIT = 50;
function resolvePageQuery(query: Query, page: number) {
  return {...query, offset: page * PAGINATION_LIMIT, limit: PAGINATION_LIMIT};
}

export function resolveSentEventsQuery(
  query: Query,
  allowed?: string[] = [
    'client',
    'event_types',
    'tracking_events',
    'recipients',
    'nps_only',
    'sort',
    'workflow_id',
    'event_id',
    'start_date',
    'end_date',
  ],
): AnalyticsQuery {
  return resolveAnalyticsQuery(query, allowed);
}

export function resolveApiPath(ids: *): string {
  return resolveAnalyticsPath('sent-events', ids);
}

const querying =
  (paramIndex, allowed) =>
  (func) =>
  (...args) => {
    args[paramIndex] = resolveSentEventsQuery(args[paramIndex], allowed);
    return func(...args);
  };

export const getAgencySentEvents: (
  query: Query,
  page?: number,
) => ThunkAction<mixed> = flow(
  querying(0),
  key((query, page) => `sentEvents/agency:${page}`),
  cached((json, query, page = 0) => receiveAgency(camel(json), page), {
    hash: (query, page) => `sentEvents/agency:${page}:${stringify(query)}`,
  }),
  fetching(),
)((query: Query, page: number = 0) =>
  reduxApi.post(
    '/sent-events',
    resolvePageQuery(query, page),
    {},
    {
      apiPath: checkAnalyticsNpsV3Enabled()
        ? ANALYTICS_API_V3
        : '/api/v1/analytics',
    },
  ),
);

export const getMoreAgencySentEvents =
  (query: Query): ThunkAction<mixed> =>
  (dispatch: Dispatch, getState: GetState) =>
    dispatch(getAgencySentEvents(query, getState().sentEvents.agency.page + 1));

export const getWorkflowSentEvents: (
  workflowId: string,
  query: Query,
  page?: number,
) => ThunkAction<mixed> = flow(
  querying(1),
  key((workflowId, query, page) => `sentEvents/workflow:${workflowId}:${page}`),
  cached(
    (json, workflowId, query, page = 0) =>
      receiveWorkflow(camel(json), workflowId, page),
    {
      hash: (workflowId, query, page) =>
        `sentEvents/workflow:${workflowId}:${page}:${stringify(query)}`,
    },
  ),
  fetching(),
)((workflowId: string, query: Query, page: number = 0) =>
  reduxApi.post(
    `analytics/workflows/${workflowId}/sent-events`,
    resolvePageQuery(query, page),
  ),
);

export const getMoreWorkflowSentEvents =
  (workflowId: string, query: Query): ThunkAction<mixed> =>
  (dispatch: Dispatch, getState: GetState) =>
    dispatch(
      getWorkflowSentEvents(
        workflowId,
        query,
        getState().sentEvents.workflows[workflowId].page + 1,
      ),
    );

export const getEventSentEvents: (
  workflowId: string,
  eventId: string,
  query: Query,
  page?: number,
) => ThunkAction<mixed> = flow(
  querying(2, ['client', 'tracking_events', 'recipients']),
  key(
    (workflowId, eventId, query, page) =>
      `sentEvents/workflow:${workflowId}:${eventId}:${page}`,
  ),
  cached(
    (json, workflowId, eventId, query, page = 0) =>
      receiveEvent(camel(json), eventId, page),
    {
      hash: (workflowId, eventId, query, page) =>
        `sentEvents/workflow:${workflowId}:${eventId}:${page}:${stringify(
          query,
        )}`,
    },
  ),
  fetching(),
)((workflowId: string, eventId: string, query: Query, page: number = 0) =>
  reduxApi.post(
    `analytics/workflows/${workflowId}/events/${eventId}/sent-events`,
    resolvePageQuery(query, page),
  ),
);

export const getMoreEventSentEvents =
  (workflowId: string, eventId: string, query: Query): ThunkAction<mixed> =>
  (dispatch: Dispatch, getState: GetState) =>
    dispatch(
      getEventSentEvents(
        workflowId,
        eventId,
        query,
        getState().sentEvents.events[eventId].page + 1,
      ),
    );

export const getAudienceMemberSentEvents: (
  audienceMemberId: string,
  page?: number,
) => ThunkAction<mixed> = flow(
  key(
    (audienceMemberId, page) =>
      `audienceMember/${audienceMemberId}/sentEvents:${page}`,
  ),
  cached((json, audienceMemberId, page = 0) =>
    receiveAudienceMember(camel(json), audienceMemberId, page),
  ),
  fetching(),
)((audienceMemberId: string, page: number = 0) =>
  reduxApi.get(
    `audience-members/${audienceMemberId}/sent-events`,
    // $FlowFixMe
    resolvePageQuery({}, page),
  ),
);

// TODO (kyle): no reason to add a getMore thunk now, but can later.
