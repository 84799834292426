// @noflow

import * as React from 'react';
import without from 'lodash/without';
import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';

import {classify, sortObject} from 'src/utils';
import {DEFAULT_LIMIT_VALUE} from './constants.js';

import {AnalyticsService} from 'src/analytics';
import {getMessagingTrackingMetrics} from 'src/components/messaging/messaging-amplitude-helper.js';

import ErrorList from 'src/components/lib/error-list';

import css from './style.css';

// TODO (kyle): make value a generic
export type Option<Value, Extras = void, Source = void> = {
  label: string,
  value: Value,
  sourceObject?: Source,
  extras?: Extras,
  disabled?: boolean,
  className?: string,
};

type Values<Value, Extras, Source> = Option<Value, Extras, Source>[];
type SelectableOption<Values, Extras, Source> = Option<
  Values,
  Extras,
  Source,
> & {
  selected: boolean,
};

export type Props<Value, Extras, Source> = {
  className?: string,
  containerClassName?: string,
  inputClassName?: string,
  optionsContainerClassName?: string,
  optionClassName?: string,
  optionGroupHeading?: string,
  label?: React.Node,
  suffixLabel?: React.Node,
  prefixLabel?: React.Node,
  placeholder?: string,
  values: Values<Value, Extras, Source>,
  options?: Values<Value, Extras, Source>,
  groupOptionsBy?: string,
  sortOptionsBy?: (Value, Value) => number,
  keepGroupOrder?: boolean,
  // TODO (kyle): this option doesn't fully work because of
  // arrow key selection.
  //showCurrentlySelected?: boolean,
  excludeValues?: string[],
  errors?: string[],
  onChange: (values: Values<Value, Extras, Source>) => void,
  onScroll: (e: SyntheticEvent<HTMLInputElement>) => void,
  limit?: number,
  disabled?: boolean,
  allowArbitraryValues: boolean,
  verticalStretch?: boolean,
  validateArbitraryValue: (value: mixed) => boolean,
  TokenComponent?: React.ComponentType<{
    className: string,
    onClick: (event: SyntheticMouseEvent<*>) => any,
    option: Option<Value, Extras, Source>,
  }>,
  tokenClassName?: string, // the wrapper for the tokens _and_ text input
  Suggestion?: React.ComponentType<{
    option: Option<Value, Extras, Source>,
    searchString: string,
    preventSelection: () => mixed,
    extras: Extras,
    onClick?: (evt: SyntheticMouseEvent<>) => mixed,
  }>,
  suggestionExtras?: Extras,
  // Always keep search suggestions open while input has focus
  searchOnEmptyString: boolean,
  onInputChange?: (value: string) => void,
  onInputFocus?: () => void,
  onInputBlur?: () => void,
  onBlur?: (event: SyntheticMouseEvent<*>) => any,
  unfiltered?: boolean,
  forceFocus?: boolean,
  focusOnMount?: boolean,
  isKnownToken: (
    token: Option<Value, Extras, Source>,
    options?: Values<Value, Extras, Source>,
  ) => boolean,
  arbitraryOptionLabel: (label: string) => string,
  actions?: Array<{
    label: string,
    Icon: () => React.Node,
    onClick: () => any,
    onlyShowWithOptions: boolean,
  }>,
  showValuesInInput?: boolean,
  enableListPasting?: boolean,
  listItemSeparatorRegex?: RegExp /** this regex is used to break the text into separate items on pasting into the token list input */,
  showTokenX: boolean,
  tabIndex?: number,
  openOnFocus?: boolean,
  hasError: boolean,
  allowBlurOnItemSelection?: Boolean,
  hideInputElement?: Boolean,
};

type State<Value, Extras, Source> = {
  inputValue: string,
  focus: boolean,
  selectedIndex: number,
  filteredOptions: Values<Value, Extras, Source>,
  groupedOptions: {[string]: Values<Value, Extras, Source>},
};

const proceedKeys = new Set(['Tab', 'Enter', ',']);
const selectionKeys = new Set(['Tab', 'Enter']);

export default class TokenListInput<
  Value,
  Extras: {},
  Source,
> extends React.PureComponent<
  Props<Value, Extras, Source>,
  State<Value, Extras, Source>,
> {
  static defaultProps = {
    allowArbitraryValues: true,
    searchOnEmptyString: true,
    isKnownToken: (
      token: Option<Value, Extras, Source>,
      options?: Values<Value, Extras, Source>,
    ) => Boolean(options && options.find((opt) => opt.value === token.value)),
    arbitraryOptionLabel: (label: string) => `Create "${label}"`,
    validateArbitraryValue: () => true,
    actions: [],
    showValuesInInput: true,
    showTokenX: true,
    listItemSeparatorRegex: /[\,\n]/,
  };

  state: State<Value, Extras, Source> = {
    inputValue: '',
    focus: false,
    selectedIndex: -1,
    filteredOptions: [],
  };

  inputBox = null;
  resultRefs = [];
  inputElement = null;
  tokensElement = null;
  optionClickPrevented = false;
  optionsBox = null;

  componentDidMount() {
    if (this.props.focusOnMount) {
      this.inputElement && this.inputElement.focus();
    }
    this.filter(this.props);
  }

  componentDidUpdate(oldProps: Props<Value, Extras, Source>) {
    if (
      oldProps.values.length < this.props.values.length &&
      this.inputBox &&
      this.tokensElement
    ) {
      this.inputBox.scrollLeft = this.tokensElement.offsetWidth;
    }

    if (
      oldProps.values !== this.props.values ||
      oldProps.options !== this.props.options ||
      oldProps.excludeValues !== this.props.excludeValues
    ) {
      this.filter(this.props);
    }
  }

  render() {
    const {
      placeholder,
      values,
      className,
      containerClassName,
      inputClassName,
      optionsContainerClassName,
      optionClassName,
      optionGroupHeading,
      options,
      limit = DEFAULT_LIMIT_VALUE,
      groupOptionsBy,
      showCurrentlySelected = true,
      keepGroupOrder = false,
      enableListPasting = false,
      errors = [],
      disabled = false,
      TokenComponent = 'div',
      PlaceholderComponent,
      tokenClassName,
      Suggestion = DefaultSuggestion,
      suggestionExtras,
      verticalStretch,
      limitHeight,
      label,
      prefixLabel,
      suffixLabel,
      isKnownToken,
      actions,
      showValuesInInput,
      tabIndex,
      forceFocus = false,
      openOnFocus = false,
      hasError,
      hideInputElement,
    } = this.props;
    const {inputValue, focus, selectedIndex, filteredOptions, groupedOptions} =
      this.state;

    const hideInput = values.length >= limit;
    const showOptions =
      filteredOptions &&
      filteredOptions.length > 0 &&
      (forceFocus || (focus && (openOnFocus || inputValue.length > 0))) &&
      !hideInput;

    const filteredActions = actions.filter(
      (a) => showOptions || !a.onlyShowWithOptions,
    );

    //NOTE (Iris): filters out duplicates by value (i.e. id or phone number)
    const filteredValueComponents = Object.values(
      values.reduce((all, recipient) => {
        all[recipient.value] = recipient;
        return all;
      }, {}),
    );
    const valueComponents = filteredValueComponents.map((token) =>
      (token.label || '').startsWith('placeholder:') ? ( //ENGAGE-7021 (label can be null or undefined)
        <PlaceholderComponent
          text={token.label.replace('placeholder:', '')}
          onReplace={(event) => this.handleTokenClick(token, event)}
        />
      ) : (
        <TokenComponent
          className={classify(
            token.className,
            isKnownToken(token, options) ? css.defaultToken : css.unknownToken,
          )}
          key={token.value}
          onClick={(event) => {
            !disabled && this.handleTokenClick(token, event);
            const isExtension =
              window?.__reduxStore?.getState()?.env?.query?.context ===
              'chrome-extension';
            if (isExtension) {
              const trackingDetails = getMessagingTrackingMetrics();
              AnalyticsService.track(
                'Extension Numbers Deselected',
                trackingDetails,
              );
            }
          }}
          option={token}
          fields={options}
        >
          <div data-qa-id="token-old">{token.label}</div>
          {this.props.showTokenX && !disabled && <span>x</span>}
        </TokenComponent>
      ),
    );

    let correctedIndex = 0;
    //this.resultRefs = [];
    const generateSuggestion = (option) => {
      let isHighlighted = false;

      // Disclude those in 'currently selected' from index
      //if (!option.selected) {
      isHighlighted = selectedIndex === correctedIndex;
      const index = correctedIndex;
      correctedIndex++;
      //}

      return (
        <div
          key={option.value}
          ref={(elm) => {
            this.resultRefs[index] = elm;
          }}
        >
          <Suggestion
            className={classify(
              css.option,
              {
                [css.selectedOption]: isHighlighted,
              },
              optionClassName,
            )}
            onClick={(event) => this.handleOptionClick(option, event)}
            option={option}
            searchString={inputValue}
            extras={{
              ...suggestionExtras,
              isHighlighted,
            }}
            preventSelection={this.preventOptionClick}
            selected={option.selected}
          />
        </div>
      );
    };

    return (
      <div className={classify(css.container, containerClassName)}>
        {!showValuesInInput && valueComponents.length > 0 && (
          <div className={css.tokensOutside}>{valueComponents}</div>
        )}
        <div
          className={classify(css.inputContainer, inputClassName)}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onMouseDown={this.handleMouseDown}
          tabIndex="-1"
        >
          <div
            className={classify(css.box, css.down, className, {
              [css.errorBox]: errors.length > 0,
              [css.boxFocus]: focus,
              [css.boxOptions]: showOptions,
              [css.boxVerticalStretch]: verticalStretch,
              [css.boxLimitHeight]: limitHeight,
              [css.inputDisabled]: hideInput,
              [css.thinError]: hasError,
            })}
            ref={(el) => (this.inputBox = el)}
          >
            {prefixLabel && (
              <div className={css.prefixLabel}>{prefixLabel}</div>
            )}
            {label && <div className={css.label}>{label}</div>}
            <div
              className={classify(css.tokens, tokenClassName)}
              ref={(el) => (this.tokensElement = el)}
              data-qa-id="token-container-old"
            >
              {showValuesInInput && valueComponents}
              {!hideInput && !hideInputElement && (
                <input
                  ref={(el) => {
                    this.inputElement = el;
                  }}
                  type="text"
                  className={css.input}
                  value={inputValue}
                  placeholder={values.length === 0 ? placeholder : null}
                  onKeyDown={this.handleInputKeyDown}
                  onChange={this.handleInputChange}
                  onPaste={enableListPasting ? this.handlePaste : undefined}
                  onFocus={this.props.onInputFocus}
                  onBlur={this.props.onInputBlur}
                  disabled={disabled}
                  tabIndex={tabIndex}
                  autoComplete="false"
                  data-qa-id="token-list-input-old"
                />
              )}
            </div>
            {suffixLabel && (
              <div className={css.suffixLabel}>{suffixLabel}</div>
            )}
          </div>

          {filteredActions.length > 0 && (
            <ul className={css.actionList}>
              {filteredActions.map((action) => (
                <li
                  className={classify(css.actionListItem, action.className)}
                  onClick={action.onClick}
                >
                  <action.Icon /> {action.label}
                </li>
              ))}
            </ul>
          )}

          {showOptions && (
            <div
              data-qa-id="token-list-input-suggestions"
              onScroll={this.props.onScroll}
              className={classify(
                css.options,
                css.down,
                optionsContainerClassName,
              )}
              ref={(el) => (this.optionsBox = el)}
            >
              {!isNil(groupOptionsBy)
                ? Object.keys(groupedOptions).map((groupKey, groupIndex) => (
                    <section className={css.optionGroup} key={groupKey}>
                      {groupKey !== 'undefined' && (
                        <h4
                          className={
                            optionGroupHeading || css.optionGroupHeading
                          }
                        >
                          {groupKey}
                        </h4>
                      )}
                      {groupedOptions[groupKey].map(generateSuggestion)}
                    </section>
                  ))
                : filteredOptions.map(generateSuggestion)}
            </div>
          )}
        </div>
        {!focus && <ErrorList errors={errors} className={css.error} />}
      </div>
    );
  }

  filter({
    values,
    options,
    excludeValues,
    groupOptionsBy,
    sortOptionsBy,
    keepGroupOrder,
    showCurrentlySelected,
  }: Props<Value, Extras, Source>) {
    const searchTerm = this.state.inputValue;

    const trimmedValue = searchTerm.trim().toLowerCase();
    // NOTE (kyle): this is not performance sensitive but could be in the future. could use caching/indexing if needed.
    let filteredOptions = options
      ? options.filter(
          ({value}) => !values.find((token) => token.value === value),
        )
      : [];
    let groupedOptions: {
      [groupKey: string]: SelectableOption<void, void>[],
    } = {};

    if (sortOptionsBy) {
      filteredOptions = filteredOptions.sort(sortOptionsBy);
    }

    if (this.props.unfiltered) {
      // noop (using filteredOptions as is)
    } else if (trimmedValue || this.props.searchOnEmptyString) {
      filteredOptions = filteredOptions.filter(
        ({label, value}) =>
          (!excludeValues || !excludeValues.includes(value)) &&
          (label.toLowerCase().includes(trimmedValue) ||
            (typeof value === 'string' &&
              value.toLowerCase().includes(trimmedValue))),
      );
    } else {
      filteredOptions = [];
    }

    if (
      this.props.allowArbitraryValues &&
      trimmedValue &&
      this.props.validateArbitraryValue(trimmedValue)
    ) {
      if (
        !options ||
        !options.find((o) => o.value.toLowerCase() === trimmedValue)
      ) {
        const arbitraryOption = {
          value: trimmedValue,
          label: this.props.arbitraryOptionLabel(trimmedValue),
        };
        filteredOptions = [arbitraryOption, ...filteredOptions];
      }
    }

    if (groupOptionsBy) {
      groupedOptions = keepGroupOrder
        ? groupBy(filteredOptions, groupOptionsBy)
        : sortObject(groupBy(filteredOptions, groupOptionsBy));
      filteredOptions = Object.values(groupedOptions).flat();
    }

    this.setState({
      filteredOptions,
      groupedOptions,
      // selectedIndex: 0,
    });
  }

  addValue(value: Option<Value, Extras, Source>) {
    const {onChange, values} = this.props;

    if (!value || value.disabled) {
      return;
    }

    this.setState(
      {
        inputValue: '',
      },
      () => {
        const existingToken = values.find(
          (token) => token.value === value.value,
        );
        if (!existingToken) {
          onChange([...values, value]);
        }
      },
    );
  }

  _handleInputKeyDown(
    event: SyntheticKeyboardEvent<HTMLInputElement> & {
      target: HTMLInputElement,
    },
  ) {
    const value = event.target.value;
    const key = event.key;
    const text = value.trim();

    const scrollResultIntoView = (nextSelectedIndex, block) => {
      const {optionsBox, resultRefs} = this;
      if (optionsBox && resultRefs[nextSelectedIndex]) {
        const resultRef = resultRefs[nextSelectedIndex];
        const containerRects = optionsBox.getBoundingClientRect();
        const itemRects = resultRef.getBoundingClientRect();

        const topDiff = containerRects.top - itemRects.top;
        const bottomDiff = containerRects.bottom - itemRects.bottom;

        if (topDiff > 0 || bottomDiff < 0) {
          const scrollTop = optionsBox.scrollTop;
          const top =
            block === 'start' ? scrollTop - topDiff : scrollTop - bottomDiff;

          this.optionsBox.scrollTo({
            top,
            behavior: 'smooth',
          });
          /*
          this.resultRefs[nextSelectedIndex].scrollIntoView({
            behavior: 'smooth',
            block,
          });
          */
        }
      }
    };

    if (proceedKeys.has(key)) {
      if (value || key !== 'Tab') {
        event.preventDefault();

        const {filteredOptions, selectedIndex} = this.state;

        if (
          filteredOptions &&
          filteredOptions.length &&
          selectionKeys.has(key)
        ) {
          this.addValue(filteredOptions[selectedIndex]);
        } else if (
          this.props.allowArbitraryValues &&
          this.props.validateArbitraryValue(text)
        ) {
          this.addValue({
            label: text,
            value: text,
          });
        }
      }
    } else if (key === 'Backspace' && value === '') {
      event.preventDefault();
      this.props.onChange(this.props.values.slice(0, -1));
    } else if (key === 'ArrowDown') {
      event.preventDefault();
      const {selectedIndex, filteredOptions} = this.state;
      let nextSelectedIndex = selectedIndex + 1;

      while (
        nextSelectedIndex < filteredOptions.length &&
        filteredOptions[nextSelectedIndex].disabled
      ) {
        nextSelectedIndex++;
      }

      if (nextSelectedIndex < filteredOptions.length) {
        this.setState({
          selectedIndex: nextSelectedIndex,
        });
        scrollResultIntoView(nextSelectedIndex, 'end');
      }
    } else if (key === 'ArrowUp') {
      event.preventDefault();
      const {selectedIndex, filteredOptions} = this.state;
      let nextSelectedIndex = selectedIndex - 1;

      while (
        nextSelectedIndex >= 0 &&
        filteredOptions[nextSelectedIndex].disabled
      ) {
        nextSelectedIndex--;
      }

      if (nextSelectedIndex >= 0) {
        this.setState({
          selectedIndex: nextSelectedIndex,
        });
        scrollResultIntoView(nextSelectedIndex, 'start');
      }
    } else if (key === 'Escape') {
      event.preventDefault();
      this.inputElement && this.inputElement.blur();
    }
  }
  handleInputKeyDown = this._handleInputKeyDown.bind(this);

  _handleInputChange(event: SyntheticInputEvent<>) {
    const value = event.target.value;
    if (this.optionsBox) {
      this.optionsBox.scrollTop = 0;
    }
    this.setState(
      {
        inputValue: value,
        selectedIndex: 0,
      },
      () => {
        if (typeof this.props.onInputChange === 'function') {
          this.props.onInputChange(value);
        }
        this.filter(this.props);
      },
    );
  }
  handleInputChange = this._handleInputChange.bind(this);

  _handlePaste = (event: ClipboardEvent) => {
    event.preventDefault();
    const value = event.clipboardData?.getData('text');
    const {limit = DEFAULT_LIMIT_VALUE} = this.props;
    if (!value || !value.length) {
      return;
    }
    const parsedValues = value
      .split(this.props.listItemSeparatorRegex)
      .reduce((acc, val) => {
        val = val.trim();
        if (
          // value exists
          val.length &&
          // value not already in input
          !this.props.values.map(({value}) => value).includes(val) &&
          // value not already in queue
          !acc.includes(val)
        ) {
          acc.push(val);
        }
        return acc;
      }, [])
      .map((val) => ({
        label: val,
        value: val,
      }));
    //remove extra items if the limit prop is provided and no of items exceeds the limit
    let newValues = [...this.props.values, ...parsedValues];
    if (limit > 0 && limit < newValues.length) {
      newValues = newValues.slice(0, limit);
    }
    this.props.onChange(newValues);
  };
  handlePaste = this._handlePaste.bind(this);

  _handleTokenClick(token: Option<Value, Extras, Source>) {
    // NOTE (wooju): it seems that this preventDefault was originally there to prevent
    // the options dropdown from showing up as you remove tokens (specifically when
    // limit > 1). This had a side effect when limit = 1 where the component loses
    // focus when the token is removed. The options dropdown would show up, but the
    // user won't be able to close it by clicking outside of the component because the
    // blur event never occurs.
    // event.preventDefault();
    const {onChange, values} = this.props;
    onChange(without(values, token));
    setTimeout(() => {
      this.inputElement && this.inputElement.focus();
    }, 0);
  }
  handleTokenClick = this._handleTokenClick.bind(this);

  _handleMouseDown(event: SyntheticMouseEvent) {
    if (!this.props.allowBlurOnItemSelection) {
      if (this.state.focus && this.inputElement === document.activeElement) {
        event.preventDefault();
      }
    }
  }
  handleMouseDown = this._handleMouseDown.bind(this);

  handleOptionClick(
    option: Option<Value, Extras, Source>,
    event: SyntheticMouseEvent<>,
  ) {
    if (this.optionClickPrevented) {
      this.optionClickPrevented = false;
      return;
    }
    event.preventDefault();
    this.addValue(option);
  }

  _preventOptionClick() {
    this.optionClickPrevented = true;
  }
  preventOptionClick = this._preventOptionClick.bind(this);

  _handleFocus(e: ?SyntheticEvent<HTMLElement>) {
    this.setState({focus: true});
    if (!e || this.inputElement !== e.target) {
      this.inputElement && this.inputElement.focus();
    }
  }
  handleFocus = this._handleFocus.bind(this);

  _handleBlur(event: SyntheticMouseEvent<HTMLElement>) {
    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur(event);
    }
    const text = this.state.inputValue.trim();
    if (
      this.props.allowArbitraryValues &&
      text &&
      this.props.validateArbitraryValue(text)
    ) {
      this.addValue({
        label: text,
        value: text,
      });
    }
    this.setState({focus: false, selectedIndex: 0});
  }
  handleBlur = this._handleBlur.bind(this);
}

export const StringListInput = ({
  values = [],
  options = [],
  onChange,
  ...props
}: {
  values: string[],
  options: string[],
  onChange: (values: string[]) => void,
}) => (
  <TokenListInput
    {...props}
    values={values.map((value) => ({label: String(value), value}))}
    onChange={(values) => onChange(values.map(({value}) => value))}
    options={options.map((value) => ({label: String(value), value}))}
  />
);

const DefaultSuggestion = <Value>({
  option,
  ...props
}: {
  option: SelectableOption<Value, {}, void>,
}) => <div {...props}>{option.label}</div>;
