// @noflow
import logger from 'src/utils/logger';

import reduce from 'lodash/reduce';
import forEach from 'lodash/forEach';
import {EventEmitter} from 'events';
import sculpt from 'sculpt';

import MeStore from './me';
import WorkflowStore from './workflow';
import AudienceMemberStore from './audience-member';
import SurveyStore from './survey';
import EventStore from './event';
import EventTemplateStore from './event-template';
import ResponderStore from './responder';
import SentEventStore from './sent-event';
import SurveyResponseStore from './survey-response';
import WorkflowReportStore from './workflow-report';
import EventReportStore from './event-report';
import ReportStore from './report';
import MemberResponseRateStore from './member-response-rate';
import StreakStatStore from './streak-stats';
import AudienceFilterStore from './audience-filter';
import SchedulingStore from './scheduling';
import CsvStore from './csv';
import AudienceMemberWorkflowStore from './audience-member-workflow';
import AudienceMemberResponseStore from './audience-member-response';
import AudienceMemberSentEventStore from './audience-member-sent-event';
import AudienceMemberDueEventStore from './audience-member-due-event';
import AudienceMemberSetStore from './audience-member-set';
import EventCreationStore from './event-creation';
import ScheduleStore from './schedule';
import AtsAuthStore from './ats-auth';
import BranchStore from './branch';

import RequestHeaderStore from './request-header';
import Cache from './cache';
import RequestStore from './request';


export default class IndexStore extends EventEmitter {
  silenceUpdates = false;

  stores: {
    me: MeStore,
    workflows: WorkflowStore,
    audienceMembers: AudienceMemberStore,
    survey: SurveyStore,
    events: EventStore,
    eventTemplates: EventTemplateStore,
    responders: ResponderStore,
    sentEvents: SentEventStore,
    surveyResponses: SurveyResponseStore,
    memberResponseRates: MemberResponseRateStore,
    streakStats: StreakStatStore,
    request: RequestStore,
    audienceFilters: AudienceFilterStore,
    scheduling: SchedulingStore,
    csv: CsvStore,
    audienceMemberWorkflows: AudienceMemberWorkflowStore,
    audienceMemberResponses: AudienceMemberResponseStore,
    audienceMemberSentEvents: AudienceMemberSentEventStore,
    audienceMemberDueEvents: AudienceMemberDueEventStore,
    audienceMemberSets: AudienceMemberSetStore,
    eventCreation: EventCreationStore,
    schedule: ScheduleStore,
    atsAuth: AtsAuthStore,
    branches: BranchStore,
  };

  me: MeStore;
  workflows: WorkflowStore;
  audienceMembers: AudienceMemberStore;
  survey: SurveyStore;
  events: EventStore;
  eventTemplates: EventTemplateStore;
  responders: ResponderStore;
  sentEvents: SentEventStore;
  surveyResponses: SurveyResponseStore;
  requestHeaders: RequestHeaderStore;
  memberResponseRates: MemberResponseRateStore;
  streakStats: StreakStatStore;
  request: RequestStore;
  cache: Cache;
  audienceFilters: AudienceFilterStore;
  scheduling: SchedulingStore;
  csv: CsvStore;
  audienceMemberWorkflows: AudienceMemberWorkflowStore;
  audienceMemberResponses: AudienceMemberResponseStore;
  audienceMemberSentEvents: AudienceMemberSentEventStore;
  audienceMemberDueEvents: AudienceMemberDueEventStore;
  audienceMemberSets: AudienceMemberSetStore;
  eventCreation: EventCreationStore;
  schedule: ScheduleStore;
  atsAuth: AtsAuthStore;
  branches: BranchStore;

  constructor() {
    super();

    this.stores = {
      me: new MeStore(),
      workflows: new WorkflowStore(),
      audienceMembers: new AudienceMemberStore(),
      survey: new SurveyStore(), // rename to 'surveys'
      events: new EventStore(),
      eventTemplates: new EventTemplateStore(),
      responders: new ResponderStore(),
      sentEvents: new SentEventStore(),
      surveyResponses: new SurveyResponseStore(),
      reports: new ReportStore(),
      workflowReports: new WorkflowReportStore(),
      eventReports: new EventReportStore(),
      memberResponseRates: new MemberResponseRateStore(),
      streakStats: new StreakStatStore(),
      audienceFilters: new AudienceFilterStore(),
      scheduling: new SchedulingStore(),
      csv: new CsvStore(),
      audienceMemberWorkflows: new AudienceMemberWorkflowStore(),
      audienceMemberResponses: new AudienceMemberResponseStore(),
      audienceMemberSentEvents: new AudienceMemberSentEventStore(),
      audienceMemberDueEvents: new AudienceMemberDueEventStore(),
      audienceMemberSets: new AudienceMemberSetStore(),
      eventCreation: new EventCreationStore(),
      schedule: new ScheduleStore(),
      atsAuth: new AtsAuthStore(),
      branches: new BranchStore(),

      request: new RequestStore(),
      requestHeaders: new RequestHeaderStore(),
    };

    const storeKeys = {};
    forEach(this.stores, (store, name) => {
      if (storeKeys[store.key] != null) {
        throw new Error(
          'Store keys must be unique.\n' +
            `See constructor of '${name}'. Claims to be '${
              store.key
            }' but that key already registered by '${storeKeys[store.key]}'`,
        );
      }
      this[name] = store;
      storeKeys[store.key] = name;
    });

    this.cache = new Cache();
  }

  initialize(data, history) {
    forEach(this.stores, store => {
      store.initialize(data);
      store.on('update', () => {
        if (!this.silenceUpdates) {
          this.emit('update');
        }
        if (false) {
          // Set this to true when debugging in a development environment
          logger.log('the store data is now:', this.stores);
        }
      });
    });

    this.cache.initialize(data._cache);
    this.history = history;
  }

  serialize() {
    let data = reduce(
      this.stores,
      (state, store) => {
        const storeState = store.serialize();
        if (storeState) {
          state = sculpt(state, {$assign: storeState});
        }
        return state;
      },
      {},
    );

    data = sculpt(data, {
      _cache: {
        $set: this.cache.serialize(),
      },
    });

    return data;
  }
}
