// @flow strict

import * as React from 'react';

import classify from 'src/utils/classify';

import {Clickable} from 'src/designSystem2021Components/text-v2.jsx';

import css from './toggle.css';

/**
 * Note:
 * Do not wrap Toggle in a label. For simplicity's sake, Toggle uses an
 * internal label tag to handle click delegation to the hidden input, and
 * nesting labels is a bad idea.
 */

type Props = {
  className?: string,
  toggleContainer?: string,
  toggleClassName?: string,
  toggleLabelClassName?: string,
  children?: React.Node,
  checked?: boolean,
  onChange?: (SyntheticInputEvent<HTMLInputElement>) => void,
  disabled?: boolean,
};

export const Toggle = ({
  className,
  toggleContainer,
  toggleClassName,
  toggleLabelClassName,
  children,
  disabled,
  checked,
  ...props
}: Props): React.Node => (
  <label
    className={classify(
      toggleContainer || css.container,
      {
        [css.toggleContainer]: checked && !disabled,
        [css.uncheckedContainer]: !checked && !disabled,
        [css.disabledContainer]: disabled,
      },
      className,
    )}
  >
    <input disabled={disabled} type="checkbox" checked={checked} {...props} />
    <div
      className={classify(toggleClassName || css.toggle, {
        [css.disabled]: disabled,
      })}
    />
    {React.Children.count(children) > 0 && (
      <Clickable className={classify(css.toggleLabel, toggleLabelClassName)}>
        {children}
      </Clickable>
    )}
  </label>
);

export default Toggle;
