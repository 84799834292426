// @noflow

import * as React from 'react';

import CircledPlusSignFilled from 'src/images/circled-plus-sign-filled.svg?noAttrs';
import {classify} from 'src/utils';

import css from './index.css';


type Props = {
  className?: string,
  onClick?: () => void,
  text: string,
  style?: *,
  filled?: boolean,
};

const CircledPlusSignButton = (
  {text, onClick, className, style, filled, ...props}: Props,
  ref,
) => (
  <button
    className={classify(
      filled ? css.filledAddButton : css.textAddButton,
      className,
    )}
    onClick={onClick}
    style={style}
    type="button"
    {...props}
    ref={ref}
  >
    <span className={css.flexWrapper}>
      <CircledPlusSignFilled className={css.plusSign} />
      <span className={css.sansLabel}>{text}</span>
    </span>
  </button>
);

export default React.forwardRef(CircledPlusSignButton);
