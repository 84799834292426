// @flow strict

export type TabErrorAction = {
  type: 'error_in_tabs',
  payload: {
    urls: Array<string>,
  },
};

export type RemoveTabErrorAction = {
  type: 'remove_error_in_tabs',
  payload: {
    url: string,
  },
};

export type RemoveTabErrorsAction = {
  type: 'remove_errors_in_tabs',
  payload: {
    urls: Array<string>,
  },
};

export type ErrorAction =
  | TabErrorAction
  | RemoveTabErrorAction
  | RemoveTabErrorsAction;

export const ERROR_IN_TABS = 'error_in_tabs';
export const REMOVE_ERROR_IN_TABS = 'remove_error_in_tabs';
export const REMOVE_ERRORS_IN_TABS = 'remove_errors_in_tabs';

export const throwTabError = (urls: Array<string>): TabErrorAction => ({
  type: ERROR_IN_TABS,
  payload: {
    urls,
  },
});

export const removeTabError = (url: string): RemoveTabErrorAction => ({
  type: REMOVE_ERROR_IN_TABS,
  payload: {
    url,
  },
});

export const removeAllEventErrors = (): RemoveTabErrorsAction => ({
  type: REMOVE_ERRORS_IN_TABS,
  payload: {
    urls: ['basics', 'scheduling', 'content'],
  },
});
