// @flow

import {useContext} from 'react';

import {IndexStore} from 'src/stores';
import {FluxContext} from './root.jsx';


export default function useFluxStore(): typeof IndexStore {
  const {store} = useContext(FluxContext);
  return store;
}
