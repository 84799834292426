// @flow

import * as React from 'react';

import Loading from 'src/components/lib/loading/loading.jsx';

import css from './image-input.css';

import {backgroundImage} from 'src/utils/index';


type Props = {
  src: ?string,
  loading?: ?boolean,
  onDelete: () => mixed,
  onChange: (event: SyntheticInputEvent<HTMLInputElement>) => mixed,
  uploadHint?: React.Node,
};

const ImageInput = ({
  src,
  loading,
  onDelete,
  onChange,
  uploadHint = '+ Upload File',
}: Props): React.Element<'div'> | React.Element<'label'> =>
  src ? (
    <div className={css.logoContainer}>
      <div className={css.logoPreview} style={backgroundImage(src)}></div>
      <button className={css.logoDelete} type="button" onClick={onDelete}>
        Delete
      </button>
    </div>
  ) : (
    <label>
      <div className={css.logoPreviewBlank}>
        {loading ? <Loading /> : <div>{uploadHint}</div>}
      </div>
      <input className={css.logoUploadInput} type="file" onChange={onChange} />
    </label>
  );

export default ImageInput;
