// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {TargetEntities} from 'src/types/target-entity-types';

// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching, cached} from 'src/utils/redux';

// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';


export const RECEIVE_TARGET_ENTITY_TYPES = 'target-entity-types/receive';

type ReceiveTargetEntityTypesAction = {
  type: 'target-entity-types/receive',
  payload: TargetEntities,
};

export type TargetEntityTypeAction = ReceiveTargetEntityTypesAction;

export const receiveTargetEntityTypes = (
  payload: TargetEntities,
): ReceiveTargetEntityTypesAction => ({
  type: RECEIVE_TARGET_ENTITY_TYPES,
  payload,
});

export const getTargetEntityTypes: () => ThunkAction<number> = flow(
  key(() => 'target-entity-types'),
  cached((targetEntityTypes) => receiveTargetEntityTypes(targetEntityTypes), {
    ttl: 60 * 1000,
  }),
  fetching(),
)(() => (dispatch) => dispatch(reduxApi.get(`entity_type`)));
