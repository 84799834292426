// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Agency} from 'src/types/agency';

import type {AuthenticationActions} from 'src/action-creators/authentication';
import type {CredsAuthentication} from 'src/types/authentication';

import {
  CREDS_AUTHENTICATION,
  CREDS_AUTHENTICATION_ERROR,
  CREDS_AUTHENTICATED,
  NEW_PHONE_REGISTRATION,
  OTP_VERIFICATION_ERROR,
  NEW_PHONE_REGISTRATION_ERROR,
  RESEND_OTP_ERROR,
  RESET_PHONE_ERROR,
  RESET_PHONE_APPROVE_ERROR,
  RESET_PHONE_APPROVED,
  VALID_AGENCIES,
  VALID_AGENCIES_ERROR,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_ERROR,
  SIGNUP_ERROR,
} from 'src/action-creators/authentication';


export const AuthenticationStatus = {
  PENDING: 'pending',
  AUTHENTICATING: 'authenticating',
  AUTHENTICATED: 'authenticated',
  ERROR: 'error',
};

export type AuthenticationState = {
  loginId: ?string,
  credAuthenticationStatus: string,
  authenticationData: ?CredsAuthentication,
  newPhone: ?string,
  signInError: ?string,
  newPhoneRegistrationError: ?string,
  otpVerificationError: ?string,
  resendOTPError: ?string,
  resetPhoneError: ?string,
  resetPhoneApproveError: ?string,
  resetPhoneApproved: ?string,
  validAgencies: ?(Agency[]),
  forgotPasswordError: ?string,
  resetPasswordError: ?string,
  signUpError: ?string,
  otpLimitExceeded: ?boolean,
};

const initialState = {
  loginId: null,
  credAuthenticationStatus: AuthenticationStatus.PENDING,
  authenticationData: null,
  newPhone: null,
  signInError: null,
  otpVerificationError: null,
  resendOTPError: null,
  resetPhoneError: null,
  resetPhoneApproveError: null,
  resetPhoneApproved: null,
  newPhoneRegistrationError: null,
  validAgencies: null,
  forgotPasswordError: null,
  resetPasswordError: null,
  signUpError: null,
  otpLimitExceeded: false,
};

export default (
  state: AuthenticationState = initialState,
  action: AuthenticationActions,
): AuthenticationState => {
  switch (action.type) {
    case CREDS_AUTHENTICATION:
      return {
        ...state,
        credAuthenticationStatus: AuthenticationStatus.AUTHENTICATING,
        authenticationData: null,
      };
    case CREDS_AUTHENTICATION_ERROR:
      return {
        ...state,
        credAuthenticationStatus: AuthenticationStatus.ERROR,
        authenticationData: null,
        signInError: action.payload,
      };
    case CREDS_AUTHENTICATED:
      return {
        ...state,
        credAuthenticationStatus: AuthenticationStatus.AUTHENTICATED,
        authenticationData: action.payload,
        signInError: null,
        otpVerificationError: null,
        resendOTPError: null,
        resetPhoneError: null,
        resetPhoneApproveError: null,
        newPhoneRegistrationError: null,
        forgotPasswordError: null,
        resetPasswordError: null,
        signUpError: null,
      };
    case NEW_PHONE_REGISTRATION:
      return {
        ...state,
        newPhone: action.payload,
      };
    case OTP_VERIFICATION_ERROR:
      return {
        ...state,
        otpVerificationError: action.payload.error,
        otpLimitExceeded:
          action.payload.status_code === 429 ||
          action.payload.status_code === 403,
      };
    case NEW_PHONE_REGISTRATION_ERROR:
      return {
        ...state,
        newPhoneRegistrationError: action.payload,
      };
    case RESEND_OTP_ERROR:
      return {
        ...state,
        resendOTPError: action.payload.error,
        otpLimitExceeded:
          action.payload.status_code === 429 ||
          action.payload.status_code === 403,
      };
    case RESET_PHONE_ERROR:
      return {
        ...state,
        resetPhoneError: action.payload,
      };
    case RESET_PHONE_APPROVE_ERROR:
      return {
        ...state,
        resetPhoneApproveError: action.payload,
      };
    case RESET_PHONE_APPROVED:
      return {
        ...state,
        resetPhoneApproved: action.payload,
      };
    case VALID_AGENCIES:
      return {
        ...state,
        credAuthenticationStatus: AuthenticationStatus.AUTHENTICATED,
        signInError: null,
        validAgencies: action.payload,
      };
    case VALID_AGENCIES_ERROR:
      return {
        ...state,
        signInError: action.payload,
        credAuthenticationStatus: AuthenticationStatus.ERROR,
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPasswordError: action.payload,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordError: action.payload,
      };
    case SIGNUP_ERROR:
      return {
        ...state,
        signUpError: action.payload,
      };
    default:
      return state;
  }
};
