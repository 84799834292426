// @flow

// analytics global is defined by script snippet included in main app html file (app.pug)

import exenv from 'exenv';
import invariant from 'invariant';


const check = (methodName) => {
  invariant(
    exenv.canUseDOM,
    `Cannot call tracking/analytics method ${methodName} outside browser environment`,
  );
  return Boolean(window.analytics);
};

const _analytics = {
  identify(...args: any) {
    if (check('identity')) {
      window.analytics.identify(...args);
    }
  },

  track(...args: any) {
    if (check('track')) {
      window.analytics.track(...args);
    }
  },
};

export default _analytics;
