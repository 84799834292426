var React = require('react');

function AudienceIcon (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M11.302 4.875a3.71 3.71 0 10-7.419 0 3.71 3.71 0 007.419 0zm1.262 17.318c.68 0 1.632-1.773 1.632-2.965v-3.97a4.83 4.83 0 00-4.831-4.83H5.997a4.83 4.83 0 00-4.83 4.83v3.97c0 1.192.95 2.965 1.631 2.965h9.766z","fillRule":"evenodd"}));
}

AudienceIcon.defaultProps = {"width":"15","height":"23","viewBox":"0 0 15 23"};

module.exports = AudienceIcon;

AudienceIcon.default = AudienceIcon;
