// @flow strict-local

import type {DynamicLabelData} from './types';

import fromPairs from 'lodash/fromPairs';
import mapValues from 'lodash/mapValues';
import {EditorState} from 'draft-js';


export const ENITITY_DYNAMIC_LABEL = 'DYNAMIC_LABEL';
export const ENITITY_PLACEHOLDER = 'PLACEHOLDER';

export const dynamicRegex: RegExp = /<+([^>]+)>+|\{\{([^}]+)\}\}/g;
export const escapedRegex: RegExp = /^<{2,}/g;

export const dynamicRegexParenthesis: RegExp = /\{\{(.*?)\}\}/g;
export const escapedRegexParenthesis: RegExp = /^{{{3,}}/g;
/**
 * Parse a dynamic label string & get an object with its label & any extra attrs
 * @param  {string}            a dynamic-label-like string like
 *                               - '<foo>'
 *                               - '<foo|fallback: `bar`>',
 *                               - '<foo|extra:"bar"|fallback:"'baz'">'
 * @return {DynamicLabelData}  an object that tells us about the label with all
 *                                piped attributes in the `attrs` obj.
 */

export const dynamicLabelText = (match: string): DynamicLabelData => {
  const dynamicField = match.replace(/[<{>}]*/g, '').split('|');
  const [labelText, ...rest] = dynamicField;

  const pairs = rest.map((attr) =>
    // $FlowFixMe[invalid-tuple-arity]
    attr.split(':').map((keyOrVal) => keyOrVal.trim()),
  );

  // $FlowFixMe[invalid-tuple-arity]
  const quotedAttrs = fromPairs(pairs);
  // remove all the outer quote from the attribute value with slice
  const attrs = mapValues(quotedAttrs, (value) =>
    typeof value === 'string' ? value.slice(1, -1) : value,
  );

  return {
    label: labelText.trim(),
    attrs,
  };
};

/**
 * The function returns the range of given entity inside the block.
 * {
 *   anchorOffset: undefined,
 *   focusOffset: undefined,
 *   text: undefined,
 * }
 */
export function getEntityRange(
  editorState: EditorState,
  entityKey: string,
): Array<number> {
  const anchorKey = editorState.getSelection().getAnchorKey();
  const block = editorState.getCurrentContent().getBlockForKey(anchorKey);
  let entityRange = [0, 0];
  block.findEntityRanges(
    (value) => value.get('entity') === entityKey,
    //$FlowFixMe[incompatible-call]
    (start, end) => (entityRange = [start, end]),
  );
  return entityRange;
}
