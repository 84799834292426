// @flow strict

/**
 * throttleAnimation
 * only runs the wrapped function once per animation frame.
 * useful for responding to high frequency browser events
 *
 * TODO React17 (kyle): reevaluate this function upon the release of react 17.
 * possible that async setState() will need to be explicitly flushed.
 */
export default function throttleAnimation<F: (...params: mixed[]) => void>(
  fn: F,
): (...params: mixed[]) => void {
  let rafId = null;
  let savedParams = [];
  return function (...params): void {
    savedParams = params;
    if (!rafId) {
      rafId = requestAnimationFrame(() => {
        rafId = null;
        fn.apply(this, savedParams);
        savedParams = [];
      });
    }
  };
}
