// @flow strict
import * as React from 'react';
import {type ProgramJobsPayload} from 'src/types/referral-v2';
import {useDispatch} from 'react-redux';
import {updateJobData} from 'src/action-creators/referral-v2/referral-jobs.js';
//$FlowFixMe
import {popModal, TitleBarDetailsModal} from 'src/action-creators/modal.js';

import {
  BULK_ACTION_MENU as menuItem,
  INPUT_TEXT_LIMIT,
} from 'src/utils/referral-v2.js';

import {AnalyticsService} from 'src/analytics';
import InfoIcon from 'src/images/i.svg?noAttrs';
import CloseIcon from 'src/images/designSystems2021/modal-close-icon.svg';
import {Button} from 'src/designSystem2021Components/buttons.jsx';
import {Input} from '@spaced-out/ui-design-system/lib/components/Input';
import {Header} from 'src/designSystem2021Components/text-v2.jsx';
import BulkLinkJobs from './bulk-link-jobs.jsx';
import bonusCss from './bonus-validity.css';


type BulkActionModalProps = {
  label: string,
  selectedItemsInPage: string[],
  programId: string,
  isAllSelected: boolean,
  totalJobRecords: number,
  pageNumber: number,
  extras?: $Shape<TitleBarDetailsModal>,
};

const BulkActionModal = ({
  label: labelValue,
  selectedItemsInPage,
  programId,
  isAllSelected,
  totalJobRecords,
  pageNumber,
  extras,
}: BulkActionModalProps): React.Node => {
  const [value, setValue] = React.useState('');
  const [isSubmiting, setIsSubmiting] = React.useState(false);

  const dispatch = useDispatch();

  const menu = menuItem.find((menu) => menu.value === labelValue);

  const handleSubmit = async () => {
    const payload = {
      job_id: selectedItemsInPage,
      [labelValue]: value,
      applies_to_all: isAllSelected,
    };
    const jobFilters: ProgramJobsPayload = {
      limit: 10,
      offset: pageNumber,
    };
    try {
      setIsSubmiting(true);
      await dispatch(
        updateJobData({
          payload,
          programId,
          jobFilters,
        }),
      );
      setIsSubmiting(false);
      dispatch(popModal());
      AnalyticsService.track('Bulk Action Clicked');
    } catch (ex) {
    } finally {
      setIsSubmiting(false);
    }
  };

  if (menu?.value === 'link_jobs') {
    return (
      <BulkLinkJobs
        selectedItemsInPage={selectedItemsInPage}
        isAllSelected={isAllSelected}
        programFilterId={programId}
        totalJobRecords={totalJobRecords}
        pageNumber={pageNumber}
      />
    );
  }

  return (
    <div className={bonusCss.modalBackground}>
      <div className={bonusCss.modalHeader}>
        <div className={bonusCss.filterContent}>
          <InfoIcon />
          <Header className={bonusCss.headerText}>
            {extras?.title ?? 'Bulk Action'}
          </Header>
        </div>
        <div onClick={() => dispatch(popModal())}>
          <CloseIcon className={bonusCss.pointer} />
        </div>
      </div>
      <div className={bonusCss.modalContent}>
        <label className={bonusCss.modalLabel}>{menu?.label}:</label>
        <Input
          value={value}
          type="number"
          onChange={(e) => {
            if (e.target.value.length <= INPUT_TEXT_LIMIT.number) {
              setValue(parseInt(e.target.value, 0).toString());
            }
          }}
          maxLength="6"
        />
      </div>
      <div className={bonusCss.bottomContainer}>
        <div className={bonusCss.buttonContainer}>
          <Button type="tertiary" onClick={() => dispatch(popModal())}>
            Cancel
          </Button>
          <Button
            disabled={!value || parseInt(value, 0) < 1 || isSubmiting}
            type="primary"
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BulkActionModal;
