// @noflow
import type {
  SankeyGraph,
  SankeyGraphVisits,
  PeopleEngagedResponse,
  PresetQuestion,
} from 'src/types/chatbot';

import type {
  ChatbotActions,
  ExternalChatbotVariable,
} from 'src/action-creators/chatbot';

import {
  SANKEY_DATA,
  SANKEY_VISITS_DATA,
  PEOPLE_ENGAGED_DATA,
  PEOPLE_ENGAGED_LOADING_DATA,
  SANKEY_GRAPH_LOADING_DATA,
  RECEIVE_QUESTION_SIMILARITY,
  RECEIVE_PRESET_QUESTIONS,
  RECEIVE_T2A_KEYWORDS,
  RECEIVE_EXTERNAL_VARS,
} from 'src/action-creators/chatbot';


export type State = {
  sankeyGraph: SankeyGraph[],
  sankeyGraphVisits: SankeyGraphVisits[],
  peopleEngaged: PeopleEngagedResponse[],
  isPeopleEngagedLoading: boolean,
  isSankeyGraphLoading: boolean,
  questionSimilarity: {[key: string]: string[]},
  presetQuestions: PresetQuestion[],
  t2aKeywords: {[key: string]: number[]},
  externalVariables: ExternalChatbotVariable[],
};

const initialState = {
  sankeyGraph: [],
  sankeyGraphVisits: [],
  peopleEngaged: [],
  isPeopleEngagedLoading: false,
  isSankeyGraphLoading: false,
  questionSimilarity: {},
  presetQuestions: [],
  t2aKeywords: {},
  externalVariables: [],
};

export default (state: State = initialState, action: ChatbotActions): State => {
  switch (action.type) {
    case SANKEY_DATA:
      return {
        ...state,
        sankeyGraph: action.payload,
      };
    case SANKEY_VISITS_DATA:
      return {
        ...state,
        sankeyGraphVisits: action.payload,
      };
    case PEOPLE_ENGAGED_DATA:
      return {
        ...state,
        // $FlowIssue object values
        peopleEngaged: action.payload,
      };
    case PEOPLE_ENGAGED_LOADING_DATA:
      return {
        ...state,
        // $FlowIssue object values
        isPeopleEngagedLoading: action.payload,
      };
    case SANKEY_GRAPH_LOADING_DATA:
      return {
        ...state,
        // $FlowIssue object values
        isSankeyGraphLoading: action.payload,
      };
    case RECEIVE_QUESTION_SIMILARITY:
      return {
        ...state,
        questionSimilarity: {...state.questionSimilarity, ...action.payload},
      };
    case RECEIVE_EXTERNAL_VARS:
      return {
        ...state,
        externalVariables: action.payload,
      };
    case RECEIVE_PRESET_QUESTIONS:
      return {
        ...state,
        presetQuestions: action.payload,
      };
    case RECEIVE_T2A_KEYWORDS:
      return {
        ...state,
        t2aKeywords: action.payload,
      };
  }
  return state;
};
