// @flow strict
import type {ReferralProgramUsersActions} from 'src/action-creators/referral-v2/referral-program-user.js';
import type {
  BulkAddStatus,
  GenericError,
  ProgramUsersResponse,
} from 'src/types/referral-v2';

import {
  ENROLLED_PROGRAM_USERS,
  ENROLLED_PROGRAM_USERS_ERROR,
  REFERRAL_ADD_BULK_STATUS_ADMIN_EDIT,
  REFERRAL_ADD_BULK_STATUS_ADMIN_VIEW,
  REFERRAL_ADD_BULK_STATUS_VIEWER_VIEW,
  REFERRAL_PROGRAM_USER,
  REFERRAL_PROGRAM_USER_ERROR,
  REFERRAL_PROGRAM_USER_LOADING,
} from 'src/action-creators/referral-v2/referral-program-user.js';

export const ReferralCurrentState = {
  IDLE: 'idle',
  FETCHING_REFERRAL_PROGRAM_USER: 'fetchingReferralProgramUsers',
};

export type ReferralProgramUsersState = {
  current_state: string,
  referral_program_user: ?ProgramUsersResponse,
  referral_program_user_error: ?GenericError,
  enrolled_program_users: ?ProgramUsersResponse,
  enrolled_program_users_error: ?GenericError,
  bulk_add_viewer_as_view: BulkAddStatus,
  bulk_add_admin_as_view: BulkAddStatus,
  bulk_add_admin_as_edit: BulkAddStatus,
};

const initialState = {
  enrolled_program_users: null,
  enrolled_program_users_error: null,
  current_state: '',
  referral_program_user: null,
  referral_program_user_error: null,
  bulk_add_viewer_as_view: null,
  bulk_add_admin_as_view: null,
  bulk_add_admin_as_edit: null,
};

export default (
  state: ReferralProgramUsersState = initialState,
  action: ReferralProgramUsersActions,
): ReferralProgramUsersState => {
  switch (action.type) {
    case REFERRAL_PROGRAM_USER: {
      return {
        ...state,
        referral_program_user: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };
    }
    case REFERRAL_PROGRAM_USER_LOADING:
      return {
        ...state,
        current_state: ReferralCurrentState.FETCHING_REFERRAL_PROGRAM_USER,
      };

    case REFERRAL_PROGRAM_USER_ERROR:
      return {
        ...state,
        referral_program_user_error: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };
    case ENROLLED_PROGRAM_USERS:
      return {
        ...state,
        enrolled_program_users_error: null,
        enrolled_program_users: action.payload,
      };
    case ENROLLED_PROGRAM_USERS_ERROR:
      return {
        ...state,
        enrolled_program_users_error: action.payload,
      };

    case REFERRAL_ADD_BULK_STATUS_ADMIN_VIEW:
      return {
        ...state,
        bulk_add_admin_as_view: action.payload,
      };
    case REFERRAL_ADD_BULK_STATUS_ADMIN_EDIT:
      return {
        ...state,
        bulk_add_admin_as_edit: action.payload,
      };
    case REFERRAL_ADD_BULK_STATUS_VIEWER_VIEW:
      return {
        ...state,
        bulk_add_viewer_as_view: action.payload,
      };

    default:
      return state;
  }
};
