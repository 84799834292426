//@flow strict

import * as React from 'react';
import {useHVBroadcasts} from 'src/components/messaging/hooks/useHVBroadcasts.js';
import {classify} from 'src/utils/classify';
import css from './warning-message.css';

import {
  // $FlowFixMe[nonstrict-import]
  setPunctuationWarningHoverEvent,
  // $FlowFixMe[nonstrict-import]
  setContentWarningClickInfo,
} from 'src/action-creators/messages.js';

import {useSelector, useDispatch} from 'react-redux';


type Props = {
  children: React.Node,
  type: string,
  start: number,
  end: number,
};

const WarningMessage = ({children, type, start, end}: Props): React.Node => {
  const contentBannerCardType = useSelector(
    (state) => state.messages.contentBannerCardType,
  );
  const contentBannerCardValue = useSelector(
    (state) => state.messages.contentBannerCardValue,
  );
  const {isHVBroadcastLocation} = useHVBroadcasts();

  const dispatch = useDispatch();
  const onMouseEnter = () => {
    dispatch(setPunctuationWarningHoverEvent(type, true));
  };

  const onMouseLeave = () => {
    dispatch(setPunctuationWarningHoverEvent(type, false));
  };

  const onClick = () => {
    if (isHVBroadcastLocation) {
      dispatch(setContentWarningClickInfo(type, start, end));
    }
  };
  return (
    <span
      className={classify(css.text, {
        [css.hover]: contentBannerCardType === type && contentBannerCardValue,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export default WarningMessage;
