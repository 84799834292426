// @flow strict-local

import type {Router, RouteLocation} from 'src/types/router';

import * as React from 'react';

import classify from 'src/utils/classify';

import {FilterCard} from './';
import Checkbox from 'src/components/lib/checkbox';

import css from './result-card.css';


type Props = {
  onSave?: (e: SyntheticEvent<>) => mixed,
  onCancel?: (e: SyntheticEvent<>) => mixed,
  onChange: (e: SyntheticEvent<HTMLInputElement>) => mixed,
  selectedTypes: string[],
  eventTypeCounts?: ?{[key: string]: number},
  eventTypes?: {value: string, label: string}[],
  history?: Router,
  location?: RouteLocation,
};

const ResultsConverted = [
  {
    key: 'unsubscribed',
    label: 'Unsubscribed',
    color: css.colorError,
  },
  {
    key: 'spam_reported',
    label: 'Spam reported',
    color: css.colorError,
  },
  {
    key: 'unopened',
    label: 'Unopened / No response',
    color: css.colorError,
  },
];

const ResultsPartiallyConverted = [
  {
    key: 'opened',
    label: 'Opened',
    color: css.colorWarning,
  },
  {
    key: 'started',
    label: 'Started',
    color: css.colorWarning,
  },
  {
    key: 'partial',
    label: 'Partial',
    color: css.colorWarning,
  },
];

const ResultsNotConverted = [
  {
    key: 'completed',
    label: 'Completed',
  },
  {
    key: 'clicked',
    label: 'Clicked',
  },
  {
    key: 'opened_no_link',
    label: 'Opened (emails w/o links)',
  },
  {
    key: 'replied',
    label: 'Replied',
  },
];

const MessageTypeCardV2 = ({
  onSave,
  onCancel,
  onChange,
  selectedTypes,
}: Props): React.Node => (
  <FilterCard onSave={onSave} onCancel={onCancel}>
    <div className={css.container}>
      <div className={css.header}>not converted</div>
      <ul className={css.checklist}>
        {ResultsConverted.map(({key, label, color}) => (
          <li key={key}>
            <label
              className={classify(css.label, {
                [css.labelChecked]: selectedTypes.includes(key),
              })}
              style={{color}}
            >
              <Checkbox
                className={css.checkbox}
                name={key}
                checked={selectedTypes.includes(key)}
                onChange={onChange}
              />
              {label}
            </label>
          </li>
        ))}
      </ul>
      <div className={css.header}>partially converted</div>
      <ul className={css.checklist}>
        {ResultsPartiallyConverted.map(({key, label, color}) => (
          <li key={key}>
            <label
              className={classify(css.label, css.success, {
                [css.labelChecked]: selectedTypes.includes(key),
              })}
              style={{color}}
            >
              <Checkbox
                className={css.checkbox}
                name={key}
                checked={selectedTypes.includes(key)}
                onChange={onChange}
              />
              {label}
            </label>
          </li>
        ))}
      </ul>
      <div className={css.header}>converted</div>
      <ul className={css.checklist}>
        {ResultsNotConverted.map(({key, label}) => (
          <li key={key}>
            <label
              className={classify(css.label, css.success, {
                [css.labelChecked]: selectedTypes.includes(key),
              })}
            >
              <Checkbox
                className={css.checkbox}
                name={key}
                checked={selectedTypes.includes(key)}
                onChange={onChange}
              />
              {label}
            </label>
          </li>
        ))}
      </ul>
      <div className={css.footer}>
        A single message can have up to three conversion types, but only one is
        shown in the conversion column. For example, a recipient can
        unsubscribe, reports spam, and click on the same message.
      </div>
    </div>
  </FilterCard>
);

export default MessageTypeCardV2;

export const SendWasSkippedCard = (): React.Node => (
  <FilterCard header="to enable filter" className={css.skippedContainer}>
    <div className={css.message}>
      <p>Set "Sent or Skipped?" to Sent</p>
    </div>
  </FilterCard>
);

export const DeliverySetToBouncedCard = (): React.Node => (
  <FilterCard header="to enable filter" className={css.skippedContainer}>
    <div className={css.message}>
      <p>Set "delivered?" to delivered</p>
    </div>
  </FilterCard>
);
