// @flow strict
import * as React from 'react';

import {
  JumboMedium,
  JumboSmall,
  JumboLarge,
  SubTitleLarge,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {CONSTANTS} from 'src/components/authentication/contants.js';

import MainLogo from 'src/images/sense-logo-new-white.svg';
import css from './container.css';


type ProductBrandingContent = {
  background_image?: string,
  body_text?: string,
  button_primary_link?: string,
  button_primary_text?: string,
  header_text?: string,
  sub_header_text_top?: string,
};

type ProductBrandingContainerProps = {
  content?: ProductBrandingContent,
};

const ProductBrandingContainer = ({
  content,
}: ProductBrandingContainerProps): React.Node => {
  const handleRedirection = () => {
    window.open(content?.button_primary_link, '_blank');
  };

  return (
    <div
      className={css.productBrandingSideBar}
      style={{
        '--background-image': content?.background_image
          ? `url(${content.background_image})`
          : 'initial',
      }}
    >
      <MainLogo className={css.brandingLogo} />
      {content ? (
        <div className={css.contentContainer}>
          {!!content.sub_header_text_top && (
            <div className={css.firstFoldText}>
              <JumboSmall color="inverseSecondary">
                {content.sub_header_text_top}
              </JumboSmall>
            </div>
          )}

          {!!content.header_text && (
            <div className={css.secondFoldText}>
              <JumboLarge color="inversePrimary">
                {content.header_text}
              </JumboLarge>
            </div>
          )}

          {!!content.body_text && (
            <div className={css.thirdFoldText}>
              <JumboSmall color="inversePrimary">
                {content.body_text}
              </JumboSmall>
            </div>
          )}

          {!!content.button_primary_text && !!content.button_primary_link && (
            <div className={css.actionContainer}>
              <Button onClick={handleRedirection}>
                {content.button_primary_text}
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className={css.productBrandingContainer}>
          <div className={css.productBranding}>
            <JumboMedium color="inversePrimary">
              {CONSTANTS.BRANDING_PAGE_HEADER}
            </JumboMedium>
            <div className={css.brandImage}></div>
            <SubTitleLarge
              color="inversePrimary"
              className={css.brandingSubText}
            >
              {CONSTANTS.BRANDING_PAGE_SUBTEXT}
            </SubTitleLarge>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductBrandingContainer;
