// @flow

import type {Params} from 'src/rerouter';
import type {RouteLocation} from 'src/types/router';


export const START = '@@route/start';
export const END = '@@route/end';

type StartPayload = {
  location: RouteLocation,
  params: Params,
};

type Start = {
  type: '@@route/start',
  payload: StartPayload,
};

type EndPayload = {
  location: RouteLocation,
  params: Params,
  status: number,
};

type End = {
  type: '@@route/end',
  payload: EndPayload,
};

export type RouteAction = Start | End;

export const startNavigation = (payload: StartPayload): Start => ({
  type: START,
  payload,
});

export const endNavigation = (payload: EndPayload): End => ({
  type: END,
  payload,
});
