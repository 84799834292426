// @noflow

import * as React from 'react';

import {Modifier, EditorState, SelectionState, convertFromRaw} from 'draft-js';
import {classify} from 'src/utils';
import {captureSentryException} from 'src/utils/sentry';

import Modal from './modal.jsx';

import css from './markdown-editor.css';
import CloseIcon from 'src/images/close-icon.svg';
import LinkIcon from 'src/images/editor/ic_insert_link_black_18px.svg';
import CenterAlignIcon from 'src/images/editor/align-center.svg';
import LeftAlignIcon from 'src/images/editor/align-left.svg';


type ImageRTEUploadProps = {
  handleClose: () => void,
  handleFormSubmit: (files: FileList) => void,
  confirmLink?: (data: Object) => void,
  showLink?: boolean,
  label?: string,
  fields?: any[],
  data?: {
    start: number,
    stop: number,
    word: string,
  },
};

export class ImageRTEUploadArea extends React.Component<ImageRTEUploadProps> {
  static defaultProps = {
    label: 'Link Destination: ',
    showLink: true,
  };

  form: HTMLFormElement;

  _handleForm(event: Event) {
    event.preventDefault();
    // $FlowIssue -- flow can't find this input (maybe find a better way?)
    this.props.handleFormSubmit(this.form.elements.fileUpload.files);
  }

  handleForm = this._handleForm.bind(this);

  componentDidMount() {
    this.form.elements[0].focus();
  }

  render() {
    return (
      <Modal handleClose={this.props.handleClose}>
        <span onClick={this.props.handleClose} className={css.closeButton}>
          <CloseIcon />
        </span>
        <form
          ref={(el) => {
            this.form = el;
          }}
          onSubmit={this.handleForm}
        >
          <div className={css.urlInputBox}>
            <div>
              <label className={css.urlFormRow}>
                <span>Upload File</span>
                <input
                  type="file"
                  accept="image/*"
                  placeholder="an image on your computer"
                  name="fileUpload"
                />
              </label>
            </div>
            <button className={css.submitButton}>Save</button>
          </div>
        </form>
      </Modal>
    );
  }
}

// an editor component can use this to remove a block by doing two things:
// removing the block's entity from the entity map and then by changing the
// block to be unstyled (effectively changes it to an empty line)
//
export const applyNull = (
  state: any,
  callback: (newState: any) => void,
  block: any,
) => {
  const blockKey = block.getKey();
  const entity = block.getEntityAt(0);

  block.findEntityRanges(
    (md) => md.getEntity() === entity,
    (start, stop) => {
      const sel = SelectionState.createEmpty(blockKey).merge({
        anchorOffset: start,
        focusOffset: stop,
      });

      const contentMinusEntity = Modifier.applyEntity(
        state.getCurrentContent(),
        sel,
        null,
      );
      const contentMinusBlock = Modifier.setBlockType(
        contentMinusEntity,
        sel,
        'unstyled',
      );

      const newEditorState = EditorState.push(
        state,
        contentMinusBlock,
        'remove-range',
      );
      callback(newEditorState);
    },
  );
};

type SOMediaProps = {
  block: any, // this is not actually an "any" type, it's a ContentBlock
  // but flow doesn't yet export its flowtypes well :(
  blockProps: Object,
  handleLink: () => {}, //
  removeImage: () => {},
  handleLeftAlign: () => void,
  handleCenterAlign: () => void,
  contentState: any,
};

export const SOMedia = ({block, blockProps, contentState}: SOMediaProps) => {
  const {removeImage, handleLink, handleLeftAlign, handleCenterAlign} =
    blockProps;

  const entityKey = block.getEntityAt(0);
  let entity;

  try {
    entity = contentState.getEntity(entityKey);
  } catch (err) {
    let serializedContentState;
    try {
      serializedContentState = convertFromRaw(contentState);
    } catch (convertError) {
      captureSentryException(err, {
        level: 'error',
        tags: {
          context: 'RTE',
          component: 'SOMedia',
          operation: 'convertFromRaw',
        },
      });
    }

    captureSentryException(err, {
      level: 'error',
      tags: {
        context: 'RTE',
        component: 'SOMedia',
        operation: 'render',
      },
      extra: {
        entityKey,
        contentState: serializedContentState,
      },
    });
    return null;
  }

  const {src, url} = entity.getData();
  const {alignment = ''} = blockProps;

  return (
    <div className={css.mediaBlock}>
      <div className={css.buttonBox}>
        <span
          className={classify(css.mediaButton, {
            [css.activeImageBlockButton]: alignment === undefined,
          })}
          onClick={() => {
            handleLeftAlign(block);
          }}
          title="Left-align this image"
        >
          <LeftAlignIcon className={css.linkIcon} />
        </span>
        <span
          className={classify(css.mediaButton, {
            [css.activeImageBlockButton]: alignment === 'center',
          })}
          onClick={() => {
            handleCenterAlign(block);
          }}
          title="Center-align this image"
        >
          <CenterAlignIcon className={css.linkIcon} />
        </span>

        <span
          className={css.mediaButton}
          onClick={() => {
            handleLink(entityKey);
          }}
          title="Add a link to this image"
        >
          <LinkIcon className={css.linkIcon} />
        </span>
        <span
          className={css.mediaButton}
          onClick={() => {
            removeImage(block);
          }}
          title="Remove this image"
        >
          <CloseIcon className={css.removeIcon} />
        </span>
      </div>
      <div className={`block-align-${alignment}`}>
        {url != null ? (
          <a href={url} target="_blank">
            <img className={css.mediaImg} src={src} />
          </a>
        ) : (
          <img className={css.mediaImg} src={src} />
        )}
      </div>
    </div>
  );
};

export const ImageBlock = ({
  alignment,
  handleLeftAlign,
  block,
  handleCenterAlign,
  handleLink,
  removeImage,
  entityKey,
  url,
  src,
}) => (
  <div className={css.mediaBlock}>
    <div className={css.buttonBox}>
      <span
        className={classify(css.mediaButton, {
          [css.activeImageBlockButton]: alignment === undefined,
        })}
        onClick={() => {
          handleLeftAlign(block);
        }}
        title="Left-align this image"
      >
        <LeftAlignIcon className={css.linkIcon} />
      </span>
      <span
        className={classify(css.mediaButton, {
          [css.activeImageBlockButton]: alignment === 'center',
        })}
        onClick={() => {
          handleCenterAlign(block);
        }}
        title="Center-align this image"
      >
        <CenterAlignIcon className={css.linkIcon} />
      </span>

      <span
        className={css.mediaButton}
        onClick={() => {
          handleLink(entityKey);
        }}
        title="Add a link to this image"
      >
        <LinkIcon className={css.linkIcon} />
      </span>
      <span
        className={css.mediaButton}
        onClick={() => {
          removeImage(block);
        }}
        title="Remove this image"
      >
        <CloseIcon className={css.removeIcon} />
      </span>
    </div>
    <div className={`block-align-${alignment}`}>
      {url != null ? (
        <a href={url} target="_blank">
          <img className={css.mediaImg} src={src} />
        </a>
      ) : (
        <img className={css.mediaImg} src={src} />
      )}
    </div>
  </div>
);
