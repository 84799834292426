// @flow

import type {EntityType} from 'src/types/ats-entities';
import type {ModuleAttributes, AttributeMetaData} from 'src/types/writeback';
import type {ReceiveAllowedAttributesAction} from 'src/action-creators/writeback';

import keyBy from 'lodash/keyBy';

import {RECEIVE_ALLOWED_ATTRIBUTES} from 'src/action-creators/writeback';


export type AllowedAttributes = {
  module_attributes: {
    [entityType: EntityType]: ModuleAttributes,
    ...
  },
  attribute_meta_data: {
    [string]: AttributeMetaData,
  },
};

const initialState = {
  module_attributes: {},
  attribute_meta_data: {},
};

export default function reducer(
  state: AllowedAttributes = initialState,
  action: ReceiveAllowedAttributesAction,
): AllowedAttributes {
  switch (action.type) {
    case RECEIVE_ALLOWED_ATTRIBUTES:
      const module_attributes = {...state.module_attributes};
      module_attributes[action.payload.entityType] =
        action.payload.data.module_attributes;

      return {
        ...state,
        attribute_meta_data: {
          ...state.attribute_meta_data,
          ...keyBy(action.payload.data.attribute_meta_data, 'attribute_name'),
        },
        module_attributes,
      };
    default:
      return state;
  }
}
