// @noflow

import React, {Children, cloneElement} from 'react';

import {classify} from 'src/utils';

import css from './action-list.css';


type Props = {
  className?: string,
  children: React.Element<any>,
};

const ActionList = ({className, children}: Props) => (
  <div className={classify(css.actions, className)}>
    {Children.map(children, child => (
      child &&
        <div className={css.action} key={child.props.key}>
          {cloneElement(child, {className: classify(css.actionButton, child.props.className)})}
        </div>
    ))}
  </div>
);

export default ActionList;
