// @flow

import type {ThunkAction} from 'src/reducers';
import type {EntityType} from 'src/types/ats-entities';
import type {WritebackAllowedAttributes} from 'src/types/writeback';

import {thunkify as flow} from 'src/utils/thunks';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api.js';


export const RECEIVE_ALLOWED_ATTRIBUTES = 'RECEIVE_ALLOWED_ATTRIBUTES';

export type ReceiveAllowedAttributesAction = {
  type: 'RECEIVE_ALLOWED_ATTRIBUTES',
  payload: {data: WritebackAllowedAttributes, entityType: EntityType},
};

export const receiveAllowedAttributes = (
  data: WritebackAllowedAttributes,
  entityType: EntityType,
): ReceiveAllowedAttributesAction => ({
  type: RECEIVE_ALLOWED_ATTRIBUTES,
  payload: {
    data,
    entityType,
  },
});

export const getAllowedAttributes: (EntityType) => ThunkAction<mixed> = flow(
  key((entityType) => `allowed-attributes:${entityType}`),
  cached((json, entityType) => receiveAllowedAttributes(json, entityType)),
  fetching(),
)((entityType) =>
  reduxApi.get(`field-writeback/allowed-attributes`, {
    entity_type: entityType,
    related_entities: true,
  }),
);
