// @noflow

import type {Dispatch} from 'src/reducers';
import type {NewWorkflow, Workflow, ApiWorkflow} from 'src/api-parsers/index';
import type {ScheduleDef, RemindersDef} from 'src/stores/scheduling';
import typeof IndexStore from 'src/stores/index';
import type {AudienceFilter} from 'src/types/audience-filter';
import type {BackgroundTask} from 'src/action-creators/background-task';

import omit from 'lodash/omit';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import flow from 'lodash/flow';
import isNil from 'lodash/isNil';
import sculpt from 'sculpt';
import logger from 'src/utils/logger';
// import invariant from 'invariant';

import {
  key,
  cachedWithDispatch,
  cached,
  fetching,
  suppressed,
  authorized,
} from 'src/utils/action';
import * as api from 'src/utils/api';
import {selectEnableDiscoverForEngage} from 'src/selectors/agency';
import {
  selectWorkflowActivationV2,
  selectAudienceFilterSeparation,
} from 'src/hooks/product-flags';
import parsers from 'src/api-parsers/index';
import eventParser from 'src/api-parsers/events';
import {startBackgroundTaskPolling} from 'src/action-creators/background-task';
import {DEFAULT_ZIPCODE_RADIUS} from 'src/components/lib/field-rule-set/zip-rule-helper';
import {ApiError} from 'src/utils/errors';
import {
  showGenericConfirm,
  showGenericError,
  showGenericAlert,
  showHandledApiError,
} from 'src/action-creators/modal';
import {
  receiveWorkflowActivationStatus,
  receiveWorkflowFilterStatus,
  getWorkflowActivationStatus,
  receiveWorkflow,
  receiveWorkflows,
  updateWorkflow as updateWorkflowAction,
  deleteWorkflow as deleteWorkflowAction,
} from 'src/action-creators/workflows';
import {
  isInProgress,
  getWorkflowActivationTask,
} from 'src/utils/background-task';


export const DEFAULT_LOCATION_OPERATOR = 'search';

/**
 * Persistant Actions
 */
export const createWorkflow = flow(
  key(() => 'create-workflow'),
  fetching(),
)(
  // eslint-disable-next-line prefer-arrow-callback
  function createWorkflow(
    store: IndexStore,
    workflow: NewWorkflow,
  ): Promise<Workflow> {
    store.workflows.startCreating();

    const normalizedWorkflow = parsers.normalize.createWorkflow(workflow);

    return api
      .post(store, 'workflows', normalizedWorkflow)
      .then((response) => {
        logger.log('workflow created', response);

        const parsedResponse = parsers.parse.workflow(response);
        store.workflows.receiveCreated(parsedResponse);
        store.reduxStore.dispatch(receiveWorkflow(parsedResponse));

        return parsedResponse;
      })
      .catch((error) => {
        logger.error('workflow createWorkflow error: ', error.stack || error);
        throw error;
      });
  },
);

export function copyWorkflow(
  store: IndexStore,
  workflowId: string,
  workflow: NewWorkflow,
): Promise<Workflow> {
  store.workflows.startCreating();

  const normalizedWorkflow = parsers.normalize.copyWorkflow(workflow);

  return api
    .post(store, `workflows/${workflowId}/copy`, normalizedWorkflow)
    .then((response) => {
      logger.log('workflow created', response);

      const parsedResponse = parsers.parse.workflow(response);
      store.workflows.receiveCreated(parsedResponse);
      store.reduxStore.dispatch(receiveWorkflow(parsedResponse));

      return parsedResponse;
    })
    .catch((error) => {
      logger.error('workflow copyWorkflow error: ', error.stack || error);
      throw error;
    });
}

const getWorkflowKey = (_dispatch, workflowId) => `getWorkflow ${workflowId}`;
export const resetCacheAndGetWorkflow = (
  store: IndexStore,
  dispatch: Dispatch,
  workflowId: string,
): Promise<Workflow> => {
  removeWorkflowsFromCache(store, [workflowId]);
  return getWorkflow(store, dispatch, workflowId);
};

export const getWorkflow = flow(
  key(getWorkflowKey),
  cachedWithDispatch({ttl: 60 * 1000}),
  fetching(),
  authorized(),
)(
  (
    store: IndexStore,
    dispatch: Dispatch,
    workflowId: string,
  ): Promise<Workflow> =>
    api.get(store, 'workflows/' + workflowId).then((response) => {
      let parsedResponse = parsers.parse.workflow(response);
      const appendJobMatchFields = selectEnableDiscoverForEngage(
        store.reduxStore.getState(),
      );
      forEach(parsedResponse.events, (event) => {
        store.events.setEvent(
          eventParser.parse.event(event, appendJobMatchFields),
        );
      });

      forEach(response['events'], (event) => {
        const parsedBranches = eventParser.parse.branches(event);
        if (parsedBranches) {
          store.branches.receiveBranches(parsedBranches);
        }
      });

      forEach(response['branched_events'], (event) =>
        store.events.setEvent(
          eventParser.parse.event(event, appendJobMatchFields),
        ),
      );

      const {backgroundActivationStatus, backgroundFilterStatus} =
        parsedResponse;
      const isWorkflowActivationV2Enabled = selectWorkflowActivationV2(
        store.reduxStore.getState(),
      );
      const isAudienceFilterSeparationEnabled = selectAudienceFilterSeparation(
        store.reduxStore.getState(),
      );
      // Not hitting the /activate/status API as it updates the background activation task status and sets it to inactive which we don't want
      if (!isWorkflowActivationV2Enabled) {
        dispatch(
          receiveWorkflowActivationStatus(
            parsedResponse.id,
            backgroundActivationStatus,
          ),
        );
      }
      if (!isAudienceFilterSeparationEnabled) {
        dispatch(
          receiveWorkflowFilterStatus(
            parsedResponse.id,
            backgroundFilterStatus,
          ),
        );
      }

      // NOTE (rng) - This is important to omit because we don't want to cache these background task statuses with
      // the workflow caching
      parsedResponse = omit(parsedResponse, [
        'backgroundActivationStatus',
        'backgroundFilterStatus',
      ]);

      store.workflows.update(parsedResponse);
      store.reduxStore.dispatch(
        updateWorkflowAction(workflowId, parsedResponse),
      );
      return parsedResponse;
    }),
);

export function getWorkflows(store: IndexStore): Promise<void> {
  const cacheKey = 'workflows';
  const cacheTtl = 120 * 1000;
  if (!store.cache.expired(cacheKey, cacheTtl)) {
    return Promise.resolve();
  }

  const {fetchingAll} = store.workflows.state;
  if (fetchingAll) {
    return fetchingAll;
  }

  const promise = api
    .get(store, 'workflows')
    .then((response) => {
      store.cache.set(cacheKey);

      response = parsers.parse.workflows(response).map((workflow) =>
        // branchedEventIds is always [] because load_externals in
        // the api call is false so instead of allowing a getWorkflows()
        // call to overwrite stored branchedEventIds, we'll just ignore
        // them. See also receiveWorkflows in action-creators/workflows.js
        omit(workflow, ['branchedEventIds']),
      );
      store.workflows.receiveAll(response);
      store.reduxStore.dispatch(receiveWorkflows(response));
    })
    .catch((error) => {
      logger.error('workflow getWorkflows error: ', error.stack || error);
    });

  store.workflows.startFetchingAll(promise);

  return promise;
}

export function unsafeUpdateWorkflow(
  store: IndexStore,
  workflow: Workflow,
): Promise<ApiWorkflow> {
  const {id} = workflow;
  const reduxState = store.reduxStore.getState();
  const {multiEntityEnabled} = reduxState.agency.config;
  const audienceFilterSeparation = selectAudienceFilterSeparation(reduxState);
  const apiWorkflow = multiEntityEnabled
    ? parsers.normalize.multiEntityWorkflow(workflow, audienceFilterSeparation)
    : parsers.normalize.workflow(workflow);

  // we'd like some things to happen immediately
  store.workflows.update(workflow);
  store.reduxStore.dispatch(updateWorkflowAction(id, workflow));

  return api
    .put(store, `workflows/${id}`, apiWorkflow)
    .then((response) => {
      logger.log('workflow updated');

      const parsedResponse = parsers.parse.workflow(response);
      store.workflows.update(parsedResponse);
      store.reduxStore.dispatch(updateWorkflowAction(id, parsedResponse));

      return response;
    })
    .catch((error) => {
      logger.error('workflow updateWorkflow error', error.stack || error);
      throw error;
    });
}

export function updateWorkflow(
  store: IndexStore,
  workflow: Workflow,
): Promise<ApiWorkflow> {
  // NOTE (rng) 7/13/16 - We are wrapping the update around the getWorkflows() call because we can have a condition
  // where there is an outstanding getWorkflows() request which would return after a updateWorkflow api request
  // and that would then overwrite the store state without the updated workflow.  This happens if the getWorkflows()
  // request takes a very long time for example while it is being fetched during the left nav rendering.
  return getWorkflows(store).then(() => unsafeUpdateWorkflow(store, workflow));
}

export const deleteWorkflow = flow(
  key(({id}) => `deleteWorkflow/${id}`),
  fetching(),
)(
  (
    store: IndexStore,
    workflow: Workflow,
    nextLocation: ?string,
  ): Promise<Workflow> => {
    const {id} = workflow;
    return api.del(store, `workflows/${id}`).then(async (response) => {
      logger.log(`workflow ${id} deleted`, response);
      if (nextLocation) {
        // push to the workflow page and immediately go
        // to the nextlocation so that if somebody goes 'back' they
        // actually go to the workflow listing page.
        //
        // in most cases we don't need to await these history operations
        // but we need to here because if we don't we'll delete the workflow
        // and cause a 404 because the route will try to load the deleted
        // workflow's resources because we're still at the route.
        await store.history.replace('/journey', {silent: true});
        await store.history.push(nextLocation);
      }
      store.workflows.delete(workflow);
      store.reduxStore.dispatch(deleteWorkflowAction(workflow));

      return response;
    });
  },
);

// eslint-disable-next-line max-params
export function confirmAndUpdateWorkflowActive(
  store: IndexStore,
  workflowId: string,
  active: boolean,
  activationTask: BackgroundTask,
  onError?: (e: Error) => mixed,
): Promise<void> {
  const {dispatch} = store.reduxStore;
  if (isInProgress(activationTask)) {
    return dispatch(
      showGenericConfirm({
        title: 'Cancel journey in the middle of activation?',
        text: 'Your journey is being activated. Would you like to cancel?',
        confirmText: 'Yes',
      }),
    ).then((yes) => yes && cancelWorkflowActivation(store, workflowId));
  }

  if (active) {
    const workflow = store.workflows.state.workflows[workflowId];
    const isEventEnabled = (eventId: string): boolean =>
      store.events.state.events[eventId].enabled;
    const numScheduledEvents = workflow.eventsSchedule.filter(
      (item) => item.schedule && isEventEnabled(item.id),
    ).length;

    if (numScheduledEvents > 0) {
      return dispatch(
        showGenericConfirm({
          title: 'Confirm Activation',
          text: `You are about to activate this journey, which will schedule ${numScheduledEvents} ${
            numScheduledEvents === 1 ? 'touchpoint' : 'touchpoints'
          }.
          Are you sure you want to activate this?`,
          confirmText: 'Yes',
        }),
      ).then(
        (yes) =>
          yes && updateWorkflowActive(store, workflowId, active, onError),
      );
    } else {
      return dispatch(
        showGenericError({
          title: 'Activation Error',
          text: `You cannot activate a journey with no scheduled touchpoints.
          Please schedule a touchpoint and then try activating again.`,
        }),
      );
    }
  }

  return updateWorkflowActive(store, workflowId, active, onError);
}

// NOTE (kyle): from a UX perspective, it is likely that you'll want to use
// confirmAndUpdateWorkflowActive() instead of this action.
export const updateWorkflowActive = flow(
  key((workflowId) => `activateWorkflow ${workflowId}`),
  fetching(),
  authorized(),
  suppressed(),
)(
  (
    store: IndexStore,
    workflowId: string,
    active: boolean,
    onError?: (e: Error) => mixed,
  ): Promise<void> => {
    const {dispatch} = store.reduxStore;
    store.cache.expire(getWorkflow.KEY(dispatch, workflowId));
    const promise = active
      ? activateWorkflow(store, workflowId)
      : dispatch(
          showGenericConfirm({
            title: 'Really deactivate journey?',
            text: 'Deactivating this journey will unschedule any follow up branched touchpoints.',
            confirmText: 'Yes, deactivate',
          }),
        ).then(
          (yes) =>
            yes &&
            api.post(store, `workflows/${workflowId}/deactivate`).then(() => {
              store.workflows.updateWorkflow(workflowId, {
                active: false,
              });
              store.reduxStore.dispatch(
                updateWorkflowAction(workflowId, {active: false}),
              );
            }),
        );

    return promise.catch((error) => {
      if (onError) {
        onError(error);
      }
      store.workflows.setError(error);
      getWorkflow(store, dispatch, workflowId);
    });
  },
);

export const updateBulkWritebackActivate = flow(
  key((workflowId) => `activateWorkflow ${workflowId}`),
  fetching(),
  authorized(),
  suppressed(),
)(
  (
    store: IndexStore,
    workflowId: string,
    workflowActive: boolean,
    onError?: (e: Error) => mixed,
  ): Promise<void> => {
    const {dispatch} = store.reduxStore;
    let promise;
    if (workflowActive) {
      promise = dispatch(
        showGenericConfirm({
          title: 'Really stop bulk database cleanup?',
          text: 'This will stop your database cleanup even though some records are remaining that haven’t been udpated. After several minutes, you will then have the option to re-start this cleanup.  When you re-start, the remaining records in your audience will finishing updating.  You’ll also have the option to include any records that were added to your database since you last started the cleanup, and that match these audience rules.',
          confirmText: 'Stop now',
        }),
      ).then(
        (yes) =>
          yes &&
          api.post(store, `workflows/${workflowId}/deactivate`).then(() => {
            store.workflows.updateWorkflow(workflowId, {
              active: false,
            });
            store.reduxStore.dispatch(
              updateWorkflowAction(workflowId, {active: false}),
            );
          }),
      );
    } else {
      promise = dispatch(
        showGenericConfirm({
          title: 'Really start bulk database cleanup?',
          text: 'Starting your database cleanup will permanently lock your audience rules and change rules, and no further edits to them will be possible. Records in your database will immediately start to be updated to match your change rules.  You can stop the cleanup at any time to prevent further changes. After stopping, you can re-start the cleanup. You’ll then have the option to include any records that were added to your database since you last started the cleanup.',
          confirmText: 'Start now',
        }),
      ).then((yes) => yes && activateWorkflow(store, workflowId));
    }

    return promise.catch((error) => {
      if (onError) {
        onError(error);
      }
    });
  },
);

export function activateWorkflow(
  store: IndexStore,
  workflowId: string,
): Promise<void> {
  const {dispatch, getState} = store.reduxStore;
  const isWorkflowActivationV2Enabled = selectWorkflowActivationV2(getState());
  return api
    .post(store, `workflows/${workflowId}/activate`)
    .then((response) => {
      // Not hitting the /activate/status API as it's no longer required if the /activate API gave success code
      if (!isWorkflowActivationV2Enabled) {
        dispatch(getWorkflowActivationStatus(store, workflowId));
      }
    })
    .then(() => {
      // Not polling Service Activation V2
      if (!isWorkflowActivationV2Enabled) {
        dispatch(
          startBackgroundTaskPolling(
            getWorkflowActivationTask(getState(), workflowId).key,
            () => {
              store.workflows.updateWorkflow(workflowId, {
                active: true,
                draft: false,
              });
              store.reduxStore.dispatch(
                updateWorkflowAction(workflowId, {active: true, draft: false}),
              );
            },
          ),
        );
      }
    })
    .then(() => {
      removeWorkflowsFromCache(store, [workflowId]);
      getWorkflow(store, dispatch, workflowId);
    });
}

// NOTE (kyle): because this action is only ever fired while activation is being processed,
// it need not update the actual status.
export function cancelWorkflowActivation(
  store: IndexStore,
  workflowId: string,
): Promise<void> {
  return api
    .post(store, `workflows/${workflowId}/activate/cancel`)
    .catch((error: Error) => {
      if (
        error instanceof ApiError &&
        error.responseBody.errors.includes('NotActivatingWorkflow')
      ) {
        store.reduxStore.dispatch(
          showGenericAlert({
            title: 'Failed to Cancel',
            text: 'It looks like this journey is no longer being activated. You can verify its status by refreshing the page. If this problem persists, please contact support.',
          }),
        );
      } else {
        store.reduxStore.dispatch(
          showHandledApiError({
            title: 'Failed to Cancel',
            text: 'We failed to cancel activation of this workflow. You can verify its status by refreshing this page and trying again. If this problem persists, please contact support.',
            error,
          }),
        );
      }
    });
}

export function removeEvent(
  store: IndexStore,
  workflowId: string,
  eventId: string,
): Promise<void> {
  store.cache.expire(getWorkflow.KEY(store.reduxStore.dispatch, workflowId));
  return api.del(store, `events/${eventId}`);
}

export function removeWorkflowsFromCache(
  store: IndexStore,
  workflowIds: Array<string>,
): void {
  workflowIds.forEach((workflowId) =>
    store.cache.expire(getWorkflowKey(null, workflowId)),
  );
}

export function getWorkflowAudienceMembers(
  store: IndexStore,
  workflowId: string,
  page: number,
): Promise<void> {
  return api
    .get(store, `workflows/${workflowId}/audience-members?page=${page}`)
    .then((response) => {
      response = parsers.parse.audienceMembersPage(response);
      store.audienceMembers.receivePage(response);
    })
    .catch((error) => {
      logger.error(
        'workflow getWorkflowAudienceMembers error: ',
        error.stack || error,
      );
    });
}

export const getAudienceFilter = flow(
  key((workflowId) => `getAudienceFilter ${workflowId}`),
  cached({ttl: 100}),
  fetching(),
)((store: IndexStore, workflowId: string): Promise<void> => {
  const audienceFilterStore = store.audienceFilters;
  const fetching = audienceFilterStore.state.fetching[workflowId];

  if (fetching) {
    return fetching;
  }
  const promise = api
    .get(store, `workflows/${workflowId}/audience-filter`)
    .then((response) => {
      response.filter = parsers.parse.audienceFilter(response?.filter);
      audienceFilterStore.receiveFetched(workflowId, response);
    });
  promise.catch((error) => {
    logger.error('workflow getAudienceFilter error', error.stack || error);
    audienceFilterStore.receiveFetched(workflowId);
  });

  audienceFilterStore.startFetching(workflowId, promise);
  return promise;
});

export function beginEditingAudienceFilter(
  store: IndexStore,
  workflowId: string,
): ?string {
  const filter = store.audienceFilters.get(workflowId);

  if (filter) {
    const editingId = 'editing' + workflowId;
    store.audienceFilters.setFilter(editingId, filter);
    return editingId;
  }
}

export function copyAudienceFilterFromWorkflow(
  store: IndexStore,
  sourceWorkflowId: string,
  targetWorkflowId: string,
) {
  return getAudienceFilter(store, sourceWorkflowId).then(() => {
    const filter = store.audienceFilters.get(sourceWorkflowId);

    store.audienceFilters.setFilter(targetWorkflowId, filter);
  });
}

export function toggleAudienceFilterAll(store: IndexStore, workflowId: string) {
  const filter = store.audienceFilters.get(workflowId);

  if (filter) {
    store.audienceFilters.setFilter(
      workflowId,
      filter.all ? {and: []} : {all: true},
    );
  }
}

const fieldDefaults = {
  string: {
    operator: 'eq',
    value: '',
  },
  boolean: {
    operator: 'eq',
    value: false,
  },
  number: {
    operator: 'gt',
    value: '',
  },
  currency: {
    operator: 'gt',
    value: '',
  },
  date: {
    operator: 'gt',
    value: null,
  },
};

const getInitialZipCodeRuleValues = () => ({
  operator: 'in',
  value: '',
  ...getDefaultZipcodeFilterFieldValues(),
});

// eslint-disable-next-line max-params
export function updateRuleField(
  store: IndexStore,
  workflowId: string,
  ruleIndex: number,
  field: string,
  fieldsToTypes: {[key: string]: string, ...} = {},
  isZipcodeField: boolean = false,
) {
  const filter = store.audienceFilters.get(workflowId);

  if (filter.and[ruleIndex].field !== field) {
    const type = fieldsToTypes[field];
    //(diwakersurya) ENGAGE-6611
    const initialValues = isZipcodeField
      ? getInitialZipCodeRuleValues()
      : fieldDefaults[type] || fieldDefaults.string;
    store.audienceFilters.setFilter(
      workflowId,
      sculpt(filter, {
        and: {
          [ruleIndex]: {
            $assign: {
              ...initialValues,
              field,
            },
          },
        },
      }),
    );
    store.audienceFilters.isEditing(true);
  }
}

export function updateRuleOperator(
  store: IndexStore,
  workflowId: string,
  ruleIndex: number,
  operator: string,
) {
  const filter = store.audienceFilters.get(workflowId);

  if (filter.and[ruleIndex].operator !== operator) {
    store.audienceFilters.setFilter(
      workflowId,
      sculpt(filter, {
        and: {
          [ruleIndex]: {
            operator: {
              $set: operator,
            },
          },
        },
      }),
    );
    store.audienceFilters.isEditing(true);
  }
}

export function updateRuleValue(
  store: IndexStore,
  workflowId: string,
  ruleIndex: number,
  value: string | string[],
) {
  const filter = store.audienceFilters.get(workflowId);

  if (filter.and[ruleIndex].value !== value) {
    store.audienceFilters.setFilter(
      workflowId,
      sculpt(filter, {
        and: {
          [ruleIndex]: {
            value: {
              $set: value,
            },
          },
        },
      }),
    );
    store.audienceFilters.isEditing(true);
  }
}
export function updateRule(
  store: IndexStore,
  workflowId: string,
  ruleIndex: number,
  payload: {[string]: string | string[]},
) {
  Object.entries(payload).forEach(([field, value]) => {
    const filter = store.audienceFilters.get(workflowId);
    store.audienceFilters.setFilter(
      workflowId,
      sculpt(filter, {
        and: {
          [ruleIndex]: {
            [field]: {
              $set: value,
            },
          },
        },
      }),
    );
  });
  store.audienceFilters.isEditing(true);
}

export function updateRuleOffset(
  store: IndexStore,
  workflowId: string,
  ruleIndex: number,
  offset: number,
) {
  const filter = store.audienceFilters.get(workflowId);

  if (filter.and[ruleIndex].offset !== offset) {
    store.audienceFilters.setFilter(
      workflowId,
      sculpt(filter, {
        and: {
          [ruleIndex]: {
            offset: {
              $set: offset,
            },
          },
        },
      }),
    );
    store.audienceFilters.isEditing(true);
  }
}

// TODO (kyle): remove 'Date' from this function name
export function updateDateRuleEmptyValue(
  store: IndexStore,
  workflowId: string,
  ruleIndex: number,
  allowEmpty: boolean,
) {
  const filter = store.audienceFilters.get(workflowId);

  if (filter.and[ruleIndex].allowEmpty !== allowEmpty) {
    store.audienceFilters.setFilter(
      workflowId,
      sculpt(filter, {
        and: {
          [ruleIndex]: {
            allowEmpty: {
              $set: allowEmpty,
            },
          },
        },
      }),
    );
    store.audienceFilters.isEditing(true);
  }
}

export function updateAudienceFilter(
  store: IndexStore,
  workflowId: string,
  filter: AudienceFilter,
  fieldsToTypes: {[key: string]: string},
): Promise<void> {
  const apiAudienceFilter = parsers.normalize.audienceFilter(
    fieldsToTypes,
    filter,
  );

  const promise = api
    .post(store, `workflows/${workflowId}/audience-filter`, apiAudienceFilter)
    .then(() => {
      // TODO (rng) - Don't set it to new filter because of backgrounding
      store.audienceFilters.setFilter(workflowId, filter);

      store.audienceFilters.isEditing(false);
      store.audienceFilters.setDraft(false);
    });
  return promise.catch((error) => {
    logger.error('workflow updateAudienceFilter error: ', error.stack || error);
    throw error;
  });
}

export function cancelWorkflowAudienceFilter(
  store: IndexStore,
  workflowId: string,
): Promise<void> {
  return api
    .post(store, `workflows/${workflowId}/audience-filter/cancel`)
    .then(() => {
      // store.audienceFilters.setFilter(workflowId, filter);
      store.audienceFilters.isEditing(false);

      // TODO (rng) - Reload old filter state
    })
    .catch((error) => {
      logger.error(
        'workflow cancelWorkflowAudienceFilter error: ',
        error.stack || error,
      );
      throw error;
    });
}

export function addEmptyAudienceFilterRule(
  store: IndexStore,
  workflowId: string,
  index: ?number,
) {
  const filter = store.audienceFilters.get(workflowId);

  const emptyFilter = {
    field: '',
    operator: '',
    value: '',
  };

  if (filter) {
    if (!isNil(index)) {
      store.audienceFilters.setFilter(
        workflowId,
        sculpt(filter, {
          and: {
            $splice: [[index, 1, emptyFilter]],
          },
        }),
      );
    } else {
      store.audienceFilters.setFilter(
        workflowId,
        sculpt(filter, {
          and: {
            $push: emptyFilter,
          },
        }),
      );
    }
    store.audienceFilters.isEditing(true);
  }
}

export function deleteAudienceFilterRule(
  store: IndexStore,
  workflowId: string,
  index: number,
) {
  const filter = store.audienceFilters.get(workflowId);

  if (filter) {
    store.audienceFilters.setFilter(
      workflowId,
      sculpt(filter, {
        and: {
          $splice: [[index, 1]],
        },
      }),
    );
    store.audienceFilters.isEditing(true);
  }
}

export function updateWorkflowLogo(
  store: IndexStore,
  workflowId: string,
  url: string,
) {
  const workflow = store.workflows.get(workflowId);
  // NOTE (kyle): we're hiding a major error here. why would the workflow not exist?
  if (workflow) {
    const updatedWorkflow = sculpt(workflow, {
      brandingSettings: {
        logo: {
          $set: url,
        },
      },
    });
    store.workflows.update(updatedWorkflow);
    store.reduxStore.dispatch(receiveWorkflow(updatedWorkflow));
  }
}

// eslint-disable-next-line max-params
export function updateModuleSchedule(
  store: IndexStore,
  workflow: Workflow,
  eventId: string,
  newSchedule: ScheduleDef,
  newReminders: RemindersDef,
) {
  /* On 5/20/16 a new format for saving was introduced that is backwards compatible, so we don't need toupdate to the
     new format until the relevant work for the feature comes up: https://github.com/Spaced-Out/sense/pull/268 */

  if (workflow.active) {
    alert('Cannot change Journey Schedule if Journey is Active.');
    return Promise.resolve(false); // TODO(marcos): should the promise reject?
  }

  // const schedule = store.scheduling.getScheduleDef(newSchedule); // dev-friendly schedule

  if (newSchedule.type === 'recurring') {
    if (newSchedule.repeats === 'daily' && !newSchedule.frequencyInterval) {
      return alert(
        'The Every: ___ Days input under the event\'s schedule settings cannot be 0.',
      );
    }
  }

  const scheduleIndex = findIndex(
    workflow.eventsSchedule,
    (schedule) => schedule.id === eventId,
  );

  const apiSchedule = store.scheduling.normalize(newSchedule, newReminders); // backend-friendly schedule

  const updatedWorkflow = sculpt(workflow, {
    eventsSchedule: {
      [scheduleIndex]: {
        $assign: {
          schedule: apiSchedule,
        },
      },
    },
  });

  return updateWorkflow(store, updatedWorkflow).then((response) => !!response);
}

export function getDefaultZipcodeFilterFieldValues(): {
  source: string | '',
  radius: number,
  locationOperator: 'manual' | 'search',
} {
  return {
    source: '',
    radius: DEFAULT_ZIPCODE_RADIUS,
    locationOperator: DEFAULT_LOCATION_OPERATOR,
  };
}
