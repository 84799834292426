// @flow strict

import moment from 'moment-timezone';

/** returns value in hh:mm AM/PM
 * @param {string} value in hh:mm e.g. '23:30'
 * @returns {{time,period}} - hh:mm AM/PM e.g. {time:'11:30',period:'AM'}
 */
export const format24to12Hours = (
  value: string,
): {time: string, period: 'AM' | 'PM'} => {
  const [hours = 0, minutes = 0] = value
    .split(':')
    .map((value) => parseInt(value, 10));
  const adjustedHours = hours > 12 ? hours - 12 : hours;
  const hours12 = `${adjustedHours === 0 ? '12' : adjustedHours}`.padStart(
    2,
    '0',
  ); //'00:00' should be '12:00' AM
  const Minutes12 = `${minutes}`.padStart(2, '0');

  return {time: `${hours12}:${Minutes12}`, period: hours < 12 ? 'AM' : 'PM'};
};

/** returns value in format hh:mm
 * @param {string|number} value in minutes e.g. 0-1440
 *  @returns {string} - hh:mm
 */
/** value must be between 0 and 1440. return format hh:mm*/
export const minutesToTimeString = (value: string | number): string => {
  const totalMinutes = typeof value === 'string' ? parseInt(value, 10) : value;
  const hours = `${Math.floor(totalMinutes / 60)}`;
  const minutes = `${totalMinutes % 60}`;
  return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
};

/** returns value between 0 to 1440
 * @param value - value format hh:mm:ss
 * @returns {string} - value between '0' to '1440'
 */
export const timeStringToMinutes = (value: string): string => {
  const [hours, minutes] = value.split(':').map((value) => parseInt(value, 10));
  return `${hours * 60 + minutes}`;
};

/**
 * @param {*} timezoneName like 'America/Los_Angeles';
 * @returns short Timezone like PST
 */
export function getAbbreviatedTimezone(timezoneName: string): string {
  const now = moment.tz(timezoneName);
  return now.format('z');
}
