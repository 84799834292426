// @flow strict

import * as React from 'react';
import css from './task-list-header.css';
import ProgressBar from 'src/components/agency-onboard/lib/progress-bar/progress-bar.jsx';
import type {Task} from 'src/components/agency-onboard/types/side-panel-comp.js';


type TaskListHeaderProps = {
  tasks: {[string]: Task},
};

export default function TaskListHeader({
  tasks,
}: TaskListHeaderProps): React.Node {
  const taskList = Object.values(tasks);
  const total = taskList.length;
  // $FlowFixMe[incompatible-use]
  const completed = taskList.filter((t) => t.status !== 'Pending').length;
  return (
    <div className={css.taskListHeader}>
      <div className={css.taskListHeaderTitle}>
        <b>Task Checklist</b>
      </div>
      <div className={css.taskListHeaderProgressBar}>
        <ProgressBar
          total={total}
          completed={completed}
          boxClassName={css.progressBarBox}
          progressClassName={css.progressBar}
          labelClassName={css.progressBarLabel}
        />
      </div>
    </div>
  );
}
