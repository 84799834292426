//@flow strict
// $FlowFixMe[nonstrict-import]
import type {DynamicLabels} from 'src/types/dynamic-labels';
import type {EntityType} from 'src/types/ats-entities';
import type {
  AudienceRule,
  VariableEntry,
  PaginationData,
  SortData,
} from 'src/types/audience-list';

import * as React from 'react';
import {useSelector} from 'react-redux';
import uniqueId from 'lodash/uniqueId';
import matchSorter from 'match-sorter';

import {classify} from 'src/utils/classify';
import {
  // $FlowFixMe[nonstrict-import]
  dedupBridgedDynamicLabels,
  //$FlowFixMe[nonstrict-import]
  getDisplayLabelForField,
} from 'src/utils/dynamic-labels';

// $FlowFixMe[nonstrict-import]
import {selectAudienceListFields} from 'src/selectors/dynamic-labels';
import {
  OPERATOR_OPTIONS,
  DEFAULT_OPERATOR_OPTION,
  isAbtRule,
  getRuleItemsBasedOnAttributeValue,
  getPaginatedEntries,
  getSortedEntries,
} from 'src/utils/audience-list';
import {DebouncedSearchInput} from 'src/components/lib/genesis/debounced-search-input.jsx';
// $FlowFixMe[nonstrict-import]
import {AutoTruncatedTextGenesis as AutoTruncatedText} from 'src/components/lib/truncated-text/truncated-text.jsx';
import AbtTable from 'src/components/audience-genesis/rules/abt-table.jsx';
import {selectList} from 'src/selectors/audience-list';
// $FlowFixMe[nonstrict-import]
import {selectAllowedAbtAttributes} from 'src/selectors/action-based-targeting-meta';
// $FlowFixMe[nonstrict-import]
import {selectSpecificEntityMetadata} from 'src/selectors/ats-entities';
import {
  Panel,
  PanelBody,
  PanelFooter,
  PanelHeader,
} from '@spaced-out/ui-design-system/lib/components/Panel';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {
  ButtonTabs,
  ButtonTab,
} from '@spaced-out/ui-design-system/lib/components/ButtonTabs';
import {Card} from '@spaced-out/ui-design-system/lib/components/Card';
import {ButtonDropdown} from '@spaced-out/ui-design-system/lib/components/ButtonDropdown';
import {
  Table,
  TableBottomBar,
  BasicSingleCell,
  PaddedDoubleContentCell,
} from '@spaced-out/ui-design-system/lib/components/Table';
import {Pagination} from '@spaced-out/ui-design-system/lib/components/Pagination';
import {itemsOnPageText} from 'src/utils/bulk-actions.js';
import {Chip} from '@spaced-out/ui-design-system/lib/components/Chip';
import {getSelectedKeysFromSelectedOption} from '@spaced-out/ui-design-system/lib/utils/menu';
import {EmptyState} from '@spaced-out/ui-design-system/lib/components/EmptyState';
import {
  SubTitleLarge,
  FormLabelSmall,
  SubTitleExtraSmall,
} from '@spaced-out/ui-design-system/lib/components/Text';

import css from './rule-picker.css';


const Variable = ({data, extras}) => {
  const isSelected = extras?.selectedKeys.includes(data.id);
  return (
    <PaddedDoubleContentCell
      className={classify(css.variableSection, {[css.highlight]: isSelected})}
    >
      <AutoTruncatedText className={css.variableText} text={data.label} />
      <FormLabelSmall color="tertiary">{data.senseNameText}</FormLabelSmall>
    </PaddedDoubleContentCell>
  );
};

const Entity = ({data, extras}) => {
  const isSelected = extras?.selectedKeys.includes(data.id);
  return (
    <BasicSingleCell
      className={classify(css.entityCell, {[css.highlight]: isSelected})}
    >
      <AutoTruncatedText className={css.entityText} text={data.entityType} />
    </BasicSingleCell>
  );
};

const Selection = ({data, extras}) => {
  const isSelected = extras?.selectedKeys.includes(data.id);
  return (
    <BasicSingleCell
      className={classify(css.selection, {[css.highlight]: isSelected})}
    >
      <Button
        onClick={() => {
          extras?.selectedKeys.includes(data.id)
            ? extras?.handleSelect(
                extras.selectedKeys.filter((id) => id !== data.id),
              )
            : extras?.handleSelect([...extras.selectedKeys, data.id]);
        }}
        size="small"
        type="tertiary"
      >
        {extras?.selectedKeys.includes(data.id) ? 'Deselect' : 'Select'}
      </Button>
    </BasicSingleCell>
  );
};

const headers = [
  {
    label: 'Variable Name',
    key: 'label',
    sortable: true,
    render: Variable,
    className: css.secondaryBackground,
  },
  {
    label: 'Entity',
    key: 'entityType',
    render: Entity,
    className: css.secondaryBackground,
  },
  {
    label: '',
    key: 'id',
    render: Selection,
    sortable: false,
    className: css.secondaryBackground,
  },
];

type RulePickerProps = {
  className?: string,
  onSubmit: (AudienceRule[]) => mixed,
  entityType: EntityType,
  onCancel: () => mixed,
  allowAbtAttributeSelection: boolean,
  rulePickerOpen: boolean,
};

const TAB_ITEMS = [
  {label: 'Entity Data Variables', type: 'entity_data_variable'},
  {label: 'Action Based Targeting', type: 'action_based_targeting'},
];

const PAGE_SIZE = 10;

export const RulePicker = ({
  className,
  onSubmit,
  entityType,
  onCancel,
  allowAbtAttributeSelection,
  rulePickerOpen,
}: RulePickerProps): React.Node => {
  const [query, setQuery] = React.useState<string>('');
  const [values, setValues] = React.useState(new Map<string, string>());
  const [operators, setOperators] = React.useState(new Map<string, string>());
  const [selected, setSelected] = React.useState(new Set());
  const [filters, setFilters] = React.useState(new Map());
  const [sort, setSort] = React.useState<SortData>({
    sortBy: 'label',
    sortOrder: 'original',
  });
  const [pagination, setPagination] = React.useState<PaginationData>({
    totalPages: 0,
    currentPage: 1,
    pageSize: PAGE_SIZE,
  });

  const [valuesWithError, setValuesWithError] = React.useState(
    new Set<string>(),
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState(
    'entity_data_variable',
  );
  const fields: DynamicLabels = useSelector((state) =>
    selectAudienceListFields(state, entityType),
  );

  const abtAttributes = useSelector(selectAllowedAbtAttributes);

  const {entityType: baseEntityType} = useSelector(selectList);

  const entityObject = useSelector((state) =>
    selectSpecificEntityMetadata(state, baseEntityType),
  );

  const handleValueChange = (id, value) => {
    setValues(new Map(values).set(id, value));
  };
  const clearValue = (id) => {
    setValues(new Map([...values.entries()].filter(([key, _]) => key !== id)));
  };
  const addValuesWithError = (id) => {
    setValuesWithError(valuesWithError.add(id));
  };
  const removeValuesWithError = (id) => {
    setValuesWithError(
      new Set([...valuesWithError.values()].filter((value) => value !== id)),
    );
  };
  const handleOperatorChange = (id, value) => {
    //clear value and errors errors on operator change
    clearValue(id);
    removeValuesWithError(id);

    if (!selected.has(id)) {
      setSelected(new Set(selected).add(id));
    }
    if (value) {
      setOperators(new Map(operators).set(id, value));
    }
  };
  const handleSelect = (selected) => {
    setSelected(new Set(selected));
  };

  const handleEntityFilterChange = (option) => {
    const selectedKeys = getSelectedKeysFromSelectedOption(
      option,
      filters.get('entityType') || [],
    );
    setFilters(new Map(filters).set('entityType', selectedKeys));
  };

  const filterOptions: FilterOption[] = getFilterOptions(
    fields,
    abtAttributes,
    selectedType,
  );
  //apply filters on fields
  const filteredFields = getFilteredFields(fields, filters);

  const dynamicLabels = React.useMemo(
    () =>
      [...dedupBridgedDynamicLabels(filteredFields)].map((item) => ({
        label: getDisplayLabelForField(item),
        id: item.resolvedId,
        value: item.value,
        type: item.type,
        senseNameText:
          item.related_entities.length === 0
            ? null
            : `${item.mapping.sense_name}` ?? null,
        entityType: item.entity_type,
      })),
    [filteredFields],
  );

  const entries: VariableEntry[] = dynamicLabels.map((item) => ({
    ...item,
    field: item.value,
    operator: operators.get(item.id),
    value: values.get(item.id),
  }));
  const matchingEntries: VariableEntry[] = matchSorter(entries, query, {
    keys: ['label'],
  });
  const totalPages: number = Math.ceil(
    matchingEntries.length / pagination.pageSize,
  );

  React.useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      currentPage: 1,
      totalPages,
    }));
  }, [query, sort.sortBy, sort.sortOrder, filters.get('entityType')?.length]);

  const sortedEntries: VariableEntry[] = getSortedEntries(
    matchingEntries,
    sort,
  );

  const paginatedEntries: VariableEntry[] = getPaginatedEntries(
    sortedEntries,
    pagination,
  );

  const onSortClick = (
    sortKey: string,
    sortDirection: 'asc' | 'desc' | 'original',
  ) => {
    setSort({sortBy: sortKey, sortOrder: sortDirection});
  };

  const handlePageChange = (page: ?number) => {
    setPagination((pagination) => ({
      ...pagination,
      currentPage: page ?? 1,
    }));
  };

  const handleSubmit = (_) => {
    const rules: AudienceRule[] = [...selected].reduce((acc, fieldId) => {
      if (isAbtRule(fieldId)) {
        const selectedAttribute = abtAttributes.find(
          (attribute) => attribute.value === fieldId,
        );
        const ruleItems = getRuleItemsBasedOnAttributeValue(
          selectedAttribute.value,
          selectedAttribute.entityType,
          baseEntityType,
          entityObject,
        );
        acc.push(ruleItems);
      } else {
        const selectedField = fields.find(
          (item) => item.resolvedId === fieldId,
        );
        if (selectedField) {
          const field = {
            attribute_name: getAttributeNameFromValue(selectedField),
            entity_type: selectedField?.entity_type,
            base_entity_type: entityType,
            from_relations: getFromRelationsForAttribute(selectedField),
          };
          const operator = DEFAULT_OPERATOR_OPTION[selectedField.type];
          // $FlowFixMe[incompatible-type]
          acc.push({
            id: uniqueId('rule'),
            field,
            operator,
            meta: null,
          });
        }
      }
      return acc;
    }, []);
    //TODO(Vish): Write useReducer hook for this.
    setQuery('');
    setValues(new Map<string, string>());
    setOperators(new Map<string, string>());
    setSelected(new Set());
    setFilters(new Map());
    setValuesWithError(new Set<string>());
    setSelectedType('entity_data_variable');
    onSubmit(rules);
  };

  const TabItems = () => (
    <ButtonTabs
      isFluid={true}
      onButtonTabSelect={(type) => {
        setSelectedType(type);
        setFilters(new Map());
      }}
      classNames={{wrapper: css.buttonTabs}}
      selectedButtonTabId={selectedType}
    >
      {TAB_ITEMS.map(({label, type}) => (
        <ButtonTab id={type} key={type}>
          {label}
        </ButtonTab>
      ))}
    </ButtonTabs>
  );

  return (
    <Panel
      anchor="right"
      isOpen={rulePickerOpen}
      size="large"
      tapOutsideToClose={false}
      classNames={{content: css.childPanel}}
    >
      <PanelHeader hideCloseBtn>
        <div className={css.innerPanelHeader}>
          <Button
            type="ghost"
            size="medium"
            iconLeftName="chevrons-left"
            onClick={onCancel}
          />
          <SubTitleLarge>Add Variables</SubTitleLarge>
        </div>
      </PanelHeader>
      <PanelBody>
        <div className={css.rulePickerBody}>
          {allowAbtAttributeSelection && (
            <TabItems TAB_ITEMS={TAB_ITEMS} type={selectedType} />
          )}
          <Card classNames={{wrapper: css.searchTableCard}} paddingTop="small">
            <div className={css.tableContainer}>
              <div className={css.searchFilter}>
                <DebouncedSearchInput
                  classNames={{container: css.searchContainer}}
                  value={query}
                  onChange={setQuery}
                  placeholder="Search any variable"
                  size="small"
                />
                <ButtonDropdown
                  type="tertiary"
                  iconRightName={isOpen ? 'chevron-up' : 'chevron-down'}
                  iconRightType="regular"
                  onMenuOpen={() => setIsOpen(true)}
                  onMenuClose={() => setIsOpen(false)}
                  menu={{
                    isFluid: false,
                    menuDisabled: false,
                    //$FlowFixMe (TODO):Vish
                    options: filterOptions,
                    optionsVariant: 'checkbox',
                    selectedKeys: filters.get('entityType') || [],
                    size: 'medium',
                  }}
                  onOptionSelect={handleEntityFilterChange}
                  size="small"
                  anchorPosition="bottom-end"
                >
                  {`${
                    selectedType === 'action_based_targeting'
                      ? 'Events'
                      : 'Entities'
                  } (${filters.get('entityType')?.length ?? 0})`}
                </ButtonDropdown>
              </div>
              {[...selected].length > 0 && (
                <div className={css.chipContainer}>
                  {[...selected].map((fieldId) => {
                    let chipLabel;
                    if (isAbtRule(fieldId)) {
                      const selectedAttribute = abtAttributes.find(
                        (attribute) => attribute.value === fieldId,
                      );
                      chipLabel = selectedAttribute
                        ? `${selectedAttribute.label}`
                        : `${fieldId}`;
                    } else {
                      const selectedField = fields.find(
                        (item) => item.resolvedId === fieldId,
                      );
                      if (selectedField) {
                        chipLabel = `${selectedField.label}`;
                      } else {
                        chipLabel = `${fieldId}`;
                      }
                    }
                    return (
                      <Chip
                        dismissable
                        onDismiss={() => {
                          handleSelect(
                            [...selected].filter((id) => id !== fieldId),
                          );
                        }}
                        semantic="primary"
                        size="medium"
                        key={fieldId}
                      >
                        {chipLabel}
                      </Chip>
                    );
                  })}
                </div>
              )}
              {selectedType === 'entity_data_variable' ? (
                <Table
                  borderRadius="0px"
                  classNames={{
                    wrapper: classify(css.variableList, {
                      [css.setBorderBottom]: paginatedEntries.length > 0,
                    }),
                  }}
                  headers={headers}
                  entries={paginatedEntries}
                  onSelect={handleSelect}
                  tableHeaderClassName={css.stickyTableHeader}
                  emptyText={
                    <EmptyState
                      description="We cant find any items matching your search."
                      title="Sorry! No result found"
                      imageVariant="file"
                    />
                  }
                  defaultSortKey={sort.sortBy}
                  defaultSortDirection={sort.sortOrder}
                  onSort={onSortClick}
                  extras={{
                    selectedKeys: [...selected],
                    handleSelect,
                    handleValueChange,
                    handleOperatorChange,
                    OPERATOR_OPTIONS,
                    fields,
                    valuesWithError,
                    addValuesWithError,
                    removeValuesWithError,
                    operators,
                    values,
                  }}
                />
              ) : (
                <AbtTable
                  query={query}
                  selected={[...selected]}
                  handleSelect={handleSelect}
                  filters={filters}
                  abtAttributes={abtAttributes}
                  isLoading={!entityObject}
                />
              )}
            </div>
            {selectedType === 'entity_data_variable' &&
              paginatedEntries.length > 0 && (
                <TableBottomBar className={css.paginationContainer}>
                  <Pagination
                    currentPage={pagination.currentPage}
                    totalPages={pagination.totalPages}
                    onChange={handlePageChange}
                  >
                    <SubTitleExtraSmall color="secondary">
                      {itemsOnPageText(
                        pagination.currentPage,
                        pagination.pageSize,
                        matchingEntries.length,
                      )}
                    </SubTitleExtraSmall>
                  </Pagination>
                </TableBottomBar>
              )}
          </Card>
        </div>
      </PanelBody>
      <PanelFooter>
        <Button
          onClick={handleSubmit}
          size="medium"
          type="primary"
          disabled={selected.size === 0 || valuesWithError.size > 0}
        >
          {selected.size > 0
            ? `Add Variables (${selected.size})`
            : 'Add Variables'}
        </Button>
      </PanelFooter>
    </Panel>
  );
};

type FilterOption = {
  key: string,
  label: string,
};
function getFilterOptions(labels, abtAttributes, selectedType): FilterOption[] {
  // NOTE (kyle): the start of the path is always the attribute name,
  // which we don't need.
  let set;
  if (selectedType === 'action_based_targeting') {
    set = abtAttributes.reduce((attributeNames, attribute) => {
      attributeNames.add(attribute.eventGroup);
      return attributeNames;
    }, new Set());
  } else {
    set = labels.reduce((filters, label) => {
      if (label.source !== 'custom') {
        const {display_name} = label.mapping;
        if (display_name) {
          filters.add(display_name);
        }
      }

      return filters;
    }, new Set());
  }

  return [...set].map((value) => ({
    key: value,
    label: value,
  }));
}

function getFilteredFields(labels, filters) {
  const entityTypeFilter = filters.get('entityType') || [];
  if (entityTypeFilter.length === 0) {
    return labels;
  }
  const filteredLabels = entityTypeFilter.reduce((acc, entityType) => {
    labels
      .filter(
        (label) =>
          label.source !== 'custom' &&
          label.mapping.display_name === entityType,
      )
      .forEach((label) => acc.add(label));

    return acc;
  }, new Set());

  return filteredLabels;
}

export const getFromRelationsForAttribute = (field: {
  value: string,
  ...
}): string[] =>
  field.value.includes('/')
    ? field.value /** field value contains fully formed relationship names as well e.g. relation1/relation2/attributename */
        .split('/')
        .slice(
          0,
          -1,
        ) /** remove attribute name and consider only the relations */
    : [];

const getAttributeNameFromValue = (field): string =>
  field.value.includes('/')
    ? field.value /** field value contains fully formed relationship names as well e.g. relation1/relation2/attributename */
        .split('/')
        .pop()
    : field.value;
