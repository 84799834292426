// @noflow

import * as React from 'react';

import typeof IndexStore from 'src/stores/index';

/**
 * Top-level component that provides flux objects via context to all children.
 *
 * Should be used as a wrapper around any routing to provide the flux objects
 * to the React subtree.
 *
 * Based on Redux's Provider component:
 * https://github.com/rackt/react-redux/blob/master/src/components/Provider.js
 *
 * NOTE: Only supplies the store in this implementation but could be updated
 * to provide other flux-related objects if necessary.
 */
export default class FluxRoot extends React.Component<{
  store: IndexStore,
  children: React.Node,
}> {
  senseStore = this.props.store;

  componentDidMount() {
    this.senseStore.on('update', this.handleStoreUpdate);
  }

  componentWillUnmount() {
    this.senseStore.removeListener('update', this.handleStoreUpdate);
  }

  render() {
    // NOTE (kyle): in order to update the store every time we create a new
    // context value.
    return (
      <FluxContext.Provider value={{store: this.props.store}}>
        {this.props.children}
      </FluxContext.Provider>
    );
  }

  handleStoreUpdate = () => {
    this.forceUpdate();
  };
}

export const FluxContext = React.createContext({store: null});
