// @noflow

import * as React from 'react';
import {Link} from 'src/rerouter';
import nth from 'lodash/nth';

import classify from 'src/utils/classify';

import ArrowIcon from 'src/images/arrow-left.svg?noAttrs';

import css from './index.css';


type Crumb = {
  icon?: React.Element<mixed>,
  name: React.Node,
  url?: string,
  key?: string,
  className?: string,
};

type Props = {
  className?: string,
  crumbs: Crumb[],
  padLeft?: boolean,
  separator?: React.Node,
  showLastLink?: boolean,
};

// NOTE (kyle): users are getting confused about the behavior of this back button so i'm
// hiding it until we can figure out a more contextual user interaction for it. for now
// users can use the builtin browser back button.
export default ({
  className,
  crumbs,
  padLeft = true,
  separator,
  showLastLink,
}: Props) => (
  <div className={css.container}>
    {false && crumbs.length > 1 ? (
      <Link className={css.titleBack} to={nth(crumbs, -2).url}>
        <ArrowIcon className={css.backArrow} />
      </Link>
    ) : (
      padLeft && <div className={css.titleLeftPad} />
    )}

    <div className={classify(css.crumbs, className)}>
      {crumbs.map((crumb, i) =>
        i < crumbs.length - 1 || showLastLink ? (
          <div
            className={classify(css.crumbInactive, crumb.className)}
            key={crumb.url || crumb.key}
          >
            <Link to={crumb.url}>
              {crumb.icon}
              <span>{crumb.name}</span>
            </Link>
            {(i < crumbs.length - 1 && separator) ?? (
              <svg className={css.crumbSlash} viewBox="0 0 10 23">
                <path d="M 0 23 L 10 0" />}
              </svg>
            )}
          </div>
        ) : (
          <div
            className={classify(css.crumbActive, crumb.className)}
            key={crumb.key || crumb.url}
          >
            {crumb.icon}
            <span>{crumb.name}</span>
          </div>
        ),
      )}
    </div>
  </div>
);
