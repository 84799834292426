// @flow strict
import * as React from 'react';
import {useHistory} from 'src/rerouter';
import {useSelector} from 'react-redux';

import {Clickable, Header} from 'src/designSystem2021Components/text-v2.jsx';
import classify from 'src/utils/classify';
import {AnalyticsService} from 'src/analytics';
import {AMPLITUDE_EVENTS} from 'src/components/agency-onboard/amplitude_event.js';
//$FlowFixMe[nonstrict-import]
import {selectAgencyData} from 'src/selectors/agency';

import css from './onboard-header.css';
import maincss from 'src/components/agency-onboard/agency-onboard.css';

import SenseHeaderLogo from 'src/components/agency-onboard/images/sense-header-logo.svg';
import ArrowRightIcon from 'src/images/icons/arrow-right-no-fill.svg';


export default function OnboardHeader(): React.Node {
  const router = useHistory();
  const agencyData = useSelector(selectAgencyData);

  const handleTrackingEvent = (event) => {
    AnalyticsService.track(event, {
      agencyId: agencyData.id,
      agencyName: agencyData.name,
    });
  };

  const goToDashboard = () => {
    handleTrackingEvent(AMPLITUDE_EVENTS.GO_TO_DASHBOARD_CLICKED);
    router.replace('/dashboard');
  };

  return (
    <div className={css.onboardHeader}>
      <div className={css.textHeader}>
        <Header className={css.headerText}>Welcome to sense!</Header>
        <div className={css.instructions}>
          Please complete the checklist below to get access to
        </div>

        <div className={css.productsContainer}>
          <ul>
            <li className={css.productText}>Engage</li>
            <li className={css.productText}>Messaging</li>
          </ul>
        </div>
      </div>
      <div
        className={classify(css.dashboardBtnDiv, maincss.text)}
        onClick={() => goToDashboard()}
      >
        <Clickable> Go To Dashboard </Clickable>
        <ArrowRightIcon className={css.icon} />
      </div>

      <div className={css.bottomQuarter3}>
        <div className={css.bottomQuarter2}>
          <div className={css.bottomQuarter1}></div>
        </div>
      </div>
      <div className={css.senseHeaderLogo}>
        <SenseHeaderLogo />
      </div>
    </div>
  );
}
