//@flow strict
// $FlowFixMe[nonstrict-import]
import type {Dispatch, GetState, ThunkAction} from 'src/reducers';

// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, cached, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';
import type {
  DelayNode,
  DelayNodeState,
} from 'src/types/automation-workflow-scheduling';
import {
  SCHEDULE_DATE_TYPES,
  SCHEDULE_TIME_TYPES,
} from 'src/utils/automation-workflow-scheduling.js';


export type DelayAction =
  | UpdateDelayNodeSchedulingAction
  | ReceiveDelayNodeSchedulingAction;

/**
 * Action Name constants definition starts here.
 */

export const UPADTE_DELAY_NODE_SCHEDULING =
  'node-scheduling/update-delay-node-scheduling';

export const RECEIVE_DELAY_NODE_SCHEDULING =
  'node-scheduling/receive-delay-node-scheduling';

/**
 * Action type definition starts here.
 */

type UpdateDelayNodeSchedulingAction = {
  type: typeof UPADTE_DELAY_NODE_SCHEDULING,
  payload: DelayNode,
};

type ReceiveDelayNodeSchedulingAction = {
  type: typeof RECEIVE_DELAY_NODE_SCHEDULING,
  payload: DelayNodeState,
};

export const updateDelayNodeSchedulingAction = (
  payload: DelayNode,
): UpdateDelayNodeSchedulingAction => ({
  type: UPADTE_DELAY_NODE_SCHEDULING,
  payload,
});

export const receiveDelayNodeScheduling = (
  payload: DelayNodeState,
): ReceiveDelayNodeSchedulingAction => ({
  type: RECEIVE_DELAY_NODE_SCHEDULING,
  payload,
});

export const fetchDelayNodeScheduling: (
  scheduleNodeId: string,
) => ThunkAction<mixed> = flow(
  key((scheduleNodeId) => `workflow-automation/scheduling/${scheduleNodeId}`),
  fetching(),
)((scheduleNodeId: string) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.get('node', {node_id: `schedule:${scheduleNodeId}`}),
  ).then((payload) => {
    //TODO : make this simple once data starts comming for all the cases
    const {id, date_offset, time_offset} = payload;
    dispatch(
      receiveDelayNodeScheduling({
        nodeId: id,
        days: date_offset?.days,
        hours: time_offset?.hours,
        minutes: time_offset?.minutes,
      }),
    );
  });
});

export const updateDelayNodeScheduling =
  (swimlaneId: string, nodeId: string, workflowId: string): ThunkAction<void> =>
  (dispatch: Dispatch, getState: GetState) => {
    const {days, hours, minutes, nodeId} = getState().delay;
    return dispatch(
      reduxApi.put(`node`, {
        node_type: 'schedule',
        swimlane_id: parseInt(swimlaneId, 10),
        workflow_id: parseInt(workflowId, 10),
        body: {
          type: 'schedule',
          date_type: SCHEDULE_DATE_TYPES.TRIGGER_DATE,
          date_value: 'today',
          time_type: SCHEDULE_TIME_TYPES.TRIGGER_TIME,
          time_value: 'now',
          date_offset: {
            days,
          },
          time_offset: {
            hours,
            minutes,
          },
          id: nodeId,
        },
      }),
    ).then((payload) => {
      //console.log('delay node update success', payload);
    });
  };

export const createDelayNodeScheduling: (
  previous_node: string,
  next_node: string,
  swimlaneId: string,
  workflowId: string,
) => ThunkAction<mixed> =
  (previous_node, next_node, swimlaneId, workflowId) =>
  (dispatch: Dispatch, getState: GetState) => {
    const {days, hours, minutes} = getState().delay;

    return dispatch(
      reduxApi.post(
        `node`,
        {
          node_type: 'schedule',
          swimlane_id: parseInt(swimlaneId, 10),
          workflow_id: parseInt(workflowId, 10),
          body: {
            date_type: SCHEDULE_DATE_TYPES.TRIGGER_DATE,
            date_value: 'today',
            time_type: SCHEDULE_TIME_TYPES.TRIGGER_TIME,
            time_value: 'now',
            date_offset: {
              days,
            },
            time_offset: {
              hours,
              minutes,
            },
          },
        },
        {previous_node, next_node},
      ),
    );
  };
