// @noflow

import type {Dispatch, ThunkAction} from 'src/reducers';
import type {
  SankeyGraph,
  SankeyGraphVisits,
  SankeyGraphRequest,
  PeopleEngagedRequest,
  PeopleEnagedType,
  PresetQuestion,
} from 'src/types/chatbot';

import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api-v2.js';
import {QUESTION_SIMILARITY_THRESHOLD} from 'src/components/conversation-builder/utils';


const {stringify} = JSON;

export const SANKEY_DATA = 'chatbot/sankey-graph';
export const SANKEY_VISITS_DATA = 'chatbot/sankey-graph-visits';
export const PEOPLE_ENGAGED_DATA = 'chatbot/people_engaged';
export const PEOPLE_ENGAGED_LOADING_DATA = 'chatbot/people_engaged_loading';
export const SANKEY_GRAPH_LOADING_DATA = 'chatbot/sankey_graph_loadingp';
export const RECEIVE_QUESTION_SIMILARITY =
  'chatbot/receive_question_similarity';
export const RECEIVE_PRESET_QUESTIONS = 'chatbot/receive_preset_questions';
export const RECEIVE_T2A_KEYWORDS = 'chatbot/receive_t2a_keywords';
export const RECEIVE_EXTERNAL_VARS = 'external-vars/receiveAll';

export type ExternalChatbotVariable = {
  data_type: string,
  entity_type: string,
  parameter: string,
};

let sankeyGraphApiDone = false;
let sankeyGraphVisitsApiDone = false;
type GetSankeyGraphDataAction = {
  type: typeof SANKEY_DATA,
  payload: Array<SankeyGraph> | [],
};

type GetSankeyGraphVisitsDataAction = {
  type: typeof SANKEY_VISITS_DATA,
  payload: Array<SankeyGraphVisits> | [],
};

type IsPeopleEngagedLoadingAction = {
  type: typeof PEOPLE_ENGAGED_LOADING_DATA,
  payload: boolean,
};

type IsSankeyGraphLoadingAction = {
  type: typeof SANKEY_GRAPH_LOADING_DATA,
  payload: boolean,
};

type GetPeopleEngagedAction = {
  type: typeof PEOPLE_ENGAGED_DATA,
  payload: Array<PeopleEngagedRequest>,
};

type ReceiveQuestionSimilarityAction = {
  type: typeof RECEIVE_QUESTION_SIMILARITY,
  payload: {[key: string]: string[]},
};

type ReceivePresetQuestionsAction = {
  type: typeof RECEIVE_PRESET_QUESTIONS,
  payload: PresetQuestion[],
};

type ReceiveT2AKeywordsAction = {
  type: typeof RECEIVE_QUESTION_SIMILARITY,
  payload: {[key: string]: number[]},
};

type ExternalChatbotVariablesAction = {
  type: typeof RECEIVE_EXTERNAL_VARS,
  payload: ExternalChatbotVariable[],
};

export type ChatbotActions =
  | GetSankeyGraphDataAction
  | GetSankeyGraphVisitsDataAction
  | GetPeopleEngagedAction
  | ReceiveQuestionSimilarityAction
  | ReceivePresetQuestionsAction
  | ReceiveT2AKeywordsAction
  | ExternalChatbotVariablesAction;

const getPeopleEngagedData = (
  payload: PeopleEngagedRequest[],
): GetPeopleEngagedAction => ({
  type: PEOPLE_ENGAGED_DATA,
  payload,
});

const getSankeyGraphData = (
  payload: Array<SankeyGraph> | [],
): GetSankeyGraphDataAction => ({
  type: SANKEY_DATA,
  payload,
});

const getSankeyGraphVisitsData = (
  payload: SankeyGraphVisits[] | [],
): GetSankeyGraphVisitsDataAction => ({
  type: SANKEY_VISITS_DATA,
  payload,
});

const isPeopleEngagedLoading = (
  payload: boolean,
): IsPeopleEngagedLoadingAction => ({
  type: PEOPLE_ENGAGED_LOADING_DATA,
  payload,
});

const isSankeyGraphLoading = (
  payload: boolean,
): IsSankeyGraphLoadingAction => ({
  type: SANKEY_GRAPH_LOADING_DATA,
  payload,
});

const receiveQuestionSimilarity = (payload: {
  [key: string]: string[],
}): ReceiveQuestionSimilarityAction => ({
  type: RECEIVE_QUESTION_SIMILARITY,
  payload,
});

const receivePresetQuestions = (payload: {
  [key: string]: string[],
}): ReceiveQuestionSimilarityAction => ({
  type: RECEIVE_PRESET_QUESTIONS,
  payload,
});

const receiveExternalChatbotVariables = (
  payload,
): ExternalChatbotVariablesAction => ({
  type: RECEIVE_EXTERNAL_VARS,
  payload,
});

export const getQuestionSimilarity =
  (questions): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const existingQuestions = getState().chatbot.questionSimilarity;
    const filteredQuestions =
      questions?.filter((question) => !existingQuestions[question]) ?? []; // is this weird to make the full question the key?

    if (filteredQuestions.length > 0) {
      dispatch(
        reduxApi.post('chatbot/questions/batch/similarity', {
          questions: filteredQuestions,
        }),
      ).then(
        (res) => {
          const normalizedResults = res.result.reduce((result, question) => {
            result[question.question] = question.predicted_labels.filter(
              ({score}) => score > QUESTION_SIMILARITY_THRESHOLD,
            );
            return result;
          }, {});
          dispatch(receiveQuestionSimilarity(normalizedResults));
        },
        (err) => {
          console.warn('unable to fetch question similarity');
        },
      );
    }
  };

export const getPresetQuestions =
  (): ThunkAction<> => async (dispatch: Dispatch) => {
    dispatch(reduxApi.get('chatbot/questions')).then((res) => {
      if (res?.questions) {
        dispatch(receivePresetQuestions(res.questions));
      } else {
        console.warn('error fetching preset questions');
      }
    });
  };

export const getExternalChatbotVariables =
  (): ThunkAction<> => async (dispatch: Dispatch) => {
    dispatch(reduxApi.get('chatbot/external-vars')).then((res) => {
      if (res) {
        dispatch(receiveExternalChatbotVariables(res));
      } else {
        console.warn('error fetching external variables');
      }
    });
  };

// analytics/chatbot/sankey
export const updateChatBotSankeyGraph =
  (): ThunkAction<> => async (dispatch: Dispatch) => {
    // $FlowIssue object values
    dispatch(getSankeyGraphData([]));
    // $FlowIssue object values
    dispatch(getSankeyGraphVisitsData([]));
  };

export const isPeopleEngagedLoadingData =
  (isLoading: boolean): ThunkAction<> =>
  async (dispatch: Dispatch) => {
    // $FlowIssue object values
    dispatch(isPeopleEngagedLoading(isLoading));
  };

export const isSankeyGraphLoadingActionData =
  (isLoading: boolean): ThunkAction<> =>
  async (dispatch: Dispatch) => {
    // $FlowIssue object values
    dispatch(isSankeyGraphLoading(isLoading));
    dispatch(getSankeyGraphData([]));
    dispatch(getSankeyGraphVisitsData([]));
  };

export const fetchChatBotSankeyGraph: (
  chatbot_id: number,
) => ThunkAction<void> = flow(
  key((payload) => `analytics/chatbot/sankey_graph/${stringify(payload)}`),
  fetching(),
)(
  (chatbot_id: number) => (dispatch: Dispatch) =>
    dispatch(
      reduxApi.get(`analytics/chatbot/sankey_graph?chatbot_id=${chatbot_id}`),
    )
      .then((response) => {
        sankeyGraphApiDone = true;
        if (sankeyGraphVisitsApiDone) {
          // $FlowIssue object values
          dispatch(isSankeyGraphLoading(false));
        }
        // $FlowIssue object values
        dispatch(getSankeyGraphData(response));
      })
      .catch((err) => {
        // $FlowIssue object values
        dispatch(isSankeyGraphLoading(false));
      }),
);

export const fetchChatBotSankeyGraphVisits: (
  payload: SankeyGraphRequest,
) => ThunkAction<void> = flow(
  key((payload) => `analytics/chatbot/sankey_visits/${stringify(payload)}`),
  fetching(),
)(
  (payload: SankeyGraphRequest) => (dispatch: Dispatch) =>
    dispatch(reduxApi.post(`analytics/chatbot/sankey_visits`, payload))
      .then((response) => {
        sankeyGraphVisitsApiDone = true;
        if (sankeyGraphApiDone) {
          // $FlowIssue object values
          dispatch(isSankeyGraphLoading(false));
        }
        // $FlowIssue object values
        dispatch(getSankeyGraphVisitsData(response));
      })
      .catch((err) => {
        // $FlowIssue object values
        dispatch(isSankeyGraphLoading(false));
      }),
);

export const fetchChatBotPeopleEngaged: (
  payload: PeopleEngagedRequest,
) => ThunkAction<PeopleEnagedType[]> = flow(
  key((payload) => `analytics/chatbot/people_engaged/${stringify(payload)}`),
  fetching(),
)((payload: PeopleEngagedRequest) => (dispatch: Dispatch) => {
  // $FlowIssue object values
  dispatch(isPeopleEngagedLoading(true));
  return dispatch(
    reduxApi.post(`analytics/chatbot/people_engaged`, payload),
  ).then(
    // $FlowIssue object values
    (response) => {
      // $FlowIssue object values
      dispatch(isPeopleEngagedLoading(false));
      // $FlowIssue object values
      dispatch(getPeopleEngagedData(response));
    },
  );
});

export const getPreviewBeefreeHtml: (
  beefree_json: {...} | {},
) => ThunkAction<mixed> = (beefree_json) => (dispatch: Dispatch) => {
  return dispatch(reduxApi.post(`chatbot/beefree-html`, {beefree_json}));
};
