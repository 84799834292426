// @noflow

import type {
  DynamicLabels,
  DynamicLabelAction,
} from 'src/action-creators/dynamic-labels';
import type {GlobalVariableAction} from 'src/action-creators/global-variables';

import {RECEIVE_ALL} from 'src/action-creators/dynamic-labels';
import {RECEIVE as RECEIVE_GVS} from 'src/action-creators/global-variables';


export type State = DynamicLabels;

const initialState = [];

const safeStringSort = ({name: left}, {name: right}) =>
  left.localeCompare(right);

type AllowedActions = DynamicLabelAction | GlobalVariableAction;
export default (state: State = initialState, action: AllowedActions): State => {
  switch (action.type) {
    case RECEIVE_ALL: {
      const receivedAttrs = [...action.payload].map(
        ({name, resource, type}) => ({
          name,
          value: name,
          resource: !['member', 'placement', 'global_variable'].includes(
            resource,
          )
            ? 'global_variable'
            : resource,
          type,
        }),
      );
      return [...state, ...receivedAttrs].sort(safeStringSort);
    }
    case RECEIVE_GVS: {
      const receivedGVars = [...action.payload].map(
        ({name, resource, type}) => ({
          name,
          value: name,
          resource,
          type,
        }),
      );
      return [...state, ...receivedGVars].sort(safeStringSort);
    }
  }
  return state;
};
