// @flow

import type {State} from 'src/reducers';
import type {
  AtsEntity,
  AtsEntities,
  AtsEntityMappings,
  EntityAttributeSchema,
  RelatedEntityAttributeSchema,
  EntityMappingsByName,
  EntityMappingsBySenseName,
  EntityType,
  EntitySenseName,
  EntityRelationship,
  AtsClientEntity,
  EntityMetadataObject,
} from 'src/types/ats-entities';
import type {EntityFilter} from 'src/types/ats-settings';
import type {DynamicLabels} from 'src/action-creators/dynamic-labels';
import type {RecipientResource} from 'src/types/survey';

import {createSelector} from 'reselect';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';

import logger from 'src/utils/logger';
import {captureSentryMessage} from 'src/utils/sentry';

import {peopleEntityTypes} from 'src/types/ats-entities';
import isEmpty from 'lodash/isEmpty';

/*
 * Base Selectors
 */

export const workflowEntityType = (
  state: State,
  workflowId: string,
): ?EntityType => state.workflows.workflows[workflowId]?.entityType;

export const eventEntityType: (State) => EntityType = (state) =>
  state.eventCreation.entityType;

export const eventRecipientResource = (state: State): RecipientResource =>
  state.eventCreation.recipientResource;

export const getEntityAttributeSchema: (State) => Map<
  string,
  EntityAttributeSchema,
> = (state) => state.atsEntities.simpleAttributes;

export const getUnsafeEntityAttributeSchema: (State) => Map<
  string,
  EntityAttributeSchema,
> = (state) => state.atsEntities.allAttributes;

export const selectEntityDirectAttributesByType: (
  state: State,
  entityType: EntityType,
) => EntityAttributeSchema[] = createSelector(
  getEntityAttributeSchema,
  (_, entityType) => entityType,
  (attributes, entityType) =>
    Array.from(filterEntityAttributesForEntityType(attributes, entityType)),
);

export const selectUnsafeEntityDirectAttributesByType: (
  state: State,
  entityType: EntityType,
) => EntityAttributeSchema[] = createSelector(
  getUnsafeEntityAttributeSchema,
  (_, entityType) => entityType,
  (attributes, entityType) =>
    Array.from(filterEntityAttributesForEntityType(attributes, entityType)),
);

export const selectEntityDirectAttributesForTypeByDisplayName: (
  state: State,
  entityType: EntityType,
) => EntityAttributeSchema[] = createSelector(
  selectEntityDirectAttributesByType,
  (attributes) => {
    const attributesDict = {};
    attributes.forEach((attribute) => {
      attributesDict[attribute.display_name] = attribute;
    });
    // $FlowFixMe[incompatible-call]
    return attributesDict;
  },
);

export const selectEntityMetadata = (
  state: State,
): ?{[string]: EntityMetadataObject} => state.atsEntities.entityMetadata;

export const selectSpecificEntityMetadata: (
  State,
  string,
) => ?EntityMetadataObject = createSelector(
  selectEntityMetadata,
  (_, entityType) => entityType,
  (entityMetadata, entityType) => entityMetadata?.[entityType],
);

export const getAtsEntityMappings = (state: State): AtsClientEntity[] =>
  state.atsEntities.entities;

export const atsEntitiesAsSelectValues: (State) => DynamicLabels =
  createSelector(getAtsEntityMappings, (mappings) =>
    mappings.map((mapping) => ({
      label: mapping.display_name_capitalized ?? mapping.display_name,
      value: mapping.name,
    })),
  );

export const getEntityTypeMappings = (state: State): AtsEntity[] => [
  ...state.atsEntities.entities,
  {
    name: 'global_variable',
    display_name: 'global variable',
    display_name_plural: 'global variables',
    sense_name: 'global_variable',
    url: null,
    is_person: false,
    is_candidate_tab: false,
    candidate_tab_order: -1,
    event_entity: false,
    related_entities: [],
    is_internal: false,
    is_csv_entity: false,
  },
  //(diwakersurya) for job match variables
  {
    name: 'job_match_variable',
    display_name: 'job match variable',
    display_name_plural: 'job match variables',
    sense_name: 'job_match_variable',
    url: null,
    is_person: false,
    is_candidate_tab: false,
    candidate_tab_order: -1,
    event_entity: false,
    related_entities: [],
    is_internal: false,
    is_csv_entity: false,
  },
];

export const selectEntityTypeMappingsByName: (State) => EntityMappingsByName =
  createSelector(getEntityTypeMappings, (mappings) => keyBy(mappings, 'name'));

export const selectEntityMappingsByName: (State) => EntityMappingsByName =
  createSelector(getAtsEntityMappings, (mappings) => keyBy(mappings, 'name'));

export const selectEntityMappingsBySenseName: (State) => EntityMappingsBySenseName =
  createSelector(getAtsEntityMappings, (mappings) =>
    keyBy(mappings, 'sense_name'),
  );

export const selectEntityTypeBySenseName = (
  state: State,
  senseName: EntitySenseName,
): ?EntityType => selectEntityMappingsBySenseName(state)[senseName]?.name;

export const selectEntityByName = (state: State, name: EntityType): AtsEntity =>
  selectEntityMappingsByName(state)[name];

export const selectEntityBySenseName = (
  state: State,
  senseName: EntitySenseName,
): AtsEntity => selectEntityMappingsBySenseName(state)[senseName];

export const selectEntitiesKeyedByUrl: (State) => AtsEntityMappings =
  createSelector(getAtsEntityMappings, (mappings) => keyBy(mappings, 'url'));

export const selectEntityByUrl: (State, string) => AtsEntity = createSelector(
  selectEntitiesKeyedByUrl,
  (_, url) => url,
  (entities, url) => entities[url],
);

export const selectPeopleEntities: (State) => AtsEntities = createSelector(
  selectEntityMappingsByName,
  (mappings) => peopleEntityTypes.map((type) => mappings[type]).filter(Boolean),
);

export const selectPeopleEntitiesKeyedByUrl: (State) => AtsEntityMappings =
  createSelector(selectPeopleEntities, (entities) => keyBy(entities, 'url'));

export const selectPeopleEntityByUrl: (State, string) => AtsEntity =
  createSelector(
    selectPeopleEntitiesKeyedByUrl,
    (_, url) => url,
    (entities, url) => entities[url],
  );

export const selectCandidateEntityType = (state: State): ?EntityType =>
  selectEntityTypeBySenseName(state, 'candidate');

export const getRelatedEntityType = (
  entities: {[string]: AtsClientEntity},
  entityType: ?EntityType,
  attributeName: string,
): ?EntityType => {
  const entity = entityType && entities[entityType];
  if (entity) {
    return entity.related_entities.find(
      (relatedEntity) => relatedEntity.name === attributeName,
    )?.entity_type;
  }
};

export const getEntityRelationsForEntityType = (
  entities: AtsClientEntity[],
  entityType: string,
): EntityRelationship[] => {
  const currentEntity: ?AtsClientEntity = entities.find(
    (entity) => entity.name === entityType,
  );

  if (currentEntity) {
    return currentEntity.related_entities.filter((rel) => {
      const relatedEntity = entities.find((e) => e.name === rel.entity_type);
      if (relatedEntity) {
        return relatedEntity.is_internal;
      }
      return false;
    });
  } else {
    return [];
  }
};

// selects related internal entities for current editing event
// so does not require a prop to be passed in assuming current
// edit event matches the requested entity type.
// if not, see: selectRelatedInternalEntitiesForEntityType(state, entityType)
export const selectRelatedInternalEntities: (State) => EntityRelationship[] =
  createSelector<State, void, EntityRelationship[], AtsClientEntity[], string>(
    getAtsEntityMappings,
    eventEntityType,
    getEntityRelationsForEntityType,
  );

// select related internal entities for any given entity type
export const selectRelatedInternalEntitiesForEntityType: (
  State,
  EntityType,
) => EntityRelationship[] = createSelector<
  State,
  string,
  EntityRelationship[],
  AtsClientEntity[],
  string,
>(
  getAtsEntityMappings,
  (_, entityType) => entityType,
  getEntityRelationsForEntityType,
);

export const selectCandidateTabEntities: (State) => AtsClientEntity[] =
  createSelector<State, void, AtsEntity[], AtsEntity[]>(
    getAtsEntityMappings,
    (mappings) =>
      mappings
        .filter(({is_candidate_tab}) => is_candidate_tab)
        .sort((a, b) =>
          a.candidate_tab_order < b.candidate_tab_order ? -1 : 1,
        ),
  );

export const resolveAttributePathToEntityType = (
  entities: {[string]: AtsClientEntity},
  entityType: ?EntityType,
  attributeName: string,
): ?EntityType => {
  const attributePath = attributeName.split('/').slice(0, -1);
  // eslint-disable-next-line no-unused-vars
  for (const attributeName of attributePath) {
    entityType = getRelatedEntityType(entities, entityType, attributeName);
    if (!entityType) {
      return;
    }
  }
  return entityType;
};

export const getCandidateFieldName: (State) => string = (state) =>
  get(getAtsEntityMappings(state), ['candidate', 'name']);

const selectAllEntityRelationships: (State) => Map<number, EntityRelationship> =
  createSelector(getAtsEntityMappings, (schemas) =>
    schemas
      .values()
      // $FlowIssue iterator helpers
      .flatMap((schema) => schema.related_entities)
      .reduce(
        (map, relationship) => map.set(relationship.id, relationship),
        new Map(),
      ),
  );

function filterAndGetEntityFilters(
  attributes: Iterable<{...EntityAttributeSchema, ...}>,
): Iterator<EntityFilter> {
  return (
    // $FlowIssue iterator helpers
    Iterator.from(attributes)
      .filter((entityAttribute) => entityAttribute.add_to_filters)
      // $FlowFixMe[incompatible-exact] [v1.32.0]
      // $FlowFixMe[prop-missing] [v1.32.0]
      .map((attr) => ({
        ...attr,
        selected: false,
      }))
  );
}

export const selectEntityFilters: (State) => EntityFilter[] = createSelector(
  getEntityAttributeSchema,
  (entityAttributes) =>
    Array.from(filterAndGetEntityFilters(entityAttributes.values())),
);

export function filterEntityAttributesForEntityType(
  attributes: Map<string, EntityAttributeSchema>,
  entityType: EntityType,
): Iterator<EntityAttributeSchema> {
  // $FlowIssue iterator helpers are not declared
  return Iterator.from(attributes.values()).filter(
    (attribute) => attribute.entity_type === entityType,
  );
}

export function* getRelatedEntityAttributesForEntityType(
  mappings: EntityMappingsByName,
  relationships: Map<number, EntityRelationship>,
  attributes: Map<string, EntityAttributeSchema>,
  entityType: EntityType,
  depth: number = 0,
  // $FlowFixMe[incompatible-return] [v1.32.0]
): Iterator<RelatedEntityAttributeSchema> {
  const mapping = mappings[entityType];

  if (!mapping) {
    return;
  }

  // $FlowIssue iterator helpers
  yield* filterEntityAttributesForEntityType(attributes, entityType).map(
    (attr) => ({
      ...attr,
      relationshipPath: [],
      resolvedRelationshipPath: [],
    }),
  );

  depth++;
  // NOTE (kyle): currently we don't support any relationship depth higher than "3"
  if (depth < 3) {
    for (const relationship of mapping.related_entities) {
      const resolvedRelationshipPath = relationship.bridge_step1_id
        ? resolveBridgeRelationshipPath(relationships, relationship)
        : [relationship];

      yield* getRelatedEntityAttributesForEntityType(
        mappings,
        relationships,
        attributes,
        relationship.entity_type,
        depth,
      )
        // $FlowIssue iterator helpers
        .map((attr) => ({
          ...attr,
          relationshipPath: [relationship.name, ...attr.relationshipPath],
          resolvedRelationshipPath: [
            ...resolvedRelationshipPath,
            ...attr.resolvedRelationshipPath,
          ],
        }));
    }
  }
}

function resolveBridgeRelationshipPath(
  allRelationships: Map<number, EntityRelationship>,
  relationship: EntityRelationship,
) {
  return [relationship.bridge_step1_id, relationship.bridge_step2_id]
    .filter(Boolean)
    .map((bridgeId) => allRelationships.get(bridgeId));
}

export function resolveRelatedAttributeEntityType(
  attributeName: string,
  entityType: EntityType,
  mappings: EntityMappingsByName,
): ?EntityType {
  let mapping = mappings[entityType];

  for (const pathPart of attributeName.split('/').slice(0, -1)) {
    if (!mapping) {
      return;
    }

    const relationship = mapping.related_entities.find(
      (relationship) => relationship.name === pathPart,
    );

    if (!relationship) {
      // no relationship, either a custom var that doesn't fit related entity mapping
      // or a bad agency config
      return;
    }
    mapping = relationship && mappings[relationship.entity_type];
  }

  return mapping.name;
}

export const selectRelatedEntityAttributesForEntityType: (
  State,
  EntityType,
) => Array<RelatedEntityAttributeSchema> = createSelector(
  selectEntityMappingsByName,
  selectAllEntityRelationships,
  getEntityAttributeSchema,
  (_, entityType) => entityType,
  (...params) => Array.from(getRelatedEntityAttributesForEntityType(...params)),
);

export const selectEntityFiltersForType: (State, EntityType) => EntityFilter[] =
  createSelector(selectRelatedEntityAttributesForEntityType, (attributes) =>
    Array.from(filterAndGetEntityFilters(attributes)),
  );

export const selectCsvUploadEntities: (State) => AtsEntities = createSelector(
  getAtsEntityMappings,
  (mappings) => mappings.filter(({is_csv_entity}) => is_csv_entity),
);

export const chatbotAllowedEntityTypes: Set<EntitySenseName> = new Set([
  'candidate',
  'bh_job_submission',
]);
// TODO (kyle): remove this
export function selectIsChatbotWorkflow(_state: State): boolean {
  return true;
}
