var React = require('react');

function AlertModal (props) {
    return React.createElement("svg",props,React.createElement("path",{"fillRule":"evenodd","clipRule":"evenodd","d":"M12.508 3.85a1.25 1.25 0 00-1.016 0c-.108.048-.273.173-.552.567-.277.391-.598.944-1.06 1.742l-5.45 9.413c-.464.801-.785 1.357-.986 1.793-.204.44-.23.647-.218.765.036.352.221.672.508.881.095.07.288.15.77.194.479.044 1.12.045 2.046.045h10.9c.925 0 1.567 0 2.046-.045.482-.044.675-.124.77-.194a1.25 1.25 0 00.508-.881c.013-.118-.014-.325-.218-.765-.201-.436-.522-.992-.986-1.793L14.12 6.16c-.462-.798-.783-1.35-1.06-1.742-.279-.394-.444-.52-.552-.567zm-1.625-1.371a2.75 2.75 0 012.234 0c.485.216.844.614 1.167 1.071.322.454.677 1.067 1.115 1.825l.02.033 5.45 9.413.018.033c.44.76.797 1.375 1.03 1.882.237.51.404 1.02.35 1.55a2.75 2.75 0 01-1.119 1.938c-.43.313-.956.424-1.515.475-.556.051-1.267.051-2.145.051H6.512c-.878 0-1.59 0-2.145-.051-.56-.051-1.085-.162-1.515-.475a2.75 2.75 0 01-1.118-1.939c-.055-.529.112-1.04.348-1.55.234-.506.59-1.121 1.03-1.881l.02-.033 5.45-9.413.019-.033c.438-.758.793-1.37 1.115-1.825.323-.457.682-.855 1.167-1.071zM12 8.25a.75.75 0 01.75.75v4a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zM12 17a1 1 0 100-2 1 1 0 000 2z"}));
}

AlertModal.defaultProps = {"width":"24","height":"24","viewBox":"0 0 24 24"};

module.exports = AlertModal;

AlertModal.default = AlertModal;
