// @noflow

/* ----------------------------------
 * Messages from background.js
 * ---------------------------------- */

// sent to page.js on browserAction in order to render the sidebar
export const INIT_PAGE = 'INIT_PAGE';
// sent to app.jsx and page.js to toggle visibility of the extension
export const CLICKED_BROWSER_ACTION = 'CLICKED_BROWSER_ACTION';
// sent to chat/app.jsx (main app) to reset the connection retry duration
export const PORT_OPEN = 'PORT_OPEN';

/* ----------------------------------
 * Messages from page.js
 * ---------------------------------- */

// sent to search.jsx (main app) to indicate that ats sync is in progress
export const ATS_SYNCING = 'ATS_SYNCING';
// sent to search.jsx (main app) to indicate that ats sync is done
export const ATS_SYNC = 'ATS_SYNC';
// sent to app.jsx to show the update banner
export const UPDATE_AVAILABLE = 'UPDATE_AVAILABLE';
// sent to background.js after page loads
export const PAGE_LOAD = 'PAGE_LOAD';
export const WANDERLY_JOB_CLICK = 'WANDERLY_JOB_CLICK';

/* ----------------------------------
 * Other messages from the extension
 * ---------------------------------- */

// from phone-handler.js to app.jsx, background.js, and chat/app.jsx (main app)
export const CLICKED_NUMBER = 'CLICKED_NUMBER';
// sent from app.jsx to background.js to clear corresponding tab data
export const UNLOAD = 'UNLOAD';
export const REMOVE_PAGE = 'REMOVE_PAGE';
// from app.jsx to background.js
export const CLICKED_TOGGLER_ON = 'CLICKED_TOGGLER_ON';
// from page.js to background.js
export const CLICKED_TOGGLER_OFF = 'CLICKED_TOGGLER_OFF';
// from action-menu to reload iframe
export const RELOAD_SIDEBAR = 'RELOAD_SIDEBAR';
// from action-menu to fetch allowed domains list from server
export const GET_ATS_PLUGIN = 'GET_ATS_PLUGIN';

export const ANALYTICS_TRACK = 'ANALYTICS_TRACK';
export const MEETING_SCHEDULE_EVENT = 'MEETING_SCHEDULE_EVENT';
export const REQUEST_HCA_SYNC = 'REQUEST_HCA_SYNC';
export const REQUEST_ADD_HCA_BUTTON = 'REQUEST_ADD_HCA_BUTTON';
export const COPY_TEXT = 'COPY_TEXT';

/* ----------------------------------
 * Messages from Sense main app
 * ---------------------------------- */

// sent to page.js to scrape the current page for contacts
export const REQUEST_ATS_SYNC = 'REQUEST_ATS_SYNC';
export const UPDATE_UNREAD_COUNT = 'UPDATE_UNREAD_COUNT';
export const GET_TAB_DATA = 'GET_TAB_DATA';
export const UPDATE_APP_URL = 'UPDATE_APP_URL';
export const UPDATE_PHONE_NUMBER_SET_ID = 'UPDATE_PHONE_NUMBER_SET_ID';
export const UPDATE_AGENT_JOINED_QUEUE = 'UPDATE_AGENT_JOINED_QUEUE';

/* flow type definitions */
export type Message<T, P = null, M = null> = {
  type: T,
  payload: P,
  meta: M,
};

export type WanderlyJobData = {
  specialties: Array<string>,
  location: string,
  jobId: string,
};

export type InitPageMessage = Message<'INIT_PAGE', string>;
export type RemovePageMessage = Message<'REMOVE_PAGE'>;
export type UnloadMessage = Message<'UNLOAD'>;
export type ReloadSidebarMessage = Message<'RELOAD_SIDEBAR'>;
export type GetAtsPluginMessage = Message<'GET_ATS_PLUGIN'>;
export type PageLoadMessage = Message<'PAGE_LOAD'>;
export type PortOpen = Message<'PORT_OPEN'>;
export type ClickedNumberMessage = Message<
  'CLICKED_NUMBER',
  ExternalContact,
  {
    append: boolean,
  },
>;
export type ClickedBrowserActionMessage = Message<
  'CLICKED_BROWSER_ACTION',
  boolean,
>;
export type ClickedTogglerMessage = Message<'CLICKED_TOGGLER_ON'>;
export type AnalyticTrackMessage = Message<'ANALYTICS_TRACK'>;
export type ClickedBackgroundMessage = Message<'CLICKED_TOGGLER_OFF'>;
export type RequestAtsSyncMessage = Message<'REQUEST_ATS_SYNC'>;
export type AtsSyncingMessage = Message<'ATS_SYNCING'>;
export type AtsSyncMessage = Message<'ATS_SYNC', AtsContact[]>;
export type UpdateUnreadCountMessage = Message<'UPDATE_UNREAD_COUNT', number>;
export type UpdateAgentJoinedQueue = Message<
  'UPDATE_AGENT_JOINED_QUEUE',
  boolean,
>;
export type UpdatePhoneNumberSetId = Message<
  'UPDATE_PHONE_NUMBER_SET_ID',
  string,
>;
export type GetTabDataMessage = Message<'GET_TAB_DATA', string | void>;
export type UpdateAppUrlMessage = Message<'UPDATE_APP_URL', string>;
export type WanderlyJobMessage = Message<
  'WANDERLY_JOB_CLICK',
  {
    payload: {
      data: WanderlyJobData,
    },
  },
>;
export type MeetingScheduleEventMessage = Message<
  'MEETING_SCHEDULE_EVENT',
  MeetingsEventData,
>;

export type ExtensionMessage =
  | ClickedNumberMessage
  | ClickedBrowserActionMessage
  | ClickedTogglerMessage
  | ClickedBackgroundMessage
  | RemovePageMessage
  | UnloadMessage
  | ReloadSidebarMessage
  | GetAtsPluginMessage
  | PageLoadMessage
  | PortOpen
  | InitPageMessage
  | RequestAtsSyncMessage
  | AtsSyncingMessage
  | AtsSyncMessage
  | UpdateUnreadCountMessage
  | UpdateAgentJoinedQueue
  | GetTabDataMessage
  | UpdateAppUrlMessage
  | WanderlyJobMessage
  | UpdatePhoneNumberSetId
  | AnalyticTrackMessage
  | MeetingScheduleEventMessage;

export type AtsContact = {
  id?: string,
  name?: string,
  phoneNumber: string,
  type: string,
};

export type ExternalContact = {
  name?: string,
  id?: string,
  type?: string,
  phoneNumber: string,
};

export type MeetingsEventData = {
  candidateName: string,
  candidatePhone: string,
  candidateEmail: string,
  jobTitle: string,
  requisitionId: string,
  meetingDescription: string,
  applicationLink: string,
  anchorExternalSourceId: string,
};

export type CopyTextData = {type: string, text: string};
