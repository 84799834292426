// @flow

import type {Dispatch, ThunkAction} from 'src/reducers';

import * as reduxApi from 'src/utils/redux-api-v2';


export type EmailDNSRecord = {
  host: string,
  type: string,
  data: string,
  valid: boolean,
};

type RecordName =
  | 'dkim1'
  | 'dkim2'
  | 'mail_cname'
  | 'domain_cname'
  | 'owner_cname';

export type EmailDomain = {
  domain: string,
  dns: {[name: RecordName]: EmailDNSRecord},
};

export type EmailValidationResult = {
  domain: string,
  results: {
    [key: RecordName]: {
      valid: boolean,
      reason: string | null, // unused for now
    },
  },
};

export const RECEIVE = 'emailDNS/receive';
export const VALIDATE = 'emailDNS/validate';
export const CREATE = 'emailDNS/create';
export const DELETE = 'emailDNS/delete';

type ReceiveAction = {
  type: 'emailDNS/receive',
  payload: EmailDomain[],
};

type ValidateAction = {
  type: 'emailDNS/validate',
  payload: EmailValidationResult,
};

type CreateAction = {
  type: 'emailDNS/create',
  payload: EmailDomain,
};

type DeleteAction = {
  type: 'emailDNS/delete',
  payload: string, // domain
};

export type EmailDNSAction =
  | ReceiveAction
  | ValidateAction
  | CreateAction
  | DeleteAction;

export type ReceiveActionCreator = (payload: EmailDomain[]) => ReceiveAction;
export const receiveAction: ReceiveActionCreator = (payload) => ({
  type: RECEIVE,
  payload,
});

export type ValidateActionCreator = (
  payload: EmailValidationResult,
) => ValidateAction;
export const validateAction: ValidateActionCreator = (payload) => ({
  type: VALIDATE,
  payload,
});

export type CreateActionCreator = (payload: EmailDomain) => CreateAction;
export const createAction: CreateActionCreator = (payload) => ({
  type: CREATE,
  payload,
});

export type DeleteActionCreator = (payload: string) => DeleteAction;
export const deleteAction: DeleteActionCreator = (payload) => ({
  type: DELETE,
  payload,
});

export const getDomains = (): ThunkAction<EmailDomain[]> => (
  dispatch: Dispatch,
) =>
  dispatch(reduxApi.get('dns-v2/domains')).then((domains: EmailDomain[]) => {
    dispatch(receiveAction(domains));
    return domains;
  });

export const validateDomain = (
  domain: string,
): ThunkAction<EmailValidationResult> => (dispatch: Dispatch) =>
  dispatch(reduxApi.post(`dns-v2/domains/${domain}/validate`)).then(
    (results) => {
      const payload = {domain, results};
      dispatch(validateAction(payload));
      return payload;
    },
  );

export const createDomain = (domain: string): ThunkAction<EmailDomain> => (
  dispatch: Dispatch,
) =>
  dispatch(reduxApi.post(`dns-v2/domains`, {domain})).then((createdDomain) => {
    dispatch(createAction(createdDomain));
    return createdDomain;
  });

export const deleteDomain = (domain: string): ThunkAction<string> => (
  dispatch: Dispatch,
) =>
  dispatch(reduxApi.del(`dns-v2/domains/${domain}`)).then(() =>
    dispatch(deleteAction(domain)),
  );
