// @noflow

// Custom control for Zendesk support widget
// https://developer.zendesk.com/embeddables/docs/widget/api

import * as React from 'react';
import invariant from 'invariant';


interface ZendeskApi {
  setLocale: () => void;
  identify: () => void;
  hide: () => void;
  show: () => void;
  activate: () => void;
  setHelpCenterSuggestions: () => void;
}
type ZendeskWidget = ZendeskApi & Function;

class ZendeskTrigger extends React.Component<
  {className?: string, children: React.Node},
  {
    api: ZendeskWidget | null,
  },
> {
  state = {
    api: null,
  };

  componentDidMount() {
    const api = window.zE || null;
    this.setState({api});
  }

  _openChat() {
    // We need to get a fresh API reference every time since Zendesk
    // initializes this object in an unpredictable and opaque way
    const api = window.zE || null;
    if (api) {
      api(() => {
        api.activate();
      });
    } else {
      invariant(api, 'Zendesk widget not found at window.zE');
    }
  }
  openChat = this._openChat.bind(this);

  render() {
    if (!this.state.api) {
      return null;
    }
    return (
      <span className={this.props.className} onClick={this.openChat}>
        {this.props.children}
      </span>
    );
  }
}

export default ZendeskTrigger;
