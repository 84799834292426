// @flow strict
import * as React from 'react';
import {useDispatch} from 'react-redux';
import classify from 'src/utils/classify';
import {linkJobsToPrograms} from 'src/action-creators/referral-v2/referral-jobs.js';
import CloseIcon from 'src/images/designSystems2021/modal-close-icon.svg';
//$FlowFixMe
import {popModal} from 'src/action-creators/modal.js';
import InfoIcon from 'src/images/designSystems2021/infoIcon.svg';
import {Button} from 'src/designSystem2021Components/buttons.jsx';
import {Header, SubHeader} from 'src/designSystem2021Components/text-v2.jsx';
import bonusCss from 'src/components/referral-v2/configure/bonus-validity.css';
import css from 'src/components/referral-v2/programs-jobs/programs-jobs.css';


const UnlinkJProgramsConfirmModal = ({
  program,
  selectedItemsInPage,
  isAllSelected,
  programFilterId,
  pageNumber,
}: {
  program: {id: string, name: string},
  selectedItemsInPage: string[],
  isAllSelected: boolean,
  programFilterId: string,
  pageNumber: number,
}): React.Node => {
  const dispatch = useDispatch();

  const unlinkProgram = async () => {
    const body = {
      job_ids: selectedItemsInPage,
      program_ids: [program.id],
      action_type: 'unlink',
      applies_to_all: isAllSelected,
    };
    await dispatch(linkJobsToPrograms(body, programFilterId, pageNumber));
  };

  return (
    <div className={bonusCss.modalBackground}>
      <div className={bonusCss.modalHeader}>
        <div className={bonusCss.filterContent}>
          <InfoIcon />
          <Header className={bonusCss.headerText}>Unlink Job</Header>
        </div>
        <div onClick={() => dispatch(popModal())}>
          <CloseIcon className={bonusCss.pointer} />
        </div>
      </div>
      <div className={css.programsModalContent}>
        <div className={css.defaultProgramRight}>
          Are you sure you want to unlink selected jobs from
        </div>
        <SubHeader>{program.name} ?</SubHeader>
      </div>
      <div className={classify(css.linkJobsBtn, css.defaultProgramLeft)}>
        <Button
          className={css.defaultMargin}
          type="secondary"
          onClick={() => dispatch(popModal())}
        >
          Cancel
        </Button>
        <Button type="primary" onClick={unlinkProgram}>
          Yes, Unlink
        </Button>
      </div>
    </div>
  );
};

export default UnlinkJProgramsConfirmModal;
