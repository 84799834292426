// @flow

import type {ModuleEditorType} from 'src/types/survey';
// $FlowFixMe[untyped-type-import]
import type {BranchInfo} from 'src/stores/event-creation';
import type {SenseEvent} from 'src/types/events';

import * as React from 'react';
import {useSelector} from 'react-redux';

import {useJobMatchEnabledForEngage} from 'src/hooks/useAgencyConfig';
import {selectAgencyConfigFlag} from 'src/selectors/agency.js';

import {classify} from 'src/utils';
import {isSmsSurveyEvent} from 'src/utils/events';
import {Button} from 'src/components/lib/new-button';

import moduleComponents from 'src/components/workflow/event/content/module/components';

import ClickAway from 'src/components/lib/click-away';
import PlusIcon from 'src/images/icons/plus.svg';

import css from './add-module.css';


type Props = {
  onTypeSelect: (type: ModuleEditorType) => mixed,
  inBranch?: mixed,
  label?: string,
  eventType?: string,
  onDuplicateModule?: () => mixed,
  // $FlowFixMe[value-as-type] [v1.32.0]
  branchInfo?: BranchInfo,
  event?: SenseEvent,
};

const AddModuleNew = (props: Props): React.Element<'div'> => {
  const atsFieldWritebackEnabled = useSelector((state) =>
    selectAgencyConfigFlag(state, 'atsFieldWritebackEnabled'),
  );

  const isNotFirstMessageModule =
    Number(props.event?.questions.length) > 1 ||
    (Number(props.event?.questions.length) === 1 &&
      props.event?.questions[0]?.type !== 'message_survey_module');
  // hide dupe button for first message module

  const isBranchQuestion =
    !props.inBranch ||
    (props.inBranch && Number(props.branchInfo?.branch.questions.length) > 0);
  // hide dupe button before first module in branch is created

  //hide dupe button if last module is 'message_survey_module'
  const questions = props.event?.questions || [];
  const hideDuplicateButton =
    questions.length > 0 &&
    questions[questions.length - 1].type === 'message_survey_module';

  const showDuplicateButton =
    props.onDuplicateModule &&
    isNotFirstMessageModule &&
    isBranchQuestion &&
    !hideDuplicateButton;
  return (
    <div className={css.container}>
      <div className={css.moduleButtonsContainer}>
        <div>
          <ClickAway>
            {(info) => <DumbAddModule {...props} {...info} />}
          </ClickAway>
        </div>
        {atsFieldWritebackEnabled && (
          <Button
            icon={
              <PlusIcon className={css.colorClickable} width={12} height={12} />
            }
            onClick={() => props.onTypeSelect('message_survey_module')}
            className={css.addModuleButtonV2}
            size="medium"
          >
            Add message / jobs
          </Button>
        )}
        {showDuplicateButton && (
          <Button
            onClick={props.onDuplicateModule}
            className={classify(
              css.addModuleButtonV2,
              css.duplicateModuleButtonV2,
            )}
            size="medium"
          >
            Duplicate the module above
          </Button>
        )}
      </div>
    </div>
  );
};

const AddModule = (props: Props): React.Element<'div'> => {
  const atsFieldWritebackEnabled = useSelector((state) =>
    selectAgencyConfigFlag(state, 'atsFieldWritebackEnabled'),
  );

  const isNotFirstMessageModule =
    Number(props.event?.questions.length) > 1 ||
    (Number(props.event?.questions.length) === 1 &&
      props.event?.questions[0]?.type !== 'message_survey_module');
  // hide dupe button for first message module

  const isBranchQuestion =
    !props.inBranch ||
    (props.inBranch && Number(props.branchInfo?.branch.questions.length) > 0);
  // hide dupe button before first module in branch is created

  const showDuplicateButton =
    props.onDuplicateModule && isNotFirstMessageModule && isBranchQuestion;

  return (
    <div className={css.container}>
      <div>
        <ClickAway>
          {(info) => <DumbAddModule {...props} {...info} />}
        </ClickAway>
      </div>
      {atsFieldWritebackEnabled && (
        <Button
          icon={
            <PlusIcon className={css.colorClickable} width={12} height={12} />
          }
          onClick={() => props.onTypeSelect('message_survey_module')}
          className={css.addModuleButtonV2}
          size="medium"
        >
          Add message
        </Button>
      )}
      {showDuplicateButton && (
        <Button
          onClick={props.onDuplicateModule}
          className={classify(
            css.addModuleButtonV2,
            css.duplicateModuleButtonV2,
          )}
          size="medium"
        >
          Duplicate the module above
        </Button>
      )}
    </div>
  );
};
const FeatureFlagWrapper = (props: Props): React.Node => {
  //SM-125 disable job-match notification for sms_survey event
  const renderAddModuleWithJobMatch =
    useJobMatchEnabledForEngage() &&
    props.event &&
    !isSmsSurveyEvent(props.event?.type);
  if (renderAddModuleWithJobMatch) {
    return <AddModuleNew {...props} />;
  }
  return <AddModule {...props} />;
};

export default FeatureFlagWrapper;

const DumbAddModule = ({
  onTypeSelect,
  inBranch,
  onOpen,
  isOpen,
  height,
  label = 'Add Module',
  anchorRef,
}: {
  ...Props,
  onOpen: () => void,
  isOpen: boolean,
  height: ?number,
  pageBottom: ?number,
  label?: string,
  anchorRef: React.Ref<'div'>,
  ...
}) => {
  const atsFieldWritebackEnabled = useSelector((state) =>
    selectAgencyConfigFlag(state, 'atsFieldWritebackEnabled'),
  );

  let moduleOrder: ModuleEditorType[] = [
    'rating_scale_survey_question',
    'nps_survey_question',
    'multiple_choice_survey_question',
    // TODO (gab): checkboxes - uncomment when server is ready
    // 'checkboxes_survey_question',
  ];

  if (atsFieldWritebackEnabled) {
    moduleOrder = [
      'field_writeback_module',
      ...moduleOrder,
      'calendar_date_survey_question',
    ];
  } else {
    moduleOrder = [
      'text_survey_question',
      ...moduleOrder,
      'message_survey_module',
    ];
  }

  const onAddSurveyModuleOpen = () => {
    if (!isOpen) {
      onOpen();
    }
  };

  return (
    <div className={css.addModuleAnchor} style={{height}} ref={anchorRef}>
      <div
        className={classify(css.addModuleContainer, {
          [css.open]: isOpen,
          [css.inBranch]: inBranch,
          [css.mounted]: height,
        })}
      >
        <button
          className={css.addModuleButton}
          onClick={onAddSurveyModuleOpen}
          data-qa-id="add-question-button"
        >
          <PlusIcon className={css.colorClickable} width={12} height={12} />
          {atsFieldWritebackEnabled ? 'Add survey question' : label}
        </button>

        {isOpen && (
          <div className={css.moduleMenu}>
            {moduleOrder.map((type) => {
              const {Icon, text} = moduleComponents[type];
              return (
                <button
                  className={classify(css.moduleTypeButton, css[type])}
                  onClick={() => onTypeSelect(type)}
                  key={type}
                >
                  <Icon />
                  {text}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
