var React = require('react');

function OptionsIcon (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M11 7.344a1.344 1.344 0 100-2.688 1.344 1.344 0 000 2.688zM12.344 11a1.344 1.344 0 11-2.688 0 1.344 1.344 0 012.688 0zM12.344 16a1.344 1.344 0 11-2.688 0 1.344 1.344 0 012.688 0z"}));
}

OptionsIcon.defaultProps = {"width":"22","height":"22","viewBox":"0 0 22 22"};

module.exports = OptionsIcon;

OptionsIcon.default = OptionsIcon;
