// @noflow

import type {
  Dimensions,
  Margins,
  NewMargins,
} from 'src/components/lib/charts/with-chart-dimensions.jsx';

import * as React from 'react';
import {
  scaleBand as d3ScaleBand,
  scaleLinear as d3ScaleLinear,
  scaleOrdinal as d3ScaleOrdinal,
} from 'd3-scale';
import flow from 'lodash/flow';
import max from 'lodash/max';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';
import take from 'lodash/take';
import {classify} from 'src/utils';

import AddButton from 'src/components/lib/add-button';
import {SvgAxis} from 'src/components/lib/charts/axis.jsx';
import Grid from 'src/components/lib/charts/grid.jsx';
import Legend from 'src/components/lib/charts/legend.jsx';
import {MouseTip} from 'src/components/lib/mouse-tip/mouse-tip.jsx';
import withChartDimensions from 'src/components/lib/charts/with-chart-dimensions.jsx';
import chartColors from 'src/components/lib/charts/colors';

import chartsCss from 'src/components/lib/charts/charts.css';

// NOTE(elliot): DataRow is a row in tabular data with key names indicating column names.
export type DataRow = {[key: string]: any};
export type Data = DataRow[];

const MIN_BAR_WIDTH_LABEL = 30;
const MIN_BAR_HEIGHT_LABEL = 14;

type Props = {
  barDirection: 'horizontal' | 'vertical',
  barLabelPadding: number,
  barAxisPadding: number,
  barPaddingInner: number,
  barPaddingOuter: number,
  barProps?: Object,
  categoryKey: string,
  categoryAccessor?: (DataRow) => number,
  chartClassName?: string,
  chartContentClassName?: string,
  className?: string,
  colors: string[],
  colorScale?: (string) => string,
  data: Data,
  dataKey?: string,
  dataAccessor: (dataRow: DataRow, key?: string) => number,
  dimensions: Dimensions,
  emptyBarSize: number,
  forceZeroState?: boolean,
  getBarLabel?: (bar: {
    category: string,
    value: number,
    group?: string,
    stack?: string,
    dataRow?: DataRow,
    stacks?: string[],
  }) => string,
  getTooltipText?: (tooltip: {
    category: string,
    value: number,
    group?: string,
    stack?: string,
    dataRow?: DataRow,
    stacks?: string[],
  }) => string,
  groupBarPaddingInner: number,
  groupBarPaddingOuter: number,
  grouped?: boolean,
  groupStacks: Array<string | string[]>,
  labelOnBar?: boolean,
  onBarClick?: (bar: {
    category: string,
    value: number,
    color: string,
    group?: string,
    stack?: string,
  }) => void,
  onShowMore?: () => void,
  onChartRef: (ref: ?Element) => void,
  margins: Margins,
  notPercent: boolean,
  selected?: ?{
    color: string,
    value: string,
  },
  showAxes?: boolean,
  showEmptyBars?: boolean,
  visibleCount?: number,
  showXAxis?: boolean,
  showYAxis?: boolean,
  showGrid?: boolean,
  showLegend?: boolean,
  sortComparator?: ?(a: DataRow, b: DataRow) => number,
  stacked?: boolean,
  tickCount: number,
  title?: string,
  updateMargins: (margins: NewMargins) => void,
  xAxisProps?: Object,
  yAxisProps?: Object,
  zeroBarPadding: number,
};

class BarChart extends React.PureComponent<Props> {
  setMarginForButtonWidth: ?boolean;

  static defaultProps = {
    colors: chartColors,
    emptyBarSize: 0,
    notPercent: true,
    barAxisPadding: 0,
    barLabelPadding: 4,
    barPaddingInner: 0.1,
    barPaddingOuter: 0,
    groupBarPaddingInner: 0.15,
    groupBarPaddingOuter: 0.15,
    tickCount: 4,
    dataAccessor: (dataRow, key) => dataRow[key],
    zeroBarPadding: 0,
    data: [],
  };

  renderBars({
    categoryScale,
    dataScale,
    colorScale,
    groupScale,
    isVertical,
    barData,
  }: {
    categoryScale: (string) => number,
    dataScale: (number) => number,
    colorScale: (string) => string,
    groupScale?: (string) => number,
    isVertical: boolean,
    barData: Data,
  }) {
    const {
      barAxisPadding,
      barLabelPadding,
      categoryAccessor,
      dataAccessor,
      emptyBarSize,
      dimensions,
      getBarLabel,
      getTooltipText,
      groupStacks,
      onBarClick,
      selected,
      zeroBarPadding,
      showEmptyBars = true,
      labelOnBar,
    } = this.props;
    return barData.map((dataRow) => {
      const category = categoryAccessor
        ? categoryAccessor(dataRow)
        : dataRow[this.props.categoryKey];
      const groups = groupStacks ? groupStacks : [null];
      return groups.map((group) => {
        const stacks = Array.isArray(group) ? group : [null];
        let stackOffset = 0;
        const position =
          categoryScale(category) + (groupScale ? groupScale(group) : 0);
        const bandwidth = (groupScale || categoryScale).bandwidth();
        const barSum = sumBy(stacks, (stack) =>
          dataAccessor(dataRow, stack || group || this.props.dataKey),
        );
        if (barSum === 0 && emptyBarSize === 0 && showEmptyBars) {
          return (
            <Bar
              key={`${category} ${group}`}
              barPosition={{
                x: isVertical ? position : zeroBarPadding,
                y: isVertical ? zeroBarPadding : position,
                width: isVertical
                  ? bandwidth
                  : dimensions.innerWidth - zeroBarPadding * 2,
                height: isVertical
                  ? dimensions.innerHeight - zeroBarPadding * 2
                  : bandwidth,
              }}
              isVertical={isVertical}
              barProps={{
                strokeDasharray: '5,5,5,5',
                stroke: chartsCss.colorGray7,
              }}
              color="transparent"
              tooltipText={
                getTooltipText
                  ? getTooltipText({category, group, value: 0})
                  : null
              }
            />
          );
        }
        return stacks.map((stack, index) => {
          const dataKey = stack || group || this.props.dataKey;
          const value = dataAccessor(dataRow, dataKey);
          const isEmptyBar = value === 0;
          const color = colorScale(stack || group || category);
          const dataPosition = Math.max(dataScale(value), 0);
          const barPosition = {
            x: isVertical ? position : barAxisPadding + stackOffset,
            y: isVertical ? dataPosition - stackOffset : position,
            width: isVertical ? bandwidth : dataPosition,
            height: isVertical
              ? dimensions.innerHeight -
                dataPosition -
                (dataPosition === 0 ? barAxisPadding : 0)
              : bandwidth,
          };
          stackOffset += Math.abs(
            (isVertical ? dimensions.innerHeight : 0) - dataPosition,
          );
          return (
            <Bar
              key={`${category} ${group} ${stack}`}
              barLabel={
                getBarLabel && index === stacks.length - 1 //only show label total on the top bar in a stack
                  ? getBarLabel({
                      category,
                      group,
                      stack,
                      stacks,
                      value,
                      dataRow,
                    })
                  : undefined
              }
              barLabelPadding={barLabelPadding}
              barPosition={barPosition}
              barPosition={{
                ...barPosition,
                ...(isEmptyBar && emptyBarSize > 0
                  ? getEmptyBarProps(barPosition, isVertical, emptyBarSize)
                  : {}),
              }}
              barProps={this.props.barProps}
              color={
                !selected || selected.value === category
                  ? color
                  : chartsCss.colorLightGray
              }
              isVertical={isVertical}
              onBarClick={
                onBarClick
                  ? () => onBarClick({category, group, stack, value, color})
                  : null
              }
              tooltipText={
                getTooltipText
                  ? getTooltipText({
                      category,
                      group,
                      stack,
                      stacks,
                      value,
                      dataRow,
                    })
                  : null
              }
              labelOnBar={labelOnBar}
            />
          );
        });
      });
    });
  }

  getChartProps() {
    const {
      barDirection,
      categoryAccessor,
      barPaddingInner,
      barPaddingOuter,
      colors,
      data,
      dataAccessor,
      dimensions,
      groupBarPaddingInner,
      groupBarPaddingOuter,
      grouped,
      groupStacks,
      sortComparator,
      stacked,
      tickCount,
      visibleCount,
    } = this.props;

    const isVertical = barDirection === 'vertical';
    const widthRange = [0, dimensions.innerWidth];
    const heightRange = [dimensions.innerHeight, 0];

    let barData = data;
    if (sortComparator) {
      barData.sort(sortComparator);
    }
    if (visibleCount) {
      barData = take(barData, visibleCount);
    }

    const categories = barData.map((dataRow) =>
      categoryAccessor
        ? categoryAccessor(dataRow)
        : dataRow[this.props.categoryKey],
    );
    // NOTE(elliot): Must reverse data because when bars are horizontal, they get
    // painted from bottom to top.
    if (!isVertical) {
      categories.reverse();
    }
    const groups = grouped ? groupStacks : null;
    const stacks = stacked ? [].concat(...groupStacks) : null;
    let flattenedData;
    if (stacked) {
      flattenedData = [].concat(
        ...barData.map((dataRow) =>
          groupStacks.map(
            flow(
              (stacks) => stacks.map((stack) => dataAccessor(dataRow, stack)),
              sum,
            ),
          ),
        ),
      );
    } else if (grouped) {
      flattenedData = [].concat(
        ...barData.map((dataRow) =>
          groups.map((group) => dataAccessor(dataRow, group)),
        ),
      );
    } else {
      flattenedData = barData.map((dataRow) =>
        dataAccessor(dataRow, this.props.dataKey),
      );
    }
    const zeroData = sum(flattenedData) === 0;

    const categoryScale = d3ScaleBand()
      .domain(categories)
      .paddingInner(barPaddingInner)
      .paddingOuter(barPaddingOuter)
      .rangeRound(isVertical ? widthRange : heightRange);

    const dataScale = d3ScaleLinear()
      .domain([0, max(flattenedData)])
      .nice(tickCount)
      .range(isVertical ? heightRange : widthRange);

    const colorDomain = stacked ? stacks : grouped ? groups : categories;
    const colorScale =
      this.props.colorScale ||
      d3ScaleOrdinal().domain(colorDomain).range(colors);

    const dataTicks = dataScale.ticks(tickCount);

    const xScale = isVertical ? categoryScale : dataScale;
    const yScale = isVertical ? dataScale : categoryScale;
    const xTicks = isVertical ? categories : dataTicks;
    const yTicks = isVertical ? dataTicks : categories;

    const chartProps = {
      isVertical,
      barData,
      categories,
      categoryScale,
      dataScale,
      colorDomain,
      colorScale,
      xScale,
      yScale,
      xTicks,
      yTicks,
      zeroData,
    };

    if (grouped) {
      const bandwidth = categoryScale.bandwidth();
      const groupScale = d3ScaleBand()
        .paddingInner(groupBarPaddingInner)
        .paddingOuter(groupBarPaddingOuter)
        .domain(groups)
        .rangeRound(isVertical ? [0, bandwidth] : [bandwidth, 0]);
      return {
        ...chartProps,
        groupScale,
      };
    }
    return chartProps;
  }

  render() {
    const {
      chartClassName,
      chartContentClassName,
      className,
      dimensions,
      forceZeroState,
      onChartRef,
      onShowMore,
      margins,
      notPercent,
      showAxes,
      showXAxis,
      showYAxis,
      showGrid,
      showLegend,
      title,
      updateMargins,
      visibleCount,
      xAxisProps,
      yAxisProps,
    } = this.props;
    const chartProps = this.getChartProps();
    const showChart =
      !forceZeroState &&
      chartProps.categories.length > 0 &&
      !chartProps.zeroData;
    return (
      <div className={classify(chartsCss.container, className)}>
        {(title || showLegend) && (
          <div className={chartsCss.header}>
            <div className={chartsCss.title}>
              <h4 className={chartsCss.h4}>{title}</h4>
            </div>
            <div className={chartsCss.titlePad} />
            <Legend
              keys={chartProps.colorDomain}
              colorScale={chartProps.colorScale}
            />
          </div>
        )}

        {showChart ? (
          <div className={classify(chartsCss.content, chartContentClassName)}>
            <div
              className={classify(chartsCss.chart, chartClassName)}
              ref={onChartRef}
            >
              <svg
                className={chartsCss.svg}
                width={dimensions.width}
                height={dimensions.height}
                viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
                preserveAspectRatio="none"
              >
                {(showAxes || showYAxis) && (
                  <SvgAxis
                    dimensions={dimensions}
                    scale={chartProps.yScale}
                    ticks={chartProps.yTicks}
                    notPercent={notPercent}
                    orientation="left"
                    title={!notPercent ? '%' : null}
                    showAllLabels={!chartProps.isVertical}
                    showAxisLine={true}
                    {...yAxisProps}
                    ref={(ref: ?SvgAxis) => {
                      if (ref) {
                        const {width} = ref.getSize() || {};
                        if (
                          !this.setMarginForButtonWidth &&
                          width !== margins.left
                        ) {
                          updateMargins({left: width});
                        }
                      }
                    }}
                  />
                )}
                {showGrid && (
                  <Grid
                    dimensions={dimensions}
                    yScale={chartProps.yScale}
                    yTicks={chartProps.yTicks}
                  />
                )}
                <g
                  transform={`translate(${dimensions.left}, ${dimensions.top})`}
                >
                  {this.renderBars(chartProps)}
                </g>
                {(showAxes || showXAxis) && (
                  <SvgAxis
                    dimensions={dimensions}
                    showAllLabels={true}
                    notPercent={notPercent}
                    orientation="bottom"
                    scale={chartProps.xScale}
                    showAxisLine={true}
                    showTicks={true}
                    ticks={chartProps.xTicks}
                    ref={(ref: ?SvgAxis) => {
                      if (ref) {
                        const {height} = ref.getSize() || {};
                        if (height !== margins.bottom) {
                          updateMargins({bottom: height});
                        }
                      }
                    }}
                    {...xAxisProps}
                    {...(chartProps.isVertical &&
                    chartProps.categories.length >= 20
                      ? {
                          tickLabelProps: {
                            transform: 'rotate(-65)',
                            textAnchor: 'end',
                          },
                        }
                      : {})}
                  />
                )}
                {showGrid && !chartProps.isVertical && (
                  <Grid
                    dimensions={dimensions}
                    xScale={chartProps.xScale}
                    xTicks={chartProps.xTicks}
                  />
                )}
              </svg>
            </div>
            {visibleCount && onShowMore && (
              <AddButton
                text="Show More"
                onClick={onShowMore}
                className={chartsCss.showMoreButton}
                style={{
                  left: 12,
                  top: dimensions.bottom,
                }}
                ref={(ref: ?HTMLButtonElement) => {
                  if (ref) {
                    const {width} = ref.getBoundingClientRect();
                    if (width > margins.left) {
                      this.setMarginForButtonWidth = true;
                      updateMargins({left: width});
                    } else {
                      this.setMarginForButtonWidth = false;
                    }
                  }
                }}
              />
            )}
          </div>
        ) : (
          <div className={chartsCss.zero}>No data yet</div>
        )}
      </div>
    );
  }
}

export default withChartDimensions(BarChart);

const Bar = ({
  barLabel,
  barLabelPadding = 0,
  barPosition,
  barProps,
  color,
  isVertical = false,
  labelOnBar = false,
  onBarClick,
  tooltipText,
}: {
  barLabel?: string,
  barLabelPadding?: number,
  barPosition: {
    x: number,
    y: number,
    width: number,
    height: number,
  },
  barProps?: Object,
  color: string,
  isVertical?: boolean,
  labelOnBar?: boolean,
  onBarClick: ?() => void,
  tooltipText: ?string,
}) => {
  // NOTE(elliot): Needs an svg tag for text centering on the
  // bar because group tags won't have relative positioning.
  const barLabelProps = labelOnBar
    ? {
        x: barPosition.width / 2,
        y: barPosition.height / 2,
        textAnchor: 'middle',
        dominantBaseline: 'middle',
        fontWeight: 'bold',
        fill: chartsCss.colorWhite,
      }
    : {
        transform: `translate(${isVertical ? 0 : barLabelPadding}, ${
          isVertical ? -barLabelPadding : 0
        })`,
        textAnchor: isVertical ? 'middle' : null,
        dominantBaseline: isVertical ? 'text-after-edge' : 'middle',
        x: barPosition.width * (isVertical ? 1 / 2 : 1),
        y: barPosition.height * (isVertical ? 0 : 1 / 2),
      };
  const showLabel =
    barLabel &&
    (!labelOnBar ||
      (barPosition.width >= MIN_BAR_WIDTH_LABEL &&
        barPosition.height >= MIN_BAR_HEIGHT_LABEL));
  const bar = (
    <g
      className={onBarClick ? chartsCss.clickable : undefined}
      onClick={onBarClick}
      transform={`translate(${barPosition.x}, ${barPosition.y})`}
      {...barPosition}
    >
      <rect
        fill={color}
        width={barPosition.width}
        height={barPosition.height}
        {...barProps}
      />
      {showLabel && <text {...barLabelProps}>{barLabel}</text>}
    </g>
  );
  return tooltipText ? <MouseTip content={tooltipText}>{bar}</MouseTip> : bar;
};

const getEmptyBarProps = (
  barPosition: {
    x: number,
    y: number,
    width: number,
    height: number,
  },
  isVertical: boolean,
  emptyBarSize: number,
) => {
  if (isVertical) {
    return {
      y: barPosition.y - emptyBarSize,
      height: emptyBarSize,
    };
  }
  return {
    width: emptyBarSize,
  };
};
