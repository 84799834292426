//@flow strict
import * as React from 'react';

//$FlowFixMe[nonstrict-import]
import {AnalyticsService} from 'src/analytics';


type Handler<A> = (args: A) => mixed;
/**
 * @deprecated will be removed. Please use useTrackedAction instead.
 */
export function useTrackingHandler<A>(
  eventIdentifier: string,
  handler?: Handler<A>,
  payload?: {...},
): Handler<A> {
  return (args: A) => {
    try {
      AnalyticsService.track(eventIdentifier, payload);
    } catch (e) {
      //do nothing for now
    }
    handler?.(args);
  };
}

export function useTrackedAction<A>(
  eventIdentifier: string,
  action: Handler<A>,
  payload?: ?{...},
): Handler<A> {
  const payloadRef = React.useRef(payload);

  //update payloadRef when payload changes
  React.useEffect(() => {
    payloadRef.current = payload;
  }, [payload]);

  const trackedAction = React.useCallback(
    async (args: A) => {
      try {
        AnalyticsService.track(eventIdentifier, payloadRef.current ?? {});
      } catch (e) {
        //do nothing for now
      }
      await action(args);
    },
    [eventIdentifier, action],
  );

  return trackedAction;
}
