// @flow strict-local

import * as React from 'react';
import {UnstyledButton} from '@spaced-out/ui-lib/lib/button';

import classify from 'src/utils/classify';

import css from './tab-group.css';


export function TabGroup<T: string>({
  tabs,
  currentTab,
  onChange,
  children,
}: {
  tabs: Array<{
    id: T,
    title: string,
    error?: mixed,
    content: React.Node,
  }>,
  currentTab: T,
  onChange: (T) => mixed,
  children: React.Node,
}): React.Node {
  return (
    <>
      <div className={css.tabBar}>
        {tabs.map((tab) => (
          <UnstyledButton
            className={classify(
              css.tab,
              currentTab === tab.id && css.active,
              tab.error && css.error,
            )}
            key={tab.id}
            onClick={() => onChange(tab.id)}
          >
            {tab.title}
          </UnstyledButton>
        ))}
      </div>
      <div className={css.body}>
        {tabs.find((tab) => tab.id === currentTab)?.content}
        {children}
      </div>
    </>
  );
}
