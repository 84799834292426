// @flow

import type {GetState, Dispatch, ThunkAction} from 'src/reducers';
import type {
  EntityAttributeMappingDiff,
  EntityAttributeSchema,
  AtsEntities,
  Usage,
  EntityMetadataObject,
} from 'src/types/ats-entities';

import * as reduxApi from 'src/utils/redux-api.js';
import {key, cached, fetching, force} from 'src/utils/redux';
import parsers from 'src/api-parsers';
import {snake} from 'src/utils';
import {thunkify as flow} from 'src/utils/thunks';
import * as reduxApiBff from 'src/utils/redux-api-bff';


export type AtsEntityAction =
  | ReceiveAtsEntityAttributesAction
  | ReceiveAtsEntitiesUsageAction
  | ReceiveAtsEntitiesAction
  | ReceiveEntityMetadataAction;

export const RECEIVE_ENTITIES = 'ats-entities/receiveAll';
export const RECEIVE_ENTITIES_USAGE = 'ats-entities/usages';
export const RECEIVE_ATTRIBUTES = 'ats-entities/attributes/receiveAll';
export const RECEIVE_METADATA = 'ats-entities/attributes/metadata';

type ReceiveAtsEntitiesAction = {
  type: 'ats-entities/receiveAll',
  payload: AtsEntities,
};

type ReceiveAtsEntitiesUsageAction = {
  type: 'ats-entities/usages',
  payload: Usage[],
};

const receiveEntities = (entities): ReceiveAtsEntitiesAction => ({
  type: RECEIVE_ENTITIES,
  payload: entities,
});

const receiveAttributesUsage = (entities): ReceiveAtsEntitiesUsageAction => ({
  type: RECEIVE_ENTITIES_USAGE,
  payload: entities,
});

export const getAllEntities: () => ThunkAction<mixed> = flow(
  key(() => 'ats-entities/all'),
  cached((entities) => receiveEntities(entities), {
    ttl: 86_400_000,
  }),
  fetching(),
)(() => (dispatch: Dispatch, getState: GetState) => {
  if (getState().agency.config.multiEntityEnabled) {
    return dispatch(reduxApi.get('entities/types'));
  }

  return Promise.resolve({});
});

type ReceiveEntityMetadataAction = {
  type: 'ats-entities/attributes/metadata',
  payload: {[string]: EntityMetadataObject},
};

const receiveEntityMetadata = (metadata: {
  [string]: EntityMetadataObject,
}): ReceiveEntityMetadataAction => ({
  type: RECEIVE_METADATA,
  payload: metadata,
});

type ReceiveAtsEntityAttributesAction = {
  type: 'ats-entities/attributes/receiveAll',
  payload: Array<EntityAttributeSchema>,
};

const receiveAttributes = (
  attributes: Array<EntityAttributeSchema>,
): ReceiveAtsEntityAttributesAction => ({
  type: RECEIVE_ATTRIBUTES,
  payload: attributes,
});

export const getEntityMetadata: () => ThunkAction<mixed> = flow(
  key(() => 'ats-entities/attributes/metadata'),
  cached(
    ({entityMetadata}) =>
      receiveEntityMetadata(parsers.parse.entityMetadata(entityMetadata)),
    {
      ttl: 86_400_000,
    },
  ),
  fetching(),
)(
  () => (dispatch: Dispatch) =>
    dispatch(reduxApiBff.post('entity_metadata', {})),
);

export const getAllEntityAttributes: () => ThunkAction<mixed> = flow(
  key(() => 'ats-entities/attributes'),
  cached((attributes) => receiveAttributes(attributes), {
    ttl: 86_400_000,
  }),
  fetching(),
)(
  () => (dispatch: Dispatch) =>
    dispatch(reduxApi.get('entities/attributes/details')),
);

export const getAllEntityAttributesUsage: () => ThunkAction<mixed> = flow(
  key(() => 'ats-entities/attributes/usage'),
  cached((entitiesMap) => receiveAttributesUsage(entitiesMap), {
    ttl: 86_400_000,
  }),
  fetching(),
)(
  () => (dispatch: Dispatch) =>
    dispatch(reduxApi.get('entities/schema-attributes/usages')),
);

export const saveEntitiesBatch =
  (changes: EntityAttributeMappingDiff[]): ThunkAction<mixed> =>
  async (dispatch: Dispatch) => {
    changes = changes.map((change) => ({
      ...change,
      display_name: change.display_name?.trim(),
    }));
    await dispatch(
      reduxApi.post(
        'entities/schema-attributes/batch',
        snake({
          changes,
        }),
      ),
    );
    await dispatch(force(getAllEntityAttributes()));
  };
