// @flow strict
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {
  TransactionHistory,
  PaymentBalance,
  FetchTransactionHistoryPayload,
  MinimumBalanceThresholdPayload,
  PaypalCredentials,
  GenericError,
} from 'src/types/referral-v2';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api.js';
//$FlowFixMe[nonstrict-import]
import {showApiError} from 'src/action-creators/modal';

import {toastApi} from '@spaced-out/ui-design-system/lib/components/index';

import {thresholdUpdateMessage} from 'src/components/referral-v2/settings/root.jsx';
import {DEFAULT_ERROR} from 'src/action-creators/referral-v2/referral-jobs.js';


export const RECEIVE_TRANSACTION_HISTORY_LOADING =
  'referral/receiveTransactionHistoryLoading';
export const RECEIVE_TRANSACTION_HISTORY = 'referral/receiveTransactionHistory';
export const RECEIVE_TRANSACTION_HISTORY_ERROR =
  'referral/receiveTransactionHistoryError';

export const RECEIVE_PAYMENT_BALANCE_LOADING =
  'referral/receivePaymentBalanceLoading';
export const RECEIVE_PAYMENT_BALANCE = 'referral/receivePaymentBalance';
export const RECEIVE_PAYMENT_BALANCE_ERROR =
  'referral/receivePaymentBalanceError';

type ReceiveTransactionHistoryLoadingAction = {
  type: typeof RECEIVE_TRANSACTION_HISTORY_LOADING,
};

type ReceiveTransactionHistoryAction = {
  type: typeof RECEIVE_TRANSACTION_HISTORY,
  payload: TransactionHistory,
};

type ReceiveTransactionHistoryErrorAction = {
  type: typeof RECEIVE_TRANSACTION_HISTORY_ERROR,
  payload: ?GenericError,
};

type PaymentBalanceLoadingAction = {
  type: typeof RECEIVE_PAYMENT_BALANCE_LOADING,
};

type PaymentBalanceAction = {
  type: typeof RECEIVE_PAYMENT_BALANCE,
  payload: PaymentBalance,
};

type PaymentBalanceErrorAction = {
  type: typeof RECEIVE_PAYMENT_BALANCE_ERROR,
  payload: ?GenericError,
};

export type ReferralPaymentActions =
  | ReceiveTransactionHistoryAction
  | ReceiveTransactionHistoryLoadingAction
  | ReceiveTransactionHistoryErrorAction
  | PaymentBalanceLoadingAction
  | PaymentBalanceAction
  | PaymentBalanceErrorAction;

export const receiveTransactionHistoryLoading =
  (): ReceiveTransactionHistoryLoadingAction => ({
    type: RECEIVE_TRANSACTION_HISTORY_LOADING,
  });

export const receiveTransactionHistory = (
  response: TransactionHistory,
): ReceiveTransactionHistoryAction => ({
  type: RECEIVE_TRANSACTION_HISTORY,
  payload: response,
});

export const receiveTransactionHistoryError = (
  payload: ?GenericError,
): ReceiveTransactionHistoryErrorAction => ({
  type: RECEIVE_TRANSACTION_HISTORY_ERROR,
  payload,
});

export const receivePaymentBalanceLoading =
  (): PaymentBalanceLoadingAction => ({
    type: RECEIVE_PAYMENT_BALANCE_LOADING,
  });

export const receivePaymentBalance = (
  payload: PaymentBalance,
): PaymentBalanceAction => ({
  type: RECEIVE_PAYMENT_BALANCE,
  payload,
});

export const receivePaymentBalanceError = (
  payload: ?GenericError,
): PaymentBalanceErrorAction => ({
  type: RECEIVE_PAYMENT_BALANCE_ERROR,
  payload,
});

export const getTransactionHistory: (
  payload: FetchTransactionHistoryPayload,
) => ThunkAction<void> = flow(
  key(() => `referral/payment/history`),
  fetching(),
)((payload) => (dispatch: Dispatch) => {
  dispatch(receiveTransactionHistoryLoading());
  return dispatch(reduxApi.get(`referral/payment/history`, payload)).then(
    (response: TransactionHistory) => {
      dispatch(receiveTransactionHistory(response));
    },
    (error) => {
      dispatch(
        receiveTransactionHistoryError(
          error?.response?.errors || DEFAULT_ERROR,
        ),
      );
    },
  );
});

export const getPaymentBalance: () => ThunkAction<void> = flow(
  key(() => `referral/payment/balance`),
  fetching(),
)(() => (dispatch: Dispatch) => {
  dispatch(receivePaymentBalanceLoading());
  return dispatch(reduxApi.get(`referral/payment/balance`)).then(
    (response: PaymentBalance) => {
      dispatch(receivePaymentBalance(response));
    },
    (error) => {
      dispatch(
        receivePaymentBalanceError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const retryTransaction: (
  transactionId: number,
  filter: FetchTransactionHistoryPayload,
) => ThunkAction<void> = flow()(
  (transactionId, filter) => (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.post(`referral/payment/${transactionId}/retry`),
    ).then(
      () => {
        dispatch(getTransactionHistory(filter));
      },
      (error) => {
        dispatch(showApiError(error, error?.response?.message));
      },
    );
  },
);

export const cancelTransaction: (
  transactionId: number,
  filter: FetchTransactionHistoryPayload,
) => ThunkAction<void> = flow()(
  (transactionId, filter) => (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.post(`referral/payment/${transactionId}/cancel`),
    ).then(
      () => {
        dispatch(getTransactionHistory(filter));
      },
      (error) => {
        dispatch(showApiError(error, error?.response?.message));
      },
    );
  },
);

export const updateMinimumBalanceThreshold: (
  payload: MinimumBalanceThresholdPayload,
) => ThunkAction<void> = flow(
  key(() => `referral/payment/minimum-balance`),
  fetching(),
)((payload) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.post(`referral/payment/minimum-balance`, payload),
  ).then(
    () => {
      toastApi.show(thresholdUpdateMessage());
      dispatch(getPaymentBalance());
    },
    (error) => {
      dispatch(showApiError(error, error?.response?.message));
    },
  );
});

export const savePaypalCredentials =
  (payload: PaypalCredentials): ThunkAction<mixed> =>
  (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.post(`referral/payment/paypal-creds`, payload),
    ).then(
      () => {},
      (error) => {
        dispatch(showApiError(error, error?.response?.message));
      },
    );
  };
