// @flow strict
import * as React from 'react';

//$FlowFixMe[untyped-import]
import {classify} from 'src/utils/index';
import urlParse from 'url-parse';
import {useEnvironmentContext} from 'src/hooks/useEnvironmentContext';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {InContextAlert} from '@spaced-out/ui-design-system/lib/components/InContextAlert';

import type {ssoLoginProps} from 'src/types/authentication.js';

import css from './sso.css';


const SSOLogin = (props: ssoLoginProps): React.Node => {
  const {ssoAuthUrl, showDualAuthForm = false} = props;
  const {isExtension} = useEnvironmentContext();
  const query = urlParse(window.location, true).query;
  const nextPathname = query.nextPathname || '';
  const nextSearch = query.nextSearch || '';
  const parsedNextSearch = new URLSearchParams(nextSearch);
  const queryString = parsedNextSearch.toString();
  const pathString = JSON.stringify({
    path: `${nextPathname || ''}?${queryString}`,
  });

  return (
    <div className={css.loginWrapper}>
      <div
        className={classify(css.contentWrapper, {
          [css.wideContent]: showDualAuthForm,
        })}
      >
        <a
          href={`${ssoAuthUrl}&state=${pathString}`}
          target="_blank"
          className={css.loginLink}
        >
          <Button
            size={isExtension ? 'small' : 'medium'}
            type={showDualAuthForm ? 'secondary' : 'primary'}
            classNames={{wrapper: css.signInBtn}}
          >
            Login with SSO
          </Button>
        </a>
        {query.oauth_error && (
          <InContextAlert
            semantic="danger"
            classNames={{wrapper: css.ssoError}}
          >
            Your account is not setup for SSO, contact your SSO administrator.
          </InContextAlert>
        )}
      </div>
    </div>
  );
};

export default SSOLogin;
