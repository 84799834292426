// @flow strict
import type {
  WritebackFieldValue,
  FieldWritebackCriteriaType,
  FieldWritebackChangeToOperatorType,
} from 'src/types/field-writeback';
import cloneDeep from 'lodash/cloneDeep';

import {
  SET_FIELD_WRITEBACK,
  UPDATE_FIELD_WRITEBACK,
  RESET_FIELD_WRITEBACK,
  SET_FIELD_WRITEBACK_CHANGE_TYPE,
  SET_FIELD_WRITEBACK_FIELD,
  SET_FIELD_WRITEBACK_VALUE,
  SET_FIELD_WRITEBACK_CHANGE_TO_OPERATOR,
  type FieldWritebackAction,
} from 'src/action-creators/field-writeback';


export type FieldWritebackState = {
  id: ?number,
  criteria_type: FieldWritebackCriteriaType,
  writebackFields: Array<WritebackFieldValue>,
  changeToOperator: FieldWritebackChangeToOperatorType,
  task_group_id: ?number,
};

const initialState = {
  id: null,
  criteria_type: 'field',
  writebackFields: [], //currently only one value can be there
  // but in future we can have multiple values e.g. city and pincode
  // and both are required to be updated
  changeToOperator: 'empty',
  task_group_id: null,
};

export default (
  state: FieldWritebackState = initialState,
  action: FieldWritebackAction,
): FieldWritebackState => {
  switch (action.type) {
    case UPDATE_FIELD_WRITEBACK: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case RESET_FIELD_WRITEBACK: {
      const payload = cloneDeep(initialState);
      return {
        ...state,
        ...payload,
      };
    }

    case SET_FIELD_WRITEBACK_CHANGE_TYPE: {
      const {payload} = action;
      const newState = cloneDeep(initialState);
      newState.criteria_type = payload;
      newState.id = state.id;
      return {
        ...state,
        ...newState,
      };
    }
    case SET_FIELD_WRITEBACK: {
      const {payload} = action;
      return {
        ...state,
        ...payload,
      };
    }
    case SET_FIELD_WRITEBACK_FIELD: {
      const {payload} = action;
      return {
        ...state,
        //this may change in future when we have multiple fields
        writebackFields: [payload],
      };
    }
    case SET_FIELD_WRITEBACK_VALUE: {
      const {id, value} = action.payload;
      return {
        ...state,
        //this may change in future whn we have multiple fields
        writebackFields: state.writebackFields.map((field) => {
          if (field.id === id) {
            return {
              ...field,
              value,
            };
          }
          return field;
        }),
      };
    }
    case SET_FIELD_WRITEBACK_CHANGE_TO_OPERATOR: {
      const {id, value} = action.payload;
      return {
        ...state,
        changeToOperator: value,
        //this may change in future whn we have multiple fields
        writebackFields: state.writebackFields.map((field) => {
          if (field.id === id) {
            return {
              ...field,
              value: [],
            };
          }
          return field;
        }),
      };
    }
  }
  return state;
};
