// @flow strict

import * as React from 'react';
import {useDispatch} from 'react-redux';
// $FlowFixMe[nonstrict-import]
import {getSenderNumberForSMS} from 'src/action-creators/event-creation';

import css from '../test-email-sms-modal.css';
import common from 'common';
import imsCss from './integrated-messaging-sender.css';


export const IntegratedMessagingSender = ({
  workflowId,
  eventId,
  anchorEntityId,
  anchorEntityType,
  contentSubscriptionCategoryId,
  smsFromUser,
}: {
  workflowId: string,
  eventId: string,
  anchorEntityId: string,
  anchorEntityType: string,
  contentSubscriptionCategoryId: number,
  smsFromUser: string,
}): React.Node => {
  const [name, setName] = React.useState('');
  const [number, setNumber] = React.useState('');
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (
      contentSubscriptionCategoryId != null &&
      anchorEntityId &&
      anchorEntityType
    ) {
      (async function () {
        const {sender_name, sender_number} = await dispatch(
          getSenderNumberForSMS({
            workflowId,
            eventId,
            category_id: contentSubscriptionCategoryId,
            anchor_entity_id: anchorEntityId,
            anchor_entity_type: anchorEntityType,
            branding_settings: {
              sms_from_user: smsFromUser ?? '',
            },
          }),
        );

        setName(sender_name);
        setNumber(sender_number);
      })();
    }
  }, [
    anchorEntityId,
    anchorEntityType,
    eventId,
    contentSubscriptionCategoryId,
    workflowId,
    smsFromUser,
  ]);

  return <SimpleIntMessPreview name={name} number={number} />;
};

const SimpleIntMessPreview = ({
  name,
  number,
}: {
  name: ?string,
  number: ?string,
}) => (
  <div className={imsCss.row}>
    <div className={css.label}>
      <span className={css.labelText}>Sender:</span>
    </div>
    <div>
      <span className={common.captionText}>
        {name && number ? `${name} (${number})` : 'No Match'}
      </span>
    </div>
  </div>
);
