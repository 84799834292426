// @noflow

import type {SentEvent} from 'src/api-parsers/events.js';

import Store from './base';


export default class SentEventStore extends Store {
  state: {
    fetching: ?Promise<void>,
    sentEvents: {[key: string]: SentEvent},
  };

  constructor() {
    super('sent-event');

    this.state = {
      fetching: null,
      sentEvents: {},
    };
  }

  startFetching(promise: Promise<void>) {
    this.setState({
      fetching: promise,
    });
  }

  receiveSentEvent(sentEvent: SentEvent) {
    this.updateState({
      fetching: {$set: null},
      sentEvents: {[sentEvent.id]: {$set: sentEvent}},
    });
  }

  byId(id: string): ?SentEvent {
    return this.state.sentEvents[id];
  }
}
