// @flow
import type {State} from 'src/reducers';
/*
import type {
  DynamicLabelExtra,
  DynamicLabels,
} from 'src/action-creators/dynamic-labels';
*/
import type {DynamicLabelExtra, DynamicLabels} from './types';

import type {AtsEntity, EntityType} from 'src/types/ats-entities';

import * as React from 'react';
import {connect} from 'react-redux';
import memoize from 'memoize-one';

import clamp from 'lodash/clamp';
import take from 'lodash/take';
import isEmpty from 'lodash/isEmpty';
import escapeRegExp from 'lodash/escapeRegExp';

import classify from 'src/utils/classify';
import {popModal as removeModal} from 'src/action-creators/modal';
import {dedupBridgedDynamicLabels} from 'src/utils/dynamic-labels';
import {getEntityTypeMappings} from 'src/selectors/ats-entities';

import SearchIcon from 'src/images/preview-icon.svg?noAttrs';
import CloseIcon from 'src/images/close-icon.svg';
import ArrowDownIcon from 'src/images/arrow-down.svg';

import TokenListInput, {Select} from 'src/components/lib/token-list-input';

import VariablePickerBaseList from './variable-picker.jsx';

import css from './variable-picker.css';
import tokenListCss from 'src/components/lib/token-list-input/style.css';


type ReduxProps = {
  entityTypes: Array<AtsEntity>,
  labels?: DynamicLabels,
};

type SelectCallback = (
  value: DynamicLabelExtra,
  event?: SyntheticMouseEvent<> | KeyboardEvent,
) => void;

type ModalProps = ReduxProps & {
  insertResult: SelectCallback,
  baseEntityType: EntityType,
  fullAttributeName: string,
  onClose?: () => mixed,
  removeModal: () => void,
  allowUnsyncedFields?: boolean,
  query?: string,
};

type ModalState = {
  queryTerm: string,
  selectedIndex: number,
  selectedResult: ?DynamicLabelExtra,
  filters: {label: string, value: string}[],
  showUnsyncedFields: boolean,
};

const unsyncedFieldSelectOptions = [
  {value: false, label: 'Synced fields'},
  {value: true, label: 'Unsynced fields'},
];

class VariablePickerModal extends React.Component<ModalProps, ModalState> {
  selectedElt: ?HTMLDivElement;
  searchInput: ?HTMLInputElement;
  listRef: {current: null | HTMLDivElement};

  filterEntityTypes = memoize(
    (labels: DynamicLabels) =>
      new Set<string>(labels.map((label) => label.display_entity_type)),
  );

  state = {
    queryTerm: this.props.query ?? '',
    selectedIndex: -1, // default to nothing having focus
    selectedResult: null,
    filters: [],
    showUnsyncedFields: false,
  };

  componentDidMount() {
    this.listRef = React.createRef();
    this.focus();
  }

  focus() {
    this.searchInput && this.searchInput.focus();
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
    return this.props.removeModal();
  };

  updateInputValue = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const queryTerm = event.currentTarget.value;
    // when updating text input, move focus to top element as you type
    this.setState({queryTerm, selectedIndex: 0});
  };

  handleKeyDown = (evt: KeyboardEvent) => {
    const key = evt.key;

    if (['ArrowUp', 'ArrowLeft'].includes(key)) {
      evt.preventDefault();
      this.moveListFocus('up');
    } else if (['ArrowDown', 'ArrowRight', 'Tab'].includes(key)) {
      evt.preventDefault();
      this.moveListFocus('down');
    } else if (key === 'Enter') {
      evt.preventDefault();
      let {selectedResult} = this.state;
      const {insertResult} = this.props;

      if (!selectedResult) {
        const [filteredLabels, filterOptions] = this.getFilteredLabels();
        selectedResult = filteredLabels[0];
      }

      if (selectedResult != null) {
        insertResult(selectedResult, evt);
        this.handleClose();
      }
    } else if (key === 'Escape') {
      this.handleClose();
    }
  };

  moveListFocus = (direction: 'up' | 'down') => {
    const {listRef} = this;
    const [filteredLabels, filterOptions] = this.getFilteredLabels();
    const delta = direction === 'up' ? -1 : 1;
    const selectedIndex = clamp(
      this.state.selectedIndex + delta,
      0,
      filteredLabels.length - 1,
    );

    const selectedResult = filteredLabels[selectedIndex];
    if (listRef?.current) {
      // $FlowFixMe
      listRef.current.scrollToItem(selectedIndex);
    }
    this.setState({selectedIndex, selectedResult});
  };

  handleSelectIndex = (selectedIndex: number) => {
    this.setState({selectedIndex});
  };

  handleClickResult = (
    result: DynamicLabelExtra,
    evt: SyntheticMouseEvent<HTMLDivElement>,
  ) => {
    this.props.insertResult(result, evt);
    this.handleClose();
  };

  getFilteredLabels = (): [DynamicLabels, FilterOption[][]] => {
    const {queryTerm, filters, showUnsyncedFields} = this.state;
    const {entityTypes, allowUnsyncedFields} = this.props;
    let {labels = []} = this.props;

    labels = Array.from(dedupBridgedDynamicLabels(labels));
    if (allowUnsyncedFields && showUnsyncedFields) {
      labels = labels.filter(({unsynced}) => unsynced);
    } else {
      labels = labels.filter(({unsynced}) => !unsynced);
    }

    const filterOptions = [getFilterOptions(labels, 0)];
    let filteredLabels = labels;
    for (const [index, filter] of filters.entries()) {
      filteredLabels = filteredLabels.filter(
        (label) =>
          label.source !== 'custom' &&
          label.entityRelationshipPath[index + 1] === filter.value,
      );
      filterOptions.push(getFilterOptions(filteredLabels, index + 1));
    }

    const filterStrings = [queryTerm].filter((f) => !isEmpty(f));

    filteredLabels = filterStrings?.length
      ? filteredLabels.filter((label) =>
          filterStrings.reduce((acc, string) => {
            const re = new RegExp(escapeRegExp(string), 'i');
            return acc && label.name.match(re);
          }, true),
        )
      : filteredLabels;

    const sortedLabels = filteredLabels.sort((a, b) => {
      const aType =
        entityTypes.find((type) => type.name === a.display_entity_type)
          ?.display_name || '';
      const bType =
        entityTypes.find((type) => type.name === b.display_entity_type)
          ?.display_name || '';

      const primarySort = aType.localeCompare(bType);

      return primarySort === 0 ? a.name.localeCompare(b.name) : primarySort;
    });
    return [sortedLabels, filterOptions];
  };

  clearFilters = () => {
    this.setState({queryTerm: '', filters: []});
  };

  render() {
    const {
      entityTypes,
      baseEntityType,
      labels = [],
      allowUnsyncedFields,
    } = this.props;
    const {queryTerm, selectedIndex, filters} = this.state;
    const [filteredLabels, filterOptions] = this.getFilteredLabels();

    return (
      <div className={css.root}>
        <header className={css.topContainer}>
          <div
            className={css.search}
            onClick={() => this.searchInput && this.searchInput.focus()}
          >
            <SearchIcon className={css.searchIcon} />
            <input
              className={css.searchInput}
              value={queryTerm}
              onKeyDown={this.handleKeyDown}
              onChange={(event) => this.updateInputValue(event)}
              ref={(input) => (this.searchInput = input)}
              placeholder="Search Variables"
            />
          </div>
          <div className={css.closeContainer}>
            <div className={css.verticalLine} />
            <CloseIcon
              className={css.closeIcon}
              onClick={() => {
                this.handleClose();
              }}
            />
          </div>
        </header>

        <section className={css.filterWrap}>
          {allowUnsyncedFields && (
            <Select
              classNames={{container: css.showUnsycnedFields}}
              options={unsyncedFieldSelectOptions}
              value={unsyncedFieldSelectOptions.find(
                (option) => option.value === this.state.showUnsyncedFields,
              )}
              onChange={(selected) =>
                this.setState({showUnsyncedFields: selected?.value})
              }
            />
          )}
          <div className={css.filters}>
            {filterOptions.map(
              (options, index) =>
                !isEmpty(options) && (
                  <div className={css.filter} key={index}>
                    {index !== 0 && (
                      <span className={css.filterSpacer}>{'→'}</span>
                    )}
                    <TokenListInput
                      containerClassName={css.filterSelect}
                      placeholder={
                        index === 0 ? 'Filter by entity type' : 'Any Type'
                      }
                      options={options || []}
                      values={filters[index] ? [filters[index]] : []}
                      openOnFocus
                      allowArbitraryValues={false}
                      limit={1}
                      focusOnMount={index > 0}
                      onChange={([filter]) => {
                        let filters;
                        if (filter != null) {
                          filters = take(this.state.filters, index + 1);
                          filters[index] = filter;
                        } else {
                          filters = take(this.state.filters, index);
                        }
                        this.setState({filters});
                      }}
                      suffixLabel={
                        <div>
                          <ArrowDownIcon
                            className={classify(
                              tokenListCss.chevron,
                              tokenListCss['down'],
                            )}
                          />
                        </div>
                      }
                    />
                  </div>
                ),
            )}
          </div>
        </section>

        <div className={css.tableListContainer}>
          <div className={css.columnHeaders}>
            <h5 className={css.variableNameHeader}>Variable Name</h5>
            {filterOptions.length > 1 && (
              <div className={css.clearButtonWrap}>
                <button className={css.clearButton} onClick={this.clearFilters}>
                  Clear Filters
                </button>
              </div>
            )}
          </div>
          <VariablePickerBaseList
            baseEntityType={baseEntityType}
            selectedIndex={selectedIndex}
            // $FlowFixMe
            onSelect={this.handleClickResult}
            labels={filteredLabels}
            listRef={this.listRef}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({
  entityTypes: getEntityTypeMappings(state),
});

const ConnectedVariablePickerModal: any = connect(
  (state: State) => ({
    entityTypes: getEntityTypeMappings(state),
  }),
  {removeModal},
)(VariablePickerModal);

export default ConnectedVariablePickerModal;

function getRelationshipPathIndex(label, index) {
  const length = label.entityRelationshipPath.length;
  return length - 1 - (length > 2 ? index + 1 : index);
}

type FilterOption = {
  value: string,
  label: string,
  index: number,
};
function getFilterOptions(labels, index): FilterOption[] {
  // NOTE (kyle): the start of the path is always the attribute name,
  // which we don't need.
  const pathIndex = index + 1;

  const set = labels.reduce((attributeNames, label) => {
    if (label.source !== 'custom') {
      const {entityRelationshipPath} = label;
      const length = entityRelationshipPath.length;

      if (pathIndex < length) {
        const pathComponent = entityRelationshipPath[pathIndex];
        if (pathComponent) {
          attributeNames.add(pathComponent);
        }
      }
    }

    return attributeNames;
  }, new Set());

  return [...set].map((value) => ({
    value,
    label: value,
    index,
  }));
}
