// @flow strict-local

import * as React from 'react';
import {useSelector} from 'react-redux';
import parseISO from 'date-fns/parseISO';

import {useReleaseFlag} from './product-flags';
import type {RerouterLocation} from 'src/rerouter';
import {useLocation} from 'src/rerouter/hooks';
import {selectCurrentAccount} from 'src/selectors/accounts';
import {selectAgencyData} from 'src/selectors/agency';

/* this is a snippet we got from delighted and we didn't write this js,
but we turned it into a hook so that we could
call it safely without modifying the whole site"*/

export function useDelighted(): boolean {
  const [isAvailable, setIsAvailable] = React.useState(false);

  React.useEffect(() => {
    /* eslint-disable */
    !(function (e, t, r, n) {
      if (!e[n]) {
        for (
          var a = (e[n] = []),
            i = [
              'survey',
              'reset',
              'config',
              'init',
              'set',
              'get',
              'event',
              'identify',
              'track',
              'page',
              'screen',
              'group',
              'alias',
            ],
            s = 0;
          s < i.length;
          s++
        ) {
          var c = i[s];
          // $FlowFixMe
          a[c] =
            // $FlowFixMe
            a[c] ||
            (function (e) {
              return function () {
                // $FlowFixMe[method-unbinding]
                var t = Array.prototype.slice.call(arguments);
                a.push([e, t]);
              };
            })(c);
        }
        // $FlowFixMe
        a.SNIPPET_VERSION = '1.0.1';
        var o = t.createElement('script');
        (o.type = 'text/javascript'),
          (o.async = !0),
          (o.src =
            'https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/' +
            r +
            '/' +
            n +
            '.js');
        var p = t.getElementsByTagName('script')[0];
        // $FlowFixMe
        p.parentNode.insertBefore(o, p);

        setIsAvailable(true);
      }
    })(window, document, 'RmMUCP2xTidD1ZY2', 'delighted');
  }, []);

  return isAvailable;
}

export function useDelightedRouteSurvey(routeProjectMapping: {
  [route: string]: string,
}) {
  const isDelightedAvailable = useDelighted();
  const location = useLocation();
  const delightedEnabled = useReleaseFlag('delightedEnabled');

  const currentAccount = useSelector(selectCurrentAccount);
  const agency = useSelector(selectAgencyData);

  const projectForRoute: ?string = ((location: RerouterLocation) => {
    const pathname = location.pathname.replace(/\/[+?]\d+/, '/:id');
    if (pathname in routeProjectMapping) {
      return routeProjectMapping[pathname];
    }
    return null;
  })(location);

  React.useEffect(() => {
    if (currentAccount) {
      const {name, email, timeCreated, salesforceContactId} = currentAccount;
      if (
        isDelightedAvailable &&
        delightedEnabled &&
        timeCreated &&
        salesforceContactId &&
        projectForRoute
      ) {
        window.delighted.survey({
          name,
          email,
          properties: {
            salesforce_contact_id: salesforceContactId,
            projectName: projectForRoute,
            agencyName: agency.name,
          },
          createdAt: parseISO(timeCreated).toISOString(),
        });
      }
    }
  }, [isDelightedAvailable, delightedEnabled, projectForRoute]);
}
