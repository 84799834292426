// @flow

import type {State} from 'src/reducers';
import type {Thread, PopulatedThread} from 'src/types/messages';

import {createSelector} from 'reselect';

import {values} from 'src/utils/object';
import {selectPopulatedThreadsByIds} from 'src/selectors/messages-v2';


export const getThreads = (state: State): {[string]: Thread} =>
  state.messages.threads;

export const selectThread = (state: State, threadId: string): ?Thread =>
  state.messages.threads[threadId];

export const selectThreadsAsArray: (
  state: State,
) => Thread[] = createSelector(getThreads, (threads): Thread[] =>
  values(threads),
);

export const selectOrderedThreads = (state: State): PopulatedThread[] =>
  selectPopulatedThreadsByIds(state, state.messages.threadOrder);

export const selectThreadForContact: (
  state: State,
  contactId: string,
) => Thread = createSelector(
  selectThreadsAsArray,
  (_, contactId: string) => contactId,
  (threads, contactId) =>
    // $FlowFixMe[incompatible-call]
    threads.find((thread) => thread.contactId === contactId),
);
