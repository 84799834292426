// @flow strict

import {
  GET_SUMMARY,
  GET_SUMMARY_ERROR,
  GET_SUMMARY_LOADING,
} from 'src/action-creators/journey-touchpoint';

import type {JourneyTouchpointActions} from 'src/action-creators/journey-touchpoint';
import type {TouchpointSummaryResponseData} from 'src/types/journey-touchpoint';


export type JourneyTouchpointState = {
  summaryData: ?TouchpointSummaryResponseData,
  summaryDataLoading: boolean,
  summaryDataError: mixed,
};

const initialState: JourneyTouchpointState = {
  summaryData: null,
  summaryDataLoading: false,
  summaryDataError: null,
};

export default (
  state: JourneyTouchpointState = initialState,
  action: JourneyTouchpointActions,
): JourneyTouchpointState => {
  switch (action.type) {
    case GET_SUMMARY:
      return {
        ...state,
        summaryData: action.payload,
        summaryDataLoading: false,
        summaryDataError: null,
      };
    case GET_SUMMARY_ERROR:
      return {
        ...state,
        summaryDataError: action.payload,
        summaryDataLoading: false,
      };
    case GET_SUMMARY_LOADING:
      return {
        ...state,
        summaryDataLoading: true,
      };
  }
  return state;
};
