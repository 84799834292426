// @flow

import type {JobVariable, JobVariables} from './types';

import * as React from 'react';

import {entityColorMapping} from 'src/utils/entities';
import classify from 'src/utils/classify';

import TruncatedText from 'src/components/lib/truncated-text';

import common from 'src/styles/common.css';
import css from './variable-picker.css';


export type VariablePickerModalRowProps = {
  data: {
    labels: JobVariables,
    selectedIndex: number,
    onClick: (
      jobDynamicLabel: JobVariable,
      evt: SyntheticMouseEvent<HTMLDivElement>,
    ) => void,
  },
  index: number,
  style: {...},
};

const VariablePickerModalRow = ({
  data: {labels, selectedIndex, onClick},
  index,
  style,
}: VariablePickerModalRowProps): React.Element<'div'> => {
  const dynamicLabel = labels[index];

  const color = common[entityColorMapping['job']?.text] || common.colorGray6;

  return (
    <div
      // even/odd in JS because react-window will
      // make css order unpredictable
      className={classify(
        index % 2 === 0 ? css.resultEven : css.resultOdd,
        selectedIndex === index ? css.resultSelected : '',
      )}
      onClick={(evt) => {
        onClick(dynamicLabel, evt);
      }}
      style={style}
    >
      <div className={css.label}>
        <TruncatedText
          className={css.modalVariableName}
          style={{
            color,
          }}
          text={dynamicLabel.name}
          limit={60}
          component="h3"
        />
        <div className={css.grammarTokens}>
          <p>{dynamicLabel.description}</p>
        </div>
      </div>
    </div>
  );
};

export default VariablePickerModalRow;
