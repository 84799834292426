// @flow strict

export const PRE_SCREENING_TRACKING_EVENTS = {
  PRE_SCREENING_VISITED: 'Pre-screening Generator Visited',
  PRE_SCREENING_CREATE_NEW_VISITED: 'Pre-screening Create New Clicked',
  PRE_SCREENING_QUESTIONS_VIEWED: 'Pre-screening Generator Viewed',
  PRE_SCREENING_DRAFTS_SEARCHED: 'Prescreening Draft Searched',
  PRE_SCREENING_DRAFTS_GENERATED: 'Prescreening Draft Generated',
  PRE_SCREENING_DRAFTS_ENHANCED: 'Prescreening Draft Enhanced',
  PRE_SCREENING_DRAFTS_SAVED: 'Pre-screening Draft Saved',
  PRE_SCREENING_USER_FEEDBACK: 'User Feedback',
  CHATBOT_CREATED: 'Pre-screening Draft Saved',
  MANUALLY_ADD_QUESTION: 'Manually Add Question',
  MANUALLY_EDIT_QUESTION: 'Manually Edit Question',
  REMOVE_QUESTION: 'Remove Question',
};

export const RESUME_GENERATOR_TRACKING_EVENTS = {
  RESUME_GENERATOR_VISITED: 'Resume Generator Visited',
  RESUME_GENERATOR_CREATE_NEW_CLICKED: 'Resume Create New Clicked',
  RESUME_VIEWED: 'Resume Viewed',
  RESUME_DRAFTS_SEARCHED: 'Resume Draft Searched',
  RESUME_DRAFTS_GENERATED: 'Resume Draft Generated',
  RESUME_DOWNLOADED: 'Resume Draft Generated',
};

export const JD_GENERATOR = {
  JD_ASSIST_VISITED: 'JD Assist Visited',
  GENERATE_JD: 'Generate JD',
  ENHANCE_JD: 'Enhance JD',
  JD_MANUALLY_EDITED: 'JD Manually Editted',
  COPY_JD: 'Copy JD',
};

export const SURVEY_SUMMARY = {
  SURVEY_SUMMARY_VISITED: 'Survey Summary Visited',
  SUMMARY_GENERATED: 'Summary Generated',
  REWRITE_SUMMARY_CLICKED: 'Rewrite Summary Clicked',
  QUICK_FOLLOW_UP_CLICKED: 'Quick Follow Up Clicked',
  ASK_BUTTON_CLICKED: 'Ask Button Clicked',
  SUMMARY_SAVED: 'Summary Saved',
  SAVED_SUMMARY_REVIEWED: 'Saved Summary Reviewed',
};
