// @flow strict
import type {
  RecentQuestions,
  QuestionsMultipleVersion,
  CandidateDetails,
} from 'src/types/sense-ai-panel';

//$FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';
import {createSelector} from 'reselect';


export const selectShowMenu = (state: State): boolean =>
  state.senseAIPanel?.showMenu;

export const selectJdList = (state: State): boolean =>
  state.senseAIPanel?.jdList;

export const selectPrescreeningList = (state: State): boolean =>
  state.senseAIPanel?.prescreeningList;

export const selectCompanyList = (state: State): boolean =>
  state.senseAIPanel?.companies;

export const selectQuestions = (state: State): string[] =>
  state.senseAIPanel?.questions;

export const selectRecentQuestions = (state: State): ?RecentQuestions =>
  state.senseAIPanel?.recentQuestions;

export const selectQuestionsFromRecent: (State, id: string) => string[] =
  createSelector(
    [
      (state: State) => state.senseAIPanel.recentQuestions,
      (state: State, id: string) => id,
    ],
    (recentQuestions, id): string[] => recentQuestions[id]?.questions,
  );

export const selectTitleFromRecent: (State, id: string) => string =
  createSelector(
    [
      (state: State) => state.senseAIPanel.recentQuestions,
      (state: State, id) => id,
    ],
    (recentQuestions, id): string => recentQuestions[id]?.title,
  );

export const selectTitle = (state: State): string => state.senseAIPanel?.title;

export const selectMultipleVersionQuestions = (
  state: State,
): QuestionsMultipleVersion => state.senseAIPanel?.multipleVersionQuestions;

export const selectJobTitle = (state: State): string =>
  state.senseAIPanel?.jobTitle;

export const selectResumeQuestions = (state: State): string[] =>
  state.senseAIPanel?.resumeQuestions;

export const selectCandidateDetails = (state: State): CandidateDetails =>
  state.senseAIPanel?.candidateDetails;

export const selectUrlSuffix = (state: State): string =>
  state.senseAIPanel?.urlSuffix;
