// @flow

import type {FilterProps} from './index-new.jsx';

import * as React from 'react';
import moment from 'moment';

import {formatPlural} from 'src/utils/intl';

import {FilterDropdown} from './common.jsx';
import DateRange from 'src/components/analytics/date-range.jsx';
import {AnalyticsService} from 'src/analytics';

import css from './index.css';


export default function TimeFrameFilter({
  query,
  minDate,
  defaultTimeAgo,
  direction,
}: FilterProps): React.Node {
  return (
    <FilterDropdown
      text={humanTimeframeQuery(query, defaultTimeAgo)}
      header="timeframe"
      solid
      direction={direction}
    >
      <DateRange
        defaultTimeAgo={defaultTimeAgo}
        className={css.dateRange}
        minDate={minDate}
      />
    </FilterDropdown>
  );
}

const humanTimeframeQuery = (query, defaultTimeAgo) => {
  if (
    !query.start_date &&
    !query.end_date &&
    query.time_ago === undefined &&
    defaultTimeAgo
  ) {
    query.time_ago = defaultTimeAgo;
  }

  if (query.time_ago !== undefined) {
    const timeAgo = JSON.parse(query.time_ago).days;
    if (timeAgo !== undefined) {
      if (timeAgo === 1) {
        return `Current Day`;
      }
      return `Last ${timeAgo} ${formatPlural(timeAgo, 'day', 'days')}`;
    }
  }
  if (query.start_date && query.end_date) {
    try {
      AnalyticsService.track('time_frame', {
        day: getTwoDateDiff(query.start_date, query.end_date),
      });
    } catch (error) {}
    return 'Custom Date Range';
  }
  if (!query.start_date && !query.end_date && !query.time_ago) {
    return 'Last 90 days';
  }
  return 'Timeframe';
};

const getTwoDateDiff = (startDate: string, endDate: string) => {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, 'days');
};
