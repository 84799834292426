// @flow strict-local

/*
 * Note: Don't use this file to add new flags,
 * instead use file: src/hooks/product-flags.js
 */

import {isInternalUser} from 'src/utils/check-internal-user';


export const ANALYTICS_API_V3 = '/api/v3/analytics';
export const ANALYTICS_API_V4 = '/api/v4/analytics';

export function checkExternalLinkLatestApiEnabled(): boolean {
  return checkForReleaseFlag(
    'externalLinkClicksV3ViewEnabled',
    'externalLinkClicksV3ViewEnabledInternal',
  );
}

export function checkResponseLatestApiEnabled(): boolean {
  return checkForReleaseFlag(
    'analyticsResponseFlaggedResponseV3ViewEnabled',
    'analyticsResponseFlaggedResponseV3ViewEnabledInternal',
  );
}

export function checkAnalyticsChatbotV3Enabled(): boolean {
  return checkForReleaseFlag(
    'analyticsChatbotV3Enabled',
    'analyticsChatbotV3EnabledInternal',
  );
}

export function checkAnalyticsTimelineV3Enabled(): boolean {
  return checkForReleaseFlag(
    'analyticsTimelineV3ViewEnabled',
    'analyticsTimelineV3ViewEnabledInternal',
  );
}

export function checkAnalyticsNpsV3Enabled(): boolean {
  return checkForReleaseFlag(
    'analyticsNpsV3ViewEnabled',
    'analyticsNpsV3ViewEnabledInternal',
  );
}

export function checkAnalyticsAuditV3Enabled(): boolean {
  return checkForReleaseFlag(
    'analyticsAuditV3ViewEnabled',
    'analyticsAuditV3ViewEnabledInternal',
  );
}

export function checkAnalyticsConversationsV3Enabled(): boolean {
  return checkForReleaseFlag(
    'analyticsTranscriptV3ViewEnabled',
    'analyticsTranscriptV3ViewEnabledInternal',
  );
}

export function checkAnalyticsConversationsExportV3Enabled(): boolean {
  return checkForReleaseFlag(
    'analyticsTranscriptExportEnabled',
    'analyticsTranscriptExportEnabledInternal',
  );
}

export function checkAnalyticsLandingPageV3Enabled(): boolean {
  return checkForReleaseFlag(
    'landingPageV3ViewEnabled',
    'landingPageV3ViewEnabledInternal',
  );
}

export function checkAnalyticsEngagementV3Enabled(): boolean {
  return checkForReleaseFlag(
    'analyticsEngagementV3ViewEnabled',
    'analyticsEngagementV3ViewEnabledInternal',
  );
}

export function checkEmbeddedAnalyticsEnabled(): boolean {
  return checkForReleaseFlag(
    'enableEmbeddedAnalytics',
    'enableEmbeddedAnalyticsInternal',
  );
}

export function checkanalyticsTranscriptExportEnabled(): boolean {
  return checkForReleaseFlag(
    'analyticsTranscriptExportIncludeSourcing',
    'analyticsTranscriptExportIncludeSourcingInternal',
  );
}

export function checkEventResponseSummaryEnabled(): boolean {
  return checkForReleaseFlag(
    'eventResponseSummaryEnabled',
    'eventResponseSummaryEnabledInternal',
  );
}

export function checkJdGeneratorEnabled(): boolean {
  return checkForReleaseFlag(
    'jdGeneratorEnabled',
    'jdGeneratorEnabledInternal',
  );
}

export function checkRoiV3ApiEnabled(): boolean {
  return checkForReleaseFlag('roiV3ApiEnabled', 'roiV3ApiInternalEnabled');
}

export function checkPplEngEmbeddedEnabled(): boolean {
  return checkForReleaseFlag(
    'enablePplEngEmbedded',
    'enablePplEngEmbeddedInternal',
  );
}

export function checkTouchpointsEmbeddedEnabled(): boolean {
  return checkForReleaseFlag(
    'enableTpDeliverabilityEmbedded',
    'enableTpDeliverabilityEmbeddedInternal',
  );
}

export function checkClickEmbeddedEnabled(): boolean {
  return checkForReleaseFlag(
    'enableClickEmbedded',
    'enableClickEmbeddedInternal',
  );
}

export function checkAnalyticsEmbeddedEnabled(): boolean {
  return checkForReleaseFlag(
    'analyticsHomePageViewEnabled',
    'analyticsHomePageViewEnabledInternal',
  );
}

export function checkAnalyticsNewLandingPageEnabled(): boolean {
  return checkForReleaseFlag(
    'analyticsNewLandingPageViewEnabled',
    'analyticsNewLandingPageViewEnabledInternal',
  );
}

export function checkAnalyticsMessagingExportEnabled(): boolean {
  const isSgAnalyticsExportEnabled = checkForReleaseFlag(
    'enableMessagingSgAnalyticsExport',
    'enableMessagingSgAnalyticsExportInternal',
  );

  const isPhoneAnalyticsExportEnabled = checkForReleaseFlag(
    'enableMessagingPhoneAnalyticsExport',
    '',
  );

  return isSgAnalyticsExportEnabled && isPhoneAnalyticsExportEnabled;
}

export function checkAnalyticsCustomEmbeddedEnabled(): boolean {
  return checkForReleaseFlag(
    'enableCustomEmbeddedAnalytics',
    'enableCustomEmbeddedAnalyticsInternal',
  );
}

export function isCandidateMatchingEnabled(): boolean {
  return checkForReleaseFlag(
    'discoverCandidateMatchingViewEnabled',
    'discoverCandidateMatchingViewEnabledInternal',
  );
}

function checkForReleaseFlag(
  releaseFlag: string,
  internalReleaseFlag: string,
): boolean {
  const state = window.senseStore.reduxStore.getState();
  const {productFlags} = state;
  const {releaseFlags} = productFlags;

  if (
    releaseFlags[releaseFlag] ||
    (isInternalUser() && releaseFlags[internalReleaseFlag])
  ) {
    return true;
  }

  return false;
}

export function getDefaultRange(): number {
  const state = window.senseStore.reduxStore.getState();
  const {productFlags, agency} = state;
  const {releaseFlags} = productFlags;
  const {config} = agency;

  if (releaseFlags['analyticsDefaultTimeRangeEnabled']) {
    return config['analyticsDefaultTimeRange'];
  }

  return 90;
}
