// @noflow

import type {Question, QuestionType, Branch} from 'src/types/survey';
import type {Event} from 'src/api-parsers/events';
import type {Workflow} from 'src/api-parsers/index';
import typeof IndexStore from 'src/stores/index';
import type {BranchInfo} from 'src/stores/event-creation';
import type {DynamicLabels} from 'src/types/dynamic-labels';

import * as React from 'react';
import partial from 'lodash/partial';

import {classify} from 'src/utils';
import {
  openBranch,
  closeBranch,
  addBranchModule,
  duplicateBranchModule,
} from 'src/actions/branch';

import FluxComponent from 'src/flux/component.jsx';
import BranchSettings from 'src/components/workflow/event/content/branch/settings.jsx';
import BranchModules from 'src/components/workflow/event/content/branch/modules.jsx';
import Connector from 'src/components/workflow/event/content/connector.jsx';
import BranchEvent from 'src/components/workflow/event/content/branch/event.jsx';
import AddModule from 'src/components/workflow/event/content/add-module.jsx';
import {Expanders} from 'src/components/lib/expander/expander2.jsx';

// TODO (kyle): not sure this is needed
//import './container.global.css';
import css from './container.css';


export const modulesThatBranch = [
  'multiple_choice_survey_question',
  'rating_scale_survey_question',
  'nps_survey_question',
];

const mapper = ({store, moduleData, workflow}) => {
  const branches = (moduleData.branchIds || []).map(({id}) =>
    store.branches.getBranch(id),
  );
  const {
    eventCreation: {
      state: {branchInfo, question, questionError},
    },
  } = store;

  return {
    branches,
    branchInfo,
    editingQuestion: question,
    event: store.eventCreation.state.event,
    questionError,
    locked: question || (branchInfo && branchInfo.editorBranch),
    eventsInCurrentWorkflow: workflow.branchedEventIds.map((id) =>
      store.events.getEvent(id),
    ),
  };
};

type BranchRowProps = {
  store: IndexStore,
  moduleData: Question,
  dynamicLabels: string[],
  eventsInCurrentWorkflow: Event[],
  workflow: Workflow,
  editingQuestion?: Question,
  questionError?: Object,
  locked: boolean,
  branches: Branch[],
  branchInfo?: BranchInfo,
  canEdit: boolean,
  conditionalAlerts: boolean,
  jobDynamicLabels: DynamicLabels,
  parentEvent?: Event,
};

class BranchRow extends React.Component<BranchRowProps> {
  constructor(props) {
    super(props);

    (this: any).handleEditBranchWorkflow =
      this.handleEditBranchWorkflow.bind(this);
    (this: any).handleAddModule = this.handleAddModule.bind(this);
  }

  render() {
    const {
      moduleData,
      dynamicLabels,
      store,
      eventsInCurrentWorkflow,
      workflow,
      branches,
      event,
      // kyle stuff
      branchInfo,
      editingQuestion,
      questionError,
      locked,
      canEdit,
      conditionalAlerts,
      parentEvent,
      jobDynamicLabels,
    } = this.props;

    //Only show branching row for specific modules.
    if (!modulesThatBranch.includes(moduleData.type)) {
      return null;
    }

    return (
      <div className={locked ? css.branchContainerLocked : css.branchContainer}>
        <div className={css.branchRow}>
          {branches.map((branch) => {
            if (branch.id) {
              return (
                <TabExisting
                  key={branch.id}
                  branch={branch}
                  editing={branchInfo && branchInfo.branch === branch}
                  disabled={locked}
                  onEditBranchWorkflow={this.handleEditBranchWorkflow}
                />
              );
            }
          })}
        </div>

        <Expanders duration={200} component="div" className={css.column}>
          {branchInfo && branchInfo.questionId === moduleData.id && (
            <div className={css.branchBoxes}>
              {!branches.includes(branchInfo.branch) && (
                <Connector invisible={true} />
              )}
              <BranchSettings
                store={store}
                disabled={locked}
                moduleData={moduleData}
                branchInfo={branchInfo}
                branches={branches}
                canEdit={canEdit}
              />
              {branchInfo.branch.branchType === 'event_branch' && (
                <div className={css.column}>
                  <Connector />
                  <BranchEvent
                    store={store}
                    workflowEvents={eventsInCurrentWorkflow}
                    eventId={branchInfo.branch.event.id}
                    workflowId={workflow.id}
                  />
                </div>
              )}
              {branchInfo.branch.branchType === 'module_branch' && (
                <div className={css.column}>
                  <Connector />
                  <BranchModules
                    className={css.column}
                    workflow={workflow}
                    branchInfo={branchInfo}
                    editingQuestion={editingQuestion}
                    questionError={questionError}
                    dynamicLabels={dynamicLabels}
                    conditionalAlerts={conditionalAlerts}
                    jobDynamicLabels={jobDynamicLabels}
                    parentEvent={parentEvent}
                  />
                  <AddModule
                    disabled={locked}
                    onTypeSelect={this.handleAddModule}
                    inBranch={true}
                    onDuplicateModule={this.handleDuplicateModule}
                    event={event}
                    branchInfo={branchInfo}
                  />
                </div>
              )}
            </div>
          )}
        </Expanders>
      </div>
    );
  }

  handleEditBranchWorkflow(branch: Branch) {
    const {store, moduleData, branchInfo} = this.props;

    if (branchInfo && branchInfo.branch === branch) {
      closeBranch(store);
    } else {
      openBranch(store, moduleData.id, branch);
    }
  }

  handleDuplicateModule = () => {
    if (
      !this.props.editingQuestion ||
      // $FlowFixMe[prop-missing] types/surveys
      !this.props.editingQuestion.hasValidationError
    ) {
      duplicateBranchModule(this.props.store);
    }
  };

  handleAddModule(type: QuestionType) {
    addBranchModule(this.props.store, type);
  }
}

export default FluxComponent(BranchRow, mapper);

type TabExistingProps = {
  branch: Branch,
  onEditBranchWorkflow: (branch: Branch) => void,
  editing: any,
  disabled: any,
};

export const TabExisting = (props: TabExistingProps) => (
  <div className={css.branchTabContainer}>
    <div
      className={classify(css.branchTab, {
        [css.disabled]: props.disabled,
        [css.editing]: props.editing,
      })}
      onClick={
        !props.disabled
          ? partial(props.onEditBranchWorkflow, props.branch)
          : null
      }
    >
      <div
        className={
          props.branch.branchType === 'module_branch'
            ? css.branchTabTextExisting
            : css.branchTabTextExistingEvent
        }
      >
        {props.branch.name}
      </div>
    </div>
    {props.editing ? <Connector /> : ''}
  </div>
);
