// @flow strict

import type {
  Answer,
  Graph,
  EditableGraph,

  // Node Types
  ConditionNode,
  ConversationNode,
  ConversationNodeType,
  DispositionNode,
  DynamicQuestionNode,
  EditableNode,
  EditableNodeType,
  EndConversationNode,
  FileUploadNode,
  JobMatchNode,
  LATNode,
  LinkToFlowNode,
  TextToApplyNode,
  MessageNode,
  NotificationNode,
  QuestionNode,
  SchedulerNode,
  SummaryNode,
  Edge,
  Id,
  ConditionOperator,
  ChatbotMedium,
  UseCase,
  ConditionValue,
  SummaryItem,
  NodeVariable,
  NodeVariables,
  ResponseType,
  DerivedField,
  DerivedFieldMap,
  Criteria,
} from 'src/types/chatbot';

import type {
  //$FlowFixMe[nonstrict-import]
  WritebackAllowedAttributes,
  //$FlowFixMe[nonstrict-import]
  AttributeMetaData,
} from 'src/types/writeback';
import type {
  Ruleset,
  EditableRuleset,
  EditableExpression,
  Operator,
} from 'src/types/ruleset';
//$FlowFixMe[nonstrict-import]
import type {VariablePickerDynamicLabels} from 'src/types/dynamic-labels';

import keyBy from 'lodash/keyBy';
import xor from 'lodash/xor';

// $FlowFixMe[nonstrict-import]
import type {RawDraftContentState} from 'draft-js/lib/RawDraftContentState';
// $FlowFixMe[nonstrict-import]
import type {ContentState} from 'draft-js';

import {fromArray} from 'src/utils/map';
import generateId from 'src/utils/id';
import {values} from 'src/utils/object';
import {setError, type Validation} from 'src/utils/validation';

import {
  setError as setError2,
  type Validation as Validation2,
} from 'src/utils/validation-2';
import {
  createRawDraftContentState,
  includesString,
  updateEntitiesInContentState,
  textDraftToString,
} from 'src/utils/draft';


export const editableNodeTypes: Array<EditableNodeType> = [
  'question',
  'message',
  'notification',
  'condition',
  'disposition',
  'summary',
  'scheduler',
  'job_match',
  'file_upload',
  'live_agent_transfer',
  'link_to_flow',
  'dynamic_question',
  'text_to_apply',
];

// Address Validated nodes generate the following derived field names/labels.
export const derivedAddressFields = [
  ['address_l1', 'Address Line 1'],
  ['address_l2', 'Address Line 2'],
  ['city', 'City'],
  ['state', 'State'],
  ['zip', 'Zip Code'],
];

export function createNode({
  defaultNode,
  type,
}: {
  defaultNode: ?ConversationNode,
  type: ConversationNodeType,
}): ConversationNode {
  if (defaultNode) {
    return defaultNode;
  }

  switch (type) {
    case 'message':
      return createMessageNode();
    case 'file_upload':
      return createFileNode();
    case 'question':
      return createQuestionNode();
    case 'summary':
      return createSummaryNode();
    case 'notification':
      return createNotificationNode();
    case 'condition':
      return createConditionNode();
    case 'disposition':
      return createDispositionNode();
    case 'scheduler':
      return createSchedulerNode();
    case 'live_agent_transfer':
      return createLATNode();
    case 'dynamic_question':
      return createDynamicQuestionNode();
  }

  throw new Error('Invalid node type');
}

export function createEditableNode(
  type: EditableNodeType,
  editableGraph?: EditableGraph,
  edge?: Edge,
): EditableNode {
  switch (type) {
    case 'message':
      return createMessageNode();
    case 'file_upload':
      return createFileNode();
    case 'question':
      return createQuestionNode();
    case 'summary':
      return createSummaryNode(editableGraph, edge);
    case 'notification':
      return createNotificationNode();
    case 'condition':
      return createConditionNode();
    case 'disposition':
      return createDispositionNode();
    case 'scheduler':
      return createSchedulerNode();
    case 'job_match':
      return createJobMatchNode();
    case 'live_agent_transfer':
      return createLATNode();
    case 'dynamic_question':
      return createDynamicQuestionNode();
    case 'text_to_apply':
      return createTextToApplyNode();
    default:
      throw new Error('Invalid type');
  }
}

export function createQuestionNode(
  attrs: ?{
    name: string,
    text_draft_json: RawDraftContentState,
    preset_question_id: string,
    question_type: string,
    response_type: ResponseType,
    answers: ?(Answer[]),
    question_is_multiselect: boolean,
    derived_field_map: ?DerivedFieldMap,
  },
): QuestionNode {
  return {
    id: generateId(),
    type: 'question',
    name: '',
    editable: true,
    text_draft_json: createRawDraftContentState(),
    writeback_field_id: null,
    derived_field_map: null,
    writeback_type: null,
    response_type: 'string',
    question_error_message: null,
    question_is_multiselect: false,
    is_root: false,
    ...attrs,
  };
}

export function createMessageNode(): MessageNode {
  return {
    id: generateId(),
    type: 'message',
    name: '',
    editable: true,
    text_draft_json: createRawDraftContentState(),
    is_root: false,
  };
}

export function createFileNode(): FileUploadNode {
  return {
    id: generateId(),
    type: 'file_upload',
    name: '',
    editable: true,
    text_draft_json: createRawDraftContentState(),
    is_root: false,
    writeback_field_id: null,
  };
}

export const nodeToSummaryItem = (node: ConversationNode): SummaryItem => ({
  node_id: node.id,
  enabled: false,
  short_name: node.name,
});

// This function affects how node variables are rendered in both
// the variable picker dropdown and the actual draft content
//
// for now, node variables that represent a single item in a derived field
// map just show up as the node's name
// node variables that represent fields or slots in a single node are shown
// as "node name // nice field name label"
//
export const nodeVariableDisplayName = (
  node: ConversationNode,
  field_name?: string,
): string => {
  if (node.derived_field_map != null) {
    if (
      Object.keys(node.derived_field_map ?? {}).length > 1 &&
      field_name != null
    ) {
      return `${node.name} // ${node.derived_field_map[field_name].field_label}`;
    }
  }
  return node.name;
};

export const nodeToNodeVariable = (
  node: ConversationNode,
): NodeVariable | NodeVariables => {
  if (node.type === 'question' && node.derived_field_map != null) {
    const fieldVariables = [];
    for (const field_name of Object.keys(node.derived_field_map)) {
      if (node.derived_field_map[field_name] == null) {
        throw new Error(`${field_name} missing in derived field map`);
      }
      if (node.derived_field_map[field_name].exported === true) {
        fieldVariables.push({
          node_id: node.id,
          derived_field_name: field_name,
          node_name: node.name,
          name: nodeVariableDisplayName(node, field_name),
          template: `“$”`,
        });
      }
    }
    return fieldVariables;
  }
  return {
    node_id: node.id,
    node_name: node.name,
    name: node.name,
    template: `“$”`,
  };
};

export function createSummaryNode(
  graph?: EditableGraph,
  edge?: Edge,
  edgeId?: number,
): SummaryNode {
  const parentNodeId = edge?.to_node_id ?? edge?.from_node_id ?? edgeId;
  const parentNode =
    graph && parentNodeId ? graph.nodes.get(parentNodeId) : undefined;
  const summary_items = findAncestorSummaryItems(graph, parentNode);

  return {
    id: generateId(),
    type: 'summary',
    name: 'Summary',
    editable: true,
    is_root: false,
    summary_items,
  };
}

export function createNotificationNode(): NotificationNode {
  return {
    id: generateId(),
    type: 'notification',
    name: '',
    editable: true,
    is_root: false,
    notification_recipients: [],
    notification_cc_recipients: [],
    notification_to_recipients: [],
    notification_email_subject: createRawDraftContentState(),
    notification_text_content: createRawDraftContentState(),
  };
}

export function createConditionNode(): ConditionNode {
  return {
    id: generateId(),
    type: 'condition',
    name: '',
    editable: true,
    is_root: false,
    condition_ruleset: {
      id: generateId(),
      expressions: [],
    },
  };
}

export function createDispositionNode(): DispositionNode {
  return {
    id: generateId(),
    type: 'disposition',
    name: '',
    editable: true,
    writeback_field_id: null,
    writeback_type: null,
    disposition_writeback_value: null,
    is_root: false,
    text_draft_json: null,
  };
}

export function createLATNode(): LATNode {
  return {
    id: generateId(),
    type: 'live_agent_transfer',
    name: 'Live Chat',
    editable: true,
    is_root: false,
    additional_configs_json: {
      response_templates: {
        after_hours: createRawDraftContentState(
          'Sorry, this request is outside business hours. Try again later.',
        ),
        agent_joined: createRawDraftContentState(
          'Please hold on, we are connecting you now.',
        ),
      },
      queue_routing_rules: {
        default_queue_id: null,
        rules: [],
      },
    },
    answers: [
      {id: 1, label: 'Success', value: 'success'},
      {id: 2, label: 'Failure', value: 'failure'},
    ],
  };
}

export function createEndConversationNode(
  behavior: 'end_conversation' | 'return_to_caller' = 'return_to_caller',
): EndConversationNode {
  return {
    id: generateId(),
    type: 'end_conversation',
    name: 'conversation end',
    editable: true,
    is_root: false,
    additional_configs_json: {
      behavior,
    },
  };
}

export function createLinkToFlowNode(): LinkToFlowNode {
  return {
    id: generateId(),
    name: 'link to flow',
    type: 'link_to_flow',
    editable: true,
    is_root: false,
    additional_configs_json: {
      target_flow_id: null,
    },
  };
}

export function createTextToApplyNode(): TextToApplyNode {
  return {
    id: generateId(),
    name: 'text to apply',
    type: 'text_to_apply',
    editable: true,
    is_root: false,
    text_draft_json: createRawDraftContentState(),
  };
}

export function createEdge(
  parentNodeId: ?Id = null,
  childNodeId: ?Id = null,
  conditions: Array<ConditionValue> | null = null,
  operator: ConditionOperator | null = null,
): Edge {
  return {
    id: generateId(),
    from_node_id: parentNodeId,
    to_node_id: childNodeId,
    conditions,
    operator,
    editable: true,
    is_jump: false,
  };
}

export function createSchedulerNode(
  allowMeetingBranching: ?boolean,
): SchedulerNode {
  return {
    id: generateId(),
    type: 'scheduler',
    name: '',
    editable: true,
    is_root: false,
    text_draft_json: createRawDraftContentState(),
    scheduler_template_id: null,
    answers: allowMeetingBranching
      ? [
          {id: 1, value: 'success', label: 'Meeting Scheduled'},
          {
            id: 2,
            value: 'calendar_slot_not_available',
            label: 'No Available Slots',
          },
          {id: 3, value: 'failure', label: 'Booking Error'},
        ]
      : undefined,
    scheduler_data: {
      questions: [
        {name: 'email', node_id: null, dynamic_field: null},
        {name: 'name', node_id: null, dynamic_field: null},
        {name: 'phone', node_id: null, dynamic_field: null},
      ],
      use_template_hosts: true,
      additional_questions: [],
      contacts: [],
      type: 'static_template',
      global_variable: null,
      event_info: {
        event_title: createRawDraftContentState(),
        event_description: createRawDraftContentState(),
      },
    },
  };
}

export function createJobMatchNode(
  worksiteOptionsEnabled: boolean = false,
  searchExpansionEnabled: boolean = false,
  v2: boolean = false, // make backwards compatible with chatbot 1.0
): JobMatchNode {
  const node = {
    id: generateId(),
    type: 'job_match',
    name: '',
    editable: true,
    is_root: false,
    text_draft_json: createRawDraftContentState(),
    job_search_criteria_data: {
      criteria: searchExpansionEnabled
        ? {}
        : {
            location: {
              reference_node_ids: [],
              ats_variables: [],
            },
            state: {
              reference_node_ids: [],
              ats_variables: [],
            },
            job_title: {
              reference_node_ids: [],
              ats_variables: [],
            },
            keywords: {
              reference_node_ids: [],
              ats_variables: [],
            },
            radius_in_miles: {
              reference_node_ids: [],
              ats_variables: [],
            },
            skills: {
              reference_node_ids: [],
              ats_variables: [],
            },
          },
      matching_messages_rte: v2 ? {} : createRawDraftContentState(),
      max_matches: 3,
      no_matches_handling: 'show_recent',
      no_matches_message_rte: createRawDraftContentState(),
      sort_param: searchExpansionEnabled ? 'relevance desc' : undefined,
      job_result_rte: {},
      show_all_jobs_button: true,
      result_display_style: 'No Styling',
      link_to_job: true,
    },
  };
  if (worksiteOptionsEnabled && !searchExpansionEnabled) {
    // $FlowFixMe[prop-missing] - easier to do this than unseal criteria
    node.job_search_criteria_data.criteria.worksite_options = {
      reference_node_ids: [],
      ats_variables: [],
      default_values: [],
    };
  }
  // $FlowFixMe
  return node;
}

export function createDynamicQuestionNode(): DynamicQuestionNode {
  const node = {
    id: generateId(),
    type: 'dynamic_question',
    name: '',
    writeback_field_id: null,
    editable: true,
    is_root: false,
  };
  return node;
}

export function createEditableGraph(graph: Graph): EditableGraph {
  return {
    ...graph,
    nodes: fromArray(graph.nodes),
    edges: fromArray(graph.edges),
    root: -1,
  };
}

export function createImmutableGraph(graph: EditableGraph): Graph {
  const {root: _, nodes, edges, ...apiGraph} = graph;
  return {
    ...apiGraph,
    nodes: [...nodes.values()],
    // TODO (kyle): we need to resolve the issue of dead-end edges.
    // they are very useful for editing, but are not supposed to persist
    // to the db.
    edges: [...edges.values()].filter((edge) => {
      // we need to prune all orphan edges when updating built graphs because
      // otherwise the put call will fail.
      if (graph.status === 'success') {
        return edge.from_node_id != null && edge.to_node_id != null;
      } else {
        return edge.from_node_id;
      }
    }),
  };
}

export function initEditableGraph(graph: Graph): EditableGraph {
  const editableGraph = createEditableGraph(graph);

  // NOTE (kyle): it is useful for rendering to have dead-end edges
  // at the top and bottom of the tree, but the server does not
  // persist them.
  if (editableGraph.nodes.size === 0 && editableGraph.edges.size === 0) {
    const rootEdge = createEdge();
    editableGraph.edges = fromArray([rootEdge]);
    editableGraph.root = rootEdge.id;
  } else {
    const {edges} = editableGraph;

    // TODO (kyle): this is extremely enifficient, but we do it once per
    // page load?
    for (const node of graph.nodes.values()) {
      if (node.is_root) {
        const rootEdge = createEdge(null, node.id);
        edges.set(rootEdge.id, rootEdge);

        // We always need a reference to the root Edge, but the API
        // does not provide one.
        editableGraph.root = rootEdge.id;
      }

      const childEdges = getChildEdges(editableGraph, node.id);
      if (childEdges.length === 0) {
        const newEdge = createEdge(node.id);
        edges.set(newEdge.id, newEdge);
      }
    }
  }

  return editableGraph;
}

export function validateEditableGraph(
  graph: EditableGraph,
  opts?: ValidationOpts,
): ?Validation {
  let error = null;

  // NOTE (kyle): check edges
  const edgeMap = new Map();
  for (const [id, edge] of graph.edges) {
    if (edge.conditions) {
      if (edge.conditions.length === 0) {
        // for empty condition sets
        error = setError(
          error,
          false,
          `edges.${id}.conditions`,
          'EMPTY_CONDITION_SET',
        );
      } else if (edge.from_node_id) {
        // for redundant condition sets
        const key = `${edge.from_node_id}:${
          edge.operator || 'every'
        }:${edge.conditions.slice().sort().join(',')}`;
        const otherEdgeId = edgeMap.get(key);
        if (otherEdgeId) {
          for (const edgeId of [id, otherEdgeId]) {
            error = setError(
              error,
              false,
              `edges.${edgeId}.conditions`,
              'IDENTICAL_CONDITION_SET',
            );
          }
        } else {
          edgeMap.set(key, id);
        }
      }
    }
  }

  // NOTE (kyle): check for redundant edges. we must use a depth-first search
  // because "jump" edges are always the secondary depth-first paths.
  const edgesSet: Set<string> = new Set();
  const visitedEdges: Set<Edge> = new Set();

  const rootNode: ?ConversationNode = graph.nodes
    .values()
    // $FlowFixMe iterator helpers
    .find((node) => node.is_root);

  const stack = rootNode ? getChildEdges(graph, rootNode.id).reverse() : [];
  let edge;
  while ((edge = stack.pop())) {
    if (!visitedEdges.has(edge)) {
      visitedEdges.add(edge);
      const edgeKey = `${String(edge.from_node_id)}:${String(edge.to_node_id)}`;
      if (edgesSet.has(edgeKey)) {
        error = setError(error, false, `edges.${edge.id}`, 'REDUNDANT_EDGE');
      }
      edgesSet.add(edgeKey);

      if (edge.to_node_id) {
        const childEdges = getChildEdges(graph, edge.to_node_id);
        stack.push(...childEdges.reverse());
      }
    }
  }

  // NOTE (kyle): due to server constraints, we also check for circular edges
  // and mark them as invalid.
  // NOTE (marcos): cycles are still a constraint in v1, but in v2 we don't
  const checkCycle = opts?.checkCycle ?? true;
  if (rootNode && checkCycle) {
    error = checkForCircularEdges(graph, error, rootNode.id);
  }

  if (graph.medium === 'sms') {
    for (const node of getFirstMessages(graph)) {
      if (node.type !== 'question') {
        error = setError(
          error,
          false,
          `nodes.${node.id}`,
          'INVALID_FIRST_MESSAGE',
        );
      }
    }
  }

  // LAT flows need to begin with at least a single questions/user interaction
  const latNode = graph.nodes
    .values()
    // $FlowFixMe iterator helpers
    .find((node) => node.type === 'live_agent_transfer');
  if (latNode) {
    const questions = findAncestorQuestionsForEditor(graph, latNode, false);
    const hasPreceedingQuestion = questions.length > 0;
    error = setError(
      error,
      hasPreceedingQuestion,
      `nodes.${latNode.id}`,
      'MISSING_PRECEDING_QUESTION',
    );
  }

  return error;
}

// eslint-disable-next-line max-params
function checkForCircularEdges(
  graph,
  error,
  nodeId,
  visitedNodes = new Set(),
  nodePath = new Set(),
) {
  let nextError = error;

  visitedNodes.add(nodeId);
  nodePath.add(nodeId);

  const childEdges = getChildEdges(graph, nodeId);

  for (const childEdge of childEdges) {
    const {to_node_id} = childEdge;
    if (to_node_id) {
      if (nodePath.has(to_node_id)) {
        nextError = setError(
          nextError,
          false,
          `edges.${childEdge.id}`,
          'CIRCULAR_EDGE',
        );
      } else if (!visitedNodes.has(to_node_id)) {
        nextError = checkForCircularEdges(
          graph,
          nextError,
          to_node_id,
          visitedNodes,
          nodePath,
        );
      }
    }
  }

  nodePath.delete(nodeId);

  return nextError;
}

// Checks graph for non-blocking warnings.
export function checkGraph(graph: EditableGraph): ?Validation2 {
  let error;
  const {root, ...rest} = graph;
  const normalizedGraph = {
    ...rest,
    edges: Array.from(graph.edges, ([_, value]) => value),
    nodes: Array.from(graph.nodes, ([_, value]) => value),
  };

  const terminalNodes = getTerminalNodes(normalizedGraph);
  for (const [key, node] of graph.nodes) {
    if (node.is_root && node.text_draft_json != null) {
      error = setError2(
        error,
        includesString(node.text_draft_json, '?'),
        `nodes.${key}.text_draft_json`,
        'SMS_RECOMMEND_QUESTION',
      );
    }
    if (node.type === 'text_to_apply') {
      const unusedKeywords = (node.answers ?? []).filter(
        ({value}) => !graph.text_to_apply_config.keywords.includes(value),
      );

      error = setError2(
        error,
        unusedKeywords.length < 1,
        `nodes.${key}.answers`,
        'MISSING_T2A_KEYWORD',
      );
    }
    if (node.type === 'question') {
      error = setError2(
        error,
        includesString(node.text_draft_json, '?'),
        `nodes.${key}.text_draft_json`,
        'NO_QUESTION_MARK',
      );
      const isLeafQuestion = Boolean(
        terminalNodes.find((termNode) => termNode.id === node.id),
      );
      error = setError2(
        error,
        !isLeafQuestion,
        `nodes.${key}.text_draft_json`,
        'NO_LEAF_QUESTION',
      );
    }

    if (
      node.type === 'disposition' &&
      graph.use_case === 'application_inbound_web'
    ) {
      error = setError2(
        error,
        node.writeback_field_id != null,
        `nodes.${key}.writeback_field_id`,
        `MISSING_APPLICATION_DISPOSITION_VALUE`,
      );
    }
  }
  return error;
}

type ValidationOpts = {
  checkCycle?: boolean,
};

export function validateGraph(
  graph: Graph,
  opts?: ValidationOpts,
): ?Validation {
  return validateEditableGraph(createEditableGraph(graph), opts);
}

export function getRoot(graph: EditableGraph): ?Edge {
  return graph.edges.get(graph.root);
}

export function getChildEdges(graph: EditableGraph, nodeId: Id): Edge[] {
  return Array.from(
    // $FlowIssue Iterator helpers
    graph.edges.values().filter((edge) => edge.from_node_id === nodeId),
  );
}
export function getParentEdges(graph: EditableGraph, nodeId: Id): Edge[] {
  return Array.from(
    // $FlowIssue Iterator helpers
    graph.edges.values().filter((edge) => edge.to_node_id === nodeId),
  );
}

function getChildNodes(graph: EditableGraph, nodeId: Id): ConversationNode[] {
  return getChildEdges(graph, nodeId)
    .map((edge): ?ConversationNode =>
      edge.to_node_id != null ? graph.nodes.get(edge.to_node_id) : null,
    )
    .filter(Boolean);
}

export function getAllChildNodes(
  graph: EditableGraph,
  nodeId: Id,
): ConversationNode[] {
  const childNodes: ConversationNode[] = [];
  const childEdges = getChildEdges(graph, nodeId);

  for (const childEdge of childEdges) {
    const {to_node_id} = childEdge;

    const childNode: ?ConversationNode = to_node_id
      ? graph.nodes.get(to_node_id)
      : null;

    if (childNode) {
      childNodes.push(childNode);
      childNodes.push(...getAllChildNodes(graph, childNode.id));
    }
  }
  return childNodes;
}

// NOTE (kyle): in certain situations, we have hashed access to a root edge,
// and should use it when possible.
function getRootNode(graph: EditableGraph): ?ConversationNode {
  for (const node of graph.nodes.values()) {
    if (node.is_root) {
      return node;
    }
  }
}

function getNextNodesOfType(
  graph: EditableGraph,
  types: ConversationNodeType[],
  startNodes: ConversationNode[],
  visitedNodes: Set<ConversationNode> = new Set(),
): [Array<ConversationNode>, Set<ConversationNode>] {
  const stack = [...startNodes];
  const firstMessages = [];
  const typeSet = new Set(types);

  let node;
  while ((node = stack.shift())) {
    if (node && !visitedNodes.has(node)) {
      visitedNodes.add(node);
      if (typeSet.has(node.type)) {
        firstMessages.push(node);
      } else {
        stack.push(...getChildNodes(graph, node.id));
      }
    }
  }

  return [firstMessages, visitedNodes];
}

export function hasWritebackNode(graph: Graph): boolean {
  return graph.nodes.some((node) => {
    if (['question', 'disposition'].includes(node.type)) {
      if (node.writeback_field_id != null) {
        return true;
      }
    }
  });
}

export function hasResponseType(graph: Graph, type: ResponseType): boolean {
  return graph.nodes.some(
    (node) => node.type === 'question' && node.response_type === type,
  );
}

// Both questions and lat nodes can trigger branching based on a response
// separate from a condition, use this to verify that the node type can branch
// by using its .answers field.
export function isBranchableNodeType(node: ConversationNode): boolean {
  return [
    'question',
    'live_agent_transfer',
    'text_to_apply',
    'scheduler',
  ].includes(node.type);
}

export function getFWBKey(usesOWB: boolean = false): {
  wbFieldIdName: string,
  getWbFieldId: (?AttributeMetaData) => ?string,
} {
  // See CHAT-2347, 'field_id' refers to new object writeback field id
  // and 'ats_field_mapping_id' is the original non-owb key
  const fieldIdName = usesOWB ? 'field_id' : 'ats_field_mapping_id';
  const getWbFieldId = (metadata: ?AttributeMetaData): ?string =>
    Reflect.get(metadata ?? {}, fieldIdName);
  return {
    wbFieldIdName: fieldIdName,
    getWbFieldId,
  };
}

// NOTE (nishant):  if all mandatory fields for the ATS candidate creation are available as a question node (or disposition) and
// their appropriate ATS WB field is added in the node.
export function getCandidateCreationErrorMessages(
  graph: Graph,
  writebackAttributes: ?WritebackAllowedAttributes,
  usesOWB?: boolean,
): Array<Array<string>> {
  const simpleErrors = [];

  // Early Exit if question node with email type is not present
  if (!hasResponseType(graph, 'email')) {
    simpleErrors.push([
      'no_sourcing_email_validation',
      `Without an email validating question node, this sourcing flow will be unable to
      create new candidates and write back the other questions you've included to your ATS`,
    ]);

    return simpleErrors;
  }

  const {getWbFieldId} = getFWBKey(usesOWB);

  const attributeMetaData = writebackAttributes?.attribute_meta_data;

  // Failsafe
  if (!attributeMetaData || !attributeMetaData.length) {
    return simpleErrors;
  }

  // TODO(marcos): replace this mandatory field logic with steve's and
  // add unit tests to match backend.
  const mandatoryFieldIds = [];

  // populate mandatory field Ids for "top level fields" (no parent id)
  attributeMetaData.forEach((writebackAttribute) => {
    if (
      writebackAttribute.create_operation === 'required' &&
      writebackAttribute.field_group === 'simple' &&
      !writebackAttribute.parent_field_id
    ) {
      mandatoryFieldIds.push(getWbFieldId(writebackAttribute));
    }
  });

  // this is only present in new ab-specific attribute data
  // otherwise this field is called owb_field_id.
  if (attributeMetaData.some((attr) => attr.field_id != null)) {
    const writebackAttributesById: {[string]: ?AttributeMetaData} = keyBy(
      attributeMetaData,
      'field_id',
    );
    const requiredParentIds = attributeMetaData
      .filter(
        (attr) =>
          attr.create_operation === 'required' &&
          attr.field_group === 'simple' &&
          writebackAttributesById[attr.parent_field_id]?.create_operation ===
            'required',
      )
      .map((attr) => attr.field_id);

    // we don't care where these ids show up,
    // just that they show up as required, so we unshift them
    // to the front of this array, they'll get handled later
    mandatoryFieldIds.unshift(...requiredParentIds);
  }

  // Exit if no fields are mandatory
  if (!mandatoryFieldIds.length) {
    return simpleErrors;
  }

  // get all the writeback field ids for the node
  const foundFieldIds = graph.nodes.flatMap(writebackFieldIdsForNode);

  // filter found field ids from the required set
  const remainingMandatoryFieldIds = mandatoryFieldIds.filter(
    (id) => !foundFieldIds.includes(id),
  );

  // if any field ids remain, keep a list of their display names
  const remainingMandatoryFieldNames = remainingMandatoryFieldIds.map((id) => {
    const foundAttribute = attributeMetaData.find(
      (attr) => id === getWbFieldId(attr),
    );

    return foundAttribute?.display_name;
  });

  // Late exit if some field validations are missing, show their names in error
  if (remainingMandatoryFieldIds.length) {
    simpleErrors.push([
      'no_sourcing_required_fields_validation',
      `Without all mandatory fields(${remainingMandatoryFieldNames.join(
        ', ',
      )}) to create a new candidate in the ATS, this flow will be unable to create new candidates and writeback other fields to your ATS.`,
    ]);
    return simpleErrors;
  }

  return simpleErrors;
}

/**
 * use this to extract the writeback field ids for each node
 */
export function writebackFieldIdsForNode(node: ConversationNode): string[] {
  const result = [];
  if (node.type === 'question') {
    if (node.writeback_field_id) {
      result.push(node.writeback_field_id);
    }
    if (node.derived_field_map) {
      // derived_field_map is optional for QuestionNodes but not for address response_types
      // but we use this optional chain to short circuit a {} => [] return
      result.push(
        ...values(node.derived_field_map ?? {})
          .map((derivedField) => {
            return derivedField.writeback_field_id;
          })
          .filter(Boolean),
      );
    }
  } else if (node.type === 'disposition' && node.writeback_field_id) {
    result.push(node.writeback_field_id);
  }
  return result;
}

export function hasNodesOfType(
  graph: Graph,
  type: ConversationNodeType,
): boolean {
  return graph.nodes.some((node) => node.type === type);
}

export function getTerminalNodes(graph: Graph): ConversationNode[] {
  const terminalEdges = graph.edges.filter((edge) => edge.to_node_id === null);
  return graph.nodes.filter((node) =>
    terminalEdges.map((edge) => edge.from_node_id).includes(node.id),
  );
}

export function hasLeafNodeType(
  graph: Graph,
  nodeType: ConversationNodeType,
): boolean {
  const possiblyBad = getTerminalNodes(graph);
  return possiblyBad.some((node) => node.type === nodeType);
}

export function hasLeafQuestion(graph: Graph): boolean {
  return hasLeafNodeType(graph, 'question');
}

export function hasLeafLATNode(graph: Graph): boolean {
  return hasLeafNodeType(graph, 'live_agent_transfer');
}

export function badNodes(graph: Graph): ConversationNode[] {
  const possiblyBad = getTerminalNodes(graph);
  return possiblyBad.filter((node) => node.type === 'question');
}

export function getFirstMessages(
  graph: EditableGraph,
): Array<ConversationNode> {
  const rootNode = getRootNode(graph);
  return rootNode
    ? getNextNodesOfType(
        graph,
        ['message', 'question'],
        [rootNode],
        new Set(),
      )[0]
    : [];
}

// NOTE (kyle): This will return the list of messages that can
// possibly be triggered AFTER the users's first response.
// This is useful for knowing when it would make sense to attach
// links that would otherwise be carrier filtered.
export function getFirstPostUserResponseMessage(
  graph: EditableGraph,
): Array<ConversationNode> {
  const rootNode = getRootNode(graph);

  if (!rootNode) {
    return [];
  }

  const [questionNodes, visitedNodes] = getNextNodesOfType(
    graph,
    ['question'],
    [rootNode],
    new Set(),
  );

  const nextNodes = questionNodes
    .map((node) => getChildNodes(graph, node.id))
    .flat();

  return getNextNodesOfType(
    graph,
    ['message', 'question'],
    nextNodes,
    visitedNodes,
  )[0];
}

export function getMultipleChoiceLabel(index: number): string {
  return String.fromCharCode(index + 65);
}

export const conditionOperatorLabels: {
  [ConditionOperator]: string,
} = {
  every: 'Contains',
  none: 'Does not contain',
};

export const flowMediumLabels: {
  [ChatbotMedium]: string,
} = {
  webapp: 'Web',
  sms: 'SMS',
};

export const useCaseLabels: {
  [UseCase]: string,
} = {
  data_enrichment: 'Data Enrichment',
  prescreening: 'Pre-screening',
  sourcing: 'Sourcing',
  application_inbound_web: 'Application',
};

// show all edges keyed by the id of the node they arrive at
// assumes that nodes always have a single ancestor
export const edgesByDestination = (graph: EditableGraph): {[Id]: Edge} =>
  [...graph.edges.values()].reduce((acc, curr) => {
    if (curr.to_node_id) {
      acc[curr.to_node_id] = curr;
    }
    return acc;
  }, {});

// return an aray of parent nodes for a given node in a graph
export const findParents = (
  node: ?ConversationNode,
  graph: EditableGraph,
): ConversationNode[] => {
  const edgeMap = edgesByDestination(graph);

  const seenNodeIds = [];

  const findParentHelper = (
    currentNode: ?ConversationNode,
    parentNodes: ConversationNode[] = [],
  ) => {
    if (currentNode) {
      // we've hit a cycle here, stop and return all nodes we've seen so far
      if (seenNodeIds.includes(currentNode.id)) {
        return parentNodes;
      }
    }

    // is there an edge leading to this node?
    const foundEdge = currentNode?.id ? edgeMap[currentNode.id] : null;

    // all nodes should have edges that point to them
    if (foundEdge) {
      const foundNodes = [...parentNodes];

      if (currentNode) {
        seenNodeIds.push(currentNode.id);
      }
      const parentId = foundEdge.from_node_id;
      const parentNode = parentId != null ? graph.nodes.get(parentId) : null;

      if (parentNode != null) {
        foundNodes.push(parentNode);
        return findParentHelper(parentNode, foundNodes);
      } else {
        return foundNodes;
      }
    } else {
      return parentNodes;
    }
  };

  return findParentHelper(node, []);
};

export const findAncestorQuestions = (
  graph?: EditableGraph,
  node?: ConversationNode,
  showFileUploadNodeVar?: boolean,
  showJobMatchNodeVar?: boolean = false,
): ConversationNode[] => {
  const parents = node && graph ? findParents(node, graph) : [];
  const questionNodes = parents.filter(
    (node) =>
      node.type === 'question' ||
      (showFileUploadNodeVar && node.type === 'file_upload') ||
      (showJobMatchNodeVar && node.type === 'job_match'),
  );
  return questionNodes;
};

export const findAncestorFileNodes = (
  graph: EditableGraph,
  node?: ConversationNode,
  edgeId?: Id,
): {node_id: Id, name: string}[] => {
  const parentNodeId = edgeId ? graph.edges.get(edgeId)?.from_node_id : null;
  let currentNode;
  let nodeVariables;
  if (edgeId != null && parentNodeId != null) {
    currentNode = graph.nodes.get(parentNodeId);
    nodeVariables = findAncestorQuestionsForEditor(
      graph,
      currentNode,
      true,
      true,
    );
  } else {
    currentNode = node;
    nodeVariables = findAncestorQuestionsForEditor(
      graph,
      currentNode,
      undefined,
      true,
    );
  }
  return [...graph.nodes.values()]
    .filter((node) => node.type === 'file_upload')
    .map((nv) => {
      const isParent = nodeVariables.some((v) => v.node_id === nv.id);
      return {node_id: nv.id, name: isParent ? nv.name : `${nv.name} (*)`};
    });
};

// find ancestor questions but don't include self
export const findAncestorQuestionsForEditor = (
  graph?: EditableGraph,
  node?: ConversationNode,
  includeNode?: boolean,
  showFileUploadNodeVar?: boolean,
  showJobMatchNodeVar?: boolean = false,
): NodeVariables =>
  findAncestorQuestions(graph, node, showFileUploadNodeVar, showJobMatchNodeVar)
    .filter((ancestor) =>
      node && !includeNode ? ancestor.id !== node.id : true,
    )
    .flatMap(nodeToNodeVariable);

export const findAncestorNodeVariables = (
  graph: EditableGraph,
  node?: ConversationNode,
  edgeId?: Id,
  showFileUploadNodeVar?: boolean,
  showJobMatchNodeVar?: boolean = false,
  showMeetingNodeVar?: boolean = false,
): NodeVariables => {
  const parentNodeId = edgeId ? graph.edges.get(edgeId)?.from_node_id : null;
  let currentNode;
  let nodeVariables;
  if (edgeId != null && parentNodeId != null) {
    currentNode = graph.nodes.get(parentNodeId);
    nodeVariables = findAncestorQuestionsForEditor(
      graph,
      currentNode,
      true,
      showFileUploadNodeVar,
      showJobMatchNodeVar,
    );
  } else {
    currentNode = node;
    nodeVariables = findAncestorQuestionsForEditor(
      graph,
      currentNode,
      undefined,
      showFileUploadNodeVar,
      showJobMatchNodeVar,
    );
  }
  const allNodeVariables = [...graph.nodes.values()]
    .filter(
      (node) =>
        node.type === 'question' ||
        (showFileUploadNodeVar && node.type === 'file_upload') ||
        (showJobMatchNodeVar && node.type === 'job_match'),
    )
    .flatMap(nodeToNodeVariable)
    .map((nv) => {
      if (!nodeVariables.some((v) => v.node_id === nv.node_id)) {
        return {...nv, name: `${nv.name} (*)`};
      }
      return nv;
    });

  if (showMeetingNodeVar) {
    const meetingNodes = [...graph.nodes.values()].filter(
      ({type}) => type === 'scheduler',
    );
    meetingNodes.forEach((node) => {
      allNodeVariables.push(
        {
          derived_field_name: 'email',
          name: `${node.name} // Email`,
          node_id: node.id,
          node_name: node.name,
          template: '“$”',
          node_type: 'scheduler',
          attribute_name: 'email',
        },
        {
          derived_field_name: 'external_source_id',
          name: `${node.name} // Recruiter ID`,
          node_id: node.id,
          node_name: node.name,
          template: '“$”',
          node_type: 'scheduler',
          attribute_name: 'external_source_id',
        },
      );
    });
  }

  // sort so that possibly unavailable nodes with (*) are sorted last
  return allNodeVariables.sort((left, right) => {
    const leftEmpty = left.name.endsWith('(*)');
    const rightEmpty = right.name.endsWith('(*)');
    if ((leftEmpty && rightEmpty) || (!leftEmpty && !rightEmpty)) {
      return 0;
    } else {
      if (leftEmpty) {
        return 1;
      }
      return -1;
    }
  });
};

export const findAncestorSummaryItems = (
  graph?: EditableGraph,
  node?: ConversationNode,
): SummaryItem[] => findAncestorQuestions(graph, node).map(nodeToSummaryItem);

export const botsBuiltCount = (statusCount: {
  [key: string]: number,
  ...
}): number => statusCount.building + statusCount.failed + statusCount.success;

export function generateGraph(partialGraph?: $Shape<Graph>): Graph {
  return {
    ...__minimalGraph,
    ...partialGraph,
  };
}

const __minimalGraph = {
  id: 1,
  name: 'Basic Graph',
  description: 'Basic Description',
  use_case: 'sourcing',
  medium: 'webapp',
  status: 'init',
  bot_type: 'state_machine',
  time_created: '',
  time_updated: undefined,
  time_building: undefined,
  time_success: undefined,
  time_failed: undefined,
  anchor_entity_type: 'bh_candidate',
  event_ids: [],

  nodes: [],
  edges: [],

  sourcing_config: {
    id: '2',
    enabled: false,
    urls: [],
    long_url: '',
    short_url: '',
    widget_timeout_seconds: 60,
    display_mode: 'max',
    mobile_display_mode: 'min',
  },
  text_to_apply_config: {
    enabled: false,
    phone_numbers: [],
    keywords: [],
  },
  brand_id: '',
  brand_settings: {},
  flow_config: {
    feedback_enabled: false,
    feedback_question: {
      blocks: [],
      entityMap: {},
    },
  },
  show_top_faqs: false,
  lat_config: {
    show_lat_button: false,
    enable_keyword_trigger: false,
    target_flow_name: null,
    target_flow_id: null,
    button_label: 'Chat with a recruiter',
  },
};

export const jobMatchCriterion: {label: string, value: Criteria}[] = [
  {label: 'Location', value: 'location'},
  {label: 'State', value: 'state'},
  {label: 'Job Title', value: 'job_title'},
  {label: 'Additional Keywords', value: 'keywords'},
  {label: 'Skills', value: 'skills'},
];

export const jmExpansionCriteria: {label: string, value: Criteria}[] = [
  ...jobMatchCriterion,
  {label: 'Worksite Options', value: 'worksite_options'},
];

export const maxMatchesOptions = [
  {label: '3 matches', value: 3},
  {label: '5 matches', value: 5},
  {label: '7 matches', value: 7},
  {label: '10 matches', value: 10},
];

export const noMatchHandlingOptions = [
  {label: 'Show most recent jobs posted by agency', value: 'show_recent'},
  {label: 'Show no matches', value: 'show_none'},
];

//Condition Node

export const operatorOptions = [{value: 'equals', label: 'equal to'}];

export const stringOperatorOptions: Array<{value: Operator, label: string}> = [
  ...operatorOptions,
  {value: 'in', label: 'one of'},
  {value: 'starts_with', label: 'start with'},
  {value: 'ends_with', label: 'end with'},
  {value: 'contains', label: 'contains'},
];

export const numericOperatorOptions: Array<{
  value: Operator,
  label: string,
}> = [
  ...operatorOptions,
  {value: 'greater_than', label: 'greater than'},
  {value: 'less_than', label: 'less than'},
];

export const dateResponseTypes: Set<ResponseType> = new Set([
  'date',
  'end date',
  'start date',
]);
export const numericResponseTypes: Set<ResponseType> = new Set([
  ...dateResponseTypes,
  'number',
  'money',
  'currency',
]);

export const baseNotOptions = [
  {value: true, label: 'not'},
  {value: false, label: ''},
];

export function getBooleanOptions(
  operator: ?Operator,
): Array<{value: boolean, label: string}> {
  const verb = ['equals', 'greater_than', 'less_than', 'in'].includes(operator)
    ? 'is'
    : 'does';

  return baseNotOptions.map((option) => ({
    ...option,
    label: verb + ' ' + option.label,
  }));
}

export function findOption<V, O: {value: V, ...}>(
  options: Array<O>,
  value: ?V,
): ?O {
  return options.find((option) => option.value === value);
}

export function updateExpression(
  ruleset: EditableRuleset,
  expressionId: Id,
  update: $Shape<EditableExpression>,
): EditableRuleset {
  return {
    ...ruleset,
    expressions: ruleset.expressions.map((expression) =>
      expression.id === expressionId ? {...expression, ...update} : expression,
    ),
  };
}

export function createExpression(): EditableExpression {
  return {
    id: generateId(),
    is_not: false,
    operator: 'equals',
    condition_value: [],
  };
}

type EditableConditionNode = {
  ...ConditionNode,
  condition_ruleset: EditableRuleset,
};

export function validateRuleset(
  ruleset: EditableRuleset,
  dynamicLabelMap: {[key: string]: VariablePickerDynamicLabels, ...},
): ?Validation {
  let error = null;

  error = setError(
    error,
    ruleset.expressions.length > 0,
    'expressions',
    'Must have at least one expression.',
  );

  for (const [index, expression] of ruleset.expressions.entries()) {
    const path = `expressions[${index}]`;
    error = setError(error, expression.operator, path + 'operator', 'Required');
    error = setError(
      error,
      expression.node_id_answer || expression.dynamic_field,
      path,
      'Required node_id_answer or dynamic_field',
    );
    /*
    error = setError(
      error,
      (expression.condition_value?.length ?? 0) > 0,
      path + 'condition_value',
      'Must have at least one condition_value.',
    );
    */
  }

  return error;
}

export function initializeEditableRuleset(ruleset: Ruleset): EditableRuleset {
  return {
    ...ruleset,
    expressions: ruleset.expressions.map((expression) =>
      // $FlowFixMe this code works fine, but Flow doesn't like entries()
      Object.fromEntries(
        Object.entries(expression).filter(([_, value]) => value != null),
      ),
    ),
  };
}

type DeepReadOnlyFn = (<T>(Array<T>) => $ReadOnlyArray<$DeepReadOnly<T>>) &
  (<T: {}>(T) => $ReadOnly<$ObjMap<T, DeepReadOnlyFn>>) &
  (<T>(T) => T);

type $DeepReadOnly<T> = $Call<DeepReadOnlyFn, T>;

export const validationTypeByQuestionType = {
  phone: 'phone number',
  email: 'email',
  name: 'string',
  number: 'number',
  checkbox: 'boolean',
  consent: 'boolean',
  multi_line_text: 'string',
  dropdown: 'string',
};

export const questionText = {
  name: 'What is your name?',
  email: 'What is your email address?',
  purpose: 'What is the purpose of this conversation?',
  phone: 'What is your phone number?',
};

export const dataTypeOptions: Array<{value: ResponseType, label: string, ...}> =
  [
    {value: 'string', label: 'None'},
    {value: 'number', label: 'Number'},
    {value: 'date', label: 'Date'},
    {value: 'start date', label: 'Future Date'},
    {value: 'end date', label: 'Past Date'},
    {value: 'money', label: 'Currency'},
    {value: 'phone number', label: 'Phone Number'},
    {value: 'zipcode', label: 'Zipcode'},
    {value: 'email', label: 'Email'},
    {value: 'address', label: 'Address'},
  ];

export const writebackDataTypesToValidationTypes: Map<
  string,
  Array<string>,
> = new Map([
  ['date', ['date', 'start date', 'end date']],
  ['number', ['number', 'zipcode']],
  ['integer', ['number', 'zipcode']],
  ['double', ['number']],
]);

export const templateTypeOptions = [
  {
    value: 'dynamic_template',
    label: 'Dynamic Template',
    description:
      'These templates utilize global variables to map to specific scheduler templates.',
  },
  {
    value: 'static_template',
    label: 'Team Template',
    description:
      'These are templates that are available for use across your team.',
  },
];

export const QUESTION_SIMILARITY_THRESHOLD = 0.3;

/**
 * Update NodeVariable entities to match updated node names, fields
 *
 * when we save a draft_json value, we hard-code in the text the name
 * of the node variable as it was when we created the draftjs content.
 *
 * however, when people change nodes to have different names, this
 * would cause a disconnect between what we show in the draftjs textarea
 * and what the actual names of the nodes/fields are in the flow.
 * this function walks a contentstate and updates the underlying text
 * to match the current node variable names/values.
 *
 * because node variables contain all useful information to render them
 * in the backend regardless of their underlying text content, we only
 * run this function so that the text content during edit matches the
 * names of all the nodes and their fields.
 *
 * this function should be run either
 * - when you are initializing a draft instance
 * - when you are making changes to the flow
 *
 * we currently run it when initializing the draft instance, but we may
 * in future move this to the reducer during a node save in order to
 * 'preflight' all the values
 */
export function updateNodeVariableReferences(
  initialContentState: ContentState,
  currentGraph: EditableGraph,
  nodeVariables?: NodeVariables = [],
): ContentState {
  const nextState = updateEntitiesInContentState(
    initialContentState,
    (entity) => {
      return entity.type === 'NODE_VARIABLE';
    },
    (entity, textContent) => {
      let content = textContent;

      const {derived_field_name, node_id, template} = entity.getData();
      const node = currentGraph.nodes.get(node_id);

      if (node == null) {
        // this... shouldn't happen, but if it does, just leave the text
        // content alone since we can't replace it meaningfully.
        // this also serves as an invariant for the part that follows
        return content;
      }
      const name = node.name;
      if (name != null) {
        const {field_label} =
          // $FlowIssue[prop-missing] - ok if derived field map is not present
          node.derived_field_map?.[derived_field_name] ?? {};
        // update a variable with a derived field name to match current
        // node name
        if (field_label != null) {
          // NOTE: this is just a rehash of the string we save as
          //       children but it _correctly_ refers to nodes/labels
          const updated = nodeVariableDisplayName(node, derived_field_name);
          if (updated !== textContent) {
            content = updated;
            return content;
          }
        } else {
          const match = nodeVariables.find((nv) => nv.node_id === node_id);
          if (match != null) {
            content = match.template.replace('$', match.name);
          } else {
            content = template.replace('$', `$name (*)`);
          }
        }
      } else {
      }
      return content;
    },
  );
  return nextState;
}
