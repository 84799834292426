// @flow strict
import * as React from 'react';

import {useSenseAi} from 'src/hooks/useSenseAi';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {SenseAiIntroPanel} from 'src/components/sense-ai/ai-intro-panel.jsx';

import css from './sense-ai.css';


type ClassNames = $ReadOnly<{
  aiPanel?: string,
}>;

export type SenseAiProps = {
  children?: React.Node,
  defaultIntroOpen?: boolean,
  heading?: React.Node,
  description?: React.Node,
  confirmButtonText?: string,
  dismissButtonText?: string,
  onConfirmButtonClick?: ?(e?: SyntheticEvent<HTMLElement>) => mixed,
  onDismissButtonClick?: ?(e?: SyntheticEvent<HTMLElement>) => mixed,
  onTrackEvent?: (
    event: 'Content Generated' | 'Content Generator Visited',
  ) => mixed,
  url?: string,
  classNames?: ClassNames,
  onAiPanelOpen?: ?(e?: SyntheticEvent<HTMLElement>, isOpen?: boolean) => mixed,
  showIntro?: boolean,
};

export const SenseAi = ({
  children,
  defaultIntroOpen = false,
  heading,
  description,
  confirmButtonText,
  onConfirmButtonClick,
  onDismissButtonClick,
  classNames,
  onAiPanelOpen,
  showIntro = true,
  onTrackEvent,
}: SenseAiProps): React.Node => {
  const {
    state: {showAiPanel, showCustomComponent},
    setShowAiPanel,
    setShowCustomComponent,
  } = useSenseAi();

  const shouldShowIntro = showIntro && showAiPanel;

  const onConfirm = (e) => {
    showIntro && onTrackEvent && onTrackEvent('Content Generated');
    setShowCustomComponent(true);
    setShowAiPanel(false);
    onConfirmButtonClick?.(e);
    // TODO(Nishant): This is because the toast incorrectly
    // calls onDismiss when we transition to custom component/children screen
    onAiPanelOpen?.(e, true);
  };

  const onDismiss = (e) => {
    setShowCustomComponent(false);
    setShowAiPanel(false);
    onDismissButtonClick?.(e);
    onAiPanelOpen?.(e, false);
  };

  const handleAIButtonClick = (e) => {
    onTrackEvent && onTrackEvent('Content Generator Visited');
    if (showIntro) {
      const show = !showAiPanel;
      setShowAiPanel(show);
      onAiPanelOpen?.(e, true);
    } else {
      onConfirm(e);
    }
  };

  //NOTE:(diwakersurya) this is considered only for first time
  React.useEffect(() => {
    setShowAiPanel(defaultIntroOpen);
    if (defaultIntroOpen) {
      setShowCustomComponent(false);
    }
  }, []);

  const disableAiButton = showIntro
    ? showCustomComponent || showAiPanel
    : showCustomComponent;

  return (
    <div className={css.aiContainer}>
      <div className={classNames?.aiPanel}>
        {showCustomComponent && children}
        {shouldShowIntro && (
          <SenseAiIntroPanel
            heading={heading}
            description={description}
            confirmButtonText={confirmButtonText}
            onConfirmButtonClick={onConfirm}
            onDismissButtonClick={onDismiss}
          />
        )}
      </div>
      <Button
        size="small"
        type="secondary"
        iconLeftName="sparkles"
        iconLeftType="solid"
        disabled={disableAiButton}
        onClick={handleAIButtonClick}
      />
    </div>
  );
};
