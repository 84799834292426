// @flow strict
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';

// $FlowFixMe[nonstrict-import]
import {popModal} from 'src/action-creators/modal';
import {classify} from 'src/utils/classify';
import {useHistory} from 'src/rerouter';
import {
  updateActiveFieldMappingScreen,
  saveStandardFieldMapping,
  confirmMigrationFieldMapping,
} from 'src/action-creators/field-mapping';
import {
  selectStandardEntityDesignationLoading,
  selectActiveFieldMappingScreen,
  selectStandardEntityDesignationCount,
  selectConfirmMigrateFieldMappingLoading,
  selectConfirmMigrateFieldMappingSuccess,
  selectAnyChangesInDesignation,
  selectSaveStandardFieldMappingLoading,
} from 'src/selectors/field-mapping';
import type {
  FieldMappingScreen,
  StandardEntityDesignationCount,
} from 'src/types/field-mapping';

import {Button} from 'src/designSystem2021Components/buttons.jsx';
import {
  Header,
  Clickable,
  Paragraph,
  SubHeader,
  Smallest,
} from 'src/designSystem2021Components/text-v2.jsx';
import FieldSelectionTable from 'src/components/field-mapping/field-selection-table.jsx';
import ChevronDoubleRightIcon from 'src/images/icons/chevron-double-right.svg?noAttrs';
import NextIcon from 'src/images/designSystems2021/next.svg?noAttrs';
import CloseIcon from 'src/images/designSystems2021/close.svg?noAttrs';
import PrevIcon from 'src/images/designSystems2021/prev.svg?noAttrs';
import WarningIcon from 'src/images/designSystems2021/warning-no-rect.svg';
import CheckIcon from 'src/images/icons/check.svg?noAttrs';
import JourneyFlow from 'src/images/field-mapping/new-journey.svg';
import css from './new-journey-builder-modal.css';


function TopicCard(props) {
  const {selectedIndex, index, setSelectedIndex} = props;
  const isActive = index === selectedIndex;
  const onClickHandler = () => {
    setSelectedIndex(index);
  };
  return (
    <div
      className={classify(css.topicCard, {[css.activeTopicCard]: isActive})}
      onClick={onClickHandler}
    >
      <SubHeader
        className={classify(css.cardTitle, isActive && css.activeCardTitle)}
      >
        <Clickable>{props.title}</Clickable>
        <NextIcon className={classify(isActive && css.activeNextIcon)} />
      </SubHeader>
      <Smallest className={css.cardDescription}>{props.description}</Smallest>
    </div>
  );
}

export default function NewJourneyBuilderModal(): React.Element<'div'> {
  const dispatch = useDispatch();
  const router = useHistory();
  const closeModal = () => dispatch(popModal());
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [finalErrorState, setFinalError] = React.useState(false);
  const activeScreen: FieldMappingScreen = useSelector(
    selectActiveFieldMappingScreen,
  );
  const standardEntityDesignationLoading: boolean = useSelector(
    selectStandardEntityDesignationLoading,
  );
  const confirmMigrateFieldMappingLoading: boolean = useSelector(
    selectConfirmMigrateFieldMappingLoading,
  );
  const confirmMigrateFieldMappingSuccess: boolean = useSelector(
    selectConfirmMigrateFieldMappingSuccess,
  );
  const designationCount: StandardEntityDesignationCount = useSelector(
    selectStandardEntityDesignationCount,
  );
  const anyChangesInDesignation: boolean = useSelector(
    selectAnyChangesInDesignation,
  );
  const saveStandardFieldMappingLoading: boolean = useSelector(
    selectSaveStandardFieldMappingLoading,
  );

  const mapFieldsClickHandler = () => {
    dispatch(updateActiveFieldMappingScreen('FieldSelection'));
  };
  const backClickHandler = () => {
    dispatch(updateActiveFieldMappingScreen('Intro'));
  };
  const settingsClickHandler = () => {
    closeModal();
    router.push({
      ...router.location,
      pathname: '/settings/ats-mapping',
    });
  };
  const saveAsDraftClickHandler = () => {
    dispatch(saveStandardFieldMapping(false));
  };
  const confirmAndMigrateClickHandler = () => {
    dispatch(saveStandardFieldMapping(true));
  };
  const cancelClickHandler = () => {
    dispatch(updateActiveFieldMappingScreen('FieldSelection'));
  };
  const confirmClickHandler = () => {
    dispatch(confirmMigrationFieldMapping());
  };
  React.useEffect(() => {
    if (confirmMigrateFieldMappingSuccess) {
      dispatch(updateActiveFieldMappingScreen('Success'));
    }
  }, [confirmMigrateFieldMappingSuccess]);

  return (
    <div
      className={classify(css.modalContainer, {
        [css.smallModal]:
          activeScreen === 'Confirm' || activeScreen === 'Success',
      })}
    >
      {(activeScreen === 'Intro' || activeScreen === 'FieldSelection') && (
        <div className={css.titleBar}>
          <Header className={css.title}>New Journey builder available</Header>
          <CloseIcon className={css.closeIcon} onClick={closeModal} />
        </div>
      )}
      {activeScreen === 'Confirm' && (
        <div className={css.confirmTitleBar}>
          <WarningIcon className={css.draftModalIcon} />
          <SubHeader className={css.confirmTitle}>
            Are you sure you want to migrate?
          </SubHeader>
        </div>
      )}
      {activeScreen === 'Success' && (
        <div className={css.successTitleBar}>
          <CheckIcon className={css.checkIcon} />
          <SubHeader className={css.successTitle}>
            Migration Request Submitted
          </SubHeader>
        </div>
      )}

      {activeScreen === 'Intro' && (
        <div className={css.modalContent}>
          <div className={css.leftIndex}>
            <Paragraph>{'Whats new?'}</Paragraph>
            <TopicCard
              title={'Intuitive, Simple, and Reimagined ‘Journeys’'}
              description={
                'Intuitive user experience, simplified UI, and better ease of use'
              }
              setSelectedIndex={setSelectedIndex}
              selectedIndex={selectedIndex}
              index={0}
            />
            <TopicCard
              title={'Goal-directed Journey Wizard'}
              description={
                'Create journeys faster and around the goals that you want to achieve'
              }
              setSelectedIndex={setSelectedIndex}
              selectedIndex={selectedIndex}
              index={1}
            />
            <TopicCard
              title={'Flexible, Modular, and Reusable Lists'}
              description={
                'Audience building is now easier with reusable Lists'
              }
              setSelectedIndex={setSelectedIndex}
              selectedIndex={selectedIndex}
              index={2}
            />
          </div>
          <JourneyFlow className={css.visualContent} />
        </div>
      )}
      {activeScreen === 'FieldSelection' && (
        <div className={css.modalContentFieldSelection}>
          <div className={css.selectFieldTitleWrap}>
            <Button
              type="tertiary"
              icon={<PrevIcon />}
              onClick={backClickHandler}
              className={css.backButton}
              size="small"
            ></Button>
            <div className={css.selectFieldTitleContent}>
              <Paragraph className={css.selectFieldParagraph}>
                Start using the new Journey Creation Wizard. <br /> Fill the
                following form so that we can ensure that all your ATS fields
                are properly mapped over. Not to worry, we're doing the heavy
                lifting on our end. Majority of the fields used in Sense are
                already mapped to your ATS. There are a few where we need your
                assistance.
              </Paragraph>
              <Smallest>
                You can also map these values in{' '}
                <span
                  className={css.settingsText}
                  onClick={settingsClickHandler}
                >
                  settings
                </span>{' '}
                tab
              </Smallest>
            </div>
          </div>
          <FieldSelectionTable
            setFinalError={setFinalError}
            containerPage={'modal'}
          />
        </div>
      )}
      {activeScreen === 'Confirm' && (
        <Paragraph className={css.confirmContent}>
          Once you migrate, you won’t be able to revert back to the older
          version.
        </Paragraph>
      )}
      {activeScreen === 'Success' && (
        <SubHeader className={css.successContent}>
          Your request has been submitted. You’re now in line to migrate to the
          new experience! This could take a few days to several weeks depending
          on the volume of requests, so please be patient. You will be notified
          when processing has been completed.
        </SubHeader>
      )}

      {activeScreen === 'Intro' && (
        <div className={css.footer}>
          <Smallest>Before migrating, you just need to map ATS fields</Smallest>
          <Button
            type="primary"
            iconPosition="right"
            icon={<ChevronDoubleRightIcon />}
            onClick={mapFieldsClickHandler}
          >
            Map Fields
          </Button>
        </div>
      )}
      {activeScreen === 'FieldSelection' && (
        <div className={css.footer}>
          <Smallest>
            {designationCount &&
              `${designationCount.mapped} / ${designationCount.total} fields mapped`}
          </Smallest>
          <div className={css.saveMigrateButtons}>
            <Button
              type="tertiary"
              onClick={saveAsDraftClickHandler}
              disabled={
                finalErrorState ||
                !anyChangesInDesignation ||
                saveStandardFieldMappingLoading
              }
            >
              Save as Draft
            </Button>
            <Button
              type="primary"
              onClick={confirmAndMigrateClickHandler}
              disabled={
                finalErrorState ||
                designationCount?.mapped !== designationCount?.total ||
                saveStandardFieldMappingLoading ||
                standardEntityDesignationLoading
              }
            >
              Confirm and Migrate
            </Button>
          </div>
        </div>
      )}
      {activeScreen === 'Confirm' && (
        <div className={css.confirmFooter}>
          <Button
            type="secondary"
            onClick={cancelClickHandler}
            size="small"
            disabled={confirmMigrateFieldMappingLoading}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={confirmClickHandler}
            size="small"
            disabled={confirmMigrateFieldMappingLoading}
          >
            Confirm
          </Button>
        </div>
      )}
      {activeScreen === 'Success' && (
        <div className={css.successFooter}>
          <Button type="primary" onClick={closeModal} size="small">
            Okay
          </Button>
        </div>
      )}
    </div>
  );
}
