// @flow strict

/*
 * Privileges
 */
export const VIEW_WORKFLOWS: 'VIEW_WORKFLOWS' = 'VIEW_WORKFLOWS';
export const EDIT_WORKFLOWS: 'EDIT_WORKFLOWS' = 'EDIT_WORKFLOWS';
export const VIEW_AUDIENCE_MEMBERS: 'VIEW_AUDIENCE_MEMBERS' =
  'VIEW_AUDIENCE_MEMBERS';
export const EDIT_AUDIENCE_MEMBERS: 'EDIT_AUDIENCE_MEMBERS' =
  'EDIT_AUDIENCE_MEMBERS';
export const VIEW_ANALYTICS: 'VIEW_ANALYTICS' = 'VIEW_ANALYTICS';
export const EDIT_SETTINGS: 'EDIT_SETTINGS' = 'EDIT_SETTINGS';
export const EDIT_PERMISSIONS: 'EDIT_PERMISSIONS' = 'EDIT_PERMISSIONS';
export const USE_MESSAGING: 'USE_MESSAGING' = 'USE_MESSAGING';
export const MANAGE_OPT_OUTS: 'MANAGE_OPT_OUTS' = 'MANAGE_OPT_OUTS';
export const EDIT_CHATBOT: 'EDIT_CHATBOT' = 'EDIT_CHATBOT';
export const ADMIN_REFERRALS: 'ADMIN_REFERRALS' = 'ADMIN_REFERRALS';
export const RECRUITER_REFERRALS: 'RECRUITER_REFERRALS' = 'RECRUITER_REFERRALS';
export const MANAGE_CALENDAR: 'MANAGE_CALENDAR' = 'MANAGE_CALENDAR';
export const MANAGE_TEAM_TEMPLATES: 'MANAGE_TEAM_TEMPLATES' =
  'MANAGE_TEAM_TEMPLATES';
export const TRM_ADMIN: 'TRM_ADMIN' = 'TRM_ADMIN';
export const TRM_VIEW: 'TRM_VIEW' = 'TRM_VIEW';
export const EDIT_REFERRALS_V2: 'EDIT_REFERRALS_V2' = 'EDIT_REFERRALS_V2';
export const VIEW_REFERRALS_V2: 'VIEW_REFERRALS_V2' = 'VIEW_REFERRALS_V2';
export const MANAGE_SECURITY: 'MANAGE_SECURITY' = 'MANAGE_SECURITY';
export const ADMIN_REFERRALS_V2: 'ADMIN_REFERRALS_V2' = 'ADMIN_REFERRALS_V2';
export const PAGES_LEADS: 'PAGES_LEADS' = 'PAGES_LEADS';
export const VIEW_PAGES: 'VIEW_PAGES' = 'VIEW_PAGES';
export const EDIT_PAGES: 'EDIT_PAGES' = 'EDIT_PAGES';
export const ADMIN_PAGES: 'ADMIN_PAGES' = 'ADMIN_PAGES';
export const VIEW_LEAD_MANAGEMENT: 'VIEW_LEAD_MANAGEMENT' =
  'VIEW_LEAD_MANAGEMENT';
export const ADMIN_LEAD_MANAGEMENT: 'ADMIN_LEAD_MANAGEMENT' =
  'ADMIN_LEAD_MANAGEMENT';
export const EDIT_LEAD_MANAGEMENT: 'EDIT_LEAD_MANAGEMENT' =
  'EDIT_LEAD_MANAGEMENT';
export const USE_JD_GENERATOR: 'USE_JD_GENERATOR' = 'USE_JD_GENERATOR';
export const USE_PRESCREENING_GENERATOR: 'USE_PRESCREENING_GENERATOR' =
  'USE_PRESCREENING_GENERATOR';
export const USE_RESUME_GENERATOR: 'USE_RESUME_GENERATOR' =
  'USE_RESUME_GENERATOR';
export const USE_ENGAGE_CONTENT_GENERATOR: 'USE_ENGAGE_CONTENT_GENERATOR' =
  'USE_ENGAGE_CONTENT_GENERATOR';
export const USE_MESSAGING_SMS_GENERATOR: 'USE_MESSAGING_SMS_GENERATOR' =
  'USE_MESSAGING_SMS_GENERATOR';
export const PAGES: 'PAGES' = 'PAGES';
export const LEADS: 'LEADS' = 'LEADS';

export const VIEW_AEP_WORKFLOWS: 'VIEW_AEP_WORKFLOWS' = 'VIEW_AEP_WORKFLOWS';
export const EDIT_AEP_WORKFLOWS: 'EDIT_AEP_WORKFLOWS' = 'EDIT_AEP_WORKFLOWS';
export const USE_SURVEY_SUMMARY_GENERATOR: 'USE_SURVEY_SUMMARY_GENERATOR' =
  'USE_SURVEY_SUMMARY_GENERATOR';
export const VIEW_JOBS: 'VIEW_JOBS' = 'VIEW_JOBS';
export const ADMIN_JOBS: 'ADMIN_JOBS' = 'ADMIN_JOBS';

export type Privilege =
  | typeof VIEW_WORKFLOWS
  | typeof EDIT_WORKFLOWS
  | typeof VIEW_AUDIENCE_MEMBERS
  | typeof EDIT_AUDIENCE_MEMBERS
  | typeof VIEW_ANALYTICS
  | typeof EDIT_SETTINGS
  | typeof EDIT_PERMISSIONS
  | typeof USE_MESSAGING
  | typeof MANAGE_OPT_OUTS
  | typeof EDIT_CHATBOT
  | typeof ADMIN_REFERRALS
  | typeof RECRUITER_REFERRALS
  | typeof MANAGE_CALENDAR
  | typeof MANAGE_TEAM_TEMPLATES
  | typeof TRM_ADMIN
  | typeof TRM_VIEW
  | typeof EDIT_REFERRALS_V2
  | typeof VIEW_REFERRALS_V2
  | typeof MANAGE_SECURITY
  | typeof ADMIN_REFERRALS_V2
  | typeof USE_JD_GENERATOR
  | typeof USE_PRESCREENING_GENERATOR
  | typeof USE_RESUME_GENERATOR
  | typeof USE_ENGAGE_CONTENT_GENERATOR
  | typeof USE_MESSAGING_SMS_GENERATOR
  | typeof PAGES_LEADS
  | typeof VIEW_PAGES
  | typeof EDIT_PAGES
  | typeof ADMIN_PAGES
  | typeof VIEW_LEAD_MANAGEMENT
  | typeof ADMIN_LEAD_MANAGEMENT
  | typeof EDIT_LEAD_MANAGEMENT
  | typeof PAGES
  | typeof LEADS
  | typeof VIEW_AEP_WORKFLOWS
  | typeof EDIT_AEP_WORKFLOWS
  | typeof USE_SURVEY_SUMMARY_GENERATOR
  | typeof VIEW_JOBS
  | typeof ADMIN_JOBS;
