// @flow strict

import CleanupIcon from 'src/images/icons/cleanup-hdd-icon.svg';
import EmailIcon from 'src/images/icons/envelop-icon.svg';
import SmsIcon from 'src/images/icons/comment-alt-icon.svg';
import CalendarIcon from 'src/images/icons/calendar-day-icon.svg';
import SyncIcon from 'src/images/icons/sync-icon.svg';

import css from './template-summary.css';


export const PLACEHOLDER_VARIABLE_REGEX: RegExp = /<placeholder:([^>]+)>+/;
export const PLAYBOOK_JOURNEY_PREFIX: string = 'Copy of Playbook:';

export const businessGoals = [
  {value: 'all', label: 'Show all template business goals'},
  {value: 'a', label: 'Business Goal A'},
  {value: 'b', label: 'Business Goal B'},
  {value: 'c', label: 'Business Goal C'},
];

// TODO (nilarnab): make this a component
export const eventTypeDisplays = {
  message: {
    Text: 'Email',
    Icon: EmailIcon,
  },
  survey: {
    Text: 'Survey',
    Icon: EmailIcon,
  },
  sms_message: {
    Text: 'SMS',
    Icon: SmsIcon,
  },
  sms_survey: {
    Text: 'SMS Survey',
    Icon: SmsIcon,
  },
  sms_chatbot: {
    Text: 'SMS Chatbot',
    Icon: SmsIcon,
  },
  nps: {
    Text: 'NPS',
    Icon: EmailIcon,
  },
  sms_nps: {
    Text: 'SMS NPS',
    Icon: SmsIcon,
  },
  sms_job: {
    Text: 'SMS JOB',
    Icon: SmsIcon,
  },
  beefree_email: {
    Text: 'Drag and drop email',
    Icon: EmailIcon,
  },
  beefree_chatbot: {
    Text: 'Drag and drop chatbot',
    Icon: EmailIcon,
  },
  bulk_writeback: {
    Text: 'Triggered cleanup',
    Icon: CleanupIcon,
  },
  digest_email: {
    Text: 'Digest email',
    Icon: EmailIcon,
  },
};
// TODO (nilarnab): make this a component
export const scheduleTypeDisplays = {
  from_date: {
    Text: 'Trigger: Single date',
    Icon: CalendarIcon,
  },
  repeat: {
    Text: 'Trigger: Recurring date',
    Icon: SyncIcon,
  },
  field_change: {
    Text: 'Trigger: Field change',
    Icon: CalendarIcon,
  },
};

export const businessGoalsClassMap = {
  reactivate: css.mauve,
  hire: css.blue,
  enrich_database: css.seaGreen,
  retain: css.brown,
  redeploy: css.purple,
  other: css.purple,
};

export const businessGoalLabelsMap = {
  reactivate: 'Reactivate',
  hire: 'Hire',
  enrich_database: 'Enrich Database',
  retain: 'Retain',
  redeploy: 'Redeploy',
  nurture: 'Nurture',
  convert: 'Convert',
  activate: 'Activate',
  other: 'Other',
};

export const textHasPlaceholder = (text: ?string): boolean =>
  Boolean(text && text.startsWith('placeholder:'));

export const isPlaceholderVariable = (variable: string): boolean =>
  PLACEHOLDER_VARIABLE_REGEX.test(variable);

export const cleanPlaceholder = (placeholder: ?string): string =>
  placeholder ? placeholder.replace('<placeholder:', '').replace('>', '') : '';
