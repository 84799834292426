// @flow strict

//$FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';
import {createSelector} from 'reselect';
import {CARD_TYPES} from 'src/components/messaging/broadcast-suppression/constants.js';
import type {BroadcastSuppressionPhoneDetails} from 'src/components/messaging/broadcast-suppression/types';
import type {
  GenericOptionsType,
  fileCsvUploadEntries,
  csvMapping,
  ContactListDetails,
  FileDetails,
  HVBroadcastSuppressionTimeFrame,
  HVBData,
  RenameModalPopupDetails,
  ScheduledOrNewHVBroadcast,
} from 'src/types/hv-broadcasts.js';
import keyBy from 'lodash/keyBy';
import {formatPhone} from 'src/utils/phone';
//$FlowFixMe[nonstrict-import]
import {selectCurrentPhoneCountry} from 'src/selectors/phone-number-sets';


export const getHVBroadcastRestoredPhoneNumbers = (state: State): string[] => {
  return state.hvBroadcasts.hvBroadcastRestoredPhoneNumbers;
};

export const getSuppressedNumbersList = (state: State): string[] => {
  return Object.keys(state.hvBroadcasts.hvBroadcastSuppression);
};

export const getSuppressedNumbersDetails = (
  state: State,
): BroadcastSuppressionPhoneDetails[] => {
  // $FlowIssue Object.values
  return Object.values(state.hvBroadcasts.hvBroadcastSuppression);
};
export const getAllHVRecentlyMessaged = (
  state: State,
): BroadcastSuppressionPhoneDetails[] => {
  const agent = state.accounts.authedUserId
    ? state.accounts.data[state.accounts.authedUserId]
    : null;
  const broadcastConfig =
    agent?.securityRole.configurations.broadcastSuppression.type || '';
  const restoredPhoneNumbers = getHVBroadcastRestoredPhoneNumbers(state);
  const suppressedNumbers: BroadcastSuppressionPhoneDetails[] =
    getSuppressedNumbersDetails(state);
  const recentlyMessaged = suppressedNumbers.filter(
    (phone) =>
      phone.broadcastSuppression &&
      !restoredPhoneNumbers.includes(phone.phoneNumber),
  );
  return broadcastConfig === 'off' ? [] : recentlyMessaged;
};

export const getAllHVLandlineNumbers = (
  state: State,
): BroadcastSuppressionPhoneDetails[] => {
  const restoredPhoneNumbers = getHVBroadcastRestoredPhoneNumbers(state);
  const suppressedNumbers: BroadcastSuppressionPhoneDetails[] =
    getSuppressedNumbersDetails(state);
  const landlineNumbers = suppressedNumbers.filter(
    (phone) =>
      !phone.broadcastSuppression &&
      !restoredPhoneNumbers.includes(phone.phoneNumber) &&
      phone.type === 'landline',
  );
  return landlineNumbers;
};

export const getAllHVInvalidNumbers = (
  state: State,
): BroadcastSuppressionPhoneDetails[] => {
  const suppressedNumbers: BroadcastSuppressionPhoneDetails[] =
    getSuppressedNumbersDetails(state);
  const invalidNumbers = suppressedNumbers.filter(
    (phone) => !phone.broadcastSuppression && phone.type === 'invalid',
  );
  return invalidNumbers;
};

export const getTypesOfHVBroadcastWarnings: (state: State) => string[] =
  createSelector(
    getAllHVRecentlyMessaged,
    getAllHVLandlineNumbers,
    getAllHVInvalidNumbers,
    (recentlyMessaged, landlineNumbers, invalidNumbers): string[] => {
      const allWarnings = [];
      if (recentlyMessaged.length > 0) {
        allWarnings.push(CARD_TYPES.RECENTLY_MESSAGED);
      }
      if (landlineNumbers.length > 0) {
        allWarnings.push(CARD_TYPES.LANDLINE);
      }
      if (invalidNumbers.length > 0) {
        allWarnings.push(CARD_TYPES.INVALID);
      }
      return allWarnings;
    },
  );

export const getHVBroadcastSuppressionCount: (state: State) => number =
  createSelector(
    getAllHVRecentlyMessaged,
    getAllHVLandlineNumbers,
    getAllHVInvalidNumbers,
    (recentlyMessaged, landlineNumbers, invalidNumbers): number => {
      return (
        recentlyMessaged.length + landlineNumbers.length + invalidNumbers.length
      );
    },
  );

export const getIgnoredPhoneNumbersList: (state: State) => string[] =
  createSelector(
    getHVBroadcastRestoredPhoneNumbers,
    getSuppressedNumbersList,
    (restoredPhoneNumbers, suppressedNumbers): string[] => {
      return suppressedNumbers.filter(
        (suppressedNumber) => !restoredPhoneNumbers.includes(suppressedNumber),
      );
    },
  );
export const selectFileCsvUploadHeaders = (state: State): string[] =>
  state.hvBroadcasts.fileCsvUploadHeaders;

export const selectFileCsvUploadEntries = (
  state: State,
): fileCsvUploadEntries[] => state.hvBroadcasts.fileCsvUploadEntries;

export const selectCsvMappingForFileUpload = (state: State): csvMapping => {
  const mappedEntries = state.hvBroadcasts.fileCsvUploadEntries;
  const mapping = {};
  const result = mappedEntries.forEach((entry) => {
    const {mappedTo, id} = entry;
    if (mappedTo && id) {
      mapping[mappedTo] = id;
    }
  });
  return mapping;
};

export const selectFileCsvUpload = (state: State): File | null =>
  state.hvBroadcasts.fileCsvUpload;

export const selectInboxesAsOptions = (state: State): GenericOptionsType[] => {
  const countryCode = selectCurrentPhoneCountry(state);
  const {phoneNumberSets} = state.hvBroadcasts.filters;
  // remove this condition when whatsapp is supported in HVB
  const smsInboxes = phoneNumberSets.filter(
    (phoneNumberSet) => phoneNumberSet.sms?.id,
  );
  const inboxValuesToOptions = smsInboxes.map((smsInbox) => {
    const formattedPhone = formatPhone(smsInbox?.number || '', countryCode);
    const label = smsInbox.name || formattedPhone;
    return {key: smsInbox.sms.id, label};
  });
  // add all inboxes
  return [{key: 'All inbox', label: 'All inbox'}, ...inboxValuesToOptions];
};

export const selectContactListsAsOptions = (
  state: State,
): GenericOptionsType[] => {
  return state.hvBroadcasts.contactLists.map((list) => {
    return {
      key: list.id,
      label: list.title,
      count: list.contactCounts,
    };
  });
};

export const selectAgentsRecentContactListAsOptions = (
  state: State,
): GenericOptionsType[] => {
  return state.hvBroadcasts.agentsRecentContactLists.map((list) => {
    return {
      key: list.id,
      label: list.title,
      count: list.contactCounts,
    };
  });
};

export const selectContactLists = (state: State): ContactListDetails[] => {
  return state.hvBroadcasts.contactLists.map((list) => {
    return {
      id: list.id,
      name: list.title,
      count: list.contactCounts,
    };
  });
};

export const selectUploadedFiles = (state: State): FileDetails[] => {
  return state.hvBroadcasts.uploadedFileDetails;
};

export const selectBroadcastSuppressionTimeFrame = (
  state: State,
): HVBroadcastSuppressionTimeFrame => {
  return state.hvBroadcasts.broadcastSuppressionTimeFrame;
};

export const selectIsContactListLimitReached = (state: State): boolean => {
  return state.hvBroadcasts.contactListLimitReached;
};

export const selectCreatedContactListId = (state: State): boolean => {
  return state.hvBroadcasts.listCreatedId;
};

export const selectAllBroadcasts = (state: State): HVBData[] => {
  //$FlowFixMe object.values
  return Object.values(state.hvBroadcasts.hvBroadcasts.broadcasts);
};

export const selectHVBroadcastsCurrentPage = (state: State): number => {
  return state.hvBroadcasts.hvBroadcasts.page;
};

export const selectHVBroadcastsTotalPages = (state: State): number => {
  return state.hvBroadcasts.hvBroadcasts.totalPages;
};

export const selectScheduledBroadcastData = (state: State): HVBData => {
  return state.hvBroadcasts.currentBroadcastData;
};

export const selectPhoneNumberSetId = (state: State): string => {
  return state.hvBroadcasts.hvBroadcastMessage.phoneNumberSetId;
};

export const selectInboxPhoneFromInboxId = (
  state: State,
  id: string,
): string => {
  const {phoneNumberSets} = state.hvBroadcasts.filters;
  // remove this condition when whatsapp is supported in HVB
  const selectedInbox = phoneNumberSets.find(
    (phoneNumberSet) => phoneNumberSet.sms?.id === id,
  );
  return selectedInbox.phone;
};

export const selectTemplateId = (state: State): string => {
  return state.hvBroadcasts.templateId;
};

export const selectSchedulerTemplateId = (state: State): string => {
  return state.hvBroadcasts.schedulerTemplateId;
};

export const selectShowRenameModalPopup = (
  state: State,
): RenameModalPopupDetails => {
  return state.hvBroadcasts.showRenameModalPopupDetails;
};

export const selectBroadcastType = (state: State): string => {
  return state.hvBroadcasts.selectedBroadcastType;
};

export const selectBroadcastDetails = (
  state: State,
): ScheduledOrNewHVBroadcast => {
  return state.hvBroadcasts.broadcastDetails;
};
