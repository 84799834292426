// @flow

import type {State} from 'src/reducers';
import type {ThreadList, StateThreadList} from 'src/types/thread-lists';
import type {PopulatedThread, Thread} from 'src/types/messages';

import {createSelector} from 'reselect';
import invariant from 'invariant';

import {emptyArray} from 'src/utils';

import {selectPopulatedThreadsByIds} from 'src/selectors/messages-v2';
import {selectThreadForContact} from 'src/selectors/threads';
import {selectPhoneNumberSetFromDefaultPhoneNumberSet} from 'src/selectors/chat';


export const selectAllThreadLists = (state: State): {[string]: ThreadList} =>
  state.threadLists.map;

export const selectAllThreadDetailsForBroadcastChip = (
  state: State,
): Thread[] => state.threadLists.threads;

export const selectThreadList = (
  state: State,
  threadListId: string,
): ?ThreadList => selectAllThreadLists(state)[threadListId];

export const selectThreadListIndex = (
  state: State,
  threadListId: string,
): number => state.threadLists.list.indexOf(threadListId);

export const selectSortedThreadLists: (State) => StateThreadList[] =
  createSelector(
    (state: State) => state.threadLists.list,
    selectPhoneNumberSetFromDefaultPhoneNumberSet,
    selectAllThreadLists,
    (list, phoneNumberSetIdVal, map) =>
      list
        .map((id) => map[id])
        .filter(
          ({phoneNumberSetId}) => phoneNumberSetId === phoneNumberSetIdVal,
        ),
  );

export const selectThreadListTable = (
  state: State,
  threadListId: string,
): PopulatedThread[] => {
  const threadList = selectThreadList(state, threadListId);
  // $FlowIssue it's okay if threadIds is null
  return selectPopulatedThreadsByIds(state, threadList.threadIds || emptyArray);
};

export const selectThreadListsForContact = (
  state: State,
  contactId: string,
): StateThreadList[] => {
  const thread = selectThreadForContact(state, contactId);

  invariant(
    thread,
    'Attempted to select thread lists for contact without a thread.',
  );

  return (selectSortedThreadLists(state).filter(
    (threadList) =>
      threadList.threadIds && threadList.threadIds.includes(thread.id),
  ): StateThreadList[]);
};

export const selectPopulatedThreadList = (
  state: State,
  threadListId: string,
) => {};
