// @flow strict-local

import type {SenseAction} from 'src/action-creators/types';
import {BATCH_ACTIONS, type BatchAction} from 'src/action-creators/batch';


export type BatchReducer<S, A> = (S, A | BatchAction) => S;

export default function enableBatching<S, A: SenseAction>(
  reducer: (S, A) => S,
): BatchReducer<S, A> {
  return function batchingReducer(state, action) {
    if (action.type === BATCH_ACTIONS) {
      return action.payload.reduce(batchingReducer, state);
    }
    // $FlowFixMe
    return reducer(state, action);
  };
}
