// @noflow
import * as React from 'react';

import css from './markdown-editor.css';

type ModalProps = {
  handleClose: () => void,
  children: React.Node,
};

export default class Modal extends React.Component<ModalProps> {
  clickedIn = false;

  handleBgClick = () => {
    if (this.clickedIn) {
      this.clickedIn = false;
      return;
    }

    this.props.handleClose();
  };

  handleKeyDown = (evt: SyntheticKeyboardEvent<>) => {
    if (evt.key === 'Escape') {
      this.props.handleClose();
    }
  }

  render() {
    return (
      <div className={css.modalBg} onClick={this.handleBgClick}>
        <div
          className={css.modalWrapper}
          onClick={() => {
            this.clickedIn = true;
          }}
          onKeyDown={this.handleKeyDown}
          // NOTE(elliot): Added tabIndex to activate keyboard events.
          tabIndex="-1"
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
