// @noflow
import type {DynamicLabels} from 'src/components/lib/markdown-editor';

import * as React from 'react';
import {
  Editor,
  getVisibleSelectionRect,
  EditorState,
  ContentState,
  Modifier,
  CompositeDecorator,
  SelectionState,
} from 'draft-js';
import {connect} from 'react-redux';

import flow from 'lodash/flow';

import {classify} from 'src/utils';
import {
  ENITITY_DYNAMIC_LABEL,
  getEntityRange,
} from 'src/components/lib/markdown-editor/utils.js';

import {getNewEditorStateAfterReplacingPlaceholder} from 'src/components/workflow/event/content/module/editors/utils';

import {pushModal} from 'src/action-creators/modal';
import {selectWorkflowEntityMapping} from 'src/selectors/dynamic-labels';

import {DynamicLabelPicker} from 'src/components/lib/markdown-editor/dynamic-label-modal.jsx';
import AddButton from 'src/components/lib/add-button';
import {buildDecorator} from 'src/components/lib/markdown-editor';
import {
  getMeEditorStateWithDynamicLabelsAsEntities,
  addDynamicLabel as addMeDynamicLabel,
  normalizeDynamicLabelsInString,
} from 'src/components/lib/markdown-editor/dynamic-text.jsx';

import {getEditorStateWithPlaceholders} from 'src/components/lib/markdown-editor/draft-placeholder.js';

import css from './draft-input.css';
import 'node_modules/draft-js/dist/Draft.css';
import 'src/components/lib/markdown-editor/draft.global.css';


const addDynamicLabel = (editorState, dynamicLabel) => {
  const label = `<${dynamicLabel.value}>`;
  const contentStateWithEntity = editorState
    .getCurrentContent()
    .createEntity(ENITITY_DYNAMIC_LABEL, 'IMMUTABLE', {dynamicLabel: label});
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const currentSelectionState = editorState.getSelection();

  const contentStateWithDynamicLabel = Modifier.insertText(
    contentStateWithEntity,
    currentSelectionState,
    label,
    null,
    entityKey,
  );

  const newEditorState = EditorState.push(
    editorState,
    contentStateWithDynamicLabel,
    'insert-characters',
  );
  return EditorState.forceSelection(
    newEditorState,
    contentStateWithDynamicLabel.getSelectionAfter(),
  );
};

const forceSelection = (editorState: EditorState) =>
  EditorState.forceSelection(editorState, editorState.getSelection());

type Props = {
  className?: string,
  disabled?: boolean,
  defaultValue?: string,
  dynamicLabels: DynamicLabels,
  multiEntityEnabled: boolean,
  placeholder?: string,
  onChange: (value?: mixed) => mixed,
  value: string,
  showAddButton?: boolean,
  dispatch: Dispatch,
  mainEntityType?: string,
  error?: string,
  hasError: boolean,
};

type State = {
  editorState: EditorState,
  labelPickerVisible: boolean,
  labelPickerPositionLeft?: number,
  labelPickerPositionTop?: number,
  mounted?: boolean,
};

class DraftInput extends React.Component<Props, State> {
  static defaultProps = {
    defaultValue: '',
    disabled: false,
    dynamicLabels: [],
    value: '',
  };

  handleDraftChange: (editorState: EditorState) => void;
  editor: ?Editor;
  newEditorState: ?EditorState;
  handleAddDynamicLabel: (
    dynamicLabel: DynamicLabel,
    e: Event<*>,
    editorState: EditorState,
  ) => void;
  handleAngle: (chars: string) => string;
  handleOpenMePickerModal: () => void;
  handleLabelPickerOpen: () => void;
  inputBox: ?HTMLElement;
  labelPicker: ?DynamicLabelPicker;
  container: ?HTMLElement;
  decorator: CompositeDecorator;

  constructor(props: Props) {
    super(props);

    const editorState = EditorState.createEmpty();

    this.state = {
      editorState,
      labelPickerVisible: false,
    };
  }

  componentDidMount() {
    this.setState({mounted: true});
    this.setDefaultEditorState();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevProps.defaultValue !== this.props.defaultValue ||
      prevProps.dynamicLabels !== this.props.dynamicLabels
    ) {
      this.setDefaultEditorState();
    }

    const offset = this.state.editorState.getSelection().getStartOffset();
    const xScrollWidth = this.inputBox.scrollWidth;
    const inputWidth = this.inputBox.getBoundingClientRect().width;
    const oldValueLength = prevState.editorState
      .getCurrentContent()
      .getPlainText('').length;
    const newValueLength = this.state.editorState
      .getCurrentContent()
      .getPlainText('').length;
    const selectionPosition = (offset / newValueLength) * xScrollWidth;
    // NOTE(elliot): End of scroll selection is not within 20px of current selection.
    const selectionNotInView =
      this.inputBox.scrollLeft + inputWidth - 20 < selectionPosition;
    if (oldValueLength < newValueLength && selectionNotInView) {
      // Change scroll left to 100 px left of the end of the cursor.
      this.inputBox.scrollLeft = selectionPosition - 100;
    }
  }

  setDefaultEditorState() {
    const {multiEntityEnabled, dynamicLabels, defaultValue, value} = this.props;

    this.decorator = buildDecorator({
      dynamicLabels,
      multiEntityEnabled,
      onPlaceholderReplace: this.handleOpenMePickerModal.bind(this, {
        onInsert: this.handlePlaceholderAddDynamicLabel,
      }),
    });

    const contentState = ContentState.createFromText(value ?? defaultValue);

    let editorState = EditorState.createWithContent(
      contentState,
      this.decorator,
    );

    (editorState = flow([
      getMeEditorStateWithDynamicLabelsAsEntities,
      getEditorStateWithPlaceholders,
    ])(editorState, dynamicLabels)),
      // Fixes issue where onSelect is called before Editor is rendered.
      // this.handleDraftChange(editorState);
      this.setState({editorState});
  }

  handleDraftChange = (newEditorState: EditorState, callback?: () => void) => {
    const editorState = flow([
      getMeEditorStateWithDynamicLabelsAsEntities,
      getEditorStateWithPlaceholders,
    ])(this.newEditorState || newEditorState, this.props.dynamicLabels);

    this.setState(
      {
        editorState,
      },
      () => {
        let text = editorState.getCurrentContent().getPlainText('');

        if (this.props.multiEntityEnabled) {
          text = normalizeDynamicLabelsInString(this.props.dynamicLabels, text);
        }

        this.props.onChange(text);
        if (callback) {
          callback();
        }
      },
    );
  };

  handleAddDynamicLabel = (
    dynamicLabel: DynamicLabel,
    e: Event<*>,
    editorState: EditorState = this.state.editorState,
  ) => {
    e.preventDefault();

    const newEditorState = this.props.multiEntityEnabled
      ? addMeDynamicLabel(editorState, editorState.getSelection(), dynamicLabel)
      : addDynamicLabel(editorState, dynamicLabel);
    // NOTE(elliot): Workaround for race conditions for editor state when clicking on an item.
    this.newEditorState = newEditorState;
    this.handleDraftChange(newEditorState, () => {
      this.newEditorState = null;
      this.setState({labelPickerVisible: false});
    });
  };

  /**
   * Will replace the placeholder with selected variable
   */
  handlePlaceholderAddDynamicLabel = (
    dynamicLabel: DynamicLabel,
    e: Event<*>,
    editorState: EditorState = this.state.editorState,
  ) => {
    e.preventDefault();

    getNewEditorStateAfterReplacingPlaceholder(
      dynamicLabel,
      editorState,
      (newEditorState) => {
        this.newEditorState = newEditorState;
        this.handleDraftChange(newEditorState, () => {
          this.newEditorState = null;
          this.setState({labelPickerVisible: false});
        });
      },
    );
  };

  handleAngle = (chars: string) => {
    if (chars === '<') {
      if (this.props.multiEntityEnabled) {
        this.handleOpenMePickerModal();
      } else {
        this.handleLabelPickerOpen();
      }
      return 'handled';
    }
  };

  handleReturn = () => 'handled';

  handleOpenMePickerModal = (params) => {
    this.props.dispatch(
      pushModal({
        type: 'VARIABLE_PICKER',
        labels: this.props.dynamicLabels,
        insertResult: params?.onInsert ?? this.handleAddDynamicLabel,
        baseEntityType: this.props.mainEntityType,
      }),
    );
  };

  handleLabelPickerOpen = () => {
    const containerRect = this.container.getBoundingClientRect();
    const cursorRect = getVisibleSelectionRect(window);
    // NOTE(elliot): getVisibleSelectionRect gives back null when there's no text in the box.
    const labelPickerPositionLeft = cursorRect
      ? cursorRect.left - containerRect.left
      : 0;
    this.setState(
      {
        labelPickerVisible: true,
        labelPickerPositionLeft,
        labelPickerPositionTop: 0,
      },
      () => {
        this.labelPicker && this.labelPicker.focus();
      },
    );
  };

  handleLabelPickerClose = (e) => {
    e.preventDefault();
    const editorState = forceSelection(
      this.newEditorState || this.state.editorState,
    );
    this.handleDraftChange(editorState, () => {
      this.setState({labelPickerVisible: false});
    });
  };

  focusEditor = (e: SyntheticEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (this.editor) {
      this.editor.focus();
    }
  };

  handleButtonKeyDown = (evt: SyntheticKeyboardEvent<HTMLDivElement>) => {
    if ([' ', 'Enter'].includes(evt.key)) {
      evt.preventDefault();
      this.handleOpenMePickerModal();
    }
  };

  render() {
    const editorState = this.newEditorState || this.state.editorState;
    if (!this.state.mounted) {
      return null;
    }
    return (
      // this wrapper ensures that the draft input stretches horizontally even
      // if it's in flex-flow: column context. The innerContainer ensures that
      // the draftInput clips and overflows its content correctly.
      <div
        className={classify(css.container, this.props.className)}
        ref={(container) => (this.container = container)}
      >
        <div className={classify(css.innerContainer)}>
          {this.state.labelPickerVisible && (
            <div
              className={css.dynamicLabelPicker}
              style={{
                left: this.state.labelPickerPositionLeft,
                top: this.state.labelPickerPositionTop,
              }}
            >
              <DynamicLabelPicker
                handleClose={this.handleLabelPickerClose}
                mode="compact"
                dynamicLabels={this.props.dynamicLabels}
                insertDynamicLabel={this.handleAddDynamicLabel}
                ref={(labelPicker) => (this.labelPicker = labelPicker)}
              />
            </div>
          )}
          <div
            className={classify(css.input, {
              [css.thinError]: this.props.hasError,
            })}
            onClick={this.focusEditor}
          >
            <div className={css.inputScroll} ref={(el) => (this.inputBox = el)}>
              <Editor
                editorState={editorState}
                onChange={this.handleDraftChange}
                ref={(editor) => (this.editor = editor)}
                placeholder={this.props.placeholder}
                readOnly={this.props.disabled}
                handleBeforeInput={this.handleAngle}
                handleReturn={this.handleReturn}
                tabIndex={0}
              />
            </div>
            {this.props.showAddButton && this.props.multiEntityEnabled && (
              <div
                role="button"
                className={css.inlineMeButton}
                onClick={this.handleOpenMePickerModal}
                onKeyDown={this.handleButtonKeyDown}
                tabIndex={0}
              >
                &lt; <span className={css.inlineX}>x</span> &gt;
              </div>
            )}
          </div>
          {this.props.showAddButton && !this.props.multiEntityEnabled && (
            <AddButton
              className={css.addButton}
              onClick={this.handleLabelPickerOpen}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({
  multiEntityEnabled: state.agency.config.multiEntityEnabled,
  mainEntityType: selectWorkflowEntityMapping(state).name,
});

export default connect(mapStateToProps)(DraftInput);
