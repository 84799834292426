// @flow
import type {ModalComponent} from 'src/types/modal';
import type {ModalType} from 'src/components/modals/modal-root.jsx';

import * as React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {selectCurrentWorkflow} from 'src/selectors/workflow';

import {classify} from 'src/utils/index';
import * as api from 'src/utils/api-no-store';

import {popModal} from 'src/action-creators/modal';

import HoverIconButton from 'src/components/lib/hover-icon-button';
import BaseModal from 'src/components/modals/base-modal.jsx';
import RadioButtons from 'src/components/lib/radio-buttons/radio-buttons.jsx';

import CancelIcon from 'src/images/icons/cancel-icon.svg?noAttrs';

import css from './base-modal.css';


const options = (newAudienceCount) => [
  {value: false, label: 'Only include the remaining audience members'},
  {
    value: true,
    label: `Include the remaining and the ${newAudienceCount} new audience members`,
  },
];

const processNewAudienceMembers = (workflowId: string) =>
  api.post(`bulk-writeback/new-audience`, undefined, {workflow_id: workflowId});

export default function BulkWritebackActivationModal({
  newAudienceCount,
  onActivateWorkflow,
  onActivationError,
  removeModal,
  className,
  type,
  ...props
}: {
  newAudienceCount: number,
  onActivateWorkflow: () => {catch: (mixed) => void},
  onActivationError: () => mixed,
  removeModal: () => mixed,
  className: string,
  type: ModalType | ModalComponent,
}): React.Element<'div'> {
  const workflow = useSelector(selectCurrentWorkflow);
  const dispatch = useDispatch();

  const baseModalClassName = className
    ? classify(css.titleBarModal, className)
    : css.titleBarModal;

  const activationOptions = options(newAudienceCount);

  const [includeNewAudience, setIncludeNewAudience] = React.useState(true);

  const handleConfirmActivation = () => {
    if (includeNewAudience) {
      processNewAudienceMembers(workflow.id)
        .then(onActivateWorkflow)
        .catch(onActivationError);
    } else {
      onActivateWorkflow().catch(onActivationError);
    }
  };

  return (
    <div className={css.titleBarContainer}>
      (
      <div className={css.titleBar} style={{backgroundColor: css.modalOrange}}>
        <div className={css.titleAndIcon}>
          <div className={css.title}>Include newly added records?</div>
        </div>
        <HoverIconButton className={css.closeXHoverButton}>
          <CancelIcon
            className={css.closeXIcon}
            onClick={() => {
              dispatch(popModal());
            }}
          />
        </HoverIconButton>
      </div>
      <BaseModal
        {...props}
        type={type}
        confirmText="Start now"
        abortText="Cancel"
        removeModal={() => dispatch(popModal())}
        handleConfirm={handleConfirmActivation}
        className={baseModalClassName}
      >
        <div className={css.contentContainer}>
          <p>
            {`Since you last started this database cleanup, some records are remaining that haven’t been udpated. Also, ${newAudienceCount} new records may have been added to your database that match these audience rules.`}
          </p>
          <RadioButtons
            name="choices"
            selectedValue={includeNewAudience}
            onChange={(choice) => {
              setIncludeNewAudience(choice);
            }}
            options={activationOptions}
            bodyClassName={css.optionGroup}
          />

          <p>
            Start now will immediately begin to updated records in your
            database. You can stop the cleanup at any time to prevent further
            changes.
          </p>
        </div>
      </BaseModal>
    </div>
  );
}
