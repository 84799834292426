// @flow strict
import * as React from 'react';

import type {ToastProps} from '@spaced-out/ui-design-system/lib/components/Toast/Toast';

import {
  ButtonTab,
  ButtonTabs,
} from '@spaced-out/ui-design-system/lib/components/ButtonTabs';
import ReferralSettings from 'src/components/referral-v2/settings/index.jsx';
import ReferralPaymentSettings from 'src/components/referral-v2/settings/payments-settings.jsx';
// $FlowFixMe[nonstrict-import]
import {useReleaseFlag} from 'src/hooks/product-flags';
import {
  Toast,
  ToastBody,
  ToastTitle,
} from '@spaced-out/ui-design-system/lib/components';
import {displayTime} from 'src/utils/date-time-2';
import css from 'src/components/referral-v2/configure/configure.css';
import settingsCss from 'src/components/referral-v2/settings/settings.css';


const ReferralSettingsPage = (): React.Node => {
  const [selectedTab, setSelectedTab] = React.useState('general');
  const isReferralPaymentsEnabled = useReleaseFlag('referralsPaypalPayment');

  return (
    <div>
      <div className={css.content}>
        {!isReferralPaymentsEnabled ? (
          <ReferralSettings />
        ) : (
          <>
            <ButtonTabs
              onButtonTabSelect={(e) => setSelectedTab(e)}
              classNames={{wrapper: settingsCss.settingsButtonTab}}
              selectedButtonTabId={selectedTab}
            >
              <ButtonTab id="general">General</ButtonTab>
              <ButtonTab id="payments">Payments</ButtonTab>
            </ButtonTabs>
            <div>
              {selectedTab === 'general' ? (
                <ReferralSettings />
              ) : (
                <ReferralPaymentSettings />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default ReferralSettingsPage;

export const thresholdUpdateMessage = (): React$Element<
  (props: ToastProps) => React.Node,
> => {
  const currentDate = new Date();
  return (
    <Toast
      initialFocus={0}
      semantic="success"
      time={`Today ${displayTime(currentDate)}`}
    >
      <ToastTitle semantic="primary">Balance threshold updated</ToastTitle>
      <ToastBody>Your minimum balance threshold successfully updated</ToastBody>
    </Toast>
  );
};
