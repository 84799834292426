// @flow strict
import type {NotificationUsersActions} from 'src/action-creators/referral-v2/referral-notification-user.js';
import type {GenericError, NotificationUsers} from 'src/types/referral-v2';

import {
  NOTIFICATION_USERS_LOADING,
  NOTIFICATION_USERS,
  NOTIFICATION_USERS_ERROR,
  NOTIFICATION_CONFIG_USERS_LOADING,
  NOTIFICATION_CONFIG_USERS,
  NOTIFICATION_CONFIG_USERS_ERROR,
} from 'src/action-creators/referral-v2/referral-notification-user.js';

export const ReferralCurrentState = {
  IDLE: 'idle',
  FETCHING_NOTIFICATION_USER: 'fetchingNotificationUsers',
  FETCHING_NOTIFICATION_CONFIG_USER: 'fetchingNotificationConfigUsers',
};

export type ReferralNotificationUsersState = {
  current_state: string,
  notification_config_users: ?(NotificationUsers[]),
  notification_config_users_error: ?GenericError,
  notification_users: ?(NotificationUsers[]),
  notification_users_error: ?GenericError,
};

const initialState = {
  notification_users: null,
  notification_users_error: null,
  current_state: '',
  notification_config_users: null,
  notification_config_users_error: null,
};

export default (
  state: ReferralNotificationUsersState = initialState,
  action: NotificationUsersActions,
): ReferralNotificationUsersState => {
  switch (action.type) {
    case NOTIFICATION_USERS_LOADING:
      return {
        ...state,
        current_state: ReferralCurrentState.FETCHING_NOTIFICATION_USER,
      };
    case NOTIFICATION_USERS:
      return {
        ...state,
        notification_users_error: null,
        notification_users: action.payload,
      };
    case NOTIFICATION_USERS_ERROR:
      return {
        ...state,
        notification_users_error: action.payload,
      };

    case NOTIFICATION_CONFIG_USERS: {
      return {
        ...state,
        notification_config_users: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };
    }
    case NOTIFICATION_CONFIG_USERS_LOADING:
      return {
        ...state,
        current_state: ReferralCurrentState.FETCHING_NOTIFICATION_CONFIG_USER,
      };

    case NOTIFICATION_CONFIG_USERS_ERROR:
      return {
        ...state,
        notification_config_users_error: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };

    default:
      return state;
  }
};
