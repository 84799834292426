// @flow strict

import type {
  FilterApi,
  FilterRule,
  NormalizedFilters,
  FilterBlock,
  PreviousRuleCondition,
  FilterRuleCondition,
  FilterScope,
  OperatorOptions,
  PreviousRule,
  EMSRule,
} from 'src/types/filter';
import type {
  SimpleABTVariable,
  DynamicTaskObjectModules,
  DynamicTaskObjectDetails,
} from 'src/components/lib/automation-module-registry/automation-module-registry';

import uniqueId from 'lodash/uniqueId';
// $FlowFixMe[untyped-import]
import {hashObject} from 'src/utils/index';
import has from 'lodash/has';
import type {FilterState} from 'src/reducers/filter';
import type {MenuOption} from '@spaced-out/ui-design-system/lib/components/Menu/Menu';
import {
  ENTITY_TYPE_EVENT_MAP,
  EVENT_GROUP_PROPERTY_MAP,
} from 'src/components/lib/automation-module-registry/utils';

import type {PreviousNodeValue} from 'src/components/lib/automation-variable-picker/panel-variable-picker';
import type {Swimlane} from 'src/types/automation-workflow';


export const MAX_FILTER_TRANSITIONS = 7;
export const MAX_FILTER_BLOCKS = 1;
export const SINGLE_VALUE_OPERATORS = ['eq', 'neq', 'gt', 'lt'];

export const ALLOWS_EMPTY_STRING_OPERATORS = ['in'];
export const ALLOWS_EMPTY_DATE_OPERATORS = ['eq', 'gt', 'lt', 'gte', 'lte'];

export const DATE_FILTER_OPTIONS = [
  {
    key: 'today',
    label: 'Today',
  },
  {
    key: 'specific_date',
    label: 'Specific Date',
  },
  //todo(vish): not required for now.
  // {
  //   key: 'relative_date',
  //   label: 'Relative to',
  // },
];

export const RELATIVE_DAYS_MINUS_OR_PLUS = [
  {
    key: 'minus',
    label: 'Minus (-)',
  },
  {
    key: 'plus',
    label: 'Plus (+)',
  },
];

export const VALUE_NOT_REQUIRED_OPERATORS = [
  'is_null',
  'is_not_null',
  'empty',
  'is_empty_or_null',
  'is_not_empty_and_not_null',
];

export const ARRAY_OPERATORS = [
  'in',
  'not_in',
  'contains',
  'ncontains',
  'starts_with',
  'nstarts_with',
  'ends_with',
];

export const DEFAULT_OPERATOR_OPTION = {
  string: 'eq',
  number: 'gt',
  date: 'gt',
  boolean: true,
  email: 'eq',
  phone: 'eq',
};

export const OPERATOR_OPTIONS: OperatorOptions = {
  string: [
    {
      value: 'eq',
      label: 'is',
      tooltip: 'status = "placed"',
    },
    {
      value: 'neq',
      label: 'is not',
      tooltip: 'status ≠ "placed"',
    },
    {
      value: 'in',
      label: 'is one of',
      tooltip: `company name = "Dell" OR company name = "Microsoft"`,
    },
    {
      value: 'not_in',
      label: 'is not one of',
      tooltip: `company name ≠ "Dell" AND company name ≠ "Microsoft"`,
    },
    {
      value: 'is_empty_or_null',
      label: 'is empty/null',
      tooltip: 'status = NULL or status is empty',
    },
    {
      value: 'is_not_empty_and_not_null',
      label: 'is not empty/ not null',
      tooltip: 'status ≠ NULL or status is not empty',
    },
  ],
  number: [
    {
      value: 'gt',
      label: 'greater than',
      tooltip: 'value > 5',
    },
    {
      value: 'lt',
      label: 'less than',
      tooltip: 'value < 5',
    },
    {
      value: 'eq',
      label: 'equals',
      tooltip: 'value = 5',
    },
    {
      value: 'neq',
      label: 'not equal to',
      tooltip: 'value ≠ 5',
    },
  ],
  currency: [
    {
      value: 'gt',
      label: 'greater than',
      tooltip: 'value > 5',
    },
    {
      value: 'lt',
      label: 'less than',
      tooltip: 'value < 5',
    },
    {
      value: 'eq',
      label: 'equals',
      tooltip: 'value = 5',
    },
    {
      value: 'neq',
      label: 'not equal to',
      tooltip: 'value ≠ 5',
    },
  ],
  date: [
    {
      value: 'gt',
      label: 'is after',
      tooltip: 'date_added > 2023-06-01 ',
    },
    {
      value: 'lt',
      label: 'is before',
      tooltip: 'date_added < 2023-06-09',
    },
    {
      value: 'eq',
      label: 'is equal to',
      tooltip: 'date_added = 2023-06-09 ',
    },
    {
      value: 'gte',
      label: 'is on or after',
      tooltip: 'date_added >= 2023-06-01',
    },
    {
      value: 'lte',
      label: 'is on or before',
      tooltip: 'date_added is <= 2023-06-09',
    },
    {
      value: 'is_empty_or_null',
      label: 'is empty/null',
      tooltip: 'status = NULL or status is empty',
    },
    {
      value: 'is_not_empty_and_not_null',
      label: 'is not empty/ not null',
      tooltip: 'status ≠ NULL or status is not empty',
    },
  ],

  boolean: [
    {
      value: 'true',
      label: 'is true',
      tooltip: 'candidate/active is true',
    },
    {
      value: 'false',
      label: 'is false',
      tooltip: 'candidate/active is false',
    },
  ],
  email: [
    {
      value: 'eq',
      label: 'is',
      tooltip: 'status = "placed"',
    },
    {
      value: 'neq',
      label: 'is not',
      tooltip: 'status ≠ "placed"',
    },
    {
      value: 'in',
      label: 'is one of',
      tooltip: `company name = "Dell" OR company name = "Microsoft"`,
    },
    {
      value: 'not_in',
      label: 'is not one of',
      tooltip: `company name ≠ "Dell" AND company name ≠ "Microsoft"`,
    },
    {
      value: 'is_empty_or_null',
      label: 'is empty/null',
      tooltip: 'status = NULL or status is empty',
    },
    {
      value: 'is_not_empty_and_not_null',
      label: 'is not empty/not null',
      tooltip: 'status ≠ NULL or status is not empty',
    },
  ],
  phone: [
    {
      value: 'eq',
      label: 'is',
      tooltip: 'number = +12123231324',
    },
    {
      value: 'neq',
      label: 'is not',
      tooltip: 'number ≠ +12123231324',
    },
    {
      value: 'in',
      label: 'is one of',
      tooltip: 'number = "123" or number = "456"',
    },
    {
      value: 'not_in',
      label: 'is not one of',
      tooltip: 'number ≠ "123" or number ≠ "456"',
    },
    {
      value: 'is_empty_or_null',
      label: 'is empty/null',
      tooltip: 'number = NULL or number is empty',
    },
    {
      value: 'is_not_empty_and_not_null',
      label: 'is not empty/ not null',
      tooltip: 'number ≠ NULL or number is not empty',
    },
  ],
};

export const PREVIOUS_NODE_CONDITIONS = {
  email_send: [
    {
      id: 'reference_type',
      value: 'task_definition_id',
      operator: 'eq',
      pickValueFromUI: false,
    },
    {
      id: 'workflow_id',
      value: null,
      operator: 'eq',
      pickValueFromUI: true,
    },
    {
      id: 'node_id',
      value: null,
      operator: 'eq',
      pickValueFromUI: true,
    },
  ],
  chatbot_trigger: [
    {
      id: 'reference_type',
      value: 'chatbot_flow_id',
      operator: 'eq',
      pickValueFromUI: false,
    },
    {
      id: 'workflow_id',
      value: null,
      operator: 'eq',
      pickValueFromUI: true,
    },
    {
      id: 'node_id',
      value: null,
      operator: 'eq',
      pickValueFromUI: true,
    },
  ],
  chatbot_over_sms: [
    {
      id: 'reference_type',
      value: 'chatbot_flow_id',
      operator: 'eq',
      pickValueFromUI: false,
    },
    {
      id: 'workflow_id',
      value: null,
      operator: 'eq',
      pickValueFromUI: true,
    },
    {
      id: 'node_id',
      value: null,
      operator: 'eq',
      pickValueFromUI: true,
    },
  ],
};

export const ABT_SCOPE = [
  {key: 'next', label: 'Next'},
  {key: 'indefinitely', label: 'Indefinitely'},
];

export const createFilterBlockIdentifier = (): string => uniqueId('block');
export const getDefaultFilterBlock = (): Map<string, FilterBlock> =>
  new Map([[createFilterBlockIdentifier(), {rules: [], scope: null}]]);

export const isRuleValueEmpty = (rule: FilterRule): boolean => {
  if (!rule.operator) {
    return false;
  }
  const valueRequired = !VALUE_NOT_REQUIRED_OPERATORS.includes(rule.operator);
  const isArrayOperator = ARRAY_OPERATORS.includes(rule.operator);

  if (valueRequired) {
    return rule.value == null || rule.value === 0 || rule.value === '';
  }

  if (isArrayOperator) {
    return !(Array.isArray(rule.value) && rule.value.length > 0);
  }

  return false;
};

export const createRuleFieldOperatorHash = (rule: FilterRule): string => {
  const {field, operator} = rule;
  const objectToHash = {
    field,
    operator,
    conditions: [],
  };
  if (rule.conditions && rule.conditions.length > 0) {
    objectToHash.conditions = [...rule.conditions];
  }
  const hashObj = hashObject(objectToHash);
  return hashObj;
};

export const createRuleHash = (rule: FilterRule): string => {
  const {field, operator, value} = rule;
  const objectToHash = {
    field,
    operator,
    value,
    conditions: [],
    selectedActionValue: '',
  };
  if (rule.conditions && rule.conditions.length > 0) {
    objectToHash.conditions = [...rule.conditions];
  }
  if (
    rule.actionValueIdentifier &&
    rule.actionValueIdentifier.selectedActionValue
  ) {
    objectToHash.selectedActionValue =
      rule.actionValueIdentifier.selectedActionValue;
  }
  const hashObj = hashObject(objectToHash);
  return hashObj;
};

export const createRulesHash = (rules: FilterRule[]): string => {
  const hashItem = rules.reduce((acc, rule) => {
    acc[createRuleHash(rule)] = true;
    return acc;
  }, {});
  return hashObject(hashItem);
};

export const validateFilterBlocks = (
  blocks: Map<string, FilterBlock>,
  maxBlocks: ?number,
): {
  blockHashCount: Map<string, number>,
  blockwiseRuleHashCount: Map<string, Map<string, number>>,
  duplicateBlockExists: boolean,
  duplicateRuleExistsInAnyBlock: boolean,
  invalidRuleExistsInAnyBlock: boolean,
  noRuleExists: boolean,
  doBlocksExceedMaxAllowedBlocks: boolean,
  anyScopeIsInvalid: boolean,
} => {
  const blockHashCount: Map<string, number> = [...blocks.values()].reduce(
    (acc, block) => {
      if (block.rules.length > 0) {
        const hash = createRulesHash(block.rules);
        const val = acc.get(hash);
        if (val) {
          return acc.set(hash, val + 1);
        }
        acc.set(hash, 1);
      }
      return acc;
    },
    new Map(),
  );
  //duplicate rules will have the same hash. this is a map which stores
  // no of rules having the same hash to detect duplicates
  // map (block id,map(ruleHash,count))
  const blockwiseRuleHashCount = [...blocks.entries()].reduce(
    (outerAccumulator, [blockId, block]) => {
      outerAccumulator.set(
        blockId,
        block.rules.reduce((acc, rule) => {
          const hash = createRuleHash(rule);
          const val = acc.get(hash);
          if (val) {
            return acc.set(hash, val + 1);
          }
          return acc.set(hash, 1);
        }, new Map()),
      );
      return outerAccumulator;
    },
    new Map(),
  );

  const duplicateBlockExists = [...blockHashCount.values()].some(
    (count) => count > 1,
  );
  const duplicateRuleExistsInAnyBlock = [
    ...blockwiseRuleHashCount.values(),
  ].some((ruleHashCount) =>
    [...ruleHashCount.values()].some((count) => count > 1),
  );

  const invalidRuleExistsInAnyBlock = [...blocks.values()].some((block) =>
    block.rules.some((rule) => {
      // todo(vish): we won't check validity in case of prevRule, handle this in case other abt events are added.
      if (rule.type === 'prevNode') {
        return false;
      }
      if (typeof rule.operator === 'boolean') {
        return false;
      }
      if (!rule.operator) {
        return true;
      }
      if (isRuleValueEmpty(rule)) {
        return true;
      }
      //Todo(Vish): handle zip
    }),
  );

  const noRuleExists = [...blocks.values()].every(
    (block) => block.rules.length === 0,
  );

  const doBlocksExceedMaxAllowedBlocks =
    maxBlocks != null && blocks.size > maxBlocks;

  const anyScopeIsInvalid = [...blocks.values()].some((block) => {
    if (!block.scope) {
      return false;
    }
    return (
      block.scope.direction === 'next' &&
      (block.scope.timespan == null || block.scope.timespan === '0')
    );
  });

  return {
    blockHashCount,
    blockwiseRuleHashCount,
    duplicateBlockExists,
    duplicateRuleExistsInAnyBlock,
    invalidRuleExistsInAnyBlock,
    noRuleExists,
    doBlocksExceedMaxAllowedBlocks,
    anyScopeIsInvalid,
  };
};

export const isLocalValueEmpty = (value: string | string[]): boolean => {
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  if (typeof value === 'string') {
    return !value.trim();
  }
  return !value;
};
export const isOperatorSwitchIncompatible = (
  newOperator: string | boolean,
  oldOperator: ?(string | boolean),
): boolean => {
  if (newOperator === oldOperator) {
    return false;
  }
  const nonArrayOperators = [
    ...SINGLE_VALUE_OPERATORS,
    ...VALUE_NOT_REQUIRED_OPERATORS,
  ];
  const isNewOperatorNonArrayOperator = nonArrayOperators.includes(newOperator);
  const isOldOperatorNonArrayOperator = nonArrayOperators.includes(oldOperator);

  return isNewOperatorNonArrayOperator !== isOldOperatorNonArrayOperator;
};

export const isAllowEmptySupported = (
  dataType: string,
  operator: string | boolean,
): boolean => {
  if (
    ['string', 'email', 'phone'].includes(dataType) &&
    ALLOWS_EMPTY_STRING_OPERATORS.includes(operator)
  ) {
    return true;
  } else if (
    ['date'].includes(dataType) &&
    ALLOWS_EMPTY_DATE_OPERATORS.includes(operator)
  ) {
    return true;
  }
  return false;
};

export const getRelativeDateDisplayBasedOnOffset = (offset: number): string => {
  const absoluteOffset = Math.abs(offset);
  return offset > 0
    ? `${absoluteOffset} days from now`
    : `${absoluteOffset} days ago`;
};

export const getRuleDisplayValue = (
  rule: FilterRule,
  noValueText: string,
  dataType: string,
  type?: string,
): string | string[] => {
  const value = rule.value || rule.value === 0 ? rule.value : null;
  const offset = rule.offset ? rule.offset : 0;
  if (Array.isArray(value)) {
    if (type === 'main') {
      const valueCsv = value.length ? value.join(', ') : noValueText;
      /**
       * The text is being displayed inside a button where only 50-90 characters are visible;
       * the rest are truncated. However, large strings sometimes prevent truncation.
       * Slicing the string will make the DOM lighter.
       **/
      return valueCsv.slice(0, 100);
    }
    return value.length > 0 ? value.map(String) : noValueText;
  }
  if (value || value === 0) {
    if (dataType === 'date') {
      return offset
        ? getRelativeDateDisplayBasedOnOffset(offset)
        : String(value);
    }
    return String(value);
  }
  return noValueText;
};

export const ruleValueToStringRuleValue = (
  value: ?number | number[] | string | string[] | boolean,
): string[] => {
  if (!value) {
    return [];
  }
  if (value != null) {
    if (Array.isArray(value)) {
      return value.map((v) => v.toString());
    }
    return [value.toString()];
  }
  return [];
};

export const ruleValueToNumberRuleValue = (
  value: ?number | number[] | string | string[],
): string => {
  if (!value && value !== 0) {
    return '';
  }
  if (value != null) {
    if (Array.isArray(value)) {
      return '';
    }
    return '' + value;
  }
  return '';
};

export const ruleValueToDateRuleValue = (
  value: ?(number | number[] | string | string[]),
): string => {
  if (!value) {
    return 'today';
  }
  if (value != null) {
    if (Array.isArray(value)) {
      return 'today';
    }
    return '' + value;
  }
  return 'today';
};

export const extractAttributeNameFromVariable = (str: string): string => {
  const regexPattern = /(?:[^\\.]|\\.)+(?=\}\}$)/;
  const matches = str.match(regexPattern);
  if (matches && matches[0]) {
    return matches[0].replace(/\\\./g, '.');
  }
  return '';
};

export const doesValueExistInRule = (rules: FilterRule[]): boolean => {
  return rules.some((rule) => has(rule, 'value') && rule.value != null);
};

export const ruleContextMenuActions = (
  cloneBlock?: () => mixed,
  removeBlock?: () => mixed,
  clearAllRuleValues?: () => mixed,
  rules: FilterRule[],
  totalBlocksAdded: number,
): Array<MenuOption> => {
  const options = [];
  if (
    cloneBlock &&
    typeof cloneBlock === 'function' &&
    totalBlocksAdded < MAX_FILTER_BLOCKS
  ) {
    options.push({label: 'Clone block', key: 'clone', iconLeft: 'clone'});
  }
  if (
    clearAllRuleValues &&
    typeof clearAllRuleValues === 'function' &&
    doesValueExistInRule(rules)
  ) {
    options.push({
      label: 'Clear all values',
      key: 'clear',
      iconLeft: 'eraser',
    });
  }

  if (removeBlock && typeof removeBlock === 'function') {
    options.push({
      label: 'Delete block',
      key: 'delete',
      iconLeft: 'trash-can',
    });
  }
  return options;
};

export const cloneRuleWithoutValues = (rules: FilterRule[]): FilterRule[] => {
  return rules.map((rule) => {
    //Todo(vish): handle action rule
    return {
      id: uniqueId('filterRule'),
      field: rule.field,
      operator: rule.operator,
      include_null: null,
      meta: null,
      offset: null,
      type: 'ems',
    };
  });
};

const getConditionsFromScope = (scope, scopeFields) => {
  let startValue, startOffset, endValue, endOffset;
  if (scope?.timespan == null) {
    startValue = 'today';
    startOffset = 0;
    endValue = 'plus_infinity';
    endOffset = 0;
  } else {
    startValue = 'minus_infinity';
    startOffset = 0;
    endValue = 'today';
    endOffset = parseInt(scope.timespan, 10);
  }
  return [
    {
      field: scopeFields.start_date,
      value: startValue,
      offset: startOffset,
      operator: 'gte',
    },
    {
      field: scopeFields.end_date,
      value: endValue,
      offset: endOffset,
      operator: 'lte',
    },
  ];
};

const getConditionFromActionIdentifier = (actionValueIdentifier) => {
  const {actionField, selectedActionValue} = actionValueIdentifier;
  return {
    field: actionField,
    value: selectedActionValue,
    operator: 'eq',
  };
};

export const normalizeAbtRule = (
  rule: PreviousRule,
  scope: ?FilterScope,
): Array<FilterRuleCondition> => {
  let conditions = [
    {operator: rule.operator, value: rule.value, field: rule.field},
  ];
  const restConditions = rule.conditions.reduce(
    (acc, {field, value, operator}) => {
      acc.push({field, value, operator});
      return acc;
    },
    [],
  );
  conditions = [...conditions, ...restConditions];
  const scopeConditions = getConditionsFromScope(scope, rule.scopeFields);
  const referenceCondition = getConditionFromActionIdentifier(
    rule.actionValueIdentifier,
  );
  conditions = [...conditions, ...scopeConditions, referenceCondition];
  return conditions;
};

export const normalizeEMSRule = (rule: EMSRule): ?FilterRuleCondition => {
  if (rule.operator != null) {
    let operator = rule.operator;
    let value = rule.value != null && rule.value;
    if (rule.operator && VALUE_NOT_REQUIRED_OPERATORS.includes(rule.operator)) {
      value = null;
    }
    if (rule.operator === 'in_radius') {
      operator = 'in';
    }
    if (
      typeof rule.operator !== 'undefined' &&
      [true, false].includes(rule.operator)
    ) {
      operator = 'eq';
      value = rule.operator;
    }
    if (rule.operator === 'empty') {
      operator = 'eq';
      value = '';
    }
    return {
      field: rule.field,
      value,
      operator,
      //Todo(vish): metadata might be added later
      // metadata: rule.operator === 'in_radius' ? {...rule.meta, type: 'distance', operator: 'search'} : null,
      //Todo(vish): offset might be added later
      // offset: rule.offset != null ? rule.offset : null,
      //Todo(vish): figure out what to do with this
      // include_null: rule.include_null === true ? true : false,
    };
  }
  return null;
};

export const normalizeFilter = (filter: FilterState): NormalizedFilters => {
  const blocks = ([...filter.blocks.values()] ?? []).reduce((acc, block) => {
    const conditions = block.rules.reduce((innerAcc, rule) => {
      const normalizedRule =
        rule.type === 'ems'
          ? normalizeEMSRule(rule)
          : normalizeAbtRule(rule, block.scope);
      if (normalizedRule) {
        if (rule.type === 'ems') {
          innerAcc.push({conditions: [normalizedRule]});
        } else {
          innerAcc.push({conditions: normalizedRule});
        }
      }
      return innerAcc;
    }, []);
    if (conditions.length > 0) {
      acc.push({rules: conditions});
    }
    return acc;
  }, []);
  //$FlowFixMe todo:vish
  return {conditions: {blocks}};
};

const getClientOperator = (rule) => {
  if (rule.operator === 'eq' && typeof rule.value === 'boolean') {
    return rule.value;
  }
  if (rule.operator === 'eq' && rule.value === '') {
    return 'empty';
  }
  return rule.operator;
};
export const parseEMSRule = (rule: FilterRuleCondition): EMSRule => {
  return {
    id: uniqueId('filterRule'),
    type: 'ems',
    operator: getClientOperator(rule),
    value: rule.value,
    field: rule.field,
    // offset: rule.offset,
    // meta: rule.metadata,
    // include_null: rule.include_null === true ? true : false,
  };
};

export const parseActionRule = (
  conditions: Array<FilterRuleCondition>,
): PreviousRule => {
  const primaryEvent = conditions[0].field;
  const restConditions = conditions.filter((condition, index) => {
    return (
      index !== 0 &&
      !condition.field.includes('reference_id') &&
      !condition.field.includes('start_date') &&
      !condition.field.includes('end_date')
    );
  });
  const referenceIdField =
    conditions.find(({field}) => field.includes('reference_id'))?.field ?? '';
  const referenceIdValue =
    conditions.find(({field}) => field.includes('reference_id'))?.value ?? '';
  const startField =
    conditions.find(({field}) => field.includes('start_date'))?.field ?? '';
  const endField =
    conditions.find(({field}) => field.includes('end_date'))?.field ?? '';
  const operator =
    typeof conditions[0].operator === 'string' ? conditions[0].operator : '';
  const value =
    typeof conditions[0].value === 'boolean' ? conditions[0].value : false;
  return {
    id: uniqueId('filterRule'),
    type: 'prevNode',
    field: primaryEvent,
    operator,
    value,
    //$FlowFixMe todo(vish)
    conditions: restConditions,
    scopeFields: {start_date: startField, end_date: endField},
    actionValueIdentifier: {
      actionField: referenceIdField,
      selectedActionValue:
        typeof referenceIdValue === 'string' ? referenceIdValue : '',
    },
  };
};

export const getScopeFromActionRule = (
  conditions: Array<FilterRuleCondition>,
): FilterScope => {
  const scope: FilterScope = {direction: 'indefinitely', timespan: null};
  const startDate = conditions.find((condition) =>
    condition.field.includes('start_date'),
  );
  const endDate = conditions.find((condition) =>
    condition.field.includes('end_date'),
  );
  if (startDate && endDate) {
    // Start date's value can be minus_infinity only when the user provides an offset.
    if (startDate.value === 'minus_infinity') {
      scope.direction = 'next';
      scope.timespan = String(endDate.offset);
    } else if (endDate.value === 'plus_infinity') {
      scope.direction = 'indefinitely';
      scope.timespan = null;
    }
  }
  return scope;
};

export const parseFilter = (
  filter: FilterApi,
): Array<{id: number, blocks: Map<string, FilterBlock>, nextNode: string}> => {
  const {next_nodes} = filter;
  const blocksArr = next_nodes.map((nextNode, index) => {
    const blocks = nextNode.condition.blocks;
    let parsedBlocks = (blocks ?? []).reduce((acc, block) => {
      const rules = [];
      let scope = null;
      let scopeParsed = false;
      (block.rules ?? []).forEach((rule) => {
        if (rule.conditions.length === 0) {
          return [];
        }
        if (rule.conditions.length > 1) {
          rules.push(parseActionRule(rule.conditions));
          if (!scopeParsed) {
            scope = getScopeFromActionRule(rule.conditions);
            scopeParsed = true;
          }
        } else {
          rules.push(parseEMSRule(rule.conditions[0]));
        }
      });
      return acc.set(createFilterBlockIdentifier(), {rules, scope});
    }, new Map());
    if (parsedBlocks.size === 0) {
      parsedBlocks = getDefaultFilterBlock();
    }

    return {id: index, blocks: parsedBlocks, nextNode: nextNode.next_node};
  });

  //$FlowFixMe (todo: vish)
  return blocksArr;
};

export const getFilterRuleText = (
  rule: FilterRule,
  ruleType: ?string,
): string => {
  if (!ruleType) {
    return '';
  }
  const fieldLabel = rule.field;
  const operatorLabel = OPERATOR_OPTIONS[ruleType].find(
    (operator) => operator.value === String(rule.operator),
  )?.label;
  const showValues =
    rule.operator != null &&
    ruleType !== 'boolean' &&
    !VALUE_NOT_REQUIRED_OPERATORS.includes(rule.operator);
  const value =
    getRuleDisplayValue(rule, `Add Values for ${fieldLabel}`, ruleType) ?? '';
  const displayValue = Array.isArray(value) ? value.join(', ') : value;
  return `${fieldLabel} ${operatorLabel ?? ''} ${
    showValues ? displayValue : ''
  }`;
};

const getKey = (obj, val) => Object.keys(obj).find((key) => obj[key] === val);

export const getABTEventGroup = (entityType: ?string): ?string => {
  return entityType ? getKey(ENTITY_TYPE_EVENT_MAP, entityType) : null;
};

export const getABTEventGroupForPreviousNode = (
  entityType: ?string,
  previousNodeModules: ?{...},
): ?string => {
  /*note: here we are doing special handling for chatbot action because chatbot action can either be chatbot_trigger or chatbot_over_sms.
   * This works because in a single task node we can either have chatbot_trigger or chatbot_over_sms.
   * */
  if (entityType === 'chatbot_action') {
    const taskModules = previousNodeModules ?? {};
    for (const module of Object.keys(taskModules)) {
      if (
        taskModules[module].type === 'chatbot_over_sms' ||
        taskModules[module].type === 'chatbot_trigger'
      ) {
        return taskModules[module].type;
      }
    }
  }
  return getABTEventGroup(entityType);
};

export const extractPropertyFromPreviousNode = (
  previousNodeModules: ?{...},
  eventGroup: ?string,
): Array<string> => {
  if (!previousNodeModules || !eventGroup) {
    return [];
  }
  const result = [];
  const taskModules = previousNodeModules ?? {};
  const property = EVENT_GROUP_PROPERTY_MAP[eventGroup];
  for (const module of Object.keys(taskModules)) {
    if (taskModules[module].type === eventGroup) {
      let value = taskModules[module].params[property];
      if (typeof value === 'number') {
        value = String(value);
      }
      result.push(value);
    }
  }
  return result;
};

//todo(vish): fix types
export const getPreviousABTRule = ({
  id,
  eventGroup,
  workflowId,
  variableInfo,
  getAbtUtilVariableInfo,
  selectedFieldEntityType,
  selectedFieldGroup,
  previousNodeModules,
}: {
  id: string,
  eventGroup: ?string,
  workflowId: string,
  variableInfo: PreviousNodeValue,
  getAbtUtilVariableInfo: (string, string, string) => ?SimpleABTVariable,
  selectedFieldEntityType: ?string,
  selectedFieldGroup: ?string,
  previousNodeModules: ?DynamicTaskObjectModules,
}): {...} => {
  const conditions = eventGroup ? PREVIOUS_NODE_CONDITIONS[eventGroup] : [];
  const ruleConditions = conditions.map(
    ({id, value, operator, pickValueFromUI}) => {
      const field =
        selectedFieldEntityType && selectedFieldGroup
          ? getAbtUtilVariableInfo(
              selectedFieldGroup,
              selectedFieldEntityType,
              id,
            )?.fieldPath
          : '';
      let val = null;
      if (pickValueFromUI) {
        switch (id) {
          case 'workflow_id':
            val = workflowId;
            break;
          case 'node_id':
            val = variableInfo.nodeId;
            break;
        }
      } else {
        val = value;
      }
      return {
        field,
        value: val,
        operator,
      };
    },
  );
  return {
    id,
    type: 'prevNode',
    field: variableInfo.id,
    operator: 'eq',
    value: variableInfo.value,
    conditions: ruleConditions,
    scopeFields: {
      start_date:
        selectedFieldEntityType && selectedFieldGroup
          ? getAbtUtilVariableInfo(
              selectedFieldGroup,
              selectedFieldEntityType,
              'start_date',
            )?.fieldPath
          : '',
      end_date:
        selectedFieldEntityType && selectedFieldGroup
          ? getAbtUtilVariableInfo(
              selectedFieldGroup,
              selectedFieldEntityType,
              'end_date',
            )?.fieldPath
          : '',
    },
    actionValueIdentifier: {
      actionField:
        selectedFieldEntityType && selectedFieldGroup
          ? getAbtUtilVariableInfo(
              selectedFieldGroup,
              selectedFieldEntityType,
              'reference_id',
            )?.fieldPath
          : '',
      selectedActionValue: extractPropertyFromPreviousNode(
        previousNodeModules,
        eventGroup,
      )[0],
    },
    eventGroup,
  };
};

export const ruleBlockContainsABTRule = (rules: FilterRule[]): boolean =>
  rules.some(({type}) => type === 'prevNode');

export const getValueFromAbtRuleConditions = (
  conditions: Array<PreviousRuleCondition>,
  query: string,
): ?string => {
  return conditions.find(({field}) => field.includes(query))?.value ?? null;
};

export const prevNodeSelectableActions = (
  modules: ?{...},
  actionType: ?string,
): number => {
  let count = 0;
  if (modules) {
    Object.keys(modules).forEach((module) => {
      const type = modules[module].type;
      if (type === actionType) {
        count += 1;
      }
    });
  }
  return count;
};

export const getStaticRuleLineText = (
  rule: FilterRule,
  getPreviousNodeName: (string) => string,
  getAbtPickerVariableInfo: (field: string) => ?SimpleABTVariable,
  ruleActionType: string,
  getChatbotName: ?(flowId: number) => string,
): string => {
  const nodeId =
    rule.type === 'prevNode'
      ? getValueFromAbtRuleConditions(rule.conditions, 'node_id')
      : null;
  const nodeName = nodeId ? getPreviousNodeName(nodeId) : '';
  const eventName = getAbtPickerVariableInfo(rule.field)?.title ?? '';
  const eventValue = rule.value;
  let ruleLineText = '';
  switch (ruleActionType) {
    case 'email_send':
      ruleLineText = `${nodeName} - ${eventName} is ${String(eventValue)}`;
      break;
    case 'chatbot_over_sms':
    case 'chatbot_trigger':
      let chatbotName = '';
      if (
        rule.actionValueIdentifier &&
        rule.actionValueIdentifier.selectedActionValue
      ) {
        chatbotName =
          getChatbotName?.(
            parseInt(rule.actionValueIdentifier.selectedActionValue, 10),
          ) ?? '';
      }
      ruleLineText = `${nodeName} - ${eventName} for ${chatbotName} is ${String(
        eventValue,
      )}`;
  }
  return ruleLineText;
};
const isValidFilterBranchId = (value: string): boolean => /^-?\d+$/.test(value);

export const getFilterTransitionHeader = (branchId: string): string =>
  branchId && isValidFilterBranchId(branchId)
    ? `Path - ${String(Number(branchId) + 1)}`
    : 'Path';

export const getLatestFilterTransitionInfo = (
  nodeId: string,
  swimlanes: Array<Swimlane>,
  swimlaneId: string,
): {tid: string, transitionId: number} => {
  const swimlane = swimlanes.find(({id}) => id === parseInt(swimlaneId, 10));
  const transitionArray = swimlane?.nodes[nodeId].next_nodes ?? [];
  const transitionId = transitionArray.length - 1;
  return {tid: transitionArray[transitionId].next_node, transitionId};
};

export const getRuleActionType = (
  fieldId: string,
  pickerVariables: Map<string, SimpleABTVariable>,
): ?string => {
  const eventObject = pickerVariables.get(fieldId);
  return getABTEventGroup(eventObject?.entity_type);
};

export const getPartialStaticRuleLineText = (
  prependText: string,
  rule: FilterRule,
  getPreviousNodeName: (string) => string,
  getAbtPickerVariableInfo: (field: string) => ?SimpleABTVariable,
  isDynamic?: boolean = false,
): string => {
  const nodeId =
    rule.type === 'prevNode'
      ? getValueFromAbtRuleConditions(rule.conditions, 'node_id')
      : null;
  const nodeName = nodeId ? getPreviousNodeName(nodeId) : '';
  const eventName = getAbtPickerVariableInfo(rule.field)?.title ?? '';
  return `${String(prependText)}${
    prependText ? ' ' : ''
  }${nodeName} - ${eventName}${isDynamic ? ' for' : ''}`;
};

export const getActionRulePossibleOptions = (
  rule: FilterRule,
  getPreviousNodeVariableInfo: (
    nodeId: string,
    id: string,
  ) => ?SimpleABTVariable,
): Array<MenuOption> => {
  const nodeId =
    rule.type === 'prevNode'
      ? getValueFromAbtRuleConditions(rule.conditions, 'node_id')
      : null;
  const eventInfo = nodeId
    ? getPreviousNodeVariableInfo(nodeId, rule.field)
    : null;
  if (eventInfo) {
    return eventInfo.possible_values.map((val) => ({
      key: String(val),
      label: `is ${String(val)}`,
    }));
  }
  return [];
};

export const getScopeErrorText = (timespan: ?string): string => {
  if (timespan == null || timespan === '0') {
    return 'Invalid Value';
  }
  return '';
};

export const getDynamicRulesOptions = (
  eventGroup: string,
  dynamicOptions: Array<string>,
  getChatbotName: (flowId: number) => string,
): Array<MenuOption> => {
  let result = [];
  switch (eventGroup) {
    //note: can add more event groups in the future
    case 'chatbot_over_sms':
    case 'chatbot_trigger':
      result = dynamicOptions.map((item) => ({
        key: item,
        label: getChatbotName(parseInt(item, 10)),
      }));
  }
  return result;
};
