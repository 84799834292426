// @noflow

import * as React from 'react';
import {TransitionGroup} from 'react-transition-group';


const OnlyChild = ({children}) => React.Children.toArray(children)[0] || null;

/**
 * NOTE (): the child(ren) needs to have CSS property transition with height in order for this to work
 */
export const Expander = ({
  duration,
  children,
}: {
  duration?: number,
  children?: React.Element<any>,
}) => {
  const child = React.Children.toArray(children)[0] || null;
  return (
    <TransitionGroup component={OnlyChild}>
      {child && <Expandee duration={duration}>{child}</Expandee>}
    </TransitionGroup>
  );
};
export default Expander;

export const Expanders = ({
  duration,
  children,
  ...props
}: {
  duration?: number,
  children?: React.Element<any>,
}) => (
  <TransitionGroup {...props}>
    {React.Children.map(
      children,
      (child) => child && <Expandee duration={duration}>{child}</Expandee>,
    )}
  </TransitionGroup>
);

type ExpanderStatus =
  | 'unmounted'
  | 'prerendering'
  | 'preentering'
  | 'entering'
  | 'entered'
  | 'exiting'
  | 'exited';

/**
 * this component functions like a Transition. see the
 * react-transition-group docs for more info.
 */
function Expandee({
  in: inProp,
  duration = 200,
  children,
}: {
  in?: boolean,
  duration?: number,
  children:
    | ((
        {style: ?{[string]: string | number}, ref: React.Ref<HTMLElement>},
        ExpanderStatus,
      ) => React.Node)
    | React.Element,
}) {
  const childRef = React.useRef<?HTMLElement>();
  const [state, setState] = React.useState<{
    status: ExpanderStatus,
    height: number,
  }>({
    status: 'unmounted',
    height: 0,
  });
  const {status, height} = state;

  React.useEffect(() => {
    if (inProp) {
      if (status === 'unmounted') {
        setState({...state, status: 'prerendering'});
      } else if (status === 'prerendering') {
        const height = childRef.current?.offsetHeight;
        setState({
          ...state,
          status: 'preentering',
          height,
        });
      } else if (status === 'preentering') {
        // reflow
        childRef.current?.offsetHeight;
        setState({...state, status: 'entering'});
      } else if (status === 'entering') {
        setTimeout(() => {
          setState({
            ...state,
            status: 'entered',
          });
        }, duration);
      }
    } else {
      if (status === 'entered') {
        const height = childRef.current?.offsetHeight;
        setState({
          ...state,
          status: 'exiting',
          height,
        });
      } else if (status === 'exiting') {
        // reflow
        childRef.current?.offsetHeight;
        setState({...state, status: 'exited', height: 0});
      } else if (status === 'exited') {
        setTimeout(() => {
          setState({...state, status: 'unmounted'});
        }, duration);
      }
    }
  }, [inProp, state]);

  if (status === 'unmounted') {
    return null;
  }

  let style;

  if (status === 'prerendering') {
    style = {
      position: 'absolute',
      height: 'auto',
      visibility: 'hidden',
    };
  } else if (status === 'preentering') {
    style = {
      height: 0,
      overflow: 'hidden',
    };
  } else if (['entering', 'exiting', 'exited'].includes(status)) {
    style = {
      height,
      overflow: 'hidden',
    };
  }

  if (typeof children === 'function') {
    return children({style, ref: childRef}, status);
  } else {
    const child = React.Children.only(children);
    return React.cloneElement(child, {style, ref: childRef});
  }
}
