// @flow strict

import * as React from 'react';

import {CONSTANTS} from 'src/components/authentication/contants.js';
import {LinearLoader} from '@spaced-out/ui-design-system/lib/components/LinearLoader';

import css from './password-strength.css';

import type {PasswordStrengthProps} from 'src/types/authentication.js';


const PasswordStrength = (props: PasswordStrengthProps): React.Node => {
  const {className, strength} = props;

  const getScoreClass = () => {
    switch (strength) {
      case 0:
        return css.noScore;
      case 1:
        return css.lowScore;
      case 2:
        return css.averageScore;
      case 3:
        return css.goodScore;
      case 4:
        return css.perfectScore;
    }
  };

  return (
    <div className={className}>
      <LinearLoader
        size="small"
        value={strength * 25}
        className={getScoreClass()}
      />
    </div>
  );
};

export default PasswordStrength;
