// @noflow

import {combineReducers} from 'redux';

import type {Action as PhoneNumberSetAction} from 'src/action-creators/chat/phone-number-sets';
import type {ReceiveAction as ReceiveKeywordSearchAction} from 'src/action-creators/chat/keyword-search';
import {
  type State as PhoneNumberSetState,
  default as phoneNumberSets,
} from './phone-number-sets';
import {
  type State as AllowedPhoneNumbersState,
  default as allowedPhoneNumbers,
} from './allowed-phone-numbers';
import {
  type State as MultiNumberInbox,
  default as multiNumberInbox,
} from './multi-number-inbox';
import {
  type State as KeywordSearchState,
  default as keywordSearch,
} from './keyword-search';
import {
  type State as FileUploadState,
  default as fileUpload,
} from './file-upload';
import {type State as PanelsState, default as panels} from './panels';
import {type State as QueuesState, default as queues} from './queues';


export type State = {|
  phoneNumberSets: PhoneNumberSetState,
  allowedPhoneNumbers: AllowedPhoneNumbersState,
  multiNumberInbox: MultiNumberInbox,
  keywordSearch: KeywordSearchState,
  fileUpload: FileUploadState,
  panels: PanelsState,
  queues: QueuesState,
|};

type Action = PhoneNumberSetAction;

const reduce: (state: State, action: Action) => State = combineReducers({
  phoneNumberSets,
  allowedPhoneNumbers,
  multiNumberInbox,
  keywordSearch,
  fileUpload,
  panels,
  queues,
});
export default reduce;
