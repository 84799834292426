// @flow

import * as React from 'react';

import classify from 'src/utils/classify';

import css from './card.css';


type Props = {
  header?: string,
  children: React.Node,
  className?: string,
  onSave?: (e: SyntheticEvent<>) => mixed,
  onCancel?: (e: SyntheticEvent<>) => mixed,
  actionRowContent?: React.Node,
};

export default class Card extends React.Component<Props> {
  render(): React.Node {
    const {
      actionRowContent,
      children,
      className,
      header,
      onSave,
      onCancel,
    } = this.props;
    const shouldRenderActionRow =
      actionRowContent != null && onSave && onCancel;

    return (
      <div className={classify(css.container, className)}>
        {header && <header className={css.header}>{header}</header>}

        {children}

        {shouldRenderActionRow && (
          <div className={css.actionRow}>
            {actionRowContent}
            {onSave && <button className={css.saveButton}>Save</button>}
            {onCancel && <button className={css.cancelButton}>Cancel</button>}
          </div>
        )}
      </div>
    );
  }
}
