// @flow strict-local

import {EditorState, SelectionState, Modifier} from 'draft-js';

//$FlowFixMe[untyped-import]
import {addDynamicLabel} from 'src/components/lib/markdown-editor/dynamic-text.jsx';


export const clearSurroundingEntity = (
  state: EditorState,
  callback: (newState: EditorState, selectionState: SelectionState) => void,
) => {
  const anchorKey = state.getSelection().getAnchorKey();
  const anchorOffset = state.getSelection().getAnchorOffset();
  const currentContent = state.getCurrentContent();
  const block = currentContent.getBlockForKey(anchorKey);

  const text = block.getText();
  let offset = anchorOffset;
  let offsetText = text.slice(anchorOffset, anchorOffset + 1);
  if (offsetText === ' ' && anchorOffset > 0) {
    offset -= 1;
    offsetText = text.slice(offset, offset + 1);
  }

  const surroundingEntity =
    block.getEntityAt(offset) ??
    block.getEntityAt(offset + 1) ??
    block.getEntityAt(offset - 1);

  if (surroundingEntity) {
    block.findEntityRanges(
      (md) => md.getEntity() === surroundingEntity,
      (start, stop) => {
        const selectionState = state.getSelection().merge({
          anchorOffset: start,
          focusOffset: stop,
        });
        const newContent = Modifier.applyEntity(
          currentContent,
          selectionState,
          null,
        );
        const newEditorState = EditorState.push(
          state,
          newContent,
          'apply-entity',
        );
        callback(newEditorState, selectionState);
      },
    );
  }
};

export const getNewEditorStateAfterReplacingPlaceholder = (
  dynamicLabel: string,
  oldState: EditorState,
  callback: (value: EditorState) => void,
): void => {
  clearSurroundingEntity(
    oldState,
    (stateWithSurroundingRemoved, selectionState) => {
      const newEditorState = addDynamicLabel(
        stateWithSurroundingRemoved,
        selectionState,
        dynamicLabel,
      );

      callback(newEditorState);
    },
  );
};
