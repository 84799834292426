// @noflow

import * as React from 'react';

import {scaleQuantize as d3ScaleQuantize} from 'd3-scale';
import max from 'lodash/max';

import pure from 'src/flux/pure.jsx';
import Loading from 'src/components/lib/loading/loading.jsx';
import {MouseTip} from 'src/components/lib/mouse-tip/mouse-tip.jsx';

import {classify} from 'src/utils';

import css from './retention-grid.css';
import chartsCss from 'src/components/lib/charts/charts.css';


const RetentionGrid = ({
  className,
  data,
  headers,
  yKey,
  yTitle,
  dataColumns,
  title,
  getTooltipText,
  loading,
}: {
  className?: string,
  getTooltipText?: (d: {[key: string]: string | number}, key: string) => string,
  data: {
    [key: string]: string | number,
  }[],
  headers: {
    label: string,
    value: string,
  }[],
  loading?: boolean,
  yKey: string,
  yTitle: string,
  dataColumns: string[],
  title: string,
}) => {
  const dataColumnsSet = new Set(dataColumns);
  const flattenedData = [].concat(
    ...data.map(dataRow => dataColumns.map(column => dataRow[column])),
  );
  const hasData = flattenedData.some(Boolean);
  const colorScale = d3ScaleQuantize()
    .domain([0, max(flattenedData)])
    .range([css.colorGreenTint, css.colorGreen, css.colorGreenShadow]);
  const y = data.map(dataRow => dataRow[yKey]);
  const gridColumns = headers.reduce(
    (prev, curr, index) => ({...prev, [curr.value]: index + 2}),
    {},
  );

  return (
    <div className={classify(className, css.container)}>
      {loading && <Loading />}
      {!loading && !hasData && (
        <div className={chartsCss.zero}>No data yet</div>
      )}
      {!loading && hasData && (
        <div
          className={css.content}
          style={{
            // NOTE(elliot): All columns are the same width but not all
            // rows are the same height.
            gridTemplateColumns: `repeat(${headers.length + 1}, 1fr)`,
          }}
        >
          {/* titles */}
          <div className={css.yTitle}>{yTitle}</div>
          <div
            className={css.title}
            style={{gridColumnEnd: headers.length + 2}}
          >
            {title}
          </div>
          {/* y-axis of sorts */}
          {y.map((yValue, yIndex) => (
            <div className={css.y} key={yValue} style={{gridRow: yIndex + 3}}>
              {yValue}
            </div>
          ))}
          {/* header */}
          {headers.map(({label, value}, index) => (
            <div
              className={css.headerItem}
              key={value}
              style={{gridColumn: index + 2}}
            >
              {label}
            </div>
          ))}
          {/* grid cells */}
          {data.map((dataRow, dataIndex) => {
            const gridRow = dataIndex + 3;
            return headers
              .filter(({value}) => value in dataRow)
              .map(({value: header}, headerIndex) => {
                const isDataColumn = dataColumnsSet.has(header);
                const value = dataRow[header];
                const gridColumn = isDataColumn
                  ? headerIndex + 2
                  : gridColumns[header];
                const backgroundColor = colorScale(value);
                const color =
                  backgroundColor !== css.colorGreenTint
                    ? css.colorWhite
                    : null;
                const colors = isDataColumn ? {backgroundColor, color} : {};
                const key = `${gridRow} ${gridColumn}`;
                const box = (
                  <div
                    className={css.box}
                    key={key}
                    style={{...colors, gridRow, gridColumn}}
                  >
                    <div className={css.boxValue}>{value}</div>
                  </div>
                );
                return isDataColumn && getTooltipText ? (
                  <MouseTip key={key} content={getTooltipText(dataRow, header)}>
                    {box}
                  </MouseTip>
                ) : (
                  box
                );
              });
          })}
        </div>
      )}
    </div>
  );
};

export default pure()(RetentionGrid);
