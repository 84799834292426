// @flow strict

import type {LoggerInfo} from 'src/utils/logger';


const levelMap = {
  warning: 'warn',
  critical: 'error',
};

export default function log(info: LoggerInfo, infoString: string) {
  let {level} = info;
  level = levelMap[level] ?? level;
  // eslint-disable-next-line  no-console
  console[level](process.env.EX_ENV === 'server' ? infoString : info);
}
