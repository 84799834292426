// @noflow

import * as React from 'react';

import {classify} from 'src/utils';

import Toggle from 'src/components/lib/toggle/toggle.jsx';
import PhoneInput from 'src/components/lib/phone-input';

import css from './chat-edit.css';
import formCss from './account-edit.css';


let countryCode;

class ChatEdit extends React.Component<
  {},
  {
    phone: string,
    country?: string,
    forwardingPhone: string,
  },
> {
  state = {
    phone: '',
    country: null,
    forwardingPhone: '',
  };

  componentDidMount() {
    import('emoji-flags').then(emojiFlags => {
      countryCode = emojiFlags.countryCode;
    });
  }

  render() {
    const {country} = this.state;
    const flag = countryCode && country ? countryCode(country).emoji : country;

    return (
      <div className={css.root}>
        <div className={css.header}>
          <h2 className={classify(formCss.subtitle, css.title)}>
            Sense Messenger
          </h2>
          <div className={css.enabler}>
            Enabled
            <Toggle className={css.toggle} />
          </div>
        </div>

        <div className={css.box}>
          <div className={css.boxHeader}>SMS Settings</div>
          <label className={formCss.formRow}>
            <div className={formCss.label}>Phone Number:</div>
            <PhoneInput
              className={formCss.input}
              name="phone"
              onChange={this.handlePhoneChange}
              value={this.state.phone}
            />
          </label>

          <label className={formCss.formRow}>
            <div className={formCss.label}>Country:</div>
            <div>{flag}</div>
          </label>

          <label className={formCss.formRow}>
            <div className={formCss.label}>City:</div>
            <div>San Francisco</div>
          </label>

          <label className={formCss.formRow}>
            <div className={formCss.label}>Forward calls to:</div>
            <div>
              <PhoneInput
                className={formCss.input}
                name="forwardingPhone"
                onChange={this.handleForwardChange}
                value={this.state.forwardingPhone}
              />
            </div>
          </label>
        </div>
      </div>
    );
  }

  handleForwardChange = (value: string) => setState(this, {
      forwardingPhone: value,
    });

  handlePhoneChange = (value: string, {country}: {country: string}) => setState(this, {
      phone: value,
      country,
    });
}

export default ChatEdit;

function setState(component, newState) {
  return new Promise(resolve => {
    component.setState(newState, resolve);
  });
}
