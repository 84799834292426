// @flow strict

import isPlainObject from 'lodash/isPlainObject';
import map from 'lodash/map';


export function classify(...args: Array<mixed>): string {
  return args
    .map((arg) => {
      let mappedArg = arg;
      if (isPlainObject(arg)) {
        // $FlowFixMe[incompatible-call]
        mappedArg = map(arg, (test, name: string) => (test ? name : null));
      }
      return mappedArg;
    })
    .reduce((array, current) => {
      if (Array.isArray(current)) {
        array.push(...current);
      } else {
        array.push(current);
      }
      return array;
    }, [])
    .filter((val) => val)
    .join(' ');
}

export default classify;
