// @flow

import type {EntityType} from 'src/types/ats-entities';
import type {DynamicLabelExtra} from 'src/action-creators/dynamic-labels';
import type {SuggestionProps} from 'src/components/lib/token-list-input/new-stuff.jsx';

import * as React from 'react';
import {useSelector} from 'react-redux';

import {
  entityColorMapping,
  getAttributeRelationshipPath,
  generateAttributeGrammarTokens,
} from 'src/utils/entities';
import {classify} from 'src/utils';

import {selectEntityTypeMappingsByName} from 'src/selectors/ats-entities';

import {AutoTruncatedText} from 'src/components/lib/truncated-text/truncated-text.jsx';

import common from 'src/styles/common.css';
import css from './variable-picker.css';


export const VariablePickerDropdownRow = ({
  option: dynamicLabel,
  extras = {},
  searchString,
  onClick,
  onMouseDown,
  selected,
  isSelected,
}: {
  option: DynamicLabelExtra,
  searchString: string,
  preventSelection: () => any,
  extras: {
    entityType: EntityType,
    isHighlighted: boolean,
  },
  onClick?: (evt: SyntheticMouseEvent<>) => mixed,
  onMouseDown?: (evt: SyntheticKeyboardEvent<>) => mixed,
  selected?: boolean,
  isSelected: boolean,
}): React.Element<'div'> => {
  let children;

  const entitiesByName = useSelector(selectEntityTypeMappingsByName);

  if (!dynamicLabel.entity_type) {
    if (searchString && !selected) {
      children = `Create ${searchString}`;
    } else {
      children = (
        <div className={css.variableName}>{`${dynamicLabel.value}`}</div>
      );
    }
  } else {
    const color =
      (dynamicLabel.display_entity_type &&
        common[entityColorMapping[dynamicLabel?.sense_name]?.text]) ||
      common.colorGray6;

    const entityRelationshipPath =
      dynamicLabel.entityRelationshipPath ??
      getAttributeRelationshipPath(
        dynamicLabel.label,
        dynamicLabel.entityType,
        entitiesByName,
      );

    const tokens = generateAttributeGrammarTokens(entityRelationshipPath);
    children = (
      <>
        <div
          key={dynamicLabel.name}
          style={{
            color,
          }}
          className={css.variableName}
        >
          {`${entityRelationshipPath[0]}`}
        </div>

        <section className={css.dropdownPipeSeparator} role="presentation">
          |
        </section>
        {tokens.map((token, index) => (
          <div
            key={index} // eslint-disable-line react/no-array-index-key
            className={classify(css.dropdownPhraseElement, {
              [css.dropdownBoldPhraseElement]: index % 2 !== 0,
            })}
          >
            {token}
          </div>
        ))}
      </>
    );
  }

  return (
    <div
      className={classify(
        css.dropdownResult,
        {[css.dropdownResultHighlighted]: isSelected},
        {[css.dropdownResultSelected]: selected},
      )}
      onClick={onClick}
      onMouseDown={onMouseDown}
      data-qa-id="variable-picker-dropdown-row"
    >
      {children}
    </div>
  );
};

export function NewVariableDropdownRow({
  className,
  option: dynamicLabel,
  onClick,
  isSelected,
}: SuggestionProps<DynamicLabelExtra>): React.Element<'div'> {
  const entitiesByName = useSelector(selectEntityTypeMappingsByName);

  let children;

  if (!dynamicLabel.entity_type) {
    if (dynamicLabel.arbitrary) {
      children = `Create ${dynamicLabel.value}`;
    } else {
      children = (
        <div className={css.variableName}>{`${dynamicLabel.value}`}</div>
      );
    }
  } else {
    const color =
      (dynamicLabel.display_entity_type &&
        common[entityColorMapping[dynamicLabel?.sense_name]?.text]) ||
      common.colorGray6;

    const entityRelationshipPath =
      dynamicLabel.entityRelationshipPath ??
      getAttributeRelationshipPath(
        dynamicLabel.label,
        dynamicLabel.entity_type,
        entitiesByName,
      );

    const tokens = generateAttributeGrammarTokens(entityRelationshipPath);
    children = (
      <>
        <div
          key={dynamicLabel.label}
          style={{
            color,
          }}
          className={css.variableName}
        >
          {`${entityRelationshipPath[0]}`}
        </div>

        <section className={css.dropdownPipeSeparator} role="presentation">
          |
        </section>
        {tokens.map((token, index) => (
          <div
            key={index} // eslint-disable-line react/no-array-index-key
            className={classify(css.dropdownPhraseElement, {
              [css.dropdownBoldPhraseElement]: index % 2 !== 0,
            })}
          >
            {token}
          </div>
        ))}
      </>
    );
  }

  return (
    <div
      className={classify(css.dropdownResult, {
        [css.dropdownResultHighlighted]: isSelected,
      })}
      onClick={onClick}
      data-qa-id="variable-picker-dropdown-row"
    >
      {children}
    </div>
  );
}
