// @flow strict
import type {HVBroadcastActions} from 'src/action-creators/hv-broadcasts.js';

import type {
  KeyedHVBroadcast,
  KeyedHVBroadcastSuppression,
  fileCsvUploadEntries,
  ContactList,
  Filters,
  FileDetails,
  HVBroadcastSuppressionTimeFrame,
  HVBroadcastDataWithPagination,
  ScheduledOrNewHVBroadcast,
  PerformanceDetailsResponse,
} from 'src/types/hv-broadcasts.js';
import {
  RECEIVE_ALL_HV_BROADCASTS,
  RECEIVE_HV_BROADCAST_SUPPRESSION,
  RESTORE_HV_BROADCAST_PHONE_NUMBERS,
  SET_CSV_FILE_UPLOAD_HEADERS,
  SET_CSV_FILE_UPLOAD_ENTRIES,
  SET_CSV_FILE_UPLOAD,
  SET_PERFORMANCE_DATA,
  SET_MESSAGE_DATA,
  RECEIVE_HV_BROADCAST_FILTERS,
  RECEIVE_HV_CONTACT_LISTS,
  RECEIVE_AGENTS_RECENT_HV_CONTACT_LISTS,
  RECEIVE_HV_BROADCAST_SUPPRESSION_TIME_FRAME,
  RECEIVE_UPLOADED_FILE_DETAILS,
  REMOVE_UPLOADED_FILE_DETAILS,
  UPDATE_CONTACT_LIST_LIMIT_REACHED,
  UPDATE_CONTACT_LIST_ID_CREATED,
  CLEAR_UPLOADED_FILE_DETAILS,
  INITIALIZE_UPLOADED_FILE_DETAILS,
  RECEIVE_PERFORMANCE_DELIVERY_ANALYTICS,
  RECEIVE_HV_BROADCAST_UPDATE_NAME,
  RECEIVE_CURRENT_HV_BROADCAST_DATA,
  REMOVE_DELETED_HV_BROADCAST,
  SET_SCHEDULER_TEMPLATE_ID,
  SET_TEMPLATE_ID,
  SET_SHOW_RENAME_MODAL_POPUP_DETAILS,
  UPDATE_PERFORMANCE_DATA_NAME,
  CLEAR_CURRENT_BROADCAST_DATA,
  CLEAR_HV_BROADCASTS,
  SET_SELECTED_BROADCASTS_TAB,
  CLEAR_HV_BROADCAST_RESTORED_PHONE_NUMBERS,
  CLEAR_HV_BROADCAST_SUPPRESSION,
  SET_BROADCAST_DETAILS,
  RECEIVE_BROADCAST_PERFORMANCE_RECIPIENTS,
} from 'src/action-creators/hv-broadcasts.js';

import keyBy from 'lodash/keyBy';


type HVBroadcastState = {
  hvBroadcasts: HVBroadcastDataWithPagination,
  hvBroadcastSuppression: KeyedHVBroadcastSuppression,
  hvBroadcastRestoredPhoneNumbers: string[],
  fileCsvUploadHeaders: string[],
  fileCsvUploadEntries: fileCsvUploadEntries[],
  fileCsvUpload: File | null,
  filters: Filters,
  contactLists: ContactList[],
  agentsRecentContactLists: ContactList[],
  broadcastSuppressionTimeFrame: HVBroadcastSuppressionTimeFrame,
  uploadedFileDetails: FileDetails[],
  contactListLimitReached: boolean,
  listCreatedId: string,
  hvBroadcastMessage: ScheduledOrNewHVBroadcast,
  hvBroadcastPerformance: PerformanceDetailsResponse,
  currentBroadcastData: ScheduledOrNewHVBroadcast,
  templateId: string,
  schedulerTemplateId: string,
  showRenameModalPopupDetails: string,
  selectedBroadcastType: string,
  broadcastDetails: ScheduledOrNewHVBroadcast,
};

const initialDraftMessage = {
  name: '',
  senderName: '',
  phoneNumberSetName: '',
  phoneNumberSetId: '',
  channel: 'sms',
  sentTime: '',
  messageBody: '',
  totalRecipients: 0,
  contactLists: [],
  files: [],
  grabbedNumbers: null,
  sendNow: true,
  id: '',
  templateId: '',
  schedulerTemplateId: '',
};

const initialHVBroadcasts = {
  broadcasts: {},
  page: 0,
  recordsPerPage: 0,
  totalPages: 0,
  totalRecords: 0,
};

const initialTimeFrame = {
  broadcastSuppresionTimeType: 'DAY_3',
  time: {
    number: 3,
    unit: 'day',
  },
};

const initialState: HVBroadcastState = {
  hvBroadcasts: initialHVBroadcasts,
  hvBroadcastSuppression: {},
  hvBroadcastRestoredPhoneNumbers: [],
  hvBroadcastPerformance: {
    deliveryAnalytics: {
      delivered: {
        total: 0,
        percent: 0,
      },
      responded: {
        total: 0,
        percent: 0,
      },
      failed: {
        total: 0,
        percent: 0,
      },
      optedOut: {
        total: 0,
        percent: 0,
      },
      skipped: {
        total: 0,
        percent: 0,
      },
    },
    name: '',
    page: 0,
    recipients: [],
    recordsPerPage: 0,
    sensePhone: '',
    totalPages: 0,
    totalRecipients: 0,
    totalRecords: 0,
    id: '',
  },
  hvBroadcastMessage: initialDraftMessage,
  fileCsvUploadHeaders: [],
  fileCsvUploadEntries: [],
  fileCsvUpload: null,
  filters: {phoneNumberSets: [], sender: []},
  contactLists: [],
  agentsRecentContactLists: [],
  broadcastSuppressionTimeFrame: initialTimeFrame,
  uploadedFileDetails: [],
  contactListLimitReached: false,
  listCreatedId: '',
  currentBroadcastData: initialDraftMessage,
  templateId: '',
  schedulerTemplateId: '',
  showRenameModalPopupDetails: '',
  selectedBroadcastType: 'sent',
  broadcastDetails: initialDraftMessage,
};

export default (
  state: HVBroadcastState = initialState,
  action: HVBroadcastActions,
): HVBroadcastState => {
  switch (action.type) {
    case RECEIVE_ALL_HV_BROADCASTS:
      const update = action.payload.update;
      const keyedBroadcasts = keyBy(action.payload.data.broadcasts, 'id');
      const broadcastDetails = {
        ...state.hvBroadcasts.broadcasts,
        ...keyedBroadcasts,
      };
      return {
        ...state,
        hvBroadcasts: update
          ? {
              ...action.payload.data,
              broadcasts: broadcastDetails,
            }
          : {...action.payload.data, broadcasts: keyedBroadcasts},
      };
    case RECEIVE_HV_BROADCAST_UPDATE_NAME:
      const {id, name} = action.payload;
      const currentBroadcast = state.hvBroadcasts.broadcasts[id];
      currentBroadcast.name = name;
      const allBroadcasts = {
        ...state.hvBroadcasts.broadcasts,
        [id]: currentBroadcast,
      };
      return {
        ...state,
        hvBroadcasts: {...state.hvBroadcasts, broadcasts: allBroadcasts},
      };
    case RECEIVE_CURRENT_HV_BROADCAST_DATA:
      const {templateId = '', schedulerTemplateId = ''} = action.payload;
      return {
        ...state,
        currentBroadcastData: action.payload,
        broadcastDetails: action.payload,
        templateId,
        schedulerTemplateId,
      };
    case REMOVE_DELETED_HV_BROADCAST:
      const filteredBroadcasts =
        delete state.hvBroadcasts.broadcasts[action.payload];
      return {...state};
    case RECEIVE_HV_BROADCAST_SUPPRESSION:
      return {
        ...state,
        hvBroadcastSuppression: keyBy(action.payload, 'phoneNumber'),
      };
    case RECEIVE_HV_BROADCAST_SUPPRESSION_TIME_FRAME:
      return {
        ...state,
        broadcastSuppressionTimeFrame: action.payload,
      };
    case RESTORE_HV_BROADCAST_PHONE_NUMBERS:
      return {
        ...state,
        hvBroadcastRestoredPhoneNumbers: [
          ...state.hvBroadcastRestoredPhoneNumbers,
          ...action.payload,
        ],
      };
    case CLEAR_HV_BROADCAST_RESTORED_PHONE_NUMBERS:
      return {
        ...state,
        hvBroadcastRestoredPhoneNumbers: [],
        hvBroadcastSuppression: {},
        broadcastSuppressionTimeFrame: initialTimeFrame,
      };
    case CLEAR_HV_BROADCAST_SUPPRESSION: {
      return {
        ...state,
        hvBroadcastSuppression: {},
      };
    }
    case SET_CSV_FILE_UPLOAD_HEADERS:
      return {
        ...state,
        fileCsvUploadHeaders: action.payload,
      };
    case SET_CSV_FILE_UPLOAD_ENTRIES:
      return {
        ...state,
        fileCsvUploadEntries: action.payload,
      };
    case SET_CSV_FILE_UPLOAD:
      return {
        ...state,
        fileCsvUpload: action.payload,
      };
    case RECEIVE_HV_BROADCAST_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case SET_PERFORMANCE_DATA:
      return {...state, hvBroadcastPerformance: action.payload};
    case UPDATE_PERFORMANCE_DATA_NAME:
      if (action.payload.id === state.hvBroadcastPerformance.id) {
        return {
          ...state,
          hvBroadcastPerformance: {
            ...state.hvBroadcastPerformance,
            name: action.payload.name,
          },
        };
      } else {
        return state;
      }
    case RECEIVE_PERFORMANCE_DELIVERY_ANALYTICS:
      if (state.hvBroadcastPerformance.id === action.payload.id) {
        return {
          ...state,
          hvBroadcastPerformance: {
            ...state.hvBroadcastPerformance,
            deliveryAnalytics: action.payload.deliveryAnalytics,
          },
        };
      } else {
        return state;
      }
    case RECEIVE_BROADCAST_PERFORMANCE_RECIPIENTS:
      if (state.hvBroadcastPerformance.id === action.payload.id) {
        return {
          ...state,
          hvBroadcastPerformance: {
            ...state.hvBroadcastPerformance,
            ...action.payload,
          },
        };
      } else {
        return state;
      }
    case SET_MESSAGE_DATA:
      return {...state, hvBroadcastMessage: action.payload};
    case RECEIVE_HV_CONTACT_LISTS:
      const updateList = action.payload.update;
      const receivedContactList = action.payload.contactLists;
      return {
        ...state,
        contactLists: updateList
          ? [...state.contactLists, ...receivedContactList]
          : receivedContactList,
      };
    case RECEIVE_AGENTS_RECENT_HV_CONTACT_LISTS:
      return {
        ...state,
        agentsRecentContactLists: action.payload,
      };
    case RECEIVE_UPLOADED_FILE_DETAILS:
      return {
        ...state,
        uploadedFileDetails: [...state.uploadedFileDetails, action.payload],
      };
    case REMOVE_UPLOADED_FILE_DETAILS:
      const filteredResults = state.uploadedFileDetails.filter(
        (file) => file.id !== action.payload,
      );
      return {
        ...state,
        uploadedFileDetails: filteredResults,
      };
    case UPDATE_CONTACT_LIST_LIMIT_REACHED:
      return {
        ...state,
        contactListLimitReached: action.payload,
      };
    case UPDATE_CONTACT_LIST_ID_CREATED:
      return {
        ...state,
        listCreatedId: action.payload,
      };
    case CLEAR_UPLOADED_FILE_DETAILS:
      return {
        ...state,
        uploadedFileDetails: [],
      };
    case INITIALIZE_UPLOADED_FILE_DETAILS:
      return {
        ...state,
        uploadedFileDetails: action.payload,
      };
    case SET_TEMPLATE_ID:
      return {...state, templateId: action.payload};
    case SET_SCHEDULER_TEMPLATE_ID:
      return {...state, schedulerTemplateId: action.payload};
    case SET_SHOW_RENAME_MODAL_POPUP_DETAILS:
      return {...state, showRenameModalPopupDetails: action.payload};
    case CLEAR_CURRENT_BROADCAST_DATA:
      return {...state, currentBroadcastData: initialDraftMessage};
    case CLEAR_HV_BROADCASTS:
      return {...state, hvBroadcasts: initialHVBroadcasts};
    case SET_SELECTED_BROADCASTS_TAB:
      return {...state, selectedBroadcastType: action.payload};
    case SET_BROADCAST_DETAILS:
      return {...state, broadcastDetails: action.payload};
    default:
      return state;
  }
};
