// @noflow

import type {EngagementCohorts} from 'src/types/engagement-cohorts';
import type {EngagementCohortsAction} from 'src/action-creators/engagement-cohorts';

import {RECEIVE} from 'src/action-creators/engagement-cohorts';


export type EngagementCohortsState = {
  [splitBy: string]: EngagementCohorts,
};

const initialState = {};

export default (
  state: EngagementCohortsState = initialState,
  action: EngagementCohortsAction,
) => {
  switch (action.type) {
    case RECEIVE:
      return {
        ...state,
        [action.payload.splitBy]: action.payload.data,
      };

    default:
      return state;
  }
};
