// @flow strict
import type {TriggerState} from 'src/reducers/trigger';
import type {
  TriggerRule,
  TriggerApi,
  TriggerApiPayload,
} from 'src/types/trigger';

import uniqueId from 'lodash/uniqueId';
// $FlowFixMe[untyped-import]
import {hashObject} from 'src/utils/index';


export const getTriggerRule = (obj: $Shape<TriggerRule>): TriggerRule => {
  const defaultTriggerRule = {
    field: null,
    fromValue: null,
    toValue: null,
    fromValueType: 'any',
    toValueType: 'specific',
  };
  return {
    id: uniqueId('trigger_rule_'),
    ...defaultTriggerRule,
    ...obj,
  };
};

export const getTriggerRuleText = (rule: TriggerRule): string => {
  const fieldLabel = rule.field;
  return `${fieldLabel ?? ''} ${getTriggerRuleValueText(rule)}`;
};

export const getTriggerRuleValueText = (rule: TriggerRule): string => {
  if (rule.fromValueType && rule.toValueType) {
    return `changes from ${
      rule.fromValueType === 'any' ? 'any value' : rule.fromValue ?? ''
    } to ${rule.toValueType === 'any' ? 'any value' : rule.toValue ?? ''} `;
  }
  return 'Changes from one value to another value, based on your selection';
};

export const parseTrigger = (
  trigger: TriggerApi,
): {rules: Array<TriggerRule>} => {
  const {next_nodes} = trigger;

  let conditions = next_nodes[0].condition.blocks[0].rules[0].conditions ?? [];
  conditions = conditions || [];
  const rules = conditions.map((condition) => {
    //$FlowFixMe[prop-missing]
    //$FlowFixMe[incompatible-use]
    const fromValue = condition.value.from ?? null;
    //$FlowFixMe[prop-missing]
    //$FlowFixMe[incompatible-use]
    const toValue = condition.value.to ?? null;
    return {
      id: uniqueId('trigger_rule_'),
      field: condition.field,
      fromValue: fromValue ? parseTriggerValue(fromValue) : null,
      toValue: toValue ? parseTriggerValue(toValue) : null,
      fromValueType: fromValue !== null ? 'specific' : 'any',
      toValueType: toValue !== null ? 'specific' : 'any',
    };
  });
  return {rules};
};

export const normalizeTrigger = (trigger: TriggerState): TriggerApiPayload => {
  const {rules} = trigger;
  const conditions = rules.reduce((acc, rule) => {
    if (rule.field != null) {
      acc.push({
        is_dynamic: true,
        field: rule.field,
        operator: 'changes',
        value: {
          from:
            rule.fromValueType === 'any'
              ? null
              : normalizeTriggerValue(rule.fromValue),
          to:
            rule.toValueType === 'any'
              ? null
              : normalizeTriggerValue(rule.toValue),
        },
      });
    }
    return acc;
  }, []);

  return {
    conditions: {
      blocks: [
        {
          rules: [
            {
              conditions,
            },
          ],
        },
      ],
    },
  };
};

export const normalizeTriggerValue = (value: ?string): ?(boolean | string) => {
  return ['true', 'false'].includes(value)
    ? value === 'true'
      ? true
      : false
    : value;
};
export const parseTriggerValue = (value: boolean | string): ?string => {
  return typeof value === 'boolean'
    ? value === true
      ? 'true'
      : 'false'
    : value;
};
export const getDuplicateRules = (rules: Array<TriggerRule>): Array<string> => {
  const ruleMap = rules.reduce((acc, rule) => {
    //ignore id field in hashing the object
    const {id, ...objToHash} = rule;
    const key = (hashObject(objToHash): string);
    if (!acc.has(key)) {
      return new Map([...acc, [key, [rule.id]]]);
    } else {
      const existingRules = acc.get(key);
      if (existingRules) {
        return new Map([...acc, [key, [...existingRules, rule.id]]]);
      }
    }
    return acc;
  }, new Map());
  return [...ruleMap.keys()].reduce((acc, key) => {
    const rules = ruleMap.get(key) ?? [];
    if (rules.length > 1) {
      return [...acc, ...rules];
    }
    return acc;
  }, []);
};
