// @noflow
import type {Branch} from 'src/types/survey';

import Store from './base';
import isEqual from 'lodash/isEqual';
import partial from 'lodash/partial';
import findKey from 'lodash/findKey';
import omit from 'lodash/omit';
import forEach from 'lodash/forEach';


type Error = {
  errors: string[],
  status: number,
};

export const BranchType = {
  EventBranch: 'event_branch',
  ModuleBranch: 'module_branch',
};

export default class BranchStore extends Store {
  state: {
    editing: boolean,
    branches: {[key: string | number]: Branch},
    saving: boolean,
    error?: Error,
    eventMaps: {[key: string | number]: string | number},
  };

  constructor() {
    super('branches');
    this.state = {
      editing: false,
      branches: {},
      saving: false,
      eventMaps: {},
    };
  }

  getBranch(branchId: string): Branch {
    return this.state.branches[branchId];
  }

  //This is for Event Branches, Gets Parent Branch of an Event.
  getBranchByEventId(eventId: string): Branch {
    return this.state.branches[this.state.eventMaps[eventId]];
  }

  receiveBranches(branches: Branch[]) {
    branches.forEach(branch => {
      this.receiveBranch(branch);
    });
  }

  receiveBranch(branch: Branch) {
    if (branch.id) {
      this.updateState({
        branches: {
          [branch.id]: {
            $set: branch,
          },
        },
      });
    }
    this.updateEventMap(branch);
  }

  updateEventMap(branch: Branch) {
    if (
      branch.branchType === BranchType.EventBranch &&
      branch.event &&
      branch.event.id
    ) {
      this.updateState({
        eventMaps: {
          [branch.event.id]: {
            $set: branch.id,
          },
        },
      });
    }
  }

  purgeEditBranches(branchIds: string[]) {
    forEach(branchIds, branch => {
      if (branch && branch.id.indexOf('new_branch_') > -1) {
        this.remove(branch.id);
      }
    });
  }

  remove(branchId: string) {
    const branches = omit(this.state.branches, branchId);

    const eventMaps = omit(
      findKey(this.state.eventMaps, partial(isEqual, branchId)),
    );

    this.setState({
      branches,
      eventMaps,
    });
  }

  isEditing(editing: boolean) {
    if (!arguments.length) {
      return this.state.editing;
    } else {
      this.setState({editing});
    }
  }

  setError(error: Error) {
    this.updateState({
      error: {
        $set: error,
      },
    });
  }

  getError(): ?Error {
    return this.state.error;
  }

  processedError() {
    this.updateState({
      error: {
        $set: undefined,
      },
    });
  }

  isNew(branchId: string) {
    return branchId.indexOf('new_branch_') > -1;
  }
}
