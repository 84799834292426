// @flow

export const contains = (
  superset: Set<mixed>,
  maybeSubset: Set<mixed>,
): boolean => {
  for (const child of maybeSubset) {
    if (!superset.has(child)) {
      return false;
    }
  }
  return true;
};
