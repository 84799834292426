// @flow strict

//$FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';
import type {
  FieldMappingScreen,
  StandardEntityAttributes,
  FieldMappingGenericError,
  StandardEntityDesignationResponse,
  StandardEntityDesignationCount,
} from 'src/types/field-mapping';

import {
  getDesignationCount,
  getIfAnyChangesInDesignation,
} from '../utils/field-mapping';

/*
 * Base Selectors
 */

export const selectActiveFieldMappingScreen = (
  state: State,
): FieldMappingScreen => state.fieldMapping.activeScreen;

/**
 * standard entity Attributes
 */

export const selectStandardEntity = (state: State): ?StandardEntityAttributes =>
  state.fieldMapping?.standardEntityAttributes;
export const selectStandardEntityLoading = (state: State): ?boolean =>
  state?.fieldMapping?.standardEntityLoading;
export const selectStandardEntityError = (
  state: State,
): ?FieldMappingGenericError => state?.fieldMapping?.standardEntityError;

/**
 * standard entity Designations
 */

export const selectStandardEntityDesignation = (
  state: State,
): ?StandardEntityDesignationResponse =>
  state.fieldMapping?.standardEntityDesignationResponse;

export const selectStandardEntityDesignationLoading = (
  state: State,
): ?boolean => state?.fieldMapping?.standardEntityDesignationLoading;

export const selectgetStandardEntityErrorDesignation = (
  state: State,
): ?FieldMappingGenericError =>
  state?.fieldMapping?.standardEntityDesignationError;

export const selectLocalStandardEntityDesignation = (
  state: State,
): ?StandardEntityDesignationResponse =>
  state.fieldMapping?.localStandardEntityDesignation;

export const selectStandardEntityDesignationCount = (
  state: State,
): ?StandardEntityDesignationCount =>
  getDesignationCount(state.fieldMapping?.localStandardEntityDesignation);

/**
 * save the updates mapping
 */

export const selectSaveStandardFieldMappingLoading = (state: State): boolean =>
  state?.fieldMapping.saveStandardFieldMappingLoading;

export const selectSaveStandardFieldMappingSuccess = (state: State): boolean =>
  state?.fieldMapping.saveStandardFieldMappingSuccess;

export const selectConfirmMigrateFieldMappingLoading = (
  state: State,
): ?boolean => state?.fieldMapping.confirmMigrateFieldMappingLoading;

export const selectConfirmMigrateFieldMappingSuccess = (
  state: State,
): ?boolean => state?.fieldMapping.confirmMigrateFieldMappingSuccess;

export const selectAnyChangesInDesignation = (state: State): ?boolean =>
  getIfAnyChangesInDesignation(
    state.fieldMapping?.standardEntityDesignationResponse,
    state.fieldMapping?.localStandardEntityDesignation,
  );
