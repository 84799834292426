// @flow strict
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useEnvironmentContext} from 'src/hooks/useEnvironmentContext';
//$FlowFixMe
import {validateEmail} from 'src/utils/index';
import {ERROR_CODES} from 'src/components/authentication/errors.js';

import {Input} from '@spaced-out/ui-design-system/lib/components/Input';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {
  TitleMedium,
  BodyLarge,
  SubTitleMedium,
  BodySmall,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {InContextAlert} from '@spaced-out/ui-design-system/lib/components/InContextAlert';
import {CONSTANTS} from 'src/components/authentication/contants.js';
import {
  forgotPassword,
  forgotPasswordNoAgency,
} from 'src/action-creators/authentication.js';

import {selectForgotPasswordError} from 'src/selectors/authentication';

import css from './forgot-password.css';
import maincss from 'src/components/authentication/main.css';

import type {ForgotPasswordFormProps} from 'src/types/authentication.js';


const ForgotPassword = (props: ForgotPasswordFormProps): React.Node => {
  const {dynamicAgency, showConfirmation} = props;

  const dispatch = useDispatch();

  const forgotPasswordError = useSelector(selectForgotPasswordError);
  const [email, setEmail] = React.useState<string>('');
  const [isValidEmail, setIsValidEmail] = React.useState<boolean>(true);
  const [isSendingEmail, setIsSendingEmail] = React.useState<boolean>(false);

  const validateUserEmail = () => {
    setIsValidEmail(validateEmail(email));
  };

  const handleReset = async () => {
    if (!validateEmail(email)) {
      setIsValidEmail(false);
      return;
    }

    let response;

    setIsSendingEmail(true);
    if (dynamicAgency) {
      response = await dispatch(forgotPasswordNoAgency(email));
    } else {
      response = await dispatch(forgotPassword(email));
    }

    setIsSendingEmail(false);
    if (response) {
      showConfirmation();
    }
  };

  const handleEmailChange = (value) => {
    if (!isValidEmail) {
      setIsValidEmail(true);
    }

    setEmail(value);
  };

  const {isExtension} = useEnvironmentContext();

  return (
    <div className={css.resetSection}>
      <div className={css.titleAndSubtitle}>
        {isExtension ? (
          <SubTitleMedium className={maincss.alignSelfCenter}>
            {CONSTANTS.RESET_PASSWORD_TITLE}
          </SubTitleMedium>
        ) : (
          <TitleMedium> {CONSTANTS.RESET_PASSWORD_TITLE} </TitleMedium>
        )}
        {isExtension ? (
          <BodySmall className={maincss.textAlignCenter}>
            {CONSTANTS.RESET_PASSWORD_SUBTEXT}
          </BodySmall>
        ) : (
          <BodyLarge>{CONSTANTS.RESET_PASSWORD_SUBTEXT}</BodyLarge>
        )}
      </div>
      <div>
        <Input
          label="Email"
          placeholder="Enter email"
          size="medium"
          type="text"
          value={email}
          error={!isValidEmail}
          errorText={CONSTANTS.INVALID_EMAIL}
          onBlur={validateUserEmail}
          onChange={(e) => handleEmailChange(e.target.value)}
        />
      </div>
      <Button
        size="medium"
        type="primary"
        onClick={handleReset}
        disabled={isSendingEmail}
      >
        {isSendingEmail ? 'Sending email' : 'Reset password'}
      </Button>

      {forgotPasswordError && (
        <InContextAlert
          semantic="danger"
          classNames={{wrapper: css.errorNotification}}
        >
          {ERROR_CODES[forgotPasswordError] || forgotPasswordError}
        </InContextAlert>
      )}
    </div>
  );
};

export default ForgotPassword;
