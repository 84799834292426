// @flow strict-local

export const isInternalUser = (): boolean => {
  const state = window.senseStore.reduxStore.getState();
  const {accounts} = state;
  if (accounts) {
    const {authedUserId} = accounts;
    if (accounts && authedUserId) {
      const userInfo = accounts.data[authedUserId];
      const {email} = userInfo;
      // return /admin_.*@sensehq/.test(email);
      return /.*@sensehq/.test(email);
    }
  }
  return false;
};
