// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {FilterRule} from 'src/types/audience-filter';
import type {EntityType} from 'src/types/ats-entities';
import type {
  AudienceListStatus,
  ListCreationType,
  ListApiResponse,
} from 'src/types/lists';
import type {AudienceListState} from 'src/reducers/audience-list.old';
import {
  createList,
  updateList,
  createAndPublishList,
} from 'src/action-creators/lists';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';
import {parseFilterRule, normalizeFilterRule} from 'src/utils/list';


export type ListFormSteps = 'details' | 'rules' | 'review' | 'copyLists';

export type UpdateStatePayload = $Shape<AudienceListState>;

export type UpdateListPayload = $Shape<{
  id: string,
  name: string,
  rules: FilterRule[],
  status: AudienceListStatus,
  all: boolean,
}>;
export type ReceiveListPayload = $Shape<{
  id: string,
  name: string,
  rules: FilterRule[],
  status: AudienceListStatus,
  target: string,
  entity_type: EntityType,
  life_cycle_goal: string,
  business_goal: string,
  all: boolean,
}>;

export const UPDATE_STATE = 'audience-list-old/update';
export const RECEIVE_LIST = 'audience-list-old/receive';
export const RECEIVE_UPDATED_LIST = 'audience-list-old/receive-updated';
export const GOTO_STEP = 'audience-list-old/goto-step';
export const RESET = 'audience-list-old/reset';
export const SET_CREATION_TYPE = 'audience-list-old/set-creation-type';
export const SET_RULES = 'audience-list-old/set-rules';
export const SET_ALL = 'audience-list-old/set-all';
export const RECEIVE_PUBLISHED_LIST_IDS =
  'audience-list-old/receive-published-list-ids';
//this action resets only the journey flow related fields which are there in the list
export const RESET_JOURNEY_FLOW_FIELDS =
  'audience-list-old/reset-journey-flow-fields';

type ReceiveListAction = {
  type: 'audience-list-old/receive',
  payload: ReceiveListPayload,
};
type ReceiveUpdatedListAction = {
  type: 'audience-list-old/receive-updated',
  payload: UpdateListPayload,
};

type UpdateStateAction = {
  type: 'audience-list-old/update',
  payload: UpdateStatePayload,
};

type GotoStepAction = {
  type: 'audience-list-old/goto-step',
  payload: ListFormSteps,
};
type ResetStateAction = {
  type: 'audience-list-old/reset',
};
type SetCreationAction = {
  type: 'audience-list-old/set-creation-type',
  payload: ?ListCreationType,
};
type SetRulesAction = {
  type: 'audience-list-old/set-rules',
  payload: FilterRule[],
};
type SetAllAction = {
  type: 'audience-list-old/set-all',
  payload: boolean,
};
type ReceivedPublishedListIdsAction = {
  type: 'audience-list-old/receive-published-list-ids',
  payload: string[],
};
type ResetJourneyFlowFields = {
  type: 'audience-list-old/reset-journey-flow-fields',
};

export type AudienceListAction =
  | ReceiveListAction
  | UpdateStateAction
  | GotoStepAction
  | ResetStateAction
  | SetCreationAction
  | SetRulesAction
  | SetAllAction
  | ReceivedPublishedListIdsAction
  | ReceiveUpdatedListAction
  | ResetJourneyFlowFields;
export const doUpdateState = (
  payload: UpdateStatePayload,
): UpdateStateAction => ({
  type: UPDATE_STATE,
  payload,
});

export const goToStep = (step: ListFormSteps): GotoStepAction => ({
  type: GOTO_STEP,
  payload: step,
});
export const setCreationType = (
  type: ?ListCreationType,
): SetCreationAction => ({type: SET_CREATION_TYPE, payload: type});
export const setRules = (rules: FilterRule[]): SetRulesAction => ({
  type: SET_RULES,
  payload: rules,
});
export const setAll = (all: boolean): SetAllAction => ({
  type: SET_ALL,
  payload: all,
});
export const reset = (): ResetStateAction => ({type: RESET});
export const updateState = (
  payload: UpdateStatePayload,
): UpdateStateAction => ({
  type: UPDATE_STATE,
  payload,
});
export const receivePublishedListIds = (
  payload: string[],
): ReceivedPublishedListIdsAction => ({
  type: RECEIVE_PUBLISHED_LIST_IDS,
  payload,
});
export const receiveUpdatedList = (
  payload: UpdateListPayload,
): ReceiveUpdatedListAction => ({
  type: RECEIVE_UPDATED_LIST,
  payload,
});
export const receiveList = (
  payload: ReceiveListPayload,
): ReceiveListAction => ({
  type: RECEIVE_LIST,
  payload,
});

export const resetJourneyFlowFields = (): ResetJourneyFlowFields => ({
  type: RESET_JOURNEY_FLOW_FIELDS,
});

export const createOrUpdateAudienceList: (
  is_internal: boolean,
  fieldsToTypes: {
    [string]: string,
  },
) => ThunkAction<ListApiResponse> =
  (is_internal, fieldsToTypes) => (dispatch, getState) => {
    const audienceList = getState().audienceListOld;
    const {
      id,
      name,
      entity_type,
      status,
      target,
      life_cycle_goal,
      business_goal,
      all,
    } = audienceList;
    const filter = normalizeFilterRule(audienceList.rules, all, fieldsToTypes);
    if (!id) {
      //create
      const apiPayload = {
        name: name.trim(),
        filter,
        entity_type,
        is_internal,
        target,
        life_cycle_goal,
        business_goal,
      };
      return dispatch(createList(apiPayload));
    } else {
      //update
      let apiPayload = {
        name: name.trim(),
      };
      //only filters in drafted lists can be updated
      apiPayload = {...apiPayload, filter};

      return dispatch(updateList(id, apiPayload));
    }
  };

export const createAndPublishAudienceList: (
  is_internal: boolean,
  fieldsToTypes: {
    [string]: string,
  },
) => ThunkAction<string> =
  (is_internal, fieldsToTypes) => async (dispatch, getState) => {
    const audienceList = getState().audienceListOld;
    const {name, entity_type, target, life_cycle_goal, business_goal, all} =
      audienceList;
    const filter = normalizeFilterRule(audienceList.rules, all, fieldsToTypes);
    //create
    const apiPayload = {
      name: name.trim(),
      filter,
      entity_type,
      is_internal,
      target,
      life_cycle_goal,
      business_goal,
    };
    return await dispatch(createAndPublishList(apiPayload));
  };

export const getList: (id: string) => ThunkAction<mixed> =
  (id) => (dispatch: Dispatch) =>
    dispatch(reduxApi.get(`audience-filter/${id}`)).then((payload) => {
      const {
        id,
        name,
        filter,
        status,
        entity_type,
        target,
        life_cycle_goal,
        business_goal,
      } = payload;
      const {all, rules} = parseFilterRule(filter);
      const updatePayload = {
        id,
        name,
        all,
        rules,
        status,
        target,
        life_cycle_goal,
        entity_type,
        business_goal,
      };
      dispatch(receiveList(updatePayload));
    });
export const getListForCopying: (id: string) => ThunkAction<mixed> =
  (id) => (dispatch: Dispatch) =>
    dispatch(reduxApi.get(`audience-filter/${id}`)).then((payload) => {
      //we dont update other fields such as id and name because only rules need
      //to be copied from one list to another. this function in future will take
      //list of listids and merge their rules and update in store
      const {filter} = payload;
      const {all, rules} = parseFilterRule(filter);
      const updatePayload = {
        all,
        rules,
      };
      dispatch(updateState(updatePayload));
    });
