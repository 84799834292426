// @flow

// $FlowFixMe[untyped-type-import]
import type {DueEvent} from 'src/api-parsers/events';
import type {ThunkAction} from 'src/reducers';

import {thunkify as flow} from 'src/utils/thunks';
import {camel} from 'src/utils';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api.js';


export const RECEIVE = 'dueEvents/receive';

export type ReceiveDueEventsAction = {
  type: 'dueEvents/receive',
  // $FlowFixMe[value-as-type] [v1.32.0]
  payload: DueEvent[],
};

export type DueEventsAction = ReceiveDueEventsAction;

export const receiveDueEvents = (
  // $FlowFixMe[value-as-type] [v1.32.0]
  dueEvents: DueEvent[],
): ReceiveDueEventsAction => ({
  type: RECEIVE,
  payload: dueEvents,
});

// TODO (kyle): this endpoint returns events attached to each due event. these
// should be merged into an event reducer and normalized.
export const getAudienceMemberDueEvents: (
  audienceMemberId: string,
) => ThunkAction<mixed> = flow(
  key((audienceMemberId) => `audienceMember/${audienceMemberId}/dueEvents`),
  cached((json) => receiveDueEvents(camel(json))),
  fetching(),
)((audienceMemberId: string) =>
  reduxApi.get(`audience-members/${audienceMemberId}/due-events`),
);
