// @noflow
import logger from 'src/utils/logger';


const BROWSER = typeof window !== 'undefined';

/**
 * Attach a key-returning function to the given function. The key is used by
 * the cached and fetching decorators, and must be added *before* them.
 */
export function key(key: Function): Function {
  return (func: Function): Function => {
    func.KEY = key;
    return func;
  };
}

/**
 * Cache an action.
 *
 * Handles resolving action calls that are already cached, and caching the
 * results of ones that aren't.
 */
export function cached({
  ttl = 60000,
  hashFunc,
}: {ttl: number, hash?: () => string} = {}): Function {
  return (func: Function) => {
    if (!func.KEY) {
      throw new Error('Must use key() decorator first before cached');
    }
    return key(func.KEY)(
      (store, ...args) =>
        new Promise((resolve, reject) => {
          const key = func.KEY.apply(this, args);
          const hash = hashFunc && hashFunc(...args);
          if (!store.cache.expired(key, ttl, hash)) {
            resolve();
          } else {
            func
              .apply(this, [store].concat(args))
              .then((result) => {
                store.cache.set(key, hash);
                resolve(result);
              })
              .catch((error) => reject(error));
          }
        }),
    );
  };
}

/**
 * Cache an action.
 *
 * Handles resolving action calls that are already cached, and caching the
 * results of ones that aren't.
 *
 * The key() for a function that uses this MUST match the following signature:
 * type dispatchActionKey = (dispatch: Dispatch, ...args: *[]) => string
 *
 * that said, you should probably not be using this.
 */
export function cachedWithDispatch({
  ttl = 60000,
  hashFunc,
}: {ttl: number, hash?: () => string} = {}): Function {
  return (func: Function) => {
    if (!func.KEY) {
      throw new Error('Must use key() decorator first before cached');
    }
    return key(func.KEY)(
      (store, dispatch, ...args) =>
        new Promise((resolve, reject) => {
          const keyVal = func.KEY.apply(this, [dispatch, ...args]);
          const hash = hashFunc && hashFunc(...args);
          if (!store.cache.expired(keyVal, ttl, hash)) {
            resolve();
          } else {
            func
              .apply(this, [store, dispatch, ...args])
              .then((result) => {
                store.cache.set(keyVal, hash);
                resolve(result);
              })
              .catch((error) => reject(error));
          }
        }),
    );
  };
}

/**
 * Batch an action and surface errors in global state.
 *
 * Handles preventing multiple concurrent calls to the same action by resolving
 * subsequent calls with the existing request promise. Puts any errors in the
 * global state so components can react to them.
 */
export function fetching(): Function {
  return (func: Function): Function => {
    if (!func.KEY) {
      throw new Error('Must use key() decorator first before fetching');
    }
    return key(func.KEY)((store, ...args) => {
      const keyVal = func.KEY.apply(this, args);
      const inProgressRequest = store.request.inProgress(keyVal);
      if (inProgressRequest) {
        return inProgressRequest;
      } else {
        const request = func
          .apply(this, [store].concat(args))
          .then((result) => {
            store.request.finish(keyVal);
            return result;
          })
          .catch((error) => {
            logger.error('error: ' + keyVal, error?.stack || error);
            store.request.error(keyVal);
            throw error;
          });

        store.request.start(keyVal, request);

        return request;
      }
    });
  };
}

/**
 * Redirect browser page to /signin if request throws Error: UNAUTHORIZED.
 */
export function authorized(): Function {
  return (func: Function): Function =>
    key(func.KEY)((...args) =>
      func.apply(this, args).catch((error) => {
        if (error.status && error.status === 401 && BROWSER) {
          window.location = '/signin';
        }
        throw error;
      }),
    );
}

/**
 * Suppresses and logs request errors so that they don't break code
 * the react render cycle.
 */
export function suppressed(): Function {
  return (func: Function): Function =>
    key(func.KEY)((...args) =>
      func.apply(this, args).catch((error) => {
        logger.error('error: ' + key, error.stack || error);
      }),
    );
}
