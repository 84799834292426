// @flow strict

import type {
  GenericError,
  SuccessApiResponse,
  SignUpResponse,
  UserDetails,
  AgencyList,
} from 'src/types/referrer-portal';
// $FlowFixMe[untyped-import]
import * as sentry from 'sentry';
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import {ERROR_CODE, DEFAULT_API_ERROR} from 'src/utils/referrer-portal';
// $FlowFixMe[untyped-import]
import {camel} from 'src/utils';
import urlParse from 'url-parse';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-referrer-portal.js';
import {AnalyticsService} from 'src/analytics';
import {unregisterAllServiceWorkers} from 'src/utils/service-workers';
// $FlowFixMe[untyped-import]
import IndexStore from 'src/stores/index';
import logger from 'src/utils/logger';
import {toastMessage} from 'src/components/referral-v2/tracking/referral-confirm-modal.jsx';
import {toastApi} from '@spaced-out/ui-design-system/lib/components/Toast';


export const USER_SIGNIN = 'user/signin';
export const USER_SIGNIN_ERROR = 'user/signin-error';

export const USER_SIGNUP = 'user/signup';
export const USER_SIGNUP_ERROR = 'user/signup-error';

export const USER_RESET_PASSWORD = 'user/reset-password';
export const USER_RESET_PASSWORD_ERROR = 'user/reset-password-error';

export const USER_AUTH = 'user/auth';

export const RECEIVE_AGENCY = 'receive/agency';
export const RECEIVE_AGENCY_ERROR = 'receive/agency-error';

export const RECEIVE_USER_DETAILS = 'receive/user-details';
export const RECEIVE_USER_DETAILS_ERROR = 'receive/user-details-error';

export const RECEIVE_NOTIFICATION = 'receive/notification';
export const RECEIVE_NOTIFICATION_ERROR = 'receive/notification-error';

type UserSigninAction = {
  type: typeof USER_SIGNIN,
  payload: SuccessApiResponse,
};

type UserSigninErrorAction = {
  type: typeof USER_SIGNIN_ERROR,
  payload?: ?string,
};

type UserSignUpAction = {
  type: typeof USER_SIGNUP,
  payload: SignUpResponse,
};

type UserSignUpErrorAction = {
  type: typeof USER_SIGNUP_ERROR,
  payload?: ?string,
};

type UserResetPasswordAction = {
  type: typeof USER_RESET_PASSWORD,
  payload: SuccessApiResponse,
};

type UserResetPasswordErrorAction = {
  type: typeof USER_RESET_PASSWORD_ERROR,
  payload?: ?string,
};

type UserAuthAction = {
  type: typeof USER_AUTH,
  payload: boolean,
};

type AgencyAction = {
  type: typeof RECEIVE_AGENCY,
  payload: AgencyList[],
};

type AgencyErrorAction = {
  type: typeof RECEIVE_AGENCY_ERROR,
  payload?: ?GenericError,
};

type UserDetailsAction = {
  type: typeof RECEIVE_USER_DETAILS,
  payload: UserDetails,
};

type UserDetailsErrorAction = {
  type: typeof RECEIVE_USER_DETAILS_ERROR,
  payload?: ?GenericError,
};

type NotificationAction = {
  type: typeof RECEIVE_NOTIFICATION,
  payload: {[string]: boolean},
};

type NotificationErrorAction = {
  type: typeof RECEIVE_NOTIFICATION_ERROR,
  payload?: ?GenericError,
};

export type AuthenticationActions =
  | UserSigninAction
  | UserSigninErrorAction
  | UserSignUpAction
  | UserSignUpErrorAction
  | UserResetPasswordAction
  | UserResetPasswordErrorAction
  | UserAuthAction
  | AgencyAction
  | AgencyErrorAction
  | UserDetailsAction
  | UserDetailsErrorAction
  | NotificationAction
  | NotificationErrorAction;

export const getAgencyError = (payload: GenericError): AgencyErrorAction => ({
  type: RECEIVE_AGENCY_ERROR,
  payload,
});

export const getUserDetailsError = (
  payload: GenericError,
): UserDetailsErrorAction => ({
  type: RECEIVE_USER_DETAILS_ERROR,
  payload,
});

export const getNotificationsError = (
  payload: GenericError,
): NotificationErrorAction => ({
  type: RECEIVE_NOTIFICATION_ERROR,
  payload,
});

export const userSignin: (payload: {
  email: string,
  password: string,
}) => ThunkAction<void> = flow(
  key(() => `auth/login`),
  fetching(),
)((payload) => async (dispatch: Dispatch) => {
  return dispatch(reduxApi.post(`auth/login`, payload)).then(
    (response: SuccessApiResponse) => {
      dispatch(performSignIn());
      return dispatch({type: USER_SIGNIN, payload: response});
    },
    (error) => {
      let apiError: string = 'Incorrect Email and Password';
      if (error?.responseBody?.errors && error.responseBody.errors[0]) {
        apiError = error.responseBody.errors[0];
      }
      dispatch({
        type: USER_SIGNIN_ERROR,
        payload: apiError,
      });
    },
  );
});

export const userSignUp: (payload: {
  email: string,
  password: string,
  name: string,
}) => ThunkAction<void> = flow(
  key(() => `auth/signup`),
  fetching(),
)((payload) => (dispatch: Dispatch) => {
  return dispatch(reduxApi.post(`auth/signup`, payload)).then(
    (response: SignUpResponse) => {
      dispatch({type: USER_SIGNUP, payload: response});
      dispatch(performSignIn(true));
    },
    (error) => {
      let apiError: string =
        'Failed to perform action, please try after sometime';
      if (error?.responseBody?.errors && error.responseBody.errors[0]) {
        apiError = error.responseBody.errors[0];
      }
      dispatch({
        type: USER_SIGNUP_ERROR,
        payload: apiError,
      });
    },
  );
});

export const userResetPassword: (payload: {
  reset_id: string,
  new_password: string,
  new_password_copy: string,
}) => ThunkAction<mixed> = flow(
  key(() => `auth/reset-password`),
  fetching(),
)((payload) => (dispatch: Dispatch) => {
  return dispatch(reduxApi.post(`auth/reset-password`, payload)).then(
    (response: SuccessApiResponse) => {
      dispatch({type: USER_RESET_PASSWORD, payload: response});
      return response;
    },
    (error) => {
      let apiError: string =
        'Failed to perform action, please try after sometime';
      if (
        error?.responseBody?.errors &&
        typeof error.responseBody.errors[0] === 'string'
      ) {
        apiError = error.responseBody.errors[0];
      }
      dispatch({
        type: USER_RESET_PASSWORD_ERROR,
        payload: apiError,
      });
    },
  );
});

export const getAllowedAgency: () => ThunkAction<void> = flow(
  key(() => `auth/agency/list`),
  fetching(),
)(() => (dispatch: Dispatch) => {
  return dispatch(reduxApi.get(`auth/agency/list`)).then(
    (response: AgencyList[]) => {
      dispatch({type: RECEIVE_AGENCY, payload: response});
    },
    (error) => {
      dispatch(getAgencyError(error?.response?.errors || DEFAULT_API_ERROR));
    },
  );
});

// $FlowFixMe[value-as-type]
export const getUserDetails: (store: IndexStore) => ThunkAction<void> = flow(
  key(() => `user`),
  fetching(),
)(
  (store) => (dispatch: Dispatch) =>
    dispatch(reduxApi.get(`user`)).then(
      (response: UserDetails) => {
        const user = camel(response);
        store?.me?.receiveFetched(user);
        dispatch({type: RECEIVE_USER_DETAILS, payload: response});
        dispatch({type: USER_AUTH, payload: true});
      },
      (error) => {
        dispatch(
          getUserDetailsError(error?.response?.errors || DEFAULT_API_ERROR),
        );
        store?.me?.receiveFetched(null);
      },
    ),
);

export const getNotification: () => ThunkAction<void> = flow(
  key(() => `user/nofitication-settings`),
  fetching(),
)(() => (dispatch: Dispatch) => {
  return dispatch(reduxApi.get(`user/nofitication-settings`)).then(
    (response: {[string]: boolean}) => {
      dispatch({type: RECEIVE_NOTIFICATION, payload: response});
    },
    (error) => {
      dispatch(
        getNotificationsError(error?.response?.errors || DEFAULT_API_ERROR),
      );
    },
  );
});

export const getAutoSubmitNotification: () => ThunkAction<{[string]: boolean}> =
  flow(
    key(() => `user/auto-submit-lead`),
    fetching(),
  )(() => (dispatch: Dispatch) => {
    return dispatch(reduxApi.get(`user/auto-submit-lead`)).then(
      (response: {[string]: boolean}) => {
        return response;
      },
    );
  });

export const updateNotification: (payload: {
  settings: {[string]: boolean},
}) => ThunkAction<void> = flow(
  key(() => `user/nofitication-settings`),
  fetching(),
)((payload) => (dispatch: Dispatch) => {
  return dispatch(reduxApi.post(`user/nofitication-settings`, payload)).then(
    (response: {[string]: boolean}) => {
      dispatch({type: RECEIVE_NOTIFICATION, payload: response});
      toastApi.show(
        toastMessage({
          message: 'Notification Updated Successfully',
        }),
      );
    },
    (error) => {
      toastApi.show(
        toastMessage({
          message: error?.responseBody?.message,
          semantic: 'danger',
        }),
      );
    },
  );
});

export const updateAutoSubmitNotification: (payload: {
  [string]: boolean,
}) => ThunkAction<?{[string]: boolean}> = flow(
  key(() => `user/auto-submit-lead`),
  fetching(),
)((payload) => (dispatch: Dispatch) => {
  return dispatch(reduxApi.put(`user/auto-submit-lead`, payload)).then(
    (response: {[string]: boolean}) => {
      toastApi.show(
        toastMessage({
          message: 'Notification Updated Successfully',
        }),
      );
      return response;
    },
    (error) => {
      toastApi.show(
        toastMessage({
          message: error?.responseBody?.message,
          semantic: 'danger',
        }),
      );
    },
  );
});

export const createPaymentDetails: (payload: {
  [string]: boolean | string | {[string]: string},
}) => ThunkAction<mixed> = flow(
  key(() => `user/payment-details`),
  fetching(),
)((payload) => (dispatch: Dispatch) => {
  return dispatch(reduxApi.post('user/payment-details', payload))
    .then((response) => {
      if (response) {
        toastApi.show(
          toastMessage({
            message: 'Payment details updated successfully',
          }),
        );
        return response;
      }
    })
    .catch((error) => {
      const apiError = error?.responseBody?.errors[0];
      const defaultError = 'Payment details not updated';
      toastApi.show(
        toastMessage({
          message:
            typeof apiError === 'string'
              ? ERROR_CODE[apiError] ?? defaultError
              : defaultError,
          semantic: 'danger',
        }),
      );
    });
});

// $FlowFixMe[value-as-type]
export const signOut: (store: IndexStore) => ThunkAction<void> = flow(
  key(() => `auth/logout`),
  fetching(),
  // $FlowFixMe[value-as-type]
)((store) => async (dispatch: Dispatch) => {
  unregisterAllServiceWorkers();
  store.me.clearAuth();
  sentry.configureScope((scope) => scope.clear());
  trackLogin('sign-out', {isSuccees: true});
  try {
    await dispatch(reduxApi.del('auth/logout'));
    store.history.replace('/signin');
    dispatch({type: USER_AUTH, payload: false});
  } catch {}
});

export const performSignIn: (fromSignup?: boolean) => ThunkAction<mixed> =
  (fromSignup = false) =>
  (): Promise<boolean> => {
    return new Promise(async (resolve) => {
      try {
        const {host, protocol} = window.location;
        const baseDomain = host.substring(host.indexOf('.') + 1);
        const query = urlParse(window.location, true).query;
        const slug = 'referrals';
        const nextPath = fromSignup
          ? '/dashboard/setup'
          : query.nextPathname
          ? query.nextPathname
          : '';
        const nextSearch = query.nextSearch || '';
        trackLogin('sign-in', {isSuccees: true});
        window.location = `${protocol}//${slug}.${baseDomain}${nextPath}${nextSearch}`;
        resolve(true);
      } catch (error) {
        trackLogin('sign-in', {isSuccees: false, error: JSON.stringify(error)});
        logger.error(error);
      }
    });
  };

function trackLogin(event, props) {
  try {
    AnalyticsService.track(event, props);
  } catch (error) {
    console.log('error: ', error);
  }
}
