// @flow

import type {State} from 'src/reducers';
import type {AllowedPhoneNumberResponseMap} from 'src/types/chat/allowed-phone-numbers';

import {createSelector} from 'reselect';

import {selectRecipients} from 'src/selectors/draft-messages';
import {selectMessagingRequireAtsSyncedCandidates} from 'src/selectors/agency';
import {selectPhoneForMessageHistory} from 'src/selectors/messages';
import {
  selectPhoneNumberSetsArray,
  selectOrderedPhoneNumberSetsArray,
  selectDefaultPhoneNumberSet,
  selectDisplayPhoneNumber,
  selectCurrentPhoneCountry,
  selectPhoneNumberSetFromDefaultPhoneNumberSet,
  selectChannelFromDefaultPhoneNumberSet,
} from 'src/selectors/phone-number-sets';
import {getVirtualContactPhoneNumber} from 'src/utils/contacts';
import {validatePhone} from 'src/utils/phone';
import {resolveDisplayPhoneNumber} from 'src/utils/phone-number-sets';
import {hasPrivilege} from 'src/utils/accounts';
import {selectIsQueueBasedInbox} from 'src/selectors/messaging-queues.js';
import {USE_MESSAGING, EDIT_CHATBOT} from 'src/types/roles';


export const canUseChatPreRelease = (state: State): boolean => {
  const hostname = (state.env && state.env.hostname) || '';
  return (
    // Allow in dev, and
    process.env.NODE_ENV !== 'production' ||
    // Allow for sense agency/subdomain on staging, and
    /\.stage-(ios|[123])\.sensehq\.co$/.test(hostname) ||
    // Allow for sense agency/subdomain on production
    'sense.sensehq.com' === hostname
  );
};

export {
  selectPhoneNumberSetsArray,
  selectOrderedPhoneNumberSetsArray,
  selectDefaultPhoneNumberSet,
  selectDisplayPhoneNumber,
  resolveDisplayPhoneNumber,
  selectCurrentPhoneCountry,
  selectPhoneNumberSetFromDefaultPhoneNumberSet,
  selectChannelFromDefaultPhoneNumberSet,
};

// NOTE (kyle): agent with non false-y phone has been specifically granted
// access to the messages app by the Sense team
export const agentHasPhone = (state: State): boolean =>
  Boolean(selectDisplayPhoneNumber(state));

export const canUseMsgPhoneOrLatInbox = (state: State): boolean => {
  const canUsePhoneInbox = hasPrivilege(state, USE_MESSAGING);
  const canUseLatInbox =
    (hasPrivilege(state, USE_MESSAGING) || hasPrivilege(state, EDIT_CHATBOT)) &&
    selectIsQueueBasedInbox(state);
  return canUsePhoneInbox || canUseLatInbox;
};

export const canUseMessaging = (state: State): boolean => {
  return hasPrivilege(state, USE_MESSAGING) && agentHasPhone(state);
};

export const canUseInboxEndpoint = (_state: State): boolean => true;

export const canUseWebSocket = (_state: State): boolean => true;

export const selectAllowedPhoneNumberResponses = (
  state: State,
): AllowedPhoneNumberResponseMap => state.chat.allowedPhoneNumbers.responses;

export const selectShouldCheckCurrentPhoneNumber = (
  state: State,
  phone: string,
): boolean => {
  const selectedPhone = selectPhoneForMessageHistory(state, phone);
  const requireAtsSyncedCandidates =
    selectMessagingRequireAtsSyncedCandidates(state);
  const allowedNumberResponses = selectAllowedPhoneNumberResponses(state);

  if (!requireAtsSyncedCandidates || !validatePhone(selectedPhone)) {
    return false;
  }

  const response = allowedNumberResponses[selectedPhone];

  return !response;
};

export const selectIsCurrentPhoneNumberAllowed: (State, string) => boolean =
  createSelector(
    selectPhoneForMessageHistory,
    selectMessagingRequireAtsSyncedCandidates,
    selectAllowedPhoneNumberResponses,
    (selectedPhone, requireAtsSyncedCandidates, allowedNumberResponses) => {
      if (!requireAtsSyncedCandidates) {
        return true;
      }

      const response = allowedNumberResponses[selectedPhone];

      return Boolean(response?.allowed);
    },
  );

export const selectRecipientPhoneNumbers: (State) => string[] = createSelector(
  selectRecipients,
  // $FlowFixMe[incompatible-call]
  (recipients) => recipients.map(getVirtualContactPhoneNumber),
);

// Recipients are disallowed if the messages_v2/allowed-numbers endpoint returns them with {allowed: false}
// at this time, we only need to check if the messagingRequiredAtsSyncedCandidates agency config is set
export const selectDisallowedRecipients: (State) => string[] = createSelector(
  selectRecipientPhoneNumbers,
  selectMessagingRequireAtsSyncedCandidates,
  selectAllowedPhoneNumberResponses,
  (recipientNumbers, requireAtsSyncedCandidates, allowedNumberResponses) => {
    if (!requireAtsSyncedCandidates) {
      return [];
    }

    const disallowed = [];

    for (const recipient of recipientNumbers) {
      const response = allowedNumberResponses[recipient];
      if (response && !response.allowed) {
        disallowed.push(recipient);
      }
    }

    return disallowed;
  },
);

// Returns recipients that have not yet been checked against the messages_v2/allowed-numbers endpoint
// at this time, we only need to check if the messagingRequiredAtsSyncedCandidates agency config is set
export const selectUncheckedRecipients: (State) => string[] = createSelector(
  selectRecipientPhoneNumbers,
  selectMessagingRequireAtsSyncedCandidates,
  selectAllowedPhoneNumberResponses,
  (recipientNumbers, requireAtsSyncedCandidates, allowedNumberResponses) => {
    if (!requireAtsSyncedCandidates) {
      return [];
    }

    const unchecked = [];

    for (const recipient of recipientNumbers) {
      const response = allowedNumberResponses[recipient];
      if (!response) {
        unchecked.push(recipient);
      }
    }

    return unchecked;
  },
);

export const selectAllowedMediaExtensions = (state: State): string[] =>
  state.chat.fileUpload.extensions;

export const selectAllowedMediaFilenameRegex: (State) => RegExp =
  createSelector(selectAllowedMediaExtensions, (extensions) => {
    // prepend a backslash to escape the dot at the start of each extension
    const escapedExtensions = extensions.map((ext) => '\\' + ext);

    const extensionUnion = escapedExtensions.join('|');

    // append $ to ensure the extension occurs at the end of the filename
    return RegExp(`(${extensionUnion})$`, 'i');
  });
