// @flow

/** NOTE: (diwakersurya)This file is a copy of redux-api-v2 mainly because we wanted
 * to fix some options for making calls to bff (a service unifying the response from
 * multi-service calls). Another reason for copying was to get access to the
 * response object in baseRequest and handle partial-status code 206 holistically
 * at least for bff calls
 */
import type {Dispatch, ThunkAction} from 'src/reducers';

import {
  base,
  type RawQuery,
  type RequestOptions,
  type ApiPostOptions,
  type ApiOptions,
} from './redux-api';
import {ApiError, PartialError, captureApiError} from 'src/utils/errors';

import * as reduxApi from 'src/utils/redux-api-v2';


export type BffOptions = {
  apiPath: string,
};

const bffOptions = {
  apiPath: '/bff/api/v1/',
};

export const get = (
  path: string,
  query?: RawQuery,
  options?: ApiOptions,
): ThunkAction<any> =>
  baseRequest(path, {
    method: 'GET',
    query,
    options: {...options, ...bffOptions},
  });

export const post = (
  path: string,
  data?: {...},
  query?: RawQuery,
  options?: ApiPostOptions,
): ThunkAction<any> =>
  baseRequest(path, {
    method: 'POST',
    data,
    query,
    options: {...options, ...bffOptions},
  });

export const patch = (
  path: string,
  data?: {...},
  query?: RawQuery,
  options?: ApiPostOptions,
): ThunkAction<any> =>
  baseRequest(path, {
    method: 'PATCH',
    data,
    query,
    options: {...options, ...bffOptions},
  });

export const put = (
  path: string,
  data?: {...},
  query?: RawQuery,
  options?: ApiPostOptions,
): ThunkAction<any> =>
  baseRequest(path, {
    method: 'PUT',
    data,
    query,
    options: {...options, ...bffOptions},
  });

export const del = (
  path: string,
  data?: {...},
  options?: ApiOptions,
): ThunkAction<any> =>
  baseRequest(path, {
    method: 'DELETE',
    data,
    options: {...options, ...bffOptions},
  });

export const baseRequest =
  (path: string, options: RequestOptions): ThunkAction<any> =>
  async (dispatch: Dispatch) => {
    const response = await dispatch(base(path, options));
    const text = await response.text();
    let json;
    if (response.headers.get('Content-Type') === 'application/json') {
      try {
        json = JSON.parse(text);
      } catch (error) {
        // NOTE (kyle): this should not happen.
        throw new ApiError({path, ...options}, response, text, error);
      }
    } else {
      json = text;
    }

    if (!response.ok) {
      throw new ApiError({path, ...options}, response, json);
    }
    //handle partial success responses
    if (response.status === 206) {
      throw new PartialError({path, ...options}, response, json);
    }

    return json;
  };
