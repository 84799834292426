// @flow strict

// $FlowFixMe[nonstrict-import]
import type {SelectOptions} from 'src/components/lib/select/select.jsx';

import * as React from 'react';
import {useDispatch} from 'react-redux';

import {classify} from 'src/utils/classify';
import {
  // $FlowFixMe[nonstrict-import]
  setFallbackNumberForCategory,
  getCategoryDetails,
} from 'src/action-creators/content-subscription';
// $FlowFixMe[nonstrict-import]
import {popModal} from 'src/action-creators/modal';
// $FlowFixMe[nonstrict-import]
import Select from 'src/components/lib/select/select.jsx';
import Button from 'src/components/lib/new-button';

import css from './default-number-for-category-modal.css';


export default function DefaultNumberForCategory({
  options,
  categoryId,
}: {
  options: SelectOptions<string>,
  categoryId: number,
}): React.Node {
  const [selectedFallbackNumber, setSelectedFallbackNumber] = React.useState();
  const dispatch = useDispatch();
  const close = () => dispatch(popModal());
  const onMakeDefault = async () => {
    if (selectedFallbackNumber?.value) {
      await dispatch(
        setFallbackNumberForCategory(categoryId, selectedFallbackNumber.value),
      );
      await dispatch(getCategoryDetails(String(categoryId)));
      close();
    }
  };

  return (
    <div className={classify(css.container)}>
      <div className={css.header}>
        <div className={css.title}>Select a fallback number for category</div>
      </div>

      <div className={css.form}>
        <span className={css.description}>
          Fallback numbers helps to send SMS, in case Agency numbers are not
          available. You can only select a fallback number from Agency Variable
          Number(s) and Agency Default Number
        </span>
        <Select
          className={css.select}
          placeholder="Select a phone number"
          options={options}
          value={selectedFallbackNumber ?? null}
          onChange={(val) => val && setSelectedFallbackNumber(val)}
        />
      </div>

      <div className={css.menu}>
        <Button className={css.cancelButton} onClick={close}>
          Cancel
        </Button>
        <Button
          className={css.nextButton}
          onClick={onMakeDefault}
          type="primary"
        >
          Set fallback
        </Button>
      </div>
    </div>
  );
}
