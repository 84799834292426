// @flow strict-local

import * as React from 'react';

import classify from 'src/utils/classify';

import css from './error-list.css';


type Props = {
  errors?: ?(string[]),
  className?: string,
};

const ErrorList = ({errors, className}: Props): React.Element<'ul'> => (
  <ul className={classify(css.errors, className)}>
    {errors && errors.map((error) => <li key={error}>{error}</li>)}
  </ul>
);

export default ErrorList;
