// @flow

import type {DynamicLabels, DynamicLabelExtra} from 'src/types/dynamic-labels';
import type {ThunkAction} from 'src/reducers';

import {thunkify as flow} from 'src/utils/thunks';
import {key, cached, fetching} from 'src/utils/redux';

import * as reduxApi from 'src/utils/redux-api-v2';

// TODO (kyle): remove and resolve all importers
export type {DynamicLabels, DynamicLabelExtra};

export type DynamicLabelReceiveAction = {
  type: 'dynamicLabels/receive all',
  payload: DynamicLabels,
};
export type DynamicLabelAction = DynamicLabelReceiveAction;

export const RECEIVE_ALL = 'dynamicLabels/receive all';

type ReceiveActionCreator = (
  payload: DynamicLabels,
) => DynamicLabelReceiveAction;
export const receiveAction: ReceiveActionCreator = (payload) => ({
  type: RECEIVE_ALL,
  payload,
});

export const getDynamicVariablesList: () => ThunkAction<mixed> = flow(
  key(() => 'dynamic variables'),
  cached((list) => receiveAction(list), {ttl: 2000}),
  fetching({}),
)(() => reduxApi.get('dynamic-labels'));
