// @flow strict
import * as React from 'react';
import {classify} from 'src/utils/classify';

import {Clickable} from 'src/designSystem2021Components/text-v2.jsx';

import {LabeledCheckbox} from 'src/designSystem2021Components/checkbox.jsx';
import {Radio} from 'src/designSystem2021Components/radio/radio.jsx';
import {Link} from 'src/rerouter';

import css from './options.css';


type OptionsClassNames = $ReadOnly<{
  options?: ?string,
  down?: ?string,
  up?: ?string,
  showAll?: ?string,
  option?: ?string,
}>;

type OptionsDirection = 'up' | 'down';

export type OptionProps<V> = {
  className?: ?string,
  option: V,
  options: V[],
  isSelected: boolean,
  onSelect: (V) => mixed,
  resolveLabel: (V) => string | React.Node,
  resolveValue: (V) => string,
  resolveIcon?: (V) => React.Node,
  disabled?: boolean,
};

type CommonOptionsProps<V> = {
  value?: string[] | string,
  baseClassNames?: OptionsClassNames,
  classNames?: OptionsClassNames,
  onSelect: (V) => mixed,
  resolveValue: (V) => string,
  resolveLabel: (V) => string | React.Node,
  resolveIcon?: (V) => React.Node,
  resolveDisabled?: (V, V[]) => boolean,
};

export function Options<V>({
  options,
  baseClassNames = css,
  classNames,
  direction = 'down',
  showAll,
  OptionComponent = DefaultOption,
  value,
  onSelect,
  resolveValue,
  resolveLabel,
  resolveIcon,
  resolveDisabled = (_, __) => false,
  shadow = true,
}: {
  ...CommonOptionsProps<V>,
  OptionComponent?: React.ComponentType<OptionProps<V>>,
  options: V[],
  direction?: OptionsDirection,
  showAll?: mixed,
  shadow?: boolean,
}): React.Node {
  const normalizedValue =
    value != null ? (Array.isArray(value) ? value : [value]) : [];
  return (
    <div
      className={classify(
        baseClassNames.options,
        baseClassNames[direction],
        classNames?.options,
        showAll && baseClassNames.showAll,
        {[css.shadow]: shadow},
      )}
    >
      {options.map((option) => {
        const isSelected = normalizedValue.includes(resolveValue(option));
        return (
          <OptionComponent
            key={resolveValue(option)}
            className={classNames?.option}
            onSelect={onSelect}
            options={options}
            option={option}
            isSelected={isSelected}
            resolveLabel={resolveLabel}
            resolveValue={resolveValue}
            resolveIcon={resolveIcon}
            disabled={resolveDisabled(option, options)}
          />
        );
      })}
    </div>
  );
}

export const DefaultOption = <V>({
  option,
  isSelected,
  resolveLabel,
  resolveIcon,
  onSelect,
  className,
  disabled = false,
}: OptionProps<V>): React.Node => {
  const label = resolveLabel(option);
  const icon = resolveIcon ? resolveIcon(option) : null;
  const handleSelect = React.useCallback(
    (_) => {
      onSelect(option);
    },
    [option, onSelect],
  );
  return (
    <div
      className={classify(css.option, className, {
        [css.selected]: isSelected,
        [css.disabled]: disabled,
      })}
      onClick={disabled ? null : handleSelect}
    >
      {Boolean(icon) && <div>{icon}</div>}
      <Clickable>{label}</Clickable>
    </div>
  );
};

export const CheckboxOption = <V>({
  option,
  isSelected,
  resolveLabel,
  onSelect,
  className,
  disabled = false,
}: OptionProps<V>): React.Node => {
  const label = resolveLabel(option);
  const handleChange = React.useCallback(
    (_) => {
      onSelect(option);
    },
    [option, onSelect],
  );
  return (
    <>
      {label ? (
        <LabeledCheckbox
          onChange={handleChange}
          checked={isSelected ? 'true' : 'false'}
          className={classify(className)}
          disabled={disabled}
        >
          {label}
        </LabeledCheckbox>
      ) : null}
    </>
  );
};

export const LinkOption = <V>({
  option,
  className,
  resolveLabel,
  resolveValue,
  resolveIcon,
  disabled = false,
}: OptionProps<V>): React.Node => {
  const label = resolveLabel(option);
  const value = resolveValue(option);
  const icon = resolveIcon ? resolveIcon(option) : null;

  return (
    <Link
      className={classify(css.option, className, {[css.disabled]: disabled})}
      to={value}
    >
      {' '}
      {Boolean(icon) && <div>{icon}</div>} <Clickable>{label}</Clickable>
    </Link>
  );
};

export const RadioOption = <V>({
  option,
  isSelected,
  resolveLabel,
  resolveValue,
  onSelect,
  className,
  disabled = false,
}: OptionProps<V>): React.Node => {
  const label = resolveLabel(option);
  const handleChange = React.useCallback(
    (_) => {
      onSelect(option);
    },
    [option, onSelect],
  );
  return (
    <div
      className={classify(css.option, css.radioOption, className, {
        [css.selected]: isSelected,
      })}
    >
      <Radio
        value={resolveValue(option)}
        checked={isSelected ? true : false}
        onChange={handleChange}
        disabled={disabled}
      >
        {label}
      </Radio>
    </div>
  );
};
