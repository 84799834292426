// @flow strict

import * as React from 'react';
// $FlowFixMe[nonstrict-import]
import type {Sidepanel} from 'src/action-creators/modal';
import {Header} from 'src/designSystem2021Components/text-v2.jsx';
import CloseIcon from 'src/images/designSystems2021/close.svg';

import classify from 'src/utils/classify';

import css from 'src/components/modals/generic-sidepanel.css';


const GenericSidepanel = (props: Sidepanel): React.Node => {
  const {
    className,
    direction,
    width,
    title,
    removeModal,
    children,
    showClear = true,
  } = props;
  return (
    <div
      className={classify(className, css.sidepanelContainer, {
        [css.left]: direction === 'left',
        [css.right]: direction === 'right',
      })}
      style={width ? {width} : {}}
    >
      {!!title && (
        <div className={classify(css.headerContainer)}>
          {typeof title === 'string' ? (
            <Header className={css.title}>{title}</Header>
          ) : (
            title
          )}
          {showClear ? (
            <div
              className={css.closeIconContainer}
              onClick={() => {
                removeModal && removeModal();
              }}
            >
              <CloseIcon className={css.closeIcon} />
            </div>
          ) : null}
        </div>
      )}

      {children}
    </div>
  );
};

export default GenericSidepanel;
