// @noflow

import ie from 'src/utils/ie';


let uniqueId = Date.now();

const noCache = request => {
  request.set('X-Requested-With', 'XMLHttpRequest');
  request.set('Expires', '-1');
  request.set(
    'Cache-Control',
    'no-cache,no-store,must-revalidate,max-age=-1,private',
  );

  if (ie === 11) {
    const param = `_=${uniqueId}`;
    if (request._query !== undefined && request._query[0]) {
      request._query[0] += `&${param}`;
    } else {
      request._query = [param];
    }
    uniqueId++;
  }

  return request;
};

export default noCache;

export function ie11Query(query?: {}) {
  return {...query, _: uniqueId++};
}

// TODO (kyle): reexamine whether this is necessary or desired when using the fetch api
export function noCacheHeaders(headers: {}) {
  return {
    ...headers,
    'X-Requested-With': 'XMLHttpRequest',
    Expires: '-1',
    'Cache-Control': 'no-cache,no-store,must-revalidate,max-age-1,private',
  };
}
