// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {
  ProductCatalogItem,
  SelectedProduct,
  GetMetricsQuery,
  HiringVelocityMetrics,
  Metric,
  ChartData,
  RoiTrendQuery,
} from 'src/types/analytics-genesis';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {fetching, key} from 'src/utils/redux';


export const RECEIVE_PRODUCT_CATALOG_LIST = 'product-catalog-list/receive';
export const RECEIVE_SUMMARY_METRICS = 'summary-metrics-roi/receive';
export const RECEIVE_HIRING_METRICS = 'hiring-metrics-roi/receive';
export const RECEIVE_ROI_TREND = 'roi-trend/receive';

/* Storing current dashboard might not be required in further iterations,
will remove accordingly */
export const RECEIVE_CURRENT_PRODUCT_LIST_ITEM =
  'current-product-list-item/receive';

type ReceiveProductCatalogList = {
  type: 'product-catalog-list/receive',
  payload: {productCatalogList: Array<ProductCatalogItem>},
};

type ReceiveCurrentProductListItem = {
  type: 'current-product-list-item/receive',
  payload: {currentProductListItem: SelectedProduct},
};

type ReceiveSummaryMetrics = {
  type: 'summary-metrics-roi/receive',
  payload: Array<Metric>,
};

type ReceiveHiringMetrics = {
  type: 'hiring-metrics-roi/receive',
  payload: HiringVelocityMetrics,
};

type ReceiveRoiTrend = {
  type: 'roi-trend/receive',
  payload: ChartData,
};

export type AnalyticsGenesisActions =
  | ReceiveProductCatalogList
  | ReceiveCurrentProductListItem
  | ReceiveSummaryMetrics
  | ReceiveHiringMetrics
  | ReceiveRoiTrend;

const receiveProductCatalogList = (payload: {
  productCatalogList: Array<ProductCatalogItem>,
}): ReceiveProductCatalogList => ({
  type: RECEIVE_PRODUCT_CATALOG_LIST,
  payload,
});

export const receiveSummaryMetrics = (
  summaryMetrics: Array<Metric>,
): ReceiveSummaryMetrics => ({
  type: RECEIVE_SUMMARY_METRICS,
  payload: summaryMetrics,
});

export const receiveHiringMetrics = (
  hiringMetrics: HiringVelocityMetrics,
): ReceiveHiringMetrics => ({
  type: RECEIVE_HIRING_METRICS,
  payload: hiringMetrics,
});

const receiveCurrentProductListItem = (payload: {
  currentProductListItem: SelectedProduct,
}): ReceiveCurrentProductListItem => ({
  type: RECEIVE_CURRENT_PRODUCT_LIST_ITEM,
  payload,
});

const receiveRoiTrend = (payload: ChartData): ReceiveRoiTrend => ({
  type: RECEIVE_ROI_TREND,
  payload,
});

export const getCatalogList: () => ThunkAction<mixed> = flow(
  key(() => `getCatalogProductList`),
  fetching(),
)(
  () => (dispatch: Dispatch) =>
    dispatch(
      reduxApi.get(
        `analytics/product-dashboards`,
        {},
        {
          apiPath: '/api/v3/',
        },
      ),
    )
      .then((payload) => {
        dispatch(
          receiveProductCatalogList({
            productCatalogList: payload.products ?? [],
          }),
        );
      })
      .catch((err) => {
        console.error(err);
      }),
);

export const setCurrentProductListItem: (
  currentProductListItem: SelectedProduct,
) => ThunkAction<mixed> = (currentProductListItem) => (dispatch: Dispatch) =>
  dispatch(receiveCurrentProductListItem({currentProductListItem}));

export const getCandidateSummaryMetrics: (
  query: GetMetricsQuery,
) => ThunkAction<mixed> = flow(
  key(() => `getCandidateSummaryMetrics`),
  fetching(),
)(
  (query) => (dispatch: Dispatch) =>
    dispatch(
      //$FlowFixMe[incompatible-call]
      reduxApi.get(`analytics/roi/summary`, query, {
        apiPath: '/api/v4/',
      }),
    )
      .then((payload) => {
        dispatch(receiveSummaryMetrics(payload));
      })
      .catch((err) => {
        console.error(err);
      }),
);

export const getCandidateHiringMetrics: (
  query: GetMetricsQuery,
) => ThunkAction<mixed> = flow(
  key(() => `getCandidateHiringMetrics`),
  fetching(),
)(
  (query) => (dispatch: Dispatch) =>
    dispatch(
      //$FlowFixMe[incompatible-call]
      reduxApi.get(`analytics/roi/hiring-velocity`, query, {
        apiPath: '/api/v4/',
      }),
    )
      .then((payload) => {
        dispatch(receiveHiringMetrics(payload));
      })
      .catch((err) => {
        console.error(err);
      }),
);

export const getRoiTrend: (query: RoiTrendQuery) => ThunkAction<mixed> = flow(
  key(() => `getRoiTrend`),
  fetching(),
)(
  (query) => (dispatch: Dispatch) =>
    dispatch(
      reduxApi.get(`analytics/roi/trend`, query, {
        apiPath: '/api/v4/',
      }),
    )
      .then((payload) => {
        dispatch(receiveRoiTrend(payload));
      })
      .catch((err) => {
        console.error(err);
      }),
);
