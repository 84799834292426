// @flow strict-local

import type {ModalAction} from 'src/action-creators/modal';
import type {ModalStateEntry} from 'src/types/modal';

import {
  PUSH_MODAL,
  POP_MODAL,
  REPLACE_MODAL,
  SET_ERROR_MODAL,
  REMOVE_ERROR_MODAL,
  PUSH_SIDEPANEL,
} from 'src/action-creators/modal';


export type ModalState = {
  entries: ModalStateEntry[],
  error: ?ModalStateEntry,
};

const initialState = {
  entries: [],
  error: null,
};

export default (
  state: ModalState = initialState,
  action: ModalAction,
): ModalState => {
  switch (action.type) {
    case PUSH_MODAL:
      return {
        ...state,
        entries: [
          ...state.entries,
          action.payload.render
            ? action.payload
            : {
                type: action.payload.type,
                props: action.payload,
              },
        ],
      };
    case REPLACE_MODAL:
      return {
        ...state,
        entries: [
          ...state.entries.slice(0, -1),
          action.payload.render
            ? action.payload
            : {
                type: action.payload.type,
                props: action.payload,
              },
        ],
      };
    case POP_MODAL:
      return {
        ...state,
        entries: state.entries.slice(0, -1),
      };
    case SET_ERROR_MODAL:
      return {
        ...state,
        error: {
          type: action.payload.type,
          props: action.payload,
        },
      };
    case REMOVE_ERROR_MODAL:
      return {
        ...state,
        error: null,
      };
    case PUSH_SIDEPANEL:
      return {
        ...state,
        entries: [
          ...state.entries,
          {
            type: action.payload.type,
            props: action.payload,
          },
        ],
      };
  }
  return state;
};
