// @flow strict

//$FlowFixMe[untyped-import]
//$FlowFixMe[no-unresolved]
import zipy from 'zipyai';


const ZIPY_TOKEN_KEY = {
  PRODUCTION: '334a9998',
  DEVELOPMENT: '12d73c46',
};

const loadService = (ENV: string) => {
  const KEY = ZIPY_TOKEN_KEY[ENV];
  if (zipy) {
    zipy.init(KEY);
  }
};

const identify = (id: string, agencyName: string) => {
  zipy.identify(id, {
    customerName: agencyName,
  });
};

const signOut = () => {
  zipy.anonymize();
};

// Public functions
const init = (ENV: string) => {
  loadService(ENV);
};

export const Zipy = {
  init,
  identify,
  signOut,
};
