// @flow strict

import * as React from 'react';
import {useParams} from 'src/rerouter';
//$FlowFixMe
import {useApi} from 'src/hooks/useApi';
import {Loading} from 'src/components/lib/loading';
import {Header} from 'src/designSystem2021Components/text-v2.jsx';

import css from './mark-task-completion.css';
import maincss from 'src/components/agency-onboard/agency-onboard.css';


export const MarkTaskCompletion = (): React.Element<'div'> => {
  const {taskId, token} = useParams();

  const apiRequest = useApi(`onboard/task/${taskId}/approve`, {
    method: 'POST',
    data: {
      approval_token: token,
    },
  });

  if (apiRequest.isLoading) {
    return (
      <div className={css.mainContainer}>
        <div className={css.container}>
          <Header className={css.approvingText}>Approving Task</Header>
          <div className={maincss.loaderDiv}>
            <Loading />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={css.mainContainer}>
      <div className={css.container}>
        {apiRequest.error && (
          <div>
            <Header>
              Error Approving Task, Please try after sometime
              <br />
              If Issue persists, connect with the support team.
            </Header>
          </div>
        )}
        {apiRequest.result && (
          <div>
            <Header>
              {apiRequest.result.task_name} task is {apiRequest.result.status}
            </Header>
          </div>
        )}
      </div>
    </div>
  );
};
