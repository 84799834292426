// @flow

import type {Account, AuthedUserAccount} from 'src/types/account';
import type {State} from 'src/reducers';
import type {Privilege} from 'src/action-creators/roles';

import {createSelector} from 'reselect';
import get from 'lodash/get';

import {EDIT_SETTINGS} from 'src/action-creators/roles';


export const getCurrentAgent = ({
  accounts,
}: State): ?(Account | AuthedUserAccount) =>
  accounts.authedUserId ? accounts.data[accounts.authedUserId] : null;

export const selectCurrentAccount = getCurrentAgent;

export const selectIsAuthed = ({accounts}: State): boolean => accounts.isAuthed;

export const getAllAgents = ({
  accounts,
}: State): {[key: string]: Account | AuthedUserAccount} => accounts.data;

export const getAgentsByAgentIds: (
  state: State,
  agentIds: string[],
) => (Account | AuthedUserAccount)[] = createSelector(
  getAllAgents,
  (_state, agentIds) => agentIds,
  (allAgents: {[key: string]: Account | AuthedUserAccount}, agentIds) => {
    const results = agentIds.map((agentId) => allAgents[agentId]);
    return results;
  },
);

export const getCanEditSettings: (state: State) => {} = createSelector(
  getCurrentAgent,
  (currentAgent) =>
    get(currentAgent, ['securityRole', 'privileges'], []).includes(
      EDIT_SETTINGS,
    ),
);

export const getAccountPrivileges: (state: State) => ?(Privilege[]) =
  createSelector(
    getCurrentAgent,
    (currentAgent) => currentAgent?.securityRole.privileges,
  );
