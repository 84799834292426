var React = require('react');

function CircledPlusSignFilled (props) {
    return React.createElement("svg",props,React.createElement("g",{"transform":"translate(0 -2)","fillRule":"evenodd"},[React.createElement("circle",{"fillOpacity":".555","cx":"12","cy":"14","r":"12","key":0}),React.createElement("text",{"fontFamily":"ElenaBasic-Bold, Elena Basic","fontSize":"22","fontWeight":"bold","key":1},React.createElement("tspan",{"x":"6","y":"20"},"+"))]));
}

CircledPlusSignFilled.defaultProps = {"width":"24","height":"24","viewBox":"0 0 24 24"};

module.exports = CircledPlusSignFilled;

CircledPlusSignFilled.default = CircledPlusSignFilled;
