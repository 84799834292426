// @flow strict

import * as React from 'react';

import Eyedropper from 'src/images/eyedropper.svg';

import css from './color-picker.css';


type Props = {
  onChange: (value: string, name?: string) => mixed,
  // WARNING (marcos): if you need `event.target.name` for anything in the
  // use the `onChange` function's second parameter `name`.
  name?: string,
  value?: string,
  defaultValue?: string,
  className?: string,
};

export default class ColorPicker extends React.Component<
  Props,
  {
    value: string,
    textInputValue: string,
  },
> {
  static defaultProps: {className: string, onChange: () => void} = {
    onChange: () => {}, // eslint-disable-line no-empty-function
    className: css.picker,
  };

  constructor(props: Props) {
    super(props);

    const value = props.value || props.defaultValue || '';
    this.state = {
      value,
      textInputValue: value,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.isUncontrolled() && this.props.value !== prevProps.value) {
      this.setState({
        textInputValue: this.props.value,
      });
    }
  }

  render(): React.Element<'div'> {
    const {name, className} = this.props;
    const {textInputValue} = this.state;

    const virtualValue = this.isUncontrolled()
      ? this.state.value
      : this.props.value;

    return (
      <div className={className}>
        <label className={css.label}>
          <input
            type="color"
            name={name}
            value={virtualValue}
            onChange={this.handleChange}
          />
          <div
            className={css.preview}
            style={{backgroundColor: virtualValue || '#666666'}}
          />
          <Eyedropper />
        </label>
        <input
          type="text"
          className={css.text}
          value={textInputValue}
          onChange={this.handleTextChange}
          placeholder="Enter hex value"
        />
      </div>
    );
  }

  _handleChange(event: SyntheticInputEvent<HTMLInputElement>) {
    const color = event.target.value;

    if (this.isUncontrolled()) {
      this.setState({
        value: color,
      });
    }

    this.setState({
      textInputValue: color,
    });

    this.props.onChange(color, this.props.name);
  }
  // $FlowFixMe[method-unbinding]
  handleChange: $FlowFixMe = this._handleChange.bind(this);

  _handleTextChange(event: SyntheticInputEvent<HTMLInputElement>) {
    const color = event.target.value;
    this.setState({
      textInputValue: color,
    });

    if (!color || color.match(/^#[0-9a-f-A-F]{6}/) || color === '') {
      if (this.isUncontrolled()) {
        this.setState(
          {
            value: color,
          },
          () => this.props.onChange(color, this.props.name),
        );
      } else {
        this.props.onChange(color, this.props.name);
      }
    }
  }
  // $FlowFixMe[method-unbinding]
  handleTextChange: $FlowFixMe = this._handleTextChange.bind(this);

  isUncontrolled(): boolean {
    return !('value' in this.props);
  }

  getValue(): string {
    return this.state.value;
  }
}
