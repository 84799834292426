// @flow strict
import * as React from 'react';
import {classify} from 'src/utils/classify';

import {Card} from 'src/designSystem2021Components/card.jsx';
import {Clickable} from 'src/designSystem2021Components/text-v2.jsx';

import makeClassNameComponent, {
  type ClassNameComponent,
} from 'src/utils/makeClassNameComponent';

import css from './menu.css';


export type Option = {
  key: string,
  label: string,
};

export type Props = {
  options: Array<Option>,
  onSelected: (option: Option) => mixed,
  selectedOption: Option,
  className?: string,
};

const OptionContainer: ClassNameComponent<> = makeClassNameComponent(
  css.option,
);

export const Menu = (props: Props): React.Node => {
  const {options, onSelected, selectedOption, className} = props;

  return (
    <Card className={classify(css.menuCard, className)} type="secondary">
      <div className={css.options}>
        {options.map(({key, label}) => (
          <OptionContainer
            className={classify({
              [css.selected]: key === selectedOption.key,
            })}
            key={key}
            onClick={() => onSelected({key, label})}
          >
            <Clickable>{label}</Clickable>
          </OptionContainer>
        ))}
      </div>
    </Card>
  );
};
