// @noflow

import type {Event, EventTemplate} from 'src/api-parsers/events';
import type {Question, Branch} from 'src/types/survey';

import omit from 'lodash/omit';

import Store from './base';


type SaveError = 'schedule' | 'event' | 'branch';
export type BranchInfo = {
  isNew: boolean,
  editorBranch: Branch,
  questionId: string,
  branch: Branch,
};

export default class EventCreationStore extends Store {
  state: {
    event: ?Event,
    template: ?Object,
    question: ?Question,
    questionError: ?Object,
    branchInfo: ?BranchInfo,
    events: {
      [key: string]: string, // contains editing ids for events
    },
    fetching: {
      [key: string]: Promise<Event>, // contains a boolean for some event's id
    },
    saveError: ?SaveError,
    name?: string,
  };

  constructor() {
    super('event-creation');

    this.state = {
      event: null,
      template: null,
      question: null,
      questionError: null,
      branchInfo: null,
      events: {},
      fetching: {},
      saveError: null,
      name: null,
    };
  }

  getEditingIds() {
    return Object.keys(this.state.events).map(eventId => this.state[eventId]);
  }

  get(eventId: string): string {
    return this.state.events[eventId];
  }

  remove(eventId: string) {
    const events = omit(this.state.events, eventId);
    this.setState({
      events,
    });
  }

  startFetching(event: Promise<Event>) {
    this.setState({
      fetching: event,
    });
  }

  receiveEvent(backingId: string, editEvent: Event) {
    this.setState({
      events: {
        [backingId]: editEvent.id,
      },
      fetching: null,
    });
  }

  setTemplate(template: EventTemplate) {
    this.setState({
      template,
    });
  }

  setError(error: SaveError) {
    this.setState({
      saveError: error,
    });
  }

  clearError() {
    this.setState({
      saveError: null,
    });
  }
}
