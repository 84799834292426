// @noflow

import type {Contact} from 'src/types/contacts';

import * as React from 'react';
import _get from 'lodash/get';

import {getErrors} from 'src/utils/validation';

import ErrorInput from 'src/components/lib/error-input';
import PhoneInput from 'src/components/lib/phone-input';

import css from './editor.css';


const ContactEditor = ({
  contact,
  errors,
  onChange,
  onSubmit,
  onCancel,
  saveLabel = 'Save',
}: {
  contact: Contact,
  errors: Object,
  onChange: (propName: string, value: string) => void,
  onSubmit: (event: SyntheticEvent<HTMLFormElement>) => void,
  onCancel: () => void,
  saveLabel?: string,
}) => (
  <form className={css.form} onSubmit={onSubmit}>
    <div className={css.row}>
      <Field propName="First Name">
        <ErrorInput
          type="text"
          className={css.input}
          name="firstName"
          value={contact.firstName || ''}
          onChange={({currentTarget: {value}}) => onChange('firstName', value)}
          errors={getErrors(errors, 'firstName')}
        />
      </Field>
      <Field propName="Last Name">
        <ErrorInput
          type="text"
          className={css.input}
          name="lastName"
          value={contact.lastName || ''}
          onChange={({currentTarget: {value}}) => onChange('lastName', value)}
          errors={getErrors(errors, 'lastName')}
        />
      </Field>
      <Field propName="phone">
        <PhoneInput
          className={css.input}
          name="phone"
          value={contact.phone}
          onChange={value => onChange('phone', value)}
          errors={getErrors(errors, 'phone')}
        />
      </Field>
    </div>
    <div className={css.row}>
      <Field propName="email">
        <ErrorInput
          type="email"
          className={css.input}
          name="email"
          value={contact.email || ''}
          onChange={({currentTarget: {value}}) => onChange('email', value)}
          errors={getErrors(errors, 'email')}
        />
      </Field>
    </div>
    <div className={css.actions}>
      <button className={css.cancel} onClick={onCancel} type="button">
        Cancel
      </button>
      <button className={css.submit} disabled={Boolean(errors)}>
        {saveLabel}
      </button>
    </div>
  </form>
);

export default ContactEditor;

const Field = ({
  propName,
  children,
}: {
  propName: string,
  children: React.Node,
}) => (
  <label>
    <span className={css.label}>{propName}</span>
    {children}
  </label>
);
