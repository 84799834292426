// @noflow
import type {EventTemplate} from 'src/api-parsers/events';
import typeof {IndexStore} from 'src/stores/index';

import * as React from 'react';
import unescape from 'lodash/unescape';

import FluxComponent from 'src/flux/component.jsx';
import Loadable from 'src/components/lib/loadable';
import Loading from 'src/components/lib/loading/loading.jsx';
//import CodeEditor from 'src/components/lib/code-editor';

import {updateEventTemplate} from 'src/actions/event-template';
import I18nContext from 'src/contexts/i18n-context';

import css from './edit.css';


type EditorProps = {
  template: EventTemplate,
  store: IndexStore,
  router: any, // typeof router?
};
type EditorState = {
  disabled: boolean,
  template: EventTemplate,
};
class TemplateEditor extends React.Component<EditorProps, EditorState> {
  static contextType = I18nContext;
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      template: this.props.template,
    };

    (this: any).handleFormUpdate = this.handleFormUpdate.bind(this);
    (this: any).handleSave = this.handleSave.bind(this);
  }

  render() {
    const {template} = this.state;

    // This is very conservative, but possible if somebody (not the template listing page)
    // routes to this page somehow. There isn't a case where that happens right now, but
    // this is here to be safe all the same.
    if (!template) {
      return <Loading />;
    }

    const {html, debugEventHtml, name} = template;

    const templateContent = html === null ? unescape(debugEventHtml) : html;
    const {labelI18nInstance} = this.context;

    return (
      <SimpleTemplateEditor
        html={templateContent}
        onHTMLChange={this.handleFormUpdate('html')}
        name={name}
        onNameChange={this.handleFormUpdate('name')}
        onSave={this.handleSave}
        disabled={this.state.disabled}
        readOnly={template.templateType === 'builtin'}
        templateId={template.id}
        templateNameLabel={labelI18nInstance.t(
          'template_name',
          'Template Name',
        )}
        emailTemplatesLabel={labelI18nInstance.t(
          'sense_templates',
          'Sense templates',
        )}
      />
    );
  }

  handleFormUpdate(key) {
    return (newVal: string) => {
      this.setState({
        template: {
          ...this.state.template,
          [key]: newVal,
        },
      });
    };
  }

  getTemplate() {
    return this.state.template;
  }

  handleSave() {
    const {template} = this.props;
    this.setState({disabled: true});
    updateEventTemplate(this.props.store, this.props.template)
      .then(
        () => {
          this.setState({disabled: false});
          this.props.router.push(`/settings/templates/${template.id}/`);
        },
        () => {
          // If we get an error, re-enabled the form
          this.setState({disabled: false});
        },
      )
      .catch(() => {
        // catch an error more significantly here
      });
  }
}

export default FluxComponent(TemplateEditor);

type SimpleEditorProps = {
  onNameChange: (val: string) => void,
  onHTMLChange: (val: string) => void,
  onSave: () => void,
  html: string,
  name: string,
  disabled: boolean,
  readOnly: boolean,
  templateId: string,
};
export const SimpleTemplateEditor = (props: SimpleEditorProps) => (
  <div className={css.container}>
    <div className={css.notes}>
      <div className={css.notesInset}>
        <div className={css.formGroup}>
          <h4 className={css.heading}>{props.templateNameLabel}</h4>
          <input
            disabled={props.disabled}
            className={css.textInput}
            onChange={(evt) => props.onNameChange(evt.target.value)}
            type="text"
            value={props.name}
          />
        </div>

        <ul className={css.templateNotes}>
          <li>
            {props.emailTemplatesLabel} require the{' '}
            <span className={css.variableName}>{'{{content}}'}</span> variable.
          </li>
          {/*
          (nilarnab) commenting this out for now
          <li>
            <span className={css.variableName}>{'{{unsubscribe}}'}</span> will
            include an html snippet with an unsubscribe link.
          </li>
          <li>
            <span className={css.variableName}>
              {'<%asm_global_unsubscribe_raw_url%>'}
            </span>{' '}
            will be replaced with the raw url to an unsubscribe link.
          </li> */}
          <li>
            <span className={css.variableName}>{'{{survey_css}}'}</span> will
            include raw css needed to properly style survey controls in emails.
            You need to place this in the template's <code>head</code> inside a{' '}
            <code>style</code> tag.
          </li>
        </ul>
      </div>
    </div>

    <CodeEditor
      className={props.readOnly ? css.readOnlyEditor : css.editor}
      width="auto"
      height="auto"
      mode="html"
      theme="tomorrow"
      onChange={props.onHTMLChange}
      name="templateeditoromgz"
      value={props.html}
      readOnly={props.readOnly}
    />
  </div>
);

const CodeEditor = Loadable({
  loader: () => import('src/components/lib/code-editor'),
});
