// @flow

import type {InputAndOptionsClassNames} from 'src/components/lib/token-list-input/new-stuff.jsx';

import * as React from 'react';

import logger from 'src/utils/logger';
import classify from 'src/utils/classify';

import {Select} from 'src/components/lib/token-list-input';

import css from './select.css';


export type SelectOption<T> = {
  label: string,
  value: T,
  ...
};

export type SelectOptions<T> = SelectOption<T>[];

type SelectProps<T> = {
  options: SelectOptions<T>,
  value: SelectOption<T> | string | number | null,
  fluxRef?: any,
  className?: string,
  classNames?: InputAndOptionsClassNames,
  optionClassName?: string,
  onChange?: (value: ?SelectOption<T>) => mixed,
  clearable?: boolean,
  searchable?: boolean,
  disabled?: boolean,
  placeholder?: string,
  name?: string,
  instanceId?: string,
  error?: mixed,
  errorText?: string,
  optionsDirection?: 'up' | 'down',
};

/**
 * DEPRECATED
 *
 * this is a legacy component that delegates to `lib/token-list-input:Select`.
 * please use that instead.
 */
export default class SoSelect<
  T: string | number | boolean,
> extends React.Component<SelectProps<T>> {
  render(): React.Node {
    const {
      value,
      className,
      optionClassName,
      classNames = {},
      fluxRef,
      onChange: unusedOnChange,
      instanceId,
      ...props
    } = this.props;

    const clearable = props.clearable === undefined || props.clearable;
    // $FlowIssue this works
    const optionValue: Option = ['string', 'number', 'boolean'].includes(
      typeof value,
    )
      ? props.options.find((option) => option.value === value) ?? {
          value,
          label: value,
        }
      : value;
    return (
      <Select
        ref={fluxRef}
        onChange={this.handleOnChange}
        clearable={clearable}
        value={optionValue}
        inputProps={{id: instanceId}}
        classNames={{
          ...classNames,
          container: classify(className, classNames.container, css.container),
          option: classify(optionClassName, classNames.option),
        }}
        {...props}
      />
    );
  }

  handleOnChange: (value: ?SelectOption<T>) => void = (
    value: ?SelectOption<T>,
  ) => {
    // many nonclearable onchange handlers are not designed to handle null values
    if (this.props.clearable === false && value == null) {
      if (this.props.onChange) {
        try {
          this.props.onChange(value);
        } catch (e) {
          if (e instanceof TypeError) {
            logger.warn(
              'onChange in SOSelect called with null value, but it threw',
              e,
            );
          }
        }
      }
    } else if (this.props.onChange) {
      this.props.onChange(value);
    }
  };
}
