// @flow strict-local

import * as React from 'react';

import {
  Modifier,
  EditorState,
  ContentBlock,
  ContentState,
  SelectionState,
} from 'draft-js';
import {ENITITY_PLACEHOLDER} from './utils';
import {
  cleanPlaceholder,
  PLACEHOLDER_VARIABLE_REGEX,
} from 'src/components/workflow/templates/utils.js';
import PlaceholderPill from 'src/components/lib/markdown-editor/placeholder-pill.jsx';


function findPlaceholderEntities(
  contentBlock: ContentBlock,
  callback,
  contentState: ContentState,
) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey != null &&
      contentState.getEntity(entityKey).getType() === ENITITY_PLACEHOLDER
    );
  }, callback);
}

export const findPlaceholderVariables = (
  contentBlock: ContentBlock,
  callback: (start: number, stop: number, data: mixed) => number,
) => {
  const text = contentBlock.getText();

  const match = PLACEHOLDER_VARIABLE_REGEX.exec(text);
  if (match) {
    const start = match.index;
    const labelProps = match[0];
    callback(start, start + match[0].length, labelProps);
  }
};

const PlaceholderVariable =
  (onReplace) =>
  ({children}) =>
    <PlaceholderPill onReplace={onReplace} text={children} />;

export const PlaceholderDecorator = (
  onReplace: () => void,
  // $FlowFixMe[signature-verification-failure]
) => ({
  component: PlaceholderVariable(onReplace),
  strategy: findPlaceholderEntities,
});

const addPlaceholderEntities = (
  contentState: ContentState,
  blockKey: string,
  entries: Array<{
    start: number,
    end: number,
    text: string,
  }>,
) => {
  let newContentState = contentState;
  let currentBlock = contentState.getBlockForKey(blockKey);
  let offset = 0;

  for (const {text, start, end} of entries) {
    newContentState = contentState.createEntity(
      ENITITY_PLACEHOLDER,
      'IMMUTABLE',
      text,
    );

    const entityKey = newContentState.getLastCreatedEntityKey();
    const selectionState = SelectionState.createEmpty(blockKey).merge({
      anchorOffset: start + offset,
      focusOffset: end + offset,
    });

    newContentState = Modifier.replaceText(
      newContentState,
      selectionState,
      cleanPlaceholder(text),
      null,
      entityKey,
    );

    const nextBlock = newContentState.getBlockForKey(blockKey);
    offset += nextBlock.getLength() - currentBlock.getLength();
    currentBlock = nextBlock;
  }

  return newContentState;
};

/**
 * modifies the given editorState so that any text variables or draft entities
 * show their current Sense Entity display name.
 */
export const getEditorStateWithPlaceholders = (
  editorState: EditorState,
): EditorState => {
  let newEditorState = editorState;
  let contentState = editorState.getCurrentContent();
  let blocks = contentState.getBlocksAsArray();

  contentState = newEditorState.getCurrentContent();
  blocks = contentState.getBlocksAsArray();

  blocks.forEach((block) => {
    const blockKey = block.getKey();

    const placeholderMatches = [];
    const existingPlaceholderEntities = {};

    findPlaceholderVariables(block, (start, end, text) =>
      placeholderMatches.push({start, end, text}),
    );
    const placeholdersToAdd = placeholderMatches.filter(
      ({start, end}) => !existingPlaceholderEntities[`${start}:${end}`],
    );
    const newContentState = addPlaceholderEntities(
      newEditorState.getCurrentContent(),
      blockKey,
      // $FlowFixMe[incompatible-call]
      placeholdersToAdd,
    );

    newEditorState = EditorState.push(
      newEditorState,
      newContentState,
      'change-block-data',
    );
  });

  return newEditorState;
};

const getEntities = (editorState: EditorState, entityType = null) => {
  const content = editorState.getCurrentContent();
  const entities = [];
  content.getBlocksAsArray().forEach((block) => {
    let selectedEntity = null;
    block.findEntityRanges(
      (character) => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity() ?? '');
          if (!entityType || (entityType && entity.getType() === entityType)) {
            selectedEntity = {
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: content.getEntity(character.getEntity() ?? ''),
            };
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        entities.push({...selectedEntity, start, end});
      },
    );
  });
  return entities;
};

export const getPlaceholderEntities = (editorState: EditorState): mixed[] =>
  getEntities(editorState, ENITITY_PLACEHOLDER);

export const getPlaceholderEntitiesCount = (editorState: EditorState): number =>
  getPlaceholderEntities(editorState).length;
