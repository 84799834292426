// @flow strict
import * as React from 'react';
import {useParams} from 'src/rerouter';
import {useDispatch} from 'react-redux';
import {Header} from 'src/designSystem2021Components/text-v2.jsx';
import {Button} from 'src/designSystem2021Components/buttons.jsx';
import {markOnboardingComplete} from 'src/components/agency-onboard/api/onboard-complete.js';
import {VARIANTS} from 'src/designSystem2021Components/toast';
import {showToastMessage} from 'src/action-creators/toasts';

import css from './mark-onboarding-completion.css';
import maincss from 'src/components/agency-onboard/agency-onboard.css';


export const MarkOnboardingCompletion = (): React.Node => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState(null);

  const approveOnboarding = () => {
    setIsLoading(true);
    dispatch(markOnboardingComplete())
      .then((response) => {
        setIsLoading(false);
        setResponseMessage(response.message);
      })
      .catch((error) => {
        dispatch(
          showToastMessage(`Error approving onboarding`, {
            variant: VARIANTS.ERROR,
          }),
        );
        setIsLoading(false);
      });
  };

  return (
    <div className={css.mainContainer}>
      <div className={css.container}>
        {responseMessage ? (
          <Header>{responseMessage}</Header>
        ) : (
          <div className={css.notApproved}>
            <Header>
              Once task details are verified, Approve the onboarding
            </Header>
            <Button
              type="primary"
              className={css.approveBtn}
              onClick={approveOnboarding}
              disabled={isLoading}
            >
              {isLoading ? 'Approving...' : 'Approve'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
