// @noflow
import * as React from 'react';

import css from './audience-csv-upload-error-modal.css';

import {classify} from 'src/utils/index';
import FluxComponent from 'src/flux/component.jsx';
import CloseIcon from 'src/images/close-icon.svg';


class AudienceCsvUploadErrorModal extends React.Component<$FlowFixMeProps> {
  render() {
    let failedRows, errMsg;
    if (this.props.data) {
      failedRows = this.props.data.failed_rows;
    } else if (this.props.error) {
      errMsg = this.props.error.message || 'We weren\'t able to upload the csv file.';
    }

    return (
      <div className={classify(this.props.className, css.container)}>
        <div className={css.header}>
          <div className={css.title}>CSV Upload Error</div>
          <CloseIcon className={css.close} onClick={this.props.removeModal} />
        </div>
        <div className={css.message}>We had difficulties with the csv file</div>
        { errMsg ? 
          <div className={css.errorMessage}>{errMsg}</div>
        : null }
        { failedRows ? 
          <ul className={css.errorList}>
            { failedRows.map(row => (
              <li className={css.errorItem} key={row.row}>{`Row ${row.row}, ${row.error}`}</li>
            )) }
          </ul>
        : null
        }
      </div>
    );
  }
}


export default FluxComponent(AudienceCsvUploadErrorModal);
