//@flow strict
export const JOB_WITH_LINK_IDENTIFIER = 'matched_job/title_with_link';
/** this regex only check the presence of text like
 * - '<foo>'
 * - '<foo|fallback: `bar`>',
 * - '<foo|extra:"bar"|fallback:"'baz'">' in the text
 * copied from src/components/lib/markdown-editor/utils.js
 * used for validating the presence of job variables in the text.
 */
export const JOB_MATCH_VARIABLE_REGEX: RegExp = /<+([^>]+)>+|\{\{([^}]+)\}\}/g;

export function* findJobVariablesInString(
  string: string,
): Generator<string, void, void> {
  let match;
  while ((match = JOB_MATCH_VARIABLE_REGEX.exec(string)) !== null) {
    // $FlowIssue match is guarenteed not to be null
    yield match[1] || match[2];
  }
}

export const findCommonElements = (
  includedFilters: Set<string>,
  depends_on_either: string[],
): boolean => {
  return depends_on_either.some((item) => includedFilters.has(item));
};
