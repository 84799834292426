// @flow

import type {Dispatch, GetState, ThunkAction} from 'src/reducers';
import type {PaginatedResponses, AnalyticsQuery} from 'src/types/report';

import pick from 'lodash/pick';

import {thunkify as flow} from 'src/utils/thunks';
import {
  resolveAnalyticsQuery,
  resolveAnalyticsQueryV2,
  resolveAnalyticsPath,
  commonFilters,
  eventFilters,
} from 'src/utils/analytics-query';
import {camel, sortObject, hashObject} from 'src/utils';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api.js';
import {getFiltered} from 'src/selectors/event-responses';
import {
  checkResponseLatestApiEnabled,
  ANALYTICS_API_V3,
} from 'src/utils/analytics-api-migration';


export const apiFilters: string[] = [
  ...commonFilters,
  'audienceMemberId',
  'flagged',
];

export const allowedFilters: string[] = [
  ...eventFilters,
  ...apiFilters,
  'responseId',
  'page',
];

export const RECEIVE_FILTERED = 'eventResponse/receiveFiltered';

export const PAGINATION_LIMIT = 50;

export type FilteredResponsesAction = {
  type: 'eventResponse/receiveFiltered',
  payload: {
    responses: PaginatedResponses,
    // $FlowFixMe[value-as-type] [v1.32.0]
    query: AnalyticsQuery,
  },
};
export type ResponseAction = FilteredResponsesAction;

export const receiveFiltered = (
  responses: PaginatedResponses,
  // $FlowFixMe[value-as-type] [v1.32.0]
  query: AnalyticsQuery,
): FilteredResponsesAction => {
  responses.hasMore = responses.responses.length >= PAGINATION_LIMIT;
  return {
    type: RECEIVE_FILTERED,
    payload: {
      responses,
      query,
    },
  };
};

function resolvePage(page: mixed): number {
  return typeof page === 'number' ? page : 0;
}

function resolvePageQuery2({page, ...query}: AnalyticsQuery): AnalyticsQuery {
  return typeof page === 'number'
    ? // $FlowFixMe[incompatible-return]
      {...query, offset: page * PAGINATION_LIMIT, limit: PAGINATION_LIMIT}
    : query;
}

export function resolveResponsesQuery(
  // $FlowFixMe[value-as-type] [v1.32.0]
  query: AnalyticsQuery = {},
  allowed: string[] = allowedFilters,
  isEnhancement: boolean = false,
): AnalyticsQuery {
  if (isEnhancement) {
    return resolveAnalyticsQueryV2(query, allowed);
  }
  return resolveAnalyticsQuery(query, allowed);
}

export function resolveApiPath(ids: *): string {
  return resolveAnalyticsPath('responses', ids);
}

const camelResponses = (json) =>
  camel(json, true, [
    'module_questions',
    'previous_module_values',
    'recipient_entity_summary',
  ]);

const querying2 =
  (allowed = allowedFilters, isEnhancement = false) =>
  (func) =>
  (query) => {
    query = resolveResponsesQuery(query, allowed, isEnhancement);
    return func(sortObject(query));
  };

export const getFilteredResponses: (AnalyticsQuery) => ThunkAction<mixed> =
  flow(
    querying2(),
    key(
      (query) =>
        `report/eventResponses/${hashObject(pick(query, allowedFilters))}`,
    ),
    cached((json, query) => receiveFiltered(camelResponses(json), query), {
      hash: (query) => `report/eventResponses/${hashObject(query)}`,
    }),
    fetching(),
  )(({workflowId, eventId, responseId, ...query}: AnalyticsQuery) => {
    let path = '';
    if (workflowId) {
      path += `workflows/${workflowId}/`;

      if (eventId) {
        path += `events/${eventId}/`;
      }
    }

    path += 'responses';

    if (responseId) {
      path += `/${responseId}`;
    }

    return reduxApi.post(
      path,
      // $FlowFixMe[incompatible-call]
      resolvePageQuery2(query),
      {},
      {
        apiPath: checkResponseLatestApiEnabled()
          ? `${ANALYTICS_API_V3}/`
          : '/api/v1/analytics/',
      },
    );
  });

export const getMoreFilteredResponses =
  (query: AnalyticsQuery): ThunkAction<mixed> =>
  (dispatch: Dispatch, getState: GetState) =>
    dispatch(
      getFilteredResponses({
        ...query,
        page: resolvePage(getFiltered(getState(), query).page) + 1,
      }),
    );

export const getFilteredResponsesV2: (AnalyticsQuery) => ThunkAction<mixed> =
  flow(
    querying2(allowedFilters, true),
    key(
      (query) =>
        `report/eventResponses/${hashObject(pick(query, allowedFilters))}`,
    ),
    cached((json, query) => receiveFiltered(camelResponses(json), query), {
      hash: (query) => `report/eventResponses/${hashObject(query)}`,
    }),
    fetching(),
  )(({workflowId, eventId, responseId, ...query}: AnalyticsQuery) => {
    let path = '';
    if (workflowId) {
      path += `workflows/${workflowId}/`;

      if (eventId) {
        path += `events/${eventId}/`;
      }
    }

    path += 'responses';

    if (responseId) {
      path += `/${responseId}`;
    }

    return reduxApi.post(
      path,
      // $FlowFixMe[incompatible-call]
      resolvePageQuery2(query),
      {},
      {
        apiPath: checkResponseLatestApiEnabled()
          ? `${ANALYTICS_API_V3}/`
          : '/api/v1/analytics/',
      },
    );
  });

export const getMoreFilteredResponsesV2 =
  (query: AnalyticsQuery): ThunkAction<mixed> =>
  (dispatch: Dispatch, getState: GetState) =>
    dispatch(
      getFilteredResponsesV2({
        ...query,
        page: resolvePage(getFiltered(getState(), query).page) + 1,
      }),
    );
