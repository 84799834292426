// @flow

import * as React from 'react';
import moment from 'moment';
import partial from 'lodash/partial';

import {classify} from 'src/utils/index';
import {resolveDateRange, type Range} from 'src/utils/date-range';
import {SimpleDateRangePicker} from 'src/components/lib/date-range-picker/date-range-picker.jsx';
import Select from 'src/components/lib/select/select.jsx';
import withAnalyticsQuery, {
  type AnalyticsQueryProps,
} from 'src/components/analytics/with-query.jsx';

import css from './date-range.css';

import {useReleaseFlag} from 'src/hooks/product-flags';


type OwnProps = {
  className?: string,
  range?: Range,
  onTimeAgoChange?: (
    props: Props,
    selectValue: ?$ReadOnly<{value: string, ...}>,
  ) => mixed,
  onDateRangeChange?: (props: Props, range: Range) => mixed,
  defaultTimeAgo?: string,
  otherTimeAgoOptions?: {value: string, label: string}[],
  minDate?: string,
};

type Props = {
  ...AnalyticsQueryProps,
  ...OwnProps,
};

const DateRange = (props: Props) => {
  const {
    query,
    onTimeAgoChange = handleTimeAgoChange,
    onDateRangeChange = handleChange,
    defaultTimeAgo = _defaultTimeAgo,
    minDate,
  } = props;
  const useRange = Boolean(query.start_date && query.end_date);
  const timeAgo = useRange ? 'range' : query.time_ago || defaultTimeAgo;
  const isDefaultTimeRangeEnabled = useReleaseFlag(
    'analyticsDefaultTimeRangeEnabled',
  );
  if (isDefaultTimeRangeEnabled && timeAgoOptions.length === 4) {
    timeAgoOptions.splice(3, 0, {
      value: JSON.stringify({
        days: 1,
      }),
      label: 'Current Day',
    });
  }
  const {
    range = {
      startDate: query.start_date,
      endDate: query.end_date,
    },
  } = props;

  return (
    <div
      className={classify(
        css.dateRange,
        {[css.manual]: useRange},
        props.className,
      )}
    >
      <Select
        className={css.timeAgoSelect}
        options={
          props.otherTimeAgoOptions
            ? [...props.otherTimeAgoOptions, ...timeAgoOptions]
            : timeAgoOptions
        }
        value={timeAgo}
        onChange={(timeAgo) => onTimeAgoChange(props, timeAgo)}
        searchable={false}
      />
      {useRange && (
        <SimpleDateRangePicker
          value={range}
          onChange={partial(onDateRangeChange, props)}
          maxDate={moment()}
          minDate={minDate ? moment(minDate) : ''}
        />
      )}
    </div>
  );
};

export default (withAnalyticsQuery(DateRange): React.ComponentType<OwnProps>);

const _defaultTimeAgo = JSON.stringify({
  days: 90,
});

const timeAgoOptions = [
  {
    value: JSON.stringify({
      days: 90,
    }),
    label: 'Last 90 days',
  },
  {
    value: JSON.stringify({
      days: 30,
    }),
    label: 'Last 30 days',
  },
  {
    value: JSON.stringify({
      days: 7,
    }),
    label: 'Last 7 days',
  },
  {
    value: 'range',
    label: 'Range...',
  },
];

function handleTimeAgoChange(
  {query, onQueryChange, defaultTimeAgo},
  selectValue,
) {
  const {value} = selectValue || {};
  let update;
  if (value === 'range') {
    const timeAgo = defaultTimeAgo ? JSON.parse(defaultTimeAgo) : undefined;
    update = {
      // NOTE (kyle): commands are run in order. resolveDateRange() sometimes
      // returns a query with time_ago.
      $merge: resolveDateRange(query, undefined, timeAgo),
      $unset: 'time_ago',
    };
  } else {
    update = {
      $unset: ['start_date', 'end_date'],
      time_ago: {$set: value},
    };
  }
  onQueryChange(update);
}

function handleChange({onQueryChange}: Props, {startDate, endDate}) {
  onQueryChange({
    start_date: {$set: startDate},
    end_date: {$set: endDate},
  });
}
