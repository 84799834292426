// @flow strict

import type {
  WindowConfig,
  WindowLocalState,
  NormalizedBlackoutWindow,
} from 'src/types/blackout-window';
import type {MenuOption} from '@spaced-out/ui-design-system/lib/components/Menu';

import capitalize from 'lodash/capitalize';
import {timeStringToMinutes} from 'src/utils/time';
import {
  checkTime1LessThanTime2,
  formatMonthDay,
  getTimein12HrsFormat,
} from 'src/utils/date-time-2';


export const DEFAULT_NO_BLACKOUT_VALUE = 'no_blackout';

export {
  format24to12Hours,
  minutesToTimeString,
  timeStringToMinutes,
} from 'src/utils/time';

/**  hh:mm to hh:mm:ss (checks if 00 time part needs to be added)
 * @param {string} time
 * @returns {string} hh:mm:ss e.g. '11:30:00'
 */
export const formatBlackoutTime = (time: string): string => {
  if (time === DEFAULT_NO_BLACKOUT_VALUE) {
    return DEFAULT_NO_BLACKOUT_VALUE;
  }
  if (time.split(':').length === 3) {
    return time;
  }
  return `${time}:00`;
};

/** returns if blackout window extends beyond midnight
 * @param {string} startTime in hh:mm:ss e.g. '23:30:00'
 * @param {string} endTime in hh:mm:ss e.g. '23:30:00'
 * @returns {boolean} - true if blackout window extends beyond midnight else false
 */
export const checkIfBlackoutExtendsBeyondMidnight = (
  startTime: string,
  endTime: string,
): boolean => {
  if (
    startTime === DEFAULT_NO_BLACKOUT_VALUE ||
    endTime === DEFAULT_NO_BLACKOUT_VALUE
  ) {
    return false;
  }
  return (
    parseInt(timeStringToMinutes(endTime), 10) <
    parseInt(timeStringToMinutes(startTime), 10)
  );
};

/*J2 Blackout window functions post this point*/

const WEEKDAYS = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

const MONTHS = {
  Jan: 31,
  Feb: 29,
  Mar: 31,
  Apr: 30,
  May: 31,
  Jun: 30,
  Jul: 31,
  Aug: 31,
  Sep: 30,
  Oct: 31,
  Nov: 30,
  Dec: 31,
};
const getTimeLabel = (
  startTime: string,
  endTime: string,
  alphanumeric: boolean = false,
) => {
  const startLabel = getTimein12HrsFormat(startTime);
  const endLabel = getEndtimeText(startTime, endTime, alphanumeric);
  return `${startLabel} to ${endLabel}`;
};

export const getDateInfo = (
  configs: Array<WindowConfig>,
  getHolidayTitle: (string) => string,
  alphanumeric: boolean = false,
): {
  blackout_window: Array<string>,
  days: Array<string>,
  weekDays: Array<string>,
} => {
  return configs.reduce(
    (acc, config) => {
      switch (config.type) {
        case 'DAILY':
          acc.blackout_window.push(
            getTimeLabel(config.start_time, config.end_time, alphanumeric),
          );
          break;
        case 'DATE_CUSTOM':
          acc.days.push(formatMonthDay(config.value));
          break;
        case 'DATE_HOLIDAY':
          if (config.resource_id) {
            acc.days.push(getHolidayTitle(config.resource_id));
          }
          break;
        case 'DAY_OF_WEEK':
          acc.weekDays.push(capitalize(config.value));
          break;
      }
      return acc;
    },
    {
      blackout_window: [],
      days: [],
      weekDays: [],
    },
  );
};

export const getDataFromConfigs = (
  configs: Array<WindowConfig>,
  type: string,
  property: string,
): Array<{id: ?number, value: ?string}> => {
  const data = [];
  configs.forEach((config) => {
    if (config.type === type) {
      data.push({id: config.id, value: config[property]});
    }
  });
  return data;
};

export const getHolidayDataFromConfigs = (
  configs: Array<WindowConfig>,
): Array<{id: ?number, value: ?string, resource_id: ?string}> => {
  const data = [];
  configs.forEach((config) => {
    if (config.type === 'DATE_HOLIDAY') {
      if (config.value && config.resource_id) {
        data.push({
          id: config.id,
          value: config.value,
          resource_id: config.resource_id,
        });
      }
    }
  });
  return data;
};

export const generateTimeOptions = (): Array<MenuOption> => {
  const options = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 30) {
      const key = `${String(i).padStart(2, '0')}:${String(j).padStart(
        2,
        '0',
      )}:00`;
      const label = getTimein12HrsFormat(key);
      options.push({key, label});
    }
  }
  return options;
};

export const getTimeLabelWithOffset = (
  time: string,
  dayOffset: number,
  alphanumeric: boolean = false,
): string => {
  const timeLabel = getTimein12HrsFormat(time);
  let offsetLabel = '';

  if (dayOffset > 0) {
    if (alphanumeric) {
      offsetLabel = dayOffset === 1 ? 'Next day' : `+${dayOffset} days`;
    } else {
      offsetLabel = `+${dayOffset} day${dayOffset > 1 ? 's' : ''}`;
    }
  }

  return `${timeLabel} ${offsetLabel}`;
};

export const generateUntilTimeOptions = (
  startTime: string,
  window: number,
): Array<MenuOption> => {
  const [startHour, startMinute, ...rest] = startTime.split(':').map(Number);
  const windowInMinutes = window * 60;
  const options = [];

  let currentHour = startHour;
  let currentMinute = startMinute;
  let dayOffset = 0;
  for (let i = 1; i <= windowInMinutes; i += 30) {
    if (i !== 0) {
      currentMinute += 30;
      if (currentMinute >= 60) {
        currentMinute -= 60;
        currentHour += 1;
      }
      if (currentHour >= 24) {
        currentHour -= 24;
        dayOffset += 1;
      }
    }
    const key = `${String(currentHour).padStart(2, '0')}:${String(
      currentMinute,
    ).padStart(2, '0')}:00`;
    const label = getTimeLabelWithOffset(key, dayOffset);
    options.push({key, label});
  }
  return options;
};

export const getDaysOfWeekOptions = (
  selectedWeekdays: Array<{id: ?number, value: string}>,
): Array<MenuOption> => {
  return WEEKDAYS.filter(
    (weekday) => !selectedWeekdays.map(({value}) => value).includes(weekday),
  ).map((weekday) => ({key: weekday, label: capitalize(weekday)}));
};

export const getDaysOfWeekContent = (
  weekdays: Array<{id: ?number, value: string}>,
): Array<{key: string, label: string, type: string}> => {
  return weekdays.map((weekday) => ({
    key: weekday.value,
    label: capitalize(weekday.value),
    type: 'weekday',
  }));
};

export const getCalendarDateContent = (
  calendarDates: Array<{id: ?number, value: string}>,
): Array<{key: string, label: string, type: string}> => {
  return calendarDates.map((date) => ({
    key: date.value,
    label: `Custom Date (${formatMonthDay(date.value)})`,
    type: 'customDate',
  }));
};

export const getHolidayContent = (
  holidays: Array<{id: ?number, value: string, resource_id: string}>,
  getHolidayTitle: (string) => string,
): Array<{key: string, label: string, type: string}> => {
  return holidays.map(({resource_id}) => ({
    key: resource_id,
    label: getHolidayTitle(resource_id),
    type: 'holiday',
  }));
};

export const getMonthOptions = (): Array<MenuOption> => {
  return Object.keys(MONTHS).map((label, index) => {
    const key = (index + 1).toString().padStart(2, '0');
    return {key, label};
  });
};

export const getMonthLabelFromValue = (monthValue: ?string): string => {
  const monthIndex = monthValue ? parseInt(monthValue, 10) - 1 : null;
  return monthIndex != null ? Object.keys(MONTHS)[monthIndex] : '';
};

export const getDaysOptions = (monthValue: ?string): Array<MenuOption> => {
  if (!monthValue) {
    return [];
  }
  const month = getMonthLabelFromValue(monthValue);
  const monthLimit = MONTHS[month];
  const options = [];
  for (let i = 1; i <= monthLimit; i++) {
    const key = i.toString().padStart(2, '0');
    options.push({key, label: key});
  }
  return options;
};

export const getWeekdaySummaryDays = (
  days: Array<{id: ?number, value: string}>,
): string => {
  if (days.length === 0) {
    return '';
  }
  const pluralDays = days.map((day) => capitalize(day.value) + 's');

  if (pluralDays.length === 1) {
    return pluralDays[0];
  } else if (pluralDays.length === 2) {
    return `${pluralDays[0]} and ${pluralDays[1]}`;
  } else {
    const lastDay = pluralDays.pop();
    return `${pluralDays.join(', ')} and ${lastDay}`;
  }
};

export const normalizeWindow = (
  data: WindowLocalState,
): NormalizedBlackoutWindow => {
  let config = [];
  if (data.enableBlackoutTime) {
    if (data.startBlackoutTime && data.endBlackoutTime) {
      const timeConfig = {
        start_time: data.startBlackoutTime.value ?? '',
        end_time: data.endBlackoutTime.value ?? '',
        value: 'DAILY',
        type: 'DAILY',
        ...(data.startBlackoutTime.id != null && {
          id: data.startBlackoutTime.id,
        }),
      };
      config.push(timeConfig);
    }
  }
  if (data.customDates.length > 0) {
    const customConfigs = data.customDates.map((dateObj) => {
      return {
        type: 'DATE_CUSTOM',
        start_time: '00:00:00',
        end_time: '23:59:59',
        value: dateObj.value,
        ...(dateObj.id != null && {id: dateObj.id}),
      };
    });
    config = [...config, ...customConfigs];
  }
  if (data.holidays.length > 0) {
    const customConfigs = data.holidays.map((dateObj) => {
      return {
        type: 'DATE_HOLIDAY',
        start_time: '00:00:00',
        end_time: '23:59:59',
        value: dateObj.value,
        resource_id: dateObj.resource_id,
        ...(dateObj.id != null && {id: dateObj.id}),
      };
    });
    config = [...config, ...customConfigs];
  }
  if (data.weekdays.length > 0) {
    const customConfigs = data.weekdays.map((dateObj) => {
      return {
        type: 'DAY_OF_WEEK',
        start_time: '00:00:00',
        end_time: '23:59:59',
        value: dateObj.value,
        ...(dateObj.id != null && {id: dateObj.id}),
      };
    });
    config = [...config, ...customConfigs];
  }
  return {
    name: data.name ?? '',
    is_default: data.default,
    configs: config,
  };
};

export const getEndtimeText = (
  startTime: string,
  endTime: string,
  alphanumeric: boolean,
): string => {
  let offset = 0;
  if (!checkTime1LessThanTime2(startTime, endTime)) {
    offset = 1;
  }
  return getTimeLabelWithOffset(endTime, offset, alphanumeric);
};
