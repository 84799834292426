// @flow strict

//$FlowFixMe[untyped-import]
import amplitude from 'amplitude-js';

// $FlowFixMe[nonstrict-import]
import {isInternalUser} from 'src/utils/check-internal-user';

import {PAGE_INFO} from 'src/analytics/page_info';


const ENABLED = true;

const AMPLITUDE_TOKEN_KEY = {
  PRODUCTION: 'fd60f8fe13c1b2a8bdcb465df096f25d',
  DEVELOPMENT: 'b3a35e90cd3dbb04e0329b3cbb09fe84',
};
// Local function
// [TODO]: handle from api, can be enabled through API
const isEnabled = () => ENABLED;

const loadService = (ENV: string) => {
  const KEY = AMPLITUDE_TOKEN_KEY[ENV];
  if (amplitude && isEnabled()) {
    amplitude.getInstance().init(KEY, null, {includeReferrer: true});
  }
};

const identify = (userId: string) => {
  if (amplitude && isEnabled() && !isUserIdExist()) {
    amplitude.getInstance().setUserId(userId);
  }
};

const register = (userDetails: mixed) => {
  if (amplitude && isEnabled()) {
    amplitude
      .getInstance()
      .setUserProperties({...userDetails, internalUser: isInternalUser()});
  }
};

const trackPage = (props: mixed) => {
  if (amplitude && isEnabled()) {
    amplitude.getInstance().logEvent('page-viewed', props);
  }
};

const track = (eventName: string, props: mixed) => {
  try {
    if (amplitude && isEnabled()) {
      amplitude.getInstance().logEvent(eventName, props);
    }
  } catch (e) {}
};

// Public functions
const init = (ENV: string) => {
  if (isEnabled()) {
    loadService(ENV);
  }
};

const reset = () => {
  if (isEnabled()) {
    try {
      amplitude.getInstance().resetSessionId();
    } catch (error) {
      console.log('error while resting the amplitude', error);
    }
  }
};

export const AnalyticsService = {
  init,
  identify,
  trackPage,
  register,
  track,
  reset,
};

// util function

const isUserIdExist = () => amplitude.getInstance().getUserId();
