// @flow strict

import * as React from 'react';
import {classify} from 'src/utils/classify';

import css from './label.css';


export type LabelProps = {
  children: React.Node,
  className?: string,
  showBorder?: boolean,
  showBackground?: boolean,
};

export const Label = ({
  children,
  showBorder = false,
  showBackground = false,
  className,
}: LabelProps): React.Node => (
  <label
    className={classify(
      css.label,
      {
        [css.border]: showBorder,
        [css.background]: showBackground,
      },
      className,
    )}
  >
    {children}
  </label>
);

export const ErrorLabel = ({
  className,
  children,
  ...restProps
}: LabelProps): React.Node => (
  <Label {...restProps} className={classify(css.error, className)}>
    {children}
  </Label>
);
export const WarningLabel = ({
  className,
  children,
  ...restProps
}: LabelProps): React.Node => (
  <Label {...restProps} className={classify(css.warning, className)}>
    {children}
  </Label>
);

export default Label;
