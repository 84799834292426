// @flow

import type {AudienceMemberData} from 'src/types/audience-member';
import type {SenseAction} from 'src/action-creators/types';

import {
  RECEIVE_AUDIENCE_MEMBERS,
  RECEIVE_PLACEMENTS,
} from 'src/action-creators/audience-members/audience-members';
import {RECEIVE_AUDIENCE_MEMBER as RECEIVE_SENT_EVENTS} from 'src/action-creators/sent-events';
import {RECEIVE_INBOX, RECEIVE_THREAD} from 'src/action-creators/messages';
import {RECEIVE_BROADCAST} from 'src/action-creators/broadcasts';
import {RECEIVE_ONE as RECEIVE_THREAD_LIST} from 'src/action-creators/thread-lists';


export type State = {
  [audienceMemberId: string]: AudienceMemberData,
};

const initialState = {...null};

export default (state: State = initialState, action: SenseAction): State => {
  switch (action.type) {
    case RECEIVE_AUDIENCE_MEMBERS: {
      return reduceAudienceMembers(state, action.payload);
    }

    case RECEIVE_PLACEMENTS: {
      const {placements, audienceMemberId} = action.payload;
      return reduceData(state, audienceMemberId, {placements});
    }

    case RECEIVE_SENT_EVENTS: {
      const {sentEvents, audienceMemberId} = action.payload;
      return reduceData(state, audienceMemberId, {
        sentEvents,
      });
    }

    case RECEIVE_BROADCAST:
    case RECEIVE_THREAD_LIST:
    case RECEIVE_INBOX: {
      if (action.payload.audienceMembers) {
        return reduceAudienceMembers(state, action.payload.audienceMembers);
      }
      break;
    }
    case RECEIVE_THREAD: {
      if (action.payload.response.audienceMembers) {
        return reduceAudienceMembers(
          state,
          action.payload.response.audienceMembers,
        );
      }
    }
  }
  return state;
};

function reduceData(state, audienceMemberId, update) {
  return {
    ...state,
    [audienceMemberId]: reduceSingleData(state, audienceMemberId, update),
  };
}

function reduceAudienceMembers(state, audienceMembers) {
  state = {...state};

  for (const audienceMember of audienceMembers) {
    state[audienceMember.id] = reduceSingleData(state, audienceMember.id, {
      audienceMember,
    });
  }

  return state;
}

function reduceSingleData(state, audienceMemberId, update) {
  return {
    ...state[audienceMemberId],
    ...update,
    id: audienceMemberId,
  };
}
