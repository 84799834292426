// @flow strict-local

import {useState, useEffect, useRef} from 'react';
import invariant from 'invariant';

import {setMap} from 'src/utils/map';
import * as api from 'src/utils/api-no-store';

import type {BaseApiOptions, ApiUrlArg} from './useApi';


type PaginationPage<R> =
  | {
      isLoading: true,
      error: null,
      records: null,
    }
  | {
      isLoading: false,
      error: Error,
      records: null,
    }
  | {
      isLoading: false,
      error: null,
      records: Array<R>,
    };

type PaginationState<R> = {
  pages: Map<number, PaginationPage<R>>,
  total: ?number,
};

type PaginationApiOptions<R, S> = {
  ...BaseApiOptions,
  pageSize: number,
  apiPath?: string,
  parseResponse?: (S) => {records: R[], total: ?number},
};

export function useAdvancedPaginatedApi<R, S>(
  urlArg: ApiUrlArg,
  {
    method,
    data: dataArg,
    pageSize,
    apiPath,
    // $FlowFixMe[incompatible-type]
    parseResponse = parseArrayResponse,
  }: PaginationApiOptions<R, S>,
): [PaginationState<R>, (number) => Promise<void>, () => void] {
  const url = resolveUrlArg(urlArg);
  let {query} = url;
  const {pathname} = url;

  const [page, setPage] = useState<PaginationState<R>>({
    pages: new Map(),
    total: null,
  });

  const aborter = useRef();

  const fetchPage = async (pageNumber: number) => {
    // we abort when requesting a new set of data
    if (pageNumber === 0 && aborter.current) {
      aborter.current.abort();
      aborter.current = null;
    }

    let abortController = aborter.current;
    if (!abortController) {
      abortController = aborter.current = new AbortController();
    }

    let {pages} = page;
    if (pageNumber === 0) {
      pages = new Map();
    }

    setPage({
      ...page,
      pages: setMap(pages, pageNumber, {
        isLoading: true,
        error: null,
        records: null,
      }),
    });

    invariant(pathname, 'Attempted to fetch data without a pathname');

    let data = dataArg;
    if (method === 'POST') {
      data = {
        ...data,
        offset: pageNumber * pageSize,
        limit: pageSize + 1,
      };
    } else {
      query = {
        ...query,
        offset: pageNumber * pageSize,
        limit: pageSize + 1,
      };
    }

    try {
      let init = {
        method,
        query,
        data,
        signal: abortController.signal,
        options: {apiPath},
      };

      if (apiPath) {
        init = {...init, options: {apiPath}};
      }

      const response = await api.baseRequest(pathname, init);
      const {records, total} = parseResponse(response);

      const hasMore = records.length > pageSize;
      const slice = hasMore ? records.slice(0, -1) : records;

      setPage((state) => ({
        pages: setMap(state.pages, pageNumber, {
          isLoading: false,
          records: slice,
          error: null,
        }),
        total,
      }));
    } catch (error) {
      // $FlowFixMe[cannot-resolve-name] DOMException is a global
      if (error instanceof DOMException && error.name === 'AbortError') {
        // this is fine
      } else {
        setPage((state) => ({
          ...state,
          pages: setMap(state.pages, pageNumber, {
            isLoading: false,
            records: null,
            error,
          }),
        }));
      }
    }
  };

  const reset = () => {
    setPage({
      pages: new Map(),
      total: null,
    });
  };

  return [page, fetchPage, reset];
}

function resolveUrlArg(urlArg) {
  const url = typeof urlArg === 'function' ? urlArg() : urlArg;

  let pathname: ?string;
  let query;
  if (url instanceof Object) {
    pathname = url.pathname;
    query = url.query;
  } else if (typeof url === 'string') {
    pathname = url;
  }

  return {pathname, query};
}

export type ApiPage<R> = {
  records: R[],
  isLoading: boolean,
  loadingMore: boolean,
  hasMore: boolean,
  error: ?Error,
};

export function useMorePaginatedApi<R>(
  urlArg: ApiUrlArg,
  {
    method,
    data,
    deps,
    shouldFetch = true,
    pageSize,
    apiPath,
  }: {
    ...BaseApiOptions,
    pageSize: number,
    apiPath?: string,
  },
): {
  ...ApiPage<R>,
  fetchMore: () => Promise<void>,
} {
  const url = typeof urlArg === 'function' ? urlArg() : urlArg;

  let pathname: ?string;
  let query;
  if (url instanceof Object) {
    pathname = url.pathname;
    query = url.query;
  } else if (typeof url === 'string') {
    pathname = url;
  }

  const [page, setPage] = useState<ApiPage<R>>({
    records: [],
    isLoading: true,
    loadingMore: false,
    hasMore: Boolean(pathname),
    error: null,
  });

  const aborter = useRef();

  const requestPage = async (offset: number) => {
    // we abort when requesting a new set of data
    if (offset === 0 && aborter.current) {
      aborter.current.abort();
      aborter.current = null;
    }

    let abortController = aborter.current;
    if (!abortController) {
      abortController = aborter.current = new AbortController();
    }

    setPage({
      ...page,
      isLoading: offset === 0,
      loadingMore: true,
    });

    invariant(pathname, 'Attempted to fetch data without a pathname');

    try {
      let init = {
        method,
        query,
        data: {
          ...data,
          offset,
          limit: pageSize + 1,
        },
        signal: abortController.signal,
        options: {apiPath},
      };
      if (apiPath) {
        init = {...init, options: {apiPath}};
      }
      const response = await api.baseRequest(pathname, init);

      const hasMore = response.length > pageSize;
      const slice = hasMore ? response.slice(0, -1) : response;

      setPage({
        records: offset === 0 ? slice : [...page.records, ...slice],
        isLoading: false,
        loadingMore: false,
        hasMore,
        error: null,
      });
    } catch (error) {
      // $FlowFixMe[cannot-resolve-name] DOMException is a global
      if (error instanceof DOMException && error.name === 'AbortError') {
        // this is fine
      } else {
        setPage({
          records: page.records,
          isLoading: false,
          loadingMore: false,
          hasMore: false,
          error,
        });
      }
    }
  };

  useEffect(() => {
    if (pathname && shouldFetch) {
      requestPage(0);
    } else {
      setPage({
        records: [],
        isLoading: false,
        loadingMore: false,
        hasMore: false,
        error: null,
      });
    }
  }, deps);

  const fetchMore = () => requestPage(page.records.length);

  return {
    ...page,
    fetchMore,
  };
}

/*
export type ApiPage<R> = {
  records: R[],
  isLoading: boolean,
  loadingMore: boolean,
  hasMore: boolean,
  error: ?Error,
};

export function useMorePaginatedApi<R, S>(
  urlArg: ApiUrlArg,
  options: PaginationApiOptions<R, S>,
): {
  ...ApiPage<R>,
  fetchMore: () => Promise<void>,
  nextPage: number,
} {
  const url = resolveUrlArg(urlArg);
  const {shouldFetch = true, deps} = options;

  const [state, fetchPage, reset] = useAdvancedPaginatedApi(urlArg, options);

  useEffect(() => {
    if (url.pathname && shouldFetch) {
      fetchPage(0);
    } else {
      reset();
    }
  }, deps);

  const stuff = useMemo(() => {
    const isLoading = Boolean(state.pages.get(0)?.isLoading);
    const records = [];
    let error,
      loadingMore = false;
    let page,
      i = 0;

    while ((page = state.pages.get(i)) && !error && !loadingMore) {
      if (page.records) {
        records.push(...page.records);
      }
      if (page.error) {
        error = page.error;
      }
      if (page.isLoading) {
        loadingMore = page.isLoading;
      }
      i++;
    }

    const hasMore = state.total != null && records.length < state.total;

    return {
      records,
      isLoading,
      loadingMore,
      error,
      hasMore,
      nextPage: i,
    };
  }, [state]);

  const fetchMore = () => fetchPage(stuff.nextPage);

  return {
    ...stuff,
    fetchMore,
  };
}
*/

function parseArrayResponse<T>(response: Array<T>): {
  records: Array<T>,
  total: ?number,
} {
  return {
    records: response,
    total: null,
  };
}

export function usePaginatedApi<R, S>(
  urlArg: ApiUrlArg,
  pageNumber: number,
  options: PaginationApiOptions<R, S>,
): {
  data: PaginationPage<R>,
  total: ?number,
  lastPage: ?number,
} {
  const url = resolveUrlArg(urlArg);
  const [state, fetchPage, reset] = useAdvancedPaginatedApi(urlArg, options);

  const {shouldFetch = true, deps} = options;

  useEffect(() => {
    if (url.pathname && shouldFetch) {
      fetchPage(pageNumber);
    } else {
      reset();
    }
  }, deps);

  const currentPage = state.pages.get(pageNumber) || {
    isLoading: true,
    error: null,
    records: null,
  };

  return {
    data: currentPage,
    total: state.total,
    lastPage:
      state.total != null ? Math.ceil(state.total / options.pageSize) : null,
  };
}
