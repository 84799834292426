// @flow

import type {Dispatch, ThunkAction} from 'src/reducers';
import type {Agency} from 'src/api-parsers/index.types';
import type {AgencyConfig, AgencyBulkConfigItem} from 'src/types/agency';

import {thunkify as flow} from 'src/utils/thunks';
import parsers from 'src/api-parsers';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api-v2.js';
import {camel} from 'src/utils';


export const RECEIVE = 'agency/receive';
export const RECEIVE_CONFIG = 'agency/receiveConfig';
export const SET_MAINTENANCE_MODE = 'agency/set-maintenance-mode';

export type Action =
  | ReceiveAgencyAction
  | ReceiveAgencyConfigAction
  | SetMaintenanceModeAction;

export type ReceiveAgencyAction = {
  type: 'agency/receive',
  // $FlowFixMe[value-as-type] [v1.32.0]
  payload: Agency,
};

export type ReceiveAgencyConfigAction = {
  type: 'agency/receiveConfig',
  payload: AgencyConfig,
};

export type SetMaintenanceModeAction = {
  type: 'agency/set-maintenance-mode',
  payload: boolean,
};

// $FlowFixMe[value-as-type] [v1.32.0]
export const receive = (payload: Agency): ReceiveAgencyAction => ({
  type: RECEIVE,
  payload,
});

export const receiveConfig = (
  payload: AgencyConfig,
): ReceiveAgencyConfigAction => ({
  type: RECEIVE_CONFIG,
  payload,
});

export const setMaintenanceMode = (
  payload: boolean,
): SetMaintenanceModeAction => ({
  type: SET_MAINTENANCE_MODE,
  payload,
});

export const getAgency: () => ThunkAction<void> = flow(
  key(() => 'agency'),
  cached((json) => receive(parsers.parse.agency(json)), {ttl: 120 * 1000}),
  fetching(),
)(() => reduxApi.get('agency', undefined, {redirect: 'manual'}));

// NOTE(gab): PUT /agency is a little weird. It doesn't accept a full version
// of the agency object
export const updateAgency =
  (agency: $Shape<Agency>): ThunkAction<void> =>
  (dispatch: Dispatch) =>
    dispatch(reduxApi.put('agency', parsers.normalize.agency(agency))).then(
      (response) => {
        dispatch(receive(parsers.parse.agency(response)));
      },
    );

export const getAgencyConfig: () => ThunkAction<void> = flow(
  key(() => 'agencyConfig'),
  // TODO (kyle): maybe lift this ttl
  cached((json) => receiveConfig(camel(json)), {ttl: 120 * 1000}),
  fetching(),
)(() => reduxApi.get('agency/config'));

export const updateBulkAgencySettings =
  (config: AgencyBulkConfigItem[]): ThunkAction<void> =>
  (dispatch: Dispatch) =>
    dispatch(reduxApi.post('agency/config/bulk-update', {data: config})).then(
      (json) => {
        dispatch(receiveConfig(camel(json)));
      },
    );
