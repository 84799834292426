// @flow strict

// $FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import type {Thread} from 'src/types/messages';
// $FlowFixMe[nonstrict-import]
import type {QueueBasedInbox} from 'src/types/chat/queues';
import type {LATQueueEntry} from 'src/types/live-agent-transfer';

import {createSelector} from 'reselect';

import {values} from 'src/utils/object';
import {first} from 'src/utils/array';


export const selectQueueBasedInboxesArray: (State) => QueueBasedInbox[] =
  createSelector(
    (state: State) => state.chat.queues.queue_based_inboxes,
    (queues): QueueBasedInbox[] => values(queues),
  );

export const selectIsQueueBasedInbox: (state: State) => boolean =
  createSelector(
    selectQueueBasedInboxesArray,
    (queues): boolean => queues.length > 0,
  );

export const selectCurrentQueue = (state: State): ?QueueBasedInbox => {
  return (
    state.chat.queues.queue_based_inboxes[
      state.chat.multiNumberInbox.selectedPhoneNumberSetId
    ] ||
    selectPrimaryQueue(state) ||
    selectFirstQueue(state)
  );
};

export const selectPrimaryQueue: (State) => ?QueueBasedInbox = createSelector(
  selectQueueBasedInboxesArray,
  (queues): ?QueueBasedInbox => queues.find((queue) => queue.is_primary),
);

export const selectCurrentQueueId: (State) => ?string = createSelector(
  selectCurrentQueue,
  (queues): ?string => queues?.queue_id,
);

export const selectFirstQueue = (state: State): ?QueueBasedInbox =>
  first(selectQueueBasedInboxesArray(state));

export const selectQueueStats: (State) => LATQueueEntry[] = createSelector(
  selectCurrentQueue,
  (state) => state.chat.queues.queues,
  (queueInbox, queues) => queues[queueInbox?.queue_id],
);

export const selectQueueThreads: (State) => Thread[] = createSelector(
  (state) => values(state.messages.threads),
  selectCurrentQueue,
  (threads, queue) =>
    threads.filter((thread) => {
      return thread.queueId === queue?.queue_id;
    }),
);

export const selectNewChatJoinLoadingStatus = (state: State): boolean =>
  state.chat.queues.isLoading;

export const selectCurrentNotificationId = (state: State): string =>
  state.chat.queues.notificationId;

export const selectQueueSoundSetting = (state: State, id: string): boolean => {
  const queues: QueueBasedInbox[] = values(
    state.chat.queues.queue_based_inboxes,
  );
  const queueWithId = queues.find((queue) => queue.queue_id === id);
  return queueWithId?.messaging_sound_setting || false;
};
