// @flow strict
import * as React from 'react';
import {useEnvironmentContext} from 'src/hooks/useEnvironmentContext';
import {useSenseAi} from 'src/hooks/useSenseAi';
import classify from 'src/utils/classify';
import {useTrackingHandler} from 'src/hooks/useTracking';
//$FlowFixMe[nonstrict-import]
import {useClipboard} from 'src/hooks/useClipboard';

import type {Tag} from 'src/reducers/sense-ai';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '@spaced-out/ui-design-system/lib/components/Modal';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {Input} from '@spaced-out/ui-design-system/lib/components/Input';
import {Textarea} from '@spaced-out/ui-design-system/lib/components/Textarea';
import {Chip} from '@spaced-out/ui-design-system/lib/components/Chip';
import {CircularLoader} from '@spaced-out/ui-design-system/lib/components/CircularLoader';
import MessageLoader from 'src/components/sense-jd-ai/message-loader.jsx';

import {
  Toast,
  ToastBody,
  ToastTitle,
  toastApi,
} from '@spaced-out/ui-design-system/lib/components/Toast';

import {
  SubTitleLarge,
  FormLabelSmall,
} from '@spaced-out/ui-design-system/lib/components/Text';

import {Icon} from '@spaced-out/ui-design-system/lib/components/Icon';

import {Feedback} from 'src/components/lib/feedback/feedback.jsx';
import Logo from 'src/images/sense-logo.svg';
import {
  LOADER_TEXTS,
  SENSE_AI_MODULE,
  SENSE_PLATFORM,
} from 'src/components/sense-ai-panel/constants.js';

import css from './email-sms-text-generator.css';


const COPY_TO_CLIPBOARD_TIMEOUT = 3000;
export const toastContent: React.Node = (
  <Toast semantic="success">
    <ToastTitle> Sense AI </ToastTitle>
    <ToastBody> Generated content has been copied to clipboard. </ToastBody>
  </Toast>
);

export const EmailTextGenerator = ({
  initialSuggestion,
  onConfirm,
  url,
  isImproving = false,
  allTags = [],
  header,
  type,
}: {
  initialSuggestion: string,
  onClose?: () => mixed,
  onConfirm?: (string) => mixed,
  isImproving?: boolean,
  url: string,
  allTags: Tag[],
  header: React.Node,
  type: string,
}): React.Node => {
  const {
    state: {prompt, generatedText, selectedTags},
    setPrompt,
    setGeneratedText,
    generateContent,
    addTag,
    removeTag,
    setShowAiPanel,
    setShowCustomComponent,
    isLoading,
  } = useSenseAi(url, {
    generatedText: initialSuggestion,
    prompt: '',
  });

  //TODO:(diwakersurya) stop gap solution. fix it later
  const [localPromptValue, setLocalPromptValue] = React.useState(prompt);
  React.useEffect(() => {
    setPrompt(localPromptValue ?? '');
  }, [localPromptValue]);

  //TODO:(diwakersurya) stop gap solution. fix it later
  const [localGeneratedTextValue, setLocalGeneratedTextValue] =
    React.useState(initialSuggestion);
  React.useEffect(() => {
    setGeneratedText(localGeneratedTextValue ?? '');
  }, [localGeneratedTextValue]);

  const handleClose = () => {
    setShowAiPanel(false);
    setShowCustomComponent(false);
  };

  const handleGenerateContent = () => {
    const payload = {prompt, tags: selectedTags, existing_text: generatedText};
    generateContent(payload).then(({generated_text}) =>
      setLocalGeneratedTextValue(generated_text),
    );
  };

  const {isExtension} = useEnvironmentContext();

  const trackAndHandleGenerateContent = useTrackingHandler(
    'Content Regenerated',
    handleGenerateContent,
    {
      selectedTags: selectedTags.length > 0 ? selectedTags : 'No Tags',
      module:
        type === 'email' || type === 'email-jobmatch'
          ? SENSE_AI_MODULE.ENGAGE_EMAIL
          : SENSE_AI_MODULE.ENGAGE_SMS,
      sensePlatform: isExtension
        ? SENSE_PLATFORM.CHROME_EXTENSION
        : SENSE_PLATFORM.WEB,
    },
  );

  const trackedAddTag = useTrackingHandler('ADD_TAG', addTag);
  const [copiedTextState, onCopyGeneratedText] = useClipboard(
    generatedText ?? '',
    {timeout: COPY_TO_CLIPBOARD_TIMEOUT},
  );

  const trackedCopyGeneratedText = useTrackingHandler(
    'COPY_MESSAGE',
    onCopyGeneratedText,
  );

  const trackedHandlePositiveFeedback = useTrackingHandler(
    'SENSE_AI_EMAIL_GENERATOR_FEEDBACK_POSITIVE',
  );
  const trackedHandleNegativeFeedback = useTrackingHandler(
    'SENSE_AI_EMAIL_GENERATOR_FEEDBACK_NEGATIVE',
  );

  React.useEffect(() => {
    if (!isImproving) {
      //make a call to generate initial content.
      handleGenerateContent();
    }
  }, []);
  const trackedHandleConfirmContent = useTrackingHandler(
    'Content Inserted',
    () => {
      onConfirm?.(generatedText ?? '');
      setShowAiPanel(false);
      setShowCustomComponent(false);
    },
    {
      module:
        type === 'email' || type === 'email-jobmatch'
          ? SENSE_AI_MODULE.ENGAGE_EMAIL
          : SENSE_AI_MODULE.ENGAGE_SMS,
      sensePlatform: isExtension
        ? SENSE_PLATFORM.CHROME_EXTENSION
        : SENSE_PLATFORM.WEB,
    },
  );

  const loadingText = isImproving
    ? 'Improving your content...'
    : 'Generating your content...';

  const textAreaLabel = isImproving
    ? 'Improved Content :'
    : 'Generated Content :';
  const generateButtonText = generatedText !== '' ? 'Regenerate' : 'Generate';
  return (
    <Modal
      initialFocus={2}
      onClose={handleClose}
      isOpen={true}
      classNames={{content: css.modalContainer}}
    >
      <ModalHeader onCloseButtonClick={handleClose}>
        <SubTitleLarge className={css.headerText}>
          <Icon name="sparkles" color="information" type="solid" />
          {header}
        </SubTitleLarge>
      </ModalHeader>
      <ModalBody>
        <div className={css.contentContainer}>
          {isLoading && (
            <div className={css.loaderContainer}>
              <MessageLoader messages={LOADER_TEXTS.engageContentTexts} />
            </div>
          )}
          <div className={css.inputAndGenerateBlock}>
            <Input
              label="Input Prompt :"
              onChange={(e) => setLocalPromptValue(e.currentTarget.value)}
              value={localPromptValue ?? ''}
              placeholder="Add prompt to improve your content below"
              size="medium"
              type="text"
              disabled={isLoading}
            />
            <Button
              type="secondary"
              iconRightName="sparkles"
              onClickCapture={
                //tracking regeneration
                generatedText === ''
                  ? handleGenerateContent
                  : trackAndHandleGenerateContent
              }
              disabled={isLoading}
            >
              {generateButtonText}
            </Button>
          </div>
          <div className={css.contentRow}>
            <Tags
              tags={allTags}
              selectedTags={selectedTags}
              handleTagClick={(value, isIncluded) =>
                isIncluded ? removeTag(value) : trackedAddTag(value)
              }
            />
          </div>
          <div className={css.textAreaContainer}>
            <Textarea
              label={textAreaLabel}
              classNames={{box: css.textAreaBox}}
              value={isLoading ? loadingText : localGeneratedTextValue ?? ''}
              onChange={(e) =>
                setLocalGeneratedTextValue(e.currentTarget.value)
              }
              placeholder="Generated Content"
              size="medium"
              locked={isLoading}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter
        classNames={{
          wrapper: classify(css.footerContainer, {
            [css.loadingFooter]: isLoading,
          }),
          actions: css.footerActions,
        }}
      >
        <Feedback
          onPositive={trackedHandlePositiveFeedback}
          onNegative={trackedHandleNegativeFeedback}
          disabled={generatedText === '' || isLoading}
        />

        <div className={css.actionButtonsContainer}>
          <Button
            type="secondary"
            iconLeftName="copy"
            disabled={isLoading}
            onClick={() => {
              trackedCopyGeneratedText();
              toastApi.show(toastContent, {
                timeout: COPY_TO_CLIPBOARD_TIMEOUT,
              });
            }}
          >
            Copy
          </Button>
          <Button
            type="primary"
            onClick={trackedHandleConfirmContent}
            disabled={isLoading}
          >
            Insert Content
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export const Tags = ({
  tags,
  selectedTags,
  handleTagClick,
  disabled = false,
}: {
  tags: Tag[],
  selectedTags: string[],
  handleTagClick: (string, boolean) => mixed,
  disabled?: boolean,
}): React.Node => {
  return (
    <>
      <FormLabelSmall color="secondary">Quick Enhancements :</FormLabelSmall>
      <div className={css.tagsContainer}>
        {tags.map((tag) => {
          const isIncluded = selectedTags.includes(tag.value);
          return (
            <Chip
              iconName={isIncluded ? 'check' : 'plus'}
              key={tag.value}
              semantic={isIncluded ? 'success' : 'primary'}
              onClick={() => handleTagClick(tag.value, isIncluded)}
              disabled={disabled}
            >
              {tag.label}
            </Chip>
          );
        })}
      </div>
    </>
  );
};
