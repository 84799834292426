// @flow strict

import * as React from 'react';

import {CONSTANTS} from 'src/components/authentication/contants.js';
import {BodyLarge} from '@spaced-out/ui-design-system/lib/components/Text';

import css from './password-instruction.css';


const PasswordInstruction = (): React.Node => {
  return (
    <div className={css.passwordInstruction}>
      <BodyLarge className={css.instruction}>
        {CONSTANTS.PASSWORD_INSRUCTION_TEXT}
      </BodyLarge>
      <div>
        <ul className={css.passwordInformation}>
          <li>
            <BodyLarge className={css.instruction}>
              {CONSTANTS.PASSWORD_INS_1}
            </BodyLarge>
          </li>
          <li>
            <BodyLarge className={css.instruction}>
              {CONSTANTS.PASSWORD_INS_2}
            </BodyLarge>
          </li>
          <li>
            <BodyLarge className={css.instruction}>
              {CONSTANTS.PASSWORD_INS_3}
            </BodyLarge>
          </li>
          <li>
            <BodyLarge className={css.instruction}>
              {CONSTANTS.PASSWORD_INS_4}
            </BodyLarge>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PasswordInstruction;
