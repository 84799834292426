// @noflow
import type {BrandingSettings, Event} from 'src/api-parsers/events';
import type {Question, Branch} from 'src/types/survey';

import find from 'lodash/find';
import clamp from 'lodash/clamp';

import Store from './base';


export type ModuleSequenceNode = {
  moduleId: string | number,
  parentModuleId: ?string,
  branchId: ?string | ?number,
  branchModuleIndex: ?number,
};

export default class SurveyStore extends Store {
  state: {
    fetching: ?Promise<void>,
    surveys: {[key: string]: Event},
    progress: {[key: string]: number},
    brandingSettings: {[key: string]: BrandingSettings},
    moduleSequences: {[key: string]: ModuleSequenceNode[]},
    branches: {[key: string]: Branch[]},
    isClientFetchDone: boolean,
  };

  constructor() {
    super('survey');

    this.state = {
      fetching: null,
      surveys: {},
      progress: {},
      brandingSettings: {},
      moduleSequences: {},
      branches: {},
      isClientFetchDone: false,
      isPossibleBot: false,
      showBotDialog: false,
      isSurveyAnswered: false,
    };
  }

  setClientFetchDone() {
    this.setState({
      isClientFetchDone: true,
    });
  }

  byId(id: string): ?Event {
    return this.state.surveys[id];
  }

  moduleSequenceById(id: string): ?(ModuleSequenceNode[]) {
    return this.state.moduleSequences[id];
  }

  // Returns the first Question object in the list of survey questions of the survey with the given surveyId
  firstQuestion(surveyId: string): ?Question {
    const survey = this.byId(surveyId);
    if (survey) {
      // First question should skip message survey type for both email/sms
      return find(
        survey.questions,
        (question) =>
          question.type !== 'message_survey_module' &&
          question.type !== 'sms_message_survey_module',
      );
    } else {
      return null;
    }
  }

  // Returns position of the first question -- it skips all message_survey_module and sms_message_survey_module
  firstQuestionPosition(surveyId: string): number {
    const survey = this.byId(surveyId);
    if (survey) {
      const firstQuestion = this.firstQuestion(surveyId);
      return this.positionInSurvey(surveyId, firstQuestion.id); // Module id is question id
    } else {
      return -1;
    }
  }

  // Returns the index position in the list of survey questions of the module with the given moduleId
  positionInSurvey(surveyId: string, moduleId: string): number {
    const survey = this.byId(surveyId);
    const moduleSequence = this.moduleSequenceById(surveyId);
    if (survey) {
      return moduleSequence.findIndex(
        (moduleSequenceNode) => moduleSequenceNode.moduleId === moduleId,
      );
    } else {
      // Should we throw an error instead?
      return -1;
    }
  }

  progressById(id: string): number {
    return this.state.progress[id] || 0;
  }

  goto(surveyId: string, position: number): void {
    const survey = this.byId(surveyId);
    const moduleSequence = this.moduleSequenceById(surveyId);
    if (survey && moduleSequence) {
      this.updateState({
        progress: {
          [surveyId]: {$set: clamp(position, 0, moduleSequence.length)},
        },
      });
    }
  }

  startFetching(surveyPromise: Promise<void>) {
    this.setState({
      fetching: surveyPromise,
    });
  }

  receiveResponse(event: Event, branches: Branch[]): void {
    this.updateState({
      fetching: {$set: null},
      surveys: {
        [event.id]: {$set: event},
      },
      branches: {
        [event.id]: {$set: branches},
      },
      progress: {
        [event.id]: 0,
      },
    });
  }

  setModuleSequence(eventId: string, moduleSequence: ModuleSequenceNode[]) {
    if (eventId && moduleSequence) {
      this.updateState({
        moduleSequences: {
          [eventId]: {$set: moduleSequence},
        },
        progress: {
          [eventId]: 0,
        },
      });
    }
  }

  setBrandingSettings(surveyId: string, brandingSettings: BrandingSettings) {
    this.updateState({
      brandingSettings: {
        [surveyId]: {
          $set: brandingSettings,
        },
      },
    });
  }

  getBrandingSettings(surveyId: string): BrandingSettings {
    return this.state.brandingSettings[surveyId] || {};
  }
  setIsPossibleBot(isPossibleBot: boolean) {
    this.setState({
      isPossibleBot,
    });
  }
  getIsPossibleBot() {
    return this.state.isPossibleBot;
  }
  setShowBotDialog(showBotDialog: boolean) {
    this.setState({
      showBotDialog,
    });
  }
  getShowBotDialog() {
    return this.state.showBotDialog;
  }
  setIsSurveyAnswered(isSurveyAnswered: boolean) {
    this.setState({
      isSurveyAnswered,
    });
  }
  getIsSurveyAnswered() {
    return this.state.isSurveyAnswered;
  }
}
