// @flow

import type {GetState, Dispatch, ThunkAction} from 'src/reducers';

import * as reduxApi from 'src/utils/redux-api-v2';
import {key, fetching, cached} from 'src/utils/redux';
import {thunkify} from 'src/utils/thunks';


export type MessagingFileUploadAction = ReceiveAllowedMediaExtensionsAction;

export const RECEIVE_ALLOWED_MEDIA_EXTENSIONS: 'chat/file-upload/receive-allowed-media-extensions' =
  'chat/file-upload/receive-allowed-media-extensions';

type ReceiveAllowedMediaExtensionsAction = {
  type: 'chat/file-upload/receive-allowed-media-extensions',
  payload: string[],
};

export const receiveAllowedMediaExtensions = (
  extensions: string[],
): ReceiveAllowedMediaExtensionsAction => ({
  type: RECEIVE_ALLOWED_MEDIA_EXTENSIONS,
  payload: extensions,
});

export const getAllowedMediaExtensions: (
  phoneNumberSetId: string,
) => ThunkAction<> = thunkify(
  key(
    (phoneNumberSetId) =>
      `chat/file-upload/allowed-media-extensions?phoneNumberSetId=${phoneNumberSetId}`,
  ),
  cached((response) => receiveAllowedMediaExtensions(response)),
  fetching(),
)(
  (phoneNumberSetId: string) => async (dispatch: Dispatch) =>
    dispatch(
      reduxApi.get(`messages_v2/allowed-media-extensions`, {
        phone_number_set_id: phoneNumberSetId,
      }),
    ),
);
