// @flow strict

/**
 * leading debounceer for async functions
 * always returns a promise, but only resolves those called at debounced
 * intervals.
 */
export const debounceAsync = <P: mixed[], R>(
  func: (...P) => Promise<R>,
  wait: number,
): ((...P) => Promise<R>) => {
  let timeout = 0;
  return function (...params: P) {
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = 0;
      }, wait);
      return Promise.resolve(func.apply(this, params));
    } else {
      clearTimeout(timeout);
      return new Promise((resolve) => {
        timeout = setTimeout(() => {
          timeout = 0;
          resolve(func.apply(this, params));
        }, wait);
      });
    }
  };
};
