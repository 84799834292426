// @flow

// $FlowFixMe[untyped-type-import]
import type {Event, SendMetadataSuppressionKeys} from 'src/api-parsers/events';
import type {
  SenseEvent,
  EventTemplate,
  EventAttachment,
} from 'src/types/events';

import Store from './base';
import findKey from 'lodash/findKey';


export type EventError = {
  errors: string[],
  status: number,
  message?: string,
};

export type EventTypeEnumType = {
  Message: 'message',
  Survey: 'survey',
  SMS_Message: 'sms_message',
  SMS_Survey: 'sms_survey',
  SMS_Chatbot: 'sms_chatbot',
  SMS_Job: 'sms_job', //SM-57
  NPS: 'nps', // exists on client side but not in DB
  SMS_NPS: 'sms_nps', // exists on client side but not in DB
  List: 'digest_email',
  BeeFree_Email: 'beefree_email',
  BeeFree_Chatbot: 'beefree_chatbot',
  Bulk_Writeback: 'bulk_writeback',
};

export const EventTypeEnum: EventTypeEnumType = {
  Message: 'message',
  Survey: 'survey',
  SMS_Message: 'sms_message',
  SMS_Survey: 'sms_survey',
  SMS_Chatbot: 'sms_chatbot',
  SMS_Job: 'sms_job', //SM-57
  NPS: 'nps', // exists on client side but not in DB
  SMS_NPS: 'sms_nps', // exists on client side but not in DB
  List: 'digest_email',
  BeeFree_Email: 'beefree_email',
  BeeFree_Chatbot: 'beefree_chatbot',
  Bulk_Writeback: 'bulk_writeback',
};

export default class EventStore extends Store {
  state: {
    editing: boolean,
    events: {[key: string | number]: SenseEvent, ...},
    eventTemplates: {[key: string | number]: EventTemplate, ...},
    saving: boolean,
    deleted: {[key: string | number]: SenseEvent, ...},
    error?: EventError,
  };

  constructor() {
    super('events');

    this.state = {
      editing: false,
      events: {},
      eventTemplates: {},
      saving: false,
      deleted: {},
    };
  }

  // $FlowFixMe[value-as-type] [v1.32.0]
  getEvent(eventId: string): Event {
    return this.state.events[eventId];
  }

  // $FlowFixMe[value-as-type] [v1.32.0]
  getEditEvent(): ?Event {
    return this.state.events['editing'];
  }

  // $FlowFixMe[value-as-type] [v1.32.0]
  getSuppressionValue({sendMetadata}: Event): ?SendMetadataSuppressionKeys {
    let suppressionValue;

    if (sendMetadata) {
      suppressionValue = findKey(sendMetadata);
    }

    return suppressionValue;
  }

  // $FlowFixMe[value-as-type] [v1.32.0]
  getTemplate(eventId: string): EventTemplate {
    return this.state.eventTemplates[eventId];
  }

  // $FlowFixMe[value-as-type] [v1.32.0]
  receive(event: Event) {
    this.updateState({
      events: {
        [event.id]: {
          $set: event,
        },
      },
    });
  }

  // $FlowFixMe[value-as-type] [v1.32.0]
  receiveTemplate(eventId: string, template: EventTemplate) {
    this.updateState({
      eventTemplates: {
        [eventId]: {
          $set: template,
        },
      },
    });
  }

  // $FlowFixMe[value-as-type] [v1.32.0]
  receiveAttachments(eventId: string, attachments: EventAttachment[]) {
    if (this.state.events[eventId]) {
      this.updateState({
        events: {
          [eventId]: {
            attachments: {
              $set: attachments,
            },
          },
        },
      });
    }
  }

  // $FlowFixMe[value-as-type] [v1.32.0]
  setEvent(event: Event) {
    this.updateState({
      events: {
        [event.id]: {
          $set: event,
        },
      },
    });
  }

  // $FlowFixMe[value-as-type] [v1.32.0]
  setTemplate(eventId: string, template: EventTemplate) {
    this.updateState({
      eventTemplates: {
        [eventId]: {
          $set: template,
        },
      },
    });
  }

  isEditing(editing: boolean): ?boolean {
    if (!arguments.length) {
      return this.state.editing;
    } else {
      this.setState({editing});
    }
  }

  setDeleted(event: SenseEvent) {
    this.setState({deleted: {...this.state.deleted, [event.id]: event}});
  }

  getDeleted(eventId: string | number): SenseEvent {
    return this.state.deleted[eventId];
  }

  setError(error: Error) {
    this.updateState({
      error: {
        $set: error,
      },
    });
  }

  getError(): ?EventError {
    return this.state.error;
  }

  clearError() {
    this.updateState({
      error: {
        $set: undefined,
      },
    });
  }
}
