// @flow strict
import * as React from 'react';
import {classify} from 'src/utils/classify';

import {Button, MenuButton} from 'src/designSystem2021Components/buttons.jsx';
import type {Option} from 'src/designSystem2021Components/menu.jsx';
import makeClassNameComponent, {
  type ClassNameComponent,
} from 'src/utils/makeClassNameComponent';

import css from './tablist.css';


export type Tab = {
  ...Option,
  subTabs?: Array<Option>,
};

export type Props = {
  tabs: Array<Tab>,
  onSelected: (option: Option) => mixed,
  selectedOption: Option,
  className?: string,
};

const TabItem: ClassNameComponent<> = makeClassNameComponent(css.tabItem);

export const TabList = (props: Props): React.Node => {
  const {tabs = {}, onSelected, selectedOption} = props;

  return (
    <div className={classify(css.wrapper, props.className)}>
      {tabs.map(({key, label, subTabs}) => {
        //note: (aditya a) think of a better way to do this
        const isSelected = subTabs
          ? key === selectedOption.key.split('.')[0]
          : key === selectedOption.key;
        return (
          <TabItem key={key} className={css.tab}>
            {subTabs ? (
              <MenuButton
                menuOptions={subTabs}
                onMenuSelect={onSelected}
                selectedOption={selectedOption}
                buttonProps={{
                  className: classify({
                    [css.selected]: isSelected,
                  }),
                  isSelected,
                  isSelectable: true,
                  type: 'nav',
                }}
              >
                {label}
              </MenuButton>
            ) : (
              <Button
                className={classify({
                  [css.selected]: isSelected,
                })}
                onClick={() => onSelected({key, label})}
                isSelected={isSelected}
                isSelectable
                type="nav"
              >
                {label}
              </Button>
            )}
          </TabItem>
        );
      })}
    </div>
  );
};
