// @flow

import * as React from 'react';

import {FilterCard} from './';

import RadioButtons from 'src/components/lib/radio-buttons/radio-buttons.jsx';

import css from './sent-card.css';


type SentCardProps = {
  value?: string,
  onChange: (value: string) => mixed,
};

const SentCard = ({
  value = 'sent_only',
  onChange,
}: SentCardProps): React.Node => (
  <FilterCard header="Show" className={css.sentCardBox}>
    <RadioButtons
      name="Sent?"
      selectedValue={value}
      onChange={onChange}
      options={[
        {value: 'skipped_only', label: 'Skipped'},
        {value: 'sent_only', label: 'Sent'},
      ]}
      className={css.filterRadioButtons}
    />
  </FilterCard>
);

export default SentCard;

export const SentSetToBothCard = (): React.Node => (
  <SentReasonCard body={'Set "sent?" to skipped or sent'} />
);

export function SentReasonCard({body}: {body: string}): React.Node {
  return (
    <FilterCard header="To enable filter" className={css.sentCardBox}>
      <div className={css.sentErrorCard}>
        <p>{body}</p>
      </div>
    </FilterCard>
  );
}
