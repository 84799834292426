// @flow strict

import * as React from 'react';

import {formatPlural} from 'src/utils/intl';


export default function FormattedPlural({
  value,
  one,
  other,
  className,
}: {
  value: number,
  one: string,
  other: string,
  className?: string,
}): React.Element<'span'> {
  return <span className={className}>{formatPlural(value, one, other)}</span>;
}
