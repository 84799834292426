// @flow strict
import * as React from 'react';
import {classify} from 'src/utils/classify';
import {useEnvironmentContext} from 'src/hooks/useEnvironmentContext';

import {SenseAi} from 'src/components/sense-ai/sense-ai.jsx';
//$FlowFixMe[nonstrict-import]
import {AnalyticsService} from 'src/analytics';
import {
  SENSE_AI_MODULE,
  SENSE_PLATFORM,
} from 'src/components/sense-ai-panel/constants.js';
// $FlowFixMe[nonstrict-import]
import useAgencyConfig from 'src/hooks/useAgencyConfig';
// $FlowFixMe[nonstrict-import]
import usePrivilege from 'src/hooks/usePrivilege';

import {EmailTextGenerator} from 'src/components/sense-ai/email-sms-text-generator.jsx';
import {
  Toast,
  ToastBody,
  toastApi,
  ToastTitle,
} from '@spaced-out/ui-design-system/lib/components/Toast';

import css from './sense-ai-wrapper.css';


export const toastContent: React.Node = (
  <Toast semantic="success">
    <ToastTitle> Sense AI </ToastTitle>
    <ToastBody>Your content pasted successfully </ToastBody>
  </Toast>
);

type EngageEmailSenseAiWrapperProps = {
  initialSuggestion?: string,
  onConfirm: (string) => mixed,
  journeyId: string,
  touchpointId: string,
  defaultIntroOpen: boolean,
  isImproving?: boolean,
  allTags: {label: string, value: string}[],
  header: React.Node,
  type: 'email' | 'sms' | 'email-jobmatch' | 'sms-jobmatch',
  showIntro: boolean,
};

export const EngageEmailSenseAiWrapper = ({
  onConfirm,
  initialSuggestion = '',
  journeyId,
  touchpointId,
  defaultIntroOpen,
  isImproving = initialSuggestion.length > 40,
  allTags,
  header,
  type = 'sms',
  showIntro = true,
}: EngageEmailSenseAiWrapperProps): React.Node => {
  const canUseEngageContentGenerator = usePrivilege(
    'USE_ENGAGE_CONTENT_GENERATOR',
  );

  const {isExtension} = useEnvironmentContext();

  const handleTrackEvents = (event) => {
    AnalyticsService.track(event, {
      module:
        type === 'email' || type === 'email-jobmatch'
          ? SENSE_AI_MODULE.ENGAGE_EMAIL
          : SENSE_AI_MODULE.ENGAGE_SMS,
      sensePlatform: isExtension
        ? SENSE_PLATFORM.CHROME_EXTENSION
        : SENSE_PLATFORM.WEB,
    });
  };

  return (
    canUseEngageContentGenerator && (
      <>
        <div
          className={classify(css.engageEmailSenseAiContainer, {
            [css.email]: type === 'email',
            [css.sms]: type === 'sms',
            [css.emailJobMatch]: type === 'email-jobmatch',
            [css.smsJobMatch]: type === 'sms-jobmatch',
          })}
        >
          <SenseAi
            heading="Write with AI Copilot"
            description="Leverage AI to generate copy or enhance what you have written"
            confirmButtonText="Try now"
            defaultIntroOpen={defaultIntroOpen}
            showIntro={showIntro}
            onTrackEvent={handleTrackEvents}
          >
            <EmailTextGenerator
              initialSuggestion={initialSuggestion}
              onConfirm={(text) => {
                onConfirm(text);
                toastApi.show(toastContent, {timeout: 2000});
              }}
              url={`workflows/${journeyId}/events/${touchpointId}/suggest`}
              isImproving={isImproving}
              allTags={allTags}
              header={header}
              type={type}
            />
          </SenseAi>
        </div>
      </>
    )
  );
};
