// @flow strict

import type {Split, SplitData, SplitsPayloadData} from 'src/types/split';
import {
  TEMP_END_NODE_IDENTIFIER,
  TEMP_NODE_IDENTIFIER,
} from 'src/utils/automation-workflow';
import {
  createRuleHash,
  createRulesHash,
  getDefaultFilterBlock,
  getScopeFromActionRule,
  isRuleValueEmpty,
  normalizeAbtRule,
  normalizeEMSRule,
  parseEMSRule,
  parseActionRule,
} from 'src/utils/filter';
import cloneDeep from 'lodash/cloneDeep';
import {isNumeric} from 'lodash-isnumeric';
import type {OperatorOptions} from 'src/types/filter';
import uniqueId from 'lodash/uniqueId';
import type {NextNodeApi, Swimlane} from 'src/types/automation-workflow';


export const DEFAULT_OPERATOR_OPTION = {
  string: 'eq',
  number: 'eq',
  date: 'eq',
  boolean: true,
  email: 'eq',
  phone: 'eq',
};

export const OPERATOR_OPTIONS: OperatorOptions = {
  string: [
    {
      value: 'eq',
      label: 'is',
      tooltip: 'status = "placed"',
    },
  ],
  number: [
    {
      value: 'eq',
      label: 'equals',
      tooltip: 'value = 5',
    },
  ],
  currency: [
    {
      value: 'eq',
      label: 'equals',
      tooltip: 'value = 5',
    },
  ],
  date: [
    {
      value: 'eq',
      label: 'is equal to',
      tooltip: 'date_added = 2023-06-09 ',
    },
  ],

  boolean: [
    {
      value: 'true',
      label: 'is true',
      tooltip: 'candidate/active is true',
    },
    {
      value: 'false',
      label: 'is false',
      tooltip: 'candidate/active is false',
    },
  ],
  email: [
    {
      value: 'eq',
      label: 'is',
      tooltip: 'status = "placed"',
    },
  ],
  phone: [
    {
      value: 'eq',
      label: 'is',
      tooltip: 'number = +12123231324',
    },
  ],
};

export const MAX_SPLITS = 7;

export const MAX_RULES_IN_SPLIT = 7;

export const getCombinedId = (swid: string, nodeId: string): string => {
  return `${swid};${nodeId}`;
};

export const generateTempSplitNode = (
  swid: string,
  ifEndNodeId: string,
  elseEndNodeId: string,
): SplitData => {
  return {
    [`${swid};${TEMP_NODE_IDENTIFIER}`]: [
      {
        id: 'temp:if',
        title: 'If',
        block: {rules: [], scope: null},
        tid: ifEndNodeId,
      },
      {
        id: 'temp:else',
        title: 'Else (Rest of all)',
        block: {rules: [], scope: null},
        tid: elseEndNodeId,
      },
    ],
  };
};

export const checkIfSplitPathIsValid = (
  splitPath: string,
  splitArray: Array<Split>,
): boolean => {
  return splitArray.some(({id}) => id === splitPath);
};

const getCurrentSplitInfoFromPreviousSplit = (title) => {
  let postfixNumber = 1;
  const lastCharacter = title[title.length - 1];
  if (!title.toLowerCase().endsWith('if') && isNumeric(lastCharacter)) {
    postfixNumber = parseInt(lastCharacter, 10) + 1;
  }
  const id = `temp:elseif_${String(postfixNumber)}`;
  const newTitle = `Else IF-${String(postfixNumber)}`;
  return {
    id,
    title: newTitle,
  };
};

export const getSplitContent = (
  firstSplitInfo: Split,
  previousSplitTitle: string,
  tempEndNodeId: string,
): Split => {
  const {id, title} = getCurrentSplitInfoFromPreviousSplit(previousSplitTitle);
  return {
    id,
    title,
    block: cloneDeep(firstSplitInfo.block),
    tid: tempEndNodeId,
  };
};

export const getCurrentSelectedIndex = (
  splitArray: Array<Split>,
  splitData: ?Split,
): number => {
  if (!splitArray || !splitData) {
    return 0;
  }
  return splitArray.findIndex(({id}) => id === splitData.id);
};

export const getUniqueTempEndNode = (): string => {
  return uniqueId(TEMP_END_NODE_IDENTIFIER);
};

const isInvalidRulePresent = (rules) => {
  return rules.some((rule) => {
    if (typeof rule.operator === 'boolean') {
      return false;
    }
    if (!rule.operator) {
      return true;
    }
    if (isRuleValueEmpty(rule)) {
      return true;
    }
  });
};

export const validateSplits = (
  currentSplitId: ?string,
  splitArray: Array<Split>,
): {
  currentSplitRuleHashCountMap: Map<string, number>,
  warningsArray: Array<string>,
  noRuleExists: boolean,
  splitsWithDuplicateRules: Array<string>,
  splitsWithInvalidRules: Array<string>,
  addedSplitsExceedMaximum: boolean,
  addedRulesExceedMaximum: boolean,
  anyScopeIsInvalid: boolean,
} => {
  const currentSplit = splitArray.find(({id}) => id === currentSplitId);
  const currentSplitRuleHashCountMap =
    currentSplit?.block.rules.reduce((acc, rule) => {
      const hash = createRuleHash(rule);
      const val = acc.get(hash);
      if (val) {
        return acc.set(hash, val + 1);
      }
      return acc.set(hash, 1);
    }, new Map()) ?? new Map();
  //todo(vish): this logic can be simplified, take it later.
  const warnings: Map<string, Array<string>> = new Map();
  for (let i = 0; i < splitArray.length; i++) {
    if (splitArray[i].block.rules.length > 0) {
      const outerRulesHash = createRulesHash(splitArray[i].block.rules);
      for (let j = i + 1; j < splitArray.length; j++) {
        const innerRulesHash = createRulesHash(splitArray[j].block.rules);
        if (outerRulesHash === innerRulesHash) {
          if (!warnings.has(splitArray[i].title)) {
            warnings.set(splitArray[i].title, []);
          }
          warnings.set(splitArray[i].title, [
            ...(warnings.get(splitArray[i].title) ?? []),
            splitArray[j].title,
          ]);
        }
      }
    }
  }
  const condensedGroups = condenseRules(warnings);
  const warningsArray = condensedGroups.map((group) => {
    const joinedGroups = group.join(', ');
    return `${joinedGroups} are having the same rules. Change value to add this split.`;
  });

  const noRuleExists =
    splitArray && splitArray[0] && splitArray[0].block.rules.length === 0;

  function isDuplicateRulePresent(rules) {
    const hashCountMap = rules.reduce((acc, rule) => {
      const hash = createRuleHash(rule);
      const val = acc.get(hash);
      if (val) {
        return acc.set(hash, val + 1);
      }
      return acc.set(hash, 1);
    }, new Map());
    return [...hashCountMap.values()].some((count) => count > 1);
  }

  const splitsWithDuplicateRules = splitArray.reduce((acc, split) => {
    if (isDuplicateRulePresent(split.block.rules)) {
      acc.push(split.title);
    }
    return acc;
  }, []);

  const splitsWithInvalidRules = splitArray.reduce((acc, split) => {
    if (isInvalidRulePresent(split.block.rules)) {
      acc.push(split.title);
    }
    return acc;
  }, []);

  const invalidRuleExistsInAnySplit = splitArray.some(({block}) => {
    return block.rules.some((rule) => {
      if (typeof rule.operator === 'boolean') {
        return false;
      }
      if (!rule.operator) {
        return true;
      }
      if (isRuleValueEmpty(rule)) {
        return true;
      }
    });
  });

  const addedSplitsExceedMaximum = currentSplit
    ? splitArray.length > MAX_SPLITS
    : false;

  const addedRulesExceedMaximum =
    splitArray &&
    splitArray[0] &&
    splitArray[0].block.rules.length > MAX_RULES_IN_SPLIT;

  const anyScopeIsInvalid = splitArray.some(({block}) => {
    if (!block.scope) {
      return false;
    }
    return (
      block.scope.direction === 'next' &&
      (block.scope.timespan == null || block.scope.timespan === '0')
    );
  });

  return {
    currentSplitRuleHashCountMap,
    warningsArray,
    noRuleExists,
    splitsWithDuplicateRules,
    splitsWithInvalidRules,
    addedSplitsExceedMaximum,
    addedRulesExceedMaximum,
    anyScopeIsInvalid,
  };
};

function condenseRules(ruleMap) {
  const groups = [];

  function findGroup(id) {
    return groups.find((group) => group.includes(id));
  }

  ruleMap.forEach((value, key) => {
    const existingGroup = findGroup(key);
    if (existingGroup) {
      value.forEach((relatedId) => {
        if (!existingGroup.includes(relatedId)) {
          existingGroup.push(relatedId);
          const relatedGroup = findGroup(relatedId);
          if (relatedGroup) {
            relatedGroup.forEach((id) => {
              if (!existingGroup.includes(id)) {
                existingGroup.push(id);
              }
            });
            groups.splice(groups.indexOf(relatedGroup), 1);
          }
        }
      });
    } else {
      const newGroup = [key, ...value];
      groups.push(newGroup);
    }
  });

  return groups;
}

export const getSplitsPayload = (
  splitArray: Array<Split>,
  from_node_id: string,
): Array<SplitsPayloadData> => {
  const splitsData = splitArray.reduce((outerAcc, {block, tid}) => {
    const conditions = block.rules.reduce((acc, rule) => {
      const normalizedRule =
        rule.type === 'ems'
          ? normalizeEMSRule(rule)
          : normalizeAbtRule(rule, block.scope);
      if (normalizedRule) {
        if (rule.type === 'ems') {
          acc.push({conditions: [normalizedRule]});
        } else {
          acc.push({conditions: normalizedRule});
        }
      }
      return acc;
    }, []);
    const obj = {};
    obj.conditions = {
      blocks: [
        {
          rules: conditions,
        },
      ],
    };
    obj.from_node_id = from_node_id;
    obj.to_node_id = tid.startsWith(TEMP_END_NODE_IDENTIFIER) ? null : tid;
    outerAcc.push(obj);
    return outerAcc;
  }, []);
  // This part is a bit weird but we need to add an end date field
  // to the last split of splits array ,if we have an abt rule, because backend requires it
  const scope = splitArray[0].block.scope;
  if (scope != null) {
    const abtRule = splitArray[0].block.rules.find(
      ({type}) => type === 'prevNode',
    );
    if (abtRule && abtRule.type === 'prevNode') {
      const endDateField = abtRule.scopeFields.end_date;
      let offset, endValue;
      if (scope && scope.timespan == null) {
        offset = 0;
        endValue = 'plus_infinity';
      } else {
        offset = parseInt(scope.timespan, 10);
        endValue = 'today';
      }
      splitsData[splitsData.length - 1].conditions.blocks[0].rules.push({
        conditions: [
          {
            field: endDateField,
            value: endValue,
            offset,
            operator: 'gt',
          },
        ],
      });
    }
  }

  //$FlowFixMe todo(vish)
  return splitsData;
};

const generateTitleFromIndex = (index, totalTransitions) => {
  if (index === 0) {
    return 'If';
  }
  if (index === totalTransitions - 1) {
    return 'Else (Rest of all)';
  }
  return `Else IF-${index}`;
};

const generateSplitsArray = (transitions) => {
  let scope = null;
  let scopeParsed = false;
  return transitions.map((transition, index) => {
    const rules = [];
    (transition.condition.blocks[0].rules ?? []).forEach((rule) => {
      //There shouldn't be any rules for else
      if (rule.conditions.length === 0) {
        return;
      }
      if (rule.conditions.length > 1) {
        //$FlowFixMe todo(vish): NextNodeApi is not correctly typed in automation-workflow types. It is not considering Filter/Split. Fix this.
        rules.push(parseActionRule(rule.conditions));
        if (!scopeParsed) {
          //$FlowFixMe todo(vish): NextNodeApi is not correctly typed in automation-workflow types. It is not considering Filter/Split. Fix this.
          scope = getScopeFromActionRule(rule.conditions);
          scopeParsed = true;
        }
      } else {
        //$FlowFixMe todo(vish): NextNodeApi is not correctly typed in automation-workflow types. It is not considering Filter/Split. Fix this.
        rules.push(parseEMSRule(rule.conditions[0]));
      }
    });
    return {
      id: String(index),
      title: generateTitleFromIndex(index, transitions.length),
      tid: transition.next_node,
      block: {
        rules: index === transitions.length - 1 ? [] : rules,
        scope,
      },
    };
  });
};

export const parseSplits = (swimlanes: Array<Swimlane>): SplitData => {
  return swimlanes.reduce((acc, {id, nodes}) => {
    const swimlaneId = id;
    Object.keys(nodes).forEach((nodeId) => {
      if (
        nodeId.startsWith('branching') &&
        //$FlowFixMe todo(vish): We need to fix the typing of AutomationNodeApi
        nodes[nodeId].body.branching_node_type === 'CONDITIONAL_SPLIT'
      ) {
        const combinedSwidSplitId = `${swimlaneId};${nodeId}`;
        acc[combinedSwidSplitId] = generateSplitsArray(
          nodes[nodeId].next_nodes,
        );
      }
    });
    return acc;
  }, {});
};
