// @flow strict

import * as React from 'react';

import css from './toggle.css';

import classify from 'src/utils/classify';

/**
 * Note:
 * Do not wrap Toggle in a label. For simplicity's sake, Toggle uses an
 * internal label tag to handle click delegation to the hidden input, and
 * nesting labels is a bad idea.
 */

type Props = {
  className?: string,
  toggleContainer?: string,
  toggleClassName?: string,
  children?: React.Node,
  checked?: mixed,
  onChange?: (SyntheticInputEvent<HTMLInputElement>) => mixed,
  disabled?: mixed,
  qaId?: string,
};

export const Toggle = ({
  className,
  toggleContainer,
  toggleClassName,
  children,
  qaId,
  disabled,
  ...props
}: Props): React.Node => (
  <label
    className={classify(toggleContainer || css.container, className)}
    data-qa-id={`${qaId ? qaId + '-' : ''}toggle`}
  >
    <input type="checkbox" {...props} data-qa-id="toggle-input" />
    <div
      className={classify(toggleClassName || css.toggle, {
        [css.disabled]: disabled,
      })}
    >
      {children}
    </div>
  </label>
);

export default Toggle;
