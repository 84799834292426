// @noflow
import type {SurveyResponse, Question, Answer} from 'src/api-parsers/events';
import type {SurveyError} from 'src/types/survey';

import logger from 'src/utils/logger';

import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import isString from 'lodash/isString';

import Store from './base';
import {omit} from 'lodash';


export default class SurveyResponseStore extends Store {
  state: {
    fetching: ?Promise<SurveyResponse>,
    currentError: ?SurveyError,
    responses: {[key: string]: SurveyResponse},
  };

  constructor() {
    super('survey-response');

    this.state = {
      fetching: null, //TODO(marcos): make this per-survey
      currentError: null,
      responses: {},
    };
  }

  startFetching(promise: Promise<SurveyResponse>) {
    this.setState({
      fetching: promise,
    });
  }

  removeDummyResponse() {
    if (this.state.responses['dummyEventResponseId']) {
      this.setState({
        responses: omit(this.state.responses, 'dummyEventResponseId'),
      });
    } else {
      logger.log(
        '[Info] dummyEventResponseId key is not present in surveyResponses, therefore, not removing it.',
      );
    }
  }

  receiveResponse(response: SurveyResponse) {
    if (response.id) {
      this.updateState({
        fetching: {$set: null},
        responses: {
          [response.id]: {$set: response},
        },
      });
    } else {
      logger.log('received survey response without id');
    }
  }

  byId(id: string): ?SurveyResponse {
    return this.state.responses[id];
  }

  byEventId(eventId: string): ?SurveyResponse {
    return find(this.state.responses, {eventId});
  }

  recordResponse(responseId: string, questionId: number, data: Answer) {
    if (data) {
      // TODO we are using the responseModules as a collection even though the datastructure is an array. We can refactor this to be an collection someday to reflect its usage and clean up this code.
      const responsePosition = findIndex(
        this.state.responses[responseId]?.responseModules,
        (response) => response?.moduleId === questionId,
      );
      if (responsePosition > -1) {
        this.updateState({
          responses: {
            [responseId]: {
              responseModules: {
                [responsePosition]: {$set: data},
              },
            },
          },
        });
      } else {
        this.updateState({
          responses: {
            [responseId]: {
              responseModules: {$push: data},
            },
          },
        });
      }
    }
  }

  getCurrentError() {
    return this.state.currentError;
  }

  setCurrentError(currentError: ?SurveyError) {
    this.setState({currentError});
  }

  validate(question: Question, answer: Answer): boolean {
    const isValid = (val: any): boolean => {
      if (val === undefined) {
        return false;
      }
      if (val === null) {
        return false;
      }
      if (isString(val) && val.trim() === '') {
        return false;
      }
      return true;
    };

    if (question.required && !isValid(answer.moduleValue)) {
      this.setCurrentError({
        message: 'This response is required.',
      });
      return false;
    }
    this.setCurrentError(null);
    return true;
  }
}
