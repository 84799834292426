// @flow strict

import * as React from 'react';

import ErrorPage from './error-page.jsx';

import css from './500.css';


const DeviceNotSupported = (): React.Node => (
  <ErrorPage pageTitle="Device Not Supported" drawMinimal>
    <h2 className={css.verySmallTextTitle}>Device Not Supported,</h2>
    <span>Please log in on a desktop computer to access Sense.</span>
  </ErrorPage>
);

export default DeviceNotSupported;
