//@flow strict

export const getBrandDisplayName = (
  brandName: ?string,
  isPrimary: boolean,
): string => {
  if (brandName) {
    return isPrimary ? `${brandName} (Default)` : brandName;
  }
  return 'Unknown Brand';
};
