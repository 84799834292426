// @flow strict

import type {RerouterLocation, Params} from './types';
import type {BaseHistory} from './history/base';
import {useContext, useState, useEffect} from 'react';
import {HistoryContext} from './history-context';
import {RouteContext} from './route-context';


export function useHistory(): BaseHistory {
  return useContext(HistoryContext).history;
}

export function useLocation(): RerouterLocation {
  return useHistory().location;
}

export function useParams(): Params {
  return useContext(RouteContext).params;
}

export function useHashState<T: string>(
  impliedHash?: T,
  allowedHashes?: T[],
): T | string {
  const [currentHash, setCurrentHash] = useState<T | string>(impliedHash ?? '');
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '') {
      setCurrentHash(impliedHash ?? '');
    } else {
      const maybeTab = location.hash.replace(/^#/, '');
      if (allowedHashes) {
        if (allowedHashes.includes(maybeTab)) {
          const foundTab = allowedHashes.find((tab) => tab === maybeTab);
          if (foundTab) {
            setCurrentHash(foundTab);
          }
        }
      } else {
        setCurrentHash(maybeTab);
      }
    }
  }, [location, impliedHash, allowedHashes]);
  return currentHash;
}
