// @flow strict

export const POSITIONS = {
  TOP_CENTER: 'topCenter',
  TOP_LEFT: 'topLeft',
  TOP_RIGHT: 'topRight',
  BOTTOM_LEFT: 'bottomLeft',
  BOTTOM_RIGHT: 'bottomRight',
  BOTTOM_CENTER: 'bottomCenter',
};

export const VARIANTS = {
  SUCCESS: 'Success',
  ERROR: 'Error',
  WARNING: 'Warning',
  INFO: 'Info',
};

export const ACTIONS = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
};
