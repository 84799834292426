// @noflow
import type {SentEventWithExternals} from 'src/api-parsers/events';
import Store from './base';


export default class AudienceMemberSentEventStore extends Store {
  state: {
    [key: string]: SentEventWithExternals[],
  };

  constructor() {
    super('audience-member-sent-events');

    this.state = {};
  }

  get(audienceMemberId: string): SentEventWithExternals[] {
    return this.state[audienceMemberId];
  }

  receive(audienceMemberId: string, sentEvents: SentEventWithExternals[]) {
    this.updateState({
      [audienceMemberId]: {
        $set: sentEvents,
      },
    });
  }
}
