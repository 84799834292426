// @flow strict

//$FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';
import type {
  JobVariables,
  JobVariablesMap,
  JobVariableValues,
  JobVariableValue,
} from 'src/types/job-variables';

import {createSelector} from 'reselect';

/*
 * Base Selectors
 */

export const selectJobVariables = (state: State): JobVariables =>
  state.jobVariables.variables || [];

export const selectJobVariableMap: (
  state: State,
) => JobVariablesMap = createSelector(selectJobVariables, (jobVariables) =>
  jobVariables.reduce((acc, variable) => {
    acc.set(variable.name, variable);
    return acc;
  }, new Map()),
);

export const selectJobVariableValues = (state: State): JobVariableValues =>
  state.jobVariables.variableValues || {};

export const selectJobVariableValuesList: (
  state: State,
) => JobVariableValue[] = createSelector(
  selectJobVariableValues,
  (jobVariableValues) =>
    /** api response is of the form {matched_job/location/0:bangalore,matched_job/location/1:hyderabad}
     * normalize it to the form [{name:matched_job/location,value:bangalore,index:0}] to be used on the ui
     */
    // $FlowFixMe[prop-missing]
    Object.keys(jobVariableValues)
      .map((key) => {
        const keyArr = key.split('/');
        const index = keyArr.pop();
        const name = keyArr.join('/');
        // $FlowFixMe[prop-missing]
        return {
          name,
          index: parseInt(index, 10),
          value: jobVariableValues[key],
          apiKey: key,
        };
      })
      .sort((a, b) => a.index - b.index),
);
