// @noflow

import * as React from 'react';

// TODO (kyle): use new link icon
import LinkIcon from 'src/images/editor/ic_insert_link_black_18px.svg';

import css from './linked-content-notice.css';


const LinkedContentNotice = ({children}) => (
  <div className={css.linkedContentNotice}>
    <div className={css.connector}>
      <div className={css.line} />
      <LinkIcon className={css.linkedContentIcon} />
      <div className={css.line} />
    </div>
    <span>{children}</span>
  </div>
);

export default LinkedContentNotice;

export const LinkedSurveyNotice = () => (
  <div className={css.linkedContentNotice}>
    <div className={css.connector}>
      <div className={css.line} />
      <LinkIcon className={css.linkedContentIcon} />
      <div className={css.line} />
    </div>
    <span>Linked survey.</span>
  </div>
);
