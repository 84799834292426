// @noflow

import type {ContentBlock, ContentState} from 'draft-js';
import type {MenuOption} from '@spaced-out/ui-design-system/lib/components/Menu/Menu';
import type {OrderedMap} from 'immutable';
import type {
  DynamicLabel as DynamicLabelType,
  DynamicLabelExtra,
} from 'src/action-creators/dynamic-labels';

import React from 'react';
import {
  EditorState,
  SelectionState,
  Modifier,
  AtomicBlockUtils,
} from 'draft-js';

import chunk from 'lodash/chunk';
import keyBy from 'lodash/keyBy';

import {
  entityColorMapping,
  generateAttributeGrammarTokens,
} from 'src/utils/entities';
import classify from 'src/utils/classify';
import {insertText} from 'src/utils/draft';
import {JOB_WITH_LINK_IDENTIFIER} from 'src/utils/job-match';

import {useContextTip} from 'src/components/lib/mouse-tip/mouse-tip.jsx';
import TruncatedText from 'src/components/lib/truncated-text';
import {Chip} from '@spaced-out/ui-design-system/lib/components/Chip';

import {
  dynamicRegex,
  escapedRegex,
  dynamicLabelText,
  ENITITY_DYNAMIC_LABEL,
  ENITITY_PLACEHOLDER,
  escapedRegexParenthesis,
  dynamicRegexParenthesis,
} from './utils';
import AttachmentIcon from 'src/images/attachment.svg';

import common from 'src/styles/common.css';
import css from './dynamic-text.css';
import labelCss from './dynamic-labels.css';


const labelTipId = Symbol('Label Tooltip Id');

const mustacheRegex = /\{\{([^}]+)\}\}/g;
const tempVarRegex = /\?([^\s\?]+)\?/g;

export const singleDynamicRegex = /^<(.*)>$/;

// $FlowFixMe -- get types from draft-js
export function findDynamicText(contentBlock, callback, labels = []) {
  const text = contentBlock.getText();

  let match, start;
  while ((match = dynamicRegex.exec(text)) !== null) {
    start = match.index;

    if (escapedRegex.exec(match[0]) === null) {
      const labelProps = dynamicLabelText(match[0]);
      // FIXME(marcos): an issue sometimes where labels = [undefined]
      if (labels.filter(Boolean).length === 0) {
        // if no labels are set at all, label anything that looks like a label
        callback(start, start + match[0].length, labelProps);
      }

      if (labels.filter(Boolean).length > 0) {
        const found = labels.some(
          (l) =>
            labelProps.label &&
            l.toLowerCase() === labelProps.label.toLowerCase(),
        );

        if (found) {
          // otherwise, only wrap text that is in approved list
          callback(start, start + match[0].length, labelProps);
        }
      }
    }
  }
}

type AttachmentLinkProps = {
  children: React.Node,
};

const AttachmentLink = (props: AttachmentLinkProps) => (
  <span className={css.label}>{props.children}</span>
);

// find {survey_link}
const strategyWithAttachmentLink = (pattern: string) => {
  const regex = new RegExp(pattern, 'g');
  return (
    contentBlock: ContentBlock,
    callback: (start: number, stop: number, data: any) => void,
  ) => {
    const text = contentBlock.getText();

    let match, start;
    while ((match = regex.exec(text)) !== null) {
      start = match.index;
      callback(start, start + match[0].length, {});
    }
  };
};

export const AttachmentLinkDecorator = (pattern: string) => ({
  component: AttachmentLink,
  strategy: strategyWithAttachmentLink(pattern),
});

export const SurveyLinkDecorator = AttachmentLinkDecorator('survey_link');
export const ChatbotLinkDecorator = AttachmentLinkDecorator('chat_link');

type DynamicLabelProps = {
  children: React.Node,
  type: string,
  className?: string,
  onClick?: (event: SyntheticMouseEvent<>) => mixed,
};

// TODO(marcos): className for the labels
const Label = (props: DynamicLabelProps) => (
  <span
    className={classify(
      props.className,
      css.label,
      css.dynamicLabel,
      css[`label${props.type}`],
    )}
    onClick={props.onClick}
  >
    {props.children}
  </span>
);

type AutomationLabelComponentProps = {
  children: React.Node,
  className?: string,
  onClick?: (event: SyntheticMouseEvent<HTMLElement>) => mixed,
  option?: {key: string, label: string},
};

export const AutomationLabel = ({children}: AutomationLabelComponentProps) => {
  return <span className={css.colorText}>{children}</span>;
};

type MultiEntityLabelComponentProps = {
  children: React.Node,
  type: string,
  className?: string,
  onClick?: (event: SyntheticMouseEvent<HTMLElement>) => mixed,
  baseEntityType: EntityType,
  option?: DynamicLabelExtra,
  useNewDesignSystem: boolean,
};

export const MultiEntityLabel = ({
  children,
  className,
  onClick,
  option,
  useNewDesignSystem,
}: MultiEntityLabelComponentProps) => {
  const isTooltipLabel = Boolean(option?.entityRelationshipPath);
  const tipContent = React.useMemo(
    () => (option ? <LabelTooltipContent dynamicLabel={option} /> : null),
    [option],
  );
  const [componentRef, handleContextMenu, handleClose, isActive] =
    useContextTip({
      id: labelTipId,
      content: tipContent,
      fixedTo: 'top',
      buttons: onClick && {Delete: onClick},
      className: labelCss.tooltip,
    });
  const colorClassName = entityColorMapping[option?.sense_name]?.name;
  return useNewDesignSystem ? (
    <span
      className={css.colorText}
      ref={componentRef}
      onContextMenu={isTooltipLabel ? handleContextMenu : null}
      onClick={
        isTooltipLabel
          ? isActive
            ? handleClose
            : onClick ?? handleContextMenu
          : onClick
      }
    >
      {children}
    </span>
  ) : (
    <span
      className={classify(className, labelCss.token, labelCss[colorClassName], {
        [labelCss.active]: isActive,
        [labelCss.disabled]: !isTooltipLabel,
      })}
      ref={componentRef}
      onContextMenu={isTooltipLabel ? handleContextMenu : null}
      onClick={
        isTooltipLabel
          ? isActive
            ? handleClose
            : onClick ?? handleContextMenu
          : onClick
      }
    >
      {children}
    </span>
  );
};

export const JobLinkLabel = ({
  children,
  className,
  onClick,
  option,
  useNewDesignSystem,
}: MultiEntityLabelComponentProps) => {
  const isTooltipLabel = Boolean(option?.entityRelationshipPath);
  const tipContent = React.useMemo(
    () => (option ? <LabelTooltipContent dynamicLabel={option} /> : null),
    [option],
  );
  const [componentRef, handleContextMenu, handleClose, isActive] =
    useContextTip({
      id: labelTipId,
      content: tipContent,
      fixedTo: 'top',
      buttons: onClick && {Delete: onClick},
      className: labelCss.tooltip,
    });
  const colorClassName = entityColorMapping[option?.sense_name]?.name;
  return (
    <>
      <span
        className={classify(
          className,
          labelCss.token,
          labelCss[colorClassName],
          labelCss.jobLinkLabel,
          {
            [labelCss.active]: isActive,
            [labelCss.disabled]: !isTooltipLabel,
          },
        )}
        ref={componentRef}
        onContextMenu={isTooltipLabel ? handleContextMenu : null}
        onClick={
          isTooltipLabel
            ? isActive
              ? handleClose
              : onClick ?? handleContextMenu
            : onClick
        }
      >
        <span className={css.linkTextIndication}>LINK</span>
        <span className={css.attachmentIcon}>
          <AttachmentIcon />
        </span>
        {children}
      </span>
    </>
  );
};

function getEntityColors(senseName: string): {
  text: string,
  background: string,
  border: string,
} {
  const entityColor = entityColorMapping[senseName] || {
    name: 'gray',
    text: 'colorGray4',
    background: 'colorGray9',
    border: 'colorGray7',
  };
  return Object.entries(entityColor).reduce(
    (colors, [styleName, colorName]) => {
      if (styleName !== 'name') {
        colors[styleName] = common[colorName];
      }
      return colors;
    },
    {},
  );
}

function LabelTooltipContent({
  dynamicLabel,
}: {
  dynamicLabel: DynamicLabelExtra,
}) {
  const colors = getEntityColors(dynamicLabel.sense_name);
  const {entityRelationshipPath} = dynamicLabel;

  const tokenChunks = chunk(
    generateAttributeGrammarTokens(entityRelationshipPath),
    2,
  );

  return (
    <div className={css.tooltip}>
      <TruncatedText
        className={css.tooltipVariableName}
        style={{
          color: colors.text,
        }}
        text={entityRelationshipPath[0]}
        limit={60}
        component="h3"
      />
      <div
        className={css.tooltipEntityType}
        style={{
          color: colors.text,
        }}
      >
        Type: {dynamicLabel.mapping.display_name}
      </div>
      <div className={css.grammarTokens}>
        {tokenChunks.map(([prefix, token]) => (
          <p
            key={`${dynamicLabel.value}${prefix}${token}`}
            className={css.tokenLine}
          >
            {`${prefix} `}
            <TruncatedText
              className={css.token}
              text={token}
              limit={60}
              component="span"
            />
          </p>
        ))}
      </div>
    </div>
  );
}

export const DynamicLabelEntry = {
  strategy: findDynamicText,
  components: {
    global_variable: (props: DynamicLabelProps) => (
      <Label {...props} type="Global" />
    ),
    member: (props: DynamicLabelProps) => (
      <Label {...props} type="Consultant" />
    ),
    placement: (props: DynamicLabelProps) => (
      <Label {...props} type="Placement" />
    ),
  },
};

type DynamicLabel = {
  end: number,
  start: number,
  labelProps: {
    attrs: {
      label: string,
      [key: string]: string,
    },
  },
};
export const getLabels = (
  contentState: ContentState,
): OrderedMap<string, DynamicLabel[]> => {
  const blocks = contentState.getBlockMap();

  const fields: OrderedMap<string, DynamicLabel[]> = blocks.map((block) => {
    const text = block.getText();
    let match, start;

    const results = [];
    while ((match = dynamicRegex.exec(text)) !== null) {
      start = match.index;

      if (escapedRegex.exec(match[0]) === null) {
        const labelProps = dynamicLabelText(match[0]);
        const labelData = {labelProps, start, end: start + match[0].length};
        results.push(labelData);
      }
    }
    return results;
  });
  return fields;
};

export const findMeDynamicText =
  (labels: DynamicLabelExtra[]) =>
  (
    contentBlock: ContentBlock,
    callback: (start: number, stop: number, data: mixed) => void,
  ) => {
    const text = contentBlock.getText();
    let match, start;
    while ((match = dynamicRegex.exec(text)) !== null) {
      start = match.index;
      if (escapedRegex.exec(match[0]) === null) {
        const labelProps = dynamicLabelText(match[0]);
        if (labels.length === 0) {
          // if no labels are set at all, label anything that looks like a label
          callback(start, start + match[0].length, labelProps);
        }
        if (labelProps.label && labels.length > 0) {
          const label = labelProps.label.toLowerCase().trim();

          const found = labels.find(
            ({name, value}) =>
              name.toLowerCase().trim() === label ||
              value.toLowerCase().trim() === label,
          );
          if (found) {
            // otherwise, only wrap text that is in approved list
            callback(start, start + match[0].length, found, label);
          }
        }
      }
    }
  };

export const findAutomationEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, stop: number, label: mixed) => mixed,
  contentState: ContentState,
) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'AUTOMATION_VARIABLE'
      );
    },
    (start, end) => {
      const entityKey = contentBlock.getEntityAt(start);
      if (entityKey) {
        const entity = contentState.getEntity(entityKey);
        if (entity) {
          callback(start, end, entity.getData());
        }
      }
    },
  );
};

export const findMeEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, stop: number, label: mixed) => mixed,
  contentState: ContentState,
) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === ENITITY_DYNAMIC_LABEL
      );
    },
    (start, end) => {
      const entityKey = contentBlock.getEntityAt(start);
      if (entityKey) {
        const entity = contentState.getEntity(entityKey);
        if (entity) {
          callback(start, end, entity.getData());
        }
      }
    },
  );
};

export const AutomationLabelComponent =
  (automationLabels: [{key: string, label: string}]) =>
  ({
    contentState,
    entityKey,
    className,
    children,
    decoratedText,
  }: {
    contentState: ContentState,
    entityKey: string,
    children: React.Node,
    className?: string,
    decoratedText: string,
  }) => {
    const lowerLabel = decoratedText.toLowerCase();
    const automationLabel = entityKey
      ? contentState.getEntity(entityKey).getData()
      : automationLabels.find(
          (label) =>
            label.key.toLowerCase() === lowerLabel ||
            label.label.toLowerCase() === lowerLabel,
        );
    return (
      <AutomationLabel className={className} option={automationLabel}>
        {children}
      </AutomationLabel>
    );
  };

export const DynamicLabelComponent =
  (labels: DynamicLabel[], useNewDesignSystem: boolean) =>
  ({
    contentState,
    entityKey,
    className,
    children,
    decoratedText,
  }: {
    contentState: ContentState,
    entityKey: string,
    children: React.Node,
    className?: string,
    decoratedText: string,
  }) => {
    const labelProps = dynamicLabelText(decoratedText);
    const lowerLabel = labelProps.label.toLowerCase();

    const dynamicLabel = entityKey
      ? contentState.getEntity(entityKey).getData()
      : // TODO (kyle): this feels heavy
        labels.find(
          (label) =>
            label.name.toLowerCase() === lowerLabel ||
            label.value.toLowerCase() === lowerLabel,
        );

    //(diwakersurya) handle special case for job link variable here
    if (dynamicLabel && dynamicLabel.name === JOB_WITH_LINK_IDENTIFIER) {
      //className = classify(className, css.jobTitleLink);
      //return a diff component here.
      return (
        <JobLinkLabel
          className={className}
          option={dynamicLabel}
          useNewDesignSystem={useNewDesignSystem}
        >
          {children}
        </JobLinkLabel>
      );
    }
    return (
      <MultiEntityLabel
        className={className}
        option={dynamicLabel}
        useNewDesignSystem={useNewDesignSystem}
      >
        {children}
      </MultiEntityLabel>
    );
  };

export const automationLabelDecorator = (
  automationLabels: [{key: string, label: string}],
) => {
  return {
    strategy: findDynText(automationLabels),
    component: AutomationLabelComponent(automationLabels),
  };
};

export const dynamicLabelDecorator = (dynamicLabels: DynamicLabel[]) => {
  // TODO (Ashwini): Should update the window.location with any of the hooks
  const pathname = window.location.pathname;
  const useNewDesignSystem =
    pathname.includes('messages') || pathname.includes('automation-workflow')
      ? true
      : false;
  return {
    strategy: findMeDynamicText(dynamicLabels),
    component: DynamicLabelComponent(dynamicLabels, useNewDesignSystem),
  };
};

function getDynamicLabelTokenString(
  label: DynamicLabelExtra,
  value: string = label.name,
): string {
  const template = label.template || '<$>';
  return template.replace('$', value);
}

function getAutomationTokenString(
  automationLabel,
  value: string = automationLabel.label,
): string {
  const template = '{{$}}';
  return template.replace('$', value);
}

type GifData = {images: {original: {url: string}}, title: string};

export const addGif = (
  editorState: EditorState,
  selectionState: SelectionState,
  data: GifData,
) => {
  let contentState = editorState.getCurrentContent();
  contentState = contentState.createEntity('GIF', 'IMMUTABLE', {
    url: data.images.original.url,
    name: data.title,
  });

  const entityKey = contentState.getLastCreatedEntityKey();

  editorState = EditorState.push(
    editorState,
    contentState,
    'change-block-data',
  );

  return AtomicBlockUtils.insertAtomicBlock(
    editorState,
    entityKey,
    `![${data.title}.gif](${data.images.original.url})`,
  );
};

export const addTemplateVariable = (
  editorState: EditorState,
  selectionState: SelectionState,
  data: GifData, //TODO: update type here
): EditorState => {
  let contentState = editorState.getCurrentContent();
  contentState = contentState.createEntity(
    'TEMPLATE_VARIABLE',
    'IMMUTABLE',
    data,
  );

  const entityKey = contentState.getLastCreatedEntityKey();

  contentState = Modifier.replaceText(
    contentState,
    selectionState,
    `{${data.display_name}}`,
    null,
    entityKey,
  );

  editorState = EditorState.push(editorState, contentState, 'insert-fragment');

  return EditorState.forceSelection(
    editorState,
    contentState.getSelectionAfter(),
  );
};

export const addJobResultVariable = (
  editorState: EditorState,
  selectionState: SelectionState,
  data: {value: string, display_name: string},
): EditorState => {
  let contentState = editorState.getCurrentContent();
  contentState = contentState.createEntity(
    'JOB_RESULT_ATTRIBUTE',
    'IMMUTABLE',
    {name: data.value, job_attribute: data.value},
  );

  const entityKey = contentState.getLastCreatedEntityKey();

  contentState = Modifier.replaceText(
    contentState,
    selectionState,
    `{${data.display_name}}`,
    null,
    entityKey,
  );

  editorState = EditorState.push(editorState, contentState, 'insert-fragment');

  return EditorState.forceSelection(
    editorState,
    contentState.getSelectionAfter(),
  );
};

export const addChatbotLink = (
  editorState: EditorState,
  selectionState: SelectionState,
): EditorState => {
  let contentState = editorState.getCurrentContent();
  contentState = contentState.createEntity('CHATBOT_LINK', 'IMMUTABLE', {
    template: '$',
    name: 'long_url',
  });

  const entityKey = contentState.getLastCreatedEntityKey();

  contentState = Modifier.replaceText(
    contentState,
    selectionState,
    '[Link to Chatbot]',
    null,
    entityKey,
  );

  editorState = EditorState.push(editorState, contentState, 'insert-fragment');

  return EditorState.forceSelection(
    editorState,
    contentState.getSelectionAfter(),
  );
};

export const addHyperlink = (
  editorState: EditorState,
  selectionState: SelectionState,
  data: {
    url: string,
    label: string,
    onEdit: () => void,
    onRemove: () => void,
  },
): EditorState => {
  let contentState = editorState.getCurrentContent();
  contentState = contentState.createEntity('HYPERLINK', 'IMMUTABLE', data);

  const entityKey = contentState.getLastCreatedEntityKey();

  contentState = Modifier.replaceText(
    contentState,
    selectionState,
    data.url,
    null,
    entityKey,
  );

  contentState = Modifier.insertText(
    contentState,
    contentState.getSelectionAfter(),
    ' ',
  );

  editorState = EditorState.push(editorState, contentState, 'insert-fragment');

  return EditorState.forceSelection(
    editorState,
    contentState.getSelectionAfter(),
  );
};

export const addAutomationLabel = (
  editorState: EditorState,
  selectionState: SelectionState,
  automationLabel: {key: string, labal: string},
) => {
  let contentState = editorState.getCurrentContent();
  contentState = contentState.createEntity(
    'AUTOMATION_VARIABLE',
    'IMMUTABLE',
    automationLabel,
  );
  const entityKey = contentState.getLastCreatedEntityKey();
  contentState = Modifier.replaceText(
    contentState,
    selectionState,
    getAutomationTokenString(automationLabel),
    null,
    entityKey,
  );
  contentState = Modifier.insertText(
    contentState,
    contentState.getSelectionAfter(),
    ' ',
  );
  editorState = EditorState.push(editorState, contentState, 'insert-fragment');
  return EditorState.forceSelection(
    editorState,
    contentState.getSelectionAfter(),
  );
};

export const addDynamicLabel = (
  editorState: EditorState,
  selectionState: SelectionState,
  dynamicLabel: DynamicLabelType,
  entityType: 'DYNAMIC_LABEL' | 'NODE_VARIABLE' = 'DYNAMIC_LABEL',
  useValue?: boolean,
) => {
  let contentState = editorState.getCurrentContent();
  contentState = contentState.createEntity(
    entityType,
    'IMMUTABLE',
    dynamicLabel,
  );

  const entityKey = contentState.getLastCreatedEntityKey();
  //contentState = Modifier.applyEntity(contentState, selectionState, entityKey);

  contentState = Modifier.replaceText(
    contentState,
    selectionState,
    useValue
      ? getDynamicLabelTokenString(dynamicLabel, dynamicLabel.value)
      : getDynamicLabelTokenString(dynamicLabel),
    null,
    entityKey,
  );
  contentState = Modifier.insertText(
    contentState,
    contentState.getSelectionAfter(),
    ' ',
  );

  editorState = EditorState.push(editorState, contentState, 'insert-fragment');

  return EditorState.forceSelection(
    editorState,
    contentState.getSelectionAfter(),
  );
};

const addAutomationLabels = (
  contentState: ContentState,
  blockKey: string,
  entries: Array<{
    start: number,
    end: number,
    label: {key: string, label: string},
  }>,
) => {
  let currentBlock = contentState.getBlockForKey(blockKey);
  let offset = 0;
  for (const {label, start, end} of entries) {
    contentState = contentState.createEntity(
      'AUTOMATION_VARIABLE',
      'IMMUTABLE',
      label,
    );
    const entityKey = contentState.getLastCreatedEntityKey();
    const selectionState = SelectionState.createEmpty(blockKey).merge({
      anchorOffset: start + offset,
      focusOffset: end + offset,
    });
    contentState = Modifier.replaceText(
      contentState,
      selectionState,
      getAutomationTokenString(label),
      null,
      entityKey,
    );
    const nextBlock = contentState.getBlockForKey(blockKey);
    offset += nextBlock.getLength() - currentBlock.getLength();
    currentBlock = nextBlock;
  }
  return contentState;
};

const addMeDynamicLabels = (
  contentState: ContentState,
  blockKey: string,
  entries: Array<{
    start: number,
    end: number,
    label: DynamicLabelType,
  }>,
  useValue?: boolean,
) => {
  let currentBlock = contentState.getBlockForKey(blockKey);
  let offset = 0;

  for (const {label, start, end} of entries) {
    contentState = contentState.createEntity(
      ENITITY_DYNAMIC_LABEL,
      'IMMUTABLE',
      label,
    );

    const entityKey = contentState.getLastCreatedEntityKey();
    const selectionState = SelectionState.createEmpty(blockKey).merge({
      anchorOffset: start + offset,
      focusOffset: end + offset,
    });

    contentState = Modifier.replaceText(
      contentState,
      selectionState,
      useValue
        ? getDynamicLabelTokenString(label, label.value)
        : getDynamicLabelTokenString(label),
      null,
      entityKey,
    );

    const nextBlock = contentState.getBlockForKey(blockKey);
    offset += nextBlock.getLength() - currentBlock.getLength();
    currentBlock = nextBlock;
  }

  return contentState;
};

export const getPlainText = (editorState: EditorState): string => {
  const content = editorState.getCurrentContent();

  return content
    .getBlockMap()
    .map((block) => block.getText())
    .toArray()
    .join('\n');
};

// Shortcut utility: Use this to add some text to an editorState wherever its
// current selection is.
export const insertTextAtSelection = (
  value: string,
  editorState: EditorState,
): EditorState => insertText(editorState, value);

export const findDynText =
  (labels: [{key: string, label: string}]) =>
  (
    contentBlock: ContentBlock,
    callback: (start: number, stop: number, data: mixed) => void,
  ) => {
    const text = contentBlock.getText();
    let match, start;
    while ((match = dynamicRegexParenthesis.exec(text)) !== null) {
      start = match.index;
      if (escapedRegexParenthesis.exec(match[0]) === null) {
        const labelProps = match[1];
        if (labels.length === 0) {
          // if no labels are set at all, label anything that looks like a label
          callback(start, start + match[0].length, labelProps);
        }
        if (labelProps && labels.length > 0) {
          const matchedLabel = labelProps.toLowerCase().trim();
          const found = labels.find(
            ({key, label}) =>
              label.toLowerCase().trim() === matchedLabel ||
              key.toLowerCase().trim() === matchedLabel,
          );
          if (found) {
            callback(start, start + match[0].length, found, matchedLabel);
          }
        }
      }
    }
  };

export const getEditorStateWithAutomationLabelsAsEntities = (
  editorState: EditorState,
  labels: MenuOption[],
): EditorState => {
  let newEditorState = editorState;
  const contentState = editorState.getCurrentContent();
  const blocks = contentState.getBlocksAsArray();
  const _findDynText = findDynText(labels);
  blocks.forEach((block) => {
    const blockKey = block.getKey();
    const dynamicLabelMatches = [];
    // find all variables in text e.g. `{{variable}}`
    _findDynText(
      block,
      (start, end, label, text) =>
        dynamicLabelMatches.push({start, end, label, text}),
      labels,
    );
    // find all DYNAMIC_LABEL entities in the text
    let existingDynamicLabelEntities = {};
    findAutomationEntities(
      block,
      (start, end, label) =>
        (existingDynamicLabelEntities[`${start}:${end}`] = {
          start,
          end,
          label,
        }),
      contentState,
    );
    let newContentState = newEditorState.getCurrentContent();
    // for each `{{variable}}` that has no attached entity, attach one.
    const labelsToAdd = dynamicLabelMatches.filter(
      ({start, end}) => !existingDynamicLabelEntities[`${start}:${end}`],
    );

    newContentState = addAutomationLabels(
      newContentState,
      blockKey,
      labelsToAdd,
    );
    const newBlock = newContentState.getBlockForKey(blockKey);
    const blockText = newBlock.getText();
    let textChangeOffset = 0;

    existingDynamicLabelEntities = {};
    findAutomationEntities(
      block,
      (start, end, label) =>
        (existingDynamicLabelEntities[`${start}:${end}`] = {
          start,
          end,
          label,
        }),
      contentState,
    );
    // for each existing entity, if its dynamic label has a new display
    // name, replace the text in the state.
    for (const {start, end, label} of Object.values(
      existingDynamicLabelEntities,
    )) {
      const newLabel = labels.find(({key}) => key === label.key);
      if (!newLabel) {
        continue;
      }
      const text = blockText.slice(start, end);
      const newText = getAutomationTokenString(newLabel);
      // if the labels are unchanged, we probably don't need to do anything else
      if (newText === text) {
        continue;
      }
      const selectionState = SelectionState.createEmpty(blockKey).merge({
        anchorOffset: start + textChangeOffset,
        focusOffset: end + textChangeOffset,
      });

      // the existing text already has an entity key, we keep it so that
      // when we replace the text we preserve the key, ditto for any
      // style that has been set on the to-be-replaced string
      const existingKey = block.getEntityAt(start);
      const existingStyle = block.getInlineStyleAt(start);

      // if we found a new label, we generally trust that the label present at
      // runtime is 'fresher' or more up to date than the one we saved previously
      // so we will merge the old entity's data with the new label we found
      newContentState = newContentState.mergeEntityData(existingKey, newLabel);

      newContentState = Modifier.replaceText(
        newContentState,
        selectionState,
        newText,
        existingStyle,
        existingKey,
      );

      textChangeOffset += newText.length - text.length;
    }
    newEditorState = EditorState.push(
      newEditorState,
      newContentState,
      'change-block-data',
    );
  });
  return newEditorState;
};

/**
 * modifies the given editorState so that any text variables or draft entities
 * show their current Sense Entity display name.
 */
export const getMeEditorStateWithDynamicLabelsAsEntities = (
  editorState: EditorState,
  labels: DynamicLabel[],
  useValue?: boolean,
): EditorState => {
  let newEditorState = editorState;
  const contentState = editorState.getCurrentContent();
  const blocks = contentState.getBlocksAsArray();

  const _findMeDynamicText = findMeDynamicText(labels);

  blocks.forEach((block) => {
    const blockKey = block.getKey();
    const dynamicLabelMatches = [];

    // find all variables in text e.g. `<variable>`
    _findMeDynamicText(
      block,
      (start, end, label, text) =>
        dynamicLabelMatches.push({start, end, label, text}),
      labels,
    );

    // find all DYNAMIC_LABEL entities in the text
    let existingDynamicLabelEntities = {};
    findMeEntities(
      block,
      (start, end, label) =>
        (existingDynamicLabelEntities[`${start}:${end}`] = {
          start,
          end,
          label,
        }),
      contentState,
    );

    let newContentState = newEditorState.getCurrentContent();

    // for each `<variable>` that has no attached entity, attach one.
    const labelsToAdd = dynamicLabelMatches.filter(
      ({start, end}) => !existingDynamicLabelEntities[`${start}:${end}`],
    );

    newContentState = addMeDynamicLabels(
      newContentState,
      blockKey,
      labelsToAdd,
      useValue,
    );

    const newBlock = newContentState.getBlockForKey(blockKey);
    const blockText = newBlock.getText();
    let textChangeOffset = 0;

    existingDynamicLabelEntities = {};
    findMeEntities(
      block,
      (start, end, label) =>
        (existingDynamicLabelEntities[`${start}:${end}`] = {
          start,
          end,
          label,
        }),
      contentState,
    );
    // for each existing entity, if its dynamic label has a new display
    // name, replace the text in the state.
    for (const {start, end, label} of Object.values(
      existingDynamicLabelEntities,
    )) {
      const newLabel = labels.find(({value}) => value === label.value);

      if (!newLabel) {
        continue;
      }

      const text = blockText.slice(start, end);
      const newText = useValue
        ? getDynamicLabelTokenString(newLabel, newLabel.value)
        : getDynamicLabelTokenString(newLabel);

      // if the labels are unchanged, we probably don't need to do anything else
      if (newText === text) {
        continue;
      }

      const selectionState = SelectionState.createEmpty(blockKey).merge({
        anchorOffset: start + textChangeOffset,
        focusOffset: end + textChangeOffset,
      });

      // the existing text already has an entity key, we keep it so that
      // when we replace the text we preserve the key, ditto for any
      // style that has been set on the to-be-replaced string
      const existingKey = block.getEntityAt(start);
      const existingStyle = block.getInlineStyleAt(start);

      // if we found a new label, we generally trust that the label present at
      // runtime is 'fresher' or more up to date than the one we saved previously
      // so we will merge the old entity's data with the new label we found
      newContentState = newContentState.mergeEntityData(existingKey, newLabel);

      newContentState = Modifier.replaceText(
        newContentState,
        selectionState,
        newText,
        existingStyle,
        existingKey,
      );

      textChangeOffset += newText.length - text.length;
    }

    // TODO (kyle): move this outside the loop?
    newEditorState = EditorState.push(
      newEditorState,
      newContentState,
      'change-block-data',
    );
  });
  return newEditorState;
};

export function normalizeAutomationLabelsInString(
  labels: [{key: string, label: string}],
  text: string,
) {
  const labelMap = keyBy(labels, 'label');

  return text.replace(
    dynamicRegexParenthesis,
    (match, displayName, displayName2) => {
      displayName = displayName || displayName2;
      const label = labelMap[displayName];
      if (label) {
        return getAutomationTokenString(label, label.key);
      }
      return match;
    },
  );
}

export function normalizeDynamicLabelsInString(
  labels: Iterable<{value: string, name: string, ...}>,
  string: string,
) {
  const labelMap = keyBy(labels, 'name');

  return string.replace(dynamicRegex, (match, displayName, displayName2) => {
    displayName = displayName || displayName2;
    const label = labelMap[displayName];
    if (label) {
      return getDynamicLabelTokenString(label, label.value);
    }
    return match;
  });
}

/**
 * Takes a label map and string with variables shown as label values
 * and replaces the label values with label name
 *
 * For a specific variable with these attributes: {
 *  value: 'firstName',
 *  name: 'First Name',
 * }
 * An example input could be 'Hi, <firstName>'
 * where this function would return 'Hi, <First Name>'
 */
export function formatDynamicLabelsInString(
  labelMap: Iterable<{value: string, name: string, ...}>,
  string: string = '',
) {
  const labelsByValue = keyBy(labelMap, 'value');

  return string.replace(dynamicRegex, (match, labelValue, labelValue2) => {
    labelValue = labelValue || labelValue2;
    const label = labelsByValue[labelValue];
    if (label) {
      return getDynamicLabelTokenString(label);
    }
    return match;
  });
}

// TODO (kyle): when core-js allows, use `matchAll()`
export function* findDynamicLabelsInString(
  string: string,
): Generator<string, void, void> {
  let match;
  while ((match = dynamicRegex.exec(string)) !== null) {
    // $FlowIssue match is guarenteed not to be null
    yield match[1] || match[2];
  }
}

export function* findMustacheLabelsInString(
  string: string,
): Generator<string, void, void> {
  let match;
  while ((match = mustacheRegex.exec(string)) !== null) {
    // $FlowIssue match is guarenteed not to be null
    yield match[1];
  }
}

export function* findTempLabelsInString(
  string: string,
): Generator<string, void, void> {
  let match;
  while ((match = tempVarRegex.exec(string)) !== null) {
    // $FlowIssue match is guarenteed not to be null
    yield match[1];
  }
}

export const TokenWithChip = ({
  children,
  className,
  onClick,
  option,
}: MultiEntityLabelComponentProps) => {
  const isTooltipLabel = Boolean(option?.entityRelationshipPath);
  const tipContent = React.useMemo(
    () => (option ? <LabelTooltipContent dynamicLabel={option} /> : null),
    [option],
  );
  const [componentRef, handleContextMenu, handleClose, isActive] =
    useContextTip({
      id: labelTipId,
      content: tipContent,
      fixedTo: 'top',
      buttons: onClick && {Delete: onClick},
      className: labelCss.tooltip,
    });
  const colorClassName = entityColorMapping[option?.sense_name]?.name;

  return (
    <div
      ref={componentRef}
      onContextMenu={isTooltipLabel ? handleContextMenu : null}
    >
      <Chip
        dismissable
        classNames={{
          wrapper: classify(
            className,
            css.tokenChip,
            // currently, we are not overriding the custom CSS for the chip component
            // labelCss[colorClassName],
            // {
            //   [labelCss.active]: isActive,
            //   [labelCss.disabled]: !isTooltipLabel,
            // },
          ),
        }}
        onDismiss={
          isTooltipLabel
            ? isActive
              ? handleClose
              : onClick ?? handleContextMenu
            : onClick
        }
        semantic="primary"
      >
        {children}
      </Chip>
    </div>
  );
};
