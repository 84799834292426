// @flow strict
import * as React from 'react';
import {useDispatch} from 'react-redux';
// $FlowFixMe[nonstrict-import]
import type {ModalComponent} from 'src/types/modal';
// $FlowFixMe[nonstrict-import]
import type {ModalType} from 'src/components/modals/modal-root.jsx';
// $FlowFixMe[nonstrict-import]
import {popModal} from 'src/action-creators/modal';
//$FlowFixMe[nonstrict-import]
import BaseModal from 'src/components/modals/base-modal.jsx';
import InfoIcon from 'src/images/icons/warning-info-icon.svg';
import DeleteIcon from 'src/images/delete-icon.svg';
import {Header, Paragraph} from 'src/designSystem2021Components/text-v2.jsx';
import {Button} from 'src/designSystem2021Components/buttons.jsx';
import css from './referral-withdraw.css';


export default function ReferralWithdrawContainer({
  type,
}: {
  type: ModalType | ModalComponent,
}): React.Node {
  const dispatch = useDispatch();

  return (
    <BaseModal
      type={type}
      confirmText="Yes"
      abortText="No"
      removeModal={() => dispatch(popModal())}
      className={css.baseModal}
    >
      <div className={css.modalContainer}>
        <div className={css.modalHeader}>
          <Header>
            <InfoIcon /> Withdraw Candidature
          </Header>
          <DeleteIcon />
        </div>
        <div className={css.modalBody}>
          <Paragraph>
            Are you sure you want to withdraw your candidature from this
            referral submission?
          </Paragraph>
        </div>
        <div className={css.modalFooter}>
          <Button>Yes</Button>
          <Button type="tertiary">No</Button>
        </div>
      </div>
    </BaseModal>
  );
}
