// @flow strict

import type {
  Entity,
  KPIData,
  Filters,
  FilterByID,
  MetricsType,
  FiltersOptions,
  SelectedFilters,
  JourneyOverviewDataTable,
  SelectedFilterColumnsData,
  SavedDashboardFiltersList,
  SavedDashboardFiltersListData,
  CurrentJourneyAndTouchpointBreadCrumbData,
} from 'src/types/analytics-genesis';
import type {LineSeriesItem} from '@spaced-out/ui-design-system/lib/components/Charts';
import type {DateRangeWithTimezone} from '@spaced-out/ui-design-system/lib/types';
import type {AnalyticsJourneysOverviewActions} from 'src/action-creators/analytics/analytics-journeys-overview';

import {
  SET_SELECTED_FILTERS,
  SET_SELECTED_ENTITIES,
  SET_SELECTED_JOURNEYS,
  SET_SELECTED_DATE_RANGE_AND_FREQUENCY,
  SET_SELECTED_METRICS_TYPE,
  SET_CURRENT_JOURNEY_AND_TOUCHPOINT_DATA,
  SET_CHART_FREQUENCY,
  RECEIVE_FILTERS_OPTIONS,
  RECEIVE_SENT_FROM_OPTIONS,
  RECEIVE_JOURNEY_OPTIONS,
  RECEIVE_KPI_DATA,
  RECEIVE_JOURNEY_PLOT_DATA,
  RECEIVE_JOURNEY_OVERVIEW_TABLE,
  RECEIVE_TOUCHPOINT_OVERVIEW_TABLE,
  RECEIVE_PEOPLE_OVERVIEW_TABLE,
  RECEIVE_JOURNEY_TABLE_COLUMNS,
  RECEIVE_TOUCHPOINT_TABLE_COLUMNS,
  RECEIVE_PEOPLE_TABLE_COLUMNS,
  RECEIVE_SAVED_DASHBOARDS,
  SET_CURRENT_DASHBOARD,
  CLEAR_SELECTED_FILTERS,
  SET_LOADING_DASHBOARD_FILTERS,
} from 'src/action-creators/analytics/analytics-journeys-overview';
import {
  getSubtractedDays,
  getUTCStartOfDay,
  getUTCEndOfDay,
  formatIsoDate,
} from 'src/utils/date-time-2';


export type AnalyticsJourneysOverviewState = {
  selectedFilters: SelectedFilters,
  selectedEntities: Array<Entity>,
  selectedJourneys: Array<FilterByID>,
  selectedDateRange: DateRangeWithTimezone,
  selectedMetricsType: MetricsType,
  currentJourneyAndTouchpointData: CurrentJourneyAndTouchpointBreadCrumbData | null,
  filtersOptions: FiltersOptions,
  sentFromOptions: Array<string>,
  KPIData: KPIData | null,
  journeyPlotData: Array<LineSeriesItem> | null,
  journeyTableData: JourneyOverviewDataTable | null,
  touchpointTableData: JourneyOverviewDataTable | null,
  peopleTableData: JourneyOverviewDataTable | null,
  chartFrequency: string,
  journeysColumnFilter: SelectedFilterColumnsData | null,
  touchpointColumnFilter: SelectedFilterColumnsData | null,
  peopleColumnFilter: SelectedFilterColumnsData | null,
  saveDashboardFilterList: {[key: string]: SavedDashboardFiltersListData},
  currentDashboard: SavedDashboardFiltersList | null,
  isDashboardFiltersLoading: boolean,
};

const initialState: AnalyticsJourneysOverviewState = {
  selectedFilters: {
    journey_lifecycle_goals: [],
    journey_business_goals: [],
    journey_categories: [],
    entity_data_filter: [],
    journey_status: [],
    recipient_type: [],
    from_address: [],
    journey_ids: [],
    event_types: [],
  },
  selectedEntities: [{id: new Date().getTime()}],
  selectedJourneys: [],
  selectedDateRange: {
    startDateUTC: getUTCStartOfDay(getSubtractedDays(formatIsoDate(), 30)),
    startDate: getSubtractedDays(formatIsoDate(), 30),
    endDateUTC: getUTCEndOfDay(formatIsoDate()),
    endDate: formatIsoDate(),
  },
  selectedMetricsType: 'overview',
  currentJourneyAndTouchpointData: null,
  filtersOptions: {
    journey_lifecycle_goal: [],
    journey_business_goal: [],
    journey_category: [],
    journey_status: [],
    recipient_type: [],
    journey_name: [],
    event_name: [],
    event_type: [],
  },
  sentFromOptions: [],
  KPIData: null,
  journeyPlotData: null,
  journeyTableData: null,
  touchpointTableData: null,
  peopleTableData: null,
  chartFrequency: 'weekly',
  journeysColumnFilter: null,
  touchpointColumnFilter: null,
  peopleColumnFilter: null,
  saveDashboardFilterList: {},
  currentDashboard: null,
  isDashboardFiltersLoading: true,
};

export default (
  state: AnalyticsJourneysOverviewState = initialState,
  action: AnalyticsJourneysOverviewActions,
): AnalyticsJourneysOverviewState => {
  switch (action.type) {
    case SET_SELECTED_FILTERS: {
      const {selectedFilters} = state;
      return {
        ...state,
        selectedFilters: {
          ...selectedFilters,
          ...action.payload,
        },
      };
    }
    case SET_SELECTED_ENTITIES: {
      return {...state, selectedEntities: action.payload};
    }
    case SET_SELECTED_JOURNEYS: {
      return {
        ...state,
        selectedJourneys: action.payload,
      };
    }
    case SET_SELECTED_DATE_RANGE_AND_FREQUENCY: {
      const {chartFrequency, ...dateRange} = action.payload;
      return {
        ...state,
        selectedDateRange: dateRange,
        chartFrequency,
      };
    }
    case SET_SELECTED_METRICS_TYPE: {
      return {...state, selectedMetricsType: action.payload};
    }
    case SET_CHART_FREQUENCY: {
      return {...state, chartFrequency: action.payload};
    }
    case SET_CURRENT_JOURNEY_AND_TOUCHPOINT_DATA: {
      return {
        ...state,
        currentJourneyAndTouchpointData: {
          ...state.currentJourneyAndTouchpointData,
          ...action.payload,
        },
        selectedFilters: {
          ...state.selectedFilters,
          journey_ids: [],
        },
        selectedJourneys: [],
      };
    }

    case RECEIVE_FILTERS_OPTIONS: {
      const {filtersOptions} = state;
      return {
        ...state,
        filtersOptions: {
          ...filtersOptions,
          ...action.payload,
        },
        selectedDateRange: {
          startDateUTC: getUTCStartOfDay(
            getSubtractedDays(formatIsoDate(), 30),
          ),
          startDate: getSubtractedDays(formatIsoDate(), 30),
          endDateUTC: getUTCEndOfDay(formatIsoDate()),
          endDate: formatIsoDate(),
        },
        selectedMetricsType: 'overview',
      };
    }
    case RECEIVE_SENT_FROM_OPTIONS: {
      return {
        ...state,
        sentFromOptions: action.payload,
      };
    }
    case RECEIVE_JOURNEY_OPTIONS: {
      const {filtersOptions} = state;
      return {
        ...state,
        filtersOptions: {
          ...filtersOptions,
          journey_name: action.payload,
        },
      };
    }
    case RECEIVE_KPI_DATA: {
      return {...state, KPIData: action.payload};
    }
    case RECEIVE_JOURNEY_PLOT_DATA: {
      return {...state, journeyPlotData: action.payload};
    }
    case RECEIVE_JOURNEY_OVERVIEW_TABLE: {
      return {...state, journeyTableData: action.payload};
    }
    case RECEIVE_TOUCHPOINT_OVERVIEW_TABLE: {
      return {...state, touchpointTableData: action.payload};
    }
    case RECEIVE_PEOPLE_OVERVIEW_TABLE: {
      return {...state, peopleTableData: action.payload};
    }
    case RECEIVE_JOURNEY_TABLE_COLUMNS: {
      return {...state, journeysColumnFilter: action.payload};
    }
    case RECEIVE_TOUCHPOINT_TABLE_COLUMNS: {
      return {...state, touchpointColumnFilter: action.payload};
    }
    case RECEIVE_PEOPLE_TABLE_COLUMNS: {
      return {...state, peopleColumnFilter: action.payload};
    }
    case CLEAR_SELECTED_FILTERS: {
      return {
        ...state,
        selectedFilters: {
          journey_lifecycle_goals: [],
          journey_business_goals: [],
          journey_categories: [],
          entity_data_filter: [],
          journey_status: [],
          recipient_type: [],
          from_address: [],
          journey_ids: [],
          event_types: [],
        },
        selectedJourneys: [],
        selectedEntities: [{id: new Date().getTime()}],
      };
    }
    case SET_CURRENT_DASHBOARD: {
      const {filters} = action.payload ?? {};
      const {
        event_types = [],
        journey_ids = [],
        from_address = [],
        journey_status = [],
        recipient_type = [],
        journey_categories = [],
        journey_business_goals = [],
        journey_lifecycle_goals = [],
        entity_data_filter = [{id: new Date().getTime()}],
      }: Filters = filters ?? {};
      return {
        ...state,
        currentDashboard: action.payload,
        selectedFilters: {
          ...state.selectedFilters,
          event_types,
          from_address,
          journey_status,
          recipient_type,
          journey_categories,
          journey_business_goals,
          journey_lifecycle_goals,
          journey_ids: journey_ids.map(({id}) => id),
        },
        selectedJourneys: journey_ids,
        selectedEntities: entity_data_filter,
      };
    }
    case RECEIVE_SAVED_DASHBOARDS: {
      const {filters, dashboard} = action.payload;
      return {
        ...state,
        saveDashboardFilterList: {
          ...state.saveDashboardFilterList,
          [dashboard]: filters,
        },
      };
    }
    case SET_LOADING_DASHBOARD_FILTERS: {
      return {
        ...state,
        isDashboardFiltersLoading: action.payload,
      };
    }
  }

  return state;
};
