// @flow strict

import * as React from 'react';
import css from './task.css';
import {useSelector, useDispatch} from 'react-redux';
import maincss from 'src/components/agency-onboard/agency-onboard.css';

import {Clickable, Smallest} from 'src/designSystem2021Components/text-v2.jsx';
import {VARIANTS} from 'src/designSystem2021Components/toast';

// $FlowFixMe[nonstrict-import]
import {
  // $FlowFixMe[nonstrict-import]
  pushSidepanel,
  // $FlowFixMe[nonstrict-import]
  popModal,
  // $FlowFixMe[nonstrict-import]
  showHandledApiError,
} from 'src/action-creators/modal';
import {submitTask} from 'src/components/agency-onboard/api/submit.js';
import ArrowRightIcon from 'src/images/icons/arrow-right-no-fill.svg';
//$FlowFixMe[nonstrict-import]
import {selectAgencyData} from 'src/selectors/agency';
import {classify} from 'src/utils/classify';
import {showToastMessage} from 'src/action-creators/toasts';
import {SidePanelLoader} from 'src/components/agency-onboard/side-panels/side-panel-loader.jsx';
import {AnalyticsService} from 'src/analytics';
import {AMPLITUDE_EVENTS} from 'src/components/agency-onboard/amplitude_event.js';

import TaskStatusIcon from 'src/components/agency-onboard/task-status-icon/task-status-icon.jsx';

// $FlowFixMe[nonstrict-import]
import {handleGenericApiError} from 'src/utils/errors';
import type {Task} from 'src/components/agency-onboard/types/side-panel-comp.js';


type TaskContainerProps = {
  selectedTaskId: string,
  tasks: {[string]: Task},
  setTasks: (old_tasks: mixed, updated_tasks: mixed) => mixed,
};

export default function TaskContainer({
  selectedTaskId,
  tasks,
  setTasks,
}: TaskContainerProps): React.Node {
  const dispatch = useDispatch();
  const agencyData = useSelector(selectAgencyData);

  const handleTrackingEvent = (event) => {
    AnalyticsService.track(event, {
      agencyId: agencyData.id,
      agencyName: agencyData.name,
    });
  };

  const handleTaskSubmit = async (taskId, userData) => {
    try {
      // call submit api
      const taskSubmitData = await dispatch(submitTask(taskId, userData));
      const updatedTasks = {
        ...tasks,
        [tasks[selectedTaskId].id]: {
          ...tasks[selectedTaskId],
          status: taskSubmitData['status'],
        },
      };

      setTasks(updatedTasks);
      dispatch(popModal());
      showSuccessMessage();

      const allTaskCompleted = Object.values(updatedTasks).every((task) => {
        // $FlowFixMe[incompatible-use]
        return task.status !== 'Pending';
      });

      if (allTaskCompleted) {
        handleTrackingEvent(AMPLITUDE_EVENTS.LAST_TASK_SUBMIT_BUTTON_CLICKED);
      }
      // task status update on ui handling
    } catch (error) {
      // Add the handling after UI design is updated
      handleGenericApiError(error);
      dispatch(
        showHandledApiError({
          title: 'Failed to Submit',
          text: 'An unexpected error occured when attempting to save your changes. If this problem persists, please contact support.',
          error,
        }),
      );
    }
  };

  const showSuccessMessage = () => {
    dispatch(
      showToastMessage(`Task Successfully Submitted`, {
        variant: VARIANTS.SUCCESS,
      }),
    );
  };

  const handleTaskCancel = () => {
    dispatch(popModal());
  };

  const handleOpenSelectedTask = (selectedTaskId, tasks) => {
    dispatch(
      pushSidepanel({
        title: tasks[selectedTaskId].name,
        children: (
          <SidePanelLoader
            task={tasks[selectedTaskId]}
            taskName={tasks[selectedTaskId].name}
            onTaskSubmit={handleTaskSubmit}
            onTaskCancel={handleTaskCancel}
          />
        ),
        direction: 'right',
        width: '740px',
      }),
    );
  };

  return (
    <div
      className={classify(css.task, maincss.centerAlign)}
      onClick={() => handleOpenSelectedTask(selectedTaskId, tasks)}
    >
      <div className={maincss.centerAlign}>
        <TaskStatusIcon task={tasks[selectedTaskId]} />
        <Clickable className={classify(css.titleText, maincss.text)}>
          {tasks[selectedTaskId].name}
        </Clickable>
      </div>
      <div>
        {tasks[selectedTaskId].status === 'In progress' ? (
          <Smallest className={classify(css.inProgressText, maincss.text)}>
            {tasks[selectedTaskId].name} In Progress
          </Smallest>
        ) : (
          <></>
        )}
        <Smallest className={classify(css.taskEstimate, maincss.text)}>
          Usually takes {tasks[selectedTaskId].estimate}
        </Smallest>
        <div className={css.icon}>
          <ArrowRightIcon className={css.icon} />
        </div>
      </div>
    </div>
  );
}
