// @noflow

import type {Event as SenseEvent} from 'src/api-parsers/events';
import type {EventCreationAction} from 'src/action-creators/event-creation';

import {
  CREATE_NEW_EVENT,
  UPDATE_EVENT,
  INHERIT_EVENT,
  SET_NEW_EVENT,
} from 'src/action-creators/event-creation';


export type EventCreationState = $Shape<SenseEvent>;

const blankNewSurvey = {
  title: '',
};

export default (
  state: EventCreationState = blankNewSurvey,
  action: EventCreationAction,
) => {
  switch (action.type) {
    // promotes a temporary new event to a real event with server event data
    case INHERIT_EVENT: {
      return {
        ...action.payload,

        // for now only inherit title (but could carry over other state here)
        title: state.title,
      };
    }
    case SET_NEW_EVENT:
      return {
        ...blankNewSurvey,
        ...action.payload,
      };
    case CREATE_NEW_EVENT:
    case UPDATE_EVENT:
      return {
        ...state,
        ...action.payload,
      };
  }
  return state;
};
