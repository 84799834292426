//@flow strict

// export const MESSAGE = {}; // Events related to messaging will come here

// export const ANALYTICS = {}; // Events related to analytics will come here

// Engage related events
export const ENGAGE = {
  DB_CLEANUP_SCHEDULING_SAVED: 'db-cleanup-scheduling-saved',
  DB_CLEANUP_RULES_CHANGED: 'db-cleanup-rules-changed',
  JOURNEY_CREATED: 'journey-created',
  JOURNEY_CREATED_V2: 'journey-created-v2',
  JOURNEY_STATUS_CHANGED: 'journey-status-changed',
  JOURNEY_LIST_CLICKED: 'journey-list-clicked',
  JOURNEY_LIST_VIEWED: 'journey-list-viewed',
  JOURNEY_AUDIENCE_SAVED: 'journey-audience-saved',
  JOURNEY_AUDIENCE_DISCARDED: 'journey-audience-discarded',
  JOURNEY_CREATION_ENTITY_TYPE_SELECTED:
    'journey-creation-entity-type-selected',
  TOUCHPOINT_CREATED: 'touchpoint-created',
  JOURNEY_LIST_ADDED: 'journey-list-added',
  JOURNEY_LIST_CREATION_STARTED: 'journey-list-creation-started',
  LIST_CREATED: 'list-created',
  LIST_CLICKED: 'list-clicked',
  LIST_EDITED: 'list-edited',
  LIST_DRAFTED: 'list-drafted',
  MONTHLY_SUCCESS_SUMMARY_EMAIL_FEEDBACK:
    'monthly_success_summary_email_feedback',
};

export const DRIVE = {
  EVENT_CREATED: 'Event Created',
  EVENT_VIEWED: 'Event Viewed',
  CONFIGUTATION_TAB_VIEWED: 'Configuration Tab Viewed',
  EVENT_STATUS_FILTER_APPLIED: 'Event Status Filter applied',
  EVENT_CANCELLED: 'Event Cancelled',
  JOBS_LINKED: 'Jobs Linked',
  JOBS_SEARCHED: 'Jobs Searched',
  LANDING_PAGE_SERACHED: 'Landing Page Searched',
  EVENT_SERACHED: 'Event Searched',
  JOBS_CREATED: 'Jobs Created',
  JOBS_VIEWED: 'Jobs Viewed',
  LANDING_PAGE_CREATED: 'Landing Page Created',
  LANDING_PAGE_VIEWED: 'Landing Page Previewed',
  LANDING_PAGE_UNLINKED: 'Landing Page Unlinked',
  EVENT_ARCHIVED: 'Event Archived',
  EVENT_PUBLISHED: 'Event Published',
  JOBS_ADDED: 'Jobs Added',
  EVENT_SHARED: 'Event Shared',
  QR_CODE_DOWNLOADED: 'QR Code Downloaded',
  EVENT_LINK_COPIED: 'Event Link Copied',
};
