// @flow

import {ApiError} from './redux-api-v2';
import {
  type RequestOptions,
  type ApiPostOptions,
  type ApiOptions,
  type OtherOptions,
  baseNoStore,
} from './redux-api';

import {emptyObject} from 'src/utils';

// Functions to make http requests without dispatch and going through
// the index or redux stores.
export const baseRequest = async (
  path: string,
  options: RequestOptions,
  otherOptions?: OtherOptions,
): Promise<any> => {
  const allOtherOptions: OtherOptions = {
    ...otherOptions,
  };

  if (window && window.csrfToken) {
    allOtherOptions.csrfToken = String(window.csrfToken);
  }

  const response = await baseNoStore(path, options, allOtherOptions);

  const text = await response.text();

  let json;
  if (response.headers.get('Content-Type') === 'application/json') {
    try {
      json = JSON.parse(text);
    } catch (error) {
      throw new ApiError({path, ...options}, response, text, error);
    }
  } else {
    json = text;
  }

  if (!response.ok) {
    throw new ApiError({path, ...options}, response, json);
  }

  return json;
};

export const get = (
  path: string,
  query?: {...},
  options: ApiOptions = emptyObject,
): Promise<any> =>
  baseRequest(path, {
    method: 'GET',
    // $FlowFixMe[incompatible-call]
    query,
    options,
  });

export const post = (
  path: string,
  data?: {...},
  query?: {...},
  options?: ApiPostOptions,
): Promise<any> =>
  baseRequest(path, {
    method: 'POST',
    data,
    // $FlowFixMe[incompatible-call]
    query,
    options,
  });

export const put = (
  path: string,
  data?: {...},
  query?: {...},
  options: ApiPostOptions = emptyObject,
): Promise<any> =>
  baseRequest(path, {
    method: 'PUT',
    data,
    // $FlowFixMe[incompatible-call]
    query,
    options,
  });

export const del = (
  path: string,
  data?: {},
  options?: ApiOptions,
): Promise<any> =>
  baseRequest(path, {
    method: 'DELETE',
    data,
    options,
  });
