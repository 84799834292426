// @flow

import mapValues from 'lodash/mapValues';
import isFunction from 'lodash/isFunction';
import * as React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

// $FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores/index';
import {withRouter} from './withRouter.jsx';
import {FluxContext} from './root.jsx';
import pure from './pure.jsx';
import nameHoc from './nameHoc';
import useFluxStore from './useFluxStore';

// TODO (kyle): remove this.
const useFlux = useFluxStore;
export {useFlux};

// $FlowFixMe[prop-missing]
const emptyObject = Object.freeze({});

type Params = {[string]: string};
type Mappers<C, M> = {
  // $FlowFixMe[value-as-type] [v1.32.0]
  [key: string]: (store: IndexStore, props: {...C, params: Params}) => M,
};

// $FlowFixMe[value-as-type] [v1.32.0]
type Mapper<C, M> = (props: {...C, store: IndexStore, params: Params}) => M;

type Options = {
  pure?: boolean,
  storeKey?: string,
};

// TODO (kyle): pure: true
export const withFlux = <-C, -M>(
  // $FlowFixMe[value-as-type] [v1.32.0]
  mapper?: ({...C, store: IndexStore}) => M,
  options: Options = Object.freeze({}),
): ((
  WrappedComponent: React.AbstractComponent<C>,
) => React.AbstractComponent<$Diff<C, M>>) => <C, M>(
  WrappedComponent: React.AbstractComponent<C>,
): React.AbstractComponent<$Diff<C, M>> => {
  if (options.pure) {
    WrappedComponent = pure()(WrappedComponent);
  }

  const WrapperComponent = (props) => {
    const {store} = React.useContext(FluxContext);
    return (
      // $FlowFixMe[cannot-spread-indexer]
      <WrappedComponent
        ref={props.fluxRef}
        // $FlowFixMe[escaped-generic]
        {...calcFluxProps(store, mapper, props, options.storeKey)}
      />
    );
  };

  return nameHoc(
    hoistNonReactStatics(WrapperComponent, WrappedComponent),
    // $FlowFixMe
    WrappedComponent,
    'Flux',
  );
};

function calcFluxProps(store, mapper, props, storeKey = 'store') {
  const router = props.router || null;
  // $FlowFixMe[cannot-spread-indexer]
  // $FlowFixMe[escaped-generic]
  let fluxProps = {...props, [storeKey]: store, router};

  if (mapper) {
    // $FlowFixMe[escaped-generic]
    // $FlowFixMe[incompatible-call]
    const mappedProps = mapper(fluxProps);
    // $FlowFixMe[escaped-generic]
    fluxProps = {...fluxProps, ...mappedProps};
  }

  // $FlowFixMe[escaped-generic]
  return fluxProps;
}

// NOTE (kyle): legacy HOCs
// FIXME(marcos): remove use of withRouter here #8433
const FluxComponent = <-C, -M>(
  WrappedComponent: React.AbstractComponent<C>,
  mappers: ({
    ...C,
    // $FlowFixMe[value-as-type] [v1.32.0]
    store: IndexStore,
    params: {[string]: string},
    // $FlowFixMe this function allows for objects, but they're not type-safe
  }) => M = emptyObject,
  options: Options = emptyObject,
): React.AbstractComponent<{
  ...$Diff<C, M>,
  // $FlowFixMe[missing-type-arg]
  fluxRef?: React.Ref<>,
  // $FlowFixMe
}> => withRouter(withFlux(massageMapper(mappers), options)(WrappedComponent));
export default FluxComponent;

function massageMapper<C, M>(mappers: Mappers<C, M> | Mapper<C, M>) {
  // $FlowFixMe[escaped-generic]
  return isFunction(mappers)
    ? mappers
    : (props) => mapValues(mappers, (mapper) => mapper(props.store, props));
}

export const fluxify = <-C, -M>(
  mappers: ({
    ...C,
    // $FlowFixMe[value-as-type] [v1.32.0]
    store: IndexStore,
    params: {[string]: string},
    // $FlowFixMe this function allows for objects, but they're not type-safe
  }) => M = emptyObject,
  options: Options = emptyObject,
): ((
  WrappedComponent: React.AbstractComponent<C>,
) => React.AbstractComponent<{
  ...$Diff<C, M>,
  fluxRef?: React.Ref<>,
}>) => <C, M>(
  WrappedComponent: React.AbstractComponent<C>,
): React.AbstractComponent<{
  ...$Diff<C, M>,
  fluxRef?: React.Ref<>,
}> => FluxComponent(WrappedComponent, mappers, options);
