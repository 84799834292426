// @flow strict

import type {AudienceListAction} from 'src/action-creators/audience-list';
import type {
  AudienceListStatus,
  AudienceRule,
  AudienceListSource,
} from 'src/types/audience-list';
import type {EntityType} from 'src/types/ats-entities';

import cloneDeep from 'lodash/cloneDeep';

import {
  createInclusionBlockIdentifier,
  getDefaultExclusionBlock,
  getDefaultInclusionBlock,
  cloneRuleWithoutValues,
} from 'src/utils/audience-list';

import {
  UPDATE_STATE,
  RECEIVE_LIST,
  RESET,
  SET_RULES,
  SET_ALL,
  RECEIVE_PUBLISHED_LIST_IDS,
  RECEIVE_UPDATED_LIST,
  RESET_JOURNEY_FLOW_FIELDS,
  RESET_AUTOMATION_WORKFLOW_FIELDS,
  ADD_INCLUSION_BLOCK,
  REMOVE_INCLUSION_BLOCK,
  CLONE_INCLUSION_BLOCK,
  SET_AUDIENCE_BLOCKS,
} from 'src/action-creators/audience-list';


export type AudienceListState = {
  id: ?string,
  name: string,
  entityType: ?string,
  inclusions: Map<string, AudienceRule[]>,
  exclusions: Map<string, AudienceRule[]>,
  associatedResources: {resource_type: string}[],
  status: ?AudienceListStatus,
  source?: ?AudienceListSource,
  target?: ?string,
  lifeCycleGoal?: ?string,
  businessGoal?: ?string,
  all: ?boolean,
  ruleCreationType: string,
};

const initialState = {
  id: null,
  name: '',
  entityType: null,
  inclusions: new Map(getDefaultInclusionBlock()),
  exclusions: new Map(getDefaultExclusionBlock()),
  associatedResources: [],
  status: null,
  target: null,
  lifeCycleGoal: null,
  businessGoal: null,
  all: false,
  ruleCreationType: 'new',
  source: null,
};

export default (
  state: AudienceListState = initialState,
  action: AudienceListAction,
): AudienceListState => {
  switch (action.type) {
    case RECEIVE_LIST: {
      const {
        id,
        status,
        inclusions,
        exclusions,
        name,
        entityType,
        all,
        source,
      } = action.payload;
      return {
        ...state,
        id,
        status,
        inclusions,
        exclusions,
        name,
        entityType,
        all,
        source,
      };
    }
    case UPDATE_STATE: {
      const updatePayload = action.payload;
      return {
        ...state,
        ...updatePayload,
      };
    }
    case RESET: {
      // return cloneDeep(initialState)
      return {...initialState};
    }
    case SET_RULES: {
      const {blockId, rules} = action.payload;
      const isInclusion = !!state.inclusions.get(blockId);
      if (isInclusion) {
        return {
          ...state,
          all: false,
          inclusions: new Map([...state.inclusions]).set(blockId, rules),
        };
      }
      const isExclusion = !!state.exclusions.get(blockId);
      if (isExclusion) {
        return {
          ...state,
          all: false,
          exclusions: new Map([...state.exclusions]).set(blockId, rules),
        };
      }
      return {...state};
    }
    case SET_ALL: {
      return {
        ...state,
        all: action.payload,
      };
    }
    case RECEIVE_PUBLISHED_LIST_IDS: {
      const publishedLists = action.payload;
      const status = publishedLists.includes(state.id)
        ? 'published'
        : state.status;

      return {
        ...state,
        status,
      };
    }
    case RECEIVE_UPDATED_LIST: {
      const {id, status, inclusions, exclusions, name} = action.payload;
      return {
        ...state,
        id,
        status,
        inclusions,
        name,
      };
    }
    case RESET_JOURNEY_FLOW_FIELDS: {
      const {businessGoal, entityType, target, lifeCycleGoal} = state;
      return {
        ...initialState,
        businessGoal,
        entityType,
        target,
        lifeCycleGoal,
      };
    }
    case RESET_AUTOMATION_WORKFLOW_FIELDS: {
      const {businessGoal, entityType, target, lifeCycleGoal} = state;
      return {
        ...initialState,
        businessGoal,
        entityType,
        target,
        lifeCycleGoal,
      };
    }
    case ADD_INCLUSION_BLOCK: {
      return {
        ...state,
        inclusions: state.inclusions.set(createInclusionBlockIdentifier(), []),
      };
    }
    case REMOVE_INCLUSION_BLOCK: {
      return {
        ...state,
        inclusions: new Map(
          [...state.inclusions.entries()].filter(
            ([key, _]) => key !== action.payload,
          ),
        ),
      };
    }
    case CLONE_INCLUSION_BLOCK: {
      return {
        ...state,
        //insertion order is important here, hence the drill
        inclusions: [...state.inclusions.entries()].reduce(
          (accumulator, [key, value]) => {
            accumulator.set(key, value);
            if (key === action.payload) {
              accumulator.set(
                createInclusionBlockIdentifier(),
                cloneRuleWithoutValues(value),
              );
            }
            return accumulator;
          },
          new Map(),
        ),
      };
    }
    case SET_AUDIENCE_BLOCKS: {
      const {inclusions, exclusions, all} = action.payload;
      return {
        ...state,
        inclusions,
        exclusions,
        all,
      };
    }
  }

  return state;
};
