// @flow

import * as React from 'react';
import {connect} from 'react-redux';
import flow from 'lodash/flow';

import css from './loader.css';


const mapStateToProps = ({route: {loading}}) => ({
  loading,
});

const HorizontalLoader = ({loading}: {loading: boolean}) => (
  <div className={loading ? css.loader : css.loaderHide} />
);

export default (flow(connect(mapStateToProps))(HorizontalLoader): any);
