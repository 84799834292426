// @flow strict

import type {ReferralFilters, GenericError} from 'src/types/referral-v2';
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api.js';
import {DEFAULT_ERROR} from 'src/action-creators/referral-v2/referral-jobs.js';


export const REFERRAL_FILTERS_LOADING = 'referral/referral-filters-loading';
export const REFERRAL_FILTERS = 'referral/referral-filters';
export const REFERRAL_FILTERS_ERROR = 'referral/referral-filters-error';

type ReferralFiltersLoadingAction = {
  type: typeof REFERRAL_FILTERS_LOADING,
};

type ReferralFiltersAction = {
  type: typeof REFERRAL_FILTERS,
  payload: ?ReferralFilters,
  from: string,
  archived: boolean,
};

type ReferralFiltersErrorAction = {
  type: typeof REFERRAL_FILTERS_ERROR,
  payload: ?GenericError,
};

export type ReferralFiltersActions =
  | ReferralFiltersAction
  | ReferralFiltersErrorAction
  | ReferralFiltersLoadingAction;

const recieveReferralFilters = (
  payload: ReferralFilters,
  from: string,
  archived: boolean,
): ReferralFiltersAction => ({
  type: REFERRAL_FILTERS,
  payload,
  from,
  archived,
});

const getReferralFiltersLoading = (): ReferralFiltersLoadingAction => ({
  type: REFERRAL_FILTERS_LOADING,
});

const getReferralFiltersError = (
  payload: ?GenericError,
): ReferralFiltersErrorAction => ({
  type: REFERRAL_FILTERS_ERROR,
  payload,
});

export const getReferralFilters: (
  from: string,
  payload: {
    start_date: string,
    end_date: string,
    archived: string,
    search_str: string,
  },
) => ThunkAction<void> = flow()((from, payload) => (dispatch: Dispatch) => {
  dispatch(getReferralFiltersLoading());

  return dispatch(reduxApi.get(`referral/${from}/filters`, payload)).then(
    (response: ReferralFilters) => {
      // $FlowIssue object values
      dispatch(recieveReferralFilters(response, from, payload.archived));
    },
    (error) => {
      dispatch(
        getReferralFiltersError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});
