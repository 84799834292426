// @flow

import type {Router, RouteLocation} from 'src/types/router';
import type {State as ReduxState} from 'src/reducers';
import type {AtsAnalyticsField, EntityFilter} from 'src/types/ats-settings';
// $FlowFixMe[untyped-type-import]
import type {UpdateOptions, AnalyticsQuery} from 'src/utils/analytics-query';
// $FlowFixMe[untyped-type-import]
import type {ClientEventType} from 'src/api-parsers/events';

import * as React from 'react';
import {connect, useDispatch} from 'react-redux';

import get from 'lodash/get';
import flow from 'lodash/flow';
import isEqual from 'lodash/isEqual';

import {formatPlural} from 'src/utils/intl';
import classify from 'src/utils/classify';

import {getAtsFieldValues} from 'src/selectors/ats-settings';
import {
  selectEntityFilters,
  selectEntityFiltersForType,
  getCandidateFieldName,
} from 'src/selectors/ats-entities';
import {getCanEditSettings} from 'src/selectors/accounts';
import {getTrackingEvents} from 'src/selectors/report';
import {saveDefaultAnalyticsQuery} from 'src/action-creators/report';

import withAnalyticsQuery from 'src/components/analytics/with-query.jsx';
import ClickAway from 'src/components/lib/click-away';
import {FilterButton} from 'src/components/lib/analytics-filter-button';
import {
  MessageTypeCard,
  RelatedToCard,
  RecipientCard,
  TrackingEventCard,
  MoreFiltersCard,
} from 'src/components/lib/analytics-filter-card';
import TimeframeFilter from './timeframe-filter.jsx';

import css from './index.css';


const eventTypes = [
  {value: 'survey', label: 'Email Surveys'},
  {value: 'message', label: 'Email Messages'},
  {value: 'sms_survey', label: 'SMS Surveys'},
  {value: 'sms_message', label: 'SMS Messages'},
];

type FilterType =
  | 'timeframe'
  | 'messagetype'
  | 'recipient'
  | 'relatedTo'
  | 'tracking_events'
  | 'more';

export type FilterProps = {
  activeTabs: string[],
  query: Object,
  // $FlowFixMe[value-as-type] [v1.32.0]
  onQueryChange: (update: Object, options?: UpdateOptions) => void,
  router: Router,
  location: RouteLocation,
  minDate?: string,
  defaultTimeAgo?: string,
  direction?: 'left' | 'right',
};

type OwnProps = {
  // $FlowFixMe[value-as-type] [v1.32.0]
  eventType?: ClientEventType,
  includeFilters?: FilterType[],
  extraFilters?: React.ComponentType<FilterProps>[],
  workflowEntityType?: string,
  entityType?: string,
  minDate?: string,
  showDefaultFilter?: boolean,
  className?: string,
  defaultTimeAgo?: string,
};

type Props = {
  ...OwnProps,
  location: RouteLocation,
  router: Router,
  eventTypeCounts: {[key: string]: number},
  query: Object,
  // $FlowFixMe[value-as-type] [v1.32.0]
  onQueryChange: (update: Object, options?: UpdateOptions) => void,
  atsFieldValues: {
    [id: string]: {
      values: AtsAnalyticsField[],
    },
  },
  entityFilters: Array<EntityFilter>,
  showSettingsLink: ?string,
  // $FlowFixMe[value-as-type] [v1.32.0]
  defaultQuery: ?AnalyticsQuery,
  agentDefaultFiltersEnabled: boolean,
  className?: string,
};

const mapStateToProps = (state: ReduxState, {entityType}): * => ({
  agentDefaultFiltersEnabled: state.agency.config.agentDefaultFiltersEnabled,
  showSettingsLink:
    (getCanEditSettings(state) && getCandidateFieldName(state)) || null,
  eventTypeCounts: state.eventTypeCounts.agency,
  atsFieldValues: getAtsFieldValues(state),
  entityFilters: entityType
    ? selectEntityFiltersForType(state, entityType)
    : selectEntityFilters(state),
  defaultQuery: state.report.defaultQuery,
});

const AnalyticsFilters = ({
  location,
  query,
  router: history,
  eventType,
  eventTypeCounts,
  includeFilters = [
    'timeframe',
    'recipient',
    // 'relatedTo',
    // 'tracking_events',
    'more',
  ],
  extraFilters = [],
  atsFieldValues,
  entityFilters,
  onQueryChange,
  showSettingsLink,
  defaultQuery,
  agentDefaultFiltersEnabled,
  minDate,
  showDefaultFilter = true,
  className,
  defaultTimeAgo = JSON.stringify({days: 90}),
}: Props) => {
  const defaultState: {
    activeTabs: string[],
    openFilter: ?string,
    lastFilterAction: 'none' | 'cleared' | 'restored' | 'saved',
  } = {
    activeTabs: [],
    openFilter: null,
    lastFilterAction: 'none',
  };
  const [state, setState] = React.useState(defaultState);
  const {activeTabs} = state;

  const dispatch = useDispatch();

  const localQueryChange = (...args) => {
    onQueryChange(...args);
    setState({...state, lastFilterAction: 'none'});
  };

  const handleOpenFilter = (name) => () => {
    setState({...state, openFilter: name});
  };

  const queryAsArray = (
    key: string,
    // $FlowFixMe[value-as-type] [v1.32.0]
    options?: UpdateOptions = {entityDataFilter: undefined},
  ) => {
    const getKey = options.entityDataFilter ? ['edf', key] : key;
    return get(query, getKey, []);
  };

  const _handleKeyedArrays = (key: string, evt: SyntheticEvent<*>) => {
    const {name} = evt.currentTarget;
    const queryArray = queryAsArray(key);

    const queryArgs = queryArray.includes(name)
      ? queryArray.filter((type) => {
          if (name === 'survey') {
            return !['survey', 'beefree_email'].includes(type);
          }
          return type !== name;
        })
      : name === 'survey'
      ? [...queryArray, name, 'beefree_email']
      : [...queryArray, name];

    localQueryChange({
      [key]: {$set: queryArgs},
    });
  };

  const handleEventTypeChange = _handleKeyedArrays.bind(this, 'event_types');
  const handleRecipientTypeChange = _handleKeyedArrays.bind(this, 'recipients');
  const handleTrackingEventTypeChange = _handleKeyedArrays.bind(
    this,
    'tracking_events',
  );

  const handleValueChange = (key: string) => (value: mixed) => {
    localQueryChange(
      {
        [key]: {$set: value},
      },
      {entityDataFilter: true},
    );
  };

  const handleFromAddressChange = (vals: string[]) => {
    localQueryChange({
      ['from_address']: {$set: vals},
    });
  };

  const selectedEventTypes = queryAsArray('event_types');
  const selectedRecipientTypes = queryAsArray('recipients');
  const selectedTrackingEventTypes = queryAsArray('tracking_events');
  const selectedFromAddresses = queryAsArray('from_address');

  const messageTypeLabel =
    selectedEventTypes.length === 1
      ? eventTypes.filter((type) => type.value === selectedEventTypes[0])[0]
          .label
      : 'Message Type';

  const {
    client,
    recruiter,
    office,
    account_manager: accountManager,
    brand,
  } = atsFieldValues;

  const edfOption = {entityDataFilter: true};

  const selectedClients = client
    ? // $FlowFixMe
      queryAsArray(client.senseAttribute, edfOption)
    : [];
  const selectedOffices = office
    ? // $FlowFixMe
      queryAsArray(office.senseAttribute, edfOption)
    : [];
  const selectedRecruiters = recruiter
    ? // $FlowFixMe
      queryAsArray(recruiter.senseAttribute, edfOption)
    : [];
  const selectedAccountManagers = accountManager
    ? // $FlowFixMe
      queryAsArray(accountManager.senseAttribute, edfOption)
    : [];
  const selectedBrands = brand
    ? // $FlowFixMe
      queryAsArray(brand.senseAttribute, edfOption)
    : [];

  // $FlowFixMe
  const showRelatedToFilter = shouldShowFilter(
    // $FlowFixMe upgraded flow
    client,
    // $FlowFixMe upgraded flow
    recruiter,
    // $FlowFixMe upgraded flow
    office,
    // $FlowFixMe upgraded flow
    accountManager,
    // $FlowFixMe upgraded flow
    brand,
  );

  const entityFiltersTransformed = Object.keys(atsFieldValues).reduce(
    (transformedFilters, fieldValueId) => {
      const fieldValue = atsFieldValues[fieldValueId];
      const entityFilter = entityFilters.find(
        ({id: entityFilterId}) => fieldValueId === entityFilterId,
      );
      if (entityFilter && fieldValue) {
        const selected = queryAsArray(
          `${entityFilter.entity_type}/${entityFilter.attribute_name}`,
          edfOption,
        );
        transformedFilters.push({
          ...entityFilter,
          ...fieldValue,
          selected,
        });
      }
      return transformedFilters;
    },
    [],
  );

  const entityFiltersActiveCount = entityFiltersTransformed.reduce(
    (prev, {selected}) => {
      if (selected) {
        if (Array.isArray(selected)) {
          return prev + selected.length;
        }
        return prev + 1;
      }
      return prev;
    },
    0,
  );

  const moreFiltersCount =
    entityFiltersActiveCount + selectedFromAddresses.length;

  return (
    <>
      <div className={classify(css.container, className)}>
        {/* timeframe is always active until date-less queries happen? */}

        {includeFilters.includes('timeframe') && (
          <TimeframeFilter
            query={query}
            activeTabs={activeTabs}
            onQueryChange={localQueryChange}
            router={history}
            location={location}
            minDate={minDate}
            defaultTimeAgo={defaultTimeAgo}
          />
        )}

        {includeFilters.includes('messagetype') && (
          <ClickAway>
            {({isOpen, onOpen, cancelNext}) => (
              <div className={css.filter}>
                <FilterButton
                  className={css.button}
                  active={
                    activeTabs.includes('messagetype') ||
                    selectedEventTypes.length > 0
                  }
                  activeFilters={
                    selectedEventTypes.length > 1
                      ? selectedEventTypes.length
                      : 0
                  }
                  onClick={() => {
                    handleOpenFilter('messagetype');
                    onOpen();
                  }}
                >
                  {messageTypeLabel}
                </FilterButton>
                {isOpen && (
                  <div className={css.filterCard} onClickCapture={cancelNext}>
                    <MessageTypeCard
                      onChange={handleEventTypeChange}
                      selectedTypes={selectedEventTypes}
                      eventTypes={eventTypes}
                      eventTypeCounts={eventTypeCounts}
                    />
                  </div>
                )}
              </div>
            )}
          </ClickAway>
        )}

        {showRelatedToFilter && includeFilters.includes('relatedTo') && (
          <ClickAway>
            {({isOpen, onOpen, cancelNext}) => (
              <div className={css.filter}>
                <FilterButton
                  className={css.button}
                  active={
                    activeTabs.includes('relatedTo') ||
                    selectedClients.length +
                      selectedRecruiters.length +
                      selectedBrands.length +
                      selectedAccountManagers.length +
                      selectedOffices.length >
                      0
                  }
                  activeFilters={
                    selectedClients.length +
                    selectedRecruiters.length +
                    selectedBrands.length +
                    selectedAccountManagers.length +
                    selectedOffices.length
                  }
                  onClick={() => {
                    handleOpenFilter('relatedTo');
                    onOpen();
                  }}
                >
                  Related To
                </FilterButton>
                {isOpen && (
                  <div className={css.filterCard} onClickCapture={cancelNext}>
                    <RelatedToCard
                      history={history}
                      location={location}
                      client={client}
                      office={office}
                      recruiter={recruiter}
                      accountManager={accountManager}
                      brand={brand}
                      selectedClients={selectedClients}
                      selectedOffices={selectedOffices}
                      selectedRecruiters={selectedRecruiters}
                      selectedAccountManagers={selectedAccountManagers}
                      selectedBrands={selectedBrands}
                      onStringListChange={handleValueChange}
                    />
                  </div>
                )}
              </div>
            )}
          </ClickAway>
        )}

        {includeFilters.includes('recipient') && (
          <ClickAway>
            {({isOpen, onOpen, cancelNext}) => (
              <div className={css.filter}>
                <FilterButton
                  className={css.button}
                  active={
                    activeTabs.includes('recipient') ||
                    selectedRecipientTypes.length > 0
                  }
                  activeFilters={selectedRecipientTypes.length}
                  onClick={() => {
                    handleOpenFilter('recipient');
                    onOpen();
                  }}
                >
                  Recipient
                </FilterButton>
                {isOpen && (
                  <div className={css.filterCard} onClickCapture={cancelNext}>
                    <RecipientCard
                      onRecipientTypeChange={handleRecipientTypeChange}
                      selectedRecipientTypes={selectedRecipientTypes}
                    />
                  </div>
                )}
              </div>
            )}
          </ClickAway>
        )}

        {includeFilters.includes('tracking_events') && (
          <ClickAway>
            {({isOpen, onOpen, cancelNext}) => (
              <div className={css.filter}>
                <FilterButton
                  className={css.button}
                  active={
                    activeTabs.includes('tracking_events') ||
                    selectedTrackingEventTypes.length > 0
                  }
                  activeFilters={selectedTrackingEventTypes.length}
                  onClick={() => {
                    handleOpenFilter('tracking_events');
                    onOpen();
                  }}
                >
                  Marketing
                </FilterButton>
                {isOpen && (
                  <div className={css.filterCard} onClickCapture={cancelNext}>
                    <TrackingEventCard
                      onTrackingEventTypeChange={handleTrackingEventTypeChange}
                      trackingEventTypes={getTrackingEvents(
                        eventType ||
                          (query.event_types && query.event_types[0]),
                      )}
                      selectedTrackingEventTypes={selectedTrackingEventTypes}
                    />
                  </div>
                )}
              </div>
            )}
          </ClickAway>
        )}

        {includeFilters.includes('more') && (
          <ClickAway>
            {({isOpen, onOpen, cancelNext}) => (
              <div className={css.filter}>
                <FilterButton
                  className={css.button}
                  active={activeTabs.includes('more') || moreFiltersCount > 0}
                  activeFilters={moreFiltersCount}
                  onClick={() => {
                    handleOpenFilter('more');
                    onOpen();
                  }}
                >
                  More Filters
                </FilterButton>
                {isOpen && (
                  <div className={css.filterCard} onClickCapture={cancelNext}>
                    <MoreFiltersCard
                      // $FlowFixMe[incompatible-type]
                      filters={entityFiltersTransformed}
                      onValueChange={handleValueChange}
                      showSettingsLink={showSettingsLink}
                      onChangeFromAddress={handleFromAddressChange}
                      filteredFromAddresses={selectedFromAddresses}
                    />
                  </div>
                )}
              </div>
            )}
          </ClickAway>
        )}

        {extraFilters.map((Filter) => (
          <Filter
            query={query}
            activeTabs={activeTabs}
            onQueryChange={localQueryChange}
            router={history}
            location={location}
          />
        ))}

        {state.lastFilterAction !== 'cleared' && Object.keys(query).length > 0 && (
          <button
            className={css.textButton}
            onClick={() => {
              onQueryChange({$set: {}});
              setState({...state, lastFilterAction: 'cleared'});
            }}
          >
            clear filters
          </button>
        )}
        {state.lastFilterAction !== 'none' && (
          <button className={css.textButton} disabled>
            filters {state.lastFilterAction}
          </button>
        )}
        {showDefaultFilter &&
          agentDefaultFiltersEnabled &&
          !isEqual(defaultQuery, query) &&
          state.lastFilterAction !== 'restored' && (
            <>
              <button
                className={css.textButton}
                onClick={() => {
                  onQueryChange({
                    $merge: defaultQuery,
                  });
                  setState({...state, lastFilterAction: 'restored'});
                }}
              >
                restore saved filters
              </button>
              <button
                className={css.textButton}
                onClick={() => {
                  dispatch(saveDefaultAnalyticsQuery(query));
                  setState({...state, lastFilterAction: 'saved'});
                }}
              >
                save these filters
              </button>
            </>
          )}
      </div>
    </>
  );
};

export default (flow(
  connect(mapStateToProps),
  withAnalyticsQuery,
)(AnalyticsFilters): React.ComponentType<OwnProps>);

const humanTimeframeQuery = (query) => {
  if (query.time_ago !== undefined) {
    const timeAgo = JSON.parse(query.time_ago).days;
    if (timeAgo !== undefined) {
      return `Last ${timeAgo} ${formatPlural(timeAgo, 'day', 'days')}`;
    }
  }
  if (query.start_date && query.end_date) {
    return 'Custom Date Range';
  }
  if (!query.start_date && !query.end_date && !query.time_ago) {
    return 'Last 90 days';
  }
  return 'Timeframe';
};

const shouldShowFilter = (...fieldValues: Array<?AtsAnalyticsField>): boolean =>
  fieldValues.reduce(
    (prev, curr) =>
      prev ||
      Boolean(curr && curr.values === null && curr.error && curr.message) ||
      Boolean(curr && Array.isArray(curr.values)) ||
      Boolean(curr && curr.min != null && curr.max != null),
    false,
  );
