// @noflow

import type {AudienceFilter} from 'src/types/audience-filter';

import omit from 'lodash/omit';

import Store from './base';


export default class AudienceFilterStore extends Store {
  state: {
    editing: boolean,
    fetching: {
      [key: string]: Promise<void>,
    },
    filters: {
      [key: string]: AudienceFilter,
    },
  };

  constructor() {
    super('audience-filter');

    this.state = {
      editing: false,
      fetching: {},
      filters: {},
    };
  }

  startFetching(workflowId: string, promise: Promise<void>) {
    this.updateState({
      fetching: {
        $assign: {
          [workflowId]: promise,
        },
      },
    });
  }

  receiveFetched(workflowId: string, response: AudienceFilter) {
    this.updateState({
      fetching: {
        $set: omit(this.state.fetching, workflowId),
      },
      draft: {
        $set: response?.draft ?? false,
      },
      filters: {
        $assign: {
          [workflowId]: response?.filter,
        },
      },
    });
  }

  setFilter(workflowId: string, filter: AudienceFilter) {
    this.updateState({
      filters: {
        [workflowId]: {
          $set: filter,
        },
      },
    });
  }

  isEditing(editing: boolean) {
    if (!arguments.length) {
      return this.state.editing;
    } else {
      this.setState({editing});
    }
  }

  get(id: string): AudienceFilter {
    return this.state.filters[id];
  }

  isDraft() {
    return this.state.draft;
  }

  setDraft(draft: boolean) {
    this.setState({draft});
  }
}
