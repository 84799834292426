// @flow

import NotificationIcon from 'src/images/module-type/notification.svg?noAttrs';
import OpenEndedIcon from 'src/images/module-type/open-ended.svg?noAttrs';
import RatingScaleIcon from 'src/images/module-type/rating-scale.svg?noAttrs';
import MultipleChoiceIcon from 'src/images/module-type/multiple-choice.svg?noAttrs';
import ListIcon from 'src/images/module-type/list.svg?noAttrs';

// TODO (gab): checkboxes - uncomment when server is ready
// import CheckboxIcon from 'src/images/module-type/checkbox.svg?noAttrs';

import OpenEndedModule from './editors/open-ended.jsx';
import RatingScaleModule from './editors/rating-scale.jsx';
import NotificationModule from './editors/notification.jsx';
import MultipleChoiceModule from './editors/multiple-choice.jsx';
import SMSNotificationModule from './editors/sms-notification.jsx';
import WritebackModule from './editors/field-writeback.jsx';
import ListModule from './editors/list.jsx';
import BeeFreeModuleEditor from './editors/beefree.jsx';


export default {
  nps_survey_question: {
    Icon: RatingScaleIcon,
    Editor: RatingScaleModule,
    text: 'NPS',
    writebackEnabled: true,
  },
  rating_scale_survey_question: {
    Icon: RatingScaleIcon,
    Editor: RatingScaleModule,
    text: 'Rating Scale',
    writebackEnabled: true,
  },
  multiple_choice_survey_question: {
    Icon: MultipleChoiceIcon,
    Editor: MultipleChoiceModule,
    text: 'Multiple Choice',
    writebackEnabled: true,
  },
  // TODO (gab): checkboxes - uncomment when server is ready
  // 'checkboxes_survey_question': {
  //   Icon: CheckboxIcon,
  //   Editor: MultipleChoiceModule,
  //   text: 'Checkboxes',
  // },
  text_survey_question: {
    Icon: OpenEndedIcon,
    Editor: OpenEndedModule,
    text: 'Free Response',
    writebackEnabled: true,
  },
  calendar_date_survey_question: {
    Icon: OpenEndedIcon,
    Editor: null, //TODO: is this okay? we don't support this for non writeback
    text: 'Calendar Date',
    writebackEnabled: true,
  },
  message_survey_module: {
    Icon: NotificationIcon,
    Editor: NotificationModule,
    text: 'Notification',
    nonJobMatchTitleText: 'Message Part',
    jobMatchTitleText: 'Message Part (with jobs)',
  },
  beefree_module: {
    Icon: NotificationIcon,
    Editor: BeeFreeModuleEditor,
    text: 'Rich Email',
    jobMatchTitleText: 'with jobs',
  },
  sms_message_survey_module: {
    Icon: NotificationIcon,
    Editor: SMSNotificationModule,
    text: 'SMS Notification',
    nonJobMatchTitleText: 'SMS Notification',
    jobMatchTitleText: 'SMS Notification (with a job)',
  },
  attribute_list_module: {
    Icon: ListIcon,
    Editor: ListModule,
    text: 'List',
  },
  field_writeback_module: {
    Icon: ListIcon, //TODO: UPDATE
    Editor: WritebackModule,
    text: 'Free Response', //TODO: UPDATE
  },
  checkboxes_survey_question: {
    Icon: ListIcon, //TODO: UPDATE
    Editor: WritebackModule,
    text: 'Checkboxes', //TODO: UPDATE
  },
};
