// @flow strict

// $FlowFixMe[nonstrict-import]
import type {State as ReduxState} from 'src/reducers';

import {useSelector} from 'react-redux';
// $FlowFixMe[nonstrict-import]
import usePrivilege from 'src/hooks/usePrivilege';

// $FlowFixMe[nonstrict-import]
import {selectReleaseFlags} from 'src/selectors/product-flags';
// $FlowFixMe[nonstrict-import]
import {isInternalUser} from 'src/utils/check-internal-user';


export function useReleaseFlag(key: string): ?boolean {
  const releaseFlag = useSelector(selectReleaseFlags);
  return releaseFlag[key];
}

export function selectBooleanFlag(state: ReduxState, key: string): boolean {
  return Boolean(selectReleaseFlags(state)[key]);
}

/**
 * Use this flag when you want to check for internal users as well
 * If you just want to check for all users use selectBooleanFlag function
 */
export function selectBooleanFlagWithInternal(
  state: ReduxState,
  key: string,
  internalKey: string,
): boolean {
  return (
    selectBooleanFlag(state, key) ||
    (isInternalUser() && selectBooleanFlag(state, internalKey))
  );
}

export function useAnalyticsV2(): boolean {
  return useSelector(selectAnalyticsV2);
}

export function useAnalyticsResponseSummary(): boolean {
  const internalUser = isInternalUser();
  const analyticsResponseSummaryAiViewEnabled = useSelector(
    selectAnalyticsResponseSummaryAiViewEnabled,
  );
  const analyticsResponseSummaryAiViewEnabledInternal = useSelector(
    selectAnalyticsResponseSummaryAiViewEnabledInternal,
  );

  const canUseSurveySummaryGenerator = usePrivilege(
    'USE_SURVEY_SUMMARY_GENERATOR',
  );

  if (
    (analyticsResponseSummaryAiViewEnabled && canUseSurveySummaryGenerator) ||
    (internalUser && analyticsResponseSummaryAiViewEnabledInternal)
  ) {
    return true;
  }
  return false;
}

export function useEventNoteWritebackActions(): boolean {
  return useSelector(selectEventNoteWritebackActions);
}
export function useSchedulingV2(): boolean {
  return useSelector(selectSchedulingV2);
}
export function useChatbotSourcing(): boolean {
  return useSelector(selectChatbotSourcingEnabled);
}
export function useChatbotNodeVariables(): boolean {
  return useSelector(selectChatbotNodeVariablesEnabled);
}
export function useChatbotDataReview(): boolean {
  return useSelector(selectChatbotDataReviewEnabled);
}

export function useJobSearchAndMatch(): boolean {
  // (nilarnab): releaseFlag for discover is getting deprecated
  // please use product specific agency_config to check if discover is enabled
  return useSelector(selectJobSearchAndMatch);
}

export function useStarredThreads(): boolean {
  return useSelector(selectStarredThreads);
}

export function useContentSubscriptionV2Footer(): boolean {
  return useSelector(selectContentSubscriptionV2Footer);
}
export function useAudienceFilterSeparation(): boolean {
  return useSelector(selectAudienceFilterSeparation); // Note: this has been released for all.
}
export function useActionNowEnabled(): boolean {
  return useSelector(selectActionNowEnabled);
}
export function useListV2(): boolean {
  return useSelector(selectListV2Enabled);
}
export function useRoiListV2(): boolean {
  return useSelector(selectRoiListV2Enabled);
}
export function useServiceAccounts(): boolean {
  return useSelector(selectSchedulerServiceAccountsEnabled);
}

export function useSchedulerMultiEventAttendee(): boolean {
  return useSelector(selectSchedulerMultiEventAttendeeEnabled);
}

export function useChatbotReminders(): boolean {
  return useSelector(selectChatbotRemindersEnabled);
}

export function useNewAllowedAttributes(): boolean {
  return useSelector(selectNewAllowedAttributes);
}

export function useTRMEnabled(): boolean {
  const canEditAndViewTRM = usePrivilege('TRM_ADMIN');
  const canViewTRM = usePrivilege('TRM_VIEW');
  return useSelector(selectTRMEnabled) && (canEditAndViewTRM || canViewTRM);
}

export function useMultiEventChatbotMeetingNodes(): boolean {
  return useSelector(selectMultiEventChatbotMeetingNodes);
}

export function useScheduleCapacityManagement(): boolean {
  return useSelector(selectScheduleCapacityManagementEnabled);
}

export function useChatbotDatabaseMaintenance(): boolean {
  return useSelector(selectChatbotDatabaseMaintenance);
}

export function useSchedulerHolidyManagement(): boolean {
  return useSelector(selectSchedulerHolidyManagementEnabled);
}

export function useAudienceBuilderGenesis(): boolean {
  return useSelector(selectAudienceBuilderGenesis);
}

export function useSchedulerDailyDigest(): boolean {
  return useSelector(selectSchedulerDailyDigestEnabled);
}

export function useMessagingInboxDrawer(): boolean {
  return useSelector(selectMessagingInboxDrawer);
}

export function useLiveAgentTransfer(): boolean {
  return useSelector(selectLATEnabled);
}

export function useLinkToATS(): boolean {
  return useSelector(selectLinkToATSEnabled);
}

export function usePanelInterview(): boolean {
  return useSelector(selectPanelInterviewsEnabled);
}

export function useChatbotJobMatchWorksiteOptions(): boolean {
  return useSelector(selectChatbotJobMatchWorksiteOptions);
}

export function useChatbotJobMatchBrandLabel(): boolean {
  return useSelector(selectChatbotJobMatchBrandLabel);
}

export function useApplicationChatbot(): boolean {
  return useSelector(selectApplicationChatbotEnabled);
}

export function useSchedulerFixedDate(): boolean {
  return useSelector(selectSchedulerFixedDateEnabled);
}

export function useSchedulerGenesis(): boolean {
  return useSelector(selectSchedulerGenesisEnabled);
}

export function useChatbotFaqV2(): boolean {
  return useSelector(selectUsesChatbotFaqV2);
}

export function useChatbotV1Disabled(): boolean {
  return useSelector(selectChatbotV1Disabled);
}

export function useChatbotLiveFlowEditEnabled(): boolean {
  return useSelector(selectChatbotLiveFlowEditEnabled);
}

export function useAppShellEnabled(): boolean {
  return useSelector(selectAppShellEnabled);
}
export function useAppShellSettingsEnabled(): boolean {
  return useSelector(selectAppShellSettingsEnabled);
}

export function useCandidateRoiEnabled(): boolean {
  return useSelector(selectCandidateRoiEnabled);
}

export function useAnalyticsDiscoverEnhancementEnabled(): boolean {
  return useSelector(selectAnalyticsDiscoverEnhancementEnabled);
}

export function selectContentSubscriptionV2Footer(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'contentSubscriptionV2Footer');
}

export function selectStarredThreads(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'messagingStarredThreads');
}

export function selectWorkflowActivationV2(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'workflowActivationV2');
}

export function selectSpamBotPreventionWithDoubleClick(
  state: ReduxState,
): boolean {
  return selectBooleanFlag(state, 'spamBotPreventionWithDoubleClick');
}

export function selectAnalyticsV2(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'analyticsV2');
}
export function selectAnalyticsResponseSummaryAiViewEnabled(
  state: ReduxState,
): boolean {
  return selectBooleanFlag(state, 'analyticsResponseSummaryAiViewEnabled');
}
export function selectAnalyticsResponseSummaryAiViewEnabledInternal(
  state: ReduxState,
): boolean {
  return selectBooleanFlag(
    state,
    'analyticsResponseSummaryAiViewEnabledInternal',
  );
}
export function selectSchedulingV2(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'schedulingV2');
}
export function selectJobSearchAndMatch(state: ReduxState): boolean {
  // (nilarnab): releaseFlag for discover is getting deprecated
  // please use product specific agency_config to check if discover is enabled
  return selectBooleanFlag(state, 'jobSearchAndMatch');
}
export function selectChatbotSourcingEnabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'chatbotSourcingEnabled');
}
export function selectChatbotNodeVariablesEnabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'chatbotNodeVarsEnabled');
}

export function selectChatbotDataReviewEnabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'chatobtDataReviewEnabled');
}
export function selectAudienceFilterSeparation(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'audienceFilterSeparation');
}
export function selectActionNowEnabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'actionNowEnabled');
}
export function selectListV2Enabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'listV2');
}
export function selectRoiListV2Enabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'roiListV2Enabled');
}
export function selectSchedulerServiceAccountsEnabled(
  state: ReduxState,
): boolean {
  return selectBooleanFlag(state, 'chatbotServiceAccount');
}

export function selectSchedulerMultiEventAttendeeEnabled(
  state: ReduxState,
): boolean {
  return Boolean(
    selectReleaseFlags(state)['enableSchedulerMultiEventAttendee'],
  );
}

export function selectChatbotRemindersEnabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'chatbotRemindersEnabled');
}

export function selectNewAllowedAttributes(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'chatbotNewAllowedAttrs');
}

export function selectTRMEnabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'trmEnabled');
}

export function selectMessagingInboxDrawer(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'showMessagingInboxDrawer');
}

export function selectEventNoteWritebackActions(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'eventNoteWritebackActions');
}

export function selectMultiEventChatbotMeetingNodes(
  state: ReduxState,
): boolean {
  return selectBooleanFlag(state, 'enableSchedulerMultiEventChatbot');
}

export function selectScheduleCapacityManagementEnabled(
  state: ReduxState,
): boolean {
  return selectBooleanFlag(state, 'enableScheduleCapacityManagement');
}

export function selectChatbotDatabaseMaintenance(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'chatbotDatabaseMaintenance');
}

export function selectSchedulerHolidyManagementEnabled(
  state: ReduxState,
): boolean {
  return selectBooleanFlag(state, 'enableSchedulerHolidayManagement');
}

export function selectSigninSplashEnabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'signinSplashEnabled');
}

export function selectSigninSplashIsCorpAgency(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'signinSplashCorp');
}

export function selectAudienceBuilderGenesis(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'listV2');
}

export function selectSchedulerDailyDigestEnabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'enableSchedulerDailyDigest');
}

export function selectLATEnabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'enableLiveAgentTransfer');
}

export function selectLinkToATSEnabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'messagingViewAtsProfileLink');
}

export function selectPanelInterviewsEnabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'enableSchedulerPanelInterview');
}

export function selectChatbotJobMatchWorksiteOptions(
  state: ReduxState,
): boolean {
  return selectBooleanFlag(state, 'chatbotJobMatchWorksiteOptions');
}

export function selectChatbotJobMatchBrandLabel(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'chatbotJobMatchBrandLabel');
}

export function selectApplicationChatbotEnabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'chatbotInboundApplicationBotWeb');
}

export function selectSchedulerFixedDateEnabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'schedulerEnableFixedDateScheduling');
}

export function selectSchedulerGenesisEnabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'enableGenesisForScheduler');
}

export function selectUsesChatbotFaqV2(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'chatbotFaqV2');
}

export function selectChatbotV1Disabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'chatbotDisableV1Flow');
}

export function selectChatbotLiveFlowEditEnabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'chatbotLiveFlowEdit');
}

export function selectAppShellEnabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'appShell');
}

export function selectAppShellSettingsEnabled(state: ReduxState): boolean {
  return selectBooleanFlag(state, 'appShellSettings');
}

export function selectCandidateRoiEnabled(state: ReduxState): boolean {
  return selectBooleanFlagWithInternal(
    state,
    'candidateRoiEnabled',
    'candidateRoiEnabledInternal',
  );
}

export function selectAnalyticsDiscoverEnhancementEnabled(
  state: ReduxState,
): boolean {
  return selectBooleanFlagWithInternal(
    state,
    'analyticsDiscoverEnhancementEnabled',
    'analyticsDiscoverEnhancementEnabledInternal',
  );
}
