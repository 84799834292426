// @flow

import type {AudienceResponse} from 'src/types/multientity-audience';
// $FlowFixMe[untyped-type-import]
import type {AudienceMember} from 'src/api-parsers';
import type {ThunkAction} from 'src/reducers';

import {thunkify as flow} from 'src/utils/thunks';
import {camel} from 'src/utils';
import {key, cached, fetching} from 'src/utils/redux';

import * as reduxApi from 'src/utils/redux-api';


export const RECEIVE: 'group-audience/receive' = 'group-audience/receive';
export const RECEIVE_ME_GROUP_AUDIENCE_PAGE =
  'audienceMembers/me/receiveGroupPage';

type AudienceMembersPayload = {
  // $FlowFixMe[value-as-type] [v1.32.0]
  memberList: AudienceMember[],
  numPages: number,
  numTotalMembers: number,
  page: number,
  securityGroupId: string,
};

export type ReceiveAudienceMembersAction = {
  type: typeof RECEIVE,
  payload: AudienceMembersPayload,
};

export type ReceiveMEGroupAudienceEntityAction = {
  type: 'audienceMembers/me/receiveGroupPage',
  payload: {
    page: number,
    groupId: string,
    data: AudienceResponse,
  },
};

export type GroupAudienceAction =
  | ReceiveAudienceMembersAction
  | ReceiveMEGroupAudienceEntityAction;

export const receive = (
  payload: AudienceMembersPayload,
): ReceiveAudienceMembersAction => ({
  type: RECEIVE,
  payload,
});

export const getAudienceMembers: (
  id: string,
  page: number,
) => ThunkAction<mixed> = flow(
  key((id, page) => `group-audience-${id}/${page}`),
  cached((response) => receive(camel(response)), {ttl: 2000}),
  fetching({}),
)((id: string, page: number) =>
  reduxApi.get(`security/groups/${id}/audience-members?page=${page}`),
);

const receiveMEGroupAudiencePage = (
  page: number,
  groupId: string,
  response: AudienceResponse,
): ReceiveMEGroupAudienceEntityAction => ({
  type: RECEIVE_ME_GROUP_AUDIENCE_PAGE,
  payload: {
    page,
    groupId,
    data: response,
  },
});

export const getMultiEntityAudienceForGroup: (
  groupId: string,
  page: string,
) => * = flow(
  key((groupId, page) => `meGroupAudience/${groupId}/${page}`),
  cached(
    (json, groupId, page) =>
      receiveMEGroupAudiencePage(page, groupId, camel(json, true, ['data'])),
    {ttl: 500},
  ),
  fetching(),
)((groupId, page) =>
  reduxApi.get(`security/groups/${groupId}/entities/summaries`, {page}),
);
