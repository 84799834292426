// @flow strict
import type {
  Validations,
  NormalizedSms,
  SmsState,
  FetchedSmsResponse,
} from 'src/types/sms';
import type {EntityType, EntityRelationship} from 'src/types/ats-entities';


export const smsKeywords = [
  'help',
  'start',
  'stop',
  'end',
  'cancel',
  'unsubscribe',
  'quit',
];

export const sendFromOptions = [
  {label: 'Variable', key: 'variable'},
  {
    label: 'Content category phone number',
    key: 'content_category_phone_number',
  },
  {
    label: 'Content subscription backup number',
    key: 'content_subscription_backup_number',
  },
];

export const normalizeSms = (smsState: SmsState): NormalizedSms => {
  const {
    id,
    task_group_id,
    to_number,
    from_number,
    category_id,
    external_source_id,
    body,
    sender_category,
  } = smsState;
  return {
    type: 'sms_send',
    id,
    task_group_id,
    params: {
      to_number,
      from_number,
      external_source_id,
      category_id,
      body,
      sender_category,
    },
  };
};

export const getSenderListWithItsEntityVariables = (
  automationVariables: Map<string, string>,
  workflowEntityType: EntityType,
  relatedInternalEntities: EntityRelationship[],
): Array<{label: string, value: string, variableValue?: string, ...}> => {
  const entriesWithExternalSourceId = Array.from(
    automationVariables.entries(),
  ).filter(([key, _value]) => key.includes('externalSourceId'));

  function getChainedValue(variable) {
    const matchingEntry = entriesWithExternalSourceId.find(([key, _value]) =>
      key.includes(variable),
    );
    return matchingEntry ? matchingEntry[0] : null;
  }

  const senderVariables =
    relatedInternalEntities.map((e) => ({
      label: e.display_name,
      value: `<${e.join_attribute}>`,
      variableValue: '',
    })) ?? [];

  senderVariables.forEach((obj) => {
    const variable = obj.label;
    const chainedValue = getChainedValue(variable);
    if (chainedValue) {
      obj.variableValue = chainedValue;
    }
  });

  return senderVariables;
};

type SenderOption = {
  label: string,
  value: string,
  variableValue?: string,
  ...
};

export const getSenderNameFromVariable = (
  senderVariable: string,
  senderOptions: Array<SenderOption>,
): string => {
  const currentSender = senderOptions.find(
    (opt) => opt.variableValue === senderVariable,
  );
  return currentSender?.label ?? '';
};

export const getErrors = (validationsObject: Validations): Array<string> => {
  const errors = [];
  const {
    senderNumberEmpty,
    sendToNumberEmpty,
    contentBodyEmpty,
    contentLengthExceeded,
    sameFromToNumber,
    invalidToNumber,
  } = validationsObject;
  if (senderNumberEmpty || sendToNumberEmpty || contentBodyEmpty) {
    errors.push('Please fill the required fields.');
  }
  if (contentLengthExceeded) {
    errors.push('SMS content is too long.');
  }
  if (sameFromToNumber) {
    errors.push('Sender Number cannot be same as Receiver Number.');
  }
  if (invalidToNumber) {
    errors.push('Send To Number is Invalid.');
  }
  return errors;
};

export const parseSms = (fetchedNodeResponse: FetchedSmsResponse): SmsState => {
  const smsSendTask = fetchedNodeResponse.body.tasks.find(
    (task) => task.type === 'sms_send',
  );
  const taskDefinition = smsSendTask?.params;
  if (!smsSendTask || !smsSendTask.params) {
    throw new Error('Sms task or its definition not found');
  }

  return {
    id: smsSendTask.id,
    task_group_id: smsSendTask.task_group_id,
    body: taskDefinition?.body,
    category_id: taskDefinition?.category_id,
    external_source_id: taskDefinition?.external_source_id
      ? taskDefinition.external_source_id
      : null,
    from_number: taskDefinition?.from_number ?? null,
    to_number:
      taskDefinition?.to_number.startsWith('{{') &&
      taskDefinition?.to_number.endsWith('}}')
        ? taskDefinition?.to_number
        : `{{${taskDefinition?.to_number || ''}}}`,
    sender_category: taskDefinition?.sender_category,
  };
};
