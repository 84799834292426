// @flow strict

import * as React from 'react';
import ErrorPage from './error-page.jsx';

// (Nilarnab) TODO: reuse single error comnponent with differnet props
const NotAllowed = (): React.Node => (
  <ErrorPage pageTitle="Please talk to your administrator" drawMinimal={true}>
    <span>
      We're sorry, but it looks like you don't have permission to access Sense.
      Please talk to your administrator..
    </span>
  </ErrorPage>
);

export default NotAllowed;
