// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, GetState, ThunkAction} from 'src/reducers';

// $FlowFixMe[nonstrict-import]
import type {ApiPostOptions, ApiOptions} from 'src/utils/redux-api';

import type {
  SurveySummarySavedResponseAPI,
  SurveySummarySavedResponse,
  GetWorkflowsWithSurveysQuery,
  SavedSurveySummaryListResponseAPI,
  SavedSurveySummaryListResponse,
  SummaryGenerationFields,
  GenerateSummaryFormData,
  GetSavedSummariesQuery,
  Workflow,
} from 'src/types/survey-summary';

// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[nonstrict-import]
import {ANALYTICS_API_V3} from 'src/utils/analytics-api-migration';
// $FlowFixMe[untyped-import]
import {fetching, key} from 'src/utils/redux';
import {
  normalizeWorkflowsQueryParams,
  parseSummaryPayload,
} from 'src/utils/survey-summary.js';


export const RECEIVE_WORKFLOWS = 'survey-summary/receive-workflows';
export const RECEIVE_SAVED_SUMMARY = 'survey-summary/receive-saved-summary';
export const RECEIVE_SAVED_SUMMARIES = 'survey-summary/receive-saved-summaries';
export const SET_SUMMARY_GENERATION_INPUT_FIELDS =
  'survey-summary/set-summary-generation-input-fields';

type SetSummaryGenerationInputFieldsAction = {
  type: 'survey-summary/set-summary-generation-input-fields',
  payload: SummaryGenerationFields,
};

type ReceiveSavedSummaryListAction = {
  type: 'survey-summary/receive-saved-summaries',
  payload: ReceiveSavedSurveySummaryListPayload,
};

type ReceiveSavedSummaryAction = {
  type: 'survey-summary/receive-saved-summary',
  payload: SurveySummarySavedResponse,
};

type ReceiveWorkflowsAction = {
  type: 'survey-summary/receive-workflows',
  payload: ReceiveWorkflowsPayload,
};

type ReceiveWorkflowsPayload = {
  totalCount: number,
  workflows: Workflow[],
  pageNumber: number,
  isNew: boolean,
};

type ReceiveSavedSurveySummaryListPayload = {|
  ...SavedSurveySummaryListResponse,
  pageNumber: number,
|};

export type SurveySummaryActions =
  | SetSummaryGenerationInputFieldsAction
  | ReceiveSavedSummaryListAction
  | ReceiveSavedSummaryAction
  | ReceiveWorkflowsAction;

export const setSummaryGenerationInputFields = (
  payload: SummaryGenerationFields,
): SetSummaryGenerationInputFieldsAction => ({
  type: 'survey-summary/set-summary-generation-input-fields',
  payload,
});

const receiveSavedSummaryList = (
  payload: ReceiveSavedSurveySummaryListPayload,
): ReceiveSavedSummaryListAction => ({
  type: 'survey-summary/receive-saved-summaries',
  payload,
});

const receiveSavedSummary = (
  payload: SurveySummarySavedResponse,
): ReceiveSavedSummaryAction => ({
  type: 'survey-summary/receive-saved-summary',
  payload,
});

const receiveWorkflows = (
  payload: ReceiveWorkflowsPayload,
): ReceiveWorkflowsAction => ({
  type: 'survey-summary/receive-workflows',
  payload,
});

const analyticsOptions: ApiOptions = {apiPath: ANALYTICS_API_V3};
const analyticsPostOptions: ApiPostOptions = {apiPath: ANALYTICS_API_V3};

export const getWorkflowsWithSurveys: (
  query: GetWorkflowsWithSurveysQuery,
  isNew: boolean,
) => ThunkAction<mixed> = flow(
  key((query) => `workflowWithSurveys-${JSON.stringify(query)}`),
  fetching(),
)((query, isNew = true) => async (dispatch: Dispatch, getState: GetState) => {
  const {pageNumber} = query;
  try {
    const normalizedQueryParams = normalizeWorkflowsQueryParams(query);
    const payload = await dispatch(
      reduxApi.get(
        '/surveys/workflows',
        normalizedQueryParams,
        analyticsOptions,
      ),
    );
    dispatch(
      receiveWorkflows({
        totalCount: payload.total_count,
        workflows: payload.workflows,
        pageNumber,
        isNew,
      }),
    );
  } catch {
    dispatch(
      receiveWorkflows({
        totalCount: 0,
        workflows: [],
        pageNumber,
        isNew,
      }),
    );
  }
});

export const getSavedSummaries: (
  query: GetSavedSummariesQuery,
) => ThunkAction<mixed> = flow(
  key(
    (query: GetSavedSummariesQuery) =>
      `generatedSummaries-${JSON.stringify(query)}`,
  ),
  fetching(),
)(
  (query: GetSavedSummariesQuery) => (dispatch: Dispatch) =>
    dispatch(
      reduxApi.get('/surveys/response-summaries', query, analyticsOptions),
    )
      .then((payload: SavedSurveySummaryListResponseAPI) => {
        const pageNumber = query.offset / query.limit + 1;
        const {total_count: totalCount, summaries} = payload;
        dispatch(
          receiveSavedSummaryList({
            totalCount,
            summaries,
            pageNumber,
          }),
        );
      })
      .catch(() => {
        dispatch(
          receiveSavedSummaryList({
            totalCount: 0,
            summaries: [],
            pageNumber: 1,
          }),
        );
      }),
);

export const getSavedSummaryById: (summaryId: string) => ThunkAction<mixed> =
  flow(
    key((summaryId: string) => `getSavedSummary-${summaryId}`),
    fetching(),
  )(
    (summaryId: string) => (dispatch: Dispatch) =>
      dispatch(
        reduxApi.get(
          '/surveys/response-summaries',
          {id: summaryId},
          analyticsOptions,
        ),
      )
        .then((payload: SurveySummarySavedResponseAPI) => {
          dispatch(receiveSavedSummary(parseSummaryPayload(payload)));
        })
        .catch(() => {
          dispatch(receiveSavedSummary(null));
        }),
  );

export const generateSummary: (
  formData: GenerateSummaryFormData,
) => ThunkAction<mixed> = flow(
  key((formData) => `generateSummary-${formData}`),
  fetching(),
)(
  (formData: GenerateSummaryFormData) => (dispatch: Dispatch) =>
    dispatch(
      reduxApi.post(
        '/surveys/generate-response-summary',
        formData,
        {},
        analyticsPostOptions,
      ),
    )
      .then((payload: SurveySummarySavedResponseAPI) => {
        dispatch(receiveSavedSummary(parseSummaryPayload(payload)));
      })
      .catch(() => {
        dispatch(receiveSavedSummary(null));
      }),
);

export const saveSummary: (summaryId: string) => ThunkAction<mixed> = flow(
  key((summaryId: string) => `saveSummary-${summaryId})`),
  fetching(),
)(
  (summaryId: string) => (dispatch: Dispatch) =>
    dispatch(
      reduxApi.post(
        '/surveys/save-summary',
        {id: summaryId},
        {},
        analyticsPostOptions,
      ),
    ),
);

export const getResponseCount: (
  formData: GenerateSummaryFormData,
) => ThunkAction<mixed> = (formData) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.post(
      `/surveys/response-count`,
      formData,
      {},
      analyticsPostOptions,
    ),
  );
};
