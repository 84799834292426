// @flow strict
import {
  IN_OPERATORS,
  getLimitRelatedErrors,
  isThereDeprecatedOperator,
} from 'src/utils/audience-list';

import type {AudienceListState} from 'src/reducers/audience-list';
//$FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';
import {createSelector} from 'reselect';
/*
 * Base Selectors
 */
export const selectList = (state: State): AudienceListState =>
  state.audienceList;

export const selectTotalInNotInCount = (state: State): number => {
  const {inclusions, exclusions}: AudienceListState = state.audienceList;
  let totalInNotInRuleCount = 0;
  const inclusionsAndExclusions = new Map([...inclusions, ...exclusions]);
  inclusionsAndExclusions.forEach((ruleBlockArr, ruleBlockKey) => {
    ruleBlockArr.forEach((ruleItem) => {
      if (ruleItem.operator && IN_OPERATORS.includes(ruleItem.operator)) {
        const ruleValueCount = Array.isArray(ruleItem.value)
          ? ruleItem.value.length
          : 0;
        totalInNotInRuleCount += ruleValueCount;
      }
    });
  });
  return totalInNotInRuleCount;
};

export const selectLimitRelatedErrors: (state: State) => Array<string> =
  createSelector(
    selectList,
    selectTotalInNotInCount,
    (audienceList, totalInNotInCount) => {
      const {inclusions, exclusions}: AudienceListState = audienceList;
      const allBlocks = new Map([...inclusions, ...exclusions]);
      const ruleItemWithErrors = getLimitRelatedErrors(
        allBlocks,
        totalInNotInCount,
      );
      return ruleItemWithErrors;
    },
  );

export const selectIsDepricatedOperatorPresent: (state: State) => boolean =
  createSelector(selectList, (audienceList) => {
    const {inclusions, exclusions}: AudienceListState = audienceList;
    const allBlocks = new Map([...inclusions, ...exclusions]);
    const isDeprecatedOperatorPresent = isThereDeprecatedOperator(allBlocks);
    return isDeprecatedOperatorPresent;
  });
