// @flow strict

import {useSelector} from 'react-redux';


export const useEnvironmentContext = (): {
  isExtension: boolean,
} => {
  const {context} = useSelector((state) => state.env.query);
  const isExtension = context === 'chrome-extension';

  return {isExtension};
};
