// @flow

import type {AgencyOverviewAction} from 'src/action-creators/agency-overview';
// $FlowFixMe[untyped-type-import]
import type {AgencyOverview} from 'src/api-parsers/index';

import {
  RECEIVE,
  RECEIVE_ACTIVE_AUDIENCE,
} from 'src/action-creators/agency-overview';


export type AgencyOverviewState = {
  // $FlowFixMe[value-as-type] [v1.32.0]
  data: {} | AgencyOverview, // TODO(marcos): fill this in
  activeAudienceMembers: ?number,
};

const initialState = {
  data: {},
  activeAudienceMembers: null,
};

export default (
  state: AgencyOverviewState = initialState,
  action: AgencyOverviewAction,
): AgencyOverviewState => {
  switch (action.type) {
    case RECEIVE:
      return {...state, data: action.payload};
    case RECEIVE_ACTIVE_AUDIENCE:
      // TODO (chris): Remove when multiEntityEnabled is deprecated
      return {
        ...state,
        activeAudienceMembers: action.payload,
      };
  }

  return state;
};
