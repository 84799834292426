// @flow

import * as React from 'react';
import startCase from 'lodash/startCase';

import {FilterCard} from './';
import Checkbox from 'src/components/lib/checkbox';

import css from './recipient-card.css';


type Props = {
  onSave?: (e: SyntheticEvent<>) => mixed,
  onCancel?: (e: SyntheticEvent<>) => mixed,
  onTrackingEventTypeChange: (e: SyntheticEvent<HTMLInputElement>) => mixed,
  trackingEventTypes: string[],
  selectedTrackingEventTypes: string[],
};

const TrackingEventCard = ({
  onSave,
  onCancel,
  onTrackingEventTypeChange,
  trackingEventTypes = [],
  selectedTrackingEventTypes = [],
}: Props): React.Node => (
  <FilterCard header="Marketing" onSave={onSave} onCancel={onCancel}>
    <section className={css.section}>
      <ul className={css.checklist}>
        {trackingEventTypes.map((value) => (
          <li key={value}>
            <label className={css.label}>
              <Checkbox
                className={css.checkbox}
                name={value}
                checked={selectedTrackingEventTypes.includes(value)}
                onChange={onTrackingEventTypeChange}
              />
              {startCase(value)}
            </label>
          </li>
        ))}
      </ul>
    </section>
  </FilterCard>
);

export default TrackingEventCard;
