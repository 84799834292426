// @flow strict-local

import type {SenseAction} from 'src/action-creators/types';


type ActionSet = Array<SenseAction | BatchAction>;

export type BatchAction = {
  type: '@@batch',
  payload: ActionSet,
};

export const BATCH_ACTIONS = '@@batch';

export const batch = (...actions: ActionSet): BatchAction => ({
  type: BATCH_ACTIONS,
  payload: actions,
});
