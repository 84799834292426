// @noflow

import type {ContactList, CsvResults} from 'src/types/contacts';

import * as React from 'react';

import {formatPlural} from 'src/utils/intl';

import useAgencyConfig from 'src/hooks/useAgencyConfig';

import FileButton from 'src/components/lib/file-button';
import {StringErrorBox} from 'src/components/lib/error-box';

import css from './preview-page.css';


const CsvPreview = ({
  contactList,
  results,
  onFileChange,
  onSubmit,
  removeModal,
}: {
  contactList?: ContactList,
  results: CsvResults,
  onFileChange: (event: SyntheticEvent<HTMLInputElement>) => void,
  onSubmit: () => void,
  removeModal: () => void,
}) => {
  const contactListLimit = useAgencyConfig('maxContactsCsvUpload');
  const {
    data,
    meta: {fields, numDropped},
  } = results;
  const numberOverLimit = contactList
    ? contactList.contacts.length + data.length - contactListLimit
    : 0;
  const tooMany = contactList && numberOverLimit > 0;

  let errors;
  // NOTE (kyle): since both of these errors implicate list length, only show
  // one at a time.
  if (tooMany) {
    errors = [
      `Adding ${data.length} rows to this list will put it over the limit (${contactListLimit}) by ${numberOverLimit} contacts. Please reduce the size of your CSV and try again.`,
    ];
  } else if (numDropped > 0) {
    errors = [
      `${numDropped} of your rows ${formatPlural(
        numDropped,
        'is',
        'are',
      )} missing required fields and will not be added.`,
    ];
  }

  return (
    <div className={css.root}>
      {errors && <StringErrorBox errors={errors} />}

      <div className={css.title}>Contact List Preview</div>
      <div className={css.tableContainer}>
        <div className={css.table}>
          {fields.map((field) => (
            <div className={css.column} key={field}>
              <div className={css.header}>{field.replace(/_/g, ' ')}</div>
              {data.slice(0, 10).map(({[field]: value}, i) => (
                <div key={i}>{value}</div>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div className={css.actions}>
        <FileButton onChange={onFileChange} accept=".csv">
          Re-Upload
        </FileButton>

        <div className={css.actionsRight}>
          <button className={css.cancel} onClick={removeModal}>
            Cancel
          </button>
          <button className={css.confirm} onClick={onSubmit} disabled={tooMany}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default CsvPreview;
