// @flow strict
import RRule from 'rrule';
import type {SchedulingStoreData} from 'src/types/automation-workflow-scheduling';
import type {AutomationWorkflowSchedulingAction} from 'src/action-creators/automation-workflow-scheduling.js';

import {
  UPADTE_NODE_SCHEDULING,
  RECEIVE_NODE_SCHEDULING,
} from 'src/action-creators/automation-workflow-scheduling.js';


const initialState = {
  id: null,
  date_type: '',
  date_value: '',
  time_type: '',
  time_value: '',
  repeat_enable: false,
  skip: '0',
  repeat: {
    interval: 1,
    freq: RRule.DAILY,
    until: 0,
    count: 1,
  },
  dateOffset: {
    sign: 1,
    count: 0,
    unit: 'days',
  },
  timeOffset: {
    sign: 1,
    count: 0,
    unit: 'hours',
  },

  date_field: null,
  time_field: null,
};

export default (
  state: SchedulingStoreData = initialState,
  action: AutomationWorkflowSchedulingAction,
): SchedulingStoreData => {
  switch (action.type) {
    case UPADTE_NODE_SCHEDULING:
      return {
        ...state,
        ...action.payload,
      };
    case RECEIVE_NODE_SCHEDULING:
      return {
        ...state,
        ...action.payload,
      };
  }
  return state;
};
