// @flow strict
//$FlowFixMe
import type {Dispatch, ThunkAction} from 'src/reducers';
//$FlowFixMe
import * as reduxApi from 'src/utils/redux-api-v2';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
import urlParse from 'url-parse';
// $FlowFixMe[nonstrict-import]
import {getCsrfToken} from 'src/action-creators/csrf';
import {AnalyticsService} from 'src/analytics';
import {createElement} from 'src/utils/dom';
import logger from 'src/utils/logger';

import type {
  CredsAuthentication,
  ApiErrorWithStatusCode,
} from 'src/types/authentication';

// $FlowFixMe[nonstrict-import]
import type {Agency} from 'src/types/agency';


export const CREDS_AUTHENTICATION = 'authentication/creds_authentication';
export const CREDS_AUTHENTICATION_ERROR =
  'authentication/creds_authentication_error';
export const CREDS_AUTHENTICATED = 'authentication/creds_authenticated';

export const VALID_AGENCIES = 'authentication/valid_agencies';
export const VALID_AGENCIES_ERROR = 'authentication/valid_agencies_error';

export const NEW_PHONE_REGISTRATION = 'authentication/new_phone_registration';
export const NEW_PHONE_REGISTRATION_ERROR =
  'authentication/new_phone_registration_error';

export const OTP_VERIFICATION_ERROR = 'authentication/otp_verification_error';

export const RESEND_OTP_ERROR = 'authentication/resend_otp_error';

export const RESET_PHONE_ERROR = 'authentication/reset_phone_error';

export const RESET_PHONE_APPROVED = 'authentication/reset_phone_approved';

export const RESET_PHONE_APPROVE_ERROR =
  'authentication/reset_phone_approve_error';

export const FORGOT_PASSWORD_ERROR = 'authentication/forgot_password_error';

export const RESET_PASSWORD_ERROR = 'authentication/reset_password_error';

export const SIGNUP_ERROR = 'authentication/signup_error';

type AuthenticatingCredAction = {
  type: typeof CREDS_AUTHENTICATION,
};

type CredAuthErrorAction = {
  type: typeof CREDS_AUTHENTICATION_ERROR,
  payload: string,
};

type CredAuthenticatedAction = {
  type: typeof CREDS_AUTHENTICATED,
  payload: CredsAuthentication,
};

type NewPhoneRegitrationAction = {
  type: typeof NEW_PHONE_REGISTRATION,
  payload: string,
};

type OTPVerificationErrorAction = {
  type: typeof OTP_VERIFICATION_ERROR,
  payload: ApiErrorWithStatusCode,
};

type ResendOTPErrorAction = {
  type: typeof RESEND_OTP_ERROR,
  payload: ApiErrorWithStatusCode,
};

type ResetPhoneErrorAction = {
  type: typeof RESET_PHONE_ERROR,
  payload: string,
};

type ResetPhoneApproveErrorAction = {
  type: typeof RESET_PHONE_APPROVE_ERROR,
  payload: string,
};

type ResetPhoneApprovedAction = {
  type: typeof RESET_PHONE_APPROVED,
  payload: string,
};

type NewPhoneRegistrationError = {
  type: typeof NEW_PHONE_REGISTRATION_ERROR,
  payload: string,
};

type ValidAgenciesAction = {
  type: typeof VALID_AGENCIES,
  payload: Array<Agency>,
};

type ValidAgenciesErrorAction = {
  type: typeof VALID_AGENCIES_ERROR,
  payload: string,
};

type ForgotPasswordErrorAction = {
  type: typeof FORGOT_PASSWORD_ERROR,
  payload: string,
};

type ResetPasswordErrorAction = {
  type: typeof RESET_PASSWORD_ERROR,
  payload: string,
};

type SignUpErrorAction = {
  type: typeof SIGNUP_ERROR,
  payload: string,
};

export type AuthenticationActions =
  | AuthenticatingCredAction
  | CredAuthErrorAction
  | CredAuthenticatedAction
  | NewPhoneRegitrationAction
  | OTPVerificationErrorAction
  | ResendOTPErrorAction
  | NewPhoneRegistrationError
  | ValidAgenciesAction
  | ValidAgenciesErrorAction
  | ForgotPasswordErrorAction
  | ResetPhoneErrorAction
  | ResetPhoneApproveErrorAction
  | ResetPhoneApprovedAction
  | ResetPasswordErrorAction
  | SignUpErrorAction;

export const authenticatingCred = (): AuthenticatingCredAction => ({
  type: CREDS_AUTHENTICATION,
});

export const credAuthError = (payload: string): CredAuthErrorAction => ({
  type: CREDS_AUTHENTICATION_ERROR,
  payload,
});

export const credsAuthenticated = (
  payload: CredsAuthentication,
): CredAuthenticatedAction => ({
  type: CREDS_AUTHENTICATED,
  payload,
});

export const newPhoneRegistration = (
  payload: string,
): NewPhoneRegitrationAction => ({
  type: NEW_PHONE_REGISTRATION,
  payload,
});

export const newPhoneRegistrationError = (
  payload: string,
): NewPhoneRegistrationError => ({
  type: NEW_PHONE_REGISTRATION_ERROR,
  payload,
});

export const OTPVerificationError = (
  payload: ApiErrorWithStatusCode,
): OTPVerificationErrorAction => ({
  type: OTP_VERIFICATION_ERROR,
  payload,
});

export const resendOtpError = (
  payload: ApiErrorWithStatusCode,
): ResendOTPErrorAction => ({
  type: RESEND_OTP_ERROR,
  payload,
});

export const resetPhoneError = (payload: string): ResetPhoneErrorAction => ({
  type: RESET_PHONE_ERROR,
  payload,
});

export const resetPhoneApproveError = (
  payload: string,
): ResetPhoneApproveErrorAction => ({
  type: RESET_PHONE_APPROVE_ERROR,
  payload,
});

export const resetPhoneApproved = (
  payload: string,
): ResetPhoneApprovedAction => ({
  type: RESET_PHONE_APPROVED,
  payload,
});

export const setValidAgencies = (
  payload: Array<Agency>,
): ValidAgenciesAction => ({
  type: VALID_AGENCIES,
  payload,
});

export const validAgenciesError = (
  payload: string,
): ValidAgenciesErrorAction => ({
  type: VALID_AGENCIES_ERROR,
  payload,
});

export const forgotPasswordError = (
  payload: string,
): ForgotPasswordErrorAction => ({
  type: FORGOT_PASSWORD_ERROR,
  payload,
});

export const resetPasswordError = (
  payload: string,
): ResetPasswordErrorAction => ({
  type: RESET_PASSWORD_ERROR,
  payload,
});

export const signUpError = (payload: string): SignUpErrorAction => ({
  type: SIGNUP_ERROR,
  payload,
});

export const authenticateCreds: (
  email: string,
  password: string,
) => ThunkAction<void> = flow(
  key((email, password) => `auth`),
  fetching(),
)((email, password) => (dispatch: Dispatch) => {
  dispatch(authenticatingCred());
  return dispatch(reduxApi.post(`auth`, {email, password})).then(
    (response) => {
      dispatch(credsAuthenticated(response));
      return response;
    },
    (error) => {
      if (error?.response?.status === 300) {
        // Migrated agent, redirection is handled in component
        return error;
      }
      // default error for login
      let apiError = 'Incorrect Email and Password';
      if (error?.responseBody?.errors && error.responseBody.errors[0]) {
        apiError = error.responseBody.errors[0];
      }
      dispatch(credAuthError(apiError));
    },
  );
});

export const checkRedirection: () => ThunkAction<mixed> =
  () => (dispatch: Dispatch) => {
    return dispatch(reduxApi.post('auth', {})).then(
      (response) => {
        dispatch(credsAuthenticated(response));
        return response;
      },
      (error) => {
        logger.error('error in redirection check', error);
      },
    );
  };

export const centralLoginAuth: (
  email: string,
  password: string,
) => ThunkAction<void> = flow(
  key((email, password) => `auth/valid-agencies`),
  fetching(),
)((email, password) => (dispatch: Dispatch) => {
  dispatch(authenticatingCred());
  return dispatch(reduxApi.post(`auth/valid-agencies`, {email, password})).then(
    (response) => {
      dispatch(setValidAgencies(response));
      return response;
    },
    (error) => {
      // default error for login
      let apiError = 'Incorrect Email and Password';
      if (error?.responseBody?.errors && error.responseBody.errors[0]) {
        apiError = error.responseBody.errors[0];
      }
      dispatch(validAgenciesError(apiError));
    },
  );
});

export const mockSignIn: (
  email: string,
  password: string,
  domain: string,
  slug: string,
) => ThunkAction<mixed> =
  (email, password, domain, slug) =>
  (dispatch: Dispatch): Promise<boolean> => {
    return new Promise(async (resolve) => {
      const action = getFormAction(slug, domain);
      const fakeForm = createElement(document, 'form', {
        style: 'display: none',
        method: 'POST',
        action,
      });

      const fakeFormEmailInput = createElement(document, 'input', {
        name: 'email',
        value: email,
      });
      fakeForm.appendChild(fakeFormEmailInput);

      const fakeFormPasswordInput = createElement(document, 'input', {
        name: 'password',
        type: 'password',
        value: password,
      });
      fakeForm.appendChild(fakeFormPasswordInput);

      window.document.body.appendChild(fakeForm);

      //$FlowFixMe[prop-missing]
      fakeForm.submit();

      resolve(true);
    });
  };

const getFormAction = (slug, domain) => {
  const query = urlParse(window.location, true).query;
  const nextPath = query.nextPathname || '';
  const nextSearch = query.nextSearch || '';
  const origin = `https://${slug}.${domain}`;

  const defaultPath = encodeURIComponent(
    `${origin}${nextPath || ''}${nextSearch || ''}${
      nextSearch ? '&' : '?'
    }redirect=true`,
  );
  // use redirect=True for checking the auth condition on the next request
  // else we'll have to call the auth request on each call
  const action = `${origin}/api/v1/auth?default_path=${defaultPath}`;
  return action;
};

export const registerNewPhone: (
  phone: string,
  email: string,
  loginId: string,
) => ThunkAction<void> = flow(
  key((phone, email, loginId) => 'mfa/register-new-phone'),
  fetching(),
)((phone, email, loginId) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.post(`mfa/register-new-phone`, {
      phone,
      email,
      login_id: loginId,
    }),
  ).then(
    (response) => {
      dispatch(newPhoneRegistration(phone));
      return response;
    },
    (error) => {
      // default error for resend OTP
      let apiError = 'Error Sending OTP to the phone';
      if (error?.responseBody?.errors && error.responseBody.errors[0]) {
        apiError = error.responseBody.errors[0];
      }

      dispatch(newPhoneRegistrationError(apiError));
    },
  );
});

export const verifyOtpForUser: (
  otp: string,
  email: string,
  loginId: string,
  newPhone: string,
) => ThunkAction<void> = flow(
  key((otp, email, loginId, newPhone) => 'mfa/verify'),
  fetching(),
)((otp, email, loginId, newPhone) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.post(`mfa/verify`, {
      user_otp: otp,
      email,
      login_id: loginId,
      new_phone: newPhone,
    }),
  ).then(
    (response) => {
      return response;
    },
    (error) => {
      // default error for login
      let apiError = 'Incorrect OTP Entered';
      if (error?.responseBody?.errors && error.responseBody.errors[0]) {
        apiError = error.responseBody.errors[0];
      }
      dispatch(
        OTPVerificationError({
          error: apiError,
          status_code: error.response.status,
        }),
      );
    },
  );
});

export const performSignIn: (agency: Agency) => ThunkAction<mixed> =
  (agency) =>
  (dispatch: Dispatch): Promise<boolean> => {
    return new Promise(async (resolve) => {
      try {
        await dispatch(getCsrfToken());
        const {host, protocol} = window.location;
        const baseDomain = host.substring(host.indexOf('.') + 1);
        const query = urlParse(window.location, true).query;
        const nextPath = query.nextPathname || '';
        const nextSearch = query.nextSearch || '';
        trackLogin('sign-in', {isSuccees: true});
        window.location = `${protocol}//${agency.slug}.${baseDomain}${nextPath}${nextSearch}`;
        resolve(true);
      } catch (error) {
        trackLogin('sign-in', {isSuccees: false, error: JSON.stringify(error)});
        logger.error(error);
      }
    });
  };

function trackLogin(event, props) {
  try {
    AnalyticsService.track(event, props);
  } catch (error) {
    console.log('event tracking error ' + error);
  }
}

export const resendOtp: (
  email: string,
  loginId: string,
  newPhone: string,
) => ThunkAction<void> = flow(
  key((email, loginId, newPhone) => 'mfa/resend'),
  fetching(),
)((email, loginId, newPhone) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.post(`mfa/resend`, {
      login_id: loginId,
      email,
      new_phone: newPhone,
    }),
  ).then(
    (response) => {
      return response;
    },
    (error) => {
      // default error for resend OTP
      let apiError = 'Error Sending OTP to the phone';
      if (error?.responseBody?.errors && error.responseBody.errors[0]) {
        apiError = error.responseBody.errors[0];
      }
      dispatch(
        resendOtpError({error: apiError, status_code: error.response.status}),
      );
    },
  );
});

export const resetPhone: (email: string) => ThunkAction<void> = flow(
  key((email) => 'mfa-setting/phone/reset'),
  fetching(),
)((email) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.post(`mfa-setting/phone/reset`, {
      email,
    }),
  ).then(
    (response) => {
      return response;
    },
    (error) => {
      // default error for reset OTP
      let apiError = 'Error while removing the phone number';
      if (error?.responseBody?.errors && error.responseBody.errors[0]) {
        apiError = error.responseBody.errors[0];
      }
      dispatch(resetPhoneError(apiError));
    },
  );
});

export const resetPhoneApprove: (approval_token: string) => ThunkAction<void> =
  flow(
    key((approval_token) => 'mfa-setting/phone/reset/approve'),
    fetching(),
  )((approval_token) => (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.post(`mfa-setting/phone/reset/approve`, {
        approval_token,
      }),
    ).then(
      (response) => {
        const respText = `Reset phone number for ${response.user_email}`;
        dispatch(resetPhoneApproved(respText));
        // $FlowFixMe[incompatible-type-arg]
        return respText;
      },
      (error) => {
        // default error for approving reset phone
        let apiError = 'Error while approving the reset phone number request';
        if (error?.responseBody?.errors && error.responseBody.errors[0]) {
          apiError = error.responseBody.errors[0];
        }
        dispatch(resetPhoneApproveError(apiError));
      },
    );
  });

export const forgotPassword: (email: string) => ThunkAction<void> = flow(
  key((email) => 'accounts/me/forgot-password'),
  fetching(),
)((email) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.post(`accounts/me/forgot-password`, {
      email,
    }),
  ).then(
    (response) => {
      return response;
    },
    (error) => {
      // default error for forgot password
      let apiError = 'Failed to perform action, please try after sometime';
      if (error?.responseBody?.errors && error.responseBody.errors[0]) {
        apiError = error.responseBody.errors[0];
      }
      dispatch(forgotPasswordError(apiError));
    },
  );
});

export const forgotPasswordNoAgency: (email: string) => ThunkAction<void> =
  flow(
    key((email) => 'accounts/me/forgot-password-no-agency'),
    fetching(),
  )((email) => (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.post(`accounts/me/forgot-password-no-agency`, {
        email,
      }),
    ).then(
      (response) => {
        return response;
      },
      (error) => {
        // default error for forgot password
        let apiError = 'Failed to perform action, please try after sometime';
        if (error?.responseBody?.errors && error.responseBody.errors[0]) {
          apiError = error.responseBody.errors[0];
        }
        dispatch(forgotPasswordError(apiError));
      },
    );
  });

export const resetPassword: (
  reset_id: string,
  new_password: string,
  new_password_copy: string,
) => ThunkAction<void> = flow(
  key(
    (reset_id, new_password, new_password_copy) => 'accounts/me/reset-password',
  ),
  fetching(),
)((reset_id, new_password, new_password_copy) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.post(`accounts/me/reset-password`, {
      reset_id,
      new_password,
      new_password_copy,
    }),
  ).then(
    (response) => {
      // $FlowIssue[incompatible-type-arg] - the return here is good
      return true;
    },
    (error) => {
      // default error for forgot password
      let apiError = 'Failed to perform action, please try after sometime';
      if (error?.responseBody?.errors && error.responseBody.errors[0]) {
        apiError = error.responseBody.errors[0];
      }
      dispatch(resetPasswordError(apiError));
    },
  );
});

export const updatePassword: (
  password: string,
  new_password: string,
  new_password_copy: string,
) => ThunkAction<void> = flow(
  key((password, new_password, new_password_copy) => 'accounts/me/password'),
  fetching(),
)((password, new_password, new_password_copy) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.post(`accounts/me/password`, {
      password,
      new_password,
      new_password_copy,
    }),
  ).then(
    (response) => {
      // $FlowIssue[incompatible-type-arg] - the return here is good
      return true;
    },
    (error) => {
      // default error for reset password
      let apiError = 'Failed to perform action, please try after sometime';
      if (error?.responseBody?.errors && error.responseBody.errors[0]) {
        apiError = error.responseBody.errors[0];
      }
      dispatch(resetPasswordError(apiError));
    },
  );
});

export const signUpUser: (
  fullname: string,
  email: string,
  password: string,
  invite_code: ?string,
) => ThunkAction<void> = flow(
  key((fullname, email, password, invite_code) => 'accounts'),
  fetching(),
)((fullname, email, password, invite_code) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.post(`accounts`, {
      fullname,
      email,
      password,
      invite_code,
    }),
  ).then(
    (response) => {
      dispatch(credsAuthenticated(response));
      return response;
    },
    (error) => {
      // default error for reset password
      let apiError = 'Failed to perform action, please try after sometime';
      if (error?.responseBody?.errors && error.responseBody.errors[0]) {
        apiError = error.responseBody.errors[0];
      }
      dispatch(signUpError(apiError));
    },
  );
});
