// @flow strict

import type {ReactRef} from 'src/types/react';

import {useRef, useEffect} from 'react';

/**
 * creates an effect that will attempt to autofocus any DOM element given the
 * ref it returns.
 *
 * useful for autofocusing an input or button when a component mounts.
 *
 * you probably don't want to use more than one of these in a component since
 * only one element can be focused at a time.
 */
export default function useAutoFocus(): ReactRef<HTMLElement> {
  const ref = useRef<?HTMLElement>();

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return ref;
}
