// @flow

// $FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores/index';
import type {
  RequestOptions,
  ApiOptions,
  ApiPostOptions,
} from 'src/utils/redux-api';
import type {RawQuery} from 'src/types/router';

import {baseRequest} from 'src/utils/redux-api-v2';


export function get(
  // $FlowFixMe[value-as-type] [v1.32.0]
  store: IndexStore,
  path: string,
  query?: RawQuery,
  options?: ApiOptions,
): Promise<any> {
  return makeRequest(store, path, {
    query,
    options,
  });
}

type ProgressEvent = {
  percent?: number,
};

// TODO (kyle): the put and post methods could maybe be DRY-er?
export function post(
  // $FlowFixMe[value-as-type] [v1.32.0]
  store: IndexStore,
  path: string,
  data?: mixed,
  query?: RawQuery,
  options?: ApiPostOptions,
): Promise<any> {
  return makeRequest(store, path, {
    method: 'POST',
    data,
    query,
    options,
  });
}

export function put(
  // $FlowFixMe[value-as-type] [v1.32.0]
  store: IndexStore,
  path: string,
  data?: mixed,
  query?: RawQuery,
  options?: ApiOptions,
): Promise<any> {
  return makeRequest(store, path, {
    method: 'PUT',
    data,
    query,
    options,
  });
}

export function del(
  // $FlowFixMe[value-as-type] [v1.32.0]
  store: IndexStore,
  path: string,
  options?: ApiOptions,
): Promise<any> {
  return makeRequest(store, path, {
    method: 'DELETE',
    options,
  });
}

export async function makeRequest<R>(
  // $FlowFixMe[value-as-type] [v1.32.0]
  store: IndexStore,
  path: string,
  options: RequestOptions,
): Promise<R> {
  try {
    return await store.reduxStore.dispatch(baseRequest(path, options));
  } catch (error) {
    // NOTE (kyle): we convert the api error here to the legacy error format.
    // in general it is bad practice to throw an object.
    throw {
      error,
      status: error.response?.status,
      response: error.responseBody || {},
    };
  }
}
