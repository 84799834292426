// @flow

import type {
  Dispatch,
  GetState,
  ThunkAction,
  ThunkSyncAction,
} from 'src/reducers';
import type {ApiAccount, Account, AuthedUserAccount} from 'src/types/account';
// $FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores/index';

import logger from 'src/utils/logger';

import {thunkify as flow} from 'src/utils/thunks';
import {camel, snake} from 'src/utils';
import {key, cached, fetching, evict} from 'src/utils/redux';
import {getAccounts as getGroupAccounts} from 'src/action-creators/group-accounts';
import {getGroups} from 'src/action-creators/groups';
import {startDataExport} from 'src/action-creators/data-export-report';

import * as reduxApi from 'src/utils/redux-api-v2';


export type AccountsAction =
  | ReceiveAccountsAction
  | RemoveAccountAction
  | MergeAccountAction
  | ReceiveAuthedUserAction
  | SetAuthedAction
  | ReceiveAuthedUserPhoneAction
  | InputAuthedUserPhoneAction
  | SetPostAuthPathAction
  | SetConfirmationMessageAction;

export const SET_CONFIRMATION_MESSAGE = 'accounts/setConfirmationMessage';
export const RECEIVE: 'accounts/receive' = 'accounts/receive';
export const REMOVE_ACCOUNT: 'accounts/removeAccount' =
  'accounts/removeAccount';

type ReceiveAccountsAction = {
  type: 'accounts/receive',
  payload: Account[],
};

export const receive = (payload: Account[]): ReceiveAccountsAction => ({
  type: RECEIVE,
  payload,
});

export const MERGE_ACCOUNT: 'accounts/receiveAccount' =
  'accounts/receiveAccount';

type MergeAccountAction = {
  type: 'accounts/receiveAccount',
  payload: AccountMerge,
};

export type AccountMerge = {
  id: string,
  name?: string,
  email?: string,
  phone?: string,
  suspended?: boolean,
  profilePicture?: {url?: string, ...},
};

export const mergeAccountInfo = (
  payload: AccountMerge,
): MergeAccountAction => ({
  type: MERGE_ACCOUNT,
  payload,
});

export const RECEIVE_AUTHED_USER: 'accounts/receiveAuthedUser' =
  'accounts/receiveAuthedUser';

type ReceiveAuthedUserAction = {
  type: 'accounts/receiveAuthedUser',
  payload: AuthedUserAccount,
};

export const receiveAuthedUser = (
  payload: AuthedUserAccount,
): ReceiveAuthedUserAction => ({
  type: RECEIVE_AUTHED_USER,
  payload,
});

export const getAuthedAccount =
  (): ThunkAction<ApiAccount> => async (dispatch) => {
    const accountResponse: ApiAccount = await dispatch(
      reduxApi.get('accounts/me'),
    );
    const account = camel(accountResponse);
    account.id = account.agentId;
    delete account.agentId;
    dispatch(mergeAccountInfo(account));
    return accountResponse;
  };

export const getErrorHandledAuthedAccount =
  (): ThunkAction<> => async (dispatch: Dispatch) => {
    try {
      const accountResponse = await dispatch(reduxApi.get('accounts/me'));
      const account = camel(accountResponse);
      account.id = account.agentId;
      delete account.agentId;
      dispatch(mergeAccountInfo(account));
    } catch (error) {
      if (error.status === 401) {
        logger.log('accounts/me Unauthorized');
      } else {
        logger.error('accounts/me error: ', error.responseBody || error.stack);
      }
    }
  };

// $FlowFixMe[value-as-type] [v1.32.0]
type Store = IndexStore;
export const getAuthedUser =
  (store: Store): ThunkAction<> =>
  (dispatch: Dispatch) =>
    dispatch(reduxApi.get('agents/me'))
      .then((response) => {
        const user = camel(response);
        store.me.receiveFetched(user);
        // $FlowFixMe api parser doesn't return correct type
        dispatch(receiveAuthedUser(user));

        if (user.phone) {
          dispatch(receiveAuthedUserPhone(user.phone));
        } else {
          dispatch(receiveAuthedUserPhone(null, MISSING_TWILIO_NUMBER));
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          logger.log('agents/me Unauthorized');
        } else {
          logger.error('agents/me error: ', error.responseBody || error.stack);
        }
      });

type SetAuthedAction = {
  type: 'accounts/setAuthed',
  payload: boolean,
};
export const SET_AUTHED = 'accounts/setAuthed';
export const setAuthed: (isAuthed: boolean) => SetAuthedAction = (
  isAuthed,
) => ({
  type: SET_AUTHED,
  payload: isAuthed,
});

export const getAccounts: () => ThunkAction<> = flow(
  key(() => 'getAccounts'),
  cached((response) => receive(camel(response)), {ttl: 2000}),
  fetching(),
)(() => reduxApi.get(`accounts`));

export const RECEIVE_AUTHED_USER_PHONE: 'accounts/receiveAuthedUserPhone' =
  'accounts/receiveAuthedUserPhone';

const MISSING_TWILIO_NUMBER = 'Missing Twilio number for logged in user';

type ReceiveAuthedUserPhoneAction = {
  type: 'accounts/receiveAuthedUserPhone',
  payload: ?string,
  error: boolean,
};

export const receiveAuthedUserPhone = (
  payload: ?string,
  error?: string,
): ReceiveAuthedUserPhoneAction => ({
  type: RECEIVE_AUTHED_USER_PHONE,
  payload: error || payload,
  error: Boolean(error),
});

export const INPUT_AUTHED_USER_PHONE: 'accounts/inputAuthedUserPhone' =
  'accounts/inputAuthedUserPhone';

type InputAuthedUserPhoneAction = {
  type: 'accounts/inputAuthedUserPhone',
  payload: string,
};

export const inputAuthedUserPhone = (
  payload: string,
): InputAuthedUserPhoneAction => ({
  type: INPUT_AUTHED_USER_PHONE,
  payload,
});

export const createPhoneForAuthedUser =
  (phone: string): ThunkAction<> =>
  (dispatch: Dispatch) =>
    dispatch(
      reduxApi.post(
        'agents/me',
        snake({
          phone,
        }),
      ),
    ).then((response) =>
      dispatch(receiveAuthedUserPhone(response && response.phone)),
    );

type SecurityData = {|
  name: string,
  securityGroupId?: string,
  securityRoleId?: string,
|};

export const updateAgent: (
  agent: Account,
  data: SecurityData,
  email?: string,
) => ThunkAction<> = flow(
  key((agent) => `updateAgent_${agent.id}`),
  fetching(),
)(
  (agent: Account, data: SecurityData, email?: string) =>
    (dispatch: Dispatch) => {
      const promises = [
        dispatch(reduxApi.put(`agents/${agent.id}`, snake(data))),
      ];
      const shouldUpdateEmail = email && agent.email !== email;
      if (shouldUpdateEmail) {
        promises.push(
          dispatch(
            reduxApi.put(`accounts/email`, {
              agent_id: agent.id,
              new_email: email,
            }),
          ),
        );
      }
      return Promise.all(promises).then((values) => {
        const updatedAgent = values[0];
        if (shouldUpdateEmail) {
          updatedAgent.email = email;
        }
        // ensure next call for getAccounts should return fresh data
        dispatch(evict(getAccounts()));
        if (data.securityGroupId) {
          dispatch(evict(getGroupAccounts(data.securityGroupId)));
        }
        dispatch(evict(getGroups()));
        dispatch(mergeAccountInfo(updatedAgent));
      });
    },
);

export const exportCsvData =
  (path: string): ThunkAction<> =>
  (dispatch: Dispatch) =>
    dispatch(startDataExport(path));

export const updateProfilePicture =
  (file: File): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const {data, authedUserId} = getState().accounts;
    const authedUserAccount = data[String(authedUserId)];
    if (authedUserAccount) {
      const oldProfilePicture = authedUserAccount.profilePicture;
      const url = URL.createObjectURL(file);
      dispatch(
        mergeAccountInfo({id: authedUserAccount.id, profilePicture: {url}}),
      );
      try {
        const formData = new FormData();
        formData.append('upload', file);
        if (oldProfilePicture?.url) {
          // $FlowFixMe[class-object-subtyping]
          await dispatch(reduxApi.put('accounts/profile_picture', formData));
        } else {
          // $FlowFixMe[class-object-subtyping]
          await dispatch(reduxApi.post('accounts/profile_picture', formData));
        }
      } catch (e) {
        authedUserAccount.profilePicture = oldProfilePicture;
        dispatch(
          mergeAccountInfo({
            id: authedUserAccount.id,
            profilePicture: oldProfilePicture,
          }),
        );
      }
    }
  };

export const removeProfilePicture =
  (): ThunkAction<> => async (dispatch: Dispatch, getState: GetState) => {
    const {data, authedUserId} = getState().accounts;
    const authedUserAccount = data[String(authedUserId)];
    if (authedUserAccount) {
      const oldProfilePicture = authedUserAccount.profilePicture;
      dispatch(
        mergeAccountInfo({id: authedUserAccount.id, profilePicture: {}}),
      );
      try {
        await dispatch(reduxApi.del('accounts/profile_picture'));
      } catch (e) {
        authedUserAccount.profilePicture = oldProfilePicture;
        dispatch(
          mergeAccountInfo({
            id: authedUserAccount.id,
            profilePicture: oldProfilePicture,
          }),
        );
      }
    }
  };

type RemoveAccountAction = {
  type: 'accounts/removeAccount',
  payload: string,
};

const removeAccount = (payload: string): RemoveAccountAction => ({
  type: REMOVE_ACCOUNT,
  payload,
});

export const deleteAccount =
  (accountId: string): ThunkAction<> =>
  (dispatch: Dispatch) =>
    dispatch(reduxApi.del(`accounts/${accountId}`)).then(() =>
      dispatch(removeAccount(accountId)),
    );

type SetPostAuthPathAction = {|
  type: 'accounts/setPostAuthPath',
  payload: ?string,
|};

export const setPostAuthPath = (path: ?string): SetPostAuthPathAction => ({
  type: 'accounts/setPostAuthPath',
  payload: path,
});

type SetConfirmationMessageAction = {
  type: 'accounts/setConfirmationMessage',
  payload: string,
};

const setConfirmationMessage = (
  payload: string,
): SetConfirmationMessageAction => ({
  type: SET_CONFIRMATION_MESSAGE,
  payload,
});

export const showConfirmationMessage =
  (message: string, duration: number = 5000): ThunkSyncAction =>
  (dispatch: Dispatch) => {
    dispatch(setConfirmationMessage(message));
    setTimeout(() => {
      dispatch(setConfirmationMessage(''));
    }, duration);
  };
