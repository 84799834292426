// @flow strict

import type {FilterRule} from 'src/types/audience-filter';
//$FlowFixMe[nonstrict-import]
import type {DynamicLabels} from 'src/types/dynamic-labels';
import type {
  ListTableEntry,
  AudienceListStatus,
  AssociatedResource,
  AssociatedResources,
} from 'src/types/lists';
import uniqueId from 'lodash/uniqueId';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
//$FlowFixMe[nonstrict-import]
import type {Workflow} from 'src/types/workflow';


export const PAGE_SIZE = 12;

export const LIST_NAME_VALID_CHARS_REGEX: RegExp =
  /^[A-Za-z-\s\d\_\{\}\(\)\[\]\&\,\*\?\'\"\:\;\.]*$/;

export const isValidListName = (name: string): boolean =>
  LIST_NAME_VALID_CHARS_REGEX.test(name);

export const LIST_STATUS_OPTIONS = [
  {label: 'Live', value: 'live'},
  {label: 'Published', value: 'published'},
  {label: 'Draft', value: 'draft'},
  {label: 'Archived', value: 'archived'},
];
export const getListsToArchive = (
  selected: string[],
  lists: ListTableEntry[],
): string[] =>
  selected.filter((id) => {
    const item = lists.find((list) => list.id === id);
    if (!item) {
      return false;
    }
    return item.status !== 'live' && item.archived === false;
  });
export const getListsToPublish = (
  selected: string[],
  lists: ListTableEntry[],
): string[] =>
  selected.filter((id) => {
    const item = lists.find((list) => list.id === id);
    if (!item) {
      return false;
    }
    return item.status === 'draft';
  });

export const OPERATOR_OPTIONS = {
  string: [
    {
      value: 'eq',
      label: 'is',
    },
    {
      value: 'neq',
      label: 'is not',
    },
    {
      value: 'empty',
      label: 'is empty',
    },
    {
      value: 'nempty',
      label: 'is not empty',
    },
    {
      value: 'contains',
      label: 'contains one of',
    },
    {
      value: 'ncontains',
      label: 'does not contain one of',
    },
    {
      value: 'starts_with',
      label: 'starts with one of',
    },
    {
      value: 'nstarts_with',
      label: 'does not start with one of',
    },
    {
      value: 'ends_with',
      label: 'ends with one of',
    },
    {
      value: 'any',
      label: 'has any value',
    },
    {
      value: 'in',
      label: 'is one of',
    },
    {
      value: 'not_in',
      label: 'is not one of',
    },
  ],
  number: [
    {
      value: 'gt',
      label: 'greater than',
    },
    {
      value: 'lt',
      label: 'less than',
    },
    {
      value: 'eq',
      label: 'equals',
    },
    {
      value: 'neq',
      label: 'not equal to',
    },
  ],
  currency: [
    {
      value: 'gt',
      label: 'greater than',
    },
    {
      value: 'lt',
      label: 'less than',
    },
    {
      value: 'eq',
      label: 'equals',
    },
    {
      value: 'neq',
      label: 'not equal to',
    },
  ],
  date: [
    {
      value: 'gt',
      label: 'is after',
    },
    {
      value: 'lt',
      label: 'is before',
    },
    {
      value: 'eq',
      label: 'is equal to',
    },
    {
      value: 'gte',
      label: 'is on or after',
    },
    {
      value: 'lte',
      label: 'is on or before',
    },
    {
      value: 'empty',
      label: 'is empty',
    },
  ],
  boolean: [
    {value: true, label: 'is true'},
    {value: false, label: 'is false'},
  ],
  email: [
    {
      value: 'eq',
      label: 'is',
    },
    {
      value: 'neq',
      label: 'is not',
    },
    {
      value: 'empty',
      label: 'is empty',
    },
    {
      value: 'nempty',
      label: 'is not empty',
    },
    {
      value: 'contains',
      label: 'contains one of',
    },
    {
      value: 'ncontains',
      label: 'does not contain one of',
    },
    {
      value: 'starts_with',
      label: 'starts with one of',
    },
    {
      value: 'nstarts_with',
      label: 'does not start with one of',
    },
    {
      value: 'ends_with',
      label: 'ends with one of',
    },
    {
      value: 'any',
      label: 'has any value',
    },
    {
      value: 'in',
      label: 'is one of',
    },
    {
      value: 'not_in',
      label: 'is not one of',
    },
  ],
  phone: [
    {
      value: 'eq',
      label: 'is',
    },
    {
      value: 'neq',
      label: 'is not',
    },
    {
      value: 'empty',
      label: 'is empty',
    },
    {
      value: 'nempty',
      label: 'is not empty',
    },
    {
      value: 'contains',
      label: 'contains one of',
    },
    {
      value: 'ncontains',
      label: 'does not contain one of',
    },
    {
      value: 'starts_with',
      label: 'starts with one of',
    },
    {
      value: 'nstarts_with',
      label: 'does not start with one of',
    },
    {
      value: 'ends_with',
      label: 'ends with one of',
    },
    {
      value: 'any',
      label: 'has any value',
    },
    {
      value: 'in',
      label: 'is one of',
    },
    {
      value: 'not_in',
      label: 'is not one of',
    },
  ],
};

export const getFieldsToDesignations = (fields: DynamicLabels): {...} => {
  const fieldsToDesignations = {};
  fields.forEach(
    (field) => (fieldsToDesignations[field.value] = field.designations),
  );
  return Object.freeze(fieldsToDesignations);
};
export const getIsZipcodeField = (
  field: string,
  fields: DynamicLabels,
): boolean => {
  const fieldsToDesignations = getFieldsToDesignations(fields);
  //its a zipcode field if the designation contains zipcode
  return (fieldsToDesignations[field] ?? []).includes('ZIPCODE');
};
export const insertExtraZipcodeOperator = (
  operators: {label: string, value: string}[],
): {label: string, value: string}[] =>
  operators.reduce((acc, operator) => {
    if (operator.value === 'in') {
      return [
        ...acc,
        operator,
        //NOTE: there is no in_radius operator on backend. its just a ui construct.
        {label: 'is within radius of', value: 'in_radius'},
      ];
    }
    return [...acc, operator];
  }, []);

export const ARRAY_OPERATORS = [
  'in',
  'not_in',
  'contains',
  'ncontains',
  'starts_with',
  'nstarts_with',
  'ends_with',
];
export const SINGLE_VALUE_OPERATORS = ['eq', 'neq', 'gt', 'lt'];
export const ALLOWS_EMPTY_VALUE_OPERATORS = ['in'];
export const VALUE_NOT_REQUIRED_OPERATORS = ['empty', 'nempty', 'any'];

export const DATE_FILTER_OPTIONS = [
  {
    value: 'today',
    label: 'Today',
  },
  {
    value: 'specific_date',
    label: 'Specific Date',
  },
  {
    value: 'relative_date',
    label: 'Relative to',
  },
];

export const RELATIVE_DAYS_OPERATOR_OPTIONS = [
  {
    value: 'gt',
    label: 'is greater than',
  },
  {
    value: 'lt',
    label: 'is less than',
  },
];

export const RELATIVE_DAYS_BEFORE_OR_AFTER = [
  {
    value: 'before',
    label: 'before',
  },
  {
    value: 'after',
    label: 'after',
  },
];

export const RELATIVE_DAYS_MINUS_OR_PLUS = [
  {
    value: 'minus',
    label: 'Minus (-)',
  },
  {
    value: 'plus',
    label: 'Plus (+)',
  },
];

export const isOperatorSwitchIncompatible = (
  newOperator: string,
  oldOperator: string,
): boolean => {
  if (newOperator === oldOperator) {
    return false;
  }
  const nonArrayOperators = ['eq', 'neq', 'empty', 'nempty', 'any'];
  const isNewOperatorNonArrayOperator = nonArrayOperators.includes(newOperator);
  const isOldOperatorNonArrayOperator = nonArrayOperators.includes(oldOperator);

  return isNewOperatorNonArrayOperator !== isOldOperatorNonArrayOperator;
};

export const isAudienceBuilding = (status: AudienceListStatus): boolean =>
  ['published', 'draft', 'building'].includes(status);

export const canArchiveList = (status: AudienceListStatus): boolean =>
  ['published', 'draft'].includes(status);

export const ruleValueToStringRuleValue = (
  value: ?number | number[] | string | string[],
): string[] => {
  if (!value) {
    return [];
  }
  if (value != null) {
    if (Array.isArray(value)) {
      return value.map((v) => v.toString());
    }
    return [value.toString()];
  }
  return [];
};
export const ruleValueToNumberRuleValue = (
  value: ?number | number[] | string | string[],
): string => {
  if (!value) {
    return '';
  }
  if (value != null) {
    if (Array.isArray(value)) {
      return '';
    }
    return '' + value;
  }
  return '';
};
export const ruleValueToDateRuleValue = (
  value: ?(number | number[] | string | string[]),
): string => {
  if (!value) {
    return 'today';
  }
  if (value != null) {
    if (Array.isArray(value)) {
      return 'today';
    }
    return '' + value;
  }
  return 'today';
};

export const getFieldsToTypes = (
  rules: FilterRule[],
  fields: DynamicLabels,
): {[string]: string} =>
  rules.reduce((acc, rule) => {
    //TODO:(diwakersurya) optimize here if required
    const fieldObj = fields.find((field) => field.value === rule.field);
    const dataType = fieldObj?.type || 'string';
    acc[rule.field] = dataType;
    return acc;
  }, {});

export const normalizeFilterRule = (
  rules: FilterRule[],
  all: boolean,
  fieldsToTypes: {[string]: string},
): {...} => {
  const apiAudienceFilter = {};
  if (all) {
    apiAudienceFilter.all = true;
  } else if (rules.length) {
    apiAudienceFilter.and = rules
      .filter((rule) => rule.field)
      .map((filterRule) => {
        // remove temp. ids added for editing
        const rule = omit(filterRule, 'id');
        const apiRule = {};
        const fieldType = fieldsToTypes[rule.field];
        let {operator} = rule;
        let metadata = null;
        //in_radius operator is just a ui construct and is represented by in
        //operator on backend
        if (operator === 'in_radius') {
          operator = 'in';
          metadata = zipcodeMeta(rule);
        }
        // (diwakersurya) ENGAGE-6611 these fields will be skipped from rule
        // and will be send on metadata key if required.see parsers.normalize.zipcodeMeta
        const zipcodeSkipFields = [
          'source',
          'radius',
          'locationOperator',
          'type',
        ];

        if (fieldType === 'date' && rule.allowEmpty) {
          // (rng) - This is a temporary hack to support a (date >= today OR date is null) type rule for WinterWyman
          // until we add a proper OR ui to the rules page
          apiRule.or = [];
          const primaryRule = {};
          primaryRule[operator] = omit(
            rule,
            'operator',
            'types',
            'allowEmpty',
            'offset',
            ...zipcodeSkipFields,
          );
          if (rule.offset != null) {
            primaryRule[operator].offset = rule.offset;
          }
          apiRule.or.push(primaryRule);
          apiRule.or.push({eq: {field: rule.field, value: null}});
        } else if (['empty', 'nempty'].includes(operator)) {
          const subRule = {field: rule.field, value: null};
          if (operator === 'empty') {
            apiRule.eq = subRule;
          } else {
            apiRule.neq = subRule;
          }
        } else if ([true, false].includes(operator)) {
          //boolean rule
          const subRule = {field: rule.field, value: operator};
          apiRule.eq = subRule;
        } else {
          apiRule[operator] = omit(
            rule,
            'operator',
            'types',
            'allowEmpty',
            'offset', //removed the offset because it should be sent only if its value is !null
            ...zipcodeSkipFields,
          );
          if (operator === 'any') {
            apiRule[operator] = omit(apiRule[operator], 'value');
          }
          if (['number', 'currency'].includes(fieldType)) {
            apiRule[operator].value = parseFloat(rule.value);
          }

          if (metadata) {
            apiRule[operator] = {
              ...apiRule[operator],
              metadata,
            };
          }
          if (rule.allowEmpty && apiRule.in) {
            // TODO (kyle): i'd rather this were null and not ''
            apiRule.in.value = [...apiRule.in.value, null];
          }
          //add offset now if it is not null. offset field is only available for date type
          //with relative operator
          if (rule.offset != null) {
            apiRule[operator].offset = rule.offset;
          }
          //if operator is in and value is non-array, make it array
          if (apiRule.in) {
            if (apiRule.in.value != null && !Array.isArray(apiRule.in.value)) {
              apiRule.in.value = [apiRule.in.value];
            }
          }
        }
        return apiRule;
      });
  }
  return apiAudienceFilter;
};

const zipcodeMeta = (
  rule,
): {type: string, operator: string, source: string, radius: number} => ({
  type: 'distance',
  operator: 'search',
  source: rule.source,
  radius: rule.radius,
});

export const parseFilterRule = (
  json: mixed,
): {all: boolean, rules: FilterRule[]} => {
  let rules = [];
  let all = false;
  //$FlowFixMe
  if (json.all) {
    all = true;
  }
  //$FlowFixMe
  else if (Array.isArray(json.and)) {
    rules = json.and.map((rule) => {
      const operator = Object.keys(rule)[0];
      const ruleId = uniqueId('filterRule');
      if (operator === 'or') {
        // Special case of empty value handling for Date rules
        // TODO (rng) - DEPRECATE ONCE WE ADD PROPER 'or' SUPPORT IN THE UI
        const primaryRule = rule.or[0];
        const emptyRule = rule.or[1];
        const primaryRuleOperator = Object.keys(primaryRule)[0];
        const parsedRule = {...primaryRule[primaryRuleOperator]};
        parsedRule.operator = primaryRuleOperator;
        if (Object.keys(emptyRule)[0] === 'eq' && emptyRule.eq.value === null) {
          parsedRule.allowEmpty = true;
        } else {
          parsedRule.allowEmpty = false;
        }
        return {...parsedRule, id: ruleId};
      } else if (operator === 'in') {
        const {
          in: {value},
        } = rule;
        const newValue = value.filter((string) => string);
        const allowEmpty = newValue.length !== value.length;
        //(diwakersurya) ENGAGE-6611
        const metadata = rule[operator].metadata;
        return {
          ...omit(rule[operator], 'metadata'), //(diwakersurya) ENGAGE-6611
          value: newValue,
          // 'in_radius' is just a ui operator. on backend, its just 'in' operator
          //with metadata:{operator:'search'} in rule field
          operator: metadata?.operator === 'search' ? 'in_radius' : operator,
          allowEmpty,
          id: ruleId,
          ...(metadata?.operator === 'search' && parseZipcodeMeta(metadata)), //(diwakersurya) ENGAGE-6611
        };
      } else if (
        ['eq', 'neq'].includes(operator) &&
        isNil(rule[operator].value)
      ) {
        const newOperator = operator === 'eq' ? 'empty' : 'nempty';
        return {...rule[operator], operator: newOperator, id: ruleId};
      } else if (
        ['contains', 'ncontains'].includes(operator) &&
        !Array.isArray(rule[operator].value)
      ) {
        // See #5734, ui previously allowed strings only, but now
        // supports either strings or arrays
        const valueAsArray = [rule[operator].value];
        return {
          ...rule[operator],
          operator,
          value: valueAsArray,
          id: ruleId,
        };
      } else if (
        operator === 'eq' &&
        [true, false].includes(rule[operator].value)
      ) {
        //boolean rule
        const field = rule[operator].field;
        return {
          field,
          id: ruleId,
          operator: rule[operator].value,
        };
      } else {
        return {...rule[operator], operator, id: ruleId};
      }
    });
  }
  return {all, rules};
};

const parseZipcodeMeta = (json: {
  source: string,
  radius: number,
  locationOperator: string,
  type: 'distance',
  operator: string,
}): {
  source: string,
  radius: number,
  locationOperator: string,
  type: 'distance',
  operator: string,
} => ({...omit(json, 'operator'), locationOperator: json.operator});

export const DEFAULT_RENDER_CONFIG = {
  'review.confirm.button.label': 'Publish',
  header: 'Create New List',
  disableEntityTypeSelection: false,
  hideSaveAsDraft: false,
};

export const overrideRenderConfig = (
  newDisplayConfig: $Shape<typeof DEFAULT_RENDER_CONFIG>,
): $Shape<typeof DEFAULT_RENDER_CONFIG> => ({
  ...DEFAULT_RENDER_CONFIG,
  ...newDisplayConfig,
});

const getWorkflowTypeAssociatedResources = (
  associatedResources: AssociatedResources,
): AssociatedResources =>
  associatedResources.filter(
    (resource) => resource.resource_type === 'workflow',
  );

export const getAssociatedJourneys = (
  associatedResources: AssociatedResources,
  additionalFilter?: (AssociatedResource) => boolean,
): AssociatedResources =>
  additionalFilter
    ? getWorkflowTypeAssociatedResources(associatedResources).filter(
        additionalFilter,
      )
    : getWorkflowTypeAssociatedResources(associatedResources);

export const getActiveAssociatedJourneys = (
  associatedResources: AssociatedResources,
  workflows: {[string]: Workflow},
): AssociatedResources =>
  getAssociatedJourneys(
    associatedResources,
    ({resource_id}) => workflows[resource_id].active,
  );

export const getInactiveAssociatedJourneys = (
  associatedResources: AssociatedResources,
  workflows: {[string]: Workflow},
): AssociatedResources =>
  getAssociatedJourneys(
    associatedResources,
    ({resource_id}) => !workflows[resource_id].active,
  );
