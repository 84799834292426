// @noflow

import keys from 'lodash/keys';
import map from 'lodash/map';

import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
import uniqueId from 'lodash/uniqueId';
import {defined, camel, snake, deprecate} from 'src/utils/index';

import type {
  ApiAudienceFilter,
  AudienceFilter,
} from 'src/types/audience-filter';
import type {ApiBrandingSettings, BrandingSettings} from './events';
import type {
  EntityMetadataApi,
  EntityMetadataObject,
} from 'src/types/ats-entities';
import keyBy from 'lodash/keyBy';

/**
 * Parsers
 */
const parsers = {
  normalize: {
    account: (account: NewAccount): ApiNewAccount =>
      defined({
        email: account.email,
        password: account.password,
        fullname: account.fullname,
        invite_code: account.inviteCode,
      }),

    agency: (agency: Agency): ApiAgency => ({
      name: agency.name,
      branding_settings: parsers.normalize.brandingSettings(
        agency.brandingSettings,
      ),
      allow_sms: agency.allowSms,
      timezone: agency.timezone,
    }),
    createWorkflow: (workflow: NewWorkflow): ApiNewWorkflow => ({
      name: workflow.name,
      branding_settings: {},
      shared_security_groups: (workflow.sharedSecurityGroups || []).map(
        ({id}) => ({id}),
      ),
      entity_type: workflow.entityType,
      events_schedule: workflow.eventsSchedule || [],
      db_cleanup: workflow.dbCleanup,
      archived: workflow.archived,
    }),

    copyWorkflow: (workflow: CopyWorkflow): ApiCopyWorkflow => ({
      name: workflow.name,
      copy_audience_filter: workflow.copyAudienceFilter,
      copy_events: workflow.copyEvents,
      db_cleanup: workflow.dbCleanup ?? false,
      shared_security_groups: (workflow.sharedSecurityGroups || []).map(
        ({id}) => ({id}),
      ),
    }),

    workflow: (workflow: Workflow): ApiUpdateWorkflow => ({
      branding_settings: parsers.normalize.brandingSettings(
        workflow.brandingSettings,
      ),
      name: workflow.name,
      events_schedule: workflow.eventsSchedule.map(
        parsers.normalize.eventsSchedule,
      ), //event.questions.map(parsers.normalize.question),
      shared_security_groups: (workflow.sharedSecurityGroups || []).map(
        ({id}) => ({id}),
      ),
      db_cleanup: workflow.dbCleanup,
      archived: workflow.archived,
    }),

    // Yes, this is a dupe of the above normalizer. If we need to, we can factor
    // in the changes, but a better approach will probably be to fix schedules generally.
    multiEntityWorkflow: (workflow: Workflow): ApiUpdateWorkflow => ({
      name: workflow.name,
      events_schedule: workflow.eventsSchedule.map(
        parsers.normalize.multiEntityEventsSchedule,
      ),
      shared_security_groups: (workflow.sharedSecurityGroups || []).map(
        ({id}) => ({id}),
      ),
      archived: workflow.archived,
      business_goal: workflow.businessGoal,
      brand_id: workflow.brandId,
    }),

    eventsSchedule: (eventSchedule: EventsSchedule): ApiEventsSchedule => {
      const apiEventsSchedule: ApiEventsSchedule = {
        id: eventSchedule.id,
      };
      const schedule = eventSchedule.schedule;
      let apiSchedule: ApiSchedule;
      if (schedule) {
        apiSchedule = {};
        if (schedule.sendDate) {
          apiSchedule['send_date'] = {
            date: schedule.sendDate.date,
            time: schedule.sendDate.time,
            send_per_placement: schedule.sendDate.send_per_placement,
          };
          apiSchedule['display_timezone'] = schedule.displayTimezone;
        } else if (schedule.repeat) {
          apiSchedule['repeat'] = {
            rule: schedule.repeat.rule,
            send_per_placement: schedule.repeat.send_per_placement,
          };
          if (schedule.repeat.relativeStartDate) {
            apiSchedule['repeat']['relative_start_date'] =
              schedule.repeat.relativeStartDate;
            apiSchedule['repeat']['time'] = schedule.repeat.time;
          } else {
            //This is the default Calendar Date
            apiSchedule['repeat']['start_date'] = schedule.repeat.startDate;
            apiSchedule['repeat']['time'] = schedule.repeat.time;
          }

          if (schedule.repeat.untilDate) {
            apiSchedule['repeat']['until_date'] = schedule.repeat.untilDate;
          }

          if (schedule.repeat.maxInstances) {
            apiSchedule['repeat']['max_instances'] =
              schedule.repeat.maxInstances;
          }

          if (schedule.repeat.frequencyInterval) {
            apiSchedule['repeat']['frequency_interval'] =
              schedule.repeat.frequencyInterval;
          }

          if (schedule.repeat.firstInstance) {
            apiSchedule['repeat']['first_instance'] =
              schedule.repeat.firstInstance;
          }
        } else if (schedule.dayOfWeek) {
          apiSchedule['day_of_week'] = schedule.dayOfWeek;
          apiSchedule['display_timezone'] = schedule.displayTimezone;
        } else if (schedule.fromDate) {
          apiSchedule['from_date'] = {
            values: schedule.fromDate.values,
            field: schedule.fromDate.field,
            send_per_placement: schedule.fromDate.send_per_placement,
          };

          if (schedule.fromDate.from_time) {
            apiSchedule['from_date']['from_time'] = schedule.fromDate.from_time;
          }
        } else if (schedule.fieldChange) {
          apiSchedule['field_change'] = schedule.fieldChange;
          apiSchedule['display_timezone'] = schedule.displayTimezone;
        } else if (schedule.placementCreated) {
          apiSchedule['placement_created'] = schedule.placementCreated;
          apiSchedule['display_timezone'] = schedule.displayTimezone;
        } else if (schedule.audienceMemberCreated) {
          apiSchedule['audience_member_created'] =
            schedule.audienceMemberCreated;
          apiSchedule['display_timezone'] = schedule.displayTimezone;
        } else if (schedule.fromStartDate) {
          apiSchedule['from_start_date'] = schedule.fromStartDate;
          apiSchedule['display_timezone'] = schedule.displayTimezone;
        } else if (schedule.fromDateAtsTrigger) {
          apiSchedule['from_date_ats_trigger'] = schedule.fromDateAtsTrigger;
        } else if (schedule.fieldChangeAtsTrigger) {
          apiSchedule['field_change_ats_trigger'] =
            schedule.fieldChangeAtsTrigger;
        } else if (schedule.campaignDateRange) {
          const {
            startDate,
            startTime,
            endTime,
            countPerDay,
            shouldEndEarly,
            endDate,
            skipWeekend,
          } = schedule.campaignDateRange;
          apiSchedule['campaign_date_range'] = {
            start_date: startDate,
            start_time: startTime,
            end_time: endTime,
            count_per_day: countPerDay,
          };

          if (endDate) {
            apiSchedule['campaign_date_range']['end_date'] = endDate;
          }

          if (skipWeekend) {
            apiSchedule['campaign_date_range']['skip_weekend'] = skipWeekend;
          }
        }

        if (schedule.gracePeriod) {
          apiSchedule['grace_period'] = schedule.gracePeriod;
        }

        if (schedule.ifWeekend) {
          apiSchedule['if_weekend'] = schedule.ifWeekend;
        }

        if (schedule.reminders) {
          // Right now, the API and client formats are the same for reminders
          apiSchedule.reminders = schedule.reminders;
        }

        if (schedule.skipIf) {
          apiSchedule.skip_if = snake(schedule.skipIf);
        }
        if (schedule.blackout_window) {
          apiSchedule.blackout_window = schedule.blackout_window;
        }
      }

      apiEventsSchedule['schedule'] = apiSchedule;
      return apiEventsSchedule;
    },

    multiEntityEventsSchedule: (
      eventSchedule: EventsSchedule,
    ): ApiEventsSchedule => {
      const apiEventsSchedule: ApiEventsSchedule = {
        id: eventSchedule.id,
      };
      const schedule = eventSchedule.schedule;
      let apiSchedule: ApiSchedule;
      if (schedule) {
        apiSchedule = {};
        if (schedule.reminders) {
          // Right now, the API and client formats are the same for reminders
          apiSchedule.reminders = schedule.reminders;
        }
        if (schedule.sendDate) {
          apiSchedule['send_date'] = {
            date: schedule.sendDate.date,
            time: schedule.sendDate.time,
            send_per_placement: undefined,
          };
          apiSchedule['display_timezone'] = schedule.displayTimezone;
        } else if (schedule.repeat) {
          apiSchedule['repeat'] = {
            rule: schedule.repeat.rule,
            send_per_placement: undefined,
          };
          if (schedule.repeat.relativeStartDate) {
            apiSchedule['repeat']['relative_start_date'] =
              schedule.repeat.relativeStartDate;
            apiSchedule['repeat']['time'] = schedule.repeat.time;
          } else {
            //This is the default Calendar Date
            apiSchedule['repeat']['start_date'] = schedule.repeat.startDate;
            apiSchedule['repeat']['time'] = schedule.repeat.time;
          }

          if (schedule.repeat.untilDate) {
            apiSchedule['repeat']['until_date'] = schedule.repeat.untilDate;
          }

          if (schedule.repeat.maxInstances) {
            apiSchedule['repeat']['max_instances'] =
              schedule.repeat.maxInstances;
          }

          if (schedule.repeat.frequencyInterval) {
            apiSchedule['repeat']['frequency_interval'] =
              schedule.repeat.frequencyInterval;
          }

          if (schedule.repeat.firstInstance) {
            apiSchedule['repeat']['first_instance'] =
              schedule.repeat.firstInstance;
          }
        } else if (schedule.dayOfWeek) {
          apiSchedule['day_of_week'] = {
            ...schedule.dayOfWeek,
            send_per_placement: undefined,
          };
          apiSchedule['display_timezone'] = schedule.displayTimezone;
        } else if (schedule.fromDate) {
          apiSchedule['from_date'] = {
            values: schedule.fromDate.values,
            field: schedule.fromDate.field,
            send_per_placement: undefined,
          };

          if (schedule.fromDate.from_time) {
            apiSchedule['from_date']['from_time'] = schedule.fromDate.from_time;
          }
        } else if (schedule.fieldChange) {
          apiSchedule['field_change'] = {
            ...schedule.fieldChange,
            send_per_placement: undefined,
          };
          apiSchedule['display_timezone'] = schedule.displayTimezone;
        } else if (schedule.placementCreated) {
          apiSchedule['placement_created'] = {
            ...schedule.placementCreated,
            send_per_placement: undefined,
          };
          apiSchedule['display_timezone'] = schedule.displayTimezone;
        } else if (schedule.audienceMemberCreated) {
          apiSchedule['audience_member_created'] = {
            ...schedule.audienceMemberCreated,
            send_per_placement: undefined,
          };
          apiSchedule['display_timezone'] = schedule.displayTimezone;
        } else if (schedule.fromStartDate) {
          apiSchedule['from_start_date'] = {
            ...schedule.fromStartDate,
            send_per_placement: undefined,
          };
          apiSchedule['display_timezone'] = schedule.displayTimezone;
        } else if (schedule.fromDateAtsTrigger) {
          apiSchedule['from_date_ats_trigger'] = {
            ...schedule.fromDateAtsTrigger,
            send_per_placement: undefined,
          };
        } else if (schedule.fieldChangeAtsTrigger) {
          apiSchedule['field_change_ats_trigger'] = {
            ...schedule.fieldChangeAtsTrigger,
            send_per_placement: undefined,
          };
        } else if (schedule.campaignDateRange) {
          const {
            startDate,
            startTime,
            endTime,
            countPerDay,
            endDate,
            skipWeekend,
          } = schedule.campaignDateRange;
          apiSchedule['campaign_date_range'] = {
            start_date: startDate,
            start_time: startTime,
            end_time: endTime,
            count_per_day: countPerDay,
          };

          if (endDate) {
            apiSchedule['campaign_date_range']['end_date'] = endDate;
          }

          if (skipWeekend) {
            apiSchedule['campaign_date_range']['skip_weekend'] = skipWeekend;
          }
        }

        if (schedule.gracePeriod) {
          apiSchedule['grace_period'] = schedule.gracePeriod;
        }

        if (schedule.ifWeekend) {
          apiSchedule['if_weekend'] = schedule.ifWeekend;
        }

        if (schedule.skipIf) {
          apiSchedule.skip_if = snake(schedule.skipIf);
        }
        if (schedule.blackout_window) {
          apiSchedule.blackout_window = schedule.blackout_window;
        }
      }

      apiEventsSchedule['schedule'] = apiSchedule;
      return apiEventsSchedule;
    },

    createAudienceMember: (
      audienceMember: AudienceMember,
    ): ApiNewAudienceMember => ({
      first_name: audienceMember.firstName,
      last_name: audienceMember.lastName,
      full_name: audienceMember.fullName,
      email: audienceMember.email,
      tags: defined(audienceMember.tags),
    }),

    audienceMember: (audienceMember: AudienceMember): ApiNewAudienceMember => ({
      first_name: audienceMember.firstName,
      last_name: audienceMember.lastName,
      full_name: audienceMember.fullName,
      email: audienceMember.email,
      opt_out_sms: audienceMember.optOutSms,
      opt_out_global: audienceMember.optOutGlobal,
      help_text_sent: audienceMember.helpTextSent,
      tags: defined(audienceMember.tags),
    }),

    // audienceFilter has a dependency on getAudienceMemberFields to put the store.audienceMembers.getFieldsToTypes()
    audienceFilter: (
      audienceFieldsToTypes: {[key: string]: string},
      audienceFilter: AudienceFilter,
    ): ApiAudienceFilter => {
      const apiAudienceFilter = {};
      if (audienceFilter.all) {
        apiAudienceFilter.all = true;
      } else if (audienceFilter.and && audienceFilter.and.length) {
        apiAudienceFilter.and = audienceFilter.and
          .filter((rule) => rule.field)
          .map((filterRule) => {
            // remove temp. ids added for editing
            const rule = omit(filterRule, 'id');
            const apiRule = {};
            const fieldType = audienceFieldsToTypes[rule.field];
            const {operator} = rule;
            // (diwakersurya) ENGAGE-6611 these fields will be skipped from rule
            // and will be send on metadata key if required.see parsers.normalize.zipcodeMeta
            const zipcodeSkipFields = [
              'source',
              'radius',
              'locationOperator',
              'type',
            ];

            if (fieldType === 'date' && rule.allowEmpty) {
              // (rng) - This is a temporary hack to support a (date >= today OR date is null) type rule for WinterWyman
              // until we add a proper OR ui to the rules page
              apiRule.or = [];
              const primaryRule = {};
              primaryRule[operator] = omit(
                rule,
                'operator',
                'types',
                'allowEmpty',
                ...zipcodeSkipFields,
              );
              apiRule.or.push(primaryRule);
              apiRule.or.push({eq: {field: rule.field, value: null}});
            } else if (['empty', 'nempty'].includes(operator)) {
              const subRule = {field: rule.field, value: null};
              if (operator === 'empty') {
                apiRule.eq = subRule;
              } else {
                apiRule.neq = subRule;
              }
            } else {
              apiRule[operator] = omit(
                rule,
                'operator',
                'types',
                'allowEmpty',
                ...zipcodeSkipFields,
              );
              if (operator === 'any') {
                apiRule[operator] = omit(apiRule[operator], 'value');
              }
              if (['number', 'currency'].includes(fieldType)) {
                apiRule[operator].value = parseFloat(rule.value);
              }
              //(diwakersurya) ENGAGE-6611
              //if rule contains location operator, we treat it as a zipcode rule
              if (operator === 'in' && rule.locationOperator != null) {
                apiRule[operator] = {
                  ...apiRule[operator],
                  metadata: parsers.normalize.zipcodeMeta(rule),
                };
              }
              if (rule.allowEmpty && apiRule.in) {
                // TODO (kyle): i'd rather this were null and not ''
                apiRule.in.value = [...apiRule.in.value, null];
              }
            }
            return apiRule;
          });
      }
      return apiAudienceFilter;
    },
    zipcodeMeta: (
      rule,
    ): {type: string, operator: string, source: string, radius: number} => ({
      type: 'distance',
      operator: rule.locationOperator,
      source: rule.source,
      radius: rule.radius,
    }),

    brandingSettings: (
      brandingSettings: ?BrandingSettings,
    ): ApiBrandingSettings =>
      // Null out empty values so they can be deleted on server
      defined(
        snake(
          mapValues(brandingSettings, (value) =>
            value?.trim ? value?.trim() || null : value,
          ),
        ),
      ),
    atsCredentials: (credentials: AtsCredentials): ApiAtsCredentials =>
      snake(defined(credentials)),
  },

  parse: {
    auth: (json: Object): Object => ({
      agent: camel(json['agent']),
      agency: parsers.parse.agency(json['agency']),
    }),

    atsAuth: (json: Object): Object => ({
      agency: parsers.parse.agency(json['agency']),
    }),

    account: (json: ApiAccount): Account => ({
      agencyId: json['agency_id'],
      agentId: json['agent_id'],
      email: json['email'],
      username: json['username'],
    }),

    agency: (json: Object): Agency => camel(json),

    agencyOverview: (json: Object): AgencyOverview => ({
      queuedEvents: json['num_queued_events'],
      sentEvents: json['num_sent_events'],
      nps: camel(json['nps']),
      totalCandidates: json['total_candidates'],
      recentlyVisitedEvents: json['recently_visited_events'],
      //setupSteps: camel(json['setup_steps']),
    }),

    agencyOverviewAudienceMembers: (
      count: number,
    ): AgencyOverviewAudienceMembers => count,

    createAccount: (json: Object): Object => ({
      agent: camel(json['agent']),
      agency: parsers.parse.agency(json['agency']),
    }),

    workflow: (json: ApiWorkflow): Workflow => ({
      id: json['id'],
      name: json['name'],
      brandingSettings: parsers.parse.brandingSettings(
        json['branding_settings'],
      ),
      eventsSchedule: json['events_schedule'].map(parsers.parse.eventsSchedule),
      active: Boolean(json['active']),
      events: json['events'],
      branchedEventIds: json['branched_events']
        ? map(json['branched_events'], (event) => event['id']).filter(
            (id) => !json['branched_events'][id]['deleted'],
          )
        : [],
      ownedSecurityGroup: json['owned_security_group'],
      sharedSecurityGroups: json['shared_security_groups'],
      inEditableGroup: json['in_editable_group'],
      backgroundActivationStatus: json['background_activation_status'],
      backgroundFilterStatus: json['background_filter_status'],
      entityType: json['entity_type'],
      dbCleanup: json['db_cleanup'],
      archived: json['archived'],
      activatedEver: json['activated_ever'],
      businessGoal: json['business_goal'],
      lifecycleGoal: json['life_cycle_goal'],
      target: json['target'],
      description: json['description'],
      draft: json['draft'],
      workflow_category: json['workflow_category'],
      events_summary: json['events_summary'],
      time_created: json['time_created'],
      brandId: json['brand_id'],
      lleTaskStatus: json['lle_task_status'],
    }),

    workflows: (json: ApiWorkflow[]): Workflow[] =>
      json.map(parsers.parse.workflow),

    eventsSchedule: (json: Object): EventsSchedule => {
      const tempEventSchedule: EventsSchedule = {
        id: json['id'],
      };
      const schedule = json['schedule'];
      if (schedule) {
        tempEventSchedule['schedule'] = {
          sendDate: schedule.send_date,
          repeat: schedule.repeat
            ? {
                startDate: schedule.repeat.start_date,
                rule: schedule.repeat.rule,
                time: schedule.repeat.time,
                frequencyInterval: schedule.repeat.frequency_interval,
                firstInstance: schedule.repeat.first_instance,
                relativeStartDate: schedule.repeat.relative_start_date,
                untilDate: schedule.repeat.until_date,
                maxInstances: schedule.repeat.max_instances,
                send_per_placement: schedule.repeat.send_per_placement,
              }
            : null,
          fromStartDate: schedule.from_start_date,
          fromDate: schedule.from_date,
          dayOfWeek: schedule.day_of_week,
          fieldChange: schedule.field_change,
          placementCreated: schedule.placement_created,
          audienceMemberCreated: schedule.audience_member_created,
          skipIf: camel(schedule.skip_if),
          reminders: schedule.reminders,
          displayTimezone: schedule.display_timezone,
          gracePeriod: schedule.grace_period,
          fromDateAtsTrigger: schedule.from_date_ats_trigger,
          fieldChangeAtsTrigger: schedule.field_change_ats_trigger,
          campaignDateRange: camel(schedule.campaign_date_range),
          ifWeekend: schedule.if_weekend,
          blackout_window: schedule.blackout_window ?? null,
        };
      }
      return tempEventSchedule;
    },

    audienceMemberPlacement: (json: Object): AudienceMemberPlacement =>
      camel(json, false),

    audienceMember: (json: Object): AudienceMember => {
      deprecate({
        reason:
          'audienceMember should be parsed as `camel(val, false)` instead',
        person: 'marcos',
      });
      return camel(json, false);
    },

    audienceMembers: (json: Object[]): AudienceMember[] =>
      json.map(parsers.parse.audienceMember),

    audienceMembersPage: (json: Object): AudienceMembersPage => {
      const memberList = camel(json['member_list'], false);
      return {
        memberList,
        page: parseInt(json['page'], 10),
        numPages: parseInt(json['num_pages'], 10),
        workflowId: json['workflow_id'],
        numTotalMembers: json['num_total_members'],
      };
    },

    audienceFilter: (json: Object): AudienceFilter => {
      if (json.all) {
        // return as is
      } else if (json.and) {
        json.and = json.and.map((rule) => {
          const operator = keys(rule)[0];
          const ruleId = uniqueId('filterRule');
          if (operator === 'or') {
            // Special case of empty value handling for Date rules
            // TODO (rng) - DEPRECATE ONCE WE ADD PROPER 'or' SUPPORT IN THE UI
            const primaryRule = rule.or[0];
            const emptyRule = rule.or[1];
            const primaryRuleOperator = keys(primaryRule)[0];
            const parsedRule = {...primaryRule[primaryRuleOperator]};
            parsedRule.operator = primaryRuleOperator;
            if (keys(emptyRule)[0] === 'eq' && emptyRule.eq.value === null) {
              parsedRule.allowEmpty = true;
            } else {
              parsedRule.allowEmpty = false;
            }
            return {...parsedRule, id: ruleId};
          } else if (operator === 'in') {
            const {
              in: {value},
            } = rule;
            const newValue = value.filter((string) => string);
            const allowEmpty = newValue.length !== value.length;
            //(diwakersurya) ENGAGE-6611
            const metadata = rule[operator].metadata;
            return {
              ...omit(rule[operator], 'metadata'), //(diwakersurya) ENGAGE-6611
              value: newValue,
              operator,
              allowEmpty,
              id: ruleId,
              ...(metadata && parsers.parse.zipcodeMeta(metadata)), //(diwakersurya) ENGAGE-6611
            };
          } else if (
            ['eq', 'neq'].includes(operator) &&
            isNil(rule[operator].value)
          ) {
            const newOperator = operator === 'eq' ? 'empty' : 'nempty';
            return {...rule[operator], operator: newOperator, id: ruleId};
          } else if (
            ['contains', 'ncontains'].includes(operator) &&
            !Array.isArray(rule[operator].value)
          ) {
            // See #5734, ui previously allowed strings only, but now
            // supports either strings or arrays
            const valueAsArray = [rule[operator].value];
            return {
              ...rule[operator],
              operator,
              value: valueAsArray,
              id: ruleId,
            };
          } else {
            return {...rule[operator], operator, id: ruleId};
          }
        });
      } else {
        json.and = [];
      }
      return json;
    },
    zipcodeMeta: (json: {
      source: string,
      radius: number,
      locationOperator: string,
      type: 'distance',
    }): {
      source: string,
      radius: number,
      locationOperator: string,
      type: 'distance',
    } => ({...omit(json, 'operator'), locationOperator: json.operator}),
    brandingSettings: (json: ?ApiBrandingSettings): BrandingSettings =>
      camel(json),
    entityMetadata: (
      json: EntityMetadataApi,
    ): {[string]: EntityMetadataObject} => {
      return keyBy(json, 'entityType');
    },
  },
};

export default parsers;
