// @flow strict
//$FlowFixMe
import type {Dispatch, ThunkAction} from 'src/reducers';
//$FlowFixMe
import * as reduxApi from 'src/utils/redux-api-v2';

// $FlowFixMe
import type {EmailValidationResult} from 'src/action-creators/email-dns';


export type EmailDomain = {
  domain: string,
  dns: {[name: RecordName]: EmailDNSRecord},
};

export type EmailDNSRecord = {
  host: string,
  type: string,
  data: string,
  valid: boolean,
};

type RecordName =
  | 'dkim1'
  | 'dkim2'
  | 'mail_cname'
  | 'domain_cname'
  | 'owner_cname';

export const addDomain =
  (domain: string): ThunkAction<EmailDomain> =>
  (dispatch: Dispatch) =>
    dispatch(reduxApi.post(`dns-v2/domains`, {domain}));

export const validateDomain =
  (domain: string): ThunkAction<EmailValidationResult> =>
  (dispatch: Dispatch) =>
    dispatch(reduxApi.post(`dns-v2/domains/${domain}/validate`));

export const deleteDomain =
  (domain: string): ThunkAction<string> =>
  (dispatch: Dispatch) =>
    dispatch(reduxApi.del(`dns-v2/domains/${domain}`));

export const sendDNSRecords = (): ThunkAction<mixed> => (dispatch: Dispatch) =>
  dispatch(reduxApi.post(`onboard/send-dns-email-settings`));
