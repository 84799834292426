// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {
  JobsQuery,
  JobPreviewQuery,
  JobPreviewData,
  MatchingCandidate,
} from 'src/types/job-preview';

// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {fetching, key} from 'src/utils/redux';


export const RECEIVE_PREVIEW_LIST = 'preview-list/receive';
export const PREVIEW_ID = 'preview-list/id';
export const RECEIVE_LIST_COUNT = 'preview-list/count';
export const REFETCH = 'preview-list/refetch';
export const SAVE_CANDIDATE_DETAIL = 'candidate/detail';
export const SAVE_SELECTED_CANDIDATE = 'candidate/selected';

type ReceivePreviewListAction = {
  type: 'preview-list/receive',
  payload: {jobs: JobPreviewData},
};
type PreviewIdAction = {
  type: 'preview-list/id',
  payload: {id: string},
};
type ReceiveListCountAction = {
  type: 'preview-list/count',
  payload: {total: number},
};
type RefetchAction = {
  type: 'preview-list/refetch',
};

type SaveCandidateDetail = {
  type: 'candidate/detail',
  payload: MatchingCandidate,
};

type SaveSelectedCandidate = {
  type: 'candidate/selected',
  payload: MatchingCandidate[],
};

export type JobPreviewActions =
  | ReceivePreviewListAction
  | PreviewIdAction
  | RefetchAction
  | ReceiveListCountAction
  | SaveCandidateDetail
  | SaveSelectedCandidate;

const receivePreviewList = (payload: {
  jobs: JobPreviewData,
}): ReceivePreviewListAction => ({
  type: 'preview-list/receive',
  payload,
});

const receiveListCount = (payload: {
  total: number,
}): ReceiveListCountAction => ({
  type: 'preview-list/count',
  payload,
});

const receivePreviewId = (payload: {id: string}): PreviewIdAction => ({
  type: 'preview-list/id',
  payload,
});

const saveCandidateDetail = (
  payload: MatchingCandidate,
): SaveCandidateDetail => ({
  type: 'candidate/detail',
  payload,
});

const saveSelectedCandidate = (
  payload: MatchingCandidate[],
): SaveSelectedCandidate => ({
  type: 'candidate/selected',
  payload,
});

export const refetchJobs = (): RefetchAction => ({
  type: 'preview-list/refetch',
});

export const getPreviewId: (query: JobsQuery) => ThunkAction<mixed> = flow(
  key((query) => `getPreviewId-${JSON.stringify(query)}`),
  fetching(),
)(
  (query) => (dispatch: Dispatch) =>
    dispatch(reduxApi.get('filter/search', query)).then((payload) => {
      dispatch(
        receivePreviewId({
          id: payload.filters[0]?.id ?? null,
        }),
      );
    }),
);

export const getPreviewList: (
  id: string,
  query: JobPreviewQuery,
) => ThunkAction<mixed> = flow(
  key((query) => `getPreviewList-${JSON.stringify(query)}`),
)(
  (id, query) => (dispatch: Dispatch) =>
    dispatch(reduxApi.get(`filter/${id}/preview`, query)).then((payload) => {
      dispatch(
        receivePreviewList({
          jobs: payload ?? {},
        }),
      );
    }),
);

export const getListCount: (
  id: string,
  query: JobPreviewQuery,
) => ThunkAction<mixed> = flow(
  key((query) => `getListCount-${JSON.stringify(query)}`),
)(
  (id, query) => (dispatch: Dispatch) =>
    dispatch(reduxApi.get(`filter/${id}/preview/count`, query)).then(
      (payload) => {
        dispatch(
          receiveListCount({
            total: payload.count ?? 0,
          }),
        );
      },
    ),
);

export const getPreviewListAndCount: (
  id: string,
  query: JobPreviewQuery,
) => ThunkAction<mixed> = flow(
  key((id, query) => `getPreviewListAndCount-${JSON.stringify(query)}`),
  fetching(),
)((id, query) => async (dispatch: Dispatch) => {
  await dispatch(getPreviewList(id, query));
  await dispatch(getListCount(id, query));
});

// wrapper of all three actions
export const getJobsPreviewData: (
  activeJobQuery: JobsQuery,
  previewListQuery: JobPreviewQuery,
) => ThunkAction<mixed> = flow(
  key(
    (activeJobQuery) => `getJobsPreviewData-${JSON.stringify(activeJobQuery)}`,
  ),
  fetching(),
)(
  (activeJobQuery, previewListQuery) => async (dispatch: Dispatch, getState) => {
    await dispatch(getPreviewId(activeJobQuery));
    const state = getState().jobPreview;
    const {id} = state;
    if (id) {
      await dispatch(getPreviewList(id, previewListQuery));
      await dispatch(getListCount(id, previewListQuery));
    }
  },
);

export const updateSaveCandidateDetail =
  (action: MatchingCandidate): ThunkAction<mixed> =>
  (dispatch: Dispatch) =>
    dispatch(saveCandidateDetail(action));

export const updateSelectedCandidates =
  (action: MatchingCandidate[]): ThunkAction<mixed> =>
  (dispatch: Dispatch) =>
    dispatch(saveSelectedCandidate(action));
