// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, GetState, ThunkAction} from 'src/reducers';
import type {
  Entity,
  KPIData,
  FilterByID,
  MetricsType,
  FiltersOptions,
  SelectedFilters,
  JourneyOverviewDataTable,
  SelectedFilterColumnsData,
  CurrentJourneyAndTouchpointBreadCrumbData,
  JourneyOverviewDataTableQuery,
  SavedDashboardFiltersList,
  SavedDashboardFiltersListQuery,
  SavedDashboardFiltersListData,
} from 'src/types/analytics-genesis';
// $FlowFixMe[nonstrict-import]
import type {ApiOptions, ApiPostOptions} from 'src/utils/redux-api';
import type {LineSeriesItem} from '@spaced-out/ui-design-system/lib/components/Charts';
import type {DateRangeWithTimezone} from '@spaced-out/ui-design-system/lib/types';

// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[nonstrict-import]
import {ANALYTICS_API_V3} from 'src/utils/analytics-api-migration';
// $FlowFixMe[untyped-import]
import {fetching, key} from 'src/utils/redux';
import {
  transformTableData,
  journeyTableColumnsQuery,
  touchpointTableColumnsQuery,
  peopleTableColumnsQuery,
} from 'src/utils/analytics-genesis';


export const SET_SELECTED_FILTERS =
  'analytics-journeys-overview/set-selected-filters';
export const SET_SELECTED_ENTITIES =
  'analytics-journeys-overview/set-selected-entities';
export const SET_SELECTED_JOURNEYS =
  'analytics-journeys-overview/set-selected-journeys';
export const SET_SELECTED_DATE_RANGE_AND_FREQUENCY =
  'analytics-journeys-overview/set-selected-date-range';
export const SET_SELECTED_METRICS_TYPE =
  'analytics-journeys-overview/set-selected-metrics-type';
export const SET_CHART_FREQUENCY =
  'analytics-journeys-overview/receive-chart-frequency';
export const RECEIVE_FILTERS_OPTIONS =
  'analytics-journeys-overview/receive-filters-options';
export const RECEIVE_SENT_FROM_OPTIONS =
  'analytics-journeys-overview/receive-sent-from-options';
export const RECEIVE_JOURNEY_OPTIONS =
  'analytics-journeys-overview/receive-journey-options';
export const RECEIVE_KPI_DATA =
  'analytics-journeys-overview/receive-kpi-metrics';
export const RECEIVE_JOURNEY_PLOT_DATA =
  'analytics-journeys-overview/receive-journey-plot-data';
export const RECEIVE_JOURNEY_OVERVIEW_TABLE =
  'analytics-journeys-overview/receive-journey-overview-table';
export const RECEIVE_TOUCHPOINT_OVERVIEW_TABLE =
  'analytics-journeys-overview/receive-touchpoint-overview-table';
export const RECEIVE_PEOPLE_OVERVIEW_TABLE =
  'analytics-journeys-overview/receive-people-overview-table';
export const RECEIVE_JOURNEY_TABLE_COLUMNS =
  'analytics-journeys-overview/receive-journey-table-columns';
export const RECEIVE_TOUCHPOINT_TABLE_COLUMNS =
  'analytics-journeys-overview/receive-touchpoint-table-columns';
export const RECEIVE_PEOPLE_TABLE_COLUMNS =
  'analytics-journeys-overview/receive-people-table-columns';
export const SET_CURRENT_JOURNEY_AND_TOUCHPOINT_DATA =
  'analytics-journeys-overview/set-current-journey-and-touchpoint-data';
export const RECEIVE_SAVED_DASHBOARDS =
  'analytics-journeys-overview/receive-saved-dashboards';
export const SET_CURRENT_DASHBOARD =
  'analytics-journeys-overview/set-current-dashboard';
export const CLEAR_SELECTED_FILTERS =
  'analytics-journeys-overview/clear-filters';
export const SET_LOADING_DASHBOARD_FILTERS =
  'analytics-journeys-overview/loading-dashboard-filters';

type SetSelectedFiltersAction = {
  type: typeof SET_SELECTED_FILTERS,
  payload: SelectedFilters,
};

type SetSelectedEntitiesAction = {
  type: typeof SET_SELECTED_ENTITIES,
  payload: Array<Entity>,
};

type SetSelectedJourneysAction = {
  type: typeof SET_SELECTED_JOURNEYS,
  payload: Array<FilterByID>,
};

type SetSelectedDateRangeAndFrequencyAction = {
  type: typeof SET_SELECTED_DATE_RANGE_AND_FREQUENCY,
  payload: {|
    ...DateRangeWithTimezone,
    chartFrequency: string,
  |},
};

type SetSelectedMetricsTypeAction = {
  type: typeof SET_SELECTED_METRICS_TYPE,
  payload: MetricsType,
};

type SetCurrentJourneyAndTouchpointDataAction = {
  type: typeof SET_CURRENT_JOURNEY_AND_TOUCHPOINT_DATA,
  payload: CurrentJourneyAndTouchpointBreadCrumbData,
};

type SetChartFrequencyAction = {
  type: typeof SET_CHART_FREQUENCY,
  payload: string,
};

type ReceiveFiltersOptionsAction = {
  type: typeof RECEIVE_FILTERS_OPTIONS,
  payload: FiltersOptions,
};

type ReceiveSentFromOptionsAction = {
  type: typeof RECEIVE_SENT_FROM_OPTIONS,
  payload: Array<string>,
};

type ReceiveJourneyOptionsAction = {
  type: typeof RECEIVE_JOURNEY_OPTIONS,
  payload: Array<FilterByID>,
};

type ReceiveKPIDataAction = {
  type: typeof RECEIVE_KPI_DATA,
  payload: KPIData | null,
};

type ReceiveJourneyPlotDataAction = {
  type: typeof RECEIVE_JOURNEY_PLOT_DATA,
  payload: Array<LineSeriesItem>,
};

type ReceiveJourneyOverviewAction = {
  type: typeof RECEIVE_JOURNEY_OVERVIEW_TABLE,
  payload: JourneyOverviewDataTable,
};

type ReceiveTouchpointOverviewAction = {
  type: typeof RECEIVE_TOUCHPOINT_OVERVIEW_TABLE,
  payload: JourneyOverviewDataTable,
};

type ReceivePeopleOverviewAction = {
  type: typeof RECEIVE_PEOPLE_OVERVIEW_TABLE,
  payload: JourneyOverviewDataTable,
};

type ReceiveJourneyTableColumnsAction = {
  type: typeof RECEIVE_JOURNEY_TABLE_COLUMNS,
  payload: SelectedFilterColumnsData,
};

type ReceiveTouchpointTableColumnsAction = {
  type: typeof RECEIVE_TOUCHPOINT_TABLE_COLUMNS,
  payload: SelectedFilterColumnsData,
};

type ReceivePeopleTableColumnsAction = {
  type: typeof RECEIVE_PEOPLE_TABLE_COLUMNS,
  payload: SelectedFilterColumnsData,
};

type ReceiveSavedDashboardsAction = {
  type: typeof RECEIVE_SAVED_DASHBOARDS,
  payload: {
    filters: SavedDashboardFiltersListData,
    dashboard: string,
    product: string,
  },
};

type SetCurrentDashboardAction = {
  type: typeof SET_CURRENT_DASHBOARD,
  payload: SavedDashboardFiltersList | null,
};

type ClearSelectedFiltersAction = {
  type: typeof CLEAR_SELECTED_FILTERS,
};

type SetLoadingDashboardFiltersAction = {
  type: typeof SET_LOADING_DASHBOARD_FILTERS,
  payload: boolean,
};

export type AnalyticsJourneysOverviewActions =
  | SetSelectedFiltersAction
  | SetSelectedEntitiesAction
  | SetSelectedJourneysAction
  | SetSelectedDateRangeAndFrequencyAction
  | SetSelectedMetricsTypeAction
  | SetChartFrequencyAction
  | ReceiveFiltersOptionsAction
  | ReceiveSentFromOptionsAction
  | ReceiveJourneyOptionsAction
  | ReceiveKPIDataAction
  | ReceiveJourneyPlotDataAction
  | ReceiveJourneyOverviewAction
  | ReceiveTouchpointOverviewAction
  | ReceivePeopleOverviewAction
  | SetCurrentJourneyAndTouchpointDataAction
  | ReceiveJourneyTableColumnsAction
  | ReceiveTouchpointTableColumnsAction
  | ReceivePeopleTableColumnsAction
  | ReceiveSavedDashboardsAction
  | SetCurrentDashboardAction
  | ClearSelectedFiltersAction
  | SetLoadingDashboardFiltersAction;

export const setSelectedFilters = (
  payload: SelectedFilters,
): SetSelectedFiltersAction => ({
  type: SET_SELECTED_FILTERS,
  payload,
});

export const setSelectedEntities = (
  payload: Array<Entity>,
): SetSelectedEntitiesAction => ({
  type: SET_SELECTED_ENTITIES,
  payload,
});

export const setSelectedJourneys = (
  payload: Array<FilterByID>,
): SetSelectedJourneysAction => ({
  type: SET_SELECTED_JOURNEYS,
  payload,
});

export const setSelectedDateRangeAndFrequency = (payload: {|
  ...DateRangeWithTimezone,
  chartFrequency: string,
|}): SetSelectedDateRangeAndFrequencyAction => ({
  type: SET_SELECTED_DATE_RANGE_AND_FREQUENCY,
  payload,
});

export const setSelectedMetricsType = (
  payload: MetricsType,
): SetSelectedMetricsTypeAction => ({
  type: SET_SELECTED_METRICS_TYPE,
  payload,
});

export const setChartFrequency = (
  payload: string,
): SetChartFrequencyAction => ({
  type: SET_CHART_FREQUENCY,
  payload,
});

const receiveFiltersOptions = (
  payload: FiltersOptions,
): ReceiveFiltersOptionsAction => ({
  type: RECEIVE_FILTERS_OPTIONS,
  payload,
});

const receiveSentFromOptions = (
  payload: Array<string>,
): ReceiveSentFromOptionsAction => ({
  type: RECEIVE_SENT_FROM_OPTIONS,
  payload,
});

const receiveJourneyOptions = (
  payload: Array<FilterByID>,
): ReceiveJourneyOptionsAction => ({
  type: RECEIVE_JOURNEY_OPTIONS,
  payload,
});

const receiveKPIData = (payload: KPIData | null): ReceiveKPIDataAction => ({
  type: RECEIVE_KPI_DATA,
  payload,
});

const receiveJourneyPlotData = (
  payload: Array<LineSeriesItem>,
): ReceiveJourneyPlotDataAction => ({
  type: RECEIVE_JOURNEY_PLOT_DATA,
  payload,
});

const receiveJourneyOverviewTableData = (
  payload: JourneyOverviewDataTable,
): ReceiveJourneyOverviewAction => ({
  type: RECEIVE_JOURNEY_OVERVIEW_TABLE,
  payload,
});

const receiveTouchpointOverviewTableData = (
  payload: JourneyOverviewDataTable,
): ReceiveTouchpointOverviewAction => ({
  type: RECEIVE_TOUCHPOINT_OVERVIEW_TABLE,
  payload,
});

const receivePeopleOverviewTableData = (
  payload: JourneyOverviewDataTable,
): ReceivePeopleOverviewAction => ({
  type: RECEIVE_PEOPLE_OVERVIEW_TABLE,
  payload,
});

const receiveJourneyTableColumnSelection = (
  payload: SelectedFilterColumnsData,
): ReceiveJourneyTableColumnsAction => ({
  type: RECEIVE_JOURNEY_TABLE_COLUMNS,
  payload,
});

const receiveTouchpointTableColumnSelection = (
  payload: SelectedFilterColumnsData,
): ReceiveTouchpointTableColumnsAction => ({
  type: RECEIVE_TOUCHPOINT_TABLE_COLUMNS,
  payload,
});

const receivePeopleTableColumnSelection = (
  payload: SelectedFilterColumnsData,
): ReceivePeopleTableColumnsAction => ({
  type: RECEIVE_PEOPLE_TABLE_COLUMNS,
  payload,
});

export const setCurrentJourneyAndTouchpointData = (
  payload: CurrentJourneyAndTouchpointBreadCrumbData,
): SetCurrentJourneyAndTouchpointDataAction => ({
  type: SET_CURRENT_JOURNEY_AND_TOUCHPOINT_DATA,
  payload,
});

const receiveSavedDashboardFilterListData = (payload: {
  filters: SavedDashboardFiltersListData,
  dashboard: string,
  product: string,
}): ReceiveSavedDashboardsAction => ({
  type: RECEIVE_SAVED_DASHBOARDS,
  payload,
});

export const setCurrentDashboard = (
  payload: SavedDashboardFiltersList | null,
): SetCurrentDashboardAction => ({
  type: SET_CURRENT_DASHBOARD,
  payload,
});

export const clearSelectedFilters = (): ClearSelectedFiltersAction => ({
  type: CLEAR_SELECTED_FILTERS,
});

export const setLoadingDashboardFilters = (
  payload: boolean,
): SetLoadingDashboardFiltersAction => ({
  type: SET_LOADING_DASHBOARD_FILTERS,
  payload,
});

const analyticsOptions: ApiOptions = {apiPath: ANALYTICS_API_V3};
const analyticsApiOptions: ApiPostOptions = {apiPath: ANALYTICS_API_V3};

export const getFiltersOptions: () => ThunkAction<mixed> = flow(
  key(() => `filtersOptions`),
  fetching(),
)(() => async (dispatch: Dispatch) => {
  const allFilters = {
    filter_list: [
      'journey_lifecycle_goal',
      'journey_business_goal',
      'journey_category',
      'journey_status',
      'recipient_type',
      'journey_name',
      'event_name',
      'event_type',
    ],
  };
  try {
    const response = await dispatch(
      reduxApi.post('/filters', allFilters, {}, analyticsApiOptions),
    );
    dispatch(receiveFiltersOptions(response));
  } catch (error) {
    dispatch(
      receiveFiltersOptions({
        journey_lifecycle_goal: [],
        journey_business_goal: [],
        journey_category: [],
        journey_status: [],
        recipient_type: [],
        journey_name: [],
        event_name: [],
        event_type: [],
      }),
    );
    console.error('Error Fetching Filters Options:', error);
  }
});

export const getJourneyOptions: (
  query: string,
  limit: number,
) => ThunkAction<mixed> = flow(
  key((query, limit) => `journeyOptions-${query}-${limit}`),
  fetching(),
)((query, limit) => async (dispatch: Dispatch) => {
  const journeyFilter = {filter_list: ['journey_name'], query, limit};
  try {
    const {journey_name} = await dispatch(
      reduxApi.post('/filters', journeyFilter, {}, analyticsApiOptions),
    );
    dispatch(receiveJourneyOptions(journey_name));
  } catch (error) {
    console.error('Error Fetching Journey Options:', error);
    dispatch(receiveJourneyOptions([]));
  }
});

export const getSentFromOptions: (searchQuery: string) => ThunkAction<mixed> =
  flow(
    key((searchQuery) => `sentFromOptions-${searchQuery}`),
    fetching(),
  )((searchQuery) => async (dispatch: Dispatch) => {
    try {
      const {attribute_values} = await dispatch(
        reduxApi.get('typeahead/event_resources/sent_event/from_address', {
          search_string: searchQuery,
        }),
      );
      dispatch(receiveSentFromOptions(attribute_values));
    } catch (error) {
      console.error('Error Fetching Sent From Options:', error);
      dispatch(receiveSentFromOptions([]));
    }
  });

export const getKPIData: (data: {...}) => ThunkAction<mixed> = flow(
  key(() => `KPIData`),
  fetching(),
)((data) => async (dispatch: Dispatch) => {
  try {
    const KPIData = await dispatch(
      reduxApi.post(
        `/journeys-overview/overview`,
        data,
        {},
        analyticsApiOptions,
      ),
    );
    dispatch(receiveKPIData(KPIData));
  } catch (error) {
    console.error('Error Fetching KPI Data:', error);
    dispatch(receiveKPIData(null));
  }
});

export const getJourneyPlotData: (data: {...}) => ThunkAction<mixed> = flow(
  key(() => `journeyPlotData`),
  fetching(),
)((data) => async (dispatch: Dispatch) => {
  try {
    const journeyPlotData = await dispatch(
      reduxApi.post(`/journeys-overview/plot`, data, {}, analyticsApiOptions),
    );
    dispatch(receiveJourneyPlotData(journeyPlotData.series));
  } catch (error) {
    console.error('Error Fetching Plot Data:', error);
    dispatch(receiveJourneyPlotData([]));
  }
});

export const getJourneyOverviewTableData: (
  query: JourneyOverviewDataTableQuery,
) => ThunkAction<mixed> = flow(
  key((query) => `journeyDataTable-${JSON.stringify(query)}`),
  fetching(),
)((query) => async (dispatch: Dispatch) => {
  try {
    const payload: JourneyOverviewDataTable = await dispatch(
      reduxApi.post(
        `/journeys-overview/data-table`,
        query,
        {},
        analyticsApiOptions,
      ),
    );
    dispatch(
      receiveJourneyOverviewTableData(transformTableData(payload || {})),
    );
  } catch (error) {
    console.error('Error fetching journey overview data:', error);
    dispatch(
      receiveJourneyOverviewTableData({
        data: [],
        total_count: 0,
      }),
    );
  }
});

export const getTouchpointOverviewTableData: (
  query: JourneyOverviewDataTableQuery,
) => ThunkAction<mixed> = flow(
  key((query) => `journeyDataTable-${JSON.stringify(query)}`),
  fetching(),
)((query) => async (dispatch: Dispatch) => {
  try {
    const payload: JourneyOverviewDataTable = await dispatch(
      reduxApi.post(
        `/journeys-overview/data-table`,
        query,
        {},
        analyticsApiOptions,
      ),
    );
    dispatch(
      receiveTouchpointOverviewTableData(transformTableData(payload || {})),
    );
  } catch (error) {
    console.error('Error fetching journey overview data:', error);
    dispatch(
      receiveTouchpointOverviewTableData({
        data: [],
        total_count: 0,
      }),
    );
  }
});

export const getPeopleOverviewTableData: (
  query: JourneyOverviewDataTableQuery,
) => ThunkAction<mixed> = flow(
  key((query) => `journeyDataTable-${JSON.stringify(query)}`),
  fetching(),
)((query) => async (dispatch: Dispatch) => {
  try {
    const payload: JourneyOverviewDataTable = await dispatch(
      reduxApi.post(
        `/journeys-overview/data-table`,
        query,
        {},
        analyticsApiOptions,
      ),
    );
    dispatch(receivePeopleOverviewTableData(transformTableData(payload || {})));
  } catch (error) {
    console.error('Error fetching journey overview data:', error);
    dispatch(
      receivePeopleOverviewTableData({
        data: [],
        total_count: 0,
      }),
    );
  }
});

export const getJourneyTableFilterColumns: () => ThunkAction<mixed> = flow(
  key(() => 'getJourneyTableFilterColumns'),
  fetching(),
)(() => async (dispatch: Dispatch) => {
  try {
    const payload: SelectedFilterColumnsData = await dispatch(
      reduxApi.get(
        '/journeys-overview/column-selection',
        journeyTableColumnsQuery,
        analyticsOptions,
      ),
    );
    dispatch(receiveJourneyTableColumnSelection(payload));
  } catch (error) {
    console.error('Error fetching journey column filter:', error);
  }
});

export const getTouchpointTableFilterColumns: () => ThunkAction<mixed> = flow(
  key(() => 'getTouchpointTableFilterColumns'),
  fetching(),
)(() => async (dispatch: Dispatch) => {
  try {
    const payload: SelectedFilterColumnsData = await dispatch(
      reduxApi.get(
        '/journeys-overview/column-selection',
        touchpointTableColumnsQuery,
        analyticsOptions,
      ),
    );
    dispatch(receiveTouchpointTableColumnSelection(payload));
  } catch (error) {
    console.error('Error fetching touchpoint overview column filter:', error);
  }
});

export const getPeopleTableFilterColumns: () => ThunkAction<mixed> = flow(
  key(() => 'getPeopleTableFilterColumns'),
  fetching(),
)(() => async (dispatch: Dispatch) => {
  try {
    const payload: SelectedFilterColumnsData = await dispatch(
      reduxApi.get(
        '/journeys-overview/column-selection',
        peopleTableColumnsQuery,
        analyticsOptions,
      ),
    );
    dispatch(receivePeopleTableColumnSelection(payload));
  } catch (error) {
    console.error('Error fetching people column filter:', error);
  }
});

export const saveSelectedFilterColumns: (
  columnData: SelectedFilterColumnsData,
) => ThunkAction<mixed> = flow(
  key(
    (columnData) => `saveSelectedFiltersColumns-${JSON.stringify(columnData)}`,
  ),
  fetching(),
)(
  (columnData) => async (dispatch: Dispatch) =>
    dispatch(
      reduxApi.post(
        '/journeys-overview/column-selection',
        columnData,
        {},
        analyticsApiOptions,
      ),
    ),
);

export const exportJourneyOverviewTableData: (
  query: JourneyOverviewDataTableQuery,
  url: string,
) => ThunkAction<mixed> = flow(
  key((query, url) => `journeyDataTable-${JSON.stringify(query)}-${url}`),
  fetching(),
)((query, url) => async (dispatch: Dispatch) => {
  try {
    await dispatch(reduxApi.post(url, query, {}));
  } catch (error) {
    console.error('Error exporting journey overview data:', error);
  }
});

export const getJourneyWorkflowDetailsColumns: (
  journeyId: string,
  touchpointId?: string,
) => ThunkAction<mixed> = flow(
  key((journeyId) => `getJourneyWorkflowDetails-${journeyId}`),
  fetching(),
)(
  (journeyId, touchpointId) =>
    async (dispatch: Dispatch, getState: GetState) => {
      try {
        const currentJourneyAndTouchpoint: CurrentJourneyAndTouchpointBreadCrumbData =
          {
            journey: {},
            touchpoint: {},
          };
        if (journeyId) {
          const response = await dispatch(
            reduxApi.get(`workflows/${journeyId}`),
          );
          currentJourneyAndTouchpoint.journey = {
            id: journeyId,
            name: response?.name,
          };

          if (touchpointId) {
            const touchpoint = response?.events_summary?.find(
              (tp) => tp.id === touchpointId,
            );

            if (touchpoint) {
              const {title, send_type} = touchpoint;

              currentJourneyAndTouchpoint.touchpoint = {
                id: touchpointId,
                name: title,
                sendType: send_type,
              };
            }
          } else {
            currentJourneyAndTouchpoint.touchpoint = {};
          }
        }
        dispatch(
          setCurrentJourneyAndTouchpointData(currentJourneyAndTouchpoint),
        );
      } catch (error) {
        console.error('Error saving overview column filter:', error);
      }
    },
);

export const getSavedJourneyDashboards: (
  query: SavedDashboardFiltersListQuery,
) => ThunkAction<mixed> = flow(
  key(() => 'j1-analytics-deliverability-engagement-saved-dashboards'),
  fetching(),
)((query) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoadingDashboardFilters(true));
    const {filters}: {filters: SavedDashboardFiltersListData} = await dispatch(
      reduxApi.get('/journeys-overview/save-filter', query, analyticsOptions),
    );
    const defaultDashboard = filters.find(({is_default}) => is_default);
    dispatch(receiveSavedDashboardFilterListData({filters, ...query}));
    dispatch(setCurrentDashboard(defaultDashboard ?? null));
  } catch (error) {
    console.error('Error fetching saved dashboard filter:', error);
  } finally {
    dispatch(setLoadingDashboardFilters(false));
  }
});

export const saveJourneyDashboard: (
  dashboardData: SavedDashboardFiltersList,
) => ThunkAction<mixed> = flow(
  key(
    (dashboardData) => `saveJourneyDashboard-${JSON.stringify(dashboardData)}`,
  ),
  fetching(),
)((dashboardData) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const {filters}: {filters: SavedDashboardFiltersListData} = await dispatch(
      reduxApi.post(
        '/journeys-overview/save-filter',
        dashboardData,
        {},
        analyticsApiOptions,
      ),
    );
    const {name: dashboardName, product, dashboard, id} = dashboardData;
    dispatch(
      receiveSavedDashboardFilterListData({filters, product, dashboard}),
    );
    const {id: currentDashboardID} =
      getState().analyticsJourneysOverview.currentDashboard ?? {};
    if (!(Boolean(id) && currentDashboardID !== id)) {
      const newDashboard = filters.find(({name}) => name === dashboardName);
      dispatch(setCurrentDashboard(newDashboard ?? null));
    }
  } catch (error) {
    console.error('Error fetching saved dashboard filter:', error);
    throw error;
  }
});
