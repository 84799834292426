// @noflow

// TODO (kyle): if we'd like to make this a multi-option segmented button,
// we should be able to use input[type=radio] instead of Toggle.

/**
 * Warning:
 * Do not wrap Switch in a label. For simplicity's sake, Switch uses an
 * internal label tag to handle click delegation to the hidden input, and
 * nesting labels is a bad idea.
 */

import * as React from 'react';
import invariant from 'invariant';

import {classify} from 'src/utils/index';

import Toggle from 'src/components/lib/toggle/toggle.jsx';

import css from './index.css';


type Props = {
  className: ?string,
  options: {
    label: string,
    value: *,
  }[],
};

export default ({options, className, ...props}: Props) => {
  invariant(options.length === 2, 'Switch requires 2 options.');

  return (
    <Toggle className={classify(css.container, className)} toggleClassName={css.toggle} {...props}>
      <div className={css.option1}>{options[0].label}</div>
      <div className={css.option2}>{options[1].label}</div>
    </Toggle>
  );
};

