// @flow

// $FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores';
import type {SenseEvent} from 'src/types/events';
import type {ThunkAction} from 'src/reducers';

import {setEventType} from 'src/actions/event';
import {lockEventType} from 'src/actions/event-creation';
import {EventTypeEnum} from 'src/stores/event';

import * as reduxApi from 'src/utils/redux-api';


export const CREATE_NEW_EVENT = 'event-creation/new';
export const UPDATE_EVENT = 'event-creation/update';
export const INHERIT_EVENT = 'event-creation/inherit';
export const SET_NEW_EVENT = 'event-creation/setNew';

type NewEventAction = {
  type: 'event-creation/new',
  // $FlowFixMe[value-as-type] [v1.32.0]
  payload: SenseEvent,
};

type UpdateEventAction = {
  type: 'event-creation/update',
  // $FlowFixMe[value-as-type] [v1.32.0]
  payload: SenseEvent,
};

type InheritEventAction = {
  type: 'event-creation/inherit',
  payload: $ReadOnly<{id: string, ...}>,
};

type NewEventMetadata = {title: string, workflowId: string};
type SetNewEventAction = {
  type: 'event-creation/setNew',
  payload: NewEventMetadata,
};

export type EventCreationAction =
  | NewEventAction
  | UpdateEventAction
  | InheritEventAction
  | SetNewEventAction;

// NOTE (kyle): this is unused and probably does not work as expected
export const createNewEvent = (payload: SenseEvent): NewEventAction => ({
  type: CREATE_NEW_EVENT,
  payload,
});

export const updateEvent = (payload: SenseEvent): UpdateEventAction => ({
  type: UPDATE_EVENT,
  payload,
});
export const inheritEvent = (payload: SenseEvent): InheritEventAction => ({
  type: INHERIT_EVENT,
  payload,
});
export const setNewEvent = (payload: NewEventMetadata): SetNewEventAction => ({
  type: SET_NEW_EVENT,
  payload,
});

// eslint-disable-next-line max-params
export const finalizeMultiEntityEvent = (
  // $FlowFixMe[value-as-type] [v1.32.0]
  store: IndexStore,
  router: *,
  workflowId: string,
  surveyId: string,
  allowSMS: boolean,
) => {
  if (allowSMS) {
    router.push(`/journey/${workflowId}/touchpoint/new`);
  } else {
    setEventType(store, EventTypeEnum.Survey);
    lockEventType(store, surveyId);
    router.push(`/journey/${workflowId}/touchpoint/${surveyId}/new/content`);
  }
};

export const sendTestMEEmailSurvey = (
  workflowId: string,
  eventId: string,
  rest?: {...},
): ThunkAction<mixed> =>
  reduxApi.post(`workflows/${workflowId}/events/${eventId}/test-send`, rest);

export const sendTestMESMSSurvey = (
  workflowId: string,
  eventId: string,
  rest?: {...},
): ThunkAction<mixed> =>
  reduxApi.post(`workflows/${workflowId}/events/${eventId}/test-send`, rest);

export const getSenderNumberForSMS = ({
  workflowId,
  eventId,
  category_id,
  anchor_entity_id,
  anchor_entity_type,
  branding_settings,
}: {
  workflowId: string,
  eventId: string,
  category_id: number,
  anchor_entity_id: string,
  anchor_entity_type: string,
  branding_settings: {
    sms_from_user: string,
  },
}): ThunkAction<mixed> =>
  reduxApi.post(`workflows/${workflowId}/events/${eventId}/sender-number`, {
    category_id,
    anchor_entity_id,
    anchor_entity_type,
    branding_settings,
  });
