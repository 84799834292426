// @flow strict

import * as React from 'react';
import {classify} from 'src/utils/classify';
import makeClassNameComponent, {
  type ClassNameComponent,
  makeClassNameComponentCustom,
} from 'src/utils/makeClassNameComponent';

import css from './core.css';
import dsCss from 'designSystem2021';


export const PaddedRoundedBox: ClassNameComponent<> = makeClassNameComponent(
  classify(dsCss.defaultPadded, dsCss.defaultRoundedCorner),
  undefined,
  'PaddedRoundedBox',
);

export const ClickableBox: ClassNameComponent<typeof PaddedRoundedBox> =
  makeClassNameComponentCustom(classify(dsCss.boxClickable), PaddedRoundedBox);

export const SelectedBox: ClassNameComponent<typeof PaddedRoundedBox> =
  makeClassNameComponentCustom(classify(dsCss.boxSelected), PaddedRoundedBox);

export const DefaultBackgroundBox: ClassNameComponent<typeof PaddedRoundedBox> =
  makeClassNameComponentCustom(
    classify(dsCss.boxDefaultBackground),
    PaddedRoundedBox,
  );

export const DefaultWhiteBox: ClassNameComponent<typeof PaddedRoundedBox> =
  makeClassNameComponentCustom(
    classify(dsCss.boxDefaultWhite),
    PaddedRoundedBox,
  );

export const ErrorBox: ClassNameComponent<typeof PaddedRoundedBox> =
  makeClassNameComponentCustom(classify(dsCss.boxError), PaddedRoundedBox);
