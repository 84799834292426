// @flow
import type {TitleBarDetailsModal} from 'src/action-creators/modal';

import * as React from 'react';

import {TitleBarModal} from 'src/components/modals/base-modal.jsx';

import WarningIcon from 'src/images/icons/warning-icon.svg?noAttrs';
import {GenericErrorDialogGenesis} from 'src/components/common/reports/genesis-generic-error-dialog.jsx';
import css from 'src/components/modals/base-modal.css';


const GenericErrorModal = (props: TitleBarDetailsModal): React.Node => {
  if (props.modalVersion === 'genesis') {
    return <GenericErrorDialogGenesis {...props} />;
  }
  return (
    <TitleBarModal
      {...props}
      titleIcon={
        props.titleIcon ?? <WarningIcon style={{fill: css.colorWhite}} />
      }
    />
  );
};

export default GenericErrorModal;
