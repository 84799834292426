// @flow
import * as React from 'react';

import {
  WritebackContext,
  UPDATE_QUESTION,
} from 'src/components/writeback/writeback-wrapper.jsx';
import {StringListInput} from 'src/components/lib/token-list-input/';

import moduleCss from 'src/components/workflow/event/content/module/editors/module.css';


export default ({
  conditionalAlerts,
}: {
  conditionalAlerts?: boolean,
}): React.Element<'div'> => {
  const {state, dispatch} = React.useContext(WritebackContext);
  const currentQuestionType = state.question?.selectedType;
  const currentQuestion = state.question?.[currentQuestionType];
  return (
    <div className={moduleCss.generalModule_row}>
      {conditionalAlerts && (
        <label className={moduleCss.generalModule_alertKeywordsLabel}>
          <div className={moduleCss.rowLabel}>
            Send alert when keywords detected:
          </div>
          <StringListInput
            verticalStretch
            placeholder="e.g. bad, late, inexperienced"
            values={currentQuestion.alertKeywords || []}
            onChange={(alertKeywords) =>
              // $FlowFixMe[incompatible-call] types/surveys
              dispatch({
                type: UPDATE_QUESTION,
                payload: {
                  questionType: currentQuestionType,
                  attrs: {
                    alertKeywords,
                  },
                },
              })
            }
          />
        </label>
      )}
    </div>
  );
};
