// @flow strict-local

import type {WorkflowActivationNotifications} from 'src/types/workflow';
import type {WorkflowsActivationAction} from 'src/action-creators/workflow-activation';

import {
  RECEIVE_ALL,
  ADD_RECENTLY_RESOLVED,
} from 'src/action-creators/workflow-activation';


export type WorkflowActivationState = {
  notifications: WorkflowActivationNotifications,
  recentlyResolved: string[],
};

const initialState = {
  notifications: Object.freeze({}),
  recentlyResolved: [],
};

export default (
  state: WorkflowActivationState = initialState,
  action: WorkflowsActivationAction,
): WorkflowActivationState => {
  switch (action.type) {
    case RECEIVE_ALL: {
      return {
        ...state,
        notifications: action.payload,
      };
    }
    case ADD_RECENTLY_RESOLVED: {
      return {
        ...state,
        recentlyResolved: [...state.recentlyResolved, ...action.payload],
      };
    }
  }

  return state;
};
