// @flow strict
import * as React from 'react';

import type {TableRowProps} from 'src/designSystem2021Components/table/index.jsx';
import type {TimeSheetData} from 'src/types/referral-v2';

import invariant from 'invariant';

import {TIMESHEET_CSV_ROWS} from 'src/utils/referral-v2.js';
import {classify} from 'src/utils/classify';

import {
  BasicSingleCell,
  StatusIndicator,
  BodyMedium,
  Dropdown,
  BasicRow,
} from '@spaced-out/ui-design-system/lib/components/index';

import tableCss from 'src/components/referral-v2/table/table.css';
import css from './timesheet.css';


export type Extras = {
  fieldMapping: {[string]: string},
  setFieldMapping: ({[string]: string}) => mixed,
  isValidate: boolean,
};

const TimeSheetTableRow = ({
  data,
  extras,
}: TableRowProps<TimeSheetData, Extras>): React.Node => {
  invariant(extras, 'extras must be set to destructure');

  const {fieldMapping, setFieldMapping, isValidate} = extras;
  const isFieldMapped =
    Object.values(fieldMapping).length === TIMESHEET_CSV_ROWS.length &&
    isValidate;

  return (
    <BasicRow className={tableCss.tableRow}>
      <BasicSingleCell>
        <StatusIndicator
          name="circle"
          type="solid"
          status={
            isFieldMapped && data.is_error
              ? 'danger'
              : fieldMapping[data.column_header]
              ? 'success'
              : 'neutral'
          }
          size="small"
        />
      </BasicSingleCell>
      <BasicSingleCell>
        <BodyMedium color="tertiary">{data.column_header}</BodyMedium>
      </BasicSingleCell>
      <BasicSingleCell>
        <Dropdown
          label=""
          placeholder="Select Field"
          onChange={(e) => {
            setFieldMapping({
              ...fieldMapping,
              [data.column_header]: e.label,
            });
          }}
          classNames={{wrapper: css.tableColumnWidth}}
          dropdownInputText={
            fieldMapping ? fieldMapping[data.column_header] : ''
          }
          menu={{
            options: data.header_from_file.map((field: string) => ({
              key: field,
              label: field,
            })),
            selectedKeys: fieldMapping
              ? [fieldMapping[data.column_header]]
              : [],
            classNames: {
              wrapper: css.optionTextContainer,
            },
          }}
          size="medium"
        />
      </BasicSingleCell>
      <BasicSingleCell>
        <BodyMedium
          className={classify({
            [css.error]: !isFieldMapped ? false : data.is_error,
          })}
        >
          {!isFieldMapped ? '-' : data.is_error ? 'Data Missing' : 'Available'}
        </BodyMedium>
      </BasicSingleCell>
    </BasicRow>
  );
};

export default TimeSheetTableRow;
