// @noflow
// NOTE: Time on the backend is UTC, time on the frontend is Local
import type {Schedule, ApiGracePeriod} from 'src/api-parsers';
import orderBy from 'lodash/orderBy';
import sortedUniqBy from 'lodash/sortedUniqBy';
import filter from 'lodash/filter';
import clone from 'lodash/clone';
import _get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import has from 'lodash/has';
import moment from 'moment-timezone';
import sculpt from 'sculpt';
import RRule from 'rrule';

import Store from './base';
import {fillArray} from 'src/utils/array';
import {camel, snake, stringifyNumber} from 'src/utils';
import {formatIsoDate} from 'src/utils/date-time';


const RRULE_WEEKDAYS = [
  RRule.MO,
  RRule.TU,
  RRule.WE,
  RRule.TH,
  RRule.FR,
  RRule.SA,
  RRule.SU,
];

const DEFAULT_TIMEZONE = 'America/Los_Angeles';
const TIME_FORMAT = 'HH:mm:ss';

export const ScheduleEnum = {
  Manual: 'manual',
  Scheduled: 'scheduled',
  Recurring: 'recurring',
  FieldChange: 'fieldchange',
  PlacementCreated: 'placementcreated',
  AudienceMemberCreated: 'audiencemembercreated',
  CampaignDateRange: 'campaigndaterange',
  Custom: 'custom',
};

export const DateTypeEnum = {
  SpecificDate: 'specific_date',
  Relative: 'relative',
  DayOfWeek: 'dow_relative',
  On: 'on',
};

export const AFTER_OPTIONS = [{value: 'after', label: 'After'}];

export const BEFORE_AFTER_OPTIONS = [
  {value: 'before', label: 'Before'},
  ...AFTER_OPTIONS,
];

export const BASIC_TIMEFRAME_OPTIONS = [
  {value: 'day', label: 'Day', labelPlural: 'Days'},
  {value: 'week', label: 'Week', labelPlural: 'Weeks'},
];

export const TIMEFRAME_OPTIONS = [
  ...BASIC_TIMEFRAME_OPTIONS,
  {value: 'month', label: 'Month', labelPlural: 'Months'},
  {value: 'year', label: 'Year', labelPlural: 'Years'},
];

export const BRANCH_EVENT_TIMEFRAME_OPTIONS = BASIC_TIMEFRAME_OPTIONS;

export const REPEATS_OPTIONS = [
  {value: 'daily', label: 'Daily', verboseString: 'every day'},
  {value: 'weekly', label: 'Weekly', verboseString: 'weekly'},
  {
    value: 'last-day-of-month',
    label: 'Last Day Of Month',
    verboseString: 'last day of month',
  },
  {value: 'yearly', label: 'Yearly', verboseString: 'yearly'},
];
export const DELAY_OPTIONS = [
  {value: 'days', label: 'Days'},
  {value: 'hours', label: 'Hours'},
];

export const DEFAULT_REMINDER: DayReminderDef = {type: 'responded', day: 3};

const DEFAULT_REMINDERS: RemindersDef = [];

export const REMINDER_OPTIONS = [
  {value: 'opened', label: 'Opened'},
  {value: 'responded', label: 'Responded To'},
];

export const MAX_CAMPAIGNS_SCHEDULED = 3;

const DefaultManualSchedule: ManualScheduleDef = {
  type: ScheduleEnum.Manual,
};

const DefaultDateSchedule: DateScheduleDef = {
  type: ScheduleEnum.Scheduled,
  dateType: DateTypeEnum.On,
  date: formatIsoDate(),
  numValue: 1,
  timeframe: 'day',
  beforeAfter: 'after',
  relField: null,
  onField: null,
  dowField: null,
  dowOrdinal: 1,
  dowBeforeAfter: 'after',
  dowDay: 'monday',
  sendTilEod: false,
  timeHour: '' + 30 * 2 * 7, // 7:00am
  timePeriod: '0',
  sendPerPlacement: false,
  gracePeriod: null,
};

const iToday = moment().isoWeekday() - 1; // Monday = 0; Sunday = 6
const daysOfWeek = new Array(7).fill(false);
daysOfWeek[iToday] = true;

const DefaultRecurringSchedule: RecurringScheduleDef = {
  type: ScheduleEnum.Recurring,
  repeats: 'weekly',
  frequencyInterval: 1,
  firstInstance: 1,
  daysOfWeek,
  startDate: formatIsoDate(),
  indefinitely: true,
  // untilDate: undefined,
  // maxInstances: undefined,
  timeHour: '0',
  timePeriod: '0',
  relativeStartDate: {
    option: 'relative_date',
    field: '',
    delayOption: 'days',
    delayNum: 0,
  },
  sendPerPlacement: false,
  gracePeriod: null,
};

const DefaultFieldChangeSchedule: FieldChangeScheduleDef = {
  type: ScheduleEnum.FieldChange,
  fieldName: '',
  fromValue: '',
  toValue: '',
  delayOption: 'hours',
  delayNum: 0,
  timeHour: '0',
  timePeriod: '0',
  sendPerPlacement: false,
  gracePeriod: null,
  countPerDay: 0,
  blackout_window: {use_default: true},
};

const DefaultPlacementCreatedSchedule: CreatedScheduleDef = {
  type: ScheduleEnum.PlacementCreated,
  fieldName: null,
  fromValue: null,
  toValue: null,
  delayOption: 'days',
  delayNum: 1,
  timeHour: '' + 30 * 2 * 9, // 9:00am
  timePeriod: '0',
  gracePeriod: null,
};

const DefaultAudienceMemberCreatedSchedule: CreatedScheduleDef = {
  type: ScheduleEnum.AudienceMemberCreated,
  fieldName: null,
  fromValue: null,
  toValue: null,
  delayOption: 'days',
  delayNum: 1,
  timeHour: '' + 30 * 2 * 9, // 9:00am
  timePeriod: '0',
  gracePeriod: null,
};

const DefaultCampaignSchedule: CampaignScheduleDef = {
  type: 'campaigndaterange',
  startDate: formatIsoDate(),
  startTimeHour: '' + 30 * 2 * 7, // 7:00am
  startTimePeriod: '0',
  endTimeHour: '' + 30 * 2 * 7, // 7:00pm
  endTimePeriod: '1',
  countPerDay: null,
  maxCountPerDay: null,
  shouldEndEarly: false,
  endDate: '',
  maxDaysToSendOver: null,
  daysToSendOver: null,
  countPerDayError: null,
  daysToSendOverError: null,
  speedType: 'maximum_until_end',
};

export const TIME_HOUR_MAX = 720;

export const DOW_DAYS = [
  {
    value: 'monday',
    label: 'Monday',
  },
  {
    value: 'tuesday',
    label: 'Tuesday',
  },
  {
    value: 'wednesday',
    label: 'Wednesday',
  },
  {
    value: 'thursday',
    label: 'Thursday',
  },
  {
    value: 'friday',
    label: 'Friday',
  },
  {
    value: 'saturday',
    label: 'Saturday',
  },
  {
    value: 'sunday',
    label: 'Sunday',
  },
];

// TODO (kyle): Flow code
// export const DOW_ORDINAL = fillArray<DowLabel>(
export const DOW_ORDINAL = fillArray(52, (index: number): DowLabel => ({
  value: index + 1,
  label: stringifyNumber(index + 1),
}));

export const SEND_TIL_EOD_OPTIONS = [
  {
    value: false,
    label: 'At',
  },
  {
    value: true,
    label: 'No earlier than',
  },
];

function isAMorPM(date) {
  if (moment(date).hours() > 11) {
    return '1'; // PM
  } else {
    return '0'; // AM
  }
}

/*
 *  This method assumes that the date/hour/period are in the timezone specified and returns a datetime string
 *  in the timezone specified
 */
export function getTzDatetimeFromDateHourPeriod(
  tzDate,
  tzHour,
  tzPeriod,
  timezone,
) {
  const {militaryHour, minute} = getMilitaryHourAndMinute(tzHour, tzPeriod);
  const dateInTz = moment
    .tz(tzDate, 'YYYY-MM-DD', timezone)
    .hour(militaryHour)
    .minute(minute)
    .format();
  return dateInTz;
}

function getDateHourPeriodFromTzDatetime(tzDatetime, timezone) {
  const dateInTz = moment.tz(tzDatetime, timezone);
  const hour =
    ((dateInTz.hours() * 60 + dateInTz.minutes()) % TIME_HOUR_MAX) + '';
  const period = isAMorPM(dateInTz);
  return {
    tzDate: dateInTz.format('YYYY-MM-DD'),
    tzHour: hour,
    tzPeriod: period,
  };
}

function getTimeFromHourAndMinute(hour, minute, timezone) {
  return moment
    .tz(moment.tz(timezone).format('YYYY-MM-DD'), timezone)
    .hour(hour)
    .minute(minute)
    .format(TIME_FORMAT);
}

function getTimeFromHourPeriod(hour, period, timezone = DEFAULT_TIMEZONE) {
  const {militaryHour, minute} = getMilitaryHourAndMinute(
    hour,
    period,
    timezone,
  );

  return getTimeFromHourAndMinute(militaryHour, minute);
}

/*
 *  This method assumes that the date/hour/period are in the timezone specified and returns a relative number of days
 *  'day' and an time of day 'time' that would be the offset values after being properly converted into UTC
 *  For example, if an event was scheduled on Day 0 of a birth date at 11:00PM PST, then it would actually be sent
 *  the equivalent of 1 day after that birth day at 6:00AM UTC (which is the same exact time)
 *
 */
function getTzDayAndTimeFromRelativeSchedule(
  numRelativeUnits,
  hour,
  period,
  beforeAfter,
  timezone,
) {
  const {militaryHour, minute} = getMilitaryHourAndMinute(hour, period);
  const time = getTimeFromHourAndMinute(militaryHour, minute, timezone);
  const numValue =
    beforeAfter === 'after'
      ? numRelativeUnits
      : numRelativeUnits
      ? -numRelativeUnits
      : 0;
  return {numValue, time};
}

export function getHourPeriodFromTime(time: string) {
  let timeHour = '0',
    timePeriod = '0';

  if (time) {
    const hours = parseInt(time.slice(0, 2), 10);
    const minutes = hours * 60 + parseInt(time.slice(3, 5), 10);
    timeHour = (minutes % TIME_HOUR_MAX) + '';
    timePeriod = minutes >= TIME_HOUR_MAX ? '1' : '0';
  }

  return {hour: timeHour, period: timePeriod};
}

/*
 *  This method assumes that the hourInMinutes are any number of minutes up to 720 (12:00) and timePeriod is either
 *  0 (for AM) or 1 (for PM).
 *  It will return the hour as an integer between 0 and 23 (inclusive) which represents the hour in military time and
 *  the minute within the hour as an integer.
 *
 */
function getMilitaryHourAndMinute(hourInMinutes, timePeriod) {
  const timeInMinutes =
    parseInt(hourInMinutes, 10) + parseInt(timePeriod, 10) * TIME_HOUR_MAX;
  const militaryHour = Math.floor(timeInMinutes / 60);
  const minute = timeInMinutes % 60;
  return {militaryHour, minute};
}

export type ScheduleState = {
  scheduleType: ScheduleTypes,
  editManualSchedule: ManualScheduleDef,
  editScheduledSchedule: DateScheduleDef,
  editRecurringSchedule: RecurringScheduleDef,
  editFieldChangeSchedule: FieldChangeScheduleDef,
  editPlacementCreatedSchedule: CreatedScheduleDef,
  editAudienceMemberCreatedSchedule: CreatedScheduleDef,
  editReminders: RemindersDef,
  editCustomSchedule: {type: ScheduleTypes},
};

export default class SchedulingStore extends Store {
  state: {
    schedules: {[eventId: string]: ScheduleState},
    activeEvent: ?string,
    scheduleType: ScheduleTypes,
    editManualSchedule: ManualScheduleDef,
    editScheduledSchedule: DateScheduleDef,
    editRecurringSchedule: RecurringScheduleDef,
    editFieldChangeSchedule: FieldChangeScheduleDef,
    editPlacementCreatedSchedule: CreatedScheduleDef,
    editAudienceMemberCreatedSchedule: CreatedScheduleDef,
    editReminders: RemindersDef,
    editCustomSchedule: {type: ScheduleTypes},
  };

  constructor() {
    super('scheduling');

    this.defaultState = {
      scheduleType: ScheduleEnum.Manual,
      editManualSchedule: DefaultManualSchedule,
      editScheduledSchedule: DefaultDateSchedule,
      editRecurringSchedule: DefaultRecurringSchedule,
      editFieldChangeSchedule: DefaultFieldChangeSchedule,
      editPlacementCreatedSchedule: DefaultPlacementCreatedSchedule,
      editAudienceMemberCreatedSchedule: DefaultAudienceMemberCreatedSchedule,
      editCampaignSchedule: DefaultCampaignSchedule,
      editReminders: DEFAULT_REMINDERS,
      editCustomSchedule: {},
    };

    this.state = {
      scheduleType: ScheduleEnum.Manual,
      editManualSchedule: DefaultManualSchedule,
      editScheduledSchedule: DefaultDateSchedule,
      editRecurringSchedule: DefaultRecurringSchedule,
      editFieldChangeSchedule: DefaultFieldChangeSchedule,
      editPlacementCreatedSchedule: DefaultPlacementCreatedSchedule,
      editAudienceMemberCreatedSchedule: DefaultAudienceMemberCreatedSchedule,
      editCampaignSchedule: DefaultCampaignSchedule,
      editReminders: DEFAULT_REMINDERS,
      editCustomSchedule: {},
      schedules: {},
      activeEvent: null,
    };
  }

  updateForId(eventId: ?string, spec: Object) {
    if (eventId != null) {
      if (!this.state.schedules[eventId]) {
        this.updateState({
          schedules: {
            [eventId]: {
              $set: Object.assign({}, this.defaultState),
            },
          },
        });
      }
      this.updateState({schedules: {[eventId]: spec}});
    } else {
      this.updateState(spec);
    }
  }

  setDefaultState(eventId: ?string) {
    const defaultState = {
      scheduleType: {$set: ScheduleEnum.Manual},
      editManualSchedule: {$set: DefaultManualSchedule},
      editScheduledSchedule: {$set: DefaultDateSchedule},
      editRecurringSchedule: {$set: DefaultRecurringSchedule},
      editFieldChangeSchedule: {
        $set: DefaultFieldChangeSchedule,
      },
      editPlacementCreatedSchedule: {
        $set: DefaultPlacementCreatedSchedule,
      },
      editAudienceMemberCreatedSchedule: {
        $set: DefaultAudienceMemberCreatedSchedule,
      },
      editCampaignSchedule: {$set: DefaultCampaignSchedule},
      editReminders: {$set: DEFAULT_REMINDERS},
    };

    this.updateForId(eventId, defaultState);
  }

  remove(eventId: string) {
    this.setState({
      schedules: omit(this.state.schedules, eventId),
    });
  }

  get(eventId: string): ?ScheduleState {
    // just return the object, don't do anything fancy to it
    // (cf. getActiveSchedule expects the schedule to be initialized)
    return this.state.schedules[eventId];
  }

  getActiveSchedule(eventId: ?string): ?ScheduleDef {
    // flow note: this method relies on a schedule being loaded before
    // state is initialized. until then, this returns undefined
    const store = eventId ? this.state.schedules[eventId] : this.state;

    // TODO(marcos) should this return immediately if store is null?
    if (store == null) {
      return store;
    }
    switch (store.scheduleType) {
      case ScheduleEnum.Manual:
        return store.editManualSchedule;
      case ScheduleEnum.Scheduled:
        return store.editScheduledSchedule;
      case ScheduleEnum.Recurring:
        return store.editRecurringSchedule;
      case ScheduleEnum.FieldChange:
        return store.editFieldChangeSchedule;
      case ScheduleEnum.PlacementCreated:
        return store.editPlacementCreatedSchedule;
      case ScheduleEnum.AudienceMemberCreated:
        return store.editAudienceMemberCreatedSchedule;
      case 'campaigndaterange':
        return store.editCampaignSchedule;
      case ScheduleEnum.Custom:
        return store.editCustomSchedule;
    }
  }

  getReminders(eventId: string): ?RemindersDef {
    // flow note: this method relies on a schedule being loaded before
    // state is initialized. until then, this returns undefined
    const store = eventId ? this.state.schedules[eventId] : this.state;
    // TODO(marcos) should this return immediately if store is null?
    if (store == null) {
      return store;
    }

    return store.editReminders;
  }

  isActiveScheduleValid(eventId: ?string): boolean {
    const schedule = this.getActiveSchedule(eventId);

    if (schedule && schedule.type === 'campaigndaterange') {
      if (
        !schedule.countPerDay ||
        schedule.timeDiffError ||
        schedule.countPerDayError ||
        schedule.daysToSendOverError
      ) {
        return false;
      }
    }

    // @TODO(coco): not implemented for other schedules

    return true;
  }

  setScheduleType(newType: ScheduleTypes, eventId: ?string) {
    this.updateForId(eventId, {scheduleType: {$set: newType}});
  }

  updateActiveSchedule(newSchedule: ScheduleDef, eventId: ?string) {
    const store = eventId ? this.state.schedules[eventId] : this.state;
    let update = {editManualSchedule: {$set: newSchedule}};
    switch (store.scheduleType) {
      case ScheduleEnum.Manual:
        update = {editManualSchedule: {$set: newSchedule}};
        break;
      case ScheduleEnum.Scheduled:
        update = {editScheduledSchedule: {$set: newSchedule}};
        break;
      case ScheduleEnum.Recurring:
        update = {editRecurringSchedule: {$set: newSchedule}};
        break;
      case ScheduleEnum.FieldChange:
        update = {editFieldChangeSchedule: {$set: newSchedule}};
        break;
      case ScheduleEnum.PlacementCreated:
        update = {
          editPlacementCreatedSchedule: {$set: newSchedule},
        };
        break;
      case ScheduleEnum.AudienceMemberCreated:
        update = {
          editAudienceMemberCreatedSchedule: {$set: newSchedule},
        };
        break;
      case 'campaigndaterange':
        update = {editCampaignSchedule: {$set: newSchedule}};
        break;
    }

    this.updateForId(eventId, update);
  }

  updateReminders(newReminders: RemindersDef, eventId: ?string) {
    const update = {editReminders: {$set: newReminders}};
    this.updateForId(eventId, update);
  }

  loadReminders(apiReminders: ?APIRemindersDef, eventId: ?string) {
    if (!apiReminders) {
      return;
    }
    const reminders = Object.keys(apiReminders).reduce((accumulator, type) => {
      apiReminders[type].forEach((reminder) => {
        accumulator.push({
          ...reminder,
          type,
        });
      });
      return accumulator;
    }, []);
    this.updateReminders(reminders, eventId);
  }

  //TODO:(diwakersurya): we are removing manual scheduling in schedule revamp. To be removed later
  loadManualScheduleFormat(eventId: ?string) {
    const update = {
      scheduleType: {$set: ScheduleEnum.Manual},
      editManualSchedule: {
        $set: {
          type: ScheduleEnum.Manual,
        },
      },
    };
    this.updateForId(eventId, update);
  }

  getDelayTime(delay: DelayTime) {
    let delayNum, delayOption;
    let hour, period;
    if (delay.hour !== undefined) {
      delayOption = 'hours';
      delayNum = delay.hour;
    } else {
      delayOption = 'days';
      delayNum = parseInt(delay.day);
      const timeFields = getHourPeriodFromTime(delay.time);
      hour = timeFields['hour'];
      period = timeFields['period'];
    }
    return {
      delayNum,
      delayOption,
      hour,
      period,
    };
  }

  getGracePeriod(schedule: Schedule): ?number {
    return schedule.gracePeriod && schedule.gracePeriod.hour
      ? schedule.gracePeriod.hour
      : null;
  }

  loadDateScheduleFormat(scheduleFormat: Schedule, eventId: ?string) {
    if (scheduleFormat.fromDate) {
      const {fromDate} = scheduleFormat;
      if (!fromDate) {
        return;
      }
      const dateVal = fromDate.values[0];
      const {value: timeframe} =
        Object.values(TIMEFRAME_OPTIONS).find(({value}) =>
          has(dateVal, value),
        ) || '';
      const numValue = dateVal[timeframe];
      const time = dateVal.time || '00:00'; // Default to midnight?
      const sendTilEod = dateVal['send_til_eod'] || false;
      const {hour, period} = getHourPeriodFromTime(time);
      const gracePeriod = this.getGracePeriod(scheduleFormat);

      const relTime = fromDate.from_time && camel(fromDate.from_time);
      let defaultTime;
      if (relTime && relTime.defaultTime) {
        const {hour, period} = getHourPeriodFromTime(relTime.defaultTime);
        defaultTime = {
          hour,
          period,
        };
        relTime.defaultTime = defaultTime;
      }

      // the second half of this condition catches relative scheduled branch events
      // which lack fields but may have numValue === 0
      if (fromDate.values && (numValue !== 0 || !fromDate.field)) {
        // DateTypeEnum.Relative
        const field = scheduleFormat.fromDate.field;
        this.updateForId(eventId, {
          scheduleType: {$set: ScheduleEnum.Scheduled},
          editScheduledSchedule: {
            $assign: {
              type: ScheduleEnum.Scheduled,
              dateType: DateTypeEnum.Relative,
              numValue: Math.abs(numValue),
              timeframe,
              beforeAfter: numValue < 0 ? 'before' : 'after',
              relField: field,
              //(diwakersurya) ENGAGE-5259, same field should be preserved for all type of dates              relField: field,
              onField: field,
              dowField: field,
              relTime,
              timeHour: hour,
              timePeriod: period,
              sendPerPlacement: scheduleFormat.fromDate.send_per_placement,
              gracePeriod,
              ifWeekend: scheduleFormat.ifWeekend,
            },
          },
        });
      } else {
        const field = scheduleFormat.fromDate.field;
        this.updateForId(eventId, {
          scheduleType: {$set: ScheduleEnum.Scheduled},
          editScheduledSchedule: {
            type: {$set: ScheduleEnum.Scheduled},
            dateType: {$set: DateTypeEnum.On},
            onField: {$set: field},
            //(diwakersurya) ENGAGE-5259, same field should be preserved for all type of dates              relField: field,
            relField: {$set: field},
            dowField: {$set: field},
            relTime: {$set: relTime},
            timeHour: {$set: hour},
            timePeriod: {$set: period},
            sendTilEod: {$set: sendTilEod},
            sendPerPlacement: {
              $set: scheduleFormat.fromDate.send_per_placement,
            },
            gracePeriod: {$set: gracePeriod},
            ifWeekend: {$set: scheduleFormat.ifWeekend},
          },
        });
      }
    } else if (scheduleFormat.sendDate) {
      const time = scheduleFormat.sendDate.time || '00:00'; // Default to midnight?
      const {hour, period} = getHourPeriodFromTime(time);
      const gracePeriod = this.getGracePeriod(scheduleFormat);
      this.updateForId(eventId, {
        scheduleType: {$set: ScheduleEnum.Scheduled},
        editScheduledSchedule: {
          $assign: {
            type: ScheduleEnum.Scheduled,
            dateType: DateTypeEnum.SpecificDate,
            date: scheduleFormat.sendDate.date,
            timeHour: hour,
            timePeriod: period,
            sendPerPlacement: scheduleFormat.sendDate.send_per_placement,
            gracePeriod,
          },
        },
      });
    } else if (scheduleFormat.dayOfWeek) {
      const dowOrdinal = scheduleFormat.dayOfWeek.num;
      const time = scheduleFormat.dayOfWeek.time || '00:00'; // Default to midnight?
      const {hour, period} = getHourPeriodFromTime(time);
      const gracePeriod = this.getGracePeriod(scheduleFormat);
      const field = scheduleFormat.dayOfWeek.field;
      this.updateForId(eventId, {
        scheduleType: {$set: ScheduleEnum.Scheduled},
        editScheduledSchedule: {
          type: {$set: ScheduleEnum.Scheduled},
          dateType: {$set: DateTypeEnum.DayOfWeek},
          dowOrdinal: {$set: Math.abs(dowOrdinal)},
          dowBeforeAfter: {
            $set: dowOrdinal < 0 ? 'before' : 'after',
          },
          dowField: {$set: field},
          //(diwakersurya) ENGAGE-5259, same field should be preserved for all type of dates              relField: field,
          onField: {$set: field},
          relField: {$set: field},
          dowDay: {$set: scheduleFormat.dayOfWeek.day},
          timeHour: {$set: hour},
          timePeriod: {$set: period},
          sendPerPlacement: {
            $set: scheduleFormat.dayOfWeek.send_per_placement,
          },
          gracePeriod: {$set: gracePeriod},
        },
      });
    }
  }

  loadRepeatScheduleFormat(scheduleFormat: Schedule, eventId: ?string) {
    const rule = new RRule.fromString(scheduleFormat.repeat.rule);
    const startDate = scheduleFormat.repeat.startDate
      ? scheduleFormat.repeat.startDate
      : moment().format('YYYY-MM-DD');
    const tzDate = moment.tz(startDate, DEFAULT_TIMEZONE).format('YYYY-MM-DD');
    const {hour, period} = getHourPeriodFromTime(
      scheduleFormat.repeat.time || '00:00:00',
    );
    const gracePeriod = this.getGracePeriod(scheduleFormat);

    // TODO (kyle): see if its necessary to add frequencyInterval here
    const setState = {
      type: {$set: ScheduleEnum.Recurring},
      startDate: {$set: tzDate},
      indefinitely: {
        $set:
          !scheduleFormat.repeat.untilDate &&
          !scheduleFormat.repeat.maxInstances,
      },
      untilDate: {$set: scheduleFormat.repeat.untilDate},
      maxInstances: {$set: scheduleFormat.repeat.maxInstances},
      timeHour: {$set: hour},
      timePeriod: {$set: period},
      relativeStartDate: {
        $set: this.getParsedRelativeField(scheduleFormat, 'relativeStartDate'),
      },
      sendPerPlacement: {
        $set: scheduleFormat.repeat.send_per_placement,
      },
      gracePeriod: {$set: gracePeriod},
      frequencyInterval: {
        $set: scheduleFormat.repeat.frequencyInterval || 1,
      },
      firstInstance: {
        $set: scheduleFormat.repeat.firstInstance || 1,
      },
      ifWeekend: {$set: scheduleFormat.ifWeekend},
    };

    if (rule.options.freq === RRule.WEEKLY) {
      const {byweekday} = rule.options;
      setState['repeats'] = {$set: 'weekly'};
      setState['daysOfWeek'] = {
        $set: RRULE_WEEKDAYS.map((day) => byweekday.includes(day.weekday)),
      };
    } else if (rule.options.freq === RRule.DAILY) {
      setState['repeats'] = {$set: 'daily'};
    } else if (
      rule.options.freq === RRule.MONTHLY &&
      // For some reason rrule uses `bynmonthday` instead of `bymonthday` if the index is negative when parsing a rule.
      // This doesn't apply when creating the rule though.
      isEqual(rule.options.bynmonthday, [-1])
    ) {
      setState['repeats'] = {$set: 'last-day-of-month'};
    } else if (rule.options.freq === RRule.YEARLY) {
      setState['repeats'] = {$set: 'yearly'};
    }

    // NOTE (kyle): this is for backwards compatibility with old daily intervals
    const {interval} = rule.options;
    if (interval && interval > 1) {
      setState.frequencyInterval = {$set: interval};
    }

    this.updateForId(eventId, {
      scheduleType: {$set: ScheduleEnum.Recurring},
      editRecurringSchedule: setState,
    });
  }

  getParsedRelativeField(
    scheduleFormat: Schedule,
    field: string,
  ): RelativeDate {
    let relativeDateField = {};
    const repeatScheduleFormat = scheduleFormat.repeat[field];
    if (repeatScheduleFormat) {
      const delayNum = parseInt(repeatScheduleFormat.value.day, 10);
      const delayOption = 'days';

      relativeDateField = sculpt(repeatScheduleFormat, {
        option: {$set: 'relative_date'},
        delayOption: {$set: delayOption},
        delayNum: {$set: delayNum},
      });
    } else {
      relativeDateField.option = 'calendar_date';
      //Defaults
      relativeDateField.field = '';
      relativeDateField.delayOption = 'days';
      relativeDateField.delayNum = 0;
    }
    return relativeDateField;
  }

  loadFieldChangeScheduleFormat(scheduleFormat: Schedule, eventId: ?string) {
    const {delayNum, delayOption, hour, period} = this.getDelayTime(
      scheduleFormat.fieldChange.delay,
    );
    const gracePeriod = this.getGracePeriod(scheduleFormat);

    const update = {
      scheduleType: {$set: ScheduleEnum.FieldChange},
      editFieldChangeSchedule: {
        $set: {
          type: ScheduleEnum.FieldChange,
          fieldName: scheduleFormat.fieldChange.name,
          fromValue: scheduleFormat.fieldChange.from,
          toValue: scheduleFormat.fieldChange.to,
          delayOption,
          delayNum,
          timeHour: hour,
          timePeriod: period,
          sendPerPlacement: scheduleFormat.fieldChange.send_per_placement,
          gracePeriod,
          ifWeekend: scheduleFormat.ifWeekend,
          blackout_window: scheduleFormat.blackout_window || {
            use_default: true,
          }, // by default it should be true for editing schedules
        },
      },
    };
    this.updateForId(eventId, update);
  }

  loadCreatedScheduleFormat(
    scheduleFormat: Schedule,
    scheduleType: string,
    eventId: ?string,
  ) {
    const createdEditSchedule =
      scheduleType === ScheduleEnum.PlacementCreated
        ? 'editPlacementCreatedSchedule'
        : 'editAudienceMemberCreatedSchedule';
    const apiScheduleType =
      scheduleType === ScheduleEnum.PlacementCreated
        ? 'placementCreated'
        : 'audienceMemberCreated';
    const {delayNum, delayOption, hour, period} = this.getDelayTime(
      scheduleFormat[apiScheduleType].delay,
    );
    const gracePeriod = this.getGracePeriod(scheduleFormat);

    const update = {
      $assign: {
        scheduleType,
        [createdEditSchedule]: {
          type: scheduleType,
          delayOption,
          delayNum,
          timeHour: hour,
          timePeriod: period,
          gracePeriod,
          ifWeekend: scheduleFormat.ifWeekend,
        },
      },
    };
    this.updateForId(eventId, update);
  }

  loadCampaignScheduleFormat(scheduleFormat: Schedule, eventId: ?string) {
    const campaignSchedule = scheduleFormat.campaignDateRange;

    const {hour: startTimeHour, period: startTimePeriod} =
      getHourPeriodFromTime(campaignSchedule.startTime);
    const {hour: endTimeHour, period: endTimePeriod} = getHourPeriodFromTime(
      campaignSchedule.endTime,
    );

    this.updateForId(eventId, {
      scheduleType: {$set: 'campaigndaterange'},
      editCampaignSchedule: {
        $assign: {
          type: 'campaigndaterange',
          startDate: campaignSchedule.startDate,
          endDate: campaignSchedule.endDate,
          shouldEndEarly: !!campaignSchedule.endDate,
          speedType: campaignSchedule.endDate
            ? 'maximum_until_date'
            : 'maximum_until_end',
          skipWeekend: campaignSchedule.skipWeekend,
          countPerDay: campaignSchedule.countPerDay,
          startTimeHour,
          startTimePeriod,
          endTimeHour,
          endTimePeriod,
        },
      },
    });
  }

  loadCustomScheduleFormat(scheduleFormat: Schedule, eventId: ?string) {
    this.updateForId(eventId, {
      scheduleType: {$set: ScheduleEnum.Custom},
      editCustomSchedule: {
        $set: {
          type: ScheduleEnum.Custom,
        },
      },
    });
  }

  loadExtras(
    scheduleFormat: Schedule,
    eventScheduleType: string,
    eventId: ?string,
  ) {
    this.updateForId(eventId, {
      [eventScheduleType]: {
        skipIf: {
          $set: {
            respondedTo: scheduleFormat.skipIf.respondedTo,
          },
        },
      },
    });
  }
  loadBranchSurveyScheduleDefaults(
    eventScheduleType: string,
    eventId: ?string,
  ) {
    this.updateForId(eventId, {
      [eventScheduleType]: {
        dateType: {
          $set: DateTypeEnum.Relative,
        },
      },
    });
  }

  // the api parser equivalent of parse; (backend data model) => frontend data model
  loadSchedule(
    scheduleFormat: Schedule,
    eventId: ?string,
    isBranchedEvent: ?boolean,
  ) {
    let eventScheduleType;
    this.setDefaultState(eventId);

    if (!scheduleFormat) {
      if (isBranchedEvent) {
        //branched events are represented as scheduled type on ui
        eventScheduleType = 'editScheduledSchedule';
        this.loadBranchSurveyScheduleDefaults(eventScheduleType, eventId);
      }
      //this.loadManualScheduleFormat(eventId);
    } else if (scheduleFormat.sendDate || scheduleFormat.fromDate) {
      eventScheduleType = 'editScheduledSchedule';
      this.loadDateScheduleFormat(scheduleFormat, eventId);
      this.loadReminders(scheduleFormat.reminders, eventId);
    } else if (scheduleFormat.dayOfWeek) {
      eventScheduleType = 'editScheduledSchedule';
      this.loadDateScheduleFormat(scheduleFormat, eventId);
      this.loadReminders(scheduleFormat.reminders, eventId);
    } else if (scheduleFormat.repeat) {
      eventScheduleType = 'editRecurringSchedule';
      this.loadRepeatScheduleFormat(scheduleFormat, eventId);
      this.loadReminders(scheduleFormat.reminders, eventId);
    } else if (scheduleFormat.fieldChange) {
      eventScheduleType = 'editFieldChangeSchedule';
      this.loadFieldChangeScheduleFormat(scheduleFormat, eventId);
      this.loadReminders(scheduleFormat.reminders, eventId);
    } else if (scheduleFormat.placementCreated) {
      this.loadCreatedScheduleFormat(
        scheduleFormat,
        ScheduleEnum.PlacementCreated,
        eventId,
      );
      eventScheduleType = 'editPlacementCreatedSchedule';
    } else if (scheduleFormat.audienceMemberCreated) {
      this.loadCreatedScheduleFormat(
        scheduleFormat,
        ScheduleEnum.AudienceMemberCreated,
        eventId,
      );
      eventScheduleType = 'editAudienceMemberCreatedSchedule';
    } else if (
      ['fromDateAtsTrigger', 'fieldChangeAtsTrigger'].some(
        (type) => scheduleFormat[type],
      )
    ) {
      this.loadCustomScheduleFormat(scheduleFormat, eventId);
    } else if (scheduleFormat.campaignDateRange) {
      this.loadCampaignScheduleFormat(scheduleFormat, eventId);
    }

    if (scheduleFormat && scheduleFormat.skipIf && eventScheduleType) {
      this.loadExtras(scheduleFormat, eventScheduleType, eventId);
    }
  }

  getRemindersFormat(reminders: RemindersDef): APIRemindersDef {
    const apiReminders = {};
    let clonedReminders = clone(reminders);

    clonedReminders = filter(clonedReminders, (reminder) => reminder.day !== 0);
    clonedReminders = orderBy(clonedReminders, (reminder) => reminder.day);
    clonedReminders = sortedUniqBy(clonedReminders, (reminder) =>
      String(reminder.type + reminder.day),
    );

    clonedReminders.forEach((reminder) => {
      /* Server is strict about keys  */
      const strippedReminder = {day: reminder.day};

      if (!apiReminders[reminder.type]) {
        apiReminders[reminder.type] = [strippedReminder];
      } else {
        apiReminders[reminder.type].push(strippedReminder);
      }
    });

    return apiReminders;
  }

  getApiGracePeriod(gracePeriod: ?number): ?ApiGracePeriod {
    return gracePeriod ? {hour: parseInt(gracePeriod, 10)} : null;
  }

  getDateScheduleFormat(schedule: DateScheduleDef): APIDateScheduleDef {
    const apiGracePeriod = this.getApiGracePeriod(schedule.gracePeriod);
    let scheduleFormat;

    if (schedule.dateType === 'specific_date') {
      const {militaryHour, minute} = getMilitaryHourAndMinute(
        schedule.timeHour,
        schedule.timePeriod,
      );
      const time = getTimeFromHourAndMinute(
        militaryHour,
        minute,
        DEFAULT_TIMEZONE,
      );
      scheduleFormat = {
        sendDate: {
          date: schedule.date,
          time,
          send_per_placement: schedule.sendPerPlacement,
        },
        display_timezone: DEFAULT_TIMEZONE,
        gracePeriod: apiGracePeriod,
      };
    } else if (schedule.dateType === 'dow_relative') {
      const dowNum =
        schedule.dowBeforeAfter === 'before'
          ? -schedule.dowOrdinal
          : schedule.dowOrdinal;
      const {militaryHour, minute} = getMilitaryHourAndMinute(
        schedule.timeHour,
        schedule.timePeriod,
      );
      const time = getTimeFromHourAndMinute(
        militaryHour,
        minute,
        DEFAULT_TIMEZONE,
      );
      scheduleFormat = {
        dayOfWeek: {
          num: dowNum,
          field: schedule.dowField,
          day: schedule.dowDay,
          time,
          send_per_placement: schedule.sendPerPlacement,
        },
        gracePeriod: apiGracePeriod,
        display_timezone: DEFAULT_TIMEZONE,
      };
    } else if (schedule.dateType === 'relative') {
      const {numValue, time} = getTzDayAndTimeFromRelativeSchedule(
        schedule.numValue,
        schedule.timeHour,
        schedule.timePeriod,
        schedule.beforeAfter,
        DEFAULT_TIMEZONE,
      );
      const values = [{[schedule.timeframe]: numValue, time}];
      if (schedule.timeframe === 'day' && numValue === 0) {
        values[0].send_til_eod = schedule.sendTilEod;
      }
      scheduleFormat = {
        fromDate: {
          values,
          field: schedule.relField,
          send_per_placement: schedule.sendPerPlacement,
        },
        gracePeriod: apiGracePeriod,
        display_timezone: DEFAULT_TIMEZONE,
        ifWeekend: schedule.ifWeekend,
      };
    } else {
      const {time} = getTzDayAndTimeFromRelativeSchedule(
        0,
        schedule.timeHour,
        schedule.timePeriod,
        schedule.beforeAfter,
        DEFAULT_TIMEZONE,
      );

      scheduleFormat = {
        fromDate: {
          values: [
            {
              day: 0,
              time,
              send_til_eod: schedule.sendTilEod,
            },
          ],
          field: schedule.onField,
          send_per_placement: schedule.sendPerPlacement,
        },
        gracePeriod: apiGracePeriod,
        display_timezone: DEFAULT_TIMEZONE,
        ifWeekend: schedule.ifWeekend,
      };
    }

    if (schedule.relTime) {
      const {hour, period} = schedule.relTime.defaultTime;
      const {militaryHour, minute} = getMilitaryHourAndMinute(hour, period);
      const defaultTime = getTimeFromHourAndMinute(
        militaryHour,
        minute,
        DEFAULT_TIMEZONE,
      );

      const values = scheduleFormat.fromDate?.values;

      scheduleFormat = {
        ...scheduleFormat,
        fromDate: {
          ...scheduleFormat.fromDate,
          values: values ? values.map((value) => omit(value, 'time')) : [],
          from_time: snake({
            ...schedule.relTime,
            defaultTime,
          }),
          ifWeekend: schedule.ifWeekend,
        },
      };
    }

    if (schedule.skipIf) {
      scheduleFormat.skipIf = schedule.skipIf;
    }

    return scheduleFormat;
  }

  getRecurringFormat(schedule: RecurringScheduleDef): APIRecurringScheduleDef {
    const rruleInput = {};
    const apiGracePeriod = this.getApiGracePeriod(schedule.gracePeriod);

    if (schedule.repeats === 'weekly') {
      rruleInput['freq'] = RRule.WEEKLY;
      rruleInput['byweekday'] = RRULE_WEEKDAYS.filter(
        (_, idx) => schedule.daysOfWeek[idx],
      );
    } else if (schedule.repeats === 'daily') {
      rruleInput['freq'] = RRule.DAILY;
    } else if (schedule.repeats === 'yearly') {
      rruleInput['freq'] = RRule.YEARLY;
    } else if (schedule.repeats === 'last-day-of-month') {
      rruleInput['freq'] = RRule.MONTHLY;
      rruleInput['bymonthday'] = [-1];
    }

    const {militaryHour, minute} = getMilitaryHourAndMinute(
      schedule.timeHour,
      schedule.timePeriod,
    );
    const time = getTimeFromHourAndMinute(
      militaryHour,
      minute,
      DEFAULT_TIMEZONE,
    );

    const rule = new RRule(rruleInput);
    const scheduleFormat = {
      repeat: {
        // Due to a bug in the RRule JS implementation, we need to put start_date in the api payload and
        // not within the rule, See: https://github.com/jkbrzt/rrule/issues/83
        startDate: schedule.startDate,
        time,
        rule: rule.toString(),
        send_per_placement: schedule.sendPerPlacement,
        frequencyInterval: schedule.frequencyInterval,
        firstInstance: schedule.firstInstance,
      },
      gracePeriod: apiGracePeriod,
      display_timezone: DEFAULT_TIMEZONE,
    };
    const relativeStartDate = this.normalizeRelativeField(
      schedule,
      'relativeStartDate',
    );
    if (relativeStartDate) {
      scheduleFormat.repeat.relativeStartDate = relativeStartDate;
    }

    if (['last-day-of-month', 'weekly'].includes(schedule.repeats)) {
      scheduleFormat.ifWeekend = undefined;
    } else {
      scheduleFormat.ifWeekend = schedule.ifWeekend;
    }

    if (!schedule.indefinitely) {
      // NOTE (gab): The API supposedly supports both options (maxIntances and
      // untilDate) but the UI currently presents them to the user as mutually
      // exclusive. This if-else-if ensures that only one or the other gets set.
      if (schedule.maxInstances) {
        scheduleFormat.repeat.maxInstances = schedule.maxInstances;
      } else if (schedule.untilDate) {
        scheduleFormat.repeat.untilDate = schedule.untilDate;
      }
    }

    if (schedule.skipIf) {
      scheduleFormat.skipIf = schedule.skipIf;
    }

    return scheduleFormat;
  }

  normalizeRelativeField(
    schedule: RecurringScheduleDef,
    field: string,
  ): ?Object {
    let relativeDateField = undefined;
    if (schedule[field].option === 'relative_date') {
      relativeDateField = {};
      const delay = {};
      if (schedule[field].delayOption === 'days') {
        delay.day = schedule[field].delayNum;
      }

      relativeDateField.field = schedule[field].field;
      relativeDateField.value = delay;
    }
    return relativeDateField;
  }

  getFieldChangeFormat(
    schedule: FieldChangeScheduleDef,
  ): APIFieldChangeScheduleDef {
    const delay = {};
    const {militaryHour, minute} = getMilitaryHourAndMinute(
      schedule.timeHour,
      schedule.timePeriod,
    );
    const time = getTimeFromHourAndMinute(
      militaryHour,
      minute,
      DEFAULT_TIMEZONE,
    );
    const apiGracePeriod = this.getApiGracePeriod(schedule.gracePeriod);

    if (schedule.delayOption === 'days') {
      delay.day = schedule.delayNum;
      delay.time = time;
    } else {
      delay.hour = schedule.delayNum;
    }

    const scheduleFormat = {
      fieldChange: {
        name: schedule.fieldName,
        delay,
        send_per_placement: schedule.sendPerPlacement,
      },
      gracePeriod: apiGracePeriod,
      display_timezone: DEFAULT_TIMEZONE,
    };

    const isEmptyish = (val) =>
      val === undefined ||
      (isString(val) && val.trim() === '') ||
      (Array.isArray(val) && val.length === 0);

    scheduleFormat.ifWeekend = schedule.ifWeekend;

    // Omit the 'from' and 'to' fields if not specified to indicate that they can be any value.
    if (!isEmptyish(schedule.fromValue)) {
      scheduleFormat.fieldChange.from = schedule.fromValue;
    }
    if (!isEmptyish(schedule.toValue)) {
      scheduleFormat.fieldChange.to = schedule.toValue;
    }

    if (schedule.skipIf) {
      scheduleFormat.skipIf = schedule.skipIf;
    }
    if (schedule.blackout_window) {
      scheduleFormat.blackout_window = {
        use_default: schedule.blackout_window.use_default,
      };
      if (!schedule.blackout_window.use_default) {
        scheduleFormat.blackout_window = schedule.blackout_window;
      }
    }

    return scheduleFormat;
  }

  getCreatedFormat(
    schedule: CreatedScheduleDef,
    scheduleType: string,
  ): APIPlacementCreatedScheduleDef | APIAudienceMemberCreatedScheduleDef {
    const delay = {};
    const {militaryHour, minute} = getMilitaryHourAndMinute(
      schedule.timeHour,
      schedule.timePeriod,
    );
    const time = getTimeFromHourAndMinute(
      militaryHour,
      minute,
      DEFAULT_TIMEZONE,
    );
    const apiGracePeriod = this.getApiGracePeriod(schedule.gracePeriod);
    if (schedule.delayOption === 'days') {
      delay.day = schedule.delayNum;
      delay.time = time;
    } else {
      delay.hour = schedule.delayNum;
    }

    const scheduleFormat = {
      [scheduleType]: {
        delay,
      },
      gracePeriod: apiGracePeriod,
      display_timezone: DEFAULT_TIMEZONE,
      ifWeekend: schedule.ifWeekend,
    };

    if (schedule.skipIf) {
      scheduleFormat.skipIf = schedule.skipIf;
    }

    return scheduleFormat;
  }

  getCampaignFormat(schedule: CampaignScheduleDef): APICampaignScheduleDef {
    const {
      startDate,
      countPerDay,
      startTimeHour,
      startTimePeriod,
      endTimeHour,
      endTimePeriod,
      skipWeekend,
    } = schedule;

    const scheduleFormat = {
      startDate,
      countPerDay,
      skipWeekend,
    };

    scheduleFormat.startTime = getTimeFromHourPeriod(
      startTimeHour,
      startTimePeriod,
    );
    scheduleFormat.endTime = getTimeFromHourPeriod(endTimeHour, endTimePeriod);

    if (schedule.shouldEndEarly && schedule.endDate) {
      scheduleFormat.endDate = schedule.endDate;
    }

    return {
      campaignDateRange: scheduleFormat,
    };
  }

  normalize(schedule: ScheduleDef, reminders: RemindersDef): APIScheduleDef {
    const formattedReminders = this.getRemindersFormat(reminders);
    switch (schedule.type) {
      case 'scheduled':
        const apiSchedule = this.getDateScheduleFormat(schedule);
        if (Object.keys(formattedReminders).length > 0) {
          apiSchedule.reminders = formattedReminders;
        }
        return apiSchedule;
      case 'recurring':
        const apiRecurringSchedule = this.getRecurringFormat(schedule);
        if (Object.keys(formattedReminders).length > 0) {
          apiRecurringSchedule.reminders = formattedReminders;
        }
        return apiRecurringSchedule;
      case 'fieldchange':
        const apiFieldChangeSchedule = this.getFieldChangeFormat(schedule);
        if (Object.keys(formattedReminders).length > 0) {
          apiFieldChangeSchedule.reminders = formattedReminders;
        }
        return apiFieldChangeSchedule;
      case 'placementcreated':
        return this.getCreatedFormat(schedule, 'placementCreated');
      case 'audiencemembercreated':
        return this.getCreatedFormat(schedule, 'audienceMemberCreated');
      case 'campaigndaterange':
        return this.getCampaignFormat(schedule);
      case 'manual':
        return null;
      default:
        return null;
    }
  }

  normalizeBranchedEventSchedule(schedule: ScheduleDef): APIScheduleDef {
    switch (schedule.type) {
      case 'scheduled':
        return this.getDateScheduleFormat(schedule);
      case 'manual':
        return null;
      default:
        return null;
    }
  }
}
