// @flow strict

type AmplitudeTrackingData = {
  agencyId: string,
  agencyName: string,
  atsSystem: string,
  platform: string,
  phoneNumberSetsCount: number,
  channel: string,
};

function getDefaultPhoneNumberSet(reduxStore) {
  // simulating the selectDefaultPhoneNumberSet selector
  const phoneNumberSets = reduxStore.chat.phoneNumberSets;
  const phoneNumberSetKeys = Object.keys(reduxStore.chat.phoneNumberSets);
  const phoneNumberSetValues = Object.values(reduxStore.chat.phoneNumberSets);
  const primaryPhoneNumberSet = phoneNumberSetValues.find(
    // $FlowFixMe[incompatible-use]
    (phoneNumberSet) => phoneNumberSet?.is_primary,
  );

  const firstPhoneNumberSet = phoneNumberSets[phoneNumberSetKeys[0]];

  const phoneNumberSet =
    reduxStore.chat.phoneNumberSets[
      reduxStore.chat.multiNumberInbox.selectedPhoneNumberSetId
    ] ||
    primaryPhoneNumberSet ||
    firstPhoneNumberSet;
  return phoneNumberSet;
}

export function getMessagingTrackingMetrics(): AmplitudeTrackingData {
  const reduxStore = window.__reduxStore.getState();
  const agency = reduxStore.agency.agency;
  const platform = reduxStore.requestHeaders.meta['url'].includes(
    'chrome-extension',
  )
    ? 'extension'
    : 'web';

  const phoneNumberSets = reduxStore.chat.phoneNumberSets;
  const phoneNumberSetsCount = Object.keys(phoneNumberSets).length;

  const defaultPhoneNumberSet = getDefaultPhoneNumberSet(reduxStore);
  const channel = defaultPhoneNumberSet?.['selected_channel'];

  return {
    agencyId: agency.id,
    agencyName: agency.name,
    atsSystem: agency.atsSystem,
    platform,
    phoneNumberSetsCount,
    channel,
  };
}
