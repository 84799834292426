// @flow strict
//$FlowFixMe
import type {Dispatch, ThunkAction} from 'src/reducers';
//$FlowFixMe
import * as reduxApi from 'src/utils/redux-api-v2';


export const markOnboardingComplete =
  (): ThunkAction<mixed> => (dispatch: Dispatch) =>
    dispatch(reduxApi.post('onboard/mark-onboarding-completed', {}));
