// @flow

import type {BeefreeDocument} from 'src/components/lib/beefree-editor';
import type {Brand} from 'src/types/brands';

import {getBeefreeFooterHtml, getBeefreeFooterHtmlOld} from 'src/utils/events';


export default function templateFactory(
  brandingSettings: ?Brand,
  isContentSubscriptionV2Footer: boolean,
  isTranscationalCategory: boolean,
  reason: ?string,
  agencyName: string,
): BeefreeDocument {
  const agencyColor = brandingSettings?.color || '#0068A5';
  const agencyLogoUrl =
    brandingSettings?.logo ||
    'https://s3.us-west-2.amazonaws.com/media.sense/email/logo/sense_logo.png';

  return {
    page: {
      body: {
        container: {
          style: {
            'background-color': '#f0f0f0',
          },
        },
        content: {
          computedStyle: {
            linkColor: `${agencyColor}`,
            messageBackgroundColor: 'transparent',
            messageWidth: '600px',
          },
          style: {
            color: '#000000',
            'font-family': 'Arial, \'Helvetica Neue\', Helvetica, sans-serif',
          },
        },
        type: 'mailup-bee-page-properties',
        webFonts: [],
      },
      description: '',
      rows: [
        {
          columns: [
            {
              'grid-columns': 12,
              modules: [
                {
                  descriptor: {
                    computedStyle: {
                      align: 'center',
                      hideContentOnMobile: false,
                    },
                    divider: {
                      style: {
                        'border-top': `6px solid ${agencyColor}`,
                        height: '0px',
                        width: '100%',
                      },
                    },
                    style: {
                      'padding-bottom': '0px',
                      'padding-left': '0px',
                      'padding-right': '0px',
                      'padding-top': '0px',
                    },
                  },
                  locked: false,
                  type: 'mailup-bee-newsletter-modules-divider',
                },
              ],
              style: {
                'background-color': 'transparent',
                'border-bottom': '0px solid transparent',
                'border-left': '0px solid transparent',
                'border-right': '0px solid transparent',
                'border-top': '0px solid transparent',
                'padding-bottom': '0px',
                'padding-left': '0px',
                'padding-right': '0px',
                'padding-top': '10px',
              },
            },
          ],
          container: {
            style: {
              'background-color': 'transparent',
              'background-image': 'none',
              'background-position': 'top left',
              'background-repeat': 'no-repeat',
            },
          },
          content: {
            computedStyle: {
              hideContentOnDesktop: false,
              hideContentOnMobile: false,
              rowColStackOnMobile: true,
            },
            style: {
              'background-color': 'transparent',
              'background-image': 'none',
              'background-position': 'top left',
              'background-repeat': 'no-repeat',
              color: '#000000',
              width: '600px',
            },
          },
          locked: false,
          type: 'one-column-empty',
        },
        {
          columns: [
            {
              'grid-columns': 12,
              modules: [
                {
                  descriptor: {
                    computedStyle: {
                      class: 'center fixedwidth',
                      hideContentOnMobile: false,
                      width: 120,
                    },
                    image: {
                      alt: 'Logo',
                      href: '',
                      src: `${agencyLogoUrl}`,
                    },
                    style: {
                      'padding-bottom': '20px',
                      'padding-left': '0px',
                      'padding-right': '0px',
                      'padding-top': '20px',
                      width: '100%',
                    },
                  },
                  locked: false,
                  type: 'mailup-bee-newsletter-modules-image',
                },
                {
                  descriptor: {
                    computedStyle: {
                      hideContentOnMobile: false,
                    },
                    style: {
                      'padding-bottom': '10px',
                      'padding-left': '10px',
                      'padding-right': '10px',
                      'padding-top': '10px',
                    },
                    text: {
                      computedStyle: {
                        linkColor: `${agencyColor}`,
                      },
                      html: '<div class="txtTinyMce-wrapper" style="font-family: Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; font-size: 12px; line-height: 14px;">\n<p style="font-size: 14px; line-height: 16px;">I\'m a new Text block ready for your content.&nbsp;</p>\n</div>',
                      style: {
                        color: '#555555',
                        'font-family': 'inherit',
                        'line-height': '120%',
                      },
                    },
                  },
                  type: 'mailup-bee-newsletter-modules-text',
                },
              ],
              style: {
                'background-color': 'transparent',
                'border-bottom': '0px solid transparent',
                'border-left': '0px solid transparent',
                'border-right': '0px solid transparent',
                'border-top': '0px solid transparent',
                'padding-bottom': '0px',
                'padding-left': '0px',
                'padding-right': '0px',
                'padding-top': '0px',
              },
            },
          ],
          container: {
            style: {
              'background-color': 'transparent',
              'background-image': 'none',
              'background-position': 'top left',
              'background-repeat': 'no-repeat',
            },
          },
          content: {
            computedStyle: {
              hideContentOnDesktop: false,
              hideContentOnMobile: false,
              rowColStackOnMobile: true,
            },
            style: {
              'background-color': '#FFFFFF',
              'background-image': 'none',
              'background-position': 'top left',
              'background-repeat': 'no-repeat',
              color: '#000000',
              width: '600px',
            },
          },
          locked: false,
          type: 'one-column-empty',
        },
        {
          columns: [
            {
              'grid-columns': 12,
              modules: [
                {
                  descriptor: {
                    computedStyle: {
                      height: 57,
                      hideContentOnMobile: false,
                      iconsDefaultHeight: 32,
                      iconsDefaultWidth: 32,
                      padding: '0 5px 5px 0',
                      width: 188,
                    },
                    iconsList: {
                      icons: [
                        {
                          image: {
                            alt: 'Facebook',
                            href: 'https://www.facebook.com/',
                            prefix: 'https://www.facebook.com/',
                            src: 'https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/facebook@2x.png',
                            title: 'Facebook',
                          },
                          name: 'facebook',
                          text: '',
                          type: 'follow',
                        },
                        {
                          image: {
                            alt: 'Twitter',
                            href: 'https://twitter.com/',
                            prefix: 'https://twitter.com/',
                            src: 'https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/twitter@2x.png',
                            title: 'Twitter',
                          },
                          name: 'twitter',
                          text: '',
                          type: 'follow',
                        },
                        {
                          image: {
                            alt: 'Instagram',
                            href: 'https://instagram.com/',
                            prefix: 'https://instagram.com/',
                            src: 'https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/instagram@2x.png',
                            title: 'Instagram',
                          },
                          name: 'instagram',
                          text: '',
                          type: 'follow',
                        },
                        {
                          image: {
                            alt: 'LinkedIn',
                            href: 'https://www.linkedin.com/',
                            prefix: 'https://www.linkedin.com/',
                            src: 'https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/linkedin@2x.png',
                            title: 'LinkedIn',
                          },
                          name: 'linkedin',
                          text: '',
                          type: 'follow',
                        },
                      ],
                    },
                    style: {
                      'padding-bottom': '10px',
                      'padding-left': '10px',
                      'padding-right': '10px',
                      'padding-top': '10px',
                      'text-align': 'center',
                    },
                  },
                  locked: false,
                  type: 'mailup-bee-newsletter-modules-social',
                },
              ],
              style: {
                'background-color': 'transparent',
                'border-bottom': '0px solid transparent',
                'border-left': '0px solid transparent',
                'border-right': '0px solid transparent',
                'border-top': '0px solid transparent',
                'padding-bottom': '0px',
                'padding-left': '0px',
                'padding-right': '0px',
                'padding-top': '0px',
              },
            },
          ],
          container: {
            style: {
              'background-color': 'transparent',
              'background-image': 'none',
              'background-position': 'top left',
              'background-repeat': 'no-repeat',
            },
          },
          content: {
            computedStyle: {
              hideContentOnDesktop: false,
              hideContentOnMobile: false,
              rowColStackOnMobile: true,
            },
            style: {
              'background-color': `${agencyColor}`,
              'background-image': 'none',
              'background-position': 'top left',
              'background-repeat': 'no-repeat',
              color: '#000000',
              width: '600px',
            },
          },
          locked: false,
          type: 'one-column-empty',
        },
        {
          columns: [
            {
              'grid-columns': 12,
              modules: [
                {
                  descriptor: {
                    computedStyle: {
                      hideContentOnMobile: false,
                    },
                    style: {
                      'padding-bottom': '5px',
                      'padding-left': '5px',
                      'padding-right': '5px',
                      'padding-top': '5px',
                    },
                    text: {
                      computedStyle: {
                        linkColor: `${agencyColor}`,
                      },
                      html: isContentSubscriptionV2Footer
                        ? getBeefreeFooterHtml()
                        : getBeefreeFooterHtmlOld(
                            isTranscationalCategory,
                            reason,
                            brandingSettings?.display_name || agencyName,
                          ),
                      style: {
                        color: '#787878',
                        'font-family': 'inherit',
                        'line-height': '150%',
                      },
                    },
                  },
                  locked: true,
                  type: 'mailup-bee-newsletter-modules-text',
                },
              ],
              style: {
                'background-color': 'transparent',
                'border-bottom': '0px solid transparent',
                'border-left': '0px solid transparent',
                'border-right': '0px solid transparent',
                'border-top': '0px solid transparent',
                'padding-bottom': '0px',
                'padding-left': '0px',
                'padding-right': '0px',
                'padding-top': '0px',
              },
            },
          ],
          container: {
            style: {
              'background-color': 'transparent',
              'background-image': 'none',
              'background-position': 'top left',
              'background-repeat': 'no-repeat',
            },
          },
          content: {
            computedStyle: {
              hideContentOnDesktop: false,
              hideContentOnMobile: false,
              rowColStackOnMobile: true,
            },
            style: {
              'background-color': 'transparent',
              'background-image': 'none',
              'background-position': 'top left',
              'background-repeat': 'no-repeat',
              color: '#000000',
              width: '600px',
            },
          },
          locked: false,
          type: 'one-column-empty',
          footer: true,
        },
      ],
      template: {
        name: 'template-base',
        type: 'basic',
        version: '2.0.0',
      },
      title: '',
    },
  };
}
