// @flow strict

import type {ReactRef} from 'src/types/react';

import * as React from 'react';

import makeClassNameComponent, {
  type ClassNameComponent,
} from 'src/utils/makeClassNameComponent';
import {
  useContextMenu,
  type ContextMenuConfig,
  type ContextMenuTuple,
} from './context-menu.jsx';
import HoverIconButton from 'src/components/lib/hover-icon-button';
import {
  HoverButton,
  type HoverButtonProps,
} from 'src/components/lib/hover-button/hover-button.jsx';
import MenuIcon from 'src/images/icons/options-icon.svg';
import ArrowRight from 'src/images/arrow-right.svg';

import css from './context-menu-2.css';


export function ContextMenu2({
  className,
  children,
  anchor,
  ...config
}: {
  ...ContextMenuConfig,
  children?: React.Node,
}): React.Node {
  const [componentRef, handleOpen, handleClose, isOpen] = useContextMenu2(
    config,
  );

  return (
    <HoverIconButton
      className={className}
      ref={componentRef}
      onClick={isOpen() ? handleClose : handleOpen}
    >
      {children || anchor || <MenuIcon />}
    </HoverIconButton>
  );
}

export function useContextMenu2(config: ContextMenuConfig): ContextMenuTuple {
  return useContextMenu({
    noArrow: true,
    noStyle: true,
    contextMenuClassName: css.menu,
    ...config,
  });
}

export function useContextMenu3(
  config: ContextMenuConfig,
): {
  ref: ReactRef<HTMLElement>,
  onClick: () => void,
} {
  const [ref, handleOpen, handleClose, isOpen] = useContextMenu2(config);
  return {
    ref,
    onClick: isOpen() ? handleClose : handleOpen,
  };
}

export function MenuOption({
  ElementType = 'div',
  ...props
}: HoverButtonProps): React.Node {
  return (
    <HoverButton
      {...props}
      className={css.menuOption}
      justify="start"
      ElementType={ElementType}
    />
  );
}

export const Menu: ClassNameComponent<> = makeClassNameComponent(css.menu);

export function SubMenuOption({
  children,
  ...props
}: {
  children: string,
  onMouseEnter?: ?(SyntheticMouseEvent<HTMLElement>) => mixed,
}): React.Node {
  return (
    <MenuOption {...props} isClickable={false}>
      <span>{children}</span> <ArrowRight />
    </MenuOption>
  );
}

export function SuperMenu({
  options,
}: {
  options: Array<
    | {
        label: string,
        action: () => mixed,
      }
    | {label: string, submenu: React.Node},
  >,
}): React.Node {
  const [subMenu, setSubMenu] = React.useState<?{
    content: React.Node,
    left: number,
    top: number,
  }>();

  return (
    <>
      {options.map((option) =>
        option.submenu ? (
          <SubMenuOption
            key={option.label}
            onMouseEnter={(event) => {
              const {
                currentTarget: {offsetLeft, offsetTop, offsetWidth},
              } = event;

              setSubMenu({
                left: offsetLeft + offsetWidth,
                top: offsetTop,
                content: option.submenu,
              });
            }}
          >
            {option.label}
          </SubMenuOption>
        ) : option.action ? (
          <MenuOption
            key={option.label}
            onClick={option.action}
            onMouseEnter={() => {
              setSubMenu(null);
            }}
          >
            {option.label}
          </MenuOption>
        ) : null,
      )}
      {subMenu && (
        <Menu
          style={{
            position: 'absolute',
            left: subMenu.left,
            top: subMenu.top,
          }}
        >
          {subMenu.content}
        </Menu>
      )}
    </>
  );
}
