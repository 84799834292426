// @flow strict
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {
  BulkAddStatus,
  ChangeProgramUserRoleRequest,
  GenericError,
  ProgramUserApiRequest,
  ProgramUsersResponse,
  SuccessApiResponse,
  UserAccessBulkRequest,
} from 'src/types/referral-v2';

// $FlowFixMe[nonstrict-import]
import {showApiError} from 'src/action-creators/modal';
import {getReferralAclData} from 'src/action-creators/referral-v2/referral-acl.js';
import {DEFAULT_ERROR} from 'src/action-creators/referral-v2/referral-jobs.js';
import {showToastMessage} from 'src/action-creators/toasts';
import {VARIANTS} from 'src/designSystem2021Components/toast';
// $FlowFixMe[untyped-import]
import {fetching, key} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api.js';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';


export const ENROLLED_PROGRAM_USERS = 'referral/enrolled-program-users';
export const ENROLLED_PROGRAM_USERS_ERROR =
  'referral/enrolled-program-users-error';

export const CHANGE_PROGRAM_USER_ROLE = 'referral/change-program-user-role';
export const CHANGE_PROGRAM_USER_ROLE_ERROR =
  'referral/change-program-user-role-error';

export const ADD_USER_ACCESS_BULK = 'referral/add-user-access-bulk';
export const ADD_USER_ACCESS_BULK_ERROR = 'referral/add-user-access-bulk-error';

export const REFERRAL_PROGRAM_USER_LOADING =
  'referralsrc/referral-program-user-loading';
export const REFERRAL_PROGRAM_USER = 'referralsrc/referral-program-user';
export const REFERRAL_PROGRAM_USER_ERROR =
  'referralsrc/referral-program-user-error';

export const REFERRAL_ADD_BULK_STATUS_ADMIN_VIEW =
  'referral/add-bulk-status-admin-view';
export const REFERRAL_ADD_BULK_STATUS_ADMIN_EDIT =
  'referral/add-bulk-status-admin-edit';

export const REFERRAL_ADD_BULK_STATUS_VIEWER_VIEW =
  'referral/add-bulk-status-viewer-view';

type ReferralBulkAddStatusAdminViewAction = {
  type: typeof REFERRAL_ADD_BULK_STATUS_ADMIN_VIEW,
  payload: BulkAddStatus,
};
type ReferralBulkAddStatusAdminEditAction = {
  type: typeof REFERRAL_ADD_BULK_STATUS_ADMIN_EDIT,
  payload: BulkAddStatus,
};
type ReferralBulkAddStatusViewerViewAction = {
  type: typeof REFERRAL_ADD_BULK_STATUS_VIEWER_VIEW,
  payload: BulkAddStatus,
};

type ReferralProgramUsersLoadingAction = {
  type: typeof REFERRAL_PROGRAM_USER_LOADING,
};

type ReferralProgramUsersAction = {
  type: typeof REFERRAL_PROGRAM_USER,
  payload: ?ProgramUsersResponse,
};

type ReferralProgramUsersErrorAction = {
  type: typeof REFERRAL_PROGRAM_USER_ERROR,
  payload: ?GenericError,
};

type ReceiveEnrolledUsers = {
  type: typeof ENROLLED_PROGRAM_USERS,
  payload?: ?ProgramUsersResponse,
};

type ReceiveEnrolledUsersError = {
  type: typeof ENROLLED_PROGRAM_USERS_ERROR,
  payload?: ?GenericError,
};

type ReceiveChangeProgramUserRoleError = {
  type: typeof CHANGE_PROGRAM_USER_ROLE_ERROR,
  payload?: ?GenericError,
};

type ReceiveAddUserAccessBulkError = {
  type: typeof ADD_USER_ACCESS_BULK_ERROR,
  payload?: ?GenericError,
};

export type ReferralProgramUsersActions =
  | ReferralProgramUsersAction
  | ReferralProgramUsersErrorAction
  | ReferralProgramUsersLoadingAction
  | ReceiveEnrolledUsers
  | ReceiveEnrolledUsersError
  | ReferralBulkAddStatusAdminViewAction
  | ReferralBulkAddStatusAdminEditAction
  | ReferralBulkAddStatusViewerViewAction
  | ReceiveChangeProgramUserRoleError
  | ReceiveAddUserAccessBulkError;

const recieveReferralProgramUser = (
  payload: ProgramUsersResponse,
): ReferralProgramUsersAction => ({
  type: REFERRAL_PROGRAM_USER,
  payload,
});

const getReferralProgramUsersLoading =
  (): ReferralProgramUsersLoadingAction => ({
    type: REFERRAL_PROGRAM_USER_LOADING,
  });

const getReferralProgramUsersError = (
  payload: ?GenericError,
): ReferralProgramUsersErrorAction => ({
  type: REFERRAL_PROGRAM_USER_ERROR,
  payload,
});

const getReferralBulkStatusViewerView = (
  payload: BulkAddStatus,
): ReferralBulkAddStatusViewerViewAction => ({
  type: REFERRAL_ADD_BULK_STATUS_VIEWER_VIEW,
  payload,
});
const getReferralBulkStatusAdminView = (
  payload: BulkAddStatus,
): ReferralBulkAddStatusAdminViewAction => ({
  type: REFERRAL_ADD_BULK_STATUS_ADMIN_VIEW,
  payload,
});
const getReferralBulkStatusAdminEdit = (
  payload: BulkAddStatus,
): ReferralBulkAddStatusAdminEditAction => ({
  type: REFERRAL_ADD_BULK_STATUS_ADMIN_EDIT,
  payload,
});

const getEnrolledProgramUsersError = (
  payload: ?GenericError,
): ReceiveEnrolledUsersError => ({
  type: ENROLLED_PROGRAM_USERS_ERROR,
  payload,
});

const getChangeProgramUserRoleError = (
  payload: ?GenericError,
): ReceiveChangeProgramUserRoleError => ({
  type: CHANGE_PROGRAM_USER_ROLE_ERROR,
  payload,
});

const getAddUserAccessBulkError = (
  payload: ?GenericError,
): ReceiveAddUserAccessBulkError => ({
  type: ADD_USER_ACCESS_BULK_ERROR,
  payload,
});

export const fetchBulkAddStatusViewerView: (
  programId: string,
  current_group: string,
  target_group: string,
) => ThunkAction<void> = flow(
  key(
    (programId: string, current_group: string, target_group: string) =>
      `referral/${programId}:${current_group}:${target_group}`,
  ),
)(
  (programId: string, current_group: string, target_group: string) =>
    (dispatch: Dispatch) => {
      return dispatch(
        reduxApi.get(
          `referral/${programId}/program/users/status?current_group=${current_group}&target_access=${target_group}`,
        ),
      ).then((response: BulkAddStatus) => {
        dispatch(getReferralBulkStatusViewerView(response));
      });
    },
);
export const fetchBulkAddStatusAdminView: (
  programId: string,
  current_group: string,
  target_group: string,
) => ThunkAction<void> = flow(
  key(
    (programId: string, current_group: string, target_group: string) =>
      `referral/${programId}:${current_group}:${target_group}`,
  ),
)(
  (programId: string, current_group: string, target_group: string) =>
    (dispatch: Dispatch) => {
      return dispatch(
        reduxApi.get(
          `referral/${programId}/program/users/status?current_group=${current_group}&target_access=${target_group}`,
        ),
      ).then((response: BulkAddStatus) => {
        dispatch(getReferralBulkStatusAdminView(response));
      });
    },
);
export const fetchBulkAddStatusAdminEdit: (
  programId: string,
  current_group: string,
  target_group: string,
) => ThunkAction<void> = flow(
  key(
    (programId: string, current_group: string, target_group: string) =>
      `referral/${programId}:${current_group}:${target_group}`,
  ),
)(
  (programId: string, current_group: string, target_group: string) =>
    (dispatch: Dispatch) => {
      return dispatch(
        reduxApi.get(
          `referral/${programId}/program/users/status?current_group=${current_group}&target_access=${target_group}`,
        ),
      ).then((response: BulkAddStatus) => {
        dispatch(getReferralBulkStatusAdminEdit(response));
      });
    },
);

export const fetchEnrolledProgramUsers: (
  programId: string,
) => ThunkAction<void> = flow(
  key(
    (programId: string) => `referral/${programId}/program/users?type=enrolled`,
  ),
  fetching(),
)((programId: string) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.get(`referral/${programId}/program/users?type=enrolled`),
  ).then(
    (response: ProgramUsersResponse) => {
      dispatch({type: ENROLLED_PROGRAM_USERS, payload: response});
    },
    (error) => {
      dispatch(
        getEnrolledProgramUsersError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const changeUserRole: (
  body: ChangeProgramUserRoleRequest,
) => ThunkAction<void> = flow()(
  (body: ChangeProgramUserRoleRequest) => (dispatch: Dispatch) => {
    const programId = body.program_id;
    return dispatch(reduxApi.post(`referral/programs/users/access`, body)).then(
      (response: {status: string, message?: string}) => {
        dispatch({type: CHANGE_PROGRAM_USER_ROLE, payload: response});
        dispatch(
          showToastMessage('Updated Successfully', {
            variant: VARIANTS.SUCCESS,
          }),
        );
        if (programId) {
          dispatch(fetchEnrolledProgramUsers(programId));
          dispatch(getReferralProgramUsers(programId));
          dispatch(getReferralAclData());
        }
      },
      (error) => {
        dispatch(
          getChangeProgramUserRoleError(
            error?.response?.errors || DEFAULT_ERROR,
          ),
        );
      },
    );
  },
);

export const addGlobalViewersAsViewRole =
  (programId: string, body: UserAccessBulkRequest): ThunkAction<mixed> =>
  (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.post(
        `referral/${programId ? programId : ''}/program/users`,
        body,
      ),
    )
      .then(() => {
        dispatch(fetchBulkAddStatusViewerView(programId, 'viewer', 'view'));
        dispatch(getReferralProgramUsers(programId));
        dispatch(fetchEnrolledProgramUsers(programId));
      })
      .catch((error) => {
        dispatch(
          getAddUserAccessBulkError(error?.response?.errors || DEFAULT_ERROR),
        );
        dispatch(showApiError(error, error.response.message));
      });
  };

export const addBulkAdminAsEditor =
  (programId: string, body: UserAccessBulkRequest): ThunkAction<mixed> =>
  (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.post(`referral/${programId}/program/users`, body),
    ).then(() => {
      dispatch(fetchBulkAddStatusAdminEdit(programId, 'admin', 'edit'));
      dispatch(getReferralProgramUsers(programId));
      dispatch(fetchEnrolledProgramUsers(programId));
    });
  };

export const addBulkAdminAsViewer =
  (programId: string, body: UserAccessBulkRequest): ThunkAction<mixed> =>
  (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.post(`referral/${programId}/program/users`, body),
    ).then(() => {
      dispatch(fetchBulkAddStatusAdminView(programId, 'admin', 'view'));
      dispatch(getReferralProgramUsers(programId));
      dispatch(fetchEnrolledProgramUsers(programId));
    });
  };

export const getReferralProgramUsers: (programId: string) => ThunkAction<void> =
  flow()((programId: string) => (dispatch: Dispatch) => {
    dispatch(getReferralProgramUsersLoading());
    return dispatch(
      reduxApi.get(`referral/${programId}/program/users?type=not_enrolled`),
    )
      .then((response: ProgramUsersResponse) => {
        dispatch(recieveReferralProgramUser(response));
      })
      .catch((error) => {
        dispatch(
          getReferralProgramUsersError(
            error?.response?.errors || DEFAULT_ERROR,
          ),
        );
      });
  });

export const updateProgramUserAccess: (
  payload: ProgramUserApiRequest,
) => ThunkAction<void> = flow()(
  (payload: ProgramUserApiRequest) => (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.post(`referral/programs/users/access`, payload),
    ).then(
      () => {
        dispatch(
          showToastMessage('Updated Successfully', {
            variant: VARIANTS.SUCCESS,
          }),
        );
      },
      (error) => {
        dispatch(showApiError(error, error.response.message));
      },
    );
  },
);

type AddUserGroupBulkAction = {
  type: typeof ADD_USER_ACCESS_BULK,
  payload: SuccessApiResponse,
};

export type ReferralProgramUserActions = AddUserGroupBulkAction;

export const addUserGroupBulkAction = (
  payload: SuccessApiResponse,
): AddUserGroupBulkAction => ({
  type: ADD_USER_ACCESS_BULK,
  payload,
});
