// @noflow

import type {Branch} from 'src/types/survey';
import type {SelectOptions} from 'src/components/lib/select/select.jsx';

import has from 'lodash/has';

import {conditionLabels} from 'src/components/workflow/event/content/branch/conditions.jsx';



export function getRatingResponseCondition(branch: Branch) {
  return getPresentProp(branch, 'responseRange') ||
    getPresentProp(branch, 'responseGte') ||
    getPresentProp(branch, 'responseLte');
}

function getPresentProp(object, prop) {
  return has(object, prop) && prop;
}

export function conditionText(branch: Branch) {
  const ratingResponseCondition = getRatingResponseCondition(branch);
  if (branch.responseRange) {
    return `Between ${branch.responseGte} and ${branch.responseLte} (inclusive)`;
  } else {
    return `${conditionLabels[ratingResponseCondition]} ${branch[ratingResponseCondition]}`;
  }
}

// filters out existing condition types
export function conditionOptionsForBranch(currentBranch: Branch, branches: Branch[], conditionLabels: {[key: string]: string}): SelectOptions {
  let labelKeys = Object.keys(conditionLabels);

  if (currentBranch.branchType === 'module_branch') {
    const otherBranchTypes = branches
      .filter(branch => branch.id !== currentBranch.id)
      .filter(branch => branch.branchType === 'module_branch')
      .filter(branch => branch.responseRange === undefined)
      .map(getRatingResponseCondition);
    if (otherBranchTypes) {
      labelKeys = labelKeys
        .filter(label => !otherBranchTypes.includes(label));
    }
  } else {
    // event branches see all values anyway
  }
  return labelKeys.map(value => ({value, label: conditionLabels[value]}));
}

export function ratingOptionsForBranch(branch: Branch, branches: Branch[], ratingOptions: SelectOptions) {

  if (branches.length === 0 && !getRatingResponseCondition(branch)) {
    return ratingOptions;
  }

  const otherBranches = branches.filter(({id, branchType}) => id !== branch.id && branchType === 'module_branch');

  return otherBranches.reduce((availableOptions: SelectOptions, branch: Branch) => {
    if (branch.responseGte && branch.responseLte) {
      const [conditionMin, conditionMax] = [branch.responseGte, branch.responseLte];
      const minIndex = availableOptions.findIndex(({value}) => value === conditionMin);
      availableOptions.splice(minIndex, conditionMax - conditionMin + 1);
      return availableOptions;
    } else if (branch.responseGte) {
      const minIndex = availableOptions.findIndex(({value}) => value === branch.responseGte);
      return availableOptions.slice(0, minIndex);
    } else if (branch.responseLte) {
      const maxIndex = availableOptions.findIndex(({value}) => value === branch.responseLte);
      return availableOptions.slice(maxIndex + 1);
    }
    return availableOptions;
  }, ratingOptions);
}
