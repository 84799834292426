// @flow

import * as React from 'react';

import Loading from 'src/components/lib/loading/loading.jsx';

import css from './more-button.css';


const MoreButton = ({
  loadingMore,
  ...props
}: {
  loadingMore: boolean,
  onClick: (evt: SyntheticEvent<>) => mixed,
}): React.Element<'button'> => (
  <button className={css.moreButton} disabled={loadingMore} {...props}>
    {loadingMore ? <Loading /> : 'More'}
  </button>
);

export default MoreButton;
