// @flow strict

import type {
  GenericError,
  Page,
  Pages,
  Leads,
  Forms,
  FormsDetail,
  UpdateFormData,
  FormEntityType,
  UpdateLeadData,
  EntityGroup,
  SubDomainDetail,
  LeadsFilter,
  LeadsCreateFilter,
  LeadForm,
  FilterViewCount,
  LeadFilterActionResponse,
  LeadsCreateFilterResponse,
  LeadsCreateFilterResponseItem,
  LeadsFilterActionResponse,
  BeFreeJson,
} from 'src/types/landing-page';

// Actions import Section
// Action Name, Action Name Loading , Action Name Error ->
import {
  LANDING_PAGES,
  LANDING_PAGES_ERROR,
  LANDING_PAGES_DETAIL,
  LANDING_PAGES_DETAIL_ERROR,
  FETCH_LEADS_LOADING,
  FETCH_LEADS,
  FETCH_LEADS_ERROR,
  FETCH_FORMS_LOADING,
  FETCH_FORMS,
  FETCH_FORMS_ERROR,
  FETCH_FORMS_DETAIL_LOADING,
  FETCH_FORMS_DETAIL,
  FETCH_FORMS_DETAIL_ERROR,
  UPDATE_STATE,
  UPDATE_LANDING_PAGE,
  UPDATE_LANDING_PAGE_ERROR,
  UPDATE_LANDING_PAGE_LOADING,
  GOTO_STEP,
  RESET,
  RESET_PAGE,
  CHECKING_URL_AVAILABILITY,
  LANDING_PAGES_ACTIONS_LOADING,
  LANDING_PAGES_ACTIONS,
  LANDING_PAGES_ACTIONS_ERROR,
  LANDING_PAGES_UPDATE_FORM_ACTION_LOADING,
  LANDING_PAGES_UPDATE_FORM_ACTION,
  LANDING_PAGES_UPDATE_FORM_ACTION_ERROR,
  FETCH_ATTRIBUTES_LOADING,
  FETCH_ATTRIBUTES,
  FETCH_ATTRIBUTES_ERROR,
  LANDING_PAGES_UPDATE_LEAD_ACTION_LOADING,
  LANDING_PAGES_UPDATE_LEAD_ACTION,
  LANDING_PAGES_UPDATE_LEAD_ACTION_ERROR,
  FETCH_ENTITY_GROUPS_LOADING,
  FETCH_ENTITY_GROUPS,
  FETCH_ENTITY_GROUPS_ERROR,
  CREATE_SUBDOMAIN,
  CREATE_SUBDOMAIN_ERROR,
  VERIFY_SUBDOMAIN,
  VERIFY_SUBDOMAIN_ERROR,
  CANDIDATE_SYNC,
  CANDIDATE_SYNC_ERROR,
  UPDATE_SELECTED_VIEW,
  UPDATE_SELECTED_VIEW_FILTER,
  LEADS_FILTER_CREATE,
  LEADS_FILTER_VIEW,
  LEADS_FILTER_VIEW_SAVE_APPLY,
  LEADS_FILTER_VIEW_COUNT,
  LEADS_FILTER_ACTION_RESPONSE,
  PAGES_DEFAULT_TEMPLATE,
} from 'src/action-creators/landing-page';
import type {
  LandingPageActions,
  LandingPageSteps,
} from 'src/action-creators/landing-page';


export const LandingPageCurrentState = {
  IDLE: 'idle',
  FETCHING_PAGES_LEADS: 'fetchingPageLeads',
  LOADING_PAGES_LEADS: 'LoadingPageLeads',
  FETCHING_PAGES_FORMS: 'fetchingPageForms',
  LOADING_PAGES_FORMS: 'LoadingPageForms',
  FETCHING_PAGES_FORMS_DETAIL: 'fetchingPageFormsDetail',
  LOADING_PAGES_FORMS_DETAIL: 'LoadingPageFormsDetail',
  FETCHING_PAGES_DETAIL: 'fetchingPageFormsDetail',
  LOADING_PAGE_DETAIL: 'LoadingPage',
  UPDATING_PAGES_ACTION_DETAIL: 'updatingPageActins',
  LOADING_PAGES_ACTION_DETAIL: 'LoadingUpdatingPageActins',
  CHECKING_URL_AVAILABILITY: 'checkingUrlAvailability',
  LOADING_PAGE_UPDATE_FORM_ACTION: 'LoadingPageUpdateFormAction',
  LOADING_ATTRIBUTE_ACTION: 'LoadingPageFormAttributeAction',
  LOADING_PAGE_UPDATE_LEAD_ACTION: 'LoadingPageUpdateLeadAction',
  LOADING_ENTITY_GROUP_ACTION: 'LoadingEntityGroupAction',
};

export type LandingPageState = {
  current_state: string,
  page: ?Page,
  pages: ?(Pages[]),
  pages_error: ?GenericError,
  pages_detail: ?Pages,
  pages_detail_error: ?GenericError,
  leads: ?(Leads[]),
  leads_error: ?GenericError,
  forms: ?Forms,
  forms_error: ?GenericError,
  formsDetail: ?FormsDetail,
  formsDetail_error: ?GenericError,
  step: LandingPageSteps,
  page_action: ?string,
  page_action_error: ?GenericError,
  update_form: ?UpdateFormData,
  update_form_error: ?GenericError,
  attributes: ?(FormEntityType[]),
  attributes_error: ?GenericError,
  update_lead: ?UpdateLeadData,
  update_lead_error: ?GenericError,
  entity_groups: ?EntityGroup,
  entity_groups_error: ?GenericError,
  create_landing_page_subdomain: ?SubDomainDetail,
  create_landing_page_subdomain_error: ?GenericError,
  verify_subdomain: ?string,
  verify_subdomain_error: ?GenericError,
  candidate_sync_status: ?string,
  candidate_sync_status_error: ?GenericError,
  leads_filter: LeadsFilter,
  leads_filter_create: ?LeadsCreateFilter,
  leads_filter_list?: ?LeadsCreateFilterResponseItem,
  leads_filter_save_apply: ?(LeadForm[]),
  leads_filter_count: ?FilterViewCount,
  leads_filter_action_response: ?LeadsFilterActionResponse,
  pages_default_template: ?BeFreeJson,
};

const pageInitialState = {
  name: '',
  description: '',
  entity_type: 'candidate_lead',
  form_ids: [],
  url: '',
};

export const leadsFilterInitialState = {
  selected_view: {
    name: 'All Leads',
    id: 0,
    selected_attributes: ([]: Array<string>),
  },
};

export const defaultAttributes = ['lead_status', 'candidate_id'];

const initialState = {
  current_state: LandingPageCurrentState.IDLE,
  page: pageInitialState,
  pages: null,
  pages_error: null,
  leads: null,
  leads_error: null,
  leads_filter: leadsFilterInitialState,
  forms: null,
  forms_error: null,
  formsDetail: null,
  formsDetail_error: null,
  step: 'details',
  pages_detail: pageInitialState,
  pages_detail_error: null,
  page_action: null,
  page_action_error: null,
  update_form: null,
  update_form_error: null,
  attributes: null,
  attributes_error: null,
  update_lead: null,
  update_lead_error: null,
  entity_groups: null,
  entity_groups_error: null,
  create_landing_page_subdomain: null,
  create_landing_page_subdomain_error: null,
  verify_subdomain: null,
  verify_subdomain_error: null,
  candidate_sync_status: null,
  candidate_sync_status_error: null,
  leads_filter_create: null,
  leads_filter_list: null,
  leads_filter_save_apply: null,
  leads_filter_count: null,
  leads_filter_action_response: null,
  pages_default_template: null,
};

export default (
  // $FlowFixMe[prop-missing]
  // $FlowFixMe[incompatible-type]
  state: LandingPageState = initialState,
  action: LandingPageActions,
): LandingPageState => {
  switch (action.type) {
    case LANDING_PAGES: {
      return {
        ...state,
        pages: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    }
    case LANDING_PAGES_ERROR:
      return {
        ...state,
        pages_error: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    case LANDING_PAGES_DETAIL: {
      // $FlowFixMe[exponential-spread]
      const data = {...state.pages_detail, ...action.payload};
      return {
        ...state,
        // $FlowFixMe[prop-missing]
        // $FlowFixMe[incompatible-return]
        pages_detail: {...data},

        current_state: LandingPageCurrentState.IDLE,
      };
    }
    case LANDING_PAGES_DETAIL_ERROR:
      return {
        ...state,
        pages_detail_error: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    case FETCH_LEADS: {
      return {
        ...state,
        leads: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    }
    case FETCH_LEADS_LOADING:
      return {
        ...state,
        current_state: LandingPageCurrentState.LOADING_PAGES_LEADS,
      };
    case FETCH_LEADS_ERROR:
      return {
        ...state,
        leads_error: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    case FETCH_FORMS: {
      return {
        ...state,
        forms: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    }
    case FETCH_FORMS_LOADING:
      return {
        ...state,
        current_state: LandingPageCurrentState.LOADING_PAGES_FORMS,
      };
    case FETCH_FORMS_ERROR:
      return {
        ...state,
        forms_error: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    case FETCH_FORMS_DETAIL: {
      return {
        ...state,
        formsDetail: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    }
    case FETCH_FORMS_DETAIL_LOADING:
      return {
        ...state,
        current_state: LandingPageCurrentState.LOADING_PAGES_FORMS,
      };
    case FETCH_FORMS_DETAIL_ERROR:
      return {
        ...state,
        formsDetail_error: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    case UPDATE_LANDING_PAGE:
      const updatePayload = action.payload;
      return {
        ...state,
        // $FlowFixMe[exponential-spread] there should be a better way to fix it
        // $FlowFixMe[prop-missing]
        page: {
          ...state.page,
          // $FlowFixMe[exponential-spread] there should be a better way
          ...updatePayload,
          // beefree_json: updatePayload?.beefree_json
          //   ? updatePayload.beefree_json
          //   : state.pages_default_template?.beefree_json,
        },
        current_state: LandingPageCurrentState.IDLE,
      };
    case UPDATE_LANDING_PAGE_LOADING:
      return {
        ...state,
        current_state: LandingPageCurrentState.LOADING_PAGE_DETAIL,
      };
    case UPDATE_LANDING_PAGE_ERROR:
      return {
        ...state,
        pages_detail_error: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    // $FlowFixMe[incompatible-return]
    case UPDATE_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case GOTO_STEP: {
      return {
        ...state,
        step: action.payload,
      };
    }
    case RESET: {
      return {
        ...initialState,
      };
    }
    case RESET_PAGE: {
      // $FlowFixMe[prop-missing]
      return {
        ...state,
        // $FlowFixMe[prop-missing]
        page: {...pageInitialState},
      };
    }
    case CHECKING_URL_AVAILABILITY: {
      return {
        ...state,
        current_state: LandingPageCurrentState.CHECKING_URL_AVAILABILITY,
      };
    }
    case LANDING_PAGES_ACTIONS: {
      return {
        ...state,
        page_action: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    }
    case LANDING_PAGES_ACTIONS_LOADING:
      return {
        ...state,
        current_state: LandingPageCurrentState.LOADING_PAGES_ACTION_DETAIL,
      };
    case LANDING_PAGES_ACTIONS_ERROR:
      return {
        ...state,
        page_action_error: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    case LANDING_PAGES_UPDATE_FORM_ACTION: {
      return {
        ...state,
        update_form: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    }
    case LANDING_PAGES_UPDATE_FORM_ACTION_LOADING:
      return {
        ...state,
        current_state: LandingPageCurrentState.LOADING_PAGE_UPDATE_FORM_ACTION,
      };
    case LANDING_PAGES_UPDATE_FORM_ACTION_ERROR:
      return {
        ...state,
        update_form_error: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };

    case FETCH_ATTRIBUTES: {
      return {
        ...state,
        attributes: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    }
    case FETCH_ATTRIBUTES_LOADING:
      return {
        ...state,
        current_state: LandingPageCurrentState.LOADING_ATTRIBUTE_ACTION,
      };
    case FETCH_ATTRIBUTES_ERROR:
      return {
        ...state,
        attributes_error: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    case LANDING_PAGES_UPDATE_LEAD_ACTION: {
      return {
        ...state,
        update_lead: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    }
    case LANDING_PAGES_UPDATE_LEAD_ACTION_LOADING:
      return {
        ...state,
        current_state: LandingPageCurrentState.LOADING_PAGE_UPDATE_LEAD_ACTION,
      };
    case LANDING_PAGES_UPDATE_LEAD_ACTION_ERROR:
      return {
        ...state,
        update_lead_error: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    case FETCH_ENTITY_GROUPS: {
      return {
        ...state,
        entity_groups: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    }
    case FETCH_ENTITY_GROUPS_LOADING:
      return {
        ...state,
        current_state: LandingPageCurrentState.LOADING_ENTITY_GROUP_ACTION,
      };
    case FETCH_ENTITY_GROUPS_ERROR:
      return {
        ...state,
        entity_groups_error: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    case CREATE_SUBDOMAIN: {
      return {
        ...state,
        create_landing_page_subdomain: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    }
    case CREATE_SUBDOMAIN_ERROR: {
      return {
        ...state,
        create_landing_page_subdomain_error: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    }
    case VERIFY_SUBDOMAIN: {
      return {
        ...state,
        verify_subdomain: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    }
    case VERIFY_SUBDOMAIN_ERROR: {
      return {
        ...state,
        verify_subdomain_error: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    }
    case CANDIDATE_SYNC: {
      return {
        ...state,
        candidate_sync_status: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    }
    case CANDIDATE_SYNC_ERROR:
      return {
        ...state,
        candidate_sync_status_error: action.payload,
        current_state: LandingPageCurrentState.IDLE,
      };
    case UPDATE_SELECTED_VIEW: {
      return {
        ...state,
        leads_filter: {
          selected_view: action.payload,
        },
      };
    }
    case UPDATE_SELECTED_VIEW_FILTER: {
      return {
        ...state,
        leads_filter: {
          selected_view: {
            ...state.leads_filter.selected_view,
            ['selected_attributes']: action.payload || [],
          },
        },
      };
    }
    case LEADS_FILTER_CREATE: {
      return {
        ...state,
        leads_filter_create: action.payload,
      };
    }
    case LEADS_FILTER_VIEW: {
      return {
        ...state,
        leads_filter_list: action.payload,
      };
    }

    case LEADS_FILTER_VIEW_SAVE_APPLY: {
      return {
        ...state,
        leads_filter_save_apply: action.payload,
      };
    }
    case LEADS_FILTER_VIEW_COUNT: {
      return {
        ...state,
        leads_filter_count: action.payload,
      };
    }

    case LEADS_FILTER_ACTION_RESPONSE: {
      return {
        ...state,
        // $FlowFixMe[incompatible-return]
        leads_filter_action_response: action.payload,
      };
    }
    case PAGES_DEFAULT_TEMPLATE: {
      return {
        ...state,
        pages_default_template: action.payload,
      };
    }

    default:
      return state;
  }
};
