//@flow strict

import sortBy from 'lodash/sortBy';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';

/**
 * Serializes a query object to be used as a unique identifier / key.
 * Sorts the keys to ensure that the same query object always returns
 * the same serialized string.
 *
 * NOTE (kyle): possible we'll eventually be able to replace this
 * with native URLSearchParams.
 */
export function serializeQuery(query: {[key: string]: mixed} = {}): string {
  const enc = encodeURIComponent;
  const keys = sortBy(Object.keys(query));
  return map(keys, (key) => {
    const val = query[key];
    if (!isUndefined(val)) {
      return `${enc(key)}=${enc(val)}`;
    }
  })
    .filter((pair) => pair)
    .join('&');
}
