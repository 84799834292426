// @flow strict
// $FlowFixMe[nonstrict-import]
import type {Dispatch, GetState, ThunkAction} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';
//$FlowFixMe[untyped-import]
import {camel, snake} from 'src/utils';
import omit from 'lodash/omit';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
import flow from 'lodash/flow';

import type {
  PaginationDetails,
  ContactList,
  CsvMapping,
  APIFilters,
  FileCsvUploadEntries,
  FileUploadAdditionalDetails,
  ContactListFilters,
  ContactDetails,
  EditContactListData,
} from 'src/types/contacts-genesis';
import {selectFileUploadAdditionalDetails} from 'src/selectors/contacts-genesis';


export const SET_CSV_FILE_UPLOAD_HEADERS = 'contacts/setCsvFileUploadHeaders';
export const SET_CSV_FILE_UPLOAD_ENTRIES = 'contacts/setCsvFileUploadEntries';
export const SET_CSV_FILE_UPLOAD = 'contacts/setCsvFileUpload';
export const SET_FILE_UPLOAD_ADDITIONAL_DETAILS =
  'contacts/setFileUploadAdditionalDetails';
export const SET_PAGINATION_DATA = 'contacts/setPaginationData';
export const RESET_PAGINATION_DATA = 'contacts/resetPaginationData';
export const SET_CONTACT_LISTS_DATA = 'contacts/setContactListData';
export const SET_ARCHIVED_CONTACT_LISTS_DATA =
  'contacts/setArchivedContactListData';
export const SET_CONTACT_LISTS_FOR_UPLOAD = 'contacts/setContactListsForUpload';
export const UPDATE_CONTACT_LIST_LIMIT_REACHED =
  'contacts/updateContactListLimitReached';
export const SET_CONTACT_LISTS_FILTER = 'contacts/setContactListsFilter';
export const UPDATE_CONTACT_LIST_DATA = 'contacts/updateContactListData';
export const SET_EDIT_CONTACT_LISTS_DATA = 'contacts/setEditContactListsData';
export const REMOVE_CONTACT_LIST_FROM_STATE =
  'contacts/removeContactListFromState';
export const SET_CONTACT_PHONE_NUMBER = 'contacts/setContactPhoneNumber';
export const SET_CONTACT_LIST_ID_FOR_CONTACT_CREATION =
  'contacts/setContactListIdForContactCreation';

export type ContactsGenesisActions =
  | SetCsvFileUploadHeadersAction
  | SetCsvFileUploadEntriesAction
  | SetPaginationDataAction
  | SetContactListsFilterAction
  | SetCsvFileUploadAction
  | SetFileUploadAdditionalDetailsAction
  | SetContactListsDataAction
  | UpdateContactListsDataAction
  | SetArchivedContactListsDataAction
  | SetContactListsForUploadAction
  | SetContactPhoneNumberAction
  | SetContactListIdForContactCreationAction;

export type SetContactPhoneNumberAction = {
  type: typeof SET_CONTACT_PHONE_NUMBER,
  payload: string,
};

export const setContactPhoneNumber = (
  payload: string,
): SetContactPhoneNumberAction => ({
  type: SET_CONTACT_PHONE_NUMBER,
  payload,
});

export type SetContactListIdForContactCreationAction = {
  type: typeof SET_CONTACT_LIST_ID_FOR_CONTACT_CREATION,
  payload: string,
};

export const setContactListIdForContactCreation = (
  payload: string,
): SetContactListIdForContactCreationAction => ({
  type: SET_CONTACT_LIST_ID_FOR_CONTACT_CREATION,
  payload,
});

export type SetContactListsFilterAction = {
  type: typeof SET_CONTACT_LISTS_FILTER,
  payload: ContactListFilters,
};

export const setContactListsFilter = (
  payload: ContactListFilters,
): SetContactListsFilterAction => ({
  type: SET_CONTACT_LISTS_FILTER,
  payload,
});

export type SetCsvFileUploadHeadersAction = {
  type: typeof SET_CSV_FILE_UPLOAD_HEADERS,
  payload: string[],
};

export const setCsvFileUploadHeaders = (
  payload: string[],
): SetCsvFileUploadHeadersAction => ({
  type: SET_CSV_FILE_UPLOAD_HEADERS,
  payload,
});

export type SetCsvFileUploadEntriesAction = {
  type: typeof SET_CSV_FILE_UPLOAD_ENTRIES,
  payload: FileCsvUploadEntries[],
};

export const setCsvFileUploadEntries = (
  payload: FileCsvUploadEntries[],
): SetCsvFileUploadEntriesAction => ({
  type: SET_CSV_FILE_UPLOAD_ENTRIES,
  payload,
});

export type SetCsvFileUploadAction = {
  type: typeof SET_CSV_FILE_UPLOAD,
  payload: File | null,
};

export const setCsvFileUpload = (
  payload: File | null,
): SetCsvFileUploadAction => ({
  type: SET_CSV_FILE_UPLOAD,
  payload,
});

export type SetFileUploadAdditionalDetailsAction = {
  type: typeof SET_FILE_UPLOAD_ADDITIONAL_DETAILS,
  payload: FileUploadAdditionalDetails,
};

export const setFileUploadAdditionalDetails = (
  payload: FileUploadAdditionalDetails,
): SetFileUploadAdditionalDetailsAction => ({
  type: SET_FILE_UPLOAD_ADDITIONAL_DETAILS,
  payload,
});

export type SetPaginationDataAction = {
  type: typeof SET_PAGINATION_DATA,
  payload: {key: string, paginationDetails: PaginationDetails},
};

export const setPaginationData = (
  key: string,
  paginationDetails: PaginationDetails,
): SetPaginationDataAction => ({
  type: SET_PAGINATION_DATA,
  payload: {key, paginationDetails},
});

export type ResetPaginationDataAction = {
  type: typeof RESET_PAGINATION_DATA,
};

export const resetPaginationData = (): ResetPaginationDataAction => ({
  type: RESET_PAGINATION_DATA,
});

export type SetContactListsDataAction = {
  type: typeof SET_CONTACT_LISTS_DATA,
  payload: {
    contactLists: ContactList[],
    update: boolean,
  },
};

export const setContactListsData = (payload: {
  contactLists: ContactList[],
  update: boolean,
}): SetContactListsDataAction => ({
  type: SET_CONTACT_LISTS_DATA,
  payload,
});

export type SetEditContactListsDataAction = {
  type: typeof SET_EDIT_CONTACT_LISTS_DATA,
  payload: EditContactListData,
};

export const setEditContactListsData = (
  payload: EditContactListData,
): SetEditContactListsDataAction => ({
  type: SET_EDIT_CONTACT_LISTS_DATA,
  payload,
});

export type UpdateContactListsDataAction = {
  type: typeof UPDATE_CONTACT_LIST_DATA,
  payload: {
    id: string,
    starred: boolean,
  },
};

export const updateContactListsData = (payload: {
  id: string,
  starred: boolean,
}): UpdateContactListsDataAction => ({
  type: UPDATE_CONTACT_LIST_DATA,
  payload,
});

export type SetArchivedContactListsDataAction = {
  type: typeof SET_ARCHIVED_CONTACT_LISTS_DATA,
  payload: {
    contactLists: ContactList[],
    update: boolean,
  },
};

export const setArchivedContactListsData = (payload: {
  contactLists: ContactList[],
  update: boolean,
}): SetArchivedContactListsDataAction => ({
  type: SET_ARCHIVED_CONTACT_LISTS_DATA,
  payload,
});

export type SetContactListsForUploadAction = {
  type: typeof SET_CONTACT_LISTS_FOR_UPLOAD,
  payload: ContactList[],
};

export const setContactListsForUpload = (
  payload: ContactList[],
): SetContactListsForUploadAction => ({
  type: SET_CONTACT_LISTS_FOR_UPLOAD,
  payload,
});

export type RemoveContactListFromStateAction = {
  type: typeof REMOVE_CONTACT_LIST_FROM_STATE,
  payload: string,
};

export const removeContactListFromState = (
  payload: string,
): RemoveContactListFromStateAction => ({
  type: REMOVE_CONTACT_LIST_FROM_STATE,
  payload,
});

export const getContacts =
  ({
    page,
    pageSize,
    searchTerm,
    sortBy,
    order,
    shareType,
  }: APIFilters): ThunkAction<> =>
  async (dispatch: Dispatch) => {
    try {
      const url = searchTerm
        ? `messages_v2/contacts/search/${searchTerm}`
        : 'messages_v2/contacts';
      const result = await dispatch(
        reduxApi.get(url, snake({pageSize, page, sortBy, order, shareType}), {
          apiPath: '/api/v2/',
        }),
      );
      dispatch(setPaginationData('contacts', {totalPages: result.pages}));
      return camel(result.contacts);
    } catch (err) {
      throw err;
    }
  };

export const getContactsForContactList =
  ({
    contactListId,
    page,
    pageSize,
    searchTerm,
    sortBy,
    order,
    shareType,
  }: APIFilters): ThunkAction<> =>
  async (dispatch: Dispatch) => {
    if (!contactListId) {
      return;
    }
    try {
      const url = searchTerm
        ? `messages_v2/contacts/search/${searchTerm}`
        : `messages_v2/contact_list/${contactListId}/contacts`;
      const apiParams = {
        pageSize,
        page,
        sortBy,
        order,
        shareType,
        contactListId,
      };
      const modifiedPayload = searchTerm
        ? apiParams
        : omit(apiParams, 'contactListId');
      const result = await dispatch(
        reduxApi.get(url, snake(modifiedPayload), {
          apiPath: '/api/v2/',
        }),
      );
      dispatch(setPaginationData('contacts', {totalPages: result.pages}));
      return camel(result.contacts);
    } catch (err) {
      throw err;
    }
  };

export const getRecentlyMessagedContacts =
  ({page, pageSize, searchTerm, sortBy, order}: APIFilters): ThunkAction<> =>
  async (dispatch: Dispatch) => {
    try {
      const url = searchTerm
        ? `messages_v2/contacts/search/${searchTerm}`
        : 'messages_v2/contacts';
      const result = await dispatch(
        reduxApi.get(
          url,
          snake({
            pageSize,
            page,
            recentlyMessagedOnly: true,
            sortBy,
            order,
          }),
          {
            apiPath: '/api/v2/',
          },
        ),
      );
      dispatch(
        setPaginationData('recentlyMessaged', {totalPages: result.pages}),
      );
      return camel(result.contacts);
    } catch (err) {
      throw err;
    }
  };

export const getContactListsToShowInUpload =
  (): ThunkAction<> => async (dispatch: Dispatch, getState: GetState) => {
    try {
      const result = await dispatch(
        reduxApi.get(
          'messages_v2/contact_lists',
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
      if (result) {
        const response = camel(result);
        const contactLists = camel(response.contactLists);
        dispatch(setContactListsForUpload(contactLists));
      }
    } catch (err) {
      throw err;
    }
  };

export const getContactLists: (APIFilters) => ThunkAction<> = flow(
  key(() => 'contacts-genesis/messages_v2/contact_lists'),
  fetching(),
)(
  ({
      page,
      pageSize,
      searchTerm,
      order,
      sortBy,
      shareType,
      agentId,
      includeGroups,
      appendData,
    }) =>
    async (dispatch: Dispatch) => {
      try {
        const url = searchTerm
          ? `messages_v2/contact_lists/search/${searchTerm}`
          : 'messages_v2/contact_lists';

        const omitFilters = [];
        if (shareType === 'all') {
          omitFilters.push('shareType');
        }
        if (agentId === 'all') {
          omitFilters.push('agentId');
        }
        if (includeGroups && includeGroups.length < 1) {
          omitFilters.push('includeGroups');
        }

        const payload = {
          pageSize,
          page,
          order,
          sortBy,
          shareType,
          agentId,
          includeGroups,
        };
        const modifiedPayload = omit(payload, omitFilters);
        const result = await dispatch(
          reduxApi.get(url, snake(modifiedPayload), {
            apiPath: '/api/v2/',
          }),
        );
        const response = camel(result);
        dispatch(
          setPaginationData('contactsList', {
            totalPages: response.pages,
            currentPage: page,
          }),
        );
        const update = appendData && page !== 1;
        dispatch(
          setContactListsData({contactLists: response.contactLists, update}),
        );
      } catch (err) {
        throw err;
      }
    },
);

export const getArchivedContactLists =
  ({page, pageSize, searchTerm, sortBy, order}: APIFilters): ThunkAction<> =>
  async (dispatch: Dispatch) => {
    try {
      const url = searchTerm
        ? `messages_v2/contact_lists/search/${searchTerm}`
        : 'messages_v2/contact_lists';
      const result = await dispatch(
        reduxApi.get(
          url,
          snake({
            pageSize,
            page,
            sortBy,
            order,
            includeArchived: true,
          }),
          {
            apiPath: '/api/v2/',
          },
        ),
      );
      const response = camel(result);
      dispatch(setPaginationData('archivedList', {totalPages: response.pages}));
      dispatch(
        setArchivedContactListsData({
          contactLists: response.contactLists,
          update: false,
        }),
      );
    } catch (err) {
      throw err;
    }
  };

export const getContactsForAContactList =
  ({page, pageSize, searchTerm}: APIFilters): ThunkAction<> =>
  async (dispatch: Dispatch) => {
    try {
      const url = searchTerm
        ? `messages_v2/contact_lists/search/${searchTerm}`
        : 'messages_v2/contact_lists';
      const result = await dispatch(
        reduxApi.get(url, snake({pageSize, page}), {
          apiPath: '/api/v2/',
        }),
      );
      const response = camel(result);
      dispatch(setPaginationData('contacts', {totalPages: response.pages}));
      return response;
    } catch (err) {
      throw err;
    }
  };

export const getContactListDetails =
  (id: string): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      const response = await dispatch(
        reduxApi.get(
          `messages_v2/contact_list/${id}`,
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
      return camel(response);
    } catch (err) {
      throw err;
    }
  };

export const createContactList =
  (fileId: string): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const details = selectFileUploadAdditionalDetails(getState());
    const {name, description, shareType} = details;
    const contactListIds = [];
    const fileIds = [fileId];
    const formattedPayload = {
      name,
      description,
      shareType,
      contactListIds,
      fileIds,
    };
    try {
      await dispatch(
        reduxApi.post(
          'messages_v2/contact_list',
          snake(formattedPayload),
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
    } catch (err) {
      throw err;
    }
  };

export const updateContactList =
  (file: File, CsvMapping: CsvMapping): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const formData = new FormData();
    const mapping = snake(CsvMapping);
    formData.append('contact_upload_file', file);
    formData.append('csv_field_map', JSON.stringify(mapping));
    const details = selectFileUploadAdditionalDetails(getState());
    const {contactListId} = details;
    try {
      await dispatch(
        reduxApi.put(
          `messages_v2/contact_list/${contactListId}`,
          // $FlowFixMe[class-object-subtyping]
          formData,
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
    } catch (err) {
      throw err;
    }
  };

export const addContactsToList =
  (contactListId: string, phoneNumbers: string): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      await dispatch(
        reduxApi.put(
          `messages_v2/contact_list/${contactListId}`,
          snake({phoneNumbers}),
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
    } catch (err) {
      throw err;
    }
  };

export const createContactListFromContacts =
  (
    name: string,
    description: string,
    shareType: string,
    phoneNumbers: string,
  ): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      const response = await dispatch(
        reduxApi.post(
          'messages_v2/contact_list',
          snake({name, description, shareType, phoneNumbers}),
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
    } catch (err) {
      throw err;
    }
  };

export const archiveContactLists =
  (contactListIds: string): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      await dispatch(
        reduxApi.put(
          `messages_v2/contact_list`,
          snake({contactListIds, archive: true}),
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
    } catch (err) {
      throw err;
    }
  };

export const unarchiveContactLists =
  (contactListIds: string): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      await dispatch(
        reduxApi.put(
          `messages_v2/contact_list`,
          snake({contactListIds, archive: false}),
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
    } catch (err) {
      throw err;
    }
  };

export const deleteContactLists =
  (contactListIds: string): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      await dispatch(
        reduxApi.put(
          `messages_v2/contact_list`,
          snake({contactListIds, delete: true}),
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
    } catch (err) {
      throw err;
    }
  };

export const starContactLists =
  (contactListId: string, starred: boolean): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      await dispatch(
        reduxApi.put(
          `messages_v2/contact_list/${contactListId}`,
          {starred},
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
      dispatch(updateContactListsData({id: contactListId, starred}));
    } catch (err) {
      throw err;
    }
  };

export const addContactToContactList =
  (contactListId: string, data: ContactDetails): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    const params = omit(data, 'permissionType');
    await dispatch(
      reduxApi.post(
        `messages_v2/contact_list/${contactListId}/contact`,
        snake(params),
        {},
        {apiPath: '/api/v2/'},
      ),
    );
  };

export const updateTitleAndDescOfContactLists =
  (contactListId: string, name: string, description: string): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      await dispatch(
        reduxApi.put(
          `messages_v2/contact_list/${contactListId}`,
          {name, description},
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
      // update the value in store for the list id - so it will be reflected
    } catch (err) {
      throw err;
    }
  };

export const uploadCsvFile =
  (file: File, CsvMapping: CsvMapping): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const details = selectFileUploadAdditionalDetails(getState());
    const {actionType} = details;
    const formData = new FormData();
    const mapping = snake(CsvMapping);
    formData.append('contact_upload_file', file);
    formData.append('csv_field_map', JSON.stringify(mapping));
    actionType === 'createList' &&
      formData.append('create_contacts', JSON.stringify(false));

    try {
      if (actionType === 'addToExistingList') {
        await dispatch(updateContactList(file, CsvMapping));
        return;
      }
      const response = await dispatch(
        reduxApi.post(
          'messages_v2/contacts/file/upload',
          // $FlowFixMe[class-object-subtyping]
          formData,
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
      if (actionType === 'createList') {
        await dispatch(createContactList(response.id));
      }
    } catch (err) {
      throw err;
    }
  };

export const searchContacts =
  (text: string): ThunkAction<mixed> =>
  async (dispatch: Dispatch) => {
    const response = await dispatch(
      reduxApi.get(
        `messages_v2/contacts/search/${text}`,
        {},
        {apiPath: '/api/v2/'},
      ),
    );
    return camel(response);
  };

export const getContact =
  (id: string): ThunkAction<mixed> =>
  async (dispatch: Dispatch) => {
    const response = await dispatch(
      reduxApi.get(`messages_v2/contact/${id}`, {}, {apiPath: '/api/v2/'}),
    );
    return camel(response);
  };

export const createContact =
  (data: ContactDetails): ThunkAction<mixed> =>
  async (dispatch: Dispatch) => {
    const params = snake(data);
    const response = await dispatch(
      reduxApi.post('messages_v2/contact', params, {}, {apiPath: '/api/v2/'}),
    );
    return camel(response);
  };

export const updateContact =
  (contactId: string, data: ContactDetails): ThunkAction<mixed> =>
  async (dispatch: Dispatch) => {
    const modifiedPayload = omit(data, ['permissionType', 'sharedGroupIds']);
    const params = snake(modifiedPayload);
    const response = await dispatch(
      reduxApi.put(
        `messages_v2/contact/${contactId}`,
        params,
        {},
        {apiPath: '/api/v2/'},
      ),
    );
    return camel(response);
  };

export const deleteContact =
  (id: string): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    await dispatch(
      reduxApi.del(`messages_v2/contact/${id}`, {}, {apiPath: '/api/v2/'}),
    );
  };

export const deleteContacts =
  (contactIds: string): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    await dispatch(
      reduxApi.del(`messages_v2/contacts`, snake({contactIds}), {
        apiPath: '/api/v2/',
      }),
    );
  };

export const removeContactsFromList =
  (contactListId: string, contactIds: string): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    await dispatch(
      reduxApi.put(
        `messages_v2/contact_list/${contactListId}/contacts`,
        snake({contactIds, action: 'remove'}),
        {},
        {
          apiPath: '/api/v2/',
        },
      ),
    );
  };

export const getSecurityGroups =
  (): ThunkAction<mixed> => async (dispatch: Dispatch) => {
    try {
      const result = await dispatch(reduxApi.get('security/groups'));
      return result;
    } catch (e) {
      throw e;
    }
  };
