// @flow strict-local

import type {Reducer} from 'redux';
import type {Account, AuthedUserAccount} from 'src/types/account';
import type {AccountsAction, AccountMerge} from 'src/action-creators/accounts';

import omit from 'lodash/omit';

import {
  RECEIVE,
  MERGE_ACCOUNT,
  RECEIVE_AUTHED_USER,
  RECEIVE_AUTHED_USER_PHONE,
  INPUT_AUTHED_USER_PHONE,
  REMOVE_ACCOUNT,
  SET_CONFIRMATION_MESSAGE,
  SET_AUTHED,
} from 'src/action-creators/accounts';


const notNullOrUndefined = (arg) => arg !== null && arg !== undefined;

const mergeAccounts = (
  data: AccountsById,
  accountsArg: AuthedUserAccount | Account | AccountMerge | Account[],
): AccountsById => {
  const accounts = Array.isArray(accountsArg) ? accountsArg : [accountsArg];
  // $FlowIssue unclear why this doesn't work
  return accounts.filter(notNullOrUndefined).reduce<AccountsById>(
    (byId, account) => {
      // $FlowFixMe upgraded flow
      byId[account.id] = {
        // $FlowFixMe[exponential-spread]
        ...byId[account.id],
        // $FlowFixMe upgraded flow
        ...account,
      };
      return byId;
    },
    {...data},
  );
};

export type AccountsById = {
  [id: string]: Account | AuthedUserAccount,
};

export type State = {
  data: AccountsById,
  isAuthed: boolean,
  authedUserId: ?string,
  authedUserPhone: ?string,
  authedUserPhoneError: ?string,
  authedUserPhoneFormInput: string,
  postAuthPath: ?string,
  confirmationMessage: string,
};

const initialState: State = {
  data: Object.freeze({}),
  isAuthed: false,
  authedUserId: null,
  authedUserPhone: null,
  authedUserPhoneError: null,
  authedUserPhoneFormInput: '',
  postAuthPath: null,
  confirmationMessage: '',
};

const reducer: Reducer<State, AccountsAction> = (
  state: State = initialState,
  action: AccountsAction,
): State => {
  switch (action.type) {
    case RECEIVE:
    case MERGE_ACCOUNT:
      return {
        ...state,
        data: mergeAccounts(state.data, action.payload),
      };

    case RECEIVE_AUTHED_USER:
      return {
        ...state,
        data: mergeAccounts(state.data, action.payload),
        authedUserId: action.payload.id,
        isAuthed: true,
      };

    case RECEIVE_AUTHED_USER_PHONE:
      return {
        ...state,
        authedUserPhone: action.payload,
        authedUserPhoneError: action.error ? action.payload : null,
      };

    case INPUT_AUTHED_USER_PHONE:
      return {
        ...state,
        authedUserPhoneFormInput: action.payload,
      };

    case REMOVE_ACCOUNT:
      return {
        ...state,
        data: omit(state.data, action.payload),
      };

    case SET_CONFIRMATION_MESSAGE:
      return {
        ...state,
        confirmationMessage: action.payload,
      };

    case 'accounts/setPostAuthPath':
      return {
        ...state,
        postAuthPath: action.payload,
      };

    case SET_AUTHED:
      return {
        ...state,
        isAuthed: action.payload,
      };
  }

  return state;
};
export default reducer;
