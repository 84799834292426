// @noflow

import type {RouteLocation} from 'src/types/router';
import type {RouteAction} from 'src/action-creators/route';

import {START, END} from 'src/action-creators/route';


export type RouteState = {
  location: RouteLocation,
  params: {[string]: string},
  status: number,
  loading: boolean,
};

const initialState = {
  location: {
    pathname: '/',
    query: {},
    search: '',
    hash: '',
  },
  params: {},
  status: 200,
  loading: false,
};

export default (
  state: RouteState = initialState,
  action: RouteAction,
): RouteState => {
  switch (action.type) {
    case START: {
      const {
        payload: {location, params = {}},
      } = action;
      return {...state, location, params, loading: true};
    }

    case END: {
      const {
        payload: {location, params = {}, status},
      } = action;
      return {...state, location, params, status, loading: false};
    }
  }

  return state;
};
