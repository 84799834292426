// @noflow

// TODO (kyle): rename this file as branch-settings

import typeof IndexStore from 'src/stores';
import type {Question, Branch} from 'src/types/survey';
import type {BranchInfo} from 'src/stores/event-creation';

import * as React from 'react';
import range from 'lodash/range';

import {editBranch, cancelBranch} from 'src/actions/branch';
import {saveBranch, deleteBranch} from 'src/actions/event-creation';
import {classify} from 'src/utils';
import {getRatingResponseCondition, conditionText} from 'src/utils/branch';

import BranchIcon from 'src/images/branch.svg';
import ActionList from 'src/components/lib/action-list';
import {
  RatingScaleConditions,
  MultipleChoiceConditions,
  conditionLabels,
} from './conditions.jsx';

import css from './branches.css';


type Props = {
  store: IndexStore,
  disabled: boolean,
  moduleData: Question,
  branches: Branch[],
  branchInfo: BranchInfo,
  ratingResponseCondition: string,
  canEdit: boolean,
};

const BranchSettings = ({
  store,
  moduleData,
  branchInfo: {branch, editorBranch, error},
  branches,
  disabled,
  canEdit,
}: Props) => {
  const ratingResponseCondition = getRatingResponseCondition(
    editorBranch || branch,
  );
  return (
    <div
      className={classify(css.branchRow, {
        [css.branchRowEdit]: editorBranch,
        [css.branchRowLocked]: !editorBranch && disabled,
      })}
    >
      <div className={css.branchLabel}>
        <BranchIcon />
        <span className={css.rowLabel}>Conditions</span>
      </div>
      {editorBranch ? (
        <div className={css.editor}>
          {moduleData.type === 'multiple_choice_survey_question' ? (
            <MultipleChoiceConditions
              store={store}
              branch={editorBranch}
              branches={branches}
              showErrors={error}
              choices={moduleData.choices}
              allowSelectMultiple={moduleData.allow_select_multiple}
            />
          ) : (
            (moduleData.type === 'rating_scale_survey_question' ||
              moduleData.type === 'nps_survey_question') && (
              // TODO (rng) - As per marcos' PR feedback: extract ratingOptions (and options above) into a separate var) both here and in event-branch.jsx
              <RatingScaleConditions
                senseStore={store}
                ratingOptions={range(
                  moduleData.minRating,
                  moduleData.maxRating + 1,
                ).map(rating => ({value: rating, label: rating}))}
                branch={editorBranch}
                branches={branches}
                showErrors={error}
                ratingResponseCondition={ratingResponseCondition}
              />
            )
          )}
          <ActionList className={css.actions}>
            <button onClick={() => deleteBranch(store)}>Delete</button>
            <button onClick={() => cancelBranch(store)}>Cancel</button>
            <button
              className={css.actionSave}
              onClick={() => saveBranch(store)}
            >
              Save
            </button>
          </ActionList>
        </div>
      ) : (
        <div className={css.locked}>
          {moduleData.type === 'multiple_choice_survey_question' && (
            <span>
              {conditionLabels.responseIn} {branch.responseIn.join(', ')}
            </span>
          )}
          {['rating_scale_survey_question', 'nps_survey_question'].includes(
            moduleData.type,
          ) && <span>{conditionText(branch)}</span>}
          {canEdit && !disabled && (
            <button
              className={css.editButton}
              onClick={() => editBranch(store)}
            >
              Edit
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default BranchSettings;
