// @flow strict

import * as React from 'react';


export default function FormattedNumber({
  value,
}: {
  value: number,
}): React.Element<'span'> {
  return <span>{value.toLocaleString()}</span>;
}
