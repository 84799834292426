// @flow
import type {JourneyType} from '../../analytics/chatbot/types.js';

import * as React from 'react';

import {FilterCard} from './';
import Checkbox from 'src/components/lib/checkbox';

import css from './journey-filters-card.css';


type Props = {
  onSave?: (e: SyntheticEvent<>) => mixed,
  onCancel?: (e: SyntheticEvent<>) => mixed,
  onJourneyTypeChange: (e: SyntheticEvent<HTMLInputElement>) => mixed,
  selectedJourneyTypes: string[],
  journeyTypes?: JourneyType[],
};

const defaultRecipientTypes = [];

const JourneyFilterCard = ({
  onSave,
  onCancel,
  journeyTypes = defaultRecipientTypes,
  onJourneyTypeChange,
  selectedJourneyTypes = [],
  cardLabel = 'Journey',
}: Props): React.Node => (
  <FilterCard header={cardLabel} onSave={onSave} onCancel={onCancel}>
    <section className={css.section}>
      <ul className={css.checklist}>
        {journeyTypes.map(({journey_id, journey_name}) => (
          <li key={journey_id}>
            <label className={css.label}>
              <Checkbox
                className={css.checkbox}
                name={String(journey_id)}
                checked={selectedJourneyTypes.includes(journey_id)}
                onChange={onJourneyTypeChange}
              />
              {journey_name}
            </label>
          </li>
        ))}
      </ul>
    </section>
  </FilterCard>
);

export default JourneyFilterCard;
