//@flow strict

import * as React from 'react';
import matchSorter from 'match-sorter';
// $FlowFixMe[nonstrict-import]
import type {ActionAttributeSchema} from 'src/types/action-based-targeting-meta';
import {classify} from 'src/utils/classify';

import {
  Table,
  BasicSingleCell,
  DoubleCell,
} from '@spaced-out/ui-design-system/lib/components/Table';
import {BodyMedium} from '@spaced-out/ui-design-system/lib/components/Text';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {EmptyState} from '@spaced-out/ui-design-system/lib/components/EmptyState';
import {CircularLoader} from '@spaced-out/ui-design-system/lib/components/CircularLoader';

import css from './abt-table.css';


const Variable = ({data, extras}) => {
  const isSelected = extras?.selectedKeys.includes(data.id);
  return (
    <DoubleCell
      title={data.label}
      subtitle={data.eventGroup}
      className={classify(css.variableSection, {[css.highlight]: isSelected})}
    />
  );
};

const Help = ({data, extras}) => {
  const isSelected = extras?.selectedKeys.includes(data.id);
  return (
    <BasicSingleCell
      className={classify(css.helpSection, {[css.highlight]: isSelected})}
    >
      <BodyMedium className={css.helpSectionContent} color="secondary">
        {data.descriptionOne}
      </BodyMedium>
    </BasicSingleCell>
  );
};

const Selection = ({data, extras}) => {
  const isSelected = extras?.selectedKeys.includes(data.id);
  return (
    <BasicSingleCell
      className={classify(css.selectionSection, {[css.highlight]: isSelected})}
    >
      <Button
        onClick={() => {
          extras?.selectedKeys.includes(data.id)
            ? extras?.handleSelect(
                extras.selectedKeys.filter((id) => id !== data.id),
              )
            : extras?.handleSelect([...extras.selectedKeys, data.id]);
        }}
        size="small"
        type="tertiary"
      >
        {extras?.selectedKeys.includes(data.id) ? 'Unselect' : 'Select'}
      </Button>
    </BasicSingleCell>
  );
};

const headers = [
  {
    label: 'Variable Name',
    key: 'label',
    sortable: true,
    render: Variable,
    className: css.secondaryBackground,
  },
  {label: '', key: 'help', render: Help, className: css.secondaryBackground},
  {
    label: '',
    key: 'id',
    render: Selection,
    sortable: false,
    className: css.secondaryBackground,
  },
];

type AbtTableProps = {
  query: string,
  selected: string[],
  handleSelect: (string[]) => void,
  filters: Map<string, Array<string>>,
  abtAttributes: ActionAttributeSchema[],
  isLoading: boolean,
};

const getFilteredItems = (tableItems, filters) => {
  const entityTypeFilter = filters.get('entityType') || [];
  if (entityTypeFilter.length === 0) {
    return tableItems;
  }
  return tableItems.filter((item) =>
    entityTypeFilter.includes(item.eventGroup),
  );
};

const AbtTable = ({
  query,
  selected,
  handleSelect,
  filters,
  abtAttributes,
  isLoading = false,
}: AbtTableProps): React.Node => {
  const tableItems = abtAttributes.map(
    ({label, value, eventGroup, descriptionOne, descriptionTwo}) => ({
      label,
      id: value,
      eventGroup,
      descriptionOne,
      descriptionTwo,
    }),
  );
  const filteredItems = getFilteredItems(tableItems, filters);
  const matchingEntries = matchSorter(filteredItems, query, {keys: ['label']});
  if (abtAttributes.length === 0 || isLoading) {
    return (
      <div className={css.loadingContainer}>
        <CircularLoader size="medium" />
      </div>
    );
  }
  return (
    <Table
      borderRadius="0px"
      classNames={{
        wrapper: classify(css.unsetBorders, {
          [css.unsetBottomBorder]:
            matchingEntries.length > 4 ||
            isLoading ||
            matchingEntries.length === 0,
        }),
      }}
      key={matchingEntries.length}
      headers={headers}
      tableHeaderClassName={css.stickyTableHeader}
      entries={matchingEntries}
      onSelect={handleSelect}
      emptyText={
        <EmptyState
          description="We cant find any items matching your search."
          title="Sorry! No result found"
          imageVariant="file"
        />
      }
      extras={{
        selectedKeys: [...selected],
        handleSelect,
      }}
    />
  );
};

export default AbtTable;
