// @noflow
import type {DynamicLabelExtra} from 'src/action-creators/dynamic-labels';

import * as React from 'react';
import Draft, {
  Modifier,
  EditorState,
  RichUtils,
  ContentBlock,
  ContentState,
} from 'draft-js';
import get from 'lodash/get';

import {dynamicLabelText, dynamicRegex, escapedRegex} from './utils.js';

import Modal from './modal.jsx';

import CloseIcon from 'src/images/close-icon.svg';

import css from './markdown-editor.css';


type ExtraLinkInputProps = {
  confirmLink: (data: Object) => mixed,
  label: string,
  fields: any[],
  handleClose: () => mixed,
  data: ?LinkSpecs,
};

export class ExtraLinkInputArea extends React.Component<ExtraLinkInputProps> {
  static defaultProps = {
    label: 'Link Destination: ',
  };

  form: ?HTMLFormElement;

  _handleForm(event: Event) {
    event.preventDefault();
    this.props.confirmLink({
      url: get(this.form, 'url.value'),
      text: get(this.form, 'text.value'),
    });
  }

  handleForm = this._handleForm.bind(this);

  componentDidMount() {
    setTimeout(() => this.form && this.form.elements[0].focus(), 0);
  }

  render() {
    const {label, fields, data} = this.props;
    const formFields = fields || [];

    return (
      <Modal handleClose={this.props.handleClose}>
        <span onClick={this.props.handleClose} className={css.closeButton}>
          <CloseIcon />
        </span>
        <form
          ref={(el) => {
            this.form = el;
          }}
          onSubmit={this.handleForm}
        >
          <div className={css.urlInputBox}>
            {formFields.map((field) => (
              <div key={field.key}>
                <label className={css.urlFormRow}>
                  <span>{field.label}</span>
                  <input
                    className={css.extraUrlInput}
                    type="text"
                    placeholder={field.placeholder}
                    defaultValue={data && field.key && data[field.key]}
                    name={field.key}
                  />
                </label>
              </div>
            ))}
            <button
              type="button"
              className={css.submitButton}
              onClick={this.handleForm}
            >
              Save
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

// $FlowFixMe -- get types from draft-js
function findLinkEntities(
  contentBlock: ContentBlock,
  callback,
  contentState: ContentState,
) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey != null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}

type LinkProps = {
  entityKey: any, // FIXME(marcos): import types from draft
  contentState: any,
  children: any,
  target?: string,
};

const Link = (props: LinkProps) => {
  const {contentState, entityKey, target = undefined} = props;
  const {url} = contentState.getEntity(entityKey).getData();
  return (
    <a href={url} className={css.link} target={target ? target : null}>
      {props.children}
    </a>
  );
};

const linkWithTarget = (target?: string) => (props: LinkProps) => (
  <Link target={target} {...props} />
);

export const LinkDecoratorEntry = (target?: string) => ({
  strategy: findLinkEntities,
  component: linkWithTarget(target),
});

// this method is called by the editor and will return either
// 1. the current link at the given selection and its offsets
// 2. the closest word at the current selection along with its offsets
// 3. the current selection, the characters it represents and their offsets
//
// in 1 and 2, the editor forces the selection of the words and returns the
// updated editorState. In 3, no selection is needed because it's already
// been selected by the user.
export type LinkData = {
  editorState: ?any, // a draft EditorState
  linkDetails: LinkSpecs,
};
export const prepareLinkSelection = (editorState: any): LinkData => {
  const sel = editorState.getSelection();
  const anchorKey = sel.getAnchorKey();
  const anchorOffset = sel.getAnchorOffset();
  const block = editorState.getCurrentContent().getBlockForKey(anchorKey);
  const blockText = block.getText();

  const specLink = getLinkAtPoint(editorState);
  if (specLink) {
    const newState = EditorState.forceSelection(
      editorState,
      sel.merge({
        anchorOffset: specLink.start,
        focusOffset: specLink.stop,
      }),
    );
    return {
      editorState: newState,
      linkDetails: specLink,
    };
  }
  // this only works if there is no link existing otherwise this will be buggy
  if (sel.isCollapsed()) {
    const [start, stop, word] = wordAtPoint(blockText, anchorOffset);
    const newSelection = sel.merge({
      anchorOffset: start,
      focusOffset: stop,
    });

    const newState = EditorState.forceSelection(editorState, newSelection);
    return {
      editorState: newState,
      linkDetails: {start, stop, text: word, url: null},
    };
  } else {
    const start = sel.getStartOffset();
    const stop = sel.getEndOffset();
    let fixedSelection;
    if (sel.getIsBackward()) {
      fixedSelection = sel.merge({
        anchorOffset: start,
        focusOffset: stop,
        isBackward: false,
      });
    } else {
      fixedSelection = sel;
    }
    const newState = EditorState.forceSelection(editorState, fixedSelection);
    const text = blockText.substring(start, stop);
    return {
      editorState: newState,
      linkDetails: {start, stop, text, url: null},
    };
  }
};

export const hasScheme = (url: string) => {
  // finds uri scheme using rfc 3986
  // https://tools.ietf.org/html/rfc3986#section-3.1
  const schemeRe = /^[a-z]([a-z0-9\+\-\.])*:\/\//;
  return schemeRe.test(url.trim().toLowerCase());
};

export const httpScheme = (url: string) => {
  const httpRe = /^https?\:\/\//;
  return httpRe.test(url.trim().toLowerCase());
};

export const hostLessScheme = (uri: string) => {
  const telRe = /^(tel|mailto)\:/;
  return telRe.test(uri.trim().toLowerCase());
};

export const maybeFixUrl = (url: string) => {
  const trimmedUrl = url.trim();
  const normalizedUrl = trimmedUrl.toLowerCase();
  // for dynamic vars, don't be clever, let the user do their thing
  if (normalizedUrl.startsWith('<')) {
    return trimmedUrl;
  }

  // we should leave tel:/mailto: schemes alone (they have their own uri scheme w/o //s)
  if (hostLessScheme(normalizedUrl)) {
    return trimmedUrl;
  }

  // for power users that throw custom-app://url-here?etc&whatevs we'll just ignore
  // their url if it's valid
  if (hasScheme(normalizedUrl) && !httpScheme(normalizedUrl)) {
    return trimmedUrl;
  }
  // if the url already starts with http or https, sendgrid will be happy
  if (httpScheme(normalizedUrl)) {
    return trimmedUrl;
  }
  if (normalizedUrl.startsWith('//') || normalizedUrl.startsWith('://')) {
    return `http://${trimmedUrl.split('//')[1]}`;
  }
  // otherwise, let's just put http:// in front of the url any be on our way
  return `http://${trimmedUrl}`;
};

export const createLink = (
  url: string,
  text: string,
  editorState: EditorState,
  dynamicLabels,
): EditorState => {
  const fixedUrl = maybeFixUrl(url);
  const linkData = getLabelMatch(url, dynamicLabels);
  const contentState = editorState.getCurrentContent();
  const contentStateWithLink = contentState.createEntity('LINK', 'MUTABLE', {
    url: fixedUrl,
    ...linkData,
  });
  const entityKey = contentStateWithLink.getLastCreatedEntityKey();
  const initialSelection = editorState.getSelection();
  const selection = initialSelection;
  const anchorOffset = selection.getStartOffset();

  const newContentState = Modifier.replaceText(
    contentStateWithLink,
    selection,
    text,
  );
  const newEditorState = Draft.EditorState.push(
    editorState,
    newContentState,
    'insert-characters',
  );
  const newSel = selection.merge({
    anchorOffset,
    focusOffset: anchorOffset + text.length,
  });
  return RichUtils.toggleLink(newEditorState, newSel, entityKey);
};

export const clearSurroundingEntity = (
  state: any,
  callback: (newState: any) => void,
) => {
  const anchorKey = state.getSelection().getAnchorKey();
  const anchorOffset = state.getSelection().getAnchorOffset();
  const currentContent = state.getCurrentContent();
  const block = currentContent.getBlockForKey(anchorKey);

  const text = block.getText();
  let offset = anchorOffset;
  let offsetText = text.slice(anchorOffset, anchorOffset + 1);
  if (offsetText === ' ' && anchorOffset > 0) {
    offset -= 1;
    offsetText = text.slice(offset, offset + 1);
  }

  const surroundingEntity = block.getEntityAt(offset);
  if (surroundingEntity) {
    block.findEntityRanges(
      (md) => md.getEntity() === surroundingEntity,
      (start, stop) => {
        const sel = state.getSelection().merge({
          anchorOffset: start,
          focusOffset: stop,
        });
        const newContent = Modifier.applyEntity(currentContent, sel, null);
        const newEditorState = EditorState.push(
          state,
          newContent,
          'apply-entity',
        );
        callback(newEditorState);
      },
    );
  }
};

export const wordAtPoint = (
  string: string,
  point: number,
): [number, number, string] => {
  const left = string.substring(0, point);
  const right = string.substring(point);

  const l = left.split('').reverse().indexOf(' ');
  const r = right.split('').indexOf(' ');
  const start = l > -1 ? point - l : 0;
  const end = r > -1 ? point + r : string.length;

  return [start, end, string.substring(start, end)];
};

export const isLinkAdjacent = (state: any) => {
  // returns true if you are at the edge of a link or within one
  const anchorOffset = state.getSelection().getAnchorOffset();
  const anchorKey = state.getSelection().getAnchorKey();
  const contentState = state.getCurrentContent();
  const entityBlock = contentState.getBlockForKey(anchorKey);

  const text = entityBlock.getText();
  let offset = anchorOffset;
  let offsetText = text.slice(anchorOffset, anchorOffset + 1);
  if (offsetText === ' ' && anchorOffset > 0) {
    offset -= 1;
    offsetText = text.slice(offset, offset + 1);
  }

  const entityKey = entityBlock.getEntityAt(offset);
  if (!entityKey) {
    return false;
  }

  const entity = entityKey && contentState.getEntity(entityKey);

  return entity && entity.getType() === 'LINK';
};

export type LinkSpecs = {
  start: number,
  stop: number,
  url: ?string,
  text: string,
};
export const getLinkAtPoint = (state: any): ?LinkSpecs => {
  const entityBlock = getCurrentBlock(state);
  const contentState = state.getCurrentContent();
  const anchorOffset = state.getSelection().getAnchorOffset();
  const entityId = entityBlock.getEntityAt(anchorOffset);
  if (entityId) {
    const data = contentState.getEntity(entityId).getData(); // the entity data, i.e. url
    const {start, stop} = entityRanges(state).entities[entityId];
    const text = entityBlock.getText().substring(start, stop);
    return {start, stop, url: data.url, text};
  }
};

export const getCurrentBlock = (state: any) => {
  const anchorKey = state.getSelection().getAnchorKey();
  return state.getCurrentContent().getBlockForKey(anchorKey);
};

type RangeEntity = {
  start: number,
  stop: number,
};
type RangeDB = {
  entityIds: string[],
  entities: {[entityId: string]: RangeEntity},
};
export const entityRanges = (state: any): RangeDB => {
  const charList = getCurrentBlock(state).getCharacterList().toJS();
  return charList.reduce(
    (db, curr, idx) => {
      const id = curr.entity;
      if (id != null) {
        if (db.entityIds.indexOf(id) === -1) {
          db.entityIds.push(id);
          db.entities[id] = {start: idx, stop: idx + 1};
        } else {
          db.entities[id].stop = idx + 1;
        }
      }
      return db;
    },
    {entityIds: [], entities: {}},
  );
};

const getLabelMatch = (text, labels: DynamicLabelExtra[]) => {
  let match;

  while ((match = dynamicRegex.exec(text)) !== null) {
    if (escapedRegex.exec(match[0]) === null) {
      const labelProps = dynamicLabelText(match[0]);

      if (labelProps.label && labels.length > 0) {
        const testLabel = labelProps.label.toLowerCase();
        const found = labels.find(
          ({value, label}) =>
            value?.toLowerCase() === testLabel ||
            label?.toLowerCase() === testLabel,
        );

        return found;
      }
    }
  }
};
