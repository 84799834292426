// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {
  ListTableEntry,
  ListApiResponse,
  ListTableQuery,
  AudienceListStatus,
} from 'src/types/lists';

import {batch} from 'react-redux';

import type {State as ListState} from 'src/reducers/lists';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';
import {parseFilterRule} from 'src/utils/list';
import {
  receivePublishedListIds,
  receiveUpdatedList,
} from 'src/action-creators/audience-list.old';
import {AnalyticsService} from 'src/analytics';
import {ENGAGE} from 'src/analytics/constants_event.js';


export const RECEIVE_LISTS = 'lists/receiveAll';
export const DELETE_LIST = 'lists/delete';
export const PUBLISH_LISTS = 'lists/publish';
export const ARCHIVE_LISTS = 'lists/archive';
export const RECEIVE_LIST_UPDATE = 'lists/receiveUpdate';
export const REFETCH = 'lists/refetch';
export const RESET_LIST_PAGE_STATE = 'lists/reset-list-page-state';

type ReceiveListsAction = {
  type: 'lists/receiveAll',
  payload: {total: number, lists: ListTableEntry[]},
};
type ReceiveListsUpdateAction = {
  type: 'lists/receiveUpdate',
  payload: {name: string, status: AudienceListStatus, id: string},
};
type DeleteListAction = {
  type: 'lists/delete',
  payload: {id: string},
};
type PublishListsAction = {
  type: 'lists/publish',
  payload: string[],
};
type ArchiveListsAction = {
  type: 'lists/archive',
  payload: string[],
};
type RefetchAction = {
  type: 'lists/refetch',
};

type ResetListPageStateAction = {
  type: 'lists/reset-list-page-state',
};

export type ListsActions =
  | ReceiveListsAction
  | DeleteListAction
  | PublishListsAction
  | ArchiveListsAction
  | ReceiveListsUpdateAction
  | RefetchAction
  | ResetListPageStateAction;

const receiveAllLists = (payload: {
  total: number,
  lists: ListTableEntry[],
}): ReceiveListsAction => ({
  type: 'lists/receiveAll',
  payload,
});

const receiveListUpdate = (payload: {
  name: string,
  status: AudienceListStatus,
  id: string,
}): ReceiveListsUpdateAction => ({
  type: 'lists/receiveUpdate',
  payload,
});

export const refetch = (): RefetchAction => ({
  type: 'lists/refetch',
});
//this action exists to let the list.jsx know
// when to reset its state.
export const resetListPageState = (): ResetListPageStateAction => ({
  type: 'lists/reset-list-page-state',
});

const doPublishLists = (ids: string[]): PublishListsAction => ({
  type: PUBLISH_LISTS,
  payload: ids,
});

export const getLists: (query: ListTableQuery) => ThunkAction<mixed> =
  (query) => (dispatch: Dispatch) =>
    dispatch(reduxApi.get('audience-filter', query)).then((payload) => {
      dispatch(
        receiveAllLists({
          total: payload.total_results,
          lists: payload.data,
        }),
      );
    });

export const publishLists: (listIds: string[]) => ThunkAction<mixed> =
  (listIds) => (dispatch: Dispatch) =>
    dispatch(
      reduxApi.post('audience-filter/publish', {audience_filter_ids: listIds}),
    ).then((_) => {
      batch(() => {
        dispatch(doPublishLists(listIds));
        dispatch(receivePublishedListIds(listIds));
      });
      return listIds;
    });
export const archiveLists: (listIds: string[]) => ThunkAction<mixed> =
  (listIds) => (dispatch) =>
    dispatch(
      reduxApi.post('audience-filter/archive', {audience_filter_ids: listIds}),
    );
export const duplicateList: (
  listId: string,
  payload: {name: string},
) => ThunkAction<mixed> = (listId, payload) => (dispatch) =>
  dispatch(reduxApi.post(`audience-filter/${listId}/duplicate`, payload));

export const createList: (payload: {
  name: string,
  filter: {...},
  entity_type: string,
  is_internal: boolean,
  target: string,
  life_cycle_goal: string,
  business_goal: string,
}) => ThunkAction<ListApiResponse> = (payload) => (dispatch, getState) =>
  dispatch(reduxApi.post(`audience-filter`, payload)).then((payload) => {
    const audienceList = getState().audienceListOld;
    const {rule_creation_type} = audienceList;
    const {id, name, status} = payload;
    const {all, rules} = parseFilterRule(payload.filter);
    AnalyticsService.track(ENGAGE.LIST_DRAFTED, {
      ...payload,
      rule_creation_method: rule_creation_type,
    });
    batch(() => {
      dispatch(receiveListUpdate({id, name, status}));
      dispatch(
        receiveUpdatedList({
          ...payload,
          all,
          rules,
        }),
      );
      dispatch(resetListPageState());
    });
    return payload;
  });

export const createAndPublishList: (payload: {
  name: string,
  filter: {...},
  entity_type: string,
  is_internal: boolean,
  target: string,
  life_cycle_goal: string,
  business_goal: string,
}) => ThunkAction<string> = (payload) => async (dispatch, getState) => {
  const audienceList = getState().audienceListOld;
  const {rule_creation_type} = audienceList;
  const list = await dispatch(reduxApi.post(`audience-filter`, payload));
  AnalyticsService.track(ENGAGE.LIST_CREATED, {
    ...payload,
    rule_creation_method: rule_creation_type,
    list_id: list.id,
  });
  await dispatch(
    reduxApi.post('audience-filter/publish', {audience_filter_ids: [list.id]}),
  );
  dispatch(resetListPageState());
  return list.id;
};

export const updateList: (
  id: string,
  payload: {
    name: string,
    filter?: {...},
  },
) => ThunkAction<ListApiResponse> = (id, payload) => (dispatch) =>
  dispatch(reduxApi.put(`audience-filter/${id}`, payload)).then((payload) => {
    const {id, name, status} = payload;
    const {all, rules} = parseFilterRule(payload.filter);
    dispatch(receiveListUpdate({id, name, status}));
    dispatch(
      receiveUpdatedList({
        ...payload,
        all,
        rules,
      }),
    );
    AnalyticsService.track(ENGAGE.LIST_EDITED, {...payload});
    return payload;
  });
