// @noflow

import type {AuthedUserAccount} from 'src/types/account';
import type {Contact} from 'src/types/contacts';
import type {Router} from 'src/types/router';
import type {Agency, AudienceMember} from 'src/api-parsers';
import type {State} from 'src/reducers';

import * as React from 'react';
import {connect, useSelector} from 'react-redux';
import {withRouter} from 'src/flux/withRouter.jsx';
import flow from 'lodash/flow';

import logger from 'src/utils/logger';

import {camel, getFullName} from 'src/utils';
import {selectCandidateEntityType} from 'src/selectors/ats-entities';
import {
  filterContactsForSearch,
  selectContactsAsArray,
} from 'src/selectors/contacts';
import {getCurrentAgent} from 'src/selectors/accounts';
import {selectCurrentPhoneCountry} from 'src/selectors/phone-number-sets';

import {TypeaheadWithExtras} from 'src/components/messaging/typeahead.jsx';
import Highlighter from 'src/components/lib/highlighter';
import {SyncedIcon} from 'src/components/contacts/name.jsx';
import withTypeahead, {
  useTypeahead,
} from 'src/components/typeahead/with-typeahead.jsx';
import useAgencyConfig from 'src/hooks/useAgencyConfig';
import {formatPhone} from 'src/utils/phone';

import css from './search.css';


const mapStateToProps = (state: State) => ({
  agency: state.agency.agency,
  contacts: selectContactsAsArray(state),
  agent: getCurrentAgent(state),
  typeaheadName: selectCandidateEntityType(state),
});

type SuggestedContact = {
  contact: Contact,
  disabled: boolean,
  // TODO (kyle): not sure why this was here
  // childRef?: any,
};

type SuggestionType = SuggestedContact | {audienceMember: AudienceMember};

type Props = {
  className?: string,
  agency: Agency,
  contacts: Contact[],
  disabledContacts: Contact[],
  disabledAudienceMembers: AudienceMember[],
  showAudienceMembers?: boolean,
  onClear: () => void,
  onSelect: (suggestion: SuggestionType) => any,
  onCreate: (value: string) => any,
  onChange: (value: string) => any,
  placeholder: string,
  icon: React.Node,
  router: Router,
  showViewAll: boolean,
  limit: number,
  agent: AuthedUserAccount,
  CreationSuggestion?: React.ComponentType<{value: string}>,
  // Pass value as prop if you want this to be a controlled component
  value?: string,
  childRef: any,
  inputElId: string,
  clearOnSelect?: boolean,
  hideDropdownOnSelect?: boolean,
  onSearch: (
    queries: string[],
    onError: (errpr: Error) => any,
    options: {hitsPerPage?: number},
  ) => Promise<Object[]>,
  searchResults: Object[],
  clearSearchResults: () => void,
};

class ContactSelector extends React.PureComponent<
  Props,
  {
    value: string,
  },
> {
  static defaultProps = {
    disabledContacts: [],
    disabledAudienceMembers: [],
    placeholder: 'Search contacts...',
    showViewAll: false,
    limit: 5,
  };

  state = {
    value: this.props.value || '',
  };

  isControlled = this.props.hasOwnProperty('value');

  static getDerivedStateFromProps(props, state) {
    return {
      value: 'value' in props ? props.value || '' : state.value,
    };
  }

  render() {
    const {
      className,
      contacts,
      placeholder,
      icon,
      showViewAll,
      limit,
      disabledContacts,
      disabledAudienceMembers,
      showAudienceMembers,
      onCreate,
      childRef,
      inputElId,
      hideDropdownOnSelect,
    } = this.props;
    const {value} = this.state;

    const filteredContacts = filterContactsForSearch(contacts, value);
    const disabledContactIds = disabledContacts.map(({id}) => id);

    let limited = filteredContacts.length > limit;
    const suggestedContacts: SuggestedContact[] = filteredContacts
      .slice(0, limit)
      .map((contact) => ({
        id: contact.id,
        contact,
        disabled: disabledContactIds.includes(contact.id),
      }));
    const results: SuggestionType[] = suggestedContacts;

    // TODO (kyle): include audience members
    // NOTE (kyle): only add audience members to results when there are fewer
    // contacts than the limit.
    if (!limited && showAudienceMembers) {
      // NOTE (kyle): filter out audience members with corresponding contacts.
      let audienceMemberSuggestions = this.props.searchResults
        .filter((audienceSearchResult) =>
          Boolean(
            audienceSearchResult.twilioNumber ||
              audienceSearchResult.primaryPhone,
          ),
        )
        .map((audienceSearchResult) => ({
          audienceMember: {
            ...camel(audienceSearchResult),
            id: audienceSearchResult.id,
            phone:
              audienceSearchResult.twilioNumber ||
              audienceSearchResult.primaryPhone,
          },
          disabled: disabledAudienceMembers.some(
            (disabledAudienceMember: AudienceMember) =>
              audienceSearchResult.id === disabledAudienceMember.id,
          ),
        }));

      limited =
        suggestedContacts.length + audienceMemberSuggestions.length > limit;
      if (limited) {
        audienceMemberSuggestions = audienceMemberSuggestions.slice(
          0,
          limit - results.length,
        );
      }

      results.push(...audienceMemberSuggestions);
    }

    return (
      <TypeaheadWithExtras
        inputElId={inputElId}
        className={className}
        childRef={childRef}
        value={value}
        onChange={this.handleChange}
        suggestions={results}
        CreationSuggestion={this.props.CreationSuggestion || CreationSuggestion}
        ViewAllSuggestion={ViewAllSuggestion}
        Suggestion={ContactSuggestion}
        onChooseSuggestion={this.handleSelect}
        onCreate={onCreate}
        onViewAll={showViewAll && limited ? this.handleViewAll : null}
        resolveOptionValue={this.resolveOptionValue}
        placeholder={placeholder}
        leftItem={icon}
        hideDropdownOnChooseSuggestion={hideDropdownOnSelect}
      />
    );
  }

  handleChange = (value: string) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }

    if (!this.isControlled) {
      this.setState({
        value,
      });
    }

    // NOTE (kyle): strip non-numeric characters and also search on that
    const possiblePhone = value.replace(/[^\d]/g, '');

    const queries = [possiblePhone, value]
      .map((val) => val.trim())
      .filter(Boolean);

    if (!queries.length) {
      this.props.clearSearchResults();
      return;
    }

    this.props.showAudienceMembers &&
      this.props.onSearch(
        queries,
        (error) => {
          logger.error(error);
          this.props.clearSearchResults();
        },
        {hitsPerPage: 3},
      );
  };

  handleSelect = (suggestion: SuggestionType) => {
    if (!suggestion.disabled) {
      this.props.onSelect(suggestion);

      if (this.props.clearOnSelect) {
        // TODO (wooju): should we clear by default?
        this.clear();
      }
    }
  };

  handleViewAll = () => {
    this.props.router.push({
      pathname: '/contacts',
      query: {
        search: this.state.value,
      },
    });

    if (!this.isControlled) {
      this.setState({
        value: '',
      });
    }
  };

  clear = () => {
    if (!this.isControlled) {
      this.setState({
        value: '',
      });
    }
  };

  resolveOptionValue = ({id}) => id;
}

const ConnectedContactSelector = flow(
  withTypeahead,
  connect(mapStateToProps),
  withRouter,
)(ContactSelector);
ConnectedContactSelector.defaultProps = {
  typeaheadType: 'entity',
};

const ContactSuggestion = ({
  option: {contact, audienceMember, disabled},
  search = '',
}: {
  option: SuggestionType,
  search: string,
}) => {
  const currentPhoneCountry = useSelector(selectCurrentPhoneCountry);
  const optOut = Boolean(contact && contact.optOut);
  const virtualContact = contact || audienceMember;
  return (
    <div
      className={
        disabled
          ? css.disabledSuggestion
          : optOut
          ? css.optedOutSuggestion
          : css.suggestion
      }
    >
      <div className={css.suggestionLeft}>
        <span className={css.name}>
          <Highlighter search={search}>
            {getFullName(virtualContact)}
          </Highlighter>
          {(audienceMember || (contact && contact.audienceMemberId)) && (
            <SyncedIcon />
          )}
          {optOut && <span> (opted out)</span>}
        </span>
        <Highlighter
          className={optOut ? css.optedOutNumber : css.number}
          search={search}
        >
          {formatPhone(
            virtualContact.phone ||
              virtualContact.phoneNumber ||
              virtualContact.primaryPhone,
            currentPhoneCountry,
          )}
        </Highlighter>
      </div>
      {disabled && <div className={css.suggestionRight}>Added</div>}
    </div>
  );
};

const CreationSuggestion = ({value}: {value: string}) => (
  <div className={css.creationSuggestion}>Create "{value}"</div>
);

const ViewAllSuggestion = () => (
  <div className={css.creationSuggestion}>View All</div>
);

export default ConnectedContactSelector;
