// @noflow

import type {PaginatedResponses} from 'src/types/report';
import type {ResponseAction} from 'src/action-creators/event-responses';

import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';

import {RECEIVE_FILTERED} from 'src/action-creators/event-responses';
import {getQueryHash} from 'src/selectors/event-responses';


export type EventResponseState = {
  filtered: {
    [hash: string]: PaginatedResponses,
  },
};

const initialState = {
  filtered: {},
};

export default (
  state: EventResponseState = initialState,
  action: ResponseAction,
): EventResponseState => {
  switch (action.type) {
    case RECEIVE_FILTERED: {
      const {
        payload: {
          query: {page, ...query},
          responses: {
            responses: newPage,
            modules: newModules,
            events: rawEvents,
            workflows: newWorkflows,
            hasMore,
          },
        },
      } = action;

      const key = getQueryHash(query);
      const data = state.filtered[key];

      const newEvents = resolveNpsEvents(rawEvents);

      let responses;
      let modules;
      let events;
      let workflows;
      if (page === undefined || page === 0) {
        responses = newPage;
        modules = newModules;
        events = newEvents;
        workflows = newWorkflows;
      } else {
        responses = [...data.responses, ...newPage];
        modules = {...data.modules, ...newModules};
        events = {...data.events, ...newEvents};
        workflows = {...data.workflows, ...newWorkflows};
      }

      const now = Date.now();
      const ttl = 2 * 60 * 1000;

      return {
        ...state,
        filtered: {
          // cull older data
          ...omitBy(state.filtered, data => now - data.fetchedAt > ttl),
          [key]: {
            page,
            hasMore,
            fetchedAt: Date.now(),
            responses,
            modules,
            events,
            workflows,
          },
        },
      };
    }
  }

  return state;
};

// NOTE (kyle): move these to the eventual event redux reducer file
const resolveNpsEvent = event => ({
  ...event,
  nps: event.modules.some(({type}) => type === 'nps_survey_question'),
});
const resolveNpsEvents = events => mapValues(events, resolveNpsEvent);
