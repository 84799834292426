// @flow

import type {MetaObject} from 'src/types/request-headers';


type SetRequestHeaders = {
  type: '@@requestHeaders/set_headers',
  payload: HeaderInfo,
};
type ClearRequestHeaders = {type: '@@requestHeaders/clear'};
type SetMeta = {
  type: '@@requestHeaders/set_meta',
  payload: MetaObject,
};

export type RequestHeaderAction =
  | SetRequestHeaders
  | ClearRequestHeaders
  | SetMeta;

export const SET_HEADERS = '@@requestHeaders/set_headers';
export const SET_META = '@@requestHeaders/set_meta';
export const CLEAR = '@@requestHeaders/clear';

type HeaderInfo = {
  headers: {[string]: string},
  subdomains: string[],
};
export const setRequestHeaders = (payload: HeaderInfo): SetRequestHeaders => ({
  type: SET_HEADERS,
  payload,
});

export const setRequestMeta = (payload: MetaObject): SetMeta => ({
  type: SET_META,
  payload,
});

export const clearClientHeaders = (): ClearRequestHeaders => ({type: CLEAR});
