// @flow strict-local

import type {TemplateSummaryType} from 'src/components/workflow/templates/types.js';
import type {PlaybookAction} from 'src/action-creators/playbooks';

import {RECEIVE, RECEIVE_ALL} from 'src/action-creators/playbooks';


type PlaybookState = {
  playbooks: Array<TemplateSummaryType>,
  playbookDetails: TemplateSummaryType,
};

const initialState = {
  playbooks: [],
  playbookDetails: {},
};

export default (
  // $FlowFixMe[incompatible-exact]
  state: PlaybookState = initialState,
  action: PlaybookAction,
): PlaybookState => {
  switch (action.type) {
    case RECEIVE_ALL:
      return {
        ...state,
        playbooks: action.payload,
      };

    case RECEIVE:
      const playbook = action.payload;
      return {
        ...state,
        playbookDetails: {
          ...state.playbookDetails,
          [playbook.id]: playbook,
        },
      };
  }

  return state;
};
