// @flow strict
import * as React from 'react';

import css from './progress-bar.css';
//$FlowFixMe
import {classify} from 'src/utils';


export type ProgressBarProps = {
  boxClassName?: string,
  progressClassName?: string,
  total: number,
  completed: number,
  labelClassName?: string,
};

const ProgressBar = ({
  boxClassName,
  progressClassName,
  total,
  completed,
  labelClassName,
}: ProgressBarProps): React.Node => (
  <div className={css.progressBar}>
    <div className={classify(css.container, boxClassName)}>
      <div
        style={{width: (completed / total) * 100 + '%'}}
        className={classify(css.progress, progressClassName)}
      ></div>
    </div>
    <div className={classify(css.label, labelClassName)}>
      {completed}/{total}
    </div>
  </div>
);

export default ProgressBar;
