// @flow strict-local

import {setError, type Validation} from 'src/utils/validation';
import {getISODate} from 'src/utils/date-time-2';

import {textHasPlaceholder} from 'src/components/workflow/templates/utils';


export function validateScheduledSchedule({
  dateType,
  date,
  onField,
  relField,
  dowField,
  timeType,
  relativeField,
}: {
  dateType?: string,
  date?: string,
  onField?: string,
  relField?: string,
  dowField?: string,
  timeType?: string,
  relativeField?: string,
}): ?Validation {
  let error;

  if (dateType === 'specific_date') {
    if (date) {
      error = setError(error, date >= getISODate(), 'date', 'INVALID');
    }
  }
  if (['on', 'relative', 'dow_relative'].includes(dateType)) {
    error = setError(
      error,
      [onField, relField, dowField].some(
        (field) => !!field && !textHasPlaceholder(field),
      ),
      'fieldName',
      'REQUIRED',
    );
  }
  if (timeType === 'relative') {
    error = setError(
      error,
      relativeField && !textHasPlaceholder(relativeField),
      'fieldName',
      'REQUIRED',
    );
  }

  return error;
}

export function validateRecurringSchedule({
  date,
  repeats,
  frequencyInterval,
  untilDate,
  relativeStartDateOption,
  relativeStartDateField,
  daysOfWeek,
}: {
  date?: string,
  repeats?: string,
  frequencyInterval?: number,
  untilDate?: string,
  relativeStartDateOption?: string,
  relativeStartDateField?: string,
  daysOfWeek?:
    | boolean[]
    | [boolean, boolean, boolean, boolean, boolean, boolean, boolean],
}): ?Validation {
  let error = null;
  if (date) {
    error = setError(error, date >= getISODate(), 'date', 'INVALID');
  }
  if (repeats === 'daily') {
    error = setError(error, frequencyInterval, 'frequencyInterval', 'INVALID');
  }
  if (repeats === 'weekly') {
    if (daysOfWeek && Array.isArray(daysOfWeek)) {
      error = setError(
        error,
        daysOfWeek.some((day) => day === true),
        'daysOfWeek',
        'INVALID',
      );
    }
  }
  if (untilDate) {
    error = setError(error, untilDate > getISODate(), 'untilDate', 'INVALID');
  }
  if (relativeStartDateOption === 'relative_date') {
    error = setError(
      error,
      relativeStartDateField && !textHasPlaceholder(relativeStartDateField),
      'recurringRelativeStartField',
      'REQUIRED',
    );
  }

  return error;
}

export function validateBatchedSchedule({
  date,
  speedType,
  endDate,
  countPerDay,
  maxCountPerDay,
}: {
  date?: string,
  speedType?: string,
  endDate?: string,
  countPerDay?: null | '' | number,
  maxCountPerDay?: null | number,
}): ?Validation {
  let error = null;
  if (countPerDay != null) {
    error = setError(error, countPerDay !== '', 'speed', 'REQUIRED');
  }
  if (countPerDay != null && countPerDay !== '' && maxCountPerDay != null) {
    error = setError(error, countPerDay <= maxCountPerDay, 'speed', 'INVALID');
  }
  if (date) {
    error = setError(error, date >= getISODate(), 'date', 'INVALID');
  }
  if (speedType === 'maximum_until_date' && endDate && date) {
    error = setError(error, endDate > date, 'date', 'INVALID');
  }

  return error;
}
export function validateFieldChangeSchedule({
  fieldName,
}: {
  fieldName?: string,
}): ?Validation {
  let error = null;

  error = setError(
    error,
    fieldName && !textHasPlaceholder(fieldName),
    'fieldName',
    'REQUIRED',
  );

  return error;
}
export function validateBranchedSurveySchedule({
  dateType,
  date,
}: {
  dateType?: string,
  date?: string,
}): ?Validation {
  let error;

  if (dateType === 'specific_date') {
    if (date) {
      error = setError(error, date >= getISODate(), 'date', 'INVALID');
    }
  }
  return error;
}
