// @noflow

import typeof IndexStore from 'src/stores/index';
import type {
  MultipleChoiceQuestion,
  CheckboxesQuestion,
} from 'src/types/survey';
import type {DynamicLabels} from 'src/action-creators/dynamic-labels';

import * as React from 'react';
import _get from 'lodash/get';

import {
  updateModule,
  addMultipleChoiceOption,
  updateMultipleChoiceOption,
  deleteMultipleChoiceOption,
} from 'src/actions/event-creation';
import mapProps from 'src/flux/map-props.jsx';
import {multipleChoiceOptionMaxLength} from 'src/components/workflow/event/content/module/editors/constants.js';

import AddButton from 'src/components/lib/add-button';
import RequiredToggle from 'src/components/workflow/event/content/module/required-toggle.jsx';
import TokenListInput from 'src/components/lib/token-list-input';
import Input from 'src/components/lib/error-input';
import DraftInput from 'src/components/lib/draft-input';
import Checkbox from 'src/components/lib/checkbox';

import moduleCss from './module.css';


const mapper = (props) => ({
  ...props,
  questionErrors: _get(props.error, 'question.errors'),
  choiceError: _get(props.error, 'choices') || [],
});

type Props = {
  fluxStore: IndexStore,
  moduleData: MultipleChoiceQuestion | CheckboxesQuestion,
  questionErrors?: string[],
  choiceError: {[number]: {value: {errors: string[]}}},
  canEdit: boolean,
  conditionalAlerts: boolean,
  dynamicLabels: DynamicLabels,
  multiEntityEnabled: boolean,
};

const MultipleChoiceModule = ({
  fluxStore,
  moduleData,
  questionErrors,
  choiceError,
  canEdit,
  conditionalAlerts,
  dynamicLabels,
  multiEntityEnabled,
}: Props) => {
  let placeholder, min, max;
  if (moduleData.type === 'checkboxes_survey_question') {
    placeholder =
      'e.g. Which of the following do you like? Select all that apply.';
    min = moduleData.min;
    max = moduleData.max;
  } else {
    placeholder = 'e.g. How was your day?';
    min = 1;
    max = 1;
  }

  return (
    <div className={moduleCss.generalModule_content}>
      <label className={moduleCss.generalModule_questionLabel}>
        <div className={moduleCss.labelWithInlineSelector}>Text</div>
        <DraftInput
          className={moduleCss.generalModule_input}
          value={moduleData.question}
          placeholder={placeholder}
          dynamicLabels={dynamicLabels}
          onChange={(value) => updateModule(fluxStore, {question: value})}
          showAddButton={multiEntityEnabled}
          multiEntityEnabled={multiEntityEnabled}
        />
      </label>

      <label className={moduleCss.multipleChoiceModule_multiSelectRow}>
        <div className={moduleCss.generalModule_questionLabel}>
          Allow recipient to select more than one option
        </div>
        <div className={moduleCss.multipleChoiceModule_groupedInputs}>
          <Checkbox
            checked={moduleData.allow_select_multiple}
            onChange={(event) => {
              updateModule(fluxStore, {
                allow_select_multiple: event.target.checked,
              });
            }}
          />
          <span>Yes</span>
        </div>
      </label>

      <div className={moduleCss.multipleChoiceModule_answers}>
        <div className={moduleCss.multipleChoiceModule_answers_title}>
          Answers:
        </div>

        {moduleData.choices.map(({value: choice, id}, index) => (
          <div className={moduleCss.multipleChoiceModule_optionRow} key={id}>
            <div
              className={moduleCss.multipleChoiceModule_optionRow_subtitle}
            >{`Option ${index + 1}:`}</div>
            <Input
              type="text"
              className={moduleCss.multipleChoiceModule_optionRow_input}
              placeholder="Please Enter an Option"
              value={choice}
              hideErrorText={true}
              onChange={(event) =>
                updateMultipleChoiceOption(fluxStore, index, event.target.value)
              }
              maxLength={multipleChoiceOptionMaxLength}
              errors={choiceError[id]?.value?.errors}
              disabled={!canEdit}
            />
            {moduleData.choices.length > 1 && canEdit && (
              <div className={moduleCss.optionRow_cell}>
                <button
                  className={moduleCss.option_close}
                  onClick={() => deleteMultipleChoiceOption(fluxStore, id)}
                >
                  X
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={moduleCss.addOption}>
        <AddButton
          text="Add Option"
          onClick={() => addMultipleChoiceOption(fluxStore)}
        />
      </div>
      <div className={moduleCss.multipleChoiceModule_row}>
        {conditionalAlerts && (
          <div className={moduleCss.multipleChoiceModule_alertChoices}>
            <div className={moduleCss.multipleChoiceModule_answers_title}>
              Send alert if user selects:
            </div>
            <TokenListInput
              disabled={!canEdit}
              containerClassName={
                moduleCss.multipleChoiceModule_alertChoices_select
              }
              values={moduleData.alertChoices
                .map((id) => ({
                  value: id,
                  label: moduleData.choices.find((choice) => choice.id === id)
                    ?.value,
                }))
                .filter(({label}) => label)}
              options={moduleData.choices.map((choice) => ({
                value: choice.id,
                label: choice.value,
              }))}
              onChange={(alertChoices) =>
                updateModule(fluxStore, {
                  alertChoices: alertChoices.map(({value: id}) => id),
                })
              }
              allowArbitraryValues={false}
              verticalStretch
            />
          </div>
        )}

        <RequiredToggle store={fluxStore} moduleData={moduleData} />
      </div>
      {/* NOTE (kyle): this code is VERY DATED. probably can't uncomment
      TODO (gab): checkboxes - uncomment when server is ready
    <div className={moduleCss.multipleChoiceModule_rules}>
      <div className={moduleCss.multipleChoiceModule_rules_title}>
        Rules
      </div>
      <div className={moduleCss.multipleChoiceModule_rules_inputs}>
        <Select className={moduleCss.multipleChoiceModule_select_restriction_type}
          clearable={false} searchable={false}
          value={(
            (max === null && '>=') ||
            (max === min && '=') ||
            (min === null && '<=')
          )}
          onChange={({value}) => {
            updateModule(store, {
              min: value === '<=' ? null : (max || min),
              max: value === '>=' ? null : (min || max),
            });
          }}
          options={[
            {label: 'Select at least', value: '>='},
            {label: 'Select at most', value: '<='},
            {label: 'Select exactly', value: '='},
          ]}
        />
        <Select className={moduleCss.multipleChoiceModule_select_restriction_number}
          clearable={false} searchable={false} options={
            moduleData.choices.map((_, index) => ({label: String(index + 1), value: index + 1}))
          }
          value={max || min}
          onChange={({value}) => {
            updateModule(store, {
              min: min && value,
              max: max && value,
            });
          }}
        />
      </div>
    </div>
    */}
    </div>
  );
};

export default mapProps(mapper)(MultipleChoiceModule);
