//@flow strict

import type {
  ContactListFilters,
  FileUploadAdditionalDetails,
  PaginationDetails,
  PaginationDetailsWithKey,
} from 'src/types/contacts-genesis';


export const initialPaginationDetails: PaginationDetails = {
  currentPage: 1,
  totalPages: 0,
  itemsPerPage: 10,
};

export const allInitialPaginationDetails: PaginationDetailsWithKey = {
  contacts: initialPaginationDetails,
  recentlyMessaged: initialPaginationDetails,
  contactsList: initialPaginationDetails,
  archivedList: initialPaginationDetails,
};

export const initialContactListFilters: ContactListFilters = {
  searchTerm: '',
  order: 'desc',
  sortBy: 'starred',
  agentId: 'all',
  shareType: 'all',
  includeGroups: [],
  sortByFilterName: 'starred',
};

export const initialAdditionalFileDetails: FileUploadAdditionalDetails = {
  actionType: 'uploadOnly',
  name: '',
  description: '',
  shareType: 'shared',
  contactListId: '',
};

export const TABLET_SCREEN_SIZE = 720;
