// @flow strict
// $FlowFixMe[nonstrict-import]
import type {ApiQuestion, ApiSMSRhetoricalQuestion} from 'src/types/survey';
// $FlowFixMe[nonstrict-import]
import type {SenseApiEvent, ClientEventType} from 'src/types/events';
// $FlowFixMe[nonstrict-import]
import {EventTypeEnum} from 'src/stores/event';


function eventContainsSmsJobModule(json: SenseApiEvent) {
  const modules: ApiQuestion[] = json['modules'];
  return modules.find(
    (module) =>
      //for sms_job, includes_job_match_variables=false is not allowed from the ui
      // we use this face to decide whether this event is of sms_job type because
      //we don't have separate event type for sms_job on the server. Its a client
      //side event construct.
      //$FlowFixMe[prop-missing]
      module['includes_job_match_variables'] === true,
  );
}
const parsers = {
  parse: {
    eventType: (json: SenseApiEvent): ClientEventType => {
      // This lets you determine on the client if you are dealing with
      // an (sms)nps survey or not because we don't actually have nps survey
      // types on the server, just surveys that happen to have nps modules...
      const modules: ApiQuestion[] = json['modules'];
      const questionTypes = modules.map(({module_type}) => module_type);
      const apiEventType = json['event_type'];

      const containsNpsModule = questionTypes.includes('nps_survey_question');
      //(diwakersurya)
      // This lets you determine on the client if you are dealing with
      // an (sms)job or not because we don't actually have sms_job
      // type on the server, just sms_message which have includes_job_match_variables field
      // which determines if it is sms_job event on client side
      if (
        apiEventType === EventTypeEnum.SMS_Message &&
        eventContainsSmsJobModule(json)
      ) {
        return EventTypeEnum.SMS_Job;
      }

      if (containsNpsModule) {
        if (apiEventType === EventTypeEnum.SMS_Survey) {
          return EventTypeEnum.SMS_NPS;
        }
        if (apiEventType === EventTypeEnum.Survey) {
          return EventTypeEnum.NPS;
        }
      }
      return apiEventType;
    },
  },
};

export default parsers;
