// @noflow
import * as React from 'react';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2.js';
import {useDispatch} from 'react-redux';
import {useApi} from 'src/hooks/useApi';
//$FlowFixMe[nonstrict-import]
import {useClipboard} from 'src/hooks/useClipboard';
import {Card} from '@spaced-out/ui-design-system/lib/components/Card';
import {Input} from '@spaced-out/ui-design-system/lib/components/Input';
import {
  FormLabelSmall,
  SubTitleMedium,
  SubTitleSmall,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {Textarea} from '@spaced-out/ui-design-system/lib/components/Textarea';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {Icon} from '@spaced-out/ui-design-system/lib/components/Icon';
import {CircularLoader} from '@spaced-out/ui-design-system/lib/components/CircularLoader';
import {
  Toast,
  ToastTitle,
  toastApi,
} from '@spaced-out/ui-design-system/lib/components/Toast';

import css from './sense-ai.css';


export default function SenseAi(): React.Node {
  const dispatch = useDispatch();
  const [jobTitle, setJobTitle] = React.useState('');
  const [outputJd, setOutputJd] = React.useState({});
  const [prompts, setPrompts] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const [_hasCopiedJd, handleJdCopy] = useClipboard(outputJd?.job_description);

  const handleGenerateJd = () => {
    setIsLoading(true);
    const toastJsx = (
      <Toast semantic="success">
        <ToastTitle> JD generated sucessfully for {jobTitle} </ToastTitle>
      </Toast>
    );

    dispatch(
      reduxApi.post('sense-ai/job-description/generate', {
        job_title: jobTitle,
        input_jd: outputJd?.job_description,
        prompts,
      }),
    )
      .then((json) => {
        setOutputJd(json);
        setIsLoading(false);
        setPrompts('');
        toastApi.show(toastJsx);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getPrompts = useApi<string[]>(
    'sense-ai/job-description/prompt-examples',
  );

  const getContentsEnhanncements = useApi<string[]>(
    'sense-ai/job-description/content-enhancements',
    {
      deps: [outputJd?.jd_id],
      shouldFetch: !!outputJd?.jd_id,
    },
  );
  const postEnhancement = useApi(
    `sense-ai/job-description/enhance/${outputJd?.jd_id}`,
    {
      method: 'POST',
      deps: [outputJd?.jd_id],
      shouldFetch: !!outputJd?.jd_id,
    },
  );
  const getMatrix = useApi<string[]>(
    `sense-ai/job-description/metrics/${outputJd?.jd_id}`,
    {
      deps: [outputJd?.jd_id],
      shouldFetch: !!outputJd?.jd_id,
    },
  );

  const getSuggestResponsibilitiesProps = useApi<string[]>(
    'sense-ai/job-description/suggest-prompts',
    {
      method: 'POST',
      data: {
        job_title: jobTitle,
        input_jd: outputJd?.job_description,
        get_responsibilities: true,
        get_skills: false,
      },
      deps: [outputJd?.job_description, jobTitle],
      shouldFetch: !!outputJd?.jd_id,
    },
  );
  const getSuggestSkillsProps = useApi<string[]>(
    'sense-ai/job-description/suggest-prompts',
    {
      method: 'POST',
      data: {
        job_title: jobTitle,
        input_jd: outputJd?.job_description,
        get_skills: true,
        get_responsibilities: false,
      },
      deps: [outputJd?.job_description, jobTitle],
      shouldFetch: !!outputJd?.jd_id,
    },
  );

  const getDefaultPercentageColor = (percentage) => {
    if (percentage <= 40) {
      return 'danger';
    } else if (percentage > 40 && percentage <= 80) {
      return 'warning';
    } else if (percentage > 80) {
      return 'success';
    }
  };

  return (
    <div className={css.container}>
      <div className={css.containerHeader}>
        <Card classNames={{wrapper: css.cardWrapper}}>
          <div className={css.spaceBetween}>
            <Input
              classNames={{box: css.jdName}}
              placeholder={'Job Title'}
              onChange={(e) => setJobTitle(e.target.value)}
            ></Input>
            {getMatrix.result?.jd_completion_score && (
              <SubTitleMedium
                color={getDefaultPercentageColor(
                  getMatrix.result?.jd_completion_score,
                )}
                className={css.percentComplete}
              >
                {getMatrix.result?.jd_completion_score} % Complete
              </SubTitleMedium>
            )}
            <div className={css.btn}>
              <Button
                type="secondary"
                onClick={() => handleJdCopy()}
                disabled={!outputJd?.job_description}
              >
                Copy
              </Button>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card classNames={{wrapper: css.cardWrapper}}>
          <div className={css.leftItems}>
            <div className={css.promptBox}>
              <Textarea
                label="Prompt"
                placeholder=""
                size="medium"
                onChange={(e) => setPrompts(e.target.value)}
                value={prompts}
              />
              <Button
                iconRightName="bolt"
                disabled={!jobTitle || isLoading}
                onClick={() => handleGenerateJd()}
              >
                <div className={css.generateJdButton}>
                  {outputJd?.job_description ? 'Enhance JD' : 'Generate JD'}
                  {isLoading && <CircularLoader size="small" />}
                </div>
              </Button>
            </div>
            <div className={css.suggestions}>
              {outputJd?.job_description ? (
                <>
                  <Sections
                    setPrompts={setPrompts}
                    prompts={prompts}
                    title="Add Missing Sections"
                    isLoading={getMatrix?.isLoading}
                    sections={
                      (getMatrix &&
                        getMatrix.result &&
                        getMatrix.result.jd_missing_sections.length > 0 &&
                        getMatrix.result.jd_missing_sections) ||
                      []
                    }
                  />
                  <Sections
                    setPrompts={setPrompts}
                    prompts={prompts}
                    title="Content Enhancements"
                    isLoading={getContentsEnhanncements?.isLoading}
                    sections={
                      (getContentsEnhanncements &&
                        getContentsEnhanncements.result &&
                        getContentsEnhanncements.result.length > 0 &&
                        getContentsEnhanncements.result) ||
                      []
                    }
                  />
                  <Sections
                    setPrompts={setPrompts}
                    prompts={prompts}
                    title="Responsibilities"
                    isLoading={getSuggestResponsibilitiesProps?.isLoading}
                    sections={
                      (getSuggestResponsibilitiesProps &&
                        getSuggestResponsibilitiesProps.result &&
                        getSuggestResponsibilitiesProps.result.length > 0 &&
                        getSuggestResponsibilitiesProps.result) ||
                      []
                    }
                  />
                  <Sections
                    setPrompts={setPrompts}
                    prompts={prompts}
                    title="Skills"
                    isLoading={getSuggestSkillsProps?.isLoading}
                    sections={
                      (getSuggestSkillsProps &&
                        getSuggestSkillsProps.result &&
                        getSuggestSkillsProps.result.length > 0 &&
                        getSuggestSkillsProps.result) ||
                      []
                    }
                  />
                </>
              ) : (
                <Sections
                  setPrompts={setPrompts}
                  prompts={prompts}
                  title="Exmaple"
                  isLoading={getPrompts?.isLoading}
                  sections={
                    (getPrompts &&
                      getPrompts.result &&
                      getPrompts.result.length > 0 &&
                      getPrompts.result) ||
                    []
                  }
                />
              )}
            </div>
          </div>
          <div className={css.rightItems}>
            <Textarea
              label="Job Description"
              placeholder="Paste your JD here if you already have one or write a prompt beside to generate JD here"
              classNames={{box: css.jdDescriptionBox}}
              value={outputJd ? outputJd?.job_description : ''}
              onChange={(e) =>
                setOutputJd({
                  ...outputJd,
                  job_description: e.target.value,
                })
              }
            />
          </div>
        </Card>
      </div>
    </div>
  );
}

const Sections = ({sections, title, isLoading, setPrompts, prompts}) => {
  if (!isLoading && (!sections || sections.length === 0)) {
    return null;
  }

  return (
    <div className={css.promptListContainer}>
      <SubTitleSmall>{title}</SubTitleSmall>
      {isLoading && <CircularLoader size="small" />}
      {!isLoading &&
        sections &&
        sections.length > 0 &&
        sections.map((item) => {
          return (
            <div
              className={css.promptList}
              onClick={() => {
                setPrompts(`${prompts}${prompts ? ',' : ''}${item}`);
              }}
            >
              <Icon name="bolt" />
              <FormLabelSmall> {item}</FormLabelSmall>
            </div>
          );
        })}
    </div>
  );
};
