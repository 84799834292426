// @noflow

import type {ScheduledMessage} from 'src/types/messages';
import type {Broadcast, BroadcastForm} from 'src/types/broadcasts';
import type {ErrorMap} from 'src/types/redux';

import moment from 'moment';

import {
  BROADCAST_STATUS_PROCESSED,
  BROADCAST_STATUS_INCOMPLETE,
} from 'src/types/broadcasts';
import {validateIn} from 'src/utils';
import {setError} from 'src/utils/validation';


export const hasSent = (
  broadcast: Broadcast | BroadcastForm | ScheduledMessage,
) =>
  broadcast.status &&
  (broadcast.status === BROADCAST_STATUS_PROCESSED ||
    broadcast.status === BROADCAST_STATUS_INCOMPLETE);

// If broadcast hasn't been processed but send date is in the recent past, consider
// it as "processing" (this is mostly for the send-now UX)
export const isProcessing = (broadcast: Broadcast | ScheduledMessage) => {
  const sendDate = moment(broadcast.sendDate).valueOf();
  const now = moment().valueOf();
  return !hasSent(broadcast) && sendDate < now && now < sendDate + 30000;
};

export const BROADCAST_CHARACTER_LIMIT = 720;

const validString = str => typeof str === 'string' && str.trim() !== '';

export const validateForm = (
  form: BroadcastForm,
  fromDraftMessageForm?: boolean = false,
  charLimit?: number = BROADCAST_CHARACTER_LIMIT,
): ErrorMap | void => {
  const errors = {};

  // TODO (kyle): check existence of thread list or thread ids

  Object.assign(
    errors,
    validateIn(form, 'body', body => {
      const hardLimit = charLimit;
      return (
        (!validString(body) && 'Broadcast message cannot be blank') ||
        (typeof body === 'string' &&
          body.length > hardLimit &&
          `Broadcast must be ${hardLimit} characters or fewer`)
      );
    }),
  );

  Object.assign(
    errors,
    validateIn(
      form,
      'sendDate',
      sendDate =>
        typeof sendDate === 'string' &&
        // Allow a small (less than 1 min) offset so that send-now
        // broadcasts don't fail this test
        moment(sendDate).valueOf() + 2000 < moment().valueOf() &&
        'Please choose a date in the future.',
    ),
  );

  if (Object.keys(errors).length > 0) {
    return errors;
  }
};

export const validateBroadcast = (
  broadcast: BroadcastForm,
  recipientLimit = 100,
) => {
  let errors;

  if (broadcast.threadListId) {
    errors = setError(
      errors,
      !broadcast.phoneNumbers,
      'phoneNumbers',
      'Cannot set phoneNumbers if threadListId is present.',
    );
  } else {
    const recipientCount = broadcast.phoneNumbers.length;

    errors = setError(
      errors,
      recipientCount > 0,
      'phoneNumbers',
      'Must have at least 1 recipient.',
    );

    errors = setError(
      errors,
      recipientCount <= recipientLimit,
      'phoneNumbers',
      `Must have ${recipientLimit} or fewer receipients.`,
    );

    broadcast.phoneNumbers.forEach((phoneNumber, index) => {
      if (!phoneNumber) {
        errors = setError(
          errors,
          phoneNumber,
          `phoneNumbers[${index}]`,
          'Invalid',
        );
      }
    });
  }

  errors = setError(
    errors,
    broadcast.body,
    'body',
    'Must have a message body.',
  );
  errors = setError(
    errors,
    broadcast.body.length <= BROADCAST_CHARACTER_LIMIT,
    'body',
    `Body must be ${BROADCAST_CHARACTER_LIMIT} characters or fewer.`,
  );

  if (broadcast.sendNow) {
    errors = setError(
      errors,
      !broadcast.sendDate,
      'sendDate',
      'Cannot set a sendDate when sendNow is true.',
    );
  } else {
    errors = setError(
      errors,
      broadcast.sendDate,
      'sendDate',
      'Must specify a sendDate.',
    );
    errors = setError(
      errors,
      moment(broadcast.sendDate) > moment() + 2000,
      'sendDate',
      'sendDate must be in the future.',
    );
  }

  return errors;
};
