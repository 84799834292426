// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {
  AudienceListTableEntry,
  AudienceListTableQuery,
  AudienceListStatus,
} from 'src/types/audience-list';

import {batch} from 'react-redux';

// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';
import {receivePublishedListIds} from 'src/action-creators/audience-list';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {fetching, key} from 'src/utils/redux';


export const RECEIVE_LISTS = 'audience-lists/receiveAll';
export const DELETE_LISTS = 'audience-lists/delete';
export const PUBLISH_LISTS = 'audience-lists/publish';
export const ARCHIVE_LISTS = 'audience-lists/archive';
export const RECEIVE_LIST_UPDATE = 'audience-lists/receiveUpdate';
export const REFETCH = 'audience-lists/refetch';
export const RESET_LIST_PAGE_STATE = 'audience-lists/reset-list-page-state';

type ReceiveListsAction = {
  type: 'audience-lists/receiveAll',
  payload: {
    total: number,
    lists: AudienceListTableEntry[],
    pageNumber: number,
    isNewList: boolean,
  },
};
type ReceiveListsUpdateAction = {
  type: 'audience-lists/receiveUpdate',
  payload: {name: string, status: AudienceListStatus, id: string},
};
type DeleteListsAction = {
  type: 'audience-lists/delete',
  payload: string[],
};
type PublishListsAction = {
  type: 'audience-lists/publish',
  payload: string[],
};
type ArchiveListsAction = {
  type: 'audience-lists/archive',
  payload: string[],
};
type UnarchiveListsAction = {
  type: 'audience-lists/unarchive',
  payload: string[],
};
type RefetchAction = {
  type: 'audience-lists/refetch',
};

type ResetListPageStateAction = {
  type: 'audience-lists/reset-list-page-state',
  payload: boolean,
};

export type AudienceListsActions =
  | ReceiveListsAction
  | PublishListsAction
  | ArchiveListsAction
  | UnarchiveListsAction
  | DeleteListsAction
  | ReceiveListsUpdateAction
  | RefetchAction
  | ResetListPageStateAction;

const receiveAllLists = (payload: {
  total: number,
  lists: AudienceListTableEntry[],
  pageNumber: number,
  isNewList: boolean,
}): ReceiveListsAction => ({
  type: 'audience-lists/receiveAll',
  payload,
});

export const receiveListUpdate = (payload: {
  name: string,
  status: AudienceListStatus,
  id: string,
}): ReceiveListsUpdateAction => ({
  type: 'audience-lists/receiveUpdate',
  payload,
});

export const refetch = (): RefetchAction => ({
  type: 'audience-lists/refetch',
});
//this action exists to let the list.jsx know
// when to reset its state.
export const resetListPageState = (
  status: boolean,
): ResetListPageStateAction => ({
  type: 'audience-lists/reset-list-page-state',
  payload: status,
});

const doPublishLists = (ids: string[]): PublishListsAction => ({
  type: PUBLISH_LISTS,
  payload: ids,
});

const doArchiveLists = (ids: string[]): ArchiveListsAction => ({
  type: ARCHIVE_LISTS,
  payload: ids,
});

const doDeleteLists = (ids: string[]): DeleteListsAction => ({
  type: DELETE_LISTS,
  payload: ids,
});

export const getLists: (
  query: AudienceListTableQuery,
  pageNumber?: number,
  isNewList?: boolean,
) => ThunkAction<mixed> = flow(
  key((query) => `listSearch-${JSON.stringify(query)}`),
  fetching(),
)(
  (query, pageNumber = 1, isNewList = true) =>
    (dispatch: Dispatch) =>
      dispatch(reduxApi.get('filter/search', query))
        .then((payload) => {
          dispatch(
            receiveAllLists({
              total: payload.totalCount ?? 0,
              lists: payload.filters ?? [],
              // totalPages: payload.totalPages ?? 0,
              pageNumber,
              isNewList,
            }),
          );
        })
        .catch((error) => {
          dispatch(
            receiveAllLists({
              total: 0,
              lists: [],
              pageNumber,
              isNewList,
            }),
          );
        }),
);

export const getListsById: (listIds: string[]) => ThunkAction<mixed> = flow(
  key((listIds) => `listDetails-${JSON.stringify(listIds)}`),
  fetching(),
)(
  (listIds) => (dispatch: Dispatch) =>
    dispatch(reduxApi.get('filter/bulk?id=' + listIds.join(','))),
);

export const publishLists: (listIds: string[]) => ThunkAction<mixed> =
  (listIds) => (dispatch: Dispatch) =>
    dispatch(reduxApi.patch('filter/publish', {ids: listIds})).then((_) => {
      batch(() => {
        dispatch(doPublishLists(listIds));
        dispatch(receivePublishedListIds(listIds));
      });
      return listIds;
    });
export const deleteLists: (listIds: string[]) => ThunkAction<mixed> =
  (listIds) => (dispatch) =>
    dispatch(
      /** TODO:(diwakersurya) fix here for delete */
      reduxApi.del('filter', {ids: listIds}),
    );

export const duplicateList: (listId: string) => ThunkAction<mixed> =
  (listId) => (dispatch) =>
    dispatch(reduxApi.post(`filter/${listId}/duplicate`));

export const archiveLists: (listIds: string[]) => ThunkAction<mixed> =
  (listIds) => (dispatch) => {
    return dispatch(reduxApi.patch('filter/archive', {ids: listIds})).then(
      (res) => {
        return listIds;
      },
    );
  };

export const unarchiveLists: (listIds: string[]) => ThunkAction<mixed> =
  (listIds) => (dispatch) => {
    return dispatch(reduxApi.patch('filter/unarchive', {ids: listIds})).then(
      (res) => {
        return listIds;
      },
    );
  };
