// @noflow

import type {State} from 'src/reducers';

import * as React from 'react';
import {connect} from 'react-redux';

import {withRouter} from 'src/flux/withRouter.jsx';
import {
  getUnreadCounts,
  startListeningWebSocket,
  stopListeningWebSocket,
} from 'src/action-creators/messages';
import {canUseMsgPhoneOrLatInbox} from 'src/selectors/chat';
import {
  selectTotalUnreadCount,
  selectCurrentInboxSoundSetting,
} from 'src/selectors/messages';

import ErrorBoundary from 'src/components/lib/error-boundary.jsx';
import UnreadTabNotification from './unread-tab-notification.jsx';
import ChatSoundEffect from 'src/components/messaging/sound-effect.jsx';

import bellMp3 from 'src/sounds/bell_ding_muted_lo.mp3';
import bellWav from 'src/sounds/bell_ding_muted_lo.wav';


type Props = MappedProps & DispatchProps;

class MessagesSocket extends React.Component<
  Props,
  {
    didMount: boolean,
  },
> {
  state = {
    didMount: false,
  };

  componentDidMount() {
    this.props.getUnreadCounts();
    this.setState({didMount: true});
  }

  componentWillUnmount() {
    this.props.stopListeningWebSocket();
  }

  componentDidUpdate() {
    if (this.canAcceptUpdates()) {
      this.props.startListeningWebSocket(this.props.router);
    }
  }

  canAcceptUpdates() {
    return (
      // Only start accepting updates on client (don't do it on the server)
      this.state.didMount
    );
  }

  render() {
    const {unreadCount, inboxViewIsMounted, soundSetting} = this.props;
    return (
      <React.Fragment>
        <UnreadTabNotification inboxOpen={inboxViewIsMounted} />
        <ChatSoundEffect
          playId={unreadCount > 0 ? unreadCount : null}
          soundSetting={soundSetting}
          media={[
            {
              src: bellMp3,
              type: 'audio/mpeg',
            },
            {
              src: bellWav,
              type: 'audio/wav',
            },
          ]}
        />
      </React.Fragment>
    );
  }
}

type MappedProps = {
  authedUserPhoneError: boolean,
  doneFetching: boolean,
  unreadCount: number,
  inboxViewIsMounted: boolean,
  canViewChat: boolean,
};

const mapStateToProps = (state: State): MappedProps => ({
  authedUserPhoneError: state.accounts.authedUserPhoneError,
  // NOTE (gab): it's important not to open the websocket until we're finished
  // with the first fetch of messages because the protocol we established so
  // that no messages fall through the cracks was:
  // 1. fetch most recent messages
  // 2. open web socket
  // 3. fetch most recent messages again (using id of most recent message in memory)
  doneFetching: state.messages.hasReceived,
  unreadCount: selectTotalUnreadCount(state),
  inboxViewIsMounted: state.messages.inboxViewIsMounted,
  canViewChat: canUseMsgPhoneOrLatInbox(state),
  soundSetting: selectCurrentInboxSoundSetting(
    state,
    state.messages.receivedPhoneNumber?.id,
    state.messages.receivedPhoneNumber?.type,
  ),
});

type DispatchProps = {
  getUnreadCounts: typeof getUnreadCounts,
  startListeningWebSocket: typeof startListeningWebSocket,
  stopListeningWebSocket: typeof stopListeningWebSocket,
};

const mapDispatchToProps: DispatchProps = {
  getUnreadCounts,
  startListeningWebSocket,
  stopListeningWebSocket,
};

const MessagesSocketParent = (props) =>
  // Only start accepting updates if the agent has ability to use Messaging app
  props.canViewChat && (
    <ErrorBoundary>
      <MessagesSocket {...props} />
    </ErrorBoundary>
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MessagesSocketParent),
);
