// @flow

import type {SenseAction} from 'src/action-creators/types';

import {
  OPEN_PANEL,
  CLOSE_PANEL,
  OPEN_MENU_PANEL,
  STORE_MENU_PANEL_STATE,
} from 'src/action-creators/chat/panels';


export type State = {
  isPanelOpen: boolean,
  isActionPanel: boolean,
  isMenuOpen: boolean,
  menuPanelStateToRestore: boolean,
};

const initialState = () => ({
  isPanelOpen: false,
  isActionPanel: false,
  isMenuOpen: false,
  menuPanelStateToRestore: false,
});

export default function reduce(
  state: State = initialState(),
  action: SenseAction,
): State {
  switch (action.type) {
    case OPEN_MENU_PANEL: {
      return {
        ...state,
        isMenuOpen: action.payload.value,
      };
    }

    case STORE_MENU_PANEL_STATE: {
      return {
        ...state,
        menuPanelStateToRestore: action.payload.value,
      };
    }
    case OPEN_PANEL: {
      const {isActionPanel} = action.payload;

      return {
        ...state,
        isPanelOpen: true,
        isMenuOpen: false,
        isActionPanel,
      };
    }

    case CLOSE_PANEL: {
      return {
        ...state,
        isPanelOpen: false,
        isActionPanel: false,
      };
    }
  }

  return state;
}
