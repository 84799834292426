var React = require('react');

function Next (props) {
    return React.createElement("svg",props,React.createElement("path",{"fillRule":"evenodd","clipRule":"evenodd","d":"M12.75 9a.75.75 0 01-.22.53l-4.5 4.5a.75.75 0 01-1.06-1.06L10.94 9 6.97 5.03a.75.75 0 011.06-1.06l4.5 4.5c.141.14.22.331.22.53z"}));
}

Next.defaultProps = {"width":"18","height":"18","viewBox":"0 0 18 18"};

module.exports = Next;

Next.default = Next;
