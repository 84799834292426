// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {
  WritebackFieldValue,
  FieldWritebackChangeToOperatorType,
} from 'src/types/field-writeback';
import type {FieldWritebackState} from 'src/reducers/field-writeback';


type UpdateFieldWritebackPayload = $Shape<FieldWritebackState>;

type FieldWritebackCriteriaType = 'field' | 'person';

export const SET_FIELD_WRITEBACK = 'field-writeback/set';
export const UPDATE_FIELD_WRITEBACK = 'field-writeback/update';
export const RESET_FIELD_WRITEBACK = 'field-writeback/reset';
export const SET_FIELD_WRITEBACK_CHANGE_TYPE =
  'field-writeback/set-criteria-type';
export const SET_FIELD_WRITEBACK_FIELD = 'field-writeback/set-writeback-field';
export const SET_FIELD_WRITEBACK_VALUE = 'field-writeback/set-writeback-value';
export const SET_FIELD_WRITEBACK_CHANGE_TO_OPERATOR =
  'field-writeback/set-change-to-operator';

type UpdateFieldWritebackAction = {
  type: 'field-writeback/update',
  payload: UpdateFieldWritebackPayload,
};
type ResetFieldWritebackAction = {
  type: 'field-writeback/reset',
  payload?: FieldWritebackState,
};
type SetFieldWritebackAction = {
  type: 'field-writeback/set',
  payload: FieldWritebackState,
};
type SetFieldWritebackCriteriaTypeAction = {
  type: 'field-writeback/set-criteria-type',
  payload: FieldWritebackCriteriaType,
};
type SetFieldWritebackFieldsAction = {
  type: 'field-writeback/set-writeback-field',
  payload: WritebackFieldValue,
};
type SetFieldWritebackValueAction = {
  type: 'field-writeback/set-writeback-value',
  payload: {id: string, value: Array<string>},
};
type SetFieldWritebackChangeToOperatorAction = {
  type: 'field-writeback/set-change-to-operator',
  payload: {id: string, value: FieldWritebackChangeToOperatorType},
};

export type FieldWritebackAction =
  | UpdateFieldWritebackAction
  | ResetFieldWritebackAction
  | SetFieldWritebackAction
  | SetFieldWritebackCriteriaTypeAction
  | SetFieldWritebackFieldsAction
  | SetFieldWritebackValueAction
  | SetFieldWritebackChangeToOperatorAction;

export const updateFieldWriteback = (
  payload: UpdateFieldWritebackPayload,
): UpdateFieldWritebackAction => ({
  type: UPDATE_FIELD_WRITEBACK,
  payload,
});

export const resetFieldWriteback = (): ResetFieldWritebackAction => ({
  type: RESET_FIELD_WRITEBACK,
});

export const setFieldWriteback = (
  payload: FieldWritebackState,
): SetFieldWritebackAction => ({
  type: SET_FIELD_WRITEBACK,
  payload,
});
export const setFieldWritebackCriteriaType = (
  payload: FieldWritebackCriteriaType,
): SetFieldWritebackCriteriaTypeAction => ({
  type: SET_FIELD_WRITEBACK_CHANGE_TYPE,
  payload,
});
export const setFieldWritebackField = (
  payload: WritebackFieldValue,
): SetFieldWritebackFieldsAction => ({
  type: SET_FIELD_WRITEBACK_FIELD,
  payload,
});
export const setFieldWritebackValue = (payload: {
  id: string,
  value: Array<string>,
}): SetFieldWritebackValueAction => ({
  type: SET_FIELD_WRITEBACK_VALUE,
  payload,
});
export const setFieldWritebackChangeToOperator = (payload: {
  id: string,
  value: FieldWritebackChangeToOperatorType,
}): SetFieldWritebackChangeToOperatorAction => ({
  type: SET_FIELD_WRITEBACK_CHANGE_TO_OPERATOR,
  payload,
});

export const getFieldWriteback: (
  id: string,
  swid: number,
) => ThunkAction<mixed> | mixed =
  (id, swid) => (dispatch: Dispatch, getState) => {
    //we already have data for fwb in the swimlane data so we pick it from there instead of getting it from the api
    // we can also hit the api from here to get the data if we want
    const fieldWritebackData = getState().automationWorkflow.swimlanes.find(
      (swimlane) => swimlane.id === swid,
    ).nodes[id];
    return fieldWritebackData ?? null;
  };
