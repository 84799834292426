//@flow strict

import type {Reducer} from 'redux';
import type {AppConfigAction} from 'src/action-creators/app-config';

import {SET_FULLSCREEN, CLEAR_FULLSCREEN} from 'src/action-creators/app-config';

// NOTE:(diwakersurya) whole app related state should go here
type State = {
  fullscreen: boolean,
};

const initialState = {
  fullscreen: false,
};

const reducer: Reducer<State, AppConfigAction> = (
  state: State = initialState,
  action: AppConfigAction,
) => {
  switch (action.type) {
    case SET_FULLSCREEN:
      return {
        ...state,
        fullscreen: true,
      };
    case CLEAR_FULLSCREEN:
      return {
        ...state,
        fullscreen: false,
      };
  }

  return state;
};

export default reducer;
