// @flow

import type {AccountInvite} from 'src/types/account-invite';
import type {Dispatch, ThunkAction} from 'src/reducers';

import {thunkify as flow} from 'src/utils/thunks';
import {camel, snake} from 'src/utils';
import {key, cached, fetching, evict} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api-v2';
import {showConfirmationMessage} from 'src/action-creators/accounts';
import {assignPhoneNumberToInvite} from 'src/action-creators/phone-numbers.js';
import {showGenericError} from 'src/action-creators/modal';


export const RECEIVE_ACCOUNT_INVITES: 'accountInvites/receiveAccountInvites' =
  'accountInvites/receiveAccountInvites';
export const RECEIVE_ACCOUNT_INVITE: 'accountInvites/receiveAccountInvite' =
  'accountInvites/receiveAccountInvite';
export const UPDATE_ACCOUNT_INVITE: 'accountInvites/updateAccountInvite' =
  'accountInvites/updateAccountInvite';

export type InvitesAction =
  | UpdateAccountInvitesAction
  | UpdateAccountInviteAction
  | ReceiveAccountInviteAction;

type UpdateAccountInvitesAction = {
  type: typeof RECEIVE_ACCOUNT_INVITES,
  payload: AccountInvite[],
};

type ReceiveAccountInviteAction = {
  type: typeof RECEIVE_ACCOUNT_INVITE,
  payload: AccountInvite,
};

type UpdateAccountInviteAction = {
  type: typeof UPDATE_ACCOUNT_INVITE,
  payload: AccountInvite,
};

const updateInvite = (
  inviteCode: string,
  payload: $Shape<AccountInvite>,
): UpdateAccountInviteAction => ({
  type: UPDATE_ACCOUNT_INVITE,
  payload: {...payload, inviteCode},
});

const receiveInvites = (
  payload: AccountInvite[],
): UpdateAccountInvitesAction => ({
  type: RECEIVE_ACCOUNT_INVITES,
  payload,
});

const receiveInvite = (payload: AccountInvite): ReceiveAccountInviteAction => ({
  type: RECEIVE_ACCOUNT_INVITE,
  payload,
});

export const createAccountInvite = (
  account: AccountInvite,
): ThunkAction<> => async (dispatch: Dispatch) => {
  const accountInvite = await dispatch(
    reduxApi.post('account_invites', snake(account)),
  );
  dispatch(receiveInvite(camel(accountInvite)));
  dispatch(
    showConfirmationMessage(
      `${accountInvite.email} has been added!`,
      10 * 1000,
    ),
  );
  if (accountInvite.provisioned_phone_id) {
    dispatch(
      assignPhoneNumberToInvite(
        accountInvite.invite_code,
        accountInvite.provisioned_phone_id,
      ),
    );
  }
};

export const sendAccountInvite = (inviteCode: string): ThunkAction<> => (
  dispatch: Dispatch,
) =>
  dispatch(reduxApi.post(`account_invites/${inviteCode}/send-email`)).then(
    (accountInvite) => {
      dispatch(receiveInvite(camel(accountInvite)));
    },
  );

export const cancelAccountInvite = (
  inviteCode: string,
): ThunkAction<> => async (dispatch: Dispatch) => {
  dispatch(updateInvite(inviteCode, {cancelled: true}));
  try {
    const accountInvite = await dispatch(
      reduxApi.put(`account_invites/${inviteCode}/cancel`),
    );
  } catch (err) {
    dispatch(updateInvite(inviteCode, {cancelled: false}));
    throw err;
  }
};

export const reSendAccountInvite = (
  inviteCode: string,
): ThunkAction<> => async (dispatch: Dispatch) => {
  dispatch(updateInvite(inviteCode, {cancelled: false}));
  try {
    const accountInvite = await dispatch(
      reduxApi.post(`account_invites/${inviteCode}/reinvite`),
    );
  } catch (err) {
    if (
      err.status === 500 ||
      err?.errors.includes('SendUserInviteEmailFailed')
    ) {
      dispatch(updateInvite(inviteCode, {cancelled: true}));
    }
    dispatch(
      showGenericError({
        text: 'We were unable to Resend Invite. Please try again! 🙏',
      }),
    );
    throw err;
  }
};

export const getAccountInvites: () => ThunkAction<> = flow(
  key(() => 'getAccountInvites'),
  cached(
    (invites) => {
      const allInvites = [
        ...invites.open_invites.map((invite) => ({
          ...invite,
          cancelled: false,
        })),
        ...invites.cancelled_invites.map((invite) => ({
          ...invite,
          cancelled: true,
        })),
      ];
      return receiveInvites(camel(allInvites));
    },
    {
      ttl: 120 * 1000,
    },
  ),
  fetching(),
)(() => reduxApi.get('account_invites/open', {include_cancelled: true}));
