// @flow strict

import type {
  // $FlowFixMe[nonstrict-import]
  WorkflowActivationNotifications,
  // $FlowFixMe[nonstrict-import]
  WorkflowActivationRecentlyResolvedNotification,
} from 'src/types/workflow';
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers/index';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';


export const NOTIFICATION_STATE_FAILED = 'FAILED';
export const NOTIFICATION_STATE_ACKNOWLEDGED = 'ACKNOWLEDGED';
export const NOTIFICATION_STATE_RESOLVED = 'RESOLVED';
export const NOTIFICATION_STATE_COMPLETED = 'COMPLETED';

export const RECEIVE_ALL = 'workflowActivation/receiveAll';
export const ADD_RECENTLY_RESOLVED = 'workflowActivation/addRecentlyResolved';

type ReceiveWorkflowsActivationAction = {|
  type: 'workflowActivation/receiveAll',
  payload: WorkflowActivationNotifications,
|};

type RecentlyResolvedWorkflowsActivationAction = {
  type: 'workflowActivation/addRecentlyResolved',
  payload: WorkflowActivationRecentlyResolvedNotification,
};

export type WorkflowsActivationAction =
  | ReceiveWorkflowsActivationAction
  | RecentlyResolvedWorkflowsActivationAction;

const receiveWorkflowActivationStatus = (
  payload: WorkflowActivationNotifications,
): ReceiveWorkflowsActivationAction => ({
  type: RECEIVE_ALL,
  payload,
});

const addRecentlyResolvedWorkflow = (
  payload: WorkflowActivationRecentlyResolvedNotification,
): RecentlyResolvedWorkflowsActivationAction => ({
  type: ADD_RECENTLY_RESOLVED,
  payload,
});

export const getWorkflowActivationStatus =
  (): ThunkAction<ReceiveWorkflowsActivationAction> =>
  async (dispatch: Dispatch) => {
    const summary: WorkflowActivationNotifications = await dispatch(
      reduxApi.get('workflow-activation/failure-summary'),
    );

    return dispatch(receiveWorkflowActivationStatus(summary));
  };

export const acknowledgeError =
  (): ThunkAction<ReceiveWorkflowsActivationAction> =>
  async (dispatch: Dispatch) => {
    await dispatch(reduxApi.post('workflow-activation/acknowledge-failures'));

    return dispatch(getWorkflowActivationStatus());
  };

export const acknowledgeResolved =
  (workflowIds: string[]): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    await dispatch(
      reduxApi.post('workflow-activation/acknowledge-resolved-failures'),
    );

    dispatch(getWorkflowActivationStatus());
    dispatch(addRecentlyResolvedWorkflow(workflowIds));
  };
