// @flow strict-local

import * as React from 'react';
import {useSelector} from 'react-redux';

// $FlowFixMe[untyped-import]
import {selectEntityMappingsBySenseName} from 'src/selectors/ats-entities';
import classify from 'src/utils/classify';

import {FilterCard} from 'src/components/lib/analytics-filter-card';
import Checkbox from 'src/components/lib/checkbox';

import css from './calendar-status.css';

import useI18n from 'src/hooks/useI18n';


type Props = {
  onSave?: (e: SyntheticEvent<>) => mixed,
  onCancel?: (e: SyntheticEvent<>) => mixed,
  onFilterCalendarStatusTypeChange: (
    e: SyntheticEvent<HTMLInputElement>,
  ) => mixed,
  selectedFilterCalendarStatusTypes: string[],
  filterCalendarStatusTypes?: {
    value: string,
    label: string,
    sense_name: string,
  }[],
};

const filterCalendarStatusTypesLocal = [
  {
    value: 'connected',
    label: 'Connected',
  },
  {
    value: 'not_connected',
    label: 'Not Connected',
  },
  {
    value: 'not_invited',
    label: 'Not yet invited',
  },
];

const getTranslatedFilterCalendarStatusTypes = (labelI18nInstance) => {
  const defaultFilterCalendarStatusTypes = [
    {
      value: 'connected',
      label: labelI18nInstance.t(
        'filterCalendarStatus-type-connnected',
        'Connected',
      ),
      sense_name: 'connected',
    },
    {
      value: 'not_connected',
      label: labelI18nInstance.t(
        'filterCalendarStatus-type-not-connected',
        'Not Connected',
      ),
      sense_name: 'not_connected',
    },
    {
      value: 'not_invited',
      label: labelI18nInstance.t(
        'filterCalendarStatus-type-not-yet-invited',
        'Not yet invited',
      ),
      sense_name: 'not_invited',
    },
  ];
  return defaultFilterCalendarStatusTypes;
};

const FilterCalendarStatusCard = ({
  onSave,
  onCancel,
  filterCalendarStatusTypes,
  onFilterCalendarStatusTypeChange,
  selectedFilterCalendarStatusTypes = [],
  cardLabel = 'Filter Calendar Status',
}: Props): React.Node => {
  const {labelI18nInstance} = useI18n();
  const filteredFilterCalendarStatusTypes =
    filterCalendarStatusTypes ||
    filterCalendarStatusTypesLocal ||
    getTranslatedFilterCalendarStatusTypes(labelI18nInstance);

  return (
    <FilterCard header={cardLabel} onSave={onSave} onCancel={onCancel}>
      <section className={css.section}>
        <ul className={css.checklist}>
          {filteredFilterCalendarStatusTypes.map(({value, label}) => (
            <li key={value}>
              <label className={classify(css.label, css[value])}>
                <Checkbox
                  className={css.checkbox}
                  name={value}
                  checked={selectedFilterCalendarStatusTypes.includes(value)}
                  onChange={onFilterCalendarStatusTypeChange}
                />
                {label}
              </label>
            </li>
          ))}
        </ul>
      </section>
    </FilterCard>
  );
};

export default FilterCalendarStatusCard;
