// @flow strict

import type {NavigationAction} from 'src/action-creators/navigation';

import {TOGGLE_SIDE_NAV} from 'src/action-creators/navigation';


export type NavigationState = {
  sideNavHidden: boolean,
};

const initialState = {
  sideNavHidden: false,
};

export default (
  state: NavigationState = initialState,
  action: NavigationAction,
): NavigationState => {
  switch (action.type) {
    case TOGGLE_SIDE_NAV: {
      return {
        ...state,
        sideNavHidden: action.payload.hide,
      };
    }
  }
  return state;
};
