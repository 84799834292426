// @flow strict

import type {ListsActions} from 'src/action-creators/lists';
import type {ListTableEntry} from 'src/types/lists';

import {
  RECEIVE_LISTS,
  PUBLISH_LISTS,
  RECEIVE_LIST_UPDATE,
  REFETCH,
  RESET_LIST_PAGE_STATE,
} from 'src/action-creators/lists';


export type State = {
  lists: ListTableEntry[],
  total: number,
  refetch: boolean,
  //flag to communicate with list.jsx componeent
  //so that it resets its state
  resetListPageState: boolean,
};

const initialState = {
  lists: [],
  total: 0,
  refetch: false,
  resetListPageState: false,
};

export default (state: State = initialState, action: ListsActions): State => {
  switch (action.type) {
    case RECEIVE_LISTS: {
      return {
        ...state,
        lists: action.payload.lists,
        total: action.payload.total,
      };
    }
    case RECEIVE_LIST_UPDATE: {
      const {id, name, status} = action.payload;
      return {
        ...state,
        lists: state.lists.map((list) => {
          if (list.id === id) {
            return {...list, name, status};
          }
          return list;
        }),
      };
    }
    case PUBLISH_LISTS: {
      const publishedLists = new Set(action.payload);
      return {
        ...state,
        lists: state.lists.map((list) => {
          if (publishedLists.has(list.id)) {
            return {...list, status: 'published'};
          } else {
            return list;
          }
        }),
      };
    }
    case REFETCH: {
      return {
        ...state,
        refetch: !state.refetch,
      };
    }
    case RESET_LIST_PAGE_STATE: {
      return {
        ...state,
        resetListPageState: !state.resetListPageState,
      };
    }
  }

  return state;
};
