// @flow strict

//$FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';
import type {EntityType} from 'src/types/ats-entities';
import type {TargetEntityTypeState} from 'src/reducers/target-entity-types';
import type {
  EntityMappingsByEntityType,
  TargetEntity,
} from 'src/types/target-entity-types';
import {createSelector} from 'reselect';
import keyBy from 'lodash/keyBy';

/*
 * Base Selectors
 */
export const selectAllEntityTypes = (state: State): TargetEntityTypeState =>
  state.targetEntityTypes;

export const selectTargetEntityTypes: (state: State) => TargetEntityTypeState =
  createSelector(selectAllEntityTypes, (mappings) => {
    return mappings.filter((mapping) => mapping.standard_entity_type !== null);
  });

export const selectEntityMappingsByEntityType: (State) => EntityMappingsByEntityType =
  createSelector(selectAllEntityTypes, (mappings) =>
    keyBy(mappings, 'entity_type'),
  );

export const selectEntityInfo: (State, EntityType) => TargetEntity =
  createSelector(
    selectEntityMappingsByEntityType,
    (_, entityType) => entityType,
    (entityMappings, entityType) => entityMappings[entityType],
  );

export const selectFocusEntity: (state: State, entityType: string) => ?string =
  createSelector(
    selectTargetEntityTypes,
    (_, entityType) => entityType,
    (entityTypes, entityType) => {
      const selectedEntityType = (entityTypes ?? []).find(
        (entityTypeItem) => entityTypeItem.entity_type === entityType,
      );
      const focusEntity = selectedEntityType?.display_name;
      return focusEntity;
    },
  );
