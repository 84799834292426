// @flow strict

import * as React from 'react';

import classify from 'src/utils/classify';

import {Clickable} from 'src/designSystem2021Components/text-v2.jsx';

import css from './checkbox.css';
import dsCss from 'designSystem2021';


const CheckIcon = ({fill, className}: {fill?: string, className?: string}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5068 5.44708C20.8121 5.72697 20.8327 6.2014 20.5528 6.50674L9.55283 18.5067C9.41461 18.6575 9.22078 18.7453 9.01626 18.7498C8.81175 18.7542 8.61429 18.6749 8.46964 18.5303L3.46964 13.5303C3.17675 13.2374 3.17675 12.7625 3.46964 12.4696C3.76253 12.1767 4.23741 12.1767 4.5303 12.4696L8.97641 16.9157L19.4471 5.49315C19.727 5.18781 20.2014 5.16718 20.5068 5.44708Z"
    />
  </svg>
);

const MinusIcon = ({fill, className}: {fill?: string, className?: string}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 12C5.25 11.5858 5.58579 11.25 6 11.25H12H18C18.4142 11.25 18.75 11.5858 18.75 12C18.75 12.4142 18.4142 12.75 18 12.75H12H6C5.58579 12.75 5.25 12.4142 5.25 12Z"
    />
  </svg>
);

export type CheckedValue = 'mixed' | 'true' | 'false';

type CheckboxProps = {
  checked?: CheckedValue,
  className?: string,
  tabIndex?: number,
  onChange?: (CheckedValue) => mixed,
  disabled?: boolean,
};
type CheckboxRef = {
  click: (SyntheticEvent<>) => void,
};

function CheckboxBase(
  {checked, className, tabIndex = 0, onChange, disabled = false}: CheckboxProps,
  ref,
): React.Node {
  const [checkedState, setCheckedState] = React.useState(checked ?? 'false');
  const checkedValue = onChange && checked != null ? checked : checkedState;
  const spanRef = React.useRef<?HTMLSpanElement>();

  const updateAndEmitState = (
    newState: CheckedValue,
    evt: SyntheticEvent<HTMLSpanElement>,
  ) => {
    setCheckedState(newState);
  };

  const getNextState = (state: CheckedValue): CheckedValue => {
    switch (state) {
      case 'mixed':
        return 'true';
      case 'true':
        return 'false';
      case 'false':
        return 'true';
    }
  };

  const maybeSetLocalState = (e: SyntheticEvent<>) => {
    if (disabled) {
      return;
    }
    const nextState = getNextState(checkedValue);
    if (onChange != null && checked != null) {
      onChange(nextState);
    } else {
      setCheckedState(nextState);
    }
    // fixes a bug where the component retains focus after clicking
    spanRef.current?.blur();
    // fixes a bug where the event getting twice call on single click
    e.stopPropagation();
    return;
  };

  React.useImperativeHandle(ref, () => ({
    click: (evt: SyntheticEvent<>) => maybeSetLocalState(evt),
  }));

  const checkFillColor = checkedValue === 'false' ? 'white' : 'black';

  return (
    <span
      className={classify(
        dsCss.defaultBorder,
        dsCss.default,
        css.checkbox,
        {[css.disabledCheckbox]: disabled},
        className,
      )}
      ref={spanRef}
      role="checkbox"
      aria-checked={checkedValue}
      tabIndex={tabIndex}
      aria-disabled={disabled}
      checked={checkedValue === 'true'}
      onClick={maybeSetLocalState}
      onKeyDown={(evt: SyntheticKeyboardEvent<>) => {
        if (disabled) {
          return;
        }
        if ([' ', 'Enter'].includes(evt.key)) {
          maybeSetLocalState(evt);
          evt.preventDefault();
        }
      }}
    >
      {checkedValue === 'true' ? (
        <CheckIcon className={css.checkIcon} />
      ) : checkedValue === 'mixed' ? (
        <MinusIcon className={css.mixedIcon} />
      ) : null}
    </span>
  );
}

export const Checkbox: React.AbstractComponent<CheckboxProps, CheckboxRef> =
  React.forwardRef<CheckboxProps, CheckboxRef>(CheckboxBase);

export const LabeledCheckbox = ({
  children,
  onChange,
  checked,
  disabled,
  className,
}: {
  children?: React.Node,
  onChange?: (CheckedValue) => mixed,
  checked?: CheckedValue,
  disabled?: boolean,
  className?: string,
}): React.Node => {
  const [localCheckedValue, setLocalCheckedValue] =
    React.useState<CheckedValue>(checked ?? 'false');
  const checkedValue = onChange == null ? localCheckedValue : checked;
  const checkboxRef = React.useRef<?CheckboxRef>(null);

  return (
    <label
      className={classify(
        className,
        css.labeledCheckboxContainer,
        disabled
          ? css.disabledContainer
          : checkedValue === 'false'
          ? css.uncheckedContainer
          : css.checkedContainer,
      )}
      onClickCapture={(evt: SyntheticMouseEvent<>) =>
        checkboxRef.current?.click(evt)
      }
    >
      <Checkbox
        ref={checkboxRef}
        className={css.labeledCheckbox}
        checked={checkedValue}
        disabled={disabled}
        onChange={(e) => {
          if (disabled) {
            return;
          }
          if (onChange == null) {
            setLocalCheckedValue(e);
          } else {
            onChange(e);
          }
        }}
      />
      {children != null && (
        <Clickable className={css.labeledCheckboxLabel}>{children}</Clickable>
      )}
    </label>
  );
};
