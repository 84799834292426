// @flow strict

//$FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';
/*
 * Base Selectors
 */

export const selectFullscreen = (state: State): boolean =>
  state.appConfig.fullscreen;
