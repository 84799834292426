// @noflow
import typeof {IndexStore} from 'src/stores/index';
import type {ConfirmOnChangeProps} from 'src/components/auth/password-confirmation.jsx';

import * as React from 'react';
import {Helmet} from 'react-helmet';
import isEmpty from 'lodash/isEmpty';
import entries from 'lodash/entries';

import FluxComponent from 'src/flux/component.jsx';
import PasswordConfirmation, {
  ErrorList,
} from 'src/components/auth/password-confirmation.jsx';
import {resetPassword, updatePassword} from 'src/actions';

import css from './auth.css';


const mappers = {
  resetToken: (
    store,
    {
      location: {
        query: {reset_id},
      },
    },
  ) => reset_id,
};

class ResetContainer extends React.Component<
  {
    store: IndexStore,
    router: any,
    children: React.Element<any>,

    resetToken: string,
  },
  $FlowFixMeState,
> {
  state = {
    buttonDisabled: true,
    password: undefined,
    oldPassword: '',
    errorsByField: {},
    score: 0,
  };

  render() {
    const {resetToken} = this.props;
    const {errorsByField, buttonDisabled} = this.state;

    return (
      <form className={css.form} onSubmit={this.handlePasswordReset}>
        <Helmet title="Reset Password" />

        {!isEmpty(errorsByField.form) && (
          <ErrorList
            errors={entries(errorsByField.form)}
            className={css.inlineErrorListCenter}
          />
        )}

        {!resetToken && (
          <label className={css.formLabel}>
            {' '}
            Current password:
            <input
              autoFocus={!resetToken}
              className={css.passwordInput}
              type="password"
              name="original-password"
              value={this.state.oldPassword}
              onChange={(evt: SyntheticInputEvent<>) =>
                this.setState({oldPassword: evt.target.value})
              }
            />
            {!isEmpty(errorsByField.oldPassword) && (
              <ErrorList
                errors={entries(errorsByField.oldPassword)}
                className={css.inlineErrorListRight}
              />
            )}
          </label>
        )}

        <PasswordConfirmation
          autoFocus={!!resetToken}
          onChange={this.handlePasswordUpdate}
          handleScoreChange={(zxcvbnData) =>
            this.setState({passwordScore: zxcvbnData?.score ?? 0})
          }
          errorsByField={errorsByField}
        />

        <button
          disabled={buttonDisabled}
          className={css.resetButton}
          type="submit"
        >
          Set New Password
        </button>
      </form>
    );
  }

  handlePasswordUpdate = (data: ConfirmOnChangeProps) => {
    if (data.newPassword === data.confirmPassword) {
      if (data.newPassword.length > 9 && this.state.passwordScore > 2) {
        this.setState({
          buttonDisabled: false,
          password: data.newPassword,
        });
      }
    } else {
      this.setState({buttonDisabled: true});
    }
  };

  handlePasswordReset = (evt: Event) => {
    evt.preventDefault();

    const {router, store, resetToken} = this.props;
    const {oldPassword, password} = this.state;

    if (password == null) {
      return;
    }

    this.setState({buttonDisabled: true});

    const action = resetToken
      ? resetPassword(store, resetToken, password)
      : updatePassword(store, oldPassword, password);

    action.then(
      () => {
        router.replace('/signin');
        this.setState({buttonDisabled: false});
      },
      (err) => {
        const errors = err.response.errors;
        if (errors.indexOf('PasswordIncorrect') > -1) {
          this.setState({
            errorsByField: {oldPassword: {incorrect: 'Old password incorrect'}},
          });
        } else if (errors.indexOf('ExistingPasswordReused') > -1) {
          this.setState({
            errorsByField: {
              newPassword: {noReuse: 'Can\'t reuse current password'},
            },
          });
        } else {
          this.setState({
            errorsByField: {
              form: {generic: 'Error changing password. Please try again.'},
            },
          });
        }

        this.setState({buttonDisabled: false});
      },
    );
  };
}

export default FluxComponent(ResetContainer, mappers);
