// @flow

import type {Option, Options} from './types';

import * as React from 'react';

import logger from 'src/utils/logger';
import {groupBy, sortBy, flat} from 'src/utils/iterable';


const proceedKeys = new Set(['Tab', 'Enter', ',']);

export type SenseTypeaheadProps<V = Option> = {
  options: V[],
  searchable?: boolean,
  excludedValues?: V[],
  allowArbitraryValues?: boolean,
  showSugggestionOnPaste?: boolean,
  allowMultiArbitraryValues?: boolean,
  validateArbitraryValue?: (string) => boolean,
  makeArbitraryValue?: (string) => V,
  formatArbitraryOptionLabel?: (string) => string,
  searchOnEmptyString?: boolean,
  groupOptionsBy?: (V) => string,
  sortOptionsBy?: (V, V) => number,
  onInputChange?: (string) => mixed,
  onInputFocus?: () => mixed,
  isArbitrary?: (V) => boolean,
  resolveKey: (V) => string,
  resolveLabel: (V) => string | React.Element<'div'>,
};

export function useSenseTypeahead<V>({
  options,
  onAddOption,
  searchable,
  isLimited,
  excludedValues = [],
  allowArbitraryValues,
  showSugggestionOnPaste,
  allowMultiArbitraryValues,
  validateArbitraryValue = () => true,
  // $FlowFixMe[incompatible-type]
  makeArbitraryValue = (searchTerm) => ({
    value: searchTerm,
    label: searchTerm,
    arbitrary: true,
  }),
  // $FlowFixMe[incompatible-use]
  isArbitrary = (option) => option.arbitrary,
  formatArbitraryOptionLabel = (value) => `Create "${value}"`,
  searchOnEmptyString = true,
  groupOptionsBy,
  sortOptionsBy,
  onInputChange,
  onInputFocus,
  resolveKey,
  resolveLabel,
}: {
  ...SenseTypeaheadProps<V>,
  onAddOption: (V) => mixed,
  isLimited?: boolean,
  ...
}): [
  TypeaheadState,
  V[],
  Map<string, V[]>,
  {
    readOnly: ?boolean,
    value: string,
    onChange: (SyntheticEvent<HTMLInputElement>) => mixed,
    onBlur: (SyntheticEvent<HTMLInputElement>) => mixed,
    onFocus: (SyntheticEvent<HTMLInputElement>) => mixed,
    onKeyDown: (SyntheticKeyboardEvent<HTMLInputElement>) => mixed,
    ref: {current: ?HTMLInputElement},
    onPaste?: (event: ClipboardEvent) => mixed,
  },
  (SyntheticMouseEvent<HTMLInputElement>) => void,
  (V) => void,
  {
    isSearching: boolean,
    focused: boolean,
    isShowingOptions: boolean,
  },
] {
  const [state, dispatch] = React.useReducer(
    reduceWithSearchTerm,
    null,
    getDefaultState,
  );
  const {searchTerm, focused} = state;
  const trimmedSearchTerm = searchTerm.trim();

  const makeMultiArbitraryValue = (searchTerm) => {
    const regex = /(?=.*[,\n])^[\d\w\s,]+$/;
    if (regex.test(searchTerm)) {
      return {
        value: searchTerm,
        label: searchTerm,
        multiArbitrary: true,
      };
    }
  };

  const handleSelect = (option: V) => {
    dispatch({type: 'search', payload: ''});
    if (isShowingOptions) {
      onAddOption(option);
    }
  };

  let [filteredOptions, groupedOptions] = useFilteredOptions({
    searchTerm,
    options,
    excludedValues,
    groupOptionsBy,
    sortOptionsBy,
    resolveKey,
    resolveLabel,
  });

  const arbitraryOption: V | false | void | null =
    allowArbitraryValues &&
    // $FlowFixMe this is stupid. this string should never be the result
    trimmedSearchTerm &&
    !options.some((option) => resolveKey(option) === trimmedSearchTerm) &&
    !excludedValues.includes(trimmedSearchTerm) &&
    validateArbitraryValue(trimmedSearchTerm) &&
    makeArbitraryValue(trimmedSearchTerm);

  if (arbitraryOption) {
    filteredOptions = [arbitraryOption, ...filteredOptions];
    // TODO (kyle): should actions even be in the groups?
    groupedOptions = new Map<string, V[]>([
      ['actions', [arbitraryOption]],
      ...groupedOptions,
    ]);
  }

  const multiArbitraryOption: V | false | void | null =
    arbitraryOption &&
    allowMultiArbitraryValues &&
    showSugggestionOnPaste &&
    // $FlowFixMe this is stupid. this string should never be the result
    trimmedSearchTerm &&
    !options.some((option) => resolveKey(option) === trimmedSearchTerm) &&
    !excludedValues.includes(trimmedSearchTerm) &&
    validateArbitraryValue(trimmedSearchTerm) &&
    // $FlowFixMe
    makeMultiArbitraryValue(trimmedSearchTerm);

  if (multiArbitraryOption) {
    filteredOptions = [...filteredOptions, multiArbitraryOption];
    const currentActions = groupedOptions.get('actions') || [];
    groupedOptions.set('actions', [...currentActions, multiArbitraryOption]);
  }

  const isSearching = Boolean(focused && searchable && !isLimited);
  const isShowingOptions = Boolean(
    focused &&
      // TODO (kyle): why is this here?
      !isLimited &&
      filteredOptions.length > 0 &&
      (trimmedSearchTerm || searchOnEmptyString),
  );

  const [{onBlur, ...inputPropsFromUseTypeahead}, handleContainerMouseDown] =
    useTypeahead({
      state,
      dispatch,
      options: filteredOptions,
      onAddOption: handleSelect,
      onInputFocus,
    });

  let inputProps = {
    readOnly: !isSearching,
    value: searchTerm,
    onChange: (event: SyntheticEvent<HTMLInputElement>) => {
      const {
        currentTarget: {value},
      } = event;
      dispatch({
        type: 'search',
        payload: value,
      });
      onInputChange && onInputChange(value);
    },
    onBlur: (event: SyntheticEvent<HTMLInputElement>) => {
      if (arbitraryOption) {
        handleSelect(arbitraryOption);
      }
      onBlur(event);
    },
    ...inputPropsFromUseTypeahead,
  };

  if (showSugggestionOnPaste) {
    inputProps = {
      ...inputProps,
      onPaste: (event: ClipboardEvent) => {
        if (!allowMultiArbitraryValues) {
          return;
        }
        event.preventDefault();
        const value = event.clipboardData?.getData('text');
        if (value && value.length) {
          dispatch({type: 'search', payload: value});
          onInputChange && onInputChange(value);
        }
      },
    };
  }

  return [
    state,
    filteredOptions,
    groupedOptions,
    inputProps,
    handleContainerMouseDown,
    handleSelect,
    {
      isSearching,
      focused,
      isShowingOptions,
    },
  ];
}
export function useFilteredOptions<V>({
  searchTerm,
  options,
  excludedValues = [],
  groupOptionsBy,
  sortOptionsBy,
  // $FlowFixMe[incompatible-use]
  resolveLabel = (option) => option.label,
  // $FlowFixMe[incompatible-use]
  resolveKey = (option) => option.value,
  searchOptionsBy = (option, searchTerm) => {
    // debugger;
    // let labelContains = false,
    //   keyContains = false;
    const resolvedLabelRaw = resolveLabel(option);
    const resolvedLabel =
      typeof resolvedLabelRaw === 'string' ? resolvedLabelRaw : String(option);
    const resolvedKey = resolveKey(option);
    if (resolvedKey == null) {
      logger.warning(`Failed to resolve key for option`, option);
    }
    if (resolvedLabel == null) {
      logger.warning(`Failed to resolve label for following option`, option);
    }
    const labelContains =
      resolvedLabel?.toLowerCase().includes(searchTerm) ?? false;
    const keyContains =
      resolvedKey?.toLowerCase().includes(searchTerm) ?? false;

    return labelContains || keyContains;
  },
}: {
  searchTerm: string,
  options: V[],
  excludedValues: V[],
  groupOptionsBy?: (V) => string,
  sortOptionsBy?: (V, V) => number,
  resolveLabel?: (V) => string | React.Element<'div'>,
  resolveKey?: (V) => string,
  searchOptionsBy?: (V, string) => boolean,
}): [V[], Map<string, V[]>] {
  return React.useMemo(() => {
    const trimmedValue = searchTerm.trim().toLowerCase();
    // NOTE (kyle): this is not performance sensitive but could be in the future. could use caching/indexing if needed.
    let filteredOptions: V[] = options
      ? options.filter((option) => !excludedValues.includes(option))
      : [];

    if (trimmedValue) {
      filteredOptions = filteredOptions.filter((option) =>
        searchOptionsBy(option, trimmedValue),
      );
    }

    if (sortOptionsBy) {
      filteredOptions = filteredOptions.slice().sort(sortOptionsBy);
    }

    let groupedOptions;
    if (groupOptionsBy) {
      groupedOptions = sortBy(
        groupBy(filteredOptions, groupOptionsBy),
        ([key1], [key2]) => key1.localeCompare(key2),
      );
      filteredOptions = Array.from(flat(groupedOptions.values()));
    } else {
      groupedOptions = new Map();
    }

    return [filteredOptions, groupedOptions];
  }, [searchTerm, options, excludedValues, sortOptionsBy]);
}

type InputProps = {
  onBlur: (_event: SyntheticEvent<HTMLInputElement>) => void,
  onFocus: (_event: SyntheticEvent<HTMLInputElement>) => void,
  onKeyDown: (event: SyntheticKeyboardEvent<HTMLInputElement>) => void,
  ref: {current: ?HTMLInputElement},
  readOnly?: ?boolean,
};

export function useTypeahead<V>({
  state,
  dispatch,
  options,
  onAddOption,
  onInputFocus,
  // $FlowFixMe[incompatible-use]
  isOptionDisabled = (option) => option.disabled,
}: {
  state: TypeaheadState,
  dispatch: (TypeaheadAction) => void,
  options: V[],
  onAddOption: (V) => mixed,
  onInputFocus?: () => mixed,
  isOptionDisabled?: (V) => boolean,
}): [InputProps, (SyntheticMouseEvent<HTMLInputElement>) => void] {
  const inputElement = React.useRef<?HTMLInputElement>();
  const {selectedIndex, focused} = state;

  /*
  React.useEffect(() => {
    setState({...state, selectedIndex: 0});
  }, [filteredOptions]);
  */

  const handleFocus = (_event: SyntheticEvent<HTMLInputElement>) => {
    dispatch({type: 'focus'});
    onInputFocus && onInputFocus();
  };

  const handleBlur = (_event: SyntheticEvent<HTMLInputElement>) => {
    dispatch({type: 'blur'});
  };

  const handleInputKeyDown = (
    event: SyntheticKeyboardEvent<HTMLInputElement>,
  ) => {
    ///const value = event.currentTarget.value;
    const key = event.key;
    //const text = value.trim();

    if (proceedKeys.has(key)) {
      //if (isShowingOptions || key !== 'Tab') {
      //event.preventDefault();

      if (
        options &&
        options.length > 0
        //selectionKeys.has(key)
      ) {
        event.preventDefault();
        onAddOption(options[selectedIndex]);
      } else {
        // TODO (kyle): not sure we want this
        //onAddOption({value, label: value, arbitrary: true});
      }
      //}
    } else if (key === 'ArrowDown') {
      event.preventDefault();
      let nextSelectedIndex = selectedIndex + 1;

      while (
        nextSelectedIndex < options.length &&
        options[nextSelectedIndex] != null &&
        isOptionDisabled(options[nextSelectedIndex])
      ) {
        nextSelectedIndex++;
      }

      if (nextSelectedIndex < options.length) {
        dispatch({type: 'select_index', payload: nextSelectedIndex});
      }
    } else if (key === 'ArrowUp') {
      event.preventDefault();
      let nextSelectedIndex = selectedIndex - 1;

      while (
        nextSelectedIndex >= 0 &&
        options[nextSelectedIndex] != null &&
        isOptionDisabled(options[nextSelectedIndex])
      ) {
        nextSelectedIndex--;
      }

      if (nextSelectedIndex >= 0) {
        dispatch({type: 'select_index', payload: nextSelectedIndex});
      }
    } else if (key === 'Escape') {
      event.preventDefault();
      inputElement.current && inputElement.current.blur();
    }
  };

  const handleContainerMouseDown = (event: SyntheticEvent<HTMLElement>) => {
    const input = inputElement.current;
    if (focused && input === document.activeElement && event.target !== input) {
      event.preventDefault();
    } else if (input && !focused) {
      event.preventDefault();
      input.focus();
      input.select();
    }
  };

  return [
    {
      ref: inputElement,
      onKeyDown: handleInputKeyDown,
      onFocus: handleFocus,
      onBlur: handleBlur,
    },
    handleContainerMouseDown,
  ];
}

export function useTokenInput({
  onAddValue,
  onBackspace,
  onInputFocus,
}: {
  onAddValue: (string) => mixed,
  onBackspace: () => mixed,
  onInputFocus?: () => mixed,
}): [
  TypeaheadState,
  (TypeaheadAction) => void,
  {
    ...InputProps,
    value: string,
    onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
  },
  (SyntheticMouseEvent<HTMLInputElement>) => void,
] {
  const [state, dispatch] = React.useReducer(
    reduceWithSearchTerm,
    null,
    getDefaultState,
  );

  const inputElement = React.useRef<?HTMLInputElement>();
  const {focused} = state;

  const handleFocus = (_event: SyntheticEvent<HTMLInputElement>) => {
    dispatch({type: 'focus'});
    onInputFocus && onInputFocus();
  };

  const handleBlur = (_event: SyntheticEvent<HTMLInputElement>) => {
    dispatch({type: 'blur'});
    if (state.searchTerm) {
      onAddValue(state.searchTerm);
    }
  };

  const handleInputKeyDown = (
    event: SyntheticKeyboardEvent<HTMLInputElement>,
  ) => {
    const value = event.currentTarget.value;
    const key = event.key;

    if (proceedKeys.has(key)) {
      if (value.trim()) {
        event.preventDefault();
        dispatch({type: 'search', payload: ''});
        onAddValue(value);
      }
    } else if (key === 'Backspace') {
      if (!value) {
        onBackspace();
      }
    } else if (key === 'Escape') {
      event.preventDefault();
      dispatch({type: 'search', payload: ''});
      setTimeout(() => {
        inputElement.current && inputElement.current.blur();
      }, 0);
    }
  };

  const handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    dispatch({type: 'search', payload: event.currentTarget.value});
  };

  const handleContainerMouseDown = (event: SyntheticEvent<HTMLElement>) => {
    const input = inputElement.current;
    if (focused && input === document.activeElement && event.target !== input) {
      event.preventDefault();
    } else if (input && !focused) {
      event.preventDefault();
      input.focus();
      input.select();
    }
  };

  return [
    state,
    dispatch,
    {
      ref: inputElement,
      value: state.searchTerm,
      onKeyDown: handleInputKeyDown,
      onFocus: handleFocus,
      onBlur: handleBlur,
      onChange: handleChange,
    },
    handleContainerMouseDown,
  ];
}

export function useSelect<V>({
  onAddOption = () => undefined,
  ...args
}: {
  options: V[],
  onAddOption?: (V) => mixed,
}): [
  TypeaheadState,
  InputProps,
  (SyntheticMouseEvent<HTMLInputElement>) => void,
] {
  const [state, dispatch] = React.useReducer(
    reduceDefault,
    null,
    getDefaultState,
  );

  const [inputProps, handleContainerMouseDown] = useTypeahead({
    state,
    dispatch,
    onAddOption,
    ...args,
  });

  return [state, {readOnly: true, ...inputProps}, handleContainerMouseDown];
}

/*
export function useTypeaheadWithArbitraryValues({
  state,
  dispatch,
  options,
  onAddOption,
  validateArbitraryValue,
  resolveArbitraryOptionLabel,
}) {
  const {searchTerm} = state;
  const trimmedSearchTerm = searchTerm.trim();
  const hasValidArbitraryValue =
    trimmedSearchTerm && validateArbitraryValue(trimmedSearchTerm);

  if (hasValidArbitraryValue) {
    options = [
      {
        value: trimmedSearchTerm,
        label: resolveArbitraryOptionLabel(trimmedSearchTerm),
        arbitrary: true,
      },
      ...options,
    ];
  }

  const [{onBlur, ...inputProps}, ...hookStuff] = useTypeahead({
    state,
    dispatch,
    options,
    onAddOption,
  });

  const handleBlur = event => {
    if (hasValidArbitraryValue) {
      onAddOption({
        value: trimmedSearchTerm,
        label: trimmedSearchTerm,
        arbitrary: true,
      });
    }
    onBlur(event);
  };

  return [{onBlur: handleBlur, ...inputProps}, ...hookStuff];
}
*/

type TypeaheadState = {
  searchTerm: string,
  focused: boolean,
  selectedIndex: number,
};

type SuggestionsAction =
  | {
      type: 'focus',
    }
  | {type: 'blur'}
  | {type: 'select_index', payload: number};

type TypeaheadAction = SuggestionsAction | {type: 'search', payload: string};

export const getDefaultState = (): TypeaheadState => ({
  searchTerm: '',
  focused: false,
  selectedIndex: 0,
});

export function reduceDefault(
  state: TypeaheadState,
  action: TypeaheadAction,
): {focused: boolean, searchTerm: string, selectedIndex: number} {
  switch (action.type) {
    case 'focus':
      return {...state, focused: true};
    case 'blur':
      return {...state, focused: false};
    case 'select_index':
      return {...state, selectedIndex: action.payload};
    default:
      throw new Error(`Invalid action type, ${action.type}`);
  }
}

export function reduceWithSearchTerm(
  state: TypeaheadState,
  action: TypeaheadAction,
): {focused: boolean, searchTerm: string, selectedIndex: number} {
  switch (action.type) {
    case 'search':
      return {...state, searchTerm: action.payload, selectedIndex: 0};
    case 'blur':
      return {...state, focused: false, searchTerm: '', selectedIndex: 0};
    default:
      return reduceDefault(state, action);
  }
}
