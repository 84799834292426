// @flow strict
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useEnvironmentContext} from 'src/hooks/useEnvironmentContext';
// eslint-disable-next-line import/no-unresolved
import {getCountryCallingCode} from 'libphonenumber-js';
// $FlowFixMe[nonstrict-import]
import useAgencyConfig from 'src/hooks/useAgencyConfig';

import {validatePhone} from 'src/utils/phone';
import {ERROR_CODES} from 'src/components/authentication/errors.js';
import {
  CONSTANTS,
  ALLOWED_COUNTRY_CODES,
} from 'src/components/authentication/contants.js';
import {AuthFlow} from 'src/components/authentication/auth-flow.js';
import {Input} from '@spaced-out/ui-design-system/lib/components/Input';
import {
  TitleMedium,
  SubTitleMedium,
  BodySmall,
  BodyLarge,
  FormLabelSmall,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {InContextAlert} from '@spaced-out/ui-design-system/lib/components/InContextAlert';
import {Dropdown} from '@spaced-out/ui-design-system/lib/components/Dropdown';
import type {MenuOption} from '@spaced-out/ui-design-system/lib/components/Menu/Menu';

import type {
  AuthSideBarComp,
  PhoneRegistrationFormProps,
} from 'src/types/authentication.js';

import {registerNewPhone} from 'src/action-creators/authentication.js';

import {
  selectUserLoginId,
  selectUserEmail,
  selectNewPhoneRegistrationError,
} from 'src/selectors/authentication';

import css from './phone-registration.css';
import maincss from 'src/components/authentication/main.css';


const PhoneRegistration = (props: AuthSideBarComp): React.Node => {
  const {goToPage} = props;
  const countryCallPrefix = [];
  // $FlowFixMe[prop-missing]
  const regionNames = new Intl.DisplayNames(['en'], {
    type: 'region',
    locale: 'en-GB',
  });
  ALLOWED_COUNTRY_CODES.forEach((countryCode) => {
    const dialCodeFromLibrary = getCountryCallingCode(countryCode);
    const dialCode = `+${dialCodeFromLibrary}`;
    const countryName = regionNames.of(countryCode);
    if (!countryCallPrefix.includes(dialCode)) {
      countryCallPrefix.push({
        label: `(${dialCode}) ${countryName}`,
        key: dialCode,
      });
    }
  });

  const defaultPhoneCountry = useAgencyConfig('defaultPhoneCountry');

  let defaultCode;
  if (defaultPhoneCountry) {
    defaultCode = {
      label: `+${getCountryCallingCode(defaultPhoneCountry)}`,
      key: `+${getCountryCallingCode(defaultPhoneCountry)}`,
    };
  } else {
    defaultCode = {
      label: '+1',
      key: '+1',
    };
  }

  return (
    <PhoneRegistrationForm
      goToPage={goToPage}
      countryCodes={countryCallPrefix}
      defaultCode={defaultCode}
    />
  );
};

const PhoneRegistrationForm = (
  props: PhoneRegistrationFormProps,
): React.Node => {
  const {goToPage, countryCodes, defaultCode} = props;
  const dispatch = useDispatch();
  const userEmail = useSelector(selectUserEmail);
  const loginId = useSelector(selectUserLoginId);
  const apiError = useSelector(selectNewPhoneRegistrationError);

  const [countryCode, setCountryCode] = React.useState<MenuOption>(defaultCode);
  const [userPhone, setUserPhone] = React.useState<string>('');
  const [phoneError, setPhoneError] = React.useState<string>('');
  const [isSendingOtp, setIsSendingOtp] = React.useState<boolean>(false);

  const sentOtpForPhone = async () => {
    const CountryPrefix = countryCode.key;
    const prefix = CountryPrefix ? CountryPrefix : '';
    const enteredPhone = `${prefix}${userPhone}`;

    const countryPrefixLength = CountryPrefix ? CountryPrefix.length : 0;
    if (
      enteredPhone.length !== 10 + countryPrefixLength ||
      !validatePhone(enteredPhone)
    ) {
      setPhoneError(ERROR_CODES['InvalidPhoneEntered']);
      return;
    } else {
      setPhoneError('');
    }

    setIsSendingOtp(true);
    const apiResponse = await dispatch(
      registerNewPhone(enteredPhone, userEmail, loginId),
    );
    setIsSendingOtp(false);
    if (apiResponse) {
      goToPage(AuthFlow.SMS_REGISTRATION.OTP_INPUT);
    }
  };

  const handleCountryCodeChange = (value) => {
    setCountryCode(value);
  };

  const handlePhoneChange = (value) => {
    if (phoneError) {
      setPhoneError('');
    }
    setUserPhone(value);
  };

  const {isExtension} = useEnvironmentContext();

  return (
    <div className={css.phoneRegistrationPage}>
      <div className={css.titleSection}>
        {isExtension ? (
          <SubTitleMedium className={maincss.alignSelfCenter}>
            {CONSTANTS.PHONE_REGISTARTION_TITLE}
          </SubTitleMedium>
        ) : (
          <TitleMedium>{CONSTANTS.PHONE_REGISTARTION_TITLE}</TitleMedium>
        )}
        {isExtension ? (
          <BodySmall className={maincss.textAlignCenter}>
            {CONSTANTS.PHONE_REGISTARTION_SUBTEXT}
          </BodySmall>
        ) : (
          <BodyLarge className={maincss.subText}>
            {CONSTANTS.PHONE_REGISTARTION_SUBTEXT}
          </BodyLarge>
        )}
      </div>
      <div className={css.inputSection}>
        <FormLabelSmall color="secondary">Phone number</FormLabelSmall>
        <div>
          <div>
            <Dropdown
              onChange={(e) => handleCountryCodeChange(e)}
              dropdownInputText={countryCode.key}
              menu={{
                options: countryCodes,
                selectedKeys: [countryCode.key],
                classNames: {wrapper: css.menuWrapper},
              }}
              size="medium"
              classNames={{wrapper: css.countryDropDown}}
            />
            <div className={css.phoneInput}>
              <Input
                size="medium"
                type="tel"
                value={userPhone}
                onChange={(e) => {
                  handlePhoneChange(e.target.value);
                }}
                error={!!phoneError}
                errorText={phoneError}
              />
            </div>
          </div>
        </div>
        <Button
          size="medium"
          type="primary"
          classNames={{wrapper: css.nextBtn}}
          onClick={sentOtpForPhone}
          disabled={isSendingOtp}
        >
          {isSendingOtp ? 'Sending Otp' : 'Next'}
        </Button>

        {!!apiError && (
          <InContextAlert
            semantic="danger"
            classNames={{wrapper: maincss.apiError}}
          >
            {ERROR_CODES[apiError] || apiError}
          </InContextAlert>
        )}
      </div>
    </div>
  );
};

export default PhoneRegistration;
