// @flow

import parseISO from 'date-fns/parseISO';


const META_INCOMING = 'incoming';
const META_OUTGOING = 'outgoing';
const META_SENT_BROADCAST = 'sent-broadcast';
const META_SCHEDULED_BROADCAST = 'scheduled-broadcast';
const META_CORE_MESSAGES = 'coreMessages';

class MessagingMetadataDateTimeFormatter {
  timeFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat(undefined, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  dateFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat(undefined, {
    month: 'long',
    day: 'numeric',
  });

  getFormattedDateTimeAndYear(timestamp: string): [string, string] {
    const date = parseISO(timestamp);
    const time = this.timeFormat.format(date);
    const dateNmonth = this.dateFormat.format(date);
    const year = date.getFullYear();

    return [time, `${dateNmonth}, ${year}`];
  }
}

export {
  META_INCOMING,
  META_OUTGOING,
  META_SENT_BROADCAST,
  META_SCHEDULED_BROADCAST,
  META_CORE_MESSAGES,
  MessagingMetadataDateTimeFormatter,
};
