// @flow

import * as React from 'react';

import logger from 'src/utils/logger';

import * as api from 'src/utils/api-no-store';
import {createElement} from 'src/utils/dom';

import css from './beefree-editor.css';
import classify from 'src/utils/classify';


let editorLoaded = false;

// These are promises returned when invoking custom ui for beefree.
//
type DialogObjectPromise<T> = {
  label: string,
  handler: (resolve: (T) => mixed, reject: () => mixed) => mixed,
};

type SpecialLink = {
  type: string, // Used for grouping similar links together
  label: string,
  link: string,
};

// The name is the friendly string displayed in the ui and
// the value is the raw value inserted in the content
type NameValue = {name: string, value: string};

// see https://tools.ietf.org/html/rfc6902
type JSONPatchOperation =
  | {
      op: 'add' | 'remove' | 'replace' | 'test',
      path: string, // looks like '/key/subkey/subsubkey',
      value:
        | string
        | number
        | {}
        | boolean
        | null
        | Array<string | number | {} | boolean | null>,
    }
  | {
      op: 'move' | 'copy',
      path: string,
      from: string,
      value:
        | string
        | number
        | {}
        | boolean
        | null
        | Array<string | number | {} | boolean | null>,
    };

// see https://docs.beefree.io/tracking-message-changes/#callback-parameters
export type OnChangeResponse = {
  code: string, // actually an enum like "01" | "02" | ...
  description: string,
  value: string,
  patches: JSONPatchOperation[],
};

// TODO(marcos): figure out what this is so we can port it to unlayer
export type BeefreeDocument = {...};

// Many of these props are defined by the beefree editor here:
//   https://docs.beefree.io/configuration-parameters/
type BeeFreeProps = {
  initialTemplate: BeefreeDocument, // this is a json beefree doc to initialize the
  // editor with (probably from a prior save)

  uid: string, // This value should probably be the user id of the current
  // person using the webapp, it is used to track the number
  // of billable users for befree and it prevents users from
  // seeing each other's content
  container?: string, // this is the name of a unique dom element that
  // beefree can take over.
  autosave?: number, // default: false
  language?: string, // default 'en-US'
  trackChanges?: boolean, // default: false
  specialLinks?: SpecialLink[], // default: []
  mergeTags?: NameValue[], // default: []
  mergeContents?: NameValue[], // default: []
  preventClose?: boolean, // default false
  editorFonts?: {...},
  roleHash?: string, // default ""
  rowDisplayConditions?: {}, // default {}
  // almost everywhere that bee emits json in a callback it really is
  // emitting a stringified json blob.
  onSave?: (jsonFile: string, htmlFile: string) => mixed,
  onChange?: (jsonFile: string, response: OnChangeResponse) => mixed,
  onSaveAsTemplate?: (jsonFile: string) => mixed,
  onAutoSave?: (jsonFile: string) => mixed,
  onSend?: (htmlFile: string) => mixed,
  onLoad?: (jsonFile: string) => mixed,
  onError?: (errorMessage: string) => mixed,
  onWarning?: (alertMessage: string) => mixed,
  onTogglePreview?: (isPreviewing: boolean) => mixed,
  contentDialog?: {
    specialLinks?: DialogObjectPromise<SpecialLink>,
    mergeTags?: DialogObjectPromise<NameValue>,
    mergeContents?: DialogObjectPromise<NameValue>,
    rowDisplayConditions?: DialogObjectPromise<{
      type: string,
      label: string,
      description: string,
      before: string,
      after: string,
    }>,
    addOn?: DialogObjectPromise<mixed>,
    externalContentURLs?: DialogObjectPromise<NameValue>,
  },
  translations?: {
    [editorComponentName: string]: {[labelIdentifier: string]: string},
  },
  containerClassName?: string,
  customCss?: string,
};

// This is the type of the imperativeHandle attached by useImperativeHandle
type BeeFreeEditorRef = {
  handleSave: () => mixed,
  togglePreview: () => mixed,
  toggleStructure: () => mixed,
  handleSaveTemplate: () => mixed,
  handleLoad: (doc?: BeefreeDocument) => mixed,
};

const BeefreeEditor = (
  {uid, initialTemplate, onSave, containerClassName, ...props}: BeeFreeProps,
  ref,
) => {
  const [id, setId] = React.useState(null);
  const [beefreeScriptLoaded, setBeefreeScriptLoaded] = React.useState(false);
  const beeFreeInstanceRef = React.useRef();

  React.useImperativeHandle(ref, () => ({
    handleSave: () => {
      // This is not used right now, but it's present in case we
      // decide to support this sort of interaction.
      // $FlowOptionalCall
      beeFreeInstanceRef.current?.save();
    },
    togglePreview: () => {
      // $FlowOptionalCall
      beeFreeInstanceRef.current?.togglePreview();
    },
    toggleStructure: () => {
      // $FlowOptionalCall
      beeFreeInstanceRef.current?.toggleStructure();
    },
    // this method flushes the current json state of the beefree editor
    // into the onSaveAsTemplate
    handleSaveTemplate: () => {
      beeFreeInstanceRef.current?.saveAsTemplate();
    },

    // this causes the editor to load this new doc (not a string, an object)
    handleLoad: (doc?: BeefreeDocument) => {
      beeFreeInstanceRef.current?.load(doc);
    },
  }));

  React.useEffect(() => {
    if (!editorLoaded) {
      const script = createElement(document, 'script', {
        src: 'https://app-rsrc.getbee.io/plugin/BeePlugin.js',
      });
      script.onload = () => {
        setBeefreeScriptLoaded(true);
      };
      // $FlowOptionalCall
      document.body?.appendChild(script);
      editorLoaded = true;
    } else {
      setBeefreeScriptLoaded(true);
    }
    const id = 'beefree-' + Math.round(Math.random() * 10000000).toString();
    setId(id);
  }, []);

  React.useEffect(() => {
    if (editorLoaded && beefreeScriptLoaded) {
      api.post('agency/beefree-plugin-token').then(
        (response) => {
          if (id) {
            window.BeePlugin.create(
              response,
              {
                uid,
                container: id,
                onSave,
                ...props,
              },
              (instance) => {
                // once the editor has been created, beeFreeInstanceRef is the
                // forwarded ref for this component
                beeFreeInstanceRef.current = instance;

                // load the editor with the template passed in via props
                instance.start(initialTemplate);
              },
            );
          }
        },
        (error) => {
          logger.error(error);
          logger.error('beefree token fetching threw an error');
        },
      );
    }
  }, [onSave, beefreeScriptLoaded]);

  return (
    <div
      id={id}
      className={classify(css.editorContainer, containerClassName)}
    />
  );
};

export default (React.forwardRef<BeeFreeProps, BeeFreeEditorRef>(
  BeefreeEditor,
): React$AbstractComponent<BeeFreeProps, BeeFreeEditorRef>);
