// @flow strict

import type {Task} from 'src/components/agency-onboard/types/side-panel-comp.js';


export const AMPLITUDE_EVENTS = {
  GO_TO_DASHBOARD_CLICKED: 'Go to Dashboard clicked',
  GO_TO_TASK_CHECKLIST_CLICKED: 'Go to Task Checklist clicked',
  SUPPORT_BUTTON_CLICKED: 'Support Bubble clicked',
  LAST_TASK_SUBMIT_BUTTON_CLICKED: 'Last task submit button clicked',
  VERIFY_CREDS_BUTTON_CLICKED: 'Verify Credentials button clicked',
  TEN_DLC_SUBMIT_BUTTON_CLICKED: '10 DLC Submit button clicked',
  ADD_PHONE_NUMBER_SUBMIT_BUTTON_CLICKED:
    'Add phone number Submit button clicked',
  COPY_TEMPLATE_CLICKED: 'Copy template clicked',
  GO_TO_BULLHORN_SUPPORT_CLICKED: 'Go to Bullhorn Support clicked',
  ADD_REP_CLICKED: 'Add rep clicked',
  SUBMIT_BUTTON_CLICKED_WITH_TWO_REPS: 'Submit button clicked with two reps',
  DNS_EMAIL_BUTTON_CLICKED: 'Email button clicked',
  DNS_EXPORT_BUTTON_CLICKED: 'Export button clicked',
};
