// @flow strict

import type {
  LabelFilterListType,
  LabelFilterType,
  FilterOptionType,
} from 'src/designSystem2021Components/types/label-filter';
import type {OptionProps} from 'src/designSystem2021Components/menu/options.jsx';

import {
  Options,
  CheckboxOption,
  RadioOption,
} from 'src/designSystem2021Components/menu/options.jsx';

import * as React from 'react';

import {classify} from 'src/utils/classify';
//$FlowFixMe[nonstrict-import]
import ClickAway from 'src/components/lib/click-away';

import {LabelFilterComponent} from './filter-label.jsx';
import {FilterContainer} from './container/filter-container.jsx';

import css from 'src/designSystem2021Components/label-filter/label-filter.css';


export type LabelFilterProps<V> = {
  ContainerClassName?: string,
  optionClassName?: string,
  optionsClassName?: string,
  listType?: LabelFilterListType,
  filterType?: LabelFilterType,
  text?: string,
  options?: V[],
  onChange?: (item: string[]) => mixed,
  onClick?: () => mixed,
  resolveLabel?: (V) => string,
  resolveValue?: (V) => string,
  resolveDisabled?: (V, V[]) => boolean,
  value: string[] | string,
  disabled?: boolean,
  size?: 'standard' | 'small',
  anchorPosition?: 'left' | 'right',
  className?: string,
  OptionComponent?: React.ComponentType<OptionProps<V>>,
};

/**
 * an label that allows selecting a value from a limited set of options.
 *
 * @param  props.listType  the controlled type and it has two selection checkbox || radio
 * @param  props.onChange  the handler that is fired when the user tries to change the value and have selected Item
 * @param  props.onClick   the handle when filterType is panel
 * @param  props.value  this list will ensure pre selected value from option should be value of list
 * @param  props.filterType  label filter have two type panel and menu
 * @param  props.text  By Default text which will be visible for the user
 * @param  props.ContainerClassName  a custom className which will apply on filter label not list
 * @param  props.options  total list of allowed values for this field. will be used to provide suggestions.
 */

export const LabelFilter = <
  V:
    | {label: string, value: string, ...}
    | {label: string, value: string, disabled?: boolean, ...},
>({
  className,
  ContainerClassName,
  optionClassName,
  optionsClassName,
  filterType = 'menu',
  listType = 'checkbox',
  text,
  size,
  options = [],
  disabled,
  value,
  onChange = () => [],
  onClick = () => {},
  resolveLabel = (item) => item.label,
  resolveValue = (item) => item.value,
  resolveDisabled = (item, _) =>
    typeof item.disabled === 'boolean' ? item.disabled : false,
  anchorPosition = 'left',
  OptionComponent,
}: LabelFilterProps<V>): React.Node => {
  const items: string[] = Array.isArray(value) ? value : [];

  const handleOnSelect = (data) => {
    if (items.includes(resolveValue(data))) {
      onChange(items.filter((item) => item !== resolveValue(data)));
    } else {
      onChange([...items, resolveValue(data)]);
    }
  };

  return (
    <ClickAway>
      {({isOpen, onOpen, cancelNext}) => (
        <div className={classify(css.filter, className)}>
          <LabelFilterComponent
            className={ContainerClassName}
            size={size}
            text={text}
            activeFilters={items.length}
            onClick={(e) => {
              filterType === 'menu' ? onOpen() : onClick();
            }}
            isOpen={isOpen}
            disabled={disabled}
            filterType={filterType}
          />
          {!disabled && (
            <div
              className={classify(
                css.filterCard,
                {
                  [css.smallFilterCard]: size === 'small',
                  [css.left]: anchorPosition === 'left',
                  [css.right]: anchorPosition === 'right',
                },
                isOpen ? css.open : css.close,
              )}
              onClickCapture={cancelNext}
            >
              {filterType === 'menu' && options.length > 0 ? (
                <Options
                  options={options}
                  value={value}
                  resolveValue={resolveValue}
                  resolveLabel={resolveLabel}
                  resolveDisabled={resolveDisabled}
                  classNames={{option: optionClassName}}
                  onSelect={handleOnSelect}
                  OptionComponent={CheckboxOption}
                  classNames={{
                    options: optionsClassName,
                    option: optionClassName,
                  }}
                />
              ) : null}
            </div>
          )}
        </div>
      )}
    </ClickAway>
  );
};
