// @flow

import times from 'lodash/times';
import * as React from 'react';
import {Helmet} from 'react-helmet';

import {classify} from 'src/utils';

import './404.global.css';


type Stars = {
  gray: React.Node,
  red: React.Node,
  blue: React.Node,
  white: React.Node,
  pizzas: React.Node,
};

type NotFoundState = {
  componentDidMount: boolean,
  pos: number,
  stars: Stars,
};

export default class NotFound extends React.Component<
  {
    title: string,
  },
  NotFoundState,
> {
  static defaultProps: {title: string} = {
    title: '404',
  };

  state: NotFoundState = {
    componentDidMount: false,
    pos: 0,
    stars: {
      gray: this.stars('gray', 40),
      red: this.stars('red', 20),
      blue: this.stars('blue', 20),
      white: this.stars('white', 20),
      pizzas: this.pizzas(10),
    },
  };

  _boundScroll: () => void;

  componentDidMount() {
    // $FlowFixMe[method-unbinding]
    this._boundScroll = this.scroll.bind(this);
    window.addEventListener('scroll', this._boundScroll);
    this.setState({componentDidMount: true});
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._boundScroll);
  }

  render(): React.Node {
    return (
      <div className="notfound">
        <Helmet title={this.props.title} />
        {this.state.componentDidMount && (
          /* Prevent server-client render mismatch by deferring render of non-deterministic elements */
          <div>
            <div
              className="notfound-stars"
              style={this.translate(0, 0.9 * this.state.pos)}
            >
              {this.state.stars.gray}
            </div>
            <div
              className="notfound-stars"
              style={this.translate(0, 0.8 * this.state.pos)}
            >
              {this.state.stars.red}
            </div>
            <div
              className="notfound-stars"
              style={this.translate(0, 0.7 * this.state.pos)}
            >
              {this.state.stars.blue}
            </div>
            <div
              className="notfound-stars"
              style={this.translate(0, 0.6 * this.state.pos)}
            >
              {this.state.stars.white}
            </div>
            <div
              className="notfound-pizzas"
              style={this.translate(0, 0.5 * this.state.pos)}
            >
              {this.state.stars.pizzas}
            </div>
          </div>
        )}
        <div className="notfound-message">
          <span style={this.translate(0, 0.2 * this.state.pos)}>4</span>
          <span style={this.translate(0, 0.1 * this.state.pos)}>0</span>
          <span style={this.translate(0, 0.3 * this.state.pos)}>4</span>
        </div>
        <div className="notfound-pizza"></div>
      </div>
    );
  }

  // TODO (kyle): some of these probably dont need to be class methods
  stars(className: string, count: number): React.Node {
    return times(count, (i) => (
      <div
        className={classify('notfound-star', className)}
        style={this.randomPosition()}
        key={i}
      ></div>
    ));
  }

  pizzas(count: number): React.Node {
    return times(count, (i) => {
      const style = {
        ...this.randomPosition(),
        transform: `rotate(${Math.round(Math.random() * 360)}deg)`,
      };
      return (
        <div className="notfound-pizza-box" style={style} key={i}>
          <div className="notfound-pizza small"></div>;
        </div>
      );
    });
  }

  randomPosition(): {left: string, top: string} {
    return {
      left: Math.round(Math.random() * 100) + '%',
      top: Math.round(Math.random() * 100) + '%',
    };
  }

  translate(x: number, y: number): {transform: string} {
    return {
      transform: `translate(${x}px, ${y}px)`,
    };
  }

  scroll() {
    this.setState({
      pos: window.document.body.scrollTop,
    });
  }
}
