// @flow

import type {Account} from 'src/types/account';
import type {ThunkAction} from 'src/reducers';

import {thunkify as flow} from 'src/utils/thunks';
import {camel} from 'src/utils';
import {key, cached, fetching} from 'src/utils/redux';

import * as reduxApi from 'src/utils/redux-api';


export const RECEIVE: 'group-accounts/receive' = 'group-accounts/receive';

export type ReceiveAccountsAction = {
  type: typeof RECEIVE,
  payload: Account[],
};

export type GroupAccountsAction = ReceiveAccountsAction;

export const receive = (payload: Account[]): ReceiveAccountsAction => ({
  type: RECEIVE,
  payload,
});

export const getAccounts: (id: string) => ThunkAction<mixed> = flow(
  key((id) => `group-accounts-${id}`),
  cached((response) => receive(camel(response)), {ttl: 2000}),
  fetching({}),
)((id: string) => reduxApi.get(`security/groups/${id}/agents`));
