// @flow strict-local

import type {EnvState} from 'src/action-creators/env';


type State = {
  env: EnvState,
};

function isStaging({env}: State): boolean {
  return env.serverRuntimeEnvironment === 'staging';
}

// NOTE(gab): if it's staging, it's staging. If it's not staging and it's not production, it's dev
export function isDevOrStage(state: State): boolean {
  return (
    isStaging(state) || process.env.NODE_ENV !== 'production' // determined at run time // determined at build time
  );
}
