// @flow strict
import type {
  Filter,
  GetListRequest,
  PayoutRuleData,
  RuleData,
} from 'src/types/referral-v2';
import {VALUE_NOT_REQUIRED_OPERATORS} from 'src/utils/filter.js';
import map from 'lodash/map';
import moment from 'moment-timezone';


export const prepareQuery = (
  page?: number = 1,
  queryString?: string = '',
  records?: number = 25,
  filters?: Filter[] = [],
): GetListRequest => ({
  offset: (page - 1) * records,
  query_string: queryString,
  limit: records,
  filters,
});

export const getSelectedRows = (selectedRowsAcrossPages: {
  [string]: string[],
}): string[] => {
  let selectedRows = [];

  map(
    selectedRowsAcrossPages,
    (value) => (selectedRows = selectedRows.concat(value)),
  );

  return selectedRows;
};

export const getFormattedDate = (date: string): string => {
  return moment(date).format('D MMM, YYYY');
};

export const getFormattedDateForApi = (date: string): string => {
  const dateVal = new Date(date);
  return `${dateVal.getFullYear()}-${(dateVal.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${dateVal.getDate().toString().padStart(2, '0')}`;
};

export const createDownloadableUrl = (
  filePath: string,
  file_container: string,
): string =>
  `${window.location.origin}/api/v1/file-access/download?file_container=${file_container}&file_path=${filePath}`;

export const getDateRangeOfDaysDifference = (
  duration: string,
): {end_date: string, start_date: string} => {
  const day = parseInt(duration, 10);
  const currentDate = new Date();
  let startDate = new Date(currentDate);
  startDate.setDate(currentDate.getDate() - day);
  if (duration === 'this_year') {
    startDate = new Date(currentDate.getFullYear(), 0, 1);
  }
  if (duration === 'this_month') {
    startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  }
  return {
    start_date: getFormattedDateForApi(startDate.toString()),
    end_date: getFormattedDateForApi(currentDate.toString()),
  };
};

export const daysDifference = (date1: string, date2: string): string => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const differenceMs = Math.abs(d1 - d2);
  const daysDifference = differenceMs / (1000 * 60 * 60 * 24);
  return Math.round(daysDifference).toString();
};

export const validateDate = (date: string): boolean => {
  if (!date) {
    return false;
  }
  const expression = /^\d{4}-\d{2}-\d{2}$/;
  return date.match(expression) != null;
};

export const isReferrerPortal = (): boolean => {
  const hostName = window.location.hostname;
  const splitDomain = hostName.split('.');
  const subDomain = splitDomain[0];
  return subDomain === 'referrals';
};

const isValidRule = (ruleBlock) => {
  return ruleBlock?.rule_type !== 'event' || ruleBlock.rule?.length;
};

const checkValidValue = (rules, requiredField) => {
  rules?.forEach((rule) => {
    const isValueRequired = !VALUE_NOT_REQUIRED_OPERATORS.includes(
      rule.operator,
    );

    const newRule = {...rule};
    if (!isValueRequired) {
      // $FlowFixMe[incompatible-type]
      delete newRule.rule_value;
    }
    requiredField.push(newRule);
  });
};

const hasPayoutError = (requiredField) => {
  return requiredField.some((rule) => {
    return Object.keys(rule).some((field) => {
      //$FlowFixMe[prop-missing]
      const value = rule[field];
      return (
        value === null ||
        value === '' ||
        // number field value validation
        Number.isNaN(value) ||
        (!Number.isNaN(value) && parseInt(value, 10) <= 0)
      );
    });
  });
};

export const validatePayoutRule = (payoutRules: ?PayoutRuleData): boolean => {
  const requiredField = [];
  const {hired_rule, start_date_rule, end_date_rule} = payoutRules || {};

  if (
    (start_date_rule && !isValidRule(start_date_rule)) ||
    !isValidRule(hired_rule) ||
    !isValidRule(end_date_rule)
  ) {
    return false;
  }
  checkValidValue(hired_rule?.rule, requiredField);
  if (start_date_rule?.rule_type === 'field' && start_date_rule.field) {
    requiredField.push(start_date_rule.field);
  } else {
    checkValidValue(start_date_rule?.rule, requiredField);
  }
  if (end_date_rule?.rule_type === 'field' && end_date_rule.field) {
    requiredField.push(end_date_rule.field);
  } else if (end_date_rule?.rule_type === 'event') {
    checkValidValue(start_date_rule?.rule, requiredField);
  }

  return !hasPayoutError(requiredField);
};

export const getOperatorText = (operator: string): string => {
  switch (operator) {
    case 'is_not_null':
    case 'eq':
      return 'has a value';
    case 'neq':
      return 'has any value other than';
    case 'is_null':
      return 'does not have a value';
    case 'contains':
      return 'has any value which matches the list of values';
    case 'ncontains':
      return 'has any value other than the list of values';
    default:
      return 'has a value';
  }
};

export const getRuleInfo = (rules?: RuleData[]): ?string => {
  const rulesText = rules
    ?.map((rule) =>
      rule.field_name
        ? ` "${rule.field_name}" ${getOperatorText(rule.operator)}${
            rule.rule_value ? ` "${rule.rule_value}"` : ''
          }`
        : '',
    )
    .filter(Boolean);

  if (rulesText && rulesText.length > 1) {
    const lastItem = rulesText.pop();
    const secondLastItem = rulesText.pop();
    rulesText.push(`${secondLastItem} and ${lastItem}`);
  }

  return rulesText?.join(', ');
};
