// @flow strict

// $FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';
import type {DisplayConfig} from 'src/types/display-config';


export const selectDisplayConfig = (state: State): DisplayConfig =>
  state.displayConfig;

export const selectDisplayConfigByKey = (
  state: State,
  key: string,
): DisplayConfig => selectDisplayConfig(state)[key];

export const selectAbtEnabled = (state: State): DisplayConfig =>
  selectDisplayConfigByKey(state, 'list_abt_enabled');

export const selectJ2Enabled = (state: State): DisplayConfig =>
  selectDisplayConfigByKey(state, 'enable_workflow') ?? false;
