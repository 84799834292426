// @flow strict

// $FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';
import type {ActionAttributeSchema} from 'src/types/action-based-targeting-meta';
import {createSelector} from 'reselect';


export const selectAbtAttributes = (state: State): ActionAttributeSchema[] =>
  state.abtAttributes.attributes;

export const selectAllowedAbtAttributes: (
  state: State,
) => ActionAttributeSchema[] = createSelector(
  selectAbtAttributes,
  (abtAttributes) =>
    abtAttributes && Array.isArray(abtAttributes)
      ? abtAttributes.filter(({hidden}) => !hidden)
      : [],
);
