// @noflow

import type {
  Group,
  GroupsAction,
  ShareableGroup,
  EditGroup,
  ErrorMessagesByType,
} from 'src/action-creators/groups';

import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import sculpt from 'sculpt';

import {
  RECEIVE,
  EDIT_GROUP_NAME,
  EDIT_GROUP_PARENT,
  TOGGLE_GROUP_ALL_FILTER,
  ADD_GROUP_FILTER_RULE,
  EDIT_GROUP_FILTER_RULE,
  DELETE_GROUP_FILTER_RULE,
  CLEAR_EDITING,
  START_EDITING,
  START_EDITING_ERROR,
  SHOW_ALL_EDITING_ERRORS,
  RECEIVE_GROUP,
  REMOVE_GROUP,
  RECEIVE_SHAREABLE_GROUPS,
  RECEIVE_GROUP_FILTER_STATUS,
} from 'src/action-creators/groups';


export type ActiveStatus =
  | 'init'
  | 'processing'
  | 'success'
  | 'inactive'
  | 'failure'
  | 'cancelled'
  | 'skipped';

export type GroupsById = {
  [id: string]: Group,
};

const validateGroup = (group) => {
  const name = group ? group.name : '';
  const filter = group ? group.filter : {pattern: {}};
  const errors = {};
  if (name.trim().length === 0) {
    errors.name = ['Enter a name for this group'];
  }
  if (!filter.pattern.all) {
    if (!filter.pattern.and || !(filter.pattern.and.length > 0)) {
      errors.filter = ['Add at least one filter rule'];
    } else if (
      // NOTE (gab): this is a heuristic. The right way to do this would be with
      // normalize.audienceFilters(fieldsToTypes, filter.pattern).and.length > 0
      // This heuristic may miss some errors, but it won't misreport an error
      // (as far as I can tell)
      !filter.pattern.and.some((rule) => rule.operator)
    ) {
      errors.filter = [
        'Make sure that at least one rule is completely filled out',
      ];
    }
  }
  return errors;
};

const getEditingErrorState = (nextEditing: EditGroup, showErrors = true) => {
  const editingErrors = validateGroup(nextEditing);
  const editingErrorsToShow = showErrors
    ? mapValues(editingErrors, () => true)
    : {};

  return {
    editingErrors,
    editingErrorsToShow,
  };
};

export type GroupsState = {
  error: ErrorMessagesByType,
  editing: ?EditGroup,
  editingErrors: ErrorMessagesByType,
  editingErrorsToShow: {[key: string]: boolean},
  data: GroupsById,
  shareable: {
    [groupId: string]: ShareableGroup,
  },
  filterActiveStatus: {
    [groupId: string]: ActiveStatus,
  },
};

const initialState = {
  error: {},
  editing: null,
  editingErrors: {},
  editingErrorsToShow: {},
  data: {},
  shareable: {},
  filterActiveStatus: {},
};

export default (
  state: GroupsState = initialState,
  action: GroupsAction,
): GroupsState => {
  switch (action.type) {
    case RECEIVE:
      return {
        ...state,
        data: keyBy(action.payload, (group) => group.id),
      };

    case RECEIVE_SHAREABLE_GROUPS:
      return {
        ...state,
        shareable: keyBy(action.payload, (group) => group.id),
      };

    case CLEAR_EDITING:
      return {
        ...state,
        editing: null,
        editingErrors: {},
        editingErrorsToShow: {},
      };

    case START_EDITING:
      return {
        ...state,
        editing: action.payload,
        editingErrors: validateGroup(action.payload),
        editingErrorsToShow: {},
      };

    case START_EDITING_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case SHOW_ALL_EDITING_ERRORS:
      return {
        ...state,
        editingErrorsToShow: mapValues(state.editingErrors, () => true),
      };

    case RECEIVE_GROUP:
      return {
        ...state,
        editing: initialState.editing,
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
      };

    case REMOVE_GROUP:
      return {
        ...state,
        data: omit(state.data, action.payload),
      };

    case EDIT_GROUP_NAME: {
      const editing = {
        ...state.editing,
        ...action.payload,
      };

      return {
        ...state,
        editing,
        ...getEditingErrorState(editing),
      };
    }

    case EDIT_GROUP_PARENT:
      return {
        ...state,
        editing: {
          ...state.editing,
          parentSecurityGroupId: action.payload.parentId,
        },
      };

    case TOGGLE_GROUP_ALL_FILTER: {
      if (!state.editing) {
        return state;
      }

      const editing = sculpt(state.editing, {
        filter: {
          pattern: {
            // When editing, we'll set both `all` and `and` keys so that
            // form state, isn't lost, but when saving we'll choose one
            // key over the other before sending to the API (see normalize
            // function in api-parsers)
            all: {$set: !state.editing.filter.pattern.all},
            and: {$set: state.editing.filter.pattern.and || []},
          },
        },
      });

      return {
        ...state,
        editing,
        ...getEditingErrorState(editing),
      };
    }

    case ADD_GROUP_FILTER_RULE: {
      const editing = sculpt(state.editing, {
        filter: {
          pattern: {
            and: {
              $push: {
                field: '',
                operator: '',
                value: '',
              },
            },
          },
        },
      });

      return {
        ...state,
        editing,
        // NOTE (gab): Do not show errors when adding a rule because a rule is added in an invalid state
        ...getEditingErrorState(editing, false),
      };
    }

    case EDIT_GROUP_FILTER_RULE: {
      if (!state.editing) {
        return state;
      }
      const {ruleIndex, change, labels} = action.payload;

      const group = state.editing;
      const rule = group.filter.pattern.and[ruleIndex];

      let baseRule = rule;

      // init missing operators (ENGAGE-2698)
      if (baseRule.operator === '') {
        const ruleLabel = labels.find((label) => label.value === change.field);
        if (ruleLabel?.type === 'boolean') {
          baseRule.operator = 'eq';
        }
      }

      if (change.field) {
        const newField = labels.find((label) => label.name === change.field);
        const oldField = labels.find((label) => label.name === rule.field);

        if (newField && (!oldField || newField.type !== oldField.type)) {
          baseRule = defaultRules[newField.type] || baseRule;
        }
      }

      const nextRule = {...baseRule, ...change};

      const editing = sculpt(state.editing, {
        filter: {
          pattern: {
            and: {
              [action.payload.ruleIndex]: {$set: nextRule},
            },
          },
        },
      });

      return {
        ...state,
        editing,
        // NOTE (gab): Do not show errors when editing a filter rule because it takes several edits before the rule
        // will become valid (we don't want to show the user an error message prematurely)
        ...getEditingErrorState(editing, false),
      };
    }

    case DELETE_GROUP_FILTER_RULE: {
      const editing = sculpt(state.editing, {
        filter: {
          pattern: {
            and: {
              $splice: [[action.payload.ruleIndex, 1]],
            },
          },
        },
      });
      return {
        ...state,
        editing,
        ...getEditingErrorState(editing),
      };
    }

    case RECEIVE_GROUP_FILTER_STATUS:
      return sculpt(state, {
        filterActiveStatus: {
          [action.payload.groupId]: {$set: action.payload.status},
        },
      });
  }

  return state;
};

export const defaultRules = {
  default: {
    field: '',
    operator: '',
    value: '',
  },
  boolean: {
    operator: 'eq',
    value: false,
  },
};
