// @flow

import type {RawQuery} from 'src/types/router';
import type {Report} from 'src/types/messages-analytics';
import type {ThunkAction} from 'src/reducers';

import {thunkify as flow} from 'src/utils/thunks';
import {camel} from 'src/utils';
import {resolveDateRange} from 'src/utils/date-range';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api-v2';


const {stringify} = JSON;

export const RECEIVE = 'messages-analytics/receive';
export const RECEIVE_KEYED = 'messages-analytics/receive_keyed';
export const SET_QUERY = 'messages-analytics/set_query';

export type ReceiveAction = {
  type: 'messages-analytics/receive',
  payload: Report,
};

export type ReceiveKeyedAction = {
  type: 'messages-analytics/receive_keyed',
  payload: {report: Report, key: string},
};

export type SetQueryAction = {
  type: 'messages-analytics/set_query',
  payload: string,
};

export type Action = ReceiveAction;

// TODO(marcos): this is probably deprecated
export const receive = (payload: Report): ReceiveAction => ({
  type: RECEIVE,
  payload,
});

export const receiveKeyed = (payload: {
  report: Report,
  key: string,
}): ReceiveKeyedAction => ({
  type: RECEIVE_KEYED,
  payload,
});

function parse(json: Object) {
  return camel(json);
}

export const setLatestMessageAnalyticsQuery = (
  query: RawQuery,
): SetQueryAction => ({
  type: SET_QUERY,
  payload: stringify(query),
});

export const getMessagesReport: (query: RawQuery) => ThunkAction<mixed> = flow(
  (func) => (query) => func(resolveDateRange(query, null, {weeks: 1})),
  key(() => `messages-analytics`),
  cached((json, q) => receiveKeyed({report: parse(json), key: stringify(q)}), {
    hash: (query) => `messages-analytics:${stringify(query)}`,
  }),
  fetching(),
)((query: RawQuery) => reduxApi.get(`messages_v2/analytics/overview`, query));
