// @flow strict

import {
  Button as LibButton,
  UnstyledButton,
} from '@spaced-out/ui-lib/lib/button';
import {
  makeClassNameComponentCustom,
  type ClassNameComponent,
} from '@spaced-out/ui-lib/lib/utils/makeClassNameComponent';

import css from './button.css';


export const Button: ClassNameComponent<
  typeof LibButton,
> = makeClassNameComponentCustom(css.button, LibButton);
export default Button;

export {UnstyledButton};
