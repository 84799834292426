// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {
  AutomationWorkflowAPI,
  AutomationWorkflowsSummaryAPI,
  AutomationWorkflowsCategoriesAPI,
  AutomationWorkflowsAddToCategoryAPIPayload,
  CategorySortKey,
} from 'src/types/automation-workflow-organizer';

// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {fetching, key} from 'src/utils/redux';
import type {BulkActionType} from 'src/reducers/automation-workflow-organizer.js';


export const RECEIVE_AUTOMATION_WORKFLOWS =
  'automation-workflow-organizer/receiveAll';
export const RESET_AUTOMATION_WORKFLOW_ORGANIZER =
  'automation-workflow-organizer/reset';
export const RECEIVE_AUTOMATION_WORKFLOWS_SUMMARY =
  'automation-workflow-organizer/receiveSummary';
export const RECEIVE_AUTOMATION_WORKFLOWS_CATEGORIES =
  'automation-workflow-organizer/receiveCategories';
export const SET_QUERY = 'automation-workflow-organizer/setQuery';
export const CLEAR_QUERY = 'automation-workflow-organizer/clearQuery';
export const FILTER_CHANGE = 'automation-workflow-organizer/filterChange';
export const SELECTION_CHANGE = 'automation-workflow-organizer/selectionChange';
export const SELECT_ALL = 'automation-workflow-organizer/selectAll';
export const PAGE_CHANGE = 'automation-workflow-organizer/pageChange';
export const TOTAL_PAGE_CHANGE =
  'automation-workflow-organizer/totalPageChange';
export const SET_BULK_ACTION = 'automation-workflow-organizer/setBulkAction';
export const FINALISE_BULK_ACTION =
  'automation-workflow-organizer/finaliseBulkAction';
export const SET_ELLIPSIS_SELECTED =
  'automation-workflow-organizer/setEllipsisSelected';
export const SET_CATEGORY_SORT_KEY =
  'automation-workflow-organizer/setCategorySortKey';

type ReceiveWorkflowsAction = {
  type: 'automation-workflow-organizer/receiveAll',
  payload: AutomationWorkflowAPI[],
};

type ReceiveWorkflowsSummaryAction = {
  type: 'automation-workflow-organizer/receiveSummary',
  payload: AutomationWorkflowsSummaryAPI,
};

type ReceiveWorkflowsCategoriesAction = {
  type: 'automation-workflow-organizer/receiveCategories',
  payload: AutomationWorkflowsCategoriesAPI[],
};

type ResetOrganizerStateAction = {
  type: 'automation-workflow-organizer/reset',
  payload: boolean,
};

type SetQueryAction = {
  type: 'automation-workflow-organizer/setQuery',
  payload: string,
};
type ClearQueryAction = {
  type: 'automation-workflow-organizer/clearQuery',
  payload: string,
};
type FilterChangeAction = {
  type: 'automation-workflow-organizer/filterChange',
  payload: {field: string, value: string[]},
};
type SelectionChangeAction = {
  type: 'automation-workflow-organizer/selectionChange',
  payload: {
    selected: string[],
    toDelete: string[],
    toArchive: string[],
    toAddToACategory: string[],
    toMoveToACategory: string[],
    toRemoveFromACategory: string[],
  },
};
type SelectAllAction = {
  type: 'automation-workflow-organizer/selectAll',
  payload: {
    selected: string[],
    toDelete: string[],
    toArchive: string[],
    toAddToACategory: string[],
    toMoveToACategory: string[],
    toRemoveFromACategory: string[],
  },
};
type PageChangeAction = {
  type: 'automation-workflow-organizer/pageChange',
  payload: number,
};
type TotalPageChangeAction = {
  type: 'automation-workflow-organizer/totalPageChange',
  payload: number,
};
type SetBulkActionAction = {
  type: 'automation-workflow-organizer/setBulkAction',
  payload: BulkActionType,
};
type FinaliseBulkAction = {
  type: 'automation-workflow-organizer/finaliseBulkAction',
};
type SetEllipsisSelectedAction = {
  type: 'automation-workflow-organizer/setEllipsisSelected',
  payload: string,
};
type SetCategorySortKeyAction = {
  type: 'automation-workflow-organizer/setCategorySortKey',
  payload: CategorySortKey,
};

export type AutomationWorkflowOrganizerAction =
  | ReceiveWorkflowsAction
  | ResetOrganizerStateAction
  | ReceiveWorkflowsSummaryAction
  | ReceiveWorkflowsCategoriesAction
  | SetQueryAction
  | ClearQueryAction
  | FilterChangeAction
  | SelectionChangeAction
  | SelectAllAction
  | PageChangeAction
  | TotalPageChangeAction
  | SetBulkActionAction
  | FinaliseBulkAction
  | SetEllipsisSelectedAction
  | SetCategorySortKeyAction;

export const setWorkflowQuery = (payload: string): SetQueryAction => ({
  type: 'automation-workflow-organizer/setQuery',
  payload,
});

export const handleWorkflowFilterChange = (payload: {
  field: string,
  value: string[],
}): FilterChangeAction => ({
  type: 'automation-workflow-organizer/filterChange',
  payload,
});

export const handleWorkflowSelectionChange = (payload: {
  selected: string[],
  toDelete: string[],
  toArchive: string[],
  toAddToACategory: string[],
  toMoveToACategory: string[],
  toRemoveFromACategory: string[],
}): SelectionChangeAction => ({
  type: 'automation-workflow-organizer/selectionChange',
  payload,
});

export const handleWorkflowPageChange = (
  payload: number,
): PageChangeAction => ({
  type: 'automation-workflow-organizer/pageChange',
  payload,
});

export const handleWorkflowTotalPageChange = (
  payload: number,
): TotalPageChangeAction => ({
  type: 'automation-workflow-organizer/totalPageChange',
  payload,
});

export const setWorkflowBulkAction = (
  payload: BulkActionType,
): SetBulkActionAction => ({
  type: 'automation-workflow-organizer/setBulkAction',
  payload,
});

export const finaliseWorkflowAction = (): FinaliseBulkAction => ({
  type: 'automation-workflow-organizer/finaliseBulkAction',
});

export const setEllipsisSelectedWorkflow = (
  payload: string,
): SetEllipsisSelectedAction => ({
  type: 'automation-workflow-organizer/setEllipsisSelected',
  payload,
});

export const setCategorySortKey = (
  payload: CategorySortKey,
): SetCategorySortKeyAction => ({
  type: 'automation-workflow-organizer/setCategorySortKey',
  payload,
});

const receiveAllWorkflows = (
  payload: AutomationWorkflowAPI[],
): ReceiveWorkflowsAction => ({
  type: 'automation-workflow-organizer/receiveAll',
  payload,
});

const receiveAllWorkflowsSummary = (
  payload: AutomationWorkflowsSummaryAPI,
): ReceiveWorkflowsSummaryAction => ({
  type: 'automation-workflow-organizer/receiveSummary',
  payload,
});

export const receiveWorkflowsCategories = (
  payload: AutomationWorkflowsCategoriesAPI[],
): ReceiveWorkflowsCategoriesAction => ({
  type: 'automation-workflow-organizer/receiveCategories',
  payload,
});

export const getAllAutomationWorkflows: () => ThunkAction<mixed> = flow(
  key(() => `getAllAutomationWorkflows`),
  fetching(),
)(
  () => (dispatch: Dispatch) =>
    dispatch(reduxApi.get('workflow')).then((payload) => {
      dispatch(receiveAllWorkflows(payload));
    }),
);

export const getAllAutomationWorkflowsSummary: () => ThunkAction<mixed> = flow(
  key(() => `getAllAutomationWorkflowsSummary`),
  fetching(),
)(
  () => (dispatch: Dispatch) =>
    dispatch(reduxApi.get('workflow/summary')).then((payload) => {
      dispatch(receiveAllWorkflowsSummary(payload));
    }),
);

export const getAutomationWorkflowsCategories: () => ThunkAction<mixed> = flow(
  key(() => `getAutomationWorkflowsCategories`),
  fetching(),
)(
  () => (dispatch: Dispatch) =>
    dispatch(reduxApi.get('workflow-category')).then((payload) => {
      dispatch(receiveWorkflowsCategories(payload));
    }),
);

export const archiveWorkflows: (workflowIds: string[]) => ThunkAction<mixed> =
  (workflowIds) => (dispatch) =>
    dispatch(reduxApi.post('workflow/archive', {workflow_ids: workflowIds}));

export const deleteWorkflows: (workflowIds: string[]) => ThunkAction<mixed> =
  (workflowIds) => (dispatch) =>
    dispatch(reduxApi.post('workflow/delete', {workflow_ids: workflowIds}));

export const duplicateWorkflow: (
  workflowIdToDuplicate: string,
  workflowName: string,
) => ThunkAction<mixed> =
  (workflowIdToDuplicate, workflowName) => (dispatch) =>
    dispatch(
      reduxApi.post(`workflow/${workflowIdToDuplicate}/copy`, {
        name: workflowName,
      }),
    );

export const starWorkflows: (
  workflowIdsToStar: string[],
) => ThunkAction<mixed> = (workflowIdsToStar) => (dispatch) =>
  dispatch(reduxApi.post('workflow/star', {workflow_ids: workflowIdsToStar}));

export const unstarWorkflow: (
  workflowIdToUnstar: string,
) => ThunkAction<mixed> = (workflowIdToUnstar) => (dispatch) =>
  dispatch(reduxApi.post(`workflow/${workflowIdToUnstar}/unstar`));

export const createAutomationWorkflowCategory: (payload: {
  name: string,
}) => ThunkAction<mixed> = (payload) => (dispatch) =>
  dispatch(reduxApi.post('workflow-category', payload)).then((response) => {
    dispatch(getAutomationWorkflowsCategories());
  });

export const addAutomationWorkflowsToCategory: (
  payload: AutomationWorkflowsAddToCategoryAPIPayload,
) => ThunkAction<mixed> = (payload) => (dispatch) =>
  dispatch(reduxApi.post('workflow/bulk-category-update', payload));

export const deleteCategory: (categoryId: string) => ThunkAction<mixed> =
  (categoryId) => (dispatch) =>
    dispatch(reduxApi.del(`workflow-category/${categoryId}`));

export const renameCategory: (
  categoryId: string,
  name: string,
) => ThunkAction<mixed> = (categoryId, name) => (dispatch) =>
  dispatch(reduxApi.post(`workflow-category/rename/${categoryId}`, {name}));
