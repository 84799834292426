var React = require('react');

function CancelIcon (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M6.262 14.323a.3.3 0 000 .425l.99.99a.3.3 0 00.425 0L11 12.414l3.323 3.324a.3.3 0 00.425 0l.99-.99a.3.3 0 000-.425L12.414 11l3.324-3.323a.3.3 0 000-.425l-.99-.99a.3.3 0 00-.425 0L11 9.586 7.677 6.262a.3.3 0 00-.425 0l-.99.99a.3.3 0 000 .425L9.586 11l-3.324 3.323z"}));
}

CancelIcon.defaultProps = {"width":"22","height":"22","viewBox":"0 0 22 22"};

module.exports = CancelIcon;

CancelIcon.default = CancelIcon;
