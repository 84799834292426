// @flow

// $FlowFixMe[untyped-type-import]
import type {Workflow} from 'src/api-parsers';
// $FlowFixMe[untyped-type-import]
import type {Event} from 'src/api-parsers/events';
import type {RouteLocation, Params} from 'src/types/router';

import flow from 'lodash/flow';
import * as React from 'react';
import {connect} from 'react-redux';

import {provideHooks} from 'src/flux/provideHooks.jsx';
import {handleGenericApiError} from 'src/utils/errors';

import styles from 'src/components/app.css';

import {
  VIEW_ANALYTICS,
  VIEW_WORKFLOWS,
  VIEW_AUDIENCE_MEMBERS,
  EDIT_WORKFLOWS,
  EDIT_SETTINGS,
  MANAGE_CALENDAR,
  MANAGE_TEAM_TEMPLATES,
  VIEW_REFERRALS_V2,
  EDIT_REFERRALS_V2,
  ADMIN_REFERRALS_V2,
} from 'src/action-creators/roles';
import {getWorkflows, getWorkflow} from 'src/actions/workflow';
import {fluxify} from 'src/flux/component.jsx';
import {hasPrivilege} from 'src/utils/accounts';
import {canUseMsgPhoneOrLatInbox, canUseMessaging} from 'src/selectors/chat';
import {selectAgencyConfigFlag} from 'src/selectors/agency';
import {isChatAppLocation, isMobile} from 'src/utils/responsive';
import {withWindowWidth} from 'src/flux/responsive.jsx';
import {selectFullscreen} from 'src/selectors/app-config.js';
import ExtensionSideNav from 'src/components/micro-apps-extension/extension-side-nav.jsx';

import AppStatusNotification from 'src/components/navigation/app-status-notification.jsx';
import {AppShellSideNav} from 'src/components/app-shell/app-shell-side-nav.jsx';


const dependencies = {
  required: async ({dispatch, store, location}) => {
    const workflowId = getWorkflowId(location);
    if (workflowId) {
      getWorkflow(store, dispatch, workflowId).catch((error) =>
        handleGenericApiError(error, [404]),
      );
    }
  },
  deferred: ({store}) => getWorkflows(store),
};

const mapStateToProps = (state, ownProps) => ({
  canViewAnalytics: hasPrivilege(state, VIEW_ANALYTICS),
  canViewWorkflows: hasPrivilege(state, VIEW_WORKFLOWS),
  canEditWorkflows: hasPrivilege(state, EDIT_WORKFLOWS),
  canEditSettings: hasPrivilege(state, EDIT_SETTINGS),
  canViewChat: canUseMessaging(state),
  canViewChatorEditChatbot: canUseMsgPhoneOrLatInbox(state),
  canViewAudienceMembers: hasPrivilege(state, VIEW_AUDIENCE_MEMBERS),
  analyticsPerformancePageEnabled: selectAgencyConfigFlag(
    state,
    'analyticsPerformancePageEnabled',
  ),
  analyticsCohortsEnabled: selectAgencyConfigFlag(
    state,
    'analyticsCohortsEnabled',
  ),
  isMobile: isMobile(state.requestHeaders.headers['user-agent']),
  context: state.env.query.context,
  schedulingAllowed:
    hasPrivilege(state, MANAGE_CALENDAR) ||
    hasPrivilege(state, MANAGE_TEAM_TEMPLATES),
  schedulerEnabled: selectAgencyConfigFlag(state, 'enableSchedulerProduct'),
  hidden: state.navigation.sideNavHidden || ownProps.hidden,
  canViewReferrals:
    hasPrivilege(state, VIEW_REFERRALS_V2) ||
    hasPrivilege(state, EDIT_REFERRALS_V2) ||
    hasPrivilege(state, ADMIN_REFERRALS_V2),
  isFullscreenExperience: selectFullscreen(state),
});

const mapper = ({store, location}) => {
  const workflowId = getWorkflowId(location);
  const workflow = store.workflows.state.workflows[workflowId];
  const branchedEventIds = Array.isArray(workflow?.branchedEventIds)
    ? workflow.branchedEventIds
    : [];
  const eventSchedule = workflow?.eventsSchedule?.map(({id}) => id) ?? [];
  const events = workflow
    ? [...branchedEventIds, ...eventSchedule]
        .map((id) => store.events.state.events[id])
        .filter((event) => event)
        .sort((a, b) =>
          a.title.localeCompare(b.title, 'en', {sensitivity: 'base'}),
        )
    : null;

  return {
    workflows: store.workflows.state.workflows,
    workflow,
    events,
    client: location.query.client,
    getWorkflows: () => getWorkflows(store),
  };
};

class WorkflowNav extends React.Component<{
  children: React.Node,
  canViewAnalytics: boolean,
  canViewAudienceMembers: boolean,
  canViewWorkflows: boolean,
  canEditWorkflows: boolean,
  canEditSettings: boolean,
  canViewChat: boolean,
  canViewChatorEditChatbot: boolean,
  // $FlowFixMe[value-as-type] [v1.32.0]
  workflows: {[string]: Workflow},
  // $FlowFixMe[value-as-type] [v1.32.0]
  workflow?: Workflow,
  // $FlowFixMe[value-as-type] [v1.32.0]
  events: ?(Event[]),
  analyticsPerformancePageEnabled: boolean,
  analyticsCohortsEnabled: boolean,
  schedulingAllowed: boolean,
  schedulerEnabled: boolean,
  isMobileWindow: boolean,
  isMobile: boolean,
  context: string,
  getWorkflows: () => mixed,
  location: RouteLocation,
  params: Params,
  hidden: ?mixed,
  canViewReferrals: boolean,
  isFullscreenExperience: boolean,
  expanded: boolean,
  setExpand: () => void,
  name: string,
  handleSenseUniversityOnClick: () => void,
}> {
  componentDidMount() {
    if (Object.keys(this.props.workflows).length === 0) {
      this.props.getWorkflows();
    }
  }

  render() {
    const {
      location,
      workflows,
      workflow,
      events,
      hidden,
      params,
      canViewAnalytics,
      canViewAudienceMembers,
      canViewWorkflows,
      canEditWorkflows,
      canEditSettings,
      canViewChat,
      canViewChatorEditChatbot,
      analyticsPerformancePageEnabled,
      analyticsCohortsEnabled,
      schedulingAllowed,
      schedulerEnabled,
      isMobileWindow,
      isMobile,
      context,
      canViewReferrals,
      isFullscreenExperience,
      expanded,
      setExpand,
      name,
      handleSenseUniversityOnClick,
    } = this.props;
    const fullyHidden =
      context === 'chrome-extension' ||
      context === 'pwa' ||
      context === 'trm-iframe' ||
      context === 'client-iframe' ||
      context === 'sense-ai' ||
      isMobile ||
      (isChatAppLocation(location) && isMobileWindow) ||
      isFullscreenExperience;

    return (
      <>
        {!fullyHidden && (
          <AppShellSideNav
            {...{
              location,
              workflows,
              workflow,
              events,
              hidden: Boolean(hidden),
              canViewAnalytics,
              canViewAudienceMembers,
              canViewWorkflows,
              canEditWorkflows,
              canEditSettings,
              canViewChat,
              canViewChatorEditChatbot,
              analyticsPerformancePageEnabled,
              analyticsCohortsEnabled,
              schedulerEnabled,
              schedulingAllowed,
              params,
              canViewReferrals,
            }}
            expanded={expanded}
            setExpand={setExpand}
            handleSenseUniversityOnClick={handleSenseUniversityOnClick}
          />
        )}
        <ExtensionSideNav />
        <div className={styles.child}>
          {!fullyHidden && <AppStatusNotification />}
          {this.props.children}
        </div>
      </>
    );
  }
}

export default (flow(
  withWindowWidth,
  fluxify(mapper),
  connect(mapStateToProps),
  provideHooks(dependencies),
)(WorkflowNav): any);

// TODO (kyle): since this component is placed high up the route tree,
// it does not have access to the workflowId param. this solution is a
// hacky stopgap.
function getWorkflowId(location) {
  const match = location.pathname.match(/\/journeys?\/(\d+)/);
  const workflowId = match && match[1];
  return workflowId;
}
