// @flow strict

import * as React from 'react';
import {Button} from 'src/designSystem2021Components/buttons.jsx';
import {Clickable} from 'src/designSystem2021Components/text-v2.jsx';

import css from 'src/components/agency-onboard/footer/default-footer.css';


type footerProps = {
  onSubmit: () => mixed,
  onCancel: () => mixed,
  disableSubmit?: boolean,
};

export const DefaultFooter = (props: footerProps): React.Node => {
  const {onSubmit, onCancel, disableSubmit} = props;

  const handleSubmit = (): void => {
    onSubmit();
  };

  const handleCancel = (): void => {
    onCancel();
  };
  return (
    <div className={css.defaultFooter}>
      <div className={css.footerBtns}>
        <Button
          type="tertiary"
          className={css.cancelBtn}
          onClick={handleCancel}
        >
          <Clickable> Cancel </Clickable>
        </Button>
        <Button
          type="primary"
          className={css.submitBtn}
          disabled={props.disableSubmit}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
