// @flow

import type {RouteLocation, Router} from 'src/types/router';
// $FlowFixMe[untyped-type-import]
import type {UpdateOptions} from 'src/utils/analytics-query';

import * as React from 'react';
import {useHistory} from 'src/rerouter';
import hoistNonReactStatics from 'hoist-non-react-statics';

import {parseQuery, updateQuery} from 'src/utils/analytics-query';


export type AnalyticsQueryProps = {
  query: Object,
  // $FlowFixMe[value-as-type]
  onQueryChange: (update: Object, options: UpdateOptions) => void,
  router: Router,
  location: RouteLocation,
};

function withAnalyticsQuery<C>(
  WrappedComponent: React.ComponentType<C>,
): React.ComponentType<$Diff<C, AnalyticsQueryProps>> {
  const WithAnalytics = (props) => {
    const router = useHistory();
    const {location} = router;
    const {
      query: {q},
    } = location;
    const query = parseQuery(q);
    // $FlowFixMe[value-as-type]
    const handleQueryChange = (update: Object, options: UpdateOptions = {}) => {
      let newQ = q;
      if (options.entityDataFilter && !query.edf) {
        newQ = updateQuery(newQ, {edf: {$set: {}}});
      }
      newQ = updateQuery(newQ, update, options);
      router.push({
        ...location,
        query: {
          ...location.query,
          q: newQ,
        },
      });
    };
    return (
      <WrappedComponent
        {...props}
        router={router}
        location={location}
        query={query}
        onQueryChange={handleQueryChange}
      />
    );
  };
  return hoistNonReactStatics(WithAnalytics, WrappedComponent);
}

export default withAnalyticsQuery;
