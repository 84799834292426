// @flow strict

import type {
  StandardEntityDesignationResponse,
  StandardEntityDesignationCount,
  FieldMappingScreen,
  StandardEntityAttributes,
  FieldMappingGenericError,
} from '../types/field-mapping';

import {
  UPADTE_ACTIVE_FIELD_MAPPING_SCREEN,
  STANDARD_ENTITY_LOADING,
  SET_STANDARD_ENTITY,
  STANDARD_ENTITY_ERROR,
  STANDARD_ENTITY_DESIGNATION_LOADING,
  SET_STANDARD_ENTITY_DESIGNATION,
  SET_LOCAL_STANDARD_ENTITY_DESIGNATION,
  STANDARD_ENTITY_DESIGNATION_ERROR,
  SAVE_STANDARD_FIELD_MAPPING_LOADING,
  SAVE_STANDARD_FIELD_MAPPING_SUCCESS,
  SAVE_STANDARD_FIELD_MAPPING_ERROR,
  CONFIRM_MIGRATE_FIELD_MAPPING_LOADING,
  CONFIRM_MIGRATE_FIELD_MAPPING_SUCCESS,
  CONFIRM_MIGRATE_FIELD_MAPPING_ERROR,
} from 'src/action-creators/field-mapping';

import type {FieldMappingAction} from 'src/action-creators/field-mapping';
import {cloneDesignationsResponse} from '../utils/field-mapping';


export type FieldMappingState = {
  activeScreen: FieldMappingScreen,
  standardEntityAttributes: StandardEntityAttributes,
  standardEntityLoading: boolean,
  standardEntityError: FieldMappingGenericError,
  standardEntityDesignationResponse: StandardEntityDesignationResponse,
  localStandardEntityDesignation: StandardEntityDesignationResponse,
  standardEntityDesignationCount: StandardEntityDesignationCount,
  standardEntityDesignationLoading: boolean,
  standardEntityDesignationError: FieldMappingGenericError,
  saveStandardFieldMappingLoading: boolean,
  saveStandardFieldMappingSuccess: boolean,
  saveStandardFieldMappingError: FieldMappingGenericError,
  confirmMigrateFieldMappingLoading: boolean,
  confirmMigrateFieldMappingSuccess: boolean,
  confirmMigrateFieldMappingError: FieldMappingGenericError,
};

const initialState: FieldMappingState = {
  activeScreen: 'Intro', // 'FieldSelection'
  standardEntityAttributes: {},
  standardEntityLoading: false,
  standardEntityError: null,
  standardEntityDesignationResponse: [],
  localStandardEntityDesignation: [],
  standardEntityDesignationCount: null,
  standardEntityDesignationLoading: false,
  standardEntityDesignationError: null,
  saveStandardFieldMappingLoading: false,
  saveStandardFieldMappingSuccess: false,
  saveStandardFieldMappingError: null,
  confirmMigrateFieldMappingLoading: false,
  confirmMigrateFieldMappingSuccess: false,
  confirmMigrateFieldMappingError: null,
};

export default (
  state: FieldMappingState = initialState,
  action: FieldMappingAction,
): FieldMappingState => {
  switch (action.type) {
    case UPADTE_ACTIVE_FIELD_MAPPING_SCREEN:
      return {
        ...state,
        activeScreen: action.payload,
      };

    case STANDARD_ENTITY_LOADING:
      return {
        ...state,
        standardEntityLoading: true,
      };
    case SET_STANDARD_ENTITY:
      return {
        ...state,
        standardEntityLoading: false,
        standardEntityAttributes: action.payload,
      };
    case STANDARD_ENTITY_ERROR:
      return {
        ...state,
        standardEntityLoading: false,
        standardEntityError: action.payload,
      };

    case STANDARD_ENTITY_DESIGNATION_LOADING:
      return {
        ...state,
        standardEntityDesignationLoading: true,
      };
    case SET_STANDARD_ENTITY_DESIGNATION:
      return {
        ...state,
        standardEntityDesignationResponse: action.payload,
        localStandardEntityDesignation: cloneDesignationsResponse(
          action.payload,
        ),
        standardEntityDesignationLoading: false,
      };
    case SET_LOCAL_STANDARD_ENTITY_DESIGNATION:
      return {
        ...state,
        localStandardEntityDesignation: action.payload,
      };
    case STANDARD_ENTITY_DESIGNATION_ERROR:
      return {
        ...state,
        standardEntityDesignationError: action.payload,
        standardEntityDesignationLoading: false,
      };

    case SAVE_STANDARD_FIELD_MAPPING_LOADING:
      return {
        ...state,
        saveStandardFieldMappingLoading: true,
        saveStandardFieldMappingSuccess: false,
        saveStandardFieldMappingError: null,
      };
    case SAVE_STANDARD_FIELD_MAPPING_SUCCESS:
      return {
        ...state,
        saveStandardFieldMappingLoading: false,
        saveStandardFieldMappingSuccess: true,
      };
    case SAVE_STANDARD_FIELD_MAPPING_ERROR:
      return {
        ...state,
        saveStandardFieldMappingLoading: false,
        saveStandardFieldMappingError: action.payload,
      };

    case CONFIRM_MIGRATE_FIELD_MAPPING_LOADING:
      return {
        ...state,
        confirmMigrateFieldMappingLoading: true,
      };
    case CONFIRM_MIGRATE_FIELD_MAPPING_SUCCESS:
      return {
        ...state,
        confirmMigrateFieldMappingLoading: false,
        confirmMigrateFieldMappingSuccess: true,
      };
    case CONFIRM_MIGRATE_FIELD_MAPPING_ERROR:
      return {
        ...state,
        confirmMigrateFieldMappingLoading: false,
        confirmMigrateFieldMappingError: action.payload,
      };
  }
  return state;
};
