// @flow strict

import type {GraphStatus} from 'src/types/chatbot';


export const statusLabels: {[GraphStatus]: string} = {
  init: 'Draft',
  building: 'Building',
  success: 'Ready for use',
  failed: 'In manual review',
  archived: 'Archived',
};

function getChatbotStagingDomain(hostname) {
  const domains = hostname.split('.');
  const senseStagingDomain = domains[1];
  return {
    'stage-chatbot': 'stage-1',
    'stage-chatbot-02': 'stage-02',
    'stage-chatbot-03': 'stage-03',
    'stage-chatbot-04': 'stage-04',
    'stage-chatbot-05': 'stage-05',
    'stage-chatbot-06': 'stage-06',
  }[senseStagingDomain];
}

export const useChatbotApiPath = (env: {
  agencySlug: string,
  serverRuntimeEnvironment: string,
  apiUrl: string,
  hostname: string,
}): string => {
  const {agencySlug, serverRuntimeEnvironment, apiUrl, hostname} = env;

  const host = serverRuntimeEnvironment === 'development' ? apiUrl : hostname;

  let chatbot_api_url = `https://${agencySlug}${
    ['development', 'staging'].includes(serverRuntimeEnvironment)
      ? '.' + getChatbotStagingDomain(host)
      : ''
  }.sensehqchat.com/api`;

  if (serverRuntimeEnvironment === 'preprod') {
    chatbot_api_url = `https://${agencySlug}.sensehqchat.sandbox-preprod.sensehq.com/api`;
  }

  return chatbot_api_url;
};

export const useFAQServicePath = (env: {
  agencySlug: string,
  serverRuntimeEnvironment: string,
  apiUrl: string,
  hostname: string,
}): string => {
  const {serverRuntimeEnvironment, apiUrl, hostname} = env;
  const host = serverRuntimeEnvironment === 'development' ? apiUrl : hostname;
  const domains = host.split('.');
  const senseStagingDomain = domains[1];

  let faq_url = `https://chatbot-faqs${
    ['staging'].includes(serverRuntimeEnvironment)
      ? '.' + senseStagingDomain
      : ''
  }.sensehq.co/api/faqs`;

  if (serverRuntimeEnvironment === 'preprod') {
    faq_url = `https://chatbot-faqs.sandbox-preprod.sensehq.com/api/faqs`;
  }
  return faq_url;
};

export const useFAQV2ServicePath = (env: {
  agencySlug: string,
  serverRuntimeEnvironment: string,
  apiUrl: string,
  hostname: string,
}): string => {
  const {serverRuntimeEnvironment, apiUrl, hostname} = env;
  const host =
    serverRuntimeEnvironment === 'development' &&
    // this hostname check here will cause local proxy to
    // prod instances to correctly resolve the prod faq
    // domain because apiUrl refers to the canonical api host
    !apiUrl.includes('sensehq.com')
      ? apiUrl
      : hostname;
  const domains = host.split('.');
  const senseStagingDomain = domains[1];

  // this is the prod url
  let faq_url = `https://chatbot-faqs.sensehq.co/api/v2`;

  if (
    serverRuntimeEnvironment === 'preprod' ||
    serverRuntimeEnvironment === 'staging'
  ) {
    faq_url = `https://chatbot-faqs.sandbox-preprod.sensehq.com/api/v2`;
    // just a quick runtime api override for this api
  } else if (window?.__use_stage_6_faqs === true) {
    faq_url = `https://chatbot-faqs.stage-chatbot-06.sensehq.co/api/v2`;
  }
  return faq_url;
};
