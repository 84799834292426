// @flow

// $FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores/index';
import type {DynamicLabels} from 'src/action-creators/dynamic-labels';
import type {EventTypes} from 'src/types/events';
import type {BeefreeModule} from 'src/components/workflow/event/beefree-content.jsx';
import type {
  BeefreeDocument,
  OnChangeResponse,
} from 'src/components/lib/beefree-editor';

import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  surveyContentToken,
  jobMatchContentToken,
  chatLinkToken,
  surveyLinkEmailToken,
} from 'src/components/workflow/event/constants';
import {ApiError} from 'src/utils/redux-api-v2';
import parsers from 'src/api-parsers/events';
import {useJobMatchEnabledForEngage} from 'src/hooks/useAgencyConfig';
import {camel} from 'src/utils';

import {
  pushModal,
  showApiError,
  showGenericError,
} from 'src/action-creators/modal';
import {selectWorkflowEntityMapping} from 'src/selectors/dynamic-labels';
import {
  getEntityTypeMappings,
  eventEntityType as getEventEntityType,
} from 'src/selectors/ats-entities';
import {getCurrentAgent} from 'src/selectors/accounts';
import {getCurrentWorkflowEntityType} from 'src/selectors/workflow.js';
import {getSearchableEntity} from 'src/utils/entities';

import SegmentedButton from 'src/components/lib/segmented-button';
import Toggle from 'src/components/lib/toggle/toggle.jsx';

import * as api from 'src/utils/api-no-store';
import {captureSentryMessage} from 'src/utils/sentry';
import logger from 'src/utils/logger';

import BeefreeEditor from 'src/components/lib/beefree-editor';
import UserSearch from 'src/components/lib/user-token-search';
import BasicDropdown from 'src/components/lib/basic-dropdown';
import PreviewIframe from 'src/components/lib/preview-iframe';

import {useMockedEntities, useCandidateForEntity} from './mocked-entity-hook';

import css from './beefree.css';


type MarketingProps = {|
  // $FlowFixMe[value-as-type] [v1.32.0]
  fluxStore: IndexStore,
  moduleData: BeefreeModule,
  dynamicLabels: DynamicLabels,
  eventType: EventTypes,
  onChange: (
    beefree_json: string,
    beefree_html?: string,
    data?: BeefreeModule,
  ) => BeefreeDocument,
|};

export default function BeeFreeEditor(
  props: MarketingProps,
): React.Element<'div'> {
  const {fluxStore, moduleData, dynamicLabels, eventType} = props;

  const dispatch = useDispatch();
  const eventEntityType = useSelector(getEventEntityType);
  const jobMatchEnabled = useJobMatchEnabledForEngage();
  const randomEntity = useCandidateForEntity(eventEntityType);
  const [beefreeJson, setBeefreeJson] = React.useState(moduleData.beefree_json);
  const [beefreeHtml, setBeefreeHtml] = React.useState(moduleData.beefree_html);
  const [beeChangeLog, setBeeChangelog] = React.useState([]);

  const editorRef = React.useRef();

  const onChange = React.useCallback(
    (beefree_json_doc: string, response?: OnChangeResponse) => {
      // We can inspect the changelog for possible issues, for now, let's just
      // keep the last five emitted change events
      setBeeChangelog((prev) => [...prev.slice(-4), response]);
      if (
        jobMatchEnabled &&
        checkForString(beefree_json_doc, surveyContentToken) > 1
      ) {
        dispatch(
          showGenericError({
            title: 'Too many surveys/jobs',
            text: 'Looks like you included another Survey/Job List in your email. Please remove all but one of them before proceeding',
          }),
        );
      }

      if (checkForString(beefree_json_doc, surveyContentToken) > 1) {
        dispatch(
          showGenericError({
            title: 'Too many surveys',
            text: jobMatchEnabled
              ? 'Looks like you included another Survey/Job List in your email. Please remove all but one of them before proceeding'
              : 'Looks like you just included another survey link in your email. Please remove all but one of them before proceeding.',
          }),
        );
      }

      const beefree_json = JSON.parse(beefree_json_doc);

      if (
        beefree_json?.page?.title === 'Template Base' ||
        beefree_json?.page?.description === 'Test template for BEE' ||
        beefree_json?.page?.template.version === '0.0.1'
      ) {
        // something has caused the template to be cleared, let's emit the changelog to sentry
        captureSentryMessage('got reverted beefree template', {
          extra: {
            changelog: beeChangeLog,
            beefreeJson, // this will likely be clipped by the 200k limit
          },
        });

        // maybe attempt to reload the existing document discarding this bad change
        editorRef.current?.handleLoad(beefreeJson);

        logger.log(
          `an error occurred while modifying following beefree document`,
        );
        logger.error(`changelog: ${JSON.stringify(beeChangeLog)}`);
        logger.error(
          `beefree doc before last change: ${JSON.stringify(beefreeJson)}`,
        );
        logger.error(`beefree doc after last change: ${beefree_json_doc}`);

        return;
      }

      const jsonDoc = props.onChange(beefree_json_doc, undefined, moduleData);

      setBeefreeJson(jsonDoc);
    },
  );

  // this is a misnomer, this callback flushes the current jsondoc embedded
  // in the editor even if it hasn't been flushed by an onChange event
  const onSaveAsTemplate = React.useCallback((beefree_json_doc: string) => {
    const jsonDoc = props.onChange(beefree_json_doc, undefined, moduleData);
    setBeefreeJson(jsonDoc);
  });

  // These are test methods that we attach to window during the lifecycle
  // of the editor so that we can debug or manually interact with the editor
  // instance
  React.useEffect(() => {
    window.__updateBeefreeDoc = (doc: string) => onChange(doc);
    window.__getBeefreeDoc = () => beefreeJson;
    window.__loadBeefreeDoc = (doc: BeefreeDocument) =>
      editorRef.current?.handleLoad(doc);
    return () => {
      delete window.__updateBeefreeDoc;
      delete window.__getBeefreeDoc;
      delete window.__loadBeefreeDoc;
    };
  }, [beefreeJson]);

  const mainEntityType = useSelector(selectWorkflowEntityMapping).display_name;

  const labelHandler = React.useCallback(
    (resolve, reject) => {
      dispatch(
        pushModal({
          type: 'VARIABLE_PICKER',
          labels: dynamicLabels,
          baseEntityType: mainEntityType,
          onClose: () => reject(),
          insertResult: (label, evt) => {
            if (evt) {
              evt.preventDefault();
              evt.stopPropagation();
            }
            resolve({name: label.name, value: `{{${label.value}}}`});
          },
        }),
      );
    },
    [dispatch, dynamicLabels, mainEntityType],
  );

  const [previewing, setPreviewing] = React.useState(false);
  const [structureVisible, setStructureVisible] = React.useState(false);
  // $FlowOptionalCall
  useDeferredEffect(
    () => editorRef.current?.toggleStructure(),
    [structureVisible],
  );

  const [previewSize, setPreviewSize] = React.useState('desktop');
  const {workflowId, eventId} = useSelector((state) => state.route.params);
  const {id: currentUserId} = useSelector(getCurrentAgent);
  // change this to default to beefreeHtml for faster initial preview
  const [previewdoc, setPreviewDoc] = React.useState(beefreeHtml);

  const [mockedEntityId, setMockedEntityId] = React.useState(randomEntity?.id);

  const [waitingForPreview, setWaitingForPreview] = React.useState(false);

  // force the bee to 'save' and flush the beefreejson into the
  // onSaveTemplate callback
  const flushBeeEditor = React.useCallback(() => {
    editorRef.current?.handleSaveTemplate();
  }, [editorRef]);

  const handleBuildPreviewToggle = async (evt) => {
    const isPreviewing = evt.currentTarget.value === 'preview';
    if (isPreviewing !== previewing) {
      if (isPreviewing) {
        editorRef.current?.handleSaveTemplate();
        handleBuildPreview(mockedEntityId);
      }
      setPreviewing(isPreviewing);
    }
  };

  //update mocked_entity_id with the first entity id
  React.useEffect(() => {
    if (randomEntity) {
      setMockedEntityId(randomEntity?.id);
    }
  }, [randomEntity]);

  const handleBuildPreview = async (mockedEntityId: ?string) => {
    setWaitingForPreview(true);
    let latestHtml = beefreeHtml;
    try {
      latestHtml = await api.post('workflows/beefree-html', {
        beefree_json: beefreeJson,
      });
      setBeefreeHtml(latestHtml);
      const normalized = parsers.normalize.event(
        fluxStore.eventCreation.state.event,
        jobMatchEnabled,
      );
      const temporary_event = {
        id: 'foo',
        modules: normalized.modules,
        beefree_json: normalized.beefree_json,
        content_subscription_category_id:
          normalized.content_subscription_category_id,
        content_subscription_reason: normalized.content_subscription_reason,
        skip_subscription_link: normalized.skip_subscription_link,
      };

      const payload = {
        temporary_event,
        mocked_entity_id: undefined,
      };
      if (mockedEntityId !== null) {
        payload['mocked_entity_id'] = mockedEntityId;
      }
      const previewHtml = await api.post(
        `workflows/${workflowId}/events/${eventId}/preview`,
        payload,
      );

      setPreviewDoc(previewHtml);
    } catch (error) {
      if (error instanceof ApiError) {
        dispatch(
          showApiError(error, 'There was an issue generating the preview.'),
        );
      } else {
        throw error;
      }
    } finally {
      setWaitingForPreview(false);
    }
  };

  return (
    <div style={{flexFlow: 'column', width: 1025, minWidth: 1025}}>
      <div>
        <SegmentedButton
          options={[
            {label: 'Build Email', value: 'build'},
            {label: 'Preview Email', value: 'preview'},
          ]}
          value={previewing ? 'preview' : 'build'}
          onChange={handleBuildPreviewToggle}
          className={css.buildPreviewToggle}
        />
      </div>
      {previewing && (
        <div className={css.previewContainer}>
          <div className={css.previewControls}>
            <SegmentedButton
              options={[
                {label: 'Desktop', value: 'desktop'},
                {label: 'Mobile', value: 'mobile'},
              ]}
              value={previewSize}
              onChange={(evt) => setPreviewSize(evt.currentTarget.value)}
            />

            <PreviewControls
              eventEntityType={eventEntityType}
              onEntityChange={setMockedEntityId}
              defaultEntity={camel(randomEntity)}
            />

            <div>
              <button
                className={css.button}
                disabled={waitingForPreview}
                onClick={() => handleBuildPreview(mockedEntityId)}
              >
                {waitingForPreview
                  ? 'Building preview...'
                  : 'Preview this email'}
              </button>
            </div>
          </div>
          <div className={css.previewContent}>
            <FakeBrowser
              className={
                previewSize === 'desktop' ? css.iframeDesktop : css.iframeMobile
              }
            >
              <PreviewIframe
                srcdoc={previewdoc}
                name="beefree-iframe-preview"
                previewSize={previewSize}
                className={
                  previewSize === 'desktop'
                    ? css.iframeDesktop
                    : css.iframeMobile
                }
              />
            </FakeBrowser>
          </div>
        </div>
      )}
      <div className={previewing ? css.hiddenBeeFree : css.beeFreeEditorBlock}>
        <div className={css.structureToggleRow}>
          <span
            className={css.structureToggleLabel}
            onClick={() => setStructureVisible((prev) => !prev)}
          >
            Show Structure
          </span>
          <Toggle
            className={css.structureToggle}
            checked={structureVisible}
            onChange={() => {
              setStructureVisible((prev) => !prev);
              // editorRef.current.toggleStructure();
            }}
          />
        </div>
        <div>
          <BeefreeEditor
            uid={currentUserId}
            ref={editorRef}
            onSaveAsTemplate={onSaveAsTemplate}
            onChange={onChange}
            trackChanges={true}
            initialTemplate={moduleData.beefree_json}
            contentDialog={{
              mergeTags: {
                label: 'Insert dynamic label',
                handler: labelHandler,
              },
            }}
            mergeContents={
              eventType === 'beefree_chatbot'
                ? []
                : [
                    {
                      name: jobMatchEnabled
                        ? 'THE SURVEY AND/OR JOB LIST WILL APPEAR HERE'
                        : 'Survey Content',
                      value: jobMatchEnabled
                        ? jobMatchContentToken
                        : surveyContentToken,
                    },
                  ]
            }
            specialLinks={[
              {
                type: 'Opt Outs',
                label: 'raw unsubscribe url',
                link: '<%asm_global_unsubscribe_raw_url%>',
              },
              eventType === 'beefree_chatbot'
                ? {
                    type: 'Chatbot',
                    label: 'link to chatbot',
                    link: chatLinkToken,
                  }
                : {
                    type: 'Survey',
                    label: 'link to survey',
                    link: surveyLinkEmailToken,
                  },
            ]}
            translations={{
              'mailup-bee-newsletter-modules-merge-content': {
                name: jobMatchEnabled ? 'Survey / Jobs' : 'Survey',
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

// Run an effect when args change but not during mount
function useDeferredEffect(effectFn, args) {
  const prev = React.useRef(null);

  React.useEffect(() => {
    if (prev.current !== null && prev.current !== args) {
      effectFn();
    }
    prev.current = args;
  }, args);
}

export function checkForString(json: string, string: string): number {
  return json.match(new RegExp(string, 'g'))?.length ?? 0;
}

function FakeBrowser({
  children,
  className,
}: {
  children: React.Node,
  className?: string,
}) {
  return (
    <div
      className={className}
      style={{
        flex: 1,
        flexFlow: 'column',
        marginTop: 20,
        border: '1px solid #ddd',
        borderRadius: 6,
        overflow: 'hidden',
      }}
    >
      <div style={{background: 'white', padding: '11px 12px 12px'}}>
        <span
          style={{
            width: 12,
            height: 12,
            borderRadius: 6,
            border: '1px solid #C4C4C4',
            marginRight: 8,
          }}
        />
        <span
          style={{
            width: 12,
            height: 12,
            borderRadius: 6,
            border: '1px solid #C4C4C4',
            marginRight: 8,
          }}
        />
        <span
          style={{
            width: 12,
            height: 12,
            borderRadius: 6,
            border: '1px solid #C4C4C4',
            marginRight: 8,
          }}
        />
      </div>
      <div style={{background: '#ddd', justifyContent: 'center'}}>
        {children}
      </div>
    </div>
  );
}

function PreviewControls({
  eventEntityType,
  onEntityChange,
  defaultEntity,
}: {
  eventEntityType: string,
  onEntityChange: (value: ?string) => *,
  defaultEntity: ?{id: string, ...},
}) {
  const [previewingAudienceId, setPreviewingAudienceId] = React.useState(
    (defaultEntity && defaultEntity.id) || null,
  );
  const [previewingPlacementId, setPreviewingPlacementId] =
    React.useState(null);

  const {loading, mockedEntityId, relatedEntities} = useMockedEntities(
    previewingAudienceId,
    previewingPlacementId,
    eventEntityType,
  );

  React.useEffect(() => {
    mockedEntityId && onEntityChange && onEntityChange(mockedEntityId);
  }, [mockedEntityId, onEntityChange]);
  const relatedEntitiesDropdownOptions =
    relatedEntities &&
    relatedEntities.map((entity) => ({
      value: entity.id,
      label: `${entity.company_name ? entity.company_name + ' ' : ''}(ID: ${
        entity.external_source_id
      })`,
    }));

  const atsEntityMappings = useSelector(getEntityTypeMappings);

  const workflowEntityInfo = useSelector(getCurrentWorkflowEntityType);
  const searchableEntityType = getSearchableEntity(
    atsEntityMappings,
    workflowEntityInfo,
  )?.name;

  return (
    <div className={css.previewControls}>
      <span className={css.selectLabel}>Viewing as:</span>
      <UserSearch
        entityType={searchableEntityType}
        onSearchResultClick={(entityId) => {
          setPreviewingAudienceId(entityId);
        }}
        defaultValues={defaultEntity ? [defaultEntity] : []}
        className={css.userSearch}
        showsValue={true}
        placeholder="Jana Ally"
        onClearSelection={() => setPreviewingAudienceId(null)}
      />

      {Array.isArray(relatedEntitiesDropdownOptions) &&
        relatedEntitiesDropdownOptions.length > 0 && (
          <React.Fragment>
            <span className={css.selectLabel}>
              {`${workflowEntityInfo.display_name}`}:
            </span>

            <BasicDropdown
              options={relatedEntitiesDropdownOptions}
              onChange={(entity) => {
                setPreviewingPlacementId(entity.value);
              }}
              selected={relatedEntitiesDropdownOptions.find(
                (option) => mockedEntityId === option.value,
              )}
              noOptionText={loading ? 'Loading' : 'no results'}
            />
          </React.Fragment>
        )}
    </div>
  );
}
