// @flow strict-local

// $FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores';
// $FlowFixMe[untyped-type-import]
import type {ScheduleDef} from 'src/stores/scheduling';

import type {
  ClientEventType,
  SenseApiEvent,
  SenseEvent,
} from 'src/types/events';

import {EventTypeEnum} from 'src/stores/event.js';
// $FlowFixMe[untyped-import]
import {validateEventAllTabs} from 'src/actions/event-creation';
import {defaultReasonGenerator} from 'src/utils/content-subscription';


export const ERROR_INVALID_TOUCHPOINT_IMPORT = 'InvalidTouchPointImport';

export const LLE_ERROR_MAP = {
  lle_audience_building_in_progress:
    'The list is currently building. Once it\'s built successfully, it will get attached to the workflow.',
  lle_audience_build_failed:
    'The audience building failed. We have alerted our tech team and are looking to find a fix.',
  lle_workflow_activation_failure:
    'The audience was built, but the workflow activation failed. The tech team has been alerted and they will get back with the updates.',
};

export const isDraftEvent = (pathname: string): boolean =>
  pathname.includes('/new');

export const isSelectingEventType = (pathname: string): boolean =>
  pathname.endsWith('/new');

export function isChatbotEvent(eventType: ClientEventType): boolean {
  return ['sms_chatbot', 'beefree_chatbot'].includes(eventType);
}

export function isSurveyEvent(
  eventType: ClientEventType,
  isRespondable: boolean,
): boolean {
  const isBeeFreeSurvey = eventType === 'beefree_email' && isRespondable;

  return isBeeFreeSurvey || ['sms_survey', 'survey'].includes(eventType);
}

export function isSmsEvent(eventType: ClientEventType): boolean {
  return ['sms_survey', 'sms_chatbot', 'sms_message', 'sms_nps'].includes(
    eventType,
  );
}

export function isDigest(eventType: ClientEventType): boolean {
  return eventType === EventTypeEnum.List;
}

export function isNpsEvent(event: SenseApiEvent): boolean {
  return event.modules.some(
    (module) => module.module_type === 'nps_survey_question',
  );
}

export function isJobMatchEvent(event: SenseEvent): boolean {
  return (
    event.eventType === EventTypeEnum.SMS_Job ||
    // $FlowFixMe[prop-missing]
    event.questions.some((module) => module.job_match_rte_json)
  );
}

export function isBulkWritebackEvent(event: SenseEvent): boolean {
  return event.eventType === EventTypeEnum.Bulk_Writeback;
}

export function isSmsSurveyEvent(eventType: ClientEventType): boolean {
  return eventType === EventTypeEnum.SMS_Survey;
}

export function isSmsNpsEvent(eventType: ClientEventType): boolean {
  return eventType === EventTypeEnum.SMS_NPS;
}

export function validateEvent(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  // $FlowFixMe[value-as-type]
  schedule: ScheduleDef,
  eventData: SenseEvent,
): boolean {
  if (schedule && eventData.eventType !== EventTypeEnum.Bulk_Writeback) {
    // TODO (nilarnab): validate bulk write here
    // ? validateBulkWriteback()
    return validateEventAllTabs(store, schedule, eventData);
  }

  return false;
}

export function eventContainsSurvey(event: SenseEvent): boolean {
  return (event.questions ?? []).some((question) =>
    [
      'text_survey_question',
      'rating_scale_survey_question',
      'nps_survey_question',
      'multiple_choice_survey_question',
      'calendar_date_survey_question',
      'file_upload_survey_question',
    ].includes(question.type),
  );
}

export function getBeefreeFooterHtml(): string {
  return `<div class="txtTinyMce-wrapper" style="font-family: Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; font-size: 12px; line-height: 18px;" data-mce-style="font-family: Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; font-size: 12px; line-height: 18px;">
  %reason_for_receiving_email% \n
  %manage_subscription_link% \n
  </div>`;
}

export function getBeefreeFooterHtmlOld(
  isTranscationalCategory: boolean,
  contentSubscriptionReason: ?string,
  agencyName: string,
): string {
  return `<div class="txtTinyMce-wrapper" style="font-family: Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; font-size: 12px; line-height: 18px;" data-mce-style="font-family: Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; font-size: 12px; line-height: 18px;">
  <p style="font-size: 14px; line-height: 21px; text-align: center;" data-mce-style="font-size: 14px; line-height: 21px; text-align: center;">
    <span style="font-size: 12px; line-height: 18px;" data-mce-style="font-size: 12px; line-height: 18px;">
      ${
        isTranscationalCategory
          ? contentSubscriptionReason ?? defaultReasonGenerator(agencyName)
          : ''
      }
    </span>
  </p>\n
  <p style="font-size: 14px; line-height: 21px; text-align: center;" data-mce-style="font-size: 14px; line-height: 21px; text-align: center;">
   You can ${
     isTranscationalCategory
       ? ''
       : `<a style="text-decoration: underline;" href="%content_subscription_unsubscribe_link%" target="_blank" rel="noopener">Unsubscribe</a> |`
   } <a style="text-decoration: underline;" href="%content_subscription_manage_link%" target="_blank" rel="noopener">Manage subscription preferences</a>
  </p>\n
  </div>`;
}

export const getSenseAiQuickTags = (
  event: SenseEvent,
  {
    isInternalEmployee,
    isLeadEntity,
  }: {isInternalEmployee: boolean, isLeadEntity: boolean},
): {label: string, value: string}[] => {
  const entityTypeTags = [];
  if (isInternalEmployee) {
    entityTypeTags.push(generateTag('Make it informal'));
  }
  if (isLeadEntity) {
    entityTypeTags.push(generateTag('Professional for Marketing reachout'));
  }
  //check and assign entity type tags
  if (isSmsEvent(event.eventType)) {
    return [
      ...entityTypeTags,
      generateTag('Tone it positively'),
      generateTag('Make it Shorter and keep under 140 characters'),
      generateTag('Make it professional'),
      generateTag('Make it longer'),
      generateTag('Remove Signing Off'),
    ];
  }

  //if not sms event, consider it as email event
  return [
    ...entityTypeTags,

    generateTag('Make it friendlier'),
    generateTag('Make it longer'),
    generateTag('Format it better'),
    generateTag('Tone it positively'),
    generateTag('Add Signing Off'),
    generateTag('Remove Signing Off'),
    generateTag('Rephrase it'),
  ];
};

const generateTag = (text: string): {label: string, value: string} => ({
  label: text,
  value: text,
});
