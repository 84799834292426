// @flow

import type {Privilege, Role} from 'src/action-creators/roles';
import type {State as AccountsState} from 'src/reducers/accounts';
import type {Account} from 'src/types/account';

import get from 'lodash/get';

// TODO (kyle): these utils are not type safe. remove them
export const getFromAuthedUser = (
  state: {accounts: AccountsState, ...},
  path: string | string[],
  defaultValue: any,
): any =>
  state.accounts.authedUserId
    ? get(state.accounts.data[state.accounts.authedUserId], path, defaultValue)
    : defaultValue;

export const hasPrivilege = (
  state: {accounts: AccountsState, ...},
  privilegeName: Privilege,
): boolean =>
  getFromAuthedUser(state, 'securityRole.privileges', []).includes(
    privilegeName,
  );

export const getAuthedUserConfiguration = (
  state: {accounts: AccountsState, ...},
  configurationKey: string,
): any =>
  getFromAuthedUser(state, 'securityRole.configurations')[configurationKey];

export function checkAccountPrivilege(
  account: Account,
  privilege: Privilege,
): boolean {
  const securityRole: Role =
    (account: any).security_role || account.securityRole;
  return securityRole.privileges.includes(privilege);
}
