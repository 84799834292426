// @flow

import type {
  ContactMap,
  ContactFormData,
  ContactCsvValue,
} from 'src/types/contacts';
import type {Action} from 'src/action-creators/contacts';
import type {MessagesAction} from 'src/action-creators/messages';
import type {ThreadListAction} from 'src/action-creators/thread-lists';
import type {ErrorMap} from 'src/types/redux';

import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';

import {
  RECEIVE_MANY_CONTACTS,
  RECEIVE_CONTACT_UPDATES,
  REMOVE_CONTACT,
  CHANGE_CONTACT_FORM,
  RESET_CONTACT_FORM,
  RECEIVE_CONTACT_FORM_ERRORS,
  RECEIVE_CSV,
  RECEIVE_CSV_ERRORS,
  CLEAR_CSV,
} from 'src/action-creators/contacts';
import {RECEIVE_INBOX, RECEIVE_THREAD} from 'src/action-creators/messages';
import {RECEIVE_BROADCAST} from 'src/action-creators/broadcasts';
import {RECEIVE_ONE as RECEIVE_THREAD_LIST} from 'src/action-creators/thread-lists';


export type State = {
  contacts: ContactMap,
  formData: ContactFormData,
  formDataErrors: ErrorMap,
  csv: ContactCsvValue,
};

const getEmptyFormData = () => ({
  firstName: '',
  lastName: '',
  phoneNumbers: [{value: '', primary: true}],
  email: null,
});

const initialState = {
  contacts: {},
  formData: getEmptyFormData(),
  formDataErrors: {},
  csv: undefined,
};

export default (
  state: State = initialState,
  action: Action | MessagesAction | ThreadListAction,
): State => {
  switch (action.type) {
    case RECEIVE_MANY_CONTACTS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          ...action.payload,
        },
      };

    case RECEIVE_CONTACT_UPDATES:
      // NOTE (kyle): this store is for full contact objects only. we don't
      // allow updates to non-existent contacts.
      const updates = action.payload.filter(({id}) => state.contacts[id]);
      return {
        ...state,
        contacts: merge({}, state.contacts, keyBy(updates, 'id')),
      };

    case REMOVE_CONTACT: {
      const id = action.payload;
      return {
        ...state,
        contacts: omit(state.contacts, id),
      };
    }

    case CHANGE_CONTACT_FORM: {
      const {formData, formDataErrors} = action.payload;
      return {
        ...state,
        formData,
        formDataErrors,
      };
    }

    case RESET_CONTACT_FORM:
      return {
        ...state,
        formData: {...getEmptyFormData(), ...action.payload},
        formDataErrors: {},
      };

    case RECEIVE_CONTACT_FORM_ERRORS: {
      return {
        ...state,
        formDataErrors: action.payload,
      };
    }

    case RECEIVE_CSV: {
      return {
        ...state,
        csv: action.payload,
      };
    }

    case RECEIVE_CSV_ERRORS: {
      return {
        ...state,
        csv: {
          errors: action.payload,
        },
      };
    }

    case CLEAR_CSV: {
      return {
        ...state,
        csv: undefined,
      };
    }

    // $FlowFixMe not sure why this isn't being picked up
    case RECEIVE_BROADCAST:
    case RECEIVE_THREAD_LIST:
    case RECEIVE_INBOX: {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          ...keyBy(action.payload.contacts, 'id'),
        },
      };
    }

    case RECEIVE_THREAD: {
      const {response, beforeTime} = action.payload;
      const {contact} = response;
      if (contact) {
        return {
          ...state,
          contacts: {
            ...state.contacts,
            [contact.id]: contact,
          },
        };
      }
    }
  }

  return state;
};

export const getEmptyContact = (): ContactFormData => ({
  firstName: '',
  lastName: '',
  phoneNumbers: [{value: '', primary: true}],
  email: null,
  /*
  phones: [{number: '', primary: true}],
  emails: [{address: '', primary: true}],
  */
});
