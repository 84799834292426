// @flow strict

import moment from 'moment';

import type {
  SurveySummarySavedResponseAPI,
  GetWorkflowsWithSurveysQuery,
  SurveySummarySavedResponse,
  WorkflowsQueryParamsAPI,
  IsProcessableAlert,
  ResponseMetaData,
} from 'src/types/survey-summary.js';


export const SAVED_SUMMARIES_PAGE_SIZE = 10;
export const normalizeWorkflowsQueryParams = ({
  entityTypes = [],
  pageNumber = 1,
  pageSize = 10,
  query = '',
}: GetWorkflowsWithSurveysQuery): WorkflowsQueryParamsAPI => {
  const offset = (pageNumber - 1) * pageSize;
  const limit = pageSize;
  const normalizedEntityTypes =
    Array.isArray(entityTypes) && entityTypes.length > 0
      ? entityTypes.join(',')
      : '';
  const params = {
    offset,
    limit,
    query,
    entityTypes: normalizedEntityTypes,
  };
  return params;
};

export const openJourneyInNewTab = (id: string): void => {
  const url = `/journey/${id}`;
  // Open the URL in a new tab
  window.open(url, '_blank');
};

// function to format date to "DD-MMM-yyyy" format
function formatDate(date: Date): string {
  return moment(date).format('DD-MMM-YYYY');
}

export const constructIsProcessableAlert = (
  responseMetaData: ResponseMetaData,
): IsProcessableAlert => {
  if (responseMetaData.is_processable === false) {
    if (responseMetaData.response_count === 0) {
      return {
        semantic: 'danger',
        text: 'There are no responses found in this date range. Please select a different date range.',
      };
    } else {
      const startDate = responseMetaData.start_date
        ? new Date(responseMetaData.start_date)
        : null;
      const endDate = responseMetaData.end_date
        ? new Date(responseMetaData.end_date)
        : null;
      const formattedStartDate = startDate ? formatDate(startDate) : '';
      const formattedEndDate = endDate ? formatDate(endDate) : '';

      return {
        semantic: 'warning',
        text: `The summary can only be generated from ${formattedStartDate} to ${formattedEndDate} for ${responseMetaData.response_count} responses.`,
      };
    }
  }

  return {
    semantic: 'information',
    text: `${responseMetaData.response_count} responses found in this date range.`,
  };
};

export const parseSummaryPayload = ({
  id,
  summary,
  start_date,
  end_date,
  event_id,
  event_module_id,
  event_name,
  follow_up_summary,
  workflow_id,
  workflow_name,
  quick_prompts,
  number_of_responses,
  question,
}: SurveySummarySavedResponseAPI): SurveySummarySavedResponse => {
  return {
    id,
    summary,
    eventId: event_id,
    eventName: event_name,
    workflowId: workflow_id,
    workflowName: workflow_name,
    eventModuleId: event_module_id,
    question,
    endDate: end_date,
    startDate: start_date,
    quickPrompts: quick_prompts,
    followUpSummary: follow_up_summary,
    numberOfResponses: number_of_responses,
  };
};
