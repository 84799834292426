// @flow strict

type SetMap<K, T> = Map<K, Set<T>>;

export const getTotalSelectedCount = <K, T>(
  mapOfSets: SetMap<K, T>,
): number => {
  // Count the total number of items
  let totalCount: number = 0;
  // Iterate over the values of the Map and sum up the sizes of the Sets
  for (const set of mapOfSets.values()) {
    totalCount += set.size || 0;
  }
  return totalCount;
};

export const getFlatArrayOfSelectedEntries = <K, T>(
  mapOfSets: SetMap<K, T>,
): Array<T> => {
  let flatSet: Set<T> = new Set();
  for (const set of mapOfSets.values()) {
    flatSet = new Set([...flatSet, ...set]);
  }
  const flatArray: Array<T> = Array.from(flatSet);
  return flatArray;
};

export const flattenMapArrays = <K, T>(map: Map<K, Array<T>>): Array<T> => {
  let flatArray: Array<T> = [];
  for (const set of map.values()) {
    flatArray = flatArray.concat(Array.from(set));
  }
  return flatArray;
};

export const itemsOnPageText = (
  pageNumber: number = 1,
  pageSize: number,
  totalCount: number,
): string => {
  let pageStart = 0;
  let pageEnd = 0;
  if (totalCount > 0) {
    pageStart = (pageNumber - 1) * pageSize + 1;
    pageEnd = Math.min(pageNumber * pageSize, totalCount);
  }
  return `Items on page: ${pageStart} - ${pageEnd} of ${totalCount}`;
};
