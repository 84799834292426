// @flow strict-local

import type {Dispatch, GetState, ThunkAction} from 'src/reducers';
import type {Router, Params} from 'src/types/router';

import {batch, type BatchAction} from 'src/action-creators/batch';

import {getInbox, getUnreadCounts} from 'src/action-creators/messages';
import {getAgentSettings} from 'src/action-creators/agent-settings';
import {getPhoneCapabilities} from 'src/action-creators/phone-numbers';
import {isQueueInbox} from 'src/selectors/multi-number-inbox';


export const SET_INBOX = 'chat/multi-number-inbox/set-inbox';
export const HIDE_SIDE_BAR = 'chat/multi-number-inbox/hide-side-bar';
export const SET_SELECTED_THREAD =
  'chat/multi-number-inbox/set-selected-thread';
export const SET_INBOX_TYPE = 'chat/multi-number-inbox/set-inbox-type';

export type Action =
  | SetInboxAction
  | HideSideBarAction
  | SetSelectedThreadAction
  | SetInboxTypeAction;

type SetInboxAction = {
  type: 'chat/multi-number-inbox/set-inbox',
  payload: string,
};

type HideSideBarAction = {
  type: 'chat/multi-number-inbox/hide-side-bar',
  payload: boolean,
};

type SetSelectedThreadAction = {
  type: 'chat/multi-number-inbox/set-selected-thread',
  payload: {[number]: string},
};

type SetInboxTypeAction = {
  type: 'chat/multi-number-inbox/set-inbox-type',
  payload: string,
};

export const setInbox = (payload: string): SetInboxAction => ({
  type: SET_INBOX,
  payload,
});
export const changeInbox =
  (router: Router, params: Params, inboxId: string): ThunkAction<void> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const {phone, phoneNumberSetId} = params;
    const isQueueInboxSelected = isQueueInbox(getState());
    await dispatch(setInbox(inboxId));

    if (
      router.location.pathname === '/messages' ||
      router.location.pathname === '/messages/' ||
      router.location.pathname.startsWith('/contact') ||
      router.location.pathname.startsWith('/messages/queues') ||
      router.location.pathname.startsWith('/messages/hv-broadcasts') ||
      phone ||
      phoneNumberSetId
    ) {
      router.replace({
        ...router.location,
        pathname: (() => {
          if (router.location.pathname.startsWith('/messages/broadcasts')) {
            return '/messages/broadcasts';
          } else if (router.location.pathname.startsWith('/contact')) {
            return '/contacts';
          } else {
            return isQueueInboxSelected ? '/messages/queues' : '/messages';
          }
        })(),
      });
    }
    dispatch(getAgentSettings());
    dispatch(getPhoneCapabilities());
    dispatch(getUnreadCounts());
  };

export const hideSideBar = (payload: boolean): HideSideBarAction => ({
  type: HIDE_SIDE_BAR,
  payload,
});

export const setSelectedThread = (payload: {
  [number]: string,
}): SetSelectedThreadAction => ({
  type: SET_SELECTED_THREAD,
  payload,
});

export const setInboxType = (payload: string): SetInboxTypeAction => ({
  type: SET_INBOX_TYPE,
  payload,
});
