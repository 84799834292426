// @flow strict

import * as React from 'react';

import ErrorPage from './error-page.jsx';

import css from './500.css';


const RuntimeError = (): React.Node => (
  <ErrorPage pageTitle="Runtime Error">
    <h2 className={css.smallerTextTitle}>Runtime Error</h2>
    <span>
      Apologies, but an unexpected error occured. If this keeps happening,
      <br />
      please email support@sensehq.com
    </span>
  </ErrorPage>
);

export default RuntimeError;
