// @flow

// $FlowFixMe[untyped-type-import]
import type {Workflow} from 'src/api-parsers/index';
import type {
  Dispatch,
  GetState,
  ThunkAction,
  ThunkSyncAction,
} from 'src/reducers/index';
import type {BusinessGoals} from 'src/types/workflow';
import type {BackgroundTaskStatusResponse} from 'src/action-creators/background-task';
import type {BackgroundTaskStatus} from 'src/reducers/background-task.js';
import type {EntityType} from 'src/types/ats-entities';
// $FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores/index';

import omit from 'lodash/omit';
import sculpt from 'sculpt';

import {thunkify as flow} from 'src/utils/thunks';
import {camel} from 'src/utils';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api';
import logger from 'src/utils/logger';

import {
  getWorkflowActivationKey,
  getWorkflowFilterKey,
} from 'src/utils/background-task';
import {
  getBackgroundTaskStatus,
  receiveBackgroundTaskStatus,
} from 'src/action-creators/background-task.js';
import parsers from 'src/api-parsers/index';


export const RECEIVE_ALL = 'workflow/receiveAll';
export const UPDATE = 'workflow/update';
export const BULK_UPDATE_WORKFLOWS = 'workflow/bulkUpdate';
export const DELETE_WORKFLOW = 'workflow/delete';
export const RECEIVE_ENTITY_TYPES = 'workflow/receiveEntityTypes';
export const RECEIVE_BUSINESS_GOALS = 'workflow/receiveBusinessGoals';
export const RECEIVE_RECIPIENT_TYPES = 'workflow/receiveRecipientTypes';
export const RECEIVE_WORKFLOW = 'workflow/recieveWorkflow';

export type ReceiveWorkflowsAction = {|
  type: 'workflow/receiveAll',
  // $FlowFixMe[value-as-type] [v1.32.0]
  payload: Workflow[],
|};

export type ReceiveWorkflowAction = {|
  type: 'workflow/recieveWorkflow',
  // $FlowFixMe[value-as-type] [v1.32.0]
  payload: Workflow[],
|};

export type UpdateWorkflowAction = {
  type: 'workflow/update',
  payload: {
    workflowId: string,
    // $FlowFixMe[value-as-type] [v1.32.0]
    update: $Shape<Workflow>,
  },
};
export type BulkUpdateWorkflowAction = {
  type: 'workflow/bulkUpdate',
  payload: {
    // $FlowFixMe[value-as-type] [v1.32.0]
    [string]: $Shape<Workflow>,
  },
};

export type DeleteWorkflowAction = {|
  type: 'workflow/delete',
  // $FlowFixMe[value-as-type] [v1.32.0]
  payload: Workflow,
|};

export type ReceiveEntityTypesAction = {|
  type: 'workflow/receiveEntityTypes',
  payload: EntityType[],
|};

export type ReceiveBusinessGoalsAction = {|
  type: 'workflow/receiveBusinessGoals',
  payload: BusinessGoals[],
|};

export type ReceiveRecipientTypesAction = {|
  type: 'workflow/receiveRecipientTypes',
  payload: {[workflowId: string]: string[]},
|};

export type WorkflowAction =
  | ReceiveWorkflowsAction
  | UpdateWorkflowAction
  | BulkUpdateWorkflowAction
  | DeleteWorkflowAction
  | ReceiveEntityTypesAction
  | ReceiveRecipientTypesAction
  | ReceiveBusinessGoalsAction
  | ReceiveWorkflowAction;

export const receiveWorkflows = (
  // $FlowFixMe[value-as-type] [v1.32.0]
  payload: Workflow[],
): ReceiveWorkflowsAction => ({
  type: RECEIVE_ALL,
  // dupe of branchedEventIds omission in getWorkflows() in src/actions/workflow.js
  payload: payload.map((workflow) => omit(workflow, ['branchedEventIds'])),
});

// $FlowFixMe[value-as-type] [v1.32.0]
export const receiveWorkflow = (payload: Workflow): ReceiveWorkflowAction => ({
  type: RECEIVE_WORKFLOW,
  payload: [payload],
});

export const updateWorkflow = (
  workflowId: string,
  // $FlowFixMe[value-as-type] [v1.32.0]
  update: $Shape<Workflow>,
): UpdateWorkflowAction => ({
  type: UPDATE,
  payload: {
    workflowId,
    update,
  },
});
export const bulkUpdateWorkflow = (
  // $FlowFixMe[value-as-type] [v1.32.0]
  payload: {[string]: $Shape<Workflow>},
): BulkUpdateWorkflowAction => ({
  type: BULK_UPDATE_WORKFLOWS,
  payload,
});

// $FlowFixMe[value-as-type] [v1.32.0]
export const deleteWorkflow = (payload: Workflow): DeleteWorkflowAction => ({
  type: DELETE_WORKFLOW,
  payload,
});

export const receiveEntityTypes = (
  payload: EntityType[],
): ReceiveEntityTypesAction => ({
  type: RECEIVE_ENTITY_TYPES,
  payload,
});

export const receiveBusinessGoals = (
  payload: BusinessGoals[],
): ReceiveBusinessGoalsAction => ({
  type: RECEIVE_BUSINESS_GOALS,
  payload,
});

export const receiveRecipientTypes = (
  workflowId: string,
  recipientTypes: string[],
): ReceiveRecipientTypesAction => ({
  type: RECEIVE_RECIPIENT_TYPES,
  payload: {[workflowId]: recipientTypes},
});

// TODO(marcos): this is currently unused
export const getAllWorkflows: () => ThunkAction<mixed> = flow(
  key(() => 'workflow/getAll'),
  cached((json) => receiveWorkflows(camel(json))),
  fetching(),
)(() => reduxApi.get('workflows'));

export const receiveWorkflowActivationStatus =
  (
    workflowId: string,
    backgroundActivationStatus: BackgroundTaskStatusResponse,
  ): ThunkSyncAction =>
  (dispatch: Dispatch) => {
    const key = getWorkflowActivationKey(workflowId);
    const statusUrl = `workflows/${workflowId}/activate/status`;
    return dispatch(
      receiveBackgroundTaskStatus({
        key,
        statusUrl,
        ...backgroundActivationStatus,
      }),
    );
  };

export const receiveWorkflowFilterStatus =
  (
    workflowId: string,
    backgroundFilterStatus: BackgroundTaskStatusResponse,
  ): ThunkSyncAction =>
  (dispatch: Dispatch) => {
    const key = getWorkflowFilterKey(workflowId);
    const statusUrl = `workflows/${workflowId}/audience-filter/status`;
    return dispatch(
      receiveBackgroundTaskStatus({
        key,
        statusUrl,
        ...camel(backgroundFilterStatus),
      }),
    );
  };

export const getWorkflowActivationStatus =
  (
    // $FlowFixMe[value-as-type] [v1.32.0]
    store: IndexStore,
    workflowId: string,
  ): ThunkAction<?BackgroundTaskStatus> =>
  async (dispatch: Dispatch): Promise<?BackgroundTaskStatus> => {
    try {
      const activationKey = getWorkflowActivationKey(workflowId);
      const {
        payload: {status},
      } = await dispatch(
        getBackgroundTaskStatus(
          activationKey,
          `workflows/${workflowId}/activate/status`,
        ),
      );

      const update = (state: boolean) => {
        const workflow = store.workflows.get(workflowId);
        store.workflows.update(
          sculpt(workflow, {
            active: {
              $set: state,
            },
          }),
        );
        dispatch(updateWorkflow(workflowId, {active: state}));
      };

      if (status === 'inactive') {
        update(false);
      } else if (status === 'success') {
        update(true);
      }

      return status;
    } catch (error) {
      logger.error(error);
    }
  };

export const getWorkflowFilterStatus =
  (workflowId: string): ThunkAction<?BackgroundTaskStatus> =>
  async (dispatch: Dispatch): Promise<?BackgroundTaskStatus> => {
    try {
      const filterKey = getWorkflowFilterKey(workflowId);
      const {
        payload: {status},
      } = await dispatch(
        getBackgroundTaskStatus(
          filterKey,
          `workflows/${workflowId}/audience-filter/status`,
        ),
      );
      return status;
    } catch (error) {
      logger.error(error);
    }
  };

// TODO(marcos) DUPE: decorator for multientity-config?
export const getAllowedEntityTypes: () => ThunkAction<EntityType[]> = flow(
  key(() => 'workflow/allowedEntityTypes'),
  cached((json) => receiveEntityTypes(camel(json)), {ttl: 60 * 60 * 1000}),
  // 1hr, these are tied to ats and are unlikely to change ever during a session
  fetching(),
)(
  () => (dispatch: Dispatch, getState: GetState) =>
    // $FlowFixMe[incompatible-type-arg]
    dispatch(reduxApi.get('workflows/allowed-entity-types')),
);

export const getBusinessGoals: () => ThunkAction<BusinessGoals> = flow(
  key(() => 'workflow/getBusinessGoals'),
  cached((json) => receiveBusinessGoals(camel(json)), {ttl: 60 * 60 * 1000}),
  // 1hr, these are unlikely to change ever during a session
  fetching(),
)(
  () => (dispatch: Dispatch, getState: GetState) =>
    // $FlowFixMe[incompatible-type-arg]
    dispatch(reduxApi.get('journeys/business-goal')),
);

// TODO(marcos) DUPE: decorator for multientity-config?
export const getEventRecipientTypes: (
  workflowId: string,
) => ThunkAction<EntityType> = flow(
  key((workflowId: string) => `allowed-recipient-types/${workflowId}`),
  cached(
    (recipientTypes, workflowId) =>
      receiveRecipientTypes(workflowId, camel(recipientTypes)),
    {ttl: 60 * 60 * 1000},
  ), // 1hr, these are tied to workflow and can't be changed easily
  fetching(),
)(
  (workflowId: string) => (dispatch: Dispatch, getState: GetState) =>
    // $FlowFixMe[incompatible-type-arg]
    dispatch(
      reduxApi.get(`workflows/${workflowId}/events/allowed-recipient-types`),
    ),
);

export const getWorkflowById: (workflowId: string) => ThunkAction<EntityType> =
  flow(
    key((workflowId) => `getWorkflow ${workflowId})`),
    cached((response) => receiveWorkflow(parsers.parse.workflow(response)), {
      ttl: 60 * 60 * 1000,
    }),
    fetching(),
  )(
    (workflowId: string) => (dispatch: Dispatch, getState: GetState) =>
      dispatch(reduxApi.get(`workflows/${workflowId}`)),
  );
