// @flow

// $FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores/index';

import * as React from 'react';

import {isMobileDevice} from 'src/utils/responsive';
import isValidBrowser from 'src/webapp.browserslist.json';
import FluxComponent from 'src/flux/component.jsx';
import Notification from 'src/components/navigation/notification.jsx';

import css from './bad-browser-notification.css';


const mapper = ({store}) => ({
  userAgent: store.requestHeaders.state.headers['user-agent'],
});

type Props = {
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  userAgent: string,
};

const BadBrowserNotification = ({store, userAgent}: Props) => {
  if (!isValidBrowser(userAgent) || isMobileDevice(userAgent)) {
    /* $FlowIssue - children prop as JSX not recognized by FlowType */
    return (
      <Notification store={store} className={css.wrapper}>
        <strong>You're using an unsupported browser.</strong>
        <span>
          For the best experience on Sense, please be sure you're on a desktop
          (not a tablet or phone), using the latest versions of Chrome, Firefox,
          Safari, or Microsoft Edge.
        </span>
      </Notification>
    );
  } else {
    return null;
  }
};

const isKnownBadBrowser = (userAgent: string): boolean =>
  // Mobile browsers are not supported
  /Mobi/.test(userAgent) ||
  // IE<11 not supported
  /MSIE/.test(userAgent) ||
  // IE11 not supported
  /Trident\/7\./.test(userAgent);

export default (FluxComponent(BadBrowserNotification, mapper, {
  pure: true,
}): React.ComponentType<{}>);
