// @noflow

import type {
  QuestionType,
  ApiQuestion,
  Question,
  ApiBranch,
  Branch,
  BranchEvent,
  ApiBranchEvent,
  ApiMultipleChoiceQuestion,
  MultipleChoiceQuestion,
  ApiRhetoricalQuestion,
  ApiBeeFreeModule,
  BeeFreeModule,
  RhetoricalQuestion,
  ApiSMSRhetoricalQuestion,
  SMSRhetoricalQuestion,
} from 'src/types/survey';
import type {
  SenseEvent,
  SenseApiEvent,
  BrandingSettings,
  EventAttachment,
  EventTemplate,
  ApiEventTemplate,
  SendMetadata,
  SendApiMetadata,
} from 'src/types/events';
import type {NewEvent, ApiNewEvent} from './events.types';

import omit from 'lodash/omit';
import uniqueId from 'lodash/uniqueId';

import {deepDefined, defined, camel, snake} from 'src/utils/index';
import indexParsers from './index';

import typedEventParsers from 'src/api-parsers/typed-event-parsers';
import {EventTypeEnum} from 'src/stores/event';


type Event = SenseEvent;
type ApiEvent = SenseApiEvent;

const normalizeEventType = (eventType, clientEventType): string => {
  if (clientEventType === EventTypeEnum.SMS_Job) {
    //sms_job is just a ui construct, under the hood, its
    //sms_message event with include job enabled
    return EventTypeEnum.SMS_Message;
  }
  return eventType;
};

/**
 * Parsers
 */
const parsers = {
  normalize: {
    create: (event: NewEvent): ApiNewEvent => ({
      event_type: event.type || 'survey', //event type is not available when creating branched survey touchpoint
      title: event.title,
      content_subscription_category_id:
        event.content_subscription_category_id || null,
      content_subscription_reason: event.content_subscription_reason || null,
      send_metadata: {'1_per_day': true},
      branding_settings: {},
      field_change_rules: null,
      //(diwakersurya) changing default enabled to false ENGAGE-5259
      //enabled = true for branched events
      enabled: event.enabled || false,
    }),
    event: (event: Event, appendJobMatchFields: boolean = false): ApiEvent => ({
      event_type: normalizeEventType(event.type, event.eventType),
      author_id: event.authorId,
      id: event.id,
      modules: event.questions.map((question) =>
        parsers.normalize.question(question, appendJobMatchFields),
      ),
      title: event.title,
      is_title_temporary: event.isTitleTemporary,
      content_subscription_category_id:
        event.content_subscription_category_id || null,
      content_subscription_reason: event.content_subscription_reason ?? null,
      subject: event.subject
        ? {
            text: event.subject,
          }
        : undefined,
      type_locked: event.typeLocked,
      branding_settings: indexParsers.normalize.brandingSettings(
        event.brandingSettings,
      ),
      recipient_resource: event.recipientResource
        ? event.recipientResource
        : null,
      dynamic_delivery_address: event.dynamicDeliveryAddress,
      send_metadata: parsers.normalize.sendMetadata(event.sendMetadata),
      enabled: event.enabled,
      beefree_json: event.beefreeJson ?? undefined, // typeof event.beefreeJson === 'string' ? event.beefreeJson : JSON.stringify(event.beefreeJson) ?? undefined,
      chatbot_flow_id: event.chatbot_flow_id ?? null,
      field_change_rules: event.field_change_rules,
      note_writeback_action_type: event.note_writeback_action_type,
      note_writeback_action_value: event.note_writeback_action_value,
      skip_subscription_link: event.skip_subscription_link,
    }),
    sendMetadata: (sendMetadata: SendMetadata): SendApiMetadata => {
      // NOTE (marcos): this handles a case temporarily where we enable
      // 1-per-client option as a special case until there's ui to handle
      // all attributes generally. see ENGAGE-3174)
      // When the designs are finalized, we'll remove this block here and
      // we'll also change suppression-options.jsx as well as the test
      // in event-parsers-test that will undoubtedly fail.
      let normalizedSendMetadata = snake(sendMetadata);
      if (normalizedSendMetadata['1_per_client']) {
        normalizedSendMetadata = omit(
          {
            ...normalizedSendMetadata,
            '1_per_attribute': 'company_name',
          },
          '1_per_client',
        );
      }
      return normalizedSendMetadata;
    },
    eventTemplate: (template: EventTemplate): ApiEventTemplate => ({
      /*
      'id': template.id,
      'template_type': template.templateType,
      'agent_id': template.agentId,
      'source_template_id': template.sourceTemplateId,
      */
      name: template.name,
      html: template.html,
      template_settings: snake(deepDefined(template.templateSettings)),
    }),

    eventAttachment: (attachment: EventAttachment): ApiEventAttachment => ({
      id: attachment.id,
      name: attachment.name,
      file_size: attachment.fileSize,
    }),

    eventAttachments: (attachments: EventAttachment[]): ApiEventAttachment[] =>
      attachments.map(parsers.normalize.eventAttachment),

    question: (
      question: Question,
      appendJobMatchFields: boolean = false,
    ): ApiQuestion => {
      const newQuestionId =
        !question.id ||
        question.id.match(/^new:/) ||
        question.questionTypeUpdated;
      const id = newQuestionId ? undefined : question.id;

      switch (question.type) {
        case 'nps_survey_question':
        case 'rating_scale_survey_question':
          // $FlowIssue: this remains a problem with flow's switch handling
          return {
            module_type: (question.type:
              | 'nps_survey_question'
              | 'rating_scale_survey_question'),
            id,
            question: question.question,
            required: question.required,
            max_text: question.maxText,
            min_text: question.minText,
            min_rating: question.minRating,
            max_rating: question.maxRating,
            branches: question.branches
              ? question.branches.map((branch) =>
                  parsers.normalize.branch(branch, appendJobMatchFields),
                )
              : undefined,
            alert_rating: question.alertRating,
            writeback: question.writeback?.enabled ? question.writeback : null,
          };
        case 'multiple_choice_survey_question':
          const choices = question.choices
            .map(({value}) => value)
            .filter((choice) => choice);

          const choices_text =
            question.writeback?.enabled &&
            Object.fromEntries(
              Object.entries(question.writeback.choices_text).filter(
                ([key, value]) => choices.includes(key),
              ),
            );

          return ({
            module_type: question.type,
            id,
            question: question.question,
            required: question.required,
            choices,
            branches: question.branches
              ? question.branches.map((branch) =>
                  parsers.normalize.branch(branch, appendJobMatchFields),
                )
              : undefined,
            alert_choices: question.alertChoices
              .map(
                (id) =>
                  question.choices.find((choice) => choice.id === id)?.value,
              )
              .filter(Boolean),
            writeback: question.writeback?.enabled
              ? {
                  ...question.writeback,
                  choices_text,
                }
              : null,
            allow_select_multiple: question.allow_select_multiple,
            multi_select_limit: question.multi_select_limit,
          }: ApiMultipleChoiceQuestion);
        case 'text_survey_question':
          return {
            module_type: question.type,
            id,
            question: question.question,
            required: question.required,
            alert_keywords: question.alertKeywords,
            writeback: question.writeback?.enabled ? question.writeback : null,
          };
        case 'calendar_date_survey_question':
          return {
            module_type: question.type,
            id,
            question: question.question,
            required: question.required,
            alert_date: question.alertDate,
            alert_condition: question.alertCondition,
            date_format: question.dateFormat,
            writeback: question.writeback?.enabled ? question.writeback : null,
          };
        case 'message_survey_module':
          const emailJobFields = {
            ...(appendJobMatchFields && {
              job_matches_count: question.job_matches_count,
              job_matches_params: question.job_matches_params ?? {},
              job_match_rte_json: question.include_job_block
                ? question.job_match_rte_json
                : null,
              send_alt_text: question.send_alt_text,
              alt_rte_json: question.alt_rte_json || null,
            }),
          };
          return ({
            module_type: question.type,
            id,
            text: question.text,
            rte_json: question.rte_json,
            ...emailJobFields,
          }: ApiRhetoricalQuestion);
        // TODO(marcos): fill this in
        case 'beefree_module':
          return ({
            module_type: question.type,
            id,
            beefree_html: question.beefree_html,
            beefree_json:
              typeof question.beefree_json === 'string'
                ? JSON.parse(question.beefree_json)
                : question.beefree_json,
          }: ApiBeeFreeModule);
        case 'sms_message_survey_module':
          //presence of includes_job_match_variables in the module
          //marks that is it a sms_job type event
          const smsJobFields = {
            ...(appendJobMatchFields &&
              typeof question['includes_job_match_variables'] !==
                'undefined' && {
                includes_job_match_variables:
                  question['includes_job_match_variables'],
                send_alt_text: question['send_alt_text'],
                ...(question['includes_job_match_variables'] && {
                  job_matches_count: question.job_matches_count,
                  job_matches_params: question.job_matches_params ?? {},
                }),
                alt_text: question['alt_text'] || '',
              }),
          };
          return ({
            module_type: question.type,
            id,
            text: question.text,
            ...smsJobFields,
          }: ApiSMSRhetoricalQuestion);
        case 'attribute_list_module':
          return {
            module_type: question.type,
            id,
            attributes_to_include: question.attributesToInclude,
          };
        default:
          throw 'TypeError: question.module_type is not valid.';
      }
    },

    surveyResponse: (response: SurveyResponse): ApiSurveyResponse =>
      defined({
        event_response_type: response.type,
        id: response.id,
        event_id: response.eventId,
        response_modules: response.responseModules
          // ENGAGE-2296 The module_value check is a stopgap for mcq
          // questions that sometimes get added to response_modules
          // but have no valid module_value -- marcos
          .filter((answer) => answer && answer.moduleValue !== undefined)
          .map(parsers.normalize.answer),
        is_possible_bot: response.isPossibleBot,
      }),

    branch: (
      branch: Branch,
      appendJobMatchFields: boolean = false,
    ): ApiBranch => {
      const normalizedBranch = {};
      normalizedBranch['id'] =
        branch.id && branch.id.indexOf('new_branch_') > -1
          ? undefined
          : branch.id;
      normalizedBranch['name'] = branch.name;
      normalizedBranch['condition'] = {
        response: {},
      };
      normalizedBranch['branch_type'] = branch.branchType;

      if (branch.event && branch.branchType === 'event_branch') {
        normalizedBranch['event'] = parsers.normalize.branchEvent(
          branch.event,
          appendJobMatchFields,
        );
      } else if (branch.branchType === 'module_branch') {
        normalizedBranch['modules'] =
          branch.questions &&
          branch.questions.map((question) =>
            parsers.normalize.question(question, appendJobMatchFields),
          );
      }

      normalizedBranch.condition['response']['gte'] = branch.responseGte
        ? branch.responseGte
        : undefined;
      normalizedBranch.condition['response']['lte'] = branch.responseLte
        ? branch.responseLte
        : undefined;
      normalizedBranch.condition['response']['in'] =
        branch.responseIn && branch.responseIn.filter(Boolean).length > 0
          ? branch.responseIn
          : undefined;

      return normalizedBranch;
    },

    branchEvent: (event: BranchEvent): ApiBranchEvent => ({
      id: event.id,
      schedule: event.schedule
        ? {
            send_date: event.schedule.sendDate
              ? event.schedule.sendDate
              : undefined,
            // display_timezone only supported for send_date
            display_timezone: event.schedule.sendDate
              ? event.schedule['display_timezone']
              : undefined,
            delay_after_response: event.schedule.fromDate
              ? {
                  day: event.schedule.fromDate.values[0].day,
                  time:
                    event.schedule.fromDate.values[0].day > 0
                      ? event.schedule.fromDate.values[0].time
                      : undefined,
                }
              : undefined,
          }
        : undefined,
    }),

    answer: (answer: Answer): ApiAnswer => {
      const {type} = answer;
      const apiAnswer: ApiAnswer = {
        response_module_type: type,
        module_id: answer.moduleId,
        module_value: answer.moduleValue,
      };

      if (answer.id) {
        apiAnswer['id'] = answer.id;
      }

      const moduleTypes = new Set([
        'rating_scale_survey_answer',
        'nps_survey_answer',
        'multiple_choice_survey_answer',
        'checkboxes_survey_answer',
        'text_survey_answer',
        'calendar_date_survey_answer',
      ]);

      if (moduleTypes.has(type)) {
        return apiAnswer;
      } else {
        throw `TypeError: answer.type "${type}" is not valid.`;
      }
    },
  },

  parse: {
    eventType: typedEventParsers.parse.eventType,
    event: (
      json: SenseApiEvent,
      appendJobMatchFields: boolean = false,
    ): SenseEvent => {
      const noBeeFree = (question) => question.beefree_json === undefined;
      const eventType = parsers.parse.eventType(json);
      const filteredQuestions = json['modules']
        .map((question) =>
          parsers.parse.question(question, appendJobMatchFields, eventType),
        )
        .reduce(
          json['event_type'] === 'sms_survey'
            ? parsers.parse.filteredSMSSurveyQuestions
            : parsers.parse.filteredSurveyQuestions,
          [],
        );

      // temporarily translate 1_per_attribute for scheduling options
      // see ENGAGE-3174 (marcos)
      let sendMetadata = json['send_metadata'];
      if (sendMetadata['1_per_attribute'] === 'company_name') {
        sendMetadata['1_per_client'] = true;
        sendMetadata['1_per_attribute'] = undefined;
      }
      sendMetadata = camel(sendMetadata);
      return {
        id: json['id'],
        // again, we're sorry, .type is the _actual_ api event type
        type: json['event_type'],
        // eventType here is the internal event type (includes nps/sms_job)
        eventType,
        authorId: json['author_id'],
        workflowId: json['workflow_id'],
        // Always hide first beefree module if present since it's attached to the
        // main event below
        questions: json['modules']
          .filter(noBeeFree)
          .map((question) =>
            parsers.parse.question(question, appendJobMatchFields, eventType),
          ),
        filteredQuestions,
        title: json['title'] || '',
        isTitleTemporary: json['is_title_temporary'] || '',
        content_subscription_category_id:
          json['content_subscription_category_id'] || null,
        content_subscription_reason:
          json['content_subscription_reason'] ?? null,
        subject: json['subject'] && json['subject']['text'],
        typeLocked: json['type_locked'],
        attachments: json['attachments'].map(parsers.parse.eventAttachment),
        brandingSettings: indexParsers.parse.brandingSettings(
          json['branding_settings'],
        ),
        entityType: json['entity_type'], // Used by MultiEntity events
        recipientResource: json['recipient_resource'],
        dynamicDeliveryAddress: json['dynamic_delivery_address'],
        sendMetadata,
        enabled: json['enabled'],
        // upgrade old beefree emails in place by extracting the first question's
        // beefree_json if available
        beefreeJson: json['beefree_json'] ?? filteredQuestions[0]?.beefree_json,
        chatbot_flow_id: json.chatbot_flow_id,
        field_change_rules: json['field_change_rules'],
        is_respondable: json['is_respondable'],
        note_writeback_action_type:
          json['writeback_metadata']?.note_writeback_action_type,
        note_writeback_action_value:
          json['writeback_metadata']?.note_writeback_action_value,
        skip_subscription_link: json['skip_subscription_link'],
      };
    },

    eventTemplate: (json: Object): EventTemplate => {
      const template = camel(json);

      // backwards compatible
      if (!template.templateSettings.links) {
        template.templateSettings.links = {};
      }

      return template;
    },

    eventTemplates: (json: Object[]): EventTemplate[] =>
      json.map(parsers.parse.eventTemplate),

    eventAttachment: (json: Object): EventAttachment => ({
      id: json['id'],
      name: json['name'],
      fileSize: json['file_size'],
    }),

    eventAttachments: (json: Object[]): EventAttachment[] =>
      json.map(parsers.parse.eventAttachment),

    question: (
      json: Object,
      appendJobMatchFields: boolean = false,
      eventType: string,
    ): Question => {
      const type: QuestionType = json['module_type'];

      switch (type) {
        case 'nps_survey_question':
        case 'rating_scale_survey_question': // $FlowIssue: flow gets confused by switches
          return {
            type: (type:
              | 'nps_survey_question'
              | 'rating_scale_survey_question'),
            id: json['id'],
            question: json['question'],
            required: json['required'],
            maxText: json['max_text'],
            minText: json['min_text'],
            minRating: json['min_rating'],
            maxRating: json['max_rating'],
            branchIds: json['branches']
              ? json['branches'].map((branch) => ({
                  id: branch.id,
                  hasChanges: false,
                }))
              : [],
            isNew: false,
            alertRating: json['alert_rating'],
            writeback: json['writeback'],
          };

        case 'multiple_choice_survey_question': {
          // NOTE (kyle): we need unique ids for each choice in order to avoid issues
          // when editing event conditions that rely on their existence.
          const choices = json['choices'].map((choice) => ({
            id: uniqueId('choice_'),
            value: choice,
          }));

          return ({
            type,
            id: json['id'],
            question: json['question'],
            required: json['required'],
            branchIds: json['branches']
              ? json['branches'].map((branch) => ({
                  id: branch.id,
                  hasChanges: false,
                }))
              : [],
            isNew: false,

            choices,
            // NOTE(elliot): The API stores `alert_choices` as a list of strings that
            // are a subset of the `choices` because the order doesn't matter. However,
            // on the front-end we store these as a list of ids to avoid bugs
            // when editing the alertChoices.
            alertChoices: json['alert_choices']
              .map((choice) => choices.find(({value}) => value === choice)?.id)
              .filter(Boolean),
            writeback: json['writeback'],
            allow_select_multiple: json['allow_select_multiple'],
            multi_select_limit: json['multi_select_limit'],
          }: MultipleChoiceQuestion);
        }

        case 'text_survey_question':
          return {
            type,
            id: json['id'],
            required: json['required'],
            question: json['question'],
            isNew: false,
            alertKeywords: json['alert_keywords'],
            writeback: json['writeback'],
          };
        case 'calendar_date_survey_question':
          return {
            type,
            id: json['id'],
            required: json['required'],
            question: json['question'],
            isNew: false,
            dateFormat: json['date_format'],
            alertDate: json['alert_date'],
            alertCondition: json['alert_condition'],
            writeback: json['writeback'],
          };
        case 'message_survey_module':
          return ({
            type,
            id: json['id'],
            text: json['text'],
            rte_json: json['rte_json'],
            rendered_html: json['rendered_html'],
            //TODO:(diwakersurya) change here to support job block
            include_job_block: json['job_match_rte_json'] ? true : false,
            job_match_rte_json: json['job_match_rte_json'],
            job_matches_count: json['job_matches_count'] || 5,
            job_matches_params: json['job_matches_params'] ?? {},
            send_alt_text: json['send_alt_text'] || false,
            alt_rte_json: json['alt_rte_json'] || null,
            isNew: false,
          }: RhetoricalQuestion);
        case 'beefree_module':
          return ({
            type,
            id: json['id'],
            beefree_html: json['beefree_html'],
            beefree_json: json['beefree_json'],
            isNew: false,
            required: true,
          }: BeeFreeModule);
        case 'sms_message_survey_module':
          const smsJobFields = {
            ...(appendJobMatchFields &&
              eventType === EventTypeEnum.SMS_Job && {
                includes_job_match_variables:
                  json['includes_job_match_variables'],
                send_alt_text: json['send_alt_text'],
                alt_text: json['alt_text'] || '',
                job_matches_count: json['job_matches_count'] || 5,
                job_matches_params: json['job_matches_params'] ?? {},
              }),
          };
          return ({
            type,
            id: json['id'],
            text: json['text'],
            includes_job_match_variables: false, //overridden to true if sms_job by smsJobFields
            ...smsJobFields,
            isNew: false,
          }: SMSRhetoricalQuestion);
        case 'attribute_list_module':
          return {
            type,
            id: json['id'],
            attributesToInclude: json['attributes_to_include'],
          };
        default:
          throw `TypeError: question.module_type ${type} is not valid.`;
      }
    },

    filteredSurveyQuestions: (
      accumulator: Question[],
      module: Question,
    ): Question[] => {
      if (
        !['message_survey_module', 'sms_message_survey_module'].includes(
          module.type,
        ) ||
        accumulator.length >= 2
      ) {
        accumulator.push(module);
      }
      return accumulator;
    },

    // For sms surveys, we allow message_survey_modules but don't allow the
    // initial sms_message_survey_module because it would be redundant and
    // include the survey link anyway
    filteredSMSSurveyQuestions: (
      accumulator: Question[],
      module: Question,
    ): Question[] => {
      if (
        !['sms_message_survey_module'].includes(module.type) ||
        accumulator.length >= 2
      ) {
        accumulator.push(module);
      }
      return accumulator;
    },

    branches: (json: Object): Branch[] => {
      const branches = [];
      json['modules'].map((module) => {
        if (module['branches']) {
          module['branches'].forEach((branch) => {
            branches.push(parsers.parse.branch(branch, json['id']));
          });
        }
      });
      return branches;
    },

    branch: (json: ApiBranch, parentEventId: string): Branch => {
      const branchProto = {
        id: json['id'],
        name: json['name'],
        branchType: json['branch_type'],
        parentEventId,
      };

      const conditions = {};
      if (json.condition.response.in) {
        conditions.responseIn = json.condition.response.in;
      } else if (json.condition.response.lte && json.condition.response.gte) {
        conditions.responseLte = json.condition.response.lte;
        conditions.responseGte = json.condition.response.gte;
        conditions.responseRange = true;
      } else if (json.condition.response.lte) {
        conditions.responseLte = json.condition.response.lte;
      } else if (json.condition.response.gte) {
        conditions.responseGte = json.condition.response.gte;
      } else {
        throw 'TypeError: Condition is not valid.';
      }

      const branch = {...branchProto, ...conditions};

      if (json.modules !== undefined) {
        branch.questions = json['modules'].map(parsers.parse.question);
      } else if (json.event !== undefined) {
        branch.event = parsers.parse.branchEvent(json['event']);
      }
      return branch;
    },

    branchEvent: (json: ApiBranchEvent): BranchEvent => ({
      id: json['id'],
      schedule:
        json['schedule'] !== undefined
          ? {
              sendDate: json['schedule']['send_date']
                ? json['schedule']['send_date']
                : undefined,
              fromDate: json['schedule']['delay_after_response']
                ? {
                    values: [
                      {
                        day: json['schedule']['delay_after_response']['day'],
                        time: json['schedule']['delay_after_response']['time'],
                      },
                    ],
                  }
                : undefined,
            }
          : undefined,
    }),

    surveyResponse: (json: Object): SurveyResponse => ({
      id: json['id'],
      type: json['event_response_type'],
      authorId: json['audience_member_id'],
      eventId: json['event_id'],
      responseModules: json['response_modules'].map(parsers.parse.answer),
    }),

    answer: (json: Object): Answer => {
      const type = json['response_module_type'];

      const answer: Answer = {
        type,
        id: json['id'],
        moduleId: json['module_id'],
        moduleValue: json['module_value'],
      };
      const moduleTypes = new Set([
        'rating_scale_survey_answer',
        'nps_survey_answer',
        'multiple_choice_survey_answer',
        'checkboxes_survey_answer',
        'text_survey_answer',
        'calendar_date_survey_answer',
        'sms_message_answer',
      ]);
      if (moduleTypes.has(type)) {
        return answer;
      } else {
        throw 'TypeError: answer.response_module_type is not valid.';
      }
    },

    sentEvent: (json: ApiSentEvent): SentEvent => ({
      agencyId: json['agency_id'],
      audienceMemberId: json['audience_member_id'],
      recipientEntityId: json['recipient_entity_id'],
      recipientEntityType: json['recipient_entity_type'],
      deliveryAddress: json['delivery_address'],
      brandingSettings: parsers.parse.brandingSettings(
        json['branding_settings'],
      ),
      eventId: json['event_id'],
      id: json['id'],
      workflowId: json['workflow_id'],
      expiredAt: json['expired_at'],
      responseHandshakeToken: json['response_handshake_token'],
      timeResponseHandshake: json['time_response_handshake'],
      entities_data: json['entities_data'],
      anchor_entity_type: json['anchor_entity_type'],
      isDebug: json['is_debug'],
    }),

    brandingSettings: (json: Object): BrandingSettings => camel(json),

    eventCalendar: (json: {[dateString: string]: Object}): EventCalendar => {
      const dates = {};
      for (const dateString in json) {
        dates[dateString] = camel(json[dateString], true).map(
          (apiCalEvent) => ({
            dateString,
            ...apiCalEvent,
          }),
        );
      }
      return dates;
    },

    eventCalendarAudiencePreviewPage: ({
      recipient_entities_due,
      recipient_entities_sent,
    }: ApiEventCalendarAudiencePreview): EventCalendarRecipient[] =>
      camel(recipient_entities_due.concat(recipient_entities_sent), true),

    audienceMemberResponses: (json: Object[]): AudienceMemberResponse[] =>
      json.map(parsers.parse.audienceMemberResponse),

    audienceMemberResponse: (json: Object): AudienceMemberResponse => ({
      id: json['id'],
      eventId: json['event_id'],
      agencyId: json['agency_id'],
      audienceMemberId: json['audience_member_id'],
      workflowId: json['workflow_id'],
      sentEventId: json['sent_event_id'],
      timeCreated: json['time_created'],
      timeUpdated: json['time_updated'],
      eventResponseType: json['event_response_type'],
      responseModules: json['response_modules'].map(parsers.parse.answer),
      client: json['client'],
      recipientResource: json['recipient_resource'],
      recipientResourceData: json['recipient_resource_data']
        ? parsers.parse.recipientResourceData(json['recipient_resource_data'])
        : null,
    }),

    recipientResourceData: (
      json: RecipientResourceData,
    ): RecipientResourceData => ({
      company: json['company'],
      email: json['email'],
    }),

    sentEventsWithExternals: (json: Object[]): SentEventWithExternals[] =>
      json.map(parsers.parse.sentEventWithExternals),

    sentEventWithExternals: (json: Object): SentEventWithExternals => ({
      id: json['id'],
      eventId: json['event_id'],
      agencyId: json['agency_id'],
      workflowId: json['workflow_id'],
      timeCreated: json['time_created'],
      audienceMemberId: json['audience_member_id'],
      recipientEntityId: json['recipient_entity_id'],
      recipientEntityType: json['recipient_entity_type'],
      deliveryAddress: json['delivery_address'],
      audienceMember: json['audience_member']
        ? camel(json['audience_member'], false)
        : '',
      audienceMemberPlacementId: json['audience_member_placement_id'],
      brandingSettings: parsers.parse.brandingSettings(
        json['branding_settings'],
      ),
      clicked: json['clicked'],
      timeClicked: json['time_clicked'],
      event: json['event'] ? parsers.parse.event(json['event']) : '',
      opened: json['opened'],
      timeOpened: json['time_opened'],
      responded: json['responded'],
      timeResponded: json['time_responded'],
    }),

    dueEvents: (json: ApiDueEvent[]): DueEvent[] =>
      json.map(parsers.parse.dueEvent),

    dueEvent: (json: ApiDueEvent): DueEvent => ({
      active: json['active'],
      agencyId: json['agency_id'],
      audienceMemberId: json['audience_member_id'],
      audienceMemberPlacementId: json['audience_member_placement_id'],
      dateEventDue: json['date_event_due'],
      eventId: json['event_id'],
      id: json['id'],
      eventSendTime: json['event_send_time'],
      timeEventDue: json['time_event_due'],
      type: json['type'],
      workflowId: json['workflow_id'],
      event: json['event'] ? parsers.parse.event(json['event']) : '',
    }),
  },
};

export default parsers;
