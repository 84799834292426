// @flow strict

import {useSelector} from 'react-redux';

// $FlowFixMe[nonstrict-import]
import {canUseMsgPhoneOrLatInbox} from 'src/selectors/chat';
// $FlowFixMe[nonstrict-import]
import usePrivilege from 'src/hooks/usePrivilege';
// $FlowFixMe[nonstrict-import]
import useAgencyConfig, {useSchedulerEnabled} from 'src/hooks/useAgencyConfig';
import {
  USE_JD_GENERATOR,
  USE_PRESCREENING_GENERATOR,
  USE_RESUME_GENERATOR,
} from 'src/types/roles';

import {
  // $FlowFixMe[nonstrict-import]
  MANAGE_CALENDAR,
} from 'src/action-creators/roles';


export const useExtensionProductsList = (): {productsList: Array<string>} => {
  // messaging
  const canUseMessaging = useSelector(canUseMsgPhoneOrLatInbox);

  // referrals
  const viewReferrals = usePrivilege('VIEW_REFERRALS_V2');
  const editReferrals = usePrivilege('EDIT_REFERRALS_V2');
  const adminReferrals = usePrivilege('ADMIN_REFERRALS_V2');
  const canViewReferrals = viewReferrals || editReferrals || adminReferrals;
  const referralV2 = useAgencyConfig('enableReferralV2');
  const viewJobsPrivilege = usePrivilege('VIEW_JOBS');
  const adminJobsPrivilege = usePrivilege('ADMIN_JOBS');

  // sense ai
  const hasJDGenerator = usePrivilege(USE_JD_GENERATOR);
  const hasPrescreeningGenerator = usePrivilege(USE_PRESCREENING_GENERATOR);
  const hasResumeGenerator = usePrivilege(USE_RESUME_GENERATOR);

  //meetings
  const schedulerEnabled = useSchedulerEnabled();
  const manageCalendar = usePrivilege(MANAGE_CALENDAR);

  const canUseRefferals = !!referralV2 && canViewReferrals;
  const canUseSenseAI =
    hasJDGenerator || hasPrescreeningGenerator || hasResumeGenerator;

  const productsList = [];
  if (canUseMessaging) {
    productsList.push('messaging');
    productsList.push('contacts');
  }
  if (canUseRefferals) {
    productsList.push('referral');
  }
  if (canUseSenseAI) {
    productsList.push('senseai');
  }

  if (schedulerEnabled && manageCalendar) {
    productsList.push('meetings');
  }

  if (viewJobsPrivilege || adminJobsPrivilege) {
    productsList.push('jobs');
  }

  return {productsList};
};
