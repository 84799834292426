// @flow strict

import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

/**
 * Returns a copy of the given object without any undefined or null values.
 */
export function omitNil<O: {...}>(object: O): $Shape<O> {
  return omitBy(object, isNil);
}

export function values<T>(ob: {[string]: T}): Array<T> {
  // $FlowIssue the result of this is never `mixed` in practice.
  return Object.values(ob);
}

export function keys<K, V>(ob: {[K]: V}): Array<K> {
  // $FlowIssue the result of this is never `mixed` in practice.
  return Object.keys(ob);
}

export function entries<K, V>(ob: {[K]: V}): Array<[K, V]> {
  // $FlowIssue[incompatible-return]
  return Object.entries(ob);
}

// TODO (kyle): we probably need to remove this. this function cannot be typed
// without inference and should not be exported.
/*
export function getProp<T: {...}>(object: T, propName: string) {
  return propName in object ? object[propName] : null;
}
*/
