// @flow strict-local

import type {
  Thread,
  MessageOriginType,
  MessageStatus,
  Message,
} from 'src/types/messages';
import type {Contact} from 'src/types/contacts';
import type {AudienceMember} from 'src/types/audience-member';
import type {ThreadList} from 'src/types/thread-lists';


export type BroadcastStatus = 'scheduled' | 'processed' | 'incomplete';

export const BROADCAST_STATUS_SCHEDULED: BroadcastStatus = 'scheduled';
export const BROADCAST_STATUS_PROCESSED: BroadcastStatus = 'processed';
export const BROADCAST_STATUS_INCOMPLETE: BroadcastStatus = 'incomplete';

export type ApiBroadcastNew = {
  phone_number_set_id: string,
  phone_numbers: string[],
  body: string,
  send_date: string,
  send_now: boolean,
};

export type ApiBroadcast = {
  id: string,
  //phone_number_set_id: string,
  //phone_numbers: string[],
  name: string,
  body: string,
  sendDate: string,
  status: BroadcastStatus,
  origin: MessageOriginType,
  oneOnOne: Boolean,
  audienceMembers?: AudienceMember[],
  contacts?: Contact[],
  threads?: Thread[],
  threadListId: string,
  placeholders?: {key: string, value: string},
};

export type BroadcastBranch = {
  sentMessageCount: number,
  body: string,
  parentBroadcastId: string,
};

export type Broadcast = {
  id: string,
  body: string,
  sendDate: string,
  status: BroadcastStatus,
  statuses: {[threadId: string]: MessageStatus},
  replies: Message[],
  origin: MessageOriginType,
  threadListId: string,
  oneOnOne: boolean,
  branches?: Array<BroadcastBranch>,
  phoneNumberSetId: string,
  agentId: string,
  failureRate: number,
  replyRate: number,
};

export type BroadcastNew = {
  id?: string,
  threadListId?: string,
  threadIds?: string[],
  phoneNumbers?: string[],
  body: string,
  sendDate?: string,
  sendNow?: boolean,
  sends?: BroadcastScheduleType,
  dripMessage?: string,
  templateId?: string,
  schedulerTemplateId?: string,
  placeHolders?: {[key: string]: string},
};

export type BroadcastScheduleType =
  | 'now'
  | '3 hours'
  | 'tomorrow'
  | 'next week'
  | 'specific date';

export type BroadcastForm = $Shape<{...Broadcast, ...BroadcastNew}>;

export type BroadcastAndMaybeList = {
  broadcast: Broadcast,
  threadList: ?ThreadList,
};

export type BroadcastAndList = {
  broadcast: Broadcast,
  threadList: ThreadList,
};
