// @flow strict
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {
  GenericError,
  ReferralLeaderBoard,
  ReferralAgentToConnect,
  ReferralAgent,
  ReferralAgencyInfo,
} from 'src/types/referral-v2';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api.js';

import {DEFAULT_ERROR} from 'src/action-creators/referral-v2/referral-jobs.js';


export const REFERRAL_LEADERBOARD_LOADING =
  'referral/referral-leaderboard-loading';
export const REFERRAL_LEADERBOARD = 'referral/referral-leaderboard';
export const REFERRAL_LEADERBOARD_ERROR = 'referral/referral-leaderboard-error';

export const REFERRAL_AGENT_LOADING = 'referral/referral-agent-loading';
export const REFERRAL_AGENT = 'referral/referral-agent';
export const REFERRAL_AGENT_ERROR = 'referral/referral-agent-error';

export const REFERRAL_AGENCY_LOADING = 'referral/referral-agency-loading';
export const REFERRAL_AGENCY = 'referral/referral-agency';
export const REFERRAL_AGENCY_ERROR = 'referral/referral-agency-error';

type ReferralLeaderboardLoadingAction = {
  type: typeof REFERRAL_LEADERBOARD_LOADING,
};

type ReferralLeaderboardAction = {
  type: typeof REFERRAL_LEADERBOARD,
  payload: ReferralLeaderBoard[],
};

type ReferralLeaderboardErrorAction = {
  type: typeof REFERRAL_LEADERBOARD_ERROR,
  payload: ?GenericError,
};

type ReferralAgentLoadingAction = {
  type: typeof REFERRAL_AGENT_LOADING,
};

type ReferralAgentAction = {
  type: typeof REFERRAL_AGENT,
  payload: ?ReferralAgent,
};

type ReferralAgentErrorAction = {
  type: typeof REFERRAL_AGENT_ERROR,
  payload: ?GenericError,
};

type ReferralAgencyLoadingAction = {
  type: typeof REFERRAL_AGENCY_LOADING,
};

type ReferralAgencyAction = {
  type: typeof REFERRAL_AGENCY,
  payload: ?ReferralAgencyInfo,
};

type ReferralAgencyErrorAction = {
  type: typeof REFERRAL_AGENCY_ERROR,
  payload: ?GenericError,
};

export type ReferralDashboardActions =
  | ReferralLeaderboardLoadingAction
  | ReferralLeaderboardAction
  | ReferralLeaderboardErrorAction
  | ReferralAgentLoadingAction
  | ReferralAgentAction
  | ReferralAgentErrorAction
  | ReferralAgencyLoadingAction
  | ReferralAgencyAction
  | ReferralAgencyErrorAction;

export const getReferralLeaderboard = (
  payload: ReferralLeaderBoard[],
): ReferralLeaderboardAction => ({
  type: REFERRAL_LEADERBOARD,
  payload,
});

export const getReferralLeaderboardLoading =
  (): ReferralLeaderboardLoadingAction => ({
    type: REFERRAL_LEADERBOARD_LOADING,
  });

export const getReferralLeaderboardError = (
  payload: ?GenericError,
): ReferralLeaderboardErrorAction => ({
  type: REFERRAL_LEADERBOARD_ERROR,
  payload,
});

export const getReferralAgents = (
  payload: ReferralAgent,
): ReferralAgentAction => ({
  type: REFERRAL_AGENT,
  payload,
});

export const getReferralAgentsLoading = (): ReferralAgentLoadingAction => ({
  type: REFERRAL_AGENT_LOADING,
});

export const getReferralAgentsError = (
  payload: ?GenericError,
): ReferralAgentErrorAction => ({
  type: REFERRAL_AGENT_ERROR,
  payload,
});

export const getReferralAgency = (
  payload: ReferralAgencyInfo,
): ReferralAgencyAction => ({
  type: REFERRAL_AGENCY,
  payload,
});

export const getReferralAgencyLoading = (): ReferralAgencyLoadingAction => ({
  type: REFERRAL_AGENCY_LOADING,
});

export const getReferralAgencyError = (
  payload: ?GenericError,
): ReferralAgencyErrorAction => ({
  type: REFERRAL_AGENCY_ERROR,
  payload,
});

export const fetchReferralLeaderBoard: (query: {
  start_date: string,
  end_date: string,
}) => ThunkAction<void> = flow(
  key(() => `referral/stats/leaderboard}`),
  fetching(),
)((query) => (dispatch: Dispatch) => {
  dispatch(getReferralLeaderboardLoading());

  return dispatch(
    reduxApi.get(
      `referral/stats/leaderboard?start_date=${query.start_date}&end_date=${query.end_date}`,
    ),
  ).then(
    (response: ReferralAgentToConnect[]) => {
      // $FlowIssue object values
      dispatch(getReferralLeaderboard(response));
    },
    (error) => {
      dispatch(
        getReferralLeaderboardError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const fetchReferralAgents: (query: {
  start_date: string,
  end_date: string,
}) => ThunkAction<void> = flow(
  key(() => `referral/stats/agent}`),
  fetching(),
)((query: {start_date: string, end_date: string}) => (dispatch: Dispatch) => {
  dispatch(getReferralAgentsLoading());

  return dispatch(
    reduxApi.get(
      `referral/stats/agent?start_date=${query.start_date}&end_date=${query.end_date}`,
    ),
  ).then(
    (response: ReferralAgent) => {
      dispatch(getReferralAgents(response));
    },
    (error) => {
      dispatch(
        getReferralAgentsError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const fetchReferralAgency: (query: {
  start_date: string,
  end_date: string,
}) => ThunkAction<void> = flow(
  key(() => `referral/stats/agency}`),
  fetching(),
)((query: {start_date: string, end_date: string}) => (dispatch: Dispatch) => {
  dispatch(getReferralAgencyLoading());

  return dispatch(
    reduxApi.get(
      `referral/stats/agency?start_date=${query.start_date}&end_date=${query.end_date}`,
    ),
  ).then(
    (response: ReferralAgencyInfo) => {
      dispatch(getReferralAgency(response));
    },
    (error) => {
      dispatch(
        getReferralAgencyError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});
