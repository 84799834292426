import 'console-polyfill';
import 'core-js/stable';
import 'core-js/proposals/iterator-helpers';
import 'regenerator-runtime/runtime';

import b64 from 'src/polyfills/base64';
import {polyfill as rafPolyfill} from 'raf';

// fix likely broken classlist issue in ie9 w/ modal
import 'classlist-polyfill';

// fix busted atob in ie
if (!window.atob) {
  window.atob = b64.decode;
}
if (!window.bota) {
  window.btoa = b64.encode;
}

// fix missing requestAnimationFrame polyfill
if (!window.requestAnimationFrame) {
  rafPolyfill();
}
