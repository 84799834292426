// @flow

import isPlainObject from 'lodash/isPlainObject';
import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';
import forEach from 'lodash/forEach';

/**
 * DEPRECATED
 * These are legacy functions that were used to transform Python-style, snake json
 * to JavaScript-style, camel json. Though they are all over our code base,
 * we should make every effort to avoid using them as they make things like
 * static typing and tracking data flow difficult.
 *
 * Going forward we will allow snake case json as long as it comes directly from
 * the Python API.
 */

/**
 * Camel cases all keys of an object/array hierarchy
 * if deep is false, only camelCases the top-level keys
 *
 * see test in src/api-parsers/__tests__/audience-member-parser-test.js
 * for usage of deep, ignoreKeys
 */
export function camel(
  thing: any,
  deep: boolean = true,
  ignoreKeys: string[] = [],
): any {
  if (isPlainObject(thing)) {
    const ob = {};
    forEach(thing, (val, key) => {
      if (deep && !ignoreKeys.includes(key)) {
        ob[camelCase(key)] = camel(val, deep, ignoreKeys);
      } else {
        ob[camelCase(key)] = val;
      }
    });
    return ob;
  } else if (Array.isArray(thing)) {
    return thing.map((val) => camel(val, deep, ignoreKeys));
  } else {
    return thing;
  }
}

/**
 * Snake cases all keys of an object/array hierarchy
 */
export function snake(thing: any): any {
  if (isPlainObject(thing)) {
    const ob = {};
    forEach(thing, (val, key) => {
      ob[snakeCase(key)] = snake(val);
    });
    return ob;
  } else if (Array.isArray(thing)) {
    return thing.map(snake);
  } else {
    return thing;
  }
}
