// @flow

// $FlowFixMe[untyped-type-import]
import type {Workflow} from 'src/api-parsers';
import type {RouteLocationTo} from 'src/types/router';

import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'src/rerouter';

import {classify} from 'src/utils';
import * as urlFor from 'src/utils/url-for';
import {values} from 'src/utils/object';
import {getAccountPrivileges} from 'src/selectors/accounts';

import NewWorkflowIcon from 'src/images/chat-new-message.svg?noAttrs';

import TruncatedText from 'src/components/lib/truncated-text';
import {FormattedPlural} from 'src/components/lib/intl';

import SearchIcon from 'src/images/preview-icon.svg';
import ClearIcon from 'src/images/close-icon.svg';
import {useAudienceFilterSeparation} from 'src/hooks/product-flags';

import css from 'src/components/navigation/side.css';


type BaseProps = {
  // $FlowFixMe[value-as-type] [v1.32.0]
  workflows: {[id: string]: Workflow},
  canEdit?: mixed,
  isAnalytics?: mixed,
};

type Props = {
  ...BaseProps,
  resolveLocation: (workflowId: string) => RouteLocationTo,
};

const WorkflowDetail = ({
  workflows,
  resolveLocation,
  canEdit,
  isAnalytics,
}: Props) => {
  const [queryTerm, setQueryTerm] = useState('');
  const privileges = useSelector(getAccountPrivileges);
  const isJourneyV2 = useAudienceFilterSeparation();
  const journeyPageRoute = isJourneyV2
    ? '/journeys/goal-selection'
    : '/journey/new';
  const canCreateWorkflow = privileges.includes('EDIT_WORKFLOWS');

  const eventWorkflows = values(workflows).filter(({dbCleanup}) => !dbCleanup);

  const filteredWorkflows = eventWorkflows
    .sort((a, b) => a.name.localeCompare(b.name, 'en', {sensitivity: 'base'}))
    // $FlowFixMe[value-as-type] [v1.32.0]
    .filter((workflow: Workflow) =>
      workflow.name.toLowerCase().includes(queryTerm.toLowerCase()),
    );

  const hasSearchTerm = filteredWorkflows.length !== eventWorkflows.length;

  return (
    <div className={css.detail}>
      <section className={css.detailSection}>
        <div className={css.detailTitleWorkflows}>
          <div>Journeys</div>
          {!isAnalytics && canCreateWorkflow && (
            <Link className={css.detailNewWorkflowButton} to={journeyPageRoute}>
              <NewWorkflowIcon className={css.newWorkflowIcon} />
            </Link>
          )}
        </div>
        <label className={css.workflowSearchLabel}>
          <SearchIcon className={css.searchIcon} />
          <input
            className={css.workflowSearch}
            value={queryTerm}
            onChange={(event) => setQueryTerm(event.currentTarget.value)}
            placeholder="Search..."
          />
          {hasSearchTerm && (
            <ClearIcon
              className={css.clearIcon}
              onClick={() => setQueryTerm('')}
            />
          )}
        </label>
        {hasSearchTerm && (
          <div
            className={classify(
              css.detailTitleWorkflows,
              css.workflowSearchResults,
            )}
          >
            <span>
              {filteredWorkflows.length}{' '}
              <FormattedPlural
                value={filteredWorkflows.length}
                one={'result'}
                other={'Results'}
              />{' '}
              found:
            </span>
          </div>
        )}

        <div className={css.detailWorkflows}>
          {
            // $FlowFixMe[value-as-type] [v1.32.0]
            filteredWorkflows.map((workflow: Workflow) => (
              <TruncatedText
                text={workflow.name}
                limit={20}
                component={Link}
                className={classify(css.detailWorkflow, {
                  [css.active]: workflow.active,
                })}
                activeClassName={css.on}
                to={resolveLocation(workflow.id)}
                key={workflow.id}
              />
            ))
          }
          {Boolean(canEdit) && !hasSearchTerm && (
            <Link className={css.detailWorkflowNew} to={journeyPageRoute}>
              Add New Journey
            </Link>
          )}
        </div>
      </section>
    </div>
  );
};

export const AnalyticsWorkflowList: React$AbstractComponent<BaseProps, mixed> =
  React.memo<BaseProps>((props) => (
    <WorkflowDetail
      {...props}
      resolveLocation={(workflowId) => (location) => ({
        ...location,
        pathname: urlFor.analyticsWorkflow(workflowId),
      })}
    />
  ));

export const DefaultWorkflowList: React$AbstractComponent<BaseProps, mixed> =
  React.memo<BaseProps>((props) => (
    <WorkflowDetail
      {...props}
      resolveLocation={(workflowId) => urlFor.workflow(workflowId)}
    />
  ));
