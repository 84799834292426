// @flow strict-local

import * as React from 'react';

import logger from 'src/utils/logger';
import {useEnvironmentContext} from 'src/hooks/useEnvironmentContext';
//$FlowFixMe[untyped-import]
import {COPY_TEXT} from 'src/chat-extension/messages.js';


type CopiedState = 'copied' | 'no-interaction' | 'failure';
type CopyOpts = {
  richData?: {[mimetype: string]: string},
  timeout?: number,
  deps?: mixed[],
};

/**
 * useClipboard is a hook that consumes data to add to the user's clipboard
 * it returns a handler that you can attach to some button, when called it will
 * attempt to add the data to the user's clipboard.
 *
 * because the clipboard apis require permissions, the handler may fail. the current
 * state is returned as the CopiedState. You can optionally pass in a timeout to
 * revert the copied state to no-interaction after some period of time.
 *
 * This hook 'resets' top copy value when plaintext or opts are changed so you can use
 * a state value to update the contents that get written to the clipboard.
 *
 * Alternatively, you may pass in a function in for plainext that returns a string, this
 * function will be called synchronously only when writing to the clipboard, which may be
 * more ergonomic than using state and other misdirection to update the clipboard value
 *
 * @param  {string|function} plaintext data or a sync function that returns plain text
 * @param  {object} an object whose keys are mimetypes and whose values are data
 * @return {[CopiedState, handler: () => mixed]}
 */
export function useClipboard(
  plaintext: string | (() => string),
  opts?: CopyOpts = Object.freeze({}),
): [CopiedState, () => mixed, ?Error] {
  const [hasCopied, setCopied] = React.useState<CopiedState>('no-interaction');
  const [clipboardError, setClipboardError] = React.useState<?Error>(null);

  const copyStateTimeoutRef = React.useRef(null);
  const componentMountedRef = React.useRef(true);
  const {isExtension} = useEnvironmentContext();

  const clipItemFromObject = (obj) => {
    const clipData = {};
    Object.entries(obj).forEach(([type, value]) => {
      if (value) {
        clipData[type] = new Blob([value], {type});
      }
    });
    return new window.ClipboardItem(clipData);
  };

  const handleCopy = React.useCallback(async () => {
    // Due to security restrictions, clipboard access is not directly available within Chrome extensions.
    // so here using message passing between the extension and the webpage within the iframe.
    if (isExtension) {
      window.parent?.parent?.postMessage(
        {type: COPY_TEXT, text: plaintext},
        '*',
      );
      setCopied('copied');
      return;
    }
    if (navigator.clipboard) {
      if (opts.richData) {
        try {
          const clipItem = clipItemFromObject(opts.richData);

          // $FlowIssue[incompatible-call] i promise clipdata is defined correctly
          await navigator.clipboard.write([clipItem]);
        } catch (clipItemError) {
          logger.warn(
            'Problem creating rich clipboard item, falling back to plaintext',
            clipItemError,
          );
          // try to at least copy plaintext data
          try {
            await navigator.clipboard.writeText(
              // $FlowIssue[incompatible-use] - opts.richData is an object and not undefined
              opts.richData['text/plain'] ??
                (typeof plaintext === 'function' ? plaintext() : plaintext),
            );
            componentMountedRef.current && setCopied('copied');
          } catch (clipWriteError) {
            logger.error('error writing to clipboard', clipWriteError);
            componentMountedRef.current && setCopied('failure');
            componentMountedRef.current && setClipboardError(clipWriteError);
          }
        }
      } else {
        try {
          await navigator.clipboard.writeText(
            typeof plaintext === 'function' ? plaintext() : plaintext,
          );
          componentMountedRef.current && setCopied('copied');
        } catch (err) {
          logger.error('clipboard write failed (maybe no permissions)', err);
          componentMountedRef.current && setCopied('failure');
          componentMountedRef.current && setClipboardError(err);
        }
      }
    }

    if (opts.timeout != null) {
      copyStateTimeoutRef.current = setTimeout(() => {
        setCopied('no-interaction');
      }, opts.timeout);
    }
  }, opts.deps || [opts.richData, plaintext]);

  // cleanup revert if the component unmounts
  React.useEffect(
    () => () => {
      componentMountedRef.current = false;
      if (
        opts.timeout !== null &&
        copyStateTimeoutRef.current &&
        hasCopied !== 'no-interaction'
      ) {
        clearTimeout(copyStateTimeoutRef.current);
        copyStateTimeoutRef.current = null;
      }
    },
    [hasCopied],
  );

  return [hasCopied, handleCopy, clipboardError];
}
