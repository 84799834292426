// @flow strict

import * as React from 'react';

import classify from 'src/utils/classify';

import {Clickable} from 'src/designSystem2021Components/text-v2.jsx';

import css from './radio.css';

/**
 * Note:
 * Do not wrap Radio in a label. For simplicity's sake, Radio uses an
 * internal label tag to handle click delegation to the hidden input, and
 * nesting labels is a bad idea.
 */

type Props = {
  classNames?: {
    container?: string,
    radio?: string,
  },
  children?: React.Node,
  checked?: boolean,
  value?: mixed,
  onChange?: (SyntheticInputEvent<HTMLInputElement>) => mixed,
  disabled?: boolean,
};

export const Radio = ({
  classNames,
  children,
  disabled,
  checked,
  value,
  ...props
}: Props): React.Node => (
  <label
    className={classify(classNames?.container || css.container, {
      [css.radioContainer]: checked && !disabled,
      [css.uncheckedContainer]: !checked && !disabled,
      [css.disabledContainer]: disabled,
    })}
  >
    <input
      type="radio"
      checked={checked}
      value={value || ''}
      disabled={disabled}
      {...props}
    />
    <div
      className={classify(classNames?.radio || css.radio, {
        [css.disabled]: disabled,
      })}
    />
    {React.Children.count(children) > 0 && (
      <Clickable className={css.radioLabel}>{children}</Clickable>
    )}
  </label>
);

export default Radio;
