// @flow

import * as React from 'react';

import FileButton from 'src/components/lib/file-button';
import {StringErrorBox} from 'src/components/lib/error-box';

import css from './file-page.css';


const CsvModal = ({
  errors,
  onFileChange,
  onCancel,
  uploadLimit,
}: {
  errors?: string[],
  onFileChange: (event: SyntheticEvent<HTMLInputElement>) => any,
  onCancel: (event: SyntheticEvent<HTMLElement>) => any,
  uploadLimit: number,
}): React.Element<'div'> => (
  <div className={css.root}>
    {errors && <StringErrorBox errors={errors} />}

    <div className={css.columns}>
      <div className={css.rules}>
        <div>Your CSV must</div>
        <ul>
          <li>
            Contain a <strong>phone</strong> column
          </li>
          <li>
            Contain a <strong>name column(s)</strong>
          </li>
          <li>
            Be <strong>{uploadLimit}</strong> contacts or fewer
          </li>
          <li>Have a header row</li>
        </ul>
      </div>

      <div className={css.example}>
        <div className={css.exampleHead}>
          <span>Example format:</span>
          <a
            href="https://s3-us-west-2.amazonaws.com/madeby.com/assets/contacts-example.csv"
            target="_blank"
          >
            Download Template
          </a>
        </div>
        <img
          src="/images/chat/csv-template@1x.png"
          srcSet="/images/chat/csv-template@2x.png 2x"
        />
      </div>
    </div>

    <div className={css.actions}>
      <div className={css.hide}>Cancel</div>
      <FileButton onChange={onFileChange} accept=".csv">
        Select File to Upload
      </FileButton>
      <button className={css.cancel} onClick={onCancel}>
        Cancel
      </button>
    </div>
  </div>
);
export default CsvModal;
