// @flow strict-local

import type {Recipient} from 'src/types/contacts';

import _get from 'lodash/get';

import {getPrimaryPhone} from 'src/utils/contacts';


export function getRecipientPhone(recipient: Recipient): string {
  let recipientPhone;
  if (typeof recipient === 'string') {
    if (recipient.startsWith('0') && recipient.length > 10) {
      recipientPhone = recipient.slice(1);
    } else {
      recipientPhone = recipient;
    }
  } else if (recipient.atsContact) {
    recipientPhone = recipient.atsContact.phoneNumber;
  } else if (recipient.contact) {
    recipientPhone = getPrimaryPhone(recipient.contact);
  } else if (recipient.audienceMember) {
    recipientPhone = (recipient.audienceMember.phoneNumber: string);
  }
  // $FlowIssue it is definitely a string
  return recipientPhone;
}

export const getDraftKey = (props: {
  params: {phone?: string, scheduledMessageId?: string},
  ...
}): string =>
  `${_get(props, 'params.phone', 'new')}${_get(
    props,
    'params.scheduledMessageId',
    '',
  )}`;
