// @flow strict

import type {
  GraphDetails,
  GraphStatus,
  Id,
  ConversationNodeType,
} from 'src/types/chatbot';

import React, {useReducer, useMemo} from 'react';
import invariant from 'invariant';

import {useChatbotLiveFlowEditEnabled} from 'src/hooks/product-flags';

import {keyBy} from 'src/utils/map';
import {useAdvancedApi, useApi} from 'src/hooks/useApi';


export function useFlows({
  showArchived,
}: {
  showArchived?: boolean,
} = {}): [{loaded: boolean, flows: Array<GraphDetails>}, (Action) => void] {
  const [{loaded, flows}, dispatch] = useReducer(reducer, initialState);

  useAdvancedApi<GraphDetails[]>('chatbot/flows/summary', {
    handleResponse: (flows) => {
      dispatch({type: 'load', payload: flows});
    },
  });

  useAdvancedApi<GraphDetails[]>('chatbot/flows', {
    handleResponse: (flows) => {
      dispatch({type: 'load', payload: flows});
    },
  });

  const flowsList = useMemo(() => {
    let flowsList = [...flows.values()];

    if (!showArchived) {
      flowsList = flowsList.filter((flow) => flow.status !== 'archived');
    }

    return flowsList;
  }, [flows, showArchived]);

  return [{loaded, flows: flowsList}, dispatch];
}

export function useFlowsCount(): ?{
  [key: string]: number,
  ...
} {
  const [countData, setCountData] = React.useState(null);

  useAdvancedApi<{
    [key: string]: number,
    ...
  }>('chatbot/flows/counts', {
    handleResponse: (counts) => {
      setCountData(counts);
    },
  });

  return countData;
}

export type MutableFieldDict = {
  config_type_to_mutable_elements: {[key: string]: string[]},
  node_type_to_mutable_elements: {[key: ConversationNodeType]: string[]},
};
export function useMutableFields(isFlowReadOnly: boolean): MutableFieldDict {
  const shouldCheck = useChatbotLiveFlowEditEnabled() && isFlowReadOnly;
  const mutableFieldResource = useApi<MutableFieldDict>(
    shouldCheck ? 'chatbot/mutable-flow-elements' : null,
    {cacheTimeout: 86_400_000},
  );
  return (
    mutableFieldResource.result ?? {
      config_type_to_mutable_elements: {},
      node_type_to_mutable_elements: {},
    }
  );
}

/**
 * getMutableStatus
 * @param  {[type]} defaultReadOnly: boolean        is the flow currently readonly?
 * @param  {[ConversationNodeType]} nodeType        the node's type (question, job match, etc)
 * @return {[type]}            returns two functions, is fieldReadOnly(fieldName) and isMutableField(fieldname)
 *                             isFieldReadOnly may be passed directly to disabled={} props and will default to
 *                             defaultreadonly when the release flag is inactive.
 *                             isMutableField does not use the status of readOnly, it merely indicates if this
 *                             field is 'special' in that it may be modified.
 *                             the mutableFields return value returns the value of the originating api call.
 */
export const useMutableFieldStatus = (
  defaultReadOnly: boolean,
  nodeType: ConversationNodeType,
): ({
  isFieldReadOnly: (fieldName: string) => boolean,
  isMutableField: (fieldName: string) => boolean,
  mutableFields: MutableFieldDict,
  canFlowUpdate: boolean,
}) => {
  const mutableFlowElements = useMutableFields(defaultReadOnly);
  const canFlowUpdate = useChatbotLiveFlowEditEnabled() && defaultReadOnly;

  return {
    isFieldReadOnly: (fieldName: string) => {
      const mutableNodeTypeFields =
        mutableFlowElements.node_type_to_mutable_elements[nodeType] ?? [];
      if (mutableNodeTypeFields.includes(fieldName)) {
        return false;
      }
      return defaultReadOnly;
    },
    isMutableField: (fieldName: string) => {
      const mutableNodeTypeFields =
        mutableFlowElements.node_type_to_mutable_elements[nodeType] ?? [];
      return defaultReadOnly && mutableNodeTypeFields.includes(fieldName);
    },
    mutableFields: mutableFlowElements,
    canFlowUpdate,
  };
};

type State = {
  loaded: boolean,
  flows: Map<Id, GraphDetails>,
  priorStatuses: Map<Id, GraphStatus>,
};

const initialState: State = {
  loaded: false,
  flows: new Map(),
  priorStatuses: new Map(),
};

type Action =
  | {
      type: 'load',
      payload: Array<GraphDetails>,
    }
  | {
      type: 'beginArchive',
      payload: Id,
    }
  | {
      type: 'rollbackArchive',
      payload: Id,
    }
  | {
      type: 'completeArchive',
      payload: Id,
    };

export type FlowsDispatch = (Action) => void;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'load': {
      return {
        loaded: true,
        flows: keyBy(
          action.payload.map((flow) => ({
            ...flow,
            time_updated: flow.time_updated || flow.time_created,
          })),
          'id',
        ),
        priorStatuses: new Map(),
      };
    }

    case 'beginArchive': {
      let flow = state.flows.get(action.payload);
      invariant(flow, 'Attempted to archive a non-existent flow.');
      const flows = new Map(state.flows);

      // support rolling back failed archive
      const priorStatuses = new Map(state.priorStatuses);
      priorStatuses.set(flow.id, flow.status);

      flow = {
        ...flow,
        status: 'archived',
      };
      flows.set(flow.id, flow);

      return {
        ...state,
        flows,
        priorStatuses,
      };
    }

    case 'rollbackArchive': {
      let flow = state.flows.get(action.payload);
      invariant(flow, 'Attempted to archive a non-existent flow.');
      const flows = new Map(state.flows);
      const priorStatuses = new Map(state.priorStatuses);
      const priorStatus = priorStatuses.get(flow.id);
      invariant(priorStatus, 'Attempting to unarchive a non-archived flow');

      flow = {
        ...flow,
        status: priorStatus,
      };

      flows.set(flow.id, flow);
      priorStatuses.delete(flow.id);

      return {
        ...state,
        flows,
        priorStatuses,
      };
    }

    case 'completeArchive': {
      const flow = state.flows.get(action.payload);
      invariant(flow, 'Attempted to archive a non-existent flow.');

      const priorStatuses = new Map(state.priorStatuses);
      const priorStatus = priorStatuses.get(flow.id);
      invariant(
        priorStatus,
        'Attempting to finish an archive for a flow that wasn\'t archived by this reducer',
      );

      // clear in flight status
      priorStatuses.delete(flow.id);

      return {
        ...state,
        priorStatuses,
      };
    }

    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
}
