// @flow strict-local
import type {
  CountApiRoi,
  RoiPlacementActivity,
  RoiRedeploymentGrid,
  RoiSummary,
} from 'src/types/roi';
import type {TimeFrame} from 'src/utils/date-range';

import * as React from 'react';
import {Link, useHistory, useLocation} from 'src/rerouter';
import {useDispatch, useSelector} from 'react-redux';
import uniqueId from 'lodash/uniqueId';
import floor from 'lodash/floor';

import classify from 'src/utils/classify';
import {formatFractionNumber, formatPercentage} from 'src/utils/number';
import {checkRoiV3ApiEnabled} from 'src/utils/analytics-api-migration';

import {startDataExport} from 'src/action-creators/data-export-report';

import {selectNpsData} from 'src/selectors/agency-overview';
import useDisplayConfig from 'src/hooks/useDisplayConfig';

import {getPlacementDurationMean} from 'src/components/analytics/performance/charts/placement-durations.jsx';
import {getAverageRedeploymentDays} from 'src/components/analytics/figures/redeployment-grid.jsx';
import {useContextTip} from 'src/components/lib/mouse-tip/mouse-tip.jsx';

import NPSZeroState from 'src/images/nps-zero-state-foreground.svg';
import Info from 'src/images/icons/info.svg';
import LinkIcon from 'src/images/icons/link-icon.svg';
import {useAudienceFilterSeparation} from 'src/hooks/product-flags';

import css from './roi.css';

import useI18n from 'src/hooks/useI18n';


function shrinkHours(hours: number): {dayValue: number, dayUnit: string} {
  const dayValue = hours < 2_080 ? hours / 40 : hours / 2_080;
  // 2_080 -> working hours in a year (40 x 52), 40 -> hours in a week
  return {
    dayValue: floor(dayValue, 1),
    dayUnit: hours < 2_080 ? 'weeks' : 'years',
  };
}

const ZeroStateBar = () => (
  <div className={css.zeroStateBarOuter}>
    <div className={css.zeroStateBarInner}></div>
    <div className={css.zeroStateBarInner2}></div>
  </div>
);

const LabeledFigures = ({
  figures,
}: {
  figures: {label: string | React.Node, value: ?number}[],
}) => (
  <div>
    {figures.map(({label, value}) => (
      <div className={css.figureContainer}>
        <div className={css.figureLabel}>{label}</div>
        <div className={css.figureValue}>
          {formatFractionNumber(Number(value))}
        </div>
      </div>
    ))}
  </div>
);

const labelTipId = uniqueId('tooltip_');

const ContentWithGlossary = ({children}: {children: React.Node}) => (
  <p className={css.tipContent}>
    <span>{children}</span>
    <span>{' See the '}</span>
    <span>
      <a
        href="https://support.sensehq.co/hc/en-us/sections/360011146471-ROI"
        className={css.glossaryLink}
        target="_blank"
      >
        glossary
      </a>
    </span>
  </p>
);

const ToolTip = ({content}: {content: string | React.Node}) => {
  const contentWithGlossary = React.useMemo(
    () => <ContentWithGlossary>{content}</ContentWithGlossary>,
    [],
  );

  const [componentRef, handleContextMenu, handleClose, isActive] =
    useContextTip({
      id: labelTipId,
      content: contentWithGlossary,
      fixedTo: 'bottom',
    });

  return (
    <div
      ref={componentRef}
      onContextMenu={handleContextMenu}
      onClick={isActive ? handleClose : handleContextMenu}
    >
      <Info className={css.infoIcon} />
    </div>
  );
};

export const PlacementRoi = ({
  roi,
  timeFrame,
}: {
  roi: ?CountApiRoi,
  timeFrame: TimeFrame,
}): React.Node => {
  const {candidates_reached, influenced_placements, fte_hours, time_saved} =
    roi || {};
  const {labelI18nInstance} = useI18n();
  const zeroState = !roi || (!candidates_reached && !influenced_placements);
  const hasROIData = candidates_reached > 0 && influenced_placements > 0;
  const hidePlacementData = candidates_reached > 0 && !influenced_placements;

  const dispatch = useDispatch();

  const location = useLocation();
  const isInAnalytics = location.pathname.includes('analytics');

  const fteTimeSaved = checkRoiV3ApiEnabled() ? time_saved : fte_hours;

  const {dayValue, dayUnit} = (roi && shrinkHours(Number(fteTimeSaved))) || {};

  const figures = roi
    ? [
        {
          label:
            labelI18nInstance.t('sense_candidates', 'Candidates') + ' reached',
          value: Number(candidates_reached) || 0,
        },
        {
          label: (
            <div>
              FTE hours <br />
              saved
            </div>
          ),
          value: Number(fteTimeSaved) || 0,
        },
        {
          label: (
            <div>
              {`FTE ${dayUnit.toUpperCase()}`} <br />
              saved
            </div>
          ),
          value: Number(dayValue) || 0,
        },
      ]
    : [];

  const handleExportPlacements = () => {
    dispatch(
      startDataExport(
        `data-exports/${
          checkRoiV3ApiEnabled() ? 'roi-highlights' : 'roi-report'
        }`,
        {
          date_range: timeFrame,
        },
      ),
    );
  };

  return (
    <div className={classify(css.section, css.roiSection)}>
      <div className={css.headerRow}>
        <h3 className={css.sectionHeader}>
          {hasROIData &&
            'Sense influenced ' +
              labelI18nInstance
                .t('sense_placements', 'placements')
                .toLowerCase()}
          {zeroState &&
            'Sense influenced ' +
              labelI18nInstance
                .t('sense_placement', 'placement')
                .toLowerCase() +
              ' funnel'}
          {hidePlacementData && 'Sense influenced return on investment'}
        </h3>
        {hasROIData && (
          <ToolTip
            content={
              <div>
                <b>
                  {labelI18nInstance.t('sense_candidates', 'Candidates')}{' '}
                  reached{' '}
                </b>
                are unique{' '}
                {labelI18nInstance
                  .t('sense_candidates', 'candidates')
                  .toLowerCase()}{' '}
                that you messaged via Sense touchpoints.{' '}
                <b>{labelI18nInstance.t('sense_placements', 'Placements')} </b>{' '}
                are counted as influenced by Sense if they occurred within 90
                days of messaging those{' '}
                {labelI18nInstance
                  .t('sense_candidates', 'candidates')
                  .toLowerCase()}
                .
              </div>
            }
          />
        )}
        {hidePlacementData && (
          <ToolTip
            content={
              labelI18nInstance.t('sense_candidates', 'Candidates') +
              ' reached are unique ' +
              labelI18nInstance
                .t('sense_candidates', 'candidates')
                .toLowerCase() +
              ' that you messaged via Sense touchpoints. Each of these automated customized messages saves a human recruiters a few minutes of time, and those minutes add up weeks and then months. That saves expenses and/or frees recruiters to do more important tasks.'
            }
          />
        )}
      </div>
      {hasROIData && (
        <>
          <div className={css.placementsRoiContainer}>
            <div
              className={classify(
                css.columnSection,
                css.candidateFigureContainer,
              )}
            >
              <div
                className={classify(css.figureLabel, css.candidateFigureColor)}
              >
                {labelI18nInstance
                  .t('sense_candidates', 'candidates')
                  .toLowerCase()}
                <br />
                reached
              </div>
              <div
                className={classify(css.figureValue, css.candidateFigureColor)}
              >
                {formatFractionNumber(Number(candidates_reached))}
              </div>
            </div>

            <div
              className={classify(
                css.columnSection,
                css.placementsFigureContainer,
              )}
            >
              <div className={css.placementsFigureTop}>
                <div className={css.placementsFigureTopInner} />
              </div>
              <div
                className={classify(
                  css.columnSection,
                  css.placementsFigureInnerContainer,
                )}
              >
                <div
                  className={classify(
                    css.figureLabel,
                    css.placementFigureColor,
                  )}
                >
                  {labelI18nInstance
                    .t('sense_placements', 'placements')
                    .toLowerCase()}
                </div>
                <div
                  className={classify(
                    css.figureValue,
                    css.placementFigureColor,
                  )}
                >
                  {formatFractionNumber(Number(influenced_placements))}
                </div>
              </div>
            </div>
          </div>
          {isInAnalytics ? (
            <div onClick={handleExportPlacements} className={css.link}>
              <span>Export a report</span>
            </div>
          ) : (
            <Link to={'/analytics/performance'} className={css.link}>
              <span>View & export full report</span>
              <LinkIcon className={css.linkIcon} />
            </Link>
          )}
        </>
      )}
      {/* // $FlowFixMe[incompatible-return] */}
      {hidePlacementData && <LabeledFigures figures={figures} />}
      {zeroState && (
        <>
          <ZeroStateBar />{' '}
          <div className={css.zeroStateBarCaption}>
            <div>ENGAGED</div>
            <div>PLACED</div>
          </div>
          <p className={css.description}>
            Generate{' '}
            {labelI18nInstance.t('sense_placement', 'placement').toLowerCase()}{' '}
            revenue by sending targeted personalized messages at key moments
            throughout the{' '}
            {labelI18nInstance.t('sense_candidate', 'candidate').toLowerCase()}{' '}
            lifecycle. Sense’s impact on your bottom line will appear in this
            chart
          </p>
          <a
            href="https://support.sensehq.co/hc/en-us/articles/360051621351-Candidate-to-Placement-Conversions"
            className={css.link}
            target="_blank"
          >
            <span>
              Engage{' '}
              {labelI18nInstance
                .t('sense_candidates', 'candidates')
                .toLowerCase()}{' '}
              to generate{' '}
              {labelI18nInstance
                .t('sense_placements', 'placements')
                .toLowerCase()}
            </span>
            <LinkIcon className={css.linkIcon} />
          </a>
        </>
      )}
    </div>
  );
};

export const SavingsRoi = ({roi}: {roi: ?CountApiRoi}): React.Node => {
  const {time_saved, fte_hours} = roi || {};
  const fteTimeSaved = Number(checkRoiV3ApiEnabled() ? time_saved : fte_hours);
  const {labelI18nInstance} = useI18n();
  const {dayValue, dayUnit} = (roi && shrinkHours(Number(fteTimeSaved))) || {};

  const figures = roi
    ? [
        {
          label: (
            <div>
              FTE hours <br />
              saved
            </div>
          ),
          value: fteTimeSaved,
        },
        {
          label: (
            <div>
              {`FTE ${dayUnit.toUpperCase()}`} <br />
              saved
            </div>
          ),
          value: dayValue,
        },
      ]
    : [];

  return (
    <div className={classify(css.section, css.roiSection)}>
      <div className={css.headerRow}>
        <h3 className={css.sectionHeader}>Savings</h3>
        {fteTimeSaved > 0 && (
          <ToolTip content="Sending a single automated customized message via Sense saves a human recruiter a few minutes of time. Sending thousands adds up to weeks, then months, freeing recruiters to do more important tasks." />
        )}
      </div>
      {roi && fteTimeSaved > 0 && <LabeledFigures figures={figures} />}

      {!fteTimeSaved && (
        <>
          <ZeroStateBar />
          <div className={css.zeroStateBarCaption}>
            <div>MANUAL</div>
            <div>AUTOMATIC</div>
          </div>
          <p className={css.description}>
            Save thousands of hours of human resources by personalizing messages
            and transcribing{' '}
            {labelI18nInstance.t('sense_candidate', 'candidate').toLowerCase()}{' '}
            responses to your ATS automatically. The hours you’ve saved will
            appear in this chart
          </p>
          <a
            href="https://support.sensehq.co/hc/en-us/articles/360052215571-Time-Saved"
            className={css.link}
            target="_blank"
          >
            <span>Time saved via automations</span>
            <LinkIcon className={css.linkIcon} />
          </a>
        </>
      )}
    </div>
  );
};

export const CandidateNPS = (): React.Node => {
  const npsData = useSelector(selectNpsData);
  const {score, promoters, passive, detractors} = npsData || {};
  const {labelI18nInstance} = useI18n();
  const total = promoters + passive + detractors;
  const isJourneyV2 = useAudienceFilterSeparation();
  const journeyPageRoute = isJourneyV2
    ? '/journeys/goal-selection'
    : '/journey/new';

  return (
    <div
      className={classify(css.section, {
        [css.npsCompact]: !score,
        [css.npsExpanded]: score,
      })}
    >
      <div className={css.headerRow}>
        <h3 className={css.sectionHeader}>
          {labelI18nInstance.t('sense_candidate', 'Candidate')} NPS
        </h3>
        {score ? (
          <ToolTip
            content={
              <div>
                {' '}
                This number is the average of <b>Net Promoter Score </b>
                responses to your surveys and chatbots that contained an NPS
                question.
              </div>
            }
          />
        ) : (
          ''
        )}
      </div>
      <div className={css.npsContents}>
        {score ? <Dial rawValue={Math.round(score)} /> : <ZeroStateDial />}
        {score ? (
          <div className={css.npsBreakdown}>
            <div className={css.npsPromoters}>{`PROMOTERS ${formatPercentage(
              promoters / total,
            )}`}</div>
            <div className={css.npsBar}>
              <div
                className={css.npsPromoterBar}
                style={{width: `${formatPercentage(promoters / total)}`}}
              />
              <div
                className={css.npsPassiveBar}
                style={{width: `${formatPercentage(passive / total)}`}}
              />
              <div style={{width: `${formatPercentage(detractors / total)}`}} />
            </div>
            <div className={css.npsBottom}>
              <div className={css.npsBottomx}>{`PASSIVES ${formatPercentage(
                passive / total,
              )}`}</div>
              <div
                className={css.detractorLabel}
              >{`DETRACTORS ${formatPercentage(detractors / total)}`}</div>
            </div>
          </div>
        ) : (
          <div className={css.columnSection}>
            <div className={css.zeroStateNpsCaption}>PROMOTER</div>
            <div className={css.zeroStateNpsBarSolid}></div>
            <div className={css.zeroStateNpsBar}></div>
            <div className={css.zeroStateNpsBarSolid}></div>
            <div className={css.zeroStateNpsCaption}>DETRACTOR</div>
          </div>
        )}
      </div>
      {score ? (
        <Link to={'/analytics/nps'} className={css.link}>
          <span>View full report</span>
          <LinkIcon className={css.linkIcon} />
        </Link>
      ) : (
        <Link to={journeyPageRoute} className={css.link}>
          <span>Create a net promoter survey</span>
          <LinkIcon className={css.linkIcon} />
        </Link>
      )}
    </div>
  );
};

export const Highlights = ({
  placementActivity,
  placementDurations,
  redeploymentGrid,
  newHires,
  redeploys,
}: {
  placementActivity?: RoiPlacementActivity,
  placementDurations?: RoiSummary,
  redeploymentGrid?: RoiRedeploymentGrid,
  newHires?: number,
  redeploys?: number,
}): React.Node => {
  const placementDurationMean = placementDurations
    ? getPlacementDurationMean(placementDurations)
    : 0;
  const averageRedeploymentDays = redeploymentGrid
    ? getAverageRedeploymentDays(redeploymentGrid)
    : 0;
  const numFirstPlacements = !checkRoiV3ApiEnabled()
    ? placementActivity?.numFirstPlacements
    : newHires || 0;
  const numRedeployments = !checkRoiV3ApiEnabled()
    ? placementActivity?.numRedeployments
    : redeploys || 0;
  const total = Number(numFirstPlacements) + Number(numRedeployments);
  const {labelI18nInstance} = useI18n();
  const displayConfig = {
    analytics_performance_redeploys: useDisplayConfig(
      'analytics_performance_redeploys',
    ),
    analytics_performance_days_to_redeploy: useDisplayConfig(
      'analytics_performance_days_to_redeploy',
    ),
    analytics_performance_months_assigned: useDisplayConfig(
      'analytics_performance_months_assigned',
    ),
  };

  return (
    <div className={classify(css.section, css.npsCompact)}>
      <h3 className={css.sectionHeader}>Highlights</h3>
      {total > 0 && (
        <div className={css.highlightsContent}>
          <div className={css.highlightRow}>
            <div className={css.highlightLabel}>
              New{' '}
              {labelI18nInstance
                .t('sense_placements', 'placements')
                .toLowerCase()}
            </div>
            <div className={css.highlightValue}>{numFirstPlacements}</div>
          </div>
          {displayConfig.analytics_performance_redeploys && (
            <div className={css.highlightRow}>
              <div className={css.highlightLabel}>Redeploys</div>
              <div className={css.highlightValue}>{numRedeployments}</div>
            </div>
          )}
          {displayConfig.analytics_performance_days_to_redeploy && (
            <div className={css.highlightRow}>
              <div className={css.highlightLabel}>Days to Redeploy</div>
              <div className={css.highlightValue}>
                {averageRedeploymentDays}
              </div>
            </div>
          )}
          {displayConfig.analytics_performance_months_assigned && (
            <div className={css.highlightRow}>
              <div className={css.highlightLabel}>Months assigned</div>
              <div className={css.highlightValue}>{placementDurationMean}</div>
            </div>
          )}
        </div>
      )}
      {(isNaN(total) || total === 0) && (
        <div className={css.description}>
          This performance metrics page gives you insight into placements,
          including the redeployment rate, average assignment length, and more.
          Key metrics about your first placements will apear here
        </div>
      )}
      {(isNaN(total) || total === 0) && (
        <Link to={'/analytics'} className={css.link}>
          <span>About performance metrics</span>
          <LinkIcon className={css.linkIcon} />
        </Link>
      )}
    </div>
  );
};

const ZeroStateDial = () => <NPSZeroState className={css.zeroStateNpsDial} />;

export function Dial({rawValue = 50}: {rawValue?: number}): React.Node {
  const value = rawValue > 0 ? rawValue / 100 : 0;

  const originAngle = Math.PI / 2;
  const addAngle = 0.7 * Math.PI;
  const startAngle = originAngle + addAngle;

  const totalAngle = addAngle * 2;
  const totalAngleDegrees = (totalAngle * 180) / Math.PI;

  const endX = Math.cos(startAngle - totalAngle);
  const endY = Math.sin(startAngle - totalAngle);

  const outerR = 50;
  const outerEndX = endX * outerR + 50;
  const outerEndY = endY * -outerR + 50;
  const outerStartX = -endX * outerR + 50;
  const outerStartY = outerEndY;

  const innerR = 37;
  const innerEndX = endX * innerR + 50;
  const innerEndY = endY * -innerR + 50;
  const innerStartX = -endX * innerR + 50;
  const innerStartY = innerEndY;

  const progressAngle = totalAngle * value;
  const endAngle = startAngle - progressAngle;
  const progressEnd = {
    x: Math.cos(endAngle),
    y: Math.sin(endAngle),
  };
  const progressOuterEnd = {
    x: progressEnd.x * outerR + 50,
    y: progressEnd.y * -outerR + 50,
  };
  const progressInnerEnd = {
    x: progressEnd.x * innerR + 50,
    y: progressEnd.y * -innerR + 50,
  };

  const middleR = (outerR - innerR) / 2 + innerR;
  const triangleEndAngle = endAngle - 0.05 * Math.PI;
  const pointerEnd = {
    x: Math.cos(triangleEndAngle) * middleR + 50,
    y: Math.sin(triangleEndAngle) * -middleR + 50,
  };

  const progressIsLarge = progressAngle > Math.PI ? 1 : 0;

  let textLength = 20;
  if (String(rawValue).length === 3) {
    textLength = 30;
  } else if (String(rawValue).length === 4) {
    textLength = 35;
  }

  return (
    <svg width="100" height="100" viewBox="0 0 100 100">
      <circle cx="50" cy="50" r="30" fill="#F7F7F7" />
      <text
        x="50%"
        y="48%"
        text-anchor="middle"
        stroke={value > 0 ? '#196E46' : '#E71D42'}
        stroke-width="2px"
        dy=".4em"
        textLength={textLength}
        lengthAdjust="spacing"
      >
        {rawValue}
      </text>
      <path
        d={`M ${outerStartX},${outerStartY}
          A ${outerR},${outerR} ${totalAngleDegrees} 1 1 ${outerEndX},${outerEndY}
          L ${innerEndX},${innerEndY}
          A ${innerR},${innerR} ${totalAngleDegrees} 1 0 ${innerStartX},${innerStartY}
          Z`}
        fill="#dddddd"
      />
      {value > 0 && (
        <path
          d={`M ${outerStartX},${outerStartY}
          A ${outerR},${outerR} ${totalAngleDegrees} ${progressIsLarge} 1 ${progressOuterEnd.x},${progressOuterEnd.y}
          L ${pointerEnd.x},${pointerEnd.y}
          L ${progressInnerEnd.x},${progressInnerEnd.y}
          A ${innerR},${innerR} ${totalAngleDegrees} ${progressIsLarge} 0 ${innerStartX},${innerStartY}
          Z`}
          fill="#196e46"
        />
      )}
    </svg>
  );
}
