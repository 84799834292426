// @flow strict-local

import * as React from 'react';

import {useApi} from 'src/hooks/useApi';
import {DismissableNotification} from './notification.jsx';


export default function AppStatusNotification(): React.Node {
  // NOTE (kyle): we don't care about loading or errors
  const {result} = useApi('app-status/announcement');

  if (!result) {
    return null;
  }

  return (
    <DismissableNotification flag="app-status-announcement">
      {result}
    </DismissableNotification>
  );
}
