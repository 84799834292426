// @flow strict

const levels = {
  critical: 0, // fire! fix now
  error: 1, // bad. should fix
  warning: 2, // possibly bad? should monitor
  info: 3, // helpful for tracking usage in production app
  debug: 4, // helpful for debugging. won't show on production
};

export default levels;
