// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {ActionAttributeSchema} from 'src/types/action-based-targeting-meta';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, cached, fetching} from 'src/utils/redux';
// $FlowFixMe[untyped-import]
import {camel} from 'src/utils';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';


export const RECEIVE_ABT_ATTRIBUTES = 'abt-attributes/receiveAll';

type ReceiveAbtAttributesAction = {
  type: 'abt-attributes/receiveAll',
  payload: ActionAttributeSchema[],
};

export type AbtAttributesAction = ReceiveAbtAttributesAction;

const receiveAttributes = (
  attributes: ActionAttributeSchema[],
): ReceiveAbtAttributesAction => ({
  type: RECEIVE_ABT_ATTRIBUTES,
  payload: attributes,
});

export const getABTAttributes: () => ThunkAction<void> = flow(
  key(() => 'ABTAttributes'),
  cached(({labels: attributes}) => receiveAttributes(camel(attributes)), {
    ttl: 60 * 60 * 1000,
  }),
  fetching(),
)(() => (dispatch: Dispatch) => dispatch(reduxApi.get('action_metadata')));
