import Papa from 'papaparse';


export const parseCsv = (file, {progress, ...config}) =>
  new Promise((resolve, reject) => {
    const {size} = file;
    let results;

    Papa.parse(file, {
      ...config,
      chunk: (resultChunk) => {
        if (!results) {
          results = resultChunk;
        } else {
          results.data.push(resultChunk.data);
        }
        progress(results.meta.cursor / size);
      },
      complete: () => {
        resolve(results);
      },
      error: (error) => reject(error),
    });
  });
