// @flow strict

import * as React from 'react';
import {classify} from 'src/utils/classify';

import css from 'src/designSystem2021Components/banner/banner.css';
import {BannerCard} from './banner-card/banner-card.jsx';
// $FlowFixMe[nonstrict-import]
import type {ContentWarningMessageDetails} from 'src/types/messages';
import {Icon} from '@spaced-out/ui-design-system/lib/components/Icon';
import {
  SubTitleExtraSmall,
  ButtonTextSmall,
} from '@spaced-out/ui-design-system/lib/components/Text';


export type BannerProps = {
  data: Array<ContentWarningMessageDetails>,
  handleResolve: (idx: number, warning_type: string) => Promise<void>,
  handleClose: (idx: number, warning_type: string) => void,
  onMouseEnter: (warning_type: string) => void,
  onMouseLeave: (warning_type: string) => void,
  className?: string,
  linkToMoreInfo?: string,
};

export const Banner = ({
  data,
  className,
  handleResolve,
  handleClose,
  onMouseEnter,
  onMouseLeave,
  linkToMoreInfo,
}: BannerProps): React.Node => {
  const [showAll, setShowAll] = React.useState(false);
  const [visibleData, setVisibleData] = React.useState([data[0]]);
  const [warningsCount, setWarningsCount] = React.useState(data.length);
  const setVisibleDataBasedOnShowAll = (value) => {
    if (value) {
      setVisibleData(data);
    } else {
      setVisibleData([data[0]]);
    }
  };
  const handleClick = () => {
    const value = !showAll;
    setShowAll((prevValue) => !prevValue);
    setVisibleDataBasedOnShowAll(value);
  };
  React.useEffect(() => {
    setShowAll(showAll);
    setVisibleDataBasedOnShowAll(showAll);
    setWarningsCount(data.length);
  }, [data, showAll, warningsCount]);

  return (
    <div className={classify(css.bannerContainer, className)}>
      <div className={css.bannerBox}>
        <button className={css.bannerShowButton} onClick={handleClick}>
          <ButtonTextSmall color="primary">
            {showAll ? 'Show Less' : `Show All (${warningsCount})`}
          </ButtonTextSmall>
          <Icon
            color="primary"
            name={showAll ? 'chevron-up' : 'chevron-down'}
            size="small"
            type="regular"
          />
        </button>
        <div className={css.bannerCardsContainer}>
          {visibleData.map((bannerData, idx) => (
            <BannerCard
              warningType={bannerData.warning_type}
              bannerText={bannerData.warning_message}
              showResolveButton={bannerData.show_resolve_button}
              handleClose={() => {
                handleClose(idx, bannerData.warning_type);
                setWarningsCount((prevValue) => prevValue - 1);
              }}
              onMouseEnter={() => {
                onMouseEnter(bannerData.warning_type);
              }}
              onMouseLeave={() => {
                onMouseLeave(bannerData.warning_type);
              }}
              handleResolve={async () => {
                handleResolve(idx, bannerData.warning_type);
                setWarningsCount((prevValue) => prevValue - 1);
              }}
            />
          ))}
        </div>
        <a href={linkToMoreInfo} target="_blank">
          <div className={css.bannerBottomMessageContainer}>
            <SubTitleExtraSmall color="secondary">
              Message Quality Suggestion
            </SubTitleExtraSmall>
            <Icon
              color="secondary"
              name="circle-info"
              size="small"
              type="regular"
            />
          </div>
        </a>
      </div>
    </div>
  );
};
