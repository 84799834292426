// @flow strict
import * as React from 'react';

//$FlowFixMe[nonstrict-import]
import type {DynamicLabels} from 'src/types/dynamic-labels';
import type {
  AudienceRule,
  ActionTypeAudienceRule,
  AudienceRuleApi,
  AudienceBlocks,
  AudienceBlocksApi,
  AudienceListTableEntry,
  AudienceListStatus,
  AssociatedResource,
  AudienceListApiResponse,
  NewAudienceListSidePanels,
  EditAudienceListSidePanels,
  AssociatedResources,
  CombinedActionAudienceRule,
  StatusOption,
  AudienceListSource,
  VariableEntry,
  PaginationData,
  SortData,
  ScopeOption,
} from 'src/types/audience-list';
// $FlowFixMe[nonstrict-import]
import type {ApiWorkflow, Workflow} from 'src/types/workflow';
import type {
  EntityMetadataObject,
  EntityMetadataEntityAttributes,
} from 'src/types/ats-entities';

import uniqueId from 'lodash/uniqueId';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import some from 'lodash/some';
import has from 'lodash/has';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
//$FlowFixMe
import {hashObject, sortObject} from 'src/utils/index';
//$FlowFixMe[nonstrict-import]
import type {SenseApiEvent} from 'src/types/events';
//$FlowFixMe[nonstrict-import]
import {checkForString} from 'src/components/workflow/event/content/module/editors/beefree.jsx';
import {
  //$FlowFixMe[nonstrict-import]
  surveyLinkEmailToken,
  //$FlowFixMe[nonstrict-import]
  surveyContentToken,
} from 'src/components/workflow/event/constants';


export const PAGE_SIZE = 12;

const commonAudienceListSidePanels = ['details', 'rules', 'review'];

export const newAudienceListSidePanels: NewAudienceListSidePanels[] = [
  ...commonAudienceListSidePanels,
  'copyLists',
];
export const editAudienceListSidePanels: EditAudienceListSidePanels[] = [
  ...commonAudienceListSidePanels,
];

export const LIST_NAME_VALID_CHARS_REGEX: RegExp =
  /^[A-Za-z-\s\d\_\{\}\(\)\[\]\&\,\*\?\'\"\:\;\.]*$/;

export const isValidListName = (name: string): boolean =>
  LIST_NAME_VALID_CHARS_REGEX.test(name);

export const LIST_STATUS_OPTIONS: Array<StatusOption> = [
  {label: 'In Use', value: 'live'},
  {label: 'Ready to use', value: 'published'},
  {label: 'Draft', value: 'draft'},
  {label: 'Building', value: 'building'},
  {label: 'Archived', value: 'archived'},
];

export function getListLabel(value: string): string {
  const statusOption: ?StatusOption = LIST_STATUS_OPTIONS.find(
    (option) => option.value === value,
  );
  return statusOption ? statusOption.label : 'Unknown';
}

const ABT_VALUES = new Set([
  'email_events_any_specific_email',
  'link_events_any_external_link',
  'survey_events_any_specific_survey',
  'survey_events_any_specific_survey_question',
  'link_events_any_specific_external_link',
]); //TODO(Vish):Get a list of all possible abt attribute values and list them here;

export const ARCHIVEABLE_STATUSES = ['draft', 'published'];

export const getListsToArchive = (
  selected: string[],
  lists: AudienceListTableEntry[],
): string[] =>
  selected.filter((id) => {
    const item = lists.find((list) => list.id === id);
    if (!item) {
      return false;
    }
    if (ARCHIVEABLE_STATUSES.includes(item.status)) {
      return true;
    }
    return false;
  });

export const getListsToUnarchive = (
  selected: string[],
  lists: AudienceListTableEntry[],
): string[] =>
  selected.filter((id) => {
    const item = lists.find((list) => list.id === id);
    if (!item) {
      return false;
    }
    return item.status === 'archived';
  });

export const getListsToPublish = (
  selected: string[],
  lists: AudienceListTableEntry[],
): string[] => {
  const listToPublish = selected.filter((id) => {
    const item = lists.find((list) => list.id === id);
    if (!item) {
      return false;
    }
    return item.status === 'draft';
  });

  return listToPublish;
};

export const DELETABLE_STATUSES = ['draft', 'published', 'archived'];

export const isListDeletable = (
  everBeenLive: boolean,
  status: string,
): boolean => {
  return everBeenLive === false && DELETABLE_STATUSES.includes(status);
};

export const getListsToDelete = (
  selected: string[],
  lists: AudienceListTableEntry[],
): string[] =>
  selected.filter((id) => {
    const item = lists.find((list) => list.id === id);
    if (!item) {
      return false;
    }
    if (isListDeletable(item.everBeenLive, item.status)) {
      return true;
    }
    return false;
  });

export const depricatedOperatorAlertText = `The operators 'is not set/present,' 'is empty,' and 'is set/present' are deprecated. Please use the 'is empty/null,' 'is not empty/not-null' operators instead and save the rules to proceed.`;

export const OPERATOR_OPTIONS = {
  string: [
    {
      value: 'eq',
      label: 'is',
      tooltip: 'status = "placed"',
    },
    {
      value: 'neq',
      label: 'is not',
      tooltip: 'status ≠ "placed"',
    },
    {
      value: 'empty',
      label: 'is empty',
      tooltip: 'status is empty',
      secondaryLabel: 'Use “is empty/null” instead',
      disabled: true,
    },
    {
      value: 'is_null',
      label: 'is not set/present',
      tooltip: 'status = NULL',
      secondaryLabel: 'Use “is empty/null” instead',
      disabled: true,
    },
    {
      value: 'is_not_null',
      label: 'is set/present',
      tooltip: 'status ≠ NULL',
      secondaryLabel: 'Use “is not empty/not-null” instead',
      disabled: true,
    },
    {
      value: 'contains',
      label: 'contains one of',
      tooltip: `email contains one of "@gmail"
      possible values = "abc@gmail.com", "xyz@gmail.in"`,
    },
    {
      value: 'ncontains',
      label: 'does not contain one of',
      tooltip: `email doesn't contain one of "@yahoo"
      possible values = "abc@gmail.com", "xyz@outlook.in"`,
    },
    {
      value: 'starts_with',
      label: 'starts with one of',
      tooltip: `name starts with one of "John"
      possible values = "John R", "John Matthew"`,
    },
    {
      value: 'nstarts_with',
      label: 'does not start with one of',
      tooltip: `name doesn't start with one of "John"
      possible values = "Mick K.", "Ram C"`,
    },
    {
      value: 'ends_with',
      label: 'ends with one of',
      tooltip: `email ends with one of "@gmail.com", "@yahoo.com"
      possible values = "abc@gmail.com", "xyz@yahoo.com"`,
    },
    {
      value: 'in',
      label: 'is one of',
      tooltip: `company name = "Dell" OR company name = "Microsoft"`,
    },
    {
      value: 'not_in',
      label: 'is not one of',
      tooltip: `company name ≠ "Dell" AND company name ≠ "Microsoft"`,
    },
    {
      value: 'is_empty_or_null',
      label: 'is empty/null',
      tooltip: 'status = NULL or status is empty',
    },
    {
      value: 'is_not_empty_and_not_null',
      label: 'is not empty/ not null',
      tooltip: 'status ≠ NULL or status is not empty',
    },
  ],
  number: [
    {
      value: 'gt',
      label: 'greater than',
      tooltip: 'value > 5',
    },
    {
      value: 'lt',
      label: 'less than',
      tooltip: 'value < 5',
    },
    {
      value: 'is_null',
      label: 'is not set/present',
      tooltip: 'value is not set',
      disabled: true,
    },
    {
      value: 'is_not_null',
      label: 'is set/present',
      tooltip: 'value is set',
      disabled: true,
    },
    {
      value: 'eq',
      label: 'equals',
      tooltip: 'value = 5',
    },
    {
      value: 'neq',
      label: 'not equal to',
      tooltip: 'value ≠ 5',
    },
  ],
  currency: [
    {
      value: 'gt',
      label: 'greater than',
      tooltip: 'value > 5',
    },
    {
      value: 'lt',
      label: 'less than',
      tooltip: 'value < 5',
    },
    {
      value: 'is_null',
      label: 'is not set/present',
      tooltip: 'value is not set',
      disabled: true,
    },
    {
      value: 'is_not_null',
      label: 'is set/present',
      tooltip: 'value is set',
      disabled: true,
    },
    {
      value: 'eq',
      label: 'equals',
      tooltip: 'value = 5',
    },
    {
      value: 'neq',
      label: 'not equal to',
      tooltip: 'value ≠ 5',
    },
  ],
  date: [
    {
      value: 'gt',
      label: 'is after',
      tooltip: 'date_added > 2023-06-01 ',
    },
    {
      value: 'lt',
      label: 'is before',
      tooltip: 'date_added < 2023-06-09',
    },
    {
      value: 'eq',
      label: 'is equal to',
      tooltip: 'date_added = 2023-06-09 ',
    },
    {
      value: 'gte',
      label: 'is on or after',
      tooltip: 'date_added >= 2023-06-01',
    },
    {
      value: 'lte',
      label: 'is on or before',
      tooltip: 'date_added is <= 2023-06-09',
    },
    {
      value: 'is_null',
      label: 'is not set/present',
      tooltip: 'date_added is not set',
      secondaryLabel: 'Use “is empty/null” instead',
      disabled: true,
    },
    {
      value: 'is_not_null',
      label: 'is set/present',
      tooltip: 'date_added is set',
      secondaryLabel: 'Use “is not empty/not-null” instead',
      disabled: true,
    },
    {
      value: 'is_empty_or_null',
      label: 'is empty/null',
      tooltip: 'status = NULL or status is empty',
    },
    {
      value: 'is_not_empty_and_not_null',
      label: 'is not empty/ not null',
      tooltip: 'status ≠ NULL or status is not empty',
    },
  ],

  boolean: [
    {
      value: true,
      label: 'is true',
      tooltip: 'candidate/active is true',
    },
    {
      value: false,
      label: 'is false',
      tooltip: 'candidate/active is false',
    },
    {
      value: 'is_null',
      label: 'is not set/present',
      tooltip: 'candidate/active is not set',
      secondaryLabel: 'Use “is empty/null” instead',
      disabled: true,
    },
    {
      value: 'is_not_null',
      label: 'is set/present',
      tooltip: 'candidate/active is set',
      secondaryLabel: 'Use “is not empty/not-null” instead',
      disabled: true,
    },
  ],
  email: [
    {
      value: 'eq',
      label: 'is',
      tooltip: 'status = "placed"',
    },
    {
      value: 'neq',
      label: 'is not',
      tooltip: 'status ≠ "placed"',
    },
    {
      value: 'empty',
      label: 'is empty',
      tooltip: 'status is empty',
      secondaryLabel: 'Use “is empty/null” instead',
      disabled: true,
    },
    {
      value: 'is_null',
      label: 'is not set/present',
      tooltip: 'status = NULL',
      secondaryLabel: 'Use “is empty/null” instead',
      disabled: true,
    },
    {
      value: 'is_not_null',
      label: 'is set/present',
      tooltip: 'status ≠ NULL',
      secondaryLabel: 'Use “is not empty/not-null” instead',
      disabled: true,
    },
    {
      value: 'contains',
      label: 'contains one of',
      tooltip: `email contains one of "@gmail"
      possible values = "abc@gmail.com", "xyz@gmail.in"`,
    },
    {
      value: 'ncontains',
      label: 'does not contain one of',
      tooltip: `email doesn't contain one of "@yahoo"
      possible values = "abc@gmail.com", "xyz@outlook.in"`,
    },
    {
      value: 'starts_with',
      label: 'starts with one of',
      tooltip: `name starts with one of "John"
      possible values = "John R", "John Matthew"`,
    },
    {
      value: 'nstarts_with',
      label: 'does not start with one of',
      tooltip: `name doesn't start with one of "John"
      possible values = "Mick K.", "Ram C"`,
    },
    {
      value: 'ends_with',
      label: 'ends with one of',
      tooltip: `email ends with one of "@gmail.com", "@yahoo.com"
      possible values = "abc@gmail.com", "xyz@yahoo.com"`,
    },
    {
      value: 'in',
      label: 'is one of',
      tooltip: `company name = "Dell" OR company name = "Microsoft"`,
    },
    {
      value: 'not_in',
      label: 'is not one of',
      tooltip: `company name ≠ "Dell" AND company name ≠ "Microsoft"`,
    },
    {
      value: 'is_empty_or_null',
      label: 'is empty/null',
      tooltip: 'status = NULL or status is empty',
    },
    {
      value: 'is_not_empty_and_not_null',
      label: 'is not empty/not null',
      tooltip: 'status ≠ NULL or status is not empty',
    },
  ],
  phone: [
    {
      value: 'eq',
      label: 'is',
      tooltip: 'number = +12123231324',
    },
    {
      value: 'neq',
      label: 'is not',
      tooltip: 'number ≠ +12123231324',
    },
    {
      value: 'empty',
      label: 'is empty',
      tooltip: 'number is empty',
      secondaryLabel: 'Use “is empty/null” instead',
      disabled: true,
    },
    {
      value: 'is_null',
      label: 'is not set/present',
      tooltip: 'number = NULL',
      secondaryLabel: 'Use “is empty/null” instead',
      disabled: true,
    },
    {
      value: 'is_not_null',
      label: 'is set/present',
      tooltip: 'number ≠ NULL',
      secondaryLabel: 'Use “is not empty/not-null” instead',
      disabled: true,
    },
    {
      value: 'contains',
      label: 'contains one of',
      tooltip: `number contains one of "1"
        possible values = "91435", "12914356"`,
    },
    {
      value: 'ncontains',
      label: 'does not contain one of',
      tooltip: `number doesn't contain one of "1"
        possible values = "92435", "2924356"`,
    },
    {
      value: 'starts_with',
      label: 'starts with one of',
      tooltip: `number starts with one of "123"
        possible values = "12393828", "123590"`,
    },
    {
      value: 'nstarts_with',
      label: 'does not start with one of',
      tooltip: `number doesn't start with one of "123"
        possible values = "12938", "148590"`,
    },
    {
      value: 'ends_with',
      label: 'ends with one of',
      tooltip: `number ends with one of "90"
        possible values = "1238090", "379890"`,
    },
    {
      value: 'in',
      label: 'is one of',
      tooltip: 'number = "123" or number = "456"',
    },
    {
      value: 'not_in',
      label: 'is not one of',
      tooltip: 'number ≠ "123" or number ≠ "456"',
    },
    {
      value: 'is_empty_or_null',
      label: 'is empty/null',
      tooltip: 'number = NULL or number is empty',
    },
    {
      value: 'is_not_empty_and_not_null',
      label: 'is not empty/ not null',
      tooltip: 'number ≠ NULL or number is not empty',
    },
  ],
};

export const DEFAULT_OPERATOR_OPTION = {
  string: 'eq',
  number: 'gt',
  date: 'gt',
  boolean: true,
  email: 'eq',
  phone: 'eq',
};

export const MAX_LIMIT_IN_NOT_IN = 50000;
export const MAX_LIMIT_SUBSTRING = 100;

//Todo(vish): Get all possible abt supported survey questions from backend.
export const ABT_SUPPORTED_SURVEY_QUESTIONS = [
  'nps_survey_question',
  'multiple_choice_survey_question',
  'text_survey_question',
];

export const SCOPE_OPTIONS: ScopeOption[] = [
  {
    label: 'Custom Date Range',
    value: 'custom_date_range',
    tooltip: 'You have a fixed date range from X to Y',
  },
  {
    label: 'Specific Date to Till now',
    value: 'specific_date_to_now',
    tooltip: 'You have a fixed "from" Date to capture actions, till now',
  },
];

//TODO(vish): Get all possible abt attribute keys from backend.
export const ABT_ATTRIBUTE_KEY_SET = {
  email_events_any_specific_email: {
    initialRuleItems: {
      criteriaField: {
        criteriaLabel: null,
        criteriaValue: null,
        journeyLabel: null,
        journeyValue: null,
        touchpointLabel: null,
        touchpointValue: null,
      },
      scopeField: {
        scopeLabel: null,
        scopeValue: null,
        fromDate: null,
        toDate: null,
      },
    },
    primaryBoxTitle: 'Select Email',
    criteriaValues: [
      {label: 'Email has been opened', value: 'email_opened'},
      {label: 'Email has been sent', value: 'email_sent'},
      {label: 'Email has been clicked', value: 'email_clicked'},
      {label: 'Email has bounced', value: 'email_bounced'},
      {label: 'Email has been delivered', value: 'email_delivered'},
    ],
    secondaryBoxTitle: 'Select Scope',
    scopeValues: SCOPE_OPTIONS,
    dslCreatorHelper: [
      {
        attribute_name: ['fromRule', 'criteriaValue', 'field'],
        from_relations: ['fromRule', 'relatedEntitiesArray', 'field'],
        value: ['fixed', true],
      },
      {
        attribute_name: ['fixed', 'reference_type', 'field'],
        value: ['fixed', 'journey'],
      },
      {
        attribute_name: ['fixed', 'reference_id', 'field'],
        value: ['fromRule', 'journeyValue'],
      },
      {
        attribute_name: ['fixed', 'sub_reference_type', 'field'],
        value: ['fixed', 'touchpoint'],
      },
      {
        attribute_name: ['fixed', 'sub_reference_id', 'field'],
        value: ['fromRule', 'touchpointValue'],
      },
      {
        operator: ['fixed', 'gte'],
        attribute_name: ['fixed', 'start_date', 'field'],
        value: ['fromRule', 'fromDate'],
      },
      {
        operator: ['fixed', 'lte'],
        attribute_name: ['fixed', 'end_date', 'field'],
        value: ['fromRule', 'toDate'],
      },
    ],
    criteriaList: [
      'email_opened',
      'email_sent',
      'email_clicked',
      'email_bounced',
      'email_delivered',
    ],
    supportedTouchpointTypes: {
      send_type: ['email'],
      event_type: ([]: Array<empty>),
    },
    //Todo(vish): (Temporary) These required keys will be removed once we remove labels from initial rule items.
    requiredCriteriaKeys: ['criteriaValue', 'journeyValue', 'touchpointValue'],
    requiredScopeKeys: ['fromDate', 'toDate'],
  },
  survey_events_any_specific_survey: {
    initialRuleItems: {
      criteriaField: {
        criteriaLabel: null,
        criteriaValue: null,
        journeyLabel: null,
        journeyValue: null,
        touchpointLabel: null,
        touchpointValue: null,
      },
      scopeField: {
        scopeLabel: null,
        scopeValue: null,
        fromDate: null,
        toDate: null,
      },
    },
    primaryBoxTitle: 'Select Criteria and Survey',
    criteriaValues: [
      {label: 'Survey Completed', value: 'survey_completed'},
      {label: 'Survey Not Started', value: 'survey_not_started'},
    ],
    secondaryBoxTitle: 'Select Scope',
    scopeValues: SCOPE_OPTIONS,
    dslCreatorHelper: [
      {
        attribute_name: ['fromRule', 'criteriaValue', 'field'],
        from_relations: ['fromRule', 'relatedEntitiesArray', 'field'],
        value: ['fixed', true],
      },
      {
        attribute_name: ['fixed', 'reference_type', 'field'],
        value: ['fixed', 'journey'],
      },
      {
        attribute_name: ['fixed', 'reference_id', 'field'],
        value: ['fromRule', 'journeyValue'],
      },
      {
        attribute_name: ['fixed', 'sub_reference_type', 'field'],
        value: ['fixed', 'touchpoint'],
      },
      {
        attribute_name: ['fixed', 'sub_reference_id', 'field'],
        value: ['fromRule', 'touchpointValue'],
      },
      {
        operator: ['fixed', 'gte'],
        attribute_name: ['fixed', 'start_date', 'field'],
        value: ['fromRule', 'fromDate'],
      },
      {
        operator: ['fixed', 'lte'],
        attribute_name: ['fixed', 'end_date', 'field'],
        value: ['fromRule', 'toDate'],
      },
    ],
    criteriaList: ['survey_completed', 'survey_not_started'],
    supportedTouchpointTypes: {
      send_type: ([]: Array<empty>),
      event_type: ['survey', 'sms_survey', 'beefree_email'],
    },
    requiredCriteriaKeys: ['criteriaValue', 'journeyValue', 'touchpointValue'],
    requiredScopeKeys: ['fromDate', 'toDate'],
  },
  survey_events_any_specific_survey_question: {
    initialRuleItems: {
      criteriaField: {
        journeyLabel: null,
        journeyValue: null,
        touchpointLabel: null,
        touchpointValue: null,
        questionLabel: null,
        questionValue: null,
      },
      scopeField: {
        scopeLabel: null,
        scopeValue: null,
        fromDate: null,
        toDate: null,
        multipleChoices: null,
        npsOperator: null,
        npsValue: null,
        keywords: null,
      },
    },
    primaryBoxTitle: 'Select Survey',
    secondaryBoxTitle: 'Select criteria and scope',
    scopeValues: SCOPE_OPTIONS,
    criteriaValues: ([]: Array<empty>),
    dslCreatorHelper: [
      {
        attribute_name: ['fixed', 'survey_question_response', 'field'],
        from_relations: ['fromRule', 'relatedEntitiesArray', 'field'],
        value: ['fixed', true],
      },
      {
        attribute_name: ['fixed', 'reference_type', 'field'],
        value: ['fixed', 'touchpoint'],
      },
      {
        attribute_name: ['fixed', 'reference_id', 'field'],
        value: ['fromRule', 'touchpointValue'],
      },
      {
        attribute_name: ['fixed', 'sub_reference_type', 'field'],
        value: ['fixed', 'event_module'],
      },
      {
        attribute_name: ['fixed', 'sub_reference_id', 'field'],
        value: ['fromRule', 'questionValue'],
      },
      {
        operator: ['fromRule', 'npsOperator'],
        attribute_name: ['fixed', 'survey_nps_score', 'field'],
        value: ['fromRule', 'npsValue'],
      },
      {
        operator: ['fixed', 'contains'],
        attribute_name: ['fixed', 'survey_text', 'field'],
        value: ['fromRule', 'keywords'],
      },
      {
        operator: ['fixed', 'in'],
        attribute_name: ['fixed', 'survey_multiple_options', 'field'],
        value: ['fromRule', 'multipleChoices'],
      },
      {
        attribute_name: ['fixed', 'action_journey_id', 'field'],
        value: ['fromRule', 'journeyValue'],
      },
      {
        operator: ['fixed', 'gte'],
        attribute_name: ['fixed', 'start_date', 'field'],
        value: ['fromRule', 'fromDate'],
      },
      {
        operator: ['fixed', 'lte'],
        attribute_name: ['fixed', 'end_date', 'field'],
        value: ['fromRule', 'toDate'],
      },
    ],
    criteriaList: ['survey_question_response'],
    supportedTouchpointTypes: {
      send_type: ([]: Array<empty>),
      event_type: ['survey', 'sms_survey', 'beefree_email'],
    },
    requiredCriteriaKeys: ['questionValue', 'journeyValue', 'touchpointValue'],
    requiredScopeKeys: ['fromDate', 'toDate'],
  },
  link_events_any_specific_external_link: {
    initialRuleItems: {
      criteriaField: {
        criteriaLabel: null,
        criteriaValue: null,
        journeyLabel: null,
        journeyValue: null,
        touchpointLabel: null,
        touchpointValue: null,
        linkValue: null,
      },
      scopeField: {
        scopeLabel: null,
        scopeValue: null,
        fromDate: null,
        toDate: null,
      },
    },
    primaryBoxTitle: 'Select link',
    criteriaValues: [
      {label: 'External link has been sent', value: 'external_link_sent'},
      {label: 'External Link has been clicked', value: 'external_link_clicked'},
    ],
    secondaryBoxTitle: 'Select Scope',
    scopeValues: SCOPE_OPTIONS,
    dslCreatorHelper: [
      {
        attribute_name: ['fromRule', 'criteriaValue', 'field'],
        from_relations: ['fromRule', 'relatedEntitiesArray', 'field'],
        value: ['fixed', true],
      },
      {
        attribute_name: ['fixed', 'reference_type', 'field'],
        value: ['fixed', 'touchpoint'],
      },
      {
        attribute_name: ['fixed', 'reference_id', 'field'],
        value: ['fromRule', 'touchpointValue'],
      },
      {
        attribute_name: ['fixed', 'sub_reference_type', 'field'],
        value: ['fixed', 'external_link'],
      },
      {
        attribute_name: ['fixed', 'sub_reference_id', 'field'],
        value: ['fromRule', 'linkValue'],
      },
      {
        attribute_name: ['fixed', 'action_journey_id', 'field'],
        value: ['fromRule', 'journeyValue'],
      },
      {
        operator: ['fixed', 'gte'],
        attribute_name: ['fixed', 'start_date', 'field'],
        value: ['fromRule', 'fromDate'],
      },
      {
        operator: ['fixed', 'lte'],
        attribute_name: ['fixed', 'end_date', 'field'],
        value: ['fromRule', 'toDate'],
      },
    ],
    criteriaList: ['external_link_sent', 'external_link_clicked'],
    supportedTouchpointTypes: {
      send_type: ['email'],
      event_type: ([]: Array<empty>),
    },
    requiredCriteriaKeys: [
      'criteriaValue',
      'journeyValue',
      'touchpointValue',
      'linkValue',
    ],
    requiredScopeKeys: ['fromDate', 'toDate'],
  },
};

export const getFieldsToDesignations = (fields: DynamicLabels): {...} => {
  const fieldsToDesignations = {};
  fields.forEach(
    (field) => (fieldsToDesignations[field.value] = field.designations),
  );
  return Object.freeze(fieldsToDesignations);
};
export const getIsZipcodeField = (
  field: string,
  fields: DynamicLabels,
): boolean => {
  const fieldsToDesignations = getFieldsToDesignations(fields);
  //its a zipcode field if the designation contains zipcode
  return (fieldsToDesignations[field] ?? []).includes('ZIPCODE');
};
export const insertExtraZipcodeOperator = (
  operators: {
    label: string,
    value: string,
    tooltip?: string,
    disabled?: boolean,
    secondaryLabel?: string,
  }[],
): {
  label: string,
  value: string,
  tooltip?: string,
  disabled?: boolean,
  secondaryLabel?: string,
}[] =>
  operators.reduce((acc, operator) => {
    if (operator.value === 'in') {
      return [
        ...acc,
        operator,
        //NOTE: there is no in_radius operator on backend. its just a ui construct.
        {
          label: 'is within radius of',
          value: 'in_radius',
          tooltip: 'radius = 20 miles from source pincode (12069)',
        },
      ];
    }
    return [...acc, operator];
  }, []);

export const IN_OPERATORS = ['in', 'not_in']; // this will have 50k limit
export const SUBSTRING_OPERATORS = [
  // this will have 100 limit
  'contains',
  'ncontains',
  'starts_with',
  'nstarts_with',
  'ends_with',
];

export const ARRAY_OPERATORS = [
  'in',
  'not_in',
  'contains',
  'ncontains',
  'starts_with',
  'nstarts_with',
  'ends_with',
];
export const SINGLE_VALUE_OPERATORS = ['eq', 'neq', 'gt', 'lt'];
export const ALLOWS_EMPTY_STRING_OPERATORS = ['in'];
export const ALLOWS_EMPTY_DATE_OPERATORS = ['eq', 'gt', 'lt', 'gte', 'lte'];
export const VALUE_NOT_REQUIRED_OPERATORS = [
  'is_null',
  'is_not_null',
  'empty',
  'is_empty_or_null',
  'is_not_empty_and_not_null',
];

export const DEPRECATED_OPERATORS = ['is_null', 'is_not_null', 'empty'];

export const DATE_FILTER_OPTIONS = [
  {
    value: 'today',
    label: 'Today',
  },
  {
    value: 'specific_date',
    label: 'Specific Date',
  },
  {
    value: 'relative_date',
    label: 'Relative to',
  },
];

export const RELATIVE_DAYS_OPERATOR_OPTIONS = [
  {
    value: 'gt',
    label: 'is greater than',
  },
  {
    value: 'lt',
    label: 'is less than',
  },
];

export const RELATIVE_DAYS_BEFORE_OR_AFTER = [
  {
    value: 'before',
    label: 'before',
  },
  {
    value: 'after',
    label: 'after',
  },
];

export const RELATIVE_DAYS_MINUS_OR_PLUS = [
  {
    value: 'minus',
    label: 'Minus (-)',
  },
  {
    value: 'plus',
    label: 'Plus (+)',
  },
];

export const isOperatorSwitchIncompatible = (
  newOperator: string,
  oldOperator: ?(string | boolean),
): boolean => {
  if (newOperator === oldOperator) {
    return false;
  }
  const nonArrayOperators = [
    ...SINGLE_VALUE_OPERATORS,
    ...VALUE_NOT_REQUIRED_OPERATORS,
  ];
  const isNewOperatorNonArrayOperator = nonArrayOperators.includes(newOperator);
  const isOldOperatorNonArrayOperator = nonArrayOperators.includes(oldOperator);

  return isNewOperatorNonArrayOperator !== isOldOperatorNonArrayOperator;
};

export const getLimitRelatedErrors = (
  allBlocks: Map<string, AudienceRule[]>,
  totalInNotInCount: number,
): Array<string> => {
  const isInNotInExceeding = totalInNotInCount > MAX_LIMIT_IN_NOT_IN;
  const ruleItemWithErrors = [];
  allBlocks.forEach((ruleBlockArr, ruleBlockKey) => {
    ruleBlockArr.forEach((ruleItem) => {
      if (ruleItem.operator && IN_OPERATORS.includes(ruleItem.operator)) {
        if (isInNotInExceeding) {
          ruleItemWithErrors.push(ruleItem.id);
        }
      } else if (
        ruleItem.operator &&
        SUBSTRING_OPERATORS.includes(ruleItem.operator)
      ) {
        const ruleValueCount = Array.isArray(ruleItem.value)
          ? ruleItem.value.length
          : 0;
        if (ruleValueCount > MAX_LIMIT_SUBSTRING) {
          ruleItemWithErrors.push(ruleItem.id);
        }
      }
    });
  });
  return ruleItemWithErrors;
};

export const isThereDeprecatedOperator = (
  allBlocks: Map<string, AudienceRule[]>,
): boolean => {
  for (const [ruleBlockKey, ruleBlockArr] of allBlocks) {
    if (
      ruleBlockArr.some(
        (ruleItem) =>
          ruleItem.operator && DEPRECATED_OPERATORS.includes(ruleItem.operator),
      )
    ) {
      return true; // Found a deprecated operator, exit the function early
    }
  }
  return false; // No deprecated operators found
};

export const isAudienceBuilding = (status: AudienceListStatus): boolean =>
  ['published', 'draft', 'building'].includes(status);

export const canArchiveList = (status: AudienceListStatus): boolean =>
  ['published', 'draft'].includes(status);

export const canEditListRules = (status: string): boolean =>
  !['building', 'archived'].includes(status);

export const isListEditable = (status: string): boolean =>
  !['archived'].includes(status);

export const isListDetailsEditable = (source: AudienceListSource): boolean =>
  !['ANALYTICS'].includes(source);

export const shouldShowABT = (source: AudienceListSource): boolean =>
  !['ANALYTICS'].includes(source);

export const ruleValueToStringRuleValue = (
  value: ?number | number[] | string | string[] | boolean,
): string[] => {
  if (!value) {
    return [];
  }
  if (value != null) {
    if (Array.isArray(value)) {
      return value.map((v) => v.toString());
    }
    return [value.toString()];
  }
  return [];
};
export const ruleValueToNumberRuleValue = (
  value: ?number | number[] | string | string[],
): string => {
  if (!value && value !== 0) {
    return '';
  }
  if (value != null) {
    if (Array.isArray(value)) {
      return '';
    }
    return '' + value;
  }
  return '';
};
export const ruleValueToDateRuleValue = (
  value: ?(number | number[] | string | string[]),
): string => {
  if (!value) {
    return 'today';
  }
  if (value != null) {
    if (Array.isArray(value)) {
      return 'today';
    }
    return '' + value;
  }
  return 'today';
};

export const getFieldsToTypes = (
  inclusions: Map<string, AudienceRule[]>,
  exclusions: Map<string, AudienceRule[]>,
  fields: DynamicLabels,
): {[string]: string} =>
  [...inclusions.values(), ...exclusions.values()]
    .flat()
    .reduce((acc, rule) => {
      //TODO:(diwakersurya) optimize here if required
      if (rule.field) {
        const fieldObj = fields.find(
          (field) => field.value === rule.field.attribute_name,
        );
        const dataType = fieldObj?.type || 'string';
        acc[rule.field.attribute_name] = dataType;
      }
      return acc;
    }, {});

export const createInclusionBlockIdentifier = (): string =>
  uniqueId('inclusionBlock');

export const createExclusionBlockIdentifier = (): string =>
  uniqueId('exclusionBlock');

export const parseAudienceBlocks = (
  json: AudienceBlocksApi,
): AudienceBlocks => {
  const {all} = json;
  let {inclusions, exclusions} = json;
  inclusions = inclusions ?? {blocks: []};
  exclusions = exclusions ?? {blocks: []};

  let parsedInclusions = (inclusions.blocks ?? []).reduce((acc, block) => {
    const rules = [];
    (block.rules ?? []).forEach((rule) => {
      if (isABTRuleFromDSL(rule.conditions)) {
        rules.push(parseActionRule(rule.conditions));
      } else {
        rules.push(parseAudienceRule(rule.conditions[0]));
      }
    });

    return acc.set(createInclusionBlockIdentifier(), rules);
  }, new Map());
  if (parsedInclusions.size === 0) {
    parsedInclusions = getDefaultInclusionBlock();
  }

  /**NOTE:(diwakersurya) all blocks from exclusion form only one block on the ui
   * by combining rules across all the blocks. this is done because exclusion
   * blocks have or between them and backend only supports or between blocks and
   * not rules.
   */
  const allExclusionRules = (exclusions.blocks ?? []).reduce((acc, block) => {
    const rules = [];
    (block.rules ?? []).forEach((rule) => {
      if (isABTRuleFromDSL(rule.conditions)) {
        rules.push(parseActionRule(rule.conditions));
      } else {
        rules.push(parseAudienceRule(rule.conditions[0]));
      }
    });
    return [...acc, ...rules];
  }, []);
  let parsedExclusions = getDefaultExclusionBlock();
  if (allExclusionRules.length > 0) {
    parsedExclusions = new Map([
      [createExclusionBlockIdentifier(), allExclusionRules],
    ]);
  }

  return {all, inclusions: parsedInclusions, exclusions: parsedExclusions};
};

const getActionAttributeKey = (conditions) => {
  for (const {field} of conditions) {
    for (const key of Object.keys(ABT_ATTRIBUTE_KEY_SET)) {
      const {criteriaList} = ABT_ATTRIBUTE_KEY_SET[key];
      if (criteriaList.includes(field.attribute_name)) {
        return {key, attributeName: field.attribute_name};
      }
    }
  }
  return {key: '', attributeName: ''};
};

const extractConditionOperator = (
  conditions: AudienceRuleApi[],
  attributeName: string,
): ?string =>
  conditions
    .find((condition) => condition.field.attribute_name === attributeName)
    ?.operator.toString();

const extractConditionValue = (
  conditions: AudienceRuleApi[],
  attributeName: string,
  // $FlowFixMe[unclear-type]
): any => {
  const node =
    conditions.find(
      (condition) => condition.field.attribute_name === attributeName,
    ) ?? {};
  if (node.value) {
    return Array.isArray(node.value) ? node.value : node.value.toString();
  }
  return null;
};

const getRelatedEntitiesForParsing = (
  conditions: AudienceRuleApi[],
): string[] =>
  conditions.find((condition) => condition.field.from_relations.length > 0)
    ?.field.from_relations ?? [];

export const parseActionRule = (
  conditions: AudienceRuleApi[],
): AudienceRule => {
  const {key: ABTAttributeKey, attributeName: criteriaValue} =
    getActionAttributeKey(conditions);
  const ruleItems = getCombinedRuleItems(ABTAttributeKey);
  switch (ABTAttributeKey) {
    case 'survey_events_any_specific_survey_question':
      ruleItems.journeyValue = extractConditionValue(
        conditions,
        'action_journey_id',
      );
      ruleItems.touchpointValue = extractConditionValue(
        conditions,
        'reference_id',
      );
      ruleItems.questionValue = extractConditionValue(
        conditions,
        'sub_reference_id',
      );
      ruleItems.multipleChoices = extractConditionValue(
        conditions,
        'survey_multiple_options',
      );
      ruleItems.keywords = extractConditionValue(conditions, 'survey_text');
      ruleItems.npsValue = extractConditionValue(
        conditions,
        'survey_nps_score',
      );
      ruleItems.npsOperator = extractConditionOperator(
        conditions,
        'survey_nps_score',
      );
      break;
    case 'link_events_any_specific_external_link':
      ruleItems.journeyValue = extractConditionValue(
        conditions,
        'action_journey_id',
      );
      ruleItems.touchpointValue = extractConditionValue(
        conditions,
        'reference_id',
      );
      ruleItems.linkValue = extractConditionValue(
        conditions,
        'sub_reference_id',
      );
      break;
    case 'survey_events_any_specific_survey':
      ruleItems.journeyValue = extractConditionValue(
        conditions,
        'reference_id',
      );
      ruleItems.touchpointValue = extractConditionValue(
        conditions,
        'sub_reference_id',
      );
      break;
    case 'email_events_any_specific_email':
      ruleItems.journeyValue = extractConditionValue(
        conditions,
        'reference_id',
      );
      ruleItems.touchpointValue = extractConditionValue(
        conditions,
        'sub_reference_id',
      );
      break;
  }
  if (has(ruleItems, 'criteriaValue')) {
    ruleItems.criteriaValue = criteriaValue;
    ruleItems.criteriaLabel =
      ABTAttributeKey &&
      ABT_ATTRIBUTE_KEY_SET[ABTAttributeKey].criteriaValues.find(
        ({value}) => value === criteriaValue,
      )?.label;
  }
  if (has(ruleItems, 'fromDate')) {
    ruleItems.fromDate = extractConditionValue(conditions, 'start_date');
  }
  if (has(ruleItems, 'toDate')) {
    ruleItems.toDate = extractConditionValue(conditions, 'end_date');
  }
  return {
    id: uniqueId('rule'),
    ruleType: 'abt_rule',
    abtAttributeValue: ABTAttributeKey,
    entityType: conditions[0].field.entity_type,
    baseEntityType: conditions[0].field.base_entity_type,
    relatedEntitiesArray: getRelatedEntitiesForParsing(conditions),
    ...ruleItems,
  };
};

export const parseAudienceRule = (rule: AudienceRuleApi): AudienceRule => {
  return {
    id: uniqueId('filterRule'),
    //(diwakersurya) zipcode rules have separate operator on ui for radius
    operator: getClientOperator(rule),
    value: rule.value,
    field: rule.field,
    offset: rule.offset,
    meta: rule.metadata,
    include_null: rule.include_null === true ? true : false,
  };
};

export const getClientOperator = (rule: AudienceRuleApi): string | boolean => {
  if (rule.metadata?.source) {
    return 'in_radius';
  }
  if (rule.operator === 'eq' && typeof rule.value === 'boolean') {
    return rule.value;
  }
  if (rule.operator === 'eq' && rule.value === '') {
    return 'empty';
  }
  return rule.operator;
};

export const normalizeAudienceBlocks = (
  json: AudienceBlocks,
): AudienceBlocksApi => {
  const {inclusions, exclusions, all} = json;
  //if all is true, no exclusion, no inclusion
  if (all) {
    return {
      all,
      inclusions: null,
      exclusions: null,
    };
  }
  const inclusionBlocks = ([...inclusions.values()] ?? []).reduce(
    (acc, rules) => {
      const conditions = rules.reduce((innerAcc, rule) => {
        let normalizedRule;
        //NOTE(Vish): Handling DSL creation for action type rule differently.
        if (isActionTypeRule(rule)) {
          normalizedRule = normalizeActionRule(rule);
        }
        //NOTE(Vish): Handling regular audience rule
        else {
          normalizedRule = normalizeAudienceRule(rule);
        }
        if (normalizedRule) {
          innerAcc.push(
            isActionTypeRule(rule)
              ? {conditions: normalizedRule}
              : {conditions: [normalizedRule]},
          );
        }
        return innerAcc;
      }, []);
      if (conditions.length > 0) {
        acc.push({rules: conditions});
      }
      return acc;
    },
    [],
  );
  const exclusionBlocks = ([...exclusions.values()] ?? []).reduce(
    (acc, rules) => {
      //NOTE:(diwakersurya) for exclusions, each rule line becomes a block in backend instead of
      //single block with multiple rule lines because backend supports or only
      //at block level and exclusion rule lines require or between them

      rules.forEach((rule) => {
        let normalizedRule;
        if (isActionTypeRule(rule)) {
          normalizedRule = normalizeActionRule(rule);
        } else {
          normalizedRule = normalizeAudienceRule(rule);
        }
        if (normalizedRule) {
          acc.push({
            rules: [
              {
                conditions: isActionTypeRule(rule)
                  ? normalizedRule
                  : [normalizedRule],
              },
            ],
          });
        }
      });
      return acc;
    },
    [],
  );
  return {
    all,
    // $FlowFixMe
    inclusions: {blocks: inclusionBlocks},
    exclusions: {
      // $FlowFixMe
      blocks: exclusionBlocks.length > 0 ? exclusionBlocks : [],
    },
  };
};

/*
Note(Vish): This function traverses the Entity Metadata Object and returns all the possible paths (in the form of parent keys)
to the provided entityType in a dfs manner. This behaviour might be modified later if the UX provides a way to zero in on the specific
entity path.
*/
function getRelationBasedOnEntity(entityObject, entityType, acc, subAcc = []) {
  for (const subObj of Object.keys(entityObject)) {
    if (!isEmpty(entityObject[subObj].relatedEntities)) {
      subAcc.push(subObj);
      getRelationBasedOnEntity(
        entityObject[subObj].relatedEntities,
        entityType,
        acc,
        subAcc,
      );
    } else {
      if (entityObject[subObj].entityType === entityType) {
        acc.push([...subAcc, subObj]);
      }
    }
  }
  subAcc.splice(-1);
}

const getRelationForEntity = (
  baseEntityType: ?string,
  entityType: ?string,
  entityObject: EntityMetadataObject,
): Array<string> => {
  //TODO(vish): Write logic to derive this from entity metadata.
  const result = [];
  let relation = [];
  if (entityObject) {
    getRelationBasedOnEntity(entityObject.relatedEntities, entityType, result);
    //Note(Vish): This is a temp fix to get the smallest relation chain.
    const relations = result.sort((a, b) => a.length - b.length);
    relation = relations.length > 0 ? relations[0] : [];
  }
  return relation;
};

const normalizeActionRule = (rule) => {
  const DSL_STRUCTURE = {
    operator: 'eq',
    value: null,
    field: {
      attribute_name: null,
      base_entity_type:
        rule.baseEntityType != null ? rule.baseEntityType : null,
      entity_type: rule.entityType != null ? rule.entityType : null,
      from_relations: [],
    },
    include_null: false,
  };
  const rulesArray = [];
  const dslHelperArray = rule.abtAttributeValue
    ? ABT_ATTRIBUTE_KEY_SET[rule.abtAttributeValue].dslCreatorHelper
    : [];
  for (const helpObject of dslHelperArray) {
    const dslNode = cloneDeep(DSL_STRUCTURE);
    let shouldPushNode = true;
    for (const dslProp of Object.keys(helpObject)) {
      const [valueSource, valuePointer, objectLevel] = helpObject[dslProp];
      const value =
        valueSource === 'fromRule' ? rule[valuePointer] : valuePointer;
      if (value == null) {
        shouldPushNode = false;
      }
      if (objectLevel != null) {
        dslNode[objectLevel][dslProp] = value;
      } else {
        dslNode[dslProp] = value;
      }
    }
    if (shouldPushNode) {
      rulesArray.push(dslNode);
    }
  }
  return rulesArray;
};

export const normalizeAudienceRule = (rule: AudienceRule): ?AudienceRuleApi => {
  if (rule.operator != null) {
    let operator = rule.operator;
    let value = rule.value != null && rule.value;
    if (rule.operator && VALUE_NOT_REQUIRED_OPERATORS.includes(rule.operator)) {
      value = null;
    }
    if (rule.operator === 'in_radius') {
      operator = 'in';
    }
    if (
      typeof rule.operator !== 'undefined' &&
      [true, false].includes(rule.operator)
    ) {
      operator = 'eq';
      value = rule.operator;
    }
    if (rule.operator === 'empty') {
      operator = 'eq';
      value = '';
    }

    return {
      //$FlowFixMe
      field: rule.field,
      //$FlowFixMe
      value,
      operator,
      //TODO:(diwakersurya) fix me later
      metadata:
        rule.operator === 'in_radius'
          ? //$FlowFixMe
            {...rule.meta, type: 'distance', operator: 'search'}
          : null,
      offset: rule.offset != null ? rule.offset : null,
      include_null: rule.include_null === true ? true : false,
    };
  }
  return null;
};

export const DEFAULT_RENDER_CONFIG = {
  'createMode.header': 'Add',
  'review.confirm.button.label': 'Confirm and Publish',
  header: 'Create New List',
  'rules.intro.content': '',
  disableEntityTypeSelection: false,
  hideSaveAsDraft: false,
  showEditDetailsButtonOnReview: true,
  associatedResourceLabel: 'journey',
};

export const overrideRenderConfig = (
  newDisplayConfig: $Shape<typeof DEFAULT_RENDER_CONFIG>,
): $Shape<typeof DEFAULT_RENDER_CONFIG> => ({
  ...DEFAULT_RENDER_CONFIG,
  ...newDisplayConfig,
});

const getWorkflowTypeAssociatedResources = (
  associatedResources: AssociatedResources,
): AssociatedResources =>
  associatedResources.filter((resource) => resource.type === 'workflow');

export const getAssociatedJourneys = (
  associatedResources: AssociatedResources,
  additionalFilter?: (AssociatedResource) => boolean,
): AssociatedResources =>
  additionalFilter
    ? getWorkflowTypeAssociatedResources(associatedResources).filter(
        additionalFilter,
      )
    : getWorkflowTypeAssociatedResources(associatedResources);

export const getActiveAssociatedJourneys = (
  associatedResources: AssociatedResources,
  workflows: {[string]: Workflow},
): AssociatedResources =>
  getAssociatedJourneys(associatedResources, ({id}) =>
    workflows[id] ? workflows[id].active : false,
  );

export const getInactiveAssociatedJourneys = (
  associatedResources: AssociatedResources,
  workflows: {[string]: Workflow},
): AssociatedResources =>
  getAssociatedJourneys(associatedResources, ({id}) =>
    workflows[id] ? !workflows[id].active : true,
  );

const getAutomationWorkflowTypeAssociatedResources = (
  associatedResources: AssociatedResources,
): AssociatedResources =>
  associatedResources.filter(
    (resource) => resource.type === 'automation_workflow',
  );

export const getAssociatedWorkflows = (
  associatedResources: AssociatedResources,
  additionalFilter?: (AssociatedResource) => boolean,
): AssociatedResources =>
  additionalFilter
    ? getAutomationWorkflowTypeAssociatedResources(associatedResources).filter(
        additionalFilter,
      )
    : getAutomationWorkflowTypeAssociatedResources(associatedResources);

export const createRuleHash = (rule: AudienceRule): string => {
  //these fields determine uniqueness of a rule
  if (isActionTypeRule(rule)) {
    const hash = omit(rule, ['id', 'ruleType']);
    return hashObject(sortObject(hash));
  } else {
    //$FlowFixMe[prop-missing]
    const {field, operator, value} = rule;
    return hashObject({field: sortObject(field), operator, value});
  }
};

export const createRulesHash = (rules: AudienceRule[]): string =>
  hashObject(
    rules.reduce((acc, rule) => {
      acc[createRuleHash(rule)] = true;
      return acc;
    }, {}),
  );

export const getDefaultExclusionBlock = (): Map<string, AudienceRule[]> =>
  new Map([[createExclusionBlockIdentifier(), []]]);
export const getDefaultInclusionBlock = (): Map<string, AudienceRule[]> =>
  new Map([[createInclusionBlockIdentifier(), []]]);

export const mergeListRules = (
  lists: AudienceListApiResponse[],
): {
  all: boolean,
  inclusions: Map<string, AudienceRule[]>,
  exclusions: Map<string, AudienceRule[]>,
} => {
  const mergedRules = lists.reduce(
    (acc, list) => {
      const {inclusions, exclusions, all} = parseAudienceBlocks(list.rule);
      if (all) {
        //if any list contains all true that means all true;
        acc.all = true;
      }
      for (const [_, value] of inclusions) {
        acc.inclusions.set(createInclusionBlockIdentifier(), value);
      }

      for (const [_, value] of exclusions) {
        if (value.length > 0) {
          acc.exclusions.set(createExclusionBlockIdentifier(), value);
        }
      }
      return acc;
    },
    {all: false, inclusions: new Map(), exclusions: new Map()},
  );
  if (mergedRules.all) {
    return {all: true, inclusions: new Map(), exclusions: new Map()};
  }
  if (mergedRules.exclusions.size === 0) {
    return {
      all: false,
      inclusions: mergedRules.inclusions,
      //empty exclusion block is shown on the ui even if
      //there is none.
      exclusions: getDefaultExclusionBlock(),
    };
  }

  return {
    all: false,
    inclusions: mergedRules.inclusions,
    //NOTE:(diwakersurya) all exclusion blocks are combined to form one exclusion block
    exclusions: new Map([
      [
        createExclusionBlockIdentifier(),
        [...mergedRules.exclusions.values()].flat(),
      ],
    ]),
  };
};

export const getAttributeIdentifierFromField = (rule: AudienceRule): string => {
  if (rule.field) {
    return rule.field.from_relations.length > 0
      ? `${rule.field.from_relations.join('/')}/${rule.field.attribute_name}`
      : rule.field.attribute_name;
  }
  return '';
};

export const isRuleValueEmpty = (rule: AudienceRule): boolean => {
  return (
    !!rule.operator &&
    ((!VALUE_NOT_REQUIRED_OPERATORS.includes(rule.operator) &&
      !rule.value &&
      rule.value !== 0) ||
      (ARRAY_OPERATORS.includes(rule.operator) &&
        !(Array.isArray(rule.value) && rule.value.length !== 0)))
  );
};

//NOTE:(diwakersurya) improvise later on if possible
export const validateAudienceBlocks = (
  json: AudienceBlocks,
  maxORNOTblocks: ?number,
  fields?: DynamicLabels,
): {
  blockHashCount: Map<string, number>,
  blockwiseRuleHashCount: Map<string, Map<string, number>>,
  duplicateBlockExists: boolean,
  duplicateRuleExistsInAnyBlock: boolean,
  invalidRuleExistsInAnyBlock: boolean,
  noRuleExists: boolean,
  doBlocksExceedMaxAllowedBlocks: boolean,
} => {
  const {all, inclusions, exclusions} = json;
  const allBlocks: Map<string, AudienceRule[]> = new Map([
    ...inclusions,
    ...exclusions,
  ]);
  // if two blocks lead to same hash due to same value for all the rule line items
  const blockHashCount: Map<string, number> = [...allBlocks.values()].reduce(
    (acc, rules) => {
      if (rules.length > 0) {
        const hash = createRulesHash(rules);
        const val = acc.get(hash);
        if (val) {
          return acc.set(hash, val + 1);
        }
        acc.set(hash, 1);
      }
      return acc;
    },
    new Map(),
  );
  //duplicate rules will have the same hash. this is a map which stores
  // no of rules having the same hash to detect duplicates
  // map (block id,map(ruleHash,count))
  const blockwiseRuleHashCount = [...allBlocks.entries()].reduce(
    (outerAccumulator, [blockId, rules]) => {
      outerAccumulator.set(
        blockId,
        rules.reduce((acc, rule) => {
          const hash = createRuleHash(rule);
          const val = acc.get(hash);
          if (val) {
            return acc.set(hash, val + 1);
          }
          return acc.set(hash, 1);
        }, new Map()),
      );
      return outerAccumulator;
    },
    new Map(),
  );

  const duplicateBlockExists = [...blockHashCount.values()].some(
    (count) => count > 1,
  );
  const duplicateRuleExistsInAnyBlock = [
    ...blockwiseRuleHashCount.values(),
  ].some((ruleHashCount) =>
    [...ruleHashCount.values()].some((count) => count > 1),
  );

  const invalidRuleExistsInAnyBlock =
    all === false &&
    [...allBlocks.values()].some((rules) =>
      rules.some((rule) => {
        if (!isActionTypeRule(rule)) {
          if (typeof rule.operator === 'boolean') {
            return false;
          }
          if (!rule.operator) {
            return true;
          }
          if (isRuleValueEmpty(rule)) {
            return true;
          }
          const attributeIdentifier = getAttributeIdentifierFromField(rule);
          const isZipcodeField =
            rule.field && rule.field.attribute_name && fields
              ? getIsZipcodeField(attributeIdentifier, fields)
              : false;
          if (
            isZipcodeField &&
            Array.isArray(rule.value) &&
            rule.value.length === 0
          ) {
            return true;
          }
          return false;
        }
        return isCriteriaBlockNotFilled(rule) || isScopeBlockNotFilled(rule);
      }),
    );

  const noRuleExists =
    all === false &&
    [...inclusions.values()].every((rules) => rules.length === 0);

  const doBlocksExceedMaxAllowedBlocks =
    maxORNOTblocks != null && all === false && inclusions.size > maxORNOTblocks;

  return {
    blockHashCount,
    blockwiseRuleHashCount,
    duplicateBlockExists,
    duplicateRuleExistsInAnyBlock,
    invalidRuleExistsInAnyBlock,
    noRuleExists,
    doBlocksExceedMaxAllowedBlocks,
  };
};

export const isAbtRule = (id: string): boolean => {
  return ABT_VALUES.has(id);
};

export const isActionTypeRule = (rule: AudienceRule): boolean => {
  if (rule.ruleType) {
    return rule.ruleType === 'abt_rule';
  }
  return false;
};

export const getCombinedRuleItems = (
  attributeValue: string,
): CombinedActionAudienceRule => {
  const initialRuleObject =
    ABT_ATTRIBUTE_KEY_SET[attributeValue].initialRuleItems;
  return {...initialRuleObject.criteriaField, ...initialRuleObject.scopeField};
};

export const getRuleItemsBasedOnAttributeValue = (
  attributeValue: string,
  entityType: string,
  baseEntityType: string,
  entityObject: EntityMetadataObject,
): ActionTypeAudienceRule => {
  const specificRuleItems = getCombinedRuleItems(attributeValue);
  const relatedEntitiesArray = getRelationForEntity(
    baseEntityType,
    entityType,
    entityObject,
  );
  return {
    id: uniqueId('rule'),
    ruleType: 'abt_rule',
    abtAttributeValue: attributeValue,
    entityType,
    baseEntityType,
    relatedEntitiesArray,
    ...specificRuleItems,
  };
};

//Needed in case of edit
export const deriveJourneyLabelFromValue = (
  journeyValue: ?string,
  workflows: {[string]: Workflow},
): string => {
  if (journeyValue && workflows && workflows[journeyValue]) {
    return workflows[journeyValue].name;
  }
  return '';
};

//Needed in case of edit
export const deriveTouchpointLabelFromValue = (
  touchpointValue: ?string,
  eventData: ?{[eventId: string]: SenseApiEvent, ...},
): string => {
  if (touchpointValue && eventData) {
    const touchpointObject = eventData[touchpointValue];
    if (touchpointObject) {
      return touchpointObject.title;
    }
    return '';
  }
  return '';
};

const deriveModuleDataFromQuestion = (modules, question) => {
  if (modules && Array.isArray(modules) && modules.length > 0) {
    for (const module of modules) {
      if (module.id === question) {
        return module;
      }
      if (
        module.branches &&
        Array.isArray(module.branches) &&
        module.branches.length > 0
      ) {
        for (const branch of module.branches) {
          const result = deriveModuleDataFromQuestion(branch.modules, question);
          if (result) {
            return result;
          }
        }
      }
    }
  }
};

export const deriveQuestionLabelFromValue = (
  eventData: ?{[eventId: string]: SenseApiEvent, ...},
  questionValue: string,
  touchpointValue: string,
): string => {
  if (eventData && eventData[touchpointValue]) {
    const moduleData = deriveModuleDataFromQuestion(
      eventData[touchpointValue].modules,
      questionValue,
    );
    if (moduleData?.question) {
      return moduleData.question;
    }
  }
  return '';
};

export const getCriteriaDisplayValue = (
  rule: AudienceRule,
  workflows: {[string]: Workflow},
  eventData: ?{[eventId: string]: SenseApiEvent, ...},
): string => {
  //TODO(Vish): Complete this map for all abt attributes
  if (rule.abtAttributeValue) {
    switch (rule.abtAttributeValue) {
      case 'email_events_any_specific_email':
        if (rule.touchpointLabel && rule.criteriaLabel) {
          return `${rule.touchpointLabel} ${rule.criteriaLabel}`;
        } else if (
          rule.journeyValue &&
          rule.touchpointValue &&
          rule.criteriaLabel
        ) {
          const touchpointLabel = deriveTouchpointLabelFromValue(
            rule.touchpointValue,
            eventData,
          );
          return `${touchpointLabel} ${rule.criteriaLabel ?? ''}`;
        } else {
          return 'Select Criteria and Email';
        }
      case 'survey_events_any_specific_survey':
        if (rule.touchpointLabel && rule.criteriaLabel) {
          return `${rule.touchpointLabel} ${rule.criteriaLabel}`;
        } else if (
          rule.journeyValue &&
          rule.touchpointValue &&
          rule.criteriaLabel
        ) {
          const touchpointLabel = deriveTouchpointLabelFromValue(
            rule.touchpointValue,
            eventData,
          );
          return `${touchpointLabel} ${rule.criteriaLabel ?? ''}`;
        } else {
          return 'Select Criteria and Survey';
        }
      case 'survey_events_any_specific_survey_question':
        if (rule.questionValue && rule.touchpointValue) {
          return (
            deriveQuestionLabelFromValue(
              eventData,
              rule.questionValue,
              rule.touchpointValue,
            ) ?? 'Select Survey Question'
          );
        }
        return 'Select Survey Question';
      case 'link_events_any_specific_external_link':
        if (rule.linkValue && rule.criteriaLabel) {
          return `${rule.linkValue} ${rule.criteriaLabel}`; // Todo(Vish): Link value needs to be shortened to be displayed, refer figma (improvement)
        }
        return 'Select Criteria and Link';
      default:
        return '';
    }
  }
  return '';
};

const getScopeDisplayValueForSurveySpecificQuestion = (rule) => {
  if (
    rule.touchpointValue &&
    rule.questionValue &&
    rule.fromDate &&
    rule.toDate
  ) {
    const fromDate = String(rule.fromDate);
    const toDate = String(rule.toDate);
    if (rule.keywords && rule.keywords.length > 0) {
      return `responded with ${rule.keywords.join(
        ', ',
      )} from ${fromDate} to ${toDate}`;
    }
    if (rule.npsValue && rule.npsOperator) {
      return `responded with ${String(
        getNpsOperatorLabel(rule.npsOperator),
      )} ${String(rule.npsValue)} from ${fromDate} to ${toDate}`;
    }
    if (rule.multipleChoices && rule.multipleChoices.length > 0) {
      return `responded with one of ${rule.multipleChoices.join(
        ', ',
      )} from ${fromDate} to ${toDate}`;
    }
  }
  return 'Select Criteria and Scope';
};

export const getScopeDisplayValue = (rule: AudienceRule): string => {
  const abtAttributeScopeMap = {
    email_events_any_specific_email:
      rule.fromDate && rule.toDate
        ? `from ${rule.fromDate} to ${rule.toDate}`
        : 'Select Scope',
    survey_events_any_specific_survey:
      rule.fromDate && rule.toDate
        ? `from ${rule.fromDate} to ${rule.toDate}`
        : 'Select Scope',
    survey_events_any_specific_survey_question:
      getScopeDisplayValueForSurveySpecificQuestion(rule),
    link_events_any_specific_external_link:
      rule.fromDate && rule.toDate
        ? `from ${rule.fromDate} to ${rule.toDate}`
        : 'Select Scope',
  };
  if (rule.abtAttributeValue) {
    return abtAttributeScopeMap[rule.abtAttributeValue];
  }
  return '';
};

const getCriteriaFieldsToOmit = (abtAttributeValue) => {
  switch (abtAttributeValue) {
    case 'survey_events_any_specific_survey_question':
      return ['questionLabel', 'touchpointLabel', 'journeyLabel'];
    default:
      return ['journeyLabel', 'touchpointLabel'];
  }
};

const getScopeFieldsToOmit = (abtAttributeValue, rule) => {
  const defaultFields = ['scopeLabel', 'scopeValue'];
  switch (abtAttributeValue) {
    case 'survey_events_any_specific_survey_question':
      if (rule.multipleChoices && rule.multipleChoices.length > 0) {
        return [...defaultFields, 'npsOperator', 'npsValue', 'keywords'];
      }
      if (rule.keywords && rule.keywords.length > 0) {
        return [...defaultFields, 'npsOperator', 'npsValue', 'multipleChoices'];
      }
      if (rule.npsValue && rule.npsOperator) {
        return [...defaultFields, 'multipleChoices', 'keywords'];
      }
      return defaultFields;
    default:
      return defaultFields;
  }
};

export const isCriteriaBlockNotFilled = (rule: AudienceRule): boolean => {
  if (rule.abtAttributeValue) {
    const criteriaBlockItems =
      ABT_ATTRIBUTE_KEY_SET[rule.abtAttributeValue].initialRuleItems
        .criteriaField;
    const criteriaFieldsToOmit = getCriteriaFieldsToOmit(
      rule.abtAttributeValue,
    );
    const itemsToCheck = omit(criteriaBlockItems, criteriaFieldsToOmit);
    return some(Object.keys(itemsToCheck), (item) => rule[item] == null);
  }
  return false;
};

export const isScopeBlockNotFilled = (rule: AudienceRule): boolean => {
  if (rule.abtAttributeValue) {
    const scopeBlockItems =
      ABT_ATTRIBUTE_KEY_SET[rule.abtAttributeValue].initialRuleItems.scopeField;
    const scopeFieldsToOmit = getScopeFieldsToOmit(
      rule.abtAttributeValue,
      rule,
    );
    const itemsToCheck = omit(scopeBlockItems, scopeFieldsToOmit);
    return some(Object.keys(itemsToCheck), (item) => rule[item] == null);
  }
  return false;
};

export const shouldShowMemberPreview = (status: string): boolean => {
  return ['live', 'published', 'draft', 'building'].includes(status);
};

const isABTRuleFromDSL = (conditions) => {
  let result = false;
  let AbtExhaustiveCriteriaList = [];
  for (const abtAttributeType of Object.keys(ABT_ATTRIBUTE_KEY_SET)) {
    const attributeCriteriaList =
      ABT_ATTRIBUTE_KEY_SET[abtAttributeType].criteriaList;
    AbtExhaustiveCriteriaList = [
      ...AbtExhaustiveCriteriaList,
      ...attributeCriteriaList,
    ];
  }
  for (const {field} of conditions) {
    if (AbtExhaustiveCriteriaList.includes(field.attribute_name)) {
      result = true;
      break;
    }
  }
  return result;
};
export const cloneRuleWithoutValues = (
  rules: AudienceRule[],
): AudienceRule[] => {
  return rules.map((rule) => {
    if (isActionTypeRule(rule) && rule.abtAttributeValue) {
      const specificRuleItems = getCombinedRuleItems(rule.abtAttributeValue);
      return {
        id: uniqueId('rule'),
        ruleType: 'abt_rule',
        abtAttributeValue: rule.abtAttributeValue,
        entityType: rule.entityType,
        baseEntityType: rule.baseEntityType,
        relatedEntitiesArray: cloneDeep(rule.relatedEntitiesArray),
        ...specificRuleItems,
      };
    }
    return {
      id: uniqueId('filterRule'),
      //$FlowFixMe
      field: cloneDeep(rule.field),
      operator: rule.operator && rule.operator,
      include_null: null,
      meta: null,
      offset: null,
    };
  });
};

export const getQuestionType = (
  touchpointValue: ?string,
  questionValue: ?string,
  eventData: ?{[eventId: string]: SenseApiEvent, ...},
): string => {
  if (touchpointValue && questionValue) {
    const moduleData = deriveModuleDataFromQuestion(
      eventData?.[touchpointValue].modules,
      questionValue,
    );
    if (moduleData?.module_type) {
      return moduleData.module_type;
    }
  }
  return '';
};

export const getAllQuestions = (
  touchpointValue: ?string,
  questionValue: ?string,
  eventData: ?{[eventId: string]: SenseApiEvent, ...},
): string[] => {
  if (
    touchpointValue &&
    questionValue &&
    eventData &&
    !isEmpty(eventData) &&
    !isEmpty(eventData[touchpointValue]) &&
    Array.isArray(eventData[touchpointValue].modules)
  ) {
    const moduleData = deriveModuleDataFromQuestion(
      eventData[touchpointValue].modules,
      questionValue,
    );
    if (moduleData && moduleData.choices) {
      return moduleData.choices;
    }
    return [];
  }
  return [];
};

export const NPS_OPERATOR_OPTIONS = [
  {value: 'eq', label: 'Equal to'},
  {value: 'gt', label: 'Greater than'},
  {value: 'lt', label: 'Less than'},
  {value: 'gte', label: 'Greater than or equal to'},
  {value: 'lte', label: 'Less than or equal to'},
];

export const getNpsOperatorLabel = (operator: string): ?string =>
  NPS_OPERATOR_OPTIONS.find(({value}) => value === operator)?.label;

export const getNpsValues = (): {label: string, value: string}[] => {
  const valueArray = [];
  for (let i = 0; i <= 10; i++) {
    valueArray.push({label: String(i), value: String(i)});
  }
  return valueArray;
};

export const getJourneyIdForFetchingEvents = (
  journeyValue: ?string,
): ?string => {
  if (journeyValue != null) {
    return journeyValue;
  }
  return null;
};

export const getSupportedActionEntityAttributes = (
  actionEntityAttributes: ?(EntityMetadataEntityAttributes[]),
  abtAttributeType: string,
): {label: string, value: string}[] =>
  actionEntityAttributes && !isEmpty(actionEntityAttributes)
    ? ABT_ATTRIBUTE_KEY_SET[abtAttributeType]['criteriaValues'].filter(
        ({value}) =>
          actionEntityAttributes.some(
            (attribute) => attribute.attributeName === value,
          ),
      )
    : [];

const surveyTokens = [surveyContentToken, surveyLinkEmailToken];

export const eventContainsSurveyBlock = (
  event: SenseApiEvent,
  tokens: string[] = surveyTokens,
): boolean => {
  if (event.beefree_json != null) {
    const doc =
      typeof event.beefree_json === 'object'
        ? JSON.stringify(event.beefree_json)
        : event.beefree_json;
    return (
      tokens.reduce((sum, token) => checkForString(doc, token) + sum, 0) > 0
    );
  }
  return false;
};

export const doesTouchpointBelongToThisAction = (
  abtAttributeValue: string,
  sendType: ?string,
  eventType: ?string,
  event?: ?SenseApiEvent,
): boolean => {
  const supported_send_types =
    ABT_ATTRIBUTE_KEY_SET[abtAttributeValue].supportedTouchpointTypes.send_type;
  const supported_event_types =
    ABT_ATTRIBUTE_KEY_SET[abtAttributeValue].supportedTouchpointTypes
      .event_type;
  //NOTE(Vish) : Handling the case when survey is not present in beefree touchpoint
  if (
    event &&
    (abtAttributeValue === 'survey_events_any_specific_survey_question' ||
      abtAttributeValue === 'survey_events_any_specific_survey') &&
    eventType === 'beefree_email'
  ) {
    return eventContainsSurveyBlock(event);
  }
  return (
    supported_send_types.some((type) => type === sendType) ||
    supported_event_types.some((type) => type === eventType)
  );
};

export const getAssociatedTouchpoints = (
  abtAttributeValue: string,
  workflowData: ?ApiWorkflow,
): Array<{label: string, value: string}> => {
  let result = [];
  if (workflowData && !isEmpty(workflowData)) {
    result = workflowData.events_summary?.reduce((acc, touchpoint) => {
      if (
        doesTouchpointBelongToThisAction(
          abtAttributeValue,
          touchpoint.send_type,
          touchpoint.event_type,
          workflowData.events[touchpoint.id],
        )
      ) {
        return acc.concat({
          label: touchpoint.title,
          value: touchpoint.id,
        });
      }
      return acc;
    }, []);
  }
  return result || [];
};

const getAssociatedQuestionsHelper = (modules, acc) => {
  if (modules && Array.isArray(modules) && modules.length > 0) {
    for (const module of modules) {
      if (ABT_SUPPORTED_SURVEY_QUESTIONS.includes(module.module_type)) {
        const question = {
          label: module.question ? module.question : '',
          value: module.id ? module.id : '',
        };
        acc.push(question);
      }
      if (
        module.branches &&
        Array.isArray(module.branches) &&
        module.branches.length > 0
      ) {
        for (const branch of module.branches) {
          getAssociatedQuestionsHelper(branch.modules, acc);
        }
      }
    }
  }
};

export const getAssociatedQuestions = (
  selectedTouchpointId: ?string,
  eventData: ?{[eventId: string]: SenseApiEvent, ...},
): Array<{label: string, value: string}> => {
  if (!selectedTouchpointId) {
    return [];
  }
  if (
    eventData &&
    !isEmpty(eventData) &&
    !isEmpty(eventData[selectedTouchpointId])
  ) {
    const questions = [];
    getAssociatedQuestionsHelper(
      eventData[selectedTouchpointId].modules,
      questions,
    );
    return questions;
  }
  return [];
};

export const checkCriteriaValidity = (rule: AudienceRule): boolean => {
  if (rule.abtAttributeValue) {
    return ABT_ATTRIBUTE_KEY_SET[
      rule.abtAttributeValue
    ].requiredCriteriaKeys.every((key) => rule[key]);
  }
  return false;
};

export const checkScopeValidity = (rule: AudienceRule): boolean => {
  if (rule.abtAttributeValue) {
    return ABT_ATTRIBUTE_KEY_SET[
      rule.abtAttributeValue
    ].requiredScopeKeys.every((key) => rule[key]);
  }
  return false;
};

const isValidDateObject = (d) => d instanceof Date && !isNaN(d);

export const isABTDateSelectionValid = (
  min: string | null,
  max: string | null,
  fromDate: string,
  toDate: string,
  scopeValue: string,
): boolean => {
  const isValidFromDate = isValidDateObject(new Date(fromDate));
  let isValidToDate = false;
  if (scopeValue === 'specific_date_to_now' && toDate === 'now') {
    isValidToDate = true;
  } else {
    isValidToDate = isValidDateObject(new Date(toDate));
  }
  if (!isValidFromDate || !isValidToDate) {
    return false;
  }

  const from = new Date(fromDate);

  if (scopeValue === 'specific_date_to_now' && toDate === 'now') {
    const isMinDateValid = min == null || from >= new Date(min);
    const isMaxDateValid = max == null || from <= new Date(max);

    return isMinDateValid && isMaxDateValid;
  }

  const to = new Date(toDate);
  const isMinDateValid =
    min == null || (from >= new Date(min) && to >= new Date(min));
  const isMaxDateValid =
    max == null || (from <= new Date(max) && to <= new Date(max));

  return isMinDateValid && isMaxDateValid;
};

const getActionNameFromDSL = (conditions) => {
  const actionAttributeTypeToNameMapping = {
    email_events_any_specific_email: 'Email Action',
    survey_events_any_specific_survey: 'Survey Action',
    survey_events_any_specific_survey_question: 'Survey Question Action',
    link_events_any_specific_external_link: 'External Link Action',
  };
  for (const abtAttributeType of Object.keys(ABT_ATTRIBUTE_KEY_SET)) {
    const attributeCriteriaList =
      ABT_ATTRIBUTE_KEY_SET[abtAttributeType].criteriaList;
    for (const {field} of conditions) {
      if (attributeCriteriaList.includes(field.attribute_name)) {
        return actionAttributeTypeToNameMapping[abtAttributeType];
      }
    }
  }
  return null;
};
export const getTrackingProps = (
  rule: AudienceBlocksApi,
): {
  number_of_include_blocks: number,
  is_not_block_present: boolean,
  is_action_rule_present_in_list: boolean,
  list_of_actions_used_in_include_section: Array<?string>,
  list_of_actions_used_in_exclude_section: Array<?string>,
} => {
  const inclusionBlocks = rule.inclusions?.blocks;
  const exclusionBlock = rule.exclusions?.blocks;
  const inclusionBlocksLength = Array.isArray(inclusionBlocks)
    ? inclusionBlocks.length
    : 0;
  const exclusionBlocksLength = Array.isArray(exclusionBlock)
    ? exclusionBlock.length
    : 0;
  let isActionRuleUsed = false;
  const ActionListItemsIncludeSection: Array<?string> = [];
  const ActionListItemsExcludeSection: Array<?string> = [];
  if (inclusionBlocks && inclusionBlocksLength > 0) {
    for (const block of inclusionBlocks) {
      for (const innerRule of block.rules) {
        if (isABTRuleFromDSL(innerRule.conditions)) {
          isActionRuleUsed = true;
          ActionListItemsIncludeSection.push(
            getActionNameFromDSL(innerRule.conditions),
          );
        }
      }
    }
  }
  if (exclusionBlock && exclusionBlocksLength > 0) {
    for (const innerRule of exclusionBlock[0].rules) {
      if (isABTRuleFromDSL(innerRule.conditions)) {
        isActionRuleUsed = true;
        ActionListItemsExcludeSection.push(
          getActionNameFromDSL(innerRule.conditions),
        );
      }
    }
  }
  return {
    number_of_include_blocks: inclusionBlocksLength,
    is_not_block_present: exclusionBlocksLength > 0,
    is_action_rule_present_in_list: isActionRuleUsed,
    list_of_actions_used_in_include_section: ActionListItemsIncludeSection,
    list_of_actions_used_in_exclude_section: ActionListItemsExcludeSection,
  };
};

const getScopeDisplayValueForSurveySpecificQuestionGen = (rule) => {
  if (
    rule.touchpointValue &&
    rule.questionValue &&
    rule.fromDate &&
    rule.toDate
  ) {
    const fromDate = String(rule.fromDate);
    const toDate = String(rule.toDate);
    if (rule.keywords && rule.keywords.length > 0) {
      return `responded with ${rule.keywords.join(', ')}`;
    }
    if (rule.npsValue && rule.npsOperator) {
      return `responded with ${String(
        getNpsOperatorLabel(rule.npsOperator),
      )} ${String(rule.npsValue)}`;
    }
    if (rule.multipleChoices && rule.multipleChoices.length > 0) {
      return `responded with one of ${rule.multipleChoices.join(', ')}`;
    }
  }
  return '';
};

export const getAbtDisplayValues = (
  rule: AudienceRule,
  workflows: {[string]: Workflow},
  eventData: ?{[eventId: string]: SenseApiEvent, ...},
): string[] => {
  //TODO(Vish): Complete this map for all abt attributes
  if (rule.abtAttributeValue) {
    const result = [];
    switch (rule.abtAttributeValue) {
      case 'email_events_any_specific_email':
        if (rule.journeyValue) {
          result.push(
            `Journey - ${deriveJourneyLabelFromValue(
              rule.journeyValue,
              workflows,
            )}`,
          );
        }
        if (rule.touchpointValue) {
          result.push(
            `Touchpoint - ${deriveTouchpointLabelFromValue(
              rule.touchpointValue,
              eventData,
            )}`,
          );
        }
        if (rule.criteriaLabel) {
          result.push(`Criteria - ${rule.criteriaLabel}`);
        }
        if (rule.fromDate && rule.toDate) {
          result.push(`Scope - from ${rule.fromDate} to ${rule.toDate}`);
        }
        return result;
      case 'survey_events_any_specific_survey':
        if (rule.journeyValue) {
          result.push(
            `Journey - ${deriveJourneyLabelFromValue(
              rule.journeyValue,
              workflows,
            )}`,
          );
        }
        if (rule.touchpointValue) {
          result.push(
            `Touchpoint - ${deriveTouchpointLabelFromValue(
              rule.touchpointValue,
              eventData,
            )}`,
          );
        }
        if (rule.criteriaLabel) {
          result.push(`Criteria - ${rule.criteriaLabel}`);
        }
        if (rule.fromDate && rule.toDate) {
          result.push(`Scope - from ${rule.fromDate} to ${rule.toDate}`);
        }
        return result;
      case 'survey_events_any_specific_survey_question':
        if (rule.journeyValue) {
          result.push(
            `Journey - ${deriveJourneyLabelFromValue(
              rule.journeyValue,
              workflows,
            )}`,
          );
        }
        if (rule.touchpointValue) {
          result.push(
            `Touchpoint - ${deriveTouchpointLabelFromValue(
              rule.touchpointValue,
              eventData,
            )}`,
          );
        }
        if (rule.questionValue && rule.touchpointValue) {
          result.push(
            `Question - ${deriveQuestionLabelFromValue(
              eventData,
              rule.questionValue,
              rule.touchpointValue,
            )}`,
          );
          result.push(
            `Criteria - ${getScopeDisplayValueForSurveySpecificQuestionGen(
              rule,
            )}`,
          );
        }
        if (rule.fromDate && rule.toDate) {
          result.push(`Scope - from ${rule.fromDate} to ${rule.toDate}`);
        }
        return result;
      case 'link_events_any_specific_external_link':
        if (rule.journeyValue) {
          result.push(
            `Journey - ${deriveJourneyLabelFromValue(
              rule.journeyValue,
              workflows,
            )}`,
          );
        }
        if (rule.touchpointValue) {
          result.push(
            `Touchpoint - ${deriveTouchpointLabelFromValue(
              rule.touchpointValue,
              eventData,
            )}`,
          );
        }
        if (rule.linkValue) {
          result.push(`Link - ${rule.linkValue}`);
        }
        if (rule.criteriaLabel) {
          result.push(`Criteria - ${rule.criteriaLabel}`);
        }
        if (rule.fromDate && rule.toDate) {
          result.push(`Scope - from ${rule.fromDate} to ${rule.toDate}`);
        }
        return result;
      default:
        return [];
    }
  }
  return [];
};

export const doesValueExistInAnyRule = (rules: AudienceRule[]): boolean => {
  return rules.some((rule) => {
    if (isActionTypeRule(rule)) {
      /*Note(Vish): Rest all ABT events for now contain criteria as the defining value except survey question.
        In future if more ABT events are added this function should be modified accordingly
      */
      if (
        rule.abtAttributeValue === 'survey_events_any_specific_survey_question'
      ) {
        return has(rule, 'questionValue') && rule.questionValue != null;
      }
      return has(rule, 'criteriaValue') && rule.criteriaValue != null;
    }
    return has(rule, 'value') && rule.value != null;
  });
};

export const mergeListRulesAndGetListsMap = (
  lists: AudienceListApiResponse[],
): {
  audienceBlocks: {
    all: boolean,
    inclusions: Map<string, AudienceRule[]>,
    exclusions: Map<string, AudienceRule[]>,
  },
  listRuleIdMap: Map<string, string[]>,
} => {
  const listRuleIdMap = new Map<string, string[]>();
  const mergedRules = lists.reduce(
    (acc, list) => {
      const {inclusions, exclusions, all} = parseAudienceBlocks(list.rule);
      const ruleKeys = [];
      for (const block of inclusions.values()) {
        block.forEach(({id}) => ruleKeys.push(id));
      }
      for (const block of exclusions.values()) {
        block.forEach(({id}) => ruleKeys.push(id));
      }
      listRuleIdMap.set(list.id, ruleKeys);
      if (all) {
        //if any list contains all true that means all true;
        acc.all = true;
      }
      for (const [_, value] of inclusions) {
        acc.inclusions.set(createInclusionBlockIdentifier(), value);
      }

      for (const [_, value] of exclusions) {
        if (value.length > 0) {
          acc.exclusions.set(createExclusionBlockIdentifier(), value);
        }
      }
      return acc;
    },
    {all: false, inclusions: new Map(), exclusions: new Map()},
  );
  if (mergedRules.all) {
    return {
      audienceBlocks: {
        all: true,
        //empty inclusion and exclusion block is shown on the ui for include all
        inclusions: getDefaultInclusionBlock(),
        exclusions: getDefaultExclusionBlock(),
      },
      listRuleIdMap,
    };
  }
  if (mergedRules.exclusions.size === 0) {
    return {
      audienceBlocks: {
        all: false,
        inclusions: mergedRules.inclusions,
        //empty exclusion block is shown on the ui even if
        //there is none.
        exclusions: getDefaultExclusionBlock(),
      },
      //Note(Vish): ListRuleIdMap is required in genesis list builder to highlight corresponding rules for a list in the copy lists screen
      listRuleIdMap,
    };
  }

  return {
    audienceBlocks: {
      all: false,
      inclusions: mergedRules.inclusions,
      //NOTE:(diwakersurya) all exclusion blocks are combined to form one exclusion block
      exclusions: new Map([
        [
          createExclusionBlockIdentifier(),
          [...mergedRules.exclusions.values()].flat(),
        ],
      ]),
    },
    listRuleIdMap,
  };
};
export function placeholderValue(
  val?: ?(string | number | boolean),
  placeholder?: string = '--',
): string {
  if (val == null) {
    return placeholder;
  }
  return val.toString();
}

export const ruleContextMenuActions = (
  cloneBlock: () => mixed,
  removeBlock: () => mixed,
  rules: AudienceRule[],
): {label: string, key: string, iconLeft: string}[] => {
  const options = [];
  if (typeof cloneBlock === 'function') {
    options.push({label: 'Clone block', key: 'clone', iconLeft: 'clone'});
  }
  if (doesValueExistInAnyRule(rules)) {
    options.push({
      label: 'Clear all values',
      key: 'clear',
      iconLeft: 'eraser',
    });
  }

  if (typeof removeBlock === 'function') {
    options.push({
      label: 'Delete block',
      key: 'delete',
      iconLeft: 'trash-can',
    });
  }
  return options;
};

export const getRelativeDateDisplayBasedOnOffset = (offset: number): string => {
  const absoluteOffset = Math.abs(offset);
  return offset > 0
    ? `${absoluteOffset} days from now`
    : `${absoluteOffset} days ago`;
};

export const getRuleDisplayValue = (
  rule: AudienceRule,
  noValueText: string,
  dataType: string,
  type?: string,
): string | string[] => {
  const value = rule.value || rule.value === 0 ? rule.value : null;
  const offset = rule.offset ? rule.offset : 0;
  if (Array.isArray(value)) {
    if (type === 'main') {
      const valueCsv = value.length ? value.join(', ') : noValueText;
      /**
       * The text is being displayed inside a button where only 50-90 characters are visible;
       * the rest are truncated. However, large strings sometimes prevent truncation.
       * Slicing the string will make the DOM lighter.
       **/
      return valueCsv.slice(0, 100);
    }
    return value.length > 0 ? value.map(String) : noValueText;
  }
  if (value || value === 0) {
    if (dataType === 'date') {
      return offset
        ? getRelativeDateDisplayBasedOnOffset(offset)
        : String(value);
    }
    return String(value);
  }
  return noValueText;
};

export const isLocalValueEmpty = (value: string | string[]): boolean => {
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  if (typeof value === 'string') {
    return !value.trim();
  }
  return !value;
};

export const isRuleDisabled = (
  highlightText: string,
  value: string | string[],
): boolean => {
  if (highlightText.length > 0) {
    if (Array.isArray(value)) {
      return !value.some((val) =>
        val.toLowerCase().includes(highlightText.toLowerCase()),
      );
    }
    return !value.toLowerCase().includes(highlightText.toLowerCase());
  }
  return false;
};

export const isAllowEmptySupported = (
  dataType: string,
  operator: string,
): boolean => {
  if (
    ['string', 'email', 'phone'].includes(dataType) &&
    ALLOWS_EMPTY_STRING_OPERATORS.includes(operator)
  ) {
    return true;
  } else if (
    ['date'].includes(dataType) &&
    ALLOWS_EMPTY_DATE_OPERATORS.includes(operator)
  ) {
    return true;
  }
  return false;
};

export const getPaginatedEntries = (
  entries: VariableEntry[],
  pagination: PaginationData,
): VariableEntry[] => {
  return [...entries].slice(
    (pagination.currentPage - 1) * pagination.pageSize,
    pagination.currentPage * pagination.pageSize,
  );
};

export const getSortedEntries = (
  entries: VariableEntry[],
  sort: SortData,
): VariableEntry[] => {
  const sortingDirection =
    sort.sortOrder === 'asc' ? 1 : sort.sortOrder === 'desc' ? -1 : 0;
  return [...entries].sort((firstEntry, secondEntry) => {
    const firstEntrySortByValue = get(firstEntry, sort.sortBy);
    const secondEntrySortByValue = get(secondEntry, sort.sortBy);
    return (
      firstEntrySortByValue.localeCompare(secondEntrySortByValue) *
      sortingDirection
    );
  });
};

export const getAssociatedResourceLabel = (
  config: $Shape<{[string]: string | boolean | React.Node}>,
): ?string => {
  const {associatedResourceLabel} = config;
  if (typeof associatedResourceLabel === 'string') {
    return associatedResourceLabel;
  }
  return null; // or handle it in a way that suits your needs
};
