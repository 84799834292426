// TODO (kyle): this is a dead file. find all references to its state and remove them!

// @noflow

import type {
  Contact,
  ContactList,
  EditingContactList,
} from 'src/types/contacts';


export type State = {
  editing: ?EditingContactList,
  adding: ?{
    contact: Contact,
    errors?: Object,
  },
  lists: {
    [listId: string]: ContactList,
  },
};

export default (
  state: State = {
    editing: null,
    adding: null,
    lists: {},
  },
  action: ContactListAction,
) => state;
