// @flow strict

export function groupBy<V, K>(
  iterable: Iterable<V>,
  predicate: (item: V, index: number) => K,
): Map<K, Array<V>> {
  const map = new Map();
  let index = 0;
  for (const v of iterable) {
    const key = predicate(v, index);
    let list = map.get(key);
    if (!list) {
      list = [];
      map.set(key, list);
    }
    list.push(v);
    index++;
  }
  return map;
}

export function keyBy<V, K>(
  iterable: Iterable<V>,
  predicate: (item: V, index: number) => K,
): Map<K, V> {
  const map = new Map();
  let index = 0;
  for (const value of iterable) {
    const key = predicate(value, index);
    map.set(key, value);
    index++;
  }
  return map;
}

interface ConstructableIterable<+Y> {
  constructor(iterable: ?Iterable<Y>): void;
  @@iterator(): $Iterator<Y, void, void>;
}

export function sortBy<V, I: ConstructableIterable<V>>(
  iterable: I,
  predicate: (V, V) => number,
): I {
  if (Array.isArray(iterable)) {
    return iterable.slice().sort(predicate);
  } else {
    return new iterable.constructor(Array.from(iterable).sort(predicate));
  }
}

export function* flat<V>(iterable: Iterable<Iterable<V>>): Iterable<V> {
  for (const list of iterable) {
    for (const item of list) {
      yield item;
    }
  }
}
