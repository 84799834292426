// @flow strict

import type {DisplayConfig} from 'src/types/display-config';
import type {Action} from 'src/action-creators/display-config';

import {RECEIVE_CONFIG} from 'src/action-creators/display-config';


export type State = DisplayConfig;

const initialState: State = {
  people_on_assignment_chart: true,
  placement_start_end_chart: true,
  redeployment_grid_chart: true,
  placement_duration_chart: true,
  analytics_performance_redeploys: true,
  analytics_performance_days_to_redeploy: true,
  analytics_performance_months_assigned: true,
  list_power_features_enabled: false,
  list_abt_enabled: false,
  is_trm_customer: false,
  enable_workflow: false,
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case RECEIVE_CONFIG:
      return {
        ...state,
        ...action.payload,
      };
  }
  return state;
};
