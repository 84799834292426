// @flow strict

import type {EmailAction} from 'src/action-creators/email';
//$FlowFixMe[nonstrict-import]
import type {
  //$FlowFixMe[nonstrict-import]
  CompleteEmailState,
  EmailAttachment,
} from 'src/types/email';

import {
  RECEIVE_EMAIL_INFO,
  UPDATE_EMAIL_INFO,
  RESET,
  RECEIVE_COMPLETE_EMAIL_INFO,
} from 'src/action-creators/email';


export type EmailState = {
  id: ?number,
  task_group_id: ?number,
  subject: ?string,
  content_json: ?string,
  content_html: ?string,
  from_name: ?string,
  from_email: ?string,
  to_email: ?string,
  cc: Array<string>,
  bcc: Array<string>,
  content_subscription_category_id: number,
  content_subscription_reason: ?string,
  skip_subscription_link: boolean,
  attachments: Array<EmailAttachment>,
  completeEmailState?: ?CompleteEmailState,
  chatbotsAttached: Array<number>,
};

const initialState = {
  id: null,
  task_group_id: null,
  subject: null,
  content_json: '{}',
  content_html: null,
  from_name: null,
  from_email: null,
  to_email: null,
  cc: [],
  bcc: [],
  attachments: [],
  // We are initializing this as 4, since default marketing category id is 4.
  content_subscription_category_id: 4,
  content_subscription_reason: null,
  skip_subscription_link: false,
  completeEmailState: null,
  chatbotsAttached: [],
};

export default (
  state: EmailState = initialState,
  action: EmailAction,
): EmailState => {
  switch (action.type) {
    case UPDATE_EMAIL_INFO:
      const updatePayload = action.payload;
      return {
        ...state,
        ...updatePayload,
      };
    case RECEIVE_EMAIL_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case RECEIVE_COMPLETE_EMAIL_INFO:
      return {
        ...state,
        completeEmailState: action.payload,
      };
    case RESET:
      return {...initialState};
  }
  return state;
};
