// @flow strict

import type {LeadUpdateBody} from 'src/types/landing-page';

import {SAVE_ORIGINAL_LEAD_DATA} from 'src/action-creators/leads';

import type {LeadsActions} from 'src/action-creators/leads';


export type LeadsState = {
  lead_current_state: string,
};

const initialState = {
  lead_current_state: null,
};

export default (
  // $FlowFixMe[prop-missing]
  // $FlowFixMe[incompatible-type]
  state: LeadsState = initialState,
  action: LeadsActions,
): LeadsState => {
  switch (action.type) {
    case SAVE_ORIGINAL_LEAD_DATA: {
      return {
        ...state,
        // $FlowFixMe[incompatible-return]
        lead_current_state: action.payload,
      };
    }
    default:
      return state;
  }
};
