//@flow strict
import omit from 'lodash/omit';
import isPlainObject from 'lodash/isPlainObject';
import type {
  DynamicTaskObjectModules,
  ABTVariables,
  SimpleABTVariable,
} from 'src/components/lib/automation-module-registry/automation-module-registry';


const specialFields = ['type'];

export const ENTITY_TYPE_EVENT_MAP = {
  email_send: 'action_email_v2',
  chatbot_trigger: 'chatbot_action',
  chatbot_over_sms: 'chatbot_action',
  //Todo(vish): add event type for sms
};

export const EVENT_GROUP_PROPERTY_MAP = {
  email_send: 'task_definition_id',
  chatbot_trigger: 'flow_id',
  chatbot_over_sms: 'flow_id',
};

function getActionMapping(
  currentPath: string,
  nonObjectKeysObj: SimpleABTVariable,
) {
  let nonObKeysObject = nonObjectKeysObj;
  const currentPathArray = currentPath.split('.');
  const keyName = currentPathArray[currentPathArray.length - 1];
  const patharray = currentPath.split('.');
  const fieldGroup = patharray.slice(0, -1).join('.');
  //we are adding fieldGroup which is the JSON path upto this point
  //so that when any action variable is selected, the correct json path for dsl meta can be resolved from variable picker.
  nonObKeysObject = {...nonObKeysObject, key: keyName, fieldGroup};
  if (nonObKeysObject.show_on_ui) {
    return [[`{{${currentPath}}}`, nonObKeysObject]];
  }
  const key = patharray[patharray.length - 1];
  const fieldPath = `{{${currentPath}}}`;
  nonObKeysObject = {...nonObKeysObject, fieldPath, fieldGroup};
  //note(vish): add both mapping and reverse mapping for normalizing and parsing
  return [[key, nonObKeysObject]];
}

function getActionVariableIdentifiers(obj, currentPath = '$') {
  //base no more properties field in the object
  let path = new Map();
  let utilMap = new Map();
  if (obj != null && typeof obj === 'object') {
    const nonObjectKeys = Object.keys(obj).filter(
      (key) => !isPlainObject(obj[key]),
    );

    const nonObjectKeysObj = nonObjectKeys.reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});

    const objectKeys = Object.keys(obj).filter((key) =>
      isPlainObject(obj[key]),
    );

    if (nonObjectKeys.length > 0) {
      const item = getActionMapping(currentPath, nonObjectKeysObj);
      if (item[0][0].startsWith('{')) {
        path = new Map([...path, ...new Map(item)]);
      } else {
        //$FlowFixMe
        if (Object.hasOwn(item[0][1], 'entity_type')) {
          const actionEntityType = item[0][1].entity_type ?? '';
          const fieldGroup = item[0][1].fieldGroup ?? '';
          item[0][0] = `${fieldGroup};${item[0][0]};${actionEntityType}`;
          utilMap = new Map([...utilMap, ...new Map(item)]);
        }
      }
    }

    if (objectKeys.length > 0) {
      for (const objectKey of objectKeys) {
        if (!specialFields.includes(objectKey)) {
          const newCurrentPath = `${currentPath}.${escapeVarNames(objectKey)}`;
          if (
            obj != null &&
            typeof obj === 'object' &&
            obj[objectKey] !== null
          ) {
            // If the current property is an object, recurse.
            path = new Map([
              ...path,
              ...getActionVariableIdentifiers(obj[objectKey], newCurrentPath)
                .pickerVariables,
            ]);
            utilMap = new Map([
              ...utilMap,
              ...getActionVariableIdentifiers(obj[objectKey], newCurrentPath)
                .utilVariables,
            ]);
          }
        }
      }
    }
  }
  return {pickerVariables: path, utilVariables: utilMap};
}
function getVariableIdentifiers(obj, currentPath = '$') {
  //base no more properties field in the object
  let path = new Map();
  if (obj != null && typeof obj === 'object') {
    const nonObjectKeys = Object.keys(obj).filter(
      (key) => typeof obj[key] !== 'object',
    );

    const nonObjectKeysObj = nonObjectKeys.reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});

    const objectKeys = Object.keys(obj).filter(
      (key) => typeof obj[key] === 'object',
    );

    if (nonObjectKeys.length > 0) {
      path = new Map([
        ...path,
        ...new Map([[`{{${currentPath}}}`, nonObjectKeysObj]]),
      ]);
    }

    if (objectKeys.length > 0) {
      for (const objectKey of objectKeys) {
        if (!specialFields.includes(objectKey)) {
          const newCurrentPath = `${currentPath}.${escapeVarNames(objectKey)}`;
          if (
            obj != null &&
            typeof obj === 'object' &&
            obj[objectKey] !== null
          ) {
            // If the current property is an object, recurse.
            path = new Map([
              ...path,
              ...getVariableIdentifiers(obj[objectKey], newCurrentPath),
            ]);
          }
        }
      }
    }
  }

  return path;
}
/** this function exists because backend will provide `properties` field in
 * the nested object but will expect frontend to skip in the json path identifier
 */
function skipFieldsRecursively(obj, propToRemove) {
  if (!isPlainObject(obj) || obj === null) {
    return obj;
  }
  let newObj = obj;
  if (typeof obj === 'object' && typeof obj[propToRemove] != null) {
    newObj = omit(obj, [propToRemove]);
    newObj = {...newObj, ...(obj[propToRemove] ?? {})};
  }
  if (typeof newObj === 'object') {
    return Object.keys(newObj).reduce((acc, key) => {
      acc[key] = skipFieldsRecursively(
        typeof newObj === 'object' && newObj[key],
        propToRemove,
      );
      return acc;
    }, {});
  }
  return {};
}

function transformUtils(originalMap) {
  const transformedMap = new Map();
  originalMap.forEach((value, key) => {
    // Split the key into its components
    const [fieldGroup, keyPart, actionPart] = key.split(';');
    if (!transformedMap.has(actionPart)) {
      transformedMap.set(actionPart, new Map());
    }
    const inMap = transformedMap.get(actionPart);
    if (inMap && inMap instanceof Map && !inMap.has(fieldGroup)) {
      inMap.set(fieldGroup, new Map());
    }
    const innerMap =
      inMap && inMap instanceof Map ? inMap.get(fieldGroup) : new Map();
    //$FlowFixMe
    innerMap?.set(keyPart, value);
  });
  return transformedMap;
}

export function getActionVariables(data: mixed): ABTVariables {
  const dataWithPropertiesSkipped = skipFieldsRecursively(data, 'properties');
  const variableList = getActionVariableIdentifiers(dataWithPropertiesSkipped);
  variableList.utilVariables = transformUtils(variableList.utilVariables);
  //$FlowFixMe: todo(vish)
  return variableList;
}

export function getAllVariables<T>(data: mixed): Map<string, T> {
  const dataWithPropertiesSkipped = skipFieldsRecursively(data, 'properties');
  const variableList = getVariableIdentifiers(dataWithPropertiesSkipped);
  //$FlowFixMe: todo(vish)
  return variableList;
}

export function escapeVarNames(varName: string): string {
  return varName.replace(/\./g, '\\.');
}

export const extractAttributeNameFromIdentifier = (str: string): string => {
  const regexPattern = /(?:[^\\.]|\\.)+(?=\}\}$)/;
  const matches = str.match(regexPattern);
  if (matches && matches[0]) {
    return matches[0].replace(/\\\./g, '.');
  }
  return '';
};

export const getSupportedTypes = (
  taskModules: DynamicTaskObjectModules,
): Array<string> => {
  const supportedTypes = [];
  Object.keys(taskModules).forEach((moduleKey) => {
    supportedTypes.push(taskModules[moduleKey].type);
  });
  return supportedTypes;
};

export function getActionMapEvents(
  supportedTypes: Array<string>,
  abtEvents: Map<string, SimpleABTVariable>,
): Map<string, SimpleABTVariable> {
  const supportedEntityTypes = [];
  supportedTypes.forEach((type) => {
    if (Object.keys(ENTITY_TYPE_EVENT_MAP).includes(type)) {
      supportedEntityTypes.push(ENTITY_TYPE_EVENT_MAP[type]);
    }
  });
  const resultMap = new Map();
  for (const [key, value] of abtEvents) {
    if (supportedEntityTypes.includes(value.entity_type)) {
      resultMap.set(key, value);
    }
  }
  return resultMap;
}
