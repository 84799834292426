// @noflow

/**
 * NOTE (kyle): This is an index file. We should avoid adding any new
 * functions to it and instead organize our utils into files within this
 * folder. For backwards compatibility, this file may export things from
 * those files.
 */

import type {RouteLocation} from 'src/types/router';

import logger from 'src/utils/logger';

import css from 'src/styles/common.css';

import upperFirst from 'lodash/upperFirst';
import isUndefined from 'lodash/isUndefined';
import sortBy from 'lodash/sortBy';
import mapValues from 'lodash/mapValues';
import isObject from 'lodash/isObject';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import times from 'lodash/times';
import get from 'lodash/get';
import set from 'lodash/set';
import invariant from 'invariant';

// Export for backwards compatibility here.
export {default as classify} from 'src/utils/classify';
export {debounceAsync} from 'src/utils/function';
export {flattenNamedComponents} from './react';
export {omitNil as defined} from './object';
export {shallowEqual} from './shallowEqual';
export {
  formatPercentage,
  prettifyNumber,
  formatCurrency,
  stringifyNumber,
  getRatingMood,
  getNpsRatingMood,
} from './number';
export {camel, snake} from './camel-snake';
export {emptyArray, emptyObject} from './empty';
export {serializeQuery} from './query';

export const imageMimeTypes = ['image/jpeg', 'image/gif', 'image/png'];
export const isImage = (file: File | string): boolean =>
  imageMimeTypes.includes(file?.type ? file?.type : file);

/**
 * Given a collection of items, returns an object mapping
 * each item's id to one of a number of pre-designated colors
 */
const DEFAULT_COLORS = times(9, (index) => css[`color${index + 1}`]);
type ColorMap = {[id: string]: string};
export function generateColorMap(collection: Object | Array<*>): ColorMap {
  const colorMap = {};
  let index = 0;
  forEach(collection, (item) => {
    colorMap[item.id] = DEFAULT_COLORS[index % DEFAULT_COLORS.length];
    index++;
  });
  return colorMap;
}

export {stopEvent, stopEventImmediately, cancelEvent} from './dom';
export {backgroundImage} from './css';

/**
 * Returns a function that caches the result of the given function and returns
 * it if the parameters are exactly the same.
 */
export function cacheFunc(func: Function): Function {
  let prevArgs = [];
  let prevResult = null;

  return function () {
    for (let i = 0; i < arguments.length; i++) {
      if (prevArgs[i] !== arguments[i]) {
        prevArgs = arguments;
        return (prevResult = func(...prevArgs));
      }
    }

    return prevResult;
  };
}

/**
 * Returns a deep copy of the given object with undefined or null values removed
 * on all anscestors.
 */
export function deepDefined(collection: Object): Object {
  const predicate = (value) => {
    if (isObject(value)) {
      value = deepDefined(value);
    }
    return value;
  };

  if (Array.isArray(collection)) {
    return collection.map(predicate).filter((value) => !isNil(value));
  }
  return omitBy(mapValues(collection, predicate), isNil);
}

/**
 * Gets the scrollTop position of the app container.
 */
export function getScrollTop(): number {
  return window.scrollY || window.pageYOffset;
}

export function scrollApp(x: number, y: number) {
  window.scrollTo(x, y);
}

export function reload() {
  window.location.reload();
}

// TODO (kyle): use startCase in lodash
/**
 * Capitalizes every first character of every word in a string.
 */
export function titleCase(string: string): string {
  return string.split(' ').map(upperFirst).join(' ');
}

/**
 *  linear interpolate between input min/max to output min/max
 *  i.e. lerp(40, [0,80], [-1,1]) => 0
 */
export function lerp(
  val: number,
  [min, max]: number[],
  [oMin, oMax]: number[],
): number {
  const s = (oMax - oMin) / (max - min);
  const b = oMax - max * s;
  return val * s + b;
}

/*
 * Returns the best attempt at a full name based on available attributes on the AudienceMember
 */
interface NameObject {
  +fullName?: string;
  +firstName?: string;
  +lastName?: string;
  +fullname?: string;
  +firstname?: string;
  +lastname?: string;
  +name?: string;
}
export function getFullName(audienceMember: NameObject): string {
  const fullNames = ['fullName', 'name'];
  for (const attribute of fullNames) {
    const value =
      audienceMember[attribute] || audienceMember[attribute.toLowerCase()];
    if (value) {
      return value;
    }
  }

  const firstName = audienceMember.firstName || audienceMember.firstname || '';
  const lastName = audienceMember.lastName || audienceMember.lastname || '';
  const space = firstName && lastName ? ' ' : '';

  return `${firstName}${space}${lastName}`;
}

/*
 * Returns the best attempt at a first name based on available attributes on the AudienceMember
 */
export function getFirstName(audienceMember: NameObject) {
  if (audienceMember.firstName) {
    return audienceMember.firstName;
  } else if (audienceMember.fullName) {
    return audienceMember.fullName.split(' ')[0];
  }
}

export function matchesHosts(hosts: string[], hostname: string): boolean {
  invariant(
    !hosts.includes('sense'),
    `matchesHosts: 'sense' will always return true in prod`,
  );
  invariant(
    !hosts.includes('sensehq'),
    `matchesHosts: 'sensehq' will always return true in prod`,
  );
  return hosts
    .map((host) => hostname.indexOf(host) > -1)
    .reduce((prev, curr) => prev || curr, false);
}

// TODO (kyle): incorporate this into sculpt
export function unset(obj, key) {
  const newObj = {};
  for (const i in obj) {
    if (i !== key) {
      newObj[i] = obj[i];
    }
  }
  return newObj;
}

/*http://stackoverflow.com/questions/5002111/javascript-how-to-strip-html-tags-from-string
Removes html tags and returns string, note: will not work on the server*/
export function stripHtml(htmlString: string): string {
  const div = document.createElement('div');
  div.innerHTML = htmlString;
  return div.textContent || div.innerText || '';
}

//Simple email validation of the sorts, string@sting.string
export function validateEmail(email: string): boolean {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(email).toLowerCase(),
  );
}

/**
 * Concatenates all of the path patterns in the sequence of
 * routes that matched a given location
 *
 * Example input (the array of length 3 corresponding to):
 *
 * <Route path="/"">
 *   <Route component={Nav}>
 *     <Route path="item/:item" component={Foo} />
 *   </Route>
 * </Route>
 *
 * Example output:
 *
 *  "/item/:item"
 */
export function pathify(routes) {
  return routes.reduce((path, route) => {
    const nextPathPart = route.path || '';

    // We need to insert a slash if and only if
    // there's stuff on both the left and right sides
    // and there ain't no slash separating them already
    const needsSlash =
      path.length > 0 &&
      nextPathPart.length > 0 &&
      path.slice(-1) !== '/' &&
      nextPathPart.slice(0, 1) !== '/';

    return path + (needsSlash ? '/' : '') + nextPathPart;
  }, '');
}

export function pathFromLocation({pathname, search, hash}: RouteLocation) {
  return pathname + search + hash;
}

// query param object's key and value are considered as paramKey and paramValue
export const addQueryParamsToUrl = (
  url: string,
  queryParams: Object,
): string => {
  const newUrl = new URL(url);
  for (const [paramKey, paramValue] of Object.entries(queryParams)) {
    newUrl.searchParams.set(paramKey, paramValue);
  }
  return newUrl.toString();
};

export const validateIn = <T: Object>(
  obj: T,
  path: string | number | Array<string | number>,
  testFn: (val: mixed, obj?: T) => false | string | string[] | Object,
  errorKeyName: string = 'errors',
): Object | void => {
  const value = get(obj, path);
  let errors = testFn(value, obj);

  if (errors) {
    if (typeof errors === 'string') {
      errors = {[errorKeyName]: [errors]};
    } else if (Array.isArray(errors)) {
      errors = {[errorKeyName]: errors};
    }

    return set({}, path, errors);
  }
};

export function sortObject(object: {}) {
  return Object.keys(object)
    .sort()
    .reduce((newObject, key) => {
      newObject[key] = object[key];
      return newObject;
    }, {});
}

export function hashObject(object: {}) {
  return JSON.stringify(sortObject(object));
}

export function ensureArray<T>(thing: Array<T> | T): Array<T> {
  return Array.isArray(thing) ? thing : [thing];
}

export const oops =
  <R, P: Array<*>>(func: (...params: P) => R, defaultResult?: R) =>
  (...params: P) => {
    let result;
    try {
      result = func(...params);
    } catch (error) {
      logger.error(error);
      result = defaultResult;
    }
    return result;
  };

type Deprecation = (opts: {
  reason?: string,
  person?: string,
  issue?: number,
}) => void;

let deprecate: Deprecation = (_opts) => {};

// Include deprecation
if (process.env.NODE_ENV !== 'production') {
  const traceWarning = ({reason, person, issue}) => {
    if (issue && Number.isInteger(issue)) {
      reason = `${
        reason ? `${reason} ` : ''
      }https://github.com/Spaced-Out/sense/issues/${issue}`;
    } else if (
      typeof issue === 'string' &&
      issue.toLowerCase().includes('engage')
    ) {
      reason = `${
        reason ? `${reason} ` : ''
      }https://sensehq.atlassian.net/browse/${issue}`;
    }
    const message = `Deprecation${person ? `(${person})` : ''}: ${
      reason !== undefined ? reason : ''
    }`;

    // using logger here will emit a useless message
    // eslint-disable-next-line no-console
    console.warn(message);
    try {
      // This is just a warning.
      // this error is being thrown so you can find
      // the call that invoked this warning.
      throw new Error(message);
    } catch (e) {}
  };

  deprecate = (opts) => {
    if (opts !== undefined) {
      traceWarning(opts);
    }
  };
}

export {deprecate};
