// @flow strict

import type {
  JourneyAudienceAction,
  AudienceFormSteps,
} from 'src/action-creators/journey-audience.old';
import type {List} from 'src/types/lists';
import {
  UPDATE_STATE,
  RECEIVE_SELECTED_LISTS,
  SET_SELECTED_LISTS,
  GOTO_STEP,
  RESET,
} from 'src/action-creators/journey-audience.old';


export type JourneyAudienceState = {
  step: AudienceFormSteps,
  selectedLists: Map<string, $Shape<List>>,
};

const initialState = {
  step: 'createMode',
  selectedLists: new Map(),
};

export default (
  state: JourneyAudienceState = initialState,
  action: JourneyAudienceAction,
): JourneyAudienceState => {
  switch (action.type) {
    case UPDATE_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case RECEIVE_SELECTED_LISTS: {
      return {
        ...state,
        selectedLists: new Map([
          ...state.selectedLists.entries(),
          ...action.payload.entries(),
        ]),
      };
    }
    case SET_SELECTED_LISTS: {
      return {
        ...state,
        selectedLists: action.payload,
      };
    }
    case GOTO_STEP: {
      return {
        ...state,
        step: action.payload,
      };
    }
    case RESET: {
      return {
        ...initialState,
      };
    }
  }

  return state;
};
