// @flow

import * as React from 'react';


type PreviewCommonProps = {
  className?: string,
  name?: string,
  previewSize?: string,
};
type PreviewSrcProps = {
  src: string,
  srcdoc?: void,
  ...PreviewCommonProps,
};
type PreviewSrcDocProps = {
  srcdoc: string,
  src?: void,
  ...PreviewCommonProps,
};
type PreviewIframeProps = PreviewSrcProps | PreviewSrcDocProps;

const PreviewIframe = ({
  src,
  className,
  name,
  srcdoc,
  previewSize,
}: PreviewIframeProps): React.Element<'iframe'> => {
  const [iframeHeight, setIframeHeight] = React.useState(0);
  const iframeRef = React.useRef<?HTMLIFrameElement>(null);

  React.useEffect(() => {
    setIframeHeight(0);
  }, [src, srcdoc]);

  const measureOnLoad = React.useCallback(() => {
    if (iframeRef.current !== null) {
      setIframeHeight(
        iframeRef.current?.contentWindow.document.body.scrollHeight,
      );
    }
  }, [iframeRef]);

  React.useEffect(() => {
    measureOnLoad();
  }, [previewSize]);

  React.useEffect(() => {
    if (srcdoc && iframeRef.current) {
      iframeRef.current.srcdoc = srcdoc;
    }
  }, [srcdoc]);

  return (
    <iframe
      src={src}
      name={name}
      onLoad={measureOnLoad}
      ref={iframeRef}
      scrolling="yes"
      marginHeight="0px"
      marginWidth="0px"
      frameBorder="0"
      className={className}
      style={{
        maxHeight: iframeHeight,
        height: iframeHeight,
      }}
      sandbox="allow-same-origin"
    />
  );
};

export default PreviewIframe;
