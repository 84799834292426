// @flow

import * as React from 'react';
import times from 'lodash/times';
import {Link} from 'src/rerouter';
import {classify} from 'src/utils/index';

import tableCss from 'src/components/lib/table/table.css';


type Props = {
  page: number,
  maxPages: number,
  url: string,
  query?: Object,
  router: any,
  oneOfNumberDesign?: boolean,
};

export default function PaginationFooter(props: Props): React.Node {
  const {
    page,
    maxPages,
    url,
    router,
    query = {},
    oneOfNumberDesign = false,
  } = props;
  if (maxPages <= 1) {
    return null;
  }
  return (
    <div className={tableCss.pagination}>
      <button
        disabled={page <= 1}
        className={tableCss.pageButton}
        onClick={() =>
          router.push({
            pathname: url,
            query: {...query, page: page - 1},
          })
        }
      >
        Prev Page
      </button>

      {oneOfNumberDesign ? (
        <div className={tableCss.pageNumbers}>
          <div className={classify(tableCss.pageNumber, tableCss.pageSelected)}>
            {page}
          </div>
          <span className={tableCss.pageNumber}> Of </span>
          <div className={classify(tableCss.pageNumber, tableCss.pageSelected)}>
            {maxPages}
          </div>
        </div>
      ) : maxPages < 6 ? (
        <div className={tableCss.pageNumbers}>
          {times(maxPages, (idx) => (
            <Link
              key={idx}
              className={classify(
                tableCss.pageNumber,
                page === idx + 1 ? tableCss.pageSelected : '',
              )}
              to={{
                pathname: url,
                // $FlowFixMe[incompatible-type]
                query: {...query, page: (idx + 1).toString()},
              }}
            >
              {idx + 1}
            </Link>
          ))}
        </div>
      ) : (
        <div className={tableCss.pageNumbers}>
          <Link
            key={1}
            className={classify(
              tableCss.pageNumber,
              page === 1 ? tableCss.pageSelected : '',
            )}
            to={{
              pathname: url,
              // $FlowFixMe[incompatible-type]
              query: {...query, page: '1'},
            }}
          >
            {1}
          </Link>
          <Link
            key={2}
            className={classify(
              tableCss.pageNumber,
              page === 2 ? tableCss.pageSelected : '',
            )}
            to={{
              pathname: url,
              // $FlowFixMe[incompatible-type]
              query: {...query, page: '2'},
            }}
          >
            {2}
          </Link>
          {page > 3 && <span className={tableCss.pageNumber}>...</span>}
          {page > 2 && page < maxPages - 1 && (
            <Link
              key={page}
              className={classify(
                tableCss.pageNumber,
                page === page ? tableCss.pageSelected : '',
              )}
              to={{
                pathname: url,
                // $FlowFixMe[incompatible-type]
                query: {...query, page: page.toString()},
              }}
            >
              {page}
            </Link>
          )}
          {page < maxPages - 2 && (
            <span className={tableCss.pageNumber}>...</span>
          )}
          <Link
            key={maxPages - 1}
            className={classify(
              tableCss.pageNumber,
              page === maxPages - 1 ? tableCss.pageSelected : '',
            )}
            to={{
              pathname: url,
              // $FlowFixMe[incompatible-type]
              query: {...query, page: (maxPages - 1).toString()},
            }}
          >
            {maxPages - 1}
          </Link>
          <Link
            key={maxPages}
            className={classify(
              tableCss.pageNumber,
              page === maxPages ? tableCss.pageSelected : '',
            )}
            to={{
              pathname: url,
              // $FlowFixMe[incompatible-type]
              query: {...query, page: maxPages.toString()},
            }}
          >
            {maxPages}
          </Link>
        </div>
      )}

      <button
        disabled={page >= maxPages}
        className={tableCss.pageButton}
        onClick={() =>
          router.push({
            pathname: url,
            query: {...query, page: page + 1},
          })
        }
      >
        Next Page
      </button>
    </div>
  );
}
