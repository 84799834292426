// @flow

import type {RoiRedeploymentGrid} from 'src/types/roi';

import * as React from 'react';

import moment from 'moment';
import memoize from 'lodash/memoize';

import {prettifyNumber} from 'src/utils';

import RetentionGrid from 'src/components/lib/retention-grid';
import pure from 'src/flux/pure.jsx';

import css from './redeployment-grid.css';


const RedeploymentGrid = ({
  data = [],
  loading,
}: {
  data: RoiRedeploymentGrid,
  loading?: boolean,
}): React.Node => {
  const showYear = data.length > 12;
  const gridData = data.map(
    // $FlowFixMe upgraded flow
    ({redeploymentRate, redeployments, numEnds, month}) => ({
      y: getFullMonth(month, showYear),
      month,
      numEnds,
      redeploymentRate: `${redeploymentRate}%`,
      // $FlowIssue - keys will always be DD-MM-YYYY format so will not clobber `y`
      ...Object.keys(redeployments).reduce(
        (counts, month) => ({...counts, [month]: redeployments[month].length}),
        {},
      ),
    }),
  );
  const [start] = data;
  const redeployments = start ? start.redeployments : {};
  const redeploymentMonths = Object.keys(redeployments)
    .sort(
      (monthA, monthB) =>
        getMonthOffset(monthA, start.month) -
        getMonthOffset(monthB, start.month),
    )
    .map((month) => {
      const monthOffset = getMonthOffset(month, start.month);
      const label = monthOffset === 0 ? '<1 month' : monthOffset;
      return {
        label,
        value: month,
      };
    });
  const headers = [
    {label: 'People', value: 'numEnds'},
    ...redeploymentMonths,
    {label: 'RD Rate', value: 'redeploymentRate'},
  ];
  const dataColumns = redeploymentMonths.map(({value}) => value);

  return (
    <RetentionGrid
      className={css.grid}
      data={gridData}
      headers={headers}
      yKey="y"
      yTitle="Ended in..."
      dataColumns={dataColumns}
      title="The number of months until a contractor was redeployed..."
      getTooltipText={(dataRow, month) => {
        const monthOffset = getMonthOffset(month, dataRow.month) || '<1';
        return `${
          dataRow[month]
        } people redeployed within ${monthOffset} month${
          monthOffset !== 1 ? 's' : ''
        } (${dataRow.redeploymentRate} RD rate)`;
      }}
      loading={loading}
    />
  );
};

const getFullMonth = (month: string, showYear: boolean) =>
  moment(month).format(showYear ? 'MMMM YYYY' : 'MMMM');
const getMonthOffset = (month: string, startMonth: string) =>
  moment(month).diff(moment(startMonth), 'months');

export const getAverageRedeploymentDays: (RoiRedeploymentGrid) => string = memoize<
  [RoiRedeploymentGrid],
  string,
>((data) => {
  const {totalCount, totalOffsets} = data.reduce(
    (prev, {month: redeploymentMonth, redeployments}) => {
      let count = 0;
      let offsets = 0;
      for (const month in redeployments) {
        const offset = moment(month).diff(moment(redeploymentMonth), 'days');
        count += redeployments[month].length;
        offsets += offset * redeployments[month].length;
      }
      return {
        totalCount: prev.totalCount + count,
        totalOffsets: prev.totalOffsets + offsets,
      };
    },
    {
      totalCount: 0,
      totalOffsets: 0,
    },
  );

  // '<1' covers edge case where all redeployments happen in the same month
  if (totalCount === 0 || totalOffsets === 0) {
    return '<1';
  }
  return prettifyNumber(totalOffsets / totalCount);
});

export default (pure()(RedeploymentGrid): typeof RedeploymentGrid);
