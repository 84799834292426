// @noflow

// NOTE (kyle): do not use this HOC. use React.memo instead.

import * as React from 'react';

import nameHoc from './nameHoc';

/**
 * Pure Render HOC
 * Makes any component pure render.
 *
 * NOTE: This is useful for large, complex, functional components, but it
 * will make small, simple, functional components even slower. Use wisely.
 */
export default () => <Props: {}>(
  WrappedComponent: React.ComponentType<Props>,
): React.ComponentType<Props> =>
  nameHoc(
    class Hoc extends React.PureComponent<Props> {
      render() {
        return <WrappedComponent {...this.props} />;
      }
    },
    WrappedComponent,
    'Pure',
  );
