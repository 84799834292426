// @noflow

import * as React from 'react';

import {FilterCard} from './';
import DateRange from 'src/components/analytics/date-range.jsx';

import css from './timeframe-card.css';


type Props = {
  onSave: (e: SyntheticEvent<*>) => any,
  onCancel: (e: SyntheticEvent<*>) => any,
  range: *,
  history: *,
  location: *,
};

export default class TimeframeCard extends React.Component<Props> {
  render() {
    const {onSave, onCancel, range, history, location} = this.props;

    return (
      <FilterCard header="timeframe" onSave={onSave} onCancel={onCancel}>
        <section className={css.section}>
          <h4 className={css.subhead}>Date Range</h4>
          <DateRange
            range={range}
            history={history}
            location={location}
            className={css.dateRange}
          />
        </section>
      </FilterCard>
    );
  }
}
