// @flow strict-local

import type {Router, RouteLocation} from 'src/types/router';

import * as React from 'react';

import {classify} from 'src/utils/classify';

import {FilterCard} from './';
import Checkbox from 'src/components/lib/checkbox';

import MessageTypeGoals from 'src/components/analytics/v2/message-type-goals.js';

import css from './message-type-card.css';


type Props = {
  onSave?: (e: SyntheticEvent<>) => mixed,
  onCancel?: (e: SyntheticEvent<>) => mixed,
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => mixed,
  selectedTypes: string[],
  eventTypeCounts?: ?{[key: string]: number},
  eventTypes?: {value: string, label: string}[],
  history?: Router,
  location?: RouteLocation,
  showSkipped?: boolean,
};

const MessageTypes = [
  {
    key: 'survey_completion',
    label: 'Surveys',
    goal: MessageTypeGoals.survey,
  },
  {
    key: 'chatbot_completion',
    label: 'Chatbots',
    goal: MessageTypeGoals.chatbot,
  },
  {
    key: 'email_link_click',
    label: 'Emails with links',
    goal: MessageTypeGoals.email_with_links,
  },
  {
    key: 'email_open',
    label: 'Emails without links',
    goal: MessageTypeGoals.email_no_links,
  },
  {
    key: 'sms_reply',
    label: 'SMS',
    goal: MessageTypeGoals.sms,
  },
];

const MessageTypesSkipped = [
  {
    key: 'survey_completion',
    label: 'Skipped Surveys',
    goal: MessageTypeGoals.survey,
  },
  {
    key: 'chatbot_completion',
    label: 'Skipped Chatbots',
    goal: MessageTypeGoals.chatbot,
  },
  {
    key: 'email',
    label: 'Skipped Emails',
    goal: MessageTypeGoals.email,
  },
  {
    key: 'sms_reply',
    label: 'Skipped SMS',
    goal: MessageTypeGoals.sms,
  },
];

const MessageTypeCardV2 = ({
  onSave,
  onCancel,
  onChange,
  selectedTypes,
  showSkipped,
}: Props): React.Node => (
  <FilterCard onSave={onSave} onCancel={onCancel}>
    <div className={css.container}>
      <div className={css.header}>
        <span>Message Type</span>
        <span>Message Goal</span>
      </div>
      <ul className={css.checklist}>
        {(showSkipped ? MessageTypesSkipped : MessageTypes).map(
          ({key, label, goal}) => (
            <li key={key}>
              <div className={css.filterItemRow}>
                <label
                  className={classify(css.label, {
                    [css.labelChecked]: selectedTypes.includes(key),
                  })}
                >
                  <Checkbox
                    className={css.checkbox}
                    name={key}
                    checked={selectedTypes.includes(key)}
                    onChange={onChange}
                  />
                  {label}
                </label>
                <label
                  className={classify(css.label, {
                    [css.labelChecked]: selectedTypes.includes(key),
                  })}
                >
                  {goal}
                </label>
              </div>
            </li>
          ),
        )}
      </ul>
    </div>
  </FilterCard>
);

export default MessageTypeCardV2;
