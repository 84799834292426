// @flow

import type {SenseAction} from 'src/action-creators/types';


type ErrorState = {
  [key: string]: Error,
};

type SenseCacheErrorAction = {
  type: string,
  meta: {
    key: string,
  },
  error: Error,
};

const initialErrorState = Object.freeze({});

export default (
  state: ErrorState = initialErrorState,
  action: SenseCacheErrorAction,
): ErrorState => {
  const {type} = action;

  switch (type) {
    case '@@requestCache/error':
      const {
        meta: {key},
        error,
      } = action;
      return {...state, [key]: error};

    case '@@requestCache/error/clear':
      return initialErrorState;
  }
  return state;
};
