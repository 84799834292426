// @flow strict

import * as React from 'react';
import {
  // $FlowFixMe[untyped-import]
  autoUpdate,
  // $FlowFixMe[untyped-import]
  flip,
  // $FlowFixMe[untyped-import]
  offset,
  // $FlowFixMe[untyped-import]
  shift,
  // $FlowFixMe[untyped-import]
  useFloating,
} from '@floating-ui/react';

import {sizeFluid} from '@spaced-out/ui-design-system/lib/styles/variables/_size.js';
import {
  spaceNone,
  spaceXXSmall,
} from '@spaced-out/ui-design-system/lib/styles/variables/_space.js';

import {classify} from 'src/utils/classify';

// $FlowFixMe[nonstrict-import]
import {ClickAway} from '@spaced-out/ui-design-system/lib/utils/click-away';
import type {ButtonProps} from '@spaced-out/ui-design-system/lib/components/Button';
import {
  Button,
  UnstyledButton,
} from '@spaced-out/ui-design-system/lib/components/Button';
import {ConditionalWrapper} from '@spaced-out/ui-design-system/lib/components/ConditionalWrapper';
import type {
  MenuOption,
  MenuProps,
} from '@spaced-out/ui-design-system/lib/components/Menu';
import {Menu} from '@spaced-out/ui-design-system/lib/components/Menu';
import type {BaseTooltipProps} from '@spaced-out/ui-design-system/lib/components/Tooltip';
import {Tooltip} from '@spaced-out/ui-design-system/lib/components/Tooltip';

import css from './custom-dropdown.css';


export const ANCHOR_POSITION_TYPE = Object.freeze({
  top: 'top',
  topStart: 'top-start',
  topEnd: 'top-end',
  bottom: 'bottom',
  bottomStart: 'bottom-start',
  bottomEnd: 'bottom-end',
  right: 'right',
});

export const STRATEGY_TYPE = Object.freeze({
  absolute: 'absolute',
  fixed: 'fixed',
});

export type AnchorType = $Values<typeof ANCHOR_POSITION_TYPE>;
export type Strategy = $Values<typeof STRATEGY_TYPE>;

type ClassNames = $ReadOnly<{
  wrapper?: string,
  buttonWrapper?: string,
  dropdownContainer?: string,
  buttonIcon?: string,
}>;

export type CustomDropdownProps = {
  ...ButtonProps,
  classNames?: ClassNames,
  menu?: MenuProps,
  positionStrategy?: Strategy,
  anchorPosition?: AnchorType,
  onOptionSelect?: (option: MenuOption, ?SyntheticEvent<HTMLElement>) => mixed,
  onMenuOpen?: () => mixed,
  onMenuClose?: () => mixed,
  tooltip?: BaseTooltipProps,
  ...
};

export const CustomDropdown: React$AbstractComponent<
  CustomDropdownProps,
  HTMLDivElement,
> = React.forwardRef<CustomDropdownProps, HTMLDivElement>(
  (
    {
      anchorPosition = 'bottom-start',
      positionStrategy = STRATEGY_TYPE.fixed,
      size = 'medium',
      onOptionSelect,
      menu,
      classNames,
      disabled,
      onMenuOpen,
      onMenuClose,
      children,
      iconRightName,
      iconRightType = 'solid',
      isFluid,
      tooltip,
      onClick,
      tabIndex = -1,
      ...restButtonProps
    }: CustomDropdownProps,
    forwardRef,
  ) => {
    const menuBtnRef = React.useRef(null);
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    React.useImperativeHandle(forwardRef, () => menuBtnRef.current);
    const {x, y, refs, strategy} = useFloating({
      open: true,
      strategy: positionStrategy,
      placement: anchorPosition,
      whileElementsMounted: autoUpdate,
      middleware: [shift(), flip(), offset(parseInt(spaceXXSmall))],
    });

    const onMenuToggle = (isOpen: boolean) => {
      if (isOpen) {
        onMenuOpen?.();
        setIsMenuOpen(true);
      } else {
        onMenuClose?.();
        setIsMenuOpen(false);
      }
    };

    return (
      <ClickAway onChange={onMenuToggle}>
        {({isOpen, onOpen, cancelNext, clickAway}) => (
          <div
            data-testid="ButtonDropdown"
            className={classify(
              css.customDropdownContainer,
              {
                [css.isFluid]: isFluid,
              },
              classNames?.dropdownContainer,
            )}
            ref={menuBtnRef}
          >
            <ConditionalWrapper
              condition={Boolean(tooltip)}
              wrapper={(children) => (
                <Tooltip
                  {...tooltip}
                  hidden={isMenuOpen ? true : tooltip?.hidden}
                >
                  {children}
                </Tooltip>
              )}
            >
              <UnstyledButton
                {...restButtonProps}
                disabled={disabled}
                size={size}
                ref={refs.setReference}
                onClick={(e) => {
                  onClick?.(e);
                  e.stopPropagation();
                  onOpen();
                }}
                className={classify(
                  css.button,
                  {
                    [css.isFluid]: isFluid,
                  },
                  classNames?.wrapper,
                )}
                tabIndex={tabIndex}
              >
                {children}
              </UnstyledButton>
            </ConditionalWrapper>

            {isOpen && menu && (
              <div
                onClickCapture={cancelNext}
                ref={refs.setFloating}
                style={{
                  display: 'flex',
                  position: strategy,
                  top: y ?? spaceNone,
                  left: x ?? spaceNone,
                  ...(isFluid && {width: sizeFluid}),
                }}
              >
                <Menu
                  {...menu}
                  onSelect={(option, e) => {
                    onOptionSelect && onOptionSelect(option, e);
                    if (
                      !menu.optionsVariant ||
                      menu.optionsVariant === 'normal'
                    ) {
                      clickAway();
                    }
                  }}
                  size={menu.size || size}
                  onTabOut={clickAway}
                />
              </div>
            )}
          </div>
        )}
      </ClickAway>
    );
  },
);
