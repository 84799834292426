// @flow strict

import * as React from 'react';


export function useAsyncDependency(
  callback: () => Promise<mixed>,
  deps: mixed[],
): boolean {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);

    callback()
      .catch((ex) => {
        throw ex;
      })
      .finally(() => {
        setLoading(false);
      });
  }, deps);

  return loading;
}
