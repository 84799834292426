// @flow strict-local

import type {FlaggedWord, FlaggingAction} from 'src/action-creators/flagging';
// $FlowFixMe[untyped-import]
import sculpt from 'sculpt';

import {setError} from 'src/utils/validation';
// $FlowFixMe[untyped-import]
import {validateEmail} from 'src/utils';

import {
  RECEIVE_FLAGGED_WORDS,
  RECEIVE_FLAGGED_RATING,
  RECEIVE_DEFAULT_EMAILS,
  ADD_FLAGGED_WORD,
  REMOVE_FLAGGED_WORD,
  EDIT_FLAGGED_RATING,
  EDIT_FLAGGED_WORD,
  REMOVE_DEFAULT_EMAIL,
  EDIT_DEFAULT_EMAIL,
  ADD_DEFAULT_EMAIL,
} from 'src/action-creators/flagging';


export type State = {
  flaggedWords: FlaggedWord[],
  flaggedRating: ?Number,
  default_emails: ?string,
  editing: {
    flaggedWords: {
      word: string,
      id?: string,
    }[],
    flaggedRating: ?Number,
    default_emails: ?string,
  },
  flaggedWord: string,
  defaultEmail: string,
  errors: ?{
    flaggedWord: {
      errors: string[],
    },
    defaultEmail: {
      errors: string[],
    },
  },
};

const initialState = {
  flaggedWord: '',
  defaultEmail: '',
  errors: null,
  flaggedWords: [],
  default_emails: '',
  editing: {
    flaggedWords: [],
    flaggedRating: null,
    default_emails: '',
  },
  flaggedRating: null,
};

const validateFlaggedWord = ({editing: {flaggedWords}, flaggedWord}: State) => {
  let error = null;

  error = setError(error, flaggedWord, 'flaggedWord', 'Word is required');

  const flaggedWordsSet = new Set(
    flaggedWords.map(({word}) => word.toLowerCase()),
  );
  const wordExists = flaggedWordsSet.has(flaggedWord.toLowerCase());
  error = setError(
    error,
    !wordExists,
    'flaggedWord',
    'Flagged word already exists',
  );
  return error;
};

const validateAlertEmail = ({
  editing: {default_emails},
  defaultEmail,
}: State) => {
  let error = null;

  error = setError(
    error,
    validateEmail(defaultEmail),
    'defaultEmail',
    'Entered text should be a valid email',
  );

  if (error) {
    return error;
  }

  const emailsSet = new Set(default_emails?.split(',') || []);
  const exists = emailsSet.has(defaultEmail.toLowerCase());
  error = setError(error, !exists, 'defaultEmail', 'Email already exists');

  return error;
};

export default (state: State = initialState, action: FlaggingAction): State => {
  switch (action.type) {
    case RECEIVE_FLAGGED_WORDS:
      return sculpt(state, {
        flaggedWords: {
          $set: action.payload,
        },
        editing: {
          flaggedWords: {$set: action.payload},
        },
      });

    case RECEIVE_DEFAULT_EMAILS:
      return sculpt(state, {
        default_emails: {
          $set: action.payload,
        },
        editing: {
          default_emails: {$set: action.payload},
        },
      });

    case RECEIVE_FLAGGED_RATING:
      return sculpt(state, {
        flaggedRating: {
          $set: action.payload,
        },
        editing: {
          flaggedRating: {$set: action.payload},
        },
      });

    case EDIT_FLAGGED_RATING:
      return sculpt(state, {
        editing: {
          flaggedRating: {$set: action.payload},
        },
      });

    case EDIT_FLAGGED_WORD:
      return {...state, flaggedWord: action.payload};

    case EDIT_DEFAULT_EMAIL:
      return {...state, defaultEmail: action.payload};

    case ADD_FLAGGED_WORD:
      const errors = validateFlaggedWord(state);
      if (errors) {
        return {...state, errors: {...state.errors, ...errors}};
      }
      return sculpt(state, {
        editing: {
          flaggedWords: {
            $unshift: [{word: state.flaggedWord}],
          },
        },
        flaggedWord: {
          $set: '',
        },
        errors: {
          $set: errors,
        },
      });

    case ADD_DEFAULT_EMAIL:
      const emailErrors = validateAlertEmail(state);

      if (emailErrors) {
        return {...state, errors: {...state.errors, ...emailErrors}};
      }

      const newArr =
        state.editing.default_emails?.split(',').filter((e) => !!e) ?? [];

      !newArr.includes(state.defaultEmail) &&
        newArr.unshift(state.defaultEmail);

      return {
        ...state,
        editing: {
          ...state.editing,
          default_emails: newArr.join(','),
        },
        defaultEmail: '',
        errors: {
          flaggedWord: {
            // $FlowFixMe[unnecessary-optional-chain]
            errors: state.errors?.flaggedWord?.errors ?? [],
          },
          defaultEmail: {
            errors: [],
          },
        },
      };

    case REMOVE_FLAGGED_WORD:
      const {word: wordToDelete} = action.payload;
      return sculpt(state, {
        editing: {
          flaggedWords: {
            $set: state.editing.flaggedWords.filter(
              ({word}) => word !== wordToDelete,
            ),
          },
        },
      });
    case REMOVE_DEFAULT_EMAIL:
      return sculpt(state, {
        editing: {
          default_emails: {
            $set: state.editing.default_emails
              ?.split(',')
              .filter((email) => email !== action.payload)
              .join(','),
          },
        },
      });
  }

  return state;
};
