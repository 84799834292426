// @noflow

import type {Agent} from 'src/api-parsers/index';

import Store from './base';

type State = {
  fetching: ?Promise<void>,
  creating: boolean,
  signingIn: boolean,
  signinError: ?Object,
  signupError: ?Object,
  authPath: ?string,
  postAuthPath: ?string,
  agent: ?Agent,
  flags: {[key: string]: boolean},
};

export default class MeStore extends Store {
  state: State;

  constructor() {
    super('me');

    this.state = {
      fetching: null,
      creating: false,
      signingIn: false,
      signinError: null,
      signupError: null,
      authPath: null,
      postAuthPath: null,
      agent: null,
      flags: {},
    };
  }

  startFetching(promise: Promise<void>) {
    this.setState({
      fetching: promise,
    });
  }

  receiveFetched(agent: Agent) {
    this.setState({
      fetching: null,
      agent,
    });
  }

  startCreating() {
    this.setState({
      creating: true,
    });
  }

  receiveCreated(agent: Agent) {
    this.receiveFetched(agent);
  }

  isAuthed(): boolean {
    return !!this.state.agent;
  }

  clearAuth() {
    this.setState({
      agent: null,
    });
  }

  is(agent: string | Object): boolean {
    const id = agent.id || agent;
    return this.state.agent.id === id;
  }

  getSearchApiKey(): ?string {
    return this.isAuthed() ? this.state.agent.searchApiKey : null;
  }

  getSearchPrefix(): ?string {
    return this.isAuthed() ? this.state.agent.searchPrefix : null;
  }
}
