// @flow

/**
 * DEPRECATED
 * This file is deprecated in favor of `redux-api-v2.js`. Please do not import
 * it anywhere it hasn't been already referenced.
 */

import type {RawQuery} from 'src/types/router';
import type {GetState, Dispatch, ThunkAction} from 'src/reducers';
// $FlowFixMe[untyped-type-import]
import type {State as EnvState} from 'src/reducers/env';
import {USER_AUTH} from 'src/action-creators/referrer-portal/authentication.js';
import ie from 'src/utils/ie';
import exenv from 'exenv';
import invariant from 'invariant';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import noop from 'lodash/noop';
import fetch from 'cross-fetch';
import {saveAs} from 'file-saver';
import URL from 'url-parse';

import {browserHistory} from 'src/reroutes/browser-history';
import logger from 'src/utils/logger';
import {serializeQuery, emptyObject} from 'src/utils';
import {noCacheHeaders, ie11Query} from 'src/utils/api-no-cache';
import {
  pushModal,
  popModal,
  replaceModal,
  showGenericError,
} from 'src/action-creators/modal';
import {setMaintenanceMode} from 'src/action-creators/agency';
import {setPostAuthPath, setAuthed} from 'src/action-creators/accounts';
import {batch} from 'src/action-creators/batch';
import {selectIsAuthed} from 'src/selectors/accounts';
import {selectCsrfToken} from 'src/selectors/csrf';
import {ApiError} from 'src/utils/errors';


export type {RawQuery};

export const API_ERROR = 'api/apiError';
const API_PATH = process.env.API_PATH || '/api/v1/';

const apiLogger = logger.createChild({context: 'Redux API'});

export type ApiOptions = {
  handleAuth?: boolean,
  // TODO (kyle): this property is too multi-purpose
  // Don't print stuff to console, modals, etc.
  silence?: boolean,
  redirect?: 'follow' | 'manual' | 'error',
  apiPath?: string,
  headers?: {
    [string]: string,
  },
};

function getHeaders(state) {
  let {headers} = state.requestHeaders;

  if (exenv.canUseDOM) {
    headers = omit(headers, ['user-agent']);
  }

  return headers;
}

function getFullHost(env, headers, path, apiPrefix = API_PATH) {
  const url = exenv.canUseDOM || !env ? '' : env.apiUrl;
  /*
  if (!exenv.canUseDom) {
    if (headers['x-forwarded-proto'] === 'https') {
      protocol = 'https://';
    } else {
      protocol = 'http://';
    }
  } else {
    protocol = '';
  }
  */

  // (rng) - We need this because we have an nginx rule to redirect HTTP
  // requests to HTTPS if they come through the ELB The other approach is to
  // have api.js requests go directly to the python API server without routing
  // back through the ELB & nginx
  const fullHost = `${url}${apiPrefix}${path}`;

  return fullHost;
}

const showApiError = (response) => (dispatch) => {
  if (!exenv.canUseDOM) {
    return;
  }

  const errorMessage = response && response.error;
  const responseText =
    response && response.body ? JSON.stringify(response.body, null, 2) : '';

  dispatch(
    showGenericError({
      title: 'API Error',
      text: 'An unexpected API error occurred. If this keeps happening, please email support@sensehq.com',
      details: `${errorMessage}\n${responseText}`,
    }),
  );
};

// this get works specifically with our redux store. It must always be dispatched.
// So if you decide to go rogue and call it outright outside of an action-creator,
// you need to do
//
// dispatch(reduxapi.get(somepath, query)).then(returnValue => {
//   whatever, maybe another dispatch or console.log here
// })

export const get = <T = mixed>(
  path: string,
  query?: RawQuery,
  options: ApiOptions = emptyObject,
): ThunkAction<T> =>
  baseRequest(path, {
    method: 'GET',
    query,
    options,
  });

type ProgressEvent = {
  percent?: number,
};

export type ApiPostOptions = {
  processProgress?: (event: ProgressEvent) => void,
  handleAuth?: boolean,
  multipart?: boolean,
  apiPath?: string,
};

export const post = <T = mixed>(
  path: string,
  data?: {...},
  query?: RawQuery,
  options?: ApiPostOptions,
): ThunkAction<T> =>
  baseRequest(path, {
    method: 'POST',
    data,
    query,
    options,
  });

export const put = <T = mixed>(
  path: string,
  data?: {...},
  query?: RawQuery,
  options?: ApiPostOptions,
): ThunkAction<T> =>
  baseRequest(path, {
    method: 'PUT',
    data,
    query,
    options,
  });

export const del = <T = mixed>(
  path: string,
  data?: {...},
  options?: ApiOptions,
): ThunkAction<T> =>
  baseRequest(path, {
    method: 'DELETE',
    data,
    options,
  });

export const download =
  (path: string, options: *): ThunkAction<mixed> =>
  async (dispatch: Dispatch) => {
    const abortController =
      typeof AbortController !== 'undefined' && new AbortController();
    let aborted = false;

    dispatch(
      pushModal({
        type: 'GENERIC_MODAL',
        title: 'Download',
        text: 'In progress...',
        abortText: 'Cancel',
        handleAbort: () => {
          if (abortController) {
            abortController.abort();
          }
          aborted = true;
          dispatch(popModal());
        },
        allowClickAway: false,
        ...(abortController
          ? {
              signal: abortController.signal,
            }
          : null),
      }),
    );

    try {
      const response: Response = await dispatch(base(path, options));

      if (response.ok) {
        const blob = await responseToBlob(response);
        if (!aborted) {
          saveAs(blob);
          dispatch(popModal());
        }
      } else {
        throw JSON.stringify(await response.json());
      }
    } catch (error) {
      apiLogger.error('Download', error);
      dispatch(
        replaceModal({
          type: 'GENERIC_ERROR',
          title: 'Download',
          text: 'Your download failed due to the following error.',
          details: String(error),
          confirmText: 'Okay',
          handleConfirm: () => {
            dispatch(popModal());
          },
        }),
      );
    }
  };

export type ApiV1Error = {
  error: Error,
  response: mixed,
};

const baseRequest =
  <T = mixed>(path: string, baseOptions?: RequestOptions): ThunkAction<T> =>
  (dispatch) =>
    dispatch(base(path, baseOptions)).then(async (response: Response) => {
      const text = await response.text();

      let json;
      if (response.status !== 404) {
        try {
          json = JSON.parse(text);
        } catch (error) {
          // NOTE (kyle): this should not happen.
          json = text;
        }
      } else {
        json = text;
      }

      if (!response.ok) {
        // TODO (kyle): eventually we'll want to completely deprecate this.
        throw {
          error: new Error('Bad Request'),
          response: json,
          apiError: new ApiError({path, ...baseOptions}, response, json),
        };
      }

      // $FlowFixMe[incompatible-return] this is better than returning `any`.
      // $FlowFixMe[incompatible-call]
      return json;
    });

export type RequestOptions = {
  path?: string,
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  data?: mixed,
  query?: RawQuery,
  signal?: AbortSignal,
  options?: $Shape<{...ApiOptions, ...ApiPostOptions}>,
};

export type OtherOptions = {
  serverHeaders?: {[string]: string, ...},
  // $FlowFixMe[value-as-type] [v1.32.0]
  env?: EnvState,
  csrfToken?: ?string,
  onError?: ({
    meta: {handleAuth: boolean, path: string, response?: Response},
    error: Error,
  }) => void,
  onRequest?: ({meta: {path: string, query?: RawQuery}, data?: mixed}) => void,
};

export const baseNoStore = async (
  path: string,
  baseOptions: RequestOptions = emptyObject,
  options: OtherOptions = emptyObject,
): Promise<Response> => {
  const {
    method = 'GET',
    data,
    signal,
    options: {
      handleAuth = true,
      silence = false,
      multipart = false,
      redirect = 'follow',
      processProgress,
      apiPath = undefined,
      headers = {},
    } = {},
  } = baseOptions;
  let {query} = baseOptions;
  const {
    serverHeaders = {},
    env = {},
    onError = noop,
    onRequest = noop,
    csrfToken = undefined,
  } = options;

  let fullHost = getFullHost(env, serverHeaders, path, apiPath);

  // NOTE (kyle): ie11 does some weird caching. we bust it manually here.
  if (ie === 11) {
    query = ie11Query(query);
  }

  // TODO (kyle): remove this and add the commented code when we decide
  // to drop support for IE 11
  const queryString = serializeQuery(query);
  if (queryString) {
    fullHost = `${fullHost}?${queryString}`;
  }

  const url = new URL(fullHost);

  /*
  if (query) {
    for (const key in query) {
      url.searchParams.append(key, query[key]);
    }
  }
  */

  // TODO (kyle): determine whether or not this is necessary.
  const init = Object.assign(
    {},
    {
      method,
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect,
      headers: ({}: {[string]: string, ...}),
    },
  );

  if (signal) {
    init.signal = signal;
  }

  for (const customHeaderKey in headers) {
    // to avoid [cannot-spread-indexer] and [cannot-spread-inexact] flow errors
    if (!init.headers[customHeaderKey]) {
      init.headers[customHeaderKey] = headers[customHeaderKey];
    }
  }

  // only relevant when rendering on server side
  /*
  init.headers = noCacheHeaders({
    Accept: 'application/json',
  });
  */
  init.headers.Accept = 'application/json';
  if (env.agencySlug) {
    init.headers['x-agency-slug'] = env.agencySlug;
  }

  if (!exenv.canUseDOM) {
    Object.assign(
      init.headers,
      pick(serverHeaders, 'cookie', 'connection', 'user-agent'),
    );
  }

  if (['POST', 'PUT', 'DELETE', 'PATCH'].includes(method)) {
    if (data && exenv.canUseDOM && data instanceof FormData) {
      init.body = data;
    } else if (data && multipart && typeof data === 'object') {
      invariant(
        exenv.canUseDOM,
        'Attempted to post multipart without a DOM context.',
      );

      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        let val = data[key];
        if (!(val instanceof File)) {
          val = JSON.stringify(val);
        }
        if (val !== undefined) {
          formData.set(key, val);
        }
      });
      init.body = formData;
      //init.headers['Content-Type'] = 'multipart/form-data';
    } else {
      // note(nilarnab): if we are deliberately adding `content-type` as `json` here
      // then we need to make sure we are not sending body as `undefined`
      // maybe `data ?? {}` should solve it?
      init.body = JSON.stringify(data);
      init.headers['Content-Type'] = 'application/json';
    }

    init.headers['X-Csrf-Token'] = csrfToken || '';

    onRequest({
      data,
      meta: {path, query},
    });
  }

  if (method === 'DELETE') {
    onRequest({
      meta: {path, query},
    });
  } else if (method === 'GET') {
    init.headers = noCacheHeaders(init.headers);
  }

  if (!silence) {
    apiLogger.info(method, `${fullHost}`);
  }

  // TODO (kyle): figure this out
  /*
  if (method === 'GET') {
    req = req.use(noCache);
  }
  */

  if (processProgress) {
    // TODO (kyle): do something better
    processProgress({percent: 0});
  }

  try {
    const response = await fetch(url, init);

    if (!response.ok) {
      onError({
        error: new Error(),
        meta: {response, handleAuth, path},
      });
    }

    return response;
  } catch (error) {
    apiLogger.error(method, error);
    onError({
      error,
      meta: {handleAuth, path},
    });
    throw error;
  }
};

export const base =
  (
    path: string,
    baseOptions: RequestOptions = emptyObject,
  ): ThunkAction<Response> =>
  async (dispatch: Dispatch, getState: GetState): Promise<Response> => {
    const serverHeaders = getHeaders(getState());
    const env = getState().env;
    const csrfToken = selectCsrfToken(getState());

    const request = await baseNoStore(path, baseOptions, {
      serverHeaders,
      env,
      csrfToken,
      onError: ({error, meta}) => {
        // $FlowFixMe
        dispatch({type: API_ERROR, error, meta});
        if (
          exenv.canUseDOM &&
          meta.response?.status === 401 &&
          meta.handleAuth &&
          selectIsAuthed(getState())
        ) {
          // $FlowFixMe
          dispatch(showLoginModal());
        } else if (exenv.canUseDOM && meta.response?.status === 503) {
          //NOTE:(diwakersurya) this check is there to make sure that we don't show
          //maintenance mode when 503 is due to other reasons. This check is also
          //there in server.js as well
          meta.response?.json().then((error) => {
            if (error?.error === 'AgencyUnderMaintenance') {
              dispatch(setMaintenanceMode(true));
            }
          });
        }
      },
      onRequest: ({meta, data}) =>
        // $FlowFixMe
        dispatch({
          type: `API_${baseOptions.method || 'GET'}`,
          payload: data,
          meta,
        }),
    });

    return request;
  };

async function responseToBlob(
  response: Response,
  filenameArg?: string,
): Promise<Blob> {
  const filename = filenameArg || parseFilename(response);
  let blob = await response.blob();
  if (typeof File !== 'undefined') {
    try {
      blob = new File([blob], filename);
    } catch (error) {
      // NOTE (kyle): Edge doesn't seem to allow creating file objects
    }
  }
  return blob;
}

function parseFilename(response: Response) {
  let filename = '';

  const disposition = response.headers.get('content-disposition');
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
}

const showLoginModal = () => (dispatch) =>
  // TODO (kyle): maybe make this an actual error modal so that it
  // can't stack.
  dispatch(
    pushModal({
      type: 'GENERIC_ERROR',
      title: 'Expired Session',
      text: 'Looks like your session expired. Please sign back in.',
      confirmText: 'Okay',
      handleConfirm: () => {
        // TODO (kyle): possible to push state and preserve current stuff?
        dispatch(
          batch(
            popModal(),
            setAuthed(false),
            dispatch({type: USER_AUTH, payload: false}),
            // TODO (kyle): also add search and hash
            setPostAuthPath(window.location.pathname),
          ),
        );
        browserHistory.push('/signin');
      },
      handleAbort: () => {
        dispatch(popModal());
      },
    }),
  );
