// @flow strict-local

import type {Agency} from 'src/types/agency';

import {useSelector} from 'react-redux';

import {
  selectAgencyData,
  selectAgencyMaintenanceMode,
} from 'src/selectors/agency';


export const useAgencyData = (): Agency => {
  const agency = useSelector(selectAgencyData);
  return agency;
};

export function useAgencyMaintenanceMode(): boolean {
  return useSelector(selectAgencyMaintenanceMode) ?? false;
}
