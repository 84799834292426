// @noflow
import * as React from 'react';

import {classify} from 'src/utils';

import MobileIcon from 'src/images/mobile.svg';
import BrowserIcon from 'src/images/browser.svg';

import segmented from './segmented.css';
import css from './style.css';


type deviceTypes = 'desktop' | 'mobile';
export default class extends React.Component<
  {
    className?: string,
    style?: {[rule: string]: string | number},
    initialDevice: deviceTypes,
    children: React.Children,
  },
  {device: deviceTypes},
> {
  constructor(props) {
    super(props);

    this.state = {device: props.initialDevice};
  }

  render() {
    const {device} = this.state;
    const {className, style, children} = this.props;
    return (
      <div className={classify(css.container, className)} style={style}>
        <div className={segmented.control}>
          <button
            aria-label="mobile preview"
            onClick={() => this.setState({device: 'mobile'})}
            className={classify({[segmented.active]: device === 'mobile'})}
          >
            <MobileIcon />
          </button>
          <button
            aria-label="desktop preview"
            onClick={() => this.setState({device: 'desktop'})}
            className={classify({[segmented.active]: device === 'desktop'})}
          >
            <BrowserIcon />
          </button>
        </div>

        <div className={css.devicePreviewWraper}>
          <div className={css[`devicePreview-${device}`]}>
            <div className={css[`devicePreviewFrame-${device}`]}>
              {children &&
                children({
                  device,
                }) /*TODO(marcos): need more error checking?? */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
