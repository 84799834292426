// @flow

import type {Node} from 'react';

import type {
  Dispatch,
  GetState,
  ThunkSyncAction,
  ThunkAction,
} from 'src/reducers';
import type {ApiError} from 'src/utils/redux-api-v2';
import type {ModalComponent, ModalRender} from 'src/types/modal';
import type {ModalType} from 'src/components/modals/modal-root.jsx';

import {selectHead} from 'src/selectors/modal';
import {captureApiError} from 'src/utils/errors';


export const PUSH_MODAL = 'modal/pushModal';
export const REPLACE_MODAL = 'modal/replaceModal';
export const POP_MODAL = 'modal/popModal';
export const SET_ERROR_MODAL = 'modal/setErrorModal';
export const REMOVE_ERROR_MODAL = 'modal/removeErrorModal';
export const PUSH_SIDEPANEL = 'modal/pushSidepanel';

type Base = {
  className?: string,
};

export type Modal = {
  ...Base,
  type: ModalType | ModalComponent,
  render?: void,
  text?: string | Node,
  allowClickAway?: boolean,
  children?: Node,
  confirmText?: string,
  confirmStyle?: 'primary' | 'destructive' | 'default',
  abortText?: string,
  errorText?: string,
  showError?: boolean,
  handleConfirm?: (event: SyntheticEvent<>) => mixed,
  handleAbort?: (event: SyntheticEvent<>) => mixed,
  removeModal?: () => void,
  topmost?: boolean,
  modalVersion?: 'genesis',
  ...
};

export type Sidepanel = {
  ...Modal,
  title?: Node,
  width?: string,
  direction: 'left' | 'right',
  showClear?: boolean,
  ...
};

export type ConfirmModal = {
  ...Base,
  text?: Node,
  title?: string,
  details?: string,
  ...
};

export type TitleBarDetailsModal = {
  ...Modal,
  title?: string,
  details?: string | Node,
  titleIcon?: Node,
  titleBarColor?: string,
  hasTitleXButton?: boolean,
  removeModal?: () => void,
  titleElement?: Node,
  cancelIcon?: Node,
  ...
};

export type BasicModal =
  | Modal
  | {
      render: ModalRender,
      allowClickAway?: boolean,
    };

export type PushModalAction = {
  type: 'modal/pushModal',
  payload: BasicModal,
};

export type ReplaceModalAction = {
  type: 'modal/replaceModal',
  payload: BasicModal,
};

export type PopModalAction = {
  type: 'modal/popModal',
};

export type SetErrorModalAction = {
  type: 'modal/setErrorModal',
  payload: Modal,
};

export type RemoveErrorModalAction = {
  type: 'modal/removeErrorModal',
};

export type PushSidepanelAction = {
  type: 'modal/pushSidepanel',
  payload: Sidepanel,
};

export type ModalAction =
  | PushModalAction
  | ReplaceModalAction
  | PopModalAction
  | SetErrorModalAction
  | RemoveErrorModalAction
  | PushSidepanelAction;

export const pushModal = (payload: BasicModal): PushModalAction => ({
  type: PUSH_MODAL,
  payload,
});

export const replaceModal = (payload: BasicModal): ReplaceModalAction => ({
  type: REPLACE_MODAL,
  payload,
});

export const popModal = (..._args: void[]): PopModalAction => ({
  type: POP_MODAL,
});

export const setErrorModal = (
  payload: TitleBarDetailsModal,
): SetErrorModalAction => ({
  type: SET_ERROR_MODAL,
  payload,
});

export const removeErrorModal = (): RemoveErrorModalAction => ({
  type: REMOVE_ERROR_MODAL,
});

const setSidepanel = (payload: Sidepanel): PushSidepanelAction => ({
  type: PUSH_SIDEPANEL,
  payload,
});

export const pushSidepanel =
  ({...specificOptions}: $Shape<Sidepanel>): ThunkSyncAction =>
  (dispatch: Dispatch) => {
    const defaultOptions: Sidepanel = {
      type: 'GENERIC_SIDEPANEL',
      direction: 'right',
    };
    dispatch(
      setSidepanel({
        ...defaultOptions,
        ...specificOptions,
      }),
    );
  };

export const showGenericError =
  ({...specificOptions}: $Shape<TitleBarDetailsModal>): ThunkSyncAction =>
  (dispatch: Dispatch) => {
    const defaultOptions = {
      type: 'GENERIC_ERROR',
      title: 'Error',
      confirmText: 'Okay',
      removeModal: () => {
        dispatch(removeErrorModal());
      },
    };
    dispatch(
      setErrorModal({
        ...defaultOptions,
        ...specificOptions,
      }),
    );
  };

export const showGenericModal =
  (
    modalType: ModalType,
    extras?: $Shape<TitleBarDetailsModal>,
  ): ((dispatch: Dispatch) => Promise<boolean>) =>
  (dispatch: Dispatch): Promise<boolean> =>
    new Promise((resolve) => {
      dispatch(
        pushModal({
          type: modalType,
          confirmText: 'Okay',
          handleConfirm: () => {
            dispatch(popModal());
            resolve(true);
          },
          ...extras,
        }),
      );
    });

export const showGenericAlert = (
  extras?: $Shape<TitleBarDetailsModal>,
): ThunkAction<boolean> => showGenericModal('GENERIC_MODAL', extras);

export const showGenericSuccess = (
  extras?: $Shape<TitleBarDetailsModal>,
): ThunkAction<boolean> => showGenericModal('GENERIC_SUCCESS', extras);

export const showGenericConfirm =
  (specificOptions: $Shape<TitleBarDetailsModal> = {}): ThunkAction<boolean> =>
  (dispatch: Dispatch): Promise<boolean> =>
    new Promise((resolve) => {
      const defaultOptions: $Shape<TitleBarDetailsModal> = {
        type: 'GENERIC_MODAL',
        text: 'Are you sure?',
        confirmText: 'Confirm',
        handleConfirm: () => {
          resolve(true);
        },
        abortText: 'Cancel',
        handleAbort: () => {
          resolve(false);
        },
        removeModal: () => {
          dispatch(popModal());
        },
        allowClickAway: false,
      };
      dispatch(
        pushModal({
          ...defaultOptions,
          ...specificOptions,
        }),
      );
    });

// Dispatch a modal that resolves with a custom value T
export function pushResolvingModal<T>(
  specificOptions: $Shape<Modal>,
): ThunkAction<T> {
  return (dispatch: Dispatch): Promise<T> =>
    new Promise((resolve, reject) => {
      dispatch(
        pushModal({
          ...specificOptions,
          handleConfirm: (e) => {
            // $FlowFixMe[incompatible-return]
            resolve(e);
            dispatch(popModal());
          },
          handleAbort: () => {
            dispatch(popModal());
            reject();
          },
          removeModal: () => {
            dispatch(popModal());
            reject();
          },
          allowClickAway: true,
          _resolve: resolve,
          _rej: reject,
        }),
      );
    });
}

// TODO (kyle): this is a stopgap until Flow supports ErrorEvent
interface ErrorEvent {
  message: string;
  filename: string;
  lineno: number;
  colno: number;
  stack: string;
  toString(): string;
}

export const showGenericRuntimeError =
  (error: ErrorEvent): ThunkSyncAction =>
  (dispatch: Dispatch) => {
    window.senseErrorEvent = error;

    let details;
    if (error.message.toLowerCase().includes('script error')) {
      details = 'See console.';
    } else {
      details = `${error.message}
${error.filename} @ ${error.lineno} | ${error.colno}
${error.stack || error.toString()}`;
    }

    dispatch(
      showGenericError({
        type: 'GENERIC_ERROR',
        title: 'Unexpected Error',
        text: 'Apologies, but an unexpected error occured. If this keeps happening, please email support@sensehq.com',
        details,
      }),
    );
  };

export const showApiError =
  (apiError: {error: Error, response: string}, text: string): ThunkSyncAction =>
  (dispatch: Dispatch) =>
    dispatch(
      showGenericError({
        type: 'GENERIC_ERROR',
        title: 'API Error',
        text,
        details: `${apiError.error.toString()}\n${JSON.stringify(
          apiError.response,
          null,
          2,
        )}`,
      }),
    );

export const showHandledApiError = ({
  title = 'API Error',
  text,
  json,
  details,
  error,
}: {
  title?: string,
  text: string,
  json?: mixed,
  details?: string,
  error?: ApiError,
}): ThunkSyncAction => {
  const finalJson = json || error?.responseBody;
  return showGenericError({
    title,
    text,
    details:
      details ||
      (finalJson ? JSON.stringify(finalJson, null, '  ') : undefined),
  });
};

export const showAndCaptureApiError = (
  error: ApiError,
  options: {
    title?: string,
    text: string,
  },
): ThunkSyncAction => {
  captureApiError(error);
  return showHandledApiError({
    ...options,
    json: error.responseBody,
  });
};

export const showUnhandledApiError = (
  error: ApiError,
  errorId: string,
  extras?: {},
): ThunkSyncAction =>
  showGenericError({
    title: 'API Error',
    text: 'An unexpected API error occurred. If this keeps happening, please email the following info to support@sensehq.com',
    details: `${String(error.error)}
Status: ${error.response.status}
Sentry: ${errorId}

Request:
${JSON.stringify(error.options, null, 2)}

Response:
${JSON.stringify(error.response, null, 2)}

Response Body:
${String(JSON.stringify(error.responseBody ?? null, null, 2))}`,
    ...extras,
  });

export const pushScheduledMessagesForContact =
  (back: () => mixed): ThunkSyncAction =>
  (dispatch: Dispatch) => {
    dispatch(
      pushModal({
        type: 'SCHEDULED_MESSAGES_FOR_CONTACT',
        routed: true,
        back,
      }),
    );
  };

export const pushEditUserModal =
  (accountId?: string): ThunkSyncAction =>
  (dispatch: Dispatch) => {
    dispatch(
      pushModal({
        type: 'CREATE_USER_MODAL',
        accountId,
      }),
    );
  };

export const popScheduledMessagesForContact =
  (): ThunkSyncAction => (dispatch: Dispatch, getState: GetState) => {
    const head = selectHead(getState());
    if (head && head.type === 'SCHEDULED_MESSAGES_FOR_CONTACT') {
      dispatch(popModal());
    }
  };

export const showCsvComingSoon = (): ThunkAction<boolean> =>
  showGenericAlert({
    text: 'To export your CSV, please remove the filters. CSV Exports with filtering options will be available soon.',
  });

export const pushEditReferralModal =
  ({
    label,
    selectedItemsInPage,
    referralId,
    programId,
    isAllSelected,
    totalJobRecords,
    page,
    extras,
  }: {
    label: string,
    selectedItemsInPage?: mixed,
    referralId?: string,
    programId?: string,
    isAllSelected?: boolean,
    totalJobRecords?: number,
    page?: number,
    extras?: $Shape<TitleBarDetailsModal>,
  }): ThunkSyncAction =>
  (dispatch: Dispatch) => {
    dispatch(
      pushModal({
        type: 'REFERRAL_BULK_ACTION_MODAL',
        label,
        selectedItemsInPage,
        referralId,
        programId,
        isAllSelected,
        totalJobRecords,
        pageNumber: page,
        extras,
      }),
    );
  };

export const pushJobsProgramsModal =
  (
    programs: {id: string, name: string}[],
    selectedItemsInPage?: mixed,
    programId?: string,
  ): ThunkSyncAction =>
  (dispatch: Dispatch) => {
    dispatch(
      pushModal({
        type: 'JOB_PROGRAMS_MODAL',
        programs,
        selectedItemsInPage,
        programId,
      }),
    );
  };

export const pushBulkUnlinkJobsPrograms =
  (
    program: {id: number, name: string},
    selectedItemsInPage: string[],
    isAllSelected: boolean,
    programFilterId: string,
    page?: number,
  ): ThunkSyncAction =>
  (dispatch: Dispatch) => {
    dispatch(
      pushModal({
        type: 'BULK_UNLINK_JOBS_REFERRAL_PROGRAMS',
        selectedItemsInPage,
        program,
        isAllSelected,
        programFilterId,
        pageNumber: page,
      }),
    );
  };
