// @flow strict
import * as React from 'react';
import {type ReferralFiltersPayload} from 'src/types/referral-v2';
import {useDispatch} from 'react-redux';
//$FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';
import {
  type ToastProps,
  type ToastSemanticType,
  CircularLoader,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  SubTitleLarge,
  BodyMedium,
  Toast,
  ToastBody,
  ToastTitle,
} from '@spaced-out/ui-design-system/lib/components/index';
import css from 'src/components/referral-v2/payouts/payouts.css';


const ReferralConfirmModal = ({
  selectedReferralCount,
  actionableCandidateCount,
  handleBulkUpdate,
  status,
  totalRecords,
  isAllItemsSelected,
  from,
  filters,
  isModalOpen,
  onClose,
}: {
  selectedReferralCount: number,
  actionableCandidateCount: number,
  handleBulkUpdate: (string) => Promise<mixed>,
  totalRecords: number,
  isAllItemsSelected: boolean,
  status: string,
  from: string,
  filters: ReferralFiltersPayload[],
  isModalOpen: boolean,
  onClose: (SyntheticEvent<HTMLElement>) => mixed,
}): React.Node => {
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [bulkActionCount, setBulkActionCount] = React.useState(null);
  const [bulkActionCountLoading, setBulkActionCountLoading] =
    React.useState(false);

  const handleSubmit = async () => {
    setIsSubmit(true);
    await handleBulkUpdate(status);
    setIsSubmit(false);
    setBulkActionCount(null);
  };

  const referralCount = isAllItemsSelected
    ? totalRecords
    : selectedReferralCount;

  const actionReferralCount = isAllItemsSelected
    ? bulkActionCount
    : actionableCandidateCount;

  React.useEffect(() => {
    if (isAllItemsSelected && isModalOpen) {
      let payload = {filters};
      if (from === 'tracking') {
        payload = {...payload, status};
      }
      setBulkActionCountLoading(true);
      dispatch(
        reduxApi.post(`referral/${from}/bulk-update/count`, payload),
      ).then(({count}: {count: number}) => {
        setBulkActionCount(count);
        setBulkActionCountLoading(false);
      });
    }
  }, [status, isModalOpen]);

  return (
    <Modal
      onClose={onClose}
      isOpen={isModalOpen}
      initialFocus={2}
      size="medium"
    >
      <ModalHeader onCloseButtonClick={onClose}>
        <SubTitleLarge>Change the Status?</SubTitleLarge>
      </ModalHeader>
      <ModalBody className={css.confirmPaymentModalText}>
        {bulkActionCountLoading ? (
          <CircularLoader className={css.loader} size="small" />
        ) : (
          <>
            <BodyMedium>
              Only {actionReferralCount} of your {referralCount} selected
              referrals can be mark as {status}
            </BodyMedium>
          </>
        )}
      </ModalBody>

      <ModalFooter>
        <Button type="tertiary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          isLoading={isSubmit}
          disabled={!actionReferralCount}
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ReferralConfirmModal;

export const toastMessage = ({
  message,
  title,
  semantic = 'success',
}: {
  message: string,
  title?: string,
  semantic?: ToastSemanticType,
}): React$Element<(props: ToastProps) => React.Node> => {
  return (
    <Toast initialFocus={0} semantic={semantic}>
      {title && <ToastTitle>{title}</ToastTitle>}
      <ToastBody>{message}</ToastBody>
    </Toast>
  );
};
