// @flow strict

import * as React from 'react';
import css from './task-status-icon.css';
import {classify} from 'src/utils/classify';
import type {Task} from 'src/components/agency-onboard/types/side-panel-comp.js';

import InProgressClockIcon from 'src/images/icons/in-progress-clock.svg';
import CircleIcon from 'src/images/icons/circle.svg';
import CompletedIcon from 'src/images/icons/verified.svg';


type TaskStatusIconProps = {
  task: Task,
};

const taskStatusEnum = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In progress',
  COMPLETED: 'Completed',
};

export default function TaskStatusIcon({
  task,
}: TaskStatusIconProps): React.Node {
  return (
    <div>
      {task.status === taskStatusEnum.PENDING && (
        <CircleIcon className={classify(css.statusIcon, css.pendingIcon)} />
      )}
      {task.status === taskStatusEnum.IN_PROGRESS && (
        <InProgressClockIcon
          className={classify(css.statusIcon, css.inProgressIcon)}
        />
      )}
      {task.status === taskStatusEnum.COMPLETED && (
        <CompletedIcon
          className={classify(css.statusIcon, css.completedIcon)}
        />
      )}
    </div>
  );
}
