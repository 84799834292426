// @noflow

import * as React from 'react';

import logger from 'src/utils/logger';

import Loading from 'src/components/lib/loading';


type Options = {
  loader: () => Promise<{default: React.ComponentType<*>}>,
  loading?: React.ComponentType<*>,
};

export default function Loadable(options: Options) {
  let Component;

  if (!options.loading) {
    options.loading = Loading;
  }

  return class Loadable extends React.Component<
    {
      loading: React.ComponentType<*>,
    },
    {loaded: boolean},
  > {
    static defaultProps = {
      loading: options.loading,
    };

    constructor(props: Options) {
      super(props);

      this.state = {
        loaded: false,
      };

      let constructed = false;

      options.loader().then(
        module => {
          Component = module.default;

          if (!constructed) {
            this.state.loaded = true;
          } else {
            this.setState({
              loaded: true,
            });
          }
        },
        error => {
          logger.error('Loadable', error);
        },
      );

      constructed = true;
    }

    render() {
      const Loading = this.props.loading;
      return this.state.loaded ? <Component {...this.props} /> : <Loading />;
    }
  };
}
