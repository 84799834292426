// @flow strict-local

import type {
  Role,
  RolesAction,
  Privilege,
  Concern,
  ErrorMessagesByType,
} from 'src/action-creators/roles';

import keyBy from 'lodash/keyBy';
import difference from 'lodash/difference';
import union from 'lodash/union';
import omit from 'lodash/omit';

import {
  RECEIVE,
  RECEIVE_ROLE,
  REMOVE_ROLE,
  START_EDITING,
  START_EDITING_ERROR,
  CLEAR_EDITING,
  MARK_ERRORS_SHOWABLE,
  EDIT_ROLE_NAME,
  EDIT_ROLE_PRIVILEGES,
  EDIT_ROLE_CONFIGURATION,

  // Privileges
  VIEW_WORKFLOWS,
  EDIT_WORKFLOWS,
  VIEW_AUDIENCE_MEMBERS,
  EDIT_AUDIENCE_MEMBERS,
  VIEW_ANALYTICS,
  EDIT_SETTINGS,
  EDIT_PERMISSIONS,
  USE_MESSAGING,
  MANAGE_OPT_OUTS,
  EDIT_CHATBOT,
  ADMIN_REFERRALS,
  RECRUITER_REFERRALS,
  MANAGE_CALENDAR,
  MANAGE_TEAM_TEMPLATES,
  TRM_ADMIN,
  TRM_VIEW,
  VIEW_REFERRALS_V2,
  EDIT_REFERRALS_V2,
  MANAGE_SECURITY,
  ADMIN_REFERRALS_V2,
  USE_JD_GENERATOR,
  USE_PRESCREENING_GENERATOR,
  USE_RESUME_GENERATOR,
  USE_ENGAGE_CONTENT_GENERATOR,
  USE_MESSAGING_SMS_GENERATOR,
  PAGES,
  LEADS,
  VIEW_PAGES,
  EDIT_PAGES,
  ADMIN_PAGES,
  VIEW_LEAD_MANAGEMENT,
  ADMIN_LEAD_MANAGEMENT,
  EDIT_LEAD_MANAGEMENT,
  VIEW_AEP_WORKFLOWS,
  EDIT_AEP_WORKFLOWS,
  USE_SURVEY_SUMMARY_GENERATOR,
  VIEW_JOBS,
  ADMIN_JOBS,
} from 'src/action-creators/roles';


export const noAccess = 'noAccess';
export const view = 'view';
export const edit = 'edit';
export const use = 'use';
export const manage_own_calendar = 'manage_own_calendar';
export const manage_opt_opts = 'manage_opt_opts';
export const manage_own_and_team_calendars = 'manage_own_and_team_calendars';
export const admin = 'admin';
export const jd_generator = 'jd_generator';
export const prescreening_generator = 'prescreening_generator';
export const resume_generator = 'resume_generator';
export const engage_content_generator = 'engage_content_generator';
export const messaging_sms_generator = 'messaging_sms_generator';
export const survey_summary_generator = 'survey_summary_generator';

type AccessLevelsByConcern = {
  [concern: Concern]: {
    [level: string]: {add: Privilege[], remove: Privilege[]},
  },
};

export const accessLevelsByConcern: AccessLevelsByConcern = {
  WORKFLOWS: {
    [noAccess]: {add: [], remove: [VIEW_WORKFLOWS, EDIT_WORKFLOWS]},
    [view]: {add: [VIEW_WORKFLOWS], remove: [EDIT_WORKFLOWS]},
    [edit]: {add: [VIEW_WORKFLOWS, EDIT_WORKFLOWS], remove: []},
  },
  ANALYTICS: {
    [noAccess]: {add: [], remove: [VIEW_ANALYTICS]},
    [view]: {add: [VIEW_ANALYTICS], remove: []},
  },
  AUDIENCE_MEMBERS: {
    [noAccess]: {
      add: [],
      remove: [VIEW_AUDIENCE_MEMBERS, EDIT_AUDIENCE_MEMBERS],
    },
    [view]: {add: [VIEW_AUDIENCE_MEMBERS], remove: [EDIT_AUDIENCE_MEMBERS]},
    [edit]: {add: [EDIT_AUDIENCE_MEMBERS, VIEW_AUDIENCE_MEMBERS], remove: []},
  },
  MESSAGING: {
    [noAccess]: {add: [], remove: [USE_MESSAGING]},
    [use]: {add: [USE_MESSAGING], remove: []},
  },
  MANAGE_OPT_OUTS: {
    [noAccess]: {add: [], remove: [MANAGE_OPT_OUTS]},
    [manage_opt_opts]: {add: [MANAGE_OPT_OUTS], remove: []},
  },
  CHATBOT: {
    [noAccess]: {add: [], remove: [EDIT_CHATBOT]},
    [edit]: {add: [EDIT_CHATBOT], remove: []},
  },
  SETTINGS: {
    [noAccess]: {add: [], remove: [EDIT_SETTINGS]},
    [edit]: {add: [EDIT_SETTINGS], remove: []},
  },
  PERMISSIONS: {
    [noAccess]: {add: [], remove: [EDIT_PERMISSIONS]},
    [edit]: {add: [EDIT_PERMISSIONS], remove: []},
  },
  REFERRAL: {
    [noAccess]: {add: [], remove: [ADMIN_REFERRALS, RECRUITER_REFERRALS]},
    [view]: {add: [RECRUITER_REFERRALS], remove: [ADMIN_REFERRALS]},
    [edit]: {add: [ADMIN_REFERRALS], remove: []},
  },
  SCHEDULER: {
    [noAccess]: {
      add: [],
      remove: [MANAGE_CALENDAR, MANAGE_TEAM_TEMPLATES],
    },
    [manage_own_calendar]: {
      add: [MANAGE_CALENDAR],
      remove: [MANAGE_TEAM_TEMPLATES],
    },
    [manage_own_and_team_calendars]: {
      add: [MANAGE_CALENDAR, MANAGE_TEAM_TEMPLATES],
      remove: [],
    },
  },
  TRM: {
    [noAccess]: {add: [], remove: [TRM_ADMIN, TRM_VIEW]},
    [view]: {add: [TRM_VIEW], remove: [TRM_ADMIN]},
    [edit]: {add: [TRM_ADMIN], remove: []},
  },
  REFERRAL_V2: {
    [noAccess]: {
      add: [],
      remove: [VIEW_REFERRALS_V2, EDIT_REFERRALS_V2, ADMIN_REFERRALS_V2],
    },
    [view]: {
      add: [VIEW_REFERRALS_V2],
      remove: [EDIT_REFERRALS_V2, ADMIN_REFERRALS_V2],
    },
    [edit]: {
      add: [VIEW_REFERRALS_V2, EDIT_REFERRALS_V2],
      remove: [ADMIN_REFERRALS_V2],
    },
    [admin]: {
      add: [VIEW_REFERRALS_V2, EDIT_REFERRALS_V2, ADMIN_REFERRALS_V2],
      remove: [],
    },
  },
  SECURITY: {
    [noAccess]: {add: [], remove: [MANAGE_SECURITY]},
    [edit]: {add: [MANAGE_SECURITY], remove: []},
  },
  SENSE_AI: {
    [jd_generator]: {add: [USE_JD_GENERATOR], remove: []},
    [prescreening_generator]: {add: [USE_PRESCREENING_GENERATOR], remove: []},
    [resume_generator]: {add: [USE_RESUME_GENERATOR], remove: []},
    [engage_content_generator]: {
      add: [USE_ENGAGE_CONTENT_GENERATOR],
      remove: [],
    },
    [messaging_sms_generator]: {add: [USE_MESSAGING_SMS_GENERATOR], remove: []},
    [survey_summary_generator]: {
      add: [USE_SURVEY_SUMMARY_GENERATOR],
      remove: [],
    },
  },
  PAGES: {
    [noAccess]: {
      add: [],
      remove: [VIEW_PAGES, EDIT_PAGES, ADMIN_PAGES],
    },
    [view]: {
      add: [VIEW_PAGES],
      remove: [EDIT_PAGES, ADMIN_PAGES],
    },
    [edit]: {
      add: [EDIT_PAGES, VIEW_PAGES],
      remove: [ADMIN_PAGES],
    },
    [admin]: {add: [ADMIN_PAGES, EDIT_PAGES, VIEW_PAGES], remove: []},
  },
  LEADS: {
    [noAccess]: {
      add: [],
      remove: [
        VIEW_LEAD_MANAGEMENT,
        ADMIN_LEAD_MANAGEMENT,
        EDIT_LEAD_MANAGEMENT,
      ],
    },
    [view]: {
      add: [VIEW_LEAD_MANAGEMENT],
      remove: [EDIT_LEAD_MANAGEMENT, ADMIN_LEAD_MANAGEMENT],
    },
    [edit]: {
      add: [EDIT_LEAD_MANAGEMENT, VIEW_LEAD_MANAGEMENT],
      remove: [ADMIN_LEAD_MANAGEMENT],
    },
    [admin]: {
      add: [EDIT_LEAD_MANAGEMENT, VIEW_LEAD_MANAGEMENT, ADMIN_LEAD_MANAGEMENT],
      remove: [],
    },
  },
  AUTOMATION_WORKFLOWS: {
    [noAccess]: {add: [], remove: [VIEW_AEP_WORKFLOWS, EDIT_AEP_WORKFLOWS]},
    [view]: {add: [VIEW_AEP_WORKFLOWS], remove: [EDIT_AEP_WORKFLOWS]},
    [edit]: {add: [VIEW_AEP_WORKFLOWS, EDIT_AEP_WORKFLOWS], remove: []},
  },
  JOBS: {
    [noAccess]: {
      add: [],
      remove: [VIEW_JOBS, ADMIN_JOBS],
    },
    [view]: {
      add: [VIEW_JOBS],
      remove: [ADMIN_JOBS],
    },
    [admin]: {
      add: [ADMIN_JOBS, VIEW_JOBS],
      remove: [],
    },
  },
};

const nextPrivileges = (prevPrivileges, concern, level): Privilege[] => {
  const {add, remove} = accessLevelsByConcern[concern][level];
  return union(difference(prevPrivileges, remove), add);
};

type RolesById = {
  [id: string]: Role,
};

export type RolesState = {
  data: RolesById,
  editing: ?Role,
  errors: ErrorMessagesByType,
  showErrors: boolean,
};

const initialState = {
  data: {},
  editing: null,
  errors: {},
  showErrors: false,
};

const validateRole = (role): ErrorMessagesByType => {
  if (!role) {
    return {};
  }

  const {name} = role;
  const errors = {};
  if (name.trim().length === 0) {
    errors.name = ['Enter a name for this role'];
  }
  return errors;
};

export default (
  state: RolesState = initialState,
  action: RolesAction,
): RolesState => {
  switch (action.type) {
    case RECEIVE:
      return {...state, data: keyBy(action.payload, (role) => role.id)};

    case RECEIVE_ROLE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
      };

    case REMOVE_ROLE:
      return {...state, data: omit(state.data, action.payload)};

    case START_EDITING:
      return {
        ...state,
        editing: action.payload,
        errors: validateRole(action.payload),
        showErrors: false,
      };

    case START_EDITING_ERROR:
      return {...state, editing: null, errors: action.payload};

    case CLEAR_EDITING:
      return {...state, editing: null, showErrors: false};

    case MARK_ERRORS_SHOWABLE:
      return {...state, showErrors: true};

    case EDIT_ROLE_NAME: {
      const currentRole = state.editing;
      if (!currentRole) {
        return state;
      }

      const errors = validateRole(action.payload);
      return {
        ...state,
        errors,
        showErrors: Object.values(errors).length > 0,

        editing: {
          ...currentRole,
          name: action.payload.name,
        },
      };
    }

    case EDIT_ROLE_PRIVILEGES: {
      if (!state.editing) {
        return state;
      }

      const {privileges} = state.editing;
      const {concern, level} = action.payload;
      return {
        ...state,
        editing: {
          ...state.editing,
          privileges: nextPrivileges(privileges, concern, level),
        },
      };
    }

    case EDIT_ROLE_CONFIGURATION: {
      if (!state.editing) {
        return state;
      }

      const {configurations} = state.editing;
      const {configurationKey, value} = action.payload;
      return {
        ...state,
        editing: {
          ...state.editing,
          configurations: {
            ...configurations,
            [configurationKey]: value,
          },
        },
      };
    }
  }

  return state;
};
