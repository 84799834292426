// @flow strict

import cloneDeep from 'lodash/cloneDeep';

import type {
  StandardEntityDesignationResponse,
  Designation,
  StandardEntityDesignationCount,
} from 'src/types/field-mapping.js';


export const STANDARD_MAPPING_TEXT =
  'Journeys now have a brand new experience, with a more intuitive interface and an enhanced audience builder!';

export const getDesignationCount = (
  standardEntityDesignations: StandardEntityDesignationResponse,
): StandardEntityDesignationCount => {
  let totalDesignationCount = 0;
  let mappedDesignationCount = 0;
  for (const standardEntityDesignationObj of standardEntityDesignations) {
    const designations: Designation[] =
      standardEntityDesignationObj.designations;
    for (const designation of designations) {
      totalDesignationCount++;
      if (designation.entity_attribute_name !== null) {
        mappedDesignationCount++;
      }
    }
  }
  const designationCount = {
    mapped: mappedDesignationCount,
    total: totalDesignationCount,
  };

  return designationCount;
};

/**
 *
 * @param {*} standardEntityDesignations
 * @param {*} localStandardEntityDesignations
 * @returns a boolean true if there is any change, otherwise false.
 * Note: This function assumes that both the arrays are in same order, and this covers our 100% usecase
 * as we are not changing order of objects at any point of time. we are only changing entity_attribute_id,
 * and entity_attribute_name in local array.
 */

export const getIfAnyChangesInDesignation = (
  standardEntityDesignations: StandardEntityDesignationResponse,
  localStandardEntityDesignations: StandardEntityDesignationResponse,
): boolean => {
  const standardEntityArrLength = standardEntityDesignations.length;
  for (let i = 0; i < standardEntityArrLength; i++) {
    if (
      standardEntityDesignations[i].standard_entity_type ===
      localStandardEntityDesignations[i].standard_entity_type
    ) {
      const designationsArr: Designation[] =
        standardEntityDesignations[i].designations;
      const localDesignationsArr: Designation[] =
        localStandardEntityDesignations[i].designations;
      const desiArrayLength = designationsArr.length;
      for (let j = 0; j < desiArrayLength; j++) {
        if (
          designationsArr[j].designation_name ===
          localDesignationsArr[j].designation_name
        ) {
          if (
            designationsArr[j].entity_attribute_id !==
              localDesignationsArr[j].entity_attribute_id ||
            designationsArr[j].entity_attribute_name !==
              localDesignationsArr[j].entity_attribute_name
          ) {
            return true;
          }
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  }
  return false;
};

export const cloneDesignationsResponse = (
  oldObject: StandardEntityDesignationResponse,
): StandardEntityDesignationResponse => cloneDeep(oldObject);
