// @flow strict-local
import type {Broadcast} from 'src/types/broadcasts';
import type {Contact} from 'src/types/contacts';
import type {AudienceMember} from 'src/types/audience-member';
import type {AuthedUserAccount} from 'src/types/account';


type MessageDirectionIncoming = 'incoming';
type MessageDirectionOutgoing = 'outgoing';
export type MessageDirection =
  | MessageDirectionIncoming
  | MessageDirectionOutgoing;
export const MSG_DIRECTION_INCOMING: MessageDirectionIncoming = 'incoming';
export const MSG_DIRECTION_OUTGOING: MessageDirectionOutgoing = 'outgoing';
export const MSG_TYPE_PENDING = 'pending';

export type MessageOriginType = 'core' | 'messaging' | 'compliance_code';
export type MessageStatus =
  | 'queued'
  | 'sent'
  | 'delivered'
  | 'received'
  | 'failed'
  | 'skipped'
  | 'skipped_for_not_in_ats'
  | 'receiver_does_not_have_whatsapp';
export type GeneralMessageStatus = MessageStatus | 'pending';

export type OldMessage = {
  id: string,
  agentId: string,
  toPhone: string,
  fromPhone: string,
  contactId: string,
  body: string,
  media: MessageMedia[],
  direction: MessageDirection,
  status: MessageStatus,
  timeCreated: string,
  timeRead: ?string,
  scheduledMessageId: string,
  statusTimeUpdated?: string,
  origin: MessageOriginType,
};

export type MessagePending = {
  id: string,
  status: 'pending',
  timeCreated: string,
  externalPhone: string,
  phoneNumberSetId: string,
  threadId: ?string,
  agentId?: ?string,
  body: string,
  direction: MessageDirection,
  media_ids?: string[],
  // Was there an error POSTing message to API?
  error: boolean,
  errorDetails?: string[],
  templateId?: string,
};

// rearchitecture types

export type MessageOrigin = 'messaging' | 'core';

export type Message = {
  id: string,
  agentId: string,
  broadcastId?: string,
  autoreplyId: ?string,
  threadId: string,
  threadMessage: string,
  direction: MessageDirection,
  timeCreated: string,
  timeRead: ?string,
  body: string,
  media: MessageMedia[],
  origin: MessageOrigin,
  status: MessageStatus,
  statusTimeUpdated: string,
  media: MessageMedia[],
  errorDetails: string[],
  isKeywordSearch?: boolean,
};

export type Thread = {
  id: string,
  sensePhone: string,
  count: number,
  externalPhone: string,
  phoneNumberSet: string,
  contactId: string,
  audienceMemberIds: string[],
  lastMessageRead: number,
  unreadCount: number,
  displayTime: string,
  archived: boolean,
  starred: boolean,
  blocked: boolean,
  hidden: boolean,
  optedOut: boolean,
  agents: AuthedUserAccount[],
  threadFilters?: string[],
  phoneNumberSetId: string,
  lastMessageReadByAgents: {[agentId: string]: string},
  contextEvents: Array<ConversationContextEvent>,
  sessionType: string,
  sessionExpiryTime: Date,
  queueId?: string,
  conversationName?: string,
  channel: string,
};

export type ThreadMetaMessage = {
  id: string,
  type:
    | 'incoming'
    | 'outgoing'
    | 'sent-broadcast'
    | 'scheduled-broadcast'
    | 'coreMessages',
  agent: ?{agentId: string, name: string},
  candidate: {fullName: string, tags: {phoneNumber: string}},
  timeCreated: string,
  body?: string,
  isMetadata: true,
};

export type ThreadMetadata = Array<ThreadMetaMessage>;

export type InboxThreadResponse = Thread & {
  message: Message,
};

export type InboxThread = {
  thread: Thread,
  newestMessage: Message,
};

export const INBOX_FILTER = {
  UNREAD: 'unread',
  READ: 'read',
  NOT_REPLIED: 'not_replied',
  ARCHIVED: 'archived',
  STARRED: 'starred',
  COVID_19: 'covid-19',
  OPEN_SESSION: 'open_session',
  CLOSED_SESSION: 'closed_session',
};

export type InboxFilter = null | $Values<typeof INBOX_FILTER>;

export const INBOX_TYPE = {
  QUEUE: 'queue',
  PHONE: 'phone',
};

export const QUEUE_INBOX_FILTER = {
  LIVE_CHAT: 'live_chat',
  RESOLVED_CHAT: 'resolved_chat',
};

export type ThreadResponse = Thread & {
  messages: Message[],
  broadcasts: Broadcast[],
  contact?: Contact,
  audienceMembers: AudienceMember[],
};

export type PopulatedThread = Thread & {
  contact?: Contact,
  audienceMembers: AudienceMember[],
};

export type MetaThread = {
  // TODO (kyle): i kinda feel like this prop should always be present.
  thread?: Thread,
  contact?: Contact,
  audienceMembers: AudienceMember[],
};

export type MessageMedia = {
  agencyId: string,
  agentId: string,
  contentType: 'image/jpeg' | 'image/gif' | 'image/png',
  fileSize: number,
  id: string,
  name: string,
  twilioUrl: ?string,
  url: string,
};

export type ApiMessageMedia = {
  agency_id: string,
  agent_id: string,
  content_type: 'image/jpeg' | 'image/gif' | 'image/png',
  file_size: number,
  id: string,
  name: string,
  twilio_url: ?string,
  url: string,
};

export type InboxNotification = {
  id: string,
  type: 'archived',
  threadId: string,
  timeCreated: Date,
  timeoutId: TimeoutID,
};

export type UnreadCounts = {
  count: number,
  thread_count: number,
  phone_number_sets_count?: number,
  queues_count?: number,
};

export type UnreadCountsByPhoneNumberSet = {
  [phoneNumberSetId: string]: UnreadCounts,
};

export type UnreadCountsByQueue = {
  [inboxId: string]: UnreadCounts,
};

export type UnreadCountsOfAllInbox = {
  [inboxId: string]: UnreadCounts,
};

export type MessageHistoryWindow = {
  mostRecentMessage: Message,
  complete: boolean,
};

export type ConversationContextEvent = {
  body: Array<[string, {b?: Boolean}]>,
  eventTimeCreated: string,
  eventType: string,
  icon: {url: string},
  id: string,
  metadata: {messageId: string, phoneNumberSetId: string, agentName: string},
  thread_id: string,
  isContextEvent: true,
};

type IdAndName = {
  id: string,
  name: string,
};

export type ConversationAddedToQueueEvent = {
  agent: IdAndName,
  inbox_id: string,
  position_in_queue: number,
  queue: IdAndName,
  time_added: string,
};

export type ContentWarningMessageDetails = {
  warning_type: string,
  warning_message: string,
  show_resolve_button: boolean,
};
export type ContentWarningBannerDataType = {
  content_warning_types: Array<string>,
  content_warning_messages: Array<ContentWarningMessageDetails>,
};

export type ChannelTypeInfo = {
  key: string,
  label: string,
  count: number,
};

export type MeetingsEventData = {
  candidateName: string,
  candidatePhone: string,
  candidateEmail: string,
  jobTitle: string,
  requisitionId: string,
  meetingDescription: string,
  applicationLink: string,
  anchorExternalSourceId: string,
};

export type ExternalEventDetails = {
  phoneNumbers: string[],
  channel: string,
  origin: string,
};
