// @flow strict

import * as React from 'react';

import classify from 'src/utils/classify';

import CheckedBox from 'src/images/checked-box.svg';
import UncheckedBox from 'src/images/unchecked-box.svg';

import css from './checkbox.css';


type CheckboxProps = {
  checked: boolean,
  name?: string,
  onChange?: (evt: SyntheticInputEvent<HTMLInputElement>) => mixed,
  className?: string,
  svgStyle?: {[cssProp: string]: mixed},
  disabled?: mixed,
};

export default ({
  checked,
  name,
  onChange,
  className,
  svgStyle,
  disabled,
}: CheckboxProps): React.Element<'span'> => (
  <span
    className={classify(
      css.fancyCheckbox,
      checked ? css.checked : css.unchecked,
      disabled ? css.disabled : null,
      className,
    )}
  >
    {checked ? (
      <CheckedBox style={svgStyle} />
    ) : (
      <UncheckedBox style={svgStyle} />
    )}
    <input
      type="checkbox"
      className={css.hiddenCheckbox}
      name={name}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
  </span>
);
