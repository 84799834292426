// @flow strict
import type {ConversationSummary, ThumbValue} from 'src/types/data-review';
import type {GraphDetails} from 'src/types/chatbot';

import * as React from 'react';
import {useDispatch, useStore} from 'react-redux';

//$FlowIgnore[nonstrict-import]
import {displayDateTime as formatDateTime} from 'src/utils/date-time-2';
import {useChatbotApiPath} from 'src/utils/chatbot';

//$FlowIgnore[nonstrict-import]
import {ThumbCell} from './data-review.jsx';

import {UnstyledButton, Button} from 'src/components/lib/new-button';

//$FlowIgnore[nonstrict-import]
import {popModal} from 'src/action-creators/modal';

import ChevronRightIcon from 'src/images/icons/chevron_right.svg';
import DownloadIcon from 'src/images/icons/download.svg';

import css from './data-review-sidepanel.css';


type SidePanelProps = {
  sortedIds: string[],
  data: ConversationSummary,
  rows: ConversationSummary[],
  flows: {[flow_id: string]: GraphDetails},
  handleOpenConversation: (id: string) => void,
  checkedState: {[conversation_id: string]: ThumbValue},
  handleReviewChange: (
    conversation_id: string,
    value: ThumbValue,
  ) => ThumbValue,
};

const DataReviewSidepanel = ({
  sortedIds,
  data,
  rows,
  flows,
  handleOpenConversation,
  checkedState,
  handleReviewChange,
}: SidePanelProps): React.Node => {
  const reduxStore = useStore();
  // $FlowFixMe
  const chatbotApiPath = useChatbotApiPath(reduxStore.getState().env ?? {});
  const writebackData = data.transcript_preview.writeback_data ?? [];

  const maybeName = writebackData.find((wbd) => wbd.name === 'name')?.text;
  const idx = sortedIds.findIndex((id) => id === data.id) ?? 0;
  const openPrevConversation =
    idx > 0 ? () => handleOpenConversation(sortedIds[idx - 1]) : null;
  const openNextConversation =
    idx < sortedIds.length - 1
      ? () => handleOpenConversation(sortedIds[idx + 1])
      : null;

  // this is here because the modal doesn't get rerendered between conversations
  // so the local value of the data review status persists between pages
  // but if we don't use this, the checkedState value doesn't update here which
  // makes the thumbs look broken...
  const [modalReviewStatus, setReviewStatus] = React.useState<ThumbValue>(null);
  React.useEffect(() => {
    setReviewStatus(checkedState[data.id] ?? data.review_status);
  }, [data.id]);
  const dispatch = useDispatch();

  const handleDownloadFile = (fileContainer, filePath, fileName) =>
    fetch(
      `${chatbotApiPath}/file-access-proxy/download?file_container=${fileContainer}&file_path=${filePath}&file_name=${encodeURIComponent(
        fileName,
      )}`,
    ).then((data) => {
      window.open(data.url);
    });

  const files = data.transcript_preview.messages.filter(({file_location}) =>
    Boolean(file_location),
  );

  const hasFiles = files.length > 0;

  return (
    <div className={css.sidePanel}>
      <div className={css.topUi}>
        {maybeName && <h2>{maybeName}</h2>}
        <div className={css.thumbBox}>
          <ThumbCell
            value={modalReviewStatus}
            onChange={async (value) => {
              setReviewStatus(value);
              const committedValue = await handleReviewChange(data.id, value);
              setReviewStatus(committedValue);
            }}
          />
        </div>
        <p>
          <strong>Source:</strong> {flows[data.flow_id].name}
        </p>
        <p>
          <strong>Chat initiated: </strong>
          {formatDateTime(data.date_responded)}
        </p>
        {hasFiles && (
          <p className={css.fileContainer}>
            <strong>Uploaded files: </strong>
            <div className={css.files}>
              {files.map(({file_location, text}) => (
                <div
                  className={css.file}
                  onClick={() => {
                    if (file_location) {
                      handleDownloadFile(
                        file_location.file_container,
                        file_location.file_path,
                        text,
                      );
                    }
                  }}
                >
                  {text} <DownloadIcon className={css.downloadIcon} />
                </div>
              ))}
            </div>
          </p>
        )}
      </div>

      <div className={css.transcriptData}>
        {data.transcript_preview.messages.map((message, index) =>
          message.user_type === 'candidate' ? (
            <p key={index} className={css.botResponse}>
              <strong>Response: </strong> {message.text}
            </p>
          ) : (
            <p key={index}>
              {message.user_type === 'agent' ? '[agent] ' : ''}
              {message.text}
            </p>
          ),
        )}
      </div>

      <div className={css.bottomUi}>
        <div className={css.prevNexBox}>
          <UnstyledButton
            onClick={openPrevConversation}
            disabled={!openPrevConversation}
          >
            <ChevronRightIcon className={css.chevronLeft} />
          </UnstyledButton>
          <UnstyledButton
            onClick={openNextConversation}
            disabled={!openNextConversation}
          >
            <ChevronRightIcon className={css.chevronRight} />
          </UnstyledButton>
        </div>
        <Button
          type="primary"
          size="medium"
          onClick={() => {
            dispatch(popModal());
          }}
        >
          Done
        </Button>
      </div>
    </div>
  );
};

export default DataReviewSidepanel;
