// @flow strict

export function formatList(list: string[], operator: string = 'and'): string {
  const {length} = list;
  if (length > 2) {
    return `${list.slice(0, -1).join(', ')}, ${operator} ${list[length - 1]}`;
  }
  return list.join(` ${operator} `);
}

export function bufferList<T>(list: T[], glue: T, suffix: T): T[] {
  const {length} = list;
  const result = [];

  if (length === 2) {
    return [list[0], suffix, list[1]];
  }

  for (let i = 0; i < length; i++) {
    const item = list[i];

    result.push(item);

    if (i < length - 1) {
      result.push(glue);
    }
    if (i === length - 2) {
      result.push(suffix);
    }
  }

  return result;
}

export function formatPlural(
  value: number,
  one: string,
  other: string,
): string {
  return value === 1 ? one : other;
}

export function formatCount(count: number, one: string, other: string): string {
  return count <= 1 ? `${count} ${one}` : `${count} ${other}`;
}
