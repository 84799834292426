// @flow

import type {
  ThreadList,
  IndexThreadList,
  StateThreadList,
} from 'src/types/thread-lists';
import type {ThreadListAction} from 'src/action-creators/thread-lists';

import omit from 'lodash/omit';
import invariant from 'invariant';

import {
  RECEIVE_PAGE,
  RECEIVE_ONE,
  RECEIVE_SOME,
  RECEIVE_UPDATED,
  RECEIVE_BROADCASTS_FOR_THREAD,
  REMOVE_MEMBER,
  PAGE_LIMIT,
  REMOVE_ONE,
  ADD_ONE,
} from 'src/action-creators/thread-lists';
import {
  RECEIVE_BROADCAST,
  RECEIVE_BROADCASTS,
  RECEIVE_BROADCASTS_PAGINATED,
} from 'src/action-creators/broadcasts';

import type {Thread} from 'src/types/messages';


export type State = {
  list: string[],
  listDone: {
    [string]: boolean,
  },
  map: {
    [string]: StateThreadList,
  },
  threads: Thread[],
};

const initialState = {
  list: [],
  listDone: Object.freeze({}),
  map: Object.freeze({}),
  threads: [],
};

const reducer = (
  state: State = initialState,
  action: ThreadListAction,
): State => {
  switch (action.type) {
    case RECEIVE_PAGE: {
      const {threadLists, offset, phone_number_set_id} = action.payload;
      const map = reducePage(state, threadLists, offset, phone_number_set_id);
      return {
        ...state,
        map,
        // $FlowFixMe upgrade babel and begin porting to inexact types
        list: Object.keys(map),
        listDone: {
          ...state.listDone,
          [phone_number_set_id]: threadLists.length < PAGE_LIMIT,
        },
      };
    }

    case RECEIVE_ONE: {
      const threadList = action.payload;
      return {
        ...state,
        map: {
          ...state.map,
          [threadList.id]: {
            ...omit(threadList, ['audienceMembers', 'contacts', 'threads']),
            threadIds: threadList.threads.map(({id}) => id),
            // TODO[Ashwini] : fix below error introduced in 0.177.0
            // $FlowFixMe[not-an-object]
            ...(threadList.threads[0]?.phoneNumberSet && {
              phoneNumberSetId: threadList.threads[0].phoneNumberSet,
            }),
          },
        },
        // these threads are only to get the fullName and other details for broadcast chip, nothing to do with actual threads
        threads: threadList.threads,
      };
    }

    case ADD_ONE: {
      const {threadList, index = 0} = action.payload;

      return {
        ...state,
        list: [
          ...state.list.slice(0, index),
          threadList.id,
          ...state.list.slice(index, state.list.length),
        ],
        map: {
          ...state.map,
          [threadList.id]: {
            ...threadList,
          },
        },
      };
    }

    case RECEIVE_SOME: {
      return {
        ...state,
        map: reduceSome(state, action.payload),
      };
    }

    case RECEIVE_UPDATED: {
      const threadList = action.payload;
      return {
        ...state,
        map: {
          ...state.map,
          [threadList.id]: {
            ...state.map[threadList.id],
            // $FlowFixMe upgraded flow
            ...threadList,
          },
        },
      };
    }

    case REMOVE_MEMBER: {
      const {listId, threadId} = action.payload;

      const currentList = state.map[listId];

      invariant(
        currentList && currentList.threadIds,
        'Attempted to remove member from unloaded thread list.',
      );

      return {
        ...state,
        map: {
          ...state.map,
          [listId]: {
            ...currentList,
            threadIds: currentList.threadIds.filter((id) => id !== threadId),
          },
        },
      };
    }

    case REMOVE_ONE: {
      const {threadListId} = action.payload;
      return {
        ...state,
        list: (state.list.filter((id) => id !== threadListId): string[]),
        map: {
          ...omit(state.map, [threadListId]),
        },
      };
    }

    case RECEIVE_BROADCASTS_FOR_THREAD: {
      const threadLists = action.payload.broadcasts.map((broadcast) => {
        let threadList = state.map[broadcast.threadListId] || {
          id: broadcast.threadListId,
          oneOnOne: true,
        };

        if (!threadList.threadIds) {
          threadList = {...threadList, threadIds: [action.payload.threadId]};
        }

        return threadList;
      });

      return {
        ...state,
        map: reduceSome(state, threadLists),
      };
    }
    // $FlowFixMe[incompatible-type] [v1.32.0]
    case RECEIVE_BROADCASTS: {
      return {
        ...state,
        map: reduceSome(state, action.payload.map(reduceBroadcastToThreadList)),
      };
    }
    // $FlowFixMe[incompatible-type] [v1.32.0]
    case RECEIVE_BROADCASTS_PAGINATED: {
      return {
        ...state,
        map: reduceSome(
          state,
          action.payload.broadcasts.map(reduceBroadcastToThreadList),
        ),
      };
    }
    // $FlowFixMe[incompatible-type] [v1.32.0]
    case RECEIVE_BROADCAST: {
      return {
        ...state,
        map: reduceSome(state, [reduceBroadcastToThreadList(action.payload)]),
      };
    }
  }

  return state;
};

export default reducer;

function reduceSome(state, threadLists) {
  const map = {...state.map};

  for (const threadList of threadLists) {
    // $FlowFixMe[prop-missing]
    map[threadList.id] = {
      ...map[threadList.id],
      ...threadList,
    };
  }

  return map;
}

function reducePage(state, threadLists, offset, phone_number_set_id) {
  const oldLists = Object.entries(state.map);

  const filtredLists =
    offset === 0
      ? oldLists.filter(
          ([id, threadList]) =>
            // $FlowFixMe Object entries losing type
            phone_number_set_id !== threadList.phoneNumberSetId,
        )
      : oldLists;

  const map = filtredLists.reduce((map, [id, threadList]) => {
    map[id] = threadList;
    return map;
  }, {});

  for (const threadList of threadLists) {
    // $FlowFixMe[prop-missing]
    map[threadList.id] = {
      ...map[threadList.id],
      ...threadList,
    };
  }

  return map;
}

function reduceBroadcastToThreadList({threadListId, threadIds, name}) {
  return {
    id: threadListId,
    threadIds,
    name,
  };
}
