// @flow
import type {
  PhoneNumberAssignmentsMapType,
  PhoneNumberAssignmentType,
} from 'src/selectors/phone-numbers';
import type {AccountInvite} from 'src/types/account-invite';
import * as React from 'react';
import {useSelector} from 'react-redux';
import {keyBy} from 'src/utils/iterable';
import {AvatarIcon} from 'src/components/lib/contact-icon/contact-icon.jsx';
import FormattedPhone from 'src/components/lib/formatted-phone.jsx';
import GenericAvatarIcon from 'src/images/generic-avatar.svg';
import {MouseTip} from 'src/components/lib/mouse-tip/mouse-tip.jsx';

import css from './provisioned-phone-numbers.css';


export const PhoneNumberListItem = ({
  phoneNumber,
  numberAssignments,
}: {
  phoneNumber: string,
  numberAssignments: PhoneNumberAssignmentType,
}): React.Element<'div'> => (
  <div className={css.phoneNumber}>
    <FormattedPhone phone={phoneNumber} />
    <UsersWithNumber numberAssignments={numberAssignments} />
  </div>
);

export const PhoneNumbersList = ({
  phoneNumbers,
  onSelect,
}: {
  phoneNumbers: Array<PhoneNumberAssignmentType>,
  onSelect: (provisioned_phone_id: number, phone_number: string) => void,
}): React.Node =>
  phoneNumbers.map((numberAssignments) => {
    const {provisioned_phone_id, phone_number} = numberAssignments;

    return (
      <div
        key={String(provisioned_phone_id)}
        className={css.phoneNumberOption}
        onClick={() => {
          onSelect(parseInt(provisioned_phone_id), phone_number);
        }}
      >
        <PhoneNumberListItem
          phoneNumber={phone_number}
          numberAssignments={numberAssignments}
        />
      </div>
    );
  });

export const UsersWithNumber = ({
  numberAssignments,
}: {
  numberAssignments: PhoneNumberAssignmentType,
}): React.Element<'div'> => {
  const {agents, invites} = numberAssignments;
  const [accountsMap, invitesData] = useSelector((state) => [
    state.accounts.data,
    state.accountInvites.data,
  ]);
  // $FlowIssue Object.values
  const accountInvites: Array<AccountInvite> = Object.values(invitesData);
  const invitesMap = keyBy(accountInvites, (invite) => invite.inviteCode);

  const accountsDisplayData = [
    ...(agents || [])
      .filter((id) => accountsMap[id])
      .map((id) => accountsMap[id]),
    ...(invites || [])
      .filter((id) => invitesMap.has(id))
      .map((id) => invitesMap.get(id)),
  ].map((account) => ({
    name:
      account?.name ||
      `${account?.firstName || ''} ${account?.lastName || ''}`.trim() ||
      account?.email ||
      '',
    ...(account?.profilePicture && {
      profilePictureUrl: account.profilePicture?.url,
    }),
  }));

  return (
    <div className={css.usersWithNumber}>
      {accountsDisplayData[0] && (
        <MouseTip content={accountsDisplayData[0].name}>
          <div>
            <AvatarIcon
              profilePictureUrl={
                accountsDisplayData[0].profilePictureUrl
                  ? accountsDisplayData[0].profilePictureUrl
                  : undefined
              }
              displayName={accountsDisplayData[0].name}
              size={24}
            />
          </div>
        </MouseTip>
      )}
      {accountsDisplayData[1] && (
        <MouseTip
          content={accountsDisplayData
            .slice(1)
            .map((data) => data.name)
            .join(', ')}
        >
          <div>
            <AvatarIcon
              displayName={
                accountsDisplayData[2] ? '. .' : accountsDisplayData[1].name
              }
              profilePictureUrl={
                accountsDisplayData[1].profilePictureUrl
                  ? accountsDisplayData[1].profilePictureUrl
                  : undefined
              }
              size={24}
              className={css.secondAvatarIcon}
            />
          </div>
        </MouseTip>
      )}
    </div>
  );
};

export type ProvisionedPhoneNumbersProps = {
  assignNumber: (provisioned_phone_id: number, phone_number: string) => void,
  numberAssignmentsMap: PhoneNumberAssignmentsMapType,
};
export const ProvisionedPhoneNumbers = ({
  assignNumber,
  numberAssignmentsMap,
}: ProvisionedPhoneNumbersProps): React.Element<'div'> => {
  const [userSearch, setUserSearch] = React.useState('');

  const possiblePhone = userSearch.replace(/[^\d]/g, '');
  const [accountsMap, invitesData] = useSelector((state) => [
    state.accounts.data,
    state.accountInvites.data,
  ]);
  // $FlowIssue Object.values
  const accountInvites: Array<AccountInvite> = Object.values(invitesData);
  const invitesMap = keyBy(accountInvites, (invite) => invite.inviteCode);

  const numberAssignments = Object.keys(numberAssignmentsMap).map(
    (key) => numberAssignmentsMap[parseInt(key)],
  );

  const numberSearchKeys = React.useMemo(
    () =>
      numberAssignments.map((numberAssignment) => {
        const {agents, invites, phone_number} = numberAssignment;
        return (
          [
            ...(agents || [])
              .filter((id) => accountsMap[id])
              .map((id) => accountsMap[id]),
            ...(invites || [])
              .filter((id) => invitesMap.has(id))
              .map((id) => invitesMap.get(id)),
          ]
            .map((agentOrInvite) =>
              [
                agentOrInvite?.name || '',
                agentOrInvite?.firstName || '',
                agentOrInvite?.lastName || '',
                agentOrInvite?.email || '',
              ]
                .join(' ')
                .trim(),
            )
            .join(' ') + (phone_number ? ` ${phone_number}` : '')
        );
      }),
    [numberAssignments, accountsMap, invitesMap],
  );

  const filteredPhoneNumbers = numberAssignments.filter(
    (numberAssignment, i) => {
      const searchKey = numberSearchKeys[i];
      return (
        searchKey.includes(userSearch) ||
        (possiblePhone.length > 0 && searchKey.includes(possiblePhone))
      );
    },
  );

  return (
    <div className={css.root}>
      <input
        className={css.phoneNumberInput}
        value={userSearch}
        onChange={(event) => {
          setUserSearch(event.target.value);
        }}
      />
      <div className={css.numberContainer}>
        <PhoneNumbersList
          onSelect={assignNumber}
          phoneNumbers={filteredPhoneNumbers}
        />
      </div>
    </div>
  );
};
