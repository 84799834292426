// @noflow

import type {Report, KeyedReports} from 'src/types/messages-analytics';
import type {Action} from 'src/action-creators/messages-analytics';

import {
  RECEIVE,
  RECEIVE_KEYED,
  SET_QUERY,
} from 'src/action-creators/messages-analytics';


export type State = {
  report: ?Report,
  keyed_reports: KeyedReports,
  current_query?: string,
};

const initialState = {
  report: null,
  keyed_reports: {},
  current_query: null,
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case SET_QUERY:
      return {...state, current_query: action.payload};
    case RECEIVE:
      return {
        ...state,
        report: action.payload,
      };
    case RECEIVE_KEYED:
      return {
        ...state,
        keyed_reports: {
          ...state.keyed_reports,
          [action.payload.key]: action.payload.report,
        },
      };
  }

  return state;
};
