// @flow

import type {Dispatch, ThunkAction, ThunkSyncAction} from 'src/reducers';
import type {ReportStats, AnalyticsQuery} from 'src/types/report';
import type {RecipientResource} from 'src/types/survey';

import {thunkify as flow} from 'src/utils/thunks';
import {camel} from 'src/utils';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api';
import {resolveAnalyticsQuery} from 'src/utils/analytics-query';
import {
  checkAnalyticsNpsV3Enabled,
  ANALYTICS_API_V3,
} from 'src/utils/analytics-api-migration';


const {stringify} = JSON;

export const RECEIVE_AGENCY = 'report/receiveAgency';
export const RECEIVE_AGENCY_FOR_RECIPIENT = 'report/receiveAgencyForRecipient';
export const RECEIVE_WORKFLOW = 'report/receiveWorkflow';
export const RECEIVE_EVENT = 'report/receiveEvent';
export const SET_DEFAULT_QUERY = 'report/setDefaultQuery';

export type AgencyReportAction = {
  type: 'report/receiveAgency',
  payload: ReportStats,
};
export type AgencyReportForRecipientAction = {
  type: 'report/receiveAgencyForRecipient',
  payload: ReportStats,
  // $FlowFixMe[value-as-type] [v1.32.0]
  recipient: RecipientResource,
};
type WorkflowReportStats = {
  ...ReportStats,
  workflowId: string,
};
export type WorkflowReportAction = {
  type: 'report/receiveWorkflow',
  payload: WorkflowReportStats,
};
type EventReportStats = {
  ...ReportStats,
  eventId: string,
};
export type EventReportAction = {
  type: 'report/receiveEvent',
  payload: EventReportStats,
};
export type DefaultQueryReportAction = {
  type: 'report/setDefaultQuery',
  // $FlowFixMe[value-as-type] [v1.32.0]
  payload: AnalyticsQuery,
};
export type ReportAction =
  | AgencyReportAction
  | AgencyReportForRecipientAction
  | WorkflowReportAction
  | EventReportAction
  | DefaultQueryReportAction;

export const receiveAgency = (payload: ReportStats): AgencyReportAction => ({
  type: RECEIVE_AGENCY,
  payload,
});
export const receiveAgencyForRecipient = (
  payload: ReportStats,
  // $FlowFixMe[value-as-type] [v1.32.0]
  recipient: RecipientResource,
): AgencyReportForRecipientAction => ({
  type: RECEIVE_AGENCY_FOR_RECIPIENT,
  payload,
  recipient,
});
export const receiveWorkflow = (
  payload: WorkflowReportStats,
): WorkflowReportAction => ({
  type: RECEIVE_WORKFLOW,
  payload,
});
export const receiveEvent = (payload: EventReportStats): EventReportAction => ({
  type: RECEIVE_EVENT,
  payload,
});
export const setDefaultQuery = (
  // $FlowFixMe[value-as-type] [v1.32.0]
  payload: AnalyticsQuery,
): DefaultQueryReportAction => ({
  type: SET_DEFAULT_QUERY,
  payload,
});

function parse(json: Object) {
  return camel(json);
}

const querying =
  (allowed = ['client', 'event_types', 'recipients', 'nps_only']) =>
  (func) =>
  (...args) => {
    const lastIndex = args.length - 1;
    args[lastIndex] = resolveAnalyticsQuery(args[lastIndex], allowed);
    return func(...args);
  };

export const getAgencyReport: (query: AnalyticsQuery) => ThunkAction<mixed> =
  flow(
    querying(),
    key(() => 'report/agency'),
    cached((json) => receiveAgency(parse(json)), {
      hash: (query) => `report/agency:${stringify(query)}`,
    }),
    fetching(),
  )((query: AnalyticsQuery) =>
    reduxApi.post(
      '/stats',
      query,
      {},
      {
        apiPath: checkAnalyticsNpsV3Enabled()
          ? ANALYTICS_API_V3
          : '/api/v1/analytics',
      },
    ),
  );

const recipientNpsReportQuerying = querying();
type RecipientsQuery = {
  ...AnalyticsQuery,
  recipients: RecipientResource[],
};
export const getAgencyNpsReportForRecipient: (
  query: RecipientsQuery,
) => ThunkAction<mixed> = flow(
  recipientNpsReportQuerying,
  key((query: RecipientsQuery) => `report/agency${query.recipients[0]}`),
  cached(
    (json, query: RecipientsQuery) =>
      receiveAgencyForRecipient(parse(json), query.recipients[0]),
    {
      hash: recipientNpsReportQuerying(
        (query: RecipientsQuery) =>
          `report/agency:${query.recipients[0]}:${stringify(query)}`,
      ),
    },
  ),
  fetching(),
)((query) =>
  reduxApi.post(
    '/stats/nps',
    query,
    {},
    {
      apiPath: checkAnalyticsNpsV3Enabled()
        ? ANALYTICS_API_V3
        : '/api/v1/analytics',
    },
  ),
);

export const getWorkflowReport: (
  workflowId: string,
  query: AnalyticsQuery,
) => ThunkAction<mixed> = flow(
  querying(),
  key((workflowId, query) => `report/workflow:${workflowId}`),
  cached((json) => receiveWorkflow(parse(json)), {
    hash: (workflowId, query) =>
      `report/workflow:${workflowId}:${stringify(query)}`,
  }),
  fetching(),
)((workflowId: string, query: AnalyticsQuery) =>
  reduxApi.post(`analytics/workflows/${workflowId}/stats`, query),
);

export const getEventReport: (
  workflowId: string,
  eventId: string,
  query: AnalyticsQuery,
) => ThunkAction<mixed> = flow(
  querying(['client', 'recipients', 'specific_issues']),
  key((workflowId, eventId) => `report/workflow:${workflowId}:${eventId}`),
  cached((json) => receiveEvent(parse(json)), {
    hash: (workflowId, eventId, query) =>
      `report/workflow:${workflowId}:${eventId}:${stringify(query)}`,
  }),
  fetching(),
  // $FlowFixMe[value-as-type] [v1.32.0]
)((workflowId: string, eventId: string, query: AnalyticsQuery) =>
  reduxApi.post(
    `/workflows/${workflowId}/events/${eventId}/stats`,
    query,
    {},
    {
      apiPath: ANALYTICS_API_V3,
    },
  ),
);

export const getDefaultAnalyticsQuery: () => ThunkAction<mixed> = flow(
  key(() => 'analytics/default-query'),
  cached(({entity_data_filter}) => setDefaultQuery(entity_data_filter)),
  fetching(),
)(() => reduxApi.get('analytics/default-filter'));

export const saveDefaultAnalyticsQuery =
  (defaultQuery: AnalyticsQuery): ThunkSyncAction =>
  (dispatch: Dispatch) => {
    dispatch(setDefaultQuery(defaultQuery));
    dispatch(
      reduxApi.put('analytics/default-filter', {
        entity_data_filter: defaultQuery,
      }),
    );
  };
