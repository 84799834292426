// @flow

// $FlowFixMe[untyped-type-import]
import type {Workflow} from 'src/api-parsers/index';
// $FlowFixMe[untyped-type-import]
import type {Event} from 'src/api-parsers/events';
import type {Question} from 'src/types/survey';
import type {JobVariables} from 'src/types/job-variables';

import React from 'react';
import {connect} from 'react-redux';
import flow from 'lodash/flow';

import {fluxify} from 'src/flux/component.jsx';
import {EDIT_WORKFLOWS} from 'src/action-creators/roles';
import {hasPrivilege} from 'src/utils/accounts';

import ModuleRow, {
  BranchModuleRow,
} from 'src/components/workflow/event/content/module/row.jsx';
import {Expanders} from 'src/components/lib/expander/expander2.jsx';
import {isSMS as checkIsSMS} from 'src/utils/sense-event';

import css from './modules.css';


const mapper = ({
  store: {
    eventCreation: {
      state: {question, questionError, branchInfo},
    },
  },
}) => ({
  editingQuestion: question,
  questionError,
  locked: question || (branchInfo && branchInfo.editorBranch),
});

const mapStateToProps = (state) => ({
  canEdit: hasPrivilege(state, EDIT_WORKFLOWS),
});

/** provides the index of module which contains job block */
function getJobBlockModuleIndex(modules): number {
  return modules.findIndex(
    (module) =>
      (module.type === 'message_survey_module' && module.include_job_block) ||
      (module.type === 'sms_message_survey_module' &&
        module.includes_job_match_variables),
  );
}

function Modules(props: {
  store: Object,
  // $FlowFixMe[value-as-type] [v1.32.0]
  workflow: Workflow,
  // $FlowFixMe[value-as-type] [v1.32.0]
  parentEvent?: Event,
  // $FlowFixMe[value-as-type] [v1.32.0]
  modules: Question[],
  // $FlowFixMe[value-as-type] [v1.32.0]
  editingQuestion: Question,
  questionError: ?Object,
  locked: boolean,
  canEdit: boolean, // should this be locked or is it different?
  dynamicLabels: string[],
  internalDynamicLabels: string[],
  jobDynamicLabels: JobVariables,
  className: string,
  parentType: 'event' | 'branch',
  conditionalAlerts: boolean,
  showErrors: string,
  isBranchedEvent: boolean,
}) {
  const {
    store,
    dynamicLabels,
    workflow,
    modules,
    editingQuestion,
    questionError,
    locked,
    parentEvent,
    className,
    parentType,
    conditionalAlerts,
    internalDynamicLabels,
    jobDynamicLabels,
    showErrors,
    isBranchedEvent,
  } = props;

  const canEdit = props.canEdit && workflow.inEditableGroup;
  // TODO(marcos): can add an additional condition here and in sms-notification.jsx
  // to enable/disable this feature
  const isSMS = parentEvent && checkIsSMS(parentEvent.type);
  const isSurveyEvent = ['sms_survey', 'survey', 'nps', 'sms_nps'].includes(
    parentEvent?.type,
  );

  const ModuleComponent = parentType === 'event' ? ModuleRow : BranchModuleRow;

  const interactiveModules = modules.filter(
    ({type}) => type !== 'message_survey_module',
  );

  const isFirstQuestionLongMultipleChoice =
    interactiveModules[0] &&
    interactiveModules[0].type === 'multiple_choice_survey_question' &&
    interactiveModules[0].choices?.length > 5;

  const firstQuestionIsFreeform =
    interactiveModules[0] &&
    (interactiveModules[0].type === 'text_survey_question' ||
      isFirstQuestionLongMultipleChoice);
  //if any notification module contains job block
  const jobBlockModuleIndex = getJobBlockModuleIndex(modules);

  return (
    <Expanders component="div" className={className} duration={200}>
      {modules.map((currentModule, index) => {
        const isEditing =
          editingQuestion && currentModule.id === editingQuestion.id;

        // Decide where to place the "Questions after this will be shown in a
        // separate window." notification, if at all
        let isFirstWebQuestion;
        // if the first module the user can interact with is a freeform question,
        // *unless* it's the first module overall (very top of the list)
        if (
          firstQuestionIsFreeform &&
          interactiveModules[0] &&
          interactiveModules[0].id === currentModule.id &&
          index !== 0
        ) {
          isFirstWebQuestion = true;
        }
        // most other cases, i.e. the second module is interactive but is not
        // a freeform question
        if (
          !firstQuestionIsFreeform &&
          interactiveModules[1] &&
          interactiveModules[1].id === currentModule.id
        ) {
          isFirstWebQuestion = true;
        }

        return (
          <div className={css.moduleRow} key={currentModule.id}>
            <ModuleComponent
              index={index}
              jobBlockModuleIndex={jobBlockModuleIndex}
              // passing this here as fluxStore because row is connect-ed
              // and store-as-prop confuses connect
              fluxStore={store}
              workflow={workflow}
              parentEvent={parentEvent}
              parentType={parentType}
              webContentStart={parentEvent && !isSMS && isFirstWebQuestion}
              linkedSurvey={isSMS && isFirstWebQuestion}
              question={isEditing ? editingQuestion : currentModule}
              error={questionError}
              dynamicLabels={dynamicLabels}
              jobDynamicLabels={jobDynamicLabels}
              internalDynamicLabels={internalDynamicLabels}
              disabled={locked}
              editing={isEditing}
              canEdit={canEdit}
              isSurveyEvent={isSurveyEvent}
              conditionalAlerts={conditionalAlerts}
              showErrors={showErrors}
              isBranchedEvent={isBranchedEvent}
            />
          </div>
        );
      })}
    </Expanders>
  );
}

export default (flow(
  // This connect is marked as impure to allow flux setStates in
  // children to propagate back through even though they would
  // normally be blocked by connect. Learn more about this here:
  // https://github.com/reactjs/react-redux/blob/master/docs/troubleshooting.md#my-views-arent-updating-when-something-changes-outside-of-redux
  // If you have any questions about this, please ask Marcos
  fluxify(mapper),
  connect(mapStateToProps, undefined, undefined, {pure: false}),
)(Modules): any);
