// @flow strict

import {UnstyledButton} from './button.jsx';
import {
  makeClassNameComponentCustom,
  type ClassNameComponent,
} from 'src/utils/makeClassNameComponent';

import css from './legacy.css';


export const LegacyButton: ClassNameComponent<
  typeof UnstyledButton,
  HTMLDivElement,
> = makeClassNameComponentCustom(css.legacy, UnstyledButton);
