// @flow

import type {Dispatch, ThunkAction} from 'src/reducers';
import type {RouteAction} from 'src/action-creators/route';
import type {Task} from 'src/types/data-export-report';
import type {RawQuery} from 'src/types/router';
import type {Node} from 'react';

import {thunkify as flow} from 'src/utils/thunks';
import {camel} from 'src/utils';
import {key, cached, fetching} from 'src/utils/redux';

import * as reduxApi from 'src/utils/redux-api-v2';
import {
  pushModal,
  showGenericError,
  showGenericAlert,
} from 'src/action-creators/modal';
import {
  DataExportInit,
  DataExportSuccess,
} from 'src/components/modals/data-export.jsx';


export type ReceiveCountAction = {
  type: 'reports/receiveCount',
  payload: number,
};
export type ReceiveTasksAction = {
  type: 'reports/receiveTasks',
  payload: {
    tasks: Task[],
    count: number,
  },
};
export type ReceiveTaskAction = {
  type: 'reports/receiveTask',
  payload: Task,
};
export type ReportAction = ReceiveTasksAction | ReceiveTaskAction | RouteAction;

export const RECEIVE_TASK = 'reports/receiveTask';
export const RECEIVE_TASKS = 'reports/receiveTasks';
export const RECEIVE_COUNT = 'reports/receiveCount';
export const REPORT_TABLE_ROW_FETCH_LIMIT = 10;

const receiveTasks = (payload) => ({
  type: RECEIVE_TASKS,
  payload,
});

const receiveTask = (payload): ReportAction => ({
  type: RECEIVE_TASK,
  payload,
});

// TODO (kyle): for any tasks in progress, we may want to start polling.
export const getDataExportReports: (offset?: number) => ThunkAction<mixed> =
  flow(
    key(() => 'getDataExportReports'),
    cached((response) => receiveTasks(camel(response)), {ttl: 2000}),
    fetching(),
  )(
    (offset: number = 0) =>
      (dispatch: Dispatch) =>
        dispatch(
          reduxApi.get(
            `data-exports?offset=${offset}&limit=${REPORT_TABLE_ROW_FETCH_LIMIT}&include_count=1`,
          ),
        ),
  );

// NOTE (kyle): the polling function fetches and checks the task status so we
// delegate to it.
export const getSingleExportReport =
  (taskId: string): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    dispatch(startPolling(taskId));
  };

export const startDataExport =
  (
    pathname: string,
    data?: $ReadOnly<{[key: string]: mixed, ...}>,
    query?: RawQuery,
    CustomContent?: string | Node,
    modalVersion?: 'genesis',
  ): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    try {
      const response = await dispatch(reduxApi.post(pathname, data, query));
      dispatch(
        pushModal({
          type: DataExportInit,
          CustomContent,
          topmost: true,
          modalVersion,
        }),
      );
      await dispatch(startPolling(response.task_id));
      dispatch(
        pushModal({type: DataExportSuccess, topmost: true, modalVersion}),
      );
    } catch (error) {
      if (
        error instanceof reduxApi.ApiError &&
        error.responseBody.errors?.includes('ResourceTaskInProgress')
      ) {
        dispatch(
          showGenericAlert({
            title: 'Please wait for the export in progress',
            text: 'An export is already in progress, and you will receive a notification when it is ready.  You may request another export as soon as the current export is ready.',
            confirmText: 'Okay',
            topmost: true,
            modalVersion,
          }),
        );
      } else {
        dispatch(
          showGenericError({
            title: 'Request failed',
            text: 'We could not process your request at this time. Refresh the page and request the report again.',
            topmost: true,
            modalVersion,
          }),
        );
      }
    }
  };

// NOTE (kyle): for simplicity's sake i'm not adding this to redux,
// but we could if we wanted to give components access to the state.
const polls = new Map();
export const startPolling =
  (taskId: string): ThunkAction<Task> =>
  (dispatch: Dispatch): Promise<Task> =>
    new Promise((resolve, reject) => {
      let timeout = 2000;

      const poll = async () => {
        const currentTimeoutId = polls.get(taskId);

        if (currentTimeoutId) {
          clearTimeout(currentTimeoutId);
        }

        let task;
        try {
          task = camel(
            await dispatch(reduxApi.get(`data-exports/${taskId}/status`)),
          );
        } catch (error) {
          reject(error);
          return;
        }

        dispatch(receiveTask(camel(task)));

        if (['INIT', 'PROCESSING'].includes(task.status)) {
          timeout = Math.min(timeout * 2, 30000);
          polls.set(taskId, setTimeout(poll, timeout));
        } else {
          resolve(task);
          polls.delete(taskId);
        }
      };

      poll();
    });
