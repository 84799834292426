// @flow strict

import * as React from 'react';
import {classify} from 'src/utils/classify';

import {Button} from '@spaced-out/ui-design-system/lib/components/Button';

import css from './feedback.css';


export type FeedbackProps = {
  onPositive: () => mixed,
  onNegative: () => mixed,
  disabled?: boolean,
};

export const Feedback = ({
  onPositive,
  onNegative,
  disabled = false,
}: FeedbackProps): React.Node => (
  //feedback component with positive and negative buttons
  <div className={css.container}>
    <Button
      type="ghost"
      iconRightName="thumbs-down"
      onClickCapture={onNegative}
      disabled={disabled}
    />
    <Button
      type="ghost"
      iconRightName="thumbs-up"
      onClickCapture={onPositive}
      disabled={disabled}
    />
  </div>
);
