// @flow strict

import type {CheckedValue} from 'src/designSystem2021Components/checkbox.jsx';
import type {SvgIcon} from 'src/types/sense';
import * as React from 'react';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import xor from 'lodash/xor';

// $FlowIssue[nonstrict-import] date-fns
import parseISO from 'date-fns/parseISO';
// $FlowIssue[nonstrict-import] date-fns
import formatDistance from 'date-fns/formatDistance';

import classify from 'src/utils/classify';
import {emptyArray, emptyObject} from 'src/utils/empty';
import makeClassNameComponent, {
  type ClassNameComponent,
} from 'src/utils/makeClassNameComponent';

import {Paragraph, Smallest} from 'src/designSystem2021Components/text-v2.jsx';
import Loading from 'src/components/lib/loading/loading.jsx';
// $FlowIssue[nonstrict-import] pending truncated text updates
import {AutoTruncatedText} from 'src/components/lib/truncated-text/truncated-text.jsx';
import {
  Checkbox,
  LabeledCheckbox,
} from 'src/designSystem2021Components/checkbox.jsx';

import SortIcon from 'src/images/designSystems2021/arrow-down.svg';

import css from './table.css';
import {makeClassNameComponentCustom} from '@spaced-out/ui-lib/lib/utils/makeClassNameComponent';


type SortDirection = 'asc' | 'desc' | 'original';
type GenericObject = {
  // the + here (not well doc'd by flow) makes all object properties covariant
  // meaning that the value of any string-keyed property is allowed to have a more
  // specific type than `mixed` (i.e. string, number fn, etc)
  // (ie. the likely case for all real instances of GenericObject)
  // learn more here https://flow.org/blog/2016/10/04/Property-Variance/
  +[key: string]: mixed,
};

export function useSortableEntries<T: GenericObject>(
  entries: Array<T>,
  idName: $Keys<T>,
  {
    defaultSortKey = 'id',
    defaultSortDirection = 'original',
    onSort,
  }: {
    defaultSortKey?: $Keys<T>,
    defaultSortDirection?: SortDirection,
    onSort?: (sortKey: string, sortDirection: SortDirection) => mixed,
  },
): {
  handleSortClick: (sortKey: $Keys<T>) => mixed,
  sortDirection: SortDirection,
  sortKey?: string,
  sortedEntries: T[],
  sortedKeys: $Keys<T>[],
} {
  const [sortKey, setSortKey] = React.useState(defaultSortKey);
  const [sortDirection, setSortDirection] =
    React.useState<SortDirection>(defaultSortDirection);

  const getNextDirection = (direction: SortDirection): SortDirection => {
    switch (direction) {
      case 'original':
        return 'desc';
      case 'asc':
        return 'original';
      case 'desc':
        return 'asc';
      default:
        return 'original';
    }
  };
  const advanceSortDirection = (dir: SortDirection) => {
    const nextDirection = getNextDirection(dir);
    setSortDirection((dir) => nextDirection);
    return nextDirection;
  };

  const handleSortClick = React.useCallback(
    (nextSortKey: string) => {
      let nextSortDirection = sortDirection;
      if (nextSortKey === sortKey) {
        nextSortDirection = advanceSortDirection(sortDirection);
      } else {
        setSortKey(nextSortKey);
        setSortDirection('desc');
      }
      onSort?.(nextSortKey, nextSortDirection);
    },
    [sortKey, sortDirection, entries],
  );

  const sortedEntries = React.useMemo(() => {
    if (sortDirection === 'original') {
      return entries;
    }
    const sortedDesc = sortBy(entries, sortKey);
    if (sortDirection === 'asc') {
      return sortedDesc;
    } else {
      return sortedDesc.reverse();
    }
  }, [sortDirection, sortKey, entries]);

  const sortedKeys = React.useMemo(
    () => sortedEntries.map((ent) => get(ent, idName)),
    [sortedEntries],
  );

  return {
    sortedEntries,
    sortedKeys,
    sortDirection,
    sortKey,
    handleSortClick,
  };
}

type EmptyRowProps = {
  emptyText?: React.Node,
  isLoading?: boolean,
  headersLength: number,
};

export type GenericHeaderItem<T: GenericObject, U: GenericObject> = {
  label: React.Node,
  key: $Keys<T>,
  className?: string,
  filterIcon?: React.Node,
  filtered?: boolean,
  subtext?: string,
  sortable?: boolean,
  headerIconClassName?: string,
  sticky?: boolean,
  render?: React.ComponentType<{
    data: T,
    extras?: U,
    className?: string,
    selected?: boolean,
  }>,
};
type GenericHeaderItems<T, U> = GenericHeaderItem<T, U>[];

// When using a custom Row prop, you need to create a component that looks like
//     MyRow = (props: TableRowProps<Entries, Extras>): React.Node => {...}
// otherwise flow will complain.
// Note that b/c extras is often optional, you will need to explicitly include
// `invariant(extras, 'extras exists');` in order to pull values out of
// extras (flow will remind you that it is of type `U | void`)
export type TableRowProps<T, U> = {
  data: T,
  extras?: U,
  sortedKeys?: string[],
  headers?: GenericHeaderItems<T, U>,
  selected?: boolean,
  disabled?: boolean,
};

export type TableRow<T, U> = React.ComponentType<TableRowProps<T, U>>;

type TableProps<T, U> = {
  className?: string,
  Row?: TableRow<T, U>,
  headers: GenericHeaderItems<T, U>,
  entries: Array<T>,
  extras?: U,
  sortable?: boolean,
  showHeader?: boolean,
  tableHeaderClassName?: string,
  headerIconClassName?: string,
  defaultSortKey?: string,
  defaultSortDirection?: 'asc' | 'desc' | 'original',

  selectedKeys?: string[],
  onSelect?: (keys: string[]) => mixed,

  idName?: $Keys<T>,
  onSort?: (key: $Keys<T>, direction: SortDirection) => void,
  isLoading?: boolean,
  emptyText?: React.Node,
  disabled?: boolean,
};

export const BasicTable: ClassNameComponent<'table'> = makeClassNameComponent(
  css.defaultTable,
  'table',
);
export const BasicTableHead: ClassNameComponent<'thead'> =
  makeClassNameComponent(css.defaultTableBody, 'thead');
export const BasicTableBody: ClassNameComponent<'tbody'> =
  makeClassNameComponent(css.defaultTableBody, 'tbody');

export const BasicRow: ClassNameComponent<'tr'> = makeClassNameComponent(
  css.defaultRow,
  'tr',
);

export const BasicHeadCell: ClassNameComponent<'th'> = makeClassNameComponent(
  css.defaultHeaderCell,
  'th',
);
export const BasicSingleCell: ClassNameComponent<'td'> = makeClassNameComponent(
  css.defaultSingleCell,
  'td',
);
export const BasicDoubleCell: ClassNameComponent<'td'> = makeClassNameComponent(
  css.defaultDoubleCell,
  'td',
);

export const PaddedContentCell: ClassNameComponent<'td'> =
  makeClassNameComponent(css.singleContentCell, 'td');
export const PaddedDoubleContentCell: ClassNameComponent<'td'> =
  makeClassNameComponent(css.doubleContentCell, 'td');

export const SingleCell = ({
  title,
  icon: Icon,
  className,
}: {
  title: string,
  icon?: SvgIcon,
  className?: string,
}): React.Node => (
  <PaddedContentCell className={className}>
    {Icon && <Icon className={css.paddedIcon} />}
    <div className={css.paddedTitleBlock}>{title}</div>
  </PaddedContentCell>
);
export const DoubleCell = ({
  title,
  subtitle,
  icon: Icon,
  className,
}: {
  title: React.Node,
  subtitle?: React.Node,
  icon?: SvgIcon,
  className?: string,
}): React.Node => (
  <PaddedDoubleContentCell className={className}>
    {Icon && <Icon className={css.paddedIcon} />}
    <div className={css.paddedTitleBlock}>
      <Paragraph className={css.doubleTitle}>{title}</Paragraph>
      {/* this u00a0 is a non breaking space, in cases where a subtitle is missing
    we need to still maintain alignment so we fake it with a nonbreaking space*/}
      <Smallest className={css.doubleSubtitle}>{subtitle ?? ''}</Smallest>
    </div>
  </PaddedDoubleContentCell>
);

export const DateCell = ({
  date,
  className,
}: {
  date: string | Date,
  className?: string,
}): React.Node => {
  const parsedDate: Date = typeof date === 'object' ? date : parseISO(date);
  return (
    <DoubleCell
      title={`${parsedDate.getMonth() + 1} / ${
        parsedDate.getDate() + 1
      } / ${parsedDate.getFullYear()}`}
      subtitle={`${formatDistance(parsedDate, new Date())} ago`}
    />
  );
};

export const Monogram = ({initials}: {initials: string}): React.Node => (
  <div className={css.monogram}>{initials}</div>
);
export const MonogramCell = ({
  initials,
  className,
}: {
  initials: string,
  className?: string,
}): React.Node => (
  <PaddedContentCell className={className}>
    <Monogram initials={initials} />
  </PaddedContentCell>
);

// This is a fallback row we use to render a table when
// initially stubbing out a design, the idea is you just avoid
// passing in a Row component and instead let this render out
// all the fields in the header in the short term
//
// Using the defaault row has the benefit that mismatches between
// header and entries _will_ error out even though there are the
// suppressions below
export function DefaultRow<T: GenericObject, U: GenericObject>({
  data,
  extras,
  headers,
  selected,
  onSelect,
  className,
  disabled,
}: {
  data: T,
  extras?: U,
  headers: GenericHeaderItems<T, U>,
  selected?: boolean,
  onSelect?: (value: CheckedValue) => mixed,
  className?: string,
  disabled?: boolean,
}): React.Node {
  return (
    <BasicRow
      className={classify(
        className,
        selected ? css.defaultSelectedBodyRow : css.defaultBodyRow,
      )}
    >
      {selected != null && (
        <PaddedContentCell>
          <LabeledCheckbox
            className={css.defaultCheckbox}
            checked={selected ? 'true' : 'false'}
            onChange={onSelect}
            disabled={disabled}
          />
        </PaddedContentCell>
      )}
      {headers.map((item, index) => {
        const {key, render: Renderer, className: cellClassName, sticky} = item;
        const value = data[key];
        return Renderer ? (
          <Renderer
            key={index}
            data={data}
            extras={extras}
            selected={selected}
            className={classify({[css.stickyCell]: sticky})}
          />
        ) : (
          <SingleCell
            key={index}
            title={String(value)}
            className={classify(cellClassName, {[css.stickyCell]: sticky})}
          />
        );
      })}
    </BasicRow>
  );
}

type TableHeaderProps<T, U> = {
  className?: string,
  tableHeaderClassName?: string,

  sortable?: boolean,

  entries: GenericHeaderItems<T, U>,

  handleSortClick?: (sortKey: $Keys<T>) => mixed,
  sortKey?: $Keys<T>,
  sortDirection?: SortDirection,

  checked?: CheckedValue,
  handleCheckboxClick?: (value: CheckedValue) => mixed,
  disabled?: boolean,
};

function DefaultTableHeader<T: GenericObject, U: GenericObject>(
  props: TableHeaderProps<T, U>,
): React.Node {
  const {
    tableHeaderClassName,
    className,
    sortable = false,
    entries,
    handleSortClick,
    sortKey,
    sortDirection = 'original',
    handleCheckboxClick,
    checked,
    disabled,
  } = props;
  return (
    <BasicTableHead
      className={classify(css.tableHeaderSortable, tableHeaderClassName)}
    >
      <BasicRow className={css.defaultHeaderRow}>
        {handleCheckboxClick && (
          <BasicHeadCell scope="col">
            <LabeledCheckbox
              className={css.defaultCheckbox}
              checked={checked}
              onChange={handleCheckboxClick}
              disabled={disabled}
            />
          </BasicHeadCell>
        )}
        {entries.map(
          (
            {
              key,
              label,
              subtext,
              filterIcon,
              filtered,
              className,
              sticky,
              sortable: cellSortable = true,
            },
            index,
          ) => {
            let headerClassName;
            const filterable = Boolean(filterIcon);
            if ((sortable && cellSortable) || filterable) {
              headerClassName = classify(
                css.defaultHeaderCellSortable,
                {
                  [css.sorted]: sortKey === key,
                  [css.filtered]: filtered,
                  [css.stickyHeaderCell]: sticky,
                },
                css[sortDirection],
                className,
              );
            } else {
              headerClassName = classify(className, {[css.sticky]: sticky});
            }
            return (
              <BasicHeadCell
                className={classify(headerClassName)}
                key={index}
                scope="col"
                onClick={
                  sortable && cellSortable && handleSortClick
                    ? () => {
                        handleSortClick(key);
                      }
                    : null
                }
              >
                <div className={css.labelContents}>
                  <div className={css.labelContainer}>
                    {label}
                    <span className={css.headerSubtext}>
                      {subtext && subtext}
                    </span>
                  </div>
                  {(sortable || filterIcon != null) && (
                    <div
                      className={classify(
                        css.headerIconContainer,
                        // headerIconClassName,
                      )}
                    >
                      {sortable && <SortIcon className={css.sortArrow} />}
                      {filterIcon != null && (
                        <div className={css.filterIcon}>{filterIcon}</div>
                      )}
                    </div>
                  )}
                </div>
              </BasicHeadCell>
            );
          },
        )}
      </BasicRow>
    </BasicTableHead>
  );
}

{
  /**
   * A Static Default Table.
   *
   * Our
   */
}
export function StaticTable<Data: GenericObject, Extras: GenericObject>(props: {
  ...TableProps<Data, Extras>,
  handleSortClick?: (sortkey: string) => mixed,
  sortKey?: string,
  sortDirection?: SortDirection,
  rowKeys?: string[],
}): React.Node {
  const {
    className,

    Row,

    entries,
    extras,
    rowKeys,

    headers,
    showHeader = true,
    tableHeaderClassName,

    sortable,
    defaultSortKey,
    defaultSortDirection = 'original',
    onSort,
    handleSortClick,
    sortKey,
    sortDirection,
    selectedKeys,
    onSelect,

    isLoading,
    idName = 'id',
    emptyText,
    disabled,
  } = props;

  // this is a fallback and honestly probably doesn't need the
  // memo'ing
  const mappedKeys = React.useMemo(
    () => rowKeys ?? entries.map((e) => get(e, idName)),
    [entries, idName, rowKeys],
  );

  return (
    <BasicTable className={className}>
      {showHeader && (
        <DefaultTableHeader
          tableHeaderClassName={tableHeaderClassName}
          className={className}
          sortable={sortable}
          entries={headers}
          handleSortClick={handleSortClick}
          sortKey={sortKey}
          sortDirection={sortDirection}
          disabled={disabled}
          handleCheckboxClick={
            selectedKeys
              ? (val) => {
                  if (val === 'true') {
                    onSelect?.(entries.map((e) => get(e, idName)));
                  } else {
                    onSelect?.([]);
                  }
                }
              : undefined
          }
          checked={
            selectedKeys == null || selectedKeys.length === 0
              ? 'false'
              : selectedKeys.length < entries.length
              ? 'mixed'
              : 'true'
          }
        />
      )}

      <BasicTableBody>
        {isLoading || !entries.length ? (
          <EmptyRow
            isLoading={isLoading}
            emptyText={emptyText}
            headersLength={headers.length || 0}
          />
        ) : (
          mappedKeys.map((key) => {
            const data = entries.find((e) => get(e, idName) === key);
            if (data == null) {
              return null;
            }
            (data: Data);
            const selected =
              selectedKeys?.includes(get(data, idName)) ?? undefined;

            return Row ? (
              <Row
                key={key}
                data={data}
                headers={headers}
                // extras and rowKeys are both 'optional'
                extras={extras}
                sortedKeys={rowKeys ?? mappedKeys}
                selected={selected}
                disabled={disabled}
              />
            ) : (
              <DefaultRow
                key={key}
                data={data}
                extras={extras}
                headers={headers}
                selected={selected}
                onSelect={
                  selectedKeys != null
                    ? (_v) => onSelect?.(xor(selectedKeys ?? [], [key]))
                    : undefined
                }
                disabled={disabled}
              />
            );
          })
        )}
      </BasicTableBody>
    </BasicTable>
  );
}

/**
 * Table
 * @param {React.ComponentType} Row - React.ComponentType<{data: Data, extras?: Extras, sortedKeys?: string[]}>
 * @param {string} className - string
 *
 */
export function Table<Data: GenericObject, Extras: GenericObject>(
  props: TableProps<Data, Extras>,
): React.Node {
  const {
    className,

    Row,

    entries,
    extras,

    headers,
    showHeader = true,
    tableHeaderClassName,

    sortable = true,
    defaultSortKey,
    defaultSortDirection = 'original',
    onSort,

    isLoading,
    idName = 'id',
    emptyText,
  } = props;

  const {sortedEntries, sortedKeys, ...sortableProps} = useSortableEntries(
    entries,
    idName,
    {
      defaultSortKey,
      defaultSortDirection,
      onSort,
    },
  );

  return (
    <StaticTable
      {...props}
      {...sortableProps}
      sortable={sortable}
      entries={entries}
      rowKeys={sortedKeys}
    />
  );
}

export const EmptyRow = ({
  isLoading,
  emptyText,
  headersLength,
}: EmptyRowProps): React.Element<'th'> => (
  <th colspan={headersLength + 1}>
    <div className={css.emptyRow}>
      {isLoading ? (
        <Loading className={css.loader} />
      ) : (
        emptyText || <Paragraph>Nothing to display here.</Paragraph>
      )}
    </div>
  </th>
);
