// @flow

import type {PaginatedResponses, EventResponse} from 'src/types/report';
// $FlowFixMe[untyped-type-import]
import type {Event} from 'src/api-parsers/events';
import type {SenseEvent} from 'src/types/events';
import type {Workflow} from 'src/types/workflow';
import type {FlaggedWord} from 'src/action-creators/flagging.js';
import type {RatingScaleQuestion, NPSSurveyQuestion} from 'src/types/survey.js';
import type {AtsEntity} from 'src/types/ats-entities';
import useAnalyticsQuery from 'src/components/analytics/useAnalyticsQuery';
import {useReleaseFlag} from 'src/hooks/product-flags';

import * as React from 'react';
import {Link} from 'src/rerouter';
import {useSelector, useDispatch} from 'react-redux';
import Highlighter from 'src/components/lib/highlighter';

import {classify, getRatingMood, getNpsRatingMood} from 'src/utils';
import {formatDateTime} from 'src/utils/date-time';
import {resolveAnalyticsPath} from 'src/utils/analytics-query';
import {
  allowedFilters,
  resolveResponsesQuery,
} from 'src/action-creators/event-responses';
import {selectEntityMappingsByName} from 'src/selectors/ats-entities';
import makeClassNameComponent, {
  makeClassNameComponentCustom,
  type ClassNameComponent,
} from 'src/utils/makeClassNameComponent';
import {
  checkResponseLatestApiEnabled,
  ANALYTICS_API_V3,
} from 'src/utils/analytics-api-migration';
import {startDataExport} from 'src/action-creators/data-export-report';

import {Button} from 'src/designSystem2021Components/buttons.jsx';
import {UnstyledButton} from 'src/components/lib/new-button';
import EventIcon from 'src/components/workflow/event/icon.jsx';
import {FormattedPlural} from 'src/components/lib/intl';
import CsvDownloadButton from 'src/components/analytics/figures/csv-download-button.jsx';
import MoreButton from 'src/components/analytics/figures/more-button.jsx';
import {AutoTruncatedText} from 'src/components/lib/truncated-text/truncated-text.jsx';

import CaretDown from 'src/images/designSystems2021/caret-down.svg';

import css from './responses.css';

import {
  Paragraph,
  Clickable,
} from '../../designSystem2021Components/text-v2.jsx';
import type {
  Conversation,
  ConversationUploadedfiles,
} from '../../types/chatbot-analytics';


export type ResponsesParams = {
  responseId: string,
  workflowId: string,
  eventId: string,
};

type ResponsesProps = {
  responseData: PaginatedResponses,
  params: ResponsesParams,
  loadingMore: boolean,
  onMoreClick: () => void,
  flagged?: boolean,
  flaggedWords?: FlaggedWord[],
  isNps?: boolean,
  multiEntityEnabled: boolean,
};

const PaginatedResponsesView = ({
  responseData,
  onMoreClick,
  loadingMore,
  params,
  flagged = false,
  flaggedWords = [],
  isNps = false,
  multiEntityEnabled,
}: ResponsesProps): React.Node => {
  const {responses} = responseData;
  const {workflowId, eventId} = params;
  const dispatch = useDispatch();
  const [query] = useAnalyticsQuery();
  const resolveResponsesQueryFinal = isNps
    ? resolveResponsesQuery(query)
    : resolveResponsesQuery(query, allowedFilters, true);
  const isNewAnalyticsResponseExport = useReleaseFlag(
    'analyticsResponseExportV3',
  );
  const handleCsvExport = () => {
    dispatch(
      startDataExport('data-exports/responses-export', {
        ...resolveResponsesQueryFinal,
        flagged,
        nps_only: isNps,
        workflow_id: workflowId,
        event_id: eventId,
      }),
    );
  };

  return (
    <Container>
      <Header count={responses.length}>
        {isNewAnalyticsResponseExport ? (
          <>
            <Button
              onClick={() => handleCsvExport()}
              type="text"
              className={css.downloadLink}
            >
              Export Spreadsheet
            </Button>
          </>
        ) : (
          <CsvDownloadButton
            className={css.downloadLink}
            url={resolveAnalyticsPath(
              'responses',
              {workflowId, eventId},
              checkResponseLatestApiEnabled(),
            )}
            queryResolver={(query) => ({
              ...resolveResponsesQueryFinal,
              flagged,
              nps_only: isNps,
            })}
            apiPath={ANALYTICS_API_V3}
          >
            Export Spreadsheet
          </CsvDownloadButton>
        )}
      </Header>

      {responses.length ? (
        <Responses
          responseData={responseData}
          params={params}
          flagged={flagged}
          flaggedWords={flaggedWords}
          isNps={isNps}
          multiEntityEnabled={multiEntityEnabled}
        />
      ) : (
        <NoData />
      )}

      {responseData.hasMore && (
        <MoreButton onClick={onMoreClick} loadingMore={loadingMore} />
      )}
    </Container>
  );
};

export default PaginatedResponsesView;

export const Container: ClassNameComponent<> = makeClassNameComponent(
  css.responses,
);

export function Header({
  count,
  children,
}: {
  count: number,
  children?: React.Node,
}): React.Node {
  return (
    <div className={css.count}>
      <strong>{count}</strong>
      <FormattedPlural value={count} one="Person" other="People" />
      {children}
    </div>
  );
}

export const DownloadButton: ClassNameComponent<typeof UnstyledButton> =
  makeClassNameComponentCustom(css.downloadLink, UnstyledButton);

export const NoData = (): React.Node => (
  <div className={css.zero}>No responses yet</div>
);

export const ResponsesColumn: ClassNameComponent<> = makeClassNameComponent(
  css.column,
);

export const Responses = ({
  responseData: {responses = [], events = {}, workflows = {}, modules = {}},
  params: {workflowId, eventId} = {},
  flagged,
  flaggedWords,
  isNps = false,
  compact = false,
  multiEntityEnabled,
}: {
  responseData: PaginatedResponses,
  params?: ResponsesParams,
  flagged: boolean,
  flaggedWords: FlaggedWord[],
  isNps?: boolean,
  compact?: boolean,
  multiEntityEnabled: boolean,
}): React.Element<'div'> => {
  const atsEntityMappings = useSelector(selectEntityMappingsByName);
  const showExtra = !eventId;

  return (
    <div className={css.column}>
      {responses
        .filter(
          ({memberResponse}) => memberResponse && memberResponse.length > 0,
        )
        .map((response) => {
          const workflow = workflows[response.workflowId];
          const event = events[response.eventId];
          const id = response.memberResponse[0].eventResponseId;
          const entityMapping = atsEntityMappings[response.recipientEntityType];
          return (
            (!showExtra || event) && (
              <ResponseComponent
                key={id}
                response={response}
                modules={modules}
                showExtra={showExtra}
                workflow={workflow}
                workflowId={(workflow && workflow.id) || workflowId}
                event={event}
                entityMapping={entityMapping}
                isNps={isNps}
                flagged={flagged}
                flaggedWords={flaggedWords}
                compact={compact}
                multiEntityEnabled={multiEntityEnabled}
              />
            )
          );
        })}
    </div>
  );
};

type ResponseComponentProps = {
  response: EventResponse,
  // $FlowFixMe[value-as-type] [v1.32.0]
  event: Event,
  // $FlowFixMe[value-as-type] [v1.32.0]
  workflow: Workflow,
  workflowId: string,
  entityMapping?: AtsEntity,
  flagged: boolean,
  flaggedWords: FlaggedWord[],
  modules: {
    // $FlowFixMe[value-as-type] [v1.32.0]
    [moduleId: string]: RatingScaleQuestion | NPSSurveyQuestion,
  },
  divRef?: () => ?HTMLElement,
  showExtra: boolean,
  compact: boolean,
  isNps?: boolean,
  selected?: boolean,
  multiEntityEnabled: boolean,
};

export function BaseResponseView({
  header,
  entityMapping,
  entityId,
  entityName,
  isArchivedEntity,
  responseTime,
  isCollapsable,
  event,
  workflow,
  isNps,
  children,
  conversation,
}: {
  header?: React.Node,
  entityMapping: ?AtsEntity,
  entityId: string,
  entityName: string,
  isArchivedEntity?: boolean,
  responseTime: string | Date,
  isCollapsable?: mixed,
  children: React.Node,
  event?: ?SenseEvent,
  workflow?: ?Workflow,
  isNps?: boolean,
  conversation?: Conversation,
}): React.Node {
  // TODO (kyle): it's possible we don't want to unnecessarily give every response
  // state.
  const [collapsed, setCollapsed] = React.useState(isCollapsable);
  return (
    <div className={css.response}>
      <div
        className={classify(
          css.headerContainer,
          isCollapsable && css.isCollapsable,
        )}
        onClick={
          isCollapsable
            ? (event) =>
                !(event.target instanceof HTMLAnchorElement) &&
                setCollapsed(!collapsed)
            : null
        }
      >
        {event && workflow && (
          <Link
            className={css.headerEvent}
            to={(location) => ({
              ...location,
              pathname: `/analytics/journey/${workflow.id}/touchpoint/${event.id}`,
            })}
          >
            <EventIcon
              className={css.headerEventIcon}
              nps={isNps}
              type={event.eventType}
            />
            <div className={css.headerEventTitles}>
              <div className={css.headerEventTitle}>{event.title}</div>
              {workflow && (
                <div className={css.headerWorkflowTitle}>{workflow.name}</div>
              )}
            </div>
          </Link>
        )}
        <EntityHeader
          label={entityMapping?.display_name || 'Recipient'}
          entityId={entityId}
          entityUrl={entityMapping?.url}
          entityName={entityName}
          time={responseTime}
          isCollapsable={isCollapsable}
          isCollapsed={collapsed}
          isArchived={isArchivedEntity}
          conversation={conversation || null}
        />
      </div>
      {!collapsed && <div className={css.main}>{children}</div>}
    </div>
  );
}

const ResponseComponent = ({
  response,
  modules,
  showExtra,
  workflowId,
  event,
  workflow,
  entityMapping,
  flagged,
  flaggedWords,
  isNps = false,
}: ResponseComponentProps) => {
  const latestResponseTime = response.memberResponse.reduce(
    (latest, {timeCreated}) => (timeCreated > latest ? timeCreated : latest),
    new Date(0).toISOString(),
  );

  const {recipientEntitySummary} = response;

  return (
    <BaseResponseView
      entityMapping={entityMapping}
      entityId={recipientEntitySummary?.id}
      entityName={recipientEntitySummary?.full_name}
      responseTime={latestResponseTime}
      event={event}
      workflow={workflow}
      isNps={isNps}
    >
      {response.memberResponse.map((answer) => {
        const {id: answerId, moduleId, moduleValue} = answer;
        const isNps = answer.responseModuleType === 'nps_survey_answer';
        const module = modules[moduleId];
        const questionText =
          response?.moduleQuestions?.[answerId] || module?.question || '';

        return (
          <div className={css.answerRow} key={answer.id}>
            <div className={css.question}>{questionText}</div>
            {answer.responseModuleType === 'rating_scale_survey_answer' ||
            isNps ? (
              <RatingScaleAnswer
                moduleValue={Number(answer.moduleValue)}
                maxRating={module.maxRating}
                isNps={isNps}
              />
            ) : flagged ? (
              <Highlighter
                className={css.answer}
                searchWords={flaggedWords.map(
                  (flaggedWord) => flaggedWord.word,
                )}
                highlightClassName={css.flaggedHighlight}
              >
                {/*TODO(marcos) fix autotruncatedtext to work like function component that emits
                     both the full text and truncated text as values? i dunno, this doesn't work:

                     <AutoTruncatedText text={answer.moduleValue} />
                  */}
                {moduleValue instanceof Array
                  ? moduleValue.join(', ')
                  : String(moduleValue)}
              </Highlighter>
            ) : (
              <div className={css.answer}>
                <AutoTruncatedText text={answer.moduleValue} />
              </div>
            )}
          </div>
        );
      })}
    </BaseResponseView>
  );
};

const RatingScaleAnswer = ({
  moduleValue,
  maxRating,
  isNps,
}: {
  moduleValue: number,
  maxRating: number,
  isNps: boolean,
}) => {
  const getMood = isNps ? getNpsRatingMood : getRatingMood;
  const mood = getMood(moduleValue, maxRating);

  return (
    <div className={classify(css.answerRatingScale, css[mood])}>
      {moduleValue} / {maxRating}
    </div>
  );
};

function EntityHeader({
  label,
  entityUrl,
  entityId,
  entityName,
  time,
  isCollapsable,
  isCollapsed,
  isArchived,
  conversation,
}) {
  const uploaded_files = conversation?.uploaded_files || [];

  const handleChildClick = function (e) {
    e.stopPropagation();
  };

  return (
    <div className={css.responseContainer}>
      <div className={css.recipientHeader}>
        <div className={css.headerCell}>
          <span className={css.headerLabel}>{label}:</span>
          {isArchived ? (
            <span className={css.archivedName}>{`Archived ${label}`}</span>
          ) : (
            <span className={css.name}>
              {entityUrl ? (
                <Link to={`/people/${entityUrl}/${entityId}`}>
                  {entityName}
                </Link>
              ) : (
                entityName
              )}
            </span>
          )}
        </div>
        <div className={css.headerCell}>
          <span className={css.headerLabel}>Date:</span>
          <span className={css.date}>{formatDateTime(time)} UTC</span>
          {isCollapsable ? (
            <CaretDown className={isCollapsed ? css.flip : null} />
          ) : null}
        </div>
      </div>
      {uploaded_files.length > 0 ? (
        <div
          className={classify(
            css.recipientHeader,
            css.responseContainerPadding,
          )}
        >
          <div className={css.filesHeaderCell} onClick={handleChildClick}>
            <span className={css.filesHeaderLabel}>Files uploaded:</span>
            <div className={css.filesItemContainer}>
              <FileUploadedComps fileUploaded={uploaded_files} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

const FileUploadedComps = ({
  fileUploaded,
}: {
  fileUploaded: ConversationUploadedfiles[],
}) => {
  const [collapsed, setCollapsed] = React.useState(false);

  return (
    <div
      className={classify(
        css.fileHeaderContainer,
        collapsed && css.isCollapsable,
      )}
    >
      {fileUploaded.length > 0 ? (
        <Paragraph className={css.fileLinkable}>
          <a href={createDownloadableUrl(fileUploaded[0])} target="_blank">
            {fileUploaded[0].file_name}
          </a>
          {!collapsed && (
            <CaretDown
              className={css.iconLinkable}
              onClick={() => setCollapsed(!collapsed)}
            />
          )}
        </Paragraph>
      ) : null}
      {collapsed && fileUploaded.length > 1 && (
        <div className={css.filesMain}>
          {fileUploaded.map(
            (file, index) =>
              index !== 0 && (
                <a href={createDownloadableUrl(file)} target="_blank">
                  <Paragraph className={css.fileLinkable}>
                    {file.file_name}
                  </Paragraph>
                </a>
              ),
          )}

          <Paragraph
            className={css.fileLinkable}
            onClick={() => setCollapsed(!collapsed)}
          >
            Collapse
            <CaretDown className={classify(css.iconLinkable, css.flip)} />
          </Paragraph>
        </div>
      )}
    </div>
  );
};

const createDownloadableUrl = (files: ConversationUploadedfiles): string =>
  `${window.location.origin}/api/v1/file-access/download?file_container=${
    files.file_container
  }&file_path=${files.file_path}&file_name=${encodeURIComponent(
    files.file_name,
  )}`;
