//@flow strict

import * as React from 'react';
import {useHistory} from 'src/rerouter';
import {
  BodyLarge,
  SubTitleLarge,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
  DIALOG_SEMANTIC,
} from '@spaced-out/ui-design-system/lib/components/Dialog';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import css from './data-export-success-genesis.css';


export const ExportDefaultContent = (): React.Node => (
  <>
    <BodyLarge color="secondary">
      When your requested report is ready for download, you will receive an
      email with a download link. While estimated times are varied, we expect it
      will be ready soon.
    </BodyLarge>

    <BodyLarge color="secondary">
      To find all past reports, navigate to the Reports tab within Settings.
      (Settings &#8594; Reports)
    </BodyLarge>

    <BodyLarge color="secondary">
      If you do not receive an email, check your spam folder or request the
      report again.
    </BodyLarge>
  </>
);

export const ReportExportInit = ({
  CustomContent = <ExportDefaultContent />,
  handlePrimaryCta = () => {},
  handleSecondaryCta = () => {},
}: {
  CustomContent?: string | React.Node,
  handlePrimaryCta?: () => void,
  handleSecondaryCta?: () => void,
}): React.Node => {
  const router = useHistory();
  const [showExportDialog, setShowExportDialog] = React.useState(true);

  return (
    <Dialog
      isOpen={showExportDialog}
      semantic={DIALOG_SEMANTIC.success}
      tapOutsideToClose
      classNames={{content: css.dialogContainer}}
    >
      <DialogHeader>
        <SubTitleLarge>Request Successful</SubTitleLarge>
      </DialogHeader>

      <DialogBody className={css.dialogBody}>{CustomContent}</DialogBody>

      <DialogFooter>
        <Button
          type="tertiary"
          isFluid
          onClick={() => {
            handleSecondaryCta();
            setShowExportDialog(false);
            router.push('/settings/reports');
          }}
        >
          View Reports
        </Button>

        <Button
          type="primary"
          isFluid
          onClick={() => {
            handlePrimaryCta();
            setShowExportDialog(false);
          }}
        >
          Okay
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export const ReportExportSuccess = ({
  handlePrimaryCta = () => {},
  handleSecondaryCta = () => {},
}: {
  handlePrimaryCta?: () => void,
  handleSecondaryCta?: () => void,
}): React.Node => {
  const router = useHistory();
  const [showExportDialog, setShowExportDialog] = React.useState(true);

  return (
    <Dialog
      isOpen={showExportDialog}
      semantic={DIALOG_SEMANTIC.success}
      tapOutsideToClose
      classNames={{content: css.dialogContainer}}
    >
      <DialogHeader>
        <SubTitleLarge>Report Ready</SubTitleLarge>
      </DialogHeader>

      <DialogBody>
        <BodyLarge color="secondary">
          Your report is ready. You can download it by navigating to the Reports
          page.
        </BodyLarge>
      </DialogBody>

      <DialogFooter>
        <Button
          type="tertiary"
          isFluid
          onClick={() => {
            handleSecondaryCta();
            setShowExportDialog(false);
            router.push('/settings/reports');
          }}
        >
          View Reports
        </Button>

        <Button
          type="primary"
          isFluid
          onClick={() => {
            handlePrimaryCta();
            setShowExportDialog(false);
          }}
        >
          Okay
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
