import {set} from 'sculpt';


export default class Cache {
  constructor() {
    this.cache = {};
  }

  initialize(data) {
    this.cache = data;
  }

  set(key, hash) {
    hash = hash || key;
    this.cache = set(this.cache, key, {
      timestamp: new Date().getTime(),
      hash,
    });
  }

  expire(key) {
    this.cache = set(this.cache, key, undefined);
  }

  expired(key, ttl, hash) {
    hash = hash || key;

    if (this.__perma) {
      return false;
    }

    const cached = this.cache[key];
    if (!cached) {
      return true;
    }

    const now = new Date().getTime();
    if (now - cached.timestamp > ttl || hash !== cached.hash) {
      this.expire(key);
      return true;
    }

    return false;
  }

  serialize() {
    return this.cache;
  }

  setPerma(perma) {
    this.__perma = perma;
  }
}

