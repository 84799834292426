// @noflow

import type {Props as TopNavLinkProps} from 'src/components/navigation/top-nav-link.jsx';

import * as React from 'react';
import {connect} from 'react-redux';

import {getUnreadCounts} from 'src/action-creators/messages';
import {selectTotalUnreadThreadCount} from 'src/selectors/messages';

import TopNavLink from 'src/components/navigation/top-nav-link.jsx';

import css from './messages-nav-link.css';


type Props = MappedProps & {
  topNavLinkProps: TopNavLinkProps,
};

class MessagesNavLink extends React.Component<Props> {
  componentDidMount() {
    this.props.getUnreadCounts();
  }

  render() {
    const {totalUnreadThreadCount, topNavLinkProps} = this.props;
    return (
      <TopNavLink
        {...topNavLinkProps}
        icon={
          <IconWithBubble
            icon={topNavLinkProps.icon}
            unreadCount={totalUnreadThreadCount}
          />
        }
      />
    );
  }
}

const IconWithBubble = ({
  icon,
  unreadCount,
}: {
  icon: React.Element<any>,
  unreadCount: number,
}) => (
  <React.Fragment>
    {React.cloneElement(icon, {key: 'icon'})}
    {unreadCount > 0 && (
      <span key="unread-count" className={css.topNavBubble}>
        {unreadCount > 99 ? '99+' : unreadCount}
      </span>
    )}
  </React.Fragment>
);

type MappedProps = {
  totalUnreadThreadCount: number,
  authedUserPhoneError: boolean,
};

const mapStateToProps = (state): MappedProps => ({
  totalUnreadThreadCount: selectTotalUnreadThreadCount(state),
  authedUserPhoneError: state.accounts.authedUserPhoneError,
});

const mapDispatchToProps = {
  getUnreadCounts,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  topNavLinkProps: ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(MessagesNavLink);
