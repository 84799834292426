// @flow strict

export type HelperAction = {
  type: 'toggle-template-helper',
  payload: {
    expanded: boolean,
  },
};

export const TOGGLE_TEMPLATE_HELPER = 'toggle-template-helper';

export const toggleTemplateHelper = (expanded: boolean): HelperAction => ({
  type: TOGGLE_TEMPLATE_HELPER,
  payload: {
    expanded,
  },
});
