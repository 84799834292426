// @noflow

import type {WorkflowReport} from 'src/api-parsers/report';

import omit from 'lodash/omit';

import Store from './base';


export default class WorkflowReportStore extends Store {
  state: {
    stats: {
      [key: string]: WorkflowReport,
    },
    fetching: {
      [key: string]: Promise<void>,
    },
  };

  constructor() {
    super('workflow report');

    this.state = {
      stats: {},
      fetching: {},
    };
  }

  startFetching(workflowId: string, promise: Promise<void>) {
    this.updateState({
      fetching: {
        $assign: {
          [workflowId]: promise,
        },
      },
    });
  }

  receiveFetched(workflowStats: WorkflowReport) {
    const {workflowId} = workflowStats;
    this.updateState({
      fetching: {
        $set: omit(this.state.fetching, workflowId),
      },
      stats: {
        $assign: {
          [workflowId]: workflowStats,
        },
      },
    });
  }

  get(id: string): WorkflowReport {
    return this.state.stats[id];
  }
}

