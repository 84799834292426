// @flow strict
//$FlowFixMe[nonstrict-import]
import type {Modal, TitleBarDetailsModal} from 'src/action-creators/modal';
//$FlowFixMe[nonstrict-import]
import type {ModalType} from 'src/components/modals/modal-root.jsx';

import * as React from 'react';

import {classify} from 'src/utils/classify';
import useAutofocus from 'src/hooks/useAutofocus';

import {Button} from 'src/designSystem2021Components/buttons.jsx';
import {Header, Clickable} from 'src/designSystem2021Components/text-v2.jsx';

import CancelIcon from 'src/images/icons/cancel.svg?noAttrs';
import InfoIcon from 'src/images/icons/info-modal.svg?noAttrs';
import AlertIcon from 'src/images/icons/alert-modal.svg?noAttrs';

import css from './base-modal-design-system-2021.css';

/**
 * NOTE(diwakersurya): copied from ./base-model and adapted to use new design system
 */
const BaseModal = ({
  className,
  children,
  abortText,
  confirmText,
  handleAbort,
  handleConfirm,
  removeModal,
}: Modal): React.Element<'div'> => {
  const confirmButtonRef = useAutofocus();

  return (
    <div className={classify(className, css.container)}>
      {children}
      {/* TODO:(diwakersurya) below buttons should be extracted into its own component to allow
      for better composition */}
      <div className={css.buttonMenu}>
        {abortText && (
          <Button
            type="secondary"
            className={css.abortButton}
            onClick={(event) => {
              handleAbort && handleAbort(event);
              removeModal && removeModal();
            }}
            data-qa-id="modal-abort-button"
          >
            <Clickable>{abortText}</Clickable>
          </Button>
        )}
        {confirmText && (
          <Button
            type={'primary'}
            onClick={(event) => {
              handleConfirm && handleConfirm(event);
              removeModal && removeModal();
            }}
            ref={confirmButtonRef}
            data-qa-id="modal-confirm-button"
          >
            <Clickable>{confirmText}</Clickable>
          </Button>
        )}
      </div>
    </div>
  );
};

const modalTitleClasses: {[type: ModalType]: string, ...} = {
  GENERIC_ERROR_DS_2021: css.error,
  GENERIC_INFO_DS_2021: css.info,
  GENERIC_MODAL_DS_2021: css.info,
  GENERIC_WARNING_DS_2021: css.warning,
};
const modalIcon: {[type: ModalType]: React.Node} = {
  GENERIC_ERROR_DS_2021: <AlertIcon />,
  GENERIC_INFO_DS_2021: <InfoIcon />,
  GENERIC_MODAL_DS_2021: <InfoIcon />,
  GENERIC_WARNING_DS_2021: <InfoIcon />,
};

/**
 * NOTE(iris): this modal has click overlay to dismiss on by default
 */
export const TitleBarModal = (props: TitleBarDetailsModal): React.Node => {
  const {
    className,
    type,
    title = 'Important Information',
    titleIcon: propTitleIcon,
    hasTitleXButton = true,
    handleAbort,
    removeModal,
    cancelIcon = <CancelIcon />,
    children,
    titleElement: propTitleElement,
  } = props;
  const titleBarClass =
    typeof type === 'string' ? modalTitleClasses[type] : css.info;
  const titleIcon =
    propTitleIcon ?? (typeof type === 'string' ? modalIcon[type] : null);
  const titleElement = propTitleElement ?? (
    <DefaultTitle
      titleBarClass={titleBarClass}
      title={title}
      titleIcon={titleIcon}
      hasTitleXButton={hasTitleXButton}
      cancelIcon={cancelIcon}
      handleAbort={handleAbort}
      removeModal={removeModal}
    />
  );
  return (
    <BaseModal {...props} className={classify(css.titleBarModal, className)}>
      {titleElement}
      {children}
    </BaseModal>
  );
};

export type DefaultTitleProps = {
  title: React.Node,
  titleIcon?: React.Node,
  hasTitleXButton?: boolean,
  cancelIcon: React.Node,
  titleBarClass: string,
  handleAbort?: (event: SyntheticEvent<>) => mixed,
  removeModal?: () => void,
};
const DefaultTitle = ({
  title,
  titleIcon,
  hasTitleXButton,
  cancelIcon,
  titleBarClass,
  handleAbort,
  removeModal,
}: DefaultTitleProps): React.Node =>
  Boolean(title) && (
    <div className={classify(css.titleBar, titleBarClass)}>
      <div className={css.titleAndIcon}>
        {titleIcon}
        <div className={css.title}>
          <Header>{title}</Header>
        </div>
      </div>
      {hasTitleXButton && (
        <Button
          type="tertiary"
          onClick={(event) => {
            handleAbort && handleAbort(event);
            removeModal && removeModal();
          }}
          icon={cancelIcon}
        ></Button>
      )}
    </div>
  );

export default BaseModal;

export const ErrorTitleBarModal = (props: TitleBarDetailsModal): React.Node => (
  <TitleBarModal {...props} type={'GENERIC_ERROR_DS_2021'} />
);
export const WarningTitleBarModal = (
  props: TitleBarDetailsModal,
): React.Node => <TitleBarModal {...props} type={'GENERIC_WARNING_DS_2021'} />;
export const InfoTitleBarModal = (props: TitleBarDetailsModal): React.Node => (
  <TitleBarModal {...props} type={'GENERIC_INFO_DS_2021'} />
);
