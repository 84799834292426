// @flow strict
import type {
  Event,
  Page,
  Job,
  CreateEventPayload,
  Analytics,
  AnalyticsPayload,
} from 'src/types/drive/drive.js';
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import * as reduxApiBff from 'src/utils/redux-api-bff.js';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2.js';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
//$FlowFixMe[nonstrict-import]
import {AnalyticsService} from 'src/analytics';
import {DRIVE} from 'src/analytics/constants_event.js';


export const DRIVE_EVENTS = 'drive/events';
export const DRIVE_EVENTS_LOADING = 'drive/events-loading';
export const DRIVE_EVENTS_ERROR = 'drive/events-error';

export const DRIVE_CREATE_EVENT = 'drive/create-event';
export const DRIVE_CREATE_EVENT_LOADING = 'drive/create-event-loading';
export const DRIVE_CREATE_EVENT_ERROR = 'drive/create-event-error';

export const DRIVE_EDIT_EVENT = 'drive/edit-event';
export const DRIVE_EDIT_EVENT_LOADING = 'drive/edit-event-loading';
export const DRIVE_EDIT_EVENT_ERROR = 'drive/edit-event-error';

export const DRIVE_EVENT = 'drive/event';
export const DRIVE_EVENT_LOADING = 'drive/event-loading';
export const DRIVE_EVENT_ERROR = 'drive/event-error';

export const DRIVE_PAGES = 'drive/pages';
export const DRIVE_PAGES_LOADING = 'drive/pages-loading';
export const DRIVE_PAGES_ERROR = 'drive/pages-error';

export const DRIVE_STATUS_PUBLISH = 'drive/status-update-publish';
export const DRIVE_STATUS_ARCHIVE = 'drive/status-update-archive';

export const DRIVE_STATUS_UPDATE_LOADING = 'drive/status-update-loading';

export const DRIVE_JOBS = 'drive/jobs';
export const DRIVE_JOBS_LOADING = 'drive/jobs-loading';
export const DRIVE_JOBS_ERROR = 'drive/jobs-error';

export const DRIVE_LINKED_JOBS = 'drive/linked-jobs';
export const DRIVE_LINKED_JOBS_LOADING = 'drive/linked-jobs-loading';
export const DRIVE_LINKED_JOBS_ERROR = 'drive/linked-jobs-error';

export const DRIVE_LINK_PAGE = 'drive/link-page';
export const DRIVE_LINK_PAGE_LOADING = 'drive/link-page-loading';
export const DRIVE_LINK_PAGE_ERROR = 'drive/link-page-error';
export const DRIVE_UNLINK_PAGE = 'drive/unlink-page';

export const DRIVE_LINK_JOBS = 'drive/link-jobs';
export const DRIVE_LINK_JOBS_LOADING = 'drive/link-jobs-loading';
export const DRIVE_LINK_JOBS_ERROR = 'drive/link-jobs-error';

export const DRIVE_EVENT_ANALYTICS = 'drive/analytics';
export const DRIVE_EVENT_ANALYTICS_LOADING = 'drive/analytics-loading';
export const DRIVE_EVENT_ANALYTICS_ERROR = 'drive/analytics-error';

type GetEventsAction = {
  type: typeof DRIVE_EVENTS,
  payload: Event[],
};
type GetEventsLoadingAction = {
  type: typeof DRIVE_EVENTS_LOADING,
};
type GetEventsErrorAction = {
  type: typeof DRIVE_EVENTS_ERROR,
  payload: mixed,
};

type CreateEventAction = {
  type: typeof DRIVE_CREATE_EVENT,
  payload: Event,
};
type CreateEventLoadingAction = {
  type: typeof DRIVE_CREATE_EVENT_LOADING,
};
type CreateEventErrorAction = {
  type: typeof DRIVE_CREATE_EVENT_ERROR,
  payload: mixed,
};

type EditEventAction = {
  type: typeof DRIVE_EDIT_EVENT,
  payload: Event,
};
type EditEventLoadingAction = {
  type: typeof DRIVE_EDIT_EVENT_LOADING,
};
type EditEventErrorAction = {
  type: typeof DRIVE_EDIT_EVENT_ERROR,
  payload: mixed,
};

type GetEventAction = {
  type: typeof DRIVE_EVENT,
  payload: Event,
};
type GetEventLoadingAction = {
  type: typeof DRIVE_EVENT_LOADING,
};
type GetEventErrorAction = {
  type: typeof DRIVE_EVENT_ERROR,
  payload: mixed,
};

type GetPagesAction = {
  type: typeof DRIVE_PAGES,
  payload: Page[],
};
type GetPagesLoadingAction = {
  type: typeof DRIVE_PAGES_LOADING,
};
type GetPagesErrorAction = {
  type: typeof DRIVE_PAGES_ERROR,
  payload: mixed,
};

type UpdateEventStatusPublishAction = {
  type: typeof DRIVE_STATUS_PUBLISH,
  payload: Event,
};

type UpdateEventStatusArchiveAction = {
  type: typeof DRIVE_STATUS_ARCHIVE,
  payload: Event,
};

type UpdateEventStatusLoadingAction = {
  type: typeof DRIVE_STATUS_UPDATE_LOADING,
};

type GetJobsAction = {
  type: typeof DRIVE_JOBS,
  payload: Job[],
};

type GetJobsErrorAction = {
  type: typeof DRIVE_JOBS_ERROR,
  payload: mixed,
};

type GetLinkedJobsAction = {
  type: typeof DRIVE_LINKED_JOBS,
  payload: Job[],
};

type GetLinkedJobsLoadingAction = {
  type: typeof DRIVE_LINKED_JOBS_LOADING,
};
type GetLinkedJobsErrorAction = {
  type: typeof DRIVE_LINKED_JOBS_ERROR,
  payload: mixed,
};

type DriveLinkPageEventAction = {
  type: typeof DRIVE_LINK_PAGE,
  payload: Event,
};
type DriveLinkPageLoadingAction = {
  type: typeof DRIVE_LINK_PAGE_LOADING,
};
type DriveLinkPageErrorAction = {
  type: typeof DRIVE_LINK_PAGE_ERROR,
  payload: mixed,
};

type DriveUnLinkPageEventAction = {
  type: typeof DRIVE_UNLINK_PAGE,
  payload: Event,
};

type LinkJobsAction = {
  type: typeof DRIVE_LINK_JOBS,
  payload: Job[],
};
type LinkJobsLoadingAction = {
  type: typeof DRIVE_LINK_JOBS_LOADING,
};
type LinkJobsErrorAction = {
  type: typeof DRIVE_LINK_JOBS_ERROR,
  payload: mixed,
};

type GetEventAnalyticsAction = {
  type: typeof DRIVE_EVENT_ANALYTICS,
  payload: Analytics[],
};
type GetEventAnalyticsLoadingAction = {
  type: typeof DRIVE_EVENT_ANALYTICS_LOADING,
};
type GetEventAnalyticsErrorAction = {
  type: typeof DRIVE_EVENT_ANALYTICS_ERROR,
  payload: mixed,
};
export type DriveActions =
  | GetEventsAction
  | GetEventsLoadingAction
  | GetEventsErrorAction
  | CreateEventAction
  | CreateEventLoadingAction
  | CreateEventErrorAction
  | EditEventAction
  | EditEventLoadingAction
  | EditEventErrorAction
  | GetEventAction
  | GetEventLoadingAction
  | GetEventErrorAction
  | GetPagesAction
  | GetPagesLoadingAction
  | GetPagesErrorAction
  | UpdateEventStatusPublishAction
  | UpdateEventStatusArchiveAction
  | UpdateEventStatusLoadingAction
  | GetJobsAction
  | GetJobsErrorAction
  | GetLinkedJobsAction
  | GetLinkedJobsLoadingAction
  | GetLinkedJobsErrorAction
  | DriveLinkPageEventAction
  | DriveLinkPageLoadingAction
  | DriveLinkPageErrorAction
  | DriveUnLinkPageEventAction
  | LinkJobsAction
  | LinkJobsLoadingAction
  | LinkJobsErrorAction
  | GetEventAnalyticsAction
  | GetEventAnalyticsLoadingAction
  | GetEventAnalyticsErrorAction;

const getEvents = (events: Event[]): GetEventsAction => ({
  type: DRIVE_EVENTS,
  payload: events,
});

const getEventsError = (payload: mixed): GetEventsErrorAction => ({
  type: DRIVE_EVENTS_ERROR,
  payload,
});

const createEvent = (event: Event): CreateEventAction => ({
  type: DRIVE_CREATE_EVENT,
  payload: event,
});

const createEventError = (payload: mixed): CreateEventErrorAction => ({
  type: DRIVE_CREATE_EVENT_ERROR,
  payload,
});

const editEventError = (payload: mixed): EditEventErrorAction => ({
  type: DRIVE_CREATE_EVENT_ERROR,
  payload,
});

const getEvent = (payload: Event): GetEventAction => ({
  type: DRIVE_EVENT,
  payload,
});

const getEventError = (payload: mixed): GetEventErrorAction => ({
  type: DRIVE_EVENT_ERROR,
  payload,
});

const getDrivePages = (pages: Page[]): GetPagesAction => ({
  type: DRIVE_PAGES,
  payload: pages,
});

const getDrivePagesError = (payload: mixed): GetPagesErrorAction => ({
  type: DRIVE_PAGES_ERROR,
  payload,
});
const updateEventStatusToPublish = (
  event: Event,
): UpdateEventStatusPublishAction => ({
  type: DRIVE_STATUS_PUBLISH,
  payload: event,
});

const updateEventStatusToArchive = (
  event: Event,
): UpdateEventStatusArchiveAction => ({
  type: DRIVE_STATUS_PUBLISH,
  payload: event,
});

const getLinkedJobs = (jobs: Job[]): GetLinkedJobsAction => ({
  type: DRIVE_LINKED_JOBS,
  payload: jobs,
});

const getLinkedJobsError = (payload: mixed): GetLinkedJobsErrorAction => ({
  type: DRIVE_LINKED_JOBS_ERROR,
  payload,
});

const linkJobs = (jobs: Job[]): LinkJobsAction => ({
  type: DRIVE_LINK_JOBS,
  payload: jobs,
});

const getJobs = (jobs: Job[]): GetJobsAction => ({
  type: DRIVE_JOBS,
  payload: jobs,
});

const getJobsError = (payload: mixed): GetJobsErrorAction => ({
  type: DRIVE_JOBS_ERROR,
  payload,
});

const linkPageEventLoading = (): DriveLinkPageLoadingAction => ({
  type: DRIVE_LINK_PAGE_LOADING,
});

const linkPageEventError = (payload: mixed): DriveLinkPageErrorAction => ({
  type: DRIVE_LINK_PAGE_ERROR,
  payload,
});

const unlinkPageEvent = (payload: Event): DriveUnLinkPageEventAction => ({
  type: DRIVE_UNLINK_PAGE,
  payload,
});

const getEventAnalytics = (
  analytics: Analytics[],
): GetEventAnalyticsAction => ({
  type: DRIVE_EVENT_ANALYTICS,
  payload: analytics,
});

const getEventAnalyticsError = (payload: mixed): GetEventsErrorAction => ({
  type: DRIVE_EVENT_ANALYTICS_ERROR,
  payload,
});

export const fetchEventAnalytics: (
  name: string,
  payload: AnalyticsPayload,
) => ThunkAction<void> = flow(
  key((name) => `events/${name}/analytics`),
  fetching(),
)((name: string, payload: AnalyticsPayload) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.post(`candidate-event-statuses/${name}`, payload, undefined, {
      apiPath: '/trmapi/api/',
    }),
  ).then(
    (response: Analytics[]) => {
      dispatch(getEventAnalytics(response));
    },
    (error) => {
      dispatch(getEventAnalyticsError(error?.response?.errors));
    },
  );
});

export const linkPage =
  (id: number, pageId: number): ThunkAction<mixed> =>
  (dispatch: Dispatch) => {
    dispatch(linkPageEventLoading());

    return dispatch(
      reduxApiBff.post(`drives/${id}/update-page?pageId=${pageId}`),
    ).then(
      (response: Event) => {
        dispatch(unlinkPageEvent(response));
      },
      (error) => {
        dispatch(linkPageEventError(error?.response?.errors));
      },
    );
  };

export const unlinkPage: (id: number) => ThunkAction<void> = flow(
  key((id) => `events/${id}/link-page`),
  fetching(),
)((id: number) => (dispatch: Dispatch) => {
  return dispatch(reduxApiBff.post(`drives/${id}/update-page?pageId=0`)).then(
    (response: Event) => {
      dispatch(unlinkPageEvent(response));
    },
    (error) => {
      dispatch(linkPageEventError(error?.response?.errors));
    },
  );
});

export const fetchDriveEvent: (id: number) => ThunkAction<void> = flow(
  key(() => `events/get-event`),
  fetching(),
)((id: number) => (dispatch: Dispatch) => {
  return dispatch(reduxApiBff.get(`drives/${id}`)).then(
    (response) => {
      return dispatch(getEvent(response));
    },
    (error) => {
      dispatch(getEventError(error?.response?.errors));
    },
  );
});

export const fetchDriveEvents: () => ThunkAction<void> = flow(
  key(() => `events/get-events`),
  fetching(),
)(() => (dispatch: Dispatch) => {
  return dispatch(reduxApiBff.get('drives')).then(
    (response: Event[]) => {
      return dispatch(getEvents(response));
    },
    (error) => {
      dispatch(getEventsError(error?.response?.errors));
    },
  );
});

export const createDriveEvent: (
  payload: CreateEventPayload,
) => ThunkAction<void> = flow(
  key(() => `events/create-event`),
  fetching(),
)((payload: CreateEventPayload) => (dispatch: Dispatch) => {
  return dispatch(reduxApiBff.post('drives', payload)).then(
    (response: Event) => {
      return dispatch(createEvent(response));
    },
    (error) => {
      dispatch(createEventError(error?.response?.errors));
    },
  );
});

export const fetchDrivePages: () => ThunkAction<void> = flow(
  key(() => `events/drive-pages`),
  fetching(),
)(() => (dispatch: Dispatch) => {
  return dispatch(reduxApiBff.get('drives/pages/unlinked')).then(
    (response: Page[]) => {
      dispatch(getDrivePages(response));
    },
    (error) => {
      dispatch(getDrivePagesError(error?.response?.errors));
    },
  );
});

export const driveStatusPublish: (id: number) => ThunkAction<void> = flow(
  key(() => `events/drive-status-publish`),
  fetching(),
)((id: number) => (dispatch: Dispatch) => {
  return dispatch(reduxApiBff.post(`drives/${id}/publish`)).then(
    (response: Event) => {
      dispatch(updateEventStatusToPublish(response));
    },
    (error) => {
      dispatch(editEventError(error?.response?.errors));
    },
  );
});

export const driveStatusArchive: (id: number) => ThunkAction<void> = flow(
  key(() => `events/drive-status-archive`),
  fetching(),
)((id: number) => (dispatch: Dispatch) => {
  return dispatch(reduxApiBff.post(`drives/${id}/archive`)).then(
    (response: Event) => {
      dispatch(updateEventStatusToArchive(response));
    },
    (error) => {
      dispatch(editEventError(error?.response?.errors));
    },
  );
});

export const fetchDriveJobs: () => ThunkAction<void> = flow(
  key(() => `events/linked-jobs`),
  fetching(),
)(() => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.get('jobs/career-page', undefined, {
      apiPath: '/trmapi/api/',
    }),
  ).then(
    (response) => {
      return dispatch(getJobs(response));
    },
    (error) => {
      dispatch(getJobsError(error?.response?.errors));
    },
  );
});

export const fetchDriveLinkedJobs: (id: number) => ThunkAction<void> = flow(
  key(() => `events/linkedjobs`),
  fetching(),
)((id: number) => (dispatch: Dispatch) => {
  return dispatch(reduxApiBff.get(`drives/${id}/jobs`)).then(
    (response) => {
      return dispatch(getLinkedJobs(response));
    },
    (error) => {
      dispatch(getLinkedJobsError(error?.response?.errors));
    },
  );
});

type LinkJobPayload = {
  drive_job_mappings: JobsMapping[],
};

type JobsMapping = {
  driveId: number,
  jobId: number,
  isActive: boolean,
  title: string,
};

export const linkNewJobs: (
  id: number,
  payload: LinkJobPayload,
) => ThunkAction<void> = flow(
  key(() => `events/drive-status-archive`),
  fetching(),
)((id: number, payload: LinkJobPayload) => (dispatch: Dispatch, getState) => {
  const state = getState();
  const agency = state.agency.agency;
  return dispatch(reduxApiBff.post(`drives/${id}/update-jobs`, payload)).then(
    (response: Job[]) => {
      dispatch(linkJobs(response));
      AnalyticsService.track(DRIVE.JOBS_LINKED, {
        agencyId: agency.id,
        agencyName: agency.name,
      });
    },
    (error) => {
      dispatch(getLinkedJobsError(error?.response?.errors));
    },
  );
});
