// @flow strict-local

import type {SyncRoute} from 'src/rerouter';
import {redirect} from './utils.jsx';


export const workflowJourneyRedirect: SyncRoute = {
  path: '(analytics/)workflow(s)',
  element: redirect((location) => ({
    ...location,
    pathname: location.pathname
      .replace('workflow', 'journey')
      .replace('event', 'touchpoint'),
  })),
};
