// @flow

// $FlowFixMe[untyped-type-import]
import type {Workflow} from 'src/api-parsers';
import type {WorkflowAction} from 'src/action-creators/workflows';
import type {EntityType} from 'src/types/ats-entities';
import type {BusinessGoals} from 'src/types/workflow';

import keyBy from 'lodash/keyBy';

import {
  RECEIVE_ALL,
  UPDATE,
  BULK_UPDATE_WORKFLOWS,
  DELETE_WORKFLOW,
  RECEIVE_ENTITY_TYPES,
  RECEIVE_BUSINESS_GOALS,
  RECEIVE_RECIPIENT_TYPES,
  RECEIVE_WORKFLOW,
} from 'src/action-creators/workflows';


export type WorkflowState = {
  workflows: {
    // $FlowFixMe[value-as-type] [v1.32.0]
    [workflowId: string]: Workflow,
  },
  workflowsWithEvents: {
    // $FlowFixMe[value-as-type] [v1.32.0]
    [workflowId: string]: Workflow,
  },
  // the entity types supported across all workflows
  entityTypes: EntityType[],
  businessGoals?: BusinessGoals[],
  // recipient allowed for each workflowId
  recipientTypes: {
    [workflowId: string]: string[],
  },
};

const initialState = {
  workflows: Object.freeze({}),
  entityTypes: Object.freeze([]),
  recipientTypes: Object.freeze({}),
  workflowsWithEvents: Object.freeze({}),
};

export default (
  state: WorkflowState = initialState,
  action: WorkflowAction,
): WorkflowState => {
  switch (action.type) {
    case RECEIVE_ALL:
      return {
        ...state,
        workflows: {...state.workflows, ...keyBy(action.payload, 'id')},
      };
    case UPDATE: {
      const {workflowId, update} = action.payload;
      const currentWorkflow = state.workflows[workflowId];
      return {
        ...state,
        workflows: {
          ...state.workflows,
          [workflowId]: {
            ...currentWorkflow,
            ...update,
          },
        },
        workflowsWithEvents: {
          ...state.workflowsWithEvents,
          [workflowId]: {
            ...currentWorkflow,
            ...update,
          },
        },
      };
    }
    case BULK_UPDATE_WORKFLOWS: {
      return {
        ...state,
        workflows: {
          ...state.workflows,
          ...Object.keys(action.payload).reduce((acc, workflowId) => {
            const update = action.payload[workflowId];
            const current = state.workflows[workflowId];
            acc[workflowId] = {...current, ...update};
            return acc;
          }, {}),
        },
        workflowsWithEvents: {
          ...state.workflowsWithEvents,
          ...Object.keys(action.payload).reduce((acc, workflowId) => {
            const update = action.payload[workflowId];
            const current = state.workflows[workflowId];
            acc[workflowId] = {...current, ...update};
            return acc;
          }, {}),
        },
      };
    }
    case RECEIVE_WORKFLOW: {
      return {
        ...state,
        workflows: {...state.workflows, ...keyBy(action.payload, 'id')},
        workflowsWithEvents: {
          ...state.workflowsWithEvents,
          ...keyBy(action.payload, 'id'),
        },
      };
    }
    case DELETE_WORKFLOW:
      const {[action.payload.id]: _removedWorkflow, ...newWorkflows} =
        state.workflows;
      return {...state, workflows: newWorkflows};
    case RECEIVE_ENTITY_TYPES:
      return {...state, entityTypes: action.payload};
    case RECEIVE_BUSINESS_GOALS:
      return {...state, businessGoals: action.payload};
    case RECEIVE_RECIPIENT_TYPES:
      return {
        ...state,
        recipientTypes: {...state.recipientTypes, ...action.payload},
      };
  }

  return state;
};
