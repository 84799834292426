// @flow strict

import * as React from 'react';
import {xor, intersection} from 'lodash';
import {debounceAsync} from 'src/utils/function';


export type ApiLocation = {
  zipcode: string,
  location_name: string,
  latitude: number,
  longitude: number,
  distance: ?number,
};
export type Location = {
  ...ApiLocation,
  label: string,
  value: string,
};

export type ListType = 'inside' | 'outside';
export type LocationOperatorType = 'manual' | 'search';

const DEBOUNCE_TIMEOUT = 500; //debounce timeout for api calls on search
export const MIN_QUERY_LENGTH = 2; //length of query after which api call should be made
export const DEFAULT_ZIPCODE_RADIUS = 20;
export const locationOperatorOptions: Array<{
  label: string,
  value: LocationOperatorType,
}> = [
  {label: 'enter zip codes manually', value: 'manual'},
  {
    label: 'is within radius of a location',
    value: 'search',
  },
];
export const distanceOptions: Array<{label: string, value: ListType}> = [
  {label: 'inside radius', value: 'inside'},
  {
    label: 'outside radius',
    value: 'outside',
  },
];
export const getMapCenter = (source: Location): {lat: number, lng: number} => ({
  lat: source.latitude,
  lng: source.longitude,
});
export const milesToZoomLevels = {
  '20': 9,
  '40': 8,
  '60': 8,
  '80': 7,
  '100': 7,
  '120': 7,
};
export const getZoom = (radius: number): number =>
  milesToZoomLevels[radius] || 10;

const createLocationIdentifierLabel = (location) =>
  `${location.zipcode}${
    location.location_name ? ` (${location.location_name.trim()})` : ''
  }`;
export const ApiLocationToLocation = (
  apiLocations: ApiLocation[],
): Location[] =>
  apiLocations.map((apiLocation) => ({
    ...apiLocation,
    value: apiLocation.zipcode,
    label: createLocationIdentifierLabel(apiLocation),
  }));

export const milesToMeters = (distance: number): number => distance * 1609.344;

export const calculateInsideAndOutsideZipCodes = (
  matchingZipcodes: {[string]: Location},
  value: string[],
): [string[], string[]] => {
  //value contains both inside and outside zipcodes
  const matchedZipcodeValues = Object.keys(matchingZipcodes);
  const insideZipcodeValues =
    matchedZipcodeValues.length > 0
      ? intersection(matchedZipcodeValues, value)
      : value;
  //preserve outside zip codes
  const outsideZipcodesValues = xor(value, insideZipcodeValues);
  return [insideZipcodeValues, outsideZipcodesValues];
};

//this hook lets you prepare async data to work with autocomplete
export function useAsyncAutocomplete<T: {label: string, value: string, ...}>(
  query: string,
  resolveOptions: (string) => Promise<T[]>,
  onSelectOption: (T) => mixed,
  debounceTimeout: number = DEBOUNCE_TIMEOUT,
): {
  query: string,
  isLoading: boolean,
  options: T[],
  error: mixed,
  onSelect: (string) => mixed,
} {
  //react state to manage async autocomplete
  const [isLoading, setIsLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [error, setError] = React.useState(null);

  const debouncedResolveOptions = React.useMemo(
    () => debounceAsync(resolveOptions, debounceTimeout),
    [debounceTimeout],
  );

  //react effect to fetch options
  React.useEffect(() => {
    const fetchOptions = async () => {
      setIsLoading(true);
      try {
        const options = await debouncedResolveOptions(query);
        setOptions(options);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (query) {
      fetchOptions();
    }
  }, [query]);

  const onSelect = (label) => {
    const selectedOption = options.find((option) => option.label === label);
    if (selectedOption) {
      onSelectOption(selectedOption);
    }
  };

  return {query, isLoading, options, error, onSelect};
}
