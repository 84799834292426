// @flow strict

import * as React from 'react';

import {
  // $FlowFixMe[nonstrict-import]
  ContentBlock,
  // $FlowFixMe[nonstrict-import]
  ContentState,
} from 'draft-js';

import WarningMessage from 'src/components/lib/messaging-input/warning-message.jsx';


export const PUNCTUATION_VARIABLE_REGEX: RegExp =
  /([!#$%&'()*+,-.:;?@[\\\]^_`{|}~]{2,})/g;
export const EMOJI_VARIABLE_REGEX: RegExp = new RegExp(
  ['💰', '🤑', '💸', '💳', '💵', '💲'].join('|'),
  'g',
);
export const CAPS_VARIABLE_REGEX: RegExp = /([A-Z]{3,})/g;
export const CAPS_VARIABLE_REGEX_RESOLVE: RegExp =
  /([A-Z]{3,} .+[A-Z]{3,} .+[A-Z]{3,})/;
export const URL_SHORTENER_VARIABLE_REGEX: RegExp =
  /((http:\/\/|https:\/\/)?(bit.ly|ow.ly|tinyurl|rb.gy))[^ ]+/g;

export const ENTITY_PUNCTUATION = 'PUNCTUATION';
export const ENTITY_EMOJI = 'EMOJI';
export const ENTITY_CAPS = 'CAPS';
export const ENTITY_URL_SHORTENER = 'URL_SHORTENER';

// code for finding entities for different types

const findPunctuationEntities = (
  contentBlock: ContentBlock,
  callback,
  contentState: ContentState,
) => {
  findPunctuationVariables(contentBlock.getText(), callback);
};

export const findPunctuationVariables = (
  text: string,
  callback: (start: number, stop: number) => number,
) => {
  let match;
  while ((match = PUNCTUATION_VARIABLE_REGEX.exec(text)) !== null) {
    if (match) {
      const start = match.index;
      callback(start, start + match[0].length);
    }
  }
};

function findEmojiEntities(
  contentBlock: ContentBlock,
  callback,
  contentState: ContentState,
) {
  findEmojiVariables(contentBlock.getText(), callback);
}

export const findEmojiVariables = (
  text: string,
  callback: (start: number, stop: number) => number,
) => {
  let match;
  while ((match = EMOJI_VARIABLE_REGEX.exec(text)) !== null) {
    if (match) {
      const start = match.index;
      callback(start, start + match[0].length);
    }
  }
};

function findCapsEntities(
  contentBlock: ContentBlock,
  callback,
  contentState: ContentState,
) {
  const text = contentBlock.getText();
  if (CAPS_VARIABLE_REGEX_RESOLVE.exec(text) !== null) {
    findCapsVariables(text, callback);
  }
}

export const findCapsVariables = (
  text: string,
  callback: (start: number, stop: number) => number,
) => {
  let match;
  while ((match = CAPS_VARIABLE_REGEX.exec(text)) !== null) {
    if (match) {
      const start = match.index;
      callback(start, start + match[0].length);
    }
  }
};

function findUrlShortenerEntities(
  contentBlock: ContentBlock,
  callback,
  contentState: ContentState,
) {
  findUrlShortenerVariables(contentBlock.getText(), callback);
}

// Recognizing the pattern in the text and replacing them with the text

export const findUrlShortenerVariables = (
  text: string,
  callback: (start: number, stop: number) => number,
) => {
  let match;
  while ((match = URL_SHORTENER_VARIABLE_REGEX.exec(text)) !== null) {
    if (match) {
      const start = match.index;
      callback(start, start + match[0].length);
    }
  }
};

// component which applies the styling required to the text

const ContentWarningVariable =
  (type) =>
  ({children, start, end}) =>
    (
      <WarningMessage type={type} start={start} end={end}>
        {children}
      </WarningMessage>
    );

// Initial places where the code is when the text is typed in the input box

// $FlowFixMe[signature-verification-failure]
export const PunctuationDecorator = () => ({
  component: ContentWarningVariable('punctuation'),
  strategy: findPunctuationEntities,
});

// $FlowFixMe[signature-verification-failure]
export const EmojiDecorator = () => ({
  component: ContentWarningVariable('emoji'),
  strategy: findEmojiEntities,
});

// $FlowFixMe[signature-verification-failure]
export const CapsDecorator = () => ({
  component: ContentWarningVariable('caps'),
  strategy: findCapsEntities,
});

// $FlowFixMe[signature-verification-failure]
export const UrlShortenerDecorator = () => ({
  component: ContentWarningVariable('url_shortener'),
  strategy: findUrlShortenerEntities,
});
