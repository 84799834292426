// @flow strict

import * as React from 'react';

import nameHoc from 'src/flux/nameHoc';
import classify from './classify';


export type ClassNameComponent<
  T = 'div',
  I = React.ElementRef<T>,
> = React.AbstractComponent<React.ElementConfig<T>, I>;

export default function makeClassNameComponent<C: string>(
  baseClassName: string,
  // $FlowFixMe not sure how to type this correctly
  ComponentType: C = 'div',
  name?: string,
): React.AbstractComponent<React.ElementConfig<C>, React.ElementRef<C>> {
  return React.forwardRef(
    // $FlowFixMe[escaped-generic]
    nameComponent(
      ({className, ...props}, ref) => (
        // $FlowFixMe[escaped-generic]
        <ComponentType
          {...props}
          ref={ref}
          className={classify(baseClassName, className)}
        />
      ),
      `CNC(${name || ComponentType})`,
    ),
  );
}

export function makeClassNameComponentCustom<
  C: $ReadOnly<{className?: string, ...}>,
  B,
>(
  baseClassName: string,
  ComponentType: React.AbstractComponent<C, B>,
): React.AbstractComponent<C, B> {
  return React.forwardRef(
    nameHoc(
      ({className, ...props}, ref) => (
        <ComponentType
          {...props}
          ref={ref}
          className={classify(baseClassName, className)}
        />
      ),
      ComponentType,
      'CNC',
    ),
  );
}

function nameComponent(component, name) {
  component.displayName = name;
  return component;
}
