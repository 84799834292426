// @flow strict

// $FlowFixMe[nonstrict-import]
import type {ThunkAction} from 'src/reducers';

import type {
  AudienceList,
  AudienceListApiResponse,
  AudienceListSource,
} from 'src/types/audience-list';
import type {JourneyAudienceState} from 'src/reducers/journey-audience';
import {
  getTrackingProps,
  normalizeAudienceBlocks,
} from 'src/utils/audience-list';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';
//$FlowFixMe[nonstrict-import]
import {AnalyticsService} from 'src/analytics';
import {ENGAGE} from 'src/analytics/constants_event.js';

//$FlowFixMe[nonstrict-import]
import {selectListV2Enabled} from 'src/hooks/product-flags';


export type AudienceFormSteps =
  | 'createMode'
  | 'details'
  | 'copyLists'
  | 'rules'
  | 'review'
  | 'pickNLink'
  | 'summary';
export type UpdateStatePayload = $Shape<JourneyAudienceState>;

export const UPDATE_STATE = 'journey-audience/update';
export const RECEIVE_SELECTED_LISTS = 'journey-audience/receive-selected-lists';
export const SET_INCLUDED_LISTS = 'journey-audience/set-included-lists';
export const SET_EXCLUDED_LISTS = 'journey-audience/set-excluded-lists';
export const RESET = 'journey-audience/reset';
export const GOTO_STEP = 'journey-audience/goto-step';

type UpdateStateAction = {
  type: 'journey-audience/update',
  payload: UpdateStatePayload,
};
type ReceiveSelectedListsAction = {
  type: 'journey-audience/receive-selected-lists',
  payload: {
    includedLists: Map<string, $Shape<AudienceList>>,
    excludedLists: Map<string, $Shape<AudienceList>>,
  },
};
type SetIncludedListsAction = {
  type: 'journey-audience/set-included-lists',
  payload: Map<string, $Shape<AudienceList>>,
};
type SetExcludedListsAction = {
  type: 'journey-audience/set-excluded-lists',
  payload: Map<string, $Shape<AudienceList>>,
};
type ResetStateAction = {
  type: 'journey-audience/reset',
};

export type JourneyAudienceAction =
  | UpdateStateAction
  | ReceiveSelectedListsAction
  | SetIncludedListsAction
  | SetExcludedListsAction
  | ResetStateAction;

export const updateState = (
  payload: UpdateStatePayload,
): UpdateStateAction => ({
  type: UPDATE_STATE,
  payload,
});

export const receiveSelectedLists = (payload: {
  includedLists: Map<string, $Shape<AudienceList>>,
  excludedLists: Map<string, $Shape<AudienceList>>,
}): ReceiveSelectedListsAction => ({
  type: RECEIVE_SELECTED_LISTS,
  payload,
});
export const setIncludedLists = (
  payload: Map<string, $Shape<AudienceList>>,
): SetIncludedListsAction => ({
  type: SET_INCLUDED_LISTS,
  payload,
});
export const setExcludedLists = (
  payload: Map<string, $Shape<AudienceList>>,
): SetExcludedListsAction => ({
  type: SET_EXCLUDED_LISTS,
  payload,
});
export const reset = (): ResetStateAction => ({type: RESET});
export const linkAudienceListsToJourney: (
  journeyId: string,
) => ThunkAction<mixed> = (journeyId) => async (dispatch, getState) => {
  const includedListIds = [...getState().journeyAudience.includedLists.keys()];
  const excludedListIds = [...getState().journeyAudience.excludedLists.keys()];
  await dispatch(
    reduxApi.post(
      `workflows/map`,
      {
        includedIds: includedListIds,
        excludedIds: excludedListIds,
        workflowId: journeyId,
      },
      undefined,
      {apiPath: '/bff/api/v1/'},
    ),
  ).then(() => {
    AnalyticsService.track(ENGAGE.JOURNEY_LIST_ADDED, {
      workflow_id: journeyId,
      list_addition_method: 'existing_list',
      included_list_ids: includedListIds,
      excluded_list_ids: excludedListIds,
    });
  });
  return {includedListIds, excludedListIds};
};
export const updateSelectedLists: (listDetails: {
  includedLists: Map<string, $Shape<AudienceList>>,
  excludedLists: Map<string, $Shape<AudienceList>>,
}) => ReceiveSelectedListsAction = (listDetails) =>
  receiveSelectedLists(listDetails);

export const getJourneyAudience: (journeyId: string) => ThunkAction<mixed> =
  flow(
    key((journeyId) => `journeyAudience/get-${journeyId}`),
    fetching(),
  )((journeyId) => async (dispatch) => {
    const payload = await dispatch(
      reduxApi.get(`/workflows/${journeyId}/mappings`),
    );
    const includedLists = new Map(
      (payload ?? [])
        .filter((resource) => resource.mappingType === 'include')
        .map((resource) => [resource.filter.id, resource.filter]),
    );

    const excludedLists = new Map(
      (payload ?? [])
        .filter((resource) => resource.mappingType === 'exclude')
        .map((resource) => [resource.filter.id, resource.filter]),
    );
    dispatch(receiveSelectedLists({includedLists, excludedLists}));
    return payload;
  });

export const createAudienceListAndLink: (
  journeyId: string,
  source: AudienceListSource,
) => ThunkAction<$Shape<AudienceListApiResponse>> =
  (journeyId, source) => (dispatch, getState) => {
    const audienceList = getState().audienceList;
    const {name, entityType, inclusions, exclusions, all, ruleCreationType} =
      audienceList;
    const rule = normalizeAudienceBlocks({inclusions, exclusions, all});
    //create
    const apiPayload = {
      filter: {
        name,
        rule,
        entityType,
        source,
      },
      resourceType: 'workflow',
      resourceId: journeyId,
    };
    return dispatch(reduxApi.post(`workflows/create-and-map`, apiPayload)).then(
      (payload) => {
        const listV2Enabled = selectListV2Enabled(getState());
        const listV2TrackingParams = listV2Enabled
          ? getTrackingProps(payload.rule)
          : {};
        AnalyticsService.track(ENGAGE.JOURNEY_LIST_ADDED, {
          ...apiPayload,
          workflow_id: journeyId,
          list_addition_method: 'new_list',
          rule_creation_method: ruleCreationType,
          list_id: payload.id,
          ...listV2TrackingParams,
        });
        return payload;
      },
    );
  };
