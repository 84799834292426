// @noflow

import type {Dispatch, GetState} from 'src/reducers';
import type {EntityType} from 'src/types/ats-entities';

import {thunkify} from 'src/utils/thunks';
import {key, fetching, progressAction} from 'src/utils/redux';
import {parseCsv} from 'src/utils/csv-import';
import {prettifyNumber} from 'src/utils';
import * as reduxApi from 'src/utils/redux-api-v2';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

// Step 1 (Choose File) Actions
export const SET_ENTITY_TYPE = 'csvImport/setEntityType';
export const RECEIVE_CSV = 'csvImport/receiveCsv';
export const RECEIVE_CSV_ERRORS = 'csvImport/receiveCsvErrors';

/* Step 2 (Map Fields) Actions */
export const SKIP_COLUMN = 'csvImport/skipColumn';
export const INCLUDE_COLUMN = 'csvImport/includeColumn';
export const SET_FIELD_NAME = 'csvImport/setFieldName';
export const SET_FIELD_TYPE = 'csvImport/setFieldType';
export const SET_DATE_FORMAT = 'csvImport/setDateFormat';
export const SET_MAPPING_ERRORS = 'csvImport/setMappingErrors';
export const SET_ID_FIELD = 'csvImport/idField';
export const ENABLE_CLIENT_VALIDATION = 'csvImport/enableClientValidation';
export const PROCEED_TO_REVIEW = 'csvImport/proceedToReview';

// Other Actions
export const CLEAR_STATE = 'csvImport/clearState';
export const RECEIVE_LAST_STATUS = 'csvImport/receiveLastStatus';
export const RECEIVE_IMPORT_HISTORY = 'csvImport/receiveImportHistory';

// Step ids
export const STEP_CHOOSE_FILE = 'csv_import_choose_file';
export const STEP_MAP_FIELDS = 'csv_import_map_fields';
export const STEP_REVIEW = 'csv_import_review';

/* Allowed id column types */
export const ALLOWED_ID_TYPES = ['string', 'email', 'phone'];

// Exported types
export type CsvImportAction =
  | SetEntityTypeAction
  | ClearStateAction
  | ReceiveCsvAction
  | ReceiveCsvErrorsAction;

export type Step =
  | 'csv_import_choose_file'
  | 'csv_import_map_fields'
  | 'csv_import_review';

export type FieldType =
  | 'string'
  | 'currency'
  | 'number'
  | 'phone'
  | 'email'
  | 'boolean'
  | 'date'
  | 'time';
export type DateFormat =
  | 'YYYY-MM-DD'
  | 'MM-DD-YYYY'
  | 'MM/DD/YYYY'
  | 'DD-MM-YYYY'
  | 'DD/MM/YYYY'
  | 'MM-DD-YY'
  | 'MM/DD/YY'
  | 'DD-MM-YY'
  | 'DD/MM/YY'
  | 'MMM-DD-YYYY';

export type ImportHistory = {
  author: string,
  filename: string,
  records_created: number,
  records_updated: number,
  is_complete: boolean,
  is_failure: boolean,
  time_uploaded: string,
  url: string,
  errors: Object[],
  entity_type: string,
};

// Set entity type
type SetEntityTypeAction = {
  type: 'csvImport/setEntityType',
  entityType: EntityType,
};

export const setImportEntityType = (
  entityType: EntityType,
): SetEntityTypeAction => ({
  type: SET_ENTITY_TYPE,
  entityType,
});

// Clear state
type ClearStateAction = {
  type: 'csvImport/clearState',
};

export const clearState = (): ClearStateAction => ({
  type: CLEAR_STATE,
});

// Parse CSV
type ReceiveCsvAction = {
  type: 'csvImport/receiveCsv',
  payload: Object,
};

const receiveCsv = (payload): ReceiveCsvAction => ({
  type: RECEIVE_CSV,
  payload,
});

type ReceiveCsvErrorsAction = {
  type: 'csvImport/receiveCsvErrors',
  payload: string[],
};

const receiveCsvErrors = (errors: string[]): ReceiveCsvErrorsAction => ({
  type: RECEIVE_CSV_ERRORS,
  payload: errors,
});

const ROW_LIMIT = 10000;
const csvImportKey = 'csvImport/parse2';

export const parseMembersCsv = thunkify(
  key(() => csvImportKey),
  fetching(),
)((file: File) => async (dispatch: Dispatch) => {
  const errors = [];
  let results;

  try {
    results = await parseCsv(file, {
      header: true,
      progress: (percent) => dispatch(progressAction(csvImportKey, percent)),
    });
  } catch (error) {
    errors.push(error);
  }

  if (results) {
    if (results.data.length > ROW_LIMIT) {
      errors.push(`Can't import more than ${prettifyNumber(ROW_LIMIT)} rows.`);
    }
  }

  if (errors.length) {
    return dispatch(receiveCsvErrors(errors));
  } else {
    return dispatch(receiveCsv({results, file}));
  }
});

// Configure fields
type SetFieldNameAction = {
  type: 'csvImport/setFieldName',
  index: number,
  displayName: string,
  attributeName: string,
};

export const setFieldName = (
  index: number,
  displayName: string,
  attributeName: string,
) => (dispatch: Dispatch) => {
  const action: SetFieldNameAction = {
    type: SET_FIELD_NAME,
    index,
    displayName,
    attributeName,
  };
  dispatch(action);
  return dispatch(validateFields());
};

type SetFieldTypeAction = {
  type: 'csvImport/setFieldType',
  index: number,
  fieldType: FieldType,
};

export const setFieldType = (index: number, fieldType: FieldType) => (
  dispatch: Dispatch,
) => {
  const action: SetFieldTypeAction = {
    type: SET_FIELD_TYPE,
    index,
    fieldType,
  };
  dispatch(action);
  return dispatch(validateFields());
};

type SetDateFormatAction = {
  type: 'csvImport/setDateFormat',
  index: number,
  format: DateFormat,
};

export const setDateFormat = (index: number, format: DateFormat) => (
  dispatch: Dispatch,
) => {
  const action: SetDateFormatAction = {
    type: SET_DATE_FORMAT,
    index,
    format,
  };
  dispatch(action);
  return dispatch(validateFields());
};

// Validate fields
type SetMappingErrorsAction = {
  type: 'csvImport/setMappingErrors',
  errors: Object,
};

export const setMappingErrors = (
  errors: Map<string, string>,
): SetMappingErrorsAction => ({
  type: SET_MAPPING_ERRORS,
  errors,
});

export const validateFields = () => (
  dispatch: Dispatch,
  getState: GetState,
): Promise<boolean> => {
  const {
    csvImport: {fieldMappings, clientValidationEnabled},
  } = getState();

  if (!clientValidationEnabled) {
    return Promise.resolve(false);
  }

  const errors = new Map(); // using Map to keep track of the order
  const globalErrors = [];

  if (!fieldMappings || fieldMappings.length === 0) {
    globalErrors.push('Need at least one column in the CSV file.');
  } else {
    // each mapping should be either skipped or filled out
    fieldMappings.forEach((fm, i) => {
      if (!fm.skip) {
        const err = [];
        if (!fm.displayName) {
          err.push('Field name is required.');
        }
        if (!fm.type) {
          err.push('Type is required.');
        }
        if (fm.type === 'date' && !fm.format) {
          err.push('Format is required for Date type.');
        }
        if (err.length > 0) {
          err.push('Or select "Skip" to skip this column.');
          errors.set(i, err);
        }
      }
    });
  }

  if (errors.size && isEmpty(globalErrors)) {
    globalErrors.push('All errors need to be resolved before proceeding.');
  }

  if (!isEmpty(globalErrors)) {
    errors.set('global', globalErrors);
  }

  dispatch(setMappingErrors(errors));

  return Promise.resolve(true);
};

// Skip or include column
type SkipColumnAction = {
  type: 'csvImport/skipColumn',
  index: number,
};

export const skipColumn = (index: number) => (dispatch: Dispatch) => {
  const action: SkipColumnAction = {
    type: SKIP_COLUMN,
    index,
  };
  dispatch(action);
  return dispatch(validateFields());
};

type IncludeColumnAction = {
  type: 'csvImport/includeColumn',
  index: number,
};

export const includeColumn = (index: number) => (dispatch: Dispatch) => {
  const action: IncludeColumnAction = {
    type: INCLUDE_COLUMN,
    index,
  };
  dispatch(action);
  return dispatch(validateFields());
};

// Set id field
type SetIdFieldAction = {
  type: 'csvImport/setIdField',
  idFieldDisplayName: string,
  idFieldAttributeName: string,
};

export const setIdField = (
  idFieldDisplayName: string,
  idFieldAttributeName: string,
) => (dispatch: Dispatch) => {
  const action: SetIdFieldAction = {
    type: SET_ID_FIELD,
    idFieldDisplayName,
    idFieldAttributeName,
  };
  dispatch(action);
  return dispatch(validateFields());
};

// Kickoff an import
type EnableClientValidationAction = {
  type: 'csvImport/enableClientValidation',
};

export const enableClientValidation = (): EnableClientValidationAction => ({
  type: ENABLE_CLIENT_VALIDATION,
});

const submitDefinition = () => (dispatch, getState) => {
  const {
    csvImport: {entityType, fieldMappings, idFieldAttributeName},
  } = getState();

  const data = {};

  Object.assign(data, {
    entity_type: entityType,
    headers: fieldMappings.map((fm) => {
      if (fm.skip) {
        return {skip: true};
      }

      const fieldMapping = omit(fm, ['displayName', 'errors', 'skip']);
      fieldMapping['name'] = fm['attributeName'];
      delete fieldMapping['attributeName'];

      return fieldMapping;
    }),
    id_field: idFieldAttributeName,
  });

  return dispatch(reduxApi.post('entities/csv/definition', data));
};

const initiateUpload = (definitionId: string) => (dispatch, getState) => {
  const file = getState().csvImport.csv.file;
  const formData = new FormData();
  formData.append('upload', file);
  return dispatch(
    reduxApi.post(`entities/csv/upload`, formData, {
      definition_id: definitionId,
    }),
  );
};

type ProceedToReviewAction = {
  type: 'csvImport/proceedToReview',
  warnings: ?(Object[]),
};

export const proceedToReview = (): ProceedToReviewAction => ({
  type: PROCEED_TO_REVIEW,
});

export const startImport = thunkify([key(() => 'csv/startImport'), fetching()])(
  () => async (dispatch: Dispatch) => {
    try {
      const json = await dispatch(submitDefinition());
      await dispatch(initiateUpload(json.id));
      dispatch(proceedToReview());
    } catch (e) {
      const {
        response: {errors},
      } = e;
      if (errors) {
        const errorMap = new Map();
        errorMap.set('global', errors);
        dispatch(setMappingErrors(errorMap));
      }
    }
  },
);

export const validateFieldsAndStartImport = thunkify(
  key(() => 'csv/validateFieldsAndStartImport'),
  fetching(),
)(() => (dispatch: Dispatch, getState: GetState) => {
  dispatch(enableClientValidation());
  return dispatch(validateFields()).then((validationEnabled) => {
    const {
      csvImport: {errors},
    } = getState();
    if ((errors && errors.size) || !validationEnabled) {
      return Promise.resolve();
    }
    return dispatch(startImport());
  });
});

// Import history
export const PAGINATION_LIMIT = 10;

type ReceiveImportHistoryAction = {
  type: 'csvImport/receiveImportHistory',
  page: number,
  imports: Object[],
};

const receiveImportHistory = (
  page: number,
  imports: ImportHistory[],
): ReceiveImportHistoryAction => ({
  type: RECEIVE_IMPORT_HISTORY,
  page,
  imports,
});

export const getImportHistory = thunkify(
  key(() => 'csv/getImportHistory'),
  fetching(),
)((page: number = 0) => (dispatch: Dispatch) =>
  dispatch(
    reduxApi.get('entities/csv/upload/history', {
      offset: page * PAGINATION_LIMIT,
      limit: PAGINATION_LIMIT,
    }),
  ).then((response) => dispatch(receiveImportHistory(page, response))),
);

export const getMoreImportHistory = () => (
  dispatch: Dispatch,
  getState: GetState,
) => dispatch(getImportHistory(getState().csvImport.history.page + 1));
