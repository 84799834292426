// @flow strict

import type {
  Categories,
  CategoriesFilter,
  CategoryStatus,
  CategoryChannel,
  Category,
  CategoryType,
  UserContentSubscription,
  UserContentSubscriptions,
  UserContentSubscriptionsExternal,
  SubscriptionRequestData,
  ProvisionedPhoneWithUser,
  CategoryAssociatedPhoneNumbers,
  CategoryUnassociatedPhoneNumbers,
  SubscriptionRequestDataV2,
} from 'src/types/content-subscription';


export const CATEGORY_STATUS_ACTIVE: 'active' = 'active';
export const CATEGORY_STATUS_INACTIVE: 'inactive' = 'inactive';
export const CATEGORY_TYPE_MARKETING = 'marketing';
export const CATEGORY_TYPE_TRANSCATIONAL = 'transactional';
export const CATEGORY_CHANNEL_SMS = 'sms';
export const CATEGORY_CHANNEL_EMAIL = 'email';

export const defaultReasonGenerator = (agencyName: string): string =>
  `You are receiving this message because you have an active relationship with ${agencyName}`;

export const ERROR_CATEGORY_USED_BY_JOURNEY =
  'InvalidSubscriptionUpdateActiveWorkflows';

export const ERROR_CATEGORY_NAME_EXIST = 'CategoryNameTaken';
export const ERROR_DEFAULT_CATEGORY_DEACTIVATE = 'InvalidCategoryStatus';

export const applyFilters = (
  categories: Categories,
  filters: CategoriesFilter,
): Categories => {
  let result = applyStatusFilter(categories, filters.status);
  result = applySearchQuery(result, filters.searchQuery);

  // todo: debounce over filters if searchQuery is set
  return result;
};

export const applySearchQuery = (
  categories: Categories,
  searchQuery: ?string,
): Categories =>
  categories.filter((category: Category) =>
    category.name.toLowerCase().startsWith((searchQuery ?? '').toLowerCase()),
  );

export const applyStatusFilter = (
  categories: Categories,
  status: ?{[statusKey: CategoryStatus]: boolean},
): Categories =>
  status
    ? categories.filter((category) => status[category.status])
    : categories;

export const filterActiveSubscriptions = (
  subscriptions: UserContentSubscriptions,
  onlyActive: boolean,
): UserContentSubscriptions => {
  if (onlyActive) {
    return {
      email: subscriptions.email.filter(
        (subs: UserContentSubscription) =>
          subs.status === CATEGORY_STATUS_ACTIVE,
      ),
      sms: subscriptions.sms.filter(
        (subs: UserContentSubscription) =>
          subs.status === CATEGORY_STATUS_ACTIVE,
      ),
    };
  }

  return subscriptions;
};

export const parseSubscriptionToApi = (
  userContentSubscriptions: UserContentSubscriptions,
): SubscriptionRequestData => {
  let api = [];
  userContentSubscriptions.email.forEach(
    ({content_subscription_category_id, subscription}) => {
      const apiSubs = subscription
        .map((subs) => ({
          content_subscription_category_id,
          ...subs,
        }))
        .filter((sub) => sub.attribute_value);

      api = [...api, ...apiSubs];
    },
  );

  let phoneApiSubs = [];
  userContentSubscriptions.sms.forEach(
    ({content_subscription_category_id, subscription}) => {
      const apiSubs = subscription
        .map((subs) => ({
          content_subscription_category_id,
          ...subs,
        }))
        .filter((sub) => sub.attribute_value);

      phoneApiSubs = [...phoneApiSubs, ...apiSubs];
    },
  );

  api = [...api, ...phoneApiSubs];

  return {
    subscription_updates: api,
  };
};

export const parseSubscriptionExternalToApiV2 = (
  userContentSubscriptions: UserContentSubscriptionsExternal,
): SubscriptionRequestDataV2 => {
  let api = [];
  userContentSubscriptions.forEach(
    ({content_subscription_category_id, subscription}) => {
      const apiSubs = subscription
        .map((subs) => ({
          content_subscription_category_id,
          attribute_value: subs.attribute_value,
          is_subscribed: subs.is_subscribed,
        }))
        .filter((sub) => sub.attribute_value);

      api = [...api, ...apiSubs];
    },
  );

  return {
    subscription_updates: api,
  };
};

export const parseSubscriptionExternalToApi = (
  userContentSubscriptions: UserContentSubscriptionsExternal,
  sentEventId: string,
): SubscriptionRequestData => {
  let api = [];
  userContentSubscriptions.forEach(
    ({content_subscription_category_id, subscription}) => {
      const apiSubs = subscription
        .map((subs) => ({
          content_subscription_category_id,
          ...subs,
        }))
        .filter((sub) => sub.attribute_value);

      api = [...api, ...apiSubs];
    },
  );

  return {
    subscription_updates: api,
    cause_sent_event_id: sentEventId,
  };
};

export const getUnSubscribeApiDataV2 = (
  content_subscription_category_id: number,
  attributeValue: string,
): SubscriptionRequestDataV2 => ({
  subscription_updates: attributeValue
    ? [
        {
          content_subscription_category_id,
          attribute_value: attributeValue,
          is_subscribed: false,
        },
      ]
    : [],
});

export const getUnSubscribeApiData = (
  content_subscription_category_id: number,
  attribute_name: string,
  attribute_value: string,
  sentEventId: string,
): SubscriptionRequestData => ({
  subscription_updates: attribute_value
    ? [
        {
          content_subscription_category_id,
          attribute_name,
          attribute_value,
          is_subscribed: false,
        },
      ]
    : [],
  cause_sent_event_id: sentEventId,
});

export const getTitleDefaultPostfix = (
  type: CategoryType,
  channel: CategoryChannel,
  isDefault: boolean,
): string =>
  isDefault
    ? channel === CATEGORY_CHANNEL_SMS
      ? `(Default SMS)`
      : `(Default ${
          type === CATEGORY_TYPE_TRANSCATIONAL ? 'transactional' : 'marketing'
        })`
    : '';

function getStashKey({user_id, user_type, name}): string {
  return `${user_id ?? ''}-${user_type}-${name}`;
}

export const processRecordsForAssociatedPhoneNumbersTable = (
  apiRecords: Array<ProvisionedPhoneWithUser>,
): CategoryAssociatedPhoneNumbers => {
  const stash = new Map();

  apiRecords.forEach(({phone_number, users}) => {
    users.forEach(({user_id, user_type, is_default, name}) => {
      const stashKey = getStashKey({user_id, user_type, name});
      const record = stash.get(stashKey) ?? {
        userId: undefined,
        userName: '',
        phoneNumbers: new Set(),
        defaultNumber: undefined,
      };

      record.phoneNumbers.add(phone_number);
      record.userName = name;
      record.userId = user_id;

      if (is_default) {
        record.defaultNumber = phone_number;
      }

      stash.set(stashKey, record);
    });
  });

  return [...stash.values()];
};

export const processRecordsForUnassociatedPhoneNumbersTable = (
  apiRecords: Array<ProvisionedPhoneWithUser>,
): CategoryUnassociatedPhoneNumbers =>
  // keeping it this way for debugging.
  // Remove return statement before commiting
  apiRecords.map(({phone_number, users, friendly_name}) => ({
    phoneNumber: phone_number,
    phoneNumberName: friendly_name,
    users: users.map(({name}) => name).join(', '),
  }));

export const getAgencyNumbers = (
  apiRecords: Array<ProvisionedPhoneWithUser>,
): Array<string> => {
  const stash = [];

  apiRecords.forEach(({phone_number, users}) => {
    for (const user of users) {
      if (user.user_type === 'agency' || user.user_type === 'agency_variable') {
        stash.push(phone_number);
        break;
      }
    }
  });

  return stash;
};

export const getLinkToCategory = (categoryId: ?number): string =>
  categoryId ? `/settings/content-subscription/category/${categoryId}` : '';
