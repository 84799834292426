// @flow

import type {State} from 'src/reducers';
import type {PaginatedResponses} from 'src/types/report';

import pick from 'lodash/pick';

import {hashObject} from 'src/utils';
import {allowedFilters} from 'src/action-creators/event-responses';


export const getQueryHash = (query: {
  page?: number,
  time_ago?: string,
  flagged?: boolean,
  ...
}): string =>
  hashObject(
    // NOTE (kyle): page is not used to key the data in the store
    pick(
      query,
      allowedFilters.filter((key) => key !== 'page'),
    ),
  );

const initialPaginatedResponses = {
  page: 0,
  hasMore: true,
  responses: [],
  modules: {},
  events: {},
  workflows: {},
};

export const getFiltered = (
  state: State,
  query: {page?: number, time_ago?: string, flagged?: boolean, ...},
): PaginatedResponses =>
  state.eventResponses.filtered[getQueryHash(query)] ||
  initialPaginatedResponses;
