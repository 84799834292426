// @flow
import type {Reducer} from 'redux';
import type {EntityLifecycleGoal} from 'src/types/entity-lifecycle-goal';
import type {EntityLifecycleGoalAction} from 'src/action-creators/entity-lifecycle-goal';

import {RECEIVE_ENTITY_LIFECYCLE_GOAL} from 'src/action-creators/entity-lifecycle-goal';


type State = {
  values: Array<EntityLifecycleGoal>,
};

const initialState = {
  values: [],
};

const reducer: Reducer<State, EntityLifecycleGoalAction> = (
  state: State = initialState,
  action: EntityLifecycleGoalAction,
) => {
  switch (action.type) {
    case RECEIVE_ENTITY_LIFECYCLE_GOAL:
      return {
        ...state,
        values: action.payload,
      };
  }

  return state;
};

export default reducer;
