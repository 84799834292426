// @flow
import type {TitleBarDetailsModal} from 'src/action-creators/modal';

import * as React from 'react';

import {TitleBarModal} from 'src/components/modals/base-modal.jsx';

import ConfirmIcon from 'src/images/icons/confirm-icon.svg?noAttrs';

import css from 'common';


const GenericSuccessModal = (props: TitleBarDetailsModal): React.Node => (
  <TitleBarModal
    {...props}
    titleIcon={
      props.titleIcon ?? <ConfirmIcon style={{fill: css.colorWhite}} />
    }
  />
);

export default GenericSuccessModal;
