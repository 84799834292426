// @noflow
import type {
  EventCalendar,
  EventCalendarItem,
  EventCalendarRecipient,
  EventCalendarAudiencePreview,
  EventCalendarAudiencePreviews,
} from 'src/api-parsers/events';

import Store from './base';


export default class ScheduleStore extends Store {
  state: {
    eventCalendar: EventCalendar,
    audiencePreviews: EventCalendarAudiencePreviews,
  };

  constructor() {
    super('schedule');

    this.state = {
      eventCalendar: {},
      audiencePreviews: {},
    };
  }

  receiveCalendar(eventCalendar: {[dateString: string]: EventCalendarItem[]}) {
    this.setState({eventCalendar});
  }

  receiveAudiencePreviewPage(
    eventId: string,
    dateString: string,
    page: number,
    newRecipients: EventCalendarRecipient[],
  ) {
    const {audiencePreviews} = this.state;

    const preview = this.getAudiencePreview(dateString, eventId) || {
      page: 0,
      isDone: false,
      recipients: [],
    };

    this.setState({
      audiencePreviews: {
        ...audiencePreviews,
        [dateString]: {
          ...this.state.audiencePreviews[dateString],
          [eventId]: {
            page,
            isDone: !newRecipients.length,
            recipients: [...preview.recipients, ...newRecipients],
          },
        },
      },
    });
  }

  getAudiencePreview(
    dateString: string,
    eventId: string,
  ): ?EventCalendarAudiencePreview {
    const {audiencePreviews} = this.state;
    return (
      (audiencePreviews &&
        audiencePreviews[dateString] &&
        audiencePreviews[dateString][eventId]) ||
      null
    );
  }
}
