// @noflow

import type {SenseEvent} from 'src/types/events';
import type {EventsAction} from 'src/action-creators/events';

import {RECEIVE_EVENTS} from 'src/action-creators/events';


export type EventsState = {
  [eventId: string]: SenseEvent,
};

export default function reduce(state: EventsState = {}, action: EventsAction) {
  switch (action.type) {
    case RECEIVE_EVENTS: {
      state = action.payload.reduce(
        (state, event) => {
          state[event.id] = event;
          return state;
        },
        {...state},
      );
    }
  }

  return state;
}
