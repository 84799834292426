//@flow strict

import * as React from 'react';
// $FlowFixMe[nonstrict-import]
import type {TitleBarDetailsModal} from 'src/action-creators/modal';
import {
  BodyLarge,
  SubTitleLarge,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
  DIALOG_SEMANTIC,
} from '@spaced-out/ui-design-system/lib/components/Dialog';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import css from './genesis-generic-error-dialog.css';


export const GenericErrorDialogGenesis = (
  props: TitleBarDetailsModal,
): React.Node => {
  const [showExportDialog, setShowExportDialog] = React.useState(true);

  const {title, text, removeModal, confirmText} = props;

  return (
    <Dialog
      isOpen={showExportDialog}
      semantic={DIALOG_SEMANTIC.danger}
      tapOutsideToClose
      classNames={{content: css.dialogContainer}}
    >
      <DialogHeader>
        <SubTitleLarge>{title}</SubTitleLarge>
      </DialogHeader>

      <DialogBody>
        <BodyLarge color="secondary">{text}</BodyLarge>
      </DialogBody>

      <DialogFooter>
        <Button
          isFluid
          type="primary"
          onClick={() => {
            if (removeModal) {
              removeModal();
            }
            setShowExportDialog(false);
          }}
        >
          {confirmText || 'Okay'}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
