// @flow strict-local

import type {AudienceMember} from 'src/types/audience-member';


export function getPhoneNumber({tags}: AudienceMember): ?string {
  if (tags['phone number']) {
    return tags['phone number'];
  }
  for (const tagName in tags) {
    if (tagName.match(/(phone|cell|mobile)/)) {
      return tags[tagName];
    }
  }
}
