// @noflow

import type {Dimensions} from 'src/components/lib/charts/with-chart-dimensions.jsx';

import * as React from 'react';

import css from './grid.css';


type Props = {
  dimensions: Dimensions,
  yTicks?: any[],
  yScale?: Function,
  xTicks?: any[],
  xScale?: Function,
  transparentGridBands?: boolean,
};

class Grid extends React.PureComponent<Props, null> {
  render() {
    const {
      dimensions,
      yTicks,
      yScale,
      xTicks,
      xScale,
      transparentGridBands = false,
    } = this.props;
    return (
      <g transform={`translate(${dimensions.left}, ${dimensions.top})`}>
        {yTicks &&
          yScale &&
          yTicks.map((tick, i) => {
            const y = yScale(tick);
            const height =
              (i === 0 ? dimensions.bottom : yScale(yTicks[i - 1])) - y;
            return (
              <g key={tick}>
                <rect
                  fill={
                    transparentGridBands || i % 2 !== 0
                      ? 'white'
                      : css.colorGray11
                  }
                  height={height}
                  width={dimensions.innerWidth}
                  x={0}
                  y={y}
                />
                <line
                  className={css.yMark}
                  x1={0}
                  x2={dimensions.innerWidth}
                  y1={y}
                  y2={y}
                />
              </g>
            );
          })}
        {xTicks &&
          xScale &&
          xTicks.map((tick, i) => {
            if (i !== 0) {
              const x = xScale(tick);
              return (
                <line
                  key={tick}
                  className={css.xMark}
                  x1={x}
                  x2={x}
                  y1={0}
                  y2={dimensions.innerHeight}
                />
              );
            }
          })}
      </g>
    );
  }
}

export default Grid;
