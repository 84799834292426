// @flow strict

// $FlowFixMe[nonstrict-import]
import type {ModalComponent} from 'src/types/modal';
// $FlowFixMe[nonstrict-import]
import type {ModalType} from 'src/components/modals/modal-root.jsx';

import * as React from 'react';
import {useDispatch} from 'react-redux';

import {useLocation} from 'src/rerouter/hooks.js';
// $FlowFixMe[nonstrict-import]
import {pushModal, popModal} from 'src/action-creators/modal';
import {AnalyticsService} from 'src/analytics';

//$FlowFixMe[nonstrict-import]
import BaseModal from 'src/components/modals/base-modal.jsx';
// $FlowFixMe[untyped-import]
import RadioButtons from 'src/components/lib/radio-buttons/radio-buttons.jsx';

import css from './feedback.css';


export function Feedback({
  name,
  type,
}: {
  name: string,
  type: ModalType | ModalComponent,
}): React.Node {
  const dispatch = useDispatch();

  const handleFeedbackModal = () => {
    dispatch(
      pushModal({
        type: 'FEEDBACK_MODAL',
        name,
      }),
    );
  };

  return (
    <div className={css.container} onClick={handleFeedbackModal}>
      feedback
    </div>
  );
}

export default function FeedBackContainer({
  name,
  type,
}: {
  name: string,
  type: ModalType | ModalComponent,
}): React.Node {
  const dispatch = useDispatch();
  const location = useLocation();

  const [selected, setSelected] = React.useState('');
  const [textArea, setTextArea] = React.useState('');

  const Options = [
    {
      value: 'helpful',
      label: 'This is helpful',
    },
    {
      value: 'clarity',
      label: 'Need clarity on the numbers shown on nodes',
    },
    {
      value: 'others',
      label: 'Others',
    },
  ];

  const handleSuccess = () => {
    if (!selected) {
      return;
    }
    AnalyticsService.track('feedback', {
      url: location.href,
      name,
      comment: textArea,
      option_selected: Options.filter((item) => item.value === selected)[0]
        .label,
    });
  };

  const handleTimeTypeChange = (selected: string) => {
    setSelected(selected);
  };

  return (
    <BaseModal
      type={type}
      confirmText="Submit"
      abortText="Cancel"
      removeModal={() => dispatch(popModal())}
      handleConfirm={handleSuccess}
      className={css.baseModal}
    >
      <div className={css.modalContainer}>
        <div className={css.modalHeader}>What do you think ?</div>
        <div className={css.modalBody}>
          <RadioButtons
            name="feedback"
            selectedValue={selected}
            onChange={handleTimeTypeChange}
            options={Options}
            bodyClassName={css.radioButtons}
          />
        </div>

        <textarea
          value={textArea}
          placeholder={`Share your suggestions or comments`}
          onChange={(e) => setTextArea(e.target.value)}
          className={css.textArea}
        />
      </div>
    </BaseModal>
  );
}
