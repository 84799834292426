// @flow

// $FlowFixMe[untyped-type-import]
import type {DueEvent} from 'src/api-parsers/events';
import type {DueEventsAction} from 'src/action-creators/due-events';

import {RECEIVE} from 'src/action-creators/due-events';


export type DueEventsState = {
  // $FlowFixMe[value-as-type] [v1.32.0]
  [dueEventId: string]: DueEvent,
};

export default (
  state: DueEventsState = Object.freeze({}),
  action: DueEventsAction,
): DueEventsState => {
  switch (action.type) {
    case RECEIVE: {
      return action.payload.reduce(
        (dueEvents, dueEvent) => {
          dueEvents[dueEvent.id] = dueEvent;
          return dueEvents;
        },
        {...state},
      );
    }
  }
  return state;
};
