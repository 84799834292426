// @flow

import type {DraftBlockType} from 'draft-js/lib/DraftBlockType';
import type {DraftHandleValue} from 'draft-js/lib/DraftHandleValue';
import type {DynamicLabelExtra as DynamicLabel} from 'src/types/dynamic-labels';
import type {EntityType} from 'src/types/ats-entities';
import type {
  SpacedOutEditorState,
  CustomButtonContextValueType,
  CustomLinkButtonContextValueType,
} from './types';
import * as React from 'react';
import {connect} from 'react-redux';

import flow from 'lodash/flow';

import {
  EditorBlock,
  Editor,
  EditorState,
  RichUtils,
  ContentBlock,
  CompositeDecorator,
  Modifier,
  AtomicBlockUtils,
  getDefaultKeyBinding,
  KeyBindingUtil,
  SelectionState,
  convertToRaw,
  // $FlowFixMe
} from 'draft-js';
import invariant from 'invariant';

import {pushModal} from 'src/action-creators/modal';
import {selectWorkflowEntityMapping} from 'src/selectors/dynamic-labels';

import {
  ExtraLinkInputArea,
  LinkDecoratorEntry,
  prepareLinkSelection,
  createLink,
  clearSurroundingEntity,
  maybeFixUrl,
  isLinkAdjacent,
} from './draft-link.js';

import {
  PlaceholderDecorator,
  getEditorStateWithPlaceholders,
} from './draft-placeholder.js';

import {ImageRTEUploadArea, SOMedia, applyNull} from './image-utils.js';
import {
  insertTextAtSelection,
  AttachmentLinkDecorator,
  SurveyLinkDecorator,
  dynamicLabelDecorator,
  addDynamicLabel,
  getMeEditorStateWithDynamicLabelsAsEntities,
} from './dynamic-text.jsx';
import {
  BlockStyleControls,
  InlineStyleControls,
  ImageControls,
  LinkControls,
  DynamicLabelControlNew,
  CenterBlockControl,
  SurveyLinkControl,
} from './editor-controls.js';
import DynamicLabelModal from './dynamic-label-modal.jsx';
import {classify} from 'src/utils';

// $FlowFixMe
import {Map} from 'immutable';

import css from './markdown-editor.css';
import 'node_modules/draft-js/dist/Draft.css';
import './draft.global.css';


export type DynamicLabels = DynamicLabel[];
export const CustomButtonContext: React.Context<CustomButtonContextValueType | null> =
  React.createContext<CustomButtonContextValueType | null>(null);
export const CustomLinkButtonContext: React.Context<CustomButtonContextValueType | null> =
  React.createContext<CustomLinkButtonContextValueType | null>(null);
type AttachmentLinkMeta = {
  pattern: string,
  label: string,
};

type SpacedOutEditorProps = {
  dynamicLabels: DynamicLabels,
  onChange: (editorState: EditorState, onMount?: boolean) => mixed,
  onPlaceholderReplace: () => void,
  value: EditorState,
  hasFormatting: boolean,
  maxCharacterLimit?: number,
  placeholder: string,
  readOnly: boolean,
  handleImageUpload?: (files: FileList) => Promise<{url: string}>,
  showErrors?: boolean,
  showAddAttachmentLink?: AttachmentLinkMeta,
  linkTarget?: string,
  mainEntityType: EntityType,
  openMultiEntityModal: (
    dynamicLabels: DynamicLabels,
    insertResult: () => void,
    baseEntityType: EntityType,
    query?: string,
  ) => void,
  CustomButton: ({...}) => React.Node,
  CustomLinkButton: ({...}) => React.Node,
  showDynamicVariableButton?: boolean,
  containerClassName?: string,
  className?: string,
};

export const buildDecorator = (
  props: $ReadOnly<{
    dynamicLabels?: DynamicLabels,
    showAddAttachmentLink?: AttachmentLinkMeta,
    onPlaceholderReplace: () => void,
    linkTarget?: string,
    jobDynamicLabels?: DynamicLabels,
    ...
  }>,
): CompositeDecorator => {
  const {linkTarget} = props;

  let decorators = [
    LinkDecoratorEntry(linkTarget),
    dynamicLabelDecorator(props.dynamicLabels),
    PlaceholderDecorator(props.onPlaceholderReplace),
  ];

  if (props.jobDynamicLabels && props.jobDynamicLabels.length) {
    decorators = [...decorators, dynamicLabelDecorator(props.jobDynamicLabels)];
  }

  if (props.showAddAttachmentLink) {
    decorators = [
      ...decorators,
      AttachmentLinkDecorator(props.showAddAttachmentLink.pattern),
    ];
  }

  return new CompositeDecorator(decorators);
};

const {hasCommandModifier} = KeyBindingUtil;

// In order to support Centering and any future per-block attributes, SOContentBlock
// wraps all non-atomic blocks and then creates an EditorBlock for them.
// The effect is that SOContentBlock then applies data-specific classes as parents
// for EditorBlocks (the default way of displaying every block)
// i.e. in the centering case, if alignment is set, then the EditorBlock is wrapped
// in <div className=`block-align-${alignment}`></div>
// take a look at the implementation of

type SOContentBlockProps = React.ElementProps<typeof EditorBlock> & {
  // This is a refinement specific to our usecase
  blockProps?: {
    alignment?: string,
  },
  ...
};

class SOContentBlock extends React.PureComponent<SOContentBlockProps> {
  render(): React.Node {
    const {blockProps = {}, ...rest} = this.props;
    const {alignment = ''} = blockProps;

    return (
      <div className={classify({[`block-align-${alignment}`]: alignment})}>
        <EditorBlock {...rest} />
      </div>
    );
  }
}

export class SpacedOutEditor extends React.Component<
  SpacedOutEditorProps,
  SpacedOutEditorState,
> {
  static defaultProps: {
    dynamicLabels: Array<any>,
    hasFormatting: boolean,
    onChange: (editorState: any, onMount?: boolean, force?: boolean) => void,
    placeholder: string,
    readOnly: boolean,
  } = {
    readOnly: false,
    dynamicLabels: [],
    placeholder: '',
    hasFormatting: true,
    // eslint-disable-next-line
    onChange: (
      editorState: EditorState,
      onMount?: boolean = false,
      force?: boolean,
    ) => {},
  };

  compositeDecorator: any;
  editor: ?Editor;

  focus: () => void;
  onChange: (value: EditorState, callback?: () => any, force?: boolean) => void;
  soBlockRenderer: (block: any) => ?any;
  handleDrop: () => DraftHandleValue;
  handleKeyCommand: (
    command: string,
    editorState: EditorState,
  ) => DraftHandleValue;

  toggleBlockType: (blockType: DraftBlockType) => void;
  toggleInlineStyle: (inlineStyle: string) => void;

  toggleLink: () => void;
  makeLink: (data: {url: string, text: string}) => void;
  removeLink: () => void;

  toggleDynamicMenu: () => void;

  insertAttachmentLink: (evt: Event) => void;

  insertImage: (response: {url: string}) => void;
  removeImage: (block: ContentBlock) => void;
  setImageLink: (key: string) => void;
  toggleImage: () => void;
  assignUrl: (data: {url: string, entityKey: string}) => void;

  toggleCentered: (block?: ContentBlock) => void;

  constructor(props: SpacedOutEditorProps) {
    super(props);

    this.compositeDecorator = buildDecorator(props);

    this.state = {
      // TODO (kyle): only do this for multi-entity
      editorState: flow([
        getMeEditorStateWithDynamicLabelsAsEntities,
        getEditorStateWithPlaceholders,
      ])(props.value, this.props.dynamicLabels),
      showLink: false,
      dynamicMenuVisible: false,
      showImageSrc: false,
      linkModalEntityKey: null,
      inlineEditing: false,
    };

    this.focus = () => (this.editor ? this.editor.focus() : undefined);

    // $FlowFixMe[method-unbinding]
    this.onChange = this._onChange.bind(this);
    // $FlowFixMe[method-unbinding]
    this.soBlockRenderer = this._soBlockRenderer.bind(this);

    // $FlowFixMe[method-unbinding]
    this.handleDrop = this._handleDrop.bind(this);
    // $FlowFixMe[method-unbinding]
    this.handleKeyCommand = this._handleKeyCommand.bind(this);
    this.toggleBlockType = (type) => this._toggleBlockType(type);
    this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);

    // $FlowFixMe[method-unbinding]
    this.toggleLink = this._toggleLink.bind(this);
    // $FlowFixMe[method-unbinding]
    this.makeLink = this._makeLink.bind(this);
    // $FlowFixMe[method-unbinding]
    this.removeLink = this._removeLink.bind(this);

    // $FlowFixMe[method-unbinding]
    this.insertImage = this._insertImage.bind(this);
    // $FlowFixMe[method-unbinding]
    this.removeImage = this._removeImage.bind(this);
    // $FlowFixMe[method-unbinding]
    this.setImageLink = this._setImageLink.bind(this);
    // $FlowFixMe[method-unbinding]
    this.toggleImage = this._toggleImage.bind(this);
    // $FlowFixMe[method-unbinding]
    this.assignUrl = this._assignUrl.bind(this);

    // $FlowFixMe[method-unbinding]
    this.toggleCentered = this._toggleCentered.bind(this);
  }

  // TODO (kyle): this seems a bit slower now since it triggers a double render.
  //
  // update the editorState when props are changed (because the props
  // transfer to state for state updates to happen internal to the editor)
  componentDidUpdate(prevProps: SpacedOutEditorProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        editorState: this.props.value,
      });
    }
  }

  // eslint-disable-next-line no-empty-function
  _onChange(editorState: EditorState, callback?: Function = () => {}) {
    // Register an editor change with the parent component
    //
    // this method reuses the naming convention from the draft-js editor, but its
    // purpose is to report a change editor state to a parent component. For this
    // reason, though it's typical to update the editor `setState({editorState})`
    // in most draft-js example code, we use this approach so that a parent
    // component that persists the editor state always has access to the latest
    // value of the editor.
    //
    // In contrast, *not* using this method, results in save operations that
    // reflect an outdated editor state, effectively losing user data.
    //
    // A `callback` argument is provided to do things like focus the editor after
    // commiting a state change.

    editorState = flow([
      getMeEditorStateWithDynamicLabelsAsEntities,
      getEditorStateWithPlaceholders,
    ])(editorState, this.props.dynamicLabels);
    this.props.onChange(editorState, false);
    this.setState({editorState}, callback);
  }

  _handleDrop(): DraftHandleValue {
    // Prevent editor from trying to receive drag-and-dropped component
    return 'handled';
  }

  // TODO (kyle): not sure this is needed
  _handlePastedText(): string {
    // Translate <variables> into entities if users attempts to paste them.
    setTimeout(() => {
      this.onChange(
        flow([
          getMeEditorStateWithDynamicLabelsAsEntities,
          getEditorStateWithPlaceholders,
        ])(this.state.editorState, this.props.dynamicLabels),
      );
    }, 0);
    return 'not-handled';
  }
  // $FlowFixMe[method-unbinding]
  handlePastedText: any = this._handlePastedText.bind(this);

  _soKeyBindingFn(e: SyntheticKeyboardEvent<>): ?string {
    if (e.keyCode === 75 && hasCommandModifier(e) /* `K` key */) {
      return 'toggle-link';
    }

    if (e.keyCode >= 65 && e.keyCode <= 90 && !hasCommandModifier(e)) {
      //alphabets
      return `insert-character: ${e.key}`;
    }
    return getDefaultKeyBinding(e);
  }
  // $FlowFixMe[method-unbinding]
  soKeyBindingFn: () => string = this._soKeyBindingFn.bind(this);

  _handleKeyCommand(
    command: string,
    editorState: EditorState,
  ): DraftHandleValue {
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const startKey = selectionState.getStartKey();
    const startOffset = selectionState.getStartOffset();
    const contentBlock = contentState.getBlockForKey(startKey);
    const contentBlockString = contentBlock.getText();
    const lastChar = startOffset > 0 ? contentBlockString[startOffset - 1] : '';

    if (command.includes('insert-character')) {
      if (lastChar === '<') {
        const newSelection = SelectionState.createEmpty(startKey);
        const updatedSelection = newSelection.merge({
          anchorOffset: startOffset - 1,
          focusOffset: startOffset,
        });
        const newEditorState = EditorState.forceSelection(
          editorState,
          updatedSelection,
        );
        this.onChange(newEditorState);

        this.props.openMultiEntityModal(
          this.props.dynamicLabels,
          this.insertFromModal,
          this.props.mainEntityType,
          command.slice(-1),
        );
      } else {
        const newEditorState = insertTextAtSelection(
          command.slice(-1),
          this.state.editorState,
        );
        this.onChange(newEditorState);
      }
      return 'handled';
    }
    if (command === 'toggle-link') {
      // just opens the link window, doesn't change editor state
      // although togglelink will expand selection if it's currently
      // collapsed
      this.toggleLink();
      return 'handled';
    }
    if (this.props.hasFormatting) {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        this.onChange(newState);
        return 'handled';
      }
    }
    return 'not-handled';
  }

  _toggleBlockType(blockType: DraftBlockType) {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  _toggleInlineStyle(inlineStyle: string) {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle),
    );
  }

  _removeImage(block: ContentBlock) {
    const stateUpdate = (updatedState: EditorState) => {
      this.onChange(updatedState);
    };
    applyNull(this.state.editorState, stateUpdate, block);
  }

  _removeLink() {
    const stateUpdate = (updatedState) => {
      this.onChange(updatedState);
    };
    clearSurroundingEntity(this.state.editorState, stateUpdate);
  }

  _toggleLink() {
    // doesn't toggle the link, toggles the link modal
    const {linkDetails, editorState} = prepareLinkSelection(
      this.state.editorState,
    );
    if (editorState) {
      this.onChange(editorState);
    }
    this.setState({
      linkDetails,
      showLink: !this.state.showLink,
      showImageSrc: false,
    });
  }

  _makeLink(data: {url: string, text: string}) {
    const {url, text} = data;
    const {editorState} = this.state;

    // before closing the link modal, check to see that a
    // url was entered, if not, then remove the link from
    // the area where the cursor was.
    if (url.trim().length > 0) {
      const newEditorState = createLink(url, text, editorState);
      this.onChange(newEditorState, () => {
        setTimeout(() => this.editor && this.editor.focus(), 0);
      });
    } else {
      clearSurroundingEntity(editorState, (newState) => {
        this.onChange(newState, () => {
          setTimeout(() => this.editor && this.editor.focus(), 0);
        });
      });
    }
    this.setState({
      showLink: false,
    });
  }

  _setImageLink(entityKey: string) {
    this.setState({linkModalEntityKey: entityKey});
  }

  _toggleImage() {
    this.setState({
      showImageSrc: !this.state.showImageSrc,
      showLink: false,
    });
  }

  _assignUrl(data: {url: string}) {
    const {editorState} = this.state;
    const contentState = editorState.getCurrentContent();
    const entityKey = this.state.linkModalEntityKey;
    if (!entityKey) {
      return;
      // TODO(marcos): this should be an error but maybe need to log it? not clear.
    }

    let url;
    if (data.url.trim() === '') {
      // if the url is not set to undefined, the image will create a dumb link
      // that just redirects to the current page i.e. href=".". Setting it to
      // undefined clears the url from the entity
      url = undefined;
    } else {
      // they meant to make _some_ url, so fix it up
      url = maybeFixUrl(data.url);
    }

    const contentStateWithUpdatedLink = contentState.mergeEntityData(
      entityKey,
      {url},
    );

    this.setState({linkModalEntityKey: null});

    // apply new contentState
    // todo(marcos): i'm not sure that this is the right entity change type
    // but there doesn't seem to be any thing else for it.
    const newState = EditorState.push(
      editorState,
      contentStateWithUpdatedLink,
      'change-block-data',
    );

    // force an editor onChange here. Even though the editor state has changed
    // with the .push above, this may not persist in some cases unless we force
    // the change or force a selection change on the editor
    this.onChange(newState, undefined, true);
  }

  _insertImage(response: {url: string}) {
    const {editorState} = this.state;
    const contentState = editorState.getCurrentContent();
    const src = response.url;
    const contentStateWithEntity = contentState.createEntity(
      'image',
      'IMMUTABLE',
      {src},
    );
    const entityKey = contentState.getLastCreatedEntityKey();
    const updatedEditorState = EditorState.push(
      editorState,
      contentStateWithEntity,
      'change-block-data',
    );
    const newState = AtomicBlockUtils.insertAtomicBlock(
      updatedEditorState,
      entityKey,
      ' ',
    );

    this.setState({
      showImageSrc: false,
    });
    this.onChange(newState);
  }

  _insertDynamicLabel(dynamicLabel: DynamicLabel, evt: ?Event) {
    if (evt) {
      evt.preventDefault();
    }

    this.setState({
      dynamicMenuVisible: false,
    });

    const newEditorState = addDynamicLabel(
      this.state.editorState,
      this.state.editorState.getSelection(),
      dynamicLabel,
    );

    this.onChange(newEditorState);
  }
  // $FlowFixMe[method-unbinding]
  insertDynamicLabel: any = this._insertDynamicLabel.bind(this);

  //TODO(marcos): need to figure out what to do here about the above change...
  _insertFromModal(label: DynamicLabel, evt?: Event) {
    this.insertDynamicLabel(label, evt);
  }
  // $FlowFixMe[method-unbinding]
  insertFromModal: any = this._insertFromModal.bind(this);

  _insertAttachmentLink(evt: Event) {
    evt.preventDefault();

    const pattern = this.props.showAddAttachmentLink?.pattern;
    invariant(pattern, 'Attempted to insert an unknown attachment link.');

    const newEditorState = insertTextAtSelection(
      pattern,
      this.state.editorState,
    );
    this.onChange(newEditorState);
  }
  // $FlowFixMe[method-unbinding]
  // $FlowFixMe[duplicate-class-member]
  insertAttachmentLink: any = this._insertAttachmentLink.bind(this);

  _toggleCentered(block?: ContentBlock) {
    const {editorState} = this.state;
    const selection = editorState.getSelection();
    const blockToCenter =
      block ||
      editorState.getCurrentContent().getBlockForKey(selection.getStartKey());
    const alignment = blockToCenter.getData().get('alignment') || undefined;

    const newAlignment = alignment ? undefined : 'center';
    const newContent = Modifier.mergeBlockData(
      editorState.getCurrentContent(),
      selection,
      // $FlowIssue Immutable typing
      Map({alignment: newAlignment}),
    );
    const updatedEditor = EditorState.push(
      editorState,
      newContent,
      'change-block-data',
    );

    this.onChange(updatedEditor);
  }

  _soBlockRenderer(block: ContentBlock):
    | null
    | {
        component: any,
        editable: boolean,
        props: {
          alignment: any,
          handleCenterAlign: (block: void | any) => void,
          handleLeftAlign: (block: void | any) => void,
          handleLink: (entityKey: string) => void,
          removeImage: (block: any) => void,
        },
      }
    | {
        component: typeof SOContentBlock,
        editable: boolean | void,
        props: {alignment: any},
      } {
    // We override the default block renderer to support centering and the
    // inline image editor for each block we get the alignment and since the
    // block is in scope for the renderer, we can also pass along a block's
    // entityKey and other associated references. If you want to get really
    // fancy, you can use that to add medium-style insert-foo behavior for each
    // block.
    const alignment = block.getData().get('alignment');

    if (block.getType() === 'atomic') {
      return {
        component: SOMedia,
        editable: false,
        props: {
          removeImage: (block: ContentBlock) => {
            this.removeImage(block);
          },
          handleLink: (entityKey: string) => {
            this.setImageLink(entityKey);
          },
          handleCenterAlign: (block?: ContentBlock) => {
            this.toggleCentered(block);
          },
          handleLeftAlign: (block?: ContentBlock) => {
            this.toggleCentered(block);
          },
          alignment,
        },
      };
    }

    // By default, most text blocks are _not_ atomic/media, so we only need to make sure
    // we pass through the editor's editable property along with the alignment. The actual
    // <SOContentBlock /> knows what to do with those.
    if (alignment) {
      return {
        component: SOContentBlock,
        // we set editable to undefined because setting it `true` causes
        // IE11 to freak out and make EditorBlocks selectable and unusable
        // in the rte.
        editable: this.props.readOnly === true ? false : undefined,
        props: {
          alignment,
        },
      };
    }

    // use default here
    return null;
  }
  render(): React.Element<'div'> {
    const {editorState, linkModalEntityKey} = this.state;
    const {
      readOnly,
      showErrors,
      hasFormatting,
      handleImageUpload,
      CustomButton,
      CustomLinkButton,
      showDynamicVariableButton = true,
    } = this.props;
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    // TODO(marcos): use classify here
    let className = readOnly ? '' : 'RichEditor-editor';
    className = classify(className, this.props.className);
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (
        readOnly ||
        contentState.getBlockMap().first().getType() !== 'unstyled'
      ) {
        className += ' RichEditor-hidePlaceholder';
      }
    }

    const selection = editorState.getSelection();
    const currentBlock = contentState.getBlockForKey(selection.getStartKey());
    const blockType = currentBlock.getType();
    const alignment = currentBlock.getData().get('alignment');
    const linkAdjacent = isLinkAdjacent(editorState);
    const currentStyle = editorState.getCurrentInlineStyle();

    let linkEntityData;
    if (linkModalEntityKey) {
      linkEntityData = contentState.getEntity(linkModalEntityKey).getData();
    }

    const checkMaxCharacterLimitExceeded = () => {
      if (this.props.maxCharacterLimit) {
        return (
          Number(contentState.getPlainText('').length) >
          Number(this.props.maxCharacterLimit)
        );
      }
      return false;
    };

    return (
      <div
        className={classify(
          {'RichEditor-root': !readOnly},
          {
            [css.emptyInputError]:
              showErrors &&
              (!contentState.hasText() || checkMaxCharacterLimitExceeded()),
          },
          this.props.containerClassName,
        )}
      >
        {this.state.dynamicMenuVisible && (
          <DynamicLabelModal
            dynamicLabels={this.props.dynamicLabels}
            insertDynamicLabel={this.insertDynamicLabel}
            handleClose={() => this.setState({dynamicMenuVisible: false})}
          />
        )}
        {!readOnly && (
          <div>
            {this.state.showImageSrc && (
              <ImageRTEUploadArea
                handleClose={() => this.setState({showImageSrc: false})}
                handleFormSubmit={(files) => {
                  if (handleImageUpload && files.length > 0) {
                    handleImageUpload(files).then((result) => {
                      this.insertImage(result);
                    });
                  }
                }}
              />
            )}

            {this.state.showLink && (
              <ExtraLinkInputArea
                data={this.state.linkDetails}
                fields={[
                  {
                    label: 'link destination',
                    placeholder: 'e.g. http://example.com/',
                    key: 'url',
                  },
                  {
                    label: 'link text',
                    placeholder: 'e.g. an example',
                    key: 'text',
                  },
                ]}
                handleClose={() => this.setState({showLink: false})}
                confirmLink={this.makeLink}
              />
            )}

            {linkEntityData && (
              <ExtraLinkInputArea
                data={linkEntityData}
                fields={[
                  {
                    label: 'image link destination',
                    placeholder: 'e.g. http://example.com/',
                    key: 'url',
                  },
                ]}
                handleClose={() => this.setState({linkModalEntityKey: null})}
                confirmLink={this.assignUrl}
              />
            )}
          </div>
        )}
        <div
          className={className}
          onClick={this.focus}
          data-qa-id="notification-editor"
        >
          <Editor
            blockStyleFn={getBlockStyle}
            blockRendererFn={this.soBlockRenderer}
            customStyleMap={styleMap}
            editorState={editorState}
            keyBindingFn={this.soKeyBindingFn}
            handleDrop={this.handleDrop}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            placeholder={this.props.placeholder}
            ref={(el) => (this.editor = el)}
            spellCheck={true}
            stripPastedStyles={!hasFormatting}
            //handlePastedText={this.handlePastedText}
          />
        </div>

        {!readOnly && (
          <div className={css.editorControlBox}>
            {hasFormatting && (
              <div>
                <InlineStyleControls
                  currentStyle={currentStyle}
                  onToggle={this.toggleInlineStyle}
                />

                <Separator />
                <CenterBlockControl
                  alignment={alignment}
                  onToggle={this.toggleCentered}
                />

                <Separator />
                {CustomLinkButton ? (
                  <CustomLinkButtonContext.Provider
                    value={{
                      insertFromModal: this.insertFromModal,
                      ...this.state,
                    }}
                  >
                    <CustomLinkButton />
                  </CustomLinkButtonContext.Provider>
                ) : (
                  <LinkControls
                    linkAdjacent={linkAdjacent}
                    showLink={this.state.showLink}
                    toggleLink={this.toggleLink}
                  />
                )}

                <Separator />
                <BlockStyleControls
                  blockType={blockType}
                  onToggle={this.toggleBlockType}
                />
              </div>
            )}

            {handleImageUpload && (
              <div>
                <Separator />
                <ImageControls
                  toggleImage={this.toggleImage}
                  imageActive={this.state.showImageSrc}
                />
              </div>
            )}

            {this.props.showAddAttachmentLink && (
              <div className={css.leftExpander}>
                <Separator />
                <SurveyLinkControl
                  onClick={this.insertAttachmentLink}
                  text={this.props.showAddAttachmentLink.label}
                />
              </div>
            )}
            {CustomButton ? (
              <>
                <Separator />
                <CustomButtonContext.Provider
                  value={{
                    insertFromModal: this.insertFromModal,
                    ...this.state,
                  }}
                >
                  <CustomButton />
                </CustomButtonContext.Provider>
              </>
            ) : (
              <>
                <Spacer />
              </>
            )}
            {showDynamicVariableButton &&
            this.props.dynamicLabels.length > 0 ? (
              <div
                className={classify({
                  [css.leftExpander]:
                    !this.props.showAddAttachmentLink &&
                    !hasFormatting &&
                    !handleImageUpload,
                })}
              >
                <Separator />
                <DynamicLabelControlNew
                  labels={this.props.dynamicLabels}
                  showDynamicMenu={() =>
                    this.setState({dynamicMenuVisible: true})
                  }
                  insertResult={this.insertFromModal}
                  dynamicMenuVisible={this.state.dynamicMenuVisible}
                />
              </div>
            ) : (
              showDynamicVariableButton && <Spacer />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mainEntityType: selectWorkflowEntityMapping(state).name,
});

const mapDispatchToProps = (dispatch) => ({
  openMultiEntityModal: (
    dynamicLabels,
    insertResult,
    baseEntityType,
    query,
  ) => {
    dispatch(
      pushModal({
        type: 'VARIABLE_PICKER',
        labels: dynamicLabels,
        insertResult,
        baseEntityType,
        query,
      }),
    );
  },
});

const ConnectedEditor: any = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpacedOutEditor);
export default ConnectedEditor;

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return block.getType();
  }
}

export function Separator(): React.Node {
  return <div className={css.separator} />;
}

export const Spacer = (): React.Node => <div className={css.spacer} />;
