var React = require('react');

function ArrowRight (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M.903 9L5 4.887.903 1.076","strokeWidth":"2","fillRule":"evenodd"}));
}

ArrowRight.defaultProps = {"width":"7","height":"10","viewBox":"0 0 7 10"};

module.exports = ArrowRight;

ArrowRight.default = ArrowRight;
