// @flow strict
import * as React from 'react';
// $FlowFixMe
import ImageInput from '../image-input/image-input.jsx';
import {Clickable} from 'src/designSystem2021Components/text-v2.jsx';
import classify from 'src/utils/classify';
// $FlowFixMe
import {showGenericError, showApiError} from 'src/action-creators/modal';
import logger from 'src/utils/logger';
// $FlowFixMe
import * as api from 'src/utils/api-no-store';

import {useDispatch} from 'react-redux';
import css from './image-field.css';
import maincss from 'src/components/agency-onboard/agency-onboard.css';


export type ImageFieldProps = {
  label: string,
  sublabel?: string,
  name: string,
  imageSrc: string,
  onChange: (name: string, value: ?string) => mixed,
  boxClass?: string,
};

function uploadMedia(file: File): Promise<{
  url: string,
}> {
  const formData = new FormData();
  formData.append('upload', file);
  return (
    api
      // $FlowFixMe[class-object-subtyping]
      .post('media/upload', formData, undefined, {multipart: true})
      .then((response) => response)
      .catch((error) => {
        logger.error('index uploadMedia error:', error.stack || error);
        throw error;
      })
  );
}

export const ImageField = ({
  label,
  sublabel,
  name,
  imageSrc,
  onChange,
  boxClass,
}: ImageFieldProps): React.Node => {
  const dispatch = useDispatch();

  const [isUploading, setIsUploading] = React.useState(false);

  const handleImageChange = async (event) => {
    onChange(name, null);

    const file = event.target.files[0];
    let error;
    if (!file) {
      error = 'No file provided.';
    } else if (file.size > 8 * 1024 * 1024) {
      error = 'File size too large. Limit is 8MB';
    }

    if (error) {
      return dispatch(
        showGenericError({
          title: 'Image Size Error',
          text: error,
        }),
      );
    }

    setIsUploading(true);

    try {
      const response = await uploadMedia(file);
      onChange(name, response.url);
    } catch (error) {
      let text;
      switch (error.status) {
        case 400:
          text =
            'Oops, the file you selected isn\'t valid. Please check that the file type is one of PNG, JPG, JPEG, or GIF. Also check that the file size is less than 8 MB. Then try again.';
          break;
        case 403:
          text =
            'Sorry, you don\'t have permission to change the logo. Please refresh the page and try again. If this error persists, please contact your customer support manager.';
          break;
        default:
          text =
            'Please try again in a few minutes. If the image upload fails multiple times, please contact your customer service manager.';
          break;
      }
      dispatch(showApiError(error, text));
    }

    setIsUploading(false);
  };

  return (
    <div className={classify(css.imageContainer, boxClass)}>
      <div className={css.imageHeader}>
        <Clickable className={maincss.textPrimary}>{label}</Clickable>
        {sublabel && <Clickable> {sublabel} </Clickable>}
        {imageSrc && (
          <Clickable
            onClick={() => {
              onChange(name, null);
            }}
            className={css.changeBtn}
          >
            Remove
          </Clickable>
        )}
      </div>
      <ImageInput
        src={imageSrc}
        loading={isUploading}
        onChange={handleImageChange}
        uploadHint={
          <div className={css.uploadHintText}>
            <Clickable
              className={classify(maincss.textPrimary, css.uploadHintText)}
            >
              Click here to upload an image
            </Clickable>
          </div>
        }
      />
    </div>
  );
};
