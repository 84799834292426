// @flow strict-local

import * as React from 'react';
// TODO (kyle): figure out how to code split this
// eslint-disable-next-line import/no-unresolved
import {AsYouType} from 'libphonenumber-js';

import ErrorInput from 'src/components/lib/error-input';


type Props = {
  onChange: (value: string, meta: {country: string}) => Promise<void> | void,
  value: string,
  defaultCountry?: string,
  placeholder?: string,
  disabled?: mixed,
  onEnterKeyDown?: () => mixed,
  autoFocus?: mixed,
};

export default function PhoneInput({
  defaultCountry = 'US',
  value,
  onChange,
  onEnterKeyDown,
  ...props
}: Props): React.Node {
  const inputRef = React.useRef<?HTMLInputElement>();
  const selectionIndex = React.useRef<number>(0);

  // NOTE (kyle): we always parse the value to avoid displaying
  // normalized numbers.
  const [phoneValue, displayValue] = React.useMemo(() => {
    const phoneValue = new AsYouType(defaultCountry);
    const displayValue = phoneValue.input(value || '');
    return [phoneValue, displayValue];
  }, [value, defaultCountry]);

  // NOTE (kyle): whenever the value changes, we must adjust the
  // cursor position so that it accounts for any characters that may
  // have been added.
  React.useLayoutEffect(() => {
    const target = inputRef.current;

    if (!target) {
      return;
    }

    const template = phoneValue.getTemplate();

    let displayIndex = 0;
    if (template) {
      for (let i = 0, j = 0; i < displayValue.length; i++) {
        if (template[i] === 'x') {
          j++;
        }
        if (j > selectionIndex.current) {
          displayIndex = i + 1;
          break;
        }
      }
    } else {
      displayIndex = selectionIndex.current + 1;
    }

    target.selectionStart = displayIndex;
    target.selectionEnd = displayIndex;
  }, [phoneValue, displayValue]);

  // NOTE (kyle): when the input value changes, we parse that value and then
  // resulting normalized phone number (without locale characters) to the
  // parent.
  const updateValue = React.useMemo(
    () => (value: string, target: HTMLInputElement) => {
      const {selectionStart} = target;

      const phoneValue = new AsYouType(defaultCountry);
      phoneValue.input(value);
      const newValue = phoneValue.getChars(); // normalized phone number

      // we cache the current cursor position here.
      selectionIndex.current =
        value.slice(0, selectionStart).replace(/[^+\d]/g, '').length - 1;

      onChange(newValue, phoneValue);
    },
    [onChange, selectionIndex],
  );

  const handleChange = React.useMemo(
    () =>
      ({target}: SyntheticInputEvent<HTMLInputElement>) => {
        updateValue(target.value, target);
      },
    [updateValue],
  );

  // NOTE (kyle): Backspacing is a special case because backspacing a special
  // character reflects an intent to backspace the last numeric character
  // before it. This allows a user to continuously backspace over the entire
  // string.
  const handleKeyDown = React.useMemo(
    () => (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
      const {key} = event;
      if (key === 'Backspace') {
        const {currentTarget: target} = event;
        const {value, selectionStart, selectionEnd} = target;

        if (selectionStart === selectionEnd) {
          event.preventDefault();

          const leftSide = value.slice(0, selectionStart);
          let cutPoint = 0;
          for (let i = leftSide.length - 1; i > -1; i--) {
            if (/[+\d]/.test(leftSide[i])) {
              cutPoint = i;
              break;
            }
          }
          const newValue =
            leftSide.slice(0, cutPoint) + value.slice(selectionStart);
          target.selectionStart = cutPoint;
          updateValue(newValue, target);
        }
      } else if (key === 'Enter' && onEnterKeyDown) {
        onEnterKeyDown();
      }
    },
    [updateValue],
  );

  return (
    <ErrorInput
      {...props}
      type="tel"
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      value={displayValue}
      inputRef={inputRef}
    />
  );
}
