// @flow

import type {Privilege} from 'src/action-creators/roles';

import {useSelector} from 'react-redux';

import {hasPrivilege} from 'src/utils/accounts';


export default function usePrivilege(privilege: Privilege): boolean {
  return useSelector((state) => hasPrivilege(state, privilege));
}
