// @flow

import type {
  BackgroundTask,
  BackgroundTaskStatus,
} from 'src/types/background-task';
import type {Dispatch, ThunkAction, ThunkSyncAction} from 'src/reducers';
// $FlowFixMe[untyped-type-import]
import type {AudienceMember} from 'src/api-parsers';

import {batch} from 'src/action-creators/batch';

import exenv from 'exenv';
import partial from 'lodash/partial';

import logger from 'src/utils/logger';

import {camel} from 'src/utils';
import * as reduxApi from 'src/utils/redux-api';
import {isInProgress, isCompleted} from 'src/utils/background-task';


export type {BackgroundTask};

export type BackgroundTaskStatusResponse = {
  editable: boolean,
  status: BackgroundTaskStatus,
};

export type AudienceFilterCreationTask = BackgroundTask & {
  // $FlowFixMe[value-as-type] [v1.32.0]
  previewMembers?: AudienceMember[],
  previewtotalCount?: number,
};

export type BackgroundTaskPollingStatus = {
  key: string,
  shouldPoll: boolean,
  pollId?: TimeoutID | -1,
};

export const RECEIVE: 'background-task/receive' = 'background-task/receive';
export const RECEIVE_POLLING: 'background-task/receive-polling' =
  'background-task/receive-polling';

export type ReceiveBackgroundTaskAction = {
  type: typeof RECEIVE,
  payload: BackgroundTask,
};

export type ReceiveBackgroundPollingAction = {
  type: typeof RECEIVE_POLLING,
  payload: BackgroundTaskPollingStatus,
};

export type BackgroundTaskAction =
  | ReceiveBackgroundTaskAction
  | ReceiveBackgroundPollingAction;

export const receiveBackgroundTaskStatus = (
  payload: BackgroundTask,
): ReceiveBackgroundTaskAction => ({
  type: RECEIVE,
  payload,
});

export const receiveBackgroundPollingStatus = (
  payload: BackgroundTaskPollingStatus,
): ReceiveBackgroundPollingAction => ({
  type: RECEIVE_POLLING,
  payload,
});

export const getBackgroundTaskStatus =
  (
    key: string,
    statusUrl: string,
    apiPathUrl?: string,
  ): ThunkAction<ReceiveBackgroundTaskAction> =>
  (dispatch) =>
    dispatch(
      reduxApi.get(
        statusUrl,
        {},
        {apiPath: apiPathUrl ? apiPathUrl : '/api/v1/'},
      ),
    ).then((status) =>
      dispatch(receiveBackgroundTaskStatus({key, statusUrl, ...camel(status)})),
    );

export const startBackgroundTask =
  (
    key: string,
    task: () => Promise<mixed>,
    statusUrl: string,
  ): ThunkAction<ReceiveBackgroundTaskAction> =>
  async (dispatch) => {
    await task();
    const status = await dispatch(reduxApi.get(statusUrl));
    return dispatch(
      receiveBackgroundTaskStatus({key, statusUrl, ...camel(status)}),
    );
  };

// TODO (kyle): prevent double polling after being called twice
export const startBackgroundTaskPolling =
  (
    key: string,
    completedAction?: (
      BackgroundTask & {
        shouldPoll: boolean,
        pollId: number,
      },
    ) => mixed,
    onInitialResponse?: (BackgroundTask) => mixed,
  ): ThunkSyncAction =>
  (dispatch, getState) => {
    let interval = 2000;
    let myTimer;

    const poll = (initialStart) => {
      const filterTask = getState().backgroundTask.data[key];
      const polling = getState().backgroundTask.polling[key];

      interval = Math.min(interval * 2, 30000); // Max of 30 seconds

      return dispatch(reduxApi.get(filterTask.statusUrl)).then(
        (status) => {
          let shouldPoll = initialStart || polling?.shouldPoll;
          let pollId = -1;
          const newFilterTask = {...filterTask, ...camel(status)};

          if (initialStart && onInitialResponse) {
            onInitialResponse(newFilterTask);
          }

          // NOTE (rng) - This allows us to know if we are transitioning to complete (instead of viewing an already complete task)
          if (isInProgress(filterTask) && isCompleted(newFilterTask)) {
            shouldPoll = false;
            if (completedAction) {
              completedAction({...newFilterTask, shouldPoll, pollId});
            }
            dispatch(receiveBackgroundTaskStatus(newFilterTask));
            dispatch(receiveBackgroundPollingStatus({key, shouldPoll, pollId}));
            return;
          }

          if (exenv.canUseDOM && isInProgress(newFilterTask) && shouldPoll) {
            // NOTE (rng) - This prevents multiple timers from being created for the same filter creation task
            if (polling?.pollId === -1 || polling?.pollId === myTimer) {
              pollId = setTimeout(partial(poll, false), interval);
              myTimer = pollId;
              logger.log(
                'Poll() timeout set ' +
                  String(pollId) +
                  ' with interval ' +
                  interval,
              );
            }
          }

          dispatch(receiveBackgroundTaskStatus(newFilterTask));
          dispatch(receiveBackgroundPollingStatus({key, shouldPoll, pollId}));
        },
        (_error) => {
          const shouldPoll =
            filterTask.shouldPoll !== undefined ? filterTask.shouldPoll : true;
          // TODO[Swatantra] : fix below error introduced in 0.177.0
          // $FlowFixMe[not-an-object]
          const newFilterTask = {...filterTask, ...status, shouldPoll};
          if (exenv.canUseDOM && isInProgress(newFilterTask) && shouldPoll) {
            logger.log('Poll() error timeout set');
            const pollId = setTimeout(partial(poll, false), interval);
            dispatch(receiveBackgroundTaskStatus({...newFilterTask, pollId}));
          }
        },
      );
    };

    poll(true);
  };

export const stopBackgroundTaskPolling =
  (key: string): ThunkSyncAction =>
  (dispatch, getState) =>
    dispatch(receiveBackgroundPollingStatus({key, shouldPoll: false}));
