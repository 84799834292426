// @noflow
import React from 'react';
import {Helmet} from 'react-helmet';
import {isEmpty, compact} from 'lodash';

/*
  If this component ever mounts, then when it unmounts, the browser will not go
  back to using the implicit default -- i.e., /favicon.ico -- until the cache
  is cleared, which means that if you want the favicon in the browser tab to
  revert to a default, then it must be linked explicitly via a favicon link
  element higher up in the component hierarchy (e.g., app.jsx)
*/

type HeadSetterProps = {
  title: ?string,
  faviconUrl: ?string,
  ogImageUrl?: string,
  ogDescription?: string,
};

const HeadSetter = (props: HeadSetterProps) => {
  if (isEmpty(compact(Object.values(props)))) {
    return null;
  }
  const {title, faviconUrl, ogImageUrl, ogDescription} = props;

  const attrs = {
    defer: false,
  };

  // Fully override title if set, without "| Sense"
  if (title) {
    attrs.titleTemplate = null;
  }

  return (
    <Helmet {...attrs}>
      {title && <title>{title}</title>}
      {faviconUrl && <link href={faviconUrl} rel="icon" />}
      {/* react-helmet doesn't yet support fragments so hence doubles
      https://github.com/nfl/react-helmet/issues/342 */}
      {ogDescription && (
        <meta property="og:description" content={ogDescription} />
      )}
      {ogDescription && (
        <meta name="twitter:description" content={ogDescription} />
      )}
      {title && <meta property="og:title" content={title} />}
      {ogImageUrl && <meta property="og:image" content={ogImageUrl} />}
      {ogImageUrl && <meta name="twitter:image" content={ogImageUrl} />}
    </Helmet>
  );
};

export default HeadSetter;
