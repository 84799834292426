// @flow

import type {DynamicLabelExtra} from 'src/types/dynamic-labels';
import type {EntityType} from 'src/types/ats-entities';
import type {
  WritebackAllowedAttributes,
  WritebackAllowedAttributesKeyed,
  AttributeMetaData,
} from 'src/types/writeback';

import * as React from 'react';
import {useSelector} from 'react-redux';
import keyBy from 'lodash/keyBy';

import useLegacyApi from 'src/hooks/useApi';
import {useNewAllowedAttributes} from 'src/hooks/product-flags.js';
import {formatUnsyncFieldForVariablePicker} from 'src/utils/writeback';
import {selectEntityMappingsByName} from 'src/selectors/ats-entities';


export function useWritebackAllowedAttributes(
  entityType: EntityType,
): ?WritebackAllowedAttributes {
  const query = React.useMemo(() => ({entity_type: entityType}), [entityType]);
  return useLegacyApi<WritebackAllowedAttributes>(
    entityType
      ? {
          pathname: `field-writeback/allowed-attributes`,
          query,
        }
      : null,
    {cacheTimeout: 10 * 60 * 1000},
  );
}
export default useWritebackAllowedAttributes;

// Note(nishant): We would use this API to fetch allowed attributes. useWritebackAllowedAttributes would be deprecated later.
export function useWritebackAllowedAttributesV2(
  entityType: ?EntityType,
): ?WritebackAllowedAttributes {
  const query = React.useMemo(
    () => ({entity_type: entityType || ''}),
    [entityType],
  );

  const pathName = useNewAllowedAttributes()
    ? 'shared/allowed-attributes'
    : 'field-writeback/allowed-attributes';
  return useLegacyApi<WritebackAllowedAttributes>(
    entityType
      ? {
          pathname: pathName,
          query,
        }
      : null,
    {cacheTimeout: 10 * 60 * 1000},
  );
}

export function useWritebackAllowedAttributesKeyed(
  entityType: EntityType,
): ?WritebackAllowedAttributesKeyed {
  const result = useWritebackAllowedAttributes(entityType);

  return React.useMemo(
    () =>
      result && {
        ...result,
        attribute_meta_data: keyBy(
          result.attribute_meta_data,
          'attribute_name',
        ),
      },
    [result],
  );
}

export function useWritebackAllowedAttributesKeyedV2(
  entityType: EntityType,
): ?WritebackAllowedAttributesKeyed {
  const result = useWritebackAllowedAttributesV2(entityType);

  return React.useMemo(
    () =>
      result && {
        ...result,
        attribute_meta_data: keyBy(
          result.attribute_meta_data,
          'attribute_name',
        ),
      },
    [result],
  );
}

export function useWritebackMetadata(
  entityType: EntityType,
): ?(AttributeMetaData[]) {
  return useWritebackAllowedAttributes(entityType)?.attribute_meta_data;
}

export function useWritebackMetadataV2(
  entityType: EntityType,
): ?(AttributeMetaData[]) {
  return useWritebackAllowedAttributesV2(entityType)?.attribute_meta_data;
}

export function useWritebackAttributesAsOptions(
  entityType: EntityType,
  allowedAttributes: AttributeMetaData[],
): DynamicLabelExtra[] {
  const entityMappings = useSelector(selectEntityMappingsByName);
  const usesOWB = useNewAllowedAttributes();

  return React.useMemo(
    () =>
      allowedAttributes.map((attribute) =>
        formatUnsyncFieldForVariablePicker(
          attribute,
          entityType,
          entityMappings,
          usesOWB,
        ),
      ),
    [allowedAttributes, entityMappings, entityType],
  );
}
