// @flow strict

import type {AudienceListsActions} from 'src/action-creators/audience-lists';
import type {AudienceListTableEntry} from 'src/types/audience-list';

import {
  RECEIVE_LISTS,
  PUBLISH_LISTS,
  RECEIVE_LIST_UPDATE,
  REFETCH,
  RESET_LIST_PAGE_STATE,
} from 'src/action-creators/audience-lists';


export type State = {
  lists: AudienceListTableEntry[],
  total: number,
  refetch: boolean,
  //flag to communicate with list.jsx componeent
  //so that it resets its state
  resetListPageState: boolean,
  listsMap: Map<number, AudienceListTableEntry[]>,
};

const initialState = {
  lists: [],
  total: 0,
  refetch: false,
  resetListPageState: false,
  listsMap: new Map(),
};

export default (
  state: State = initialState,
  action: AudienceListsActions,
): State => {
  switch (action.type) {
    case RECEIVE_LISTS: {
      const {lists, total, pageNumber, isNewList} = action.payload;
      const tempListsMap = isNewList ? new Map() : new Map(state.listsMap);

      return {
        ...state,
        lists: action.payload.lists,
        total: action.payload.total,
        listsMap: tempListsMap.set(pageNumber, lists),
      };
    }
    case RECEIVE_LIST_UPDATE: {
      const {id, name, status} = action.payload;
      return {
        ...state,
        lists: state.lists.map((list) => {
          if (list.id === id) {
            return {...list, name, status};
          }
          return list;
        }),
      };
    }
    case PUBLISH_LISTS: {
      const publishedLists = new Set(action.payload);
      return {
        ...state,
        lists: state.lists.map((list) => {
          if (publishedLists.has(list.id)) {
            return {...list, status: 'published'};
          } else {
            return list;
          }
        }),
      };
    }
    case REFETCH: {
      return {
        ...state,
        refetch: !state.refetch,
      };
    }
    case RESET_LIST_PAGE_STATE: {
      return {
        ...state,
        resetListPageState: action.payload,
      };
    }
  }

  return state;
};
