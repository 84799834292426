// @noflow
//$FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores/index';
import type {CompositeDecorator} from 'draft-js';
import type {DynamicLabels} from 'src/components/lib/markdown-editor';
import type {BooleanSelectOption} from './constants';

import React, {Component} from 'react';
import {useDispatch} from 'react-redux';
import _get from 'lodash/get';
import {EditorState, ContentState} from 'draft-js';
import {classify} from 'src/utils';
import {
  chatLinkToken,
  surveyLinkSmsToken,
} from 'src/components/workflow/event/constants';
import {updateModule} from 'src/actions/event-creation';
import {pushModal} from 'src/action-creators/modal';
import {showJobMatchParamsModal} from 'src/action-creators/job-variables';
import {getNewEditorStateAfterReplacingPlaceholder} from './utils';
import Editor, {
  buildDecorator,
} from 'src/components/lib/markdown-editor/index2';
import {Separator} from 'src/components/lib/separator';
import {ErrorLabel} from 'src/components/lib/label/label.jsx';

import {
  getPlainText,
  getLabels,
  normalizeDynamicLabelsInString,
} from 'src/components/lib/markdown-editor/dynamic-text.jsx';
import {JobMatchButton, SMSJobBlockInfo, SMSAltMessage} from './job-match.jsx';
import {Button} from 'src/components/lib/new-button';
import {INCLUDE_JOB_BLOCK_OPTIONS, SMS_MAX_CHARACTER_LIMIT} from './constants';

import {Select} from 'src/components/lib/token-list-input/new-stuff.jsx';

import css from './module.css';


const SMS_CONTENT_WITH_JOB_DATA = `I found a job that looks like a great match for you: <matched_job/company_name> need a <matched_job/title> in <matched_job/location>. Reply if you want to learn more!`;

type JobMatchSMSWrapperProps = {
  parentEvent: ?Event,
  moduleData: {
    text: string,
    draftEditorState?: EditorState,
    alt_text: string,
    altDraftEditorState?: EditorState,
    includes_job_match_variables: boolean,
    send_alt_text: boolean,
  },
  dynamicLabels: DynamicLabels,
  showErrors: boolean,
  eventId: string,
  index: string,
  //$FlowFixMe[value-as-type]
  fluxStore: IndexStore,
  error?: {
    errors: string[],
  },
  jobBlockModuleIndex: number,
  index: number,
  jobDynamicLabels: DynamicLabels,
};

class JobMatchSMSWrapper extends Component<JobMatchSMSWrapperProps> {
  decorator: CompositeDecorator;
  initialEditorState: EditorState;
  initialAltEditorState: EditorState;
  handleModuleChange: (value: {...}) => void;
  handleAltEditorChange: (value: {...}) => void;
  constructor(props: JobMatchSMSWrapperProps) {
    super(props);
    this.decorator = this.getDecorator({
      showAddAttachmentLink: this.getAttachmentLinkMeta(),
    });

    this.initialEditorState = this.deserializeRTEContents(
      props.moduleData.text,
    );
    this.initialAltEditorState = this.deserializeRTEContents(
      props.moduleData.alt_text,
    );

    (this: any).handleEditorChange = this.handleEditorChange.bind(this);
    this.handleModuleChange = this._handleModuleChange.bind(this);
    this.handleAltEditorChange = this._handleAltEditorChange.bind(this);
  }

  getAttachmentLinkMeta() {
    return (
      this.props.parentEvent && getAttachmentLinkMeta(this.props.parentEvent)
    );
  }

  getDecorator = ({showAddAttachmentLink}) =>
    buildDecorator({
      dynamicLabels: this.props.dynamicLabels,
      jobDynamicLabels: this.props.jobDynamicLabels,
      showAddAttachmentLink,
      onPlaceholderReplace: () => {
        const reduxDispatch = this.props.fluxStore.reduxStore.dispatch;
        reduxDispatch(
          pushModal({
            type: 'VARIABLE_PICKER',
            insertResult: (dynamicLabel) => {
              const oldState = this.props.moduleData.draftEditorState;
              getNewEditorStateAfterReplacingPlaceholder(
                dynamicLabel,
                oldState,
                (value) => {
                  this.handleEditorChange(value);
                },
              );
            },
            labels: this.props.dynamicLabels,
          }),
        );
      },
    });

  componentDidUpdate(prevProps: JobMatchSMSWrapperProps) {
    if (prevProps.dynamicLabels !== this.props.dynamicLabels) {
      this.decorator = this.getDecorator({
        showAddAttachmentLink: this.getAttachmentLinkMeta(),
      });

      this.updateEditorDecorator();
    }
  }
  _handleModuleChange(updatedValue: {...}) {
    updateModule(this.props.fluxStore, updatedValue);
  }
  updateEditorDecorator() {
    const {draftEditorState} = this.props.moduleData;
    if (draftEditorState) {
      // update the current editorstate's decorators to include {survey_link} support
      const editorWithNewDecorator = EditorState.set(draftEditorState, {
        decorator: this.decorator,
      });
      this.handleEditorChange(editorWithNewDecorator);
    } else {
      // if no editor state is available, haven't called handelEditorChange once
      // so initialize it with the correct decorator list
      this.initialEditorState = this.deserializeRTEContents(
        this.props.moduleData.text,
      );
    }
  }

  deserializeRTEContents = (text: string) => {
    const content = ContentState.createFromText(text || '');
    return EditorState.createWithContent(content, this.decorator);
  };

  handleEditorChange(draftEditorState: EditorState) {
    let text = getPlainText(draftEditorState);

    text = normalizeDynamicLabelsInString(this.props.dynamicLabels, text);

    updateModule(this.props.fluxStore, {
      text,
      draftEditorState,
    });
  }
  _handleAltEditorChange(altDraftEditorState: EditorState) {
    let alt_text = getPlainText(altDraftEditorState);

    alt_text = normalizeDynamicLabelsInString(
      this.props.dynamicLabels,
      alt_text,
    );

    updateModule(this.props.fluxStore, {
      alt_text,
      altDraftEditorState,
    });
  }

  render() {
    const {
      error,
      moduleData: {
        draftEditorState,
        altDraftEditorState,
        text,
        includes_job_match_variables,
        send_alt_text,
        job_matches_count,
        job_matches_params,
        alt_text,
      },
      jobBlockModuleIndex,
      jobDynamicLabels,
      index,
      showErrors,
    } = this.props;
    const editorState = draftEditorState || this.initialEditorState;
    const altEditorState = altDraftEditorState || this.initialAltEditorState;
    const charCount = text ? text.length : 0;
    const textHasLabels =
      editorState &&
      getLabels(editorState.getCurrentContent()).filter((arr) => arr.length > 0)
        .size > 0;
    const warning = charCount > SMS_MAX_CHARACTER_LIMIT - 100;

    const errors = _get(error, 'text.errors', []);
    const altMessageErrors = _get(error, 'altText.errors', []);
    const enableJobBlockInteractions =
      jobBlockModuleIndex === -1 || jobBlockModuleIndex === index;
    return (
      <NotificationModule
        editorState={editorState}
        altEditorState={altEditorState}
        text={text}
        warning={warning}
        errors={errors}
        altMessageErrors={altMessageErrors}
        charCount={charCount}
        dynamicLabels={this.props.dynamicLabels}
        textHasLabels={textHasLabels}
        onChange={this.handleEditorChange}
        onAltEditorChange={this.handleAltEditorChange}
        attachmentLinkMeta={this.getAttachmentLinkMeta()}
        jobDynamicLabels={jobDynamicLabels}
        includesJobMatchVariables={includes_job_match_variables}
        sendAltMessage={send_alt_text}
        altText={alt_text}
        handleModuleChange={this.handleModuleChange}
        enableJobBlockInteractions={enableJobBlockInteractions}
        job_matches_count={job_matches_count}
        job_matches_params={job_matches_params}
        missingParamsError={showErrors === 'missing_job_match_params'}
      />
    );
  }
}

type NotificationModuleProps = {
  editorState: EditorState,
  altEditorState: EditorState,
  warning: boolean,
  textHasLabels: boolean,
  errors: string[],
  altMessageErrors: string[],
  dynamicLabels: DynamicLabels,
  jobDynamicLabels: DynamicLabels,
  charCount: number,
  onChange: (editorState: EditorState) => void,
  onAltEditorChange: (editorState: EditorState) => void,
  attachmentLinkMeta: ?AttachmentLinkMeta,
  includesJobMatchVariables: boolean,
  jobMatchesCount: number,
  sendAltMessage: boolean,
  handleModuleChange: (value: {...}) => void,
  altText: string,
  enableJobBlockInteractions: boolean,
  missingParamsError: boolean,
};

const NotificationModule = ({
  editorState,
  altEditorState,
  warning,
  textHasLabels,
  errors,
  altMessageErrors,
  dynamicLabels,
  jobDynamicLabels,
  charCount,
  onChange,
  onAltEditorChange,
  attachmentLinkMeta,
  includesJobMatchVariables,
  sendAltMessage,
  handleModuleChange,
  altText,
  job_matches_count,
  job_matches_params,
  enableJobBlockInteractions,
  missingParamsError,
}: NotificationModuleProps) => {
  const dispatch = useDispatch();
  const handleIncludeJobModuleChange = (
    selectedOption: ?BooleanSelectOption,
  ) => {
    if (selectedOption) {
      handleModuleChange({includes_job_match_variables: selectedOption.value});
    }
  };
  const handleAltMessageOptionChange = (
    selectedOption: ?BooleanSelectOption,
  ) => {
    if (selectedOption) {
      handleModuleChange({send_alt_text: selectedOption.value});
    }
  };
  const selectedIncludeJobBlockOption = INCLUDE_JOB_BLOCK_OPTIONS.find(
    (item) => item.value === includesJobMatchVariables,
  );

  const handleEditJobMatchButtonClick = (dynamicLabels, onParamsChange) => {
    dispatch(
      showJobMatchParamsModal({
        dynamicLabels,
        job_matches_count,
        job_matches_params,
        onParamsChange,
      }),
    );
  };

  return (
    <>
      <div className={css.notificationContainer}>
        <div className={css.generalModule_content}>
          <label className={css.sectionLabel}>
            <span className={css.smsNotification_type}>
              {includesJobMatchVariables
                ? 'Text block — for one matching job:'
                : 'Message:'}
            </span>
            <span className={css.smsNotification_message}>
              {charCount > SMS_MAX_CHARACTER_LIMIT ? (
                <span className={css.smsNotification_error_message}>
                  <strong>
                    ({SMS_MAX_CHARACTER_LIMIT - charCount} characters),{' '}
                  </strong>
                  <strong>
                    Max char limit {SMS_MAX_CHARACTER_LIMIT} reached!!
                  </strong>
                </span>
              ) : (
                <span
                  className={
                    warning ? css.smsNotification_warning_message : null
                  }
                >
                  {textHasLabels ? 'Approx. ' : ''}
                  {warning ? (
                    <span>
                      <strong>{SMS_MAX_CHARACTER_LIMIT - charCount}</strong>{' '}
                      characters remaining*, Max char limit is{' '}
                      {SMS_MAX_CHARACTER_LIMIT}
                    </span>
                  ) : (
                    <span>
                      <strong>{charCount}</strong> characters*
                    </span>
                  )}
                </span>
              )}
            </span>
          </label>

          <Editor
            className={css.smsNotificationEditor}
            value={editorState}
            hasFormatting={false}
            showErrors={
              errors.length > 0 || charCount > SMS_MAX_CHARACTER_LIMIT
            }
            dynamicLabels={dynamicLabels}
            showAddAttachmentLink={false}
            onChange={onChange}
            maxCharacterLimit={SMS_MAX_CHARACTER_LIMIT}
            //(diwakersurya)
            CustomButton={(props) => (
              <JobMatchButton {...props} dynamicLabels={jobDynamicLabels} />
            )}
          />
          {errors.length > 0 && (
            <ErrorLabel className={css.jobMatchTextError}>
              {errors[0]}
            </ErrorLabel>
          )}

          <p className={css.smsNotificationNoticeLeft}>
            *Carriers may split messages into multiple parts, and variables can
            expand to various widths .
          </p>
        </div>
        <div className={css.jobBlockInfoContainer}>
          <label className={css.sectionLabel}>
            {/* <div className={css.labelWithInlineSelector}> */}
            Include job matching?
            {/* </div> */}
          </label>
          <div className={css.jobBlockControlsSMS}>
            <div>
              <Select
                value={selectedIncludeJobBlockOption}
                classNames={{container: css.includeJobs}}
                options={INCLUDE_JOB_BLOCK_OPTIONS}
                onChange={handleIncludeJobModuleChange}
                clearable={false}
                disabled={true}
              />
            </div>
            <SMSJobBlockInfo
              includesJobMatchVariables={includesJobMatchVariables}
            />
            {includesJobMatchVariables && (
              <div>
                <Button
                  className={classify(css.buttonWhite, {
                    [css.buttonWithError]: missingParamsError,
                  })}
                  onClick={() =>
                    handleEditJobMatchButtonClick(
                      dynamicLabels,
                      (job_matches_params, selectedJobMatchCount) => {
                        handleModuleChange({
                          job_matches_params,
                          job_matches_count: selectedJobMatchCount,
                        });
                      },
                    )
                  }
                >
                  Edit job matching options
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      {enableJobBlockInteractions && (
        <>
          <Separator className={css.sectionSeparator} />
          <SMSAltMessage
            sendAltMessage={sendAltMessage}
            dynamicLabels={dynamicLabels}
            onChange={onAltEditorChange}
            editorState={altEditorState}
            errors={altMessageErrors}
            text={altText}
            showAddAttachmentLink={attachmentLinkMeta}
            onAltMessageOptionChange={handleAltMessageOptionChange}
          />
        </>
      )}
    </>
  );
};

export default JobMatchSMSWrapper;

export const chatLinkMeta = {
  pattern: chatLinkToken,
  label: 'Insert chat link',
};
export const surveyLinkMeta = {
  pattern: surveyLinkSmsToken,
  label: 'Insert survey link',
};
export function getAttachmentLinkMeta(event: Event) {
  switch (event.type) {
    case 'beefree_chatbot':
    case 'sms_chatbot':
      return chatLinkMeta;
    case 'beefree_email':
    case 'survey':
    case 'sms_survey':
    case 'sms_nps':
    case 'nps':
      return surveyLinkMeta;
  }
}
