// @flow strict-local

import * as React from 'react';

import logger from 'src/utils/logger';

import {captureSentryException} from 'src/utils/sentry';


type Props = {
  children: React.Node,
  fallback?: (Error) => React.Node,
  onError?: (Error) => mixed,
  id?: mixed,
};

type State = {
  error: ?Error,
};

export default class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    error: null,
  };

  static getDerivedStateFromError(error: Error): State {
    return {error};
  }

  componentDidCatch(error: Error, info: mixed) {
    if (this.props.onError) {
      this.props.onError(error);
    }

    logger.error('[Error Boundary]', error);
    captureSentryException(error, {
      level: 'error',
      tags: {
        context: 'Boundary',
      },
      extra: {
        info,
      },
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (this.state.error && this.props.id !== prevProps.id) {
      this.setState({error: null});
    }
  }

  render(): React.Node {
    if (this.state.error) {
      return this.props.fallback ? this.props.fallback(this.state.error) : null;
    }

    return this.props.children;
  }
}
