// @noflow

import * as React from 'react';

import css from './index.css';

import DatePicker from 'src/components/lib/date-picker/date-picker.jsx';

export default () => (
  <div className={css.guide}>
    <h1 className={css.title}>Style Guide</h1>
    <h2 className={css.subtitle}>Subtitle</h2>
    <h3 className={css.smallTitle}>Small Title</h3>

    <div className={css.box}>
      <div className={css.boxHeader}>Chart Box Header</div>
      <div className={css.boxContent}>
        <div className={css.boxLabel1}>Chart Label</div>
        <div className={css.boxLabel2}>Chart Sublabel</div>
      </div>
    </div>

    <div className={css.question}>Did you feel welcome on your first day?</div>

    <div className={css.buttons}>
      <button className={css.button}>Button</button>
      <button className={css.textButton}>
        <svg width="24" height="24" viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="12" fill="#007faf" />
          <path
            d="M 11,7 13,7 13,11 17,11 17,13 13,13 13,17 11,17 11,13 7,13 7,11 11,11 z"
            fill="white"
          />
        </svg>
        <span>Text Button</span>
      </button>
    </div>

    <div className={css.form}>
      <label className={css.formBlock}>
        <div className={css.label}>Label</div>
        <input
          type="text"
          className={css.input}
          defaultValue="Filled in value"
        />
      </label>

      <div className={css.formGrid}>
        <label className={css.formCol}>
          <div className={css.label}>Label</div>
          <input type="text" className={css.input} placeholder="Placeholder" />
        </label>
        <label className={css.formCol}>
          <div className={css.label}>Label</div>
          <input type="text" className={css.input} placeholder="Placeholder" />
        </label>
      </div>

      <div className={css.formRow}>
        <div className={css.label}>Label</div>
        <input
          type="text"
          className={css.input}
          defaultValue="Filled in value"
        />
      </div>
      <div className={css.formRow}>
        <div className={css.label}>Date Label</div>
        <DatePicker
          className={css.dateInput}
          placeholderText="Click to select"
        />
      </div>
    </div>

    <div className={css.eventModule}>
      <div className={css.boxHeader}>Chart Box Header</div>
      <div className={css.eventModuleBody} />
    </div>
  </div>
);
