// @flow

import type {Dispatch, GetState, ThunkAction} from 'src/reducers';
import type {Contact, ContactNew, ContactFormData} from 'src/types/contacts';

import invariant from 'invariant';

import {camel, snake} from 'src/utils';
import * as reduxApi from 'src/utils/redux-api-v2';
import get from 'lodash/get';

import {
  receiveContact,
  receiveContactFormErrors,
  updateContact,
} from 'src/action-creators/contacts';
import {
  // fetchMessagesForPhone,
  receiveThreadUpdate,
} from 'src/action-creators/messages';
import {selectThreadByPhone} from 'src/selectors/messages-v2';


export const createContact = (contact: ContactNew): ThunkAction<Contact> => (
  dispatch: Dispatch,
  getState: GetState,
): Promise<Contact> =>
  dispatch(reduxApi.post('messages_v2/contacts', snake(contact))).then(
    (response) => {
      const contact = camel(response);
      dispatch(receiveContact(contact));

      const phone = contact.phoneNumbers[0].value;
      const thread = selectThreadByPhone(getState(), phone);

      if (thread) {
        dispatch(
          receiveThreadUpdate(thread.id, {
            contactId: contact.id,
          }),
        );
      }

      // TODO (kyle): it's totally unnecessary to load messages after updating the contact.
      // we should instead return the
      //dispatch(fetchMessagesForPhone(contact.phoneNumbers[0].value));
      return contact;
    },
  );

export const saveContact = (
  formData: ContactFormData,
): ThunkAction<Contact> => (dispatch: Dispatch) => {
  let action;
  if (formData.id === undefined) {
    action = createContact(formData);
  } else {
    invariant(
      formData.id,
      'update contact must be called on an existing Contact',
    );
    action = updateContact(formData);
  }

  return dispatch(action).catch((error) => {
    const errorMap = {};
    if (
      get(error, 'response.errors', []).includes(
        'ContactWithPhoneAlreadyExists',
      )
    ) {
      errorMap.phones = {
        errors: ['This phone number already exists.'],
      };
    } else {
      errorMap.errors = ['There was a problem saving this contact.'];
    }

    dispatch(receiveContactFormErrors(errorMap));
    throw error;
  });
};
