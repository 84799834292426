// @noflow

import type {DynamicLabels} from '.';

import * as React from 'react';
import clamp from 'lodash/clamp';
import get from 'lodash/get';

import Modal from './modal.jsx';
import Highlighter from 'src/components/lib/highlighter';

import ConsultantIcon from 'src/images/consultant.svg';
import GlobalIcon from 'src/images/global.svg';
import PlacementIcon from 'src/images/placement.svg';

import {classify} from 'src/utils';

import css from './dynamic-label-modal.css';


type DynamicLabelModalProps = {
  dynamicLabels: DynamicLabels,
  insertDynamicLabel: (s: string, e: SyntheticEvent<*>) => mixed,
  handleClose: (e: Event) => mixed,
  mode?: 'compact' | 'rte',
};

type DynamicLabelPickerState = {
  searchValue: string,
  selectedResourceTab: ?SelectedResourceTab,
  filteredLabels: DynamicLabels,
  focused: boolean,
  focusIndex: number,
};

const variableResourceToIconMap = {
  member: ConsultantIcon,
  placement: PlacementIcon,
  global_variable: GlobalIcon,
};

const dynamicLabelResourceMap = {
  member: 'Consultant',
  placement: 'Placement',
  global_variable: 'Global',
};

type SelectedResourceTab = $Keys<typeof dynamicLabelResourceMap> | null;

const ResourceTabs = ({
  tabLabels = dynamicLabelResourceMap,
  selectedResourceTab,
  handleSelectedResourceTabChange,
  mode,
  dynamicLabels,
}: {
  tabLabels: {[key: string]: string},
  selectedResourceTab: SelectedResourceTab,
  handleSelectedResourceTabChange: (SelectedResourceTab) => void,
  mode?: 'compact' | 'rte',
  dynamicLabels: DynamicLabels,
}) => {
  const expectedResources = Object.keys(tabLabels);
  const labelSet = new Set(
    dynamicLabels.map(({resource}) =>
      expectedResources.includes(resource) ? resource : 'global_variable',
    ),
  );
  const availableTabs = expectedResources.filter((resource) =>
    labelSet.has(resource),
  );

  if (mode === 'compact' && availableTabs.length <= 1) {
    return null;
  }

  return (
    <section
      className={
        mode === 'rte' ? css.dynamicLabelsTabs : css.compactDynamicLabelsTabs
      }
    >
      <div
        className={classify(css.dynamicLabelsTab, {
          [css.activeDynamicLabelsTab]: selectedResourceTab === null,
        })}
        onClick={() => handleSelectedResourceTabChange(null)}
      >
        {mode === 'rte' ? 'All variables' : 'All'}
      </div>
      {availableTabs.map((resource) => {
        const label = get(tabLabels, resource, 'Other');
        const Icon = get(variableResourceToIconMap, resource, PlacementIcon);
        return (
          <div
            className={classify(css.dynamicLabelsTab, css[label], {
              [css.compactDynamicLabelsTab]: mode !== 'rte',
              [css.activeDynamicLabelsTab]: resource === selectedResourceTab,
            })}
            key={resource}
            onClick={() => handleSelectedResourceTabChange(resource)}
            title={label}
          >
            <Icon />
            {mode === 'rte' ? label : null}
          </div>
        );
      })}
    </section>
  );
};

type DynamicLabelListProps = {
  dynamicLabels: DynamicLabels,
  onClick: (dynamicLabel: DynamicLabel, event: SyntheticEvent<*>) => void,
  searchValue: string,
  focused?: boolean,
  focusIndex?: number,
};

export class DynamicLabelList extends React.Component<DynamicLabelListProps> {
  selectedElt: ?HTMLDivElement;

  render() {
    const {
      dynamicLabels,
      onClick,
      searchValue,
      focused,
      focusIndex,
    } = this.props;

    const allowedResources = Object.keys(dynamicLabelResourceMap);
    const safeResource = (resource) =>
      allowedResources.includes(resource) ? resource : 'global_variable';

    // TODO (rng): Eventually we want to update field mappings to honor display name, but to get it functional, this
    // is being set to attribute name
    return (
      <div className={css.dynamicLabelList}>
        {dynamicLabels.map((dynamicLabel, index) => (
          <div
            className={classify(
              css.dynamicButton,
              css[dynamicLabelResourceMap[safeResource(dynamicLabel.resource)]],
              {[css.focusedElement]: focused && index === focusIndex},
            )}
            ref={(elt) => {
              if (focusIndex === index) {
                this.selectedElt = elt;
              }
            }}
            key={dynamicLabel.value}
            onClick={(evt) => onClick(dynamicLabel, evt)}
          >
            <Highlighter search={searchValue}>{dynamicLabel.name}</Highlighter>
          </div>
        ))}
      </div>
    );
  }

  componentDidMount() {
    this.scrollToFocused();
  }

  componentDidUpdate() {
    this.scrollToFocused();
  }

  scrollToFocused = () => {
    if (
      this.props.focused &&
      this.props.focusIndex !== undefined &&
      this.props.focusIndex > -1
    ) {
      if (this.selectedElt) {
        this.selectedElt.scrollIntoView();
      }
    }
  };
}

export class DynamicLabelPicker extends React.PureComponent<
  DynamicLabelModalProps,
  DynamicLabelPickerState,
> {
  input: ?HTMLInputElement;
  focus: () => void;

  static defaultProps = {
    mode: 'rte',
    dynamicLabels: [],
  };

  constructor(props: DynamicLabelModalProps) {
    super(props);
    this.state = {
      searchValue: '',
      selectedResourceTab: null,
      filteredLabels: props.dynamicLabels,
      focused: false,
      focusIndex: -1,
    };

    this.focus = this.focus.bind(this);
  }

  componentDidMount() {
    this.focus();
    window.document.addEventListener('click', this.handleClickAway);
  }

  componentWillUnmount() {
    window.document.removeEventListener('click', this.handleClickAway);
  }

  clicked = false;

  handleClickAway = (e: MouseEvent) => {
    if (this.clicked) {
      this.clicked = false;
      return;
    }

    this.props.handleClose && this.props.handleClose(e);
  };

  handleClick = () => {
    // NOTE(elliot): Maybe think of stopping propagation here so this click event
    // does not trigger parent element's click handler.
    this.clicked = true;
  };

  handleSearchValueChange = (
    evt: SyntheticKeyboardEvent<HTMLInputElement>,
  ): void => {
    const searchValue = evt.target ? evt.currentTarget.value : '';
    this.setState({searchValue});
  };

  handleKeyDown = (
    evt: SyntheticKeyboardEvent<HTMLInputElement>,
    dynamicLabels: DynamicLabels,
  ): void => {
    const {focusIndex, searchValue} = this.state;
    if (evt.key === 'Enter' && dynamicLabels.length) {
      const selectedIndex = focusIndex > -1 ? focusIndex : 0;
      this.resetFocus();
      this.props.insertDynamicLabel(dynamicLabels[selectedIndex], evt);
    } else if (
      evt.key === 'Escape' ||
      (evt.key === 'Backspace' && searchValue === '')
    ) {
      this.resetFocus();
      this.props.handleClose(evt.nativeEvent);
    } else if (evt.key === 'ArrowDown') {
      this.moveListFocus('down');
    } else if (evt.key === 'ArrowUp') {
      this.moveListFocus('up');
    } else if (
      (evt.key === 'ArrowRight' || evt.key === 'Tab') &&
      focusIndex > -1
    ) {
      evt.preventDefault();
      this.resetFocus();
      this.setState({
        searchValue: dynamicLabels[focusIndex].name,
      });
    } else {
      this.resetFocus();
    }
  };

  getFilteredLabels = () => {
    const {selectedResourceTab, searchValue} = this.state;
    const isAllTabSelected = selectedResourceTab === null;
    return this.props.dynamicLabels.filter(({name, resource}) => {
      const labelResource =
        resource in dynamicLabelResourceMap ? resource : 'global_variable';
      return (
        name.toLowerCase().includes(searchValue.toLowerCase()) &&
        (isAllTabSelected || selectedResourceTab === labelResource)
      );
    });
  };

  resetFocus = () => {
    this.setState({focused: false, focusIndex: -1});
  };

  moveListFocus = (direction: 'up' | 'down') => {
    const filteredLabels = this.getFilteredLabels();
    const delta = direction === 'up' ? -1 : 1;
    this.setState(({focusIndex}) => ({
      focusIndex: clamp(focusIndex + delta, -1, filteredLabels.length - 1),
      focused: focusIndex + delta > -1,
    }));
  };

  handleSelectedResourceTabChange = (
    selectedResourceTab: SelectedResourceTab,
  ) => {
    this.setState({selectedResourceTab});
  };

  focus() {
    this.input && this.input.focus();
  }

  render() {
    const {searchValue, selectedResourceTab, focusIndex} = this.state;
    const filteredLabels = this.getFilteredLabels();
    const {mode, dynamicLabels} = this.props;
    const isAllTabSelected = selectedResourceTab === null;
    const hasVariablesForSelectedTab = dynamicLabels.some(({resource}) =>
      isAllTabSelected ? resource : resource === selectedResourceTab,
    );
    const searchPlaceholder =
      focusIndex > -1
        ? filteredLabels[focusIndex].name
        : `Search ${
            isAllTabSelected
              ? 'all'
              : dynamicLabelResourceMap[selectedResourceTab].toLowerCase()
          } variables`;
    return (
      <div
        onClick={this.handleClick}
        className={
          mode === 'rte'
            ? css.dynamicLabelsContainer
            : css.compactDynamicLabelsContainer
        }
      >
        {/* TODO(elliot): Move header to Modal component when we apply design changes to image and links. */}
        <header
          className={
            mode === 'rte'
              ? css.dynamicLabelsHeader
              : css.compactDynamicLabelsHeader
          }
        >
          <input
            ref={(input) => {
              this.input = input;
            }}
            value={searchValue}
            onChange={this.handleSearchValueChange}
            onKeyDown={(evt) => this.handleKeyDown(evt, filteredLabels)}
            placeholder={searchPlaceholder}
            className={
              mode === 'rte'
                ? css.dynamicLabelsSearchInput
                : css.compactDynamicLabelsSearchInput
            }
          />
          <span
            className={css.dynamicLabelsClose}
            onClick={(evt) => this.props.handleClose(evt.nativeEvent)}
          >
            Close
          </span>
        </header>
        <ResourceTabs
          dynamicLabels={dynamicLabels}
          mode={mode}
          selectedResourceTab={selectedResourceTab}
          handleSelectedResourceTabChange={this.handleSelectedResourceTabChange}
        />
        <section
          className={
            mode === 'rte '
              ? css.dynamicLabelsContent
              : css.compactDynamicLabelsContent
          }
        >
          {filteredLabels.length === 0 ? (
            <div className={css.dynamicLabelsEmptyLabel}>
              {hasVariablesForSelectedTab ? 'No results' : 'No Variables'}
            </div>
          ) : (
            <DynamicLabelList
              onClick={this.props.insertDynamicLabel}
              dynamicLabels={filteredLabels}
              searchValue={searchValue}
              focused={this.state.focused}
              focusIndex={this.state.focusIndex}
            />
          )}
        </section>
      </div>
    );
  }
}

export default class DynamicLabelModal extends React.Component<DynamicLabelModalProps> {
  render() {
    return (
      <Modal handleClose={this.props.handleClose}>
        <DynamicLabelPicker {...this.props} />
      </Modal>
    );
  }
}
