// @flow strict

import * as React from 'react';
import type {ReferralSetting} from 'src/types/referral-v2';
import {
  INITIAL_REFERRAL_SETTINGS,
  radioSelectOption,
  radioChoiceOption,
  radioTimeSheetLogOption,
} from 'src/utils/referral-v2.js';

import {SubTitleSmall} from '@spaced-out/ui-design-system/lib/components/Text';
import {SimpleDropdown} from '@spaced-out/ui-design-system/lib/components/Dropdown';
import {type MenuOption} from '@spaced-out/ui-design-system/lib/components/Menu';
import {
  Card,
  CardHeader,
  CardContent,
} from '@spaced-out/ui-design-system/lib/components/Card';
import {InContextAlert} from '@spaced-out/ui-design-system/lib/components/InContextAlert';
import PayoutRule from 'src/components/referral-v2/payout-rule/payout-rule.jsx';
import css from 'src/components/referral-v2/configure/configure.css';


const ReferralAdvanceSettings = ({
  referralSettings,
}: {
  referralSettings: ?ReferralSetting,
}): React.Node => {
  const {
    duplicate_profile_check_method,
    reward_for_existing_referral,
    should_pay_for_different_role,
  } =
    referralSettings?.one_time_settings ||
    INITIAL_REFERRAL_SETTINGS.one_time_settings;

  return (
    <Card type="primary" classNames={{wrapper: css.defaultMargin}}>
      <CardHeader>
        <SubTitleSmall>One Time Settings</SubTitleSmall>
      </CardHeader>
      <CardContent className={css.contentSection}>
        <InContextAlert
          semantic="information"
          leftIconName="circle-info"
          classNames={{wrapper: css.defaultMargin}}
        >
          One-time settings will be locked upon saving the changes. You would
          need to contact support to make any further changes.
        </InContextAlert>
        <PayoutRule disabled={true} />
        <div className={css.defaultMargin}>
          <SingleSelectDropdown
            value={duplicate_profile_check_method}
            options={radioSelectOption}
            disabled={true}
            label="What will be used as the duplicate check for new referrals in the ATS?"
          />
        </div>
        <div className={css.defaultMargin}>
          <SingleSelectDropdown
            value={reward_for_existing_referral?.toLocaleString()}
            options={radioChoiceOption}
            label="Do you want to allow referral submission if the candidate is already exists in your database?"
            disabled={true}
          />
        </div>
        <div className={css.defaultMargin}>
          <SingleSelectDropdown
            value={should_pay_for_different_role.toLocaleString()}
            options={radioChoiceOption}
            label="Is a referral eligible for payout if hired for a different job role?"
            disabled={true}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default ReferralAdvanceSettings;

type SingleSelectDropdownProps = {
  label: string,
  options?: MenuOption[],
  value?: string,
  disabled?: boolean,
  onChangeOption?: (value: string) => mixed,
};
export const SingleSelectDropdown = ({
  value,
  options = radioChoiceOption,
  label,
  disabled = false,
  onChangeOption,
}: SingleSelectDropdownProps): React.Node => {
  return (
    <div className={css.questionContainer}>
      <div className={css.radioOptions}>
        <SimpleDropdown
          classNames={{box: css.roundedSelect}}
          label={label}
          options={options}
          selectedKeys={value ? [value] : []}
          onChange={(option) => onChangeOption?.(option.key)}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
