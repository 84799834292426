// @flow strict
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  BodyLarge,
  TitleMedium,
  ButtonTextMedium,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {
  Table,
  BasicSingleCell,
} from '@spaced-out/ui-design-system/lib/components/Table';
import {CONSTANTS} from 'src/components/authentication/contants.js';
import {selectValidAgencies} from 'src/selectors/authentication';
import {mockSignIn} from 'src/action-creators/authentication.js';

import type {dynamicAgencyProps} from 'src/types/authentication.js';

import css from './dynamic-agency.css';
import maincss from 'src/components/authentication/main.css';


const DynamicAgency = (props: dynamicAgencyProps): React.Node => {
  const {creds} = props;
  const dispatch = useDispatch();
  const validAgencies = useSelector(selectValidAgencies);
  const {_a, _b, hostname} = useSelector((state) => state.env);

  const handleSignInClicked = (agency) => {
    const host = hostname.split('.').slice(1).join('.');
    dispatch(mockSignIn(creds.email, creds.password, host, agency.slug));
  };

  const agencyRow = validAgencies.map((agency) => {
    return {
      Agency: agency.name,
      Action: (
        <ButtonTextMedium
          color="clickable"
          className={css.signInBtn}
          onClick={() => handleSignInClicked(agency)}
        >
          {' '}
          Sign In{' '}
        </ButtonTextMedium>
      ),
      id: agency.name,
    };
  });

  const headers = [
    {
      key: 'Agency',
      label: 'Agency',
    },
    {
      key: 'Action',
      label: 'Action',
      render: (row) => <BasicSingleCell> {row.data.Action} </BasicSingleCell>,
    },
  ];

  return (
    <div className={css.dynamicAgencyPage}>
      <TitleMedium className={css.titleText}>
        {CONSTANTS.DYNAMIC_AGENCY_TITLE}
      </TitleMedium>
      <BodyLarge className={maincss.subText}>
        {CONSTANTS.DYNAMIC_AGENCY_SUBTEXT}
      </BodyLarge>
      <div className={css.agencyTable}>
        <Table entries={agencyRow} headers={headers} />
      </div>
    </div>
  );
};

export default DynamicAgency;
