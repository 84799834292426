// @flow strict

// $FlowFixMe[nonstrict-import] - State may never get strict typed :/
import type {State} from 'src/reducers';


export const getTimezone = ({agency, agentSettings}: State): string =>
  agentSettings.timezone || agency.agency.timezone;

// in order prefer the agency timezone,
// then the user's preferred timezone
// then the system timezone
export const getAgencyTimezone = ({agency, agentSettings}: State): string =>
  agency.agency.timezone ||
  agentSettings.timezone ||
  Intl.DateTimeFormat().resolvedOptions().timeZone;

// values in branding settings are strftime-compatible date strings, but
// we use moment, so we need to map backwards from those to moment.
// If we want to support new formats, make a corresponding change to the
// EVENT_DATE_FORMATS dict in app_server/dates/__init__.py
// TODO(marcos): this is unused and we generally don't want it, but here
// it is anyway in case we need to hard-match the server's date formatting
export const getDateFormat = ({agency}: State): string => {
  const {eventDateFormat} = agency.agency.brandingSettings;
  if (eventDateFormat) {
    const formatMappings = {
      '%Y-%m-%d': 'YYYY-MM-DD',
      '%m-%d-%Y': 'MM-DD-YYYY',
      '%m/%d/%Y': 'MM/DD/YYYY',
      '%d-%m-%y': 'DD-MM-YY',
      '%d-%m-%Y': 'DD-MM-YYYY',
      '%d/%m/%y': 'DD/MM/YY',
      '%d/%m/%Y': 'DD/MM/YYYY',
      '%m-%d-%y': 'MM-DD-YY',
      '%m/%d/%y': 'MM/DD/YY',
      '%b-%d-%Y': 'MMM-DD-YYYY',
    };
    return formatMappings[eventDateFormat];
  }
  return 'MM/DD/YYYY';
};
