// @flow strict
import * as React from 'react';

import type {TimeSheetData} from 'src/types/referral-v2';

import {useDispatch} from 'react-redux';

import FileSaver from 'file-saver';

//$FlowFixMe[untyped-import]
import {parseCsv} from 'src/utils/csv-import';
//$FlowFixMe[untyped-import]
import {progressAction} from 'src/utils/redux';
import {
  TIMESHEET_CSV_ROWS,
  sampleTimeSheetData,
} from 'src/utils/referral-v2.js';

import {
  CollapsibleCard,
  CollapsibleCardContent,
  BodySmall,
  ModalBody,
  StaticTable,
  InContextAlert,
  BodyMedium,
  FileUpload,
} from '@spaced-out/ui-design-system/lib/components/index';

import TimeSheetTableRow from './timesheet-table-row.jsx';

import css from './timesheet.css';


type TimeSheetProps = {
  setIsActionDisabled: (boolean) => mixed,
  showFieldMapping: boolean,
  csvFileData: {[string]: string}[],
  setCsvFileData: (data: {[string]: string}[]) => mixed,
  setFieldMapping: ({[string]: string}) => mixed,
  fieldMapping: {[string]: string},
  errorField: ?(string[]),
  isValidate: boolean,
  setFile: (files: ?File) => void,
  handleReset: (resetFileData?: boolean) => mixed,
  isFieldMappedError: () => boolean,
  file: ?File,
};

type FileUploadCallbacks = {
  onProgress: (number) => void,
  onSuccess: () => void,
  onError: (string) => void,
};

const tableHeaders = [
  {
    label: 'Match',
    key: 'match',
  },
  {
    label: 'Column Header',
    key: 'column_header',
  },
  {
    label: 'Header From Your File ',
    key: 'header_from_file',
    className: css.defaultPadding,
  },
  {
    label: 'Data Preview',
    key: 'data_preview',
  },
];

const TimeSheet = ({
  setIsActionDisabled,
  showFieldMapping,
  csvFileData,
  setCsvFileData,
  fieldMapping,
  setFieldMapping,
  errorField,
  isValidate,
  setFile,
  handleReset,
  isFieldMappedError,
  file,
}: TimeSheetProps): React.Node => {
  const dispatch = useDispatch();

  const entries: TimeSheetData[] = TIMESHEET_CSV_ROWS.map((row, idx) => ({
    id: idx,
    match: '',
    column_header: row.label,
    header_from_file: csvFileData.length > 0 ? Object.keys(csvFileData[0]) : [],
    data_preview: '',
    is_error: errorField?.some((error) => {
      const fieldMapped = fieldMapping[row.label];
      return error === fieldMapped;
    }),
  }));

  const handleFileUpload = async (
    file: ?File,
    callbacks: ?FileUploadCallbacks,
  ) => {
    if (!file) {
      setFile(null);
      return;
    }
    callbacks?.onProgress(40);

    const csvImportKey = 'programLinks/parseCsv';
    const results = await parseCsv(file, {
      header: true,
      transformHeader: null,
      progress: (percent) => dispatch(progressAction(csvImportKey, percent)),
    });

    callbacks?.onProgress(100);
    callbacks?.onSuccess();

    setFile(file);
    setCsvFileData(results.data);
    setIsActionDisabled(false);
  };

  const downloadSampleFile = () => {
    const csvHeaders = TIMESHEET_CSV_ROWS.map((row) => row.label);
    const rows = sampleTimeSheetData.map((obj) =>
      // $FlowIssue[prop-missing]
      csvHeaders.map((key) => obj[key]),
    );
    rows.unshift(csvHeaders);
    const csvData = rows.map((row) => row.join(',')).join('\n');

    FileSaver.saveAs(
      new Blob([csvData], {type: '.csv'}),
      `sample-timesheet.csv`,
    );
  };

  React.useEffect(() => {
    if (
      Object.keys(fieldMapping).length === TIMESHEET_CSV_ROWS.length &&
      !isValidate
    ) {
      setIsActionDisabled(false);
    }
  }, [fieldMapping]);

  if (showFieldMapping) {
    return (
      <ModalBody className={css.modalBody}>
        <BodyMedium color="secondary">
          Please choose the appropriate field below for each column in your CSV
          file.
        </BodyMedium>
        {isValidate && isFieldMappedError() && (
          <InContextAlert
            actionText="Upload New File"
            onAction={() => handleReset(true)}
            semantic="danger"
            classNames={{wrapper: css.warningCard}}
          >
            Few data missing in the imported CSV file
          </InContextAlert>
        )}
        <StaticTable
          classNames={{
            wrapper: css.timeSheetTable,
          }}
          tableHeaderClassName={css.tableHeader}
          entries={entries}
          headers={tableHeaders}
          extras={{fieldMapping, setFieldMapping, isValidate}}
          TableRow={TimeSheetTableRow}
        />
      </ModalBody>
    );
  }
  return (
    <ModalBody className={css.modalBody}>
      <CollapsibleCard isOpen={true} title="Header required for the CSV file">
        <CollapsibleCardContent>
          {TIMESHEET_CSV_ROWS.map((row) => {
            return (
              <div className={css.csvRow}>
                <BodySmall className={css.rowLabel}>{row.label}</BodySmall>
              </div>
            );
          })}
        </CollapsibleCardContent>
      </CollapsibleCard>
      <BodySmall className={css.sampleFileButton} color="tertiary">
        Download a
        <BodySmall
          onClick={downloadSampleFile}
          className={css.downloadFile}
          color="clickable"
        >
          sample file
        </BodySmall>
        and see if these are present in your CSV file for upload.
      </BodySmall>
      <div className={css.dividerLine} />
      <FileUpload
        label="CSV File Upload"
        instruction="The file size should be less than 1 MB"
        secondaryInstruction="Types: .csv  Max. size: 1mb"
        maxSize={1000000}
        accept={{'img/pdf': ['.csv']}}
        instruction={file?.name}
        onValidFilesDrop={(fileObjectArray, callbacks) => {
          handleFileUpload(fileObjectArray[0].file, callbacks);
        }}
        onRejectedFilesDrop={() => {
          handleFileUpload(null);
        }}
      />
    </ModalBody>
  );
};

export default TimeSheet;
