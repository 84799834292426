// @flow

// TODO (kyle): we cannot flowtype this file until we break `useTypeahead` up
// into a version that returns a TypeaheadAudienceMember and a string.

import type {EntityType} from 'src/types/ats-entities';

import * as React from 'react';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';

import logger from 'src/utils/logger';
import {camel, debounceAsync, emptyArray, emptyObject} from 'src/utils';
import * as api from 'src/utils/api-no-store';


export type TypeaheadType =
  | 'resource'
  | 'attribute'
  | 'entity'
  | 'entityAttribute'
  | 'eventResources'
  | 'chatbotConvPeople';

type ApiTypeaheadResponse = {
  entities: mixed[],
};

export type TypeaheadAudienceMember = {
  firstName?: string,
  fullName?: string,
  lastName?: string,
  email?: string,
  id: string,
  twilioNumber?: string,
  phone?: string,
};

export type OnSearch = (
  term: string | string[],
  onError?: (Error) => mixed | void,
  options?: {limit?: number, offset?: number} | void,
) => Promise<mixed>;

export type TypeaheadProps<T> = {
  searchResults: T[],
  clearSearchResults: () => mixed,
  onSearch: OnSearch,
};

const ttl = 2 * 60 * 1000;
const fetchDebounceTime = 300;

export default function withTypeahead<
  D,
  T: {
    typeaheadType: TypeaheadType,
    // TODO (kyle): for ME should these be called `entityType` and `attributeName`?
    typeaheadName?: string,
    typeaheadSubname?: string,
    apiPrefix?: string,
    onSearch: OnSearch,
    searchResults: Array<D>,
    clearSearchResults: () => void,
    total: number,
    dry: boolean,
    nextOffset: number,
    ...
  },
>(WrappedComponent: React.ComponentType<T>): (props: T) => React.Node {
  return function WithTypeahead(props: T) {
    const [
      searchResults,
      handleSearch,
      handleClear,
      total,
      nextOffset,
    ] = useTypeahead(
      props.typeaheadType,
      props.typeaheadName,
      props.typeaheadSubname,
      props.apiPrefix,
    );
    const dry = !searchResults || searchResults?.length >= total;
    return (
      <WrappedComponent
        {...props}
        onSearch={handleSearch}
        searchResults={searchResults || emptyArray}
        clearSearchResults={handleClear}
        total={total}
        dry={dry}
        nextOffset={nextOffset}
      />
    );
  };
}

export function useTypeahead<Result>(
  typeaheadType: TypeaheadType,
  typeaheadName?: string,
  typeaheadSubname?: string,
  apiPrefix?: string,
): [Array<Result> | void, OnSearch, () => void, number, number] {
  const [allSearchResults, setSearchResults] = React.useState<{
    [searchKey: string]: {
      fetchedAt: number,
      data: Array<Result>,
      total: number,
      nextOffset: number,
    },
  }>(emptyObject);
  const [searchKey, setSearchKey] = React.useState<string | null>(null);

  const activeRequest = React.useRef<Promise<mixed> | void>();

  const handleClear = React.useCallback(() => {
    if (searchKey) {
      setSearchResults(omit(allSearchResults, searchKey));
    }
  }, [searchKey, allSearchResults, setSearchResults]);

  const _getTypeaheadAttributeValues = React.useMemo(
    () => debounceAsync(getTypeaheadAttributeValues, fetchDebounceTime),
    [],
  );
  const _getTypeaheadEventResourcesValues = React.useMemo(
    () => debounceAsync(getTypeaheadEventResourcesValues, fetchDebounceTime),
    [],
  );

  const _getTypeaheadEventChatbotConvFlowValues = React.useMemo(
    () =>
      debounceAsync(getTypeaheadEventChatbotConvFlowValues, fetchDebounceTime),
    [],
  );

  const _getTypeaheadResourceValues = React.useMemo(
    () => debounceAsync(getTypeaheadResourceValues, fetchDebounceTime),
    [],
  );

  const _fetchEntities = React.useMemo(
    () => debounceAsync(fetchEntities, fetchDebounceTime),
    [],
  );
  const _fetchEntityAttributes = React.useMemo(
    () => debounceAsync(fetchEntityAttributes, fetchDebounceTime),
    [],
  );

  // NOTE (kyle): flow typing for useCallback does not work if the callback
  // takes in params.
  const handleSearch = React.useMemo(
    () => async (
      query: string | string[],
      onError: (Error) => mixed = (error) => logger.error(String(error)),
      {limit = 5, offset = 0}: {limit?: number, offset?: number} = {},
    ) => {
      const searchString = Array.isArray(query) ? query.join(' ') : query;
      const localSearchKey = [
        typeaheadType,
        typeaheadName,
        typeaheadSubname,
        searchString,
      ].join(',');

      let request = null;
      const previousResults = allSearchResults[localSearchKey];

      // returns cached value if there is no more data to be fetched or if the data requested is alredy fetched and is fresh

      setSearchKey(localSearchKey);

      if (
        previousResults &&
        (previousResults.total <= previousResults.data.length ||
          (previousResults.nextOffset > (offset || -1) &&
            Date.now() - previousResults.fetchedAt < ttl))
      ) {
        return;
      }

      if (typeaheadType && typeaheadName) {
        if (typeaheadType === 'attribute') {
          activeRequest.current = request = _getTypeaheadAttributeValues(
            typeaheadName,
            searchString,
            apiPrefix,
          ).then((response) => ({
            [localSearchKey]: {
              data: response.attributeValues,
              fetchedAt: Date.now(),
              total: response.total || response.attributeValues.length,
            },
          }));
        } else if (typeaheadType === 'eventResources') {
          if (typeaheadSubname) {
            activeRequest.current = request = _getTypeaheadEventResourcesValues(
              typeaheadName,
              typeaheadSubname,
              searchString,
              apiPrefix,
            ).then((response) => ({
              [localSearchKey]: {
                data: response.attributeValues,
                fetchedAt: Date.now(),
                total: response.total || response.attributeValues.length,
              },
            }));
          }
        } else if (typeaheadName === 'chatbotConvPeople') {
          activeRequest.current = request = _getTypeaheadEventChatbotConvFlowValues(
            typeaheadName,
            typeaheadSubname,
            searchString,
            apiPrefix,
          ).then((response) => ({
            [localSearchKey]: {
              data: response,
              fetchedAt: Date.now(),
              total: response.length,
            },
          }));
        } else if (typeaheadType === 'entity') {
          activeRequest.current = request = _fetchEntities(
            typeaheadName,
            searchString,
            previousResults && previousResults.nextOffset === offset
              ? offset
              : undefined,
            limit,
            apiPrefix,
          ).then((response) => ({
            [localSearchKey]: {
              data:
                previousResults && previousResults.nextOffset === offset
                  ? [...previousResults.data, ...response.entities]
                  : response.entities,
              fetchedAt: Date.now(),
              total: response.total || response.entities.length,
              nextOffset: limit + offset,
            },
          }));
        } else if (typeaheadType === 'entityAttribute') {
          if (typeaheadSubname) {
            activeRequest.current = request = _fetchEntityAttributes(
              typeaheadName,
              typeaheadSubname,
              searchString,
              apiPrefix,
            ).then((response) => ({
              [localSearchKey]: {
                data: response.entityAttributeValues,
                fetchedAt: Date.now(),
                total: response.total || response.entityAttributeValues.length,
              },
            }));
          }
        } else {
          activeRequest.current = request = _getTypeaheadResourceValues(
            typeaheadName,
            searchString,
            previousResults && previousResults.nextOffset === offset
              ? offset
              : undefined,
            limit,
            apiPrefix,
          ).then((response) => ({
            [localSearchKey]: {
              // $FlowIssue typeaheadName is not undefined
              data:
                previousResults && previousResults.nextOffset === offset
                  ? [...previousResults.data, ...response[`${typeaheadName}s`]]
                  : response[`${typeaheadName}s`],
              total: response.total,
              fetchedAt: Date.now(),
              nextOffset: limit + offset,
              // dry: response[`${typeaheadName}s`].length < response.total,
            },
          }));
        }
      }

      if (request) {
        request
          .then((searchResults) => {
            if (activeRequest.current !== request) {
              logger.log(`Cancelling stale request`, localSearchKey);
              return;
            }
            const now = Date.now();
            setSearchResults({
              ...omitBy(allSearchResults, (data) => now - data.fetchedAt > ttl),
              ...searchResults,
            });
          })
          .catch(onError);
      }

      return request;
    },
    [
      allSearchResults,
      setSearchResults,
      setSearchKey,
      _getTypeaheadResourceValues,
      _getTypeaheadEventResourcesValues,
      _getTypeaheadEventChatbotConvFlowValues,
      _getTypeaheadAttributeValues,
      _fetchEntities,
      _fetchEntityAttributes,
      typeaheadName,
      typeaheadSubname,
      typeaheadType,
    ],
  );

  const results = searchKey ? allSearchResults[searchKey] : null;

  return [
    results?.data,
    handleSearch,
    handleClear,
    results?.total || 0,
    results?.nextOffset || 0,
  ];
}

const getTypeaheadAttributeValues = (
  attribute: string,
  search_string: string,
  apiPrefix?: string,
): Promise<ApiTypeaheadResponse> =>
  api
    .get(`${apiPrefix || ''}typeahead/attributes/${attribute}`, {
      search_string,
    })
    .then(camel);

// TODO(marcos): maybe we should change this so that it looks like typeahead_paths = string[]
// and then the client/typeahead select list can just pass in paths=['invalid_event', 'attributes', 'from_address']
// and this just joins them for any arbitrary set of paths?
const getTypeaheadEventResourcesValues = (
  event_type: string,
  attribute_name: string,
  search_string: string,
  apiPrefix?: string,
): Promise<ApiTypeaheadResponse> =>
  api
    .get(
      `${
        apiPrefix || ''
      }typeahead/event_resources/${event_type}/${attribute_name}`,
      {
        search_string,
      },
    )
    .then(camel);

const getTypeaheadEventChatbotConvFlowValues = (
  event_type: string,
  candidate_name: string,
  search_string: string,
  apiPrefix?: string,
): Promise<ApiTypeaheadResponse> =>
  api
    .get(
      `${
        apiPrefix || ''
      }analytics/chatbot/meta/candidate?candidate_name=${search_string}`,
    )
    .then(camel);

const getTypeaheadResourceValues = (
  resource: string,
  search_string: string,
  offset: number = 0,
  limit: number = 5,
  apiPrefix?: string,
) =>
  api
    .get(`${apiPrefix || ''}typeahead/resources/${resource}`, {
      search_string,
      limit,
      offset,
    })
    .then(camel);

const fetchEntities = (
  entityType: EntityType,
  search_string: string,
  offset: number = 0,
  limit: number = 5,
  apiPrefix?: string,
) =>
  api
    .get(
      `${
        apiPrefix ? `${apiPrefix}typeahead` : `typeahead/entities/${entityType}`
      }`,
      {
        search_string,
        offset,
        limit,
      },
    )
    .then(camel);

const fetchEntityAttributes = (
  entityType: EntityType,
  attributeName: string,
  search_string: string,
  apiPrefix?: string,
) =>
  api
    .get(
      `${
        apiPrefix || ''
      }typeahead/entities/${entityType}/attributes/${attributeName}`,
      {
        search_string,
      },
    )
    .then(camel);
