// @flow strict-local

import * as React from 'react';
import {useLocation} from 'src/rerouter';

import ErrorBoundary from 'src/components/lib/error-boundary.jsx';

import RuntimeError from './runtime-error.jsx';


type Props = {
  children: React.Node,
  onError?: (Error) => mixed,
  id?: mixed,
};

export default function RuntimeErrorBoundary(props: Props): React.Node {
  return <ErrorBoundary {...props} fallback={() => <RuntimeError />} />;
}

export function RouterErrorBoundary(props: Props): React.Node {
  const location = useLocation();

  return <RuntimeErrorBoundary {...props} id={location.pathname} />;
}
