// @flow

// $FlowFixMe[untyped-type-import]
import type {Workflow} from 'src/api-parsers/index';
import type {ModalType} from 'src/components/modals/modal-root.jsx';

import * as React from 'react';

import FluxComponent from 'src/flux/component.jsx';
import {TitleBarModal} from 'src/components/modals/base-modal.jsx';

import {deleteCurrentEvent} from 'src/actions/event-creation';

import WarningIcon from 'src/images/icons/warning-icon.svg?noAttrs';

import css from 'src/components/modals/base-modal.css';


const mapper = ({store, workflow: {id}}) => ({
  deletingEvent: Boolean(store.request.inProgress(`deleteCurrentEvent/${id}`)),
});

class EventDeleteModal extends React.Component<{
  store: any,
  router: any,
  // $FlowFixMe[value-as-type] [v1.32.0]
  workflow: Workflow,
  eventId: string,
  removeModal: () => void,
  deletingEvent: boolean,
  type: ModalType,
  handleDelete?: () => {then: (mixed) => mixed},
}> {
  constructor(props) {
    super(props);

    // $FlowFixMe[method-unbinding]
    (this: any).handleDelete = this.handleDelete.bind(this);
  }

  render() {
    const {
      workflow: {active},
      removeModal,
      deletingEvent,
      type,
    } = this.props;

    const headingText = active
      ? 'Can\'t Delete an Active Touchpoint'
      : 'Really delete this touchpoint?';
    const explanationText = active
      ? 'Please de-activate your journey before deleting a touchpoint.'
      : 'Once you delete it, all touchpoint modules and scheduling \
        information will be lost but analytics will be retained.';

    return (
      <TitleBarModal
        type={type}
        title={headingText}
        text={explanationText}
        confirmText={active ? '' : deletingEvent ? 'Deleting...' : 'Delete it'}
        abortText={active ? 'OK' : 'Cancel'}
        // $FlowFixMe[method-unbinding]
        handleConfirm={this.handleDelete}
        handleAbort={removeModal}
        titleIcon={<WarningIcon style={{fill: css.colorWhite}} />}
      />
    );
  }

  handleDelete(event: SyntheticEvent<>) {
    event.preventDefault();

    const {
      store,
      router,
      workflow: {id},
      removeModal,
      handleDelete,
    } = this.props;

    const handleDeleteSuccess = () => {
      router.replace(`/journey/${id}`);
      removeModal();
    };

    if (handleDelete) {
      handleDelete().then(handleDeleteSuccess);
    } else {
      deleteCurrentEvent(store, id).then(handleDeleteSuccess);
    }
  }
}

export default (FluxComponent(
  EventDeleteModal,
  mapper,
): React.ComponentType<any>);
