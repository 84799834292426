// @noflow

import typeof IndexStore from 'src/stores/index';
import type {Workflow} from 'src/api-parsers/index';
import type {Branch} from 'src/types/survey';

import React, {Component} from 'react';
import find from 'lodash/find';

import logger from 'src/utils/logger';

import {createBranchEvent, updateBranchEvent} from 'src/actions/event-creation';

import FluxComponent from 'src/flux/component.jsx';
import {TitleBarModal} from 'src/components/modals/base-modal.jsx';

import css from './create-event-modal.css';


const mapper = ({store}) => ({
  workflow: find(store.workflows.state.workflows, ({eventsSchedule}) =>
    eventsSchedule.find(({id}) => id === store.eventCreation.state.event.id),
  ),
});

type Props = {
  store: IndexStore,
  router: any,
  workflow: Workflow,
  removeModal: () => void,
};

//This modal is used in Branching workflow to Create an event
class CreateEventModal extends Component<
  Props,
  {
    validName: boolean,
  },
> {
  constructor(props) {
    super(props);

    this.state = {
      validName: true,
    };

    (this: any).handleCreateEvent = this.handleCreateEvent.bind(this);
    (this: any).handleCancel = this.handleCancel.bind(this);
  }

  render() {
    return (
      <TitleBarModal
        className={css.inner}
        title={'Create new touchpoint'}
        handleAbort={this.handleCancel}
        abortText={'Cancel'}
        handleConfirm={this.handleCreateEvent}
        confirmText={'Create'}
      >
        <input
          ref={(el) => (this.input = el)}
          type="text"
          name="title"
          placeholder="Enter touchpoint name"
          className={this.state.validName ? css.input : css.invalid}
        />
      </TitleBarModal>
    );
  }

  handleCancel(event) {
    event.preventDefault();
    this.props.removeModal();
  }

  handleCreateEvent(event) {
    event.preventDefault();
    const {store, workflow} = this.props;
    const title = this.input.value.trim();

    if (workflow.active) {
      alert('Please make the journey inactive before adding touchpoints');
      return;
    }

    if (title) {
      createBranchEvent(store, title, workflow)
        .then((newEvent) => updateBranchEvent(store, newEvent.id))
        .then(this.props.removeModal)
        .catch((error) => {
          alert('Error creating the Touchpoint!!');
          logger.error(
            'Create Branch Touchpoint Error: ',
            error.stack || error,
          );
        });
    } else {
      this.setState({
        validName: false,
      });
    }
  }
}

export default FluxComponent(CreateEventModal, mapper);
