//@flow strict
import * as React from 'react';
import {useSelector} from 'react-redux';

import {useSoundEffect, type MediaSpec} from 'src/hooks/useAudio';
// $FlowFixMe[nonstrict-import]
import {useReleaseFlag} from 'src/hooks/product-flags';


type Props = {
  media: MediaSpec | MediaSpec[],
  playId: mixed,
  soundSetting: boolean,
  playOnMount?: boolean,
};

export default function ChatSoundEffect(props: Props): React.Node {
  const {soundSetting, playId, ...restProps} = props;

  const soundEffectsEnabledSetting = useSelector(
    (state) => state.agentSettings.messagingSound,
  );
  const soundAndEmailSettingsReleaseFlag = useReleaseFlag(
    'messagingSoundEmailSettingsChangeToPhoneSetAssignment',
  );

  const soundEffectsEnabled = soundAndEmailSettingsReleaseFlag
    ? soundSetting
    : soundEffectsEnabledSetting;

  useSoundEffect({
    ...restProps,
    playId,
    soundEffectsEnabled,
  });

  return null;
}
