// @flow

import type {
  Placement,
  PositionedPlacement,
  ArrowPosition,
} from 'src/types/position';
import type {ReactRef} from 'src/types/react';

import * as React from 'react';
import {last, camelCase} from 'lodash';
import invariant from 'invariant';
import {push} from 'sculpt';
import findIndex from 'lodash/findIndex';

import logger from 'src/utils/logger';
import {deprecate} from 'src/utils/index.js';

import classify from 'src/utils/classify';
import {getAnchorPosition} from 'src/utils/dom';
import throttleAnimation from 'src/utils/throttle-animation';

import css from './mouse-tip.css';


let tipComponent;

type FixedPlacement = Placement | null;
type ButtonMap = {
  [key: string]: (event: SyntheticMouseEvent<HTMLElement>) => void,
};

type BaseTipProps = {
  content: React.Node,
  fixedTo?: FixedPlacement,
  arrowPosition?: ArrowPosition,
  noArrow?: boolean,
  noStyles?: boolean,
  className?: string,
  buttons?: ButtonMap,
  id?: string | Symbol,
  hideDelay?: number,
};

type MouseTipProps<C> = {
  ...BaseTipProps,
  children: React.Element<C>,
  disable?: boolean,
  delay?: number, //milliseconds
  tipClassName?: string,
  contentClassName?: string,
};

type ComponentRef = {current: ?HTMLElement};

type RenderedTip = {
  ...BaseTipProps,
  arrowPosition: ArrowPosition,
  componentRef: ComponentRef,
  handleClose: () => void,
  tipProps?: {
    tipClassName?: string,
    contentClassName?: string,
    onMouseLeave?: (SyntheticMouseEvent<HTMLElement>) => void,
    onMouseEnter?: (SyntheticMouseEvent<HTMLElement>) => void,
  },
};

export function MouseTip<
  P: {
    onMouseEnter: (SyntheticMouseEvent<HTMLElement>) => mixed,
    onMouseLeave: (SyntheticMouseEvent<HTMLElement>) => mixed,
    ...
  },
  C:
    | React.AbstractComponent<P, HTMLElement>
    | string
    | React.StatelessFunctionalComponent<P>,
>({
  children,
  disable,
  tipClassName,
  contentClassName,
  ...props
}: MouseTipProps<C>): React.Node {
  const hoverProps = useHoverTip({
    ...props,
    tipProps: {
      tipClassName,
      contentClassName,
    },
    disabled: disable,
  });

  return React.cloneElement(children, hoverProps);
}

type BaseHookProps = {
  ...BaseTipProps,
  disabled?: boolean,
  onClose?: () => mixed,
  tipProps?: {
    tipClassName?: string,
    contentClassName?: string,
    onMouseEnter?: (SyntheticMouseEvent<HTMLElement>) => void,
    onMouseLeave?: (SyntheticMouseEvent<HTMLElement>) => void,
  },
};
export function useMouseTip(
  {
    onClose,
    disabled,
    arrowPosition = 'center',
    fixedTo,
    ...config
  }: BaseHookProps,
  deps?: mixed[],
): [ReactRef<HTMLElement>, () => void, () => void, boolean] {
  fixedTo = config.buttons ? 'top' : fixedTo;

  const componentRef = React.useRef<?HTMLElement>();
  const currentTip = React.useContext(MouseTipContext);
  const isActive = Boolean(
    currentTip && currentTip.componentRef === componentRef,
  );

  // idea here is we default to deps, but it's ~~very easy~~ to pass a
  // React.Node as content to the mousetip, but this will break the
  // effect because it is ~always changing~, so we can only use content as
  // a dep if it's a string (because value equality) otherwise we just update
  // the tip once on render
  const tipDeps =
    deps ?? React.isValidElement(config.content) ? [] : [config.content];

  // TODO(marcos): this is technically correct, but only in cases where config.content
  // does not necessarily have referrential equality across renders
  // if (React.isValidElement(config.content) && !Array.isArray(deps)) {
  //   deprecate({
  //     reason: `useMouseTip should not have a React.Node as its content without a valid deps argument`,
  //     person: 'Marcos',
  //   });
  // }

  React.useEffect(() => {
    if (tipComponent) {
      tipComponent.update(tipConfig);
    }
  }, tipDeps);

  React.useEffect(
    () => () => {
      if (tipComponent) {
        tipComponent.pop(componentRef);
      }
    },
    [],
  );

  const handleOpen = () => {
    if (!disabled) {
      invariant(
        tipComponent,
        'MouseTip: Your mouse tip will not work without a mounted MouseTipRoot.',
      );
      tipComponent.push(tipConfig);
    }
  };

  const handleClose = () => {
    invariant(
      tipComponent,
      'MouseTip: Your mouse tip will not work without a mounted MouseTipRoot.',
    );
    tipComponent.pop(componentRef);
    if (onClose) {
      onClose();
    }
  };

  const tipConfig = {
    componentRef,
    handleClose,
    arrowPosition,
    fixedTo,
    ...config,
  };

  return [componentRef, handleOpen, handleClose, isActive];
}

type HoverTipProps = {
  ...BaseHookProps,
  delay?: number,
  hideDelay?: number,
};

export function useHoverTip(
  {delay, buttons, tipProps, hideDelay, ...config}: HoverTipProps,
  deps?: mixed[],
): {
  ref: {current: ?HTMLElement},
  onMouseEnter: (SyntheticMouseEvent<HTMLElement>) => void,
  onMouseLeave: (SyntheticMouseEvent<HTMLElement>) => void,
} {
  const timeoutRef = React.useRef<?TimeoutID>();
  const clearDelay = () => clearTimeout(timeoutRef.current);

  const [componentRef, handleOpen, handleClose] = useMouseTip(
    {
      ...config,
      buttons,
      onClose: () => {
        if (timeoutRef.current) {
          clearDelay();
        }
      },
      tipProps: {
        ...tipProps,
        onMouseLeave: (event) => handleMouseLeave(event),
        onMouseEnter: () => {
          clearDelay();
          handleOpen();
        },
      },
    },
    deps,
  );

  const handleMouseEnter = () => {
    clearDelay();
    if (delay) {
      timeoutRef.current = setTimeout(handleOpen, delay);
    } else {
      handleOpen();
    }
  };

  const handleMouseLeave = (event: ?SyntheticMouseEvent<HTMLElement>) => {
    const isTarget = event && event.currentTarget === componentRef.current;
    const isGoingUp =
      event &&
      // $FlowFixMe
      event.movementY < 0 &&
      // $FlowFixMe
      Math.abs(event.movementY) > Math.abs(event.movementX);

    // Don't close tip if the cursor is headed toward buttons
    if (buttons && isTarget && isGoingUp) {
      return;
    }

    clearDelay();
    if (hideDelay) {
      timeoutRef.current = setTimeout(handleClose, hideDelay);
    } else {
      handleClose();
    }
  };

  return {
    ref: componentRef,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  };
}

export function useContextTip(
  config: BaseHookProps,
  deps?: mixed[],
): [ReactRef<HTMLElement>, (SyntheticEvent<>) => void, () => void, boolean] {
  const [componentRef, handleOpen, handleClose, isActive] = useMouseTip(
    config,
    deps,
  );

  const handleContextMenu = (event: SyntheticEvent<>) => {
    event.preventDefault();
    if (isActive) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  const handleClickOutside = (event: ?SyntheticMouseEvent<HTMLElement>) => {
    const isTarget = event && event.currentTarget === componentRef.current;
    if (!isTarget && isActive) {
      handleClose();
    }
  };

  React.useEffect(() => {
    window.document.addEventListener('click', handleClickOutside);
    return () => {
      window.document.removeEventListener('click', handleClickOutside);
    };
  });

  return [componentRef, handleContextMenu, handleClose, isActive];
}

type MouseTipRootState = {
  tips: RenderedTip[],
  show: boolean,
  placement?: Placement,
  x: number,
  y: number,
};
type MouseTipRootProps = {
  children: React.Node,
  tipProps?: {
    tipClassName?: string,
    contentClassName?: string,
    onMouseLeave?: (SyntheticMouseEvent<HTMLElement>) => void,
    onMouseEnter?: (SyntheticMouseEvent<HTMLElement>) => void,
  },
};
export class MouseTipRoot extends React.Component<
  MouseTipRootProps,
  MouseTipRootState,
> {
  initialState: {
    placement?: Placement,
    show: boolean,
    tips: Array<RenderedTip>,
    x: number,
    y: number,
  } = {
    placement: 'top',
    show: false,
    tips: [],
    x: 0,
    y: 0,
  };
  state: MouseTipRootState = this.initialState;

  pad: number = 20;
  boundary: number = 80;

  componentDidMount() {
    if (tipComponent) {
      logger.error('MouseTipRoot: Attempting to mount more than once.');
    }
    // $FlowFixMe[escaped-generic]
    tipComponent = this;
  }

  componentWillUnmount() {
    tipComponent = null;
    this.stop();
  }

  componentDidUpdate(
    _prevProps: MouseTipRootProps,
    prevState: MouseTipRootState,
  ) {
    const currentTip = this.getTopTip();
    const prevTip = last(prevState.tips);

    if (!currentTip || currentTip?.fixedTo) {
      this.stop();
    } else if (!currentTip?.fixedTo && (!prevTip || prevTip?.fixedTo)) {
      this.listen();
    }
  }

  push(config: RenderedTip) {
    this.setState(({tips}) => {
      if (config.id) {
        tips = tips.filter(({id}) => id !== config.id);
      }
      return {
        tips: push(tips, config),
        show: true,
      };
    });
  }

  pop(componentToRemove: ComponentRef) {
    this.setState(({tips}) => {
      tips = tips.filter(
        ({componentRef}) => componentRef !== componentToRemove,
      );
      return tips.length > 0 ? {tips} : this.initialState;
    });
  }

  update(config: RenderedTip) {
    const {tips} = this.state;
    if (tips.length === 0) {
      return;
    }

    const componentIndex = findIndex(
      tips,
      ({componentRef}) => componentRef === config.componentRef,
    );

    if (componentIndex >= 0) {
      const updatedTips = tips.slice();
      updatedTips[componentIndex] = config;
      this.setState({tips: updatedTips});
    }
  }

  getTopTip():
    | any
    | {
        arrowPosition: ArrowPosition,
        buttons?: ButtonMap,
        className?: string,
        componentRef: ComponentRef,
        content: React.Node,
        fixedTo?: FixedPlacement,
        handleClose: () => void,
        id?: string | Symbol,
        noArrow?: boolean,
        noStyles?: boolean,
        tipProps?: {
          contentClassName?: string,
          onMouseLeave?: (SyntheticMouseEvent<HTMLElement>) => void,
          onMouseEnter?: (SyntheticMouseEvent<HTMLElement>) => void,
          tipClassName?: string,
        },
      } {
    return last(this.state.tips);
  }

  isAtTop(componentRef: ComponentRef): boolean {
    const tip = last(this.state.tips);
    return tip && tip.componentRef === componentRef;
  }

  calcTipPlacement(fixedTo: Placement): PositionedPlacement {
    const {
      componentRef: {current},
      noStyles,
    } = last(this.state.tips);
    if (!current) {
      return {x: 0, y: 0, placement: fixedTo};
    }
    const pad = noStyles ? 0 : this.pad;
    return getAnchorPosition(current, fixedTo, pad);
  }

  listen() {
    window.addEventListener('mousemove', this.placeFluid);
  }

  stop() {
    window.removeEventListener('mousemove', this.placeFluid);
  }

  _placeFluid(event: MouseEvent) {
    const placement = event.pageY < this.boundary ? 'bottom' : 'top';

    const x = event.pageX;
    const y = event.pageY - this.pad;

    this.setState({
      placement,
      x,
      y,
    });
  }
  placeFluid: (...params: Array<mixed>) => void = throttleAnimation(
    // $FlowFixMe[method-unbinding]
    this._placeFluid.bind(this),
  );

  render(): React.Node {
    const {children} = this.props;
    const {show, tips} = this.state;
    const tip = last(tips);

    let tipContent;
    if (show && tip) {
      const {
        content,
        handleClose,
        tipProps,
        arrowPosition,
        noArrow,
        noStyles,
        buttons,
        fixedTo,
        className,
      } = tip;

      const {
        x,
        y,
        placement = '',
      } = fixedTo ? this.calcTipPlacement(fixedTo) : this.state;

      const style = {
        left: x,
        top: y,
      };

      const tipClass = css[camelCase(`${placement} ${arrowPosition} tip`)];
      const svgClass = css[camelCase(`${placement} ${arrowPosition} svg`)];

      tipContent = (
        <div
          className={classify(tipClass, className)}
          style={style}
          onClick={(event) => {
            // NOTE (kyle): this prevents clickaways from firing on
            // other elements.
            event.nativeEvent.stopImmediatePropagation();
            event.stopPropagation();
          }}
          onMouseEnter={tipProps?.onMouseEnter}
          onMouseLeave={tipProps?.onMouseLeave}
        >
          <section
            className={classify(
              {[css.content]: !noStyles},
              tipProps?.contentClassName,
            )}
          >
            {content}
          </section>
          {buttons && (
            <section className={css.buttons}>
              {Object.keys(buttons).map((key) => (
                <button
                  key={key}
                  className={css.button}
                  onClick={(event: SyntheticMouseEvent<HTMLElement>) => {
                    buttons[key].call(this, event);
                    handleClose();
                  }}
                >
                  {key}
                </button>
              ))}
            </section>
          )}
          {!noArrow && (
            <svg viewBox="0 0 11 9" className={svgClass}>
              <path
                className={classify(css.arrow, tipProps?.tipClassName)}
                d="M 0,0 L 11,0 L 5.5,9z"
              />
              <path
                className={classify(css.arrowCover, tipProps?.tipClassName)}
                d="M 1,0 L 10,0"
              />
            </svg>
          )}
        </div>
      );
    }

    return (
      <MouseTipContext.Provider value={tip}>
        {children}
        {tipContent}
      </MouseTipContext.Provider>
    );
  }
}

const MouseTipContext = React.createContext<?RenderedTip>();
