// @flow strict

import type {RuleBlockComponentProps} from 'src/types/referral-v2';
// $FlowFixMe[nonstrict-import]

import * as React from 'react';

import classify from 'src/utils/classify';
import {getRuleInfo} from 'src/components/referral-v2/common.js';
import {Dropdown} from '@spaced-out/ui-design-system/lib/components/Dropdown';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {Link} from '@spaced-out/ui-design-system/lib/components/Link';
import {
  FormLabelSmall,
  SubTitleExtraSmall,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {Input} from '@spaced-out/ui-design-system/lib/components/Input';
import {Card} from '@spaced-out/ui-design-system/lib/components/Card';
import {
  RadioGroup,
  RadioButton,
} from '@spaced-out/ui-design-system/lib/components/RadioButton';
import Rule from 'src/components/referral-v2/payout-rule/rule.jsx';
import rulesCss from 'src/components/referral-v2/settings/settings.css';


const StartDateRuleBlock = ({
  getEntityOptionsByFieldType,
  handleUpdateRuleBlockData,
  handleSelectRule,
  handleCopyRule,
  handleAddRule,
  ruleBlockData,
  entityFields,
  onDeleteRule,
  onEditRule,
  disabled,
}: RuleBlockComponentProps): React.Node => {
  const handleChangeRuleType = (value) => {
    let data = {rule_type: value};
    if (value === 'field') {
      data = {...data, field: {field_name: ''}};
    } else {
      data = {...data, rule: []};
    }
    handleUpdateRuleBlockData('start_date_rule', data);
  };

  React.useEffect(() => {
    if (!ruleBlockData) {
      handleUpdateRuleBlockData('start_date_rule', {
        rule_type: 'field',
        field: {
          field_name: '',
        },
      });
    }
  }, []);

  const handleOffsetChange = (value) => {
    if (parseInt(value) > 300) {
      return;
    }
    if (ruleBlockData) {
      handleUpdateRuleBlockData('start_date_rule', {
        ...ruleBlockData,
        offset: parseInt(value),
      });
    }
  };

  return (
    <div className={classify(rulesCss.flexColumn, rulesCss.defaultGap)}>
      <Link onClick={() => handleCopyRule?.()} disabled={disabled}>
        Use the rule as created in 1 above.
      </Link>
      <RadioGroup
        align="horizontal-fixed"
        name="radio-group-2"
        onChange={handleChangeRuleType}
        value={ruleBlockData?.rule_type ?? 'field'}
      >
        <RadioButton disabled={disabled} value={'field'}>
          Map a field to get the value automatically
        </RadioButton>
        <RadioButton disabled={disabled} value={'event'}>
          Configure rule
        </RadioButton>
      </RadioGroup>
      {ruleBlockData?.rule_type === 'event' ? (
        <>
          {ruleBlockData.rule?.map((payoutRule, index) => {
            const rule = entityFields.find(
              (field) => field.value === payoutRule.field_name,
            );
            return (
              <Rule
                disabled={disabled}
                options={getEntityOptionsByFieldType()}
                rule={rule}
                fieldObject={payoutRule}
                showAndText={index > 0}
                hideRemoveIcon={index === 0}
                handleSelectRule={(option) =>
                  handleSelectRule(
                    option,
                    'start_date_rule',
                    ruleBlockData,
                    index,
                  )
                }
                onRemove={() =>
                  onDeleteRule(index, 'start_date_rule', ruleBlockData)
                }
                onEdit={(ruleData) =>
                  onEditRule(ruleData, 'start_date_rule', ruleBlockData, index)
                }
              />
            );
          })}
          {ruleBlockData.rule && ruleBlockData.rule.length > 0 && (
            <div
              className={classify(rulesCss.offSetInput, rulesCss.defaultGap)}
            >
              <div className={rulesCss.andText}></div>
              <FormLabelSmall color="secondary">
                From the date captured above, add:
              </FormLabelSmall>
              <div className={rulesCss.ruleInput}>
                <Input
                  onChange={(event) => handleOffsetChange(event.target.value)}
                  disabled={disabled}
                  value={
                    ruleBlockData.offset ? ruleBlockData.offset.toString() : ''
                  }
                  min="0"
                  size="small"
                  type="number"
                />
              </div>
              <FormLabelSmall>days</FormLabelSmall>
            </div>
          )}
          <div className={rulesCss.ruleInputContainer}>
            <Button
              size="small"
              iconLeftName="plus"
              isFluid={false}
              type="secondary"
              disabled={disabled}
              onClick={() => handleAddRule('start_date_rule', ruleBlockData)}
            >
              Add a new clause
            </Button>
          </div>
          {ruleBlockData.rule && ruleBlockData.rule.length > 0 && (
            <Card classNames={{wrapper: rulesCss.chip}}>
              <SubTitleExtraSmall color="secondary">
                We will use the date when the condition defined above is
                satisfied to identify the start date for the referral candidate.
                In this case, when the field
                {getRuleInfo(ruleBlockData.rule)},{' '}
                {!!ruleBlockData.offset &&
                  `and identify the start date after ${ruleBlockData.offset} days for the referral candidate`}
              </SubTitleExtraSmall>
            </Card>
          )}
        </>
      ) : (
        <>
          <div className={rulesCss.ruleInput}>
            <Dropdown
              required={true}
              disabled={disabled}
              size="small"
              label="Field name"
              dropdownInputText={ruleBlockData?.field?.field_name ?? 'Select'}
              onChange={(option) =>
                handleSelectRule(option, 'start_date_rule', ruleBlockData)
              }
              menu={{
                classNames: {
                  wrapper: rulesCss.option,
                  optionTextContainer: rulesCss.option,
                },
                options: getEntityOptionsByFieldType('date') ?? [],
                selectedKeys: ruleBlockData?.field?.field_name
                  ? [ruleBlockData.field.field_name]
                  : [],
                allowSearch: true,
              }}
            />
          </div>
          {ruleBlockData?.field?.field_name && (
            <Card classNames={{wrapper: rulesCss.chip}}>
              <SubTitleExtraSmall color="secondary">
                We will use the date shown in "{ruleBlockData.field.field_name}"
                field to identify the start date of referral candidate.
              </SubTitleExtraSmall>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default StartDateRuleBlock;
