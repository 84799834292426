// @flow strict

import type {ReferralExternalAction} from 'src/action-creators/referral-external';
import type {
  Question,
  Brand,
  QuestionApiError,
  JobResponse,
  JobDescription,
} from 'src/types/referral-external';

import {DEFAULT_BUTTON_COLOR} from 'src/utils/referral-external';
import {
  RECEIVE_QUESTIONS,
  RECEIVE_QUESTIONS_ERROR,
  RECEIVE_BRAND,
  FETCHING_REFERRAL_JOBS,
  FETCHING_LOCATIONS,
  FETCHING_DESCRIPTION,
} from 'src/action-creators/referral-external';


export type State = {
  questions: Question[],
  brand: Brand,
  // jobs: Job[],
  must_acknowledge_terms: boolean,
  program_name: string,
  welcome_message: string,
  is_tracking_enabled: boolean,
  is_referrer_portal_enabled: boolean,
  job_list_enabled: boolean,
  questionnaire_api_error: ?QuestionApiError,
  locations: ?(string[]),
  jobs: ?(JobResponse[]),
  jobDescription: ?JobDescription,
};

const initialState = {
  questions: [],
  brand: {logo_url: '', display_name: '', button_color: DEFAULT_BUTTON_COLOR},
  // jobs: [],
  must_acknowledge_terms: false,
  program_name: '',
  welcome_message: '',
  is_tracking_enabled: true,
  is_referrer_portal_enabled: false,
  job_list_enabled: false,
  questionnaire_api_error: null,
  locations: null,
  jobs: null,
  jobDescription: null,
};

export default (
  state: State = initialState,
  action: ReferralExternalAction,
): State => {
  switch (action.type) {
    case RECEIVE_QUESTIONS: {
      return {
        ...state,
        questions: action.payload.questions,
        must_acknowledge_terms: action.payload.must_acknowledge_terms,
        program_name: action.payload.program_name,
        welcome_message: action.payload.welcome_message,
        is_tracking_enabled: action.payload.is_tracking_enabled,
        is_referrer_portal_enabled: action.payload.is_referrer_portal_enabled,
        job_list_enabled: action.payload.job_list_enabled,
      };
    }
    case RECEIVE_QUESTIONS_ERROR: {
      return {
        ...state,
        questionnaire_api_error: action.payload,
      };
    }
    case RECEIVE_BRAND: {
      return {
        ...state,
        brand: {
          ...action.payload,
          button_color: action.payload.button_color || DEFAULT_BUTTON_COLOR,
        },
      };
    }
    // case RECEIVE_JOBS: {
    //   return {
    //     ...state,
    //     jobs: action.payload,
    //   };
    // }
    case FETCHING_REFERRAL_JOBS: {
      return {
        ...state,
        jobs: action.payload,
      };
    }
    case FETCHING_LOCATIONS: {
      return {
        ...state,
        locations: action.payload,
      };
    }
    case FETCHING_DESCRIPTION: {
      return {
        ...state,
        jobDescription: action.payload,
      };
    }
  }
  return state;
};
