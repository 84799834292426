// @flow

import type {
  GetState,
  Dispatch,
  ThunkAction,
  ThunkSyncAction,
} from 'src/reducers';
import type {Action} from 'src/types/redux';
import type {
  StateThreadList,
  IndexThreadList,
  ApiThreadList,
} from 'src/types/thread-lists';
import type {ApiBroadcast} from 'src/types/broadcasts';

import invariant from 'invariant';

import logger from 'src/utils/logger';

import {thunkify as flow} from 'src/utils/thunks';
import {key, cached, fetching, force} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api-v2';
import {camel, snake} from 'src/utils';
import {pushModal, showHandledApiError} from 'src/action-creators/modal';
import {selectPhoneNumberSetFromDefaultPhoneNumberSet} from 'src/selectors/chat';
import {
  selectThreadList,
  selectThreadListIndex,
  selectSortedThreadLists,
} from 'src/selectors/thread-lists';


type ReceivePageAction = Action<
  'threads/lists/receivePage',
  {
    threadLists: IndexThreadList[],
    offset: number,
    phone_number_set_id: string,
  },
>;
type ReceiveOneAction = Action<'threads/lists/receiveOne', ApiThreadList>;
type ReceiveSomeAction = Action<'threads/lists/receiveSome', IndexThreadList[]>;
type ReceiveBroadcastsAction = Action<
  'threads/lists/receiveBroadcastsForThread',
  {
    threadId: string,
    broadcasts: ApiBroadcast[],
  },
>;
type ReceiveUpdatedAction = Action<
  'threads/lists/receiveUpdated',
  StateThreadList,
>;
type RemoveMemberAction = Action<
  'threads/lists/removeMember',
  {
    listId: string,
    threadId: string,
  },
>;
type RemoveOneAction = Action<
  'threads/lists/removeOne',
  {threadListId: string},
>;
type AddOneAction = Action<
  'threads/lists/addOne',
  {
    threadList: StateThreadList,
    index?: number,
  },
>;

export type ThreadListAction =
  | ReceivePageAction
  | ReceiveOneAction
  | ReceiveSomeAction
  | ReceiveUpdatedAction
  | ReceiveBroadcastsAction
  | RemoveMemberAction
  | RemoveOneAction
  | AddOneAction;

export const RECEIVE_PAGE = 'threads/lists/receivePage';
const receivePage = (payload: *): ReceivePageAction => ({
  type: RECEIVE_PAGE,
  payload,
});

export const RECEIVE_BROADCASTS_FOR_THREAD =
  'threads/lists/receiveBroadcastsForThread';
export const receiveBroadcastsForThread = (
  threadId: string,
  broadcasts: ApiBroadcast[],
): ReceiveBroadcastsAction => ({
  type: RECEIVE_BROADCASTS_FOR_THREAD,
  payload: {
    threadId,
    broadcasts,
  },
});

export const PAGE_LIMIT = 30;

export const get_page: (
  offset: number,
  phone_number_set_id: string,
) => ThunkAction<> = flow(
  key(
    (offset, phone_number_set_id) =>
      `threads/lists/getPage:${offset}&phoneNumberSetId:${phone_number_set_id}`,
  ),
  cached((json, offset, phone_number_set_id) =>
    receivePage({
      threadLists: camel(json.map((list) => ({...list, phone_number_set_id}))),
      offset,
      phone_number_set_id,
    }),
  ),
  fetching(),
)((offset: number, phone_number_set_id: string) =>
  reduxApi.get('messages_v2/thread-lists', {
    offset,
    limit: PAGE_LIMIT,
    internal: true,
    phone_number_set_id,
  }),
);

export const getPage: (
  offset: number,
  phone_number_set_id: string,
) => ThunkAction<> =
  (offset) => async (dispatch: Dispatch, getState: GetState) => {
    const phone_number_set_id = selectPhoneNumberSetFromDefaultPhoneNumberSet(
      getState(),
    );
    return dispatch(get_page(offset, phone_number_set_id));
  };

export const getMoreThreadLists: () => ThunkAction<> = flow(
  key(() => 'threads/lists/getMore'),
  fetching(),
)(() => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const {listDone} = state.threadLists;
  const list = selectSortedThreadLists(state);
  const phone_number_set_id =
    selectPhoneNumberSetFromDefaultPhoneNumberSet(state);
  invariant(!listDone[phone_number_set_id], 'Max offset for inbox reached!');
  return dispatch(getPage(list.length, phone_number_set_id));
});

export const RECEIVE_ONE = 'threads/lists/receiveOne';
const receiveOne = (payload: *): ReceiveOneAction => ({
  type: RECEIVE_ONE,
  payload,
});

export const getOne: (
  id: string,
  broadcastSuppressionNumber?: string,
  broadcastSuppressionUnit?: string,
) => ThunkAction<> = flow(
  key((id) => `threads/lists/getOne:${id}`),
  cached((json) => receiveOne(camel(json)), {ttl: 100}),
  fetching(),
)(
  (
    id: string,
    broadcastSuppressionNumber?: string,
    broadcastSuppressionUnit?: string,
  ) =>
    reduxApi.get(`messages_v2/thread-list/${id}`, {
      thread_list_id: id,
      broadcast_suppression_number: broadcastSuppressionNumber,
      broadcast_suppression_unit: broadcastSuppressionUnit,
    }),
);

export const RECEIVE_SOME = 'threads/lists/receiveSome';
const receiveSome = (payload: *): ReceiveSomeAction => ({
  type: RECEIVE_SOME,
  payload,
});

export const getForContact: (contactId: string) => ThunkAction<> = flow(
  key((contactId) => `threads/lists/getForContact:${contactId}`),
  cached((json) => receiveSome(camel(json))),
  fetching(),
)((contactId: string) =>
  reduxApi.get(`messages_v2/contact/${contactId}/thread-lists`),
);

export const ADD_ONE = 'threads/lists/addOne';
export const addThreadList = (payload: {
  threadList: StateThreadList,
  index?: number,
}): AddOneAction => ({
  type: ADD_ONE,
  payload,
});

export const create: ({name: string}) => ThunkAction<StateThreadList> = flow(
  key(() => `threads/lists/create`),
  fetching(),
)(
  (threadList: {name: string}) =>
    async (dispatch: Dispatch, getState: GetState) => {
      const phoneNumberSetId = selectPhoneNumberSetFromDefaultPhoneNumberSet(
        getState(),
      );
      const json = await dispatch(
        reduxApi.post('messages_v2/thread-lists', {
          phone_number_set_id: phoneNumberSetId,
          name: threadList.name,
        }),
      );

      const newThreadList = camel(json);
      dispatch(addThreadList({threadList: newThreadList}));
      return newThreadList;
    },
);

export const RECEIVE_UPDATED = 'threads/lists/receiveUpdated';
const receiveUpdated = (payload: StateThreadList): ReceiveUpdatedAction => ({
  type: RECEIVE_UPDATED,
  payload,
});

// NOTE (kyle): the only thing you can currently update is the name
export const update: (
  id: string,
  updates: {name: string},
) => ThunkAction<void> = flow(
  key((id) => `threads/lists/update:${id}`),
  fetching(),
)((id: string, updates: {name: string}) => async (dispatch: Dispatch) => {
  const json = await dispatch(
    reduxApi.put(`messages_v2/thread-list/${id}`, snake(updates)),
  );
  dispatch(receiveUpdated(camel(json)));
});

export const START_ADDING = 'threads/lists/startAdding';
export const startAdding =
  (listId: string): ThunkSyncAction =>
  (dispatch: Dispatch, getState: GetState) => {
    const threadList = getState().threadLists.map[listId];

    invariant(threadList, 'Attempted to edit a nonexistent contact list.');

    dispatch(pushModal({type: 'CONTACT_LIST_EDITOR', threadListId: listId}));
  };

export const addToList =
  (listId: string, phoneNumbers: string[]): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const phoneNumberSetId = selectPhoneNumberSetFromDefaultPhoneNumberSet(
      getState(),
    );

    await dispatch(
      reduxApi.put(
        `messages_v2/thread-lists/${listId}/threads/by/phone-number`,
        {
          phone_numbers: phoneNumbers,
          phone_number_set_id: phoneNumberSetId,
        },
      ),
    );

    return dispatch(force(getOne(listId)));
  };

export const REMOVE_MEMBER = 'threads/lists/removeMember';

export const removeThread =
  (listId: string, threadId: string): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    // TODO (kyle): implement this
    logger.log('removing thread');

    const originalThreadList = getState().threadLists.map[listId];

    dispatch({
      type: REMOVE_MEMBER,
      payload: {
        listId,
        threadId,
      },
    });

    try {
      await dispatch(
        reduxApi.post(`messages_v2/thread-list/${listId}/remove`, {
          thread_ids: [threadId],
        }),
      );
    } catch (error) {
      dispatch(receiveUpdated(originalThreadList));
      if (error instanceof reduxApi.ApiError) {
        dispatch(
          showHandledApiError({
            text: 'Failed to remove member from contact list.',
            json: error.responseBody,
          }),
        );
      } else {
        throw error;
      }
    }
  };

export const REMOVE_ONE = 'threads/lists/removeOne';
const removeOne = (payload: *): RemoveOneAction => ({
  type: REMOVE_ONE,
  payload,
});

export const deleteThreadList =
  (threadListId: string): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const threadList = selectThreadList(getState(), threadListId);
    const threadListIndex = selectThreadListIndex(getState(), threadListId);
    dispatch(removeOne({threadListId}));
    try {
      await dispatch(reduxApi.del(`messages_v2/thread-list/${threadListId}`));
    } catch (error) {
      if (threadList) {
        dispatch(addThreadList({threadList, index: threadListIndex}));
      }
    }
  };
