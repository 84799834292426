// @flow

import type {AuditActions, AuditData} from 'src/action-creators/audit';
import type {AttributeMetaData} from 'src/types/writeback';
import type {EntityType} from 'src/types/ats-entities';

import {
  RECEIVE_AUDIT,
  RECEIVE_WRITEBACK_ATTRIBUTES,
} from 'src/action-creators/audit';


export type AuditFields = {
  [EntityType]: AttributeMetaData[],
  ...
};

export type AuditState = {
  all: AuditData,
  fields: AuditFields,
};

const initialState = {
  all: {},
  fields: {},
};

export default (
  state: AuditState = initialState,
  action: AuditActions,
): AuditState => {
  switch (action.type) {
    case RECEIVE_AUDIT:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.pageNumber]: action.payload.data,
        },
      };
    case RECEIVE_WRITEBACK_ATTRIBUTES:
      return {
        ...state,
        fields: action.payload,
      };
    default:
      return state;
  }
};
