// @noflow
import * as React from 'react';

import {
  supportedTimezones,
  getTimezoneDisplayNameAndOffset,
} from 'src/utils/date-time';

import Select from 'src/components/lib/select/select.jsx';

import css from './choose-time-zone-modal.css';


type TimezoneOption = {
  label: string,
  value: string,
};

function mapTimezoneToTimezoneOption(timezone: string): TimezoneOption {
  const {displayName, offset} = getTimezoneDisplayNameAndOffset(timezone);
  const label = `(${offset}) ${displayName}`;
  return {
    label,
    value: timezone,
  };
}

const timezoneOptions = supportedTimezones
  .map(mapTimezoneToTimezoneOption)
  .sort((option1, option2) => {
    if (option1.label < option2.label) {
      return -1;
    }
    if (option1.label > option2.label) {
      return 1;
    }
    return 0;
  });

type Props = {
  currentTimezone: string,
  handleSave: (newTimezone: string) => void,
};

type State = {
  currentTimezoneOption: TimezoneOption,
};

export default class ChooseTimeZoneModal extends React.Component<Props, State> {
  state = {
    currentTimezoneOption: mapTimezoneToTimezoneOption(
      this.props.currentTimezone,
    ),
  };

  handleChange = entry => this.setState({currentTimezoneOption: entry});

  render() {
    const {currentTimezoneOption} = this.state;

    return (
      <div className={css.container}>
        <div className={css.title}>Choose your default time zone</div>
        <div className={css.selectContainer}>
          <Select
            className={css.select}
            options={timezoneOptions}
            value={currentTimezoneOption}
            onChange={this.handleChange}
            clearable={false}
          />
        </div>
        <div className={css.menu}>
          <div
            className={css.saveButton}
            onClick={() => this.props.handleSave(currentTimezoneOption.value)}
          >
            Save
          </div>
        </div>
      </div>
    );
  }
}
