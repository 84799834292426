// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, GetState, ThunkAction} from 'src/reducers';

// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';
import type {
  FilterRule,
  FilterScope,
  UpdateEMSRulePayload,
} from 'src/types/filter';
import {
  TEMP_END_NODE_IDENTIFIER,
  TEMP_NODE_IDENTIFIER,
} from 'src/utils/automation-workflow';
import {
  addTempSplit,
  addTempTransition,
  deleteSplit,
  getAutomationWorkflow,
} from 'src/action-creators/automation-workflow';
import {
  getCombinedId,
  getSplitsPayload,
  getUniqueTempEndNode,
} from 'src/utils/split';
import type {SplitData} from 'src/types/split';


export const ADD_TEMP_SPLIT_NODE = 'split/add-temp';
export const ADD_SPLIT = 'split/add-split';
export const SET_SPLIT_RULES = 'split/set-rules';
export const SET_SPLIT_SCOPE = 'split/set-scope';
export const UPDATE_SPLIT_RULE = 'split/update-rule';
export const UPDATE_SPLIT_SCOPE = 'split/update-scope';
export const REMOVE_SPLIT_RULE = 'split/remove-rule';
export const DELETE_SPLIT = 'split/delete-split';
export const RECEIVE_SPLITS = 'split/receive-splits';

type AddTempSplitNodeAction = {
  type: 'split/add-temp',
  payload: {swid: string, ifEndNodeId: string, elseEndNodeId: string},
};

type AddSplitAction = {
  type: 'split/add-split',
  payload: {
    swid: string,
    nodeId: string,
    position: number,
    tempEndNodeId: string,
  },
};

type SetRulesAction = {
  type: 'split/set-rules',
  payload: {swid: string, nodeId: string, rules: FilterRule[]},
};

type SetScopeAction = {
  type: 'split/set-scope',
  payload: {swid: string, nodeId: string, scope: ?FilterScope},
};

type UpdateRuleAction = {
  type: 'split/update-rule',
  payload: {
    swid: string,
    nodeId: string,
    splitId: string,
    ruleId: string,
    updateObject: UpdateEMSRulePayload,
  },
};

type UpdateScopeAction = {
  type: 'split/update-scope',
  payload: {
    swid: string,
    nodeId: string,
    updateObject: ?FilterScope,
  },
};

type RemoveRuleAction = {
  type: 'split/remove-rule',
  payload: {
    swid: string,
    nodeId: string,
    ruleHash: string,
  },
};

type DeleteSplitNodeAction = {
  type: 'split/delete-split',
  payload: {
    swid: string,
    nodeId: string,
    position: number,
  },
};

type ReceiveSplitsAction = {
  type: 'split/receive-splits',
  payload: SplitData,
};

export type SplitAction =
  | AddTempSplitNodeAction
  | AddSplitAction
  | SetRulesAction
  | SetScopeAction
  | UpdateRuleAction
  | UpdateScopeAction
  | RemoveRuleAction
  | DeleteSplitNodeAction
  | ReceiveSplitsAction;

//We will be adding temp entries to both automation workflow store and split store
export const addTempSplitNodeAndModifySwimlanes: (
  swid: string,
  tid: string,
) => ThunkAction<mixed> = (swid, tid) => async (dispatch: Dispatch) => {
  const elseEndNodeId = getUniqueTempEndNode();
  // adding temp split in canvas
  await dispatch(
    addTempSplit({
      swid,
      nodeId: TEMP_NODE_IDENTIFIER,
      tid: elseEndNodeId,
      position: 1,
    }),
  );
  // adding temp split in node
  return dispatch(addTempSplitNode(swid, tid, elseEndNodeId));
};

export const deleteTempSplitAndModifySwimlanes: (
  swid: string,
  nodeId: string,
  position: number,
) => ThunkAction<mixed> =
  (swid, nodeId, position) => async (dispatch: Dispatch) => {
    await dispatch(deleteSplit({swid, nodeId, position}));
    return dispatch(deleteSplitNode(swid, nodeId, position));
  };

export const deleteSplitNode = (
  swid: string,
  nodeId: string,
  position: number,
): DeleteSplitNodeAction => ({
  type: DELETE_SPLIT,
  payload: {swid, nodeId, position},
});

export const addTempSplitNode = (
  swid: string,
  ifEndNodeId: string,
  elseEndNodeId: string,
): AddTempSplitNodeAction => ({
  type: ADD_TEMP_SPLIT_NODE,
  payload: {swid, ifEndNodeId, elseEndNodeId},
});

export const addSplitAndModifySwimlanes: (
  swid: string,
  nodeId: string,
  position: number,
) => ThunkAction<mixed> =
  (swid, nodeId, position) => async (dispatch: Dispatch) => {
    const uniqueTempEndNode = getUniqueTempEndNode();
    await dispatch(
      addTempSplit({
        swid,
        nodeId,
        tid: uniqueTempEndNode,
        position,
      }),
    );
    return dispatch(addSplit(swid, nodeId, position, uniqueTempEndNode));
  };

export const addSplit = (
  swid: string,
  nodeId: string,
  position: number,
  tempEndNodeId: string,
): AddSplitAction => ({
  type: ADD_SPLIT,
  payload: {swid, nodeId, position, tempEndNodeId},
});

export const setSplitRules = (
  swid: string,
  nodeId: string,
  rules: FilterRule[],
): SetRulesAction => ({
  type: SET_SPLIT_RULES,
  payload: {swid, nodeId, rules},
});

export const setSplitScope = (
  swid: string,
  nodeId: string,
  scope: ?FilterScope,
): SetScopeAction => ({
  type: SET_SPLIT_SCOPE,
  payload: {swid, nodeId, scope},
});

export const updateSplitRule = (
  swid: string,
  nodeId: string,
  splitId: string,
  ruleId: string,
  updateObject: UpdateEMSRulePayload,
): UpdateRuleAction => ({
  type: UPDATE_SPLIT_RULE,
  payload: {swid, nodeId, splitId, ruleId, updateObject},
});

export const updateSplitScope = (
  swid: string,
  nodeId: string,
  updateObject: ?FilterScope,
): UpdateScopeAction => ({
  type: UPDATE_SPLIT_SCOPE,
  payload: {swid, nodeId, updateObject},
});

export const removeSplitRule = (
  swid: string,
  nodeId: string,
  ruleHash: string,
): RemoveRuleAction => ({
  type: REMOVE_SPLIT_RULE,
  payload: {swid, nodeId, ruleHash},
});

export const createNewSplitNode: ({
  from_node_id: string,
  to_node_id: string,
  swimlane_id: string,
  workflow_id: string,
  stateSplitId: string,
}) => ThunkAction<mixed> =
  ({from_node_id, to_node_id, swimlane_id, workflow_id, stateSplitId}) =>
  async (dispatch: Dispatch) => {
    const payload = {
      node_type: 'branching',
      swimlane_id: parseInt(swimlane_id, 10),
      workflow_id: parseInt(workflow_id, 10),
      body: {
        branching_node_type: 'CONDITIONAL_SPLIT',
      },
    };
    const res = await dispatch(
      reduxApi.post('node', payload, {
        previous_node: from_node_id,
        next_node: to_node_id,
      }),
    );
    if (res && Array.isArray(res) && res.length > 0) {
      return dispatch(
        saveSplits({
          from_node_id: `branching:${res[0].id}`,
          workflow_id,
          stateSplitId,
          swimlane_id,
        }),
      );
    }
  };

export const saveSplits: ({
  from_node_id: string,
  workflow_id: string,
  stateSplitId: string,
  swimlane_id: string,
}) => ThunkAction<mixed> =
  ({from_node_id, workflow_id, stateSplitId, swimlane_id}) =>
  (dispatch: Dispatch, getState: GetState) => {
    const combinedId = getCombinedId(swimlane_id, stateSplitId);
    const splitArray = getState().split[combinedId];
    const payload = getSplitsPayload(splitArray, from_node_id);
    return dispatch(
      reduxApi.post('node/transition/bulk', {transitions: payload}),
    ).then(() => dispatch(getAutomationWorkflow(workflow_id)));
  };

export const receiveSplits = (payload: SplitData): ReceiveSplitsAction => ({
  type: RECEIVE_SPLITS,
  payload,
});
