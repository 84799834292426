// @flow strict
import type {
  ReferralSetting,
  ReferralSettingApiRequest,
} from 'src/types/referral-v2';
import * as React from 'react';
import {useSelector, useDispatch} from 'react-redux';
// $FlowFixMe[nonstrict-import]
import {useReleaseFlag} from 'src/hooks/product-flags';
import {AnalyticsService} from 'src/analytics';
// $FlowFixMe[nonstrict-import]
import {showHandledApiError} from 'src/action-creators/modal';
import {
  REFERRAL_NOTIFICATION,
  timeSheetSyncOptions,
} from 'src/utils/referral-v2.js';
import {showToastMessage} from 'src/action-creators/toasts';
import {VARIANTS} from 'src/designSystem2021Components/toast';
import {getReferralSettings} from 'src/action-creators/referral-v2/referral-settings.js';
//$FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2.js';
import {selectReferralSetting} from 'src/selectors/referral-settings.js';
//$FlowFixMe[nonstrict-import]
import usePrivilege from 'src/hooks/usePrivilege';
import {
  SubTitleSmall,
  FormLabelSmall,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {
  Card,
  CardHeader,
  CardContent,
} from '@spaced-out/ui-design-system/lib/components/Card';
import {Toggle} from '@spaced-out/ui-design-system/lib/components/Toggle';
import ReferralAdvanceSettings from 'src/components/referral-v2/settings/referral-settings.jsx';
import ReferralJobListAdvanceSettings, {
  SingleSelectDropdown,
} from 'src/components/referral-job-list/settings/one-time-settings.jsx';
import css from 'src/components/referral-v2/configure/configure.css';


const ReferralSettings = (): React.Node => {
  const dispatch = useDispatch();
  const isJobListEnabled = useReleaseFlag('referralsJobListEnabled');

  React.useEffect(() => {
    dispatch(getReferralSettings());
  }, []);
  const referralSettings: ?ReferralSetting = useSelector(selectReferralSetting);

  const hasEditPermission = usePrivilege('EDIT_REFERRALS_V2');
  const hasSuperAdminAccess = usePrivilege('ADMIN_REFERRALS_V2');

  const handleSave = async (payload: ReferralSettingApiRequest[]) => {
    try {
      await dispatch(
        reduxApi.put('referral/configure/settings', {data: payload}),
      );
      dispatch(
        showToastMessage('Details saved successfully', {
          variant: VARIANTS.SUCCESS,
        }),
      );
      dispatch(getReferralSettings());
    } catch (error) {
      dispatch(showHandledApiError({text: error?.response?.message, error}));
    }
  };
  const onToggle = (fieldName: string, value: boolean) => {
    const payload: ReferralSettingApiRequest[] = [
      {category: 'notifications', configs: [{key: fieldName, value}]},
    ];
    handleSave(payload);
  };

  const onOtherSettingChange = (fieldName: string, value: boolean | string) => {
    const payload: ReferralSettingApiRequest[] = [
      {category: 'other_settings', configs: [{key: fieldName, value}]},
    ];
    AnalyticsService.track('Referral review', {referralReview: value});
    handleSave(payload);
  };
  return (
    <div className={css.generalNotificationsContent}>
      <Card type="primary" classNames={{wrapper: css.defaultMargin}}>
        <CardHeader className={css.stepperCardHeader}>
          <SubTitleSmall className={css.toogleLabel}>
            Notification Settings
          </SubTitleSmall>
          <div className={css.notificationContent}>
            <SubTitleSmall>For Me</SubTitleSmall>
            <SubTitleSmall>For Referrers</SubTitleSmall>
          </div>
        </CardHeader>
        <CardContent className={css.contentSection}>
          {REFERRAL_NOTIFICATION.map((notification) => (
            <div key={notification.agent} className={css.defaultMargin}>
              <FormLabelSmall className={css.toogleLabel}>
                {notification.label}
              </FormLabelSmall>
              <div className={css.notificationContent}>
                <Toggle
                  classNames={{wrapper: css.emptyToggle}}
                  onChange={(e) => onToggle(notification.agent, e.checked)}
                  checked={referralSettings?.notifications[notification.agent]}
                  disabled={!hasEditPermission}
                />
                <Toggle
                  classNames={{wrapper: css.emptyToggle}}
                  onChange={(e) => onToggle(notification.recruiter, e.checked)}
                  checked={
                    referralSettings?.notifications[notification.recruiter]
                  }
                  disabled={!hasSuperAdminAccess}
                />
              </div>
            </div>
          ))}
        </CardContent>
      </Card>
      {isJobListEnabled ? (
        <ReferralJobListAdvanceSettings referralSettings={referralSettings} />
      ) : (
        <ReferralAdvanceSettings
          referralSettings={referralSettings}
          hasEditPermission={hasEditPermission}
          isOneTimeSettingDisabled={true}
          handleSave={handleSave}
        />
      )}
      <Card type="primary" classNames={{wrapper: css.defaultMargin}}>
        <CardHeader className={css.stepperCardHeader}>
          <SubTitleSmall>Other Settings</SubTitleSmall>
        </CardHeader>
        <CardContent className={css.contentSection}>
          <div className={css.defaultMargin}>
            <SingleSelectDropdown
              value={referralSettings?.other_settings.review_profile.toLocaleString()}
              onChangeOption={(value) => {
                const valueChanged = value === 'true' ? true : false;
                onOtherSettingChange('review_profile', valueChanged);
              }}
              label="Do you want your recruiters to review the referrals before they
              are submitted into your ATS?"
              disabled={!hasEditPermission}
            />
          </div>
          <SingleSelectDropdown
            value={
              referralSettings?.other_settings.timesheet_sync_type
                ? referralSettings.other_settings.timesheet_sync_type.toLocaleString()
                : 'overwrite'
            }
            onChangeOption={(value) => {
              onOtherSettingChange('timesheet_sync_type', value);
            }}
            options={timeSheetSyncOptions}
            label="Timesheet Sync Type"
            disabled={!hasEditPermission}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default ReferralSettings;
