// @flow

import type {Dispatch, GetState, ThunkAction} from 'src/reducers';

import * as reduxApi from 'src/utils/redux-api-v2';


export type CsrfAction = ReceiveCsrfTokenAction;

type ReceiveCsrfTokenAction = {
  type: 'csrf/receive',
  payload: {token: string},
};

export const RECEIVE_CSRF_TOKEN: 'csrf/receive' = 'csrf/receive';

export const receiveCsrfToken = (token: string): ReceiveCsrfTokenAction => ({
  type: RECEIVE_CSRF_TOKEN,
  payload: {token},
});

export const getCsrfToken = (): ThunkAction<> => async (dispatch: Dispatch) => {
  const response = await dispatch(reduxApi.get('auth/csrf-token'));
  const csrfToken = response['csrf-token'];
  dispatch(receiveCsrfToken(csrfToken));
  return csrfToken;
};
