// @flow strict
import * as React from 'react';
// $FlowFixMe
import {MouseTip} from 'src/components/lib/mouse-tip/mouse-tip.jsx';
import {Clickable} from 'src/designSystem2021Components/text-v2.jsx';
import InfoIcon from 'src/images/designSystems2021/infoIcon.svg';

import css from './info-label.css';


export type InfoLabelProps = {
  text: string,
  content: string,
  fixedTo: 'top' | 'left' | 'right' | 'bottom',
  infoBoxClass?: string,
  infoIconClass?: string,
};

export const InfoLabel = (props: InfoLabelProps): React.Node => {
  const {text, content, fixedTo, infoBoxClass, infoIconClass} = props;

  return (
    <div className={css.labelWithMouseTip}>
      {text}
      <MouseTip
        content={
          <Clickable className={css.mouseTipContentClass}>{content}</Clickable>
        }
        fixedTo={fixedTo}
        className={css.mouseTipContent}
        contentClassName={css.mouseTipContentClass}
        tipClassName={css.mouseTipClass}
      >
        <div className={infoBoxClass}>
          <InfoIcon className={infoIconClass} />
        </div>
      </MouseTip>
    </div>
  );
};
