// @flow

import type {
  WebsocketMessage,
  NewMessagesMsg,
  ThreadUpdateMsg,
} from 'src/types/websocket';
import type {Router} from 'src/types/router';
import type {MessagePending} from 'src/types/messages';
import {INBOX_TYPE} from 'src/types/messages';
import type {State, Dispatch, GetState} from 'src/reducers';

import values from 'lodash/values';
import filter from 'lodash/filter';
import omit from 'lodash/omit';
import moment from 'moment-timezone';
import {getTimezone} from 'src/selectors/date-time';
import {UPDATE_AGENT_JOINED_QUEUE} from 'src/chat-extension/messages';

import {batch, type BatchAction} from 'src/action-creators/batch';
import {
  changeReadOnlyMode,
  getInbox,
  receiveMessages,
  updateUnreadCounts,
  fetchMessagesForPhone,
  receiveSchedulerEvent,
  receiveConversationContextEvents,
  receiveTypingEvent,
  receiveReadStatusEvent,
  setReceivedPhoneNumberEvent,
  setInboxFilter,
} from 'src/action-creators/messages';
import {
  receivePerformanceDeliveryAnalytics,
  receiveBroadcastPerformanceRecipients,
} from 'src/action-creators/hv-broadcasts.js';
import {receiveAutoReplySettingsUpdates} from 'src/action-creators/chat/phone-number-sets';
import {setInbox} from 'src/action-creators/chat/multi-number-inbox';
import {receiveContact} from 'src/action-creators/contacts';
import {markBroadcastProcessed} from 'src/action-creators/broadcasts';
import {receivePopulatedThreads} from 'src/action-creators/threads';
import {receive as receiveAgentsForThread} from 'src/action-creators/accounts';
import {selectContact} from 'src/selectors/contacts';
import {getAudienceMembers} from 'src/selectors/audience-members';
import {selectReleaseFlags} from 'src/selectors/product-flags';
import {selectQueueSoundSetting} from 'src/selectors/messaging-queues';
import {toastApi} from '@spaced-out/ui-design-system/lib/components/Toast';
import latNotification from 'src/components/messaging/lat-notification.jsx';
import {EXTENSION_ID} from 'src/chat-extension/constants';
import {
  //$FlowFixMe[nonstrict-import]
  receiveQueueNotificationId,
  //$FlowFixMe[nonstrict-import]
  removeQueueNotificationId,
} from 'src/action-creators/chat/queues';
import {
  MESSAGE_EVENTS,
  THREAD_UPDATE,
  CONTACT_UPDATE,
  UNREAD_COUNT,
  CONVERSATION_CONTEXT_EVENT,
  TYPING_EVENT,
  READ_STATUS,
  SCHEDULER_NEW,
  SCHEDULER_RESCHEDULE,
  SCHEDULER_CANCEL,
  AGENT_JOINED_THREAD,
  AUTOREPLY_CHANGE,
  CONVERSATION_ADDED_TO_QUEUE,
  BROADCAST_DELIVERY_STATS_EVENT,
  BROADCAST_PERFORMANCE_RECIPIENTS_EVENT,
} from 'src/types/websocket';
import {WebSocketClient} from 'src/web-socket-client';
import {camel} from 'src/utils';
import logger from 'src/utils/logger';
import {captureSentryException} from 'src/utils/sentry';
import {pushChatLocation} from 'src/components/messaging/link.jsx';


const WEBSOCKET_OPEN = 1;

// Amount of time we switch WebSocket feature off if the user is experiencing
// issues with the WebSocket
const WEBSOCKET_FORBIDDEN_MS = 6 * 60 * 60 * 1000; // 6 hours

// Export for testing
export class MessageUpdateReceiver {
  client: WebSocketClient;
  dispatch: Dispatch;
  router: Router;
  buffer: WebsocketMessage[];
  buffering: boolean;
  firstDisconnectTime: ?Date;
  numDisconnects: number;
  getState: GetState;

  constructor(
    websocketClient: WebSocketClient,
    dispatch: Dispatch,
    getState: GetState,
    router: Router,
  ) {
    this.client = websocketClient;
    this.dispatch = dispatch;
    this.buffer = [];
    this.buffering = true;
    this.firstDisconnectTime = null;
    this.numDisconnects = 0;
    this.getState = getState;
    this.router = router;

    // $FlowFixMe[method-unbinding]
    (this: any).handleConnectionOpen = this.handleConnectionOpen.bind(this);
    // $FlowFixMe[method-unbinding]
    (this: any).handleConnectionClose = this.handleConnectionClose.bind(this);
    // $FlowFixMe[method-unbinding]
    (this: any).handleMessage = this.handleMessage.bind(this);

    // $FlowFixMe[method-unbinding]
    this.client.addListener('connection:open', this.handleConnectionOpen);
    // $FlowFixMe[method-unbinding]
    this.client.addListener('connection:close', this.handleConnectionClose);
    [
      MESSAGE_EVENTS,
      THREAD_UPDATE,
      CONTACT_UPDATE,
      CONVERSATION_CONTEXT_EVENT,
      TYPING_EVENT,
      UNREAD_COUNT,
      SCHEDULER_NEW,
      SCHEDULER_RESCHEDULE,
      SCHEDULER_CANCEL,
      AGENT_JOINED_THREAD,
      AUTOREPLY_CHANGE,
      CONVERSATION_ADDED_TO_QUEUE,
      BROADCAST_DELIVERY_STATS_EVENT,
      BROADCAST_PERFORMANCE_RECIPIENTS_EVENT,
    ].forEach((type) => {
      // $FlowFixMe[method-unbinding]
      this.client.addListener(type, this.handleMessage);
    });

    // The moment we have an open web socket, we begin buffering messages
    // and then fetch the latest updates from the API. Then we empty
    // the buffer. This ensures that we don't miss any message updates, for
    // example, between the moment the app starts and the moment we start
    // getting updates from the web socket.
    if (this.client.connection?.readyState === WEBSOCKET_OPEN) {
      this.handleConnectionOpen();
    }

    //for testing when websocket connection unavailable
    window.__mockWsMessage = (mockMessage) => {
      this.handleMessage(mockMessage, true);
    };
  }

  tearDown() {
    // $FlowFixMe[method-unbinding]
    this.client.removeListener('connection:open', this.handleConnectionOpen);
    // $FlowFixMe[method-unbinding]
    this.client.removeListener('connection:close', this.handleConnectionClose);
    [
      MESSAGE_EVENTS,
      THREAD_UPDATE,
      CONTACT_UPDATE,
      UNREAD_COUNT,
      SCHEDULER_NEW,
      SCHEDULER_RESCHEDULE,
      SCHEDULER_CANCEL,
      AGENT_JOINED_THREAD,
      AUTOREPLY_CHANGE,
      CONVERSATION_ADDED_TO_QUEUE,
      BROADCAST_DELIVERY_STATS_EVENT,
      BROADCAST_PERFORMANCE_RECIPIENTS_EVENT,
    ].forEach((type) => {
      // $FlowFixMe[method-unbinding]
      this.client.removeListener(type, this.handleMessage);
    });
  }

  onEnterReadOnly() {
    if (this.firstDisconnectTime === null) {
      this.firstDisconnectTime = new Date();
    }
    this.numDisconnects++;
    if (typeof localStorage !== 'undefined') {
      // Set variable in localStorage to prevent app from trying to use
      // WebSocket server if user refreshes the page
      localStorage.setItem(
        'doNotUseWebSocketBefore',
        String(new Date(Date.now() + WEBSOCKET_FORBIDDEN_MS)),
      );
    }
  }

  onExitReadOnly() {
    if (this.isConnectionHealthy()) {
      if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('doNotUseWebSocketBefore');
      }
    }
  }

  isConnectionHealthy(): boolean {
    const {firstDisconnectTime} = this;
    if (this.numDisconnects > 5 && firstDisconnectTime) {
      const timeSinceFirstDisconnect =
        Date.now() - firstDisconnectTime.getTime();
      // If disconnect frequency is greater than 1 every 10 seconds ...
      if (this.numDisconnects / timeSinceFirstDisconnect > 1 / 10000) {
        return false;
      }
    }
    return true; // assumed healthy until proven otherwise
  }

  handleConnectionOpen() {
    this.dispatch(changeReadOnlyMode(false));
    this.onExitReadOnly();
    this.getUpdatesFromApi().then(() => {
      this.buffering = false;
      this.buffer.forEach((message) => this.handleMessage(message));
      this.buffer = [];
    });
  }

  handleConnectionClose() {
    this.dispatch(changeReadOnlyMode(true));
    this.onEnterReadOnly();
    this.buffering = true;
  }

  sendMessageToExtension(type: string, payload: any) {
    const chrome = window.chrome;
    if (!chrome || !chrome.runtime) {
      return;
    }
    chrome.runtime.sendMessage(EXTENSION_ID, {
      type,
      payload,
    });
  }

  handleMessage(message: WebsocketMessage, mock: boolean = false) {
    logger.log('got message', message);
    if (!mock && this.buffering) {
      this.buffer.push(message);
    } else {
      switch (message.type) {
        case MESSAGE_EVENTS: {
          const {messages, agents, thread} = message.payload;
          // NOTE (aditya): dispatching these markBroadcastProcessed actions
          // here, so that the broadcast state updates as soon as the websocket
          // message is received.
          const actions = [];
          // TOOD (kyle): `messages` should always be defined, right?
          messages &&
            messages.forEach((message) => {
              if (
                // $FlowFixMe message is not camelcased
                message.broadcast_id &&
                (message.status === 'delivered' || message.status === 'sent')
              ) {
                actions.push(markBroadcastProcessed(message.broadcast_id));
              }
            });
          const pendingMessages: Array<MessagePending> = values(
            this.getState().messages.pendingMessages,
          );
          const filteredMessages = filter(
            messages,
            // $FlowFixMe message is not camelcased
            ({direction, broadcast_id, threadId}) =>
              !(
                !broadcast_id &&
                direction === 'outgoing' &&
                pendingMessages.some(
                  (pendingMessage) => pendingMessage?.threadId === threadId,
                )
              ),
          );
          this.dispatch(
            batch(
              this.receiveThreadMessage(message),
              receiveMessages(camel(filteredMessages)),
              receiveAgentsForThread(agents),
              ...actions,
            ),
          );
          break;
        }

        case THREAD_UPDATE: {
          this.dispatch(this.receiveThreadMessage(message));
          break;
        }

        case CONTACT_UPDATE: {
          const {contact} = message.payload;
          this.dispatch(receiveContact(camel(contact)));
          break;
        }

        case UNREAD_COUNT:
          this.dispatch(
            updateUnreadCounts({
              unreadByPhoneNumberSet:
                message.payload.unread_by_phone_number_set,
              unreadByQueue: message.payload.unread_by_lat_queue,
              unreadTimestamp: message.payload.unread_timestamp,
            }),
          );
          break;
        case TYPING_EVENT: {
          const {agent, thread_id, is_typing} = message.payload;
          this.dispatch(receiveTypingEvent(thread_id, agent.id, is_typing));
          break;
        }

        case CONVERSATION_CONTEXT_EVENT: {
          const shouldUseConversationContext =
            this.getState().agentSettings.messagingEnableConversationContext;
          if (shouldUseConversationContext) {
            this.dispatch(
              receiveConversationContextEvents(
                camel(message.payload.event.context_events),
              ),
            );
          }
          break;
        }

        case READ_STATUS: {
          this.dispatch(receiveReadStatusEvent(message.payload));
          break;
        }

        case SCHEDULER_NEW: {
          const {event} = message.payload;
          logger.log('scheduler new event', event);
          const externalPhone = event?.primary_guest_phone;
          if (externalPhone) {
            this.dispatch(receiveSchedulerEvent(externalPhone, event));
          }
          break;
        }
        case SCHEDULER_CANCEL: {
          const {event} = message.payload;
          logger.log('scheduler cancel event', event);
          break;
        }
        case SCHEDULER_RESCHEDULE: {
          const {event} = message.payload;
          logger.log('scheduler reschedule event', event);
          break;
        }

        case AGENT_JOINED_THREAD: {
          const {inbox_id, thread_id} = message.payload;
          this.dispatch(getInbox({})).then(() => {
            const pathname = `/messages/queues/${inbox_id}/with/${thread_id}`;
            pushChatLocation(this.router, pathname);
          });
          break;
        }

        case AUTOREPLY_CHANGE: {
          const {phone_number, active, body, until, is_autoreply_on} =
            message.payload;
          this.dispatch(
            receiveAutoReplySettingsUpdates(phone_number, {
              active,
              body,
              until,
              is_autoreply_on,
            }),
          );
          break;
        }

        case CONVERSATION_ADDED_TO_QUEUE: {
          const path = this.router?.location?.pathname || '';
          if (path.startsWith('/messages') || path.startsWith('/contacts')) {
            const {time_added, inbox_id, queue} = message.payload;
            const {title, body} = message.metadata;
            // get local timezone formatted date time 11/20/2023 4.15pm
            const timeZone = getTimezone(this.getState());
            const formattedTime = moment
              .tz(moment.unix(time_added), timeZone)
              .format('M/DD/YY h:mma');

            const handleDismiss = () => {
              this.dispatch(removeQueueNotificationId());
            };
            const handleOpenQueue = () => {
              this.dispatch(setInboxFilter(null));
              this.dispatch(setInbox(inbox_id));
              const pathname = `/messages/queues/${inbox_id}/`;
              pushChatLocation(this.router, pathname);
            };

            const soundSetting = selectQueueSoundSetting(
              this.getState(),
              queue.id,
            );

            const toastIdFromApi = toastApi.show(
              latNotification({
                toastTitle: title,
                toastBody: body,
                time: formattedTime,
                soundSetting,
                handleDismiss,
                handleOpenQueue,
              }),
              {
                autoClose: false,
              },
            );

            this.sendMessageToExtension(UPDATE_AGENT_JOINED_QUEUE, {
              timeAdded: time_added,
              soundSetting,
            });
            // in case of multiple toasts, remove prev and add current
            this.dispatch(removeQueueNotificationId());
            this.dispatch(receiveQueueNotificationId(toastIdFromApi));
          }
          break;
        }
        case BROADCAST_DELIVERY_STATS_EVENT: {
          const {delivery_analytics, broadcast_id} = message.payload;
          this.dispatch(
            receivePerformanceDeliveryAnalytics({
              id: broadcast_id,
              deliveryAnalytics: camel(delivery_analytics),
            }),
          );
          break;
        }
        case BROADCAST_PERFORMANCE_RECIPIENTS_EVENT: {
          const messagePayload = camel(message.payload);
          const {countOnly, id} = messagePayload;
          const omitKeys = countOnly
            ? ['recipients', 'countOnly']
            : ['countOnly'];
          if (id) {
            const payload = omit(messagePayload, omitKeys);
            this.dispatch(receiveBroadcastPerformanceRecipients(payload));
          }
          break;
        }

        default:
          const error = new Error(
            `MessageUpdateReceiver received invalid message type ${message.type}`,
          );
          logger.error(message, error);
          captureSentryException(error, {
            extra: {
              messageType: message.type,
            },
          });
          break;
      }
    }
  }

  getUpdatesFromApi(): Promise<mixed> {
    return this.dispatch(getInbox({noIncrement: true}));
  }

  getFullThreadData(phone_number_set_id: string, external_phone: string) {
    this.dispatch(fetchMessagesForPhone(external_phone, phone_number_set_id));
  }

  receiveThreadMessage(message: ThreadUpdateMsg | NewMessagesMsg): BatchAction {
    const {phone_number_set_id, thread} = message.payload;
    let {contact, audience_members} = message.payload;

    const contacts = [];
    const audienceMembers = [];

    if (thread && thread.channel === 'lat') {
      this.dispatch(
        // $FlowFixMe Thread is not camel cased
        setReceivedPhoneNumberEvent(thread.queue_id, INBOX_TYPE.QUEUE),
      );
      // $FlowFixMe Thread is not camel cased
    } else if (thread && thread.sense_phone) {
      this.dispatch(
        setReceivedPhoneNumberEvent(thread.sense_phone, INBOX_TYPE.PHONE),
      );
    }

    if (message.metadata.is_thin_notification) {
      contact = ((contact: any): string);
      audience_members = ((audience_members: any): string[]);

      const localAudienceMembers = getAudienceMembers(this.getState());
      if (
        (contact && !selectContact(this.getState(), contact)) ||
        audience_members.some((id) => !localAudienceMembers[id])
      ) {
        // $FlowFixMe Thread is not camel cased
        this.getFullThreadData(phone_number_set_id, thread.external_phone);
      }
    } else {
      if (contact) {
        contacts.push(camel(contact));
      }
      if (audience_members?.length) {
        audienceMembers.push(...camel(audience_members));
      }
    }
    const camelThread = omit(camel(thread), 'lastMessageReadByAgents');

    return receivePopulatedThreads([camelThread], contacts, audienceMembers);
  }
}
