// @flow

import type {SenseAction} from 'src/action-creators/types';

import omit from 'lodash/omit';


type CacheState = {
  [key: string]: {
    timestamp: number,
    queryHash: string,
  },
};

export type SenseCacheAction =
  | {
      type: '@@cache/set',
      payload: {
        key: string,
        timestamp: number,
        queryHash: string,
      },
      meta?: {
        ttl: number,
      },
    }
  | {
      type: '@@cache/evict',
      payload: {
        key: string,
      },
    };

export default (
  state: CacheState = Object.freeze({}),
  action: SenseCacheAction,
): CacheState => {
  switch (action.type) {
    case '@@cache/set':
      const {
        payload: {key, timestamp, queryHash},
      } = action;
      return {...state, [key]: {timestamp, queryHash}};
    case '@@cache/evict':
      const keyToEvict = action.payload.key;
      return omit(state, keyToEvict);
    default:
      return state;
  }
};
