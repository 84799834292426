// @flow strict

// $FlowFixMe[nonstrict-import]
import type {ThunkAction} from 'src/reducers';

import type {List, ListApiResponse} from 'src/types/lists';
import type {JourneyAudienceState} from 'src/reducers/journey-audience.old';
import {normalizeFilterRule} from 'src/utils/list';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';
//$FlowFixMe[nonstrict-import]
import {AnalyticsService} from 'src/analytics';
import {ENGAGE} from 'src/analytics/constants_event.js';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';


export type AudienceFormSteps =
  | 'createMode'
  | 'details'
  | 'copyLists'
  | 'rules'
  | 'review'
  | 'pickNLink'
  | 'summary';
export type UpdateStatePayload = $Shape<JourneyAudienceState>;

export const UPDATE_STATE = 'journey-audience-old/update';
export const RECEIVE_SELECTED_LISTS =
  'journey-audience-old/receive-selected-lists';
export const SET_SELECTED_LISTS = 'journey-audience-old/set-selected-lists';
export const RESET = 'journey-audience-old/reset';
export const GOTO_STEP = 'journey-audience-old/goto-step';

type UpdateStateAction = {
  type: 'journey-audience-old/update',
  payload: UpdateStatePayload,
};
type ReceiveSelectedListsAction = {
  type: 'journey-audience-old/receive-selected-lists',
  payload: Map<string, $Shape<List>>,
};
type SetSelectedListsAction = {
  type: 'journey-audience-old/set-selected-lists',
  payload: Map<string, $Shape<List>>,
};
type ResetStateAction = {
  type: 'journey-audience-old/reset',
};
type GotoStepAction = {
  type: 'journey-audience-old/goto-step',
  payload: AudienceFormSteps,
};

export type JourneyAudienceAction =
  | UpdateStateAction
  | ReceiveSelectedListsAction
  | SetSelectedListsAction
  | ResetStateAction
  | GotoStepAction;

export const updateState = (
  payload: UpdateStatePayload,
): UpdateStateAction => ({
  type: UPDATE_STATE,
  payload,
});

export const receiveSelectedLists = (
  payload: Map<string, $Shape<List>>,
): ReceiveSelectedListsAction => ({
  type: RECEIVE_SELECTED_LISTS,
  payload,
});
export const setSelectedLists = (
  payload: Map<string, $Shape<List>>,
): SetSelectedListsAction => ({
  type: SET_SELECTED_LISTS,
  payload,
});
export const reset = (): ResetStateAction => ({type: RESET});
export const goToStep = (step: AudienceFormSteps): GotoStepAction => ({
  type: GOTO_STEP,
  payload: step,
});
export const linkAudienceListsToJourney: (
  journeyId: string,
) => ThunkAction<mixed> = (journeyId) => async (dispatch, getState) => {
  const listIds = [...getState().journeyAudienceOld.selectedLists.keys()];
  AnalyticsService.track(ENGAGE.JOURNEY_LIST_ADDED, {
    workflow_id: journeyId,
    list_addition_method: 'existing_list',
    audience_filter_ids: listIds,
  });
  await dispatch(
    reduxApi.post(`workflows/${journeyId}/audience-filters`, {
      audience_filter_ids: listIds,
    }),
  );
  return listIds;
};
export const updateSelectedLists: (
  listDetails: Map<string, $Shape<List>>,
) => ReceiveSelectedListsAction = (listDetails) =>
  receiveSelectedLists(listDetails);

export const getJourneyAudience: (journeyId: string) => ThunkAction<mixed> =
  flow(
    key((journeyId) => `journeyAudienceOld/get-${journeyId}`),
    fetching(),
  )((journeyId) => async (dispatch) => {
    const payload = await dispatch(
      reduxApi.get(`workflows/${journeyId}/audience-filters`),
    );
    const selectedLists = new Map(
      (payload ?? []).map((list) => [list.id, list]),
    );
    dispatch(setSelectedLists(selectedLists));
    return payload;
  });

export const createAudienceListAndLink: (
  journeyId: string,
  fieldsToTypes: {
    [string]: string,
  },
) => ThunkAction<$Shape<ListApiResponse>> =
  (journeyId, fieldsToTypes) => (dispatch, getState) => {
    const audienceList = getState().audienceListOld;
    const {
      name,
      entity_type,
      target,
      life_cycle_goal,
      business_goal,
      all,
      rule_creation_type,
    } = audienceList;
    const filter = normalizeFilterRule(audienceList.rules, all, fieldsToTypes);
    //create
    const apiPayload = {
      name,
      filter,
      entity_type,
      target,
      life_cycle_goal,
      business_goal,
    };
    AnalyticsService.track(ENGAGE.JOURNEY_LIST_ADDED, {
      ...apiPayload,
      workflow_id: journeyId,
      list_addition_method: 'new_list',
      rule_creation_method: rule_creation_type,
    });
    return dispatch(
      reduxApi.post(
        `workflows/${journeyId}/internal_audience-filter`,
        apiPayload,
      ),
    ).then((payload) => payload);
  };
