// @flow
import Store from './base';


export default class StreakStatStore extends Store {
  constructor() {
    super('streak-stats');
    this.state = {};
  }

  get(key: string): Object {
    return this.state[key];
  }
}
