// @noflow

import type {
  Categories,
  CategoriesFilter,
  UserContentSubscriptionsExternal,
  UserContentSubscriptions,
} from 'src/types/content-subscription';
import type {ApiBrandingSettings} from 'src/types/events';
import type {ContentSubscriptionAction} from 'src/action-creators/content-subscription';

import {
  CategoryReceiveAll,
  CategoryApplyFilter,
  CategoryReceiveDetails,
  UserReceiveSubscriptions,
  UserReceiveSubscriptionsExternal,
  UserReceiveSubscriptionsExternalV2,
} from 'src/action-creators/content-subscription';
import {
  applyFilters,
  CATEGORY_STATUS_ACTIVE,
  CATEGORY_STATUS_INACTIVE,
} from 'src/utils/content-subscription';


export type ContentSubscriptionState = {
  categories: Categories,
  filteredCategories: Categories,
  categoryDetails: {[string]: Category},
  filters: CategoriesFilter,
  userContentSubscriptionsExternal: {
    [string]: UserContentSubscriptionsExternal,
  },
  userContentSubscriptions: {[string]: UserContentSubscriptions},
  brandingSettings: ApiBrandingSettings,
  isDebug: boolean,
};

const initialState: ContentSubscriptionState = {
  categories: [],
  filteredCategories: [],
  categoryDetails: {},
  userContentSubscriptions: {
    email: [],
    sms: [],
  },
  userContentSubscriptionsExternal: {},
  filters: {
    status: {
      [CATEGORY_STATUS_ACTIVE]: true,
      [CATEGORY_STATUS_INACTIVE]: false,
    },
  },
  brandingSettings: {},
  isDebug: false,
};

export default (
  state: ContentSubscriptionState = initialState,
  action: ContentSubscriptionAction,
): ContentSubscriptionState => {
  const {type, payload} = action;

  switch (type) {
    case CategoryReceiveAll: {
      return {
        ...state,
        // todo(nilarnab): apply filters here?
        categories: [...payload],
      };
    }
    case CategoryReceiveDetails: {
      const categoryDetails = {...state.categoryDetails};
      categoryDetails[payload.id] = payload;
      return {
        ...state,
        categoryDetails,
      };
    }
    case CategoryApplyFilter: {
      const newFilters = {
        ...state.filters,
        searchQuery: payload.searchQuery ?? state.filters.searchQuery,
        status: {
          ...state.filters.status,
          ...payload.status,
        },
      };
      return {
        ...state,
        filters: newFilters,
        filteredCategories: applyFilters([...state.categories], newFilters),
      };
    }
    case UserReceiveSubscriptions: {
      return {
        ...state,
        userContentSubscriptions: {
          ...state.userContentSubscriptions,
          [payload.entityId]: {
            email: [...(payload.userContentSubscriptions.email ?? [])],
            sms: [...(payload.userContentSubscriptions.sms ?? [])],
          },
        },
      };
    }
    case UserReceiveSubscriptionsExternal: {
      return {
        ...state,
        userContentSubscriptionsExternal: {
          ...state.userContentSubscriptionsExternal,
          [payload.entityId]: [...payload.userContentSubscriptionsExternal],
        },
      };
    }
    case UserReceiveSubscriptionsExternalV2: {
      return {
        ...state,
        brandingSettings:
          payload.userContentSubscriptionsExternal.branding_settings,
        isDebug: payload.userContentSubscriptionsExternal.is_debug,
        userContentSubscriptionsExternal: {
          ...state.userContentSubscriptionsExternal,
          [payload.trackingId]: [
            ...payload.userContentSubscriptionsExternal.subscriptions,
          ],
        },
      };
    }
  }
  return state;
};
