// @flow

import type {SenseAction} from 'src/action-creators/types';

import type {QueueBasedInbox} from 'src/types/chat/queues';

import {
  RECEIVE_QUEUE,
  RECEIVE_QUEUE_BASED_INBOXES,
  UPDATE_QUEUE_JOIN_LOAD_STATE,
  UPDATE_QUEUE_BASED_INBOXES,
  RECEIVE_QUEUE_NOTIFICATION_ID,
} from 'src/action-creators/chat/queues';


export type State = {
  queue_based_inboxes: {
    [id: string]: QueueBasedInbox,
  },
  queues: {...},
  isLoading: boolean,
  notificationId: string,
};

const initialState = {
  queue_based_inboxes: {},
  queues: {},
  isLoading: false,
  notificationId: '',
};

export default function reduce(
  state: State = initialState,
  action: SenseAction,
): State {
  switch (action.type) {
    case RECEIVE_QUEUE_BASED_INBOXES: {
      const queues = action?.payload?.queue_based_inboxes || [];
      const nextInboxes = {};
      for (const queue of queues) {
        nextInboxes[queue.id] = queue;
      }

      return {
        ...state,
        queue_based_inboxes: nextInboxes,
      };
    }

    case RECEIVE_QUEUE: {
      const queueId = action.payload.id;
      const nextQueues = {
        ...state.queues,
        [queueId]: action.payload,
      };

      return {
        ...state,
        queues: nextQueues,
      };
    }

    case UPDATE_QUEUE_BASED_INBOXES: {
      const queueId = action.payload.id;
      return {
        ...state,
        queue_based_inboxes: {
          ...state.queue_based_inboxes,
          [queueId]: action.payload,
        },
      };
    }

    case UPDATE_QUEUE_JOIN_LOAD_STATE: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case RECEIVE_QUEUE_NOTIFICATION_ID: {
      return {
        ...state,
        notificationId: action.payload,
      };
    }
  }

  return state;
}
