// @noflow

import * as React from 'react';

import {classify} from 'src/utils';

import css from './connector.css';


const Connector = ({children, className, invisible}: {
  children?: any,
  className?: string,
  invisible?: any,
}) => (
  <div className={classify(css.connector, {[css.invisible]: invisible}, className)}>{children}</div>
);

export default Connector;
