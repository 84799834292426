// @flow

import type {Dispatch, GetState} from 'src/reducers';
import type {TargetEntity} from 'src/types/target-entity-types';

import * as React from 'react';
import 'redux';
import {useDispatch, useSelector} from 'react-redux';
import invariant from 'invariant';

import {getCurrentWorkflowEntityType} from 'src/selectors/workflow.js';
import {
  getEntityTypeMappings,
  selectCandidateEntityType,
} from 'src/selectors/ats-entities';
import {
  getSearchableEntity,
  getSearchableEntityFromStandardEntities,
} from 'src/utils/entities';
import {useApi} from 'src/hooks/useApi';

import {
  fetchPersonRelatedEntities,
  getEntityVariableValues,
} from 'src/action-creators/audience-members/multientity';

import {selectRelatedEntitiesAsSummaries} from 'src/selectors/multientity-audience';
import {selectEntityMappingsByEntityType} from 'src/selectors/target-entity-types';


const getPlacementsAction =
  (audienceMemberId?: string, entityType?: string, personEntityType: string) =>
  async (dispatch: Dispatch, getState: GetState) => {
    // TODO (kyle): verify that this will work with just the related entityType
    await dispatch(
      fetchPersonRelatedEntities(
        // $FlowFixMe not sure why we're allowing undefined here?
        audienceMemberId,
        // $FlowFixMe
        entityType,
        // $FlowFixMe
        personEntityType,
      ),
    );

    // $FlowFixMe not sure why we're allowing undefined here?
    return selectRelatedEntitiesAsSummaries(
      getState(),
      audienceMemberId,
      entityType,
    );
  };

export function useMockedEntities(
  audienceMemberId: string | null,
  selectedPlacementId: string | null,
  entityType: string,
): {loading: boolean, mockedEntityId: ?string, relatedEntities: void} {
  const workflowEntityType = useSelector(getCurrentWorkflowEntityType);
  const dispatch = useDispatch();

  const getEntityVarValues = React.useCallback(
    (...args) => dispatch(getEntityVariableValues(...args)),
    [dispatch],
  );
  const getMEPlacements = React.useCallback(
    (audienceMemberId: string, entityType: string, personEntityType: string) =>
      dispatch(
        getPlacementsAction(audienceMemberId, entityType, personEntityType),
      ),
    [dispatch],
  );

  const atsEntityMappings = useSelector(getEntityTypeMappings);
  const entityMapping = atsEntityMappings[entityType];
  const workflowEntityInfo = useSelector(getCurrentWorkflowEntityType);
  const searchableEntityType = getSearchableEntity(
    atsEntityMappings,
    workflowEntityInfo,
  )?.name;

  async function getMeRelatedEntities(
    audienceMemberId: string,
    entityType: string,
  ) {
    invariant(
      searchableEntityType,
      'Called getMeRelatedEntities without an searchableEntityType.',
    );

    const relatedEntities = await getMEPlacements(
      audienceMemberId,
      entityType,
      searchableEntityType,
    );

    //fetch the entity variables for the first entity (default selected in dropdown)
    if (relatedEntities?.length) {
      await getEntityVarValues(relatedEntities[0].id, workflowEntityType?.name);
    }
    const selectedEntityId = relatedEntities.length
      ? relatedEntities[0].id
      : null;
    return [relatedEntities, selectedEntityId];
  }

  const [mockedEntityId, setMockedEntityId] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [relatedEntities, setRelatedEntities] = React.useState();

  React.useEffect(() => {
    // don't run if no audienceMemberId
    if (!audienceMemberId) {
      return;
    }
    setLoading(true);
    setMockedEntityId(audienceMemberId);

    if (entityMapping?.is_person) {
      getEntityVarValues(audienceMemberId, workflowEntityType?.name).then(
        () => {
          setMockedEntityId(audienceMemberId);
          setLoading(false);
        },
      );
    } else {
      getMeRelatedEntities(audienceMemberId, entityType).then(
        ([relatedEntities, selectedEntityId]) => {
          setRelatedEntities(relatedEntities);
          setMockedEntityId(selectedEntityId);
          setLoading(false);
        },
      );
    }
  }, [audienceMemberId, entityType]);

  React.useEffect(() => {
    if (selectedPlacementId) {
      setMockedEntityId(selectedPlacementId);
      getEntityVarValues(selectedPlacementId, workflowEntityType?.name);
    }
  }, [selectedPlacementId]);

  return {loading, mockedEntityId, relatedEntities};
}

/** this function hits the api for candidate typeahead search,
 * gets the list of candidates and returns first candidate from
 * that list.
 */
export const useCandidateForEntity = (
  entityType: string,
): ?{id: string, ...} => {
  const candidateEntityType = useSelector(selectCandidateEntityType);
  const result = useApi(
    entityType === candidateEntityType
      ? `typeahead/entities/${entityType}?limit=5&offset=0&search_string=`
      : '',
  ).result;
  return result?.entities && result?.entities.length > 0 && result?.entities[0];
};

export const useRandomPersonEntity = (
  entity: TargetEntity,
): ?{[string]: string | number | boolean} => {
  /*Note(Vish): We need to get searchable entity type and pick entity id of related person entity type for non searchable entity type,
   * Otherwise variables won't show for non person entity types from the preview API. This changes also needs to be done in beefree preview.
   * This can be taken up once Backend provides us with the variable value getter API.
   * */
  const entityMappings = useSelector(selectEntityMappingsByEntityType);
  const searchableEntity = getSearchableEntityFromStandardEntities(
    entity,
    entityMappings,
  );
  let url = '';
  if (
    searchableEntity?.entity_type &&
    searchableEntity?.entity_type === entity.entity_type
  ) {
    url = `typeahead/entities/${searchableEntity.entity_type}?limit=5&offset=0&search_string=`;
  }
  const result = useApi(url).result;
  if (url === '') {
    return {id: 'simulate'};
  }
  return result && result?.entities && result?.entities.length > 0
    ? result.entities[0]
    : null;
};
