// @flow strict

import * as React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import type {
  ReferralBonusAndPayoutSettings,
  ReferralSettingApiRequest,
  ReferralSetting,
  PayoutRuleData,
} from 'src/types/referral-v2';
import {
  INITIAL_REFERRAL_SETTINGS,
  radioSelectOption,
  radioChoiceOption,
  INPUT_TEXT_LIMIT,
} from 'src/utils/referral-v2.js';
//$FlowFixMe[nonstrict-import]
import {AnalyticsService} from 'src/analytics';
import {validatePayoutRule} from 'src/components/referral-v2/common.js';
import classify from 'src/utils/classify';
import {selectProgramFormData} from 'src/selectors/referral-v2';
import {updateProgramDetailsFormData} from 'src/action-creators/referral-v2/programs.js';
import {updateOneTimeSettings} from 'src/action-creators/referral-v2/referral-settings.js';
import {SubTitleSmall} from '@spaced-out/ui-design-system/lib/components/Text';
import {
  Card,
  CardHeader,
  CardContent,
} from '@spaced-out/ui-design-system/lib/components/Card';
import {InContextAlert} from '@spaced-out/ui-design-system/lib/components/InContextAlert';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {Input} from '@spaced-out/ui-design-system/lib/components/Input';
import {SingleSelectDropdown} from 'src/components/referral-job-list/settings/one-time-settings.jsx';
import PayoutRule from 'src/components/referral-v2/payout-rule/payout-rule.jsx';
import css from 'src/components/referral-v2/configure/configure.css';


const ReferralAdvanceSettings = ({
  referralSettings,
  hasEditPermission,
  isOneTimeSettingDisabled,
  bonusAndPayoutData,
  handleSave,
}: {
  referralSettings: ?ReferralSetting,
  bonusAndPayoutData?: ReferralBonusAndPayoutSettings,
  hasEditPermission: boolean,
  isOneTimeSettingDisabled: boolean,
  handleSave?: (data: Array<ReferralSettingApiRequest>) => Promise<mixed>,
}): React.Node => {
  const dispatch = useDispatch();

  const [bonusAndPayoutSetting, setBonusAndPayoutSetting] =
    React.useState<ReferralBonusAndPayoutSettings>(
      INITIAL_REFERRAL_SETTINGS.bonus_and_payout_settings,
    );

  const oneTimeSettings =
    referralSettings?.one_time_settings ??
    INITIAL_REFERRAL_SETTINGS.one_time_settings;

  const programDetailsFormData = useSelector(selectProgramFormData);
  const payoutRules: PayoutRuleData =
    useSelector(
      (state) =>
        state?.referralSettings?.referral_settings?.one_time_settings
          ?.payout_rule,
    ) || [];

  const handleChange = (category: string, fieldName: string, value: mixed) => {
    if (category === 'one_time_settings') {
      dispatch(updateOneTimeSettings({...oneTimeSettings, [fieldName]: value}));
      return;
    } else if (
      typeof value === 'string' &&
      value.length <= INPUT_TEXT_LIMIT.number
    ) {
      if (isOneTimeSettingDisabled) {
        setBonusAndPayoutSetting((prevState) => ({
          ...prevState,
          [fieldName]: parseInt(value),
        }));
        return;
      }
      return dispatch(
        updateProgramDetailsFormData({
          ...programDetailsFormData,
          bonus_and_payout_settings: {
            ...bonusAndPayoutSetting,
            [fieldName]: value,
          },
        }),
      );
    }
  };

  const isAllRequiredQuestionsAnswered = () => {
    const requiredField = {...oneTimeSettings, ...bonusAndPayoutSetting};
    const isError = Object.keys({...requiredField}).some((field) => {
      const value = requiredField[field];
      return (
        value === null ||
        value === '' ||
        Number.isNaN(value) ||
        (!Number.isNaN(value) && parseInt(value, 10) <= 0)
      );
    });
    return !isError && validatePayoutRule(payoutRules);
  };

  const handleSaveDetails = async () => {
    const payload: ReferralSettingApiRequest[] = [
      {
        category: 'bonus_and_payout_settings',
        configs: Object.keys(bonusAndPayoutSetting).map((key: string) => ({
          key,
          value: bonusAndPayoutSetting[key],
        })),
      },
    ];
    AnalyticsService.track('Primary contact', {
      duplicateProfileCheckMethod:
        oneTimeSettings.duplicate_profile_check_method,
    });
    handleSave?.(payload);
  };

  React.useEffect(() => {
    if (referralSettings && !bonusAndPayoutData) {
      setBonusAndPayoutSetting(
        referralSettings.bonus_and_payout_settings
          ? referralSettings.bonus_and_payout_settings
          : bonusAndPayoutSetting,
      );
    }
  }, [referralSettings]);

  React.useEffect(() => {
    if (bonusAndPayoutData) {
      setBonusAndPayoutSetting(bonusAndPayoutData);
    }
  }, [bonusAndPayoutData]);

  React.useEffect(() => {
    isAllRequiredQuestionsAnswered();
  }, [payoutRules]);

  return (
    <Card
      type="primary"
      classNames={{
        wrapper: classify(css.flexColumn, css.defaultGap, css.defaultMargin, {
          [css.customCard]: !isOneTimeSettingDisabled,
        }),
      }}
    >
      <CardHeader className={css.stepperCardHeader}>
        <SubTitleSmall>Settings</SubTitleSmall>
        {isOneTimeSettingDisabled && (
          <Button
            size="small"
            type="primary"
            onClick={handleSaveDetails}
            disabled={!hasEditPermission || !isAllRequiredQuestionsAnswered()}
          >
            Save changes
          </Button>
        )}
      </CardHeader>
      <CardContent className={css.contentSection}>
        <SubTitleSmall className={css.defaultMargin}>
          Bonus & Payout Settings
        </SubTitleSmall>
        <div className={css.defaultMargin}>
          <Input
            placeholder="Enter amount"
            label="Bonus Amount"
            type="number"
            value={bonusAndPayoutSetting.default_bonus_amount?.toString() || ''}
            onChange={(e) => {
              handleChange(
                'bonus_and_payout',
                'default_bonus_amount',
                e.target.value,
              );
            }}
            disabled={!hasEditPermission}
          />
        </div>
        <div className={css.defaultMargin}>
          <Input
            label={`Bonus Payout Period`}
            placeholder={`Enter bonus payout period (${oneTimeSettings.timesheet_log_type})`}
            type="number"
            value={
              bonusAndPayoutSetting.default_bonus_payout_period?.toString() ||
              ''
            }
            onChange={(e) => {
              handleChange(
                'bonus_and_payout',
                'default_bonus_payout_period',
                e.target.value,
              );
            }}
            disabled={!hasEditPermission}
          />
        </div>
        <div>
          <Input
            label={`Referral Validity`}
            placeholder={`Enter bonus validity (days)`}
            type="number"
            value={
              bonusAndPayoutSetting.default_referral_validity?.toString() || ''
            }
            onChange={(e) => {
              handleChange(
                'bonus_and_payout',
                'default_referral_validity',
                e.target.value,
              );
            }}
            disabled={!hasEditPermission}
          />
        </div>
      </CardContent>
      <div className={css.divider} />
      <CardContent className={css.contentSection}>
        <SubTitleSmall className={css.defaultMargin}>
          One Time Settings
        </SubTitleSmall>
        <InContextAlert
          semantic="information"
          leftIconName="circle-info"
          classNames={{wrapper: css.defaultMargin}}
        >
          One-time settings will be locked upon saving the changes. You would
          need to contact support to make any further changes.
        </InContextAlert>
        <PayoutRule disabled={isOneTimeSettingDisabled || !hasEditPermission} />
        <div className={css.defaultMargin}>
          <SingleSelectDropdown
            value={oneTimeSettings.duplicate_profile_check_method}
            options={radioSelectOption}
            onChangeOption={(value) => {
              handleChange(
                'one_time_settings',
                'duplicate_profile_check_method',
                value,
              );
            }}
            disabled={isOneTimeSettingDisabled || !hasEditPermission}
            label="What will be used as the duplicate check for new referrals in the ATS?"
          />
        </div>
        <div className={css.defaultMargin}>
          <SingleSelectDropdown
            value={oneTimeSettings.reward_for_existing_referral?.toLocaleString()}
            onChangeOption={(value) => {
              const valueChanged = value === 'true' ? true : false;
              handleChange(
                'one_time_settings',
                'reward_for_existing_referral',
                valueChanged,
              );
            }}
            options={radioChoiceOption}
            label="Do you want to allow referral submission if the candidate is already exists in your database?"
            disabled={isOneTimeSettingDisabled || !hasEditPermission}
          />
        </div>
        <div className={css.defaultMargin}>
          <SingleSelectDropdown
            value={oneTimeSettings.should_pay_for_different_role.toLocaleString()}
            onChangeOption={(value) => {
              const valueChanged = value === 'true' ? true : false;
              handleChange(
                'one_time_settings',
                'should_pay_for_different_role',
                valueChanged,
              );
            }}
            options={radioChoiceOption}
            label="Is a referral eligible for payout if hired for a different job role?"
            disabled={isOneTimeSettingDisabled || !hasEditPermission}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default ReferralAdvanceSettings;
