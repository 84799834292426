// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, GetState, ThunkAction} from 'src/reducers';
import type {FilterBlock, FilterRule, FilterScope} from 'src/types/filter';
import {normalizeFilter, parseFilter} from 'src/utils/filter';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';
import {getAutomationWorkflow} from 'src/action-creators/automation-workflow';
import type {ABTVariables} from 'src/components/lib/automation-module-registry/automation-module-registry';


export type ReceiveFilterPayload = {
  id: number,
  blocks: Map<string, FilterBlock>,
  nextNode: string,
};
export const RECEIVE_FILTER = 'filter/receive';
export const RESET = 'filter/reset';
export const ADD_BLOCK = 'filter/add-block';
export const REMOVE_BLOCK = 'filter/remove-block';
export const CLONE_BLOCK = 'filter/clone-block';
export const SET_RULES = 'filter/set-rules';

export const SET_SCOPE = 'filter/set-scope';

type ReceiveFilterAction = {
  type: 'filter/receive',
  payload: ReceiveFilterPayload,
};

type ResetStateAction = {
  type: 'filter/reset',
};

type AddBlockAction = {
  type: 'filter/add-block',
};

type CloneBlockAction = {
  type: 'filter/clone-block',
  payload: string,
};

type RemoveBlockAction = {
  type: 'filter/remove-block',
  payload: string,
};

type SetRulesAction = {
  type: 'filter/set-rules',
  payload: {blockId: string, rules: FilterRule[]},
};

type SetScopeAction = {
  type: 'filter/set-scope',
  payload: {blockId: string, scope: ?FilterScope},
};

export type FilterAction =
  | ReceiveFilterAction
  | ResetStateAction
  | SetRulesAction
  | RemoveBlockAction
  | CloneBlockAction
  | AddBlockAction
  | SetScopeAction;

export const receiveFilter = (
  payload: ReceiveFilterPayload,
): ReceiveFilterAction => ({
  type: RECEIVE_FILTER,
  payload,
});

export const resetFilter = (): ResetStateAction => ({
  type: RESET,
});

export const addBlock = (): AddBlockAction => ({
  type: ADD_BLOCK,
});

export const removeBlock = (blockId: string): RemoveBlockAction => ({
  type: REMOVE_BLOCK,
  payload: blockId,
});

export const cloneBlock = (blockId: string): CloneBlockAction => ({
  type: CLONE_BLOCK,
  payload: blockId,
});

export const setRules = (
  blockId: string,
  rules: FilterRule[],
): SetRulesAction => ({
  type: SET_RULES,
  payload: {blockId, rules},
});

export const setScope = (
  blockId: string,
  scope: ?FilterScope,
): SetScopeAction => ({
  type: SET_SCOPE,
  payload: {blockId, scope},
});

export const getFilter: (
  id: string,
  transitionId: number,
  swid: number,
) => ThunkAction<mixed> | mixed =
  (id, transitionId, swid) => (dispatch: Dispatch, getState) => {
    const filterData = getState().automationWorkflow.swimlanes.find(
      (swimlane) => swimlane.id === swid,
    ).nodes[id];
    if (filterData) {
      const allParsedBlocks = parseFilter(filterData);
      const currentFilter = allParsedBlocks.find(
        (block) => block.id === transitionId,
      );
      if (currentFilter) {
        return dispatch(receiveFilter(currentFilter));
      }
    }
  };

export const saveFilter: ({
  from_node_id: string,
  to_node_id: ?string,
  workflow_id: string,
  position: ?string,
}) => ThunkAction<mixed> =
  ({from_node_id, to_node_id, workflow_id, position}) =>
  (dispatch: Dispatch, getState: GetState) => {
    const normalizedFilter = normalizeFilter(getState().filter);
    const payload = {
      // TODO[Vishwanath] : fix below error introduced in 0.177.0
      // $FlowFixMe[not-an-object]
      ...(position && {position}),
      from_node_id,
      to_node_id: to_node_id ?? null,
      ...normalizedFilter,
    };
    return dispatch(reduxApi.put(`node/transition`, payload)).then(() =>
      dispatch(getAutomationWorkflow(workflow_id)),
    );
  };

export const createNewFilter: ({
  from_node_id: string,
  to_node_id: string,
  swimlane_id: string,
  workflow_id: string,
  position: string,
}) => ThunkAction<mixed> =
  ({from_node_id, to_node_id, swimlane_id, workflow_id, position = 'right'}) =>
  async (dispatch: Dispatch) => {
    const payload = {
      node_type: 'branching',
      swimlane_id: parseInt(swimlane_id, 10),
      workflow_id: parseInt(workflow_id, 10),
      body: {
        branching_node_type: 'FILTER',
      },
    };
    const res = await dispatch(
      reduxApi.post('node', payload, {
        previous_node: from_node_id,
        next_node: to_node_id,
      }),
    );
    if (res && Array.isArray(res) && res.length > 0) {
      return dispatch(
        saveFilter({
          from_node_id: `branching:${res[0].id}`,
          to_node_id,
          workflow_id,
          position,
        }),
      );
    }
  };
