// @noflow

import * as React from 'react';


export default <InputProps: {}, MappedProps: {}>(
  mapper: InputProps => MappedProps,
) => (WrappedComponent: React.ComponentType<MappedProps>) => (
  props: InputProps,
) => <WrappedComponent {...mapper(props)} />;
