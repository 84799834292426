// @flow

import * as React from 'react';
import Envelope from 'src/images/dashboard/envelope.svg';

import css from './dashboard.css';


function EmptyDashboard({agentName}: {agentName: string}): React.Node {
  return (
    <div className={css.emptyDashboard} data-qa-id="dashboard">
      <div className={css.emptyDashboardContent} data-it-dashboard-empty>
        <h1 className={css.emptyDashboardGreeting}>Hi, {agentName}</h1>
        <Envelope className={css.envelope} />
        <h2 className={css.emptyDashboardSubheader}>Welcome to Sense</h2>
        <p className={css.emptyDashboardIntro}>
          Start browsing through the navigation to the left.
        </p>
      </div>
    </div>
  );
}

export default EmptyDashboard;
