// @flow

import type {
  ApiBroadcastNew,
  ApiBroadcast,
  Broadcast,
  BroadcastNew,
  BroadcastForm,
  BroadcastBranch,
} from 'src/types/broadcasts';
import type {ErrorMap} from 'src/types/redux';
import type {
  GetState,
  Dispatch,
  ThunkAction,
  ThunkSyncAction,
} from 'src/reducers';

import invariant from 'invariant';
import Backoff from 'backo';
import moment from 'moment';

import logger from 'src/utils/logger';

import {defined, snake, camel} from 'src/utils';
import {thunkify as flow} from 'src/utils/thunks';
import {BROADCAST_STATUS_SCHEDULED} from 'src/types/broadcasts';
import {isProcessing} from 'src/utils/broadcasts';
import {key, cached, fetching} from 'src/utils/redux';
import {
  selectPhoneNumberSetFromDefaultPhoneNumberSet,
  selectDefaultPhoneNumberSet,
} from 'src/selectors/chat';
import {selectPopulatedBroadcast} from 'src/selectors/broadcasts';
import {
  selectMessageOrigin,
  selectDiscoverCandidateDetails,
} from 'src/selectors/draft-messages';
import * as reduxApi from 'src/utils/redux-api-v2';


export const RECEIVE_BROADCASTS = 'broadcasts/receiveBroadcasts';
export const RECEIVE_BROADCASTS_PAGINATED =
  'broadcasts/receiveBroadcastsPaginated';
export const RECEIVE_NEW_BROADCAST = 'broadcasts/receiveNewBroadcast';
export const RECEIVE_BROADCAST = 'broadcasts/receiveBroadcast';
export const RECEIVE_SCHEDULED_MESSAGE = 'broadcasts/receiveScheduledMessage';
export const RECEIVE_BROADCAST_BRANCHES = 'broadcasts/receiveBroadcastBranches';
export const CHANGE_BROADCAST_FORM = 'broadcasts/changeBroadcastForm';
export const RECEIVE_BROADCAST_FORM_ERRORS = 'broadcasts/receiveFormErrors';
export const RESET_BROADCAST_FORM = 'broadcasts/resetBroadcastForm';
export const REMOVE_BROADCAST = 'broadcasts/remove';
export const REMOVE_SCHEDULED_MESSAGE = 'broadcasts/removeScheduledMessage';
export const MARK_BROADCAST_PROCESSED = 'broadcasts/markProcessed';

export type Action =
  | ReceiveBroadcastsAction
  | ReceiveNewBroadcastAction
  | ReceiveBroadcastsPaginatedAction
  | ReceiveBroadcastAction
  | ReceiveBroadcastBranchesAction
  | ChangeBroadcastFormAction
  | ReceiveFormErrors
  | ResetBroadcastFormAction
  | RemoveBroadcastaAction
  | MarkBroadcastProcessed
  | ReceiveScheduledMessageAction
  | RemoveScheduledMessageAction;

type ReceiveBroadcastsAction = {
  type: 'broadcasts/receiveBroadcasts',
  payload: ApiBroadcast[],
};

type ReceiveBroadcastsPaginatedAction = {
  type: 'broadcasts/receiveBroadcastsPaginated',
  payload: {
    broadcasts: ApiBroadcast[],
    offset: number,
    limit: number,
    phone_number_set_id: string,
  },
};

export const receiveBroadcasts = (
  broadcasts: ApiBroadcast[],
): ReceiveBroadcastsAction => ({
  type: RECEIVE_BROADCASTS,
  payload: broadcasts,
});

export const receiveBroadcastsPaginated = (
  broadcasts: ApiBroadcast[],
  offset: number,
  limit: number,
  phone_number_set_id: string,
): ReceiveBroadcastsPaginatedAction => ({
  type: RECEIVE_BROADCASTS_PAGINATED,
  payload: {broadcasts, offset, limit, phone_number_set_id},
});

type ReceiveNewBroadcastAction = {
  type: 'broadcasts/receiveNewBroadcast',
  payload: Broadcast,
};

export const receiveNewBroadcast = (
  broadcast: Broadcast,
): ReceiveNewBroadcastAction => ({
  type: RECEIVE_NEW_BROADCAST,
  payload: broadcast,
});

type ReceiveBroadcastAction = {
  type: 'broadcasts/receiveBroadcast',
  payload: ApiBroadcast,
};

type ReceiveScheduledMessageAction = {
  type: 'broadcasts/receiveScheduledMessage',
  payload: ApiBroadcast,
};

export const receiveBroadcast = (
  broadcast: ApiBroadcast,
): ReceiveBroadcastAction => ({
  type: RECEIVE_BROADCAST,
  payload: broadcast,
});

export const receiveScheduledMessage = (
  scheduledMessage: ApiBroadcast,
): ReceiveScheduledMessageAction => ({
  type: RECEIVE_SCHEDULED_MESSAGE,
  payload: scheduledMessage,
});

type ReceiveBroadcastBranchesAction = {
  type: 'broadcasts/receiveBroadcastBranches',
  payload: Array<BroadcastBranch>,
};

export const receiveBroadcastBranches = (
  broadcastBranches: Array<BroadcastBranch>,
): ReceiveBroadcastBranchesAction => ({
  type: RECEIVE_BROADCAST_BRANCHES,
  payload: broadcastBranches,
});

type ChangeBroadcastFormAction = {
  type: 'broadcasts/changeBroadcastForm',
  payload: BroadcastForm,
};

export const changeBroadcastForm = (
  props: BroadcastForm,
): ChangeBroadcastFormAction => ({
  type: CHANGE_BROADCAST_FORM,
  payload: props,
});

type ResetBroadcastFormAction = {
  type: 'broadcasts/resetBroadcastForm',
  payload: ?BroadcastForm,
};

export const resetBroadcastForm = (
  form: ?BroadcastForm,
): ResetBroadcastFormAction => ({
  type: RESET_BROADCAST_FORM,
  payload: form,
});

export const setDefaultBroadcastForm = (
  threadListId?: string,
): ResetBroadcastFormAction =>
  resetBroadcastForm({
    threadListId,
    sendDate: moment().add(1, 'day').hour(0).minute(0).toJSON(),
  });

export const getBroadcasts: () => ThunkAction<mixed> = flow(
  key(() => 'getBroadcasts'),
  cached((response) => receiveBroadcasts(camel(response))),
  fetching({}),
)(() => reduxApi.get('messages_v2/broadcasts'));

export const getBroadcastsPaginated: ({
  offset: number,
  limit: number,
  phone_number_set_id: string,
}) => ThunkAction<mixed> = flow(
  key(
    ({offset, limit, phone_number_set_id}) =>
      `getBroadcasts:${offset},${limit || ''},${phone_number_set_id || ''}`,
  ),
  cached((response, {offset, limit, phone_number_set_id}) =>
    receiveBroadcastsPaginated(
      camel(response),
      offset,
      limit,
      phone_number_set_id,
    ),
  ),
  fetching({}),
)(
  ({
    offset,
    limit,
    phone_number_set_id,
  }: {
    offset: number,
    limit: number,
    phone_number_set_id: string,
  }) =>
    reduxApi.get('messages_v2/broadcasts', {
      offset,
      limit,
      phone_number_set_id,
    }),
);

export const getMoreBroadcasts =
  (limit: number = 50): ThunkAction<mixed> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const {offsets} = getState().broadcasts;
    const phone_number_set_id = selectPhoneNumberSetFromDefaultPhoneNumberSet(
      getState(),
    );

    await dispatch(
      getBroadcastsPaginated({
        offset: offsets[phone_number_set_id],
        limit,
        phone_number_set_id,
      }),
    );
  };

export const getBroadcast: (id: string) => ThunkAction<mixed> = flow(
  key((id) => `getBroadcast:${id}`),
  cached((response) => receiveBroadcast(camel(response))),
  fetching(),
)((id: string) => reduxApi.get(`messages_v2/broadcast/${id}`));

export const getBroadcastBranches: (id: string) => ThunkAction<mixed> = flow(
  key((id) => `getBroadcastBranches:${id}`),
  cached((response) => receiveBroadcastBranches(camel(response))),
  fetching(),
)((id: string) => reduxApi.get(`messages_v2/broadcast/${id}/branches`));

export const createBroadcast: (
  draft: BroadcastNew,
  ignorePhoneNumberList?: Array<string>,
) => ThunkAction<ApiBroadcast> = flow(
  key(() => 'createBroadcast'),
  fetching(),
)(
  (draft: BroadcastNew, ignorePhoneNumberList?: Array<string> = []) =>
    async (dispatch: Dispatch, getState: GetState) => {
      const origin = selectMessageOrigin(getState());
      const discoverCandidateDetails = selectDiscoverCandidateDetails(
        getState(),
      );
      const phoneNumberSetId = selectPhoneNumberSetFromDefaultPhoneNumberSet(
        getState(),
      );
      const apiDraft: ApiBroadcastNew = snake(
        defined({
          templateId: draft.templateId,
          schedulerTemplateId: draft.schedulerTemplateId,
          body: draft.body,
          phoneNumberSetId,
          phoneNumbers: draft.phoneNumbers,
          threadListId: draft.threadListId,
          send_now: draft.sendNow ? draft.sendNow : undefined,
          send_date: draft.sendNow ? undefined : draft.sendDate,
          placeholders: draft.placeHolders,
          ignore_phone_numbers: ignorePhoneNumberList,
        }),
      );

      const payload = discoverCandidateDetails
        ? {...apiDraft, origin, metadata: discoverCandidateDetails}
        : apiDraft;

      const response = await dispatch(
        reduxApi.post('messages_v2/broadcasts', snake(payload)),
      );

      const broadcast: ApiBroadcast = camel(response);

      if (isProcessing(broadcast)) {
        dispatch(pollForProcessedStatus(broadcast));
      }

      dispatch(receiveBroadcast(broadcast));

      if (broadcast.id && draft.dripMessage) {
        dispatch(
          reduxApi.post(`messages_v2/broadcast/${broadcast.id}/branches`, {
            body: draft.dripMessage,
            condition: 'positive_reply',
          }),
        );
      }

      return broadcast;
    },
);

export const createScheduledMessage: (
  draft: BroadcastNew,
  ignorePhoneNumberList?: Array<string>,
) => ThunkAction<ApiBroadcast> = flow(
  key(() => 'createBroadcast'),
  fetching(),
)(
  (draft: BroadcastNew, ignorePhoneNumberList?: Array<string> = []) =>
    async (dispatch: Dispatch, getState: GetState) => {
      const origin = selectMessageOrigin(getState());
      const discoverCandidateDetails = selectDiscoverCandidateDetails(
        getState(),
      );
      const phoneNumberSetId = selectPhoneNumberSetFromDefaultPhoneNumberSet(
        getState(),
      );
      const apiDraft: ApiBroadcastNew = snake(
        defined({
          templateId: draft.templateId,
          schedulerTemplateId: draft.schedulerTemplateId,
          body: draft.body,
          phoneNumberSetId,
          phoneNumbers: draft.phoneNumbers,
          threadListId: draft.threadListId,
          send_now: draft.sendNow ? draft.sendNow : undefined,
          send_date: draft.sendNow ? undefined : draft.sendDate,
          ignore_phone_numbers: ignorePhoneNumberList,
        }),
      );
      const payload = discoverCandidateDetails
        ? {...apiDraft, origin, metadata: discoverCandidateDetails}
        : apiDraft;
      const response = await dispatch(
        reduxApi.post('messages_v2/broadcasts', snake(payload)),
      );

      const broadcast: ApiBroadcast = camel(response);

      if (isProcessing(broadcast)) {
        dispatch(pollForProcessedStatus(broadcast));
      }

      dispatch(receiveScheduledMessage(broadcast));

      if (broadcast.id && draft.dripMessage) {
        dispatch(
          reduxApi.post(`messages_v2/broadcast/${broadcast.id}/branches`, {
            body: draft.dripMessage,
            condition: 'positive_reply',
          }),
        );
      }

      return broadcast;
    },
);

export const updateBroadcast: ({
  ...BroadcastNew,
  ...
}) => ThunkAction<ApiBroadcast> = flow(
  key(() => 'updateBroadcast'),
  fetching(),
)((draft: {...BroadcastNew, ...}) => async (dispatch: Dispatch) => {
  const apiDraft: ApiBroadcastNew = snake(
    defined({
      body: draft.body,
      send_now: draft.sendNow || undefined,
      send_date: !draft.sendNow ? draft.sendDate : undefined,
    }),
  );

  invariant(draft.id, 'Must have id to update broadcast');

  const response = await dispatch(
    reduxApi.put(`messages_v2/broadcast/${draft.id}`, apiDraft),
  );
  const broadcast: ApiBroadcast = camel(response);

  // TODO (gab): consolidate update/createBroadcast()
  if (isProcessing(broadcast)) {
    dispatch(pollForProcessedStatus(broadcast));
  }

  dispatch(receiveBroadcast(broadcast));
  return broadcast;
});

export const updateScheduledMessage: ({
  ...BroadcastNew,
  ...
}) => ThunkAction<ApiBroadcast> = flow(
  key(() => 'updateBroadcast'),
  fetching(),
)((draft: {...BroadcastNew, ...}) => async (dispatch: Dispatch) => {
  const apiDraft: ApiBroadcastNew = snake(
    defined({
      body: draft.body,
      send_now: draft.sendNow || undefined,
      send_date: !draft.sendNow ? draft.sendDate : undefined,
    }),
  );

  invariant(draft.id, 'Must have id to update broadcast');

  const response = await dispatch(
    reduxApi.put(`messages_v2/broadcast/${draft.id}`, apiDraft),
  );
  const broadcast: ApiBroadcast = camel(response);

  // TODO (gab): consolidate update/createBroadcast()
  if (isProcessing(broadcast)) {
    dispatch(pollForProcessedStatus(broadcast));
  }

  dispatch(receiveScheduledMessage(broadcast));
  dispatch(receiveBroadcast(broadcast));
  return broadcast;
});

const pollForProcessedStatus =
  (broadcast): ThunkSyncAction =>
  (dispatch) => {
    invariant(
      broadcast.status === BROADCAST_STATUS_SCHEDULED,
      'Tried to poll for processed status on already processed broadcast',
    );

    // NOTE(gab): backoff instances are not shared between broadcasts
    // so there is no need to ever reset the backoff instance. Either the
    // broadcast comes out of the scheduled state, or it polls forever.
    const broadcastStatusBackoff = new Backoff({
      min: 10000,
      max: 10 * 60 * 1000,
    });

    const poll = async () => {
      try {
        const response = await dispatch(
          reduxApi.get(`messages_v2/broadcast/${broadcast.id}`),
        );
        if (response.status !== BROADCAST_STATUS_SCHEDULED) {
          const updatedBroadcast: ApiBroadcast = camel(response);
          if (broadcast.oneOnOne) {
            dispatch(receiveScheduledMessage(updatedBroadcast));
          } else {
            dispatch(receiveBroadcast(updatedBroadcast));
          }
        } else {
          setTimeout(poll, broadcastStatusBackoff.duration());
        }
      } catch (error) {
        // Log error, do not keep polling
        if (error) {
          logger.error('Error getting broadcast status', error);
        }
      }
    };

    setTimeout(poll, broadcastStatusBackoff.duration());
  };

export const editBroadcast =
  (id: string): ThunkAction<mixed> =>
  (dispatch, getState) => {
    const info = selectPopulatedBroadcast(getState(), id);

    // TODO(gab): attempt to fetch the broadcast before giving up
    invariant(
      info.broadcast,
      'Attempted to call `editBroadcast` on a nonexistent resource',
    );

    return dispatch(
      resetBroadcastForm({
        ...info.broadcast,
        threadIds: info.threadList?.threadIds || [],
      }),
    );
  };

type RemoveBroadcastaAction = {
  type: 'broadcasts/remove',
  payload: string,
};

type RemoveScheduledMessageAction = {
  type: 'broadcasts/removeScheduledMessage',
  payload: {id: string, threadIds: Array<string>},
};

export const removeBroadcast = (id: string): RemoveBroadcastaAction => ({
  type: REMOVE_BROADCAST,
  payload: id,
});

export const removeScheduledMessage = (
  id: string,
  threadIds: Array<string>,
): RemoveScheduledMessageAction => ({
  type: REMOVE_SCHEDULED_MESSAGE,
  payload: {id, threadIds},
});

export const deleteBroadcast: (id: string) => ThunkAction<void> = flow(
  key((id: string) => `deleteBroadcast-${id}`),
  fetching(),
)(
  (id: string) => (dispatch: Dispatch) =>
    dispatch(reduxApi.del(`messages_v2/broadcast/${id}`)).then(() =>
      dispatch(removeBroadcast(id)),
    ),
);

export const deleteScheduledMessage: (
  id: string,
  threadIds: Array<string>,
) => ThunkAction<void> = flow(
  key((id: string, threadIds: Array<string>) => `deleteBroadcast-${id}`),
  fetching(),
)(
  (id: string, threadIds: Array<string>) => (dispatch: Dispatch) =>
    dispatch(reduxApi.del(`messages_v2/broadcast/${id}`)).then(() =>
      dispatch(removeScheduledMessage(id, threadIds)),
    ),
);

type ReceiveFormErrors = {
  type: 'broadcasts/receiveFormErrors',
  payload: ErrorMap,
};

export const receiveFormErrors = (errors: ErrorMap): ReceiveFormErrors => ({
  type: RECEIVE_BROADCAST_FORM_ERRORS,
  payload: errors,
});

type MarkBroadcastProcessed = {
  type: 'broadcasts/markProcessed',
  payload: string,
};

export const markBroadcastProcessed = (id: string): MarkBroadcastProcessed => ({
  type: MARK_BROADCAST_PROCESSED,
  payload: id,
});
