// @flow strict

import type {AbtAttributesAction} from 'src/action-creators/action-based-targeting-meta';
import type {ActionAttributeSchema} from 'src/types/action-based-targeting-meta';
import {RECEIVE_ABT_ATTRIBUTES} from 'src/action-creators/action-based-targeting-meta';


export type State = {
  attributes: ActionAttributeSchema[],
};

const initialState = {
  attributes: [],
};

export default (
  state: State = initialState,
  action: AbtAttributesAction,
): State => {
  switch (action.type) {
    case RECEIVE_ABT_ATTRIBUTES: {
      return {
        ...state,
        attributes: action.payload,
      };
    }
  }
  return state;
};
