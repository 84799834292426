// @flow strict

import * as React from 'react';
import {Clickable} from 'src/designSystem2021Components/text-v2.jsx';
import {Input} from 'src/components/agency-onboard/lib/input/input.jsx';

import {DefaultFooter} from 'src/components/agency-onboard/footer/default-footer.jsx';
import {InfoLabel} from 'src/components/agency-onboard/info-label/info-label.jsx';
//$FlowFixMe
import {validateEmail} from 'src/utils/index';
import {validatePhone} from 'src/utils/phone';

import type {Task} from 'src/components/agency-onboard/types/side-panel-comp.js';

import css from './fallback-email-and-phone.css';
import maincss from 'src/components/agency-onboard/agency-onboard.css';


export type FallbackProps = {
  task: Task,
  onTaskSubmit: (id: string, value: mixed) => mixed,
  onTaskCancel: () => mixed,
  data: {
    default_email?: string,
    area_code?: string,
    call_forwarding_number?: string,
  },
};

export const FallbackEmailandPhone = (props: FallbackProps): React.Node => {
  const {task, onTaskCancel, onTaskSubmit, data} = props;
  const [formData, setFormData] = React.useState({
    default_email: data.default_email || null,
    area_code: data.area_code || null,
    call_forwarding_number: data.call_forwarding_number || null,
  });

  const [disableSubmit, setDisableSubmit] = React.useState(true);

  const checkIfFormComplete = () => {
    let isFormComplete = true;
    Object.keys(formData).forEach((field) => {
      if (!formData[field]) {
        isFormComplete = false;
      }
    });

    if (!validateEmail(formData['default_email'])) {
      isFormComplete = false;
    }

    if (!validatePhone(formData['call_forwarding_number'])) {
      isFormComplete = false;
    }

    return isFormComplete;
  };

  React.useEffect(() => {
    setDisableSubmit(!checkIfFormComplete());
  }, [formData]);

  const handleFormDataChange = (field, value) => {
    const curData = {...formData};
    curData[field] = value;
    setFormData(curData);
  };

  const handleFormSubmit = () => {
    onTaskSubmit(task['id'], formData);
  };

  const handleFormCancel = () => {
    onTaskCancel();
  };

  return (
    <div className={maincss.sidePanel}>
      <div className={css.emailDiv}>
        <Clickable className={css.headerText}>
          <InfoLabel
            text="Agency Fallback Email"
            content="This will be used as a fallback email in case the sender email is not available"
            fixedTo="bottom"
            infoBoxClass={css.infoBox}
            infoIconClass={maincss.infoIcon}
          />
        </Clickable>
        <div className={maincss.inputBox}>
          <Input
            name="default_email"
            value={formData.default_email || ''}
            type="text"
            label="Email"
            boxClassName={maincss.outerBoxInputField}
            onChange={(event) =>
              handleFormDataChange('default_email', event.target.value)
            }
          />
        </div>
      </div>

      <hr className={maincss.dividerLine}></hr>

      <div className={css.numberDiv}>
        <Clickable className={css.headerText}>
          <InfoLabel
            text="Agency Fallback Number"
            content="This will be used as a fallback number in case the sender phone number is not available"
            fixedTo="bottom"
            infoBoxClass={css.infoBox}
            infoIconClass={maincss.infoIcon}
          />
        </Clickable>
        <div>
          <div className={maincss.inputBox}>
            <Input
              name="area_code"
              value={formData.area_code || ''}
              type="text"
              label="Area Code Preference*"
              boxClassName={maincss.outerBoxInputField}
              onChange={(event) =>
                handleFormDataChange('area_code', event.target.value)
              }
            />
          </div>
          <div className={maincss.inputBox}>
            <Input
              name="call_forwarding_number"
              value={formData.call_forwarding_number || ''}
              type="text"
              label="Call Forwarding Number*"
              boxClassName={maincss.outerBoxInputField}
              onChange={(event) =>
                handleFormDataChange(
                  'call_forwarding_number',
                  event.target.value,
                )
              }
            />
          </div>
        </div>
      </div>
      <DefaultFooter
        disableSubmit={disableSubmit}
        onSubmit={handleFormSubmit}
        onCancel={handleFormCancel}
      />
    </div>
  );
};
