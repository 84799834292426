//@flow strict

import * as React from 'react';
// $FlowFixMe[nonstrict-import]
import type {TitleBarDetailsModal} from 'src/action-creators/modal';
import classify from 'src/utils/classify';
import useAutofocus from 'src/hooks/useAutofocus';
import {
  BodyLarge,
  SubTitleLarge,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
  DIALOG_SEMANTIC,
} from '@spaced-out/ui-design-system/lib/components/Dialog';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import css from './genesis-base-dialog.css';


export const GenericBaseDialog = (props: TitleBarDetailsModal): React.Node => {
  const [showExportDialog, setShowExportDialog] = React.useState(true);

  const {
    className,
    children,
    showError,
    errorText,
    abortText,
    confirmText,
    handleAbort,
    handleConfirm,
    removeModal,
    title,
  } = props;

  return (
    <Dialog
      isOpen={showExportDialog}
      semantic={DIALOG_SEMANTIC.warning}
      tapOutsideToClose
      initialFocus={1}
      classNames={{content: classify(className, css.dialogContainer)}}
    >
      <DialogHeader>
        <SubTitleLarge>{title}</SubTitleLarge>
      </DialogHeader>

      <DialogBody>
        {showError && <span className={css.errorText}>{errorText}</span>}
        <BodyLarge color="secondary">{children}</BodyLarge>
      </DialogBody>

      <DialogFooter>
        {abortText && (
          <Button
            isFluid
            type="tertiary"
            className={css.abortButton}
            onClick={(event) => {
              handleAbort && handleAbort(event);
              removeModal && removeModal();
            }}
          >
            {abortText}
          </Button>
        )}
        {confirmText && (
          <Button
            isFluid
            type="primary"
            onClick={(event) => {
              handleConfirm && handleConfirm(event);
              removeModal && removeModal();
              setShowExportDialog(false);
            }}
          >
            {confirmText}
          </Button>
        )}
      </DialogFooter>
    </Dialog>
  );
};
