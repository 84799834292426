// @flow strict

import * as React from 'react';
import {useDispatch} from 'react-redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';

import {CustomDropdown} from 'src/components/lib/custom-dropdown/custom-dropdown.jsx';
import {SideMenuLink} from '@spaced-out/ui-design-system/lib/components/SideMenuLink';

import css from './support-links.css';


const optionMappings = {
  featureRequest:
    'https://form.asana.com/?k=HHJwBxMRKYi4zwNiU_qYVg&d=713022220213973',
  fileAnIssue: 'https://support.sensehq.co/hc/en-us/requests/new',
};

const helpDropdownOptions = [
  {
    key: 'senseLearningCenter',
    label: 'Sense Learning Center',
  },
  {
    key: 'featureRequest',
    label: 'Feature Request',
  },
  {
    key: 'fileAnIssue',
    label: 'File an Issue',
  },
];

const SupportLinks = (): React.Node => {
  const dispatch = useDispatch();

  const handleSenseUniversityOnClick = async () => {
    await dispatch(reduxApi.get('sense-university/login')).then((url) => {
      window.open(url, '_blank');
    });
  };

  const onOptionSelect = async (option) => {
    if (option.key === 'senseLearningCenter') {
      await handleSenseUniversityOnClick();
    } else {
      const url = optionMappings[option.key];
      url && window.open(url, '_blank');
    }
  };

  return (
    <div className={css.sideNavHelp}>
      <CustomDropdown
        anchorPosition="right-end"
        menu={{
          isFluid: false,
          options: helpDropdownOptions,
        }}
        onOptionSelect={onOptionSelect}
        tooltip={{body: 'Support Links'}}
      >
        <SideMenuLink
          classNames={{wrapper: css.sideMenuLinkWrapper}}
          pageNameKey="supportLinks"
        />
      </CustomDropdown>
    </div>
  );
};

export default SupportLinks;
