// @flow strict
import * as React from 'react';
import {classify} from 'src/utils/classify';

import {Smallest} from 'src/designSystem2021Components/text-v2.jsx';

import css from './input.css';


export type Props = {
  value?: string | number,
  onChange?: (
    evt: SyntheticInputEvent<HTMLInputElement>,
    isEnter?: boolean,
  ) => mixed,
  onFocus?: (e: SyntheticInputEvent<HTMLInputElement>) => mixed,
  onBlur?: (e: SyntheticInputEvent<HTMLInputElement>) => mixed,
  onKeyPress?: (e: SyntheticKeyboardEvent<HTMLInputElement>) => mixed,
  onPaste?: (e: ClipboardEvent) => mixed,
  name?: string,
  disabled?: boolean,
  placeholder?: string,
  error?: boolean,
  errorText?: ?string,
  label?: string | React.Node,
  contextLabel?: string | React.Node,
  className?: string,
  boxClassName?: string,
  type?: 'text' | 'number' | 'password' | 'email' | 'tel' | 'url',
  clearable?: boolean,
};

const Input_ = (props: Props, ref): React.Node => {
  const {
    value,
    onChange,
    onFocus,
    onBlur,
    name,
    disabled,
    placeholder,
    error,
    errorText,
    label,
    contextLabel,
    className,
    boxClassName,
    clearable,
    ...inputProps
  } = props;

  //Note(Aditya): we need to know filled so we can suppress error state when the user is trying to fix it.
  // getting value from uncontrolled is complicated and unnecessary since error states only work with controlled inputs.
  const controlledInputFilled = value !== '';

  return (
    <div
      className={classify(css.className, css.wrapper, {
        [css.filled]: controlledInputFilled ?? false,
        [css.withError]: error ?? false,
      })}
    >
      {(Boolean(label) || Boolean(contextLabel) || error) && (
        <div className={css.info}>
          <Smallest className={css.commonLabel}>{label ?? ''}</Smallest>
          <Smallest
            className={error && errorText ? css.errorLabel : css.commonLabel}
          >
            {error && errorText ? errorText : contextLabel ?? ''}
          </Smallest>
        </div>
      )}
      <div
        className={classify(css.box, boxClassName, {
          [css.inputDisabled]: disabled ?? false,
        })}
      >
        <input
          disabled={disabled}
          name={name}
          ref={ref}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          {...inputProps}
        />
      </div>
    </div>
  );
};

export const Input = (React.forwardRef<Props, HTMLInputElement>(
  Input_,
): React$AbstractComponent<Props, HTMLInputElement>);
