// @flow strict

import type {JobPreviewActions} from 'src/action-creators/job-preview';
import type {JobPreviewData, MatchingCandidate} from 'src/types/job-preview';
import {
  RECEIVE_PREVIEW_LIST,
  PREVIEW_ID,
  RECEIVE_LIST_COUNT,
  REFETCH,
  SAVE_CANDIDATE_DETAIL,
  SAVE_SELECTED_CANDIDATE,
} from 'src/action-creators/job-preview';


export type JobPreviewState = {
  jobs: JobPreviewData,
  total: number,
  id: string | null,
  refetch: boolean,
  matchingCandidate: MatchingCandidate | null,
  selectedCandidates: MatchingCandidate[] | null,
};

const initialState = {
  jobs: {
    columns: [],
    data: [],
  },
  total: 0,
  id: null,
  refetch: false,
  matchingCandidate: null,
  selectedCandidates: null,
};

export default (
  state: JobPreviewState = initialState,
  action: JobPreviewActions,
): JobPreviewState => {
  switch (action.type) {
    case RECEIVE_PREVIEW_LIST: {
      return {
        ...state,
        jobs: action.payload.jobs,
      };
    }
    case PREVIEW_ID: {
      return {
        ...state,
        id: action.payload.id,
      };
    }
    case RECEIVE_LIST_COUNT: {
      return {
        ...state,
        total: action.payload.total,
      };
    }
    case REFETCH: {
      return {
        ...state,
        refetch: !state.refetch,
      };
    }
    case SAVE_CANDIDATE_DETAIL: {
      return {
        ...state,
        matchingCandidate: action.payload,
      };
    }
    case SAVE_SELECTED_CANDIDATE: {
      return {
        ...state,
        selectedCandidates: action.payload,
      };
    }
  }

  return state;
};
