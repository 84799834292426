// @flow strict

import type {JourneyAudienceAction} from 'src/action-creators/journey-audience';
import type {AudienceList} from 'src/types/audience-list';
import {
  UPDATE_STATE,
  RECEIVE_SELECTED_LISTS,
  SET_INCLUDED_LISTS,
  SET_EXCLUDED_LISTS,
  RESET,
} from 'src/action-creators/journey-audience';


export type JourneyAudienceState = {
  includedLists: Map<string, $Shape<AudienceList>>,
  excludedLists: Map<string, $Shape<AudienceList>>,
};

const initialState = {
  includedLists: new Map(),
  excludedLists: new Map(),
};

export default (
  state: JourneyAudienceState = initialState,
  action: JourneyAudienceAction,
): JourneyAudienceState => {
  switch (action.type) {
    case UPDATE_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case RECEIVE_SELECTED_LISTS: {
      return {
        ...state,
        includedLists: new Map([
          ...state.includedLists.entries(),
          ...action.payload.includedLists.entries(),
        ]),
        excludedLists: new Map([
          ...state.excludedLists.entries(),
          ...action.payload.excludedLists.entries(),
        ]),
      };
    }
    case SET_INCLUDED_LISTS: {
      return {
        ...state,
        includedLists: action.payload,
      };
    }
    case SET_EXCLUDED_LISTS: {
      return {
        ...state,
        excludedLists: action.payload,
      };
    }
    case RESET: {
      return {
        ...initialState,
      };
    }
  }

  return state;
};
