// @flow strict
// $FlowFixMe[nonstrict-import]
import type {Dispatch, GetState, ThunkAction} from 'src/reducers';
import type {
  PerformanceDetailsResponse,
  HVBroadcast,
  HVBroadcastPayload,
  HVBroadcastSuppression,
  csvMapping,
  ScheduledOrNewHVBroadcast,
  fileCsvUploadEntries,
  ContactList,
  Filters,
  FileDetails,
  HVBroadcastSuppressionTimeFrame,
  ContactListPayload,
  PerformanceDeliveryAnalytics,
  HVBroadcastDataWithPagination,
  HVBroadcastPerformancePayload,
  DashboardFilters,
  RenameModalPopupDetails,
  HVBroadcastPerformanceRecipientsWS,
} from 'src/types/hv-broadcasts.js';
import {
  getIgnoredPhoneNumbersList,
  selectBroadcastSuppressionTimeFrame,
  selectIsContactListLimitReached,
  selectTemplateId,
  selectSchedulerTemplateId,
} from 'src/selectors/hv-broadcasts.js';
import {
  selectMessageOrigin,
  selectDiscoverCandidateDetails,
} from 'src/selectors/draft-messages';
//$FlowFixMe[nonstrict-import]
import {getCurrentAgent} from 'src/selectors/accounts';
//$FlowFixMe[untyped-import]
import {camel, snake} from 'src/utils';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
import flow from 'lodash/flow';
import moment from 'moment-timezone';
import {getTimezone} from 'src/selectors/date-time';


export type HVBroadcastActions =
  | ReceiveAllHVBroadcastsAction
  | ReceiveHVBroadcastSuppressionAction
  | RestoreHVBroadcastPhoneNumbersAction
  | ReceiveHVBroadcastFiltersAction
  | ReceiveContactListsAction
  | SetCsvFileUploadHeadersAction
  | SetCsvFileUploadEntriesAction
  | SetCsvFileUploadAction
  | ReceiveHVBroadcastUploadedFileDetailsAction
  | ReceiveHVBroadcastSuppressionTimeFrameAction
  | RemoveHVBroadcastUploadedFileDetailsAction
  | UpdateContactListLimitReachedAction
  | UpdateContactListIdCreatedAction
  | ReceiveCurrentHVBroadcastDataEvent
  | ReceiveHVBroadcastUpdatedNameAction
  | SetPerformanceDataAction
  | ReceivePerformanceDeliveryAnalytics
  | ClearUploadedFileDetailsAction
  | InitializeUploadedFileDetailsAction
  | SetMessageDataAction
  | ReceiveBroadcastPerformanceRecipientsAction;

export const SET_PERFORMANCE_DATA = 'hv-broadcasts/setPerformanceData';
export const SET_MESSAGE_DATA = 'hv-broadcasts/setMessageData';
export const RECEIVE_ALL_HV_BROADCASTS = 'hv-broadcasts/receiveAllHVBroadcasts';
export const RECEIVE_HV_BROADCAST_SUPPRESSION =
  'hv-broadcasts/receiveHVBroadcastSuppression';

export const RECEIVE_HV_BROADCAST_FILTERS =
  'hv-broadcasts/receiveHVBroadcastFilters';

export const RECEIVE_HV_CONTACT_LISTS = 'hv-broadcasts/receiveHVContactLists';

export const RECEIVE_AGENTS_RECENT_HV_CONTACT_LISTS =
  'hv-broadcasts/receiveAgentsRecentHVContactLists';

export const RECEIVE_HV_BROADCAST_SUPPRESSION_TIME_FRAME =
  'hv-broadcasts/receiveHVBroadcastSuppressionTimeFrame';

export const RESTORE_HV_BROADCAST_PHONE_NUMBERS =
  'hv-broadcasts/restoreHVBroadcastPhoneNumbers';

export const SET_CSV_FILE_UPLOAD_HEADERS =
  'hv-broadcasts/setCsvFileUploadHeaders';
export const SET_CSV_FILE_UPLOAD_ENTRIES =
  'hv-broadcasts/setCsvFileUploadEntries';

export const SET_CSV_FILE_UPLOAD = 'hv-broadcasts/setCsvFileUpload';

export const RECEIVE_UPLOADED_FILE_DETAILS =
  'hv-broadcasts/receiveUploadedFileDetails';

export const REMOVE_UPLOADED_FILE_DETAILS =
  'hv-broadcasts/removeUploadedFileDetails';

export const UPDATE_CONTACT_LIST_LIMIT_REACHED =
  'hv-broadcasts/updateContactListLimitReached';

export const UPDATE_CONTACT_LIST_ID_CREATED =
  'hv-broadcasts/updateContactListIdCreated';

export const CLEAR_UPLOADED_FILE_DETAILS =
  'hv-broadcasts/clearUploadedFileDetails';

export const INITIALIZE_UPLOADED_FILE_DETAILS =
  'hv-broadcasts/initializeUploadedFileDetails';

export const RECEIVE_PERFORMANCE_DELIVERY_ANALYTICS =
  'hv-broadcasts/receivePerformanceDeliveryAnalytics';

export const RECEIVE_BROADCAST_PERFORMANCE_RECIPIENTS =
  'hv-broadcasts/receiveBroadcastPerformanceRecipients';

export const RECEIVE_HV_BROADCAST_UPDATE_NAME =
  'hv-broadcasts/receiveHVBroadcastUpdateName';

export const RECEIVE_CURRENT_HV_BROADCAST_DATA =
  'hv-broadcasts/receiveCurrentHVBroadcastData';

export const REMOVE_DELETED_HV_BROADCAST =
  'hv-broadcasts/removeDeletedHVBrpadcast';

export const SET_SCHEDULER_TEMPLATE_ID = 'hv-broadcasts/setSchedulerTemplateId';

export const SET_TEMPLATE_ID = 'hv-broadcasts/setTemplateId';

export const SET_SHOW_RENAME_MODAL_POPUP_DETAILS =
  'hv-broadcasts/showRenameModalPopupDetails';

export const UPDATE_PERFORMANCE_DATA_NAME =
  'hv-broadcasts/updatePerformanceDataName';

export const CLEAR_CURRENT_BROADCAST_DATA =
  'hv-broadcasts/clearCurrentBroadcastData';

export const CLEAR_HV_BROADCASTS = 'hv-broadcasts/clearHVBroadcasts';

export const SET_SELECTED_BROADCASTS_TAB =
  'hv-broadcasts/setSelectedBroadcastsTab';

export const CLEAR_HV_BROADCAST_RESTORED_PHONE_NUMBERS =
  'hv-broadcasts/clearHVBroadcastRestoredPhoneNumbers';

export const CLEAR_HV_BROADCAST_SUPPRESSION =
  'hv-broadcasts/clearHVBroadcastSuppression';

export const SET_BROADCAST_DETAILS = 'hv-broadcasts/setBroadcastDetails';

export type SetBroadcastDetailsAction = {
  type: typeof SET_BROADCAST_DETAILS,
  payload: ScheduledOrNewHVBroadcast,
};

export const setBroadcastDetails = (
  payload: ScheduledOrNewHVBroadcast,
): SetBroadcastDetailsAction => ({
  type: SET_BROADCAST_DETAILS,
  payload,
});

export type SetSelectedBroadcastsTabAction = {
  type: typeof SET_SELECTED_BROADCASTS_TAB,
  payload: string,
};

export const setSelectedBroadcastsTab = (
  payload: string,
): SetSelectedBroadcastsTabAction => ({
  type: SET_SELECTED_BROADCASTS_TAB,
  payload,
});

export type ClearCurrentBroadcastDataAction = {
  type: typeof CLEAR_CURRENT_BROADCAST_DATA,
};

export const clearCurrentBroadcastData =
  (): ClearCurrentBroadcastDataAction => ({
    type: CLEAR_CURRENT_BROADCAST_DATA,
  });

export type ClearHVBroadcastRestoredPhoneNumbersAction = {
  type: typeof CLEAR_HV_BROADCAST_RESTORED_PHONE_NUMBERS,
};

export const clearHVBroadcastRestoredPhoneNumbers =
  (): ClearHVBroadcastRestoredPhoneNumbersAction => ({
    type: CLEAR_HV_BROADCAST_RESTORED_PHONE_NUMBERS,
  });

export type ClearHVBroadcastSuppressionAction = {
  type: typeof CLEAR_HV_BROADCAST_SUPPRESSION,
};

export const clearHVBroadcastSuppression =
  (): ClearHVBroadcastSuppressionAction => ({
    type: CLEAR_HV_BROADCAST_SUPPRESSION,
  });

export type ClearHVBroadcastsAction = {
  type: typeof CLEAR_HV_BROADCASTS,
};

export const clearHVBroadcasts = (): ClearHVBroadcastsAction => ({
  type: CLEAR_HV_BROADCASTS,
});

export type updatePerformanceDataNameAction = {
  type: typeof UPDATE_PERFORMANCE_DATA_NAME,
  payload: {id: string, name: string},
};

export const updatePerformanceDataName = (payload: {
  id: string,
  name: string,
}): updatePerformanceDataNameAction => ({
  type: UPDATE_PERFORMANCE_DATA_NAME,
  payload,
});

export type SetShowRenameModalPopupDetails = {
  type: typeof SET_SHOW_RENAME_MODAL_POPUP_DETAILS,
  payload: RenameModalPopupDetails,
};

export const setShowRenameModalPopupDetails = (
  payload: RenameModalPopupDetails,
): SetShowRenameModalPopupDetails => ({
  type: SET_SHOW_RENAME_MODAL_POPUP_DETAILS,
  payload,
});

export type ReceivePerformanceDeliveryAnalytics = {
  type: typeof RECEIVE_PERFORMANCE_DELIVERY_ANALYTICS,
  payload: {id: string, deliveryAnalytics: PerformanceDeliveryAnalytics},
};

export const receivePerformanceDeliveryAnalytics = (payload: {
  id: string,
  deliveryAnalytics: PerformanceDeliveryAnalytics,
}): ReceivePerformanceDeliveryAnalytics => ({
  type: RECEIVE_PERFORMANCE_DELIVERY_ANALYTICS,
  payload,
});

export type ReceiveBroadcastPerformanceRecipientsAction = {
  type: typeof RECEIVE_BROADCAST_PERFORMANCE_RECIPIENTS,
  payload: HVBroadcastPerformanceRecipientsWS,
};

export const receiveBroadcastPerformanceRecipients = (
  payload: HVBroadcastPerformanceRecipientsWS,
): ReceiveBroadcastPerformanceRecipientsAction => ({
  type: RECEIVE_BROADCAST_PERFORMANCE_RECIPIENTS,
  payload,
});

export type ReceiveCurrentHVBroadcastDataEvent = {
  type: typeof RECEIVE_CURRENT_HV_BROADCAST_DATA,
  payload: ScheduledOrNewHVBroadcast,
};

export const receiveCurrentHVBroadcastData = (
  payload: ScheduledOrNewHVBroadcast,
): ReceiveCurrentHVBroadcastDataEvent => ({
  type: RECEIVE_CURRENT_HV_BROADCAST_DATA,
  payload,
});

export type ReceiveHVBroadcastUpdatedNameAction = {
  type: typeof RECEIVE_HV_BROADCAST_UPDATE_NAME,
  payload: {id: string, name: string},
};

export const receiveHVBroadcastUpdatedName = (payload: {
  id: string,
  name: string,
}): ReceiveHVBroadcastUpdatedNameAction => ({
  type: RECEIVE_HV_BROADCAST_UPDATE_NAME,
  payload,
});

export type RemoveDeletedHVBroadcastAction = {
  type: typeof REMOVE_DELETED_HV_BROADCAST,
  payload: string,
};

export const removeDeletedHVBroadcast = (
  payload: string,
): RemoveDeletedHVBroadcastAction => ({
  type: REMOVE_DELETED_HV_BROADCAST,
  payload,
});

export type ReceiveAllHVBroadcastsAction = {
  type: typeof RECEIVE_ALL_HV_BROADCASTS,
  payload: {
    data: HVBroadcastDataWithPagination,
    update?: boolean,
  },
};

export const receiveAllHVBroadcasts = (payload: {
  data: HVBroadcastDataWithPagination,
  update?: boolean,
}): ReceiveAllHVBroadcastsAction => ({
  type: RECEIVE_ALL_HV_BROADCASTS,
  payload,
});

export type ReceiveHVBroadcastSuppressionAction = {
  type: typeof RECEIVE_HV_BROADCAST_SUPPRESSION,
  payload: HVBroadcastSuppression[],
};

export const receiveHVBroadcastSuppression = (
  payload: HVBroadcastSuppression[],
): ReceiveHVBroadcastSuppressionAction => ({
  type: RECEIVE_HV_BROADCAST_SUPPRESSION,
  payload,
});

export type ReceiveHVBroadcastSuppressionTimeFrameAction = {
  type: typeof RECEIVE_HV_BROADCAST_SUPPRESSION_TIME_FRAME,
  payload: HVBroadcastSuppressionTimeFrame,
};

export const receiveHVBroadcastTimeFrameSuppression = (
  payload: HVBroadcastSuppressionTimeFrame,
): ReceiveHVBroadcastSuppressionTimeFrameAction => ({
  type: RECEIVE_HV_BROADCAST_SUPPRESSION_TIME_FRAME,
  payload,
});

export type ReceiveHVBroadcastUploadedFileDetailsAction = {
  type: typeof RECEIVE_UPLOADED_FILE_DETAILS,
  payload: FileDetails,
};

export const receiveHVBroadcastUploadedFileDetails = (
  payload: FileDetails,
): ReceiveHVBroadcastUploadedFileDetailsAction => ({
  type: RECEIVE_UPLOADED_FILE_DETAILS,
  payload,
});

export type RemoveHVBroadcastUploadedFileDetailsAction = {
  type: typeof REMOVE_UPLOADED_FILE_DETAILS,
  payload: string,
};

export const removeHVBroadcastUploadedFileDetails = (
  payload: string,
): RemoveHVBroadcastUploadedFileDetailsAction => ({
  type: REMOVE_UPLOADED_FILE_DETAILS,
  payload,
});

export type ReceiveHVBroadcastFiltersAction = {
  type: typeof RECEIVE_HV_BROADCAST_FILTERS,
  payload: Filters,
};

export const receiveHVBroadcastFilters = (
  payload: Filters,
): ReceiveHVBroadcastFiltersAction => ({
  type: RECEIVE_HV_BROADCAST_FILTERS,
  payload,
});

export type ReceiveContactListsAction = {
  type: typeof RECEIVE_HV_CONTACT_LISTS,
  payload: {contactLists: ContactList[], update: boolean},
};

export const receiveContactLists = (payload: {
  contactLists: ContactList[],
  update: boolean,
}): ReceiveContactListsAction => ({
  type: RECEIVE_HV_CONTACT_LISTS,
  payload,
});

export type ReceiveAgentsRecentContactListsAction = {
  type: typeof RECEIVE_AGENTS_RECENT_HV_CONTACT_LISTS,
  payload: ContactList[],
};

export const receiveAgentsRecentContactLists = (
  payload: ContactList[],
): ReceiveAgentsRecentContactListsAction => ({
  type: RECEIVE_AGENTS_RECENT_HV_CONTACT_LISTS,
  payload,
});

export type RestoreHVBroadcastPhoneNumbersAction = {
  type: typeof RESTORE_HV_BROADCAST_PHONE_NUMBERS,
  payload: string[],
};

export const restoreHVBroadcastPhoneNumbers = (
  payload: string[],
): RestoreHVBroadcastPhoneNumbersAction => ({
  type: RESTORE_HV_BROADCAST_PHONE_NUMBERS,
  payload,
});

export type SetCsvFileUploadHeadersAction = {
  type: typeof SET_CSV_FILE_UPLOAD_HEADERS,
  payload: string[],
};

export const setCsvFileUploadHeaders = (
  payload: string[],
): SetCsvFileUploadHeadersAction => ({
  type: SET_CSV_FILE_UPLOAD_HEADERS,
  payload,
});

export type SetCsvFileUploadEntriesAction = {
  type: typeof SET_CSV_FILE_UPLOAD_ENTRIES,
  payload: fileCsvUploadEntries[],
};

export const setCsvFileUploadEntries = (
  payload: fileCsvUploadEntries[],
): SetCsvFileUploadEntriesAction => ({
  type: SET_CSV_FILE_UPLOAD_ENTRIES,
  payload,
});

export type SetCsvFileUploadAction = {
  type: typeof SET_CSV_FILE_UPLOAD,
  payload: File | null,
};

export const setCsvFileUpload = (
  payload: File | null,
): SetCsvFileUploadAction => ({
  type: SET_CSV_FILE_UPLOAD,
  payload,
});

export type UpdateContactListLimitReachedAction = {
  type: typeof UPDATE_CONTACT_LIST_LIMIT_REACHED,
  payload: boolean,
};

export const updateContactListLimitReached = (
  payload: boolean,
): UpdateContactListLimitReachedAction => ({
  type: UPDATE_CONTACT_LIST_LIMIT_REACHED,
  payload,
});

export type UpdateContactListIdCreatedAction = {
  type: typeof UPDATE_CONTACT_LIST_ID_CREATED,
  payload: string,
};

export const updateContactListIdCreated = (
  payload: string,
): UpdateContactListIdCreatedAction => ({
  type: UPDATE_CONTACT_LIST_ID_CREATED,
  payload,
});

export type ClearUploadedFileDetailsAction = {
  type: typeof CLEAR_UPLOADED_FILE_DETAILS,
};

export const clearUploadedFileDetails = (): ClearUploadedFileDetailsAction => ({
  type: CLEAR_UPLOADED_FILE_DETAILS,
});

export type InitializeUploadedFileDetailsAction = {
  type: typeof INITIALIZE_UPLOADED_FILE_DETAILS,
  payload: FileDetails[],
};

export const initializeUploadedFileDetails = (
  payload: FileDetails[],
): InitializeUploadedFileDetailsAction => ({
  type: INITIALIZE_UPLOADED_FILE_DETAILS,
  payload,
});

export type SetPerformanceDataAction = {
  type: typeof SET_PERFORMANCE_DATA,
  payload: PerformanceDetailsResponse,
};

export const setPerformanceData = (
  payload: PerformanceDetailsResponse,
): SetPerformanceDataAction => ({
  type: SET_PERFORMANCE_DATA,
  payload,
});

export type SetMessageDataAction = {
  type: typeof SET_MESSAGE_DATA,
  payload: ScheduledOrNewHVBroadcast,
};

export const setMessageData = (
  payload: ScheduledOrNewHVBroadcast,
): SetMessageDataAction => ({
  type: SET_MESSAGE_DATA,
  payload,
});

export type SetTemplateIdAction = {
  type: typeof SET_TEMPLATE_ID,
  payload: string,
};

export const setTemplateId = (payload: string): SetTemplateIdAction => ({
  type: SET_TEMPLATE_ID,
  payload,
});

export type SetSchedulerTemplateIdAction = {
  type: typeof SET_TEMPLATE_ID,
  payload: string,
};

export const setSchedulerTemplateId = (
  payload: string,
): SetSchedulerTemplateIdAction => ({
  type: SET_SCHEDULER_TEMPLATE_ID,
  payload,
});

export const getAllHVBroadcasts =
  (
    data: DashboardFilters,
    offset: number = 0,
    update: boolean = false,
  ): ThunkAction<> =>
  async (dispatch: Dispatch) => {
    const limit = 20;
    try {
      const result = await dispatch(
        reduxApi.post(
          `messages_v2/broadcasts`,
          snake(data),
          {offset, limit},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
      dispatch(receiveAllHVBroadcasts({data: camel(result), update}));
    } catch (err) {
      throw err;
    }
  };

export const fetchPerformanceData: (
  broadcastId: string,
  body?: HVBroadcastPerformancePayload,
  offset?: number,
) => ThunkAction<void> = flow(
  key((broadcastId) => `messages_v2/broadcasts/${broadcastId}/performance`),
  fetching(),
)((broadcastId, body, offset) => async (dispatch: Dispatch) => {
  const data = body ? body : {};
  try {
    const result = await dispatch(
      reduxApi.post(
        `messages_v2/broadcasts/${broadcastId}/performance`,
        snake(data),
        {offset},
        {
          apiPath: '/api/v2/',
        },
      ),
    );
    // here id is needed to update the WS event data
    dispatch(setPerformanceData({...camel(result), id: broadcastId}));
  } catch (err) {
    throw err;
  }
});

export const fetchMessageData = (broadcastId: string): ThunkAction<> => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await dispatch(
        reduxApi.get(
          `messages_v2/broadcasts/${broadcastId}`,
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
      dispatch(setMessageData(camel(result)));
    } catch (err) {
      throw err;
    }
  };
};

export const resolveContentWarning =
  (
    body: string,
    type: string,
    startOffset: number,
    endOffset: number,
  ): ThunkAction<> =>
  async (dispatch: Dispatch) => {
    try {
      const result = await dispatch(
        reduxApi.post(`messages_v2/content_warnings_response`, {
          message_body: body,
          warning_type: type,
          start_offset: startOffset,
          end_offset: endOffset,
        }),
      );
      return camel(result);
    } catch (err) {
      throw err;
    }
  };
export const getHVBroadcastFilters =
  (): ThunkAction<> => async (dispatch: Dispatch) => {
    try {
      const result = await dispatch(
        reduxApi.get(
          `messages_v2/broadcasts/filters`,
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
      dispatch(receiveHVBroadcastFilters(camel(result)));
    } catch (err) {
      throw err;
    }
  };

export const renameHVBroadcast =
  (id: string, name: string): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      await dispatch(
        reduxApi.put(
          `messages_v2/broadcasts/${id}`,
          {name},
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
      dispatch(receiveHVBroadcastUpdatedName({id, name}));
    } catch (err) {
      throw err;
    }
  };

export const getHVBroadcastData =
  (id: string): ThunkAction<> =>
  async (dispatch: Dispatch) => {
    try {
      const result = await dispatch(
        reduxApi.get(
          `messages_v2/broadcasts/${id}`,
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
      dispatch(receiveCurrentHVBroadcastData({...camel(result), id}));
      return camel(result);
    } catch (err) {
      throw err;
    }
  };

export const deleteScheduledHVBroadcast =
  (id: string): ThunkAction<> =>
  async (dispatch: Dispatch) => {
    try {
      await dispatch(
        reduxApi.del(
          `messages_v2/broadcasts/${id}`,
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
      dispatch(removeDeletedHVBroadcast(id));
    } catch (err) {
      throw err;
    }
  };

export const sendScheduledHVBroadcast =
  (
    id: string,
    broadcastDetails: ScheduledOrNewHVBroadcast,
    sendNow: boolean,
  ): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const {
      name,
      phoneNumberSetId,
      messageBody: body,
      contactLists,
      files,
      grabbedPhoneNumbers,
      sentTime: sendDate,
      sendNow,
      templateId,
      schedulerTemplateId,
    } = broadcastDetails;

    const contactListIds = contactLists.map((contactList) => contactList.id);
    const fileIds = files.map((file) => file.id);
    const ignorePhoneNumbers = getIgnoredPhoneNumbersList(getState());
    const payloadData = {
      name,
      phoneNumberSetId,
      body,
      contactListIds,
      fileIds,
      ignorePhoneNumbers,
      grabbedPhoneNumbers,
      sendNow: sendNow || undefined,
      sendDate: sendNow ? undefined : sendDate,
      templateId: templateId || undefined,
      schedulerTemplateId: schedulerTemplateId || undefined,
    };
    try {
      await dispatch(
        reduxApi.put(
          `messages_v2/broadcasts/${id}`,
          snake(payloadData),
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
    } catch (err) {
      throw err;
    }
  };
export const createHVBroadcast =
  (
    data: ScheduledOrNewHVBroadcast,
    ignoredPhoneNumbers?: string[],
  ): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const origin = selectMessageOrigin(getState());
    const discoverCandidateDetails = selectDiscoverCandidateDetails(getState());
    const {
      name = '',
      phoneNumberSetId,
      messageBody: body,
      sentTime: sendDate,
      contactLists = [],
      files = [],
      grabbedPhoneNumbers,
      sendNow,
      templateId,
      schedulerTemplateId,
    } = data;

    const contactListIds = contactLists.map((contactList) => contactList.id);
    const fileIds = files.map((file) => file.id);
    const ignorePhoneNumbers =
      ignoredPhoneNumbers || getIgnoredPhoneNumbersList(getState());
    const payloadData = {
      name,
      phoneNumberSetId,
      body,
      contactListIds,
      fileIds,
      ignorePhoneNumbers,
      grabbedPhoneNumbers,
      sendNow: sendNow || undefined,
      sendDate: sendNow ? undefined : sendDate,
      templateId: templateId || undefined,
      schedulerTemplateId: schedulerTemplateId || undefined,
    };
    const payload = discoverCandidateDetails
      ? {...payloadData, origin, metadata: discoverCandidateDetails}
      : payloadData;
    try {
      const response = await dispatch(
        reduxApi.post(
          'messages_v2/broadcasts/create',
          snake(payload),
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
      return response.id;
    } catch (err) {
      throw err;
    }
  };

export const getContactLists =
  (
    searchTerm?: string = '',
    offset?: number = 0,
    limit?: number = 10,
    sortBy?: string = 'title',
    order?: string = 'asc',
  ): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      const limitReached = selectIsContactListLimitReached(getState());
      if (!limitReached) {
        const URL = searchTerm
          ? `messages_v2/contact_lists/search/${searchTerm}`
          : 'messages_v2/contact_lists';
        const result = await dispatch(
          reduxApi.get(
            URL,
            {
              limit,
              offset,
              sort_by: sortBy,
              order,
            },
            {
              apiPath: '/api/v2/',
            },
          ),
        );
        if (result) {
          const response = camel(result);
          const update = offset !== 0;
          const contactLists = camel(response.contactLists);
          dispatch(receiveContactLists({contactLists, update}));
          if (contactLists.length < limit) {
            dispatch(updateContactListLimitReached(true));
          }
        }
      }
    } catch (err) {
      throw err;
    }
  };

export const getAgentsRecentContactLists =
  (
    limit?: number = 5,
    sortBy?: string = 'time_created',
    order?: string = 'desc',
  ): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const currentUserId = getCurrentAgent(getState())?.id;
    try {
      const URL = 'messages_v2/contact_lists';
      const result = await dispatch(
        reduxApi.get(
          URL,
          {
            limit,
            sort_by: sortBy,
            order,
            agent_id: currentUserId,
          },
          {
            apiPath: '/api/v2/',
          },
        ),
      );
      if (result) {
        const response = camel(result);
        const contactLists = camel(response.contactLists);
        dispatch(receiveAgentsRecentContactLists(contactLists));
      }
    } catch (err) {
      throw err;
    }
  };

export const getBroadcastSuppression =
  (contactListIds: string[], fileIds: string[]): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      const broadcastSuppressionTimeFrame = selectBroadcastSuppressionTimeFrame(
        getState(),
      );

      const agent = getState().accounts.authedUserId
        ? getState().accounts.data[getState().accounts.authedUserId]
        : null;
      const broadcastConfigType =
        agent?.securityRole?.configurations?.broadcastSuppression?.type ||
        'off';

      if (
        broadcastConfigType !== 'off' &&
        (contactListIds.length > 0 || fileIds.length > 0)
      ) {
        const result = await dispatch(
          reduxApi.post(
            'messages_v2/broadcasts/suppression',
            {
              contact_list_ids: contactListIds,
              file_ids: fileIds,
              grabbed_phone_numbers: [],
              broadcast_suppression_setting: broadcastSuppressionTimeFrame.time,
            },
            {},
            {
              apiPath: '/api/v2/',
            },
          ),
        );
        await dispatch(receiveHVBroadcastSuppression(camel(result)));
      }
    } catch (err) {
      throw err;
    }
  };

export const uploadCsvFile =
  (file: File, csvMapping: csvMapping): ThunkAction<> =>
  async (dispatch: Dispatch) => {
    const formData = new FormData();
    const mapping = snake(csvMapping);
    formData.append('contact_upload_file', file);
    formData.append('csv_field_map', JSON.stringify(mapping));
    try {
      const response = await dispatch(
        reduxApi.post(
          'messages_v2/contacts/file/upload',
          // $FlowFixMe[class-object-subtyping]
          formData,
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
      const modifiedResponse = {
        id: response.id,
        name: response.file_name,
        count: response.csv_row_count,
      };
      dispatch(receiveHVBroadcastUploadedFileDetails(modifiedResponse));
    } catch (err) {
      throw err;
    }
  };

export const createContactList =
  (data: ContactListPayload): ThunkAction<> =>
  async (dispatch: Dispatch) => {
    const contactListIds = data.contactListIds.join(',');
    const fileIds = data.fileIds.join(',');
    const formattedPayload = {...data, contactListIds, fileIds};
    try {
      const response = await dispatch(
        reduxApi.post(
          'messages_v2/contact_list',
          snake(formattedPayload),
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
      if (response?.contact_list?.id) {
        dispatch(updateContactListIdCreated(response.contact_list.id));
      }
    } catch (err) {
      throw err;
    }
  };

export const updateContactList =
  (data: ContactListPayload, contactListId: string): ThunkAction<> =>
  async (dispatch: Dispatch) => {
    const contactListIds = data.contactListIds.join(',');
    const fileIds = data.fileIds.join(',');
    const formattedPayload = {...data, contactListIds, fileIds};
    try {
      await dispatch(
        reduxApi.put(
          `messages_v2/contact_list/${contactListId}`,
          snake(formattedPayload),
          {},
          {
            apiPath: '/api/v2/',
          },
        ),
      );
    } catch (err) {
      throw err;
    }
  };
