// @noflow

import type {Action, SimpleAction} from 'src/types/redux';
import type {Contact} from 'src/types/contacts';

import {useReducer} from 'react';
import invariant from 'invariant';

import {setError} from 'src/utils/validation';
import {validatePhone} from 'src/utils/phone';
import {validateEmail} from 'src/utils';
import {getEmptyContact} from 'src/reducers/contacts';

type Member = {
  contact?: Contact,
  audienceMembers: AudienceMember[],
  externalPhone: string,
};

type NewContact = {
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
};

type State = {
  members: {
    [string]: Member,
  },
  adding: ?{
    contact: NewContact,
    errors: mixed,
  },
};

export const initialState = {
  members: {},
  adding: null,
};

type AddAction = Action<'add', Member>;
type RemoveAction = Action<'remove', string>;
type NewContactAction = Action<
  'new',
  {
    firstName: string,
    lastName: string,
  },
>;
type EditContactAction = Action<'edit', $Shape<NewContact>>;
type CreateContactAction = SimpleAction<'create'>;
type ClearContactAction = SimpleAction<'clear'>;

export type EditorAction =
  | AddAction
  | RemoveAction
  | NewContactAction
  | CreateContactAction
  | EditContactAction
  | ClearContactAction;
export type EditorDispatch = EditorAction => void;

export function reducer(state: State, action: EditorAction) {
  switch (action.type) {
    case 'add': {
      const thread = action.payload;
      return {
        ...state,
        members: {
          ...state.members,
          [thread.externalPhone]: thread,
        },
      };
    }

    case 'remove': {
      const {[action.payload]: _, ...members} = state.members;
      return {
        ...state,
        members,
      };
    }

    case 'new': {
      const newContact = {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        ...action.payload,
      };

      return {
        ...state,
        adding: {
          contact: newContact,
          errors: validateContact(newContact),
        },
      };
    }

    case 'edit': {
      const contact = {
        ...state.adding?.contact,
        ...action.payload,
      };

      return {
        ...state,
        adding: {
          contact,
          errors: validateContact(contact),
        },
      };
    }

    case 'create': {
      invariant(state.adding, 'Cannot create an empty contact.');

      const {
        contact: {phone, ...newContact},
      } = state.adding;
      const contact = {
        ...newContact,
        phoneNumbers: [
          {
            primary: true,
            value: phone,
          },
        ],
      };

      return {
        members: {
          [phone]: {
            contact,
            audienceMembers: [],
            externalPhone: phone,
          },
        },
        adding: null,
      };
    }

    case 'clear': {
      return {
        ...state,
        adding: null,
      };
    }

    default:
      throw new Error(`Invalid action type ${action.type}`);
  }
}

const useThreadListEditor = () =>
  useReducer<State, EditorAction>(reducer, initialState);
export default useThreadListEditor;

const validateContact = (contact: NewContact) => {
  let error = null;

  error = setError(
    error,
    contact.firstName,
    'firstName',
    'First name is required.',
  );
  error = setError(
    error,
    contact.lastName,
    'lastName',
    'Last name is required.',
  );

  const {phone} = contact;
  error = setError(error, phone, 'phone', 'Phone number is required.');
  if (phone) {
    error = setError(
      error,
      validatePhone(phone),
      'phone',
      'Phone number is invalid.',
    );
  }

  if (contact.email) {
    error = setError(
      error,
      validateEmail(contact.email),
      'email',
      'Email is invalid.',
    );
  }

  return error;
};
