// @flow strict
import type {
  Validations,
  NormalizedSMSChatbot,
  SMSChatbotState,
  FetchedSMSChatbotResponse,
} from 'src/types/sms-chatbot';


export const parseSMSChatbot = (
  fetchedNodeResponse: FetchedSMSChatbotResponse,
): SMSChatbotState => {
  const smsChatbotSendTask = fetchedNodeResponse.body.tasks.find(
    (task) => task.type === 'chatbot_over_sms',
  );
  const taskDefinition = smsChatbotSendTask?.params;
  if (!smsChatbotSendTask || !smsChatbotSendTask.params) {
    throw new Error('Sms task or its definition not found');
  }

  return {
    id: smsChatbotSendTask.id,
    task_group_id: smsChatbotSendTask.task_group_id,
    flow_id: taskDefinition?.flow_id,
    to_number:
      taskDefinition?.to_number.startsWith('{{') &&
      taskDefinition?.to_number.endsWith('}}')
        ? taskDefinition?.to_number
        : `{{${taskDefinition?.to_number || ''}}}`,
  };
};

export const normalizeSMSChatbot = (
  smsChatbotState: SMSChatbotState,
): NormalizedSMSChatbot => {
  const {id, task_group_id, to_number, flow_id} = smsChatbotState;
  return {
    type: 'chatbot_over_sms',
    id,
    task_group_id,
    params: {
      to_number,
      flow_id,
      recipient_external_source_id:
        '{{$.entity_candidate_fetch.response.entity_id}}',
      anchor_external_source_id:
        '{{$.entity_group_fetch.response.entity.externalSourceId}}',
    },
  };
};

export const getErrors = (validationsObject: Validations): Array<string> => {
  const errors = [];
  const {sendToNumberEmpty, invalidToNumber} = validationsObject;
  if (sendToNumberEmpty) {
    errors.push('Please fill the required fields.');
  }
  if (invalidToNumber) {
    errors.push('Send to number is Invalid.');
  }
  return errors;
};
