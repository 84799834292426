// @flow strict
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {
  ReferralTrackingPayload,
  GetReferralTrackingApiResponse,
  GetReferralPayoutsApiResponse,
  ReferralUpdateAction,
  GenericError,
  JobsPayload,
  Status,
} from 'src/types/referral-v2';
import type {Task} from 'src/types/data-export-report';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api.js';
import {DEFAULT_ERROR} from 'src/action-creators/referral-v2/referral-jobs.js';
import {AnalyticsService} from 'src/analytics';
import {
  //$FlowFixMe[nonstrict-import]
  showApiError,
} from 'src/action-creators/modal';
import {toastApi} from '@spaced-out/ui-design-system/lib/components/index';
import {toastMessage} from 'src/components/referral-v2/tracking/referral-confirm-modal.jsx';
import {successMessage} from 'src/components/referral-v2/timesheet/timesheet-modal.jsx';
import {displayTime} from 'src/utils/date-time-2';
// $FlowFixMe[untyped-import]
import {camel} from 'src/utils';
// $FlowFixMe[nonstrict-import]
import {ApiError} from 'src/utils/redux-api-v2';


export const REFERRAL_TRACKING_LOADING = 'referral/referral-tracking-loading';
export const REFERRAL_TRACKING = 'referral/referral-tracking';
export const REFERRAL_TRACKING_ERROR = 'referral/referral-tracking-error';

export const REFERRAL_PAYOUTS_LOADING = 'referral/referral-payouts-loading';
export const REFERRAL_PAYOUTS = 'referral/referral-payouts';
export const REFERRAL_PAYOUTS_ERROR = 'referral/referral-payouts-error';

export const REFERRAL_TRACKING_UPDATE_ACTION_LOADING =
  'referral/referral-tracking-update-action-loading';
export const REFERRAL_TRACKING_UPDATE_ACTION_ERROR =
  'referral/referral-tracking-update-action-error';
export const REFERRAL_TRACKING_UPDATE_ACTION_STATUS =
  'referral/referral-tracking-update-action-status';

export const RECEIVE_TIME_SHEET_DATA = 'referral/receive-time-sheet-data';

type ReferralTrackingAction = {
  type: typeof REFERRAL_TRACKING,
  payload: GetReferralTrackingApiResponse,
};

type ReferralTrackingLoadingAction = {
  type: typeof REFERRAL_TRACKING_LOADING,
};

type ReferralTrackingErrorAction = {
  type: typeof REFERRAL_TRACKING_ERROR,
  payload: ?GenericError,
};

type ReferralPayoutsAction = {
  type: typeof REFERRAL_PAYOUTS,
  payload: GetReferralPayoutsApiResponse,
};
type ReferralPayoutsLoadingAction = {
  type: typeof REFERRAL_PAYOUTS_LOADING,
};

type ReferralPayoutsErrorAction = {
  type: typeof REFERRAL_PAYOUTS_ERROR,
  payload: ?GenericError,
};

type ReferralTrackingUpdateLoadingAction = {
  type: typeof REFERRAL_TRACKING_UPDATE_ACTION_LOADING,
};

type ReferralTrackingUpdateErrorAction = {
  type: typeof REFERRAL_TRACKING_UPDATE_ACTION_ERROR,
  payload: ?GenericError,
};

type ReferralTrackingUpdateStatusAction = {
  type: typeof REFERRAL_TRACKING_UPDATE_ACTION_STATUS,
  payload: ?Status,
};

type TimeSheetDataAction = {
  type: typeof RECEIVE_TIME_SHEET_DATA,
  payload: ?Task,
};

export type ReferralTrackingActions =
  | ReferralPayoutsAction
  | ReferralPayoutsLoadingAction
  | ReferralPayoutsErrorAction
  | ReferralTrackingAction
  | ReferralTrackingLoadingAction
  | ReferralTrackingErrorAction
  | ReferralTrackingUpdateLoadingAction
  | ReferralTrackingUpdateErrorAction
  | TimeSheetDataAction;

export const getReferralTracking = (
  payload: GetReferralTrackingApiResponse,
): ReferralTrackingAction => ({
  type: REFERRAL_TRACKING,
  payload,
});

export const getReferralTrackingLoading =
  (): ReferralTrackingLoadingAction => ({
    type: REFERRAL_TRACKING_LOADING,
  });

export const getReferralTrackingError = (
  payload: ?GenericError,
): ReferralTrackingErrorAction => ({
  type: REFERRAL_TRACKING_ERROR,
  payload,
});

export const getReferralPayouts = (
  payload: GetReferralPayoutsApiResponse,
): ReferralPayoutsAction => ({
  type: REFERRAL_PAYOUTS,
  payload,
});

export const getReferralPayoutsLoading = (): ReferralPayoutsLoadingAction => ({
  type: REFERRAL_PAYOUTS_LOADING,
});

export const getReferralPayoutsError = (
  payload: ?GenericError,
): ReferralPayoutsErrorAction => ({
  type: REFERRAL_PAYOUTS_ERROR,
  payload,
});

export const getReferralTrackingUpdateActionLoading =
  (): ReferralTrackingUpdateLoadingAction => ({
    type: REFERRAL_TRACKING_UPDATE_ACTION_LOADING,
  });

export const getReferralTrackingUpdateActionError = (
  payload: ?GenericError,
): ReferralTrackingUpdateErrorAction => ({
  type: REFERRAL_TRACKING_UPDATE_ACTION_ERROR,
  payload,
});

export const getReferralTrackingUpdateActionStatus = (
  payload: ?Status,
): ReferralTrackingUpdateStatusAction => ({
  type: REFERRAL_TRACKING_UPDATE_ACTION_STATUS,
  payload,
});

export const receiveReceiveTimeSheetData = (
  payload: ?Task,
): TimeSheetDataAction => ({
  type: RECEIVE_TIME_SHEET_DATA,
  payload,
});

export const getReferralTrackingDetails: (
  payload: ReferralTrackingPayload,
  filterType?: string,
) => ThunkAction<void> = flow(
  key(
    (payload, filterType) =>
      `referral/tracking/?archived=${filterType === 'inactive' ? 'true' : ''}`,
  ),
  fetching(),
)(
  (payload: ReferralTrackingPayload, filterType?: string) =>
    (dispatch: Dispatch) => {
      dispatch(getReferralTrackingLoading());
      const url = `referral/tracking?archived=${
        filterType === 'inactive' ? 'true' : ''
      }`;

      return dispatch(reduxApi.post(url, payload)).then(
        (response: GetReferralTrackingApiResponse) => {
          dispatch(getReferralTracking(response));
        },
        (error) => {
          dispatch(
            getReferralTrackingError(error?.response?.errors || DEFAULT_ERROR),
          );
        },
      );
    },
);

export const getReferralPayoutsDetails: (
  payload: ReferralTrackingPayload,
  filterType?: string,
) => ThunkAction<void> = flow()(
  (payload: ReferralTrackingPayload, filterType?: string) =>
    (dispatch: Dispatch) => {
      dispatch(getReferralPayoutsLoading());
      return dispatch(
        reduxApi.post(
          `referral/payout?archived=${filterType === 'inactive' ? 'true' : ''}`,
          payload,
        ),
      ).then(
        (response: GetReferralPayoutsApiResponse) => {
          dispatch(getReferralPayouts(response));
        },
        (error) => {
          dispatch(
            getReferralPayoutsError(error?.response?.errors || DEFAULT_ERROR),
          );
        },
      );
    },
);

export const updateReferralAction =
  ({
    payload,
    detailsProps,
    name,
    from,
    filterType,
  }: {
    payload: ReferralUpdateAction[],
    detailsProps: ReferralTrackingPayload,
    name?: string,
    from?: string,
    filterType?: string,
  }): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    const isTracking = from === 'tracking';
    dispatch(getReferralTrackingUpdateActionLoading());
    const archiveMessage = payload[0].message;
    const baseRoute = isTracking ? 'tracking' : 'payout';
    const url = `referral/${baseRoute}${
      archiveMessage ? (isTracking ? '' : 's/archive') : ''
    }`;
    try {
      await dispatch(reduxApi.put(url, {data: payload}));
      if (isTracking) {
        dispatch(getReferralTrackingDetails(detailsProps, filterType || ''));
      } else {
        dispatch(getReferralPayoutsDetails(detailsProps));
      }
      toastApi.show(
        toastMessage({message: generateMessage(payload, name, archiveMessage)}),
      );
    } catch (error) {
      dispatch(showApiError(error, error?.response?.message));
      dispatch(
        getReferralTrackingUpdateActionError(
          error?.response?.errors || DEFAULT_ERROR,
        ),
      );
    }
  };

export const bulkReferralStatusUpdate =
  (
    filtersPayload: ReferralTrackingPayload,
    status: string,
    from: string,
  ): ThunkAction<mixed> =>
  (dispatch: Dispatch) => {
    let payload;
    if (from === 'tracking') {
      payload = {filters: filtersPayload.filters, status};
    } else {
      payload = {filters: filtersPayload.filters, action: status};
    }
    return dispatch(reduxApi.post(`referral/${from}/bulk-update`, payload))
      .then(() => {
        if (from === 'tracking') {
          dispatch(getReferralTrackingDetails(filtersPayload));
        } else {
          dispatch(getReferralPayoutsDetails(filtersPayload));
        }
        toastApi.show(
          toastMessage({message: `candidates have been Marked As ${status}.`}),
        );
      })
      .catch((error) => {
        dispatch(showApiError(error, error?.response?.message));
      });
  };

export const updatePayoutBonus =
  ({
    payload,
    payoutFilters,
  }: {
    payload: JobsPayload,
    payoutFilters: ReferralTrackingPayload,
  }): ThunkAction<mixed> =>
  (dispatch: Dispatch) => {
    return dispatch(reduxApi.put(`referral/payouts/update`, payload))
      .then(() => {
        return dispatch(getReferralPayoutsDetails(payoutFilters));
      })
      .catch((error) => {
        dispatch(showApiError(error, error?.response?.message));
      });
  };

export const uploadTimeSheetFile =
  (
    data: void | {...},
    loggedInUserId: number,
    filtersPayload: ReferralTrackingPayload,
  ): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    try {
      const response = await dispatch(
        reduxApi.post('referral/timesheet/process', data),
      );
      const task: Task = await dispatch(
        startPolling(response.task_id, loggedInUserId),
      );
      toastApi.show(successMessage({time: displayTime(task.timeUpdated)}));
      dispatch(getReferralPayoutsDetails(filtersPayload));
      dispatch(receiveReceiveTimeSheetData(camel(task)));
    } catch (error) {
      if (error instanceof ApiError) {
        dispatch(showApiError(error, error?.response?.message));
      }
    }
  };

const polls = new Map();
export const startPolling =
  (taskId: string, loggedInUserId?: number): ThunkAction<Task> =>
  (dispatch: Dispatch): Promise<Task> =>
    new Promise((resolve, reject) => {
      let timeout = 2000;

      const poll = async () => {
        const currentTimeoutId = polls.get(taskId);

        if (currentTimeoutId) {
          clearTimeout(currentTimeoutId);
        }

        let task: Task;
        try {
          task = camel(
            await dispatch(reduxApi.get(`referral/timesheet/status`)),
          );
        } catch (error) {
          reject(error);
          return;
        }
        dispatch(receiveReceiveTimeSheetData(camel(task)));
        if (
          ['INIT', 'PROCESSING'].includes(task.status) &&
          task.agentId === loggedInUserId
        ) {
          timeout = Math.min(timeout * 2, 30000);
          polls.set(taskId, setTimeout(poll, timeout));
        } else {
          resolve(task);
          polls.delete(taskId);
          return task;
        }
      };
      poll();
    });

const generateMessage = (
  payload: ReferralUpdateAction[],
  name?: string,
  archiveMessage = false,
): string => {
  const item = payload[0];
  const messageSuffix = archiveMessage ? 'Archived' : '';
  if (payload.length === 1 && name && !item.job_id) {
    return `${name} has been Marked As ${item.status || messageSuffix}.`;
  }
  if (!item.status && item.job_id && name) {
    return `${name} has been assigned a job.`;
  }
  AnalyticsService.track('Status Changed', {
    status: item.status || messageSuffix,
  });
  return `${payload.length} candidates have been Marked As ${
    item.status || messageSuffix
  }.`;
};
