// @flow

import type {SenseEvent} from 'src/types/events';


type ReceiveEventsAction = {
  type: 'events/receive',
  payload: SenseEvent[],
};

export type EventsAction = ReceiveEventsAction;

export const RECEIVE_EVENTS = 'events/receive';

export const receiveEvents = (events: SenseEvent[]): ReceiveEventsAction => ({
  type: RECEIVE_EVENTS,
  payload: events,
});
