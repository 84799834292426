// @flow

import type {State} from 'src/reducers/index';
import type {
  AtsAnalyticsFieldMapping,
  AtsAnalyticsFieldValues,
  AllowedTearsheetTypes,
} from 'src/types/ats-settings';

import {createSelector} from 'reselect';
import keyBy from 'lodash/keyBy';


export const getAtsFieldMappings = (state: State): AtsAnalyticsFieldMapping[] =>
  state.atsSettings.mappings;
export const selectAllowedTearsheetTypes = (
  state: State,
): AllowedTearsheetTypes => state.atsSettings.allowedTearsheetTypes;

const selectAtsFieldMappingsKeyedById = createSelector(
  getAtsFieldMappings,
  (atsFieldMappings) => keyBy(atsFieldMappings, 'id'),
);

export const getAtsFieldValues = (state: State): AtsAnalyticsFieldValues =>
  state.atsSettings.fieldValues;

export const selectAtsFieldValues: (
  state: State,
) => AtsAnalyticsFieldMapping[] = createSelector(
  selectAtsFieldMappingsKeyedById,
  getAtsFieldValues,
  (keyedAtsFieldMappings, atsFieldValues) =>
    // $FlowFixMe[prop-missing]
    Object.keys(atsFieldValues).map((fieldValueId) => ({
      ...atsFieldValues[fieldValueId],
      ...keyedAtsFieldMappings[fieldValueId],
    })),
);

export const selectAtsFieldMappingsKeyedByAnalyticsField: (
  state: State,
) => {[string]: AtsAnalyticsFieldMapping} = createSelector(
  getAtsFieldMappings,
  (atsFieldMappings) => keyBy(atsFieldMappings, 'analyticsField'),
);
