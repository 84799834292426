// @flow strict
import * as React from 'react';
import type {LinkJobsPrograms} from 'src/types/referral-v2';

import {useDispatch, useSelector} from 'react-redux';
import {useAsyncDependency} from 'src/hooks/useAsyncDependency';

import {
  fetchProgramsForJobs,
  linkJobsToPrograms,
} from 'src/action-creators/referral-v2/referral-jobs.js';
//$FlowFixMe
import {popModal} from 'src/action-creators/modal.js';

import CloseIcon from 'src/images/designSystems2021/modal-close-icon.svg';
import {Header} from 'src/designSystem2021Components/text-v2.jsx';
import {SearchInput} from 'src/designSystem2021Components/search-input/search-input.jsx';
import {Button} from 'src/designSystem2021Components/buttons.jsx';

import Loader from 'src/components/lib/loading';

import {
  CheckboxGroup,
  Checkbox,
} from '@spaced-out/ui-design-system/lib/components/Checkbox';
//$FlowFixMe[nonstrict-import]
import {AutoTruncatedText} from 'src/components/lib/truncated-text/truncated-text.jsx';

import css from './bulk-link-jobs.css';


const BulkLinkJobs = ({
  selectedItemsInPage,
  isAllSelected,
  programFilterId,
  totalJobRecords,
  pageNumber,
}: {
  selectedItemsInPage: string[],
  isAllSelected: boolean,
  programFilterId: string,
  totalJobRecords?: number,
  pageNumber: number,
}): React.Node => {
  const dispatch = useDispatch();

  const [searchKey, setSearchKey] = React.useState('');
  const [selectedPrograms, setSelectedPrograms] = React.useState([]);

  const programsForJobs: ?(LinkJobsPrograms[]) = useSelector(
    (state) => state.referralV2.programsForJobs,
  );

  const linkJobs = async () => {
    const body = {
      job_ids: selectedItemsInPage,
      program_ids: selectedPrograms,
      action_type: 'link',
      applies_to_all: isAllSelected,
    };
    await dispatch(linkJobsToPrograms(body, programFilterId, pageNumber));
  };

  const dependencies = useAsyncDependency(
    () =>
      dispatch(
        fetchProgramsForJobs({
          job_ids: selectedItemsInPage,
          program_id: programFilterId,
          applies_to_all: isAllSelected,
        }),
      ),
    [],
  );

  if (!programsForJobs || dependencies) {
    return <Loader />;
  }
  return (
    <div className={css.modalBackground}>
      <div className={css.modalHeader}>
        <div className={css.filterContent}>
          <Header>Link Jobs to program</Header>
        </div>
        <div onClick={() => dispatch(popModal())}>
          <CloseIcon className={css.pointer} />
        </div>
      </div>
      <div className={css.modalBody}>
        <SearchInput
          className={css.searchInput}
          value={searchKey}
          onChange={(value) => setSearchKey(value)}
          placeholder="Search"
        />
        <div className={css.programListContainer}>
          <CheckboxGroup
            name="programs"
            onChange={setSelectedPrograms}
            value={selectedPrograms}
            error={false}
          >
            {programsForJobs
              .filter((program) =>
                program.name.toLowerCase().includes(searchKey.toLowerCase()),
              )
              .map((program) => (
                <ProgramCheckbox
                  key={program.id}
                  program={program}
                  selectedItemsInPage={selectedItemsInPage}
                  value={program.id}
                  totalJobRecords={totalJobRecords}
                  isAllSelected={isAllSelected}
                />
              ))}
          </CheckboxGroup>
        </div>
        <div className={css.linkJobsBtn}>
          <Button
            disabled={selectedPrograms.length === 0}
            type="primary"
            onClick={linkJobs}
          >
            Link Jobs
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BulkLinkJobs;

type ProgramCheckboxPropTypes = {
  program: LinkJobsPrograms,
  selectedItemsInPage: string[],
  name?: string,
  checked?: boolean,
  onChange?: () => void,
  value: string,
  totalJobRecords?: number,
  isAllSelected: boolean,
};

const ProgramCheckbox = ({
  program,
  selectedItemsInPage,
  name,
  checked,
  onChange,
  value,
  totalJobRecords = 0,
  isAllSelected,
}: ProgramCheckboxPropTypes) => {
  const totalSelectedJob = isAllSelected
    ? totalJobRecords
    : selectedItemsInPage.length;

  const linkedJobs = totalSelectedJob - program.linked_jobs;

  return (
    <div className={css.eachProgram}>
      <div className={css.eachProgramLeft}>
        <Checkbox
          name={name}
          checked={checked}
          onChange={onChange}
          value={value}
        >
          <AutoTruncatedText
            line={1}
            showFullTextOnHover={true}
            text={program.name}
          />
        </Checkbox>
      </div>
      <div className={css.eachProgramRight}>
        {program.linked_jobs > 0 ? (
          <div>
            <strong className={css.primaryText}>
              {linkedJobs}/{totalSelectedJob}
            </strong>
            &nbsp; jobs will be linked, {program.linked_jobs} already exists
          </div>
        ) : (
          <div>
            <strong className={css.primaryText}>{totalSelectedJob}</strong>
            &nbsp; jobs will be linked
          </div>
        )}
      </div>
    </div>
  );
};
