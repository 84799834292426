// @flow

import exenv from 'exenv';
import {BrowserHistory, ServerHistory, type RouterHistory} from 'src/rerouter';

/**
 * NOTE (kyle): Wherever possible, use `useHistory` instead of this singleton.
 * It will be difficult to refactor any code that uses it in the future. Plus
 * the ServerHistory is mostly a noop.
 */
export const browserHistory: RouterHistory = exenv.canUseDOM
  ? new BrowserHistory()
  : new ServerHistory();
