// @flow strict

// $FlowFixMe[nonstrict-import]
import type {ThunkAction} from 'src/reducers';

import type {AudienceList} from 'src/types/audience-list';
import type {AutomationWorkflowAudienceState} from 'src/reducers/automation-workflow-audience';

import {normalizeAudienceBlocks} from 'src/utils/audience-list';
import {getListsById} from 'src/action-creators/audience-lists';
import {getAutomationWorkflow} from 'src/action-creators/automation-workflow';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';


export type AudienceFormSteps =
  | 'createMode'
  | 'details'
  | 'copyLists'
  | 'rules'
  | 'review'
  | 'pickNLink'
  | 'summary';
export type UpdateStatePayload = $Shape<AutomationWorkflowAudienceState>;

export const UPDATE_STATE = 'automation-workflow-audience/update';
export const RECEIVE_SELECTED_LISTS =
  'automation-workflow-audience/receive-selected-lists';
export const SET_INCLUDED_LISTS =
  'automation-workflow-audience/set-included-lists';
export const SET_EXCLUDED_LISTS =
  'automation-workflow-audience/set-excluded-lists';
export const RESET = 'automation-workflow-audience/reset';
export const GOTO_STEP = 'automation-workflow-audience/goto-step';

type UpdateStateAction = {
  type: 'automation-workflow-audience/update',
  payload: UpdateStatePayload,
};
type ReceiveSelectedListsAction = {
  type: 'automation-workflow-audience/receive-selected-lists',
  payload: {
    includedLists: Map<string, $Shape<AudienceList>>,
    excludedLists: Map<string, $Shape<AudienceList>>,
  },
};
type SetIncludedListsAction = {
  type: 'automation-workflow-audience/set-included-lists',
  payload: Map<string, $Shape<AudienceList>>,
};
type SetExcludedListsAction = {
  type: 'automation-workflow-audience/set-excluded-lists',
  payload: Map<string, $Shape<AudienceList>>,
};
type ResetStateAction = {
  type: 'automation-workflow-audience/reset',
};

export type AutomationWorkflowAudienceAction =
  | UpdateStateAction
  | ReceiveSelectedListsAction
  | SetIncludedListsAction
  | SetExcludedListsAction
  | ResetStateAction;

export const updateState = (
  payload: UpdateStatePayload,
): UpdateStateAction => ({
  type: UPDATE_STATE,
  payload,
});

export const receiveSelectedLists = (payload: {
  includedLists: Map<string, $Shape<AudienceList>>,
  excludedLists: Map<string, $Shape<AudienceList>>,
}): ReceiveSelectedListsAction => ({
  type: RECEIVE_SELECTED_LISTS,
  payload,
});
export const setIncludedLists = (
  payload: Map<string, $Shape<AudienceList>>,
): SetIncludedListsAction => ({
  type: SET_INCLUDED_LISTS,
  payload,
});
export const setExcludedLists = (
  payload: Map<string, $Shape<AudienceList>>,
): SetExcludedListsAction => ({
  type: SET_EXCLUDED_LISTS,
  payload,
});
export const reset = (): ResetStateAction => ({type: RESET});
export const linkAudienceListsToAutomationWorkflow: (
  journeyId: string,
) => ThunkAction<mixed> =
  (automationWorkflowId) => async (dispatch, getState) => {
    const includedListIds = [
      ...getState().automationWorkflowAudience.includedLists.keys(),
    ];
    const excludedListIds = [
      ...getState().automationWorkflowAudience.excludedLists.keys(),
    ];
    await dispatch(
      reduxApi.put(`workflow/${automationWorkflowId}/mappings`, {
        included_filter_ids: includedListIds,
        excluded_filter_ids: excludedListIds,
      }),
    );
    //also update the automation workflow state
    dispatch(getAutomationWorkflow(automationWorkflowId));
    return {includedListIds, excludedListIds};
  };
export const updateSelectedLists: (listDetails: {
  includedLists: Map<string, $Shape<AudienceList>>,
  excludedLists: Map<string, $Shape<AudienceList>>,
}) => ReceiveSelectedListsAction = (listDetails) =>
  receiveSelectedLists(listDetails);

export const getAutomationWorkflowAudience: (
  automationWorkflowId: string,
) => ThunkAction<mixed> = flow(
  key(
    (automationWorkflowId) =>
      `automationWorkflowAudience/get-${automationWorkflowId}`,
  ),
  fetching(),
)((automationWorkflowId) => async (dispatch) => {
  const response = await dispatch(
    reduxApi.get(`/workflow/${automationWorkflowId}/mappings`),
  );

  const listIds = [...response.included, ...response.excluded];
  //get all the workflow details
  //$FlowFixMe[incompatible-call]
  const listsDetailResponse = await dispatch(getListsById(listIds));
  const listDetailsMap = new Map(
    listsDetailResponse?.filters.map((list) => [list.id, list]),
  );
  const includedLists = new Map(
    response.included.map((listId) => [listId, listDetailsMap.get(listId)]),
  );

  const excludedLists = new Map(
    response.excluded.map((listId) => [listId, listDetailsMap.get(listId)]),
  );
  dispatch(receiveSelectedLists({includedLists, excludedLists}));
  return response;
});

export const createAudienceListAndLink: (
  automationWorkflowId: string,
) => ThunkAction<mixed> = (automationWorkflowId) => (dispatch, getState) => {
  const audienceList = getState().audienceList;
  const {name, entityType, inclusions, exclusions, all} = audienceList;
  const rule = normalizeAudienceBlocks({inclusions, exclusions, all});
  //create
  const apiPayload = {
    name,
    rule,
    entityType,
    source: 'LIST_UI',
  };

  return dispatch(
    reduxApi.post(
      `/workflow/${automationWorkflowId}/list-create-and-map`,
      apiPayload,
    ),
  ).then(() => dispatch(getAutomationWorkflow(automationWorkflowId)));
};
