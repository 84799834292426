// @flow strict

import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Button} from 'src/designSystem2021Components/buttons.jsx';
import {Input} from 'src/components/agency-onboard/lib/input/input.jsx';
import {Collapsible} from 'src/designSystem2021Components/collapsible/collapsible.jsx';
import {Clickable, SubHeader} from 'src/designSystem2021Components/text-v2.jsx';
import classify from 'src/utils/classify';

import {verifyCreds} from 'src/components/agency-onboard/api/datasync.js';

//$FlowFixMe[nonstrict-import]
import {useClipboard} from 'src/hooks/useClipboard';
import {showToastMessage} from 'src/action-creators/toasts';
import {DefaultFooter} from 'src/components/agency-onboard/footer/default-footer.jsx';
import {
  AtsCredTemplate,
  BullHornSupportLink,
} from 'src/components/agency-onboard/constants.jsx';

import {VARIANTS} from 'src/designSystem2021Components/toast';
//$FlowFixMe[nonstrict-import]
import {selectAgencyData} from 'src/selectors/agency';
import {AnalyticsService} from 'src/analytics';
import {AMPLITUDE_EVENTS} from 'src/components/agency-onboard/amplitude_event.js';
import type {Task} from 'src/components/agency-onboard/types/side-panel-comp.js';

import maincss from 'src/components/agency-onboard/agency-onboard.css';
import css from 'src/components/agency-onboard/side-panels/start-data-sync.css';
import designcss from 'src/components/design-doc.css';
import ExternalLinkIcon from 'src/images/icons/ext-link-nofill.svg';
import ClipboardIcon from 'src/images/icons/clipboard-2.svg';


type FieldType = {
  type: 'text' | 'number' | 'password' | 'email' | 'tel' | 'url',
  label: string,
  value: string,
  name: string,
};

type StartDataSyncProps = {
  task: Task,
  onTaskSubmit: (id: string, value: mixed) => mixed,
  onTaskCancel: () => mixed,
  data: Array<FieldType>,
};

export const StartDataSyncSidePanel = (
  props: StartDataSyncProps,
): React.Node => {
  const {data, task, onTaskCancel, onTaskSubmit} = props;

  const dispatch = useDispatch();
  const agencyData = useSelector(selectAgencyData);

  const [creds, setCreds] = React.useState(
    data.reduce((acc, cur) => ({...acc, [cur.name]: cur.value}), {}),
  );
  const [disableVerify, setDisableVerify] = React.useState(true);
  const [disableSubmit, setDisableSubmit] = React.useState(true);
  const [isCredsVerified, setIsCredsVerified] = React.useState(false);
  const [isCredsChecked, setIsCredsChecked] = React.useState(false);
  const [isCheckingCreds, setIsCheckingCreds] = React.useState(false);

  React.useEffect(() => {
    setDisableVerify(!isCredsEntered());
  }, [creds]);

  const handleTrackingEvent = (event) => {
    AnalyticsService.track(event, {
      agencyId: agencyData.id,
      agencyName: agencyData.name,
    });
  };

  const isCredsEntered = () => {
    let credsEntered = true;
    Object.keys(creds).forEach((key) => {
      if (!creds[key]) {
        credsEntered = false;
      }
    });
    return credsEntered;
  };

  const handleValueChange = (event) => {
    const current_data = {...creds};
    current_data[event.target.name] = event.target.value;
    setCreds(current_data);
  };

  const validateCreds = () => {
    setIsCheckingCreds(true);
    dispatch(verifyCreds(creds))
      .then((response) => {
        setIsCredsVerified(response.is_valid);
        setIsCredsChecked(true);
        setDisableSubmit(!response.is_valid);
        setIsCheckingCreds(false);
        if (response.is_valid) {
          handleTrackingEvent(AMPLITUDE_EVENTS.VERIFY_CREDS_BUTTON_CLICKED);
        }
      })
      .catch((error) => {
        dispatch(
          showToastMessage(`Error Verifying credentials`, {
            variant: VARIANTS.ERROR,
          }),
        );
        setIsCheckingCreds(false);
      });
  };

  const [_hasCopiedTemplate, handleTemplateCopy] =
    useClipboard(AtsCredTemplate);

  const showTemplateCopyToast = () => {
    dispatch(
      showToastMessage(`Copied Template`, {
        variant: VARIANTS.SUCCESS,
      }),
    );
  };

  const handleFormSubmit = async () => {
    setDisableSubmit(true);
    await onTaskSubmit(task['id'], creds);
    setDisableSubmit(false);
  };

  const handleFormCancel = () => {
    onTaskCancel();
  };

  return (
    <div className={maincss.sidePanel}>
      <Collapsible
        title="Don't have ATS Credentials? Here's how you can get them!"
        containerClassName={designcss.collapsibleBlock}
        headerClassName={css.headerText}
        isOpen
      >
        <div className={css.getHelpContainer}>
          <div className={css.getHelpDiv}>
            <SubHeader
              className={classify(maincss.textPrimary, css.getAtsCredsList)}
            >
              Step 1:
            </SubHeader>
            <div className={css.extActionStep}>
              <SubHeader
                className={classify(maincss.text, css.getAtsCredsText)}
              >
                Go to Bullhorn Support and login
              </SubHeader>
              <a
                href={BullHornSupportLink}
                className={css.extAction}
                target="_blank"
                onClick={() =>
                  handleTrackingEvent(
                    AMPLITUDE_EVENTS.GO_TO_BULLHORN_SUPPORT_CLICKED,
                  )
                }
              >
                <div className={css.icon}>
                  <ExternalLinkIcon className={css.icon} />
                </div>
                <Clickable className={css.extActionText}>
                  {' '}
                  Go to Bullhorn Support{' '}
                </Clickable>
              </a>
            </div>
          </div>
          <div className={css.getHelpDiv}>
            <SubHeader
              className={classify(maincss.textPrimary, css.getAtsCredsList)}
            >
              Step 2:
            </SubHeader>
            <div className={css.extActionStep}>
              <SubHeader
                className={classify(maincss.text, css.getAtsCredsText)}
              >
                Create a ticket with below template
              </SubHeader>
              <div
                className={css.extAction}
                onClick={() => {
                  handleTemplateCopy();
                  showTemplateCopyToast();
                  handleTrackingEvent(AMPLITUDE_EVENTS.COPY_TEMPLATE_CLICKED);
                }}
              >
                <div className={css.icon}>
                  <ClipboardIcon className={css.icon} />
                </div>
                <Clickable className={css.extActionText}>
                  {' '}
                  Copy Template{' '}
                </Clickable>
              </div>
            </div>
          </div>
          <div>
            <Clickable className={css.ticketTemplate}>
              {AtsCredTemplate}
            </Clickable>
          </div>
          <div className={css.getHelpDiv}>
            <SubHeader
              className={classify(maincss.textPrimary, css.getAtsCredsList)}
            >
              Step 3:
            </SubHeader>
            <SubHeader className={classify(maincss.text, css.getAtsCredsText)}>
              Done! Your Implementation Manager will receive the credentials and
              will do the next steps for you.
            </SubHeader>
          </div>
        </div>
      </Collapsible>

      <hr className={maincss.dividerLine}></hr>

      <Collapsible
        title="Enter ATS Credentials"
        containerClassName={designcss.collapsibleBlock}
        headerClassName={css.headerText}
      >
        <div className={css.credentialBox}>
          <div className={css.credsForm}>
            {data.map((field, index) => (
              <div className={maincss.inputBox} key={index}>
                <Input
                  className={css.inputField}
                  type={field.type}
                  label={field.label}
                  boxClassName={maincss.outerBoxInputField}
                  value={creds[field.name] || ''}
                  onChange={(event) => handleValueChange(event)}
                  name={field.name}
                />
              </div>
            ))}
          </div>
          <div className={css.credVerifierDiv}>
            {isCredsChecked && isCredsVerified && (
              <span className={css.validCreds}> Credentials Verified </span>
            )}
            {isCredsChecked && !isCredsVerified && (
              <span className={css.invalidCreds}> Credentials Not Valid </span>
            )}
            <div className={css.verifyBtnDiv}>
              <Button
                type="secondary"
                className={css.verifyBtn}
                onClick={() => validateCreds()}
                disabled={disableVerify || isCheckingCreds}
              >
                <Clickable>
                  {' '}
                  {isCheckingCreds ? 'Verifying' : 'Verify Credentials'}{' '}
                </Clickable>
              </Button>
            </div>
          </div>
        </div>
      </Collapsible>
      <DefaultFooter
        disableSubmit={disableSubmit}
        onSubmit={handleFormSubmit}
        onCancel={handleFormCancel}
      />
    </div>
  );
};
