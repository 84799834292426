// @noflow

import type {EventType} from 'src/api-parsers';

import * as React from 'react';

import EventMessageIcon from 'src/images/event-types/message.svg';
import EventSurveyIcon from 'src/images/event-types/survey.svg';
import EventNpsIcon from 'src/images/event-types/nps.svg';


const EventIcon = ({nps, type, ...props}: {
  nps?: any,
  type?: EventType,
}) => {
  if (nps) {
    return <EventNpsIcon {...props} />;
  }

  switch (type) {
    case 'message':
    case 'sms_message':
      return <EventMessageIcon {...props} />;

    case 'survey':
    case 'sms_survey':
      return <EventSurveyIcon {...props} />;

    default:
      return <EventMessageIcon {...props} />;
  }
};

export default EventIcon;
