// @flow

import type {RouteLocation} from 'src/types/router';

import * as React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'src/rerouter';
import isNil from 'lodash/isNil';

import {classify} from 'src/utils';
import {selectTabErrors} from 'src/selectors/errors';

import ErrorAlertIcon from 'src/images/icons/error-alert-icon.svg';

import css from './tabs.css';


export type Tab = {
  url: string,
  name: string,
  onlyActiveOnIndex?: boolean,
  Icon?: React.ComponentType<{className: string}>,
  className?: string,
  onClick?: (SyntheticMouseEvent<HTMLElement>) => mixed,
  ...
};

type Props = {
  children?: React.Node,
  className?: string,
  tabs: Tab[],
  baseUrl: string,
  activeTabClassName?: string,
  tabClassName?: string,
  hideHeader?: boolean,
  handleTransition?: boolean,
};

const Tabs = ({
  children,
  className,
  tabs,
  baseUrl,
  tabClassName,
  activeTabClassName,
  hideHeader,
  handleTransition = false,
}: Props): React.Element<'div'> => {
  const {errorTabUrls} = useSelector(selectTabErrors);

  return (
    <div className={classify(css.tabBar, className)}>
      {!hideHeader && (
        <div
          className={classify(css.tabs, {
            [css.transitionRightForHelper]: handleTransition,
          })}
        >
          {tabs.map((tab) => {
            const {url = '', name, Icon, onlyActiveOnIndex, onClick} = tab;
            const fullUrl = baseUrl + url;
            const hasError = errorTabUrls.has(url.replace('/', ''));

            const tabLink = (
              <Link
                to={fullUrl}
                key={url}
                activeClassName={classify(
                  Icon !== null ? css.currentTab : css.currentTabNoIcon,
                  activeTabClassName,
                  {[css.errorTabActive]: hasError},
                )}
                className={classify(css.tab, tabClassName, {
                  [css.errorTab]: hasError,
                })}
                onlyActiveOnIndex={Boolean(onlyActiveOnIndex)}
                data-qa-id={`${name.toLowerCase()}-tab`}
                onClick={onClick}
              >
                {hasError ? (
                  <ErrorAlertIcon className={css.svgError} />
                ) : (
                  Icon != null && (
                    <Icon className={classify(css.svg, tab.className)} />
                  )
                )}
                {name}
              </Link>
            );

            return tabLink;
          })}
        </div>
      )}
      {
        // $FlowFixMe[sketchy-number-and]
        children && <div className={css.content}>{children}</div>
      }
    </div>
  );
};

export function getCurrentTab(
  location: RouteLocation,
  baseUrl: string,
  tabs: Array<Tab>,
): ?Tab {
  return tabs.find((tab) => {
    const trailingSlash = /\/$/;
    return (
      (baseUrl + tab.url).replace(trailingSlash, '') ===
      location.pathname.replace(trailingSlash, '')
    );
  });
}

export default Tabs;
