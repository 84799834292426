// @flow
import * as React from 'react';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import uniq from 'lodash/uniq';

import {format as prettierFormat} from 'prettier';
import prettierBaber from 'prettier/parser-babel';

// these disables come from the eslint import plugin being unable
// to handle the import from the highlightsjs package
// eslint-disable-next-line import/no-unresolved
import hljs from 'highlight.js/lib/core';
// eslint-disable-next-line import/no-unresolved
import langJS from 'highlight.js/lib/languages/javascript';
// eslint-disable-next-line import/no-unresolved
import langCSS from 'highlight.js/lib/languages/css';
import 'highlight.js/styles/github.css';

import classify from 'src/utils/classify';
import {useClipboard} from 'src/hooks/useClipboard';

import {
  Header,
  SubHeader,
  Clickable,
  Paragraph,
  Smallest,
} from 'src/designSystem2021Components/text-v2.jsx';
import {Input} from 'src/designSystem2021Components/input.jsx';
import {TabList} from 'src/designSystem2021Components/tablist.jsx';
import {
  Options,
  RadioOption,
  CheckboxOption,
} from 'src/designSystem2021Components/menu/options.jsx';
import {Button} from 'src/designSystem2021Components/buttons.jsx';
import {
  ClickableBox,
  SelectedBox,
  DefaultBackgroundBox,
  DefaultWhiteBox,
  ErrorBox,
} from 'src/designSystem2021Components/core.jsx';
import {Select} from 'src/designSystem2021Components/select.jsx';
import {Carousel} from 'src/designSystem2021Components/carousel.jsx';
import {Pagination} from 'src/designSystem2021Components/pagination.jsx';
import {Card} from 'src/designSystem2021Components/card.jsx';
import {SearchInput} from 'src/designSystem2021Components/search-input/search-input.jsx';
import {LabelFilter} from 'src/designSystem2021Components/label-filter/filter.jsx';
import {
  Checkbox,
  LabeledCheckbox,
} from 'src/designSystem2021Components/checkbox.jsx';
import {Toggle} from 'src/designSystem2021Components/toggle/toggle.jsx';
import {VerticalTimeline} from 'src/designSystem2021Components/vertical-timeline/vertical-timeline.jsx';
import {VerticalTimelineElement} from 'src/designSystem2021Components/vertical-timeline/vertical-timeline-element.jsx';
import {Collapsible} from 'src/designSystem2021Components/collapsible/collapsible.jsx';
import {FileUpload} from 'src/designSystem2021Components/file-upload.jsx';

import {VARIANTS, POSITIONS} from 'src/designSystem2021Components/toast';
import {showToastMessage} from 'src/action-creators/toasts';
import {pushSidepanel} from 'src/action-creators/modal';

import type {VerticalTimelineElementProps} from '../designSystem2021Components/vertical-timeline/vertical-timeline-element.jsx';
import {HorizontalTimeline} from '../designSystem2021Components/horizontal-timeline/horizontal-timeline.jsx';
import {HorizontalTimelineElement} from '../designSystem2021Components/horizontal-timeline/horizontal-timeline-element.jsx';
import {MenuButton} from 'src/designSystem2021Components/menu/menu-buttons.jsx';
import {Menu as MenuWithTrigger} from 'src/designSystem2021Components/menu/menu.jsx';
import {Radio} from 'src/designSystem2021Components/radio/radio.jsx';
import {Textarea} from 'src/designSystem2021Components/textarea.jsx';
import {AsyncSearch} from 'src/designSystem2021Components/async-search.jsx';
import {Banner} from 'src/designSystem2021Components/banner/banner.jsx';

import {
  Table,
  StaticTable,
  BasicSingleCell,
  BasicDoubleCell,
  DateCell,
  MonogramCell,
} from 'src/designSystem2021Components/table/index.jsx';

import {useDispatch} from 'react-redux';

import LinkIcon from 'src/images/designSystems2021/link-2.svg?noAttrs';
import LoadingIcon from 'src/images/icons/file-loading-icon.svg';

import css from './design-doc.css';
import dsCss from 'designSystem2021';


hljs.registerLanguage('javascript', langJS);
hljs.registerLanguage('css', langCSS);

const DesignDocContext = React.createContext<{
  rootPath: string,
  addDefaultExport?: (string) => mixed,
  addNamedExport?: (string) => mixed,
}>({
  rootPath: 'src/designSystem2021Components/',
  addDefaultExport: (name: string) => {},
  addNamedExport: (name: string) => {},
});

const Section = ({
  children,
  name,
  figmaLink,
  relPath,
}: {
  children: React.Node,
  name: string,
  figmaLink?: string,
  relPath?: string,
}) => {
  const [namedExports, setNamedExports] = React.useState([]);
  const [defaultExport, setDefaultExport] = React.useState();

  const importPath =
    React.useContext(DesignDocContext).rootPath + (relPath ?? '');

  const namedExportString =
    namedExports.length > 0
      ? `{${uniq(namedExports)
          .sort((a, b) => a.localeCompare(b))
          .sort((a, b) => a.length - b.length)
          .join(',')}}`
      : '*';
  const importString =
    namedExports.length > 0
      ? `import ${
          defaultExport ? defaultExport + ',' : ''
        } ${namedExportString} from '${importPath}';`
      : `import ${
          defaultExport ? defaultExport : '* as ' + upperFirst(camelCase(name))
        } from '${importPath}';`;

  return (
    <DesignDocContext.Provider
      value={{
        rootPath: importPath,
        addNamedExport: (name) => setNamedExports((prev) => [...prev, name]),
        addDefaultExport: (name) => setDefaultExport(name),
      }}
    >
      <div className={css.section}>
        <div className={css.sectionHeader}>
          <SubHeader>{name}</SubHeader>
          {figmaLink && (
            <a className={css.link} target="_blank" href={figmaLink}>
              <LinkIcon className={css.icon} />
            </a>
          )}
        </div>
        {relPath && <FormattedCode prettier>{importString}</FormattedCode>}
        {children}
      </div>
    </DesignDocContext.Provider>
  );
};

function DesignDoc(): React.Node {
  return (
    <div className={css.root}>
      <Header className={css.title}>Design System 2021</Header>

      <TextComponentsDemo />

      <FormInputDemo />

      <TabListDemo />

      <OptionsDemo />

      <MenuButtonsDemo />

      <MenuWithTriggerDemo />

      <ButtonsDemo />

      <BordersDemo />

      <BoxComponentDemo />

      <ToggleDemo />

      <CheckboxDemo />

      <CollapsibleDemo />

      <SelectDemo />

      <CarouselDemo />

      <CardDemo />

      <ToastDemo />

      <SearchInputDemo />
      <FileUploadDemo />

      <VerticalTimelineDemo />

      <HorizontalTimelineDemo />

      <TableDemo />
      <PaginationDemo />

      <SidepanelDemo />
      <LabelFilterDemo />

      <RadioDemo />
      <TextareaDemo />
      <AsyncSearchDemo />
      <BannerCompDemo />
    </div>
  );
}

export default function DesignDocPage(): React.Node {
  return (
    <DesignDocContext.Provider
      value={{rootPath: 'src/designSystem2021Components/'}}
    >
      <DesignDoc />
    </DesignDocContext.Provider>
  );
}

const formatCode = (someCode: string) =>
  prettierFormat(someCode.trim(), {
    plugins: [prettierBaber],
    parser: 'babel',
  });

export const FormattedCode = ({
  children,
  prettier,
}: {
  children: string,
  prettier?: boolean,
}): React.Node => {
  const trimmed = children.trim();
  const code = prettier ? formatCode(trimmed) : trimmed;

  const [_didCopyImportPath, copyImportPath] = useClipboard(code);

  const getHighlightedCode = () => {
    const hl = hljs.highlight(code, {language: 'javascript'});
    return {__html: hl.value};
  };
  return (
    <div
      className={classify(dsCss.defaultPadded, dsCss.bgDefaultBackground)}
      style={{
        display: 'block',
        whiteSpace: 'pre',
        font: '12px/13px "Andale Mono", Monaco, monospace',
        cursor: 'copy',
      }}
      dangerouslySetInnerHTML={getHighlightedCode()}
      onClick={copyImportPath}
    />
  );
};

const Export = ({
  name,
  defaultExport,
  children,
}: {
  name: string,
  defaultExport?: boolean,
  children?: React.Node,
}): React.Node => {
  const addNamedExport = React.useContext(DesignDocContext).addNamedExport;
  const addDefaultExport = React.useContext(DesignDocContext).addDefaultExport;
  React.useEffect(() => {
    defaultExport ? addDefaultExport?.(name) : addNamedExport?.(name);
  }, []);
  return children || null;
};

const getCopyableProps = ({
  children,
  ...componentProps
}: {
  icon?: React.Node,
  children?: string,
  key?: string,
  disabled?: boolean,
  isSelected?: boolean,
  isSelectable?: boolean,
  iconPosition?: string,
  isError?: boolean,
  type?: string,
  size?: string,
}) => {
  const componentMarkup = `<Button ${Object.keys(componentProps)
    .map((key) => {
      if (typeof componentProps[key] === 'boolean') {
        return componentProps[key] === true ? key : undefined;
      }
      if (key === 'icon' && componentProps[key] != null) {
        return 'icon={<LinkIcon />}';
      }
      if (key === 'key') {
        return '';
      }
      if (typeof componentProps[key] === 'string') {
        return `${key}="${componentProps[key]}"`;
      }
    })
    .join(' ')} ${children == null ? '/>' : '>' + children + '</Button>'}`;
  return formatCode(componentMarkup).trim();
};

const DemoButton = (props): React.Node => {
  // Apologies, this is complicated because the prettier transformation is
  // a bit expensive, so there's a ton of mouse enter/out chicanery to deal
  // with it.

  const timeoutId = React.useRef();
  const [title, setTitle] = React.useState(
    'Click to see source and copy to clipboard',
  );

  const [_didCopy, onClick] = useClipboard(title);

  React.useEffect(() => {});
  const {value, ...rest} = props;
  return (
    <Button
      {...rest}
      onClick={onClick}
      title={title}
      className={css.demoButton}
      onMouseOver={() => {
        if (timeoutId.current != null) {
          return;
        }
        const id = setTimeout(() => {
          setTitle(getCopyableProps({...rest, children: value}));
          timeoutId.current = null;
        }, 150);
        timeoutId.current = id;
      }}
      onMouseOut={() => {
        if (timeoutId.current != null) {
          clearTimeout(timeoutId.current);
          timeoutId.current = null;
        }
      }}
    >
      {value}
    </Button>
  );
};

const ButtonRow = ({
  value,
  size,
  type,
  disabled,
  isError,
  isSelected,
  isSelectable,
}) => {
  const propValues = {
    type: ['primary', 'secondary', 'tertiary'],
    iconPosition: ['left', 'right'],
    icon: [<LinkIcon className={css.iconLarge} />, undefined],
    isError: [true, false],
    disabled: [true, false],
    value, // any text
    size, // ['standard', 'small'],
    isSelectable, //['true', 'false'],
  };

  const defaultProps = {
    value,
    size,
    type,
    isError,
    isSelected,
    disabled,
    isSelectable,
  };
  const icon = (
    <LinkIcon className={size === 'small' ? css.iconSmall : css.iconLarge} />
  );
  const iconOnlyProps = {
    ...defaultProps,
    icon,
    value: undefined,
    iconPosition: undefined,
  };

  return (
    <div>
      <DemoButton {...defaultProps} />
      <React.Fragment>
        {propValues.iconPosition.map((iconPosition) => {
          const props = {...defaultProps, iconPosition, icon};
          return <DemoButton key={iconPosition} {...props} />;
        })}
      </React.Fragment>
      <DemoButton {...iconOnlyProps} />
    </div>
  );
};

const ButtonRows = (props: {
  value?: string,
  type?: 'primary' | 'secondary' | 'tertiary' | 'selectable',
  isError?: boolean,
  disabled?: boolean,
  isSelected?: boolean,
  isSelectable?: boolean,
}) => (
  <React.Fragment>
    {[undefined, 'small'].map((size) => (
      <ButtonRow key={size ?? 'standard'} {...{...props, size}} />
    ))}
  </React.Fragment>
);

const ButtonContent = (props: {value?: string}) => {
  const propValues = {
    type: ['primary', 'secondary', 'tertiary'],
    isSelected: [true, false],
    iconPositions: ['left', 'right', undefined],
    icon: [<LinkIcon className={css.iconLarge} />, undefined],
    size: ['standard', 'small'],
    isError: [true, false],
    disabled: [true, false],
  };

  return (
    <React.Fragment>
      {propValues.type.map((type) => (
        <React.Fragment key={type}>
          <SubHeader style={{textTransform: 'capitalize', marginTop: 16}}>
            {type}
          </SubHeader>
          <ButtonRows type={type} {...props} />
          <SubHeader style={{textTransform: 'capitalize', marginTop: 16}}>
            {type} error
          </SubHeader>
          <ButtonRows type={type} isError={true} {...props} />

          <SubHeader style={{textTransform: 'capitalize', marginTop: 16}}>
            {type} disabled
          </SubHeader>
          <ButtonRows type={type} disabled={true} {...props} />
          {['tertiary'].includes(type) && (
            <>
              <SubHeader style={{textTransform: 'capitalize', marginTop: 16}}>
                {type} selected (only when isSelectable=true)
              </SubHeader>
              <ButtonRows {...props} type={type} isSelectable isSelected />
              <SubHeader style={{textTransform: 'capitalize', marginTop: 16}}>
                {type} unselected (only when isSelectable=true)
              </SubHeader>
              <ButtonRows
                {...props}
                type={type}
                isSelectable={true}
                isSelected={false}
              />
            </>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

const ButtonsDemo = () => {
  const [buttonContent, setButtonContent] = React.useState('Link Candidate');

  return (
    <Section
      name="Buttons"
      figmaLink="https://www.figma.com/file/mtMlZYMHBqtoBedM6cpZ96/colors-%26-styles?node-id=681%3A932"
      relPath="buttons.jsx"
    >
      <Paragraph>
        Buttons have a ton of options, from type, to position of icon, and on
        and on. to make this easier, you can simply click on any button below
        and the component definition will appear in your clipboard. If you hover
        over the component long enough, it will display as its title.
      </Paragraph>
      <FormattedCode>
        {`
<Button type="primary" iconPosition="right" icon={<LinkIcon />} />;
        `}
      </FormattedCode>
      <Paragraph>
        The 'tertiary' variant has 2 extra states - selected and unselected.
        These states are only available when isSelectable is true. The selected
        state is controlled by isSelected prop.
      </Paragraph>
      <FormattedCode>
        {`
<Button type="tertiary" isSelectable isSelected />;
<Button type="tertiary" isSelectable isSelected={false} />;
        `}
      </FormattedCode>
      <Paragraph>
        This text input below allows you to see what the button will look like
        with different labels, but in practice you can do this just by throwing
        any React.Node as the children of the Button component.
      </Paragraph>

      <input
        type="text"
        className={classify(dsCss.defaultBorder, dsCss.defaultPadded)}
        value={buttonContent}
        placeholder="Button Text"
        onChange={(evt) => setButtonContent(evt.currentTarget.value)}
      />
      <Export name="Button" />

      <ButtonContent value={buttonContent} />
    </Section>
  );
};

const CollapsibleDemo = () => (
  <Section
    name="Collapsible"
    figmaLink=""
    relPath="collapsible/collapsible.jsx"
  >
    <div className={css.collapsible}>
      <Collapsible
        title="Normal Collapsible 1"
        containerClassName={css.collapsibleBlock}
      >
        <p>Lorem Ipsum</p>
      </Collapsible>
      <Collapsible
        title="Normal Collapsible 2"
        containerClassName={css.collapsibleBlock}
      >
        <p>Lorem Ipsum</p>
      </Collapsible>
      <Collapsible
        title="Normal Collapsible 3"
        containerClassName={css.collapsibleBlock}
      >
        <p>Lorem Ipsum</p>
      </Collapsible>
      <Collapsible
        title="Normal Collapsible 4"
        containerClassName={css.collapsibleBlock}
      >
        <p>Lorem Ipsum</p>
      </Collapsible>
      <Collapsible
        title="Open Collapsible"
        containerClassName={css.collapsibleBlock}
        isOpen
      >
        <p>Lorem Ipsum</p>
      </Collapsible>
    </div>
  </Section>
);

const BoxComponentDemo = () => (
  <Section name="Box Components" relPath="core.jsx" figmaLink="">
    <Export name="ClickableBox">
      <ClickableBox>
        <Smallest>ClickableBox</Smallest>
      </ClickableBox>
    </Export>

    <Export name="SelectedBox">
      <SelectedBox>
        <Smallest>SelectedBox</Smallest>
      </SelectedBox>{' '}
    </Export>

    <Export name="DefaultBackgroundBox">
      <DefaultBackgroundBox>
        <Smallest>DefaultBackgroundBox</Smallest>
      </DefaultBackgroundBox>
    </Export>

    <Export name="DefaultWhiteBox">
      <DefaultWhiteBox>
        <Smallest>DefaultWhiteBox</Smallest>
      </DefaultWhiteBox>
    </Export>

    <Export name="ErrorBox">
      <ErrorBox>
        <Smallest>ErrorBox</Smallest>
      </ErrorBox>
    </Export>

    <Paragraph>
      It's possible that these boxes don't do enough (i.e. you want to handle a
      different set of hover interactions or whatever) You can instead build
      them up from their utility classes.
    </Paragraph>

    <FormattedCode>
      {`
import dsCss from 'designSystem2021';
export default function() {
  return <div>
    <div className={dsCss.boxClickable} />
    <div className={dsCss.boxSelected} />
    <div className={dsCss.boxDefaultBackground} />
    <div className={dsCss.boxDefaultWhite} />
    <div className={dsCss.boxError} />
  </div>
}
      `}
    </FormattedCode>
    <Paragraph>Or use these classes to enable hover effects like so</Paragraph>
    <FormattedCode>
      {`
.myInteractiveBox {
  composes: boxClickable from 'designSystem2021';
}
.myInteractiveBox:hover {
  composes: boxSelected from 'designSystem2021';
}
      `}
    </FormattedCode>
  </Section>
);

const CheckboxDemo = () => (
  <Section
    name="Checkbox"
    relPath="checkbox.jsx"
    figmaLink="https://www.figma.com/file/T64DM8GIUZqVavdsBlhP5F/2.0-hi-fi-components-%26-icons?node-id=40%3A3600"
  >
    <Paragraph>
      We support a three state checkbox and as a result the onChange values that
      you receive is not boolean, but instead one of three values:{' '}
      <code>'true' | 'false' | 'mixed'</code>
    </Paragraph>
    <Paragraph>
      in addition to the onChange prop, you can set the <code>disabled</code>{' '}
      prop as well as setting <code>tabIndex</code> and as always{' '}
      <code>className</code>.
    </Paragraph>
    <Paragraph>
      although the checkbox supports a sort of pseudo uncontrolled state, you
      should always treat it as a controlled component. Uncontrolled use is only
      good for prototyping.
    </Paragraph>
    <FormattedCode>
      {`
<LabeledCheckbox checked="true">
  LabeledCheckbox (checked is 'true')
</LabeledCheckbox>
      `}
    </FormattedCode>

    <div className={css.labeledCheckboxContainer}>
      <Export name="LabeledCheckbox">
        <LabeledCheckbox checked="true">
          {' '}
          Labeled Checkbox (true)
        </LabeledCheckbox>
      </Export>
      <Export name="LabeledCheckbox">
        <LabeledCheckbox checked="false">
          {' '}
          Labeled Checkbox (false)
        </LabeledCheckbox>
      </Export>
      <Export name="LabeledCheckbox">
        <LabeledCheckbox checked="mixed">
          {' '}
          Labeled Checkbox (mixed)
        </LabeledCheckbox>
      </Export>
    </div>

    <Paragraph>disabled checkboxes with labels</Paragraph>
    <FormattedCode>
      {`
<LabeledCheckbox checked="mixed" disabled>
  Labeled Checkbox (true)
</LabeledCheckbox>
      `}
    </FormattedCode>

    <div className={css.labeledCheckboxContainer}>
      <LabeledCheckbox disabled checked="true">
        {' '}
        Labeled Checkbox (true)
      </LabeledCheckbox>
      <LabeledCheckbox disabled checked="false">
        {' '}
        Labeled Checkbox (false)
      </LabeledCheckbox>
      <LabeledCheckbox disabled checked="mixed">
        {' '}
        Labeled Checkbox (mixed)
      </LabeledCheckbox>
    </div>

    <Paragraph>
      We also support vanilla <code>Checkbox</code>, it's what the labeled
      variant is built from. You will likely need this when developing a
      component that lays out checkboxes (like a table or complex nested ui).
      You probably don't need these very often, but they're here if you need
      them. The only problem with using them is that because they're not
      "proper" input elements, they don't support the label -> click behavior,
      which is implemented by the LabeledCheckbox component.
    </Paragraph>
    <DefaultWhiteBox style={{alignItems: 'center'}}>
      <Paragraph>Checkboxes (no labels) (false)</Paragraph>
      <Export name="Checkbox" />
      <Checkbox checked="true" />
      <Checkbox checked="mixed" />
      <Checkbox checked="false" />
      <Checkbox checked="false" disabled={true} />
    </DefaultWhiteBox>
    <DefaultWhiteBox style={{alignItems: 'center'}}>
      <Paragraph>
        Labeled Checkboxes without "child" content get padded boxes
      </Paragraph>
      <LabeledCheckbox checked="true" />
      <LabeledCheckbox checked="mixed" />
      <LabeledCheckbox checked="false" />
    </DefaultWhiteBox>
    <ClickableBox style={{alignItems: 'center'}}>
      <Checkbox checked="mixed" />
      <Paragraph>Checkbox (mixed)</Paragraph>
    </ClickableBox>
  </Section>
);

const ToggleDemo = () => {
  const [toggleAChecked, setToggleAChecked] = React.useState(false);
  const [toggleBChecked, setToggleBChecked] = React.useState(true);
  return (
    <Section
      name="Toggle"
      relPath="toggle.jsx"
      figmaLink="https://www.figma.com/file/T64DM8GIUZqVavdsBlhP5F/2.0-hi-fi-components-%26-icons?node-id=40%3A3600"
    >
      <>
        <br />
        Labeled
        <div className={css.labeledCheckboxContainer}>
          <Export name="Toggle">
            <Toggle
              checked={toggleAChecked}
              onChange={(e) => setToggleAChecked(e.currentTarget.checked)}
            >
              Toggle A
            </Toggle>
          </Export>
          <Export name="Toggle">
            <Toggle
              checked={toggleBChecked}
              onChange={(e) => setToggleBChecked(e.currentTarget.checked)}
            >
              Toggle B
            </Toggle>
          </Export>
          <Export name="Toggle">
            <Toggle checked={false} disabled>
              Toggle C
            </Toggle>
          </Export>
          <Export name="Toggle">
            <Toggle checked={true} disabled>
              Toggle D
            </Toggle>
          </Export>
        </div>
        <br />
        Unlabeled
        <div className={css.labeledCheckboxContainer}>
          <Export name="Toggle">
            <Toggle
              checked={toggleAChecked}
              onChange={(e) => setToggleAChecked(e.currentTarget.checked)}
            />
          </Export>
          <Export name="Toggle">
            <Toggle
              checked={toggleBChecked}
              onChange={(e) => setToggleBChecked(e.currentTarget.checked)}
            />
          </Export>
          <Export name="Toggle">
            <Toggle checked={false} disabled />
          </Export>
          <Export name="Toggle">
            <Toggle checked={true} disabled />
          </Export>
        </div>
      </>
    </Section>
  );
};

const OptionsDemo = () => {
  const options = [
    {key: 'op1', label: 'Option 1', disabled: true},
    {key: 'op2', label: 'Option 2'},
    {key: 'op3', label: 'Option 3', disabled: true},
    {key: 'op4', label: 'Option 4'},
    {key: 'op5', label: 'Option 5'},
    {key: 'op6', label: 'Option 6'},
  ];
  const [selectedOptions, setSelectedOption] = React.useState('op1');
  return (
    <Section
      name="Menu Component (Options in UI)"
      figmaLink="https://www.figma.com/file/T64DM8GIUZqVavdsBlhP5F/2.0-hi-fi-components-%26-icons?node-id=49%3A5527"
      relPath="menu/options.jsx"
    >
      <Paragraph>
        This is options component in isolation. This component has been used
        with MenuButton to create menu component, Filter Labels to create filter
        component
      </Paragraph>
      <Export name="Options">
        <>
          <div className={css.optionsContainer}>
            <div>
              <Options
                options={options}
                classNames={{options: css.menuOptionClassName}}
                onSelect={(item) => setSelectedOption(item.key)}
                resolveLabel={(item) => item.label}
                resolveValue={(item) => item.key}
                value={selectedOptions}
              />
            </div>
            <div>
              <Options
                options={options}
                classNames={{options: css.menuOptionClassName}}
                onSelect={(item) => setSelectedOption(item.key)}
                resolveLabel={(item) => item.label}
                resolveValue={(item) => item.key}
                value={selectedOptions}
                OptionComponent={RadioOption}
              />
            </div>
            <div>
              <Options
                options={options}
                classNames={{options: css.menuOptionClassName}}
                onSelect={(item) => setSelectedOption(item.key)}
                resolveLabel={(item) => item.label}
                resolveValue={(item) => item.key}
                value={selectedOptions}
                OptionComponent={CheckboxOption}
              />
            </div>
          </div>
          <Clickable className={css.header}>
            With some disabled Options
          </Clickable>
          <div className={css.optionsContainer}>
            <div>
              <Options
                options={options}
                classNames={{options: css.menuOptionClassName}}
                onSelect={(item) => setSelectedOption(item.key)}
                resolveLabel={(item) => item.label}
                resolveValue={(item) => item.key}
                resolveDisabled={(item) => ['op1', 'op3'].includes(item.key)}
                value={selectedOptions}
                OptionComponent={CheckboxOption}
              />
            </div>
            <div>
              <Options
                options={options}
                classNames={{options: css.menuOptionClassName}}
                onSelect={(item) => setSelectedOption(item.key)}
                resolveLabel={(item) => item.label}
                resolveValue={(item) => item.key}
                resolveDisabled={(item) => ['op1', 'op3'].includes(item.key)}
                value={selectedOptions}
                OptionComponent={RadioOption}
              />
            </div>
            <div>
              <Options
                options={options}
                classNames={{options: css.menuOptionClassName}}
                onSelect={(item) => setSelectedOption(item.key)}
                resolveLabel={(item) => item.label}
                resolveValue={(item) => item.key}
                resolveDisabled={(item) => ['op1', 'op3'].includes(item.key)}
                value={selectedOptions}
              />
            </div>
          </div>
        </>
      </Export>
    </Section>
  );
};

const TabListDemo = () => {
  const [selectedOption, setSelectedOption] = React.useState({
    key: 'dogs',
    label: 'Dogs',
  });
  const tabs = [
    {key: 'dogs', label: 'Dogs'},
    {key: 'cats', label: 'Cats'},
    {
      key: 'other',
      label: 'Other',
      subTabs: [
        {key: 'other.seahorses', label: 'Sea Horses'},
        {key: 'other.groundhogs', label: 'Groundhogs'},
      ],
    },
  ];
  return (
    <>
      <Section
        name="TabList Component"
        figmaLink="https://www.figma.com/file/eFDsi2ggzYoa7ssPo9tgtQ/jobs%2C-candidate-lists-%26-profiles?node-id=440%3A23335"
        relPath="tablist.jsx"
      >
        <Paragraph>Tabs headers manages selected with subtabs.</Paragraph>
        <Card type="secondary">
          <TabList
            onSelected={setSelectedOption}
            selectedOption={selectedOption}
            tabs={tabs}
          />
        </Card>
        <Card type="secondary">
          <Paragraph>You are on the {selectedOption.label} page</Paragraph>
        </Card>

        <FormattedCode>
          {`
() => {
  const [selected, setSelected] = React.useState({key: 'dogs', label: 'Dogs'});
  const tabs = [
    {key: 'dogs', label: 'Dogs'},
    {key: 'cats', label: 'Cats'},
    {
      key: 'other',
      label: 'Other',
      subTabs: [
        {key: 'other.seahorses', label: 'Sea Horses'},
        {key: 'other.groundhogs', label: 'Groundhogs'},
      ],
    },
  ];
  return (
    <Card border="all">
      <TabList
        onSelected={setSelected}
        selected={selected}
        tabs={tabs}
      />
    </Card>
        )
}
        `}
        </FormattedCode>
      </Section>
    </>
  );
};

const FormInputDemo = () => {
  const [value, setValue] = React.useState('');
  const [requiredValue, setRequiredValue] = React.useState('');
  const error = value.includes('bad word');
  const errorText = 'No bad words allowed';
  const onChange = (evt) => {
    setValue(evt.target.value);
  };
  return (
    <>
      <Section
        name="Form Input Components"
        figmaLink="https://www.figma.com/file/T64DM8GIUZqVavdsBlhP5F/2.0-hi-fi-components-%26-icons?node-id=40%3A3600"
        relPath="input.jsx"
      >
        <Paragraph>Basic Input component with label and error state.</Paragraph>

        <SubHeader>Controlled input with error state</SubHeader>
        <div className={css.inputContainer}>
          <Input
            error={error}
            errorText={errorText}
            value={value}
            placeholder="Try typing bad word"
            onChange={onChange}
            label="Input Label"
            contextLabel="Optional"
          />
        </div>

        <FormattedCode>
          {`
() => {
  const [value, setValue] = React.useState('');
  const error = value.includes('bad word');
  const errorText = 'No bad words allowed';
  const onChange = (evt) => {
    setValue(evt.target.value);
  };
  return <Input
    error={error}
    errorText={errorText}
    value={value}
    placeholder="Try typing bad word"
    onChange={onChange}
    label="Input Label"
    contextLabel="Optional"
  />
}
        `}
        </FormattedCode>

        <SubHeader>Disabled Input</SubHeader>
        <div className={css.inputContainer}>
          <Input
            disabled
            placeholder="Disabled"
            label="Input Label"
            contextLabel="Locked"
          />
        </div>

        <FormattedCode>
          {`
  <Input
  disabled
  placeholder="Disabled"
  label="Input Label"
  contextLabel="Locked"
/>
          `}
        </FormattedCode>

        <SubHeader>Uncontrolled Input</SubHeader>
        <div className={css.inputContainer}>
          <Input
            placeholder="Placeholder"
            label="Input Label"
            contextLabel="Optional"
          />
        </div>

        <FormattedCode>
          {`
<Input
  placeholder="Placeholder"
  label="Input Label"
  contextLabel="Optional"
/>
          `}
        </FormattedCode>
        <SubHeader>Required Input</SubHeader>
        <div className={css.inputContainer}>
          <Input
            error={!requiredValue.length}
            errorText={'must be filled'}
            placeholder="Placeholder"
            label="Input Label"
            contextLabel="Required"
            value={requiredValue}
            onChange={(evt) => {
              setRequiredValue(evt.target.value);
            }}
          />
        </div>

        <FormattedCode>
          {`
<Input
  placeholder="Placeholder"
  label="Input Label"
  contextLabel="Optional"
/>
          `}
        </FormattedCode>
      </Section>
    </>
  );
};

const TextComponentsDemo = () => (
  <>
    <Section
      name="Text Components"
      figmaLink="https://www.figma.com/file/mtMlZYMHBqtoBedM6cpZ96/colors-%26-styles?node-id=681%3A932"
      relPath="text-v2.jsx"
    >
      <Paragraph>
        When possible, use the TextComponent components. they're simple wrappers
        for h1/h2/p, but if you need to apply the classes, those are also
        available. Try the components first, though.
      </Paragraph>

      <FormattedCode prettier>
        {`
<React.Fragment>
<Header>
  a page header
</Header>
<SubHeader>
  a second level heading
</SubHeader>
<Clickable>
  text inside a ui control or link
</Clickable>
<Paragraph>
  Paragraph body text
</Paragraph>
<Smallest>
  Small Text
</Smallest>
</React.Fragment>
        `}
      </FormattedCode>

      <Paragraph>
        The text components are built entirely from makeClassNameComponent, but
        you can use the classNames that underlie these to make your own. The
        ideal is using the component and adding your own classes to those as
        needed to define overrides like layout, spacing, etc, but the classes
        are here for You again, use your best judgement but prefer the
        components first.
      </Paragraph>
      <FormattedCode>
        {`
import dsCss from 'designSystem2021';
const Sample = (props) => {
  return (<div>
    <h1 className={dsCss.header}>header</h1>
    <h2 className={dsCss.subHeader}>subHeader</h2>
    <span className={dsCss.clickable}>clickable</span>
    <p className={dsCss.paragraph}>paragraph</p>
    <span className={dsCss.smallest}>smallest</span>
  </div>)
};
          `}
      </FormattedCode>
      <Export name="Header">
        <Header>
          {`<Header />`}: Page Title or Section Headers (renders as &nbsp;{' '}
          <code>h1</code>)
        </Header>
      </Export>

      <Export name="SubHeader">
        <SubHeader>
          <code>{`<SubHeader />`}</code>Secondary Navigation bar (renders as
          &nbsp; <code>h2</code>)
        </SubHeader>
      </Export>

      <Export name="Clickable">
        <Clickable>
          <code>{`<Clickable />`}</code>: all ui interaction text (renders as{' '}
          <code>span</code>)
        </Clickable>
      </Export>

      <Export name="Paragraph">
        <Paragraph>
          <code>{`<Paragraph />`}</code>: all body text (renders as{' '}
          <code>p</code>)
        </Paragraph>
      </Export>

      <Export name="Smallest">
        <Smallest>
          <code>{`<Smallest />`}</code>: small text (renders as{' '}
          <code>span</code>)
        </Smallest>
      </Export>
    </Section>
  </>
);
const BordersDemo = () => {
  const backgroundClassNames = [
    'bgClickableBackground',
    'bgSelectedBackground',
    'bgSuccessBackground',
    'bgWarnBackground',
    'bgErrorBackground',
    'bgDefaultBackground',
  ];

  const borderClassNames = [
    'borderTop',
    'borderRight',
    'borderBottom',
    'borderLeft',
    'borderTopAndBottom',
    'borderLeftAndRight',
    'borderFull',
  ];

  return (
    <Section
      name="Borders"
      figmaLink="https://www.figma.com/file/mtMlZYMHBqtoBedM6cpZ96/colors-%26-styles?node-id=687%3A1342"
    >
      <Paragraph>
        Borders are funny, the are not really <i>real</i> things, they're inset
        box-shadow styles on containers with backgrounds and the effect is to
        basically saturate the background color.
      </Paragraph>
      <Paragraph>
        Click on the className on the left to copy the <em>import</em> for the
        class, click on a box to copy its <em>composed class</em>. In practice
        that looks like
      </Paragraph>

      <FormattedCode>
        {`
.someContainer {
  composes: bgClickableBackground borderTop from 'designSystem2021';
  /* your other css goes here*/
}`}
      </FormattedCode>

      {borderClassNames.map((borderClassName) => (
        <React.Fragment key={borderClassName}>
          <CopyableBorderCell
            borderClassName={borderClassName}
            backgroundClassNames={backgroundClassNames}
          />
        </React.Fragment>
      ))}
    </Section>
  );
};

const CopyableBorderCell = ({borderClassName, backgroundClassNames}) => {
  const [_didCopy, classImportHook] = useClipboard(
    `composes: ${borderClassName} from 'designSystem2021';`,
  );
  return (
    <div
      key={borderClassName}
      className={dsCss.borderBottom}
      style={{padding: '.5em 0', cursor: 'copy'}}
    >
      <div style={{width: 200}}>
        <Smallest onClick={classImportHook}>.{borderClassName}</Smallest>
      </div>
      {backgroundClassNames.map((backgroundClassName) => (
        <React.Fragment key={backgroundClassName}>
          <CopyableBorderBgCell
            backgroundClassName={backgroundClassName}
            borderClassName={borderClassName}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

const CopyableBorderBgCell = ({backgroundClassName, borderClassName}) => {
  const composesText = `composes: ${backgroundClassName} ${borderClassName} from 'designSystem2021';`;
  const [_didCopyBox, copyComposedClass] = useClipboard(composesText);
  return (
    <div
      key={backgroundClassName}
      className={classify(dsCss[backgroundClassName], dsCss[borderClassName])}
      style={{
        width: 100,
        height: 80,
        marginRight: 20,
        cursor: 'copy',
      }}
      onClick={copyComposedClass}
    ></div>
  );
};

const SelectDemo = () => {
  const [selected, setSelected] = React.useState(null);
  const options = [
    {label: 'Option One', value: 'one'},
    {label: 'Option Two', value: 'two'},
    {label: 'Option Three', value: 'three'},
  ];
  return (
    <Section
      name="Select"
      figmaLink="https://www.figma.com/file/T64DM8GIUZqVavdsBlhP5F/2.0-hi-fi-components-%26-icons?node-id=40%3A3600"
      relPath="select.jsx"
    >
      <Paragraph>
        Use this component to provide list of options. This component takes the
        width of the container in which it is rendered.
      </Paragraph>
      <SelectContainer>
        <Select
          options={options}
          value={selected}
          placeholder="Placeholder"
          onChange={(option) => setSelected(option)}
          label="Select Input Label"
          contextLabel="Optional"
        />
      </SelectContainer>
      <SelectContainer>
        <Select
          options={options}
          value={{label: 'Option One', value: 'one'}}
          // eslint-disable-next-line no-empty-function
          onChange={() => {}}
          label="Select Input Label"
          contextLabel="Optional"
        />
      </SelectContainer>
      <SelectContainer>
        <Select
          options={options}
          value={selected}
          placeholder="Placeholder — error"
          onChange={(option) => setSelected(option)}
          label="Select Input Label"
          contextLabel="Optional"
          error="Error"
        />
      </SelectContainer>
    </Section>
  );
};

const ToastDemo = () => {
  const dispatch = useDispatch();
  const openToast = (
    pos: $Values<typeof POSITIONS>,
    variant?: $Values<typeof VARIANTS>,
  ) => {
    dispatch(
      showToastMessage(
        <div className={css.toastMsg}>
          If you wish to make an apple pie from scratch, you must first invent
          the <span>universe</span>.
        </div>,
        {
          timeout: 5000,
          variant: variant || VARIANTS.INFO,
          position: pos,
        },
      ),
    );
  };
  const openToastWithVariableWidth = () => {
    dispatch(
      showToastMessage(
        <div>
          If you wish to make an apple pie from scratch, you must first invent
          the universe.
        </div>,
        {
          width: '200px',
        },
      ),
    );
  };
  const openToastWithoutLink = () => {
    dispatch(
      showToastMessage(
        'If you wish to make an apple pie from scratch, you must first invent the universe.',
      ),
    );
  };
  return (
    <Section name="Toast" figmaLink="" relPath="">
      <FormattedCode>
        {`
import {VARIANTS, POSITIONS} from 'src/designSystem2021Components/toast';
import {showToastMessage} from 'src/action-creators/toasts';
const dispatch = useDispatch();
const openToast = (pos: $Values<typeof VARIANTS>) => {
    dispatch(
      showToastMessage(
        <div>
          1 Job has been sucessfully applied to Ian Gramm View Job Application
        </div>,
        {
          timeout: 5000,
          pause: false,
          variant: VARIANTS.INFO,
          delay: 0,
          position: pos,
        },
      ),
    );
  };
      `}
      </FormattedCode>
      <div className={css.toastContent}>
        <Paragraph onClick={() => openToast(POSITIONS.TOP_LEFT, VARIANTS.INFO)}>
          Top Left Info Toast
        </Paragraph>
        <Paragraph
          onClick={() => openToast(POSITIONS.TOP_RIGHT, VARIANTS.SUCCESS)}
        >
          Top Right Success Toast
        </Paragraph>
        <Paragraph
          onClick={() => openToast(POSITIONS.BOTTOM_LEFT, VARIANTS.WARNING)}
        >
          Bottom Left Warning Toast
        </Paragraph>
        <Paragraph
          onClick={() => openToast(POSITIONS.BOTTOM_RIGHT, VARIANTS.ERROR)}
        >
          Bottom Right Error Toast
        </Paragraph>
        <Paragraph onClick={openToastWithoutLink}>Toast without link</Paragraph>
        <Paragraph onClick={openToastWithVariableWidth}>
          Toast with variable width
        </Paragraph>
      </div>
    </Section>
  );
};

const VerticalTimelineDemo = () => {
  const timelineElement: React.Node = GetTimelineElement();
  const timeline1: VerticalTimelineElementProps[] = [
    {
      id: 1,
      header: '12 Jan 2020 12:41 PM',
    },
    {
      id: 2,
      header: '12 Jan 2020 12:42 PM',
      lineStyle: 'dashed',
      lineColor: '#DF7E0C',
    },
    {
      id: 3,
      header: '12 Jan 2020 12:43 PM',
      isInProgress: true,
      circleColor: '#DF7E0C',
    },
  ];

  const timeline2: VerticalTimelineElementProps[] = [
    {
      id: 1,
      header: '12 Jan 2020 12:41 PM',
    },
    {
      id: 2,
      header: '12 Jan 2020 12:42 PM',
      lineStyle: 'dashed',
      lineColor: '#DF7E0C',
    },
    {
      id: 3,
      header: '12 Jan 2020 12:43 PM',
      isInProgress: true,
      icon: <LoadingIcon />,
    },
  ];
  const timeline3: VerticalTimelineElementProps[] = [
    {
      id: 1,
      header: '12 Jan 2020 12:41 PM',
    },
    {
      id: 2,
      header: '12 Jan 2020 12:42 PM',
    },
    {
      id: 3,
      header: '12 Jan 2020 12:43 PM',
    },
  ];
  return (
    <Section
      name="Vertical Timeline"
      figmaLink=""
      relPath="vertical-timeline/vertical-timeline.jsx"
    >
      <Paragraph>
        Use this component to create a vertical timeline. Each timeline entry
        must have a child. This also supports custom icons, line styles, custom
        color for timeline entry circle.
      </Paragraph>
      <FormattedCode prettier>
        {`
      <VerticalTimeline baseLineColor="#03964C" baseLineStyle="dashed">
        {timeline1.map((timeline) => (
            <React.Fragment key={timeline.id}>
              <VerticalTimelineElement {...timeline} >
                {timelineElement}
              </VerticalTimelineElement>
            </React.Fragment>
          ))}
      </VerticalTimeline>
        `}
      </FormattedCode>
      <div className={css.timelineContainer}>
        <VerticalTimeline>
          {timeline1.map((timeline) => (
            <React.Fragment key={timeline.id}>
              <VerticalTimelineElement {...timeline}>
                {timelineElement}
              </VerticalTimelineElement>
            </React.Fragment>
          ))}
        </VerticalTimeline>
        <VerticalTimeline>
          {timeline2.map((timeline) => (
            <React.Fragment key={timeline.id}>
              <VerticalTimelineElement {...timeline}>
                {timelineElement}
              </VerticalTimelineElement>
            </React.Fragment>
          ))}
        </VerticalTimeline>
        <VerticalTimeline>
          {timeline3.map((timeline) => (
            <React.Fragment key={timeline.id}>
              <VerticalTimelineElement {...timeline}>
                {timelineElement}
              </VerticalTimelineElement>
            </React.Fragment>
          ))}
        </VerticalTimeline>
      </div>

      <div className={css.timelineContainer}>
        <VerticalTimeline baseLineColor="#03964C">
          {timeline1.map((timeline) => (
            <React.Fragment key={timeline.id}>
              <VerticalTimelineElement {...timeline}>
                {timelineElement}
              </VerticalTimelineElement>
            </React.Fragment>
          ))}
        </VerticalTimeline>
        <VerticalTimeline baseLineColor="#03964C">
          {timeline2.map((timeline) => (
            <React.Fragment key={timeline.id}>
              <VerticalTimelineElement {...timeline}>
                {timelineElement}
              </VerticalTimelineElement>
            </React.Fragment>
          ))}
        </VerticalTimeline>
        <VerticalTimeline baseLineColor="#03964C" baseLineStyle="dashed">
          {timeline3.map((timeline) => (
            <React.Fragment key={timeline.id}>
              <VerticalTimelineElement {...timeline}>
                {timelineElement}
              </VerticalTimelineElement>
            </React.Fragment>
          ))}
        </VerticalTimeline>
      </div>
    </Section>
  );
};

const HorizontalTimelineDemo = () => (
  <Section
    name="Horizontal Timeline"
    figmaLink=""
    relPath="horizontal-timeline/horizontal-timeline.jsx"
  >
    <Paragraph>
      Use this component to create a horizontal timeline. Each timeline entry
      must have a children node. This also supports custom icons, line stylings
      and custom color for timeline entry circle.
    </Paragraph>
    <FormattedCode prettier>
      {`
          <HorizontalTimeline baseLineColor="#03964C" baseLineStyle="solid">
            <HorizontalTimelineElement isCompleted>
              Upload
            </HorizontalTimelineElement>
            <HorizontalTimelineElement isCompleted>
              Map fields
            </HorizontalTimelineElement>
            <HorizontalTimelineElement isCompleted>
              Review & Import
            </HorizontalTimelineElement>
          </HorizontalTimeline>
        `}
    </FormattedCode>
    {<GetHorizontalTimelineElements />}
  </Section>
);

const GetHorizontalTimelineElements = () => (
  <>
    <div className={css.timelineContainer}>
      <HorizontalTimeline>
        <HorizontalTimelineElement isInProgress>
          Upload
        </HorizontalTimelineElement>
        <HorizontalTimelineElement>Map fields</HorizontalTimelineElement>
        <HorizontalTimelineElement>Review & Import</HorizontalTimelineElement>
      </HorizontalTimeline>
    </div>
    <div className={css.timelineContainer}>
      <HorizontalTimeline>
        <HorizontalTimelineElement
          isCompleted
          lineStyle="solid"
          lineColor="#FBD9B1"
        >
          Upload
        </HorizontalTimelineElement>
        <HorizontalTimelineElement isInProgress>
          Map fields
        </HorizontalTimelineElement>
        <HorizontalTimelineElement>Review & Import</HorizontalTimelineElement>
      </HorizontalTimeline>
    </div>
    <div className={css.timelineContainer}>
      <HorizontalTimeline>
        <HorizontalTimelineElement
          isCompleted
          lineStyle="solid"
          lineColor="#03964C"
        >
          Upload
        </HorizontalTimelineElement>
        <HorizontalTimelineElement
          isCompleted
          lineStyle="solid"
          lineColor="#FBD9B1"
        >
          Map fields
        </HorizontalTimelineElement>
        <HorizontalTimelineElement isInProgress>
          Review & Import
        </HorizontalTimelineElement>
      </HorizontalTimeline>
    </div>
    <div className={css.timelineContainer}>
      <HorizontalTimeline>
        <HorizontalTimelineElement
          isCompleted
          lineStyle="solid"
          lineColor="#03964C"
        >
          Upload
        </HorizontalTimelineElement>
        <HorizontalTimelineElement
          isCompleted
          lineStyle="solid"
          lineColor="#FBD9B1"
        >
          Map fields
        </HorizontalTimelineElement>
        <HorizontalTimelineElement isInProgress>
          <div className={css.horizontalProgress}>
            Review & Import <LoadingIcon />
          </div>
        </HorizontalTimelineElement>
      </HorizontalTimeline>
    </div>
    <div className={css.timelineContainer}>
      <HorizontalTimeline baseLineColor="#03964C" baseLineStyle="solid">
        <HorizontalTimelineElement isCompleted>
          Upload
        </HorizontalTimelineElement>
        <HorizontalTimelineElement isCompleted>
          Map fields
        </HorizontalTimelineElement>
        <HorizontalTimelineElement isCompleted>
          Review & Import
        </HorizontalTimelineElement>
      </HorizontalTimeline>
    </div>
  </>
);

const GetTimelineElement = () => {
  const dispatch = useDispatch();
  const openToast = () => {
    dispatch(showToastMessage('Some Timeline Action'));
  };
  return (
    <div className={css.timeline}>
      <div className={css.lineText}>Imported 120 Candidates</div>
      <div className={css.buttons}>
        <Button
          size="small"
          type="tertiary"
          className={css.timelineButton}
          onClick={openToast}
        >
          Link
        </Button>
        <Button size="small" type="tertiary" onClick={openToast}>
          Download
        </Button>
      </div>
    </div>
  );
};

export const SelectContainer = ({
  children,
}: {
  children?: React.Node,
}): React.Node => <div className={css.selectContainer}>{children}</div>;

const CarouselDemo = () => {
  const items = [
    {label: 'one', value: 'one'},
    {label: 'two', value: 'two'},
    {label: 'three', value: 'three'},
    {label: 'four', value: 'four'},
    {label: 'five', value: 'five'},
    {label: 'six', value: 'six'},
    {label: 'seven', value: 'seven'},
    {label: 'eight', value: 'eight'},
    {label: 'nine', value: 'nine'},
    {label: 'ten', value: 'ten'},
  ];
  return (
    <Section name="Carousel" figmaLink="" relPath="carousel.jsx">
      <Paragraph>
        Use this component to create a carousel of labels, images, or custom
        components. For best result the combined width + margins of all the
        items shown on one page should be equal to the width of the container in
        which this carousel is rendered e.g. if there are 3 items on page, each
        200px wide and there is a margin of 10px for each item, then the
        container width should be 3*200 + 3*10 = 630px;
      </Paragraph>
      <FormattedCode prettier>
        {`
          <Carousel>
            <Button type='tertiary' isSelectable={true} isSelected={false}>
              one
            </Button>
            <Button type='tertiary'  isSelectable={true} isSelected={false}>
              two
            </Button>
          </Carousel>
        `}
      </FormattedCode>
      <div className={css.carouselContainer}>
        <Export name="Carousel">
          <Carousel>
            {items.map((item) => (
              <Button
                type="tertiary"
                className={css.carouselDemoButton}
                key={item.value}
                isSelectable
                isSelected={false}
              >
                <Clickable>{item.label}</Clickable>
              </Button>
            ))}
          </Carousel>
        </Export>
      </div>
      <Paragraph>
        The content inside the carousel controls the margins and paddings around
        it. e.g. the secondary button here provides a margin of 5px around it so
        that its borders are visible on hover and dont get clipped by the
        carousel. `margin-5px-class` here is just an example which provides a
        margin of 5px around the button.
      </Paragraph>
      <FormattedCode prettier>
        {`
              <Carousel>
                <Button type='secondary'  className="margin-5px-class" >
                  one
                </Button>
                <Button type='secondary'  className="margin-5px-class">
                  two
                </Button>
                <Button type='secondary'  className="margin-5px-class">
                  three
                </Button>
                <Button type='secondary'  className="margin-5px-class">
                  four
                </Button>
                <Button type='secondary'  className="margin-5px-class">
                  five
                </Button>
              </Carousel>
        `}
      </FormattedCode>
      <div className={css.carouselContainer}>
        <Carousel>
          <Button type="secondary" className={css.carouselDemoButton}>
            one
          </Button>
          <Button type="secondary" className={css.carouselDemoButton}>
            two
          </Button>
          <Button type="secondary" className={css.carouselDemoButton}>
            three
          </Button>
          <Button type="secondary" className={css.carouselDemoButton}>
            four
          </Button>
          <Button type="secondary" className={css.carouselDemoButton}>
            five
          </Button>
        </Carousel>
      </div>
    </Section>
  );
};

const CardDemo = () => {
  const dispatch = useDispatch();
  const typeValues = ['primary', 'secondary', 'warning', 'error', 'success'];
  const openToast = (codeString: string) => {
    dispatch(
      showToastMessage(
        `code copied to clipboard:
         ${codeString}`,
      ),
    );
  };
  return (
    <Section name="Card" figmaLink="" relPath="card.jsx">
      <Paragraph>
        Use this component to create a card container. . Following are some
        256x256 card containers examples. type prop supports following values{' '}
        {typeValues.join(' | ')}
      </Paragraph>

      <Export name="Card">
        {typeValues.map((type) => {
          const codeString = `<Card type="${type}"></Card>`;
          return (
            <div
              className={css.cardSampleContainer}
              key={type}
              onClick={() => openToast(codeString)}
            >
              <FormattedCode prettier>{codeString}</FormattedCode>
              <Card className={css.card} type={type}>
                Hello there
              </Card>
            </div>
          );
        })}
      </Export>

      <Paragraph>
        primary and secondary cards have hover interaction as well. They are
        only visible when isSelectable is true
      </Paragraph>
      <Export name="Card">
        {['primary', 'secondary'].map((type) => {
          const codeString = `<Card type="${type}" isSelectable></Card>`;
          return (
            <div className={css.cardSampleContainer} key={type}>
              <FormattedCode
                prettier
              >{`<Card type="${type}" isSelectable></Card>`}</FormattedCode>
              <Card className={css.card} type={type} isSelectable>
                Hello there
              </Card>
              <FormattedCode
                prettier
              >{`<Card type="${type}" isSelectable isSelected></Card>`}</FormattedCode>
              <Card className={css.card} type={type} isSelectable isSelected>
                Hello there
              </Card>
            </div>
          );
        })}
      </Export>
    </Section>
  );
};

const LabelFilterDemo = () => {
  const [selected, setSelected] = React.useState(['1']);
  const handleSelect = (items: Array<string>) => {
    setSelected(items);
  };

  const defaultSelected = ['1'];

  const handleClick = () => {
    console.log('panel clicked');
  };

  const listItems = [
    {value: '1', label: 'Data Screening'},
    {value: '2', label: 'Sourcing'},
    {value: '3', label: 'Level'},
  ];

  return (
    <Section
      name="LabelFilter"
      figmaLink="https://www.figma.com/file/NFMq6du9ZH4f7XC2pi3xiv/%F0%9F%9B%B0--journeysFolders?node-id=681%3A25227"
      relPath=""
    >
      <FormattedCode>
        {`
import {LabelFilter} from 'src/designSystem2021Components/label-filter';
const LabelFilterComponent = () => {
  const handleSelect = (items: Array<string>) => {
    console.log(items);
  };

  const listItems = [
    {value: '1', label: 'ChatBot name here will'},
    {value: '2', label: 'Test 2'},
    {value: '3', label: 'Test 3'},
  ];

  return (
    <LabelFilter
      text="Filter label"
      options={listItems}
      onChange={handleSelect}
    />
  );
};
      `}
      </FormattedCode>

      <div className={css.labelFilter}>
        <Paragraph>
          <h5> Default State </h5>
          <Export name="labelFilter">
            <LabelFilter
              text="Default Filter label"
              options={listItems}
              value={selected}
              onChange={handleSelect}
            />
          </Export>
        </Paragraph>
        <Paragraph>
          <h5> Selected State </h5>
          <Export name="labelFilter">
            <LabelFilter
              text="Selected Filter label"
              options={listItems}
              onChange={handleSelect}
              value={selected}
            />
          </Export>
        </Paragraph>
        <Paragraph>
          <h5> Disabled State </h5>
          <Export name="labelFilter">
            <LabelFilter
              text="Disable Filter label"
              options={listItems}
              onChange={handleSelect}
              disabled
              value={selected}
            />
          </Export>
        </Paragraph>
      </div>
      <div className={css.labelFilter}>
        <Paragraph>
          <h5> Default State (SIZE = small) </h5>
          <Export name="labelFilter">
            <LabelFilter
              text="Default Filter label"
              options={listItems}
              size={'small'}
              value={selected}
              onChange={handleSelect}
            />
          </Export>
        </Paragraph>
        <Paragraph>
          <h5> Selected State (SIZE = small) </h5>
          <Export name="labelFilter">
            <LabelFilter
              text="Selected Filter label"
              options={listItems}
              onChange={handleSelect}
              value={selected}
              size={'small'}
            />
          </Export>
        </Paragraph>
        <Paragraph>
          <h5> Disabled State (SIZE = small) </h5>
          <Export name="labelFilter">
            <LabelFilter
              text="Disable Filter label"
              options={listItems}
              onChange={handleSelect}
              disabled
              size={'small'}
              value={selected}
            />
          </Export>
        </Paragraph>
      </div>

      <div className={css.labelFilter}>
        <Paragraph>
          <h5> Default State With Type Panel </h5>
          <Export name="labelFilter">
            <LabelFilter
              text="Default Filter label"
              filterType="panel"
              value={selected}
              onClick={handleClick}
            />
          </Export>
        </Paragraph>
        <Paragraph>
          <h5> Selected State With Type Panel </h5>
          <Export name="labelFilter">
            <LabelFilter
              text="Selected Filter label"
              filterType="panel"
              onClick={handleClick}
              value={selected}
            />
          </Export>
        </Paragraph>
        <Paragraph>
          <h5> Disabled State With Type Panel </h5>
          <Export name="labelFilter">
            <LabelFilter
              text="Disable Filter label"
              filterType="panel"
              disabled
              value={selected}
            />
          </Export>
        </Paragraph>
      </div>
      <div className={css.labelFilter}>
        <Paragraph>
          <h5> Default State (SIZE = small) With Type Panel </h5>
          <div className={css.filterLabel}>
            <Export name="labelFilter">
              <LabelFilter
                text="Default Filter label"
                size={'small'}
                filterType="panel"
                value={selected}
                onClick={handleClick}
              />
            </Export>
          </div>
        </Paragraph>
        <Paragraph>
          <h5> Selected State (SIZE = small) With Type Panel </h5>
          <div className={css.filterLabel}>
            <Export name="labelFilter">
              <LabelFilter
                text="Selected Filter label"
                filterType="panel"
                onClick={handleClick}
                size={'small'}
                value={selected}
              />
            </Export>
          </div>
        </Paragraph>
        <Paragraph>
          <h5> Disabled State (SIZE = small) With Type Panel </h5>
          <div className={css.filterLabel}>
            <Export name="labelFilter">
              <LabelFilter
                text="Disable Filter label"
                filterType="panel"
                onClick={handleClick}
                disabled
                size={'small'}
                value={selected}
              />
            </Export>
          </div>
        </Paragraph>
      </div>
      <div className={css.rightAnchoredFilterContainer}>
        <Paragraph>
          {' '}
          The alignment of filter options can be controlled by anchorPosition
          prop. It accepts 'left'|'right' as values. The default value is
          'left'. following example shows how we can anchor the options with the
          right side of filter label.{' '}
        </Paragraph>
        <div className={css.filterLabel}>
          <Export name="labelFilter">
            <LabelFilter
              text="Right anchored options"
              value={selected}
              options={listItems}
              anchorPosition="right"
            />
          </Export>
        </div>
      </div>
      <div className={css.rightAnchoredFilterContainer}>
        <Paragraph>
          The options shown in the filter can be disabled and disallowed from
          user selection by padding `disabled` prop as `true` in the options
          object. e.g. `Sourcing` options has been disabled here and can not be
          selected.
        </Paragraph>
        <div className={css.filterLabel}>
          <Export name="labelFilter">
            <LabelFilter
              text="With disabled Options"
              value={selected}
              options={listItems.map((item) => ({
                ...item,
                disabled: item.value === '2',
              }))}
              anchorPosition="right"
              value={selected}
              onChange={handleSelect}
            />
          </Export>
        </div>
      </div>
    </Section>
  );
};

const sidepanelWrapper = (component: React.Node) => (
  <div style={{padding: '20px', flexFlow: 'column'}}>{component}</div>
);

const SidepanelDemo = () => {
  const dispatch = useDispatch();
  const openLeftSidepanel = () => {
    dispatch(
      pushSidepanel({
        title: 'Horizontal Timeline Demo',
        children: sidepanelWrapper(<GetHorizontalTimelineElements />),
        direction: 'left',
        width: '500px',
      }),
    );
  };
  const openRightSidepanel = () => {
    dispatch(
      pushSidepanel({
        title: 'Vertical Timeline Demo',
        children: sidepanelWrapper(<VerticalTimelineDemo />),
        direction: 'right',
      }),
    );
  };
  const openFullWidthSidepanel = () => {
    dispatch(
      pushSidepanel({
        title: 'Table Demo',
        children: sidepanelWrapper(<TableDemo />),
        direction: 'right',
      }),
    );
  };

  const handleClick = () => {
    console.log('panel clicked');
  };

  const listItems = [
    {value: '1', label: 'Data Screening'},
    {value: '2', label: 'Sourcing'},
    {value: '3', label: 'Level'},
  ];

  return (
    <Section name="Sidepanel">
      <Paragraph>
        Use this component to create a Sidepanel. Width of the sidepanel is
        guided by the content, height is always full viewport height.
      </Paragraph>
      <div>
        <Button
          type="primary"
          onClick={openLeftSidepanel}
          style={{marginRight: '8px'}}
        >
          Trigger left fixed width Sidepanel
        </Button>
        <Button
          type="primary"
          onClick={openRightSidepanel}
          style={{marginRight: '8px'}}
        >
          Trigger right content width Sidepanel
        </Button>
        <Button
          type="primary"
          onClick={openFullWidthSidepanel}
          style={{marginRight: '8px'}}
        >
          Trigger right full page width Sidepanel
        </Button>
      </div>
    </Section>
  );
};

const SearchInputDemo = () => {
  const [value, setValue] = React.useState('');

  const PLACEHOLDER = 'Search placeholder';

  const handleOnChange = (text: string) => {
    setValue(text);
  };

  return (
    <Section
      name="Search Input"
      figmaLink="https://www.figma.com/file/T64DM8GIUZqVavdsBlhP5F/2.0-hi-fi-components-%26-icons?node-id=42%3A2178"
      relPath="search-input/search-input.jsx"
    >
      <Paragraph>Search Input (on Enter)</Paragraph>
      <Export name="SearchInput">
        <SearchInput
          value={value}
          placeholder={PLACEHOLDER}
          onChange={handleOnChange}
          useEnterKey={true}
        ></SearchInput>
      </Export>

      <Paragraph>Search Input Typed</Paragraph>
      <Export name="SearchInput">
        <SearchInput
          value={value || 'Hey Typed text type'}
          placeholder={PLACEHOLDER}
          onChange={handleOnChange}
        ></SearchInput>
      </Export>

      <Paragraph>Search Input disabled</Paragraph>
      <Export name="SearchInput">
        <SearchInput
          disabled={true}
          value={value}
          placeholder={PLACEHOLDER}
          onChange={handleOnChange}
        ></SearchInput>
      </Export>

      <Paragraph>
        Debounce Search Input ( Default debounce time -> 100ms)
      </Paragraph>
      <Export name="SearchInput">
        <SearchInput
          isDebounce={true}
          placeholder={PLACEHOLDER}
          value={value}
          onChange={handleOnChange}
        ></SearchInput>
      </Export>
    </Section>
  );
};
const FileUploadDemo = () => (
  <Section name="File Upload" figmaLink="" relPath="file-upload.jsx">
    <Paragraph>
      This component is used for single file upload. In addition to accepting
      files via drag-and-drop, clicking on the component will open the system
      file select dialog. Once a file is accepted, it is up to the user to
      perform the upload and update the progress bar. The example below shows a
      mock successful file upload.
    </Paragraph>
    <FileUpload
      label="File Upload Success"
      alternateLabel="Optional"
      secondaryInstruction="Types: .doc, .docx, or .pdf.  Max. size: 5mb"
      maxSize={5000000}
      onFileSelect={(file, callbacks) => {
        let progress = 0;
        let intervalId;
        intervalId = setInterval(() => {
          progress += 5;
          if (progress >= 100) {
            callbacks.onSuccess();
            clearInterval(intervalId);
          } else {
            callbacks.onProgress(progress);
          }
        }, 100);
      }}
    />

    <Paragraph>
      The component also takes props which determine the accepted file types and
      the maximum size. Custom client or server side validation and network
      error handling can be handled via callback once a file has been accepted.
      The example below shows a mock network error.
    </Paragraph>
    <FileUpload
      label="File Upload Error Demo"
      alternateLabel="Optional"
      secondaryInstruction="Types: .doc, .docx, or .pdf.  Max. size: 5mb"
      maxSize={5000000}
      accept={['.pdf', '.doc', '.docx']}
      onFileSelect={(file, callbacks) => {
        let progress = 0;
        let intervalId;
        intervalId = setInterval(() => {
          progress += 5;
          if (progress >= 55) {
            callbacks.onError('Network connectivity issue');
            clearInterval(intervalId);
          } else {
            callbacks.onProgress(progress);
          }
        }, 100);
      }}
    />
    <Paragraph />
    <FileUpload label="File Upload Disabled Demo" disabled />
  </Section>
);

const TableDemo = () => {
  const headers = [
    {label: 'Name', key: 'name'},
    {label: 'Tel', key: 'phone'},
    {label: 'Notes', key: 'notes'},
    {label: 'Sign', key: 'sign'},
    {label: 'email', key: 'email'},
  ];

  const Zodiac = ({data}) => {
    const map = {
      taurus: '♉︎',
      aquarius: '♒︎',
      gemini: '♊︎',
      leo: '♌︎',
    };
    return (
      <BasicSingleCell>
        <div
          className={dsCss.bgClickableBackground}
          style={{
            flex: 1,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 30,
          }}
        >
          {map[data.sign] ?? '???'}
        </div>
      </BasicSingleCell>
    );
  };
  const fancyHeaders = [
    {
      label: 'Name',
      key: 'name',
      sticky: true,
      render: ({data, className}) => (
        <MonogramCell className={className} initials={data.name[0]} />
      ),
    },
    {label: 'Tel', key: 'phone'},
    {
      label: 'Date',
      key: 'phone',
      render: ({data}) => (
        <DateCell
          date={new Date(new Date() - 80_000_000 * Math.random() * 30)}
        />
      ),
    },
    {label: 'Notes', key: 'notes', className: css.loooongCell},
    {
      label: 'Sign',
      key: 'sign',
      render: Zodiac,
    },
    {label: 'email', key: 'email'},
  ];

  const entries = [
    {
      id: '1',
      name: 'Alice',
      phone: '123-456-7890',
      notes: 'likes candy',
      email: 'alice@symbolics.co',
      sign: 'taurus',
    },
    {
      id: '2',
      name: 'Charles',
      phone: '234-567-8901',
      notes: 'huge nerd',
      email: 'charles@lcs.edu',
      sign: 'aquarius',
    },
    {
      id: '3',
      name: 'Eva',
      phone: '345-678-9012',
      notes: 'sneaky',
      email: 'ev@luat.or',
      sign: 'gemini',
    },
    {
      id: '4',
      name: 'Ben',
      phone: '456-789-0123',
      notes: 'n/a',
      email: 'bitdiddle@t.co',
      sign: 'leo',
    },
  ];

  const [staticTableKeys, setStaticTableKeys] = React.useState(['2', '3']);

  return (
    <Section name="Table" figmaLink="" relPath="table/index.jsx">
      <Paragraph>
        First, let's just see a <code>Table</code>
      </Paragraph>

      <Table headers={headers} entries={entries} />

      <Export name="Table" />
      <Paragraph>
        What is a Table? What is it we expect of a table? For starters, let's
        make a table easy to reason about. At bare minimum, we need two things
        for every table
      </Paragraph>
      <ul className={css.list}>
        <li>
          some data, an array of <code>entries: T[]</code>
        </li>
        <li>
          some way to describe that data, an array of{' '}
          <code>headers: GenericHeaderItems&lt;T&gt;[]</code>
        </li>
      </ul>

      <Paragraph>
        From this, we can create a table from most array-like collections with
        this
      </Paragraph>
      <FormattedCode prettier>
        {`
const headers = [{label: 'Name', key: 'item_name'}];
const entries = [{id: '1', item_name: 'Tim'}];
const MyTable = () => <Table headers={headers} entries={entries} />
        `}
      </FormattedCode>

      <Paragraph>
        Because react needs a way to <code>key</code> individual items when it
        renders them in a sequence, we also need some way to identify each item
        in the <code>entries</code>. For this, we have a prop called{' '}
        <code>idName: string</code> which is a string that maps to some key in
        the collection that you can use as a unique key (it defaults to{' '}
        <code>'id'</code>)
      </Paragraph>
      <FormattedCode prettier>
        {`
const headers = [{label: 'Name', key: 'item_name'}];
const entries = [{uuid: '1', item_name: 'Tim'}];
const MyTable = () => <Table headers={headers} entries={entries} idName="uuid" />
        `}
      </FormattedCode>

      <Paragraph>
        The <code>Table</code> component takes care of lots of things for you
        but it's not a magical component, it wraps a lot of behavior, so you can
        have , for instance, a static table with no sorting options, by doing
        this.
      </Paragraph>

      <SubHeader>Static Tables</SubHeader>

      <Export name="StaticTable" />
      <FormattedCode prettier>
        {`
const headers = [{label: 'Name', key: 'item_name'}];
const entries = [{uuid: '1', item_name: 'Tim'}];
const MyTable = () => <StaticTable headers={headers} entries={entries} idName="uuid" />
        `}
      </FormattedCode>
      <Paragraph>
        <code>StaticTable</code>s work just like regular tables, except they
        don't have interactive headers, so you lose sorting
      </Paragraph>

      <StaticTable headers={headers} entries={entries} />

      <Paragraph>
        But remember, a regular interactive table uses a static table under the
        hood, so how does it work? You can think of the StaticTable as a kind of
        controlled table component. except that traditionally we'd update the
        entries prop and rerender from that.
      </Paragraph>
      <Paragraph>
        That would work just fine, but instead we can just pass in the keys we
        care about. By doing this, we can simplify filtering and sorting outside
        of the table while the table keeps a stable value reference to all the
        entities.
      </Paragraph>
      <FormattedCode prettier>
        {`
const MyTable = () => <StaticTable headers={headers} entries={entries} idName="id" rowKeys={['3']} />
        `}
      </FormattedCode>
      <Paragraph>
        This just gives up a table with one item (even though it has technically
        received _all the items_)
      </Paragraph>
      <StaticTable headers={headers} entries={entries} rowKeys={['3']} />

      <SubHeader>Selectable Tables</SubHeader>

      <Paragraph>
        Let's say we want to also make any of these rows selectable, we can do
        that by also passing in another property, <code>selectedKeys</code>.
      </Paragraph>
      <FormattedCode prettier>
        {`
const MyTable = () => <StaticTable headers={headers} entries={entries} idName="id" selectedKeys={['2', '3']} />
        `}
      </FormattedCode>
      <StaticTable
        headers={headers}
        entries={entries}
        selectedKeys={['2', '3']}
      />
      <Paragraph>
        It's not super useful though if we can't change the selected rows, so to
        make that part interactive we need to pass in a new prop
        <code>onSelect: (keys: string[]) => mixed</code> to the
        <code>Table</code> or <code>StaticTable</code>. As in this example, the
        handler is a simple <code>setState</code> call, but you could have it do
        more complex validation or fire off a fire and forget api call, or
        whatever.
      </Paragraph>
      <FormattedCode prettier>
        {`
const [staticTableKeys, setStaticTableKeys] = React.useState(['2', '3']);
const MyTable = () => <StaticTable
headers={headers} entries={entries}
idName="id" selectedKeys={staticTableKeys}
onSelect={setStaticTableKeys} />
        `}
      </FormattedCode>
      <Paragraph>
        Selecting rows in the table will set this value of keys:{' '}
        {staticTableKeys.join(', ')}.
      </Paragraph>
      <Table
        headers={headers}
        entries={entries}
        selectedKeys={staticTableKeys}
        onSelect={setStaticTableKeys}
      />
      <Paragraph>
        again, these options apply to both Table and StaticTable.
      </Paragraph>
      <SubHeader>Custom Cells</SubHeader>
      <Paragraph>
        It's possible you need to render out a custom cell, not just the default
        cell. There are two ways to do that. If you only need to modify a single
        cell, you can simply add a{' '}
        <code>{`render: React.ComponentType<{data: T, extras?: U}>`}</code> key
        to any given header item. This cell renderer receives the _whole row_ of
        data, not just the individual cell, it's up to you what to do with it
      </Paragraph>
      <FormattedCode prettier>
        {`
  const Zodiac = ({data}) => {
    const map = {
      taurus: '♉︎',
      aquarius: '♒︎',
      gemini: '♊︎',
      leo: '♌︎',
    };
    return (
      <div
        className={dsCss.bgClickableBackground}
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 30,
        }}
      >
        {map[data.sign] ?? '???'}
      </div>
    );
  };
        const fancyHeaders = [
          {label: 'Name', key: 'name'},
          {label: 'Tel', key: 'phone'},
          {label: 'Notes', key: 'notes'},
          {
            label: 'Sign',
            key: 'sign',
            render: Zodiac,
          },
          {label: 'email', key: 'email'}];

          const MyTable = () => <StaticTable
headers={fancyHeaders} entries={entries}
idName="id" selectedKeys={staticTableKeys}
onSelect={setStaticTableKeys} />
        `}
      </FormattedCode>

      {/* this could be better, let's talk about it --marcos */}
      <div style={{overflowX: 'scroll'}}>
        <Table
          className={css.scrollx}
          headers={fancyHeaders}
          entries={entries}
        />
      </div>
      <Export name="SingleCell" />
      <Export name="DoubleCell" />
      <Export name="DateCell" />
      <Export name="Monogram" />
      <Export name="MonogramCell" />
    </Section>
  );
};
const PaginationDemo = () => {
  const pageSize = 4;
  const [currentPage, setCurrentPage] = React.useState(1);
  const headers = [
    {label: 'Name', key: 'name'},
    {label: 'Tel', key: 'phone'},
    {label: 'Notes', key: 'notes'},
    {label: 'Sign', key: 'sign'},
    {label: 'email', key: 'email'},
  ];

  const entries = [
    {
      id: '1',
      name: 'Alice-1',
      phone: '123-456-7890',
      notes: 'likes candy',
      email: 'alice@symbolics.co',
      sign: 'taurus',
    },
    {
      id: '2',
      name: 'Charles-2',
      phone: '234-567-8901',
      notes: 'huge nerd',
      email: 'charles@lcs.edu',
      sign: 'aquarius',
    },
    {
      id: '3',
      name: 'Eva-3',
      phone: '345-678-9012',
      notes: 'sneaky',
      email: 'ev@luat.or',
      sign: 'gemini',
    },
    {
      id: '4',
      name: 'Ben-4',
      phone: '456-789-0123',
      notes: 'n/a',
      email: 'bitdiddle@t.co',
      sign: 'leo',
    },
    {
      id: '5',
      name: 'Alice-5',
      phone: '123-456-7890',
      notes: 'likes candy',
      email: 'alice@symbolics.co',
      sign: 'taurus',
    },
    {
      id: '6',
      name: 'Charles-6',
      phone: '234-567-8901',
      notes: 'huge nerd',
      email: 'charles@lcs.edu',
      sign: 'aquarius',
    },
    {
      id: '7',
      name: 'Eva-7',
      phone: '345-678-9012',
      notes: 'sneaky',
      email: 'ev@luat.or',
      sign: 'gemini',
    },
    {
      id: '8',
      name: 'Ben-8',
      phone: '456-789-0123',
      notes: 'n/a',
      email: 'bitdiddle@t.co',
      sign: 'leo',
    },
    {
      id: '9',
      name: 'Alice-9',
      phone: '123-456-7890',
      notes: 'likes candy',
      email: 'alice@symbolics.co',
      sign: 'taurus',
    },
    {
      id: '10',
      name: 'Charles-10',
      phone: '234-567-8901',
      notes: 'huge nerd',
      email: 'charles@lcs.edu',
      sign: 'aquarius',
    },
    {
      id: '11',
      name: 'Eva-11',
      phone: '345-678-9012',
      notes: 'sneaky',
      email: 'ev@luat.or',
      sign: 'gemini',
    },
    {
      id: '12',
      name: 'Ben-12',
      phone: '456-789-0123',
      notes: 'n/a',
      email: 'bitdiddle@t.co',
      sign: 'leo',
    },
  ];

  const setPageNumber = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const currentPageEntries = entries.slice(
    currentPage * pageSize - 4,
    currentPage * pageSize,
  );

  return (
    <Section name="Pagination" figmaLink="" relPath="pagination.jsx">
      <Export name="Pagination" />
      <Paragraph>
        Pagination component has 2 main props totalPages and onChange. it
        optionally takes currentPage prop in which case it acts as controlled
        component. The state is maintained by the component using the Pagination
        component. Following example shows pagination component.
      </Paragraph>
      <FormattedCode prettier>
        {`
          <Pagination totalPages={3} onChange={setPageNumber}/>
        `}
      </FormattedCode>
      <div className={css.paginationContainer}>
        <Header>{currentPage}</Header>
        <Pagination totalPages={3} onChange={setPageNumber} />
      </div>

      <Paragraph>
        Users can also input the valid page number to move quickly to the
        desired page. However, the component will take care of validating the
        user input and only call the onChange handler if the input is valid(i.e
        number/between min and max page values).
      </Paragraph>
      <Paragraph>
        Otherwise, it will indicate the invalid input by changing the input
        border to red. The input validation is only performed on pressing enter
        key after typing something in the input box. Try to move to page no 4 in
        following component.
      </Paragraph>
      <FormattedCode prettier>
        {`
          <Pagination totalPages={3} onChange={setPageNumber}/>
        `}
      </FormattedCode>
      <div className={css.paginationContainer}>
        <Header>{currentPage}</Header>
        <Pagination totalPages={3} onChange={setPageNumber} />
      </div>

      <Paragraph>
        Following example shows how pagination component can be combined with a
        table component to show paginated data.
      </Paragraph>

      <FormattedCode prettier>
        {`
        /* some code omitted for brevity */

        const pageSize = 4;
        const [currentPage, setCurrentPage] = React.useState(1);
        const setPageNumber = (currentPage) => {
          setCurrentPage(currentPage);
        };
        const currentPageEntries = entries.slice(
          currentPage * pageSize - pageSize,
          currentPage * pageSize,
        );
          <div>
        <Table  entries={currentPageEntries} />

        <Pagination totalPages={Math.floor(entries.length/pageSize)} onChange={setPageNumber}/>
        </div>
        `}
      </FormattedCode>

      <div className={css.tableWithPaginationContainer}>
        <Table headers={headers} entries={currentPageEntries} />
        <div className={css.paginationContainer}>
          <Paragraph>
            records: {currentPage * pageSize - pageSize + 1} to{' '}
            {currentPage * pageSize} of {entries.length}
          </Paragraph>
          <Pagination
            totalPages={Math.floor(entries.length / pageSize)}
            onChange={setPageNumber}
          />
        </div>
      </div>
    </Section>
  );
};

const DemoMenuButton = (props): React.Node => {
  // Apologies, this is complicated because the prettier transformation is
  // a bit expensive, so there's a ton of mouse enter/out chicanery to deal
  // with it.

  const timeoutId = React.useRef();
  const [title, setTitle] = React.useState(
    'Click to see source and copy to clipboard',
  );

  const [_didCopy, onClick] = useClipboard(title);

  const {children = null, ...rest} = props;
  return (
    <MenuButton
      {...rest}
      onClick={onClick}
      title={title}
      className={css.demoButton}
      onMouseOver={() => {
        if (timeoutId.current != null) {
          return;
        }
        const id = setTimeout(() => {
          setTitle(getCopyableProps({...rest}));
          timeoutId.current = null;
        }, 150);
        timeoutId.current = id;
      }}
      onMouseOut={() => {
        if (timeoutId.current != null) {
          clearTimeout(timeoutId.current);
          timeoutId.current = null;
        }
      }}
    >
      {children}
    </MenuButton>
  );
};
const MenuButtonRow = ({
  size,
  type,
  disabled,
  children,
  isSelectable,
  isSelected,
}) => {
  const defaultProps = {
    size,
    type,
    disabled,
    isSelectable,
    isSelected,
  };
  const icon = (
    <LinkIcon className={size === 'small' ? css.iconSmall : css.iconLarge} />
  );
  const iconOnlyProps = {
    ...defaultProps,
    icon,
  };
  const labelOnlyProps = {
    ...defaultProps,
    children,
  };
  const iconWithLabelProps = {
    ...defaultProps,
    icon,
    children,
  };

  return (
    <div>
      <DemoMenuButton {...labelOnlyProps} />
      <DemoMenuButton {...iconWithLabelProps} />
      <DemoMenuButton {...iconOnlyProps} />
    </div>
  );
};
const MenuButtonRows = (props: {
  children?: React.Node,
  type?: 'primary' | 'secondary' | 'tertiary',
  disabled?: boolean,
  isSelectable?: boolean,
  isSelected?: boolean,
}) => (
  <React.Fragment>
    {[undefined, 'small'].map((size) => (
      <MenuButtonRow key={size ?? 'default'} {...{...props, size}}>
        {props.children}
      </MenuButtonRow>
    ))}
  </React.Fragment>
);
const MenuButtonContent = (props: {children?: React.Node}) => {
  const propValues = {
    type: ['primary', 'secondary', 'tertiary'],
    icon: [<LinkIcon className={css.iconLarge} />, undefined],
    size: ['default', 'small'],
    disabled: [true, false],
  };

  return (
    <React.Fragment>
      {propValues.type.map((type) => (
        <React.Fragment key={type}>
          <SubHeader style={{textTransform: 'capitalize', marginTop: 16}}>
            {type}
          </SubHeader>
          <MenuButtonRows type={type} {...props}>
            {props.children}
          </MenuButtonRows>
          <SubHeader style={{textTransform: 'capitalize', marginTop: 16}}>
            {type} disabled
          </SubHeader>
          <MenuButtonRows type={type} disabled={true} {...props}>
            {props.children}{' '}
          </MenuButtonRows>

          {['tertiary'].includes(type) && (
            <>
              <SubHeader style={{textTransform: 'capitalize', marginTop: 16}}>
                {type} selected (only when isSelectable=true)
              </SubHeader>
              <MenuButtonRows {...props} type={type} isSelectable isSelected />
              <SubHeader style={{textTransform: 'capitalize', marginTop: 16}}>
                {type} unselected (only when isSelectable=true)
              </SubHeader>
              <MenuButtonRows
                {...props}
                type={type}
                isSelectable={true}
                isSelected={false}
              />
            </>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

const MenuButtonsDemo = () => {
  const [buttonContent, setButtonContent] = React.useState('Button Label');

  return (
    <Section
      name="Menu Buttons"
      figmaLink="https://www.figma.com/file/T64DM8GIUZqVavdsBlhP5F/2.0-hi-fi-components-%26-icons?node-id=91%3A4937"
      relPath="menu-buttons.jsx"
    >
      <Paragraph>
        Menu buttons are specifically designed for places where we want to open
        a menu. They share most but not all properties with our buttons. The
        shared properties are: size and types.
      </Paragraph>
      <FormattedCode>
        {`
<MenuButton type="primary" icon={<LinkIcon />} />;
        `}
      </FormattedCode>
      <Paragraph>
        This text input below allows you to see what the MenuButton will look
        like with different labels, but in practice you can do this just by
        throwing any React.Node as the child of the Button component.
      </Paragraph>

      <input
        type="text"
        className={classify(dsCss.defaultBorder, dsCss.defaultPadded)}
        value={buttonContent}
        placeholder="MenuButton Text"
        onChange={(evt) => setButtonContent(evt.currentTarget.value)}
      />
      <Export name="MenuButton" />
      <MenuButtonContent>{buttonContent}</MenuButtonContent>
    </Section>
  );
};

const MenuWithTriggerDemo = () => {
  const options = [
    {label: 'one', value: 'one'},
    {label: 'two', value: 'two'},
    {label: 'three', value: 'three'},
    {label: 'four', value: 'four'},
    {label: 'five', value: 'five'},
    {label: 'six', value: 'six'},
    {label: 'seven', value: 'seven'},
    {label: 'eight', value: 'eight'},
    {label: 'nine', value: 'nine'},
    {label: 'ten', value: 'ten'},
  ];
  const triggerTypes = ['primary', 'secondary', 'tertiary'];
  const triggerSizes = ['default', 'small'];

  return (
    <Section name="Menu" figmaLink="" relPath="menu.jsx">
      <Paragraph>
        Use this component provide a button which opens options on click.
      </Paragraph>
      <FormattedCode prettier>
        {`

        `}
      </FormattedCode>
      <div className={css.menuDemoContainer}>
        <Export name="Menu">
          {triggerSizes.map((size) =>
            triggerTypes.map((type) => (
              <div
                style={{display: 'flex', alignItems: 'flex-end'}}
                key={`${type}-${size}-with-text`}
              >
                <MenuWithTrigger
                  classNames={{container: css.menuContainer}}
                  options={options}
                  size={size}
                  trigger={
                    <MenuButton type={type} size={size} icon={<LinkIcon />}>
                      Select
                    </MenuButton>
                  }
                  onSelect={console.log}
                />
              </div>
            )),
          )}
          {triggerSizes.map((size) =>
            triggerTypes.map((type) => (
              <div style={{display: 'flex'}} key={`${type}-${size}-with-icon`}>
                <MenuWithTrigger
                  options={options}
                  size={size}
                  trigger={
                    <MenuButton type={type} size={size} icon={<LinkIcon />} />
                  }
                  onSelect={console.log}
                />
              </div>
            )),
          )}
          <div className={css.section}>
            <Clickable>Menu with some disabled options</Clickable>
            <MenuWithTrigger
              options={options.map((item) => ({
                ...item,
                disabled: ['one', 'three'].includes(item.value),
              }))}
              classNames={{options: css.optionsWithDisabledItems}}
              trigger={<MenuButton icon={<LinkIcon />}>Select</MenuButton>}
              onSelect={console.log}
            />
          </div>
        </Export>
      </div>
    </Section>
  );
};

const RadioDemo = () => {
  const [radioAChecked, setRadioAChecked] = React.useState('');
  return (
    <Section
      name="Radio"
      relPath="radio.jsx"
      figmaLink="https://www.figma.com/file/T64DM8GIUZqVavdsBlhP5F/2.0-hi-fi-components-%26-icons?node-id=40%3A3600"
    >
      <>
        <br />
        Labeled
        <div className={css.labeledCheckboxContainer}>
          <Export name="Radio">
            <Radio
              checked={radioAChecked === 'A'}
              value="A"
              onChange={(e) => setRadioAChecked(e.target.value)}
            >
              Radio A
            </Radio>
          </Export>
          <Export name="Radio">
            <Radio
              checked={radioAChecked === 'B'}
              value="B"
              onChange={(e) => setRadioAChecked(e.target.value)}
            >
              Radio B
            </Radio>
          </Export>
          <Export name="Radio">
            <Radio
              checked={radioAChecked === 'C'}
              value="C"
              onChange={(e) => setRadioAChecked(e.target.value)}
            >
              Radio C
            </Radio>
          </Export>
          <Export name="Radio">
            <Radio checked={false} disabled>
              Radio C
            </Radio>
          </Export>
          <Export name="Radio">
            <Radio checked={true} disabled>
              Radio D
            </Radio>
          </Export>
        </div>
        <br />
        Unlabeled
        <div className={css.labeledCheckboxContainer}>
          <Export name="Radio">
            <Radio
              checked={radioAChecked === 'A'}
              value="A"
              onChange={(e) => setRadioAChecked(e.target.value)}
            ></Radio>
          </Export>
          <Export name="Radio">
            <Radio
              checked={radioAChecked === 'B'}
              value="B"
              onChange={(e) => setRadioAChecked(e.target.value)}
            ></Radio>
          </Export>
          <Export name="Radio">
            <Radio
              checked={radioAChecked === 'C'}
              value="C"
              onChange={(e) => setRadioAChecked(e.target.value)}
            ></Radio>
          </Export>
          <Export name="Radio">
            <Radio checked={false} disabled />
          </Export>
          <Export name="Radio">
            <Radio checked={true} disabled />
          </Export>
        </div>
      </>
    </Section>
  );
};

const TextareaDemo = () => {
  const [value, setValue] = React.useState('');
  const [requiredValue, setRequiredValue] = React.useState('');
  const error = value.includes('bad word');
  const errorText = 'No bad words allowed';
  const onChange = (evt) => {
    setValue(evt.target.value);
  };
  return (
    <>
      <Section name="Textarea" relPath="textarea.jsx">
        <Paragraph>
          Basic Textarea component with label and error state.
        </Paragraph>

        <SubHeader>Controlled Textarea with error state</SubHeader>
        <div className={css.inputContainer}>
          <Textarea
            error={error}
            errorText={errorText}
            value={value}
            placeholder="Try typing bad word"
            onChange={onChange}
            label="Input Label"
            contextLabel="Optional"
          />
        </div>

        <FormattedCode>
          {`
() => {
  const [value, setValue] = React.useState('');
  const error = value.includes('bad word');
  const errorText = 'No bad words allowed';
  const onChange = (evt) => {
    setValue(evt.target.value);
  };
  return <Textarea
    error={error}
    errorText={errorText}
    value={value}
    placeholder="Try typing bad word"
    onChange={onChange}
    label="Textarea Label"
    contextLabel="Optional"
  />
}
        `}
        </FormattedCode>

        <SubHeader>Disabled Textarea</SubHeader>
        <div className={css.inputContainer}>
          <Textarea
            disabled
            placeholder="Disabled"
            label="Textarea Label"
            contextLabel="Locked"
          />
        </div>

        <FormattedCode>
          {`
  <Textarea
  disabled
  placeholder="Disabled"
  label="Textarea Label"
  contextLabel="Locked"
/>
          `}
        </FormattedCode>

        <SubHeader>Uncontrolled Textarea</SubHeader>
        <div className={css.inputContainer}>
          <Textarea
            placeholder="Placeholder"
            label="Textarea Label"
            contextLabel="Optional"
          />
        </div>

        <FormattedCode>
          {`
<Textarea
  placeholder="Placeholder"
  label="Textarea Label"
  contextLabel="Optional"
/>
          `}
        </FormattedCode>
        <SubHeader>Required Textarea</SubHeader>
        <div className={css.inputContainer}>
          <Textarea
            error={!requiredValue.length}
            errorText={'must be filled'}
            placeholder="Placeholder"
            label="Textarea Label"
            contextLabel="Required"
            value={requiredValue}
            onChange={(evt) => {
              setRequiredValue(evt.target.value);
            }}
          />
        </div>

        <FormattedCode>
          {`
<Textarea
  placeholder="Placeholder"
  label="Textarea Label"
  contextLabel="Optional"
/>
          `}
        </FormattedCode>
      </Section>
    </>
  );
};

const BannerCompDemo = () => {
  const ContentWarningMapping = {
    emoji: 'Avoid using money-related emojis. 💰🤑💸 💳',
    punctuation: 'Avoid using excessive punctuation.',
    length: 'Keep messages under 140 characters.',
    url_shortener: 'Public URL shorteners are banned by carriers.',
    caps: 'Avoid using excessive ALL CAPS.',
  };
  const data = {};
  data.content_warning_types = ['punctuation', 'emoji', 'length'];
  data.content_warning_messages = [];
  data.content_warning_types.map((warning) => {
    let show_resolve_button = true;
    if (warning === 'length') {
      show_resolve_button = false;
    }
    data.content_warning_messages.push({
      warning_type: warning,
      warning_message: ContentWarningMapping[warning],
      show_resolve_button,
    });
  });

  const handleResolve = async () => {};

  const handleClose = () => {};

  const onMouseEnter = () => {};

  const onMouseLeave = () => {};
  const linkToMoreInfo = 'https://www.sensehq.com/';

  return (
    <Section
      name="Banner component"
      figmaLink="https://www.figma.com/file/zdPxs0fvZLJFP0KhS7x2A5/Small-UX-Improvement?node-id=708%3A9175"
      relPath="banner.jsx"
    >
      <Paragraph>
        use this component wherever you want to show warnings.
      </Paragraph>
      <FormattedCode prettier>
        {`
          <Banner 
          data={data.content_warning_messages} 
          handleResolve={handleResolve}
          handleClose={handleClose} 
          onMouseEnter={onMouseEnter} 
          onMouseLeave={onMouseLeave}
          linkToMoreInfo={linkToMoreInfo}/>
        `}
      </FormattedCode>
      <Paragraph>
        Implement the methods handleResolve, handleClose, onMouseEnter,
        onMouseLeave as per feature/product requirement.Attribute
        linkToMoreInfo(is optional) to redirect the users to any knowledge or
        info page.
      </Paragraph>
      <div className={css.bannerDemoContainer}>
        <Export name="Banner">
          <Banner
            data={data.content_warning_messages}
            handleResolve={handleResolve}
            handleClose={handleClose}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            linkToMoreInfo={linkToMoreInfo}
          />
        </Export>
      </div>
    </Section>
  );
};

const AsyncSearchDemo = () => {
  const options = [
    {label: 'one', value: 'one'},
    {label: 'two', value: 'two'},
    {label: 'three', value: 'three'},
    {label: 'four', value: 'four'},
    {label: 'five', value: 'five'},
    {label: 'six', value: 'six'},
    {label: 'seven', value: 'seven'},
    {label: 'eight', value: 'eight'},
    {label: 'nine', value: 'nine'},
    {label: 'ten', value: 'ten'},
  ];
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  const onSelect = (option) => {
    setSelectedOptions([...selectedOptions, option]);
  };
  const resolveOptions = async (query) =>
    options.filter((option) => option.label.includes(query));

  return (
    <Section name="Async Search" figmaLink="" relPath="async-search.jsx">
      <Paragraph>
        use this component wherever you want to search from a list of options.
      </Paragraph>
      <FormattedCode prettier>
        {`

        `}
      </FormattedCode>
      <div className={css.searchDemoContainer}>
        <Export name="AsyncSearch">
          <AsyncSearch
            defaultOptions={options}
            onSelect={onSelect}
            resolveOptions={resolveOptions}
          />
        </Export>
        <div className={css.selectedSearchResults}>
          {selectedOptions.map((option) => (
            <Button type="tertiary" size="small">
              {option.label}
            </Button>
          ))}
        </div>
      </div>
    </Section>
  );
};
