// @flow

import type {
  Field,
  FieldMap,
  FieldType,
  Resource,
} from 'src/types/audience-member';
import type {State} from 'src/reducers';
import type {State as AudienceMembersMap} from 'src/reducers/audience-members/audience-members';
import type {EntityType, EntityAttributeMapping} from 'src/types/ats-entities';
import type {SentEventWithExternals} from 'src/api-parsers/events.types';

import {createSelector} from 'reselect';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';

import {values} from 'src/utils/object';
import {
  entityAsLegacyEntity,
  // $FlowFixMe[untyped-type-import]
  type ModifiedLegacyAudienceMember,
} from 'src/selectors/multientity-audience';

// TODO (aditya): change get to select
export const getAudienceMembers = (state: State): AudienceMembersMap =>
  state.audienceMembers.audienceMembers;
const getFields = (state: State) => state.audienceMembers.fields;
export const getMultiEntityEnabled = (state: State): boolean =>
  state.agency.config.multiEntityEnabled;
const getLegacyAudienceMembers = (state: State) =>
  state.audienceMembers.audienceMembers;
const getMeAudienceMembers = (state: State) =>
  state.multientityAudience.entities;

const getLegacyAudienceMember = (state: State, audienceMemberId: string) =>
  getLegacyAudienceMembers(state)[audienceMemberId];

// audience member bridge between legacy/multientity audiences
// at some point this can just point to the correct store.
export const selectAudienceMember: (
  State,
  string,
  // $FlowFixMe[value-as-type]
) => ModifiedLegacyAudienceMember = createSelector(
  getMultiEntityEnabled,
  getLegacyAudienceMembers,
  getMeAudienceMembers,
  (_state, id) => id,
  (multiEntityEnabled, legacyAudienceMembers, meAudienceMembers, id) => {
    if (multiEntityEnabled) {
      return entityAsLegacyEntity(meAudienceMembers[id]);
    } else {
      return legacyAudienceMembers[id].audienceMember;
    }
  },
);

export const selectAudienceMemberSentEvents = (
  state: State,
  audienceMemberId: string,
): SentEventWithExternals[] =>
  getLegacyAudienceMember(state, audienceMemberId)?.sentEvents || [];

const makeFieldsToTypes = (fields: Field[]) =>
  fields.reduce((acc, field) => {
    acc[field.name] = field.type;
    return acc;
  }, {});

export const selectFieldsAsArray: (State) => Field[] = createSelector(
  getFields,
  (fields): Field[] => values(fields),
);

export const selectFieldsToTypes: (State) => {
  [string]: FieldType,
} = createSelector(selectFieldsAsArray, makeFieldsToTypes);

export const selectFieldsForResource: (
  State,
  Resource,
) => Field[] = createSelector(
  selectFieldsAsArray,
  (_, resource) => resource,
  (fields, resource) => fields.filter((field) => field.resource === resource),
);

export const selectMemberFields = (state: State): Field[] =>
  selectFieldsForResource(state, 'member');
export const selectPlacementFields = (state: State): Field[] =>
  selectFieldsForResource(state, 'placement');

export const selectFieldMapForResource: (
  State,
  Resource,
) => {[string]: Field} = createSelector(selectFieldsForResource, (fields) =>
  keyBy(fields, 'name'),
);

export const selectMemberFieldsToTypes: (State) => {
  [string]: FieldType,
} = createSelector(selectMemberFields, makeFieldsToTypes);
export const selectPlacementFieldsToTypes: (State) => {
  [string]: FieldType,
} = createSelector(selectPlacementFields, makeFieldsToTypes);

export const selectEntitySummaryById = (
  state: State,
  id: string,
  atsEntityType: EntityType,
): EntityAttributeMapping =>
  state.multientityAudience.related[atsEntityType]?.[id] || {};

//
// TODO (kyle): eventually stop using these getters below in favor of the selectors.
//

export const getFieldsToTypes = (state: State): {[string]: FieldType} =>
  mapFieldsToTypes(state.audienceMembers.fields);

export const getMemberFieldsToTypes = (state: State): {[string]: FieldType} =>
  mapFieldsToTypes(pickFieldsByResource(state, 'member'));

export const getPlacementFieldsToTypes = (
  state: State,
): {[string]: FieldType} =>
  mapFieldsToTypes(pickFieldsByResource(state, 'placement'));

const mapFieldsToTypes = (fields: FieldMap) =>
  mapValues(fields, ({type}) => type);

const pickFieldsByResource = (state, resource) =>
  pickBy(state.audienceMembers.fields, (field) => field.resource === resource);
