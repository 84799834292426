// @flow strict
import type {SalesPerformanceEntry} from 'src/types/sales-performance-analytics';

import type {SalesPerformanceAnalyticsActions} from 'src/action-creators/sales-performance-list';
import {
  RECEIVE_SALES_LIST,
  REFETCH_SALES_PERFORMANCE_LIST,
} from 'src/action-creators/sales-performance-list';


export type SalesPerformanceAnalyticsState = {
  lists: SalesPerformanceEntry[],
  refetch: boolean,
};

const initialState = {
  lists: [],
  refetch: false,
};

export default (
  state: SalesPerformanceAnalyticsState = initialState,
  action: SalesPerformanceAnalyticsActions,
): SalesPerformanceAnalyticsState => {
  switch (action.type) {
    case RECEIVE_SALES_LIST: {
      const lists = action.payload;
      return {
        ...state,
        lists,
      };
    }
    case REFETCH_SALES_PERFORMANCE_LIST: {
      return {
        ...state,
        refetch: !state.refetch,
      };
    }
    default:
      return {...state};
  }
};
