// @noflow

import * as React from 'react';
import kebabCase from 'lodash/kebabCase';

import NewAdd from 'src/images/new-add-button.svg?noAttrs';
import {classify} from 'src/utils';

import css from './index.css';


type Props = {
  className?: string,
  onClick?: () => void,
  style?: *,
  text: string,
};

const NewAddButton = ({text, onClick, className, style}: Props) => (
  <button
    className={classify(css.addButton, className)}
    onClick={onClick}
    style={style}
    type="button"
    data-qa-id={`${kebabCase(text)}`}
  >
    <span className={css.flexWrapper}>
      <NewAdd className={css.plusSign} />
      <span className={css.sansLabel}>{text}</span>
    </span>
  </button>
);

export default NewAddButton;
