// @noflow

import typeof {IndexStore} from 'src/stores/index';
import type {Agency} from 'src/api-parsers';
import type {BrandingSettings} from 'src/api-parsers/events';
import type {RouteLocation} from 'src/types/router';

import * as React from 'react';
import {connect} from 'react-redux';
import {Link} from 'src/rerouter';
import {Helmet} from 'react-helmet';

import logger from 'src/utils/logger';
import {classify} from 'src/utils';

import {signIn} from 'src/actions/index';
import {createElement} from 'src/utils/dom';
import {getValidAgencies} from 'src/action-creators/auth';
import {isMobile, isAndroid} from 'src/utils/responsive';

import {UPDATE_APP_URL} from 'src/chat-extension/messages';
import {EXTENSION_ID} from 'src/chat-extension/constants';

import Table, {Cells, Cell} from 'src/components/lib/table';
import FluxComponent from 'src/flux/component.jsx';

import SenseIcon from 'src/images/sense-icon.svg';

import css from './auth.css';


const getExpiryPeriod = (durationInSeconds = 0) => {
  if (durationInSeconds < 60) {
    return [durationInSeconds, 'seconds'];
  }
  return [Math.floor(durationInSeconds / 60), 'minutes'];
};

const SsoButton = ({query, buttonColor, ssoAuthUrl}) => {
  const {nextPathname, nextSearch} = query;
  const parsedNextSearch = new URLSearchParams(nextSearch);
  const queryString = parsedNextSearch.toString();
  const pathString = JSON.stringify({
    path: `${nextPathname || ''}?${queryString}`,
  });
  return (
    <>
      {query.oauth_error && (
        <div className={css.error}>
          Your account is not setup for SSO, contact your SSO administrator.
        </div>
      )}
      <a
        href={`${ssoAuthUrl}&state=${pathString}`}
        target="_blank"
        className={css.ssoButton}
        style={
          buttonColor && {
            backgroundColor: buttonColor,
            borderColor: buttonColor,
          }
        }
      >
        Login with SSO
      </a>
    </>
  );
};

type Props = {
  store: IndexStore,
  dynamicAgency: boolean,
  getValidAgencies: () => void,

  agency: {
    brandingSettings: BrandingSettings,
    ssoAuthUrl: string | null,
  },
  me: {
    signingIn: boolean,
    signinError: boolean,
  },
  location: RouteLocation,
};

type State = {
  validAgencies: ?[],
  email: string,
  password: string,
  error: boolean,
  cookies: {[string]: string} | null,
  cookieEnabled: true,
};

const showSpinner = ({
  dynamicAgency,
  location,
  cookies,
  isMobile,
  ssoAuthUrl,
}) => {
  if (isMobile) {
    if (!dynamicAgency) {
      return true;
    } else {
      const {nextPathname, nextSearch} = location.query;
      const parsedNextSearch = new URLSearchParams(nextSearch);
      const isPwa = parsedNextSearch.get('context') === 'pwa';
      const trySignin = parsedNextSearch.get('signin') === 'try';
      const agencyLocation = cookies?.['sosense-agency-location'];

      return (
        dynamicAgency && isPwa && trySignin && (!cookies || agencyLocation)
      );
    }
  } else {
    return false;
  }
};

class Signin extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      validAgencies: null,
      email: '',
      password: '',
      cookies: null,
      cookieEnabled: true,
    };

    (this: any).handleSubmit = this.handleSubmit.bind(this);
    (this: any).handlePwaRedirects = this.handlePwaRedirects.bind(this);
  }

  handlePwaRedirects(window) {
    const {location, dynamicAgency} = this.props;
    // if mobile redirect to auth so pwa users can login with unified login
    const host = window.location.host;
    const [slug, ...rest] = host.split('.');
    if (slug !== 'auth' && isMobile) {
      window.location.host = ['auth', ...rest].join('.');
    }

    // Note (aditya): the android shell will navigate to auth.sensehq.com when reopened (even if the user is still logged in)
    // so we navigate to the agency subdomain found in the cookie so the user can resume without logging in every time.
    const {nextPathname, nextSearch} = location.query;
    const parsedNextSearch = new URLSearchParams(nextSearch);
    const isPwa = parsedNextSearch.get('context') === 'pwa';
    const trySignin = parsedNextSearch.get('signin') === 'try';
    const cookies = window.document.cookie
      .split(';')
      .reduce((cookies, cookie) => {
        if (cookie.trim()) {
          const [cookieName, cookieValue] = cookie.trim().split('=');
          cookies[cookieName] = cookieValue;
        }
        return cookies;
      }, {});
    this.setState({cookies});
    const agencyLocation = cookies?.['sosense-agency-location'];
    if (dynamicAgency && isMobile && isPwa && trySignin && agencyLocation) {
      const url = window.location.href;
      const protocall = window.location.protocol;
      parsedNextSearch.delete('signin');
      const queryString = parsedNextSearch.toString();
      window.location.href = `${protocall}//${agencyLocation}${
        nextPathname || ''
      }?${queryString}`;
    }
  }

  componentDidMount() {
    const {
      isMobile,
      isAndroid,
      dynamicAgency,
      agency: {ssoAuthUrl: ssoAuthUrl},
    } = this.props;

    if (!navigator.cookieEnabled) {
      this.setState({cookieEnabled: false});
    }

    if (isMobile) {
      this.handlePwaRedirects(window);
    }

    if (
      dynamicAgency &&
      'serviceWorker' in navigator &&
      isMobile &&
      isAndroid
    ) {
      logger.log('registering service worker...');
      navigator.serviceWorker.register('/service-worker.js').then(
        (registration) => {
          // Registration was successful
          logger.log(
            'ServiceWorker registration successful with scope: ',
            registration.scope,
          );
        },
        (err) => {
          // registration failed
          logger.log('ServiceWorker registration failed: ', err);
        },
      );
    }
  }
  render() {
    const {signinError, signingIn} = this.props.me;
    const {
      agency: {
        brandingSettings: {buttonColor},
        ssoAuthUrl: ssoAuthUrl,
      },
      store,
      dynamicAgency,
      location,
      isMobile,
    } = this.props;
    const isRateLimitError = signinError && signinError.status === 429;
    const isError = !isRateLimitError && (signinError || this.state.error);

    const {validAgencies, email, password, cookies, cookieEnabled} = this.state;
    if (showSpinner({dynamicAgency, location, cookies, isMobile, ssoAuthUrl})) {
      return <SenseIcon className={css.spinner} />;
    }
    return (
      <div>
        {validAgencies && (
          <div className={css.agencyContainer}>
            <p className={css.headerText}>Please select your account.</p>
            <Table
              extras={{store, email, password}}
              header={agencyHeader}
              entries={validAgencies}
              Row={AgencyRow}
              defaultSortKey="name"
              className={css.table}
              sortable={false}
            />
          </div>
        )}
        {!validAgencies && (
          <form
            className={css.form}
            action="/api/v1/auth"
            method="POST"
            onSubmit={this.handleSubmit}
            data-it-signin-form
          >
            <Helmet title="Sign In" />
            {!cookieEnabled && (
              <div className={css.error}>
                Cookies are disabled. Please enable them to sign in.
              </div>
            )}
            {ssoAuthUrl ? (
              <SsoButton
                ssoAuthUrl={ssoAuthUrl}
                query={this.props.location.query}
                buttonColor={buttonColor}
              />
            ) : (
              <>
                {isError && (
                  <div className={css.error}>Incorrect email or password.</div>
                )}
                {isRateLimitError && (
                  <div className={css.error}>
                    For your safety, sign in has been temporarily locked due to
                    suspicious account activity. Please try again after{' '}
                    {getExpiryPeriod(
                      signinError.response.expiry_until_in_sec,
                    ).join(' ')}{' '}
                    or tap the "Forgot Password" link below.
                  </div>
                )}
                <input
                  autoFocus={true}
                  className={classify(css.input, 'zipy-block')}
                  disabled={signingIn || !cookieEnabled}
                  type="email"
                  name="email"
                  placeholder="Email"
                  data-it-email
                />
                <input
                  className={classify(css.input, 'zipy-block')}
                  disabled={signingIn || !cookieEnabled}
                  type="password"
                  name="password"
                  placeholder="Password"
                  data-it-password
                />

                <button
                  className={css.button}
                  disabled={signingIn || !cookieEnabled}
                  style={
                    buttonColor && {
                      backgroundColor: buttonColor,
                      borderColor: buttonColor,
                    }
                  }
                >
                  {signingIn ? 'Signing In...' : 'Sign In'}
                </button>
                <div className={css.endNoteBox}>
                  <span className={css.endnote}>
                    <Link to="/forgot" className={css.link}>
                      Forgot Password?
                    </Link>
                  </span>
                </div>
              </>
            )}
          </form>
        )}
      </div>
    );
  }

  handleSubmit(event) {
    const {dynamicAgency, store, me, getValidAgencies, isMobile} = this.props;
    event.preventDefault();

    if (me.signingIn) {
      return;
    }

    const originalForm = event.target;
    const info = {
      email: originalForm.email.value,
      password: originalForm.password.value,
    };
    const {nextPathname, nextSearch} = this.props.location.query;
    const domain = getDomainFromStore(store);
    const {query} = this.props.location;

    if (dynamicAgency) {
      // Get valid agencies
      getValidAgencies(info)
        .then((validAgencies) => {
          if (validAgencies.length === 1) {
            // If one valid agency, automatically log in
            const action = getFormAction(
              validAgencies[0].slug,
              domain,
              query,
              isMobile,
            );
            mockSigninPost(action, info.email, info.password);
          } else {
            // If multiple valid agencies, have user choose one to login
            this.setState({
              error: false,
              validAgencies,
              email: info.email,
              password: info.password,
            });
          }
        })
        .catch((e) => {
          this.setState({error: e});
        });
      return;
    }

    signIn(store, window.location, info).then((result) => {
      if (result?.response?.status === 300) {
        // A migration audit exists for this user, we should redirect them to the new agency app url
        const slug = result?.responseBody?.slug;
        const appUrl = `${slug}.${domain}`;
        sendUpdateAppUrlMessage(appUrl);
        const action = getFormAction(
          slug,
          domain,
          this.props.location.query,
          isMobile,
        );
        mockSigninPost(action, info.email, info.password);
      }
    });
  }
}

const agencyHeader = [
  {label: 'Agency', key: 'name'},
  {label: 'Action', key: 'action'},
];

const AgencyRow = ({
  data: {name, slug},
  extras: {store, email, password},
}: {
  data: {
    name: string,
    slug: string,
  },
  extras: {
    store: IndexStore,
    email: string,
    password: string,
  },
}) => (
  <Cells>
    <Cell>{name}</Cell>
    <Cell>
      <Link
        onClick={() => {
          const action = `//${slug}.${getDomainFromStore(store)}/api/v1/auth`;
          mockSigninPost(action, email, password);
        }}
      >
        Sign in
      </Link>
    </Cell>
  </Cells>
);

const getFormAction = (slug, domain, query, isMobile) => {
  const {nextPathname, nextSearch} = query;

  //if mobile only allow navigating to messages
  const pathName =
    isMobile && !nextPathname?.includes('/messages')
      ? '/messages'
      : nextPathname;

  const origin = `https://${slug}.${domain}`;

  const defaultPath = encodeURIComponent(
    `${origin}${pathName || ''}${nextSearch || ''}`,
  );
  const action = `${origin}/api/v1/auth?default_path=${defaultPath}`;

  return action;
};

const getDomainFromStore = (store: IndexStore) => {
  // Get hostname from store
  const {
    env: {hostname = ''},
  } = store.reduxStore.getState();

  // Cut off the subdomain from hostname
  return hostname.split('.').slice(1).join('.');
};

function sendUpdateAppUrlMessage(appUrl: string) {
  const chrome = window.chrome;
  if (!chrome) {
    return;
  }

  const message = {
    type: UPDATE_APP_URL,
    payload: appUrl,
  };

  chrome.runtime.sendMessage(EXTENSION_ID, message, (response) => {
    logger.log('app url updated', appUrl);
  });
}

// DANGER ZONE
const mockSigninPost = (action, email, password) => {
  const fakeForm = createElement(document, 'form', {
    style: 'display: none',
    method: 'POST',
    action,
  });

  const fakeFormEmailInput = createElement(document, 'input', {
    name: 'email',
    value: email,
  });
  fakeForm.appendChild(fakeFormEmailInput);

  const fakeFormPasswordInput = createElement(document, 'input', {
    name: 'password',
    type: 'password',
    value: password,
  });
  fakeForm.appendChild(fakeFormPasswordInput);

  document.body.appendChild(fakeForm);

  fakeForm.submit();
};

const mapDispatchToProps = (dispatch) => ({
  getValidAgencies(info) {
    return dispatch(getValidAgencies(info.email, info.password));
  },
});

const mapStateToProps = (state) => ({
  isMobile: isMobile(state.requestHeaders.headers['user-agent']),
  isAndroid: isAndroid(state.requestHeaders.headers['user-agent']),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FluxComponent(Signin));
