// @flow strict

export const CONSTANTS = {
  PRIVACY_POLICY_LINK: 'https://www.sensehq.com/privacy-policy',
  PRIVACY_POLICY_TEXT: 'Privacy Policy',
  BACK_BUTTON_TEXT: 'Back',
  SIGNIN_TITLE: 'Hi, welcome to Sense!',
  INVALID_EMAIL: 'Please enter a valid email address',
  INVALID_FULL_NAME: 'Name is mandatory',
  EMPTY_PASSWORD: 'Password is mandatory',
  PHONE_REGISTARTION_TITLE: 'Multifactor authentication',
  PHONE_REGISTARTION_SUBTEXT:
    'Your organization has enabled multifactor authentication for added security. To generate your verification code, please enter your registered phone number.',
  OTP_PAGE_TITLE: 'Multifactor authentication',
  OTP_PAGE_SUBTEXT: 'A verification code has been sent to your phone number',
  EMAIL_OTP_PAGE_SUBTEXT:
    'Your organization has enabled multifactor authentication for added security. The verification code has been sent to your registered email.',
  PHONE_RESET_TITLE: 'Pending admin approval.',
  RESEND_OTP_SUCCESS: 'Verification code has been resent',
  PHONE_RESET_SUBTEXT:
    'An email with a link to reset your phone number has been sent to your administrator for approval.',
  DYNAMIC_AGENCY_TITLE: 'Hi, welcome to Sense!',
  DYNAMIC_AGENCY_SUBTEXT: 'Please select your account.',
  RESET_PASSWORD_TITLE: 'Reset password',
  RESET_PASSWORD_SUBTEXT:
    'Enter the email address associated with your account and we\'ll send you a link to reset your password.',
  FORGOT_PASSWORD_CONFIRMATION_TITLE:
    'Please check your email for a password reset link.',
  FORGOT_PASSWORD_CONFIRMATION_SUBTEXT:
    'You will receive an email with a link to reset your password if you have entered an email that matches your account. Please check spam or junk folders as well.',
  RESET_PASSWORD_PAGE_TITLE: 'Create new password',
  PASSWORD_INSRUCTION_TEXT:
    'To secure your account, make sure your password includes:',
  PASSWORD_INS_1:
    'At least 12 characters (required for your password)—the more characters, the better',
  PASSWORD_INS_2: 'At least one uppercase and lowercase letter',
  PASSWORD_INS_3: 'At least one number',
  PASSWORD_INS_4: 'At least one special character, e.g., ! @ # ? ]',
  RESET_PASSWORD_DISABLED_ALERT: 'Invalid Reset Token or User Not Logged In',
  SIGNUP_TITLE: 'Hi, welcome to Sense!',
  SIGNUP_SUBTEXT: 'Enter the below details to sign up.',
  RESET_PASSWORD_CONFIRMATION_TITLE: 'Password has been reset',
  RESET_PASSWORD_CONFIRMATION_SUBTEXT:
    'You can now access your account using your new password.',
  BRANDING_PAGE_HEADER: 'The Sense Talent Engagement Platform',
  BRANDING_PAGE_SUBTEXT:
    'Accelerate hiring, strengthen your brand, and exceed recruiting targets - all while delivering a personalized candidate experience.',
};

export const ALLOWED_COUNTRY_CODES = [
  'US',
  'GB',
  'AU',
  'HK',
  'MY',
  'SG',
  'DE',
  'BE',
  'NZ',
  'IN',
];
