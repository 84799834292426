// @noflow
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classify from 'src/utils/classify';
import {useEnvironmentContext} from 'src/hooks/useEnvironmentContext';
import IndexStore from 'src/stores';
import {useHistory} from 'src/rerouter';
import {setExternalEventDetails} from 'src/action-creators/messages';
import {getCsrfToken} from 'src/action-creators/csrf';
import {selectCsrfToken} from 'src/selectors/csrf';
import {getReleaseFlags} from 'src/action-creators/product-flags';
import {getAgencyConfig} from 'src/action-creators/agency';
import {getDisplayConfig} from 'src/action-creators/display-config';
import {getAuthedUser, getAuthedAccount} from 'src/action-creators/accounts';
import {fetchAllInboxes} from 'src/action-creators/chat/queues';
import {CircularLoader} from '@spaced-out/ui-design-system/lib/components/CircularLoader';
import {ErrorMessage} from '@spaced-out/ui-design-system/lib/components/ErrorMessage';
//$FlowFixMe[nonstrict-import]
import {selectAgencyData} from 'src/selectors/agency';

import {ButtonTextExtraSmall} from '@spaced-out/ui-design-system/lib/components/Text';
import ProductBrandingContainer from './product-branding.jsx';
import {CONSTANTS} from 'src/components/authentication/contants.js';
import * as reduxApi from 'src/utils/redux-api-v2';
// $FlowFixMe[nonstrict-import]
import {useApi} from 'src/hooks/useApi';

import css from './container.css';
import maincss from '../main.css';


type AuthenticationContainerProps = {
  children: React.Node,
  senseStore: IndexStore,
};
const AuthenticationContainer = (
  props: AuthenticationContainerProps,
): React.Node => {
  const {isExtension} = useEnvironmentContext();
  const router = useHistory();
  const dispatch = useDispatch();
  const {result, error} = useApi(`marketing-content`);
  const [showLoader, setShowLoader] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const {senseStore} = props;

  const ssoAuthUrl = useSelector(selectAgencyData).ssoAuthUrl;
  const showDualAuthForm = useSelector(selectAgencyData).showDualAuthForm;

  const handleExternalMessagingEvents = async (event) => {
    // TODO (Ashwini) need to check if this is needed. Causing re-renders because receiving events from react dev tools
    if (/^react-devtools/gi.test(event.data.source)) {
      return;
    }

    if (event.data.type === 'MULTIPLE_RECIPIENT') {
      setShowLoader(true);
      const {origin} = event;
      const {exchangeToken, phoneNumbers, channel} = event.data.payload;
      if (exchangeToken) {
        try {
          await dispatch(
            reduxApi.post('auth/exchange', {
              exchange_token: exchangeToken,
            }),
          );
        } catch (e) {
          setShowLoader(false);
          setShowError(true);
          throw e;
        }

        try {
          // authentication of user and also get the configurations
          const csrfToken = await dispatch(getCsrfToken());

          // setting the csrf token on window for api's called from src/utils/api-no-store.js
          if (window) {
            window.csrfToken = csrfToken;
          }

          await dispatch(getReleaseFlags());
          await dispatch(getAgencyConfig());
          await dispatch(getDisplayConfig());
          await dispatch(getAuthedUser(senseStore));
          await dispatch(getAuthedAccount(senseStore));

          // messaging
          await dispatch(fetchAllInboxes());
          await dispatch(
            setExternalEventDetails({phoneNumbers, channel, origin}),
          );
        } catch (e) {
          throw e;
        }

        router.replace(`/messages`);
        setShowLoader(false);
      }
    }
  };

  React.useEffect(() => {
    if (!isExtension) {
      window.addEventListener('message', handleExternalMessagingEvents);
    }

    return () => {
      if (!isExtension) {
        window.removeEventListener('message', handleExternalMessagingEvents);
      }
    };
  }, [showError, showLoader]);

  if (showLoader) {
    return (
      <div className={css.container}>
        <CircularLoader colorToken="colorFillPrimary" size="large" />
      </div>
    );
  }

  if (showError) {
    return (
      <div className={css.container}>
        <ErrorMessage
          classNames={{
            wrapper: css.errorMessage,
          }}
          description="This error occurs when the exchange token provided for authentication is invalid or expired. The exchange token is a unique identifier generated by our API for each authentication request, and it must be valid and within its time-to-live (TTL) to authenticate successfully"
          heading="Invalid Exchange Token"
          imageVariant="forbidden"
          title="Exchange Token Validation Failure"
        />
      </div>
    );
  }

  return (
    <div className={css.mainContainer}>
      {!isExtension && (
        <ProductBrandingContainer
          content={
            result?.content === 'marketing' && !error ? result?.settings : null
          }
        />
      )}
      {!showError && !showLoader && (
        <div
          className={classify(css.content, {
            [css.contentBackground]: ssoAuthUrl && !showDualAuthForm,
          })}
        >
          <div className={css.chlidElement}>
            {React.cloneElement(props.children)}
          </div>
          <div className={css.footerSection}>
            <a href={CONSTANTS.PRIVACY_POLICY_LINK} target="_blank">
              <ButtonTextExtraSmall
                className={classify(css.privaryPolicy, maincss.underline)}
              >
                {CONSTANTS.PRIVACY_POLICY_TEXT}
              </ButtonTextExtraSmall>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthenticationContainer;
