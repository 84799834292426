// @noflow

import typeof IndexStore from 'src/stores/index';
import type {Agency} from 'src/api-parsers/index';
import type {State} from 'src/reducers';

import * as React from 'react';
import {provideHooks} from 'src/flux/provideHooks.jsx';
import {connect} from 'react-redux';
import flow from 'lodash/flow';

import {fluxify} from 'src/flux/component.jsx';
import {getAgency} from 'src/action-creators/agency';
import {
  selectSigninSplashEnabled,
  selectSigninSplashIsCorpAgency,
} from 'src/hooks/product-flags';

import Loading from 'src/components/lib/loading/loading.jsx';
import Logo from 'src/images/sense-logo.svg';

import {Hero} from './staffing-splash.jsx';
import MFAAuthContainer from 'src/components/authentication/container/container.jsx';

import css from './auth.css';


const mappers = {
  me: (store) => store.me.state,
};

const mapStateToProps = (state: State) => ({
  agency: state.agency.agency,
  agencySlug: state.env.agencySlug,
  splashEnabled: selectSigninSplashEnabled(state),
  isCorpAgency: selectSigninSplashIsCorpAgency(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAgency: () => dispatch(getAgency()),
});

class AuthContainer extends React.Component<{
  store: IndexStore,
  router: any,
  children: React.Element<any>,

  agency: Agency,
  me: any, // TODO(marcos) replace with type
  getAgency: () => void,
}> {
  componentDidMount() {
    if (this.props.agencySlug !== 'auth') {
      this.props.getAgency();
    }
  }
  render() {
    if (!this.props.agency) {
      return <Loading />;
    }

    const {agency, me} = this.props;
    const {logo} = agency.brandingSettings;

    if (window.location.pathname.includes('signin') && agency.mfaEnabled) {
      return <MFAAuthContainer store={this.props.store} agency={agency} />;
    }

    return (
      <div className={css.wrapper}>
        {this.props.splashEnabled && !this.props.isCorpAgency && <Hero />}

        <div className={css.bg}>
          <div className={css.container}>
            <div className={css.logo}>
              {logo ? <img className={css.logoImage} src={logo} /> : <Logo />}
            </div>

            {React.cloneElement(this.props.children, {agency, me})}
          </div>
          <div className={css.footer}>
            Powered by{' '}
            <div className={css.footerLogo}>
              <Logo />
            </div>
          </div>
          <div className={css.endnote}>
            <a
              className={css.privacyPolicyLink}
              href="https://www.sensehq.com/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default flow(
  fluxify(mappers),
  // Since this component has children, which could be flux-connected components,
  // we must direct React Redux to NOT make this a pure component, otherwise
  // child components will not re-render when the Flux store changes
  connect(mapStateToProps, mapDispatchToProps, undefined, {pure: false}),
)(AuthContainer);
