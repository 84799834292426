// @noflow
import * as React from 'react';

import css from './audience-csv-upload-modal.css';

import {classify} from 'src/utils/index';
import Checkmark from 'src/images/checkmark.svg';


export default class AudienceCsvUploadModal extends React.Component<$FlowFixMeProps> {
  render() {
    return (
      <div className={classify(this.props.className, css.container)}>
        <div className={css.header}>
          <div className={css.title} >Upload CSV</div>
          <div className={css.fileTitle}>TItle<Checkmark className={css.checkmark} /></div>
        </div>

        <div className={css.form}>
          <div className={css.tagLabel}>Add tags (optional)</div>
          <input type="text" className={css.tagInput} placeholder="Enter Name" />
        </div>

        <div className={css.menu}>
          <div className={css.cancelButton}>Cancel</div>
          <div className={css.nextButton}>Next</div>
        </div>
      </div>
    );
  }
}
