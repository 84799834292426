// @flow

import type {ThunkAction} from 'src/reducers';

import omit from 'lodash/omit';

import {thunkify as flow} from 'src/utils/thunks';
import {camel, snake} from 'src/utils';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api-v2';
import {selectAgencyConfig} from 'src/selectors/agency';

import {
  VIEW_WORKFLOWS,
  EDIT_WORKFLOWS,
  VIEW_AUDIENCE_MEMBERS,
  EDIT_AUDIENCE_MEMBERS,
  VIEW_ANALYTICS,
  EDIT_SETTINGS,
  EDIT_PERMISSIONS,
  USE_MESSAGING,
  MANAGE_OPT_OUTS,
  EDIT_CHATBOT,
  ADMIN_REFERRALS,
  RECRUITER_REFERRALS,
  MANAGE_CALENDAR,
  MANAGE_TEAM_TEMPLATES,
  TRM_ADMIN,
  TRM_VIEW,
  VIEW_REFERRALS_V2,
  EDIT_REFERRALS_V2,
  MANAGE_SECURITY,
  ADMIN_REFERRALS_V2,
  USE_JD_GENERATOR,
  USE_PRESCREENING_GENERATOR,
  USE_RESUME_GENERATOR,
  USE_ENGAGE_CONTENT_GENERATOR,
  USE_MESSAGING_SMS_GENERATOR,
  PAGES_LEADS,
  VIEW_PAGES,
  EDIT_PAGES,
  ADMIN_PAGES,
  VIEW_LEAD_MANAGEMENT,
  ADMIN_LEAD_MANAGEMENT,
  EDIT_LEAD_MANAGEMENT,
  PAGES,
  LEADS,
  VIEW_AEP_WORKFLOWS,
  EDIT_AEP_WORKFLOWS,
  USE_SURVEY_SUMMARY_GENERATOR,
  VIEW_JOBS,
  ADMIN_JOBS,
  type Privilege,
} from 'src/types/roles';


export {
  VIEW_WORKFLOWS,
  EDIT_WORKFLOWS,
  VIEW_AUDIENCE_MEMBERS,
  EDIT_AUDIENCE_MEMBERS,
  VIEW_ANALYTICS,
  EDIT_SETTINGS,
  EDIT_PERMISSIONS,
  USE_MESSAGING,
  MANAGE_OPT_OUTS,
  EDIT_CHATBOT,
  ADMIN_REFERRALS,
  RECRUITER_REFERRALS,
  MANAGE_CALENDAR,
  MANAGE_TEAM_TEMPLATES,
  TRM_ADMIN,
  TRM_VIEW,
  VIEW_REFERRALS_V2,
  EDIT_REFERRALS_V2,
  MANAGE_SECURITY,
  ADMIN_REFERRALS_V2,
  USE_JD_GENERATOR,
  USE_PRESCREENING_GENERATOR,
  USE_RESUME_GENERATOR,
  USE_ENGAGE_CONTENT_GENERATOR,
  USE_MESSAGING_SMS_GENERATOR,
  PAGES_LEADS,
  VIEW_PAGES,
  EDIT_PAGES,
  ADMIN_PAGES,
  VIEW_LEAD_MANAGEMENT,
  ADMIN_LEAD_MANAGEMENT,
  EDIT_LEAD_MANAGEMENT,
  PAGES,
  LEADS,
  VIEW_AEP_WORKFLOWS,
  EDIT_AEP_WORKFLOWS,
  USE_SURVEY_SUMMARY_GENERATOR,
  VIEW_JOBS,
  ADMIN_JOBS,
};
export type {Privilege};

/*
 * Actions
 */
export const RECEIVE: 'roles/receive' = 'roles/receive';
export const RECEIVE_ROLE: 'roles/receiveRole' = 'roles/receiveRole';
export const REMOVE_ROLE: 'roles/removeRole' = 'roles/removeRole';
export const START_EDITING: 'roles/startEditing' = 'roles/startEditing';
export const START_EDITING_ERROR: 'roles/startEditingError' =
  'roles/startEditingError';
export const CLEAR_EDITING: 'roles/clearEditing' = 'roles/clearEditing';
export const EDIT_ROLE_NAME: 'roles/updateRoleName' = 'roles/updateRoleName';
export const EDIT_ROLE_PRIVILEGES: 'roles/updateRolePrivileges' =
  'roles/updateRolePrivileges';
export const EDIT_ROLE_CONFIGURATION: 'roles/updateRoleConfiguration' =
  'roles/updateRoleConfiguration';
export const MARK_ERRORS_SHOWABLE: 'roles/markErrorsShowable' =
  'roles/markErrorsShowable';

const normalize = (role: Role): ApiRole => snake(omit(role, ['id']));

const parse = (role: ApiRole): Role => camel(role);

export type Concern =
  | 'WORKFLOWS'
  | 'ANALYTICS'
  | 'AUDIENCE_MEMBERS'
  | 'SETTINGS'
  | 'PERMISSIONS'
  | 'MESSAGING'
  | 'MANAGE_OPT_OUTS'
  | 'CHATBOT'
  | 'REFERRAL'
  | 'SCHEDULER'
  | 'TRM'
  | 'REFERRAL_V2'
  | 'SECURITY'
  | 'SENSE_AI'
  | 'PAGES'
  | 'LEADS'
  | 'AUTOMATION_WORKFLOWS'
  | 'JOBS';

export type RoleConfigurations = {
  broadcastRecipientLimit: number,
  tollfreeBroadcastRecipientLimit: number,
};

export type Role = {
  id: string,
  name: string,
  privileges: Privilege[],
  configurations: RoleConfigurations,
};

type ApiRole = {
  id: string,
  name: string,
  privileges: Privilege[],
  configurations: RoleConfigurations,
};

export type RolesAction =
  | ReceiveRolesAction
  | ReceiveRoleAction
  | RemoveRoleAction
  | EditRoleNameAction
  | EditRolePrivilegesAction
  | EditRoleConfigurationAction
  | StartEditingAction
  | StartEditingErrorAction
  | MarkErrorsShowableAction
  | ClearEditingAction;

type ReceiveRolesAction = {
  type: typeof RECEIVE,
  payload: Role[],
};

export const receive = (payload: Role[]): ReceiveRolesAction => ({
  type: RECEIVE,
  payload,
});

type ReceiveRoleAction = {
  type: typeof RECEIVE_ROLE,
  payload: Role,
};

const receiveRole = (payload: Role): ReceiveRoleAction => ({
  type: RECEIVE_ROLE,
  payload,
});

type RemoveRoleAction = {
  type: typeof REMOVE_ROLE,
  payload: string,
};

const removeRole = (payload: string): RemoveRoleAction => ({
  type: REMOVE_ROLE,
  payload,
});

export type ErrorMessagesByType = {[key: string]: string[], ...};

type StartEditingAction = {
  type: typeof START_EDITING,
  payload: Role,
};

const startEditingRole = (role: Role): StartEditingAction => ({
  type: START_EDITING,
  payload: role,
});

type StartEditingErrorAction = {
  type: typeof START_EDITING_ERROR,
  payload: ErrorMessagesByType,
  error: true,
};

const startEditingError = (
  errorMessagesByType: ErrorMessagesByType,
): StartEditingErrorAction => ({
  type: START_EDITING_ERROR,
  payload: errorMessagesByType,
  error: true,
});

type ClearEditingAction = {
  type: typeof CLEAR_EDITING,
};

const clearEditing = (): ClearEditingAction => ({
  type: CLEAR_EDITING,
});

type MarkErrorsShowableAction = {
  type: typeof MARK_ERRORS_SHOWABLE,
};

export const markErrorsShowable = (): MarkErrorsShowableAction => ({
  type: MARK_ERRORS_SHOWABLE,
});

export const startEditing =
  (id: string | void): ThunkAction<> =>
  (dispatch, getState) => {
    dispatch(clearEditing());
    if (id) {
      return dispatch(getRoles()).then(() => {
        const role = getState().roles.data[id];

        if (!role) {
          return dispatch(
            startEditingError({
              notFound: [`Could not get role with id ${String(id)}`],
            }),
          );
        }

        return dispatch(startEditingRole(role));
      });
    } else {
      const emptyRole: Role = {
        id: '',
        name: '',
        privileges: [],
        configurations: {
          broadcastRecipientLimit: selectAgencyConfig(getState())[
            'broadcastRecipientLimit'
          ],
          tollfreeBroadcastRecipientLimit: selectAgencyConfig(getState())[
            'tollfreeBroadcastRecipientLimit'
          ],
        },
      };

      return dispatch(startEditingRole(emptyRole));
    }
  };

export const getRoles: () => ThunkAction<mixed> = flow(
  key(() => 'getRoles'),
  cached((response) => receive(response.map(parse)), {ttl: 2000}),
  fetching({}),
)(() => reduxApi.get('security/roles'));

export const createRole =
  (role: Role): ThunkAction<> =>
  (dispatch) =>
    dispatch(reduxApi.post('security/roles', normalize(role))).then((updated) =>
      dispatch(receiveRole(updated)),
    );

export const updateRole =
  (role: Role): ThunkAction<> =>
  (dispatch) =>
    dispatch(reduxApi.put(`security/roles/${role.id}`, normalize(role))).then(
      (updated) => dispatch(receiveRole(updated)),
    );

export const deleteRole =
  (roleId: string): ThunkAction<> =>
  (dispatch) =>
    dispatch(reduxApi.del(`security/roles/${roleId}`)).then(() =>
      dispatch(removeRole(roleId)),
    );

type EditRoleNameAction = {
  type: typeof EDIT_ROLE_NAME,
  payload: {
    name: string,
  },
};

export const editRoleName = (name: string): EditRoleNameAction => ({
  type: EDIT_ROLE_NAME,
  payload: {
    name,
  },
});

export type EditRolePrivilegesAction = {
  type: typeof EDIT_ROLE_PRIVILEGES,
  payload: {
    concern: Concern,
    level: string,
  },
};

export const editRolePrivileges = (
  concern: Concern,
  level: string,
): EditRolePrivilegesAction => ({
  type: EDIT_ROLE_PRIVILEGES,
  payload: {
    concern,
    level,
  },
});

export type EditRoleConfigurationAction = {
  type: typeof EDIT_ROLE_CONFIGURATION,
  payload: {
    configurationKey: string,
    value: any,
  },
};

export const editRoleConfiguration = (
  configurationKey: string,
  value: any,
): EditRoleConfigurationAction => ({
  type: EDIT_ROLE_CONFIGURATION,
  payload: {
    configurationKey,
    value,
  },
});
