// @flow strict

import * as React from 'react';
import {isNaN, isNumber} from 'lodash';

import {classify} from 'src/utils/classify';

import {Button} from 'src/designSystem2021Components/buttons.jsx';
import PrevIcon from 'src/images/designSystems2021/prev.svg';
import NextIcon from 'src/images/designSystems2021/next.svg';
import {Input} from 'src/designSystem2021Components/input.jsx';
import ChevronDoubleRightIcon from 'src/images/icons/chevron-double-right.svg?noAttrs';

import css from './pagination.css';


export type PaginationProps = {
  className?: string,
  totalPages: number,
  currentPage?: number,
  onChange: (page: number) => mixed,
};

export const Pagination = ({
  className,
  totalPages,
  currentPage: controlledValue,
  onChange,
}: PaginationProps): React.Node => {
  const [internalCurrentPage, setInternalCurrentPage] = React.useState(1);
  const currentPage = controlledValue ?? internalCurrentPage;
  const [inputValue, setInputValue] = React.useState('');
  const [inputError, setInputError] = React.useState(false);
  const inputRef = React.useRef(null);

  const moveNext = () => {
    if (currentPage < totalPages) {
      const newPageNo = currentPage + 1;
      move(newPageNo);
    }
  };
  const movePrev = () => {
    if (currentPage > 1) {
      const newPageNo = currentPage - 1;
      move(newPageNo);
    }
  };
  const moveFirst = () => {
    if (currentPage !== 1) {
      move(1);
    }
  };
  const moveLast = () => {
    if (currentPage !== totalPages) {
      move(totalPages);
    }
  };
  const handleTextBoxEnterKey = () => {
    inputRef.current?.blur();
    const newPageNo = parseInt(inputValue, 10);
    if (isNaN(newPageNo) || !isNumber(newPageNo)) {
      setInputError(true);
      return;
    }
    if (newPageNo > totalPages || newPageNo < 1) {
      setInputError(true);
      return;
    }
    if (newPageNo >= 1 && newPageNo <= totalPages) {
      move(newPageNo);
    }
  };

  const move = (pageNumber: number) => {
    if (pageNumber === currentPage) {
      return;
    }
    setInputError(false);
    setInputValue('');
    setInternalCurrentPage(pageNumber);
    onChange && onChange(pageNumber);
  };

  const prevIconDisabled = currentPage === 1;
  const nextIconDisabled = currentPage === totalPages;

  return (
    <div className={classify(css.wrapper, className)}>
      <Button
        type="tertiary"
        icon={<ChevronDoubleRightIcon className={css.rotate180} />}
        onClick={moveFirst}
      ></Button>
      <Button
        type="tertiary"
        icon={<PrevIcon />}
        disabled={prevIconDisabled}
        onClick={movePrev}
      ></Button>
      <Input
        value={inputValue}
        onChange={(e) => setInputValue(e.currentTarget.value)}
        error={inputError}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            return handleTextBoxEnterKey();
          }
        }}
        type="number"
        placeholder={'' + currentPage}
        ref={inputRef}
      />

      <Button
        type="tertiary"
        icon={<NextIcon />}
        disabled={nextIconDisabled}
        onClick={moveNext}
      ></Button>
      <Button
        type="tertiary"
        icon={<ChevronDoubleRightIcon />}
        onClick={moveLast}
        iconPosition="right"
      >
        {totalPages ?? ''}
      </Button>
    </div>
  );
};
