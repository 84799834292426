var React = require('react');

function Prev (props) {
    return React.createElement("svg",props,React.createElement("path",{"fillRule":"evenodd","clipRule":"evenodd","d":"M5.47 9.53a.75.75 0 010-1.06l4.5-4.5a.75.75 0 111.06 1.06L7.06 9l3.97 3.97a.75.75 0 11-1.06 1.06l-4.5-4.5z"}));
}

Prev.defaultProps = {"width":"18","height":"18","viewBox":"0 0 18 18"};

module.exports = Prev;

Prev.default = Prev;
