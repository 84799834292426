// @flow strict
import * as React from 'react';
import {useEnvironmentContext} from 'src/hooks/useEnvironmentContext';
import {
  SubTitleLarge,
  BodyLarge,
  BodyMedium,
  SubTitleSmall,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {CONSTANTS} from 'src/components/authentication/contants.js';

import ResetImage from 'src/images/reset-confirmation.svg';
import css from './forgot-confirmation.css';
import maincss from 'src/components/authentication/main.css';


const ForgotPasswordConfirmation = (): React.Node => {
  const {isExtension} = useEnvironmentContext();
  return (
    <div className={css.resetConfirmationPage}>
      <ResetImage />
      {isExtension ? (
        <SubTitleSmall className={maincss.textAlignCenter}>
          {CONSTANTS.FORGOT_PASSWORD_CONFIRMATION_TITLE}
        </SubTitleSmall>
      ) : (
        <SubTitleLarge className={css.titleText}>
          {CONSTANTS.FORGOT_PASSWORD_CONFIRMATION_TITLE}
        </SubTitleLarge>
      )}
      {isExtension ? (
        <BodyMedium className={maincss.textAlignCenter}>
          {CONSTANTS.FORGOT_PASSWORD_CONFIRMATION_SUBTEXT}
        </BodyMedium>
      ) : (
        <BodyLarge className={css.infoText}>
          {CONSTANTS.FORGOT_PASSWORD_CONFIRMATION_SUBTEXT}
        </BodyLarge>
      )}
    </div>
  );
};

export default ForgotPasswordConfirmation;
