// @noflow

import typeof IndexStore from 'src/stores/index';
import type {TextQuestion} from 'src/types/survey';
import type {DynamicLabels} from 'src/action-creators/dynamic-labels';

import * as React from 'react';
import _get from 'lodash/get';

import {updateModule} from 'src/actions/event-creation';

import RequiredToggle from 'src/components/workflow/event/content/module/required-toggle.jsx';
import {StringListInput} from 'src/components/lib/token-list-input';
import DraftInput from 'src/components/lib/draft-input';

import moduleCss from './module.css';


type Props = {
  fluxStore: IndexStore,
  error?: Object,
  moduleData: TextQuestion,
  conditionalAlerts?: boolean,
  dynamicLabels: DynamicLabels,
  multiEntityEnabled: boolean,
};

const OpenEndedModule = ({
  fluxStore,
  error,
  moduleData,
  conditionalAlerts,
  dynamicLabels,
  multiEntityEnabled,
}: Props) => {
  const questionErrors = _get(error, 'question.errors', []);
  return (
    <div className={moduleCss.generalModule_content}>
      <label className={moduleCss.generalModule_questionLabel}>
        <div className={moduleCss.labelWithInlineSelector}>Question Text</div>
        <DraftInput
          className={moduleCss.generalModule_input}
          value={moduleData.question}
          hasError={error?.question?.errors?.length}
          placeholder="What could we do to make your job better?"
          dynamicLabels={dynamicLabels}
          onChange={(value) => updateModule(fluxStore, {question: value})}
          showAddButton={multiEntityEnabled}
          multiEntityEnabled={multiEntityEnabled}
        />
      </label>
      <div className={moduleCss.generalModule_row}>
        {conditionalAlerts && (
          <label className={moduleCss.generalModule_alertKeywordsLabel}>
            <div className={moduleCss.rowLabel}>
              Send alert when keywords detected:
            </div>
            <StringListInput
              placeholder="e.g. bad, late, inexperienced"
              values={moduleData.alertKeywords || []}
              onChange={(alertKeywords) =>
                updateModule(fluxStore, {alertKeywords})
              }
              verticalStretch
            />
          </label>
        )}
        <RequiredToggle store={fluxStore} moduleData={moduleData} />
      </div>
    </div>
  );
};

export default OpenEndedModule;
