// @flow strict

import * as React from 'react';
import {Clickable, Smallest} from 'src/designSystem2021Components/text-v2.jsx';
import {DefaultFooter} from 'src/components/agency-onboard/footer/default-footer.jsx';
import {Radio} from 'src/designSystem2021Components/radio/radio.jsx';
import type {Task} from 'src/components/agency-onboard/types/side-panel-comp.js';

import css from './note-writeback.css';
import maincss from 'src/components/agency-onboard/agency-onboard.css';
import classify from 'src/utils/classify';


export type NoteWritebackProps = {
  task: Task,
  onTaskSubmit: (id: string, value: mixed) => mixed,
  onTaskCancel: () => mixed,
  data: {
    writeback_type: string,
  },
};

export const NoteWriteback = (props: NoteWritebackProps): React.Node => {
  const {task, onTaskCancel, onTaskSubmit, data} = props;
  const [writebackType, setWritebackType] = React.useState(
    data.writeback_type || null,
  );
  const [disableSubmit, setDisableSubmit] = React.useState(true);

  const handleFormSubmit = () => {
    onTaskSubmit(task['id'], {writeback_type: writebackType});
  };

  const handleFormCancel = () => {
    onTaskCancel();
  };

  return (
    <div className={maincss.sidePanel}>
      <Clickable className={maincss.textPrimary}>
        Select a format for Note writeback
      </Clickable>
      <Clickable className={classify(maincss.textPrimary, css.text)}>
        Engage has the capability to add notes taken to be written directly to
        your ATS. Please select your preferred format below
      </Clickable>

      <div className={css.previewOption}>
        <Radio
          checked={writebackType === 'Inbound vs Outbound'}
          value="Inbound vs Outbound"
          onChange={(e) => {
            setWritebackType(e.target.value);
            setDisableSubmit(false);
          }}
        >
          <Clickable className={classify(maincss.text, css.textBold)}>
            Inbound vs Outbound
          </Clickable>
        </Radio>

        <div className={css.previewBox}>
          <Clickable className={classify(maincss.textPrimary, css.textBold)}>
            Outbound
          </Clickable>
          <ul className={css.summaryText}>
            <li>
              <Clickable className={classify(maincss.text, css.textSmallBold)}>
                I have a Machinist available in your city, interested?
              </Clickable>
            </li>
            <li>
              <Clickable className={classify(maincss.text, css.textSmallBold)}>
                From Joel Fischer (+16155415807) to Zach (+14158413251)
              </Clickable>
            </li>
            <li>
              <Smallest className={classify(maincss.text, css.textSmallLight)}>
                Nov 20, 2020, 09:12 AM (times are listed in PDT)
              </Smallest>
            </li>
          </ul>
          <div className={css.inboundSection}>
            <Clickable className={classify(maincss.textPrimary, css.textBold)}>
              Inbound
            </Clickable>
            <ul className={css.summaryText}>
              <li>
                <Clickable
                  className={classify(maincss.text, css.textSmallBold)}
                >
                  Always! What do you have?
                </Clickable>
              </li>
              <li>
                <Clickable
                  className={classify(maincss.text, css.textSmallBold)}
                >
                  From Zech Morrissey (+16155415807) to Joel Fischer
                  (+14158413251)
                </Clickable>
              </li>
              <li>
                <Smallest
                  className={classify(maincss.text, css.textSmallLight)}
                >
                  Nov 20, 2020, 09:08 AM (times are listed in PDT)
                </Smallest>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={css.previewOption}>
        <Radio
          checked={writebackType === 'Daily Summary'}
          value="Daily Summary"
          onChange={(e) => {
            setWritebackType(e.target.value);
            setDisableSubmit(false);
          }}
        >
          <Clickable className={classify(maincss.text, css.textBold)}>
            Daily Summary
          </Clickable>
        </Radio>
        <div className={css.previewBox}>
          <Clickable className={classify(maincss.textPrimary, css.textBold)}>
            SMS Daily Summary:
          </Clickable>
          <ul className={classify(css.summaryText, css.summaryPreviewBox)}>
            <li>
              <Clickable
                className={classify(maincss.textPrimary, css.textSmallBold)}
              >
                Joel Fischer (09:08 AM):
              </Clickable>
              <Clickable className={classify(maincss.text, css.textSmallBold)}>
                Hey Zach, are you still looking for a job?{' '}
              </Clickable>
            </li>
            <li>
              <Clickable
                className={classify(maincss.textPrimary, css.textSmallBold)}
              >
                Zach (09:09 AM):
              </Clickable>
              <Clickable className={classify(maincss.text, css.textSmallBold)}>
                Always! What do you have?
              </Clickable>
            </li>
            <li>
              <Clickable
                className={classify(maincss.textPrimary, css.textSmallBold)}
              >
                Joel Fischer (09:12 AM):
              </Clickable>
              <Clickable className={classify(maincss.text, css.textSmallBold)}>
                I have a Machinist available in your city, interested?
              </Clickable>
            </li>
            <li>
              <Clickable className={classify(maincss.text, css.textSmallBold)}>
                Messaging history between Joel Fischer (+16155415807) and Zach
                +1215813251{' '}
              </Clickable>
            </li>
            <li>
              <Smallest className={classify(maincss.text, css.textSmallLight)}>
                Nov 20, 2020, 09:12 AM (times are listed in PST){' '}
              </Smallest>
            </li>
          </ul>
        </div>
      </div>
      <DefaultFooter
        disableSubmit={disableSubmit}
        onSubmit={handleFormSubmit}
        onCancel={handleFormCancel}
      />
    </div>
  );
};
