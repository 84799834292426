// @flow
// $FlowFixMe[untyped-type-import]
import typeof {IndexStore} from 'src/stores/index';
// $FlowFixMe[untyped-type-import]
import type {BrandingSettings} from 'src/api-parsers/events';
import type {AccountInvite} from 'src/types/account-invite';
import type {ErrorsByField as PasswordErrorsByField} from 'src/components/auth/password-confirmation.jsx';
import {connect} from 'react-redux';

import flow from 'lodash/flow';
import pick from 'lodash/pick';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import find from 'lodash/find';
import * as React from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'src/rerouter';
import {provideHooks} from 'src/flux/provideHooks.jsx';

import authCss from './auth.css';
import css from './signup.css';

import {ErrorBox} from 'src/components/lib/error-box';
import Loading from 'src/components/lib/loading';
import {PasswordConfirmation} from 'src/components/auth/password-confirmation.jsx';
import Input from 'src/components/lib/error-input';
import {fluxify} from 'src/flux/component.jsx';
import {createAccount} from 'src/actions/index';
import {useApi} from 'src/hooks/useApi';
import {values} from 'src/utils/object';
import {ApiError} from 'src/utils/errors';
import {classify} from 'src/utils';


type APICreateAccountErrorName =
  | 'EmailAlreadyExists'
  | 'InvalidEmail'
  | 'InvalidPassword'
  | 'InvalidFullname'
  | 'InvalidAgency'
  | 'InvalidInviteCode';
type APICreateAccountError = {
  response: {
    errors: APICreateAccountErrorName[],
  },
};

function getErrorObject(
  serverErrorName: ?APICreateAccountErrorName,
): ErrorsByField {
  switch (serverErrorName) {
    case 'InvalidEmail':
      return {
        email: {invalid: 'The email you entered is not a valid email address.'},
      };
    case 'InvalidInviteCode':
      return {
        form: {invalidInvite: 'Invalid invite code'},
      };
    case 'EmailAlreadyExists':
      return {
        email: {
          exists: (
            <span>
              The email you entered already exists. Perhaps you meant to{' '}
              <Link className={css.link} to="/signin">
                Sign In
              </Link>
              ?
            </span>
          ),
        },
      };
    case 'InvalidPassword':
      return {
        password: {
          invalidPassword:
            'You cannot use that password. Please try another password.',
        },
      };
    default:
      return {
        form: {generic: 'Error signing up. Please try again.'},
      };
  }
}

function normalizeServerError(
  serverError: APICreateAccountError,
): ErrorsByField {
  const errorNames = get(serverError, 'response.errors', []);
  if (errorNames.length === 0) {
    return {};
  }

  return merge(...errorNames.map((errorName) => getErrorObject(errorName)));
}

type ErrorNameDescMap = {[errorName: string]: string | React.Node};

type ErrorsByField = {
  form?: ErrorNameDescMap,
  fullname?: ErrorNameDescMap,
  email?: ErrorNameDescMap,
  password?: PasswordErrorsByField,
  ...
};

type SignupProps = {
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  me: any, // FIXME(marcos): this is more involved
  agency: {
    // $FlowFixMe[value-as-type]
    brandingSettings: BrandingSettings,
  },

  location: any,
};

const Signup = (props: SignupProps): React.Node => {
  const [errors, setErrors] = React.useState<ErrorsByField>({});

  const serverError = props.me.signupError;

  const prevServerErrorRef = React.useRef();
  React.useEffect(() => {
    if (serverError !== prevServerError) {
      setErrors((prev: ErrorsByField): ErrorsByField => ({
        ...prev,
        validationErrors: normalizeServerError(serverError),
      }));
    }
    prevServerErrorRef.current = serverError;
  }, [serverError]);
  const prevServerError = prevServerErrorRef.current;

  const {
    agency: {
      brandingSettings: {color},
    },
    location: {
      query: {invite_code},
    },
  } = props;

  const inviteResource = useApi<AccountInvite>(
    `account_invites/${invite_code}`,
    {
      shouldCamel: true,
      shouldFetch: !!invite_code,
    },
  );

  let invite = undefined;
  if (invite_code && inviteResource.isLoading) {
    return <Loading />;
  } else if (
    !invite_code ||
    // $FlowIssue[prop-missing] status is part of the serialized SOError on 404s
    inviteResource?.status === 404
  ) {
    // pass, no invite or invalid invite
  } else if (inviteResource.result) {
    invite = pick(inviteResource.result, [
      'email',
      'firstName',
      'lastName',
      'inviteCode',
    ]);
  }

  return (
    <SignupForm
      color={color}
      invite={invite}
      inviteCode={invite_code}
      store={props.store}
      setErrors={setErrors}
      errors={errors}
    />
  );
};

const SignupForm = ({
  invite,
  inviteCode,
  store,
  color,
  setErrors,
  errors,
}: {
  invite?: {
    email?: string,
    firstName?: string,
    lastName?: string,
    inviteCode?: string,
  },
  inviteCode?: string,
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  color: string,
  setErrors: (errors: ErrorsByField) => mixed,
  errors: ErrorsByField,
}): React.Element<'form'> => {
  const {creating} = store.me;

  const inviteEmail = get(invite, 'email');
  const firstName = get(invite, 'firstName');
  const lastName = get(invite, 'lastName');

  const [fullName, setFullName] = React.useState(
    `${firstName || ''} ${lastName || ''}`.trim(),
  );
  const [email, setEmail] = React.useState(inviteEmail);
  const [passwordScore, setPasswordScore] = React.useState(0);
  const [passwordErrors, setPasswordErrors] = React.useState({});
  const [newPassword, setNewPassword] = React.useState('');

  const inviteCodeErrors =
    // fixme marcos: invite should be useApi'd
    inviteCode && !invite
      ? ['The invite code has either expired or is invalid']
      : [];

  function handleSubmit(event) {
    event.preventDefault();

    if (store.me.state.creating) {
      return;
    }

    const validationErrors = validate({fullName, email, passwordErrors});
    setErrors(validationErrors);
    if (!isEmpty(validationErrors)) {
      return;
    }

    const info = {
      fullname: fullName,
      email,
      password: newPassword,
      inviteCode: (invite && invite.inviteCode) || undefined,
    };

    createAccount(store, window.location, info);
  }

  return (
    <form className={css.form} method="POST" onSubmit={handleSubmit}>
      <Helmet title="Sign Up" />

      <span className={css.pageTitle}>Sign up</span>

      <div className={css.intro}>
        {!isEmpty(errors.form) ? (
          <ErrorBox
            errors={values(errors.form ?? {}).map((reason) => ({
              reason,
            }))}
          />
        ) : inviteCodeErrors.length > 0 ? (
          <ErrorBox errors={inviteCodeErrors.map((reason) => ({reason}))} />
        ) : (
          <span className={css.welcome}>
            Welcome to Sense! Enter the details below to sign up.
          </span>
        )}
      </div>

      <Input
        type="text"
        className={classify(authCss.inputContainer, 'zipy-block')}
        value={fullName}
        disabled={firstName && lastName}
        autoFocus={!(firstName && lastName)}
        onChange={(evt) => setFullName(evt.currentTarget.value)}
        placeholder="Full Name"
        // $FlowFixMe(marcos): this is correct, the values are not null and React.Nodes are valid here
        errors={errors.fullname && values(errors.fullname)}
      />

      <Input
        className={classify(authCss.inputContainer, 'zipy-block')}
        type="email"
        value={email}
        onChange={(evt) => setEmail(evt.currentTarget.value)}
        disabled={invite}
        placeholder="Email Address"
        // $FlowFixMe(marcos): this is correct, the values are not null and React.Nodes are valid here
        errors={errors.email && values(errors.email)}
      />

      <PasswordConfirmation
        firstInputLabel=""
        firstInputPlaceholder="Password (10 char. min)"
        secondInputLabel=""
        secondInputPlaceholder="Repeat Password"
        meterLabel=""
        errorsByField={errors.password}
        handleScoreChange={(zxcvbndata) =>
          setPasswordScore(zxcvbndata?.score ?? 0)
        }
        emails={[inviteEmail, email]}
        names={[firstName, lastName, ...(fullName?.split(' ') || [])]}
        onChange={({passwordErrors, newPassword}) => {
          setPasswordErrors(passwordErrors);
          setNewPassword(newPassword);
        }}
      />

      <button
        data-score={passwordScore}
        className={authCss.button}
        disabled={creating || (passwordScore ?? -1) < 3}
        style={color && {backgroundColor: color, borderColor: color}}
      >
        {creating ? 'Signing Up...' : 'Sign Up'}
      </button>
      <span className={authCss.endnote}>
        Or{' '}
        <Link className={authCss.link} to="/signin">
          Sign In
        </Link>
      </span>
    </form>
  );
};

const validate = ({fullName, email, passwordErrors}): ErrorsByField => {
  const errors = {};

  if (!fullName.trim()) {
    errors.fullname = {
      empty: 'Full name must be provided.',
    };
  }

  if (!email.trim()) {
    errors.email = {
      empty: 'Email address must be provided.',
    };
  }

  if (!isEmpty(passwordErrors)) {
    errors.password = passwordErrors;
  }

  return errors;
};

export default (flow(
  fluxify(),
  connect(),
)(Signup): React.ComponentType<SignupProps>);
