// @flow strict-local

// $FlowFixMe[untyped-type-import]
import type {ThunkAction, Dispatch} from 'src/reducers/index';
import type {TemplateSummaryType} from 'src/components/workflow/templates/types.js';

import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api';


export const RECEIVE_ALL = 'playbook/receiveAll';
export const RECEIVE = 'playbook/receive';

export type ReceivePlaybooksAction = {|
  type: 'playbook/receiveAll',
  payload: TemplateSummaryType[],
|};

export type ReceivePlaybookAction = {|
  type: 'playbook/receive',
  payload: TemplateSummaryType,
|};

export type PlaybookAction = ReceivePlaybooksAction | ReceivePlaybookAction;

export const receivePlaybooks = (
  payload: TemplateSummaryType[],
): ReceivePlaybooksAction => ({
  type: RECEIVE_ALL,
  payload,
});

export const receivePlaybook = (
  payload: TemplateSummaryType,
): ReceivePlaybookAction => ({
  type: RECEIVE,
  payload,
});

export const getAllPlaybooks: () => ThunkAction<PlaybookAction> = flow(
  key(() => 'playbook/getAll'),
  cached(receivePlaybooks),
  fetching(),
)(() => reduxApi.get('journey-playbook'));

export const getPlaybook =
  (playbookId: string): ThunkAction<PlaybookAction> =>
  async (dispatch: Dispatch): Promise<PlaybookAction> => {
    const playbook = await dispatch(
      reduxApi.get(`journey-playbook/${playbookId}`),
    );

    return dispatch(receivePlaybook(playbook));
  };
