// @flow
import Store from './base';


export default class MemberResponseRateStore extends Store {
  constructor() {
    super('member-response-rates');
    this.state = {};
  }

  get(key: string): Object {
    return this.state[key];
  }
}
