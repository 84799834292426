// @flow strict-local

import type {Dispatch, GetState, ThunkAction} from 'src/reducers';
import type {AllowedPhoneNumberResponse} from 'src/types/chat/allowed-phone-numbers';

import * as api from 'src/utils/api-no-store';
import {captureApiError} from 'src/utils/redux-api-v2';


export type AllowedNumberMap = {
  [phoneNumber: string]: AllowedPhoneNumberResponse,
};

export type ReceiveAction = {
  type: 'chat/allowed-phone-numbers/receive',
  payload: AllowedNumberMap,
};

export const RECEIVE = 'chat/allowed-phone-numbers/receive';

export const receive = (payload: AllowedNumberMap): ReceiveAction => ({
  type: RECEIVE,
  payload,
});

export const requestAllowedNumbers = (
  phoneNumbers: string[],
): ThunkAction<> => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const response: AllowedNumberMap = await api.post(
      'messages_v2/allowed-numbers',
      {phone_numbers_to_check: phoneNumbers},
    );
    dispatch(receive(response));
  } catch (error) {
    captureApiError(error);
  }
};
