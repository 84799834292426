// @flow strict-local

import * as React from 'react';
import {useSelector} from 'react-redux';

import {selectCurrentPhoneCountry} from 'src/selectors/phone-number-sets';
import {formatPhone} from 'src/utils/phone';


export default function FormattedPhone({
  phone,
  defaultValue,
}: {
  phone: string,
  defaultValue?: string,
}): React.Node {
  const currentPhoneCountry = useSelector(selectCurrentPhoneCountry);
  return <>{formatPhone(phone, currentPhoneCountry) || defaultValue}</>;
}
