// @noflow

import * as React from 'react';
import throttleAnimation from 'src/utils/throttle-animation';


export type Dimensions = {
  width: number,
  height: number,
  innerWidth: number,
  innerHeight: number,
  top: number,
  bottom: number,
  left: number,
  right: number,
};

export type Margins = {
  top: number,
  bottom: number,
  left: number,
  right: number,
};

export type NewMargins = {
  [key: $Keys<Margins>]: number,
};

type Props = {
  dimensions?: Dimensions,
  width?: number,
  height?: number,
  innerWidth?: number,
  innerHeight?: number,
  margins: Margins,
};

type State = {
  dimensions: Dimensions,
  margins: Margins,
  chart?: Element,
};

function withChartDimensions(WrappedComponent: React.ComponentType<any>) {
  return class extends React.Component<Props, State> {
    static defaultProps = {
      margins: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    };

    handleChartRef: (ref: ?Element) => void;
    handleChartDimensions: () => void;
    updateMargins: (newMargins: Margins) => void;

    constructor(props: Props) {
      super(props);
      this.handleChartRef = this._handleChartRef.bind(this);
      this.handleChartDimensions = throttleAnimation(
        this._handleChartDimensions.bind(this),
      );
      this.updateMargins = this._updateMargins.bind(this);
      const {width, height} = props;
      this.state = {
        dimensions:
          props.dimensions || getFullDimensions(width, height, props.margins),
        margins: props.margins,
        chart: null,
      };
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleChartDimensions);
    }

    componentDidUpdate(prevProps) {
      if (this.props.data?.length !== prevProps.data?.length) {
        this.handleChartDimensions();
      }
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleChartDimensions);
    }

    _handleChartRef(ref: ?Element) {
      if (!ref || this.props.dimensions) {
        return;
      }
      this.setState(
        {
          chart: ref,
        },
        () => {
          this.handleChartDimensions();
        },
      );
    }

    _handleChartDimensions() {
      if (!this.state.chart) {
        return;
      }
      const rect = this.state.chart.getBoundingClientRect();
      // NOTE(elliot): Add default width and height values.
      const {margins} = this.state;
      const {innerWidth, innerHeight} = this.props;
      const width = innerWidth
        ? innerWidth + margins.left + margins.right
        : this.props.width || rect.width;
      const height = innerHeight
        ? innerHeight + margins.top + margins.bottom
        : this.props.height || rect.height;
      this.setState({
        dimensions: getFullDimensions(width, height, this.state.margins),
      });
    }

    _updateMargins(newMargins: Margins) {
      this.setState(({dimensions: {width, height}, margins}: State) => {
        const updatedMargins = {...margins, ...newMargins};
        return {
          margins: updatedMargins,
          dimensions: getFullDimensions(width, height, updatedMargins),
        };
      });
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onChartRef={this.handleChartRef}
          dimensions={this.state.dimensions}
          margins={this.state.margins}
          updateMargins={this.updateMargins}
        />
      );
    }
  };
}

export default withChartDimensions;

const getFullDimensions = (
  width: number = 0,
  height: number = 0,
  margins: Margins,
): Dimensions => ({
  width,
  height,
  innerWidth: width - margins.left - margins.right,
  innerHeight: height - margins.top - margins.bottom,
  top: margins.top,
  bottom: height - margins.bottom,
  left: margins.left,
  right: width - margins.right,
});
