// @flow

import * as React from 'react';
import {
  useHistory,
  useParams,
  type Params,
  type RouterHistory,
  type RerouterLocation,
} from 'src/rerouter';
import hoistNonReactStatics from 'hoist-non-react-statics';

import nameHoc from './nameHoc';

/**
 * DO NOT USE THIS.
 * This is a legacy HOC for backwards compatibility.
 */
export function withRouter<C>(
  WrappedComponent: React.ComponentType<C>,
): React.ComponentType<
  $Diff<
    C,
    {
      router: RouterHistory,
      location: RerouterLocation,
      params: Params,
    },
  >,
> {
  return nameHoc(
    hoistNonReactStatics((props) => {
      const history = useHistory();
      const params = useParams();
      return (
        <WrappedComponent
          {...props}
          router={history}
          location={history.location}
          params={params}
        />
      );
    }, WrappedComponent),
    WrappedComponent,
    'withRouter',
  );
}

export function withHistory<C>(
  WrappedComponent: React.ComponentType<C>,
): React.ComponentType<
  $Diff<
    C,
    {
      router: RouterHistory,
      history: RouterHistory,
      location: RerouterLocation,
    },
  >,
> {
  return nameHoc(
    hoistNonReactStatics((props) => {
      const history = useHistory();
      return (
        <WrappedComponent
          {...props}
          router={history}
          history={history}
          location={history.location}
        />
      );
    }, WrappedComponent),
    WrappedComponent,
    'withHistory',
  );
}

/*
export function useRouter() {
  const history = useHistory();
  const params = useParams();
  const {location} = history;

  const router = React.useMemo(() => ({
    push: (...args) => history.push(...args),
    replace: (...args) => history.replace(...args),
    goBack: () => history.back(),
  }), [history]);

  return {
    ...router,
    router,
    history,
    params,
    location,
  };
}
*/
