// @flow strict

import * as React from 'react';
import {Button} from 'src/designSystem2021Components/buttons.jsx';
import {Checkbox} from 'src/designSystem2021Components/checkbox.jsx';
import {Clickable} from 'src/designSystem2021Components/text-v2.jsx';

import css from 'src/components/agency-onboard/footer/manual-footer.css';


export type footerProps = {
  isCompleted: boolean,
  onDone: () => mixed,
  onCancel: () => mixed,
  disabled: boolean,
};

export const ManualFooter = (props: footerProps): React.Node => {
  const {isCompleted, onDone, onCancel, disabled} = props;

  const [actionCompleted, setActionCompleted] = React.useState(isCompleted);

  const handleSubmit = () => {
    onDone();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <div className={css.manualFooter}>
      <div className={css.completeActionRow}>
        <Checkbox
          checked={actionCompleted ? 'true' : 'false'}
          className={css.completeActionCheck}
          onChange={(_) => setActionCompleted(!actionCompleted)}
          disabled={disabled}
        />
        <Clickable className={css.completeActionText}>
          I have completed this step
        </Clickable>
      </div>
      <div className={css.footerBtns}>
        <Button
          type="tertiary"
          className={css.cancelBtn}
          onClick={handleCancel}
        >
          <Clickable> Cancel </Clickable>
        </Button>
        <Button
          type="primary"
          className={css.doneBtn}
          disabled={!actionCompleted}
          onClick={handleSubmit}
        >
          Done
        </Button>
      </div>
    </div>
  );
};
