// @flow
import type {EmailDomain, EmailDNSAction} from 'src/action-creators/email-dns';

import {keyBy} from 'lodash';

import {RECEIVE, VALIDATE, CREATE, DELETE} from 'src/action-creators/email-dns';


export type EmailDomainState = {domains: {[domain: string]: EmailDomain}};

export default (
  state: EmailDomainState = {domains: Object.freeze({})},
  action: EmailDNSAction,
): EmailDomainState => {
  switch (action.type) {
    case RECEIVE: {
      return {domains: keyBy(action.payload, 'domain')};
    }

    case VALIDATE: {
      const {domain, results} = action.payload;
      const domains = state.domains;
      const dns = domains[domain]?.dns;
      if (!dns) {
        return state;
      }
      const updatedDns = Object.fromEntries(
        Object.entries(dns).map(([recordName, recordInfo]) => [
          recordName,
          // $FlowIssue Flow doesn't know that keys in results must be of type recordName
          {...recordInfo, valid: results[recordName].valid},
        ]),
      );
      return {
        ...state,
        domains: {
          ...state.domains,
          [domain]: {
            ...state.domains[domain],
            dns: updatedDns,
          },
        },
      };
    }

    case CREATE: {
      const domain = action.payload;
      return {
        ...state,
        domains: {
          ...state.domains,
          [domain.domain]: domain,
        },
      };
    }

    case DELETE: {
      const domain = action.payload;
      const domains = Object.assign({}, state.domains);
      delete domains[domain];
      return {domains};
    }
  }

  return state;
};
