// @noflow

import type {Account} from 'src/types/account';
import type {Role} from 'src/action-creators/roles';
import type {Group} from 'src/action-creators/groups';
import type {PhoneNumberAssignmentsMapType} from 'src/selectors/phone-numbers';
import typeof IndexStore from 'src/stores/index';
import type {State as ReduxState} from 'src/reducers';

import flow from 'lodash/flow';
import get from 'lodash/get';
import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {provideHooks} from 'src/flux/provideHooks.jsx';

import {inflight} from 'src/utils/redux';
import {hasPrivilege} from 'src/utils/accounts';
import {classify} from 'src/utils';

import {getAccounts, updateAgent} from 'src/action-creators/accounts';
import {EDIT_PERMISSIONS, getRoles} from 'src/action-creators/roles';
import {updateMe} from 'src/actions/index';
import {createAccountInvite} from 'src/action-creators/account-invites';
import {getGroups} from 'src/action-creators/groups';
import {canUseChatPreRelease} from 'src/selectors/chat';
import {selectReleaseFlags} from 'src/selectors/product-flags';

import Button from 'src/components/lib/new-button';
import Loading from 'src/components/lib/loading/loading.jsx';
import {fluxify} from 'src/flux/component.jsx';
import {Select} from 'src/components/lib/token-list-input';
import ChatEdit from 'src/components/settings/accounts/chat-edit.jsx';
import {getPhoneNumberAssignments} from 'src/selectors/phone-numbers';
import {PhoneNumberListItem} from 'src/components/settings/accounts/provisioned-phone-numbers.jsx';
import FormattedPhone from 'src/components/lib/formatted-phone.jsx';

import {withRouter} from 'src/flux/withRouter.jsx';

import AudienceIcon from 'src/images/audience-icon.svg?noAttrs';

import css from './account-edit.css';


const dependencies = {
  deferred: ({dispatch, getState}) => {
    const promises = [dispatch(getAccounts())];
    if (hasPrivilege(getState(), EDIT_PERMISSIONS)) {
      promises.push(dispatch(getRoles()), dispatch(getGroups()));
    }
    return Promise.all(promises);
  },
};

const mapStateToProps = (state, ownProps) => {
  const params = ownProps.params;
  const accountId = params.accountId || ownProps.accountId;
  const isSaving =
    params &&
    state.accounts.data[accountId] &&
    inflight(state, updateAgent(state.accounts.data[accountId]));
  return {
    rolesById: state.roles.data,
    accountsById: state.accounts.data,
    groupsById: state.groups.data,
    canEditPermissions: hasPrivilege(state, EDIT_PERMISSIONS),
    me: state.accounts.data[state.accounts.authedUserId],
    showMessagesApp: canUseChatPreRelease(state),
    numberAssignmentsMap: getPhoneNumberAssignments(state),
    isSaving,
    accountId,
    isNew: !accountId,
    isMe: state.accounts.authedUserId === accountId,
    userAccountsUpdatesReleaseFlag: selectReleaseFlags(state)
      .userAccountsUpdates,
  };
};

type Props = {
  isNew: boolean,
  accountsById: {[key: string]: Account},
  rolesById: {[key: string]: Role},
  groupsById: {[key: string]: Group},
  canEditPermissions: boolean,
  me: Account,
  showMessagesApp: boolean,
  accountId: string,
  numberAssignmentsMap: PhoneNumberAssignmentsMapType,
  removeModal?: () => void,
  userAccountsUpdatesReleaseFlag: boolean,

  params: {
    accountId: string,
  },
  router: Function,
  isMe: boolean,
  store: IndexStore,
  dispatch: Function,
  isSaving: boolean,
};

class AccountEditor extends Component<Props> {
  form: HTMLInputElement;

  state = {
    apiErrorMessage: null,
    isRequesting: false,
  };

  render() {
    const {
      isNew,
      rolesById,
      groupsById,
      accountsById,
      canEditPermissions,
      showMessagesApp,
      accountId,
      isSaving,
      numberAssignmentsMap,
      removeModal,
      userAccountsUpdatesReleaseFlag,
    } = this.props;
    const {apiErrorMessage, isRequesting} = this.state;
    const account = accountsById[accountId];

    if (isNew) {
      return (
        <UpdateUserForm
          isNew={true}
          onSubmit={this.handleSubmit}
          roles={rolesById}
          groups={groupsById}
          canEditPermissions={canEditPermissions}
          errorMessage={apiErrorMessage}
          isSaving={isRequesting || isSaving}
          isModal={Boolean(removeModal)}
          removeModal={removeModal}
          numberAssignmentsMap={numberAssignmentsMap}
          userAccountsUpdatesReleaseFlag={userAccountsUpdatesReleaseFlag}
        />
      );
    } else {
      if (!account) {
        return <Loading />;
      } else {
        return (
          <UpdateUserForm
            onSubmit={this.handleSubmit}
            account={account}
            roles={rolesById}
            groups={groupsById}
            canEditPermissions={canEditPermissions}
            showMessagesApp={showMessagesApp}
            isSaving={isSaving}
            isModal={Boolean(removeModal)}
            removeModal={removeModal}
            numberAssignmentsMap={numberAssignmentsMap}
            userAccountsUpdatesReleaseFlag={userAccountsUpdatesReleaseFlag}
          />
        );
      }
    }
  }

  handleSubmit = (form: HTMLFormElement) => {
    this.setState({isRequesting: true});
    const {
      isNew,
      isMe,
      me,
      store,
      router,
      accountsById,
      accountId,
      removeModal,
    } = this.props;
    const account = accountsById[accountId];
    const agentData = {
      // for users that can't see permissions but can edit settings,
      // create invite with their own role/group ids
      securityRoleId: get(form, 'role.value', me.securityRole.id),
      securityGroupId: get(form, 'group.value', me.securityGroup.id),
    };
    const email = get(form, 'email.value', '').trim();
    if (isNew) {
      const first_name = get(form, 'first_name.value', '').trim();
      const last_name = get(form, 'last_name.value', '').trim();
      const provisioned_phone_id = get(form, 'phone.value', '').trim();
      this.props
        .dispatch(
          createAccountInvite({
            ...agentData,
            email,
            ...(first_name && {first_name}),
            ...(last_name && {last_name}),
            ...(provisioned_phone_id && {provisioned_phone_id}),
          }),
        )
        .then(() => {
          if (removeModal) {
            removeModal();
          } else {
            router.push('/settings/accounts');
          }
        })
        .catch((err) => {
          this.setState({
            isRequesting: false,
          });
          if (err.status === 400) {
            this.setState({
              apiErrorMessage: err.responseBody.errors,
            });
          } else {
            throw err;
          }
        });
    } else {
      const name = get(form, 'name.value', '').trim();

      let updatePromise;
      if (isMe) {
        // FIXME (marcos): updateMe can only update name
        updatePromise = updateMe(store, {name});
      } else {
        updatePromise = Promise.resolve();
      }

      updatePromise
        .then(() =>
          this.props.dispatch(
            updateAgent(account, {...agentData, name}, email),
          ),
        )
        .then(() => {
          this.setState({isRequesting: false}, () => {
            if (removeModal) {
              removeModal();
            } else {
              router.push('/settings/accounts');
            }
          });
        });
    }
  };
}

export default flow(
  connect(mapStateToProps),
  withRouter,
  provideHooks(dependencies),
)(AccountEditor);

const SuggestionComponent = ({option, isSelected: _, ...props}) => (
  <div {...props}>
    <PhoneNumberListItem
      phoneNumber={option.extras.phoneNumber}
      numberAssignments={option.extras.numberAssignments}
    />
  </div>
);

const ValueComponent = ({value}) =>
  value.extras ? (
    <PhoneNumberListItem
      phoneNumber={value.extras.phoneNumber}
      numberAssignments={value.extras.numberAssignments}
    />
  ) : (
    ''
  );

const getSelectOption = (value) => {
  if (value) {
    return {value: value.id, label: value.name};
  }
  return null;
};

type UpdateUserFormProps = {
  account?: Account,
  onSubmit: (form: HTMLFormElement) => void,
  roles: {[key: string]: Role},
  groups: {[key: string]: Group},
  isNew?: boolean,
  canEditPermissions: boolean,
  showMessagesApp?: boolean,
  isSaving?: boolean,
  errorMessage?: any,
  isModal?: boolean,
  removeModal?: () => void,
  numberAssignmentsMap: PhoneNumberAssignmentsMapType,
  userAccountsUpdatesReleaseFlag: boolean,
};
export class UpdateUserForm extends React.Component<
  UpdateUserFormProps,
  {
    role: ?string,
    group: ?string,
  },
> {
  form: HTMLFormElement;

  state = {
    role: getSelectOption(this.props.account?.securityRole),
    group: getSelectOption(this.props.account?.securityGroup),
    phone: null,
    showFieldErrors: false,
  };

  handleSave = (form) => {
    if (!this.state.role || !this.state.group) {
      this.setState({showFieldErrors: true});
    } else {
      this.setState({showFieldErrors: false});
      this.props.onSubmit(form);
    }
  };

  render() {
    const {
      roles,
      groups,
      isNew,
      account,
      canEditPermissions,
      showMessagesApp,
      isSaving,
      errorMessage,
      isModal,
      removeModal,
      numberAssignmentsMap,
      userAccountsUpdatesReleaseFlag,
    } = this.props;

    const {showFieldErrors, role, group, phone} = this.state;

    // const {name, email} = this.props.account;
    const heading = account ? get(account, 'name') : 'Create User';

    const roleSelectOptions = Object.keys(roles)
      .map((id) => roles[id])
      .map(({id, name}) => ({value: id, label: name}));
    const groupSelectOptions = Object.keys(groups)
      .map((id) => groups[id])
      .map(({id, name}) => ({value: id, label: name}))
      .sort((a, b) => a.label?.localeCompare(b.label));
    const numbersSelectOptions = Object.values(numberAssignmentsMap || {}).map(
      ({provisioned_phone_id, phone_number}) => ({
        value: provisioned_phone_id,
        label: String(phone_number),
        extras: {
          phoneNumber: String(phone_number),
          numberAssignments: numberAssignmentsMap[provisioned_phone_id],
        },
      }),
    );

    return (
      <div
        className={classify(css.formContainer, {
          [css.modalBackground]: isModal,
        })}
      >
        <form
          className={css.main}
          onSubmit={(evt) => {
            evt.preventDefault();
            this.handleSave(evt.target);
          }}
          ref={(el) => (this.form = el)}
        >
          <Helmet title={account ? `Edit ${heading}` : heading} />
          <h2 className={css.subtitle}>{heading}</h2>

          {!isNew && (
            <label className={css.formRow}>
              <div className={css.label}>Full Name</div>
              <input
                type="text"
                className={css.input}
                name="name"
                defaultValue={get(account, 'name')}
                autoFocus
              />
            </label>
          )}

          {isNew && (
            <div className={css.formRow}>
              <label className={css.formInput}>
                <div className={css.label}>First Name</div>
                <input
                  type="text"
                  className={css.input}
                  name="first_name"
                  autoFocus
                />
              </label>
              <label className={css.formInput}>
                <div className={css.label}>Last Name</div>
                <input type="text" className={css.input} name="last_name" />
              </label>
            </div>
          )}

          <div className={css.formRow}>
            <label className={css.formInput}>
              <div className={css.label}>Email</div>
              <input
                type="email"
                disabled={userAccountsUpdatesReleaseFlag ? false : !isNew}
                className={css.input}
                name="email"
                defaultValue={get(account, 'email')}
                required
              />
            </label>
          </div>

          {canEditPermissions && (
            <div className={css.rolesAndGroups}>
              <div className={css.formRow}>
                <div className={css.formInput}>
                  <div className={css.label}>Role</div>
                  <div className={css.field}>
                    <Select
                      className={
                        showFieldErrors && !role
                          ? css.fieldSelectorError
                          : css.fieldSelector
                      }
                      options={roleSelectOptions}
                      name="role"
                      clearable={false}
                      value={role}
                      onChange={(value) => this.setState({role: value})}
                    />
                    {showFieldErrors && !role && (
                      <div className={css.fieldError}>Role cannot be blank</div>
                    )}
                  </div>
                </div>
              </div>

              <div className={css.formRow}>
                <div className={css.formInput}>
                  <div className={css.label}>Group</div>
                  <div className={css.field}>
                    <Select
                      className={
                        showFieldErrors && !group
                          ? css.fieldSelectorError
                          : css.fieldSelector
                      }
                      options={groupSelectOptions}
                      name="group"
                      clearable={false}
                      value={group}
                      onChange={(value) => this.setState({group: value})}
                    />
                    {showFieldErrors && !group && (
                      <div className={css.fieldError}>
                        Group cannot be blank
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {isNew && (
            <div className={css.formRow}>
              <div className={css.formInput}>
                <div className={css.label}>Phone (optional)</div>
                <div className={css.field}>
                  <Select
                    className={css.fieldSelector}
                    options={numbersSelectOptions}
                    components={{
                      Suggestion: SuggestionComponent,
                      ValueComponent,
                    }}
                    searchable
                    name="phone"
                    clearable={true}
                    placeholder=""
                    value={phone}
                    onChange={(value) => {
                      this.setState({phone: value});
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {errorMessage && <div className={css.redFont}>{errorMessage}</div>}
          <div className={css.buttonContainer}>
            {isModal && (
              <Button type="default" disabled={isSaving} onClick={removeModal}>
                {'Cancel'}
              </Button>
            )}
            <Button
              disabled={isSaving}
              type="primary"
              onClick={() => {
                this.handleSave(this.form);
              }}
            >
              {isNew
                ? isSaving
                  ? 'Creating...'
                  : 'Create User'
                : isSaving
                ? 'Saving...'
                : 'Save'}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
