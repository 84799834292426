// @flow strict
import type {GenericError, AclResponse} from 'src/types/referral-v2';
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api.js';
import {DEFAULT_ERROR} from 'src/action-creators/referral-v2/referral-jobs.js';


export const REFERRAL_USERS_PROGRAMS_ACCESS = 'referral/user-program-access';
export const REFERRAL_USERS_PROGRAMS_ACCESS_LOADING =
  'referral/user-program-access-loading';
export const REFERRAL_USERS_PROGRAMS_ACCESS_ERROR =
  'referral/user-program-access-error';

type ReceiveReferralUsersProgramAccessAction = {
  type: typeof REFERRAL_USERS_PROGRAMS_ACCESS,
  payload: AclResponse,
};
type ReceiveReferralUsersProgramAccessLoadingAction = {
  type: typeof REFERRAL_USERS_PROGRAMS_ACCESS_LOADING,
};
type ReceiveReferralUsersProgramAccessErrorAction = {
  type: typeof REFERRAL_USERS_PROGRAMS_ACCESS_ERROR,
  payload: ?GenericError,
};

export type ReferralAclActions =
  | ReceiveReferralUsersProgramAccessAction
  | ReceiveReferralUsersProgramAccessLoadingAction
  | ReceiveReferralUsersProgramAccessErrorAction;

const getReferralAcl = (
  payload: AclResponse,
): ReceiveReferralUsersProgramAccessAction => ({
  type: REFERRAL_USERS_PROGRAMS_ACCESS,
  payload,
});

const getReferralAclLoading =
  (): ReceiveReferralUsersProgramAccessLoadingAction => ({
    type: REFERRAL_USERS_PROGRAMS_ACCESS_LOADING,
  });

const getReferralAclError = (
  payload: ?GenericError,
): ReceiveReferralUsersProgramAccessErrorAction => ({
  type: REFERRAL_USERS_PROGRAMS_ACCESS_ERROR,
  payload,
});

export const getReferralAclData: () => ThunkAction<void> = flow()(
  () => (dispatch: Dispatch) => {
    dispatch(getReferralAclLoading());
    return dispatch(reduxApi.get('referral/users/programs/access')).then(
      (response: AclResponse) => {
        // $FlowIssue object values
        dispatch(getReferralAcl(response));
      },
      (error) => {
        dispatch(getReferralAclError(error?.response?.errors || DEFAULT_ERROR));
      },
    );
  },
);
