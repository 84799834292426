// @flow

import * as React from 'react';
import range from 'lodash/range';
import {useDispatch} from 'react-redux';

import {
  WritebackContext,
  UPDATE_QUESTION,
} from 'src/components/writeback/writeback-wrapper.jsx';
import Input from 'src/components/lib/error-input';
import Select from 'src/components/lib/select/select.jsx';

import moduleCss from 'src/components/workflow/event/content/module/editors/module.css';


export default ({
  conditionalAlerts,
  error,
}: {
  conditionalAlerts?: boolean,
  error: {
    question?: {errors: string[]},
    minText?: {errors: string[]},
    maxText?: {errors: string[]},
  },
}): React.Element<'div'> => {
  const {state, dispatch} = React.useContext(WritebackContext);
  const currentQuestionType = state.question?.selectedType;
  const currentQuestion = state.question?.[currentQuestionType];
  const reduxStoreDispatcher = useDispatch();

  return (
    <div className={moduleCss.ratingScaleContainer}>
      <div className={moduleCss.ratingScaleModule_bottomContent}>
        <label
          className={moduleCss.generalModule_questionLabel}
          data-qa-id="rating-scale-left-label"
        >
          <div className={moduleCss.labelWithInlineSelector}>{
            // $FlowFixMe[prop-missing] types/surveys
            // $FlowFixMe[incompatible-type]
            `Left Label (${currentQuestion.minRating})`
          }</div>
          <Input
            className={moduleCss.generalModule_input}
            type="text"
            placeholder="e.g. Not Good"
            // $FlowFixMe[prop-missing] types/surveys
            value={currentQuestion.minText}
            onChange={(event) => {
              // $FlowFixMe[incompatible-call] types/surveys
              dispatch({
                type: UPDATE_QUESTION,
                payload: {
                  questionType: currentQuestionType,
                  attrs: {
                    minText: event.target.value,
                  },
                },
              });
            }}
            errors={error?.minText?.errors}
          />
        </label>
        <div className={moduleCss.ratingScaleModule_separator} />
        <label
          className={moduleCss.generalModule_questionLabel}
          data-qa-id="rating-scale-right-label"
        >
          <div className={moduleCss.labelWithInlineSelector}>{
            // $FlowFixMe[prop-missing] types/surveys
            // $FlowFixMe[incompatible-type]
            `Right Label (${currentQuestion.maxRating})`
          }</div>
          <Input
            className={moduleCss.generalModule_input}
            type="text"
            placeholder="e.g. Great"
            // $FlowFixMe[prop-missing] types/surveys
            value={currentQuestion.maxText}
            onChange={(event) => {
              // $FlowFixMe[incompatible-call] types/surveys
              dispatch({
                type: UPDATE_QUESTION,
                payload: {
                  questionType: currentQuestionType,
                  attrs: {
                    maxText: event.target.value,
                  },
                },
              });
            }}
            errors={error?.maxText?.errors}
          />
        </label>
      </div>
      <div className={moduleCss.generalModule_row}>
        {conditionalAlerts && (
          <div className={moduleCss.ratingScaleModule_alertRating}>
            <div className={moduleCss.ratingScaleModule_alertRatingTitle}>
              Send alert if answer is:{' '}
            </div>
            <div
              className={
                moduleCss.ratingScaleModule_alertRatingSelect_container
              }
            >
              <Select
                className={moduleCss.ratingScaleModule_alertRatingSelect}
                // $FlowFixMe[incompatible-type] types/surveys
                // $FlowFixMe[prop-missing] types/surveys
                value={currentQuestion.alertRating}
                // $FlowFixMe[incompatible-call]
                options={range(
                  // $FlowFixMe[prop-missing] types/surveys
                  currentQuestion.minRating,
                  // $FlowFixMe[prop-missing] types/surveys
                  // $FlowFixMe[unsafe-addition]
                  currentQuestion.maxRating + 1,
                ).map((value) => ({
                  label: `<= ${value}`,
                  value,
                }))}
                onChange={(evt) => {
                  // $FlowFixMe[incompatible-call] types/surveys
                  dispatch({
                    type: UPDATE_QUESTION,
                    payload: {
                      questionType: currentQuestionType,
                      attrs: {
                        alertRating: evt?.value,
                      },
                    },
                  });
                }}
                clearable={false}
                searchable={false}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
