// @noflow

import type {Workflow, ApiWorkflow, Schedule} from 'src/api-parsers/index';

import find from 'lodash/find';
import omit from 'lodash/omit';

import Store from './base';


export default class WorkflowStore extends Store {
  state: {
    creating: boolean,
    fetchingAll: ?Promise<ApiWorkflow[]>,
    workflows: {
      [workflowId: string]: Workflow,
    },
    error: ?Error,
  };

  constructor() {
    super('workflow');

    this.state = {
      creating: false,
      fetchingAll: null,
      workflows: {},
      error: null,
    };
  }

  /**
   * mutators
   */
  startCreating() {
    this.setState({
      creating: true,
    });
  }

  receiveCreated(workflow: Workflow) {
    this.updateState({
      creating: {
        $set: false,
      },
      workflows: {
        [workflow.id]: {
          $set: workflow,
        },
      },
    });
  }

  startFetchingAll(promise: Promise<ApiWorkflow[]>) {
    this.setState({
      fetchingAll: promise,
    });
  }

  // NOTE (kyle): this method assumes we're getting the full list
  receiveAll(workflows: Workflow[]) {
    const newWorkflows = {...this.state.workflows};

    // NOTE (kyle): we merge workflows because the list api endpoint returns
    // a reduced data set.
    workflows.forEach(workflow => {
      const {id} = workflow;
      let newWorkflow;
      if (newWorkflows[id]) {
        newWorkflow = {...newWorkflows[id], ...workflow};
      } else {
        newWorkflow = workflow;
      }
      newWorkflows[id] = newWorkflow;
    });

    this.setState({
      fetchingAll: null,
      workflows: newWorkflows,
    });
  }

  update(workflow: Workflow) {
    this.updateState({
      workflows: {
        [workflow.id]: {
          $set: workflow,
        },
      },
    });
  }

  delete(workflow: Workflow) {
    this.setState({
      workflows: omit(this.state.workflows, workflow.id),
    });
  }

  updateWorkflow(workflowId: string, assignment: $Shape<Workflow>) {
    this.updateState({
      workflows: {
        [workflowId]: {
          $assign: assignment,
        },
      },
    });
  }

  setError(error: Error) {
    this.updateState({
      error: {
        $set: error,
      },
    });
  }

  getError(): ?Error {
    return this.state.error;
  }

  processedError() {
    this.updateState({
      error: {
        $set: undefined,
      },
    });
  }

  /**
   * presenters
   */
  get(id: string): ?Workflow {
    return this.state.workflows[id];
  }

  all(): {[key: string]: Workflow} {
    return this.state.workflows;
  }

  getEventsSchedule(workflowId: string, eventId: string): ?Schedule {
    const workflow = this.state.workflows[workflowId];
    if (workflow != null) {
      const eventsSchedule = find(
        workflow.eventsSchedule,
        schedule => schedule.id === eventId,
      );

      if (eventsSchedule !== undefined) {
        // if not set, this would be undefined, do callers care b/w null/undef
        return eventsSchedule.schedule || null;
      }
    }
  }

  isBranchedEvent(workflowId: string, eventId: string): boolean {
    const workflow = this.state.workflows[workflowId];
    /* If the event id does not exist in the event schedule, then its a branched event. Doing this instead of using
    workflow.branchedEvents because sometimes the branchedEvents is gettings cleared by getWorkflows call which the
    navigation makes. getWorkflows call does not return branchedEvents */
    const eventNotInWorkflowEventSchedule = !workflow.eventsSchedule.find(
      event => event.id === eventId,
    );
    return eventNotInWorkflowEventSchedule;
  }
}
