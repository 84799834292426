// @flow strict

//$FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';

//$FlowFixMe[nonstrict-import]
import {INBOX_TYPE} from 'src/types/messages';


export const getSelectedInboxType = (state: State): string =>
  state.chat.multiNumberInbox.inboxType;

export const isQueueInbox = (state: State): boolean =>
  state.chat.multiNumberInbox.inboxType === INBOX_TYPE.QUEUE;

export const isPhoneInbox = (state: State): boolean =>
  state.chat.multiNumberInbox.inboxType === INBOX_TYPE.PHONE;
