// @noflow

import {
  RECEIVE_GLOBAL_VARIABLES,
  ReceiveGlobalVariablesAction,
} from 'src/action-creators/namespaced-global-variables';
import type {NamespacedGlobalVariable} from '../types/namespaced-global-variables';


export type NamespacedGlobalVariableState = NamespacedGlobalVariable[];

export default (
  state: NamespacedGlobalVariableState = [],
  action: ReceiveGlobalVariablesAction,
): State => {
  switch (action.type) {
    case RECEIVE_GLOBAL_VARIABLES: {
      const globalVariables = state.concat(action.payload);
      const result = [];
      // Dedupe by key (anchor_entity_type, fully_formed_name)
      const namespacedNames = new Set();
      globalVariables.forEach(namespaced => {
        if (!namespacedNames.has(`${namespaced.anchor_entity_type}//${namespaced.fully_formed_name}`)) {
          namespacedNames.add(`${namespaced.anchor_entity_type}//${namespaced.fully_formed_name}`);
          result.push(namespaced);
        }
      });
      return result;
    }
  }
  return state;
};
