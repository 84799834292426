// @flow

// $FlowFixMe[untyped-type-import]
import type {Template} from 'src/reducers/templates';
import type {Dispatch, GetState, ThunkAction} from 'src/reducers';

import {thunkify as flow} from 'src/utils/thunks';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api';
import {camel} from 'src/utils';
import {getTemplate} from 'src/selectors/templates';


export const RECEIVE = 'messagingTemplates/receive';
export const REMOVE = 'messagingTemplates/remove';

export type ReceiveAction = {
  type: 'messagingTemplates/receive',
  // $FlowFixMe[value-as-type] [v1.32.0]
  payload: Template[],
};

export type RemoveAction = {
  type: 'messagingTemplates/remove',
  payload: string,
};

export type TemplateAction = ReceiveAction | RemoveAction;

// $FlowFixMe[value-as-type] [v1.32.0]
export const receive = (payload: Template[]): ReceiveAction => ({
  type: RECEIVE,
  payload: camel(payload),
});

export const remove = (id: string): RemoveAction => ({
  type: REMOVE,
  payload: id,
});

export const getAllTemplates: () => ThunkAction<mixed> = flow(
  key(() => 'templates'),
  cached(receive),
  fetching(),
)(() => reduxApi.get('messages/templates'));

export const fetchTemplate: (templateId: string) => ThunkAction<mixed> = flow(
  key((id) => `templates/${id}`),
  cached((template) => receive([template])),
)((templateId: string) => reduxApi.get(`messages/templates/${templateId}`));

export const createTemplate = (
  name: string,
  body: string,
): ThunkAction<> => async (dispatch: Dispatch) => {
  const newTemplate = await dispatch(
    reduxApi.post('messages/templates', {name, body}),
  );
  dispatch(receive([newTemplate]));
};

export const updateTemplate = (
  id: string,
  {name, body}: {name?: string, body?: string},
): ThunkAction<> => async (dispatch: Dispatch, getState: *) => {
  // update ui immediately
  const template = getState().templates.templates.find(
    (tmpl) => tmpl.id === id,
  );
  const optimisticUpdate = {...template, name, body};
  dispatch(receive([optimisticUpdate]));

  const updatedTemplate = await dispatch(
    reduxApi.put(`messages/template/${id}`, {name, body}),
  );
  dispatch(receive([updatedTemplate]));
  return updatedTemplate;
};

export const deleteTemplate = (id: string): ThunkAction<> => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const template = getTemplate(getState(), id);
  // delete immediately in ui, but still wait for the api
  dispatch(remove(id));
  try {
    await dispatch(reduxApi.del(`messages/template/${id}`));
  } catch (error) {
    if (template) {
      dispatch(receive([template]));
    }
    throw error;
  }
};

export const copyTemplate = (
  id: string,
  name?: string,
): ThunkAction<> => async (dispatch: Dispatch) => {
  const copiedTemplate = await dispatch(
    reduxApi.post(`messages/template/${id}/copy`, {name}),
  );
  dispatch(receive([copiedTemplate]));
};

// sets as default (topmost) global template
export const useTemplate = (id: string): ThunkAction<mixed> => (
  dispatch: Dispatch,
) => dispatch(reduxApi.post(`messages/template/${id}/use`));
