// @flow strict

import React from 'react';
import css from './index.css';
import type {ToastMessage, variantTypes} from 'src/types/toast';
import classify from 'src/utils/classify';
import {useSelector, useDispatch} from 'react-redux';
import {getToasts} from 'src/selectors/toasts';
import {removeToast} from 'src/action-creators/toasts';
import CloseIcon from 'src/images/designSystems2021/close.svg';
import SuccessIcon from 'src/images/designSystems2021/success.svg';
import WarningIcon from 'src/images/designSystems2021/warning.svg';
import ErrorIcon from 'src/images/designSystems2021/error.svg';
import InfoIcon from 'src/images/designSystems2021/info.svg';
import {VARIANTS} from 'src/designSystem2021Components/toast';


const ToastVariantIcon = ({variantType}: {variantType?: variantTypes}) => {
  switch (variantType) {
    case VARIANTS.SUCCESS:
      return <SuccessIcon />;
    case VARIANTS.INFO:
      return <InfoIcon />;
    case VARIANTS.WARNING:
      return <WarningIcon />;
    case VARIANTS.ERROR:
      return <ErrorIcon />;
    default:
      return <></>;
  }
};

const ToastContainer = (): Array<React$Element<'div'>> => {
  const data: ToastMessage[] = useSelector(getToasts);
  const dispatch = useDispatch();

  const positionMaintainer = () => {
    const mapper = {};
    data &&
      data.map(({position, ...rest}: ToastMessage) => {
        if (position) {
          if (!mapper[position]) {
            mapper[position] = [];
          }
          mapper[position].push(rest);
        }
      });
    return mapper;
  };

  const markup = () => {
    const mapper = positionMaintainer();

    return (
      mapper &&
      Object.keys(mapper).map((position) => {
        const content = mapper[position].map(
          ({content, variant, id, width}: ToastMessage) => {
            let animationCssClass = 'toastItemAnimationTop';

            if (position.indexOf('bottom')) {
              animationCssClass = 'toastItemAnimationBottom';
            }

            return (
              <div
                key={id}
                className={classify(css.toastItem, css[animationCssClass])}
                style={width ? {maxWidth: width} : {}}
              >
                <div className={css.toastIconContainer}>
                  <ToastVariantIcon variantType={variant} />
                </div>
                <div className={css.contentContainer}>{content}</div>
                <div
                  className={css.closeIconContainer}
                  onClick={() => dispatch(removeToast(id))}
                >
                  <CloseIcon className={css.closeIcon} />
                </div>
              </div>
            );
          },
        );
        return (
          <div
            key={position}
            className={classify(css.toastContainer, css[position])}
          >
            {content}
          </div>
        );
      })
    );
  };

  return markup();
};

export default ToastContainer;
