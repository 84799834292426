// @noflow

import type {
  RoiDetail,
  RoiMetric,
  RoiPlacementActivity,
  RoiRedeploymentGrid,
  RoiSummary,
} from 'src/types/roi';
import type {RoiAction} from 'src/action-creators/roi';

import {
  RECEIVE_DETAIL,
  RECEIVE_SUMMARY,
  CHANGE_TIME_UNIT,
} from 'src/action-creators/roi';


export type RoiState = {
  summary: {
    [metric: RoiMetric]: RoiSummary,
    placement_activity?: RoiPlacementActivity,
    redeployment_grid?: RoiRedeploymentGrid,
  },
  detail: {
    [summaryId: string]: RoiDetail,
  },
  timeUnit: {
    [metric: RoiMetric]: 'day' | 'week' | 'month',
  },
};

const initialState = {
  summary: {},
  detail: {},
  timeUnit: {
    placement_starts_and_ends: 'month',
    touches: 'month',
    placement_durations: 'month',
    new_activations: 'month',
    redeployments: 'month',
    active_placements: 'month',
    assignment_length: 'month',
  },
};

export default (state: RoiState = initialState, action: RoiAction) => {
  switch (action.type) {
    case RECEIVE_SUMMARY: {
      return {
        ...state,
        summary: {
          ...state.summary,
          [action.meta.metric]: action.payload,
        },
      };
    }
    case RECEIVE_DETAIL: {
      return {
        ...state,
        detail: {
          ...state.detail,
          [action.meta.summaryId]: action.payload,
        },
      };
    }
    case CHANGE_TIME_UNIT: {
      return {
        ...state,
        timeUnit: {
          ...state.timeUnit,
          [action.meta.metric]: action.payload,
        },
      };
    }
    default:
      return state;
  }
};
