// @flow strict

import * as React from 'react';

import ChatSoundEffect from 'src/components/messaging/sound-effect.jsx';
import {
  Toast,
  ToastBody,
  ToastFooter,
  ToastTitle,
} from '@spaced-out/ui-design-system/lib/components/Toast';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {Icon} from '@spaced-out/ui-design-system/lib/components/Icon';

import bellMp3 from 'src/sounds/bell_ding_muted_lo.mp3';
import bellWav from 'src/sounds/bell_ding_muted_lo.wav';

import css from './lat-notification.css';


type LATNotificationProps = {
  toastTitle: string,
  toastBody: string,
  time: string,
  soundSetting: boolean,
  handleDismiss: () => void,
  handleOpenQueue: () => void,
};

const latNotification = ({
  toastTitle,
  toastBody,
  time,
  soundSetting,
  handleDismiss,
  handleOpenQueue,
}: LATNotificationProps): React.Node => {
  const customIcon = (
    <Icon name="bell" size="medium" color="danger" type="solid" />
  );
  return (
    <div className={css.wrapper}>
      <Toast
        initialFocus={0}
        semantic="danger"
        time={time}
        customIcon={customIcon}
        onClose={handleDismiss}
      >
        <ToastTitle>{toastTitle}</ToastTitle>
        <ToastBody>{toastBody}</ToastBody>
        <ToastFooter>
          <Button onClick={handleDismiss}>Dismiss</Button>
          <Button onClick={handleOpenQueue}>Open Queue</Button>
        </ToastFooter>
      </Toast>
      <ChatSoundEffect
        // we don't need unique playId here, it should play sound upon mount
        playId={soundSetting}
        soundSetting={soundSetting}
        playOnMount={true}
        media={[
          {
            src: bellMp3,
            type: 'audio/mpeg',
          },
          {
            src: bellWav,
            type: 'audio/wav',
          },
        ]}
      />
    </div>
  );
};

export default latNotification;
