//@flow strict

import * as React from 'react';

import classify from '../../utils/classify';
import type {
  IconSize,
  IconType,
} from '@spaced-out/ui-design-system/lib/components/Icon';
import type {MenuOption} from '@spaced-out/ui-design-system/lib/components/Menu/Menu';
import {Icon} from '@spaced-out/ui-design-system/lib/components/Icon';
import {
  BodyMedium,
  TEXT_COLORS,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {
  SideMenuLink,
  MENU_NAME_LIST,
} from '@spaced-out/ui-design-system/lib/components/SideMenuLink';
import {UnstyledButton} from '@spaced-out/ui-design-system/lib/components/Button';
import {CustomDropdown} from 'src/components/app-shell/custom-dropdown.jsx';

import {HoverDropdown} from 'src/components/app-shell/side-menu-dropdown.jsx';
import {size100} from '@spaced-out/ui-design-system/lib/styles/variables/_size.js';
import css from './side-menu-group.css';


type ClassNames = $ReadOnly<{
  wrapper?: string,
  groupHeader?: string,
  menuGroup?: string,
}>;

export type SideMenuGroupProps = {
  sideMenuLinkKey: string,
  expanded: boolean,
  children?: React.Node,
  selectedMenuKey?: string,
  selectedValue: string,
  onSubLinkSelect: (key: string) => void,
  subLinkOptions: Array<MenuOption>,
};

export const SideMenuGroup = ({
  sideMenuLinkKey = 'dashboard',
  expanded,
  onSubLinkSelect,
  subLinkOptions,
  selectedValue,
}: SideMenuGroupProps): React.Node => {
  const handleMenuOptionSelect = (option) => {
    onSubLinkSelect(option.key);
  };

  const isChildSelected = React.useMemo(() => {
    subLinkOptions;
    for (const item of subLinkOptions) {
      if (item.key === selectedValue) {
        return true;
      }
    }
    return false;
  }, [subLinkOptions, selectedValue]);

  return (
    <>
      {expanded ? (
        <div
          className={css.subMenuGroupWrapper}
          key={`${sideMenuLinkKey}-group`}
        >
          <SideMenuLink
            opened={true}
            pageNameKey={sideMenuLinkKey}
            classNames={{wrapper: css.subMenuGroupLink}}
            tabIndex={-1}
            disabled={true}
          />
          <div className={css.subMenuGroup}>
            {subLinkOptions.map((option) => {
              return (
                <SideMenuSubLink
                  pageNameKey={option.key}
                  pageNameLabel={option.label}
                  selectedValue={selectedValue}
                  onSubLinkSelect={onSubLinkSelect}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <CustomDropdown
          anchorPosition="right"
          ariaLabel={MENU_NAME_LIST[sideMenuLinkKey].title}
          menu={{
            isFluid: false,
            menuDisabled: false,
            options: subLinkOptions,
            selectedKeys: [selectedValue],
            size: 'small',
            width: size100,
          }}
          onOptionSelect={handleMenuOptionSelect}
          classNames={{dropdownContainer: css.dropdownWrapper}}
        >
          <HoverDropdown
            anchorPosition="right"
            menu={{
              isFluid: false,
              menuDisabled: false,
              options: subLinkOptions,
              selectedKeys: [selectedValue],
              size: 'small',
              width: size100,
            }}
            onOptionSelect={handleMenuOptionSelect}
            sideMenuLink={{
              opened: false,
              pageNameKey: sideMenuLinkKey,
              tabIndex: 0,
              selectedValue: isChildSelected ? sideMenuLinkKey : '',
            }}
            classNames={{dropdownContainer: css.dropdownWrapper}}
          />
        </CustomDropdown>
      )}
    </>
  );
};

// SideMenuGroup.displayName = 'SideMenuGroup';

export type SideMenuSubLinkProps = {
  pageNameKey: string,
  pageNameLabel?: string,
  selectedValue?: string,
  disabled?: boolean,
  onSubLinkSelect: (key: string) => void,
};

export const SideMenuSubLink = ({
  pageNameKey,
  pageNameLabel,
  selectedValue,
  disabled = false,
  onSubLinkSelect,
}: SideMenuSubLinkProps): React.Node => {
  const selected = selectedValue === pageNameKey;
  const handleClick = () => {
    onSubLinkSelect(pageNameKey);
  };
  return (
    <UnstyledButton
      className={classify(css.subLinkWrapper, {
        [css.selected]: selected,
        [css.disabled]: disabled,
      })}
      onClick={handleClick}
      tabIndex={0}
      key={pageNameKey}
    >
      <BodyMedium color={TEXT_COLORS.inverseSecondary} className={css.menuText}>
        {pageNameLabel}
      </BodyMedium>
    </UnstyledButton>
  );
};
