// @flow

import type {State} from 'src/reducers';
// $FlowFixMe[untyped-type-import]
import {type AnalyticsQuery} from 'src/utils/analytics-query';
import type {ClientEventType} from 'src/types/events';

import mapKeys from 'lodash/mapKeys';

import {isSmsEvent} from 'src/utils/events';

// $FlowFixMe[value-as-type] [v1.32.0]
export function selectDefaultQuery(state: State): ?AnalyticsQuery {
  return state.report.defaultQuery;
}

export function getTrackingEvents(eventType?: ClientEventType): string[] {
  let types;
  if (eventType === 'message') {
    types = [
      'sent',
      'delivered',
      'opened',
      'clicked',
      'unsubscribed',
      'bounced',
      'marked_as_spam',
    ];
  } else if (eventType === 'survey') {
    types = [
      'sent',
      'delivered',
      'opened',
      'clicked',
      'responded',
      'unsubscribed',
      'bounced',
      'marked_as_spam',
    ];
  } else if (eventType && isSmsEvent(eventType)) {
    types = ['sent', 'delivered', 'responded', 'unsubscribed'];
  } else {
    types = [
      'sent',
      'delivered',
      'opened',
      'clicked',
      'responded',
      'unsubscribed',
      'bounced',
      'marked_as_spam',
    ];
  }

  return types;
}

// TODO (kyle): eventually we shouldnt need this
const plotPropMap = {
  bounce: 'bounced',
  click: 'clicked',
  open: 'opened',
  spamreport: 'markedAsSpam',
  unsubscribe: 'unsubscribed',
};
export function standardizePlotPoint(plotPoint: Object): Object {
  return mapKeys(plotPoint, (value, key) => {
    const mappedKey = plotPropMap[key];
    return mappedKey ? mappedKey : key;
  });
}
