// @flow strict

import type {SMSChatbotAction} from 'src/action-creators/sms-chatbot';
import type {SMSChatbotState} from 'src/types/sms-chatbot';

import {
  RECEIVE_SMS_CHATBOT_INFO,
  UPDATE_SMS_CHATBOT_INFO,
  RESET,
} from 'src/action-creators/sms-chatbot';


const initialState = {
  id: null,
  flow_id: null,
  task_group_id: null,
  to_number: null,
};

export default (
  state: SMSChatbotState = initialState,
  action: SMSChatbotAction,
): SMSChatbotState => {
  switch (action.type) {
    case UPDATE_SMS_CHATBOT_INFO:
      const updatePayload = action.payload;
      return {
        ...state,
        ...updatePayload,
      };
    case RECEIVE_SMS_CHATBOT_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case RESET:
      return {...initialState};
  }
  return state;
};
