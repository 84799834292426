// @flow

import type {Dispatch, GetState, ThunkAction} from 'src/reducers';
import type {Contact} from 'src/types/contacts';

import type {ApiThreadList} from 'src/types/thread-lists';

import invariant from 'invariant';

import {camel, snake} from 'src/utils';
import {thunkify as flow} from 'src/utils/thunks';
import * as reduxApi from 'src/utils/redux-api-v2';
import {key, fetching, progressAction, force} from 'src/utils/redux';
import {csvKey, receiveManyContacts} from 'src/action-creators/contacts';
import {
  addThreadList,
  getOne as getThreadList,
} from 'src/action-creators/thread-lists';
import {selectPhoneNumberSetFromDefaultPhoneNumberSet} from 'src/selectors/chat';


export const uploadCsv: (contactListId?: string) => ThunkAction<void> = flow(
  key(() => csvKey),
  fetching(),
)(
  (contactListId?: string) => async (dispatch: Dispatch, getState: GetState) => {
    const csvState = getState().contacts.csv;

    invariant(
      csvState && csvState.file && csvState.results,
      'Attempted to upload a csv that did not exist in the store.',
    );

    const {file, results} = csvState;
    const phoneNumberSetId = selectPhoneNumberSetFromDefaultPhoneNumberSet(
      getState(),
    );

    const url = contactListId
      ? `messages_v2/contacts/upload/${contactListId}`
      : 'messages_v2/contacts/upload';

    const {
      created,
      updated,
      thread_list,
    }: {
      created: Contact[],
      updated: Contact[],
      invalid: Object[],
      thread_list: ApiThreadList,
    } = await dispatch(
      reduxApi.post(
        url,
        {
          phone_number_set_id: phoneNumberSetId,
          contacts_json: snake(results.data),
          file,
        },
        {},
        {
          multipart: true,
          processProgress: ({percent}) => {
            dispatch(progressAction(csvKey, (percent || 0) / 100));
          },
        },
      ),
    );

    // NOTE (kyle): saving this code if i need to test later
    /*
  await new Promise(resolve => {
    let i = 0;
    const countUp = () => {
      dispatch(progressAction(csvKey, i / 100));
      i++;
      if (i >= 100) {
        resolve();
      } else {
        setTimeout(countUp, 200);
      }
    };

    countUp();
  });
  */

    dispatch(receiveManyContacts(camel([...created, ...updated])));
    if (contactListId) {
      await dispatch(force(getThreadList(thread_list.id)));
    } else {
      dispatch(addThreadList({threadList: camel(thread_list)}));
    }
  },
);
