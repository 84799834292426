// @flow

import type {ThunkAction} from 'src/reducers';
import type {AudienceMember, Placement} from 'src/types/audience-member';
import type {EntityType} from 'src/types/ats-entities';

import {thunkify as flow} from 'src/utils/thunks';
import {camel, deprecate, ensureArray} from 'src/utils';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api';

import {getMultiEntityAudienceMember} from 'src/action-creators/audience-members/multientity';


export const RECEIVE_AUDIENCE_MEMBERS =
  'audienceMembers/audienceMembers/receive';
export const RECEIVE_PLACEMENTS = 'audienceMembers/placements/receive';

export type ReceiveAudienceMembersAction = {
  type: 'audienceMembers/audienceMembers/receive',
  payload: AudienceMember[],
};

export type ReceivePlacements = {
  type: 'audienceMembers/placements/receive',
  payload: {
    audienceMemberId: string,
    placements: Placement[],
  },
};

export type AudienceMemberAction =
  | ReceiveAudienceMembersAction
  | ReceivePlacements;

export const receiveAudienceMembers = (
  audienceMembers: AudienceMember | AudienceMember[],
): ReceiveAudienceMembersAction => ({
  type: RECEIVE_AUDIENCE_MEMBERS,
  payload: ensureArray<AudienceMember>(audienceMembers),
});

const receivePlacements = (audienceMemberId, placements) => ({
  type: RECEIVE_PLACEMENTS,
  payload: {
    audienceMemberId,
    placements,
  },
});

export const getLegacyAudienceMember: (id: string) => ThunkAction<mixed> = flow(
  key((id) => `audienceMember/${id}`),
  cached((json) => receiveAudienceMembers([camel(json, false)])),
  fetching(),
)((id) => reduxApi.get(`audience-members/${id}`));

// toggle redux getAudienceMember behavior for legacy/me audiences
export const getAudienceMember: (
  id: string,
  type: EntityType,
) => ThunkAction<mixed> = (id, type) => (dispatch, getState) => {
  if (!type) {
    deprecate({
      reason: '`getAudienceMember` must be called with second parameter `type`',
    });
  }
  return dispatch(getMultiEntityAudienceMember(id, type));
};

export const getPlacements: (id: string) => ThunkAction<mixed> = flow(
  key((id) => `audienceMember/placements/${id}`),
  cached((json, id) => receivePlacements(id, camel(json))),
  fetching(),
)((id) => reduxApi.get(`audience-members/${id}/placements`));
