// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {TriggerRule} from 'src/types/trigger';

import type {TriggerState} from 'src/reducers/trigger';

// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';
import {getAutomationWorkflow} from 'src/action-creators/automation-workflow';
import {parseTrigger, normalizeTrigger} from 'src/utils/trigger';


type UpdateTriggerPayload = $Shape<TriggerState>;

export const RECEIVE_TRIGGER = 'trigger/receive';
export const UPDATE_TRIGGER = 'trigger/update';
export const RESET_TRIGGER = 'trigger/reset';
export const ADD_TRIGGER_RULE = 'trigger/add-rule';
export const REMOVE_TRIGGER_RULE = 'trigger/remove-rule';
export const UPDATE_TRIGGER_RULE = 'trigger/update-rule';
export const START_EDIT_TRIGGER_RULE = 'trigger/start-edit-rule';
export const END_EDIT_TRIGGER_RULE = 'trigger/end-edit-rule';

type UpdateTriggerAction = {
  type: 'trigger/update',
  payload: UpdateTriggerPayload,
};
type ResetTriggerAction = {
  type: 'trigger/reset',
  payload?: TriggerState,
};
type ReceiveTriggerAction = {
  type: 'trigger/receive',
  payload: TriggerState,
};
type AddTriggerRuleAction = {
  type: 'trigger/add-rule',
  payload: string,
};
type RemoveTriggerRuleAction = {
  type: 'trigger/remove-rule',
  payload: string,
};
type UpdateTriggerRuleAction = {
  type: 'trigger/update-rule',
  payload: TriggerRule,
};
type StartEditTriggerRuleAction = {
  type: 'trigger/start-edit-rule',
  payload: string,
};
type EndEditTriggerRuleAction = {
  type: 'trigger/end-edit-rule',
};

export type TriggerAction =
  | UpdateTriggerAction
  | ResetTriggerAction
  | ReceiveTriggerAction
  | AddTriggerRuleAction
  | RemoveTriggerRuleAction
  | UpdateTriggerRuleAction
  | StartEditTriggerRuleAction
  | EndEditTriggerRuleAction;

export const updateTrigger = (
  payload: UpdateTriggerPayload,
): UpdateTriggerAction => ({
  type: UPDATE_TRIGGER,
  payload,
});

export const resetTrigger = (payload?: TriggerState): ResetTriggerAction => ({
  type: RESET_TRIGGER,
  payload,
});

export const receiveTrigger = (
  payload: $Shape<TriggerState>,
): ReceiveTriggerAction => ({
  type: RECEIVE_TRIGGER,
  payload,
});

export const addTriggerRule = (payload: string): AddTriggerRuleAction => {
  return {
    type: ADD_TRIGGER_RULE,
    payload,
  };
};

export const removeTriggerRule = (
  payload: string,
): RemoveTriggerRuleAction => ({
  type: REMOVE_TRIGGER_RULE,
  payload,
});

export const updateTriggerRule = (
  payload: TriggerRule,
): UpdateTriggerRuleAction => ({
  type: UPDATE_TRIGGER_RULE,
  payload,
});
export const startEditTriggerRule = (
  id: string,
): StartEditTriggerRuleAction => ({
  type: START_EDIT_TRIGGER_RULE,
  payload: id,
});
export const endEditTriggerRule = (): EndEditTriggerRuleAction => ({
  type: END_EDIT_TRIGGER_RULE,
});

export const saveTrigger: (payload: {
  from_node_id: string,
  to_node_id: string,
}) => ThunkAction<mixed> = (payload) => (dispatch: Dispatch, getState) => {
  const {from_node_id, to_node_id} = payload;
  const triggerApiPayload = {
    from_node_id,
    to_node_id,
    ...normalizeTrigger(getState().trigger),
  };
  return dispatch(reduxApi.put(`node/transition`, triggerApiPayload));
};

export const getTrigger: (
  id: string,
  swid: number,
) => ThunkAction<mixed> | mixed =
  (id, swid) => (dispatch: Dispatch, getState) => {
    //we already have data for trigger in the swimlane data so we pick it from there instead of getting it from the api
    const triggerData = getState().automationWorkflow.swimlanes.find(
      (swimlane) => swimlane.id === swid,
    ).nodes[id];
    if (triggerData) {
      return dispatch(receiveTrigger(parseTrigger(triggerData)));
    }
  };

export const saveTriggerAndSync: ({
  from_node_id: string,
  to_node_id: string,
  workflow_id: string,
}) => ThunkAction<mixed> =
  ({from_node_id, to_node_id, workflow_id}) =>
  (dispatch: Dispatch) => {
    return dispatch(saveTrigger({from_node_id, to_node_id})).then(() => {
      dispatch(getAutomationWorkflow(workflow_id));
    });
  };
