// @noflow

import type {Action, AgentSettings} from 'src/action-creators/agent-settings';

import {
  CHANGE,
  SET_SIGNATURE,
  SET_ANALYTICS_DIGEST_EMAIL_SUBSCRIPTION,
  SET_ANALYTICS_REPORT_LIST,
} from 'src/action-creators/agent-settings';


export type State = $Shape<AgentSettings>;

const initialState = {};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_SIGNATURE:
      return {
        ...state,
        signature: action.payload,
      };
    case SET_ANALYTICS_DIGEST_EMAIL_SUBSCRIPTION:
      return {
        ...state,
        analyticsDigestEmailSubscription: action.payload,
      };
    case SET_ANALYTICS_REPORT_LIST:
      return {
        ...state,
        analyticsReportList: action.payload,
      };
  }
  return state;
};
