// @flow

import type {Dispatch, ThunkAction} from 'src/reducers';
import type {NamespacedGlobalVariable} from 'src/types/namespaced-global-variables';

import {thunkify as flow} from 'src/utils/thunks';
import * as reduxApi from 'src/utils/redux-api.js';
import {cached, fetching, key} from 'src/utils/redux';


export const RECEIVE_GLOBAL_VARIABLES =
  'namespaced-global-variables/receiveAll';

type ReceiveGlobalVariablesAction = {
  type: 'namespaced-global-variables/receiveAll',
  payload: NamespacedGlobalVariable,
};

export type NamespacedGlobalVariablesAction = ReceiveGlobalVariablesAction;

const receiveGlobalVariables = (
  globalVariables,
): NamespacedGlobalVariablesAction => ({
  type: 'namespaced-global-variables/receiveAll',
  payload: globalVariables,
});

export const getAllNamespacedGlobalVariables: () => ThunkAction<mixed> = flow(
  key(() => 'namespaced-global-variables/receiveAll'),
  cached((globalVariables) => receiveGlobalVariables(globalVariables), {
    ttl: 86_400_000,
  }),
  fetching(),
)(() => (dispatch: Dispatch) =>
  dispatch(reduxApi.get('global-variables/namespaced')),
);
