// @flow strict
import type {AnalyticsGenesisActions} from 'src/action-creators/analytics-genesis';
import type {
  ProductCatalogItem,
  SelectedProduct,
  Metric,
  HiringVelocityMetrics,
  ChartData,
} from 'src/types/analytics-genesis';

import {
  RECEIVE_PRODUCT_CATALOG_LIST,
  RECEIVE_CURRENT_PRODUCT_LIST_ITEM,
  RECEIVE_SUMMARY_METRICS,
  RECEIVE_HIRING_METRICS,
  RECEIVE_ROI_TREND,
} from 'src/action-creators/analytics-genesis';


export type State = {
  productCatalogList: Array<ProductCatalogItem>,
  currentProductListItem: SelectedProduct | null,
  summaryMetrics: Array<Metric>,
  hiringMetrics: HiringVelocityMetrics,
  roiTrendNumbers: ChartData | null,
};

const initialState = {
  productCatalogList: [],
  currentProductListItem: null,
  summaryMetrics: [],
  hiringMetrics: [],
  roiTrendNumbers: null,
};

export default (
  state: State = initialState,
  action: AnalyticsGenesisActions,
): State => {
  switch (action.type) {
    case RECEIVE_PRODUCT_CATALOG_LIST: {
      return {
        ...state,
        productCatalogList: action.payload.productCatalogList,
      };
    }
    case RECEIVE_CURRENT_PRODUCT_LIST_ITEM: {
      return {
        ...state,
        currentProductListItem: action.payload.currentProductListItem,
      };
    }
    case RECEIVE_SUMMARY_METRICS: {
      return {
        ...state,
        summaryMetrics: action.payload,
      };
    }
    case RECEIVE_HIRING_METRICS: {
      return {
        ...state,
        hiringMetrics: action.payload,
      };
    }
    case RECEIVE_ROI_TREND: {
      return {
        ...state,
        roiTrendNumbers: action.payload,
      };
    }
  }

  return state;
};
