// @flow strict

import type {SmsState} from 'src/types/sms';
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import * as reduxApiBff from 'src/utils/redux-api-bff';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';
import {parseSms} from 'src/utils/sms';


export const RECEIVE_SMS_INFO = 'sms/receive';
export const UPDATE_SMS_INFO = 'sms/update';

export const RESET = 'sms/reset';

type UpdateSmsInfoPayload = $Shape<SmsState>;

type ReceiveSmsInfoAction = {
  type: 'sms/receive',
  payload: SmsState,
};

type UpdateSmsInfoAction = {
  type: 'sms/update',
  payload: UpdateSmsInfoPayload,
};

type ResetStateAction = {
  type: 'sms/reset',
};

export type SmsAction =
  | ReceiveSmsInfoAction
  | UpdateSmsInfoAction
  | ResetStateAction;

export const reset = (): ResetStateAction => ({type: RESET});

export const receiveSmsNode = (payload: SmsState): ReceiveSmsInfoAction => ({
  type: RECEIVE_SMS_INFO,
  payload,
});

export const updateSmsInfo = (
  payload: UpdateSmsInfoPayload,
): UpdateSmsInfoAction => ({
  type: UPDATE_SMS_INFO,
  payload,
});

export const getSmsTaskNode: (id: string) => ThunkAction<mixed> =
  (id) => async (dispatch: Dispatch) => {
    const fetchedNodeResponse = await dispatch(
      reduxApiBff.get(`node`, {
        node_id: `task:${id}`,
      }),
    );
    const parsedSmsNode = parseSms(fetchedNodeResponse);
    return dispatch(updateSmsInfo(parsedSmsNode));
  };

export const getProvisionedPhoneNumbers: (
  id: string | number,
) => ThunkAction<mixed> = (id) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.get(`provisioned-phone`, {
      associated_with_category: String(true),
      category_id: String(id),
    }),
  );
};
