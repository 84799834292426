import 'src/utils/polyfills';

import React from 'react';
import {render} from 'react-dom';
import {RouterProvider} from 'src/rerouter';
import * as sentry from 'sentry';

import {browserHistory} from 'src/reroutes/browser-history';
import {bootstrapStores} from 'src/client/bootstrap';
import logger from 'src/utils/logger';
import App from 'src/components/sense.jsx';
import ErrorBoundary from 'src/components/500/boundary.jsx';
import {PartialError as PartialErrorComponent} from 'src/components/206/index.jsx';
import {initializeFlags} from 'src/actions';
import {
  showGenericRuntimeError,
  showUnhandledApiError,
} from 'src/action-creators/modal';
import {showPartialErrorToast} from 'src/action-creators/toasts';
import {
  ApiError,
  PartialError,
  captureApiError,
  wranglePossibleApiError,
} from 'src/utils/errors';
import {isDevOrStage} from 'src/utils/env';
import {pathify} from 'src/utils/index';


const {reduxStore, store, env} = bootstrapStores(browserHistory);

if (reduxStore.getState().accounts.authedUserId !== null) {
  const uid = reduxStore.getState().accounts.authedUserId;
  const agency = reduxStore.getState().env.agencySlug;
  sentry.configureScope((scope) => {
    scope.setUser({
      id: uid,
      username: reduxStore.getState().accounts.data[uid].name,
    });
    scope.setTag('agency', agency);
  });
  logger.updateContext({
    userid: uid,
  });
  // NOTE (kyle): we need this for walkme
  window.__senseUserId = uid;
}

//let currentRoutes = routes(store, reduxStore);

function sendPageviewToGoogleAnalytics() {
  // Note (gab): must pass this function as the `onUpdate` prop to the <Router> class in order
  // for the `this` variable to point to the Router component instance
  const {routes} = this.state;
  const {ga} = window;
  if (ga && routes) {
    ga('set', 'page', pathify(routes));
    ga('send', 'pageview');
  }
}

const renderWith = () => {
  render(
    <ErrorBoundary>
      <RouterProvider history={browserHistory}>
        <App reduxStore={reduxStore} senseStore={store} />
      </RouterProvider>
    </ErrorBoundary>,
    document.getElementById('app'),
  );
};

renderWith();

if (store.me.isAuthed()) {
  initializeFlags(store);

  // App Cues
  // NOTE (kyle): if we ever go back to ajax login, we'll need to add this to the login action
  const {Appcues} = window;
  if (Appcues) {
    Appcues.start();
    const {agent} = store.me.state;
    Appcues.identify(agent.id, {
      name: agent.name,
      // NOTE (kyle): can add more props for targeting
    });
  }
}

if (isDevOrStage({env})) {
  window.addEventListener('error', (...args) => {
    const {dispatch} = reduxStore;
    dispatch(showGenericRuntimeError(...args));
  });
}

// NOTE (kyle): Support for this in Chrome is highly dependent on obscure
// security conditions, and core-js never polyfills Promise in Chrome.
// Hopefully this will improve over time, but there is no guarentee.
// NOTE (kyle): When @babel/polyfill upgrades to core-js@3, we should be
// able to use the more elegant, addEventListener version of this.
window.onunhandledrejection = (event) => {
  let {reason} = event;
  // NOTE (kyle): utils/api.js returns a weird legacy error object.
  reason = wranglePossibleApiError(reason);

  window.lastUnhandledError = reason;

  if (reason instanceof PartialError) {
    captureApiError(reason, null, {unhandled: true});
    reduxStore.dispatch(showPartialErrorToast(<PartialErrorComponent />));
  } else if (reason instanceof ApiError) {
    captureApiError(reason, null, {unhandled: true});
    if (
      reduxStore.getState().accounts.authedUserId &&
      ![401, 403].includes(reason.response.status)
    ) {
      reduxStore.dispatch(showUnhandledApiError(reason, sentry.lastEventId()));
    }
  } else {
    sentry.captureException(reason);
  }
};

// To ease live debugging
window.senseStore = store;
// NOTE(gab): Don't delete this unless you've verified via grepping the codebase that it's not used
window.__reduxStore = reduxStore;
window.logger = logger;
