// @flow

import type {ThunkAction, GetState, Dispatch} from 'src/reducers';

import isEmpty from 'lodash/isEmpty';
import snakeCase from 'lodash/snakeCase';
import invariant from 'invariant';

import {thunkify as flow} from 'src/utils/thunks';
import * as reduxApi from 'src/utils/redux-api-v2';
import {key, cached, fetching} from 'src/utils/redux';
import {camel} from 'src/utils';
import logger from 'src/utils/logger';

import {selectPhoneNumberSetFromDefaultPhoneNumberSet} from 'src/selectors/chat';


export const CHANGE = 'agentSettings/change';
export const SET_SIGNATURE = 'agentSettings/setSignature';
export const SET_ANALYTICS_DIGEST_EMAIL_SUBSCRIPTION =
  'agentSettings/setAnalyticsDigestEmailSubscription';
export const SET_ANALYTICS_REPORT_LIST = 'agentSettings/setAnalyticsReportList';

export type Action =
  | ChangeAction
  | SetSignatureAction
  | SetAnalyticsReportListAction
  | SetAnalyticsDigestEmailSubscriptionAction;

export type Signature = {active: boolean, body: string, ...};

// Defined in app_server/agent/db_schema.py
export type AgentSettings = {
  messagingSound: boolean,
  messagingEmailNotificationLevel: 'disabled' | 'unread' | 'all',
  timezone: string, // may be empty string
  messagingCallForwardingNumber: string, // may be empty string
  messagingEmailNotificationDelayMinutes: number,
  signature: Signature,
  analyticsDigestEmailSubscription?: Boolean,
  sendOnEnter: boolean,
  messagingEnableConversationContext: boolean,
};

type ChangeAction = {
  type: 'agentSettings/change',
  payload: $Shape<AgentSettings>,
};

type SetSignatureAction = {
  type: 'agentSettings/setSignature',
  payload: mixed,
};

type SetAnalyticsDigestEmailSubscriptionAction = {
  type: 'agentSettings/setAnalyticsDigestEmailSubscription',
  payload: boolean,
};

type SetAnalyticsReportListAction = {
  type: 'agentSettings/setAnalyticsReportList',
  payload: Array<{name: string, ...}>,
};

export const change = (payload: $Shape<AgentSettings>): ChangeAction => ({
  type: CHANGE,
  payload,
});

const setSignature = (payload: mixed): SetSignatureAction => ({
  type: SET_SIGNATURE,
  payload,
});

const setAnalyticsDigestEmailSubscription = (
  payload: boolean,
): SetAnalyticsDigestEmailSubscriptionAction => ({
  type: SET_ANALYTICS_DIGEST_EMAIL_SUBSCRIPTION,
  payload,
});

const setAnalyticsReportList = (
  payload: Array<{name: string, ...}>,
): SetAnalyticsReportListAction => ({
  type: SET_ANALYTICS_REPORT_LIST,
  payload,
});

export const getAnalyticsDigestEmailSubscription =
  (): ThunkAction<void> => async (dispatch: Dispatch) => {
    try {
      const reports = await dispatch(reduxApi.get('reports'));
      dispatch(setAnalyticsReportList(reports));
      const reportsNames = reports.map((r) => r.name);
      if (reportsNames.includes('WeeklyMessagingStats')) {
        const weeklyMessagingStats = await dispatch(
          reduxApi.get('reports/subscription/WeeklyMessagingStats'),
        );

        dispatch(
          setAnalyticsDigestEmailSubscription(Boolean(weeklyMessagingStats)),
        );
      }
    } catch (error) {
      logger.error(error);
    }
  };

export const updateAnalyticsDigestEmailSubscription =
  (status: boolean): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const prevValue = getState().agentSettings.analyticsDigestEmailSubscription;
    dispatch(setAnalyticsDigestEmailSubscription(status));
    try {
      if (status) {
        dispatch(reduxApi.post('reports/subscription/WeeklyMessagingStats'));
      } else {
        dispatch(reduxApi.del('reports/subscription/WeeklyMessagingStats'));
      }
    } catch (e) {
      dispatch(setAnalyticsDigestEmailSubscription(prevValue));
    }
  };

export const getSignature = (): ThunkAction<> => async (dispatch: Dispatch) => {
  try {
    const res = await dispatch(reduxApi.get('messages_v2/signature'));
    dispatch(setSignature(res));
  } catch (error) {
    if (error?.response.status === 404) {
      dispatch(setSignature(null));
    } else {
      throw error;
    }
  }
};

export const updateSignature =
  (updates: {body?: string, active?: boolean}): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const prevValue = getState().agentSettings.signature || {};
    dispatch(setSignature({...prevValue, ...updates}));
    try {
      await dispatch(reduxApi.put('messages_v2/signature', updates));
    } catch (e) {
      dispatch(setSignature(prevValue));
    }
  };

export const createSignature =
  (updates: {
    body?: string,
    active?: boolean,
    enable_for_all_messages?: boolean,
  }): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const prevValue = getState().agentSettings.signature || {};
    dispatch(setSignature({prevValue, ...updates}));
    try {
      const res = await dispatch(
        reduxApi.post('messages_v2/signatures', updates),
      );
    } catch (e) {
      dispatch(setSignature(prevValue));
    }
  };

flow(
  key(() => 'put agent signature'),
  fetching({}),
)(
  (name: $Keys<AgentSettings>, value: any): ThunkAction<mixed> =>
    async (dispatch, getState) => {
      const {agentSettings} = getState();

      invariant(
        !isEmpty(agentSettings),
        'Must populate agent settings before updating',
      );

      const prevValue = agentSettings[name];

      // optimistic update
      // $FlowFixMe not sure how to handl this. maybe should change the params to a $Shape
      dispatch(change({[name]: value}));

      try {
        await dispatch(
          reduxApi.put(`agents/me/settings/${snakeCase(name)}`, {value}),
        );
      } catch (err) {
        // revert optimistic update
        // $FlowFixMe same as above
        dispatch(change({[name]: prevValue}));
        throw err;
      }
    },
);

export const getAgentSettings =
  (): ThunkAction<mixed> => (dispatch: Dispatch, getState: GetState) => {
    const phoneNumberSetId = selectPhoneNumberSetFromDefaultPhoneNumberSet(
      getState(),
    );
    if (phoneNumberSetId) {
      return dispatch(fetchAgentSettings(phoneNumberSetId));
    } else {
      return Promise.resolve();
    }
  };

export const fetchAgentSettings: (phoneNumberSetId: string) => ThunkAction<> =
  flow(
    key((phoneNumberSetId) => `agents/me/settings/${phoneNumberSetId}`),
    cached((response) => change(camel(response))),
    fetching(),
  )(() => reduxApi.get('agents/me/settings'));

// This updates one setting at a time
export const updateAgentSetting: (
  name: $Keys<AgentSettings>,
  value: any,
) => ThunkAction<mixed> = flow(
  key(() => 'put agent setting'),
  fetching({}),
)(
  (name: $Keys<AgentSettings>, value: any): ThunkAction<mixed> =>
    async (dispatch, getState) => {
      const {agentSettings} = getState();

      invariant(
        !isEmpty(agentSettings),
        'Must populate agent settings before updating',
      );

      const prevValue = agentSettings[name];

      // optimistic update
      // $FlowFixMe not sure how to handl this. maybe should change the params to a $Shape
      dispatch(change({[name]: value}));

      try {
        await dispatch(
          reduxApi.put(`agents/me/settings/${snakeCase(name)}`, {value}),
        );
      } catch (err) {
        // revert optimistic update
        // $FlowFixMe same as above
        dispatch(change({[name]: prevValue}));
        throw err;
      }
    },
);
