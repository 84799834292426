// @flow strict

export default {
  survey: 'Completion',
  chatbot: 'Completion',
  email_with_links: 'Click',
  email_no_links: 'Open',
  sms: 'Reply',
  email: 'Unknown (Click or Open)',
};
