// @flow strict

import type {HelperAction} from 'src/action-creators/template-helper';

import {TOGGLE_TEMPLATE_HELPER} from 'src/action-creators/template-helper';


export type HelperState = {
  expanded: boolean,
};

const initialState = {
  expanded: true,
};

export default (
  state: HelperState = initialState,
  action: HelperAction,
): HelperState => {
  switch (action.type) {
    case TOGGLE_TEMPLATE_HELPER: {
      return {
        ...state,
        expanded: action.payload.expanded,
      };
    }
  }
  return state;
};
