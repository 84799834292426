// @flow

import * as React from 'react';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';

import {emptyArray, emptyObject, deprecate} from 'src/utils';
import classify from 'src/utils/classify';
import makeClassNameComponent, {
  type ClassNameComponent,
} from 'src/utils/makeClassNameComponent';

import Loading from 'src/components/lib/loading/loading.jsx';
import {AutoTruncatedText} from 'src/components/lib/truncated-text/truncated-text.jsx';

import ArrowDown from 'src/images/arrow-down.svg';

import css from './table.css';


export type TableHeader = {
  label: React.Node,
  key: string,
  className?: string,
  filterIcon?: React.Node,
  filtered?: boolean,
  subtext?: string,
  sortable?: boolean,
  ContentNode?: React.Node,
  headerIconClassName?: string,
}[];

type Props<Entry: {...}> = {
  ...EmptyRowProps,
  className?: string,
  Row: React.ComponentType<{
    data: Entry,
    extras: Object,
    sortedKeys: string[],
  }>,
  header: TableHeader,
  entries: Array<Entry>,
  extras: Object,
  sortable: boolean,
  showHeader?: boolean,
  tableHeaderClassName?: string,
  headerIconClassName?: string,
  defaultSortKey?: string,
  defaultSortDirection: 'asc' | 'desc',
  idName: string,
  onSort?: (key: string, direction: SortDirection) => void,
  EmptyContent?: React.ComponentType<{isLoading?: boolean}>,
};

type SortDirection = 'asc' | 'desc';

type EmptyRowProps = {
  emptyText?: React.Node,
  isLoading?: boolean,
};

export class Table<Entry: {...}> extends React.PureComponent<
  Props<Entry>,
  {
    sortKey?: string,
    sortDirection: SortDirection,
  },
> {
  static defaultProps: {
    defaultSortDirection: SortDirection,
    entries: any,
    extras: any,
    header: any,
    idName: string,
    sortable: boolean,
    showHeader?: boolean,
  } = {
    header: emptyArray,
    entries: emptyArray,
    extras: emptyObject,
    sortable: true,
    showHeader: true,
    defaultSortDirection: 'asc',
    idName: 'id',
  };

  state: {sortDirection: SortDirection, sortKey?: string} = {
    sortKey: this.props.defaultSortKey,
    sortDirection: this.props.defaultSortDirection,
  };

  render(): React.Node {
    const {
      className,
      Row,
      entries,
      extras,
      header,
      idName,
      sortable,
      isLoading,
      emptyText,
      EmptyContent,
      showHeader,
      tableHeaderClassName,
      headerIconClassName,
      onSort,
    } = this.props;
    const {sortKey, sortDirection} = this.state;
    const sortEntries = () => {
      if (onSort) {
        return entries;
      } else {
        const sortedEntries = sortKey
          ? sortBy(entries, (entry) => {
              const value = sortKey
                .split('.')
                .reduce(
                  (currentEntryLevel, property) => currentEntryLevel[property],
                  entry,
                );
              if (typeof value === 'string') {
                return value.toLowerCase();
              } else if (typeof value === 'object') {
                return value?.length || 0;
              } else {
                return value;
              }
            })
          : entries;
        if (sortDirection === 'desc') {
          sortedEntries.reverse();
        }
        return sortedEntries;
      }
    };

    const sortedEntries = sortEntries();

    const sortedKeys = sortedEntries.map((ent) => get(ent, idName));

    return (
      <TableBox className={className}>
        {showHeader && (
          <header
            className={classify(css.tableHeaderSortable, tableHeaderClassName)}
          >
            {header.map(
              ({
                key,
                label,
                subtext,
                filterIcon,
                filtered,
                ContentNode,
                className,
                sortable: cellSortable = true,
              }) => {
                let headerClassName;
                const filterable = Boolean(filterIcon);
                if ((sortable && cellSortable) || filterable) {
                  headerClassName = classify(
                    css.headerCellSortable,
                    {
                      [css.sorted]: sortKey === key,
                      [css.filtered]: filtered,
                    },
                    css[sortDirection],
                    className,
                  );
                } else {
                  headerClassName = classify(css.headerCell, className);
                }
                return (
                  <div
                    className={headerClassName}
                    key={key}
                    onClick={
                      sortable && cellSortable
                        ? () => {
                            this.handleSortClick(key);
                          }
                        : null
                    }
                  >
                    {ContentNode}
                    {!ContentNode && (
                      <>
                        <div className={css.labelContainer}>
                          {label}
                          <span className={css.headerSubtext}>
                            {subtext && subtext}
                          </span>
                        </div>
                        {(sortable || filterIcon != null) && (
                          <div
                            className={classify(
                              css.headerIconContainer,
                              headerIconClassName,
                            )}
                          >
                            {sortable && (
                              <ArrowDown className={css.sortArrow} />
                            )}
                            {filterIcon != null && (
                              <div className={css.filterIcon}>{filterIcon}</div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                );
              },
            )}
          </header>
        )}

        <div className={css.tableBodyV2}>
          {isLoading || !sortedEntries.length ? (
            EmptyContent != null ? (
              <EmptyContent isLoading={isLoading} />
            ) : (
              <EmptyRow isLoading={isLoading} emptyText={emptyText} />
            )
          ) : (
            sortedEntries.map((entry) => (
              <Row
                data={entry}
                extras={extras}
                sortedKeys={sortedKeys}
                key={get(entry, idName)}
              />
            ))
          )}
        </div>
      </TableBox>
    );
  }

  handleSortClick(sortKey: string) {
    if (sortKey === this.state.sortKey) {
      const sortDirection = this.state.sortDirection === 'asc' ? 'desc' : 'asc';
      this.setState({sortDirection}, () => {
        if (this.props.onSort) {
          this.props.onSort(sortKey, sortDirection);
        }
      });
    } else {
      this.setState({sortKey});
      if (this.props.onSort && this.state?.sortDirection) {
        this.props.onSort(sortKey, this.state.sortDirection);
      }
    }
  }
}

export default Table;

type CellsProps = {
  children?: React.Node,
  className?: ?string,
  truncatedText?: React.Node,
  onClick?: ?(SyntheticEvent<HTMLElement>) => mixed,
};

export const TableBox: ClassNameComponent<> = makeClassNameComponent(css.table);

export const Cells = ({
  children,
  className,
  ...rest
}: CellsProps): React.Element<'div'> => (
  <div className={classify(css.row, className)} {...rest}>
    {children}
  </div>
);
export const Row = Cells;

export const Cell = ({
  children,
  truncatedText,
  className,
}: CellsProps): React.Element<'div'> => {
  if (React.isValidElement(children) && truncatedText == null) {
    deprecate({
      reason: `Cell cannot have a React.Node as its 'text', please use the 'truncatedText' prop or a BasicCell.`,
      person: 'Marcos',
    });
  }

  return (
    <div className={classify(css.cell, className)}>
      <AutoTruncatedText text={children} tipContent={truncatedText} />
    </div>
  );
};

export const BasicCell = ({
  children,
  className,
}: CellsProps): React.Element<'div'> => (
  <div className={classify(css.cell, className)}>{children}</div>
);

export const TruncatedCell = ({
  content,
  className,
  qaId,
}: {
  content: string | React.Node,
  className: ?string,
  qaId?: string,
}): React.Element<'div'> => (
  <div className={classify(css.cell, className)} data-qa-id={qaId}>
    <AutoTruncatedText text={content} />
  </div>
);

export const TableContainer: React.AbstractComponent<
  React.ElementConfig<empty>,
> = makeClassNameComponent(css.tableContainer);
export const TablePage: React.AbstractComponent<React.ElementConfig<empty>> =
  makeClassNameComponent(css.content);
export const TablePageHeader: React.AbstractComponent<
  React.ElementConfig<string>,
> = makeClassNameComponent(css.header, 'header');
export const TableActions: ClassNameComponent<> = makeClassNameComponent(
  css.actionsNew,
);

export const Page: React.AbstractComponent<React.ElementConfig<empty>> =
  makeClassNameComponent(css.content2);
export const HeaderSection: React.AbstractComponent<
  React.ElementConfig<empty>,
> = makeClassNameComponent(css.headerSection);

export const EmptyRow = ({
  isLoading,
  emptyText,
}: EmptyRowProps): React.Element<'div'> => (
  <div className={css.emptyRow}>
    {isLoading ? (
      <Loading className={css.loader} />
    ) : (
      emptyText || 'Nothing to display here.'
    )}
  </div>
);
