// @flow strict

import * as React from 'react';
import PlusIcon from 'src/images/icons/plus.svg';
import {Button} from 'src/components/lib/new-button';
import css from './index.css';
import dsCss from 'designSystem2021';


type Props = {
  className?: string,
  onClick?: () => void,
  hideVariableIcon?: boolean,
  text: string,
};

//we can not use the exiting button component here
//because of ux requirements.
export const VariableButton = ({
  text,
  onClick,
  hideVariableIcon = false,
}: Props): React.Node => (
  <button type="button" onClick={onClick} className={css.button}>
    <span className={css.iconContainer}>
      <PlusIcon className={dsCss.colorClickable} width={8} height={8} />
    </span>
    {text}{' '}
    {!hideVariableIcon && (
      <span className={css.variableIndicator}>{'< x >'}</span>
    )}
  </button>
);

export default VariableButton;
