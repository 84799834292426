// @flow strict

import * as React from 'react';

//$FlowFixMe
import * as utils from 'src/utils';

import css from './index.css';


export const PartialError = (): React.Node => {
  const handleClose = () => {
    utils.reload();
  };
  return (
    <span>
      Something went wrong and the changes are not saved. Please{' '}
      <span className={css.pointer} onClick={handleClose}>
        <b>
          <u>refresh</u>
        </b>
      </span>
    </span>
  );
};
