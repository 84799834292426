// @flow
// $FlowFixMe[untyped-type-import]
import type {Workflow} from 'src/api-parsers/index';

import Store from './base';


export default class AudienceMemberWorkflowStore extends Store {
  state: {
    // $FlowFixMe[value-as-type] [v1.32.0]
    [key: string]: Workflow[],
  };

  constructor() {
    super('audience-member-workflows');

    this.state = {...null};
  }

  get(audienceMemberId: string): Object[] {
    return this.state[audienceMemberId];
  }

  set(audienceMemberId: string, workflows: Object[]) {
    this.updateState({
      [audienceMemberId]: {
        $set: workflows,
      },
    });
  }
}
