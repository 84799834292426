// @flow strict

//$FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';
import {AuthenticationStatus} from 'src/reducers/authentication';

// $FlowFixMe[nonstrict-import]
import type {Agency} from 'src/types/agency';


export const selectMfaCredsValidating = (state: State): boolean =>
  state.authentication?.credAuthenticationStatus ===
  AuthenticationStatus.AUTHENTICATING;

export const selectMfaCredsError = (state: State): boolean =>
  state.authentication?.credAuthenticationStatus === AuthenticationStatus.ERROR;

export const selectMfaAuthChannel = (state: State): string =>
  state.authentication?.authenticationData?.mfa_settings?.auth_channel;

export const selectUserPhoneNumber = (state: State): string =>
  state.authentication?.authenticationData?.mfa_settings?.phone_number;

export const selectUserLoginId = (state: State): string =>
  state.authentication?.authenticationData?.login_id;

export const selectUserEmail = (state: State): string =>
  state.authentication?.authenticationData?.mfa_settings?.email_id;

export const selectUserNewPhoneNumber = (state: State): string =>
  state.authentication?.newPhone;

export const selectCurrentAgency = (state: State): Agency =>
  state.authentication?.authenticationData?.agency;

export const selectSignInError = (state: State): string =>
  state.authentication?.signInError;

export const selectOtpVerificationError = (state: State): string =>
  state.authentication?.otpVerificationError;

export const selectNewPhoneRegistrationError = (state: State): string =>
  state.authentication?.newPhoneRegistrationError;

export const selectResendOtpError = (state: State): string =>
  state.authentication?.resendOTPError;

export const selectResetPhoneError = (state: State): string =>
  state.authentication?.resetPhoneError;

export const selectResetPhoneApproveError = (state: State): string =>
  state.authentication?.resetPhoneApproveError;

export const selectResetPhoneApproved = (state: State): string =>
  state.authentication?.resetPhoneApproved;

export const selectValidAgencies = (state: State): ?(Agency[]) =>
  state.authentication?.validAgencies;

export const selectForgotPasswordError = (state: State): string =>
  state.authentication?.forgotPasswordError;

export const selectResetPasswordError = (state: State): string =>
  state.authentication?.resetPasswordError;

export const selectSignUpError = (state: State): string =>
  state.authentication?.signUpError;

export const selectOtpLimitExceeded = (state: State): boolean =>
  state.authentication?.otpLimitExceeded;
