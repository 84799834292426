// @flow strict
import * as React from 'react';
import OnboardHeader from 'src/components/agency-onboard/onboard-header/onboard-header.jsx';
//$FlowFixMe
import OnboardTasksContainer from 'src/components/agency-onboard/onboard-tasks-container/tasks-container.jsx';
import {ImplementationManager} from 'src/components/agency-onboard/implementation-manager/implementation-manager.jsx';

import css from './onboard-page.css';


export default function OnboardPage(): React.Node {
  return (
    <div className={css.onboardPageContainer}>
      <OnboardHeader />
      <OnboardTasksContainer />
      <ImplementationManager />
    </div>
  );
}
