// @flow strict
import * as React from 'react';
import {useHistory} from 'src/rerouter';

import {
  SubTitleLarge,
  BodyLarge,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {CONSTANTS} from 'src/components/authentication/contants.js';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';

import ResetImage from 'src/images/reset-confirmation.svg';
import css from './reset-confirmation.css';


const ResetConfirmation = (): React.Node => {
  const router = useHistory();

  const goToSignIn = () => {
    router.replace('/signin');
  };

  return (
    <div className={css.resetConfirmationPage}>
      <ResetImage />
      <SubTitleLarge className={css.titleText}>
        {CONSTANTS.RESET_PASSWORD_CONFIRMATION_TITLE}
      </SubTitleLarge>
      <BodyLarge className={css.infoText}>
        {CONSTANTS.RESET_PASSWORD_CONFIRMATION_SUBTEXT}
      </BodyLarge>
      <Button
        size="medium"
        type="primary"
        onClick={goToSignIn}
        classNames={{wrapper: css.loginBtn}}
      >
        Log in
      </Button>
    </div>
  );
};

export default ResetConfirmation;
