// @flow

import type {PhoneNumbersAction} from 'src/action-creators/phone-numbers';

import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';

import {
  RECEIVE_PHONE_NUMBERS,
  ADD_AGENT_PHONE_NUMBER_ASSIGNMENT,
  REMOVE_AGENT_PHONE_NUMBER_ASSIGNMENT,
  ADD_INVITE_PHONE_NUMBER_ASSIGNMENT,
  REMOVE_INVITE_PHONE_NUMBER_ASSIGNMENT,
  RECEIVE_PHONE_CAPABILITIES,
  SET_MESSAGING_CALL_FORWARDING_NUMBER,
  SET_FRIENDLY_NAME,
} from 'src/action-creators/phone-numbers';

import {selectDisplayPhoneNumber} from 'src/selectors/chat';


export type PhoneNumbersState = {
  user_invites: Array<{[invite_code: string]: {[key: string]: any}}>,
  user_accounts: Array<{[agent_id: string]: {[key: string]: any}}>,
  phone_numbers: {[provisioned_phone_id: string]: string},
  invite_assignments: {[invite_code: string]: Array<number>},
  agents: Array<{[agent_id: string]: {[key: string]: any}}>,
  agent_assignments: {[agent_id: string]: Array<number>},
  phone_capabilities: {[phone_number: string]: {[key: string]: boolean}},
  call_forwarding_numbers: {[provisioned_phone_id: string]: string},
  friendly_names: {[provisioned_phone_id: string]: string},
};

const phoneNumbersState = {
  user_invites: [],
  user_accounts: [],
  phone_numbers: Object.freeze({}),
  invite_assignments: Object.freeze({}),
  agents: [],
  agent_assignments: Object.freeze({}),
  phone_capabilities: Object.freeze({}),
  call_forwarding_numbers: Object.freeze({}),
  friendly_names: Object.freeze({}),
};

export default (
  state: PhoneNumbersState = phoneNumbersState,
  action: PhoneNumbersAction,
): PhoneNumbersState => {
  switch (action.type) {
    case RECEIVE_PHONE_NUMBERS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_MESSAGING_CALL_FORWARDING_NUMBER: {
      const {call_forwarding_number, provisioned_phone_id} = action.payload;
      return {
        ...state,
        call_forwarding_numbers: {
          ...state.call_forwarding_numbers,
          [provisioned_phone_id]: call_forwarding_number,
        },
      };
    }
    case SET_FRIENDLY_NAME: {
      const {friendly_name, provisioned_phone_id} = action.payload;
      return {
        ...state,
        friendly_names: {
          ...state.friendly_names,
          [provisioned_phone_id]: friendly_name,
        },
      };
    }
    case ADD_AGENT_PHONE_NUMBER_ASSIGNMENT: {
      const {agent_id, provisioned_phone_id} = action.payload;
      return {
        ...state,
        agent_assignments: {
          ...state.agent_assignments,
          [agent_id]: [
            ...(state.agent_assignments[agent_id] || []),
            provisioned_phone_id,
          ],
        },
      };
    }
    case REMOVE_AGENT_PHONE_NUMBER_ASSIGNMENT: {
      const {agent_id, provisioned_phone_id} = action.payload;
      return {
        ...state,
        agent_assignments: {
          ...state.agent_assignments,
          [agent_id]:
            state.agent_assignments[agent_id]?.filter(
              (id) => String(id) !== String(provisioned_phone_id),
            ) || [],
        },
      };
    }
    case ADD_INVITE_PHONE_NUMBER_ASSIGNMENT: {
      const {invite_code, provisioned_phone_id} = action.payload;
      return {
        ...state,
        invite_assignments: {
          ...state.invite_assignments,
          [invite_code]: [
            ...(state.invite_assignments[invite_code] || []),
            provisioned_phone_id,
          ],
        },
      };
    }
    case REMOVE_INVITE_PHONE_NUMBER_ASSIGNMENT: {
      const {invite_code, provisioned_phone_id} = action.payload;
      return {
        ...state,
        invite_assignments: {
          ...state.invite_assignments,
          [invite_code]:
            state.invite_assignments[invite_code]?.filter(
              (code) => String(code) !== String(provisioned_phone_id),
            ) || [],
        },
      };
    }
    case RECEIVE_PHONE_CAPABILITIES: {
      const {phoneNumber, capabilities} = action.payload;
      return {
        ...state,
        phone_capabilities: {
          [phoneNumber]: capabilities,
        },
      };
    }
  }
  return state;
};
