// @flow

import type {Params, RerouterLocation} from 'src/rerouter';
import type {Dispatch, GetState} from 'src/reducers';

import * as React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import {useLocation, useParams} from 'src/rerouter';
import {useStore} from 'react-redux';

import IndexStore from 'src/stores';
import nameHoc from './nameHoc';
import useFluxStore from './useFluxStore';
import {handleGenericApiError} from 'src/utils/errors';

/**
 * DO NOT USE THIS.
 * This is a legacy HOC for backwards compatibility.
 */
export function provideHooks<C>({
  required,
  deferred,
  clientSide,
}: {
  required?: (RedialArgs) => Promise<mixed>,
  deferred?: (RedialArgs) => Promise<mixed>,
  clientSide?: (RedialArgs) => Promise<mixed>,
}): (WrappedComponent: React.ComponentType<C>) => React.ComponentType<C> {
  return (WrappedComponent) =>
    nameHoc(
      hoistNonReactStatics((props) => {
        const location = useLocation();
        const params = useParams();
        const fluxStore = useFluxStore();
        const reduxStore = useStore();

        const [loaded, setLoaded] = React.useState(!required);

        React.useEffect(() => {
          let canceled = false;

          const redialArgs = {
            params,
            location,
            store: fluxStore,
            dispatch: reduxStore.dispatch,
            getState: reduxStore.getState,
          };

          const getDeferred = () => {
            if (!canceled) {
              deferred &&
                Promise.resolve(deferred(redialArgs)).catch(
                  handleGenericApiError,
                );
              clientSide &&
                Promise.resolve(clientSide(redialArgs)).catch(
                  handleGenericApiError,
                );
              setLoaded(true);
            }
          };

          if (required) {
            // TODO (kyle): handle errors
            Promise.resolve(required(redialArgs)).then(
              getDeferred,
              handleGenericApiError,
            );
          } else {
            getDeferred();
          }

          return () => {
            canceled = true;
          };
          // NOTE (kyle): this is a heuristic. we could hypothetically use `props.pathname`.
        }, [location.href]);

        return loaded ? <WrappedComponent {...props} /> : null;
      }, WrappedComponent),
      WrappedComponent,
      'provideHooks',
    );
}

type RedialArgs = {
  params: Params,
  location: RerouterLocation,
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  dispatch: Dispatch,
  getState: GetState,
};
