// @flow strict

import type {
  Category,
  Categories,
  CategoriesFilter,
  CategoryStatus,
  CategoryChannel,
  UserContentSubscriptions,
  UserContentSubscriptionsExternal,
  UserContentSubscriptionsExternalV2,
  SubscriptionRequestData,
  SubscriptionRequestDataV2,
} from 'src/types/content-subscription';

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';
import {
  parseSubscriptionToApi,
  parseSubscriptionExternalToApi,
  parseSubscriptionExternalToApiV2,
} from 'src/utils/content-subscription';
// $FlowFixMe[nonstrict-import]
import * as reduxApiBff from 'src/utils/redux-api-bff';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching, cached} from 'src/utils/redux';


type ReceiveCategoryDetailsAction = {
  type: 'category/receive/details',
  payload: Category,
};

type ReceiveAllCategoryAction = {
  type: 'category/receive/all',
  payload: Categories,
};

type ApplyFiltersAction = {
  type: 'category/apply/filters',
  payload: CategoriesFilter,
};

type CategoryReceiveAssociatedTouchpointsAction = {
  type: 'category/receive/associatedTouchpoints',
  payload: Category,
};

type CategoryReceiveUserSubscriptionAction = {
  type: 'user/receive/subscriptions',
  payload: {
    entityId: string,
    userContentSubscriptions: UserContentSubscriptions,
  },
};

type CategoryReceiveUserSubscriptionExternalAction = {
  type: 'user/receive/subscriptions/external',
  payload: {
    entityId: string,
    userContentSubscriptionsExternal: UserContentSubscriptionsExternal,
  },
};

type CategoryReceiveUserSubscriptionExternalActionV2 = {
  type: 'user/receive/subscriptions/external/V2',
  payload: {
    trackingId: string,
    userContentSubscriptionsExternal: UserContentSubscriptionsExternalV2,
  },
};

export type ContentSubscriptionAction =
  | ReceiveCategoryDetailsAction
  | ReceiveAllCategoryAction
  | ApplyFiltersAction
  | CategoryReceiveUserSubscriptionAction
  | CategoryReceiveUserSubscriptionExternalAction
  | CategoryReceiveUserSubscriptionExternalActionV2
  | CategoryReceiveAssociatedTouchpointsAction;

export const CategoryReceiveAll = 'category/receive/all';
export const CategoryReceiveDetails = 'category/receive/details';
export const CategoryApplyFilter = 'category/apply/filters';
export const CategoryReceiveAssociatedTouchpoints =
  'category/Receive/associatedTouchpoints';
export const CategoryActivate = 'category/activate';

export const UserReceiveSubscriptions = 'user/receive/subscriptions';
export const UserReceiveSubscriptionsExternal =
  'user/receive/subscriptions/external';

export const UserReceiveSubscriptionsExternalV2 =
  'user/receive/subscriptions/external/V2';

const receiveAllCategoryAction = (
  categories: Categories,
): ReceiveAllCategoryAction => ({
  type: CategoryReceiveAll,
  payload: categories,
});

const receiveCategoryDetailsAction = (
  category: Category,
): ReceiveCategoryDetailsAction => ({
  type: CategoryReceiveDetails,
  payload: category,
});

const receiveUserSubscriptionsAction = (
  entityId: string,
  userContentSubscriptions: UserContentSubscriptions,
): CategoryReceiveUserSubscriptionAction => ({
  type: UserReceiveSubscriptions,
  payload: {
    entityId,
    userContentSubscriptions,
  },
});

const receiveUserSubscriptionsActionExternal = (
  entityId: string,
  userContentSubscriptionsExternal: UserContentSubscriptionsExternal,
): CategoryReceiveUserSubscriptionExternalAction => ({
  type: UserReceiveSubscriptionsExternal,
  payload: {
    entityId,
    userContentSubscriptionsExternal,
  },
});

const receiveUserSubscriptionsActionExternalV2 = (
  trackingId: string,
  userContentSubscriptionsExternal: UserContentSubscriptionsExternalV2,
): CategoryReceiveUserSubscriptionExternalActionV2 => ({
  type: UserReceiveSubscriptionsExternalV2,
  payload: {
    trackingId,
    userContentSubscriptionsExternal,
  },
});

export const getCategories =
  (): ThunkAction<Categories> => async (dispatch: Dispatch) => {
    const categories: Categories = await dispatch(
      reduxApi.get(`content-subscription-category`),
    );
    return dispatch(receiveAllCategoryAction(categories));
  };

export const getCategoryDetails =
  (categoryId: string): ThunkAction<Categories> =>
  async (dispatch: Dispatch) => {
    const category: Category = await dispatch(
      reduxApi.get(`content-subscription-category/${categoryId}`),
    );
    return dispatch(receiveCategoryDetailsAction(category));
  };

export const applyFilters = (
  filters: CategoriesFilter,
): ApplyFiltersAction => ({
  type: CategoryApplyFilter,
  payload: filters,
});

export const addNewCategory =
  (
    name: string,
    description: string,
    unsubscribeMessage: string,
    channel: CategoryChannel,
  ): ThunkAction<Categories> =>
  (dispatch: Dispatch) =>
    dispatch(
      reduxApi.post(`content-subscription-category`, {
        name,
        description,
        channel,
        unsubscribe_message: unsubscribeMessage,
      }),
    );

export const editCategory =
  (
    categoryId: string,
    params: {
      name: string,
      description: string,
      status: CategoryStatus,
      unsubscribeMessage: string,
      channel: CategoryChannel,
    },
  ): ThunkAction<Categories> =>
  (dispatch: Dispatch) =>
    dispatch(
      reduxApi.post(`content-subscription-category/${categoryId}`, {
        status: params.status,
        name: params.name,
        description: params.description,
        channel: params.channel,
        unsubscribe_message: params.unsubscribeMessage,
      }),
    );

export const getUserContentSubscriptions =
  (
    entityType: string,
    entityId: string,
  ): ThunkAction<UserContentSubscriptions> =>
  async (dispatch: Dispatch) => {
    const userContentSubscriptions: UserContentSubscriptions = await dispatch(
      reduxApi.get(
        `entity/${entityType}/${entityId}/content-subscription-category`,
      ),
    );

    return dispatch(
      receiveUserSubscriptionsAction(entityId, userContentSubscriptions),
    );
  };

export const getUserContentSubscriptionsExternalV2 =
  (trackingId: string): ThunkAction<UserContentSubscriptionsExternalV2> =>
  async (dispatch: Dispatch) => {
    const userContentSubscriptions: UserContentSubscriptionsExternalV2 =
      await dispatch(
        reduxApiBff.get(`email/subscriptions/external/${trackingId}`),
      );
    return dispatch(
      receiveUserSubscriptionsActionExternalV2(
        trackingId,
        userContentSubscriptions,
      ),
    );
  };

export const getUserContentSubscriptionsExternal =
  (
    entityType: string,
    entityId: string,
    attributeName: string,
  ): ThunkAction<UserContentSubscriptionsExternal> =>
  async (dispatch: Dispatch) => {
    const userContentSubscriptions: UserContentSubscriptionsExternal =
      await dispatch(
        reduxApi.get(
          `entity/${entityType}/${entityId}/content-subscription-category/external/${attributeName}`,
        ),
      );

    return dispatch(
      receiveUserSubscriptionsActionExternal(
        entityId,
        userContentSubscriptions,
      ),
    );
  };

export const saveUserSubscription =
  (
    entityType: string,
    entityId: string,
    userContentSubscriptions: UserContentSubscriptions,
    external: boolean = false,
  ): ThunkAction<UserContentSubscriptions> =>
  async (dispatch: Dispatch) => {
    const apiData = parseSubscriptionToApi(userContentSubscriptions);

    // (nilarnab) either send a changelog or a diff
    // this is inefficient for the API

    await dispatch(
      saveUserSubscriptionApi(entityType, entityId, apiData, external),
    );

    return dispatch(getUserContentSubscriptions(entityType, entityId));
  };

export const markNumberDefaultForUser =
  (
    category_id: number,
    agent_id: string,
    phone_number: string,
  ): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    await dispatch(
      reduxApi.post('provisioned-phone/mark-default', {
        category_id,
        agent_id,
        phone_number,
      }),
    );
  };

export const setFallbackNumberForCategory =
  (category_id: number, phone_number: string): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    await dispatch(
      reduxApi.post('provisioned-phone/set-fallback', {
        category_id,
        phone_number,
      }),
    );
  };

export const addNumbersToCategory =
  (category_id: number, phone_numbers: Array<string>): ThunkAction<void> =>
  async (dispatch: Dispatch) =>
    dispatch(
      reduxApi.post('provisioned-phone/update-category', {
        category_id,
        phone_numbers,
      }),
    );

export const saveUserSubscriptionExternalV2 =
  (
    userContentSubscriptions: UserContentSubscriptionsExternal,
    trackingId: string,
  ): ThunkAction<UserContentSubscriptions> =>
  async (dispatch: Dispatch) => {
    const apiData = parseSubscriptionExternalToApiV2(userContentSubscriptions);
    return dispatch(saveUserSubscriptionApiV2(apiData, trackingId, true));
  };
export const saveUserSubscriptionExternal =
  (
    entityType: string,
    entityId: string,
    userContentSubscriptions: UserContentSubscriptionsExternal,
    external: boolean = false,
    sentEventId: string,
    // eslint-disable-next-line max-params
  ): ThunkAction<UserContentSubscriptions> =>
  async (dispatch: Dispatch) => {
    const apiData = parseSubscriptionExternalToApi(
      userContentSubscriptions,
      sentEventId,
    );

    // (nilarnab) either send a changelog or a diff
    // this is inefficient for the API

    return dispatch(
      saveUserSubscriptionApi(entityType, entityId, apiData, external),
    );
  };

export const saveUserSubscriptionApiV2 =
  (
    apiData: SubscriptionRequestDataV2,
    trackingId: string,
    external: boolean = false,
  ): ThunkAction<UserContentSubscriptions> =>
  async (dispatch: Dispatch) =>
    await dispatch(
      reduxApiBff.post(
        `email/subscriptions${external ? '/external' : ''}/${trackingId}`,
        apiData,
      ),
    );
export const saveUserSubscriptionApi =
  (
    entityType: string,
    entityId: string,
    apiData: SubscriptionRequestData,
    external: boolean = false,
  ): ThunkAction<UserContentSubscriptions> =>
  async (dispatch: Dispatch) =>
    await dispatch(
      reduxApi.post(
        `entity/${entityType}/${entityId}/content-subscription-category${
          external ? '/external' : ''
        }`,
        apiData,
      ),
    );
export const getContentSubscriptionCategories: () => ThunkAction<Categories> =
  flow(
    key(() => 'getContentSubscriptionCategories'),
    cached((response) => receiveAllCategoryAction(response), {
      ttl: 60 * 60 * 1000,
    }),
    fetching(),
  )(() => reduxApiBff.get(`subscription/content-subscription-category`));
