// @noflow

import type {ContactList, ContactCsvValue} from 'src/types/contacts';

import * as React from 'react';
import {connect} from 'react-redux';

import {parseContactsCsv, clearCsv} from 'src/action-creators/contacts';
import {uploadCsv} from 'src/action-creators/contact-csv';
import {inflight, progress} from 'src/utils/redux';

import FilePage from './csv/file-page.jsx';
import PreviewPage from './csv/preview-page.jsx';
import ProgressPage from './csv/progress.jsx';

import css from './csv-modal.css';


const mapStateToProps = (state, {contactListId}) => ({
  processing: inflight(state, parseContactsCsv()),
  progress: progress(state, parseContactsCsv()),
  csv: state.contacts.csv,
  contactList: contactListId && state.contactLists.lists[contactListId],
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleFileChange: ({currentTarget: {files}}) => {
    dispatch(parseContactsCsv(files[0]));
  },
  handleSubmit: async () => {
    await dispatch(uploadCsv(ownProps.contactListId));
    ownProps.removeModal();
  },
  handleUnmount: () => dispatch(clearCsv()),
});

class CsvModal extends React.Component<
  {
    dispatch: Dispatch,
    contactListId?: string,
    contactList?: ContactList,
    processing: ?boolean,
    progress: ?number,
    csv: ContactCsvValue,
    handleFileChange: (event: SyntheticEvent<HTMLInputElement>) => void,
    handleSubmit: () => Promise<any>,
    handleUnmount: () => void,
    removeModal: () => void,
    uploadLimit: number,
  },
  {},
> {
  componentWillUnmount() {
    this.props.handleUnmount();
  }

  render() {
    const {
      contactList,
      processing,
      progress,
      csv,
      handleFileChange,
      handleSubmit,
      removeModal,
      uploadLimit,
    } = this.props;

    let page;
    if (processing) {
      page = <ProgressPage progress={progress} />;
    } else if (csv?.results) {
      page = (
        <PreviewPage
          contactList={contactList}
          results={csv.results}
          onFileChange={handleFileChange}
          onSubmit={handleSubmit}
          removeModal={removeModal}
        />
      );
    } else {
      page = (
        <FilePage
          errors={csv?.errors ?? undefined}
          onFileChange={handleFileChange}
          onCancel={this.handleFileCancel}
          uploadLimit={uploadLimit}
        />
      );
    }

    return (
      <div className={css.root}>
        <span className={css.title}>Upload CSV</span>
        {page}
      </div>
    );
  }

  handleFileCancel = () => {
    this.props.removeModal();
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CsvModal);
