// @flow strict

import * as React from 'react';

import classify from 'src/utils/classify';

import css from './hover-icon-button.css';


type ButtonProps = $ReadOnly<{
  className: string,
  onClick?: () => mixed,
  style?: ?{[string]: string | number, ...},
  ...
}>;

type Props = {
  ElementType?: React.AbstractComponent<ButtonProps, HTMLElement> | string,
  children: React.Node,
  width?: number,
  height?: number,
  padding?: number,
  onClick?: (SyntheticEvent<>) => mixed,
  bgIconClassName?: string,
  className?: string,
  active?: boolean,
  hoverDisabled?: boolean,
  dimensions?: 'full' | 'icon',
  ['aria-label']: string,
};

// NOTE (kyle): this component allows changing the underlying element so
// HTMLElement will not always hold true if you're using a custom component.
// Declaring an ElementType and sending properties it will fail a Flow check.
// To fix this, we'll need to create a component factory with a generic
// ElementType.
export const HoverIconButton: React$AbstractComponent<Props, HTMLElement> =
  React.forwardRef<Props, HTMLElement>((props, ref) => {
    const {
      ElementType = 'button',
      children,
      onClick,
      width = 24,
      height = 24,
      padding = 0,
      className,
      bgIconClassName,
      hoverDisabled = false,
      active = false,
      dimensions = 'icon',
      ...rest
    } = props;
    const minDiameter = Math.max(width, height);

    return (
      // $FlowFixMe[incompatible-type]
      <ElementType
        {...rest}
        ref={ref}
        type="button"
        role="button"
        className={classify(css.hoverIcon, className)}
        // $FlowFixMe
        onClick={onClick}
        style={
          dimensions === 'full'
            ? {
                width: minDiameter,
                height: minDiameter,
              }
            : null
        }
      >
        <div className={css.hoverIconRoot}>
          <div className={css.hoverIconBgContainer}>
            <div
              className={classify(
                css.bgIconClassName,
                {
                  [css.hoverIconBg]: !hoverDisabled,
                  [css.hoverIconBgActive]: active,
                },
                bgIconClassName,
              )}
              style={{
                width: minDiameter,
                height: minDiameter,
                borderRadius: minDiameter,
              }}
            />
          </div>
          <div className={css.hoverIconIcon}>{children}</div>
        </div>
      </ElementType>
    );
  });
