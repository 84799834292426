// @noflow

/**
 * Warning:
 * Do not wrap SegmentedButton in a label. For simplicity's sake,
 * SegmentedButton uses an internal label tag to handle click delegation to the
 * hidden input, and nesting labels is a bad idea.
 *
 * be careful using boolean values for V as they will be coerced to strings
 * when you extract them from event.currentTarget in the onchange handler.
 * your best bet is to _not_ use them and instead normalize in your onChange
 */

import * as React from 'react';
import invariant from 'invariant';
import uniqueId from 'lodash/uniqueId';

import {classify} from 'src/utils';

import css from './segmented-button.css';


type Props<V> = {
  className: ?string,
  disabled?: boolean,
  options: {
    label: string,
    value: V,
  }[],
  defaultValue?: any,
  value?: any,
  name?: string,
  type?: 'radio' | 'checkbox',
  onChange?: (
    event: SyntheticEvent<
      HTMLInputElement & {
        currentTarget: {value: V},
      },
    >,
  ) => void,
};

function SegmentedButton<T: string>(props: Props<T>) {
  const [inputName, setInputName] = React.useState(
    props.name || uniqueId('segmented'),
  );

  React.useEffect(() => {
    if (props.name == null) {
      setInputName(uniqueId('segmented'));
    }
  }, [props.name]);

  const {
    disabled,
    options,
    className,
    name,
    value: currentValue,
    defaultValue,
    onChange,
    type = 'radio',
  } = props;

  invariant(
    options.length > 1,
    'Segmented button requires more than 1 option.',
  );
  return (
    <div className={classify(css.container, className)}>
      {options.map(({label, value}) => {
        let isChecked = false;
        if (type === 'radio') {
          isChecked = currentValue != null && currentValue === value;
        }
        if (type === 'checkbox') {
          isChecked = currentValue.includes(value);
        }

        return (
          <label
            className={css.label}
            key={value}
            data-qa-id={`${value}-tab-button`}
          >
            <input
              type={type}
              className={css.input}
              name={name || inputName}
              value={value}
              checked={isChecked}
              defaultChecked={
                currentValue === undefined && defaultValue !== undefined
                  ? defaultValue === value
                  : undefined
              }
              onChange={onChange}
              disabled={disabled}
            />
            <div className={css.segment}>{label}</div>
          </label>
        );
      })}
    </div>
  );
}

export default React.memo<Props<string>>(SegmentedButton);
