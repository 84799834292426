// @noflow
import type {AudienceMember} from 'src/api-parsers/index';
export type Responder = AudienceMember;

import Store from './base';


export default class ResponderStore extends Store {
  state: {
    fetching: ?Promise<void>,
    responders: {[key: string] : Responder},
  };

  constructor() {
    super('responder');

    this.state = {
      fetching: null,
      responders: {},
    };
  }

  startFetching(promise: Promise<void>) {
    this.setState({
      fetching: promise,
    });
  }

  receiveResponder(responder: AudienceMember) {
    this.updateState({
      fetching: {$set: null},
      responders: {
        [responder.id]: {$set: responder},
      },
    });
  }

  byId(id: string): ?Responder {
    return this.state.responders[id];
  }
}
