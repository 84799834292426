// @flow strict
import * as React from 'react';
import classify from 'src/utils/classify';
// $FlowFixMe[nonstrict-import]
import ClickAway from 'src/components/lib/click-away';
import MenuButton from './menu-buttons.jsx';
import {type OptionProps, Options, DefaultOption} from './options.jsx';

import css from './menu.css';


type MenuProps<V> = {
  options: V[],
  label?: React.Node,
  isOpen?: boolean,
  resolveLabel?: (V) => string | React.Node,
  resolveValue?: (V) => string,
  resolveIcon?: (V) => React.Node,
  resolveDisabled?: (V, V[]) => boolean,
  onSelect: (V) => mixed,
  onChange?: (boolean) => mixed,
  trigger?: React.Node,
  value?: string | string[],
  classNames?: {
    container?: string,
    options?: string,
    button?: string,
    option?: string,
    triggerContainer?: string,
    optionContainer?: string,
  },
  anchorPosition?: 'left' | 'right' | 'top',
  size?: 'default' | 'small',
  type?: 'primary' | 'secondary' | 'tertiary',
  disabled?: boolean,
  //user is responsible for wiring things up properly if he/she
  //decided to pass the OptionComponent. This is an advance option
  //usually wont be needed. DefaultOption should suffice.
  OptionComponent?: React.ComponentType<OptionProps<V>>,
};

export const Menu = <
  V:
    | {label: string, value: string, ...}
    | {label: string, value: string, icon?: React.Node, ...}
    | {label: string, value: string, disabled?: boolean, ...}
    | {label: string, value: string, labelDisplay?: React.Node, ...},
>({
  options,
  label,
  classNames,
  resolveLabel = (item) => item.label,
  resolveValue = (item) => item.value,
  resolveIcon = (_) => null,
  resolveDisabled = (item, _) =>
    typeof item.disabled === 'boolean' ? item.disabled : false,
  size = 'default',
  type = 'secondary',
  disabled = false,
  trigger = (
    <MenuButton
      type={type}
      size={size}
      disabled={disabled}
      className={classNames?.button}
    >
      {label}
    </MenuButton>
  ),
  onSelect,
  onChange,
  value,
  anchorPosition = 'left',
  isOpen: propIsOpen,
  OptionComponent = DefaultOption,
}: MenuProps<V>): React.Node => (
  <ClickAway onChange={onChange}>
    {({isOpen, onOpen, cancelNext, clickAway}) => {
      const controlledIsOpen = propIsOpen ?? isOpen;
      return (
        <div className={classify(css.container, classNames?.container)}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              onOpen();
            }}
            className={classNames?.triggerContainer}
          >
            {trigger}
          </div>
          {/* hide options if button is disabled (discussed with swatantra)*/}
          {controlledIsOpen && !disabled && (
            <div
              className={classify(css.options, classNames?.optionContainer, {
                [css.left]: anchorPosition === 'left',
                [css.right]: anchorPosition === 'right',
                [css.top]: anchorPosition === 'top', //show menu on top of button
                [css.small]: size === 'small',
              })}
              onClickCapture={cancelNext}
            >
              <Options
                options={options}
                value={value}
                resolveValue={resolveValue}
                resolveLabel={resolveLabel}
                resolveIcon={resolveIcon}
                onSelect={(option) => {
                  if (!resolveDisabled(option, options)) {
                    onSelect(option);
                    clickAway();
                  }
                }}
                classNames={{
                  options: classNames?.options,
                  option: classNames?.option,
                }}
                OptionComponent={OptionComponent}
                resolveDisabled={resolveDisabled}
              />
            </div>
          )}
        </div>
      );
    }}
  </ClickAway>
);

export default Menu;
