// @flow strict

import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'src/rerouter';
import {useApi} from 'src/hooks/useApi';
import {Loading} from 'src/components/lib/loading';
import {CONSTANTS} from 'src/components/authentication/contants.js';
import {TitleMedium} from '@spaced-out/ui-design-system/lib/components/Text';

import css from './phone-deregistration.css';

import {resetPhoneApprove} from 'src/action-creators/authentication.js';

import {
  selectResetPhoneApproveError,
  selectResetPhoneApproved,
} from 'src/selectors/authentication';


export const ApprovePhoneReset = (): React.Element<'div'> => {
  const {token} = useParams();
  const dispatch = useDispatch();
  const apiError = useSelector(selectResetPhoneApproveError);
  const apiResponse = useSelector(selectResetPhoneApproved);
  const handlePhoneResetApproval = async () => {
    await dispatch(resetPhoneApprove(token));
  };
  handlePhoneResetApproval();
  return (
    <div className={css.mainContainer}>
      <div className={css.container}>
        {apiError ? (
          <TitleMedium>{apiError}</TitleMedium>
        ) : (
          <TitleMedium>{apiResponse}</TitleMedium>
        )}
      </div>
    </div>
  );
};
