// @flow

// $FlowFixMe[untyped-type-import]
import type {Range} from 'src/utils/date-time';
import type {EntityFilter} from 'src/types/ats-settings';

import * as React from 'react';
import {Link} from 'src/rerouter';
import startCase from 'lodash/startCase';
import chunk from 'lodash/chunk';
import groupBy from 'lodash/groupBy';

import {classify, formatCurrency} from 'src/utils';
import {prettyBullhornLabel} from 'src/utils/entities';
import {useMultiEntityEnabled} from 'src/hooks/useAgencyConfig';
import {useReleaseFlag} from 'src/hooks/product-flags';

import {FilterCard} from './';
import {StringSelectList} from 'src/components/lib/token-list-input';
import {
  ValueListInput,
  type ValueListInputProps,
} from 'src/components/lib/token-list-input/new-stuff.jsx';
import {SimpleDateRangePicker} from 'src/components/lib/date-range-picker/date-range-picker.jsx';
import Slider from 'src/components/lib/slider';
import TypeaheadSelectList from 'src/components/typeahead/select-list.jsx';

import css from './more-filters-card.css';


type Props = {
  filters: EntityFilter[],
  onSave?: (e: SyntheticEvent<*>) => mixed,
  onCancel?: (e: SyntheticEvent<*>) => mixed,
  query?: Object,
  onValueChange: (key: string) => <T>(value: T) => mixed,
  showSettingsLink: ?string,
  filteredFromAddresses?: string[],
  onChangeFromAddress?: (values: string[]) => mixed,
  showInternalUserFilter?: boolean,
  direction?: 'right' | 'left',
};

export default function MoreFiltersCard(props: Props): React.Node {
  const {
    filters,
    onSave,
    onCancel,
    onValueChange,
    showSettingsLink,
    onChangeFromAddress,
    filteredFromAddresses,
    showInternalUserFilter,
    direction = 'right',
  } = props;

  const multiEntityEnabled = useMultiEntityEnabled();
  const showSentFrom = useReleaseFlag('fromAddressFilter');

  const columns = React.useMemo(() => {
    const sortedFilters = filters
      .slice()
      .sort(({entity_type: a}, {entity_type: b}) => a.localeCompare(b));

    return chunk(
      sortedFilters.map((filter, index, filters) => ({
        filter,
        lastOfType:
          (filters[index + 1] &&
            filter.entity_type !== filters[index + 1].entity_type) ||
          filters.length - 1 === index,
      })),
      Math.ceil(sortedFilters.length / 4),
    );
  }, [filters]);

  return (
    <FilterCard
      className={classify(css.containerWrap, {
        [css.left]: direction === 'left',
      })}
      header="more filters"
      onSave={onSave}
      onCancel={onCancel}
    >
      {!showInternalUserFilter &&
        showSentFrom &&
        onChangeFromAddress &&
        filteredFromAddresses != null && (
          <div>
            <div className={css.section}>
              <h5 className={css.entityFilterType}>Sent From</h5>
              <TypeaheadSelectList
                typeaheadType="eventResources"
                typeaheadName="sent_event"
                typeaheadSubname="from_address"
                placeholder="from email address"
                classNames={{
                  outerContainer: css.selectListContainer,
                  container: css.input,
                }}
                values={filteredFromAddresses}
                onChange={onChangeFromAddress}
              />
            </div>
          </div>
        )}
      <div className={css.columnWrap}>
        {columns.length === 0 && (
          <section className={classify(css.section, css.center)}>
            <h4 className={css.emptyTitle}>No Filters Yet</h4>
            <span className={css.emptyText}>
              Create your own custom filters by
              <br />
              adding them on the ATS Settings page
            </span>
            {showSettingsLink && (
              <Link
                to={`/settings/ats/fields/${showSettingsLink}`}
                className={css.settingsLink}
              >
                Add Filter in Settings
              </Link>
            )}
          </section>
        )}
        {columns.map((column, index) => {
          const groupedByEntity = groupBy(column, 'filter.entity_type');
          return (
            <div className={css.column} key={index}>
              {Object.keys(groupedByEntity).map((entityGroup) => (
                <div className={css.section} key={entityGroup}>
                  <h5 className={css.entityFilterType}>
                    {groupedByEntity[entityGroup][0]?.filter
                      ?.entity_type_display_name ||
                      prettyBullhornLabel(entityGroup)}{' '}
                    Fields
                  </h5>
                  {groupedByEntity[entityGroup].map(
                    ({filter: filterInfo, lastOfType}) => {
                      const {display_name, attribute_name} = filterInfo;
                      const filterName = `${entityGroup}/${attribute_name}`;
                      const editSettingsLink =
                        showSettingsLink && lastOfType ? (
                          <div className={css.actionRow}>
                            <Link
                              to={`/settings/ats/fields/${entityGroup}`}
                              className={css.settingsLink}
                            >
                              {`Edit ${prettyBullhornLabel(
                                entityGroup,
                              )} Fields in Settings`}
                            </Link>
                          </div>
                        ) : null;

                      let filter;
                      if (filterInfo.data_type === 'currency') {
                        filter = (
                          <Slider
                            className={css.filter}
                            min={filterInfo.min}
                            max={filterInfo.max}
                            {...filterInfo.selected}
                            valueFormatter={formatCurrency}
                            unit="/hr. +"
                            onChange={onValueChange(filterName)}
                            step={0.01}
                          />
                        );
                      } else if (filterInfo.data_type === 'date') {
                        const {min, max} = filterInfo;
                        filter = (
                          <SimpleDateRangePicker
                            className={css.filter}
                            {...props}
                            value={
                              filterInfo.selected || {
                                startDate: min,
                                endDate: max,
                              }
                            }
                            minDate={min}
                            maxDate={max}
                            onChange={onValueChange(filterName)}
                          />
                        );
                      } else if (Array.isArray(filterInfo.values)) {
                        const props = {
                          classNames: {
                            outerContainer: css.selectListContainer,
                            container: css.input,
                          },
                          onChange: onValueChange(filterName),
                        };
                        if (filterInfo.data_type === 'boolean') {
                          filter = (
                            <SingleValueInput
                              {...props}
                              options={filterInfo.values || []}
                              values={filterInfo.selected || []}
                              type={Boolean}
                            />
                          );
                        } else if (filterInfo.data_type === 'number') {
                          filter = (
                            <SingleValueInput
                              {...props}
                              options={filterInfo.values || []}
                              values={filterInfo.selected || []}
                              type={Number}
                            />
                          );
                        } else {
                          filter = (
                            <StringSelectList
                              {...props}
                              options={filterInfo.values || []}
                              values={filterInfo.selected || []}
                            />
                          );
                        }
                      } else if (filterInfo.values === null) {
                        const extraProps = multiEntityEnabled
                          ? {
                              typeaheadType: 'entityAttribute',
                              typeaheadName: entityGroup,
                              typeaheadSubname: attribute_name,
                            }
                          : {
                              typeaheadType: 'attribute',
                              typeaheadName: filterName,
                            };
                        if (
                          filterInfo.data_type !== 'boolean' &&
                          filterInfo.data_type !== 'number'
                        ) {
                          filter = (
                            <TypeaheadSelectList
                              {...extraProps}
                              classNames={{
                                outerContainer: css.selectListContainer,
                                container: css.input,
                              }}
                              values={filterInfo.selected || []}
                              onChange={onValueChange(filterName)}
                              placeholder={'Type to search'}
                            />
                          );
                        }
                      }
                      if (!filter) {
                        return;
                      }
                      return (
                        <section className={css.section} key={display_name}>
                          <h4 className={css.subhead}>
                            {startCase(display_name)}
                          </h4>
                          {filter}
                          {editSettingsLink}
                        </section>
                      );
                    },
                  )}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </FilterCard>
  );
}

function SingleValueInput<T>(props: ValueListInputProps<T>) {
  return (
    <ValueListInput {...props} limit={1} searchable allowArbitraryValues />
  );
}
