// @flow strict

// $FlowFixMe[nonstrict-import]
import type {ThunkAction} from 'src/reducers';
import type {DisplayConfig} from 'src/types/display-config';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, cached, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2.js';


export const RECEIVE_CONFIG = 'configurations/display';

export type Action = ReceiveDisplayConfigAction;

export type ReceiveDisplayConfigAction = {
  type: 'configurations/display',
  payload: DisplayConfig,
};

export const receiveConfig = (
  payload: DisplayConfig,
): ReceiveDisplayConfigAction => ({
  type: RECEIVE_CONFIG,
  payload,
});

export const getDisplayConfig: () => ThunkAction<void> = flow(
  key(() => 'displayConfig'),
  cached((json) => receiveConfig(json), {ttl: 6 * 60 * 60 * 1000}),
  fetching(),
)(() => reduxApi.get('configurations/display'));
