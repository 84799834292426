// @flow strict

import * as React from 'react';
import {classify} from 'src/utils/classify';
import {useSelector} from 'react-redux';
import css from 'src/designSystem2021Components/banner/banner-card/banner-card.css';
import {Icon} from '@spaced-out/ui-design-system/lib/components/Icon';
import {
  BodyMedium,
  ButtonTextMedium,
} from '@spaced-out/ui-design-system/lib/components/Text';


export type BannerCardProps = {
  warningType: string,
  bannerText: string,
  showResolveButton: boolean,
  handleResolve: () => Promise<void>,
  handleClose: () => void,
  onMouseEnter: () => void,
  onMouseLeave: () => void,
};

export const BannerCard = ({
  warningType,
  bannerText,
  showResolveButton,
  handleResolve,
  handleClose,
  onMouseEnter,
  onMouseLeave,
}: BannerCardProps): React.Node => {
  const contentWarningTextType = useSelector(
    (state) => state.messages.contentWarningTextType,
  );
  const contentWarningTextValue = useSelector(
    (state) => state.messages.contentWarningTextValue,
  );

  return (
    <div
      className={classify(css.bannerCardContainer, {
        [css.hover]:
          contentWarningTextType === warningType && contentWarningTextValue,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      warningType={warningType}
    >
      <div className={css.bannerIconAndText}>
        <Icon
          color="warning"
          name="circle-exclamation"
          size="small"
          type="regular"
        />
        <BodyMedium color="primary">{bannerText}</BodyMedium>
      </div>
      <div>
        {showResolveButton && (
          <div className={css.bannerCardButton} onClick={handleResolve}>
            <ButtonTextMedium color="warning">Resolve</ButtonTextMedium>
          </div>
        )}
        <Icon
          className={css.bannerCardCloseIcon}
          color="secondary"
          name="close"
          onClick={handleClose}
          size="small"
          type="regular"
        />
      </div>
    </div>
  );
};
