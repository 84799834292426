// @flow

export type ServerRuntimeEnvironment = 'production' | 'development' | 'staging';

export type EnvState = {
  hostname: string,
  agencySlug: string,
  apiHost: string,
  serverRuntimeEnvironment: ServerRuntimeEnvironment,
  release: string, // a git sha (used in staging/prod, otherwise 'dev')
  query: {
    [string]: string,
  },
};

export const RECEIVE: 'env/receive' = 'env/receive';

export type ReceiveEnvAction = {
  type: typeof RECEIVE,
  payload: EnvState,
};

export type EnvAction = ReceiveEnvAction;

export const receive = (payload: EnvState): ReceiveEnvAction => ({
  type: RECEIVE,
  payload,
});
