// @noflow

import * as React from 'react';

import {classify} from 'src/utils';

import ClickAway from 'src/components/lib/click-away';
import BranchIcon from 'src/images/branch.svg';

import css from './add.css';


type Props = {
  onClick: () => void,
  onSelectNewBranchType: Function,
};

const TabNew = ({onClick, ...props}: Props) => (
  <div className={css.container} onClick={onClick}>
    <ClickAway>
      {(clickAwayProps) => <MenuNew {...props} {...clickAwayProps} />}
    </ClickAway>
  </div>
);

export default TabNew;

type MenuProps = {
  onOpen: () => void,
  isOpen: boolean,
  onSelectNewBranchType: (type: string) => void,
  className?: string,
  moduleBelongsToBranchedEvent: boolean,
};

const MenuNew = ({
  onOpen,
  isOpen,
  onSelectNewBranchType,
  className,
  moduleBelongsToBranchedEvent,
}: MenuProps) => {
  const newBranchOptions = getNewBranchOptions(moduleBelongsToBranchedEvent);
  return (
    <div className={classify(isOpen ? css.menuOpen : css.menu, className)}>
      <div className={css.tab} onClick={!isOpen ? onOpen : null}>
        <span className={css.tabText}>Add Branch</span>
        <BranchIcon />
      </div>
      {isOpen && (
        <div className={css.options}>
          {newBranchOptions.map(({label, value}) => (
            <button
              className={css.option}
              key={value}
              onClick={() => onSelectNewBranchType(value)}
            >
              {label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const newBranchOptions = [
  {
    value: 'module_branch',
    label: 'Modules',
  },
  {
    value: 'event_branch',
    label: 'Touchpoint',
  },
];

//if parent is a branch touchpoint, it can not have more branched touchpoints
//though it can have more branched modules. the backend does not support as of
//now see ENGAGE-9036
function getNewBranchOptions(moduleBelongsToBranchedEvent) {
  if (moduleBelongsToBranchedEvent) {
    return newBranchOptions.filter(({value}) => value !== 'event_branch');
  }
  return newBranchOptions;
}
