// @flow strict
import * as React from 'react';
import {Input as InputField} from 'src/designSystem2021Components/input.jsx';
import type {Props} from 'src/designSystem2021Components/input.jsx';
import {Smallest} from 'src/designSystem2021Components/text-v2.jsx';

import css from './input.css';


export const Input = (props: Props): React.Node => {
  const {
    value,
    onChange,
    onFocus,
    onBlur,
    name,
    disabled,
    placeholder,
    error,
    errorText,
    label,
    contextLabel,
    boxClassName,
    ...inputProps
  } = props;

  return (
    <InputField
      disabled={disabled}
      name={name}
      placeholder={placeholder}
      boxClassName={boxClassName}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      error={error}
      errorText={errorText}
      label={<Smallest className={css.labelClass}>{label}</Smallest>}
      {...inputProps}
    />
  );
};
