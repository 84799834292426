// @noflow

import * as React from 'react';
import {Link} from 'src/rerouter';
import startCase from 'lodash/startCase';

import TruncatedText from 'src/components/lib/truncated-text';

import ArrowLeft from 'src/images/arrow-left.svg';

import css from './side.css';


const AnalyticsEngagementCohortsDetail = ({location}: {location: Location}) => {
  const match = location.pathname.match(/\/cohorts\/([^\/])/);
  const analyticsField = match && match[1];
  return (
    <div className={css.detail}>
      <Link
        className={css.detailWorkflowTitle}
        to={{
          ...location,
          pathname: `/analytics`,
        }}
      >
        <ArrowLeft className={css.backArrow} />
        <TruncatedText
          text={`Engagement By ${startCase(analyticsField || 'Cohort')}`}
          limit={25}
        />
      </Link>
      <section className={css.detailSection}>
        <div className={css.detailTitle}>Details</div>
        <div className={css.detailItems}>
          <Link
            className={css.detailItem}
            activeClassName={css.detailItemOn}
            onlyActiveOnIndex
            to={{
              ...location,
              pathname: `/analytics/cohorts/${analyticsField}`,
            }}
          >
            Graph
          </Link>
          <Link
            className={css.detailItem}
            activeClassName={css.detailItemOn}
            to={{
              ...location,
              pathname: `/analytics/cohorts/${analyticsField}/audience-list`,
            }}
          >
            Audience List
          </Link>
        </div>
      </section>
    </div>
  );
};

export default AnalyticsEngagementCohortsDetail;
