// @flow

import type {Result as ZxcvbnData} from 'zxcvbn';

import * as React from 'react';

import {values} from 'src/utils/object';
import {classify} from 'src/utils';
import css from './password-confirmation.css';
import {MouseTip} from 'src/components/lib/mouse-tip/mouse-tip.jsx';
import Input from 'src/components/lib/error-input';
import InfoIcon from 'src/images/icons/get-info.svg';

// replaced on componentDidMount because it's a large-ish module
let zxcvbn = (_passwordString: string, _extra?: string[]): ?ZxcvbnData =>
  undefined;

export type ErrorNameDescMap = {[errorName: string]: string};

export type ErrorsByField = {
  newPassword?: ErrorNameDescMap,
  confirmPassword?: ErrorNameDescMap,
  ...
};

type ErrorListProps = {
  errors: Array<[string, string | React.Node]>,
  className: string,
};

export const ErrorList = ({
  errors,
  className,
}: ErrorListProps): React.Element<'ul'> => (
  <ul className={className}>
    {errors.map(([errorName, html]) => (
      <li key={errorName}>{html}</li>
    ))}
  </ul>
);

const Meter = ({
  rating = 0,
  ready = false,
}: {
  rating?: number,
  ready?: boolean,
}) => {
  let ratingStyle;
  if (rating === 1) {
    ratingStyle = css.rating1;
  } else if (rating === 2) {
    ratingStyle = css.rating2;
  } else if (rating === 3) {
    ratingStyle = css.rating3;
  } else if (rating === 4) {
    ratingStyle = css.rating4;
  }

  return (
    <div className={ready ? css.meter : css.hiddenMeter}>
      <div className={rating > 0 ? ratingStyle : css.defaultSlice} />
      <div className={rating > 1 ? ratingStyle : css.defaultSlice} />
      <div className={rating > 2 ? ratingStyle : css.defaultSlice} />
      <div className={rating > 3 ? ratingStyle : css.defaultSlice} />
    </div>
  );
};

// Values exported to the parent change handler
export type ConfirmOnChangeProps = {
  newPassword: string,
  confirmPassword: string,
  passwordErrors: ErrorsByField,
};

type ConfirmProps = {
  onChange?: (d: ConfirmOnChangeProps) => mixed,
  newPassword?: string,
  confirmPassword?: string,
  firstInputLabel?: string,
  firstInputPlaceholder?: string,
  secondInputLabel?: string,
  secondInputPlaceholder?: string,
  meterLabel?: string,
  errorsByField?: ErrorsByField,
  autoFocus?: boolean,
  handleScoreChange: (score: ?ZxcvbnData) => mixed,
  emails: string[],
  names: string[],
};

type ConfirmState = {
  newPassword: string,
  confirmPassword: string,
  meterShowsWhichInput: 'newPassword' | 'confirmPassword',
  zxcvbnReady: boolean,
  zxcvbnData: ?ZxcvbnData,
};

const validate = function validate(state: ConfirmState): ErrorsByField {
  const {newPassword, confirmPassword} = state;
  const equal = newPassword === confirmPassword;
  const passwordFilled = newPassword.length > 0;
  const passwordTooShort = newPassword.length < 10;
  const confirmFilled = confirmPassword.length > 0;
  const errors = {};

  // tooShort is superset of empty
  if (!passwordFilled) {
    errors.newPassword = {
      empty: 'Password must be provided.',
    };
  } else if (passwordTooShort) {
    errors.newPassword = {
      tooShort: 'Password must be at least 10 characters long.',
    };
  }

  if (!confirmFilled) {
    errors.confirmPassword = {
      empty: 'Password must be typed twice to ensure accuracy.',
    };
  } else if (passwordFilled && !equal) {
    errors.confirmPassword = {
      notEqual: 'Passwords must match.',
    };
  }

  return errors;
};

const errorMessage = function errorMessage(state: ConfirmState): {
  primary: string,
  secondary: string,
} {
  const {newPassword, confirmPassword} = state;
  const errors = validate(state);
  const newPasswordErrors = errors.newPassword || {};
  const confirmPasswordErrors = errors.confirmPassword || {};
  const potentiallyEqual = newPassword.includes(confirmPassword);

  if (!potentiallyEqual && confirmPasswordErrors.notEqual) {
    return {primary: 'not equal', secondary: confirmPasswordErrors.notEqual};
  } else if (newPasswordErrors.tooShort) {
    return {primary: 'too short', secondary: newPasswordErrors.tooShort};
  } else {
    return {primary: '', secondary: ''};
  }
};

const scoreFromData = (score?: number, state: ConfirmState): number => {
  if (!score || score === 0) {
    return 0;
  }

  // Prevents meter from showing green when component is in visible error state
  if (errorMessage(state).primary) {
    return 1;
  }

  return score;
};

export const PasswordConfirmation = function PasswordConfirmation({
  firstInputLabel = 'New Password:',
  firstInputPlaceholder = '',
  secondInputLabel = 'Confirm New Password:',
  secondInputPlaceholder = '',
  meterLabel = '',
  errorsByField = {},
  autoFocus = false,
  emails = [],
  names = [],
  newPassword: initialNewPassword,
  confirmPassword: initialConfirmPassword,
  onChange,
  handleScoreChange,
}: ConfirmProps): React.Element<'div'> {
  const [state, setState] = React.useState<ConfirmState>({
    confirmPassword: initialConfirmPassword ?? '',
    newPassword: initialNewPassword ?? '',
    meterShowsWhichInput: 'newPassword',
    zxcvbnReady: false,
    zxcvbnData: undefined,
  });

  React.useEffect(() => {
    import('zxcvbn').then((_zxcvbn) => {
      zxcvbn = _zxcvbn.default;
      setState((prev) => ({...prev, zxcvbnReady: true}));
    });
  }, []);

  // this callback is fired when changing the password and
  // updates the internal state value of the most recently
  // updated field as well as its value and the current
  // zxcvbn score dict.
  const handlePasswordUpdate = React.useCallback(
    ({target}: SyntheticInputEvent<>) => {
      const {name: inputName, value: inputValue} = target;
      const meterShowsWhichInput =
        inputName === 'newPassword' ? 'newPassword' : 'confirmPassword';

      const extradata = [...emails, ...names].filter(Boolean);

      if (inputValue != null) {
        const zxcvbnData = zxcvbn(inputValue, extradata);

        setState((prevState) => ({
          ...prevState,
          [inputName]: inputValue,
          meterShowsWhichInput,
          zxcvbnData,
        }));
      }
    },
    [emails, names],
  );

  // this effect runs _after_ the setstate which updates the password values and
  // the zxcvbn data so that we're certain that any callbacks passed in always have
  // up-to-date value of the state / errors / score / etc
  React.useEffect(() => {
    // if any callbacks were passed in, update them here
    onChange?.({
      newPassword: state.newPassword,
      confirmPassword: state.confirmPassword,
      passwordErrors: validate(state),
    });

    handleScoreChange?.(state.zxcvbnData);
  }, [state]);

  const {newPassword, confirmPassword} = state;
  const {primary, secondary} = errorMessage(state);

  let currentScore = state.zxcvbnData?.score;
  if (primary) {
    currentScore = 1;
  } else if (!currentScore) {
    currentScore = 0;
  }

  const passwordWarningText = state.zxcvbnData?.feedback?.warning;

  return (
    <div className={css.column}>
      <div className={css.column}>
        <label className={css.formLabel}>
          {firstInputLabel && (
            <span className={css.labelText}>{firstInputLabel}</span>
          )}
          <Input
            className={classify(css.passwordInput, 'zipy-block')}
            type="password"
            autoFocus={autoFocus}
            name="newPassword"
            value={newPassword}
            onChange={handlePasswordUpdate}
            placeholder={firstInputPlaceholder}
            errors={
              errorsByField.newPassword && values(errorsByField.newPassword)
            }
          />
        </label>
        <label className={css.formLabel}>
          {secondInputLabel && (
            <span className={css.labelText}>{secondInputLabel}</span>
          )}
          <Input
            className={css.passwordInput}
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={handlePasswordUpdate}
            placeholder={secondInputPlaceholder}
            errors={
              errorsByField.confirmPassword &&
              values(errorsByField.confirmPassword)
            }
          />
        </label>
      </div>

      <div className={css.strengthRow}>
        <Meter
          rating={scoreFromData(state.zxcvbnData?.score, state)}
          ready={state.zxcvbnReady}
        />
        <span className={css.strengthLabel}>
          <span>{meterLabel}&nbsp;</span>

          <MouseTip content={secondary}>
            <span className={css.errorMessage}>{primary}</span>
          </MouseTip>
          {passwordWarningText && (
            <MouseTip content={passwordWarningText ?? ''}>
              {/* $FlowFixMe[incompatible-type-arg] */}
              <InfoIcon className={css.infoTip} />
            </MouseTip>
          )}
        </span>
      </div>
    </div>
  );
};

export default PasswordConfirmation;
