// @noflow

import type {
  GlobalVariable,
  GlobalVariableAction,
} from 'src/action-creators/global-variables';

import sculpt from 'sculpt';

import {
  RECEIVE,
  DELETE,
  UPDATE,
  RECEIVE_FIELD,
  DELETE_FIELD,
  UPDATE_FIELD,
  SET_MAPPING_ERROR,
} from 'src/action-creators/global-variables';


export type GlobalVariableState = GlobalVariable[];

export default (
  state: GlobalVariableState = [],
  action: GlobalVariableAction,
): GlobalVariableState => {
  switch (action.type) {
    case RECEIVE_FIELD: {
      const {
        meta: {gvarId},
        payload,
      } = action;
      const gvarIndex = state.findIndex((gv) => gv.id === gvarId);
      const gvar = state.find((gv) => gv.id === gvarId);

      if (gvar) {
        const fieldIndex = gvar.fieldValues.findIndex(
          (fv) => fv.id === payload.id,
        );

        let updatedGvar;
        if (fieldIndex > -1) {
          updatedGvar = sculpt(gvar, {
            fieldValues: {$splice: [[fieldIndex, 1, payload]]},
          });
        } else {
          updatedGvar = sculpt(gvar, {
            fieldValues: {$push: [payload]},
          });
        }
        return sculpt(state, {$splice: [[gvarIndex, 1, updatedGvar]]});
      }

      return state;
    }

    case RECEIVE: {
      const {payload: receivedVars} = action;

      const receivedIds = receivedVars.map((gv) => gv.id);
      const tempState = state.filter((gv) => !receivedIds.includes(gv.id));

      return tempState.concat(receivedVars);
    }

    case UPDATE: {
      const {gvId, ...changes} = action.payload;

      const newState = state.map((gv) => {
        if (gv.id === gvId) {
          gv = {...gv, ...changes};
        }

        return gv;
      });

      return newState;
    }

    case UPDATE_FIELD: {
      const {gvId, fieldId} = action.meta;

      const newState = state.map((gv) => {
        if (gv.id === gvId) {
          gv.fieldValues = gv.fieldValues.map((f) => {
            if (f.id === fieldId) {
              f = {...f, ...action.payload};
            }
            return f;
          });
        }

        return {...gv};
      });

      return newState;
    }

    case DELETE: {
      const {
        meta: {id},
      } = action;
      return state.filter((gv) => gv.id !== id);
    }

    case DELETE_FIELD: {
      const {
        meta: {gvarId, fieldId},
      } = action;
      const parentIndex = state.findIndex((gv) => gv.id === gvarId);

      if (parentIndex > -1) {
        const fieldValues = state[parentIndex].fieldValues;
        const fieldIndex = fieldValues.findIndex(
          (field) => field.id === fieldId,
        );

        if (fieldIndex > -1) {
          return sculpt(state, {
            [parentIndex]: {
              fieldValues: {
                $splice: [[fieldIndex, 1]],
              },
            },
          });
        }
      }

      return state;
    }

    case SET_MAPPING_ERROR: {
      const {
        meta: {gvId},
        payload,
      } = action;

      const newState = state.map((gv) => {
        if (gv.id === gvId) {
          gv.hasMappingError = payload;
        }

        return {...gv};
      });

      return newState;
    }
  }

  return state;
};
