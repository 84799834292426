// @flow strict

import type {JobVariablesAction} from 'src/action-creators/job-variables';
import type {JobVariable, JobVariableValues} from 'src/types/job-variables';

import {
  RECEIVE_JOB_VARIABLES,
  RECEIVE_JOB_VARIABLE_VALUES,
} from 'src/action-creators/job-variables';


export type State = {
  variables: JobVariable[],
  variableValues: JobVariableValues,
};

const initialState = {
  variables: [],
  variableValues: {},
};

export default (
  state: State = initialState,
  action: JobVariablesAction,
): State => {
  switch (action.type) {
    case RECEIVE_JOB_VARIABLES: {
      return {
        ...state,
        variables: action.payload,
      };
    }
    case RECEIVE_JOB_VARIABLE_VALUES: {
      return {
        ...state,
        variableValues: action.payload,
      };
    }
  }
  return state;
};
