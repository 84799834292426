// @flow

import type {Question} from 'src/types/survey';
import type {EntityType} from 'src/types/ats-entities';
import type {
  WritebackAllowedAttributesKeyed,
  AttributeMetaData,
} from 'src/types/writeback';

import * as React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'src/rerouter';

import {selectWorkflow} from 'src/selectors/workflow';
import {useWritebackAllowedAttributesKeyed} from 'src/hooks/useWritebackAllowedAttributes';


export type QuestionType =
  | 'text_survey_question'
  | 'rating_scale_survey_question'
  | 'nps_survey_question'
  | 'multiple_choice_survey_question'
  | 'file_upload_survey_question'
  | 'calendar_date_survey_question';

type CurrentQuestion = {
  id?: string,
  // $FlowFixMe[value-as-type] [v1.32.0]
  selectedType: QuestionType,
  // $FlowFixMe[value-as-type] [v1.32.0]
  originalType: QuestionType,
  // $FlowFixMe[value-as-type] [v1.32.0]
  [QuestionType]: Question,
  ...
};

export type WritebackContextState = {
  question: CurrentQuestion,
  // $FlowFixMe[value-as-type] [v1.32.0]
  module_attributes: ?{[QuestionType]: string[], ...},
  entity_type: ?EntityType,
  attribute_meta_data: ?{[string]: AttributeMetaData},
  ...
};

type ReducerTypes =
  | 'RECEIVE_ALLOWED_ATTRIBUTES'
  | 'RECEIVE_QUESTION'
  | 'UPDATE_QUESTION'
  | 'UPDATE_QUESTION_TYPE'
  | 'RESET_STATE';

export const RECEIVE_ALLOWED_ATTRIBUTES = 'RECEIVE_ALLOWED_ATTRIBUTES';
export const RECEIVE_QUESTION = 'RECEIVE_QUESTION';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const UPDATE_QUESTION_TYPE = 'UPDATE_QUESTION_TYPE';
export const RESET_STATE = 'RESET_STATE';

type ReceiveAllowedAttributesAction = {
  type: 'RECEIVE_ALLOWED_ATTRIBUTES',
  payload: ?WritebackAllowedAttributesKeyed,
};

type UpdateQuestionAction = {
  type: 'UPDATE_QUESTION',
  payload: {
    // $FlowFixMe[value-as-type] [v1.32.0]
    questionType: QuestionType,
    // $FlowFixMe[value-as-type] [v1.32.0]
    attrs: {...Question, ...},
  },
};

type ReceiveQuestionAction = {
  type: 'RECEIVE_QUESTION',
  // $FlowFixMe[value-as-type] [v1.32.0]
  payload: Question,
};

type UpdateQuestionTypeAction = {
  type: 'UPDATE_QUESTION_TYPE',
  payload: {
    // $FlowFixMe[value-as-type] [v1.32.0]
    questionType: QuestionType,
    // $FlowFixMe[value-as-type] [v1.32.0]
    newQuestionAttrs: Question,
  },
};

type ResetStateAction = {
  type: 'RESET_STATE',
};

type WritebackReducerActions =
  | ReceiveAllowedAttributesAction
  | UpdateQuestionAction
  | ReceiveQuestionAction
  | UpdateQuestionTypeAction
  | ResetStateAction;

const initialState = {
  question: {},
  module_attributes: null,
  entity_type: null,
  attribute_meta_data: null,
};

export const WritebackContext: React$Context<{
  dispatch: (WritebackReducerActions) => void,
  state: WritebackContextState,
  ...
}> = React.createContext<{
  state: WritebackContextState,
  dispatch: (WritebackReducerActions) => void,
  ...
}>({state: initialState, dispatch: () => {}});

export const reducer = (
  state: WritebackContextState,
  action: WritebackReducerActions,
): WritebackContextState => {
  switch (action.type) {
    case RECEIVE_ALLOWED_ATTRIBUTES:
      // $FlowFixMe spreading the payload here does not satisfy the state type
      return {...state, ...action.payload};
    case UPDATE_QUESTION:
      const {questionType, attrs} = action.payload;
      return {
        ...state,
        question: {
          ...state.question,
          // $FlowFixMe[invalid-computed-prop] types/surveys
          // $FlowFixMe[cannot-spread-inexact] types/surveys
          [questionType]: {
            // $FlowFixMe[exponential-spread] types/surveys
            ...state.question[questionType],
            ...attrs,
          },
        },
      };
    case RECEIVE_QUESTION:
      const question = action.payload;
      const questionWithTempTypes = {
        id: question.id,
        selectedType: question.type,
        originalType: question.type, //use to check against changed question type, if applicable
        // $FlowFixMe[invalid-computed-prop] types/surveys
        [question.type]: {
          ...question,
          writeback: question.writeback ? question.writeback : {enabled: false},
        },
      };

      return {
        ...state,
        // $FlowFixMe this is not a valid CurrentQuestion
        question: questionWithTempTypes,
      };
    case UPDATE_QUESTION_TYPE:
      const previousQuestionType = state.question.selectedType;
      const previousQuestionAttrs = state.question[previousQuestionType];

      return {
        ...state,
        question: {
          ...state.question,
          // $FlowFixMe[invalid-computed-prop] types/surveys
          [previousQuestionType]: previousQuestionAttrs,
          // $FlowFixMe[invalid-computed-prop] types/surveys
          [action.payload.questionType]: {
            ...action.payload.newQuestionAttrs,
            id: previousQuestionAttrs.id,
            // $FlowFixMe[prop-missing] types/surveys
            isNew: previousQuestionAttrs.isNew,
            required: previousQuestionAttrs.required,
            // $FlowFixMe[prop-missing] types/surveys
            question: previousQuestionAttrs.question,
          },
          selectedType: action.payload.questionType,
        },
      };
    case RESET_STATE:
      return {
        ...state,
        question: {},
      };
    default:
      return state;
  }
};

export const WritebackWrapper = ({
  children,
}: {
  children: React.Node,
}): React.Node => {
  // $FlowFixMe[incompatible-call] types/surveys
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {workflowId} = useParams();
  const workflow = useSelector((state) => selectWorkflow(state, workflowId));
  const allowedWritebackAttributes = useWritebackAllowedAttributesKeyed(
    workflow?.entityType,
  );

  React.useEffect(() => {
    dispatch({
      type: RECEIVE_ALLOWED_ATTRIBUTES,
      payload: allowedWritebackAttributes,
    });
  }, [allowedWritebackAttributes]);

  const contextValue = React.useMemo(() => ({state, dispatch}), [state]);

  return (
    <WritebackContext.Provider value={contextValue}>
      {children}
    </WritebackContext.Provider>
  );
};
