var React = require('react');

function CaretDown (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M8 10.25a.75.75 0 00-.53 1.28l4 4a.75.75 0 001.06 0l4-4a.75.75 0 00-.53-1.28H8z","fillRule":"evenodd","clipRule":"evenodd"}));
}

CaretDown.defaultProps = {"width":"24","height":"24","viewBox":"0 0 24 24"};

module.exports = CaretDown;

CaretDown.default = CaretDown;
