// @flow strict-local

import {useState, useEffect, useMemo} from 'react';
import inRange from 'lodash/inRange';

import {ApiError, captureApiError} from 'src/utils/errors';

export type ResourceState<T> =
  | {
      result: null,
      error: null,
      isLoading: boolean,
    }
  | {
      result: T,
      error: null,
      isLoading: boolean,
    }
  | {
      result: null,
      error: Error,
      isLoading: boolean,
    };

// TODO (kyle): this should eventually use Suspense
export function useResource<T>(
  resource: () => Promise<T>,
  options:
    | mixed[]
    | {
        deps: mixed[],
        onError?: (Error) => mixed,
      },
): ResourceState<T> {
  const [state, setState] = useState<ResourceState<T>>(defaultState);

  const {deps, onError} = Array.isArray(options)
    ? {deps: options, onError: null}
    : options;

  useEffect(() => {
    if (!state.isLoading) {
      setState((state) => ({
        ...state,
        isLoading: true,
      }));
    }

    resource().then(
      (result: T) => {
        setState({
          result,
          error: null,
          isLoading: false,
        });
      },
      (error: Error) => {
        // NOTE (kyle): we only capture 400 errors because they are generally
        // the client's fault (not the server's).
        if (error instanceof ApiError) {
          const {status} = error.response;
          if (inRange(status, 400, 500) && ![404, 401].includes(status)) {
            captureApiError(error);
          }
        }

        setState({
          result: null,
          error,
          isLoading: false,
        });

        if (onError) {
          onError(error);
        }
      },
    );
  }, deps);

  return state;
}

export default useResource;

export const defaultState = {
  result: null,
  error: null,
  isLoading: true,
};

export function useSuspenseResource<R>(
  callback: () => Promise<R>,
  deps: mixed[],
): () => R {
  return useMemo(() => {
    let loading = true;
    let error;
    let data;

    const promise = callback().then(
      (result) => {
        data = result;
        loading = false;
      },
      (caughtError) => {
        error = caughtError;
        loading = false;
      },
    );

    return () => {
      if (loading) {
        throw promise;
      }

      if (error != null) {
        throw error;
      }

      return data;
    };
  }, deps);
}
