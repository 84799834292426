// @flow strict
import * as React from 'react';
import {classify} from 'src/utils/classify';

import {Smallest} from 'src/designSystem2021Components/text-v2.jsx';

import css from './textarea.css';


export type Props = {
  value?: string,
  onChange?: (
    evt: SyntheticInputEvent<HTMLInputElement>,
    isEnter?: boolean,
  ) => mixed,
  onFocus?: (e: SyntheticInputEvent<HTMLInputElement>) => mixed,
  onBlur?: (e: SyntheticInputEvent<HTMLInputElement>) => mixed,
  onKeyPress?: (e: SyntheticKeyboardEvent<HTMLInputElement>) => mixed,
  name?: string,
  disabled?: boolean,
  placeholder?: string,
  error?: boolean,
  errorText?: string,
  label?: string | React.Node,
  contextLabel?: string | React.Node,
  boxClassName?: string,
  rows?: string,
  bottomBar?: React.Node,
};

const Textarea_ = (props: Props, ref): React.Node => {
  const {
    value,
    onChange,
    onFocus,
    onBlur,
    name,
    disabled,
    placeholder,
    error,
    errorText,
    label,
    contextLabel,
    boxClassName,
    bottomBar,
    ...textareaProps
  } = props;

  const controlledTextareaFilled = value !== '';

  return (
    <div
      className={classify(css.wrapper, {
        [css.filled]: controlledTextareaFilled ?? false,
        [css.withError]: error ?? false,
      })}
    >
      {(Boolean(label) || Boolean(contextLabel)) && (
        <div className={css.info}>
          <Smallest className={css.commonLabel}>{label ?? ''}</Smallest>
          <Smallest className={css.commonLabel}>
            {error && errorText ? errorText : contextLabel ?? ''}
          </Smallest>
        </div>
      )}
      <div
        className={classify(css.box, boxClassName, {
          [css.textareaDisabled]: disabled ?? false,
        })}
      >
        <textarea
          ref={ref}
          disabled={disabled}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          {...textareaProps}
        ></textarea>
        {bottomBar}
      </div>
    </div>
  );
};

export const Textarea = (React.forwardRef<Props, HTMLTextAreaElement>(
  Textarea_,
): React$AbstractComponent<Props, HTMLTextAreaElement>);
