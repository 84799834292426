// @flow strict

import * as React from 'react';
import css from './horizontal-timeline-element.css';
import classify from 'src/utils/classify';


export type HorizontalTimelineElementProps = {
  icon?: React.Node,
  isInProgress?: boolean,
  isCompleted?: boolean,
  circleColor?: string,
  lineStyle?: 'solid' | 'dashed',
  lineColor?: string,
  children?: React.Node,
  lineVariant?: 'success' | 'loading' | 'default',
};

export const HorizontalTimelineElement = (
  props: HorizontalTimelineElementProps,
): React.Node => {
  const {
    lineStyle,
    lineColor,
    icon,
    isInProgress,
    isCompleted,
    circleColor,
    children,
    lineVariant,
  } = props;
  const customStyle = {};
  if (lineStyle) {
    customStyle['--lineStyle'] = lineStyle;
  }
  if (lineColor) {
    customStyle['--lineColor'] = lineColor;
  }
  return (
    <div
      className={classify(css.timelineElementContainer, {
        [css.successLine]: lineVariant === 'success',
        [css.loadingLine]: lineVariant === 'loading',
        [css.defaultLine]: lineVariant === 'default',
      })}
      style={customStyle}
    >
      {props.icon ? (
        <div className={css.timelineIcon}>{icon}</div>
      ) : (
        <div
          className={classify(css.timelineCircle, {
            [css.loading]: isInProgress,
            [css.completed]: isCompleted,
          })}
          style={circleColor ? {backgroundColor: circleColor} : {}}
        ></div>
      )}

      <div className={css.timelineContent}>
        <div
          className={classify(css.customComponent, {
            [css.inProgress]: isInProgress,
            [css.completed]: isCompleted,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
