// @flow strict-local

export const isRoiFlagEnabled = (): boolean => {
  try {
    const state = window.senseStore.reduxStore.getState();
    return state.productFlags.releaseFlags.roiV2ApiEnabled;
  } catch (ex) {
    return false;
  }
};
