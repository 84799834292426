// @flow strict

// $FlowFixMe[nonstrict-import]
import type {DynamicLabels} from 'src/types/dynamic-labels';
// $FlowFixMe[nonstrict-import]
import type {ModalComponent} from 'src/types/modal';
// $FlowFixMe[nonstrict-import]
import type {ModalType} from 'src/components/modals/modal-root.jsx';
import type {
  JobMatchParams,
  JobMatchQueryParam,
  JobMatchSortOption,
} from 'src/types/job-variables.js';
import type {
  NumericSelectOption,
  StringSelectOption,
} from 'src/components/workflow/event/content/module/editors/constants.js';

import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useInflight} from 'src/hooks/redux';

import {
  selectJobMetaFilterParams,
  selectJobMatchCountParam,
  selectJobMatchSortParam,
  selectJobMatchParamDependants,
} from 'src/selectors/job-match-meta.js';
import {getJobMatchQueryParams} from 'src/action-creators/job-match-meta.js';
import {classify} from 'src/utils/classify';

import {findCommonElements} from 'src/utils/job-match.js';

import makeClassNameComponent, {
  type ClassNameComponent,
} from 'src/utils/makeClassNameComponent';

import {useAsyncDependency} from 'src/hooks/useAsyncDependency';

// $FlowFixMe[nonstrict-import]
import {popModal} from 'src/action-creators/modal';

import {CircularLoader} from '@spaced-out/ui-design-system/lib/components/CircularLoader';

import HoverIconButton from 'src/components/lib/hover-icon-button';
// $FlowFixMe[nonstrict-import]
import TitleBarModal from 'src/components/modals/base-modal.jsx';
// $FlowFixMe[nonstrict-import]
import {Select} from 'src/components/lib/token-list-input/new-stuff.jsx';
// $FlowFixMe[nonstrict-import]
import Checkbox from 'src/components/lib/checkbox';
// $FlowFixMe[nonstrict-import]
import EmailListInput from 'src/components/workflow/event/content/email-list-input.jsx';

import CancelIcon from 'src/images/icons/cancel-icon.svg?noAttrs';

import css from './job-match-params-modal.css';
import {filter} from 'lodash';


const ErrorText: ClassNameComponent<> = makeClassNameComponent(css.errorText);
const InputWithError: ClassNameComponent<> = makeClassNameComponent(
  css.inputWithError,
);

const FieldErrorText = ({maxEntryCount}): React.Node => {
  if (maxEntryCount === 1) {
    return (
      <ErrorText>{`${maxEntryCount} field selection is required`}</ErrorText>
    );
  } else if (maxEntryCount > 1) {
    return (
      <ErrorText>{`Min 1 and max. ${maxEntryCount} field selection is required`}</ErrorText>
    );
  }
  return null;
};

function getDefaultSetFilters(
  job_matches_params: ?JobMatchParams,
): Set<string> {
  const setFilters = new Set<string>();
  for (const key in job_matches_params) {
    if (Array.isArray(job_matches_params[key])) {
      !!job_matches_params[key].length && job_matches_params[key][0]
        ? setFilters.add(key)
        : '';
    } else {
      !!job_matches_params[key] && setFilters.add(key);
    }
  }
  return setFilters;
}

const getEmailListValue = (value) => {
  return Array.isArray(value) ? value.join(',') : value;
};

function isValidStringInput(value: ?mixed, maxCount: number): boolean {
  if (maxCount > 1) {
    const len = Array.isArray(value) ? value.filter((a) => !!a).length : 0;
    return len > 0 && len <= maxCount;
  } else {
    return !!value;
  }
}

const DisabledMessageBox = (): React.Node => (
  <div className={css.disabledMessage}>Enter candidate field to be matched</div>
);

const JobMatchParamDropdown = ({
  id,
  name,
  value,
  options,
  showError,
  onInputParamsChangeHandler,
  max_number_of_values_allowed,
}) => {
  const dropdownOptions = options?.map((option) => ({
    value: option,
    label: option,
  }));

  const selectedDropdownOption = dropdownOptions?.find(
    (item) => item.value === value,
  );

  return (
    <InputWithError>
      <Select
        value={selectedDropdownOption}
        classNames={{container: css.draftInputContainer}}
        options={dropdownOptions}
        onChange={(selectedOption) => {
          onInputParamsChangeHandler(
            id,
            selectedOption?.value,
            max_number_of_values_allowed,
          );
        }}
        clearable={false}
        placeholder={`Select ${name}`}
        error={showError}
      />
      {showError && <FieldErrorText maxEntryCount={1} />}
    </InputWithError>
  );
};

export default function JobMatchParamsModal({
  dynamicLabels,
  job_matches_count,
  job_sorting_param,
  job_matches_params,
  removeModal,
  className,
  onParamsChange,
  type,
  ...props
}: {
  dynamicLabels: DynamicLabels,
  job_matches_params: JobMatchParams,
  job_matches_count: number,
  job_sorting_param: string,
  onParamsChange: (params: JobMatchParams, matchesCount: number) => void,
  removeModal: () => mixed,
  className: string,
  type: ModalType | ModalComponent,
}): React.Element<'div'> {
  const dispatch = useDispatch();

  const isLoading = useInflight(getJobMatchQueryParams());

  useAsyncDependency(() => dispatch(getJobMatchQueryParams()), []);

  const filterOptions = useSelector(selectJobMetaFilterParams);
  const countParam = useSelector(selectJobMatchCountParam);
  const sortParam = useSelector(selectJobMatchSortParam);
  const sortOptions = sortParam?.options || [];
  const dependants = useSelector(selectJobMatchParamDependants);

  const [includedFilters, setIncludedFilters] = React.useState(
    getDefaultSetFilters(job_matches_params),
  );

  const [jobMatchesParamsValues, setJobMatchesParamsValues] = React.useState(
    new Map(Object.entries(job_matches_params)),
  );

  const onInputParamsChangeHandler = (
    key,
    value,
    max_number_of_values_allowed = 0,
  ) => {
    let stateValue = value;
    if (max_number_of_values_allowed > 1) {
      stateValue = stateValue?.split(',');
    }
    const newJobMatchesParamsValues = new Map(jobMatchesParamsValues);
    newJobMatchesParamsValues.set(key, stateValue);

    setJobMatchesParamsValues(newJobMatchesParamsValues);
  };

  const jobMatchCountOptions = React.useMemo(() => {
    const optionsArr = countParam?.options || [];
    return optionsArr.map((count) => ({
      value: count,
      label: `${count} ${count > 1 ? 'jobs' : 'job'} at most`,
    }));
  }, [countParam]);

  const [currentJobMatchesCount, setCurrentJobMatchesCount] =
    React.useState<?NumericSelectOption>(null);

  const localJobMatchesCount = jobMatchCountOptions.find(
    (option) => option.value === (currentJobMatchesCount ?? job_matches_count),
  );

  const setJobSortingParam = (selectedOption) => {
    if (!includedFilters.has(sortParam.id)) {
      toggleChecked(sortParam.id, true);
    }
    onInputParamsChangeHandler(sortParam?.id, selectedOption?.value);
  };

  const getJobMatchSortingOptions = () => {
    return Array.isArray(sortOptions)
      ? sortOptions?.reduce(
          (result, {depends_on_either, id, name, visibility}) => {
            if (
              visibility === 'ENABLED' &&
              ((Array.isArray(depends_on_either) &&
                depends_on_either.length &&
                findCommonElements(includedFilters, depends_on_either)) ||
                !depends_on_either?.length)
            ) {
              result.push({
                value: id,
                label: `${sortParam.name} ${name}`,
              });
            }
            return result;
          },
          [],
        )
      : [];
  };

  const jobMatchSortingOptions = React.useMemo(
    () => getJobMatchSortingOptions(),
    [sortOptions, includedFilters],
  );

  const jobMatchSelectedSortBy = React.useMemo(() => {
    return jobMatchSortingOptions?.find(
      (option) => option.value === jobMatchesParamsValues.get(sortParam?.id),
    );
  }, [jobMatchSortingOptions, jobMatchesParamsValues]);

  const [title, setJobTitle] = React.useState(job_matches_params.title);
  const [keywords, setKeywords] = React.useState(
    job_matches_params.keywords?.join(','),
  );

  const [errors, setErrors] = React.useState(new Set());

  const [showModalError, setShowModalError] = React.useState(false);

  const removeFilter = (filter, includedFilters) => {
    let clonedIncludedFilters = new Set(includedFilters);
    clonedIncludedFilters.delete(filter);
    const dependantsParams = dependants.get(filter);
    dependantsParams?.forEach((param) => {
      if (clonedIncludedFilters.has(param)) {
        const paramObj = filterOptions.find(
          (filterObject) => filterObject.id === param,
        );
        const isValid = findCommonElements(
          clonedIncludedFilters,
          paramObj.depends_on_either,
        );
        if (!isValid) {
          clonedIncludedFilters = removeFilter(param, clonedIncludedFilters);
        }
      }
    });
    return clonedIncludedFilters;
  };

  const toggleChecked = (key, checked) => {
    let clonedIncludedFilters = new Set(includedFilters);
    if (checked) {
      clonedIncludedFilters.add(key);
    } else {
      clonedIncludedFilters = removeFilter(key, clonedIncludedFilters);
    }
    setIncludedFilters(clonedIncludedFilters);
  };

  const closeModal = () => dispatch(popModal());

  const validateParams = (): boolean => {
    const errorsSet = new Set();
    includedFilters.forEach((filter, index) => {
      const filterOption = filterOptions.find(
        (filterObject) => filterObject.id === filter,
      );
      if (filterOption) {
        if (
          !isValidStringInput(
            jobMatchesParamsValues.get(filter),
            filterOption.max_number_of_values_allowed,
          )
        ) {
          errorsSet.add(filter);
        }
      } else if (filter === sortParam?.id) {
        if (
          jobMatchSelectedSortBy?.value !== jobMatchesParamsValues.get(filter)
        ) {
          errorsSet.add(filter);
        }
      }
    });

    setErrors(errorsSet);
    return errorsSet.size === 0;
  };

  const onSubmit = () => {
    if (includedFilters.size === 0) {
      setShowModalError(true);
    } else {
      setShowModalError(false);
      const isValid = validateParams();
      if (isValid) {
        const jobMatchParams = {};
        includedFilters.forEach((filter) => {
          jobMatchParams[filter] = jobMatchesParamsValues.get(filter);
        });
        onParamsChange(jobMatchParams, localJobMatchesCount?.value ?? 0);
        closeModal();
      }
    }
  };

  const usableDynamicLabels = dynamicLabels.filter(
    (field) =>
      field.mapping.url !== 'internals' &&
      field.mapping.url !== 'global_variable',
  );

  return (
    <div className={css.titleBarContainer}>
      <div className={css.titleBar}>
        <div className={css.titleAndIcon}>
          <div className={css.title}>Job matching options</div>
        </div>
        <HoverIconButton className={css.closeXHoverButton}>
          <CancelIcon className={css.closeXIcon} onClick={closeModal} />
        </HoverIconButton>
      </div>
      <TitleBarModal
        {...props}
        type={type}
        confirmText="Save Options"
        abortText="Discard Changes"
        handleAbort={closeModal}
        handleConfirm={onSubmit}
        className={css.titleBarModal}
        showError={showModalError}
        errorText="At least one row must be checked and completed"
      >
        <div className={css.contentContainer}>
          <div className={css.headerBox}>
            {`For each candidate, job matches are based on the checked options
              below. If the values of all of the selected candidate fields match
              the corresponding values in the job description, then a match will
              be made. By checking more options, each candidate is less likely to
              have job matches, but more likley to have strong job matches.`}
            <div className={css.headerBottom}>
              <span>
                {`For each candidate, zero or more job matches may be found. If
                multiple job matches are found for any candidate, select the
                maximum number to show:`}
              </span>
              <Select
                value={localJobMatchesCount}
                classNames={{container: css.jobMatchHeaderSelect}}
                options={jobMatchCountOptions}
                onChange={(sel) => sel && setCurrentJobMatchesCount(sel.value)}
                clearable={false}
                placeholder={'Select job match count'}
              />
            </div>
            <div className={css.headerBottom}>
              <span>{`Select on what basis the jobs to be sorted for the candidate`}</span>
              <InputWithError className={css.jobMatchHeaderSelect}>
                <Select
                  value={jobMatchSelectedSortBy}
                  classNames={{container: css.jobMatchHeaderSelect}}
                  options={jobMatchSortingOptions}
                  onChange={(sel) => sel && setJobSortingParam(sel)}
                  clearable={false}
                  placeholder={'Select Sort by'}
                  error={errors.has(sortParam?.id)}
                />
                {errors.has(sortParam?.id) && (
                  <ErrorText>{'Select correct sort by'}</ErrorText>
                )}
              </InputWithError>
            </div>
            <div className={css.headerBottom}>
              <span>
                {`What search criteria is supported ? `}{' '}
                <a
                  className={css.link}
                  href={
                    'https://support.sensehq.co/hc/en-us/articles/16312225441805'
                  }
                  target="_blank"
                >
                  {'Learn more'}
                </a>
              </span>
            </div>
          </div>
          <div className={css.paramsTable}>
            <div className={css.paramsTableHeaderRow}>
              <span className={css.paramsTableHeader}>
                Job description data
              </span>
              <span className={css.paramsTableHeader}>
                Candidate profile data
              </span>
            </div>
            {isLoading ? (
              <div className={css.loaderContainer}>
                <CircularLoader colorToken="colorBackdropFill" size="large" />
              </div>
            ) : (
              <div className={css.paramsContainer}>
                {filterOptions?.map(
                  ({
                    name,
                    depends_on_either,
                    options,
                    id,
                    input_type,
                    max_number_of_values_allowed,
                    visibility,
                  }) => {
                    if (
                      (Array.isArray(depends_on_either) &&
                        depends_on_either.length > 0 &&
                        findCommonElements(
                          includedFilters,
                          depends_on_either,
                        )) ||
                      !depends_on_either?.length
                    ) {
                      return (
                        <>
                          <div
                            className={classify(css.paramsTableCell, {
                              [css.paramsTableCellWithError]: errors.has(id),
                            })}
                          >
                            <label>
                              <Checkbox
                                name={name}
                                checked={includedFilters.has(id)}
                                onChange={({currentTarget: {checked}}) => {
                                  toggleChecked(id, checked);
                                }}
                                disabled={visibility === 'DISABLED'}
                              />
                            </label>
                            <span className={css.paramLabel}>{name}</span>
                          </div>

                          <div
                            className={classify(css.paramsTableCell, {
                              [css.paramsTableCellWithError]: errors.has(id),
                            })}
                          >
                            {includedFilters.has(id) ? (
                              Array.isArray(input_type) &&
                              input_type.includes('DROPDOWN_SELECTION') ? (
                                <JobMatchParamDropdown
                                  id={id}
                                  name={name}
                                  value={jobMatchesParamsValues.get(id)}
                                  options={options}
                                  showError={errors.has(id)}
                                  onInputParamsChangeHandler={
                                    onInputParamsChangeHandler
                                  }
                                  max_number_of_values_allowed={
                                    max_number_of_values_allowed
                                  }
                                />
                              ) : (
                                <InputWithError>
                                  <EmailListInput
                                    hasError={errors.has(id)}
                                    options={usableDynamicLabels}
                                    value={getEmailListValue(
                                      jobMatchesParamsValues.get(id),
                                    )}
                                    placeholder={`Select a ${name}`}
                                    onChange={(inputValue) =>
                                      onInputParamsChangeHandler(
                                        id,
                                        inputValue,
                                        max_number_of_values_allowed,
                                      )
                                    }
                                    multiEntityEnabled={true}
                                    containerClassName={css.draftInputContainer}
                                    limit={max_number_of_values_allowed}
                                  />
                                  {errors.has(id) && (
                                    <FieldErrorText
                                      maxEntryCount={
                                        max_number_of_values_allowed
                                      }
                                    />
                                  )}
                                </InputWithError>
                              )
                            ) : (
                              <DisabledMessageBox />
                            )}
                          </div>
                        </>
                      );
                    }
                  },
                )}
              </div>
            )}
          </div>
        </div>
      </TitleBarModal>
    </div>
  );
}
