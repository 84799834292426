// @noflow

import * as React from 'react';

import {classify} from 'src/utils';

import UploadIcon from 'src/images/upload-icon-small.svg';

import css from './file-button.css';


type UnstyledProps = {
  children: string,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
  accept: string,
  className?: string,
};

export class UnstyledFileButton extends React.Component<UnstyledProps, {}> {
  input = null;

  render() {
    const {children, onChange, accept, className} = this.props;

    return [
      <input type="file" className={css.input} key="input" accept={accept} ref={input => this.input = input} onChange={onChange} />,
      <button className={className} key="button" onClick={this.handleClick}>{children}<UploadIcon /></button>,
    ];
  }

  handleClick = () => {
    if (this.input) {
      this.input.click();
    }
  };
}


const FileButton = ({className, ...props}: UnstyledProps) => (
  <UnstyledFileButton className={classify(css.button, className)} {...props} />
);

export default FileButton;
