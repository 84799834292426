// @flow strict
import * as React from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'src/rerouter';
import {useEnvironmentContext} from 'src/hooks/useEnvironmentContext';

// $FlowFixMe[nonstrict-import]
import {EXTENSION_ID} from 'src/chat-extension/constants';
import logger from 'src/utils/logger';

// $FlowFixMe[untyped-import]
import PortContext from 'src/components/messaging/port-context';

// $FlowFixMe[nonstrict-import]
import {setMeetingsScheduleEventData} from 'src/action-creators/messages';
// $FlowFixMe[nonstrict-import]
import {routeExternalContact} from 'src/action-creators/contacts';

import {
  // $FlowFixMe[untyped-import]
  CLICKED_NUMBER,
  // $FlowFixMe[untyped-import]
  PORT_OPEN,
  // $FlowFixMe[untyped-import]
  WANDERLY_JOB_CLICK,
  // $FlowFixMe[untyped-import]
  MEETING_SCHEDULE_EVENT,
} from 'src/chat-extension/messages';
// $FlowFixMe[untyped-import]
import {isAgentSelectedForAmplitudeTracking} from 'src/selectors/messages-v2';

import {AnalyticsService} from 'src/analytics';
import {getMessagingTrackingMetrics} from 'src/components/messaging/messaging-amplitude-helper.js';
// $FlowFixMe[nonstrict-import]
import {setDraft} from 'src/action-creators/draft-messages';


const initialExtensionTimeout = 200;

const ExtensionConnection = ({
  children,
}: {
  children: React.Node,
}): React.Node => {
  const {isExtension} = useEnvironmentContext();
  const router = useHistory();
  const dispatch = useDispatch();

  const [extensionPortValue, setExtensionPortValue] = React.useState(null);
  const [extensionConnectionTimeout, setExtensionConnectionTimeout] =
    React.useState(initialExtensionTimeout);
  const trackAmplitudeEvent = useSelector(isAgentSelectedForAmplitudeTracking);

  const handleMessage = (message) => {
    if (message.type === PORT_OPEN) {
      setExtensionConnectionTimeout(initialExtensionTimeout);
    }
    // This legacy code is commented and will be removed if there is no customer reaching out regarding this
    // else if (message.type === WANDERLY_JOB_CLICK) {
    //   const data = message.payload.payload;
    //   setTimeout(() => {
    //     dispatch(
    //       setDraft('new', {
    //         body: `Hi <contact_first_name>, I have a ${data.specialties[0]} job in ${data.location}. Are you interested?`,
    //         dripMessage: `Here's some more info: https://wanderly.us/nurse/jobs/compare?id=${data.jobId}  Let me know what you think!`,
    //       }),
    //     );
    //     router.push({
    //       ...router.location,
    //       pathname: '/messages/new',
    //     });
    //   }, 0);
    // }
    else if (message.type === MEETING_SCHEDULE_EVENT) {
      router.push({
        ...router.location,
        pathname: '/meetings/schedule-now',
      });
      const payload = message.payload;
      setTimeout(() => {
        dispatch(setMeetingsScheduleEventData(payload));
      }, 0);
    } else if (message.type === CLICKED_NUMBER) {
      setTimeout(() => {
        dispatch(routeExternalContact(router, message));
        if (trackAmplitudeEvent) {
          const trackingDetails = getMessagingTrackingMetrics();
          AnalyticsService.track('Extension Number Clicked', trackingDetails);
        }
      }, 0);
    }
  };

  const handleDisconnect = (port) => {
    const error =
      port.error ||
      (window.chrome &&
        window.chrome.runtime &&
        window.chrome.runtime.lastError);
    logger.log('Port disconnecting');
    if (error) {
      logger.error('Disconnected from extension.', error);
    }

    port.onMessage.removeListener(handleMessage);

    logger.log(
      `Attempting to reconnect to extension in ${extensionConnectionTimeout}ms...`,
    );
    setTimeout(() => connectToExtension(), extensionConnectionTimeout);
    setExtensionConnectionTimeout((prev) => prev * 2);
    setExtensionPortValue(null);
  };

  const connectToExtension = () => {
    const extensionPort = window.chrome.runtime.connect(EXTENSION_ID, {
      name: 'chat-iframe',
    });
    setExtensionPortValue(extensionPort);
    if (extensionPort) {
      extensionPort.onMessage.addListener(handleMessage);
      extensionPort.onDisconnect.addListener(handleDisconnect);
    }
  };

  React.useEffect(() => {
    if (window.chrome && window.chrome.runtime && isExtension) {
      connectToExtension();
    }
  }, []);

  return (
    <PortContext.Provider value={extensionPortValue}>
      {children}
    </PortContext.Provider>
  );
};

export default ExtensionConnection;
