// @flow strict

import * as React from 'react';

import css from './placeholder.css';


function ReplaceBlock(): React.Node {
  return (
    <div className={css.replaceBlock}>
      <svg
        width="60"
        height="25"
        viewBox="0 0 70 25"
        xmlns="http://www.w3.org/2000/svg"
      >
        <text
          x="5"
          y="15"
          fill="#ffffff"
          fontVariant="all-petite-caps"
          fontWeight="bold"
        >
          replace
        </text>
      </svg>
      <svg
        width="22"
        height="25"
        style={{
          marginRight: '5px',
          marginTop: '1px',
        }}
        viewBox="0 0 22 25"
        fill="#ffffff"
      >
        <path
          d="M6.969 8c-.563 0-.844.688-.438 1.094l4 4c.25.25.657.25.907 0l4-4C15.844 8.688 15.563 8 15 8H6.97z"
          fill="#ffffff"
        ></path>
      </svg>
    </div>
  );
}
type Props = {
  text: React.Node,
  onReplace: () => void,
};

const PlaceholderPill = ({onReplace, text}: Props): React.Node => {
  return (
    <div className={css.placeholderPill} onClick={onReplace}>
      <div className={css.text}>{text}</div>
      <ReplaceBlock />
    </div>
  );
};

export default PlaceholderPill;
