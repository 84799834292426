// @flow

// $FlowFixMe[untyped-type-import]
import type {AudienceMember} from 'src/api-parsers';
import type {
  ThreadResponse,
  InboxThreadResponse,
  Thread,
  Message,
  MessagePending,
  MessageMedia,
  InboxFilter,
  InboxNotification,
  UnreadCounts,
  UnreadCountsByPhoneNumberSet,
  UnreadCountsByQueue,
  ConversationContextEvent,
  MeetingsEventData,
  ExternalEventDetails,
} from 'src/types/messages';
import type {Router} from 'src/types/router';
import type {AuthedUserAccount, ThreadOwner} from 'src/types/account';

import type {Contact} from 'src/types/contacts';
import type {
  GetState,
  Dispatch,
  ThunkAction,
  ThunkSyncAction,
} from 'src/reducers';
import type {SchedulerEvent} from 'src/types/scheduler';

import uniqueId from 'lodash/uniqueId';
import moment from 'moment';
import invariant from 'invariant';

import logger from 'src/utils/logger';
import {thunkify as flow} from 'src/utils/thunks';
import {selectMessagingDisableMms} from 'src/selectors/agency';
import {selectPhoneNumberSetFromDefaultPhoneNumberSet} from 'src/selectors/chat';
import {
  selectMessageOrigin,
  selectDiscoverCandidateDetails,
} from 'src/selectors/draft-messages';
import {selectCurrentQueue} from 'src/selectors/messaging-queues';
import {
  getThreadById,
  selectThreadByPhone,
  selectMessagesForThread,
} from 'src/selectors/messages-v2';
import {selectCurrentInbox, selectCurrentInboxId} from 'src/selectors/inboxes';
import {selectThread} from 'src/selectors/threads';
import {selectReleaseFlags} from 'src/selectors/product-flags';
import {selectLATEnabled} from 'src/hooks/product-flags';
import {
  selectMostRecentMessage,
  selectMostRecentUnreadMessage,
} from 'src/selectors/messages';
import {MSG_DIRECTION_OUTGOING} from 'src/types/messages';
import {key, fetching, cached} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api-v2';
import {snake, camel} from 'src/utils';
import {getTimestamp} from 'src/utils/date-time';
import {captureSentryMessage, captureSentryException} from 'src/utils/sentry';
import {getWebSocketClient} from 'src/web-socket-client';
import {MessageUpdateReceiver} from 'src/action-creators/message-update-receiver';
import {receiveContactUpdates} from 'src/action-creators/contacts';
import {receive as receiveAgentsForThread} from 'src/action-creators/accounts';
import {batch} from 'src/action-creators/batch';
import {receiveBroadcasts} from 'src/action-creators/broadcasts';
import {receiveBroadcastsForThread} from 'src/action-creators/thread-lists';
import {receiveQueueBasedInboxes} from 'src/action-creators/chat/queues';


export const RECEIVE_MESSAGES = 'messages/receiveMessages';
export const RECEIVE_NEW_MESSAGE = 'messages/receiveNewMessage';
export const START_POLLING = 'messages/startPolling';
export const STOP_POLLING = 'messages/stopPolling';
export const MARK_MESSAGES_FROM_PHONE_AS_READ =
  'messages/markMessagesFromPhoneAsRead';
export const MARK_THREAD_COMPLETE = 'messages/markThreadComplete';
export const RECEIVE_MESSAGES_FOR_THREAD = 'messages/receiveForThread';
export const RECEIVE_PENDING_MESSAGE = 'messages/receivePendingMessage';
export const ACKNOWLEDGE_MOST_RECENT_UNREAD =
  'messages/acknowledgeMostRecentUnread';
export const INBOX_MOUNTED = 'messages/inboxMounted';
export const INBOX_UNMOUNTED = 'messages/inboxUnmounted';
export const CHANGE_READ_ONLY_MODE = 'messages/changeReadOnlyMode';
export const RECEIVE_UNREAD_COUNTS = 'messages/receiveUnreadCounts';
export const UPDATE_UNREAD_COUNTS = 'messages/updateUnreadCounts';

// new stuff
export const RECEIVE_THREAD = 'messages/receiveThread';
export const RECEIVE_INBOX = 'messages/receiveInbox';
export const RECEIVE_TYPING_EVENT = 'messages/receiveTypingEvent';
export const RECEIVE_THREAD_UPDATE = 'threads/receiveUpdate';
export const UPDATE_OPT_OUT_IN_THREAD = 'threads/updateOptOutInThread';
export const RECEIVE_CONVERSATION_CONTEXT_EVENTS =
  'threads/receiveConversationContextEvents';
export const SET_THREAD_OWNERS = 'messages/setThreadOwners';
export const ASSIGN_THREAD_OWNER = 'messages/assignThreadOwner';
export const REMOVE_THREAD_OWNER = 'messages/removeThreadOwner';
export const SET_INBOX_FILTER = 'messages/setFilter';
export const SET_FCM_TOKEN = 'messages/setFcmToken';
export const RECEIVE_SCHEDULER_EVENT = 'messages/receiveSchedulerEvent';
export const RECEIVE_READ_STATUS_EVENT = 'messages/receiveReadStatusEvent';
export const ADD_NOTIFICATION = 'messages/addNotification';
export const REMOVE_NOTIFICATION = 'messages/removeNotification';
export const SET_MESSAGE_HISTORY_WINDOW = 'messages/setMessageHistoryWindow';
export const CLEAR_MESSAGE_HISTORY_WINDOW =
  'messages/clearMessageHistoryWindow';
export const SET_BANNER_CARD_HOVER = 'messages/setBannerCardHover';
export const SET_WARNING_TEXT_HOVER = 'messages/setWarningTextHover';
export const SET_CONTENT_WARNING_CLICK_INFO =
  'messages/setContentWarningClickInfo';
export const SET_MULTI_CHANNEL_INBOX = 'messages/setMultiChannelInbox';
export const MESSAGE_RECEIVED_NUMBER = 'messages/setReceivedPhoneNumber';
export const MESSAGE_ACTIVE_ACTION_TYPE = 'messages/setActiveActionType';
export const MESSAGE_ACTIVE_TEMPLATE = 'messages/setActiveTemplate';
export const CALL_CONTENT_BANNER_API = 'messages/callContentBannerApi';
export const SHOW_USER_DETAILS_ICON = 'messages/showUserDetailsIcon';
export const MEETINGS_SCHEDULE_EVENT_DATA =
  'messages/meetingsScheduleEventData';
export const SET_EXTERNAL_EVENT_DETAILS = 'messages/setExternalEventDetails';

export type Action =
  | ReceiveMessagesAction
  | ReceiveNewMessageAction
  | StartPollingAction
  | StopPollingAction
  | MarkMessagesFromPhoneAsReadAction
  | MarkThreadCompleteAction
  | ReceivePendingMessageAction
  | AcknowledgeMostRecentUnreadAction
  | InboxMountedAction
  | InboxUnmountedAction
  | ChangeReadOnlyModeAction
  | ReceiveUnreadCountsAction
  | UpdateUnreadCountsAction
  | ReceiveThreadAction
  | ReceiveInboxAction
  | ReceiveTypingEventAction
  | ReceiveThreadUpdateAction
  | SetInboxFilterAction
  | ReceiveSchedulerEventAction
  | SetThreadUnreadFlag
  | {type: 'messages/addNotification', payload: InboxNotification}
  | RemoveNotificationAction
  | SetMessageHistoryWindowAction
  | ClearMessageHistoryWindowAction
  | receiveConversationContextEventsAction
  | ReceiveReadStatusEventAction
  | SetThreadOwnersAction
  | AssignThreadOwnerAction
  | SetBannerCardHoverAction
  | SetWarningTextHoverAction
  | SetMultiChannelInboxAction
  | SetReceivedPhoneNumberAction
  | SetActiveActionTypeAction
  | SetActiveTemplateAction
  | ShowUserDetailsIconAction
  | UpdateOptOutInThreadAction
  | SetContentWarningClickInfoAction
  | SetMeetingsScheduleEventDataAction;
export type MessagesAction = Action;

export type ApiMessage = {
  id: string,
  agent_id: string,
  to_phone: string,
  from_phone: string,
  contact_id?: string,
  body: string,
  time_created: string,
  time_read: ?boolean,
  scheduledMessageId: string,
};

type ApiMessageNew = {
  phone_number_set_id: string,
  external_phone: string,
  external_contact_id?: string,
  external_contact_type?: string,
  body: string,
};

type MessageNewBase = {
  body: string,
  phoneNumberSetId: string,
  externalPhone: string,
  media_ids?: string[],
};
type MessageNew =
  | MessageNewBase
  | (MessageNewBase & {
      externalContactId?: string,
      externalContactType?: string,
    });

type ChangeReadOnlyModeAction = {
  type: 'messages/changeReadOnlyMode',
  payload: boolean,
};

export const changeReadOnlyMode = (
  readOnly: boolean,
): ChangeReadOnlyModeAction => ({
  type: CHANGE_READ_ONLY_MODE,
  payload: readOnly,
});

/**
 * WebSocket realtime implementation
 */
let messageUpdateReceiver = null;

type StartPollingAction = {
  type: 'messages/startPolling',
};

export const startListeningWebSocket =
  (router: Router): ThunkSyncAction =>
  (dispatch: Dispatch, getState: GetState) => {
    if (getState().messages.isPolling) {
      return;
    }

    const client = getWebSocketClient();
    if (!client) {
      return;
    }

    dispatch({
      type: START_POLLING,
    });

    messageUpdateReceiver = new MessageUpdateReceiver(
      client,
      dispatch,
      getState,
      router,
    );
  };

type StopPollingAction = {
  type: 'messages/stopPolling',
};

export const stopListeningWebSocket = (): StopPollingAction => {
  if (messageUpdateReceiver !== null) {
    messageUpdateReceiver.tearDown();
  }
  messageUpdateReceiver = null;

  return {
    type: STOP_POLLING,
  };
};

export const getMostRecentMessages =
  (): ThunkAction<boolean> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const mostRecentMessage = selectMostRecentMessage(getState());
    let filter_message_datetime = mostRecentMessage
      ? mostRecentMessage.statusTimeUpdated || mostRecentMessage.timeCreated
      : moment(0).toISOString();

    if (!filter_message_datetime) {
      captureSentryMessage('empty filter_message_datetime', {
        level: 'warning',
        extra: {
          message: mostRecentMessage,
        },
      });
      filter_message_datetime = moment(0).toISOString();
    }

    let response = await dispatch(
      reduxApi.get(
        'messages/recent',
        {
          filter_message_datetime,
        },
        {
          silence: true,
        },
      ),
    );

    if (response.length === 0) {
      return false;
    }

    response = camel(response);

    dispatch(
      batch(
        receiveMessages(response),
        receiveContactUpdates(
          response.map(({contactId}) => ({
            id: contactId,
            archived: false,
          })),
        ),
      ),
    );

    return true;
  };

type InboxResponse = {
  agents: AuthedUserAccount[],
  threads: InboxThreadResponse[],
  contacts: Contact[],
  // $FlowFixMe[value-as-type] [v1.32.0]
  audienceMembers: AudienceMember[],
  meta: {
    count: number,
  },
};

type ReceiveInboxAction = {
  type: 'messages/receiveInbox',
  payload: InboxResponse,
  meta: {offset: number, noIncrement: boolean, phoneNumberSetId: string},
};

export const receiveInbox = (
  inbox: InboxResponse,
  meta: {offset: number, noIncrement: boolean, phoneNumberSetId: string},
): ReceiveInboxAction => ({
  type: RECEIVE_INBOX,
  payload: inbox,
  meta,
});

type ReceiveTypingEventAction = {
  type: 'messages/receiveTypingEvent',
  payload: {threadId: string, agentId: string, status: boolean},
};

export const receiveTypingEvent = (
  threadId: string,
  agentId: string,
  status: boolean,
): ReceiveTypingEventAction => ({
  type: RECEIVE_TYPING_EVENT,
  payload: {threadId, agentId, status},
});

export const DEFAULT_PAGE_SIZE = 10;

export const getInboxMultiNumber: ({
  offset?: number,
  threads?: number,
  filter?: InboxFilter,
  owned_by?: string,
  noIncrement?: boolean,
  phoneNumberSetId: string,
}) => ThunkAction<> = flow(
  key(
    ({offset = 0, filter, owned_by, phoneNumberSetId, noIncrement}) =>
      `getInboxMultiNumber?offset=${offset}filter=${filter}phone_number_set_id=${phoneNumberSetId}owned_by=${owned_by}noIncrement=${noIncrement}`,
  ),
  cached((response, {offset = 0, noIncrement = false, phoneNumberSetId}) =>
    receiveInbox(camel(response), {offset, noIncrement, phoneNumberSetId}),
  ),
  fetching(),
)(
  ({
      offset = 0,
      threads = DEFAULT_PAGE_SIZE,
      filter,
      owned_by,
      phoneNumberSetId,
    }) =>
    (dispatch: Dispatch, getState: GetState) => {
      const isLATEnabled = selectLATEnabled(getState());
      //Note (aditya): must appease the flow gods flow doesn't like conditional params
      const params: {
        offset: number,
        threads: number,
        phone_number_set_id?: string,
        filter?: InboxFilter,
        owned_by?: string,
      } = {
        offset,
        threads,
      };
      if (!isLATEnabled) {
        params.phone_number_set_id = phoneNumberSetId;
      }
      if (filter) {
        params.filter = filter;
      }
      if (owned_by) {
        params.owned_by = owned_by;
      }

      if (isLATEnabled) {
        return dispatch(
          reduxApi.get(
            `messages_v2/inbox/${phoneNumberSetId}/threads`,
            params,
            {
              apiPath: '/api/v2/',
            },
          ),
        );
      } else {
        return dispatch(reduxApi.get('messages_v2/inbox', params));
      }
    },
);

export const getInbox =
  (args: {
    offset?: number,
    threads?: number,
    filter?: InboxFilter,
    owned_by?: string,
    noIncrement?: boolean,
  }): ThunkAction<mixed> =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const phoneNumberSetId = selectCurrentInboxId(state);
    if (phoneNumberSetId) {
      return dispatch(
        getInboxMultiNumber({
          ...args,
          phoneNumberSetId,
        }),
      );
    } else {
      return Promise.resolve();
    }
  };

export const getMoreInbox =
  (): ThunkAction<mixed> => (dispatch: Dispatch, getState: GetState) => {
    const isLATEnabled = selectLATEnabled(getState());
    const phoneNumberSetId = selectPhoneNumberSetFromDefaultPhoneNumberSet(
      getState(),
    );
    const inboxId = selectCurrentInboxId(getState());
    const inboxOffsetId = isLATEnabled ? inboxId : phoneNumberSetId;
    const {inboxOffset, inboxMaxOffsetReached, inboxFilter} =
      getState().messages;
    invariant(
      !inboxMaxOffsetReached[inboxOffsetId],
      'Max offset for inbox reached!',
    );
    return dispatch(
      getInbox({
        offset: inboxOffset[inboxOffsetId],
        filter: inboxFilter,
      }),
    );
  };

export const getMessagesForLoggedInUser: () => ThunkAction<void> = flow(
  key(() => 'getMessagesForLoggedInUser'),
  cached(() => ({type: 'handled by _getMessagesForLoggedInUser, do nothing'})),
  fetching(),
)(
  () => (dispatch: Dispatch) =>
    dispatch(reduxApi.get('messages')).then((response) =>
      dispatch(receiveMessages(camel(response))),
    ),
);

type ReceiveMessagesAction = {
  type: 'messages/receiveMessages',
  payload: Message[],
  meta: {
    shouldUpdateMostRecent: boolean,
  },
};

export const receiveMessages = (
  messages: Message[],
  shouldUpdateMostRecent: boolean = true,
): ReceiveMessagesAction => ({
  type: RECEIVE_MESSAGES,
  payload: messages,
  meta: {
    shouldUpdateMostRecent,
  },
});

type ReceiveNewMessageAction = {
  type: 'messages/receiveNewMessage',
  payload: Message,
  meta: {
    pendingMessageId: string,
    shouldUpdateMostRecent: boolean,
  },
};

const receiveNewMessage = (
  message: Message,
  pendingMessageId: string,
): ReceiveNewMessageAction => ({
  type: RECEIVE_NEW_MESSAGE,
  payload: message,
  meta: {
    pendingMessageId,
    shouldUpdateMostRecent: false,
  },
});

type ReceivePendingMessageAction = {
  type: 'messages/receivePendingMessage',
  payload: MessagePending,
};

const receivePendingMessage = (
  message: MessagePending,
): ReceivePendingMessageAction => ({
  type: RECEIVE_PENDING_MESSAGE,
  payload: message,
});

export const createMessage =
  (
    body: string,
    phone: string,
    templateId?: string,
    schedulerTemplateId?: string,
    mediaIds: string[] = [],
    pendingMessage?: ?MessagePending,
    placeholders?: {[key: string]: string},
  ): ThunkAction<mixed> =>
  (dispatch: Dispatch, getState: GetState) => {
    const inboxId = selectCurrentQueue(getState())?.id;
    const phoneNumberSetId = selectPhoneNumberSetFromDefaultPhoneNumberSet(
      getState(),
    );
    const thread = selectThreadByPhone(getState(), phone);
    const newMessage: any = {
      // TODO[Ashwini]: fix below error introduced in 0.177.0
      // $FlowFixMe[not-an-object]
      ...(templateId && {templateId}),
      phoneNumberSetId,
      externalPhone: phone,
      media_ids: mediaIds,
      body,
    };
    if (placeholders) {
      newMessage.placeholders = placeholders;
    }
    if (schedulerTemplateId) {
      newMessage.schedulerTemplateId = schedulerTemplateId;
    }

    if (!pendingMessage) {
      pendingMessage = {
        ...newMessage,
        id: uniqueId('pending-message'),
        status: 'pending',
        threadId: thread?.id,
        timeCreated: getTimestamp(),
        direction: MSG_DIRECTION_OUTGOING,
        error: false,
      };
      dispatch(receivePendingMessage(pendingMessage));
    }

    // $FlowFixMe[incompatible-call]
    return dispatch(postMessage(newMessage, pendingMessage));
  };

export const createThreadMessage =
  (
    body: string,
    threadId: string,
    templateId?: string,
    schedulerTemplateId?: string,
    mediaIds: string[] = [],
    pendingMessage?: ?MessagePending,
    placeholders?: {key: string, value: string},
  ): ThunkAction<mixed> =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    // TODO[Ashwini] : fix below errors introduced in 0.177.0
    // $FlowFixMe[not-an-object]
    const thread = getThreadById(state, threadId);
    const newMessage: any = {
      // $FlowFixMe[not-an-object]
      ...(templateId && {templateId}),
      media_ids: mediaIds,
      body,
    };

    if (placeholders) {
      newMessage.placeholders = placeholders;
    }
    if (schedulerTemplateId) {
      newMessage.schedulerTemplateId = schedulerTemplateId;
    }

    if (!pendingMessage) {
      pendingMessage = {
        ...newMessage,
        id: uniqueId('pending-message'),
        status: 'pending',
        threadId: thread?.id,
        timeCreated: getTimestamp(),
        direction: MSG_DIRECTION_OUTGOING,
        error: false,
      };
      dispatch(receivePendingMessage(pendingMessage));
    }

    return dispatch(postThreadMessage(newMessage, pendingMessage));
  };

const postMessage =
  (
    newMessage: MessageNew,
    pendingMessage: MessagePending,
  ): ThunkAction<mixed> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const origin = selectMessageOrigin(getState());
    const discoverCandidateDetails = selectDiscoverCandidateDetails(getState());
    const inboxId = selectCurrentQueue(getState())?.id;
    const apiMessageNew: ApiMessageNew = snake(newMessage);
    const payload = discoverCandidateDetails
      ? {...newMessage, origin, metadata: discoverCandidateDetails}
      : apiMessageNew;
    try {
      const response = await dispatch(
        reduxApi.post('messages_v2', snake(payload)),
      );
      const message = camel(response);
      dispatch(receiveNewMessage(message, pendingMessage.id));
      if (message?.threadId) {
        dispatch(
          receiveThreadUpdate(message.threadId, {
            displayTime: message.timeCreated,
            archived: false,
          }),
        );
      }
      return message;
    } catch (error) {
      // Dispatching receivePendingMessage with same id will overwrite existing pending
      // message in store
      dispatch(
        receivePendingMessage({
          ...pendingMessage,
          error: true,
          errorDetails:
            error instanceof reduxApi.ApiError ? error.responseBody.errors : [],
        }),
      );
    }
  };

const postThreadMessage =
  (
    newMessage: MessageNew,
    pendingMessage: MessagePending,
  ): ThunkAction<mixed> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const origin = selectMessageOrigin(getState());
    const discoverCandidateDetails = selectDiscoverCandidateDetails(getState());
    const inboxId = selectCurrentQueue(getState())?.id;
    const threadId = pendingMessage.threadId;
    if (!inboxId || !threadId) {
      return dispatch(
        receivePendingMessage({
          ...pendingMessage,
          error: true,
          errorDetails: [],
        }),
      );
    }

    const apiRoute = `messages_v2/inbox/${inboxId}/threads/${threadId}/messages`;

    const apiMessageNew: ApiMessageNew = snake(newMessage);
    const payload = discoverCandidateDetails
      ? {...newMessage, origin, metadata: discoverCandidateDetails}
      : apiMessageNew;
    try {
      const response = await dispatch(
        reduxApi.post(apiRoute, snake(payload), {}, {apiPath: '/api/v2/'}),
      );
      const message = camel(response);
      dispatch(receiveNewMessage(message, pendingMessage.id));
      if (message?.threadId) {
        dispatch(
          receiveThreadUpdate(message.threadId, {
            displayTime: message.timeCreated,
            archived: false,
          }),
        );
      }
      return message;
    } catch (error) {
      // Dispatching receivePendingMessage with same id will overwrite existing pending
      // message in store
      dispatch(
        receivePendingMessage({
          ...pendingMessage,
          error: true,
          errorDetails:
            error instanceof reduxApi.ApiError ? error.responseBody.errors : [],
        }),
      );
    }
  };

export const createMultipleMediaMessages =
  (files: File[], phone: string): ThunkSyncAction =>
  (dispatch: Dispatch, getState: GetState) => {
    for (const file of files) {
      dispatch(createMediaMessage(file, phone));
    }
  };

export const createMediaMessage =
  (file: File, phone: string): ThunkSyncAction =>
  (dispatch: Dispatch, getState: GetState) => {
    const phoneNumberSetId = selectPhoneNumberSetFromDefaultPhoneNumberSet(
      getState(),
    );
    const thread = selectThreadByPhone(getState(), phone);

    const pendingMessage: MessagePending = {
      id: uniqueId('pending-message'),
      status: 'pending',
      phoneNumberSetId,
      threadId: thread?.id,
      externalPhone: phone,
      timeCreated: getTimestamp(),
      media_ids: [uniqueId()],
      body: '',
      direction: MSG_DIRECTION_OUTGOING,
      error: false,
    };
    dispatch(receivePendingMessage(pendingMessage));

    const formData = new FormData();
    formData.append('upload', file);
    formData.append('phone_number_set_id', phoneNumberSetId);

    dispatch(uploadMediaForMessage(pendingMessage, formData));
  };

const uploadMediaForMessage =
  (pendingMessage: MessagePending, formData: FormData): ThunkSyncAction =>
  (dispatch: Dispatch, getState: GetState) => {
    const mmsDisabled = selectMessagingDisableMms(getState());
    if (mmsDisabled) {
      throw new Error(
        'Attempting to upload media but MMS Messages are Disabled',
      );
    }
    // $FlowFixMe[class-object-subtyping]
    dispatch(reduxApi.post('messages_v2/media/upload', formData))
      .then((response: MessageMedia) => {
        //NOTE (Iris): currently, we only send one mediaId per message
        const mediaId = response.id;
        //NOTE(Iris): message text and message media are currently sent separately, hence the empty body
        const emptyBody = '';
        dispatch(
          createMessage(
            emptyBody,
            pendingMessage.externalPhone,
            undefined,
            undefined,
            [mediaId],
            pendingMessage,
          ),
        );
      })
      .catch(() => {
        dispatch(receivePendingMessage({...pendingMessage, error: true}));
      });
  };

type MarkMessagesFromPhoneAsReadAction = {
  type: 'messages/markMessagesFromPhoneAsRead',
  payload: Message[],
};

// TODO (kyle): review this thunk and its need for all the messages in a
// thread.
export const markThreadAsRead =
  (threadId: string, messageId: string): ThunkAction<void> =>
  async (dispatch: Dispatch, getState: GetState) => {
    // TODO (kyle): make sure this case is necessary
    const state = getState();
    const thread = selectThread(state, threadId);

    if (!thread || thread.count - thread.lastMessageRead <= 0) {
      return;
    }

    await dispatch(
      reduxApi.put(`messages_v2/thread/${threadId}/read-status`, {
        message_id: messageId,
      }),
    );

    // TODO (kyle): websocket should push this info
    await dispatch(getUnreadCounts());
  };

export const markThreadAsUnread =
  (threadId: string, currentPhone: string): ThunkAction<void> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const messages = selectMessagesForThread(state, threadId);
    const currentThread = selectThreadByPhone(state, currentPhone);

    const messageId = messages[0].id;

    await dispatch(
      reduxApi.del(`messages_v2/thread/${threadId}/read-status`, {
        message_id: messageId,
      }),
    );

    dispatch(
      setThreadUnreadFlag(currentThread && currentThread.id === threadId),
    );

    // TODO (kyle): websocket should push this info
    await Promise.all([
      dispatch(getUnreadCounts()),
      dispatch(getThread(threadId)),
    ]);
  };
type SetThreadOwnersAction = {
  type: 'messages/setThreadOwners',
  payload: {[string]: {[string]: string}},
};

export function setThreadOwners(payload: {
  [string]: {[string]: string},
}): SetThreadOwnersAction {
  return {
    type: SET_THREAD_OWNERS,
    payload,
  };
}

type AssignThreadOwnerAction = {
  type: 'messages/assignThreadOwner',
  payload: {threadId: string, user: ThreadOwner},
};

export function assignThreadOwner(payload: {
  threadId: string,
  user: ThreadOwner,
}): AssignThreadOwnerAction {
  return {
    type: ASSIGN_THREAD_OWNER,
    payload,
  };
}

type RemoveThreadOwnerAction = {
  type: 'messages/removeThreadOwner',
  payload: {threadId: string, agentId: string},
};

export function removeThreadOwner(payload: {
  threadId: string,
  agentId: string,
}): RemoveThreadOwnerAction {
  return {
    type: REMOVE_THREAD_OWNER,
    payload,
  };
}

type SetThreadUnreadFlag = {
  type: 'messages/setThreadUnreadFlag',
  payload: boolean,
};

export const SET_THREAD_UNREAD_FLAG = 'messages/setThreadUnreadFlag';
export function setThreadUnreadFlag(value: boolean): SetThreadUnreadFlag {
  return {
    type: SET_THREAD_UNREAD_FLAG,
    payload: value,
  };
}

type MarkThreadCompleteAction = {
  type: 'messages/markThreadComplete',
  payload: string,
};

const markThreadComplete = (phone: string): MarkThreadCompleteAction => ({
  type: MARK_THREAD_COMPLETE,
  payload: phone,
});

type ReceiveThreadAction = {|
  type: 'messages/receiveThread',
  payload: {response: ThreadResponse, beforeTime?: string},
|};

export const receiveThread = (
  response: ThreadResponse,
  beforeTime?: string,
): ReceiveThreadAction => ({
  type: RECEIVE_THREAD,
  payload: {response, beforeTime},
});

const handleThreadAndMessagesResponse = (response, useLegacy) => {
  const actions = [
    receiveThread(camel(response)),
    receiveAgentsForThread(response.agents),
    receiveBroadcasts(camel(response.broadcasts)),
    receiveBroadcastsForThread(response.id, camel(response.broadcasts)),
  ];

  if (response.messages.length !== 0 && !useLegacy) {
    actions.push(setMessageHistoryWindow(camel(response)));
  }

  return batch(...actions);
};

type SetMessageHistoryWindowAction = {
  type: 'messages/setMessageHistoryWindow',
  payload: {
    messages: Message[],
  },
};

export const setMessageHistoryWindow = (latestMessages: {
  messages: Message[],
}): SetMessageHistoryWindowAction => ({
  type: SET_MESSAGE_HISTORY_WINDOW,
  payload: latestMessages,
});

type ClearMessageHistoryWindowAction = {
  type: 'messages/clearMessageHistoryWindow',
};

export const clearMessageHistoryWindow =
  (): ClearMessageHistoryWindowAction => ({
    type: CLEAR_MESSAGE_HISTORY_WINDOW,
  });

export const fetchMoreRecentMessagesForPhone: (
  phone: string,
  phoneNumberSetId: string,
  mostRecentMessageId: string,
  useLegacy: ?boolean,
) => ThunkAction<mixed> = flow(
  key(
    (phone, phoneNumberSetId, mostRecentMessageId, useLegacy) =>
      `fetchMoreRecentMessagesForPhone:${phone}:${phoneNumberSetId}:${mostRecentMessageId}:${useLegacy}`,
  ),
  // eslint-disable-next-line max-params
  cached((response, phone, phoneNumberSetId, mostRecentMessageId, useLegacy) =>
    handleThreadAndMessagesResponse(response, useLegacy),
  ),
  fetching(),
)(
  (
      phone: string,
      phoneNumberSetId: string,
      mostRecentMessageId: string,
      useLegacy: ?boolean = false,
    ) =>
    async (dispatch: Dispatch, getState: GetState) => {
      const shouldUseConversationContext =
        getState().agentSettings.messagingEnableConversationContext;

      const apiString =
        shouldUseConversationContext && !useLegacy
          ? 'cc-thread-and-messages'
          : 'thread-and-messages';
      try {
        return await dispatch(
          reduxApi.get(
            `messages_v2/${apiString}/by-message-context/${mostRecentMessageId}`,
            {
              phone_number_set_id: phoneNumberSetId,
              external_phone: phone,
              count_before: 0,
              count_after: 20,
            },
          ),
        );
      } catch (err) {
        // dismiss 404s (the receiver will noop the response)
        if (err?.response?.status === 404) {
          return null;
        } else {
          // actually raise the error if not a 404
          throw err;
        }
      }
    },
);

export const fetchMessagesForPhone: (
  phone: string,
  phoneNumberSetId: string,
  beforeTime?: string,
  useLegacy: ?boolean,
) => ThunkAction<mixed> = flow(
  key(
    (phone, phoneNumberSetId, beforeTime, useLegacy = false) =>
      `fetchMessagesForPhone:${phone}:${phoneNumberSetId}:${beforeTime}${
        useLegacy ? 'useLegacy' : ''
      }`,
  ),
  cached((response, phone, phoneNumberSetId, beforeTime) => {
    if (!response) {
      // just emit an action but don't do anything if the response is null
      // TODO(marcos): should the cached() receiver be allowed to return null?
      return {type: '@@/receiver-noop'};
    }
    const actions = [
      receiveThread(camel(response), beforeTime),
      receiveAgentsForThread(response.agents),
      receiveBroadcastsForThread(response.id, camel(response.broadcasts)),
    ];
    if (
      response.messages.length === 0 &&
      (!response.context_events || response.context_events?.length === 0)
    ) {
      actions.push(markThreadComplete(response.id));
    }
    return batch(...actions);
  }),
  fetching(),
)(
  (
      phone: string,
      phoneNumberSetId: string,
      beforeTime?: string,
      useLegacy: ?boolean = false,
    ) =>
    async (dispatch: Dispatch, getState: GetState) => {
      const agentSettings = getState().agentSettings;
      const shouldUseConversationContext =
        !useLegacy && agentSettings.messagingEnableConversationContext;
      const apiString = shouldUseConversationContext
        ? 'cc-thread-and-messages'
        : 'thread-and-messages';
      try {
        return await dispatch(
          reduxApi.get(`messages_v2/${apiString}`, {
            phone_number_set_id: phoneNumberSetId,
            external_phone: phone,
            limit: 20,
            // NOTE (kyle): filtering only occurs if there is an offsetId
            to_date: beforeTime,
          }),
        );
      } catch (err) {
        // dismiss 404s (the receiver will noop the response)
        if (err?.response?.status === 404) {
          return null;
        } else {
          // actually raise the error if not a 404
          throw err;
        }
      }
    },
);

export const fetchMessagesForThread: (
  threadId: string,
  inboxId: string,
  beforeTime?: string,
) => ThunkAction<mixed> = flow(
  key(
    (threadId, inboxId, beforeTime) =>
      `fetchMessagesForThread:${threadId}:${inboxId}:${beforeTime}`,
  ),
  cached((response, threadId, inboxId, beforeTime) => {
    if (!response) {
      // just emit an action but don't do anything if the response is null
      // TODO(marcos): should the cached() receiver be allowed to return null?
      return {type: '@@/receiver-noop'};
    }
    const actions = [
      receiveThread(camel(response), beforeTime),
      receiveAgentsForThread(response.agents),
      receiveBroadcastsForThread(response.id, camel(response.broadcasts)),
    ];
    if (
      response.messages.length === 0 &&
      (!response.context_events || response.context_events?.length === 0)
    ) {
      actions.push(markThreadComplete(response.id));
    }
    return batch(...actions);
  }),
  fetching(),
)(
  (
      threadId: string,
      inboxId: string,
      beforeTime?: string,
      useLegacy: ?boolean = false,
    ) =>
    async (dispatch: Dispatch, getState: GetState) => {
      const agentSettings = getState().agentSettings;
      const shouldUseConversationContext =
        !useLegacy && agentSettings.messagingEnableConversationContext;
      const apiString = `messages_v2/inbox/${inboxId}/threads/${threadId}/messages`;

      try {
        return await dispatch(
          reduxApi.get(
            apiString,
            {
              limit: 20,
              to_date: beforeTime,
            },
            {apiPath: '/api/v2/'},
          ),
        );
      } catch (err) {
        // dismiss 404s (the receiver will noop the response)
        if (err?.response?.status === 404) {
          return null;
        } else {
          // actually raise the error if not a 404
          throw err;
        }
      }
    },
);

export const fetchMessageWithContext: (
  phone: string,
  phoneNumberSetId: string,
  messageId: string,
  useLegacy?: boolean,
) => ThunkAction<mixed> = flow(
  key(
    (phone, phoneNumberSetId, messageId, useLegacy = false) =>
      `fetchMessageWithContext:${phone}:${phoneNumberSetId}:${messageId}:${String(
        useLegacy,
      )}`,
  ),
  fetching(),
)(
  (
      phone: string,
      phoneNumberSetId: string,
      messageId: string,
      useLegacy: boolean = false,
    ) =>
    async (dispatch: Dispatch, getState: GetState) => {
      const agentSettings = getState().agentSettings;
      const shouldUseConversationContext =
        !useLegacy && agentSettings.messagingEnableConversationContext;
      const apiString = shouldUseConversationContext
        ? 'cc-thread-and-messages'
        : 'thread-and-messages';
      const response = await dispatch(
        reduxApi.get(
          `messages_v2/${apiString}/by-message-context/${messageId}`,
          {
            phone_number_set_id: phoneNumberSetId,
            external_phone: phone,
            count_before: 20,
            count_after: 20,
          },
        ),
      );

      if (response) {
        dispatch(handleThreadAndMessagesResponse(response, useLegacy));
      } else {
        logger.error('unexpected empty response from message context api');
      }
    },
);

type AcknowledgeMostRecentUnreadAction = {
  type: 'messages/acknowledgeMostRecentUnread',
  payload: ?Message,
};

// aka clear browser tab notification
export const acknowledgeMostRecentUnread =
  (): ThunkSyncAction => (dispatch: Dispatch, getState: Function) =>
    dispatch(
      ({
        type: ACKNOWLEDGE_MOST_RECENT_UNREAD,
        payload: selectMostRecentUnreadMessage(getState()),
      }: AcknowledgeMostRecentUnreadAction),
    );

type InboxMountedAction = {
  type: 'messages/inboxMounted',
};

export const inboxMounted = (): InboxMountedAction => ({
  type: INBOX_MOUNTED,
});

type InboxUnmountedAction = {
  type: 'messages/inboxUnmounted',
};

export const inboxUnmounted = (): InboxUnmountedAction => ({
  type: INBOX_UNMOUNTED,
});

export const getUnreadCounts: () => ThunkAction<mixed> = flow(
  key(() => 'messages/getUnreadCounts'),
  fetching(),
)(() => async (dispatch: Dispatch, getState: GetState) => {
  const unreadSummaryReleaseFlag =
    getState().productFlags.releaseFlags.messagingUnreadSummaryApiFix;
  const client = getWebSocketClient();
  // don't call the unread/summary if it is connected to websocket
  if (unreadSummaryReleaseFlag && client?.connectionOpened) {
    return;
  }
  const response = await dispatch(reduxApi.get('messages_v2/unread/summary'));
  if (!response.phone_number_sets) {
    const error = new Error('phone_number_sets is missing in response');
    captureSentryException(error, {
      level: 'error',
      extra: {
        response,
      },
    });
  }
  dispatch(
    receiveUnreadCounts({
      unreadTotal: response.total,
      unreadByPhoneNumberSet: response.phone_number_sets,
      unreadByQueue: response.queues,
      unreadTimestamp: response.timestamp,
    }),
  );
});

type ReceiveUnreadCountsPayload = {
  unreadTotal: UnreadCounts,
  unreadByPhoneNumberSet: UnreadCountsByPhoneNumberSet,
  unreadByQueue: UnreadCountsByQueue,
  unreadTimestamp: number,
};

type ReceiveUnreadCountsAction = {
  type: 'messages/receiveUnreadCounts',
  payload: ReceiveUnreadCountsPayload,
};

export const receiveUnreadCounts = (
  unreadCounts: ReceiveUnreadCountsPayload,
): ReceiveUnreadCountsAction => ({
  type: RECEIVE_UNREAD_COUNTS,
  payload: unreadCounts,
});

type UpdateUnreadCountsPayload = {
  unreadByPhoneNumberSet: UnreadCountsByPhoneNumberSet,
  unreadByQueue: UnreadCountsByQueue,
  unreadTimestamp: number,
};

type UpdateUnreadCountsAction = {
  type: 'messages/updateUnreadCounts',
  payload: UpdateUnreadCountsPayload,
};

export const updateUnreadCounts = (
  unreadCounts: UpdateUnreadCountsPayload,
): UpdateUnreadCountsAction => ({
  type: UPDATE_UNREAD_COUNTS,
  payload: unreadCounts,
});

type ReceiveThreadUpdateAction = {
  type: 'threads/receiveUpdate',
  payload: {
    threadId: string,
    update: $Shape<Thread>,
  },
};

export const receiveThreadUpdate = (
  threadId: string,
  update: $Shape<Thread>,
): ReceiveThreadUpdateAction => ({
  type: RECEIVE_THREAD_UPDATE,
  payload: {
    threadId,
    update,
  },
});

type UpdateOptOutInThreadAction = {
  type: 'threads/updateOptOutInThread',
  payload: {
    threadId: string,
    optOut: boolean,
  },
};

export const updateOptOutInThread = (
  threadId: string,
  optOut: boolean,
): UpdateOptOutInThreadAction => ({
  type: UPDATE_OPT_OUT_IN_THREAD,
  payload: {
    threadId,
    optOut,
  },
});

type receiveConversationContextEventsAction = {
  type: 'threads/receiveConversationContextEvents',
  payload: Array<ConversationContextEvent>,
};

export const receiveConversationContextEvents = (
  events: Array<ConversationContextEvent>,
): receiveConversationContextEventsAction => ({
  type: RECEIVE_CONVERSATION_CONTEXT_EVENTS,
  payload: events,
});

export const updateThread =
  (
    threadId: string,
    updates: $Shape<{
      archived: boolean,
      blocked: boolean,
      hidden: boolean,
      starred: boolean,
    }>,
  ): ThunkAction<mixed> =>
  (dispatch: Dispatch) => {
    dispatch(receiveThreadUpdate(threadId, updates));
    if (updates.archived) {
      dispatch(archiveThread(threadId));
    }
    return dispatch(
      reduxApi.put(
        `messages_v2/inbox-thread/by/thread/${threadId}`,
        snake(updates),
      ),
    );
  };

export const archiveThread =
  (threadId: string): ThunkSyncAction =>
  (dispatch: Dispatch) => {
    const notification: InboxNotification = {
      id: uniqueId(),
      type: 'archived',
      threadId,
      timeCreated: new Date(),
      timeoutId: setTimeout(() => {}, 0),
    };

    dispatch(addNotification(notification));
  };

export const pinNotification =
  (notification: InboxNotification): ThunkSyncAction =>
  (dispatch: Dispatch) => {
    clearTimeout(notification.timeoutId);
    dispatch({type: ADD_NOTIFICATION, payload: notification});
  };

export const unpinNotification =
  (notification: InboxNotification): ThunkSyncAction =>
  (dispatch: Dispatch, getState: GetState) => {
    const isVisible = getState().messages.notifications.find(
      ({id}) => id === notification.id,
    );
    if (isVisible) {
      dispatch(addNotification(notification));
    }
  };

export const addNotification =
  (notification: InboxNotification): ThunkSyncAction =>
  (dispatch: Dispatch, getState: GetState) => {
    const newNotification: InboxNotification = {
      ...notification,
      timeoutId: setTimeout(() => {
        dispatch(removeNotification(newNotification));
      }, 3000),
    };
    dispatch({type: ADD_NOTIFICATION, payload: newNotification});
  };

type RemoveNotificationAction = {
  type: 'messages/removeNotification',
  payload: InboxNotification,
};

export const removeNotification = (
  payload: InboxNotification,
): RemoveNotificationAction => ({
  type: REMOVE_NOTIFICATION,
  payload,
});

type SetInboxFilterAction = {
  type: 'messages/setFilter',
  payload: {filter: InboxFilter, owned_by?: string},
};

export const setInboxFilter = (
  filter: InboxFilter,
  owned_by?: string,
): SetInboxFilterAction => ({
  type: SET_INBOX_FILTER,
  payload: {filter, owned_by},
});

type SetFcmTokenAction = {
  type: 'messages/setFcmToken',
  payload: string,
};

export const setFcmToken = (token: string): SetFcmTokenAction => ({
  type: SET_FCM_TOKEN,
  payload: token,
});

type ReceiveSchedulerEventAction = {
  type: 'messages/receiveSchedulerEvent',
  payload: {externalPhone: string, event: SchedulerEvent},
};

export const receiveSchedulerEvent = (
  externalPhone: string,
  event: SchedulerEvent,
): ReceiveSchedulerEventAction => ({
  type: RECEIVE_SCHEDULER_EVENT,
  payload: {externalPhone, event},
});

export const getSchedulerEvent =
  (externalPhone: string): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    const event = await dispatch(
      reduxApi.get(`scheduler/event/next/${externalPhone}`),
    );
    dispatch(receiveSchedulerEvent(externalPhone, event));
  };

export const getFilteredInbox =
  (filter: InboxFilter, owned_by?: string): ThunkAction<mixed> =>
  (dispatch: Dispatch) => {
    dispatch(setInboxFilter(filter, owned_by));
    return dispatch(getInbox({filter, owned_by}));
  };

export const getThread =
  (threadId: string): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    const thread = await dispatch(
      reduxApi.get(`messages_v2/inbox-thread/by/thread/${threadId}`),
    );

    dispatch(receiveThreadUpdate(threadId, camel(thread)));
  };

type ReceiveReadStatusEventAction = {
  type: 'messages/receiveReadStatusEvent',
  payload: {agent_id: string, thread_id: string, message_id: string},
};

export const receiveReadStatusEvent = (readStatusEvent: {
  agent_id: string,
  thread_id: string,
  message_id: string,
}): ReceiveReadStatusEventAction => ({
  type: RECEIVE_READ_STATUS_EVENT,
  payload: readStatusEvent,
});

type SetBannerCardHoverAction = {
  type: 'messages/setBannerCardHover',
  payload: {type: string, currValue: boolean},
};

export const setPunctuationBannerHoverEvent = (
  type: string,
  currValue: boolean,
): SetBannerCardHoverAction => ({
  type: SET_BANNER_CARD_HOVER,
  payload: {type, currValue},
});

type SetWarningTextHoverAction = {
  type: 'messages/setWarningTextHover',
  payload: {type: string, currValue: boolean},
};

export const setPunctuationWarningHoverEvent = (
  type: string,
  currValue: boolean,
): SetWarningTextHoverAction => ({
  type: SET_WARNING_TEXT_HOVER,
  payload: {type, currValue},
});

type SetContentWarningClickInfoAction = {
  type: 'messages/setContentWarningClickInfo',
  payload: {type: string, start: number, end: number},
};

export const setContentWarningClickInfo = (
  type: string,
  start: number,
  end: number,
): SetContentWarningClickInfoAction => ({
  type: SET_CONTENT_WARNING_CLICK_INFO,
  payload: {type, start, end},
});

type SetMultiChannelInboxAction = {
  type: 'messages/setMultiChannelInbox',
  payload: {value: boolean},
};

export const setMultiChannelInboxEvent = (
  value: boolean,
): SetMultiChannelInboxAction => ({
  type: SET_MULTI_CHANNEL_INBOX,
  payload: {value},
});

type SetReceivedPhoneNumberAction = {
  type: 'messages/setReceivedPhoneNumber',
  payload: {id: string, type: string},
};

export const setReceivedPhoneNumberEvent = (
  id: string,
  type: string,
): SetReceivedPhoneNumberAction => ({
  type: MESSAGE_RECEIVED_NUMBER,
  payload: {id, type},
});

type SetActiveActionTypeAction = {
  type: 'messages/setActiveActionType',
  payload: {value: string},
};

export const setActiveActionTypeEvent = (
  value: string,
): SetActiveActionTypeAction => ({
  type: MESSAGE_ACTIVE_ACTION_TYPE,
  payload: {value},
});

type SetActiveTemplateAction = {
  type: 'messages/setActiveTemplate',
  payload: {value: *},
};

export const setActiveTemplateEvent = (value: *): SetActiveTemplateAction => ({
  type: MESSAGE_ACTIVE_TEMPLATE,
  payload: {value},
});

type CallContentBannerApiAction = {
  type: 'messages/callContentBannerApi',
  payload: {value: boolean},
};

export const callContentBannerApiEvent = (
  value: boolean,
): CallContentBannerApiAction => ({
  type: CALL_CONTENT_BANNER_API,
  payload: {value},
});

type SetMeetingsScheduleEventDataAction = {
  type: 'messages/meetingsScheduleEventData',
  payload: MeetingsEventData,
};

export const setMeetingsScheduleEventData = (
  payload: MeetingsEventData,
): SetMeetingsScheduleEventDataAction => ({
  type: MEETINGS_SCHEDULE_EVENT_DATA,
  payload,
});

type ShowUserDetailsIconAction = {
  type: 'messages/showUserDetailsIcon',
  payload: {value: boolean},
};

export const showUserDetailsIconActionEvent = (
  value: boolean,
): ShowUserDetailsIconAction => ({
  type: SHOW_USER_DETAILS_ICON,
  payload: {value},
});

type SetExternalEventDetailsAction = {
  type: 'messages/setExternalEventDetails',
  payload: ExternalEventDetails,
};

export const setExternalEventDetails = (
  payload: ExternalEventDetails,
): SetExternalEventDetailsAction => ({
  type: SET_EXTERNAL_EVENT_DETAILS,
  payload,
});

export const resolveThread =
  (threadId: string): ThunkAction<mixed> =>
  async (dispatch: Dispatch) => {
    const response = await dispatch(
      reduxApi.post(`messages_v2/thread/${threadId}/close`, {}),
    );

    dispatch(receiveThread(camel(response)));
  };

export const fetchAllInboxes: () => ThunkAction<mixed> = flow(
  key(() => 'chat/queues/fetchAll'),
  cached((response) => {
    return receiveQueueBasedInboxes(response);
  }),
)(
  () =>
    (dispatch: Dispatch): Promise<Response> =>
      dispatch(reduxApi.get('messages_v2/inbox', {}, {apiPath: '/api/v2/'})),
);
