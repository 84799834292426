// @flow strict

//$FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {EntityLifecycleGoal} from 'src/types/entity-lifecycle-goal';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, cached, fetching} from 'src/utils/redux';

// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';


export type EntityLifecycleGoalAction = ReceiveEntityLifecycleGoalAction;

type ReceiveEntityLifecycleGoalAction = {
  type: 'entityLifecycleGoal/receive',
  payload: Array<EntityLifecycleGoal>,
};

export const RECEIVE_ENTITY_LIFECYCLE_GOAL: 'entityLifecycleGoal/receive' =
  'entityLifecycleGoal/receive';

export const receiveEntityLifecycleGoal = (
  payload: Array<EntityLifecycleGoal>,
): ReceiveEntityLifecycleGoalAction => ({
  type: RECEIVE_ENTITY_LIFECYCLE_GOAL,
  payload,
});

export const getEntityLifecycleGoals: () => ThunkAction<void> = flow(
  key(() => 'getEntityLifecycleGoals'),
  cached((response) => receiveEntityLifecycleGoal(response), {
    ttl: 60 * 60 * 1000,
  }),
  fetching(),
)(() => reduxApi.get('target-lifecycle-business-goals'));
