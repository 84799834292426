// @flow strict

import type {
  AutomationWorkflowAPI,
  AutomationWorkflowsSummaryAPI,
  AutomationWorkflowsCategoriesAPI,
  CategorySortKey,
} from 'src/types/automation-workflow-organizer';
import type {AutomationWorkflowOrganizerAction} from 'src/action-creators/automation-workflow-organizer';

import {
  RESET_AUTOMATION_WORKFLOW_ORGANIZER,
  RECEIVE_AUTOMATION_WORKFLOWS,
  RECEIVE_AUTOMATION_WORKFLOWS_SUMMARY,
  RECEIVE_AUTOMATION_WORKFLOWS_CATEGORIES,
  SET_QUERY,
  CLEAR_QUERY,
  FILTER_CHANGE,
  SELECTION_CHANGE,
  SELECT_ALL,
  PAGE_CHANGE,
  TOTAL_PAGE_CHANGE,
  SET_BULK_ACTION,
  FINALISE_BULK_ACTION,
  SET_ELLIPSIS_SELECTED,
  SET_CATEGORY_SORT_KEY,
} from 'src/action-creators/automation-workflow-organizer';


export type BulkActionType = ?(
  | 'delete'
  | 'bulkDelete'
  | 'duplicate'
  | 'archive'
  | 'addToACategory'
  | 'moveToACategory'
  | 'bulkAddToACategory'
  | 'bulkMoveToACategory'
  | 'removeFromACategory'
  | 'deleteCategory'
);

export type AutomationWorkflowOrganizerState = {
  automation_workflows: AutomationWorkflowAPI[],
  total: number,
  automation_workflows_summary: AutomationWorkflowsSummaryAPI,
  automation_workflows_categories: AutomationWorkflowsCategoriesAPI[],
  query: string,
  otherFilters: $Shape<{status: string[], entity: string[]}>,
  sort: {sortBy: string, sortOrder: 'asc' | 'desc' | 'original'},
  selected: Set<string>,
  ellipsisSelected: string,
  selectAll: boolean,
  idsForBulkAction: Map<string, string[]>,
  activeBulkAction: BulkActionType,
  pageNumber: number,
  totalPages: number,
  reset: boolean,
  selectedCategory: string,
  categorySortKey: CategorySortKey,
};

const initialState = {
  automation_workflows: [],
  total: 0,
  automation_workflows_summary: {
    all: 0,
    uncategorized: 0,
    starred: 0,
    archived: 0,
  },
  automation_workflows_categories: [],
  query: '',
  otherFilters: {status: [], entity: []},
  sort: {sortBy: 'timeCreated', sortOrder: 'desc'},
  selected: new Set(),
  ellipsisSelected: '',
  selectAll: false,
  idsForBulkAction: new Map(),
  activeBulkAction: null,
  pageNumber: 1,
  totalPages: 1,
  reset: false,
  selectedCategory: 'all', // Possible Value for default = 'all', 'starred', 'uncategorised', 'archived'
  categorySortKey: 'oldest',
};

export default (
  state: AutomationWorkflowOrganizerState = initialState,
  action: AutomationWorkflowOrganizerAction,
): AutomationWorkflowOrganizerState => {
  switch (action.type) {
    // case RESET_AUTOMATION_WORKFLOW_ORGANIZER: {
    //   const {payload = initialState} = action;
    //   return {
    //     ...state,
    //     ...payload,
    //   };
    // }
    case RECEIVE_AUTOMATION_WORKFLOWS: {
      return {
        ...state,
        automation_workflows: action.payload,
        total: action.payload.length, // [Rachit Check] Is this the best way?
      };
    }
    case RECEIVE_AUTOMATION_WORKFLOWS_SUMMARY: {
      return {
        ...state,
        automation_workflows_summary: action.payload,
      };
    }
    case RECEIVE_AUTOMATION_WORKFLOWS_CATEGORIES: {
      return {
        ...state,
        automation_workflows_categories: action.payload,
      };
    }
    case SET_QUERY:
      return {...state, query: action.payload};
    case CLEAR_QUERY:
      return {...state, query: ''};
    case FILTER_CHANGE: {
      const {field} = action.payload;
      const {value} = action.payload;
      //archive and live are mutually exclusive so we clear live when
      //archive is selected and vice-versa
      // if (field === 'status' && value.includes('archived')) {
      //   value = value.filter((status) => status !== 'live');
      // }
      // if (field === 'status' && value.includes('live')) {
      //   value = value.filter((status) => status !== 'archived');
      // }
      return {
        ...state,
        otherFilters: {...state.otherFilters, [field]: value ?? []},
      };
    }
    case SELECTION_CHANGE:
    case SELECT_ALL:
      const {
        selected,
        toDelete,
        toArchive,
        toAddToACategory,
        toMoveToACategory,
        toRemoveFromACategory,
      } = action.payload;

      return {
        ...state,
        selected: new Set(selected),
        idsForBulkAction: new Map([
          ['delete', toDelete],
          ['archive', toArchive],
          ['addToACategory', toAddToACategory],
          ['moveToACategory', toMoveToACategory],
          ['removeFromACategory', toRemoveFromACategory],
        ]),
      };
    case PAGE_CHANGE: {
      return {
        ...state,
        pageNumber: action.payload,
      };
    }
    case TOTAL_PAGE_CHANGE: {
      const totalPages = action.payload;
      //if total pages is less than the current page, set the current page to the last page
      const pageNumber = Math.min(state.pageNumber, totalPages);

      return {
        ...state,
        pageNumber,
        totalPages: action.payload,
      };
    }
    case SET_BULK_ACTION: {
      return {
        ...state,
        activeBulkAction: action.payload,
      };
    }
    // case SORT_CHANGE: {
    //   const {sortBy, sortOrder} = action.payload;
    //   return {
    //     ...state,
    //     sort: {sortBy, sortOrder},
    //   };
    // }
    case FINALISE_BULK_ACTION: {
      return {
        ...state,
        activeBulkAction: null,
      };
    }
    case RESET_AUTOMATION_WORKFLOW_ORGANIZER: {
      return {
        ...initialState,
      };
    }
    case SET_ELLIPSIS_SELECTED: {
      return {
        ...state,
        ellipsisSelected: action.payload,
      };
    }
    case SET_CATEGORY_SORT_KEY: {
      return {
        ...state,
        categorySortKey: action.payload,
      };
    }
  }
  return state;
};
