// @flow strict
import * as React from 'react';
import {
  // $FlowFixMe[untyped-import]
  autoUpdate,
  // $FlowFixMe[untyped-import]
  flip,
  // $FlowFixMe[untyped-import]
  offset,
  // $FlowFixMe[untyped-import]
  shift,
  // $FlowFixMe[untyped-import]
  useFloating,
} from '@floating-ui/react';
import startCase from 'lodash/startCase';
import {useSelector} from 'react-redux';
import {classify} from 'src/utils/classify';
import {useHistory} from 'src/rerouter/hooks.js';
//$FlowFixMe[nonstrict-import]
import {getCurrentAgent} from 'src/selectors/accounts';
//$FlowFixMe[nonstrict-import]
import {selectAgencyData} from 'src/selectors/agency';
import {Tooltip} from '@spaced-out/ui-design-system/lib/components/Tooltip';
import {Avatar} from '@spaced-out/ui-design-system/lib/components/Avatar';
import {Icon} from '@spaced-out/ui-design-system/lib/components/Icon';
import {
  FormLabelMedium,
  BodySmall,
  TEXT_COLORS,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {Truncate} from '@spaced-out/ui-design-system/lib/components/Truncate';
import {
  ButtonDropdown,
  STRATEGY_TYPE,
  ANCHOR_POSITION_TYPE,
} from '@spaced-out/ui-design-system/lib/components/ButtonDropdown';
import {CustomDropdown} from 'src/components/app-shell/custom-dropdown.jsx';
import {size200} from '@spaced-out/ui-design-system/lib/styles/variables/_size.js';

import css from './account-bar.css';


type AccountBarProps = {
  expanded: boolean,
  role?: string,
  handleSenseUniversityOnClick: () => void,
};

const accountMenuOptions = [
  {
    key: 'sense_learning_center',
    label: 'Sense Learning Center',
    iconLeft: 'graduation-cap',
  },
  {
    key: 'change_password',
    label: 'Change password',
    iconLeft: 'key',
  },
  {
    key: 'support',
    label: 'Support',
    iconLeft: 'headset',
  },
  {
    key: 'signout',
    label: 'Sign out',
    iconLeft: 'arrow-right-from-bracket',
  },
];

export const AccountBar = ({
  expanded,
  handleSenseUniversityOnClick,
}: AccountBarProps): React.Node => {
  const history = useHistory();
  const {location} = history;
  const {name, profilePictureUrl} = useSelector((state) =>
    getCurrentAgent(state),
  );
  const {name: agencyName} = useSelector(selectAgencyData);
  const handleAccountOptionClick = (option) => {
    if (option.key === 'sense_learning_center') {
      handleSenseUniversityOnClick();
    } else if (option.key === 'change_password') {
      history.push('/reset');
    } else if (option.key === 'support') {
      window.open('https://support.sensehq.co', '_blank');
    } else if (option.key === 'signout') {
      history.push('/signout');
    }
  };
  return (
    <CustomDropdown
      anchorPosition="right"
      ariaLabel={name}
      menu={{
        isFluid: false,
        menuDisabled: false,
        options: accountMenuOptions,
        width: size200,
        classNames: {wrapper: css.menuShift},
      }}
      {...(!expanded ? {tooltip: {body: name}} : {})}
      isFluid
      onOptionSelect={handleAccountOptionClick}
      tabIndex={0}
      classNames={{
        dropdownContainer: classify(css.accountDropdownContainer, {
          [css.collapsedDropdownContainer]: !expanded,
        }),
        wrapper: classify(css.accountContainer, {
          [css.collapsedContainer]: !expanded,
        }),
      }}
    >
      <div className={css.avatarWrap}>
        <Avatar text={name} ring={true} imageSrc={profilePictureUrl} />
      </div>
      {expanded && (
        <div className={css.nameContainerRow}>
          <div className={css.userDetails}>
            <FormLabelMedium color={TEXT_COLORS.inversePrimary}>
              <Truncate>{name}</Truncate>
            </FormLabelMedium>
            <BodySmall color={TEXT_COLORS.inverseSecondary}>
              <Truncate>{startCase(agencyName)}</Truncate>
            </BodySmall>
          </div>
          <div className={css.arrowWrap}>
            <Icon
              name="chevron-right"
              size="small"
              type="solid"
              color={TEXT_COLORS.inversePrimary}
            />
          </div>
        </div>
      )}
    </CustomDropdown>
  );
};
