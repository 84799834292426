// @flow

import * as React from 'react';
import {Link} from 'src/rerouter';

import classify from 'src/utils/classify';

import css from './small.css';


const SmallTabs = ({
  className,
  tabs,
  baseUrl = '',
}: {
  className?: string,
  tabs: {
    url: string,
    name: string,
    onlyActiveOnIndex?: mixed,
  }[],
  baseUrl?: string,
}): React.Element<'div'> => (
  <div className={classify(css.tabs, className)}>
    {tabs.map(({url, name, onlyActiveOnIndex, ...props}, index) => (
      <div className={css.tab} key={baseUrl + url}>
        {index !== 0 && <div className={css.pipe}>|</div>}
        <Link
          activeClassName={css.active}
          to={baseUrl + url}
          onlyActiveOnIndex={Boolean(onlyActiveOnIndex)}
          {...props}
        >
          {name}
        </Link>
      </div>
    ))}
  </div>
);

export default SmallTabs;
