// @noflow
import omit from 'lodash/omit';

import Store from './base';


export default class RequestStore extends Store {
  state: {
    requests: {
      [key: string]: Promise,
    },
    errors: {
      [key: string]: boolean,
    },
    hostname: string,
  };

  constructor() {
    super('request');

    this.state = {
      requests: {},
      errors: {},
      hostname: '',
    };
  }

  start(key: string, promise: Promise) {
    this.updateState({
      requests: {
        $assign: {
          [key]: promise,
        },
      },
    });
  }

  finish(key: string) {
    this.updateState({
      requests: {
        $set: omit(this.state.requests, key),
      },
      errors: {
        $set: omit(this.state.errors, key),
      },
    });
  }

  inProgress(key: string): Promise {
    return this.state.requests[key];
  }

  error(key: string) {
    this.updateState({
      requests: {
        $set: omit(this.state.requests, key),
      },
      errors: {
        $assign: {
          [key]: true,
        },
      },
    });
  }

  hasError(key: string): boolean {
    return this.state.errors && this.state.errors[key];
  }

  setHostname(hostname) {
    this.updateState({
      hostname: {
        $set: hostname,
      },
    });
  }

  getHostname(): string {
    return this.state.hostname;
  }
}

