// @flow strict

import type {ErrorAction} from 'src/action-creators/errors';
import {
  ERROR_IN_TABS,
  REMOVE_ERROR_IN_TABS,
  REMOVE_ERRORS_IN_TABS,
} from 'src/action-creators/errors';


const ErrorLocations = {
  tabs: 'tabs',
};

export type ErrorState = {
  tabs: {
    errorTabUrls: Set<string>,
  },
  ...
};

const initialState = {
  tabs: {
    errorTabUrls: new Set([]),
  },
};

export default (
  state: ErrorState = initialState,
  action: ErrorAction,
): ErrorState => {
  switch (action.type) {
    case ERROR_IN_TABS: {
      return {
        [ErrorLocations.tabs]: {
          errorTabUrls: new Set([
            ...state[ErrorLocations.tabs].errorTabUrls,
            ...action.payload.urls,
          ]),
        },
      };
    }
    case REMOVE_ERROR_IN_TABS: {
      const errors = state[ErrorLocations.tabs].errorTabUrls;
      errors.delete(action.payload.url);
      return {
        [ErrorLocations.tabs]: {
          errorTabUrls: new Set([...errors]),
        },
      };
    }
    case REMOVE_ERRORS_IN_TABS: {
      const errors = state[ErrorLocations.tabs].errorTabUrls;

      return {
        [ErrorLocations.tabs]: {
          errorTabUrls: new Set(
            [...errors].filter((error) => !action.payload.urls.includes(error)),
          ),
        },
      };
    }
  }

  return state;
};
