// @flow strict
import type {
  //$FlowFixMe[nonstrict-import]
  SelectProps as OldSelectProps,
  //$FlowFixMe[nonstrict-import]
  SuggestionProps,
  //$FlowFixMe[nonstrict-import]
  InputAndOptionsClassNames,
} from 'src/components/lib/token-list-input/new-stuff.jsx';

import * as React from 'react';
import {classify} from 'src/utils/classify';

import {
  //$FlowFixMe[nonstrict-import]
  InputAndOptions,
  //$FlowFixMe[nonstrict-import]
  DefaultValueComponent,
  //$FlowFixMe[nonstrict-import]
  Placeholder,
  //$FlowFixMe[nonstrict-import]
  DefaultSuggestion,
} from 'src/components/lib/token-list-input/new-stuff.jsx';
//$FlowFixMe[nonstrict-import]
import {useSenseTypeahead} from 'src/components/lib/token-list-input/hooks.js';
import {Smallest} from 'src/designSystem2021Components/text-v2.jsx';
import CaretDownIcon from 'src/images/icons/caret-down.svg?noAttrs';

import CancelIcon from 'src/images/close-x-thick.svg';
import css from './select.css';

/**
 * an input that allows selecting a value from a limited set of options.
 *
 * Very similar to the HTML `select` tag but with more bells and whistles.
 *
 * @param  props.value  the controlled value
 * @param  props.onChange  the handler that is fired when the user tries to change the value
 * @param  props.name  the name of the input (useful if it is part of a form)
 * @param  props.limit  the maximum number of item the user can add
 * @param  props.TokenComponent  a custom component used to render each token within the input
 *
 * @param  props.options  total list of allowed values for this field. will be used to provide suggestions.
 * @param  props.searchable  whether or not to filter the list of options when the user types in the input
 * @param  props.allowArbitraryValues  whether or not the user can specify their own custom values
 * @param  props.validateArbitraryValue  a function that determines if a custom value is valid
 * @param  props.formatArbitraryOptionLabel  a function that customizes the custom value when it shows up as a suggestion
 * @param  props.searchOnEmptyString  whether or not to show suggestions when there is no text in the search input
 * @param  props.groupOptionsBy  a function that produces a category key for any given option. if specified, suggestions will be grouped into sections by category.
 * @param  props.sortOptionsBy  a comparison function that determines the sort order of suggestions. by default it sorts them alphabetically.
 */

export type SelectProps<T> = {
  ...OldSelectProps<T>,
  label?: React.Node,
  contextLabel?: React.Node,
  classNames?: SelectClassNames,
};

export type SelectOption<T> = {
  label: T,
  value: T,
  ...
};
export type SelectClassNames = $ReadOnly<{
  ...InputAndOptionsClassNames,
  label?: ?string,
  contextLabel?: ?string,
  ...
}>;

export function Select<T>(props: SelectProps<T>): React.Node {
  const handleAddOption = (option) => {
    onChange(option);
    // $FlowIssue
    inputProps.ref.current?.blur();
  };
  const {
    value,
    onChange,
    name,
    searchable,
    clearable,
    disabled,
    autofocus,
    placeholder,
    hasLabel,
    optionsDirection,
    baseClassNames = css,
    classNames,
    error,
    errorText,
    showAllOptions,
    components = {},
    // $FlowFixMe[incompatible-use]
    resolveKey = (option) => option.value,
    // $FlowFixMe[incompatible-use]
    resolveLabel = (option) => option.label,
    label,
    contextLabel,
  } = props;

  const {ValueComponent = DefaultValueComponent} = components;
  if (!components.Suggestion) {
    components.Suggestion = Suggestion;
  }

  const [
    {selectedIndex},
    filteredOptions,
    groupedOptions,
    inputProps,
    handleContainerMouseDown,
    handleSelect,
    {isSearching, focused, isShowingOptions},
  ] = useSenseTypeahead({
    onAddOption: handleAddOption,
    ...props,
    // $FlowFixMe[incompatible-call]
    resolveKey,
    // $FlowFixMe[incompatible-call]
    resolveLabel,
  });

  const showClearButton = clearable && value && !disabled;
  const showChevron = !showClearButton && !disabled;
  // returns true when a value exists or can be determined using
  // resolveKey (which is used to fetch V typed values)
  const hasValue = (data: ?T): boolean => {
    if (data == null) {
      return false;
    }

    if (resolveKey(data) == null) {
      return false;
    }

    return true;
  };

  return (
    <div className={css.wrapper}>
      {(Boolean(label) || Boolean(contextLabel)) && (
        <div className={css.info}>
          <Smallest
            className={classify(css.commonLabel, classNames?.label, {
              [css.errorLabel]: error ?? false,
            })}
          >
            {label ?? ''}
          </Smallest>
          <Smallest
            className={classify(css.commonLabel, classNames?.contextLabel, {
              [css.errorLabel]: error ?? false,
            })}
          >
            {contextLabel ?? ''}
          </Smallest>
        </div>
      )}
      <div>
        <InputAndOptions
          error={error}
          errorText={errorText}
          baseClassNames={baseClassNames}
          classNames={classNames}
          options={props.groupOptionsBy ? groupedOptions : filteredOptions}
          optionsDirection={optionsDirection}
          showAllOptions={showAllOptions}
          selectedIndex={searchable ? selectedIndex : null}
          onSelect={handleSelect}
          onContainerMouseDown={handleContainerMouseDown}
          components={components}
          inputProps={{
            ...props.inputProps,
            outerRef: props.inputProps?.ref,
            disabled,
            ...inputProps,
          }}
          isSearching={isSearching}
          isFocused={focused}
          isShowingOptions={isShowingOptions}
          resolveLabel={resolveLabel}
          resolveKey={resolveKey}
          suffixLabel={
            showClearButton ? (
              <div
                className={css.clearButton}
                onMouseDown={() => onChange(null)}
              >
                <CancelIcon />
              </div>
            ) : (
              showChevron && (
                <div>
                  <CaretDownIcon
                    className={classify(
                      css.chevron,
                      optionsDirection && css[optionsDirection],
                      focused && css.chevronUp,
                    )}
                  />
                </div>
              )
            )
          }
          autofocus={autofocus}
          // $FlowFixMe[incompatible-type]
          resolveLabel={resolveLabel}
          // $FlowFixMe[incompatible-type]
          resolveKey={resolveKey}
        >
          {!isSearching && value != null && (
            // $FlowFixMe[incompatible-type]
            <ValueComponent value={value} resolveLabel={resolveLabel} />
          )}
          {placeholder && (
            <Placeholder
              className={css.placeholder}
              placeholder={placeholder}
              isLabel={hasLabel}
              isHidden={isSearching || hasValue(value)}
            />
          )}
          <input
            type="hidden"
            name={name}
            value={value != null ? resolveKey(value) : null}
          />
        </InputAndOptions>
      </div>
    </div>
  );
}

const Suggestion = <T>(props: SuggestionProps<T>) => (
  <DefaultSuggestion className={css.option} {...props} />
);
