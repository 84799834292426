// @flow

import * as React from 'react';
import {Link} from 'src/rerouter';

import css from './top-nav-link.css';


export type Props = React.ElementProps<typeof Link> & {
  expanded: mixed,
  icon: React.Element<any>,
  label: string,
  ...
};

export default ({expanded, icon, label, ...props}: Props): React.Node => (
  <Link
    className={css.topNavItem}
    activeClassName={css.topNavItemOn}
    {...props}
    data-qa-id={`select-${label.toLowerCase()}-page`}
  >
    <div className={css.topNavIconContainer}>{icon}</div>
    <span
      className={expanded ? css.topNavTextExpanded : css.topNavTextCollapsed}
    >
      {label}
    </span>
  </Link>
);
