// @flow

import type {SenseAction} from 'src/action-creators/types';

import logger from 'src/utils/logger';


type RequestState = {
  [key: string]: {
    inflight?: any,
    errors?: any,
    progress?: number,
  },
};

type RequestActionBase = {
  meta: {
    key: string,
  },
};

type BeginAction = {
  ...RequestActionBase,
  type: '@@requestCache/begin',
  payload: Promise<*>,
};
type EndAction = {
  ...RequestActionBase,
  type: '@@requestCache/end',
};
type ErrorAction = {
  ...RequestActionBase,
  type: '@@requestCache/error',
  error: Error,
};
type ProgressAction = {
  ...RequestActionBase,
  type: '@@requestCache/progress',
  payload: number,
};

type SenseRequestAction =
  | BeginAction
  | EndAction
  | ErrorAction
  | ProgressAction;

export default (
  state: RequestState = Object.freeze({}),
  action: SenseRequestAction,
): RequestState => {
  if (action.type.startsWith('@@requestCache/')) {
    const {
      meta: {key},
    } = action;

    switch (action.type) {
      case '@@requestCache/begin': {
        const inflight = action.payload;
        return {...state, [key]: {inflight, progress: 0}};
      }
      case '@@requestCache/end': {
        const {[key]: _, ...rest} = state;
        return rest;
      }
      case '@@requestCache/error': {
        const {error} = action;
        return {...state, [key]: {error}};
      }
      case '@@requestCache/progress': {
        const request = state[key];
        if (!request) {
          logger.warn(
            'Request cache attempted to update progress on an undefined request.',
          );
          return state;
        }

        const progress = action.payload;
        return {...state, [key]: {...request, progress}};
      }
      default:
        return state;
    }
  }

  return state;
};
