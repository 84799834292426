// @flow

import type {EntityType} from 'src/types/ats-entities';
import type {Dispatch, ThunkAction} from 'src/reducers';

import flow from 'lodash/flow';

import {snake} from 'src/utils';
import {key, cached, fetching, cacheSetAction} from 'src/utils/redux';

import * as reduxApi from 'src/utils/redux-api';

// at some point there may be other kinds (for now only strings)
type GlobalVariableTypes = 'string';

type APIGVField = {
  id: string,
  field_value: ?string,
  plain_text: string,
};

export type APIGlobalVariable = {
  id: string,
  name: string,
  type: GlobalVariableTypes,
  field_name: string,
  entity_type?: EntityType,
  use_default_plaintext: boolean,
  default_plain_text: string,
  field_values: APIGVField[],
};

export type GVField = {
  id: string,
  fieldValue?: ?string,
  plainText?: string,
};

export type PartialGVField = {
  id?: string,
  fieldValue?: ?string,
  plainText?: string,
};

export type GlobalVariable = {
  id: string,
  name: string,
  type: GlobalVariableTypes,
  fieldName: string,
  entityType?: EntityType,
  relatedEntities?: EntityType[],
  defaultPlainText: string,
  fieldValues: GVField[],
  useDefaultPlainText?: boolean,
};

export type PartialGlobalVariable = {
  fieldName?: string,
  entityType?: EntityType,
  defaultPlainText?: string,
  fieldValues?: GVField[],
  useDefaultPlainText?: boolean,
};

export const RECEIVE = 'globalVariables/receive';
export const DELETE = 'globalVariables/delete';
export const UPDATE = 'globalVariables/update';
export const SET_MAPPING_ERROR = 'globalVariables/setMappingError';
export const UPDATE_FIELD = 'globalVariables/field/update';
export const RECEIVE_FIELD = 'globalVariables/field/create';
export const DELETE_FIELD = 'globalVariables/field/delete';

type ReceiveAction = {
  type: 'globalVariables/receive',
  payload: GlobalVariable[],
};
type DeleteAction = {type: 'globalVariables/delete', meta: {id: string}};
type CreateFieldAction = {
  type: 'globalVariables/field/create',
  payload: GVField,
  meta: {gvarId: string},
};
type DeleteFieldAction = {
  type: 'globalVariables/field/delete',
  meta: {gvarId: string, fieldId: string},
};

type UpdateAction = {
  type: 'globalVariables/update',
  payload: {gvId: string, ...PartialGlobalVariable},
};

type UpdateFieldAction = {
  type: 'globalVariables/field/update',
  payload: {...PartialGVField},
  meta: {
    gvId: string,
    fieldId: string,
  },
};

export type MappingErrorAction = {
  type: 'globalVariables/setMappingError',
  meta: {
    gvId: string,
  },
  payload: boolean,
};

export type GlobalVariableAction =
  | ReceiveAction
  | DeleteAction
  | UpdateAction
  | CreateFieldAction
  | UpdateFieldAction
  | DeleteFieldAction
  | MappingErrorAction;

export type ReceiveActionCreator = (payload: GlobalVariable[]) => ReceiveAction;
export const receiveAction: ReceiveActionCreator = (payload) => ({
  type: RECEIVE,
  payload,
});

export type DeleteActionCreator = (id: string) => DeleteAction;
export const deleteAction: DeleteActionCreator = (id) => ({
  type: DELETE,
  meta: {
    id,
  },
});

export const updateAction = (
  gvId: string,
  changes: PartialGlobalVariable,
): UpdateAction => ({
  type: UPDATE,
  payload: {
    gvId,
    ...changes,
  },
});

export const updateFieldAction = (
  gvId: string,
  fieldId: string,
  changes: PartialGVField,
): UpdateFieldAction => ({
  type: UPDATE_FIELD,
  meta: {
    gvId,
    fieldId,
  },
  payload: {
    ...changes,
  },
});

export type ReceiveFieldActionCreator = (
  gvarId: string,
  payload: GVField,
) => CreateFieldAction;
export const receiveFieldAction: ReceiveFieldActionCreator = (
  gvarId,
  payload,
) => ({
  type: RECEIVE_FIELD,
  payload,
  meta: {
    gvarId,
  },
});

export type DeleteFieldActionCreator = (
  gvarId: string,
  fieldId: string,
) => DeleteFieldAction;
export const deleteFieldAction: DeleteFieldActionCreator = (
  gvarId,
  fieldId,
) => ({
  type: DELETE_FIELD,
  meta: {
    gvarId,
    fieldId,
  },
});

export const setGvMappingError = (
  gvId: string,
  hasMappingError: boolean,
): ThunkAction<MappingErrorAction> => (dispatch) =>
  dispatch({
    type: SET_MAPPING_ERROR,
    meta: {
      gvId,
    },
    payload: hasMappingError,
  });

const normalizeField = (gvField: GVField): APIGVField => ({
  plain_text: gvField.plainText ?? '',
  id: gvField.id,
  field_value: gvField.fieldValue === undefined ? '' : gvField.fieldValue,
});

const normalize = (gv: GlobalVariable): APIGlobalVariable => ({
  id: gv.id,
  name: gv.name,
  type: gv.type,
  field_name: gv.fieldName,
  entity_type: gv.entityType,
  use_default_plaintext: Boolean(gv.useDefaultPlainText),
  default_plain_text: gv.defaultPlainText ?? '',
  field_values: gv.fieldValues.map(normalizeField),
});

const parseField = (apiField: APIGVField): GVField => ({
  plainText: apiField.plain_text,
  id: apiField.id,
  fieldValue: apiField.field_value,
});

const parse = (apiGv: APIGlobalVariable): GlobalVariable => ({
  id: apiGv.id,
  name: apiGv.name,
  type: apiGv.type,
  fieldName: apiGv.field_name,
  entityType: apiGv.entity_type,
  useDefaultPlainText: apiGv.use_default_plaintext || false,
  defaultPlainText: apiGv.default_plain_text,
  fieldValues: apiGv.field_values.map(parseField),
});

const isValid = (gv: GlobalVariable): mixed => {
  const fieldValues = gv.fieldValues;
  const visitedValues = new Map();

  for (let {fieldValue} of fieldValues) {
    fieldValue = fieldValue === undefined ? '' : fieldValue;
    if (visitedValues.has(fieldValue)) {
      return false;
    } else {
      visitedValues.set(fieldValue, true);
    }
  }

  return true;
};

export const getGlobalVariables: () => ThunkAction<mixed> = flow(
  key(() => 'global variables'),
  cached((gvArr) => receiveAction(gvArr.map(parse)), {ttl: 2000}),
  fetching({}),
)(() => reduxApi.get('global-variables'));

// allow passing in tags or variableNames to filter search
export const getGlobalVariable: (
  variableId: string,
) => ThunkAction<mixed> = flow(
  key((variableId) => `global variable:${variableId}`),
  cached((gv) => receiveAction([parse(gv)]), {ttl: 1000}),
  fetching({}),
)((variableId: string) => reduxApi.get(`global-variables/${variableId}`));

export const createGlobalVariable = (data: {|
  name: string,
  field: string,
  entityType?: EntityType,
  type: string,
|}): ThunkAction<GlobalVariable> => (dispatch: Dispatch) =>
  dispatch(reduxApi.post(`global-variables`, snake(data))).then((response) => {
    // $FlowFixMe[prop-missing] why are we spreading here?
    const createdVar = parse({...response});
    // TODO(marcos): manually dispatch a cache receipt here?
    dispatch(receiveAction([createdVar]));
    dispatch(cacheSetAction(`global variable:${createdVar.id}`));
    return createdVar;
  });

export const updateGlobalVariable = (
  gv: GlobalVariable,
): ThunkAction<MappingErrorAction> => (dispatch) => {
  if (isValid(gv)) {
    const args = normalize(gv);
    dispatch(setGvMappingError(gv.id, false));
    return dispatch(
      reduxApi.put(`global-variables/${gv.id}`, args),
    ).then((updated) => dispatch(receiveAction([parse(updated)])));
  }

  return Promise.resolve().then(() => dispatch(setGvMappingError(gv.id, true)));
};

export const updateUseDefaultText = (
  gvId: string,
  useDefaultPlainText: boolean,
): UpdateAction =>
  updateAction(gvId, {
    useDefaultPlainText,
  });

export const updateDefaultText = (
  gvId: string,
  defaultPlainText: string,
): UpdateAction =>
  updateAction(gvId, {
    defaultPlainText,
  });

export const deleteGlobalVariable = (
  globalVariableId: string,
): ThunkAction<void> => (dispatch) =>
  dispatch(reduxApi.del(`global-variables/${globalVariableId}`)).then(() =>
    dispatch(deleteAction(globalVariableId)),
  );

export const createGlobalVariableField = (
  globalVariableId: string,
  fieldId: string,
): ThunkAction<CreateFieldAction> => (dispatch) =>
  dispatch(
    receiveFieldAction(globalVariableId, {
      id: fieldId,
    }),
  );

export const addNullGlobalVariableField = (
  globalVariableId: string,
  fieldId: string,
): CreateFieldAction =>
  receiveFieldAction(globalVariableId, {
    id: fieldId,
    fieldValue: null,
  });

export const removeNullGlobalVariableField = (
  globalVariableId: string,
  fieldId: string,
): ThunkAction<GVField> => deleteGlobalVariableField(globalVariableId, fieldId);

export const updateGlobalVariableField = (
  globalVariableId: string,
  fieldId: string,
  changes: PartialGVField,
): ThunkAction<GVField> => (dispatch) =>
  dispatch(updateFieldAction(globalVariableId, fieldId, changes));

export const deleteGlobalVariableField = (
  globalVariableId: string,
  fieldValueId: string,
): ThunkAction<GVField> => (dispatch) =>
  dispatch(deleteFieldAction(globalVariableId, fieldValueId));
