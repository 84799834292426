// @flow

import type {ReleaseFlags} from 'src/types/product-flags';
import type {ThunkAction} from 'src/reducers';

import {thunkify as flow} from 'src/utils/thunks';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api-v2.js';
import {camel} from 'src/utils';


export type Action = ReceiveReleaseFlagsAction;

export const RECEIVE_RELEASE_FLAGS = 'flags/receiveReleaseFlags';

export type ReceiveReleaseFlagsAction = {
  type: 'flags/receiveReleaseFlags',
  payload: ReleaseFlags,
};

export const receiveReleaseFlags = (
  payload: ReleaseFlags,
): ReceiveReleaseFlagsAction => ({
  type: RECEIVE_RELEASE_FLAGS,
  payload,
});

export const getReleaseFlags: () => ThunkAction<mixed> = flow(
  key(() => 'releaseFlags'),
  cached((json) => receiveReleaseFlags(camel(json)), {ttl: 120 * 1000}),
  fetching(),
)(() => reduxApi.get('product-flags/release-flags'));
