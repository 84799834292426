// @noflow
import type {DueEvent} from 'src/api-parsers/events';
import Store from './base';


export default class AudienceMemberDueEventStore extends Store {
  state: {
    [key: string]: DueEvent[],
  };

  constructor() {
    super('audience-member-due-events');

    this.state = {};
  }

  get(audienceMemberId: string): DueEvent[] {
    return this.state[audienceMemberId];
  }

  receive(audienceMemberId: string, dueEvents: DueEvent[]) {
    this.updateState({
      [audienceMemberId]: {
        $set: dueEvents,
      },
    });
  }
}
