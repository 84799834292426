// @flow

import type {Dispatch, GetState, ThunkAction} from 'src/reducers';
import type {LATQueueEntry} from 'src/types/live-agent-transfer';
import type {QueueBasedInbox} from 'src/types/chat/queues';

import {thunkify} from 'src/utils/thunks';
import {key, cached} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api-v2';

import {batch} from 'src/action-creators/batch';
import {receive as receivePhoneNumberSets} from 'src/action-creators/chat/phone-number-sets';
import {selectCurrentNotificationId} from 'src/selectors/messaging-queues.js';
import {toastApi} from '@spaced-out/ui-design-system/lib/components/Toast';


export type QueuesAction =
  | ReceiveAction
  | ReceiveInboxesAction
  | ReceiveQueueJoinLoadState
  | ReceiveQueueNotificationId
  | UpdateQueueBasedInboxesAction;

type ReceiveAction = {
  type: 'chat/queues/receive',
  payload: LATQueueEntry,
};

type ReceiveInboxesAction = {
  type: 'chat/queues/receive-inboxes',
  payload: {queue_based_inboxes: QueueBasedInbox[], ...},
};

type UpdateQueueBasedInboxesAction = {
  type: 'chat/queues/update-queue-based-inboxes',
  payload: QueueBasedInbox,
};

type ReceiveQueueJoinLoadState = {
  type: 'chat/queues/update-queue-join-load-state',
  payload: boolean,
};

type ReceiveQueueNotificationId = {
  type: 'chat/queues/receive-queue-notification-id',
  payload: string,
};

export const RECEIVE_QUEUE = 'chat/queues/receive';
export const RECEIVE_QUEUE_BASED_INBOXES = 'chat/queues/receive-inboxes';
export const UPDATE_QUEUE_BASED_INBOXES =
  'chat/queues/update-queue-based-inboxes';
export const UPDATE_QUEUE_JOIN_LOAD_STATE =
  'chat/queues/update-queue-join-load-state';

export const RECEIVE_QUEUE_NOTIFICATION_ID =
  'chat/queues/receive-queue-notification-id';

export const receiveQueue = (payload: LATQueueEntry): ReceiveAction => ({
  type: RECEIVE_QUEUE,
  payload,
});

export const receiveQueueBasedInboxes = (payload: {
  queue_based_inboxes: QueueBasedInbox[],
  ...
}): ReceiveInboxesAction => ({
  type: RECEIVE_QUEUE_BASED_INBOXES,
  payload,
});

export const updateQueueBasedInboxes = (
  payload: QueueBasedInbox,
): UpdateQueueBasedInboxesAction => ({
  type: UPDATE_QUEUE_BASED_INBOXES,
  payload,
});

export const updateQueueJoinLoadSate = (
  payload: boolean,
): ReceiveQueueJoinLoadState => ({
  type: UPDATE_QUEUE_JOIN_LOAD_STATE,
  payload,
});

export const fetchAllInboxes: () => ThunkAction<mixed> = thunkify(
  key(() => 'chat/queues/fetchAll'),
  cached((response) => {
    return batch(
      receiveQueueBasedInboxes(response),
      receivePhoneNumberSets(response.phone_number_inboxes),
    );
  }),
)(
  () =>
    (dispatch: Dispatch): Promise<Response> =>
      dispatch(reduxApi.get('messages_v2/inbox', {}, {apiPath: '/api/v2/'})),
);

export const fetchQueue: (queueId: string) => ThunkAction<mixed> = thunkify(
  key((queueId: string) => `chat/queues/fetch/queueId:${queueId}`),
  cached(
    (response) => {
      return receiveQueue(response);
    },
    {ttl: 8000},
  ),
)(
  (queueId: string) =>
    (dispatch: Dispatch): Promise<Response> =>
      dispatch(reduxApi.get(`messages_v2/lat/queues/${queueId}`)),
);

export const joinQueue =
  (queueId: string): ThunkAction<mixed> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const response = await dispatch(
      reduxApi.post(`messages_v2/lat/queues/${queueId}/join`),
    );

    return response;
  };

export const receiveQueueNotificationId = (
  payload: string,
): ReceiveQueueNotificationId => ({
  type: RECEIVE_QUEUE_NOTIFICATION_ID,
  payload,
});

export const removeQueueNotificationId =
  (): ThunkAction<void> => async (dispatch: Dispatch, getState: GetState) => {
    const toastId = selectCurrentNotificationId(getState());
    toastApi.remove(toastId);
  };
