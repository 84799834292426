// @noflow

import type {PopulatedThreadList, ThreadList} from 'src/types/thread-lists';
import type {ContactList} from 'src/types/contacts';

import * as React from 'react';
import {connect} from 'react-redux';

import useThreadListEditor from 'src/reducers/thread-list-editor';
import {addToList} from 'src/action-creators/thread-lists';
import {selectThreadListTable} from 'src/selectors/thread-lists';

import Editor from './editor-2.jsx';
import {TitleBarModal} from 'src/components/modals/base-modal.jsx';

import css from './edit.css';


type Props = {
  contactList?: ContactList,
  threadList: PopulatedThreadList,
  threadListId: string,
  removeModal: () => void,
  dispatch: Dispatch,
};

function EditList({threadListId, threads, removeModal, addToList}: Props) {
  const [state, dispatch] = useThreadListEditor();

  const handleSubmit = React.useCallback(async () => {
    await addToList(threadListId, Object.keys(state.members));
    // TODO (iris): add a try/catch
    removeModal();
  }, [removeModal, addToList, threadListId, state.members]);

  return (
    <TitleBarModal
      className={css.root}
      title={'Add Contacts'}
      handleAbort={removeModal}
    >
      <div className={css.root}>
        <Editor
          dispatch={dispatch}
          threads={threads}
          members={state.members}
          adding={state.adding}
          onCancel={removeModal}
          onSubmit={handleSubmit}
        />
      </div>
    </TitleBarModal>
  );
}

const mapStateToProps = (state, {threadListId}) => ({
  threads: selectThreadListTable(state, threadListId),
});

const mapDispatchToProps = {
  addToList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditList);
