// @flow strict

import * as React from 'react';


export default function useSnippet() {
  React.useEffect(() => {
    const snippet = document.createElement('script');
    let widgetIframe, widgetButtonIframe;

    snippet.src =
      'https://sales-journeys-demo.sensehq.com/snippet.js?bottom=40';
    snippet.async = true;
    snippet.onload = () => {
      widgetIframe = document.getElementById('__sense-widget');
      widgetButtonIframe = document.getElementById('__sense-widget-button');
    };

    document.body?.appendChild(snippet);

    return () => {
      if (document.body == null) {
        return;
      }
      document.body.removeChild(snippet);
      if (widgetIframe) {
        document.body?.removeChild(widgetIframe);
      }
      if (widgetButtonIframe) {
        document.body?.removeChild(widgetButtonIframe);
      }
    };
  }, []);
}
