// @flow strict
import type {
  GenericError,
  UserDetails,
  PaymentDetails,
} from 'src/types/referrer-portal';


export const DEFAULT_API_ERROR: GenericError = {
  field_errors: {},
  non_field_errors: [
    {
      type: 'default',
      message: 'Some Error occoured',
    },
  ],
};

export const SUCCESS_PAYLOAD = {
  message: 'success',
};

export const ERROR_CODE = {
  PaymentMethodAlreadyExists: 'Payment Method Already Exists',
  ServerError: 'Internal Server Error',
};

export const REFERRAL_NOTIFICATION = [
  {
    title: 'Submitted',
    description: 'Get email confirmation every time you submit a referral.',
    key: 'submission',
  },
  {
    title: 'Withdrawn',
    description:
      'Get email notification when candidate referred by you withdraw their application.',
    key: 'withdrawn',
  },
  {
    title: 'Status Change',
    description:
      'Get email notification when the status of a referred candidate changes.',
    key: 'status_change',
  },
  {
    title: 'Pending Payout',
    description: 'Get notified when you are eligible for the referral bonus.',
    key: 'payments',
  },
];

export const USER_FORM_INPUT_FIELDS = [
  {key: 'name', label: 'Name'},
  {key: 'email', label: 'Email'},
  {key: 'phone_number', label: 'Phone Number'},
  {key: 'location', label: 'Location'},
  {key: 'company_name', label: 'Company Name'},
  {key: 'position', label: 'Title/Position'},
];

export const BANK_INPUT_FIELDS = [
  {key: 'name', label: 'Name as per Bank Account'},
  {key: 'bank_name', label: 'Bank Name'},
  {key: 'account_number', label: 'Account Number'},
  {key: 'routing_number', label: 'Routing Number'},
  {key: 'swift_code', label: 'Swift Code'},
];

export const userFormInitialValue: UserDetails = {
  id: null,
  name: '',
  email: '',
  profile_picture_url: '',
  phone_number: '',
  position: '',
  bio: '',
  company_name: '',
  location: '',
};

export const paymentInitialData: PaymentDetails = {
  method: 'paypal',
  details: {
    paypal_id: '',
    name: '',
    bank_name: '',
    account_number: '',
    swift_code: '',
    routing_number: '',
  },
};

export const PROFILE_SETUP_STEEPER = {
  user: 0,
  payment: 1,
};

export const SETTINGS_TABS = [
  {tabId: '/settings/profile', label: 'Profile'},
  {
    tabId: '/settings/preferences',
    label: 'Preferences',
  },
];

export const routeToProduct = {
  dashboard: '/dashboard',
  referrals: '/referrals',
  jobs: '/jobs',
  earnings: '/earnings',
  settings: '/settings',
};

export const PRODUCT_LISTS = [
  {product: 'dashboard', iconName: 'chart-column'},
  {product: 'referrals', iconName: 'user-check'},
  {product: 'jobs', iconName: 'briefcase'},
  {product: 'earnings', iconName: 'wallet'},
  {product: 'settings', iconName: 'gear'},
];

export const USER_FORM_URL_SEGMENT = '/u/';
