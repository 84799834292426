// @flow strict

import * as React from 'react';

import css from './staffing-splash.css';

import May31Splash from './may31splash.svg';


export function Hero(): React.Node {
  return (
    <div className={css.hero}>
      <Circles />

      {/* This part varies from version to version, sometimes an image, svg, etc */}
      <May31Splash />

      <SenseLogo />
      <div className={css.heroText}>
        <p className={css.heroDate}>May 31 | 12pm EST</p>
        <h2 className={css.heroHeadline}>
          Smart Targeting & Automation with Sense
        </h2>
        <p className={css.heroSubhead}>
          Learn to leverage dynamic audiences like never before
        </p>
        <a
          className={css.heroCTA}
          href="https://bit.ly/44T8hM2"
          target="_blank"
        >
          Register Now
        </a>
      </div>
    </div>
  );
}

function SenseLogo() {
  return (
    <svg
      className={css.heroLogo}
      width="27"
      height="41"
      viewBox="0 0 27 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5.35408" cy="34.8012" r="5.35408" fill="#7561E6" />
      <circle cx="21.082" cy="24.093" r="5.35408" fill="#33A30F" />
      <circle cx="5.35408" cy="16.061" r="5.35408" fill="#FFD252" />
      <circle cx="21.082" cy="5.35408" r="5.35408" fill="#F05A28" />
    </svg>
  );
}

function Circles() {
  return (
    <svg
      className={css.heroCircles}
      width="678"
      height="678"
      viewBox="0 0 678 678"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2">
        <circle
          cy="678"
          r="678"
          transform="rotate(-90 0 678)"
          fill="url(#paint0_linear_9_8)"
        />
        <circle
          cy="680"
          r="450"
          transform="rotate(-90 0 680)"
          fill="url(#paint1_linear_9_8)"
        />
        <circle
          cx="-0.334396"
          cy="680.333"
          r="206.833"
          transform="rotate(-90 -0.334396 680.333)"
          fill="#111133"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_9_8"
          x1="-631.562"
          y1="699.853"
          x2="201.597"
          y2="-39.336"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.248463" stop-color="#301E6D" />
          <stop offset="1" stop-color="#301E6D" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9_8"
          x1="190.698"
          y1="277.537"
          x2="-462.964"
          y2="707.549"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#462E9C" stop-opacity="0" />
          <stop offset="0.538171" stop-color="#462E9C" />
        </linearGradient>
      </defs>
    </svg>
  );
}
