// @flow

import * as React from 'react';
import {useSelector} from 'react-redux';

import {selectEntityMappingsBySenseName} from 'src/selectors/ats-entities';

import {FilterCard} from './';
import Checkbox from 'src/components/lib/checkbox';

import css from './recipient-card.css';

import useI18n from 'src/hooks/useI18n';


type Props = {
  onSave?: (e: SyntheticEvent<>) => mixed,
  onCancel?: (e: SyntheticEvent<>) => mixed,
  onRecipientTypeChange: (e: SyntheticEvent<HTMLInputElement>) => mixed,
  selectedRecipientTypes: string[],
  recipientTypes?: {value: string, label: string, sense_name: string}[],
};

const getTranslatedRecipientTypes = (labelI18nInstance) => {
  const defaultRecipientTypes = [
    {
      value: 'audience_member',
      label: labelI18nInstance.t('sense_candidate', 'Candidate'),
      sense_name: 'candidate',
    },
    {
      value: 'client',
      label: labelI18nInstance.t('sense_contact', 'Contact'),
      sense_name: 'client_company_employee',
    },
    {
      value: 'internal',
      label: labelI18nInstance.t(
        'sense_internal_employee',
        'Internal Employee',
      ),
      sense_name: 'internal_employee',
    },
  ];
  return defaultRecipientTypes;
};

const RecipientCard = ({
  onSave,
  onCancel,
  recipientTypes,
  onRecipientTypeChange,
  selectedRecipientTypes = [],
  cardLabel = 'Recipient',
}: Props): React.Node => {
  const {labelI18nInstance} = useI18n();

  const translatedRecipientTypes =
    recipientTypes || getTranslatedRecipientTypes(labelI18nInstance);

  const atsEntities = useSelector(selectEntityMappingsBySenseName);
  const filteredRecipientTypes = translatedRecipientTypes.filter(
    (recipient) => atsEntities[recipient.sense_name],
  );

  return (
    <FilterCard header={cardLabel} onSave={onSave} onCancel={onCancel}>
      <section className={css.section}>
        <ul className={css.checklist}>
          {filteredRecipientTypes.map(({value, label}) => (
            <li key={value}>
              <label className={css.label}>
                <Checkbox
                  className={css.checkbox}
                  name={value}
                  checked={selectedRecipientTypes.includes(value)}
                  onChange={onRecipientTypeChange}
                />
                {label}
              </label>
            </li>
          ))}
        </ul>
      </section>
    </FilterCard>
  );
};

export default RecipientCard;
