// @flow

import type {
  RouteLocationTo,
  RouteLocationParam,
  Router,
} from 'src/types/router';

import * as React from 'react';
import {Link, useHistory} from 'src/rerouter';
import {useSelector} from 'react-redux';


const ChatLink = ({
  to,
  toOutside,
  hideInExtension,
  ...props
}: $ReadOnly<{
  to?: RouteLocationTo,
  toOutside?: boolean,
  hideInExtension?: boolean,
  ...
}>): React.Node => {
  const {context, ver, channel} = useSelector((state) => state.env.query);
  const isExtension = context === 'chrome-extension';
  let linkTo = to;
  if (isExtension) {
    if (hideInExtension) {
      return null;
    }

    if (toOutside) {
      return <a {...props} href={to} target="_blank" rel="noopener" />;
    } else {
      linkTo = (location) => {
        let intendedLocation;
        if (typeof to === 'string') {
          intendedLocation = {pathname: to};
        } else if (typeof to === 'function') {
          intendedLocation = to(location);
        } else {
          intendedLocation = to;
        }

        return {
          // TODO[Ashwini] : fix below error introduced in 0.177.0
          // $FlowFixMe[not-an-object]
          ...intendedLocation,
          query: {
            ...(intendedLocation?.query || null),
            context,
            ver,
            channel,
          },
        };
      };
    }
  }

  // $FlowFixMe[incompatible-type]
  return <Link {...props} to={linkTo} />;
};

export default ChatLink;

export function pushChatLocation(
  router: Router,
  newLocation: string | RouteLocationParam,
  browserContext: string = '',
) {
  const {context, ver} = router.location.query;
  const intendedLocation =
    typeof newLocation === 'string'
      ? {pathname: newLocation, query: {}}
      : newLocation;
  if (context) {
    // $FlowFixMe[incompatible-type]
    intendedLocation.query = {...intendedLocation.query, context, ver};
  }
  if (browserContext === 'chrome-extension') {
    window.open(intendedLocation.pathname, '_blank');
    return;
  }
  router.push(intendedLocation);
}

export function replaceChatLocation(
  router: Router,
  newLocation: string | RouteLocationParam,
) {
  const intendedLocation = prepareChatLocation(router, newLocation);

  router.replace(intendedLocation);
}

const prepareChatLocation = (router, newLocation) => {
  const {context, ver} = router.location.query;
  const intendedLocation =
    typeof newLocation === 'string'
      ? {pathname: newLocation, query: {}}
      : newLocation;
  if (context) {
    // $FlowFixMe[incompatible-type]
    intendedLocation.query = {...intendedLocation.query, context, ver};
  }

  return intendedLocation;
};
