// @noflow

import Store from './base';


export default class AtsAuthStore extends Store {
  state : {
    authorised: boolean,
    signInError: boolean,
    userExists: boolean,
    emailSentTo: ?string,
  };

  constructor() {
    super('ats-auth');

    this.state = {
      authorised: false,
      signInError : false,
      userExists : false,
      emailSentTo: undefined,
    };
  }

  receiveFetched(response: any) {
    if (response.success) {
      this.setState({
        authorised: true,
      });
    }
    this.setState({
      signingIn: false,
    });
  }

  isAuthed(): boolean {
    return this.state.authorised;
  }

  isAuthFailed(): boolean {
    return this.state.signInError;
  }

  userExists(): boolean {
    return this.state.userExists;
  }

  emailSentTo(): ?string {
    return this.state.emailSentTo;
  }

  clearAuth() {
    this.setState({
      authorised: false,
    });
  }
}
