// @flow strict

import * as React from 'react';
import {Helmet} from 'react-helmet';

import classify from 'src/utils/classify';

import css from './500.css';


const ErrorPage = ({
  pageTitle,
  drawMinimal,
  children,
}: {
  pageTitle: string,
  drawMinimal?: ?boolean,
  children: React.Node,
}): React.Node => (
  <div
    className={classify(css.page, {
      [css.minimalErrorBox]: drawMinimal,
    })}
  >
    <Helmet title={pageTitle} />
    {!drawMinimal && (
      <>
        <div className={css.sky}>
          <img className={css.leftCloud} src="/images/500/Left Cloud.svg" />
          <img className={css.rightCloud} src="/images/500/Right Cloud.svg" />
        </div>
        <div className={css.sea}>
          <img className={css.horizon} src="/images/500/Horizon Bergs.svg" />
          <img className={css.lines} src="/images/500/Water Lines.svg" />
          <img className={css.leftBerg} src="/images/500/Left Berg.svg" />
          <img className={css.rightBerg} src="/images/500/Right Berg.svg" />
        </div>
        <img className={css.seals} src="/images/500/Seal and Berg.svg" />
      </>
    )}
    <div
      className={classify({
        [css.text]: !drawMinimal,
        [css.textWithoutPosition]: drawMinimal,
      })}
    >
      {children}
      <div className={css.actions}>
        <button className={css.goBack} onClick={() => window.history.back()}>
          Go back
        </button>
        <button className={css.reload} onClick={() => window.location.reload()}>
          Reload
        </button>
      </div>
    </div>
  </div>
);

export default ErrorPage;
