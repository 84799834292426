// @flow strict

import * as React from 'react';
import {classify} from 'src/utils/classify';
import debounce from 'lodash/debounce';
import {Input} from 'src/designSystem2021Components/input.jsx';

import SearchIcon from 'src/images/icons/new-search.svg';
import ClearIcon from 'src/images/icons/clear-icon.svg';

import css from 'src/designSystem2021Components/search-input/search-input.css';


export type SearchInputProps = {
  className?: string,
  inputClassName?: string,
  placeholder?: string,
  disabled?: boolean,
  value?: string,
  isDebounce?: boolean,
  debounceTime?: number,
  useEnterKey?: boolean,
  onChange: (value: string) => mixed,
  onFocus?: (SyntheticEvent<HTMLInputElement>) => mixed,
  onBlur?: (SyntheticEvent<HTMLInputElement>) => mixed,
  onKeyPress?: (e: SyntheticKeyboardEvent<HTMLInputElement>) => mixed,
  onPaste?: (e: ClipboardEvent) => mixed,
  onEnter?: () => mixed,
  onClear?: () => mixed,
  showClearIcon?: boolean,
};

export const SearchInput = ({
  className,
  inputClassName,
  value,
  placeholder,
  disabled = false,
  isDebounce = false,
  useEnterKey = false,
  debounceTime = 100,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  onPaste,
  onClear,
  showClearIcon,
}: // persistClearIcon = false,
// showClearIconWhileTyping = true,
SearchInputProps): React.Node => {
  const [isFocus, setIsFocus] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [searchValue, setSearchValue] = React.useState(value);

  React.useEffect(() => {
    setSearchValue(value);
  }, [value]);

  const handleChange = (text: string) => {
    setSearchValue(text);
    if (useEnterKey) {
      setSearchText(text);
    } else {
      isDebounce ? debouncedChangeHandler(text) : onChange(text);
    }
  };

  const debouncedChangeHandler = React.useCallback(
    debounce(onChange, debounceTime),
    [],
  );

  const handleEnter = () => {
    onChange(searchText);
    setSearchText('');
  };

  const getClearIcon = () => {
    if (showClearIcon != null) {
      return showClearIcon ? <ClearIcon /> : null;
    }
    return isFocus && (value || searchText) && !disabled ? <ClearIcon /> : null; //default handling of clear icon in case showClearIcon flag is not provided.
  };

  const handleClear = () => {
    handleChange('');
    onClear && onClear();
  };

  const clearIcon = getClearIcon();

  return (
    <div
      className={classify(
        css.searchInput,
        className,
        disabled ? css.disabled : null,
      )}
    >
      <div
        className={classify(
          css.searchIcon,
          value || searchText ? css.typed : null,
          isFocus ? css.focused : null,
        )}
      >
        <SearchIcon className={css.icon} />
      </div>
      <Input
        boxClassName={classify(
          css.input,
          inputClassName,
          value || (searchText && !disabled) ? css.inputFocus : null,
          disabled ? css.inputDisabled : null,
        )}
        value={searchValue}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(evt) => handleChange(evt.currentTarget.value)}
        onFocus={(e) => {
          onFocus && onFocus(e);
          setIsFocus(true);
        }}
        onBlur={(e) => {
          onBlur && onBlur(e);
          setIsFocus(false);
        }}
        onKeyPress={(e) => {
          onKeyPress && onKeyPress(e);
          if (e.key === 'Enter' && useEnterKey) {
            return handleEnter();
          }
        }}
        onPaste={onPaste}
      />

      {!!clearIcon && (
        <div className={css.clearIcon} onMouseDown={handleClear}>
          {clearIcon}
        </div>
      )}
    </div>
  );
};
