//@flow strict

export const PAGE_SIZE = 10;

export const promptsList: Array<string> = [
  'Empathetic and Supportive',

  'Friendly and Conversational',

  'Professional and Formal',

  'Gender Neutral',
];

export const ADD_PROMPT_TYPES: Array<string> = ['skills', 'responsibilities'];

export const TONE_PROMPT_TYPES: Array<string> = [
  'missingSections',
  'contentEnhancement',
];

export const PROMPT_TYPES: Array<string> = [
  ...ADD_PROMPT_TYPES,
  ...TONE_PROMPT_TYPES,
];

export const issueTypes = [
  {
    key: '1',
    label: 'The content wasn’t relevant',
  },
  {
    key: '2',
    label: 'The content wasn’t logical',
  },
  {
    key: '3',
    label: 'The content has factual inaccuracies',
  },
  {
    key: '4',
    label: 'The content has poor language fluency',
  },
];

export const QuestionEnhanceMentOptions = [
  {
    disabled: true,
    key: 'Enhance with AI',
    label: 'Enhance with AI',
  },
  {
    key: 'Shorter',
    label: 'Shorter',
  },
  {
    key: 'Longer',
    label: 'Longer',
  },
  {
    key: 'Grammar Check',
    label: 'Grammar Check',
  },
  {
    disabled: true,
    key: 'More Options',
    label: 'More Options',
  },
  {
    key: 'Edit',
    label: 'Edit ',
  },
  {
    key: 'Remove',
    label: 'Remove',
  },
];

export const QuestionSetEnhanceMentOptions = [
  {
    disabled: true,
    key: 'Enhance with AI',
    label: 'Enhance with AI',
  },
  {
    key: 'Shorter',
    label: 'Shorter',
  },
  {
    key: 'Longer',
    label: 'Longer',
  },
  {
    key: 'Grammar Check',
    label: 'Grammar Check',
  },
  {
    disabled: true,
    key: 'Change tone',
    label: 'Change tone',
  },
  {
    key: 'Friendly',
    label: 'Friendly',
  },
  {
    key: 'Professional',
    label: 'Professional',
  },
  {
    key: 'Casual',
    label: 'Casual',
  },
];

export const STRING_CONSTANTS = {
  HOME_PAGE_TITLE: 'AI Content Generator',
  HOME_PAGE_SUBTEXT:
    'Access the power of AI, right inside Sense. Select your project below to get started.',
  AI_INFORMATION_TEXT:
    'IMPORTANT: We recommend reviewing AI-generated content for accuracy and appropriateness.',
  CHATBOT_GENERATED_FEEDBACK:
    'Your prescreening chatbot has been successfully generated.',
  PRE_SCREENING_QUESTION_SAVE:
    'Your prescreening questions have been successfully saved.',
};

export const ERROR_TEXTS = {
  SAVE_RESUME_ERROR:
    'Error downloading the resume, please try again after sometime.',
  EMPTY_PRE_SCREENING_TABLE:
    'You don’t have any screening questions created. Get started by creating one.',
  EMPTY_RESUME_TABLE:
    'You haven\'t created any resumes yet. Click Create New to start.',
  NO_JOBS_ASSIGNED:
    'You don’t have any job descriptions created. Get started by creating one.',
  CANDIDATES_SEARCH_ERROR: 'Error Searching for candidates',
  NO_RESPONSE_CHATBOT: 'There has been no response to the selected chatbot.',
  NO_CHATBOTS_CREATED:
    'No pre-screening chatbots are created, please create one to get started',
};

export const CARDS_TITLE = {
  JOB_DESCRIPTION_GENERATOR: 'Job Descriptions',
  PRE_SCREENING: 'Pre-screening Questions',
  RESUME_GENERATOR: 'Resumes',
  SURVEY_SUMMARY: 'Survey Summaries',
};

export const CARDS = [
  {
    iconName: 'file-signature',
    title: CARDS_TITLE.JOB_DESCRIPTION_GENERATOR,
    description:
      'Generate complete job descriptions in seconds with just a job title and brief summary',
  },
  {
    iconName: 'comments',
    title: CARDS_TITLE.PRE_SCREENING,
    description:
      'Instantly create pre-screening questions based on job titles or descriptions',
  },
  {
    iconName: 'file',
    title: CARDS_TITLE.RESUME_GENERATOR,
    description:
      'Automatically compose candidate resumes based on new information or completed chatbot conversations',
  },
  {
    iconName: 'square-poll-vertical',
    title: CARDS_TITLE.SURVEY_SUMMARY,
    description:
      'Automatically generate survey summary based on journey, touchpoints and questions',
  },
];

export const LOADER_TEXTS = {
  jdTexts: [
    'Cooking Up Custom Careers...Stirring up the Perfect Job Recipe',
    'Spinning Syllables into Dream Jobs...Unleashing the Job Description Djinn',
  ],
  prescreeningTexts: [
    'Polishing Interview Crystal Balls...Conjuring Questions from the HR Universe',
    'Summonsing Smart Queries for You...Crafting Tailored Questions for Candidates',
  ],
  resumeTexts: [
    'Crafting Resumes, Pixel by Pixel...Turning Words into Professional Portraits',
    'Building Career Chronicles, Bit by Bit...Weaving Professional Stories from Text',
    'Unfolding the Resume Red Carpet...Breathing Life into Words on Resumes',
  ],
  messagingTexts: [
    'Summoning Words, Crafting Spells...Weaving Words for the Recruiter’s Quill',
    'Crafting Conversations with Code...Blending Syntax and Emotion into Messages',
  ],
  engageContentTexts: [
    'Summoning Words, Crafting Spells...Weaving Words for the Recruiter’s Quill',
    'Crafting Conversations with Code...Blending Syntax and Emotion into Messages',
  ],
};

export const SENSE_AI_MODULE = {
  ENGAGE_EMAIL: 'Engage Email',
  ENGAGE_SMS: 'Engage SMS',
  MESSAGING: 'Messaging',
};

export const SENSE_PLATFORM = {
  WEB: 'Web',
  CHROME_EXTENSION: 'Chrome Extension',
};
