// @flow strict

import * as React from 'react';
import {classify} from 'src/utils/classify';

import css from './card.css';


export type CardType =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'warning';

export type CardProps = {
  children?: ?React.Node,
  className?: string,
  type?: CardType,
  isSelectable?: boolean,
  isSelected?: boolean,
  onClick?: (e: SyntheticMouseEvent<>) => mixed,
  onClickCapture?: (e: SyntheticMouseEvent<>) => mixed,
};

export const Card = ({
  children,
  className,
  type = 'primary',
  isSelectable = false,
  isSelected = false,
  ...restProps
}: CardProps): React.Node => (
  <div
    className={classify(
      css.card,
      css[type],
      {[css.selectable]: isSelectable, [css.selected]: isSelected},
      className,
    )}
    {...restProps}
  >
    {children}
  </div>
);
