// @flow strict

import levels from 'src/utils/logger/levels';
import consoleTransport from 'src/utils/logger/console';


export type Logger = {
  transports: Array<Transport>,
  levels: {[string]: number, ...},
  context: ?{...},

  getContext(): {},
  log(level: string, message: string, data?: mixed): void,
  logInfo(LoggerInfo): void,
};

export type Transport = (info: LoggerInfo, formattedString: string) => void;

export type LoggerInfo = {
  timestamp?: string,
  level: string,
  message?: ?string,
  data?: ?mixed,
};

export type SenseLogger = typeof defaultLogger;

const baseLogger: Logger = {
  transports: [],
  levels: Object.freeze({}),
  context: undefined,

  getContext() {
    // $FlowFixMe[object-this-reference]
    return this.context;
  },

  log(level, message, data) {
    const info = {
      level,
      message,
      data,
    };

    // $FlowFixMe[object-this-reference]
    this.logInfo(info);
  },

  logInfo({timestamp, level, message, ...rest}: LoggerInfo) {
    const info = {
      timestamp: timestamp ?? new Date().toISOString(),
      level,
      message,
      // $FlowFixMe[object-this-reference]
      ...this.getContext(),
      ...rest,
    };

    // $FlowFixMe[object-this-reference]
    for (const transport of this.transports) {
      transport(info, JSON.stringify(info));
    }
  },
};

function createLogger<L: {[string]: number, ...}>({
  levels,
  transports,
}: {
  levels: L,
  transports: Transport[],
}): Logger {
  return {
    ...baseLogger,
    levels,
    transports,
  };
}

function getLogAlias(level: string) {
  return function (message: string, data?: mixed) {
    this._logger.log(level, message, data);
  };
}

function createLoggerWrapper<L: {...}>(
  levels: L,
): $ObjMap<L, () => (string, m?: mixed) => void> {
  const wrapper = {};
  for (const level in levels) {
    wrapper[level] = getLogAlias(level);
  }
  return wrapper;
}

export function createChildLogger(
  logger: DefaultLogger,
  context: {...},
): DefaultLogger {
  return {
    ...logger,
    _logger: {
      ...logger._logger,
      context,
      getContext() {
        return {
          ...logger._logger.getContext(),
          // $FlowFixMe[object-this-reference]
          ...this.context,
        };
      },
    },
  };
}

export function updateContext(logger: typeof defaultLogger, context: mixed) {
  logger._logger.context = {
    ...logger._logger.context,
    ...context,
  };
}

const transports = [consoleTransport];
if (
  process.env.EX_ENV === 'server' &&
  process.env.DEPLOY_ENV === 'production'
) {
  transports.push(require('src/utils/logger/syslog').default);
}

type LevelLog = (string, mixed) => void;
export type DefaultLogger = {
  _logger: Logger,
  critical: LevelLog,
  error: LevelLog,
  warning: LevelLog,
  info: LevelLog,
  debug: LevelLog,
  log: LevelLog,
  warn: LevelLog,
  updateContext: ({...}) => void,
  createChild: ({...}) => DefaultLogger,
};
const defaultLogger: DefaultLogger = {
  _logger: createLogger({levels, transports}),
  ...createLoggerWrapper(levels),
  log: getLogAlias('info'),
  warn: getLogAlias('warning'),
  updateContext(context: {...}) {
    // $FlowFixMe[object-this-reference]
    updateContext(this, context);
  },
  createChild(context: {...}) {
    // $FlowFixMe[object-this-reference]
    return createChildLogger(this, context);
  },
};

export default defaultLogger;
