// @flow strict-local

import type {Agent} from 'src/types/agents';
import type {Account} from 'src/types/account';
import type {
  Message,
  Thread,
  UnreadCountsByPhoneNumberSet,
  UnreadCountsByQueue,
  ConversationContextEvent,
  ConversationAddedToQueueEvent,
} from 'src/types/messages';
import type {Contact} from 'src/types/contacts';
import type {AudienceMember} from 'src/types/audience-member';
import type {SchedulerEvent} from 'src/types/scheduler';
import type {HVBroadcastPerformanceRecipientsWS} from 'src/types/hv-broadcasts';

// NOTE (kyle): these strings should be the only js exports
// in this file, which allows `Object.values(module);` to give
// a list of all possible message types.
export const MESSAGE_EVENTS = 'messaging:message';
export const UNREAD_COUNT = 'messaging:unread_count';
export const THREAD_UPDATE = 'messaging:thread_status';
export const CONTACT_UPDATE = 'messaging:contact';
export const CONVERSATION_CONTEXT_EVENT =
  'messaging:conversation_context_event';
export const SCHEDULER_NEW = 'scheduler:new_event';
export const SCHEDULER_RESCHEDULE = 'scheduler:reschedule_event';
export const SCHEDULER_CANCEL = 'scheduler:cancel_event';
export const TYPING_EVENT = 'messaging:typing_event';
export const READ_STATUS = 'messaging:read_status';
export const AGENT_JOINED_THREAD = 'messaging:agent_joined_thread_event';
export const AUTOREPLY_CHANGE = 'messaging:autoreply_change_event';
export const CONVERSATION_ADDED_TO_QUEUE =
  'messaging:conversation_added_to_queue_event';
export const BROADCAST_DELIVERY_STATS_EVENT =
  'messaging:broadcast_delivery_stats_event';
export const BROADCAST_PERFORMANCE_RECIPIENTS_EVENT =
  'messaging:broadcast_performance_recipients_event';

type Metadata = {
  title: string,
  body: string,
  is_thin_notification: boolean,
};

export type SchedulerNewEventMsg = {
  type: 'scheduler:new_event',
  payload: {
    event: SchedulerEvent,
  },
  metadata: Metadata,
};

export type ConversationContextEventMsg = {
  type: 'messaging:conversation_context_event',
  payload: {
    event: {context_events: Array<ConversationContextEvent>},
  },
  metadata: Metadata,
};
export type TypingEventMsg = {
  type: 'messaging:typing_event',
  payload: {
    agent: {id: string},
    thread_id: string,
    is_typing: boolean,
  },
};

export type SchedulerCancelEventMsg = {
  type: 'scheduler:cancel_event',
  payload: {
    event: SchedulerEvent,
  },
  metadata: Metadata,
};

export type SchedulerRescheduleEventMsg = {
  type: 'scheduler:reschedule_event',
  payload: {
    event: SchedulerEvent,
  },
  metadata: Metadata,
};

export type ReadStatusMsg = {
  type: 'messaging:read_status',
  payload: {
    agent_id: string,
    thread_id: string,
    message_id: string,
  },
};

export type NewMessagesMsg = {
  type: 'messaging:message',
  payload: {
    // NOTE (kyle): these are not currently camel cased.
    messages: Message[],
    thread: Thread,
    audience_members: AudienceMember[],
    contact: Contact,
    agents: Account[],
    phone_number_set_id: string,
  },
  metadata: Metadata,
};

export type UnreadCountMsg = {
  type: 'messaging:unread_count',
  payload: {
    unread_message_count: number,
    unread_thread_count: number,
    unread_by_phone_number_set: UnreadCountsByPhoneNumberSet,
    unread_by_lat_queue: UnreadCountsByQueue,
    unread_timestamp: number,
  },
};

export type ContactUpdateMsg = {
  type: 'messaging:contact',
  payload: {
    contact: Contact,
  },
};

export type ThreadUpdateMsg = {
  type: 'messaging:thread_status',
  payload: {
    thread: Thread,
    audience_members: AudienceMember[],
    contact: Contact,
    phone_number_set_id: string,
  },
  metadata: Metadata,
};

export type AgentJoinedThreadMsg = {
  type: 'messaging:agent_joined_thread_event',
  payload: {
    agent: {
      id: string,
      name: string,
    },
    thread_id: string,
    inbox_id: string,
  },
};

export type AutoreplyChange = {
  type: 'messaging:autoreply_change_event',
  payload: {
    phone_number_set_id: string,
    phone_number: string,
    active: boolean,
    until: string,
    body: string,
    is_autoreply_on: boolean,
  },
};

export type ConversationAddedToQueue = {
  type: 'messaging:conversation_added_to_queue_event',
  payload: ConversationAddedToQueueEvent,
  metadata: Metadata,
};

// Broadcast delivery stats event
type BroadcastStats = {
  total: number,
  percent: number,
};

type DeliveryAnalytics = {
  delivered: BroadcastStats,
  responded: BroadcastStats,
  failed: BroadcastStats,
  opted_out: BroadcastStats,
  skipped: BroadcastStats,
};

export type BroadcastDeliveryStatsEvent = {
  type: 'messaging:broadcast_delivery_stats_event',
  payload: {
    agenct_id: string,
    broadcast_id: string,
    delivery_analytics: DeliveryAnalytics,
    metadata: Metadata,
  },
};

export type BroadcastPerformanceRecipientsEvent = {
  type: 'messaging:broadcast_performance_recipients_event',
  payload: HVBroadcastPerformanceRecipientsWS,
};

export type WebsocketMessage =
  | NewMessagesMsg
  | ReadStatusMsg
  | UnreadCountMsg
  | ThreadUpdateMsg
  | ConversationContextEventMsg
  | ContactUpdateMsg
  | SchedulerNewEventMsg
  | SchedulerCancelEventMsg
  | TypingEventMsg
  | SchedulerRescheduleEventMsg
  | AgentJoinedThreadMsg
  | AutoreplyChange
  | ConversationAddedToQueue
  | BroadcastDeliveryStatsEvent
  | BroadcastPerformanceRecipientsEvent;

export type WebsocketMetaMessage = {
  agent_id: string,
  payload: WebsocketMessage[],
};
