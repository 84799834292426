// @flow strict
//$FlowFixMe
import type {Dispatch, ThunkAction} from 'src/reducers';
//$FlowFixMe
import * as reduxApi from 'src/utils/redux-api-v2';


export const submitTask =
  (task_id: string, user_input: mixed): ThunkAction<mixed> =>
  (dispatch: Dispatch) =>
    dispatch(
      reduxApi.put(`onboard/task/${task_id}`, {
        user_input,
      }),
    );
