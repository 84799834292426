// @flow

// NOTE (kyle): this is a direct result of `key` in `manifest.json`. DO NOT change this unless you know what you're doing.
export const EXTENSION_ID = 'febgikmgbjnbmpodlhljpnnoeappdeck';

/*
export const BASE_PROTOCOL: string =
  process.env.NODE_ENV === 'production' ? 'https' : 'http';
  */

export const BASE_PROTOCOL: string = 'https';
