// @noflow

import type {EventReport, ModuleStats} from 'src/api-parsers/report';

import omit from 'lodash/omit';
import {assign} from 'sculpt';

import Store from './base';

export default class EventReportStore extends Store {
  state: {
    stats: {
      [key: string]: EventReport,
    },
    moduleStats: {
      [key: string]: ModuleStats,
    },
    responses: {
      [key: string]: Object,
    },
    flaggedResponses: {
      [key: string]: Object,
    },
    moduleResponses: {
      [key: string]: Object,
    },
    fetching: {
      [key: string]: Promise<void>,
    },
  };

  constructor() {
    super('event report');

    this.state = {
      stats: {},
      moduleStats: {},
      responses: {},
      flaggedResponses: {},
      moduleResponses: {},
      fetching: {},
    };
  }

  startFetchingEvent(eventId: string, promise: Promise<void>) {
    this.updateState({
      fetching: {
        $assign: {
          [eventId]: promise,
        },
      },
      stats: {
        $unset: eventId,
      },
    });
  }

  receiveEvent(report: EventReport) {
    this.setState({
      fetching: omit(this.state.fetching, report.eventId),
      stats: assign(this.state.stats, {
        [report.eventId]: report,
      }),
    });
  }

  startFetchingModule(
    eventId: string,
    moduleId: string,
    promise: Promise<void>
  ) {
    this.updateState({
      fetching: {
        $assign: {
          [`${eventId}:${moduleId}`]: promise,
        },
      },
    });
  }

  moduleKey(eventId: string, moduleId: string): string {
    return `${eventId}:${moduleId}`;
  }

  receiveModule(module: Object) {
    const key = this.moduleKey(module.eventId, module.moduleId);
    this.setState({
      fetching: omit(this.state.fetching, key),
      moduleStats: assign(this.state.moduleStats, {
        [key]: module,
      }),
    });
  }

  getModule({
    eventId,
    moduleId,
  }: {
    eventId: string,
    moduleId: string,
  }): ModuleStats {
    const key = this.moduleKey(eventId, moduleId);
    return this.state.moduleStats[key];
  }

  // TODO (kyle): create type and annotation and transformer for this
  receiveResponses(responses: Object) {
    this.updateState({
      responses: {
        $assign: {
          [responses.eventId]: responses,
        },
      },
    });
  }

  receiveFlaggedResponses(responses: Object) {
    this.updateState({
      flaggedResponses: {
        $assign: {
          [responses.eventId]: responses,
        },
      },
    });
  }

  getModuleResponses({
    eventId,
    moduleId,
  }: {
    eventId: string,
    moduleId: string,
  }): Object {
    const key = this.moduleKey(eventId, moduleId);
    const {moduleResponses} = this.state;
    return moduleResponses[key] && moduleResponses[key].responses;
  }

  // TODO (dbow): create type and annotation and transformer for this
  receiveModuleResponses(response: Object) {
    const {eventId, moduleId} = response;
    this.updateState({
      moduleResponses: {
        $assign: {
          [this.moduleKey(eventId, moduleId)]: response,
        },
      },
    });
  }
}
