// @flow strict

import type {
  Site,
  SiteQuery,
  SitePage,
  SiteLandingPage,
  DesignConfig,
} from 'src/types/page-site';
// import type {SiteLandingPage} from 'src/types/landing-page';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff.js';

// Const Defined Here
export const PAGE_SITE_CONSTANTS = {
  FETCH_SITES: 'page-site/sites',
  FETCH_SITE_DETAIL: 'site-detail/siteId',
  UPDATE_SITE_DETAIL: 'site-detail/update',
  RESET_SITE_DETAIL: 'site-detail/reset',
  UPDATE_SITE_PAGE: 'site-page/update',
};

// Defined Action Type Here
type FetchSiteAction = {
  type: typeof PAGE_SITE_CONSTANTS.FETCH_SITES,
  payload: Site[],
};

type FetchSiteDetailAction = {
  type: typeof PAGE_SITE_CONSTANTS.FETCH_SITE_DETAIL,
  payload: Site,
};

type UpdateSiteDetailsAction = {
  type: typeof PAGE_SITE_CONSTANTS.UPDATE_SITE_DETAIL,
  payload: Site,
};

type ResetSiteDetailsAction = {
  type: typeof PAGE_SITE_CONSTANTS.RESET_SITE_DETAIL,
};

type UpdateSitePageAction = {
  type: typeof PAGE_SITE_CONSTANTS.UPDATE_SITE_PAGE,
  payload: SiteLandingPage,
};

// All The Actions here
export type SitesActions =
  | FetchSiteAction
  | FetchSiteDetailAction
  | UpdateSiteDetailsAction
  | ResetSiteDetailsAction
  | UpdateSitePageAction;

//
const fetchSitesList = (payload: Site[]): FetchSiteAction => ({
  type: PAGE_SITE_CONSTANTS.FETCH_SITES,
  payload,
});

export const resetSiteDetail = (): ResetSiteDetailsAction => ({
  type: PAGE_SITE_CONSTANTS.RESET_SITE_DETAIL,
});

const fetchSiteDetail = (payload: Site): FetchSiteDetailAction => ({
  type: PAGE_SITE_CONSTANTS.FETCH_SITE_DETAIL,
  payload,
});

export const updateSiteDetails = (payload: Site): UpdateSiteDetailsAction => ({
  type: PAGE_SITE_CONSTANTS.UPDATE_SITE_DETAIL,
  payload,
});

export const updateSitePage = (
  payload: SiteLandingPage,
): UpdateSitePageAction => ({
  type: PAGE_SITE_CONSTANTS.UPDATE_SITE_PAGE,
  payload,
});

// Function to call Dispatch function from here
export const getSiteDetail: (siteId: string) => ThunkAction<void> = flow(
  key(() => `${PAGE_SITE_CONSTANTS.FETCH_SITE_DETAIL}/`),
  fetching(),
)((siteId) => (dispatch: Dispatch) => {
  return dispatch(reduxApi.get(`landing-pages/career-site/${siteId}`))
    .then((response: Site) => {
      dispatch(fetchSiteDetail(response));
      //$FlowFixMe[incompatible-type-arg]
      return response;
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
});

export const getPageSites: () => ThunkAction<void> = flow(
  key(() => `${PAGE_SITE_CONSTANTS.FETCH_SITES}/`),
  fetching(),
)(() => (dispatch: Dispatch) => {
  return dispatch(reduxApi.get(`landing-pages/career-site`))
    .then((response: Site[]) => {
      dispatch(fetchSitesList(response));
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
});

export const saveCareerSite: (data: SiteLandingPage) => ThunkAction<void> =
  flow(
    key(() => `landing-pages/page-saveSite`),
    fetching(),
  )((data: SiteLandingPage) => (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.post(`landing-pages/page/${data.id}`, {
        ...data,
        should_publish: false,
      }),
    )
      .then((response: SiteLandingPage) => {
        dispatch(updateSitePage(response));
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  });

export const getSitePage: (id: string) => ThunkAction<void> = flow(
  key((id) => `landing-pages/page/${id}`),
  fetching(),
)((id: string) => (dispatch: Dispatch) => {
  return dispatch(reduxApi.get(`landing-pages/page/${id}`))
    .then((response: SiteLandingPage) => {
      dispatch(updateSitePage(response));
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
});

export const createSite: (query: SiteQuery) => ThunkAction<void> = flow(
  key(() => `${PAGE_SITE_CONSTANTS.UPDATE_SITE_DETAIL}`),
  fetching(),
)((query) => async (dispatch: Dispatch) => {
  try {
    const {domain_configuration_id, url, site_id, subdomain, ...rest} = query;
    const updateQuery = {
      ...rest,
      domain_configuration: {
        id: domain_configuration_id,
        domain: url,
        subdomain,
      },
      id: site_id,
    };

    const response = await dispatch(
      reduxApi.post(`landing-pages/career-site`, query),
    );

    if (response) {
      dispatch(
        updateSiteDetails({
          ...updateQuery,
          site_pages: response.site_pages,
          design_config: response.design_conifg,
          id: response.id,
          seo_settings: response.seo_settings,
          published_at: response.published_at,
          archived_at: response.archived_at,
        }),
      );
    }
    return response;
  } catch (error) {
    console.error(error);
    throw error;
    // Handle error as needed
  }
});

export const duplicateSite: (query: SiteQuery) => ThunkAction<void> = flow(
  key(() => `${PAGE_SITE_CONSTANTS.UPDATE_SITE_DETAIL}-duplicate`),
  fetching(),
)((query) => async (dispatch: Dispatch) => {
  try {
    const {domain_configuration_id, url, site_id, subdomain, ...rest} = query;
    const updateQuery = {
      ...rest,
      domain_configuration: {
        id: domain_configuration_id,
        domain: url,
        subdomain,
      },
      id: site_id,
    };

    const response = await dispatch(
      reduxApi.post(`landing-pages/career-site/duplicate`, query),
    );

    if (response) {
      dispatch(
        updateSiteDetails({
          ...updateQuery,
          site_pages: response.site_pages,
          design_config: response.design_conifg,
          id: response.id,
          seo_settings: response.seo_settings,
          published_at: response.published_at,
          archived_at: response.archived_at,
        }),
      );
    }
    return response;
  } catch (error) {
    console.error(error);
    throw error;
    // Handle error as needed
  }
});

export const updateSite: (
  siteId: string,
  query: SiteQuery,
) => ThunkAction<void> = flow(
  key(() => `${PAGE_SITE_CONSTANTS.UPDATE_SITE_DETAIL}-siteSettings`),
  fetching(),
)((siteId, query) => async (dispatch: Dispatch) => {
  try {
    const {domain_configuration_id, url, site_id, subdomain, ...rest} = query;
    const updateQuery = {
      ...rest,
      domain_configuration: {
        id: domain_configuration_id,
        domain: url,
        subdomain,
      },
      id: site_id,
    };

    const response = await dispatch(
      reduxApi.put(`landing-pages/career-site/${siteId}`, query),
    );

    if (response) {
      dispatch(
        updateSiteDetails({
          ...updateQuery,
          site_pages: response.site_pages,
          design_config: response.design_conifg,
          id: response.id,
          seo_settings: response.seo_settings,
          published_at: response.published_at,
          archived_at: response.archived_at,
        }),
      );
    }
    return response;
  } catch (error) {
    console.error(error);
    throw error;
    // Handle error as needed
  }
});

export const addPages: (
  id: string,
  pagesQuery: SitePage[],
  designConfigQuery: DesignConfig,
) => ThunkAction<void> = flow(
  key(() => `${PAGE_SITE_CONSTANTS.UPDATE_SITE_DETAIL}-Pages`),
  fetching(),
)((id, pagesQuery, designConfigQuery) => async (dispatch: Dispatch) => {
  try {
    const response = await dispatch(
      // $FlowFixMe[incompatible-call]
      reduxApi.post(`landing-pages/career-site/${id}/pages`, {
        pages: pagesQuery,
        design_config: designConfigQuery,
      }),
    );
    if (response) {
      dispatch(
        updateSiteDetails({
          site_pages: response.site_pages,
        }),
      );
    }
    return response;
  } catch (error) {
    console.error(error);
    throw error;
    // Handle error as needed
  }
});

export const publishCareerSite: (siteId: string) => ThunkAction<void> = flow(
  key(() => `landing-pages/page-publishSite`),
  fetching(),
)((siteId) => (dispatch: Dispatch) => {
  return dispatch(reduxApi.post(`landing-pages/career-site/${siteId}/publish`))
    .then((response) => {
      console.log(response);
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
});

export const unPublishCareerSite: (siteId: string) => ThunkAction<void> = flow(
  key(() => `landing-pages/page-unpublishSite`),
  fetching(),
)((siteId) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.post(`landing-pages/career-site/${siteId}/unpublish`),
  )
    .then((response) => {
      console.log(response);
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
});

export const deleteSite: (siteId: string) => ThunkAction<void> = flow(
  key(() => `landing-pages/page-deleteSite`),
  fetching(),
)((siteId) => (dispatch: Dispatch) => {
  return dispatch(reduxApi.del(`landing-pages/career-site/${siteId}`))
    .then((response) => {
      console.log(response);
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
});
