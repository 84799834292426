// @flow

import type {State} from 'src/reducers';
import type {Contact, ContactList, ContactMap} from 'src/types/contacts';

import {createSelector} from 'reselect';
import sortBy from 'lodash/sortBy';

import {captureSentryMessage} from 'src/utils/sentry';


const useParam = (state: State, param: string) => param;

export const getContacts = (state: State): ContactMap =>
  state.contacts.contacts;
export const selectContactsAsArray: (State) => Contact[] = createSelector(
  getContacts,
  // $FlowFixMe[incompatible-call]
  (contacts) => Object.values(contacts),
);

export const selectContact = (state: State, contactId: string): ?Contact =>
  state.contacts.contacts[contactId];

export const getContactByPhone = (
  contacts: Contact[],
  phone: string,
): ?Contact =>
  contacts.find((contact) => {
    checkForContactPhoneError(contact);
    return contact.phoneNumbers.some(({value}) => value === phone);
  });

export const selectContactByPhone: (State, string) => ?Contact = createSelector(
  selectContactsAsArray,
  useParam,
  getContactByPhone,
);

const getEditingList = (state) => state.contactLists.editing.contacts;
export const selectEditingListContacts: (State) => Contact[] = createSelector(
  getContacts,
  getEditingList,
  (contactMap, contactList) =>
    contactList.map((id) => (typeof id === 'string' ? contactMap[id] : id)),
);

const getLists = (state) => state.contactLists.lists;
export const selectSortedContactLists: (
  state: State,
) => ContactList[] = createSelector(getLists, (lists) =>
  sortBy(
    Object.keys(lists).map((list) => lists[list]),
    'name',
  ),
);

const getList = (state, listId) => state.contactLists.lists[listId];
export const selectContactsForList: (
  State,
  string,
) => Contact[] = createSelector(
  getList,
  getContacts,
  // NOTE (kyle): it's unlikely (but possible) that contacts in a group will not
  // exist in the global store. we filter undefined values out here.
  (list, contacts) => list.contacts.map((id) => contacts[id]).filter(Boolean),
);

export const selectNonOptOutContactsForList: (
  State,
  string,
) => Contact[] = createSelector(
  selectContactsForList,
  // $FlowFixMe[prop-missing]
  (contacts) => contacts.filter(({optedOut}) => !optedOut),
);
export const selectOptOutContactsForList: (
  State,
  string,
) => Contact[] = createSelector(
  selectContactsForList,
  // $FlowFixMe[prop-missing]
  (contacts) => contacts.filter(({optedOut}) => optedOut),
);

// TODO (kyle): this is probably inefficient.
export const selectListsForContact: (
  State,
  string,
) => ContactList[] = createSelector(useParam, getLists, (contactId, lists) =>
  Object.keys(lists)
    .map((list) => lists[list])
    .filter((list) => list.contacts.includes(contactId)),
);

const normalizeString = (str) => (str || '').replace(/\W/g, '').toLowerCase();

export const filterContactsForSearch = (
  contacts: Contact[],
  searchTerm: string,
): Contact[] => {
  searchTerm = normalizeString(searchTerm);

  return !searchTerm
    ? []
    : contacts
        .filter((contact) => {
          checkForContactPhoneError(contact);

          // TODO (kyle): fix this case when we move all contacts to a list of phone numbers
          const phone = contact.phoneNumbers[0];
          const matchesPhone = phone && phone.value.includes(searchTerm);

          let {firstName = '', lastName = ''} = contact;
          firstName = normalizeString(firstName);
          lastName = normalizeString(lastName);

          const matchesName = `${firstName}${lastName}`.includes(searchTerm);

          return matchesPhone || matchesName;
          // NOTE (kyle): we do this to make rendering easier
        })
        // TODO (kyle): copying this object may mess with memoization down the line
        // we should see about removing this.
        .map((contact) => ({
          ...contact,
          phone: contact.phoneNumbers[0] && contact.phoneNumbers[0].value,
        }));
};

export const selectContactsForSearch: (
  State,
  string,
) => Contact[] = createSelector(
  selectContactsAsArray,
  useParam,
  filterContactsForSearch,
);

// TODO (kyle): remove this later. it's temporary.
function checkForContactPhoneError(contact) {
  if (!contact.phoneNumbers) {
    captureSentryMessage('Contact is missing `phoneNumbers`', {
      level: 'error',
      extra: {
        contact,
      },
    });

    // NOTE (kyle): we intentionally mutate the contact here to avoid
    // duplicating this error.
    contact.phoneNumbers = [];
  }
}
