// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch} from 'src/reducers';

import type {contentTypes, ToastOptions, ToastMessage} from 'src/types/toast';
import {VARIANTS, POSITIONS} from 'src/designSystem2021Components/toast';


const defaultOptions: ToastOptions = {
  delay: 0,
  timeout: 2000,
  position: POSITIONS.BOTTOM_RIGHT,
  variant: VARIANTS.INFO,
};

export const SHOW_TOAST = 'toast/showToast';
export const REMOVE_TOAST = 'toast/removeToast';

export type ShowToastAction = {
  type: typeof SHOW_TOAST,
  payload: ToastMessage,
};

export type RemoveToastAction = {
  type: typeof REMOVE_TOAST,
  payload: number,
};

export const showToast = (payload: ToastMessage): ShowToastAction => ({
  type: SHOW_TOAST,
  payload,
});

export const removeToast = (payload: number): RemoveToastAction => ({
  type: REMOVE_TOAST,
  payload,
});

export type ToastAction = ShowToastAction | RemoveToastAction;

export const showToastMessage =
  (
    content: contentTypes,
    options: ToastOptions = defaultOptions,
  ): ((dispatch: Dispatch) => Promise<number>) =>
  (dispatch: Dispatch): Promise<number> =>
    new Promise((resolve) => {
      const mergedOptions = {...defaultOptions, ...options};
      const toastMessage: ToastMessage = {
        content,
        ...mergedOptions,
        id: 0,
      };

      const timeoutId = window.setTimeout(() => {
        dispatch(
          showToast({
            ...toastMessage,
            id: timeoutId,
          }),
        );
      }, toastMessage.delay);
      if (toastMessage.timeout) {
        window.setTimeout(() => {
          dispatch(removeToast(timeoutId));
        }, toastMessage.timeout);
      }

      resolve(timeoutId);
    });

export const showPartialErrorToast =
  (content: contentTypes): mixed =>
  (dispatch) => {
    dispatch(
      showToast({
        ...defaultOptions,
        ...{id: 0, key: 'PARTIAL_ERROR', variant: VARIANTS.ERROR, content},
      }),
    );
  };
