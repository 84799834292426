// @flow

import type {State} from 'src/reducers';
import type {
  AtsEntity,
  EntityMappingsBySenseName,
  EntityType,
} from 'src/types/ats-entities';
import type {Workflow} from 'src/types/workflow';
import type {SenseEvent} from 'src/types/events';

import {createSelector} from 'reselect';
import startCase from 'lodash/startCase';
import _get from 'lodash/get';
import keyBy from 'lodash/keyBy';

import {values} from 'src/utils/object';
import {prettyBullhornLabel} from 'src/utils/entities';
import {selectEntityMappingsByName} from 'src/selectors/ats-entities';
import {doesTouchpointBelongToThisAction} from 'src/utils/audience-list';


export const selectCurrentWorkflow = (state: State): ?Workflow => {
  const workflowId = state.route.params.workflowId;
  return workflowId ? selectWorkflow(state, workflowId) : null;
};

export const selectWorkflowWithEvents = (
  state: State,
  workflowId: string,
): ?Workflow => state.workflows.workflowsWithEvents[workflowId];

export const selectCurrentWorkflowWithEvents = (state: State): ?Workflow => {
  const workflowId = state.route.params.workflowId;
  return workflowId ? selectWorkflowWithEvents(state, workflowId) : null;
};

export const selectEventById: (
  state: State,
  workflowId: string,
  eventId: string,
) => SenseEvent = createSelector(
  (state) => state,
  (state, workflowId) => selectWorkflowWithEvents(state, workflowId),
  (_, __, eventId) => eventId,
  (state, workflow, eventId) =>
    eventId && workflow?.events
      ? // $FlowFixMe[incompatible-call]
        values(workflow.events).find(({id}) => id === eventId)
      : // $FlowFixMe[incompatible-call]
        null,
);

export const selectCurrentEvent: (state: State) => SenseEvent = createSelector(
  (state) => state,
  selectCurrentWorkflowWithEvents,
  (state, workflow) => {
    const eventId = state.route.params.eventId;
    return eventId && workflow
      ? // $FlowFixMe[incompatible-call]
        values(workflow.events).find(({id}) => id === eventId)
      : // $FlowFixMe[incompatible-call]
        null;
  },
);

export const selectAllowedEntityTypesByAtsName = (state: State): EntityType[] =>
  state.workflows.entityTypes;

export const selectWorkflow = (state: State, workflowId: string): ?Workflow =>
  state.workflows.workflows[workflowId];

export const selectWorkflows = (state: State): {[string]: Workflow} =>
  state.workflows.workflows;

export const selectCleanupWorkflows: (state: State) => Workflow[] =
  createSelector(selectWorkflows, (workflows) =>
    values(workflows).filter((workflow) => workflow.dbCleanup),
  );

//This selector returns only those journeys which are required during abt flows.
export const selectAbtWorkflows: (
  State,
  ?string,
) => {label: string, value: string}[] = createSelector(
  selectWorkflows,
  (_, abtAttributeType) => abtAttributeType,
  (workflows, abtAttributeType) => {
    const result = [];
    for (const workflowId of Object.keys(workflows)) {
      const eventSummaryList =
        /*Here we are deliberately putting both eventsSummary & events_summary,
        since the workflow selector for Journeys picks workflows from old flux Store and
        for Lists it picks from Redux store. Can be removed when J2 is implemented. */
        //$FlowFixMe[prop-missing]
        workflows[workflowId].eventsSummary ??
        workflows[workflowId].events_summary;
      if (
        abtAttributeType &&
        eventSummaryList &&
        Array.isArray(eventSummaryList)
      ) {
        if (
          eventSummaryList.some(
            ({sendType, eventType, send_type, event_type}) =>
              doesTouchpointBelongToThisAction(
                abtAttributeType,
                sendType ?? send_type,
                eventType ?? event_type,
              ),
          )
        ) {
          result.push({
            label: workflows[workflowId].name,
            value: workflows[workflowId].id,
          });
        }
      }
    }
    return result;
  },
);

export const allowedEntityTypesAsMap: (
  state: State,
) => EntityMappingsBySenseName = createSelector(
  selectAllowedEntityTypesByAtsName,
  selectEntityMappingsByName,
  (entityTypes, mappings) =>
    keyBy(
      entityTypes.map((type) => mappings[type]),
      'sense_name',
    ),
);

export const allowedEntityTypesAsSelectValues: (
  state: State,
  workflowId: string,
) => {value: string, label: string}[] = createSelector(
  selectAllowedEntityTypesByAtsName,
  selectEntityMappingsByName,
  (entityTypes, mappings) =>
    entityTypes.map((value) => {
      const label = mappings[value]?.display_name || prettyBullhornLabel(value);
      return {label, value};
    }),
);

export const recipientTypesForWorkflowAsSelectValues: (
  state: State,
  workflowId: string,
) => {value: string, label: string}[] = createSelector(
  (state, workflowId) =>
    _get(state, ['workflows', 'recipientTypes', workflowId, 'relatedEntities']),
  (relatedEntities) =>
    relatedEntities
      ? relatedEntities.map(({relatedEntity}) => ({
          value: relatedEntity,
          label: startCase(relatedEntity),
        }))
      : [],
);

export const getCurrentWorkflowEntityType = (state: State): ?AtsEntity => {
  // TODO (kyle): is all this optional chaining required?
  const workflowId = state.route?.params?.workflowId;
  const atsType = state.workflows.workflows[workflowId]?.entityType;
  return state.atsEntities?.entities.find((type) => type.name === atsType);
};
