// @flow
import * as React from 'react';
import {RadioGroup, Radio} from 'react-radio-group';

import dates, {formatIsoDate, getMomentDate} from 'src/utils/date-time';

import {
  WritebackContext,
  UPDATE_QUESTION,
} from 'src/components/writeback/writeback-wrapper.jsx';
import {StringListInput} from 'src/components/lib/token-list-input/';
import RadioButtons from 'src/components/lib/radio-buttons/radio-buttons.jsx';
import Checkbox from 'src/components/lib/checkbox';
import DatePicker from 'src/components/lib/date-picker/date-picker.jsx';
import BasicDropdown from 'src/components/lib/basic-dropdown';

import radioCss from 'src/components/lib/radio-buttons/radio.css';
import moduleCss from 'src/components/workflow/event/content/module/editors/module.css';


const dateOptions = [
  {value: 'yyyy-mm-dd', label: 'Year, month, and date'},
  {value: 'mm-dd', label: 'Month and date only'},
];

const alertConditionOptions = [
  {value: 'on_or_before', label: 'on or before'},
  {value: 'on_or_after', label: 'on or after'},
];

export default ({
  conditionalAlerts,
}: {
  conditionalAlerts?: boolean,
}): React.Element<'div'> => {
  const {state, dispatch} = React.useContext(WritebackContext);
  const currentQuestionType = state.question?.selectedType;
  const currentQuestion = state.question?.[currentQuestionType];

  const updateQuestion = (attrs) => {
    dispatch({
      type: UPDATE_QUESTION,
      payload: {
        questionType: currentQuestionType,
        // $FlowFixMe[incompatible-call] types/surveys
        attrs,
      },
    });
  };

  return (
    <div className={moduleCss.calendarDate_container}>
      {conditionalAlerts && (
        <div className={moduleCss.calendarDate_alertContainer}>
          <label className={moduleCss.calendarDate_inputLabel}>
            Alert
            <Checkbox
              className={moduleCss.calendarDate_input}
              // $FlowFixMe[prop-missing] types/surveys
              // $FlowFixMe[incompatible-type]
              checked={currentQuestion.alertCondition}
              onChange={(event) => {
                // $FlowFixMe[prop-missing] types/surveys
                if (currentQuestion.alertCondition) {
                  updateQuestion({alertCondition: null});
                } else {
                  updateQuestion({alertCondition: 'on_or_before'});
                }
              }}
            />
          </label>

          {
            // $FlowFixMe[prop-missing] types/surveys
            currentQuestion.alertCondition && (
              <div className={moduleCss.calendarDate_inputContainer}>
                <span className={moduleCss.calendarDate_inputLabel}>
                  For responses
                </span>
                <div className={moduleCss.calendarDate_input}>
                  <BasicDropdown
                    disabled
                    noOptionText={'None'}
                    options={alertConditionOptions}
                    selected={alertConditionOptions.find(
                      (option) =>
                        option.value === currentQuestion.alertCondition,
                    )}
                    onChange={(selected) => {
                      updateQuestion({alertCondition: selected.value});
                    }}
                  />
                </div>
              </div>
            )
          }

          {
            // $FlowFixMe[prop-missing] types/surveys
            currentQuestion.alertCondition && (
              <div className={moduleCss.calendarDate_inputContainer}>
                <span className={moduleCss.calendarDate_inputLabel}>Date</span>
                <DatePicker
                  className={moduleCss.calendarDate_input}
                  selected={
                    currentQuestion.alertDate &&
                    getMomentDate(currentQuestion.alertDate)
                  }
                  onChange={(value) => {
                    if (value) {
                      updateQuestion({alertDate: formatIsoDate(value)});
                    }
                  }}
                  type="date"
                />
              </div>
            )
          }
        </div>
      )}

      {conditionalAlerts && (
        <div className={moduleCss.multipleChoiceModule_hintText}>
          {
            'Any alert emails will be sent if the recipient’s response date matches the alert options above.'
          }
        </div>
      )}
    </div>
  );
};
