// @flow

import type {Thread} from 'src/types/messages';
import type {Broadcast} from 'src/types/broadcasts';
import type {Contact} from 'src/types/contacts';
import type {AudienceMember} from 'src/types/audience-member';
import type {State} from 'src/reducers';
import {getTimezone} from 'src/selectors/date-time';

import * as React from 'react';
import {connect} from 'react-redux';

import {
  selectThreadByPhone,
  selectDefaultRecipientForThread,
  selectUpcomingScheduledMessagesForThread,
} from 'src/selectors/messages-v2';

import {getFullName} from 'src/utils';

import ScheduledMessageView from 'src/components/messaging/scheduled-message.jsx';
import Loading from 'src/components/lib/loading';

import ClockIcon from 'src/images/clock-icon.svg';

import css from './scheduled-messages-for-contact.css';


type Props = {
  thread?: Thread,
  scheduledMessages: Broadcast[],
  timezone: string,
  recipient?: Contact | AudienceMember,
  contact: Contact,
  routed: boolean,
};

function ScheduledMessagesForContact({
  scheduledMessages,
  timezone,
  recipient,
  thread,
  routed: isModal,
}: Props): React.Node {
  return (
    <div className={css.root}>
      <div className={css.inset}>
        {thread ? (
          <>
            <h4 className={css.heading}>
              <ClockIcon className={css.clockIcon} />
              {/* $FlowFixMe: NameObject type is not compatible with Contact type */}
              Scheduled Messages {recipient && 'for ' + getFullName(recipient)}
              {/* TODO (gab): add close button */}
            </h4>
            <div className={css.scheduledMessageList}>
              {scheduledMessages.map((schMsg) => (
                <ScheduledMessageView
                  key={schMsg.id}
                  scheduledMessage={schMsg}
                  timezone={timezone}
                  isModal={isModal}
                />
              ))}
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state: State) => {
  const thread = selectThreadByPhone(state, state.route.params.phone);
  return {
    thread,
    scheduledMessages: thread
      ? selectUpcomingScheduledMessagesForThread(state, thread.id)
      : [],
    recipient: thread
      ? selectDefaultRecipientForThread(state, thread.id)
      : null,
    timezone: getTimezone(state),
  };
};

export default (connect(mapStateToProps)(
  ScheduledMessagesForContact,
): React.StatelessFunctionalComponent<Props>);
