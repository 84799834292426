// @flow

import type {Dispatch, ThunkAction} from 'src/reducers';

import differenceBy from 'lodash/differenceBy';

import {thunkify as flow} from 'src/utils/thunks';
import * as reduxApi from 'src/utils/redux-api-v2';
import {key, cached, fetching, evictAction} from 'src/utils/redux';


export const RECEIVE_FLAGGED_WORDS = 'flagging/receiveFlaggedWords';
export const RECEIVE_DEFAULT_EMAILS = 'flagging/receiveDefaultEmails';
export const RECEIVE_FLAGGED_RATING = 'flagging/receiveFlaggedRating';
export const ADD_FLAGGED_WORD = 'flagging/addFlaggedWord';
export const ADD_DEFAULT_EMAIL = 'flagging/addDefaultEmail';
export const REMOVE_FLAGGED_WORD = 'flagging/removeFlaggedWord';
export const REMOVE_DEFAULT_EMAIL = 'flagging/removeDefaultEmail';
export const EDIT_FLAGGED_RATING = 'flagging/editFlaggedRating';
export const EDIT_FLAGGED_WORD = 'flagging/editFlaggedWord';
export const EDIT_DEFAULT_EMAIL = 'flagging/editDefaultEmail';

export type FlaggingAction =
  | ReceiveFlaggedWordsAction
  | ReceiveDefaultEmailsAction
  | ReceiveFlaggedRatingAction
  | AddFlaggedWordAction
  | AddDefaultEmailAction
  | RemoveFlaggedWordAction
  | EditFlaggedRatingAction
  | EditDefaultEmailAction
  | EditFlaggedWordAction
  | RemoveDefaultEmailAction;

export type FlaggedWord = {
  word: string,
  id: string,
};

type ReceiveFlaggedWordsAction = {
  type: 'flagging/receiveFlaggedWords',
  payload: FlaggedWord[],
};

export const receiveFlaggedWords = (
  payload: FlaggedWord[],
): ReceiveFlaggedWordsAction => ({
  type: RECEIVE_FLAGGED_WORDS,
  payload,
});

type ReceiveDefaultEmailsAction = {
  type: 'flagging/receiveDefaultEmails',
  payload: FlaggedWord[],
};

export const receiveDefaultEmails = (
  payload: FlaggedWord[],
): ReceiveDefaultEmailsAction => ({
  type: RECEIVE_DEFAULT_EMAILS,
  payload,
});

type ReceiveFlaggedRatingAction = {
  type: 'flagging/receiveFlaggedRating',
  payload: Number,
};

export const receiveFlaggedRating = (
  payload: Number,
): ReceiveFlaggedRatingAction => ({
  type: RECEIVE_FLAGGED_RATING,
  payload,
});

type AddFlaggedWordAction = {
  type: 'flagging/addFlaggedWord',
};

export const addFlaggedWord = (): AddFlaggedWordAction => ({
  type: ADD_FLAGGED_WORD,
});

type AddDefaultEmailAction = {
  type: 'flagging/addDefaultEmail',
};

export const addDefaultEmail = (): AddDefaultEmailAction => ({
  type: ADD_DEFAULT_EMAIL,
});

type RemoveFlaggedWordAction = {
  type: 'flagging/removeFlaggedWord',
  payload: FlaggedWord,
};

export const removeFlaggedWord = (
  flaggedWord: FlaggedWord,
): RemoveFlaggedWordAction => ({
  type: REMOVE_FLAGGED_WORD,
  payload: flaggedWord,
});

type RemoveDefaultEmailAction = {
  type: 'flagging/removeDefaultEmail',
  payload: string,
};

export const removeDefaultEmail = (
  email: string,
): RemoveDefaultEmailAction => ({
  type: REMOVE_DEFAULT_EMAIL,
  payload: email,
});

type EditFlaggedRatingAction = {
  type: 'flagging/editFlaggedRating',
  payload: Number,
};

export const editFlaggedRating = (rating: Number): EditFlaggedRatingAction => ({
  type: EDIT_FLAGGED_RATING,
  payload: rating,
});

type EditFlaggedWordAction = {
  type: 'flagging/editFlaggedWord',
  payload: string,
};

export const editFlaggedWord = (word: string): EditFlaggedWordAction => ({
  type: EDIT_FLAGGED_WORD,
  payload: word,
});

type EditDefaultEmailAction = {
  type: 'flagging/editDefaultEmail',
  payload: string,
};

export const editDefaultEmail = (
  defaultEmail: string,
): EditDefaultEmailAction => ({
  type: EDIT_DEFAULT_EMAIL,
  payload: defaultEmail,
});

const saveFlaggedSettings =
  (): ThunkAction<mixed> => (dispatch: Dispatch, getState) => {
    const {flaggedRating, default_emails} = getState().flagging.editing;
    return dispatch(
      reduxApi.post('responses/flagging/settings', {
        rating: flaggedRating,
        emails: default_emails,
      }),
    ).then(() => dispatch(receiveFlaggedRating(flaggedRating)));
  };

const createFlaggedWords =
  (creations: FlaggedWord[]): ThunkAction<mixed> =>
  (dispatch: Dispatch) =>
    dispatch(
      reduxApi.post('responses/flagging/flagged-word', {
        words: creations.map(({word}) => word.toLowerCase()),
      }),
    ).then((flaggedWords) => dispatch(receiveFlaggedWords(flaggedWords)));

const deleteFlaggedWords =
  (deletions: FlaggedWord[]): ThunkAction<mixed> =>
  (dispatch: Dispatch) =>
    dispatch(
      reduxApi.del('responses/flagging/flagged-word', {
        word_ids: deletions.map(({id}) => id),
      }),
    ).then((flaggedWords) => dispatch(receiveFlaggedWords(flaggedWords)));

export const getFlaggingSettings =
  (): ThunkAction<void> => (dispatch: Dispatch) =>
    dispatch(reduxApi.get('responses/flagging')).then((flaggingSettings) => {
      dispatch(receiveFlaggedWords(flaggingSettings.words));
      dispatch(receiveFlaggedRating(flaggingSettings.rating));
      dispatch(receiveDefaultEmails(flaggingSettings.emails));
    });

export const getFlaggedWords: () => ThunkAction<mixed> = flow(
  key(() => 'getFlaggedWords'),
  cached(receiveFlaggedWords),
  fetching(),
)(() => reduxApi.get('responses/flagging/flagged-word'));

export const handleSave: () => ThunkAction<mixed> = flow(
  key(() => 'handleFlaggingSave'),
  fetching({}),
)(() => (dispatch: Dispatch, getState: Function) => {
  const {
    flagging: {editing, flaggedWords},
    requestCache: {cache},
  } = getState();
  const changes = [];
  const creations = differenceBy(
    editing.flaggedWords,
    flaggedWords,
    'word',
  ).reverse();
  const deletions = differenceBy(flaggedWords, editing.flaggedWords, 'word');

  changes.push(dispatch(saveFlaggedSettings()));

  if (creations.length) {
    changes.push(dispatch(createFlaggedWords(creations)));
  }

  if (deletions.length) {
    changes.push(dispatch(deleteFlaggedWords(deletions)));
  }

  if (changes.length) {
    const cachedFlaggedEventResponsesKeys = Object.keys(cache).filter((key) =>
      key.startsWith('flaggedEventResponses'),
    );
    for (const key of cachedFlaggedEventResponsesKeys) {
      changes.push(dispatch(evictAction(key)));
    }
  }

  return Promise.all(changes);
});
