// @noflow

import type {Message} from 'src/types/messages';
import type {Contact} from 'src/types/contacts';

import * as React from 'react';
import {connect} from 'react-redux';

import {acknowledgeMostRecentUnread} from 'src/action-creators/messages';
import {
  selectMostRecentUnreadMessage,
  selectUnreadCountSinceLastAcknowledge,
} from 'src/selectors/messages';
import {selectCurrentPhoneCountry} from 'src/selectors/phone-number-sets';
import {formatPhone} from 'src/utils/phone';
import HeadSetter from 'src/components/lib/head-setter';


const TAB_CHANGE_INTERVAL = 2000;
const UNREAD_FAVICON_HREF = '/images/favicon/unread-messages-iconx96.png';

type Props = {
  document?: Document,
  mostRecentUnreadMessage: ?Message,
  unreadCountSinceLastAcknowledge: number,
  inboxOpen: boolean,
  contact: ?Contact,
  dispatch: Dispatch,
  currentPhoneCountry: string,
};

// This component does two main things:
// 1. Notifies user via browser tab of unread messages received while the user is on
//    other tab or window
// 2. Clears notification when user returns to browser tab in which this component
//    lives (so long as inbox view is also on the page)
export class UnreadTabNotification extends React.Component<
  Props,
  {index: number},
> {
  timeoutId: ?TimeoutID;

  constructor(props: Props) {
    super(props);
    this.timeoutId = null;
    this.state = {
      index: 0,
    };
    (this: any).getTitle = this.getTitle.bind(this);
    (this: any).getFaviconHref = this.getFaviconHref.bind(this);
    (this: any).conditionallyAcknowledge = this.conditionallyAcknowledge.bind(
      this,
    );
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  // Whenever this component and the inbox view component are visible, we clear
  // the unread message notification
  conditionallyAcknowledge(): void {
    // NOTE(gab): User can only acknowledge unread messages if the inbox view is
    // on the page and the app is not embedded in a subframe.
    if (window.top === window && this.props.inboxOpen) {
      this.props.dispatch(acknowledgeMostRecentUnread());
    }
  }

  hasUnread() {
    return (
      this.props.mostRecentUnreadMessage &&
      this.props.unreadCountSinceLastAcknowledge > 0
    );
  }

  getTitle(oldTitle: string): ?string {
    const titles = this.getTitles(oldTitle);
    if (titles) {
      // Make sure we only ever have one and only one timeout running at a time
      if (!this.timeoutId) {
        this.timeoutId = setTimeout(() => {
          this.timeoutId = null;
          this.setState((prevState) => ({
            index: (prevState.index + 1) % titles.length,
          }));
        }, TAB_CHANGE_INTERVAL);
      }
      return titles[this.state.index];
    }
  }

  getTitles(oldTitle: string): ?Array<string> {
    if (!this.hasUnread()) {
      return;
    }

    const {
      mostRecentUnreadMessage,
      contact,
      unreadCountSinceLastAcknowledge,
      currentPhoneCountry,
    } = this.props;

    const phoneOrName = contact
      ? contact.firstName ||
        contact.lastName ||
        formatPhone(contact.phone, currentPhoneCountry)
      : formatPhone(mostRecentUnreadMessage.fromPhone, currentPhoneCountry);
    return [
      `(${unreadCountSinceLastAcknowledge}) ${oldTitle}`,
      `${phoneOrName} messaged you`,
    ];
  }

  getFaviconHref() {
    if (!this.hasUnread()) {
      return;
    }

    return UNREAD_FAVICON_HREF;
  }

  render() {
    return (
      <BrowserTabNotification
        document={this.props.document}
        onGetTitle={this.getTitle}
        onGetFaviconHref={this.getFaviconHref}
        onVisible={this.conditionallyAcknowledge}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const mostRecentUnreadMessage = selectMostRecentUnreadMessage(state);
  const currentPhoneCountry = selectCurrentPhoneCountry(state);

  return {
    mostRecentUnreadMessage,
    unreadCountSinceLastAcknowledge: selectUnreadCountSinceLastAcknowledge(
      state,
    ),
    contact:
      mostRecentUnreadMessage &&
      mostRecentUnreadMessage.contactId &&
      state.contacts.contacts[mostRecentUnreadMessage.contactId],
    currentPhoneCountry,
  };
};

export default connect(mapStateToProps)(UnreadTabNotification);

type BrowserTabNotificationProps = {
  // For testing, allow passing in document
  document?: Document,
  onGetTitle: (oldTitle: string) => ?string,
  onGetFaviconHref: () => ?string,
  onVisible: () => void,
};

// When browser tab goes inactive (user goes to another tab or window), this component
// will update the tab title with the return value from props.getTitle
export class BrowserTabNotification extends React.Component<
  BrowserTabNotificationProps,
  {
    hiddenDocumentTitle: ?string,
  },
> {
  // For testing, allow passing in document
  document: ?Document;

  constructor(props: BrowserTabNotificationProps) {
    super(props);
    this.state = {
      hiddenDocumentTitle: null,
    };
    this.document = this.props.document;
    (this: any).handleVisibilityChange = this.handleVisibilityChange.bind(this);
  }

  componentDidMount() {
    if (!this.document) {
      this.document = window.document;
    }
    this.document.addEventListener(
      'visibilitychange',
      this.handleVisibilityChange,
    );
    if (this.document && !this.document.hidden) {
      this.props.onVisible();
    }
  }

  componentWillUnmount() {
    if (!this.document) {
      return;
    }

    this.document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange,
    );
  }

  handleVisibilityChange() {
    if (!this.document) {
      return;
    }

    if (this.document.hidden) {
      // Stash the old document title
      this.setState({hiddenDocumentTitle: this.document.title});
    } else {
      // Restore old document title
      if (typeof this.state.hiddenDocumentTitle === 'string') {
        this.document.title = this.state.hiddenDocumentTitle;
      }

      // Update state
      this.setState({hiddenDocumentTitle: null});

      // Let the parent element respond to visibility
      this.props.onVisible();
    }
  }

  render() {
    // TODO (gab): rewrite this component to use React Helmet and then it will
    // not need to access document directly except for visibility changes
    if (!this.document) {
      return null;
    }

    const {hiddenDocumentTitle} = this.state;

    // Do not update browser tab unless user is on another tab,
    // meaning this page is hidden
    if (typeof hiddenDocumentTitle !== 'string') {
      return null;
    }

    // Set document title
    const title = this.props.onGetTitle(hiddenDocumentTitle);
    return (
      <HeadSetter title={title} faviconUrl={this.props.onGetFaviconHref()} />
    );
  }
}
