// @noflow
//
import * as api from 'src/utils/api';
import typeof IndexStore from 'src/stores/index';


type MediaResult = {
  url: string,
};

export const uploadMedia = (store: IndexStore, fileData: File): Promise<MediaResult> => {
  let formData = new FormData();
  formData.append('upload', fileData);

  return api.post(store, 'media/upload', formData);
};
