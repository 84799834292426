// @flow strict

import * as React from 'react';
import {
  // $FlowFixMe[untyped-import]
  autoUpdate,
  // $FlowFixMe[untyped-import]
  flip,
  // $FlowFixMe[untyped-import]
  offset,
  // $FlowFixMe[untyped-import]
  shift,
  // $FlowFixMe[untyped-import]
  useFloating,
  // $FlowFixMe[untyped-import]
  useHover,
  // $FlowFixMe[untyped-import]
  useFocus,
  // $FlowFixMe[untyped-import]
  useClick,
  // $FlowFixMe[untyped-import]
  useRole,
  // $FlowFixMe[untyped-import]
  useInteractions,
  // $FlowFixMe[untyped-import]
  safePolygon,
} from '@floating-ui/react';

import {sizeFluid} from '@spaced-out/ui-design-system/lib/styles/variables/_size.js';
import {
  spaceNone,
  spaceXXSmall,
} from '@spaced-out/ui-design-system/lib/styles/variables/_space.js';

import {classify} from 'src/utils/classify';

// $FlowFixMe[nonstrict-import]
import {ClickAway} from '@spaced-out/ui-design-system/lib/utils/click-away';
import type {ButtonProps} from '@spaced-out/ui-design-system/lib/components/Button';
import {
  Button,
  UnstyledButton,
} from '@spaced-out/ui-design-system/lib/components/Button';
import {ConditionalWrapper} from '@spaced-out/ui-design-system/lib/components/ConditionalWrapper';
import type {
  MenuOption,
  MenuProps,
} from '@spaced-out/ui-design-system/lib/components/Menu';
import {Menu} from '@spaced-out/ui-design-system/lib/components/Menu';
import type {BaseTooltipProps} from '@spaced-out/ui-design-system/lib/components/Tooltip';
import {Tooltip} from '@spaced-out/ui-design-system/lib/components/Tooltip';
import {SideMenuLink} from '@spaced-out/ui-design-system/lib/components/SideMenuLink';
import type {SideMenuLinkProps} from '@spaced-out/ui-design-system/lib/components/SideMenuLink';

import css from './custom-dropdown.css';


export const ANCHOR_POSITION_TYPE = Object.freeze({
  top: 'top',
  topStart: 'top-start',
  topEnd: 'top-end',
  bottom: 'bottom',
  bottomStart: 'bottom-start',
  bottomEnd: 'bottom-end',
  right: 'right',
});

export const STRATEGY_TYPE = Object.freeze({
  absolute: 'absolute',
  fixed: 'fixed',
});

export type AnchorType = $Values<typeof ANCHOR_POSITION_TYPE>;
export type Strategy = $Values<typeof STRATEGY_TYPE>;

type ClassNames = $ReadOnly<{
  buttonWrapper?: string,
  dropdownContainer?: string,
  buttonIcon?: string,
}>;

export type CustomDropdownProps = {
  ...ButtonProps,
  classNames?: ClassNames,
  menu?: MenuProps,
  positionStrategy?: Strategy,
  anchorPosition?: AnchorType,
  onOptionSelect?: (option: MenuOption, ?SyntheticEvent<HTMLElement>) => mixed,
  sideMenuLink: SideMenuLinkProps,
  ...
};

export const HoverDropdown: React$AbstractComponent<
  CustomDropdownProps,
  HTMLDivElement,
> = React.forwardRef<CustomDropdownProps, HTMLDivElement>(
  (
    {
      anchorPosition = 'bottom-start',
      positionStrategy = STRATEGY_TYPE.fixed,
      size = 'medium',
      onOptionSelect,
      menu,
      classNames,
      disabled,
      children,
      iconRightName,
      iconRightType = 'solid',
      isFluid,
      sideMenuLink,
      ...restButtonProps
    }: CustomDropdownProps,
    forwardRef,
  ) => {
    const menuBtnRef = React.useRef(null);
    const [isOpen, setIsOpen] = React.useState(false);

    React.useImperativeHandle(forwardRef, () => menuBtnRef.current);
    const {x, y, refs, strategy, context} = useFloating({
      open: isOpen,
      onOpenChange: setIsOpen,
      strategy: positionStrategy,
      placement: anchorPosition,
      whileElementsMounted: autoUpdate,
      middleware: [shift(), flip(), offset(parseInt(spaceXXSmall))],
    });

    const hover = useHover(context, {
      handleClose: safePolygon(),
    });
    const focus = useFocus(context);
    const click = useClick(context);
    const role = useRole(context, {role: 'menu'});

    const {getReferenceProps, getFloatingProps} = useInteractions([
      hover,
      focus,
      role,
      click,
    ]);

    return (
      <div
        data-testid="ButtonDropdown"
        className={classify(
          css.customDropdownContainer,
          {
            [css.isFluid]: isFluid,
          },
          classNames?.dropdownContainer,
        )}
        ref={menuBtnRef}
        tabIndex={disabled ? '-1' : 0}
      >
        <SideMenuLink
          {...sideMenuLink}
          {...restButtonProps}
          {...getFloatingProps()}
          disabled={disabled}
          size={size}
          ref={refs.setReference}
        />
        {isOpen && menu && (
          <div
            ref={refs.setFloating}
            style={{
              display: 'flex',
              position: strategy,
              top: y ?? spaceNone,
              left: x ?? spaceNone,
              ...(isFluid && {width: sizeFluid}),
            }}
            {...getFloatingProps()}
          >
            <Menu
              {...menu}
              onSelect={(option, e) => {
                onOptionSelect && onOptionSelect(option, e);
              }}
              size={menu.size || size}
            />
          </div>
        )}
      </div>
    );
  },
);
