//@flow strict
// $FlowFixMe[nonstrict-import]
import type {Dispatch, GetState, ThunkAction} from 'src/reducers';
import {RRule} from 'rrule';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, cached, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';
import type {SchedulingStoreData} from 'src/types/automation-workflow-scheduling';
import {
  schedulingDataParse,
  normalizeSchedulingBody,
} from 'src/utils/automation-workflow-scheduling.js';


export type AutomationWorkflowSchedulingAction =
  | UpdateNodeSchedulingAction
  | ReceiveNodeSchedulingAction;

/**
 * Action Name constants definition starts here.
 */

export const UPADTE_NODE_SCHEDULING = 'node-scheduling/update-node-scheduling';

export const RECEIVE_NODE_SCHEDULING =
  'node-scheduling/receive-node-scheduling';

/**
 * Action type definition starts here.
 */

type UpdateNodeSchedulingPayload = SchedulingStoreData;
type UpdateNodeSchedulingAction = {
  type: typeof UPADTE_NODE_SCHEDULING,
  payload: UpdateNodeSchedulingPayload,
};

type ReceiveNodeSchedulingAction = {
  type: typeof RECEIVE_NODE_SCHEDULING,
  payload: SchedulingStoreData,
};

export const updateNodeSchedulingAction = (
  payload: UpdateNodeSchedulingPayload,
): UpdateNodeSchedulingAction => ({
  type: UPADTE_NODE_SCHEDULING,
  payload,
});

export const receiveNodeScheduling = (
  payload: SchedulingStoreData,
): ReceiveNodeSchedulingAction => ({
  type: RECEIVE_NODE_SCHEDULING,
  payload,
});

export const fetchNodeScheduling: (
  scheduleNodeId: string,
) => ThunkAction<mixed> = flow(
  key(() => 'workflow-automation/scheduling'),
  fetching(),
)((scheduleNodeId: string) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.get('node', {node_id: `schedule:${scheduleNodeId}`}),
  ).then((payload) => {
    dispatch(receiveNodeScheduling(schedulingDataParse(payload)));
  });
});

export const updateNodeScheduling =
  (
    nodeId: string,
    swimlane_id: string,
    workflowId: string,
  ): ThunkAction<void> =>
  (dispatch: Dispatch, getState: GetState) => {
    const schedulingStoreData = getState().scheduling;
    return dispatch(
      reduxApi.put(`node`, {
        node_type: 'schedule',
        swimlane_id: parseInt(swimlane_id),
        workflow_id: parseInt(workflowId),
        body: normalizeSchedulingBody(schedulingStoreData),
      }),
    );
  };
