// @flow

//$FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores/index';
import type {EntityType} from 'src/types/ats-entities';
import type {ListQuestion} from 'src/types/survey';
import type {DynamicLabels} from 'src/action-creators/dynamic-labels.js';

import * as React from 'react';
import _get from 'lodash/get';
import flow from 'lodash/flow';
import {useSelector} from 'react-redux';

import {emptyArray} from 'src/utils';

import {createDynamicLabelSelector} from 'src/selectors/dynamic-labels';

import {
  addListChoiceOption,
  deleteAttributeListOption,
  updateListAttributeOption,
} from 'src/actions/event-creation';

import mapProps from 'src/flux/map-props.jsx';
import {TokenListInput} from 'src/components/lib/token-list-input';
import AddButton from 'src/components/lib/add-button';

import CloseIcon from 'src/images/close-icon-round.svg';

import css from './module.css';


const selectDynamicLabels = createDynamicLabelSelector({depth: 'rte'});
export function useAtsVariables(entityType: EntityType): DynamicLabels {
  return useSelector((state) => selectDynamicLabels(state, entityType));
}

const mapper = (props) => ({
  ...props,
  fieldErrors: _get(props.error, 'attributes'),
});

type ListProps = {
  // $FlowFixMe[value-as-type] [v1.32.0]
  fluxStore: IndexStore,
  moduleData: ListQuestion,
  dynamicLabels: DynamicLabels,
};

const ListModule = ({
  fluxStore,
  moduleData,
  dynamicLabels,
}: ListProps): React.Node => {
  const options = dynamicLabels.map((field) => ({
    label: field.name,
    value: field.value,
  }));

  return (
    <div className={css.generalModule_content}>
      <label className={css.generalModule_questionLabel}>
        <div className={css.labelWithInlineSelector}>Display Fields</div>
      </label>
      <div className={css.listModule_main}>
        <div className={css.listModule_specify}>
          Specify what you want displayed in your list.
        </div>
        <div className={css.listModule_attributes}>
          {moduleData.attributesToInclude.map((attribute, index) => {
            const values = attribute
              ? [{label: attribute, value: attribute}]
              : [];
            return (
              <div className={css.listModule_row}>
                <div className={css.listModule_field}>Field {index + 1}:</div>
                <TokenListInput
                  values={values}
                  options={options}
                  placeholder="Select fields that will show up in the list"
                  onChange={(values) =>
                    updateListAttributeOption(
                      fluxStore,
                      index,
                      values.map(({value}) => value).join(''),
                    )
                  }
                  allowArbitraryValues={false}
                  limit={1}
                />
                {moduleData.attributesToInclude.length > 1 && (
                  <div className={css.optionRow_cell}>
                    <button
                      className={css.option_close}
                      onClick={() =>
                        deleteAttributeListOption(fluxStore, index)
                      }
                    >
                      <CloseIcon />
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className={css.addOption}>
        <AddButton
          text="Add Option"
          onClick={() => addListChoiceOption(fluxStore)}
        />
      </div>
    </div>
  );
};

export default (flow(mapProps(mapper))(ListModule): React.ComponentType<{}>);
