// @flow strict-local

import * as React from 'react';

import {Select} from 'src/components/lib/token-list-input/new-stuff.jsx';
import {Toggle} from 'src/components/conversation-builder/node-editor/node-link-toggle.jsx';
import {TabGroup} from 'src/components/conversation-builder/node-editor/tab-group.jsx';


export default function TestPage(): React.Node {
  const [value, setValue] = React.useState(false);

  return (
    <div style={{width: 400, padding: '50px', flexFlow: 'column'}}>
      <Toggle value={value} onChange={setValue} />
    </div>
  );
}

const options = ['kyle', 'alex', 'joe', 'marcos', 'bob', 'keith'];

const tabs = [
  {id: '1', title: 'Hello'},
  {id: '2', title: 'What is?'},
];
