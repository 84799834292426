//@flow strict

import type {
  RecentQuestions,
  QuestionsMultipleVersion,
  CandidateDetails,
  QuestionDetail,
  JdGenerate,
  Query,
  JdListing,
  PrescreeningListing,
} from 'src/types/sense-ai-panel';
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {fetching, key} from 'src/utils/redux';


export const SET_QUESTIONS = 'senseai-panel/setquestions';
export const RECENT_QUESTIONS = 'senseai-panel/setRecentQuestions';
export const ALL_DRAFT_QUESTIONS = 'senseai-panel/getAllDraftQuestions';
export const TITLE = 'senseai-panel/setTitle';
export const MULTIPLE_QUESTION_VERSION =
  'senseai-panel/multipleQuestionVersion';
export const CLEAR_MULTIPLE_VERSIONS = 'senseai-panel/clearMultipleVersions';
export const RESUME_QUESTIONS = 'senseai-panel/resumeQuestions';
export const CANDIDATE_DETAILS = 'senseai-panel/candidateDetails';
export const ALL_CANDIDATES = 'senseai-panel/allCandidates';
export const JOB_TITLE = 'senseai-panel/jobTitle';
export const SET_AI_PANEL_OPEN = 'senseai-panel/aiPanelOpen';
export const GENERATE_JD = 'senseai-panel/jdGenerate';
export const RECEIVE_ALL_JDS = 'senseai-panel/receiveJDs';
export const RECEIVE_ALL_PRESCREENING_QUESTIONS =
  'senseai-panel/receiveAllPrescreeningQuestions';
export const DELETE_JD = 'senseai-panel/deleteJD';
export const DELETE_PRESCREENING_QUESTIONS =
  'senseai-panel/deletePrescreeningQuestions';
export const RECEIVE_COMPANY_LIST = 'senseai-panel/companyList';
export const SET_URL_SUFFIX_AND_OPEN_PANEL = 'senseai-panel/seturlsuffix';

export type SenseAIPanelActions =
  | SetAIPanelOpenAction
  | SetQuestionsAction
  | SetResumeQuestionsAction
  | SetCandidateDetailsAction
  | SetAllCandidateDetailsAction
  | SetJobTitleAction
  | SetRecentQuestionsAction
  | GetAllDraftQuestionsAction
  | GetTitle
  | SetMultipleQuestionVersionsAction
  | ClearMultipleQuestionVersionsAction
  | ReceiveAllJds
  | ReceiveAllPrescreeningQuestions
  | CompanyList
  | DeleteJdAction
  | DeletePrescreeningQuestionsAction
  | SetUrlSuffixAndOpenPanelAction;

type SetAIPanelOpenAction = {
  type: typeof SET_AI_PANEL_OPEN,
  payload: boolean,
};

export const setAIPanelOpenEvent = (value: boolean): SetAIPanelOpenAction => ({
  type: SET_AI_PANEL_OPEN,
  payload: value,
});

type SetQuestionsAction = {
  type: typeof SET_QUESTIONS,
  payload: string[],
};

export const setQuestionsEvent = (value: string[]): SetQuestionsAction => ({
  type: SET_QUESTIONS,
  payload: value,
});

type SetResumeQuestionsAction = {
  type: typeof RESUME_QUESTIONS,
  payload: string[],
};

export const setResumeQuestionsEvent = (
  value: string[],
): SetResumeQuestionsAction => ({
  type: RESUME_QUESTIONS,
  payload: value,
});

type SetCandidateDetailsAction = {
  type: typeof CANDIDATE_DETAILS,
  payload: CandidateDetails,
};

export const setCandidateDetailsEvent = (
  value: CandidateDetails,
): SetCandidateDetailsAction => ({
  type: CANDIDATE_DETAILS,
  payload: value,
});

type SetAllCandidateDetailsAction = {
  type: typeof ALL_CANDIDATES,
  payload: [],
};

export const setAllCandidateDetailsEvent = (
  value: [],
): SetAllCandidateDetailsAction => ({
  type: ALL_CANDIDATES,
  payload: value,
});

type ReceiveAllJds = {
  type: typeof RECEIVE_ALL_JDS,
  payload: JdListing,
};

const receiveAllJds = (payload: JdListing): ReceiveAllJds => ({
  type: RECEIVE_ALL_JDS,
  payload,
});

type ReceiveAllPrescreeningQuestions = {
  type: typeof RECEIVE_ALL_PRESCREENING_QUESTIONS,
  payload: PrescreeningListing,
};

const receiveAllPreScreeningQuestions = (
  payload: PrescreeningListing,
): ReceiveAllPrescreeningQuestions => ({
  type: RECEIVE_ALL_PRESCREENING_QUESTIONS,
  payload,
});

type CompanyList = {
  type: typeof RECEIVE_COMPANY_LIST,
  payload: mixed,
};

const receiveCompanyList = (payload: mixed): CompanyList => ({
  type: RECEIVE_COMPANY_LIST,
  payload,
});

type SetJobTitleAction = {
  type: typeof JOB_TITLE,
  payload: string,
};

export const setJobTitleEvent = (value: string): SetJobTitleAction => ({
  type: JOB_TITLE,
  payload: value,
});

type SetRecentQuestionsAction = {
  type: typeof RECENT_QUESTIONS,
  payload: RecentQuestions,
};

export const setRecentQuestionsEvent = (
  value: RecentQuestions,
): SetRecentQuestionsAction => ({
  type: RECENT_QUESTIONS,
  payload: value,
});

type GetAllDraftQuestionsAction = {
  type: typeof ALL_DRAFT_QUESTIONS,
  payload: Array<QuestionDetail>,
};

export const getAllDraftQuestionsEvent = (
  value: Array<QuestionDetail>,
): GetAllDraftQuestionsAction => ({
  type: ALL_DRAFT_QUESTIONS,
  payload: value,
});

type GetTitle = {
  type: typeof TITLE,
  payload: string,
};

export const setTitleEvent = (value: string): GetTitle => ({
  type: TITLE,
  payload: value,
});

type SetMultipleQuestionVersionsAction = {
  type: typeof MULTIPLE_QUESTION_VERSION,
  payload: QuestionsMultipleVersion,
};

export const setMultipleQuestionVersionsEvent = (
  value: QuestionsMultipleVersion,
): SetMultipleQuestionVersionsAction => ({
  type: MULTIPLE_QUESTION_VERSION,
  payload: value,
});

type ClearMultipleQuestionVersionsAction = {
  type: typeof CLEAR_MULTIPLE_VERSIONS,
};

export const clearMultipleQuestionVersionsEvent =
  (): ClearMultipleQuestionVersionsAction => ({
    type: CLEAR_MULTIPLE_VERSIONS,
  });

type SetJdGenerateAction = {
  type: typeof GENERATE_JD,
  payload: ?JdGenerate,
};

export const setJdGenerateData = (value: ?JdGenerate): SetJdGenerateAction => ({
  type: GENERATE_JD,
  payload: value,
});

type DeleteJdAction = {
  type: typeof DELETE_JD,
  payload: string,
};

export const deleteJdAction = (jdId: string): DeleteJdAction => ({
  type: DELETE_JD,
  payload: jdId,
});

type DeletePrescreeningQuestionsAction = {
  type: typeof DELETE_PRESCREENING_QUESTIONS,
  payload: string,
};

export const deletePrescreeningQuestions = (
  progressId: string,
): DeletePrescreeningQuestionsAction => ({
  type: DELETE_PRESCREENING_QUESTIONS,
  payload: progressId,
});

type SetUrlSuffixAndOpenPanelAction = {
  type: typeof SET_URL_SUFFIX_AND_OPEN_PANEL,
  payload: string,
};

export const setUrlSuffixAndOpenPanel = (
  urlSuffix: string,
): SetUrlSuffixAndOpenPanelAction => ({
  type: SET_URL_SUFFIX_AND_OPEN_PANEL,
  payload: urlSuffix,
});

export const getJDs: (query: Query) => ThunkAction<mixed> = flow(
  key((query) => `jdDetails-${JSON.stringify(query)}`),
  fetching(),
)(
  (query) => (dispatch: Dispatch) =>
    dispatch(reduxApi.get('sense-ai/job-description/history', query)).then(
      (payload) => {
        dispatch(receiveAllJds(payload));
      },
    ),
);

export const getPreScreeningQuestions: (query: Query) => ThunkAction<mixed> =
  flow(
    key((query) => `preScreening-${JSON.stringify(query)}`),
    fetching(),
  )(
    (query) => (dispatch: Dispatch) =>
      dispatch(reduxApi.get('sense-ai/screening/history', query)).then(
        (payload) => {
          dispatch(receiveAllPreScreeningQuestions(payload));
        },
      ),
  );

export const getCompanyList: (query: Query) => ThunkAction<mixed> = flow(
  key((query) => `companyList-${JSON.stringify(query)}`),
  fetching(),
)(
  (query) => (dispatch: Dispatch) =>
    dispatch(reduxApi.get('sense-ai/job-description/company/list', query)).then(
      (payload) => {
        dispatch(receiveCompanyList(payload));
      },
    ),
);

export const deletePrescreening: (progressId: string) => ThunkAction<mixed> =
  (progressId) => (dispatch: Dispatch) =>
    dispatch(reduxApi.del(`sense-ai/screening/${progressId}`)).then(() => {
      dispatch(deletePrescreeningQuestions(progressId));
    });

export const deleteJd: (jdId: string) => ThunkAction<mixed> =
  (jdId) => (dispatch: Dispatch) =>
    dispatch(reduxApi.del(`sense-ai/job-description/${jdId}`)).then(() => {
      dispatch(deleteJdAction(jdId));
    });
