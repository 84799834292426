// @flow strict

export const MouseTipCompanyDetails =
  'Make sure your Business Name, Physical Address and Tax number that you\
submit are exactly as they appear on your official Tax registration forms.\
If your name or address does not match exactly as they appear on your official business records,\
this can lower your trust score. We mean it when we say "exactly”.\
Down to every space, letter or punctuation mark!';

export const MouseTipBusinessReg =
  'If you are only doing business under one name via text messaging,\
you should only need to register one EIN that correlates to that DBA.\
However, if you identify yourself using a business name that is very different across EINs,\
you may want to consider registering multiple EINs so as to help carriers avoid confusion.\
Please note that you will need to pay additional brand and campaign registration fees for each EIN registered.';

export const AtsCredTemplate = `Hi Bullhorn Support,

We have started a new partnership with Sense and need your assistance in sending the following to the Sense team:

a) A specific account (username/pwd) in our Bullhorn account for integration with Sense, with the proper permissions set up to access all records
b) The Bullhorn API keys (client_id & secret) which that account can use to make the API requests to Bullhorn. We will be using REST APIs.

Please also disable the TOS (Terms of Service) prompt for the API User you create, so that Sense can log in programmatically.

Let me know if you have any questions.
    `;

export const BullHornSupportLink = 'https://help.bullhorn.com/s/contactsupport';
