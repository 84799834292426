// @flow strict

import type {JourneyCategoriesAction} from 'src/action-creators/journey-categories';
import type {Category} from 'src/types/journey-categories';

import {
  RECEIVE_CUSTOM_CATEGORIES,
  ADD_CUSTOM_CATEGORY,
  DELETE_CUSTOM_CATEGORY,
  UPDATE_CUSTOM_CATEGORY,
} from 'src/action-creators/journey-categories';


export type State = {
  customCategories: Category[],
};

const initialState = {
  customCategories: [],
};

export default (
  state: State = initialState,
  action: JourneyCategoriesAction,
): State => {
  switch (action.type) {
    case RECEIVE_CUSTOM_CATEGORIES: {
      return {
        ...state,
        customCategories: action.payload,
      };
    }
    case ADD_CUSTOM_CATEGORY: {
      const {id, name, time_created} = action.payload;
      return {
        ...state,
        customCategories: [
          ...state.customCategories,
          {id, name, count: 0, time_created},
        ],
      };
    }
    case DELETE_CUSTOM_CATEGORY: {
      const {id} = action.payload;
      return {
        ...state,
        customCategories: state.customCategories.filter(
          (category) => category.id !== id,
        ),
      };
    }
    case UPDATE_CUSTOM_CATEGORY: {
      const {id, name} = action.payload;
      return {
        ...state,
        customCategories: state.customCategories.map((category) =>
          category.id === id ? {...category, name} : category,
        ),
      };
    }
  }

  return state;
};
