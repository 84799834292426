// @flow

// $FlowFixMe[untyped-type-import]

import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getAgencyOverview} from 'src/action-creators/agency-overview';
import {getFilteredResponses} from 'src/action-creators/event-responses';
import {hasError} from 'src/utils/redux';
import {handleGenericApiError} from 'src/utils/errors';

import {getFlaggedWords} from 'src/action-creators/flagging';
import {getFiltered} from 'src/selectors/event-responses';

import Loading from 'src/components/lib/loading/loading.jsx';
import Dashboard from './dashboard.jsx';


const responsesQuery = () => ({
  time_ago: JSON.stringify({days: 30}),
  page: 0,
});

const DashboardContainer = (): React.Node => {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(true);
  const error = useSelector((state) => hasError(state, getAgencyOverview));

  React.useEffect(() => {
    Promise.all([
      dispatch(getAgencyOverview()),
      dispatch(getFilteredResponses(responsesQuery())),
      dispatch(getFilteredResponses({...responsesQuery(), flagged: true})),
      dispatch(getFlaggedWords()),
    ])
      .then(setLoading(false))
      .catch((error) => handleGenericApiError(error));
  }, []);

  const name = useSelector(
    (state) => state.accounts.data[state.accounts.authedUserId].name,
  );
  const agencyOverview = useSelector((state) => state.agencyOverview.data);
  const responseData = useSelector((state) =>
    getFiltered(state, responsesQuery()),
  );
  const flaggedResponseData = useSelector((state) =>
    getFiltered(state, {...responsesQuery(), flagged: true}),
  );
  const flaggedWords = useSelector((state) =>
    state.flagging.flaggedWords.map(({word}) => word),
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>There was an error retrieving the overview.</div>;
  }

  return (
    <Dashboard
      agencyOverview={agencyOverview}
      name={name}
      multiEntityEnabled={true}
      responseData={responseData}
      flaggedResponseData={flaggedResponseData}
      flaggedWords={flaggedWords}
    />
  );
};

export default DashboardContainer;
