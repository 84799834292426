// @flow strict

// $FlowFixMe[nonstrict-import]
import {
  // $FlowFixMe[nonstrict-import]
  Modifier,
  // $FlowFixMe[nonstrict-import]
  EditorState,
  // $FlowFixMe[nonstrict-import]
  ContentState,
  // $FlowFixMe[nonstrict-import]
  convertToRaw,
  // $FlowFixMe[nonstrict-import]
  convertFromRaw,
  // $FlowFixMe[nonstrict-import]
  SelectionState,
  // $FlowFixMe[nonstrict-import]
  type ContentBlock,
} from 'draft-js';
// $FlowFixMe[nonstrict-import]
import type {RawDraftContentState} from 'draft-js/lib/RawDraftContentState';
// $FlowFixMe[nonstrict-import]
import type DraftEntityInstance from 'draft-js/lib/DraftEntityInstance';


export type BlockNodeKey = string;

export function hasText(rawJson: RawDraftContentState): boolean {
  return rawJson.blocks.some((block) => block.text.trim().length > 0);
}

export function includesString(
  rawJson: RawDraftContentState,
  string: string,
): boolean {
  return rawJson.blocks.some((block) => block.text.includes(string));
}

export function testRegex(
  rawJson: RawDraftContentState,
  regex: RegExp,
): boolean {
  return rawJson.blocks.some((block) => regex.test(block.text));
}

export function insertText(
  editorState: EditorState,
  text: string,
): EditorState {
  const contentState = Modifier.replaceText(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    text,
  );
  return EditorState.forceSelection(
    EditorState.push(editorState, contentState, 'insert-characters'),
    contentState.getSelectionAfter(),
  );
}

export function createRawDraftContentState(
  text?: string,
): RawDraftContentState {
  const raw = text
    ? convertToRaw(ContentState.createFromText(text))
    : convertToRaw(EditorState.createEmpty().getCurrentContent());
  return raw;
}

export const textDraftToString = (
  textDraftJson: RawDraftContentState,
): string => {
  const content = convertFromRaw(textDraftJson);
  return content ? content.getPlainText() : '';
};

/**
 * A draft entity filter entities in a content block
 *
 * The filter function returns a boolean value to indicate
 * whether an entity should be processed.
 *
 */
export type DraftEntityFilter = (
  entity: DraftEntityInstance,
  block: ContentBlock,
) => boolean;

/**
 * A callback that mutates the text in a content block
 *
 * the callback receives the matched entity instance,
 * the substring it is attempting to replace and the
 * surrounding content block.
 *
 * the callback _must_ return a string. if no replacement
 * is needed, it should return the textContent.
 * */
export type DraftMutatingEntityCallback = (
  entity: DraftEntityInstance,
  textContent: string,
  blockContent: ContentBlock,
) => string;

/**
 * update matching entities in a RawDraftContentState
 *
 * given a filter and a callback, create a new rdcs that
 * has the updates applied. The callback must return a string
 * or the replaced content will turn into an emoji. To noop,
 * return the textContent passed into the callback
 * */
export function updateRdcsEntityContent(
  rdcs: RawDraftContentState,
  filter: DraftEntityFilter,
  callback: DraftMutatingEntityCallback,
): RawDraftContentState {
  const contentState = convertFromRaw(rdcs);

  const nextContentState = updateEntitiesInContentState(
    contentState,
    filter,
    callback,
  );

  return convertToRaw(nextContentState);
}

/**
 * update matching entities in a ContentState
 *
 * given a filter and a callback, create a new rdcs that
 * has the updates applied. The callback must return a string
 * or the replaced content will turn into an emoji. To noop,
 * return the textContent passed into the callback
 * */
export function updateEntitiesInContentState(
  contentState: ContentState,
  filter: DraftEntityFilter,
  callback: DraftMutatingEntityCallback,
): ContentState {
  let editorState = EditorState.createWithContent(contentState);

  // these should not change even after we mutate the editorState
  const blockMap = editorState.getCurrentContent().getBlockMap();

  for (const blockKey of blockMap.keys()) {
    const nextEditorState = updateEntitiesInContentBlock(
      editorState,
      blockKey,
      filter,
      callback,
    );
    editorState = nextEditorState;
  }
  return editorState.getCurrentContent();
}

/**
 * update matching entities in a ContentBlock
 *
 * given a filter and a callback, create a new rdcs that
 * has the updates applied. The callback must return a string
 * or the replaced content will turn into an emoji. To noop,
 * return the textContent passed into the callback.
 *
 * This function returns a new EditorState but only modifies
 * content in the provided blockKey for a provided editorState.
 *
 * You typically won't need to call this directly, it's called
 * by updateEntitiesInContentState to progressively update an
 * entire contentState.
 *
 * it seems weird to pass in a whole editorstate to modify a
 * ContentBlock but they're necessarily tied to editorstate
 * instances and there's no way to modify them (using Modifier
 * or Selection) outside of an editorstate context. bummer.
 * */
function updateEntitiesInContentBlock(
  editorState: EditorState,
  blockKey: string,
  filter: DraftEntityFilter,
  callback: DraftMutatingEntityCallback,
): EditorState {
  // this selection is collapsed and begins at [0,0], the start of the block
  let selection = SelectionState.createEmpty(blockKey);

  // This isn't 'mutable' but the value is continually reassigned in this function
  // because we need to update the block but continue doing work on it.
  let mutableEditorState = EditorState.forceSelection(editorState, selection);

  // while loop here because we keep running replacements until we
  // advance the focus cursor to the end of the block
  // (`done` is set to true when cursor reaches the end)
  let done = false;
  while (done === false) {
    // Each loop attempts a single mutation within the block
    const block = mutableEditorState
      .getCurrentContent()
      .getBlockForKey(blockKey);
    // Because each run of the loop may modify the block's content size, we need to
    // recalculate this length when we start each run
    const blockLength = block.getText().length;

    // The character list is a sparse immutablejs array of entity/style information at
    // each offset.  Again, this changes each time we mutate the block, so we need to
    // recalculate it before we begin.
    const cm = block.getCharacterList();

    // this is the left edge of the cursor. After a replacement happens, the offset is
    // to the right edge of the modified text.
    let focus = selection.getFocusOffset();
    let currentEntityKey = null;
    let entityKey = null;
    let entityData;
    // This loop builds up a draft selection.
    // When an entity is found at some position, the selection's focus offset
    // is extended if it matches the filter function.
    // the loop continues until either the focus point reaches the end of the block
    // or the end of an entity range.
    // #1
    // __xx___
    // ^
    // selection = [0,0], entityKey = null, currentEntityKey = null
    // (we find nothing here, so we advance the cursor)
    //
    // #2
    // __xx___
    //  ^
    // selection = [1,1], entityKey = null, currentEntityKey = null
    //
    // #3
    // __xx___
    //   ^
    // selection = [2,2], entityKey = null, currentEntityKey = 'x'
    //
    // #4
    // __xx___
    //    ^
    // selection = [2,3], entityKey = x, currentEntityKey = 'x'
    //
    // #5
    // __xx___
    //     ^
    // selection = [2,4], entityKey = x, currentEntityKey = null
    // (now with this selection and entityKey we can make the replacement
    //  this is when the inner for loop calls break and then does the actual
    //  text modification.)
    for (focus = selection.getStartOffset(); focus < blockLength; focus += 1) {
      currentEntityKey = cm.get(focus)?.getEntity();

      // if there's a mismatch between currentEntityKey and entityKey
      // we're at an entity boundary (#5)
      if (currentEntityKey !== entityKey) {
        // this collapsed range check handles the cases:
        // 1. we're at an [entity]|[plaintext] boundary
        // 2. we're at an [entity]|[entity] boundary
        // 3. we're at an [entity]" end of block boundary
        if (!selection.isCollapsed()) {
          break;
        }

        // are we at the start of an entity range? (#3)
        if (currentEntityKey != null) {
          // now, is this an entity our consumer cares about?
          const found = filter(
            mutableEditorState
              .getCurrentContent()
              .getEntity(currentEntityKey) ?? {},
            block,
          );

          if (found) {
            // update selection to include this character (#4)
            selection = selection.merge({
              anchorOffset: focus,
              focusOffset: focus + 1,
            });
            // update entityKey and entityData
            entityKey = currentEntityKey;
            entityData = entityKey
              ? mutableEditorState.getCurrentContent().getEntity(entityKey)
              : null;
          }
          // this is an entity, but we don't care about it (#1 -> #2)
          else {
            selection = selection.merge({
              anchorOffset: focus + 1,
              focusOffset: focus + 1,
            });
          }
        } else {
          // pass ,
        }
      }
      // two options here, we're either in the middle of an entity
      // or we're in the middle of nonmatching text
      else {
        // we're in a run of nonmatching text, move cursor up
        if (selection.isCollapsed()) {
          selection = selection.merge({
            anchorOffset: focus + 1,
            focusOffset: focus + 1,
          });
        }
        // we're in the middle of an entity, increase the focus by one (#4)
        else {
          selection = selection.merge({focusOffset: focus + 1});
        }
      }
    }
    // we're out of the for loop and if the selection isn't collapsed, it's beacuse
    // we matched a run of characters to be replaced
    if (!selection.isCollapsed() && entityData != null) {
      const matchedText = block
        .getText()
        .substring(selection.getStartOffset(), selection.getEndOffset());
      // we get the style key for selection so we don't mangle the style for the
      // replaced text
      const styleKey = cm.get(selection.getStartOffset()).getStyle();

      // this warning sign is just a sigil for actual replacements but maybe
      // this should always throw? Or maybe null should delete the matched
      // entity? or maybe it should just unset its entity??????
      // we'll cross that bridge when we get there
      const replacementText =
        callback(
          entityData,
          matchedText,
          mutableEditorState.getCurrentContent().getBlockForKey(blockKey),
        ) ?? '⚠️';

      const nextContentState = Modifier.replaceText(
        mutableEditorState.getCurrentContent(),
        selection,
        replacementText,
        styleKey,
        entityKey,
      );
      // update mutableEditorState
      mutableEditorState = EditorState.push(
        mutableEditorState,
        nextContentState,
        'insert-characters',
        true,
      );
      // update selection, it is now collapsed and directly after the insertion
      selection = mutableEditorState.getSelection();
    }

    // end the outer while loop if we advance the cursor to the end of the block
    if (selection.getFocusOffset() >= block.getText().length) {
      // focus has moved all the way to the end
      done = true;
    }
  }
  return mutableEditorState;
}
