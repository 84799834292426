// @flow strict-local

import type {AgencyConfig, BlackoutSettings} from 'src/types/agency';

import {useSelector} from 'react-redux';

import {selectAgencyConfig} from 'src/selectors/agency';
import {DEFAULT_NO_BLACKOUT_VALUE} from 'src/utils/blackout-window';


type UseAgencyConfig = ((key: string) => void | boolean | number | string) &
  (() => AgencyConfig);

/**
 * NOTE (kyle): the preferred way to call this hook is like this
 * ```
 * useAgencyConfig().myConfigFlag;
 * ```
 * This ensures you get the correct flag Flow type.
 */
const useAgencyConfig: UseAgencyConfig = function useAgencyConfig(key) {
  const agencyConfig = useSelector(selectAgencyConfig);
  return key ? agencyConfig[key] : agencyConfig;
};
export default useAgencyConfig;

export function useMultiEntityEnabled(): true {
  return true;
}

export function useChatbotEnabled(): boolean {
  return useAgencyConfig().enableChatbot;
}

export function useSchedulerEnabled(): boolean {
  return useAgencyConfig().enableSchedulerProduct;
}

export function useDefaultPhoneCountry(): string {
  return useAgencyConfig().defaultPhoneCountry;
}

export function useChatbotSourcing(): boolean {
  const configs = useAgencyConfig();
  return (
    configs.chatbotT2AEnabled ||
    configs.chatbotSourcingWidgetEnabled ||
    configs.chatbotSourcingLinkEnabled
  );
}

export function useChatbotOutbound(): boolean {
  return useAgencyConfig().chatbotOutboundEnabled;
}

export function useChatbotSourcingWidget(): boolean {
  return useAgencyConfig().chatbotSourcingWidgetEnabled;
}

export function useChatbotT2A(): boolean {
  return useAgencyConfig().chatbotT2AEnabled;
}

export function useJobMatchEnabledForEngage(): boolean {
  return useAgencyConfig().enableJobMatchForEngage;
}

export function useJobMatchEnabledForAnyProduct(): boolean {
  const config = useAgencyConfig();

  return (
    config.enableJobMatchForEngage ||
    config.enableJobMatchForMessaging ||
    config.enableJobMatchForOutboundChatbot ||
    config.enableJobMatchForInboundChatbot
  );
}

export function useChatbotSourcingLink(): boolean {
  return useAgencyConfig().chatbotSourcingLinkEnabled;
}

export function useSenseEvents(): boolean {
  return useAgencyConfig().enableSenseHiringEvents;
}

export function useBlackoutSettings(): BlackoutSettings {
  const {smsBlackoutSettings, emailBlackoutSettings} = useAgencyConfig(); //both are json strings
  return {
    smsBlackoutSettings: JSON.parse(smsBlackoutSettings),
    emailBlackoutSettings: JSON.parse(emailBlackoutSettings),
  };
}
export function useBlackoutSettingsOverlap({
  time,
  type,
}: {
  time: ?string,
  type: 'sms' | 'email',
}): {isOverlapping: boolean} {
  const result = {isOverlapping: false};
  const {smsBlackoutSettings, emailBlackoutSettings} = useBlackoutSettings(); //both are json strings

  const blackoutSettings =
    type === 'sms' ? smsBlackoutSettings : emailBlackoutSettings;
  const {start_time, end_time} = blackoutSettings;
  //if any of start time and end time is no_blackout, then return false
  if ([start_time, end_time].includes(DEFAULT_NO_BLACKOUT_VALUE)) {
    return result;
  }
  if (time != null) {
    result.isOverlapping =
      end_time > start_time
        ? time >= start_time && time <= end_time
        : time >= start_time || time <= end_time;
  }
  return result;
}

export function useAtsFieldTriggeredWritebackEnabled(): boolean {
  return useAgencyConfig().atsFieldTriggeredWritebackEnabled;
}

export function useEnableSenseAi(): boolean {
  return useAgencyConfig().enableSenseAi ?? false;
}
export function useSenseAi(): boolean {
  const agencyConfig = useSelector(selectAgencyConfig);
  return agencyConfig['useSenseAi'] ?? false;
}

export function useDisableNewMessageCompose(): boolean {
  const agencyConfig = useSelector(selectAgencyConfig);
  return agencyConfig['disableNewMessageCompose'] ?? false;
}

export function useAtsWritebackToManyFieldsHandlingMode():
  | 'REPLACE'
  | 'APPEND' {
  return useAgencyConfig().atsWritebackToManyFieldsHandlingMode;
}
export function useCustomDailyFrequencyEnabled(): boolean {
  return useAgencyConfig().customDailyFrequencyEnabled ?? false;
}
