// @flow strict

//$FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';
import type {
  PaginationDetails,
  FileCsvUploadEntries,
  GenericOptionsType,
  CsvMapping,
  ContactList,
  FileUploadAdditionalDetails,
  ContactListFilters,
  EditContactListData,
} from 'src/types/contacts-genesis.js';

export const getPaginationDetails = (
  state: State,
  key: string,
): PaginationDetails => {
  return state.contactsGenesis.paginationDetails[key];
};

export const selectFileCsvUploadHeaders = (state: State): string[] =>
  state.contactsGenesis.fileCsvUploadHeaders;

export const selectFileCsvUploadEntries = (
  state: State,
): FileCsvUploadEntries[] => state.contactsGenesis.fileCsvUploadEntries;

export const selectFileCsvUpload = (state: State): File | null =>
  state.contactsGenesis.fileCsvUpload;

export const selectFileUploadAdditionalDetails = (
  state: State,
): FileUploadAdditionalDetails =>
  state.contactsGenesis.fileUploadAdditionalDetails;

export const selectCsvMappingForFileUpload = (state: State): CsvMapping => {
  const mappedEntries = state.contactsGenesis.fileCsvUploadEntries;
  const mapping = {};
  const result = mappedEntries.forEach((entry) => {
    const {mappedTo, id} = entry;
    if (mappedTo && id) {
      mapping[mappedTo] = id;
    }
  });
  return mapping;
};

export const selectContactLists = (state: State): ContactList[] =>
  state.contactsGenesis.contactsListData;

export const selectArchivedContactLists = (state: State): ContactList[] =>
  state.contactsGenesis.archivedContactsListData;

export const selectContactListsAsOptions = (
  state: State,
): GenericOptionsType[] => {
  return state.contactsGenesis.contactListsForUploadScreen.map((list) => {
    return {key: list.id, label: list.title};
  });
};

export const selectUploadContactListNameFromId = (
  state: State,
  id: string,
): string => {
  return (
    state.contactsGenesis.contactListsForUploadScreen.find(
      (contactList) => contactList.id === id,
    )?.title || 'List name'
  );
};

export const selectIsContactListLimitReached = (state: State): boolean => {
  return state.contactsGenesis.contactListLimitReached;
};

export const selectContactListNameFromId = (
  state: State,
  id: string,
): string => {
  return (
    state.contactsGenesis.contactsListData.find(
      (contactList) => contactList.id === id,
    )?.title || 'List name'
  );
};

export const selectContactListFilters = (state: State): ContactListFilters => {
  return state.contactsGenesis.contactListFilters;
};

export const selectEditContactListsData = (
  state: State,
): EditContactListData => {
  return state.contactsGenesis.editContactListsData;
};

export const selectContactPhoneNumber = (state: State): string => {
  return state.contactsGenesis.contactPhoneNumber;
};

export const selectContactListIdToAddContact = (state: State): string => {
  return state.contactsGenesis.contactListIdForContactCreation;
};
