// @flow

export const reducePage = (
  oldList: string[],
  newItems: {id: string}[],
  offset: number,
): string[] => {
  const ids = newItems.map(({id}) => id);

  let list;
  if (offset === 0) {
    list = ids;
  } else {
    list = oldList;
    const spliceIndex = list.indexOf(ids[0]);
    if (spliceIndex >= 0) {
      list = oldList.slice(0, spliceIndex);
    }
    // $FlowIssue not sure what's happening here
    list = list.concat(ids);
  }

  return list;
};
