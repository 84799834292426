var React = require('react');

function EmailIcon (props) {
    return React.createElement("svg",props,React.createElement("g",{"fillRule":"evenodd"},[React.createElement("path",{"d":"M1 6c0-1.105.891-2 2.001-2h12.006C16.107 4 17 4.887 17 6v6c0 1.105-.895 2-1.994 2H2.994A1.992 1.992 0 011 12V6z","key":0}),React.createElement("path",{"strokeLinecap":"round","strokeLinejoin":"round","d":"M3 6.222l6.15 3.334L15 6.222","key":1})]));
}

EmailIcon.defaultProps = {"width":"18","height":"18","viewBox":"0 0 18 18"};

module.exports = EmailIcon;

EmailIcon.default = EmailIcon;
