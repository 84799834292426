// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {EmailState} from 'src/reducers/email';
//$FlowFixMe[nonstrict-import]
import type {DynamicLabels} from 'src/types/dynamic-labels';
// $FlowFixMe[nonstrict-import]
import type {CompleteEmailState} from 'src/types/email';
import type {
  EntityMappingsByEntityType,
  TargetEntity,
  StandardEntity,
} from 'src/types/target-entity-types';
import type {EntityType} from 'src/types/ats-entities';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';

import {
  parseEmail,
  normalizeEmailEdit,
  getAttributeDataFromEntityGroup,
  getIncomingRelatedEntityGroupIds,
} from 'src/utils/email';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
import {omit} from 'lodash';
import {getAutomationWorkflow} from 'src/action-creators/automation-workflow';
import {
  SCHEDULE_DATE_TYPES,
  SCHEDULE_TIME_TYPES,
} from 'src/utils/automation-workflow-scheduling.js';


export const RECEIVE_EMAIL_INFO = 'email/receive';
export const UPDATE_EMAIL_INFO = 'email/update';

export const RECEIVE_COMPLETE_EMAIL_INFO = 'email/receiveCompleteEmail';

export const RESET = 'email/reset';

type UpdateEmailInfoPayload = $Shape<EmailState>;

type ReceiveEmailInfoAction = {
  type: 'email/receive',
  payload: EmailState,
};

type UpdateEmailInfoAction = {
  type: 'email/update',
  payload: UpdateEmailInfoPayload,
};

type ReceiveCompleteEmailInfoAction = {
  type: 'email/receiveCompleteEmail',
  payload: CompleteEmailState,
};

type ResetStateAction = {
  type: 'email/reset',
};

export type EmailAction =
  | ReceiveEmailInfoAction
  | UpdateEmailInfoAction
  | ResetStateAction
  | ReceiveCompleteEmailInfoAction;

export const reset = (): ResetStateAction => ({type: RESET});

export const receiveEmailNode = (
  payload: EmailState,
): ReceiveEmailInfoAction => ({
  type: RECEIVE_EMAIL_INFO,
  payload,
});

export const receiveCompleteEmailNode = (
  payload: CompleteEmailState,
): ReceiveCompleteEmailInfoAction => ({
  type: RECEIVE_COMPLETE_EMAIL_INFO,
  payload,
});

export const getEmailTaskNode: (id: string) => ThunkAction<mixed> =
  (id) => async (dispatch: Dispatch, getState) => {
    const emailNode = await dispatch(
      reduxApi.get(`node`, {
        node_id: `task:${id}`,
      }),
    );

    const parsedEmailNode = parseEmail(emailNode);
    //dispatch(receiveCompleteEmailNode(emailNode));
    return dispatch(receiveEmailNode(parsedEmailNode));
  };

export const updateEmailInfo = (
  payload: UpdateEmailInfoPayload,
): UpdateEmailInfoAction => ({
  type: UPDATE_EMAIL_INFO,
  payload,
});

export const uploadAttachment: (
  emailTaskId: number,
  file: File,
) => ThunkAction<void> = flow(
  key((_, file) => file.name),
  fetching(),
)((emailTaskId: number, file: File) => async (dispatch: Dispatch) => {
  return await dispatch(
    reduxApi.put(
      `task/${emailTaskId}/attachments`,
      {file},
      {},
      {
        multipart: true,
      },
    ),
  );
});

export const deleteAttachment: (
  emailTaskId: number,
  id: string,
) => ThunkAction<mixed> = (emailTaskId, id) => (dispatch: Dispatch) => {
  return dispatch(reduxApi.del(`task/${emailTaskId}/attachments/${id}`));
};

export const getPreviewBeefreeHtml: ({
  entity_id: ?string,
  workflowId: string,
  workflowEntityType: EntityType,
  nodeId: string,
}) => ThunkAction<mixed> =
  ({entity_id, workflowId, workflowEntityType, nodeId}) =>
  (dispatch: Dispatch, getState) => {
    if (entity_id == null) {
      return dispatch(getPreviewBeefreeHtmlWithoutReplacements());
    }
    const email = getState().email;
    const modulePayload = normalizeEmailEdit(email);

    const apiPayload = {
      execution: {
        entity_id,
        workflow_id: workflowId,
        entity_type: workflowEntityType,
      },
      task: modulePayload,
    };

    return dispatch(reduxApi.post(`node/${nodeId}/preview`, apiPayload));
  };

export const getPreviewBeefreeHtmlWithoutReplacements: () => ThunkAction<mixed> =
  () => (dispatch: Dispatch, getState) => {
    const email = getState().email;
    const beefree_json = email.content_json;

    return dispatch(reduxApi.post(`email/beefree-json-html`, {beefree_json}));
  };

// export const getEntityGroupAttributes: (
//   entityId: string,
//   entityType?: EntityType,
// ) => ThunkAction<mixed> =
//   (entityId, entityType) => async (dispatch: Dispatch) => {
//     const body = {
//       entity_type: entityType,
//       entity_id: entityId,
//       is_truncated: false,
//       level: 3,
//     };
//     const res = await dispatch(reduxApi.post('entity_group', body));
//     return getAttributeDataFromEntityGroup(res);
//   };

export const getIncomingPersonRelatedEntities: (
  entityId: string,
  entityType?: EntityType,
  workflowStandardEntity: StandardEntity,
) => ThunkAction<mixed> =
  (entityId, entityType, workflowStandardEntity) =>
  async (dispatch: Dispatch) => {
    const body = {
      entity_id: entityId,
      entity_type: entityType,
    };
    const res = await dispatch(reduxApi.post('entity_group/profile', body));
    return res.incoming_related_entities.length > 0
      ? getIncomingRelatedEntityGroupIds(
          res.incoming_related_entities,
          workflowStandardEntity,
        )
      : [];
  };
