// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';

// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';
// $FlowFixMe[untyped-import]
import {key, cached, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
import type {
  JobMatchCountParam,
  JobMatchSortParam,
  JobMatchQueryParam,
} from 'src/types/job-variables.js';


export const RECEIVE_JOB_MATCH_QUERY_PARAMS =
  'job-match-meta/receive-job-match-query-params';

type ReceiveJobMatchQueryParamsAction = {
  type: 'job-match-meta/receive-job-match-query-params',
  payload: {
    queryParams: JobMatchQueryParam[],
    countParam: JobMatchCountParam,
    sortParam: JobMatchSortParam,
  },
};

export type JobMatchMetaActions = ReceiveJobMatchQueryParamsAction;

export const receiveJobMatchQueryParams = (payload: {
  countParam: JobMatchCountParam,
  queryParams: JobMatchQueryParam[],
  sortParam: JobMatchSortParam,
}): ReceiveJobMatchQueryParamsAction => ({
  type: RECEIVE_JOB_MATCH_QUERY_PARAMS,
  payload,
});

export const getJobMatchQueryParams: () => ThunkAction<mixed> = flow(
  key(() => 'job-match/query-params'),
  fetching(),
)(() => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.get('discover/job-match/query-params?sense_app=engage'),
  ).then((payload) => {
    console.log(
      'count_param: ',
      payload.data.count_param,
      'filter_params: ',
      payload.data.filter_params,
      'sort_param: ',
      payload.data.sort_param,
    );
    dispatch(
      receiveJobMatchQueryParams({
        countParam: payload.data.count_param,
        queryParams: payload.data.filter_params,
        sortParam: payload.data.sort_param,
      }),
    );
  });
});
