// @flow strict-local

import type {SenseAction} from 'src/action-creators/types';
import type {KeywordSearchSuggestion} from 'src/types/chat/keyword-search';

import {
  RECEIVE,
  SELECT_THREAD,
  RESET_SEARCH,
} from 'src/action-creators/chat/keyword-search';


export type State = {
  suggestions: Map<string, string[]>,
  currentThread: ?string,
};

const initialState = (): State => ({
  suggestions: new Map<string, string[]>(),
  currentThread: null,
});

export default function reduce(
  state: State = initialState(),
  action: SenseAction,
): State {
  switch (action.type) {
    case RECEIVE: {
      const nextMap = new Map<string, string[]>();

      const {displayMessages} = action.payload;

      for (const suggestion of displayMessages) {
        const threadGroup = nextMap.get(suggestion.threadId) || [];
        threadGroup.push(suggestion.id);
        nextMap.set(suggestion.threadId, threadGroup);
      }

      return {
        ...state,
        suggestions: nextMap,
      };
    }
    case SELECT_THREAD: {
      return {
        ...state,
        currentThread: action.payload,
      };
    }
    case RESET_SEARCH: {
      return {
        ...initialState(),
      };
    }
  }

  return state;
}
