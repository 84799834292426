//@flow strict

//$FlowFixMe[nonstrict-import]
import type {KeyedThunkAction} from 'src/types/thunk-decorators';
// $FlowFixMe[nonstrict-import]
import type {ApiError} from 'src/utils/errors';
import {useSelector} from 'react-redux';
//$FlowFixMe[untyped-import]
import {inflight, hasError} from 'src/utils/redux';

import {useRef} from 'react';
import usePrevious from 'src/hooks/usePrevious';


export function useInflight(func: KeyedThunkAction<mixed>): boolean {
  return useSelector((state) => inflight(state, func));
}

export function useHasError(func: KeyedThunkAction<mixed>): ApiError {
  return useSelector((state) => hasError(state, func));
}

export function useHasNetworkRequestCompleted(
  func: KeyedThunkAction<mixed>,
): boolean {
  const inflightValue = useInflight(func);
  const previousInflightValue = usePrevious(inflightValue);
  const hasCompleted = useRef(false);
  if (previousInflightValue === true && inflightValue === false) {
    hasCompleted.current = true;
  }
  return hasCompleted.current;
}
