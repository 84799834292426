// @flow

// $FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores/index';
import type {Question} from 'src/types/survey';
// $FlowFixMe[untyped-type-import]
import type {Workflow, Event} from 'src/api-parsers';
import type {JobVariables} from 'src/types/job-variables';

import * as React from 'react';
import {useDrag, useDrop} from 'react-dnd-cjs';
import {getEmptyImage} from 'react-dnd-html5-backend-cjs';
import {useSelector, useDispatch} from 'react-redux';

import {classify, stopEvent} from 'src/utils';
import {JOB_WITH_LINK_IDENTIFIER} from 'src/utils/job-match';

import {useJobMatchEnabledForEngage} from 'src/hooks/useAgencyConfig';

import {
  editModule,
  cancelModule,
  deleteModule,
  saveModule,
  updateModule,
  errorsForQuestion,
} from 'src/actions/event-creation';
import {EventTypeEnum} from 'src/stores/event';

import {selectJobVariableMap} from 'src/selectors/job-variables';
import {moveModule, moveBranchModule} from 'src/actions/event';
import {newBranch} from 'src/actions/branch';
import {formatDynamicLabelsInString} from 'src/components/lib/markdown-editor/dynamic-text.jsx';
import useIsWorknWorkflow from 'src/hooks/useIsWorknWorkflow';

import Expander from 'src/components/lib/expander/expander2.jsx';
import AddBranch from 'src/components/workflow/event/content/branch/add.jsx';
import ArrowDown from 'src/images/arrow-down.svg';
import ArrowUp from 'src/images/arrow-up.svg';
import DragHandle from 'src/images/drag-handle.svg';
import {useContextTip} from 'src/components/lib/mouse-tip/mouse-tip.jsx';

import BranchingContainer, {
  modulesThatBranch,
} from 'src/components/workflow/event/content/branch/container.jsx';
import LinkedContentNotice, {
  LinkedSurveyNotice,
} from 'src/components/workflow/event/content/linked-content-notice.jsx';
import {
  WritebackContext,
  RECEIVE_QUESTION,
  RESET_STATE,
} from 'src/components/writeback/writeback-wrapper.jsx';
import {showGenericConfirm} from 'src/action-creators/modal';
import FieldWritebackModule from 'src/components/workflow/event/content/module/editors/field-writeback.jsx';

import moduleComponents from 'src/components/workflow/event/content/module/components';
import {selectAgencyConfigFlag} from 'src/selectors/agency';

import TrashIcon from 'src/images/delete-icon.svg';

import css from './row.css';


const supportsJobBlock = (clientEventType, questionType): boolean =>
  ![EventTypeEnum.SMS_Message, EventTypeEnum.SMS_Survey].includes(
    clientEventType,
  ) &&
  ['sms_message_survey_module', 'message_survey_module'].includes(questionType);

const RowTitle = ({
  index,
  text,
  questionRequired,
}: {
  index: number,
  text: string,
  questionRequired: boolean,
}) => (
  <>
    <span className={css.index}>{index + 1}</span>
    <span className={css.slash}>/</span>
    <span className={css.type}>
      {text}
      {questionRequired && ' *'}
    </span>
  </>
);
const JobMatchRowTitle = ({
  index,
  questionRequired,
  nonJobMatchTitleText,
  jobMatchTitleText,
  containsJobData,
}: {
  index: number,
  questionRequired: boolean,
  nonJobMatchTitleText: string,
  jobMatchTitleText: string,
  containsJobData: boolean,
}) => (
  <>
    <span className={css.index}>{index + 1}</span>
    <span className={css.slash}>/</span>
    <span className={css.type}>
      {containsJobData ? jobMatchTitleText : nonJobMatchTitleText}
      {questionRequired && ' *'}
    </span>
  </>
);
type DraggableProps = {
  isOver?: boolean,
  isDragging?: boolean,
  dragRef?: (React.ElementRef<any> | null) => mixed,
  dropRef?: {current: ?HTMLElement},
};

type Props = {
  ...DraggableProps,
  // $FlowFixMe[value-as-type] [v1.32.0]
  fluxStore: IndexStore,
  dynamicLabels: string[],
  jobDynamicLabels: JobVariables,
  internalDynamicLabels: string[],
  question: Question,
  error?: ?Object,
  disabled: boolean,
  editing?: boolean,
  index: number,
  canEdit?: boolean,
  // $FlowFixMe[value-as-type] [v1.32.0]
  workflow: Workflow,
  parentType: 'event' | 'branch',
  // $FlowFixMe[value-as-type] [v1.32.0]
  parentEvent?: Event,
  webContentStart?: boolean,
  linkedSurvey?: boolean,
  isSurveyEvent?: boolean,
  conditionalAlerts?: boolean,
  jobBlockModuleIndex: number,
  showErrors: string,
  isBranchedEvent: boolean,
};

const validationTipId = 'Validation Tooltip Id';

export const ModuleRow = (props: Props): null | React.Element<'div'> => {
  const {
    isDragging,
    isOver,
    dragRef,
    dropRef,
    workflow,
    parentEvent,
    parentType,
    fluxStore,
    index,
    editing,
    disabled,
    dynamicLabels,
    jobDynamicLabels,
    question,
    error,
    webContentStart,
    canEdit,
    linkedSurvey,
    isSurveyEvent,
    internalDynamicLabels,
    conditionalAlerts = false,
    jobBlockModuleIndex,
    showErrors,
    isBranchedEvent,
  } = props;

  const jobMatchEnabled = useJobMatchEnabledForEngage();
  const isWorknWorkflow = useIsWorknWorkflow();
  const moduleBelongsToBranchedEvent = isBranchedEvent; //this module belongs to a branch touchpoint
  const hasNoModals = useSelector((state) => state.modal.entries.length === 0);
  //NOTE:(diwakersurya) this has to be done this way because of how handleClickAway has been setup
  const isSenseAiOpen = useSelector(
    (state) => state.senseAi.showCustomComponent,
  );

  const writebackContext = React.useContext(WritebackContext);
  const atsFieldWritebackEnabled = useSelector((state) =>
    selectAgencyConfigFlag(state, 'atsFieldWritebackEnabled'),
  );
  const isWritebackModule =
    atsFieldWritebackEnabled &&
    // $FlowFixMe[prop-missing] types/surveys
    moduleComponents[question.type]?.writebackEnabled;

  const handleSaveModule = () => {
    if (isWritebackModule) {
      const currentQuestionType =
        state?.question?.selectedType || question.type;
      const originalQuestionType = state?.question?.originalType;
      const currentQuestion =
        state?.question?.[currentQuestionType] || question;
      const questionTypeUpdated =
        !currentQuestion.id.includes('new') &&
        currentQuestionType !== originalQuestionType;
      // $FlowFixMe[prop-missing] types/surveys
      const {writeback, ...otherQuestionAttrs} = currentQuestion;
      const updatedQuestionAttrs = writeback?.enabled
        ? currentQuestion
        : {...otherQuestionAttrs};

      updateModule(fluxStore, {
        ...currentQuestion,
        questionTypeUpdated,
      });
    }
    updateModule(fluxStore, {
      hasValidationError: false,
    });
    const saveResult = saveModule(fluxStore, parentType);
    if (!saveResult.error) {
      dispatch({
        type: RESET_STATE,
      });
    } else {
      updateModule(fluxStore, {
        hasValidationError: true,
      });
    }
  };

  const handleNewBranch = React.useMemo(
    () => (type) => {
      newBranch(fluxStore, question.id, type);
    },
    [fluxStore, question.id],
  );

  const tipContent = React.useMemo(() => <ValidationAlertTooltipContent />, []);

  const [componentRef, handleContextMenu] = useContextTip({
    id: validationTipId,
    fixedTo: 'top',
    content: tipContent,
  });

  // //componentDidMount, willUnmount
  // React.useEffect(() => {
  //   props.connectDragPreview(getEmptyImage());
  //   // writebackContext.dispatch({
  //   //   type: RECEIVE_QUESTION,
  //   //   payload: question,
  //   // });
  // }, []);

  /**NOTE:(diwakersurya) this handler is on window. hence we need to handle the Ai modal buttons so that
   * the ai popup does not close if isSenseAiOpen is true
   */
  const handleClickAway = (event: MouseEvent) => {
    if (isSenseAiOpen) {
      return;
    }
    if (!hasNoModals) {
      return;
    }

    const targetElement = event.target;
    if (
      targetElement instanceof Node &&
      dropRef?.current?.contains(targetElement)
    ) {
      return;
    }

    if (canEdit) {
      handleSaveModule();
    } else {
      cancelModule(fluxStore, parentType);
    }
  };
  React.useEffect(() => {
    if (editing) {
      window.document.addEventListener('click', handleClickAway, {
        capture: true,
      });

      return () => {
        window.document.removeEventListener('click', handleClickAway, {
          capture: true,
        });
      };
    }
  }, [editing, handleClickAway]);

  const {state, dispatch} = writebackContext;
  const reduxStateDispatcher = useDispatch();

  if (!moduleComponents[question.type]) {
    return null;
  }

  const {
    Icon,
    Editor,
    jobMatchTitleText = '',
    nonJobMatchTitleText = '',
  } = moduleComponents[question.type];
  let text;
  if (isWorknWorkflow && question.type === 'sms_message_survey_module') {
    text = 'App Notification';
  } else if (isWritebackModule && editing && state.question?.selectedType) {
    // $FlowFixMe[prop-missing] types/surveys
    text = moduleComponents[state.question.selectedType].text;
  } else {
    text = moduleComponents[question.type].text;
  }
  // TODO(marcos): this will omit strings for events with a blank line up top
  // so maybe worth having a utility to do something like
  // (question?.rte_json?.blocks || [])
  //    .map(({text}) => text?.trim())
  //    .filter(Boolean)[0] || ''
  // $FlowFixMe[prop-missing] types/surveys
  const rteText = question?.rte_json?.blocks?.[0]?.text;
  const questionText = formatDynamicLabelsInString(
    dynamicLabels,
    rteText || question.text || question.question || '',
  );

  const cssTypes = {
    [css.isOver]: isOver,
    [css.isDragging]: isDragging,
    [css.isInBranch]: !parentEvent,
  };

  let boxClassName;
  if (editing) {
    boxClassName = css.boxOpen;
  } else if (disabled) {
    boxClassName = css.boxDisabled;
  } else {
    boxClassName = css.boxClosed;
  }

  // NOTE (kyle): parentless events are guarenteed to be surveys
  // NOTE (marcos): beefree_emails can include survey modules or not
  const canDelete =
    parentEvent?.type === 'beefree_email' ||
    (index > 0 &&
      (!parentEvent || ['survey', 'sms_survey'].includes(parentEvent.type))) ||
    (parentEvent?.type === 'digest_email' && index > 2);

  const isNotMultiSelect =
    question.type !== 'multiple_choice_survey_question' ||
    // $FlowFixMe[prop-missing] types/surveys
    !question.allow_select_multiple ||
    (question.allow_select_multiple && question.multi_select_limit === 1);
  const containsJobData = jobBlockModuleIndex === index;

  //(diwakersurya) remove link variable in case of sms
  const filteredJobDynamicLabels =
    question.type !== 'sms_message_survey_module'
      ? jobDynamicLabels
      : jobDynamicLabels.filter(
          (label) => label.name !== JOB_WITH_LINK_IDENTIFIER,
        );
  const showJobMatchRowTitle =
    jobMatchEnabled && supportsJobBlock(parentEvent?.eventType, question.type);

  return (
    <div
      className={classify(css.row, css[question.type])}
      ref={dropRef}
      data-qa-row="module-row"
    >
      {webContentStart && !isDragging && (
        <LinkedContentNotice>
          {jobMatchEnabled
            ? `The modules below will be shown on the survey webpage`
            : `Questions after this will be shown in a separate window.`}
        </LinkedContentNotice>
      )}
      {linkedSurvey && !isDragging && <LinkedSurveyNotice />}
      <div className={classify(boxClassName, cssTypes)}>
        <div
          className={disabled ? css.headerDisabled : css.header}
          onClick={() => {
            if (disabled) {
              handleSaveModule();
            } else {
              editModule(fluxStore, question.id, parentType);
            }
          }}
        >
          <div className={css.dragHandle} ref={!disabled ? dragRef : null}>
            <DragHandle />
          </div>
          {showJobMatchRowTitle ? (
            <JobMatchRowTitle
              index={index}
              jobMatchTitleText={jobMatchTitleText}
              nonJobMatchTitleText={nonJobMatchTitleText}
              questionRequired={question.required}
              containsJobData={containsJobData}
            />
          ) : (
            <RowTitle
              index={index}
              text={text}
              questionRequired={question.required}
            />
          )}
          {!editing && (
            <div className={css.details}>
              <Icon className={css.icon} />
              <span className={css.question}>{questionText}</span>
            </div>
          )}

          {canEdit &&
            parentEvent &&
            !disabled &&
            !editing &&
            isNotMultiSelect &&
            modulesThatBranch.includes(question.type) &&
            parentType === 'event' && (
              <AddBranch
                className={css.addBranch}
                onClick={stopEvent}
                onSelectNewBranchType={handleNewBranch}
                moduleBelongsToBranchedEvent={moduleBelongsToBranchedEvent}
              />
            )}

          {!disabled && !editing && <ArrowDown />}

          {editing && (
            <>
              <div className={css.actions}>
                {canEdit && canDelete && (
                  <button
                    className={css.iconButton}
                    onClick={(browserEvent) => {
                      browserEvent.stopPropagation();
                      reduxStateDispatcher(
                        showGenericConfirm({
                          title: 'Confirm Delete',
                          text: `You are about to remove this module.
                          Are you sure?`,
                          confirmText: 'Yes',
                        }),
                      ).then((yes) => {
                        if (yes) {
                          deleteModule(fluxStore, parentType, question.id);
                          dispatch({
                            type: RESET_STATE,
                          });
                        }
                      });
                    }}
                  >
                    <TrashIcon />
                  </button>
                )}
              </div>
              <div
                ref={componentRef}
                onClick={(_ev) => {
                  const error = errorsForQuestion(
                    question,
                    fluxStore.eventCreation.state.event,
                    selectJobVariableMap(fluxStore.reduxStore.getState()),
                  );
                  if (error) {
                    handleContextMenu(_ev);
                  }
                }}
              >
                <ArrowUp />
              </div>
            </>
          )}
        </div>

        <Expander duration={200}>
          {editing && (
            <div className={css.editor}>
              {isWritebackModule ? (
                // $FlowFixMe[prop-missing] types/surveys
                <FieldWritebackModule
                  fluxStore={fluxStore}
                  error={error}
                  dynamicLabels={dynamicLabels}
                  // $FlowFixMe[prop-missing] types/surveys
                  // $FlowFixMe[incompatible-type] types/surveys
                  moduleData={question}
                  conditionalAlerts={conditionalAlerts}
                />
              ) : (
                // $FlowFixMe[prop-missing] types/surveys
                <Editor
                  fluxStore={fluxStore}
                  parentEvent={parentEvent}
                  error={error}
                  dynamicLabels={dynamicLabels}
                  internalDynamicLabels={internalDynamicLabels}
                  // $FlowFixMe[prop-missing] types/surveys
                  // $FlowFixMe[incompatible-type] types/surveys
                  moduleData={question}
                  canEdit={canEdit}
                  isSurveyEvent={isSurveyEvent}
                  conditionalAlerts={conditionalAlerts}
                  multiEntityEnabled={true}
                  index={index}
                  jobBlockModuleIndex={jobBlockModuleIndex}
                  jobDynamicLabels={filteredJobDynamicLabels}
                  showErrors={showErrors}
                />
              )}
            </div>
          )}
        </Expander>
      </div>

      {parentEvent && !isDragging && (
        <BranchingContainer
          moduleData={question}
          dynamicLabels={dynamicLabels}
          workflow={workflow}
          eventType={parentEvent.type}
          canEdit={canEdit}
          conditionalAlerts={conditionalAlerts}
          jobDynamicLabels={filteredJobDynamicLabels}
          parentEvent={parentEvent}
        />
      )}
    </div>
  );
};

const getDraggableRow = (type, handleMove) =>
  function DraggableRow(props: Props) {
    const {index, question, fluxStore} = props;

    // TODO (kyle): i'm not supposed to attach `question` here.
    const [{isDragging}, dragRef, preview] = useDrag({
      item: {type, index, question},
      begin: () => ({
        type,
        index,
        question,
        boundingClientRect: dropZoneRef.current
          ? dropZoneRef.current.getBoundingClientRect()
          : null,
      }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    React.useEffect(() => {
      preview(getEmptyImage());
    }, []);

    const dropZoneRef = React.useRef();

    const [{isOver}, connectDrop] = useDrop({
      accept: type,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
      hover: (item, monitor) => {
        if (item.index === index) {
          return;
        }

        if (!dropZoneRef.current) {
          return;
        }

        const hoverBoundingRect = dropZoneRef.current.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        if (item.index < index && hoverClientY < hoverMiddleY) {
          return;
        }

        if (item.index > index && hoverClientY > hoverMiddleY) {
          return;
        }

        handleMove(fluxStore, item.index, index);

        // TODO (kyle): find out if this is still necessary
        // NOTE (kyle): React DND author, gaearon, actually recommends mutating the
        // sourceItem for performance reasons.
        item.index = index;
      },
    });

    connectDrop(dropZoneRef);

    return (
      <ModuleRow
        {...props}
        isDragging={isDragging}
        isOver={isOver}
        dropRef={dropZoneRef}
        dragRef={dragRef}
      />
    );
  };

const ValidationAlertTooltipContent = () => (
  <span id="strange-span">
    You need to fill all the required fields in this module before closing it
  </span>
);

export function FixedModuleRow(props: Props): React.Node {
  return <ModuleRow {...props} isDragging={false} isOver={false} />;
}

export default ((process.env.EX_ENV === 'server'
  ? FixedModuleRow
  : getDraggableRow('Event Module', moveModule)): (props: Props) => React.Node);
export const BranchModuleRow: (props: Props) => React.Node =
  process.env.EX_ENV === 'server'
    ? FixedModuleRow
    : getDraggableRow('Branch Module', moveBranchModule);
