// @flow

import type {EventAttachmentFilter} from 'src/types/report';

import * as React from 'react';

import {FilterCard} from './';
import RadioButtons from 'src/components/lib/radio-buttons';

import css from './survey-chatbot-card.css';


type Props = {
  onSave?: (e: SyntheticEvent<>) => mixed,
  onCancel?: (e: SyntheticEvent<>) => mixed,
  onTypeChange: (e: SyntheticEvent<>) => mixed,
  selectedType?: string,
};

const filterTypes: Array<{label: string, value: EventAttachmentFilter}> = [
  {
    value: null,
    label: 'All metrics',
  },
  {
    value: 'survey_only',
    label: 'Surveys only',
  },
  {
    value: 'chatbot_only',
    label: 'Chatbots only',
  },
];

const SurveyAndChatbotCard = ({
  onSave,
  onCancel,
  onTypeChange,
  // $FlowFixMe[incompatible-type]
  selectedType = null,
}: Props): React.Node => (
  <FilterCard header="Surveys & chatbots" onSave={onSave} onCancel={onCancel}>
    <div className={css.filterContent}>
      <RadioButtons
        selectedValue={selectedType}
        onChange={onTypeChange}
        options={filterTypes}
        labelClassName={css.radioDisplay}
        bodyClassName={css.flexColumn}
      />
    </div>
  </FilterCard>
);

export default SurveyAndChatbotCard;
