// @flow

import type {Dispatch, ThunkAction} from 'src/reducers';

import {
  base,
  type RawQuery,
  type RequestOptions,
  type ApiPostOptions,
  type ApiOptions,
} from './redux-api';

import {ApiError, captureApiError} from 'src/utils/errors';

// TODO (kyle): every file that imports ApiError from here should import from utils.
export {ApiError, captureApiError};

// this get works specifically with our redux store. It must always be dispatched.
// So if you decide to go rogue and call it outright outside of an action-creator,
// you need to do
//
// dispatch(reduxapi.get(somepath, query)).then(returnValue => {
//   whatever, maybe another dispatch or console.log here
// })
export const get = (
  path: string,
  query?: RawQuery,
  options?: ApiOptions,
): ThunkAction<any> =>
  baseRequest(path, {
    method: 'GET',
    query,
    options,
  });

export const post = (
  path: string,
  data?: {...},
  query?: RawQuery,
  options?: ApiPostOptions,
): ThunkAction<any> =>
  baseRequest(path, {
    method: 'POST',
    data,
    query,
    options,
  });

export const patch = (
  path: string,
  data?: {...},
  query?: RawQuery,
  options?: ApiPostOptions,
): ThunkAction<any> =>
  baseRequest(path, {
    method: 'PATCH',
    data,
    query,
    options,
  });

export const put = (
  path: string,
  data?: {...},
  query?: RawQuery,
  options?: ApiPostOptions,
): ThunkAction<any> =>
  baseRequest(path, {
    method: 'PUT',
    data,
    query,
    options,
  });

export const del = (
  path: string,
  data?: {...},
  options?: ApiOptions,
): ThunkAction<any> =>
  baseRequest(path, {
    method: 'DELETE',
    data,
    options,
  });

export const baseRequest =
  (path: string, options: RequestOptions): ThunkAction<any> =>
  async (dispatch: Dispatch) => {
    const response = await dispatch(base(path, options));

    const text = await response.text();

    let json;
    if (response.headers.get('Content-Type') === 'application/json') {
      try {
        json = JSON.parse(text);
      } catch (error) {
        // NOTE (kyle): this should not happen.
        throw new ApiError({path, ...options}, response, text, error);
      }
    } else {
      json = text;
    }

    if (!response.ok) {
      throw new ApiError({path, ...options}, response, json);
    }

    return json;
  };
