// @flow strict

import * as React from 'react';


export default function nameHoc<C, T: React.ComponentType<C>, C2>(
  WrapperComponent: T,
  WrappedComponent: React.ComponentType<C2>,
  hocName: string,
): T {
  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WrapperComponent.displayName = `${hocName}(${wrappedComponentName})`;
  return WrapperComponent;
}
