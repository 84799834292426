// @flow

import type {SenseAction} from 'src/action-creators/types';
import type {ReducerCombiner} from 'src/types/redux';

import {combineReducers} from 'redux';

import cache from './cache';
import error from './error';
import request from './request';


type Reducers = {
  cache: typeof cache,
  error: typeof error,
  request: typeof request,
};

const reducer: ReducerCombiner<Reducers, SenseAction> = combineReducers<
  Reducers,
  SenseAction,
>({
  cache,
  request,
  error,
});

export default reducer;
