// @flow

import * as React from 'react';

import isAfter from 'date-fns/isAfter';

// $FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores/index';

import {setFlag} from 'src/actions';
import useFluxStore from 'src/flux/useFluxStore';
import classify from 'src/utils/classify';

import CloseIcon from 'src/images/close-icon.svg?noAttrs';

import css from './notification.css';


type Props = {
  children?: React.Node,
  onDismiss?: () => mixed,
  // $FlowFixMe[value-as-type] [v1.32.0]
  store?: IndexStore,
  className?: string,
};

export default function Notification({
  children,
  onDismiss,
  className,
}: Props): React.Node {
  return (
    <div className={classify(css.container, className)}>
      <div className={css.message}>{children}</div>
      {onDismiss && (
        <div className={css.closeContainer}>
          <button className={css.close} onClick={onDismiss}>
            <CloseIcon />
          </button>
        </div>
      )}
    </div>
  );
}

function handleCloseNotification(store, flag) {
  setFlag(store, {
    [flag]: true,
  });
}

type DismissableProps = {
  children: React.Node,
  hideAfterDate?: Date,
  flag: string,
  className?: string,
};

export function DismissableNotification({
  flag,
  hideAfterDate,
  ...props
}: DismissableProps): React.Node {
  const store = useFluxStore();
  if (store.me.state.flags[flag]) {
    return null;
  }
  if (hideAfterDate != null && isAfter(new Date(), hideAfterDate)) {
    return null;
  }

  return (
    <Notification
      {...props}
      onDismiss={() => handleCloseNotification(store, flag)}
    />
  );
}
