// @noflow

import typeof IndexStore from 'src/stores/index';
import type {
  TextQuestion,
  RatingScaleQuestion,
  MultipleChoiceQuestion,
  CheckboxesQuestion,
} from 'src/types/survey';

import * as React from 'react';

import {updateModule} from 'src/actions/event-creation';

import Toggle from 'src/components/lib/toggle/toggle.jsx';

import css from './required-toggle.css';


type Props = {
  store: IndexStore,
  moduleData: | TextQuestion
    | RatingScaleQuestion
    | MultipleChoiceQuestion
    | CheckboxesQuestion,
};

const RequiredToggle = ({store, moduleData}: Props) => (
  <div className={css.toggleContainer}>
    Required
    <Toggle
      className={css.toggle}
      checked={moduleData.required}
      value={moduleData.required}
      onChange={() => updateModule(store, {required: !moduleData.required})}
    />
  </div>
);

export default RequiredToggle;
