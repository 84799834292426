// @flow

// $FlowFixMe[untyped-type-import]
import type {Schedule} from 'src/api-parsers/index';
import type {EventTypes} from 'src/types/events';

import * as React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'src/rerouter';
import pick from 'lodash/pick';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import parseISO from 'date-fns/parseISO';

import {selectRecentlyVisitedEvents} from 'src/selectors/agency-overview';
import {VIEW_ANALYTICS} from 'src/action-creators/roles';
import {hasPrivilege} from 'src/utils/accounts';

import Table, {
  TableContainer,
  Cells,
  BasicCell,
} from 'src/components/lib/table';
import {AutoTruncatedText} from 'src/components/lib/truncated-text/truncated-text.jsx';

import Loading from 'src/components/lib/loading/loading.jsx';
import ContextMenu from 'src/components/lib/context-menu';
import HoverIconButton from 'src/components/lib/hover-icon-button';
import logger from 'src/utils/logger';

import MenuIcon from 'src/images/icons/options-icon.svg?noAttrs';
import AnalyticsMenuIcon from 'src/images/icons/analytics-menu-icon.svg';
import AudienceMenuIcon from 'src/images/icons/audience-menu-icon.svg';
import JourneyMenuIcon from 'src/images/icons/journey-menu-icon.svg';
import EditMenuIcon from 'src/images/icons/edit-menu-icon.svg';

import EmailIcon from 'src/images/icons/email-event-icon.svg?noAttrs';
import SMSIcon from 'src/images/icons/sms-event-icon.svg?noAttrs';

import css from './recent-events.css';


type EventRowData = {
  eventId: string,
  workflowId: string,
  eventName: string,
  workflowName: string,
  lastSent: ?string,
  nextSend: ?string,
  eventType: EventTypes,
  recipient: string,
  // $FlowFixMe[value-as-type] [v1.32.0]
  schedule: Schedule,
};

const eventsTableHeader = [
  {
    key: 'name',
    label: 'Touchpoint Message',
  },
  {
    key: 'lastSend',
    label: 'Last Sent',
  },
  {
    key: 'nextSend',
    label: 'Next Send',
  },
];

const schedulingTypes = {
  repeat: 'trigger on recurring date',
  field_change: 'triggered by change to a field',
  send_date: 'triggered on a single date',
  from_date: 'triggered by date of a field',
};

const EventRow = ({data}: {data: EventRowData}) => {
  const {
    eventId,
    workflowId,
    eventName,
    workflowName,
    lastSent,
    nextSend,
    eventType,
    recipient,
    schedule,
  } = data;
  const schedulingType =
    schedulingTypes &&
    schedule &&
    intersection(Object.keys(schedulingTypes), Object.keys(schedule))?.[0];

  const [menuActive, setMenuActive] = React.useState(false);
  const canViewAnalytics = useSelector((state) =>
    hasPrivilege(state, VIEW_ANALYTICS),
  );

  const isBeeFreeEvent = eventType === 'beefree_email';

  return (
    <Cells key={eventId} className={css.eventRow}>
      <BasicCell>
        <div>
          <AutoTruncatedText text={eventName || '(Event name stub)'} />
        </div>
        <div className={css.subText}>
          <AutoTruncatedText text={workflowName} />
        </div>
      </BasicCell>
      <BasicCell>
        <div>
          <AutoTruncatedText className={css.timestamp} text={lastSent} />
        </div>
        <div className={css.recipient}>
          {eventType?.includes('sms') ? (
            <SMSIcon className={css.icon} />
          ) : (
            <EmailIcon className={css.icon} />
          )}
          <AutoTruncatedText text={recipient} />
        </div>
      </BasicCell>
      <BasicCell className={css.nextSendCell}>
        <div className={css.nextSendText}>
          <AutoTruncatedText className={css.timestamp} text={nextSend} />
          <div className={css.subText}>
            <AutoTruncatedText
              text={schedulingTypes[schedulingType] || 'Not scheduled'}
            />
          </div>
        </div>
        <ContextMenu
          contextMenuClassName={css.contextMenu}
          anchor={
            <HoverIconButton active={menuActive}>
              <MenuIcon className={css.menuIcon} />
            </HoverIconButton>
          }
          anchorPosition="end"
          onOpen={() => setMenuActive(true)}
          onClose={() => setMenuActive(false)}
        >
          {canViewAnalytics && (
            <Link
              to={`/analytics/journey/${workflowId}/touchpoint/${eventId}`}
              className={css.menuLink}
            >
              <AnalyticsMenuIcon className={css.menuItemIcon} />
              Analytics
            </Link>
          )}
          <Link
            to={`/journey/${workflowId}/audience`}
            className={css.menuLink}
          >
            <AudienceMenuIcon className={css.menuItemIcon} />
            Audience
          </Link>
          <Link to={`/journey/${workflowId}`} className={css.menuLink}>
            <JourneyMenuIcon className={css.menuItemIcon} />
            Parent Journey
          </Link>
          <Link
            to={`/journey/${workflowId}/touchpoint/${eventId}${
              isBeeFreeEvent ? '/email' : ''
            }`}
            className={css.menuLink}
          >
            <EditMenuIcon className={css.menuItemIcon} />
            Edit
          </Link>
        </ContextMenu>
      </BasicCell>
    </Cells>
  );
};

const NoRecentActivities = () => (
  <div className={css.noActivitiesContainer}>
    <div className={css.noActivitiesSection1}>
      <div className={css.noActivitiesTextContainer}>
        <h2 className={css.noActivitiesTitle}>
          Find recently viewed items here!
        </h2>
        <p className={css.noActivitiesDescription}>
          {'Now when you view a touchpoint message within '}
          <Link className={css.link} to={'/analytics'}>
            analytics
          </Link>
          {' or journeys, it will appear here on your dashboard.'}
        </p>
      </div>
    </div>
    <div className={css.noActivitiesSection2} />
    <div className={css.noActivitiesSection3} />
  </div>
);

const EventsTable = ({fetching}: {fetching: boolean}): React.Node => {
  const recentlyVisitedEvents = useSelector(selectRecentlyVisitedEvents);

  const workflows = useSelector((state) => state.workflows.workflows || {});
  const workflowIds = Object.keys(recentlyVisitedEvents).map(
    (eventId) => recentlyVisitedEvents?.[eventId].workflow_id,
  );
  const workflowAttrs =
    workflows && workflowIds && pick(workflows, workflowIds);

  const eventData = React.useMemo(() => {
    const dateFormatter = new Intl.DateTimeFormat(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
    });

    const formatDate = (date) => {
      const ecmaDate = parseISO(date);
      try {
        return dateFormatter.format(ecmaDate);
      } catch (err) {
        logger.error(`failed to parse date: ${date}`);
      }
    };

    return (
      recentlyVisitedEvents &&
      Object.keys(recentlyVisitedEvents).map((eventId) => {
        const event = recentlyVisitedEvents[eventId];
        const workflow = workflowAttrs[event.workflow_id];
        let recipient;
        if (event.last_sent_num_recipients) {
          recipient =
            event.last_sent_num_recipients === 1
              ? '1 recipient'
              : `${event.last_sent_num_recipients} recipients`;
        } else {
          recipient = event.event_type.includes('sms') ? 'SMS' : 'Email';
        }

        return {
          eventId,
          workflowId: event.workflow_id,
          eventName: event.event_title,
          lastSent: event.last_sent_time
            ? formatDate(event.last_sent_time)
            : 'Never',
          nextSend: event.next_send_time
            ? formatDate(event.next_send_time)
            : 'None scheduled',
          eventType: event.event_type,
          recipient,
          schedule: event.event_schedule,
          workflowName: workflow?.name,
        };
      })
    );
  }, [Object.keys(recentlyVisitedEvents).length]);

  if (isEmpty(workflows) || fetching) {
    return <Loading />;
  }

  if (isEmpty(recentlyVisitedEvents)) {
    return <NoRecentActivities />;
  }

  return (
    <TableContainer>
      <Table
        header={eventsTableHeader}
        entries={eventData}
        Row={EventRow}
        sortable={false}
      />
    </TableContainer>
  );
};

export default EventsTable;
