// @flow

import * as React from 'react';

import ErrorPage from './error-page.jsx';

import css from './500.css';


const Error500 = (): React.Node => (
  <ErrorPage pageTitle="Error">
    <h2 className={css.textTitle}>Hold on,</h2>
    <span>
      Something went wrong
      <br />
      Error 500
    </span>
  </ErrorPage>
);

export default Error500;
