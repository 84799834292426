// @flow strict

import type {
  ReferralSetting,
  GenericError,
  PayoutRuleData,
  ReferralOneTimeSetting,
} from 'src/types/referral-v2';
// $FlowFixMe[nonstrict-import]
import type {Dispatch, GetState, ThunkAction} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api.js';
import {updateProgramDetailsFormData} from 'src/action-creators/referral-v2/programs.js';
import {DEFAULT_ERROR} from 'src/action-creators/referral-v2/referral-jobs.js';


export const REFERRAL_SETTINGS_LOADING = 'referral/referral-settings-loading';
export const REFERRAL_SETTINGS = 'referral/referral-settings';
export const REFERRAL_SETTINGS_ERROR = 'referral/referral-settings-error';
export const UPDATE_PAYOUT_RULE = 'referral/update-payout-rule';
export const UPDATE_ONE_TIME_SETTINGS = 'referral/update-one-time-settings';

type ReferralSettingsLoadingAction = {
  type: typeof REFERRAL_SETTINGS_LOADING,
};

type ReferralSettingsAction = {
  type: typeof REFERRAL_SETTINGS,
  payload: ?ReferralSetting,
};

type ReferralSettingsErrorAction = {
  type: typeof REFERRAL_SETTINGS_ERROR,
  payload: ?GenericError,
};

type UpdatePayoutRuleAction = {
  type: typeof UPDATE_PAYOUT_RULE,
  payload: ?PayoutRuleData,
};

type UpdateOneTimeSettingsAction = {
  type: typeof UPDATE_ONE_TIME_SETTINGS,
  payload: ReferralOneTimeSetting,
};

export type ReferralSettingsActions =
  | ReferralSettingsAction
  | ReferralSettingsErrorAction
  | ReferralSettingsLoadingAction
  | UpdatePayoutRuleAction
  | UpdateOneTimeSettingsAction;

const recieveReferralSetting = (
  payload: ReferralSetting,
): ReferralSettingsAction => ({
  type: REFERRAL_SETTINGS,
  payload,
});

const getReferralSettingsLoading = (): ReferralSettingsLoadingAction => ({
  type: REFERRAL_SETTINGS_LOADING,
});

const getReferralSettingsError = (
  payload: ?GenericError,
): ReferralSettingsErrorAction => ({
  type: REFERRAL_SETTINGS_ERROR,
  payload,
});

export const updatePayoutRule = (
  payload: ?PayoutRuleData,
): UpdatePayoutRuleAction => ({
  type: UPDATE_PAYOUT_RULE,
  payload,
});

export const updateOneTimeSettings = (
  payload: ReferralOneTimeSetting,
): UpdateOneTimeSettingsAction => ({
  type: UPDATE_ONE_TIME_SETTINGS,
  payload,
});

export const getReferralSettings: () => ThunkAction<void> = flow()(
  () => (dispatch: Dispatch, getState: GetState) => {
    const programDetailsFormData =
      getState().referralProgram.program_details_form_data;
    dispatch(getReferralSettingsLoading());

    return dispatch(reduxApi.get(`referral/configure/settings`)).then(
      (response: ReferralSetting) => {
        // $FlowIssue object values
        dispatch(recieveReferralSetting(response));
        if (response.bonus_and_payout_settings) {
          dispatch(
            //$FlowFixMe[prop-missing]
            updateProgramDetailsFormData({
              ...programDetailsFormData,
              bonus_and_payout_settings: response.bonus_and_payout_settings,
            }),
          );
        }
      },
      (error) => {
        dispatch(
          getReferralSettingsError(error?.response?.errors || DEFAULT_ERROR),
        );
      },
    );
  },
);
