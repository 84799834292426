// @flow strict

import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Input} from 'src/components/agency-onboard/lib/input/input.jsx';
import {Clickable} from 'src/designSystem2021Components/text-v2.jsx';
import {Button} from 'src/designSystem2021Components/buttons.jsx';
import {ManualFooter} from 'src/components/agency-onboard/footer/manual-footer.jsx';
import {InfoLabel} from 'src/components/agency-onboard/info-label/info-label.jsx';
import classify from 'src/utils/classify';
//$FlowFixMe[nonstrict-import]
import {selectAgencyData} from 'src/selectors/agency';
import {StaticTable} from 'src/designSystem2021Components/table/index.jsx';
import {
  addDomain,
  validateDomain,
  deleteDomain,
  sendDNSRecords,
} from 'src/components/agency-onboard/api/email-dns-add.js';
// $FlowFixMe[nonstrict-import]
import {showHandledApiError} from 'src/action-creators/modal';
// $FlowFixMe[nonstrict-import]
import {handleGenericApiError} from 'src/utils/errors';
import {showToastMessage} from 'src/action-creators/toasts';
import {VARIANTS} from 'src/designSystem2021Components/toast';
import {AnalyticsService} from 'src/analytics';
import {AMPLITUDE_EVENTS} from 'src/components/agency-onboard/amplitude_event.js';

import type {Task} from 'src/components/agency-onboard/types/side-panel-comp.js';

import maincss from 'src/components/agency-onboard/agency-onboard.css';
import css from 'src/components/agency-onboard/side-panels/dns-configuration.css';
import EmailIcon from 'src/images/icons/email-nofill.svg';
import DownloadIcon from 'src/images/icons/download-nofill.svg';
import InfoIcon from 'src/images/icons/info-icon-nofill.svg';
import CheckIcon from 'src/images/icons/check.svg';


type Domain = {
  dns: {
    valid: boolean,
  },
  domain: string,
};

type apiData = {
  domains: Array<Domain>,
  user_input: ?{
    done: number,
  },
  total_domains: number,
  verified_domains: number,
};

type DNSConfigProps = {
  task: Task,
  onTaskSubmit: (id: string, user_input: mixed) => mixed,
  onTaskCancel: () => mixed,
  data: apiData,
};

export const DNSConfiguration = (props: DNSConfigProps): React.Node => {
  const dispatch = useDispatch();
  const agencyData = useSelector(selectAgencyData);

  const {task, onTaskCancel, onTaskSubmit, data} = props;

  const {domains, user_input, total_domains, verified_domains} = data;
  const isDone = user_input?.done;

  const [domainData, setDomainData] = React.useState(domains || []);
  const [domain, setDomain] = React.useState<string>('');
  const [verifiedDomains, setVerifiedDomains] =
    React.useState<number>(verified_domains);
  const [totalDomains, setTotalDomains] = React.useState<number>(total_domains);
  const [verificationFailed, setVerificationFailed] = React.useState({});
  const verified = domains.map((dom) => dom['dns']);

  const handleFormSubmit = () => {
    onTaskSubmit(task['id'], {done: 1});
  };

  const handleFormCancel = () => {
    onTaskCancel();
  };

  const handleTrackingEvent = (event) => {
    AnalyticsService.track(event, {
      agencyId: agencyData.id,
      agencyName: agencyData.name,
    });
  };

  const header = [
    {
      label: 'Type',
      key: 'type',
    },
    {
      label: 'SubDomain',
      key: 'host',
    },
    {
      label: 'Data',
      key: 'data',
    },
  ];

  const handleAddDomain = async () => {
    try {
      const response = await dispatch(addDomain(domain));
      setDomainData([...domainData, response]);
      setTotalDomains(totalDomains + 1);
      if (
        Object.keys(response['dns']).every(
          (key) => response['dns'][key]['valid'],
        )
      ) {
        setVerifiedDomains(verifiedDomains + 1);
      }
    } catch (error) {
      handleGenericApiError(error);
      dispatch(
        showHandledApiError({
          title: 'Failed to Add Domain',
          text: 'An unexpected error occured when attempting to save your changes. If this problem persists, please contact support.',
          error,
        }),
      );
    }
  };

  const handleValidateDomain = async (domain, index) => {
    try {
      const response = await dispatch(validateDomain(domain));
      const curVerification = {...verificationFailed};
      const verified = Object.values(response).every(
        // $FlowFixMe[incompatible-use]
        (d): string => d.valid,
      );
      curVerification[domain] = !verified;
      setVerificationFailed(curVerification);
      if (verified) {
        setVerifiedDomains(verifiedDomains + 1);
      }
    } catch (error) {
      handleGenericApiError(error);
      dispatch(
        showHandledApiError({
          title: 'Failed to Validate Domain',
          text: 'An unexpected error occured when attempting to save your changes. If this problem persists, please contact support.',
          error,
        }),
      );
    }
  };

  const handleRemoveDomain = async (domain, index) => {
    try {
      const response = await dispatch(deleteDomain(domain));
      const newDomainData = [...domainData];
      newDomainData.splice(index, 1);
      setDomainData(newDomainData);

      const curVerification = {...verificationFailed};
      delete curVerification[domain];
      setVerificationFailed(curVerification);

      setTotalDomains(totalDomains - 1);
      if (verifiedDomains > 0) {
        setVerifiedDomains(verifiedDomains - 1);
      }
    } catch (error) {
      handleGenericApiError(error);
      dispatch(
        showHandledApiError({
          title: 'Failed to Delete Domain',
          text: 'An unexpected error occured when attempting to save your changes. If this problem persists, please contact support.',
          error,
        }),
      );
    }
  };

  const sendDNSmail = async () => {
    try {
      const response = await dispatch(sendDNSRecords());
      dispatch(
        showToastMessage(`Email Sent Successfully`, {
          variant: VARIANTS.SUCCESS,
        }),
      );
    } catch (error) {
      handleGenericApiError(error);
      dispatch(
        showHandledApiError({
          title: 'Failed to Send DNS Records Mail',
          text: 'An unexpected error occured when attempting to save your changes. If this problem persists, please contact support.',
          error,
        }),
      );
    }

    handleTrackingEvent(AMPLITUDE_EVENTS.DNS_EMAIL_BUTTON_CLICKED);
  };

  return (
    <div className={maincss.sidePanel}>
      <div className={css.infoTextDiv}>
        <Clickable className={classify(maincss.textPrimary, css.infoText)}>
          Incorrect DNS settings can result in your emails landing in spams or
          getting blacklisted. Finish this step to ensure your emails are
          delivered to your candidate’s email box.
        </Clickable>
      </div>

      <hr className={maincss.dividerLine}></hr>

      <div className={css.domainEntry}>
        <Clickable className={classify(maincss.headerText, css.headerText)}>
          <InfoLabel
            text="Save your Domain Name"
            content="To start the verification process, enter your email domain name below."
            fixedTo="bottom"
            infoBoxClass={css.infoBox}
            infoIconClass={maincss.infoIcon}
          />
        </Clickable>
        <div className={css.domainEntryBox}>
          <div className={css.domainBox}>
            <Input
              name="domain"
              value={domain || ''}
              type="text"
              label="Domain"
              boxClassName={maincss.outerBoxInputField}
              onChange={(event) => setDomain(event.target.value)}
            />
          </div>
          <div className={css.saveDomainBtnDiv}>
            <Button
              type="secondary"
              className={css.saveDomainBtn}
              onClick={handleAddDomain}
              disabled={!domain}
            >
              <Clickable>Add Domain </Clickable>
            </Button>
          </div>
        </div>
      </div>

      <hr className={maincss.dividerLine}></hr>

      {domainData.length ? (
        <div className={css.domainInfo}>
          <div className={css.domainInfoHeader}>
            <div className={css.domainHeaderText}>
              <Clickable
                className={classify(maincss.headerText, css.headerText)}
              >
                <InfoLabel
                  text="Added Domains"
                  content="After you have saved all of your email domain names, Export settings OR mail them to your technical administrator. Once the settings have been added to your records, wait 30 minutes and then verify all the setting below."
                  fixedTo="right"
                  infoBoxClass={css.infoBox}
                  infoIconClass={maincss.infoIcon}
                />
              </Clickable>
              <Clickable className={css.verifiedDomains}>
                {verifiedDomains}/{totalDomains} Verified
              </Clickable>
            </div>
            <div className={css.domainInfoIcons}>
              <div className={css.iconBox} onClick={sendDNSmail}>
                <EmailIcon className={css.domainInfoIcon} />
                <Clickable className={css.domainInfoText}>Email</Clickable>
              </div>
              <div className={css.iconBox}>
                <a
                  className={css.exportSection}
                  href={`/api/v1/dns-v2/domains/table`}
                  download={'dns-info.txt'}
                  onClick={() =>
                    handleTrackingEvent(
                      AMPLITUDE_EVENTS.DNS_EXPORT_BUTTON_CLICKED,
                    )
                  }
                >
                  <DownloadIcon className={css.domainInfoIcon} />
                  <Clickable className={css.domainInfoText}>Export</Clickable>
                </a>
              </div>
            </div>
          </div>
          <div className={css.domainSettings}>
            {domainData.map((data, index) => (
              <div className={css.addedDomain} key={index}>
                <Clickable
                  className={classify(maincss.headerText, css.headerText)}
                >
                  {data['domain']}
                </Clickable>
                {/* $FlowFixMe[incompatible-type-arg] */}
                <StaticTable
                  // $FlowFixMe[incompatible-use]
                  headers={header}
                  entries={Object.values(data['dns'])}
                  className={css.domainTable}
                ></StaticTable>
                <div className={css.verificationSection}>
                  {verificationFailed[data['domain']] && (
                    <>
                      <InfoIcon className={css.failedIcon} />
                      <Clickable className={css.verificationFailed}>
                        Verification Failed
                      </Clickable>
                    </>
                  )}
                  <div className={css.buttonRow}>
                    <Button
                      type="tertiary"
                      className={css.removeBtn}
                      onClick={() => handleRemoveDomain(data['domain'], index)}
                    >
                      <Clickable>Remove Domain</Clickable>
                    </Button>
                    {
                      verificationFailed[data['domain']] ? (
                        <Button
                          type="tertiary"
                          className={css.verifyBtn}
                          onClick={() =>
                            handleValidateDomain(data['domain'], index)
                          }
                        >
                          <Clickable>Retry Verification </Clickable>
                        </Button>
                      ) : // $FlowIssue object.values
                      Object.values(data['dns']).every((d) => d['valid']) ? (
                        <div className={css.centerAlign}>
                          <CheckIcon className={css.verifiedIcon} />
                          <Clickable className={css.verifiedText}>
                            Verified
                          </Clickable>
                        </div>
                      ) : (
                        <Button
                          type="tertiary"
                          className={css.verifyBtn}
                          onClick={() =>
                            handleValidateDomain(data['domain'], index)
                          }
                        >
                          Verify Settings
                        </Button>
                      )
                      // $FlowIssue object.values
                    }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      <ManualFooter
        isCompleted={!!isDone}
        onDone={handleFormSubmit}
        onCancel={handleFormCancel}
        disabled={!(totalDomains > 0 && verifiedDomains / totalDomains === 1)}
      />
    </div>
  );
};
