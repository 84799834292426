// @noflow
import type {EventTemplate} from 'src/api-parsers/events';
import typeof {IndexStore} from 'src/stores/index';

import * as React from 'react';
import ReactDOM from 'react-dom';
import {Helmet} from 'react-helmet';
import {provideHooks} from 'src/flux/provideHooks.jsx';
import flow from 'lodash/flow';
import debounce from 'lodash/debounce';
import {connect} from 'react-redux';

import {
  getEventTemplate,
  deleteEventTemplate,
  updateEventTemplate,
  copyEventTemplate,
} from 'src/actions/event-template';
import {showGenericError} from 'src/action-creators/modal';

import {fluxify} from 'src/flux/component.jsx';
import Loading from 'src/components/lib/loading/loading.jsx';
import Breadcrumbs from 'src/components/lib/breadcrumbs';
import Variables from './variables.jsx';
import Edit from './edit.jsx';
import DevicePreview from 'src/components/lib/device-preview';
import I18nContext from 'src/contexts/i18n-context';

import css from './container.css';
import crumbsCss from 'src/components/lib/breadcrumbs/index.css';


const mapDispatchToProps = (dispatch: Dispatch) => ({
  showErrorModal: () => {
    dispatch(
      showGenericError({
        title: 'This touchpoint template is currently in use.',
        text: 'To delete this touchpoint template, you\'ll first need to remove it from any journey touchpoints that are using it.',
      }),
    );
  },
});

const dependencies = provideHooks({
  deferred: ({store, params: {templateId}}) =>
    getEventTemplate(store, templateId),
});

const mappers = {
  template: (store, {params: {templateId}}) =>
    store.eventTemplates.get(templateId),
};

type TemplateDetailProps = {
  template: EventTemplate,
  store: IndexStore,
  showErrorModal: () => any,
  children: any,
  router: any,
  location: any,
};

class TemplateDetail extends React.Component<TemplateDetailProps> {
  editor: null | Edit | Variables;
  iframe: ?HTMLIFrameElement;
  static contextType = I18nContext;

  constructor(props) {
    super(props);

    (this: any).handleDeleteTemplate = this.handleDeleteTemplate.bind(this);
    (this: any).handleDuplicateTemplate =
      this.handleDuplicateTemplate.bind(this);
    (this: any).handleSave = this.handleSave.bind(this);
    (this: any).handleSaveDebounced = debounce(this.handleSave, 750);
  }

  render() {
    const {template} = this.props;
    if (!template) {
      return <Loading />;
    }
    const {labelI18nInstance} = this.context;
    const crumbs = [
      {
        url: '/settings/templates',
        name: labelI18nInstance.t('email_templates', 'Templates'),
      },
      {url: `/settings/templates/${template.id}`, name: template.name},
    ];

    // TODO (kyle): figure this out
    const above = true;
    const isCustom = template.templateType === 'custom';

    return (
      <div className={css.detailContainer}>
        <Helmet title={`Templates - ${template.name}`} />
        <div className={crumbsCss.titleBox}>
          <h1 className={above ? css.title : crumbsCss.titleFixed}>
            <Breadcrumbs crumbs={crumbs} />
            <div className={css.actions}>
              <button
                className={css.actionButton}
                onClick={this.handleDeleteTemplate}
              >
                Delete
              </button>
              <span className={css.actionSlash}>/</span>
              <button
                className={css.actionButton}
                onClick={this.handleDuplicateTemplate}
              >
                Duplicate
              </button>
              {isCustom && (
                <button className={css.saveButton} onClick={this.handleSave}>
                  Save
                </button>
              )}
            </div>
          </h1>
        </div>

        <div className={isCustom ? css.customBody : css.body}>
          <DevicePreview className={css.preview} initialDevice="desktop">
            {({device}) => (
              <div>
                {device === 'mobile' ? (
                  <div>
                    <iframe
                      src={`/api/v1/event-templates/${template.id}/html`}
                      className={css.mobilePreview}
                      marginHeight="0px"
                      marginWidth="0px"
                      frameBorder="0"
                      scrolling="yes"
                      ref={(el) => (this.iframe = el)}
                      sandbox="allow-same-origin"
                    />
                    <div
                      className={css.overshade}
                      onClick={(e) => e.preventDefault()}
                    />
                  </div>
                ) : (
                  <FullHeightIframe
                    templateId={template.id}
                    className={css.desktopPreview}
                    iframeRef={(el) => (this.iframe = el)}
                  />
                )}
              </div>
            )}
          </DevicePreview>

          {isCustom ? (
            <Edit
              key={template.id}
              template={template}
              fluxRef={(component) => (this.editor = component)}
            />
          ) : (
            <Variables
              template={template}
              fluxRef={(component) => (this.editor = component)}
              onChange={this.handleSaveDebounced}
            />
          )}
        </div>
      </div>
    );
  }

  handleDeleteTemplate() {
    const {
      store,
      showErrorModal,
      template: {id},
    } = this.props;
    deleteEventTemplate(store, id).then((resp) => {
      if (resp) {
        this.props.router.push('/settings/templates');
      } else {
        showErrorModal();
      }
    });
  }

  handleDuplicateTemplate() {
    const {
      store,
      template: {id},
      router,
    } = this.props;
    copyEventTemplate(store, id).then((resp) => {
      if (resp) {
        router.push(`/settings/templates/${resp.id}/`);
      }
    });
  }

  handleSave(reload: boolean = true) {
    if (!this.editor) {
      return;
    }

    const template = this.editor.getTemplate();
    const updatedTemplate = {...this.props.template, ...template};

    updateEventTemplate(this.props.store, updatedTemplate).then(() => {
      if (this.iframe && reload) {
        this.iframe.contentWindow.location.reload();
      }
    });
  }
}

type IframeProps = {
  templateId: string,
  iframeRef: () => any,
  className?: string,
  offset?: number,
};

class FullHeightIframe extends React.Component<IframeProps> {
  static defaultProps = {
    offset: 20,
  };

  state = {
    iFrameHeight: '0px',
  };

  render() {
    const {templateId, className, offset, iframeRef} = this.props;

    return (
      <iframe
        onLoad={() => {
          const obj = ReactDOM.findDOMNode(this);
          this.setState({
            iFrameHeight: obj.contentWindow.document.body.scrollHeight + offset,
          });
        }}
        ref={iframeRef}
        src={`/api/v1/event-templates/${templateId}/html`}
        scrolling="no"
        marginHeight="0px"
        marginWidth="0px"
        frameBorder="0"
        className={className}
        id="iframe"
        style={{
          maxHeight: this.state.iFrameHeight,
          height: this.state.iFrameHeight,
        }}
        sandbox="allow-same-origin"
      />
    );
  }
}

export default TemplateDetail = flow(
  fluxify(mappers),
  connect(null, mapDispatchToProps),
  dependencies,
)(TemplateDetail);
