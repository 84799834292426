// @flow

import * as React from 'react';
import findIndex from 'lodash/findIndex';
import without from 'lodash/without';
import uniqueId from 'lodash/uniqueId';

import {classify} from 'src/utils';

import type {MultipleChoiceQuestion} from 'src/types/survey.js';
import {multipleChoiceOptionMaxLength} from 'src/components/workflow/event/content/module/editors/constants.js';

import {
  WritebackContext,
  UPDATE_QUESTION,
} from 'src/components/writeback/writeback-wrapper.jsx';
import Input from 'src/components/lib/error-input';
import Toggle from 'src/components/lib/toggle/toggle.jsx';
import Checkbox from 'src/components/lib/checkbox';

import DeleteIcon from 'src/images/delete-icon.svg';
import ExpandIcon from 'src/images/expand-icon.svg';

import moduleCss from 'src/components/workflow/event/content/module/editors/module.css';


export default ({
  conditionalAlerts,
  error,
}: {
  conditionalAlerts?: boolean,
  error?: {
    question?: {
      errors: string[],
    },
    choices?: {
      [key: string]: {value: {errors: string[]}},
    },
  },
}): React.Element<'div'> => {
  const {state, dispatch} = React.useContext(WritebackContext);
  const currentQuestionType = state.question?.selectedType;
  const currentQuestion = state.question?.[currentQuestionType];

  // $FlowFixMe[incompatible-use] types/surveys
  // $FlowFixMe[prop-missing] types/surveys
  const writebackEnabled = currentQuestion.writeback?.enabled;

  // $FlowFixMe[prop-missing] types/surveys
  const writebackField = currentQuestion.writeback?.attribute_name;
  let fieldPossibleValues: Array<string> = [];
  if (
    writebackField &&
    state.attribute_meta_data?.[writebackField]?.possible_values
  ) {
    fieldPossibleValues =
      state.attribute_meta_data?.[writebackField]?.possible_values;
  }

  const allowAddingNewValues =
    writebackField &&
    state.attribute_meta_data?.[writebackField]?.allow_adding_new_values;

  // $FlowFixMe[prop-missing] types/surveys
  const choices: Array<{id: string, isNew?: boolean, value: string}> =
    // $FlowFixMe[prop-missing] types/surveys
    // $FlowFixMe[incompatible-use]
    currentQuestion?.choices.map((choice) => {
      return fieldPossibleValues?.includes(choice.value)
        ? choice
        : {...choice, isNew: true};
    });

  const getInitialCustomMultipleChoiceOptions = fieldPossibleValues
    ? choices
        .filter((choice) => !fieldPossibleValues.includes(choice.value))
        .map((choice) => ({...choice, isNew: true}))
    : [];

  const [showAllWritebackChoices, setShowAllWritebackChoices] = React.useState(
    !!getInitialCustomMultipleChoiceOptions.length,
  );

  const customMultipleChoiceOptions =
    currentQuestion?.customMultipleChoiceOptions ||
    getInitialCustomMultipleChoiceOptions;

  const updateQuestion = (attrs) => {
    dispatch({
      type: UPDATE_QUESTION,
      payload: {
        questionType: currentQuestionType,
        // $FlowFixMe[incompatible-call] types/surveys
        attrs,
      },
    });
  };

  const addCustomMultipleChoiceOptionsHandler = () => {
    updateQuestion({
      customMultipleChoiceOptions: [
        ...customMultipleChoiceOptions,
        {value: '', id: uniqueId('choice_'), isNew: true},
      ],
    });
  };

  const removeCustomMultipleChoiceOptionsHandler = (choiceId) => {
    const updatedChoices = choices.filter((choice) => choice.id !== choiceId);
    const updatedCustomMultipleChoiceOptions =
      customMultipleChoiceOptions.filter((choice) => choice.id !== choiceId);
    updateQuestion({
      choices: updatedChoices,
      customMultipleChoiceOptions: updatedCustomMultipleChoiceOptions,
    });
  };

  React.useEffect(() => {
    if (choices.length === 0) {
      updateQuestion({
        choices: [
          {value: '', id: uniqueId('choice_')},
          {value: '', id: uniqueId('choice_')},
        ],
      });
    }
  }, []);

  // $FlowFixMe[prop-missing] types/surveys
  const choicesText = currentQuestion.writeback?.choices_text ?? {};

  const choiceValues = choices.map((choice) => choice.value);

  const choiceIds = choices.map((choice) => choice.id);

  const updateNewChoiceValue = (id, newValue, oldValue) => {
    const foundIndex = customMultipleChoiceOptions.findIndex(
      (x) => x.id === id,
    );
    const updatedNewChoice = {
      ...customMultipleChoiceOptions[foundIndex],
      value: newValue,
    };
    const updatedNewChoices = [...customMultipleChoiceOptions];
    updatedNewChoices[foundIndex] = updatedNewChoice;

    const updatedChoices = [...choices];

    if (choiceValues.includes(oldValue)) {
      const foundIndex = choices.findIndex(
        (x) => x.id === id && x.isNew === true,
      );
      const updatedNewChoice = {...choices[foundIndex], value: newValue};
      updatedChoices[foundIndex] = updatedNewChoice;
    }

    const updatedChoicesText = {...choicesText};

    updateQuestion({
      choices: updatedChoices,
      writeback: {
        // $FlowFixMe[prop-missing] types/surveys
        ...currentQuestion.writeback,
        choices_text: updatedChoicesText,
      },
      customMultipleChoiceOptions: updatedNewChoices,
    });
  };

  const newToggleClickHandler = (event, isNew, value, id, index) => {
    if (event.currentTarget.checked === true) {
      if (
        !value ||
        fieldPossibleValues.includes(value) ||
        choiceValues.includes(value)
      ) {
        // show error
        return;
      }
      const {writeback = {}} = currentQuestion || {};
      const choices_text = {
        ...writeback.choices_text,
        [value]: value,
      };
      updateQuestion({
        choices: [...choices, {value, id, isNew}],
        writeback: {
          ...writeback,
          choices_text,
        },
      });
    } else {
      let updatedChoicesText = {...choicesText};
      if (value in choicesText) {
        const {[value]: _, ...restChoicesText} = choicesText;
        updatedChoicesText = {
          ...restChoicesText,
        };
        updateQuestion({
          choices: choices.filter((item) => item.id !== id),
          writeback: {
            // $FlowFixMe[prop-missing] types/surveys
            ...currentQuestion.writeback,
            choices_text: updatedChoicesText,
          },
        });
      }
    }
  };

  const sortedPossibleValues = React.useMemo(
    () => {
      const disabledChoices = fieldPossibleValues
        ? fieldPossibleValues
            .filter((choice) => !choiceValues.includes(choice))
            .map((choice) => ({
              value: choice,
              id: uniqueId('choice_'),
            }))
        : [];

      const disabledNewChoices = customMultipleChoiceOptions.filter(
        (choice) => !choiceIds.includes(choice.id),
      );

      return [...choices, ...disabledChoices, ...disabledNewChoices];
    },
    [writebackField, customMultipleChoiceOptions], //not including choices as a dependency here because we don't want to re-sort when choices changes
  );

  let multipleChoiceOptions;
  // $FlowFixMe[prop-missing] types/surveys
  // $FlowFixMe[incompatible-type] types/surveys
  if (currentQuestion.writeback?.enabled) {
    // Note: when writeback enabled - this is to handle "show 4 options" and "expand".
    multipleChoiceOptions = showAllWritebackChoices
      ? sortedPossibleValues
      : sortedPossibleValues.slice(0, 4);
  } else {
    multipleChoiceOptions = choices;
  }

  // $FlowFixMe[incompatible-type] types/surveys
  // $FlowFixMe[prop-missing] types/surveys
  const showChoices = currentQuestion.writeback?.enabled
    ? Boolean(writebackField)
    : true;

  const rowStyles = classify(moduleCss.multipleChoiceModule_choicesContainer, {
    // $FlowFixMe[incompatible-use] types/surveys
    // $FlowFixMe[prop-missing] types/surveys
    [moduleCss.withWriteback]: currentQuestion.writeback?.enabled,
    [moduleCss.withAlerts]: conditionalAlerts,
    [moduleCss.withNewWriteback]:
      // $FlowFixMe[prop-missing] types/surveys
      currentQuestion?.writeback?.enabled && customMultipleChoiceOptions.length,
  });

  return (
    <div className={moduleCss.multipleChoiceModule_container}>
      {showChoices && (
        <div className={moduleCss.multipleChoiceModule_rows}>
          <div className={rowStyles}>
            {writebackEnabled && (
              <div className={moduleCss.multipleChoiceModule_choiceColumnTitle}>
                ATS value saved
              </div>
            )}
            {writebackEnabled && (
              <div className={moduleCss.multipleChoiceModule_choiceColumnTitle}>
                Enabled
              </div>
            )}
            <div className={moduleCss.multipleChoiceModule_choiceColumnTitle}>
              Option text recipient sees
            </div>
            {conditionalAlerts && (
              <div
                className={classify(
                  moduleCss.multipleChoiceModule_choiceColumnTitle,
                  moduleCss.multipleChoiceModule_alertItem,
                )}
              >
                Alert
              </div>
            )}
          </div>

          {multipleChoiceOptions.map(({value, id, isNew = false}, index) => (
            <div key={id} className={rowStyles}>
              <Input
                type="text"
                disabled={
                  // $FlowFixMe[prop-missing] types/surveys
                  // $FlowFixMe[incompatible-type] types/surveys
                  currentQuestion.writeback?.enabled &&
                  (!isNew || choiceIds.includes(id))
                }
                className={moduleCss.multipleChoiceModule_choiceInput}
                placeholder="Please enter an option"
                value={value}
                onChange={(event) => {
                  if (isNew) {
                    updateNewChoiceValue(id, event.target.value, value);
                  } else {
                    const updatedChoices = choices.slice();
                    //non writeback choice index is always consistent
                    updatedChoices[index] = {
                      value: event.target.value,
                      id: updatedChoices[index].id,
                    };
                    updateQuestion({choices: updatedChoices});
                  }
                }}
                errors={
                  // $FlowFixMe[prop-missing] types/surveys
                  // $FlowFixMe[incompatible-type] types/surveys
                  (!currentQuestion.writeback?.enabled &&
                    error?.choices?.[id]?.value?.errors) || // $FlowFixMe[incompatible-type]
                  (isNew && fieldPossibleValues?.includes(value)
                    ? ['This value already exist']
                    : [])
                }
                data-qa-id={`option-${index}-input`}
              />

              {
                // $FlowFixMe[prop-missing] types/surveys
                // $FlowFixMe[incompatible-type] types/surveys
                currentQuestion.writeback?.enabled && (
                  <Toggle
                    checked={choiceIds.includes(id)}
                    className={moduleCss.multipleChoiceModule_choiceInput}
                    onChange={(event) => {
                      if (isNew) {
                        newToggleClickHandler(event, isNew, value, id, index);
                      } else {
                        // $FlowFixMe[prop-missing] types/surveys
                        const {writeback} = currentQuestion;
                        // $FlowFixMe[incompatible-use] types/surveys
                        const choices_text = writeback.choices_text[value]
                          ? // $FlowFixMe[incompatible-use] types/surveys
                            writeback.choices_text
                          : // $FlowFixMe[exponential-spread]
                            {
                              // $FlowFixMe[incompatible-use] types/surveys
                              ...writeback.choices_text,
                              [value]: value,
                            };
                        // if below if condition is true mean toggle was true and it has been made false.
                        if (choiceIds.includes(id)) {
                          updateQuestion({
                            choices: choices.filter((item) => item.id !== id),
                          });
                        } else {
                          updateQuestion({
                            choices: [...choices, {value, id, isNew}],
                            writeback: {
                              ...writeback,
                              choices_text,
                            },
                          });
                        }
                      }
                    }}
                    qaId={`${value}-option`}
                  />
                )
              }

              {
                // $FlowFixMe[prop-missing] types/surveys
                // $FlowFixMe[incompatible-type] types/surveys
                currentQuestion.writeback?.enabled && (
                  <Input
                    type="text"
                    disabled={!choiceIds.includes(id)}
                    className={moduleCss.multipleChoiceModule_choiceInput}
                    placeholder="Please enter option text"
                    value={choicesText[value] || ''}
                    onChange={(event) => {
                      // $FlowFixMe[exponential-spread]
                      const updatedChoicesText = {
                        ...choicesText,
                        [value]: event.target.value,
                      };
                      updateQuestion({
                        writeback: {
                          // $FlowFixMe[prop-missing] types/surveys
                          ...currentQuestion.writeback,
                          choices_text: updatedChoicesText,
                        },
                      });
                    }}
                    maxLength={multipleChoiceOptionMaxLength}
                    errors={error?.choices?.[id]?.value?.errors}
                  />
                )
              }

              {conditionalAlerts && (
                <label
                  className={classify(
                    moduleCss.multipleChoiceModule_choiceInput,
                    moduleCss.multipleChoiceModule_alertItem,
                  )}
                >
                  <Checkbox
                    // $FlowFixMe[prop-missing] types/surveys
                    // $FlowFixMe[incompatible-use]
                    checked={currentQuestion.alertChoices.includes(id)}
                    disabled={!choiceValues.includes(value)}
                    onChange={(event) => {
                      let alertChoices;
                      // $FlowFixMe optional chaining
                      if (currentQuestion.alertChoices?.includes(id)) {
                        alertChoices = without(
                          // $FlowFixMe[prop-missing] types/surveys
                          currentQuestion.alertChoices,
                          id,
                        );
                      } else {
                        // $FlowFixMe[prop-missing] types/surveys
                        // $FlowFixMe[incompatible-type]
                        alertChoices = [...currentQuestion.alertChoices, id];
                      }
                      updateQuestion({alertChoices});
                    }}
                  />
                </label>
              )}

              {
                // $FlowFixMe[prop-missing] types/surveys
                // $FlowFixMe[incompatible-type] types/surveys
                choices.length > 1 &&
                  // $FlowFixMe[prop-missing] types/surveys
                  (!currentQuestion?.writeback?.enabled ||
                    (currentQuestion.writeback?.enabled && isNew)) && (
                    <button
                      className={classify(
                        moduleCss.multipleChoiceModule_choiceInput,
                        moduleCss.multipleChoiceModule_deleteIcon,
                      )}
                      onClick={() => {
                        const updatedChoices = choices.slice(); // coping the aaray
                        updatedChoices.splice(index, 1);
                        updateQuestion({choices: updatedChoices});
                        isNew && removeCustomMultipleChoiceOptionsHandler(id);
                      }}
                    >
                      <DeleteIcon />
                    </button>
                  )
              }
            </div>
          ))}
        </div>
      )}
      {
        // $FlowFixMe[prop-missing] types/surveys
        // $FlowFixMe[incompatible-type] types/surveys
        !currentQuestion.writeback?.enabled && (
          <div
            className={moduleCss.multipleChoiceModule_addRow}
            onClick={() => {
              // setShowDefaultRow(false);
              if (!choices.length) {
                updateQuestion({
                  choices: [
                    {value: '', id: uniqueId('choice_')},
                    {value: '', id: uniqueId('choice_')},
                  ],
                });
              } else {
                updateQuestion({
                  choices: [...choices, {value: '', id: uniqueId('choice_')}],
                });
              }
            }}
          >
            Add another option
          </div>
        )
      }
      {
        // $FlowFixMe[prop-missing] types/surveys
        // $FlowFixMe[incompatible-type] types/surveys
        currentQuestion.writeback?.enabled &&
          allowAddingNewValues &&
          (showAllWritebackChoices || fieldPossibleValues.length <= 4) && (
            <div
              className={moduleCss.multipleChoiceModule_addRow}
              onClick={addCustomMultipleChoiceOptionsHandler}
            >
              Add another option
            </div>
          )
      }

      {
        // $FlowFixMe[prop-missing] types/surveys
        // $FlowFixMe[incompatible-type] types/surveys
        currentQuestion.writeback?.enabled &&
          writebackField &&
          fieldPossibleValues && (
            <div className={moduleCss.multipleChoiceModule_choiceToggles}>
              <div>
                <a
                  className={
                    moduleCss.multipleChoiceModule_choiceEnableAllToggle
                  }
                  onClick={() => {
                    if (choices.length !== fieldPossibleValues.length) {
                      setShowAllWritebackChoices(true);
                      updateQuestion({
                        choices: sortedPossibleValues,
                        writeback: {
                          // $FlowFixMe[prop-missing] types/surveys
                          ...currentQuestion.writeback,
                          choices_text: sortedPossibleValues.reduce(
                            (result, {value}) => {
                              result[value] = // $FlowFixMe[incompatible-use] types/surveys
                                // $FlowFixMe[prop-missing] types/surveys
                                currentQuestion.writeback.choices_text[value]
                                  ? // $FlowFixMe[prop-missing] types/surveys
                                    // $FlowFixMe[incompatible-use] types/surveys
                                    currentQuestion.writeback.choices_text[
                                      value
                                    ]
                                  : value;
                              return result;
                            },
                            {},
                          ),
                        },
                      });
                    }
                  }}
                  data-qa-id="enable-all-multiple-choice"
                >
                  enable all
                </a>
                <div
                  className={
                    moduleCss.multipleChoiceModule_choiceEnableAllToggleDivider
                  }
                >
                  /
                </div>
                <a
                  className={
                    moduleCss.multipleChoiceModule_choiceEnableAllToggle
                  }
                  onClick={() => {
                    setShowAllWritebackChoices(true);
                    updateQuestion({choices: []});
                  }}
                >
                  disable all
                </a>
              </div>

              {fieldPossibleValues.length > 4 && (
                <div
                  className={
                    moduleCss.multipleChoiceModule_choiceEnableAllToggle
                  }
                  onClick={() =>
                    setShowAllWritebackChoices(!showAllWritebackChoices)
                  }
                >
                  {!showAllWritebackChoices && (
                    <span
                      className={moduleCss.multipleChoiceModule_hiddenText}
                    >{`${fieldPossibleValues.length - 4} ${
                      fieldPossibleValues.length === 5 ? 'option' : 'options'
                    } hidden`}</span>
                  )}
                  <span>{showAllWritebackChoices ? 'Collapse' : 'Expand'}</span>
                  <ExpandIcon
                    className={moduleCss.multipleChoiceModule_expandIcon}
                  />
                </div>
              )}
            </div>
          )
      }

      <div className={moduleCss.multipleChoiceModule_hintText}>
        {conditionalAlerts
          ? 'The recipient will only see the enabled options above. Any alert emails will be sent if the recipient selects an option checked for alert above.'
          : 'The recipient will only see the enabled options above.'}
      </div>
    </div>
  );
};
