// @flow strict
import type {ReferralFilters, GenericError} from 'src/types/referral-v2';
import type {ReferralFiltersActions} from 'src/action-creators/referral-v2/referral-filter.js';
import {
  REFERRAL_FILTERS_LOADING,
  REFERRAL_FILTERS,
  REFERRAL_FILTERS_ERROR,
} from 'src/action-creators/referral-v2/referral-filter.js';


export const ReferralCurrentState = {
  IDLE: 'idle',
  FETCHING_REFERRAL_FILTERS: 'fetchingReferralFilters',
};

export type ReferralFiltersState = {
  current_state: string,
  referral_tracking_filters: {
    active: ?ReferralFilters,
    inactive: ?ReferralFilters,
  },
  referral_payout_filters: {
    active: ?ReferralFilters,
    inactive: ?ReferralFilters,
  },
  referral_filters_error: ?GenericError,
};

const referralFiltersInitialState = {
  current_state: '',
  referral_tracking_filters: {
    active: null,
    inactive: null,
  },
  referral_payout_filters: {
    active: null,
    inactive: null,
  },
  referral_filters_error: null,
};

export default (
  state: ReferralFiltersState = referralFiltersInitialState,
  action: ReferralFiltersActions,
): ReferralFiltersState => {
  switch (action.type) {
    case REFERRAL_FILTERS: {
      const filterType: string = action.archived ? 'inactive' : 'active';
      if (action.from === 'tracking') {
        return {
          ...state,
          referral_tracking_filters: {
            ...state.referral_tracking_filters,
            [filterType]: action.payload,
          },
          current_state: ReferralCurrentState.IDLE,
        };
      }
      return {
        ...state,
        referral_payout_filters: {
          ...state.referral_tracking_filters,
          [filterType]: action.payload,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    }
    case REFERRAL_FILTERS_LOADING:
      return {
        ...state,
        current_state: ReferralCurrentState.FETCHING_REFERRAL_FILTERS,
      };

    case REFERRAL_FILTERS_ERROR:
      return {
        ...state,
        referral_filters_error: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };
    default:
      return state;
  }
};
