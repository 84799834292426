// @flow

import type {
  RoiDetail,
  RoiMetric,
  RoiSubMetric,
  RoiSummary,
  RoiTimeUnit,
  RoiPlacementActivity,
  RoiRedeploymentGrid,
  ApiNewRoiRedeploymentGrid,
} from 'src/types/roi';
import type {GetState, Dispatch, ThunkAction} from 'src/reducers';
import type {BackgroundTask} from 'src/action-creators/background-task';
import type {RawQuery} from 'src/types/router';

import {thunkify as flow} from 'src/utils/thunks';
import {
  startBackgroundTask,
  startBackgroundTaskPolling,
  getBackgroundTaskStatus,
} from 'src/action-creators/background-task.js';

import {camel} from 'src/utils';
import {resolveRoiDateRange, resolveTimeFrame} from 'src/utils/date-range';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api';
import {isInProgress} from 'src/utils/background-task';
import {isRoiFlagEnabled} from 'src/utils/roiFlagEnabled';
import {checkRoiV3ApiEnabled} from 'src/utils/analytics-api-migration';


const {stringify} = JSON;

export const RECEIVE_SUMMARY = 'roi/receiveSummary';
export const RECEIVE_DETAIL = 'roi/receiveDetail';
export const CHANGE_TIME_UNIT = 'roi/changeTimeUnit';

export type RoiSummaryAction = {
  type: 'roi/receiveSummary',
  payload: RoiSummary | RoiPlacementActivity | RoiRedeploymentGrid,
  meta: {metric: RoiMetric},
};

export type RoiDetailAction = {
  type: 'roi/receiveDetail',
  payload: RoiDetail,
  meta: {summaryId: string},
};

export type RoiChangeTimeUnitAction = {
  type: 'roi/changeTimeUnit',
  payload: RoiTimeUnit,
  meta: {metric: RoiMetric},
};

export type RoiAction =
  | RoiSummaryAction
  | RoiDetailAction
  | RoiChangeTimeUnitAction;

export const receiveSummary = (
  payload: RoiSummary | RoiPlacementActivity | RoiRedeploymentGrid,
  metric: RoiMetric,
): RoiSummaryAction => ({
  type: RECEIVE_SUMMARY,
  payload,
  meta: {metric},
});

export const newRoiParser = (
  payload: ApiNewRoiRedeploymentGrid,
): RoiRedeploymentGrid =>
  // $FlowOptionalCall
  payload?.map((newSummary) => ({
    redeployments: newSummary.redeployment_start_members.reduce((acc, curr) => {
      acc[curr.redeployment_start_month] = Array(curr.value);
      return acc;
    }, {}),
    redeploymentRate: newSummary.redeployment_rate,
    numEnds: newSummary.placement_end_members.value,
    month: newSummary.placement_end_month,
  })) ?? [];

export const receiveRedeploymentGridSummary = (
  payload: ApiNewRoiRedeploymentGrid,
): RoiSummaryAction => ({
  type: RECEIVE_SUMMARY,
  payload: newRoiParser(payload),
  meta: {metric: roiMetrics.REDEPLOYMENT_GRID},
});

export const receiveDetail = (
  payload: RoiDetail,
  summaryId: string,
): RoiDetailAction => ({
  type: RECEIVE_DETAIL,
  payload,
  meta: {summaryId},
});

export const changeTimeUnit = (
  payload: RoiTimeUnit,
  metric: RoiMetric,
): RoiChangeTimeUnitAction => ({
  type: CHANGE_TIME_UNIT,
  payload,
  meta: {metric},
});

const parse = (json) => camel(json, true, ['redeployments']);

const querying =
  (allowed = ['time_unit']) =>
  (f) =>
  (...args) => {
    const lastIndex = args.length - 1;
    args[lastIndex] = resolveRoiDateRange(args[lastIndex], allowed, {years: 1});
    return f(...args);
  };

export const getRoiSummary: (
  metric: RoiMetric,
  query: RawQuery,
) => ThunkAction<mixed> = flow(
  querying(),
  key((metric) => `roi/summary:${metric}`),
  cached((json, metric) => receiveSummary(parse(json), metric), {
    hash: (metric: RoiMetric, query: RawQuery) =>
      `roi/summary:${metric}:${stringify(query)}`,
  }),
  fetching(),
)((metric: RoiMetric, query: RawQuery) =>
  reduxApi.get(
    `analytics/roi/${metric}${!checkRoiV3ApiEnabled() ? '/summary' : ''}`,
    query,
    {
      apiPath: checkRoiV3ApiEnabled()
        ? '/api/v3/'
        : isRoiFlagEnabled()
        ? '/api/v2/'
        : '/api/v1/',
    },
  ),
);

export const getRoiDetail: (
  subMetric: RoiSubMetric,
  summaryId: string,
  query: RawQuery,
) => ThunkAction<mixed> = flow(
  querying(),
  key((subMetric, summaryId, _query) => `roi/detail:${subMetric}:${summaryId}`),
  cached(
    (json, _subMetric: RoiSubMetric, summaryId: string) =>
      receiveDetail(json, summaryId),
    {
      hash: (subMetric: RoiSubMetric, summaryId: string, query: RawQuery) =>
        `roi/detail:${subMetric}:${summaryId}:${stringify(query)}`,
    },
  ),
  fetching(),
)((subMetric: RoiSubMetric, summaryId: string, query: RawQuery) =>
  reduxApi.get(`analytics/roi/count${subMetric}/detail`, query, {
    apiPath: checkRoiV3ApiEnabled()
      ? '/api/v3/'
      : isRoiFlagEnabled()
      ? '/api/v2/'
      : '/api/v1/',
  }),
);

export const getRoiStatus =
  (): ThunkAction<void> =>
  async (dispatch: Dispatch): Promise<void> => {
    const {payload: task} = await dispatch(
      getBackgroundTaskStatus(
        'roi-data-generation',
        'analytics/roi/data-generation/status',

        checkRoiV3ApiEnabled()
          ? '/api/v3/'
          : isRoiFlagEnabled()
          ? '/api/v2/'
          : '/api/v1/',
      ),
    );
    if (task && isInProgress(task)) {
      return dispatch(startBackgroundTaskPolling(task.key));
    }
  };

export const startRoiDataGeneration =
  (): ThunkAction<void> =>
  async (dispatch: Dispatch): Promise<void> => {
    const {payload: task} = await dispatch(
      startBackgroundTask(
        `roi-data-generation`,
        () =>
          dispatch(
            reduxApi.post(
              `analytics/roi/data-generation/new`,
              {},
              {},
              {
                apiPath: checkRoiV3ApiEnabled() ? '/api/v3/' : '/api/v1/',
              },
            ),
          ),
        `analytics/roi/data-generation/status`,
      ),
    );

    return dispatch(startBackgroundTaskPolling(task.key));
  };

export const handleTimeUnitChange: (
  timeUnit: RoiTimeUnit,
  metric: RoiMetric,
  query: RawQuery,
) => ThunkAction<mixed> = flow(
  key(
    (timeUnit, metric, query) =>
      `handleTimeUnitChange:${metric}:${timeUnit}:${stringify(query)}`,
  ),
  fetching(),
)(
  (timeUnit: RoiTimeUnit, metric: RoiMetric, query: RawQuery) =>
    (dispatch: Dispatch) => {
      dispatch(changeTimeUnit(timeUnit, metric));
      return dispatch(getRoiSummary(metric, {...query, time_unit: timeUnit}));
    },
);

export const handleTimeUnitChangeV2: (
  timeUnit: RoiTimeUnit,
  metric: RoiMetric,
  query: RawQuery,
) => ThunkAction<mixed> = flow(
  key(
    (timeUnit, metric, query) =>
      `handleTimeUnitChange:${metric}:${timeUnit}:${stringify(query)}`,
  ),
  fetching(),
)(
  (timeUnit: RoiTimeUnit, metric: RoiMetric, query: RawQuery) =>
    (dispatch: Dispatch) => {
      dispatch(changeTimeUnit(timeUnit, metric));
      return dispatch(getRoiSummary(metric, {...query, time_unit: timeUnit}));
    },
);

export const roiMetrics: {[key: string]: RoiMetric, ...} = {
  PLACEMENTS_STARTS_AND_ENDS: 'placement_starts_and_ends',
  TOUCHES: 'touches',
  PLACEMENT_DURATIONS: 'placement_durations',
  NEW_ACTIVATIONS: 'new_activations',
  REDEPLOYMENTS: 'redeployments',
  PLACEMENT_ACTIVITY: 'placement_activity',
  REDEPLOYMENT_GRID: 'redeployment_grid',
  BILLABLE_CONTRACTORS: 'billable_contractors',
  PEOPLE_ON_ASSIGNMENT: 'active_placements',
  ASSIGNMENT_LENGTH: 'assignment_length',
};
