// @flow strict-local

import type {InputProps, RefProp} from 'src/types/react';

import * as React from 'react';

import {classify} from 'src/utils/classify';

import ErrorList from 'src/components/lib/error-list';

import css from './error-input.css';


type Props = {
  ...InputProps,
  className?: ?string,
  errors?: string[] | void,
  hideErrorText?: boolean,
  inputRef?: RefProp<HTMLInputElement>,
  disabled?: mixed,
};

const ErrorInput = ({
  className,
  errors = [],
  hideErrorText,
  inputRef,
  ...props
}: Props): React.Element<'div'> => (
  <div className={classify(css.container, className)}>
    <input
      className={errors.length ? css.errorInput : css.input}
      ref={inputRef}
      {...props}
    />
    {errors.length > 0 && !hideErrorText && (
      <ErrorList className={css.errors} errors={errors} />
    )}
  </div>
);

export default ErrorInput;
