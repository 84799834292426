// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {EntityAttributes} from 'src/types/entity-attributes';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching, cached} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';


export const RECEIVE_ATTRIBUTES = 'entity-attributes/receiveAttributes';

type ReceiveEntityAttributesAction = {
  type: 'entity-attributes/receiveAttributes',
  payload: EntityAttributes,
};

export type AttributesAction = ReceiveEntityAttributesAction;

const receiveAttributes = (
  attributes: EntityAttributes,
): ReceiveEntityAttributesAction => ({
  type: RECEIVE_ATTRIBUTES,
  payload: attributes,
});

export const getAllEntityAttributes: () => ThunkAction<mixed> = flow(
  key(() => 'entity-attributes'),
  cached((attributes) => receiveAttributes(attributes), {
    ttl: 86_400_000,
  }),
  fetching(),
)(() => (dispatch: Dispatch) => dispatch(reduxApi.get('entity_attribute')));
