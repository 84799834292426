// @flow strict-local

import type {State} from 'src/reducers';
import type {PhoneNumbersState} from 'src/reducers/phone-numbers.js';

import {createSelector} from 'reselect';


export type PhoneNumberAssignmentType = {
  provisioned_phone_id: number,
  phone_number: string,
  agents?: Array<string>,
  invites?: Array<string>,
  callForwardingNumber: ?string,
  friendlyName: ?string,
};

export type PhoneNumberAssignmentsMapType = {
  [provisioned_phone_id: number]: PhoneNumberAssignmentType,
};

export const getPhoneNumbers = (state: State): PhoneNumbersState =>
  state.phoneNumbers;

export const getPhoneNumberAssignments: (
  state: State,
) => PhoneNumberAssignmentsMapType = createSelector(
  getPhoneNumbers,
  (phoneNumbers) => {
    const {
      agent_assignments,
      invite_assignments,
      phone_numbers,
      call_forwarding_numbers,
      friendly_names,
    } = phoneNumbers;
    if (!phone_numbers) {
      return Object.freeze({});
    }

    const agentsByPhoneId = {};

    Object.entries(agent_assignments).forEach(([agentId, phoneIds]) => {
      //$FlowFixMe phoneIds is typed number but being mixed in Object.entries
      phoneIds.forEach((phoneId) => {
        if (agentsByPhoneId[phoneId]) {
          agentsByPhoneId[phoneId].push(agentId);
        } else {
          agentsByPhoneId[phoneId] = [agentId];
        }
      });
    });

    const invitesByPhoneId = {};

    Object.entries(invite_assignments).forEach(([inviteId, phoneIds]) => {
      //$FlowFixMe phoneIds is typed number but being mixed in Object.entries
      phoneIds.forEach((phoneId) => {
        if (invitesByPhoneId[phoneId]) {
          invitesByPhoneId[phoneId].push(inviteId);
        } else {
          invitesByPhoneId[phoneId] = [inviteId];
        }
      });
    });

    const phoneNumberAssignments = {};

    Object.entries(phone_numbers).forEach(
      ([provisioned_phone_id, phone_number]) => {
        phoneNumberAssignments[provisioned_phone_id] = {
          provisioned_phone_id,
          phone_number,
          agents: agentsByPhoneId[provisioned_phone_id],
          invites: invitesByPhoneId[provisioned_phone_id],
          friendlyName: friendly_names[provisioned_phone_id],
          callForwardingNumber: call_forwarding_numbers[provisioned_phone_id],
        };
      },
    );

    return {...phoneNumberAssignments};
  },
);
