// @noflow

import typeof IndexStore from 'src/stores/index';
import type {RatingScaleQuestion, NPSSurveyQuestion} from 'src/types/survey';
import type {DynamicLabels} from 'src/action-creators/dynamic-labels';

import * as React from 'react';
import flow from 'lodash/flow';
import _get from 'lodash/get';
import range from 'lodash/range';

import mapProps from 'src/flux/map-props.jsx';
import {updateModule} from 'src/actions/event-creation';

import Input from 'src/components/lib/error-input';
import RequiredToggle from 'src/components/workflow/event/content/module/required-toggle.jsx';
import Select from 'src/components/lib/select/select.jsx';
import DraftInput from 'src/components/lib/draft-input';

import moduleCss from './module.css';


const mapper = (props) => ({
  ...props,
  questionErrors: _get(props.error, 'question.errors'),
  minErrors: _get(props.error, 'minText.errors'),
  maxErrors: _get(props.error, 'maxText.errors'),
});

type Props = {|
  fluxStore: IndexStore,
  moduleData: RatingScaleQuestion | NPSSurveyQuestion,
  error: ?Object,
  questionErrors?: string[],
  minErrors?: string[],
  maxErrors?: string[],
  conditionalAlerts?: boolean,
  dynamicLabels: DynamicLabels,
  multiEntityEnabled: boolean,
|};

//inlineModuleSelector, onQuestionTypeChange are only used in branch mode.
const RatingScaleModule = ({
  fluxStore,
  moduleData,
  questionErrors, // TODO(marcos): reintroduce these errors
  minErrors,
  maxErrors,
  conditionalAlerts,
  dynamicLabels,
  multiEntityEnabled,
}: Props) => (
  <div className={moduleCss.generalModule_content}>
    <div className={moduleCss.moduleInputRow}>
      <label className={moduleCss.generalModule_questionLabel}>
        <div className={moduleCss.labelWithInlineSelector}>Text</div>
        <DraftInput
          value={moduleData.question}
          placeholder="How was your day?"
          dynamicLabels={dynamicLabels}
          onChange={(value) => updateModule(fluxStore, {question: value})}
          showAddButton={multiEntityEnabled ? true : false}
          multiEntityEnabled={multiEntityEnabled}
        />
      </label>
    </div>

    <div className={moduleCss.ratingScaleModule_bottomContent}>
      <label className={moduleCss.generalModule_questionLabel}>
        <div
          className={moduleCss.labelWithInlineSelector}
        >{`Left Label (${moduleData.minRating})`}</div>
        <Input
          className={moduleCss.generalModule_input}
          type="text"
          placeholder="e.g. Not Good"
          value={moduleData.minText}
          onChange={(event) =>
            updateModule(fluxStore, {minText: event.target.value})
          }
          errors={minErrors}
          hideErrorText={true}
        />
      </label>
      <div className={moduleCss.ratingScaleModule_separator} />
      <label className={moduleCss.generalModule_questionLabel}>
        <div
          className={moduleCss.labelWithInlineSelector}
        >{`Right Label (${moduleData.maxRating})`}</div>
        <Input
          className={moduleCss.generalModule_input}
          type="text"
          placeholder="e.g. Great"
          value={moduleData.maxText}
          hideErrorText={true}
          onChange={(event) =>
            updateModule(fluxStore, {maxText: event.target.value})
          }
          errors={maxErrors}
        />
      </label>
    </div>
    <div className={moduleCss.generalModule_row}>
      {conditionalAlerts && (
        <div className={moduleCss.ratingScaleModule_alertRating}>
          <div className={moduleCss.ratingScaleModule_alertRatingTitle}>
            Send alert if answer is:{' '}
          </div>
          <div
            className={moduleCss.ratingScaleModule_alertRatingSelect_container}
          >
            <Select
              className={moduleCss.ratingScaleModule_alertRatingSelect}
              value={moduleData.alertRating}
              options={range(
                moduleData.minRating,
                moduleData.maxRating + 1,
              ).map((value) => ({
                label: `<= ${value}`,
                value,
              }))}
              onChange={(evt: {value: string}) =>
                updateModule(fluxStore, {alertRating: evt.value})
              }
              clearable={false}
              searchable={false}
            />
          </div>
        </div>
      )}
      <RequiredToggle store={fluxStore} moduleData={moduleData} />
    </div>
  </div>
);

export default flow(mapProps(mapper))(RatingScaleModule);
