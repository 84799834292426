// @flow

export const surveyContentString = 'content';
export const surveyContentToken = '{{content}}';
/** (diwakersurya)we need to add extra div with class in case of beefree because surveys
 * add some css on server for centering the content and handle responsiveness. So if we don't
 * do this, the existing css will affect job match blocks as well as we are using the
 * infrastructure(placeholder/html-replacement/html creation on server) created by
 * surveys for job match blocks. for css file, refer
 * app_server/templates/branded_emails/common_content.css
 * use this file to reset any styling which leaks to sense-job-match-block class.
 */
export const jobMatchContentToken = `<div class='sense-job-match-block'>{{content}}</div>`;

export const surveyLinkString = 'survey_link';
export const surveyLinkEmailToken = '{{survey_link}}';
export const surveyLinkSmsToken = '{survey_link}';

export const chatLinkString = 'chat_link';
export const chatLinkToken = '{{chat_link}}';
