// @flow
import type {DynamicLabelExtra} from 'src/action-creators/dynamic-labels';
import type {QuestionType} from 'src/types/survey';
import type {
  ModuleAttributes,
  FieldChangeRule,
  AttributeMetaData,
} from 'src/types/writeback';
import type {
  AtsEntity,
  EntityType,
  EntityMappingsByName,
} from 'src/types/ats-entities';
import {getFWBKey} from 'src/components/conversation-builder/utils';
import {useNewAllowedAttributes} from 'src/hooks/product-flags';

import isEmpty from 'lodash/isEmpty';
import reverse from 'lodash/reverse';

import {resolveRelatedAttributeEntityType} from 'src/selectors/ats-entities';


export const getQuestionTypeByAttributeName = (
  moduleAttributes: ModuleAttributes,
  attributeName: string,
  // $FlowFixMe[value-as-type] [v1.32.0]
): QuestionType =>
  // $FlowFixMe[incompatible-return] types/surveys
  Object.keys(moduleAttributes).find(
    (questionType) => moduleAttributes[questionType] === attributeName,
  );

export const getRelatedEntityType = (entity: {
  attributeName: string,
  mapping: {
    related_entities: {
      join_attribute: string,
      entity_type: EntityType,
    }[],
  },
}): void | EntityType =>
  entity?.mapping.related_entities.find(
    (relatedEntity) => relatedEntity.join_attribute === entity.attributeName,
  )?.entity_type;

export const getRelatedEntityAtsEntityTypesByJoinAttribute = (
  atsEntitiesMeta: AtsEntity[],
): {[string]: EntityType} => {
  const result = {};
  atsEntitiesMeta.forEach(({related_entities}) => {
    related_entities.forEach(({join_attribute, entity_type}) => {
      result[join_attribute] = entity_type;
    });
  });

  return result;
};

export const getRelatedEntityIdsByEntityType = (
  rules: FieldChangeRule[],
): {
  [EntityType]: string[],
} => {
  const result = {};
  rules.forEach(
    ({related_entity_id, related_entity_type, rule_type, change_type}) => {
      if (
        rule_type === 'related_entity' &&
        change_type === 'fixed_value' &&
        related_entity_type &&
        related_entity_id
      ) {
        if (result[related_entity_type]) {
          result[related_entity_type].push(related_entity_id);
        } else {
          result[related_entity_type] = [related_entity_id];
        }
      }
    },
  );

  return result;
};

type RuleValidation = {
  attribute_name?: string,
  base_attribute_name?: string,
  change_type?: string,
  modification?: string,
  new_value?: string,
  unit?: string,
};
export const validateRules = (
  rules: FieldChangeRule[],
): null | Array<RuleValidation> => {
  const errors: RuleValidation[] = rules.map(
    ({
      attribute_name,
      change_type,
      new_value,
      base_attribute_name,
      modification,
      modify_base,
      data_type,
    }) => {
      const ruleErrors = {};
      if (!attribute_name) {
        ruleErrors.attribute_name = 'Missing field to change';
      }
      if (!change_type) {
        ruleErrors.change_type = 'Missing change type';
      }
      if (
        change_type === 'fixed_value' &&
        (new_value === null || new_value === undefined)
      ) {
        ruleErrors.new_value = 'Missing new value';
      }
      if (change_type === 'from_attribute') {
        if (!base_attribute_name) {
          ruleErrors.base_attribute_name = 'Missing base attribute name';
        }
        if (modify_base) {
          if (!modification?.value) {
            ruleErrors.modification = 'Missing modification value';
          }
          if (data_type === 'date' && !modification?.unit) {
            ruleErrors.unit = 'Missing modification unit';
          }
        }
      }

      return {...ruleErrors};
    },
  );

  const hasErrors = errors.find((rule) => !isEmpty(rule));

  return hasErrors ? errors : null;
};

export const getRunHistoryAuditUrl = (
  workflowId: string,
  startDate: string,
  stoppedDate: ?string,
): string =>
  '/audit?' +
  new URLSearchParams({
    workflow_id: workflowId,
    time_ago: 'range',
    start_date: startDate,
    end_date: stoppedDate ? stoppedDate : new Date().toISOString(),
  }).toString();

export const formatUnsyncFieldForVariablePicker = (
  field: AttributeMetaData,
  baseEntityType: EntityType,
  mappings: EntityMappingsByName,
  usesOWB: boolean,
): DynamicLabelExtra => {
  const {attribute_name, data_type, display_name} = field;

  const {getWbFieldId} = getFWBKey(usesOWB);

  const entityType =
    resolveRelatedAttributeEntityType(
      attribute_name,
      baseEntityType,
      mappings,
    ) || baseEntityType;
  const entityMapping = mappings[entityType];
  const baseEntityMapping = mappings[baseEntityType];
  const entityRelationshipPath = [
    ...reverse(attribute_name.split('/')),
    baseEntityMapping?.display_name,
  ];
  entityRelationshipPath[0] = display_name ?? entityRelationshipPath[0];

  return {
    attributeId: getWbFieldId(field),
    attributeName: attribute_name,
    baseEntityType: entityType,
    display_entity_type: entityType,
    entity_type: entityType,
    entityRelationshipPath,
    entityType,
    hidden: false,
    is_join_attribute: false,
    label: display_name ?? attribute_name,
    mapping: entityMapping,
    name: attribute_name,
    related_entities: [],
    /*(diwakersurya) temp fix (ENGAGE-7099)*/
    resolvedId: attribute_name,
    resource: 'member',
    rteValue: `<${attribute_name}>`,
    sense_name: entityMapping?.sense_name,
    source: 'member',
    template: '<$>',
    type: data_type,
    value: attribute_name,
    unsynced: true,
  };
};
