// @flow strict
// if updating the flow name, update in the auth container also

export const AuthFlow = {
  SMS_REGISTRATION: {
    SIGN_IN: 'SIGN_IN',
    PHONE_REGISTRATION: 'PHONE_REGISTRATION',
    OTP_INPUT: 'OTP_INPUT',
    RESET_PHONE: 'RESET_PHONE',
  },
  SMS: {
    SIGN_IN: 'SIGN_IN',
    OTP_INPUT: 'OTP_INPUT',
    RESET_PHONE: 'RESET_PHONE',
  },
  EMAIL: {
    SIGN_IN: 'SIGN_IN',
    OTP_INPUT: 'OTP_INPUT',
  },
  DYNAMIC_AGENCY: {
    SIGN_IN: 'SIGN_IN',
    SELECT_AGENCY: 'SELECT_AGENCY',
  },
};
