// @flow strict
import * as React from 'react';
import type {PaymentBalance} from 'src/types/referral-v2';

import {useSelector, useDispatch} from 'react-redux';

import {
  getPaymentBalance,
  savePaypalCredentials,
  updateMinimumBalanceThreshold,
} from 'src/action-creators/referral-v2/referral-payment.js';
import {
  selectPaymentBalance,
  selectPaymentBalanceError,
  selectPaymentBalanceLoading,
} from 'src/selectors/referral-v2';

import {
  CollapsibleCard,
  CollapsibleCardContent,
} from '@spaced-out/ui-design-system/lib';
import {paypalSetupInformation} from 'src/utils/referral-v2';
import {
  BodyMedium,
  SubTitleLarge,
  SubTitleSmall,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {Input} from '@spaced-out/ui-design-system/lib/components/Input';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {Badge} from '@spaced-out/ui-design-system/lib/components/Badge';
import PaypalIcon from 'src/images/designSystems2021/paypal.svg';
import {Chip} from '@spaced-out/ui-design-system/lib/components/Chip';

import {Link} from '@spaced-out/ui-design-system/lib/components/Link';
import {Icon} from '@spaced-out/ui-design-system/lib/components/Icon';
import css from 'src/components/referral-v2/settings/settings.css';


const ReferralPaymentSettings = (): React.Node => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getPaymentBalance());
  }, []);

  const paymentBalance: PaymentBalance =
    useSelector(selectPaymentBalance) || {};
  const paymentBalanceLoading = useSelector(selectPaymentBalanceLoading);
  const paymentBalanceError = useSelector(selectPaymentBalanceError);

  return (
    <div className={css.paymentCard}>
      <CollapsibleCard
        title={
          <Title
            isSetupPending={paymentBalanceError}
            balance={paymentBalance.balance}
            minimumThreshold={paymentBalance.minimum_balance_threshold}
            paymentBalanceLoading={paymentBalanceLoading}
          />
        }
      >
        <CollapsibleCardContent>
          {paymentBalanceError ? (
            <PaymentSetup />
          ) : (
            <PaymentStatus
              balance={paymentBalance.balance}
              minimumBalance={paymentBalance.minimum_balance_threshold}
              paymentBalanceLoading={paymentBalanceLoading}
            />
          )}
        </CollapsibleCardContent>
      </CollapsibleCard>
    </div>
  );
};
export default ReferralPaymentSettings;

const PaymentSetup = (): React.Node => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [apiKey, setApiKey] = React.useState('');
  const [secretKey, setSecretKey] = React.useState('');

  const dispatch = useDispatch();
  const clearInputs = () => {
    setApiKey('');
    setSecretKey('');
  };

  const saveDetails = async () => {
    const payload = {
      client_id: apiKey,
      client_secret: secretKey,
    };

    await dispatch(savePaypalCredentials(payload));
    setIsSubmitting(false);
    dispatch(getPaymentBalance());
    clearInputs();
  };

  return (
    <div className={css.paymentSetup}>
      <div className={css.inputActionContainer}>
        <div className={css.inputsContainer}>
          <Input
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            label="API Key"
            placeholder="Enter API Key here"
          />
          <Input
            value={secretKey}
            onChange={(e) => setSecretKey(e.target.value)}
            label="Secret Key"
            placeholder="Enter Secret Key here"
          />
        </div>
        <div className={css.actionContainer}>
          <Button
            type="tertiary"
            classNames={{wrapper: css.minimumBalanceButton}}
          >
            Don't have account
          </Button>
          <Button
            type="primary"
            classNames={{wrapper: css.minimumBalanceButton}}
            onClick={saveDetails}
            isLoading={isSubmitting}
            disabled={!apiKey || !secretKey}
          >
            Submit
          </Button>
        </div>
      </div>

      <div className={css.divider}></div>

      <div className={css.information}>
        <SubTitleSmall className={css.headerTextColor}>
          Here is how to get the credentials
        </SubTitleSmall>

        <div className={css.informationList}>
          {paypalSetupInformation.map((info) => (
            <BodyMedium key={info.id}>
              <Badge fill="blue" text={info.id} />
              <span className={css.setupInfo}>{info.text}</span>
              {info.link && (
                <Link
                  as="bodyMedium"
                  underline={false}
                  target={'_blank'}
                  href={info.link.url}
                >
                  {info.link.text}
                  <Icon
                    color="clickable"
                    name="arrow-up-right-from-square"
                    size="medium"
                    type="regular"
                  />
                </Link>
              )}
            </BodyMedium>
          ))}
        </div>
      </div>
    </div>
  );
};

const PaymentStatus = ({
  balance,
  minimumBalance,
  paymentBalanceLoading,
}: {
  balance: number,
  minimumBalance: number,
  paymentBalanceLoading: boolean,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isLocked, setIsLocked] = React.useState(!!minimumBalance);
  const dispatch = useDispatch();
  const [minBalanceInput, setMinBalanceInput] = React.useState(minimumBalance);

  React.useEffect(() => {
    if (minimumBalance) {
      setMinBalanceInput(minimumBalance);
    }
  }, [minimumBalance]);

  const handleEditThreshold = async () => {
    if (isLocked) {
      setIsLocked(false);
    } else {
      const payload = {
        minimum_balance_threshold: minBalanceInput,
      };
      setIsSubmitting(true);
      await dispatch(updateMinimumBalanceThreshold(payload));
      setIsSubmitting(false);
      setIsLocked(true);
    }
  };

  return (
    <div className={css.paymentBalance}>
      <div className={css.paymentStatus}>
        <div className={css.totalBalance}>
          <SubTitleLarge>Total Balance</SubTitleLarge>
          {paymentBalanceLoading ? (
            <SubTitleLarge>Loading...</SubTitleLarge>
          ) : (
            <SubTitleLarge
              color={minimumBalance > balance ? 'danger' : 'success'}
            >
              ${balance}
            </SubTitleLarge>
          )}
        </div>
        <Link
          href={
            'https://www.paypal.com/us/digital-wallet/manage-money/add-cash'
          }
          underline={false}
          target="_blank"
        >
          <Button iconRightName="add" type={'primary'}>
            Add Balance
          </Button>
        </Link>
      </div>

      <div className={css.divider}></div>

      <div className={css.minimumBalance}>
        <Input
          value={minBalanceInput ? minBalanceInput.toString() : ''}
          type="number"
          onChange={(e) => setMinBalanceInput(parseFloat(e.target.value))}
          locked={isLocked}
          label="Set threshold notification"
          placeholder="Enter minimum value"
          helperText="You will be notified If the balance falls below threshold"
        />

        <Button
          type={minimumBalance ? 'secondary' : 'tertiary'}
          classNames={{wrapper: css.minimumBalanceButton}}
          disabled={!minimumBalance || !minBalanceInput}
          isLoading={isSubmitting}
          onClick={handleEditThreshold}
        >
          {isLocked ? 'Edit' : 'Save'}
        </Button>
      </div>
    </div>
  );
};

const Title = ({
  isSetupPending,
  balance,
  minimumThreshold,
  paymentBalanceLoading,
}: {
  isSetupPending: boolean,
  balance: number,
  minimumThreshold: number,
  paymentBalanceLoading: boolean,
}) => {
  return (
    <span className={css.paypalHeader}>
      <PaypalIcon />
      <SubTitleLarge>Paypal</SubTitleLarge>
      {isSetupPending ? (
        <Chip semantic="danger">Setup Pending</Chip>
      ) : (
        <>
          {paymentBalanceLoading ? (
            <Chip>Loading...</Chip>
          ) : (
            <div>
              {minimumThreshold !== undefined && minimumThreshold > balance ? (
                <Chip semantic="danger">Low Balance</Chip>
              ) : (
                <Chip semantic="success">Balance ${balance}</Chip>
              )}
            </div>
          )}
        </>
      )}
    </span>
  );
};
