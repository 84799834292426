// @flow

// $FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores/index';
import type {SenseEvent} from 'src/types/events';
import type {
  Question,
  QuestionType,
  MultipleChoiceQuestion,
  RatingScaleQuestion,
  NPSSurveyQuestion,
  RhetoricalQuestion,
  BeeFreeModule,
  SMSRhetoricalQuestion,
} from 'src/types/survey';

import uniqueId from 'lodash/uniqueId';
import {fillArray} from 'src/utils/array';

import {EventTypeEnum} from 'src/stores/event';

import {selectAgencyConfig} from 'src/selectors/agency';


type Event = SenseEvent;

export function populateEventModules(
  // $FlowFixMe[value-as-type] [v1.32.0]
  store: IndexStore,
  editEvent: $Shape<SenseEvent>,
  // $FlowFixMe[value-as-type] [v1.32.0]
): SenseEvent {
  const atsFieldWritebackEnabled = selectAgencyConfig(
    store.reduxStore.getState(),
  )['atsFieldWritebackEnabled'];
  const agencyQuestionPrototypes = questionPrototypes(store);

  // TODO (kyle): NPS and SMS_NPS are not true EventType strings
  if (editEvent.eventType === EventTypeEnum.NPS) {
    editEvent = makeEventNps(agencyQuestionPrototypes, editEvent);
    // $FlowFixMe[incompatible-type]
  } else if (editEvent.eventType === EventTypeEnum.SMS_NPS) {
    editEvent = makeEventSMSNps(agencyQuestionPrototypes, editEvent);
  } else if (editEvent.eventType === EventTypeEnum.Survey) {
    editEvent = makeEventSurvey(
      agencyQuestionPrototypes,
      editEvent,
      atsFieldWritebackEnabled,
    );
  } else if (editEvent.eventType === EventTypeEnum.SMS_Survey) {
    editEvent = makeEventSMSSurvey(
      agencyQuestionPrototypes,
      editEvent,
      atsFieldWritebackEnabled,
    );
  } else if (editEvent.eventType === EventTypeEnum.SMS_Job) {
    editEvent = makeEventSmsJobMessage(agencyQuestionPrototypes, editEvent);
  } else if (editEvent.eventType === EventTypeEnum.SMS_Message) {
    editEvent = makeEventSmsMessage(agencyQuestionPrototypes, editEvent);
  } else if (editEvent.eventType === EventTypeEnum.Message) {
    editEvent = makeEventMessage(agencyQuestionPrototypes, editEvent);
  } else if (editEvent.eventType === EventTypeEnum.List) {
    editEvent = makeEventList(agencyQuestionPrototypes, editEvent);
  } else if (editEvent.eventType === EventTypeEnum.BeeFree_Email) {
    editEvent = makeBeeFreeMessage(agencyQuestionPrototypes, editEvent);
  } else if (editEvent.eventType === EventTypeEnum.SMS_Chatbot) {
    editEvent = makeEventSmsChatbot(agencyQuestionPrototypes, editEvent);
  }

  return editEvent;
}

const makeEventNps = (
  prototypes: PrototypeQuestions,
  event: SenseEvent,
  // $FlowFixMe[prop-missing] type prop is bad
): SenseEvent => ({
  ...event,
  type: EventTypeEnum.Survey,
  eventType: EventTypeEnum.NPS,
  questions: [prototypes['nps_survey_question']],
});

const makeEventSMSNps = (
  prototypes: PrototypeQuestions,
  event: SenseEvent,
  // $FlowFixMe[prop-missing] type prop is bad
): SenseEvent => ({
  ...event,
  type: EventTypeEnum.SMS_Survey,
  eventType: EventTypeEnum.SMS_Survey,
  questions: [
    // $FlowFixMe[incompatible-type] types/surveys
    prototypes['sms_message_survey_module'],
    prototypes['nps_survey_question'],
  ],
});

const makeEventSurvey = (
  prototypes: PrototypeQuestions,
  // $FlowFixMe[value-as-type] [v1.32.0]
  event: Event,
  atsFieldWritebackEnabled: boolean,
  // $FlowFixMe[value-as-type] [v1.32.0]
): Event =>
  atsFieldWritebackEnabled
    ? {
        ...event,
        questions: [prototypes['message_survey_module']],
      }
    : {
        ...event,
        questions: [
          prototypes['message_survey_module'],
          prototypes['rating_scale_survey_question'],
        ],
      };

const makeEventSMSSurvey = (
  prototypes: PrototypeQuestions,
  // $FlowFixMe[value-as-type] [v1.32.0]
  event: Event,
  atsFieldWritebackEnabled: boolean,
  // $FlowFixMe[value-as-type] [v1.32.0]
): Event =>
  atsFieldWritebackEnabled
    ? {
        ...event,
        // $FlowFixMe[incompatible-type] types/surveys
        questions: [prototypes['sms_message_survey_module']],
      }
    : {
        ...event,
        questions: [
          // $FlowFixMe[incompatible-type] types/surveys
          prototypes['sms_message_survey_module'],
          prototypes['rating_scale_survey_question'],
        ],
      };

const makeEventSmsChatbot = (
  prototypes: PrototypeQuestions,
  // $FlowFixMe[value-as-type] [v1.32.0]
  event: Event,
  // $FlowFixMe[value-as-type] [v1.32.0]
): Event => ({
  ...event,
  questions: [],
});

const makeEventSmsMessage = (
  prototypes: PrototypeQuestions,
  // $FlowFixMe[value-as-type] [v1.32.0]
  event: Event,
  // $FlowFixMe[value-as-type] [v1.32.0]
): Event => ({
  ...event,
  // $FlowFixMe[incompatible-type] types/surveys
  questions: [prototypes['sms_message_survey_module']],
});
const makeEventSmsJobMessage = (
  prototypes: PrototypeQuestions,
  // $FlowFixMe[value-as-type] [v1.32.0]
  event: Event,
  // $FlowFixMe[value-as-type] [v1.32.0]
): Event => ({
  ...event,
  questions: [
    // $FlowFixMe[incompatible-return] types/surveys
    {
      ...prototypes['sms_message_survey_module'],
      text: 'I found a job that looks like a great match for you: <matched_job/company_name> need a <matched_job/title> in <matched_job/location>. Reply if you want to learn more!',
      includes_job_match_variables: true,
      send_alt_text: true,
      alt_text:
        'I may have some jobs that would be a great fit for you! If you’re still available, please reply!',
    },
  ],
});

const makeEventMessage = (
  prototypes: PrototypeQuestions,
  // $FlowFixMe[value-as-type] [v1.32.0]
  event: Event,
  // $FlowFixMe[value-as-type] [v1.32.0]
): Event => ({
  ...event,
  questions: [prototypes['message_survey_module']],
});

const makeBeeFreeMessage = (
  prototypes: PrototypeQuestions,
  // $FlowFixMe[value-as-type] [v1.32.0]
  event: Event,
  // $FlowFixMe[value-as-type] [v1.32.0]
): Event => ({
  ...event,
  // $FlowFixMe[incompatible-type] types/surveys
  questions: [prototypes['beefree_module']],
});

const makeEventList = (
  prototypes: PrototypeQuestions,
  // $FlowFixMe[value-as-type] [v1.32.0]
  event: Event,
  // $FlowFixMe[value-as-type] [v1.32.0]
): Event => ({
  ...event,
  questions: [
    prototypes.message_survey_module,
    prototypes.attribute_list_module,
    prototypes.message_survey_module,
  ],
});

// $FlowFixMe[value-as-type] [v1.32.0]
export type PrototypeQuestions = {[key: QuestionType]: Question, ...};
// $FlowFixMe[value-as-type] [v1.32.0]
export const questionPrototypes = (store: IndexStore): PrototypeQuestions => {
  const {agency} = store.reduxStore.getState().agency;

  // this function consumes the store to allow individual agencies
  // to have custom prototype questions (i.e. 1-5 rating scales)
  // if it's not a global preference yet.
  const args = {};

  if (['apex', 'agency-1'].includes(agency.name)) {
    args.minRating = 0;
    args.maxRating = 10;
  }
  if (
    [
      'judge-staging',
      'protingent',
      'Wood Personnel',
      'Wood Personnel (Adapt)',
    ].includes(agency.name)
  ) {
    args.minRating = 1;
    args.maxRating = 5;
  }
  if (['adecco', 'agency-1'].includes(agency.name)) {
    args.minRating = 1;
    args.maxRating = 4;
  }
  // $FlowFixMe[incompatible-return] types/surveys
  return {
    // $FlowFixMe[incompatible-return] types/surveys
    rating_scale_survey_question: ratingScaleQuestion(
      args.minRating,
      args.maxRating,
    ),
    // $FlowFixMe[incompatible-return] types/surveys
    nps_survey_question: npsQuestion,
    multiple_choice_survey_question: multipleChoiceQuestion,
    //checkboxes_survey_question: checkboxesQuestion,
    // $FlowFixMe[incompatible-return] types/surveys
    text_survey_question: textQuestion,
    // $FlowFixMe[incompatible-return] types/surveys
    message_survey_module: rhetoricalQuestion,
    // $FlowFixMe[incompatible-return] types/surveys
    beefree_module: beeFreeModule,
    // $FlowFixMe[incompatible-return] types/surveys
    sms_message_survey_module: smsMessageModule,
    // $FlowFixMe[incompatible-return] types/surveys
    attribute_list_module: attributeListModule,
    // $FlowFixMe[incompatible-return] types/surveys
    field_writeback_module: textQuestion,
    // $FlowFixMe[incompatible-return] types/surveys
    calendar_date_survey_question: calendarDateQuestion,
  };
};

export const ratingScaleQuestion = (
  minRating?: number = 1,
  maxRating?: number = 10,
  // $FlowFixMe[prop-missing]
): RatingScaleQuestion => ({
  type: 'rating_scale_survey_question',
  question: '',
  maxText: '',
  minText: '',
  minRating,
  maxRating,
  branchIds: [],
});

// $FlowFixMe[prop-missing]
export const npsQuestion: NPSSurveyQuestion = {
  type: 'nps_survey_question',
  question:
    'How likely are you to recommend [COMPANY NAME] to a friend or colleague?',
  maxText: 'Extremely likely',
  minText: 'Not likely at all',
  minRating: 0,
  maxRating: 10,
  branchIds: [],
};

// $FlowFixMe[prop-missing]
export const rhetoricalQuestion: RhetoricalQuestion = {
  type: 'message_survey_module',
  text: '',
  include_job_block: false,
  job_matches_count: 5,
  send_alt_text: false,
  job_matches_params: {
    city: null,
    state: null,
    radius_in_miles: null,
    keywords: null,
    title: null,
    skills: null,
  },
};

// $FlowFixMe[prop-missing]
export const beeFreeModule: BeeFreeModule = {
  type: 'beefree_module',
  beefree_html: '',
  beefree_json: null,
};

// $FlowFixMe[prop-missing]
export const smsMessageModule: SMSRhetoricalQuestion = {
  type: 'sms_message_survey_module',
  text: '',
};

const choices = fillArray(4, () => ({
  id: uniqueId('choice_'),
  value: '',
}));

// $FlowFixMe[prop-missing] types/surveys
const multipleChoiceQuestion: MultipleChoiceQuestion = {
  type: 'multiple_choice_survey_question',
  question: '',
  required: false,
  choices,
  branchIds: [],
  alertChoices: [],
};

const checkboxesQuestion = {
  type: 'checkboxes_survey_question',
  question: '',
  choices,
  branchIds: [],
  min: null,
  max: choices.length,
};

const textQuestion = {
  type: 'text_survey_question',
  question: '',
  alertKeywords: [],
};

const calendarDateQuestion = {
  type: 'calendar_date_survey_question',
  question: '',
  alertKeywords: [],
  dateFormat: 'yyyy-mm-dd',
};
// TODO (nelson): Get rid of pre-filled attribute
const attributeListModule = {
  type: 'attribute_list_module',
  attributesToInclude: [],
};
