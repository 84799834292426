// @flow

import type {
  Broadcast,
  BroadcastForm,
  ApiBroadcast,
} from 'src/types/broadcasts';
import type {Action} from 'src/action-creators/broadcasts';
import type {Action as MessagesAction} from 'src/action-creators/messages';
import type {ErrorMap} from 'src/types/redux';

import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';

import {
  RECEIVE_BROADCASTS,
  RECEIVE_BROADCASTS_PAGINATED,
  RECEIVE_NEW_BROADCAST,
  RECEIVE_BROADCAST,
  RECEIVE_BROADCAST_BRANCHES,
  CHANGE_BROADCAST_FORM,
  RESET_BROADCAST_FORM,
  RECEIVE_BROADCAST_FORM_ERRORS,
  REMOVE_BROADCAST,
  MARK_BROADCAST_PROCESSED,
} from 'src/action-creators/broadcasts';
import {BROADCAST_STATUS_PROCESSED} from 'src/types/broadcasts';
import {validateForm} from 'src/utils/broadcasts';
import {defined} from 'src/utils';


export type State = {
  data: Broadcast[],
  offsets: {[string]: number},
  form: ?BroadcastForm,
  formReset: ?BroadcastForm,
  formErrors: ErrorMap,
  hasMore: {[string]: boolean},

  map: {[string]: Broadcast},
};

const initialState = {
  data: [],
  offsets: Object.freeze({}),
  form: null,
  formReset: null,
  formErrors: Object.freeze({}),
  hasMore: Object.freeze({}),

  map: Object.freeze({}),
};

export default (
  state: State = initialState,
  action: Action | MessagesAction,
): State => {
  switch (action.type) {
    case RECEIVE_BROADCASTS: {
      return reduceBroadcasts(state, action.payload);
    }
    case RECEIVE_BROADCASTS_PAGINATED: {
      return reduceBroadcastsPaginated(state, action.payload);
    }

    case RECEIVE_NEW_BROADCAST:
      return {
        ...state,
        data: [...state.data, action.payload],
      };

    case RECEIVE_BROADCAST_BRANCHES:
      if (!action.payload.length) {
        return state;
      }

      const {parentBroadcastId} = action.payload[0];
      const updatedBroadcast = {
        ...state.map[parentBroadcastId],
        branches: action.payload,
      };
      return {
        ...state,
        map: {
          ...state.map,
          [parentBroadcastId]: updatedBroadcast,
        },
      };

    case RECEIVE_BROADCAST: {
      const {threads, contacts, audienceMembers, ...broadcast} = action.payload;

      const updatedBroadcast: Object = broadcast;

      if (threads) {
        updatedBroadcast.threadIds = threads.map(({id}) => id);
      }

      (updatedBroadcast: Broadcast);

      return {
        ...state,
        map: {
          ...state.map,
          [broadcast.id]: {
            ...cleanUpApiBroadcast(updatedBroadcast),
          },
        },
      };
    }

    case CHANGE_BROADCAST_FORM:
      const form = {
        ...state.form,
        ...action.payload,
      };
      return {
        ...state,
        form,
        // Remove existing errors if they no longer apply
        formErrors:
          (!isEmpty(state.formErrors) && validateForm(form)) ||
          Object.freeze({}),
      };

    case RESET_BROADCAST_FORM:
      return {
        ...state,
        // TODO (kyle): i'm not sure if `formReset` is ever set anywhere

        // dispatch(resetBroadcastForm()) should reset form to the value in formReset
        // otherwise if a value is passed, it resets both form and formReset
        form: action.payload === undefined ? state.formReset : action.payload,
        formReset:
          action.payload === undefined ? state.formReset : action.payload,
        formErrors: Object.freeze({}),
      };

    case REMOVE_BROADCAST: {
      const id = action.payload;
      return {
        ...state,
        data: state.data.filter((broadcast) => broadcast.id !== id),
        map: omit(state.map, id),
      };
    }

    case RECEIVE_BROADCAST_FORM_ERRORS: {
      return {
        ...state,
        formErrors: action.payload,
      };
    }

    case MARK_BROADCAST_PROCESSED: {
      const id = action.payload;
      return {
        ...state,
        data: state.data.map((broadcast) =>
          broadcast.id === id
            ? {...broadcast, status: BROADCAST_STATUS_PROCESSED}
            : broadcast,
        ),
        map: {
          ...state.map,
          [id]: {...state.map?.[id], status: BROADCAST_STATUS_PROCESSED},
        },
      };
    }
  }

  return state;
};

const reduceBroadcasts = (state: State, broadcasts: ApiBroadcast[]): State => {
  const map = {...state.map};

  for (const broadcast of broadcasts) {
    map[broadcast.id] = {
      ...map[broadcast.id],
      ...cleanUpApiBroadcast(broadcast),
    };
  }

  return {
    ...state,
    data: broadcasts.map(cleanUpApiBroadcast),
    map,
  };
};

const reduceBroadcastsPaginated = (
  state: State,
  {
    broadcasts,
    offset,
    limit,
    phone_number_set_id,
  }: {
    broadcasts: ApiBroadcast[],
    offset: number,
    limit: number,
    phone_number_set_id: string,
  },
): State => {
  const map = {...state.map};

  for (const broadcast of broadcasts) {
    map[broadcast.id] = {
      ...map[broadcast.id],
      ...cleanUpApiBroadcast(broadcast),
    };
  }

  const data = Object.values(map);
  const broadcastsByPhoneNumberSet = groupBy(data, 'phoneNumberSetId');
  const offsets = mapValues(
    broadcastsByPhoneNumberSet,
    (broadcasts) => broadcasts.length,
  );
  return {
    ...state,
    // $FlowFixMe Object.values
    data,
    offsets,
    hasMore: {
      ...state.hasMore,
      [phone_number_set_id]: !(offset + limit > offsets[phone_number_set_id]),
    },
    map,
  };
};

function cleanUpApiBroadcast(broadcast: ApiBroadcast) {
  // TODO (kyle): `defined` should not be necessary
  return defined(omit(broadcast, ['name', 'threadIds']));
}
