// @flow

import type {EventStatusPlot} from 'src/types/report';
import type {PlotAction} from 'src/action-creators/plot';

import {
  RECEIVE_AGENCY,
  RECEIVE_WORKFLOW,
  RECEIVE_EVENT,
} from 'src/action-creators/plot';


export type PlotState = {
  agency: ?EventStatusPlot,
  workflows: {
    [workflowId: string]: EventStatusPlot,
  },
  events: {
    [eventId: string]: EventStatusPlot,
  },
};

const initialState = {
  agency: null,
  workflows: Object.freeze({}),
  events: Object.freeze({}),
};

export default (
  state: PlotState = initialState,
  action: PlotAction,
): PlotState => {
  switch (action.type) {
    case RECEIVE_AGENCY:
      return {...state, agency: action.payload};

    case RECEIVE_WORKFLOW:
      return {
        ...state,
        workflows: {
          ...state.workflows,
          [action.payload.workflowId]: action.payload.plot,
        },
      };

    case RECEIVE_EVENT:
      return {
        ...state,
        events: {
          ...state.events,
          [action.payload.eventId]: action.payload.plot,
        },
      };
  }

  return state;
};
