// @flow

import type {SenseEvent, ClientEventType} from 'src/types/events';
import type {Tab} from 'src/components/lib/tabs/tabs.jsx';

import {EventTypeEnum} from 'src/stores/event';


const eventTypeSelectionTab = {
  url: '',
  name: 'Intro',
  onlyActiveOnIndex: true,
};

const introTabs = [{name: 'Basics', url: '/basics'}];

const finalTabs = [{name: 'Scheduling', url: '/scheduling'}];

const newEventTabs = [eventTypeSelectionTab, ...introTabs];

const contentTab = {name: 'Content', url: '/content'};
const surveyTab = {name: 'Survey', url: '/content'};

const beeFreeTab = {name: 'Email', url: '/email'};

// NOTE (kyle): we don't use this currently but could if we wanted to
// add the chatbot editor back.
const chatbotTab = {name: 'Chatbot', url: '/chatbot'};

const tabsForBasic = [...introTabs, contentTab, ...finalTabs];

const tabsForBeeFree = [...introTabs, beeFreeTab, surveyTab, ...finalTabs];

const tabsForBeeFreeChatbot = [
  ...introTabs,
  beeFreeTab,
  chatbotTab,
  ...finalTabs,
];

const tabsForSmsChatbot = [...introTabs, chatbotTab, ...finalTabs];

export function getProgressTabsForType(eventType: ClientEventType): Tab[] {
  switch (eventType) {
    case EventTypeEnum.BeeFree_Email:
      return tabsForBeeFree;
    case EventTypeEnum.BeeFree_Chatbot:
      return tabsForBeeFreeChatbot;
    case EventTypeEnum.SMS_Chatbot:
      return tabsForSmsChatbot;
  }
  return tabsForBasic;
}

export function getProgressTabsForEvent(event: SenseEvent): Tab[] {
  return getProgressTabsForType(event.eventType);
}

export function getProgressTabsForNewEvent(event: SenseEvent): Tab[] {
  return !event.typeLocked
    ? newEventTabs
    : getProgressTabsForType(event.eventType);
}
