// @flow strict

import type {
  ReferralJob,
  ReferralSetup,
  ReferralSetupApiRequest,
  ReferralStage,
} from 'src/types/referral-v2';

import type {MenuOption} from '@spaced-out/ui-design-system/lib/components/index';


export const STATUS = {
  REFERRED: 'Referred',
  CONSIDERED: 'Considered',
  UNDER_REVIEW: 'Under Review',
  HIRED: 'Hired',
  PAID: 'Paid',
  NOT_CONSIDERD: 'Not Considered',
};

export const SELECTED_TEMP_JOB: ReferralJob = {
  id: '',
  title: '',
  branch: '',
};

export const BULK_ACTION_MENU = [
  {label: 'Link Jobs to Program', value: 'link_jobs', disabled: false},
  {label: 'Bonus Amount', value: 'payout_amount', disabled: false},
  {label: 'Payout Period', value: 'payout_period', disabled: false},
  {label: 'Referral Validity', value: 'referral_validity', disabled: false},
  {label: 'Unlink Jobs', value: 'unlink_jobs', disabled: false},
];

export const REFERRAL_NOTIFICATION = [
  {
    label: 'Referral Submitted',
    recruiter: 'referral_submission_recruiter_notification',
    agent: 'submission',
  },
  {
    label: 'Referral Status Change',
    recruiter: 'referral_status_change_recruiter_notification',
    agent: 'status_change',
  },
  {
    label: 'Referral Withdrawn',
    recruiter: 'referral_withdrawn_recruiter_notification',
    agent: 'withdrawn',
  },
  {
    label: 'Referral Payments',
    recruiter: 'referral_payment_recruiter_notification',
    agent: 'payments',
  },
];

export const trackingArchiveOptions = [
  {label: 'Not Considered', key: 'Not Considered'},
  {label: 'Duplicate Record', key: 'Duplicate Record'},
  {label: 'Others', key: 'others'},
];

export const payoutArchiveOptions = [
  {label: 'Referral left Organization', key: 'Referral left Organization'},
  {label: 'Referrer left Organization', key: 'Referrer left Organization'},
  {label: 'Duplicate Record', key: 'Duplicate Record'},
];

export const trackingAndPayoutPageTabs = [
  {key: 'all', label: 'All'},
  {key: 'archived', label: 'Archived'},
];

export const programAndJobTabs = [
  {key: 'programs', label: 'Programs'},
  {key: 'jobs', label: 'Jobs'},
];

export const mainTabs = [
  {tabId: '/referralv2/dashboard', label: 'Dashboard'},
  {tabId: '/referralv2/tracking', label: 'Tracking'},
  {tabId: '/referralv2/payouts', label: 'Payouts'},
  {tabId: '/referralv2/programs', label: 'Programs'},
  {tabId: '/referralv2/jobs', label: 'Jobs'},
  {tabId: '/referralv2/settings', label: 'Settings'},
];

export const radioSelectOption = [
  {label: 'Use Email', key: 'email'},
  {label: 'Use Phone Number', key: 'phone'},
  {label: 'Use Email or Phone Number', key: 'email_or_phone'},
];

export const radioChoiceOption = [
  {label: 'Yes', key: 'true'},
  {label: 'No', key: 'false'},
];

export const radioTimeSheetLogOption = [
  {label: 'Days', key: 'days'},
  {label: 'Hours', key: 'hours'},
];

export const timeSheetSyncOptions = [
  {
    label: 'Overwrite',
    key: 'overwrite',
  },
  {
    label: 'Addition',
    key: 'add',
  },
];

export const menuOptions = [
  {label: 'Edit Program Details', key: 'edit'},
  {label: 'Duplicate as New', key: 'duplicate'},
  {label: 'Activate program', key: 'archive'},
  {label: 'Deactivate Program', key: 'unarchive'},
  {label: 'Copy Personalised  Link', key: 'copy_agent_link'},
  {label: 'Copy Agency Link', key: 'copy_agency_link'},
];

export const DEFAULT_LINE_ATTRIBUTE = {
  lineVariant: 'default',
  lineStyle: 'dashed',
};

export const LOADING_LINE_ATTRIBUTE = {
  lineVariant: 'loading',
  lineStyle: 'solid',
};

export const SUCCESS_LINE_ATTRIBUTE = {
  lineVariant: 'success',
  lineStyle: 'solid',
};

export const SAVE_BTN_TEXTS = {
  SETUP: 'Save & Continue',
  CONFIGURE: 'Save Changes',
};

export const INITIAL_SETUP_STATE: ReferralSetup = {
  name: '',
  brand_id: '',
  welcome_message: '',
  terms: '',
  must_acknowledge_terms: false,
};

export const INITIAL_REFERRAL_SETTINGS = {
  one_time_settings: {
    should_pay_for_different_role: false,
    reward_for_existing_referral: false,
    duplicate_profile_check_method: 'email',
    timesheet_log_type: 'days',
    payout_rule: null,
  },
  bonus_and_payout_settings: {
    default_bonus_amount: null,
    default_bonus_payout_period: 90,
    default_referral_validity: 90,
    payout_currency: 'USD',
  },
};

export const SETUP_PROGRAM_TAB = [
  {
    id: 0,
    key: 'setup',
    label: 'Program Setup',
    description: 'Basic details of the program',
  },
  {
    id: 1,
    key: 'settings',
    label: 'Settings',
    description: 'One time settings',
  },
  {
    id: 2,
    key: 'questionnaire',
    label: 'Form',
    description: 'Questionnaire for referrals',
  },
  {
    id: 3,
    key: 'user_access',
    label: 'User Access',
    description: 'Program & notification access',
  },
];

export const CREATE_MULTIPLE_PROGRAM_TAB = [
  {
    id: 0,
    key: 'setup',
    label: 'Program Setup',
    description: 'Basic details of the program',
  },
  {
    id: 1,
    key: 'questionnaire',
    label: 'Form',
    description: 'Questionnaire for referrals',
  },
  {
    id: 2,
    key: 'user_access',
    label: 'User Access',
    description: 'Program & notification access',
  },
];

export const SETUP_PROGRAM_JOB_LIST_TAB = [
  {
    id: 0,
    key: 'setup',
    label: 'Program Setup',
    description: 'Basic details of the program',
  },
  {
    id: 1,
    key: 'settings',
    label: 'Settings',
    description: 'One time settings',
  },
  {
    id: 2,
    key: 'questionnaire',
    label: 'Form',
    description: 'Questionnaire for referrals',
  },
  {
    id: 3,
    key: 'user_access',
    label: 'User Access',
    description: 'Program & notification access',
  },
  {
    id: 4,
    key: 'jobs',
    label: 'Jobs',
    description: 'Include a job list',
  },
];

export const CREATE_JOB_LIST_PROGRAM_TAB = [
  {
    id: 0,
    key: 'setup',
    label: 'Program Setup',
    description: 'Basic details of the program',
  },
  {
    id: 1,
    key: 'questionnaire',
    label: 'Form',
    description: 'Questionnaire for referrals',
  },
  {
    id: 2,
    key: 'user_access',
    label: 'User Access',
    description: 'Program & notification access',
  },
  {
    id: 3,
    key: 'settings',
    label: 'Bonus & Validity Settings',
    description: 'Set the bonus & validity',
  },
  {
    id: 4,
    key: 'jobs',
    label: 'Jobs',
    description: 'Include a job list',
  },
];

export const normalizePayload = (
  data: ReferralSetup,
): ReferralSetupApiRequest => {
  return {
    stage: 'setup',
    setup_configuration: {
      name: data.name,
      welcome_message: data.welcome_message,
      brand_id: data.brand_id,
      terms: data.terms,
      must_acknowledge_terms: data.must_acknowledge_terms,
    },
  };
};

export const hidePreviewSection = (currentStage: ReferralStage): boolean =>
  ['completed', 'bonus', 'settings', 'jobs', 'user_access'].includes(
    currentStage,
  );

export const isValidForMarkAsConsiderd = (
  allowReferralWithoutJob?: boolean,
  job_id: string,
): boolean => allowReferralWithoutJob || !!job_id;

export const PROGRAM_TABS = [
  {tabId: 'setup', label: 'Program Details'},
  {tabId: 'questionnaire', label: 'Form'},
  {tabId: 'user_access', label: 'User Access'},
];

export const PROGRAM_JOB_LIST_TABS = [
  {tabId: 'setup', label: 'Program Details'},
  {tabId: 'questionnaire', label: 'Form'},
  {tabId: 'user_access', label: 'User Access'},
  {tabId: 'settings', label: 'Settings'},
  {tabId: 'jobs', label: 'Jobs'},
];

export const DEFAULT_JOB_LIST_PROGRAM_MAPPING = {
  setup: 1,
  settings: 2,
  questionnaire: 3,
  user_access: 4,
  jobs: 5,
  completed: 6,
};

export const NON_DEFAULT_JOB_LIST_PROGRAM_MAPPING = {
  setup: 1,
  questionnaire: 2,
  user_access: 3,
  settings: 4,
  jobs: 5,
  completed: 6,
};

export const DEFAULT_PROGRAM_MAPPING = {
  setup: 1,
  settings: 2,
  questionnaire: 3,
  user_access: 4,
  completed: 5,
  // jobs step is not required for non default program so assigning it's value as zero
  jobs: 0,
};

export const NON_DEFAULT_PROGRAM_MAPPING = {
  setup: 1,
  questionnaire: 2,
  user_access: 3,
  completed: 4,
  // settings and jobs step is not required for non default program so assigning it's value as zero
  settings: 0,
  jobs: 0,
};

export const programUserRole = [
  {key: 'view', label: 'As a Viewer'},
  {key: 'edit', label: 'As a Editor'},
];

export const REFERRAL_USER_GROUP = {
  viewer: {label: 'Viewer', enum: 'viewer'},
  admin: {label: 'Admin', enum: 'admin'},
  super_admin: {label: 'Super Admin', enum: 'super_admin'},
};

export const USER_ACCESS_ACTION_OPTIONS = [
  {key: 'no_access', label: 'Remove Access'},
];

export const DEFAULT_PAGE_NUMBER = 1;

export const PROGRAM_FILTER = [
  {
    label: 'Active Programs',
    key: 'active',
  },
  {
    label: 'Inactive Programs',
    key: 'inactive',
  },
];

export const TRACKING_ACTION_OPTIONS = [
  {label: 'Mark As Considered', value: STATUS.CONSIDERED},
  {label: 'Mark As Hired', value: STATUS.HIRED},
];

export const pathsToCheck = [
  'setup',
  'programs',
  'payouts',
  'tracking',
  'setting',
  'job',
  'create',
  'change-log',
];

export const TIMESHEET_CSV_ROWS = [
  {
    label: 'Name',
    value: 'name',
  },
  {label: 'Candidate ID', value: 'candidate id'},
  {
    label: 'Job Order ID',
    value: 'job order id',
  },
  {label: 'Placement ID', value: 'placement id'},
  {
    label: 'Total Time',
    value: 'total time',
  },
];

export const sampleTimeSheetData = [
  {
    Name: 'Jhon',
    'Candidate ID': '789273',
    'Job Order ID': '8989',
    'Placement ID': '78983273',
    'Total Time': '800',
  },
];

export const programLinkCsvRow = [
  'Program ID',
  'Program Name',
  'Program Type',
  'Agent Name',
  'Agency Link',
  'Agent Link',
];

export const INPUT_TEXT_LIMIT = {
  default: 150,
  program_message: 500,
  form_recommendation: 500,
  number: 6,
};

export const bonusAmountLimit = 999999;

export const defaultJobProgramFilter = [
  {
    name: 'All jobs',
    id: '',
  },
];

export const paypalSetupInformation = [
  {
    id: '1',
    text: 'Login to you PayPal account and ',
    link: {
      url: 'https://www.paypal.com/us/digital-wallet/manage-money/add-cash',
      text: 'visit applications page',
    },
  },
  {
    id: '2',
    text: 'Select create app.',
  },
  {
    id: '3',
    text: 'Under app type select platform - Move payments to sellers as a platform (marketplace, crowdfunding, or e-commerce platform).',
  },
  {
    id: '4',
    text: 'Select create app.',
  },
  {
    id: '5',
    text: 'Done! The page will display clients ID and secret. Copy and enter them above.',
  },
];

export const DEFAULT_TABLE_PAGE_SIZE = 13;

export const DEFAULT_DESKTOP_WINDOW_SIZE = 1170;

export const notificationSettingsOptions = [
  {label: 'Submitted', key: 'submission'},
  {label: 'Status Change', key: 'status_change'},
  {label: 'Withdrawn', key: 'withdrawn'},
  {label: 'Payments', key: 'payments'},
];

export const timeFrameOptions = [
  {label: 'Last 90 days', key: '90'},
  {label: 'Last 30 days', key: '30'},
  {label: 'Last 7 days', key: '7'},
  {label: 'Custom Date Range', key: 'date-range'},
];

export const reasonActionMapping = {
  failed: {
    text: 'Reason for failure of payment transaction here.',
    actionText: 'Retry',
  },
  unclaimed: {
    text: 'Not claimed yet.',
    actionText: 'Cancel Transaction',
  },
};

export const timeAgoOptions: MenuOption[] = [
  {key: 'this_year', label: 'Last year'},
  {key: '90', label: 'Last 90 days'},
  {key: '30', label: 'Last 30 days'},
  {key: 'this_month', label: 'Last month'},
  {key: '7', label: 'Last 7 days'},
];

export const payoutActionOptions = (
  payment_status: string,
  selected: boolean,
): MenuOption[] => {
  return [
    {
      label: 'Edit Bonus',
      key: 'edit_bonus',
      disabled: payment_status === 'Paid' || selected,
    },
    {label: 'Archive', key: 'archive'},
    {label: 'Edit Start Date', key: 'edit_start_date'},
    {
      label: 'Mark as Paid',
      key: 'mark_as_paid',
      disabled: payment_status !== 'Payout Pending' || selected,
    },
    {
      label: 'Pay using PayPal',
      key: 'stage_change',
      disabled: payment_status !== 'Payout Pending' || selected,
    },
  ];
};

export const jobHeaders = [
  {
    key: 'title',
    label: 'Job Title',
  },
  {
    key: 'owner_name',
    label: 'Job Owner',
  },
  {
    key: 'payout_amount',
    label: 'Bonus Amount',
    sortable: true,
  },
  {
    key: 'payout_period',
    label: 'Payout Period (in days)',
    sortable: true,
  },
  {
    key: 'referral_validity',
    label: 'Referral Validity (in days)',
    sortable: true,
  },
  {key: 'action', label: ''},
];

export const getOperatorOptions = (
  fieldType: string,
): {key: string, label: string, tooltip: string}[] => {
  switch (fieldType) {
    case 'boolean':
      return [
        {
          key: 'eq',
          label: 'is',
          tooltip: 'isActive = "true/false"',
        },
      ];
    default:
      return [
        {
          key: 'eq',
          label: 'is',
          tooltip:
            'Define the value which the field is expected to have in quotes e.g. "placed"',
        },
        {
          key: 'neq',
          label: 'is not',
          tooltip:
            'Define the value which the field is not expected to have in quotes e.g. "placed"',
        },
        {
          key: 'is_not_null',
          label: 'is set/present',
          tooltip: 'Value not required',
        },
        {
          key: 'is_null',
          label: 'is not set/present',
          tooltip: 'Value not required',
        },
        {
          key: 'contains',
          label: 'contains one of',
          tooltip: `Multiple values are expected separated by comma e.g. "hired", "confirmed start" etc.`,
        },
        {
          key: 'ncontains',
          label: 'does not contain one of',
          tooltip: `Multiple values are expected separated by comma e.g. “hired”, “confirmed start” etc.`,
        },
      ];
  }
};

export const entityValueTypeOptions = [
  {label: 'Custom Value', key: 'Custom Value'},
  {label: 'Referral Field', key: 'Referral Field'},
];

export const booleanDropdownOptions = [
  {label: 'True', key: 'true'},
  {label: 'False', key: 'false'},
];

export const offsetOptions = [
  {label: 'Plus (+)', key: '+'},
  {label: 'Minus (-)', key: '-'},
];

export const customQuestionTypeOptions = [
  {key: 'referral', label: 'Referral Question'},
  {key: 'referrer', label: 'Referrer Question'},
];
