// @flow strict-local

import type {BackgroundTaskAction} from 'src/action-creators/background-task';
import type {
  BackgroundTaskStatus,
  BackgroundTask,
} from 'src/types/background-task';

import {RECEIVE, RECEIVE_POLLING} from 'src/action-creators/background-task';


export type {BackgroundTaskStatus};

export type TasksByKey = {
  [key: string]: BackgroundTask,
};

export type PollingTimersByKey = {
  [key: string]: boolean,
};

export type BackgroundTaskState = {
  data: TasksByKey,
  polling: PollingTimersByKey,
};

const initialState = {
  data: Object.freeze({}),
  polling: Object.freeze({}),
};

export default (
  state: BackgroundTaskState = initialState,
  action: BackgroundTaskAction,
): BackgroundTaskState => {
  switch (action.type) {
    case RECEIVE:
      return {
        ...state,
        data: {...state.data, [action.payload.key]: action.payload},
      };

    case RECEIVE_POLLING:
      return {
        ...state,
        polling: {
          ...state.polling,
          [action.payload.key]: action.payload,
        },
      };
  }

  return state;
};
