// @flow strict

import type {NoteWritebackActions} from 'src/types/note-writeback-metadata';
import type {NoteWritebackMetadataAction} from 'src/action-creators/note-writeback-metadata';

import {NoteWritebackMetadataReceive} from 'src/action-creators/note-writeback-metadata';


export type State = {
  actions: NoteWritebackActions,
};

const initialState = {
  actions: [],
};

export default (
  state: State = initialState,
  action: NoteWritebackMetadataAction,
): State => {
  switch (action.type) {
    case NoteWritebackMetadataReceive: {
      return {
        ...state,
        actions: action.payload,
      };
    }
  }

  return state;
};
