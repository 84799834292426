// @flow strict

import type {
  ReferralDetails,
  GenericError,
  GetReferralTrackingApiResponse,
  ReferralConfiguration,
  ReferralProgramsApiResponse,
  ReferralLeaderBoard,
  ReferralAgent,
  ReferralAgencyInfo,
  ReferralPayouts,
  LinkJobsPrograms,
  TransactionHistory,
  ReferralLink,
} from 'src/types/referral-v2';
import type {Task} from 'src/types/data-export-report';
import type {ReferralJobsActions} from 'src/action-creators/referral-v2/referral-jobs.js';
import type {ReferralProgramActions} from 'src/action-creators/referral-v2/programs.js';
import type {ReferralTrackingActions} from 'src/action-creators/referral-v2/referral-tracking.js';
import type {ReferralPaymentActions} from 'src/action-creators/referral-v2/referral-payment.js';
import type {ReferralDashboardActions} from 'src/action-creators/referral-v2/referral-dashboard.js';

import {
  RECEIVE_JOBS_LOADING,
  RECEIVE_JOBS,
  RECEIVE_JOBS_ERROR,
  RECEIVE_ATTRIBUTES_LOADING,
  RECEIVE_ATTRIBUTES,
  RECEIVE_ATTRIBUTES_ERROR,
  RECEIVE_CONFIGURED_ATTRIBUTES_LOADING,
  RECEIVE_CONFIGURED_ATTRIBUTES,
  RECEIVE_CONFIGURED_ATTRIBUTES_ERROR,
  RECEIVE_ATTRIBUTES_VALUES_LOADING,
  RECEIVE_ATTRIBUTES_VALUES,
  RECEIVE_ATTRIBUTES_VALUES_ERROR,
  RECEIVE_FILTER_ATTRIBUTES_VALUES_LOADING,
  RECEIVE_FILTER_ATTRIBUTES_VALUES,
  RECEIVE_FILTER_ATTRIBUTES_VALUES_ERROR,
  RECEIVE_BONUS_CHANGE_LOG_LOADING,
  RECEIVE_BONUS_CHANGE_LOG,
  RECEIVE_BONUS_CHANGE_LOG_ERROR,
  FETCH_PROGRAMS_FOR_JOBS,
  REFERRAL_LINK_JOBS_PROGRAMS_ERROR,
  RECEIVE_SEARCHED_ATTRIBUTES_VALUES,
} from 'src/action-creators/referral-v2/referral-jobs.js';
import {
  PROGRAM_AGENCY_LINKS,
  BRANDS_LOADING,
  BRANDS,
  BRANDS_ERROR,
  QUESTIONNAIRE_LOADING,
  QUESTIONNAIRE,
  QUESTIONNAIRE_ERROR,
  PROGRAMS,
  PROGRAMS_LOADING,
  PROGRAMS_ERROR,
  PROGRAM_DETAILS_LOADING,
  PROGRAM_DETAILS,
  PROGRAM_DETAILS_ERROR,
  SAVE_PROGRAM_DETAILS_LOADING,
  SAVE_PROGRAM_DETAILS,
  SAVE_PROGRAM_DETAILS_ERROR,
} from 'src/action-creators/referral-v2/programs.js';
import {
  RECEIVE_TIME_SHEET_DATA,
  REFERRAL_TRACKING_LOADING,
  REFERRAL_TRACKING,
  REFERRAL_TRACKING_ERROR,
  REFERRAL_PAYOUTS_LOADING,
  REFERRAL_PAYOUTS,
  REFERRAL_PAYOUTS_ERROR,
} from 'src/action-creators/referral-v2/referral-tracking.js';
import {
  REFERRAL_LEADERBOARD_LOADING,
  REFERRAL_LEADERBOARD,
  REFERRAL_LEADERBOARD_ERROR,
  REFERRAL_AGENCY_LOADING,
  REFERRAL_AGENCY,
  REFERRAL_AGENCY_ERROR,
  REFERRAL_AGENT_LOADING,
  REFERRAL_AGENT,
  REFERRAL_AGENT_ERROR,
} from 'src/action-creators/referral-v2/referral-dashboard.js';
import {
  RECEIVE_TRANSACTION_HISTORY_LOADING,
  RECEIVE_TRANSACTION_HISTORY,
  RECEIVE_TRANSACTION_HISTORY_ERROR,
  RECEIVE_PAYMENT_BALANCE_LOADING,
  RECEIVE_PAYMENT_BALANCE,
  RECEIVE_PAYMENT_BALANCE_ERROR,
} from 'src/action-creators/referral-v2/referral-payment.js';


export const ReferralCurrentState = {
  IDLE: 'idle',
  FETCHING_REFERRAL_DETAILS: 'fetchingReferralDetails',
  SAVING_REFERRAL_DETAILS: 'savingReferralDetails',
  FETCHING_REFERRAL_TRACKING_DETAILS: 'fetchingReferralTrackingDetails',
  FETCHING_JOBS: 'fetchingJobs',
  FETCHING_BRANDS: 'fetchingBrands',
  FETCHING_QUESTIONNAIRE: 'fetchingQuestionnaire',
  FETCHING_HIRED_STATES: 'fetchingHiredStates',
  FETCHING_LEADERBOARD: 'fetchingLeaderboard',
  FETCHING_AGENCY: 'fetchingAgency',
  FETCHING_AGENTS: 'fetchingAgents',
  FETCHING_REFERRAL_PROGRAM: 'fetchingReferralProgram',
  FETCHING_REFERRAL_PAYOUTS: 'fetchingReferralPayouts',
  FETCHING_REFERRAL_ATTRIBUTES: 'fetchingReferralAttributes',
  FETCHING_REFERRAL_CONFIGURED_ATTRIBUTES:
    'fetchingReferralConfiguredAttributes',
  FETCHING_REFERRAL_ATTRIBUTES_VALUES: 'fetchingReferralAttributesValues',
  FETCHING_REFERRAL_FILTER_ATTRIBUTES_VALUES:
    'fetchingReferralFilterAttributesValues',
  FETCHING_BONUS_CHANGE_LOG: 'fetchingBonusChangeLog',
  FETCHING_TRANSACTION_HISTORY: 'fetchingTransactionHistory',
  FETCHING_PAYMENT_BALANCE: 'fetchingPaymentBalance',
};

export type ReferralState = {
  current_state: string,
  referral_programs?: ?ReferralProgramsApiResponse,
  referral_details?: ?ReferralDetails,
  referral_details_error?: ?GenericError,
  referral_tracking_details_error?: ?GenericError,
  save_referral_error?: ?GenericError,
  referral_tracking_details?: ?GetReferralTrackingApiResponse,
  referral_programs_error?: ?GenericError,
  configuration?: ?ReferralConfiguration,
  referral_leaderboard?: ?(ReferralLeaderBoard[]),
  referral_leaderboard_error?: ?GenericError,
  referral_agents?: ?ReferralAgent,
  referral_agents_error?: ?GenericError,
  referral_agency?: ?ReferralAgencyInfo,
  referral_agency_error?: ?GenericError,
  referral_payouts?: ?ReferralPayouts,
  referral_attributes_error?: ?GenericError,
  referral_selected_attributes_error?: ?GenericError,
  referral_attributes_values_error?: ?GenericError,
  referral_filter_attributes_values_error?: ?GenericError,
  bonus_change_log_error?: ?GenericError,
  programsForJobs: ?(LinkJobsPrograms[]),
  programsForJobsError: ?GenericError,
  payout_transaction_history_error?: ?GenericError,
  payout_transaction_history?: ?TransactionHistory,
  time_sheet_data: ?Task,
  payment_balance_error?: ?GenericError,
  referral_agency_links: ?ReferralLink,
};

const initialState = {
  current_state: ReferralCurrentState.IDLE,
  referral_programs: null,
  referral_programs_error: null,
  referral_details: null,
  referral_details_error: null,
  save_referral_error: null,
  referral_tracking_details: null,
  configuration: null,
  referral_leaderboard: null,
  referral_leaderboard_error: null,
  referral_agents: null,
  referral_agents_error: null,
  referral_agency: null,
  referral_agency_error: null,
  referral_payouts: null,
  referral_attributes_error: null,
  referral_selected_attributes_error: null,
  referral_attributes_values_error: null,
  referral_filter_attributes_values_error: null,
  bonus_change_log_error: null,
  programsForJobs: null,
  programsForJobsError: null,
  payout_transaction_history_error: null,
  payout_transaction_history: null,
  time_sheet_data: null,
  referral_agency_links: null,
};

export default (
  state: ReferralState = initialState,
  action:
    | ReferralJobsActions
    | ReferralDashboardActions
    | ReferralTrackingActions
    | ReferralPaymentActions
    | ReferralProgramActions,
): ReferralState => {
  switch (action.type) {
    case PROGRAMS: {
      return {
        ...state,
        referral_programs: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };
    }

    case PROGRAMS_LOADING:
      return {
        ...state,
        current_state: ReferralCurrentState.FETCHING_REFERRAL_PROGRAM,
      };

    case PROGRAMS_ERROR:
      return {
        ...state,
        referral_programs_error: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };

    case PROGRAM_DETAILS_LOADING:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          referral_details_error: null,
        },
        current_state: ReferralCurrentState.FETCHING_REFERRAL_DETAILS,
      };
    case PROGRAM_DETAILS:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          referral_details: action.isReferralsJobListEnabled
            ? getJobListReferralDetails(action.payload)
            : getReferralDetails(action.payload),
          referral_details_error: null,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case PROGRAM_DETAILS_ERROR:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          referral_details_error: action.payload,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case SAVE_PROGRAM_DETAILS_LOADING:
      return {
        ...state,
        current_state: ReferralCurrentState.SAVING_REFERRAL_DETAILS,
      };
    case SAVE_PROGRAM_DETAILS:
      return {
        ...state,
        current_state: ReferralCurrentState.IDLE,
      };
    case SAVE_PROGRAM_DETAILS_ERROR:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          save_referral_error: action.payload,
        },
        current_state: ReferralCurrentState.IDLE,
      };

    case RECEIVE_JOBS_LOADING:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          jobs: null,
          jobs_error: null,
        },
        current_state: ReferralCurrentState.FETCHING_JOBS,
      };
    case RECEIVE_JOBS:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          jobs: action.payload,
          jobs_error: null,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case RECEIVE_JOBS_ERROR:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          jobs: null,
          jobs_error: action.payload,
        },
        current_state: ReferralCurrentState.IDLE,
      };

    case BRANDS_LOADING:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          brands_error: null,
        },
        current_state: ReferralCurrentState.FETCHING_BRANDS,
      };
    case BRANDS:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          brands: action.payload,
          brands_error: null,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case BRANDS_ERROR:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          brands_error: action.payload,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case QUESTIONNAIRE_LOADING:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          questionnaire_error: null,
        },
        current_state: ReferralCurrentState.FETCHING_QUESTIONNAIRE,
      };
    case QUESTIONNAIRE:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          questionnaire: action.payload,
          questionnaire_error: null,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case QUESTIONNAIRE_ERROR:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          questionnaire_error: action.payload,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case REFERRAL_TRACKING:
      return {
        ...state,
        referral_tracking_details: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };
    case REFERRAL_TRACKING_LOADING:
      return {
        ...state,
        current_state: ReferralCurrentState.FETCHING_REFERRAL_TRACKING_DETAILS,
      };
    case REFERRAL_TRACKING_ERROR:
      return {
        ...state,
        referral_tracking_details_error: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };
    case REFERRAL_PAYOUTS:
      return {
        ...state,
        referral_payouts: {
          ...state.referral_payouts,
          payouts: action.payload,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case REFERRAL_PAYOUTS_LOADING:
      return {
        ...state,
        current_state: ReferralCurrentState.FETCHING_REFERRAL_PAYOUTS,
      };
    case REFERRAL_PAYOUTS_ERROR:
      return {
        ...state,
        referral_payouts: {
          ...state.referral_payouts,
          payouts_error: action.payload,
        },
        current_state: ReferralCurrentState.IDLE,
      };

    case REFERRAL_LEADERBOARD:
      return {
        ...state,
        referral_leaderboard: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };
    case REFERRAL_LEADERBOARD_LOADING:
      return {
        ...state,
        current_state: ReferralCurrentState.FETCHING_LEADERBOARD,
      };
    case REFERRAL_LEADERBOARD_ERROR:
      return {
        ...state,
        referral_leaderboard_error: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };

    case REFERRAL_AGENCY:
      return {
        ...state,
        referral_agency: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };
    case REFERRAL_AGENCY_LOADING:
      return {
        ...state,
        current_state: ReferralCurrentState.FETCHING_AGENCY,
      };
    case REFERRAL_AGENCY_ERROR:
      return {
        ...state,
        referral_agency_error: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };

    case REFERRAL_AGENT:
      return {
        ...state,
        referral_agents: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };
    case REFERRAL_AGENT_LOADING:
      return {
        ...state,
        current_state: ReferralCurrentState.FETCHING_AGENTS,
      };
    case REFERRAL_AGENT_ERROR:
      return {
        ...state,
        referral_agents_error: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };
    case RECEIVE_ATTRIBUTES_LOADING:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          referral_attributes_error: null,
        },
        current_state: ReferralCurrentState.FETCHING_REFERRAL_ATTRIBUTES,
      };
    case RECEIVE_ATTRIBUTES:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          referral_attributes: action.payload,
          referral_attributes_error: null,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case RECEIVE_ATTRIBUTES_ERROR:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          referral_attributes_error: action.payload,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case RECEIVE_CONFIGURED_ATTRIBUTES_LOADING:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          referral_selected_attributes_error: null,
        },
        current_state:
          ReferralCurrentState.FETCHING_REFERRAL_CONFIGURED_ATTRIBUTES,
      };
    case RECEIVE_CONFIGURED_ATTRIBUTES:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          referral_selected_attributes: action.payload,
          referral_selected_attributes_error: null,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case RECEIVE_CONFIGURED_ATTRIBUTES_ERROR:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          referral_selected_attributes_error: action.payload,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case RECEIVE_ATTRIBUTES_VALUES_LOADING:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          referral_attributes_values_error: null,
        },
        current_state: ReferralCurrentState.FETCHING_REFERRAL_ATTRIBUTES_VALUES,
      };
    case RECEIVE_ATTRIBUTES_VALUES:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          referral_attributes_values: action.payload,
          referral_attributes_values_error: null,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case RECEIVE_SEARCHED_ATTRIBUTES_VALUES:
      const defautAttributesValue =
        state.configuration?.referral_attributes_values || [];
      const updateAttributeValues = defautAttributesValue.map((attribute) => {
        return attribute.id === action.payload.id ? action.payload : attribute;
      });
      return {
        ...state,
        configuration: {
          ...state.configuration,
          referral_attributes_values: updateAttributeValues,
          referral_attributes_values_error: null,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case RECEIVE_ATTRIBUTES_VALUES_ERROR:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          referral_attributes_values_error: action.payload,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case RECEIVE_FILTER_ATTRIBUTES_VALUES_LOADING:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          referral_filter_attributes_values_error: null,
        },
        current_state:
          ReferralCurrentState.FETCHING_REFERRAL_FILTER_ATTRIBUTES_VALUES,
      };
    case RECEIVE_FILTER_ATTRIBUTES_VALUES:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          referral_filter_attributes_values: action.payload,
          referral_filter_attributes_values_error: null,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case RECEIVE_FILTER_ATTRIBUTES_VALUES_ERROR:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          referral_filter_attributes_values_error: action.payload,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case RECEIVE_BONUS_CHANGE_LOG_LOADING:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          bonus_change_log_error: null,
        },
        current_state: ReferralCurrentState.FETCHING_BONUS_CHANGE_LOG,
      };
    case RECEIVE_BONUS_CHANGE_LOG:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          bonus_change_log: action.payload,
          bonus_change_log_error: null,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case RECEIVE_BONUS_CHANGE_LOG_ERROR:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          bonus_change_log_error: action.payload,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case FETCH_PROGRAMS_FOR_JOBS:
      return {
        ...state,
        programsForJobs: action.payload,
        programsForJobsError: null,
      };
    case REFERRAL_LINK_JOBS_PROGRAMS_ERROR:
      return {
        ...state,
        programsForJobsError: action.payload,
      };
    case RECEIVE_TRANSACTION_HISTORY_LOADING:
      return {
        ...state,
        current_state: ReferralCurrentState.FETCHING_TRANSACTION_HISTORY,
      };
    case RECEIVE_TRANSACTION_HISTORY:
      return {
        ...state,
        payout_transaction_history: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };
    case RECEIVE_TRANSACTION_HISTORY_ERROR:
      return {
        ...state,
        payout_transaction_history_error: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };
    case RECEIVE_TIME_SHEET_DATA:
      return {...state, time_sheet_data: action.payload};

    case RECEIVE_PAYMENT_BALANCE_LOADING:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          payment_balance_error: null,
        },
        current_state: ReferralCurrentState.FETCHING_PAYMENT_BALANCE,
      };
    case RECEIVE_PAYMENT_BALANCE:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          payment_balance: action.payload,
          payment_balance_error: null,
        },
        current_state: ReferralCurrentState.IDLE,
      };
    case RECEIVE_PAYMENT_BALANCE_ERROR:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          payment_balance_error: action.payload,
        },
      };

    case PROGRAM_AGENCY_LINKS:
      return {
        ...state,
        referral_agency_links: action.payload,
        current_state: ReferralCurrentState.IDLE,
      };
    default:
      return state;
  }
};

const getReferralDetails = (
  referralDetails?: ?ReferralDetails,
): ?ReferralDetails => {
  if (referralDetails?.stage === 'setup') {
    referralDetails.stage = 'settings';
    if (!referralDetails.is_default_program) {
      referralDetails.stage = 'questionnaire';
    }
    return referralDetails;
  }

  if (referralDetails?.stage === 'settings') {
    referralDetails.stage = 'questionnaire';
    return referralDetails;
  }
  if (referralDetails?.stage === 'questionnaire') {
    referralDetails.stage = 'user_access';
    return referralDetails;
  }
  if (referralDetails?.stage === 'user_access') {
    referralDetails.stage = 'completed';
    return referralDetails;
  }
  return referralDetails;
};

const getJobListReferralDetails = (
  referralDetails?: ?ReferralDetails,
): ?ReferralDetails => {
  if (referralDetails?.stage === 'setup') {
    referralDetails.stage = 'settings';
    if (!referralDetails.is_default_program) {
      referralDetails.stage = 'questionnaire';
    }
    return referralDetails;
  }
  if (referralDetails?.stage === 'settings') {
    referralDetails.stage = 'questionnaire';
    if (!referralDetails.is_default_program) {
      referralDetails.stage = 'jobs';
    }
    return referralDetails;
  }
  if (referralDetails?.stage === 'questionnaire') {
    referralDetails.stage = 'user_access';
    return referralDetails;
  }
  if (referralDetails?.stage === 'user_access') {
    referralDetails.stage = 'jobs';
    if (!referralDetails.is_default_program) {
      referralDetails.stage = 'settings';
    }
    return referralDetails;
  }
  if (referralDetails?.stage === 'jobs') {
    referralDetails.stage = 'completed';
    return referralDetails;
  }
  return referralDetails;
};
