// @flow strict-local

import pick from 'lodash/pick';
import endOfDay from 'date-fns/endOfDay';
import endOfYear from 'date-fns/endOfYear';
import startOfYear from 'date-fns/startOfYear';
import endOfQuarter from 'date-fns/endOfQuarter';
import startOfQuarter from 'date-fns/startOfQuarter';
import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import subYears from 'date-fns/subYears';
import subQuarters from 'date-fns/subQuarters';
import subMonths from 'date-fns/subMonths';
import sub, {type Duration} from 'date-fns/sub';

import logger from 'src/utils/logger';


export type Range = {
  startDate: string,
  endDate: string,
};

const getStartDate = (timeAgo: Duration) =>
  sub(endOfDay(new Date()), timeAgo).toISOString();

type DateRangeQuery = {
  time_ago?: string,
  roi_time_frame?: TimeFrame,
  start_date?: string,
  end_date?: string,
  ...
};

// TODO (kyle): change this name to `resolveDateRangeQuery`
export function resolveDateRange<T: DateRangeQuery>(
  range: T,
  pickProps?: ?(string[]),
  defaultTimeAgo?: Duration = {days: 90},
): T & {start_date: string, end_date: string} {
  let query = pickProps ? pick(range, pickProps) : range;

  if (range.time_ago) {
    const timeAgo = JSON.parse(range.time_ago);
    const start_date = getStartDate(timeAgo);

    query = {
      ...query,
      start_date,
      end_date: endOfDay(new Date()).toISOString(),
    };
  } else if (range.start_date && range.end_date) {
    query = {
      ...query,
      ...pick(range, 'start_date', 'end_date'),
    };
  } else {
    query = {
      ...query,
      start_date: getStartDate(defaultTimeAgo),
      end_date: endOfDay(new Date()).toISOString(),
    };
  }

  return query;
}

export function resolveRoiDateRange<T: DateRangeQuery>(
  range: T,
  pickProps?: ?(string[]),
  defaultTimeAgo?: Duration = {days: 90},
): T & {start_date: string, end_date: string} {
  let query = resolveDateRange(range, pickProps, defaultTimeAgo);
  if (range.roi_time_frame) {
    query = {
      ...query,
      ...resolveTimeFrame(range.roi_time_frame),
    };
  }
  // $FlowFixMe[incompatible-return] not sure how to fix this
  // $FlowFixMe[prop-missing] not sure how to fix this
  return query;
}

export type TimeFrame =
  | 'this_year'
  | 'last_year'
  | 'this_quarter'
  | 'last_quarter'
  | 'this_month'
  | 'last_month';

export const defaultTimeFrame: 'this_year' = 'this_year';
export const timeFrames: Set<TimeFrame> = new Set([
  'this_year',
  'last_year',
  'this_quarter',
  'last_quarter',
  'this_month',
  'last_month',
]);

export function parseTimeFrame(
  timeFrame: string = defaultTimeFrame,
): TimeFrame {
  let result = timeFrame;
  // $FlowFixMe[incompatible-call] this is a valid check
  if (!timeFrames.has(timeFrame)) {
    logger.warning(`Invalid timeframe ${timeFrame}`);
    result = defaultTimeFrame;
  }
  // $FlowFixMe[incompatible-return] result is guaranteed to be a TimeFrame
  return result;
}

export function resolveTimeFrame(timeFrame: TimeFrame = defaultTimeFrame): {
  start_date: string,
  end_date: string,
} {
  let currentDate = new Date();

  let startDate;
  let endDate;

  switch (timeFrame) {
    case 'last_year':
      currentDate = subYears(currentDate, 1);
    // falls through
    case 'this_year':
      startDate = startOfYear(currentDate);
      endDate = endOfYear(currentDate);
      break;
    case 'last_quarter':
      currentDate = subQuarters(currentDate, 1);
    // falls through
    case 'this_quarter':
      startDate = startOfQuarter(currentDate);
      endDate = endOfQuarter(currentDate);
      break;
    case 'last_month':
      currentDate = subMonths(currentDate, 1);
    // falls through
    case 'this_month':
      startDate = startOfMonth(currentDate);
      endDate = endOfMonth(currentDate);
      break;
    default:
      startDate = startOfYear(currentDate);
      endDate = endOfYear(currentDate);
  }

  return {
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString(),
  };
}
