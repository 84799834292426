// @flow strict

import * as React from 'react';

import classify from 'src/utils/classify';

import css from './loading.css';


type Props = {
  className?: string,
  animationDelay?: number,
};

export class ClassLoading extends React.PureComponent<
  Props,
  {
    countingDown: boolean,
  },
> {
  static defaultProps: {animationDelay: number} = {
    animationDelay: 400,
  };

  state: {countingDown: boolean} = {
    countingDown: true,
  };
  timeout: TimeoutID;

  componentDidMount() {
    // Don't show spinner before a small delay (because the content
    // might actually render within that delay)
    this.timeout = setTimeout(
      () => this.setState({countingDown: false}),
      this.props.animationDelay,
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render(): null | React.Element<'div'> {
    if ((this.props.animationDelay ?? 0) > 0 && this.state.countingDown) {
      return null;
    }

    return <div className={classify(css.loading, this.props.className)} />;
  }
}

export const Loading = ({
  animationDelay = 400,
  className,
}: Props): React.Node => {
  const [isWaiting, setIsWaiting] = React.useState(true);
  const timeoutId = React.useRef<?TimeoutID>(null);

  React.useEffect(() => {
    timeoutId.current = setTimeout(() => {
      setIsWaiting(false);
      timeoutId.current = null;
    }, animationDelay);

    return () => {
      if (timeoutId.current != null) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return animationDelay > 0 && !isWaiting ? (
    <div className={classify(css.loading, className)} />
  ) : null;
};

// TODO (kyle): eventually don't make this the default
export default function LoadingPage({
  className,
  loadingClassName,
  animationDelay,
}: {
  className?: string,
  loadingClassName?: string,
  animationDelay?: number,
}): React.Element<'div'> {
  return (
    <div className={classify(className, css.container)}>
      <Loading className={loadingClassName} animationDelay={animationDelay} />
    </div>
  );
}
