// @flow

import type {Option, Options} from './types';

import * as React from 'react';
import {useDispatch} from 'react-redux';
import first from 'lodash/first';
import last from 'lodash/last';
import kebabCase from 'lodash/kebabCase';
import uniq from 'lodash/uniq';

import classify from 'src/utils/classify';
import logger from 'src/utils/logger';

import {
  useFilteredOptions,
  useTypeahead,
  useSelect,
  useSenseTypeahead,
  useTokenInput,
  getDefaultState,
  reduceDefault,
  reduceWithSearchTerm,
  type SenseTypeaheadProps,
} from './hooks';
import {showGenericError} from 'src/action-creators/modal';

import {DEFAULT_LIMIT_VALUE} from './constants.js';

import ArrowDownIcon from 'src/images/arrow-down.svg';
import CancelIcon from 'src/images/close-x-thick.svg';
import SearchIcon from 'src/images/search-icon.svg';

import css from './style.css';
import baseCss from './base.css';
import legacyCss from './legacy.css';
import borderlessCss from './borderless.css';

/**
 * TODO
 * - fix all global .Select styles
 * - variable height for token-list-input?
 * - variable picker dropdown
 * - limit height?
 * - error box?
 * - make a new messaging contact input
 * - actions?
 * - make it work with the variable selector
 * - replicate old token-list-input
 */

type AutocompleteProps = {
  value: string,
  options: string[],
  onChange: (string) => mixed,
  onSelect?: (string) => mixed,
  name?: string,
  emptyOption?: string,
  clearable?: boolean,
  prefixLabel?: React.Node,
  placeholder?: string,
  error?: mixed,
  baseClassNames?: InputAndOptionsClassNames,
  classNames?: InputAndOptionsClassNames,
  clearOnSelect?: boolean,
};

/**
 * a text input that provides suggestions
 *
 * Useful for situations where the user is not restricted to a short list
 * of options but may want hints or reminders of useful values (e.g. search fields).
 *
 * @param  props  the react props
 * @param  props.value  the controlled value of the input
 * @param  props.options  all possible suggestions that will be filtered by the user
 * @param  props.onChange  a handler that is called whenever the value changes
 * @param  props.onSelect  a handler that is called whenever a value from suggestions is selected
 * @param  props.name  the name of the text input (useful if its in a form)
 */
export function Autocomplete({
  value,
  options,
  onChange,
  onSelect,
  name,
  clearable,
  prefixLabel,
  placeholder,
  error,
  baseClassNames,
  classNames,
  emptyOption,
  clearOnSelect = false,
}: AutocompleteProps): React.Node {
  const handleSelect = (option: string) => {
    if (clearOnSelect) {
      // clearing the input on select
      onChange('');
    } else {
      onChange(option);
    }
    onSelect && onSelect(option);
    // $FlowIssue
    inputProps.ref.current?.blur();
  };

  const [state, dispatch] = React.useReducer(
    reduceDefault,
    null,
    getDefaultState,
  );
  const {focused, selectedIndex} = state;
  const excludedValues = React.useMemo(() => [value], [value]);

  const [filteredOptions] = useFilteredOptions({
    searchTerm: value,
    options,
    excludedValues,
    searchOptionsBy: (option, searchTerm) => option.includes(searchTerm),
  });
  const [inputProps, handleContainerMouseDown] = useTypeahead({
    state,
    dispatch,
    options: filteredOptions,
    onAddOption: handleSelect,
    isOptionDisabled: (_) => false,
  });

  const isShowingEmptyOption =
    focused && filteredOptions.length === 0 && emptyOption;
  const isShowingOptions = focused && filteredOptions.length > 0;

  const showClearButton = clearable && value && !focused;

  return (
    <InputAndOptions
      baseClassNames={baseClassNames}
      classNames={classNames}
      error={error}
      options={
        filteredOptions.length > 0 ? filteredOptions : [emptyOption ?? '']
      }
      selectedIndex={selectedIndex}
      onSelect={isShowingEmptyOption ? () => false : handleSelect}
      onContainerMouseDown={handleContainerMouseDown}
      inputProps={{
        name,
        value,
        onChange: (event) => onChange(event.currentTarget.value),
        placeholder,
        ...inputProps,
      }}
      isFocused={focused}
      isShowingOptions={isShowingOptions || isShowingEmptyOption}
      prefixLabel={
        // $FlowFixMe[sketchy-number-and]
        prefixLabel && <div className={css.prefixLabel}>{prefixLabel}</div>
      }
      suffixLabel={
        showClearButton && (
          <div className={css.clearButton} onMouseDown={() => onChange('')}>
            <CancelIcon />
          </div>
        )
      }
      resolveLabel={(value) => value}
      resolveKey={(value) => value}
      isArbitrary={(_value) => false}
    />
  );

  // TODO (kyle): remove this eventually?
  /*
  return (
    <div className={css.container}>
      <div
        className={css.inputContainer}
        onMouseDown={handleContainerMouseDown}
      >
        <div
          className={classify(css.box, {
            [css.focused]: focused,
            [css.withOptions]: isShowingOptions,
            [css.withPrefix]: prefixLabel,
            [css.withSuffix]: showSuffix,
          })}
        >
          {prefixLabel && <div className={css.prefixLabel}>{prefixLabel}</div>}
          <input
            type="text"
            className={css.selectorSearch}
            name={name}
            value={value}
            onChange={event => onChange(event.currentTarget.value)}
            placeholder={placeholder}
            {...inputProps}
          />
          {showClearButton && (
            <div
              className={classify(css.suffixLabel, css.clearButton)}
              onMouseDown={() => onChange('')}
            >
              <CancelIcon />
            </div>
          )}
        </div>
        {isShowingOptions && (
          <Suggestions
            options={filteredOptions}
            selectedIndex={selectedIndex}
            onOptionClick={handleSelect}
          />
        )}
      </div>
    </div>
  );
      */
}

export function SearchInput(props: AutocompleteProps): React.Node {
  return <Autocomplete prefixLabel={<SearchIcon />} {...props} />;
}

export type TokenListInputClassNames = $ReadOnly<{
  ...InputAndOptionsClassNames,
  outerContainer?: ?string,
  ...
}>;

// TODO (kyle): we need to split this out into two components,
// one that requires the resolvers and one that expects an
// Option type.
export type TokenListInputProps<V> = {
  values: V[],
  onChange: (V[]) => mixed,
  inputProps?: React.ElementProps<'input'>,
  name?: string,
  limit?: number,
  showValuesOutside?: mixed,
  error?: mixed,
  isExpandable?: mixed,
  isHeightLimited?: mixed,
  baseClassNames?: InputAndOptionsClassNames,
  classNames?: TokenListInputClassNames,
  enableListPasting?: boolean,
  components?: SuggestionsComponents<V> & {
    Token?: React.ComponentType<TokenProps<V>>,
    ...
  },
  prefixLabel?: React.Node,
  placeholder?: ?string,
  hasLabel?: boolean,
  autofocus?: boolean,
  ...SenseTypeaheadProps<V>,
  resolveLabel?: (V) => string | React.Element<'div'>,
  resolveKey?: (V) => string,
  makeValue?: (string) => V,
  disabled?: mixed,
};

/**
 * an input that allows for a variable number of unique values
 *
 * Useful for allowing the user to build lists of items.
 *
 * @param  props.values  the controlled list of values
 * @param  props.onChange  the handler that is fired when the user tries to change the values
 * @param  props.name  the name of the input (useful if it is part of a form)
 * @param  props.limit  the maximum number of item the user can add
 * @param  props.TokenComponent  a custom component used to render each token within the input
 * @param  props.showValuesOutside  if true, clickable tokens will show up above and outside the input.
 *                                  useful when the list can get very large.
 *
 * @param  props.options  total list of allowed values for this field. will be used to provide suggestions.
 * @param  props.searchable  whether or not to filter the list of options when the user types in the input
 * @param  props.allowArbitraryValues  whether or not the user can specify their own custom values
 * @param  props.validateArbitraryValue  a function that determines if a custom value is valid
 * @param  props.formatArbitraryOptionLabel  a function that customizes the custom value when it shows up as a suggestion
 * @param  props.searchOnEmptyString  whether or not to show suggestions when there is no text in the search input
 * @param  props.groupOptionsBy  a function that produces a category key for any given option. if specified, suggestions will be grouped into sections by category.
 * @param  props.sortOptionsBy  a comparison function that determines the sort order of suggestions. by default it sorts them alphabetically.
 */
export function TokenListInput<V>(
  props: TokenListInputProps<V>,
): React.Element<'div'> {
  const {
    values,
    onChange,
    name,
    limit = Infinity,
    searchable,
    showValuesOutside,
    error,
    isExpandable,
    isHeightLimited,
    enableListPasting,
    baseClassNames = legacyCss,
    classNames,
    prefixLabel,
    placeholder,
    hasLabel,
    components: {Token = DefaultToken, ...components} = {},
    autofocus,
    // $FlowFixMe[incompatible-use]
    resolveKey = (option) => option.value,
    // $FlowFixMe[incompatible-use]
    resolveLabel = (option) => option.label,
    // $FlowFixMe[incompatible-return]
    // $FlowFixMe[incompatible-use]
    makeValue = (string): V => ({value: string, label: string}),
    // $FlowFixMe[incompatible-use]
    isArbitrary = (option) => option.arbitrary,
    disabled,
  } = props;

  const dispatch = useDispatch();

  const handleAddOption = (option) => {
    onChange([...values, option]);
  };

  const excludedValues = values;
  const isLimited = values.length >= limit;

  const [
    {selectedIndex, searchTerm},
    filteredOptions,
    groupedOptions,
    inputProps,
    handleContainerMouseDown,
    handleSelect,
    {isSearching, focused, isShowingOptions},
  ] = useSenseTypeahead({
    onAddOption: handleAddOption,
    excludedValues,
    isLimited,
    ...props,
    resolveKey,
    resolveLabel,
  });

  const tokens: Array<React.Node> = useTokens<V>({
    values,
    onChange,
    resolveKey,
    resolveLabel,
    inputRef: inputProps.ref,
    enableListPasting,
    Token,
    disabled: Boolean(disabled),
  });

  return (
    <div className={classify(css.container, classNames?.outerContainer)}>
      {showValuesOutside ? (
        <div className={css.tokensOutside}>{tokens}</div>
      ) : null}
      <InputAndOptions
        resolveKey={resolveKey}
        resolveLabel={resolveLabel}
        isArbitrary={isArbitrary}
        baseClassNames={baseClassNames}
        classNames={classNames}
        components={components}
        error={error}
        isLimited={isLimited}
        isExpandable={isExpandable}
        isHeightLimited={isHeightLimited}
        // $FlowFixMe[incompatible-type] edge case that isn't used for the React.Element case. This is too risky to fix for a component that we will deprecate soon
        options={props.groupOptionsBy ? groupedOptions : filteredOptions}
        selectedIndex={searchable ? selectedIndex : null}
        onSelect={handleSelect}
        onContainerMouseDown={handleContainerMouseDown}
        autofocus={autofocus}
        inputProps={{
          ...props.inputProps,
          disabled,
          outerRef: props.inputProps?.ref,
          onKeyDown: (event) => {
            if (event.key === 'Backspace' && searchTerm === '') {
              event.preventDefault();
              onChange(values.slice(0, -1));
            } else {
              inputProps.onKeyDown(event);
            }
          },
          onPaste: (event: ClipboardEvent) => {
            if (!enableListPasting || !makeValue) {
              return;
            }
            event.preventDefault();
            const value = event.clipboardData?.getData('text');
            if (!value || !value.length) {
              return;
            }
            const parsedValues = value
              .split(/[\,\n]/)
              .reduce((acc, val) => {
                val = val.trim();
                if (
                  // value exists
                  val.length > 0 &&
                  // value not already in input
                  !values.map((value) => resolveKey(value)).includes(val) &&
                  // value not already in queue
                  !acc.includes(val)
                ) {
                  acc.push(val);
                }
                return acc;
              }, [])
              .map(makeValue);

            if (values.length + parsedValues.length <= DEFAULT_LIMIT_VALUE) {
              onChange([...values, ...parsedValues]);
            } else {
              dispatch(
                showGenericError({
                  title: 'Too many entries',
                  text: `Limit is ${DEFAULT_LIMIT_VALUE}`,
                }),
              );
            }
          },
          ...inputProps,
        }}
        isSearching={isSearching}
        isFocused={focused}
        isShowingOptions={isShowingOptions}
        prefixLabel={prefixLabel}
      >
        {!showValuesOutside && tokens}
        {placeholder && (
          <Placeholder
            className={baseClassNames.placeholder}
            placeholder={placeholder}
            isLabel={hasLabel}
            isHidden={isSearching || tokens.length > 0}
          />
        )}
      </InputAndOptions>
      {values.map((option) => (
        <input
          type="hidden"
          name={name}
          key={resolveKey(option)}
          value={resolveKey(option)}
        />
      ))}
    </div>
  );

  // NOTE (kyle): remove this?
  /*
  return (
    <div className={css.container}>
      {showValuesOutside && <div className={css.tokensOutside}>{tokens}</div>}
      <div
        className={css.inputContainer}
        onMouseDown={handleContainerMouseDown}
      >
        <div
          className={classify(css.box, {
            [css.isSearching]: isSearching,
            [css.focused]: focused,
            [css.withOptions]: isShowingOptions,
          })}
        >
          {!showValuesOutside && tokens}
          <input
            type="text"
            className={css.selectorSearch}
            {...inputProps}
            onKeyDown={event => {
              if (event.key === 'Backspace' && searchTerm === '') {
                event.preventDefault();
                onChange(values.slice(0, -1));
              } else {
                inputProps.onKeyDown(event);
              }
            }}
          />
        </div>
        {isShowingOptions && (
          <Suggestions
            options={props.groupOptionsBy ? groupedOptions : filteredOptions}
            selectedIndex={searchable ? selectedIndex : null}
            onOptionClick={handleSelect}
          />
        )}
      </div>
      {values.map(option => (
        <input
          type="hidden"
          name={name}
          key={option.value}
          value={option.value}
        />
      ))}
    </div>
  );
      */
}

export type TokenProps<V> = {
  className: string,
  option?: V,
  onClick: ?(SyntheticEvent<Element>) => mixed,
  children: string | React.Element<'div'>,
};
function DefaultToken<V>({className, onClick, children}: TokenProps<V>) {
  return (
    <div className={classify(css.defaultToken, className)} onClick={onClick}>
      {children}
    </div>
  );
}

const stringResolver = (value) => value;

export type StringListInputProps = {
  ...TokenListInputProps<string>,
  values: string[],
  options?: string[],
  onChange: (string[]) => mixed,
};

export function StringListInput({
  values,
  options = [],
  onChange,
  ...props
}: StringListInputProps): React.Node {
  return (
    <TokenListInput
      {...props}
      values={values}
      options={options}
      onChange={onChange}
      resolveLabel={stringResolver}
      resolveKey={stringResolver}
      makeValue={stringResolver}
      makeArbitraryValue={stringResolver}
      isArbitrary={(value) => !options.includes(value)}
    />
  );
}

export type ValueListInputProps<V> = {
  ...TokenListInputProps<V>,
  type: () => V,
};

export function ValueListInput<V>({
  values,
  options,
  onChange,
  type,
  ...props
}: ValueListInputProps<V>): React.Node {
  const resolver = (value) => String(value);
  return (
    <TokenListInput
      {...props}
      values={values}
      options={options}
      onChange={onChange}
      resolveLabel={resolver}
      resolveKey={resolver}
      isArbitrary={(value) => !options.includes(value)}
      makeValue={type}
      makeArbitraryValue={type}
    />
  );
}

export type CustomTokenListInputProps = {
  values: string[],
  onChange: (string[]) => mixed,
  name?: string,
  components?: {
    Token?: React.ComponentType<TokenProps<string>>,
  },
  baseClassNames?: TokenListInputClassNames,
  classNames?: TokenListInputClassNames,
  placeholder?: ?string,
  enableListPasting?: boolean,
  disabled?: boolean,
  isExpandable?: boolean,
  inputProps?: React.ElementProps<'input'>,
};

export function CustomTokenListInput({
  values,
  onChange,
  name,
  placeholder,
  classNames,
  components: {Token = DefaultToken, ...components} = {},
  enableListPasting,
  disabled,
  inputProps,
  ...props
}: CustomTokenListInputProps): React.Node {
  const [state, dispatch, baseInputProps, handleContainerMouseDown] =
    useTokenInput({
      onAddValue(newValue) {
        onChange([...values, newValue.trim()]);
      },
      onBackspace() {
        onChange(values.slice(0, -1));
      },
    });

  const tokens = useTokens({
    values,
    onChange,
    inputRef: baseInputProps.ref,
    enableListPasting,
    disabled,
    resolveLabel: stringResolver,
    resolveKey: stringResolver,
    Token,
  });

  return (
    <div className={classify(css.container, classNames?.outerContainer)}>
      <BasicInput
        // $FlowFixMe[cannot-spread-indexer]
        inputProps={{
          ...inputProps,
          disabled,
          ...baseInputProps,
        }}
        isFocused={state.focused}
        onContainerMouseDown={handleContainerMouseDown}
        {...props}
      >
        {tokens}
        {!state.searchTerm &&
          !state.focused &&
          tokens.length === 0 &&
          placeholder && (
            <div className={css.selectorPlaceholder}>{placeholder}</div>
          )}
      </BasicInput>
      {values.map((option) => (
        <input type="hidden" name={name} key={option} value={option} />
      ))}
    </div>
  );
}

export const DefaultValueComponent = <V>({
  value,
  resolveLabel,
}: {
  value: V,
  resolveLabel: (V) => string | React.Element<'div'>,
}): React.Node => (
  <div className={css.selectorValue}>{resolveLabel(value)}</div>
);

export type SelectProps<V> = {
  value: ?V,
  onChange: (?V) => mixed,
  name?: string,
  clearable?: boolean,
  disabled?: boolean,
  autofocus?: boolean,
  placeholder?: string,
  hasLabel?: boolean,
  inputProps?: {
    onPaste?: (event: ClipboardEvent) => mixed,
    ...
  } & React.ElementProps<'input'>,
  optionsDirection?: OptionsDirection,
  classNames?: InputAndOptionsClassNames,
  baseClassNames?: InputAndOptionsClassNames,
  error?: mixed,
  errorText?: string,
  showAllOptions?: mixed,
  components?: SuggestionsComponents<V>,
  ...SenseTypeaheadProps<V>,
  resolveLabel?: (V) => string | React.Element<'div'>,
  resolveKey?: (V) => string,
};

/**
 * an input that allows selecting a value from a limited set of options.
 *
 * Very similar to the HTML `select` tag but with more bells and whistles.
 *
 * @param  props.value  the controlled value
 * @param  props.onChange  the handler that is fired when the user tries to change the value
 * @param  props.name  the name of the input (useful if it is part of a form)
 * @param  props.limit  the maximum number of item the user can add
 * @param  props.TokenComponent  a custom component used to render each token within the input
 *
 * @param  props.options  total list of allowed values for this field. will be used to provide suggestions.
 * @param  props.searchable  whether or not to filter the list of options when the user types in the input
 * @param  props.allowArbitraryValues  whether or not the user can specify their own custom values
 * @param  props.validateArbitraryValue  a function that determines if a custom value is valid
 * @param  props.formatArbitraryOptionLabel  a function that customizes the custom value when it shows up as a suggestion
 * @param  props.searchOnEmptyString  whether or not to show suggestions when there is no text in the search input
 * @param  props.groupOptionsBy  a function that produces a category key for any given option. if specified, suggestions will be grouped into sections by category.
 * @param  props.sortOptionsBy  a comparison function that determines the sort order of suggestions. by default it sorts them alphabetically.
 */
export function Select<V>(props: SelectProps<V>): React.Node {
  const handleAddOption = (option) => {
    onChange(option);
    // $FlowIssue
    inputProps.ref.current?.blur();
  };
  const {
    value,
    onChange,
    name,
    searchable,
    clearable,
    disabled,
    autofocus,
    placeholder,
    hasLabel,
    optionsDirection,
    baseClassNames = legacyCss,
    classNames,
    error,
    errorText,
    showAllOptions,
    components = {},
    // $FlowFixMe[incompatible-use]
    resolveKey = (option) => option.value,
    // $FlowFixMe[incompatible-use]
    resolveLabel = (option) => option.label,
  } = props;

  const {ValueComponent = DefaultValueComponent} = components;

  const [
    {selectedIndex},
    filteredOptions,
    groupedOptions,
    inputProps,
    handleContainerMouseDown,
    handleSelect,
    {isSearching, focused, isShowingOptions},
  ] = useSenseTypeahead({
    onAddOption: handleAddOption,
    ...props,
    resolveKey,
    resolveLabel,
  });

  const showClearButton = clearable && value && !disabled;
  const showChevron = !focused && !showClearButton && !disabled;

  // returns true when a value exists or can be determined using
  // resolveKey (which is used to fetch V typed values)
  const hasValue = (data: ?V): boolean => {
    if (data == null) {
      return false;
    }

    if (resolveKey(data) == null) {
      return false;
    }

    return true;
  };

  return (
    <InputAndOptions
      error={error}
      errorText={errorText}
      baseClassNames={baseClassNames}
      classNames={classNames}
      options={props.groupOptionsBy ? groupedOptions : filteredOptions}
      optionsDirection={optionsDirection}
      showAllOptions={showAllOptions}
      selectedIndex={searchable ? selectedIndex : null}
      onSelect={handleSelect}
      onContainerMouseDown={handleContainerMouseDown}
      components={components}
      inputProps={{
        ...props.inputProps,
        outerRef: props.inputProps?.ref,
        disabled,
        ...inputProps,
      }}
      isSearching={isSearching}
      isFocused={focused}
      isShowingOptions={isShowingOptions}
      resolveLabel={resolveLabel}
      resolveKey={resolveKey}
      suffixLabel={
        showClearButton ? (
          <div className={css.clearButton} onMouseDown={() => onChange(null)}>
            <CancelIcon />
          </div>
        ) : (
          showChevron && (
            <div>
              <ArrowDownIcon
                className={classify(
                  css.chevron,
                  optionsDirection && css[optionsDirection],
                )}
              />
            </div>
          )
        )
      }
      autofocus={autofocus}
      resolveLabel={resolveLabel}
      resolveKey={resolveKey}
    >
      {!isSearching && value != null && (
        <ValueComponent value={value} resolveLabel={resolveLabel} />
      )}
      {placeholder && (
        <Placeholder
          className={baseClassNames.placeholder}
          placeholder={placeholder}
          isLabel={hasLabel}
          isHidden={isSearching || hasValue(value)}
        />
      )}
      <input
        type="hidden"
        name={name}
        value={value != null ? resolveKey(value) : null}
      />
    </InputAndOptions>
  );

  // TODO (kyle): eventually remove
  /*
  return (
    <div className={css.container}>
      <div
        className={css.inputContainer}
        onMouseDown={handleContainerMouseDown}
      >
        <div
          className={classify(css.box, {
            [css.inputDisabled]: disabled,
            [css.isSearching]: isSearching,
            [css.focused]: focused,
            [css.withOptions]: isShowingOptions,
            [css.withSuffix]: showSuffix,
          })}
        >
          {!isSearching && value && (
            <div className={css.selectorValue}>{value.label}</div>
          )}
          {!isSearching && !value && placeholder && (
            <div className={css.selectorPlaceholder}>{placeholder}</div>
          )}
          <input
            type="text"
            className={css.selectorSearch}
            disabled={disabled}
            {...inputProps}
            {...props.inputProps}
          />
          {showClearButton && (
            <div
              className={classify(css.suffixLabel, css.clearButton)}
              onMouseDown={() => onChange(null)}
            >
              <CancelIcon />
            </div>
          )}
          {showChevron && (
            <div className={classify(css.suffixLabel)}>
              <ArrowDownIcon className={css[optionsDirection]} />
            </div>
          )}
        </div>
        {isShowingOptions && (
          <Suggestions
            options={props.groupOptionsBy ? groupedOptions : filteredOptions}
            selectedIndex={searchable ? selectedIndex : null}
            onOptionClick={handleSelect}
          />
        )}
      </div>
      <input type="hidden" name={name} value={value?.value} />
    </div>
  );
      */
}

type CommonMenuProps<V, O> = {
  name?: string,
  value: ?V,
  baseClassNames?: InputAndOptionsClassNames,
  classNames?: InputAndOptionsClassNames,
  disabled?: boolean,
  options: O[],
  optionsDirection?: OptionsDirection,
  placeholder?: string,
};

// NOTE (kyle): this is not a great pattern. it works for this case,
// but it will break down if we have to expand the union to include other
// prop constraints.
export type MenuProps<V, O> =
  | {
      ...CommonMenuProps<V, O>,
      clearable?: false,
      onChange: (Option) => mixed,
    }
  | {
      ...CommonMenuProps<V, O>,
      clearable: true,
      onChange: (?Option) => mixed,
    };

export function Menu<V, O: {value: V, label: string, ...}>(
  props: MenuProps<V, O>,
): React.Node {
  const handleSelect = (option) => {
    onChange(option);
    setTimeout(() => {
      inputProps.ref.current?.blur();
    }, 0);
  };

  const {
    value,
    onChange,
    disabled,
    options,
    optionsDirection,
    name,
    placeholder,
    clearable,
    ...subProps
  } = props;
  const valueLabel = options.find((option) => option.value === value)?.label;

  const [{focused}, inputProps, handleContainerMouseDown] = useSelect({
    options,
  });

  const showClearButton = clearable && valueLabel && !disabled;
  const showChevron = !focused && !showClearButton && !disabled;

  return (
    <InputAndOptions
      {...subProps}
      optionsDirection={optionsDirection}
      options={options}
      onSelect={handleSelect}
      onContainerMouseDown={handleContainerMouseDown}
      inputProps={{
        disabled,
        name,
        placeholder,
        ...inputProps,
      }}
      isFocused={focused}
      isShowingOptions={focused}
      suffixLabel={
        props.clearable && showClearButton ? (
          <div
            className={css.clearButton}
            onMouseDown={() => props.onChange(null)}
          >
            <CancelIcon />
          </div>
        ) : (
          showChevron && (
            <div>
              <ArrowDownIcon
                className={classify(
                  css.chevron,
                  optionsDirection && css[optionsDirection],
                )}
              />
            </div>
          )
        )
      }
    >
      {valueLabel ? (
        <div className={css.selectorValue}>{String(valueLabel)}</div>
      ) : null}
    </InputAndOptions>
  );
}

type SuggestionsClassNames = $ReadOnly<{
  options?: ?string,
  down?: ?string,
  up?: ?string,
  showAll?: ?string,
  option?: ?string,
  selected?: ?string,
  optionGroup?: ?string,
  optionGroupHeading?: ?string,
  ...
}>;

type SuggestionsComponents<V> = {
  Suggestion?: React.ComponentType<SuggestionProps<V>>,
  ValueComponent?: React.ComponentType<{
    value: V,
    resolveLabel: (V) => string | React.Element<'div'>,
  }>,
  ...
};

type OptionsDirection = 'up' | 'down';

export type SuggestionProps<V> = {
  className: string,
  onClick: (SyntheticEvent<>) => mixed,
  option: V,
  isArbitrary: boolean,
  isSelected: boolean,
  resolveLabel: (V) => string | React.Element<'div'>,
};

type CommonSuggestionsProps<V> = {
  selectedIndex: ?number,
  baseClassNames: SuggestionsClassNames,
  classNames?: SuggestionsClassNames,
  onOptionClick: (V, SyntheticEvent<>) => mixed,
  // TODO (kyle): not sure if we need this
  suggestionProps?: SuggestionProps<V>,
  components?: SuggestionsComponents<V>,
  resolveKey: (V) => string,
  resolveLabel: (V) => string | React.Element<'div'>,
  isArbitrary: (V) => boolean,
};

function SuggestionsGroup<V>({
  baseClassNames,
  classNames,
  options,
  selectedIndex,
  onOptionClick,
  suggestionProps,
  components: {Suggestion = DefaultSuggestion} = {},
  resolveKey,
  resolveLabel,
  isArbitrary,
}: {
  ...CommonSuggestionsProps<V>,
  options: V[],
}) {
  return options.map((option, index) => {
    const isSelected = index === selectedIndex;
    return (
      // $FlowFixMe[escaped-generic]
      <Suggestion
        className={classify(
          baseClassNames.option,
          classNames?.option,
          isSelected && baseClassNames.selected,
        )}
        key={resolveKey(option)}
        onClick={(event) => onOptionClick(option, event)}
        option={option}
        isSelected={isSelected}
        resolveLabel={resolveLabel}
        isArbitrary={isArbitrary(option)}
        {...suggestionProps}
      />
    );
  });
}

export function Suggestions<V>({
  options,
  baseClassNames,
  classNames,
  direction,
  showAll,
  ...props
}: {
  ...CommonSuggestionsProps<V>,
  options: V[] | Map<string, V[]>,
  direction: OptionsDirection,
  showAll?: mixed,
}): React.Element<'div'> {
  return (
    <div
      className={classify(
        baseClassNames.options,
        baseClassNames[direction],
        classNames?.options,
        showAll && baseClassNames.showAll,
      )}
    >
      {options instanceof Map ? (
        <GroupedSuggestions
          {...props}
          options={options}
          baseClassNames={baseClassNames}
          classNames={classNames}
        />
      ) : (
        <SuggestionsGroup
          {...props}
          options={options}
          baseClassNames={baseClassNames}
          classNames={classNames}
        />
      )}
    </div>
  );
}

function GroupedSuggestions<V>({
  baseClassNames,
  options,
  selectedIndex,
  ...props
}: {
  ...CommonSuggestionsProps<V>,
  options: Map<string, V[]>,
}) {
  let runningTotal = 0;
  return Array.from(options).map(([groupName, group]) => {
    const section = (
      <section className={baseClassNames.optionGroup} key={groupName}>
        <h4 className={baseClassNames.optionGroupHeading}>{groupName}</h4>
        <SuggestionsGroup
          {...props}
          baseClassNames={baseClassNames}
          selectedIndex={
            selectedIndex != null ? selectedIndex - runningTotal : null
          }
          options={group}
        />
      </section>
    );
    runningTotal += group.length;
    return section;
  });
}

export const DefaultSuggestion = <V>({
  option,
  isSelected: _,
  resolveLabel,
  isArbitrary,
  ...props
}: SuggestionProps<V>): React.Node => {
  const label = resolveLabel(option);

  return (
    <div
      {...props}
      data-qa-id={`suggestion-${kebabCase(
        typeof label === 'string' ? label : String(option),
      )}`}
    >
      {isArbitrary && typeof label === 'string' ? `Create "${label}"` : label}
    </div>
  );
};

export type InputAndOptionsClassNames = $ReadOnly<{
  ...SuggestionsClassNames,
  container?: ?string,
  placeholder?: ?string,
  box?: ?string,
  up?: ?string,
  down?: ?string,
  inputDisabled?: ?string,
  isSearching?: ?string,
  focused?: ?string,
  withOptions?: ?string,
  withPrefix?: ?string,
  withSuffix?: ?string,
  withError?: ?string,
  withVerticalStretch?: ?string,
  withHeightLimit?: ?string,
  ...
}>;

type RequiredInputAndOptionsProps<V> = {
  options: V[] | Map<string, V[]>,
  onSelect: (V) => mixed,
  onContainerMouseDown: (SyntheticMouseEvent<HTMLInputElement>) => mixed,
  inputProps: {disabled?: mixed, ...} & React.ElementProps<'input'>,
};

type OptionalInputAndOptionsProps<V> = {
  children?: React.Node,
  baseClassNames?: InputAndOptionsClassNames,
  classNames?: InputAndOptionsClassNames,
  components?: SuggestionsComponents<V>,
  selectedIndex?: ?number,
  onPaste?: (event: ClipboardEvent) => mixed,
  isSearching?: mixed,
  isFocused?: mixed,
  isShowingOptions?: mixed,
  isLimited?: mixed,
  isExpandable?: mixed,
  isHeightLimited?: mixed,
  prefixLabel?: React.Node,
  suffixLabel?: React.Node,
  optionsDirection?: OptionsDirection,
  error?: mixed,
  showAllOptions?: mixed,
  autofocus?: mixed,
  resolveKey?: (V) => string,
  resolveLabel?: (V) => string | React.Element<'div'>,
  isArbitrary?: (V) => boolean,
  errorText?: string,
};

type InputAndOptionsProps<V> = {
  ...RequiredInputAndOptionsProps<V>,
  ...OptionalInputAndOptionsProps<V>,
};

export function InputAndOptions<V = Option>({
  baseClassNames = legacyCss,
  classNames = {},
  components,
  options,
  selectedIndex,
  onSelect,
  optionsDirection = 'down',
  showAllOptions,
  autofocus,
  // $FlowFixMe[incompatible-use]
  resolveKey = (option) => option.value,
  // $FlowFixMe[incompatible-use]
  resolveLabel = (option) => option.label,
  // $FlowFixMe[incompatible-use]
  isArbitrary = (option) => option.arbitrary,
  ...props
}: InputAndOptionsProps<V>): React.Node {
  if (Array.isArray(options)) {
    if (uniq(options.map(resolveKey)).length !== options.length) {
      logger.warn(
        `resolveKey must resolve a unique id for each item or search will throw`,
      );
    }
  }
  return (
    <BasicInput
      baseClassNames={baseClassNames}
      classNames={classNames}
      optionsDirection={optionsDirection}
      {...props}
      lowerContent={
        Boolean(props.isShowingOptions) && (
          <Suggestions
            baseClassNames={baseClassNames}
            classNames={classNames}
            components={components}
            direction={optionsDirection}
            options={options}
            selectedIndex={selectedIndex}
            onOptionClick={onSelect}
            showAll={showAllOptions}
            resolveKey={resolveKey}
            resolveLabel={resolveLabel}
            isArbitrary={isArbitrary}
          />
        )
      }
    />
  );
}

// TODO (kyle): put this inside InputAndOptions
function BasicInput({
  children,
  baseClassNames = legacyCss,
  classNames = {},
  onContainerMouseDown,
  inputProps,
  isSearching,
  isFocused,
  isLimited,
  isShowingOptions,
  isExpandable,
  isHeightLimited,
  prefixLabel,
  suffixLabel,
  optionsDirection = 'down',
  error,
  errorText,
  lowerContent,
  autofocus,
  autoComplete = 'no',
}: {
  onContainerMouseDown: (SyntheticMouseEvent<HTMLInputElement>) => mixed,
  inputProps: {disabled?: mixed, ...} & React.ElementProps<'input'>,
  children?: React.Node,
  baseClassNames?: InputAndOptionsClassNames,
  classNames?: InputAndOptionsClassNames,
  selectedIndex?: ?number,
  onPaste?: (event: ClipboardEvent) => mixed,
  isSearching?: mixed,
  isFocused?: mixed,
  isShowingOptions?: mixed,
  isLimited?: mixed,
  isExpandable?: mixed,
  isHeightLimited?: mixed,
  prefixLabel?: React.Node,
  suffixLabel?: React.Node,
  optionsDirection?: OptionsDirection,
  error?: mixed,
  errorText?: string,
  lowerContent?: React.Node,
  autofocus?: mixed,
  // 'no' is nonstandard but we probably want it because chrome needs it in order to disable
  // autocomplete, https://bugs.chromium.org/p/chromium/issues/detail?id=914451
  autoComplete?: 'on' | 'off' | 'no',
}) {
  if (!inputProps.type) {
    inputProps.type = 'text';
  }

  React.useLayoutEffect(() => {
    if (autofocus) {
      inputProps.ref.current?.focus();
    }
  }, []);

  const {outerRef, ...inputPropsRest} = inputProps;

  return (
    <div
      className={classify(baseClassNames.container, classNames.container)}
      onMouseDown={onContainerMouseDown}
    >
      <div
        className={classify(
          baseClassNames.box,
          baseClassNames[optionsDirection],
          classNames.box,
          classNames[optionsDirection],
          inputProps.disabled && baseClassNames.inputDisabled,
          inputProps.disabled && classNames.inputDisabled,
          isSearching && baseClassNames.isSearching,
          isSearching && classNames.isSearching,
          isFocused && baseClassNames.focused,
          isFocused && classNames.focused,
          isShowingOptions && baseClassNames.withOptions,
          isShowingOptions && classNames.withOptions,
          // $FlowFixMe[sketchy-number-and]
          prefixLabel && baseClassNames.withPrefix,
          // $FlowFixMe[sketchy-number-and]
          prefixLabel && classNames.withPrefix,
          // $FlowFixMe[sketchy-number-and]
          suffixLabel && baseClassNames.withSuffix,
          // $FlowFixMe[sketchy-number-and]
          suffixLabel && classNames.withSuffix,
          error && baseClassNames.withError,
          error && classNames.withError,
          isExpandable && baseClassNames.withVerticalStretch,
          isExpandable && classNames.withVerticalStretch,
          isHeightLimited && baseClassNames.withHeightLimit,
          isHeightLimited && classNames.withHeightLimit,
        )}
      >
        {prefixLabel}
        {children}
        <input
          {...inputPropsRest}
          autoComplete={autoComplete}
          ref={(el) => {
            inputProps.ref.current = el;
            if (outerRef) {
              outerRef.current = el;
            }
          }}
          className={css.selectorSearch}
          style={{
            visibility: isLimited && 'hidden',
          }}
        />
        {suffixLabel}
      </div>
      {lowerContent}
      {errorText && <span className={css.error}>{errorText}</span>}
    </div>
  );
}

function useTokens<V>({
  values,
  onChange,
  resolveKey,
  resolveLabel,
  inputRef,
  enableListPasting,
  Token,
  disabled,
}: {
  values: V[],
  onChange: (V[]) => mixed,
  resolveKey: (V) => string,
  resolveLabel: (V) => string | React.Element<'div'>,
  inputRef: {current: ?HTMLInputElement},
  enableListPasting?: boolean,
  Token: React.ComponentType<TokenProps<V>>,
  disabled?: boolean,
}): Array<React.Node> {
  const handleClickOption = React.useMemo(
    () => (optionToRemove) => {
      onChange(
        values.filter(
          (option) => resolveKey(option) !== resolveKey(optionToRemove),
        ),
      );
      // $FlowIssue optional chaining
      inputRef.current?.focus();
    },
    [values, onChange],
  );

  let tokens = [];

  if (enableListPasting && values.length > 100) {
    // $FlowFixMe[incompatible-type] edge case that isn't used for the React.Element case. This is too risky to fix for a component that we will deprecate soon
    const truncatedTokenContents = `${resolveLabel(first(values))}
+ ${values.length - 2} ${values.length === 1 ? 'other' : 'others'}
${
  // $FlowFixMe[incompatible-type]
  resolveLabel(last(values))
}`;

    tokens = [
      <Token
        className={css.selectorTruncatedValue}
        key="truncatedToken"
        onClick={() => onChange([])}
      >
        {truncatedTokenContents}
      </Token>,
    ];
  } else {
    tokens = values.map((option) => (
      <Token
        className={css.selectorValue}
        key={resolveKey(option)}
        option={option}
        onClick={!disabled ? () => handleClickOption(option) : null}
      >
        {resolveLabel(option)}
      </Token>
    ));
  }

  return tokens;
}

export function Placeholder({
  className,
  placeholder,
  isLabel,
  isHidden,
}: {
  className?: ?string,
  placeholder?: ?string,
  isLabel?: boolean,
  isHidden?: boolean,
}): React.Node {
  return (
    <div
      className={classify(
        css.selectorPlaceholder,
        className,
        isLabel && css.isLabel,
        isHidden && css.hidden,
      )}
    >
      {placeholder}
    </div>
  );
}
