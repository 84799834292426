// @flow

import type {SentEvent, PaginatedSentEvents} from 'src/types/report';
import type {SentEventAction} from 'src/action-creators/sent-events';

import {emptyObject} from 'src/utils';
import {
  RECEIVE_AGENCY,
  RECEIVE_WORKFLOW,
  RECEIVE_EVENT,
  PAGINATION_LIMIT,
} from 'src/action-creators/sent-events';


export type SentEventState = {
  agency: PaginatedSentEvents,
  workflows: {
    [workflowId: string]: PaginatedSentEvents,
  },
  events: {
    [eventId: string]: PaginatedSentEvents,
  },
  sentEvents: {
    [sentEventId: string]: SentEvent,
  },
};

const initialPaginatedSentEvents = {
  page: 0,
  hasMore: true,
  sentEventIds: [],
};

const initialState = {
  agency: initialPaginatedSentEvents,
  workflows: emptyObject,
  events: emptyObject,
  sentEvents: emptyObject,
};

export default (
  state: SentEventState = initialState,
  action: SentEventAction,
): SentEventState => {
  switch (action.type) {
    case RECEIVE_AGENCY: {
      return {
        ...state,
        agency: reducePage(state.agency, action),
        sentEvents: reduceSentEvents(state, action.payload.sentEvents),
      };
    }

    case RECEIVE_WORKFLOW: {
      const {payload} = action;
      const {workflowId} = payload;
      const workflow =
        state.workflows[workflowId] || initialPaginatedSentEvents;

      return {
        ...state,
        workflows: {
          ...state.workflows,
          [workflowId]: reducePage(workflow, action),
        },
        sentEvents: reduceSentEvents(state, payload.sentEvents),
      };
    }

    case RECEIVE_EVENT: {
      const {payload} = action;
      const {eventId} = payload;
      const event = state.events[eventId] || initialPaginatedSentEvents;

      return {
        ...state,
        events: {
          ...state.events,
          [eventId]: reducePage(event, action),
        },
        sentEvents: reduceSentEvents(state, payload.sentEvents),
      };
    }

    // TODO (kyle): normalize SentEventWithExternals data if needed
  }

  return state;
};

const reduceSentEvents = (
  state: SentEventState,
  sentEvents: SentEvent[],
): {[string]: SentEvent} =>
  sentEvents.reduce(
    (sentEvents: {[string]: SentEvent}, sentEvent: SentEvent) => {
      sentEvents[sentEvent.sentEventId] = sentEvent;
      return sentEvents;
    },
    {...state.sentEvents},
  );

const reducePage = (state, {payload: {page, sentEvents}}) => {
  const idsToAdd = sentEvents.map(({sentEventId}) => sentEventId);
  const sentEventIds =
    page === 0 ? idsToAdd : [...state.sentEventIds, ...idsToAdd];
  return {
    page,
    sentEventIds,
    hasMore: idsToAdd.length >= PAGINATION_LIMIT,
  };
};
