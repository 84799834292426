// @flow strict-local

import type {KeywordSearchSuggestion} from 'src/types/chat/keyword-search';


type KeywordSearchResponse = {
  displayMessages: KeywordSearchSuggestion[],
};

export type ReceiveAction = {
  type: 'chat/keyword-search/receive',
  payload: KeywordSearchResponse,
};

export const RECEIVE = 'chat/keyword-search/receive';

export const receive = (payload: KeywordSearchResponse): ReceiveAction => ({
  type: RECEIVE,
  payload,
});

export const SELECT_THREAD = 'chat/keyword-search/select-thread';

export type SelectThreadAction = {
  type: 'chat/keyword-search/select-thread',
  payload: ?string,
};

export const selectThread = (threadId: ?string): SelectThreadAction => ({
  type: SELECT_THREAD,
  payload: threadId,
});

export const RESET_SEARCH = 'chat/keyword-search/reset-search';

export type ResetSearchAction = {
  type: 'chat/keyword-search/reset-search',
};

export const resetSearch = (): ResetSearchAction => ({
  type: 'chat/keyword-search/reset-search',
});

export type KeywordSearchAction =
  | ReceiveAction
  | SelectThreadAction
  | ResetSearchAction;
