// @flow

import type {Query} from 'src/types/router';
import type {EventTypeCounts} from 'src/types/report';
import type {ThunkAction} from 'src/reducers';

import {thunkify as flow} from 'src/utils/thunks';
import {resolveAnalyticsQuery} from 'src/utils/analytics-query';
import {key, cached, fetching} from 'src/utils/redux';
import {
  checkAnalyticsNpsV3Enabled,
  ANALYTICS_API_V3,
} from 'src/utils/analytics-api-migration';

import * as reduxApi from 'src/utils/redux-api';


const {stringify} = JSON;

export const RECEIVE_AGENCY = 'eventTypeCounts/receiveAgency';
export const RECEIVE_WORKFLOW = 'eventTypeCounts/receiveWorkflow';

export type AgencyEventTypeCountsAction = {
  type: 'eventTypeCounts/receiveAgency',
  payload: EventTypeCounts,
};
export type WorkflowEventTypeCountsAction = {
  type: 'eventTypeCounts/receiveWorkflow',
  payload: {
    counts: EventTypeCounts,
    workflowId: string,
  },
};
export type EventTypeCountsAction =
  | AgencyEventTypeCountsAction
  | WorkflowEventTypeCountsAction;

export const receiveAgency = (
  payload: EventTypeCounts,
): AgencyEventTypeCountsAction => ({
  type: RECEIVE_AGENCY,
  payload,
});
export const receiveWorkflow = (
  counts: EventTypeCounts,
  workflowId: string,
): WorkflowEventTypeCountsAction => ({
  type: RECEIVE_WORKFLOW,
  payload: {
    counts,
    workflowId,
  },
});

const querying =
  () =>
  (func) =>
  (...args) => {
    const lastIndex = args.length - 1;
    args[lastIndex] = resolveAnalyticsQuery(args[lastIndex], [
      'client',
      'recipients',
      'nps_only',
    ]);
    return func(...args);
  };

export const getAgencyEventTypeCounts: (query: Query) => ThunkAction<mixed> =
  flow(
    querying(),
    key(() => 'eventTypeCounts/agency'),
    cached((json) => receiveAgency(json), {
      hash: (query) => `eventTypeCounts/agency:${stringify(query)}`,
    }),
    fetching(),
  )((query: Query) =>
    reduxApi.post(
      '/event-type-count',
      query,
      {},
      {
        apiPath: checkAnalyticsNpsV3Enabled()
          ? `${ANALYTICS_API_V3}`
          : '/api/v1/analytics',
      },
    ),
  );

export const getWorkflowEventTypeCounts: (
  workflowId: string,
  query: Query,
) => ThunkAction<mixed> = flow(
  querying(),
  key((workflowId) => `eventTypeCounts/workflow:${workflowId}`),
  cached((json, workflowId) => receiveWorkflow(json, workflowId), {
    hash: (workflowId, query) =>
      `eventTypeCounts/workflow:${workflowId}:${stringify(query)}`,
  }),
  fetching(),
)((workflowId: string, query: Query) =>
  reduxApi.post(`analytics/workflows/${workflowId}/event-type-count`, query),
);
