// @flow strict
import * as React from 'react';
import {useDispatch} from 'react-redux';
import {
  //$FlowFixMe
  popModal,
  //$FlowFixMe
  pushEditReferralModal,
  //$FlowFixMe
  pushBulkUnlinkJobsPrograms,
} from 'src/action-creators/modal.js';

//$FlowFixMe[nonstrict-import]
import {AutoTruncatedText} from 'src/components/lib/truncated-text/truncated-text.jsx';

import {Button} from 'src/designSystem2021Components/buttons.jsx';
import {Header} from 'src/designSystem2021Components/text-v2.jsx';
import bonusCss from 'src/components/referral-v2/configure/bonus-validity.css';
import CrossIcon from 'src/images/designSystems2021/close.svg?noAttrs';
import css from './programs-jobs.css';


const JobProgramsModal = ({
  programs,
  selectedItemsInPage,
  programId,
  page,
}: {
  programs: {id: string, name: string}[],
  programId: string,
  selectedItemsInPage: string[],
  page: number,
}): React.Node => {
  const dispatch = useDispatch();
  const handleClick = async () => {
    await dispatch(popModal());
    dispatch(
      pushEditReferralModal({
        label: 'link_jobs',
        selectedItemsInPage,
        programId,
        isAllSelected: false,
        page,
      }),
    );
  };

  const unlinkProgram = (program) => {
    dispatch(popModal());
    dispatch(
      pushBulkUnlinkJobsPrograms(
        {id: parseInt(program.id, 10), name: program.name},
        selectedItemsInPage,
        false,
        programId,
        page
      ),
    );
  };
  return (
    <div className={bonusCss.modalBackground}>
      <div className={bonusCss.modalHeader}>
        <Header className={bonusCss.filterContent}>Programs Linked</Header>
        <Button
          icon={<CrossIcon />}
          type="text"
          onClick={() => dispatch(popModal())}
        />
      </div>
      <div className={css.programsModalContent}>
        {programs.map((program) => (
          <div className={css.eachProgramInModal}>
            <div className={css.eachProgramWidth}>
              <AutoTruncatedText
                line={1}
                showFullTextOnHover={true}
                text={program.name}
              />
            </div>
            <Button
              className={css.hidden}
              type="text"
              size="small"
              onClick={() => unlinkProgram(program)}
            >
              Unlink Program
            </Button>
          </div>
        ))}
      </div>
      <div className={css.linkJobsBtn}>
        <Button type="tertiary" onClick={handleClick}>
          Link job to program
        </Button>
      </div>
    </div>
  );
};

export default JobProgramsModal;
