// @flow

import type {SenseAction} from 'src/action-creators/types';
import type {AllowedPhoneNumberResponseMap} from 'src/types/chat/allowed-phone-numbers';

import {RECEIVE} from 'src/action-creators/chat/allowed-phone-numbers';


export type State = {
  responses: AllowedPhoneNumberResponseMap,
};

const initialState = {
  responses: Object.freeze({}),
};

export default function reduce(
  state: State = initialState,
  action: SenseAction,
): State {
  switch (action.type) {
    case RECEIVE: {
      return {
        ...state,
        responses: {
          ...state.responses,
          ...action.payload,
        },
      };
    }
  }

  return state;
}
