// @flow strict

import * as React from 'react';

import {useInflight} from 'src/hooks/redux';

import {useSelector, useDispatch} from 'react-redux';
import type {SenseAiState, Tag} from 'src/reducers/sense-ai';
import {selectSenseAiData} from 'src/selectors/sense-ai';
import {
  setShowAiPanel,
  setShowCustomComponent,
  setPrompt,
  setGeneratedText,
  addTag,
  removeTag,
  receiveAllTags,
  setExternallyAccessibleFields,
  generateContent,
  setError,
  resetSelectedTags,
} from 'src/action-creators/sense-ai';


export const ALL_TAGS = [
  {label: 'Make it friendly', value: 'Make it friendly'},
  {label: 'Make it gender neutral', value: 'Make it gender neutral'},
  {label: 'Make it candid', value: 'Make it candid'},
  {label: 'Make it longer', value: 'Make it longer'},
];

export type SenseAiHookProps = {
  state: SenseAiState,
  isLoading: boolean,
  generateContent: (payload?: {...}) => Promise<{generated_text: string}>,
  // $FlowFixMe
  addTag: (string) => mixed,
  // $FlowFixMe
  removeTag: (string) => mixed,
  // $FlowFixMe
  receiveAllTags: (Tag[]) => mixed,
  resetSelectedTags: () => mixed,
  // $FlowFixMe
  reset: () => mixed,
  // $FlowFixMe
  setPrompt: (string) => mixed,
  // $FlowFixMe
  setShowAiPanel: (boolean) => mixed,
  // $FlowFixMe
  setShowCustomComponent: (boolean) => mixed,
  // $FlowFixMe
  setGeneratedText: (string) => mixed,
  // $FlowFixMe
  setError: (string) => mixed,
};

export type Config = {
  deps?: [],
  prompt?: string,
  selectedTags?: string[],
  generatedText?: string,
  error?: string,
  allTags?: Tag[],
};

export const useSenseAi = (
  url?: string = '',
  config?: Config = {deps: []},
): SenseAiHookProps => {
  const isLoading = useInflight(generateContent(url));

  React.useEffect(() => {
    const {
      prompt = '',
      selectedTags = [],
      generatedText = '',
      error = '',
      allTags = ALL_TAGS,
    } = config;
    const payload = {prompt, generatedText, selectedTags, error, allTags};
    dispatch(setExternallyAccessibleFields(payload));
  }, config.deps || []);

  const dispatch = useDispatch();
  const state: SenseAiState = useSelector(selectSenseAiData);

  const reset = () => {};

  const dispatcher = (fn) => {
    // $FlowFixMe[incompatible-call]
    return (...args) => dispatch(fn.apply(this, args));
  };

  return {
    state,
    addTag: dispatcher(addTag),
    removeTag: dispatcher(removeTag),
    reset,
    isLoading,
    setPrompt: dispatcher(setPrompt),
    setGeneratedText: dispatcher(setGeneratedText),
    setShowAiPanel: dispatcher(setShowAiPanel),
    setShowCustomComponent: dispatcher(setShowCustomComponent),
    generateContent: (payload) => dispatch(generateContent(url, payload)),
    setError: dispatcher(setError),
    receiveAllTags: dispatcher(receiveAllTags),
    resetSelectedTags: () => dispatch(resetSelectedTags()),
  };
};
