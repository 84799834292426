// @flow
import type {SenseEvent} from 'src/types/events';
import type {EntityType} from 'src/types/ats-entities';
import * as React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {ERROR_INVALID_TOUCHPOINT_IMPORT} from 'src/utils/events';
import {showGenericError} from 'src/action-creators/modal';

import {fluxify} from 'src/flux/component.jsx';

import {selectEntityByName} from 'src/selectors/ats-entities';
// import {DynamicLabelComponent} from 'src/components/lib/markdown-editor/'

import css from './import-entity-warning.css';


type ModalProps = {
  popModal: () => void,
  eventId: string,
  workflowId: string,
  sourceEvent: SenseEvent,
  entityType: EntityType,
  popModal: () => void,
  copyEvent: () => Promise<void>,
};

function ImportEntityWarningModal(props: ModalProps) {
  const [saving, setSaving] = React.useState(false);
  const dispatch = useDispatch();

  const handleImport = React.useCallback(async () => {
    setSaving(true);
    try {
      await props.copyEvent();
    } catch (err) {
      setSaving(false);
      if (
        err?.responseBody &&
        err?.responseBody?.status === 400 &&
        err?.responseBody?.errors?.includes(ERROR_INVALID_TOUCHPOINT_IMPORT)
      ) {
        dispatch(
          showGenericError({
            title: 'Sorry, the touchpoint can’t be imported',
            text: `The touchpoint you selected can’t be imported into this journey because it contains variables which conflicts with the entity type of this journey.`,
          }),
        );
      }
    }
    setSaving(false);
    props.popModal();
  }, [setSaving]);

  const entityMeta = useSelector((state) =>
    selectEntityByName(state, props.sourceEvent.entityType),
  );

  return (
    <div className={css.deleteContainer}>
      <h2 className={css.heading}>Import Touchpoint?</h2>
      <p className={css.explanation}>
        The touchpoint you’ve selected is from{' '}
        <span title={entityMeta.display_name}>a different journey type</span>{' '}
        and some of the variables used in it may not copy over correctly.
      </p>
      <p>
        They will change to have the format ‘?variablename?’ and will need to be
        updated in this new touchpoint after import.
      </p>
      <div className={css.buttonRow}>
        <button
          className={css.okButton}
          disabled={saving}
          onClick={handleImport}
        >
          {saving ? 'Importing...' : 'Import anyway'}
        </button>
        <button
          className={css.cancelButton}
          disabled={saving}
          onClick={props.popModal}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default (fluxify()(
  ImportEntityWarningModal,
): React.ComponentType<ModalProps>);
