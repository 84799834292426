// @flow strict-local

import {useReleaseFlag} from 'src/hooks/product-flags';
import usePrivilege from 'src/hooks/usePrivilege';
import {useSelector} from 'react-redux';


export default function useReferrals(): Array<?boolean> {
  const canEditReferrals = usePrivilege('ADMIN_REFERRALS');
  const canViewReferrals = usePrivilege('RECRUITER_REFERRALS');
  const canUseReferral = canEditReferrals || canViewReferrals;
  const referralsEnabled = useReleaseFlag('referralsEnabled');

  return [referralsEnabled, canUseReferral];
}

export const useProgramAccess = (programId?: ?string): boolean => {
  const hasGlobalAccess = usePrivilege('EDIT_REFERRALS_V2');

  const programAccess = useSelector(
    (state) => state.referralAcl.referral_users_program_acl,
  );
  if (!programId) {
    return false;
  }
  const program = programAccess?.programs[programId];

  if (!program) {
    return false;
  }
  if (program.is_default) {
    return hasGlobalAccess;
  }

  return program.access === 'edit';
};
