// @flow strict-local

import type {State} from 'src/reducers';

import {useSelector} from 'react-redux';

import {selectAgencyConfig} from 'src/selectors/agency';


export function selectIsWorknWorkflow(state: State): boolean {
  const workflowId = state.route.params.workflowId;
  return (
    workflowId &&
    selectAgencyConfig(state)[
      'worknPushNotificationSupportedWorkflowIds'
    ].includes(workflowId)
  );
}

export default function useIsWorknWorkflow(): boolean {
  return useSelector(selectIsWorknWorkflow);
}
