// @flow strict

import * as React from 'react';
import type {ReactRef} from 'src/types/react';
import {useEffect} from 'react';
import {useHistory, useParams} from 'src/rerouter';
import {useDispatch} from 'react-redux';
import moment from 'moment-timezone';

import {
  Toast,
  ToastBody,
  ToastFooter,
  ToastTitle,
  toastApi,
} from '@spaced-out/ui-design-system/lib/components/Toast';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';

import {
  getHVBroadcastData,
  setShowRenameModalPopupDetails,
} from 'src/action-creators/hv-broadcasts';


type ReturnValue = {
  isHVBroadcastLocation: boolean,
  isScheduledHVBroadcastLocation: boolean,
  showBroadcastRenamedSuccessfully: (
    id: string,
    prevName: string,
    currName: string,
  ) => void,
  showHVBroadcastSuccessfullySent: (id: string, name: string) => void,
  showHVBroadcastScheduled: (
    id: string,
    inboxId: string,
    name: string,
    date: string,
  ) => void,
  showHVBroadcastSuccessfullySaved: (id: string, name: string) => void,
  showContactListSuccessToast: (actionText: string) => void,
};

export function useHVBroadcasts(): ReturnValue {
  const router = useHistory();
  const dispatch = useDispatch();
  const location = router.location;
  const isHVBroadcastLocation = location.pathname.startsWith(
    '/messages/hv-broadcasts',
  );
  const isScheduledHVBroadcastLocation = location.pathname.startsWith(
    '/messages/hv-broadcasts/scheduled',
  );

  const showBroadcastRenamedSuccessfully = (id, prevName, currName) => {
    const onViewDetails = () => {
      router.push(`/messages/hv-broadcasts/details/${id}`);
    };
    const onDismiss = (toastId) => {
      toastApi.remove(toastId);
    };
    const toastId = toastApi.show(
      <Toast initialFocus={0} semantic="success">
        <ToastTitle>Broadcast Named Successfully!</ToastTitle>
        <ToastBody>{`'${prevName}' renamed to '${currName}'`}</ToastBody>
        <ToastFooter>
          <Button onClick={() => onDismiss(toastId)}>Dismiss</Button>
          <Button onClick={onViewDetails}>View Details</Button>
        </ToastFooter>
      </Toast>,
    );
  };

  const showHVBroadcastSuccessfullySent = (id, name) => {
    const onViewDetails = () => {
      router.push(`/messages/hv-broadcasts/details/${id}`);
    };
    const onDismiss = (toastId) => {
      toastApi.remove(toastId);
    };
    const toastId = toastApi.show(
      <Toast initialFocus={0} semantic="success">
        <ToastTitle>Success!</ToastTitle>
        <ToastBody>{`'${name}' broadcast sent successfully.`}</ToastBody>
        <ToastFooter>
          <Button onClick={() => onDismiss(toastId)}>Dismiss</Button>
          <Button onClick={onViewDetails}>View Details</Button>
        </ToastFooter>
      </Toast>,
    );
  };

  const showHVBroadcastScheduled = (id, inboxId, name, date) => {
    const onEditDetails = async () => {
      await dispatch(getHVBroadcastData(id));
      router.push(`/messages/hv-broadcasts/scheduled/${id}/from/${inboxId}`);
    };
    const onDismiss = (toastId) => {
      toastApi.remove(toastId);
    };
    const formattedDate = `Scheduled On: ${moment(date).format(
      'MMM Do, YYYY, HH:mma',
    )}`;
    const toastId = toastApi.show(
      <Toast initialFocus={0} semantic="success" time={formattedDate}>
        <ToastTitle>Success!</ToastTitle>
        <ToastBody>{`'${name}' broadcast scheduled successfully.`}</ToastBody>
        <ToastFooter>
          <Button onClick={() => onDismiss(toastId)}>Dismiss</Button>
          <Button onClick={onEditDetails}>Edit Details</Button>
        </ToastFooter>
      </Toast>,
    );
  };

  const showHVBroadcastSuccessfullySaved = (id, name) => {
    const toastId = toastApi.show(
      <Toast initialFocus={0} semantic="success">
        <ToastTitle>Success!</ToastTitle>
        <ToastBody>{`'${name}' broadcast saved successfully.`}</ToastBody>
      </Toast>,
    );
  };

  const showContactListSuccessToast = (actionText) => {
    toastApi.show(
      <Toast initialFocus={0} semantic="success">
        <ToastTitle>Success!</ToastTitle>
        <ToastBody>{`Contact list ${actionText} successfully.`}</ToastBody>
      </Toast>,
    );
  };
  return {
    isHVBroadcastLocation,
    isScheduledHVBroadcastLocation,
    showBroadcastRenamedSuccessfully,
    showHVBroadcastSuccessfullySent,
    showHVBroadcastScheduled,
    showHVBroadcastSuccessfullySaved,
    showContactListSuccessToast,
  };
}
