// @noflow

import type {Group, ShareableGroup} from 'src/action-creators/groups';


export const getGroupAndDescendants = <T: Group | ShareableGroup>(groups: T[], groupId: string): T[] => {
  const result = [];
  const group = groups.find(group => group.id === groupId);

  if (!group) {
    return result;
  }

  result.push(group);

  // Keeps adding children to the end of the array and descending into
  // each of those children and adding their children to end of array and
  // descending etc until all descendants have been found. Basically a
  // breadth-first search.
  for (let i = 0; i < result.length; i++) {
    const parent = result[i];
    const children = groups.filter(child => child.parentSecurityGroupId === parent.id);
    result.push(...children);
  }

  return result;
};

export const rootGroupId = (groupData: {[id: string]: Group}) => {
  // finds the group whose parent property is either null or
  // outside the seen collection (see group-utils-tests.js)
  const groupIds = Object.keys(groupData);
  return groupIds.find(id => (
    !groupIds.includes(groupData[id].parentSecurityGroupId)
  ));
};
