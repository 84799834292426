// @flow strict

import type {ReactRef} from 'src/types/react';

import * as React from 'react';
import invariant from 'invariant';

import classify from 'src/utils/classify';
import {getAnchorPosition, type Placement} from 'src/utils/dom';

import css from './context-menu.css';


let menuRoot = null;
const clickedInsideMap = new WeakMap();

type AnchorPosition = 'start' | 'center' | 'end';

type ContextMenuProps = {
  ...ContextMenuConfig,
  children: React.Node,
  menuContent?: React.Node,
  anchorPosition?: AnchorPosition,
};

export default function ContextMenu(
  props: ContextMenuProps,
): React.Element<'div'> {
  const {children, ...restProps} = props;
  const {className, anchor, onClick, onOpen, onClose} = props;
  const config = {
    anchorPosition: 'end',
    closeOnMenuClick: true,
    menuContent: children,
    ...restProps,
  };
  const [componentRef, handleOpen, handleClose, isOpen] =
    useContextMenu(config);

  return (
    <div
      className={classify(css.button, className)}
      ref={componentRef}
      onClick={(event) => {
        event.preventDefault();
        if (isOpen()) {
          handleClose();
          if (onClose) {
            onClose();
          }
        } else {
          handleOpen();
          if (onOpen) {
            onOpen();
          }
        }
        if (onClick) {
          onClick(event);
        }
      }}
    >
      {anchor ? anchor : '+'}
    </div>
  );
}

export type ContextMenuConfig = {
  className?: string,
  anchor?: React.Node,
  noStyle?: boolean,
  contextMenuClassName?: string,
  header?: string,
  anchorPosition?: AnchorPosition,
  justify?: AnchorPosition,
  placement?: Placement,
  onClick?: (event: SyntheticMouseEvent<HTMLElement>) => mixed,
  onExit?: () => mixed,
  onOpen?: () => mixed,
  onClose?: () => mixed,
  closeOnMenuClick?: mixed,
  disabled?: mixed,
  menuContent: React.Node,
  noArrow?: mixed,
  upward?: boolean,
  downward?: boolean,
};

export type ContextMenuTuple = [
  ReactRef<HTMLElement>,
  () => void,
  () => void,
  () => boolean,
  () => void,
];

export function useContextMenu({
  onClose,
  disabled,
  closeOnMenuClick = true,
  ...config
}: ContextMenuConfig): ContextMenuTuple {
  const componentRef = React.useRef<?HTMLElement>();

  const handleOpen = () => {
    if (!disabled) {
      invariant(
        menuRoot,
        'ContextMenu: Your context menu will not work without a mounted ContextMenuRoot.',
      );
      menuRoot.set(componentRef, {closeOnMenuClick, ...config, onClose});
    }
  };

  const updateMenuContent = () => {
    invariant(
      menuRoot,
      'ContextMenu: Your context menu will not work without a mounted ContextMenuRoot.',
    );
    menuRoot.set(componentRef, {closeOnMenuClick, ...config, onClose});
  };

  const handleClose = () => {
    invariant(
      menuRoot,
      'ContextMenu: Your context menu will not work without a mounted ContextMenuRoot.',
    );
    menuRoot.unset(componentRef);
    if (onClose) {
      onClose();
    }
  };

  const isOpen = () => Boolean(menuRoot?.state.activeMenu === componentRef);

  // If the parent component of the menu unmounts, we remove it.
  React.useEffect(() => {
    return () => {
      window.addEventListener(
        'click',
        () => {
          if (menuRoot && menuRoot.state.activeMenu === componentRef) {
            menuRoot.unset(componentRef);
          }
        },
        {once: true},
      );
    };
  }, []);

  return [componentRef, handleOpen, handleClose, isOpen, updateMenuContent];
}

type ContextMenuRef = {current: ?HTMLElement};
type ContextMenuRootState = {
  activeMenu: ?ContextMenuRef,
  config: ?ContextMenuConfig,
};
export class ContextMenuRoot extends React.PureComponent<
  {},
  ContextMenuRootState,
> {
  menuElement: ?HTMLElement = null;

  state: ContextMenuRootState = {
    activeMenu: null,
    config: null,
  };

  componentDidMount() {
    // $FlowFixMe[escaped-generic]
    menuRoot = this;
  }

  componentWillUnmount() {
    menuRoot = null;
    this.stopListening();
  }

  componentDidUpdate(_: {}, prevState: ContextMenuRootState) {
    if (!prevState.activeMenu && this.state.activeMenu) {
      setTimeout(() => {
        window.addEventListener('click', this.handleWindowClick);
      }, 0);
    } else if (prevState.activeMenu && !this.state.activeMenu) {
      this.stopListening();
    }

    // NOTE (kyle): if the menu overlows past the bottom of the fold,
    // we pop it upward here.
    const {activeMenu, config} = this.state;
    if (activeMenu && config) {
      const menuElement = this.menuElement;
      if (menuElement) {
        const rect = menuElement.getBoundingClientRect();
        if (
          activeMenu.current &&
          (config.upward || rect.bottom > window.innerHeight) &&
          !config.downward
        ) {
          const {y} = getAnchorPosition(
            activeMenu.current,
            'top',
            0,
            config.justify,
          );

          menuElement.style.top = y - rect.height + 'px';
        }
      }
    }
  }

  stopListening() {
    window.removeEventListener('click', this.handleWindowClick);
  }

  render(): null | React.Element<'div'> {
    const {activeMenu, config} = this.state;
    if (activeMenu?.current && config) {
      const {x, y} = getAnchorPosition(
        activeMenu.current,
        config.placement,
        0,
        config.justify,
      );
      const style = {
        left: x,
        top: y,
      };

      return (
        <div
          className={classify(css.menu, config.contextMenuClassName, {
            [css.menuDefaultStyle]: !config.noStyle,
          })}
          style={style}
          data-ui-anchor={`${config.anchorPosition || 'end'}${
            config.noArrow ? 'NoCaret' : ''
          }`}
          data-it-user-menu
          ref={(element) => (this.menuElement = element)}
          onClick={(e) => {
            // prevents context menu from closing if using render function
            if (!config.closeOnMenuClick) {
              clickedInsideMap.set(e.nativeEvent, true);
            }
          }}
        >
          {config.noStyle ? (
            config.menuContent
          ) : (
            <div className={css.items}>{config.menuContent}</div>
          )}
          {!config.noArrow && (
            <svg className={css.caret} viewBox="0 0 20 9">
              <path className={css.caretArrow} d="M0,11 L10,0.5 L20,11z" />
            </svg>
          )}
        </div>
      );
    }

    return null;
  }

  set(component: ContextMenuRef, config: ContextMenuConfig) {
    this.setState({
      activeMenu: component,
      config: {placement: 'bottom', ...config},
    });
  }

  unset(component: ContextMenuRef) {
    invariant(
      component === this.state.activeMenu,
      'Attempted to hide a menu that was not already being displayed.',
    );
    //Note (aditya) checking activeMenu to prevent flow error since activeMenu is typed ?ContextMenu and could be null
    if (this.state.activeMenu) {
      const onExit = this.state.config?.onExit;
      if (onExit) {
        onExit();
      }
    }
    this.setState({
      activeMenu: null,
      config: null,
    });
  }

  _handleWindowClick(event: MouseEvent) {
    if (clickedInsideMap.get(event)) {
      clickedInsideMap.delete(event);
      return;
    }
    // not sure we need this.
    /*
    if (this.menuElement.contains(event.target)) {
      return;
    }
    */

    const {activeMenu} = this.state;

    // NOTE (kyle): if the consumer calls `close` in a click handler, its
    // possible that the handler will bubble up after the activeMenu has been
    // removed.
    if (activeMenu) {
      if (this.state.config?.onClose) {
        this.state.config.onClose();
      }
      this.unset(activeMenu);
    }
  }
  handleWindowClick: (event: MouseEvent) => void =
    // $FlowFixMe[method-unbinding]
    this._handleWindowClick.bind(this);
}
