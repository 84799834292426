// @flow

import type {State} from 'src/reducers';
import type {ModalStateEntry} from 'src/types/modal';

import {createSelector} from 'reselect';


const getModals = (state: State) => state.modal;

export const selectHead: (state: State) => ModalStateEntry = createSelector(
  getModals,
  (modals) => modals.error || modals.entries[modals.entries.length - 1],
);
