// @flow strict

import makeClassNameComponent, {
  type ClassNameComponent,
} from 'src/utils/makeClassNameComponent';

import dsCss from 'designSystem2021';


export const Header: ClassNameComponent<'h1'> = makeClassNameComponent(
  dsCss.header,
  'h1',
  'Header',
);
export const SubHeader: ClassNameComponent<'h2'> = makeClassNameComponent(
  dsCss.subHeader,
  'h2',
  'SubHeader',
);

export const Paragraph: ClassNameComponent<'p'> = makeClassNameComponent(
  dsCss.paragraph,
  'p',
  'Paragraph',
);

export const Clickable: ClassNameComponent<'span'> = makeClassNameComponent(
  dsCss.clickable,
  'span',
  'Clickable',
);

export const Smallest: ClassNameComponent<'span'> = makeClassNameComponent(
  dsCss.smallest,
  'span',
  'Smallest',
);

export const BaseText: ClassNameComponent<'span'> = makeClassNameComponent(
  dsCss.baseType,
  'span',
  'Base',
);
