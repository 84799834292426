// @flow strict
export const CARD_TYPES = {
  RECENTLY_MESSAGED: 'recentlymessaged',
  LANDLINE: 'landline',
  INVALID: 'invalid',
};

export const TIME_FRAME_UNIT = {
  DAY_1: 'DAY_1',
  DAY_3: 'DAY_3',
  WEEK_1: 'WEEK_1',
  CUSTOM_TIME: 'CUSTOM_TIME',
};

export const BROADCAST_SUPPRESSION_TYPE = {
  OFF: 'off',
  ADMIN: 'forced',
  RECRUITER: 'optional',
};
