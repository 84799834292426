// @flow strict

export type EntityType =
  | SFTPEntityType
  | AEEntityType
  | BullhornEntityType
  | PCREntityType
  | JDEntityType
  | EREntityType
  | TPEntityType
  | TREntityType
  | ROEntityType
  | AvionteEntityType
  | TREntityType
  | GlobalVariableEntityType
  | CSVEntityType
  | CustomPersonEntityType
  | SFDCEntityType
  | SKEntityType;

type BullhornEntityType =
  | 'bh_appointment'
  | 'bh_candidate'
  | 'bh_corporate_user'
  | 'bh_client_contact'
  | 'bh_client_corporation'
  | 'bh_job_order'
  | 'bh_job_submission'
  | 'bh_candidate_education'
  | 'bh_candidate_reference'
  | 'bh_lead'
  | 'bh_certification'
  | 'bh_placement'
  | 'bh_placement_certification'
  | 'bh_opportunity'
  | 'es_applicant'
  | 'es_workflow';

type PCREntityType =
  | 'pcr_contact'
  | 'pcr_user'
  | 'pcr_candidate'
  | 'pcr_interview'
  | 'pcr_placement';

type JDEntityType =
  | 'jd_candidate'
  | 'jd_activity'
  | 'jd_job'
  | 'jd_user'
  | 'jd_company'
  | 'jd_contact';

type EREntityType =
  | 'er_candidate'
  | 'er_match'
  | 'er_position'
  | 'er_hr_representative'
  | 'er_company'
  | 'er_contact'
  | 'er_seed'
  | 'er_candidate_application';

type TPEntityType = 'tp_applicant' | 'tp_placement' | 'tp_customer';

type AvionteEntityType =
  | 'ab_talent'
  | 'ab_user'
  | 'ab_placement'
  | 'ab_company'
  | 'ab_contact'
  | 'ab_job'
  | 'ab_web_applicant'
  | 'ab_pipeline';

type SFTPEntityType =
  | 'sftp_candidate'
  | 'sftp_placement'
  | 'sftp_client_company_employee'
  | 'sftp_client_company'
  | 'sftp_job_order'
  | 'sftp_internal_employee';

type AEEntityType =
  | 'ae_candidate'
  | 'ae_placement'
  | 'ae_client_company_employee'
  | 'ae_client_company'
  | 'ae_job'
  | 'ae_job_posting'
  | 'ae_internal_employee'
  | 'ae_appointment'
  | 'ae_certification'
  | 'ae_submission'
  | 'ae_lead'
  | 'ae_task';

type TREntityType =
  | 'tr_candidate'
  | 'tr_user'
  | 'tr_contact'
  | 'tr_company'
  | 'tr_submission'
  | 'tr_placement'
  | 'tr_interview'
  | 'tr_reference'
  | 'tr_lead'
  | 'tr_job'
  | 'tr_note';

type ROEntityType =
  | 'ro_candidate'
  | 'ro_user'
  | 'ro_contact'
  | 'ro_company'
  | 'ro_submission'
  | 'ro_opportunity_discussed'
  | 'ro_application'
  | 'ro_placement'
  | 'ro_interview'
  | 'ro_lead'
  | 'ro_job'
  | 'ro_note';

type SFDCEntityType =
  | 'sfdc_candidate'
  | 'sfdc_user'
  | 'sfdc_contact'
  | 'sfdc_company'
  | 'sfdc_submission'
  | 'sfdc_placement'
  | 'sfdc_interview'
  | 'sfdc_lead'
  | 'sfdc_job'
  | 'sfdc_note'
  | 'sfdc_reference'
  | 'sfdc_opportunity';

type SKEntityType =
  | 'sk_offer_letter_approver'
  | 'sk_offer_letter'
  | 'sk_interview_schedule'
  | 'sk_application'
  | 'sk_hiring_team_member'
  | 'sk_job'
  | 'sk_candidate'
  | 'sk_user'
  | 'sk_interviewer';
type CustomPersonEntityType = 'custom_person';

type CSVEntityType = 'csv_standard_candidate' | 'csv_standard_placement';

type GlobalVariableEntityType = 'global_variable';

export type PeopleEntityType =
  | 'sftp_candidate'
  | 'sftp_client_company_employee'
  | 'sftp_internal_employee'
  | 'ae_candidate'
  | 'ae_client_company_employee'
  | 'ae_internal_employee'
  | 'bh_candidate'
  | 'bh_corporate_user'
  | 'bh_client_contact'
  | 'bh_candidate_reference'
  | 'bh_lead'
  | 'pcr_candidate'
  | 'pcr_user'
  | 'pcr_contact'
  | 'jd_candidate'
  | 'jd_user'
  | 'jd_contact'
  | 'er_candidate'
  | 'er_hr_representative'
  | 'er_contact'
  | 'er_seed'
  | 'er_candidate_application'
  | 'ae_lead'
  | 'ab_talent'
  | 'ab_contact'
  | 'ab_user'
  | 'tp_applicant'
  | 'tr_candidate'
  | 'tr_contact'
  | 'tr_user'
  | 'ro_candidate'
  | 'ro_contact'
  | 'ro_user'
  | 'csv_standard_candidate'
  | 'custom_person'
  | 'sfdc_candidate'
  | 'sfdc_contact'
  | 'sfdc_user'
  | 'sk_user'
  | 'sk_offer_letter_approver'
  | 'sk_hiring_team_member'
  | 'sk_interviewer'
  | 'sk_candidate'
  | 'sfdc_lead'
  | 'tr_reference';

export const peopleEntityTypes: PeopleEntityType[] = [
  'sftp_candidate',
  'sftp_client_company_employee',
  'sftp_internal_employee',
  'ae_candidate',
  'ae_client_company_employee',
  'ae_internal_employee',
  'bh_candidate',
  'bh_corporate_user',
  'bh_client_contact',
  'bh_candidate_reference',
  'bh_lead',
  'pcr_candidate',
  'pcr_user',
  'pcr_contact',
  'jd_candidate',
  'jd_user',
  'jd_contact',
  'er_candidate',
  'er_hr_representative',
  'er_contact',
  'ab_talent',
  'ab_contact',
  'ab_user',
  'tp_applicant',
  'tr_candidate',
  'tr_contact',
  'tr_user',
  'ro_candidate',
  'ro_contact',
  'ro_user',
  'csv_standard_candidate',
  'custom_person',
  'sfdc_candidate',
  'sfdc_contact',
  'sfdc_user',
  'sk_user',
  'sk_offer_letter_approver',
  'sk_hiring_team_member',
  'sk_interviewer',
  'sk_candidate',
  'er_seed',
  'ae_lead',
  'sfdc_lead',
  'tr_reference',
];
export const peopleEntityTypesSet: Set<EntityType> = new Set(peopleEntityTypes);

// sense name for some entities have ats prefixes because they are ATS specific
// This should contain all values from StandardSenseEntity in python
export type EntitySenseName =
  | 'candidate'
  | 'client_company_employee'
  | 'internal_employee'
  | 'placement'
  | 'appointment'
  | 'job'
  | 'task'
  | 'job_submission'
  | 'global_variable'
  | 'bh_job_submission'
  | 'bh_candidate_education'
  | 'bh_candidate_reference'
  | 'bh_lead'
  | 'lead'
  | 'opportunity'
  | 'candidate_stage'
  | 'candidate_pipeline'
  | 'candidate_qualification'
  | 'billing_record'
  | 'placement_certification'
  | 'bh_certification'
  | 'candidate_reference'
  | 'certification'
  | 'bh_placement_certification'
  | 'bh_opportunity'
  | 'ae_certification'
  | 'ae_submission'
  | 'ae_task'
  | 'ae_lead'
  | 'er_match'
  | 'er_seed'
  | 'er_candidate_application'
  | 'jd_activity'
  | 'jd_billing_record'
  | 'jd_salary_record'
  | 'es_applicant'
  | 'es_workflow'
  | 'workflow'
  | 'applicant'
  | 'tr_submission'
  | 'ro_submission'
  | 'ro_opportunity_discussed'
  | 'ro_application'
  | 'ab_web_applicant'
  | 'ab_pipeline'
  | 'sfdc_submission'
  | 'sfdc_opportunity';

export type WorkflowEntitiesSenseName =
  | 'candidate'
  | 'appointment'
  | 'placement'
  | 'internal_employee'
  | 'client_company_employee'
  | 'job'
  | 'task'
  | 'bh_candidate_education'
  | 'bh_candidate_reference'
  | 'bh_lead'
  | 'bh_placement_certification'
  | 'bh_opportunity'
  | 'er_seed'
  | 'er_candidate_application'
  | 'bh_job_submission'
  | 'er_match'
  | 'jd_activity'
  | 'jd_billing_record'
  | 'es_applicant'
  | 'es_workflow'
  | 'ab_web_applicant'
  | 'ae_task'
  | 'sfdc_opportunity';

export type ApiEntitySenseName =
  | 'candidate'
  | 'client_company_employee'
  | 'internal_employee'
  | 'placement'
  | 'appointment'
  | 'job'
  | 'task'
  | 'bh_job_submission'
  | 'bh_candidate_education'
  | 'bh_candidate_reference'
  | 'bh_lead'
  | 'bh_placement_certification'
  | 'bh_opportunity'
  | 'er_match'
  | 'er_seed'
  | 'er_candidate_application'
  | 'jd_activity'
  | 'jd_billing_record'
  | 'jd_salary_record'
  | 'es_applicant'
  | 'es_workflow'
  | 'sfdc_opportunity';

export type AtsEntity = {
  name: EntityType,
  display_name: string,
  display_name_plural: string,
  sense_name: EntitySenseName,
  candidate_tab_order?: number,
  is_candidate_tab?: boolean,
  url: ?string,
  event_entity: boolean,
  candidate_tab_order: number,
  is_candidate_tab: boolean,
  is_person: boolean,
  related_entities: EntityRelationship[],
  is_internal: boolean,
  is_csv_entity: boolean,
  display_name_capitalized: string,
};

export type ApiAtsEntity = {
  name: EntityType,
  display_name: string,
  display_name_plural: string,
  sense_name: ApiEntitySenseName,
  candidate_tab_order?: number,
  is_candidate_tab?: boolean,
  url: ?string,
  event_entity: boolean,
  candidate_tab_order: number,
  is_candidate_tab: boolean,
  is_person: boolean,
  related_entities: EntityRelationship[],
  is_internal: boolean,
  is_csv_entity: boolean,
};

// this type maps onto the server EntityRelationSchema
export type EntityRelationship = {
  id: number,
  name: string,
  display_name: string,
  join_attribute: string,
  entity_type: EntityType,
  bridge_step1_id: ?number,
  bridge_step2_id: ?number,
};

export type AtsEntityMappings = {
  [string]: AtsEntity,
};

export type EntityMappingsByName = {
  [EntityType]: AtsEntity,
};

export type EntityMappingsBySenseName = {
  [EntitySenseName]: AtsEntity,
};

export type EntityMappingsBySenseNameMap = Map<EntitySenseName, AtsEntity>;

export type AtsEntities = AtsEntity[];

export type AtsClientEntity = AtsEntity;

export type AttributeStringDataType =
  | 'string'
  | 'date'
  | 'time'
  | 'phone'
  | 'email'
  | 'html'
  | 'text';

export type AttributeNumberDataType = 'number' | 'currency';

export type AttributeDataType =
  | AttributeStringDataType
  | AttributeNumberDataType
  | 'boolean';

export type EntityAttribute = {
  dataType: AttributeDataType,
  displayName: string,
  fullyFormedDisplayName: string,
  fullyFormedName: string,
  attributeName: string,
  addToFilters: boolean,
  relatedEntityType: EntityType,
  relatedEntities: AtsEntities,
  hidden: boolean,
};

export type EntityAttributeSchema = {
  add_to_filters: boolean,
  attribute_name: string,
  category: ?string,
  data_type: AttributeDataType,
  display_name: string,
  entity_type: EntityType,
  hidden: boolean,
  is_join_attribute: boolean,
  id: string,
  source: string,
  designations?: string[],
};

export type RelatedEntityAttributeSchema = {
  ...EntityAttributeSchema,
  relationshipPath: string[],
  resolvedRelationshipPath: EntityRelationship[],
};

export type EntityAttributeMappingDiff = {
  id: string,
  display_name?: string,
  add_to_filters?: boolean,
  hidden?: boolean,
};

export type EntityAttributeMapping = {
  ...EntityAttributeSchema,
  inUse: boolean,
};

export type EntityAttributeSet = {
  all: EntityAttribute[],
  recipient: EntityAttribute[],
};

export type EntityAttributeMap = {
  [entity_type: EntityType]: EntityAttributeSet,
  ...
};

export type Usage = {
  entityAttributeId: string,
  location: string,
  resourceId: string,
  resourceType: string,
};

export type EntityMetadataEntityAttributes = {
  id: string,
  agencyId: string,
  displayName: string,
  attributeName: string,
  dataType: string,
  entityType: EntityType,
  defaultValue: ?string,
  source: string,
  addToFilters: boolean,
  hidden: boolean,
  timeCreated: ?Array<number>,
  timeUpdated: ?Array<number>,
  timeDeleted: ?Array<number>,
};

export type EntityMetadataRelatedEntity = {
  name: string,
  entityType: string,
  source: string,
  joinAttribute: string,
  displayName: string,
  relatedEntities: {[string]: EntityMetadataRelatedEntity} | {||},
  entityAttributes: EntityMetadataEntityAttributes[],
};

export type EntityMetadataObject = {
  entityType: string,
  source: string,
  relatedEntities: {[string]: EntityMetadataRelatedEntity} | {||},
  entityAttributes: EntityMetadataEntityAttributes[],
};

export type EntityMetadataApi = EntityMetadataObject[];
