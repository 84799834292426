// @flow strict

import * as React from 'react';
import css from './task-list.css';
import TaskContainer from 'src/components/agency-onboard/task/task.jsx';
import type {Task} from 'src/components/agency-onboard/types/side-panel-comp.js';


type TaskListProps = {
  tasks: {[string]: Task},
  setTasks: () => mixed,
};

export default function OnboardTaskList({
  tasks,
  setTasks,
}: TaskListProps): React.Node {
  return (
    tasks && (
      <div className={css.taskList}>
        {Object.entries(tasks).map(([task_id, task]) => (
          <TaskContainer
            // $FlowFixMe[incompatible-use]
            key={task.id}
            // $FlowFixMe[incompatible-use]
            selectedTaskId={task.id}
            tasks={tasks}
            setTasks={setTasks}
          />
        ))}
      </div>
    )
  );
}
