// @flow strict

import type {AutomationWorkflow} from 'src/types/automation-workflow';
import type {EntityType} from 'src/types/ats-entities';
import cloneDeep from 'lodash/cloneDeep';

import {
  UPDATE_AUTOMATION_WORKFLOW,
  RESET_AUTOMATION_WORKFLOW,
  RECEIVE_AUTOMATION_WORKFLOW,
  ADD_TEMP_NODE,
  RESET_TEMP_NODES,
  ADD_TEMP_TRANSITION,
  type AutomationWorkflowAction,
  ADD_TEMP_SPLIT,
  DELETE_SPLIT,
} from 'src/action-creators/automation-workflow';
import {
  DUMMY_SWIMLANE,
  createSwimlaneNode,
  TEMP_NODE_IDENTIFIER,
  removeSwimlaneTempNodes,
  createTransition,
  TEMP_END_NODE_IDENTIFIER,
  createSplitTransition,
} from 'src/utils/automation-workflow';


export type AutomationWorkflowState = {
  ...AutomationWorkflow,
};

const initialState = {
  id: null,
  name: null,
  //make this entity type enum
  entity_type: null,
  standard_entity_type: null,
  starred: false,
  status: 'INACTIVE', //default to this enum
  time_updated: null,
  swimlanes: [DUMMY_SWIMLANE],
  filters: null,
  workflow_category: null,
  created_by_agent_name: null,
  security_group: null,
  time_created: null,
  brand_id: null,
  shared_security_groups: [],
  frequencyLimit: null,
  frequencyType: 'DAY',
  blackout_window_id: null,
};

export default (
  state: AutomationWorkflowState = initialState,
  action: AutomationWorkflowAction,
): AutomationWorkflowState => {
  switch (action.type) {
    case UPDATE_AUTOMATION_WORKFLOW: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case RESET_AUTOMATION_WORKFLOW: {
      //note(vish): we are deepcloning initial state here coz it contains swimlanes object which is deeply nested.
      const {payload = cloneDeep(initialState)} = action;
      return {
        ...state,
        ...payload,
      };
    }
    case RECEIVE_AUTOMATION_WORKFLOW: {
      const {payload} = action;
      return {
        ...state,
        ...payload,
        swimlanes: payload.swimlanes || [DUMMY_SWIMLANE],
      };
    }
    case ADD_TEMP_NODE: {
      //TODO: (diwakersurya) fix this later
      const {nodeType, swimlaneId, sourceNodeId, transitionId} = action.payload;
      //update swimlanes somehow
      const swimlanes = state.swimlanes.map((swimlane) => {
        if (swimlane.id === parseInt(swimlaneId, 10)) {
          const fromNode = swimlane.nodes[sourceNodeId];
          //find related node
          const relatedNodeId = fromNode.metadata.related_to;
          const relatedNode = relatedNodeId
            ? swimlane.nodes[relatedNodeId]
            : null;

          // the node for which pointers need to be modified
          const effectiveFromNode = relatedNode ? relatedNode : fromNode;
          // const nextNodes=relatedNode?relatedNode.next_nodes:fromNode.next_nodes;
          const newNode = createSwimlaneNode({
            id: TEMP_NODE_IDENTIFIER,
            next_nodes: effectiveFromNode.next_nodes
              .filter((nextNode, index) => index === transitionId)
              .map((nextNode) => ({
                condition: {blocks: []},
                next_node: nextNode.next_node,
                transition_id: null,
              })),
          });
          //$FlowFixMe TODO: (diwakersurya) fix this later
          newNode.type = nodeType;
          //todo(vish): currently we are identifying transitions on the basis of array index. This should be changed to proper ID later given from BE
          effectiveFromNode.next_nodes = effectiveFromNode.next_nodes.map(
            (nextNode, index) => {
              if (index === transitionId) {
                return {
                  ...nextNode,
                  next_node: TEMP_NODE_IDENTIFIER,
                };
              }
              return {...nextNode};
            },
          );
          const nodes = {
            ...swimlane.nodes,
            [sourceNodeId]: fromNode,
            [TEMP_NODE_IDENTIFIER]: newNode,
          };
          return {
            ...swimlane,
            nodes,
          };
        }
        return swimlane;
      });
      return {
        ...state,
        swimlanes,
      };
    }

    case RESET_TEMP_NODES: {
      const swimlanes = [...state.swimlanes];
      const newSwimlanes = swimlanes.map((swimlane) => {
        removeSwimlaneTempNodes(swimlane, swimlane.initial_node);
        return swimlane;
      });
      return {
        ...state,
        swimlanes: newSwimlanes,
      };
    }
    case ADD_TEMP_TRANSITION: {
      const {swimlaneId, sourceNodeId, position} = action.payload;
      const swimlanes = state.swimlanes.map((swimlane) => {
        if (swimlane.id === parseInt(swimlaneId, 10)) {
          const effectiveNode = swimlane.nodes[sourceNodeId];
          const newTransition = createTransition();
          const newTempEndNode = {
            id: TEMP_END_NODE_IDENTIFIER,
            metadata: {},
            next_nodes: [],
          };
          if (position === 'left') {
            effectiveNode.next_nodes = [
              newTransition,
              ...effectiveNode.next_nodes,
            ];
          } else {
            effectiveNode.next_nodes = [
              ...effectiveNode.next_nodes,
              newTransition,
            ];
          }
          const nodes = {
            ...swimlane.nodes,
            [sourceNodeId]: effectiveNode,
            [TEMP_END_NODE_IDENTIFIER]: newTempEndNode,
          };
          return {
            ...swimlane,
            nodes,
          };
        }
        return swimlane;
      });
      return {
        ...state,
        swimlanes,
      };
    }
    case ADD_TEMP_SPLIT: {
      const {swid, nodeId, tid, position} = action.payload;
      const swimlanes = state.swimlanes.map((swimlane) => {
        if (swimlane.id === parseInt(swid, 10)) {
          const effectiveNode = swimlane.nodes[nodeId];
          const newTransition = createSplitTransition(tid);
          let newTempEndNode = null;
          if (!swimlane.nodes.hasOwnProperty(tid)) {
            newTempEndNode = {
              id: tid,
              metadata: {},
              next_nodes: [],
            };
          }
          effectiveNode.next_nodes.splice(position, 0, newTransition);
          const nodes = {
            ...swimlane.nodes,
            [nodeId]: effectiveNode,
          };
          if (newTempEndNode != null) {
            nodes[tid] = newTempEndNode;
          }
          return {
            ...swimlane,
            nodes,
          };
        }
        return swimlane;
      });
      return {
        ...state,
        swimlanes,
      };
    }
    case DELETE_SPLIT: {
      const {swid, nodeId, position} = action.payload;
      const swimlanes = state.swimlanes.map((swimlane) => {
        if (swimlane.id === parseInt(swid, 10)) {
          const effectiveNode = swimlane.nodes[nodeId];
          effectiveNode.next_nodes.splice(position, 1);
          const nodes = {
            ...swimlane.nodes,
            [nodeId]: effectiveNode,
          };
          return {
            ...swimlane,
            nodes,
          };
        }
        return swimlane;
      });
      return {
        ...state,
        swimlanes,
      };
    }
  }
  return state;
};
