// @flow

import type {
  GetState,
  Dispatch,
  ThunkAction,
  ThunkSyncAction,
} from 'src/reducers';
import type {
  BroadcastSuppressionPhoneDetails,
  TimeFrame,
} from 'src/components/messaging/broadcast-suppression/types';

import type {ApiBroadcast, BroadcastScheduleType} from 'src/types/broadcasts';
import type {SetOfDiscoverCandidateDetails} from 'src/types/draft-messages';
import type {SuggestionType} from 'src/types/contacts';

import _get from 'lodash/get';
import invariant from 'invariant';

import {getPrimaryPhone} from 'src/utils/contacts';
import {validateBroadcast} from 'src/utils/broadcasts';
import {createBroadcast} from 'src/action-creators/broadcasts';

import {selectAllowedMediaFilenameRegex} from 'src/selectors/chat';

import {
  selectFilesForUpload,
  selectFileUploadErrors,
} from 'src/selectors/draft-messages';


export const SELECT_PHONE = 'draftMessages/selectPhone';
export const SET_PHONE_FOR_NEW = 'draftMessages/setPhoneForNew';
export const INPUT_PHONE = 'draftMessages/inputPhone';
export const SET_MULTIPLE_RECIPIENTS = 'draftMessages/setMultipleRecipients';
export const SET_DISCOVER_ORIGIN_AND_METADATA =
  'draftMessages/setDiscoverOriginAndMetadata';
export const SET_DISCOVER_IFRAME_CONTEXT =
  'draftMessages/setDiscoverIframeContext';
export const CHANGE_DRAFT = 'draftMessages/change';
export const CLEAR_DRAFT = 'draftMessages/clear';
export const ADD_SCHEDULE = 'draftMessages/addSchedule';
export const CLEAR_NEW_MESSAGE = 'draftMessages/clearNewMessage';
export const SET_SCHEDULE_TYPE = 'draftMessages/setScheduleType';
export const UPDATE_SCHEDULED_DRAFT = 'draftMessages/updateDraft';
export const SET_SCHEDULED_DRAFT = 'draftMessages/setDraft';
export const SET_PHONE_TYPES = 'draftMessages/setPhoneTypes';
export const CLEAR_PHONE_TYPES = 'draftMessages/clearPhoneTypes';
export const CLEAR_RESTORED_PHONE_NUMBERS =
  'draftMessages/clearRestoredPhoneNumbers';
export const SET_RESTORED_PHONE_NUMBERS =
  'draftMessages/setRestoredPhoneNumbers';
export const SET_RESTORED_LANDLINE_NUMBERS =
  'draftMessages/setRestoredLandlineNumbers';
export const REMOVE_NUMBER_FROM_RESTORED_PHONE_NUMBERS =
  'draftMessages/removeNumberRestoredPhoneNumbers';
export const SET_BROADCAST_SUPPRESSION_TIMEFRAME =
  'draftMessages/setBroadcastSuppressionTimeFrame';
export const CLEAR_BROADCAST_SUPPRESSION_TIMEFRAME =
  'draftMessages/clearBroadcastSuppressionTimeFrame';
export const ADD_FILES: 'chat/file-upload/add-files' =
  'chat/file-upload/add-files';
export const CLEAR_FILES: 'chat/file-upload/clear-files' =
  'chat/file-upload/clear-files';
export const REMOVE_FILE: 'chat/file-upload/remove-file' =
  'chat/file-upload/remove-file';
export const REMOVE_FILE_ERROR: 'chat/file-upload/remove-error' =
  'chat/file-upload/remove-error';

export const ERROR_FILE_SIZE = 'error-file-size';
export const ERROR_FILE_TYPE = 'error-file-type';
export const ERROR_FILE_COUNT = 'error-file-count';

export type Action =
  | InputPhoneAction
  | SetMultipleRecipientsAction
  | ChangeDraftAction
  | ClearDraftAction
  | AddScheduleAction
  | ClearNewMessageAction
  | SetScheduleTypeAction
  | UpdateDraftAction
  | ClearFilesAction
  | RemoveFileAction
  | RemoveFileUploadErrorAction
  | SetPhoneTypesAction
  | ClearPhoneTypesAction
  | SetRestoredPhoneNumbersAction
  | SetRestoredLandlineNumbersAction
  | RemoveNumberFromRestoredPhoneNumbersAction
  | SetBroadcastSuppressionTimeFrameAction
  | ClearBroadcastSuppressionTimeFrameAction
  | ClearRestoredPhoneNumbersAction;

type InputPhoneAction = {
  type: 'draftMessages/inputPhone',
  payload: ?string,
};

export const inputPhone = (phone: ?string): InputPhoneAction => ({
  type: INPUT_PHONE,
  payload: phone,
});

type ChangeDraftAction = {
  type: 'draftMessages/change',
  payload: {
    to: string,
    body: string,
  },
};

export const setMultipleRecipients = (
  recipients: Array<SuggestionType | string>,
): SetMultipleRecipientsAction => ({
  type: SET_MULTIPLE_RECIPIENTS,
  payload: recipients,
});

type SetMultipleRecipientsAction = {
  type: 'draftMessages/setMultipleRecipients',
  payload: Array<SuggestionType | string>,
};

export const setDiscoverOriginAndMetadata = (
  origin: string,
  candidateDetails: SetOfDiscoverCandidateDetails,
): SetDiscoverOriginAndMetadataAction => ({
  type: SET_DISCOVER_ORIGIN_AND_METADATA,
  payload: {origin, candidateDetails},
});

type SetDiscoverOriginAndMetadataAction = {
  type: typeof SET_DISCOVER_ORIGIN_AND_METADATA,
  payload: {
    origin: string,
    candidateDetails: SetOfDiscoverCandidateDetails,
  },
};

export const setDiscoverIframeContext = (
  payload: boolean,
): SetDiscoverIframeContextAction => ({
  type: SET_DISCOVER_IFRAME_CONTEXT,
  payload,
});

type SetDiscoverIframeContextAction = {
  type: typeof SET_DISCOVER_IFRAME_CONTEXT,
  payload: boolean,
};

export const changeDraft = (
  to: string,
  nextDraft: string,
): ChangeDraftAction => ({
  type: CHANGE_DRAFT,
  payload: {
    to,
    body: nextDraft,
  },
});

type ClearDraftAction = {
  type: 'draftMessages/clear',
  payload: string,
};

export const clearDraft = (to: string): ClearDraftAction => ({
  type: CLEAR_DRAFT,
  payload: to,
});

type ClearNewMessageAction = {
  type: 'draftMessages/clearNewMessage',
};

export const clearNewMessage = (): ClearNewMessageAction => ({
  type: CLEAR_NEW_MESSAGE,
});

type AddScheduleAction = {
  type: 'draftMessages/addSchedule',
  payload: string,
  meta: {
    toPhone: string,
  },
};

export const addSchedule = (
  toPhone: string,
  date: string,
): AddScheduleAction => ({
  type: ADD_SCHEDULE,
  payload: date,
  meta: {
    toPhone,
  },
});

type SetScheduleTypeAction = {
  type: 'draftMessages/setScheduleType',
  payload: {
    toPhone: string,
    scheduleType: BroadcastScheduleType,
    sendDate: string,
  },
};

export const setScheduleType = (
  toPhone: *,
  scheduleType: *,
  sendDate: *,
): SetScheduleTypeAction => ({
  type: SET_SCHEDULE_TYPE,
  payload: {
    toPhone,
    scheduleType,
    sendDate,
  },
});

type UpdateDraftAction = {
  type: 'draftMessages/updateDraft',
  payload: {
    draftKey: string,
    draft: Object,
  },
};

// This literally splats the draft over the prior draft for given draftKey
export const updateDraft = (
  draftKey: string,
  draft: Object,
): UpdateDraftAction => ({
  type: UPDATE_SCHEDULED_DRAFT,
  payload: {
    draftKey,
    draft,
  },
});

type SetDraftAction = {
  type: 'draftMessages/setDraft',
  payload: {
    draftKey: string,
    draft: Object,
  },
};

/*
This is different from updateDraft, it replaces the draft and gets a new id
this is needed because DumbMessageCompose will not rerender if the id does not change
use setDraft when you want to create a new draft even when the user is focusing on compose.
*/
export const setDraft = (draftKey: string, draft: Object): SetDraftAction => ({
  type: SET_SCHEDULED_DRAFT,
  payload: {
    draftKey,
    draft,
  },
});

export const sendDraftBroadcast =
  (
    draftKey: string,
    ignorePhoneNumberList?: Array<string> = [],
  ): ThunkAction<?ApiBroadcast> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const {recipients, drafts} = getState().draftMessages;
    const scheduledMessage = drafts[draftKey];

    invariant(scheduledMessage, 'There is no message for this draftKey');

    let broadcast = {
      body: scheduledMessage.body,
      dripMessage: scheduledMessage.dripMessage,
      templateId: scheduledMessage.templateId,
      schedulerTemplateId: scheduledMessage.schedulerTemplateId,
    };

    if (scheduledMessage.sendDate) {
      broadcast = {...broadcast, sendDate: scheduledMessage.sendDate};
    } else {
      broadcast = {...broadcast, sendNow: true};
    }

    invariant(recipients.length > 0, 'Must have at least 1 recipient');

    const firstRecipient = recipients[0];
    if (
      typeof firstRecipient === 'object' &&
      firstRecipient.threadListSuggestion
    ) {
      broadcast = {
        ...broadcast,
        threadListId: firstRecipient.threadListSuggestion.id,
      };
    } else {
      broadcast = {...broadcast, phoneNumbers: []};
      for (const recipient of recipients) {
        if (typeof recipient === 'string') {
          broadcast.phoneNumbers.push(recipient);
        } else if (recipient.atsContact) {
          // TODO (kyle): should server handle external contacts?
          broadcast.phoneNumbers.push(recipient.atsContact.phoneNumber);
        } else if (recipient.audienceMember) {
          broadcast.phoneNumbers.push(recipient.audienceMember.phoneNumber);
        } else {
          const contactPhoneNumber =
            recipient.contact && getPrimaryPhone(recipient.contact);
          if (contactPhoneNumber) {
            broadcast.phoneNumbers.push(contactPhoneNumber);
          }
        }
      }
    }

    const errors = validateBroadcast(broadcast, recipients.length);
    if (errors) {
      throw errors;
    }

    dispatch(clearDraft(draftKey));
    try {
      const response = await dispatch(
        createBroadcast(broadcast, ignorePhoneNumberList),
      );
      return response;
    } catch (error) {
      // reinsert the draft and throw an error (to trigger a modal)
      dispatch(updateDraft(draftKey, scheduledMessage));
      throw error;
    }
  };

function addError(errors, error) {
  if (!errors.includes(error)) {
    return [...errors, error];
  }

  return errors;
}

export const addAndValidateFiles =
  (draftKey: string, files: File[]): ThunkSyncAction =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    const prevFiles = selectFilesForUpload(state, draftKey);
    const prevErrors = selectFileUploadErrors(state, draftKey);
    const fileTypeRegex = selectAllowedMediaFilenameRegex(state);

    const nextFiles = [...prevFiles];
    let nextErrors = prevErrors;

    for (const file of files) {
      if (nextFiles.length >= 5) {
        nextErrors = addError(nextErrors, ERROR_FILE_COUNT);
        break;
      }
      if (file && !nextFiles.includes(file)) {
        if (file.size >= 5000000) {
          nextErrors = addError(nextErrors, ERROR_FILE_SIZE);
        } else if (!fileTypeRegex.test(file.name)) {
          nextErrors = addError(nextErrors, ERROR_FILE_TYPE);
        } else {
          nextFiles.push(file);
        }
      }
    }

    dispatch(updateDraft(draftKey, {files: nextFiles, fileErrors: nextErrors}));
  };

type RemoveFileUploadErrorAction = {
  type: 'chat/file-upload/remove-error',
  payload: {draftKey: string, error: string},
};

export const removeFileUploadError = (
  draftKey: string,
  error: string,
): RemoveFileUploadErrorAction => ({
  type: REMOVE_FILE_ERROR,
  payload: {draftKey, error},
});

type ClearFilesAction = {
  type: 'chat/file-upload/clear-files',
  payload: {draftKey: string},
};

export const clearFiles = (draftKey: string): ClearFilesAction => ({
  type: CLEAR_FILES,
  payload: {draftKey},
});

type RemoveFileAction = {
  type: 'chat/file-upload/remove-file',
  payload: {draftKey: string, file: File},
};

export const removeFile = (draftKey: string, file: File): RemoveFileAction => ({
  type: REMOVE_FILE,
  payload: {draftKey, file},
});

type SetPhoneTypesAction = {
  type: 'draftMessages/setPhoneTypes',
  payload: {value: BroadcastSuppressionPhoneDetails[]},
};

export const setPhoneTypesEvent = (
  value: BroadcastSuppressionPhoneDetails[],
): SetPhoneTypesAction => ({
  type: SET_PHONE_TYPES,
  payload: {value},
});

type ClearPhoneTypesAction = {
  type: 'draftMessages/clearPhoneTypes',
};

export const clearPhoneTypesEvent = (): ClearPhoneTypesAction => ({
  type: CLEAR_PHONE_TYPES,
});

type ClearRestoredPhoneNumbersAction = {
  type: 'draftMessages/clearRestoredPhoneNumbers',
};

export const clearRestoredPhoneNumbersEvent =
  (): ClearRestoredPhoneNumbersAction => ({
    type: CLEAR_RESTORED_PHONE_NUMBERS,
  });

type SetRestoredPhoneNumbersAction = {
  type: 'draftMessages/setRestoredPhoneNumbers',
  payload: {value: Array<string>},
};

export const setRestoredPhoneNumbersEvent = (
  value: Array<string>,
): SetRestoredPhoneNumbersAction => ({
  type: SET_RESTORED_PHONE_NUMBERS,
  payload: {value},
});

type SetRestoredLandlineNumbersAction = {
  type: 'draftMessages/setRestoredLandlineNumbers',
  payload: {value: Array<string>},
};

export const setRestoredLandlineNumbersEvent = (
  value: Array<string>,
): SetRestoredLandlineNumbersAction => ({
  type: SET_RESTORED_LANDLINE_NUMBERS,
  payload: {value},
});

type RemoveNumberFromRestoredPhoneNumbersAction = {
  type: 'draftMessages/removeNumberRestoredPhoneNumbers',
  payload: {value: Array<string>},
};

export const removeNumberFromRestoredPhoneNumbersEvent = (
  value: Array<string>,
): RemoveNumberFromRestoredPhoneNumbersAction => ({
  type: REMOVE_NUMBER_FROM_RESTORED_PHONE_NUMBERS,
  payload: {value},
});

type SetBroadcastSuppressionTimeFrameAction = {
  type: 'draftMessages/setBroadcastSuppressionTimeFrame',
  payload: {value: TimeFrame},
};

export const setBroadcastSuppressionTimeFrameEvent = (
  value: TimeFrame,
): SetBroadcastSuppressionTimeFrameAction => ({
  type: SET_BROADCAST_SUPPRESSION_TIMEFRAME,
  payload: {value},
});

type ClearBroadcastSuppressionTimeFrameAction = {
  type: 'draftMessages/clearBroadcastSuppressionTimeFrame',
};

export const clearBroadcastSuppressionTimeFrameEvent =
  (): ClearBroadcastSuppressionTimeFrameAction => ({
    type: CLEAR_BROADCAST_SUPPRESSION_TIMEFRAME,
  });
