// @flow strict

import type {
  GenericError,
  Pages,
  Leads,
  Forms,
  FormsDetail,
  Page,
  ACTION_TYPE,
  Action,
  UpdateFormData,
  FormEntityType,
  UpdateLeadData,
  EntityGroup,
  CreateSubDomainPayload,
  SubDomainDetail,
  LeadsFilter,
  LeadsPreviewFilter,
  LeadsCreateFilter,
  LeadsCreateFilterResponse,
  FilterIds,
  SelectedFilterView,
  LeadsCreateFilterResponseItem,
  FilterViewCount,
  FilterViewActionType,
  LeadFilterActionResponse,
  LeadForm,
  LeadsFilterActionResponse,
  BeFreeJson,
} from 'src/types/landing-page';

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction, GetState} from 'src/reducers';
import type {LandingPageState} from 'src/reducers/landing-page';
// $FlowFixMe[nonstrict-import]
import type {Query} from 'src/types/router';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {camel} from 'src/utils';
// $FlowFixMe[untyped-import]
import {key, cached, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff.js';
import {selectLandingPagesDetail} from 'src/selectors/landing-page';
import {SENSE_CANDIDATE_LEAD} from 'src/components/landing-page/constants';
import {leadsFilterInitialState} from 'src/reducers/landing-page';


const {stringify} = JSON;
export const DEFAULT_ERROR: GenericError = {
  field_errors: {},
  non_field_errors: [
    {
      type: 'default',
      message: 'Some Error occoured',
    },
  ],
};
export type LandingPageSteps = 'details' | 'editor' | 'editDetails';

export type UpdateStatePayload = $Shape<LandingPageState>;

// Define Consts here

export const LANDING_PAGES = 'landingPage/landingPage';
export const LANDING_PAGES_ERROR = 'landingPage/landingPage-error';
export const FETCH_LEADS_LOADING = 'landingPage/leads-loading';
export const FETCH_LEADS = 'landingPage/leads';
export const FETCH_LEADS_ERROR = 'landingPage/leads-error';
export const FETCH_FORMS_LOADING = 'landingPage/forms-loading';
export const FETCH_FORMS = 'landingPage/forms';
export const FETCH_FORMS_ERROR = 'landingPage/forms-error';
export const FETCH_FORMS_DETAIL_LOADING = 'landingPage/forms-detail-loading';
export const FETCH_FORMS_DETAIL = 'landingPage/forms-detail';
export const FETCH_FORMS_DETAIL_ERROR = 'landingPage/forms-error-detail';
export const LANDING_PAGES_DETAIL = 'landingPage/landingPage-detail';
export const LANDING_PAGES_DETAIL_ERROR =
  'landingPage/landingPage-detail-error';
export const LANDING_PAGES_DETAIL_LEADS_LOADING =
  'landingPage/landingPage-detail-leads-loading';
export const LANDING_PAGES_DETAIL_LEADS =
  'landingPage/landingPage-detail-leads';
export const LANDING_PAGES_DETAIL_LEADS_ERROR =
  'landingPage/landingPage-detail-leads-error';
export const RESET = 'landing-page/reset';
export const RESET_PAGE = 'landing-page/resetPage';
export const GOTO_STEP = 'landing-page/goto-step';
export const UPDATE_LANDING_PAGE = 'landing-page/page/update';
export const UPDATE_LANDING_PAGE_LOADING =
  'landingPage/update-landingPage-loading';
export const UPDATE_LANDING_PAGE_ERROR = 'landingPage/update-landingPage-error';
export const CHECKING_URL_AVAILABILITY =
  'landingPage/checking-url-availability';
export const UPDATE_STATE = 'landing-page/update';
export const LANDING_PAGES_ACTIONS_LOADING =
  'landingPage/landingPage-actions-loading';
export const LANDING_PAGES_ACTIONS = 'landingPage/landingPage-actions';
export const LANDING_PAGES_ACTIONS_ERROR = 'landingPage/update-actions-error';
export const LANDING_PAGES_UPDATE_FORM_ACTION_LOADING =
  'landingPage/landingPage-update-form-action-loading';
export const LANDING_PAGES_UPDATE_FORM_ACTION =
  'landingPage/landingPage-update-from-action';
export const LANDING_PAGES_UPDATE_FORM_ACTION_ERROR =
  'landingPage/landingPage-update-form-action-error';

export const FETCH_ATTRIBUTES_LOADING = 'landingPage/attribute-loading';
export const FETCH_ATTRIBUTES = 'landingPage/attribute';
export const FETCH_ATTRIBUTES_ERROR = 'landingPage/attribute-detail';

export const LANDING_PAGES_UPDATE_LEAD_ACTION_LOADING =
  'landingPage/landingPage-update-lead-action-loading';
export const LANDING_PAGES_UPDATE_LEAD_ACTION =
  'landingPage/landingPage-update-lead-action';
export const LANDING_PAGES_UPDATE_LEAD_ACTION_ERROR =
  'landingPage/landingPage-update-lead-action-error';

export const FETCH_ENTITY_GROUPS_LOADING = 'landingPage/entity-groups-loading';
export const FETCH_ENTITY_GROUPS = 'landingPage/entity-groups';
export const FETCH_ENTITY_GROUPS_ERROR = 'landingPage/entity-groups-error';

export const CREATE_SUBDOMAIN = 'landing-page/create-subdomain';
export const CREATE_SUBDOMAIN_ERROR = 'landingPage/create-subdomain-error';
export const VERIFY_SUBDOMAIN = 'landing-page/verify-subdomain';
export const VERIFY_SUBDOMAIN_ERROR = 'landingPage/verify-subdomain-error';
export const CANDIDATE_SYNC = 'landingPage/candidate_sync';
export const CANDIDATE_SYNC_ERROR = 'landingPage/candidate_sync-error';

export const UPDATE_SELECTED_VIEW = 'leads-filter/update_selected_view';
export const UPDATE_SELECTED_VIEW_FILTER =
  'leads-filter/update_selected_view_filter';
export const LEADS_FILTER_PREVIEW = 'leads-filters/preview';
export const LEADS_FILTER_CREATE = 'leads-filters/create';
export const LEADS_FILTER_VIEW = 'leads-filters/view';
export const LEADS_FILTER_VIEW_SAVE_APPLY = 'leads-filters/view-save-apply';
export const LEADS_FILTER_VIEW_COUNT = 'leads-filters/view-count';
export const LEADS_FILTER_ACTION = 'leads-filters/view-action';
export const LEADS_FILTER_EXPORT = 'leads-filters/view-export';
export const LEADS_FILTER_ACTION_RESPONSE =
  'leads-filters/lead-filter-action-response';
export const PAGES_DEFAULT_TEMPLATE = 'pages-default-template';

// Define Types here
type ReceiveLandingPageAction = {
  type: typeof LANDING_PAGES,
  payload: ?(Pages[]),
};

type ReceiveLandingPageErrorAction = {
  type: typeof LANDING_PAGES_ERROR,
  payload: ?GenericError,
};

type ReceiveLandingPageDetailAction = {
  type: typeof LANDING_PAGES_DETAIL,
  payload: ?Pages,
};

type ReceiveLandingPageDetailErrorAction = {
  type: typeof LANDING_PAGES_DETAIL_ERROR,
  payload: ?GenericError,
};

type ReceiveLandingPageDetailLeadsLoadingAction = {
  type: typeof LANDING_PAGES_DETAIL_LEADS_LOADING,
};

type ReceiveLandingPageDetailLeadsAction = {
  type: typeof LANDING_PAGES_DETAIL_LEADS,
  payload: ?Pages,
};

type ReceiveLandingPageDetailLeadsErrorAction = {
  type: typeof LANDING_PAGES_DETAIL_LEADS_ERROR,
  payload: ?GenericError,
};

type ReceiveLandingPageLeadsLoadingAction = {
  type: typeof FETCH_LEADS_LOADING,
};

type ReceiveLandingPageLeadsAction = {
  type: typeof FETCH_LEADS,
  payload: ?(Leads[]),
};

type ReceiveLandingPageLeadsErrorAction = {
  type: typeof FETCH_LEADS_ERROR,
  payload: ?GenericError,
};

type ReceiveLandingPageFormsLoadingAction = {
  type: typeof FETCH_FORMS_LOADING,
};

type ReceiveLandingPageFormsAction = {
  type: typeof FETCH_FORMS,
  payload: ?Forms,
};

type ReceiveLandingPageFormsErrorAction = {
  type: typeof FETCH_FORMS_ERROR,
  payload: ?GenericError,
};

type ReceiveLandingPageFormsDetailLoadingAction = {
  type: typeof FETCH_FORMS_DETAIL_LOADING,
};

type ReceiveLandingPageFormsDetailAction = {
  type: typeof FETCH_FORMS_DETAIL,
  payload: ?FormsDetail,
};

type ReceiveLandingPageFormsDetailErrorAction = {
  type: typeof FETCH_FORMS_DETAIL_ERROR,
  payload: ?GenericError,
};
type ResetStateAction = {
  type: typeof RESET,
};
type ResetPageStateAction = {
  type: typeof RESET_PAGE,
};

type GotoStepAction = {
  type: 'landing-page/goto-step',
  payload: LandingPageSteps,
};

type UpdateLandingPageAction = {
  type: typeof UPDATE_LANDING_PAGE,
  payload: ?Page,
};
type UpdateLandingPageLoadingAction = {
  type: typeof UPDATE_LANDING_PAGE_LOADING,
};
type UpdateLandingPageErrorAction = {
  type: typeof UPDATE_LANDING_PAGE_ERROR,
  payload: ?GenericError,
};

type CheckingUrlAvailabilityAction = {
  type: typeof CHECKING_URL_AVAILABILITY,
};
type UpdateStateAction = {
  type: 'landing-page/update',
  payload: UpdateStatePayload,
};
type UpdateLandingPageActionsLoadingAction = {
  type: typeof LANDING_PAGES_ACTIONS_LOADING,
};

type UpdateLandingPageActions = {
  type: typeof LANDING_PAGES_ACTIONS,
  payload: ?Action,
};

type UpdateLandingPageActionsErrorAction = {
  type: typeof LANDING_PAGES_ACTIONS_ERROR,
  payload: ?GenericError,
};

type UpdateLandingPageUpdateFormLoadingAction = {
  type: typeof LANDING_PAGES_UPDATE_FORM_ACTION_LOADING,
};

type UpdateLandingPageUpdateFormAction = {
  type: typeof LANDING_PAGES_UPDATE_FORM_ACTION,
  payload: ?UpdateFormData,
};

type UpdateLandingPageUpdateFormErrorAction = {
  type: typeof LANDING_PAGES_UPDATE_FORM_ACTION_ERROR,
  payload: ?GenericError,
};

type ReceiveAttributeLoadingAction = {
  type: typeof FETCH_ATTRIBUTES_LOADING,
};

type ReceiveAttributeAction = {
  type: typeof FETCH_ATTRIBUTES,
  payload: ?(FormEntityType[]),
};

type ReceiveAttributeErrorAction = {
  type: typeof FETCH_ATTRIBUTES_ERROR,
  payload: ?GenericError,
};

type UpdateLandingPageUpdateLeadLoadingAction = {
  type: typeof LANDING_PAGES_UPDATE_LEAD_ACTION_LOADING,
};

type UpdateLandingPageUpdateLeadAction = {
  type: typeof LANDING_PAGES_UPDATE_LEAD_ACTION,
  payload: ?UpdateLeadData,
};

type UpdateLandingPageUpdateLeadErrorAction = {
  type: typeof LANDING_PAGES_UPDATE_LEAD_ACTION_ERROR,
  payload: ?GenericError,
};

type ReceiveEntityGroupLoadingAction = {
  type: typeof FETCH_ENTITY_GROUPS_LOADING,
};

type ReceiveEntityGroupAction = {
  type: typeof FETCH_ENTITY_GROUPS,
  payload: ?EntityGroup,
};

type ReceiveEntityGroupErrorAction = {
  type: typeof FETCH_ENTITY_GROUPS_ERROR,
  payload: ?GenericError,
};

type CreateSubDomainAction = {
  type: typeof CREATE_SUBDOMAIN,
  payload: ?SubDomainDetail,
};

type CreateSubDomainErrorAction = {
  type: typeof CREATE_SUBDOMAIN_ERROR,
  payload: ?GenericError,
};

type VerifySubDomainAction = {
  type: typeof VERIFY_SUBDOMAIN,
  payload: string,
};

type VerifySubDomainErrorAction = {
  type: typeof VERIFY_SUBDOMAIN_ERROR,
  payload: ?GenericError,
};

type CandidateSyncAction = {
  type: typeof CANDIDATE_SYNC,
  payload: ?string,
};

type CandidateSyncErrorAction = {
  type: typeof CANDIDATE_SYNC_ERROR,
  payload: ?GenericError,
};

type UpdateSelectedViewAction = {
  type: typeof UPDATE_SELECTED_VIEW,
  payload: SelectedFilterView,
};

type UpdateSelectedViewFilterAction = {
  type: typeof UPDATE_SELECTED_VIEW_FILTER,
  payload: ?(string[]),
};

type LeadsPreviewFilterAction = {
  type: typeof LEADS_FILTER_PREVIEW,
  payload: ?LeadsPreviewFilter,
};

type LeadsCreateFilterAction = {
  type: typeof LEADS_FILTER_CREATE,
  payload: ?LeadsCreateFilter,
};

type LeadsViewFilterAction = {
  type: typeof LEADS_FILTER_VIEW,
  payload: ?LeadsCreateFilterResponseItem,
};

type LeadsViewFilterSaveApplyAction = {
  type: typeof LEADS_FILTER_VIEW_SAVE_APPLY,
  payload: ?(LeadForm[]),
};

type LeadsViewFilterCountAction = {
  type: typeof LEADS_FILTER_VIEW_COUNT,
  payload: ?FilterViewCount,
};

type LeadsViewActionTypeAction = {
  type: typeof LEADS_FILTER_ACTION,
  payload: ?FilterViewCount,
};

type LeadsViewExportAction = {
  type: typeof LEADS_FILTER_EXPORT,
  payload: ?FilterViewCount,
};

type LeadsViewFilterResponseAction = {
  type: typeof LEADS_FILTER_ACTION_RESPONSE,
  payload: ?LeadsFilterActionResponse,
};

type PagesDefaultTemplateAction = {
  type: typeof PAGES_DEFAULT_TEMPLATE,
  payload: ?BeFreeJson,
};

export type LandingPageActions =
  | ReceiveLandingPageAction
  | ReceiveLandingPageErrorAction
  | ReceiveLandingPageLeadsLoadingAction
  | ReceiveLandingPageLeadsAction
  | ReceiveLandingPageLeadsErrorAction
  | ReceiveLandingPageFormsLoadingAction
  | ReceiveLandingPageFormsAction
  | ReceiveLandingPageFormsErrorAction
  | ReceiveLandingPageFormsDetailLoadingAction
  | ReceiveLandingPageFormsDetailAction
  | ReceiveLandingPageFormsDetailErrorAction
  | ResetStateAction
  | ResetPageStateAction
  | GotoStepAction
  | UpdateLandingPageAction
  | UpdateLandingPageLoadingAction
  | UpdateLandingPageErrorAction
  | CheckingUrlAvailabilityAction
  | UpdateStateAction
  | ReceiveLandingPageDetailAction
  | ReceiveLandingPageDetailErrorAction
  | UpdateLandingPageActionsLoadingAction
  | UpdateLandingPageActions
  | UpdateLandingPageActionsErrorAction
  | UpdateLandingPageUpdateFormLoadingAction
  | UpdateLandingPageUpdateFormAction
  | UpdateLandingPageUpdateFormErrorAction
  | ReceiveAttributeLoadingAction
  | ReceiveAttributeAction
  | ReceiveAttributeErrorAction
  | UpdateLandingPageUpdateLeadLoadingAction
  | UpdateLandingPageUpdateLeadAction
  | UpdateLandingPageUpdateLeadErrorAction
  | ReceiveEntityGroupLoadingAction
  | ReceiveEntityGroupAction
  | ReceiveEntityGroupErrorAction
  | CreateSubDomainAction
  | CreateSubDomainErrorAction
  | VerifySubDomainAction
  | VerifySubDomainErrorAction
  | CandidateSyncAction
  | CandidateSyncErrorAction
  | UpdateSelectedViewAction
  | UpdateSelectedViewFilterAction
  | LeadsPreviewFilterAction
  | LeadsCreateFilterAction
  | LeadsViewFilterAction
  | LeadsViewFilterSaveApplyAction
  | LeadsViewFilterCountAction
  | LeadsViewActionTypeAction
  | LeadsViewExportAction
  | LeadsViewFilterResponseAction
  | PagesDefaultTemplateAction;

export const getLandingPages = (
  payload: Pages[],
): ReceiveLandingPageAction => ({
  type: LANDING_PAGES,
  payload,
});

export const getLandingPageLeads = (
  payload: Leads[],
): ReceiveLandingPageLeadsAction => ({
  type: FETCH_LEADS,
  payload,
});

export const getLandingPageDetail = (
  payload: Pages,
): ReceiveLandingPageDetailAction => ({
  type: LANDING_PAGES_DETAIL,
  payload,
});

export const getLandingPageDetailError = (
  payload: ?GenericError,
): ReceiveLandingPageDetailErrorAction => ({
  type: LANDING_PAGES_DETAIL_ERROR,
  payload,
});

export const getLandingPageLeadsLoading =
  (): ReceiveLandingPageLeadsLoadingAction => ({
    type: FETCH_LEADS_LOADING,
  });
export const resetLandingPage = (): ResetStateAction => ({type: RESET});

export const resetPage = (): ResetPageStateAction => ({type: RESET_PAGE});

export const goToStep = (step: LandingPageSteps): GotoStepAction => ({
  type: GOTO_STEP,
  payload: step,
});

export const updateState = (
  payload: UpdateStatePayload,
): UpdateStateAction => ({
  type: UPDATE_STATE,
  payload,
});

export const getLandingPageError = (
  payload: ?GenericError,
): ReceiveLandingPageErrorAction => ({
  type: LANDING_PAGES_ERROR,
  payload,
});

export const getLandingPageForms = (
  payload: Forms,
): ReceiveLandingPageFormsAction => ({
  type: FETCH_FORMS,
  payload,
});

export const getLandingPageFormsLoading =
  (): ReceiveLandingPageFormsLoadingAction => ({
    type: FETCH_FORMS_LOADING,
  });

export const getLandingPageFormError = (
  payload: ?GenericError,
): ReceiveLandingPageFormsErrorAction => ({
  type: FETCH_FORMS_ERROR,
  payload,
});

export const getLandingPageFormsDetail = (
  payload: FormsDetail | null,
): ReceiveLandingPageFormsDetailAction => ({
  type: FETCH_FORMS_DETAIL,
  payload,
});

export const getLandingPageFormsDetailLoading =
  (): ReceiveLandingPageFormsDetailLoadingAction => ({
    type: FETCH_FORMS_DETAIL_LOADING,
  });

export const getLandingPageFormDetailError = (
  payload: ?GenericError,
): ReceiveLandingPageFormsDetailErrorAction => ({
  type: FETCH_FORMS_DETAIL_ERROR,
  payload,
});

export const updateLandingPage = (payload: Page): UpdateLandingPageAction => ({
  type: UPDATE_LANDING_PAGE,
  payload,
});
export const updateLandingPageLoading = (): UpdateLandingPageLoadingAction => ({
  type: UPDATE_LANDING_PAGE_LOADING,
});
export const updateLandingPageError = (
  payload: ?GenericError,
): UpdateLandingPageErrorAction => ({
  type: UPDATE_LANDING_PAGE_ERROR,
  payload,
});

export const getUrlAvailabilityLoading = (): CheckingUrlAvailabilityAction => ({
  type: CHECKING_URL_AVAILABILITY,
});

export const updateLandingPageAction = (
  payload: Action,
): UpdateLandingPageActions => ({
  type: LANDING_PAGES_ACTIONS,
  payload,
});

export const updateLandingPageActionLoading =
  (): UpdateLandingPageActionsLoadingAction => ({
    type: LANDING_PAGES_ACTIONS_LOADING,
  });

export const updateLandingPageActionError = (
  payload: ?GenericError,
): UpdateLandingPageActionsErrorAction => ({
  type: LANDING_PAGES_ACTIONS_ERROR,
  payload,
});

export const updateLandingPageFormUpdateAction = (
  payload: UpdateFormData,
): UpdateLandingPageUpdateFormAction => ({
  type: LANDING_PAGES_UPDATE_FORM_ACTION,
  payload,
});

export const updateLandingPageFormUpdateLoadingAction =
  (): UpdateLandingPageUpdateFormLoadingAction => ({
    type: LANDING_PAGES_UPDATE_FORM_ACTION_LOADING,
  });

export const updateLandingPageFormUpdateErrorAction = (
  payload: ?GenericError,
): UpdateLandingPageUpdateFormErrorAction => ({
  type: LANDING_PAGES_UPDATE_FORM_ACTION_ERROR,
  payload,
});

export const getAttributes = (
  payload: FormEntityType[],
): ReceiveAttributeAction => ({
  type: FETCH_ATTRIBUTES,
  payload,
});

export const getAttributesLoading = (): ReceiveAttributeLoadingAction => ({
  type: FETCH_ATTRIBUTES_LOADING,
});

export const getAttributesError = (
  payload: ?GenericError,
): ReceiveAttributeErrorAction => ({
  type: FETCH_ATTRIBUTES_ERROR,
  payload,
});

export const updateLandingPageLeadUpdateAction = (
  payload: UpdateLeadData,
): UpdateLandingPageUpdateLeadAction => ({
  type: LANDING_PAGES_UPDATE_LEAD_ACTION,
  payload,
});

export const updateLandingPageLeadUpdateLoadingAction =
  (): UpdateLandingPageUpdateLeadLoadingAction => ({
    type: LANDING_PAGES_UPDATE_LEAD_ACTION_LOADING,
  });

export const updateLandingPageLeadUpdateErrorAction = (
  payload: ?GenericError,
): UpdateLandingPageUpdateLeadErrorAction => ({
  type: LANDING_PAGES_UPDATE_LEAD_ACTION_ERROR,
  payload,
});

export const getEntityGroupsData = (
  payload: EntityGroup,
): ReceiveEntityGroupAction => ({
  type: FETCH_ENTITY_GROUPS,
  payload,
});

export const getEntityGroupsDataLoading =
  (): ReceiveEntityGroupLoadingAction => ({
    type: FETCH_ENTITY_GROUPS_LOADING,
  });

export const getEntityGroupsDataError = (
  payload: ?GenericError,
): ReceiveEntityGroupErrorAction => ({
  type: FETCH_ENTITY_GROUPS_ERROR,
  payload,
});

export const createLandingPageSubDomain = (
  payload: ?SubDomainDetail,
): CreateSubDomainAction => ({
  type: CREATE_SUBDOMAIN,
  payload,
});

export const createSubDomainError = (
  payload: ?GenericError,
): CreateSubDomainErrorAction => ({
  type: CREATE_SUBDOMAIN_ERROR,
  payload,
});

export const verifyLandingPageSubDomain = (
  payload: string,
): VerifySubDomainAction => ({
  type: VERIFY_SUBDOMAIN,
  payload,
});

export const verifySubDomainError = (
  payload: ?GenericError,
): VerifySubDomainErrorAction => ({
  type: VERIFY_SUBDOMAIN_ERROR,
  payload,
});

export const startCandidateSync = (payload: ?string): CandidateSyncAction => ({
  type: CANDIDATE_SYNC,
  payload,
});

export const startCandidateSyncError = (
  payload: ?GenericError,
): CandidateSyncErrorAction => ({
  type: CANDIDATE_SYNC_ERROR,
  payload,
});

export const updateSelectedViewData = (
  payload: SelectedFilterView,
): UpdateSelectedViewAction => ({
  type: UPDATE_SELECTED_VIEW,
  payload,
});

export const updateSelectedViewFilterData = (
  payload: ?(string[]),
): UpdateSelectedViewFilterAction => ({
  type: UPDATE_SELECTED_VIEW_FILTER,
  payload,
});

export const leadsPreviewFilterData = (
  payload: ?LeadsPreviewFilter,
): LeadsPreviewFilterAction => ({
  type: LEADS_FILTER_PREVIEW,
  payload,
});

export const leadsCreateFilterData = (
  payload: ?LeadsCreateFilter,
): LeadsCreateFilterAction => ({
  type: LEADS_FILTER_CREATE,
  payload,
});

export const leadsViewFilterData = (
  payload: ?LeadsCreateFilterResponseItem,
): LeadsViewFilterAction => ({
  type: LEADS_FILTER_VIEW,
  payload,
});

export const updateSelectedViewFilterSaveApplyData = (
  payload: ?(LeadForm[]),
): LeadsViewFilterSaveApplyAction => ({
  type: LEADS_FILTER_VIEW_SAVE_APPLY,
  payload,
});

export const leadsViewFilterCountData = (
  payload: ?FilterViewCount,
): LeadsViewFilterCountAction => ({
  type: LEADS_FILTER_VIEW_COUNT,
  payload,
});

export const leadsViewFilterActionData = (
  payload: ?FilterViewCount,
): LeadsViewActionTypeAction => ({
  type: LEADS_FILTER_ACTION,
  payload,
});

export const leadsViewDeleteData = (
  payload: ?FilterViewCount,
): LeadsViewExportAction => ({
  type: LEADS_FILTER_EXPORT,
  payload,
});

export const leadsViewFilterActionResponseData = (
  payload: ?LeadsFilterActionResponse,
): LeadsViewFilterResponseAction => ({
  type: LEADS_FILTER_ACTION_RESPONSE,
  payload,
});

export const pagesDefaultTemplateResponse = (
  payload: ?BeFreeJson,
): PagesDefaultTemplateAction => ({
  type: PAGES_DEFAULT_TEMPLATE,
  payload,
});

// API CALLS

export const getPages: () => ThunkAction<void> = flow(
  key(() => `sensepages/pages`),
  fetching(),
)(() => (dispatch: Dispatch) => {
  return dispatch(reduxApi.get(`landing-pages/page`)).then(
    (response: Pages[]) => {
      dispatch(getLandingPages(response));
    },
    (error) => {
      dispatch(getLandingPageError(error?.response?.errors || DEFAULT_ERROR));
    },
  );
});

export const createLandingPage: (data: Page) => ThunkAction<void> = flow(
  key(() => `sensepages/pages`),
  fetching(),
)((data: Page) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.post(`landing-pages/page`, {...data, should_publish: false}),
  ).then(
    (response) => {
      dispatch(getPagesDetail(response)); // Returning this as promise so that we have all the page data before the editor page is loaded
      return response;
    },
    (error) => {
      dispatch(getLandingPageError(error?.response?.errors || DEFAULT_ERROR));
    },
  );
});

// [ToDo] This function is not required as it is same end point -- refactor it later
export const publishLandingPage: (data: Page) => ThunkAction<void> = flow(
  key(() => `sensepages/pages`),
  fetching(),
)((data: Page) => (dispatch: Dispatch, getState: GetState) => {
  dispatch(updateLandingPageLoading());

  return dispatch(
    // $FlowFixMe[incompatible-type]
    reduxApi.post(`landing-pages/page/${data.id}`, {
      ...data,
      should_publish: true,
    }),
  ).then(
    (response: Page) => {
      dispatch(getPages());
      dispatch(updateLandingPage(response)); // updateLandingPage check this function as update detail should be here
    },
    (error) => {
      dispatch(
        updateLandingPageError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const saveLandingPage: (data: Page) => ThunkAction<void> = flow(
  key(() => `sensepages/pages`),
  fetching(),
)((data: Page) => (dispatch: Dispatch, getState: GetState) => {
  dispatch(updateLandingPageLoading());

  return dispatch(
    // $FlowFixMe[incompatible-type]
    reduxApi.post(`landing-pages/page/${data.id}`, {
      ...data,
      should_publish: false,
    }),
  ).then(
    (response: Pages) => {
      dispatch(getPages());
      dispatch(getLandingPageDetail(response));
    },
    (error) => {
      dispatch(
        updateLandingPageError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const checkUrlAvailability: () => ThunkAction<void> = flow(
  key(() => `sensepages/pages`),
  fetching(),
)(() => (dispatch: Dispatch, getState: GetState) => {
  dispatch(getUrlAvailabilityLoading());
  const {url = '', id} = selectLandingPagesDetail(getState());

  return dispatch(
    reduxApi.post('landing-pages/page/check-url', {url, id}),
  ).then(
    (response) => {
      if (response.status === 'present') {
        // $FlowFixMe[prop-missing]
        dispatch(getLandingPageDetail({url}));
        dispatch(updateLandingPageActionError(null));
      } else {
        dispatch(updateLandingPageActionError(DEFAULT_ERROR));
      }
    },
    (error) => {
      dispatch(
        updateLandingPageActionError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const getLandingPage: (id: string) => ThunkAction<void> = flow(
  key((id) => `landing-pages/page/${id}`),
  fetching(),
)((id: string) => (dispatch: Dispatch) => {
  dispatch(resetPage());
  dispatch(updateLandingPageLoading());

  return dispatch(reduxApi.get(`landing-pages/page/${id}`)).then(
    (response: Page) => {
      dispatch(updateLandingPage(response));
    },
    (error) => {
      dispatch(
        updateLandingPageError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const getPagesDetail: (id: string) => ThunkAction<void> = flow(
  key((id) => `sensepages/pages_detail/${id}`),
  fetching(),
)((id: string) => (dispatch: Dispatch) => {
  // $FlowFixMe[incompatible-call]
  dispatch(getLandingPageDetail(null));

  return dispatch(reduxApi.get(`landing-pages/page/${id}`)).then(
    (response: Pages) => {
      dispatch(getLandingPageDetail(response));
    },
    (error) => {
      dispatch(
        getLandingPageDetailError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const getPagesLeads: (id: ?string, query: ?string) => ThunkAction<void> =
  flow(
    key((id: ?string) => `landing-pages/leads/${id || ''}`),
    fetching(),
  )((id: ?string, query: ?string) => (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.get(
        `landing-pages/leads${id ? `?page_id=${id}` : ''}${
          id && query
            ? `&query=${query}`
            : !id && query
            ? `?query=${query}`
            : ''
        }`,
      ),
    ).then(
      (response: Leads[]) => {
        dispatch(getLandingPageLeads(response));
      },
      (error) => {
        dispatch(getLandingPageError(error?.response?.errors || DEFAULT_ERROR));
      },
    );
  });

// [ToDo] @rachit please have a look at the about action
export const getPagesForms: (action: ?string) => ThunkAction<void> = flow(
  key(
    (action: ?string) =>
      `sensepages/pages-forms${JSON.stringify(action || '')}`,
  ),
  fetching(),
)((action: ?string) => (dispatch: Dispatch) => {
  if (action !== 'ajax') {
    // To not show loading state on click of "Refresh Forms"
    dispatch(getLandingPageFormsLoading());
  }

  return dispatch(reduxApi.get(`landing-pages/form`)).then(
    (response: Forms) => {
      dispatch(getLandingPageForms(response));
    },
    (error) => {
      dispatch(
        getLandingPageFormError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const getPagesFormsDetail: (id: string) => ThunkAction<void> = flow(
  key((id) => `sensepages/pages-forms-details${id}`),
  fetching(),
)((id: string) => (dispatch: Dispatch) => {
  dispatch(getLandingPageFormsDetailLoading());
  dispatch(getLandingPageFormsDetail(null));

  return dispatch(reduxApi.get(`landing-pages/form/${id}`)).then(
    (response: FormsDetail) => {
      dispatch(getLandingPageFormsDetail(response));
    },
    (error) => {
      dispatch(
        getLandingPageFormDetailError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const updateLandingPageItem: (
  id: string,
  action: string,
) => ThunkAction<void> = flow(
  key((id) => `referral/configure/${stringify(id)}`),
  fetching(),
)((id: string, action: string) => (dispatch: Dispatch) => {
  dispatch(updateLandingPageActionLoading());

  const url = `landing-pages/page/${action}/${id}`;

  return dispatch(reduxApi.post(url)).then(
    (response) => {
      dispatch(updateLandingPageAction(response));
      dispatch(getPages());
    },
    (error) => {
      dispatch(
        updateLandingPageActionError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const updateLandingPageForm: (
  formData: UpdateFormData,
) => ThunkAction<void> = flow(
  key((formData) => `referral/configure/${stringify(formData)}`),
  fetching(),
)((formData: UpdateFormData) => (dispatch: Dispatch) => {
  dispatch(updateLandingPageFormUpdateLoadingAction());

  const url = formData.id
    ? `landing-pages/form/${formData.id}`
    : `landing-pages/form`;

  let updateForm = formData;
  if (formData.id) {
    updateForm = {
      name: formData.name,
    };
  }

  return dispatch(reduxApi.post(url, updateForm)).then(
    (response) => {
      dispatch(updateLandingPageFormUpdateAction(response));
      dispatch(getPagesForms());
    },
    (error) => {
      dispatch(
        updateLandingPageFormUpdateErrorAction(
          error?.response?.errors || DEFAULT_ERROR,
        ),
      );
    },
  );
});

export const getAllAttributes: (entityType: string) => ThunkAction<void> = flow(
  key(() => `landing-pages/form/attributes?entity_type`),
  fetching(),
)((entityType: string) => (dispatch: Dispatch) => {
  dispatch(getAttributesLoading());

  return dispatch(
    reduxApi.get(`landing-pages/form/attributes?entity_type=${entityType}`),
  ).then(
    (response: FormEntityType[]) => {
      dispatch(getAttributes(response));
    },
    (error) => {
      dispatch(getAttributesError(error?.response?.errors || DEFAULT_ERROR));
    },
  );
});

export const updateLandingPageLead: (
  formData: UpdateLeadData,
) => ThunkAction<void> = flow(
  key(
    (formData: UpdateLeadData) => `referral/configure/${stringify(formData)}`,
  ),
  fetching(),
)((formData: UpdateLeadData) => (dispatch: Dispatch) => {
  dispatch(updateLandingPageLeadUpdateLoadingAction());

  // const url = formData.id
  //   ? `entity_operations/sense_candidate_lead/update/${formData.id}`
  //   : `entity_operations/sense_candidate_lead/create`;

  const url = formData.id
    ? `lead-management/sense_candidate_lead/update/${formData.id}`
    : `lead-management/sense_candidate_lead/create`;

  const updateForm: UpdateLeadData = {...formData};
  delete updateForm.id;

  return dispatch(reduxApi.post(url, updateForm)).then(
    (response) => {
      dispatch(updateLandingPageLeadUpdateErrorAction(null));
      dispatch(updateLandingPageLeadUpdateAction(response));
      dispatch(getPagesLeads());
      formData.id ? dispatch(getEntityGroups(formData.id)) : '';
    },
    (error) => {
      dispatch(
        updateLandingPageLeadUpdateErrorAction(
          error?.response?.errors || DEFAULT_ERROR,
        ),
      );
    },
  );
});

export const getEntityGroups: (entityID: string) => ThunkAction<void> = flow(
  key((entityID: string) => `/entity_group/${entityID}`),
  fetching(),
)((entityID: string) => (dispatch: Dispatch) => {
  dispatch(getEntityGroupsDataLoading());

  return dispatch(
    reduxApi.post(`entity_group`, {
      entity_type: SENSE_CANDIDATE_LEAD,
      entity_id: entityID,
      level: 2,
      is_truncated: false,
      required_relations: [],
    }),
  ).then(
    (response: EntityGroup) => {
      dispatch(getEntityGroupsData(response));
    },
    (error) => {
      dispatch(
        getEntityGroupsDataError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const createSubDomain: (
  payload: CreateSubDomainPayload,
) => ThunkAction<void> = flow(
  key((payload) => `landing-pages/subdomain/${stringify(payload)}`),
  fetching(),
)((payload: CreateSubDomainPayload) => (dispatch: Dispatch) => {
  const url = `landing-pages/subdomain`;

  return dispatch(reduxApi.post(url, payload)).then(
    (response: SubDomainDetail) => {
      dispatch(createLandingPageSubDomain(response));
    },
    (error) => {
      dispatch(createSubDomainError(error?.response?.errors || DEFAULT_ERROR));
    },
  );
});

export const verifySubDomain: (verifySubDomainId: string) => ThunkAction<void> =
  flow(
    key(
      (verifySubDomainId: string) =>
        `landing-pages/subdomain/verify/${verifySubDomainId || ''}`,
    ),
    fetching(),
  )((verifySubDomainId: string) => (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.get(`landing-pages/subdomain/verify/${verifySubDomainId}`),
    ).then(
      (response: string) => {
        dispatch(verifyLandingPageSubDomain(response));
      },
      (error) => {
        dispatch(
          verifySubDomainError(error?.response?.errors || DEFAULT_ERROR),
        );
      },
    );
  });
export const candidateSync: (payload: string) => ThunkAction<void> = flow(
  key((payload: string) => `/landing-pages/leads/candidate_sync/${payload}`),
  fetching(),
)((payload: string) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.post(`landing-pages/leads/candidate_sync?entity_id=${payload}`),
  ).then(
    (response: {status: string}) => {
      dispatch(startCandidateSync(response.status));
    },
    (error) => {
      dispatch(
        startCandidateSyncError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

// Filter View For Leads action from here ----
export const updateSelectedView =
  (action: SelectedFilterView): ThunkAction<mixed> =>
  (dispatch: Dispatch) =>
    dispatch(updateSelectedViewData(action));

export const updateSelectedViewFilter =
  (action: string[]): ThunkAction<mixed> =>
  (dispatch: Dispatch) =>
    dispatch(updateSelectedViewFilterData(action));

export const leadsFilterPreview: (
  payload: LeadsPreviewFilter,
) => ThunkAction<void> = flow(
  key(
    (payload: LeadsPreviewFilter) =>
      `filter/preview-for-rule/${JSON.stringify(payload)}`,
  ),
  fetching(),
)((payload: LeadsPreviewFilter) => (dispatch: Dispatch) => {
  return dispatch(reduxApi.post(`filter/preview-for-rule`, {...payload})).then(
    (response: Leads[]) => {
      dispatch(getLandingPageLeads(response));
    },
    (error) => {
      // error case
    },
  );
});

export const leadsFilterCreate: (
  payload: LeadsCreateFilter,
) => ThunkAction<void> = flow(
  key(
    (payload: LeadsCreateFilter) =>
      `/landing-pages/leads/filter_create/${JSON.stringify(payload)}`,
  ),
  fetching(),
)((payload: LeadsCreateFilter) => (dispatch: Dispatch) => {
  if (payload && payload.id) {
    const patchPayload = {
      id: payload.id,
      rule: payload.rule,
      name: payload.name,
    };
    return dispatch(
      reduxApi.patch(`filter/${payload.id}`, {...patchPayload}),
    ).then(
      (response: LeadsCreateFilterResponse) => {
        dispatch(leadsViewFilterList());
      },
      (error) => {
        // error case
      },
    );
  } else {
    return dispatch(reduxApi.post(`filter`, {...payload})).then(
      (response: LeadsCreateFilterResponse) => {
        // dispatch(leadsCreateFilterData(response));
        dispatch(
          leadPublish({
            ids: [response.id],
          }),
        );
      },
      (error) => {
        // error case
      },
    );
  }
});

export const leadsViewFilterList: () => ThunkAction<void> = flow(
  key(() => `filter/search}`),
  fetching(),
)(() => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.get(`filter/search`, {
      entityTypes: 'sense_candidate_lead',
      source: 'LEAD_MANAGEMENT',
      isInternal: true,
      includeRules: true,
    }),
  ).then(
    (response: LeadsCreateFilterResponseItem) => {
      const finalFilterResponse: LeadsCreateFilterResponse[] = response.filters;
      finalFilterResponse.unshift(ALL_LEADS_VIEW);
      dispatch(
        leadsViewFilterData({
          ...response,
          ['filters']: finalFilterResponse,
        }),
      );
    },
    (error) => {
      dispatch(verifySubDomainError(error?.response?.errors || DEFAULT_ERROR));
    },
  );
});

export const leadPublish: (payload: {
  ids: number[],
}) => ThunkAction<void> = flow(
  key((payload: {ids: string[]}) => `filter/${JSON.stringify(payload) || ''}`),
  fetching(),
)((payload: {ids: number[]}) => (dispatch: Dispatch) => {
  return dispatch(
    // ${filterId ? '/' + filterId : ''}
    reduxApi.patch(`filter/publish`, payload),
  ).then(
    (response: LeadsCreateFilterResponseItem) => {
      dispatch(leadPreviewWithId(response.filters[0].id));
      dispatch(leadsViewFilterList());
    },
    (error) => {
      // dispatch(verifySubDomainError(error?.response?.errors || DEFAULT_ERROR));
    },
  );
});

export const leadPreviewWithId: (leadId: number) => ThunkAction<void> = flow(
  key((leadId: number) => `filter/${leadId || ''}`),
  fetching(),
)((leadId: number) => (dispatch: Dispatch) => {
  return dispatch(reduxApi.get(`filter/${leadId}/preview`)).then(
    (response: Leads[]) => {
      dispatch(getLandingPageLeads(response));
      leadsFilterCount;
    },
    (error) => {
      // dispatch(verifySubDomainError(error?.response?.errors || DEFAULT_ERROR));
    },
  );
});

export const deleteLeadsView: (payload: FilterIds) => ThunkAction<void> = flow(
  key((payload: FilterIds) => `filter/${JSON.stringify(payload) || ''}`),
  fetching(),
)((payload: FilterIds) => (dispatch: Dispatch) => {
  return dispatch(reduxApi.del(`filter`, payload)).then(
    (response: string) => {
      dispatch(leadsViewFilterList());
      // dispatch(updateSelectedView(leadsFilterInitialState.selected_view));
    },
    (error) => {
      // dispatch(verifySubDomainError(error?.response?.errors || DEFAULT_ERROR));
    },
  );
});

export const updateSelectedViewFilterSaveApply =
  (action: LeadForm[]): ThunkAction<mixed> =>
  (dispatch: Dispatch) =>
    dispatch(updateSelectedViewFilterSaveApplyData(action));

export const leadsFilterCount: (
  payload: LeadsPreviewFilter,
) => ThunkAction<void> = flow(
  key(
    (payload: LeadsPreviewFilter) =>
      `filter/preview-for-rule/count/${JSON.stringify(payload)}`,
  ),
  fetching(),
)((payload: LeadsPreviewFilter) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.post(`filter/preview-for-rule/count`, {...payload}),
  ).then(
    (response: FilterViewCount) => {
      dispatch(leadsViewFilterCountData(response));
    },
    (error) => {
      // error case
    },
  );
});

export const leadsFilterAction: (
  payload: FilterViewActionType,
  actionType: string,
) => ThunkAction<void> = flow(
  key(
    (payload: FilterViewActionType) =>
      `landing-pages/bulk_actions/${JSON.stringify(payload)}`,
  ),
  fetching(),
)(
  (payload: FilterViewActionType, actionType: string) =>
    (dispatch: Dispatch) => {
      return dispatch(
        reduxApi.post(`landing-pages/bulk_actions/${actionType}`, {...payload}),
      ).then(
        (response: {response: string}) => {
          // showToastFilterAction
          const actionResponse = response.response;
          const action: LeadsFilterActionResponse = {
            message: actionResponse,
            actionType,
            status: actionResponse !== 'failed',
          };
          dispatch(leadsViewFilterActionResponseData(action));
          dispatch(leadsViewFilterList());
        },
        (error) => {
          // error case
        },
      );
    },
);

export const leadsFilterExport: (
  payload: FilterViewActionType,
  actionType: string,
) => ThunkAction<void> = flow(
  key(
    (payload: FilterViewActionType) =>
      `landing-pages/bulk_actions/export/${JSON.stringify(payload)}`,
  ),
  fetching(),
)(
  (payload: FilterViewActionType, actionType: string) =>
    (dispatch: Dispatch) => {
      return dispatch(
        reduxApi.post(`landing-pages/bulk_actions/export/${actionType}`, {
          ...payload,
        }),
      ).then(
        (response: {url: string}) => {
          if (response && response.url) {
            const action: LeadsFilterActionResponse = {
              message: 'success',
              actionType,
              status: true,
            };
            dispatch(leadsViewFilterActionResponseData(action));
            window.open(response.url, '_blank');
          }
        },
        (error) => {
          // error case
        },
      );
    },
);

export const getDefaultPageTemplate: () => ThunkAction<void> = flow(
  key(() => `landing-pages/template/1`),
  fetching(),
)(() => (dispatch: Dispatch) => {
  return dispatch(reduxApi.get(`landing-pages/template/1`)).then(
    (response: BeFreeJson) => {
      dispatch(pagesDefaultTemplateResponse(response));
    },
    (error) => {
      // dispatch(getLandingPageError(error?.response?.errors || DEFAULT_ERROR));
    },
  );
});

// $FlowFixMe[prop-missing]
const ALL_LEADS_VIEW: LeadsCreateFilterResponse = {
  id: 0,
  entityType: 'sense_candidate_lead',
  standardEntityType: 'sense_candidate_lead',
  relatedEntities: [],
  standardRelatedEntities: [],
  rule: {
    all: false,
    exclusions: {
      blocks: [],
    },
    inclusions: null,
  },
  totalMembers: 0,
  timeUpdatedTotalMembers: '2023-04-19 10:52:28.468310+00:00',
  timeCreated: '2023-03-29 12:15:49.997083+00:00',
  timeUpdated: '2023-07-11 16:46:12.386358+00:00',
  deletedCorrespondingAfms: false,
  status: 'live',
  lastUpdatedBy: 'agent:418793700071594857',
  name: 'All Leads',
  target: 'sense_candidate_lead',
  lifeCycleGoal: 'who_signed_up_on_form',
  businessGoal: 'hire',
  ownedSecurityGroupId: '5421608921968039103',
  // $FlowFixMe[incompatible-type]
  metadata: null,
  hasDifferentSecurityGroup: false,
  createdBy: 'agent:3589960670866113728',
};
