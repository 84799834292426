// @flow strict

import * as React from 'react';

import VariableIcon from 'src/images/icons/variable-icon.svg';
import NodeIcon from './node-icon.svg';

import css from './node-link-toggle.css';


export function Toggle({
  value,
  onChange,
}: {
  value: boolean,
  onChange: (boolean) => mixed,
}): React.Node {
  return (
    <div className={css.root} onClick={() => onChange(!value)}>
      <input
        type="checkbox"
        className={css.checkbox}
        checked={value}
        onChange={(event) => onChange(event.currentTarget.checked)}
      />
      <div className={css.toggle}>
        <div className={css.labelOn}>Var</div>
        <div className={css.labelOff}>Node</div>
        <div className={css.node}>
          {value ? <VariableIcon className={css.variableIcon} /> : <NodeIcon />}
        </div>
      </div>
    </div>
  );
}
