/* @flow */

import type {DynamicLabelExtra} from 'src/action-creators/dynamic-labels';
import type {EntityType} from 'src/types/ats-entities';
// $FlowFixMe[untyped-type-import]
import type {Props as TokenListInputProps} from 'src/components/lib/token-list-input/token-list-input.old.jsx';

import * as React from 'react';
import {useDispatch} from 'react-redux';
import noop from 'lodash/noop';

import {dedupBridgedDynamicLabels} from 'src/utils/dynamic-labels';
import classify from 'src/utils/classify';

import {pushModal} from 'src/action-creators/modal';

import {MultiEntityLabel} from 'src/components/lib/markdown-editor/dynamic-text.jsx';

import css from './variable-picker.css';


function MultiEntityVariableSelect({
  containerClassName,
  onChange,
  options,
  errors,
  value,
  entityType,
  allowUnsyncedFields,
  placeholder,
}: {
  options: DynamicLabelExtra[],
  entityType: ?EntityType,
  value?: ?string,
  allowUnsyncedFields?: boolean,
  errors?: string[],
  onChange: (DynamicLabelExtra) => void,
  containerClassName?: string,
  placeholder?: string,
  'data-qa-id'?: string,
}): React.Node {
  const dedupedOptions = React.useMemo(
    () => Array.from(dedupBridgedDynamicLabels(options)),
    [options],
  );

  const dispatch = useDispatch();
  const handleOnFocus = () => {
    dispatch(
      pushModal({
        type: 'VARIABLE_PICKER',
        labels: dedupedOptions,
        insertResult: (dynamicLabel) => {
          onChange(dynamicLabel);
        },
        entityType,
        allowUnsyncedFields,
      }),
    );
  };
  const selectedFieldMeta = React.useMemo(
    () =>
      value ? dedupedOptions.find((field) => value === field.value) : undefined,
    [value],
  );

  return (
    <div
      className={classify(css.modalInputContainer, containerClassName, {
        [css.errorInput]: errors,
      })}
      onClick={handleOnFocus}
    >
      {value ? (
        <MultiEntityLabel onClick={noop} option={selectedFieldMeta}>
          {selectedFieldMeta?.label}
        </MultiEntityLabel>
      ) : (
        <span className={css.placeholder}>
          {placeholder ?? 'select variable'}
        </span>
      )}
    </div>
  );
}

export default MultiEntityVariableSelect;
