// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {ConfigData, Layout} from 'src/types/job-widget';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';


export const RECEIVE_CONFIG_DATA = 'config-data/receive';
export const UPDATE_CONFIG_DATA = 'config-data/update';
export const RESET_DATA = 'data/reset';
export const REMOVE_CONFIG_DATA_FILTER_INCLUDED_ID =
  'config-data/remove-filter-includedId';
export const UPDATE_CONFIG_DATA_ATTRIBUTES = 'config-data/update-attributes';
export const UPDATE_LAYOUT = 'layout/update';

type ReceiveConfigData = {
  type: 'config-data/receive',
  payload: {configData: ConfigData},
};

type UpdateConfigData = {
  type: 'config-data/update',
  payload: {configData: ConfigData},
};

type UpdateLayout = {
  type: 'layout/update',
  payload: Layout,
};

type RemoveConfigDataFilterIncludedId = {
  type: 'config-data/remove-filter-includedId',
  payload: {id: string},
};

type UpdateConfigDataAttributes = {
  type: 'config-data/update-attributes',
  payload: {attribute: string},
};

type ResetData = {
  type: 'data/reset',
};

export type JobListActions =
  | ReceiveConfigData
  | UpdateConfigData
  | UpdateLayout
  | ResetData
  | RemoveConfigDataFilterIncludedId
  | UpdateConfigDataAttributes;

const receiveConfigData = (payload: {
  configData: ConfigData,
}): ReceiveConfigData => ({
  type: 'config-data/receive',
  payload,
});

const updateData = (payload: {configData: ConfigData}): UpdateConfigData => ({
  type: 'config-data/update',
  payload,
});

export const updateLayout = (payload: Layout): UpdateLayout => ({
  type: 'layout/update',
  payload,
});

const removeDataFilterIncludedId = (payload: {
  id: string,
}): RemoveConfigDataFilterIncludedId => ({
  type: 'config-data/remove-filter-includedId',
  payload,
});

const updateDataAttributes = (payload: {
  attribute: string,
}): UpdateConfigDataAttributes => ({
  type: 'config-data/update-attributes',
  payload,
});

const resetData = (): ResetData => ({
  type: 'data/reset',
});

export const getConfigData: (pageId: string) => ThunkAction<mixed> =
  (pageId) => (dispatch: Dispatch) =>
    dispatch(reduxApi.get(`landing-pages/job-widget/config/${pageId}`)).then(
      (payload) => {
        dispatch(
          receiveConfigData({
            configData: payload ?? null,
          }),
        );
      },
    );

export const updateConfigData: (newData: ConfigData) => ThunkAction<mixed> =
  (newData) => (dispatch: Dispatch) =>
    dispatch(reduxApi.post(`landing-pages/job-widget/config`, newData)).then(
      () => {
        dispatch(updateData({configData: newData ?? null}));
      },
    );

export const resetConfigData =
  (): ((dispatch: Dispatch) => void) => (dispatch: Dispatch) => {
    dispatch(resetData());
  };

export const removeConfigDataFilterIncludedId: (
  id: string,
) => ThunkAction<mixed> = (id) => (dispatch: Dispatch) =>
  dispatch(removeDataFilterIncludedId({id}));

export const updateConfigDataAttributes: (
  attribute: string,
) => ThunkAction<mixed> = (attribute) => (dispatch: Dispatch) =>
  dispatch(updateDataAttributes({attribute}));

export const getSiteChatbots: (siteId: string) => ThunkAction<mixed> = flow(
  key((siteId) => `siteChatbots-${siteId}`),
  fetching(),
)(
  (siteId) => (dispatch: Dispatch) =>
    dispatch(reduxApi.get(`landing-pages/career-site/${siteId}/chatbot`))
      .then((response) => {
        return response;
      })
      .catch((err) => console.error(err)),
);

export const updateChatbotForPage: (
  pageId: string,
  chatbotId: string,
) => ThunkAction<mixed> = (pageId, chatbotId) => (dispatch: Dispatch) =>
  dispatch(reduxApi.put(`landing-pages/page/${pageId}/chatbot/${chatbotId}`))
    .then((response) => {
      return response;
    })
    .catch((err) => console.error(err));

export const deleteChatbotForPage: (pageId: string) => ThunkAction<mixed> =
  (pageId) => (dispatch: Dispatch) =>
    dispatch(reduxApi.del(`landing-pages/page/${pageId}/chatbot`))
      .then((response) => {
        return response;
      })
      .catch((err) => console.error(err));

export const getChatbotForPage: (pageId: string) => ThunkAction<mixed> = flow(
  key((pageId) => `chatbot-${pageId}`),
  fetching(),
)(
  (pageId) => (dispatch: Dispatch) =>
    dispatch(reduxApi.get(`landing-pages/page/${pageId}/chatbot`))
      .then((response) => {
        return response;
      })
      .catch((err) => console.error(err)),
);
