// @flow

import type {Contact} from 'src/types/contacts';
// $FlowFixMe[untyped-type-import]
import type {EditorDispatch} from 'src/reducers/thread-list-editor';

import * as React from 'react';

import logger from 'src/utils/logger';

import Editor from 'src/components/contacts/editor.jsx';
import {createContact} from 'src/thunks/contacts';

import {useDispatch} from 'react-redux';
import css from './contact-adder.css';


type Props = {
  // $FlowFixMe[value-as-type] [v1.32.0]
  dispatch: EditorDispatch,
  contact: Contact,
  errors: Object,
};

export default function Adder({
  dispatch,
  contact,
  errors,
}: Props): React.Element<'div'> {
  const [isPosted, setPosted] = React.useState(false);
  const dispatcher = useDispatch();

  const handleSubmit = React.useCallback(
    (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (errors) {
        setPosted(true);
      } else {
        const formDataDetails = {
          email: '',
          firstName: '',
          lastName: '',
          phoneNumbers: [],
        };
        const formData = new FormData(event.currentTarget);
        for (const [key, value] of formData.entries()) {
          if (key === 'phone') {
            const valueOfPhone: string = value.toString();
            formDataDetails['phoneNumbers'] = [
              {value: valueOfPhone, primary: true},
            ];
          } else {
            formDataDetails[key] = value;
          }
        }
        dispatcher(createContact(formDataDetails));
        dispatch({type: 'create'});
        setPosted(false);
      }
    },
    [errors, dispatch],
  );

  const handleChange = React.useCallback(
    (propName, value) => {
      logger.log('change', propName);
      dispatch({
        type: 'edit',
        payload: {[propName]: value},
      });
    },
    [dispatch],
  );

  const handleCancel = React.useCallback(
    () => dispatch({type: 'clear'}),
    [dispatch],
  );

  return (
    <div className={css.box}>
      <Editor
        contact={contact}
        errors={isPosted ? errors : null}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </div>
  );
}
