// @flow strict
import * as React from 'react';
import {useHistory} from 'src/rerouter';
import {useDispatch, useSelector} from 'react-redux';
import {ERROR_CODES} from 'src/components/authentication/errors.js';
import {CONSTANTS} from 'src/components/authentication/contants.js';
import {useEnvironmentContext} from 'src/hooks/useEnvironmentContext';
import logger from 'src/utils/logger';

//$FlowFixMe[untyped-import]
import {UPDATE_APP_URL} from 'src/chat-extension/messages';
//$FlowFixMe[nonstrict-import]
import {EXTENSION_ID} from 'src/chat-extension/constants';

//$FlowFixMe
import {validateEmail} from 'src/utils/index';

import {Input} from '@spaced-out/ui-design-system/lib/components/Input';
import {
  TitleMedium,
  BodySmall,
  BodyMedium,
  ButtonTextMedium,
  ButtonTextSmall,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {InContextAlert} from '@spaced-out/ui-design-system/lib/components/InContextAlert';
import {AuthFlow} from 'src/components/authentication/auth-flow.js';
import {
  mockSignIn,
  authenticateCreds,
  performSignIn,
  centralLoginAuth,
} from 'src/action-creators/authentication.js';

import type {SignInPageProps} from 'src/types/authentication.js';

import {
  selectMfaCredsValidating,
  selectMfaCredsError,
  selectSignInError,
} from 'src/selectors/authentication';

import Logo from 'src/images/sense-logo.svg';
import classify from 'src/utils/classify';

import css from './sign-in.css';
import maincss from 'src/components/authentication/main.css';


const SignInPage = (props: SignInPageProps): React.Node => {
  const dispatch = useDispatch();
  const router = useHistory();

  const signingIn = useSelector(selectMfaCredsValidating);
  const signinError = useSelector(selectMfaCredsError);
  const signInErrorText = useSelector(selectSignInError);

  const {goToPage, dynamicAgency, setCreds} = props;

  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [isValidEmail, setIsValidEmail] = React.useState<boolean>(true);
  const [isEmptyPassword, setIsEmptyPassword] = React.useState<boolean>(false);
  const {_a, _b, hostname} = useSelector((state) => state.env);

  const validateUserEmail = () => {
    setIsValidEmail(validateEmail(email));
  };

  const getHost = () => {
    return hostname.split('.').slice(1).join('.');
  };

  const updateExtensionURL = (appUrl: string) => {
    const chrome = window.chrome;
    if (!chrome) {
      return;
    }

    const message = {
      type: UPDATE_APP_URL,
      payload: appUrl,
    };

    chrome.runtime.sendMessage(EXTENSION_ID, message, (response) => {
      logger.log('app url updated', appUrl);
    });
  };

  const handleDynamicAgencySignIn = async () => {
    const apiData = await dispatch(centralLoginAuth(email, password));
    setCreds({email, password});

    if (apiData?.length > 1) {
      goToPage(AuthFlow.DYNAMIC_AGENCY.SELECT_AGENCY, 'DYNAMIC_AGENCY');
    } else if (apiData?.length === 1) {
      const host = getHost();
      dispatch(mockSignIn(email, password, host, apiData[0].slug));
    }
  };

  const handleMigratedAgent = (apiData) => {
    if (!apiData?.responseBody) {
      return;
    }
    const host = getHost();
    const slug = apiData?.responseBody?.slug;
    const appURL = `${slug}.${host}`;
    updateExtensionURL(appURL);
    dispatch(mockSignIn(email, password, host, slug));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidEmail || isEmptyPassword) {
      return;
    }

    if (dynamicAgency) {
      handleDynamicAgencySignIn();
      return;
    }

    const apiData = await dispatch(authenticateCreds(email, password));

    if (!apiData) {
      return;
    }

    if (apiData?.response?.status === 300) {
      handleMigratedAgent(apiData);
      return;
    }

    const mfa_enabled = apiData?.agency?.mfa_auth_channel;

    if (!mfa_enabled) {
      dispatch(performSignIn(apiData?.agency));
      return;
    }

    const authChannel = apiData?.mfa_settings?.auth_channel;
    const userPhone = apiData?.mfa_settings?.phone_number;

    if (authChannel === 'SMS' && userPhone) {
      goToPage(AuthFlow.SMS.OTP_INPUT, 'SMS');
    } else if (authChannel === 'SMS') {
      goToPage(
        AuthFlow.SMS_REGISTRATION.PHONE_REGISTRATION,
        'SMS_REGISTRATION',
      );
    } else if (authChannel === 'EMAIL') {
      goToPage(AuthFlow.EMAIL.OTP_INPUT, 'EMAIL');
    }

    return;
  };

  const handleResetPassowrd = () => {
    router.replace('/forgot');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  const handleEmailChange = (value) => {
    if (!isValidEmail) {
      setIsValidEmail(true);
    }

    setEmail(value);
  };

  const handlePasswordChange = (value) => {
    if (isEmptyPassword && value) {
      setIsEmptyPassword(false);
    }

    setPassword(value);
  };

  const {isExtension} = useEnvironmentContext();

  return (
    <div className={css.signInPage}>
      {isExtension && (
        <div className={maincss.senseLogo}>
          <Logo />
        </div>
      )}
      <div className={css.signInSection}>
        {!isExtension && (
          <TitleMedium className={css.contentTitle}>
            {CONSTANTS.SIGNIN_TITLE}
          </TitleMedium>
        )}
        <form onSubmit={handleSubmit} className={css.credForm}>
          <div className={css.credSection}>
            <div className={css.credInput}>
              <Input
                label="Email"
                placeholder="Enter email"
                size={isExtension ? 'small' : 'medium'}
                type="text"
                value={email}
                error={!isValidEmail}
                errorText={CONSTANTS.INVALID_EMAIL}
                onBlur={validateUserEmail}
                onChange={(e) => handleEmailChange(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </div>
            <div className={css.credInput}>
              <Input
                label="Password"
                placeholder="Enter password"
                size={isExtension ? 'small' : 'medium'}
                type="password"
                error={isEmptyPassword}
                errorText={CONSTANTS.EMPTY_PASSWORD}
                value={password}
                onBlur={() => setIsEmptyPassword(!password)}
                onChange={(e) => handlePasswordChange(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </div>
          </div>
          <div
            className={classify(css.resetPassword, {
              [maincss.alignSelfCenter]: isExtension,
            })}
          >
            {isExtension ? (
              <BodySmall className={css.forgotLoginText}>
                Can't log in?
              </BodySmall>
            ) : (
              <BodyMedium className={css.forgotLoginText}>
                Can't log in?
              </BodyMedium>
            )}
            {isExtension ? (
              <ButtonTextSmall
                color="clickable"
                className={css.resetLink}
                onClick={handleResetPassowrd}
              >
                Reset password
              </ButtonTextSmall>
            ) : (
              <ButtonTextMedium
                color="clickable"
                className={css.resetLink}
                onClick={handleResetPassowrd}
              >
                Reset password
              </ButtonTextMedium>
            )}
          </div>
          <Button
            size={isExtension ? 'small' : 'medium'}
            type="primary"
            classNames={{wrapper: css.signInBtn}}
            onClick={handleSubmit}
            disabled={signingIn}
          >
            {signingIn ? 'Signing In' : 'Sign In'}
          </Button>
        </form>
        {signinError && (
          <InContextAlert
            semantic="danger"
            classNames={{wrapper: css.loginError}}
          >
            {ERROR_CODES[signInErrorText] || signInErrorText}
          </InContextAlert>
        )}
      </div>
    </div>
  );
};

export default SignInPage;
