// @flow

import type {RecipientResource} from 'src/types/survey';
import type {ReportStats} from 'src/types/report';
import type {ReportAction} from 'src/action-creators/report';
// $FlowFixMe[untyped-type-import]
import {type AnalyticsQuery} from 'src/utils/analytics-query';

import {
  RECEIVE_AGENCY,
  RECEIVE_AGENCY_FOR_RECIPIENT,
  RECEIVE_WORKFLOW,
  RECEIVE_EVENT,
  SET_DEFAULT_QUERY,
} from 'src/action-creators/report';

import {parseLinks} from 'src/api-parsers/link-stats';


export type ReportState = {
  stats: ?ReportStats,
  clientStats: ?ReportStats,
  consultantStats: ?ReportStats,
  workflows: {
    [workflowId: string]: ReportStats,
  },
  events: {
    [eventId: string]: ReportStats,
  },
  // $FlowFixMe[value-as-type] [v1.32.0]
  defaultQuery: ?AnalyticsQuery,
};

const initialState = {
  stats: null,
  clientStats: null,
  consultantStats: null,
  workflows: Object.freeze({}),
  events: Object.freeze({}),
  defaultQuery: null,
};

export default (
  state: ReportState = initialState,
  action: ReportAction,
): ReportState => {
  switch (action.type) {
    case RECEIVE_AGENCY:
      // $FlowFixMe
      return {...state, stats: reduceReport(action.payload)};

    case RECEIVE_AGENCY_FOR_RECIPIENT:
      const updateKey = getAgencyRecipientUpdateKey(action.recipient);
      return {...state, [updateKey]: reduceReport(action.payload)};

    case RECEIVE_WORKFLOW:
      return {
        ...state,
        workflows: {
          ...state.workflows,
          [action.payload.workflowId]: reduceReport(action.payload),
        },
      };

    case RECEIVE_EVENT:
      return {
        ...state,
        events: {
          ...state.events,
          [action.payload.eventId]: reduceReport(action.payload),
        },
      };

    case SET_DEFAULT_QUERY:
      return {
        ...state,
        defaultQuery: action.payload,
      };
  }

  return state;
};

const reduceReport = (report) => ({
  ...report,
  links: report.links && parseLinks(report.links),
});

export const getAgencyRecipientUpdateKey = (
  // $FlowFixMe[value-as-type] [v1.32.0]
  recipient: RecipientResource,
): string =>
  ({
    client: 'clientStats',
    audience_member: 'consultantStats',
    internal: 'internalStats',
  }[recipient]);
