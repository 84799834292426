// @flow strict

import * as React from 'react';
import {useDispatch} from 'react-redux';

import {setFullscreen, clearFullscreen} from 'src/action-creators/app-config';


export const useFullScreenApp = (): mixed => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    // when this component mounts, move the application to fullscreen mode
    dispatch(setFullscreen());
    return () => {
      // when this component unmounts, move the application to normal mode
      dispatch(clearFullscreen());
    };
  }, []);
};
