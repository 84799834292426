// @flow strict

import type {LeadUpdateBody} from 'src/types/landing-page';

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction, GetState} from 'src/reducers';
// $FlowFixMe[untyped-import]
import {key, cached, fetching} from 'src/utils/redux';

// Const Defined Here
export const SAVE_ORIGINAL_LEAD_DATA = 'leads/lead-original-data';

// Defined Action Type Here
type SaveOriginalLeadDataAction = {
  type: typeof SAVE_ORIGINAL_LEAD_DATA,
  payload: LeadUpdateBody[],
};

// All The Actions here
export type LeadsActions = SaveOriginalLeadDataAction;

//
const saveOriginalLeadData = (
  payload: LeadUpdateBody[],
): SaveOriginalLeadDataAction => ({
  type: SAVE_ORIGINAL_LEAD_DATA,
  payload,
});

// Function to call Dispatch function from here
export const updateSavedOriginalLeadData =
  (dt: LeadUpdateBody[]): ThunkAction<mixed> =>
  (dispatch: Dispatch) =>
    // $FlowFixMe[incompatible-call]
    dispatch(saveOriginalLeadData(dt));
