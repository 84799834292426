// @noflow

import type {EnvState, ReceiveEnvAction} from 'src/action-creators/env';

import {RECEIVE} from 'src/action-creators/env';


export type State = ?EnvState;

// NOTE(gab): initial state for this reducer MUST be externally supplied. There are no appropriate default
// values for `NODE_ENV`, for instance; they must be supplied by the runtime.
export default (state: State = null, action: ReceiveEnvAction): State => {
  switch (action.type) {
    case RECEIVE:
      return {...state, ...action.payload};
  }

  return state;
};
