// @flow

import type {
  FieldMappingValueReference,
  AtsAnalyticsFieldValues,
  SavedCustomFieldMapping,
  SavedAnalyticsAudience,
  AtsAnalyticsFieldMapping,
  AnalyticsAudience,
  NewCustomFieldMapping,
  AllowedTearsheetTypes,
} from 'src/types/ats-settings';

import type {GetState, Dispatch, ThunkAction} from 'src/reducers';

import {thunkify as flow} from 'src/utils/thunks';
import {camel, snake} from 'src/utils';
import * as reduxApi from 'src/utils/redux-api';
import {key, cached, fetching, evictAction} from 'src/utils/redux';
import {showGenericConfirm, showGenericAlert} from 'src/action-creators/modal';
import {getRoiStatus} from 'src/action-creators/roi';
import {getMultiEntityEnabled} from 'src/selectors/audience-members';
import parsers from 'src/api-parsers';
import {isRoiFlagEnabled} from 'src/utils/roiFlagEnabled';
import {checkRoiV3ApiEnabled} from 'src/utils/analytics-api-migration';


export const RECEIVE_ATS_MAPPINGS = 'atsSettings/receiveAtsMappings';
export const RECEIVE_FIELD_VALUES = 'atsSettings/receiveFieldValues';
export const CUSTOM_MAPPING_SAVED = 'atsSettings/savedCustomMapping';
export const SET_MAPPING = 'atsSettings/setMapping';
export const EDIT_MAPPING = 'atsSettings/editMapping';
export const REMOVE_MAPPING = 'atsSettings/removeMapping';
export const RECEIVE_AUDIENCES = 'atsSettings/receiveAudiences';
export const RECEIVE_ALLOWED_TEARSHEET_TYPES =
  'ats-entities/allowed-tearsheet-types';

export type AtsSettingsAction =
  | ReceiveAtsMappingsAction
  | ReceiveFieldValuesAction
  | ReceiveAllowedTearsheetTypesAction
  | SetMappingAction
  | EditMappingAction
  | RemoveMappingAction
  | SavedCustomMappingAction;

const mappingDefaults = {
  id: null,
  analyticsField: '',
  senseAttribute: '',
  fieldDescription: '',
  fieldType: null,
  addToFilters: false,
  userDefined: false,
};

type ReceiveAtsMappingsAction = {
  type: 'atsSettings/receiveAtsMappings',
  payload: {
    mappingType: 'defined' | 'available',
    mappings: AtsAnalyticsFieldMapping[],
  },
};

const receiveAtsMappings = (payload): ReceiveAtsMappingsAction => ({
  type: RECEIVE_ATS_MAPPINGS,
  payload,
});

type ReceiveFieldValuesAction = {
  type: typeof RECEIVE_FIELD_VALUES,
  payload: AtsAnalyticsFieldValues,
};

const receiveFieldValues = (
  payload: AtsAnalyticsFieldValues,
): ReceiveFieldValuesAction => ({
  type: RECEIVE_FIELD_VALUES,
  payload,
});

type SavedCustomMappingAction = {
  type: 'atsSettings/savedCustomMapping',
  payload: SavedCustomFieldMapping,
};

const savedCustomMapping = (
  payload: SavedCustomFieldMapping,
): SavedCustomMappingAction => ({
  type: CUSTOM_MAPPING_SAVED,
  payload,
});

type SetMappingAction = {
  type: 'atsSettings/setMapping',
  payload: AtsAnalyticsFieldMapping,
};

const setMapping = (payload: AtsAnalyticsFieldMapping): SetMappingAction => ({
  type: SET_MAPPING,
  payload,
});

type RemoveMappingAction = {
  type: 'atsSettings/removeMapping',
  payload: string,
};

const removeMapping = (id: string): RemoveMappingAction => ({
  type: REMOVE_MAPPING,
  payload: id,
});

type EditMappingAction = {
  type: 'atsSettings/editMapping',
  payload: {
    id: string,
    field: string,
    value: any,
  },
};

const editMapping = (id, field, value): EditMappingAction => ({
  type: EDIT_MAPPING,
  payload: {
    id,
    field,
    value,
  },
});

type ReceiveAudiencesAction = {
  type: 'atsSettings/receiveAudiences',
  payload: SavedAnalyticsAudience[],
};

const receiveAudiences = (payload: Object[]): ReceiveAudiencesAction => ({
  type: RECEIVE_AUDIENCES,
  payload: payload.map((audience) => ({
    ...camel(audience),
    rule: parsers.parse.audienceFilter(audience.rule),
  })),
});

type ReceiveAllowedTearsheetTypesAction = {
  type: typeof RECEIVE_ALLOWED_TEARSHEET_TYPES,
  payload: AllowedTearsheetTypes,
};

const receiveAllowedTearsheetTypes = (
  allowedTearsheetTypes,
): ReceiveAllowedTearsheetTypesAction => ({
  type: RECEIVE_ALLOWED_TEARSHEET_TYPES,
  payload: allowedTearsheetTypes,
});

export const getAvailablePredefinedMappings: () => ThunkAction<mixed> = flow(
  key(() => 'getAvailablePredefinedMappings'),
  cached((json) =>
    receiveAtsMappings({
      mappingType: 'available',
      mappings: camel(json),
    }),
  ),
  fetching(),
)(() => reduxApi.get('analytics/ats-field-map/available-predefined-fields'));

export const getDefinedMappings: () => ThunkAction<mixed> = flow(
  key(() => 'getDefinedMappings'),
  cached((json) =>
    receiveAtsMappings({
      mappingType: 'defined',
      mappings: camel(json),
    }),
  ),
  fetching(),
)(() => reduxApi.get('analytics/engagement/cohorts/attribute-mapping'));

export const getFieldValues =
  (): ((dispatch: Dispatch, getState: GetState) => Promise<void>) =>
  async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(getMultiEntityAnalyticsFieldValues());
  };

export const getMultiEntityAnalyticsFieldValues: () => ThunkAction<mixed> =
  flow(
    key(() => 'getFieldValues'),
    cached((json) => receiveFieldValues(camel(json))),
    fetching(),
  )(() => reduxApi.get('analytics/attribute-values'));

export const getOldFieldValues: () => ThunkAction<mixed> = flow(
  key(() => 'getFieldValues'),
  cached((json) => receiveFieldValues(camel(json))),
  fetching(),
)(() => reduxApi.get('analytics/ats-field-map/field-values'));

export const updateOrCreateMapping =
  ({
    id,
    analyticsField,
    field,
    value,
  }: FieldMappingValueReference): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch) => {
    if (id) {
      // edit
      dispatch(editMapping(id, field, value));
      await dispatch(
        reduxApi.put(
          `analytics/ats-field-map/${id}`,
          snake({
            [field]: value,
          }),
        ),
      );
      dispatch(evictAction(getFieldValues.KEY()));
    } else {
      // create
      const data = {
        ...mappingDefaults,
        analyticsField,
        [field]: value,
      };
      const response = await dispatch(
        reduxApi.post(`analytics/ats-field-map`, snake(data)),
      );
      dispatch(setMapping(camel(response)));
    }
    !checkRoiV3ApiEnabled() && dispatch(getRoiStatus());
  };

export const createCustomMapping =
  (data: NewCustomFieldMapping): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch) => {
    const response = await dispatch(
      reduxApi.post(`analytics/ats-field-map`, snake(data)),
    );
    dispatch(savedCustomMapping(camel(response)));
    if (data.addToFilters) {
      dispatch(evictAction(getFieldValues.KEY()));
    }
    !checkRoiV3ApiEnabled() && dispatch(getRoiStatus());
  };

export const resetMapping =
  (id: string): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch) => {
    if (id) {
      dispatch(editMapping(id, 'senseAttribute', null));
      dispatch(editMapping(id, 'id', null));
      await dispatch(reduxApi.del(`analytics/ats-field-map/${id}`));
      dispatch(evictAction(getFieldValues.KEY()));
      !checkRoiV3ApiEnabled() && dispatch(getRoiStatus());
    }
  };

export const deleteMapping =
  (id: string): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch) => {
    const yes = await dispatch(
      showGenericConfirm({
        title: 'Are you sure you want to delete this field?',
        text: 'This will delete this field for everyone in your organization. This action cannot be undone.',
      }),
    );
    if (yes) {
      await dispatch(reduxApi.del(`analytics/ats-field-map/${id}`));
      dispatch(removeMapping(id));
      dispatch(evictAction(getFieldValues.KEY()));
      !checkRoiV3ApiEnabled() && dispatch(getRoiStatus());
    }
  };

export const getAllAudiences: () => ThunkAction<> = flow(
  key(() => 'analyticsAudiences'),
  cached((json: AnalyticsAudience[]) => receiveAudiences(json)),
  fetching(),
  // $FlowFixMe
)(() =>
  reduxApi.get(
    'analytics/roi/generation-rule',
    {},
    {apiPath: checkRoiV3ApiEnabled() ? '/api/v3/' : '/api/v1/'},
  ),
);

export const saveAudience =
  (
    fieldsToTypes: {[key: string]: string},
    {id, name, entityType, rule}: AnalyticsAudience,
  ): ((dispatch: Dispatch) => Promise<boolean>) =>
  async (dispatch: Dispatch) => {
    rule = parsers.normalize.audienceFilter(fieldsToTypes, rule);

    const audience = {
      name,
      entityType,
      rule,
    };

    const confirmed = await dispatch(
      showGenericConfirm({
        title: 'Are you sure you want to update the analytics audience?',
        text: 'This will take up to an hour. You cannot undo this operation.',
      }),
    );

    let response;

    if (confirmed) {
      try {
        if (id) {
          response = await dispatch(
            reduxApi.put(
              `analytics/roi/generation-rule/${id}`,
              snake(audience),
              {},
              {apiPath: checkRoiV3ApiEnabled() ? '/api/v3/' : '/api/v1/'},
            ),
          );
        } else {
          response = await dispatch(
            reduxApi.post(
              'analytics/roi/generation-rule',
              snake(audience),
              {},
              {apiPath: checkRoiV3ApiEnabled() ? '/api/v3/' : '/api/v1/'},
            ),
          );
        }
      } catch (error) {
        if (error.status === 400) {
          dispatch(
            showGenericAlert({
              title: 'Invalid Rules',
              text: 'It looks like some of your rules are invalid. Please try editing them and resaving.',
            }),
          );
          return false;
        }

        throw error;
      }

      // $FlowFixMe upgraded flow
      dispatch(evictAction(getAllAudiences.KEY()));
      !checkRoiV3ApiEnabled() && dispatch(getRoiStatus());
      receiveAudiences([response]);

      return true;
    }
    return false;
  };

export const getAllowedTearsheetTypes: () => ThunkAction<mixed> = flow(
  key(() => 'getAllowedTearsheetTypes'),
  cached((types: AllowedTearsheetTypes) => receiveAllowedTearsheetTypes(types)),
  fetching(),
)(() => reduxApi.get('tearsheets/allowed-types'));
