//@flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2.js';
import type {TouchpointSummaryResponseData} from 'src/types/journey-touchpoint';


export const GET_SUMMARY = 'journey-touchpoint/get-summary';
export const GET_SUMMARY_ERROR = 'journey-touchpoint/get-summary-error';
export const GET_SUMMARY_LOADING = 'journey-touchpoint/get-summary-loading';

type JourneyTouchpointGetSummaryAction = {
  type: typeof GET_SUMMARY,
  payload: TouchpointSummaryResponseData,
};

type JourneyTouchpointGetSummaryErrorAction = {
  type: typeof GET_SUMMARY_ERROR,
  payload: {error: string},
};

export type JourneyTouchpointActions =
  | JourneyTouchpointGetSummaryAction
  | JourneyTouchpointGetSummaryErrorAction;

export const getSummary =
  (id: string): ThunkAction<mixed> =>
  (dispatch: Dispatch) => {
    dispatch({
      type: GET_SUMMARY_LOADING,
    });

    return dispatch(
      reduxApi.get(`analyze-responses-report/by-touchpoint/${id}`),
    ).then(
      (response: TouchpointSummaryResponseData) => {
        dispatch({
          type: GET_SUMMARY,
          payload: response,
        });
      },
      () => {
        dispatch({
          type: GET_SUMMARY_ERROR,
          payload: {error: 'Something went wrong!!'},
        });
      },
    );
  };
