// @noflow

import type {
  AudienceResponse,
  AudienceEntity,
} from 'src/types/multientity-audience';
import type {MultiEntityAudienceActions} from 'src/action-creators/audience-members/multientity';
import type {EntityType, EntityAttributeMapping} from 'src/types/ats-entities';

import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import transform from 'lodash/transform';
import flatten from 'lodash/flatten';
import sculpt from 'sculpt';

import {
  RECEIVE_ME_AUDIENCE_PAGE,
  RECEIVE_ME_AUDIENCE_ENTITY,
  RECEIVE_ME_WORKFLOW_AUDIENCE_PAGE,
  RECEIVE_ME_RELATED_ENTITIES,
  RECEIVE_ME_VARIABLES,
  RECEIVE_PERSON_ENTITY_SUMMARY,
  RECEIVE_ENTITY_SUMMARY,
} from 'src/action-creators/audience-members/multientity';


export type AudienceResponsePage = {
  ...AudienceResponse,
  entities: void,
  entitiesById: string[],
};

// TODO(marcos): A related entity could be a placement, appointment, job submission
// or any number of other related entities on a given candidate (or anything). This
// is loosely typed becasue we sort of don't care what it is, as long as it.

export type State = {
  [entity: string]: {
    [page: number]: AudienceResponsePage,
  },
  entities: {
    [id: string]: AudienceEntity,
  },

  // incoming entities are denormalized keys of ids to other ids that
  // when we fetch them, will go in `related`
  incoming: {
    [audienceMemberId: string]: {
      [relatedEntity: EntityType]: {
        // this relation is somewhat useless for the client (for now)
        // what we will most often care about are the strings of ids
        // which will populate things like placement/appointment/etc
        [relatedBy: string]: string[],
      },
    },
  },

  // for now, stash related entities here until we move them out per-request
  // into the page's state/context to avoid balooning the store
  related: {
    [relatedEntityType: EntityType]: {
      [id: string]: EntityAttributeMapping,
    },
  },

  workflows: {
    [id: string]: {
      [page: string]: AudienceResponsePage,
    },
  },

  variables: {
    [personEntityId: string]: {
      [variable: string]: ?string | ?number | ?boolean,
    },
  },
};

const initialState = {
  entities: {},
  workflows: {},
  related: {},
  incoming: {},
  variables: {},
};

export default (
  state: State = initialState,
  action: MultiEntityAudienceActions,
) => {
  switch (action.type) {
    case RECEIVE_ME_AUDIENCE_PAGE: {
      // normalize entities on receipt
      const entitiesById = action.payload.data.entities.map(
        (entity) => entity.id,
      );
      const entityGroup = state[action.payload.entity] || {};
      const entityGroupPages = {
        [action.payload.entity]: {
          ...entityGroup,
          [action.payload.page]: {
            ...action.payload.data,
            entities: undefined,
            entitiesById,
          },
        },
      };

      const keyedEntities = keyBy(action.payload.data.entities, 'id');
      const updatedEntities = {
        entities: {
          ...state.entities,
          ...keyedEntities,
        },
      };
      const newState = {
        ...state,
        ...entityGroupPages,
        ...updatedEntities,
      };
      return newState;
    }

    case RECEIVE_ME_WORKFLOW_AUDIENCE_PAGE: {
      // TODO(marcos): duped code from above, see if worth deduping
      // normalize entities on receipt
      const entitiesById = action.payload.data.entities.map(
        (entity) => entity.id,
      );
      const workflowAudience = state.workflows[action.payload.workflowId] || {};
      const workflowAudiencePages = {
        [action.payload.workflowId]: {
          ...workflowAudience,
          [action.payload.page]: {
            ...action.payload.data,
            entities: undefined,
            entitiesById,
          },
        },
      };

      const keyedEntities = keyBy(action.payload.data.entities, 'id');
      const updatedEntities = {
        entities: {
          ...state.entities,
          ...keyedEntities,
        },
      };
      const newState = {
        ...state,
        workflows: {
          ...state.workflows,
          ...workflowAudiencePages,
        },
        ...updatedEntities,
      };
      return newState;
    }

    case RECEIVE_ME_AUDIENCE_ENTITY: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.entity.id]: {
            ...state.entities[action.payload.entity.id],
            ...action.payload.entity,
          },
        },
        incoming: {
          ...state.incoming,
          [action.payload.entity.id]: Object.keys(
            action.payload.incoming_related_entity_ids,
          ).reduce((acc, curr) => {
            acc[curr] = flatten(
              Object.keys(action.payload.incoming_related_entity_ids[curr]).map(
                (relation) =>
                  action.payload.incoming_related_entity_ids[curr][relation],
              ),
            );
            return acc;
          }, {}),
        },
      };
    }

    case RECEIVE_PERSON_ENTITY_SUMMARY: {
      return sculpt(state, {
        related: {
          $merge: transform(
            action.payload,
            (result, value, key) => {
              result[key] = keyBy(value, 'id');
              return result;
            },
            {},
          ),
        },
      });
    }

    case RECEIVE_ME_RELATED_ENTITIES: {
      return {
        ...state,
        related: {
          ...state.related,
          [action.payload.type]: {
            ...state.related[action.payload.type],
            ...keyBy(action.payload.entity_groups, 'id'),
          },
        },
      };
    }

    case RECEIVE_ENTITY_SUMMARY: {
      //receiving one single entity summary
      return {
        ...state,
        related: {
          ...state.related,
          [action.payload.entityType]: {
            ...state.related[action.payload.entityType],
            [action.payload.data.id]: action.payload.data,
          },
        },
      };
    }
    // TODO This is wrong. The variables should be keyed on entity type and ID, not just ID.
    case RECEIVE_ME_VARIABLES: {
      return {
        ...state,
        variables: {
          ...state.variables,
          [action.payload.personEntityId]: {
            ...state.entities[action.payload.personEntityId],
            ...action.payload.entityVariables,
          },
        },
      };
    }
  }
  return state;
};
