// @flow strict
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useEnvironmentContext} from 'src/hooks/useEnvironmentContext';
import {CircularLoader} from '@spaced-out/ui-design-system/lib/components/CircularLoader';
import {
  BodySmall,
  TitleMedium,
} from '@spaced-out/ui-design-system/lib/components/Text';
import SignInPage from 'src/components/authentication/signin/sign-in/sign-in.jsx';
import PhoneRegistration from 'src/components/authentication/signin/phone-registration/phone-registration.jsx';
import OtpPage from 'src/components/authentication/signin/otp-page/otp-page.jsx';
import PhoneResetConfirmation from 'src/components/authentication/signin/phone-reset-confirmation/phone-reset-confirmation.jsx';
import DynamicAgency from 'src/components/authentication/signin/dynamic-agency/dynamic-agency.jsx';
import SSOLogin from 'src/components/authentication/signin/sso/sso.jsx';

import {AuthFlow} from 'src/components/authentication/auth-flow.js';
import {CONSTANTS} from 'src/components/authentication/contants.js';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import Logo from 'src/images/sense-logo.svg';
import SenseCircleImage from 'src/images/sso-login-circle-image.svg';
import {classify} from 'src/utils/classify';
import {
  performSignIn,
  checkRedirection,
} from 'src/action-creators/authentication.js';

import {
  selectUserPhoneNumber,
  selectCurrentAgency,
  selectMfaAuthChannel,
} from 'src/selectors/authentication';

import {
  //$FlowFixMe[nonstrict-import]
  selectAgencyData,
  //$FlowFixMe[nonstrict-import]
  selectAgencyConfig,
  //$FlowFixMe[nonstrict-import]
  selectAgencyBrandingSettingsLogo,
} from 'src/selectors/agency';

import ArrowLeft from 'src/images/icons/chevron-left.svg?noAttrs';

import type {
  SignInContainerProps,
  Credentials,
} from 'src/types/authentication.js';

import css from './signin-container.css';
import maincss from 'src/components/authentication/main.css';


const SignInContainer = (props: SignInContainerProps): React.Node => {
  const dispatch = useDispatch();
  const {dynamicAgency} = props;

  const centreAlignedPages = [AuthFlow.SMS_REGISTRATION.RESET_PHONE];

  const IgnoreBackBtn = [AuthFlow.SMS_REGISTRATION.RESET_PHONE];

  const userPhone = useSelector(selectUserPhoneNumber);
  const agency = useSelector(selectCurrentAgency);
  const userAuthChannel = useSelector(selectMfaAuthChannel);
  const ssoAuthUrl = useSelector(selectAgencyData).ssoAuthUrl;
  const showDualAuthForm = useSelector(selectAgencyData).showDualAuthForm;
  const agencyLogo = useSelector(selectAgencyBrandingSettingsLogo);

  const [curPage, setCurPage] = React.useState('');
  // flow can be set according to the api response
  const [mfaFlow, setMfaFlow] = React.useState<string>('');
  const [pageHistory, setPageHistory] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [creds, setCreds] = React.useState<Credentials>({
    email: '',
    password: '',
  });
  const [isCentreAligned, setIsCentreAligned] = React.useState(false);

  const {isExtension} = useEnvironmentContext();

  const isRedirect = () => {
    // redirect=true should always be last
    const queryParams = Object.fromEntries(
      new URLSearchParams(window.location.search),
    );

    if (queryParams.redirect) {
      return true;
    }

    if (!queryParams.nextSearch) {
      return;
    }

    const params = Object.fromEntries(
      new URLSearchParams(queryParams.nextSearch),
    );
    return params.redirect;
  };

  const isFromSignUp = () => {
    const queryParams = Object.fromEntries(
      new URLSearchParams(window.location.search),
    );

    if (queryParams.fromSignUp) {
      return true;
    }
  };

  const setAuthFlow = (agency, authChannel, userPhone) => {
    if (!agency.mfa_auth_channel) {
      dispatch(performSignIn(agency));
      return;
    }

    if (authChannel === 'SMS' && userPhone) {
      goToPage(AuthFlow.SMS.OTP_INPUT, 'SMS');
    } else if (authChannel === 'SMS') {
      goToPage(
        AuthFlow.SMS_REGISTRATION.PHONE_REGISTRATION,
        'SMS_REGISTRATION',
      );
    }
  };

  const handleRedirection = () => {
    setIsLoading(true);
    dispatch(checkRedirection())
      .then((response) => {
        if (!response) {
          return;
        }
        setAuthFlow(
          response.agency,
          response?.mfa_settings?.auth_channel,
          response?.mfa_settings?.phone_number,
        );
      })
      .catch((error) => {
        console.log('error while checking redirection ', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSignUp = () => {
    setIsLoading(true);
    try {
      setAuthFlow(agency, userAuthChannel, userPhone);
    } catch {
      console.log('error while setting auth flow from signup');
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (dynamicAgency) {
      return;
    }

    if (isRedirect()) {
      handleRedirection();
    } else if (isFromSignUp()) {
      handleSignUp();
    }
  }, []);

  const goToPage = (page: string, newMfaFlow: ?string) => {
    if (!mfaFlow && !newMfaFlow) {
      return;
    }

    if (newMfaFlow) {
      setMfaFlow(newMfaFlow);
    }

    let flow;
    if (mfaFlow) {
      flow = AuthFlow[mfaFlow];
    } else if (newMfaFlow) {
      flow = AuthFlow[newMfaFlow];
    } else {
      return;
    }

    const nextPage = flow[page];

    const curPageHistory = [...pageHistory];
    curPageHistory.push(curPage);

    if (centreAlignedPages.includes(nextPage)) {
      setIsCentreAligned(true);
    } else {
      setIsCentreAligned(false);
    }

    if (IgnoreBackBtn.includes(nextPage)) {
      setPageHistory([]);
    } else {
      setPageHistory(curPageHistory);
    }

    setCurPage(nextPage);
  };

  const goToPrevPage = () => {
    if (!pageHistory.length) {
      return;
    }
    const curPageHistory = [...pageHistory];
    const prevPage = curPageHistory.pop();
    setCurPage(prevPage);
    setPageHistory(curPageHistory);
  };

  const getContent = () => {
    if (ssoAuthUrl) {
      if (showDualAuthForm) {
        return (
          <div className={css.ssoWithEmailContainer}>
            <SignInPage
              goToPage={goToPage}
              dynamicAgency={dynamicAgency}
              setCreds={setCreds}
            />
            <div
              className={classify(css.divider, {
                [css.extensionDivider]: isExtension,
              })}
            >
              <hr className={css.sectionDivider} />
              <BodySmall>OR</BodySmall>
              <hr className={css.sectionDivider} />
            </div>
            <SSOLogin ssoAuthUrl={ssoAuthUrl} showDualAuthForm />
          </div>
        );
      }
      return (
        <div className={css.ssoOnlyContainer}>
          {!isExtension && <SenseCircleImage />}
          {agencyLogo ? (
            <img src={agencyLogo} className={css.ssoOnlyLogo} />
          ) : (
            <Logo />
          )}
          <TitleMedium>Hi, Welcome to Sense!</TitleMedium>
          <SSOLogin ssoAuthUrl={ssoAuthUrl} />
        </div>
      );
    }

    switch (mfaFlow) {
      case 'SMS_REGISTRATION':
        switch (curPage) {
          case AuthFlow.SMS_REGISTRATION.SIGN_IN:
            return (
              <SignInPage
                goToPage={goToPage}
                dynamicAgency={dynamicAgency}
                setCreds={setCreds}
              />
            );
          case AuthFlow.SMS_REGISTRATION.PHONE_REGISTRATION:
            return <PhoneRegistration goToPage={goToPage} />;
          case AuthFlow.SMS_REGISTRATION.OTP_INPUT:
            return <OtpPage goToPage={goToPage} />;
          case AuthFlow.SMS_REGISTRATION.RESET_PHONE:
            return <PhoneResetConfirmation />;
          default:
            return (
              <SignInPage
                goToPage={goToPage}
                dynamicAgency={dynamicAgency}
                setCreds={setCreds}
              />
            );
        }
      case 'SMS':
        switch (curPage) {
          case AuthFlow.SMS_REGISTRATION.SIGN_IN:
            return (
              <SignInPage
                goToPage={goToPage}
                dynamicAgency={dynamicAgency}
                setCreds={setCreds}
              />
            );
          case AuthFlow.SMS_REGISTRATION.OTP_INPUT:
            return <OtpPage goToPage={goToPage} />;
          case AuthFlow.SMS_REGISTRATION.RESET_PHONE:
            return <PhoneResetConfirmation />;
          default:
            return (
              <SignInPage
                goToPage={goToPage}
                dynamicAgency={dynamicAgency}
                setCreds={setCreds}
              />
            );
        }
      case 'EMAIL':
        switch (curPage) {
          case AuthFlow.EMAIL.SIGN_IN:
            return (
              <SignInPage
                goToPage={goToPage}
                dynamicAgency={dynamicAgency}
                setCreds={setCreds}
              />
            );
          case AuthFlow.EMAIL.OTP_INPUT:
            return <OtpPage goToPage={goToPage} />;
          default:
            return (
              <SignInPage
                goToPage={goToPage}
                dynamicAgency={dynamicAgency}
                setCreds={setCreds}
              />
            );
        }
      case 'DYNAMIC_AGENCY':
        switch (curPage) {
          case AuthFlow.DYNAMIC_AGENCY.SIGN_IN:
            return (
              <SignInPage
                goToPage={goToPage}
                dynamicAgency={dynamicAgency}
                setCreds={setCreds}
              />
            );
          case AuthFlow.DYNAMIC_AGENCY.SELECT_AGENCY:
            return <DynamicAgency creds={creds} />;
          default:
            return (
              <SignInPage
                goToPage={goToPage}
                dynamicAgency={dynamicAgency}
                setCreds={setCreds}
              />
            );
        }
      default:
        return (
          <SignInPage
            goToPage={goToPage}
            dynamicAgency={dynamicAgency}
            setCreds={setCreds}
          />
        );
    }
  };

  return isLoading ? (
    <div className={css.loader}>
      <CircularLoader colorToken="colorFillPrimary" size="large" />
    </div>
  ) : (
    <div className={maincss.contentSideBar}>
      {pageHistory.length !== 0 && (
        <>
          {isExtension ? (
            <div className={maincss.senseLogoWithBtn}>
              <Button
                ariaLabel="back"
                iconRightName="angle-left"
                onClick={goToPrevPage}
                size="medium"
                type="tertiary"
              />
              <Logo />
            </div>
          ) : (
            <Button
              iconLeftName="angle-left"
              onClick={goToPrevPage}
              size="medium"
              type="tertiary"
            >
              {CONSTANTS.BACK_BUTTON_TEXT}
            </Button>
          )}
        </>
      )}

      <div
        className={classify(
          isCentreAligned || ssoAuthUrl
            ? maincss.containerContentCentreAligned
            : maincss.containerContent,
          {
            [maincss.containerContentExt]: isExtension,
            [maincss.containerContentTopAligned]:
              ssoAuthUrl && !showDualAuthForm && !isExtension,
          },
        )}
      >
        {getContent()}
      </div>
    </div>
  );
};

export default SignInContainer;
