// @noflow

import type {Contact} from 'src/types/contacts';

import * as React from 'react';
import {findDOMNode} from 'react-dom';

import {classify} from 'src/utils';
import throttleAnimation from 'src/utils/throttle-animation';

import css from './typeahead.css';


const resolveOptionValue = ({value}) => value;

const calculateDropdownHeight = (el: ?Element) => {
  if (!el) {
    return;
  }
  const domRect = el.getBoundingClientRect();
  // browser viewport height - height of the text input - top margin of the text input
  return window.innerHeight - domRect.height - domRect.top;
};

type Props = {
  onChange: (value: string) => void,
  onChooseSuggestion: (option: Object) => void,
  hideDropdownOnChooseSuggestion: boolean,
  suggestions: Object[], // Immutable! PureComponent ...
  value: string,
  Suggestion: React.ComponentType<{
    option: Object,
    search: string,
    preventSelection: () => any,
  }>,
  className?: string,
  resolveOptionValue: (option: Object) => string,
  leftItem?: React.Node,
  rightItem?: React.Node,
  inputElId: string,
  forceFocus?: boolean,
  onBlur?: () => void,
};

class Typeahead extends React.PureComponent<
  Props,
  {
    selectedIndex: number,
    hideDropdown: boolean,
    dropdownHeight?: ?number,
    el?: Element,
  },
> {
  static defaultProps = {
    resolveOptionValue,
  };

  state = {
    selectedIndex: 0,
    hideDropdown: true,
  };

  input = null;
  suggestionClickPrevented = false;

  handleResize = throttleAnimation(() => {
    this.setState({
      dropdownHeight: calculateDropdownHeight(this.state.el),
    });
  });

  componentDidUpdate(prevProps: Props) {
    if (prevProps.suggestions !== this.props.suggestions) {
      this.setState({
        selectedIndex: 0,
      });
    }
  }

  componentDidMount() {
    const el = findDOMNode(this);
    if (el) {
      this.setState({
        dropdownHeight: calculateDropdownHeight(el),
        el,
      });
      window.addEventListener('resize', this.handleResize);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const {
      onChange,
      // don't pass this to ...inputProps
      onChooseSuggestion: _onChooseSuggestion,
      hideDropdownOnChooseSuggestion,
      suggestions,
      Suggestion,
      className,
      value,
      resolveOptionValue,
      leftItem,
      rightItem,
      inputElId,
      forceFocus,
      onBlur,
      ...inputProps
    } = this.props;
    const {selectedIndex, hideDropdown, dropdownHeight} = this.state;

    const dropdownIsVisible = !hideDropdown && suggestions.length > 0;

    let shouldHideDropdownOnBlur = true;

    if (forceFocus) {
      this.focus();
    }

    return (
      <div
        className={classify(css.root, className, {
          [css.withResults]: dropdownIsVisible,
          [css.withLeftItem]: leftItem,
          [css.withRightItem]: rightItem,
        })}
      >
        {leftItem}
        <input
          id={inputElId}
          type="text"
          className={classify(css.input, {
            [css.withResults]: dropdownIsVisible,
          })}
          ref={(input) => (this.input = input)}
          autoComplete="off"
          value={value}
          onChange={this.handleChange}
          onFocus={(evt) => {
            this.setState({hideDropdown: false});
            evt.target.select();
          }}
          onBlur={() => {
            if (onBlur) {
              onBlur();
            }
            this.setState({hideDropdown: shouldHideDropdownOnBlur});
            if (!shouldHideDropdownOnBlur) {
              this.focus();
              shouldHideDropdownOnBlur = true;
            }
          }}
          onKeyDown={this.handleInputKeyDown}
          {...inputProps}
        />
        {rightItem}

        {dropdownIsVisible && (
          <ul
            className={css.dropdown}
            style={dropdownHeight ? {maxHeight: `${dropdownHeight}px`} : null}
          >
            {suggestions.map((option, index) => (
              <li
                key={resolveOptionValue(option)}
                className={
                  selectedIndex === index
                    ? css.selectedResultLine
                    : css.resultLine
                }
                onClick={() => this.chooseSuggestion(index)}
                onMouseDown={() => (shouldHideDropdownOnBlur = false)}
              >
                <Suggestion
                  option={option}
                  search={value}
                  preventSelection={this.preventSuggestionClick}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }

  focus() {
    this.input && this.input.focus();
  }

  _handleChange({target: {value}}) {
    this.setState({hideDropdown: false});
    this.props.onChange(value);
  }
  handleChange = this._handleChange.bind(this);

  _preventSuggestionClick() {
    this.suggestionClickPrevented = true;
  }
  preventSuggestionClick = this._preventSuggestionClick.bind(this);

  chooseSuggestion(index: number) {
    if (this.suggestionClickPrevented) {
      this.suggestionClickPrevented = false;
      return;
    }

    this.props.onChooseSuggestion(this.props.suggestions[index]);
    if (this.props.hideDropdownOnChooseSuggestion) {
      this.setState({hideDropdown: true});
    }
  }

  _handleInputKeyDown(event: SyntheticKeyboardEvent<HTMLInputElement>) {
    const value = event.currentTarget.value;
    const key = event.key;

    if (key === 'Escape') {
      if (this.input) {
        this.input.blur();
      }
    } else if (key === 'Enter') {
      event.preventDefault();

      const {suggestions} = this.props;
      const {selectedIndex} = this.state;

      if (suggestions.length) {
        this.chooseSuggestion(selectedIndex);
      }
    } else if (key === 'ArrowDown' || key === 'Tab') {
      // NOTE (kyle): allow normal tab behavior if no text is in the input
      if (value || key !== 'Tab') {
        event.preventDefault();
        const {
          suggestions: {length},
        } = this.props;
        const {selectedIndex} = this.state;

        if (selectedIndex < length - 1) {
          this.setState({
            selectedIndex: selectedIndex + 1,
          });
        }
      }
    } else if (key === 'ArrowUp') {
      event.preventDefault();
      const {selectedIndex} = this.state;

      if (selectedIndex > 0) {
        this.setState({
          selectedIndex: selectedIndex - 1,
        });
      }
    }
  }
  handleInputKeyDown = this._handleInputKeyDown.bind(this);
}

export default Typeahead;

type WithExtrasProps = {
  childRef: any,
  onCreate?: (value: string) => void,
  CreationSuggestion: React.ComponentType<{value: string}>,
  onViewAll?: () => void,
  ViewAllSuggestion: React.ComponentType<{}>,
} & Props;

export class TypeaheadWithExtras extends React.PureComponent<WithExtrasProps> {
  static defaultProps = {
    CreationSuggestion: ({value}) => `Create "${value}"`,
    ViewAllSuggestion: () => 'View All',
    resolveOptionValue,
  };

  creationOption = {value: '@@create'};
  viewAllOption = {value: '@@viewAll'};

  render() {
    const {
      childRef,
      onCreate,
      CreationSuggestion,
      onViewAll,
      ViewAllSuggestion,
      suggestions,
      Suggestion,
      value,
      inputElId,
      ...props
    } = this.props;

    const newSuggestions = suggestions;
    if (value) {
      if (onCreate) {
        suggestions.unshift(this.creationOption);
      }
      if (onViewAll) {
        suggestions.push(this.viewAllOption);
      }
    }

    const CustomSuggestion = ({option, search, preventSelection}) => {
      if (option === this.creationOption) {
        return <CreationSuggestion value={value} />;
      }
      if (option === this.viewAllOption) {
        return <ViewAllSuggestion />;
      }
      return (
        <Suggestion
          option={option}
          search={value}
          preventSelection={preventSelection}
        />
      );
    };

    return (
      <Typeahead
        inputElId={inputElId}
        ref={childRef}
        suggestions={newSuggestions}
        Suggestion={CustomSuggestion}
        value={value}
        {...props}
        resolveOptionValue={this.resolveOptionValue}
        onChooseSuggestion={this.handleChooseSuggestion}
      />
    );
  }

  handleChooseSuggestion = (option: Object) => {
    if (option === this.creationOption) {
      this.props.onCreate(this.props.value);
    } else if (option === this.viewAllOption) {
      this.props.onViewAll();
    } else {
      this.props.onChooseSuggestion(option);
    }
  };

  resolveOptionValue = (option: Object) => {
    if (option === this.creationOption || option === this.viewAllOption) {
      return resolveOptionValue(option);
    } else {
      return this.props.resolveOptionValue(option);
    }
  };
}
