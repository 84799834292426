// @flow

/*
import type {
  DynamicLabelExtra,
  DynamicLabels,
} from 'src/action-creators/dynamic-labels';
*/
import type {DynamicLabelExtra, DynamicLabels, AtsDynamicLabel} from './types';

import * as React from 'react';

import capitalize from 'lodash/capitalize';
import chunk from 'lodash/chunk';
import {
  entityColorMapping,
  generateAttributeGrammarTokens,
} from 'src/utils/entities';
import classify from 'src/utils/classify';

import TruncatedText from 'src/components/lib/truncated-text';

import common from 'src/styles/common.css';
import css from './variable-picker.css';


export type VariablePickerModalRowProps = {
  data: {
    labels: DynamicLabels,
    selectedIndex: number,
    onClick: (
      DynamicLabelExtra,
      evt: SyntheticMouseEvent<HTMLDivElement>,
    ) => void,
  },
  index: number,
  style: Object,
};

const VariablePickerModalRow = ({
  data: {labels, selectedIndex, onClick},
  index,
  style,
}: VariablePickerModalRowProps): React.Element<'div'> => {
  const dynamicLabel = labels[index];

  const color =
    (dynamicLabel.sense_name &&
      common[entityColorMapping[dynamicLabel.sense_name]?.text]) ||
    common.colorGray6;

  return (
    <div
      // even/odd in JS because react-window will
      // make css order unpredictable
      className={classify(
        index % 2 === 0 ? css.resultEven : css.resultOdd,
        selectedIndex === index ? css.resultSelected : '',
      )}
      onClick={(evt) => {
        onClick(dynamicLabel, evt);
      }}
      style={style}
    >
      <div className={css.label}>
        <TruncatedText
          className={css.modalVariableName}
          style={{
            color,
          }}
          text={
            dynamicLabel.source === 'custom'
              ? dynamicLabel.name
              : dynamicLabel.entityRelationshipPath[0]
          }
          limit={60}
          component="h3"
        />
        <div className={css.grammarTokens}>
          {dynamicLabel.source === 'custom' ? (
            <p>{dynamicLabel.description}</p>
          ) : (
            <GrammarTokens dynamicLabel={dynamicLabel} />
          )}
        </div>
      </div>
      <div className={css.labelType}>
        {capitalize(
          dynamicLabel.source === 'custom'
            ? dynamicLabel.category
            : dynamicLabel.mapping.display_name,
        )}
      </div>
    </div>
  );
};

export default VariablePickerModalRow;

function GrammarTokens({dynamicLabel}: {dynamicLabel: AtsDynamicLabel}) {
  return chunk(
    generateAttributeGrammarTokens(dynamicLabel.entityRelationshipPath),
    2,
  ).map(([prefix, token]) => (
    <p key={`${dynamicLabel.value}${prefix}${token}`}>
      {`${prefix} `}
      <TruncatedText
        className={css.token}
        text={token}
        limit={60}
        component="span"
      />
    </p>
  ));
}
