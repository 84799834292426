// @flow strict

import type {
  GenericError,
  ReferralStats,
  RecentActivity,
} from 'src/types/referrer-portal';
import type {ReferralLeaderBoard, ReferralLinks} from 'src/types/referral-v2';
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-referrer-portal.js';
import {DEFAULT_API_ERROR} from 'src/utils/referrer-portal';


export const REFERRAL_STATS = 'referral-stats';
export const REFERRAL_STATS_ERROR = 'referral-stats-error';

export const REFERRAL_LEADERBOARD = 'referrals-leaderboard';
export const REFERRAL_LEADERBOARD_ERROR = 'referrals-leaderboard-error';

export const REFERRAL_SOCIAL_LINKS = 'referrals-social-links';
export const REFERRAL_SOCIAL_LINKS_ERROR = 'referrals-social-links-error';

export const REFERRAL_ACTIVITIES = 'referrals-activities';
export const REFERRAL_ACTIVITIES_ERROR = 'referrals-activities-error';

type ReferralsStatsAction = {
  type: typeof REFERRAL_STATS,
  payload: ReferralStats,
};

type ReferralsStatsErrorAction = {
  type: typeof REFERRAL_STATS_ERROR,
  payload?: ?GenericError,
};

type ReferralsLeaderBoardAction = {
  type: typeof REFERRAL_LEADERBOARD,
  payload: ReferralLeaderBoard[],
};

type ReferralsLeaderBoardErrorAction = {
  type: typeof REFERRAL_LEADERBOARD_ERROR,
  payload?: ?GenericError,
};

type ReferralSocialLinksAction = {
  type: typeof REFERRAL_SOCIAL_LINKS,
  payload: ReferralLinks[],
};

type ReferralSocialLinksErrorAction = {
  type: typeof REFERRAL_SOCIAL_LINKS_ERROR,
  payload?: ?GenericError,
};

type ReferralActivitiesAction = {
  type: typeof REFERRAL_ACTIVITIES,
  payload: RecentActivity[],
};

type ReferralActivitiesErrorAction = {
  type: typeof REFERRAL_ACTIVITIES_ERROR,
  payload?: ?GenericError,
};

export type DashboardActions =
  | ReferralsStatsAction
  | ReferralsStatsErrorAction
  | ReferralsLeaderBoardAction
  | ReferralsLeaderBoardErrorAction
  | ReferralSocialLinksAction
  | ReferralSocialLinksErrorAction
  | ReferralActivitiesAction
  | ReferralActivitiesErrorAction;

export const receiveReferralStatsDetailsError = (
  payload: ?GenericError,
): ReferralsStatsErrorAction => ({
  type: REFERRAL_STATS_ERROR,
  payload,
});

export const receiveReferralsLeaderBoardError = (
  payload: ?GenericError,
): ReferralsLeaderBoardErrorAction => ({
  type: REFERRAL_LEADERBOARD_ERROR,
  payload,
});

export const receiveReferralSocialLinksError = (
  payload: ?GenericError,
): ReferralActivitiesErrorAction => ({
  type: REFERRAL_SOCIAL_LINKS_ERROR,
  payload,
});

export const receiveReferralActivitiesError = (
  payload: ?GenericError,
): ReferralSocialLinksErrorAction => ({
  type: REFERRAL_ACTIVITIES_ERROR,
  payload,
});

export const getReferralStatsDetails: (payload: {
  start_date: string,
  end_date: string,
}) => ThunkAction<void> = flow(
  key(() => `referral-stats`),
  fetching(),
)((payload) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.get(
      `referral-stats?start_date=${payload.start_date}&end_date=${payload.end_date}`,
    ),
  ).then(
    (response: ReferralStats) => {
      dispatch({
        type: REFERRAL_STATS,
        payload: response,
      });
    },
    (error) => {
      dispatch(
        receiveReferralStatsDetailsError(
          error?.response?.errors || DEFAULT_API_ERROR,
        ),
      );
    },
  );
});

export const getReferralLeaderBoard: () => ThunkAction<void> = flow(
  key(() => `tracking/leaderboard`),
  fetching(),
)(() => (dispatch: Dispatch) => {
  return dispatch(reduxApi.get(`tracking/leaderboard`)).then(
    (response: ReferralLeaderBoard[]) => {
      dispatch({
        type: REFERRAL_LEADERBOARD,
        payload: response,
      });
    },
    (error) => {
      dispatch(
        receiveReferralsLeaderBoardError(
          error?.response?.errors || DEFAULT_API_ERROR,
        ),
      );
    },
  );
});

export const getReferralSocialLinks: () => ThunkAction<void> = flow(
  key(() => `user/referral-social-links`),
  fetching(),
)(() => (dispatch: Dispatch) => {
  return dispatch(reduxApi.get(`user/referral-social-links`)).then(
    (response: ReferralLinks[]) => {
      dispatch({
        type: REFERRAL_SOCIAL_LINKS,
        payload: response,
      });
    },
    (error) => {
      dispatch(
        receiveReferralSocialLinksError(
          error?.response?.errors || DEFAULT_API_ERROR,
        ),
      );
    },
  );
});

export const getRecentActivities: (payload: {
  start_date: string,
  end_date: string,
}) => ThunkAction<void> = flow(
  key(() => `user/referral-activity`),
  fetching(),
)((payload) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.get(
      `user/referral-activity?start_date=${payload.start_date}&end_date=${payload.end_date}&limit=10`,
    ),
  ).then(
    (response: RecentActivity[]) => {
      dispatch({
        type: REFERRAL_ACTIVITIES,
        payload: response,
      });
    },
    (error) => {
      dispatch(
        receiveReferralActivitiesError(
          error?.response?.errors || DEFAULT_API_ERROR,
        ),
      );
    },
  );
});
