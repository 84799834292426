// @flow strict

import type {
  PaginationDetailsWithKey,
  FileCsvUploadEntries,
  ContactList,
  FileUploadAdditionalDetails,
  ContactListFilters,
  EditContactListData,
} from 'src/types/contacts-genesis';
import type {ContactsGenesisActions} from 'src/action-creators/contacts-genesis';

import {
  RESET_PAGINATION_DATA,
  SET_PAGINATION_DATA,
  SET_CSV_FILE_UPLOAD_HEADERS,
  SET_CSV_FILE_UPLOAD_ENTRIES,
  SET_CONTACT_LISTS_DATA,
  SET_FILE_UPLOAD_ADDITIONAL_DETAILS,
  SET_CSV_FILE_UPLOAD,
  SET_CONTACT_LISTS_FOR_UPLOAD,
  UPDATE_CONTACT_LIST_LIMIT_REACHED,
  SET_ARCHIVED_CONTACT_LISTS_DATA,
  SET_CONTACT_LISTS_FILTER,
  UPDATE_CONTACT_LIST_DATA,
  REMOVE_CONTACT_LIST_FROM_STATE,
  SET_EDIT_CONTACT_LISTS_DATA,
  SET_CONTACT_PHONE_NUMBER,
  SET_CONTACT_LIST_ID_FOR_CONTACT_CREATION,
} from 'src/action-creators/contacts-genesis';
import {
  initialPaginationDetails,
  initialContactListFilters,
  initialAdditionalFileDetails,
  allInitialPaginationDetails,
} from 'src/components/contacts-genesis/contacts-genesis-constants';

type ContactsState = {
  paginationDetails: PaginationDetailsWithKey,
  fileCsvUploadHeaders: string[],
  fileCsvUploadEntries: FileCsvUploadEntries[],
  fileCsvUpload: File | null,
  fileUploadAdditionalDetails: FileUploadAdditionalDetails,
  contactsListData: ContactList[],
  editContactListsData: EditContactListData,
  archivedContactsListData: ContactList[],
  contactListsForUploadScreen: ContactList[],
  contactListLimitReached: boolean,
  contactListFilters: ContactListFilters,
  contactPhoneNumber: string,
  contactListIdForContactCreation: string,
};

const initialState: ContactsState = {
  paginationDetails: allInitialPaginationDetails,
  fileCsvUploadHeaders: [],
  fileCsvUploadEntries: [],
  fileCsvUpload: null,
  contactsListData: [],
  editContactListsData: {title: '', description: ''},
  archivedContactsListData: [],
  fileUploadAdditionalDetails: initialAdditionalFileDetails,
  contactListsForUploadScreen: [],
  contactListLimitReached: false,
  contactListFilters: initialContactListFilters,
  contactPhoneNumber: '',
  contactListIdForContactCreation: '',
};

export default (
  state: ContactsState = initialState,
  action: ContactsGenesisActions,
): ContactsState => {
  switch (action.type) {
    case SET_CONTACT_LIST_ID_FOR_CONTACT_CREATION: {
      return {
        ...state,
        contactListIdForContactCreation: action.payload,
      };
    }
    case SET_CONTACT_PHONE_NUMBER:
      return {
        ...state,
        contactPhoneNumber: action.payload,
      };
    case SET_CSV_FILE_UPLOAD_HEADERS:
      return {
        ...state,
        fileCsvUploadHeaders: action.payload,
      };
    case SET_CSV_FILE_UPLOAD_ENTRIES:
      return {
        ...state,
        fileCsvUploadEntries: action.payload,
      };
    case SET_CSV_FILE_UPLOAD:
      return {
        ...state,
        fileCsvUpload: action.payload,
      };

    case RESET_PAGINATION_DATA:
      return {...state, paginationDetails: allInitialPaginationDetails};
    case SET_PAGINATION_DATA:
      const {key, paginationDetails} = action.payload;
      return {
        ...state,
        paginationDetails: {
          ...state.paginationDetails,
          [key]: {...state.paginationDetails[key], ...paginationDetails},
        },
      };
    case SET_CONTACT_LISTS_DATA:
      const updateList = action.payload.update;
      const receivedContactList = action.payload.contactLists;
      return {
        ...state,
        contactsListData: updateList
          ? [...state.contactsListData, ...receivedContactList]
          : receivedContactList,
      };
    case SET_ARCHIVED_CONTACT_LISTS_DATA:
      const updateArchivedList = action.payload.update;
      const receivedArchivedContactList = action.payload.contactLists;
      return {
        ...state,
        archivedContactsListData: updateArchivedList
          ? [...state.contactsListData, ...receivedArchivedContactList]
          : receivedArchivedContactList,
      };
    case SET_FILE_UPLOAD_ADDITIONAL_DETAILS:
      return {
        ...state,
        fileUploadAdditionalDetails: action.payload,
      };
    case SET_CONTACT_LISTS_FOR_UPLOAD:
      return {
        ...state,
        contactListsForUploadScreen: action.payload,
      };
    case SET_CONTACT_LISTS_FILTER:
      // reset pagination details when filters change
      return {
        ...state,
        contactListFilters: action.payload,
        paginationDetails: allInitialPaginationDetails,
      };
    case UPDATE_CONTACT_LIST_DATA: {
      const {id, starred} = action.payload;
      const selectedList = state.contactsListData.find(
        (contactsList) => contactsList.id === id,
      );
      if (!selectedList) {
        return state;
      }

      selectedList.starred = starred;
      const newList = [...state.contactsListData];
      return {...state, contactsListData: newList};
    }
    case REMOVE_CONTACT_LIST_FROM_STATE: {
      const id = action.payload;
      const filteredList = state.contactsListData.filter(
        (contactsList) => contactsList.id !== id,
      );
      return {
        ...state,
        contactsListData: filteredList,
      };
    }
    case SET_EDIT_CONTACT_LISTS_DATA: {
      return {
        ...state,
        editContactListsData: action.payload,
      };
    }
    default:
      return state;
  }
};
