// @flow strict-local

import type {AnalyticsQuery} from 'src/types/report';

import * as React from 'react';
import {useDispatch} from 'react-redux';

import {download} from 'src/utils/redux-api';
import useAnalyticsQuery from 'src/components/analytics/useAnalyticsQuery';

import {allowedFilters} from 'src/action-creators/link-stats';


export const CsvDownloadButton = ({
  className,
  children,
  queryResolver,
  url,
  apiPath,
  isEnhancement,
}: {
  className?: string,
  apiPath?: string,
  url: string,
  queryResolver: (
    AnalyticsQuery,
    allowed?: string[],
    isEnhancement?: boolean,
  ) => AnalyticsQuery,
  children?: React.Node,
  isEnhancement?: boolean,
}): React.Node => {
  const dispatch = useDispatch();
  const [query] = useAnalyticsQuery();

  const handleClick = () => {
    const resolver = isEnhancement
      ? queryResolver(query, allowedFilters, isEnhancement)
      : queryResolver(query);
    dispatch(
      download(url, {
        method: 'POST',
        data: {...resolver, export: 'csv'},
        options: {
          apiPath,
        },
      }),
    );
  };

  return (
    <div className={className} onClick={handleClick}>
      {children != null ? children : 'Export CSV'}
    </div>
  );
};

export default CsvDownloadButton;
