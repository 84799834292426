// @flow

import type {
  ClickedLink,
  ClickedLinks,
  PaginatedLinkStats,
} from 'src/types/link-stats';
import type {LinkStatsAction} from 'src/action-creators/link-stats';

import {emptyObject} from 'src/utils';
import {
  RECEIVE_AGENCY,
  RECEIVE_WORKFLOW,
  RECEIVE_EVENT,
  PAGINATION_LIMIT,
} from 'src/action-creators/link-stats';

import {resolveLinkId, parseLinks} from 'src/api-parsers/link-stats';


export type LinkStatsState = {
  agency: PaginatedLinkStats,
  workflows: {
    [workflowId: string]: PaginatedLinkStats,
  },
  events: {
    [eventId: string]: PaginatedLinkStats,
  },
  links: {
    [linkId: string]: ClickedLink,
  },
};

const initialPaginatedLinkStats = {
  page: 0,
  hasMore: true,
  stats: emptyObject,
  linkIds: [],
};

const initialState = {
  agency: initialPaginatedLinkStats,
  workflows: emptyObject,
  events: emptyObject,
  links: emptyObject,
};

export default (
  state: LinkStatsState = initialState,
  action: LinkStatsAction,
): LinkStatsState => {
  switch (action.type) {
    case RECEIVE_AGENCY: {
      return {
        ...state,
        agency: reducePage(state.agency, action),
        links: reduceLinks(state, action.payload.linkStats.links),
      };
    }

    case RECEIVE_WORKFLOW: {
      const {payload} = action;
      const {workflowId} = payload;
      const workflow = state.workflows[workflowId] || initialPaginatedLinkStats;

      return {
        ...state,
        workflows: {
          ...state.workflows,
          [workflowId]: reducePage(workflow, action),
        },
        links: reduceLinks(state, payload.linkStats.links),
      };
    }

    case RECEIVE_EVENT: {
      const {payload} = action;
      const {eventId} = payload;
      const event = state.events[eventId] || initialPaginatedLinkStats;

      return {
        ...state,
        events: {
          ...state.events,
          [eventId]: reducePage(event, action),
        },
        links: reduceLinks(state, payload.linkStats.links),
      };
    }
  }

  return state;
};

const reduceLinks = (
  state: LinkStatsState,
  links: ClickedLinks,
): {
  [string]: ClickedLink,
} =>
  parseLinks(links).reduce(
    (links, link) => {
      links[link.id] = link;
      return links;
    },
    {...state.links},
  );

const reducePage = (
  state: PaginatedLinkStats,
  {payload: {page, linkStats}},
): PaginatedLinkStats => {
  const {links, ...stats} = linkStats;
  const idsToAdd = links.map((link) => resolveLinkId(link));
  const linkIds = page === 0 ? idsToAdd : [...state.linkIds, ...idsToAdd];
  return {
    page,
    linkIds,
    stats,
    hasMore: idsToAdd.length >= PAGINATION_LIMIT,
  };
};
