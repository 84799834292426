// @flow strict

import * as React from 'react';
import {
  BodyLarge,
  SubTitleLarge,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {Icon} from '@spaced-out/ui-design-system/lib/components/Icon';
import css from './top.css';


type TopNavigationProps = {
  userLimit: number,
  onClose: () => void,
};

const BulkMessageWrarning = ({
  userLimit,
  onClose,
}: TopNavigationProps): React.Node => {
  return (
    <div className={css.warningModalContent}>
      <Icon
        color="warning"
        name="circle-exclamation"
        size="medium"
        type="regular"
      />
      <SubTitleLarge>Maximum candidate limit exceeded</SubTitleLarge>
      <BodyLarge>
        You can send message upto {userLimit} candidates at a time
      </BodyLarge>
      <Button type="secondary" onClick={onClose}>
        Cancel
      </Button>
    </div>
  );
};

export default BulkMessageWrarning;
