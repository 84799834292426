// @flow strict-local

import type {EnvState} from 'src/action-creators/env';

// $FlowFixMe[untyped-import]
import * as sentry from 'sentry';

// other products can use this
export function bootstrapSentry(env: EnvState) {
  sentry.init({
    dsn: 'https://05107e14b96c467489873f093f7399ba@sentry.io/190397',
    release: env.release || 'dev',
    environment: env.serverRuntimeEnvironment,
    serverName: env.hostname || 'dserver.com',
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'AbortError: Aborted'],
    beforeSend(event, hint) {
      const {originalException} = hint;
      if (
        // NOTE (kyle): filter out uncaught Recaptcha exceptions
        originalException === 'Timeout' ||
        // NOTE (kyle): this error notifies of perf issues. it is benign.
        originalException?.message === 'ResizeObserver loop limit exceeded' ||
        // NOTE (kyle): filter out Delighted errors
        originalException?.message?.includes('Synchronous XHR') ||
        originalException?.message?.includes('web.delighted.com') ||
        // NOTE(marcos): filter out two draft errors caused by browser plugins modifing contenteditable
        originalException?.message?.includes(
          'Failed to execute \'extend\' on \'Selection\'',
        ) ||
        originalException?.message?.includes(
          'Failed to execute \'setStart\' on \'Range\'',
        ) ||
        // walkme's media player can error out but we can only see it in the stack
        // q.v. https://sentry.io/share/issue/36b5b8a1685d4f63a2c8dbb0177f9580/
        originalException?.stack?.includes('walkme_lib')
      ) {
        return null;
      }
      return event;
    },
  });
}
