// @flow strict

import type {EntityType, EntityAttributeSchema} from 'src/types/ats-entities';
import type {
  Entity,
  FilterByID,
  FilterByKey,
  SelectedFilters,
  J2SelectedFilters,
  JourneyOverviewDataTable,
  JourneyTableListAPIPayload,
  JourneyOverviewDataTableQuery,
  WorkflowTableListAPIPayload,
  WorkflowOverviewDataTableQuery,
  WorkflowOverviewDataTable,
} from 'src/types/analytics-genesis';
import type {MenuOption} from '@spaced-out/ui-design-system/lib/components/Menu';
import type {DateRangeWithTimezone} from '@spaced-out/ui-design-system/lib/types';

import lodash from 'lodash';
import moment from 'moment-timezone';
import {
  getSubtractedDays,
  formatIsoDate,
  getUTCStartOfDay,
  getUTCEndOfDay,
} from 'src/utils/date-time-2';
import {
  // $FlowFixMe[nonstrict-import]
  filterEntityAttributesForEntityType,
} from 'src/selectors/ats-entities';


const DATE_RANGES = {
  sevenDays: 7,
  thirtyDays: 30,
  sixtyDays: 60,
  ninetyDays: 90,
};

export const METRICS_COUNT_BY_VIEW = {
  overview: 3,
  email: 6,
  sms: 5,
};

export const JOURNEY_PAGE_SIZE = 10;
export const DEBOUNCE_DELAY = 500;
export const PAGE_SIZE_TYPEAHEAD = 15;

export const KPIMetricsOrder = [
  'people_reached',
  'unsubscribed',
  'spam',
  'sent',
  'delivered',
  'opened',
  'clicked',
  'skipped',
  'bounced',
];

export const booleanMenuOptions: Array<MenuOption> = [
  {
    key: 'true',
    label: 'True',
  },
  {
    key: 'false',
    label: 'False',
  },
];

export const timeRangeOptions = [
  {
    key: 'thisYear',
    label: 'This year',
  },
  {
    key: 'sevenDays',
    label: 'Last 7 Days',
  },
  {
    key: 'thirtyDays',
    label: 'Last 30 days',
  },
  {
    key: 'sixtyDays',
    label: 'Last 60 days',
  },
  {
    key: 'ninetyDays',
    label: 'Last 90 days',
  },
  {
    key: 'lastYear',
    label: 'Last year',
  },
  {
    key: 'custom',
    label: 'Custom time',
  },
];

export const NoRecordsOptions = [{key: 'noRecords', label: 'No Records Found'}];

export const journeyTableColumnsQuery = {
  product: 'journeys',
  dashboard: 'engagement-and-deliverability',
  sub_dashboard: 'journey-view',
  table: 'journeys-data-table',
};

export const touchpointTableColumnsQuery = {
  product: 'journeys',
  dashboard: 'engagement-and-deliverability',
  sub_dashboard: 'touchpoint-view',
  table: 'touchpoints-data-table',
};

export const peopleTableColumnsQuery = {
  product: 'journeys',
  dashboard: 'engagement-and-deliverability',
  sub_dashboard: 'people-view',
  table: 'peoples-data-table',
};

export const timePeriodsOptions = [
  {key: 'quarter', label: 'Quarterly'},
  {key: 'month', label: 'Monthly'},
  {key: 'week', label: 'Weekly'},
];

export const roiSummaryMetrics = [
  {label: 'reached', icon: 'radar'},
  {label: 'sourced', icon: 'filter'},
  {label: 'submitted', icon: 'marker'},
  {label: 'hired', icon: 'trophy-star'},
];

export const hiringMetricsCategories = [
  {
    key: 'time_to_hire',
    label: 'Time to Hire',
    category: 'Hiring Velocity',
    icon: 'rabbit-running',
  },
  {
    key: 'time_to_submit',
    label: 'Time to Submit',
    category: 'Hiring Velocity',
    icon: 'rabbit-running',
  },

  {
    key: 'hours_saved',
    label: 'FTE Hours Saved',
    category: 'Savings',
    icon: 'piggy-bank',
  },
  {
    key: 'spend_saved',
    label: 'Job Board Spend Saved',
    category: 'Savings',
    icon: 'piggy-bank',
  },

  {
    key: 'influenced_revenue',
    label: 'Influenced Gross Revenue',
    category: 'Revenue',
    icon: 'circle-dollar',
  },
  {
    key: 'influenced_margin',
    label: 'Influenced Gross Margin',
    category: 'Revenue',
    icon: 'circle-dollar',
  },
];

export const exportOptions = [
  {
    key: 'engage',
    label: 'Journey Performance',
  },
  {
    key: 'messaging',
    label: 'Messaging Performance',
  },
  {
    key: 'inbound_chatbot',
    label: 'Inbound Chatbot Performance',
  },
  {
    key: 'referral',
    label: 'Referral Performance',
  },
];

export const typeOfMetric = [
  {key: 'absolute_numbers', label: 'Absolute Numbers'},
  {key: 'conversion_rates', label: 'Conversion Rates'},
];

export const reportsChartTimePeriodOptions = [
  {key: 'quarterly', label: 'Quarterly'},
  {key: 'monthly', label: 'Monthly'},
  {key: 'weekly', label: 'Weekly'},
  {key: 'daily', label: 'Daily'},
];

export const SHOW_ALL_OPTION = {
  iconLeft: 'grid-horizontal',
  iconLeftType: 'solid',
  label: 'Show All',
  key: 'showAll',
};

export const getDashboardTableList = ({
  listState = {},
  pageSize,
  journeyId,
  touchpointId,
  commonPayload,
}: JourneyTableListAPIPayload): JourneyOverviewDataTableQuery => {
  const {sort: {sortBy, sortOrder} = {}, pageNumber, query} = listState;
  let apiQuery = {
    ...commonPayload,
    limit: pageSize,
    offset: (pageNumber - 1) * pageSize,
  };
  if (journeyId) {
    apiQuery = {...apiQuery, workflow_id: journeyId};
  }
  if (touchpointId) {
    apiQuery = {...apiQuery, event_id: touchpointId};
  }
  if (query) {
    apiQuery = {...apiQuery, query};
  }
  if (sortBy && sortOrder !== 'original') {
    apiQuery = {...apiQuery, sort: sortOrder === 'asc' ? sortBy : `-${sortBy}`};
  }
  return apiQuery;
};

export const getAttributeOptions = (
  entityType: EntityType,
  attributes: Map<string, EntityAttributeSchema>,
): Array<MenuOption> => {
  if (entityType) {
    const attributesList = Array.from(
      filterEntityAttributesForEntityType(attributes, entityType),
    );
    const menuOptions = attributesList
      .map((attribute) => ({
        key: attribute.id,
        label: attribute.display_name,
      }))
      .filter((attribute) => Boolean(attribute.key));
    return menuOptions ?? [];
  } else {
    return [];
  }
};

export const getFilterItemOptionsByKey = (
  filterItemList: Array<FilterByKey>,
): Array<MenuOption> => {
  const options = filterItemList.map(({key, value}) => ({
    key,
    label: value,
  }));
  return options;
};

export const getFilterItemOptionsByID = (
  filterItemList: Array<FilterByID>,
): Array<MenuOption> => {
  const options = filterItemList.map(({id, name}) => ({
    key: id,
    label: name,
  }));
  return options;
};

export const getPlaceholderText = (
  options: Array<MenuOption>,
  selectedOptions: Array<string>,
  title: string,
): string => {
  const isAllSelected =
    lodash.difference(
      options.map((option) => option.key),
      selectedOptions,
    ).length === 0;

  return isAllSelected
    ? `All ${title}`
    : selectedOptions
        .map((opt) => options.find((item) => item.key === opt)?.label ?? '')
        .join(', ');
};

export const getOverviewDataDisplayName = (str: string): string => {
  if (!str.includes('_')) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const words = str.split('_');
  switch (words[0]) {
    case 'opened': {
      words[0] = 'open';
      break;
    }
    case 'bounced': {
      words[0] = 'bounce';
      break;
    }
    case 'clicked': {
      words[0] = 'click';
      break;
    }
    case 'delivered': {
      words[0] = 'delivery';
      break;
    }
    case 'unsubscribed': {
      words[0] = 'unsubscribe';
      break;
    }
  }
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join(' ');
};

export const getDateRangeDataByOption = (
  selectedDateRangeOption: string,
  timezone: string,
): DateRangeWithTimezone => {
  const today = formatIsoDate();
  let rangeStartDate, rangeEndDate;

  switch (selectedDateRangeOption) {
    case 'thisYear':
      rangeStartDate = formatIsoDate(moment().startOf('y'));
      rangeEndDate = today;
      break;
    case 'lastYear':
      const previousYear = moment().subtract(1, 'y').year();
      rangeStartDate = `${previousYear}-01-01`;
      rangeEndDate = `${previousYear}-12-31`;
      break;
    default:
      rangeStartDate = getSubtractedDays(
        today,
        DATE_RANGES[selectedDateRangeOption],
      );
      rangeEndDate = today;
      break;
  }

  // Adding timezone to dates
  const rangeStartDateUTC = getUTCStartOfDay(rangeStartDate, timezone);
  const rangeEndDateUTC = getUTCEndOfDay(rangeEndDate, timezone);

  return {
    startDateUTC: rangeStartDateUTC,
    endDateUTC: rangeEndDateUTC,
    startDate: rangeStartDate,
    endDate: rangeEndDate,
    timezone,
  };
};

export const getOptionByDataRange = (
  dateRange: DateRangeWithTimezone,
): MenuOption => {
  const timeZone = moment.tz.guess();
  let option;
  timeRangeOptions.forEach((menuOption) => {
    const {key} = menuOption;
    const {startDate, endDate} = getDateRangeDataByOption(key, timeZone);
    if (startDate === dateRange.startDate && endDate === dateRange.endDate) {
      option = menuOption;
    }
  });
  return option ?? timeRangeOptions.slice(-1)[0];
};

export const formatDateText = (date?: string): string =>
  date ? moment(date).format('MM.DD.YYYY') : '';

export const getTimePeriodOption = (
  startDate: string,
  endDate: string,
  isJourneyDashboard: boolean = false,
): MenuOption => {
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);
  const daysDiff = Math.abs(endDateMoment.diff(startDateMoment, 'd'));

  const getExactKey = (range: string) =>
    isJourneyDashboard ? `${range}ly` : range;

  if (daysDiff <= 7 && isJourneyDashboard) {
    return {key: 'daily', label: 'Daily'};
  } else if (daysDiff <= 30) {
    return {key: getExactKey('week'), label: 'Weekly'};
  } else if (daysDiff <= 180) {
    return {key: getExactKey('month'), label: 'Monthly'};
  } else {
    return {
      key: getExactKey('quarter'),
      label: 'Quarterly',
    };
  }
};

export const getSymbolFromCurrencyCode = (currencyCode: string): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
  });
  const formatted = formatter.format(0);
  const symbol = formatted.replace(/[\d.,\s]/g, '');

  return symbol;
};

export const getInitialFrequency = (
  startDate: ?string,
  endDate: ?string,
): string => {
  if (startDate && endDate) {
    return getTimePeriodOption(startDate, endDate, true).key;
  } else {
    return 'quarterly';
  }
};

export const getValidFrequencyOptions = (
  startDate: ?string,
  endDate: ?string,
): Array<MenuOption> => {
  const frequencyIndex = lodash.findIndex(reportsChartTimePeriodOptions, {
    key: getInitialFrequency(startDate, endDate),
  });
  return reportsChartTimePeriodOptions.filter(
    (_, index) => index >= frequencyIndex,
  );
};

export const getValidTimePeriodOptions = (
  startDate: ?string,
  endDate: ?string,
): Array<MenuOption> => {
  let key = 'quarterly';
  if (startDate && endDate) {
    key = getTimePeriodOption(startDate, endDate).key;
  }
  const timePeriodIndex = lodash.findIndex(timePeriodsOptions, {key});
  return timePeriodsOptions.filter((_, index) => index >= timePeriodIndex);
};

export const transformEntities = (entities: Array<Entity>): {and: mixed} => {
  const transformedEntities = [];
  entities.forEach(({entityType, attribute, attributeValue}) => {
    if (
      entityType &&
      attribute &&
      !lodash.isNil(attributeValue) &&
      !lodash.isEmpty(attributeValue)
    ) {
      let newEntity;
      // $FlowFixMe[incompatible-type]
      const field = `${entityType}/${attribute.attribute_name}`;
      if (lodash.isArray(attributeValue)) {
        newEntity = {
          in: {
            field,
            value: attributeValue,
          },
        };
      } else if (
        lodash.isObject(attributeValue) &&
        attributeValue?.min &&
        attributeValue.max
      ) {
        const {max, min} = attributeValue;
        newEntity = {
          and: [
            {
              lte: {
                field,
                value: max,
              },
            },
            {
              gte: {
                field,
                value: min,
              },
            },
          ],
        };
      } else {
        newEntity = {
          eq: {
            field,
            value: attributeValue,
          },
        };
      }
      transformedEntities.push(newEntity);
    }
  });

  return {and: transformedEntities};
};

export const itemsOnPageText = (
  pageNumber: number = 1,
  pageSize: number,
  totalCount: number,
): string => {
  let pageStart = 0;
  let pageEnd = 0;

  if (totalCount > 0) {
    pageStart = (pageNumber - 1) * pageSize + 1;
    pageEnd = Math.min(pageNumber * pageSize, totalCount);
  }

  return `Items on page: ${pageStart} - ${pageEnd} of ${totalCount}`;
};

export const transformTableData = (
  input: JourneyOverviewDataTable,
): JourneyOverviewDataTable => {
  const entityKeys: Set<string> = new Set();

  const transformedData =
    input.data?.map((row) => {
      // Collect all keys from the row itself

      if (row.entity_attributes) {
        // Collect all keys from entity_attributes
        Object.keys(row.entity_attributes).forEach((key) =>
          entityKeys.add(key),
        );

        // Merge entity_attributes into the main row
        Object.assign(row, row.entity_attributes);

        // Remove the entity_attributes property
        delete row.entity_attributes;
      }

      return row;
    }) || [];

  // Convert the sets to arrays

  const allAttributesColumn = Array.from(entityKeys);

  // Return the transformed table data
  return {
    ...input,
    data: transformedData,
    allAttributesColumn,
  };
};

export const transformWorkflowTableData = (
  input: WorkflowOverviewDataTable,
): WorkflowOverviewDataTable => {
  const entityKeys: Set<string> = new Set();

  const transformedData =
    input.data?.map((row) => {
      // Collect all keys from the row itself

      if (row.entity_attributes) {
        // Collect all keys from entity_attributes
        Object.keys(row.entity_attributes).forEach((key) =>
          entityKeys.add(key),
        );

        // Merge entity_attributes into the main row
        Object.assign(row, row.entity_attributes);

        // Remove the entity_attributes property
        delete row.entity_attributes;
      }

      return row;
    }) || [];

  // Convert the sets to arrays

  const allAttributesColumn = Array.from(entityKeys);

  // Return the transformed table data
  return {
    ...input,
    data: transformedData,
    allAttributesColumn,
  };
};

export const convertStringCase = (str: string): string => {
  // Replace underscores with spaces and capitalize the first letter of each word
  return str
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const removeLockedFilters = (
  payload: SelectedFilters,
  journey: string,
  touchpoint: string,
): SelectedFilters => {
  if (journey) {
    // the following filters are locked when a journey is selected
    const newPayload = lodash.omit(payload, [
      'journey_lifecycle_goals',
      'journey_business_goals',
      'journey_categories',
      'recipient_type',
      'journey_status',
      'journey_ids',
    ]);
    if (touchpoint) {
      // touchpoint type is locked when a touchpoint is selected
      return {
        ...lodash.omit(newPayload, ['event_types']),
        workflow_id: journey,
        event_id: touchpoint,
      };
    } else {
      // delivery status filter isn't present when a touchpoint isn't selected
      return {...newPayload, workflow_id: journey};
    }
  } else {
    return lodash.omit(payload, ['event_types']);
  }
};

export const removeLockedFiltersJ2 = (
  payload: J2SelectedFilters,
  workflow: string,
  node: string,
): J2SelectedFilters => {
  if (workflow) {
    // the following filters are locked when a workflow is selected
    let newPayload = lodash.omit(payload, [
      'workflow_categories',
      'workflow_status',
      'recipient_type',
      'workflow_ids',
    ]);
    if (node) {
      // node type is locked when a node is selected
      newPayload = lodash.omit(newPayload, ['node_types']);
      newPayload = {...newPayload, workflow_id: workflow, node_id: node};
    } else {
      newPayload = {...newPayload, workflow_id: workflow};
    }
    return newPayload;
  } else {
    return lodash.omit(payload, ['node_types']);
  }
};

export const getJourneyDashboardName = (
  journeyId: string,
  eventId: string,
): string => {
  if (journeyId && eventId) {
    return `journeys_${journeyId}_${eventId}`;
  } else if (journeyId) {
    return `journeys_${journeyId}`;
  } else {
    return 'journeys_all';
  }
};

export const getWorkflowDashboardName = (
  workflowId: string,
  nodeId: string,
): string => {
  if (workflowId && nodeId) {
    return `workflows_${workflowId}_${nodeId}`;
  } else if (workflowId) {
    return `workflows_${workflowId}`;
  } else {
    return 'workflows_all';
  }
};

export const EXPORT_ANALYTICS_JOURNEYS_ENGAGEMENT_URL =
  'data-exports/journeys-overview/journeys';

export const EXPORT_ANALYTICS_TOUCHPOINTS_ENGAGEMENT_URL =
  'data-exports/journeys-overview/touchpoints';

export const EXPORT_ANALYTICS_PEOPLES_ENGAGEMENT_URL =
  'data-exports/journeys-overview/peoples';

export const menuOptionsForAllJourneyOverviewTable = [
  {
    key: 'bounced',
    label: 'Bounced',
  },
  {
    key: 'clicked',
    label: 'Clicked',
  },
  {
    key: 'delivered',
    label: 'Delivered',
  },
  {
    key: 'opened',
    label: 'Opened',
  },
  {
    key: 'people_reached',
    label: 'People Reached',
  },
  {
    key: 'sent',
    label: 'Sent',
  },
  {
    key: 'skipped',
    label: 'Skipped',
  },
  {
    key: 'spam_reports',
    label: 'Spam Reports',
  },
  {
    key: 'unsubscribed',
    label: 'Unsubscribed',
  },
  {
    key: 'workflow_status',
    label: 'Journey Status',
  },
];

export const menuOptionsForJourneyTouchpointTable = [
  {
    key: 'bounced',
    label: 'Bounced',
  },
  {
    key: 'clicked',
    label: 'Clicked',
  },
  {
    key: 'delivered',
    label: 'Delivered',
  },
  {
    key: 'event_type',
    label: 'Touchpoint Type',
  },
  {
    key: 'opened',
    label: 'Opened',
  },
  {
    key: 'people_reached',
    label: 'People Reached',
  },
  {
    key: 'sent',
    label: 'Sent',
  },
  {
    key: 'skipped',
    label: 'Skipped',
  },
  {
    key: 'spam_reports',
    label: 'Spam Reports',
  },
  {
    key: 'unsubscribed',
    label: 'Unsubscribed',
  },
];

export const menuOptionsForPeopleTable = [
  {key: 'entityVariable', label: 'Entity Variable'},
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'phone',
    label: 'Phone',
  },
  {
    key: 'time_created',
    label: 'Time Sent',
  },
  {
    key: 'time_delivered',
    label: 'Time Delivered',
  },
  {
    key: 'time_opened',
    label: 'Time Opened',
  },
  {
    key: 'time_clicked',
    label: 'Time Clicked',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'skip_reason',
    label: 'Skip Reason',
  },
  {
    key: 'bounced_reason',
    label: 'Bounced Reason',
  },
];

/**
 * Formats a given date string to the custom format 'ddd, M/D/YY, h:mm A'.
 *
 * @param {string} inputStr - The input date string in the format 'YYYY-MM-DD HH:mm:ss.SSSSSS'.
 * @returns {string} - The formatted date string in the format 'ddd, M/D/YY, h:mm A'.
 */
export function formatToCustomDateString(inputStr: string): string {
  // Parse the input string to a Moment object
  const dt = moment(inputStr, 'YYYY-MM-DD HH:mm:ss.SSSSSS');
  // Convert the Moment object to the desired string format
  return dt.format('ddd, M/D/YY, h:mm A');
}

export const WORKFLOW_PAGE_SIZE = 10;

export const getWorkflowDashboardTableList = ({
  listState = {},
  pageSize,
  workflowId,
  nodeId,
  commonPayload,
}: WorkflowTableListAPIPayload): WorkflowOverviewDataTableQuery => {
  const {sort: {sortBy, sortOrder} = {}, pageNumber, query} = listState;
  let apiQuery = {
    ...commonPayload,
    limit: pageSize,
    offset: (pageNumber - 1) * pageSize,
  };
  if (workflowId) {
    apiQuery = {...apiQuery, workflow_id: workflowId};
  }
  if (nodeId) {
    apiQuery = {...apiQuery, node_id: nodeId};
  }
  if (query) {
    apiQuery = {...apiQuery, query};
  }
  if (sortBy && sortOrder !== 'original') {
    apiQuery = {...apiQuery, sort: sortOrder === 'asc' ? sortBy : `-${sortBy}`};
  }
  return apiQuery;
};

export const EXPORT_ANALYTICS_WORKFLOWS_ENGAGEMENT_URL =
  'data-exports/workflows-overview/workflows';

export const EXPORT_ANALYTICS_NODES_ENGAGEMENT_URL =
  'data-exports/workflows-overview/nodes';

export const EXPORT_ANALYTICS_PEOPLES_WORKFLOW_ENGAGEMENT_URL =
  'data-exports/workflows-overview/peoples';

export const menuOptionsForAllWorkflowOverviewTable = [
  {
    key: 'people_reached',
    label: 'People Reached',
  },
  {
    key: 'unsubscribed',
    label: 'Unsubscribed',
  },
  {
    key: 'spam_reports',
    label: 'Spam Reports',
  },
  {
    key: 'workflow_status',
    label: 'Workflow Status',
  },
  {
    key: 'sent',
    label: 'Sent',
  },
  {
    key: 'delivered',
    label: 'Delivered',
  },
  {
    key: 'clicked',
    label: 'Clicked',
  },
  {
    key: 'skipped',
    label: 'Skipped',
  },
  {
    key: 'bounced',
    label: 'Bounced',
  },
];

export const menuOptionsForAllNodesOverviewTable = [
  {
    key: 'bounced',
    label: 'Bounced',
  },
  {
    key: 'clicked',
    label: 'Clicked',
  },
  {
    key: 'delivered',
    label: 'Delivered',
  },
  {
    key: 'node_type',
    label: 'Node Type',
  },
  {
    key: 'people_reached',
    label: 'People Reached',
  },
  {
    key: 'sent',
    label: 'Sent',
  },
  {
    key: 'skipped',
    label: 'Skipped',
  },
  {
    key: 'spam_reports',
    label: 'Spam Reports',
  },
  {
    key: 'unsubscribed',
    label: 'Unsubscribed',
  },
];

export const menuOptionsForAllPeoplesOverviewTable = [
  {key: 'entityVariable', label: 'Entity Variable'},
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'phone',
    label: 'Phone',
  },
  {
    key: 'recipient_entity_id',
    label: 'Recipient Entity ID',
  },
  {
    key: 'skip_reason',
    label: 'Skip Reason',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'time_bounced',
    label: 'Time Bounced',
  },
  {
    key: 'time_clicked',
    label: 'Time Clicked',
  },
  {
    key: 'time_created',
    label: 'Time Created',
  },
  {
    key: 'time_delivered',
    label: 'Time Delivered',
  },
  {
    key: 'bounced_reason',
    label: 'Bounced Reason',
  },
];

export const workflowTableColumnsQuery = {
  product: 'workflows',
  dashboard: 'engagement-and-deliverability',
  sub_dashboard: 'workflow-view',
  table: 'workflows-data-table',
};

export const nodeTableColumnsQuery = {
  product: 'workflows',
  dashboard: 'engagement-and-deliverability',
  sub_dashboard: 'node-view',
  table: 'nodes-data-table',
};

export const peopleWorkflowTableColumnsQuery = {
  product: 'workflows',
  dashboard: 'engagement-and-deliverability',
  sub_dashboard: 'people-view',
  table: 'peoples-data-table',
};
