// @flow

// NOTE (kyle): this overrides html5 DnD behavior by allowing for a custom drag
// layer. It can remain simple because our app currently only has a single
// draggable element, but if we add any more we'll have to add drag previews for
// every item.

import * as React from 'react';
// $FlowFixMe[untyped-type-import]
import {useDragLayer, type DragLayerMonitor} from 'react-dnd-cjs';

import css from './layer.css';

import {FixedModuleRow} from 'src/components/workflow/event/content/module/row.jsx';

// $FlowFixMe[value-as-type] [v1.32.0]
const collect = (monitor: DragLayerMonitor) => ({
  item: monitor.getItem(),
  type: monitor.getItemType(),
  isDragging: monitor.isDragging(),
  currentOffset: monitor.getSourceClientOffset(),
});

export default function PreviewLayer(): null | React.Element<'div'> {
  const {item, type, currentOffset, isDragging} = useDragLayer(collect);

  React.useEffect(() => {
    if (isDragging) {
      window.document.body.style.cursor = 'grabbing';
    } else {
      window.document.body.style.cursor = '';
    }
  }, [isDragging]);

  if (!isDragging) {
    return null;
  }

  if (!currentOffset) {
    return null;
  }

  if (type !== 'Event Module' && type !== 'Branch Module') {
    return null;
  }

  if (!item.boundingClientRect) {
    return null;
  }

  const {left, top, right, bottom} = item.boundingClientRect;
  const [width, height] = [right - left, bottom - top];
  const {x, y} = currentOffset;
  const style = {
    transform: `translate(${x}px, ${y}px)`,
    width: `${width}px`,
    height: `${height}px`,
  };

  return (
    <div className={css.layer}>
      <div className={css.item} style={style}>
        {
          // $FlowFixMe this component is not interactible so it doesn't need all its props.
          <FixedModuleRow
            dynamicLabels={Object.freeze([])}
            jobDynamicLabels={Object.freeze([])}
            question={item.question}
            disabled={true}
            index={item.index}
            parentType="event"
          />
        }
      </div>
    </div>
  );
}
