// @flow

import * as React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

import {getMobilePanelSize, isMobileWindowWidth} from 'src/utils/responsive';
import {WindowWidthContext} from 'src/components/lib/responsive.jsx';


export {WindowWidthContext};

type ResponsiveInfo = {
  windowWidth: number,
  mobilePanelSize: number,
  isMobileWindow: boolean,
};

export const withWindowWidth = <T>(
  WrappedComponent: React.ComponentType<T>,
): React.ComponentType<
  $Diff<
    T,
    {
      windowWidth: any,
      mobilePanelSize: any,
      isMobileWindow: any,
    },
  >,
> =>
  hoistNonReactStatics(
    (props) => (
      <WindowWidthContext.Consumer>
        {(width) => (
          // $FlowFixMe
          <WrappedComponent
            {...props}
            windowWidth={width}
            mobilePanelSize={getMobilePanelSize(width)}
            isMobileWindow={isMobileWindowWidth(width)}
          />
        )}
      </WindowWidthContext.Consumer>
    ),
    WrappedComponent,
  );

export function useWindowWidth(): ResponsiveInfo {
  const width = React.useContext(WindowWidthContext);
  return {
    windowWidth: width,
    mobilePanelSize: getMobilePanelSize(width),
    isMobileWindow: isMobileWindowWidth(width),
  };
}
