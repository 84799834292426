// @noflow

import type {AudienceMemberResponse} from 'src/api-parsers/events';

import Store from './base';


export default class AudienceMemberResponseStore extends Store {
  state: {
    [key: string]: AudienceMemberResponse[],
  };

  constructor() {
    super('audience-member-responses');

    this.state = {};
  }

  get(audienceMemberId: string): AudienceMemberResponse[] {
    return this.state[audienceMemberId];
  }

  receive(audienceMemberId: string, responses: AudienceMemberResponse[]) {
    this.updateState({
      [audienceMemberId]: {
        $set: responses,
      },
    });
  }
}
