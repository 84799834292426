// @flow

// $FlowFixMe[untyped-type-import]
import type {AudienceMember} from 'src/api-parsers';
import type {ReceiveAudienceMembersAction} from 'src/action-creators/group-audience';
import type {PersonRelatedSummary} from 'src/types/entity-summaries';

import get from 'lodash/get';

import {
  RECEIVE,
  RECEIVE_ME_GROUP_AUDIENCE_PAGE,
} from 'src/action-creators/group-audience';


export type State = {
  [groupId: string]: {
    memberListPages: {
      // $FlowFixMe[value-as-type] [v1.32.0]
      [page: string]: AudienceMember[],
    },
    numTotalMembers: number,
    numPages: number,
    candidateListPages: {
      [key: string]: PersonRelatedSummary[],
    },
  },
};

const initialState = Object.freeze({});

export default (
  state: State = initialState,
  action: ReceiveAudienceMembersAction,
): State => {
  switch (action.type) {
    case RECEIVE:
      const memberListPages = get(
        state,
        [action.payload.securityGroupId, 'memberListPages'],
        {},
      );

      return {
        ...state,
        [action.payload.securityGroupId]: {
          numTotalMembers: action.payload.numTotalMembers,
          numPages: action.payload.numPages,
          memberListPages: {
            ...memberListPages,
            [String(action.payload.page)]: action.payload.memberList,
          },
        },
      };

    // $FlowFixMe[incompatible-type] [v1.32.0]
    case RECEIVE_ME_GROUP_AUDIENCE_PAGE:
      const candidateListPages = get(
        state,
        [action.payload.groupId, 'candidateListPages'],
        {},
      );

      return {
        ...state,
        [action.payload.groupId]: {
          numTotalMembers: action.payload.data.totalEntities,
          numPages: action.payload.data.numPages,
          candidateListPages: {
            ...candidateListPages,
            [String(action.payload.page)]: action.payload.data.entities,
          },
        },
      };
  }

  return state;
};
