// @flow

import type {NpsPlot} from 'src/types/report';
import type {NpsPlotAction} from 'src/action-creators/nps-plot';
import type {RecipientResource} from 'src/types/survey';

import {
  RECEIVE_AGENCY,
  RECEIVE_WORKFLOW,
  RECEIVE_EVENT,
} from 'src/action-creators/nps-plot';


export type NpsPlotState = {
  agency: ?NpsPlot,
  agencyClient: ?NpsPlot,
  agencyConsultant: ?NpsPlot,
  workflows: {
    [workflowId: string]: NpsPlot,
  },
  events: {
    [eventId: string]: NpsPlot,
  },
};

const initialState = {
  agency: null,
  agencyClient: null,
  agencyConsultant: null,
  workflows: Object.freeze({}),
  events: Object.freeze({}),
};

export default (
  state: NpsPlotState = initialState,
  action: NpsPlotAction,
): NpsPlotState => {
  switch (action.type) {
    case RECEIVE_AGENCY:
      if (action.recipient) {
        const updateKey = getAgencyRecipientUpdateKey(action.recipient);
        return {...state, [updateKey]: action.payload};
      }
      return {...state, agency: action.payload};

    case RECEIVE_WORKFLOW:
      return {
        ...state,
        workflows: {
          ...state.workflows,
          [action.payload.workflowId]: action.payload.plot,
        },
      };

    case RECEIVE_EVENT:
      return {
        ...state,
        events: {
          ...state.events,
          [action.payload.eventId]: action.payload.plot,
        },
      };
  }

  return state;
};

export const getAgencyRecipientUpdateKey = (
  // $FlowFixMe[value-as-type] [v1.32.0]
  recipient: RecipientResource,
): string =>
  ({
    client: 'agencyClient',
    audience_member: 'agencyConsultant',
    internal: 'agencyInternal',
  }[recipient]);
