// @flow

import type {AnalyticsQuery} from 'src/types/report';
// $FlowFixMe[untyped-type-import]
import type {UpdateOptions} from 'src/utils/analytics-query';

import {useHistory} from 'src/rerouter';
import * as React from 'react';

import {parseQuery, updateQuery} from 'src/utils/analytics-query';

/**
 * Read and modify "analytics querystrings" (i.e. ?q=....)
 *
 * the return value is a parsed version of the ?q query along with an updater function.
 *
 * the updater takes a Sculpt Update Spec Object and an optional set of options passed to updateQuery.
 * calling the updater will stringify the new ?q query param and router.push to the new location.
 */
export default function useAnalyticsQuery(): [
  AnalyticsQuery,
  // $FlowFixMe[value-as-type]
  (update: Object, options: UpdateOptions) => void,
] {
  const router = useHistory();
  const {location} = router;

  const {
    query: {q},
  } = location;

  const query = parseQuery(q);

  const handleQueryChange = (update, options = {}) => {
    let newQ = q;
    if (options.entityDataFilter && !query.edf) {
      newQ = updateQuery(newQ, {edf: {$set: {}}});
    }
    newQ = updateQuery(newQ, update, options);
    router.push({
      ...location,
      query: {
        ...location.query,
        q: newQ,
      },
    });
  };

  return [query, handleQueryChange];
}

// This is a variant of the above that retains a copy of the query in state
// and only updates it as a result of explicit changes to the router query
// the default behavior of the above hook to recreate the query makes it
// difficult to effect off the query because it may change very aggressively
//
// if you find that useAnalyticsQuery is causing a hook that depends on a query
// value to fire too often, try using this
export function useStatefulAnalyticsQuery(): [
  AnalyticsQuery,
  // $FlowFixMe[value-as-type]
  (update: Object, options?: UpdateOptions) => void,
] {
  const router = useHistory();
  const {location} = router;

  const {
    query: {q},
  } = location;

  const qRef = React.useRef(q);

  const [locationQuery, setLocationQuery] = React.useState(() =>
    parseQuery(qRef.current),
  );

  React.useEffect(() => {
    if (q !== qRef.current) {
      setLocationQuery(parseQuery(q));
      qRef.current = q;
    }
  }, [q]);

  const handleQueryChange = (update, options = {}) => {
    let newQ = q;
    if (options.entityDataFilter && !locationQuery.edf) {
      newQ = updateQuery(newQ, {edf: {$set: {}}});
    }
    newQ = updateQuery(newQ, update, options);
    router.push({
      ...location,
      query: {
        ...location.query,
        q: newQ,
      },
    });
  };

  return [locationQuery, handleQueryChange];
}
