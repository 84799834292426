// @flow strict

import * as React from 'react';

import {Input} from '@spaced-out/ui-design-system/lib/components/Input';
import PasswordStrength from 'src/components/authentication/common/password-strength/password-strength.jsx';
import css from './password-confirmation.css';

import type {PasswordConfirmationProps} from 'src/types/authentication.js';

//$FlowFixMe[cannot-resolve-name]
let zxcvbn = (_passwordString: string, _extra?: string[]): ?ZxcvbnData =>
  undefined;

const PasswordConfirmation = (props: PasswordConfirmationProps): React.Node => {
  const {
    passwordLabel,
    passwordPlaceholder,
    confirmPasswordLabel,
    confirmPasswordPlaceholder,
    setPasswordStrengthValue,
    setNewPasswordValue,
    setConfirmPasswordValue,
  } = props;

  const [passwordScore, setPasswordScore] = React.useState<number>(0);
  const [newPassword, setNewPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] =
    React.useState<string>('');
  const [showPasswordStrength, setShowPasswordStrength] =
    React.useState<boolean>(true);

  React.useEffect(() => {
    import('zxcvbn').then((_zxcvbn) => {
      zxcvbn = _zxcvbn.default;
    });
  }, []);

  const handlePasswordChange = (type, value) => {
    if (type === 'newPassword') {
      setNewPassword(value);
      // for parent container
      setNewPasswordValue(value);

      const zxcvbnData = zxcvbn(value);
      setPasswordScore(zxcvbnData?.score || 0);
      // for the parent container
      setPasswordStrengthValue(zxcvbnData?.score || 0);
    } else {
      setConfirmPassword(value);
      // for parent container
      setConfirmPasswordValue(value);
    }

    if (confirmPasswordError) {
      setConfirmPasswordError('');
    }
  };

  const isPasswordMatch = () => {
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError('Password must match');
    }
  };

  const handleConfirmPasswordBlue = () => {
    isPasswordMatch();
    setShowPasswordStrength(true);
  };

  return (
    <div className={css.passwordConfirmation}>
      <div>
        <Input
          label={passwordLabel}
          placeholder={passwordPlaceholder}
          size="medium"
          required={true}
          type="password"
          value={newPassword}
          onFocus={() => setShowPasswordStrength(true)}
          onChange={(e) => handlePasswordChange('newPassword', e.target.value)}
        />
      </div>
      <div className={css.confirmPassword}>
        <Input
          label={confirmPasswordLabel}
          placeholder={confirmPasswordPlaceholder}
          size="medium"
          required={true}
          type="password"
          value={confirmPassword}
          onBlur={handleConfirmPasswordBlue}
          onFocus={() => setShowPasswordStrength(false)}
          error={!!confirmPasswordError}
          errorText={confirmPasswordError}
          onChange={(e) =>
            handlePasswordChange('confirmPassword', e.target.value)
          }
        />
      </div>
      {showPasswordStrength && (
        <PasswordStrength className={css.strength} strength={passwordScore} />
      )}
    </div>
  );
};

export default PasswordConfirmation;
