// @noflow

import type {Router, RouteLocation} from 'src/types/router';

import * as React from 'react';

import {classify} from 'src/utils';

import {FilterCard} from './';
import Checkbox from 'src/components/lib/checkbox';

import css from './message-type-card.css';


type Props = {
  onSave: (e: *) => any,
  onCancel: (e: *) => any,
  onChange: (e: SyntheticEvent<*>) => any,
  selectedTypes: string[],
  eventTypeCounts: ?{[key: string]: number},
  eventTypes: {value: string, label: string}[],
  history: Router,
  location: RouteLocation,
};

const MessageTypeCard = ({
  onSave,
  onCancel,
  onChange,
  selectedTypes,
  eventTypes,
  eventTypeCounts,
}: Props) => (
  <FilterCard header="Message Type" onSave={onSave} onCancel={onCancel}>
    <ul className={css.checklist}>
      {eventTypes.map(({value, label}) => {
        // TODO (nilarnab): maybe the event-type-count api should do the consolidation
        const valueText = eventTypeCounts
          ? value === 'survey'
            ? eventTypeCounts[value] + eventTypeCounts['beefree_email']
            : eventTypeCounts[value]
          : '';
        return (
          <li key={value}>
            <label
              className={classify(css.label, {
                [css.labelChecked]: selectedTypes.includes(value),
              })}
            >
              <Checkbox
                className={css.checkbox}
                name={value}
                checked={selectedTypes.includes(value)}
                onChange={onChange}
              />
              {label} ({valueText})
            </label>
          </li>
        );
      })}
    </ul>
  </FilterCard>
);

export default MessageTypeCard;
