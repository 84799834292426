// @flow strict

import moment from 'moment-timezone';
import {RRule} from 'rrule';
import type {
  DateOffsetApi,
  SchedulingStoreData,
  Repeat,
  SchedulingUpdatePayloadBody,
  SchedulingValidations,
} from 'src/types/automation-workflow-scheduling.js';
import type {NodePayload} from 'src/types/automation-workflow';


export const TIME_HOUR_MAX = 720;

const DEFAULT_TIMEZONE = 'America/Los_Angeles';

const TIME_FORMAT = 'HH:mm:ss';

export const VALIDATIONS_INITIAL_STATE = {
  staticDateValueEmpty: false,
  staticDateValueWrong: false,
  staticTimeValueEmpty: false,
  dateFieldEmpty: false,
  timeFieldEmpty: false,
  repeatUntilEmpty: false,
  repeatFreqEmpty: false,
  repeatFreqWrong: false,
  skipEmpty: false,
  skipWrong: false,
  weekEmpty: false,
  dateEmpty: false,
  dateWrong: false,
  occurrenceEmpty: false,
  occurrenceWrong: false,
};

/*
 *  This method assumes that the hourInMinutes are any number of minutes up to 720 (12:00) and timePeriod is either
 *  0 (for AM) or 1 (for PM).
 *  It will return the hour as an integer between 0 and 23 (inclusive) which represents the hour in military time and
 *  the minute within the hour as an integer.
 *
 */
export function getMilitaryHourAndMinute(
  hourInMinutes: string,
  timePeriod: string,
): {militaryHour: number, minute: number} {
  const timeInMinutes =
    parseInt(hourInMinutes, 10) + parseInt(timePeriod, 10) * TIME_HOUR_MAX;
  const militaryHour = Math.floor(timeInMinutes / 60);
  const minute = timeInMinutes % 60;
  return {militaryHour, minute};
}

export function getHourPeriodFromTime(time: string): {
  hour: string,
  period: string,
} {
  let timeHour = '0',
    timePeriod = '0';

  if (time) {
    const hours = parseInt(time.slice(0, 2), 10);
    const minutes = hours * 60 + parseInt(time.slice(3, 5), 10);
    timeHour = (minutes % TIME_HOUR_MAX) + '';
    timePeriod = minutes >= TIME_HOUR_MAX ? '1' : '0';
  }

  return {hour: timeHour, period: timePeriod};
}

function getTimeFromHourAndMinute(hour, minute, timezone) {
  return moment
    .tz(moment.tz(timezone).format('YYYY-MM-DD'), timezone)
    .hour(hour)
    .minute(minute)
    .format(TIME_FORMAT);
}

export function getTimeFromHourPeriod(hour: string, period: string): string {
  const {militaryHour, minute} = getMilitaryHourAndMinute(hour, period);
  return getTimeFromHourAndMinute(militaryHour, minute);
}

export function displaySchedulingDate(date: string): string {
  return moment(date).format('DD/MM/YYYY');
}

export const TIME_HOUR_OPTIONS: Array<{value: string, label: string}> = [
  {
    value: '0',
    label: '12:00',
  },
  {
    value: '30',
    label: '12:30',
  },
  {
    value: '60',
    label: '1:00',
  },
  {
    value: '90',
    label: '1:30',
  },
  {
    value: '120',
    label: '2:00',
  },
  {
    value: '150',
    label: '2:30',
  },
  {
    value: '180',
    label: '3:00',
  },
  {
    value: '210',
    label: '3:30',
  },
  {
    value: '240',
    label: '4:00',
  },
  {
    value: '270',
    label: '4:30',
  },
  {
    value: '300',
    label: '5:00',
  },
  {
    value: '330',
    label: '5:30',
  },
  {
    value: '360',
    label: '6:00',
  },
  {
    value: '390',
    label: '6:30',
  },
  {
    value: '420',
    label: '7:00',
  },
  {
    value: '450',
    label: '7:30',
  },
  {
    value: '480',
    label: '8:00',
  },
  {
    value: '510',
    label: '8:30',
  },
  {
    value: '540',
    label: '9:00',
  },
  {
    value: '570',
    label: '9:30',
  },
  {
    value: '600',
    label: '10:00',
  },
  {
    value: '630',
    label: '10:30',
  },
  {
    value: '660',
    label: '11:00',
  },
  {
    value: '690',
    label: '11:30',
  },
];

export const TIME_PERIOD_OPTIONS: Array<{value: string, label: string}> = [
  {
    value: '0',
    label: 'AM',
  },
  {
    value: '1',
    label: 'PM',
  },
];

export const SCHEDULE_DATE_TYPES = {
  TRIGGER_DATE: 'TODAY',
  STATIC_DATE: 'STATIC',
  DATE_FIELD: 'DYNAMIC',
};

export const SCHEDULE_TIME_TYPES = {
  TRIGGER_TIME: 'NOW',
  STATIC_TIME: 'STATIC',
  TIME_FIELD: 'DYNAMIC',
};

const getDateOffsetObj = (date_offset: DateOffsetApi) => {
  const {years = 0, months = 0, weeks = 0, days = 0} = date_offset || {};
  if (years) {
    if (years > 0 || years < 0) {
      return {
        unit: 'years',
        count: Math.abs(years),
        sign: years < 0 ? -1 : 1,
      };
    }
  } else if (!!months && months > 0) {
    return {
      unit: 'months',
      count: Math.abs(months),
      sign: months < 0 ? -1 : 1,
    };
  } else if (!!weeks && weeks > 0) {
    return {
      unit: 'weeks',
      count: Math.abs(weeks),
      sign: weeks < 0 ? -1 : 1,
    };
  } else {
    return {
      unit: 'days',
      count: Math.abs(days) || 0,
      sign: !!days && days < 0 ? -1 : 1,
    };
  }
};

const getTimeOffsetObj = (time_offset) => {
  const {hours = 0} = time_offset || {};
  if (!!hours && (hours > 0 || hours < 0)) {
    return {
      unit: 'hours',
      count: Math.abs(hours) || 0,
      sign: !!hours && hours < 0 ? -1 : 1,
    };
  } else {
    return null;
  }
};

export const schedulingDataParse = (
  payload: NodePayload,
): SchedulingStoreData => {
  const {
    date_type,
    date_value,
    time_type,
    time_value,
    date_offset,
    recurring_rule,
    blackout_action,
  } = payload;
  let dateValue = date_value;
  let timeValue = time_value;
  let dateField = null;
  let timeField = null;
  if (date_type === SCHEDULE_DATE_TYPES.DATE_FIELD) {
    dateField = date_value ?? null;
    dateValue = '';
  }
  if (time_type === SCHEDULE_TIME_TYPES.TIME_FIELD) {
    timeField = time_value ?? null;
    timeValue = '';
  }
  const dateOffset =
    date_type === SCHEDULE_DATE_TYPES.DATE_FIELD
      ? getDateOffsetObj(date_offset)
      : null;
  const timeOffset =
    time_type === SCHEDULE_TIME_TYPES.TIME_FIELD
      ? getTimeOffsetObj(payload.time_offset)
      : null;

  let repeat: Repeat = {
    interval: 1,
    freq: RRule.DAILY,
  };
  if (recurring_rule && recurring_rule.rule) {
    const {rule} = recurring_rule;
    const options = RRule.parseString(rule);
    const {interval, freq, until, count, byweekday} = options;
    let repeatUntilKey = UNTIL_OPTIONS.INDEFINITELY;
    // TODO Check is until is a date instance.
    let endDate = null;
    if (until) {
      repeatUntilKey = UNTIL_OPTIONS.SPECIFIC_DATE;
      endDate = moment(until).format('YYYY-MM-DD');
    } else if (count) {
      repeatUntilKey = UNTIL_OPTIONS.REPEAT_LIMIT;
    }
    repeat = {
      interval,
      freq,
      repeatUntilKey,
      count,
      until: endDate,
      byweekday,
    };
  }

  return {
    id: payload.id,
    date_type,
    date_value: dateValue,
    date_field: dateField,
    dateOffset,
    time_type,
    time_value: timeValue,
    time_field: timeField,
    timeOffset,
    repeat_enable: !!payload.recurring_rule,
    repeat,
    skip: recurring_rule?.first_instance
      ? String(recurring_rule.first_instance - 1)
      : '0',
    blackout_action,
  };
};

export const getScheduleSummaryText = (
  {
    date_type,
    date_value,
    date_field,
    time_type,
    time_value,
    time_field,
  }: SchedulingStoreData,
  scheduleSeenOnce: boolean,
): string => {
  let schedulingSummary = 'Send';
  if (scheduleSeenOnce) {
    return `${schedulingSummary} when the previous node is added`;
  }
  if (date_type === SCHEDULE_DATE_TYPES.TRIGGER_DATE) {
    schedulingSummary = `${schedulingSummary} when workflow triggers`;
  } else if (date_type === SCHEDULE_DATE_TYPES.STATIC_DATE && date_value) {
    schedulingSummary = `${schedulingSummary} on ${date_value}`;
  } else if (date_type === SCHEDULE_DATE_TYPES.DATE_FIELD && date_field) {
    schedulingSummary = `${schedulingSummary} on ${date_field}`;
  }

  if (time_type === SCHEDULE_TIME_TYPES.TRIGGER_TIME) {
    schedulingSummary = `${schedulingSummary} at the scheduled time`;
  } else if (time_type === SCHEDULE_TIME_TYPES.STATIC_TIME && time_value) {
    const {hour: timeHour, period: timePeriod} =
      getHourPeriodFromTime(time_value);
    const selectedHour = TIME_HOUR_OPTIONS.find(
      (option) => option.value === timeHour,
    );
    const selectedPeriod = TIME_PERIOD_OPTIONS.find(
      (option) => option.value === timePeriod,
    );
    if (selectedHour && selectedPeriod) {
      schedulingSummary = `${schedulingSummary} at ${selectedHour.label} ${selectedPeriod.label}`;
    }
  } else if (time_type === SCHEDULE_TIME_TYPES.TIME_FIELD && time_field) {
    schedulingSummary = `${schedulingSummary} at ${time_field}`;
  }
  return schedulingSummary;
};

export const UNTIL_OPTIONS = {
  INDEFINITELY: '0',
  SPECIFIC_DATE: '1',
  REPEAT_LIMIT: '2',
};

export const normalizeSchedulingBody = (
  schedulingStoreData: SchedulingStoreData,
): SchedulingUpdatePayloadBody => {
  const {
    id,
    date_type,
    date_value,
    time_type,
    time_value,
    repeat_enable,
    repeat,
    date_field,
    dateOffset,
    time_field,
    timeOffset,
    skip,
    blackout_action,
  } = schedulingStoreData;
  let date_offset = null;
  let dateValue = date_value;
  let timeValue = time_value;
  if (date_type === SCHEDULE_DATE_TYPES.DATE_FIELD) {
    if (dateOffset?.unit) {
      date_offset = {
        [dateOffset.unit]:
          parseInt(dateOffset.count, 10) * parseInt(dateOffset.sign, 10),
      };
    }
    dateValue = date_field ?? '';
  }
  let time_offset = null;
  if (time_type === SCHEDULE_TIME_TYPES.TIME_FIELD) {
    if (timeOffset?.count) {
      time_offset = {
        hours: parseInt(timeOffset.count, 10) * parseInt(timeOffset.sign, 10),
      };
    }
    timeValue = time_field ?? '';
  }
  const rruleOptions = repeat ? normalizeRruleOptions(repeat) : {};
  const recurring_rule = repeat_enable
    ? {
        rule: RRule.optionsToString(rruleOptions),
        first_instance: Number(skip) + 1,
      }
    : null;
  return {
    type: 'schedule',
    date_type,
    date_value: dateValue,
    time_type,
    date_offset,
    time_value: timeValue,
    time_offset,
    recurring_rule,
    id,
    blackout_action,
  };
};

const normalizeRruleOptions = (repeatOptions: Repeat) => {
  const {
    freq = RRule.DAILY,
    count = 0,
    byweekday = '',
    interval = 0,
    until = '',
    repeatUntilKey,
  } = repeatOptions || {};

  const rruleOptions: Repeat = {freq, interval};
  if (repeatUntilKey === UNTIL_OPTIONS.SPECIFIC_DATE && until) {
    rruleOptions.until = new Date(until);
  } else if (repeatUntilKey === UNTIL_OPTIONS.REPEAT_LIMIT && count) {
    rruleOptions.count = count;
  }
  if (freq === RRule.WEEKLY && byweekday) {
    rruleOptions.byweekday = byweekday;
  }
  return rruleOptions;
};

export const rruleToText = (repeatOptions: Repeat): string => {
  const rruleOptions = normalizeRruleOptions(repeatOptions);
  const rule = new RRule(rruleOptions);
  return rule.toText();
};

export const recurrenceRRuleToText = (rrule: string): string => {
  const rruleObj = RRule.fromString(rrule);
  return rruleObj.toText();
};

export const getErrors = (errors: SchedulingValidations): Array<string> => {
  const err = [];
  const hasEmptyList = [
    'staticDateValueEmpty',
    'staticTimeValueEmpty',
    'dateFieldEmpty',
    'timeFieldEmpty',
    'repeatUntilEmpty',
    'repeatFreqEmpty',
    'skipEmpty',
    'weekEmpty',
    'dateEmpty',
    'occurrenceEmpty',
  ].some((err) => errors[err]);
  if (hasEmptyList) {
    err.push('Please fill the required fields.');
  }
  if (errors.staticDateValueWrong) {
    err.push('The selected date cannot be earlier than today\'s date.');
  }
  if (errors.dateWrong) {
    err.push('The selected date cannot be earlier than today\'s date.');
  }
  if (errors.repeatFreqWrong) {
    err.push(
      'Please input a valid format for Repeat Every (Zero, negative numbers, and decimals are not permitted).',
    );
  }
  if (errors.occurrenceWrong) {
    err.push(
      'Please input a valid format for Occurrence (Zero, negative numbers, and decimals are not permitted).',
    );
  }
  if (errors.skipWrong) {
    err.push(
      'Please input a valid format for Skip (Negative numbers and decimals are not permitted).',
    );
  }
  return err;
};
