// @flow strict

import type {
  DisplayConfig,
  DisplayConfigValues,
} from 'src/types/display-config';

import {useSelector} from 'react-redux';

import {
  selectDisplayConfigByKey,
  selectAbtEnabled,
  selectJ2Enabled,
} from 'src/selectors/display-config';


type UseDisplayConfig = (key: string) => DisplayConfigValues;
/*
 * ```
 * useDisplayConfig().displayConfigKey;
 * ```
 * This ensures you get the correct flag Flow type.
 */
const useDisplayConfig: UseDisplayConfig = function useDisplayConfig(key) {
  const displayConfig = useSelector((state) =>
    selectDisplayConfigByKey(state, key),
  );
  return displayConfig;
};

//NOTE:(diwakersurya) this includes OR|NOT Rules and multiple list mapping
export const useAudienceListPowerFeatures = (): DisplayConfigValues => {
  return useDisplayConfig('list_power_features_enabled');
};
export const useAbtEnabled = (): DisplayConfigValues => {
  const isAbtEnabled = useSelector((state) => selectAbtEnabled(state));
  return isAbtEnabled;
};
export const useJ2Enabled = (): DisplayConfigValues => {
  return useSelector(selectJ2Enabled);
};

export default useDisplayConfig;
