// @flow

// $FlowFixMe[untyped-type-import]
import type {RecentlyVisitedEvent} from 'src/api-parsers/index';

import keyBy from 'lodash/keyBy';

import type {State} from 'src/reducers';


export const selectRecentlyVisitedEvents = (
  state: State,
  // $FlowFixMe[value-as-type] [v1.32.0]
): {[eventId: string]: RecentlyVisitedEvent} => {
  const recentlyVisitedEvents =
    state.agencyOverview?.data.recentlyVisitedEvents || [];

  return keyBy(recentlyVisitedEvents, 'event_id');
};

export const selectNpsData = (
  state: State,
): ?{detractors: number, passive: number, promoters: number, score: number} =>
  state.agencyOverview?.data.nps;
