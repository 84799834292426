// @flow strict
import * as React from 'react';

import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {Icon} from '@spaced-out/ui-design-system/lib/components/Icon';
import {
  Toast,
  ToastBody,
  ToastFooter,
  ToastTitle,
} from '@spaced-out/ui-design-system/lib/components/Toast';

import css from './ai-intro-panel.css';


type ClassNames = $ReadOnly<{
  wrapper?: string,
  image?: string,
  title?: string,
  description?: string,
  children?: string,
}>;

export type SenseAiIntroPanelProps = {
  classNames?: ClassNames,
  isOpen?: boolean,
  description?: React.Node,
  confirmButtonText?: string,
  heading?: React.Node,
  onConfirmButtonClick?: ?(SyntheticEvent<HTMLElement>) => mixed,
  onDismissButtonClick?: () => void,
};

export const SenseAiIntroPanel = ({
  heading = 'Content Generator',
  description = 'Hey, I can help you create a message',
  confirmButtonText = 'Yes, Try',
  onConfirmButtonClick,
  onDismissButtonClick,
}: SenseAiIntroPanelProps): React.Node => {
  return (
    <>
      <Toast
        classNames={{wrapper: css.introToast}}
        initialFocus={0}
        semantic="information"
        onClose={() => onDismissButtonClick?.()}
        customIcon={<Icon name="sparkles" type="solid" color="information" />}
      >
        <ToastTitle className={css.toastTitle}>{heading}</ToastTitle>
        <ToastBody>{description}</ToastBody>
        <ToastFooter>
          <Button type="secondary" onClick={onConfirmButtonClick}>
            {confirmButtonText}
          </Button>
        </ToastFooter>
      </Toast>
    </>
  );
};
