// @noflow
import typeof IndexStore from 'src/stores/index';
import type {EventTemplate} from 'src/api-parsers/events';

import flow from 'lodash/flow';

import logger from 'src/utils/logger';

import * as api from 'src/utils/api';
import parsers from 'src/api-parsers/events';

import {key, cached, fetching} from 'src/utils/action';


export const getEventTemplates = flow(
  key(() => 'event-templates'),
  cached({ttl: 120 * 1000}),
  fetching(),
)(
  (store: IndexStore): Promise<EventTemplate[]> => {
    const promise = api
      .get(store, 'event-templates')
      .then(response => {
        const templates = parsers.parse.eventTemplates(response);
        store.eventTemplates.receiveAll(templates);
        return templates;
      })
      .catch(error => {
        logger.error(
          'event-template getEventTemplates error: ',
          error.stack || error,
        );
      });

    return promise;
  },
);

export const getEventTemplate = flow(
  key((templateId: string) => `event-template-${templateId}`),
  cached({ttl: 120 * 1000}),
  fetching(),
)(
  (store: IndexStore, templateId: string): Promise<EventTemplate> => {
    const promise = api
      .get(store, `event-templates/${templateId}`)
      .then(response => {
        const template = parsers.parse.eventTemplate(response);

        store.eventTemplates.receive(template);
        return template;
      });
    return promise;
  },
);

export const updateEventTemplate = function(
  store: IndexStore,
  template: EventTemplate,
): Promise<EventTemplate> {
  const payload = parsers.normalize.eventTemplate(template);
  const promise = api
    .put(store, `event-templates/${template.id}`, payload)
    .then(response => {
      const updatedTemplate = parsers.parse.eventTemplate(response);

      store.eventTemplates.receive(updatedTemplate);
      return updatedTemplate;
    });
  return promise;
};

export const createNewTemplate = function(
  store: IndexStore,
  name: string,
  html: string,
): Promise<EventTemplate> {
  const promise = api
    .post(store, 'event-templates', {name, html})
    .then(response => {
      const freshTemplate = parsers.parse.eventTemplate(response);
      store.eventTemplates.receive(freshTemplate);

      return freshTemplate;
    });
  return promise;
};

export const deleteEventTemplate = function(
  store: IndexStore,
  templateId: string,
): Promise<*> {
  return api.del(store, `event-templates/${templateId}`).then(
    () => {
      store.eventTemplates.remove(templateId);
      return true;
    },
    err => {
      logger.error(err.stack || err);
      return false;
    },
  );
};

export const copyEventTemplate = function(
  store: IndexStore,
  templateId: string,
): Promise<*> {
  return api.post(store, `event-templates/${templateId}/copy`, {}).then(
    resp => {
      const copy = parsers.parse.eventTemplate(resp);
      store.eventTemplates.receive(copy);

      return copy;
    },
    err => {
      logger.error(err.stack || err);
      return false;
    },
  );
};
