// @flow

import * as React from 'react';

import {
  TokenListInput,
  type TokenListInputProps,
  type TokenProps,
  StringListInput,
  type StringListInputProps,
  ValueListInput,
  type ValueListInputProps,
} from './new-stuff.jsx';
import FilledToken from './filled-token.jsx';

// option prop is optional, see type TokenProps<V> in new-stuff.jsx
// the real signature here should be some variant of TokenProps where
// V includes color optionally
const ColorToken = (props: TokenProps<{color?: string, ...}>) => (
  <FilledToken pillColor={props.option?.color} {...props} />
);

const sharedProps = {
  showValuesOutside: true,
  allowArbitraryValues: false,
  searchOnEmptyString: true,
  searchable: true,
};

const selectListProps = {
  ...sharedProps,
  components: {
    Token: ColorToken,
  },
};

const SelectList = <T>(props: TokenListInputProps<T>): React.Node => (
  // $FlowFixMe[escaped-generic]
  // $FlowFixMe[incompatible-type]
  <TokenListInput {...selectListProps} {...props} />
);

export default SelectList;

export const StringSelectList = (props: StringListInputProps): React.Node => (
  <StringListInput {...sharedProps} {...props} />
);

export const ValueSelectList = <T>(
  props: ValueListInputProps<T>,
): React.Node => <ValueListInput {...sharedProps} {...props} />;
