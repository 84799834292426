// @flow strict

import * as React from 'react';
import Loading from 'src/components/lib/loading/loading.jsx';
import css from './image-input.css';
//$FlowFixMe
import {backgroundImage} from 'src/utils/index';


type Props = {
  src: ?string,
  loading?: ?boolean,
  onChange: (event: SyntheticInputEvent<HTMLInputElement>) => mixed,
  uploadHint?: React.Node,
};

const ImageInput = (props: Props): React.Node => {
  const {src, loading, onChange, uploadHint} = props;

  return src ? (
    <div className={css.logoContainer}>
      <div className={css.logoPreview} style={backgroundImage(src)}></div>
      <input className={css.logoUploadInput} type="file" onChange={onChange} />
    </div>
  ) : (
    <label>
      <div className={css.logoPreviewBlank}>
        {loading ? <Loading /> : <div>{uploadHint}</div>}
      </div>
      <input
        className={css.logoUploadInput}
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        onChange={onChange}
      />
    </label>
  );
};

export default ImageInput;
