// @flow strict

import type {AtsEntity} from 'src/types/ats-entities';
import type {
  ReferralSetting,
  PayoutRuleData,
  RuleBlockData,
  RuleData,
} from 'src/types/referral-v2';
// $FlowFixMe[nonstrict-import]
import type {DynamicLabels} from 'src/types/dynamic-labels';

import * as React from 'react';

import {useSelector, useDispatch} from 'react-redux';
import {useAsyncDependency} from 'src/hooks/useAsyncDependency';

import classify from 'src/utils/classify';
import {getRuleInfo} from 'src/components/referral-v2/common.js';

import {selectReferralSetting} from 'src/selectors/referral-settings.js';
// $FlowFixMe[nonstrict-import]
import {selectEntityBySenseName} from 'src/selectors/ats-entities';
import {selectList} from 'src/selectors/audience-list';
// $FlowFixMe[nonstrict-import]
import {selectAudienceListFields} from 'src/selectors/dynamic-labels';

//$FlowFixMe[nonstrict-import]
import {updatePayoutRule} from 'src/action-creators/referral-v2/referral-settings.js';
import {updateState} from 'src/action-creators/audience-list';
import {getTargetEntityTypes} from 'src/action-creators/target-entity-types';
// $FlowFixMe[nonstrict-import]
import {getAllEntityAttributes} from 'src/action-creators/ats-entities';
// $FlowFixMe[nonstrict-import]
import {getAllowedEntityTypes} from 'src/action-creators/workflows';
import {selectTargetEntityTypes} from 'src/selectors/target-entity-types';

import {
  FormLabelSmall,
  SubTitleSmall,
  SubTitleExtraSmall,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {type MenuOption} from '@spaced-out/ui-design-system/lib/components/Menu';
import {CircularLoader} from '@spaced-out/ui-design-system/lib/components/CircularLoader';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {Card} from '@spaced-out/ui-design-system/lib/components/Card';
import {Tooltip} from '@spaced-out/ui-design-system/lib/components/Tooltip';
import {Icon} from '@spaced-out/ui-design-system/lib/components/Icon';
import {Dropdown} from '@spaced-out/ui-design-system/lib/components/Dropdown';

// $FlowFixMe[nonstrict-import]
import {NewVariableDropdownRow} from 'src/components/lib/variable-picker/variable-picker-dropdown-row.jsx';
import StartDateRuleBlock from 'src/components/referral-v2/payout-rule/start-date-rule-block.jsx';
import EndDateRuleBlock from 'src/components/referral-v2/payout-rule/end-date-rule-block.jsx';
import Rule from 'src/components/referral-v2/payout-rule/rule.jsx';
import css from 'src/components/referral-v2/configure/configure.css';
import rulesCss from 'src/components/referral-v2/settings/settings.css';


const PayoutRule = ({disabled}: {disabled: boolean}): React.Node => {
  const entityFilterList = [
    'job_submission',
    'placement',
    'candidate_application',
    'appointment',
  ];
  const entityTypes = useSelector(selectTargetEntityTypes);

  const entityTypeOptions: MenuOption[] = entityTypes
    .filter((entityType) =>
      entityFilterList.includes(entityType.standard_entity_type),
    )
    .map((entity) => ({
      label: entity.display_name,
      key: entity.entity_type,
    }));

  const dispatch = useDispatch();

  const referralSettings: ?ReferralSetting = useSelector(selectReferralSetting);

  const payoutRules: ?PayoutRuleData =
    referralSettings?.one_time_settings.payout_rule;

  const selectedEntity = entityTypes.find(
    (entity) => entity.entity_type === payoutRules?.tracking_entity,
  );

  const entity: AtsEntity = useSelector((state) =>
    selectEntityBySenseName(
      state,
      //$FlowFixMe[incompatible-call]
      selectedEntity?.standard_entity_type ?? 'placement',
    ),
  );

  const entityName = entity ? entity.name : '';

  const {entityType} = useSelector(selectList);

  const fields: DynamicLabels = useSelector((state) =>
    selectAudienceListFields(state, entityType),
  );

  const filteredFields = getFilteredFields(
    fields,
    new Map([]).set('entityType', entityFilterList),
  );

  const getEntityOptionsByFieldType = (filter) =>
    filteredFields
      .filter((field) => (filter ? field.type.includes(filter) : true))
      .map((field) => ({
        customComponent: (
          <NewVariableDropdownRow
            className={rulesCss.option}
            isArbitrary={false}
            isSelected={false}
            // eslint-disable-next-line no-empty-function
            onClick={() => {}}
            option={field}
            resolveLabel={(item) => item.label}
          />
        ),
        label: field.label,
        key: field.value,
        classNames: {wrapper: rulesCss.optionContainer},
      }));

  const handleUpdateRuleBlockData = (
    ruleBlockType: string,
    ruleBlockData: ?RuleBlockData,
  ) => {
    const updatedData = {
      ...payoutRules,
      tracking_entity: entityName,
      [ruleBlockType]: ruleBlockData,
    };
    dispatch(updatePayoutRule(updatedData));
  };

  const handleAddRule = (
    ruleBlockType: string,
    ruleBlockData: ?RuleBlockData,
  ) => {
    const newRule = {
      field_name: '',
      operator: '',
      rule_value: '',
    };
    const updatedRule = {
      rule_type: 'event',
      rule: ruleBlockData?.rule ? [...ruleBlockData.rule, newRule] : [newRule],
    };
    handleUpdateRuleBlockData(ruleBlockType, updatedRule);
  };

  const onDeleteRule = (
    ruleIndex: number,
    ruleBlockType: string,
    ruleBlockData: ?RuleBlockData,
  ) => {
    const updatedRule = {
      rule_type: 'event',
      rule: ruleBlockData?.rule?.filter(
        (payoutRule, index) => index !== ruleIndex,
      ),
    };
    handleUpdateRuleBlockData(ruleBlockType, updatedRule);
  };

  const onEditRule = (
    ruleData: RuleData,
    ruleBlockType: string,
    ruleBlockData: ?RuleBlockData,
    ruleIndex: number,
  ) => {
    const updatedRule = {
      rule_type: 'event',
      rule: ruleBlockData?.rule?.map((payoutRule, index) =>
        index === ruleIndex ? ruleData : payoutRule,
      ),
    };
    handleUpdateRuleBlockData(ruleBlockType, updatedRule);
  };

  const handleCopyRule = () => {
    handleUpdateRuleBlockData('start_date_rule', {
      rule_type: 'event',
      rule: payoutRules?.hired_rule?.rule,
    });
  };

  const handleSelectRule = (
    option: MenuOption,
    ruleBlockType: string,
    ruleBlockData: ?RuleBlockData,
    ruleIndex?: number,
  ) => {
    const rule =
      filteredFields.find((field) => field.value === option.key) || {};

    const newRule = {
      field_name: rule?.value,
      operator: rule.type.includes('date') ? 'is_not_null' : 'eq',
      rule_value: '',
    };

    const updateRuleBlock = (updates) =>
      handleUpdateRuleBlockData(ruleBlockType, {
        rule_type: ruleBlockData?.rule_type ?? '',
        ...updates,
      });

    if (ruleBlockData?.rule_type === 'event') {
      if (ruleBlockData.rule && ruleBlockData.rule.length > 0) {
        const updateRuleList = ruleBlockData.rule.map((ruleData, index) =>
          index === ruleIndex
            ? {
                field_name: rule?.value,
                operator: rule.type.includes('date') ? 'is_not_null' : 'eq',
                rule_value: '',
              }
            : ruleData,
        );
        updateRuleBlock({rule: updateRuleList});
      } else {
        updateRuleBlock({
          rule: [newRule],
        });
      }
    } else if (
      ruleBlockData?.rule_type === 'field' &&
      ruleBlockData.field?.field_name !== rule.value
    ) {
      updateRuleBlock({
        field: {field_name: rule.value},
      });
    }
  };

  const onChangeEntityType = (option) => {
    dispatch(
      updatePayoutRule({
        tracking_entity: option.key,
        hired_rule: {rule_type: 'event', rule: []},
        start_date_rule: {rule_type: 'field', field: {field_name: ''}},
        end_date_rule: null,
      }),
    );
  };

  React.useEffect(() => {
    dispatch(
      updateState({
        entityType: entityName,
      }),
    );
  }, [entityName]);

  const isDataLoading = useAsyncDependency(
    () =>
      Promise.all([
        dispatch(getAllEntityAttributes()),
        dispatch(getAllowedEntityTypes()),
        dispatch(getTargetEntityTypes()),
      ]),
    [],
  );
  if (isDataLoading) {
    return (
      <Card classNames={{wrapper: rulesCss.payoutCard}}>
        <CircularLoader className={css.loader} />
      </Card>
    );
  }
  return (
    <div className={css.contentSection}>
      <SubTitleSmall className={rulesCss.payoutLabel}>
        Payout Policy
      </SubTitleSmall>
      <Card classNames={{wrapper: rulesCss.payoutCard}}>
        <SubTitleSmall color="secondary">
          Create policy to payout referrers for their successful referrals
        </SubTitleSmall>
        <div className={classify(rulesCss.flexColumn, rulesCss.ruleInput)}>
          <Dropdown
            disabled={disabled}
            label={
              <FormLabelSmall color="secondary">
                Focus entity{' '}
                <Tooltip
                  titleMaxLines={3}
                  title="Select an entity to map the fields of remaining form."
                >
                  <Icon size="small" name="info-circle" />
                </Tooltip>
              </FormLabelSmall>
            }
            menu={{
              options: entityTypeOptions,
              selectedKeys: selectedEntity?.entity_type
                ? [selectedEntity.entity_type]
                : [],
            }}
            onChange={onChangeEntityType}
            required={true}
            size="small"
            dropdownInputText={selectedEntity?.display_name ?? 'Select'}
          />
        </div>
        <div className={classify(rulesCss.flexColumn, rulesCss.defaultGap)}>
          <FormLabelSmall color="secondary">
            1. Define the rule which signifies that the referral is hired as per
            your system: *
          </FormLabelSmall>
          {payoutRules?.hired_rule?.rule?.map((payoutRule, index) => {
            const rule = filteredFields.find(
              (field) => field.value === payoutRule.field_name,
            );
            return (
              <Rule
                options={getEntityOptionsByFieldType()}
                disabled={disabled}
                fieldObject={payoutRule}
                rule={rule}
                showAndText={index > 0}
                hideRemoveIcon={index === 0}
                handleSelectRule={(option) =>
                  handleSelectRule(
                    option,
                    'hired_rule',
                    payoutRules.hired_rule,
                    index,
                  )
                }
                onRemove={() =>
                  onDeleteRule(index, 'hired_rule', payoutRules.hired_rule)
                }
                onEdit={(ruleData) =>
                  onEditRule(
                    ruleData,
                    'hired_rule',
                    payoutRules.hired_rule,
                    index,
                  )
                }
              />
            );
          })}
          <div className={rulesCss.ruleInputContainer}>
            <Button
              size="small"
              iconLeftName="plus"
              isFluid={false}
              type="secondary"
              disabled={disabled}
              onClick={() =>
                handleAddRule('hired_rule', payoutRules?.hired_rule)
              }
            >
              Add a new clause
            </Button>
          </div>
          {payoutRules?.hired_rule?.rule &&
            payoutRules.hired_rule.rule.length > 0 && (
              <Card classNames={{wrapper: rulesCss.chip}}>
                <SubTitleExtraSmall color="secondary">
                  If the field
                  {getRuleInfo(payoutRules.hired_rule.rule)}, we will consider
                  the referral as hired.
                </SubTitleExtraSmall>
              </Card>
            )}
        </div>
        <div className={classify(rulesCss.flexColumn, rulesCss.defaultGap)}>
          <FormLabelSmall color="secondary">
            2. Define the rule which help us to identify the start date of the
            referral in your system: *
          </FormLabelSmall>
          <StartDateRuleBlock
            getEntityOptionsByFieldType={getEntityOptionsByFieldType}
            ruleBlockData={payoutRules?.start_date_rule}
            disabled={disabled}
            entityFields={filteredFields}
            handleUpdateRuleBlockData={handleUpdateRuleBlockData}
            handleSelectRule={handleSelectRule}
            handleAddRule={handleAddRule}
            handleCopyRule={handleCopyRule}
            onDeleteRule={onDeleteRule}
            onEditRule={onEditRule}
          />
        </div>
        <div className={classify(rulesCss.flexColumn, rulesCss.defaultGap)}>
          <FormLabelSmall color="secondary">
            3. Is there a policy related to referral completing a defined period
            of time before being considered for payout: *
          </FormLabelSmall>
          <EndDateRuleBlock
            getEntityOptionsByFieldType={getEntityOptionsByFieldType}
            ruleBlockData={payoutRules?.end_date_rule}
            disabled={disabled}
            entityFields={filteredFields}
            handleUpdateRuleBlockData={handleUpdateRuleBlockData}
            handleSelectRule={handleSelectRule}
            handleAddRule={handleAddRule}
            onDeleteRule={onDeleteRule}
            onEditRule={onEditRule}
          />
        </div>
      </Card>
    </div>
  );
};

export default PayoutRule;

function getFilteredFields(labels, filters) {
  const entityTypeFilter = filters.get('entityType') || [];
  if (entityTypeFilter.length === 0) {
    return labels;
  }
  const filteredLabels = entityTypeFilter.reduce((acc, entityType) => {
    labels
      .filter(
        (label) =>
          label.source !== 'custom' && label.mapping.sense_name === entityType,
      )
      .forEach((label) => acc.push(label));

    return acc;
  }, []);

  return filteredLabels;
}
