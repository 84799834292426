// @flow strict

import toPath from 'lodash/toPath';

import {values} from 'src/utils/object';


export type Validation = {
  children: {
    [string]: ?Validation,
  },
  errors?: string[],
};

/**
 * Validate a nested item in a path.
 *
 * if test is falsey, the errorName will be attached to the supplied path
 *
 * @param {?Validation} errorArg - an optional, mutable, Validation argument
 * @param {boolean} test - an invariant test that needs to be true
 * @param {string | string[]} path - a path to identify the location of the error
 * @param {string} errorName - the name / error to assign for this path
 */
export function setError(
  errorArg: ?Validation,
  test: mixed,
  pathArg: string | string[],
  words: string,
): ?Validation {
  if (test) {
    return errorArg;
  }
  const error = errorArg || ({children: {}}: Validation);
  const path = toPath(pathArg);

  let current: Validation = error;
  for (let i = 0; i < path.length; i++) {
    const key = path[i];
    let child = current.children[key];

    if (!child) {
      child = {children: {}};
      current.children[key] = child;
    }

    current = child;
  }

  current.errors = current.errors || [];
  current.errors.push(words);
  return error;
}

export function getValidation(
  errorArg: ?Validation,
  pathArg: string | mixed[],
): ?Validation {
  const path = toPath(pathArg);

  let item = errorArg;
  for (const key of path) {
    if (!item) {
      return;
    }

    item = item.children[key];
  }

  return item;
}

export function getErrors(
  errorArg: ?Validation,
  pathArg: string | mixed[],
): ?(string[]) {
  return getValidation(errorArg, pathArg)?.errors;
}

export function filterValidation(
  errorArg: ?Validation,
  keys: Array<string>,
): ?Validation {
  let result;
  for (const key of keys) {
    const subValidation = errorArg?.[key];
    if (subValidation) {
      result = {
        ...result,
        ...subValidation,
      };
    }
  }
  return result;
}

// NOTE (kyle): For ease-of-use, this function supports a wildcard '*'
// in the path, but using even one of these will greatly compromise
// performance. Please avoid it whenever possible.
export function hasError(
  errorArg: ?Validation,
  pathArg: string | $ReadOnlyArray<mixed>,
  errorString: string,
): boolean {
  const path = toPath(pathArg);

  let item = errorArg;
  for (let i = 0; i < path.length; i++) {
    if (!item) {
      return false;
    }

    const key = path[i];

    if (key === '*') {
      const subPath = path.slice(i + 1);
      return values(item.children).some((child) =>
        hasError(child, subPath, errorString),
      );
    }

    item = item.children[key];
  }

  return item?.errors?.includes(errorString) || false;
}
