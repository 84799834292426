// @flow strict
import * as React from 'react';
import {Clickable} from 'src/designSystem2021Components/text-v2.jsx';
import {backgroundImage} from 'src/utils/css';
import classify from 'src/utils/classify';

import css from './branding-preview.css';
import maincss from 'src/components/agency-onboard/agency-onboard.css';


export type PreviewProps = {
  brandColor?: string,
  buttonColor?: string,
  logo?: string,
};

export const Preview = ({
  brandColor,
  buttonColor,
  logo,
}: PreviewProps): React.Node => {
  const boxStyle = {
    borderTopColor: brandColor,
  };

  const solidButtonStyle = {
    borderColor: buttonColor,
    backgroundColor: buttonColor,
  };
  const clearButtonStyle = {
    borderColor: buttonColor,
    color: buttonColor,
  };

  return (
    <>
      <Clickable>Preview</Clickable>
      <div className={css.previewBox} style={boxStyle}>
        <div className={css.logoSection}>
          {logo ? (
            <div className={css.previewLogo} style={backgroundImage(logo)} />
          ) : (
            <div className={css.previewLogoBlank}>Logo</div>
          )}
          <div className={css.previewTextSection}>
            <Clickable className={classify(css.previewText, maincss.text)}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </Clickable>
          </div>
        </div>
        <div className={css.buttonRow}>
          <div className={css.previewButtonWhite} style={clearButtonStyle}>
            Button - Empty
          </div>
          <div className={css.previewButton} style={solidButtonStyle}>
            Button - Filled
          </div>
        </div>
      </div>
    </>
  );
};
