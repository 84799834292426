// @flow

// $FlowFixMe[untyped-type-import]
import type {Workflow} from 'src/api-parsers/index';
import type {Question} from 'src/types/survey';
// $FlowFixMe[untyped-type-import]
import type {Event} from 'src/api-parsers/events';

// $FlowFixMe[untyped-type-import]
import type {BranchInfo} from 'src/stores/event-creation';
import type {DynamicLabels} from 'src/types/dynamic-labels';

import * as React from 'react';

import Modules from 'src/components/workflow/event/content/modules.jsx';


const BranchModules = (props: {
  showErrors?: boolean,
  // $FlowFixMe[value-as-type] [v1.32.0]
  workflow: Workflow,
  // $FlowFixMe[value-as-type] [v1.32.0]
  branchInfo: BranchInfo,
  // $FlowFixMe[value-as-type] [v1.32.0]
  editingQuestion: Question,
  questionError: ?Object,
  dynamicLabels: string[],
  conditionalAlerts: boolean,
  jobDynamicLabels: DynamicLabels,
  isBranchedEvent: boolean,
  // $FlowFixMe[value-as-type] [v1.32.0]
  parentEvent?: Event,
}): React.Node => (
  <Modules
    {...props}
    modules={props.branchInfo.branch.questions}
    parentType="branch"
    parentEvent={props.parentEvent}
  />
);

export default BranchModules;
