// @flow strict
import * as React from 'react';

import type {ReferralTrackingPayload} from 'src/types/referral-v2';
import type {Task} from 'src/types/data-export-report';
import type {ToastProps} from '@spaced-out/ui-design-system/lib/components/Toast/Toast';

import {useDispatch, useSelector} from 'react-redux';

import generateId from 'src/utils/id';
import {TIMESHEET_CSV_ROWS} from 'src/utils/referral-v2.js';
import {displayDateTime} from 'src/utils/date-time-2';

import {
  uploadTimeSheetFile,
  startPolling,
} from 'src/action-creators/referral-v2/referral-tracking.js';

import {
  Modal,
  ModalHeader,
  ModalFooter,
  Button,
  SubTitleLarge,
  Tooltip,
  Toast,
  ToastTitle,
  ToastBody,
} from '@spaced-out/ui-design-system/lib/components/index';

import TimeSheet from './time-sheet.jsx';
import css from 'src/components/referral-v2/timesheet/timesheet.css';


const TimeSheetModal = ({
  filtersPayload,
}: {
  filtersPayload: ReferralTrackingPayload,
}): React.Node => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [showFieldMapping, setShowFieldMapping] = React.useState(false);
  const [isActionDisabled, setIsActionDisabled] = React.useState(true);
  const [isValidate, setIsValidate] = React.useState(false);

  const [fieldMapping, setFieldMapping] = React.useState<{[string]: string}>(
    {},
  );

  const [file, setFile] = React.useState<?File>(null);

  const [csvFileData, setCsvFileData] = React.useState<{[string]: string}[]>(
    [],
  );
  const [errorField, setErrorField] = React.useState([]);

  const dispatch = useDispatch();

  const handleReset = (resetFile?: boolean) => {
    setShowFieldMapping(false);
    setCsvFileData(resetFile ? [] : csvFileData);
    setFieldMapping({});
    setErrorField([]);
    setIsValidate(false);
    setIsActionDisabled(resetFile ? true : false);
    setFile(resetFile ? null : file);
  };

  const onToogleModal = () => {
    if (showFieldMapping) {
      handleReset(false);
      return;
    }
    handleReset(true);
    setIsModalOpen(!isModalOpen);
  };

  const validateCsvFile = () => {
    if (fieldMapping) {
      setIsValidate(true);
      const missingColumns = new Set([]);
      csvFileData.forEach((fields) => {
        const valuesCount = Object.values(fields).filter(
          (value) => value,
        ).length;
        if (valuesCount > 0) {
          Object.values(fields).forEach((value, idx) => {
            if (!value || value === '') {
              const fieldName = Object.keys(fields)[idx];
              missingColumns.add(fieldName);
            }
          });
        }
      });
      if (missingColumns.size > 0) {
        setErrorField(Array.from(missingColumns));
      }
    }
  };

  const isFieldMappedError = () => {
    let error = false;
    TIMESHEET_CSV_ROWS.forEach((row) =>
      errorField.some((field) => {
        const fieldMapped = fieldMapping[row.label];
        if (field === fieldMapped) {
          error = true;
        }
      }),
    );
    return error;
  };

  const getDefaultTimeSheet = () => {
    const defaultFieldMapping = {};
    TIMESHEET_CSV_ROWS.map((row) =>
      csvFileData.forEach((data) => {
        if (Object.keys(data).includes(row.value)) {
          const fieldName =
            Object.keys(data).find((key) => key === row.value) || '';
          defaultFieldMapping[row.label] = fieldName;
        }
      }),
    );
    return defaultFieldMapping;
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('field_mapping', JSON.stringify(fieldMapping));
    if (file) {
      formData.append('file', file);
    }
    // $FlowFixMe[incompatible-call] the type of formData class is object bydefault
    dispatch(uploadTimeSheetFile(formData, loggedInUserId, filtersPayload));
    setIsModalOpen(false);
  };

  const handleAction = () => {
    if (!showFieldMapping) {
      setShowFieldMapping(true);
      setIsActionDisabled(true);
      setFieldMapping(getDefaultTimeSheet());
      return;
    }
    if (!isValidate && showFieldMapping) {
      validateCsvFile();
      return;
    }
    if (isValidate && !isFieldMappedError()) {
      handleSubmit();
    }
  };

  const timeSheetData: ?Task = useSelector(
    (state) => state?.referralV2.time_sheet_data,
  );
  const loggedInUserId = useSelector((state) => state.accounts.authedUserId);

  const isProcessing = ['INIT', 'PROCESSING'].includes(timeSheetData?.status);

  React.useEffect(() => {
    dispatch(startPolling(generateId().toString(), loggedInUserId));
  }, []);

  return (
    <div>
      {timeSheetData || isProcessing ? (
        <Tooltip
          title={
            isProcessing
              ? `The timesheet has been uploaded by ${
                  timeSheetData?.initiatedBy || ''
                }. Processing is underway`
              : `Last synced on ${displayDateTime(
                  timeSheetData?.timeUpdated,
                )} by ${timeSheetData?.initiatedBy || ''}`
          }
          titleMaxLines={3}
          placement="top"
        >
          <div>
            <Button
              iconLeftName={isProcessing ? 'loader' : ''}
              type="secondary"
              classNames={{wrapper: css.timesheetButton}}
              onClick={onToogleModal}
              disabled={isProcessing}
            >
              {isProcessing ? 'Processing' : 'Sync Timesheet'}
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button
          type="secondary"
          onClick={onToogleModal}
          disabled={isProcessing}
        >
          {'Sync Timesheet'}
        </Button>
      )}
      <Modal isOpen={isModalOpen} initialFocus={2}>
        <ModalHeader hideCloseBtn>
          <SubTitleLarge>
            {showFieldMapping ? 'Field Mapping' : 'Sync Timesheet'}
          </SubTitleLarge>
        </ModalHeader>
        <TimeSheet
          setIsActionDisabled={setIsActionDisabled}
          showFieldMapping={showFieldMapping}
          csvFileData={csvFileData}
          setCsvFileData={setCsvFileData}
          fieldMapping={fieldMapping}
          setFieldMapping={setFieldMapping}
          errorField={errorField}
          isValidate={isValidate}
          file={file}
          setFile={setFile}
          isFieldMappedError={isFieldMappedError}
          handleReset={handleReset}
        />
        <ModalFooter>
          <Button onClick={onToogleModal} type="ghost">
            {showFieldMapping ? 'Back' : 'Cancel'}
          </Button>
          <Button
            disabled={isActionDisabled || (isValidate && isFieldMappedError())}
            onClick={handleAction}
            type="primary"
          >
            {!showFieldMapping ? 'Sync' : isValidate ? 'Save' : 'Map Field'}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TimeSheetModal;

export const successMessage = ({
  time,
}: {
  time: string,
}): React$Element<(props: ToastProps) => React.Node> => {
  return (
    <Toast initialFocus={0} semantic="success" time={`Today ${time}`}>
      <ToastTitle semantic="primary">Timesheet Synced</ToastTitle>
      <ToastBody>Your CSV file has been synced successfully.</ToastBody>
    </Toast>
  );
};
