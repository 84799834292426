// @flow strict-local

import type {State} from 'src/reducers';
import type {
  Category,
  Categories,
  CategoriesFilter,
  CategoryChannel,
  UserContentSubscriptionsExternal,
} from 'src/types/content-subscription';
import type {ApiBrandingSettings} from 'src/types/events';

import {createSelector} from 'reselect';

import {
  CATEGORY_STATUS_ACTIVE,
  CATEGORY_TYPE_TRANSCATIONAL,
  CATEGORY_TYPE_MARKETING,
  CATEGORY_CHANNEL_SMS,
  CATEGORY_CHANNEL_EMAIL,
} from 'src/utils/content-subscription';


export const selectCategories = (state: State): Categories =>
  state.contentSubscription.categories;

export const getCategoryByType = (
  categories: Categories,
  type: string,
): Categories => categories.filter((cat) => cat.type === type);

export const getCategoryByChannel = (
  categories: Categories,
  channel: CategoryChannel,
): Categories => categories.filter((cat) => cat.channel === channel);

export const getDefaultCategoryByType = (
  categories: Categories,
  type: string,
): Category => categories.filter((cat) => cat.type === type && cat.default)[0];

export const selectCategoryDetails =
  (categoryId: string): ((state: State) => Categories) =>
  (state: State): Categories =>
    state.contentSubscription.categoryDetails[categoryId];

export const selectFilteredCategories = (state: State): Categories =>
  state.contentSubscription.filteredCategories;

export const selectCategoriesFilter = (state: State): CategoriesFilter =>
  state.contentSubscription.filters;

export const selectUserContentSubscriptionExternal = (
  state: State,
  entityId: string,
): UserContentSubscriptionsExternal =>
  state.contentSubscription.userContentSubscriptionsExternal[entityId];

export const selectActiveCategories: (state: State) => Categories =
  createSelector(selectCategories, (categories): Categories =>
    categories.filter((cat) => cat.status === CATEGORY_STATUS_ACTIVE),
  );

export const selectCategoryById: (
  state: State,
  categoryId: ?number,
  // $FlowFixMe[incompatible-type]
) => Category = createSelector(
  selectCategories,
  (_, categoryId): ?number => categoryId,
  (categories, categoryId): ?Category =>
    categories.find((cat) => cat.id === categoryId),
);

export const selectActiveTransactionalCategories: (state: State) => Categories =
  createSelector(selectActiveCategories, (activeCategories: Categories) =>
    getCategoryByType(activeCategories, CATEGORY_TYPE_TRANSCATIONAL),
  );

export const selectActiveMarketingCategories: (state: State) => Categories =
  createSelector(selectActiveCategories, (activeCategories: Categories) =>
    getCategoryByType(activeCategories, CATEGORY_TYPE_MARKETING),
  );

export const selectActiveSmsCategories: (state: State) => Categories =
  createSelector(
    selectActiveMarketingCategories,
    (activeCategories: Categories) =>
      getCategoryByChannel(activeCategories, CATEGORY_CHANNEL_SMS),
  );

export const selectActiveEmailCategories: (state: State) => Categories =
  createSelector(
    selectActiveMarketingCategories,
    (activeCategories: Categories) =>
      getCategoryByChannel(activeCategories, CATEGORY_CHANNEL_EMAIL),
  );

export const selectDefaultActiveEmailMarketingCategory: (
  state: State,
) => Category = createSelector(
  selectActiveEmailCategories,
  (activeCategories: Categories) =>
    getDefaultCategoryByType(activeCategories, CATEGORY_TYPE_MARKETING),
);

export const selectDefaultActiveSmsCategory: (state: State) => Category =
  createSelector(
    selectActiveSmsCategories,
    (activeCategories: Categories) =>
      activeCategories.filter((cat) => cat.default)[0],
  );

export const selectEmailDebugMode = (state: State): boolean =>
  state.contentSubscription.isDebug;

export const selectBrandingSettings = (state: State): ApiBrandingSettings =>
  state.contentSubscription.brandingSettings;
