// @flow
import type {EnvState} from 'src/action-creators/env';
import type {ReduxStore} from 'src/reducers';

import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

import reducers from 'src/reducers';
import IndexStore from 'src/stores/index';
import {isDevOrStage} from 'src/utils/env';
import logger from 'src/utils/logger';
import {BrowserHistory} from 'src/rerouter';
import {AnalyticsService} from 'src/analytics';
import {Zipy} from 'src/zipy';
import {bootstrapSentry} from './bootstrap-sentry';


export {bootstrapSentry};

export function bootstrapStores(browserHistory?: typeof BrowserHistory): {
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  reduxStore: ReduxStore,
  env: EnvState,
} {
  const middleware = [thunk];

  const store = new IndexStore();
  store.initialize(window.data, browserHistory);

  const {env} = window.reduxData;

  const composeEnhancers = isDevOrStage({env})
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;
  const reduxStore = createStore(
    reducers,
    window.reduxData,
    composeEnhancers(applyMiddleware(...middleware)),
  );

  const initialiseLib = (ENV: string) => {
    try {
      AnalyticsService.reset();
      AnalyticsService.init(ENV);
      if (reduxStore.getState().productFlags.releaseFlags.zipyEnabled) {
        Zipy.init(ENV);
      }
    } catch (error) {}
  };

  // Stores have been hydrated. Clean up `window` object
  delete window.data;
  delete window.reduxData;

  // NOTE (kyle): as we move more code into the redux store and out of the sense store
  // some old-style actions will need to access redux data.
  store.reduxStore = reduxStore;

  store.request.setHostname(window.location.hostname);

  // Configure sentry.io using env redux data.
  // This makes sentry available in _all_ client contexts but allows us to filter
  // out dev errors (or aggregate them)
  if (process.env.NODE_ENV === 'production') {
    bootstrapSentry(env);
    initialiseLib('PRODUCTION');
  } else {
    initialiseLib('DEVELOPMENT');
  }

  logger.updateContext({
    hostname: env.hostname,
    agency: env.hostname.split('.')[0],
  });

  const agencyid = reduxStore.getState().agency.agency.id;
  if (agencyid) {
    logger.updateContext({
      agencyid,
    });
  }

  return {store, reduxStore, env};
}
