// @flow strict
// $FlowFixMe[nonstrict-import]
import type {Dispatch, GetState, ThunkAction} from 'src/reducers';
import type {
  BonusChangeLog,
  GenericError,
  ReferralJobData,
  SuccessApiResponse,
  ReferralAttributes,
  ReferralAttributesValues,
  ProgramJobsPayload,
  LinkJobsToProgramsPayload,
  LinkJobsPrograms,
  FetchProgramsForJobsPayload,
  JobsPayload,
} from 'src/types/referral-v2';
// $FlowFixMe[nonstrict-import]
import {ApiError} from 'src/utils/redux-api-v2';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api.js';
import {DEFAULT_PAGE_NUMBER} from 'src/utils/referral-v2.js';
//$FlowFixMe[nonstrict-import]
import {
  //$FlowFixMe[nonstrict-import]
  popModal,
  //$FlowFixMe[nonstrict-import]
  showApiError,
} from 'src/action-creators/modal';


export const DEFAULT_ERROR: GenericError = {
  field_errors: {},
  non_field_errors: [
    {
      type: 'default',
      message: 'Some Error occoured',
    },
  ],
};

export const RECEIVE_JOBS_LOADING = 'referral/receiveJobsLoading';
export const RECEIVE_JOBS = 'referral/receiveJobs';
export const RECEIVE_JOBS_ERROR = 'referral/receiveJobsError';

export const RECEIVE_ATTRIBUTES_LOADING = 'referral/receiveAttributesLoading';
export const RECEIVE_ATTRIBUTES = 'referral/receiveAttributes';
export const RECEIVE_ATTRIBUTES_ERROR = 'referral/receiveAttributesError';

export const RECEIVE_CONFIGURED_ATTRIBUTES_LOADING =
  'referral/receiveConfiguredAttributesLoading';
export const RECEIVE_CONFIGURED_ATTRIBUTES =
  'referral/receiveConfiguredAttributes';
export const RECEIVE_CONFIGURED_ATTRIBUTES_ERROR =
  'referral/receiveConfiguredAttributesError';

export const RECEIVE_ATTRIBUTES_VALUES_LOADING =
  'referral/receiveAttributesValuesLoading';
export const RECEIVE_ATTRIBUTES_VALUES = 'referral/receiveAttributesValues';
export const RECEIVE_ATTRIBUTES_VALUES_ERROR =
  'referral/receiveAttributesValuesError';

export const RECEIVE_FILTER_ATTRIBUTES_VALUES_LOADING =
  'referral/receiveFilterAttributesValuesLoading';
export const RECEIVE_FILTER_ATTRIBUTES_VALUES =
  'referral/receiveFilterAttributesValues';
export const RECEIVE_FILTER_ATTRIBUTES_VALUES_ERROR =
  'referral/receiveFilterAttributesValuesError';

export const RECEIVE_BONUS_CHANGE_LOG_LOADING =
  'referral/receiveBonusChangeLogLoading';
export const RECEIVE_BONUS_CHANGE_LOG = 'referral/receiveBonusChangeLog';
export const RECEIVE_BONUS_CHANGE_LOG_ERROR =
  'referral/receiveBonusChangeLogError';

export const REFERRAL_LINK_JOBS_PROGRAMS = 'referral/link-jobs-programs';
export const REFERRAL_LINK_JOBS_PROGRAMS_ERROR =
  'referral/link-jobs-programs-error';
export const FETCH_PROGRAMS_FOR_JOBS = 'referral/fetch-programs-for-jobs';
export const FETCH_PROGRAMS_FOR_JOBS_ERROR =
  'referral/fetch-programs-for-jobs-error';

export const RECEIVE_SEARCHED_ATTRIBUTES_VALUES =
  'referral/searched-attributes-values';

type LinkJobsToProgramsAction = {
  type: typeof REFERRAL_LINK_JOBS_PROGRAMS,
  payload: SuccessApiResponse,
};

type LinkJobsToProgramsErrorAction = {
  type: typeof REFERRAL_LINK_JOBS_PROGRAMS_ERROR,
  payload: ?GenericError,
};

type FetchJobsForPrograms = {
  type: typeof FETCH_PROGRAMS_FOR_JOBS,
  payload: LinkJobsPrograms[],
};

type ReceiveJobsLoadingAction = {
  type: typeof RECEIVE_JOBS_LOADING,
};

type ReceiveJobsAction = {
  type: typeof RECEIVE_JOBS,
  payload: ?(ReferralJobData[]),
};

type ReceiveJobsErrorAction = {
  type: typeof RECEIVE_JOBS_ERROR,
  payload: ?GenericError,
};

// Referral Bonus and validity start here

type ReceiveAttributesLoadingAction = {
  type: typeof RECEIVE_ATTRIBUTES_LOADING,
};

type ReceiveAttributesAction = {
  type: typeof RECEIVE_ATTRIBUTES,
  payload: ?(ReferralAttributes[]),
};

type ReceiveAttributesErrorAction = {
  type: typeof RECEIVE_ATTRIBUTES_ERROR,
  payload: ?GenericError,
};

type ReceiveConfiguredAttributesLoadingAction = {
  type: typeof RECEIVE_CONFIGURED_ATTRIBUTES_LOADING,
};

type ReceiveConfiguredAttributesAction = {
  type: typeof RECEIVE_CONFIGURED_ATTRIBUTES,
  payload: ReferralAttributes[],
};

type ReceiveConfiguredAttributesErrorAction = {
  type: typeof RECEIVE_CONFIGURED_ATTRIBUTES_ERROR,
  payload: ?GenericError,
};

type ReceiveAttributesValuesLoadingAction = {
  type: typeof RECEIVE_ATTRIBUTES_VALUES_LOADING,
};

type ReceiveAttributesValuesAction = {
  type: typeof RECEIVE_ATTRIBUTES_VALUES,
  payload: ReferralAttributesValues[],
};

type ReceiveAttributesValuesErrorAction = {
  type: typeof RECEIVE_ATTRIBUTES_VALUES_ERROR,
  payload: ?GenericError,
};

type ReceiveFilterAttributesValuesLoadingAction = {
  type: typeof RECEIVE_FILTER_ATTRIBUTES_VALUES_LOADING,
};

type ReceiveFilterAttributesValuesAction = {
  type: typeof RECEIVE_FILTER_ATTRIBUTES_VALUES,
  payload: ReferralAttributesValues[],
  isSearchQuery?: boolean,
};

type ReceiveSearchedAttributesValuesAction = {
  type: typeof RECEIVE_SEARCHED_ATTRIBUTES_VALUES,
  payload: ReferralAttributesValues,
};

type ReceiveFilterAttributesValuesErrorAction = {
  type: typeof RECEIVE_FILTER_ATTRIBUTES_VALUES_ERROR,
  payload: ?GenericError,
};

type ReceiveBonusChangeLogLoadingAction = {
  type: typeof RECEIVE_BONUS_CHANGE_LOG_LOADING,
};

type ReceiveBonusChangeLogAction = {
  type: typeof RECEIVE_BONUS_CHANGE_LOG,
  payload: BonusChangeLog[],
};

type ReceiveBonusChangeLogErrorAction = {
  type: typeof RECEIVE_BONUS_CHANGE_LOG_ERROR,
  payload: ?GenericError,
};

export type ReferralJobsActions =
  | ReceiveJobsLoadingAction
  | ReceiveJobsAction
  | ReceiveJobsErrorAction
  | ReceiveAttributesLoadingAction
  | ReceiveAttributesAction
  | ReceiveAttributesErrorAction
  | ReceiveConfiguredAttributesLoadingAction
  | ReceiveConfiguredAttributesAction
  | ReceiveConfiguredAttributesErrorAction
  | ReceiveAttributesValuesLoadingAction
  | ReceiveAttributesValuesAction
  | ReceiveAttributesValuesErrorAction
  | ReceiveFilterAttributesValuesLoadingAction
  | ReceiveFilterAttributesValuesAction
  | ReceiveSearchedAttributesValuesAction
  | ReceiveFilterAttributesValuesErrorAction
  | ReceiveBonusChangeLogLoadingAction
  | ReceiveBonusChangeLogAction
  | ReceiveBonusChangeLogErrorAction
  | LinkJobsToProgramsAction
  | LinkJobsToProgramsErrorAction
  | FetchJobsForPrograms;

export const receiveJobsLoading = (): ReceiveJobsLoadingAction => ({
  type: RECEIVE_JOBS_LOADING,
});

export const receiveJobs = (
  response: ?(ReferralJobData[]),
): ReceiveJobsAction => ({
  type: RECEIVE_JOBS,
  payload: response,
});

export const receiveJobsError = (
  payload: ?GenericError,
): ReceiveJobsErrorAction => ({
  type: RECEIVE_JOBS_ERROR,
  payload,
});

export const receiveAttributesLoading = (): ReceiveAttributesLoadingAction => ({
  type: RECEIVE_ATTRIBUTES_LOADING,
});

export const receiveAttributes = (
  response: ?(ReferralAttributes[]),
): ReceiveAttributesAction => ({
  type: RECEIVE_ATTRIBUTES,
  payload: response,
});

export const receiveAttributesError = (
  payload: ?GenericError,
): ReceiveAttributesErrorAction => ({
  type: RECEIVE_ATTRIBUTES_ERROR,
  payload,
});

export const receiveConfiguredAttributesLoading =
  (): ReceiveConfiguredAttributesLoadingAction => ({
    type: RECEIVE_CONFIGURED_ATTRIBUTES_LOADING,
  });

export const receiveConfiguredAttributes = (
  response: ReferralAttributes[],
): ReceiveConfiguredAttributesAction => ({
  type: RECEIVE_CONFIGURED_ATTRIBUTES,
  payload: response,
});

export const receiveConfiguredAttributesError = (
  payload: ?GenericError,
): ReceiveConfiguredAttributesErrorAction => ({
  type: RECEIVE_CONFIGURED_ATTRIBUTES_ERROR,
  payload,
});

export const receiveAttributesValuesLoading =
  (): ReceiveAttributesValuesLoadingAction => ({
    type: RECEIVE_ATTRIBUTES_VALUES_LOADING,
  });

export const receiveAttributesValues = (
  response: ReferralAttributesValues[],
): ReceiveAttributesValuesAction => ({
  type: RECEIVE_ATTRIBUTES_VALUES,
  payload: response,
});

export const receiveAttributesValuesError = (
  payload: ?GenericError,
): ReceiveAttributesValuesErrorAction => ({
  type: RECEIVE_ATTRIBUTES_VALUES_ERROR,
  payload,
});

export const receiveFilterAttributesValuesLoading =
  (): ReceiveFilterAttributesValuesLoadingAction => ({
    type: RECEIVE_FILTER_ATTRIBUTES_VALUES_LOADING,
  });

export const receiveFilterAttributesValues = (
  response: ReferralAttributesValues[],
): ReceiveFilterAttributesValuesAction => ({
  type: RECEIVE_FILTER_ATTRIBUTES_VALUES,
  payload: response,
});

export const receiveFilterAttributesValuesError = (
  payload: ?GenericError,
): ReceiveFilterAttributesValuesErrorAction => ({
  type: RECEIVE_FILTER_ATTRIBUTES_VALUES_ERROR,
  payload,
});

export const receiveBonusChangeLogLoading =
  (): ReceiveBonusChangeLogLoadingAction => ({
    type: RECEIVE_BONUS_CHANGE_LOG_LOADING,
  });

export const receiveBonusChangeLog = (
  response: BonusChangeLog[],
): ReceiveBonusChangeLogAction => ({
  type: RECEIVE_BONUS_CHANGE_LOG,
  payload: response,
});

export const receiveBonusChangeLogError = (
  payload: ?GenericError,
): ReceiveBonusChangeLogErrorAction => ({
  type: RECEIVE_BONUS_CHANGE_LOG_ERROR,
  payload,
});

export const linkJobsToProgramsAction = (
  payload: SuccessApiResponse,
): LinkJobsToProgramsAction => ({
  type: REFERRAL_LINK_JOBS_PROGRAMS,
  payload,
});

export const linkJobsToProgramsErrorAction = (
  payload: ?GenericError,
): LinkJobsToProgramsErrorAction => ({
  type: REFERRAL_LINK_JOBS_PROGRAMS_ERROR,
  payload,
});

export const fetchProgramsForJobsAction = (
  payload: LinkJobsPrograms[],
): FetchJobsForPrograms => ({
  type: FETCH_PROGRAMS_FOR_JOBS,
  payload,
});

export const fetchProgramsForJobsErrorAction = (
  payload: ?GenericError,
): LinkJobsToProgramsErrorAction => ({
  type: REFERRAL_LINK_JOBS_PROGRAMS_ERROR,
  payload,
});

export const getSearchedAttributeValues = (
  payload: ReferralAttributesValues,
): ReceiveSearchedAttributesValuesAction => ({
  type: RECEIVE_SEARCHED_ATTRIBUTES_VALUES,
  payload,
});

export const getReferralJobs: (
  programId: string,
  ProgramJobsPayload: ?ProgramJobsPayload,
) => ThunkAction<void> = flow()(
  (programId: string, programJobsPayload: ?ProgramJobsPayload) =>
    (dispatch: Dispatch, getState: GetState) => {
      const isReferralsJobListEnabled =
        getState().productFlags.releaseFlags.referralsJobListEnabled;
      // $FlowIssue object values
      dispatch(receiveJobsLoading());
      return dispatch(
        reduxApi.get(
          `referral/configure/${
            isReferralsJobListEnabled ? 'job-list/jobs' : 'jobs'
          }`,
          {
            program_id: programId,
            ...programJobsPayload,
          },
        ),
      ).then(
        (response: ReferralJobData) => {
          // $FlowIssue object valuesz
          dispatch(receiveJobs(response));
        },
        (error) => {
          // $FlowIssue object values
          dispatch(receiveJobsError(error?.response?.errors || DEFAULT_ERROR));
        },
      );
    },
);

export const getAttributes: (programId: string) => ThunkAction<void> = flow(
  key(
    (programId) => `referral/configure/job-attributes?program_id=${programId}`,
  ),
  fetching(),
)((programId) => (dispatch: Dispatch) => {
  dispatch(receiveAttributesLoading());

  return dispatch(
    reduxApi.get(`referral/configure/job-attributes?program_id=${programId}`),
  ).then(
    (response: Array<ReferralAttributes>) => {
      dispatch(receiveAttributes(response));
    },
    (error) => {
      dispatch(
        receiveAttributesError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const getConfiguredAttributes: (programId: string) => ThunkAction<void> =
  flow(
    key(
      (programId) =>
        `referral/configure/selected-job-attributes?program_id=${programId}`,
    ),
    fetching(),
  )((programId) => (dispatch: Dispatch) => {
    dispatch(receiveConfiguredAttributesLoading());

    return dispatch(
      reduxApi.get(
        `referral/configure/selected-job-attributes?program_id=${programId}`,
      ),
    ).then(
      (response: Array<ReferralAttributes>) => {
        dispatch(receiveConfiguredAttributes(response));
      },
      (error) => {
        dispatch(
          receiveConfiguredAttributesError(
            error?.response?.errors || DEFAULT_ERROR,
          ),
        );
      },
    );
  });

export const getAttributesValues =
  (payload: {program_id: string}): ThunkAction<mixed> =>
  (dispatch: Dispatch) => {
    dispatch(receiveAttributesValuesLoading());
    return dispatch(
      reduxApi.get(
        `referral/configure/job-attribute-values?program_id=${payload.program_id}`,
      ),
    ).then(
      (response: Array<ReferralAttributesValues>) => {
        dispatch(receiveAttributesValues(response));
      },
      (error) => {
        dispatch(
          receiveAttributesValuesError(
            error?.response?.errors || DEFAULT_ERROR,
          ),
        );
      },
    );
  };

export const getFilterAttributesValues: (
  programId: string,
) => ThunkAction<void> = flow(
  key(
    (programId) =>
      `referral/configure/selected-job-attribute-values?program_id=${programId}`,
  ),
  fetching(),
)((programId) => (dispatch: Dispatch) => {
  dispatch(receiveFilterAttributesValuesLoading());
  return dispatch(
    reduxApi.get(
      `referral/configure/selected-job-attribute-values?program_id=${programId}`,
    ),
  ).then(
    (response: Array<ReferralAttributesValues>) => {
      dispatch(receiveFilterAttributesValues(response));
    },
    (error) => {
      dispatch(
        receiveFilterAttributesValuesError(
          error?.response?.errors || DEFAULT_ERROR,
        ),
      );
    },
  );
});

export const getBonusChangeLog: () => ThunkAction<void> = flow(
  key(() => `referral/changelog/bonus`),
  fetching(),
)(() => (dispatch: Dispatch) => {
  dispatch(receiveBonusChangeLogLoading());
  return dispatch(reduxApi.get(`referral/changelog/bonus`)).then(
    (response: Array<BonusChangeLog>) => {
      dispatch(receiveBonusChangeLog(response));
    },
    (error) => {
      dispatch(
        receiveBonusChangeLogError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const fetchProgramsForJobs =
  (payload: FetchProgramsForJobsPayload): ThunkAction<mixed> =>
  (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.post('referral/programs/linked-jobs', payload),
    ).then(
      (response: LinkJobsPrograms[]) => {
        dispatch(fetchProgramsForJobsAction(response));
      },
      (error) => {
        dispatch(
          fetchProgramsForJobsErrorAction(
            error?.response?.errors || DEFAULT_ERROR,
          ),
        );
      },
    );
  };

export const linkJobsToPrograms =
  (
    payload: LinkJobsToProgramsPayload,
    programId: string,
    pageNumber: number,
  ): ThunkAction<mixed> =>
  (dispatch: Dispatch) => {
    const url = `referral/programs/jobs`;
    return dispatch(
      reduxApi.post(url, {...payload, program_id: programId}),
    ).then(
      (response: SuccessApiResponse) => {
        dispatch(linkJobsToProgramsAction(response));
        dispatch(popModal());
        dispatch(getReferralJobs(programId, {limit: 10, offset: pageNumber}));
      },
      (error) => {
        dispatch(
          linkJobsToProgramsErrorAction(
            error?.response?.errors || DEFAULT_ERROR,
          ),
        );
      },
    );
  };

export const updateJobData =
  ({
    payload,
    programId,
    jobFilters,
  }: {
    payload: JobsPayload,
    programId: string,
    jobFilters: ProgramJobsPayload,
  }): ThunkAction<mixed> =>
  (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.post(`referral/configure/bulk-edit/jobs`, {
        program_id: programId,
        data: payload,
      }),
    )
      .then(() => {
        return dispatch(getReferralJobs(programId, jobFilters));
      })
      .catch((error) => {
        dispatch(showApiError(error, error?.response?.message));
      });
  };

export const getLastUpdatedFilters =
  (programFilterId: string): ThunkAction<mixed> =>
  (dispatch: Dispatch) =>
    dispatch(
      reduxApi.get(`referral/configure/bonus/lastupdated`, {
        program_id: programFilterId,
      }),
    );

export const applyFilters =
  (payload: {
    program_id: string,
    attribute_values: ?Array<ReferralAttributesValues>,
  }): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    const {program_id: programId} = payload;
    try {
      await dispatch(
        reduxApi.put('referral/configure/job-attribute-values', payload),
      );
      await dispatch(getFilterAttributesValues(programId));
      dispatch(
        getReferralJobs(programId, {
          limit: 10,
          offset: DEFAULT_PAGE_NUMBER,
        }),
      );
      dispatch(popModal());
    } catch (error) {
      if (error instanceof ApiError) {
        dispatch(showApiError(error, error?.response?.message));
      }
    }
  };

export const getSearchedAttributesValues =
  (payload: {
    search_query?: {[string]: string},
    program_id: string,
    selected_attributes: ReferralAttributesValues[],
  }): ThunkAction<mixed> =>
  (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.post(
        'referral/configure/selected-job-attributes/search',
        payload,
      ),
    ).then(
      (response: ReferralAttributesValues) => {
        dispatch(getSearchedAttributeValues(response));
      },
      (error) => {
        if (error instanceof ApiError) {
          dispatch(showApiError(error, error?.response?.message));
        }
      },
    );
  };
