//@flow strict
import * as React from 'react';
import classify from '@spaced-out/ui-design-system/lib/utils/classify';
import {ButtonTextMedium} from '@spaced-out/ui-design-system/lib/components/Text';

import css from './message-loader.css';


type MessageLoaderProps = {
  messages: Array<string>,
};

const MessageLoader = ({messages}: MessageLoaderProps): React.Node => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    const updateIndex = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length);
    };

    const timerId = setTimeout(updateIndex, 6000);

    return () => clearTimeout(timerId);
  }, [messages, currentIndex]);

  const splitMessage = (message) => {
    const messageArr = message.split('...');
    return messageArr;
  };

  return (
    <div className={css.container}>
      <img
        src="/images/loader/Magnifying_loader_animation_80px.gif"
        alt="Loader"
      />
      {messages.map((message, index) => {
        const [firstLine, secondLine] = splitMessage(message);
        return (
          <ButtonTextMedium
            key={index}
            className={classify(css.messageContainer, {
              [css.messageVisible]: index === currentIndex,
            })}
          >
            {`${firstLine}...`}
            <br />
            {secondLine}
          </ButtonTextMedium>
        );
      })}
    </div>
  );
};

export default MessageLoader;
