// @flow strict

import * as React from 'react';
import {Smallest} from 'src/designSystem2021Components/text-v2.jsx';
import isEmpty from 'lodash/isEmpty';
import {useSelector, useDispatch} from 'react-redux';
import css from './field-selection-table.css';

//$FlowFixMe[untyped-import]
import {classify} from 'src/utils';
import StandardEntityTypeSection from './standard-entity-type-section.jsx';
import {
  fetchStandardEntity,
  fetchStandardEntityDesignations,
} from 'src/action-creators/field-mapping';
import {
  selectStandardEntity,
  selectLocalStandardEntityDesignation,
} from 'src/selectors/field-mapping';
import type {
  StandardEntityAttributes,
  StandardEntityDesignationResponse,
} from 'src/types/field-mapping';


type FieldSelectionTableProps = {
  setFinalError: (error: boolean) => void,
  containerPage: 'modal' | 'settings',
};

const FieldSelectionTable = ({
  setFinalError,
  containerPage,
}: FieldSelectionTableProps): React.Element<'div'> => {
  const dispatch = useDispatch();

  const standardEntityAttributes: ?StandardEntityAttributes =
    useSelector(selectStandardEntity);
  const localStandardEntityDesignations: ?StandardEntityDesignationResponse =
    useSelector(selectLocalStandardEntityDesignation);

  React.useEffect(() => {
    dispatch(fetchStandardEntity());
    dispatch(fetchStandardEntityDesignations());
  }, []);

  const sectionLevelErrorMap = React.useRef([]);
  const updateSectionLevelErrorMap = (index, error) => {
    sectionLevelErrorMap.current[index] = error;
    const finalError = sectionLevelErrorMap.current.reduce(
      (previousValue, currentValue) => previousValue || currentValue,
      false,
    );
    setFinalError(finalError);
  };

  return (
    <div className={css.fieldSelectionTableContainer}>
      <div className={css.tableHeader}>
        <Smallest className={css.headerItem}>{'Sense Attribute'}</Smallest>
        <Smallest className={css.headerItem}>{'ATS Field'}</Smallest>
      </div>
      <div
        className={classify({
          [css.tableContentScroll]: containerPage === 'modal',
          [css.tableContentNoScroll]: containerPage === 'settings',
        })}
      >
        {!isEmpty(standardEntityAttributes) &&
          localStandardEntityDesignations &&
          localStandardEntityDesignations.map(
            (standardEntityDesignationObj, index) => (
              <StandardEntityTypeSection
                standardEntityType={
                  standardEntityDesignationObj.standard_entity_type
                }
                designations={standardEntityDesignationObj.designations}
                entityAttributesList={
                  standardEntityAttributes?.[
                    standardEntityDesignationObj.standard_entity_type
                  ]
                }
                entityIndex={index}
                updateSectionLevelError={updateSectionLevelErrorMap}
                key={standardEntityDesignationObj.standard_entity_type}
              />
            ),
          )}
      </div>
    </div>
  );
};

export default FieldSelectionTable;
