// @noflow

import typeof IndexStore from 'src/stores/index';
import type {CompositeDecorator, RawDraftContentState} from 'draft-js';
import type {DynamicLabels} from 'src/components/lib/markdown-editor';

import React, {Component} from 'react';
import {connect, useSelector} from 'react-redux';
import {classify} from 'src/utils/classify';

import _get from 'lodash/get';
import flow from 'lodash/flow';
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from 'draft-js';

import {
  isDigest,
  isSmsSurveyEvent,
  getSenseAiQuickTags,
} from 'src/utils/events';
import {selectEntityTypeBySenseName} from 'src/selectors/ats-entities';
import {pushModal} from 'src/action-creators/modal';

import Editor, {buildDecorator} from 'src/components/lib/markdown-editor/index';
import JobMatchNotificationContainer from './job-match-notification.jsx';
import {EngageEmailSenseAiWrapper} from 'src/components/workflow/event/sense-ai-wrapper.jsx';

import mapProps from 'src/flux/map-props.jsx';
import {uploadMedia} from 'src/actions/media';
import {updateModule} from 'src/actions/event-creation';
import {useJobMatchEnabledForEngage} from 'src/hooks/useAgencyConfig';

import {getNewEditorStateAfterReplacingPlaceholder} from './utils';

import css from './module.css';


const mapper = (props) => ({
  ...props,
  errors: _get(props.error, 'errors'),
});
type NotificationProps = {
  fluxStore: IndexStore,
  moduleData: {
    text: string,
    rte_json: RawDraftContentState,
    draftEditorState?: EditorState,
  },
  canEdit: boolean,
  dynamicLabels: DynamicLabels,
  internalDynamicLabels: DynamicLabels,
  errors?: string[],
  onUploadImage?: () => {},
  multiEntityEnabled: boolean,
};
class NotificationContainer extends Component<NotificationProps> {
  decorator: CompositeDecorator;
  labels: string[];
  initialEditorState: EditorState;
  handleEditorChange: (value: EditorState) => void;
  handleImageUpload: (files: FileList) => any; // should be void...

  constructor(props: NotificationProps) {
    super(props);

    this.decorator = this.getDecorator();

    this.initialEditorState = this.deserializeRTEContents(props);

    this.handleEditorChange = this._handleEditorChange.bind(this);
    this.handleImageUpload = this._handleImageUpload.bind(this);
  }

  getDecorator = () =>
    buildDecorator({
      dynamicLabels: this.props.dynamicLabels,
      multiEntityEnabled: this.props.multiEntityEnabled,
      onPlaceholderReplace: () => {
        const reduxDispatch = this.props.fluxStore.reduxStore.dispatch;
        reduxDispatch(
          pushModal({
            type: 'VARIABLE_PICKER',
            insertResult: this.handlePlaceholderInsert,
            labels: this.props.dynamicLabels,
            baseEntityType: this.props.mainEntityType,
          }),
        );
      },
    });

  componentDidUpdate(prevProps) {
    // Handle decorator changes here because demoComponent relies on componentDidMount
    // but this is generally how to handle dealing with updating the editor as a result
    // of props changes

    if (prevProps.dynamicLabels !== this.props.dynamicLabels) {
      // Update the decorators to include new labels
      this.decorator = this.getDecorator();
      this.updateEditorDecorator();
    }
  }

  updateEditorDecorator() {
    const {draftEditorState} = this.props.moduleData;
    if (draftEditorState) {
      // update the current editorstate's decorators to include {survey_link} support
      const editorWithNewDecorator = EditorState.set(draftEditorState, {
        decorator: this.decorator,
      });
      this.handleEditorChange(editorWithNewDecorator);
    } else {
      // if no editor state is available, haven't called handelEditorChange once
      // so initialize it with the correct decorator list
      this.initialEditorState = this.deserializeRTEContents(this.props);
    }
  }

  deserializeRTEContents = (props: NotificationProps) => {
    let content: ContentState;

    content = ContentState.createFromText('');

    if (props.moduleData.rte_json) {
      // if we get rte_json, it's either being actively updated
      // or it's _just_ been deserialized by the api_parser
      content = convertFromRaw(props.moduleData.rte_json); // no, we just deserialized content state
    }
    return EditorState.createWithContent(content, this.decorator);
  };

  render() {
    const {
      errors,
      canEdit,
      moduleData: {draftEditorState},
      dynamicLabels,
      multiEntityEnabled,
      internalDynamicLabels,
      parentEvent,
    } = this.props;
    const editorState = draftEditorState || this.initialEditorState;

    return (
      <NotificationEditor
        editorState={editorState}
        dynamicLabels={
          parentEvent && isDigest(parentEvent.eventType)
            ? internalDynamicLabels
            : dynamicLabels
        }
        onChange={this.handleEditorChange}
        onFileUpload={this.handleImageUpload}
        canEdit={canEdit}
        errors={errors}
        multiEntityEnabled={multiEntityEnabled}
      />
    );
  }

  _handleEditorChange(value: EditorState) {
    const json = convertToRaw(value.getCurrentContent());
    updateModule(this.props.fluxStore, {
      rte_json: json,
      draftEditorState: value,
    });
  }
  handlePlaceholderInsert = (dynamicLabel) => {
    const oldState = this.props.moduleData.draftEditorState;
    getNewEditorStateAfterReplacingPlaceholder(
      dynamicLabel,
      oldState,
      (value) => {
        this.handleEditorChange(value);
      },
    );
  };

  _handleImageUpload(files: FileList) {
    return uploadMedia(this.props.fluxStore, files[0]);
  }
}

type NotificationEditorProps = {
  editorState: EditorState,
  dynamicLabels: DynamicLabels,
  onChange: (value: EditorState) => void,
  onFileUpload: (files: FileList) => any,
  errors?: string[],
  canEdit?: boolean,
  multiEntityEnabled: boolean,
};
const _NotificationEditor = ({
  editorState,
  dynamicLabels,
  onChange,
  onFileUpload,
  errors,
  canEdit,
  multiEntityEnabled,
}: NotificationEditorProps) => (
  <div className={css.generalModule_content}>
    <div className={css.generalModule_message}>
      Use a message to welcome a contractor, or give them an intro to the
      company.
    </div>

    <label className={css.generalModule_questionLabel}>
      <div className={css.labelWithInlineSelector}>Text</div>
    </label>

    <Editor
      readOnly={!canEdit}
      dynamicLabels={dynamicLabels}
      onChange={onChange}
      value={editorState}
      placeholder="e.g. Good luck on your first day!"
      handleImageUpload={onFileUpload}
      showErrors={!!errors}
      multiEntityEnabled={multiEntityEnabled}
    />
  </div>
);

const NotificationEditor = _NotificationEditor;

const mapStateToProps = (state) => ({
  multiEntityEnabled: state.agency.config.multiEntityEnabled, // TODO: safely remove
});

const FeatureFlagWrapperV1 = (props) => {
  //SM-125 disable job-match notification for sms_survey event
  const renderNotificationModuleWithJobMatch =
    useJobMatchEnabledForEngage() && !isSmsSurveyEvent(props.parentEvent?.type);
  if (renderNotificationModuleWithJobMatch) {
    return <JobMatchNotificationContainer {...props} />;
  }
  return <NotificationContainer {...props} />;
};

const FeatureFlagWrapperV2 = (props) => {
  const {
    workflowId: journeyId,
    id: touchpointId,
    entityType,
  } = props.parentEvent;
  const renderNotificationModuleWithJobMatch =
    useJobMatchEnabledForEngage() && !isSmsSurveyEvent(props.parentEvent?.type);
  const leadAtsEntityType = useSelector((state) =>
    selectEntityTypeBySenseName(state, 'lead'),
  );
  const internalEmployeeEntityType = useSelector((state) =>
    selectEntityTypeBySenseName(state, 'internal_employee'),
  );

  const [count, setCount] = React.useState(0);
  const key = `remount-control-key-${count}`;
  const contentState = props.moduleData?.rte_json
    ? convertFromRaw(props.moduleData?.rte_json)
    : null;
  const initialSuggestion = contentState?.getPlainText() ?? '';
  return (
    <div
      className={classify(css.senseAiModuleContainerEmail, {
        [css.jobMatch]: renderNotificationModuleWithJobMatch === true,
      })}
    >
      <FeatureFlagWrapperV1 key={key} {...props} />
      <EngageEmailSenseAiWrapper
        initialSuggestion={initialSuggestion}
        defaultIntroOpen={
          initialSuggestion.length === 0 || initialSuggestion.length > 40
        }
        journeyId={journeyId}
        touchpointId={touchpointId}
        header="Auto-Generate Email"
        allTags={getSenseAiQuickTags(props.parentEvent, {
          isCandidateEntity: leadAtsEntityType === entityType,
          isInternalEmployee: internalEmployeeEntityType === entityType,
        })}
        onConfirm={(content) => {
          //we need to somehow create rtejson and update module here
          //the only way to make draftjs accept the new editor state is remount it
          const contentState = ContentState.createFromText(content);
          const editorState = EditorState.createWithContent(contentState);
          const json = convertToRaw(editorState.getCurrentContent());

          updateModule(props.fluxStore, {
            rte_json: json,
            draftEditorState: undefined,
          });
          //invalidate existing editor so that it starts fresh
          setCount((count) => count + 1);
          //also show toast
        }}
        type={renderNotificationModuleWithJobMatch ? 'email-jobmatch' : 'email'}
        showIntro={initialSuggestion === '' ? true : false}
      />
    </div>
  );
};

const FeatureFlagWrapper = (props) => {
  return <FeatureFlagWrapperV2 {...props} />;
};

export default flow(
  connect(mapStateToProps),
  mapProps(mapper),
)(FeatureFlagWrapper);
