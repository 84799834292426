// @flow strict

import type {
  AudienceListAction,
  ListFormSteps,
} from 'src/action-creators/audience-list.old';
import type {FilterRule} from 'src/types/audience-filter';
import type {AudienceListStatus} from 'src/types/lists';
import type {EntityType} from 'src/types/ats-entities';

import {
  UPDATE_STATE,
  RECEIVE_LIST,
  GOTO_STEP,
  RESET,
  SET_RULES,
  SET_ALL,
  RECEIVE_PUBLISHED_LIST_IDS,
  RECEIVE_UPDATED_LIST,
  RESET_JOURNEY_FLOW_FIELDS,
} from 'src/action-creators/audience-list.old';


export type AudienceListState = {
  id: ?string,
  step: ListFormSteps,
  name: string,
  entity_type: ?EntityType,
  rules: FilterRule[],
  associated_resources: {resource_type: string}[],
  status: ?AudienceListStatus,
  target: ?string,
  life_cycle_goal: ?string,
  business_goal: ?string,
  all: ?boolean,
  rule_creation_type: string,
};

const initialState = {
  id: null,
  step: 'details',
  name: '',
  entity_type: null,
  rules: [],
  associated_resources: [],
  status: null,
  target: null,
  life_cycle_goal: null,
  business_goal: null,
  all: false,
  rule_creation_type: '',
};

export default (
  state: AudienceListState = initialState,
  action: AudienceListAction,
): AudienceListState => {
  switch (action.type) {
    case RECEIVE_LIST: {
      const {
        id,
        status,
        rules,
        name,
        target,
        entity_type,
        life_cycle_goal,
        business_goal,
        all,
      } = action.payload;
      return {
        ...state,
        id,
        status,
        rules,
        name,
        target,
        life_cycle_goal,
        business_goal,
        entity_type,
        all,
      };
    }
    case UPDATE_STATE: {
      const updatePayload = action.payload;
      return {
        ...state,
        ...updatePayload,
      };
    }
    case GOTO_STEP: {
      return {
        ...state,
        step: action.payload,
      };
    }
    case RESET: {
      return {
        ...initialState,
      };
    }
    case SET_RULES: {
      return {
        ...state,
        all: false,
        rules: [...action.payload],
      };
    }
    case SET_ALL: {
      return {
        ...state,
        all: action.payload,
      };
    }
    case RECEIVE_PUBLISHED_LIST_IDS: {
      const publishedLists = action.payload;
      const status = publishedLists.includes(state.id)
        ? 'published'
        : state.status;

      return {
        ...state,
        status,
      };
    }
    case RECEIVE_UPDATED_LIST: {
      const {id, status, rules, name} = action.payload;
      return {
        ...state,
        id,
        status,
        rules,
        name,
      };
    }
    case RESET_JOURNEY_FLOW_FIELDS: {
      const {business_goal, entity_type, target, life_cycle_goal} = state;
      return {
        ...initialState,
        business_goal,
        entity_type,
        target,
        life_cycle_goal,
      };
    }
  }

  return state;
};
