// @flow strict

// $FlowFixMe[nonstrict-import]
import type {BeefreeDocument} from 'src/components/lib/beefree-editor';
import type {Brand} from 'src/types/brands';
import {DEFAULT_BEEFREE_LOGO_URL} from 'src/utils/automation-workflow';


export default function templateFactory({
  brandingSettings,
}: {
  brandingSettings: ?Brand,
}): BeefreeDocument {
  const agencyColor = brandingSettings?.color || '#0068A5';
  const agencyLogoUrl = brandingSettings?.logo || DEFAULT_BEEFREE_LOGO_URL;
  const buttonColor = brandingSettings?.button_color || agencyColor;

  const rows = [
    {
      columns: [
        {
          'grid-columns': 12,
          modules: [
            {
              descriptor: {
                computedStyle: {
                  class: 'center fixedwidth',
                  hideContentOnMobile: false,
                  width: 120,
                },
                image: {
                  alt: '',
                  href: '',
                  src: `${agencyLogoUrl}`,
                },
                style: {
                  'padding-bottom': '0px',
                  'padding-left': '0px',
                  'padding-right': '0px',
                  'padding-top': '0px',
                  width: '100%',
                },
              },
              locked: false,
              type: 'mailup-bee-newsletter-modules-image',
            },
          ],
          style: {
            'background-color': 'transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-top': '0px solid transparent',
            'padding-bottom': '25px',
            'padding-left': '0px',
            'padding-right': '0px',
            'padding-top': '25px',
          },
        },
      ],
      container: {
        style: {
          'background-color': `${agencyColor}`,
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat',
        },
      },
      content: {
        computedStyle: {
          hideContentOnDesktop: false,
          hideContentOnMobile: false,
          rowColStackOnMobile: true,
          rowReverseColStackOnMobile: false,
          verticalAlign: 'top',
        },
        style: {
          'background-color': 'transparent',
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat',
          'border-bottom': '0px solid transparent',
          'border-left': '0px solid transparent',
          'border-radius': '0px',
          'border-right': '0px solid transparent',
          'border-top': '0px solid transparent',
          color: '#000000',
          width: '600px',
        },
      },
      empty: false,
      locked: false,
      synced: false,
      type: 'one-column-empty',
    },
    {
      columns: [
        {
          'grid-columns': 12,
          modules: [
            {
              descriptor: {
                computedStyle: {
                  hideContentOnMobile: false,
                },
                style: {
                  'padding-bottom': '10px',
                  'padding-left': '10px',
                  'padding-right': '10px',
                  'padding-top': '10px',
                },
                text: {
                  computedStyle: {
                    linkColor: `${agencyColor}`,
                  },
                  html: '<div class="txtTinyMce-wrapper" style="font-size:14px;line-height:16px;font-family:Arial, &#39;Helvetica Neue&#39;, Helvetica, sans-serif;" data-mce-style="font-size:14px;line-height:16px;font-family:Arial, &#39;Helvetica Neue&#39;, Helvetica, sans-serif;"><p style="font-size:14px;line-height:16px;text-align:center;word-break:break-word;" data-mce-style="font-size:14px;line-height:16px;text-align:center;word-break:break-word;"><span style="font-size:24px;line-height:28px;" data-mce-style="font-size:24px;line-height:28px;">Header Text</span></p></div>',
                  style: {
                    color: '#000000',
                    'font-family':
                      'Arial, \'Helvetica Neue\', Helvetica, sans-serif',
                    'line-height': '120%',
                  },
                },
              },
              locked: false,
              type: 'mailup-bee-newsletter-modules-text',
            },
          ],
          style: {
            'background-color': 'transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-top': '0px solid transparent',
            'padding-bottom': '0px',
            'padding-left': '0px',
            'padding-right': '0px',
            'padding-top': '10px',
          },
        },
      ],
      container: {
        style: {
          'background-color': 'transparent',
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat',
        },
      },
      content: {
        computedStyle: {
          hideContentOnDesktop: false,
          hideContentOnMobile: false,
          rowColStackOnMobile: true,
          rowReverseColStackOnMobile: false,
          verticalAlign: 'top',
        },
        style: {
          'background-color': 'transparent',
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat',
          color: '#000000',
          width: '600px',
        },
      },
      empty: false,
      locked: false,
      synced: false,
      type: 'one-column-empty',
    },
    {
      columns: [
        {
          'grid-columns': 12,
          modules: [
            {
              descriptor: {
                computedStyle: {
                  hideContentOnMobile: false,
                },
                style: {
                  'padding-bottom': '10px',
                  'padding-left': '10px',
                  'padding-right': '10px',
                  'padding-top': '10px',
                },
                text: {
                  computedStyle: {
                    linkColor: `${agencyColor}`,
                  },
                  html: `<div class="txtTinyMce-wrapper" style="font-family:inherit;font-size:12px;line-height:14px;" data-mce-style="font-family:inherit;font-size:12px;line-height:14px;"><p style="font-size:14px;line-height:16px;word-break:break-word;" data-mce-style="font-size:14px;line-height:16px;word-break:break-word;text-align:center;">Dear [Candidate's Name],<br><br> Thank you for your interest in [Company Name]!<br>To proceed with your application, please click on the button below to engage in a quick conversation with our chatbot.</p></div>`,
                  style: {
                    color: '#5d5959',
                    'font-family': 'inherit',
                    'line-height': '120%',
                  },
                },
              },
              locked: false,
              type: 'mailup-bee-newsletter-modules-text',
            },
            {
              descriptor: {
                button: {
                  label:
                    '<div class="txtTinyMce-wrapper" style="" data-mce-style=""><p style="word-break:break-word;" data-mce-style="word-break:break-word;">Go To Chatbot</p></div>',
                  style: {
                    'background-color': `${buttonColor}`,
                    'border-bottom': '0px solid transparent',
                    'border-left': '0px solid transparent',
                    'border-radius': '4px',
                    'border-right': '0px solid transparent',
                    'border-top': '0px solid transparent',
                    color: '#ffffff',
                    direction: 'ltr',
                    'font-family': 'inherit',
                    'font-size': '14px',
                    'font-weight': '400',
                    'line-height': '200%',
                    'max-width': '100%',
                    'padding-bottom': '5px',
                    'padding-left': '20px',
                    'padding-right': '20px',
                    'padding-top': '5px',
                    width: 'auto',
                  },
                  target: '_blank',
                  href: '[Link to Chatbot]',
                },
                computedStyle: {
                  height: 38,
                  hideContentOnMobile: false,
                  width: 95,
                },
                style: {
                  'padding-bottom': '10px',
                  'padding-left': '10px',
                  'padding-right': '10px',
                  'padding-top': '10px',
                  'text-align': 'center',
                },
              },
              locked: false,
              type: 'mailup-bee-newsletter-modules-button',
            },
            {
              descriptor: {
                computedStyle: {
                  hideContentOnMobile: false,
                },
                style: {
                  'padding-bottom': '10px',
                  'padding-left': '10px',
                  'padding-right': '10px',
                  'padding-top': '10px',
                },
                text: {
                  computedStyle: {
                    linkColor: `${agencyColor}`,
                  },
                  html: `<div class="txtTinyMce-wrapper" style="font-family:inherit;font-size:12px;line-height:14px;" data-mce-style="font-family:inherit;font-size:12px;line-height:14px;"><p style="font-size:14px;line-height:16px;word-break:break-word;" data-mce-style="font-size:14px;line-height:16px;word-break:break-word;text-align:center;">This should only take a few minutes of your time and will help us get to know you better.<br>
                  If you have any questions, feel free to reach out to us at [Contact Information].<br><br>
                  
                  Best regards,<br>
                  [Your Company Name] Team</p></div>`,
                  style: {
                    color: '#5d5959',
                    'font-family': 'inherit',
                    'line-height': '120%',
                  },
                },
              },
              locked: false,
              type: 'mailup-bee-newsletter-modules-text',
            },
          ],
          style: {
            'background-color': 'transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-top': '0px solid transparent',
            'padding-bottom': '0px',
            'padding-left': '0px',
            'padding-right': '0px',
            'padding-top': '0px',
          },
        },
      ],
      container: {
        style: {
          'background-color': 'transparent',
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat',
        },
      },
      content: {
        computedStyle: {
          hideContentOnDesktop: false,
          hideContentOnMobile: false,
          rowColStackOnMobile: true,
          rowReverseColStackOnMobile: false,
          verticalAlign: 'top',
        },
        style: {
          'background-color': '#FFFFFF',
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat',
          color: '#000000',
          width: '600px',
        },
      },
      empty: false,
      locked: false,
      synced: false,
      type: 'one-column-empty',
    },
    {
      columns: [
        {
          'grid-columns': 12,
          modules: [
            {
              descriptor: {
                computedStyle: {
                  height: 57,
                  hideContentOnMobile: false,
                  iconsDefaultHeight: 32,
                  iconsDefaultWidth: 32,
                  padding: '0 5px 5px 0',
                  width: 188,
                },
                iconsList: {
                  icons: [
                    {
                      image: {
                        alt: 'Facebook',
                        href: 'https://www.facebook.com/',
                        prefix: 'https://www.facebook.com/',
                        src: 'https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-dark-gray/facebook@2x.png',
                        target: '_self',
                        title: 'Facebook',
                      },
                      name: 'facebook',
                      text: '',
                      type: 'follow',
                    },
                    {
                      image: {
                        alt: 'Twitter',
                        href: 'https://twitter.com/',
                        prefix: 'https://twitter.com/',
                        src: 'https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-dark-gray/twitter@2x.png',
                        target: '_self',
                        title: 'Twitter',
                      },
                      name: 'twitter',
                      text: '',
                      type: 'follow',
                    },
                    {
                      image: {
                        alt: 'Instagram',
                        href: 'https://instagram.com/',
                        prefix: 'https://instagram.com/',
                        src: 'https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-dark-gray/instagram@2x.png',
                        target: '_self',
                        title: 'Instagram',
                      },
                      name: 'instagram',
                      text: '',
                      type: 'follow',
                    },
                    {
                      image: {
                        alt: 'LinkedIn',
                        href: 'https://www.linkedin.com/',
                        prefix: 'https://www.linkedin.com/',
                        src: 'https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-dark-gray/linkedin@2x.png',
                        target: '_self',
                        title: 'LinkedIn',
                      },
                      name: 'linkedin',
                      text: '',
                      type: 'follow',
                    },
                  ],
                },
                style: {
                  'padding-bottom': '10px',
                  'padding-left': '10px',
                  'padding-right': '10px',
                  'padding-top': '10px',
                  'text-align': 'center',
                },
              },
              locked: false,
              type: 'mailup-bee-newsletter-modules-social',
            },
            {
              descriptor: {
                computedStyle: {
                  hideContentOnMobile: false,
                },
                style: {
                  'padding-bottom': '10px',
                  'padding-left': '10px',
                  'padding-right': '10px',
                  'padding-top': '10px',
                },
                text: {
                  computedStyle: {
                    linkColor: `${agencyColor}`,
                  },
                  html: '<div class="txtTinyMce-wrapper" style="font-size:14px;line-height:16px;font-family:inherit;" data-mce-style="font-size:14px;line-height:16px;font-family:inherit;"><p style="font-size:14px;line-height:16px;word-break:break-word;text-align:center;" data-mce-style="font-size:14px;line-height:16px;word-break:break-word;text-align:center;">companywebsite.com</p></div>',
                  style: {
                    color: '#555555',
                    'font-family': 'inherit',
                    'line-height': '120%',
                  },
                },
              },
              locked: false,
              type: 'mailup-bee-newsletter-modules-text',
            },
            {
              descriptor: {
                computedStyle: {
                  hideContentOnMobile: false,
                },
                style: {
                  'padding-bottom': '10px',
                  'padding-left': '10px',
                  'padding-right': '10px',
                  'padding-top': '10px',
                },
                text: {
                  computedStyle: {
                    linkColor: `${agencyColor}`,
                  },
                  html: '<div class="txtTinyMce-wrapper" style="font-size:14px;line-height:16px;font-family:inherit;" data-mce-style="font-size:14px;line-height:16px;font-family:inherit;"><p style="font-size:14px;line-height:16px;word-break:break-word;text-align:center;" data-mce-style="font-size:14px;line-height:16px;word-break:break-word;text-align:center;"><span style="font-size:12px;line-height:14px;" data-mce-style="font-size:12px;line-height:14px;">Company address line 1, street, city, state, 112233</span></p><p style="font-size:14px;line-height:16px;word-break:break-word;text-align:center;" data-mce-style="font-size:14px;line-height:16px;word-break:break-word;text-align:center;"><span style="font-size:12px;line-height:14px;" data-mce-style="font-size:12px;line-height:14px;">+1 2332123321</span></p></div>',
                  style: {
                    color: '#555555',
                    'font-family': 'inherit',
                    'line-height': '120%',
                  },
                },
              },
              locked: false,
              type: 'mailup-bee-newsletter-modules-text',
            },
          ],
          style: {
            'background-color': 'transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-top': '0px solid transparent',
            'padding-bottom': '0px',
            'padding-left': '0px',
            'padding-right': '0px',
            'padding-top': '0px',
          },
        },
      ],
      container: {
        style: {
          'background-color': '#e8e8e8',
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat',
        },
      },
      content: {
        computedStyle: {
          hideContentOnDesktop: false,
          hideContentOnMobile: false,
          rowColStackOnMobile: true,
          rowReverseColStackOnMobile: false,
          verticalAlign: 'top',
        },
        style: {
          'background-color': 'transparent',
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat',
          color: '#000000',
          width: '600px',
        },
      },
      empty: false,
      locked: false,
      synced: false,
      type: 'one-column-empty',
    },
    {
      columns: [
        {
          'grid-columns': 12,
          modules: [
            {
              descriptor: {
                computedStyle: {
                  hideContentOnMobile: false,
                },
                style: {
                  'padding-bottom': '5px',
                  'padding-left': '5px',
                  'padding-right': '5px',
                  'padding-top': '5px',
                },
                text: {
                  computedStyle: {
                    linkColor: '#0068A5',
                  },
                  html: '<div class="txtTinyMce-wrapper" style="font-family: Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; font-size: 12px; line-height: 18px;" data-mce-style="font-family: Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; font-size: 12px; line-height: 18px;"> {{reason_for_receiving_email}} {{manage_subscription_link}} </div>',
                  style: {
                    color: '#787878',
                    'font-family': 'inherit',
                    'line-height': '150%',
                  },
                },
              },
              locked: true,
              type: 'mailup-bee-newsletter-modules-text',
            },
          ],
          style: {
            'background-color': 'transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-top': '0px solid transparent',
            'padding-bottom': '0px',
            'padding-left': '0px',
            'padding-right': '0px',
            'padding-top': '0px',
          },
        },
      ],
      container: {
        style: {
          'background-color': 'transparent',
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat',
        },
      },
      content: {
        computedStyle: {
          hideContentOnDesktop: false,
          hideContentOnMobile: false,
          rowColStackOnMobile: true,
          rowReverseColStackOnMobile: false,
          verticalAlign: 'top',
        },
        style: {
          'background-color': 'transparent',
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat',
          color: '#000000',
          width: '600px',
        },
      },
      empty: false,
      footer: true,
      locked: false,
      synced: false,
      type: 'one-column-empty',
    },
  ];

  return {
    page: {
      body: {
        container: {
          style: {
            'background-color': '#ffffff',
          },
        },
        content: {
          computedStyle: {
            linkColor: `${agencyColor}`,
            messageBackgroundColor: 'transparent',
            messageWidth: '600px',
          },
          style: {
            color: '#000000',
            'font-family': 'Arial, \'Helvetica Neue\', Helvetica, sans-serif',
          },
        },
        type: 'mailup-bee-page-properties',
        webFonts: [],
      },
      description: '',
      rows,
      template: {
        name: 'template-base',
        type: 'basic',
        version: '2.0.0',
      },
      title: '',
    },
  };
}
