// @noflow
import type {State as ReduxState} from 'src/reducers';
import type {AudienceEntity} from 'src/types/multientity-audience';
import type {EntitySummary, PlacementSummary} from 'src/types/entity-summaries';
import type {PersonRelatedSummary} from 'src/types/entity-summaries.snake.js';
import type {EntityType, EntityAttribute} from 'src/types/ats-entities';
import type {AudienceMemberTags} from 'src/api-parsers/index';

import {createSelector} from 'reselect';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';

import {values} from 'src/utils/object';
import {emptyObject, emptyArray} from 'src/utils';
import {selectEntityDirectAttributesByType} from 'src/selectors/ats-entities';


export type LegacyAudienceObject = {
  memberList: LegacyAudienceMember[],
  numPages: number,
  numTotalMembers: number,
  page: number,
  workflowId?: string,
};

export type LegacyAudienceMember = {
  id: string,
  firstName?: string,
  lastName?: string,
  fullname?: string | null,
};

export type ModifiedLegacyAudienceMember = {
  ...LegacyAudienceMember,
  ...AudienceEntity,
  name?: null | string,
  firstname?: null | string,
  lastname?: null | string,
  candidate?: *,
  tags?: AudienceMemberTags,
};

const entities = (state: ReduxState) => state.multientityAudience.entities;

export const selectRelatedEntities = (state) =>
  state.multientityAudience.related;

const getIncomingEntities = (state: ReduxState): * =>
  state.multientityAudience.incoming;
const getAtsMappingsByUrl = (state) => keyBy(state.atsEntities.entities, 'url');

export const selectEntity: (
  state: ReduxState,
  id: string,
) => AudienceEntity = createSelector(
  entities,
  (state, id) => id,
  (entities, id) => entities[id],
);

export const selectEntitySummary = (
  state: ReduxState,
  entityType: EntityType,
  audienceMemberId: string,
) =>
  state?.multientityAudience?.related?.[entityType]?.[audienceMemberId] || {};

export const entityAsLegacyEntity = (
  entity: AudienceEntity,
): ModifiedLegacyAudienceMember => ({
  ...entity,
  ...(entity.data ? entity.data : {}),
  tags: entity.data,
  id: entity.id,
});

export const expandedEntityAsLegacyEntity = (
  entity: AudienceEntity,
): ModifiedLegacyAudienceMember => ({
  ...entity,
  ...(entity?.data || {}),
  tags: entity.data,
  id: entity.id,
  // TODO (rng) - Massive ugly hack, this works because
  // Appointment/Placement/JobSubmission have either a 'candidate' or
  // 'candidateReference' field as the candidate object
  candidate:
    entity.outgoingRelatedEntityIds?.candidate ||
    entity.outgoingRelatedEntityIds?.candidateReference ||
    entity.outgoingRelatedEntityIds?.pcrCandidate ||
    {},
});

export const selectMultientityAudienceAsLegacyAudience: (
  state: ReduxState,
  page: number,
  entity: string,
) => LegacyAudienceObject = createSelector(
  // TODO(marcos): use getAtsCandidateName here instead?
  (state, page, entity) => state.multientityAudience?.[entity]?.[page],
  entities,
  (state, page) => page,
  (audiencePage, entities, page) =>
    audiencePage
      ? {
          // TODO(marcos): replace this with a better implementation
          memberList: audiencePage.entitiesById.map((entityId) =>
            entityAsLegacyEntity(entities[entityId]),
          ),
          numPages: audiencePage.numPages,
          numTotalMembers: audiencePage.totalEntities,
          page,
          isLoaded: true,
        }
      : {
          memberList: [],
          numPages: 0,
          numTotalMembers: 0,
          page,
          isLoaded: false,
        },
);

export const selectMultientityWorkflowAudienceCount = (
  state: ReduxState,
  workflowId: string,
) => state.multientityAudience?.workflows?.[workflowId]?.['1']?.totalEntities;

export const selectMultientityWorkflowAudienceAsLegacyAudience: (
  state: ReduxState,
  page: string,
  workflowId?: string,
) => LegacyAudienceObject = createSelector(
  (state, page, workflowId) =>
    state.multientityAudience.workflows[workflowId] &&
    state.multientityAudience.workflows[workflowId][page],
  entities,
  (state, page) => page,
  (state, page, workflowId) => workflowId,
  (audiencePage, entities, page, workflowId) =>
    audiencePage
      ? {
          // TODO(marcos): replace this with a better implementation
          memberList: audiencePage.entitiesById.map(
            (entityId) => entities[entityId],
          ),
          numPages: audiencePage.numPages,
          numTotalMembers: audiencePage.totalEntities,
          page: parseInt(page, 10),
          workflowId,
        }
      : null,
);

export const selectMultiEntityAudienceMember = createSelector<
  ReduxState,
  string,
  ?ModifiedLegacyAudienceMember,
  AudienceEntity,
>(selectEntity, (entity) => (entity ? entityAsLegacyEntity(entity) : null));

type AudienceMemberField = {
  isReadOnly: boolean,
  key: string,
  label: string,
  type: 'string' | 'number' | 'date' | 'currency',
  value?: *,
};

export const selectAudienceMemberAsFields: (
  state: ReduxState,
  id: string,
  entityType: EntityType,
) => AudienceMemberField[] = createSelector(
  selectEntity,
  (state, _id, entityType) =>
    selectEntityDirectAttributesByType(state, entityType),
  (entity, candidateAttributes) =>
    entity?.data
      ? candidateAttributes.map((attribute) => ({
          label: attribute.display_name,
          key: attribute.attribute_name,
          isReadOnly: true,
          type: 'string',
          value: entity.data[attribute.attribute_name] ?? null,
          dataType: attribute.data_type,
          category: attribute.category,
          others: attribute,
        }))
      : [],
);

/**
 * - gets and formats related entities of a person entity
 * - entityType here is ats entity type (e.g. 'bh_placement')
 * - data attribute in the return includes only variables of the related entity;
 *   for example, if the entity type is bh_placement, data would include placement variables only
 * - an example return with one related entity: [
 *   {
        "submission_date": "2019-02-03",
        "company_id": null,
        "company_name": null,
        "candidate_id": "01b9d017-54be-402d-9a1c-6697ee6cbd0f",
        "candidate_name": "Chris Pryor",
        "data": {
          "id": {
            "name": "id",
            "value": "132",
            "type": "string",
            "label": "id"
          },
          "latestappointment.id": {
            "name": "latestappointment.id",
            "value": null,
            "type": "string",
            "label": "latestappointment.id"
          }
          ...
        },
        "external_source_id": "132",
        "id": "0812c0fc-a127-401d-a66a-4817fcc27cf6"
      }
    ]
 */
export const selectRelatedEntitiesAsSummaries = createSelector<
  ReduxState, // State
  string, // Props
  EntitySummary[],
  {
    [id: string]: EntitySummary,
  },
  string[], // T2
  EntityAttribute[], // T3
>(
  // T1
  (state, _, entityType) =>
    state.multientityAudience.related?.[entityType] || emptyObject,
  // T2
  (state, audienceMemberId, entityType) =>
    state.multientityAudience.incoming?.[audienceMemberId]?.[entityType] ||
    emptyArray,
  // T3
  (state, _, entityType) =>
    selectEntityDirectAttributesByType(state, entityType),
  (relatedEntityDict, entityList, atsEntityAttributes) =>
    entityList
      .map((id) => {
        const relatedEntity = relatedEntityDict[id];
        if (relatedEntity) {
          const data = keyBy(
            atsEntityAttributes.map((attribute) => ({
              name: attribute.attribute_name,
              value:
                relatedEntity.data[attribute.attribute_name?.toLowerCase()] ??
                null,
              //NOTE (angelina): lowercasing attribute name because server sends back variable values with keys lowercased
              type: attribute.data_type,
              label: attribute.display_name,
            })),
            'name',
          );

          return {
            ...relatedEntity,
            id: relatedEntity.id,
            data,
          };
        }

        return null;
      })
      .filter(Boolean),
);

export const selectRelatedEntityIds: (
  state: ReduxState,
  entityType: EntityType,
  audienceMemberId: string,
) => string[] = createSelector(
  (_, entityType) => entityType,
  (_, __, audienceMemberId) => audienceMemberId,
  getIncomingEntities,
  (entityType, audienceMemberId, incomingEntities) => {
    const relatedEntities = get(
      incomingEntities,
      [audienceMemberId, entityType],
      [],
    );
    return relatedEntities;
  },
);

export const selectRelatedEntitySummaries: (
  state: ReduxState,
  entityType: EntityType,
  audienceMemberId: string,
) => PlacementSummary[] = createSelector(
  selectRelatedEntityIds,
  (state, entityType) =>
    state.multientityAudience.related?.[entityType] || emptyObject,
  (entityIds, relatedEntities) =>
    entityIds.map((entityId) => relatedEntities[entityId]).filter(Boolean),
);

export const selectPersonEntitySummaries: (
  state: ReduxState,
  personId: string,
  entityUrl: string,
) => PersonRelatedSummary = (state, personId, entityUrl) => {
  const mappingsByUrl = getAtsMappingsByUrl(state);
  const entityType = mappingsByUrl[entityUrl]?.name;
  return state.multientityAudience.related[entityType]?.[personId] || {};
};

/**
 * returns a list of all related entities that have already been fetched. This is useful for avoiding refetching entities already fetched.
 */
export const selectAllRelatedEntitiesIds: (
  state: ReduxState,
) => string[] = createSelector(selectRelatedEntities, (relatedEntities) =>
  Object.keys(relatedEntities).reduce(
    (ids, entityType) => ids.concat(Object.keys(relatedEntities[entityType])),
    [],
  ),
);

export const selectRelatedEntityByExternalId: (
  state: ReduxState,
  entityType: EntityType,
  externalId: string,
) => EntitySummary = createSelector(
  selectRelatedEntities,
  (_, entityType) => entityType,
  (_, __, externalId) => externalId,
  (relatedEntities, entityType, externalId) => {
    const entities = relatedEntities[entityType];
    return (
      (entities &&
        values(entities).find(
          ({external_source_id}) => external_source_id === externalId,
        )) ||
      {}
    );
  },
);
