// @noflow

import * as React from 'react';

import {classify} from 'src/utils';
import CloseIcon from 'src/images/close-icon.svg';

import css from './style.css';


type Props = {
  onClick: *,
  className?: string,
  children: React.Node,
  pillColor?: string,
};

const FilledToken = ({className, onClick, children, pillColor}: Props) => (
  <div className={classify(css.filledToken, className)} onClick={onClick}>
    {pillColor && (
      <div className={css.pillBar} style={{backgroundColor: pillColor}} />
    )}
    <span className={css.filledTokenValue}>{children}</span>
    <CloseIcon className={css.deleteFilledToken} />
  </div>
);

export default FilledToken;
