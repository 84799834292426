// @flow strict

import type {TriggerRule} from 'src/types/trigger';

import {
  UPDATE_TRIGGER,
  ADD_TRIGGER_RULE,
  REMOVE_TRIGGER_RULE,
  UPDATE_TRIGGER_RULE,
  RECEIVE_TRIGGER,
  START_EDIT_TRIGGER_RULE,
  END_EDIT_TRIGGER_RULE,
  type TriggerAction,
} from 'src/action-creators/trigger';
import {getTriggerRule} from 'src/utils/trigger';


export type TriggerState = {
  rules: TriggerRule[],
  editRuleId: ?string,
};

const initialState = {
  rules: [],
  editRuleId: null,
};

export default (
  state: TriggerState = initialState,
  action: TriggerAction,
): TriggerState => {
  switch (action.type) {
    case ADD_TRIGGER_RULE: {
      const newTriggerRule = getTriggerRule({field: action.payload});
      return {
        ...state,
        rules: [...state.rules, newTriggerRule],
        editRuleId: newTriggerRule.id,
      };
    }
    case REMOVE_TRIGGER_RULE: {
      return {
        ...state,
        rules: state.rules.filter((rule) => rule.id !== action.payload),
      };
    }
    case UPDATE_TRIGGER_RULE: {
      const {id, ...restPayload} = action.payload;
      return {
        ...state,
        rules: state.rules.map((rule) => {
          if (rule.id === id) {
            return {...rule, ...restPayload};
          }
          return rule;
        }),
      };
    }
    case RECEIVE_TRIGGER: {
      return {...state, ...action.payload};
    }
    case START_EDIT_TRIGGER_RULE: {
      return {...state, editRuleId: action.payload};
    }
    case END_EDIT_TRIGGER_RULE: {
      return {...state, editRuleId: null};
    }
  }
  return state;
};
