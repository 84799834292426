// @flow strict
import * as React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Clickable, SubHeader} from 'src/designSystem2021Components/text-v2.jsx';
import {DefaultFooter} from 'src/components/agency-onboard/footer/default-footer.jsx';
import classify from 'src/utils/classify';
import {FileUpload} from 'src/designSystem2021Components/file-upload.jsx';

// $FlowFixMe[nonstrict-import]
import * as api from 'src/utils/api-no-store';
import logger from 'src/utils/logger';
//$FlowFixMe[nonstrict-import]
import {selectAgencyData} from 'src/selectors/agency';
import {AnalyticsService} from 'src/analytics';
import {AMPLITUDE_EVENTS} from 'src/components/agency-onboard/amplitude_event.js';

// $FlowFixMe[nonstrict-import]
import {showApiError} from 'src/action-creators/modal';

import css from './add-phone-number.css';
import maincss from 'src/components/agency-onboard/agency-onboard.css';
import DownloadIcon from 'src/images/icons/download-nofill.svg';


type Task = {
  id: string,
};

type submitData = {
  csv_url: string,
  csv_name: string,
};

export type sidePanelPropps = {
  task: Task,
  onTaskSubmit: (id: string, data: submitData) => mixed,
  onTaskCancel: () => mixed,
};

function uploadMedia(file: File): Promise<{
  url: string,
}> {
  const formData = new FormData();
  formData.append('upload', file);
  return (
    api
      // $FlowFixMe[class-object-subtyping]
      .post('onboard/upload-phones', formData, undefined, {multipart: true})
      .then((response) => response)
      .catch((error) => {
        logger.error('index uploadMedia error:', error.stack || error);
        throw error;
      })
  );
}

export const AddPhoneNumber = (props: sidePanelPropps): React.Node => {
  const dispatch = useDispatch();
  const agencyData = useSelector(selectAgencyData);

  const handleTrackingEvent = (event) => {
    AnalyticsService.track(event, {
      agencyId: agencyData.id,
      agencyName: agencyData.name,
    });
  };

  const {task, onTaskCancel, onTaskSubmit} = props;
  const [csvUrl, setCsvUrl] = React.useState('');
  const [csvName, setCsvName] = React.useState('');

  const handleFormSubmit = () => {
    onTaskSubmit(task['id'], {csv_url: csvUrl, csv_name: csvName});
    handleTrackingEvent(
      AMPLITUDE_EVENTS.ADD_PHONE_NUMBER_SUBMIT_BUTTON_CLICKED,
    );
  };

  const handleFormCancel = () => {
    onTaskCancel();
  };

  const handleFileUpload = async (file, callbacks) => {
    try {
      const response = await uploadMedia(file);
      console.log(response);
      console.log(file);
      setCsvUrl(response.url);
      setCsvName(file.name);

      callbacks.onSuccess();
    } catch (error) {
      let text;
      switch (error.status) {
        case 400:
          text = 'Oops, the file you selected isn\'t valid';
          break;
        case 403:
          text = 'Sorry, you don\'t have permission to upload.';
          break;
        default:
          text = 'Please try again in a few minutes.';
          break;
      }
      callbacks.onError(text);
    }
  };

  return (
    <div className={maincss.sidePanel}>
      <Clickable className={maincss.textPrimary}>
        Upload all you agency numbers below in CSV format
      </Clickable>
      <div className={css.stepsContainer}>
        <div className={css.stepDiv}>
          <SubHeader className={classify(css.stepHeader, maincss.textPrimary)}>
            Step 1:
          </SubHeader>
          <SubHeader className={classify(css.stepText, maincss.text)}>
            <div>
              You Can
              <a
                className={css.downloadLink}
                href={`/api/v1/onboard/upload-phones/download-sample-csv`}
                download={'add-phone-sample.csv'}
              >
                <DownloadIcon className={css.downloadIcon} />
                <Clickable className={css.downloadInfo}>
                  {' '}
                  Download Sample CSV{' '}
                </Clickable>
              </a>
              From here
            </div>
          </SubHeader>
        </div>
        <div className={css.stepDiv}>
          <SubHeader className={classify(css.stepHeader, maincss.textPrimary)}>
            Step 2:
          </SubHeader>
          <SubHeader className={classify(css.stepText, maincss.text)}>
            After filling the sample CSV, upload the form below
            <div className={css.fileUploadSection}>
              <FileUpload
                label={''}
                secondaryInstruction="Types: .csv  Max. size: 1mb"
                maxSize={1000000}
                accept={['.csv']}
                // $FlowFixMe[incompatible-type]
                onFileSelect={async (file, callbacks) => {
                  handleFileUpload(file, callbacks);
                }}
              />
            </div>
          </SubHeader>
        </div>
        <DefaultFooter
          disableSubmit={!csvUrl}
          onSubmit={handleFormSubmit}
          onCancel={handleFormCancel}
        />
      </div>
    </div>
  );
};
