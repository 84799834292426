// @flow

import * as sentry from 'sentry';


type SentryContext = {
  extra?: {...},
  tags?: {...},
  level?: string,
};

function configureSentryScope(context, fn) {
  sentry.withScope((scope) => {
    const {extra, tags, level} = context;

    if (level) {
      scope.setLevel(level);
    }

    if (extra) {
      Object.keys(extra).forEach((extraKey) => {
        scope.setExtra(extraKey, extra[extraKey]);
      });
    }

    if (tags) {
      Object.keys(tags).forEach((tagKey) => {
        scope.setTag(tagKey, tags[tagKey]);
      });
    }

    fn();
  });
}

export function captureSentryException(err: mixed, context: SentryContext) {
  configureSentryScope(context, () => {
    sentry.captureException(err);
  });
}

export function captureSentryMessage(message: string, context: SentryContext) {
  configureSentryScope(context, () => {
    sentry.captureMessage(message);
  });
}
