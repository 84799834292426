// @flow

import * as React from 'react';
import truncate from 'lodash/truncate';
import debounce from 'lodash/debounce';

import classify from 'src/utils/classify';

import {MouseTip} from 'src/components/lib/mouse-tip/mouse-tip.jsx';
import {Tooltip} from '@spaced-out/ui-design-system/lib/components/Tooltip';
import {elevationToast} from '@spaced-out/ui-design-system/lib/styles/variables/_elevation';
import css from './truncated-text.css';


type TruncatedTextType = (<C: string>({
  [string]: mixed,
  text: string,
  limit: number,
  component?: C,
}) => React.Node) &
  (<C: React.ComponentType<any>>({
    ...React.ElementConfig<C>,
    text: string,
    limit: number,
    component: C,
  }) => React.Node);

const TruncatedText: TruncatedTextType = ({
  text,
  limit,
  // $FlowFixMe[escaped-generic]
  component = 'span',
  ...props
}) => {
  const shortText = truncate(text, {length: limit});
  // $FlowFixMe[escaped-generic]
  // $FlowFixMe[incompatible-call]
  const content = React.createElement(component, props, shortText);
  if (shortText === text) {
    // $FlowFixMe[escaped-generic]
    return content;
  }
  return <MouseTip content={text}>{content}</MouseTip>;
};

export default TruncatedText;

type AutoTruncatedTextType = (<C: string>({
  [string]: mixed,
  useFragment?: boolean,
  text: React.Node,
  tipContent?: React.Node,
  wrapText?: boolean,
  component?: C,
  className?: string,
}) => React.Node) &
  (<C: React.ComponentType<mixed>>({
    ...React.ElementConfig<C>,
    useFragment?: boolean,
    text: React.Node,
    tipContent?: React.Node,
    wrapText?: boolean,
    component: C,
    className?: string,
  }) => React.Node);

export const AutoTruncatedText: AutoTruncatedTextType = ({
  useFragment = false,
  text,
  tipContent,
  wrapText = false,
  className,
  // $FlowFixMe[escaped-generic]
  ...props
}) => {
  const [showMouseTip, setShowMouseTip] = React.useState(false);
  const truncatedRef = React.useRef<?HTMLSpanElement>();

  const checkWidths = React.useMemo(
    () =>
      debounce(() => {
        const span = truncatedRef.current;

        if (span) {
          setShowMouseTip(span.offsetWidth < span.scrollWidth);
        }
      }, 200),
    [],
  );

  //running only on mount and unmount
  React.useEffect(() => {
    window.addEventListener('resize', checkWidths);

    return () => {
      window.removeEventListener('resize', checkWidths);
    };
  }, [checkWidths]);

  React.useEffect(() => {
    checkWidths();
  }, [text]);

  const content = showMouseTip ? (
    <MouseTip content={tipContent ?? text}>
      {/* $FlowFixMe[incompatible-type] */}
      <span
        {...props}
        className={classify(css.truncatedText, className)}
        ref={truncatedRef}
      >
        {text}
      </span>
    </MouseTip>
  ) : (
    // $FlowFixMe[incompatible-type]
    <span
      {...props}
      className={classify(className, {
        [css.truncatedText]: !wrapText,
        [css.wrapText]: wrapText,
      })}
      ref={truncatedRef}
    >
      {text}
    </span>
  );

  return useFragment ? (
    <>{content}</>
  ) : (
    <div className={css.container}>{content}</div>
  );
};

export const AutoTruncatedTextGenesis: AutoTruncatedTextType = ({
  useFragment = false,
  text,
  tipContent,
  wrapText = false,
  className,
  // $FlowFixMe[escaped-generic]
  ...props
}) => {
  const [showMouseTip, setShowMouseTip] = React.useState(false);
  const truncatedRef = React.useRef<?HTMLSpanElement>();

  const checkWidths = React.useMemo(
    () =>
      debounce(() => {
        const span = truncatedRef.current;

        if (span) {
          setShowMouseTip(span.offsetWidth < span.scrollWidth);
        }
      }, 200),
    [],
  );

  //running only on mount and unmount
  React.useEffect(() => {
    window.addEventListener('resize', checkWidths);

    return () => {
      window.removeEventListener('resize', checkWidths);
    };
  }, [checkWidths]);

  React.useEffect(() => {
    checkWidths();
  }, [text]);

  const content = showMouseTip ? (
    <Tooltip body={tipContent ?? text} elevation={elevationToast}>
      {/* $FlowFixMe[incompatible-type] */}
      <span
        {...props}
        className={classify(css.truncatedText, className)}
        ref={truncatedRef}
      >
        {text}
      </span>
    </Tooltip>
  ) : (
    // $FlowFixMe[incompatible-type]
    <span
      {...props}
      className={classify(className, {
        [css.truncatedText]: !wrapText,
        [css.wrapText]: wrapText,
      })}
      ref={truncatedRef}
    >
      {text}
    </span>
  );

  return useFragment ? (
    <>{content}</>
  ) : (
    <div className={css.container}>{content}</div>
  );
};
