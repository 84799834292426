// @flow strict

import * as React from 'react';
import {Clickable} from 'src/designSystem2021Components/text-v2.jsx';
import {Input} from 'src/components/agency-onboard/lib/input/input.jsx';

import {DefaultFooter} from 'src/components/agency-onboard/footer/default-footer.jsx';
import {ImageField} from 'src/components/agency-onboard/lib/image-field/image-field.jsx';
import ColorPicker from 'src/components/agency-onboard/lib/color-picker/color-picker.jsx';
import {Preview} from 'src/components/agency-onboard/branding-preview/branding-preview.jsx';
import type {Task} from 'src/components/agency-onboard/types/side-panel-comp.js';

import css from './configure-default-branding.css';
import maincss from 'src/components/agency-onboard/agency-onboard.css';


type BrandingSettingData = {
  brandColor: string,
  buttonColor: string,
  logo: string,
  favicon: string,
  displayName: string,
  ogImage: string,
};

export type DefaultBrandingProps = {
  task: Task,
  onTaskSubmit: (id: string, apiData: BrandingSettingData) => mixed,
  onTaskCancel: () => mixed,
  data: BrandingSettingData,
};

export const ConfigureDefaultBranding = (
  props: DefaultBrandingProps,
): React.Node => {
  const DEFAULT_COLOR = '#5C34CD';
  const {task, onTaskCancel, onTaskSubmit, data} = props;
  const [brandingSettings, setBrandingSettings] = React.useState(
    Object.keys(data)
      ? data
      : {
          brandColor: '',
          buttonColor: '',
          logo: '',
          favicon: '',
          displayName: '',
          ogImage: '',
        },
  );

  const handleSettingChange = (field, value) => {
    // using callback function because image upload takes time
    // so overriding input box values
    setBrandingSettings((brandingSettings) => {
      const newBrandingSettings = {...brandingSettings};
      // $FlowFixMe[incompatible-type]
      newBrandingSettings[field] = value;
      return newBrandingSettings;
    });
  };

  const handleFormSubmit = () => {
    onTaskSubmit(task['id'], brandingSettings);
  };

  const handleFormCancel = () => {
    onTaskCancel();
  };

  return (
    <div className={maincss.sidePanel}>
      <Clickable>
        Add personalised touch to your emails by adding branding details{' '}
      </Clickable>
      <div className={css.brandingSettings}>
        <div className={css.uploadSettings}>
          <Clickable>Details</Clickable>
          <div className={css.uploadSection}>
            <div>
              <ImageField
                boxClass={css.imageBoxClass}
                onChange={handleSettingChange}
                label="Logo"
                name="logo"
                imageSrc={brandingSettings.logo}
              />
            </div>
            <div className={css.inputBox}>
              <Input
                name="agency_default_name"
                value={brandingSettings.displayName || ''}
                type="text"
                label="Agency Default Name"
                boxClassName={maincss.outerBoxInputField}
                onChange={(event) =>
                  handleSettingChange('displayName', event.target.value)
                }
              />
            </div>
            <div>
              <ImageField
                boxClass={css.imageBoxClass}
                onChange={handleSettingChange}
                label="Favicon"
                name="favicon"
                imageSrc={brandingSettings.favicon}
              />
            </div>
            <div className={css.colorField}>
              <Clickable className={maincss.textPrimary}>Brand Color</Clickable>
              <ColorPicker
                name="brandColor"
                onChange={handleSettingChange}
                value={brandingSettings.brandColor ?? DEFAULT_COLOR}
                textBoxClass={css.colorFieldInput}
              />
            </div>
            <div className={css.colorField}>
              <Clickable className={maincss.textPrimary}>
                Button Color
              </Clickable>
              <ColorPicker
                name="buttonColor"
                onChange={handleSettingChange}
                value={brandingSettings.buttonColor ?? DEFAULT_COLOR}
                textBoxClass={css.colorFieldInput}
              />
            </div>
            <div>
              <ImageField
                boxClass={css.survayImage}
                onChange={handleSettingChange}
                label="Survey/Bot Share Image"
                name="ogImage"
                imageSrc={brandingSettings.ogImage}
              />
            </div>
          </div>
        </div>
        <div className={css.previewSection}>
          <div className={css.brandingPreview}>
            <Preview
              brandColor={brandingSettings.brandColor}
              buttonColor={brandingSettings.buttonColor}
              logo={brandingSettings.logo}
            />
          </div>
        </div>
      </div>
      <DefaultFooter onSubmit={handleFormSubmit} onCancel={handleFormCancel} />
    </div>
  );
};
