// @flow strict

import {useState, useEffect, useMemo, useRef} from 'react';
import {useDispatch} from 'react-redux';

// $FlowFixMe[nonstrict-import] - need to upgrade this
import {captureApiError, ApiError} from 'src/utils/errors';
// $FlowFixMe[nonstrict-import] - need to upgrade this
import * as api from 'src/utils/redux-api-v2';
// $FlowFixMe[nonstrict-import] - need to upgrade this
import {camel} from 'src/utils/camel-snake';
import {shallowEqual} from 'src/utils/shallowEqual';
import {requestIdleCallback} from 'src/utils/requestIdleCallback';
// $FlowFixMe[nonstrict-import] - need to upgrade this
import {defaultState, type ResourceState} from './useResource';
// $FlowFixMe[untyped-import]
import {serializeQuery} from 'src/utils';


export type {ResourceState};

type Query = {[string]: string, ...};

export type ApiUrl = string | {pathname: string, query?: Query} | null | void;
export type ApiUrlArg = ApiUrl | (() => ApiUrl);

export const IGNORED_STATUS_CODES = [401, 404];

export type BaseApiOptions = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE',
  data?: mixed,
  onFetch?: () => mixed,
  handleError?: (ApiError) => mixed,
  shouldFetch?: boolean,
  shouldCamel?: boolean,
  cacheTimeout?: number,
  apiPath?: string,
  deps?: mixed[],
  key?: string,
  // all 400 <= status < 500 codes get logged _to sentry_
  // unless they're included here. We don't log 401 or 404s.
  // only update this to maintain a semantic api contract
  // but to avoid spamming sentry.
  ignoredStatusCodes?: number[],
};

type Refetch = (force?: boolean) => void;

// TODO (kyle): possibly allow for passing deps to give
// consumer more fine grained control.
export function useAdvancedApi<Response>(
  url: ApiUrl,
  {
    method = 'GET',
    key = 'key',
    data,
    onFetch,
    handleResponse,
    handleError,
    shouldFetch = true,
    shouldCamel,
    cacheTimeout,
    ignoredStatusCodes = IGNORED_STATUS_CODES,
    deps,
    ...options
  }: {
    ...BaseApiOptions,
    handleResponse: (Response) => mixed,
  },
): Refetch {
  let pathname: ?string;
  let query;
  if (url instanceof Object) {
    pathname = url.pathname;
    query = url.query;
  } else if (typeof url === 'string') {
    pathname = url;
  }

  const aborter = useRef();
  const dispatch = useDispatch();

  const doFetch = (force) => {
    if (aborter.current) {
      aborter.current.abort();
      aborter.current = null;
    }

    if (pathname && shouldFetch) {
      const definedPathname = pathname;

      // this error handler gets called on initial fetch as well as when a response is cached
      // so that calls that specify a cache can always expect handleError to be called consistently
      // if it is present (as well as consistent api error handling for cached/initiating calls)
      const useApiErrorHandler = (error) => {
        if (error instanceof ApiError) {
          const {status} = error.response;
          // We only capture errors that are our fault.
          if (status < 500 && !ignoredStatusCodes.includes(status)) {
            captureApiError(error);
          }
          if (handleError) {
            handleError(error);
          }
        } else if (
          // $FlowFixMe[cannot-resolve-name] DOMException
          error instanceof DOMException &&
          error.name === 'AbortError'
        ) {
          // we're the ones who did this
        } else {
          throw error;
        }
      };

      if (cacheTimeout !== undefined && !force) {
        const cacheRecord = apiCache.get({
          pathname,
          query,
          timeout: cacheTimeout,
          key,
        });

        if (cacheRecord) {
          // $FlowFixMe we have to assume this is the correct data.
          Promise.resolve(cacheRecord.data)
            .then((record) => {
              handleResponse((record: Response));
            })
            .catch(useApiErrorHandler);
          return;
        }
      }

      onFetch && onFetch();

      const abortController = new AbortController();
      aborter.current = abortController;

      const responseParamPromise = dispatch(
        // $FlowFixMe[incompatible-call]
        api.baseRequest(pathname, {
          method,
          query,
          data,
          signal: abortController.signal,
          options,
        }),
      );

      if (cacheTimeout) {
        apiCache.set({
          pathname: definedPathname,
          query,
          timeout: cacheTimeout,
          data: responseParamPromise,
          key,
        });
      }
      responseParamPromise
        .then((record) => {
          const data = shouldCamel ? camel(record) : record;
          handleResponse((data: Response));
        })
        .catch(useApiErrorHandler);
    }
  };

  useEffect(doFetch, deps || [pathname, serializeQuery(query)]);

  return doFetch;
}

export type ApiState<Response> = {
  ...ResourceState<Response>,
  refetch: () => void,
};

/**
 * allows you to pull api responses into React components.
 *
 * initially the return value will be `null`, but it should eventually
 * resolve to the response from the api.
 *
 * @param url  an api path
 *             if it is a false-y value, the data will not be fetched.
 *
 * TODO (kyle): we should handle errors
 */
export function useApi<Response>(
  url: ApiUrl,
  optionsArg?: BaseApiOptions | mixed[],
): ApiState<Response> {
  const [state, setState] = useState<ResourceState<Response>>(
    ({
      ...defaultState,
      isLoading: Boolean(url),
    }: ResourceState<Response>),
  );

  const options = {
    onFetch: () => {
      setState((state) => ({...state, isLoading: true}));
    },
    handleResponse: (result: Response) => {
      setState({
        result,
        error: null,
        isLoading: false,
      });
    },
    handleError: (error: Error) => {
      setState({
        result: null,
        error,
        isLoading: false,
      });
    },
  };

  const refetch = useAdvancedApi<Response>(
    url,
    Array.isArray(optionsArg)
      ? {
          ...options,
          deps: optionsArg,
        }
      : {
          ...optionsArg,
          ...options,
        },
  );

  return {
    ...state,
    refetch,
  };
}

export function useApiStub<Stub>(stub: Stub): {
  ...ResourceState<Stub>,
  refetch: () => void,
} {
  return {
    ...defaultState,
    isLoading: false,
    result: stub,
    refetch: () => {
      // pass
    },
  };
}

export function useLegacyApi<Response>(
  url: ApiUrl,
  options?: BaseApiOptions,
): ?Response {
  const [response, setResponse] = useState<?Response>(null);

  useAdvancedApi(url, {
    ...options,
    handleResponse: (response) => {
      setResponse(response);
    },
  });

  return response;
}
export default useLegacyApi;

export function useLegacyApiWithParams<Response>(
  getUrl: () => ApiUrl,
  params: mixed[],
  options?: BaseApiOptions,
): ?Response {
  const url = useMemo(getUrl, params);
  return useLegacyApi(url, options);
}

export function useLoadingApi<Response>(
  getUrl: () => ApiUrl,
  params: mixed[],
  options?: BaseApiOptions,
): {response: ?Response, loaded: boolean} {
  const response = useLegacyApiWithParams(getUrl, params, options);
  return {response, loaded: response != null};
}

type CacheRecord = {
  pathname: string,
  query: ?Query,
  timeout: number, // milliseconds
  time: number,
  data: mixed,
  key?: string,
};

class ApiCache {
  _cache: Map<string, CacheRecord> = new Map();

  get({
    pathname,
    query,
    timeout,
    key,
  }: {
    pathname: string,
    query: ?Query,
    timeout: number,
    key: string,
  }): ?CacheRecord {
    const record = this._cache.get(pathname + key);
    // console.log({key,recordKey:record?.key});

    if (
      record &&
      // TODO (kyle): how slow is this comparison?
      (!shallowEqual(record.query, query) ||
        record.key !== key ||
        Date.now() - record.time > timeout)
    ) {
      this._cache.delete(pathname);
      return null;
    }

    return record;
  }

  set({
    pathname,
    query,
    timeout,
    data,
    key,
  }: {
    pathname: string,
    query: ?Query,
    timeout: number,
    data: mixed,
    key: string,
  }) {
    const record = {
      pathname,
      query,
      timeout,
      time: Date.now(),
      data,
      key,
    };
    this._cache.set(pathname + key, record);

    // $FlowFixMe[method-unbinding]
    requestIdleCallback(this.cull.bind(this));
  }

  evict(pathname: string) {
    this._cache.delete(pathname);
  }

  cull() {
    const currentTime = Date.now();
    // $FlowIssue
    this._cache = new Map(
      this._cache
        .entries()
        // $FlowIssue iterator helpers
        .filter(([_key, record]) => currentTime - record.time < record.timeout),
    );
  }
}

export const apiCache: ApiCache = new ApiCache();
