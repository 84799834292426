// @flow strict

export type ToggleSideNavAction = {
  type: 'toggle-side-nav',
  payload: {
    hide: boolean,
  },
};

export type NavigationAction = ToggleSideNavAction;

export const TOGGLE_SIDE_NAV = 'toggle-side-nav';

export const toggleSideNav = (hide: boolean): ToggleSideNavAction => ({
  type: TOGGLE_SIDE_NAV,
  payload: {
    hide,
  },
});
