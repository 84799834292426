// @noflow

import type {DraftBlockType} from 'draft-js/lib/DraftBlockType';

import * as React from 'react';

import {connect} from 'react-redux';
import {pushModal} from 'src/action-creators/modal';

import {classify} from 'src/utils';
import {selectWorkflowEntityMapping} from 'src/selectors/dynamic-labels';

import NewAddButton from 'src/components/lib/new-add-button';
import VariableButton from 'src/components/lib/variable-button';
import BoldIcon from 'src/images/editor/bold.svg';
import ItalicIcon from 'src/images/editor/italic.svg';
import LinkIcon from 'src/images/editor/link.svg';
// import UnlinkIcon from 'src/images/editor/ic_unlink_black_18px.svg';
import PhotoIcon from 'src/images/editor/image.svg';
import H1Icon from 'src/images/editor/h1.svg';
import H2Icon from 'src/images/editor/h2.svg';
import QuoteIcon from 'src/images/editor/blockquote.svg';
import ULIcon from 'src/images/editor/list-bullet.svg';
import OLIcon from 'src/images/editor/list-numbered.svg';
import CenterAlignIcon from 'src/images/editor/align-center.svg';
import LeftAlignIcon from 'src/images/editor/align-left.svg';

import css from './markdown-editor.css';


type StyleButtonProps = {
  style: *,
  active: boolean,
  onToggle: (styleOrBlocktype: *) => void,
  IconComponent: ?any, // react class
  label: string,
};

export class StyleButton extends React.PureComponent<StyleButtonProps> {
  _handleToggle(style: string, event: Event) {
    event.preventDefault();
    this.props.onToggle(style);
  }

  handleToggle: (event: SyntheticEvent<>) => {};

  constructor(props: StyleButtonProps) {
    super(props);

    (this: any).handleToggle = this._handleToggle.bind(this, props.style);
  }

  render() {
    const {active, IconComponent} = this.props;
    const className = classify(
      css.styleButton,
      {'RichEditor-activeButton': active},
      {[css.activeStyleButton]: active},
    );

    return (
      <span className={className} onMouseDown={this.handleToggle}>
        {IconComponent ? (
          <IconComponent
            className={classify(css.editorIcon, {
              [css.activeIcon]: this.props.active,
            })}
          />
        ) : (
          this.props.label
        )}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  {label: 'H1', style: 'header-one', IconComponent: H1Icon},
  {label: 'H2', style: 'header-two', IconComponent: H2Icon},
  {label: 'UL', style: 'unordered-list-item', IconComponent: ULIcon},
  {label: 'OL', style: 'ordered-list-item', IconComponent: OLIcon},
  {label: 'Blockquote', style: 'blockquote', IconComponent: QuoteIcon},
];

type BlockControlProps = {
  blockType: string,
  onToggle: (blockType: DraftBlockType) => void,
};
export class BlockStyleControls extends React.PureComponent<BlockControlProps> {
  render() {
    const {blockType} = this.props;

    return (
      <div className="RichEditor-controls">
        {BLOCK_TYPES.map((type) => (
          <StyleButton
            key={type.label}
            active={type.style === blockType}
            onToggle={this.props.onToggle}
            {...type}
          />
        ))}
      </div>
    );
  }
}

type ImageControlProps = {
  imageActive: boolean,
  toggleImage: () => void,
};

export class ImageControls extends React.PureComponent<ImageControlProps> {
  render() {
    const {imageActive, toggleImage} = this.props;
    return (
      <StyleButton
        key="img"
        active={imageActive}
        label="Insert Image"
        onToggle={toggleImage}
        IconComponent={PhotoIcon}
      />
    );
  }
}

const INLINE_STYLES = [
  {label: 'Bold', style: 'BOLD', IconComponent: BoldIcon},
  {label: 'Italic', style: 'ITALIC', IconComponent: ItalicIcon},
  // {label: 'Underline', style: 'UNDERLINE'},
  // {label: 'Monospace', style: 'CODE'},
];

type InlineControlProps = {
  onToggle: (style: string) => void,
  currentStyle: any, // actually an immutable set
};
export class InlineStyleControls extends React.PureComponent<InlineControlProps> {
  render() {
    const {currentStyle, onToggle} = this.props;

    return (
      <div className="RichEditor-controls">
        {INLINE_STYLES.map((type) => (
          <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            onToggle={onToggle}
            {...type}
          />
        ))}
      </div>
    );
  }
}

type LinkControlProps = {
  showLink: boolean,
  linkAdjacent: boolean,
  toggleLink: () => void,
};

export class LinkControls extends React.PureComponent<LinkControlProps> {
  render() {
    const {showLink, linkAdjacent} = this.props;

    return (
      <StyleButton
        key="link"
        active={linkAdjacent || showLink}
        label="Link"
        onToggle={this.props.toggleLink}
        style="LINK"
        IconComponent={LinkIcon}
      />
    );
  }
}

type DynamicLabelProps = {
  dynamicMenuVisible: Boolean,
  multiEntityEnabled: Boolean,
  showDynamicMenu: () => void,
  openModal: () => void,
};

const mapStateToProps = (state: State) => ({
  multiEntityEnabled: state.agency.config.multiEntityEnabled,
  mainEntityType: selectWorkflowEntityMapping(state).name,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const {labels, insertResult} = ownProps;

  return {
    openMultiEntityModal: (baseEntityType) => {
      dispatch(
        pushModal({
          type: 'VARIABLE_PICKER',
          labels,
          insertResult,
          baseEntityType,
        }),
      );
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {multiEntityEnabled, mainEntityType} = stateProps;
  const {openMultiEntityModal} = dispatchProps;
  const {showDynamicMenu} = ownProps;
  return {
    handleButton: () => {
      if (multiEntityEnabled) {
        openMultiEntityModal(mainEntityType);
      } else {
        showDynamicMenu();
      }
    },
  };
};

class DynamicLabelControlComp extends React.PureComponent<DynamicLabelProps> {
  render() {
    const {handleButton} = this.props;
    return (
      <NewAddButton
        className={css.styleNewButton}
        text="Add New Variable"
        onClick={handleButton}
      />
    );
  }
}
function DynamicLabelControlCompNew(props) {
  const {handleButton} = props;
  return (
    <div className={css.variableButtonContainer}>
      <VariableButton text="Variable" onClick={handleButton} />
    </div>
  );
}

export const DynamicLabelControl = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DynamicLabelControlComp);
export const DynamicLabelControlNew = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DynamicLabelControlCompNew);

export class CenterBlockControl extends React.PureComponent<{
  alignment: string,
  onToggle: any,
}> {
  render() {
    const {alignment, onToggle} = this.props;

    return (
      <div>
        <StyleButton
          active={alignment !== 'center'}
          label="Center this paragraph"
          onToggle={onToggle}
          IconComponent={LeftAlignIcon}
        />
        <StyleButton
          active={alignment === 'center'}
          label="Center this paragraph"
          onToggle={onToggle}
          IconComponent={CenterAlignIcon}
        />
      </div>
    );
  }
}

export class SurveyLinkControl extends React.PureComponent<{
  onClick: (evt: Event) => void,
  text: string,
}> {
  render() {
    return (
      <NewAddButton
        className={css.styleNewButton}
        text={this.props.text}
        onClick={this.props.onClick}
      />
    );
  }
}
