// @noflow

import type {RouteLocation, Router} from 'src/types/router';
import type {Data, DataRow} from 'src/components/lib/bar-chart/bar-chart.jsx';

import * as React from 'react';
import {withRouter} from 'src/flux/withRouter.jsx';
import flow from 'lodash/flow';
import moment from 'moment';

import BarChart from 'src/components/lib/bar-chart/bar-chart.jsx';
import Loading from 'src/components/lib/loading/loading.jsx';
import pure from 'src/flux/pure.jsx';
import SegmentedButton from 'src/components/lib/segmented-button';
import {classify} from 'src/utils/classify';
import css from './unit-chart.css';


const unitOptions = [
  {label: 'Week', value: 'week'},
  {label: 'Month', value: 'month'},
];

const timeUnitLabels = {
  week: 'Weeks',
  month: 'Months',
};

const UnitBarChart = ({
  barDirection,
  categoryKey,
  color,
  colors,
  data,
  dataAccessor,
  dataKey,
  getTooltipText,
  grouped,
  groupStacks,
  loading,
  location,
  onBarClick,
  router,
  stacked,
  onUnitChange,
  timeUnit,
  showEmptyBars,
  hideUnitChange,
  containerClassName,
  chartContentClassName,
  unitsClassName,
  ...props
}: {
  barDirection: string,
  categoryKey: string,
  color?: string,
  colors?: {
    [key: string]: string,
  },
  data: Data,
  dataKey?: string,
  dataAccessor: (dataRow: DataRow, key: string) => any,
  getTooltipText?: (tooltip: {
    category: string,
    value: number,
    group?: string,
    stack?: string,
  }) => string,
  grouped?: boolean,
  groupStacks?: Array<string | string[]>,
  loading: any,
  location: RouteLocation,
  onBarClick?: (bar: {
    category: string,
    value: number,
    color: string,
    group?: string,
    stack?: string,
  }) => void,
  router: Router,
  stacked?: boolean,
  onUnitChange?: (string) => void,
  timeUnit?: string,
  hideUnitChange?: boolean,
  containerClassName?: string,
  chartContentClassName?: string,
  unitsClassName?: string,
  showEmptyBars?: boolean,
}) => {
  const time_unit = timeUnit || location.query.time_unit;
  const colorScale = color
    ? () => color
    : colors
    ? (key: string) => colors[key]
    : undefined;
  const dateLabelFormat = time_unit === 'week' ? 'MMM D' : 'MMM';
  const xAxisLabels = data
    ? data.reduce(
        (prev, curr) => ({
          ...prev,
          [curr[categoryKey]]: moment(curr[categoryKey]).format(
            dateLabelFormat,
          ),
        }),
        {},
      )
    : [];
  const handleUnitChange = (e) => {
    if (onUnitChange) {
      return onUnitChange(e.target.value);
    }
    router.push({
      ...location,
      query: {
        ...location.query,
        time_unit: e.target.value,
      },
    });
  };
  return (
    <div className={classify(css.container, containerClassName)}>
      <div className={classify(css.units, unitsClassName)}>
        {!hideUnitChange && (
          <SegmentedButton
            className={css.unitButton}
            options={unitOptions}
            value={time_unit}
            onChange={handleUnitChange}
          />
        )}
      </div>
      {loading ? (
        <Loading />
      ) : (
        <BarChart
          barAxisPadding={1}
          barDirection={barDirection}
          barPaddingInner={0.5}
          barPaddingOuter={0.15}
          categoryKey={categoryKey}
          colorScale={colorScale}
          data={data}
          dataKey={dataKey}
          dataAccessor={dataAccessor}
          getTooltipText={getTooltipText}
          groupBarPadding={0.2}
          grouped={grouped}
          groupStacks={groupStacks}
          onBarClick={onBarClick}
          showAxes={true}
          showGrid={true}
          stacked={stacked}
          showEmptyBars={showEmptyBars}
          chartContentClassName={chartContentClassName}
          xAxisProps={{
            axisLabels: xAxisLabels,
            showTicks: false,
            title: timeUnitLabels[time_unit],
            titleAlign: 'start',
            titlePadding: 20,
            titleProps: {
              textAnchor: 'end',
              x: 4,
            },
          }}
          zeroBarPadding={8}
          {...props}
        />
      )}
    </div>
  );
};

export default flow(withRouter, pure())(UnitBarChart);
