// @noflow

import * as React from 'react';
import {TransitionGroup, CSSTransition} from 'react-transition-group';

import crossfadeCss from './animations/crossfade.css';
import pushCss from './animations/push.css';
import popCss from './animations/pop.css';


export default function Pusher({
  children,
  ...props
}: {
  children: Array<React.Element<mixed>>,
}) {
  const refs = [
    React.useRef(),
    React.useRef(),
    React.useRef(),
  ];

  const [state, setState] = React.useState({
    children,
    transitionName: crossfadeCss,
  });

  const array = React.Children.toArray(children);
  const currentArray = React.Children.toArray(state.children);

  const keys = array.map(({key}) => key).join(',');
  const currentKeys = currentArray.map(({key}) => key).join(',');

  let crossfade =
    Math.abs(currentArray.length - array.length) > 1 ||
    currentArray.length === 0 ||
    array.length === 0;

  let push;
  if (!crossfade) {
    push = currentArray.length < array.length;
    currentArray.forEach((element, i) => {
      if (array[i] && array[i].key !== element.key) {
        crossfade = true;
      }
    });
  }

  let transitionName;
  if (crossfade) {
    transitionName = crossfadeCss;
  } else if (push) {
    transitionName = pushCss;
  } else {
    transitionName = popCss;
  }

  React.useEffect(() => {
    setState({
      children,
      transitionName,
    });
  }, [children]);

  const index = currentArray.length - 1;
  const element = currentArray[index];

  return (
    <TransitionGroup {...props}>
      {index >= 0 && (
        <CSSTransition
          key={element.key}
          classNames={
            keys === currentKeys ? state.transitionName : transitionName
          }
          timeout={200}
          nodeRef={refs[index]}
        >
          <div ref={refs[index]}>
            {element}
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}
