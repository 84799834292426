// @flow

import type {ThreadResponse, MessageStatus} from 'src/types/messages';
import type {ThreadList} from 'src/types/thread-lists';
import type {Broadcast} from 'src/types/broadcasts';
import {selectDefaultPhoneNumberSet} from 'src/selectors/chat';

import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment-timezone';

import {hasSent, isProcessing} from 'src/utils/broadcasts';
import {classify, getFullName} from 'src/utils';
import dates from 'src/utils/date-time';
import {useHistory} from 'src/rerouter';

import {getBroadcastBranches} from 'src/action-creators/broadcasts';
import {selectPopulatedThreadsByIds} from 'src/selectors/messages-v2';
import {getAllAgents} from 'src/selectors/accounts';

import FormattedPhone from 'src/components/lib/formatted-phone.jsx';
import {AutoTruncatedText} from 'src/components/lib/truncated-text/truncated-text.jsx';
import MouseTip from 'src/components/lib/mouse-tip';
import HoverButton from 'src/components/lib/hover-button';
import Expander from 'src/components/lib/expander/expander2.jsx';

import ChatLink from 'src/components/messaging/link.jsx';
import ClockIcon from 'src/images/icons/scheduling-icon.svg?noAttrs';
import ContactIcon from 'src/images/broadcasts/contact.svg';
import DeliveredIcon from 'src/images/broadcasts/delivered.svg';
import SentIcon from 'src/images/broadcasts/sent.svg';
import OptedOutIcon from 'src/images/broadcasts/opt-out.svg';
import BouncedIcon from 'src/images/broadcasts/bounced.svg';
import PencilIcon from 'src/images/icons/edit-icon.svg';
import HoverIconButton from 'src/components/lib/hover-icon-button';
import ResponsesIcon from 'src/images/icons/messages-icon.svg?noAttrs';
import GraphIcon from 'src/images/icons/graph-icon.svg?noAttrs';

import colorCss from 'src/styles/modules/colors.css';
import css from './scheduled-message.css';


const {colorClickable, colorGray6, colorGreenShadow, colorRedShadow} = colorCss;

type Props = {
  scheduledMessage: Broadcast,
  timezone: string,
  threadList?: ?ThreadList,
  isModal?: boolean,
};

function formatShortDateTime(dateTime) {
  const mDate = moment(dateTime);
  const yearFormat = dates.sameYear(dateTime) ? '' : '/YY';

  const formatString = `M/D${yearFormat} h:mma`;

  return mDate.format(formatString);
}

export default function ScheduledMessageView(props: Props): React.Node {
  const {scheduledMessage, timezone, isModal = false, threadList} = props;

  const threads = useSelector((state) =>
    threadList ? selectPopulatedThreadsByIds(state, threadList.threadIds) : {},
  );

  const [broadcastPane, setBroadcastPane] = React.useState<string>('');
  const dispatch = useDispatch();
  const broadcastId = scheduledMessage?.id;
  React.useEffect(() => {
    if (broadcastId) {
      dispatch(getBroadcastBranches(broadcastId));
    }
  }, [broadcastId]);

  const handleBroadcastActions = React.useCallback(
    (pane: string) => {
      if (broadcastPane !== pane) {
        setBroadcastPane(pane);
      } else {
        setBroadcastPane('');
      }
    },
    [setBroadcastPane, broadcastPane],
  );

  const {failureRate, replyRate} = scheduledMessage;
  const is1on1 = scheduledMessage.oneOnOne;
  const replies = scheduledMessage.replies;
  const branches = scheduledMessage.branches;
  const agentId = scheduledMessage.agentId;
  const createdBy = useSelector((state) => state.accounts?.data?.[agentId]);

  const dripMessage = branches?.find(() => true);
  const dripMessageSent = Boolean(dripMessage?.sentMessageCount);

  const editLocation = {
    pathname: `/messages/broadcasts/${scheduledMessage?.id}/edit`,
    state: {
      isModal,
    },
  };

  const sensePhone = useSelector(selectDefaultPhoneNumberSet).phone_numbers[0];

  return (
    <div className={css.container}>
      <div
        className={classify(css.main, {
          [css.broadcastPanel]: !isProcessing(scheduledMessage),
        })}
      >
        <div className={css.broadcastContainer}>
          <div className={css.broadcastSentBy}>
            {createdBy?.name ? `Sent by ${createdBy.name}` : ''}
          </div>
          <div className={css.bubbleContainer}>
            <MouseTip
              className={css.sentToolTip}
              contentClassName={css.sentToolTipContents}
              tipClassName={css.toolTipCaret}
              disable={!hasSent(scheduledMessage)}
              delay={200}
              arrowPosition={'center'}
              fixedTo={'top'}
              content={
                <BroadcastTime
                  className={css.sentToolTipTime}
                  sendDate={scheduledMessage?.sendDate}
                  timezone={timezone}
                  format={'MM/DD/YYYY h:mma'}
                  text={'Sent'}
                />
              }
            >
              <div className={is1on1 ? css.bubble1on1 : css.bubbleBroadcast}>
                <div>{scheduledMessage?.body}</div>
              </div>
            </MouseTip>
            {!isProcessing(scheduledMessage) && (
              <div
                className={classify(css.broadcastActionsContainer, {
                  [css.actionsContainerOpen]: Boolean(broadcastPane),
                })}
              >
                {!hasSent(scheduledMessage) && (
                  <div className={classify(css.broadcastActions)}>
                    <div className={css.dateAndStatusBroadcast}>
                      <ClockIcon className={css.clockIconBroadcast} />
                      <BroadcastTime
                        className={css.dateBroadcast}
                        sendDate={scheduledMessage?.sendDate}
                        timezone={timezone}
                      />
                      <ChatLink to={(editLocation: any)}>
                        <HoverIconButton>
                          <PencilIcon />
                        </HoverIconButton>
                      </ChatLink>
                    </div>
                  </div>
                )}
                {!isModal && !hasSent(scheduledMessage) && (
                  <hr className={css.rule} />
                )}
                {!isModal && (
                  <div className={css.broadcastActions}>
                    <BroadcastTabButton
                      name="recipients"
                      label="Recipients"
                      activePane={broadcastPane}
                      handleTabClick={handleBroadcastActions}
                    >
                      <ContactIcon />
                      {threads.length}
                    </BroadcastTabButton>
                    <BroadcastTabButton
                      name="responses"
                      label="Responses"
                      activePane={broadcastPane}
                      handleTabClick={handleBroadcastActions}
                    >
                      <ResponsesIcon />
                      {scheduledMessage?.replies?.length || 0}
                    </BroadcastTabButton>
                    <BroadcastTabButton
                      name="analytics"
                      label="Analytics"
                      activePane={broadcastPane}
                      handleTabClick={handleBroadcastActions}
                    >
                      <GraphIcon />
                    </BroadcastTabButton>
                  </div>
                )}
                <Expander>
                  {broadcastPane === 'recipients' && (
                    <div className={css.recipientsContainer}>
                      <hr className={css.rule} />
                      <div className={css.recipients}>
                        {threads.map((thread, index) => {
                          const person =
                            thread?.audienceMembers[0] || thread?.contact;
                          const name = person ? getFullName(person) : '-';
                          return (
                            <BroadcastRecipient
                              key={thread.id}
                              name={name}
                              phone={thread.externalPhone}
                              status={scheduledMessage?.statuses[thread.id]}
                              date={scheduledMessage?.sendDate}
                              timezone={timezone}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {broadcastPane === 'responses' && (
                    <div className={css.recipientsContainer}>
                      <hr className={css.rule} />
                      <div className={css.recipients}>
                        {!replies?.length ? (
                          <div className={css.replyEmptyText}>
                            No replies yet
                          </div>
                        ) : (
                          replies.map((response) => (
                            <BroadcastResponse
                              threads={threads}
                              response={response}
                              sensePhone={sensePhone}
                            />
                          ))
                        )}
                      </div>
                    </div>
                  )}
                  {broadcastPane === 'analytics' && (
                    <div className={css.recipientsContainer}>
                      <hr className={css.rule} />
                      <div className={css.recipients}>
                        <div className={css.recipientRow}>
                          <span>Reply Rate</span>
                          <span>{Math.round(replyRate * 100)}%</span>
                        </div>
                        <div className={css.recipientRow}>
                          <span>Failure Rate</span>
                          <span>{Math.round(failureRate * 100)}%</span>
                        </div>
                      </div>
                    </div>
                  )}
                </Expander>
              </div>
            )}
          </div>
          {branches && branches.length > 0 && (
            <>
              <div className={css.dripMessageConnector}>
                {
                  <div
                    className={
                      dripMessageSent ? css.circle : css.circlenotFilled
                    }
                  ></div>
                }
                <div className={css.line}></div>
                <div>
                  If candidate replies with affirmative keywords, send this
                  response.
                </div>
              </div>
              <div className={css.dripMessage}>
                <div>{dripMessage?.body}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function getNameForThread(thread) {
  const person = thread?.audienceMembers[0] || thread?.contact;
  const name = person ? getFullName(person) : '-';

  return name;
}

const BroadcastTabButton = ({
  name,
  handleTabClick,
  children,
  activePane,
  label,
}) => (
  <MouseTip
    className={css.broadcastTooltip}
    tipClassName={css.toolTipCaret}
    contentClassName={css.compressedToolTipContents}
    delay={200}
    arrowPosition={'center'}
    fixedTo={'top'}
    content={label}
  >
    <div
      className={classify(css.recipientsButton, {
        [css.recipientsButtonActive]: activePane === name,
      })}
      onClick={() => handleTabClick(name)}
    >
      {children}
    </div>
  </MouseTip>
);

const BroadcastRecipient = ({
  name,
  phone,
  status,
  date,
  timezone,
}: {
  name: string,
  phone: string,
  status: MessageStatus,
  date: string,
  timezone: string,
}) => {
  let icon;
  if (status === 'delivered' || status === 'received') {
    icon = <DeliveredIcon />;
  } else if (status === 'sent') {
    icon = <SentIcon />;
  } else if (status === 'skipped') {
    icon = <OptedOutIcon />;
  } else if (
    status === 'failed' ||
    status === 'skipped_for_not_in_ats' ||
    status === 'receiver_does_not_have_whatsapp'
  ) {
    icon = <BouncedIcon />;
  }
  return (
    <div className={css.recipientRow}>
      <div>
        <div className={css.avatarContainer}>{name[0]}</div>
        <div className={css.recipientText}>
          <AutoTruncatedText
            text={<span className={css.recipientName}>{name}</span>}
          />
          <span className={css.recipientPhoneNumber}>
            <FormattedPhone phone={phone} />
          </span>
        </div>
      </div>

      {Boolean(icon) && (
        <MouseTip
          className={css.statusToolTip}
          tipClassName={css.toolTipCaret}
          delay={200}
          arrowPosition={'end'}
          fixedTo={'top'}
          content={
            <BroadcastStatusToolTip
              status={status}
              date={date}
              timezone={timezone}
            />
          }
        >
          <div className={css.iconContainer}>{icon}</div>
        </MouseTip>
      )}
    </div>
  );
};

const BroadcastResponse = ({response, threads, sensePhone}) => {
  const router = useHistory();

  const {threadId, timeCreated} = response;
  const thread = threads.find((t) => t.id === threadId);

  if (!thread) {
    return null;
  }

  const {externalPhone} = thread;

  const threadLink = thread
    ? `/messages/with/${externalPhone}/from/${sensePhone}`
    : false;
  const name = getNameForThread(thread);
  const formattedDate = formatShortDateTime(timeCreated);
  const tooltipContent = `${name}, ${formattedDate}`;

  const responseText =
    (response?.media?.length || 0) > 0 && !response.body
      ? `${name} sent an image`
      : response.body;

  return (
    <HoverButton
      className={css.replyButton}
      onClick={() => {
        if (threadLink) {
          router.push(threadLink);
        }
      }}
    >
      <MouseTip
        className={css.broadcastTooltip}
        tipClassName={css.toolTipCaret}
        contentClassName={css.toolTipContentContainer}
        delay={200}
        arrowPosition={'center'}
        fixedTo={'top'}
        content={tooltipContent}
      >
        <div className={css.avatarContainer}>{name[0]}</div>
      </MouseTip>
      <div>
        <div className={css.bubbleBroadcastResponse}>
          <AutoTruncatedText useFragment text={<span>{responseText}</span>} />
        </div>
      </div>
    </HoverButton>
  );
};

const BroadcastTime = ({
  sendDate,
  timezone,
  className,
  format = 'ddd MMM D h:mma z',
  text = '',
}: {
  sendDate: string,
  timezone: string,
  className?: string,
  format?: string,
  text?: string,
}) => {
  const formattedTime = moment(sendDate).tz(timezone).format(format);
  const content = text ? text + ' ' + formattedTime : formattedTime;

  return <div className={classify(css.date, className)}>{content}</div>;
};

const statusColors = {
  queued: colorGray6,
  skipped: colorGray6,
  skipped_for_not_in_ats: colorRedShadow,
  failed: colorRedShadow,
  receiver_does_not_have_whatsapp: colorRedShadow,
  received: colorGreenShadow,
  delivered: colorGreenShadow,
  sent: colorClickable,
};

const BroadcastStatusToolTip = ({status, date, timezone}) => (
  <div className={css.toolTipContents}>
    <div
      className={css.toolTipStatusLabel}
      style={{color: statusColors[status]}}
    >
      {status === 'failed' || status === 'receiver_does_not_have_whatsapp'
        ? 'undelivered'
        : status === 'skipped_for_not_in_ats'
        ? 'not in ats'
        : status}
    </div>
    <BroadcastTime
      className={css.toolTipTime}
      sendDate={date}
      timezone={timezone}
      format={'MMM D h:mma'}
    />
    {['sent', 'delivered'].includes(status) &&
      `This message was confirmed as ${status} to the user's device.`}
    {status === 'received' &&
      `This message was confirmed as received by the user's device.`}
    {status === 'failed' &&
      `This message was undeliverable to the user's device.`}
    {status === 'skipped' &&
      `This message was not sent to the user who has opted out of receiving messages.`}
    {status === 'skipped_for_not_in_ats' &&
      `This message was not sent to the user since they don't have a synced ATS record.`}
    {status === 'receiver_does_not_have_whatsapp' &&
      'This message was undeliverable, because this number can not be found on WhatsApp.'}
  </div>
);
