// @flow strict
import type {
  GenericError,
  AgencyList,
  UserDetails,
  JobsData,
  EarningsData,
  PaymentDetails,
  EarningsGraphData,
  ReferralStats,
  RecentActivity,
} from 'src/types/referrer-portal';
import type {
  ReferralPrograms,
  GetReferralTrackingApiResponse,
  ReferralLeaderBoard,
  ReferralLinks,
  ReferralSelectedQuestionnaire,
} from 'src/types/referral-v2';
import type {JobDescription} from 'src/types/referral-external';
import type {AuthenticationActions} from 'src/action-creators/referrer-portal/authentication.js';
import {
  USER_AUTH,
  USER_SIGNIN,
  USER_SIGNIN_ERROR,
  USER_SIGNUP,
  USER_SIGNUP_ERROR,
  USER_RESET_PASSWORD_ERROR,
  RECEIVE_AGENCY,
  RECEIVE_AGENCY_ERROR,
  RECEIVE_USER_DETAILS,
  RECEIVE_USER_DETAILS_ERROR,
  RECEIVE_NOTIFICATION,
  RECEIVE_NOTIFICATION_ERROR,
} from 'src/action-creators/referrer-portal/authentication.js';
import {
  RECEIVE_ALL_REFERRALS,
  RECEIVE_ALL_REFERRALS_ERROR,
  RECEIVE_ALL_REFERRAL_PROGRAMS,
  RECEIVE_ALL_REFERRAL_PROGRAMS_ERROR,
} from 'src/action-creators/referrer-portal/tracking.js';
import {
  RECEIVE_JOB_DESCRIPTION,
  RECEIVE_JOB_DESCRIPTION_ERROR,
  RECEIVE_PROGRAM_QUESTIONS,
  RECEIVE_PROGRAM_QUESTIONS_ERROR,
  RECEIVE_REFERRAL_PROGRAMS,
  RECEIVE_REFERRAL_PROGRAMS_ERROR,
  RECEIVE_JOBS,
  RECEIVE_JOBS_ERROR,
  RECEIVE_JOB_LOCATIONS,
  RECEIVE_JOB_LOCATIONS_ERROR,
} from 'src/action-creators/referrer-portal/user-form.js';
import {
  RECEIVE_EARNINGS,
  RECEIVE_EARNINGS_ERROR,
  RECEIVE_PAYMENTS_DETAILS,
  RECEIVE_PAYMENTS_DETAILS_ERROR,
  RECEIVE_EARNINGS_GRAPH_DATA,
  RECEIVE_EARNINGS_GRAPH_DATA_ERROR,
} from 'src/action-creators/referrer-portal/earnings.js';

import {
  REFERRAL_STATS,
  REFERRAL_STATS_ERROR,
  REFERRAL_LEADERBOARD,
  REFERRAL_LEADERBOARD_ERROR,
  REFERRAL_SOCIAL_LINKS,
  REFERRAL_SOCIAL_LINKS_ERROR,
  REFERRAL_ACTIVITIES,
  REFERRAL_ACTIVITIES_ERROR,
} from 'src/action-creators/referrer-portal/dashboard.js';


export type ReferrerPortalState = {
  current_state: ?string,
  isAuthed: ?boolean,
  signin_error: ?string,
  signup_error: ?string,
  reset_password_error: ?string,
  agency_list: ?(AgencyList[]),
  agency_list_error: ?GenericError,
  selected_agency: ?number,
  selected_agency_error: ?GenericError,
  account: ?UserDetails,
  account_error: ?GenericError,
  referral_tracking_details: ?GetReferralTrackingApiResponse,
  referral_tracking_details_error: ?GenericError,
  notification: ?{[string]: boolean},
  notification_error: ?GenericError,
  agency_programs: ?(ReferralPrograms[]),
  agency_programs_error: ?GenericError,
  referral_programs: ?(ReferralPrograms[]),
  referral_programs_error: ?GenericError,
  jobs: ?(JobsData[]),
  jobs_error: ?GenericError,
  job_locations: ?(string[]),
  job_locations_error: ?GenericError,
  job_description: ?JobDescription,
  job_description_error: ?GenericError,
  earnings: ?EarningsData,
  earnings_error: ?GenericError,
  payment_details: ?(PaymentDetails[]),
  payment_details_error: ?GenericError,
  earnings_graph_data: ?EarningsGraphData,
  earnings_graph_data_error: ?GenericError,
  referral_stats: ?ReferralStats,
  referral_stats_error: ?GenericError,
  referral_leaderboard: ?(ReferralLeaderBoard[]),
  referral_leaderboard_error: ?GenericError,
  referral_social_links: ?ReferralLinks,
  referral_social_links_error: ?GenericError,
  referral_activities: ?(RecentActivity[]),
  referral_activities_error: ?GenericError,
  program_questions: ?(ReferralSelectedQuestionnaire[]),
  program_questions_error: ?GenericError,
};

const referrerPortalInitialState = {
  current_state: '',
  isAuthed: false,
  signin_error: null,
  signup_error: null,
  reset_password_error: null,
  agency_list: [],
  agency_list_error: null,
  selected_agency: null,
  selected_agency_error: null,
  account: null,
  account_error: null,
  referral_tracking_details: null,
  referral_tracking_details_error: null,
  notification: null,
  agency_programs: null,
  agency_programs_error: null,
  referral_programs: null,
  referral_programs_error: null,
  notification_error: null,
  jobs: null,
  jobs_error: null,
  job_locations: null,
  job_locations_error: null,
  job_description: null,
  job_description_error: null,
  earnings: null,
  payment_details: null,
  payment_details_error: null,
  earnings_error: null,
  earnings_graph_data: null,
  earnings_graph_data_error: null,
  referral_stats: null,
  referral_stats_error: null,
  referral_leaderboard: null,
  referral_leaderboard_error: null,
  referral_social_links: null,
  referral_social_links_error: null,
  referral_activities: null,
  referral_activities_error: null,
  program_questions: null,
  program_questions_error: null,
};

// authentication
export default (
  state: ReferrerPortalState = referrerPortalInitialState,
  action: AuthenticationActions,
): ReferrerPortalState => {
  switch (action.type) {
    case USER_AUTH: {
      return {
        ...state,
        isAuthed: action.payload,
      };
    }
    case USER_SIGNIN: {
      return {
        ...state,
        signin_error: null,
      };
    }
    case USER_SIGNIN_ERROR:
      return {
        ...state,
        signin_error: action.payload,
      };
    case USER_SIGNUP: {
      return {
        ...state,
        signup_error: null,
        // $FlowFixMe[prop-missing]
        account: {id: action.payload.user_id},
      };
    }
    case USER_SIGNUP_ERROR:
      return {
        ...state,
        signup_error: action.payload,
      };
    case USER_RESET_PASSWORD_ERROR:
      return {
        ...state,
        reset_password_error: action.payload,
      };
    case RECEIVE_AGENCY: {
      return {
        ...state,
        agency_list: action.payload,
      };
    }
    case RECEIVE_AGENCY_ERROR:
      return {
        ...state,
        agency_list_error: action.payload,
      };
    case RECEIVE_USER_DETAILS: {
      return {
        ...state,
        account: action.payload,
      };
    }
    case RECEIVE_USER_DETAILS_ERROR:
      return {
        ...state,
        account_error: action.payload,
      };
    case RECEIVE_ALL_REFERRALS: {
      return {
        ...state,
        referral_tracking_details: action.payload,
      };
    }
    case RECEIVE_ALL_REFERRALS_ERROR: {
      return {
        ...state,
        referral_tracking_details_error: action.payload,
        referral_tracking_details: null,
      };
    }
    case RECEIVE_NOTIFICATION: {
      return {
        ...state,
        notification: action.payload,
      };
    }
    case RECEIVE_NOTIFICATION_ERROR:
      return {
        ...state,
        notification_error: action.payload,
      };
    case RECEIVE_REFERRAL_PROGRAMS: {
      return {
        ...state,
        agency_programs: action.payload,
      };
    }
    case RECEIVE_REFERRAL_PROGRAMS_ERROR: {
      return {
        ...state,
        agency_programs_error: action.payload,
      };
    }
    case RECEIVE_JOBS: {
      return {
        ...state,
        jobs: action.payload,
      };
    }
    case RECEIVE_JOBS_ERROR: {
      return {
        ...state,
        jobs_error: action.payload,
        jobs: null,
      };
    }
    case RECEIVE_JOB_LOCATIONS: {
      return {
        ...state,
        job_locations: action.payload,
      };
    }
    case RECEIVE_JOB_LOCATIONS_ERROR: {
      return {
        ...state,
        job_locations_error: action.payload,
      };
    }
    case RECEIVE_JOB_DESCRIPTION: {
      return {
        ...state,
        job_description: action.payload,
      };
    }
    case RECEIVE_JOB_DESCRIPTION_ERROR: {
      return {
        ...state,
        job_description_error: action.payload,
        job_description: null,
      };
    }
    case RECEIVE_EARNINGS: {
      return {
        ...state,
        earnings: action.payload,
      };
    }
    case RECEIVE_EARNINGS_ERROR:
      return {
        ...state,
        earnings_error: action.payload,
      };
    case RECEIVE_PAYMENTS_DETAILS: {
      return {
        ...state,
        payment_details: action.payload,
        payment_details_error: null,
      };
    }
    case RECEIVE_PAYMENTS_DETAILS_ERROR:
      return {
        ...state,
        payment_details_error: action.payload,
      };
    case RECEIVE_EARNINGS_GRAPH_DATA: {
      return {
        ...state,
        earnings_graph_data: action.payload,
        earnings_graph_data_error: null,
      };
    }
    case RECEIVE_EARNINGS_GRAPH_DATA_ERROR:
      return {
        ...state,
        earnings_graph_data_error: action.payload,
      };
    case REFERRAL_STATS: {
      return {
        ...state,
        referral_stats: action.payload,
      };
    }
    case REFERRAL_STATS_ERROR:
      return {
        ...state,
        referral_stats_error: action.payload,
      };
    case REFERRAL_LEADERBOARD: {
      return {
        ...state,
        referral_leaderboard: action.payload,
      };
    }
    case REFERRAL_LEADERBOARD_ERROR:
      return {
        ...state,
        referral_leaderboard_error: action.payload,
      };
    case REFERRAL_SOCIAL_LINKS: {
      return {
        ...state,
        referral_social_links: action.payload,
      };
    }
    case REFERRAL_SOCIAL_LINKS_ERROR:
      return {
        ...state,
        referral_social_links_error: action.payload,
      };
    case REFERRAL_ACTIVITIES: {
      return {
        ...state,
        referral_activities: action.payload,
      };
    }
    case REFERRAL_ACTIVITIES_ERROR:
      return {
        ...state,
        referral_activities_error: action.payload,
      };
    case RECEIVE_PROGRAM_QUESTIONS: {
      return {
        ...state,
        program_questions: action.payload,
      };
    }
    case RECEIVE_PROGRAM_QUESTIONS_ERROR:
      return {
        ...state,
        program_questions_error: action.payload,
      };
    case RECEIVE_ALL_REFERRAL_PROGRAMS: {
      return {
        ...state,
        referral_programs: action.payload,
      };
    }
    case RECEIVE_ALL_REFERRAL_PROGRAMS_ERROR:
      return {
        ...state,
        referral_programs_error: action.payload,
        referral_programs: null,
      };
    default:
      return state;
  }
};
