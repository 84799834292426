/* @flow */

import type {DynamicLabelExtra} from 'src/action-creators/dynamic-labels';
import type {EntityType} from 'src/types/ats-entities';
// $FlowFixMe[untyped-type-import]
import type {Props as TokenListInputProps} from 'src/components/lib/token-list-input/token-list-input.old.jsx';

import * as React from 'react';

import {dedupBridgedDynamicLabels} from 'src/utils/dynamic-labels';
import classify from 'src/utils/classify';

import TokenListInput from 'src/components/lib/token-list-input/token-list-input.old.jsx';
import {MultiEntityLabel} from 'src/components/lib/markdown-editor/dynamic-text.jsx';
import PlaceholderPill from 'src/components/lib/markdown-editor/placeholder-pill.jsx';
import {VariablePickerDropdownRow} from 'src/components/lib/variable-picker/variable-picker-dropdown-row.jsx';

import css from './variable-picker.css';


function MultiEntityVariableSelect({
  className,
  containerClassName,
  baseEntityType,
  values = [],
  onChange,
  options,
  limit,
  ...props
}: {
  // $FlowFixMe[value-as-type] [v1.32.0]
  ...TokenListInputProps,
  options: DynamicLabelExtra[],
  baseEntityType: EntityType,
  value?: string,
}): React.Node {
  /*
  const dispatch = useDispatch();
  React.useEffect(() => {
    //
    dispatch(getAllEntities());
    dispatch(getAllEntityAttributes());
    dispatch(getGlobalVariables());
  }, [dispatch, getAllEntities, getAllEntityAttributes, getGlobalVariables]);
*/

  options = React.useMemo(
    () => Array.from(dedupBridgedDynamicLabels(options)),
    [options],
  );

  // Support a single 'value' prop for backward compatibility with Select
  const singleStringValue = 'value' in props;
  let onChangeOverride;

  if (singleStringValue) {
    const {value} = props;
    onChangeOverride = ([selection]) => {
      onChange(selection);
    };
    values = value?.length
      ? options.filter(({value: searchValue}) => value === searchValue)
      : [];
    limit = 1;
  }

  return (
    <TokenListInput
      {...props}
      containerClassName={classify(
        css.multiEntityVariableSelectContainer,
        containerClassName,
      )}
      optionsContainerClassName={css.multiEntityVariableSelectOptions}
      groupOptionsBy="mapping.display_name"
      sortOptionsBy={(a, b) =>
        (a.entityRelationshipPath?.[0] || a.name).localeCompare(
          b.entityRelationshipPath?.[0] || b.name,
          'en',
          {
            sensitivity: 'base',
          },
        )
      }
      options={options}
      values={values}
      limit={limit}
      onChange={onChangeOverride || onChange}
      TokenComponent={MultiEntityLabel}
      PlaceholderComponent={PlaceholderPill}
      Suggestion={VariablePickerDropdownRow}
      suggestionExtras={{entityType: baseEntityType}}
      searchOnEmptyString
      openOnFocus
    />
  );
}

export default MultiEntityVariableSelect;
