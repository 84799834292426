var React = require('react');

function WarningIcon (props) {
    return React.createElement("svg",props,[React.createElement("path",{"d":"M8 20.2c0-.11.09-.2.2-.2h5.6c.11 0 .2.09.2.2v.6a.2.2 0 01-.2.2H8.2a.2.2 0 01-.2-.2v-.6zM10.02 6.219c0-.125.063-.219.188-.219h1.596c.125 0 .188.094.188.219l-.484 5.619c0 .094-.094.156-.188.156h-.656c-.094 0-.188-.063-.188-.156l-.455-5.62zM11 15a1 1 0 100-2 1 1 0 000 2z","key":0}),React.createElement("path",{"fillRule":"evenodd","clipRule":"evenodd","d":"M12.289 1.75l7.5 13c.594 1-.156 2.25-1.281 2.25h-15c-1.156 0-1.875-1.25-1.313-2.25l7.5-13c.563-1 2.031-1 2.594 0zM3.508 16h15a.482.482 0 00.406-.75l-7.5-13a.497.497 0 00-.844 0l-7.5 13c-.187.344.032.75.438.75z","key":1})]);
}

WarningIcon.defaultProps = {"width":"22","height":"22","viewBox":"0 0 22 22"};

module.exports = WarningIcon;

WarningIcon.default = WarningIcon;
