// @flow
// $FlowFixMe[untyped-type-import]
import type {Agency} from 'src/api-parsers/index';

import * as React from 'react';
import {useDispatch} from 'react-redux';
import {Helmet} from 'react-helmet';

import logger from 'src/utils/logger';

import {Link} from 'src/rerouter';

import * as reduxApi from 'src/utils/redux-api-v2';

import Loading from 'src/components/lib/loading/loading.jsx';

import css from './auth.css';


const ForgotPassword = ({
  dynamicAgency,
  agency,
}: {
  dynamicAgency: boolean,
  // $FlowFixMe[value-as-type] [v1.32.0]
  agency: Agency,
}): React.Node => {
  const [state, setStateAll] = React.useState<{
    sending: boolean,
    sent: boolean,
    email: string,
  }>({
    sending: false,
    sent: false,
    email: '',
  });

  const setState = (newState) => setStateAll({...state, ...newState});
  const dispatch = useDispatch();
  const handleSubmit = React.useCallback(
    (evt) => {
      evt.preventDefault();
      const {email} = state;

      const postUrl = dynamicAgency
        ? 'accounts/me/forgot-password-no-agency'
        : 'accounts/me/forgot-password';

      dispatch(reduxApi.post(postUrl, {email})).then(
        () => setState({sent: true}),
        (err) => {
          if (
            err.response.message === 'this server isn\'t set up to send emails'
          ) {
            setState({sent: true});
          }
          logger.error(err.stack || err.response);
        },
      );
    },
    [state.email, dynamicAgency],
  );

  if (!agency) {
    return <Loading />;
  }

  const color = agency.brandingSettings?.color;
  const {sending, email, sent} = state;

  return (
    <form className={css.form} onSubmit={handleSubmit}>
      <Helmet title="Forgot Password" />
      {sent ? (
        <div className={css.form}>
          <p>
            An email has been sent to {email} and you will receive a password
            reset link if that is the address you signed up with.
          </p>
          <p>
            <Link className={css.link} to="/signin">
              Sign in
            </Link>
            .
          </p>
        </div>
      ) : (
        <div className={css.form}>
          <p>What email did you sign up with?</p>
          <input
            className={css.input}
            type="email"
            autoFocus={true}
            name="email"
            placeholder="Email"
            value={email}
            onChange={(evt) => setState({email: evt.target.value})}
          />
          <button
            className={css.button}
            disabled={sending}
            style={color && {backgroundColor: color, borderColor: color}}
          >
            {sending ? 'Sending...' : 'Reset Password'}
          </button>
          <span className={css.endnote}>
            Or{' '}
            <Link className={css.link} to="/signin">
              Sign In
            </Link>
          </span>
        </div>
      )}
    </form>
  );
};

export default ForgotPassword;
