// @noflow

import type {ExtensionMessage} from 'src/chat-extension/messages';

import * as React from 'react';

import nameHoc from 'src/flux/nameHoc';


const PortContext = React.createContext(null);
export default PortContext;

export const withPort = <Props: Object>(
  WrappedComponent: React.ComponentType<Props>,
): React.ComponentType<$Diff<Props, {port: ?window.chrome.runtime.Port}>> =>
    nameHoc(
      props => (
        <PortContext.Consumer>
          {port => <WrappedComponent {...props} port={port} />}
        </PortContext.Consumer>
      ),
      WrappedComponent,
      'withExtensionPort',
    );

class _PortComponent extends React.Component<{
  onMessage: ExtensionMessage => any,
  port: ?window.chrome.runtime.Port,
}> {
  componentDidMount() {
    this.listenToPort();
  }

  componentWillUnmount() {
    this.forgetPort();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.port && !this.props.port) {
      this.forgetPort(prevProps.port);
    } else if (!prevProps.port && this.props.port) {
      this.listenToPort();
    }
  }

  render() {
    return null;
  }

  listenToPort() {
    this.props.port &&
      this.props.port.onMessage.addListener(this.handlePortMessage);
  }

  forgetPort(port = this.props.port) {
    port && port.onMessage.removeListener(this.handlePortMessage);
  }

  _handlePortMessage(message: ExtensionMessage) {
    this.props.onMessage(message);
  }
  handlePortMessage = this._handlePortMessage.bind(this);
}

export const PortComponent = withPort(_PortComponent);
