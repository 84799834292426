// @noflow
import typeof IndexStore from 'src/stores/index';
import type {State as ReduxState, Dispatch} from 'src/reducers';
import type {Event} from 'src/api-parsers/events';
import type {Router} from 'src/types/router';
import type {PrototypeQuestions} from 'src/actions/event';
import type {Validation} from 'src/utils/validation';
import type {
  Question,
  QuestionType,
  ModuleEditorType,
  MultipleChoiceQuestion,
  Branch,
  RecipientResource,
} from 'src/types/survey';
import type {Workflow, WeekendMetadataKeys} from 'src/api-parsers/index';
import type {
  ScheduleDef,
  RecurringScheduleDef,
  RemindersDef,
  ReminderType,
} from 'src/stores/scheduling';
import type {JobVariables} from 'src/types/job-variables';

import sculpt, {assign} from 'sculpt';
import clamp from 'lodash/clamp';
import clone from 'lodash/clone';
import omit from 'lodash/omit';
import uniqueId from 'lodash/uniqueId';
import _set from 'lodash/set';
import _get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import flow from 'lodash/flow';
import invariant from 'invariant';
import logger from 'src/utils/logger';
import * as api from 'src/utils/api';
import * as urlFor from 'src/utils/url-for';
import {
  tempVarsInEvent,
  findModuleEntityVariablesInEvent,
  placeholderCountInEvent,
} from 'src/utils/sense-event';
import {findDynamicLabelsInString} from 'src/components/lib/markdown-editor/dynamic-text.jsx';
// $FlowIssue
import {convertFromRaw} from 'draft-js';
import parsers from 'src/api-parsers/events';

import {
  surveyLinkSmsToken,
  surveyLinkEmailToken,
  surveyContentToken,
  chatLinkToken,
} from 'src/components/workflow/event/constants';
import {SMS_MAX_CHARACTER_LIMIT} from 'src/components/workflow/event/content/module/editors/constants';
import {populateEventModules} from './event/populate';
import {key, fetching} from 'src/utils/action';
import {isDraftEvent} from 'src/utils/events';
import {JOB_MATCH_VARIABLE_REGEX} from 'src/utils/job-match';
import {EventTypeEnum} from 'src/stores/event';
import {DEFAULT_REMINDER} from 'src/stores/scheduling';
import {setError, hasError} from 'src/utils/validation';
import {
  validateScheduledSchedule,
  validateRecurringSchedule,
  validateBatchedSchedule,
  validateFieldChangeSchedule,
  validateBranchedSurveySchedule,
} from 'src/components/workflow/event/scheduling/validation';
import {textHasPlaceholder} from 'src/components/workflow/templates/utils';
import {createDynamicLabelSelector} from 'src/selectors/dynamic-labels';
import {
  selectJobVariableMap,
  selectJobVariables,
} from 'src/selectors/job-variables';
import {selectEnableDiscoverForEngage} from 'src/selectors/agency';
import {contains} from 'src/utils/set';

import {updateEvent as updateReduxEditEvent} from 'src/action-creators/event-creation';

import {getProgressTabsForNewEvent} from 'src/components/workflow/event/tabs.js';
import {selectSchedulingV2} from 'src/hooks/product-flags';
import {selectActiveTransactionalCategories} from 'src/selectors/content-subscription';

import {
  updateSchedulingEdit,
  updateSchedulingRemindersEdit,
  createEvent,
  endEdit,
  getEvent,
  getTemplate,
  updateEvent,
  updateTemplate,
  getEventWithBranches,
  questionPrototypes,
} from 'src/actions/event';
import {
  updateWorkflow,
  getWorkflow,
  updateModuleSchedule,
  removeEvent,
} from 'src/actions/workflow';
import {
  updateBranchEventSchedule,
  resolveBranchName,
  closeBranch,
} from 'src/actions/branch';
import {checkForString} from 'src/components/workflow/event/content/module/editors/beefree.jsx';


export type ModuleParentType = 'event' | 'branch';

// Assume workflow exists in store
export const beginEditingFlow = flow(
  key(() => 'begin-editing-flow'),
  fetching(),
)(
  // eslint-disable-next-line prefer-arrow-callback
  function beginEditingFlow(
    store: IndexStore,
    eventTitle: string,
    workflowId: string,
  ): Promise<?[Event, Workflow]> {
    const createNewEventPromise = createEvent(store, {
      title: eventTitle.trim(),
      content_subscription_category_id: null,
      content_subscription_reason: null,
    });
    const workflow = store.workflows.get(workflowId);

    const addEventToWorkflow = (newEvent: Event) =>
      updateWorkflow(
        store,
        assign(workflow, {
          eventsSchedule: workflow.eventsSchedule.concat([{id: newEvent.id}]),
        }),
      ).then(() => newEvent);

    const seedNewEventTemplate = (newEvent: Event) =>
      // we just want to have the newEvent's template so we can dupe
      // it safely when we beginEdit
      getTemplate(store, newEvent.id).then(() => newEvent);

    return createNewEventPromise
      .then(addEventToWorkflow)
      .then(seedNewEventTemplate)
      .then((newEvent) => {
        initEventCreation(store, newEvent);
        return [newEvent, workflow];
      });
  },
);

export const resumeEditingFlow = (
  dispatch: Dispatch,
  store: IndexStore,
  eventId: string,
  workflowId: string,
) => {
  const existingEditFlow = _get(store, ['eventCreation', 'state', 'event']);
  // NOTE(gab): This is for the case when a user navigates directly from one event page to
  // another* because the EventContainer component does not unmount in that case.
  // * How might a user do that? Goes to event 1, goes to menu, goes to event 2, uses the
  // browser back button to jump straight back to event 1.
  if (existingEditFlow && existingEditFlow.id !== eventId) {
    endEditingFlow(store);
  }

  return getEditEvent(store, eventId).then(() =>
    getEditSchedule(dispatch, store, eventId, workflowId),
  );
};

const initEventCreation = (store, event) => {
  const template = store.events.getTemplate(event.id);
  store.eventCreation.setState({
    event,
    template,
    question: null,
    branchInfo: null,
    saveError: null,
  });
};

export const endEditingFlow = (store: IndexStore) => {
  const {event} = store.eventCreation.state;
  invariant(
    event,
    'Attempted to end editing flow without a touchpoint object.',
  );

  store.scheduling.remove(event.id);
  endEdit(store);
};

export const deleteCurrentEvent = flow(
  key((workflowId) => `deleteCurrentEvent/${workflowId}`),
  fetching(),
)((store: IndexStore, workflowId: string): Promise<*> => {
  const {event} = store.eventCreation.state;

  return removeEvent(store, workflowId, event.id).then(() => {
    store.events.isEditing(false);
    store.events.setDeleted(event);
  });
});

export const getEditEvent = (
  store: IndexStore,
  eventId: string,
): Promise<?Event> => {
  const editableEvent = store.eventCreation.state.event;

  if (editableEvent) {
    return Promise.resolve(editableEvent);
  }

  return getEvent(store, eventId)
    .then(() => getTemplate(store, eventId))
    .then(() => {
      const event = store.events.getEvent(eventId);
      initEventCreation(store, event);
      return event;
    });
};

export const getEditSchedule = (
  dispatch: Function,
  store: IndexStore,
  eventId: string,
  workflowId: string,
): Promise<any> => {
  if (store.scheduling.get(eventId)) {
    return Promise.resolve(store.scheduling.getActiveSchedule(eventId));
  }
  return getWorkflow(store, dispatch, workflowId).then(() => {
    let schedule = store.workflows.getEventsSchedule(workflowId, eventId);
    const workflow = store.workflows.get(workflowId);
    if (workflow.branchedEventIds.indexOf(eventId) > -1) {
      const branch = store.branches.getBranchByEventId(eventId);
      if (branch.event && branch.event.schedule) {
        schedule = branch.event.schedule;
      }
    }

    store.scheduling.loadSchedule(
      schedule,
      eventId,
      store.workflows.isBranchedEvent(workflow.id, eventId),
    );
    return store.scheduling.getActiveSchedule(eventId);
  });
};

export function updateEditEvent(store: IndexStore, props: Object) {
  const previousEvent = store.eventCreation.state.event;

  store.eventCreation.updateState({
    event: {
      $assign: props,
    },
  });

  store.reduxStore.dispatch(updateReduxEditEvent({...previousEvent, ...props}));

  const {event} = store.eventCreation.state;

  // NOTE (kyle): changing the dynamicDeliveryAddress can possibly invalidate
  // the schedule. in a refactor we can be smarter about fixing the data,
  // but for now we just reset the whole schedule.
  const fields = store.reduxStore.getState().audienceMembers.fields;

  const previousField = fields[previousEvent.dynamicDeliveryAddress];
  const previousResource = previousField && previousField.resource;

  const field = fields[event.dynamicDeliveryAddress];
  const resource = field && field.resource;

  if (resource === 'placement' && previousResource !== resource) {
    store.scheduling.setDefaultState(store.eventCreation.state.event.id);
  }
}

export function updateEditEventBrandingSettings(
  store: IndexStore,
  props: Object,
) {
  store.eventCreation.updateState({
    event: {
      brandingSettings: {
        $assign: props,
      },
    },
  });
  store.reduxStore.dispatch(
    updateReduxEditEvent({
      ...store.eventCreation.state.event,
      brandingSettings: {
        ...(store.eventCreation.state.event.brandingSettings || {}),
        ...props,
      },
    }),
  );
}

export function updateRecipientResource(
  store: IndexStore,
  resource: RecipientResource,
) {
  store.eventCreation.updateState({
    event: {
      $merge: {
        dynamicDeliveryAddress: null,
        recipientResource: resource,
      },
    },
  });
  store.reduxStore.dispatch(
    updateReduxEditEvent({
      ...store.eventCreation.state.event,
      dynamicDeliveryAddress: null,
      recipientResource: resource,
    }),
  );
}

// eslint-disable-next-line max-params
export function sendTestSurvey(
  store: IndexStore,
  eventId: string,
  send_to: string,
  audienceMemberId: ?string,
  placementId: ?string,
  entityId?: string,
): Promise<*> {
  const temporaryEvent = store.eventCreation.state.event;
  const mergedTemporaryEvent = getEventWithBranches(store, temporaryEvent);
  const jsonBody = {
    // eslint-disable-next-line object-shorthand
    send_to: send_to,
    placement_id: placementId,
    temporary_event: {},
    audience_member_id: undefined,
    entity_id: entityId,
  };

  if (audienceMemberId) {
    jsonBody['audience_member_id'] = audienceMemberId;
  }

  if (mergedTemporaryEvent) {
    const appendJobMatchFields = selectEnableDiscoverForEngage(
      store.reduxStore.getState(),
    );
    jsonBody['temporary_event'] = parsers.normalize.event(
      mergedTemporaryEvent,
      appendJobMatchFields,
    );
    delete jsonBody['temporary_event'].id;
  }

  return api.post(store, `events/${eventId}/send/test-email`, jsonBody).then(
    (_res) => _res,
    (error) => {
      logger.error('survey sendSurvey error: ', error.stack || error);
      throw error;
    },
  );
}

// eslint-disable-next-line max-params
export function sendTestSMSSurvey(
  store: IndexStore,
  eventId: string,
  phoneNumber: string,
  withBranches: boolean = false, // for now we'll pass this bool in but
  // eventually the feature will be widely
  // deployed and this can check against
  // the event type in the store. --marcos
  audienceMemberId: ?string,
  placementId: ?string,
  entityId?: string,
): Promise<*> {
  const temporaryEvent = store.eventCreation.state.event;
  const mergedTemporaryEvent = withBranches
    ? getEventWithBranches(store, temporaryEvent)
    : temporaryEvent;

  const jsonBody = {
    phone_number: phoneNumber,
    placement_id: placementId,
    temporary_event: {},
    audience_member_id: undefined,
    entity_id: entityId,
  };

  if (audienceMemberId) {
    jsonBody['audience_member_id'] = audienceMemberId;
  }

  if (mergedTemporaryEvent) {
    const appendJobMatchFields = selectEnableDiscoverForEngage(
      store.reduxStore.getState(),
    );
    jsonBody['temporary_event'] = parsers.normalize.event(
      mergedTemporaryEvent,
      appendJobMatchFields,
    );
    delete jsonBody['temporary_event'].id;
  }

  return api.post(store, `events/${eventId}/send/test-sms`, jsonBody).then(
    (_res) => true,
    (error) => {
      logger.error('survey sendSMSSurvey error: ', error.stack || error);
      throw error;
    },
  );
}

// Note saveEvent does not catch any errors, you must take care of that yourself.
export const saveEvent = async (
  store: IndexStore,
  eventId: string,
  workflowId: string,
): Promise<mixed> => {
  const {template} = store.eventCreation.state;
  const {event} = store.eventCreation.state;

  const updatedSchedule = store.scheduling.getActiveSchedule(eventId); // returns scheduledef
  const updatedReminders = store.scheduling.getReminders(eventId);
  const workflow = store.workflows.get(workflowId);

  // NOTE (kyle): translate all fields that use the DraftInput
  /*
  const reduxState = store.reduxStore.getState();
  const multiEntityEnabled = selectMultiEntityEnabled(reduxState);
  if (multiEntityEnabled) {
    const dynamicLabels = selectDynamicLabelsForWorkflowId(
      reduxState,
      workflowId,
    );
    const labelMap = keyBy(dynamicLabels, 'name');

    ['brandingSettings.emailFromName', 'subject'].forEach(path => {
      const value = _get(event, path);
      if (value) {
        _set(event, path, normalizeDynamicLabelsInString(labelMap, value));
      }
    });
  }
  */

  const updatedEvent = await updateEvent(store, event);

  await updateTemplate(store, event, template);

  let result;
  // NOTE (kyle): do not save custom schedules
  if (updatedSchedule.type === 'custom') {
    result = true;
    // If the event belongs to a branch do not update the schedule in the workflow.
  } else if (!store.workflows.isBranchedEvent(workflow.id, eventId)) {
    result = await updateModuleSchedule(
      store,
      workflow,
      eventId,
      updatedSchedule,
      updatedReminders,
    );
  } else {
    result = await updateBranchEventSchedule(store, eventId, updatedSchedule);
  }

  // NOTE (kyle): make sure the editing event is identical to the newly
  // received event from the server.
  store.eventCreation.setState({
    event: updatedEvent,
  });

  return result;
};

export const lockEventType = async (
  store: IndexStore,
  eventId: string,
): Promise<void> => {
  const editEvent = store.eventCreation.state.event;

  invariant(
    editEvent.id === eventId,
    `lockEventType called on a touchpoint that wasn't being edited.`,
  );

  if (editEvent.typeLocked) {
    return true;
  }

  const updatedEvent = populateEventModules(store, editEvent);

  updatedEvent.typeLocked = true;

  /*
  // NOTE (kyle): chatbot events require a new conversation flow
  if (
    ['sms_chatbot', 'beefree_chatbot'].includes(updatedEvent.type) &&
    !updatedEvent.chatbot_flow_id
  ) {
    const conversationFlow = await store.reduxStore.dispatch(
      createFlow(updatedEvent.entityType),
    );
    updatedEvent.chatbot_flow_id = conversationFlow.id;
  }
  */

  await updateEvent(store, updatedEvent);

  const event = store.events.state.events[updatedEvent.id];
  const question = event.questions[0];
  store.eventCreation.setState({
    event,
    question,
  });
};

const navigateToErrorPage = (
  url: string,
  router: Router,
  error:
    | 'schedule'
    | 'basics'
    | 'event'
    | 'branch'
    | 'invalid_or_temporary_variables'
    | 'sms_character_limit_exceeded'
    | 'multiple_surveys'
    | 'missing_survey_modules'
    | 'missing_survey_block'
    | 'missing_chatbot_link'
    | 'missing_job_match_params',
) => {
  const isEventNew = isDraftEvent(router.location.pathname);
  if (error === 'schedule') {
    url += '/scheduling';
  } else if (error === 'basics') {
    url += '/basics';
  } else if (
    error === 'event' ||
    error === 'branch' ||
    error === 'missing_job_match_params' ||
    error === 'invalid_or_temporary_variables' ||
    error === 'sms_character_limit_exceeded'
  ) {
    url += '/content';
  } else if (error === 'missing_survey_modules') {
    url += '/content';
  } else if (error === 'missing_chatbot_link') {
    url += '/chatbot';
  } else if (error === 'multiple_surveys' || error === 'missing_survey_block') {
    url += '/email';
  } else {
    const currentTab = router.location.pathname.split('/').pop();

    url += `/${currentTab}`;
  }

  router.push(url);
};

function validateEventForTab(store, currentTab, schedule, editEvent) {
  let error;

  switch (currentTab) {
    case 'new':
      break;
    case 'basics':
      if (
        !isValidEventBasics(editEvent, store.reduxStore.getState()) ||
        !isValidEventTitle(editEvent)
      ) {
        error = 'basics';
      }
      break;
    case 'design':
    case 'email':
      if (!eventContainsImpliedSurveyModules(editEvent)) {
        error = 'missing_survey_modules';
      } else if (!eventContainsImpliedSurveyBlock(editEvent)) {
        error = 'missing_survey_block';
      } else if (!eventContainsOnlyOneSurvey(editEvent)) {
        error = 'multiple_surveys';
      }
      break;
    case 'content':
    case 'survey':
      if (store.eventCreation.state.questionError) {
        error = getQuestionErrorCode(store);
      } else if (
        !isValidEventBasics(editEvent, store.reduxStore.getState()) ||
        !isValidEventTitle(editEvent)
      ) {
        error = 'basics';
      } else if (!isValidEventContent(store, editEvent)) {
        error = 'event';
      } else if (
        !eventContentContainsNoBadVariables(
          store.reduxStore.getState(),
          editEvent,
        )
      ) {
        error = 'invalid_or_temporary_variables';
      } else if (!eventContainsImpliedSurveyModules(editEvent)) {
        error = 'missing_survey_modules';
      } else if (!eventContainsImpliedSurveyBlock(editEvent)) {
        error = 'missing_survey_block';
      } else if (!eventContainsOnlyOneSurvey(editEvent)) {
        error = 'multiple_surveys';
      }
      if (editEvent.type === EventTypeEnum.BeeFree_Chatbot) {
        if (!eventContainsSurveyBlock(editEvent, [chatLinkToken])) {
          error = 'missing_chatbot_link';
        }
      }
      if (eventContainsInvalidJobMatchParams(editEvent)) {
        error = 'missing_job_match_params';
      }
      break;
    case 'scheduling':
    default:
      error = validateEventAllTabs(store, schedule, editEvent);
      break;
  }

  return error;
}

export function validateEventAllTabs(store, schedule, editEvent) {
  // TODO (nilarnab): this should talk to reduxState in future
  //product flag to check if v2 scheduling is enabled
  const reduxState = store.reduxStore.getState();
  if (selectSchedulingV2(reduxState)) {
    if (
      validateV2Schedule(
        schedule,
        store.workflows.isBranchedEvent(editEvent.workflowId, editEvent.id),
      )
    ) {
      return 'schedule';
    }
  }
  let error;
  if (!isValidSchedule(schedule)) {
    error = 'schedule';
  } else if (
    !isValidEventBasics(editEvent, store.reduxStore.getState()) ||
    !isValidEventTitle(editEvent)
  ) {
    error = 'basics';
  } else if (store.eventCreation.state.questionError) {
    error = getQuestionErrorCode(store);
  } else if (!isValidEventContent(store, editEvent)) {
    error = 'event';
  } else if (
    !eventContentContainsNoBadVariables(store.reduxStore.getState(), editEvent)
  ) {
    error = 'invalid_or_temporary_variables';
  } else if (!eventContainsImpliedSurveyModules(editEvent)) {
    error = 'missing_survey_modules';
  } else if (!eventContainsImpliedSurveyBlock(editEvent)) {
    error = 'missing_survey_block';
  } else if (!eventContainsOnlyOneSurvey(editEvent)) {
    error = 'multiple_surveys';
  }
  if (editEvent.type === EventTypeEnum.BeeFree_Chatbot) {
    if (!eventContainsSurveyBlock(editEvent, [chatLinkToken])) {
      error = 'missing_chatbot_link';
    }
  }

  if (eventContainsInvalidJobMatchParams(editEvent)) {
    error = 'missing_job_match_params';
  }

  return error;
}

function getQuestionErrorCode(store) {
  if (
    store.eventCreation.state.question?.text?.length > SMS_MAX_CHARACTER_LIMIT
  ) {
    return 'sms_character_limit_exceeded';
  } else if (
    store.eventCreation.state.question?.alt_text?.length >
    SMS_MAX_CHARACTER_LIMIT
  ) {
    return 'alt_sms_character_limit_exceeded';
  } else {
    return 'event';
  }
}

export function getErrorTabs(store, schedule, editEvent) {
  // TODO (nilarnab): this should talk to reduxState in future
  //product flag to check if v2 scheduling is enabled
  const reduxState = store.reduxStore.getState();
  const errorTabs = new Set();
  if (selectSchedulingV2(reduxState)) {
    if (
      validateV2Schedule(
        schedule,
        store.workflows.isBranchedEvent(editEvent.workflowId, editEvent.id),
      )
    ) {
      errorTabs.add('scheduling');
    }
  }
  if (!isValidSchedule(schedule)) {
    errorTabs.add('scheduling');
  }
  if (
    !isValidEventBasics(editEvent, store.reduxStore.getState()) ||
    !isValidEventTitle(editEvent)
  ) {
    errorTabs.add('basics');
  }
  if (store.eventCreation.state.questionError) {
    errorTabs.add('content');
  }
  if (!isValidEventContent(store, editEvent)) {
    errorTabs.add('content');
  }
  if (
    !eventContentContainsNoBadVariables(store.reduxStore.getState(), editEvent)
  ) {
    errorTabs.add('content');
  }
  if (!eventContainsImpliedSurveyModules(editEvent)) {
    errorTabs.add('content');
  }
  if (!eventContainsImpliedSurveyBlock(editEvent)) {
    errorTabs.add('content');
  }
  if (!eventContainsOnlyOneSurvey(editEvent)) {
    errorTabs.add('content');
  }
  if (editEvent.type === EventTypeEnum.BeeFree_Chatbot) {
    if (!eventContainsSurveyBlock(editEvent, [chatLinkToken])) {
      errorTabs.add('content');
    }
  }

  return errorTabs;
}

function getNextTab(currentTab, event) {
  const tabs = getProgressTabsForNewEvent(event);
  const currentIndex = tabs.findIndex(
    (tab) => tab.url.replace('/', '') === currentTab,
  );
  const nextTab = currentIndex > -1 && tabs[currentIndex + 1];
  return nextTab?.url || '/basics';
}

export type FinalizeEventOptions = {
  workflowId: string,
  eventId: string,
  routeOnSave?: boolean,
  routeOnError?: boolean,
};
export const finalizeEvent = (
  store: IndexStore,
  router: Object,
  {workflowId, eventId, routeOnSave, routeOnError}: FinalizeEventOptions,
): Promise<boolean> => {
  invariant(
    !store.events.state.saving,
    'finalizeEvent was called while a touchpoint was being saved.',
  );
  const schedule = store.scheduling.getActiveSchedule(eventId);
  const editEvent = store.eventCreation.state.event;

  const isEventNew = isDraftEvent(router.location.pathname);
  const currentTab = router.location.pathname.split('/').pop();

  const error = isEventNew
    ? validateEventForTab(store, currentTab, schedule, editEvent)
    : validateEventAllTabs(store, schedule, editEvent);
  if (error) {
    store.eventCreation.setError(error);
    const url = urlFor.event(workflowId, eventId) + (isEventNew ? '/new' : '');
    navigateToErrorPage(url, router, error);

    return Promise.reject(new Error(error));
  }

  store.eventCreation.clearError();
  store.events.clearError();
  store.events.setState({saving: true});
  //Flush any open branches in event creation
  closeBranch(store);

  const promise = lockEventType(store, eventId)
    .then(() => saveEvent(store, eventId, workflowId))
    .then(() => true);

  return promise
    .then((result) => {
      store.events.setState({saving: false});
      if (result && routeOnSave && isEventNew) {
        const url =
          urlFor.event(workflowId, eventId) +
          `/new${getNextTab(currentTab, editEvent)}`;
        router.push(url);
      }
      return result;
    })
    .catch((error) => {
      store.events.setState({saving: false});
      error.response && store.events.setError(error.response);
      if (routeOnError) {
        logger.error(error);
        const saveError = store.eventCreation.state.saveError;
        const url =
          urlFor.event(workflowId, eventId) + (isEventNew ? '/new' : '');
        navigateToErrorPage(url, router, saveError);
      } else {
        throw error;
      }
    });
};

// Generic-ish actions called by forms to sculpt data in scheduling store
// eslint-disable-next-line max-params
export const handleScheduleChangeValue = (
  store: IndexStore,
  schedule: ScheduleDef,
  eventId: string,
  key: string,
  value: string | boolean | null | void,
) => {
  const spec = {
    [key]: {$set: value},
  };
  updateSchedulingEdit(store, sculpt(schedule, spec), eventId);
};

export const handleBlackoutWindowChangeValue = ({
  store,
  schedule,
  eventId,
  updateObject,
}: {
  store: IndexStore,
  schedule: ScheduleDef,
  eventId: string,
  updateObject: {[key: string]: boolean | {...}},
}) => {
  const spec = Object.keys(updateObject).reduce(
    (spec, key) => {
      spec.blackout_window = {
        ...spec.blackout_window,
        [key]: {$set: updateObject[key]},
      };
      return spec;
    },
    {blackout_window: {}},
  );
  updateSchedulingEdit(store, sculpt(schedule, spec), eventId);
};

export const updateSkipIfChange = (
  store: IndexStore,
  schedule: ScheduleDef,
  eventId: string,
  value: string,
) => {
  if (value === 'continue') {
    const {skipIf, ...newSchedule} = schedule;
    updateSchedulingEdit(store, newSchedule, eventId);
  } else {
    updateSchedulingEdit(
      store,
      sculpt(schedule, {
        skipIf: {
          $set: {
            respondedTo: value,
          },
        },
      }),
      eventId,
    );
  }
};

// Action to update more than one value at once, always sets
export const handleScheduleChangeMultipleValues = (
  store: IndexStore,
  schedule: ScheduleDef,
  eventId: string,
  setSpec: {[key: string]: string | boolean | void},
) => {
  const spec = mapValues(setSpec, (value) => ({$set: value}));
  updateSchedulingEdit(store, sculpt(schedule, spec), eventId);
};

// Generic action to update deeper data
export const handleScheduleDeepChangeValue = (
  store: IndexStore,
  schedule: ScheduleDef,
  eventId: string,
  key: string,
  deepKey: string,
  value: string,
) => {
  const spec = {
    [key]: {[deepKey]: {$set: value}},
  };
  updateSchedulingEdit(store, sculpt(schedule, spec), eventId);
};

export const handleScheduleDurationChange = (
  store: IndexStore,
  schedule: RecurringScheduleDef,
  eventId: string,
  newDuration: {
    indefinitely: boolean,
    untilDate?: string,
    maxInstances?: number,
  },
) => {
  const {indefinitely, untilDate, maxInstances} = newDuration;
  updateSchedulingEdit(
    store,
    {...schedule, indefinitely, untilDate, maxInstances},
    eventId,
  );
};

export const handleDayOfWeekChange = (
  store: IndexStore,
  schedule: RecurringScheduleDef,
  eventId: string,
  value: string,
) => {
  if (value !== undefined && value !== null) {
    const newDaysOfWeek = clone(schedule.daysOfWeek);
    newDaysOfWeek[parseInt(value)] = !newDaysOfWeek[parseInt(value)];
    const newSchedule = sculpt(schedule, {
      daysOfWeek: {$set: newDaysOfWeek},
    });
    updateSchedulingEdit(store, newSchedule, eventId);
  }
};

export function updateEventIfWeekend(
  store: IndexStore,
  schedule: ScheduleDef,
  eventId: string,
  weekendOption: ?WeekendMetadataKeys,
) {
  const weekendMetadata = weekendOption ? {send: weekendOption} : undefined;
  updateSchedulingEdit(
    store,
    sculpt(schedule, {
      ifWeekend: {
        $set: weekendMetadata,
      },
    }),
    eventId,
  );
}

export const handleNumericChange = (
  store: IndexStore,
  schedule: ScheduleDef,
  eventId: string,
  key: string,
  value: string | number,
) => {
  if (eventId) {
    const parsedVal = !value ? 0 : parseInt(value, 10);

    if (!isNaN(parsedVal) && parsedVal >= 0) {
      const spec = {
        [key]: {$set: parsedVal},
      };
      updateSchedulingEdit(store, sculpt(schedule, spec), eventId);
    }
  }
};

export const updateTimeType = (
  store: IndexStore,
  schedule: ScheduleDef,
  eventId: string,
  type: 'specific' | 'relative',
) => {
  const spec =
    type === 'specific'
      ? {$unset: 'relTime'}
      : {
          relTime: {
            $set: {
              field: null,
              value: {hour: 0},
              defaultTime: {
                hour: '540',
                period: '0',
              },
            },
          },
        };

  updateSchedulingEdit(store, sculpt(schedule, spec), eventId);
};
//new function since timeType and sendTilEod have been combined in the new flow.
export const updateTimeTypeV2 = (
  store: IndexStore,
  schedule: ScheduleDef,
  eventId: string,
  type: 'specific' | 'relative',
  sendTilEod: boolean,
) => {
  const spec =
    type === 'specific'
      ? {$unset: 'relTime', sendTilEod: {$set: sendTilEod}}
      : {
          relTime: {
            $set: {
              field: null,
              value: {hour: -1},
              defaultTime: {
                hour: '420',
                period: '0',
              },
            },
          },
        };

  updateSchedulingEdit(store, sculpt(schedule, spec), eventId);
};

export const updateRelativeTime = (
  store: IndexStore,
  schedule: ScheduleDef,
  eventId: string,
  spec: Object,
) => {
  const newSchedule = sculpt(schedule, {
    relTime: spec,
  });
  updateSchedulingEdit(store, newSchedule, eventId);
};

export const handleAddReminder = (
  store: IndexStore,
  reminders: RemindersDef,
  eventId: string,
) => {
  if (eventId) {
    const newDayReminders = clone(reminders);
    newDayReminders.push(DEFAULT_REMINDER);
    updateSchedulingRemindersEdit(store, newDayReminders, eventId);
  }
};

export const handleRemoveReminder = (
  store: IndexStore,
  reminders: RemindersDef,
  eventId: string,
  index: number,
) => {
  if (eventId) {
    const newDayReminders = clone(reminders);
    newDayReminders.splice(index, 1);
    updateSchedulingRemindersEdit(store, newDayReminders, eventId);
  }
};

export const handleChangeReminder = (
  store: IndexStore,
  reminders: RemindersDef,
  eventId: ?string,
  index: number,
  key: 'day' | 'type',
  value: number | ReminderType,
) => {
  if (!eventId) {
    return null;
  }

  const newDayReminders = clone(reminders);

  if (key === 'day') {
    const parsedVal = !value ? 0 : parseInt(value, 10);
    if (!isNaN(parsedVal) && parsedVal >= 0) {
      newDayReminders[index] = {...newDayReminders[index], day: parsedVal};
    }
  } else {
    newDayReminders[index] = {...newDayReminders[index], [key]: value};
  }

  updateSchedulingRemindersEdit(store, newDayReminders, eventId);
};
/** allow empty values in number input */
export const handleChangeReminderV2 = (
  store: IndexStore,
  reminders: RemindersDef,
  eventId: ?string,
  index: number,
  key: 'day' | 'type',
  value: number | '' | ReminderType,
) => {
  if (!eventId) {
    return null;
  }

  const newDayReminders = clone(reminders);
  newDayReminders[index] = {...newDayReminders[index], [key]: value};

  updateSchedulingRemindersEdit(store, newDayReminders, eventId);
};

export const updateScheduleRecurringRepeat = (
  store: IndexStore,
  schedule: ScheduleDef,
  value: string,
  eventId: string,
) => {
  schedule = sculpt(schedule, {
    $assign: {
      repeats: value,
      frequencyInterval: 1,
    },
  });
  updateSchedulingEdit(store, schedule, eventId);
};

/**
 * Module
 */
export function addModule(store: IndexStore, type: ModuleEditorType) {
  const {event} = store.eventCreation.state;
  invariant(
    event,
    'Attempted to add module while not currently editing an event.',
  );

  const newQuestion = {
    ...questionPrototypes(store)[type],
    id: uniqueId('new:'),
    isNew: true,
  };

  store.eventCreation.updateState({
    event: {
      questions: {
        $push: newQuestion,
      },
    },
    question: {
      $set: newQuestion,
    },
    questionError: {
      $set: null,
    },
  });

  return newQuestion;
}

export function duplicateModule(store: IndexStore) {
  const {event} = store.eventCreation.state;
  invariant(
    event,
    'Attempted to add module while not currently editing an event.',
  );
  const lastModule = event.questions[event.questions.length - 1];

  const newQuestion = {
    ...lastModule,
    id: uniqueId('new:'),
    isNew: true,
  };

  store.eventCreation.updateState({
    event: {
      questions: {
        $push: newQuestion,
      },
    },
    question: {
      $set: newQuestion,
    },
    questionError: {
      $set: null,
    },
  });

  return newQuestion;
}

export function editModule(
  store: IndexStore,
  questionId: string,
  parentType: ModuleParentType,
) {
  const {eventCreation} = store;
  const {state} = eventCreation;

  const {questions} =
    parentType === 'branch' ? state.branchInfo.branch : state.event;
  const question = questions.find((question) => question.id === questionId);

  eventCreation.setState({
    question,
    questionError: null,
  });
}

export function cancelModule(store: IndexStore, parentType: ModuleParentType) {
  const {question} = store.eventCreation.state;

  let spec = {
    question: {
      $set: null,
    },
  };

  if (question.isNew) {
    const questionsSpec = {
      questions: {
        $apply: (questions) => questions.filter(({id}) => id !== question.id),
      },
    };

    let parentSpec;
    if (parentType === 'branch') {
      parentSpec = {
        branchInfo: {
          branch: questionsSpec,
        },
      };
    } else {
      parentSpec = {
        event: questionsSpec,
      };
    }

    spec = {...spec, ...parentSpec};
  }

  store.eventCreation.updateState(spec);

  if (parentType === 'branch') {
    saveBranch(store);
  }
}

export function sculptModule(store: IndexStore, spec: Object) {
  store.eventCreation.updateState({
    question: spec,
  });

  // TODO (kyle): we could probably use the spec path to modify the error object,
  // but that would require lib changes to sculpt()
  const error = store.eventCreation.state.questionError;
  const reduxState = store.reduxStore.getState();
  const jobVariablesMap = selectJobVariableMap(reduxState);
  if (error) {
    store.eventCreation.setState({
      questionError: errorsForQuestion(
        store.eventCreation.state.question,
        store.eventCreation.state.event,
        jobVariablesMap,
      ),
    });
  }
}

export function adjustType(spec: Object) {
  if (!spec.hasOwnProperty('min') || !spec.hasOwnProperty('max')) {
    return spec;
  }
  return spec.min === 1 && spec.max === 1
    ? {...omit(spec, ['min', 'max']), type: 'multiple_choice_survey_question'}
    : {...spec, type: 'checkboxes_survey_question'};
}

export function updateModule(store: IndexStore, spec: Object) {
  sculptModule(store, {
    $assign: adjustType(spec),
  });
}

export function deleteModule(
  store: IndexStore,
  parentType: ModuleParentType,
  id: string,
) {
  const filterSpec = {
    questions: {
      $apply: (questions) => questions.filter((question) => question.id !== id),
    },
  };

  let parentSpec;
  if (parentType === 'branch') {
    parentSpec = {
      branchInfo: {
        branch: filterSpec,
      },
    };
  } else {
    parentSpec = {
      event: filterSpec,
    };
  }

  store.eventCreation.updateState({
    ...parentSpec,
    question: {
      $set: null,
    },
  });

  if (parentType === 'branch') {
    saveBranch(store);
  }
}

export function saveModule(store: IndexStore, parentType: ModuleParentType) {
  const {eventCreation} = store;
  let newQuestion = eventCreation.state.question;
  const oldQuestion = eventCreation.state.event.questions.find(
    (question) => question.id === newQuestion.id,
  );
  const reduxState = store.reduxStore.getState();
  const jobVariablesMap = selectJobVariableMap(reduxState);
  const error = errorsForQuestion(
    newQuestion,
    store.eventCreation.state.event,
    jobVariablesMap,
  );

  if (!error) {
    const isBranched = parentType === 'branch';

    newQuestion = {...newQuestion, isNew: false};

    if (!isBranched) {
      // updates to a module can affect branches and their conditions.
      newQuestion = fixModuleBranches(store, oldQuestion, newQuestion);
    }

    const questionsSpec = {
      questions: {
        $map: (question) =>
          question.id === newQuestion.id ? newQuestion : question,
      },
    };

    let parentSpec;
    if (isBranched) {
      parentSpec = {
        branchInfo: {
          branch: questionsSpec,
        },
      };
    } else {
      parentSpec = {
        event: questionsSpec,
      };
    }

    eventCreation.updateState({
      ...parentSpec,
      question: {
        $set: null,
      },
      questionError: {
        $set: null,
      },
    });

    if (isBranched) {
      saveBranch(store);
    }
  } else {
    eventCreation.setState({
      questionError: error,
    });
  }

  return {error};
}

function fixModuleBranches<Q: Question>(
  store: IndexStore,
  oldQuestion: Q,
  newQuestion: Q,
  error,
): Q {
  if (
    newQuestion.type === 'multiple_choice_survey_question' &&
    oldQuestion.type === 'multiple_choice_survey_question'
  ) {
    for (const branchId of newQuestion.branchIds) {
      const oldBranch = store.branches.getBranch(branchId.id);

      if (oldBranch) {
        let branch = oldBranch;

        for (const condition of branch.responseIn) {
          const oldChoice = oldQuestion.choices.find(
            (choice) => choice.value === condition,
          );

          invariant(
            oldChoice,
            'Attempting to save a branch condition that does not match a module choice.',
          );

          const newChoice = newQuestion.choices.find(
            ({id}) => id === oldChoice.id,
          );
          if (!newChoice) {
            // the corresponding choice was deleted.
            branch = {
              ...branch,
              responseIn: branch.responseIn.filter(
                (value) => value !== condition,
              ),
            };
          } else if (newChoice.value !== oldChoice.value) {
            // the corresponding choice was changed.
            branch = {
              ...branch,
              responseIn: branch.responseIn.map((value) =>
                value === condition ? newChoice.value : value,
              ),
            };
          }

          if (branch !== oldBranch) {
            // TODO (kyle): notify the user about this?
            if (branch.responseIn.length === 0) {
              // remove the branch entirely if none of its conditions
              // exist any longer.
              newQuestion = ({
                ...newQuestion,
                branchIds: newQuestion.branchIds.filter(
                  ({id}) => id !== branchId.id,
                ),
              }: MultipleChoiceQuestion);
            } else {
              // update the branch with the latest conditions.
              branch.name = resolveBranchName(branch);
              // TODO (kyle): figure out if we can batch these updates.
              store.branches.receiveBranch(branch);
            }
          }
        }
      }
    }
  }

  return newQuestion;
}

export function updateMultipleChoiceOption(
  store: IndexStore,
  index: number,
  text: string,
) {
  sculptModule(store, {
    choices: {
      [index]: {
        value: {
          $set: text,
        },
      },
    },
  });
}

export function updateListAttributeOption(
  store: IndexStore,
  index: number,
  text: string,
) {
  sculptModule(store, {
    attributesToInclude: {
      [index]: {
        $set: text,
      },
    },
  });
}

function clampMinMax({max, min}, numChoices) {
  const spec = {};

  if (max) {
    spec.max = {$set: clamp(max, 1, numChoices)};
  }

  if (min) {
    spec.min = {$set: clamp(min, 1, numChoices)};
  }

  return spec;
}

export function deleteMultipleChoiceOption(
  store: IndexStore,
  idToDelete: string,
) {
  const {question} = store.eventCreation.state;
  const newAlertChoices = question.alertChoices.filter(
    (id) => id !== idToDelete,
  );

  const spec = {
    choices: {
      $apply: (choices) => choices.filter(({id}) => id !== idToDelete),
    },
    alertChoices: {
      $set: newAlertChoices,
    },
  };

  sculptModule(
    store,
    question.type === 'checkboxes_survey_question' // make sure that the min and max do not exceed the number of options
      ? {...spec, ...clampMinMax(question, question.choices.length - 1)}
      : // multiple choice type has implicit max/min = 1
        spec,
  );
}

export function deleteAttributeListOption(
  store: IndexStore,
  indexToDelete: number,
) {
  sculptModule(store, {
    attributesToInclude: {
      $splice: [[indexToDelete, 1]],
    },
  });
}

export function addMultipleChoiceOption(store: IndexStore) {
  sculptModule(store, {
    choices: {
      $push: {
        id: uniqueId('choice_'),
        value: '',
      },
    },
  });
}

export function addListChoiceOption(store: IndexStore) {
  sculptModule(store, {
    attributesToInclude: {
      $push: '',
    },
  });
}

/**
 * Branch
 */

// Along with branch validation, feeds updated branchInfo from eventCreation
// back to the event store for later serializing/etc.
export function saveBranch(store: IndexStore) {
  // TODO (kyle): validation
  const {eventCreation} = store;
  const {editorBranch, branch, questionId} = eventCreation.state.branchInfo;
  const questionIndex = eventCreation.state.event.questions.findIndex(
    ({id}) => id === questionId,
  );
  const question = eventCreation.state.event.questions[questionIndex];

  const branchToSave = editorBranch || branch;

  if (!hasValidBranchCondition(question.type, branchToSave)) {
    store.eventCreation.updateState({
      branchInfo: {
        error: {
          $set: true,
        },
      },
    });
    return;
  }

  let branchIndex = question.branchIds.findIndex(
    ({id}) => branchToSave.id === id,
  );
  if (branchIndex < 0) {
    branchIndex = question.branchIds.length;
  }

  store.branches.receiveBranch(branchToSave);
  eventCreation.updateState({
    branchInfo: {
      $assign: {
        branch: branchToSave,
        editorBranch: null,
        isNew: false,
      },
    },
    event: {
      questions: {
        [questionIndex]: {
          branchIds: {
            [branchIndex]: {
              $set: {
                id: branchToSave.id,
                hasChanges: true,
              },
            },
          },
        },
      },
    },
  });
}

export function deleteBranch(store: IndexStore) {
  const {eventCreation} = store;
  const {event, branchInfo} = eventCreation.state;
  const {branch, questionId} = branchInfo;
  const questionIndex = event.questions.findIndex(({id}) => id === questionId);

  eventCreation.updateState({
    branchInfo: {
      $set: null,
    },
    event: {
      questions: {
        [questionIndex]: {
          branchIds: {
            $apply: (branchIds) => branchIds.filter(({id}) => id !== branch.id),
          },
        },
      },
    },
  });
}

export function updateBranchEvent(store: IndexStore, id: string) {
  store.eventCreation.updateState({
    branchInfo: {
      branch: {
        event: {
          id: {
            $set: id,
          },
        },
      },
    },
  });

  saveBranch(store);
}

export const isValidQuestion = (question: Question): boolean => {
  switch (question.type) {
    case 'nps_survey_question':
    case 'rating_scale_survey_question':
      return (
        !!question.question.trim() &&
        !!question.minText.trim() &&
        !!question.maxText.trim() &&
        hasValidBranches(question)
      );

    case 'multiple_choice_survey_question':
      return (
        !!question.question.trim() &&
        question.choices.every(({value}) => value.trim()) &&
        hasValidBranches(question)
      );

    // TODO(marcos): presumably use some beefree status code here to check
    // if a module is valid
    case 'beefree_module':
      return true;

    case 'text_survey_question':
    case 'calendar_date_survey_question':
      return !!question.question.trim();

    case 'sms_message_survey_module':
      return !!question.text.trim();

    case 'message_survey_module':
      if (question.rte_json && Array.isArray(question.rte_json.blocks)) {
        const blocks = question.rte_json.blocks;
        const hasAtomicBlocksWithEntities =
          blocks.filter(
            ({entityRanges = [], type = 'unstyled'}) =>
              entityRanges.length > 0 && type === 'atomic',
          ).length > 0;
        const assumedText = blocks
          .map(({text = ''}) => text)
          .join('')
          .trim();

        return hasAtomicBlocksWithEntities || assumedText.length > 0;
        // FIXME(marcos): events/branches when they're initialized can sometimes be deeply frozen and
        // will cause convertFromRaw to throw (although hard to repro this exactly)
        // const content = convertFromRaw(question.rte_json);
        // return content.hasText();
      } else if (question.text) {
        return !!question.text.trim();
      } else {
        return false;
      }

    case 'attribute_list_module':
      if (
        question.attributesToInclude.length === 0 ||
        question.attributesToInclude.filter((attr) => attr === '').length > 0
      ) {
        return false;
      }
      return true;

    default:
      return false;
  }
};

export function errorsForQuestion(
  question: Question,
  event: Event,
  jobVariablesMap?: JobVariablesMap = {},
): ?Object {
  let error = {};

  if (question.writeback && event.questions) {
    const writebackFields = event.questions
      .filter(
        ({writeback, id}) => writeback?.attribute_name && id !== question.id,
      )
      .map(({writeback}) => writeback.attribute_name);

    setError(
      error,
      !writebackFields.includes(question.writeback?.attribute_name),
      'writeback.attribute_name',
      'Please choose a unique ATS field for each question module that uses “save response”',
    );
  }

  switch (question.type) {
    case 'nps_survey_question':
    case 'rating_scale_survey_question':
      {
        const writebackEnabled = question.writeback?.enabled;
        setError(
          error,
          question.question.trim(),
          'question',
          'Question cannot be empty.',
        );
        setError(
          error,
          !writebackEnabled || question.writeback?.attribute_name,
          'writeback.attribute_name',
          'Please select an ATS variable',
        );
        setError(error, question.minText.trim(), 'minText', 'Cannot be empty.');
        setError(error, question.maxText.trim(), 'maxText', 'Cannot be empty.');
        setError(
          error,
          hasValidBranches(question),
          'branches',
          'Branches are invalid.',
        );
      }
      break;

    case 'multiple_choice_survey_question': {
      const choices = {};
      const uniqueErrorMessage = 'Must be unique.';
      const writebackEnabled = question.writeback?.enabled;

      setError(
        error,
        question.choices.length > 0,
        'question',
        'Please create at least one option',
      );

      setError(
        error,
        !writebackEnabled || question.writeback?.attribute_name,
        'writeback.attribute_name',
        'Please select an ATS variable',
      );

      setError(
        error,
        !writebackEnabled || question.writeback?.attribute_name,
        'question',
        'Please select an ATS variable',
      );

      setError(
        error,
        question.question.trim(),
        'question',
        'Question cannot be empty.',
      );

      setError(
        error,
        !question.allow_select_multiple ||
          (question.allow_select_multiple && !question.branchIds.length),
        'question',
        'Branching is not compatible with multi-select multiple choice questions currently.',
      );

      question.choices.forEach(({value: choice, id}, index) => {
        const trimmed = choice?.trim();
        const choiceText = choice && question.writeback?.choices_text?.[choice];
        if (writebackEnabled) {
          setError(
            error,
            choiceText,
            `choices[${id}].value`,
            'Cannot be empty.',
          );
        } else {
          setError(error, trimmed, `choices[${id}].value`, 'Cannot be empty.');
        }

        const choicePreviousOccurence = writebackEnabled
          ? choices[choiceText]
          : choices[choice];
        if (choicePreviousOccurence !== undefined) {
          const choicePreviousOccurenceFullPath = `choices[${choicePreviousOccurence}].value`;
          const hasUniqueError = hasError(
            error,
            choicePreviousOccurenceFullPath,
            uniqueErrorMessage,
          );
          setError(error, false, `choices[${id}].value`, uniqueErrorMessage);
          setError(
            error,
            hasUniqueError,
            choicePreviousOccurenceFullPath,
            uniqueErrorMessage,
          );
        }

        if (writebackEnabled) {
          choices[choiceText] = id;
        } else {
          choices[choice] = id;
        }
      });

      setError(
        error,
        hasValidBranches(question),
        'branches',
        'Branches are invalid.',
      );
      break;
    }

    case 'text_survey_question':
      const writebackEnabled = question.writeback?.enabled;

      setError(error, question.question.trim(), 'question', 'Cannot be empty.');
      setError(
        error,
        !writebackEnabled || question.writeback?.attribute_name,
        'writeback.attribute_name',
        'Please select an ATS variable',
      );
      setError(
        error,
        !writebackEnabled || question.writeback?.attribute_name,
        'question',
        'Please select an ATS variable',
      );
      break;

    case 'sms_message_survey_module':
      const smsText = question.text.trim();
      const smsAltText = question.alt_text?.trim();
      setError(error, smsText, 'text', 'Cannot be empty.');
      if (smsText.length > SMS_MAX_CHARACTER_LIMIT) {
        setError(error, false, 'text', 'SMS max character limit has exceeded.');
      }
      if (smsAltText?.length > SMS_MAX_CHARACTER_LIMIT) {
        setError(
          error,
          false,
          'altText',
          'Alternate SMS max character limit has exceeded.',
        );
      }
      if (event.type === EventTypeEnum.SMS_Survey) {
        const hasSurveyLink = smsText.includes(surveyLinkSmsToken);
        setError(
          error,
          hasSurveyLink,
          'text',
          'Linked surveys need a survey link',
        );
      }

      break;

    case 'message_survey_module':
      let hasText = true;
      let noSurveyLink = true;

      if (question.rte_json) {
        const content = convertFromRaw(question.rte_json);
        hasText = content.hasText();
        noSurveyLink = !content.getPlainText().includes(surveyLinkSmsToken);
      } else if (question.text) {
        hasText = question.text.trim();
        noSurveyLink = !question.text.includes(surveyLinkSmsToken);
      } else {
        setError(error, false, '', 'Must have text.');
      }

      setError(error, hasText, '', 'Must have text.');
      setError(
        error,
        noSurveyLink,
        '',
        'Only sms modules can include survey links',
      );

      break;

    case 'attribute_list_module':
      const attributes = {};
      question.attributesToInclude.forEach((attribute, index) => {
        if (attribute === '') {
          setError(
            error,
            false,
            `attributes[${index}]`,
            'Field cannot be empty.',
          );
        }
        attributes[attribute] = index;
      });

      break;

    case 'beefree_module':
      // const hasHtml = question.beefree_html.trim().length > 0;
      const hasJson = question.beefree_json !== null;
      setError(
        error,
        hasJson,
        'content',
        'Beefree editor did not update content',
      );

      break;

    default:
      setError(error, true, 'type', 'Invalid type.');
      break;
  }

  //jobVariablesMap.size > 0 means jobMatch is enabled in agency config
  if (jobVariablesMap.size > 0) {
    error = errorsForJobMatch(error, question, event, jobVariablesMap);
  }

  return Object.keys(error).length ? error : null;
}

export function errorsForJobMatch(
  error: {...},
  question: Question,
  event: Event,
  jobVariablesMap: JobVariablesMap,
): ?Object {
  if (
    ['message_survey_module', 'sms_message_survey_module'].includes(
      question.type,
    )
  ) {
    switch (question.type) {
      case 'sms_message_survey_module':
        //SM-59 (diwakersurya)
        if (question.includes_job_match_variables) {
          //check if text contains job match variables
          const text = question.text.trim();
          error = setError(
            error,
            textContainsJobVariables(text, jobVariablesMap),
            'text',
            'Text must contain job variables.',
          );
          if (question.send_alt_text) {
            const altText = question.alt_text.trim();
            error = setError(error, altText, 'altText', 'Cannot be empty.');
          }
        }
        break;
      case 'message_survey_module':
        //SM-59 (diwakersurya)
        let hasText = true;
        if (question.include_job_block) {
          if (question.job_match_rte_json) {
            const content = convertFromRaw(question.job_match_rte_json);
            hasText = content.hasText();
            error = setError(error, hasText, 'jobBlock', 'Must have text.');
            //check if contains at least one job variable
            const text = content.getPlainText().trim();
            error = setError(
              error,
              textContainsJobVariables(text, jobVariablesMap),
              'jobBlock',
              'Text must contain job variables',
            );
          } else {
            error = setError(error, false, 'jobBlock', 'Must have text.');
          }
          if (question.send_alt_text) {
            if (question.alt_rte_json) {
              const content = convertFromRaw(question.alt_rte_json);
              hasText = content.hasText();
              error = setError(error, hasText, 'altText', 'Must have text.');
            } else {
              error = setError(error, false, 'altText', 'Must have text.');
            }
          }
        }
        break;
      default:
        break;
    }
  }

  return error;
}
const textContainsJobVariables = (text: string, jobVariables: JobVariables) => {
  let result = false;
  //TODO:(diwakersurya) check if this can be improved
  const matches = [...text.matchAll(JOB_MATCH_VARIABLE_REGEX)].map(
    ([, matchText]) => matchText.trim(),
  );
  for (let i = 0; i < matches.length; i++) {
    if (jobVariables.has(matches[i])) {
      result = true;
      break;
    }
  }
  return result;
};

//Getting branches inside this function because isValidEvent is being used outside this file.
export const isValidEvent = (store: IndexStore, event: Event): boolean =>
  isValidEventContent(store, event) &&
  isValidEventBasics(event, store.reduxStore.getState()) &&
  isValidBeeEvent(event, store.reduxStore.getState());

export const isValidBeeEvent = (event, state): boolean =>
  eventContainsOnlyOneSurvey(event) &&
  eventContainsImpliedSurveyBlock(event) &&
  eventContainsImpliedSurveyModules(event) &&
  eventContentContainsNoBadVariables(state, event);

export const eventContainsSurveyBlock = (
  event: Event,
  tokens: string[] = surveyTokens,
): boolean => {
  if (event.beefreeJson) {
    const doc =
      typeof event.beefreeJson === 'object'
        ? JSON.stringify(event.beefreeJson)
        : event.beefreeJson;
    return (
      tokens.reduce((sum, token) => checkForString(doc, token) + sum, 0) > 0
    );
  }
};

const surveyTokens = [surveyContentToken, surveyLinkEmailToken];

// error out if survey content block appears but there are no survey questions
export const eventContainsImpliedSurveyModules = (event: Event): boolean => {
  if (event.beefreeJson) {
    if (eventContainsSurveyBlock(event) && event.questions.length === 0) {
      return false;
    }
  }
  return true;
};

// error out if questions are present but there is no survey content
export const eventContainsImpliedSurveyBlock = (event: Event): boolean => {
  if (event.beefreeJson) {
    if (event.questions.length > 0 && !eventContainsSurveyBlock(event)) {
      return false;
    }
  }
  return true;
};

export const eventContentContainsNoBadVariables = (
  state: ReduxState,
  event: Event,
): boolean => {
  const tempVars = tempVarsInEvent(event);
  const allVars = new Set(findModuleEntityVariablesInEvent(event));
  const placeholderCount = placeholderCountInEvent(event);
  if (placeholderCount > 0) {
    return false;
  }

  if (tempVars.size === 0 && allVars.size === 0) {
    return true;
  }

  const selectDynamicLabels = createDynamicLabelSelector();
  // create a Set that contains all the dynamic label fullyFormedNames
  const jobVars = selectJobVariables(state);
  const dynamicLabels = selectDynamicLabels(state, event.entityType);
  const legacyDynamicLabels = state.dynamicLabels;
  const validFullyFormedNames = new Set([
    ...dynamicLabels.map((f) => f.value?.toLowerCase()),
    ...dynamicLabels.map((f) => f.name?.toLowerCase()),
    ...dynamicLabels.map((f) => f.label?.toLowerCase()),
    ...legacyDynamicLabels.map((f) => f.value?.toLowerCase()),
    ...legacyDynamicLabels.map((f) => f.name?.toLowerCase()),
    ...legacyDynamicLabels.map((f) => f.label?.toLowerCase()),
    ...jobVars.map((f) => f.name?.toLowerCase()),
    ...jobVars.map((f) => f.value?.toLowerCase()),
    ...[
      'chat_link',
      'survey_link',
      'content',
      '{{chat_link}}',
      '{{survey_link}}',
      '{{content}}',
    ],
  ]);

  // only block if we find a possible variable that could also match as a
  // dynamic label
  if (
    [...tempVars].find(
      (str) => str && !validFullyFormedNames.has(str?.toLowerCase()),
    )
  ) {
    return false;
  }

  // if found variables doesn't match to actual variables
  if (
    !contains(
      validFullyFormedNames,
      [...allVars].map((x) => x?.toLowerCase()),
    )
  ) {
    return false;
  }

  return true;
};

export const hasNoBadVars = (state: ReduxState, text, entityType): boolean => {
  if (!text) {
    return true;
  }

  if (text && text.includes('placeholder:')) {
    // dynamicDeliveryAddress returned from API does not have angular braces
    // so <placeholder:regex> wont work
    return false;
  }

  const variables = findDynamicLabelsInString(text);
  const foundVars = new Set([...variables]);

  if (foundVars.size === 0) {
    return true;
  }

  const selectDynamicLabels = createDynamicLabelSelector();
  const dynamicLabels = selectDynamicLabels(state, entityType);
  const legacyDynamicLabels = state.dynamicLabels;
  // create a Set that contains all the dynamic label fullyFormedNames
  const validFullyFormedNames = new Set([
    ...dynamicLabels.map((f) => f.value?.toLowerCase()),
    ...dynamicLabels.map((f) => f.name?.toLowerCase()),
    ...dynamicLabels.map((f) => f?.label?.toLowerCase()),
    ...legacyDynamicLabels.map((f) => f.value?.toLowerCase()),
    ...legacyDynamicLabels.map((f) => f.name?.toLowerCase()),
    ...legacyDynamicLabels.map((f) => f?.label?.toLowerCase()),
  ]);

  if (
    [...foundVars].find(
      (str) => str && !validFullyFormedNames.has(str.toLowerCase()),
    )
  ) {
    return false;
  } else {
    return true;
  }
};

export const eventContainsOnlyOneSurvey = (event: Event): boolean => {
  if (event.beefreeJson) {
    const doc =
      typeof event.beefreeJson === 'object'
        ? JSON.stringify(event.beefreeJson)
        : event.beefreeJson;
    if (
      checkForString(doc, surveyContentToken) +
        checkForString(doc, surveyLinkEmailToken) >
      1
    ) {
      return false;
    }
  }
  return true;
};

export const eventContainsInvalidJobMatchParams = (event: Event): boolean => {
  for (const q of event.questions) {
    if (
      ((q.type === 'message_survey_module' && q.include_job_block) ||
        (q.type === 'sms_message_survey_module' &&
          q.includes_job_match_variables)) &&
      q.job_matches_count > 0
    ) {
      if (!q.job_matches_params) {
        return true;
      }
    }
  }
  return false;
};

export const isValidEventBasics = (event: Event, state: ReduxState): boolean =>
  isValidEmailEvent(event, state) && isValidSmsEvent(event, state);

const hasValidQuestionCount = (event) =>
  event.type === EventTypeEnum.BeeFree_Email ||
  event.type === EventTypeEnum.BeeFree_Chatbot ||
  (event.type === EventTypeEnum.SMS_Chatbot && event.questions.length === 0) ||
  event.questions.length > 0;

const isInvalidContentType = (
  state: ReduxState,
  subscriptionId: ?number,
  skipTransactionalFooter: ?boolean,
  subscriptionReason: ?string,
): boolean => {
  const transactionalCategories = selectActiveTransactionalCategories(state);
  if (subscriptionId) {
    return (
      subscriptionId === transactionalCategories[0].id &&
      !skipTransactionalFooter &&
      !subscriptionReason
    );
  }
  return true;
};

export const isValidEventContent = (
  store: IndexStore,
  event: Event,
): boolean => {
  const eventWithBranches = getEventWithBranches(store, event);

  return (
    hasValidQuestionCount(eventWithBranches) &&
    eventWithBranches.questions.every(isValidQuestion)
  );
};

export const isValidEmailEvent = (event: Event, state: ReduxState): boolean => {
  if (
    [
      EventTypeEnum.Message,
      EventTypeEnum.Survey,
      EventTypeEnum.List,
      EventTypeEnum.BeeFree_Email,
      EventTypeEnum.BeeFree_Chatbot,
    ].includes(event.type)
  ) {
    return (
      Boolean(event.subject) &&
      Boolean(event.brandingSettings?.emailFromAddress) &&
      Boolean(event.dynamicDeliveryAddress) &&
      hasNoBadVars(state, event.subject, event.entityType) &&
      hasNoBadVars(
        state,
        event?.brandingSettings?.emailFromAddress,
        event.entityType,
      ) &&
      hasNoBadVars(
        state,
        event?.brandingSettings?.emailCcList,
        event.entityType,
      ) &&
      hasNoBadVars(
        state,
        event?.brandingSettings?.emailBccList,
        event.entityType,
      ) &&
      hasNoBadVars(state, event.dynamicDeliveryAddress, event.entityType) &&
      hasNoBadVars(
        state,
        event?.brandingSettings?.emailFromAddress,
        event.entityType,
      ) &&
      !isInvalidContentType(
        state,
        event?.content_subscription_category_id,
        event?.skip_subscription_link,
        event?.content_subscription_reason,
      )
    );
  }
  return true;
};

export const isValidEventTitle = (event: Event): boolean =>
  !event.isTitleTemporary && event?.title.trim().length > 0;

export const isValidSmsEvent = (event: Event, state: ReduxState): boolean => {
  if (
    [
      EventTypeEnum.SMS_Message,
      EventTypeEnum.SMS_Survey,
      EventTypeEnum.SMS_Job,
      EventTypeEnum.SMS_NPS,
      EventTypeEnum.SMS_Chatbot,
    ].includes(event.type)
  ) {
    return (
      event.dynamicDeliveryAddress &&
      event.title &&
      hasNoBadVars(state, event.dynamicDeliveryAddress, event.entityType)
    );
  }
  return true;
};
export const isValidSchedule = (schedule: ScheduleDef): boolean => {
  if (schedule.type === 'recurring') {
    if (schedule.repeats === 'daily' && !schedule.frequencyInterval) {
      return false;
    }

    if (
      schedule.repeats === 'weekly' &&
      !schedule.daysOfWeek.some((day) => day === true)
    ) {
      return false;
    }

    const {relativeStartDate} = schedule;
    if (
      relativeStartDate.option === 'relative_date' &&
      !relativeStartDate.field
    ) {
      return false;
    }
  }

  if (schedule.type === 'fieldchange' && !schedule.fieldName) {
    return false;
  }

  if (schedule.type === 'scheduled') {
    if (schedule.relTime && !schedule.relTime.field) {
      return false;
    }
  }

  if (schedule.dateType === 'on') {
    if (!schedule.onField) {
      return false;
    }
  }

  return true;
};
export const validateV2Schedule = (
  schedule: ScheduleDef,
  isBranchedEvent: boolean,
): ?Validation => {
  let error;
  if (schedule.type === 'recurring') {
    const {
      repeats,
      daysOfWeek,
      untilDate,
      frequencyInterval,
      relativeStartDate: {
        option: relativeStartDateOption,
        field: relativeStartDateField,
      },
    } = schedule;
    error = validateRecurringSchedule({
      repeats,
      frequencyInterval,
      daysOfWeek,
      untilDate,
      relativeStartDateOption,
      relativeStartDateField,
    });
    if (error) {
      return error;
    }
  }
  if (schedule.type === 'campaigndaterange') {
    const {startDate, speedType, endDate, countPerDay, maxCountPerDay} =
      schedule;
    error = validateBatchedSchedule({
      date: startDate,
      speedType,
      endDate,
      countPerDay,
      maxCountPerDay,
    });
    if (error) {
      return error;
    }
  }
  if (
    schedule.type === 'fieldchange' &&
    (!schedule.fieldName || textHasPlaceholder(schedule.fieldName))
  ) {
    const {fieldName} = schedule;
    error = validateFieldChangeSchedule({fieldName});
    if (error) {
      return error;
    }
  }

  if (schedule.type === 'scheduled') {
    const {dateType, date, onField, relField, dowField, relTime} = schedule;
    const timeType = relTime ? 'relative' : 'specific';
    const relativeField = relTime?.field;
    error = isBranchedEvent
      ? validateBranchedSurveySchedule({dateType, date})
      : validateScheduledSchedule({
          dateType,
          date,
          onField,
          relField,
          dowField,
          timeType,
          relativeField,
        });
    if (error) {
      return error;
    }
  }

  return error;
};

export const hasValidBranches = (question: Question): boolean =>
  question.branches
    ? question.branches.every(
        // branches can be undefined because events store can get updated while
        // branches are still being parsed. When branch store gets updated
        // this flow should start again
        (branch) => !branch || isValidBranch(question.type, branch),
      )
    : true;

export const isValidBranch = (
  questionType: string,
  branch: Branch,
  validateModules: ?boolean = true,
): boolean => {
  const hasBranchName = !!branch.name;
  switch (branch.branchType) {
    case 'event_branch':
      return (
        hasBranchName &&
        hasValidBranchCondition(questionType, branch) &&
        !!branch.event
      );
    case 'module_branch':
      return (
        hasBranchName &&
        hasValidBranchCondition(questionType, branch) &&
        (validateModules ? branch.questions.every(isValidQuestion) : true)
      );
    default:
      return false;
  }
};

export const hasValidBranchCondition = (
  questionType: string,
  branch: Branch,
): boolean => {
  switch (questionType) {
    case 'nps_survey_question':
    case 'rating_scale_survey_question':
      return !!branch.responseLte || !!branch.responseGte;
    case 'multiple_choice_survey_question':
      let isValid = true;
      branch.responseIn.forEach((val) => {
        if (!val) {
          isValid = false;
        }
      });
      return isValid;
    default:
      return false;
  }
};

export const validateBranch = (
  store: IndexStore,
  questionType: string,
  branch: Branch,
): boolean => {
  const saveError = store.eventCreation.state.saveError;
  //Need to validate only settings here.
  if (!isValidBranch(questionType, branch, false)) {
    store.eventCreation.setError('branch');
    return false;
  } else if (saveError === 'branch') {
    store.eventCreation.clearError();
  }
  return true;
};

export const updateTemplateForEvent = (
  store: IndexStore,
  eventId: string,
  templateId: string,
): void => {
  const templateForId = store.eventTemplates.get(templateId);
  store.eventCreation.setTemplate(templateForId);
};

export const createBranchEvent = async (
  store: IndexStore,
  title: string,
  workflow: Workflow,
): Promise<Event> => {
  //default enabled=true for branched survey touchpoint ENGAGE-5259
  let newEvent = await createEvent(store, {
    title: title.trim(),
    enabled: true,
    content_subscription_category_id: null,
    content_subscription_reason: null,
  });

  newEvent = {
    ...newEvent,
    type: EventTypeEnum.Survey,
    typeLocked: true,
  };

  await updateEvent(store, newEvent);

  store.workflows.update(
    sculpt(workflow, {
      branchedEventIds: {
        $push: [newEvent.id],
      },
    }),
  );

  await getTemplate(store, newEvent.id);

  return newEvent;
};
