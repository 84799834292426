// @flow strict

let requestPolyfill: (
  callback: ({
    didTimeout: boolean,
    timeRemaining: () => number,
    ...
  }) => void,
  options?: {
    timeout: number,
    ...
  },
) => IdleCallbackID;
let cancelPolyfill: (id: IdleCallbackID) => void;

if (typeof requestIdleCallback === 'function') {
  requestPolyfill = requestIdleCallback;
  cancelPolyfill = cancelIdleCallback;
} else {
  requestPolyfill = (
    callback: ({
      didTimeout: boolean,
      timeRemaining: () => number,
      ...
    }) => mixed,
    options?: {
      timeout: number,
      ...
    },
  ) => {
    const timeout = options?.timeout || 5000;
    // $FlowFixMe polyfill
    return setTimeout(() => {
      callback({
        didTimeout: true,
        timeRemaining: () => 0,
      });
    }, timeout);
  };

  // $FlowFixMe polyfill
  cancelPolyfill = clearTimeout;
}

export {
  requestPolyfill as requestIdleCallback,
  cancelPolyfill as cancelIdleCallback,
};
