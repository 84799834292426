var React = require('react');

function I (props) {
    return React.createElement("svg",props,React.createElement("g",{"fillRule":"evenodd"},[React.createElement("path",{"d":"M10 19c-4.8 0-8.7-3.9-8.7-8.7 0-4.8 3.9-8.6 8.7-8.6 4.8 0 8.7 3.9 8.7 8.7 0 4.8-3.9 8.6-8.7 8.6zm0-15.3a6.7 6.7 0 100 13.4 6.7 6.7 0 000-13.4z","key":0}),React.createElement("path",{"d":"M8.8 7.2c0-.7.5-1.2 1.2-1.2s1.2.5 1.2 1.2-.5 1.2-1.2 1.2-1.2-.5-1.2-1.2zm.1 7.4V9.4H11v5.3H8.9v-.1z","key":1})]));
}

I.defaultProps = {"width":"20","height":"20","viewBox":"0 0 20 20"};

module.exports = I;

I.default = I;
