// @flow

import * as React from 'react';

import {
  default as BaseButton,
  type Props as ButtonProps,
} from '@spaced-out/ui-lib/lib/button/button';
import makeClassNameComponent from 'src/utils/makeClassNameComponent';

import HoverIconButton from 'src/components/lib/hover-icon-button';
import CancelIcon from 'src/images/icons/cancel-icon.svg?noAttrs';
import ConfirmIcon from 'src/images/icons/confirm-icon.svg?noAttrs';
import WarningIcon from 'src/images/icons/warning-icon.svg?noAttrs';

import css from './base-modal.css';


export const Container: React.AbstractComponent<
  React.ElementConfig<empty>,
> = makeClassNameComponent(css.titleBarContainer);
export const Content: React.AbstractComponent<
  React.ElementConfig<empty>,
> = makeClassNameComponent(css.container);
export const TextContent: React.AbstractComponent<
  React.ElementConfig<empty>,
> = makeClassNameComponent(css.textContent);
export const Buttons: React.AbstractComponent<
  React.ElementConfig<empty>,
> = makeClassNameComponent(css.buttonMenu);

export function Button(props: ButtonProps): React.Node {
  return <BaseButton {...props} />;
}

export function SuccessTitleBar({
  children,
  onClose,
}: {
  children: string,
  onClose?: ?(SyntheticEvent<>) => mixed,
}): React.Node {
  return (
    <TitleBar
      title={children}
      icon={<ConfirmIcon style={{fill: css.colorWhite}} />}
      color={css.modalGreen}
      onClose={onClose}
    />
  );
}

export function WarningTitleBar({
  children,
  onClose,
}: {
  children: string,
  onClose: ?(SyntheticEvent<>) => mixed,
}): React.Node {
  return (
    <TitleBar
      title={children}
      icon={<WarningIcon style={{fill: css.colorWhite}} />}
      color={css.modalOrange}
      onClose={onClose}
    />
  );
}

export function TitleBar({
  title,
  icon,
  color,
  onClose,
}: {
  title: string,
  icon: ?React.Node,
  color: string,
  onClose?: ?(SyntheticEvent<>) => mixed,
}): React.Element<'div'> {
  return (
    <div className={css.titleBar} style={{backgroundColor: color}}>
      <div className={css.titleAndIcon}>
        <span className={css.titleIcon}>{icon}</span>
        <div className={css.title}>{title}</div>
      </div>
      {onClose && (
        <HoverIconButton className={css.closeXHoverButton}>
          <CancelIcon className={css.closeXIcon} onClick={onClose} />
        </HoverIconButton>
      )}
    </div>
  );
}
