// @flow

import type {ErrorMap} from 'src/types/redux';
import type {
  Contact,
  ApiContact,
  ContactFormData,
  SuggestionType,
  VirtualContact,
} from 'src/types/contacts';

import omit from 'lodash/omit';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';

import {validateIn} from 'src/utils';
import {normalizePhone} from 'src/utils/phone';


export const getPrimaryPhone = (contact: Contact): ?string => {
  const primaryPhoneNumber = contact.phoneNumbers.find(({primary}) => primary);
  return primaryPhoneNumber && primaryPhoneNumber.value;
};

const validString = (str) => Boolean(typeof str === 'string' && str.trim());
const validatePhone = ({value}) =>
  !validString(value) && 'Invalid phone number';
const validateEmail = ({address}: {address: string}) =>
  !validString(address) && 'Invalid email address';

const validatePhones = (phones) => {
  const errors = {};

  if (!phones || phones.length === 0) {
    errors.errors = ['Must provide at least one phone number'];
  } else {
    if (!phones.some((phone) => phone.primary)) {
      errors.errors = ['Must mark one phone number as primary'];
    }

    phones.forEach((_, index) =>
      Object.assign(errors, validateIn(phones, index, validatePhone)),
    );
  }

  if (Object.keys(errors).length > 0) {
    return errors;
  }
};

const validateEmails = (emails) => {
  if (!emails || emails.length === 0) {
    return;
  }

  const errors = {};

  const onlyOneEmail = emails.length === 1;
  const singleAddress = emails[0].address;
  const singleAddressIsEmpty =
    !singleAddress || singleAddress.trim().length === 0;
  const noNeedToValidateEmails = onlyOneEmail && singleAddressIsEmpty;

  if (noNeedToValidateEmails) {
    return;
  }

  if (!emails.some((email) => email.primary)) {
    errors.errors = ['Must mark one email address as primary'];
  }

  emails.forEach((_, index) =>
    Object.assign(errors, validateIn(emails, index, validateEmail)),
  );

  if (Object.keys(errors).length > 0) {
    return errors;
  }
};

export const validateContactForm = (
  formData: ContactFormData,
): ErrorMap | void => {
  const errors = {};

  Object.assign(
    errors,
    validateIn(
      formData,
      'firstName',
      (firstName) => validString(firstName) || 'Must provide a first name',
    ),
  );

  Object.assign(
    errors,
    validateIn(
      formData,
      'lastName',
      (lastName) => validString(lastName) || 'Must provide a last name',
    ),
  );

  Object.assign(errors, validateIn(formData, 'phoneNumbers', validatePhones));

  Object.assign(errors, validateIn(formData, 'emails', validateEmails));

  if (Object.values(errors).some((field) => field !== true)) {
    return errors;
  }
};

export const pack = (formData: ContactFormData): ApiContact => {
  const sortPrimaryFirst = (a, b) => {
    if (a.primary) {
      return -1;
    }

    if (b.primary) {
      return 1;
    }

    return 0;
  };

  const contact = cloneDeep(omit(formData, ['audienceMemberId']));

  contact.phones.sort(sortPrimaryFirst);
  contact.phone = contact.phones.shift().number;
  if (contact.phones.length > 0) {
    contact.phones.forEach((phone) => {
      delete phone.id;
      delete phone.primary;
    });
    contact.additionalPhones = contact.phones;
  }

  if (contact.emails.length > 0) {
    contact.emails.sort(sortPrimaryFirst);
    contact.email = contact.emails.shift().address;

    if (contact.email.trim().length === 0) {
      delete contact.email;
    }

    if (contact.emails.length > 0) {
      contact.emails.forEach((email) => {
        delete email.id;
        delete email.primary;
      });
      contact.additionalEmails = contact.emails;
    }
  }

  delete contact.phones;
  delete contact.emails;

  return contact;
};

export const getVirtualContact = (
  suggestion: SuggestionType | string,
): VirtualContact =>
  suggestion.atsContact ||
  suggestion.contact ||
  suggestion.audienceMember ||
  (suggestion.threadListSuggestion && suggestion.threadListSuggestion);

export const getVirtualContactPhoneNumber = (
  contact: SuggestionType | string,
): ?string => {
  const person = getVirtualContact(contact) || contact;
  // $FlowFixMe
  return normalizePhone(person.phone || person.phoneNumber || person);
};

export const getVirtualContactPhoneNumberFromToken = (token: any): ?string => {
  return (
    token?.sourceObject?.contact?.phone ||
    token?.sourceObject?.audienceMember?.phoneNumber ||
    token?.sourceObject?.atsContact?.phoneNumber ||
    token?.value
  );
};
