// @flow strict

import * as React from 'react';

import {classify} from 'src/utils/classify';
import {SearchInput} from 'src/designSystem2021Components/search-input/search-input.jsx';
import {
  type OptionProps,
  Options,
} from 'src/designSystem2021Components/menu/options.jsx';
// $FlowFixMe[nonstrict-import]
import ClickAway from 'src/components/lib/click-away';

import css from './async-search.css';


export type AsyncSearchProps<V> = {
  classNames?: {container?: string, options?: string, input?: string},
  resolveOptions?: (query: string) => Promise<Array<V>>,
  defaultOptions?: Array<V>,
  resolveLabel?: (V) => string,
  resolveValue?: (V) => string,
  resolveIcon?: (V) => React.Node,
  onSelect: (value: V | {label: string, value: string}) => mixed,
  anchorPosition?: 'top' | 'bottom',
  OptionComponent?: React.ComponentType<OptionProps<V>>,
  allowArbitraryValues?: boolean,
  disabled?: boolean,
  multiSearchEnabled?: boolean,
  value?: string,
  placeholder?: string,
  /** NOTE:(diwakersurya) onPaste is not something that this component should handle,
   *  it should just provide an event which the user of component can handle.
   */
  onPaste?: (e: ClipboardEvent) => mixed,
};
export const AsyncSearch = <V: {label: string, value: string, ...}>({
  classNames,
  resolveOptions = (query) => Promise.resolve([]),
  resolveLabel = (item) => item.label,
  resolveValue = (item) => item.value,
  resolveIcon = (item) => null,
  defaultOptions,
  onSelect,
  anchorPosition = 'top',
  OptionComponent,
  allowArbitraryValues = false,
  disabled = false,
  onPaste,
  multiSearchEnabled = true,
  value = '',
  placeholder,
}: AsyncSearchProps<V>): React.Node => {
  const [query, setQuery] = React.useState(value);
  const [options, setOptions] = React.useState(defaultOptions ?? []);
  const [clearIcon, setClearIcon] = React.useState(value ? true : false);

  const handleOptions = async (searchText) => {
    const options = await resolveOptions(searchText);
    setOptions(options);
  };

  const handleOnChange = (searchText, onOpen) => {
    setQuery(searchText);
    if (!multiSearchEnabled) {
      onOpen();
    }
    handleOptions(searchText);
  };

  return (
    <ClickAway>
      {({isOpen, onOpen, height, cancelNext, anchorRef, pageBottom}) => (
        <div
          className={classify(css.container, classNames?.container)}
          onClick={(e) => {
            e.preventDefault();
            onOpen();
          }}
          ref={anchorRef}
        >
          <SearchInput
            value={query}
            onChange={(searchText) => handleOnChange(searchText, onOpen)}
            className={classNames?.input}
            disabled={disabled}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && allowArbitraryValues) {
                onSelect({
                  label: e.currentTarget.value,
                  value: e.currentTarget.value,
                });
                setQuery('');
              }
            }}
            onPaste={onPaste}
            onClear={
              !multiSearchEnabled
                ? () => {
                    onSelect({label: '', value: ''});
                    setClearIcon(false);
                  }
                : undefined
            }
            onFocus={(e) => setClearIcon(false)}
            onBlur={(e) =>
              query.length > 0 ? setClearIcon(true) : setClearIcon(false)
            }
            showClearIcon={!multiSearchEnabled ? clearIcon : undefined}
            placeholder={placeholder}
          />

          {isOpen && options.length > 0 && (
            <div
              className={classify(css.options, {
                [css.top]: anchorPosition === 'top',
                [css.bottom]: anchorPosition === 'bottom',
              })}
            >
              <Options
                options={options}
                resolveValue={resolveValue}
                resolveLabel={resolveLabel}
                resolveIcon={resolveIcon}
                onSelect={(option) => {
                  if (!multiSearchEnabled) {
                    setQuery(option.label);
                    setClearIcon(true);
                    handleOptions(option.label);
                  }
                  onSelect(option);
                }}
                classNames={{options: classNames?.options}}
                OptionComponent={OptionComponent}
              />
            </div>
          )}
        </div>
      )}
    </ClickAway>
  );
};
