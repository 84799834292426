// @flow

import type {PhoneNumberSet} from 'src/types/chat/phone-number-sets';
import type {SenseAction} from 'src/action-creators/types';

import {
  RECEIVE,
  RECEIVE_AUTO_REPLY,
  UPDATE_MSG_CHANNEL_TYPE,
  REMOVE_PHONE_NUMBER,
  RECEIVE_AUTO_REPLY_SETTINGS_UPDATES,
} from 'src/action-creators/chat/phone-number-sets';

import omit from 'lodash/omit';


export type State = {
  [phoneNumberSetId: string]: PhoneNumberSet,
};

const initialState = Object.freeze({});

const processData = (data = []) => {
  const obj = {};
  data.forEach((phoneNumberSet) => {
    phoneNumberSet['default_channel'] = phoneNumberSet.sms ? 'sms' : 'whatsapp';
    phoneNumberSet['selected_channel'] = phoneNumberSet['selected_channel']
      ? phoneNumberSet['selected_channel']
      : phoneNumberSet['default_channel'];
    if (!Object.keys(obj).includes(phoneNumberSet.phone_numbers[0])) {
      obj[phoneNumberSet.phone_numbers[0]] = phoneNumberSet;
    }
  });
  return obj;
};

const getPhoneNumberFromPhoneNumberSets = (
  phoneNumberSets,
  phoneNumberSetId,
) => {
  let phoneNumber: string = '';
  Object.entries(phoneNumberSets).forEach((phoneNumberSet: any) => {
    if (
      phoneNumberSet[1]['sms'] &&
      phoneNumberSet[1].sms.id === phoneNumberSetId
    ) {
      phoneNumber = phoneNumberSet[1].phone_numbers[0];
    }
    if (
      phoneNumberSet[1]['whatsapp'] &&
      phoneNumberSet[1].whatsapp.id === phoneNumberSetId
    ) {
      phoneNumber = phoneNumberSet[1].phone_numbers[0];
    }
  });
  return phoneNumber;
};
export default function reduce(
  state: State = initialState,
  action: SenseAction,
): State {
  switch (action.type) {
    case RECEIVE: {
      return {
        ...state,
        ...processData(action.payload),
      };
    }
    case RECEIVE_AUTO_REPLY: {
      const phoneNumber = getPhoneNumberFromPhoneNumberSets(
        state,
        action.payload.phoneNumberSetId,
      );
      if (state[phoneNumber].sms && state[phoneNumber].whatsapp) {
        if (
          action.payload.phoneNumberSetId === state[phoneNumber].whatsapp.id
        ) {
          return {
            ...state,
            [phoneNumber]: {
              ...state[phoneNumber],
              autoreply: action.payload,
            },
          };
        }
      } else {
        return {
          ...state,
          [phoneNumber]: {
            ...state[phoneNumber],
            autoreply: action.payload,
          },
        };
      }
      return state;
    }
    case RECEIVE_AUTO_REPLY_SETTINGS_UPDATES: {
      const phoneNumber = action.payload.phoneNumber;

      if (phoneNumber && state[phoneNumber]) {
        return {
          ...state,
          [phoneNumber]: {
            ...state[phoneNumber],
            autoreply: {
              ...state[phoneNumber].autoreply,
              ...action.payload.autoReply,
            },
          },
        };
      }
      return state;
    }
    case UPDATE_MSG_CHANNEL_TYPE: {
      const phoneNumberSetId = action.payload.phoneNumberSetId;
      const phoneNumberSetFound = Object.keys(state).indexOf(phoneNumberSetId);
      if (phoneNumberSetFound === -1) {
        return state;
      }
      return {
        ...state,
        [action.payload.phoneNumberSetId]: {
          ...state[action.payload.phoneNumberSetId],
          selected_channel: action.payload.selected_channel,
        },
      };
    }
    case REMOVE_PHONE_NUMBER: {
      return {
        ...omit(state, action.payload.phoneNumberSetId),
      };
    }
  }

  return state;
}
