// @flow strict

import type {
  NotificationUsers,
  GenericError,
  NotificationSettings,
} from 'src/types/referral-v2';
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api.js';
// $FlowFixMe[nonstrict-import]
import {showApiError} from 'src/action-creators/modal';
import {DEFAULT_ERROR} from 'src/action-creators/referral-v2/referral-jobs.js';
import {showToastMessage} from 'src/action-creators/toasts';
import {VARIANTS} from 'src/designSystem2021Components/toast';


export const NOTIFICATION_USERS_LOADING = 'referral/notification-users-loading';
export const NOTIFICATION_USERS = 'referral/notification-users';
export const NOTIFICATION_USERS_ERROR = 'referral/notification-users-error';
export const NOTIFICATION_CONFIG_USERS_LOADING =
  'referral/notification-config-users-loading';
export const NOTIFICATION_CONFIG_USERS = 'referral/notification-config-users';
export const NOTIFICATION_CONFIG_USERS_ERROR =
  'referral/notification-config-users-error';

type NotificationUsersLoadingAction = {
  type: typeof NOTIFICATION_USERS_LOADING,
};

type NotificationUsersAction = {
  type: typeof NOTIFICATION_USERS,
  payload: ?(NotificationUsers[]),
};

type NotificationUsersErrorAction = {
  type: typeof NOTIFICATION_USERS_ERROR,
  payload: ?GenericError,
};
type NotificationConfigUsersLoadingAction = {
  type: typeof NOTIFICATION_CONFIG_USERS_LOADING,
};

type NotificationConfigUsersAction = {
  type: typeof NOTIFICATION_CONFIG_USERS,
  payload: ?(NotificationUsers[]),
};

type NotificationConfigUsersErrorAction = {
  type: typeof NOTIFICATION_CONFIG_USERS_ERROR,
  payload: ?GenericError,
};

export type NotificationUsersActions =
  | NotificationUsersAction
  | NotificationUsersErrorAction
  | NotificationUsersLoadingAction
  | NotificationConfigUsersAction
  | NotificationConfigUsersErrorAction
  | NotificationConfigUsersLoadingAction;

const recieveNotificationUsers = (
  payload: NotificationUsers[],
): NotificationUsersAction => ({
  type: NOTIFICATION_USERS,
  payload,
});

const getNotificationUsersLoading = (): NotificationUsersLoadingAction => ({
  type: NOTIFICATION_USERS_LOADING,
});

const getNotificationUsersError = (
  payload: ?GenericError,
): NotificationUsersErrorAction => ({
  type: NOTIFICATION_USERS_ERROR,
  payload,
});

const recieveNotificationConfigUsers = (
  payload: NotificationUsers[],
): NotificationConfigUsersAction => ({
  type: NOTIFICATION_CONFIG_USERS,
  payload,
});

const getNotificationConfigUsersLoading =
  (): NotificationConfigUsersLoadingAction => ({
    type: NOTIFICATION_CONFIG_USERS_LOADING,
  });

const getNotificationConfigUsersError = (
  payload: ?GenericError,
): NotificationConfigUsersErrorAction => ({
  type: NOTIFICATION_CONFIG_USERS_ERROR,
  payload,
});

export const getNotificationUsers: (programId: string) => ThunkAction<void> =
  flow()((programId) => (dispatch: Dispatch) => {
    dispatch(getNotificationUsersLoading());

    return dispatch(
      reduxApi.get(`referral/program/${programId}/notification/all-users`),
    ).then(
      (response: NotificationUsers[]) => {
        // $FlowIssue object values
        dispatch(recieveNotificationUsers(response));
      },
      (error) => {
        dispatch(
          getNotificationUsersError(error?.response?.errors || DEFAULT_ERROR),
        );
      },
    );
  });

export const getNotificationConfigUsers: (
  programId: string,
) => ThunkAction<void> = flow()((programId) => (dispatch: Dispatch) => {
  dispatch(getNotificationConfigUsersLoading());

  return dispatch(
    reduxApi.get(`referral/program/${programId}/notification/users`),
  ).then(
    (response: NotificationUsers[]) => {
      // $FlowIssue object values
      dispatch(recieveNotificationConfigUsers(response));
    },
    (error) => {
      dispatch(
        getNotificationConfigUsersError(
          error?.response?.errors || DEFAULT_ERROR,
        ),
      );
    },
  );
});

export const addNotificationUsers: (
  programId: string,
  payload: {email: string[]},
) => ThunkAction<void> = flow()(
  (programId, payload) => (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.post(
        `referral/program/${programId}/notification/add-user`,
        payload,
      ),
    ).then(
      () => {
        dispatch(
          showToastMessage('User Added Successfully', {
            variant: VARIANTS.SUCCESS,
          }),
        );
        dispatch(getNotificationUsers(programId));
        dispatch(getNotificationConfigUsers(programId));
      },
      (error) => {
        if (error?.response?.status === 409) {
          dispatch(
            showToastMessage('User Already Exist', {
              variant: VARIANTS.ERROR,
            }),
          );
          return;
        }
        dispatch(showApiError(error, error.response.message));
      },
    );
  },
);

export const updateUserNotificationSettings: (
  programId: string,
  payload: {email: string, settings: NotificationSettings},
) => ThunkAction<void> = flow()(
  (programId, payload) => (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.post(
        `referral/program/${programId}/notification/user-settings`,
        payload,
      ),
    ).then(
      () => {
        dispatch(
          showToastMessage('Updated Successfully', {
            variant: VARIANTS.SUCCESS,
          }),
        );
        dispatch(getNotificationConfigUsers(programId));
      },
      (error) => {
        dispatch(showApiError(error, error.response.message));
      },
    );
  },
);

export const deleteNotificationUser: (
  email: string,
  programId: string,
) => ThunkAction<void> = flow()((email, programId) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.post(`referral/program/${programId}/notification/delete-user`, {
      email,
    }),
  ).then(() => {
    dispatch(
      showToastMessage('Deleted Successfully', {
        variant: VARIANTS.SUCCESS,
      }),
    );
    dispatch(getNotificationUsers(programId));
    dispatch(getNotificationConfigUsers(programId));
  });
});
