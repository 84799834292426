// @flow strict

import * as React from 'react';

import {StartDataSyncSidePanel} from 'src/components/agency-onboard/side-panels/start-data-sync.jsx';
import {TenDLCRegistrationSidePanel} from 'src/components/agency-onboard/side-panels/tendlc-registration.jsx';
import {ConfigureDefaultBranding} from 'src/components/agency-onboard/side-panels/configure-default-branding.jsx';
import {FallbackEmailandPhone} from 'src/components/agency-onboard/side-panels/fallback-email-and-phone.jsx';
import {DNSConfiguration} from 'src/components/agency-onboard/side-panels/dns-configuration.jsx';
import {NoteWriteback} from 'src/components/agency-onboard/side-panels/note-writeback.jsx';
import {AddPhoneNumber} from 'src/components/agency-onboard/side-panels/add-phone-number.jsx';

//$FlowFixMe[nonstrict-import]
import {useLoadingApi} from 'src/hooks/useApi';
import {Loading} from 'src/components/lib/loading';
import type {sidePanelProps} from 'src/components/agency-onboard/types/side-panel.js';

import maincss from 'src/components/agency-onboard/agency-onboard.css';


const SIDE_PANEL_COMPONENT = {
  'Start Data Sync': StartDataSyncSidePanel,
  '10DLC Registration': TenDLCRegistrationSidePanel,
  'Configure Default Branding': ConfigureDefaultBranding,
  'Note Writeback Configuration': NoteWriteback,
  'DNS Configuration': DNSConfiguration,
  'Add Phone Numbers': AddPhoneNumber,
  'Add Fallback Email and Phone Number': FallbackEmailandPhone,
};

export const SidePanelLoader = (props: sidePanelProps): React.Node => {
  const {task, onTaskSubmit, onTaskCancel, taskName} = props;
  const SelectedTask = SIDE_PANEL_COMPONENT[taskName];

  const {response: formData, loaded: dataLoaded} = useLoadingApi(
    () => `onboard/task/${task['id']}`,
    [],
  );

  if (!formData || !dataLoaded) {
    return (
      <div className={maincss.loaderDiv}>
        <Loading />
      </div>
    );
  }

  return (
    <SelectedTask
      task={task}
      taskName={taskName}
      onTaskSubmit={onTaskSubmit}
      onTaskCancel={onTaskCancel}
      data={formData}
    />
  );
};
