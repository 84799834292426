// @noflow

import type {Contact} from 'src/types/contacts';
import type {Message, MessagePending} from 'src/types/messages';
import type {State} from 'src/reducers';

import {createSelector} from 'reselect';
import _get from 'lodash/get';

import {normalizePhone} from 'src/utils/phone';
import {compareTimestamps} from 'src/utils/date-time';
import {getRecipientPhone} from 'src/utils/draft-messages';

import {
  INBOX_TYPE,
  MSG_DIRECTION_INCOMING,
  MSG_DIRECTION_OUTGOING,
} from 'src/types/messages';

import {selectCurrentPhoneCountry} from 'src/selectors/phone-number-sets';


export type InboxMessage = Message & {
  inboxContact?: Contact,
  inboxPhone: string,
  hasUnread: boolean,
};

// Automated messages are broadcasts and events, which should not affect
// a thread's priority in the inbox.
export const isOutgoingCoreMessage = (message: Message) =>
  (message.origin === 'core' ||
    message.origin === 'scheduler' ||
    message.origin === 'business_api') &&
  message.direction === MSG_DIRECTION_OUTGOING;
const isAutomatedMessage = (message: Message) =>
  message.scheduledMessageId || isOutgoingCoreMessage(message);
const isConversationalMessage = (message: Message) =>
  !isAutomatedMessage(message);

const getMessages = (state) => state.messages.byId;
const getThreads = (state) => state.messages.threadsByPhone;
const getContacts = (state) => state.contacts.contacts;
export const selectThreadDigests = createSelector(
  getMessages,
  getThreads,
  getContacts,
  (messagesById, threadsByPhone, contacts) => {
    // Group messages into conversation threads by contact
    // $FlowIssue - core-js got the type def wrong
    const groups = Object.entries(threadsByPhone).filter(
      ([_phone, ids]: [string, string[]]) =>
        // Remove any conversations that consist of only automated messages
        ids.some((id) => isConversationalMessage(messagesById[id])),
    );

    // Sort groups by most recent conversational (non-automated) message
    groups.sort(([_, aIds], [__, bIds]) => {
      // Ignore automated messages
      // (they don't affect the order in which conversation threads should appear)
      const {timeCreated: a} =
        messagesById[
          aIds.find((id) => isConversationalMessage(messagesById[id]))
        ];
      const {timeCreated: b} =
        messagesById[
          bIds.find((id) => isConversationalMessage(messagesById[id]))
        ];

      // NOTE(gab): because we use ISO 8601 dates we can simply sort them
      // lexicographically. See https://en.wikipedia.org/wiki/ISO_8601#General_principles
      if (a < b) {
        return 1;
      }

      if (b < a) {
        return -1;
      }

      return 0;
    });

    // The component expects an array of message supertypes:
    // the most recent one from each contact
    return groups.map(([_phone, ids]) => {
      const msg = messagesById[ids[0]];

      let contactPhone;
      const contactId = msg.contactId;

      if (msg.direction === MSG_DIRECTION_INCOMING) {
        contactPhone = msg.fromPhone;
      } else {
        contactPhone = msg.toPhone;
      }

      return {
        ...msg,
        inboxContact: contacts[contactId],
        inboxPhone: contactPhone,
        hasUnread: ids.some((id) => messagesById[id].timeRead === null),
      };
    });
  },
);

export const selectMessagesForInbox = createSelector(
  selectThreadDigests,
  // NOTE (kyle): after deleting a contact, we hide the thread
  (threadDigests) =>
    threadDigests.filter(
      ({inboxContact}) => inboxContact && !inboxContact.archived,
    ),
);

export const selectTotalUnreadThreadCount = (state) =>
  state.messages.unreadTotal.thread_count;

export const selectTotalUnreadPhoneNumberSetsCount = (state) =>
  state.messages.unreadTotal.phone_number_sets_count;

export const selectTotalUnreadQueuesCount = (state) =>
  state.messages.unreadTotal.queues_count;

export const selectTotalUnreadCount = (state) =>
  state.messages.unreadTotal.count;

export const selectTotalUnreadCountsWithIds = (state) => {
  return state.messages.unreadCountsOfAllInbox;
};

export const selectUnreadCountByPhoneNumberSets = (state) =>
  state.messages.unreadByPhoneNumberSet;

export const selectUnreadCountByQueue = (state) => state.messages.unreadByQueue;

const getMostRecentId = (state) => state.messages.mostRecentId;
export const selectMostRecentMessage = createSelector(
  getMessages,
  getMostRecentId,
  (messagesById, mostRecentId) => messagesById[mostRecentId],
);

export const selectPhoneForMessageHistory: (
  state: State,
  phone: string,
) => ?string = createSelector(
  (state) => state.draftMessages.inputPhone,
  (state) => state.draftMessages.recipients,
  (state, phone) => phone,
  selectCurrentPhoneCountry,
  (inputPhone, recipients, routePhone, currentPhoneCountry) => {
    if (routePhone) {
      return routePhone;
    }

    if (recipients.length === 1) {
      return normalizePhone(
        getRecipientPhone(recipients[0]),
        currentPhoneCountry,
      );
    }

    return normalizePhone(inputPhone || '', currentPhoneCountry);
  },
);

export const selectUnreadMessages: (state: State) => Message[] = createSelector(
  getMessages,
  (messagesById) =>
    Object.values(messagesById).filter((msg) => msg && msg.timeRead === null),
);

export const selectMostRecentUnreadMessage: (state: State) => ?Message =
  createSelector(selectUnreadMessages, (unreadMessages) => {
    if (unreadMessages.length > 0) {
      return unreadMessages.reduce((mostRecent, msg) =>
        moreRecent(msg, mostRecent) ? msg : mostRecent,
      );
    }
  });

const getLastAcknowledgedMessage = (state) =>
  state.messages.byId[state.messages.acknowledgedId];

export const selectUnreadCountSinceLastAcknowledge = createSelector(
  getLastAcknowledgedMessage,
  selectUnreadMessages,
  (lastAcknowledgedMessage, unreadMessages) => {
    if (lastAcknowledgedMessage) {
      return unreadMessages.reduce(
        (count, msg) =>
          (count += moreRecent(msg, lastAcknowledgedMessage) ? 1 : 0),
        0,
      );
    } else {
      return 0;
    }
  },
);

export const moreRecent = (a: Message, b: Message): boolean =>
  (a.statusTimeUpdated || a.timeCreated) >
  (b.statusTimeUpdated || b.timeCreated);

export const selectCurrentInboxSoundSetting = (
  state: State,
  id: string,
  type: string,
): boolean => {
  if (type === INBOX_TYPE.QUEUE) {
    // get the inbox id from thread id
    const queues = Object.values(state.chat.queues.queue_based_inboxes);
    const selectedQueue = queues.find((queue) => queue.queue_id === id);
    return selectedQueue?.messaging_sound_setting;
  } else if (type === INBOX_TYPE.PHONE) {
    const phoneNumberSets = state.chat.phoneNumberSets;
    return phoneNumberSets?.[id]?.messaging_sound_setting;
  } else {
    return false;
  }
};

export const selectInboxIdFromQueueId = (
  state: State,
  queueId: string,
): string => {
  const queues = Object.values(state.chat.queues.queue_based_inboxes);
  const selectedQueue = queues.find((queue) => queue.queue_id === queueId);
  return selectedQueue?.id;
};

export const selectHVBroadcastContentWarningInfo = (state: State): any => {
  const {
    contentWarningClickTextType,
    contentWarningClickStartOffset,
    contentWarningClickEndOffset,
  } = state.messages;
  return {
    contentWarningClickTextType,
    contentWarningClickEndOffset,
    contentWarningClickStartOffset,
  };
};
