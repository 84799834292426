// @flow strict

export type MediaSpec = {
  src: string,
  type: string,
};

export type MediaSpecOrSpecs = MediaSpec | MediaSpec[];

/**
 * manages the multi-tracked playing of an audio file.
 *
 * If the current environment does not provide an `Audio` API,
 * this class does nothing.
 *
 * Note that in Sense, you probably don't want to use this class
 * directly. Both `useAudioPlayer` and `SoundEffect` are better
 * for components that want to play sound.
 */
export class MultiTrackAudio {
  _media: MediaSpec[];
  _audio: HTMLAudioElement | null;

  /**
   * @param media  either a single src/type pair for a sound file
   *               or a list of sound files of different types.
   *               This class will check browser support for each
   *               until it finds one it can use.
   *
   * Note that there is no guarentee this class will be able to use
   * the media it is given.
   */
  constructor(media: MediaSpec | MediaSpec[]) {
    this._media = Array.isArray(media) ? media : [media];
    this._audio = typeof Audio === 'undefined' ? null : loadAudio(this._media);
  }

  play() {
    if (this._audio) {
      // NOTE (kyle): the try/catch is for IE 11, which doesn't support promises in audio.
      try {
        Promise.resolve(this._audio.play()).catch((_error) => {
          // ignore
        });
      } catch (_error) {
        // ignore
      }
      this._audio = loadAudio(this._media);
    }
  }
}

export class SingleTrackAudio {
  _media: MediaSpec[];
  _audio: HTMLAudioElement | null;
  _playing: boolean;

  /**
   * @param media  either a single src/type pair for a sound file
   *               or a list of sound files of different types.
   *               This class will check browser support for each
   *               until it finds one it can use.
   *
   * Note that there is no guarentee this class will be able to use
   * the media it is given.
   */
  constructor(media: MediaSpec | MediaSpec[]) {
    this._media = Array.isArray(media) ? media : [media];
    this._audio = typeof Audio === 'undefined' ? null : loadAudio(this._media);
    this._playing = false;
  }

  play() {
    const audio = this._audio;
    if (audio && (audio.paused || audio.ended) && !this._playing) {
      this._playing = true;
      // NOTE (kyle): the try/catch is for IE 11, which doesn't support promises in audio.
      try {
        Promise.resolve(audio.play())
          .catch(() => {
            // ignore
          })
          .then(() => {
            this._playing = false;
          });
      } catch (_error) {
        this._playing = false;
      }
    }
  }
}

function loadAudio(media: MediaSpec[]) {
  const audio = new Audio();

  let goodSrc;
  for (const {src, type} of media) {
    if (audio.canPlayType(type)) {
      goodSrc = src;
    }
  }

  if (goodSrc) {
    audio.src = goodSrc;
  }

  audio.load();

  return audio;
}
