// @flow

import type {Contact} from 'src/types/contacts';
import type {AudienceMember} from 'src/types/audience-member';
import type {Thread} from 'src/types/messages';

import * as React from 'react';
import {useSelector} from 'react-redux';

import {getFullName} from 'src/utils';
import {phoneEqual, formatPhone} from 'src/utils/phone';
import classify from 'src/utils/classify';
import {selectCurrentPhoneCountry} from 'src/selectors/phone-number-sets';

import MouseTip from 'src/components/lib/mouse-tip';
import Icon from 'src/images/icons/is-contact-icon.svg?noAttrs';

import css from './name.css';


const ContactName = ({
  contact,
  audienceMember,
  thread,
  phone,
  truncate,
}: {
  contact?: ?Contact,
  audienceMember?: ?AudienceMember,
  thread?: ?Thread,
  phone: ?string,
  truncate?: boolean,
}): React.Node => {
  const currentPhoneCountry = useSelector(selectCurrentPhoneCountry);

  const person = audienceMember || contact;
  const displayName =
    (person && getNonPhoneName(person, phone)) ||
    (phone && formatPhone(phone, currentPhoneCountry));
  const nameClass = truncate ? css.truncate : undefined;
  const isBlocked = thread && thread.blocked;
  return (
    <React.Fragment>
      <span className={nameClass}>{displayName}</span>
      {audienceMember && <SyncedIcon />}
      {isBlocked && <BlockedIcon />}
    </React.Fragment>
  );
};

export default ContactName;

export const SyncedIcon = ({className}: {className?: string}): React.Node => (
  <MouseTip content="Contact linked to ATS">
    {/* $FlowFixMe[incompatible-type-arg] */}
    <Icon className={classify(css.icon, className)} />
  </MouseTip>
);

const BLOCKED_EMOJI_CHAR = `\u{1F6AB}`;
export const BlockedIcon = ({className}: {className?: string}): React.Node => (
  <MouseTip content="Blocked">
    <span className={classify(css.emojiIcon, className)}>
      {BLOCKED_EMOJI_CHAR}
    </span>
  </MouseTip>
);

export const getNonPhoneName = (person: *, phone: ?string): string => {
  const name = getFullName(person);
  return phoneEqual(name, phone) ? '' : name;
};
