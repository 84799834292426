// @flow

import type {
  SenseEvent,
  SenseApiEvent,
  EventAttachment,
  EventTemplate,
  EventTypes,
  SendMetadataSuppressionKeys,
} from 'src/types/events';
import type {NewEvent} from 'src/api-parsers/events.types';
import logger from 'src/utils/logger';
import parsers from 'src/api-parsers/events';
import type {Question} from 'src/types/survey';
import type {
  RemindersDef,
  ScheduleDef,
  ScheduleTypes,
} from 'src/stores/scheduling.types';
import type {EventError} from 'src/stores/event';
import type {Dispatch} from 'src/reducers';
// $FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores/index';

import * as React from 'react';
import sculpt from 'sculpt';
import size from 'lodash/size';
import invariant from 'invariant';

import * as api from 'src/utils/api';
import * as reduxApi from 'src/utils/redux-api';
import {saveBranch} from 'src/actions/event-creation';
import {createDynamicLabelSelector} from 'src/selectors/dynamic-labels';
import {selectEnableDiscoverForEngage} from 'src/selectors/agency';
import {workflowEntityType as selectWorkflowEntityType} from 'src/selectors/ats-entities';
import {populateEventModules} from './event/populate';
import {updateWorkflow} from 'src/action-creators/workflows';
import {updateWorkflow as updateWorkflowInStore} from 'src/actions/workflow';


export {
  questionPrototypes,
  ratingScaleQuestion,
  npsQuestion,
  rhetoricalQuestion,
  beeFreeModule,
  smsMessageModule,
} from './event/populate';

export async function createEvent(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  event: NewEvent,
): Promise<SenseEvent | void> {
  const apiEvent = parsers.normalize.create(event);
  const appendJobMatchFields = selectEnableDiscoverForEngage(
    store.reduxStore.getState(),
  );
  let response;
  const {dispatch, getState} = store.reduxStore;
  try {
    if (getState().agency.config.multiEntityEnabled) {
      const {workflowId} = getState().eventCreation;
      const updatedApiEvent = maybeAssignRecipientResource(apiEvent, getState);

      response = await dispatch(
        reduxApi.post(`workflows/${workflowId}/events`, updatedApiEvent),
      );
    } else {
      response = await api.post(store, 'events', apiEvent);
      response['dynamic_delivery_address'] =
        ['survey', 'message'].includes(response['event_type']) &&
        !response['dynamic_delivery_address']
          ? 'email'
          : response['dynamic_delivery_address'];
    }
  } catch (error) {
    logger.error('event createEvent error: ', error.stack || error);
    return;
  }
  return processEventResponse(store, response, appendJobMatchFields);
}

export async function createEventNew(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  workflowId: string,
  event: NewEvent,
): Promise<SenseEvent | void> {
  let response;
  const workflow = store.workflows.get(workflowId);
  const {dispatch} = store.reduxStore;
  try {
    response = await dispatch(
      reduxApi.post(`workflows/${workflowId}/events`, event),
    );
  } catch (error) {
    logger.error('event createEvent error: ', error.stack || error);
    return;
  }
  const appendJobMatchFields = selectEnableDiscoverForEngage(
    store.reduxStore.getState(),
  );
  const parsedEventResponse = processEventResponse(
    store,
    response,
    appendJobMatchFields,
  );
  store.workflows.update({
    ...workflow,
    eventsSchedule: workflow.eventsSchedule.concat([
      {id: parsedEventResponse.id},
    ]),
  });
  return parsedEventResponse;
}

export function createBulkWritebackEvent(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  workflowId: string,
): Promise<SenseEvent | void> {
  const apiEvent = {
    event_type: 'bulk_writeback',
    title: 'unnamed cleanup',
    enabled: true,
    field_change_rules: [],
    content_subscription_category_id: null,
    content_subscription_reason: null,
  };

  return createEventNew(store, workflowId, apiEvent);
}

export async function updateEventNew(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  event: $Shape<SenseApiEvent>,
): Promise<SenseEvent | void> {
  let response;
  const workflow = store.workflows.get(event.workflow_id);
  const {dispatch} = store.reduxStore;
  try {
    response = await dispatch(reduxApi.put(`events/${event.id}`, event));
  } catch (error) {
    logger.error('event update error: ', error.stack || error);
    return;
  }
  const appendJobMatchFields = selectEnableDiscoverForEngage(
    store.reduxStore.getState(),
  );
  const parsedEventResponse = processEventResponse(
    store,
    response,
    appendJobMatchFields,
  );
  dispatch(
    updateWorkflow(parsedEventResponse.workflowId, {
      events: {
        ...workflow.events,
        [parsedEventResponse.id]: parsedEventResponse,
      },
    }),
  );
  store.workflows.update({
    ...workflow,
    events: {
      ...workflow.events,
      [parsedEventResponse.id]: parsedEventResponse,
    },
  });

  return parsedEventResponse;
}

export function updateBulkWritebackEvent(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  event: SenseApiEvent,
): Promise<SenseEvent | void> {
  const apiEvent = {
    event_type: event.type,
    id: event.id,
    title: event.title,
    enabled: event.enabled,
    field_change_rules: event.field_change_rules,
    workflow_id: event.workflow_id,
  };

  return updateEventNew(store, apiEvent);
}

// workflows whose anchor entity type is not a person _need_ to have a valid
// recipient related entity set. so we just pick the first one when
// initializing the event, but assume it will be changed later on since it
// _can't_ be null.
const selectAddresses = createDynamicLabelSelector({
  dataType: 'email',
  depth: 'recipient',
});

const maybeAssignRecipientResource = (apiEvent, getState) => {
  const state = getState();
  const {workflowId} = state.eventCreation;
  const recipientTypes = state.workflows.recipientTypes[workflowId];
  if (!recipientTypes.canSendToAnchor) {
    const workflowEntityType = selectWorkflowEntityType(state, workflowId);
    const addresses =
      workflowEntityType && selectAddresses(state, workflowEntityType);
    return {
      ...apiEvent,
      dynamic_delivery_address: addresses?.[0].fullyFormedName,
    };
  }
  return apiEvent;
};

export function getEvent(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  eventId: string,
): Promise<SenseEvent | void> {
  const cacheKey = `event${eventId}`;
  const cacheTtl = 120 * 1000;
  if (!store.cache.expired(cacheKey, cacheTtl)) {
    return Promise.resolve();
  }
  const appendJobMatchFields = selectEnableDiscoverForEngage(
    store.reduxStore.getState(),
  );
  return api.get(store, `events/${eventId}`).then((response) => {
    store.cache.set(cacheKey);
    return processEventResponse(store, response, appendJobMatchFields);
  });
}

export function getTemplate(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  eventId: string,
): Promise<EventTemplate | void> {
  return api.get(store, `events/${eventId}/template`).then(
    (resp) => {
      const template = parsers.parse.eventTemplate(resp);
      store.events.receiveTemplate(eventId, template);

      return template;
    },
    (err) => {
      logger.error(err.stack || err);
    },
  );
}

export function getEventAttachments(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  eventId: string,
): Promise<*> {
  return api.get(store, `events/${eventId}/attachments`).then(
    (response) => {
      const attachments = parsers.parse.eventAttachments(response);
      store.events.receiveAttachments(eventId, attachments);
      return attachments;
    },
    (error) => {
      logger.error(
        `event getEventAttachments failed for ${eventId} with error: `,
        error,
      );
    },
  );
}

//Only operations on the question passed to the function and does not update the store.
function attachBranchesToQuestion(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  question: Question,
) {
  return sculpt(question, {
    branches: {
      $set: question.branchIds
        ? question.branchIds.map((branch) =>
            store.branches.getBranch(branch.id),
          )
        : [],
    },
  });
}

//Merge branches onto the modules.
export function getEventWithBranches(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  event: SenseEvent,
): SenseEvent {
  const questions = event.questions.map((question) =>
    attachBranchesToQuestion(store, question),
  );
  const mergedEvent = sculpt(event, {
    questions: {
      $set: questions,
    },
  });
  return mergedEvent;
}

export function updateEvent(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  event: SenseEvent,
): Promise<SenseEvent | void> {
  const eventWithBranches = getEventWithBranches(store, event);
  const appendJobMatchFields = selectEnableDiscoverForEngage(
    store.reduxStore.getState(),
  );
  const apiEvent = parsers.normalize.event(
    eventWithBranches,
    appendJobMatchFields,
  );
  return api
    .put(store, `events/${event.id}`, apiEvent)
    .then((response) => {
      store.events.isEditing(false);
      return processEventResponse(store, response, appendJobMatchFields);
    })
    .catch((error) => {
      store.events.setError(error.response);
      throw new Error(`event updateEvent error: ${error.stack || error}`);
    });
}

function processEventResponse(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  response: SenseEvent,
  appendJobMatchFields: boolean = false,
) {
  const parsedEventResponse = parsers.parse.event(
    response,
    appendJobMatchFields,
  );
  store.events.receive(parsedEventResponse);
  //Getting the branches if any and storing them in another store.
  const parsedBranches = parsers.parse.branches(response);
  if (size(parsedBranches) > 0) {
    store.eventCreation.remove(parsedEventResponse.id);
    store.branches.receiveBranches(parsedBranches);
  }
  return parsedEventResponse;
}

export function updateTemplate(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  event: SenseEvent,
  template: EventTemplate,
): Promise<*> {
  const template_id = template.id;
  return api.put(store, `events/${event.id}/template`, {template_id});
}

export function editEvent(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  spec: Object,
) {
  const event = store.eventCreation.state.event;
  invariant(
    event,
    'Attempted to edit touchpoint while not currently editing a touchpoint.',
  );
  store.eventCreation.updateState({
    event: spec,
  });
  // TODO (kyle): find out if this is necessary
  store.events.isEditing(true);
}

export function updateEventTitle(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  title: string,
) {
  editEvent(store, {
    title: {
      $set: title,
    },
  });
}

export function updateEventEnabled(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  enabled: boolean,
) {
  editEvent(store, {
    enabled: {
      $set: enabled,
    },
  });
}

export function updateEventSuppression(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  suppressionLabel: ?SendMetadataSuppressionKeys,
) {
  const suppression = {};
  if (suppressionLabel) {
    suppression[suppressionLabel] = true;
  }

  editEvent(store, {
    sendMetadata: {
      $set: suppression,
    },
  });
}
export function updateEventSuppressionV2(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  suppressionLabel: ?string,
) {
  editEvent(store, {
    sendMetadata: {
      $set: suppressionLabel ? {[suppressionLabel]: true} : {},
    },
  });
}

export function uploadAttachment(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  file: any,
  eventId: string,
): Promise<EventAttachment[]> {
  const formData = new FormData();
  const name = file ? file.name : 'attachment';
  formData.append(name, file);
  return api
    .post(store, `events/${eventId}/attachments`, formData)
    .then((response) => {
      const attachments = parsers.parse.eventAttachments(response);
      store.events.receiveAttachments(eventId, attachments);
      return attachments;
    })
    .catch((error) => {
      logger.error('uploadAttachment error:', error.stack || error);
      error.response && store.events.setError(error.response);
      throw new Error(error);
    });
}

export function deleteAttachment(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  attachment: EventAttachment,
  eventId: string,
): Promise<void> {
  return api
    .del(store, `events/${eventId}/attachments/${attachment.id}`)
    .catch((error) => {
      logger.error('deleteAttachment error:', error.stack || error);
      throw new Error(error);
    });
}

export function updateQuestion(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  questionIndex: number,
  spec: Object,
) {
  editEvent(store, {
    questions: {
      [questionIndex]: spec,
    },
  });
}

export function eventDeleteModule(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  questionIndex: number,
) {
  editEvent(store, {
    questions: {
      $splice: [[questionIndex, 1]],
    },
  });
}

export function moveModule(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  oldIndex: number,
  newIndex: number,
) {
  const event = store.eventCreation.state.event;
  invariant(
    event,
    'Attempted to move module while not currently editing a touchpoint.',
  );

  const question = event.questions[oldIndex];

  editEvent(store, {
    questions: {
      $splice: [
        [oldIndex, 1],
        [newIndex, 0, question],
      ],
    },
  });
}

export function moveBranchModule(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  oldIndex: number,
  newIndex: number,
) {
  const branchInfo = store.eventCreation.state.branchInfo;
  const branch = branchInfo && branchInfo.branch;
  invariant(
    branch,
    'Attempted to move module while not currently viewing a branch.',
  );

  const question = branch.questions[oldIndex];

  store.eventCreation.updateState({
    branchInfo: {
      branch: {
        questions: {
          $splice: [
            [oldIndex, 1],
            [newIndex, 0, question],
          ],
        },
      },
    },
  });

  saveBranch(store);
}

export function beginEdit(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  eventId: string,
): ?SenseEvent {
  const event = store.events.getEvent(eventId);

  if (event) {
    store.eventCreation.setState({
      event,
    });
  }
}

// $FlowFixMe[value-as-type]
export function endEdit(store: IndexStore) {
  store.events.setState({
    error: null,
  });
  store.eventCreation.setState({
    event: null,
    template: null,
    saveError: null,
  });
}

export function updateSchedulingEdit(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  newSchedule: ScheduleDef,
  eventId: ?string,
) {
  store.scheduling.updateActiveSchedule(newSchedule, eventId);
}

export function updateSchedulingEditType(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  type: ScheduleTypes,
  eventId: ?string,
) {
  store.scheduling.setScheduleType(type, eventId);
}

export function updateSchedulingEditTypeV2(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  type: string,
  eventId: string,
) {
  store.scheduling.setScheduleType(type, eventId);
}

export function updateSchedulingRemindersEdit(
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  newReminders: RemindersDef,
  eventId: ?string,
) {
  store.scheduling.updateReminders(newReminders, eventId);
}

export const smsSurveyMessageModule = {
  type: 'sms_message_survey_module',
  text: `\n\n{survey_link}`,
};

export const setEventType = (
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  type: EventTypes,
) => {
  editEvent(store, {
    $assign: populateEventModules(store, {
      type,
      eventType: type,
      dynamicDeliveryAddress: null,
      questions: [],
      content_subscription_category_id: null,
    }),
  });
};

export const parseEventErrors = (
  error?: EventError,
): {title: string, reason: string | React.Node}[] => {
  const parsed = [];

  if (error) {
    if (error.errors && error.errors.indexOf('InvalidBrandingSettings') > -1) {
      if (error.message && error.message.search('email_from_address') > 0) {
        parsed.push({
          title: 'Bad Sender Address',
          reason: (
            <span>
              The <strong>sender email</strong> address is badly formatted
            </span>
          ),
        });
      }
      if (error.message && error.message.search('email_cc_list') > 0) {
        parsed.push({
          title: 'Bad CC Address',
          reason: (
            <span>
              An address in the <strong>CC field</strong> is badly formatted
            </span>
          ),
        });
      }
      if (error.message && error.message.search('email_bcc_list') > 0) {
        parsed.push({
          title: 'Bad BCC Address',
          reason: (
            <span>
              An address in the <strong>BCC field</strong> is badly formatted
            </span>
          ),
        });
      }
    }

    if (error.errors && error.errors.indexOf('InvalidDynamicFields') > -1) {
      parsed.push({
        title: 'Bad Dynamic Field',
        reason: 'You tried using a misspelled dynamic field',
      });
    }

    if (error.errors && error.errors.indexOf('InvalidMessageTextEvent') > -1) {
      parsed.push({
        title: 'Missing SMS Survey Link',
        reason: 'Message text is missing {{survey_link}}',
      });
    }

    if (error.errors && error.errors.indexOf('InvalidModuleValue') > -1) {
      parsed.push({
        title: 'Bad Module',
        reason: 'One of the modules in this survey is formatted incorrectly',
      });
    }

    if (error.errors && error.errors.indexOf('InvalidWorkflowSchedule') > -1) {
      parsed.push({
        title: 'Schedule Options',
        reason: 'One or more required fields below is empty or invalid',
      });
    }

    if (error.errors?.indexOf('MultipleNPSSurveyQuestionError') > -1) {
      parsed.push({
        title: 'NPS Survey',
        reason:
          'You cannot add more than 1 NPS Survey Question to a NPS Survey',
      });
    }

    if (error.errors?.indexOf('FileTypeNotSupported') > -1) {
      parsed.push({
        title: 'Attachment',
        reason:
          'Only .pdf; .doc; .docx; .xls; .xlsx; .odt; .txt; .rtf; .ppt; .pptx; .png; .jpg; are allowed',
      });
    }

    if (parsed.length === 0 && error.status > 200) {
      parsed.push({
        title: 'Server Error',
        reason: (
          <span>
            There was an error on the server that prevented saving this event.
            If this keeps happening, please contact us at{' '}
            <code>support@sensehq.com</code>
          </span>
        ),
      });
    }
  }

  return parsed;
};
