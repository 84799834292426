// @noflow

import type {EventError} from 'src/stores/event';

import * as React from 'react';

import {parseEventErrors} from 'src/actions/event';
import {classify} from 'src/utils';

import FormattedPlural from 'src/components/lib/intl/formatted-plural';

import css from './style.css';


type ErrorBoxProps = {
  className?: string,
  errors: {title?: string, reason: mixed}[],
  showHeading?: boolean,
  customHeader?: string,
  errorBoxClass?: string,
};

export class ErrorBox extends React.PureComponent<ErrorBoxProps> {
  render() {
    const {
      errors,
      className,
      showHeading = true,
      customHeader,
      errorBoxClass,
    } = this.props;
    return (
      <div className={classify(css.root, className)}>
        {showHeading && (
          <h4 className={css.errorHeading}>
            {errors.length > 0 ? (
              <FormattedPlural
                value={errors.length}
                one={customHeader || 'There was a problem'}
                other={customHeader || 'There were some problems'}
              />
            ) : (
              'No Problems'
            )}
          </h4>
        )}
        <div className={classify(css.errorBox, errorBoxClass)}>
          <ul className={css.errorList}>
            {errors.map((error, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <li className={css.errorItem} key={idx}>
                {error.title && (
                  <span>
                    <strong className={css.errorHead}>{error.title}</strong>
                    &nbsp;-&nbsp;
                  </span>
                )}
                {error.reason}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export const StringErrorBox = ({errors, ...props}: {errors: string[]}) => (
  <ErrorBox {...props} errors={errors.map((reason) => ({reason}))} />
);

export class EventErrorBox extends React.PureComponent<{
  errors: EventError,
  className?: string,
}> {
  render() {
    const {className = '', showHeading = true} = this.props;
    const formattedErrors = parseEventErrors(this.props.errors);

    if (formattedErrors.length > 0) {
      return (
        <ErrorBox
          errors={formattedErrors}
          className={className}
          showHeading={showHeading}
        />
      );
    }
    return <div />;
  }
}
