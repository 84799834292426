// @flow

import type {Query} from 'src/types/router';
import type {NpsPlot, AnalyticsQuery} from 'src/types/report';
import type {RecipientResource} from 'src/types/survey';
import type {ThunkAction} from 'src/reducers';

import {thunkify as flow} from 'src/utils/thunks';
import {camel} from 'src/utils';
import {resolveDateRange} from 'src/utils/date-range';
import {resolveAnalyticsQuery} from 'src/utils/analytics-query';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api';
import {
  checkAnalyticsNpsV3Enabled,
  ANALYTICS_API_V3,
} from 'src/utils/analytics-api-migration';


const {stringify} = JSON;

export const RECEIVE_AGENCY = 'npsPlot/receiveAgency';
export const RECEIVE_WORKFLOW = 'npsPlot/receiveWorkflow';
export const RECEIVE_EVENT = 'npsPlot/receiveEvent';

export type AgencyNpsPlotAction = {
  type: 'npsPlot/receiveAgency',
  payload: NpsPlot,
  // $FlowFixMe[value-as-type] [v1.32.0]
  recipient?: RecipientResource,
};
export type WorkflowNpsPlotAction = {
  type: 'npsPlot/receiveWorkflow',
  payload: {
    plot: NpsPlot,
    workflowId: string,
  },
};
export type EventNpsPlotAction = {
  type: 'npsPlot/receiveEvent',
  payload: {
    plot: NpsPlot,
    workflowId: string,
    eventId: string,
  },
};
export type NpsPlotAction =
  | AgencyNpsPlotAction
  | WorkflowNpsPlotAction
  | EventNpsPlotAction;

export const receiveAgency = (
  payload: NpsPlot,
  // $FlowFixMe[value-as-type] [v1.32.0]
  recipient?: RecipientResource,
): AgencyNpsPlotAction => ({
  type: RECEIVE_AGENCY,
  payload,
  recipient,
});
export const receiveWorkflow = (
  plot: NpsPlot,
  workflowId: string,
): WorkflowNpsPlotAction => ({
  type: RECEIVE_WORKFLOW,
  payload: {
    plot,
    workflowId,
  },
});
export const receiveEvent = (
  plot: NpsPlot,
  workflowId: string,
  eventId: string,
): EventNpsPlotAction => ({
  type: RECEIVE_EVENT,
  payload: {
    plot,
    workflowId,
    eventId,
  },
});

const querying =
  (allowed = ['client', 'event_types', 'recipients']) =>
  (func) =>
  (...args) => {
    const lastIndex = args.length - 1;
    args[lastIndex] = resolveNpsPlotQuery(args[lastIndex], allowed);
    return func(...args);
  };

const queryingNps =
  (allowed = ['client', 'event_types', 'recipients']) =>
  (func) =>
  (...args) => {
    const lastIndex = args.length - 1;
    const {time_unit, ...rest} = args[lastIndex];

    let addition;
    if (time_unit === 'day') {
      addition = {step_day: 1};
    } else if (time_unit === 'week') {
      addition = {step_week: 1};
    } else if (time_unit === 'month') {
      addition = {step_month: 1};
    } else {
      addition = {step_week: 1};
    }
    args[lastIndex] = resolveAnalyticsQuery(args[lastIndex], allowed);
    args[lastIndex] = {...args[lastIndex], ...addition};
    return func(...args);
  };

function resolveNpsPlotQuery(query: Query, allowed) {
  const {time_unit, ...rest} = query;

  let addition;
  if (time_unit === 'day') {
    addition = {step_day: 1};
  } else if (time_unit === 'week') {
    addition = {step_week: 1};
  } else if (time_unit === 'month') {
    addition = {step_month: 1};
  } else {
    addition = {step_week: 1};
  }
  // $FlowFixMe[cannot-spread-indexer]
  // $FlowFixMe[incompatible-call]
  return {...resolveDateRange(rest, allowed), ...addition};
}
type NpsPlotQuery = {
  ...AnalyticsQuery,
  query: Query,
};
export const getAgencyNpsPlot: (
  recipient?: RecipientResource,
  query: NpsPlotQuery,
) => ThunkAction<mixed> = flow(
  queryingNps(),
  key((recipient, _query) => `npsPlot/agency:${recipient}`),
  cached((json, recipient, _query) => receiveAgency(camel(json), recipient), {
    hash: (recipient, query) =>
      `npsPlot/agency:${recipient}:${stringify(query)}`,
  }),
  fetching(),
)((recipient?: RecipientResource, query: NpsPlotQuery) =>
  reduxApi.post(
    '/plot-nps',
    query,
    {},
    {
      apiPath: checkAnalyticsNpsV3Enabled()
        ? ANALYTICS_API_V3
        : '/api/v1/analytics',
    },
  ),
);

export const getWorkflowNpsPlot: (
  workflowId: string,
  query: Query,
) => ThunkAction<mixed> = flow(
  querying(),
  key((workflowId, query) => `npsPlot/workflow:${workflowId}`),
  cached((json, workflowId) => receiveWorkflow(camel(json), workflowId), {
    hash: (workflowId, query) =>
      `npsPlot/workflow:${workflowId}:${stringify(query)}`,
  }),
  fetching(),
)((workflowId: string, query: Query) =>
  reduxApi.post(
    `/workflows/${workflowId}/plot-nps`,
    query,
    {},
    {
      apiPath: ANALYTICS_API_V3,
    },
  ),
);

export const getEventNpsPlot: (
  workflowId: string,
  eventId: string,
  query: Query,
) => ThunkAction<mixed> = flow(
  querying(['client', 'recipients']),
  key(
    (workflowId, eventId, query) => `npsPlot/workflow:${workflowId}:${eventId}`,
  ),
  cached(
    (json, workflowId, eventId) =>
      receiveEvent(camel(json), workflowId, eventId),
    {
      hash: (workflowId, eventId, query) =>
        `npsPlot/workflow:${workflowId}:${eventId}:${stringify(query)}`,
    },
  ),
  fetching(),
)((workflowId: string, eventId: string, query: Query) =>
  reduxApi.post(
    `/workflows/${workflowId}/events/${eventId}/plot-nps`,
    query,
    {},
    {
      apiPath: ANALYTICS_API_V3,
    },
  ),
);
