// @noflow

import type {State as AudienceMembersState} from './audience-members';
import type {State as FieldsState} from './fields';
import type {AudienceMemberAction} from 'src/action-creators/audience-members/audience-members';
import type {FieldsAction} from 'src/action-creators/audience-members/fields';

import {combineReducers} from 'redux';

import fields from './fields';
import audienceMembers from './audience-members';


export type State = {|
  audienceMembers: AudienceMembersState,
  fields: FieldsState,
|};

type Action = AudienceMemberAction | FieldsAction;

export default (combineReducers({
  fields,
  audienceMembers,
}): (state: State, action: Action) => State);
