// @flow strict

import * as React from 'react';
import css from './vertical-timeline.css';


export const VerticalTimeline = ({
  children,
  baseLineColor,
  baseLineStyle,
}: {
  children: React.Node,
  baseLineColor?: string,
  baseLineStyle?: 'solid' | 'dashed',
}): React.Node => {
  const customStyle = {};
  if (baseLineStyle) {
    customStyle['--baseLineStyle'] = baseLineStyle;
  }
  if (baseLineColor) {
    customStyle['--baseLineColor'] = baseLineColor;
  }

  return (
    <div className={css.timelineContainer} style={customStyle}>
      {children}
    </div>
  );
};
