// @noflow

import type {EntityFilter} from 'src/types/ats-settings';
import type {AnalyticsQuery} from 'src/types/report';

import flow from 'lodash/flow';
import omit from 'lodash/omit';
import isPlainObject from 'lodash/isPlainObject';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import pick from 'lodash/pick';
import sculpt from 'sculpt';
import logger from 'src/utils/logger';
import {resolveDateRange} from 'src/utils/date-range';
import {checkExternalLinkLatestApiEnabled,getDefaultRange} from 'src/utils/analytics-api-migration';



export type {AnalyticsQuery};

/**
 * parse an encodeduricomponent json object back into an object
 *
 * use this to decode ?q= analytics querystring parameters
 */
export const parseQuery = (query: string): AnalyticsQuery => {
  const decoded = decodeURIComponent(query);
  try {
    return JSON.parse(decoded);
  } catch (_e) {
    return {};
  }
};

export const stringifyQuery = (query: AnalyticsQuery): string =>
  flow(JSON.stringify, encodeURIComponent)(query);

const shouldUnset = (value: mixed, key: string) =>
  key === '$set' &&
  ((Array.isArray(value) && value.length === 0) || value === undefined);

const unsetEmptyValues = (update: any): any => {
  if (isPlainObject(update)) {
    const newUpdate = {};
    forEach(update, (val, key) => {
      // Adds `$unset` to the proper level when object contains $set with empty array or undefined.
      if (some(val, shouldUnset)) {
        newUpdate.$unset = [...(newUpdate.$unset || []), key];
        return;
      }
      if (shouldUnset(val, key)) {
        return;
      }
      newUpdate[key] = unsetEmptyValues(val);
    });
    return newUpdate;
  }
  return update;
};

export type UpdateOptions = {entityDataFilter?: boolean};
/**
 * Apply a sculpt-compatible spec to a parsed querystring.
 *
 * For instance updateQuery(stringifyQuery({a:1, b:2}), {b:3}) === stringifyQuery({a:1, b:3})
 */
export const updateQuery = (
  query: string,
  update: Object,
  {entityDataFilter}: {entityDataFilter?: boolean} = {},
): string => {
  const resolvedUpdate = unsetEmptyValues(update);
  const queryUpdate = entityDataFilter ? {edf: resolvedUpdate} : resolvedUpdate;
  return stringifyQuery(sculpt(parseQuery(query), queryUpdate));
};

const isRangeQuery = (value) =>
  isPlainObject(value) &&
  (value.start || value.startDate || value.min || value.endDate);

export function queryIsEmpty(query: AnalyticsQuery): boolean {
  return Object.keys(query).length === 0;
}

// TODO (kyle): endpoint-specific query option should go in
// their own files.
export const timeFilters = ['start_date', 'end_date', 'time_ago'];

export const paginationFilters = ['page', 'offset', 'limit'];

export const commonFilters = [
  'event_types',
  'specific_issues',
  'nps_only',
  'nps_types',
  'recipients',
  'from_address',
];

export const metricsFilters = ['tracking_events'];

export const eventFilters = ['workflowId', 'eventId'];

export const plotFilters = ['time_unit', 'step_day', 'step_week', 'step_month'];

export const engagementFilters = ['num_events_missed'];

export const eventResponsesFilters = ['flagged', 'audienceMemberId'];

export const sortFilters = ['sort_order', 'sort'];

// TODO (kyle): entitDataFilters should go in their own property.
// this will remove the need to filter them out of the query.
const _nonEntityDataFilters = [
  ...timeFilters,
  ...paginationFilters,
  ...commonFilters,
  ...metricsFilters,
  ...eventFilters,
  ...plotFilters,
  ...engagementFilters,
  ...eventResponsesFilters,
  ...sortFilters,
];

// TODO (kyle): combine non-entity and allowed
export const resolveAnalyticsQuery = (
  query: Object,
  allowed: string[],
): AnalyticsQuery => {
  const resolvedQuery = resolveDateRange(omit(query, 'edf'), allowed);
  // TODO(marcos): ENGAGE-2497 why is query undefined ever?
  const entityDataFilters = query?.edf ? Object.keys(query.edf) : [];
  return {
    ...resolvedQuery,
    ...(entityDataFilters.length > 0
      ? {
          entity_data_filter: {
            and: entityDataFilters.map((field) => {
              let value = query.edf[field];
              if (isRangeQuery(value)) {
                return {
                  and: [
                    {lte: {field, value: value.end || value.endDate}},
                    {gte: {field, value: value.start || value.startDate}},
                  ],
                };
              }
              let key;
              if (Array.isArray(value)) {
                if (value.length === 1) {
                  key = 'eq';
                  value = value[0];
                } else {
                  key = 'in';
                }
              } else {
                key = 'eq';
              }
              return {
                [key]: {field, value},
              };
            }),
          },
        }
      : {}),
  };
};

export const resolveAnalyticsPath = (
  endpoint: string,
  {
    workflowId,
    eventId,
  }: {
    workflowId?: string,
    eventId?: string,
  } = {},
  responseV3Enabled = false,
): string => {
  let url = '';
  if (workflowId) {
    url = url + `/workflows/${workflowId}`;

    if (eventId) {
      url = url + `/events/${eventId}`;
    }
  }

  if (
    responseV3Enabled ||
    (endpoint === 'sent-links' && checkExternalLinkLatestApiEnabled())
  ) {
    return url + '/' + endpoint;
  } else {
    url = 'analytics' + url + '/' + endpoint;
  }
  return url;
};

/**
 * given a list of attributes, restricts the given analytics query
 * string to those attributes only. useful for navigating between
 * analytics pages.
 *
 * if the query is clean, it returns undefined
 * otherwise, it returns the cleaned query string
 */
export function cleanUpAttributeFilters(
  attributes: EntityFilter[],
  query: string,
): ?string {
  const filters = parseQuery(query);
  const {edf} = filters;

  if (edf) {
    const filtersToCopy = attributes
      .map(
        (attribute) => `${attribute.entity_type}/${attribute.attribute_name}`,
      )
      .filter((filterName) => edf[filterName]);

    if (
      filtersToCopy.length > 0 &&
      Object.keys(edf).length > filtersToCopy.length
    ) {
      const newEdf = pick(edf, filtersToCopy);

      logger.log(
        'Adjusting entity attribute filters to fit workflow type.',
        edf,
      );

      return stringifyQuery({
        ...filters,
        edf: newEdf,
      });
    }
  }
}

export const resolveAnalyticsQueryV2 = (
  query: Object,
  allowed: string[],
): AnalyticsQuery => {
  const resolvedQuery = resolveDateRange(omit(query, 'edf'), allowed, {
    days: getDefaultRange(),
  });
  // TODO(marcos): ENGAGE-2497 why is query undefined ever?
  const entityDataFilters = query?.edf ? Object.keys(query.edf) : [];
  return {
    ...resolvedQuery,
    ...(entityDataFilters.length > 0
      ? {
          entity_data_filter: {
            and: entityDataFilters.map((field) => {
              let value = query.edf[field];
              if (isRangeQuery(value)) {
                return {
                  and: [
                    {lte: {field, value: value.end || value.endDate}},
                    {gte: {field, value: value.start || value.startDate}},
                  ],
                };
              }
              let key;
              if (Array.isArray(value)) {
                if (value.length === 1) {
                  key = 'eq';
                  value = value[0];
                } else {
                  key = 'in';
                }
              } else {
                key = 'eq';
              }
              return {
                [key]: {field, value},
              };
            }),
          },
        }
      : {}),
  };
};
