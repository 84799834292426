// @noflow

import type {Action} from 'src/action-creators/messages';
import type {ThreadListAction} from 'src/action-creators/thread-lists';
import type {ThreadAction} from 'src/action-creators/threads';
import type {ThreadOwner} from 'src/types/account';
import type {Broadcast} from 'src/types/broadcasts';
import type {
  Thread,
  Message,
  MessagePending,
  InboxFilter,
  InboxNotification,
  UnreadCounts,
  UnreadCountsByPhoneNumberSet,
  UnreadCountsByQueue,
  UnreadCountsOfAllInbox,
  MeetingsEventData,
} from 'src/types/messages';

import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import omit from 'lodash/omit';
import mergeWith from 'lodash/mergeWith';

import {compareTimestamps} from 'src/utils/date-time';
import {
  DEFAULT_PAGE_SIZE,
  RECEIVE_MESSAGES,
  RECEIVE_NEW_MESSAGE,
  START_POLLING,
  STOP_POLLING,
  MARK_MESSAGES_FROM_PHONE_AS_READ,
  MARK_THREAD_COMPLETE,
  RECEIVE_PENDING_MESSAGE,
  ACKNOWLEDGE_MOST_RECENT_UNREAD,
  INBOX_MOUNTED,
  INBOX_UNMOUNTED,
  CHANGE_READ_ONLY_MODE,
  RECEIVE_UNREAD_COUNTS,
  UPDATE_UNREAD_COUNTS,
  // new stuff
  RECEIVE_INBOX,
  RECEIVE_THREAD,
  RECEIVE_THREAD_UPDATE,
  RECEIVE_CONVERSATION_CONTEXT_EVENTS,
  ASSIGN_THREAD_OWNER,
  REMOVE_THREAD_OWNER,
  SET_INBOX_FILTER,
  SET_FCM_TOKEN,
  SET_THREAD_UNREAD_FLAG,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  SET_MESSAGE_HISTORY_WINDOW,
  CLEAR_MESSAGE_HISTORY_WINDOW,
  RECEIVE_SCHEDULER_EVENT,
  RECEIVE_TYPING_EVENT,
  RECEIVE_READ_STATUS_EVENT,
  SET_BANNER_CARD_HOVER,
  SET_WARNING_TEXT_HOVER,
  SET_CONTENT_WARNING_CLICK_INFO,
  SET_MULTI_CHANNEL_INBOX,
  CALL_CONTENT_BANNER_API,
  MESSAGE_RECEIVED_NUMBER,
  MESSAGE_ACTIVE_ACTION_TYPE,
  MESSAGE_ACTIVE_TEMPLATE,
  SHOW_USER_DETAILS_ICON,
  UPDATE_OPT_OUT_IN_THREAD,
  MEETINGS_SCHEDULE_EVENT_DATA,
  SET_EXTERNAL_EVENT_DETAILS,
} from 'src/action-creators/messages';
import {
  RECEIVE_BROADCAST,
  RECEIVE_SCHEDULED_MESSAGE,
  REMOVE_SCHEDULED_MESSAGE,
} from 'src/action-creators/broadcasts';
import {RECEIVE_ONE as RECEIVE_THREAD_LIST} from 'src/action-creators/thread-lists';
import {RECEIVE_THREADS, PAGE_LIMIT} from 'src/action-creators/threads';
import {MSG_DIRECTION_INCOMING} from 'src/types/messages';
import {moreRecent} from 'src/selectors/messages';
import {reducePage} from 'src/reducers/pagination';


export type State = {
  // Pointer to most recent message for e.g., polling for new messages
  mostRecentId: string,
  byId: {
    [id: string]: Message,
  },
  threadsByPhone: {
    // NOTE(gab): Messages have to be grouped by phone, not by contact id, because a
    // single contact may have more than one phone, and as of the time of this
    // comment, we decided to not merge conversations across multiple phones
    [phone: string]: string[], // array of message ids
  },
  isPolling: boolean,

  acknowledgedId: string,

  inboxViewIsMounted: boolean,

  readOnly: boolean,

  inboxOffset: {
    [phoneNumberSetId: string]: number,
  },
  inboxMaxOffsetReached: {
    [phoneNumberSetId: string]: boolean,
  },

  unreadTotal: UnreadCounts,
  unreadByPhoneNumberSet: UnreadCountsByPhoneNumberSet,
  unreadByQueue: UnreadCountsByQueue,
  unreadCountsOfAllInbox: UnreadCountsOfAllInbox,
  unreadTimestamp: number,

  // Has the app received messages, even once? (doesn't matter if message array was empty)
  hasReceived: boolean,

  // NEW STUFF
  threads: {
    [threadId: string]: Thread,
  },
  messages: {
    [messageId: string]: Message,
  },
  scheduledMessages: {
    [scheduledMessageId: string]: Broadcast,
  },
  // NOTE (gab): keep track of threads that we've come to the end of
  threadsByPhoneComplete: {
    [phone: string]: boolean,
  },
  // NOTE (gab): Because we optimistically render, we need a place to store new
  // messages that are in the process of being POSTed to the server.
  pendingMessages: {
    [uid: string]: MessagePending,
  },
  threadOrder: string[],
  threadsDone: boolean,
  inboxFilter: InboxFilter,
  notifications: Array<InboxNotification>,
  fcmToken: string,
  threadMarkedUnread: boolean,
  messageHistoryWindow: {
    mostRecentMessage: Message,
    complete: boolean,
  },
  schedulerEvents: {
    [id: string]: any,
  },
  typingStatus: {
    [id: string]: {[id: string]: boolean},
  },
  owners: {[threadId: string]: ThreadOwner},
  showUserDetailsIcon: boolean,
  contentWarningClickTextType: string,
  contentWarningClickStartOffset: number,
  contentWarningClickEndOffset: number,
  meetingsEventData: MeetingsEventData,
};

const initialState = {
  mostRecentId: '',
  byId: {},
  // A thread is an array of message ids in reverse chronological order representing
  // a conversation between the logged in user and one of her contacts
  threadsByPhone: {},
  threadsByPhoneComplete: {},
  pendingMessages: {},
  isPolling: false,

  // The last unread message that the user acknowledged. Note that acknowledgment
  // requires user interaction on the client, this value can never be set server-side.
  // It's a client-only attribute
  acknowledgedId: '',

  inboxViewIsMounted: false,

  readOnly: false,

  inboxOffset: {},
  inboxMaxOffsetReached: {},

  unreadTotal: {
    count: 0,
    unread_count: 0,
    thread_count: 0,
  },
  unreadByPhoneNumberSet: {},
  unreadByQueue: {},
  unreadCountsOfAllInbox: {},
  unreadTimestamp: 0,

  hasReceived: false,

  isMmsLoading: false,

  // new stuff
  threads: {},
  messages: {},
  threadOrder: [],
  threadsDone: false,
  threadMarkedUnread: false,
  schedulerEvents: {},
  // TODO (kyle): remove
  scheduledMessages: {},
  inboxFilter: null,
  notifications: [],
  fcmToken: '',
  messageHistoryWindow: null,
  owners: {},
  typingStatus: {},
  messagingChannelType: 'sms',
  activeActionType: '',
  activeTemplate: null,
  callContentBannerApi: false,
  showUserDetailsIcon: true,
  contentWarningClickTextType: '',
  contentWarningClickStartOffset: 0,
  contentWarningClickEndOffset: 0,
  meetingsEventData: {
    candidateName: '',
    candidatePhone: '',
    candidateEmail: '',
    jobTitle: '',
    requisitionId: '',
    meetingDescription: '',
    applicationLink: '',
    anchorExternalSourceId: '',
  },
  externalEventDetails: {phoneNumbers: [], channel: 'sms', origin: ''},
};

function pluck(list, key) {
  return list.map((item) => item[key]);
}

function pluckByMessageId(list) {
  return pluck(list, 'messageId');
}

function union(listA, listB) {
  const listAIds = new Set(pluckByMessageId(listA));
  listA = listA.concat(listB.filter((m) => !listAIds.has(m.messageId)));

  return listA;
}

export default (
  state: State = initialState,
  action: Action | ThreadListAction | ThreadAction,
) => {
  switch (action.type) {
    case SET_EXTERNAL_EVENT_DETAILS:
      return {
        ...state,
        externalEventDetails: action.payload,
      };
    case SHOW_USER_DETAILS_ICON:
      return {
        ...state,
        showUserDetailsIcon: action.payload.value,
      };
    case CALL_CONTENT_BANNER_API:
      return {
        ...state,
        callContentBannerApi: action.payload.value,
      };
    case SET_MULTI_CHANNEL_INBOX:
      return {
        ...state,
        isInboxMultiChannel: action.payload.value,
      };
    case SET_BANNER_CARD_HOVER:
      return {
        ...state,
        contentBannerCardType: action.payload.type,
        contentBannerCardValue: action.payload.currValue,
      };
    case SET_WARNING_TEXT_HOVER:
      return {
        ...state,
        contentWarningTextType: action.payload.type,
        contentWarningTextValue: action.payload.currValue,
      };
    case SET_CONTENT_WARNING_CLICK_INFO: {
      return {
        ...state,
        contentWarningClickTextType: action.payload.type,
        contentWarningClickStartOffset: action.payload.start,
        contentWarningClickEndOffset: action.payload.end,
      };
    }
    case MESSAGE_ACTIVE_ACTION_TYPE:
      return {
        ...state,
        activeActionType: action.payload.value,
      };
    case MESSAGE_ACTIVE_TEMPLATE:
      return {
        ...state,
        activeTemplate: action.payload.value,
      };
    case START_POLLING:
      return {
        ...state,
        isPolling: true,
      };
    case MESSAGE_RECEIVED_NUMBER:
      return {
        ...state,
        receivedPhoneNumber: action.payload,
      };
    case STOP_POLLING:
      return {
        ...state,
        isPolling: false,
      };

    case ACKNOWLEDGE_MOST_RECENT_UNREAD: {
      const mostRecentUnreadMessage = action.payload;
      if (mostRecentUnreadMessage) {
        return {
          ...state,
          acknowledgedId: mostRecentUnreadMessage.id,
        };
      }
      break;
    }
    case RECEIVE_TYPING_EVENT: {
      const {threadId, agentId, status} = action.payload;
      const threadTypingStatus = {
        ...state.typingStatus[threadId],
        [agentId]: status,
      };

      return {
        ...state,
        typingStatus: {...state.typingStatus, [threadId]: threadTypingStatus},
      };
    }

    case RECEIVE_READ_STATUS_EVENT: {
      const {thread_id, agent_id, message_id} = action.payload;
      const thread = state.threads[thread_id];
      const threadMessage = state.messages[message_id]?.threadMessage;
      const lastMessageReadByAgents = {...thread.lastMessageReadByAgents};
      if (threadMessage) {
        lastMessageReadByAgents[agent_id] = threadMessage;
      }
      return {
        ...state,
        threads: {
          ...state.threads,
          [thread_id]: {...thread, lastMessageReadByAgents},
        },
      };
    }

    case RECEIVE_SCHEDULER_EVENT: {
      const {externalPhone, event} = action.payload;
      const currentEvent = state.schedulerEvents[externalPhone];

      return {
        ...state,
        schedulerEvents: {
          ...state.schedulerEvents,
          [externalPhone]:
            currentEvent && currentEvent.event_start < event.event_start
              ? currentEvent
              : event,
        },
      };
    }

    case INBOX_MOUNTED: {
      return {
        ...state,
        inboxViewIsMounted: true,
      };
    }

    case INBOX_UNMOUNTED: {
      return {
        ...state,
        inboxViewIsMounted: false,
      };
    }

    case CHANGE_READ_ONLY_MODE: {
      return {
        ...state,
        readOnly: action.payload,
      };
    }

    case RECEIVE_UNREAD_COUNTS: {
      const {
        unreadTotal,
        unreadByPhoneNumberSet = {},
        unreadByQueue = {},
        unreadTimestamp,
      } = action.payload;

      const oldTimestamp = state.messages.unreadTimestamp || 0;
      if (unreadTimestamp > oldTimestamp) {
        const updatedUnreadTotal = {
          ...unreadTotal,
          phone_number_sets_count: 0,
          queues_count: 0,
        };
        // calculate unread count for phone inbox
        for (const {thread_count} of Object.values(unreadByPhoneNumberSet)) {
          updatedUnreadTotal.phone_number_sets_count += thread_count;
        }
        // calculate unread count for queue inbox
        for (const {thread_count} of Object.values(unreadByQueue)) {
          updatedUnreadTotal.queues_count += thread_count;
        }

        return {
          ...state,
          unreadTotal: updatedUnreadTotal,
          unreadByPhoneNumberSet,
          unreadByQueue,
          unreadTimestamp,
          unreadCountsOfAllInbox: {...unreadByPhoneNumberSet, ...unreadByQueue},
        };
      } else {
        return {...state};
      }
    }

    case UPDATE_UNREAD_COUNTS: {
      const {
        unreadByPhoneNumberSet = {},
        unreadByQueue = {},
        unreadTimestamp,
      } = action.payload;
      const oldTimestamp = state.messages.unreadTimestamp || 0;
      if (unreadTimestamp > oldTimestamp) {
        // phone inbox unread count calculation
        const updatedUnreadByPhoneNumberSet = {
          ...state.unreadByPhoneNumberSet,
          ...unreadByPhoneNumberSet,
        };
        const unreadTotal = {
          thread_count: 0,
          count: 0,
          phone_number_sets_count: 0,
          queues_count: 0,
        };
        for (const {thread_count, count} of Object.values(
          updatedUnreadByPhoneNumberSet,
        )) {
          unreadTotal.thread_count += thread_count;
          unreadTotal.count += count;
          unreadTotal.phone_number_sets_count += count;
        }

        // queue inbox unread count calculation
        const updatedUnreadByQueue = {
          ...state.unreadByQueue,
          ...unreadByQueue,
        };
        for (const {thread_count, count} of Object.values(
          updatedUnreadByQueue,
        )) {
          unreadTotal.thread_count += thread_count;
          unreadTotal.count += count;
          unreadTotal.queues_count += count;
        }

        return {
          ...state,
          unreadTotal,
          unreadByPhoneNumberSet: updatedUnreadByPhoneNumberSet,
          unreadByQueue: updatedUnreadByQueue,
          unreadTimestamp,
          unreadCountsOfAllInbox: {
            ...updatedUnreadByPhoneNumberSet,
            ...updatedUnreadByQueue,
          },
        };
      } else {
        return {...state};
      }
    }
    // new stuff
    case RECEIVE_INBOX: {
      const {
        payload: {threads, displayMessages},
        meta: {offset, noIncrement, phoneNumberSetId},
      } = action;
      const threadCount = threads.length;
      const newThreads = {...state.threads};
      for (const thread of threads) {
        newThreads[thread.id] = mergeWith(
          {},
          newThreads[thread.id],
          thread,
          (a, b) => (b === null ? a : undefined),
        );
      }
      const inboxOffset =
        offset < 0 || noIncrement
          ? state.inboxOffset[phoneNumberSetId]
          : offset + threadCount;
      const newState = {
        ...state,
        hasReceived: true,
        inboxOffset: {...state.inboxOffset, [phoneNumberSetId]: inboxOffset},
        inboxMaxOffsetReached: {
          ...state.inboxMaxOffsetReached,
          [phoneNumberSetId]:
            offset < 0
              ? state.inboxMaxOffsetReached[phoneNumberSetId]
              : threadCount < DEFAULT_PAGE_SIZE,
        },
        threads: newThreads,
        messages: {
          ...state.messages,
          ...keyBy(displayMessages, 'id'),
        },
      };
      return newState;
    }

    case ASSIGN_THREAD_OWNER: {
      const {threadId, user} = action.payload;
      const ownersMap = keyBy(state.threads[threadId]?.owners, 'agentId');
      ownersMap[user.agentId] = user;
      const updatedOwners = Object.values(ownersMap);
      const newthread = {...state.threads[threadId], owners: updatedOwners};
      return {
        ...state,
        threads: {...state.threads, [threadId]: newthread},
      };
    }
    case REMOVE_THREAD_OWNER: {
      const {threadId, agentId} = action.payload;
      const ownersMap = keyBy(state.threads[threadId]?.owners, 'agentId');
      delete ownersMap[agentId];
      const updatedOwners = Object.values(ownersMap);
      return {
        ...state,
        threads: {
          ...state.threads,
          [threadId]: {...state.threads[threadId], owners: updatedOwners},
        },
      };
    }
    case SET_INBOX_FILTER: {
      const {filter, owned_by} = action.payload;
      return {
        ...state,
        inboxFilter: filter,
        inboxFilterOwnedBy: owned_by,
      };
    }

    case SET_FCM_TOKEN: {
      return {
        ...state,
        fcmToken: action.payload,
      };
    }

    case RECEIVE_MESSAGES: {
      return {
        ...state,
        messages: {
          ...state.messages,
          ...keyBy(action.payload, 'id'),
        },
        hasReceived: true,
      };
    }

    case RECEIVE_THREAD: {
      const {response, beforeTime} = action.payload;
      let thread = response;
      const {messages, broadcasts} = thread;

      if (beforeTime) {
        const contextEvents = values({
          ...keyBy(state.threads[thread.id]?.contextEvents || [], 'id'),
          ...keyBy(thread?.contextEvents || [], 'id'),
        });
        thread.contextEvents = contextEvents;
      }

      // NOTE (kyle): fix for API
      thread = {
        ...omit(thread, [
          'scheduledBroadcast',
          'messages',
          'broadcasts',
          'contact',
          'audienceMembers',
          'agents',
        ]),
      };

      if (
        state.threads[thread.id] &&
        state.threads[thread.id].threadMetadata &&
        thread.threadMetadata
      ) {
        const oldStateThreadMeta = state.threads[thread.id].threadMetadata;

        thread.threadMetadata.firstIncoming = union(
          thread.threadMetadata.firstIncoming,
          oldStateThreadMeta.firstIncoming,
        );
        thread.threadMetadata.firstOutgoing = union(
          thread.threadMetadata.firstOutgoing,
          oldStateThreadMeta.firstOutgoing,
        );
        thread.threadMetadata.coreMessages = union(
          thread.threadMetadata.coreMessages,
          oldStateThreadMeta.coreMessages,
        );
        thread.threadMetadata.sentBroadcasts = union(
          thread.threadMetadata.sentBroadcasts,
          oldStateThreadMeta.sentBroadcasts,
        );
        thread.threadMetadata.scheduledBroadcast = union(
          thread.threadMetadata.scheduledBroadcast,
          oldStateThreadMeta.scheduledBroadcast,
        );
      }

      return {
        ...state,
        threads: {
          ...state.threads,
          [thread.id]: thread,
        },
        messages: {
          ...state.messages,
          ...keyBy(messages, 'id'),
        },
        scheduledMessages: {
          ...state.scheduledMessages,
          [thread.id]: broadcasts,
        },
      };
    }

    case SET_THREAD_UNREAD_FLAG:
      const flag = action.payload;

      return {
        ...state,
        threadMarkedUnread: flag,
      };

    case MARK_THREAD_COMPLETE:
      return {
        ...state,
        threadsByPhoneComplete: {
          ...state.threadsByPhoneComplete,
          [action.payload]: true,
        },
      };
    case RECEIVE_CONVERSATION_CONTEXT_EVENTS: {
      const events = action.payload.filter(({threadId}) =>
        Boolean(state.threads[threadId]),
      );
      const threads = events.map(({threadId}) => state.threads[threadId]);
      const threadsToBeUpdated = keyBy(threads, 'id');
      for (const event of events) {
        const thread = threadsToBeUpdated[event?.threadId];
        if (thread) {
          const contextEvents = keyBy(thread.contextEvents || [], 'id');
          contextEvents[event.id] = event;
          threadsToBeUpdated[event.threadId] = {
            ...thread,
            contextEvents: values(contextEvents),
          };
        }
      }
      return {
        ...state,
        threads: {
          ...state.threads,
          ...threadsToBeUpdated,
        },
      };
    }

    case RECEIVE_THREAD_UPDATE: {
      const {threadId, update} = action.payload;

      // NOTE (kyle): when unarchiving a thread, we remove any associated
      // notifications.
      let notifications = state.notifications;
      if ('archived' in update && !update.archived) {
        notifications = notifications.filter((notification) => {
          const isFound =
            notification.type === 'archived' &&
            notification.threadId === threadId;
          if (isFound) {
            clearTimeout(notification.timeoutId);
          }
          return !isFound;
        });
      }

      return {
        ...state,
        notifications,
        threads: {
          ...state.threads,
          [threadId]: {
            ...state.threads[threadId],
            ...update,
          },
        },
      };
    }

    case UPDATE_OPT_OUT_IN_THREAD: {
      const {threadId, optOut} = action.payload;

      return {
        ...state,
        threads: {
          ...state.threads,
          [threadId]: {
            ...state.threads[threadId],
            optedOut: optOut,
          },
        },
      };
    }

    case RECEIVE_PENDING_MESSAGE: {
      const pendingMessage = action.payload;
      return {
        ...state,
        pendingMessages: {
          ...state.pendingMessages,
          [pendingMessage.id]: pendingMessage,
        },
      };
    }

    case RECEIVE_NEW_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.payload.id]: action.payload,
        },
        pendingMessages: omit(
          state.pendingMessages,
          action.meta.pendingMessageId,
        ),
      };

    case RECEIVE_THREAD_LIST:
    case RECEIVE_BROADCAST: {
      return reduceThreads(state, action.payload.threads);
    }

    case RECEIVE_SCHEDULED_MESSAGE: {
      const {threads, contacts, audienceMembers, ...broadcast} = action.payload;
      for (const thread of threads) {
        if (thread.id in state.scheduledMessages) {
          const threadScheduledMessages = state.scheduledMessages[thread.id];
          const index = threadScheduledMessages.findIndex(
            (scehduledMessage) => scehduledMessage.id === broadcast.id,
          );
          if (index === -1) {
            state.scheduledMessages[thread.id] = [
              ...state.scheduledMessages[thread.id],
              broadcast,
            ];
          } else {
            state.scheduledMessages[thread.id][index] = broadcast;
          }
        }
      }
      return state;
    }

    case REMOVE_SCHEDULED_MESSAGE: {
      const {id, threadIds} = action.payload;
      for (const threadId of threadIds) {
        if (threadId in state.scheduledMessages) {
          state.scheduledMessages[threadId] = state.scheduledMessages[
            threadId
          ].filter((msg) => msg.id !== id);
        }
      }
      return state;
    }

    case RECEIVE_THREADS: {
      const {threads, offset} = action.payload;
      let newState = reduceThreads(state, action.payload.threads);

      if (Number.isInteger(offset)) {
        newState = {
          ...newState,
          // $FlowFixMe upgrade babel and begin porting to inexact types
          threadOrder: reducePage(state.threadOrder, threads, offset),
          threadsDone: threads.length < PAGE_LIMIT,
        };
      }

      return newState;
    }

    case MARK_MESSAGES_FROM_PHONE_AS_READ:
      return {
        ...state,
        messages: {
          ...state.messages,
          ...keyBy(action.payload, 'id'),
        },
      };

    case ADD_NOTIFICATION:
      const notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload.id,
      );
      return {
        ...state,
        notifications: [...notifications, action.payload],
      };

    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification !== action.payload,
        ),
      };

    case SET_MESSAGE_HISTORY_WINDOW: {
      const {messages, lastMessageRead} = action.payload;

      let mostRecentMessage;
      for (const message of messages) {
        if (
          !mostRecentMessage ||
          message.timeCreated > mostRecentMessage.timeCreated
        ) {
          mostRecentMessage = message;
        }
      }

      if (
        Number(mostRecentMessage.threadMessage) > lastMessageRead ||
        (messages.length === 1 && messages[0].id === mostRecentMessage.id)
      ) {
        // handle this case
        return {
          ...state,
          messageHistoryWindow: {
            ...state.messageHistoryWindow,
            complete: true,
          },
        };
      }

      return {
        ...state,
        messageHistoryWindow: {mostRecentMessage, complete: false},
      };
    }

    case CLEAR_MESSAGE_HISTORY_WINDOW: {
      return {
        ...state,
        messageHistoryWindow: null,
      };
    }
    case MEETINGS_SCHEDULE_EVENT_DATA: {
      return {...state, meetingsEventData: action.payload};
    }
  }
  return state;
};

const receiveMessages = (state: State, action): State => {
  const messages = Array.isArray(action.payload)
    ? action.payload
    : [action.payload];
  const byId = {...state.byId};
  const threadsByPhone = {...state.threadsByPhone};
  let {mostRecentId, acknowledgedId} = state;

  const threadsChanged = {};

  for (const msg of messages) {
    const existingMsg = byId[msg.id];

    // byId
    // (must update byId first, because the rest of the loop assumes byId is up to date)
    byId[msg.id] =
      existingMsg && existingMsg.statusTimeUpdated > msg.statusTimeUpdated
        ? existingMsg
        : msg;

    // mostRecentId
    if (action.meta.shouldUpdateMostRecent) {
      if (!mostRecentId || moreRecent(msg, byId[mostRecentId])) {
        mostRecentId = msg.id;
        if (!acknowledgedId) {
          acknowledgedId = mostRecentId;
        }
      }
    }

    const phone =
      msg.direction === MSG_DIRECTION_INCOMING ? msg.fromPhone : msg.toPhone;

    let thread = threadsChanged[phone];
    if (!thread) {
      thread = threadsByPhone[phone];
      thread = threadsChanged[phone] = thread ? thread.slice() : [];
    }
    if (!thread.includes(msg.id)) {
      thread.push(msg.id);
    }

    /*
    // threadsByPhone
    // but only do this step if the message hasn't been sorted into a thread before
    // (i.e., don't duplicate)
    if (!existingMsg) {
      const phone =
        msg.direction === MSG_DIRECTION_INCOMING ? msg.fromPhone : msg.toPhone;
      const thread = threadsByPhone[phone] || [];

      // Both the `messages` array and the thread array are sorted most recent to least
      // recent. Therefore, iterate through the thread array in reverse since the oldest
      // messages are at the end and each new message we try to sort into the thread
      // array is older and older (mostly likely each new message will be sorted to
      // the end of the thread array)
      let insertIndex = thread.length + 1;
      while (--insertIndex) {
        // Since the thread array is already sorted we only have to do one comparison to
        // find the insertion index
        // NB: have to use <= vs < for stable sort
        const id = thread[insertIndex - 1];
        if (msg.timeCreated <= byId[id].timeCreated) {
          break;
        }
      }

      threadsByPhone[phone] = [
        ...thread.slice(0, insertIndex),
        msg.id,
        ...thread.slice(insertIndex, thread.length),
      ];
    }
    */
  }

  for (const phoneNumber in threadsChanged) {
    threadsByPhone[phoneNumber] = threadsChanged[phoneNumber].sort(
      (messageIdA, messageIdB) =>
        compareTimestamps(
          byId[messageIdA].timeCreated,
          byId[messageIdB].timeCreated,
        ),
    );
  }

  return {
    ...state,
    mostRecentId,
    acknowledgedId,
    byId,
    threadsByPhone,
  };
};

const reduceThreads = (state: State, threads: Thread[]) => {
  // NOTE (kyle): because these threads do not contain some
  // info, we merge them into the store.
  threads = threads.map((thread) => ({
    ...state.threads[thread.id],
    ...thread,
  }));

  return {
    ...state,
    threads: {
      ...state.threads,
      ...keyBy(threads, 'id'),
    },
  };
};
