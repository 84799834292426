// @flow strict
import type {
  LabelFilterType,
  FilterOptionType,
} from 'src/designSystem2021Components/types/label-filter';

import * as React from 'react';

import {classify} from 'src/utils/classify';

import {Clickable} from 'src/designSystem2021Components/text-v2.jsx';
import {Button} from 'src/designSystem2021Components/buttons.jsx';
import {FilterContainer} from 'src/designSystem2021Components/label-filter/container/filter-container.jsx';

import CaretDownIcon from 'src/images/icons/caret-down.svg';
import CaretUpIcon from 'src/images/icons/caret-up.svg';
import ChevronDoubleRightIcon from 'src/images/icons/chevron-double-right.svg';

import css from 'src/designSystem2021Components/label-filter/label-filter.css';
import dsCss from 'designSystem2021';


type FilterProps = {
  filterType?: LabelFilterType,
  text?: string,
  onClick?: (SyntheticEvent<HTMLElement>) => mixed,
  activeFilters?: number,
  isOpen?: boolean,
  className?: string,
  size?: 'standard' | 'small',
  disabled?: boolean,
};

export const LabelFilterComponent = ({
  filterType = 'menu',
  text,
  size,
  onClick,
  className,
  activeFilters = 0,
  isOpen = false,
  disabled = false,
}: FilterProps): React.Node => (
  <div
    className={classify(
      css.filterButton,
      className,
      activeFilters !== 0
        ? classify(css.activeButton, css.activeButtonText)
        : css.buttonCss,
      disabled ? css.disabled : null,
      size === 'small' ? css.smallContainer : null,
    )}
    disabled={disabled}
    onClick={onClick}
  >
    <div className={css.buttonContainer}>
      <Clickable>{text}</Clickable>
      {disabled && activeFilters === 0 ? null : (
        <ButtonIcon
          isOpen={isOpen}
          filterType={filterType}
          activeFilters={activeFilters}
          disabled={disabled}
          size={size}
        />
      )}
    </div>
  </div>
);

const ButtonIcon = ({
  isOpen,
  filterType,
  activeFilters,
  disabled,
  size,
}: {
  isOpen: boolean,
  disabled: boolean,
  filterType?: LabelFilterType,
  activeFilters?: number,
  size?: string,
}): React.Node => (
  <div
    className={classify(css.buttonIconContainer, {
      [css.activeButtonIcon]: activeFilters !== 0,
      [css.disabled]: disabled,
      [css.small]: size === 'small',
      [css.buttonIconActiveContainer]: !disabled && activeFilters !== 0,
    })}
  >
    {activeFilters !== 0 ? (
      <span className={dsCss.clickable}>{activeFilters}</span>
    ) : (
      ''
    )}
    {disabled ? null : filterType === 'menu' ? (
      <>
        {isOpen ? (
          <CaretUpIcon className={css.buttonIcon} />
        ) : (
          <CaretDownIcon className={css.buttonIcon} />
        )}
      </>
    ) : (
      <ChevronDoubleRightIcon className={css.buttonIcon} />
    )}
  </div>
);
