// @flow
import type {InvitesAction} from 'src/action-creators/account-invites';
import type {AccountInvite} from 'src/types/account-invite';

import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';

import {
  RECEIVE_ACCOUNT_INVITES,
  RECEIVE_ACCOUNT_INVITE,
  UPDATE_ACCOUNT_INVITE,
} from 'src/action-creators/account-invites';


export type InvitesByEmail = {
  [email: string]: AccountInvite,
  ...
};
export type InvitesState = {
  data: InvitesByEmail,
};

const initialState = {
  data: {},
};

export default (
  state: InvitesState = initialState,
  action: InvitesAction,
): InvitesState => {
  switch (action.type) {
    case RECEIVE_ACCOUNT_INVITE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.email]: action.payload,
        },
      };
    case UPDATE_ACCOUNT_INVITE:
      // $FlowIssue Object.values
      const accountInvites: Array<AccountInvite> = Object.values(state.data);
      const oldAccountInvite = accountInvites.find(
        accountInvite => accountInvite.inviteCode === action.payload.inviteCode,
      );
      const updatedData = {};
      if (oldAccountInvite) {
        updatedData[oldAccountInvite.email] = {
          ...oldAccountInvite,
          ...action.payload,
        };
      }
      return {
        ...state,
        data: {
          ...state.data,
          ...updatedData,
        },
      };
    case RECEIVE_ACCOUNT_INVITES:
      return {
        ...state,
        data: keyBy(action.payload, accountInvite => accountInvite.email),
      };
  }
  return state;
};
