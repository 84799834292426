// @flow

import type {
  Broadcast,
  BroadcastAndList,
  BroadcastAndMaybeList,
} from 'src/types/broadcasts';
import type {ThreadList, StateThreadList} from 'src/types/thread-lists';
import type {State} from 'src/reducers';

import {createSelector} from 'reselect';

import values from 'lodash/values';
import {compareTimestamps} from 'src/utils/date-time';
import {isProcessing} from 'src/utils/broadcasts';
import {selectAllThreadLists} from 'src/selectors/thread-lists';
import {getAuthedUserConfiguration} from 'src/utils/accounts';
import {
  selectPhoneNumberSetFromDefaultPhoneNumberSet,
  selectDefaultPhoneNumberSet,
} from 'src/selectors/chat';
//$FlowFixMe[nonstrict-import]
import {getCurrentAgent} from 'src/selectors/accounts';

import {selectIsDiscoverIframeContext} from 'src/selectors/draft-messages';


const getBroadcasts = (state: State): {[string]: Broadcast} =>
  state.broadcasts.map;

export const selectBroadcastsSorted: (state: State) => BroadcastAndList[] =
  createSelector(
    getBroadcasts,
    selectAllThreadLists,
    selectPhoneNumberSetFromDefaultPhoneNumberSet,
    (broadcasts, threadLists, phoneNumberSetId) => {
      let broadcastsArray = values(broadcasts);

      broadcastsArray = broadcastsArray
        .sort((a, b) => compareTimestamps(a.sendDate, b.sendDate))
        .map((broadcast) => populateBroadcast(broadcast, threadLists));

      // $FlowFixMe upgraded flow
      return broadcastsArray.filter(
        ({threadList, broadcast}) =>
          threadList && broadcast.phoneNumberSetId === phoneNumberSetId,
      );
    },
  );

export const selectInboxBroadcasts: (state: State) => BroadcastAndList[] =
  createSelector(selectBroadcastsSorted, (broadcasts) =>
    broadcasts.filter(
      // TODO (kyle): once we backfill, we should make this check
      // into !oneOnOne.
      ({threadList}) =>
        threadList && threadList.threadIds && threadList.threadIds.length > 1,
    ),
  );

export const selectUpcomingScheduledMessagesForThread: (
  state: State,
  threadId: string,
) => BroadcastAndList[] = createSelector(
  selectBroadcastsSorted,
  (_state, threadId) => threadId,
  (broadcasts, threadId) =>
    broadcasts.filter(
      ({broadcast, threadList}) =>
        // TODO (kyle): once we backfill, we should make this check
        // into oneOnOne.
        threadList &&
        threadList.threadIds &&
        threadList.threadIds.length === 1 &&
        broadcast.status === 'scheduled' &&
        !isProcessing(broadcast) &&
        threadList.threadIds.includes(threadId),
    ),
);

const populateBroadcast = (
  broadcast: Broadcast,
  threadLists: {[string]: ThreadList},
): BroadcastAndMaybeList => {
  const threadList = threadLists[broadcast.threadListId];
  return {
    broadcast,
    threadList: threadList && threadList.threadIds ? threadList : null,
  };
};

export const selectBroadcast = (state: State, broadcastId: string): Broadcast =>
  state.broadcasts.map[broadcastId];

export const selectPopulatedBroadcast: (
  State,
  string,
) => BroadcastAndMaybeList = createSelector(
  selectBroadcast,
  selectAllThreadLists,
  populateBroadcast,
);

export const selectBroadcastRecipientLimit = (state: State): number => {
  let broadcastLimit = 0;
  const agent = getCurrentAgent(state);
  if (agent?.id) {
    broadcastLimit = selectDefaultPhoneNumberSet(state)?.is_tollfree
      ? getAuthedUserConfiguration(state, 'tollfreeBroadcastRecipientLimit')
      : getAuthedUserConfiguration(state, 'broadcastRecipientLimit');
  }
  const isDiscoverIframeContext = selectIsDiscoverIframeContext(state);

  return isDiscoverIframeContext
    ? Math.min(broadcastLimit, 250)
    : broadcastLimit;
};
