// @flow

import * as React from 'react';

import HighlightWords from 'react-highlight-words';

import css from './highlighter.css';


type HighlighterProps = {
  className?: string,
  highlightClassName?: string,
  search?: string,
  searchWords?: string[],
  children?: string,
};

const Highlighter = ({
  search,
  searchWords,
  children,
  ...props
}: HighlighterProps): React.Node => (
  <HighlightWords
    highlightTag="span"
    highlightClassName={css.highlight}
    searchWords={searchWords || [search]}
    textToHighlight={children || ''}
    autoEscape={true}
    {...props}
  />
);

export default Highlighter;
