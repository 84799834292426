// @flow strict
import * as React from 'react';

import {useApi} from 'src/hooks/useApi';
import {CircularLoader} from '@spaced-out/ui-design-system/lib/components/CircularLoader';

import SignUpForm from 'src/components/authentication/signup/sign-up/sign-up.jsx';

import css from './signup-container.css';
import maincss from 'src/components/authentication/main.css';


const SignUpContainer = (): React.Node => {
  const [inviteCode, setInviteCode] = React.useState<string>('');

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const invite_code = params.get('invite_code');
    if (invite_code) {
      setInviteCode(invite_code);
    }
  }, []);

  const inviteFetch = useApi(`account_invites/${inviteCode}`, {
    shouldFetch: !!inviteCode,
  });

  if (inviteCode && inviteFetch.isLoading) {
    return (
      <div className={css.loader}>
        <CircularLoader colorToken="colorFillPrimary" size="large" />
      </div>
    );
  } else if (!inviteCode || !inviteFetch.result) {
    return (
      <SignUpForm
        inviteEmail={''}
        inviteCode={''}
        // $FlowFixMe[prop-missing]
        inviteCodeError={inviteFetch.error?.responseBody?.errors[0]}
      />
    );
  } else if (inviteFetch.result) {
    return (
      <SignUpForm
        inviteEmail={inviteFetch.result.email}
        inviteCode={inviteCode}
        inviteCodeError={''}
      />
    );
  } else {
    return <SignUpForm inviteEmail={''} inviteCode={''} inviteCodeError={''} />;
  }
};

export default SignUpContainer;
