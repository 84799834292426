var React = require('react');

function ChatNewMessage (props) {
    return React.createElement("svg",props,React.createElement("g",{"fillRule":"evenodd"},[React.createElement("path",{"d":"M3 4.001V20A1 1 0 004.001 21H20A1 1 0 0021 19.999V4A1 1 0 0019.999 3H4A1 1 0 003 4.001zm-2 0A3 3 0 014.001 1H20A3 3 0 0123 4.001V20A3 3 0 0119.999 23H4A3 3 0 011 19.999V4z","fillRule":"nonzero","key":0}),React.createElement("path",{"d":"M15.696 12.44v-1.68h-2.688V7.8h-1.856v2.96H8.464v1.68h2.688v3.088h1.856V12.44z","key":1})]));
}

ChatNewMessage.defaultProps = {"width":"24","height":"24","viewBox":"0 0 24 24"};

module.exports = ChatNewMessage;

ChatNewMessage.default = ChatNewMessage;
