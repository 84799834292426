// @flow strict

export type OpenPanelAction = {
  type: 'chat/panels/open-panel',
  payload: {
    isActionPanel: boolean,
  },
};

export type ClosePanelAction = {
  type: 'chat/panels/close-panel',
};

export type OpenMenuPanelAction = {
  type: 'chat/panels/open-menu-panel',
  payload: {
    value: boolean,
  },
};

export type StoreMenuPanelStateAction = {
  type: 'chat/panels/store-menu-panel-state',
  payload: {
    value: boolean,
  },
};
export type Action =
  | OpenPanelAction
  | ClosePanelAction
  | OpenMenuPanelAction
  | StoreMenuPanelStateAction;

export type PanelsAction = Action;

export const OPEN_PANEL = 'chat/panels/open-panel';
export const CLOSE_PANEL = 'chat/panels/close-panel';
export const OPEN_MENU_PANEL = 'chat/panels/open-menu-panel';
export const STORE_MENU_PANEL_STATE = 'chat/panels/store-menu-panel-state';

export const openMessagingPanel = (
  isActionPanel: boolean,
): OpenPanelAction => ({
  type: OPEN_PANEL,
  payload: {isActionPanel},
});

export const closeMessagingPanel = (): ClosePanelAction => ({
  type: CLOSE_PANEL,
});

export const openMessagingMenuPanel = (
  value: boolean,
): OpenMenuPanelAction => ({
  type: OPEN_MENU_PANEL,
  payload: {value},
});

export const restoreMenuPanelState = (
  value: boolean,
): StoreMenuPanelStateAction => ({
  type: STORE_MENU_PANEL_STATE,
  payload: {value},
});
