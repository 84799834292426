// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {
  Brand,
  QuestionApiError,
  JobResponse,
  JobDescription,
  QuestionnaireApiResponse,
} from 'src/types/referral-external';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';


export const RECEIVE_QUESTIONS = 'referral-external/receiveQuestions';
export const RECEIVE_QUESTIONS_ERROR =
  'referral-external/receiveQuestionsError';
export const RECEIVE_BRAND = 'referral-external/receiveBrand';

export const FETCHING_REFERRAL_JOBS = 'referral-external/jobsListing';
export const FETCHING_LOCATIONS = 'referral-external/locations';
export const FETCHING_DESCRIPTION = 'referral-extenal/description';

type ReceiveQuestionsAction = {
  type: typeof RECEIVE_QUESTIONS,
  payload: QuestionnaireApiResponse,
};

type ReceiveQuestionsErrorAction = {
  type: typeof RECEIVE_QUESTIONS_ERROR,
  payload: ?QuestionApiError,
};

type ReceiveBrandAction = {
  type: typeof RECEIVE_BRAND,
  payload: Brand,
};

type FetchingJobsAction = {
  type: typeof FETCHING_REFERRAL_JOBS,
  payload: JobResponse[],
};

type FetchingLocationsAction = {
  type: typeof FETCHING_LOCATIONS,
  payload: string[],
};

type FetchingJobDescriptionAction = {
  type: typeof FETCHING_DESCRIPTION,
  payload: JobDescription,
};

export type ReferralExternalAction =
  | ReceiveQuestionsAction
  | ReceiveQuestionsErrorAction
  | ReceiveBrandAction
  | FetchingJobsAction
  | FetchingLocationsAction
  | FetchingJobDescriptionAction;

const receiveQuestions = (
  data: QuestionnaireApiResponse,
): ReceiveQuestionsAction => ({
  type: RECEIVE_QUESTIONS,
  payload: data,
});

export const receiveQuestionsError = (
  response: QuestionApiError,
): ReceiveQuestionsErrorAction => ({
  type: RECEIVE_QUESTIONS_ERROR,
  payload: response,
});

const receiveBrand = (brand: Brand): ReceiveBrandAction => ({
  type: RECEIVE_BRAND,
  payload: brand,
});

const getJobsListing = (jobs: JobResponse[]): FetchingJobsAction => ({
  type: FETCHING_REFERRAL_JOBS,
  payload: jobs,
});

const getLocations = (locations: string[]): FetchingLocationsAction => ({
  type: FETCHING_LOCATIONS,
  payload: locations,
});

const getJobDescription = (
  jobDescription: JobDescription,
): FetchingJobDescriptionAction => ({
  type: FETCHING_DESCRIPTION,
  payload: jobDescription,
});

export const getQuestions: (referralLinkId: string) => ThunkAction<mixed> =
  flow(
    key(() => 'referral-external/receiveQuestions'),
    fetching(),
  )(
    (referralLinkId) => (dispatch: Dispatch) =>
      dispatch(
        reduxApi.get(
          `referral/questionnaire?referral_link_id=${referralLinkId}`,
        ),
      ).then((data: QuestionnaireApiResponse) =>
        // $FlowIssue object values
        dispatch(receiveQuestions(data)),
      ),
  );

export const getBrand: (referralLinkId: string) => ThunkAction<mixed> = flow(
  key(() => 'referral-external/receiveBrand'),
  fetching(),
)(
  (referralLinkId) => (dispatch: Dispatch) =>
    dispatch(
      reduxApi.get(`referral/brand?referral_link_id=${referralLinkId}`),
    ).then((data) => {
      // $FlowIssue object values
      dispatch(receiveBrand(data.brand));
    }),
);

type JobsFilter = {
  search_str: string,
  location: string[],
};

export const updatedJobsListing =
  (
    referralLinkId: string,
    agentId?: string,
    body: JobsFilter,
    isJobListEnabled: boolean,
  ): ThunkAction<mixed> =>
  (dispatch: Dispatch) => {
    const params = agentId
      ? `agent_id=${agentId}`
      : `referral_link_id=${referralLinkId}`;
    const apiUrl = `referral/${
      isJobListEnabled ? 'job-list/jobs' : 'jobs'
    }?${params}`;

    return dispatch(reduxApi.post(apiUrl, body)).then((data: JobResponse[]) => {
      // $FlowIssue object values
      dispatch(getJobsListing(data));
    });
  };

export const getTrackingJobList =
  (
    referralId: string,
    agentId: string,
    body: {search_str: string},
  ): ThunkAction<mixed> =>
  (dispatch: Dispatch) => {
    const apiUrl = `referral/tracking/job-list/jobs?agent_id=${agentId}&referral_id=${referralId}`;
    return dispatch(reduxApi.post(apiUrl, body)).then((data: JobResponse[]) => {
      // $FlowIssue object values
      dispatch(getJobsListing(data));
    });
  };

export const fetchLocations: (
  referralLinkId: string,
  agentId?: string,
) => ThunkAction<mixed> = flow(
  key(
    (referralLinkId: string) =>
      `referral/jobs/locations?referral_link_id=${referralLinkId}`,
  ),
  fetching(),
)((referralLinkId, agentId) => (dispatch: Dispatch) => {
  const params = agentId
    ? `agent_id=${agentId}`
    : `referral_link_id=${referralLinkId}`;
  return dispatch(reduxApi.get(`referral/jobs/locations?${params}`)).then(
    (data: string[]) => {
      // $FlowIssue object values
      dispatch(getLocations(data));
    },
  );
});

export const fetchDescription: (jobId: string) => ThunkAction<mixed> = flow(
  key((jobId: string) => `referral/job/${jobId}/description`),
  fetching(),
)(
  (jobId: string) => (dispatch: Dispatch) =>
    dispatch(reduxApi.get(`referral/job/${jobId}/description`)).then(
      (data: JobDescription) => {
        // $FlowIssue object values
        dispatch(getJobDescription(data));
      },
    ),
);
