// @noflow
//$FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores/index';
import type {RhetoricalQuestion} from 'src/types/survey.js';
//$FlowFixMe[import-value-as-type]
import type {CompositeDecorator, RawDraftContentState} from 'draft-js';
import type {DynamicLabels} from 'src/components/lib/markdown-editor';
import type {JobVariables} from 'src/types/job-variables';
import type {BooleanSelectOption, NumericSelectOption} from './constants';
import type {Dispatch} from 'src/reducers';

import React, {Component} from 'react';
import _get from 'lodash/get';
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from 'draft-js';

import {isDigest} from 'src/utils/events';
import {classify} from 'src/utils/classify';

import MarkdownEditor, {
  buildDecorator,
} from 'src/components/lib/markdown-editor/index2';
import {Separator} from 'src/components/lib/separator';
import {ErrorLabel} from 'src/components/lib/label/label.jsx';
//$FlowFixMe[untyped-import]
import {getMeEditorStateWithDynamicLabelsAsEntities} from 'src/components/lib/markdown-editor/dynamic-text.jsx';
import {pushModal} from 'src/action-creators/modal';
import {showJobMatchParamsModal} from 'src/action-creators/job-variables';

import {Select} from 'src/components/lib/token-list-input/new-stuff.jsx';
import {Button} from 'src/components/lib/new-button';
//$FlowFixMe[untyped-import]
import {uploadMedia} from 'src/actions/media';
//$FlowFixMe[untyped-import]
import {updateModule} from 'src/actions/event-creation';
import {
  JobMatchButton,
  JobLinkButton,
  JobBlockInfo,
  AltMessage,
} from './job-match.jsx';
import {INCLUDE_JOB_BLOCK_OPTIONS, JOB_MATCH_COUNT_OPTIONS} from './constants';
import {getNewEditorStateAfterReplacingPlaceholder} from './utils';

import css from './module.css';


const JOB_DATA = `<matched_job/title_with_link>\n
for <matched_job/company_name> located in <matched_job/location>\n`;

type JobMatchNotificationContainerProps = {
  //$FlowFixMe[value-as-type]
  fluxStore: IndexStore,
  moduleData: {
    text: string,
    include_job_block: boolean,
    rte_json: RawDraftContentState,
    draftEditorState?: EditorState,
    job_module_rte_json: RawDraftContentState,
    jobDraftEditorState?: EditorState,
    job_matches_count: number,
    job_matches_params: mixed,
    send_alt_text: boolean,
    alt_rte_json: RawDraftContentState,
    altDraftEditorState: EditorState,
  },
  canEdit: boolean,
  dynamicLabels: DynamicLabels,
  internalDynamicLabels: DynamicLabels,
  jobDynamicLabels: JobVariables,
  errors?: string[],
  error?: {
    errors: string[],
  },
  onUploadImage?: () => {},
  multiEntityEnabled: boolean,
  question: RhetoricalQuestion,
  jobBlockModuleIndex: number,
  index: number,
};

class JobMatchNotificationContainer extends Component<JobMatchNotificationContainerProps> {
  decorator: CompositeDecorator;
  labels: string[];
  initialEditorState: EditorState;
  initialJobEditorState: EditorState;
  initialAltEditorState: EditorState;
  handleEditorChange: (value: EditorState) => void;
  handleJobEditorChange: (value: EditorState) => void;
  handleAltEditorChange: (value: EditorState) => void;
  handleImageUpload: (files: FileList) => void;
  handleModuleChange: (value: {...}) => void;

  constructor(props: JobMatchNotificationContainerProps) {
    super(props);
    this.decorator = this.getDecorator();

    this.initialEditorState = this.deserializeRTEContents(
      props.moduleData.rte_json,
    );
    this.initialJobEditorState = props.moduleData.job_match_rte_json
      ? this.deserializeRTEContents(props.moduleData.job_match_rte_json)
      : getMeEditorStateWithDynamicLabelsAsEntities(
          EditorState.createWithContent(
            ContentState.createFromText(JOB_DATA),
            this.decorator,
          ),
          props.jobDynamicLabels,
        );
    this.initialAltEditorState = this.deserializeRTEContents(
      props.moduleData.alt_rte_json,
    );

    this.handleEditorChange = this._handleEditorChange.bind(this);
    this.handleJobEditorChange = this._handleJobEditorChange.bind(this);
    this.handleAltEditorChange = this._handleAltEditorChange.bind(this);
    this.handleImageUpload = this._handleImageUpload.bind(this);
    // this.handleJobBlockInsert=this._handleJobBlockInsert.bind(this);
    this.handleModuleChange = this._handleModuleChange.bind(this);
  }

  getDecorator = () =>
    buildDecorator({
      dynamicLabels: this.props.dynamicLabels,
      jobDynamicLabels: this.props.jobDynamicLabels,
      multiEntityEnabled: this.props.multiEntityEnabled,
      onPlaceholderReplace: () => {
        const reduxDispatch = this.props.fluxStore.reduxStore.dispatch;
        reduxDispatch(
          pushModal({
            type: 'VARIABLE_PICKER',
            insertResult: this.handlePlaceholderInsert,
            labels: this.props.dynamicLabels,
            baseEntityType: this.props.mainEntityType,
          }),
        );
      },
    });

  componentDidMount() {
    //update the store with default jobEditorState. otherwise default
    //wont be reflected in store.
    this.handleJobEditorChange(this.initialJobEditorState);
  }
  componentDidUpdate(prevProps: JobMatchNotificationContainerProps) {
    // Handle decorator changes here because demoComponent relies on componentDidMount
    // but this is generally how to handle dealing with updating the editor as a result
    // of props changes

    if (prevProps.dynamicLabels !== this.props.dynamicLabels) {
      // Update the decorators to include new labels
      this.decorator = this.getDecorator();
      this.updateEditorDecorator();
    }
  }

  updateEditorDecorator() {
    const {draftEditorState, rte_json} = this.props.moduleData;
    if (draftEditorState) {
      // update the current editorstate's decorators to include {survey_link} support
      const editorWithNewDecorator = EditorState.set(draftEditorState, {
        decorator: this.decorator,
      });
      this.handleEditorChange(editorWithNewDecorator);
    } else {
      // if no editor state is available, haven't called handelEditorChange once
      // so initialize it with the correct decorator list
      this.initialEditorState = this.deserializeRTEContents(rte_json);
    }
  }

  deserializeRTEContents = (rte_json?: RawDraftContentState) => {
    let content: ContentState;

    content = ContentState.createFromText('');

    if (rte_json) {
      // if we get rte_json, it's either being actively updated
      // or it's _just_ been deserialized by the api_parser
      content = convertFromRaw(rte_json); // no, we just deserialized content state
    }
    return EditorState.createWithContent(content, this.decorator);
  };

  _handleEditorChange(value: EditorState) {
    const json = convertToRaw(value.getCurrentContent());
    updateModule(this.props.fluxStore, {
      rte_json: json,
      draftEditorState: value,
    });
  }
  handlePlaceholderInsert = (dynamicLabel) => {
    const oldState = this.props.moduleData.draftEditorState;
    getNewEditorStateAfterReplacingPlaceholder(
      dynamicLabel,
      oldState,
      (value) => {
        this.handleEditorChange(value);
      },
    );
  };
  _handleJobEditorChange(value: EditorState) {
    const json = convertToRaw(value.getCurrentContent());
    updateModule(this.props.fluxStore, {
      job_match_rte_json: json,
      jobDraftEditorState: value,
    });
  }
  _handleAltEditorChange(value: EditorState) {
    const json = convertToRaw(value.getCurrentContent());
    updateModule(this.props.fluxStore, {
      alt_rte_json: json,
      altDraftEditorState: value,
    });
  }
  _handleModuleChange(updatedValue: {...}) {
    updateModule(this.props.fluxStore, updatedValue);
  }
  _handleImageUpload(files: FileList) {
    return uploadMedia(this.props.fluxStore, files[0]);
  }
  render() {
    const {
      errors,
      fluxStore,
      canEdit,
      moduleData: {
        draftEditorState,
        include_job_block,
        jobDraftEditorState,
        job_matches_count,
        job_matches_params,
        send_alt_text,
        altDraftEditorState,
      },
      dynamicLabels,
      multiEntityEnabled,
      internalDynamicLabels,
      jobDynamicLabels,
      parentEvent,
      jobBlockModuleIndex,
      index,
      error,
      showErrors,
    } = this.props;
    const editorState = draftEditorState || this.initialEditorState;
    const jobEditorState = jobDraftEditorState || this.initialJobEditorState;
    const altEditorState = altDraftEditorState || this.initialAltEditorState;
    const enableJobBlockInteractions =
      jobBlockModuleIndex === -1 || jobBlockModuleIndex === index;
    const isInBranch = !parentEvent; //logic copied from row.jsx
    const reduxDispatch = fluxStore.reduxStore.dispatch;
    return (
      <JobMatchNotificationEditor
        editorState={editorState}
        jobEditorState={jobEditorState}
        altEditorState={altEditorState}
        dynamicLabels={
          parentEvent && isDigest(parentEvent.eventType)
            ? internalDynamicLabels
            : dynamicLabels
        }
        jobDynamicLabels={jobDynamicLabels}
        onChange={this.handleEditorChange}
        onJobEditorChange={this.handleJobEditorChange}
        onAltEditorChange={this.handleAltEditorChange}
        onFileUpload={this.handleImageUpload}
        canEdit={canEdit}
        errors={errors}
        error={error}
        multiEntityEnabled={multiEntityEnabled}
        includeJobBlock={include_job_block}
        job_matches_count={job_matches_count}
        job_matches_params={job_matches_params}
        sendAltMessage={send_alt_text}
        handleModuleChange={this.handleModuleChange}
        enableJobBlockInteractions={enableJobBlockInteractions}
        isInBranch={isInBranch}
        dispatch={reduxDispatch}
        missingParamsError={showErrors === 'missing_job_match_params'}
      />
    );
  }
}

type JobMatchNotificationEditorProps = {
  editorState: EditorState,
  jobEditorState: EditorState,
  altEditorState: EditorState,
  dynamicLabels: DynamicLabels,
  jobDynamicLabels: JobVariables,
  onChange: (value: EditorState) => void,
  onJobEditorChange: (value: EditorState) => void,
  onAltEditorChange: (value: EditorState) => void,
  onFileUpload: (files: FileList) => void,
  errors?: string[],
  error?: {
    errors: string[],
  },
  canEdit?: boolean,
  multiEntityEnabled: boolean,
  includeJobBlock: boolean,
  job_matches_count: number,
  job_matches_params: mixed,
  sendAltMessage: boolean,
  handleModuleChange: (value: {...}) => void,
  enableJobBlockInteractions: boolean,
  isInBranch: boolean,
  dispatch: Dispatch,
  missingParamsError: boolean,
};

const JobMatchNotificationEditor = ({
  editorState,
  jobEditorState,
  altEditorState,
  dynamicLabels,
  jobDynamicLabels,
  onChange,
  onJobEditorChange,
  onAltEditorChange,
  onFileUpload,
  errors,
  error,
  canEdit,
  multiEntityEnabled,
  includeJobBlock,
  job_matches_count,
  job_matches_params,
  sendAltMessage,
  handleModuleChange,
  enableJobBlockInteractions,
  isInBranch,
  dispatch,
  missingParamsError,
}: JobMatchNotificationEditorProps) => {
  const handleIncludeJobModuleChange = (
    selectedOption: ?BooleanSelectOption,
  ) => {
    if (selectedOption) {
      handleModuleChange({include_job_block: selectedOption.value});
    }
  };
  const handleAltMessageOptionChange = (
    selectedOption: ?BooleanSelectOption,
  ) => {
    if (selectedOption) {
      handleModuleChange({send_alt_text: selectedOption.value});
    }
  };

  const handleEditJobMatchButtonClick = (dynamicLabels, onParamsChange) => {
    dispatch(
      showJobMatchParamsModal({
        dynamicLabels,
        job_matches_count,
        job_matches_params,
        onParamsChange,
      }),
    );
  };

  const selectedIncludeJobBlockOption = INCLUDE_JOB_BLOCK_OPTIONS.find(
    (item) => item.value === includeJobBlock,
  );

  const jobEditorErrors = _get(error, 'jobBlock.errors', []);
  const altMessageErrors = _get(error, 'altText.errors', []);
  return (
    <>
      <div className={css.notificationContainer}>
        <div
          className={classify(css.generalModule_content, {
            [css.branchModule_content]: isInBranch,
          })}
        >
          <label className={css.sectionLabel}>Text block:</label>
          <MarkdownEditor
            readOnly={!canEdit}
            containerClassName={css.notificationEditor}
            dynamicLabels={dynamicLabels}
            onChange={onChange}
            value={editorState}
            placeholder="e.g. Good luck on your first day!"
            handleImageUpload={onFileUpload}
            showErrors={!!errors}
            multiEntityEnabled={multiEntityEnabled}
          />
          {includeJobBlock && (
            <>
              <label className={classify(css.sectionLabel, css.jobBlockLabel)}>
                Job block — repeats once for each job:
              </label>

              <MarkdownEditor
                readOnly={!canEdit}
                dynamicLabels={jobDynamicLabels}
                containerClassName={css.jobEditor}
                className={css.jobEditor}
                onChange={onJobEditorChange}
                value={jobEditorState}
                placeholder="e.g. Enter job module data!"
                showErrors={!!jobEditorErrors}
                multiEntityEnabled={multiEntityEnabled}
                showDynamicVariableButton={false}
                CustomButton={(props) => (
                  <JobMatchButton {...props} dynamicLabels={jobDynamicLabels} />
                )}
                CustomLinkButton={(props) => (
                  <JobLinkButton {...props} dynamicLabels={jobDynamicLabels} />
                )}
              />
              {jobEditorErrors.length > 0 && (
                <ErrorLabel className={css.jobMatchTextError}>
                  {jobEditorErrors[0]}
                </ErrorLabel>
              )}
            </>
          )}
        </div>

        <div className={css.jobBlockInfoContainer}>
          <label className={css.sectionLabel}>Include jobs?</label>
          <div
            className={classify(css.jobBlockControls, {
              [css.jobBlockControlsYes]: includeJobBlock,
            })}
          >
            <div>
              <Select
                value={selectedIncludeJobBlockOption}
                classNames={{container: css.includeJobs}}
                options={INCLUDE_JOB_BLOCK_OPTIONS}
                onChange={handleIncludeJobModuleChange}
                clearable={false}
                disabled={!enableJobBlockInteractions}
              />
            </div>
            <JobBlockInfo
              includeJobBlock={includeJobBlock}
              enableJobBlockInteractions={enableJobBlockInteractions}
            />
            {includeJobBlock && (
              <div>
                <Button
                  className={classify(css.buttonWhite, {
                    [css.buttonWithError]: missingParamsError,
                  })}
                  onClick={() =>
                    handleEditJobMatchButtonClick(
                      dynamicLabels,
                      (job_matches_params, selectedJobMatchCount) => {
                        handleModuleChange({
                          job_matches_params,
                          job_matches_count: selectedJobMatchCount,
                        });
                      },
                    )
                  }
                >
                  Edit job matching options
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {includeJobBlock && (
        <React.Fragment>
          <Separator className={css.sectionSeparator} />
          <AltMessage
            sendAltMessage={sendAltMessage}
            dynamicLabels={dynamicLabels}
            onChange={onAltEditorChange}
            editorState={altEditorState}
            onFileUpload={onFileUpload}
            multiEntityEnabled={multiEntityEnabled}
            errors={altMessageErrors}
            onAltMessageOptionChange={handleAltMessageOptionChange}
          />
        </React.Fragment>
      )}
    </>
  );
};

export default JobMatchNotificationContainer;
