var React = require('react');

function Check (props) {
    return React.createElement("svg",props,React.createElement("path",{"fillRule":"evenodd","clipRule":"evenodd","d":"M20.507 5.447a.75.75 0 01.046 1.06l-11 12a.75.75 0 01-1.083.023l-5-5a.75.75 0 011.06-1.06l4.446 4.446L19.447 5.493a.75.75 0 011.06-.046z"}));
}

Check.defaultProps = {"width":"24","height":"24","viewBox":"0 0 24 24"};

module.exports = Check;

Check.default = Check;
