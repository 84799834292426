// @flow

import type {Query} from 'src/types/router';
import type {EventStatusPlot} from 'src/types/report';
import type {ThunkAction} from 'src/reducers';

import {thunkify as flow} from 'src/utils/thunks';
import {camel} from 'src/utils';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api';
import {resolveAnalyticsQuery} from 'src/utils/analytics-query';
import {
  checkAnalyticsNpsV3Enabled,
  ANALYTICS_API_V3,
} from 'src/utils/analytics-api-migration';


const {stringify} = JSON;

export const RECEIVE_AGENCY = 'plot/receiveAgency';
export const RECEIVE_WORKFLOW = 'plot/receiveWorkflow';
export const RECEIVE_EVENT = 'plot/receiveEvent';

export type AgencyPlotAction = {
  type: 'plot/receiveAgency',
  payload: EventStatusPlot,
};
export type WorkflowPlotAction = {
  type: 'plot/receiveWorkflow',
  payload: {
    plot: EventStatusPlot,
    workflowId: string,
  },
};
export type EventPlotAction = {
  type: 'plot/receiveEvent',
  payload: {
    plot: EventStatusPlot,
    workflowId: string,
    eventId: string,
  },
};
export type PlotAction =
  | AgencyPlotAction
  | WorkflowPlotAction
  | EventPlotAction;

export const receiveAgency = (payload: EventStatusPlot): AgencyPlotAction => ({
  type: RECEIVE_AGENCY,
  payload,
});
export const receiveWorkflow = (
  plot: EventStatusPlot,
  workflowId: string,
): WorkflowPlotAction => ({
  type: RECEIVE_WORKFLOW,
  payload: {
    plot,
    workflowId,
  },
});
export const receiveEvent = (
  plot: EventStatusPlot,
  workflowId: string,
  eventId: string,
): EventPlotAction => ({
  type: RECEIVE_EVENT,
  payload: {
    plot,
    workflowId,
    eventId,
  },
});

const querying =
  (allowed = ['client', 'event_types', 'recipients', 'nps_only']) =>
  (func) =>
  (...args) => {
    const lastIndex = args.length - 1;
    args[lastIndex] = resolvePlotQuery(args[lastIndex], allowed);
    return func(...args);
  };

function resolvePlotQuery(query: Query, allowed) {
  const {time_unit, ...rest} = query;

  let addition;
  if (time_unit === 'day') {
    addition = {step_day: 1};
  } else if (time_unit === 'week') {
    addition = {step_week: 1};
  } else if (time_unit === 'month') {
    addition = {step_month: 1};
  } else {
    addition = {step_week: 1};
  }

  return {...resolveAnalyticsQuery(rest, allowed), ...addition};
}

export const getAgencyPlot: (query: Query) => ThunkAction<mixed> = flow(
  querying(),
  key((query) => 'plot/agency'),
  cached((json) => receiveAgency(camel(json)), {
    hash: (query) => `plot/agency:${stringify(query)}`,
  }),
  fetching(),
)((query: Query) =>
  reduxApi.post(
    '/plot',
    query,
    {},
    {
      apiPath: checkAnalyticsNpsV3Enabled()
        ? ANALYTICS_API_V3
        : '/api/v1/analytics',
    },
  ),
);

export const getWorkflowPlot: (
  workflowId: string,
  query: Query,
) => ThunkAction<mixed> = flow(
  querying(),
  key((workflowId, query) => `plot/workflow:${workflowId}`),
  cached((json, workflowId) => receiveWorkflow(camel(json), workflowId), {
    hash: (workflowId, query) =>
      `plot/workflow:${workflowId}:${stringify(query)}`,
  }),
  fetching(),
)((workflowId: string, query: Query) =>
  reduxApi.post(`analytics/workflows/${workflowId}/plot`, query),
);

export const getEventPlot: (
  workflowId: string,
  eventId: string,
  query: Query,
) => ThunkAction<mixed> = flow(
  querying(['client', 'recipient']),
  key((workflowId, eventId, query) => `plot/workflow:${workflowId}:${eventId}`),
  cached(
    (json, workflowId, eventId) =>
      receiveEvent(camel(json), workflowId, eventId),
    {
      hash: (workflowId, eventId, query) =>
        `plot/workflow:${workflowId}:${eventId}:${stringify(query)}`,
    },
  ),
  fetching(),
)((workflowId: string, eventId: string, query: Query) =>
  reduxApi.post(
    `/workflows/${workflowId}/events/${eventId}/plot`,
    query,
    {},
    {
      apiPath: ANALYTICS_API_V3,
    },
  ),
);
