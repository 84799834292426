// @flow strict

import type {SplitAction} from 'src/action-creators/split';
import type {SplitData} from 'src/types/split';
import {
  ADD_SPLIT,
  ADD_TEMP_SPLIT_NODE,
  SET_SPLIT_RULES,
  SET_SPLIT_SCOPE,
  UPDATE_SPLIT_RULE,
  UPDATE_SPLIT_SCOPE,
  REMOVE_SPLIT_RULE,
  DELETE_SPLIT,
  RECEIVE_SPLITS,
} from 'src/action-creators/split';

// $FlowFixMe[nonstrict-import]: it's typed just not strict-typed
import produce from 'immer'; // eslint-disable-line import/no-unresolved

import {
  generateTempSplitNode,
  getCombinedId,
  getSplitContent,
} from 'src/utils/split';
import {
  createRuleFieldOperatorHash,
  ruleBlockContainsABTRule,
} from 'src/utils/filter';
import cloneDeep from 'lodash/cloneDeep';


const initialState = {};

export default (
  state: SplitData = initialState,
  action: SplitAction,
): SplitData => {
  switch (action.type) {
    case RECEIVE_SPLITS: {
      const splitsObj = action.payload;
      return cloneDeep(splitsObj);
    }
    case ADD_TEMP_SPLIT_NODE: {
      const {swid, ifEndNodeId, elseEndNodeId} = action.payload;
      return {
        ...state,
        ...generateTempSplitNode(swid, ifEndNodeId, elseEndNodeId),
      };
    }
    case ADD_SPLIT: {
      const {swid, nodeId, position, tempEndNodeId} = action.payload;
      const combinedId = getCombinedId(swid, nodeId);
      const next = produce(state, (draft) => {
        if (position > 0 && position < state[combinedId].length) {
          const firstSplitInfo = draft[combinedId][0];
          const previousSplitTitle = draft[combinedId][position - 1].title;
          draft[combinedId].splice(
            position,
            0,
            getSplitContent(firstSplitInfo, previousSplitTitle, tempEndNodeId),
          );
        }
      });
      return next;
    }
    case SET_SPLIT_RULES: {
      const {swid, nodeId, rules} = action.payload;
      const combinedId = getCombinedId(swid, nodeId);
      const next = produce(state, (draft) => {
        draft[combinedId].forEach((split, index) => {
          if (index < draft[combinedId].length - 1) {
            rules.forEach((rule) => {
              split.block.rules.push(rule);
            });
          }
        });
      });
      return next;
    }
    case SET_SPLIT_SCOPE: {
      const {swid, nodeId, scope} = action.payload;
      const combinedId = getCombinedId(swid, nodeId);
      const next = produce(state, (draft) => {
        draft[combinedId].forEach((split, index) => {
          if (index < draft[combinedId].length - 1) {
            split.block.scope = scope;
          }
        });
      });
      return next;
    }
    case UPDATE_SPLIT_RULE: {
      const {swid, nodeId, splitId, ruleId, updateObject} = action.payload;
      const combinedId = getCombinedId(swid, nodeId);
      const next = produce(state, (draft) => {
        const node = draft[combinedId];
        const split = node.find((s) => s.id === splitId);
        if (split) {
          const rule = split.block.rules.find((r) => r.id === ruleId);
          if (rule) {
            Object.assign(rule, updateObject);
          }
        }
      });
      return next;
    }
    case UPDATE_SPLIT_SCOPE: {
      const {swid, nodeId, updateObject} = action.payload;
      const combinedId = getCombinedId(swid, nodeId);
      const next = produce(state, (draft) => {
        const splits = draft[combinedId];
        splits.forEach(({block}) => {
          block.scope = updateObject;
        });
      });
      return next;
    }

    case REMOVE_SPLIT_RULE: {
      const {swid, nodeId, ruleHash} = action.payload;
      const combinedId = getCombinedId(swid, nodeId);
      const next = produce(state, (draft) => {
        const splits = draft[combinedId];
        splits.forEach(({block}) => {
          const indexToRemove = block.rules.findIndex(
            (r) => createRuleFieldOperatorHash(r) === ruleHash,
          );
          if (indexToRemove !== -1) {
            block.rules.splice(indexToRemove, 1);
            if (!ruleBlockContainsABTRule(block.rules)) {
              block.scope = null;
            }
          }
        });
      });
      return next;
    }

    case DELETE_SPLIT: {
      const {swid, nodeId, position} = action.payload;
      const combinedId = getCombinedId(swid, nodeId);
      const next = produce(state, (draft) => {
        if (position < state[combinedId].length) {
          draft[combinedId].splice(position, 1);
        }
      });
      return next;
    }
  }
  return state;
};
