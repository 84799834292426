// @noflow

import React from 'react';

import classify from 'src/utils/classify';

import ClickAway from 'src/components/lib/click-away';
import {FilterButton} from 'src/components/lib/analytics-filter-button';

import DownArrow from 'src/images/arrow-down.svg';

import css from './basic-dropdown.css';


type BasicDropdownProps = {
  options: Array<{value: string, label: string, disabled?: boolean}>,
  onChange: (selected: object) => mixed,
  selected: object,
  noOptionText: string,
  qaId: string,
};

export default (props: BasicDropdownProps) => {
  const {options, selected, onChange, noOptionText, hintText, qaId} = props;

  return (
    <div className={css.container} data-qa-id={`${qaId}-dropdown`}>
      <ClickAway>
        {({isOpen, onOpen}) => (
          <div className={css.filter}>
            <FilterButton
              className={classify(css.button, {
                [css.noRelatedEntities]: options.length === 0,
              })}
              active={false}
              onClick={onOpen}
            >
              {selected?.label || noOptionText}
              <DownArrow />
            </FilterButton>

            {isOpen && (
              <div className={css.filterCard}>
                <ul className={css.checklist}>
                  {hintText && <li className={css.hintText}>{hintText}</li>}
                  {options?.map(option => (
                    <li
                      key={option.value}
                      className={classify(css.option, {
                        [css.disabled]: option.disabled,
                      })}
                    >
                      <div
                        className={css.label}
                        onClick={
                          option.disabled ? undefined : () => onChange(option)
                        }
                        name={option.value}
                        data-qa-id={`${qaId}-option-${option.value}`}
                      >
                        {option.label}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </ClickAway>
    </div>
  );
};
