// @flow strict
import type {Event, Page, Job, Analytics} from 'src/types/drive/drive.js';
import type {DriveActions} from 'src/action-creators/drive/drive';
import {
  DRIVE_EVENTS,
  DRIVE_EVENTS_LOADING,
  DRIVE_EVENTS_ERROR,
  DRIVE_CREATE_EVENT,
  DRIVE_CREATE_EVENT_LOADING,
  DRIVE_CREATE_EVENT_ERROR,
  DRIVE_EDIT_EVENT,
  DRIVE_EDIT_EVENT_LOADING,
  DRIVE_EDIT_EVENT_ERROR,
  DRIVE_EVENT,
  DRIVE_EVENT_LOADING,
  DRIVE_EVENT_ERROR,
  DRIVE_PAGES,
  DRIVE_PAGES_LOADING,
  DRIVE_PAGES_ERROR,
  DRIVE_STATUS_PUBLISH,
  DRIVE_STATUS_ARCHIVE,
  DRIVE_STATUS_UPDATE_LOADING,
  DRIVE_JOBS,
  DRIVE_JOBS_LOADING,
  DRIVE_JOBS_ERROR,
  DRIVE_LINK_PAGE,
  DRIVE_UNLINK_PAGE,
  DRIVE_LINK_PAGE_LOADING,
  DRIVE_LINK_PAGE_ERROR,
  DRIVE_LINKED_JOBS,
  DRIVE_LINKED_JOBS_LOADING,
  DRIVE_LINKED_JOBS_ERROR,
  DRIVE_LINK_JOBS,
  DRIVE_LINK_JOBS_LOADING,
  DRIVE_EVENT_ANALYTICS,
  DRIVE_EVENT_ANALYTICS_LOADING,
  DRIVE_EVENT_ANALYTICS_ERROR,
} from 'src/action-creators/drive/drive';


export const DriveCurrentState = {
  IDLE: 'IDLE',
  FETCHING_EVENTS: 'FETCHING_EVENTS',
  CREATE_EVENT: 'CREATE_EVENT',
  EDIT_EVENT: 'EDIT_EVENT',
  FETCHING_EVENT: 'FETCHING_EVENT',
  FETCHING_PAGES: 'FETCHING_PAGES',
  STATUS_UPDATE_EVENT: 'STATUS_UPDATE_EVENT',
  FETCHING_JOBS: 'FETCHING_JOBS',
  LINK_PAGE: 'LINK_PAGE',
  FETCHING_LINKED_JOBS: 'FETCHING_LINKED_JOBS',
  LINKING_JOBS: 'LINKING_JOBS',
  FETCHING_ANALYTICS: 'FETCHING_ANALYTICS',
};

type DriveState = {
  current_state: string,
  events: Event[],
  pages: Page[],
  event: ?Event,
  jobs: Job[],
  linkedJobs?: Job[],
  analytics: Analytics[],
};

const initialState = {
  current_state: DriveCurrentState.IDLE,
  events: [],
  pages: [],
  jobs: [],
  event: null,
  linkedJobs: [],
  analytics: [],
};

export default (
  state: DriveState = initialState,
  action: DriveActions,
): DriveState => {
  switch (action.type) {
    case DRIVE_EVENTS:
      return {
        ...state,
        events: action.payload,
        event: null,
        current_state: DriveCurrentState.IDLE,
      };
    case DRIVE_EVENTS_LOADING:
      return {
        ...state,
        current_state: DriveCurrentState.FETCHING_EVENTS,
      };
    case DRIVE_EVENTS_ERROR:
      return {
        ...state,
        current_state: DriveCurrentState.IDLE,
      };
    case DRIVE_CREATE_EVENT:
      return {
        ...state,
        events: [...state.events, {...action.payload}],
        current_state: DriveCurrentState.IDLE,
      };
    case DRIVE_CREATE_EVENT_LOADING:
      return {
        ...state,
        current_state: DriveCurrentState.CREATE_EVENT,
      };
    case DRIVE_CREATE_EVENT_ERROR:
      return {
        ...state,
        current_state: DriveCurrentState.IDLE,
      };
    case DRIVE_EDIT_EVENT:
      return {
        ...state,
        events: state.events.map((event) => {
          if (event.id === action.payload.id) {
            return action.payload;
          }
          return event;
        }),
        current_state: DriveCurrentState.IDLE,
      };

    case DRIVE_EDIT_EVENT_LOADING:
      return {
        ...state,
        current_state: DriveCurrentState.EDIT_EVENT,
      };
    case DRIVE_EDIT_EVENT_ERROR:
      return {
        ...state,
        current_state: DriveCurrentState.IDLE,
      };
    case DRIVE_EVENT:
      return {
        ...state,
        event: action.payload,
        current_state: DriveCurrentState.IDLE,
      };
    case DRIVE_EVENT_LOADING:
      return {
        ...state,
        current_state: DriveCurrentState.FETCHING_EVENT,
      };
    case DRIVE_EVENT_ERROR:
      return {
        ...state,
        current_state: DriveCurrentState.IDLE,
      };
    case DRIVE_STATUS_UPDATE_LOADING: {
      return {
        ...state,
        current_state: DriveCurrentState.STATUS_UPDATE_EVENT,
      };
    }
    case DRIVE_STATUS_PUBLISH:
    case DRIVE_STATUS_ARCHIVE:
      return {
        ...state,
        event: action.payload,
        current_state: DriveCurrentState.IDLE,
      };

    case DRIVE_JOBS:
      return {
        ...state,
        jobs: action.payload,
        current_state: DriveCurrentState.IDLE,
      };
    case DRIVE_JOBS_LOADING:
      return {
        ...state,
        current_state: DriveCurrentState.FETCHING_JOBS,
      };
    case DRIVE_JOBS_ERROR:
      return {
        ...state,
        current_state: DriveCurrentState.IDLE,
      };

    case DRIVE_PAGES:
      return {
        ...state,
        pages: action.payload,
        current_state: DriveCurrentState.IDLE,
      };
    case DRIVE_PAGES_LOADING:
      return {
        ...state,
        current_state: DriveCurrentState.FETCHING_PAGES,
      };

    case DRIVE_LINK_PAGE:
      return {
        ...state,
        event: action.payload,
        current_state: DriveCurrentState.IDLE,
      };

    case DRIVE_UNLINK_PAGE:
      return {
        ...state,
        event: action.payload,
        current_state: DriveCurrentState.IDLE,
      };
    case DRIVE_LINK_PAGE_LOADING:
      return {
        ...state,
        current_state: DriveCurrentState.LINK_PAGE,
      };
    case DRIVE_LINK_PAGE_ERROR:
      return {
        ...state,
        current_state: DriveCurrentState.IDLE,
      };

    case DRIVE_LINKED_JOBS:
      return {
        ...state,
        linkedJobs: action.payload,
        current_state: DriveCurrentState.IDLE,
      };

    case DRIVE_LINKED_JOBS_LOADING:
      return {
        ...state,
        current_state: DriveCurrentState.FETCHING_LINKED_JOBS,
      };

    case DRIVE_LINKED_JOBS_ERROR:
      return {
        ...state,
      };

    case DRIVE_LINK_JOBS:
      return {
        ...state,
        linkedJobs: action.payload,
        current_state: DriveCurrentState.IDLE,
      };

    case DRIVE_LINK_JOBS_LOADING:
      return {
        ...state,
        current_state: DriveCurrentState.LINKING_JOBS,
      };

    case DRIVE_EVENT_ANALYTICS:
      return {
        ...state,
        analytics: action.payload,
        current_state: DriveCurrentState.IDLE,
      };

    case DRIVE_EVENT_ANALYTICS_LOADING:
      return {
        ...state,
        current_state: DriveCurrentState.FETCHING_ANALYTICS,
      };

    case DRIVE_EVENT_ANALYTICS_ERROR:
      return {
        ...state,
      };

    default:
      return {
        ...state,
      };
  }
};
