// @flow

import type {ReduxStore} from 'src/reducers';
// $FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores';

// $FlowFixMe[nonstrict-import]
import {isInternalUser} from 'src/utils/check-internal-user';

import './app.global.css';

import styles from './app.css';
import logger from 'src/utils/logger';

import * as React from 'react';
import {DndProvider} from 'react-dnd-cjs';
import HTML5Backend from 'react-dnd-html5-backend-cjs';
import {Helmet} from 'react-helmet';
import {Provider} from 'react-redux';

import FluxRoot from 'src/flux/root.jsx';
import {isMobile, mobileWidthPx} from 'src/utils/responsive';
import {isDevOrStage} from 'src/utils/env';

import {WindowWidthProvider} from 'src/components/lib/responsive.jsx';
import AgencyConfigContext from 'src/contexts/agency-config';
import ErrorBoundary from 'src/components/500/boundary.jsx';
import DragLayer from 'src/components/lib/dnd/layer.jsx';
import ModalRoot from 'src/components/modals/modal-root.jsx';
import {ContextMenuRoot} from 'src/components/lib/context-menu';
import {MouseTipRoot} from 'src/components/lib/mouse-tip/mouse-tip.jsx';
import labelI18nInstance from 'src/components/lib/i18n/i18n';
import I18nContext from 'src/contexts/i18n-context';
import ToastContainer from 'src/designSystem2021Components/toast/ToastContainer.jsx';
import {ToastContainer as GenesisToastContainer} from '@spaced-out/ui-design-system/lib/components/Toast';


type Props = {
  reduxStore: ReduxStore,
  // $FlowFixMe[value-as-type]
  senseStore: IndexStore,
  children: React.Node,
};

export default function App({
  senseStore,
  reduxStore,
  children,
}: Props): React.Node {
  const {
    requestHeaders: {headers},
    agency: {config, agency},
    env,
    accounts,
  } = reduxStore.getState();

  const agent = accounts.authedUserId
    ? accounts.data[accounts.authedUserId]
    : null;
  const webPlatformContext =
    env.query.context === 'chrome-extension' ? 'Extension' : 'Web';
  const clarityPropertiesToTrack = {
    'Agency Id': agency?.id,
    'Agency Name': agency?.name,
    'Agent Id': agent?.id,
    'Internal User': isInternalUser ? 'Yes' : 'No',
    'ATS System': agency?.atsSystem,
    'Sense Web Platform context': webPlatformContext,
  };

  React.useEffect(() => {
    window.document.addEventListener('dragover', handleDragOver);
    window?.chmln?.identify(agent?.id, {
      email: agent?.email,
      name: agent?.name,
      company: {uid: agency?.id, salesforce_uid: agency?.salesforceAccountId},
    });

    for (const [key, value] of Object.entries(clarityPropertiesToTrack)) {
      try {
        window?.clarity?.('set', key, value);
      } catch (e) {
        logger.error('clarity is not enabled');
      }
    }

    return () => {
      window.document.removeEventListener('dragover', handleDragOver);
    };
  }, []);

  const content = (
    <MouseTipRoot>
      <div className={styles.body}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
      <ContextMenuRoot />
      <ModalRoot />
    </MouseTipRoot>
  );

  const getLabelI18nInstance = () => labelI18nInstance;

  return (
    <div className={styles.container}>
      <Provider store={reduxStore}>
        <FluxRoot store={senseStore}>
          <AgencyConfigContext.Provider value={config || {}}>
            <I18nContext.Provider
              value={{labelI18nInstance: getLabelI18nInstance()}}
            >
              <SenseWindowWidthProvider userAgent={headers['user-agent']}>
                <Helmet defaultTitle="Sense" titleTemplate="%s | Sense">
                  {isDevOrStage({env}) ? (
                    <link href="/images/favicon-dev.svg" rel="icon" />
                  ) : (
                    <link href="/images/favicon.svg" rel="icon" />
                  )}
                </Helmet>
                {process.env.EX_ENV === 'server' ? (
                  content
                ) : (
                  <DndProvider backend={HTML5Backend}>
                    {content}
                    <DragLayer />
                  </DndProvider>
                )}
                <ToastContainer />
                <GenesisToastContainer />
              </SenseWindowWidthProvider>
            </I18nContext.Provider>
          </AgencyConfigContext.Provider>
        </FluxRoot>
      </Provider>
    </div>
  );
}

function SenseWindowWidthProvider({
  userAgent,
  ...props
}: {
  userAgent: string,
  children: React.Node,
}) {
  return (
    <WindowWidthProvider
      defaultWidth={isMobile(userAgent) ? mobileWidthPx : 1000}
      {...props}
    />
  );
}

// Returns an amount of pixels to scroll that gets bigger the
// closer the mouse is to the top or bottom of the window.

function getScrollByY(clientY) {
  const scrollZoneHeight = 100;
  const topTriggerLineY = scrollZoneHeight;
  const bottomTriggerLineY = window.innerHeight - scrollZoneHeight;

  if (clientY < topTriggerLineY) {
    return (15 * (clientY - topTriggerLineY)) / scrollZoneHeight;
  } else if (clientY > bottomTriggerLineY) {
    return (15 * (clientY - bottomTriggerLineY)) / scrollZoneHeight;
  } else {
    return 0;
  }
}

function handleDragOver({clientY}) {
  window.scrollBy(0, getScrollByY(clientY));
}
