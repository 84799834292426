// @flow

import Store from './base';


type ResponseRates = {
  endDate: string,
  startDate: string,
  responseRates: {
    open: number,
    response: number,
    startDate: string,
    endDate: string,
  }[],
};

export default class ReportStore extends Store {
  state: {
    responseRates: {
      data: ?ResponseRates,
      fetching: ?Promise<void>,
    },
  };

  constructor() {
    super('report');

    this.state = {
      responseRates: {
        data: null,
        fetching: null,
      },
    };
  }

  startFetching(promise: Promise<void>) {
    this.updateState({
      responseRates: {
        $assign: {
          fetching: promise,
        },
      },
    });
  }

  receiveFetched(data: ResponseRates) {
    this.updateState({
      responseRates: {
        $assign: {
          fetching: null,
          data,
        },
      },
    });
  }
}
