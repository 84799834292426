// @flow strict

import * as React from 'react';
import type {SvgIcon} from 'src/types/sense';

import {Link} from 'src/rerouter';

import classify from 'src/utils/classify';

import css from './zero.css';


const ZeroEvents = ({
  title,
  titleClassName,
  text,
  className,
  Icon,
  iconClassName,
  linkText,
  linkUrl,
}: {
  title: string,
  titleClassName?: string,
  text: string,
  className?: string,
  Icon?: SvgIcon,
  iconClassName?: string,
  linkText: string,
  linkUrl: string,
}): React.Node => (
  <div className={classify(css.box, className)}>
    {Icon && <Icon className={classify(css.icon, iconClassName)} />}

    <div className={classify(css.title, titleClassName)}>{title}</div>
    <div className={css.text}>{text}</div>
    {linkText && (
      <Link className={css.link} to={linkUrl}>
        {linkText}
      </Link>
    )}
  </div>
);

export default ZeroEvents;
