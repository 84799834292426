// @noflow

import type {ApiEventCalendarAudiencePreview} from 'src/api-parsers/events';
import typeof IndexStore from 'src/stores/index';

import flow from 'lodash/flow';

import logger from 'src/utils/logger';

import * as api from 'src/utils/api';
import {key, fetching} from 'src/utils/action';
import {serializeQuery} from 'src/utils/index';
import {
  checkAnalyticsTimelineV3Enabled,
  ANALYTICS_API_V3,
} from 'src/utils/analytics-api-migration';
import parsers from 'src/api-parsers/events';


type EventCalQuery = {
  start_date?: string,
  end_date?: string,
  workflow?: string,
};

export const getEventCalendar = flow(
  key((query) => `event-calendar-${query.start_date}-${query.end_date}`),
  fetching(),
)(
  // eslint-disable-next-line prefer-arrow-callback
  function getEventCalendar(
    store: IndexStore,
    query: EventCalQuery,
  ): Promise<*> {
    const {workflow: workflowId} = query;
    if (workflowId) {
      delete query.workflow;
    }

    if (query.start_date && query.end_date) {
      return api
        .get(
          store,
          'calendar?' + serializeQuery(query),
          {},
          {
            apiPath: checkAnalyticsTimelineV3Enabled()
              ? `${ANALYTICS_API_V3}/`
              : '/api/v1/',
          },
        )
        .then((response) => {
          let eventCalendar = parsers.parse.eventCalendar(response);

          // Filter down events by workflow in the client until set up on the server
          if (workflowId) {
            const belongsToCurrentWorkflow = (event) =>
              event.workflowId === workflowId;

            eventCalendar = {
              dueEvents: eventCalendar.dueEvents.filter(
                belongsToCurrentWorkflow,
              ),
              sentEvents: eventCalendar.sentEvents.filter(
                belongsToCurrentWorkflow,
              ),
            };
          }

          store.schedule.receiveCalendar(eventCalendar);
        });
    } else {
      return Promise.reject(new Error('DateQuery missing parameters'));
    }
  },
);

export const getEventCalendarAudiencePreviewPage = flow(
  key(
    (dateString: string, eventId: string) =>
      `event-calendar-audience-preview-${dateString}-${eventId}`,
  ),
  fetching(),
)((store: IndexStore, dateString: string, eventId: string): Promise<void> => {
  const limit = 25;
  const preview = store.schedule.getAudiencePreview(dateString, eventId);

  if (preview && preview.isDone) {
    return Promise.resolve();
  }

  const page = preview ? preview.page + 1 : 0;

  return api
    .get(
      store,
      `calendar/events/${eventId}/recipient-entities?date=${dateString}&offset=${
        page * limit
      }&limit=${limit} `,
    )
    .then((response: ApiEventCalendarAudiencePreview) => {
      if (response) {
        const audiencePreviewPage =
          parsers.parse.eventCalendarAudiencePreviewPage(response);
        store.schedule.receiveAudiencePreviewPage(
          eventId,
          dateString,
          page,
          audiencePreviewPage,
        );
      }
    })
    .catch((error) => {
      logger.error(
        'workflow getEventCalendarAudiencePreviewPage error: ',
        error.stack || error,
      );
    });
});
