// @flow
import type {CountApiRoi} from 'src/types/roi';

import * as React from 'react';

import * as api from 'src/utils/api-no-store';
import logger from 'src/utils/logger';
import {useReleaseFlag} from 'src/hooks/product-flags';
import {resolveTimeFrame} from 'src/utils/date-range';

import Loading from 'src/components/lib/loading/loading.jsx';
import {checkRoiV3ApiEnabled} from 'src/utils/analytics-api-migration';

import {SavingsRoi, PlacementRoi, CandidateNPS} from './roi.jsx';


export default function DashboardRoi(): React.Node {
  const [roi, setRoi] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const timeFrame = 'last_quarter';

  const {start_date, end_date} = resolveTimeFrame(timeFrame);

  const roiV2ApiEnabled = useReleaseFlag('roiV2ApiEnabled');

  const handleRoiSummaryApi = () => {
    api
      .get(
        'analytics/roi/summary',
        {start_date, end_date},
        {apiPath: '/api/v3/'},
      )
      .then((result: CountApiRoi) => {
        setRoi(result);
        setLoading(false);
      })
      .catch((error) => {
        logger.log(`Error fetching roi data: ${error}`);
        setLoading(false);
      });
  };

  const handleOldSummaryApi = () => {
    api
      .post(
        'analytics/roi/count',
        {},
        {
          date_range: timeFrame,
        },
        {
          apiPath: roiV2ApiEnabled ? '/api/v2/' : '/api/v1/',
        },
      )
      .then((result: CountApiRoi) => {
        setRoi(result);
        setLoading(false);
      })
      .catch((error) => {
        logger.log(`Error fetching roi data: ${error}`);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    setLoading(true);

    const startDateObj = new Date();
    startDateObj.setDate(startDateObj.getDate() - 90);

    checkRoiV3ApiEnabled() ? handleRoiSummaryApi() : handleOldSummaryApi();
  }, []);

  const showSavingsSection =
    (!roi?.candidates_reached && !roi?.influenced_placements) ||
    (roi?.candidates_reached > 0 && roi?.influenced_placements > 0);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <PlacementRoi roi={roi} timeFrame={timeFrame} />
      {showSavingsSection && <SavingsRoi roi={roi} />}
      <CandidateNPS />
    </div>
  );
}
