// @flow strict

import * as React from 'react';
import {useSelector} from 'react-redux';
import {Button} from 'src/designSystem2021Components/buttons.jsx';
import {Clickable, Smallest} from 'src/designSystem2021Components/text-v2.jsx';
import {Input} from 'src/components/agency-onboard/lib/input/input.jsx';
import {Collapsible} from 'src/designSystem2021Components/collapsible/collapsible.jsx';
import {Textarea} from 'src/designSystem2021Components/textarea.jsx';
import {DefaultFooter} from 'src/components/agency-onboard/footer/default-footer.jsx';
import {Select} from 'src/designSystem2021Components/select.jsx';
//$FlowFixMe[nonstrict-import]
import {selectAgencyData} from 'src/selectors/agency';
import {InfoLabel} from 'src/components/agency-onboard/info-label/info-label.jsx';
import classify from 'src/utils/classify';
import {AnalyticsService} from 'src/analytics';
import {AMPLITUDE_EVENTS} from 'src/components/agency-onboard/amplitude_event.js';

import type {Task} from 'src/components/agency-onboard/types/side-panel-comp.js';

import {
  MouseTipCompanyDetails,
  MouseTipBusinessReg,
} from 'src/components/agency-onboard/constants.jsx';
import maincss from 'src/components/agency-onboard/agency-onboard.css';
import css from 'src/components/agency-onboard/side-panels/tendlc-registration.css';
import designcss from 'src/components/design-doc.css';
import PlusIcon from 'src/images/icons/plus2.svg?noAttrs';
import DeleteIcon from 'src/images/icons/delete-icon.svg';


type SelectOption = {
  label: string,
  value: string,
};

type RepDetailForm = {
  full_name: string,
  email: string,
  phone_number: string,
  title: string,
};

type TenDLCFormData = {
  legal_business_name: string,
  dba: string,
  company_physical_address: string,
  company_type: SelectOption,
  business_registration_number: string,
  base_sense_url: string,
  duns_or_gin_or_lei: string,
  company_website: string,
  region: SelectOption,
  stock_symbol: string,
  stock_exchange: string,
  repList: Array<RepDetailForm>,
};

export type TenDLCFormProps = {
  task: Task,
  onTaskSubmit: (id: string, apiData: mixed) => mixed,
  onTaskCancel: () => mixed,
  data: TenDLCFormData,
};

export const TenDLCRegistrationSidePanel = (
  props: TenDLCFormProps,
): React.Node => {
  const {task, onTaskSubmit, onTaskCancel, data} = props;
  const agencyData = useSelector(selectAgencyData);

  // variables
  const repDetail = {
    full_name: '',
    email: '',
    phone_number: '',
    title: '',
  };

  const reqFields = [
    'legal_business_name',
    'company_physical_address',
    'business_registration_number',
    'base_sense_url',
    'company_website',
  ];

  // constants
  const ALLOWED_REPS_NUMBER = 2;

  const selectFieldOptions = {
    company_type: [
      {label: 'Private Company', value: 'Private Company'},
      {label: 'Publicly Traded Company', value: 'Publicaly Traded Company'},
      {label: 'Charity/Non-Profit', value: 'Charity/Non-Profit'},
    ],
    region: [
      {label: 'USA and Canada', value: 'USA and Canada'},
      {label: 'Europe', value: 'Europe'},
      {label: 'Asia', value: 'Asia'},
      {label: 'Africa', value: 'Africa'},
      {label: 'Latin America', value: 'Latin America'},
    ],
  };

  const handleTrackingEvent = (event) => {
    AnalyticsService.track(event, {
      agencyId: agencyData.id,
      agencyName: agencyData.name,
    });
  };

  const getCurrentData = (data) => {
    if (!Object.keys(data).length) {
      return {};
    }

    const selectFields = ['company_type', 'region'];
    const ignoreFields = ['repList'];
    const formData = {};

    Object.keys(data).forEach((key) => {
      if (ignoreFields.includes(key)) {
        return;
      } else if (selectFields.includes(key)) {
        // $FlowFixMe[prop-missing]
        selectFieldOptions[key].forEach((option) => {
          if (option['value'] === data[key]) {
            formData[key] = option;
          }
        });
      } else {
        formData[key] = data[key];
      }
    });

    return formData;
  };

  const [repList, setRepList] = React.useState(
    data.repList || [{...repDetail}],
  );
  const [formData, setFormData] = React.useState<TenDLCFormData>(
    // $FlowFixMe[incompatible-call]
    getCurrentData(data),
  );
  const [addRepDisabled, setAddRepDisabled] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(true);
  const [isInputDisabled, setInputDisabled] = React.useState(false);

  React.useEffect(() => {
    setDisableSubmit(!checkIfFormComplete());
    setInputDisabled(task['status'] !== 'Pending');
  }, [formData, repList, task]);

  const handleFormDataChange = (event) => {
    const curFormData = {...formData};
    curFormData[event.target.name] = event.target.value;
    setFormData(curFormData);
  };

  const handleSelectChange = (field, option) => {
    const curFormData = {...formData};
    // $FlowFixMe[incompatible-type]
    curFormData[field] = option;
    setFormData(curFormData);
  };

  const handleAddRep = () => {
    // only two reps are allowed
    if (repList.length >= ALLOWED_REPS_NUMBER - 1) {
      setAddRepDisabled(true);
    }

    const newRep = {...repDetail};
    setRepList([...repList, newRep]);
    handleTrackingEvent(AMPLITUDE_EVENTS.ADD_REP_CLICKED);
  };

  const handleDeleteRep = (index) => {
    const reps = [...repList];
    reps.splice(index, 1);
    if (!reps.length) {
      const newRep = {...repDetail};
      reps.push(newRep);
    }

    setRepList(reps);
    setAddRepDisabled(false);
  };

  const handleRepDetailChange = (event, index) => {
    const curData = [...repList];
    curData[index][event.target.name] = event.target.value;
    setRepList(curData);
  };

  const handleFormSubmit = () => {
    // select elements have label and value so need to loop through it
    const userData = {};
    Object.keys(formData).forEach((key) => {
      userData[key] = formData[key].value || formData[key];
    });

    userData['repList'] = [...repList];
    if (repList.length === 2) {
      handleTrackingEvent(AMPLITUDE_EVENTS.SUBMIT_BUTTON_CLICKED_WITH_TWO_REPS);
    }
    onTaskSubmit(task['id'], userData);
    handleTrackingEvent(AMPLITUDE_EVENTS.TEN_DLC_SUBMIT_BUTTON_CLICKED);
  };

  const handleFormCancel = () => {
    onTaskCancel();
  };

  const checkIfFormComplete = () => {
    let isFormComplete = true;
    reqFields.forEach((field) => {
      if (!formData[field]) {
        isFormComplete = false;
      }
    });

    repList.forEach((rep) => {
      Object.keys(repDetail).forEach((key) => {
        if (!rep[key]) {
          isFormComplete = false;
        }
      });
    });
    return isFormComplete;
  };

  return (
    <div className={maincss.sidePanel}>
      <Collapsible
        title={
          <InfoLabel
            text="Enter Company Details"
            content={MouseTipCompanyDetails}
            fixedTo="bottom"
            infoBoxClass={css.infoBox}
            infoIconClass={maincss.infoIcon}
          ></InfoLabel>
        }
        containerClassName={designcss.collapsibleBlock}
        headerClassName={maincss.headerText}
        isOpen
      >
        <div className={maincss.sidePanelForm}>
          <div className={maincss.inputBox}>
            <Input
              name="legal_business_name"
              value={formData.legal_business_name || ''}
              disabled={isInputDisabled}
              type="text"
              label="Legal Business Name*"
              boxClassName={maincss.outerBoxInputField}
              onChange={(event) => handleFormDataChange(event)}
            />
          </div>
          <div className={maincss.inputBox}>
            <Input
              name="dba"
              value={formData.dba || ''}
              disabled={isInputDisabled}
              type="text"
              label="DBA (if any)"
              boxClassName={maincss.outerBoxInputField}
              onChange={(event) => handleFormDataChange(event)}
            />
          </div>
          <div className={css.textArea}>
            <Textarea
              name="company_physical_address"
              value={formData.company_physical_address || ''}
              disabled={isInputDisabled}
              rows="7"
              label={
                <Smallest className={maincss.inputLabel}>
                  Company Physical Address*
                </Smallest>
              }
              boxClassName={maincss.outerBoxTextAreaField}
              onChange={(event) => handleFormDataChange(event)}
            />
          </div>
          <div className={maincss.inputBox}>
            <Select
              name="company_type"
              value={formData.company_type || null}
              disabled={isInputDisabled}
              options={selectFieldOptions['company_type']}
              classNames={{box: maincss.outerBoxInputField}}
              onChange={(option) => handleSelectChange('company_type', option)}
              label="Company Type"
            />
          </div>
          <div className={maincss.inputBox}>
            <Input
              name="business_registration_number"
              value={formData.business_registration_number || ''}
              disabled={isInputDisabled}
              type="text"
              label={
                <InfoLabel
                  text="Business registration number (EIN/Tax number/ID)*"
                  content={MouseTipBusinessReg}
                  fixedTo="left"
                  infoBoxClass={css.infoBox}
                  infoIconClass={maincss.infoIcon}
                ></InfoLabel>
              }
              boxClassName={maincss.outerBoxInputField}
              onChange={(event) => handleFormDataChange(event)}
            />
          </div>
          <div className={maincss.inputBox}>
            <Input
              name="base_sense_url"
              value={formData.base_sense_url || ''}
              disabled={isInputDisabled}
              type="text"
              label="Base Sense URL for this EIN (if multiple, separate by commas)*"
              boxClassName={maincss.outerBoxInputField}
              onChange={(event) => handleFormDataChange(event)}
            />
          </div>
          <div className={maincss.inputBox}>
            <Input
              name="duns_or_gin_or_lei"
              value={formData.duns_or_gin_or_lei || ''}
              disabled={isInputDisabled}
              type="text"
              label="DUNS or GIIN or LEI Number (optional but recommended)"
              boxClassName={maincss.outerBoxInputField}
              onChange={(event) => handleFormDataChange(event)}
            />
          </div>
          <div className={maincss.inputBox}>
            <Input
              name="company_website"
              value={formData.company_website || ''}
              disabled={isInputDisabled}
              type="text"
              label="Company Website*"
              boxClassName={maincss.outerBoxInputField}
              onChange={(event) => handleFormDataChange(event)}
            />
          </div>
          <div className={maincss.inputBox}>
            <Select
              name="region"
              value={formData.region || null}
              disabled={isInputDisabled}
              options={selectFieldOptions['region']}
              classNames={{box: maincss.outerBoxInputField}}
              onChange={(option) => handleSelectChange('region', option)}
              label="Region of operation"
            />
          </div>
          <div className={maincss.inputBox}>
            <Input
              name="stock_symbol"
              value={formData.stock_symbol || ''}
              disabled={isInputDisabled}
              type="text"
              label="Stock Symbol"
              boxClassName={maincss.outerBoxInputField}
              onChange={(event) => handleFormDataChange(event)}
            />
          </div>
          <div className={maincss.inputBox}>
            <Input
              name="stock_exchange"
              value={formData.stock_exchange || ''}
              disabled={isInputDisabled}
              type="text"
              label="Stock Exchange"
              boxClassName={maincss.outerBoxInputField}
              onChange={(event) => handleFormDataChange(event)}
            />
          </div>
        </div>
      </Collapsible>
      <hr className={maincss.dividerLine}></hr>
      <Collapsible
        title="Enter Rep Details*"
        containerClassName={designcss.collapsibleBlock}
        headerClassName={maincss.headerText}
      >
        <div className={css.repDetails}>
          {repList.map((rep, index) => (
            <div key={index} className={css.repInfoDiv}>
              <div className={css.repHeader}>
                <Clickable
                  className={classify(maincss.textPrimary, css.repHeaderText)}
                >
                  Rep {index + 1}
                </Clickable>
                {repList.length > 1 && (
                  <Button
                    type="tertiary"
                    className={css.deleteIcon}
                    onClick={() => handleDeleteRep(index)}
                    size="small"
                  >
                    <span>&#8212;</span>
                  </Button>
                )}
              </div>
              <div className={maincss.sidePanelForm}>
                <div className={maincss.inputBox}>
                  <Input
                    name="full_name"
                    type="text"
                    label="Full Name*"
                    boxClassName={maincss.outerBoxInputField}
                    value={rep.full_name}
                    onChange={(event) => handleRepDetailChange(event, index)}
                    disabled={isInputDisabled}
                  />
                </div>
                <div className={maincss.inputBox}>
                  <Input
                    name="email"
                    type="email"
                    label="Email*"
                    boxClassName={maincss.outerBoxInputField}
                    value={rep.email}
                    disabled={isInputDisabled}
                    onChange={(event) => handleRepDetailChange(event, index)}
                  />
                </div>
                <div className={maincss.inputBox}>
                  <Input
                    name="phone_number"
                    type="text"
                    label="Phone Number*"
                    boxClassName={maincss.outerBoxInputField}
                    value={rep.phone_number}
                    disabled={isInputDisabled}
                    onChange={(event) => handleRepDetailChange(event, index)}
                  />
                </div>
                <div className={maincss.inputBox}>
                  <Input
                    name="title"
                    type="text"
                    label="Title*"
                    boxClassName={maincss.outerBoxInputField}
                    value={rep.title}
                    disabled={isInputDisabled}
                    onChange={(event) => handleRepDetailChange(event, index)}
                  />
                </div>
              </div>
            </div>
          ))}
          <Button
            type="tertiary"
            className={css.addRepBtn}
            icon={<PlusIcon />}
            onClick={handleAddRep}
            iconPosition="left"
            size="small"
            disabled={isInputDisabled || addRepDisabled}
          >
            Add Rep
          </Button>
        </div>
      </Collapsible>
      <DefaultFooter
        disableSubmit={disableSubmit}
        onSubmit={handleFormSubmit}
        onCancel={handleFormCancel}
      />
    </div>
  );
};
