// @flow strict

type ArrayOrObject =
  | Array<mixed>
  | $ReadOnly<{[string]: mixed, ...}>
  | void
  | null;

// Use this to cheaply compare two objects/arrays.
// We ignore a bit of Flow typing issues for simplicity's sake.
export const shallowEqual = (
  ob1: ArrayOrObject,
  ob2: ArrayOrObject,
): boolean => {
  if (ob1 === ob2) {
    return true;
  }

  if (ob1 == null || ob2 == null) {
    return false;
  }

  // $FlowFixMe
  const keys1 = Object.keys(ob1);
  // $FlowFixMe
  const keys2 = Object.keys(ob2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let i = 0; i < keys1.length; i++) {
    // $FlowFixMe
    if (ob1[keys1[i]] !== ob2[keys2[i]]) {
      return false;
    }
  }

  return true;
};
