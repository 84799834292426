// @flow strict

import * as React from 'react';
import classify from 'src/utils/classify';
import {UnstyledButton} from '@spaced-out/ui-lib/lib/button';
import CaretIcon from 'src/images/designSystems2021/caret-down.svg?noAttrs';

import css from '../buttons.css';


type MenuButtonProps = {
  onKeyPress?: ?(SyntheticKeyboardEvent<HTMLElement>) => mixed,
  onClick?: ?(SyntheticEvent<HTMLElement>) => mixed,
  className?: string,
  icon?: React.Node,
  children?: React.Node,
  type?: 'primary' | 'secondary' | 'tertiary',
  disabled?: boolean,
  size?: 'default' | 'small',
  caret?: React.Node,
  isSelectable?: boolean,
  isSelected?: boolean,
  ...
};

/**
 * Our Design System menu-button. It has lots of variants.
 *
 * Set the content with children and
 * @type {[type]}
 */
export const MenuButton: React$AbstractComponent<
  MenuButtonProps,
  HTMLDivElement,
> = React.forwardRef<MenuButtonProps, HTMLDivElement>(
  (
    {
      className,
      children,
      icon = null,
      type = 'primary',
      disabled = false,
      size = 'default',
      isSelected = false,
      isSelectable = false,
      caret = <CaretIcon />,
      ...props
    }: MenuButtonProps,
    ref,
  ) => (
    <UnstyledButton
      {...props}
      className={classify(className, css.button, css.menuButton, {
        [css.primary]: !isSelectable && type === 'primary',
        [css.secondary]: !isSelectable && type === 'secondary',
        [css.tertiary]: type === 'tertiary',
        [css.selected]: isSelectable && isSelected,
        [css.unselected]: isSelectable && !isSelected,
        [css.disabled]: disabled,
        [css.small]: size === 'small',
      })}
      ref={ref}
    >
      {icon != null && children != null && (
        <div className={css.buttonRow}>
          <div className={css.iconContainerLeft}>{icon}</div>
          <div className={css.textContainerRight}>{children}</div>
          <div className={css.caretContainer}>{caret}</div>
        </div>
      )}

      {icon != null && !children && (
        <div className={css.buttonRow}>
          <div className={css.iconContainerCenter}>{icon}</div>
        </div>
      )}
      {icon == null && children != null && (
        <div className={css.buttonRow}>
          <div className={css.textContainerCenter}>{children}</div>
          <div className={css.caretContainer}>{caret}</div>
        </div>
      )}
    </UnstyledButton>
  ),
);

export default MenuButton;
