// @noflow

import * as React from 'react';

import {classify} from 'src/utils';

import css from './legend.css';


const Legend = ({
  className,
  colorScale,
  keys,
  keyTextClassName,
  keyColorClassName,
}: {
  className?: string,
  colorScale: (key: string) => string,
  keys: string[],
  keyTextClassName?: string,
  keyColorClassName?: string,
}) => (
  <div className={classify(css.legend, className)}>
    {keys.map((key) => (
      <div key={key}>
        <div
          className={classify(css.keyColor, keyColorClassName)}
          style={{backgroundColor: colorScale(key)}}
        />
        <div className={classify(css.keyText, keyTextClassName)}>{key}</div>
      </div>
    ))}
  </div>
);

export default Legend;
