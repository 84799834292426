// @flow

import type {Task} from 'src/types/data-export-report';
import type {ReportAction} from 'src/action-creators/data-export-report';

import {
  RECEIVE_TASKS,
  RECEIVE_TASK,
} from 'src/action-creators/data-export-report';


export type ReportsState = {
  tasks: Map<string, Task>,
  taskList: string[],
  offset: number,
  count: number,
  hasMore: boolean,
  polls: Map<string, TimeoutID>,
};

const initialState = {
  tasks: new Map(),
  taskList: [],
  offset: 0,
  count: 0,
  hasMore: false,
  polls: new Map(),
};

function deriveHasMore(count: number, currentOffset: number) {
  return currentOffset < count;
}

export default (
  state: ReportsState = initialState,
  action: ReportAction,
): ReportsState => {
  switch (action.type) {
    case RECEIVE_TASKS: {
      const {tasks, count} = action.payload;

      const currentIds = new Set(state.taskList);
      const newTasks = new Map(state.tasks);
      for (const task of tasks) {
        newTasks.set(task.id, task);
        currentIds.add(task.id);
      }

      const newTaskList = Array.from(currentIds);
      const newOffset = newTaskList.length;

      return {
        ...state,
        tasks: newTasks,
        taskList: newTaskList,
        offset: newOffset,
        hasMore: deriveHasMore(count, newOffset),
      };
    }

    case RECEIVE_TASK: {
      const task = action.payload;

      const tasks = new Map(state.tasks);
      tasks.set(task.id, task);

      let taskList = state.taskList;
      if (!taskList.includes(task.id)) {
        taskList = [task.id, ...taskList];
      }

      return {
        ...state,
        tasks,
        taskList,
        offset: taskList.length,
      };
    }
  }

  return state;
};
