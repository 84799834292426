// @flow strict

import type {JobVariables, JobVariable} from 'src/types/job-variables';

import type {BooleanSelectOption} from './constants';

import * as React from 'react';
import {useDispatch} from 'react-redux';
import _get from 'lodash/get';
//$FlowFixMe[nonstrict-import]
import {EditorState} from 'draft-js';
import {JOB_WITH_LINK_IDENTIFIER} from 'src/utils/job-match';
import {classify} from 'src/utils/classify';
//$FlowFixMe[nonstrict-import]
import MarkdownEditor, {
  //$FlowFixMe[nonstrict-import]
  CustomButtonContext,
  //$FlowFixMe[nonstrict-import]
  CustomLinkButtonContext,
  //$FlowFixMe[nonstrict-import]
  Separator,
  //$FlowFixMe[nonstrict-import]
  Spacer,
} from 'src/components/lib/markdown-editor/index2';
//$FlowFixMe[untyped-import]
import {getLabels} from 'src/components/lib/markdown-editor/dynamic-text.jsx';
//$FlowFixMe[nonstrict-import]
import {pushModal} from 'src/action-creators/modal';

import {VariableButton} from 'src/components/lib/variable-button';
//$FlowFixMe[nonstrict-import]
import {Select} from 'src/components/lib/token-list-input/new-stuff.jsx';
//$FlowFixMe[untyped-import]
import {StyleButton} from 'src/components/lib/markdown-editor/editor-controls';
import {
  ALT_MESSAGE_OPTIONS,
  SMS_ALT_MESSAGE_OPTIONS,
  SMS_MAX_CHARACTER_LIMIT,
} from './constants';
import LinkIcon from 'src/images/editor/link.svg';
import {ErrorLabel} from 'src/components/lib/label/label.jsx';

import css from './module.css';


export function JobMatchButton({
  dynamicLabels,
}: {
  dynamicLabels: JobVariables,
}): React.Node {
  const {insertFromModal} = React.useContext(CustomButtonContext) || {};

  //use context here to interact with markdown editor
  const dispatch = useDispatch();
  const insertJobVariable = (dynamicLabel: JobVariable, evt?: Event) => {
    insertFromModal(dynamicLabel, evt);
  };

  const showJobVariablePickerModal = () => {
    dispatch(
      pushModal({
        type: 'JOB_VARIABLE_PICKER',
        labels: dynamicLabels,
        insertResult: insertJobVariable,
      }),
    );
  };
  return (
    <>
      <Spacer />
      <Separator />
      <div className={css.addJobVariableContainer}>
        <VariableButton
          text="Job Variable"
          onClick={showJobVariablePickerModal}
          hideVariableIcon
        />
      </div>
    </>
  );
}

export function JobLinkButton({
  dynamicLabels,
}: {
  dynamicLabels: JobVariables,
}): React.Node {
  const {insertFromModal} = React.useContext(CustomLinkButtonContext) || {};

  const addJobLink = () => {
    const jobLinkVariable = dynamicLabels.find(
      (label) => label.name === JOB_WITH_LINK_IDENTIFIER,
    );
    if (jobLinkVariable) {
      insertFromModal(jobLinkVariable);
    }
  };

  return (
    <>
      <StyleButton
        key="link"
        label="Link"
        onToggle={addJobLink}
        style="LINK"
        IconComponent={LinkIcon}
      />
    </>
  );
}

export const JobBlockInfo = ({
  includeJobBlock,
  enableJobBlockInteractions,
}: {
  includeJobBlock: boolean,
  enableJobBlockInteractions: boolean,
}): React.Node => {
  if (!enableJobBlockInteractions) {
    return (
      <p>
        Jobs can only be included once per touchpoint. A job list has already
        been included in another message part.
      </p>
    );
  }

  return includeJobBlock ? (
    <>
      <p>
        If the candidate has <b>one or more</b> matching jobs, then the “Text
        block” and the “Job block” to the left will be included in the message.
      </p>
      <p>
        Each candidate may have a different number of matching jobs. For each
        candidate, the text in the “Job block” to the left will repeat for each
        matching job. However, you may also limit the total number of jobs to
        include only:
      </p>
    </>
  ) : (
    <>
      <p>
        Change this setting to include a list of jobs within the email body or
        within a survey.
      </p>
      <p>
        The job list will be unique to each candidate and will only include open
        jobs from your database that closely fit each candidate’s skills and
        preferences.
      </p>
    </>
  );
};

export const SMSJobBlockInfo = ({
  includesJobMatchVariables,
}: {
  includesJobMatchVariables: boolean,
}): React.Node =>
  includesJobMatchVariables ? (
    <p>
      If the candidate has a matching job, then the text block to the left will
      be sent.
    </p>
  ) : (
    <p></p>
  );

type AltMessageProps = {
  sendAltMessage: boolean,
  dynamicLabels: JobVariables,
  onChange: (value: EditorState) => void,
  onAltMessageOptionChange: (?BooleanSelectOption) => void,
  editorState: EditorState,
  onFileUpload: (files: FileList) => void,
  multiEntityEnabled: boolean,
  errors: string[],
};
export const AltMessage = ({
  sendAltMessage,
  dynamicLabels,
  onChange,
  onAltMessageOptionChange,
  editorState,
  onFileUpload,
  multiEntityEnabled,
  errors,
}: AltMessageProps): React.Node => {
  const selectedAltMessageOption = ALT_MESSAGE_OPTIONS.find(
    (item) => item.value === sendAltMessage,
  );
  const altMessageError = sendAltMessage && errors && errors.length > 0;

  return (
    <div className={css.notificationContainer}>
      <div className={css.generalModule_content}>
        <label className={css.sectionLabel}>
          Alternate text block — for zero matching jobs:
        </label>
        <div className={css.altMessageWrapper}>
          <MarkdownEditor
            containerClassName={css.altMessageEditor} //constrain width
            className={css.altMessageEditor} //constrain height
            dynamicLabels={dynamicLabels}
            onChange={onChange}
            value={editorState}
            placeholder="e.g. alternate message goes here!"
            handleImageUpload={onFileUpload}
            showErrors={altMessageError}
            multiEntityEnabled={multiEntityEnabled}
          />
        </div>
      </div>

      <div className={css.altMessageInfoContainer}>
        <label className={css.sectionLabel}>For zero matching jobs:</label>
        <div
          className={classify(css.altMessageControls, {
            [css.altMessageControlsNo]: !sendAltMessage,
          })}
        >
          <div>
            <Select
              value={selectedAltMessageOption}
              classNames={{container: css.includeJobs}}
              options={ALT_MESSAGE_OPTIONS}
              onChange={onAltMessageOptionChange}
              clearable={false}
            />
          </div>
          {sendAltMessage ? (
            <p>
              If the candidate has <b>zero</b> matching jobs, then both the
              “Text block” and the “Job block” above will be replaced with the
              “Alternate text block” to the left. Any additional message parts
              or survey questions below will still be included.
            </p>
          ) : (
            <p>
              If the candidate has <b>zero</b> matching jobs, then no email will
              be sent to this candidate at all. Also, all other message parts or
              survey questions above and below <b>won’t</b> be sent.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

type SMSAltMessageProps = {
  className?: string,
  sendAltMessage: boolean,
  dynamicLabels: JobVariables,
  onChange: (value: EditorState) => void,
  onAltMessageOptionChange: (?BooleanSelectOption) => void,
  editorState: EditorState,
  text: string,
  showAddAttachmentLink: boolean,
  errors: string[],
};
export const SMSAltMessage = ({
  sendAltMessage,
  dynamicLabels,
  onChange,
  onAltMessageOptionChange,
  editorState,
  text,
  showAddAttachmentLink,
  errors,
}: SMSAltMessageProps): React.Node => {
  const charCount = text ? text.length : 0;
  const textHasLabels =
    editorState &&
    getLabels(editorState.getCurrentContent()).filter((arr) => arr.length > 0)
      .size > 0;
  const warning = charCount > SMS_MAX_CHARACTER_LIMIT - 100;
  const selectedAltMessageOption = SMS_ALT_MESSAGE_OPTIONS.find(
    (item) => item.value === sendAltMessage,
  );
  const altMessageError =
    sendAltMessage &&
    errors &&
    (errors.length > 0 || charCount > SMS_MAX_CHARACTER_LIMIT);

  return (
    <div className={css.notificationContainer}>
      <div className={css.generalModule_content}>
        <label className={css.sectionLabel}>
          <span className={css.smsNotification_type}>
            Alternate text block — for zero matching jobs:
          </span>
          <span className={css.smsNotification_message}>
            {charCount > SMS_MAX_CHARACTER_LIMIT ? (
              <span className={css.smsNotification_error_message}>
                <strong>
                  ({SMS_MAX_CHARACTER_LIMIT - charCount} characters),{' '}
                </strong>
                <strong>
                  Max char limit {SMS_MAX_CHARACTER_LIMIT} reached!!
                </strong>
              </span>
            ) : (
              <span
                className={warning ? css.smsNotification_warning_message : null}
              >
                {textHasLabels ? 'Approx. ' : ''}
                {warning ? (
                  <span>
                    <strong>{SMS_MAX_CHARACTER_LIMIT - charCount}</strong>{' '}
                    characters remaining*, Max char limit is{' '}
                    {SMS_MAX_CHARACTER_LIMIT}
                  </span>
                ) : (
                  <span>
                    <strong>{charCount}</strong> characters*
                  </span>
                )}
              </span>
            )}
          </span>
        </label>
        <div className={css.altMessageWrapper}>
          <MarkdownEditor
            containerClassName={css.altMessageEditorSMS}
            className={css.altMessageEditorSMS}
            value={editorState}
            hasFormatting={false}
            showErrors={altMessageError}
            dynamicLabels={dynamicLabels}
            showAddAttachmentLink={showAddAttachmentLink}
            onChange={onChange}
            maxCharacterLimit={SMS_MAX_CHARACTER_LIMIT}
          />
        </div>
        {errors.length > 0 && (
          <ErrorLabel className={css.jobMatchTextError}>{errors[0]}</ErrorLabel>
        )}

        <p className={css.smsNotificationNoticeLeft}>
          *Carriers may split messages into multiple parts, and variables can
          expand to various widths.
        </p>
      </div>

      <div className={css.altMessageInfoContainer}>
        <label className={css.sectionLabel}>For zero matching jobs:</label>
        <div
          className={classify(css.altMessageControls, {
            [css.altMessageControlsNo]: !sendAltMessage,
          })}
        >
          <div>
            <Select
              value={selectedAltMessageOption}
              classNames={{container: css.includeJobs}}
              options={SMS_ALT_MESSAGE_OPTIONS}
              onChange={onAltMessageOptionChange}
              clearable={false}
            />
          </div>
          {sendAltMessage ? (
            <p>
              If the candidate <b>doesn’t</b> have a matching job, then the
              “Text block” above will be replaced with the “Alternate text
              block” to the left.
            </p>
          ) : (
            <p>
              If the candidate <b>doesn’t</b> have a matching job, then no SMS
              will be sent at all.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
