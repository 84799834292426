var React = require('react');

function Close (props) {
    return React.createElement("svg",props,React.createElement("path",{"fillRule":"evenodd","clipRule":"evenodd","d":"M3.97 3.97a.75.75 0 011.06 0L9 7.94l3.97-3.97a.75.75 0 111.06 1.06L10.06 9l3.97 3.97a.75.75 0 11-1.06 1.06L9 10.06l-3.97 3.97a.75.75 0 01-1.06-1.06L7.94 9 3.97 5.03a.75.75 0 010-1.06z"}));
}

Close.defaultProps = {"width":"18","height":"18","viewBox":"0 0 18 18"};

module.exports = Close;

Close.default = Close;
