// @flow strict-local

import type {
  DataReviewApiResult,
  ConversationSummary,
  ThumbValue,
} from 'src/types/data-review';
import type {GraphDetails} from 'src/types/chatbot';
import type {BaseHistory} from 'src/rerouter/history/base';
import * as React from 'react';
import {useDispatch} from 'react-redux';
import keyBy from 'lodash/keyBy';
import xor from 'lodash/xor';

import {useFlows} from './api-hooks';
import {usePaginatedApi} from 'src/hooks/usePaginatedApi';
import {useHistory} from 'src/rerouter/hooks.js';
import {useStatefulAnalyticsQuery as useAnalyticsQuery} from 'src/components/analytics/useAnalyticsQuery';
import {useReleaseFlag} from 'src/hooks/product-flags';

import * as reduxApi from 'src/utils/redux-api-v2';
import {resolveDateRange} from 'src/utils/date-range';
import {displayDate} from 'src/utils/date-time-2';
import {AnalyticsService} from 'src/analytics';

import {startDataExport} from 'src/action-creators/data-export-report.js';
import {pushModal, popModal} from 'src/action-creators/modal';

import Table, {Cells, Cell} from 'src/components/lib/table/table.jsx';
import {UnstyledButton} from 'src/components/lib/new-button';
import EventFilters from 'src/components/analytics/filters/index.jsx';
import {FilterCard} from 'src/components/lib/analytics-filter-card';
import {AutoTruncatedText} from 'src/components/lib/truncated-text/truncated-text.jsx';
import PaginationFooter from 'src/components/lib/table/pagination.jsx';
import ClickAway from 'src/components/lib/click-away';
// $FlowIssue[untyped-import] - need to type filter button
import {FilterButton} from 'src/components/lib/analytics-filter-button';
import Checkbox from 'src/components/lib/checkbox';
import {Button} from '@spaced-out/ui-lib/lib/button';
import {Clickable} from 'src/designSystem2021Components/text-v2.jsx';
import {Menu} from 'src/designSystem2021Components/menu/menu.jsx';
import Loading from 'src/components/lib/loading';

import DownloadIcon from 'src/images/icons/download-filled.svg';

import css from './data-review.css';


export const DataReview = (): React.Node => {
  const router = useHistory();
  const dispatch = useDispatch();
  const ROWS_PER_PAGE = 20;
  const {
    query: {page: currentPage = '1', q},
  } = router.location;

  const zeroIndexPage = Number(currentPage) - 1;

  const [analyticsQuery, updateQuery] = useAnalyticsQuery();
  const {start_date = '', end_date = ''} = resolveDateRange(
    analyticsQuery ?? {},
  );

  const summaryData = usePaginatedApi<ConversationSummary, DataReviewApiResult>(
    {
      pathname: 'chatbot/data-review',
      query: {
        start_date,
        end_date,
        flow_ids:
          // $FlowIssue flowIds is guaranteed to exist but not .length but we fallback anyway
          (analyticsQuery.flowIds?.length ?? 0) > 0
            ? // $FlowIssue[incompatible-call]
              analyticsQuery.flowIds
            : // $FlowIssue[incompatible-call]
              undefined,
      },
    },
    zeroIndexPage,
    {
      pageSize: ROWS_PER_PAGE,

      parseResponse: (res) => ({
        records: res.conversation_summaries,
        total: res.total_results,
      }),
      deps: [currentPage, start_date, end_date, analyticsQuery.flowIds],
    },
  );
  const [{flows}] = useFlows({showArchived: true});
  const flowsById = React.useMemo(() => keyBy(flows ?? [], 'id'), [flows]);

  const dataReviewEnhancementEnabled = useReleaseFlag(
    'chatbotDataReviewEnhancement',
  );

  const rows = summaryData.data.records ?? [];
  const maxPages = summaryData.lastPage ?? 0 + 1;

  // This handles the local thum up/down state for the individual conversations
  //
  const [checkedState, setCheckedState] = React.useState<{
    [conversation_id: string]: ThumbValue,
  }>({});

  // state of the top-level table checkbox
  // if this is checked , we select all the checkboxes in the table
  // but if unchecked, we unselect all the checkboxes in the table
  // we do this by storing the checked state for all visible rows and
  const [tableChecked, setTableChecked] = React.useState(false);
  const [checkedForDownload, setCheckedForDownload] = React.useState([]);
  React.useEffect(() => {
    // don't do anything on empty result sets
    if (rows.length === 0) {
      if (tableChecked === true) {
        // perhaps though the result set changed but the table appears "checked"
        // so we reset the table this once
        setTableChecked(false);
      }
      return;
    }
    if (tableChecked) {
      setCheckedForDownload(rows.map((r) => r.id));
    } else {
      setCheckedForDownload([]);
    }
  }, [tableChecked, analyticsQuery.flowIds, rows]);

  const selectedFlow = flowsById[analyticsQuery.flowIds];

  const handleAllCsvExport = React.useCallback(
    async () =>
      selectedFlow &&
      dispatch(
        startDataExport('analytics/chatbot/sourcing-conversations/csv', {
          flow_id: selectedFlow.id,
          conversation_ids: [],
        }),
      ),
    [checkedForDownload, selectedFlow],
  );

  const handleCheckedCsvExport = React.useCallback(
    async () =>
      selectedFlow &&
      checkedForDownload.length > 0 &&
      dispatch(
        startDataExport('analytics/chatbot/sourcing-conversations/csv', {
          flow_id: dataReviewEnhancementEnabled ? selectedFlow.id : undefined,
          conversation_ids: checkedForDownload,
        }),
      ),
    [checkedForDownload, selectedFlow],
  );

  const handleReviewChange = React.useCallback(
    async (conversation_id: string, value: ThumbValue): Promise<ThumbValue> => {
      const priorValue = checkedState[conversation_id];
      setCheckedState((prev) => ({...prev, [conversation_id]: value}));
      try {
        await dispatch(
          reduxApi.put(`chatbot/data-review/${conversation_id}`, {
            review_status: value,
          }),
        );
        return value;
      } catch (e) {
        // on error, reset the state to the nullish value
        setCheckedState((prev) => ({...prev, [conversation_id]: priorValue}));
        return priorValue;
      }
    },
    [checkedState, dispatch, setCheckedState],
  );

  const downloadOptions = React.useMemo(
    () => [
      {
        label: `Download selected (${checkedForDownload.length})`,
        value: 'selected',
        onSelect: async () => {
          handleCheckedCsvExport();
        },
        disabled: !checkedForDownload.length,
      },
      {
        label: `Download all transcripts (${String(summaryData.total)})`,
        value: 'all',
        onSelect: async () => {
          handleAllCsvExport();
        },
      },
    ],
    [checkedForDownload.length, summaryData.total],
  );

  if (summaryData.data.isLoading) {
    return <Loading />;
  }

  return (
    <div className={css.container}>
      <h2>Chatbot Data Review</h2>
      <h3 className={css.subtitle}>
        {dataReviewEnhancementEnabled && selectedFlow
          ? selectedFlow.name
          : 'Inbound data review'}
      </h3>

      <div className={css.actionRow}>
        <div className={css.filterRow}>
          <FlowFilters
            flows={flows}
            // $FlowIssue[incompatible-type] - the object is set as a numerical array
            selectedFlows={analyticsQuery.flowIds ?? []}
            onChange={(flowIds) => {
              updateQuery({flowIds: {$set: flowIds}});
            }}
          />
          <EventFilters
            includeFilters={['timeframe']}
            showDefaultFilter={false}
          />
        </div>

        {dataReviewEnhancementEnabled && (
          <Menu
            label="Download CSV"
            options={downloadOptions}
            onSelect={(item) => {
              if (!item.onSelect) {
                return;
              } else {
                item.onSelect();
              }
            }}
            type="primary"
          />
        )}
      </div>

      <Table
        header={[
          {
            key: 'checkbox',
            sortable: false,
            className: css.checkboxCell,
            label: (
              <label>
                <Checkbox
                  checked={tableChecked}
                  onChange={() => {
                    setTableChecked((prev) => !prev);
                  }}
                />
              </label>
            ),
          },
          {key: 'chatbot', label: 'Chatbot'},
          {key: 'channel', label: 'Channel'},
          {key: 'engaged', label: 'Engaged'},
          {key: 'downloaded', label: 'Downloaded'},
          {key: 'data preview', label: 'Data Preview'},
          {key: 'review', label: 'Review'},
          {key: 'actions', label: 'Actions'},
        ]}
        entries={rows}
        extras={{
          checkedState,
          setCheckedState,
          flows: flowsById,
          router,
          checkedForExport: checkedForDownload,
          handleCheckForExport: (id) =>
            setCheckedForDownload((prev) => xor([id], prev)),
          handleReviewChange,
          rows,
        }}
        Row={Row}
      ></Table>
      <PaginationFooter
        page={Number(currentPage)}
        maxPages={maxPages}
        url={router.location.pathname}
        router={router}
        query={router.location.query}
      />
      {!dataReviewEnhancementEnabled && (
        <div className={css.downloadRow}>
          <Button
            type="primary"
            size="medium"
            onClick={() => {
              AnalyticsService.track('Chatbot Review Data Exported', {
                flow_id: analyticsQuery.workflowId,
              });
              handleCheckedCsvExport();
            }}
            icon={<DownloadIcon className={css.downloadIcon} />}
            disabled={checkedForDownload.length === 0}
            title={
              checkedForDownload.length === 0
                ? 'Select rows to download a csv'
                : 'Download records'
            }
          >
            Download CSV
          </Button>
        </div>
      )}
    </div>
  );
};

type RowProps = {
  data: ConversationSummary,
  extras: {
    checkedState: {[conversation_id: string]: ThumbValue},
    flows: {[flow_id: string]: GraphDetails},
    router: BaseHistory,
    checkedForExport: Array<string>,
    handleCheckForExport: (conversation_id: string) => mixed,
    handleReviewChange: (
      conversation_id: string,
      value: ThumbValue,
    ) => ThumbValue,
    rows: ConversationSummary[],
  },
  sortedKeys: string[],
};
const Row = ({
  data,
  extras: {
    checkedState,
    flows,
    router,
    checkedForExport,
    handleCheckForExport,
    handleReviewChange,
    rows,
  },
  sortedKeys,
}: RowProps) => {
  const dispatch = useDispatch();

  const openFlow = (_evt: SyntheticEvent<>) => {
    router.push(`/conversations/${data.flow_id}`);
  };

  const openConversation = React.useCallback(
    (id: string) => {
      const rowData = rows.find((r) => r.id === id);

      dispatch(popModal());
      dispatch(
        pushModal({
          type: 'DATA_REVIEW_SIDEPANEL',
          data: rowData,
          flows,
          rows,
          sortedIds: sortedKeys,
          handleOpenConversation: openConversation,
          handleReviewChange,
          checkedState,
        }),
      );
    },
    [rows, flows, sortedKeys, dispatch, checkedState],
  );

  const openCurrent = () => {
    openConversation(data.id);
  };

  return (
    <Cells>
      <Cell className={css.checkboxCell}>
        <label>
          <Checkbox
            checked={checkedForExport.includes(data.id)}
            // className={css.flowFilterCheckbox}
            onChange={() => {
              handleCheckForExport(data.id);
            }}
          />
        </label>
      </Cell>
      <Cell>
        <span
          onClick={(e) => {
            if (e.metaKey) {
              openFlow(e);
            }
          }}
          onDoubleClick={openFlow}
        >
          {/* $FlowIssue[unnecessary-optional-chain] this is absolutely necesssary */}
          {flows[data.flow_id]?.name}
        </span>
      </Cell>
      <Cell>{data.channel.includes('web') ? 'Website Bot' : 'Sms Bot'}</Cell>
      <Cell>{displayDate(data.date_responded)}</Cell>
      <Cell>{data.downloaded ? displayDate(data.downloaded) : '—'}</Cell>
      <Cell>
        <div onClick={openCurrent}>
          {' '}
          {data.transcript_preview.writeback_data.length +
            data.transcript_preview.messages.length >
          0
            ? [
                ...data.transcript_preview.writeback_data,
                ...data.transcript_preview.messages,
              ]
                .map((p) => {
                  if (p.text != null && p.name != null) {
                    return `${p.name}: ${String(p.text)}`;
                  }
                  if (p.text != null && p.user_type != null) {
                    return `${p.user_type}: ${String(p.text)}`;
                  }
                })
                .join(', ')
            : '—'}
        </div>
      </Cell>
      <Cell>
        <ThumbCell
          value={
            checkedState[data.id] ??
            data.review_status /*checkedState[data.id]*/
          }
          onChange={(value: ThumbValue) => {
            handleReviewChange(data.id, value);
          }}
        />
      </Cell>
      <Cell>
        <div onClick={openCurrent} className={css.action}>
          View Transcript
        </div>
      </Cell>
    </Cells>
  );
};

const FlowFilters = ({
  flows,
  selectedFlows,
  onChange,
}: {
  flows: GraphDetails[],
  selectedFlows: number[],
  onChange?: (number[]) => mixed,
}) => {
  const dataReviewEnhancementEnabled = useReleaseFlag(
    'chatbotDataReviewEnhancement',
  );

  const handleFlowChange = (flowId) => (evt) => {
    if (dataReviewEnhancementEnabled) {
      onChange?.([flowId]);
      return;
    }

    if (evt.target.checked) {
      onChange?.([...selectedFlows, flowId]);
    } else {
      onChange?.(selectedFlows.filter((id) => id !== flowId));
    }
  };

  return (
    <ClickAway>
      {({isOpen, onOpen, cancelNext}) => (
        <div className={css.filter}>
          <FilterButton
            className={css.button}
            active={selectedFlows.length > 0}
            activeFilters={
              dataReviewEnhancementEnabled ? undefined : selectedFlows.length
            }
            onClick={() => {
              onOpen();
            }}
          >
            {dataReviewEnhancementEnabled ? 'Switch flow' : 'Flows Selected'}
          </FilterButton>
          {isOpen && (
            <div onClickCapture={cancelNext}>
              <FilterCard header="Flows" className={css.flowFilterCard}>
                <ul className={css.flowFilterList}>
                  {flows.map((flow) => (
                    <li
                      key={flow.id}
                      className={css.checkboxRow}
                      onClick={
                        dataReviewEnhancementEnabled
                          ? handleFlowChange(flow.id)
                          : undefined
                      }
                    >
                      <label className={css.flowFilterLabel}>
                        {!dataReviewEnhancementEnabled && (
                          <Checkbox
                            checked={selectedFlows.includes(flow.id)}
                            className={css.flowFilterCheckbox}
                            onChange={handleFlowChange(flow.id)}
                          />
                        )}
                        <AutoTruncatedText text={flow.name} />
                      </label>
                    </li>
                  ))}
                </ul>
              </FilterCard>
            </div>
          )}
        </div>
      )}
    </ClickAway>
  );
};

// see also ChatbotDataReviewStatus

export const ThumbCell = ({
  value,
  onChange,
}: {
  value: ThumbValue,
  onChange: (value: ThumbValue) => mixed,
}): React.Node => (
  <div className={css.thumbRow}>
    <UnstyledButton
      className={css.approveButton}
      onClick={() => onChange('accepted')}
    >
      {value === 'accepted' ? <FilledThumbUp /> : <EmptyThumbUp />}
    </UnstyledButton>
    <UnstyledButton
      className={css.denyButton}
      onClick={() => onChange('declined')}
    >
      {value === 'declined' ? <FilledThumbDown /> : <EmptyThumbDown />}
    </UnstyledButton>
  </div>
);

const EmptyThumbUp = () => (
  <svg
    width="17"
    height="19"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3828 10.6396C16.6992 10.1123 16.875 9.5498 16.875 8.88184C16.875 7.33496 15.5391 5.89355 13.8516 5.89355H12.5508C12.7266 5.43652 12.8672 4.90918 12.8672 4.24121C12.8672 1.6748 11.5312 0.549805 9.52734 0.549805C7.34766 0.549805 7.48828 3.88965 6.99609 4.38184C6.1875 5.19043 5.23828 6.7373 4.57031 7.2998H1.125C0.492188 7.2998 0 7.82715 0 8.4248V16.8623C0 17.4951 0.492188 17.9873 1.125 17.9873H3.375C3.86719 17.9873 4.32422 17.6357 4.42969 17.1787C6.01172 17.2139 7.10156 18.5498 10.6875 18.5498C10.9688 18.5498 11.25 18.5498 11.4961 18.5498C14.2031 18.5498 15.3984 17.1787 15.4336 15.21C15.9258 14.5771 16.1719 13.6982 16.0664 12.8545C16.418 12.2217 16.5234 11.4482 16.3828 10.6396ZM14.2031 12.5381C14.6602 13.2764 14.2383 14.2607 13.7109 14.5771C13.9922 16.2646 13.0781 16.8623 11.8477 16.8623H10.5117C8.01562 16.8623 6.36328 15.5615 4.5 15.5615V8.9873H4.85156C5.87109 8.9873 7.24219 6.52637 8.19141 5.57715C9.17578 4.59277 8.85938 2.90527 9.52734 2.2373C11.1797 2.2373 11.1797 3.39746 11.1797 4.24121C11.1797 5.6123 10.1953 6.24512 10.1953 7.58105H13.8516C14.5898 7.58105 15.1523 8.24902 15.1875 8.91699C15.1875 9.5498 14.7305 10.2178 14.3789 10.2178C14.8711 10.7451 14.9766 11.835 14.2031 12.5381ZM3.09375 15.7373C3.09375 16.2295 2.70703 16.5811 2.25 16.5811C1.75781 16.5811 1.40625 16.2295 1.40625 15.7373C1.40625 15.2803 1.75781 14.8936 2.25 14.8936C2.70703 14.8936 3.09375 15.2803 3.09375 15.7373Z"
      fill="#2E855C"
      fillOpacity="0.7"
    />
  </svg>
);

const FilledThumbUp = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.68848 7.875H0.875977C0.383789 7.875 0.0322266 8.26172 0.0322266 8.71875V17.1562C0.0322266 17.6484 0.383789 18 0.875977 18H3.68848C4.14551 18 4.53223 17.6484 4.53223 17.1562V8.71875C4.53223 8.26172 4.14551 7.875 3.68848 7.875ZM2.28223 16.5938C1.79004 16.5938 1.43848 16.2422 1.43848 15.75C1.43848 15.293 1.79004 14.9062 2.28223 14.9062C2.73926 14.9062 3.12598 15.293 3.12598 15.75C3.12598 16.2422 2.73926 16.5938 2.28223 16.5938ZM13.5322 2.88281C13.5322 0.28125 11.8447 0 11.001 0C10.2627 0 9.94629 1.40625 9.80566 2.03906C9.59473 2.8125 9.41895 3.58594 8.8916 4.11328C7.7666 5.27344 7.16895 6.71484 5.7627 8.08594C5.69238 8.19141 5.65723 8.29688 5.65723 8.40234V15.9258C5.65723 16.1367 5.83301 16.3125 6.04395 16.3477C6.60645 16.3477 7.34473 16.6641 7.90723 16.9102C9.03223 17.4023 10.4033 18 12.0908 18H12.1963C13.708 18 15.501 18 16.2041 16.9805C16.5205 16.5586 16.5908 16.0312 16.415 15.3984C17.0127 14.8008 17.2939 13.6758 17.0127 12.7617C17.6104 11.9531 17.6807 10.793 17.3291 9.98438C17.751 9.5625 18.0322 8.89453 17.9971 8.26172C17.9971 7.17188 17.083 6.1875 15.9229 6.1875H12.3369C12.6182 5.20312 13.5322 4.35938 13.5322 2.88281Z"
      fill="#2E855C"
    />
  </svg>
);

const EmptyThumbDown = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2832 8.29541C17.4238 7.48682 17.3184 6.71338 16.9668 6.08057C17.0723 5.23682 16.8262 4.35791 16.334 3.7251C16.2988 1.75635 15.1035 0.350098 12.3965 0.350098C12.1504 0.350098 11.8691 0.385254 11.5879 0.385254C7.9668 0.385254 6.80664 1.75635 5.40039 1.75635H5.01367C4.80273 1.61572 4.55664 1.4751 4.27539 1.4751H2.02539C1.39258 1.4751 0.900391 2.00244 0.900391 2.6001V11.0376C0.900391 11.6704 1.39258 12.1626 2.02539 12.1626H4.27539C4.66211 12.1626 5.04883 11.9517 5.22461 11.6001H5.4707C6.13867 12.1978 7.08789 13.7446 7.89648 14.5532C8.38867 15.0454 8.24805 18.3501 10.4277 18.3501C12.4316 18.3501 13.7676 17.2603 13.7676 14.6938C13.7676 14.0259 13.627 13.4985 13.4512 13.0415H14.752C16.4395 13.0415 17.7754 11.6001 17.7754 10.0532C17.7754 9.3501 17.5996 8.82275 17.2832 8.29541ZM3.15039 10.7563C2.6582 10.7563 2.30664 10.4048 2.30664 9.9126C2.30664 9.45557 2.6582 9.06885 3.15039 9.06885C3.60742 9.06885 3.99414 9.45557 3.99414 9.9126C3.99414 10.4048 3.60742 10.7563 3.15039 10.7563ZM14.752 11.354H11.0957C11.0957 12.6899 12.0801 13.3228 12.0801 14.6938C12.0801 15.5376 12.0801 16.6626 10.4277 16.6626C9.75977 16.0298 10.0762 14.3423 9.0918 13.3579C8.14258 12.4087 6.77148 9.9126 5.75195 9.9126H5.40039V3.37354C7.26367 3.37354 8.91602 2.07275 11.4121 2.07275H12.748C13.9785 2.07275 14.8926 2.67041 14.6113 4.35791C15.1387 4.67432 15.5605 5.65869 15.1035 6.39697C15.877 7.1001 15.7715 8.18994 15.2793 8.71729C15.6309 8.71729 16.0879 9.38525 16.0879 10.0181C16.0527 10.686 15.4902 11.354 14.752 11.354Z"
      fill="#E71D42"
      fillOpacity="0.7"
    />
  </svg>
);
const FilledThumbDown = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 1.85938V9.82812C0 10.293 0.332031 10.625 0.796875 10.625H3.45312C3.88477 10.625 4.25 10.293 4.25 9.82812V1.85938C4.25 1.42773 3.88477 1.0625 3.45312 1.0625H0.796875C0.332031 1.0625 0 1.42773 0 1.85938ZM1.32812 8.5C1.32812 8.06836 1.66016 7.70312 2.125 7.70312C2.55664 7.70312 2.92188 8.06836 2.92188 8.5C2.92188 8.96484 2.55664 9.29688 2.125 9.29688C1.66016 9.29688 1.32812 8.96484 1.32812 8.5ZM10.3594 17C11.1562 17 12.75 16.7344 12.75 14.3105C12.75 12.916 11.8867 12.1191 11.6211 11.1562H15.0078C16.1035 11.1562 16.9668 10.2598 17 9.23047C17 8.63281 16.7344 8.00195 16.3359 7.60352C16.668 6.83984 16.6016 5.74414 16.0371 4.98047C16.3027 4.11719 16.0371 3.05469 15.4727 2.49023C15.6387 1.89258 15.5723 1.39453 15.2734 0.996094C14.6094 0.0332031 12.916 0 11.4883 0H11.3887C9.79492 0.0332031 8.5 0.597656 7.4375 1.0625C6.90625 1.29492 6.20898 1.59375 5.67773 1.59375C5.47852 1.62695 5.3125 1.79297 5.3125 1.99219V9.09766C5.3125 9.19727 5.3457 9.33008 5.41211 9.39648C6.74023 10.6914 7.30469 12.0527 8.36719 13.1484C8.86523 13.6465 9.03125 14.377 9.23047 15.1074C9.36328 15.7051 9.66211 17 10.3594 17Z"
      fill="#E71D42"
    />
  </svg>
);
