// @flow strict

import React from 'react';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {Icon} from '@spaced-out/ui-design-system/lib/components/Icon';
import {BodyLarge} from '@spaced-out/ui-design-system/lib/components/Text';
import {
  Toast,
  TOAST_SEMANTIC,
  ToastTitle,
  ToastBody,
  ToastFooter,
  toastApi,
} from '@spaced-out/ui-design-system/lib/components/Toast';

import {Tooltip} from '@spaced-out/ui-design-system/lib/components/Tooltip';

import css from './genesis-style.css';


const toast1 = (
  <Toast semantic={TOAST_SEMANTIC.success} time="Today 4:00pm" initialFocus={0}>
    <ToastTitle>Toast Title Sample</ToastTitle>
    <ToastBody>The quick brown fox jumps over the lazy dog</ToastBody>
    <ToastFooter>
      <Button
        children="Dismiss"
        onClick={() => {
          // eslint-disable-next-line no-console
          console.log('Secondary Action Clicked');
        }}
      />
      <Button
        children="Primary Action"
        onClick={() => {
          // eslint-disable-next-line no-console
          console.log('Primary Action Clicked');
        }}
      />
    </ToastFooter>
  </Toast>
);

const App = (): React$Element<'div'> => (
  <div className={css.appContainer}>
    <h1>Hello I'm consuming the component library</h1>
    <Button>Hello world</Button>
    <Icon name="face-party"></Icon>
    <BodyLarge>Hello world</BodyLarge>

    <Button
      size="small"
      onClick={() =>
        toastApi.show(toast1, {
          autoClose: false,
        })
      }
    >
      Toast With Action
    </Button>

    <Tooltip
      body="The quick brown fox jumps over the dog,
  The quick brown fox jumps over the dog
  The quick brown fox jumps over the dog"
      bodyMaxLines={2}
      placement="bottom-start"
      title="Label"
      titleMaxLines={1}
    >
      <Button iconLeftName="face-sunglasses" type="tertiary">
        Hover Me
      </Button>
    </Tooltip>
  </div>
);

export default App;
