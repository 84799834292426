// @noflow

import type {SenseAction} from 'src/action-creators/types';
import type {State} from 'src/reducers';
import type {KeyedThunkAction, KeyedThunk} from 'src/types/thunk-decorators';

import logger from 'src/utils/logger';

import exenv from 'exenv';
import get from 'lodash/get';

// TODO (kyle): import these
type Dispatch = Function;
type GetState = Function;

/**
 * Attach a key-returning function to the given function. The key is used by
 * the cached and fetching decorators, and must be added *before* them.
 */
export function key<P: Array<any> | Tuple, K: (...P) => string>(key: K) {
  return <F: (...P) => any>(wrappedFunc: F): {[[call]]: F, KEY: K, ...} =>
    Object.assign(
      function (...params) {
        return Object.assign(wrappedFunc.apply(this, params), {
          KEY: () => key(...params),
        });
      },
      wrappedFunc,
      {KEY: key},
    );
}

/**
 * evaluates whether a Date.getTime() is older than a ttl
 */
const expired = (time: number, ttl: number): boolean => {
  if (time) {
    const now = new Date().getTime();
    return now - time > ttl;
  }
  return true;
};

/**
 * Cache a promise-returning function.
 *
 * Handles resolving action calls that are already cached, and caching the
 * results of ones that aren't. Only works for promises
 */
type ReduxState = {[key: string]: {}};
type CacheOpts = {
  ttl?: number,
  hash?: HashFunction,
  cacheSet?: CacheSetCreator,
  cacheHit?: CacheHitCreator,
  cacheMiss?: CacheMissCreator,
  ttlSelector?: TTLSelector,
};
// types for internal action creators used to populate cache
type HashFunction = () => string;
type CacheSetCreator = (
  key: string,
  timestamp: number,
  queryHash?: string,
) => {
  type: string,
  payload: {key: string, timestamp: number, queryHash?: string},
};
type CacheHitCreator = (
  key: string,
  ttl: number,
  queryHash?: string,
) => {type: string, payload: {key: string}, meta: {ttl: number}};
type CacheMissCreator = (
  key: string,
  ttl: number,
  queryHash?: string,
) => {type: string, payload: {key: string}, meta: {ttl: number}};
type TTLSelector = (
  state: ReduxState,
  key: string,
) => {timestamp: number, queryHash?: string};

// These are defaults (i can't see why we would change them)
export const cacheSetAction = (
  key: string,
  timestamp: number = new Date().getTime(),
  queryHash?: string,
) => ({type: '@@cache/set', payload: {key, timestamp, queryHash}});
const cacheHitAction = (key, ttl, queryHash) => ({
  type: '@@cache/hit',
  payload: {key, queryHash},
  meta: {ttl},
});
const cacheMissAction = (key, ttl, queryHash) => ({
  type: '@@cache/miss',
  payload: {key, queryHash},
  meta: {ttl},
});
const cacheTtlSelector = (state, key) =>
  get(state, ['requestCache', 'cache', key], {timestamp: 0});

// This is the type of our api methods now, they work this way so they can access the store
// and do any other validation needed before firing off an an xhr
// So while  reduxApi.get(path) returns => (dispatch, getState) => Promise
// you are welcome to take advantage of this by crafting an api call like
// userId => reduxApi.get(`path/${useId}`)
type ThunkReturning<ARGS, RETURNS> = (
  ...args: ARGS[]
) => (dispatch: Dispatch, getState: GetState) => Promise<RETURNS>;

// TODO (kyle): use thunk action in reducer file
type ThunkAction<R> = (dispatch: Dispatch, getState: GetState) => Promise<R>;

type Decorator<ARGS, RETURN, DECORATED_RETURN> = (
  f: (...args: ARGS[]) => RETURN,
) => (...args: ARGS[]) => DECORATED_RETURN;

// The function that handles receipt of an api request is basically
// an action creator: feed it some payload value and get back an object
// with at least a string in the type field
type Receiver = (payload: any, ...args: *[]) => SenseAction;

// TODO (kyle): fix the typing on this decorator
export function cached(
  receive: Receiver,
  {
    ttl = 60000,
    hash,
    cacheSet = cacheSetAction,
    cacheHit = cacheHitAction,
    cacheMiss = cacheMissAction,
    ttlSelector = cacheTtlSelector,
  }: CacheOpts = {},
) {
  return <A: Array<mixed>, R>(
    wrappedFunc: KeyedThunk<A, R>,
  ): KeyedThunk<A, void> => {
    if (!wrappedFunc.KEY) {
      throw new Error('Must use key() decorator first before cached');
    }

    const cacheWrapped = (...restArgs) => {
      const thunk = wrappedFunc(...restArgs);
      const keyVal = thunk.KEY();

      const cacheThunk = function cacheThunk(dispatch, getState) {
        return new Promise((resolve, reject) => {
          const savedTtl = ttlSelector(getState(), keyVal);
          const isExpired = expired(savedTtl.timestamp, ttl);
          const queryHash = hash ? hash(...restArgs) : keyVal;

          if (!isExpired && queryHash === savedTtl.queryHash) {
            dispatch(cacheHit(keyVal, ttl, queryHash));
            resolve();
          } else {
            dispatch(cacheMiss(keyVal, ttl, queryHash));

            thunk(dispatch, getState)
              .then((result) => {
                dispatch(receive(result, ...restArgs));
                dispatch(cacheSet(keyVal, new Date().getTime(), queryHash));
                // TODO (kyle): why are we not resolving the result here?
                resolve();
              })
              .catch((error) => reject(error));
          }
        });
      };

      return Object.assign(cacheThunk, thunk);
    };

    return Object.assign(cacheWrapped, wrappedFunc);
  };
}

type FetchingOpts = {
  inProgressSelector?: (state: Object, key: string) => ?Promise<any>,
  beginRequestAction?: (
    key: string,
    promise: Promise<mixed>,
  ) => {type: string, payload: Promise<mixed>, meta: {key: string}},
  endRequestAction?: (key: string) => {type: string, meta: {key: string}},
  errorRequestAction?: (
    key: string,
    error: any,
  ) => {type: string, meta: {key: string}, error: any},
};
const selectInProgress = (state, key) =>
  get(state, ['requestCache', 'request', key, 'inflight']);
export const beginAction = (key: string, promise: Promise<any>) => ({
  type: '@@requestCache/begin',
  payload: promise,
  meta: {key},
});
export const endAction = (key: string) => ({
  type: '@@requestCache/end',
  meta: {key},
});
export const errorAction = (key: string, error: Error) => ({
  type: '@@requestCache/error',
  meta: {key},
  error,
});
export const progressAction = (key: string, progress: number) => ({
  type: '@@requestCache/progress',
  meta: {key},
  payload: progress,
});
/**
 * Batch an action and surface errors in global state.
 *
 * Handles preventing multiple concurrent calls to the same action by resolving
 * subsequent calls with the existing request promise. Puts any errors in the
 * global state so components can react to them.
 */

export function fetching({
  inProgressSelector = selectInProgress,
  beginRequestAction = beginAction,
  endRequestAction = endAction,
  errorRequestAction = errorAction,
}: FetchingOpts = {}) {
  return <A: Array<mixed>, R>(
    wrappedFunc: KeyedThunk<A, R>,
  ): KeyedThunk<A, R> => {
    if (!wrappedFunc.KEY) {
      throw new Error('Must use key() decorator first before fetching');
    }

    const fetchingWrapped = (...args) => {
      const thunk = wrappedFunc(...args);
      const keyValue = thunk.KEY();

      const fetchingThunk = function fetchingThunk(dispatch, getState) {
        const keyValue = wrappedFunc.KEY.apply(this, args);

        const inProgressRequest = inProgressSelector(getState(), keyValue);
        if (inProgressRequest) {
          return inProgressRequest;
        } else {
          // we could make this dispatch(wrappedFunc(args))
          // but that might tie us too closely to redux-thunk
          const request = thunk(dispatch, getState);
          dispatch(beginRequestAction(keyValue, request));

          // NOTE (kyle): we return the original request promise, not the
          // handled promise generated (and unassigned) here.
          request
            .then(() => {
              dispatch(endRequestAction(keyValue));
            })
            .catch((error) => {
              dispatch(errorRequestAction(keyValue, error));
            });

          return request;
        }
      };

      return Object.assign(fetchingThunk, thunk);
    };

    return Object.assign(fetchingWrapped, wrappedFunc);
  };
}

// check if a @key-ed actionCreator is still inflight
//
//     const pending = inflight(reduxState, getFoo()) // or
//     const stillWaiting = infilght(reduxState, getUser('userid'))
//
// the function call you feed to inflight should match the
// dependency your page or route or component needs
export function inflight(state: State, func: KeyedThunkAction<mixed>) {
  if (!func.KEY) {
    throw new Error('only cached/fetching-wrapped actions have KEYs set');
  }
  return Boolean(
    get(state, ['requestCache', 'request', func.KEY() || '@@null', 'inflight']),
  );
}

export function hasError(state: State, func: KeyedThunkAction<mixed>) {
  if (!func.KEY) {
    throw new Error('only cached/fetching-wrapped actions have KEYs set');
  }
  return get(state, ['requestCache', 'error', func.KEY()]);
}

export function progress(state: State, func: KeyedThunkAction<mixed>) {
  if (!func.KEY) {
    throw new Error('only cached/fetching-wrapped actions have KEYs set');
  }
  return get(state, [
    'requestCache',
    'request',
    func.KEY() || '@@null',
    'progress',
  ]);
}

// evict an action creator's key to manually cache-bust
// dispatch this right before you dispatch an action which will receive
// possibly cached data
//
//     dispatch(evict(getFoo())) for arg-less getFoo or
//     dispatch(evict(getUser('someid')))
//
export const evictAction = (key: string) => ({
  type: '@@cache/evict',
  payload: {key},
});
export const evict = (thunk: KeyedThunkAction<mixed>) => {
  if (!thunk.KEY) {
    throw new Error('only @key-ed action creators can be evicted');
  }
  return evictAction(thunk.KEY());
};
export const force =
  (thunk: KeyedThunkAction<mixed>) => (dispatch: Dispatch) => {
    dispatch(evict(thunk));
    dispatch(thunk);
  };

/**
 * Force an thunk to ignore caching rules and always hit the server.
 * Essentially the same as evicting before calling.
 *
 *   dispatch(forceThunk(getFoo()));
 */
export const forceThunk =
  (thunk: KeyedThunkAction<mixed>) => (dispatch: Dispatch) => {
    dispatch(evict(thunk));
    dispatch(thunk);
  };

/**
 * Redirect browser page to /signin if request throws Error: UNAUTHORIZED.
 */
export function authorized(): Function {
  return (func: Function): Function =>
    key(func.KEY)((...args) =>
      func.apply(this, args).catch((error) => {
        if (error.status && error.status === 401 && exenv.canUseDOM) {
          window.location = '/signin';
        }
        throw error;
      }),
    );
}

/**
 * Suppresses and logs request errors so that they don't break code
 * the react render cycle.
 */
export function suppressed(): Function {
  return (func: Function): Function =>
    key(func.KEY)((...args) =>
      func.apply(this, args).catch((error) => {
        const keyVal = func.KEY.apply(this, args);
        logger.error('error: ' + keyVal, error.stack || error);
      }),
    );
}

/**
 * Delays the thunk call by the specified miliseconds. Useful for testing
 * inflight booleans and such that would usually resolve nearly instantly.
 */
export function delay(ms: number): Function {
  return (func: Function): Function =>
    key(func.KEY)(
      (...args) =>
        (dispatch, getState) =>
          new Promise((resolve) => {
            setTimeout(resolve, ms);
          }).then(() => func.apply(this, args)(dispatch, getState)),
    );
}
