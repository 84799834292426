// @flow

import type {
  Dispatch,
  GetState,
  ThunkAction,
  ThunkSyncAction,
} from 'src/reducers';
import type {
  Contact,
  ContactUpdate,
  CsvResults,
  CsvFileResults,
  ContactFormData,
} from 'src/types/contacts';
import type {AudienceMember} from 'src/types/audience-member';
import type {ErrorMap} from 'src/types/redux';
// $FlowFixMe[untyped-type-import]
import type {ClickedNumberMessage} from 'src/chat-extension/messages';
import type {Router} from 'src/types/router';

import pick from 'lodash/pick';
import keyBy from 'lodash/keyBy';
import invariant from 'invariant';

import {selectMaxContactsCsvUpload} from 'src/selectors/agency';
import {thunkify as flow} from 'src/utils/thunks';
import {camel, snake, getFullName} from 'src/utils';
import {getPrimaryPhone} from 'src/utils/contacts';
import {parseCsv} from 'src/utils/csv';
import {key, cached, fetching, progressAction} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api-v2';
import {normalizePhone} from 'src/utils/phone';
import logger from 'src/utils/logger';
import analytics from 'src/segment-analytics';
import {selectContactByPhone} from 'src/selectors/contacts';
import {selectCurrentPhoneCountry} from 'src/selectors/phone-number-sets';
import {setMultipleRecipients} from 'src/action-creators/draft-messages';
import {showGenericError, showApiError} from 'src/action-creators/modal';
import {selectDefaultPhoneNumberSet} from 'src/selectors/chat';


export const RECEIVE_MANY_CONTACTS = 'contacts/receiveMany';
export const RECEIVE_CONTACT_UPDATES = 'contacts/receiveUpdates';
export const REMOVE_CONTACT = 'contacts/remove';
export const CHANGE_CONTACT_FORM = 'contacts/form/change';
export const RESET_CONTACT_FORM = 'contacts/form/reset';
export const RECEIVE_CONTACT_FORM_ERRORS = 'contacts/form/receiveErrors';
export const RECEIVE_CSV = 'contacts/receiveCsv';
export const CLEAR_CSV = 'contacts/clearCsv';
export const RECEIVE_CSV_ERRORS = 'contacts/receiveCsvErrors';

export type Action =
  | ReceiveManyContactsAction
  | ReceiveContactUpdatesAction
  | RemoveContactAction
  | ChangeContactFormAction
  | ResetContactFormAction
  | ReceiveContactFormErrorsAction
  | ReceiveCsvAction
  | ReceiveCsvErrorsAction
  | ClearCsvAction;

type ReceiveManyContactsAction = {
  type: 'contacts/receiveMany',
  payload: {[id: string]: Contact},
};

type ReceiveContactUpdatesAction = {
  type: 'contacts/receiveUpdates',
  payload: ContactUpdate[],
};

type ReceiveCsvAction = {
  type: 'contacts/receiveCsv',
  payload: {
    results: CsvResults,
    file: File,
  },
};

type ReceiveCsvErrorsAction = {
  type: 'contacts/receiveCsvErrors',
  payload: string[],
};

type ClearCsvAction = {
  type: 'contacts/clearCsv',
};

export const receiveContactUpdates = (
  contactUpdates: ContactUpdate[],
): ReceiveContactUpdatesAction => ({
  type: RECEIVE_CONTACT_UPDATES,
  payload: contactUpdates,
});

export const receiveManyContacts = (
  contacts: Contact[],
): ReceiveManyContactsAction => ({
  type: RECEIVE_MANY_CONTACTS,
  payload: keyBy(contacts, 'id'),
});

export const getContacts: () => ThunkAction<mixed> = flow(
  key(() => 'contacts/getAll'),
  cached((json) => receiveManyContacts(camel(json))),
  fetching(),
)(() => reduxApi.get('messages_v2/contacts'));

export const receiveContact = (contact: Contact): ReceiveManyContactsAction =>
  receiveManyContacts([contact]);

export const getContactByPhone: (phone: string) => ThunkAction<mixed> = flow(
  key((phone) => `contacts/getByPhone:${phone}`),
  cached((json) => {
    // TODO(gab): Get back end to return 404 instead of null when no contact is found for
    // phone
    if (!json || !json.id) {
      return {type: 'do nothing'};
    }
    return receiveContact(camel(json));
  }),
  fetching(),
)((phone: string) => reduxApi.get(`messages/contact/by-phone/${phone}`));

type ChangeContactFormAction = {
  type: 'contacts/form/change',
  payload: {
    formData: ContactFormData,
    formDataErrors: ErrorMap,
  },
};

export const changeContactForm =
  (props: $Shape<ContactFormData>): ThunkSyncAction =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const {formData, formDataErrors} = state.contacts;
    const nextFormDataErrors = {...formDataErrors};

    return dispatch(
      ({
        type: CHANGE_CONTACT_FORM,
        payload: {
          formData: {
            ...formData,
            // $FlowFixMe upgraded flow
            ...props,
          },
          formDataErrors: nextFormDataErrors,
        },
      }: ChangeContactFormAction),
    );
  };

type ResetContactFormAction = {
  type: 'contacts/form/reset',
  payload: ?$Shape<Contact>,
};

export const resetContactForm = (
  form: ?$Shape<Contact>,
): ResetContactFormAction => ({
  type: RESET_CONTACT_FORM,
  payload: form,
});

export const fetchContact: (id: string) => ThunkAction<void> = flow(
  key((id) => `messages/contact/${id}`),
  fetching(),
)(
  (id: string) => (dispatch: Dispatch) =>
    dispatch(reduxApi.get(`messages_v2/contact/${id}`)).then((response) => {
      const cameled = camel(response);
      dispatch(receiveContact(cameled));
    }),
);

export const getContact =
  (id: string): ThunkAction<void> =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState().contacts;
    const contact = state.contacts[id];
    if (contact) {
      return Promise.resolve();
    } else {
      return dispatch(fetchContact(id));
    }
  };

export const editContact =
  (id: string): ThunkAction<void> =>
  async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(getContact(id));
    const contact = getState().contacts.contacts[id];

    invariant(
      contact,
      'Attempted to call `editContact` on a nonexistent resource',
    );

    return dispatch(resetContactForm(contact));
  };

type RemoveContactAction = {
  type: 'contacts/remove',
  payload: string,
};

const removeContact = (id: string) => ({
  type: REMOVE_CONTACT,
  payload: id,
});

export const deleteContact =
  (id: string): ThunkAction<mixed> =>
  (dispatch: Dispatch, getState: GetState) => {
    const contact = getState().contacts.contacts[id];

    invariant(
      contact,
      'Attempted to call `deleteContact` on a nonexistent resource',
    );

    // Optimistically remove contact from redux store ...
    dispatch(removeContact(id));
    return dispatch(reduxApi.del(`messages_v2/contact/${id}`)).catch((err) => {
      // ... but restore contact if the API delete failed
      dispatch(receiveContact(contact));
      throw err;
    });
  };

type ReceiveContactFormErrorsAction = {
  type: 'contacts/form/receiveErrors',
  payload: ErrorMap,
};

export const receiveContactFormErrors = (
  errors: ErrorMap,
): ReceiveContactFormErrorsAction => ({
  type: RECEIVE_CONTACT_FORM_ERRORS,
  payload: errors,
});

export const updateContact =
  (contact: Contact, optimistic: boolean = false): ThunkAction<Contact> =>
  (dispatch: Dispatch, getState: GetState): Promise<Contact> => {
    const originalContact = getState().contacts.contacts[contact.id];

    invariant(
      originalContact,
      'Attempted to update a contact that does not exist.',
    );

    if (optimistic) {
      dispatch(receiveContact({...originalContact, ...contact}));
    }

    return dispatch(
      reduxApi.put(
        `messages_v2/contact/${contact.id}`,
        snake(
          pick(contact, ['firstName', 'lastName', 'phoneNumbers', 'email']),
        ),
      ),
    ).then(
      (response) => {
        const contact = camel(response);
        dispatch(receiveContact(contact));
        return contact;
      },
      (error) => {
        dispatch(showApiError(error, 'Failed to update contact'));

        // NOTE (kyle): there's a race condition here with overlapping contact
        // updates.
        if (optimistic) {
          dispatch(receiveContact(originalContact));
        }

        throw error;
      },
    );
  };

export const csvKey = 'contacts/parseCsv';
export const parseContactsCsv: (file: File) => ThunkAction<> = flow(
  key(() => csvKey),
  fetching(),
)(
  (file: File): ThunkAction<> =>
    async (dispatch: Dispatch, getState: GetState) => {
      const errors = [];
      let results;

      try {
        results = (await parseCsv(file, {
          header: true,
          skipEmptyLines: true,
          // NOTE (kyle): maybe debounce this?
          progress: (percent) => dispatch(progressAction(csvKey, percent)),
        }): CsvResults);
      } catch (error) {
        errors.push(error);
      }

      if (results) {
        const {
          meta: {fields},
          data,
        } = results;

        const maxContactsCsvUpload = selectMaxContactsCsvUpload(getState());

        if (data.length > maxContactsCsvUpload) {
          errors.push(`Your CSV has more than ${maxContactsCsvUpload} rows.`);
        }

        if (!fields.includes('phone')) {
          errors.push('Your CSV does not contain a \'phone\' column.');
        }

        if (
          !fields.includes('name') &&
          !(fields.includes('first name') && fields.includes('last name'))
        ) {
          errors.push(
            'Your CSV does not contain a \'name\' or \'first name\' and \'last name\' column.',
          );
        }

        // NOTE (kyle): limit the fields shown
        const fieldNameMap = {
          'first name': 'first_name',
          'last name': 'last_name',
          phone: 'phone',
          email: 'email',
        };
        const allowedFields: string[] = Object.keys(fieldNameMap);
        // $FlowIssue object values
        const finalFields: string[] = Object.values(fieldNameMap);
        const requiredFields = ['first name', 'last name', 'phone'];
        const parsedRows = data
          .filter((row) =>
            requiredFields.every((field) => (row[field] || '').trim()),
          )
          .map((row) => {
            const newRow = {};
            allowedFields.forEach((field) => {
              const contactField = fieldNameMap[field];
              newRow[contactField] = row[field];
            });
            return newRow;
          });

        results = {
          ...results,
          meta: {
            // $FlowFixMe[incompatible-use]
            ...results.meta,
            fields: finalFields,
            // $FlowFixMe[incompatible-use]
            numDropped: results.data.length - parsedRows.length,
          },
          data: parsedRows,
        };
      }

      if (!results || errors.length > 0) {
        return dispatch(receiveCsvErrors(errors));
      } else {
        return dispatch(receiveCsv({results, file}));
      }
    },
);

const receiveCsv = (payload: CsvFileResults): ReceiveCsvAction => ({
  type: RECEIVE_CSV,
  payload,
});

const receiveCsvErrors = (errors: string[]): ReceiveCsvErrorsAction => ({
  type: RECEIVE_CSV_ERRORS,
  payload: errors,
});

export const clearCsv = (): ClearCsvAction => ({
  type: CLEAR_CSV,
});

export const uploadCsv: () => ThunkAction<void> = flow(
  key(() => csvKey),
  fetching(),
)(() => async (dispatch: Dispatch, getState: GetState) => {
  const csvState = getState().contacts.csv;

  invariant(
    csvState && csvState.file && csvState.results,
    'Attempted to upload a csv that did not exist in the store.',
  );

  const {file, results} = csvState;

  const {
    created,
    updated,
  }: {
    created: Contact[],
    updated: Contact[],
    invalid: Object[],
  } = await dispatch(
    reduxApi.post(
      'messages_v2/contacts/upload',
      {
        contacts_json: results.data,
        file,
      },
      {},
      {
        multipart: true,
        processProgress: ({percent}) => {
          dispatch(progressAction(csvKey, (percent || 0) / 100));
        },
      },
    ),
  );

  // NOTE (kyle): saving this code if i need to test later
  /*
  await new Promise(resolve => {
    let i = 0;
    const countUp = () => {
      dispatch(progressAction(csvKey, i / 100));
      i++;
      if (i >= 100) {
        resolve();
      } else {
        setTimeout(countUp, 200);
      }
    };

    countUp();
  });
  */

  dispatch(receiveManyContacts(camel([...created, ...updated])));
});

export const routeExternalContact =
  (
    router: Router,
    // $FlowFixMe[value-as-type] [v1.32.0]
    message: ClickedNumberMessage,
  ): ThunkAction<void> =>
  async (dispatch: Dispatch, getState: GetState) => {
    // NOTE (kyle): <= 1.14.3 versions of the extension did not
    // pass meta info with this message.
    const {payload, meta: {append} = {}} = message;
    const currentPhoneCountry = selectCurrentPhoneCountry(getState());
    const phoneNumberSet = selectDefaultPhoneNumberSet(getState());
    const phoneNumber = normalizePhone(
      payload.phoneNumber,
      currentPhoneCountry,
    );

    if (!phoneNumber) {
      dispatch(
        showGenericError({
          title: 'Unable to use contact.',
          text: `${payload.phoneNumber} is an invalid ${currentPhoneCountry} or international phone number.`,
        }),
      );
      return;
    }

    invariant(
      phoneNumber || payload.id,
      'Cannot initiate chat with this record. No id or valid phone.',
    );

    let contact: ?Contact;

    // TODO (kyle): remove this
    if (false && payload.id && payload.type) {
      const {type, id} = payload;
      try {
        contact = await dispatch(
          reduxApi.get(
            `messages/contact/by-external-source/type/${type}/id/${id}`,
            undefined,
            {
              silence: true,
            },
          ),
        );
        contact = camel(contact);
      } catch (error) {}
    }

    if (!contact) {
      try {
        // TODO (kyle): get thread by phone and see if it has a contact
        // or audience member.
        //await dispatch(getContactByPhone(phoneNumber));
      } catch (error) {}
      contact = selectContactByPhone(getState(), phoneNumber);
    }

    const memberNamesAndNums = payload.name
      ? {}
      : (await dispatch(getNamesForPhoneNumbers([phoneNumber]))) || {};

    const atsContact = {
      ...payload,
      phoneNumber,
      name: payload.name ?? memberNamesAndNums[phoneNumber],
    };

    if (append) {
      const additionalRecipient = contact
        ? {contact}
        : {
            atsContact,
          };
      dispatch(
        setMultipleRecipients([
          ...getState().draftMessages.recipients,
          additionalRecipient,
        ]),
      );

      if (router.location.pathname !== '/messages/new') {
        router.push({
          ...router.location,
          pathname: '/messages/new',
        });
      }
    } else {
      const contactPhone = contact && getPrimaryPhone(contact);
      if (contactPhone) {
        // NOTE (kyle): preserve location query string for chrome extension
        router.push({
          ...router.location,
          pathname: `/messages/with/${contactPhone}/from/${phoneNumberSet.phone_numbers[0]}/${phoneNumberSet['selected_channel']}`,
        });
      } else {
        dispatch(
          setMultipleRecipients([
            {
              atsContact,
            },
          ]),
        );
        router.push({
          ...router.location,
          pathname: '/messages/new',
        });
      }
    }
  };

export const getNamesForPhoneNumbers =
  (
    numbers: string[],
  ): ThunkAction<?{
    [phoneNumber: string]: string,
  }> =>
  async (dispatch: Dispatch) => {
    try {
      const audienceMembers: {
        [phoneNumber: string]: AudienceMember[],
      } = await dispatch(
        reduxApi.post(`messages_v2/get-entities-for-phone-numbers`, {
          phone_numbers: numbers,
        }),
      );

      const memberNamesAndNums = {};
      Object.keys(audienceMembers).forEach((memberNumber) => {
        const audienceMember = camel(audienceMembers[memberNumber][0]);
        if (audienceMember) {
          memberNamesAndNums[memberNumber] = getFullName(audienceMember);
        }
      });

      return memberNamesAndNums;
    } catch (error) {
      analytics.track('messaging.name.fetch.fail');
      logger.error('Failed to fetch names for numbers', error);
    }
  };
