// @flow strict

import type {SurveySummaryActions} from 'src/action-creators/survey-summary';
import type {
  Workflow,
  SurveySummarySavedResponse,
  SavedSurveySummaryListResponse,
  SummaryGenerationFields,
} from 'src/types/survey-summary';

import {
  RECEIVE_WORKFLOWS,
  RECEIVE_SAVED_SUMMARY,
  RECEIVE_SAVED_SUMMARIES,
  SET_SUMMARY_GENERATION_INPUT_FIELDS,
} from 'src/action-creators/survey-summary';

import {formatIsoDate, getSubtractedDays} from 'src/utils/date-time-2';


export type SurveySummaryState = {
  savedSummary: ?SurveySummarySavedResponse,
  savedSummaryList: SavedSurveySummaryListResponse,
  workflowsMap: Map<number, Workflow[]>,
  totalWorkflowCount: number,
  summaryGenerationFields: SummaryGenerationFields,
};

const initialState: SurveySummaryState = {
  savedSummaryList: {
    summaries: [],
    totalCount: 0,
  },
  savedSummary: null,
  totalWorkflowCount: 0,
  workflowsMap: new Map(),
  summaryGenerationFields: {
    selectedJourney: null,
    selectedTouchpoint: null,
    selectedQuestion: null,
    // Set the date range of last 7 days in case of All Journeys
    startDate: getSubtractedDays(formatIsoDate(), 7),
    endDate: formatIsoDate(),
  },
};

export default (
  state: SurveySummaryState = initialState,
  action: SurveySummaryActions,
): SurveySummaryState => {
  switch (action.type) {
    case RECEIVE_WORKFLOWS: {
      const {workflows, pageNumber, isNew} = action.payload;
      const tempWorkflowsMap = isNew ? new Map() : new Map(state.workflowsMap);
      return {
        ...state,
        totalWorkflowCount: action.payload.totalCount,
        workflowsMap: tempWorkflowsMap.set(pageNumber, workflows),
      };
    }
    case RECEIVE_SAVED_SUMMARY: {
      return {
        ...state,
        savedSummary: action.payload,
      };
    }
    case RECEIVE_SAVED_SUMMARIES: {
      const {summaries, totalCount, pageNumber} = action.payload;
      return {
        ...state,
        savedSummaryList: {
          summaries:
            pageNumber === 1
              ? summaries
              : [...state.savedSummaryList.summaries, ...summaries],
          totalCount,
        },
      };
    }
    case SET_SUMMARY_GENERATION_INPUT_FIELDS: {
      const summaryGenerationFieldPartials = action.payload;
      return {
        ...state,
        summaryGenerationFields: {
          ...state.summaryGenerationFields,
          ...summaryGenerationFieldPartials,
        },
      };
    }
  }

  return state;
};
