// @flow strict
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useEnvironmentContext} from 'src/hooks/useEnvironmentContext';
import {ERROR_CODES} from 'src/components/authentication/errors.js';
//$FlowFixMe[nonstrict-import]
import {selectIsAuthed} from 'src/selectors/accounts';
import {selectResetPasswordError} from 'src/selectors/authentication';
import {
  resetPassword,
  updatePassword,
} from 'src/action-creators/authentication.js';

import PasswordInstruction from 'src/components/authentication/common/password-instruction/password-instruction.jsx';
import PasswordConfirmation from 'src/components/authentication/common/password-confirmation/password-confirmation.jsx';
import {Input} from '@spaced-out/ui-design-system/lib/components/Input';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {TitleMedium} from '@spaced-out/ui-design-system/lib/components/Text';
import {InContextAlert} from '@spaced-out/ui-design-system/lib/components/InContextAlert';
import ResetConfirmation from 'src/components/authentication/reset/reset-confirmation.jsx';

import {CONSTANTS} from 'src/components/authentication/contants.js';
import {classify} from 'src/utils/classify';
import css from './reset-password.css';
import maincss from 'src/components/authentication/main.css';


const ResetPasswordContainer = (): React.Node => {
  const dispatch = useDispatch();

  const {isExtension} = useEnvironmentContext();

  const [resetToken, setResetToken] = React.useState<string>('');
  const [currentPassword, setCurrentPassword] = React.useState<string>('');
  const [passwordStrength, setPasswordStrength] = React.useState<number>(0);
  const [newPassword, setNewPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [disableReset, setDisableReset] = React.useState<boolean>(true);
  const [isResetSuccess, setIsResetSuccess] = React.useState<boolean>(false);
  const [isResetInProgress, setIsResetInProgress] =
    React.useState<boolean>(false);

  const isAgentLoggedIn = useSelector(selectIsAuthed);
  const resetPasswordError = useSelector(selectResetPasswordError);

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('reset_id');
    if (token) {
      setResetToken(token);
    }
  }, []);

  React.useEffect(() => {
    if (passwordStrength < 3) {
      setDisableReset(true);
    } else if (newPassword !== confirmPassword) {
      setDisableReset(true);
    } else if (!resetToken && !isAgentLoggedIn) {
      setDisableReset(true);
    } else if (!resetToken && !currentPassword) {
      setDisableReset(true);
    } else {
      setDisableReset(false);
    }
  }, [passwordStrength, currentPassword, newPassword, confirmPassword]);

  const handlePasswordReset = async () => {
    setIsResetInProgress(true);
    let apiData;
    if (resetToken) {
      apiData = await dispatch(
        resetPassword(resetToken, newPassword, confirmPassword),
      );
    } else {
      apiData = await dispatch(
        updatePassword(currentPassword, newPassword, confirmPassword),
      );
    }

    setIsResetInProgress(false);
    if (apiData) {
      setIsResetSuccess(true);
    }
  };

  return (
    <div className={maincss.contentSideBar}>
      {!isResetSuccess && (
        <div
          className={classify(maincss.containerContent, {
            [maincss.containerContentExt]: isExtension,
          })}
        >
          <div className={css.resetSection}>
            <>
              <TitleMedium> {CONSTANTS.RESET_PASSWORD_PAGE_TITLE} </TitleMedium>
              <div className={css.instructions}>
                <PasswordInstruction />
              </div>
              {!resetToken && (
                <Input
                  label={'Current password'}
                  placeholder={`Enter current password`}
                  size="medium"
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
              )}
              <div
                className={
                  resetToken
                    ? css.passwordConfirmationToken
                    : css.passwordConfirmationSignedIn
                }
              >
                <PasswordConfirmation
                  passwordLabel={'New password'}
                  passwordPlaceholder={'Enter password'}
                  confirmPasswordLabel={'Confirm password'}
                  confirmPasswordPlaceholder={'Repeat password'}
                  setPasswordStrengthValue={setPasswordStrength}
                  setNewPasswordValue={setNewPassword}
                  setConfirmPasswordValue={setConfirmPassword}
                />
              </div>
              <Button
                size="medium"
                type="primary"
                classNames={{wrapper: css.setNewBtn}}
                disabled={disableReset}
                onClick={handlePasswordReset}
              >
                {isResetInProgress
                  ? 'Setting new password'
                  : 'Set new password'}
              </Button>
              {!resetToken && !isAgentLoggedIn && (
                <InContextAlert
                  semantic="danger"
                  classNames={{wrapper: css.alert}}
                >
                  {CONSTANTS['RESET_PASSWORD_DISABLED_ALERT']}
                </InContextAlert>
              )}
              {!isResetSuccess && resetPasswordError && (
                <InContextAlert
                  semantic="danger"
                  classNames={{wrapper: css.alert}}
                >
                  {ERROR_CODES[resetPasswordError] || resetPasswordError}
                </InContextAlert>
              )}
            </>
          </div>
        </div>
      )}

      {isResetSuccess && (
        <div className={maincss.containerContentCentreAligned}>
          <ResetConfirmation />
        </div>
      )}
    </div>
  );
};

export default ResetPasswordContainer;
