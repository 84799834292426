import dsCss from 'designSystem2021';
import colors from 'src/styles/modules/colors.css';


export default [
  dsCss.colorClickable,
  colors.colorGreen,
  colors.colorOrange,
  colors.colorPurple,
  colors.colorLightBlue,
  colors.colorLightGreen,
  colors.colorBrown,
  colors.colorDeepPurple,
  colors.colorDarkGreen,
  colors.colorYellow,
  colors.colorRed,
  colors.colorSeaGreen,
];

export const metricsColors = {
  sent: dsCss.colorClickable,
  numSent: dsCss.colorClickable,
  delivered: colors.colorGreen,
  numDelivered: colors.colorGreen,
  opened: colors.colorOrange,
  numOpened: colors.colorOrange,
  clicked: colors.colorPurple,
  numClicked: colors.colorPurple,
  responded: colors.colorLightBlue,
  numResponded: colors.colorLightBlue,
  unsubscribed: colors.colorLightGreen,
  numUnsubscribed: colors.colorLightGreen,
  bounced: colors.colorBrown,
  numBounced: colors.colorBrown,
  markedAsSpam: colors.colorDeepPurple,
  numMarkedAsSpam: colors.colorDeepPurple,
  converted: colors.colorSeaGreen,
  lightGreen: colors.colorGreenLight,
  goldenYellow: colors.colorGoldenYellow,
  redText: colors.colorRed,
};
