// @flow

import type {
  // $FlowFixMe[untyped-type-import]
  EventTemplate,
  // $FlowFixMe[untyped-type-import]
  TemplateBrandingSettings,
} from 'src/api-parsers/events';
// $FlowFixMe[untyped-type-import]
import type {Agency} from 'src/api-parsers/index';
// $FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores/index';
import type {State as ReduxState} from 'src/reducers';

import * as React from 'react';
import {connect} from 'react-redux';
import flow from 'lodash/flow';
import map from 'lodash/map';
import partial from 'lodash/partial';
import omit from 'lodash/omit';
import sculpt from 'sculpt';

import logger from 'src/utils/logger';

import {fluxify} from 'src/flux/component.jsx';
import {uploadMedia} from 'src/actions';

import Switch from 'src/components/lib/switch';
import ImageInput from 'src/components/settings/image-input.jsx';
import ColorPicker from 'src/components/lib/color-picker/color-picker.jsx';

import css from './variables.css';

// TODO (kyle): this should probably go somewhere central
const socialNetworks = {
  twitter: 'Twitter',
  linkedin: 'Linkedin',
  facebook: 'Facebook',
  instagram: 'Instagram',
  youtube: 'Youtube',
  'google-plus': 'Google+',
};

const subscribeOptions = [
  {label: 'Show', value: true},
  {label: 'Hide', value: false},
];

type Props = {
  // $FlowFixMe[value-as-type] [v1.32.0]
  template: EventTemplate,
  // $FlowFixMe[value-as-type] [v1.32.0]
  agency: Agency,
  // $FlowFixMe[value-as-type] [v1.32.0]
  store: IndexStore,
  onChange: (?boolean) => mixed,
};

type State = {
  // $FlowFixMe[value-as-type] [v1.32.0]
  templateSettings: TemplateBrandingSettings,
  name: string,
};

const mapStateToProps = (state: ReduxState) => ({
  agency: state.agency.agency,
});

class TemplateVariables extends React.PureComponent<Props, State> {
  iframeElt: HTMLIFrameElement;

  constructor(props) {
    super(props);

    this.state = {
      templateSettings: props.template.templateSettings,
      name: props.template.name,
    };

    // $FlowFixMe[method-unbinding]
    (this: any).handleLogoChange = this.handleLogoChange.bind(this);
    // $FlowFixMe[method-unbinding]
    (this: any).handleLogoDelete = this.handleLogoDelete.bind(this);
    // $FlowFixMe[method-unbinding]
    (this: any).handleColorChange = this.handleColorChange.bind(this);
    // $FlowFixMe[method-unbinding]
    (this: any).handleLinkChange = this.handleLinkChange.bind(this);
    // $FlowFixMe[method-unbinding]
    (this: any).handleCheckChange = this.handleCheckChange.bind(this);
    // $FlowFixMe[method-unbinding]
    (this: any).handleInputChange = this.handleInputChange.bind(this);
    // $FlowFixMe[method-unbinding]
    (this: any).handleNameChange = this.handleNameChange.bind(this);
  }

  render() {
    const {template} = this.props;
    const {templateType, builtinType, sourceTemplateId} = template;
    const {
      name,
      templateSettings: {
        logo,
        logoLink,
        color,
        bgColor,
        boxColor,
        signature,
        links,
        footerText,
        showUnsubscribe,
        buttonColor,
      },
    } = this.state;
    const isCustom = templateType === 'custom';
    const isStandard = builtinType === 'standard';
    const isDefault = builtinType === 'default';
    const isOriginal = sourceTemplateId === null;

    return (
      <div className={css.rightColumn}>
        <div className={isCustom ? css.disabledColumn : css.settingsColumn}>
          {!isOriginal && (
            <div className={css.flexColumn}>
              <p className={css.topLabel}>Name</p>
              <input
                type="text"
                className={css.textInput}
                value={name}
                // $FlowFixMe[method-unbinding]
                onChange={this.handleNameChange}
              />
            </div>
          )}

          <p className={css.label}>Logo</p>
          <div className={css.logoInput}>
            <ImageInput
              src={logo}
              // $FlowFixMe[method-unbinding]
              onChange={this.handleLogoChange}
              // $FlowFixMe[method-unbinding]
              onDelete={this.handleLogoDelete}
            />
          </div>
          <p className={css.label}>Logo Link</p>
          <input
            type="text"
            placeholder="e.g. https://www.sensehq.com/"
            name="logoLink"
            className={css.textInput}
            value={logoLink}
            // $FlowFixMe[method-unbinding]
            onChange={this.handleInputChange}
          />

          <p className={css.label}>Brand Color</p>
          <ColorPicker
            name="color"
            defaultValue={color}
            // $FlowFixMe[method-unbinding]
            onChange={this.handleColorChange}
          />
          <p className={css.label}>Button Color</p>
          <ColorPicker
            name="buttonColor"
            defaultValue={buttonColor}
            // $FlowFixMe[method-unbinding]
            onChange={this.handleColorChange}
          />
          {isStandard && (
            <div className={css.flexColumn}>
              <p className={css.label}>Background Color</p>
              <ColorPicker
                name="bgColor"
                defaultValue={bgColor || '#f0f0f0'}
                // $FlowFixMe[method-unbinding]
                onChange={this.handleColorChange}
              />
              <p className={css.label}>Header/Footer Color</p>
              <ColorPicker
                name="boxColor"
                defaultValue={boxColor || color || '#000000'}
                // $FlowFixMe[method-unbinding]
                onChange={this.handleColorChange}
              />
              {map(socialNetworks, (name, value) => (
                <label className={css.linkLabel} key={value}>
                  <div className={css.label}>{name}</div>
                  <input
                    type="text"
                    className={css.textInput}
                    value={links[value] || ''}
                    // $FlowFixMe[method-unbinding]
                    onChange={partial(this.handleLinkChange, value)}
                  />
                </label>
              ))}

              {/*
              (nilarnab) Hiding this for now
              <div className={css.linkLabel}>
                <div className={css.label}>Unsubscribe Link</div>
                <Switch
                  className={css.unsubscribeSwitch}
                  name="showUnsubscribe"
                  options={subscribeOptions}
                  checked={showUnsubscribe}
                  onChange={this.handleCheckChange}
                />
              </div> */}

              <label className={css.linkLabel}>
                <div className={css.label}>Footer Text</div>
                <textarea
                  className={css.textarea}
                  name="footerText"
                  value={footerText}
                  // $FlowFixMe[method-unbinding]
                  onChange={this.handleInputChange}
                />
              </label>
            </div>
          )}

          {/*
          (nilarnab) Hiding this for now
          {isDefault && (
            <div className={css.flexColumn}>
              <div className={css.linkLabel}>
                <div className={css.label}>Unsubscribe Link</div>
                <Switch
                  className={css.unsubscribeSwitch}
                  name="showUnsubscribe"
                  options={subscribeOptions}
                  checked={showUnsubscribe}
                  onChange={this.handleCheckChange}
                />
              </div>
            </div>
          )} */}

          <p className={css.label}>Signature</p>
          <textarea
            placeholder="signature"
            className={css.textarea}
            name="signature"
            value={signature}
            // $FlowFixMe[method-unbinding]
            onChange={this.handleInputChange}
          />
        </div>
      </div>
    );
  }

  getTemplate() {
    return {...this.state};
  }

  handleLogoChange(event) {
    // $FlowIssue -- flow doesn't know about FileEvent target files
    uploadMedia(this.props.store, event.target.files[0]).then(
      (resp) => {
        this.setState(
          {
            templateSettings: {...this.state.templateSettings, logo: resp.url},
          },
          () => this.props.onChange(),
        );
      },
      (err) => {
        logger.error(err.stack || err.response.body);
      },
    );
  }

  handleLogoDelete() {
    const {logo, ...rest} = this.state.templateSettings;
    if (logo) {
      this.setState({templateSettings: rest}, () => this.props.onChange());
    }
  }

  handleColorChange(color: string, key: string = 'color') {
    let templateSettings;
    if (color) {
      templateSettings = {...this.state.templateSettings, [key]: color};
    } else {
      templateSettings = omit(this.state.templateSettings, key);
    }
    this.setState({templateSettings}, () => this.props.onChange());
  }

  handleLinkChange(type, event) {
    const {value} = event.target;
    const {templateSettings} = this.state;
    const spec = value.trim() ? {$assign: {[type]: value}} : {$unset: type};

    const links = sculpt(templateSettings.links, spec);

    // NOTE (kyle): eventually we'll allow for custom ordering
    const linkOrder = map(socialNetworks, (label, type) => type).filter(
      (type) => links[type],
    );

    this.setState(
      {
        templateSettings: {...templateSettings, links, linkOrder},
      },
      () => this.props.onChange(),
    );
  }

  handleCheckChange(event) {
    const {name, checked} = event.target;
    this.setState(
      {
        templateSettings: {
          ...this.state.templateSettings,
          [name]: checked,
        },
      },
      () => this.props.onChange(),
    );
  }

  handleInputChange(event) {
    const {templateSettings} = this.state;
    const {name, value} = event.target;
    const trimmedValue = value.trim();

    const spec = trimmedValue ? {$assign: {[name]: value}} : {$unset: name};
    const refresh = name !== 'logoLink';

    this.setState(
      {
        templateSettings: sculpt(templateSettings, spec),
      },
      () => this.props.onChange(refresh),
    );
  }

  handleNameChange(event) {
    this.setState(
      {
        name: event.target.value,
      },
      () => this.props.onChange(),
    );
  }
}

export default (flow(
  fluxify(),
  connect(mapStateToProps),
)(TemplateVariables): any);
