// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {EntityType} from 'src/types/ats-entities';
import type {
  AudienceRule,
  AudienceListStatus,
  AudienceListCreationType,
  AudienceListApiResponse,
  AudienceBlocksApi,
  AudienceListSource,
} from 'src/types/audience-list';

import type {AudienceListState} from 'src/reducers/audience-list';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';

import {batch} from 'react-redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';

import {
  receiveListUpdate,
  resetListPageState,
} from 'src/action-creators/audience-lists';
import {
  parseAudienceBlocks,
  normalizeAudienceBlocks,
  getTrackingProps,
} from 'src/utils/audience-list';

//$FlowFixMe[nonstrict-import]
import {AnalyticsService} from 'src/analytics';
import {ENGAGE} from 'src/analytics/constants_event.js';
//$FlowFixMe[nonstrict-import]
import {selectListV2Enabled} from 'src/hooks/product-flags';


export type ListFormSteps = 'details' | 'rules' | 'review' | 'copyLists';

export type UpdateStatePayload = $Shape<AudienceListState>;

export type UpdateListPayload = $Shape<{
  id: string,
  name: string,
  inclusions: Map<string, AudienceRule[]>,
  exclusions: Map<string, AudienceRule[]>,
  all: boolean,
  status: AudienceListStatus,
}>;
export type ReceiveListPayload = $Shape<{
  id: string,
  name: string,
  inclusions: Map<string, AudienceRule[]>,
  exclusions: Map<string, AudienceRule[]>,
  all: boolean,
  status: AudienceListStatus,
  source: AudienceListSource,
  entityType: EntityType,
}>;

export type AudienceBlockPayload = {
  inclusions: Map<string, AudienceRule[]>,
  exclusions: Map<string, AudienceRule[]>,
  all: boolean,
};

export const UPDATE_STATE = 'audience-list/update';
export const RECEIVE_LIST = 'audience-list/receive';
export const RECEIVE_UPDATED_LIST = 'audience-list/receive-updated';
export const RESET = 'audience-list/reset';
export const SET_CREATION_TYPE = 'audience-list/set-creation-type';
export const SET_RULES = 'audience-list/set-rules';
export const SET_ALL = 'audience-list/set-all';
export const RECEIVE_PUBLISHED_LIST_IDS =
  'audience-list/receive-published-list-ids';
//this action resets only the journey flow related fields which are there in the list
export const RESET_JOURNEY_FLOW_FIELDS =
  'audience-list/reset-journey-flow-fields';

//this action resets only the automation-workflow flow related fields which are there in the list
export const RESET_AUTOMATION_WORKFLOW_FIELDS =
  'audience-list/reset-automation-workflow-fields';
export const ADD_INCLUSION_BLOCK = 'audience-list/add-inclusion-block';
export const REMOVE_INCLUSION_BLOCK = 'audience-list/remove-inclusion-block';
export const CLONE_INCLUSION_BLOCK = 'audience-list/clone-inclusion-block';
export const SET_AUDIENCE_BLOCKS = 'audience-list/set-audience-blocks';

type ReceiveListAction = {
  type: 'audience-list/receive',
  payload: ReceiveListPayload,
};
type ReceiveUpdatedListAction = {
  type: 'audience-list/receive-updated',
  payload: UpdateListPayload,
};

type UpdateStateAction = {
  type: 'audience-list/update',
  payload: UpdateStatePayload,
};
type ResetStateAction = {
  type: 'audience-list/reset',
};
type SetCreationAction = {
  type: 'audience-list/set-creation-type',
  payload: ?AudienceListCreationType,
};
type SetRulesAction = {
  type: 'audience-list/set-rules',
  payload: {blockId: string, rules: AudienceRule[]},
};
type SetAllAction = {
  type: 'audience-list/set-all',
  payload: boolean,
};
type ReceivedPublishedListIdsAction = {
  type: 'audience-list/receive-published-list-ids',
  payload: string[],
};
type ResetJourneyFlowFields = {
  type: 'audience-list/reset-journey-flow-fields',
};
type ResetAutomationWorkflowFields = {
  type: 'audience-list/reset-automation-workflow-fields',
};
type AddInclusionBlockAction = {
  type: 'audience-list/add-inclusion-block',
};
type RemoveInclusionBlockAction = {
  type: 'audience-list/remove-inclusion-block',
  payload: string,
};
type CloneInclusionBlockAction = {
  type: 'audience-list/clone-inclusion-block',
  payload: string,
};
type SetAudienceBlocks = {
  type: 'audience-list/set-audience-blocks',
  payload: AudienceBlockPayload,
};
export type AudienceListAction =
  | ReceiveListAction
  | UpdateStateAction
  | ResetStateAction
  | SetCreationAction
  | SetRulesAction
  | SetAllAction
  | ReceivedPublishedListIdsAction
  | ReceiveUpdatedListAction
  | ResetJourneyFlowFields
  | ResetAutomationWorkflowFields
  | AddInclusionBlockAction
  | RemoveInclusionBlockAction
  | CloneInclusionBlockAction
  | SetAudienceBlocks;
export const doUpdateState = (
  payload: UpdateStatePayload,
): UpdateStateAction => ({
  type: UPDATE_STATE,
  payload,
});

export const setCreationType = (
  type: ?AudienceListCreationType,
): SetCreationAction => ({type: SET_CREATION_TYPE, payload: type});
export const setRules = (
  blockId: string,
  rules: AudienceRule[],
): SetRulesAction => ({
  type: SET_RULES,
  payload: {blockId, rules},
});
export const setAll = (all: boolean): SetAllAction => ({
  type: SET_ALL,
  payload: all,
});
export const reset = (): ResetStateAction => ({type: RESET});
export const updateState = (
  payload: UpdateStatePayload,
): UpdateStateAction => ({
  type: UPDATE_STATE,
  payload,
});
export const receivePublishedListIds = (
  payload: string[],
): ReceivedPublishedListIdsAction => ({
  type: RECEIVE_PUBLISHED_LIST_IDS,
  payload,
});
export const receiveUpdatedList = (
  payload: UpdateListPayload,
): ReceiveUpdatedListAction => ({
  type: RECEIVE_UPDATED_LIST,
  payload,
});
export const receiveList = (
  payload: ReceiveListPayload,
): ReceiveListAction => ({
  type: RECEIVE_LIST,
  payload,
});

export const resetJourneyFlowFields = (): ResetJourneyFlowFields => ({
  type: RESET_JOURNEY_FLOW_FIELDS,
});

export const resetAutomationWorkflowFields =
  (): ResetAutomationWorkflowFields => ({
    type: RESET_AUTOMATION_WORKFLOW_FIELDS,
  });
export const addInclusionBlock = (): AddInclusionBlockAction => ({
  type: ADD_INCLUSION_BLOCK,
});

export const setAudienceBlocks = (
  payload: AudienceBlockPayload,
): SetAudienceBlocks => ({
  type: SET_AUDIENCE_BLOCKS,
  payload,
});

export const removeInclusionBlock = (
  blockId: string,
): RemoveInclusionBlockAction => ({
  type: REMOVE_INCLUSION_BLOCK,
  payload: blockId,
});

export const cloneInclusionBlock = (
  blockId: string,
): CloneInclusionBlockAction => ({
  type: CLONE_INCLUSION_BLOCK,
  payload: blockId,
});

export const createOrUpdateAudienceList: (
  is_internal: boolean,
  source: AudienceListSource,
) => ThunkAction<AudienceListApiResponse> =
  (is_internal, source) => (dispatch, getState) => {
    const audienceList = getState().audienceList;
    const {id, name, entityType, inclusions, exclusions, all} = audienceList;
    const rule = normalizeAudienceBlocks({inclusions, exclusions, all});
    if (!id) {
      //create
      const apiPayload = {
        name: name.trim(),
        rule,
        entityType,
        internal: is_internal,
        source,
      };
      return dispatch(createList(apiPayload));
    } else {
      //update
      const apiPayload = {
        name: name.trim(),
        rule,
      };
      return dispatch(updateList(id, apiPayload));
    }
  };

export const createAndPublishAudienceList: (
  is_internal: boolean,
  source: AudienceListSource,
) => ThunkAction<string> = (internal, source) => async (dispatch, getState) => {
  const audienceList = getState().audienceList;
  const {name, entityType, inclusions, exclusions, all} = audienceList;
  const rule = normalizeAudienceBlocks({inclusions, exclusions, all});
  //create
  const apiPayload = {
    name: name.trim(),
    rule,
    entityType,
    internal,
    source,
  };
  return await dispatch(createAndPublishList(apiPayload));
};

export const getList: (id: string) => ThunkAction<mixed> = flow(
  key((id) => `listDetails-${id}`),
  fetching(),
)(
  (id) => (dispatch: Dispatch) =>
    dispatch(reduxApi.get(`filter/${id}`)).then((payload) => {
      const {id, name, rule, status, entityType, source} = payload;
      const {all, inclusions, exclusions} = parseAudienceBlocks(rule);
      const updatePayload = {
        id,
        name,
        all,
        inclusions,
        exclusions,
        status,
        entityType,
        source,
      };
      dispatch(receiveList(updatePayload));
    }),
);

export const createList: (payload: {
  name: string,
  rule: AudienceBlocksApi,
  entityType: string,
  internal: boolean,
  source: AudienceListSource,
}) => ThunkAction<AudienceListApiResponse> =
  (payload) => (dispatch, getState) =>
    dispatch(reduxApi.post(`filter`, payload)).then((payload) => {
      const audienceList = getState().audienceList;
      const {ruleCreationType} = audienceList;
      const {id, name, status, source} = payload;
      const {inclusions, exclusions, all} = parseAudienceBlocks(payload.rule);
      const listV2Enabled = selectListV2Enabled(getState());
      const listV2TrackingParams = listV2Enabled
        ? getTrackingProps(payload.rule)
        : {};
      AnalyticsService.track(ENGAGE.LIST_DRAFTED, {
        ...payload,
        rule_creation_method: ruleCreationType,
        list_id: id,
        ...listV2TrackingParams,
      });
      batch(() => {
        dispatch(receiveListUpdate({id, name, status}));
        dispatch(
          receiveUpdatedList({
            ...payload,
            inclusions,
            exclusions,
            all,
          }),
        );
        dispatch(resetListPageState(true));
      });
      return payload;
    });

export const createAndPublishList: (payload: {
  name: string,
  rule: AudienceBlocksApi,
  entityType: string,
  internal: boolean,
  source: AudienceListSource,
}) => ThunkAction<string> = (payload) => async (dispatch, getState) => {
  const audienceList = getState().audienceList;
  const {ruleCreationType} = audienceList;
  const list = await dispatch(reduxApi.post(`filter`, payload));
  const listV2Enabled = selectListV2Enabled(getState());
  const listV2TrackingParams = listV2Enabled
    ? getTrackingProps(payload.rule)
    : {};
  AnalyticsService.track(ENGAGE.LIST_CREATED, {
    ...payload,
    rule_creation_method: ruleCreationType,
    list_id: list.id,
    ...listV2TrackingParams,
  });
  await dispatch(reduxApi.patch('filter/publish', {ids: [list.id]}));
  dispatch(resetListPageState(true));
  return list.id;
};

export const updateList: (
  id: string,
  payload: {
    name: string,
    rule?: AudienceBlocksApi,
  },
) => ThunkAction<AudienceListApiResponse> =
  (id, payload) => (dispatch, getState) =>
    dispatch(reduxApi.patch(`filter/${id}`, {id, ...payload})).then(
      (payload) => {
        const {id, name, status} = payload;
        const {inclusions, exclusions, all} = parseAudienceBlocks(payload.rule);
        dispatch(receiveListUpdate({id, name, status}));
        dispatch(
          receiveUpdatedList({
            ...payload,
            inclusions,
            exclusions,
            all,
          }),
        );
        const listV2Enabled = selectListV2Enabled(getState());
        const listV2TrackingParams = listV2Enabled
          ? getTrackingProps(payload.rule)
          : {};
        AnalyticsService.track(ENGAGE.LIST_EDITED, {
          ...payload,
          list_id: id,
          ...listV2TrackingParams,
        });
        return payload;
      },
    );

export const getAudienceListMemberCount: (id: string) => ThunkAction<number> =
  flow(
    key((id) => `listCount-${id}`),
    fetching(),
  )((listId) => (dispatch) => dispatch(reduxApi.get(`filter/${listId}/count`)));
