// @noflow

import type {TemplateAction} from 'src/action-creators/templates';
import type {Template} from 'src/types/templates';

import {RECEIVE, REMOVE} from 'src/action-creators/templates';


export type State = {
  templates: Template[],
};

const initialState = {
  templates: [],
};

export default (state: State = initialState, action: TemplateAction): State => {
  let templates;
  switch (action.type) {
    case REMOVE:
      templates = state.templates.filter((tmpl) => tmpl.id !== action.payload);
      return {
        templates,
      };
    case RECEIVE:
      const receivedIds = action.payload.map((tmpl) => tmpl.id);
      const filteredTemplates = state.templates.filter(
        (tmpl) => !receivedIds.includes(tmpl.id),
      );
      templates = [...filteredTemplates, ...action.payload];
      return {...state, templates};
    default:
      return state;
  }
};
