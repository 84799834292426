// @flow
import type {State} from 'src/reducers';

import type {JobVariable, JobVariables} from './types';

import type {AtsEntity, EntityType} from 'src/types/ats-entities';

import * as React from 'react';
import {connect} from 'react-redux';
import memoize from 'memoize-one';

import clamp from 'lodash/clamp';
import isEmpty from 'lodash/isEmpty';
import escapeRegExp from 'lodash/escapeRegExp';
import {popModal as removeModal} from 'src/action-creators/modal';
import {getEntityTypeMappings} from 'src/selectors/ats-entities';

import SearchIcon from 'src/images/preview-icon.svg?noAttrs';
import CloseIcon from 'src/images/close-icon.svg';

import VariablePickerBaseList from './variable-picker.jsx';
import JobVariablePickerRowComponent from './job-variable-picker-modal-row.jsx';

import css from './variable-picker.css';


type ReduxProps = {
  entityTypes: Array<AtsEntity>,
  labels?: JobVariables,
};

type SelectCallback = (
  value: JobVariable,
  event?: SyntheticMouseEvent<> | KeyboardEvent,
) => void;

type ModalProps = ReduxProps & {
  insertResult: SelectCallback,
  baseEntityType: EntityType,
  onClose?: () => mixed,
  removeModal: () => void,
  query?: string,
};

type ModalState = {
  queryTerm: string,
  selectedIndex: number,
  selectedResult: ?JobVariable,
};

class VariablePickerModal extends React.Component<ModalProps, ModalState> {
  selectedElt: ?HTMLDivElement;
  searchInput: ?HTMLInputElement;
  listRef: {current: null | HTMLDivElement};

  filterEntityTypes = memoize(
    (labels: JobVariables) =>
      new Set<string>(labels.map((label) => label.name)),
  );

  state = {
    queryTerm: this.props.query ?? '',
    selectedIndex: -1, // default to nothing having focus
    selectedResult: null,
  };

  componentDidMount() {
    this.listRef = React.createRef();
    this.focus();
  }

  focus() {
    this.searchInput && this.searchInput.focus();
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
    return this.props.removeModal();
  };

  updateInputValue = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const queryTerm = event.currentTarget.value;
    // when updating text input, move focus to top element as you type
    this.setState({queryTerm, selectedIndex: 0});
  };

  handleKeyDown = (evt: KeyboardEvent) => {
    const key = evt.key;

    if (['ArrowUp', 'ArrowLeft'].includes(key)) {
      evt.preventDefault();
      this.moveListFocus('up');
    } else if (['ArrowDown', 'ArrowRight', 'Tab'].includes(key)) {
      evt.preventDefault();
      this.moveListFocus('down');
    } else if (key === 'Enter') {
      evt.preventDefault();
      let {selectedResult} = this.state;
      const {insertResult} = this.props;

      if (!selectedResult) {
        const [filteredLabels] = this.getFilteredLabels();
        selectedResult = filteredLabels[0];
      }

      if (selectedResult != null) {
        insertResult(selectedResult, evt);
        this.handleClose();
      }
    } else if (key === 'Escape') {
      this.handleClose();
    }
  };

  moveListFocus = (direction: 'up' | 'down') => {
    const {listRef} = this;
    const [filteredLabels] = this.getFilteredLabels();
    const delta = direction === 'up' ? -1 : 1;
    const selectedIndex = clamp(
      this.state.selectedIndex + delta,
      0,
      filteredLabels.length - 1,
    );

    const selectedResult = filteredLabels[selectedIndex];
    if (listRef?.current) {
      // $FlowFixMe
      listRef.current.scrollToItem(selectedIndex);
    }
    this.setState({selectedIndex, selectedResult});
  };

  handleSelectIndex = (selectedIndex: number) => {
    this.setState({selectedIndex});
  };

  handleClickResult = (
    result: JobVariable,
    evt: SyntheticMouseEvent<HTMLDivElement>,
  ) => {
    this.props.insertResult(result, evt);
    this.handleClose();
  };

  getFilteredLabels = (): [JobVariables] => {
    const {queryTerm} = this.state;
    const {labels = []} = this.props;
    let filteredLabels = labels;
    const filterStrings = [queryTerm].filter((f) => !isEmpty(f));

    filteredLabels = filterStrings?.length
      ? filteredLabels.filter((label) =>
          filterStrings.reduce((acc, string) => {
            const re = new RegExp(escapeRegExp(string), 'i');
            return acc && label.name.match(re);
          }, true),
        )
      : filteredLabels;
    const sortedLabels = filteredLabels;
    return [sortedLabels];
  };

  clearFilters = () => {
    this.setState({queryTerm: ''});
  };

  render() {
    const {baseEntityType} = this.props;
    const {queryTerm, selectedIndex} = this.state;
    const [filteredLabels] = this.getFilteredLabels();

    return (
      <div className={css.root}>
        <header className={css.topContainer}>
          <div
            className={css.search}
            onClick={() => this.searchInput && this.searchInput.focus()}
          >
            <SearchIcon className={css.searchIcon} />
            <input
              className={css.searchInput}
              value={queryTerm}
              onKeyDown={this.handleKeyDown}
              onChange={(event) => this.updateInputValue(event)}
              ref={(input) => (this.searchInput = input)}
              placeholder="Search Variables"
            />
          </div>
          <div className={css.closeContainer}>
            <div className={css.verticalLine} />
            <CloseIcon
              className={css.closeIcon}
              onClick={() => {
                this.handleClose();
              }}
            />
          </div>
        </header>
        <div className={css.tableListContainer}>
          <VariablePickerBaseList
            baseEntityType={baseEntityType}
            selectedIndex={selectedIndex}
            // $FlowFixMe
            onSelect={this.handleClickResult}
            labels={filteredLabels}
            listRef={this.listRef}
            RowComponent={JobVariablePickerRowComponent}
          />
        </div>
      </div>
    );
  }
}

const ConnectedVariablePickerModal: any = connect(
  (state: State) => ({
    entityTypes: getEntityTypeMappings(state),
  }),
  {removeModal},
)(VariablePickerModal);

export default ConnectedVariablePickerModal;
