// @flow strict

import type {SenseAiActions} from 'src/action-creators/sense-ai';

import {
  SET_SHOW_AI_PANEL,
  SET_SHOW_CUSTOM_COMPONENT,
  SET_PROMPT,
  SET_GENERATED_TEXT,
  ADD_TAG,
  REMOVE_TAG,
  RESET_SELECTED_TAGS,
  RECEIVE_ALL_TAGS,
  SET_EXTERNALLY_ACCESSIBLE_FIELDS,
  SET_ERROR,
} from 'src/action-creators/sense-ai';


export type Tag = {
  label: string,
  value: string,
};

export type ExternallyAccessibleFields = $Shape<{
  prompt: string,
  selectedTags: string[],
  generatedText: string,
  error: string,
  allTags: Tag[],
}>;

export type GenerateContentParams = {
  url: string,
  prompt: string,
  selectedTags: string[],
};

export type SenseAiState = {
  show: boolean,
  prompt: ?string,
  selectedTags: string[],
  generatedText: ?string,
  allTags: Tag[],
  error: ?string,
  showAiPanel?: boolean,
  showCustomComponent?: boolean,
};

const initialState: SenseAiState = {
  show: false,
  prompt: undefined,
  selectedTags: [],
  generatedText: undefined,
  allTags: [],
  error: null,
  showAiPanel: true,
  showCustomComponent: false,
};

export default (
  state: SenseAiState = initialState,
  action: SenseAiActions,
): SenseAiState => {
  switch (action.type) {
    case ADD_TAG:
      return {
        ...state,
        selectedTags: [...state.selectedTags, action.payload],
      };
    case REMOVE_TAG:
      return {
        ...state,
        selectedTags: state.selectedTags.filter(
          (tag) => tag !== action.payload,
        ),
      };
    case RESET_SELECTED_TAGS:
      return {
        ...state,
        selectedTags: [],
      };
    case SET_PROMPT:
      return {...state, prompt: action.payload};
    case SET_GENERATED_TEXT:
      return {...state, generatedText: action.payload};
    case SET_ERROR:
      return {...state, error: action.payload};
    case SET_SHOW_AI_PANEL:
      return {...state, showAiPanel: action.payload};
    case SET_SHOW_CUSTOM_COMPONENT:
      return {...state, showCustomComponent: action.payload};
    case RECEIVE_ALL_TAGS:
      return {...state, allTags: action.payload};
    case SET_EXTERNALLY_ACCESSIBLE_FIELDS:
      return {...state, ...action.payload};
    default:
      return state;
  }
};
