// @flow strict

import type {Site, SiteLandingPage} from 'src/types/page-site';
import type {Page} from 'src/types/landing-page';

import type {SitesActions} from 'src/action-creators/page-site';
import {PAGE_SITE_CONSTANTS} from 'src/action-creators/page-site';


const pageInitialState = {
  name: '',
  description: '',
  entity_type: 'candidate_lead',
  form_ids: [],
  url: '',
};

export type PageSiteState = {
  page_sites: Site[],
  site_detail: Site,
  page: ?SiteLandingPage,
};

const initialState = {
  page_sites: null,
  site_detail: null,
  page: pageInitialState,
};

export default (
  // $FlowFixMe[prop-missing]
  // $FlowFixMe[incompatible-type]
  state: PageSiteState = initialState,
  action: SitesActions,
): PageSiteState => {
  switch (action.type) {
    case PAGE_SITE_CONSTANTS.FETCH_SITES: {
      return {
        ...state,
        page_sites: action.payload,
      };
    }
    case PAGE_SITE_CONSTANTS.FETCH_SITE_DETAIL: {
      return {
        ...state,
        site_detail: action.payload,
      };
    }
    case PAGE_SITE_CONSTANTS.UPDATE_SITE_DETAIL:
      return {
        ...state,
        site_detail: {...state.site_detail, ...action.payload},
      };
    case PAGE_SITE_CONSTANTS.RESET_SITE_DETAIL: {
      return {
        ...state,
        site_detail: initialState.site_detail,
        page: initialState.page,
      };
    }
    case PAGE_SITE_CONSTANTS.UPDATE_SITE_PAGE:
      const updatePayload = action.payload;
      return {
        ...state,
        page: {
          ...state.page,
          ...updatePayload,
        },
      };
    default:
      return state;
  }
};
