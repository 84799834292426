// @flow

import type {ThunkAction} from 'src/reducers';
import type {RawQuery} from 'src/utils/redux-api';
import type {EngagementCohorts} from 'src/types/engagement-cohorts';

import {thunkify as flow} from 'src/utils/thunks';
import {camel} from 'src/utils';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api';
import {resolveAnalyticsQuery} from 'src/utils/analytics-query';


const {stringify} = JSON;

export const RECEIVE = 'engagementCohorts/receive';

export type EngagementCohortsAction = {
  type: 'engagementCohorts/receive',
  payload: {
    data: EngagementCohorts,
    splitBy: string,
  },
};

const receive = (data: EngagementCohorts, splitBy: string) => ({
  type: RECEIVE,
  payload: {
    data,
    splitBy,
  },
});

const querying = () => (func) => (...args) => {
  const lastIndex = args.length - 1;
  args[lastIndex] = resolveAnalyticsQuery(args[lastIndex], [
    'client',
    'event_types',
    'recipients',
    'sort',
  ]);
  return func(...args);
};

export const getEngagementCohorts: (
  splitBy: string,
  query: RawQuery,
) => ThunkAction<mixed> = flow(
  querying(),
  key(
    (splitBy: string, query: RawQuery) =>
      `engagementCohorts:${splitBy}:${stringify(query)}`,
  ),
  cached((json, splitBy: string) => receive(camel(json), splitBy), {
    hash: (splitBy: string, query: RawQuery) =>
      `engagementCohorts:${splitBy}:${stringify(query)}`,
  }),
  fetching(),
)((splitBy: string, query: RawQuery) =>
  reduxApi.post(`analytics/engagement/cohorts`, {
    ...query,
    split_by: {attributes: [splitBy]},
  }),
);
