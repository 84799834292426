// @flow strict-local

import type {BackgroundTaskState} from 'src/reducers/background-task';
import type {BackgroundTask} from 'src/action-creators/background-task';


export const getWorkflowActivationKey = (workflowId: string): string =>
  `workflow-${workflowId}-activation`;
export const getWorkflowFilterKey = (workflowId: string): string =>
  `workflow-${workflowId}-audience-filter-create`;
export const getGroupFilerKey = (groupId: string): string =>
  `group-${groupId}-audience-filter-create`;

// TODO (chris): these should be selectors
export const isInProgress = (backgroundTask: ?BackgroundTask): boolean =>
  Boolean(
    backgroundTask &&
      backgroundTask.status &&
      ['init', 'processing'].includes(backgroundTask.status.toLowerCase()),
  );
export const isCompleted = (backgroundTask: ?BackgroundTask): boolean =>
  Boolean(
    backgroundTask &&
      backgroundTask.status &&
      ['failure', 'cancelled', 'skipped', 'success'].includes(
        backgroundTask.status.toLowerCase(),
      ),
  );

export const getWorkflowActivationTask = (
  state: {backgroundTask: BackgroundTaskState, ...},
  workflowId: string,
): BackgroundTask =>
  state.backgroundTask.data[getWorkflowActivationKey(workflowId)];

export const getWorkflowFilterTask = (
  state: {backgroundTask: BackgroundTaskState, ...},
  workflowId: string,
): BackgroundTask =>
  state.backgroundTask.data[getWorkflowFilterKey(workflowId)];

export const getGroupFilterTask = (
  state: {backgroundTask: BackgroundTaskState, ...},
  groupId: string,
): BackgroundTask => state.backgroundTask.data[getGroupFilerKey(groupId)];
