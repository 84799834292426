// @flow strict

import * as React from 'react';
import {useHistory} from 'src/rerouter';
import {useEnvironmentContext} from 'src/hooks/useEnvironmentContext';
// $FlowFixMe[nonstrict-import]
import {useReleaseFlag} from 'src/hooks/product-flags';
import {useExtensionProductsList} from './hooks/useExtensionProductsList';

import {SideMenuLink} from '@spaced-out/ui-design-system/lib/components/SideMenuLink';

import SenseLogo from 'src/images/sense-new-logo.svg';
import invert from 'lodash/invert';
import SupportLinks from './support-links.jsx';
import {classify} from 'src/utils/classify';
import css from './extension-side-nav.css';


const routeToProduct = {
  messaging: '/messages',
  contacts: '/contacts',
  referral: '/referralv2?source=ext',
  senseai: '/sense-ai-panel?source=ext',
  meetings: '/meetings',
  jobs: '/jobs/all',
};

const ExtensionSideNav = (): React.Node => {
  const router = useHistory();
  const {isExtension} = useEnvironmentContext();
  const isMicroAppsEnabled = useReleaseFlag(
    'messagingEnableMicroappsInExtension',
  );

  // useExtensionProductsList checks permissions for products and provides list of product route names
  // ex: messaging, referrals, senseai which are part of routeToProduct
  const {productsList} = useExtensionProductsList();

  // we need this logic to redirect to a product with external clicks from ATS
  const pathName = router.location.pathname;
  const selectedProductKey =
    Object.keys(routeToProduct).find((route) =>
      pathName.includes(routeToProduct[route].split('?')[0]),
    ) ||
    (productsList.length > 0 && productsList[0]) ||
    '';

  const [selectedProduct, setSelectedProduct] =
    React.useState(selectedProductKey);

  React.useEffect(() => {
    setSelectedProduct(selectedProductKey);
  }, [selectedProductKey]);

  const handleOnChange = (e, selectedKey) => {
    setSelectedProduct(selectedKey);
    router.push(routeToProduct[selectedKey]);
  };
  // productList.length < 1, Messaging is always present, if there are only other products, show sidebar
  const hideExtensionSideNav =
    !isMicroAppsEnabled || !isExtension || productsList.length <= 1;

  return (
    <>
      {!hideExtensionSideNav ? (
        <div className={css.container}>
          <div className={css.senseLogo}>
            <SenseLogo />
          </div>
          <div className={css.sideNavLinks}>
            {productsList.map((product) => {
              return (
                <SideMenuLink
                  onChange={handleOnChange}
                  pageNameKey={product}
                  selectedValue={selectedProduct}
                />
              );
            })}
          </div>
          <SupportLinks />
        </div>
      ) : null}
    </>
  );
};

export default ExtensionSideNav;
