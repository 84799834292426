var React = require('react');

function ChevronLeft (props) {
    return React.createElement("svg",props,React.createElement("path",{"fillRule":"evenodd","clipRule":"evenodd","d":"M7.47 12.53a.75.75 0 010-1.06l6-6a.75.75 0 111.06 1.06L9.06 12l5.47 5.47a.75.75 0 11-1.06 1.06l-6-6z"}));
}

ChevronLeft.defaultProps = {"width":"24","height":"24","viewBox":"0 0 24 24"};

module.exports = ChevronLeft;

ChevronLeft.default = ChevronLeft;
