// @flow strict

import * as React from 'react';
import {type BaseHistory} from './history/base';
import {HistoryContext} from './history-context';
//$FlowFixMe[nonstrict-import]
import {browserHistory} from 'src/reroutes/browser-history';


export function RouterProvider({
  history,
  children,
}: {
  history: BaseHistory,
  children: React.Node,
}): React.Node {
  const [_, setState] = React.useState();

  React.useEffect(
    () =>
      history.addListener(() => {
        console.log('location change event');
        setState({});
      }),
    [history],
  );

  const contextValue = React.useMemo(
    () => ({history}),
    [history.location.href],
  );

  console.log('provider', history.location.href);

  return (
    <HistoryContext.Provider value={contextValue}>
      {children}
    </HistoryContext.Provider>
  );
}
