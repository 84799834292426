// @flow

// $FlowFixMe[untyped-type-import]
import type {Workflow} from 'src/api-parsers/index';
// $FlowFixMe[untyped-type-import]
import type {Event} from 'src/api-parsers/events';

// $FlowFixMe[value-as-type] [v1.32.0]
export function workflow(workflow: Workflow | string): string {
  const workflowId = typeof workflow === 'object' ? workflow.id : workflow;
  return `/journey/${workflowId}`;
}
// $FlowFixMe[value-as-type] [v1.32.0]
export function analyticsWorkflow(workflow: Workflow | string): string {
  const workflowId = typeof workflow === 'object' ? workflow.id : workflow;
  return `/analytics/journey/${workflowId}`;
}

export function event(
  // $FlowFixMe[value-as-type] [v1.32.0]
  workflowObject: Workflow | string,
  // $FlowFixMe[value-as-type] [v1.32.0]
  event: Event | string,
  section?: ?string,
): string {
  const eventId = typeof event === 'object' ? event.id : event;
  let path = `${workflow(workflowObject)}/touchpoint/${eventId}`;
  if (section) {
    path = `${path}/${section}`;
  }
  return path;
}
