// @flow

// $FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores/index';
import type {TextQuestion} from 'src/types/survey';
import type {DynamicLabels} from 'src/action-creators/dynamic-labels';

import * as React from 'react';
import _get from 'lodash/get';

import {values} from 'src/utils/object';

import {
  WritebackContext,
  UPDATE_QUESTION,
  UPDATE_QUESTION_TYPE,
  RECEIVE_QUESTION,
} from 'src/components/writeback/writeback-wrapper.jsx';
import {
  MultipleChoice,
  RatingScale,
  FreeResponse,
  CalendarDate,
} from 'src/components/workflow/event/content/module/editors/writeback-modules';
import DraftInput from 'src/components/lib/draft-input';
import SurveyModuleOptions from 'src/components/workflow/event/content/survey-module-options';
import Loading from 'src/components/lib/loading/loading.jsx';

import WarningIcon from 'src/images/invalid-number.svg';

import moduleCss from './module.css';


type Props = {
  // $FlowFixMe[value-as-type] [v1.32.0]
  fluxStore: IndexStore,
  error?: Object,
  // $FlowFixMe[value-as-type] [v1.32.0]
  moduleData: TextQuestion,
  conditionalAlerts?: boolean,
  dynamicLabels: DynamicLabels,
};

const questionComponents = {
  text_survey_question: FreeResponse,
  nps_survey_question: RatingScale,
  rating_scale_survey_question: RatingScale,
  multiple_choice_survey_question: MultipleChoice,
  calendar_date_survey_question: CalendarDate,
};

const FieldWritebackModule = ({
  fluxStore,
  error,
  moduleData,
  conditionalAlerts,
  dynamicLabels,
}: Props): React.Element<'div'> | React.Node => {
  const {state, dispatch} = React.useContext(WritebackContext);

  React.useEffect(() => {
    dispatch({
      type: RECEIVE_QUESTION,
      payload: moduleData,
    });
  }, []);

  const currentQuestionType = state.question?.selectedType;
  const currentQuestion = state.question?.[currentQuestionType];

  if (!currentQuestion || !state.attribute_meta_data) {
    return <Loading />;
  }

  // $FlowFixMe[prop-missing] types/surveys
  const QuestionComponent = questionComponents[currentQuestionType];

  const errorStrings = error?.question?.errors ?? [];

  return (
    <div className={moduleCss.generalModule_content}>
      <label
        className={moduleCss.generalModule_questionLabel}
        data-qa-id="module-question-input"
      >
        <div className={moduleCss.labelWithInlineSelector}>Question Text</div>
        <DraftInput
          className={moduleCss.generalModule_input}
          // $FlowFixMe[prop-missing] types/surveys
          value={currentQuestion.question}
          hasError={error?.question?.errors?.length}
          placeholder="What could we do to make your job better?"
          dynamicLabels={dynamicLabels}
          onChange={(value) => {
            // $FlowFixMe[incompatible-call] types/surveys
            dispatch({
              type: UPDATE_QUESTION,
              payload: {
                questionType: currentQuestionType,
                attrs: {
                  question: value,
                },
              },
            });
          }}
          showAddButton
          multiEntityEnabled
        />
      </label>
      {errorStrings.length > 0 && (
        <div className={moduleCss.errors}>
          {errorStrings.map((error) => (
            <div className={moduleCss.error}>{error}</div>
          ))}
        </div>
      )}

      <SurveyModuleOptions
        error={error}
        questionId={moduleData.id}
        fluxStore={fluxStore}
      />

      <QuestionComponent conditionalAlerts={conditionalAlerts} error={error} />

      {// $FlowFixMe[prop-missing] types/surveys
      currentQuestion?.writeback?.enabled && (
        <div className={moduleCss.multipleChoiceModule_writebackHint}>
          <WarningIcon className={moduleCss.multipleChoiceModule_hintIcon} />
          Responses will overwrite ATS values
        </div>
      )}
    </div>
  );
};

export default FieldWritebackModule;
