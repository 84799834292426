// @flow strict-local

import type {Account} from 'src/types/account';
import type {ReceiveAccountsAction} from 'src/action-creators/group-accounts';

import {RECEIVE} from 'src/action-creators/group-accounts';


export type GroupAccountsState = {
  data: Account[],
};

const initialState = {
  data: [],
};

export default (
  state: GroupAccountsState = initialState,
  action: ReceiveAccountsAction,
): GroupAccountsState => {
  switch (action.type) {
    case RECEIVE:
      return {...state, data: action.payload};
  }

  return state;
};
