// @noflow

import type {Router, RouteLocation} from 'src/types/router';
import type {AtsAnalyticsField} from 'src/types/ats-settings';

import * as React from 'react';

import {FilterCard} from './';
import {StringSelectList} from 'src/components/lib/token-list-input';
import TypeaheadSelectList from 'src/components/typeahead/select-list.jsx';
import startCase from 'lodash/startCase';

import css from './related-to-card.css';


type Props = {
  onSave: () => any,
  onCancel: () => any,
  client: AtsAnalyticsField,
  office: AtsAnalyticsField,
  recruiter: AtsAnalyticsField,
  accountManager: AtsAnalyticsField,
  brand: AtsAnalyticsField,
  selectedClients: string[],
  selectedOffices: string[],
  selectedRecruiters: string[],
  selectedAccountManagers: string[],
  selectedBrands: string[],
  history: Router,
  location: RouteLocation,
  onStringListChange: (key: string) => (values: string[]) => any,
};

export default class RelatedToCard extends React.Component<Props> {
  renderFilter(field: AtsAnalyticsField, selected: string[]) {
    const showFilter = shouldShowFilter(field);
    if (!showFilter) {
      return;
    }
    const sharedSelectListProps = {
      containerClassName: css.selectListContainer,
      inputClassName: css.input,
      onChange: this.props.onStringListChange(field.senseAttribute),
      values: selected,
    };
    return (
      <section className={css.section} key={field.analyticsField}>
        <h4 className={css.subhead}>{startCase(field.analyticsField)}</h4>
        {shouldShowSelect(field) && (
          <StringSelectList options={field.values} {...sharedSelectListProps} />
        )}
        {shouldShowTypeahead(field) && (
          <TypeaheadSelectList
            typeaheadName={field.senseAttribute}
            typeaheadType="attribute"
            {...sharedSelectListProps}
          />
        )}
      </section>
    );
  }

  render() {
    const {
      onSave,
      onCancel,
      client,
      office,
      recruiter,
      accountManager,
      brand,
      selectedClients,
      selectedOffices,
      selectedRecruiters,
      selectedAccountManagers,
      selectedBrands,
    } = this.props;

    return (
      <FilterCard header="Related To" onSave={onSave} onCancel={onCancel}>
        {[
          [client, selectedClients],
          [office, selectedOffices],
          [recruiter, selectedRecruiters],
          [accountManager, selectedAccountManagers],
          [brand, selectedBrands],
        ].map(field => this.renderFilter(...field))}
      </FilterCard>
    );
  }
}

const shouldShowSelect = (field: AtsAnalyticsField) =>
  Boolean(field && Array.isArray(field.values));
const shouldShowTypeahead = (field: AtsAnalyticsField) =>
  Boolean(field && field.values === null && field.error && field.message);
const shouldShowFilter = (field: AtsAnalyticsField) =>
  shouldShowSelect(field) || shouldShowTypeahead(field);
