// @noflow
import type {NewAccount} from 'src/api-parsers/index';
import typeof IndexStore from 'src/stores/index';

import flow from 'lodash/flow';
import invariant from 'invariant';
import urlParse from 'url-parse';
import * as sentry from 'sentry';

import {unregisterAllServiceWorkers} from 'src/utils/service-workers';
import logger from 'src/utils/logger';

import * as api from 'src/utils/api';
import * as reduxApi from 'src/utils/redux-api-v2';
import parsers from 'src/api-parsers/index';
import {authorized, suppressed} from 'src/utils/action';
import {camel} from 'src/utils/index';
import {setAuthed} from 'src/action-creators/accounts';
import {getCsrfToken} from 'src/action-creators/csrf';
import {AnalyticsService} from 'src/analytics';


export function pushRoute(store: IndexStore, nextLocation: string | object) {
  invariant(
    store.history,
    'pushRoute() cannot be called without a viable browserHistory. This is most likely because this code was not run in a browser context.',
  );
  store.history.push(nextLocation);
}

/* Account actions */
export function setAuthPath(store: IndexStore, authPath: string) {
  store.me.setState({
    authPath,
  });
}

export function setFlag(store: IndexStore, newFlags: {[key: string]: boolean}) {
  store.me.updateState({
    flags: {
      $assign: newFlags,
    },
  });
  localStorage.flags = JSON.stringify(store.me.state.flags);
}

export function initializeFlags(store: IndexStore) {
  const flags = JSON.parse(localStorage.flags || '{}');
  store.me.updateState({
    flags: {
      $assign: flags,
    },
  });
}

type Agent = {
  name: string,
};

export const updateMe = flow(
  authorized(),
  suppressed(),
)((store: IndexStore, agent: Agent): Promise<*> =>
  api.put(store, 'agents/me', agent).then((response) => {
    const agent = camel(response);
    store.me.setState({
      agent,
    });
    return agent;
  }),
);

export async function signIn(
  store: IndexStore,
  location: Location,
  creds: {email: string, password: string, agency?: string},
): Promise {
  store.me.setState({signingIn: true});

  try {
    const response = await api.post(store, 'auth', creds);

    await store.reduxStore.dispatch(getCsrfToken());

    const {agency} = parsers.parse.auth(response);
    const {host, protocol} = location;
    const baseDomain = host.substring(host.indexOf('.') + 1);
    const query = urlParse(location, true).query;
    const nextPath =
      store.me.state.postAuthPath ||
      store.reduxStore.getState().accounts.postAuthPath ||
      query.nextPathname ||
      '';
    const nextSearch = store.me.state.postAuthSearch || query.nextSearch || '';
    trackLogin('sign-in', {isSuccees: true});
    // NOTE (kyle): could probably safely push() the new path here
    window.location = `${protocol}//${agency.slug}.${baseDomain}${nextPath}${nextSearch}`;
  } catch (error) {
    trackLogin('sign-in', {isSuccees: false, error: JSON.stringify(error)});
    if (error?.error?.response?.status === 300) {
      return error.error;
    } else {
      store.me.setState({signingIn: false, signinError: error});
    }
  }
}

export async function signOut(store: IndexStore): Promise<void> {
  const {reduxStore} = store;

  unregisterAllServiceWorkers();
  const cookies = Object.fromEntries(
    window.document.cookie.split(';').map((cookie) => cookie.trim().split('=')),
  );
  const fcm_android = cookies?.fcm_android;
  const {fcmToken, bindingType} = fcm_android
    ? {fcmToken: fcm_android, bindingType: 'fcm_android'}
    : {fcmToken: reduxStore.getState().messages.fcmToken, bindingType: 'fcm'};
  if (fcmToken) {
    try {
      // Note(aditya): this api call resets the auth cookies so we must wait for it to resolve before calling signout.
      // if done without await it will allow users to login without reentering password
      await reduxStore.dispatch(
        reduxApi.del(
          `messages_v2/push-notification/registration/${bindingType}/${fcmToken}`,
        ),
      );
    } catch (error) {
      logger.error(error);
    }

    try {
      // Note(aditya): this api call resets the auth cookies so we must wait for it to resolve before calling signout.
      // if done without await it will allow users to login without reentering password
      await reduxStore.dispatch(
        reduxApi.del(
          `messaging-service/push-notification/registration/chrome/${fcmToken}`,
        ),
      );
    } catch (error) {
      logger.error(error);
    }
  }

  trackLogin('sign-out', {isSuccees: true});
  store.me.setState({signingOut: true});
  store.reduxStore.dispatch(setAuthed(false));

  try {
    await api.del(store, 'auth');

    store.me.setState({signingOut: false});
    store.me.clearAuth();

    store.history.replace('/signin');
    sentry.configureScope((scope) => scope.clear());
  } catch (error) {
    logger.error('index signOut error: ', error.stack || error);
    store.me.setState({signingOut: false});
    store.reduxStore.dispatch(setAuthed(true));
  }
}

export function createAccount(
  store: IndexStore,
  location: Location,
  account: NewAccount,
): Promise {
  store.me.startCreating();

  account = parsers.normalize.account(account);

  return api
    .post(store, 'accounts', account)
    .then((response) => {
      logger.log('account created', response);

      response = parsers.parse.createAccount(response);

      const {agency} = response;
      const {host, protocol} = location;
      const baseDomain = host.substring(host.indexOf('.') + 1);
      window.location = `${protocol}//${agency.slug}.${baseDomain}`;
    })
    .catch((error) => {
      logger.error('index createAccount error: ', error.stack || error);
      store.me.setState({creating: false, signupError: error});
    });
}

export function updatePassword(
  store: IndexStore,
  password: string,
  new_password: string,
): Promise<*> {
  return api.post(store, 'accounts/me/password', {
    password,
    new_password,
    new_password_copy: new_password,
  });
}

export function forgotPassword(store: IndexStore, email: string): Promise<*> {
  return api.post(store, 'accounts/me/forgot-password', {email});
}

export function resetPassword(
  store: IndexStore,
  reset_id: string,
  new_password: string,
): Promise<*> {
  return api.post(store, 'accounts/me/reset-password', {
    reset_id,
    new_password,
    new_password_copy: new_password,
  });
}

export function authenticateBullhorn(
  store: IndexStore,
  ats_name: string,
  ats_corporation_id: string,
  ats_user_id: string,
  ats_auth_token: string,
): Promise<*> {
  return api.post(store, 'auth/ats/bullhorn', {
    ats_name,
    corporation_id: ats_corporation_id,
    user_id: ats_user_id,
    ats_auth_token,
  });
}

export function atsSendEmail(
  store: IndexStore,
  creds: {corporationId: string, userId: string, atsName: string},
): Promise<*> {
  return api
    .post(
      store,
      'auth/ats/bullhorn/send-email',
      {
        ats_name: creds.atsName,
        corporation_id: creds.corporationId,
        user_id: creds.userId,
      },
      undefined,
      {handleAuth: false},
    )
    .then((response) => {
      store.atsAuth.setState({emailSentTo: response.email});
    })
    .catch(() => {
      store.atsAuth.setState({authEmailSent: undefined});
    });
}

//This method just returns a status 200 if user is found, other wise a 400.
export function atsAccountExists(
  store: IndexStore,
  creds: {corporationId: string, userId: string, atsName: string},
): Promise<*> {
  return api
    .get(
      store,
      'auth/ats/bullhorn/account-exists',
      {
        ats_name: creds.atsName,
        corporation_id: creds.corporationId,
        user_id: creds.userId,
      },
      {
        handleAuth: false,
      },
    )
    .then(() => {
      store.atsAuth.setState({userExists: true});
      return true;
    })
    .catch(() => {
      store.atsAuth.setState({userExists: false});
      return false;
    });
}

export function uploadMedia(store: IndexStore, file: any): Promise {
  const formData = new FormData();
  formData.append('upload', file);
  return api
    .post(store, 'media/upload', formData)
    .then((response) => response)
    .catch((error) => {
      logger.error('index uploadMedia error:', error.stack || error);
      throw error;
    });
}

// ********** TRACK AUTH ************

function trackLogin(event, props) {
  try {
    AnalyticsService.track(event, props);
  } catch (error) {
    console.log('event tracking error ' + error);
  }
}
