// @flow strict

import * as React from 'react';
// $FlowFixMe[untyped-import] -- this should be fixed soon
import {useDropzone} from 'react-dropzone';
import clamp from 'lodash/clamp';

import {classify} from 'src/utils/classify';

import FileWithPlusIcon from 'src/images/designSystems2021/file-with-plus.svg';
import ClearFileIcon from 'src/images/designSystems2021/times-multiply.svg';

import {Button} from './buttons.jsx';

import css from './file-upload.css';


function fileUploadInitialState() {
  return {
    stage: 'unselected',
    file: null,
  };
}

function fileUploadReducer(state, action) {
  switch (action.type) {
    case 'startUpload':
      return {
        stage: 'uploading',
        file: action.file,
        progress: 0,
      };

    case 'progress':
      return {
        stage: 'uploading',
        file: state.file,
        progress: action.progress,
      };

    case 'success':
      if (state.stage === 'uploading') {
        return {
          stage: 'success',
          file: state.file,
        };
      } else {
        return state;
      }

    case 'error':
      const nextState = {
        stage: 'error',
        file: action.file || state.file,
        message: action.message,
      };

      return nextState;

    case 'clear':
      return fileUploadInitialState();

    default:
      return state;
  }
}

export type FileUploadProps = {
  disabled?: boolean,
  label: string,
  alternateLabel?: string,
  instruction?: string,
  error?: boolean,
  draggingInstruction?: string,
  secondaryInstruction?: string,
  maxSize?: number,
  accept?: string[],
  onFileSelect?: (file: File, callbacks: FileUploadCallbacks) => void,
};

type FileUploadCallbacks = {
  onProgress: (number) => void,
  onSuccess: () => void,
  onError: (string) => void,
};

const DROPZONE_ERROR_MESSAGES = {
  'file-too-large': 'File exceeds maximum size',
  'file-invalid-type': 'Wrong file type',
};

export function FileUpload(props: FileUploadProps): React.Node {
  const {
    label = '',
    alternateLabel = '',
    disabled = false,
    instruction = 'Drag & drop the file here',
    draggingInstruction = 'Drop the file here to start uploading..',
    disabledInstruction = 'File upload is currently unavailable',
    error = false,
    secondaryInstruction = ' ',
    maxSize,
    accept,
    onFileSelect = (file, callbacks) => {},
  } = props;

  const [uploadState, dispatch] = React.useReducer(
    fileUploadReducer,
    fileUploadInitialState(),
  );

  const handleStartUpload = (file) => {
    dispatch({type: 'startUpload', file});
  };

  const handleUploadProgress = (progress) => {
    dispatch({type: 'progress', progress});
  };

  const handleUploadSuccess = () => {
    dispatch({type: 'success'});
  };

  const handleUploadError = (message, file) => {
    dispatch({type: 'error', message, file});
  };

  const handleClearUpload = () => {
    dispatch({type: 'clear'});
  };

  const handleDropAccepted = (files) => {
    const [file] = files;
    handleStartUpload(file);

    onFileSelect(file, {
      onProgress: handleUploadProgress,
      onSuccess: handleUploadSuccess,
      onError: handleUploadError,
    });
  };

  const handleDropRejected = (fileRejections) => {
    console.log('rejected', fileRejections);
    const {errors, file} = fileRejections[0];

    const error = errors[0];

    const message = DROPZONE_ERROR_MESSAGES[error.code];

    handleUploadError(message, file);
  };

  const shouldAcceptFiles = !disabled && uploadState.stage === 'unselected';

  const {isDragActive, getRootProps, getInputProps} = useDropzone({
    maxFiles: 1,
    multiple: false,
    maxSize,
    accept,
    noClick: !shouldAcceptFiles,
    noDrag: !shouldAcceptFiles,
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejected,
  });

  const hasFileHover =
    !disabled && uploadState.stage === 'unselected' && isDragActive;
  const hasFileSelected = !disabled && !!uploadState.file;
  const hasError = !disabled && (error || uploadState.stage === 'error');

  let altLabelText = alternateLabel;
  if (disabled) {
    altLabelText = 'Locked';
  } else if (hasError) {
    altLabelText = altLabelText ?? 'Please try again';
  }

  const labelClassName = classify(css.label, {
    [css.labelError]: hasError,
    [css.labelDisabled]: disabled,
  });

  const altLabelClassName = classify(css.alternateLabel, {
    [css.labelError]: hasError,
  });

  const containerClassName = classify(css.container, {
    [css.fileHover]: hasFileHover,
    [css.fileSelected]: hasFileSelected,
    [css.containerError]: hasError,
    [css.containerDisabled]: disabled,
    [css.acceptingFiles]: shouldAcceptFiles,
  });

  const primaryInstruction = hasFileHover ? draggingInstruction : instruction;

  const primaryContent = uploadState.file?.name
    ? uploadState.file.name
    : primaryInstruction;

  let secondaryContent;
  if (uploadState.stage === 'uploading') {
    secondaryContent = <ProgressBar progress={uploadState.progress} />;
  } else {
    let secondaryText = secondaryInstruction;

    if (uploadState.stage === 'error') {
      secondaryText = `Upload Failed: ${uploadState.message}`;
    } else if (uploadState.stage === 'success') {
      secondaryText = 'Success!';
    }

    secondaryContent = (
      <div className={css.secondaryInstruction}>{secondaryText}</div>
    );
  }

  return (
    <div className={css.wrapper}>
      <div className={css.labelRow}>
        <div className={labelClassName}>{label}</div>
        {altLabelText && (
          <div className={altLabelClassName}>{altLabelText}</div>
        )}
      </div>
      <div {...getRootProps({className: containerClassName})}>
        <input {...getInputProps()} />

        <div className={css.content}>
          <div className={css.iconContainer}>
            <FileWithPlusIcon className={css.icon} />
          </div>

          <div className={css.innerContainer}>
            <div className={css.primaryText}>{primaryContent}</div>
            {secondaryContent}
          </div>

          <div>
            {hasFileSelected && (
              <Button
                isError
                type="tertiary"
                icon={<ClearFileIcon className={css.clearFileIcon} />}
                onClick={handleClearUpload}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

type ProgressBarProps = {
  progress: number,
  normalized?: boolean,
};

function ProgressBar(props: ProgressBarProps) {
  const {progress, normalized = false} = props;

  const progressPercent = normalized
    ? clamp(progress, 0, 1) * 100
    : clamp(progress, 0, 100);

  const progressStyle = {width: `${progressPercent}%`};

  return (
    <div className={css.progressContainer}>
      <div
        className={css.progressBar}
        role="progressbar"
        aria-valuenow={progressPercent}
        aria-valuemin="0"
        aria-valuemax="100"
        style={progressStyle}
      />
    </div>
  );
}
