// @flow strict
import type {MenuOption} from '@spaced-out/ui-design-system/lib/components/Menu/Menu';
import {
  parsePhoneNumberFromString,
  PhoneNumber,
  getCountries,
  getCountryCallingCode,
} from 'libphonenumber-js'; // eslint-disable-line import/no-unresolved
// full.json is replaced by max in libphonenumber-js library, so if we want to use any functionality with complete metadata, we should import it from /max

// eslint-disable-next-line import/no-unresolved
import {isValidPhoneNumber} from 'libphonenumber-js/max';


const defaultCountry = 'US';

export const parsePhone = (
  number: string,
  country: string = defaultCountry,
  // $FlowFixMe[value-as-type]
): ?PhoneNumber => parsePhoneNumberFromString(number, country);
export const validatePhone = (
  number: ?string,
  country: string = defaultCountry,
): boolean => {
  return number === undefined || number === null
    ? false
    : isValidPhoneNumber(number) || isValidPhoneNumber(number, country);
};
// https://github.com/catamphetamine/libphonenumber-js#formatparsed_number-format
// TODO (kyle): should this throw?
export const formatPhone = (
  number: string,
  country: string = defaultCountry,
): ?string => {
  const phone = parsePhone(number, country);
  if (phone) {
    if (phone.country === country) {
      return phone.formatNational();
    } else if (phone.country) {
      return phone.formatInternational();
    }
  }
  return number;
};

// E.164 phone number format
export const normalizePhone = (
  // $FlowFixMe[value-as-type]
  number: string | PhoneNumber,
  country?: string,
): ?string => {
  const parsedNumber =
    typeof number === 'string' ? parsePhone(number, country) : number;
  if (parsedNumber && parsedNumber.number && parsedNumber.isValid()) {
    return parsedNumber.format('E.164');
  }
  const pattern = /^[0-9]{5,6}$/;
  if (typeof number === 'string') {
    if (number && number.match(pattern)) {
      return number;
    }
  }
};

// NOTE(gab): 'mixed' type allows this functionally to safely take any inputs;
// if inputs are bad, function returns false
export function phoneEqual(
  aPhone: mixed,
  bPhone: mixed,
  country?: string,
): boolean {
  return (
    typeof aPhone === 'string' &&
    typeof bPhone === 'string' &&
    normalizePhone(aPhone, country) === normalizePhone(bPhone, country)
  );
}

export const getCountryFromPhone = (phoneNumber: ?string): ?string => {
  if (phoneNumber != null) {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber);
    if (parsedNumber && parsedNumber.country) {
      return parsedNumber.country;
    }
  }
};

// NOTE (kyle): this aggressively eliminates short numbers.
// mostly for scraping in countries with lax numerical rules.
export function isNotShortPhone(
  // $FlowFixMe[value-as-type]
  phoneNumber: string | PhoneNumber,
  country?: string,
): boolean {
  const parsedPhone =
    typeof phoneNumber === 'string'
      ? parsePhone(phoneNumber, country)
      : phoneNumber;
  return Boolean(
    parsedPhone &&
      parsedPhone.nationalNumber &&
      parsedPhone.nationalNumber.length > 7,
  );
}

// NOTE (kyle): this aggressively eliminates short numbers.
// mostly for scraping in countries with lax numerical rules.
export function normalizeLongPhone(
  // $FlowFixMe[value-as-type]
  number: string | PhoneNumber,
  country?: string,
): ?string {
  const parsedNumber =
    typeof number === 'string' ? parsePhone(number, country) : number;
  if (parsedNumber && isNotShortPhone(parsedNumber)) {
    return normalizePhone(parsedNumber);
  }
}

export function getCountryCodes(): Array<MenuOption> {
  //$FlowFixMe[prop-missing]
  const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
  const countries = getCountries();
  const countryCodes = countries.map((country) => ({
    code: getCountryCallingCode(country),
    name: regionNames.of(country),
  }));
  const COUNTRY_CODES: MenuOption[] = countryCodes
    .filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.code === item.code),
    )
    .map((country) => {
      return {
        label: `${country.name} +${country.code}`,
        key: `+${country.code}`,
      };
    });
  return COUNTRY_CODES;
}
