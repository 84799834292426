// @noflow

import type {Action} from 'src/action-creators/draft-messages';
import type {SuggestionType} from 'src/types/contacts';
import type {
  MessagingDraft,
  SetOfDiscoverCandidateDetails,
} from 'src/types/draft-messages';
import type {
  BroadcastSuppressionPhoneDetails,
  TimeFrame,
} from 'src/components/messaging/broadcast-suppression/types';

import omit from 'lodash/omit';
import uniqueId from 'lodash/uniqueId';

import {
  INPUT_PHONE,
  SET_MULTIPLE_RECIPIENTS,
  SET_DISCOVER_ORIGIN_AND_METADATA,
  SET_DISCOVER_IFRAME_CONTEXT,
  CHANGE_DRAFT,
  CLEAR_DRAFT,
  ADD_SCHEDULE,
  CLEAR_NEW_MESSAGE,
  SET_SCHEDULE_TYPE,
  UPDATE_SCHEDULED_DRAFT,
  SET_SCHEDULED_DRAFT,
  REMOVE_FILE_ERROR,
  REMOVE_FILE,
  CLEAR_FILES,
  SET_PHONE_TYPES,
  CLEAR_PHONE_TYPES,
  SET_RESTORED_PHONE_NUMBERS,
  REMOVE_NUMBER_FROM_RESTORED_PHONE_NUMBERS,
  SET_BROADCAST_SUPPRESSION_TIMEFRAME,
  CLEAR_BROADCAST_SUPPRESSION_TIMEFRAME,
  CLEAR_RESTORED_PHONE_NUMBERS,
  SET_RESTORED_LANDLINE_NUMBERS,
} from 'src/action-creators/draft-messages';


export type State = {
  // what if instead we always just have these here
  // there is only ever one "new" id instead of having to
  // rebuild the broadcast from all these parts
  drafts: {[phone: string]: MessagingDraft},
  recipients: Array<SuggestionType | string>,
  inputPhone: ?string,
  nextDraftId: string,
  phoneTypes: {[key: string]: BroadcastSuppressionPhoneDetails},
  restoredPhoneNumbers: string[],
  restoredLandlineNumbers: string[],
  timeFrame: TimeFrame,
  candidateDetails: SetOfDiscoverCandidateDetails,
  isDiscoverIframeContext: boolean,
};

export default (
  state: State = {
    // pseudo-broadcasts keyed by phone number/'new'
    drafts: {},
    // for broadcasting when there's more than one recipient
    recipients: [],
    // whatever is shown in the "To:" input box
    inputPhone: null,
    // the id of the next draft (note that uniqueId is only called when the reducer is created).
    nextDraftId: uniqueId(),
    phoneTypes: {},
    restoredPhoneNumbers: [],
    restoredLandlineNumbers: [],
    timeFrame: {},
    origin: 'messaging',
    candidateDetails: {},
    isDiscoverIframeContext: false,
  },
  action: Action,
): State => {
  switch (action.type) {
    case SET_PHONE_TYPES:
      const phoneTypes = {};
      action.payload.value.map((phoneInfo) => {
        phoneTypes[phoneInfo['phoneNumber']] = phoneInfo;
      });
      const phoneNumbers = Object.keys(phoneTypes);
      return {
        ...state,
        phoneTypes,
        restoredPhoneNumbers: [
          ...state.restoredPhoneNumbers.filter((restoredPhoneNumber) =>
            phoneNumbers.includes(restoredPhoneNumber),
          ),
        ],
        restoredLandlineNumbers: [
          ...state.restoredLandlineNumbers.filter((restoredPhoneNumber) =>
            phoneNumbers.includes(restoredPhoneNumber),
          ),
        ],
      };
    case CLEAR_PHONE_TYPES:
      return {
        ...state,
        phoneTypes: {},
      };
    case SET_RESTORED_PHONE_NUMBERS:
      return {
        ...state,
        restoredPhoneNumbers: [
          ...state.restoredPhoneNumbers,
          ...action.payload.value,
        ],
      };
    case SET_RESTORED_LANDLINE_NUMBERS:
      return {
        ...state,
        restoredLandlineNumbers: [
          ...state.restoredLandlineNumbers,
          ...action.payload.value,
        ],
      };
    case CLEAR_RESTORED_PHONE_NUMBERS:
      return {
        ...state,
        restoredPhoneNumbers: [],
        restoredLandlineNumbers: [],
      };
    case REMOVE_NUMBER_FROM_RESTORED_PHONE_NUMBERS:
      const restoredPhoneNumbers = state.restoredPhoneNumbers.filter(
        (phone) => !action.payload.value.includes(phone),
      );
      const restoredLandlineNumbers = state.restoredLandlineNumbers.filter(
        (phone) => !action.payload.value.includes(phone),
      );
      return {
        ...state,
        restoredPhoneNumbers,
        restoredLandlineNumbers,
      };
    case SET_BROADCAST_SUPPRESSION_TIMEFRAME:
      return {
        ...state,
        timeFrame: action.payload.value,
      };
    case CLEAR_BROADCAST_SUPPRESSION_TIMEFRAME:
      return {
        ...state,
        timeFrame: {},
      };
    case INPUT_PHONE: {
      let inputPhone = action.payload;

      if (inputPhone === undefined) {
        inputPhone = state.inputPhone || '';
      }

      return {
        ...state,
        inputPhone,
      };
    }

    case SET_MULTIPLE_RECIPIENTS: {
      return {
        ...state,
        recipients: action.payload ? action.payload : [],
      };
    }

    case SET_DISCOVER_ORIGIN_AND_METADATA: {
      return {
        ...state,
        origin: action.payload.origin,
        candidateDetails: action.payload.candidateDetails,
      };
    }
    case SET_DISCOVER_IFRAME_CONTEXT: {
      return {
        ...state,
        isDiscoverIframeContext: action.payload,
      };
    }

    case CHANGE_DRAFT: {
      const {to, body} = action.payload;
      return reduceDraftUpdate(state, to, {body});
    }

    case ADD_SCHEDULE: {
      return reduceDraftUpdate(state, action.meta.toPhone, {
        sendDate: action.payload,
      });
    }

    case SET_SCHEDULE_TYPE: {
      const {toPhone, scheduleType, sendDate} = action.payload;
      return reduceDraftUpdate(state, toPhone, {
        sends: scheduleType,
        sendDate,
      });
    }

    case UPDATE_SCHEDULED_DRAFT: {
      const {draftKey, draft} = action.payload;
      return reduceDraftUpdate(state, draftKey, draft);
    }
    case SET_SCHEDULED_DRAFT: {
      return {
        ...state,
        drafts: {
          ...state.drafts,
          [action.payload.draftKey]: {
            ...action.payload.draft,
            id: state.nextDraftId,
          },
        },
        nextDraftId: uniqueId(),
      };
    }

    case CLEAR_DRAFT: {
      return {
        ...state,
        drafts: omit(state.drafts, action.payload),
        inputPhone: null,
      };
    }

    case CLEAR_NEW_MESSAGE: {
      return {
        ...state,
        inputPhone: '',
        recipients: [],
        drafts: omit(state.drafts, 'new'),
      };
    }

    case REMOVE_FILE_ERROR: {
      const {draftKey, error} = action.payload;

      const prevErrors = state.drafts[draftKey]?.fileErrors || [];

      const nextErrors = prevErrors.filter((err) => err !== error);

      return reduceDraftUpdate(state, draftKey, {fileErrors: nextErrors});
    }

    case REMOVE_FILE: {
      const {draftKey, file} = action.payload;

      const prevFiles = state.drafts[draftKey]?.files || [];

      const nextFiles = prevFiles.filter((f) => f !== file);

      return reduceDraftUpdate(state, draftKey, {files: nextFiles});
    }

    case CLEAR_FILES: {
      const {draftKey} = action.payload;

      return reduceDraftUpdate(state, draftKey, {files: [], fileErrors: []});
    }
  }

  return state;
};

/**
 * if a draft with the key does not exist,
 * it will create a new one with a unique id.
 */
function reduceDraftUpdate(
  state: State,
  draftKey: string,
  draftUpdate: $Shape<MessagingDraft>,
): State {
  let draft = state.drafts[draftKey];

  if (!draft) {
    draft = {id: state.nextDraftId};
    state = {
      ...state,
      nextDraftId: uniqueId(),
    };
  }

  draft = {
    ...draft,
    ...draftUpdate,
  };

  return {
    ...state,
    drafts: {
      ...state.drafts,
      [draftKey]: draft,
    },
  };
}
