// @flow

import type {Dispatch, GetState, ThunkAction} from 'src/reducers';
import type {PhoneNumberSet} from 'src/types/chat/phone-number-sets';
import type {AutoReply, AutoReplyUpdatePayload} from 'src/types/autoreply';
import {INBOX_TYPE} from 'src/types/messages';
import {selectCurrentQueue} from 'src/selectors/messaging-queues';
import {selectLATEnabled} from 'src/hooks/product-flags';
import {getSelectedInboxType} from 'src/selectors/multi-number-inbox';
import {updateQueueBasedInboxes} from 'src/action-creators/chat/queues';

import {thunkify} from 'src/utils/thunks';
import {key, cached} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api-v2';


type ReceiveAction = {
  type: 'chat/phone-number-sets/receive',
  payload: PhoneNumberSet[],
};

type ReceiveAutoReplyAction = {
  type: 'chat/phone-number-sets/receiveAutoReply',
  payload: AutoReply,
};

type ReceiveAutoReplySettingsUpdatesAction = {
  type: 'chat/phone-number-sets/receiveAutoReplySettingsUpdates',
  payload: {
    phoneNumber: string,
    autoReply: AutoReplyUpdatePayload,
  },
};

type UpdateMsgChannelTypeAction = {
  type: 'chat/phone-number-sets/updateMsgChannelType',
  payload: {phoneNumberSetId: string, selected_channel: string},
};

type RemovePhoneNumberAction = {
  type: 'chat/phone-number-sets/removePhoneNumber',
  payload: {phoneNumberSetId: string},
};
export type Action =
  | ReceiveAction
  | ReceiveAutoReplyAction
  | ReceiveAutoReplySettingsUpdatesAction
  | RemovePhoneNumberAction;

export const RECEIVE = 'chat/phone-number-sets/receive';
export const RECEIVE_AUTO_REPLY = 'chat/phone-number-sets/receiveAutoReply';
export const RECEIVE_AUTO_REPLY_SETTINGS_UPDATES =
  'chat/phone-number-sets/receiveAutoReplySettingsUpdates';
export const UPDATE_MSG_CHANNEL_TYPE =
  'chat/phone-number-sets/updateMsgChannelType';

export const REMOVE_PHONE_NUMBER = 'chat/phone-number-sets/removePhoneNumber';

export const receive = (payload: PhoneNumberSet[]): ReceiveAction => ({
  type: RECEIVE,
  payload,
});

export const receiveAutoReply = (
  payload: AutoReply,
): ReceiveAutoReplyAction => ({
  type: RECEIVE_AUTO_REPLY,
  payload,
});

export const receiveAutoReplySettingsUpdates = (
  phoneNumber: string,
  autoReply: AutoReplyUpdatePayload,
): ReceiveAutoReplySettingsUpdatesAction => ({
  type: RECEIVE_AUTO_REPLY_SETTINGS_UPDATES,
  payload: {phoneNumber, autoReply},
});

export const updateMsgChannelType = (payload: {
  phoneNumberSetId: string,
  selected_channel: string,
}): UpdateMsgChannelTypeAction => ({
  type: UPDATE_MSG_CHANNEL_TYPE,
  payload,
});

export const removePhoneNumber = (payload: {
  phoneNumberSetId: string,
}): RemovePhoneNumberAction => ({
  type: REMOVE_PHONE_NUMBER,
  payload,
});

export const fetchAll: () => ThunkAction<mixed> = thunkify(
  key(() => 'chat/phone-number-sets/receive'),
  cached((response) => receive(response)),
)(
  () =>
    (dispatch: Dispatch): Promise<Response> =>
      dispatch(
        reduxApi.get(
          'messages_v2/phone-number-sets',
          {},
          {apiPath: '/api/v2/'},
        ),
      ),
);
export const updateInboxSettingsWithoutAPICall =
  (
    id: string,
    inboxSettings: {
      messaging_sound_setting: boolean,
      messaging_email_setting?: string,
    },
    autoReply: AutoReplyUpdatePayload,
  ): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const selectedInboxType = getSelectedInboxType(getState());
    const isQueueInbox = selectedInboxType === INBOX_TYPE.QUEUE;
    if (isQueueInbox) {
      const queueInboxData = selectCurrentQueue(getState());
      if (queueInboxData) {
        await dispatch(
          updateQueueBasedInboxes({
            ...queueInboxData,
            messaging_sound_setting: Boolean(
              inboxSettings.messaging_sound_setting,
            ),
          }),
        );
      }
    } else {
      // phone inbox with sms and whatsapp
      const phoneNumberSet = getState().chat.phoneNumberSets[id];
      dispatch(receive([{...phoneNumberSet, ...inboxSettings}]));
      dispatch(receiveAutoReplySettingsUpdates(id, autoReply));
    }
  };
export const updateInboxSettings =
  (
    id: string,
    inboxSettings: {
      color?: string,
      name?: string,
      is_primary?: boolean,
      messaging_sound_setting?: boolean,
      messaging_email_setting?: string,
    },
  ): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const settingKeys = [
      'color',
      'name',
      'is_primary',
      'messaging_sound_setting',
      'messaging_email_setting',
    ];
    const isLATEnabled = selectLATEnabled(getState());
    const selectedInboxType = getSelectedInboxType(getState());
    const isQueueInbox = selectedInboxType === INBOX_TYPE.QUEUE;
    if (isLATEnabled) {
      // lat inbox
      if (isQueueInbox) {
        const queueInboxData = selectCurrentQueue(getState());
        if (queueInboxData) {
          await dispatch(
            updateQueueBasedInboxes({
              ...queueInboxData,
              messaging_sound_setting: Boolean(
                inboxSettings.messaging_sound_setting,
              ),
            }),
          );
          try {
            if (settingKeys.some((key) => inboxSettings.hasOwnProperty(key))) {
              if (id) {
                await dispatch(
                  reduxApi.put(
                    'messages_v2/inbox',
                    {
                      id,
                      ...inboxSettings,
                    },
                    {},
                    {apiPath: '/api/v2/'},
                  ),
                );
              }
            }
          } catch (err) {
            if (queueInboxData) {
              dispatch(updateQueueBasedInboxes(queueInboxData));
            }
            throw err;
          }
        }
      } else {
        // phone inbox with sms and whatsapp
        const phoneNumberSet = getState().chat.phoneNumberSets[id];
        const whatsAppPhoneNumberSetId = phoneNumberSet?.whatsapp?.inbox_id;
        const smsPhoneNumberSetId = phoneNumberSet?.sms?.inbox_id;
        dispatch(receive([{...phoneNumberSet, ...inboxSettings}]));
        try {
          if (settingKeys.some((key) => inboxSettings.hasOwnProperty(key))) {
            if (smsPhoneNumberSetId) {
              await dispatch(
                reduxApi.put(
                  'messages_v2/inbox',
                  {
                    id: smsPhoneNumberSetId,
                    ...inboxSettings,
                  },
                  {},
                  {apiPath: '/api/v2/'},
                ),
              );
            }
            if (whatsAppPhoneNumberSetId) {
              await dispatch(
                reduxApi.put(
                  'messages_v2/inbox',
                  {
                    id: whatsAppPhoneNumberSetId,
                    ...inboxSettings,
                  },
                  {},
                  {apiPath: '/api/v2/'},
                ),
              );
            }
          }
        } catch (err) {
          dispatch(receive([phoneNumberSet]));
          throw err;
        }
      }
    } else {
      // will be deprecated
      const phoneNumberSet = getState().chat.phoneNumberSets[id];
      const whatsAppPhoneNumberSetId = phoneNumberSet?.whatsapp?.id;
      const smsPhoneNumberSetId = phoneNumberSet?.sms?.id;
      dispatch(receive([{...phoneNumberSet, ...inboxSettings}]));
      try {
        if (settingKeys.some((key) => inboxSettings.hasOwnProperty(key))) {
          if (smsPhoneNumberSetId) {
            await dispatch(
              reduxApi.put('messages_v2/phone-number-sets', {
                id: smsPhoneNumberSetId,
                ...inboxSettings,
              }),
            );
          }
          if (whatsAppPhoneNumberSetId) {
            await dispatch(
              reduxApi.put('messages_v2/phone-number-sets', {
                id: whatsAppPhoneNumberSetId,
                ...inboxSettings,
              }),
            );
          }
        }
      } catch (err) {
        dispatch(receive([phoneNumberSet]));
        throw err;
      }
    }
  };

export const updatePrimaryInbox =
  (id: string, is_primary: boolean): ThunkAction<> =>
  async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(updateInboxSettings(id, {is_primary}));

    const nextPhoneNumberSets = await dispatch(
      reduxApi.get('messages_v2/inbox', {}, {apiPath: '/api/v2/'}),
    );
    await dispatch(receive(nextPhoneNumberSets.phone_number_inboxes));
  };
