// @flow

import type {ThunkAction} from 'src/reducers';
import type {
  // $FlowFixMe[untyped-type-import]
  AgencyOverview,
  // $FlowFixMe[untyped-type-import]
  AgencyOverviewAudienceMembers,
} from 'src/api-parsers/index';

import {thunkify as flow} from 'src/utils/thunks';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api.js';
import parsers from 'src/api-parsers/index';
import {
  checkAnalyticsLandingPageV3Enabled,
  ANALYTICS_API_V3,
} from 'src/utils/analytics-api-migration';


export const RECEIVE = 'agencyOverview/receive';
export const RECEIVE_ACTIVE_AUDIENCE = 'agencyOverview/receiveMembers';

export type ReceiveAgencyOverviewAction = {
  type: 'agencyOverview/receive',
  // $FlowFixMe[value-as-type] [v1.32.0]
  payload: AgencyOverview,
};

export type AgencyOverviewAudienceAction = {
  type: 'agencyOverview/receiveMembers',
  // $FlowFixMe[value-as-type] [v1.32.0]
  payload: AgencyOverviewAudienceMembers,
};

export type AgencyOverviewAction =
  | ReceiveAgencyOverviewAction
  | AgencyOverviewAudienceAction;

const {
  parse: {
    agencyOverview: agencyOverviewParser,
    agencyOverviewAudienceMembers: agencyOverviewMemberParser,
  },
} = parsers;

export const receive = (
  // $FlowFixMe[value-as-type] [v1.32.0]
  payload: AgencyOverview,
): ReceiveAgencyOverviewAction => ({
  type: RECEIVE,
  payload,
});

export const receiveAudienceMembers = (
  // $FlowFixMe[value-as-type] [v1.32.0]
  payload: AgencyOverviewAudienceMembers,
): AgencyOverviewAudienceAction => ({
  type: RECEIVE_ACTIVE_AUDIENCE,
  payload,
});

export const getAgencyOverview: () => ThunkAction<mixed> = flow(
  key(() => `agencyOverview`),
  cached((json) => receive(agencyOverviewParser(json)), {ttl: 10000}),
  fetching({}),
)(() =>
  reduxApi.get(
    `agency/overview`,
    {},
    {
      apiPath: checkAnalyticsLandingPageV3Enabled()
        ? `${ANALYTICS_API_V3}/`
        : '/api/v1/',
    },
  ),
);

// TODO (chris): Remove when multiEntityEnabled is deprecated
export const getAgencyOverviewAudienceMembers: () => ThunkAction<mixed> = flow(
  key(() => `agencyOverviewAudienceMembers`),
  cached((json) => receiveAudienceMembers(json), {}),
  fetching({}),
)(() => reduxApi.get(`agency/active-members`));
