// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';

// $FlowFixMe[nonstrict-import]
import type {ApiPostOptions, ApiOptions} from 'src/utils/redux-api';

import type {
  SalesPerformanceAPIResponse,
  SalesPerformanceEntry,
  SalesPerformancePostBody,
} from 'src/types/sales-performance-analytics';

// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {fetching, key} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import {ANALYTICS_API_V4} from 'src/utils/analytics-api-migration';


export const RECEIVE_SALES_LIST = 'sales-performance-lists/receiveAll';
export const REFETCH_SALES_PERFORMANCE_LIST = 'sales-performance-lists/refetch';

type ReceiveSalesListsAction = {
  type: typeof RECEIVE_SALES_LIST,
  payload: SalesPerformanceEntry[],
};

type RefetchSalesListsAction = {
  type: typeof REFETCH_SALES_PERFORMANCE_LIST,
};

export type SalesPerformanceAnalyticsActions =
  | ReceiveSalesListsAction
  | RefetchSalesListsAction;

const receiveSalesPerformance = (
  payload: SalesPerformanceEntry[],
): ReceiveSalesListsAction => ({
  type: RECEIVE_SALES_LIST,
  payload,
});

export const refetchSalesPerformance = (): RefetchSalesListsAction => ({
  type: REFETCH_SALES_PERFORMANCE_LIST,
});

const analyticsOptions: ApiOptions = {apiPath: ANALYTICS_API_V4};
const analyticsPostOptions: ApiPostOptions = {apiPath: ANALYTICS_API_V4};

export const getPerformanceSalesLists: () => ThunkAction<mixed> = flow(
  key(() => 'list-sales-performance'),
  fetching(),
)(() => async (dispatch: Dispatch) => {
  try {
    const {data}: SalesPerformanceAPIResponse = await dispatch(
      reduxApi.get('/roi/rules', {}, analyticsOptions),
    );
    dispatch(receiveSalesPerformance(data));
  } catch {
    dispatch(receiveSalesPerformance([]));
  }
});

export const postSalesData: (
  data: SalesPerformancePostBody,
) => ThunkAction<mixed> = (data) => async (dispatch: Dispatch) => {
  const {name, currency_name, currency_value} = data;
  await dispatch(
    reduxApi.post(
      '/roi/rules',
      {
        name,
        currency_name,
        currency_value,
      },
      {},
      analyticsPostOptions,
    ),
  );
};
