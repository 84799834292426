// @noflow

import * as React from 'react';

import {formatPercentage} from 'src/utils';

import css from './progress.css';


const Progress = ({progress}: {
  progress: number,
}) => {
  const percent = formatPercentage(progress);
  return (
    <div className={css.box}>
      <div className={css.info}>
        <div>Uploading...</div>
        <div className={css.number}>{percent}</div>
      </div>
      <div className={css.total}>
        <div className={css.progress} style={{width: percent}}></div>
      </div>
    </div>
  );
};

export default Progress;
