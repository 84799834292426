// @flow

import * as React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'src/rerouter';
import {popModal} from 'src/action-creators/modal';

import {
  Content,
  TextContent,
  Container,
  Buttons,
  Button,
  SuccessTitleBar,
} from './components.jsx';
import {
  ReportExportInit,
  ReportExportSuccess,
} from 'src/components/common/reports/data-export-success-genesis.jsx';

import css from './base-modal.css';


export const DefaultContent = (): React.Node => (
  <>
    <p>
      When your requested report is ready for download, you will receive an
      email with a download link. While estimated times are varied, we expect it
      will be ready soon.
    </p>
    <p>
      To find all past reports, navigate to the Reports tab within Settings.
      (Settings &#8594; Reports)
    </p>
    <p>
      If you do not receive an email, check your spam folder or request the
      report again.
    </p>
  </>
);

export function DataExportInit({
  removeModal,
  CustomContent,
  modalVersion,
}: {
  removeModal: () => mixed,
  CustomContent?: string | React.Node,
  modalVersion?: 'genesis',
  ...
}): React.Node {
  const router = useHistory();
  const closePreviousModal = useClosePreviousModal();

  if (modalVersion === 'genesis') {
    return (
      <ReportExportInit
        CustomContent={CustomContent}
        handlePrimaryCta={() => {
          removeModal();
          closePreviousModal();
        }}
        handleSecondaryCta={() => {
          removeModal();
          closePreviousModal();
        }}
      />
    );
  }
  return (
    <Container>
      <SuccessTitleBar>Request successful</SuccessTitleBar>
      <Content>
        <TextContent className={css.successText}>
          {CustomContent ? CustomContent : <DefaultContent />}
        </TextContent>
        <Buttons>
          <Button
            onClick={() => {
              router.push('/settings/reports');
              removeModal();
              closePreviousModal();
            }}
          >
            View reports
          </Button>
          <Button type="primary" onClick={removeModal}>
            Okay
          </Button>
        </Buttons>
      </Content>
    </Container>
  );
}

export function DataExportSuccess({
  removeModal,
  modalVersion,
}: {
  removeModal: () => mixed,
  modalVersion?: 'genesis',
  ...
}): React.Node {
  const router = useHistory();
  const closePreviousModal = useClosePreviousModal();

  if (modalVersion === 'genesis') {
    return (
      <ReportExportSuccess
        handlePrimaryCta={() => {
          removeModal();
          closePreviousModal();
        }}
        handleSecondaryCta={() => {
          removeModal();
          closePreviousModal();
        }}
      />
    );
  }

  return (
    <Container>
      <SuccessTitleBar>Report ready</SuccessTitleBar>
      <Content>
        <TextContent className={css.successText}>
          <p>
            Your report is ready. You can download it by navigating to the
            Reports page.
          </p>
        </TextContent>
        <Buttons>
          <Button
            onClick={() => {
              router.push('/settings/reports');
              removeModal();
              closePreviousModal();
            }}
          >
            View reports
          </Button>
          <Button type="primary" onClick={removeModal}>
            Okay
          </Button>
        </Buttons>
      </Content>
    </Container>
  );
}

const useClosePreviousModal = () => {
  const dispatch = useDispatch();
  const closePreviousModal = React.useCallback(() => {
    //(diwakersurya) this closes previous modal whether its
    // a side panel or modal.
    dispatch(popModal());
  }, []);
  return closePreviousModal;
};
