// @flow strict

import * as React from 'react';
import {RadioGroup, Radio} from 'react-radio-group';
import {classify} from 'src/utils/classify';

import css from './radio.css';

export {RadioGroup};

export function RadioPair({
  className,
  value,
  label,
  isHorizontal,
}: {
  className?: ?string,
  value: mixed,
  label: React.Node,
  isHorizontal?: boolean,
}): React.Node {
  return (
    <label
      className={classify(
        isHorizontal ? css.radioLabelHorizontal : css.radioLabel,
        className,
      )}
    >
      <Radio value={value} className={css.radio} />
      <span className={css.inputLabel}>{label}</span>
    </label>
  );
}
