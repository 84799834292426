// @flow
import type {JourneyType} from '../chatbot/types.js';
import type {Router, RouteLocation} from 'src/types/router';
import type {State as ReduxState} from 'src/reducers';
import type {AtsAnalyticsField, EntityFilter} from 'src/types/ats-settings';
// $FlowFixMe[untyped-type-import]
import type {UpdateOptions, AnalyticsQuery} from 'src/utils/analytics-query';
// $FlowFixMe[untyped-type-import]
import type {ClientEventType} from 'src/types/events';
import type {AtsEntity} from 'src/types/ats-entities';

import * as React from 'react';
import {connect, useDispatch} from 'react-redux';

import get from 'lodash/get';
import flow from 'lodash/flow';
import isEqual from 'lodash/isEqual';

import {formatPlural} from 'src/utils/intl';
import {queryIsEmpty} from 'src/utils/analytics-query';

import {getAtsFieldValues} from 'src/selectors/ats-settings';
import {
  selectEntityFilters,
  selectEntityFiltersForType,
  getCandidateFieldName,
  selectEntityMappingsByName,
} from 'src/selectors/ats-entities';
import {getCanEditSettings} from 'src/selectors/accounts';
import {getTrackingEvents} from 'src/selectors/report';
import {saveDefaultAnalyticsQuery} from 'src/action-creators/report';

import withAnalyticsQuery from 'src/components/analytics/with-query.jsx';
import ClickAway from 'src/components/lib/click-away';
import {FilterButton} from 'src/components/lib/analytics-filter-button';

import {
  MessageTypeCardV2,
  ResultCard,
  DeliveryStatusCard,
  RelatedToCard,
  RecipientCard,
  TrackingEventCard,
  SurveyAndChatbotCard,
  MoreFiltersCard,
  SentCard,
  DeliverySetToBouncedCard,
  SendWasSkippedCard,
  JourneyFilterCard,
  FilterCalendarStatusCard,
} from 'src/components/lib/analytics-filter-card';
import {SentReasonCard} from 'src/components/lib/analytics-filter-card/sent-card.jsx';
import TimeframeFilter from './timeframe-filter.jsx';

import css from './index.css';


type FilterType =
  | 'timeframe'
  | 'messagetype'
  | 'recipient'
  | 'relatedTo'
  | 'tracking_events'
  | 'more'
  | 'sentType'
  | 'result'
  | 'delivery_status'
  | 'event_type_filter'
  | 'journey'
  | 'filter_calendar_status';

export type FilterProps = {
  activeTabs: string[],
  query: Object,
  // $FlowFixMe[value-as-type] [v1.32.0]
  onQueryChange: (update: Object, options?: UpdateOptions) => void,
  router: Router,
  location: RouteLocation,
};

type OwnProps = {
  // $FlowFixMe[value-as-type] [v1.32.0]
  eventType?: ClientEventType,
  includeFilters?: FilterType[],
  extraFilters?: React.ComponentType<FilterProps>[],
  workflowEntityType?: string,
  entityType?: string,
  journeyList?: JourneyType[],
  minDate?: string,
  defaultTimeAgo?: string,
  showDefaultFilter?: boolean,
  className?: string,
  direction?: 'left' | 'right',
  showInternalUserFilter?: boolean,
};

type Props = {
  ...OwnProps,
  location: RouteLocation,
  router: Router,
  eventTypeCounts: {[key: string]: number},
  query: Object,
  // $FlowFixMe[value-as-type] [v1.32.0]
  onQueryChange: (update: Object, options?: UpdateOptions) => void,
  entities: {[string]: AtsEntity},
  atsFieldValues: {
    [id: string]: {
      values: AtsAnalyticsField[],
    },
  },
  entityFilters: Array<EntityFilter>,
  showSettingsLink: ?string,
  // $FlowFixMe[value-as-type] [v1.32.0]
  defaultQuery: ?AnalyticsQuery,
  agentDefaultFiltersEnabled: boolean,
  className?: string,
  journeyList?: JourneyType[],
  direction?: 'left' | 'right',
  showInternalUserFilter?: boolean,
};

const mapStateToProps = (state: ReduxState, {entityType}): * => ({
  agentDefaultFiltersEnabled: state.agency.config.agentDefaultFiltersEnabled,
  showSettingsLink:
    (getCanEditSettings(state) && getCandidateFieldName(state)) || null,
  eventTypeCounts: state.eventTypeCounts.agency,
  entities: selectEntityMappingsByName(state),
  atsFieldValues: getAtsFieldValues(state),
  entityFilters: entityType
    ? selectEntityFiltersForType(state, entityType)
    : selectEntityFilters(state),
  defaultQuery: state.report.defaultQuery,
});

const AnalyticsFilters = ({
  className,
  location,
  query,
  router: history,
  eventType,
  eventTypeCounts,
  includeFilters = ['timeframe', 'recipient', 'more'],
  extraFilters = [],
  entities,
  atsFieldValues,
  entityFilters,
  onQueryChange,
  showSettingsLink,
  defaultQuery,
  agentDefaultFiltersEnabled,
  journeyList,
  minDate,
  defaultTimeAgo,
  showDefaultFilter = true,
  direction,
  showInternalUserFilter = false,
}: Props) => {
  const defaultState: {
    activeTabs: string[],
    openFilter: ?string,
    lastFilterAction: 'none' | 'cleared' | 'restored' | 'saved',
  } = {
    activeTabs: [],
    openFilter: null,
    lastFilterAction: 'none',
  };
  const [state, setState] = React.useState(defaultState);
  const {activeTabs} = state;

  const dispatch = useDispatch();

  const localQueryChange = (...args) => {
    onQueryChange(...args);
    setState({...state, lastFilterAction: 'none'});
  };

  const handleOpenFilter = (name) => () => {
    setState({...state, openFilter: name});
  };

  const queryAsArray = (
    key: string,
    // $FlowFixMe[value-as-type] [v1.32.0]
    options?: UpdateOptions = {entityDataFilter: undefined},
  ) => {
    const getKey = options.entityDataFilter ? ['edf', key] : key;
    return get(query, getKey, []);
  };

  const queryAsValue = (
    key: string,
    // $FlowFixMe[value-as-type] [v1.32.0]
    options?: UpdateOptions = {entityDataFilter: undefined},
  ) => {
    const getKey = options.entityDataFilter ? ['edf', key] : key;
    return get(query, getKey, null);
  };

  const _handleKeyedArrays = (
    key: string,
    evt: SyntheticEvent<HTMLInputElement>,
  ) => {
    const {name} = evt.currentTarget;
    const queryArray = queryAsArray(key);

    const queryArgs = queryArray.includes(name)
      ? queryArray.filter((type) => type !== name)
      : [...queryArray, name];

    localQueryChange({
      [key]: {$set: queryArgs},
    });
  };

  const handleMessageAndGoalTypeChange = _handleKeyedArrays.bind(
    this,
    'message_goal_type',
  );
  const handleResultTypeChange = _handleKeyedArrays.bind(this, 'result');
  const handleDeliveryStatusTypeChange = _handleKeyedArrays.bind(
    this,
    'delivery_status',
  );
  const handleRecipientTypeChange = _handleKeyedArrays.bind(
    this,
    'recipient_type',
  );

  const handleFilterCalendarStatusTypeChange = _handleKeyedArrays.bind(
    this,
    'filter_calendar_status',
  );

  const handleJourneyTypeChange = _handleKeyedArrays.bind(this, 'journey_type');

  const handleTrackingEventTypeChange = _handleKeyedArrays.bind(
    this,
    'tracking_events',
  );

  const handleRangeChange =
    (key: string) => (range: {start: number, end: number}) => {
      localQueryChange(
        {
          [key]: {$set: range},
        },
        {
          entityDataFilter: true,
        },
      );
    };

  const handleStringListChange = (key: string) => (values: string[]) => {
    localQueryChange(
      {
        [key]: {$set: values},
      },
      {entityDataFilter: true},
    );
  };

  const handleSurveyChatbotChange = (filter) => {
    localQueryChange({
      event_type_filter: {$set: filter},
    });
  };

  const handleFromAddressChange = (vals: string[]) => {
    localQueryChange({
      ['from_address']: {$set: vals},
    });
  };

  const selectedMessageAndGoalTypes = queryAsArray('message_goal_type');
  const selectedResultTypes = queryAsArray('result');
  const selectedDeliveryStatusTypes = queryAsArray('delivery_status');
  const selectedRecipientTypes = queryAsArray('recipient_type');
  const selectedFilterCalendarStatusTypes = queryAsArray(
    'filter_calendar_status',
  );
  const selectedJourneyTypes = queryAsArray('journey_type');
  const selectedTrackingEventTypes = queryAsArray('tracking_events');
  const selectedSummaryFilterType = queryAsValue('event_type_filter') || null;
  const selectedFromAddresses = queryAsArray('from_address');

  const {
    client,
    recruiter,
    office,
    account_manager: accountManager,
    brand,
  } = atsFieldValues;

  const edfOption = {entityDataFilter: true};

  const selectedClients = client
    ? // $FlowFixMe
      queryAsArray(client.senseAttribute, edfOption)
    : [];
  const selectedOffices = office
    ? // $FlowFixMe
      queryAsArray(office.senseAttribute, edfOption)
    : [];
  const selectedRecruiters = recruiter
    ? // $FlowFixMe
      queryAsArray(recruiter.senseAttribute, edfOption)
    : [];
  const selectedAccountManagers = accountManager
    ? // $FlowFixMe
      queryAsArray(accountManager.senseAttribute, edfOption)
    : [];
  const selectedBrands = brand
    ? // $FlowFixMe
      queryAsArray(brand.senseAttribute, edfOption)
    : [];

  // $FlowFixMe
  const showRelatedToFilter = shouldShowFilter(
    // $FlowFixMe upgraded flow
    client,
    // $FlowFixMe upgraded flow
    recruiter,
    // $FlowFixMe upgraded flow
    office,
    // $FlowFixMe upgraded flow
    accountManager,
    // $FlowFixMe upgraded flow
    brand,
  );

  let entityFiltersTransformed = Object.keys(atsFieldValues).reduce(
    (transformedFilters, fieldValueId) => {
      const fieldValue = atsFieldValues[fieldValueId];
      let entityFilter = entityFilters.find(
        ({id: entityFilterId}) => fieldValueId === entityFilterId,
      );
      if (entityFilter && fieldValue) {
        entityFilter = {
          ...entityFilter,
          entity_type_display_name:
            entities[entityFilter.entity_type]?.display_name,
          sense_name: entities[entityFilter.entity_type]?.sense_name,
        };
        const selected = queryAsArray(
          `${entityFilter.entity_type}/${entityFilter.attribute_name}`,
          edfOption,
        );
        transformedFilters.push({
          ...entityFilter,
          ...fieldValue,
          selected,
        });
      }
      return transformedFilters;
    },
    [],
  );

  if (showInternalUserFilter) {
    entityFiltersTransformed = entityFiltersTransformed.filter(
      (item: any) => item?.sense_name === 'internal_employee',
    );
  }

  const entityFiltersActiveCount = entityFiltersTransformed.reduce(
    (prev, {selected}) => {
      if (selected) {
        if (Array.isArray(selected)) {
          return prev + selected.length;
        }
        return prev + 1;
      }
      return prev;
    },
    0,
  );

  const moreFiltersCount =
    entityFiltersActiveCount + selectedFromAddresses.length;
  const sentState = queryAsValue('show_filter') || 'sent_only';
  const deliveryStatusState = queryAsValue('delivery_status') || [];

  return (
    <div className={className}>
      {/* timeframe is always active until date-less queries happen? */}
      {includeFilters.includes('timeframe') && (
        <TimeframeFilter
          query={query}
          activeTabs={activeTabs}
          onQueryChange={localQueryChange}
          router={history}
          location={location}
          minDate={minDate}
          defaultTimeAgo={defaultTimeAgo}
          direction={direction}
        />
      )}

      {showRelatedToFilter && includeFilters.includes('relatedTo') && (
        <ClickAway>
          {({isOpen, onOpen, cancelNext}) => (
            <div className={css.filter}>
              <FilterButton
                className={css.button}
                active={
                  activeTabs.includes('relatedTo') ||
                  selectedClients.length +
                    selectedRecruiters.length +
                    selectedBrands.length +
                    selectedAccountManagers.length +
                    selectedOffices.length >
                    0
                }
                activeFilters={
                  selectedClients.length +
                  selectedRecruiters.length +
                  selectedBrands.length +
                  selectedAccountManagers.length +
                  selectedOffices.length
                }
                onClick={() => {
                  handleOpenFilter('relatedTo');
                  onOpen();
                }}
              >
                Related To
              </FilterButton>
              {isOpen && (
                <div className={css.filterCard} onClickCapture={cancelNext}>
                  <RelatedToCard
                    history={history}
                    location={location}
                    client={client}
                    office={office}
                    recruiter={recruiter}
                    accountManager={accountManager}
                    brand={brand}
                    selectedClients={selectedClients}
                    selectedOffices={selectedOffices}
                    selectedRecruiters={selectedRecruiters}
                    selectedAccountManagers={selectedAccountManagers}
                    selectedBrands={selectedBrands}
                    onStringListChange={handleStringListChange}
                  />
                </div>
              )}
            </div>
          )}
        </ClickAway>
      )}

      {includeFilters.includes('recipient') && (
        <ClickAway>
          {({isOpen, onOpen, cancelNext}) => (
            <div className={css.filter}>
              <FilterButton
                className={css.button}
                active={
                  activeTabs.includes('recipient') ||
                  selectedRecipientTypes.length > 0
                }
                activeFilters={selectedRecipientTypes.length}
                onClick={() => {
                  handleOpenFilter('recipient');
                  onOpen();
                }}
              >
                Recipient Type
              </FilterButton>
              {isOpen && (
                <div className={css.filterCard} onClickCapture={cancelNext}>
                  <RecipientCard
                    onRecipientTypeChange={handleRecipientTypeChange}
                    selectedRecipientTypes={selectedRecipientTypes}
                  />
                </div>
              )}
            </div>
          )}
        </ClickAway>
      )}

      {includeFilters.includes('filter_calendar_status') && (
        <ClickAway>
          {({isOpen, onOpen, cancelNext}) => (
            <div className={css.filter}>
              <FilterButton
                className={css.button}
                active={
                  activeTabs.includes('filter_calendar_status') ||
                  selectedFilterCalendarStatusTypes.length > 0
                }
                activeFilters={selectedFilterCalendarStatusTypes.length}
                onClick={() => {
                  handleOpenFilter('filter_calendar_status');
                  onOpen();
                }}
              >
                Filter Calendar Status
              </FilterButton>
              {isOpen && (
                <div className={css.filterCard} onClickCapture={cancelNext}>
                  <FilterCalendarStatusCard
                    onFilterCalendarStatusTypeChange={
                      handleFilterCalendarStatusTypeChange
                    }
                    selectedFilterCalendarStatusTypes={
                      selectedFilterCalendarStatusTypes
                    }
                  />
                </div>
              )}
            </div>
          )}
        </ClickAway>
      )}

      {includeFilters.includes('journey') && (
        <ClickAway>
          {({isOpen, onOpen, cancelNext}) => (
            <div className={css.filter}>
              <FilterButton
                className={css.button}
                active={
                  activeTabs.includes('journey') ||
                  selectedJourneyTypes.length > 0
                }
                activeFilters={selectedJourneyTypes.length}
                onClick={() => {
                  handleOpenFilter('journey');
                  onOpen();
                }}
              >
                Journey Type
              </FilterButton>
              {isOpen && (
                <div className={css.filterCard} onClickCapture={cancelNext}>
                  <JourneyFilterCard
                    onJourneyTypeChange={handleJourneyTypeChange}
                    selectedJourneyTypes={selectedJourneyTypes}
                    journeyTypes={journeyList}
                  />
                </div>
              )}
            </div>
          )}
        </ClickAway>
      )}

      {includeFilters.includes('sentType') && (
        <ClickAway>
          {({isOpen, onOpen, cancelNext}) => (
            <div className={css.filter}>
              <FilterButton
                className={css.button}
                active={activeTabs.includes('sentType')}
                // activeFilters={sentState && sentState !== 'both'}
                onClick={() => {
                  handleOpenFilter('sentType');
                  onOpen();
                }}
              >
                Sent or Skipped
              </FilterButton>
              {isOpen && (
                <div className={css.filterCard} onClickCapture={cancelNext}>
                  <SentCard
                    value={sentState}
                    onChange={(v) => {
                      localQueryChange({
                        show_filter: {$set: v},
                        delivery_status: {$set: undefined},
                        result: {$set: undefined},
                        message_goal_type: {$set: undefined},
                      });
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </ClickAway>
      )}

      {includeFilters.includes('delivery_status') && (
        <ClickAway>
          {({isOpen, onOpen, cancelNext}) => (
            <div className={css.filter}>
              <FilterButton
                className={css.button}
                active={
                  activeTabs.includes('delivery_status') ||
                  selectedDeliveryStatusTypes.length > 0
                }
                activeFilters={
                  selectedDeliveryStatusTypes.length > 1
                    ? selectedDeliveryStatusTypes.length
                    : 0
                }
                onClick={() => {
                  handleOpenFilter('delivery_status');
                  onOpen();
                }}
              >
                Delivered?
              </FilterButton>
              {isOpen && (
                <div className={css.filterCard} onClickCapture={cancelNext}>
                  {sentState !== 'sent_only' ? (
                    <SentReasonCard body={'Set "Sent or Skipped?" to Sent'} />
                  ) : (
                    <DeliveryStatusCard
                      onChange={handleDeliveryStatusTypeChange}
                      selectedTypes={selectedDeliveryStatusTypes}
                      sent={sentState}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </ClickAway>
      )}

      {includeFilters.includes('messagetype') && (
        <ClickAway>
          {({isOpen, onOpen, cancelNext}) => (
            <div className={css.filter}>
              <FilterButton
                className={css.button}
                active={
                  activeTabs.includes('messagetype') ||
                  selectedMessageAndGoalTypes.length > 0
                }
                activeFilters={
                  selectedMessageAndGoalTypes.length > 1
                    ? selectedMessageAndGoalTypes.length
                    : 0
                }
                onClick={() => {
                  handleOpenFilter('messagetype');
                  onOpen();
                }}
              >
                Message Type & goal
              </FilterButton>
              {isOpen && (
                <div className={css.filterCard} onClickCapture={cancelNext}>
                  <MessageTypeCardV2
                    onChange={handleMessageAndGoalTypeChange}
                    selectedTypes={selectedMessageAndGoalTypes}
                    showSkipped={sentState !== 'sent_only'}
                  />
                </div>
              )}
            </div>
          )}
        </ClickAway>
      )}

      {includeFilters.includes('result') && (
        <ClickAway>
          {({isOpen, onOpen, cancelNext}) => (
            <div className={css.filter}>
              <FilterButton
                className={css.button}
                active={
                  activeTabs.includes('result') ||
                  selectedResultTypes.length > 0
                }
                activeFilters={
                  selectedResultTypes.length > 1
                    ? selectedResultTypes.length
                    : 0
                }
                onClick={() => {
                  handleOpenFilter('result');
                  onOpen();
                }}
              >
                Converted?
              </FilterButton>
              {isOpen && (
                <div className={css.filterCard} onClickCapture={cancelNext}>
                  {sentState === 'skipped_only' ? (
                    <SendWasSkippedCard />
                  ) : deliveryStatusState.length === 1 &&
                    deliveryStatusState[0] === 'bounced' ? (
                    <DeliverySetToBouncedCard />
                  ) : (
                    <ResultCard
                      onChange={handleResultTypeChange}
                      selectedTypes={selectedResultTypes}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </ClickAway>
      )}

      {includeFilters.includes('event_type_filter') && (
        <ClickAway>
          {({isOpen, onOpen, cancelNext}) => (
            <div className={css.filter}>
              <FilterButton
                className={css.button}
                active={
                  activeTabs.includes('event_type_filter') ||
                  selectedSummaryFilterType != null
                }
                onClick={() => {
                  handleOpenFilter('event_type_filter');
                  onOpen();
                }}
              >
                Surveys &amp; chatbots
              </FilterButton>
              {isOpen && (
                <div className={css.filterCard} onClickCapture={cancelNext}>
                  <SurveyAndChatbotCard
                    onTypeChange={handleSurveyChatbotChange}
                    selectedType={selectedSummaryFilterType}
                  />
                </div>
              )}
            </div>
          )}
        </ClickAway>
      )}

      {includeFilters.includes('tracking_events') && (
        <ClickAway>
          {({isOpen, onOpen, cancelNext}) => (
            <div className={css.filter}>
              <FilterButton
                className={css.button}
                active={
                  activeTabs.includes('tracking_events') ||
                  selectedTrackingEventTypes.length > 0
                }
                activeFilters={selectedTrackingEventTypes.length}
                onClick={() => {
                  handleOpenFilter('tracking_events');
                  onOpen();
                }}
              >
                Marketing
              </FilterButton>
              {isOpen && (
                <div className={css.filterCard} onClickCapture={cancelNext}>
                  <TrackingEventCard
                    onTrackingEventTypeChange={handleTrackingEventTypeChange}
                    trackingEventTypes={getTrackingEvents(
                      eventType || (query.event_types && query.event_types[0]),
                    )}
                    selectedTrackingEventTypes={selectedTrackingEventTypes}
                  />
                </div>
              )}
            </div>
          )}
        </ClickAway>
      )}

      {includeFilters.includes('more') && (
        <ClickAway>
          {({isOpen, onOpen, cancelNext}) => (
            <div className={css.filter}>
              <FilterButton
                className={css.button}
                active={activeTabs.includes('more') || moreFiltersCount > 0}
                activeFilters={moreFiltersCount}
                onClick={() => {
                  handleOpenFilter('more');
                  onOpen();
                }}
              >
                More Filters
              </FilterButton>
              {isOpen && (
                <div className={css.filterCard} onClickCapture={cancelNext}>
                  {/* $FlowFixMe[prop-missing] */}
                  <MoreFiltersCard
                    // $FlowFixMe[incompatible-type]
                    filters={entityFiltersTransformed}
                    // $FlowFixMe[incompatible-type]
                    onValueChange={handleRangeChange}
                    onStringListChange={handleStringListChange}
                    showSettingsLink={showSettingsLink}
                    onChangeFromAddress={handleFromAddressChange}
                    filteredFromAddresses={selectedFromAddresses}
                    direction={direction}
                    showInternalUserFilter={showInternalUserFilter}
                    direction={direction}
                  />
                </div>
              )}
            </div>
          )}
        </ClickAway>
      )}

      {extraFilters.map((Filter) => (
        <Filter
          query={query}
          activeTabs={activeTabs}
          onQueryChange={localQueryChange}
          router={history}
          location={location}
        />
      ))}

      {state.lastFilterAction !== 'cleared' && !queryIsEmpty(query) && (
        <button
          className={css.textButton}
          onClick={() => {
            onQueryChange({$set: {}});
            setState({...state, lastFilterAction: 'cleared'});
          }}
        >
          clear filters
        </button>
      )}
      {state.lastFilterAction !== 'none' && (
        <button className={css.textButton} disabled>
          filters {state.lastFilterAction}
        </button>
      )}
      {showDefaultFilter &&
        agentDefaultFiltersEnabled &&
        !isEqual(defaultQuery, query) &&
        state.lastFilterAction !== 'restored' && (
          <>
            <button
              className={css.textButton}
              onClick={() => {
                onQueryChange({
                  $merge: defaultQuery,
                });
                setState({...state, lastFilterAction: 'restored'});
              }}
            >
              restore saved filters
            </button>
            <button
              className={css.textButton}
              onClick={() => {
                dispatch(saveDefaultAnalyticsQuery(query));
                setState({...state, lastFilterAction: 'saved'});
              }}
            >
              save these filters
            </button>
          </>
        )}
    </div>
  );
};

export default (flow(
  connect(mapStateToProps),
  withAnalyticsQuery,
)(AnalyticsFilters): React.ComponentType<OwnProps>);

const humanTimeframeQuery = (query) => {
  if (query.time_ago !== undefined) {
    const timeAgo = JSON.parse(query.time_ago).days;
    if (timeAgo !== undefined) {
      return `Last ${timeAgo} ${formatPlural(timeAgo, 'day', 'days')}`;
    }
  }
  if (query.start_date && query.end_date) {
    return 'Custom Date Range';
  }
  if (!query.start_date && !query.end_date && !query.time_ago) {
    return 'Last 90 days';
  }
  return 'Timeframe';
};

const shouldShowFilter = (...fieldValues: Array<?AtsAnalyticsField>): boolean =>
  fieldValues.reduce(
    (prev, curr) =>
      prev ||
      Boolean(curr && curr.values === null && curr.error && curr.message) ||
      Boolean(curr && Array.isArray(curr.values)) ||
      Boolean(curr && curr.min != null && curr.max != null),
    false,
  );
