// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {
  JobVariable,
  JobVariableValues,
  JobMatchParams,
} from 'src/types/job-variables';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';
// $FlowFixMe[untyped-import]
import {key, cached, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[nonstrict-import]
import {pushModal, popModal} from 'src/action-creators/modal';


export const RECEIVE_JOB_VARIABLES = 'job-variables/receiveAll';
export const RECEIVE_JOB_VARIABLE_VALUES = 'job-variables/receiveAllValues';

type ReceiveJobVariablesAction = {
  type: 'job-variables/receiveAll',
  payload: JobVariable[],
};

type ReceiveJobVariableValuesAction = {
  type: 'job-variables/receiveAllValues',
  payload: JobVariableValues,
};

export type JobVariablesAction =
  | ReceiveJobVariablesAction
  | ReceiveJobVariableValuesAction;

const receiveJobVariables = (
  jobVariables: JobVariable[],
): ReceiveJobVariablesAction => ({
  type: RECEIVE_JOB_VARIABLES,
  payload: jobVariables,
});

const receiveJobVariableValues = (
  values: JobVariableValues,
): ReceiveJobVariableValuesAction => ({
  type: RECEIVE_JOB_VARIABLE_VALUES,
  payload: values,
});

export const getAllJobVariables: () => ThunkAction<mixed> = flow(
  key(() => 'job-variables/all'),
  cached((jobVariables) => receiveJobVariables(jobVariables), {
    ttl: 86_400_000,
  }),
  fetching(),
)(
  () => (dispatch: Dispatch) =>
    dispatch(reduxApi.get('job_match/attribute/details')),
);

export const getJobVariableValues: (
  string,
  number,
  JobMatchParams,
) => ThunkAction<mixed> = flow(
  key(
    (
      candidateEntityId: string,
      job_matches_count: number,
      job_matches_params: JobMatchParams,
    ) =>
      `candidateEntityId:${candidateEntityId}:${job_matches_count}:${JSON.stringify(
        job_matches_params,
      )}`,
  ),
  cached((json) => receiveJobVariableValues(json), {
    hash: (
      candidateEntityId: string,
      job_matches_count: number,
      job_matches_params: JobMatchParams,
    ) =>
      `candidateEntityId:${candidateEntityId}:${job_matches_count}:${JSON.stringify(
        job_matches_params,
      )}`,
  }),
  fetching(),
)(
  (
    candidateEntityId: string,
    job_matches_count: number,
    job_matches_params: JobMatchParams,
  ) =>
    reduxApi.get(`job_match/candidate/${candidateEntityId}/data`, {
      num_job_matches: job_matches_count,
      job_matches_params: JSON.stringify(job_matches_params),
    }),
);

export const getJobVariableValuesForAnyEntity: (
  string,
  number,
  JobMatchParams,
  string,
) => ThunkAction<mixed> = flow(
  key(
    (
      anchorEntityId: string,
      job_matches_count: number,
      job_matches_params: JobMatchParams,
      workflow_entity_type: string,
    ) =>
      `anchorEntityId:${anchorEntityId}:${job_matches_count}:${JSON.stringify(
        job_matches_params,
      )}:workflow_entity_type:${workflow_entity_type}`,
  ),
  cached((json) => receiveJobVariableValues(json), {
    hash: (
      anchorEntityId: string,
      job_matches_count: number,
      job_matches_params: JobMatchParams,
      workflow_entity_type: string,
    ) =>
      `anchorEntityId:${anchorEntityId}:${job_matches_count}:${JSON.stringify(
        job_matches_params,
      )}:workflow_entity_type:${workflow_entity_type}`,
  }),
  fetching(),
)(
  (
    anchorEntityId: string,
    job_matches_count: number,
    job_matches_params: JobMatchParams,
    workflow_entity_type,
  ) =>
    reduxApi.get(
      `job_match/workflow_job_match_data/${workflow_entity_type}/${anchorEntityId}`,
      {
        num_job_matches: job_matches_count,
        job_matches_params: JSON.stringify(job_matches_params),
      },
    ),
);

export const showJobMatchParamsModal: ({
  dynamicLabels: string[],
  job_matches_count: number,
  job_matches_params: JobMatchParams,
  onParamsChange: (params: JobMatchParams, matchesCount: number) => void,
}) => ThunkAction<mixed> =
  ({dynamicLabels, job_matches_count, job_matches_params, onParamsChange}) =>
  (dispatch: Dispatch): Promise<boolean> =>
    new Promise((resolve) => {
      // TODO: allow api users to handle confirm and reject
      dispatch(
        pushModal({
          dynamicLabels,
          job_matches_count,
          job_matches_params: job_matches_params ?? {
            city: null,
            state: null,
            radius_in_miles: null,
            keywords: null,
            title: null,
            skills: null,
          },
          onParamsChange,
          type: 'JOB_MATCH_PARAMS_MODAL',
          confirmText: 'Okay',
          handleConfirm: () => {
            dispatch(popModal());
            resolve(true);
          },
        }),
      );
    });
