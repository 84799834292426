// @flow

import type {Router, RouteLocation} from 'src/types/router';

import * as React from 'react';

import {classify} from 'src/utils';

import {FilterCard} from './';
import Checkbox from 'src/components/lib/checkbox';

import css from './result-card.css';


type Props = {
  onSave?: (e: SyntheticEvent<>) => mixed,
  onCancel?: (e: SyntheticEvent<>) => mixed,
  onChange: (e: SyntheticEvent<HTMLInputElement>) => mixed,
  selectedTypes: string[],
  eventTypeCounts?: ?{[key: string]: number},
  eventTypes?: {value: string, label: string}[],
  history?: Router,
  location?: RouteLocation,
  sent: 'skipped_only' | 'sent_only',
};

const FiltersSendingSkipped = [
  {
    key: 'missing_info',
    label: 'Missing info',
    color: css.colorError,
  },
  {
    key: 'invalid_info',
    label: 'Invalid info',
    color: css.colorError,
  },
  {
    key: 'suppression',
    label: 'Suppression',
    color: css.colorError,
  },
  {
    key: 'other',
    label: 'Other',
    color: css.colorError,
  },
];

const FiltersSent = [
  {
    key: 'delivered',
    label: 'Delivered',
  },
  {
    key: 'bounced',
    label: 'Bounced',
  },
];

const DeliveryStatusCard = ({
  onSave,
  onCancel,
  onChange,
  selectedTypes,
  sent,
}: Props): React.Node => (
  <FilterCard
    onSave={onSave}
    onCancel={onCancel}
    className={css.deliveryCardContainer}
  >
    {sent === 'skipped_only' ? (
      <>
        <div className={css.header}>sending skipped</div>
        <ul className={css.checklist}>
          {FiltersSendingSkipped.map(({key, label, color}) => (
            <li key={key}>
              <label
                className={classify(css.label, {
                  [css.labelChecked]: selectedTypes.includes(key),
                })}
                style={{color}}
              >
                <Checkbox
                  className={css.checkbox}
                  name={key}
                  checked={selectedTypes.includes(key)}
                  onChange={onChange}
                />
                {label}
              </label>
            </li>
          ))}
        </ul>
      </>
    ) : (
      <>
        <ul className={css.checklist}>
          {FiltersSent.map(({key, label}) => (
            <li key={key}>
              <label
                className={classify(css.label, {
                  [css.labelChecked]: selectedTypes.includes(key),
                })}
              >
                <Checkbox
                  className={css.checkbox}
                  name={key}
                  checked={selectedTypes.includes(key)}
                  onChange={onChange}
                />
                {label}
              </label>
            </li>
          ))}
        </ul>
      </>
    )}
  </FilterCard>
);

export default DeliveryStatusCard;
