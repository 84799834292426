// @flow strict

/**
 * similar to _.range but allows you to pass a callback to create unique items.
 *
 * ex
 *   const myArray = fillArray(10, index => ({index}));
 *   myArray[8].index === 8;
 */
export function fillArray<T>(
  arrayParam: Array<mixed> | number,
  filler: (number) => T,
): Array<T> {
  const array = Array.isArray(arrayParam) ? arrayParam : Array(arrayParam);

  const returnArray = [];
  for (let i = 0; i < array.length; i++) {
    returnArray[i] = filler(i);
  }

  return returnArray;
}

export function first<T>(array: Array<T>): ?T {
  return array[0];
}

export function last<T>(array: Array<T>): ?T {
  return array[array.length - 1];
}

export function contains(superset: mixed[], maybeSubset: mixed[]): boolean {
  return maybeSubset.reduce((acc, elt) => acc && superset.includes(elt), true);
}
