//@flow strict
import * as React from 'react';
import {useDebounce} from 'src/hooks/useDebounce';

import {SearchInput} from '@spaced-out/ui-design-system/lib/components/SearchInput';
import type {SearchInputProps} from '@spaced-out/ui-design-system/lib/components/SearchInput';


export type DebouncedSearchInputProps = {
  ...SearchInputProps,
  classNames?: $Shape<{container: string, input: string}>,
  value: string,
  onChange: (string) => mixed,
  placeholder?: string,
  debounceTime?: number,
  size?: 'medium' | 'small',
  ...
};

export const DebouncedSearchInput = ({
  classNames,
  value,
  onChange,
  placeholder = 'Search',
  debounceTime = 300,
  size = 'medium',
  ...searchInputProps
}: DebouncedSearchInputProps): React.Node => {
  const [query, setQuery] = React.useState(value);
  const debouncedQueryValue = useDebounce(query, debounceTime);
  React.useEffect(() => {
    onChange(debouncedQueryValue);
  }, [debouncedQueryValue]);

  React.useEffect(() => {
    if (value !== query) {
      setQuery(value);
    }
  }, [value]);
  return (
    <SearchInput
      {...searchInputProps}
      classNames={{wrapper: classNames?.container}}
      value={query}
      onChange={(e) => setQuery(e.target.value)}
      placeholder={placeholder}
      onClear={() => setQuery('')}
      size={size}
    />
  );
};
