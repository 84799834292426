// @noflow
import Store from './base';


export type header = {
  value?: string,
  type?: string,
  skip?: boolean,
  custom?: boolean, // don't send to backend
  error?: string,
};

export const HeaderTypeEnum = {
  Text: 'text',
  Date: 'date',
  Number: 'number',
};

type uploadResponse = ?{
  created_audience_members: Object[],
  failed_rows: Object[],
  updated_audience_members: Object[],
  warnings: Object[],
};

export default class CsvStore extends Store {
  state: {
    csvFile: ?Object,
    hasHeaders: boolean,
    parsedCsv: ?Object,
    columnIndex: number,
    headers: header[],
    numColumns: number,
    uploadResponse: uploadResponse,
    uploadProgress: number,
    uploadError: boolean,
  };

  constructor() {
    super('csv');

    this.state = {
      csvFile: null,
      hasHeaders: false,
      parsedCsv: null,
      columnIndex: 0,
      headers: [],
      numColumns: 0,
      uploadResponse: null,
      uploadProgress: 0,
      uploadError: false,
    };
  }

  // Set is an initializing function, it should be called before all other properties are modified.
  set(csvFile: Object, parsedCsv: Object) {
    this.setState({
      csvFile,
      hasHeaders: false,
      parsedCsv,
      columnIndex: 0,
      headers: [],
      numColumns: this.calculateNumColumns(parsedCsv),
      uploadResponse: null,
      uploadProgress: 0,
      uploadError: false,
    });
  }

  getCsvFile(): ?Object {
    return this.state.csvFile;
  }

  setHasHeaders(hasHeaders: boolean, fieldsToType: Object) {
    let headers = this.state.headers;
    let parsedCsv = this.state.parsedCsv;
    if (hasHeaders && parsedCsv && parsedCsv.data && parsedCsv.data[0]) {
      parsedCsv.data[0].map((header, index) => {
        let inputHeader = header.trim().toLowerCase();
        if (ROOT_FIELDS_WITH_SPACES[inputHeader]) {
          inputHeader = ROOT_FIELDS_WITH_SPACES[inputHeader];
        }
        if (fieldsToType[inputHeader]) {
          headers[index] = {
            value: inputHeader,
          };
        } else {
          headers[index] = {
            value: header,
            custom: true,
            type: HeaderTypeEnum.Text,
          };
        }
      });
    }

    this.setState({
      hasHeaders,
      headers,
      numColumns: headers.length,
    });
  }

  getHasHeaders() {
    return this.state.hasHeaders;
  }

  getParsed(): ?Object {
    return this.state.parsedCsv;
  }

  getColumnIndex(): number {
    return this.state.columnIndex;
  }

  setColumnIndex(columnIndex: number) {
    this.setState({
      columnIndex,
    });
  }

  getHeader(columnIndex: number): header {
    const header = this.state.headers[columnIndex];
    return this.state.headers[columnIndex] || {};
  }

  setCsvHeader(columnIndex: number, header: header) {
    this.updateState({
      headers: {
        [columnIndex]: {
          $set: header,
        },
      },
    });
  }

  getNumColumns(): number {
    return this.state.numColumns;
  }

  getHeaders(): header[] {
    return this.state.headers;
  }

  setUploadResponse(response: uploadResponse) {
    this.setState({
      uploadResponse: response,
    });
  }

  getUploadResponse(): uploadResponse {
    return this.state.uploadResponse;
  }

  beginUpload() {
    this.setState({
      uploadProgress: 1,
    });
  }

  setUploadProgress(uploadProgress: number) {
    this.setState({
      uploadProgress,
    });
  }

  getUploadProgress(): number {
    return this.state.uploadProgress;
  }

  setUploadError(error: boolean) {
    this.setState({
      uploadError: true,
    });
  }

  getUploadError(): boolean {
    return this.state.uploadError;
  }

  // Helpers
  calculateNumColumns(parsedCsv: Object) {
    if (parsedCsv && parsedCsv.data && parsedCsv.data[0]) {
      let numColumns = 0;
      let data = parsedCsv.data;
      for (let i = 0; i < data.length; i++) {
        numColumns = Math.max(numColumns, data[i].length);
      }
      return numColumns;
    } else {
      return 0;
    }
  }
}


// note: this is order sensitive for display on the users/edit page
// note: Keep this consistent with components/users/edit.jsx DEFAULT_TAGS
export const FIELD_TO_LABEL = {
  'first name': 'First Name',
  'last name': 'Last Name',
  'email': 'Email',
  'phone number': 'Phone Number',
  'start_date': 'Start Date',
  'end_date': 'End Date',
  'client': 'Client',
  'job title': 'Job Title',
  'bill rate': 'Bill Rate',
  'pay rate': 'Pay Rate',
  'recruiter': 'Recruiter',
  'hiring manager': 'Hiring Manager',
  'account manager': 'Account Manager',
  'audience_type': 'Audience Type',
  'full name': 'Full Name',
  'birth date': 'Birthday',
  'location': 'Location',
  'offer date': 'Offer Date',
};

export const ROOT_FIELDS_WITH_SPACES = {
  'start date': 'start_date',
  'end date': 'end_date',
};
