//@flow strict
import type {ReferralAclActions} from 'src/action-creators/referral-v2/referral-acl.js';
import {
  REFERRAL_USERS_PROGRAMS_ACCESS,
  REFERRAL_USERS_PROGRAMS_ACCESS_LOADING,
  REFERRAL_USERS_PROGRAMS_ACCESS_ERROR,
} from 'src/action-creators/referral-v2/referral-acl.js';
import type {AclResponse, GenericError} from 'src/types/referral-v2';

const initialState = {
  referral_users_program_acl: null,
  referral_users_program_acl_error: null,
};

type ReferralAclState = {
  current_state?: string,
  referral_users_program_acl?: ?AclResponse,
  referral_users_program_acl_error?: ?GenericError,
};

export const referralAclCurrentState = {
  IDLE: 'idle',
  FETCHING_REFERRAL_ACL: 'fetchingReferralAcl',
};

export default (
  state: ReferralAclState = initialState,
  action: ReferralAclActions,
): ReferralAclState => {
  switch (action.type) {
    case REFERRAL_USERS_PROGRAMS_ACCESS:
      return {
        ...state,
        referral_users_program_acl: action.payload,
        current_state: referralAclCurrentState.IDLE,
      };
    case REFERRAL_USERS_PROGRAMS_ACCESS_LOADING:
      return {
        ...state,
        current_state: referralAclCurrentState.FETCHING_REFERRAL_ACL,
      };
    case REFERRAL_USERS_PROGRAMS_ACCESS_ERROR:
      return {
        ...state,
        referral_users_program_acl_error: action.payload,
        current_state: referralAclCurrentState.IDLE,
      };
    default:
      return {...state};
  }
};
