// @flow strict

import type {
  GenericError,
  EarningsData,
  PaymentDetails,
  EarningsGraphData,
} from 'src/types/referrer-portal';
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import {ApiError} from 'src/utils/redux-api-v2';
import {ERROR_CODE} from 'src/utils/referrer-portal';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-referrer-portal.js';
import {toastMessage} from 'src/components/referral-v2/tracking/referral-confirm-modal.jsx';
import {toastApi} from '@spaced-out/ui-design-system/lib/components/index';


export const RECEIVE_EARNINGS = 'receive/earnings';
export const RECEIVE_EARNINGS_ERROR = 'receive/earnings-error';

export const RECEIVE_PAYMENTS_DETAILS = 'receive/payment-details';
export const RECEIVE_PAYMENTS_DETAILS_ERROR = 'receive/payment-details-error';

export const RECEIVE_EARNINGS_GRAPH_DATA = 'receive/earnings-graph-data';
export const RECEIVE_EARNINGS_GRAPH_DATA_ERROR =
  'receive/earnings-graph-data-error';

type UserEarningsAction = {
  type: typeof RECEIVE_EARNINGS,
  payload: EarningsData,
};

type UserEarningsErrorAction = {
  type: typeof RECEIVE_EARNINGS_ERROR,
  payload?: ?GenericError,
};

type PaymentDetailsAction = {
  type: typeof RECEIVE_PAYMENTS_DETAILS,
  payload: EarningsGraphData,
};

type PaymentDetailsErrorAction = {
  type: typeof RECEIVE_PAYMENTS_DETAILS_ERROR,
  payload?: ?GenericError,
};

type EarningsGraphDataAction = {
  type: typeof RECEIVE_EARNINGS_GRAPH_DATA,
  payload?: ?EarningsGraphData,
};

type EarningsGraphDataErrorAction = {
  type: typeof RECEIVE_EARNINGS_GRAPH_DATA_ERROR,
  payload?: ?GenericError,
};

export type EarningsActions =
  | UserEarningsAction
  | UserEarningsErrorAction
  | PaymentDetailsAction
  | PaymentDetailsErrorAction
  | EarningsGraphDataAction
  | EarningsGraphDataErrorAction;

export const receiveUserEarningsError = (
  payload: GenericError,
): UserEarningsErrorAction => ({
  type: RECEIVE_EARNINGS_ERROR,
  payload,
});

export const receivePaymentDetailsError = (
  payload: GenericError,
): PaymentDetailsErrorAction => ({
  type: RECEIVE_PAYMENTS_DETAILS_ERROR,
  payload,
});

export const receiveEarningsGraphDataError = (
  payload: GenericError,
): EarningsGraphDataErrorAction => ({
  type: RECEIVE_EARNINGS_GRAPH_DATA_ERROR,
  payload,
});

export const getUserEarnings: (query: {
  start_date: string,
  end_date: string,
}) => ThunkAction<void> = flow(
  key(() => `earnings`),
  fetching(),
)((query) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.get(
      `earnings?start_date=${query.start_date}&end_date=${query.end_date}`,
    ),
  ).then(
    (response: EarningsData) => {
      return dispatch({type: RECEIVE_EARNINGS, payload: response});
    },
    (error) => {
      if (error?.responseBody?.errors) {
        dispatch(receiveUserEarningsError(error.responseBody.errors));
      }
    },
  );
});

export const getPaymentDetails: () => ThunkAction<void> = flow(
  key(() => `user/payment-details`),
  fetching(),
)(() => (dispatch: Dispatch) => {
  return dispatch(reduxApi.get(`user/payment-details`)).then(
    (response: PaymentDetails[]) => {
      return dispatch({type: RECEIVE_PAYMENTS_DETAILS, payload: response});
    },
    (error) => {
      if (error?.responseBody?.errors) {
        dispatch(receivePaymentDetailsError(error.responseBody.errors));
      }
    },
  );
});

export const updatePaymentDetails: (
  paymentData: PaymentDetails,
) => ThunkAction<void> = (paymentData) => (dispatch: Dispatch) => {
  return dispatch(reduxApi.put(`user/payment-details`, paymentData))
    .then((response) => {
      if (response) {
        dispatch(getPaymentDetails());
        toastApi.show(
          toastMessage({
            message: 'Payment details updated successfully',
          }),
        );
      }
      return response;
    })
    .catch((error) => {
      if (error instanceof ApiError) {
        const apiError = error?.responseBody?.errors[0];
        toastApi.show(
          toastMessage({
            message: ERROR_CODE[apiError] ?? 'Payment details not updated',
            semantic: 'danger',
          }),
        );
      }
    });
};

export const getEarningsGraphData: (query: {
  start_date: string,
  end_date: string,
}) => ThunkAction<void> = flow(
  key(() => `earnings/median`),
  fetching(),
)((query) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.get(
      `earnings/median?start_date=${query.start_date}&end_date=${query.end_date}`,
    ),
  ).then(
    (response: EarningsGraphData) => {
      return dispatch({type: RECEIVE_EARNINGS_GRAPH_DATA, payload: response});
    },
    (error) => {
      if (error?.responseBody?.errors) {
        dispatch(receiveEarningsGraphDataError(error.responseBody.errors));
      }
    },
  );
});
