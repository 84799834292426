// @flow strict

import type {
  GetReferralTrackingApiResponse,
  ReferralTrackingPayload,
  ReferralPrograms,
  GenericError,
} from 'src/types/referral-v2';
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-referrer-portal.js';
import {toastMessage} from 'src/components/referral-v2/tracking/referral-confirm-modal.jsx';
import {toastApi} from '@spaced-out/ui-design-system/lib/components/Toast';
import {DEFAULT_API_ERROR} from 'src/utils/referrer-portal';


export const RECEIVE_ALL_REFERRALS = 'receive/all-referrals';
export const RECEIVE_ALL_REFERRALS_ERROR = 'receive/all-referrals-error';

export const RECEIVE_ALL_REFERRAL_PROGRAMS = 'receive/all-referral-programs';
export const RECEIVE_ALL_REFERRAL_PROGRAMS_ERROR =
  'receive/all-referral-programs-error';

type ReceiveReferralsAction = {
  type: typeof RECEIVE_ALL_REFERRALS,
  payload: GetReferralTrackingApiResponse,
};

type ReceiveReferralsErrorAction = {
  type: typeof RECEIVE_ALL_REFERRALS_ERROR,
  payload?: ?GenericError,
};

type ReceiveAllReferralProgramsAction = {
  type: typeof RECEIVE_ALL_REFERRAL_PROGRAMS,
  payload: GetReferralTrackingApiResponse,
};

type ReceiveAllReferralProgramsErrorAction = {
  type: typeof RECEIVE_ALL_REFERRAL_PROGRAMS_ERROR,
  payload?: ?GenericError,
};

export type ReferralsActions =
  | ReceiveReferralsAction
  | ReceiveReferralsErrorAction
  | ReceiveAllReferralProgramsAction
  | ReceiveAllReferralProgramsErrorAction;

export const receiveReferralsError = (
  payload: GenericError,
): ReceiveReferralsErrorAction => ({
  type: RECEIVE_ALL_REFERRALS_ERROR,
  payload,
});

export const receiveAllReferralProgramsError = (
  payload: GenericError,
): ReceiveAllReferralProgramsErrorAction => ({
  type: RECEIVE_ALL_REFERRAL_PROGRAMS_ERROR,
  payload,
});

export const getAllReferralsDetails: (
  payload: ReferralTrackingPayload,
  isLead?: boolean,
) => ThunkAction<void> = flow(
  key((payload, isLead = false) => `${isLead ? 'requests' : 'tracking'}`),
  fetching(),
)((payload, isLead = false) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.post(`${isLead ? 'requests' : 'tracking'}`, payload),
  ).then(
    (response: GetReferralTrackingApiResponse) => {
      dispatch({
        type: RECEIVE_ALL_REFERRALS,
        payload: response,
      });
    },
    (error) => {
      dispatch(
        receiveReferralsError(error?.response?.errors || DEFAULT_API_ERROR),
      );
    },
  );
});

export const getAllReferralsPrograms: (agencyId: string) => ThunkAction<void> =
  flow(
    key(() => `agency/<string:agency_id>/programs`),
    fetching(),
  )((agencyId) => (dispatch: Dispatch) => {
    return dispatch(reduxApi.get(`agency/${agencyId}/programs`)).then(
      (response: ReferralPrograms[]) => {
        dispatch({
          type: RECEIVE_ALL_REFERRAL_PROGRAMS,
          payload: response,
        });
      },
      (error) => {
        dispatch(
          receiveAllReferralProgramsError(
            error?.response?.errors || DEFAULT_API_ERROR,
          ),
        );
      },
    );
  });

export const getResumeLink: (resume: string) => ThunkAction<void> =
  (resume) => (dispatch: Dispatch) => {
    return dispatch(reduxApi.get(`requests/resume?file_path=${resume}`))
      .then((resumeUrl) => {
        const fileExtension = resume.split('.')[1];
        const a = document.createElement('a');
        a.href = resumeUrl;
        a.download = `resume.${fileExtension ?? 'pdf'}`;
        // $FlowFixMe
        document.body.appendChild(a);
        a.click();
        // $FlowFixMe
        document.body.removeChild(a);
        toastMessage({
          message: 'Successfully Downloaded',
        });
      })
      .catch((error) => {
        toastApi.show(
          toastMessage({
            message: error?.responseBody?.message,
            semantic: 'danger',
          }),
        );
      });
  };
