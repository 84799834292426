// @flow
import type {EntitySummary} from 'src/types/entity-summaries.snake';
import type {EntityType} from 'src/types/ats-entities';
import type {Filters, Status, AutomationType} from 'src/types/audit';
import type {AttributeMetaData} from 'src/types/writeback';
import type {ThunkAction} from 'src/reducers';
import {
  checkAnalyticsAuditV3Enabled,
  ANALYTICS_API_V3,
} from 'src/utils/analytics-api-migration';

import {thunkify as flow} from 'src/utils/thunks';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api.js';


export const RECEIVE_AUDIT = 'RECEIVE_AUDIT';
export const RECEIVE_WRITEBACK_ATTRIBUTES = 'RECEIVE_WRITEBACK_ATTRIBUTES';

export type AuditEntry = {
  automation_type: AutomationType,
  date_attempted: string,
  entity_summary: EntitySummary,
  entity_type: EntityType,
  event_id: string,
  event_name: string,
  field: string,
  new_value: string | number | null,
  old_value: string | number | null,
  status: Status,
  workflow_id: string,
  workflow_name: string,
  flow_id: string,
  flow_name: string,
};

export type AuditData = {
  page?: number,
  records_per_page?: number,
  total_pages?: number,
  total_records?: number,
  records?: AuditEntry[],
  ...
};

export type ReceiveAuditEntriesAction = {
  type: 'RECEIVE_AUDIT',
  payload: {
    data: AuditData,
    pageNumber: string,
  },
};

export type ReceiveWritebackAttributesAction = {
  type: 'RECEIVE_WRITEBACK_ATTRIBUTES',
  payload: {[EntityType]: AttributeMetaData[], ...},
};

export type AuditActions =
  | ReceiveAuditEntriesAction
  | ReceiveWritebackAttributesAction;

export const receiveAuditEntries = (
  data: AuditData,
  pageNumber: string,
): ReceiveAuditEntriesAction => ({
  type: RECEIVE_AUDIT,
  payload: {
    data,
    pageNumber,
  },
});

export const receiveWritebackAttributes = (data: {
  [EntityType]: AttributeMetaData[],
  ...
}): ReceiveWritebackAttributesAction => ({
  type: RECEIVE_WRITEBACK_ATTRIBUTES,
  payload: data,
});

export const getAuditEntries: (
  pageNumber: string,
  filters: Filters,
) => ThunkAction<mixed> = flow(
  key(
    (pageNumber: string, filters: Filters) =>
      `writeback-audit-entries-page${pageNumber}?${JSON.stringify(filters)}`,
  ),
  cached(
    (json, pageNumber: string, filters: Filters) =>
      receiveAuditEntries(json, pageNumber),
    {
      ttl: 3000, //TODO: cache audit entries with filters better
      hash: (pageNumber, filters) =>
        `writeback-audit-entries-page${pageNumber}?${JSON.stringify(filters)}`,
    },
  ),
  fetching(),
)((pageNumber: string, filters: Filters) =>
  reduxApi.post(
    `/audit?page=${pageNumber}`,
    filters || {},
    {},
    {
      apiPath: checkAnalyticsAuditV3Enabled()
        ? ANALYTICS_API_V3
        : '/api/v1/bulk-writeback',
    },
  ),
);

export const getWritebackAttributes: () => ThunkAction<mixed> = flow(
  key(() => `writeback-attributes`),
  cached((json) => receiveWritebackAttributes(json), {
    hash: () => `writeback-attributes`,
  }),
  fetching(),
)(() => reduxApi.get(`field-writeback/attributes`));
