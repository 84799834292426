// @flow strict

import type {EmailState} from 'src/reducers/email';
import type {
  // $FlowFixMe[nonstrict-import]
  DynamicLabelExtra as DynamicLabel,
  // $FlowFixMe[nonstrict-import]
  DynamicLabels,
} from 'src/types/dynamic-labels';
import type {MenuGroupTitleOption} from '@spaced-out/ui-design-system/lib';
import type {EntityType} from 'src/types/ats-entities';
import groupBy from 'lodash/groupBy';
import type {
  Configs,
  CompleteEmailState,
  Validations,
  NormalizedEmail,
} from 'src/types/email';
import type {
  EntityGroupApi,
  EntityMappingsByEntityType,
  IncomingRelatedEntity,
  StandardEntity,
} from 'src/types/target-entity-types';

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import omit from 'lodash/omit';


export const SUPPORTED_CHATBOT_USECASES = ['data_enrichment', 'prescreening'];
export const VALID_ATTACHMENTS = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'application/octet-stream': [
    '.pdf',
    '.ppt',
    '.pptx',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.odt',
    '.rtf',
  ],
  'text/plain': ['.txt'],
  'application/vnd.ms-excel': ['.csv'], //Windows recognizes .csv files as a different type (https://github.com/react-dropzone/react-dropzone/issues/276)
  'text/csv': ['.csv'],
};

export const MAX_EMAIL_ATTACHMENTS_SIZE = 10 * 1024 * 1024;

export const normalizeEmail = (
  emailState: EmailState,
  isPreview: ?boolean = false,
): NormalizedEmail => {
  const content = {
    type: 'BEEFREE',
    content_json: emailState.content_json,
    content_html: emailState.content_html,
  };
  const from = {
    name: emailState.from_name,
    email: emailState.from_email,
  };
  const to = {
    name: null,
    email: emailState.to_email,
  };

  const cc = emailState.cc.map((email) => ({name: null, email}));
  const bcc = emailState.bcc.map((email) => ({name: null, email}));
  const content_subscription = {
    category_id: emailState.content_subscription_category_id,
    reason: emailState.content_subscription_reason,
    skip_subscription_link: emailState.skip_subscription_link,
  };

  const attachments = (emailState.attachments ?? []).map(
    ({file_name, id, size, value, type}) => ({
      file_name,
      id,
      size,
      value,
      type,
    }),
  );
  return {
    type: 'email_send',
    id: emailState.id,
    task_group_id: emailState.task_group_id,
    params: {
      task_definition: {
        content,
        subject: emailState.subject,
        from,
        to,
        cc,
        bcc,
        content_subscription,
        attachments,
      },
    },
  };
};

export const parseEmail = (emailState: CompleteEmailState): EmailState => {
  const emailSendTask = emailState.body.tasks.find(
    (task) => task.type === 'email_send',
  );
  const chatbotsAttached = emailState.body.tasks.reduce(
    (acc, {type, params}) => {
      if (type === 'chatbot_trigger' && params.flow_id) {
        acc.push(params.flow_id);
      }
      return acc;
    },
    [],
  );
  if (!emailSendTask) {
    throw new Error('Email task not found');
  }
  if (emailSendTask.type !== 'email_send') {
    throw new Error('Email task not found');
  }
  const taskDefinition = emailSendTask.params.task_definition;
  if (!taskDefinition) {
    throw new Error('Email task definition not found');
  }
  return {
    id: emailSendTask.id,
    task_group_id: emailState.task_id,
    subject: taskDefinition.subject,
    content_json: taskDefinition.content.content_json,
    content_html: taskDefinition.content.content_html,
    from_name: taskDefinition.from.name,
    from_email: taskDefinition.from.email,
    to_email: taskDefinition.to.email,
    cc: taskDefinition.cc.map((item) => item.email),
    bcc: taskDefinition.bcc.map((item) => item.email),
    content_subscription_category_id:
      taskDefinition.content_subscription.category_id,
    content_subscription_reason: taskDefinition.content_subscription.reason,
    skip_subscription_link:
      taskDefinition.content_subscription.skip_subscription_link,
    completeEmailState: emailState,
    attachments:
      taskDefinition.attachments?.map((attachment) => ({
        ...attachment,
        reject: false,
        rejectReason: '',
        progress: false,
      })) ?? [],
    chatbotsAttached,
  };
};
export const getVariableOptions = (
  dynamicLabels: DynamicLabels,
): Array<MenuGroupTitleOption> => {
  const options = [];
  const variableMap = groupBy(dynamicLabels, (dl) => dl.mapping.display_name);
  for (const [key, value] of Object.entries(variableMap)) {
    const optionsObj = {
      groupTitle: key,
      //$FlowFixMe Todo(Vish)
      options: value.map(({resolvedId, label}) => ({key: resolvedId, label})),
    };
    options.push(optionsObj);
  }
  return options;
};
export const getNodeConfigs = (
  values: Array<string>,
  dynamicLabels: DynamicLabels,
  entityType: string,
  configs: Configs,
): Configs => {
  const rteOptionMap = dynamicLabels.reduce((acc, option) => {
    acc[option.rteValue] = option;
    return acc;
  }, {});
  const localConfig = cloneDeep(configs);
  values.forEach((rteValue) => {
    if (rteOptionMap[rteValue]) {
      const key = rteOptionMap[rteValue].value;
      const relationArray = key ? key.split('/') : [];
      if (relationArray.length > 0) {
        relationArray.unshift(entityType);
        localConfig.dynamic_variables[key] = relationArray;
      }
    }
  });
  return !isEmpty(localConfig) ? localConfig : {dynamic_variables: {}};
};

export const extractRTELabelsFromString = (
  inputString: ?string,
): ?Array<string> => {
  try {
    const regex = /{{(.*?)}}/g;
    const matches = inputString && inputString.match(regex);
    return matches ? matches : [];
  } catch (e) {
    console.error('Invalid JSON Input', e.message);
  }
};

const getAttributeNameFromValue = (value): string =>
  value ? value.split('/').pop() : value;

export const generateDynamicLabelConfig = (
  labels: Array<string>,
  dynamicLabels: DynamicLabels,
  baseEntity: string,
): {...} => {
  const config = {};
  const valueOptionMap = dynamicLabels.reduce((acc, option) => {
    acc[option.value] = option;
    return acc;
  }, {});
  labels.forEach((label) => {
    const param = {};
    const relationArray = label ? label.split('/') : [];
    const fieldValue = label;
    if (relationArray.length > 0) {
      param.from_relations = relationArray.slice(0, -1);
      param.attribute_name = getAttributeNameFromValue(fieldValue);
      param.entity_type = valueOptionMap[label].entity_type;
      param.base_entity_type = baseEntity;
    }
    config[fieldValue] = param;
  });
  return config;
};

export const getEmsFetchDynamicLabels = (
  emailState: EmailState,
  dynamicLabels: DynamicLabels,
  isPreview: ?boolean = false,
): Array<string> => {
  const emsDynamicLabels = [];
  function populateEmsDynLabelsArray(rteValue) {
    if (rteValue && rteOptionMap[rteValue]) {
      emsDynamicLabels.push(rteOptionMap[rteValue].value);
    }
  }
  const rteOptionMap = dynamicLabels.reduce((acc, option) => {
    acc[option.rteValue] = option;
    return acc;
  }, {});
  extractRTELabelsFromString(emailState.content_json)?.forEach(
    populateEmsDynLabelsArray,
  );
  if (!isPreview) {
    emailState.cc.forEach(populateEmsDynLabelsArray);
    emailState.bcc.forEach(populateEmsDynLabelsArray);
    populateEmsDynLabelsArray(emailState.from_email);
    populateEmsDynLabelsArray(emailState.to_email);
    extractRTELabelsFromString(emailState.from_name)?.forEach(
      populateEmsDynLabelsArray,
    );
    extractRTELabelsFromString(emailState.subject)?.forEach(
      populateEmsDynLabelsArray,
    );
  }
  return uniq(emsDynamicLabels);
};
export const getUsedEmsDynamicLabels = (
  emailState: EmailState,
  dynamicLabels: DynamicLabels,
  isPreview: ?boolean = false,
): Map<string, DynamicLabel> => {
  const usedEmsDynamicLabels = new Map<string, DynamicLabel>();
  const rteOptionMap = dynamicLabels.reduce((acc, option) => {
    acc[option.rteValue] = option;
    return acc;
  }, {});
  function populateEmsDynLabels(rteValue) {
    if (rteValue && rteOptionMap[rteValue]) {
      usedEmsDynamicLabels.set(rteValue, rteOptionMap[rteValue]);
    }
  }

  extractRTELabelsFromString(emailState.content_json)?.forEach(
    populateEmsDynLabels,
  );
  if (!isPreview) {
    emailState.cc.forEach(populateEmsDynLabels);
    emailState.bcc.forEach(populateEmsDynLabels);
    populateEmsDynLabels(emailState.from_email);
    populateEmsDynLabels(emailState.to_email);
    extractRTELabelsFromString(emailState.from_name)?.forEach(
      populateEmsDynLabels,
    );
    extractRTELabelsFromString(emailState.subject)?.forEach(
      populateEmsDynLabels,
    );
  }
  return usedEmsDynamicLabels;
};

export const normalizeEmailEdit = (
  emailState: EmailState,
  isPreview?: boolean = false,
): ?NormalizedEmail => {
  const {completeEmailState: existingApiObject, ...restEmailState} = emailState;
  const editableFields = normalizeEmail(restEmailState);
  //we need to send the "email_send" task_definition_id while updating

  if (existingApiObject) {
    const existingEmailSendTask = existingApiObject.body.tasks.find(
      (task) => task.type === 'email_send',
    );
    if (existingEmailSendTask) {
      editableFields.params.task_definition_id =
        existingEmailSendTask.params.task_definition_id;
    }
  }
  if (!editableFields.params.task_definition_id) {
    throw 'can not update task because task_definition_id is missing';
  }
  return editableFields;
};

export const generateChatbotConfig = (emailState: EmailState): {...} => {
  let rows = [];
  if (emailState.content_json) {
    try {
      rows = JSON.parse(emailState.content_json)?.page?.rows ?? [];
    } catch (ex) {}
  }
  const params = (rows ?? [])
    .filter((row) => rowContainsChatbot(row))
    .map((row) => row.metadata)
    .reduce((acc, metadata) => {
      const chatbot = omit(metadata, 'chatbotType');
      return {...acc, ...chatbot};
    }, {});

  return params;
};

export const rowContainsChatbot = (row: {
  rowInternal: {uid: string, ...},
  columns: Array<{modules: Array<{...}>, ...}>,
}): boolean => {
  if (!row.rowInternal) {
    return false;
  }
  if (row.rowInternal.uid !== 'sense-chatbot-row') {
    return false;
  }
  if (row.columns.length !== 1) {
    return false;
  }
  if (row.columns[0].modules.length === 0) {
    return false;
  }
  return true;
};
export const getErrors = (validationsObject: Validations): Array<string> => {
  const errors = [];
  const {
    senderNameEmpty,
    senderEmailEmpty,
    emailSubjectEmpty,
    emailSubjectTooLong,
    sendToEmailEmpty,
    contentSubEmpty,
    sameFromToEmail,
    invalidFromEmail,
    invalidToEmail,
  } = validationsObject;
  if (
    senderNameEmpty ||
    senderEmailEmpty ||
    emailSubjectEmpty ||
    sendToEmailEmpty ||
    contentSubEmpty
  ) {
    errors.push('Please fill the required fields.');
  }
  if (sameFromToEmail) {
    errors.push('Sender Email cannot be same as Receiver Email.');
  }
  if (invalidFromEmail) {
    errors.push('Sender Email is Invalid.');
  }
  if (invalidToEmail) {
    errors.push('Receiver Email is Invalid.');
  }

  if (emailSubjectTooLong) {
    errors.push('Subject text is too long.');
  }
  return errors;
};

const flattenEntityGroup = (
  entityGroupData: {...},
  parentKey?: string = '',
): {...} => {
  const result = {};
  for (const key in entityGroupData) {
    const newKey = parentKey ? `${parentKey}/${key}` : key;
    result[newKey] = entityGroupData[key];
  }
  return result;
};

export const getAttributeDataFromEntityGroup = (
  entityGroup: EntityGroupApi,
  parentKey?: string = '',
): {...} => {
  let result = {};
  for (const key in entityGroup.relatedEntities) {
    const combinedKey = parentKey ? `${parentKey}/${key}` : key;
    result = {
      ...result,
      ...getAttributeDataFromEntityGroup(
        entityGroup.relatedEntities[key],
        combinedKey,
      ),
    };
  }
  result = {
    ...result,
    ...flattenEntityGroup(entityGroup.entity.data, parentKey),
  };
  return result;
};

export const getIncomingRelatedEntityGroupIds = (
  incomingRelatedEntities: Array<IncomingRelatedEntity>,
  standardWorkflowEntity: StandardEntity,
): Array<{key: string, label: string}> => {
  const targetEntityGroup = incomingRelatedEntities.find(
    (incomingEntity) =>
      incomingEntity.standard_entity_type === standardWorkflowEntity,
  );
  if (targetEntityGroup) {
    return targetEntityGroup.incoming_related_entity_groups.map(({entity}) => {
      return {
        key: entity.id,
        //Todo(Vish): We need to append a display name before externalSourceId here. Discuss with Backend
        label: `ID: ${entity.externalSourceId}`,
      };
    });
  }
  return [];
};

/**
 * sorts variables so that event variables that do not exist for the person entity or related entity are at the top,
 * followed by event variables that exist but has no value,
 * and finally event variables with values
 */
export const getSortedEventVariables = ({
  flattenedEntityVariables,
  uniqueEntityVariablesInEvent,
  dynamicLabelsByValue,
}: {
  flattenedEntityVariables: {[string]: mixed},
  uniqueEntityVariablesInEvent: Array<string>,
  dynamicLabelsByValue: {[string]: DynamicLabel},
}): Array<DynamicLabel> => {
  const variablesWithValues = [];
  const nonexistantVariables = [];
  const variablesWithEmptyFields = [];

  for (const variable of uniqueEntityVariablesInEvent) {
    const entityValue = variable && flattenedEntityVariables[variable];
    if (!dynamicLabelsByValue[variable]) {
      continue;
      //do not include variables not in the dynamicLabels map
    } else if (entityValue === null || entityValue === '') {
      variablesWithEmptyFields.push({
        ...dynamicLabelsByValue[variable],
        entityValue: '(field empty)',
      });
    } else if (entityValue === undefined) {
      nonexistantVariables.push({
        ...dynamicLabelsByValue[variable],
        entityValue: '(does not exist)',
      });
    } else {
      variablesWithValues.push({
        ...dynamicLabelsByValue[variable],
        entityValue,
      });
    }
  }

  //concating three arrays to ensure the correct order based on variable value state
  return [
    ...nonexistantVariables,
    ...variablesWithEmptyFields,
    ...variablesWithValues,
  ];
};

export const getTotalChatbotsFromBeefreeJSON = (content: string): number => {
  if (content === '{}') {
    return 0;
  }
  const contentJSON = JSON.parse(content);
  const emailRows = contentJSON?.page?.rows;
  const chatbotCount = emailRows.reduce((chatbotCount, row) => {
    if (row.rowInternal && row.rowInternal.uid === 'sense-chatbot-row') {
      return row?.metadata && !isEmpty(row.metadata)
        ? chatbotCount + 1
        : chatbotCount;
    }
    return chatbotCount;
  }, 0);
  return chatbotCount;
};

export const validateEmailBeefreeJson = (content: string): ?string => {
  if (content === '{}') {
    return 'No content found in the email';
  }
  const contentJSON = JSON.parse(content);
  const emailRows = contentJSON?.page?.rows;

  //check if no placeholder content is present for chatbot,
  // if yes, don't let the user save the content
  const containsChatbotPlaceholder = emailRows.some((row) => {
    if (row.rowInternal && row.rowInternal.uid === 'sense-chatbot-row') {
      return row.empty;
    }
    return false;
  });

  if (containsChatbotPlaceholder) {
    return 'Configure the chatbot add-on to save the design';
  }
  return null;
};

export const cleanupEmailBeefreeJson = (contentJSON: string): string => {
  if (contentJSON === '{}') {
    return contentJSON;
  }
  const content = JSON.parse(contentJSON);
  const emailRows = content?.page?.rows;
  /** NOTE(diwakersurya) below code removes the empty chatbot-addon-rows.
   * this solves the issue when user remove chatbot addon row content but does not
   * remove the row itself. this can be better solved if beefree provides a way
   * to prevent the deletion of content through the ui. We should only allow the
   * deletion of addon row itself along with its content and not the content
   * separately
   */
  if (emailRows) {
    const cleanedEmailRows = emailRows.filter((row) => {
      if (row.rowInternal && row.rowInternal.uid === 'sense-chatbot-row') {
        //currently chatbot row has only one column
        //get the first column
        const firstColumn = row.columns?.[0];
        if (!firstColumn) {
          return false;
        }
        // if there are no modules, it means, there is no content in the column
        // hence this row should be removed
        return firstColumn.modules.length > 0;
      }
      return true;
    });
    content.page.rows = cleanedEmailRows;
  }
  return JSON.stringify(content);
};

export const checkForCandidateStandardEntity = (
  entityType: ?EntityType,
  entityMappings: EntityMappingsByEntityType,
): boolean => {
  if (entityType && entityMappings) {
    const entity = entityMappings[entityType];
    if (entity.standard_entity_type === 'candidate') {
      return true;
    }
    return (
      entity.related_entities.some(
        ({entityType}) =>
          entityMappings[entityType].standard_entity_type === 'candidate',
      ) ?? false
    );
  }
  return false;
};
