// @flow strict
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api.js';
import type {
  Tag,
  ExternallyAccessibleFields,
  GenerateContentParams,
} from 'src/reducers/sense-ai';

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';


export const SET_SHOW_AI_PANEL = 'sense-ai/setShowAiPanel';
export const SET_ERROR = 'sense-ai/setError';
export const SET_SHOW_CUSTOM_COMPONENT = 'sense-ai/setShowCustomComponent';
export const SET_PROMPT = 'sense-ai/setPrompt';
export const SET_GENERATED_TEXT = 'sense-ai/setGeneratedText';
export const ADD_TAG = 'sense-ai/addTag';
export const REMOVE_TAG = 'sense-ai/removeTag';
export const RESET_SELECTED_TAGS = 'sense-ai/resetSelectedTags';
export const RECEIVE_ALL_TAGS = 'sense-ai/receiveAllTags';
export const SET_EXTERNALLY_ACCESSIBLE_FIELDS =
  'sense-ai/setExternallyAccessibleFields';
export const GENERATE_CONTENT = 'sense-ai/generateContent';

export type SenseAiActions =
  | SetShowAiPanelAction
  | SetShowCustomComponentAction
  | SetPromptAction
  | SetGeneratedTextAction
  | AddTagAction
  | RemoveTagAction
  | ResetSelectedTagsAction
  | ReceiveAllTagsAction
  | SetExternallyAccessibleFieldsAction
  | SetErrorAction;

export type SetShowAiPanelAction = {
  type: typeof SET_SHOW_AI_PANEL,
  payload: boolean,
};

export type SetShowCustomComponentAction = {
  type: typeof SET_SHOW_CUSTOM_COMPONENT,
  payload: boolean,
};

export type SetErrorAction = {
  type: typeof SET_ERROR,
  payload: string,
};

export type SetPromptAction = {
  type: typeof SET_PROMPT,
  payload: string,
};

export type SetGeneratedTextAction = {
  type: typeof SET_GENERATED_TEXT,
  payload: string,
};

export type AddTagAction = {
  type: typeof ADD_TAG,
  payload: string,
};

export type RemoveTagAction = {
  type: typeof REMOVE_TAG,
  payload: string,
};
export type ResetSelectedTagsAction = {
  type: typeof RESET_SELECTED_TAGS,
};

export type ReceiveAllTagsAction = {
  type: typeof RECEIVE_ALL_TAGS,
  payload: Tag[],
};

export type SetExternallyAccessibleFieldsAction = {
  type: typeof SET_EXTERNALLY_ACCESSIBLE_FIELDS,
  payload: ExternallyAccessibleFields,
};
export const setShowAiPanel = (payload: boolean): SetShowAiPanelAction => ({
  type: SET_SHOW_AI_PANEL,
  payload,
});

export const setError = (payload: string): SetErrorAction => ({
  type: SET_ERROR,
  payload,
});

export const setShowCustomComponent = (
  payload: boolean,
): SetShowCustomComponentAction => ({
  type: SET_SHOW_CUSTOM_COMPONENT,
  payload,
});

export const setPrompt = (payload: string): SetPromptAction => ({
  type: SET_PROMPT,
  payload,
});

export const setGeneratedText = (payload: string): SetGeneratedTextAction => ({
  type: SET_GENERATED_TEXT,
  payload,
});

export const addTag = (payload: string): AddTagAction => ({
  type: ADD_TAG,
  payload,
});
export const removeTag = (payload: string): RemoveTagAction => ({
  type: REMOVE_TAG,
  payload,
});

export const resetSelectedTags = (): ResetSelectedTagsAction => ({
  type: RESET_SELECTED_TAGS,
});
export const receiveAllTags = (payload: Tag[]): ReceiveAllTagsAction => ({
  type: RECEIVE_ALL_TAGS,
  payload,
});

export const setExternallyAccessibleFields = (
  payload: ExternallyAccessibleFields,
): SetExternallyAccessibleFieldsAction => ({
  type: SET_EXTERNALLY_ACCESSIBLE_FIELDS,
  payload,
});

//may be should use some parameter for key calculation
export const generateContent: (string, payload?: {...}) => ThunkAction<mixed> =
  flow(
    key((url) => `generateContent-${url}`),
    fetching(),
  )((url, payload) => async (dispatch, getState) => {
    const {prompt, selectedTags} = getState().senseAi;
    const data = {
      ...payload,
      prompt,
      //TODO:(diwakersurya) fix this
      //$FlowFixMe
      ...(url.toLowerCase().includes('workflow')
        ? {tags: selectedTags}
        : {selected_tags: selectedTags}),
    };
    dispatch(setError(''));

    return dispatch(reduxApi.post(url, data)).then(
      // $FlowIssue object values
      (response: {generated_text: string}) => {
        dispatch(setGeneratedText(response.generated_text));
        dispatch(resetSelectedTags());
        // $FlowFixMe[incompatible-type-arg]
        return response;
      },
      (error: string) => {
        // $FlowIssue object values
        dispatch(setError(error?.response?.errors?.[0]));
      },
    );
  });
