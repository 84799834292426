// @noflow

import * as React from 'react';
import clamp from 'lodash/clamp';
import pick from 'lodash/pick';

import SliderHandle from 'src/images/slider-handle.svg';

import {classify} from 'src/utils';

import css from './slider.css';


type Props = {
  className?: string,
  min: number,
  max: number,
  start?: number,
  end?: number,
  defaultStart?: number,
  defaultEnd?: number,
  disabled: boolean,
  step: number,
  title?: string,
  valueFormatter: (value: number) => string,
  unit: string,
  onChange?: (values: {start: number, end: number}) => void,
  step?: number,
};

type State = {
  startValue?: number,
  endValue?: number,
  start: number,
  end: number,
  movingStart: boolean,
  movingEnd: boolean,
};

// NOTE(elliot): 'start' and 'end' are arbitrary while moving.
class Slider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      start: props.start || props.defaultStart || props.min,
      end: props.end || props.defaultEnd || props.max,
      movingStart: false,
      movingEnd: false,

      // @NOTE(elliot): Initial props to compare so this component can be controlled by props.
      startValue: props.start,
      endValue: props.end,
    };
  }

  static defaultProps = {
    valueFormatter: String,
    unit: '',
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (
      (nextProps.start !== undefined &&
        nextProps.start !== prevState.startValue) ||
      (nextProps.end !== undefined && nextProps.end !== prevState.endValue)
    ) {
      return {
        start: nextProps.start,
        end: nextProps.end,
        startValue: nextProps.start,
        endValue: nextProps.end,
      };
    }
    return null;
  }

  componentWillUnmount() {
    this.removeMouseMove();
    this.removeMouseUp();
  }

  node: React.Element<*>;
  trackNode: React.Element<*>;

  handleMouseDown = (type: 'start' | 'end') => () => {
    const stateKey = type === 'start' ? 'movingStart' : 'movingEnd';
    this.setState({[stateKey]: true}, () => {
      this.addMouseMove();
      this.addMouseUp();
    });
  };

  handleStartMouseDown = this.handleMouseDown('start');
  handleEndMouseDown = this.handleMouseDown('end');

  getTrackClientRect = (): ClientRect => this.trackNode.getClientRect();

  handleMouseMove = (e: SyntheticMouseEvent<*>) => {
    if (this.state.movingStart || this.state.movingEnd) {
      const clientRect = this.getTrackClientRect();
      const value = getValueFromEvent(
        e,
        clientRect,
        this.props.min,
        this.props.max,
      );
      const stateKey = this.state.movingStart ? 'start' : 'end';
      this.setState({[stateKey]: value});
    }
  };

  addMouseMove = () => {
    this.removeMouseMove();
    this.node.ownerDocument.addEventListener('mousemove', this.handleMouseMove);
  };

  removeMouseMove = () => {
    this.node.ownerDocument.removeEventListener(
      'mousemove',
      this.handleMouseMove,
    );
  };

  handleMouseUp = (e: SyntheticMouseEvent<*>) => {
    if (this.state.movingStart || this.state.movingEnd) {
      const clientRect = this.getTrackClientRect();
      const value = getValueFromEvent(
        e,
        clientRect,
        this.props.min,
        this.props.max,
        this.props.step,
      );
      const movingKey = this.state.movingStart ? 'movingStart' : 'movingEnd';
      const valueKey = this.state.movingStart ? 'start' : 'end';
      requestAnimationFrame(() => {
        const prevValue = this.state[valueKey];
        this.setState({[movingKey]: false, [valueKey]: value}, () => {
          if (this.props.onChange && value !== prevValue) {
            this.props.onChange(pick(this.state, ['start', 'end']));
          }
        });
      });
    }
  };

  addMouseUp = () => {
    this.removeMouseUp();
    this.node.ownerDocument.addEventListener('mouseup', this.handleMouseUp);
  };

  removeMouseUp = () => {
    this.node.ownerDocument.removeEventListener('mouseup', this.handleMouseUp);
  };

  handleTrackMouseDown = (e: SyntheticMouseEvent<*>) => {
    if (this.props.disabled) {
      return;
    }
    e.preventDefault();
    const clientRect = this.getTrackClientRect();
    const value = getValueFromEvent(
      e,
      clientRect,
      this.props.min,
      this.props.max,
    );
    const startDifference = Math.abs(this.state.start - value);
    const endDifference = Math.abs(this.state.end - value);
    if (startDifference < endDifference) {
      this.setState({start: value});
    } else {
      this.setState({end: value});
    }
  };

  render() {
    const {
      className,
      min,
      max,
      disabled,
      title,
      valueFormatter,
      step,
      unit,
    } = this.props;
    const {start, end, movingStart, movingEnd} = this.state;
    const sharedProps = {
      min,
      max,
      disabled,
    };
    const values = [start, end].sort((a, b) => a - b);
    const startLabel = valueFormatter(getValue(values[0], step));
    const endLabel = valueFormatter(getValue(values[1], step));
    return (
      <div
        className={classify(css.container, className)}
        ref={node => (this.node = node)}
      >
        {Boolean(title) && <div className={css.title}>{title}</div>}
        <div
          className={css.selectedRange}
        >{`${startLabel} - ${endLabel}${unit}`}</div>
        <div className={css.slider}>
          <Track
            {...sharedProps}
            onMouseDown={this.handleTrackMouseDown}
            start={start}
            end={end}
            ref={trackNode => (this.trackNode = trackNode)}
          />
          {/* Start Handle */}
          <Handle
            {...sharedProps}
            value={start}
            moving={movingStart}
            onMouseDown={this.handleStartMouseDown}
            iconClassName={css.startHandle}
          />
          {/* End Handle */}
          <Handle
            {...sharedProps}
            value={end}
            moving={movingEnd}
            onMouseDown={this.handleEndMouseDown}
            iconClassName={css.endHandle}
          />
          <input type="hidden" name="start" value={start} />
          <input type="hidden" name="end" value={end} />
        </div>
      </div>
    );
  }
}

const getPercentageFromPosition = (position, {width}) => {
  const percentage = position / width;

  return percentage || 0;
};

const getValueFromEvent = (
  e: SyntheticMouseEvent<*>,
  clientRect: ClientRect,
  min: number,
  max: number,
  step?: number,
) => {
  const {clientX} = e;
  const {left, width} = clientRect;

  const position = clamp(clientX - left, 0, width);
  const percentage = getPercentageFromPosition(position, clientRect);
  const range = max - min;
  const value = min + range * percentage;

  return step ? getStepValue(value, step) : value;
};

const getPercentageFromValue = (value, min, max) => {
  const validValue = clamp(value, min, max);
  const range = max - min;
  const percentage = (validValue - min) / range;

  return percentage || 0;
};

const getStepValue = (value: number, step: number) =>
  Math.round(value / step) * step;

const getValue = (value, step) => (step ? getStepValue(value, step) : value);

const Handle = ({
  className,
  iconClassName,
  min,
  max,
  value,
  disabled,
  onMouseDown,
}: {
  className?: string,
  iconClassName?: string,
  min: number,
  max: number,
  value: number,
  disabled: boolean,
  onMouseDown: (e: SyntheticMouseEvent<*>) => void,
}) => {
  const percentage = getPercentageFromValue(value, min, max) * 100;
  const style = {
    left: `${percentage}%`,
  };
  return (
    <div
      className={classify(css.handle, className)}
      style={style}
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      aria-disabled={disabled}
      onMouseDown={onMouseDown}
    >
      <SliderHandle className={iconClassName} />
    </div>
  );
};

class Track extends React.Component<{
  start: number,
  end: number,
  min: number,
  max: number,
  onMouseDown: (e: SyntheticMouseEvent<*>) => void,
}> {
  node: React.Element<*>;

  getClientRect = () => (this.node ? this.node.getBoundingClientRect() : null);

  render() {
    const {min, max} = this.props;
    const [start, end] = [this.props.start, this.props.end].sort(
      (a, b) => a - b,
    );
    const startPercentage = getPercentageFromValue(start, min, max);
    const endPercentage = getPercentageFromValue(end, min, max);
    const activeStyle = {
      width: `${(endPercentage - startPercentage) * 100}%`,
      left: `${startPercentage * 100}%`,
    };

    return (
      <div
        className={css.track}
        onMouseDown={this.props.onMouseDown}
        ref={node => (this.node = node)}
      >
        <div className={css.activeTrack} style={activeStyle} />
      </div>
    );
  }
}

export default Slider;
