// @flow strict

import type {Props as ButtonProps} from '@spaced-out/ui-lib/lib/button/button';

import * as React from 'react';
import {Link} from 'src/rerouter';

import classify from 'src/utils/classify';

import css from './button.css';

// TODO (kyle): there has to be a good way to share more code with
// Button.
export function LinkButton({
  type = 'default',
  size = 'small',
  round,
  styleDisabled,
  icon,
  className,
  children,
  ...props
}: ButtonProps & {
  className?: string,
  children?: React.Node,
  ...
}): React.Node {
  return (
    <Link
      {...props}
      className={classify(className, css.buttonText, css[type], css[size], {
        [css.buttonIcon]: icon,
        [css.buttonEnabled]: true,
        [css.buttonDisabled]: styleDisabled,
        [css.buttonRound]: round,
      })}
    >
      {// $FlowFixMe[sketchy-number-and]
      icon && <div className={css.icon}>{icon}</div>}
      <span className={css.children}>{children}</span>
    </Link>
  );
}
