// @flow strict

import * as React from 'react';
import debounce from 'lodash/debounce';

import {
  MultiTrackAudio,
  SingleTrackAudio,
  type MediaSpec,
} from 'src/utils/audio';


export type {MediaSpec};

/**
 * @param media  a media spec or list of media specs. For ease of use and performance
 *               this hook does NOT expect media to ever change on successive
 *               calls. If you want to change the media, create a separate call to this
 *               hook.
 * @return       a memoized function that will play a sound on demand
 *
 * Note that the sound is preloaded and multi-tracked. This means
 * successive calls to the "play" function are allowed and mixed
 * appropriately.
 */
export function useAudioPlayer(media: MediaSpec | MediaSpec[]): () => void {
  return React.useMemo(() => {
    const audio = new MultiTrackAudio(media);
    return () => audio.play();
  }, []);
}

/**
 * `playId` is a boolean-ish value. When it changes to a truthy value,
 * (either from a falsey or other truthy value), the sound effect will play.
 *
 * `media` is a media spec or list of media specs. For ease of use and
 * performance this hook does NOT expect media to ever change on successive
 * calls. If you want to change the media, create a separate call to this hook.
 *
 * Note that an audio element can only play serially. This means that
 * calling `play()` before a previous sound finishes, will do nothing.
 */
export function useSoundEffect({
  media,
  playOnMount,
  playId,
  soundEffectsEnabled,
  debounceDuration,
}: {
  media: MediaSpec | MediaSpec[],
  playId: mixed,
  playOnMount?: boolean,
  soundEffectsEnabled: boolean,
  debounceDuration?: number,
}) {
  const play = React.useMemo(() => {
    const audio = new SingleTrackAudio(media);
    const play = () => audio.play();
    return typeof debounceDuration === 'number'
      ? debounce(play, debounceDuration, {leading: true, trailing: false})
      : play;
  }, []);
  const [mounted, setMounted] = React.useState(playOnMount);

  React.useEffect(() => {
    if (soundEffectsEnabled && playId && mounted) {
      play();
    }
  }, [playId]);

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, []);
}
