// @flow

import type {InputProps, RefProp} from 'src/types/react';

import * as React from 'react';

import classify from 'src/utils/classify';

import ErrorList from 'src/components/lib/error-list';

import css from './error-input.css';


type ErrorProps = {
  ...InputProps,
  errors: string[] | void,
  className?: string,
  textAreaClassName?: string,
};

const ErrorTextarea = ({
  className,
  textAreaClassName,
  errors = [],
  ...props
}: ErrorProps): React.Element<'div'> => (
  <div className={classify(css.container, className)}>
    <textarea
      className={classify(
        errors.length ? css.errorTextarea : css.textarea,
        textAreaClassName,
      )}
      {...props}
    />
    {errors.length > 0 && <ErrorList className={css.errors} errors={errors} />}
  </div>
);

export default ErrorTextarea;
