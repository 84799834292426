// @flow

import type {Dispatch, GetState, ThunkAction} from 'src/reducers';
import type {Query} from 'src/types/router';
import type {ApiLinkStats, LinkStats} from 'src/types/link-stats';
import type {AnalyticsQuery} from 'src/types/report';

import {thunkify as flow} from 'src/utils/thunks';
import {camel} from 'src/utils';
import pick from 'lodash/pick';

import {
  resolveAnalyticsQuery,
  resolveAnalyticsQueryV2,
  commonFilters,
  metricsFilters,
} from 'src/utils/analytics-query';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api.js';

import {
  checkExternalLinkLatestApiEnabled,
  ANALYTICS_API_V3,
} from 'src/utils/analytics-api-migration';


const {stringify} = JSON;

export const RECEIVE_AGENCY = 'linkStats/receiveAgency';
export const RECEIVE_WORKFLOW = 'linkStats/receiveWorkflow';
export const RECEIVE_EVENT = 'linkStats/receiveEvent';

type PaginatedLinkStats = {
  page: number,
  linkStats: ApiLinkStats,
};

export type AgencyLinkStatsAction = {
  type: 'linkStats/receiveAgency',
  payload: PaginatedLinkStats,
};
export type WorkflowLinkStatsAction = {
  type: 'linkStats/receiveWorkflow',
  payload: {
    ...PaginatedLinkStats,
    workflowId: string,
  },
};
export type EventLinkStatsAction = {
  type: 'linkStats/receiveEvent',
  payload: {
    ...PaginatedLinkStats,
    eventId: string,
  },
};
export type LinkStatsAction =
  | AgencyLinkStatsAction
  | WorkflowLinkStatsAction
  | EventLinkStatsAction;

export const receiveAgency = (
  linkStats: ApiLinkStats,
  page: number,
): AgencyLinkStatsAction => ({
  type: RECEIVE_AGENCY,
  payload: {
    linkStats,
    page,
  },
});

export const receiveWorkflow = (
  linkStats: ApiLinkStats,
  workflowId: string,
  page: number,
): WorkflowLinkStatsAction => ({
  type: RECEIVE_WORKFLOW,
  payload: {
    workflowId,
    linkStats,
    page,
  },
});

export const receiveEvent = (
  linkStats: ApiLinkStats,
  eventId: string,
  page: number,
): EventLinkStatsAction => ({
  type: RECEIVE_EVENT,
  payload: {
    eventId,
    linkStats,
    page,
  },
});

export const PAGINATION_LIMIT = 50;
function resolvePageQuery(query: Query, page) {
  return {...query, offset: page * PAGINATION_LIMIT, limit: PAGINATION_LIMIT};
}

export const allowedFilters: string[] = [...commonFilters, ...metricsFilters];

export function resolveLinkStatsQuery(
  query: Query,
  allowed?: string[] = [...commonFilters, ...metricsFilters],
  isEnhancement?: boolean = false,
): AnalyticsQuery {
  if (isEnhancement) {
    return resolveAnalyticsQueryV2(query, allowed);
  }
  return resolveAnalyticsQuery(query, allowed);
}

const querying =
  (paramIndex, allowed, isEnhancement = false) =>
  (func) =>
  (...args) => {
    args[paramIndex] = resolveLinkStatsQuery(
      args[paramIndex],
      allowed,
      isEnhancement,
    );
    return func(...args);
  };

export const getAgencyLinkStats: (
  query: Query,
  page?: number,
  otherFilterQuery: Query,
) => ThunkAction<mixed> = flow(
  querying(0),
  key((query, page) => `linkStats/agency:${page}`),
  cached((json, query, page = 0) => receiveAgency(camel(json), page), {
    hash: (query, page) => `linkStats/agency:${page}:${stringify(query)}`,
  }),
  fetching(),
)((query: Query, page: number = 0, otherFilterQuery: Query) => {
  const resolved = resolveQuery(otherFilterQuery);
  const q = {
    ...query,
    ...resolved,
  };
  const apiPayload = resolvePageQuery(q, page);
  return reduxApi.post(
    'sent-links',
    apiPayload,
    {},
    {
      apiPath: checkExternalLinkLatestApiEnabled()
        ? `${ANALYTICS_API_V3}/`
        : '/api/v1/analytics/',
    },
  );
});

export const getMoreAgencyLinkStats =
  (query: Query): ThunkAction<mixed> =>
  (dispatch: Dispatch, getState: GetState) =>
    dispatch(getAgencyLinkStats(query, getState().linkStats.agency.page + 1, query),);

export const getWorkflowLinkStats: (
  string,
  Query,
  page?: number,
  otherFilterQuery: Query,
) => ThunkAction<mixed> = flow(
  querying(1),
  key((workflowId, query, page) => `linkStats/workflow:${workflowId}:${page}`),
  cached(
    (json, workflowId, query, page = 0) =>
      receiveWorkflow(camel(json), workflowId, page),
    {
      hash: (workflowId, query, page) =>
        `linkStats/workflow:${workflowId}:${page}:${stringify(query)}`,
    },
  ),
  fetching(),
)(
  (
    workflowId: string,
    query: Query,
    page: number = 0,
    otherFilterQuery: Query,
  ) => {
    const resolved = resolveQuery(otherFilterQuery);
    const q = {
      ...query,
      ...resolved,
    };
    const apiPayload = resolvePageQuery(q, page);
    return reduxApi.post(
      `workflows/${workflowId}/sent-links`,
      apiPayload,
      {},
      {
        apiPath: checkExternalLinkLatestApiEnabled()
          ? `${ANALYTICS_API_V3}/`
          : '/api/v1/analytics/',
      },
    );
  },
);

export const getMoreWorkflowLinkStats =
  (workflowId: string, query: Query): ThunkAction<mixed> =>
  (dispatch: Dispatch, getState: GetState) =>
    dispatch(getWorkflowLinkStats(workflowId, query, getState().linkStats.workflows[workflowId].page + 1, query),);

export const getEventLinkStats: (
  workflowId: string,
  eventId: string,
  query: Query,
  page?: number,
  otherFilterQuery: Query,
) => ThunkAction<mixed> = flow(
  querying(2, ['client', 'tracking_events']),
  key(
    (workflowId, eventId, query, page) =>
      `linkStats/workflow:${workflowId}:${eventId}:${page}`,
  ),
  cached(
    (json, workflowId, eventId, query, page = 0) =>
      receiveEvent(camel(json), eventId, page),
    {
      hash: (workflowId, eventId, query, page) =>
        `linkStats/workflow:${workflowId}:${eventId}:${page}:${stringify(
          query,
        )}`,
    },
  ),
  fetching(),
)(
  (
    workflowId: string,
    eventId: string,
    query: Query,
    page: number = 0,
    otherFilterQuery: Query,
  ) => {
    const resolved = resolveQuery(otherFilterQuery);
    const q = {
      ...query,
      ...resolved,
    };
    const apiPayload = resolvePageQuery(q, page);
    return reduxApi.post(
      `workflows/${workflowId}/events/${eventId}/sent-links`,
      apiPayload,
      {},
      {
        apiPath: checkExternalLinkLatestApiEnabled()
          ? `${ANALYTICS_API_V3}/`
          : '/api/v1/analytics/',
      },
    );
  },
);

export const getMoreEventLinkStats =
  (workflowId: string, eventId: string, query: Query): ThunkAction<mixed> =>
  (dispatch: Dispatch, getState: GetState) =>
    dispatch(getEventLinkStats(workflowId, eventId, query, getState().linkStats.events[eventId].page + 1, query),);

export const getAgencyLinkStatsV2: (
  query: Query,
  page?: number,
  otherFilterQuery: Query,
) => ThunkAction<mixed> = flow(
  querying(0, [...commonFilters, ...metricsFilters], true),
  key((query, page) => `linkStats/agency:${page}`),
  cached((json, query, page = 0) => receiveAgency(camel(json), page), {
    hash: (query, page) => `linkStats/agency:${page}:${stringify(query)}`,
  }),
  fetching(),
)((query: Query, page: number = 0, otherFilterQuery: Query) => {
  const resolved = resolveQuery(otherFilterQuery);
  const q = {
    ...query,
    ...resolved,
  };
  const apiPayload = resolvePageQuery(q, page);
  return reduxApi.post(
    'sent-links',
    apiPayload,
    {},
    {
      apiPath: checkExternalLinkLatestApiEnabled()
        ? `${ANALYTICS_API_V3}/`
        : '/api/v1/analytics/',
    },
  );
});

export const getMoreAgencyLinkStatsV2 =
  (query: Query): ThunkAction<mixed> =>
  (dispatch: Dispatch, getState: GetState) =>
    dispatch(getAgencyLinkStatsV2(query, getState().linkStats.agency.page + 1, query),);

export const getWorkflowLinkStatsV2: (
  string,
  Query,
  page?: number,
  otherFilterQuery: Query,
) => ThunkAction<mixed> = flow(
  querying(1, [...commonFilters, ...metricsFilters], true),
  key((workflowId, query, page) => `linkStats/workflow:${workflowId}:${page}`),
  cached(
    (json, workflowId, query, page = 0) =>
      receiveWorkflow(camel(json), workflowId, page),
    {
      hash: (workflowId, query, page) =>
        `linkStats/workflow:${workflowId}:${page}:${stringify(query)}`,
    },
  ),
  fetching(),
)(
  (
    workflowId: string,
    query: Query,
    page: number = 0,
    otherFilterQuery: Query,
  ) => {
    const resolved = resolveQuery(otherFilterQuery);
    const q = {
      ...query,
      ...resolved,
    };
    const apiPayload = resolvePageQuery(q, page);
    return reduxApi.post(
      `workflows/${workflowId}/sent-links`,
      apiPayload,
      {},
      {
        apiPath: checkExternalLinkLatestApiEnabled()
          ? `${ANALYTICS_API_V3}/`
          : '/api/v1/analytics/',
      },
    );
  },
);

export const getMoreWorkflowLinkStatsV2 =
  (workflowId: string, query: Query): ThunkAction<mixed> =>
  (dispatch: Dispatch, getState: GetState) =>
    dispatch(getWorkflowLinkStatsV2(workflowId, query, getState().linkStats.workflows[workflowId].page + 1, query),);

export const getEventLinkStatsV2: (
  workflowId: string,
  eventId: string,
  query: Query,
  page?: number,
  otherFilterQuery: Query,
) => ThunkAction<mixed> = flow(
  querying(2, ['client', 'tracking_events'], true),
  key(
    (workflowId, eventId, query, page) =>
      `linkStats/workflow:${workflowId}:${eventId}:${page}`,
  ),
  cached(
    (json, workflowId, eventId, query, page = 0) =>
      receiveEvent(camel(json), eventId, page),
    {
      hash: (workflowId, eventId, query, page) =>
        `linkStats/workflow:${workflowId}:${eventId}:${page}:${stringify(
          query,
        )}`,
    },
  ),
  fetching(),
)(
  (
    workflowId: string,
    eventId: string,
    query: Query,
    page: number = 0,
    otherFilterQuery: Query,
  ) => {
    const resolved = resolveQuery(otherFilterQuery);
    const q = {
      ...query,
      ...resolved,
    };
    const apiPayload = resolvePageQuery(q, page);
    return reduxApi.post(
      `workflows/${workflowId}/events/${eventId}/sent-links`,
      apiPayload,
      {},
      {
        apiPath: checkExternalLinkLatestApiEnabled()
          ? `${ANALYTICS_API_V3}/`
          : '/api/v1/analytics/',
      },
    );
  },
);

export const getMoreEventLinkStatsV2 =
  (workflowId: string, eventId: string, query: Query): ThunkAction<mixed> =>
  (dispatch: Dispatch, getState: GetState) =>
    dispatch(getEventLinkStatsV2(workflowId, eventId, query, getState().linkStats.events[eventId].page + 1, query),);

function resolveQuery(query) {
  const resolvedQueryObject = resolveAnalyticsQueryV2(query);

  if (resolvedQueryObject['recipient_type']) {
    resolvedQueryObject.recipients = resolvedQueryObject['recipient_type'];
  }

  return {
    ...pick(resolvedQueryObject, [
      'start_date',
      'end_date',
      'workflow_id',
      'event_id',
      'offset',
      'limit',
      'message_goal_type',
      'show_filter',
      'result',
      'delivery_status',
      'entity_data_filter',
      'from_address',
      'event_type_filter',
      'recipients',
    ]),
  };
}
