// @flow strict-local

import type {AgencyConfig} from 'src/types/agency';
import type {Context} from 'react';

import {createContext} from 'react';


const AgencyConfigContext: Context<$Shape<AgencyConfig>> = createContext({});
export default AgencyConfigContext;
