// @flow strict

import type {ToastAction} from 'src/action-creators/toasts';
import type {ToastMessage} from 'src/types/toast';

import {SHOW_TOAST, REMOVE_TOAST} from 'src/action-creators/toasts';


export default (
  state: ToastMessage[] = [],
  action: ToastAction,
): ToastMessage[] => {
  switch (action.type) {
    case SHOW_TOAST:
      return [...state, action.payload];
    case REMOVE_TOAST:
      return state.filter((i) => i.id !== action.payload);
  }
  return state;
};
