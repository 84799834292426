// @flow strict
import * as React from 'react';
import MainLogo from 'src/images/sense-logo-new-white.svg';
import CloseMenu from 'src/images/close-menu.svg';
import CloseMenuHover from 'src/images/close-menu-hover.svg';
import Hamburger from 'src/images/hamburger.svg';
import RightArrow from 'src/images/right-arrow.svg';

import {UnstyledButton} from '@spaced-out/ui-design-system/lib/components/Button';
import {Tooltip} from '@spaced-out/ui-design-system/lib/components/Tooltip';

import css from './side-nav-header.css';


type SideNavHeaderProps = {
  expanded: boolean,
  setExpand: (boolean) => void,
};

export const SideNavHeader = ({
  expanded,
  setExpand,
}: SideNavHeaderProps): React.Node => {
  return (
    <div className={css.headerContainer}>
      {expanded ? (
        <div className={css.expandedHeader}>
          <MainLogo className={css.logo} />
          <UnstyledButton
            ariaLabel="Collapse Side Nav"
            onClick={() => setExpand(false)}
            size="medium"
            type="primary"
            className={css.headerButton}
            tabIndex={0}
          >
            <div className={css.closeMenu}>
              <CloseMenu />
            </div>
            <div className={css.closeMenuHover}>
              <CloseMenuHover />
            </div>
          </UnstyledButton>
        </div>
      ) : (
        <UnstyledButton
          ariaLabel="Expand Side Nav"
          onClick={() => setExpand(true)}
          size="medium"
          type="primary"
          className={css.headerButton}
          tabIndex={0}
        >
          <div className={css.closeMenu}>
            <Hamburger />
          </div>
          <div className={css.closeMenuHover}>
            <RightArrow />
          </div>
        </UnstyledButton>
      )}
    </div>
  );
};
