// @flow

import type {
  SenseEvent,
  EventTemplate,
  ClientEventType,
  BrandingSettings,
} from 'src/types/events';
import type {RawDraftContentState} from 'draft-js/lib/RawDraftContentState';

import {EventTypeEnum} from 'src/stores/event.js';

import {
  findDynamicLabelsInString,
  findMustacheLabelsInString,
  findTempLabelsInString,
} from 'src/components/lib/markdown-editor/dynamic-text.jsx';

import {getPlaceholderEntitiesCount} from 'src/components/lib/markdown-editor/draft-placeholder.js';

import {findJobVariablesInString} from 'src/utils/job-match.js';


export function findUniqueEntityVariablesInEvent(
  event: SenseEvent,
  template?: EventTemplate,
): Set<string> {
  return new Set(findAllEntityVariablesInEvent(event, template));
}

export function* findModuleEntityVariablesInEvent(
  event: SenseEvent,
  // $FlowFixMe[incompatible-return] types/surveys
): Generator<string, void, void> {
  for (const module of event.questions) {
    if (typeof module.question === 'string') {
      yield* findDynamicLabelsInString(module.question);
    }

    if (module.rte_json) {
      const {rte_json} = module;

      for (const entity of Object.values(rte_json.entityMap)) {
        // TODO (kyle): find flowtype for Entity
        // $FlowIssue Object.values
        if (entity.type === 'DYNAMIC_LABEL') {
          // TODO (kyle): name or value?
          // $FlowIssue Object.values
          yield entity.data.value;
        }
      }

      // NOTE (angelina): adding this additional loop in cases where entityMap has not been populated correctly. Dupes could be eliminated by calling findUniqueEntityVariablesInEvent. See https://github.com/Spaced-Out/sense/issues/15929 for additional details
      for (const block of rte_json.blocks) {
        yield* findDynamicLabelsInString(block.text);
      }
    } else if (module.text) {
      yield* findDynamicLabelsInString(module.text);
    } else if (module.beefree_html) {
      yield* findMustacheLabelsInString(module.beefree_html);
    }

    // (rng) - There is a bug in validating Digest List attributes, so commenting out until Nilarnab can debug
    // if (module.attributesToInclude) {
    //   yield* module.attributesToInclude;
    // }
  }
}

export function* findAllEntityVariablesInEvent(
  event: SenseEvent,
  template?: EventTemplate,
  // $FlowFixMe[incompatible-return] types/surveys
): Generator<string, void, void> {
  if (event.dynamicDeliveryAddress) {
    yield event.dynamicDeliveryAddress;
  }

  if (event.subject) {
    yield* findDynamicLabelsInString(event.subject);
  }

  yield* findAllEntityVariablesInBrandingSettings(event.brandingSettings);

  if (event.beefreeJson) {
    yield* findAllEntityVariablesInBeeFreeJson(event.beefreeJson);
  }

  for (const module of event.questions) {
    if (typeof module.question === 'string') {
      yield* findDynamicLabelsInString(module.question);
    }

    if (module.writeback) {
      // $FlowFixMe[incompatible-type]
      yield module.writeback.attribute_name;
    }

    if (module.rte_json) {
      const {rte_json} = module;

      for (const entity of Object.values(rte_json.entityMap)) {
        // TODO (kyle): find flowtype for Entity
        // $FlowIssue Object.values
        if (entity.type === 'DYNAMIC_LABEL') {
          // TODO (kyle): name or value?
          // $FlowIssue Object.values
          yield entity.data.value;
        }
      }

      // NOTE (angelina): adding this additional loop in cases where entityMap has not been populated correctly. Dupes could be eliminated by calling findUniqueEntityVariablesInEvent. See https://github.com/Spaced-Out/sense/issues/15929 for additional details
      for (const block of rte_json.blocks) {
        yield* findDynamicLabelsInString(block.text);
      }
    } else if (module.text) {
      yield* findDynamicLabelsInString(module.text);
    } else if (module.beefree_html) {
      yield* findMustacheLabelsInString(module.beefree_html);
    }
    //SM-136 allow for job variables preview
    if (module.include_job_block === true) {
      // $FlowFixMe[prop-missing]
      const {job_match_rte_json, alt_rte_json} = module;
      if (job_match_rte_json) {
        yield* findVariablesInDraftContent(job_match_rte_json);
      }
      //also check alt_rte
      if (alt_rte_json) {
        yield* findVariablesInDraftContent(alt_rte_json);
      }
    }

    // NOTE(marcos): this allows us to check handcrafted variables in html templates
    // for validity before doing a test send
    if (template?.html) {
      yield* findMustacheLabelsInString(template.html);
    }

    if (module.attributesToInclude) {
      yield* module.attributesToInclude;
    }
  }
}

export function tempVarsInEvent(event: SenseEvent): Set<string> {
  return new Set(findAllTempVariables(event));
}

export function placeholderCountInEvent(event: SenseEvent): number {
  return countAllPlaceholderVariablesInEvent(event);
}

export function* findVariablesInDraftContent(
  rte_json: RawDraftContentState,
  // $FlowFixMe[incompatible-return] types/surveys
): Generator<string, void, void> {
  for (const entity of Object.values(rte_json?.entityMap)) {
    // $FlowIssue Object.values
    if (entity.type === 'DYNAMIC_LABEL') {
      // $FlowIssue Object.values
      yield entity.data.value;
    }
  }
  for (const block of rte_json?.blocks) {
    yield* findJobVariablesInString(block.text);
  }
}

export function* findAllTempVariables(
  event: SenseEvent,
): Generator<string, void, void> {
  if (event.subject) {
    yield* findTempLabelsInString(event.subject);
  }

  for (const key of [
    'emailFromAddress',
    'emailFromName',
    'alertEmails',
    'emailCcList',
    'emailBccList',
  ]) {
    if (event.brandingSettings?.[key]) {
      yield* findTempLabelsInString(event.brandingSettings[key]);
    }
  }

  for (const module of event.questions) {
    if (typeof module.question === 'string') {
      yield* findTempLabelsInString(module.question);
    }

    if (module.rte_json) {
      const {rte_json} = module;

      // NOTE (angelina): adding this additional loop in cases where entityMap has not been populated correctly. Dupes could be eliminated by calling findUniqueEntityVariablesInEvent. See https://github.com/Spaced-Out/sense/issues/15929 for additional details
      for (const block of rte_json.blocks) {
        yield* findTempLabelsInString(block.text);
      }
    } else if (module.text) {
      yield* findTempLabelsInString(module.text);
    } else if (module.beefree_json) {
      yield* findTempLabelsInString(JSON.stringify(module.beefree_json));
    }
  }
}

export function countAllPlaceholderVariablesInEvent(event: SenseEvent): number {
  let count = 0;
  for (const module of event.questions) {
    //$FlowFixMe
    if (module.draftEditorState) {
      count += getPlaceholderEntitiesCount(module.draftEditorState) ?? 0;
    }
  }
  return count;
}

// TODO (kyle): not sure this is necessary. maybe fold it back into
// the `event` function above.
function* findAllEntityVariablesInBrandingSettings(
  brandingSettings: BrandingSettings,
): Generator<string, void, void> {
  for (const key of [
    'emailFromAddress',
    'emailFromName',
    'alertEmails',
    'emailCcList',
    'emailBccList',
  ]) {
    if (brandingSettings[key]) {
      yield* findDynamicLabelsInString(brandingSettings[key]);
    }
  }
}

/**
 * This function attempts to ignore the underlying structure
 * of the BeeFree JSON at the cost of doing a full traversal.
 */
function* findAllEntityVariablesInBeeFreeJson(json: {
  ...
}): Generator<string, void, void> {
  for (const [key, value] of Object.entries(json)) {
    if (key === 'html' && typeof value === 'string') {
      yield* findMustacheLabelsInString(value);
    } else if (value != null && typeof value === 'object') {
      yield* findAllEntityVariablesInBeeFreeJson(value);
    }
  }
}

export const isSMS = (eventType: ClientEventType): boolean =>
  [
    EventTypeEnum.SMS_Message,
    EventTypeEnum.SMS_Survey,
    EventTypeEnum.SMS_Chatbot,
    EventTypeEnum.SMS_NPS,
    EventTypeEnum.SMS_Job,
  ].includes(eventType);

export const isEmail = (eventType: ClientEventType): boolean =>
  [
    EventTypeEnum.Message,
    EventTypeEnum.Survey,
    EventTypeEnum.List,
    EventTypeEnum.NPS,
    EventTypeEnum.BeeFree_Email,
    EventTypeEnum.BeeFree_Chatbot,
  ].includes(eventType);

export const isSurvey = (eventType: ClientEventType): boolean =>
  [
    EventTypeEnum.Survey,
    EventTypeEnum.SMS_Survey,
    EventTypeEnum.NPS,
    EventTypeEnum.SMS_NPS,
  ].includes(eventType);

export const isRespondable = (eventType: ClientEventType): boolean =>
  [EventTypeEnum.Survey, EventTypeEnum.SMS_Survey, EventTypeEnum].includes(
    eventType,
  );

export const eventDisplayName = (eventType: ClientEventType): string => {
  switch (eventType) {
    case EventTypeEnum.Survey:
      return 'Survey';
    case EventTypeEnum.SMS_Message:
      return 'SMS';
    case EventTypeEnum.SMS_Survey:
      return 'SMS Survey';
    case EventTypeEnum.SMS_Chatbot:
      return 'SMS Chatbot';
    case EventTypeEnum.NPS:
      return 'NPS';
    case EventTypeEnum.SMS_NPS:
      return 'SMS NPS';
    case EventTypeEnum.List:
      return 'Digest List';
    case EventTypeEnum.BeeFree_Email:
      return 'Rich Email';
    case EventTypeEnum.BeeFree_Chatbot:
      return 'Rich Email Chatbot';
    case EventTypeEnum.Bulk_Writeback:
      return 'Bulk Writeback';
    case EventTypeEnum.Message:
    default:
      return 'Message';
  }
};

export const getMaxAllowedChoiceNumber = (
  choices: {value: string, label: string}[],
  maxLength: ?number,
): number => {
  if (!maxLength || choices.length === 0) {
    return choices.length;
  }

  const sortedChoices = [...choices].sort(
    (a, b) => b.value.length - a.value.length,
  );

  if (maxLength < sortedChoices[0].value.length) {
    return 0;
  } //unlikely case where if hit, changes may have to be made to the data, but adding this here just as a safeguard

  let choicesCharSum = 0;
  let result = 0;
  for (let i = 0; i < sortedChoices.length; i++) {
    if (choicesCharSum >= maxLength) {
      break;
    }
    choicesCharSum += sortedChoices[i].value.length;
    result++;
  }

  return result;
};

export const getMultiSelectOptions = (
  maxAllowedChoiceNumber: number,
  numEnabledChoices: number,
): Array<{value: number, label: string, ...}> => {
  const result = [{value: 1, label: 'one choice'}];
  const numOptions = Math.min(maxAllowedChoiceNumber, numEnabledChoices);

  for (let i = 1; i < numOptions; i++) {
    let label = `${i + 1} choices`;
    if (i === numEnabledChoices - 1) {
      label += ' (all)';
    } else if (i === numOptions - 1) {
      label += ' (max)';
    }

    result.push({
      value: i + 1,
      label,
    });
  }

  return result;
};
