// @flow strict
import type {Option as MenuOption} from './menu.jsx';

import * as React from 'react';
import classify from 'src/utils/classify';
import {UnstyledButton} from '@spaced-out/ui-lib/lib/button';
import {useContextMenu} from 'src/components/lib/context-menu';
import {Menu} from 'src/designSystem2021Components/menu.jsx';

import DownCaretIcon from 'src/images/designSystems2021/caret-down.svg?noAttrs';

import css from './buttons.css';


type ButtonProps = {
  onKeyPress?: ?(SyntheticKeyboardEvent<HTMLElement>) => mixed,
  onClick?: ?(SyntheticEvent<HTMLElement>) => mixed,
  className?: string,
  icon?: React.Node,
  value?: string, // deprecated, don't use this!
  children?: React.Node,
  type?: 'primary' | 'secondary' | 'tertiary' | 'selectable' | 'nav' | 'text',
  isError?: boolean,
  iconPosition?: 'left' | 'right',
  isSelectable?: boolean,
  isSelected?: boolean,
  disabled?: boolean,
  menuOptions?: Array<MenuOption>,
  size?: 'standard' | 'small',
  ...
};

/**
 * Our Design System button. It has lots of variants.
 *
 * Set the content with children and
 * @type {[type]}
 */
export const Button: React$AbstractComponent<ButtonProps, HTMLDivElement> =
  React.forwardRef<ButtonProps, HTMLDivElement>(
    (
      {
        className,
        children,
        icon = null,
        iconPosition = 'left',
        value,
        type = 'primary',
        isError = false,
        disabled = false,
        isSelected = false,
        isSelectable = false,
        size = 'standard',
        ...props
      }: ButtonProps,
      ref,
    ) => (
      <UnstyledButton
        {...props}
        className={classify(className, css.button, {
          [css.primary]: !isSelectable && type === 'primary',
          [css.secondary]: !isSelectable && type === 'secondary',
          [css.tertiary]: type === 'tertiary',
          [css.nav]: type === 'nav',
          [css.text]: type === 'text',
          [css.selected]: isSelectable && isSelected,
          [css.unselected]: isSelectable && !isSelected,
          [css.error]: isError,
          [css.disabled]: disabled,
          [css.small]: size === 'small',
        })}
        ref={ref}
      >
        <div className={css.buttonRow}>
          {/* Has no icon, only child/value */}
          {icon == null ? (
            <div className={css.textContainerCenter}>{children ?? value}</div>
          ) : // has icon, but no child/value
          value == null && children == null ? (
            <div className={css.iconContainerCenter}>{icon}</div>
          ) : // has icon _and_ child/value
          iconPosition === 'left' ? (
            <>
              <div className={css.iconContainerLeft}>{icon}</div>
              <div className={css.textContainerRight}>{value || children}</div>
            </>
          ) : (
            <>
              <div className={css.textContainerLeft}>{children ?? value}</div>
              <div className={css.iconContainerRight}>{icon}</div>
            </>
          )}
        </div>
      </UnstyledButton>
    ),
  );

type MenuButtonProps = {
  menuOptions: Array<MenuOption>,
  onMenuSelect: (option: MenuOption) => mixed,
  selectedOption: MenuOption,
  children?: React.Node,
  buttonProps: ButtonProps,
};

export const MenuButton = ({
  menuOptions,
  onMenuSelect,
  selectedOption,
  children,
  buttonProps,
}: MenuButtonProps): React.Node => {
  const [contextMenuRef, handleOpen] = useContextMenu({
    noArrow: true,
    noStyle: true,
    anchorPosition: 'start',
    justify: 'start',

    menuContent: (
      <div>
        <Menu
          options={menuOptions}
          onSelected={onMenuSelect}
          selectedOption={selectedOption}
        />
      </div>
    ),
  });
  return (
    <div ref={contextMenuRef} onClick={handleOpen}>
      <Button {...buttonProps} iconPosition="right" icon={<DownCaretIcon />}>
        {children}
      </Button>
    </div>
  );
};

export default Button;
