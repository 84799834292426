// @flow strict

// $FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';
import {ReferralCurrentState} from 'src/reducers/referral-v2';
import type {
  BonusChangeLog,
  BulkAddStatus,
  GenericError,
  GetReferralPayoutsApiResponse,
  GetReferralTrackingApiResponse,
  ReferralAgencyInfo,
  ReferralAgent,
  ReferralAttributes,
  ReferralAttributesValues,
  TransactionHistory,
  PaymentBalance,
  ReferralLink,
  ReferralBrand,
  ReferralDetails,
  ReferralJobData,
  ReferralLeaderBoard,
  ReferralPrograms,
  ReferralQuestionnaire,
  ReferralSetup,
  ReferralBonusAndPayoutSettings,
} from 'src/types/referral-v2';

// Swatantra
export const selectReferralPrograms = (state: State): ?(ReferralPrograms[]) =>
  state.referralV2?.referral_programs?.referral_programs;

export const selectReferralProgramsLoading = (state: State): ?boolean =>
  state?.referralV2?.current_state ===
  ReferralCurrentState.FETCHING_REFERRAL_PROGRAM;

export const selectReferralProgramsError = (state: State): ?GenericError =>
  state?.referralV2?.referral_programs_error;

export const selectReferralLeaderboard = (
  state: State,
): ?(ReferralLeaderBoard[]) => state.referralV2?.referral_leaderboard;

export const selectReferralLeaderboardLoading = (state: State): ?boolean =>
  state?.referralV2?.currentState === ReferralCurrentState.FETCHING_LEADERBOARD;

export const selectReferralLeaderboardError = (state: State): ?GenericError =>
  state?.referralV2?.referral_leaderboard_error;

export const selectReferralAgents = (state: State): ?ReferralAgent =>
  state.referralV2?.referral_agents;

export const selectReferralAgentsLoading = (state: State): ?boolean =>
  state?.referralV2?.currentState === ReferralCurrentState.FETCHING_AGENTS;

export const selectReferralAgentsError = (state: State): ?GenericError =>
  state?.referralV2?.referral_agents_error;

export const selectReferralAgency = (state: State): ?ReferralAgencyInfo =>
  state.referralV2?.referral_agency;

export const selectReferralAgencyLoading = (state: State): ?boolean =>
  state?.referralV2?.currentState === ReferralCurrentState.FETCHING_AGENCY;

export const selectReferralAgencyError = (state: State): ?GenericError =>
  state?.referralV2?.referral_agency_error;

// Nishant

export const selectReferralDetails = (state: State): ?ReferralDetails =>
  state?.referralV2?.configuration?.referral_details;

export const selectReferralDetailsError = (state: State): ?GenericError =>
  state?.referralV2?.configuration?.referral_details_error;

export const selectSaveReferralDetailsError = (state: State): ?GenericError =>
  state?.referralV2?.configuration?.save_referral_error;

export const selectSaveReferralDetailsLoading = (state: State): boolean =>
  state?.referralV2?.current_state ===
  ReferralCurrentState.SAVING_REFERRAL_DETAILS;

export const selectReferralTrackingDetails = (
  state: State,
): ?GetReferralTrackingApiResponse =>
  state?.referralV2?.referral_tracking_details;

export const selectReferralTrackingDetailsError = (
  state: State,
): ?GenericError | string => state?.referralV2?.referral_details_error;

export const selectReferralTrackingDetailsLoading = (state: State): boolean =>
  state?.referralV2?.current_state ===
  ReferralCurrentState.FETCHING_REFERRAL_TRACKING_DETAILS;

export const selectReferralPayoutsDetails = (
  state: State,
): ?GetReferralPayoutsApiResponse =>
  state?.referralV2?.referral_payouts?.payouts;

export const selectReferralPayoutsDetailsError = (
  state: State,
): ?GenericError | string => state?.referralV2?.referral_payouts?.payouts_error;

export const selectReferralPayoutsDetailsLoading = (state: State): boolean =>
  state?.referralV2?.current_state ===
  ReferralCurrentState.FETCHING_REFERRAL_PAYOUTS;

export const selectBrands = (state: State): ?(ReferralBrand[]) =>
  state?.referralV2?.configuration?.brands;

export const selectBrandsLoading = (state: State): boolean =>
  state?.referralV2?.current_state === ReferralCurrentState.FETCHING_BRANDS;

export const selectBrandsError = (state: State): ?GenericError =>
  state?.referralV2?.configuration?.brands_error;

export const selectJobs = (state: State): ?(ReferralJobData[]) =>
  state?.referralV2?.configuration?.jobs;

export const selectJobsLoading = (state: State): boolean =>
  state?.referralV2?.current_state === ReferralCurrentState.FETCHING_JOBS;

export const selectJobsError = (state: State): ?GenericError =>
  state?.referralV2?.configuration?.jobs_error;

export const selectQuestionnaire = (state: State): ?(ReferralQuestionnaire[]) =>
  state?.referralV2?.configuration?.questionnaire;

export const selectQuestionnaireLoading = (state: State): boolean =>
  state?.referralV2?.current_state ===
  ReferralCurrentState.FETCHING_QUESTIONNAIRE;

export const selectQuestionnaireError = (state: State): ?GenericError =>
  state?.referralV2?.configuration?.questionnaire_error;
// Nishant end

// Vishal
export const selectAttributes = (state: State): ?(ReferralAttributes[]) =>
  state?.referralV2?.configuration?.referral_attributes;

export const selectAttributesLoading = (state: State): boolean =>
  state?.referralV2?.current_state ===
  ReferralCurrentState.FETCHING_REFERRAL_ATTRIBUTES;

export const selectAttributesError = (state: State): ?GenericError =>
  state?.referralV2?.configuration?.referral_attributes_error;

export const selectConfiguredAttributes = (
  state: State,
): ?(ReferralAttributes[]) =>
  state?.referralV2?.configuration?.referral_selected_attributes;

export const selectConfiguredAttributesLoading = (state: State): boolean =>
  state?.referralV2?.current_state ===
  ReferralCurrentState.FETCHING_REFERRAL_CONFIGURED_ATTRIBUTES;

export const selectConfiguredAttributesError = (state: State): ?GenericError =>
  state?.referralV2?.configuration?.referral_selected_attributes_error;

export const selectAttributesValues = (
  state: State,
): ?(ReferralAttributesValues[]) =>
  state?.referralV2?.configuration?.referral_attributes_values;

export const selectAttributesValuesLoading = (state: State): boolean =>
  state?.referralV2?.current_state ===
  ReferralCurrentState.FETCHING_REFERRAL_ATTRIBUTES_VALUES;

export const selectAttributesValuesError = (state: State): ?GenericError =>
  state?.referralV2?.configuration?.referral_attributes_values_error;

export const selectFilterAttributesValues = (
  state: State,
): ?(ReferralAttributesValues[]) =>
  state?.referralV2?.configuration?.referral_filter_attributes_values;

export const selectFilterAttributesValuesLoading = (state: State): boolean =>
  state?.referralV2?.current_state ===
  ReferralCurrentState.FETCHING_REFERRAL_ATTRIBUTES_VALUES;

export const selectFilterAttributesValuesError = (
  state: State,
): ?GenericError =>
  state?.referralV2?.configuration?.referral_filter_attributes_values_error;

export const selectBonusChangeLog = (state: State): ?(BonusChangeLog[]) =>
  state?.referralV2?.configuration?.bonus_change_log;

export const selectBonusChangeLogLoading = (state: State): boolean =>
  state?.referralV2?.current_state ===
  ReferralCurrentState.FETCHING_BONUS_CHANGE_LOG;

export const selectBonusChangeLogError = (state: State): ?GenericError =>
  state?.referralV2?.configuration?.bonus_change_log_error;

export const selectTransactionHistory = (state: State): ?TransactionHistory =>
  state?.referralV2?.payout_transaction_history;

export const selectTransactionHistoryLoading = (state: State): boolean =>
  state?.referralV2?.current_state ===
  ReferralCurrentState.FETCHING_TRANSACTION_HISTORY;

export const selectTransactionHistoryError = (state: State): ?GenericError =>
  state?.referralV2?.payout_transaction_history_error;

export const selectPaymentBalance = (state: State): ?PaymentBalance =>
  state?.referralV2?.configuration?.payment_balance;

export const selectPaymentBalanceLoading = (state: State): boolean =>
  state?.referralV2?.current_state ===
  ReferralCurrentState.FETCHING_PAYMENT_BALANCE;

export const selectPaymentBalanceError = (state: State): ?GenericError =>
  state?.referralV2?.configuration?.payment_balance_error;

export const selectReferralLinks = (state: State): ?ReferralLink =>
  state?.referralV2?.referral_agency_links;
export const selectBulkStatusForViewerAsView = (state: State): ?BulkAddStatus =>
  state?.referralProgramUsers?.bulk_add_viewer_as_view;

export const selectBulkStatusForAdminAsView = (state: State): ?BulkAddStatus =>
  state?.referralProgramUsers?.bulk_add_admin_as_view;

export const selectBulkStatusForAdminAsEdit = (state: State): ?BulkAddStatus =>
  state?.referralProgramUsers?.bulk_add_admin_as_edit;

export const selectProgramFormData = (
  state: State,
): {
  setup: ReferralSetup,
  bonus_and_payout_settings: ReferralBonusAndPayoutSettings,
} => state?.referralProgram?.program_details_form_data;
