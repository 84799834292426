// @noflow
import * as React from 'react';
import DatePicker from 'react-datepicker';
import uniqueId from 'lodash/uniqueId';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import './date-picker-override.css';
import css from './date-picker.css';

import {classify} from 'src/utils/index';

import CalendarIcon from 'src/images/calendar-icon.svg';


type DatePickerProps = {
  className?: string,
  defaultValue?: moment$Moment | null,
  onChange?: (e: ?moment$Moment) => void,
  selected?: moment$Moment | null,
  disabled?: boolean,
};

export default class extends React.Component<
  DatePickerProps,
  {
    value: ?moment$Moment,
    elementId: string,
    mounted: boolean,
  },
> {
  handleChange: (value: ?moment$Moment) => void;

  constructor(props: DatePickerProps) {
    super(props);

    this.state = {
      value: props.defaultValue || null,
      elementId: uniqueId('date-picker'),
      mounted: false,
    };
  }
  pickerRef = null;

  componentDidMount() {
    this.setState({mounted: true});
  }

  _handleChange(value: ?moment$Moment) {
    this.setState({value});
  }
  handleChange = this._handleChange.bind(this);

  _handleClickOutside() {
    this.pickerRef.cancelFocusInput();
  }
  handleClickOutside = this._handleClickOutside.bind(this);

  render() {
    if (!this.state.mounted) {
      return null;
    }

    const {
      className,
      selected,
      onChange,
      minDate,
      maxDate,
      disabled,
      ...rest
    } = this.props;

    const handleChange = onChange || this.handleChange;
    return (
      <div
        className={classify(css.datePicker, className, {
          [css.disabled]: disabled,
        })}
      >
        <DatePicker
          id={this.state.elementId}
          selected={selected?.toDate() || this.state.value?.toDate()}
          dateFormat={'yyyy-MM-dd'}
          fixedHeight={true}
          onChange={(value) => handleChange(moment(value))}
          ref={(node) => {
            this.pickerRef = node;
          }}
          onClickOutside={this.handleClickOutside}
          minDate={minDate?.toDate()}
          maxDate={maxDate?.toDate()}
          disabled={disabled}
          {...rest}
        />
        <label htmlFor={this.state.elementId} className={css.label}>
          <CalendarIcon />
        </label>
      </div>
    );
  }
}
