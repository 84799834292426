// @flow strict

import type {
  UserDetails,
  AgencyList,
  JobsData,
} from 'src/types/referrer-portal';
import type {
  ReferralPrograms,
  ReferralSelectedQuestionnaire,
} from 'src/types/referral-v2';
import type {JobDescription} from 'src/types/referral-external';
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-referrer-portal.js';
import {DEFAULT_API_ERROR} from 'src/utils/referrer-portal';

import {
  RECEIVE_USER_DETAILS,
  RECEIVE_USER_DETAILS_ERROR,
  RECEIVE_AGENCY,
  RECEIVE_AGENCY_ERROR,
} from 'src/action-creators/referrer-portal/authentication.js';

// $FlowFixMe[incompatible-use]
const defaultApiErrorMessage = DEFAULT_API_ERROR.non_field_errors[0]?.message;

export const RECEIVE_JOB_DESCRIPTION = 'receive/job-description';
export const RECEIVE_JOB_DESCRIPTION_ERROR = 'receive/job-description-error';

export const RECEIVE_PROGRAM_QUESTIONS = 'receive/program-questions';
export const RECEIVE_PROGRAM_QUESTIONS_ERROR =
  'receive/program-questions-error';

export const RECEIVE_REFERRAL_PROGRAMS = 'receive/referral-programs';
export const RECEIVE_REFERRAL_PROGRAMS_ERROR =
  'receive/referral-programs-error';

export const RECEIVE_JOBS = 'receive/jobs';
export const RECEIVE_JOBS_ERROR = 'receive/jobs-error';

export const RECEIVE_JOB_LOCATIONS = 'receive/job-locations';
export const RECEIVE_JOB_LOCATIONS_ERROR = 'receive/job-locations-error';

type JobDescriptionAction = {
  type: typeof RECEIVE_JOB_DESCRIPTION,
  payload: JobDescription,
};

type JobDescriptionErrorAction = {
  type: typeof RECEIVE_JOB_DESCRIPTION_ERROR,
  payload?: ?string,
};

type ProgramQuestionAction = {
  type: typeof RECEIVE_PROGRAM_QUESTIONS,
  payload: ReferralSelectedQuestionnaire[],
};

type ProgramQuestionErrorAction = {
  type: typeof RECEIVE_PROGRAM_QUESTIONS_ERROR,
  payload?: ?string,
};

type ReferralProgramAction = {
  type: typeof RECEIVE_REFERRAL_PROGRAMS,
  payload: ReferralPrograms[],
};

type ReferralProgramErrorAction = {
  type: typeof RECEIVE_REFERRAL_PROGRAMS_ERROR,
  payload?: ?string,
};

type JobsAction = {
  type: typeof RECEIVE_JOBS,
  payload: JobsData[],
};

type JobsErrorAction = {
  type: typeof RECEIVE_JOBS_ERROR,
  payload?: ?string,
};

type JobLocationAction = {
  type: typeof RECEIVE_JOB_LOCATIONS,
  payload: string[],
};

type JobLocationErrorAction = {
  type: typeof RECEIVE_JOB_LOCATIONS_ERROR,
  payload?: ?string,
};

export type UserFormsActions =
  | JobDescriptionAction
  | JobDescriptionErrorAction
  | ProgramQuestionAction
  | ProgramQuestionErrorAction
  | ReferralProgramAction
  | ReferralProgramErrorAction
  | JobsAction
  | JobsErrorAction
  | JobLocationAction
  | JobLocationErrorAction;

export const getUser: (referralLinkId: string) => ThunkAction<void> = flow(
  key(() => `form/user`),
  fetching(),
)(
  (referralLinkId) => (dispatch: Dispatch) =>
    dispatch(reduxApi.get(`form/user?link_id=${referralLinkId}`)).then(
      (response: UserDetails) => {
        dispatch({type: RECEIVE_USER_DETAILS, payload: response});
      },
      (error) => {
        const errorMessage: string =
          error?.responseBody?.message || defaultApiErrorMessage;
        dispatch({
          type: RECEIVE_USER_DETAILS_ERROR,
          payload: errorMessage,
        });
      },
    ),
);

export const getAllowedAgency: (referralLinkId: string) => ThunkAction<void> =
  flow(
    key(() => `form/user/agencies`),
    fetching(),
  )((referralLinkId) => (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.get(`form/user/agencies?link_id=${referralLinkId}`),
    ).then(
      (response: AgencyList[]) => {
        dispatch({type: RECEIVE_AGENCY, payload: response});
      },
      (error) => {
        const errorMessage: string =
          error?.responseBody?.message || defaultApiErrorMessage;
        dispatch({
          type: RECEIVE_AGENCY_ERROR,
          payload: errorMessage,
        });
      },
    );
  });

export const getAgencyPrograms: (agencyId: string) => ThunkAction<void> = flow(
  key((agencyId: string) => `form/agency/${agencyId}/programs`),
  fetching(),
)((agencyId) => (dispatch: Dispatch) => {
  return dispatch(reduxApi.get(`form/agency/${agencyId}/programs`)).then(
    (response: ReferralPrograms[]) => {
      dispatch({
        type: RECEIVE_REFERRAL_PROGRAMS,
        payload: response,
      });
    },
    (error) => {
      const errorMessage: string =
        error?.responseBody?.message || defaultApiErrorMessage;
      dispatch({
        type: RECEIVE_REFERRAL_PROGRAMS_ERROR,
        payload: errorMessage,
      });
    },
  );
});

export const getProgramJobs: (
  agencyId: string,
  programId: string,
  query: {search_str: string, offset: number, limit: number, location: string},
) => ThunkAction<void> = flow(
  key(
    (agencyId, programId) =>
      `form/agency/${agencyId}/programs/${programId}/jobs`,
  ),
  fetching(),
)((agencyId, programId, query) => (dispatch: Dispatch) => {
  const {search_str = '', offset = 0, limit = 0, location = ''} = query;
  const queryString = `?offset=${offset}&limit=${limit}&search_str=${search_str}&location=${location}`;

  return dispatch(
    reduxApi.get(
      `form/agency/${agencyId}/programs/${programId}/jobs${queryString}`,
    ),
  ).then(
    (response: JobsData[]) => {
      dispatch({
        type: RECEIVE_JOBS,
        payload: response,
      });
    },
    (error) => {
      const errorMessage: string =
        error?.responseBody?.message || defaultApiErrorMessage;
      dispatch({
        type: RECEIVE_JOBS_ERROR,
        payload: errorMessage,
      });
    },
  );
});

export const fetchJobDescription: (
  agencyId: string,
  jobId: string,
) => ThunkAction<void> = flow(
  key((agencyId, jobId) => `form/agency/${agencyId}/jobs/${jobId}/description`),
  fetching(),
)((agencyId, jobId) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.get(`form/agency/${agencyId}/jobs/${jobId}/description`),
  ).then(
    (response: JobDescription) => {
      dispatch({
        type: RECEIVE_JOB_DESCRIPTION,
        payload: response,
      });
    },
    (error) => {
      const errorMessage: string =
        error?.responseBody?.message || defaultApiErrorMessage;
      dispatch({
        type: RECEIVE_JOB_DESCRIPTION_ERROR,
        payload: errorMessage,
      });
    },
  );
});

export const fetchJobsLocation: (
  agencyId: string,
  programId: string,
) => ThunkAction<void> = flow(
  key(
    (agencyId, programId) =>
      `form/agency/${agencyId}/programs/${programId}/locations`,
  ),
  fetching(),
)((agencyId, programId) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.get(`form/agency/${agencyId}/programs/${programId}/locations`),
  ).then(
    (response: string[]) => {
      dispatch({
        type: RECEIVE_JOB_LOCATIONS,
        payload: response,
      });
    },
    (error) => {
      const errorMessage: string =
        error?.responseBody?.message || defaultApiErrorMessage;
      dispatch({
        type: RECEIVE_JOB_LOCATIONS_ERROR,
        payload: errorMessage,
      });
    },
  );
});

export const getProgramQuestions: (
  agencyId: string,
  programId: string,
) => ThunkAction<void> = flow(
  key(
    (agencyId, programId) =>
      `form/agency/${agencyId}/programs/${programId}/questionnaire`,
  ),
  fetching(),
)((agencyId, programId) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.get(`form/agency/${agencyId}/programs/${programId}/questionnaire`),
  ).then(
    (response: ReferralSelectedQuestionnaire[]) => {
      dispatch({
        type: RECEIVE_PROGRAM_QUESTIONS,
        payload: response,
      });
    },
    (error) => {
      const errorMessage: string =
        error?.responseBody?.message || defaultApiErrorMessage;
      dispatch({
        type: RECEIVE_PROGRAM_QUESTIONS_ERROR,
        payload: errorMessage,
      });
    },
  );
});
