// @flow strict

import * as React from 'react';
import {classify} from 'src/utils/classify';
import css from './index.css';


type Props = {
  className?: string,
};

export const Separator = ({className}: Props): React.Node => (
  <div className={classify(css.separator, className)} />
);

export default Separator;
