// @flow strict
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'src/rerouter';

import {CONSTANTS} from 'src/components/authentication/contants.js';
import {ERROR_CODES} from 'src/components/authentication/errors.js';

import {
  BodyLarge,
  TitleMedium,
  BodyMedium,
  ButtonTextMedium,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {Input} from '@spaced-out/ui-design-system/lib/components/Input';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {InContextAlert} from '@spaced-out/ui-design-system/lib/components/InContextAlert';

import {signUpUser} from 'src/action-creators/authentication.js';

import {selectSignUpError} from 'src/selectors/authentication';
import PasswordInstruction from 'src/components/authentication/common/password-instruction/password-instruction.jsx';
import PasswordConfirmation from 'src/components/authentication/common/password-confirmation/password-confirmation.jsx';

import type {SignUpFormProps} from 'src/types/authentication.js';

import css from './sign-up.css';
import maincss from 'src/components/authentication/main.css';


const SignUpForm = (props: SignUpFormProps): React.Node => {
  const {inviteEmail, inviteCode, inviteCodeError} = props;

  const router = useHistory();
  const dispatch = useDispatch();

  const signUpError = useSelector(selectSignUpError);

  const [fullName, setFullName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [passwordStrength, setPasswordStrength] = React.useState<number>(0);
  const [newPassword, setNewPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [disableSignUp, setDisableSignUp] = React.useState<boolean>(true);
  const [isSigningUp, setIsSigningUp] = React.useState<boolean>(false);
  const [isNameValid, setIsNameValid] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (passwordStrength < 3) {
      setDisableSignUp(true);
    } else if (newPassword !== confirmPassword) {
      setDisableSignUp(true);
    } else if (!fullName) {
      setDisableSignUp(true);
    } else {
      setDisableSignUp(false);
    }
  }, [passwordStrength, newPassword, confirmPassword, fullName]);

  const setName = (value) => {
    setFullName(value);
    setIsNameValid(!!value);
  };

  const goToSignIn = () => {
    router.replace('/signin');
  };

  const handleSignUp = async () => {
    setIsSigningUp(true);
    const apiResponse = await dispatch(
      signUpUser(fullName, inviteEmail || email, newPassword, inviteCode),
    );

    if (apiResponse) {
      const {host, protocol} = window.location;
      const baseDomain = host.substring(host.indexOf('.') + 1);
      if (apiResponse?.agency?.mfa_auth_channel) {
        const path = '/signin?fromSignUp=true';
        router.replace(path);
      } else {
        const path = `${protocol}//${apiResponse.agency.slug}.${baseDomain}`;
        window.location = path;
      }
    }
    setIsSigningUp(false);
  };

  return (
    <div className={maincss.contentSideBar}>
      <div className={css.signUpcontainerContent}>
        <div className={css.signUpSection}>
          <TitleMedium> {CONSTANTS.SIGNUP_TITLE} </TitleMedium>
          <BodyLarge className={maincss.subText}>
            {CONSTANTS.SIGNUP_SUBTEXT}
          </BodyLarge>
          {inviteCodeError && (
            <InContextAlert semantic="danger" classNames={{wrapper: css.alert}}>
              {ERROR_CODES[inviteCodeError]}
            </InContextAlert>
          )}
          <div className={css.input}>
            <Input
              label="Name"
              placeholder="Enter full name"
              size="medium"
              type="text"
              value={fullName}
              error={!isNameValid}
              errorText={CONSTANTS.INVALID_FULL_NAME}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className={css.input}>
            <Input
              label="Email"
              placeholder="Enter email"
              size="medium"
              type="text"
              value={inviteEmail || email}
              disabled={!!inviteEmail}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={css.instructions}>
            <PasswordInstruction />
          </div>
          <div className={css.confirmation}>
            <PasswordConfirmation
              passwordLabel={'New password'}
              passwordPlaceholder={'Enter password'}
              confirmPasswordLabel={'Confirm password'}
              confirmPasswordPlaceholder={'Repeat password'}
              setPasswordStrengthValue={setPasswordStrength}
              setNewPasswordValue={setNewPassword}
              setConfirmPasswordValue={setConfirmPassword}
            />
          </div>
          <Button
            size="medium"
            type="primary"
            classNames={{wrapper: css.signUpBtn}}
            disabled={disableSignUp || isSigningUp}
            onClick={handleSignUp}
          >
            {isSigningUp ? 'Signing up' : 'Sign up'}
          </Button>
          {signUpError && (
            <InContextAlert semantic="danger" classNames={{wrapper: css.alert}}>
              {ERROR_CODES[signUpError] || ERROR_CODES['GenericSignUpError']}
            </InContextAlert>
          )}
          <div className={css.signInSection}>
            <BodyMedium className={css.forgotLoginText}>Or</BodyMedium>
            <ButtonTextMedium
              color="clickable"
              className={css.signInLink}
              onClick={goToSignIn}
            >
              Sign in
            </ButtonTextMedium>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
