// @flow

import * as React from 'react';
import {
  useHistory,
  type Params,
  type RouterHistory,
  type GetElement,
  type ElementContext,
  type RerouterLocation,
  type LocationArg,
} from 'src/rerouter';

import {useFullScreenApp} from 'src/hooks/useFullScreenApp';

import Loading from 'src/components/lib/loading';
import {AnalyticsService} from 'src/analytics';


export function container(
  Component: React.ComponentType<{children: React.Node}>,
): GetElement {
  return ({children, pathname}) => (
    <Component key={pathname} children={children} />
  );
}

export function imbue<C: ElementContext>(
  Component: React.ComponentType<C>,
): GetElement {
  // $FlowFixMe[incompatible-type]
  return (context) => <Component {...context} key={context.pathname} />;
}

export function imbueLegacy<
  C: {
    ...ElementContext,
    router: RouterHistory,
  },
>(Component: React.ComponentType<C>): GetElement {
  return (context) => (
    // $FlowFixMe[incompatible-type]
    <Component {...context} router={context.history} key={context.pathname} />
  );
}

export function redirect(to: LocationArg): React.Node {
  return (
    <OnEnter
      callback={(history) => {
        history.replace(to);
      }}
      params={null}
    />
  );
}

export function trackPageView(
  forceChildren: GetElement | React.Node = null,
): GetElement {
  return (context) => {
    const finalChildren =
      typeof forceChildren === 'function'
        ? forceChildren(context)
        : forceChildren;
    function fireEvent() {
      try {
        AnalyticsService.track('page_view', {
          url: context.location.pathname,
        });
      } catch (error) {}
    }
    const {children, pathname, params} = context;
    return (
      <OnEnter
        key={pathname}
        params={params}
        callback={fireEvent}
        children={finalChildren || children}
      />
    );
  };
}

export function onEnter(
  callback: (RouterHistory, Params) => void | (() => void),
  forceChildren: GetElement | React.Node = null,
): GetElement {
  return (context) => {
    const finalChildren =
      typeof forceChildren === 'function'
        ? forceChildren(context)
        : forceChildren;
    const {children, pathname, params} = context;
    return (
      <OnEnter
        key={pathname}
        params={params}
        callback={callback}
        children={finalChildren || children}
      />
    );
  };
}

export function onEnterAsync(
  callback: (RouterHistory, Params) => Promise<mixed>,
  forceChildren: React.Node = null,
): GetElement {
  return onEnter((history, params) => {
    callback(history, params);
  }, forceChildren);
}

// TODO (kyle): there is a possible bug here. `onEnter` will not fire when
// moving between two `OnEnter` components in the same place in the tree.
export function OnEnter<P>({
  callback,
  params,
  children = null,
}: {
  callback: (RouterHistory, P) => void | (() => void),
  params: P,
  children?: React.Node,
}): React.Node {
  const history = useHistory();
  React.useEffect(() => callback(history, params), []);
  return children;
}

//NOTE:(diwakersurya) this function can be used while defining route to decide whether
// the experience should be without top and left nav.
export function fullscreen<C: ElementContext>(
  Component: React.ComponentType<C>,
): GetElement {
  return (context) => {
    // $FlowFixMe[incompatible-type]
    const children = <Component {...context} key={context.pathname} />;

    return <FullScreen>{children}</FullScreen>;
  };
}

export function FullScreen({
  children = null,
}: {
  children?: React.Node,
}): React.Node {
  useFullScreenApp();
  return children;
}

export function split<C>(
  importer: () => Promise<{default: React.ComponentType<C>}>,
): (C) => React.Node {
  const SplitComponent = React.lazy(importer);
  return (props: C) => (
    <React.Suspense fallback={<Loading />}>
      <SplitComponent {...props} />
    </React.Suspense>
  );
}
