// @flow

import type {State} from 'src/reducers';
import type {Template} from 'src/types/templates';

import sortBy from 'lodash/sortBy';
import {createSelector} from 'reselect';


export const getTemplate = (state: State, id: string): ?Template =>
  state.templates.templates.find((template) => template.id === id);

export const selectSortedTemplates: (State) => Template[] = createSelector<
  State,
  void,
  Template[],
  Template[],
>(
  (state) => state.templates.templates,
  (templates) => sortBy(templates, 'name'),
);
