// @flow strict

import type {RuleData} from 'src/types/referral-v2';
// $FlowFixMe[nonstrict-import]
import type {DynamicLabelExtra} from 'src/types/dynamic-labels';

import * as React from 'react';

import classify from 'src/utils/classify';
import {
  booleanDropdownOptions,
  getOperatorOptions,
} from 'src/utils/referral-v2.js';
import {VALUE_NOT_REQUIRED_OPERATORS} from 'src/utils/filter.js';

import {FormLabelSmall} from '@spaced-out/ui-design-system/lib/components/Text';
import {Dropdown} from '@spaced-out/ui-design-system/lib/components/Dropdown';
import {
  elevationToast,
  elevationModal,
} from '@spaced-out/ui-design-system/lib/styles/variables/_elevation';
import {type MenuOption} from '@spaced-out/ui-design-system/lib/components/Menu';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {Tooltip} from '@spaced-out/ui-design-system/lib/components/Tooltip';
import {Icon} from '@spaced-out/ui-design-system/lib/components/Icon';
import {Input} from '@spaced-out/ui-design-system/lib/components/Input';
import css from 'src/components/referral-v2/configure/configure.css';
import rulesCss from 'src/components/referral-v2/settings/settings.css';


type RuleProps = {
  disabled: boolean,
  options: ?(DynamicLabelExtra[]),
  rule: ?DynamicLabelExtra,
  fieldObject?: RuleData,
  handleSelectRule: (option: MenuOption) => mixed,
  onRemove: () => mixed,
  onEdit: (fieldObject: RuleData) => mixed,
  showAndText: boolean,
  hideRemoveIcon?: boolean,
};

const Rule = ({
  disabled,
  options,
  rule,
  fieldObject,
  handleSelectRule,
  onRemove,
  onEdit,
  showAndText,
  hideRemoveIcon,
}: RuleProps): React.Node => {
  const ruleInputType = rule?.type;
  const disabledRuleValueInput =
    VALUE_NOT_REQUIRED_OPERATORS.includes(fieldObject?.operator) ||
    !fieldObject?.field_name ||
    ruleInputType?.includes('date') ||
    disabled;

  const isOperatorDisabled = ruleInputType === 'date' || disabled;
  const operatorOptions = ruleInputType
    ? getOperatorOptions(ruleInputType)
    : [];

  const customOperatorOptions = operatorOptions.map(({key, label, tooltip}) => {
    return {
      key,
      customComponent: (
        <div className={rulesCss.customOption}>
          <div>{label}</div>
          {tooltip && (
            <Tooltip body={tooltip} elevation={elevationModal} bodyMaxLines={5}>
              <Icon
                color="secondary"
                name="info-circle"
                size="small"
                type="regular"
              />
            </Tooltip>
          )}
        </div>
      ),
    };
  });

  const handleEdit = (fieldName: string, value: string | number) => {
    const data: RuleData = {
      field_name: fieldObject?.field_name ?? '',
      operator: fieldObject?.operator ?? '',
      rule_value: fieldObject?.rule_value ?? '',
    };

    const updatedData = {
      ...data,
      [fieldName]: value,
    };

    onEdit(updatedData);
  };

  const selectedOperator = operatorOptions.find(
    (operator) => operator.key === fieldObject?.operator,
  );

  return (
    <div
      key={rule?.attributeId}
      className={classify(rulesCss.ruleInputContainer, rulesCss.defaultGap)}
    >
      <div className={rulesCss.andText}>
        {showAndText && <FormLabelSmall color="secondary">And</FormLabelSmall>}
      </div>
      <Dropdown
        required={true}
        disabled={disabled}
        size="small"
        label="Field name"
        dropdownInputText={fieldObject?.field_name ?? 'Select'}
        onChange={handleSelectRule}
        menu={{
          classNames: {
            wrapper: rulesCss.option,
            optionTextContainer: rulesCss.option,
          },
          isFluid: true,
          options: options ?? [],
          selectedKeys: fieldObject?.field_name ? [fieldObject.field_name] : [],
          allowSearch: true,
        }}
      />
      <Dropdown
        required={true}
        disabled={isOperatorDisabled}
        menu={{
          options: customOperatorOptions,
          selectedKeys: selectedOperator?.key ? [selectedOperator.key] : [],
          classNames: {
            wrapper: rulesCss.option,
            optionTextContainer: rulesCss.option,
          },
        }}
        onChange={({key}) => handleEdit('operator', key)}
        size="small"
        label="Operator"
        dropdownInputText={selectedOperator?.label ?? 'Select'}
      />
      {ruleInputType?.includes('boolean') ? (
        <Dropdown
          disabled={disabled}
          size="small"
          menu={{
            isFluid: false,
            options: booleanDropdownOptions,
            size: 'small',
            selectedKeys: fieldObject?.rule_value
              ? [fieldObject.rule_value]
              : [],
            classNames: {
              wrapper: rulesCss.option,
              optionTextContainer: rulesCss.option,
            },
          }}
          onChange={({key}) => handleEdit('rule_value', key)}
          dropdownInputText={fieldObject?.rule_value ?? 'Select'}
        />
      ) : (
        <Input
          required={!disabledRuleValueInput}
          disabled={disabledRuleValueInput}
          size="small"
          label="Value"
          value={
            disabledRuleValueInput ? 'Not applicable' : fieldObject?.rule_value
          }
          type={disabledRuleValueInput ? 'text' : ruleInputType}
          onChange={(event) => handleEdit('rule_value', event.target.value)}
        />
      )}
      {!disabled && (
        <div className={css.removeRuleButtonContainer}>
          <Tooltip
            body="Delete rule"
            bodyMaxLines={1}
            placement="left"
            elevation={elevationToast}
          >
            <Button
              classNames={{
                wrapper: classify(css.removeRuleButton, css.showOnRowHover),
              }}
              onClick={() => onRemove()}
              disabled={hideRemoveIcon}
              ariaLabel="Delete Rule"
              size="small"
              type="tertiary"
              iconRightName="trash"
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default Rule;
