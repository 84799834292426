// @flow strict

import * as React from 'react';
import css from './vertical-timeline-element.css';
import classify from 'src/utils/classify';


export type VerticalTimelineElementProps = {
  id: number,
  header?: React.Node,
  icon?: React.Node,
  isInProgress?: boolean,
  circleColor?: string,
  lineStyle?: 'solid' | 'dashed',
  lineColor?: string,
  children?: React.Node,
};

export const VerticalTimelineElement = (
  props: VerticalTimelineElementProps,
): React.Node => {
  const {
    lineStyle,
    lineColor,
    icon,
    isInProgress,
    circleColor,
    header,
    children,
  } = props;
  const customStyle = {};
  if (lineStyle) {
    customStyle['--lineStyle'] = lineStyle;
  }
  if (lineColor) {
    customStyle['--lineColor'] = lineColor;
  }
  return (
    <div className={css.timelineElementContainer} style={customStyle}>
      {props.icon ? (
        <div className={css.timelineIcon}>{icon}</div>
      ) : (
        <div
          className={classify(css.timelineCircle, {
            [css.loading]: isInProgress,
          })}
          style={circleColor ? {backgroundColor: circleColor} : {}}
        ></div>
      )}

      <div className={css.timelineContent}>
        {!!header && <div className={css.headerBlock}>{header}</div>}
        <div className={css.customComponent}>{children}</div>
      </div>
    </div>
  );
};
