// @flow strict

import * as React from 'react';
import invariant from 'invariant';

import {pageHeight} from 'src/utils/dom';


export type ChildProps = {
  onOpen: () => void,
  isOpen: boolean,
  height: ?number,
  pageBottom: ?number,
  cancelNext: () => void,
  clickAway: () => void,
  anchorRef: (?HTMLElement) => mixed,
};

type ClickAwayProps = {
  children: (props: ChildProps) => React.Node,
  shouldCancel: (event: MouseEvent) => boolean,
  onChange?: (isOpen: boolean) => mixed,
};

type ClickAwayState = {
  isOpen: boolean,
  height: ?number,
  pageBottom: ?number,
};

export default class ClickAway extends React.Component<
  ClickAwayProps,
  ClickAwayState,
> {
  static defaultProps: {shouldCancel: () => boolean} = {
    shouldCancel: () => false,
  };

  state: ClickAwayState = {
    isOpen: false,
    height: null,
    pageBottom: null,
  };

  el: ?HTMLElement = null;
  cancelNext: boolean = false;

  componentDidMount() {
    if (this.el) {
      this.setState({
        height: this.el.offsetHeight,
        pageBottom: this.pageBottom(),
      });
    }
  }

  componentDidUpdate(prevProps: ClickAwayProps, prevState: ClickAwayState) {
    const {isOpen} = this.state;
    if (prevState.isOpen !== isOpen) {
      if (this.state.isOpen) {
        window.document.addEventListener('click', this.handleCloseClick);
      } else {
        window.document.removeEventListener('click', this.handleCloseClick);
      }
    }
  }

  componentWillUnmount() {
    window.document.removeEventListener('click', this.handleCloseClick);
  }

  render(): React.Node {
    const {height, isOpen, pageBottom} = this.state;
    return this.props.children({
      onOpen: this.handleOpenClick,
      isOpen,
      height,
      pageBottom,
      cancelNext: this.cancelNextClickAway,
      clickAway: this.forceClose,
      anchorRef: (el) => (this.el = el),
    });
  }

  handleOpenClick: () => void = () => {
    // NOTE (kyle): we recalculate the position on click because sibling and neice components
    // could have changed.
    let {pageBottom} = this.state;
    if (this.el) {
      pageBottom = this.pageBottom();
    }

    this.setState(
      {
        isOpen: true,
        pageBottom,
      },
      this.handleOnChange,
    );
  };

  handleCloseClick: (evt: MouseEvent) => void = (evt: MouseEvent) => {
    if (this.props.shouldCancel(evt)) {
      return;
    }

    if (this.cancelNext === true) {
      this.cancelNext = false;
    } else {
      this.setState(
        {
          isOpen: false,
        },
        this.handleOnChange,
      );
    }
  };

  forceClose: () => void = () => {
    this.setState({isOpen: false}, this.handleOnChange);
  };

  cancelNextClickAway: () => void = () => {
    this.cancelNext = true;
  };

  handleOnChange: () => mixed = () =>
    this.props.onChange && this.props.onChange(this.state.isOpen);

  pageBottom(): $FlowFixMe {
    invariant(this.el, 'pageBottom() requires that this.el not be null');
    const bottomBound = this.el ? this.el.getBoundingClientRect().bottom : 0;
    return pageHeight() - bottomBound + window.scrollY;
  }
}

/*
 // WIP
function useClickaway({shouldCancel, onChange}: {
  shouldCancel: (event: MouseEvent) => boolean,
  onChange?: (isOpen: boolean) => mixed,
}): ChildProps {
  const anchorRef = React.useRef();

  const [state, setState] = React.useState({
    isOpen: false,
    height: null,
    pageBottom: null,
  });

  const handleOpenClick = () => {
    // NOTE (kyle): we recalculate the position on click because sibling and neice components
    // could have changed.
    let {pageBottom} = state;
    if (anchorRef.current) {
      pageBottom = this.pageBottom();
    }

    setState(
      {
        ...state,
        isOpen: true,
        pageBottom,
      },
    );
  };

  React.useEffect(() => {
    if (state.isOpen) {
      const handleCloseClick = (event) => {
        if (shouldCancel(event)) {
          return;
        }
      };

      window.document.addEventListener('click', handleCloseClick);

      return () => {
        window.document.removeEventListener('click', handleCloseClick);
      };
    }
  }, [state.isOpen]);

  return {
    ...state,
    onOpen: handleOpenClick,
  };
}
*/
