// @flow strict

import * as React from 'react';

import classify from 'src/utils/classify';

import css from './hover-button.css';


type ButtonProps = {
  className: string,
  onClick?: (SyntheticEvent<>) => mixed,
  style?: ?{[string]: string | number, ...},
  ...
};

export type HoverButtonProps = {
  // $FlowFixMe
  [string]: any,
  ElementType?: React.ComponentType<ButtonProps> | string,
  children: React.Node,
  onClick?: (SyntheticEvent<>) => mixed,
  bgIconClassName?: string,
  backgroundColor?: string,
  className?: string,
  justify?: 'start' | 'center' | 'end',
  isClickable?: mixed,
  hoverDisabled?: boolean,
  active?: boolean,
  disabled?: boolean,
};

export const HoverButton = (
  props: HoverButtonProps,
): React.Node | React.Element<string> => {
  const {
    ElementType = 'button',
    children,
    onClick,
    className,
    bgIconClassName,
    backgroundColor,
    rootClassName,
    justify = 'center',
    isClickable = true,
    hoverDisabled = false,
    active = false,
    disabled = false,
    ...rest
  } = props;

  return (
    // $FlowFixMe
    <ElementType
      {...rest}
      type="button"
      role="button"
      className={classify(css.hoverBtn, rootClassName)}
      onClick={(evt) => {
        if (!disabled) {
          onClick?.(evt);
        }
      }}
    >
      <div
        className={classify(
          css.hoverContainer,
          css[justify],
          isClickable && !disabled && css.isClickable,
        )}
      >
        <div className={classify(css.hoverContent, className)}>{children}</div>
        <div
          className={classify(bgIconClassName, {
            [css.hoverBg]: !hoverDisabled,
            [css.hoverBgActive]: active,
          })}
          style={backgroundColor && {backgroundColor}}
        />
      </div>
    </ElementType>
  );
};
