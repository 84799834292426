// @flow strict

import type {Attribute, Designation} from 'src/types/field-mapping';
import * as React from 'react';
import {Select} from 'src/designSystem2021Components/select.jsx';
import type {SelectOption} from 'src/designSystem2021Components/select.jsx';
import {Smallest, Clickable} from 'src/designSystem2021Components/text-v2.jsx';
import {
  //$FlowFixMe[nonstrict-import]
  MouseTip,
} from 'src/components/lib/mouse-tip/mouse-tip.jsx';
import {useDispatch} from 'react-redux';
import {updateLocalStandardEntityDesignation} from 'src/action-creators/field-mapping.js';

import WarningIcon from 'src/images/icons/alert-modal.svg?noAttrs';
import DoneIcon from 'src/images/icons/done_all.svg?noAttrs';
import css from './field-selection-table.css';


type standardEntityDesignationsProps = {
  standardEntityType: string,
  designations: Designation[],
  entityAttributesList?: Attribute[],
  entityIndex: number,
  updateSectionLevelError: (index: number, error: boolean) => void,
};

const StandardEntityTypeSection = ({
  standardEntityType,
  designations,
  entityAttributesList,
  entityIndex,
  updateSectionLevelError,
}: standardEntityDesignationsProps): React.Element<'div'> => {
  const dispatch = useDispatch();
  const [selectedOptionsCountMap, setSelectedOptionsCountMap] = React.useState(
    {},
  );
  React.useEffect(() => {
    const selectedOptionsCountMapInitial = {};
    for (const designation of designations) {
      if (designation.entity_attribute_id) {
        if (!selectedOptionsCountMapInitial[designation.entity_attribute_id]) {
          selectedOptionsCountMapInitial[designation.entity_attribute_id] = 1;
        } else {
          selectedOptionsCountMapInitial[designation.entity_attribute_id] =
            selectedOptionsCountMapInitial[designation.entity_attribute_id] + 1;
        }
      }
    }
    setSelectedOptionsCountMap(selectedOptionsCountMapInitial);
  }, [designations]);

  const fieldLevelErrorMap = React.useRef([false]);
  const updateFieldLevelErrorMap = (index, error) => {
    fieldLevelErrorMap.current[index] = error;
    let orWithInitial = false;
    orWithInitial = fieldLevelErrorMap.current.reduce(
      (previousValue, currentValue) => previousValue || currentValue,
      false,
    );
    updateSectionLevelError(entityIndex, !!orWithInitial);
  };

  const setSelectedAttribute = (option, designationName, entityAttributeId) => {
    updateSelectedOptionsCountMap(
      option?.value,
      designationName,
      entityAttributeId,
    );
    dispatch(
      updateLocalStandardEntityDesignation({
        standard_entity_type: standardEntityType,
        designation_name: designationName,
        entity_attribute_name: option?.label || null,
        entity_attribute_id: option?.value || null,
      }),
    );
  };
  const updateSelectedOptionsCountMap = (
    newEntityAttributeId = null,
    designationName,
    entityAttributeId,
  ) => {
    if (entityAttributeId === newEntityAttributeId) {
      return;
    }
    const selectedOptionsCountMapTemp = {...selectedOptionsCountMap};
    // decrease count of previously selected entity
    if (entityAttributeId && selectedOptionsCountMapTemp[entityAttributeId]) {
      selectedOptionsCountMapTemp[entityAttributeId] =
        selectedOptionsCountMapTemp[entityAttributeId] - 1;
    }

    // Increase count of newly selected entity
    if (newEntityAttributeId) {
      if (!selectedOptionsCountMapTemp[newEntityAttributeId]) {
        selectedOptionsCountMapTemp[newEntityAttributeId] = 1;
      } else {
        selectedOptionsCountMapTemp[newEntityAttributeId] =
          selectedOptionsCountMapTemp[newEntityAttributeId] + 1;
      }
    }
    setSelectedOptionsCountMap(selectedOptionsCountMapTemp);
  };

  const checkError = (selectedEntityAttributeId, designationIndex) => {
    let errorState;
    if (
      selectedEntityAttributeId &&
      selectedOptionsCountMap[selectedEntityAttributeId] === 1
    ) {
      errorState = false;
    } else if (
      selectedEntityAttributeId &&
      selectedOptionsCountMap[selectedEntityAttributeId] > 1
    ) {
      errorState = true;
    }
    updateFieldLevelErrorMap(designationIndex, errorState);
    return errorState;
  };

  const filterOptions = (options, dataType): SelectOption<string>[] => {
    const filterredAttributes = options.filter(
      (option) => option.data_type === dataType,
    );
    const mapAttributeArrayAsOptions = filterredAttributes.map(
      (attributeObj) => ({
        label: attributeObj.attribute_name,
        value: attributeObj.attribute_id,
      }),
    );
    return mapAttributeArrayAsOptions;
  };

  return (
    <div className={css.entityTypeBox}>
      <Smallest className={css.entityType}>{standardEntityType}</Smallest>
      {designations.map((designationObj, designationIndex) => (
        <div className={css.attributeRow} key={designationObj.designation_name}>
          <div className={css.attributeNameBox}>
            <Clickable className={css.attributeNameText}>
              {designationObj.designation_name}
            </Clickable>
          </div>
          {entityAttributesList && designationObj && (
            <>
              {checkError(
                designationObj.entity_attribute_id,
                designationIndex,
              ) ? (
                <MouseTip
                  content={
                    <>
                      Same ATS field is mapped to one or more attributes. Please
                      check and resolve.
                    </>
                  }
                  fixedTo="top"
                >
                  <div className={css.selectWrapBox}>
                    <Select
                      options={filterOptions(
                        entityAttributesList,
                        designationObj.data_type,
                      )}
                      value={{
                        label: designationObj.entity_attribute_name || '',
                        value: designationObj.entity_attribute_id || '',
                      }}
                      placeholder="Select Field"
                      onChange={(option) =>
                        setSelectedAttribute(
                          option,
                          designationObj.designation_name,
                          designationObj.entity_attribute_id,
                        )
                      }
                      classNames={{
                        container: css.selectContainer,
                        box: css.selectBox,
                      }}
                      error={true}
                    />
                    <div className={css.placeholder}></div>
                  </div>
                </MouseTip>
              ) : (
                <div className={css.selectWrapBox}>
                  <Select
                    options={filterOptions(
                      entityAttributesList,
                      designationObj.data_type,
                    )}
                    value={{
                      label: designationObj.entity_attribute_name || '',
                      value: designationObj.entity_attribute_id || '',
                    }}
                    placeholder="Select Field"
                    onChange={(option) =>
                      setSelectedAttribute(
                        option,
                        designationObj.designation_name,
                        designationObj.entity_attribute_id,
                      )
                    }
                    classNames={{
                      container: css.selectContainer,
                      box: css.selectBox,
                    }}
                    error={false}
                  />
                  {designationObj.entity_attribute_id ? (
                    <DoneIcon className={css.doneIcon} />
                  ) : (
                    <WarningIcon className={css.warningIcon} />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default StandardEntityTypeSection;
