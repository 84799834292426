// @flow strict

import type {
  Entity,
  Filters,
  KPIData,
  FilterByID,
  MetricsType,
  J2FiltersOptions,
  J2SelectedFilters,
  WorkflowOverviewDataTable,
  SelectedWorkflowFilterColumnsData,
  CurrentWorkflowAndNodeBreadCrumbData,
  SavedDashboardFiltersList,
  SavedDashboardFiltersListData,
} from 'src/types/analytics-genesis';
import type {LineSeriesItem} from '@spaced-out/ui-design-system/lib/components/Charts';
import type {DateRangeWithTimezone} from '@spaced-out/ui-design-system/lib/types';
import type {AnalyticsWorkflowsOverviewActions} from 'src/action-creators/analytics/analytics-workflows-overview';

import {
  RECEIVE_KPI_DATA,
  RECEIVE_FILTERS_OPTIONS,
  RECEIVE_WORKFLOW_OPTIONS,
  RECEIVE_WORKFLOW_PLOT_DATA,
  RECEIVE_WORKFLOW_TABLE_DATA,
  RECEIVE_NODE_TABLE_DATA,
  RECEIVE_PEOPLE_TABLE_DATA,
  RECEIVE_SAVED_DASHBOARDS,
  SET_CHART_FREQUENCY,
  SET_SELECTED_FILTERS,
  SET_SELECTED_ENTITIES,
  SET_SELECTED_WORKFLOWS,
  SET_SELECTED_METRICS_TYPE,
  SET_SELECTED_DATE_RANGE_AND_FREQUENCY,
  CLEAR_SELECTED_FILTERS,
  RECEIVE_WORKFLOW_TABLE_COLUMNS,
  RECEIVE_NODE_TABLE_COLUMNS,
  RECEIVE_PEOPLE_TABLE_COLUMNS,
  SET_CURRENT_WORKFLOW_AND_NODE_DATA,
  SET_CURRENT_DASHBOARD,
  SET_LOADING_DASHBOARD_FILTERS,
} from 'src/action-creators/analytics/analytics-workflows-overview';
import {
  getSubtractedDays,
  getUTCStartOfDay,
  getUTCEndOfDay,
  formatIsoDate,
} from 'src/utils/date-time-2';


export type AnalyticsWorkflowsOverviewState = {
  KPIData: KPIData | null,
  filtersOptions: J2FiltersOptions,
  workflowPlotData: Array<LineSeriesItem> | null,
  workflowTableData: WorkflowOverviewDataTable | null,
  nodeTableData: WorkflowOverviewDataTable | null,
  peopleTableData: WorkflowOverviewDataTable | null,
  selectedFilters: J2SelectedFilters,
  selectedEntities: Array<Entity>,
  selectedWorkflows: Array<FilterByID>,
  selectedMetricsType: MetricsType,
  selectedDateRange: DateRangeWithTimezone,
  chartFrequency: string,
  workflowTableColumnFilter: SelectedWorkflowFilterColumnsData | null,
  nodeTableColumnFilter: SelectedWorkflowFilterColumnsData | null,
  peopleTableColumnFilter: SelectedWorkflowFilterColumnsData | null,
  currentWorkflowAndNodeData: CurrentWorkflowAndNodeBreadCrumbData | null,
  saveDashboardFilterList: {[key: string]: SavedDashboardFiltersListData},
  currentDashboard: SavedDashboardFiltersList | null,
  isDashboardFiltersLoading: boolean,
};

const initialState: AnalyticsWorkflowsOverviewState = {
  KPIData: null,
  filtersOptions: {
    workflow_status: [],
    recipient_type: [],
    node_type: [],
    workflow_name: [],
    workflow_category: [],
  },
  workflowPlotData: null,
  workflowTableData: null,
  nodeTableData: null,
  peopleTableData: null,

  selectedFilters: {
    workflow_categories: [],
    entity_data_filter: [],
    workflow_status: [],
    recipient_type: [],
    workflow_ids: [],
    node_types: [],
  },
  selectedEntities: [{id: new Date().getTime()}],
  selectedWorkflows: [],
  selectedMetricsType: 'overview',
  selectedDateRange: {
    startDateUTC: getUTCStartOfDay(getSubtractedDays(formatIsoDate(), 30)),
    startDate: getSubtractedDays(formatIsoDate(), 30),
    endDateUTC: getUTCEndOfDay(formatIsoDate()),
    endDate: formatIsoDate(),
  },
  currentWorkflowAndNodeData: null,
  chartFrequency: 'weekly',
  workflowTableColumnFilter: null,
  nodeTableColumnFilter: null,
  peopleTableColumnFilter: null,
  saveDashboardFilterList: {},
  currentDashboard: null,
  isDashboardFiltersLoading: true,
};

export default (
  state: AnalyticsWorkflowsOverviewState = initialState,
  action: AnalyticsWorkflowsOverviewActions,
): AnalyticsWorkflowsOverviewState => {
  switch (action.type) {
    case SET_CHART_FREQUENCY: {
      return {...state, chartFrequency: action.payload};
    }
    case SET_SELECTED_FILTERS: {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          ...action.payload,
        },
      };
    }
    case SET_SELECTED_ENTITIES: {
      return {...state, selectedEntities: action.payload};
    }
    case SET_SELECTED_WORKFLOWS: {
      return {
        ...state,
        selectedWorkflows: action.payload,
      };
    }
    case SET_SELECTED_METRICS_TYPE: {
      return {...state, selectedMetricsType: action.payload};
    }
    case SET_SELECTED_DATE_RANGE_AND_FREQUENCY: {
      const {chartFrequency, ...dateRange} = action.payload;
      return {
        ...state,
        selectedDateRange: dateRange,
        chartFrequency,
      };
    }
    case RECEIVE_FILTERS_OPTIONS: {
      const {filtersOptions} = state;
      return {
        ...state,
        filtersOptions: {
          ...filtersOptions,
          ...action.payload,
        },
        selectedDateRange: {
          startDateUTC: getUTCStartOfDay(
            getSubtractedDays(formatIsoDate(), 30),
          ),
          startDate: getSubtractedDays(formatIsoDate(), 30),
          endDateUTC: getUTCEndOfDay(formatIsoDate()),
          endDate: formatIsoDate(),
        },
        selectedMetricsType: 'overview',
      };
    }
    case RECEIVE_WORKFLOW_OPTIONS: {
      const {filtersOptions} = state;
      return {
        ...state,
        filtersOptions: {
          ...filtersOptions,
          workflow_name: action.payload,
        },
      };
    }
    case RECEIVE_WORKFLOW_TABLE_DATA: {
      return {...state, workflowTableData: action.payload};
    }
    case RECEIVE_KPI_DATA: {
      return {...state, KPIData: action.payload};
    }
    case RECEIVE_WORKFLOW_PLOT_DATA: {
      return {...state, workflowPlotData: action.payload};
    }
    case CLEAR_SELECTED_FILTERS: {
      return {
        ...state,
        selectedFilters: {
          workflow_categories: [],
          entity_data_filter: [],
          workflow_status: [],
          recipient_type: [],
          workflow_ids: [],
          node_types: [],
        },
        selectedWorkflows: [],
        selectedEntities: [{id: new Date().getTime()}],
      };
    }
    case RECEIVE_NODE_TABLE_DATA: {
      return {...state, nodeTableData: action.payload};
    }
    case RECEIVE_PEOPLE_TABLE_DATA: {
      return {...state, peopleTableData: action.payload};
    }
    case RECEIVE_WORKFLOW_TABLE_COLUMNS: {
      return {...state, workflowTableColumnFilter: action.payload};
    }
    case RECEIVE_NODE_TABLE_COLUMNS: {
      return {...state, nodeTableColumnFilter: action.payload};
    }
    case RECEIVE_PEOPLE_TABLE_COLUMNS: {
      return {...state, peopleTableColumnFilter: action.payload};
    }
    case SET_CURRENT_WORKFLOW_AND_NODE_DATA: {
      return {
        ...state,
        currentWorkflowAndNodeData: {
          ...state.currentWorkflowAndNodeData,
          ...action.payload,
        },
        selectedFilters: {
          ...state.selectedFilters,
          workflow_ids: [],
        },
        selectedWorkflows: [],
      };
    }
    case SET_CURRENT_DASHBOARD: {
      const {filters} = action.payload ?? {};
      const {
        node_types = [],
        workflow_ids = [],
        recipient_type = [],
        workflow_status = [],
        entity_data_filter = [{id: new Date().getTime()}],
        workflow_categories = [],
      }: Filters = filters ?? {};
      return {
        ...state,
        currentDashboard: action.payload,
        selectedFilters: {
          ...state.selectedFilters,
          node_types,
          recipient_type,
          workflow_status,
          workflow_categories,
          workflow_ids: workflow_ids.map(({id}) => id),
        },
        selectedWorkflows: workflow_ids,
        selectedEntities: entity_data_filter,
      };
    }
    case RECEIVE_SAVED_DASHBOARDS: {
      const {filters, dashboard} = action.payload;
      return {
        ...state,
        saveDashboardFilterList: {
          ...state.saveDashboardFilterList,
          [dashboard]: filters,
        },
      };
    }
    case SET_LOADING_DASHBOARD_FILTERS: {
      return {
        ...state,
        isDashboardFiltersLoading: action.payload,
      };
    }
  }
  return state;
};
