var React = require('react');

function PreviewIcon (props) {
    return React.createElement("svg",props,React.createElement("g",{"transform":"translate(2 3)","fillRule":"evenodd"},[React.createElement("circle",{"cx":"5","cy":"5","r":"5","key":0}),React.createElement("path",{"d":"M8.5 8.5l5 5","strokeLinecap":"round","key":1})]));
}

PreviewIcon.defaultProps = {"width":"18","height":"18","viewBox":"0 0 18 18"};

module.exports = PreviewIcon;

PreviewIcon.default = PreviewIcon;
