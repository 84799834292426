// @flow strict
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {AutomationWorkflowState} from 'src/reducers/automation-workflow';
import type {FrequencyLimit} from 'src/types/automation-workflow';

// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-bff';
import {
  parseAutomationWorkflow,
  normalizeAutomationWorkflow,
} from 'src/utils/automation-workflow';
import {
  SCHEDULE_DATE_TYPES,
  SCHEDULE_TIME_TYPES,
} from 'src/utils/automation-workflow-scheduling.js';
import {parseSplits} from 'src/utils/split';
import {receiveSplits} from 'src/action-creators/split';


type UpdateAutomationWorkflowPayload = $Shape<AutomationWorkflowState>;

export const RECEIVE_AUTOMATION_WORKFLOW = 'automation-workflow/receive';
export const UPDATE_AUTOMATION_WORKFLOW = 'automation-workflow/update';
export const RESET_AUTOMATION_WORKFLOW = 'automation-workflow/reset';
export const ADD_TEMP_NODE = 'automation-workflow/add-temp-node';
export const ADD_TEMP_TRANSITION = 'automation-workflow/add-temp-transition';
export const RESET_TEMP_NODES = 'automation-workflow/reset-temp-nodes';
export const ADD_TEMP_SPLIT = 'automation-workflow/add-temp-split';
export const DELETE_SPLIT = 'automation-workflow/delete-split';

type UpdateAutomationWorkflowAction = {
  type: 'automation-workflow/update',
  payload: UpdateAutomationWorkflowPayload,
};
type ResetAutomationWorkflowAction = {
  type: 'automation-workflow/reset',
  payload?: AutomationWorkflowState,
};
type ReceiveAutomationWorkflowAction = {
  type: 'automation-workflow/receive',
  payload: AutomationWorkflowState,
};
type AddTempNodeAction = {
  type: 'automation-workflow/add-temp-node',
  payload: {
    nodeType: string,
    swimlaneId: string,
    sourceNodeId: string,
    transitionId: ?number,
  },
};

type AddTempTransitionAction = {
  type: 'automation-workflow/add-temp-transition',
  payload: {
    sourceNodeId: string,
    swimlaneId: string,
    position: 'left' | 'right',
  },
};

type ResetTempNodesAction = {
  type: 'automation-workflow/reset-temp-nodes',
};

type AddTempSplitAction = {
  type: 'automation-workflow/add-temp-split',
  payload: {
    swid: string,
    nodeId: string,
    tid: string,
    position: number,
  },
};

type DeleteSplitAction = {
  type: 'automation-workflow/delete-split',
  payload: {
    swid: string,
    nodeId: string,
    position: number,
  },
};

export type AutomationWorkflowAction =
  | UpdateAutomationWorkflowAction
  | ResetAutomationWorkflowAction
  | ReceiveAutomationWorkflowAction
  | AddTempNodeAction
  | AddTempTransitionAction
  | ResetTempNodesAction
  | AddTempSplitAction
  | DeleteSplitAction;

export const updateAutomationWorkflow = (
  payload: UpdateAutomationWorkflowPayload,
): UpdateAutomationWorkflowAction => ({
  type: UPDATE_AUTOMATION_WORKFLOW,
  payload,
});
//add a new journey with provided or initial state
export const resetAutomationWorkflow = (
  payload?: AutomationWorkflowState,
): ResetAutomationWorkflowAction => ({
  type: RESET_AUTOMATION_WORKFLOW,
  payload,
});
// put data received from api into store
export const receiveAutomationWorkflow = (
  payload: AutomationWorkflowState,
): ReceiveAutomationWorkflowAction => ({
  type: RECEIVE_AUTOMATION_WORKFLOW,
  payload,
});
//add a client side node
export const addTempNode = (payload: {
  swimlaneId: string,
  nodeType: string,
  sourceNodeId: string,
  transitionId: ?number,
}): AddTempNodeAction => ({
  type: ADD_TEMP_NODE,
  payload,
});

export const addTempTransition = (payload: {
  sourceNodeId: string,
  swimlaneId: string,
  position: 'left' | 'right',
}): AddTempTransitionAction => ({
  type: ADD_TEMP_TRANSITION,
  payload,
});

export const resetTempNodes = (): ResetTempNodesAction => ({
  type: RESET_TEMP_NODES,
});

export const getAutomationWorkflow: (
  automationWorkflowId: string,
) => ThunkAction<mixed> =
  (automationWorkflowId) => async (dispatch: Dispatch) => {
    try {
      const [automationWorkflow, swimlanes] = await Promise.all([
        dispatch(reduxApi.get(`workflow/${automationWorkflowId}`)),
        dispatch(reduxApi.get(`workflow/${automationWorkflowId}/swimlanes`)),
      ]).catch((ex) => {
        throw ex;
      });

      const automationWorkflowData = {
        ...automationWorkflow,
        ...swimlanes,
      };
      //we need to sync the split store whenever we receive workflows.
      await dispatch(receiveSplits(parseSplits(swimlanes.swimlanes)));
      return dispatch(
        receiveAutomationWorkflow(
          parseAutomationWorkflow(automationWorkflowData),
        ),
      );
    } catch (ex) {
      throw ex;
    }
  };

export const createAutomationWorkflow: () => ThunkAction<mixed> =
  () => (dispatch: Dispatch, getState) => {
    const automationWorkflow = getState().automationWorkflow;
    return dispatch(
      reduxApi.post(
        `workflow`,
        normalizeAutomationWorkflow(automationWorkflow),
      ),
    );
  };

//for now, only updating name and brand_id is allowed for workflows
export const saveAutomationWorkflow: (payload: {
  name?: string,
  brand_id?: string,
  frequency_limit?: FrequencyLimit,
  blackout_window_id?: number,
}) => ThunkAction<mixed> = (payload) => (dispatch: Dispatch, getState) => {
  const {id} = getState().automationWorkflow;
  return dispatch(reduxApi.put(`workflow/${id}`, payload)).then(({name}) =>
    dispatch(updateAutomationWorkflow({name})),
  );
};

export const activateAutomationWorkflow: (id: string) => ThunkAction<mixed> =
  (id) => (dispatch: Dispatch) => {
    return dispatch(reduxApi.post(`workflow/${id}/activate`)).then(() =>
      dispatch(getAutomationWorkflow(id)),
    );
  };

export const deactivateAutomationWorkflow: (id: string) => ThunkAction<mixed> =
  (id) => (dispatch: Dispatch) => {
    return dispatch(reduxApi.post(`workflow/${id}/deactivate`)).then(() =>
      dispatch(getAutomationWorkflow(id)),
    );
  };

export const archiveAutomationWorkflow: (id: string) => ThunkAction<mixed> =
  (id) => (dispatch: Dispatch) => {
    return dispatch(reduxApi.post(`workflow/${id}/archive`)).then(() =>
      dispatch(getAutomationWorkflow(id)),
    );
  };

export const unarchiveAutomationWorkflow: (id: string) => ThunkAction<mixed> =
  (id) => (dispatch: Dispatch) => {
    return dispatch(reduxApi.post(`workflow/${id}/unarchive`)).then(() =>
      dispatch(getAutomationWorkflow(id)),
    );
  };

export const deleteAutomationWorkflow: (id: string) => ThunkAction<mixed> =
  (id) => (dispatch: Dispatch) => {
    return dispatch(reduxApi.del(`workflow/${id}`));
  };

export const starAutomationWorkflow: (id: string) => ThunkAction<mixed> =
  (id) => (dispatch: Dispatch) => {
    //optimistically update the store
    dispatch(updateAutomationWorkflow({starred: true}));
    return dispatch(reduxApi.post(`workflow/star`, {workflow_ids: [id]})).catch(
      (ex) => {
        //revert optimistic update
        dispatch(updateAutomationWorkflow({starred: false}));
        throw ex;
      },
    );
  };

export const unstarAutomationWorkflow: (id: string) => ThunkAction<mixed> =
  (id) => (dispatch: Dispatch) => {
    //optimistically update the store
    dispatch(updateAutomationWorkflow({starred: false}));
    return dispatch(reduxApi.post(`workflow/${id}/unstar`)).catch((ex) => {
      //revert optimistic update
      dispatch(updateAutomationWorkflow({starred: true}));
      throw ex;
    });
  };

export const deleteAutomationWorkflowNode: (
  NodeId: string,
  automationWorkflowId: string,
) => ThunkAction<mixed> = (id, automationWorkflowId) => (dispatch: Dispatch) => {
  return dispatch(reduxApi.del(`node/${id}`)).then(() =>
    dispatch(getAutomationWorkflow(automationWorkflowId)),
  );
};
export const deleteAutomationWorkflowTransition: (
  nodeId: string,
  nextNodeId: string,
  automationWorkflowId: string,
) => ThunkAction<mixed> =
  (nodeId, nextNodeId, automationWorkflowId) => (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.del(
        `node/transition?previous_node=${nodeId}&next_node=${nextNodeId}`,
      ),
    ).then(() => dispatch(getAutomationWorkflow(automationWorkflowId)));
  };

export const createModule: (
  module: {
    type: string,
    task_group_id?: number,
    ...
  },
  canvasParams: {sid: string, tid: string, swid: string, workflow_id: string},
) => ThunkAction<mixed> =
  (module, canvasParams) => async (dispatch: Dispatch) => {
    //since we are creating module, we first need to create a task group
    //then we can create a module
    const {sid, tid, swid, workflow_id} = canvasParams;

    let payload = module;
    let nodeCreationResult = null;
    let moduleCreationResult = null;
    if (
      module.type === 'email_send' ||
      module.type === 'sms_send' ||
      module.type === 'chatbot_over_sms' ||
      module.type === 'owb_fwb_send'
    ) {
      // this is create node payload used if the node has been created yet
      payload = {
        node_type: 'scheduled_task',
        swimlane_id: parseInt(swid, 10),
        workflow_id,
        body: {
          date_type: SCHEDULE_DATE_TYPES.TRIGGER_DATE,
          time_type: SCHEDULE_TIME_TYPES.TRIGGER_TIME,
          date_value: 'today',
          time_value: 'now',
          task_type: ['email_send', 'sms_send'].includes(module.type)
            ? module.type === 'email_send'
              ? 'email'
              : 'sms'
            : module.type,
          task: {
            ...module,
          },
          blackout_action: 'SEND_BEFORE',
        },
      };
    }
    // if task_group_id is not present, this means we need to create the node first
    if (!module.task_group_id) {
      if (
        module.type === 'email_send' ||
        module.type === 'sms_send' ||
        module.type === 'owb_fwb_send'
      ) {
        // this is create node payload used if the node has not been created yet
        payload = {
          // node with an attached schedule
          node_type: 'scheduled_task',
          swimlane_id: parseInt(swid, 10),
          workflow_id,
          body: {
            date_type: SCHEDULE_DATE_TYPES.TRIGGER_DATE,
            time_type: SCHEDULE_TIME_TYPES.TRIGGER_TIME,
            date_value: 'today',
            time_value: 'now',
            task_type:
              module.type === 'owb_fwb_send'
                ? 'owb_fwb_send'
                : module.type === 'email_send'
                ? 'email'
                : 'sms',
            task: {
              ...module,
            },
            blackout_action: 'SEND_BEFORE',
          },
        };
        // } else {
        //   // normal node
        //   payload = {
        //     node_type: 'task',
        //     swimlane_id: parseInt(swid, 10),
        //     workflow_id,
        //     body: {
        //       task_type: module.type,
        //       task: {
        //         ...module,
        //       },
        //     },
        //   };
        // }
      }
      //use the taskNodeId here in this call
      nodeCreationResult = await dispatch(
        reduxApi.post(`node`, payload, {
          previous_node: sid,
          next_node: tid,
        }),
      );
    } else {
      moduleCreationResult = await dispatch(reduxApi.post(`task`, module));
    }
    // this lets the user know if module has been created or node has been created
    return {moduleCreationResult, nodeCreationResult};
  };

export const updateModule: (module: {
  id: number,
  type: string,
  ...
}) => ThunkAction<mixed> = (module) => (dispatch: Dispatch) => {
  const {id, ...restModule} = module;
  return dispatch(reduxApi.put(`task/${id}`, restModule));
};
export const modifySecurityGroupAccess: (
  automationWorkflowId: string,
  securityGroupId: string,
  access: boolean,
) => ThunkAction<mixed> =
  (automationWorkflowId, securityGroupId, access) => (dispatch: Dispatch) => {
    return dispatch(
      reduxApi.post(`workflow/${automationWorkflowId}/access`, {
        security_group_id: securityGroupId,
        access,
      }),
    ).then(() => dispatch(getAutomationWorkflow(automationWorkflowId)));
  };

export const deleteModule: (module: {
  id: number,
}) => ThunkAction<mixed> = (module) => (dispatch: Dispatch) => {
  const {id} = module;
  return dispatch(reduxApi.del(`task/${id}`));
};

export const addTempSplit = (payload: {
  swid: string,
  nodeId: string,
  tid: string,
  position: number,
}): AddTempSplitAction => ({
  type: ADD_TEMP_SPLIT,
  payload,
});

export const deleteSplit = (payload: {
  swid: string,
  nodeId: string,
  position: number,
}): DeleteSplitAction => ({
  type: DELETE_SPLIT,
  payload,
});
