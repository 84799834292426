// @flow

import type {AtsEntityAction} from 'src/action-creators/ats-entities';
import type {
  AtsClientEntity,
  EntityAttributeSchema,
  Usage,
  EntityMetadataObject,
} from 'src/types/ats-entities';

import {
  RECEIVE_ENTITIES,
  RECEIVE_ATTRIBUTES,
  RECEIVE_ENTITIES_USAGE,
  RECEIVE_METADATA,
} from 'src/action-creators/ats-entities';


export type State = {
  entities: AtsClientEntity[],
  // TODO (kyle): this is a legacy prop that we should remove
  // when we can confirm it's never referenced.
  attributes: {},
  simpleAttributes: Map<string, EntityAttributeSchema>,
  allAttributes: Map<string, EntityAttributeSchema>,
  usages: Usage[],
  entityMetadata: {[string]: EntityMetadataObject},
};

const initialState = {
  entities: [],
  attributes: {...null},
  simpleAttributes: new Map(),
  allAttributes: new Map(),
  usages: [],
  entityMetadata: {},
};

export default (
  state: State = initialState,
  action: AtsEntityAction,
): State => {
  switch (action.type) {
    case RECEIVE_ENTITIES: {
      return {
        ...state,
        entities: action.payload,
      };
    }

    case RECEIVE_ENTITIES_USAGE: {
      return {
        ...state,
        usages: action.payload,
      };
    }

    case RECEIVE_ATTRIBUTES: {
      return {
        ...state,
        simpleAttributes: action.payload.reduce(
          (map, attr) => (attr.hidden ? map : map.set(attr.id, attr)),
          new Map(),
        ),
        allAttributes: action.payload.reduce(
          (map, attr) => map.set(attr.id, attr),
          new Map(),
        ),
      };
    }
    case RECEIVE_METADATA: {
      return {
        ...state,
        entityMetadata: action.payload,
      };
    }
  }
  return state;
};
