// @flow

import type {Dispatch, GetState, ThunkAction} from 'src/reducers';

import {selectDisplayPhoneNumber} from 'src/selectors/chat';
import {showAndCaptureApiError} from 'src/action-creators/modal';

import {thunkify as flow} from 'src/utils/thunks';
import {key, cached, fetching, evict} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api-v2';
import {removePhoneNumber} from 'src/action-creators/chat/phone-number-sets';


export const RECEIVE_PHONE_NUMBERS: 'phoneNumbers/receivePhoneNumbers' =
  'phoneNumbers/receivePhoneNumbers';
export const ADD_AGENT_PHONE_NUMBER_ASSIGNMENT: 'phoneNumbers/addAgentPhoneNumberAssignment' =
  'phoneNumbers/addAgentPhoneNumberAssignment';
export const REMOVE_AGENT_PHONE_NUMBER_ASSIGNMENT: 'phoneNumbers/removeAgentPhoneNumberAssignment' =
  'phoneNumbers/removeAgentPhoneNumberAssignment';
export const ADD_INVITE_PHONE_NUMBER_ASSIGNMENT: 'phoneNumbers/addInvitePhoneNumberAssignment' =
  'phoneNumbers/addInvitePhoneNumberAssignment';
export const REMOVE_INVITE_PHONE_NUMBER_ASSIGNMENT: 'phoneNumbers/removeInvitePhoneNumberAssignment' =
  'phoneNumbers/removeInvitePhoneNumberAssignment';
export const RECEIVE_PHONE_CAPABILITIES: 'phoneNumbers/receivePhoneCapabilities' =
  'phoneNumbers/receivePhoneCapabilities';
export const SET_MESSAGING_CALL_FORWARDING_NUMBER: 'phoneNumbers/setMessagingCallForwardingNumber' =
  'phoneNumbers/setMessagingCallForwardingNumber';
export const SET_FRIENDLY_NAME: 'phoneNumbers/setfriendlyName' =
  'phoneNumbers/setfriendlyName';

export type PhoneNumbersAction =
  | UpdatePhoneNumbersAction
  | AddAgentPhoneNumberAssignmentAction
  | RemoveAgentPhoneNumberAssignmentAction
  | AddInvitePhoneNumberAssignmentAction
  | RemoveInvitePhoneNumberAssignmentAction
  | ReceivePhoneCapabilitiesAction
  | SetMessagingCallForwardingNumberAction
  | SetFriendlyNameAction;
type UpdatePhoneNumbersAction = {
  type: typeof RECEIVE_PHONE_NUMBERS,
  payload: mixed,
};

export const receivePhoneNumbers = (
  payload: mixed,
): UpdatePhoneNumbersAction => ({
  type: RECEIVE_PHONE_NUMBERS,
  payload,
});

export const getPhoneNumbers: () => ThunkAction<mixed> = flow(
  key(() => 'getPhoneNumbers'),
  cached((phoneNumbers) => receivePhoneNumbers(phoneNumbers), {
    ttl: 120 * 1000,
  }),
  fetching(),
)(
  () => async (dispatch: Dispatch) =>
    // TODO(aditya): should paginate this
    dispatch(reduxApi.get('messages_v2/phone-numbers', {page_size: 500000})),
);

type AddAgentPhoneNumberAssignmentAction = {
  type: typeof ADD_AGENT_PHONE_NUMBER_ASSIGNMENT,
  payload: {agent_id: string, provisioned_phone_id: number},
};

export const addAgentPhoneNumberAssignment = (
  agent_id: string,
  provisioned_phone_id: number,
): AddAgentPhoneNumberAssignmentAction => ({
  type: ADD_AGENT_PHONE_NUMBER_ASSIGNMENT,
  payload: {agent_id, provisioned_phone_id},
});

export const assignPhoneNumberToAgent =
  (agent_id: string, provisioned_phone_id: number): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    // ensure next call for getPhoneNumbers should return fresh data
    dispatch(evict(getPhoneNumbers()));
    dispatch(addAgentPhoneNumberAssignment(agent_id, provisioned_phone_id));

    try {
      await dispatch(
        reduxApi.post('messages_v2/phone_numbers/assign/agent', {
          agent_id,
          provisioned_phone_id,
        }),
      );
    } catch (error) {
      dispatch(
        removeAgentPhoneNumberAssignment(agent_id, provisioned_phone_id),
      );
      dispatch(
        showAndCaptureApiError(error, {
          title: 'API Error',
          text: `It looks like there was an issue and we failed to assign a phone number to this Agent:${agent_id}`,
        }),
      );
    }
  };

type SetMessagingCallForwardingNumberAction = {
  type: typeof SET_MESSAGING_CALL_FORWARDING_NUMBER,
  payload: {call_forwarding_number: string, provisioned_phone_id: number},
};

export const setMessagingCallForwardingNumber = (
  call_forwarding_number: string,
  provisioned_phone_id: number,
): SetMessagingCallForwardingNumberAction => ({
  type: SET_MESSAGING_CALL_FORWARDING_NUMBER,
  payload: {call_forwarding_number, provisioned_phone_id},
});

export const assignCallForwardingNumber =
  (
    call_forwarding_number: string,
    provisioned_phone_id: number,
  ): ThunkAction<void> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const previousNumber =
      getState().phoneNumbers.call_forwarding_numbers[provisioned_phone_id];
    dispatch(
      setMessagingCallForwardingNumber(
        call_forwarding_number,
        provisioned_phone_id,
      ),
    );
    const phoneNumber =
      getState().phoneNumbers.phone_numbers?.[provisioned_phone_id];
    try {
      await dispatch(
        reduxApi.post(`provisioned-phone/call_forwarding/${phoneNumber}`, {
          forwarding_number: call_forwarding_number,
        }),
      );
    } catch (error) {
      // revert optimistic update
      dispatch(
        setMessagingCallForwardingNumber(previousNumber, provisioned_phone_id),
      );
      dispatch(
        showAndCaptureApiError(error, {
          title: 'API Error',
          text: `Failed to set ${call_forwarding_number} as call forwarding number`,
        }),
      );
    }
  };

type SetFriendlyNameAction = {
  type: typeof SET_FRIENDLY_NAME,
  payload: {friendly_name: string, provisioned_phone_id: number},
};

export const setFriendlyName = (
  friendly_name: string,
  provisioned_phone_id: number,
): SetFriendlyNameAction => ({
  type: SET_FRIENDLY_NAME,
  payload: {friendly_name, provisioned_phone_id},
});

export const assignFriendlyName =
  (friendly_name: string, provisioned_phone_id: number): ThunkAction<void> =>
  async (dispatch: Dispatch, getState: GetState) => {
    const previousNumber =
      getState().phoneNumbers.friendly_names[provisioned_phone_id];
    dispatch(setFriendlyName(friendly_name, provisioned_phone_id));
    const phoneNumber =
      getState().phoneNumbers.phone_numbers?.[provisioned_phone_id];
    try {
      await dispatch(
        reduxApi.post(`provisioned-phone/friendly-name/${phoneNumber}`, {
          friendly_name,
        }),
      );
    } catch (error) {
      // revert optimistic update
      dispatch(setFriendlyName(previousNumber, provisioned_phone_id));
      dispatch(
        showAndCaptureApiError(error, {
          title: 'API Error',
          text: `Failed to set ${friendly_name} as friendly name`,
        }),
      );
    }
  };

type RemoveAgentPhoneNumberAssignmentAction = {
  type: typeof REMOVE_AGENT_PHONE_NUMBER_ASSIGNMENT,
  payload: {agent_id: string, provisioned_phone_id: number},
};

export const removeAgentPhoneNumberAssignment = (
  agent_id: string,
  provisioned_phone_id: number,
): RemoveAgentPhoneNumberAssignmentAction => ({
  type: REMOVE_AGENT_PHONE_NUMBER_ASSIGNMENT,
  payload: {agent_id, provisioned_phone_id},
});

export const unassignAgentPhoneNumber =
  (
    agent_id: string,
    provisioned_phone_id: number,
    phone_number: string,
  ): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    // ensure next call for getPhoneNumbers should return fresh data
    dispatch(removePhoneNumber({phoneNumberSetId: phone_number}));
    dispatch(evict(getPhoneNumbers()));
    dispatch(removeAgentPhoneNumberAssignment(agent_id, provisioned_phone_id));
    try {
      await dispatch(
        reduxApi.del('messages_v2/phone_numbers/assign/agent', {
          agent_id,
          provisioned_phone_id,
        }),
      );
    } catch (error) {
      dispatch(addAgentPhoneNumberAssignment(agent_id, provisioned_phone_id));
      dispatch(
        showAndCaptureApiError(error, {
          title: 'API Error',
          text: `Failed to remove agent:${agent_id} from phone number`,
        }),
      );
    }
  };

type AddInvitePhoneNumberAssignmentAction = {
  type: typeof ADD_INVITE_PHONE_NUMBER_ASSIGNMENT,
  payload: {invite_code: string, provisioned_phone_id: number},
};

export const addInvitePhoneNumberAssignment = (
  invite_code: string,
  provisioned_phone_id: number,
): AddInvitePhoneNumberAssignmentAction => ({
  type: ADD_INVITE_PHONE_NUMBER_ASSIGNMENT,
  payload: {invite_code, provisioned_phone_id},
});

export const assignPhoneNumberToInvite =
  (invite_code: string, provisioned_phone_id: number): ThunkAction<void> =>
  async (dispatch: Dispatch, getState: GetState) => {
    // ensure next call for getPhoneNumbers should return fresh data
    dispatch(evict(getPhoneNumbers()));
    dispatch(addInvitePhoneNumberAssignment(invite_code, provisioned_phone_id));
    try {
      await dispatch(
        reduxApi.post('messages_v2/phone_numbers/assign/invite', {
          invite_code,
          provisioned_phone_id,
        }),
      );
    } catch (error) {
      dispatch(
        removeInvitePhoneNumberAssignment(invite_code, provisioned_phone_id),
      );
      dispatch(
        showAndCaptureApiError(error, {
          title: 'API Error',
          text: `Failed to assign invite:${invite_code} to phone number`,
        }),
      );
    }
  };

type RemoveInvitePhoneNumberAssignmentAction = {
  type: typeof REMOVE_INVITE_PHONE_NUMBER_ASSIGNMENT,
  payload: {invite_code: string, provisioned_phone_id: number},
};

export const removeInvitePhoneNumberAssignment = (
  invite_code: string,
  provisioned_phone_id: number,
): RemoveInvitePhoneNumberAssignmentAction => ({
  type: REMOVE_INVITE_PHONE_NUMBER_ASSIGNMENT,
  payload: {invite_code, provisioned_phone_id},
});

export const unassignInvitePhoneNumber =
  (invite_code: string, provisioned_phone_id: number): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    // ensure next call for getPhoneNumbers should return fresh data
    dispatch(evict(getPhoneNumbers()));
    dispatch(
      removeInvitePhoneNumberAssignment(invite_code, provisioned_phone_id),
    );
    try {
      await dispatch(
        reduxApi.del('messages_v2/phone_numbers/assign/invite', {
          invite_code,
          provisioned_phone_id,
        }),
      );
    } catch (error) {
      dispatch(
        addInvitePhoneNumberAssignment(invite_code, provisioned_phone_id),
      );
      dispatch(
        showAndCaptureApiError(error, {
          title: 'API Error',
          text: `Failed to remove invite:${invite_code} from phone number`,
        }),
      );
    }
  };

// Phone capabilities
export type PhoneCapabilities = {
  voice: boolean,
  sms: boolean,
  mms: boolean,
  fax: boolean,
};

type ReceivePhoneCapabilitiesAction = {
  type: typeof RECEIVE_PHONE_CAPABILITIES,
  payload: {phoneNumber: string, capabilities: PhoneCapabilities},
};

export const receivePhoneCapabilities = (
  phoneNumber: string,
  capabilities: PhoneCapabilities,
): ReceivePhoneCapabilitiesAction => ({
  type: RECEIVE_PHONE_CAPABILITIES,
  payload: {phoneNumber, capabilities},
});

export const getPhoneCapabilities =
  (): ThunkAction<void> => async (dispatch: Dispatch, getState: GetState) => {
    const phoneNumber = selectDisplayPhoneNumber(getState());
    if (phoneNumber) {
      const capabilities = await dispatch(
        reduxApi.get(`provisioned-phone/capabilities/${phoneNumber}`),
      );
      dispatch(receivePhoneCapabilities(phoneNumber, capabilities));
    }
  };
