// @flow strict
import type {TargetEntities} from 'src/types/target-entity-types';
import {
  RECEIVE_TARGET_ENTITY_TYPES,
  type TargetEntityTypeAction,
} from 'src/action-creators/target-entity-types';


export type TargetEntityTypeState = TargetEntities;
const initialState = [];

export default (
  state: TargetEntityTypeState = initialState,
  action: TargetEntityTypeAction,
): TargetEntityTypeState => {
  switch (action.type) {
    case RECEIVE_TARGET_ENTITY_TYPES: {
      return action.payload;
    }
  }
  return state;
};
