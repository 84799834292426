// @flow

import type {
  // $FlowFixMe[untyped-type-import]
  Option,
  // $FlowFixMe[untyped-type-import]
  Props as TokenListInputProps,
} from 'src/components/lib/token-list-input/token-list-input.old.jsx';
import type {DynamicLabels} from 'src/action-creators/dynamic-labels';

import * as React from 'react';

import {emptyArray} from 'src/utils';

import {MultiEntityVariableSelect} from 'src/components/lib/variable-picker';
import {MultiEntityLabel} from 'src/components/lib/markdown-editor/dynamic-text.jsx';


type EmailListInputProps = {
  // $FlowFixMe[value-as-type] [v1.32.0]
  ...TokenListInputProps,
  value: ?string,
  onChange: (value: string) => mixed,
  rteMode: boolean, // if yes, wrap known values in <>
};

const EmailListInput = ({
  value,
  values,
  onChange,
  options,
  hasError = false,
  rteMode = true,
  ...props
}: EmailListInputProps): React.Node => {
  const valueOptionMap = React.useMemo(
    () =>
      options.reduce((acc, option) => {
        acc[option.value] = option;
        return acc;
      }, {}),
    [options],
  );

  const handleChange = React.useCallback(
    (values: DynamicLabels) =>
      onChange(
        // $FlowIssue optional chaining method call
        values
          ?.map(
            ({entity_type, value, rteValue}) =>
              entity_type && rteMode ? rteValue : value, // rteValue is equivalent to <value>
          )
          .join(', '),
      ),
    [onChange],
  );

  if (!value) {
    values = emptyArray;
  } else {
    values = value.split(', ').map((email) => {
      const knownTokenRegex = /\<(.*)\>/g;

      const knownToken = knownTokenRegex.exec(email);
      email = knownToken !== null ? knownToken[1] : email;
      return (
        valueOptionMap[email] || {
          label: email,
          value: email,
          sourceObject: {},
        }
      );
    });
  }
  return (
    <MultiEntityVariableSelect
      values={values}
      hasError={hasError}
      onChange={handleChange}
      verticalStretch={true}
      options={options}
      {...props}
    />
  );
};

export const EmailDynamicVarToken = ({
  option,
  children,
  dynamicLabels,
  type,
  className,
  onClick,
}: {
  dynamicLabels: DynamicLabels,
  // $FlowFixMe[value-as-type] [v1.32.0]
  option: Option & {
    extras: {
      resource?: string,
    },
  },

  children: React.Node,
  className?: string,
  type: *,
  onClick: *,
}): React.Node | React.Element<'div'> => {
  const entityType = dynamicLabels.find((label) => label.label === option.label)
    ?.mapping?.sense_name;

  const {resource} = option.sourceObject;
  if (resource) {
    return (
      <MultiEntityLabel {...{children, type, className, onClick, entityType}} />
    );
  }
  return <div {...{className, onClick}}>{children}</div>;
};

export default EmailListInput;
