// @flow

import * as React from 'react';
import GroupIcon from 'src/images/group-icon.svg';
import GenericAvatarIcon from 'src/images/generic-avatar.svg';

import colors from 'src/styles/modules/colors.css';
import css from './contact-icon.css';
import {classify} from 'src/utils';


const avatarColors = [
  colors.color7,
  colors.color3,
  colors.color4,
  colors.colorOrange,
  colors.color8,
  colors.color9,
  colors.color1,
  colors.color6,
];

// Todo: (Ashwini) These are base colors from Genesis, should be updated with variables, once I get it from design
const avatarGenesisColors = [
  '#412592',
  '#434343',
  '#504F6E',
  '#054baa',
  '#026b37',
  '#9e5909',
  '#a11436',
  '#9E8609',
  '#009476',
  '#7F1C97',
  '#9F126F',
];

// Note (Aditya): generates a hash value from a string
// used for picking a random avatar color based on user name.
const generateHash = (string) => {
  let hash = 0xdeadbeef;
  for (let index = 0; index < string.length; index++) {
    hash = Math.imul(hash ^ string.charCodeAt(index), 2654435761);
  }
  return (hash ^ (hash >>> 6)) >>> 0;
};

const generateInitials = (displayName) => {
  if (displayName) {
    const withoutEmojis = displayName.replace(
      /([\u2580-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      '',
    );
    const names = withoutEmojis.trim().split(' ');

    const firstInitial = names[0][0] || '';
    const lastInitial = names.length > 1 ? names[names.length - 1][0] : '';
    const initials = `${firstInitial}${lastInitial}`.toUpperCase();
    return initials;
  }
  return false;
};

export const getAvatarColor = (colorKey: string): string =>
  avatarColors[generateHash(colorKey) % avatarColors.length];

export const getGenesisAvatarColor = (colorKey: string): string =>
  avatarGenesisColors[generateHash(colorKey) % avatarGenesisColors.length];

export const ContactIcon = ({
  initials,
  colorKey,
  size = 32,
  useGenesisColors = false,
  className,
}: {
  initials: string,
  colorKey: string,
  size: number,
  useGenesisColors: boolean,
  className?: string,
}): React.Element<'div'> => {
  const avatarColor = useGenesisColors
    ? getGenesisAvatarColor(colorKey)
    : getAvatarColor(colorKey);
  const avatarContainerStyle = {
    backgroundColor: avatarColor,
    height: size,
    width: size,
    minWidth: size,
  };
  const avatarStyle = {fontSize: `${size / 3}px`, lineHeight: `${size}px`};
  return (
    <div
      className={classify(css.avatarContainer, className)}
      style={avatarContainerStyle}
    >
      <span className={css.avatar} style={avatarStyle}>
        {initials}
      </span>
    </div>
  );
};

export const AvatarIcon = ({
  displayName,
  size,
  profilePictureUrl,
  className,
  useGenesisColors = false,
  renderFallbackIcon: FallbackIcon = GenericAvatarIcon,
}: {
  displayName: string,
  size: number,
  className?: string,
  useGenesisColors?: boolean,
  profilePictureUrl?: string | void,
  renderFallbackIcon?: React.ComponentType<{
    style: {[string]: string | number | void, ...},
    className: ?string,
  }>,
}): React.Node => {
  const initials = generateInitials(displayName);
  if (profilePictureUrl) {
    return (
      <img
        className={className}
        style={{
          height: `${size}px`,
          width: `${size}px`,
          minWidth: `${size}px`,
          objectFit: 'cover',
          borderRadius: '100%',
        }}
        src={profilePictureUrl}
      />
    );
  } else if (initials) {
    return (
      <ContactIcon
        className={className}
        initials={initials}
        colorKey={displayName}
        size={size}
        useGenesisColors={useGenesisColors}
      />
    );
  } else {
    return (
      <FallbackIcon
        style={{
          height: `${size}px`,
          width: `${size}px`,
          flexShrink: '0',
        }}
        className={className}
      />
    );
  }
};
