// @flow strict

import _set from 'lodash/set';
import _get from 'lodash/get';


export type Validation = {
  [string]: ?Validation,
  errors?: string[],
};

export function setError<V: Validation = Validation>(
  error: ?V,
  test: mixed,
  path: string,
  words: string,
): ?V {
  if (!test) {
    // $FlowFixMe need some way to build up the object
    error = error || {};
    const fullPath = path ? `${path}.errors` : 'errors';
    const errors = _get(error, fullPath) || [];
    errors.push(words);
    _set(error, fullPath, errors);
  }
  return error;
}

export function getErrors(error: ?Validation, path: string): ?(string[]) {
  return _get(error, `${path}.errors`);
}

export function hasError(
  error: ?Validation,
  path: string,
  words: string,
): boolean {
  return (getErrors(error, path) || []).includes(words);
}

export function filterValidation(
  errorArg: ?Validation,
  keys: Array<string>,
): ?Validation {
  let result;
  for (const key of keys) {
    const subValidation = errorArg?.[key];
    if (subValidation) {
      result = {
        ...result,
        ...subValidation,
      };
    }
  }
  return result;
}
