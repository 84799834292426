// @flow

import type {MetaObject} from 'src/types/request-headers';
import type {RequestHeaderAction} from 'src/action-creators/request-headers';

import {
  SET_HEADERS,
  SET_META,
  CLEAR,
} from 'src/action-creators/request-headers';


export type RequestHeaderState = {
  headers: {
    [key: string]: string,
    ...
  },
  // initial request data carried over from server request
  // that we use for autoclick data: ip address, referrer, client
  meta: $Shape<MetaObject>,
  subdomains: string[],
};

const initialState = {
  headers: Object.freeze({}),
  meta: Object.freeze({}),
  subdomains: Object.freeze([]),
};

export default (
  state: RequestHeaderState = initialState,
  action: RequestHeaderAction,
): RequestHeaderState => {
  switch (action.type) {
    case SET_HEADERS: {
      const {
        payload: {headers, subdomains},
      } = action;
      return {
        ...state,
        headers,
        subdomains,
      };
    }

    case SET_META: {
      return {
        ...state,
        meta: action.payload,
      };
    }

    case CLEAR: {
      const {headers: serverHeaders} = state;
      const ua = serverHeaders['user-agent'];
      if (ua) {
        return {
          ...initialState,
          headers: {'user-agent': ua},
          // keep metadata around even when clearing headers
          // because we sidecar this data in survey requests
          meta: state.meta,
        };
      } else {
        return initialState;
      }
    }
  }
  return state;
};
