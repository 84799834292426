// @flow

/*
import type {
  DynamicLabelExtra,
  DynamicLabels,
} from 'src/action-creators/dynamic-labels';
*/
import type {
  DynamicLabelExtra,
  DynamicLabels,
  JobVariables,
  JobVariable,
} from './types';
import type {EntityType} from 'src/types/ats-entities';

import * as React from 'react';
import memoize from 'memoize-one';
import {VariableSizeList} from 'react-window';

import VariablePickerModalRow from './variable-picker-modal-row.jsx';

import css from './variable-picker.css';


type SelectCallback = (
  value: DynamicLabelExtra | JobVariable,
  event?: SyntheticMouseEvent<> | KeyboardEvent,
) => void;

const createMemoizedItemData = memoize(
  // eslint-disable-next-line max-params
  (labels, selectedIndex, baseEntityType, onClick) => ({
    labels,
    onClick,
    selectedIndex,
  }),
);

const VariablePickerBaseList = ({
  baseEntityType,
  selectedIndex,
  onSelect,
  //$FlowFixMe we can use generics here
  labels = [],
  RowComponent = VariablePickerModalRow,
  listRef,
}: {
  baseEntityType: EntityType,
  onSelect: SelectCallback,
  selectedIndex: number,
  labels: DynamicLabels | JobVariables,
  RowComponent?: React.ComponentType<any>,
  listRef?: any,
}): React.Node => {
  const itemData = createMemoizedItemData(
    labels,
    selectedIndex,
    baseEntityType,
    onSelect,
  );
  const listKey = `${itemData.labels?.length}`;

  return (
    <VariableSizeList
      key={
        listKey
      } /* VariableSizeList component keeps track of the size of each item based on its index. Since we're reusing the same VariableSizeList component instance, the index values were not getting reset table gets updated and hence depth for indexes once calculated remains the same which raises UI bugs. Added key prop so that VariableSizeList re-renders everytime on a list update. */
      ref={listRef}
      className={css.results}
      itemData={itemData}
      itemCount={itemData.labels.length}
      width="100%"
      height={400}
      itemSize={(index) => {
        const depth =
          itemData.labels[index].entityRelationshipPath?.length ?? 0;

        return depth > 2
          ? 25 + // header / variable name is 25px
              depth * (19 + 2) + // each depth p tag (19 height + 2 margin)
              2 + // the final, non-collapsed margin
              10 // the cell padding
          : 100;
      }}
    >
      {RowComponent}
    </VariableSizeList>
  );
};

export default VariablePickerBaseList;
