// @flow strict

import type {
  FieldMappingScreen,
  StandardEntityAttributes,
  FieldMappingGenericError,
  StandardEntityDesignationResponse,
  UpdateStandardEntityDesignationData,
  Designation,
} from 'src/types/field-mapping';
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction, GetState} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
import {cloneDesignationsResponse} from 'src/utils/field-mapping';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2.js';


const DEFAULT_ERROR: FieldMappingGenericError = {
  errorMsg: 'Some Error occoured',
};

export type FieldMappingAction =
  | UpdateActiveFieldMappingScreenAction
  | StandardEntityAction
  | StandardEntityLoadingAction
  | StandardEntityErrorAction;

/**
 * Action Name constants definition starts here.
 */

export const UPADTE_ACTIVE_FIELD_MAPPING_SCREEN =
  'fieldMapping/updateActiveScreen';

export const SET_STANDARD_ENTITY_DESIGNATION =
  'field-mapping/set-standard-entity-designations';
export const STANDARD_ENTITY_DESIGNATION_LOADING =
  'field-mapping/standad-entity-designations-loading';
export const STANDARD_ENTITY_DESIGNATION_ERROR =
  'field-mapping/standad-entity-designations-error';
export const SET_LOCAL_STANDARD_ENTITY_DESIGNATION =
  'field-mapping/set-local-standard-entity-designations';

export const SET_STANDARD_ENTITY = 'field-mapping/set-standard-entity';
export const STANDARD_ENTITY_LOADING = 'field-mapping/standad-entity-loading';
export const STANDARD_ENTITY_ERROR = 'field-mapping/standad-entity-error';

export const SAVE_STANDARD_FIELD_MAPPING_LOADING =
  'field-mapping/save-standard-field-mapping-loading';
export const SAVE_STANDARD_FIELD_MAPPING_SUCCESS =
  'field-mapping/save-standard-field-mappin-success';
export const SAVE_STANDARD_FIELD_MAPPING_ERROR =
  'field-mapping/save-standard-field-mappin-error';

export const CONFIRM_MIGRATE_FIELD_MAPPING_LOADING =
  'field-mapping/confirm-migrate-field-mapping-loading';

export const CONFIRM_MIGRATE_FIELD_MAPPING_SUCCESS =
  'field-mapping/confirm-migrate-field-mapping-success';
export const CONFIRM_MIGRATE_FIELD_MAPPING =
  'field-mapping/confirm-migrate-field-mapping';
export const CONFIRM_MIGRATE_FIELD_MAPPING_ERROR =
  'field-mapping/confirm-migrate-field-mapping-error';

/**
 * Action type definition starts here.
 */

type UpdateActiveFieldMappingScreenAction = {
  type: 'fieldMapping/updateActiveScreen',
  payload: FieldMappingScreen,
};

type SaveStandardFieldMappingLoadingAction = {
  type: typeof SAVE_STANDARD_FIELD_MAPPING_LOADING,
};

type SaveStandardFieldMappingErrorAction = {
  type: typeof SAVE_STANDARD_FIELD_MAPPING_ERROR,
  payload: ?FieldMappingGenericError,
};

type StandardEntityDesignationLoadingAction = {
  type: typeof STANDARD_ENTITY_DESIGNATION_LOADING,
};
type StandardEntityDesignationErrorAction = {
  type: typeof STANDARD_ENTITY_DESIGNATION_ERROR,
  payload: ?FieldMappingGenericError,
};

type StandardEntityDesignationAction = {
  type: typeof SET_STANDARD_ENTITY_DESIGNATION,
  payload: StandardEntityDesignationResponse,
};
type LocalStandardEntityDesignationAction = {
  type: typeof SET_LOCAL_STANDARD_ENTITY_DESIGNATION,
  payload: StandardEntityDesignationResponse,
};

type StandardEntityLoadingAction = {
  type: typeof STANDARD_ENTITY_LOADING,
};

type StandardEntityErrorAction = {
  type: typeof STANDARD_ENTITY_ERROR,
  payload: ?FieldMappingGenericError,
};

type StandardEntityAction = {
  type: typeof SET_STANDARD_ENTITY,
  payload: StandardEntityAttributes,
};

type ConfirmMigrateFieldMappingLoadingAction = {
  type: typeof CONFIRM_MIGRATE_FIELD_MAPPING_LOADING,
};

type ConfirmMigrateFieldMappingSuccessAction = {
  type: typeof CONFIRM_MIGRATE_FIELD_MAPPING_SUCCESS,
};

type ConfirmMigrateFiledMappingErrorAction = {
  type: typeof CONFIRM_MIGRATE_FIELD_MAPPING_ERROR,
  payload: ?FieldMappingGenericError,
};

/**
 * Action Definiton starts here.
 */

export const updateActiveFieldMappingScreen = (
  payload: FieldMappingScreen,
): UpdateActiveFieldMappingScreenAction => ({
  type: UPADTE_ACTIVE_FIELD_MAPPING_SCREEN,
  payload,
});

export const getStandardEntityDesignationsLoading =
  (): StandardEntityDesignationLoadingAction => ({
    type: STANDARD_ENTITY_DESIGNATION_LOADING,
  });
export const getStandardEntityDesignationsError = (
  payload: ?FieldMappingGenericError,
): StandardEntityDesignationErrorAction => ({
  type: STANDARD_ENTITY_DESIGNATION_ERROR,
  payload,
});
export const setStandardEntityDesignations = (
  payload: StandardEntityDesignationResponse,
): StandardEntityDesignationAction => ({
  type: SET_STANDARD_ENTITY_DESIGNATION,
  payload,
});
export const setLocalStandardEntityDesignations = (
  payload: StandardEntityDesignationResponse,
): LocalStandardEntityDesignationAction => ({
  type: SET_LOCAL_STANDARD_ENTITY_DESIGNATION,
  payload,
});

export const getStandardEntityLoading = (): StandardEntityLoadingAction => ({
  type: STANDARD_ENTITY_LOADING,
});

export const getStandardEntityError = (
  payload: ?FieldMappingGenericError,
): StandardEntityErrorAction => ({
  type: STANDARD_ENTITY_ERROR,
  payload,
});

export const setStandardEntity = (
  payload: StandardEntityAttributes,
): StandardEntityAction => ({
  type: SET_STANDARD_ENTITY,
  payload,
});

export const saveStandardFieldMappingLoading =
  (): SaveStandardFieldMappingLoadingAction => ({
    type: SAVE_STANDARD_FIELD_MAPPING_LOADING,
  });
export const saveStandardFiledMappingSuccess = (
  payload: ?FieldMappingGenericError,
): SaveStandardFieldMappingErrorAction => ({
  type: SAVE_STANDARD_FIELD_MAPPING_SUCCESS,
  payload,
});
export const saveStandardFiledMappingError = (
  payload: ?FieldMappingGenericError,
): SaveStandardFieldMappingErrorAction => ({
  type: SAVE_STANDARD_FIELD_MAPPING_ERROR,
  payload,
});

export const confirmMigrateFieldMappingLoading =
  (): ConfirmMigrateFieldMappingLoadingAction => ({
    type: CONFIRM_MIGRATE_FIELD_MAPPING_LOADING,
  });
export const confirmMigrateFieldMappingSuccess =
  (): ConfirmMigrateFieldMappingSuccessAction => ({
    type: CONFIRM_MIGRATE_FIELD_MAPPING_SUCCESS,
  });

export const confirmMigrateFiledMappingError = (
  payload: ?FieldMappingGenericError,
): ConfirmMigrateFiledMappingErrorAction => ({
  type: SAVE_STANDARD_FIELD_MAPPING_ERROR,
  payload,
});

export const updateLocalStandardEntityDesignation =
  (payload: UpdateStandardEntityDesignationData): ThunkAction<> =>
  (dispatch: Dispatch, getState: GetState) => {
    const {
      standard_entity_type,
      designation_name,
      entity_attribute_name,
      entity_attribute_id,
    } = payload;
    const localStandardEntityDesignations: StandardEntityDesignationResponse =
      getState().fieldMapping?.localStandardEntityDesignation;

    for (const standardEntityDesignationObj of localStandardEntityDesignations) {
      if (
        standardEntityDesignationObj.standard_entity_type ===
        standard_entity_type
      ) {
        const designations: Designation[] =
          standardEntityDesignationObj.designations;
        for (const designation of designations) {
          if (designation.designation_name === designation_name) {
            designation.entity_attribute_name = entity_attribute_name;
            designation.entity_attribute_id = entity_attribute_id;
            break;
          }
        }
        break;
      }
    }

    // $FlowIssue object values
    return dispatch(
      setLocalStandardEntityDesignations(localStandardEntityDesignations),
    );
  };

export const resetLocalStandardEntityDesignations =
  (): ThunkAction<void> =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const standardEntityDesignations: StandardEntityDesignationResponse =
      getState().fieldMapping?.standardEntityDesignationResponse;
    const clonedDesignationsResponse = cloneDesignationsResponse(
      standardEntityDesignations,
    );
    // $FlowIssue object values
    dispatch(setLocalStandardEntityDesignations(clonedDesignationsResponse));
  };

export const fetchStandardEntityDesignations: () => ThunkAction<void> = flow(
  key(() => 'entity_attributes/standard/designations'),
  fetching(),
)(() => (dispatch: Dispatch) => {
  dispatch(getStandardEntityDesignationsLoading());
  return dispatch(reduxApi.get(`entity_attributes/standard/designations`)).then(
    (response: StandardEntityDesignationResponse) => {
      // $FlowIssue object values
      dispatch(setStandardEntityDesignations(response));
    },
    (error) => {
      // $FlowIssue object values
      dispatch(
        getStandardEntityDesignationsError(
          error?.response?.errors || DEFAULT_ERROR,
        ),
      );
    },
  );
});

export const fetchStandardEntity: () => ThunkAction<void> = flow(
  key(() => 'entity_attributes/standard/attributes'),
  fetching(),
)(() => (dispatch: Dispatch) => {
  dispatch(getStandardEntityLoading());
  return dispatch(reduxApi.get(`entity_attributes/standard/attributes`)).then(
    (response: StandardEntityAttributes) => {
      // $FlowIssue object values
      dispatch(setStandardEntity(response));
    },
    (error) => {
      // $FlowIssue object values
      dispatch(
        getStandardEntityError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

// $FlowIssue object values
export const saveStandardFieldMapping =
  (migrate: boolean): ThunkAction<void> =>
  (dispatch: Dispatch, getState: GetState) => {
    const standardEntityDesignations: StandardEntityDesignationResponse =
      getState().fieldMapping?.localStandardEntityDesignation;
    // $FlowIssue object values
    dispatch(saveStandardFieldMappingLoading());
    const url = 'entity_attributes/standard/designations';

    return dispatch(
      reduxApi.post(url, {standard_designations: standardEntityDesignations}),
    ).then(
      (response) => {
        // $FlowIssue object values
        dispatch(saveStandardFiledMappingSuccess());
        if (migrate === true) {
          // $FlowIssue object values
          dispatch(updateActiveFieldMappingScreen('Confirm'));
        }
        // $FlowIssue object values
        dispatch(setStandardEntityDesignations(response));
      },
      (error) => {
        // $FlowIssue object values
        dispatch(
          saveStandardFiledMappingError(
            error?.response?.errors || DEFAULT_ERROR,
          ),
        );
      },
    );
  };

export const confirmMigrationFieldMapping =
  (): ThunkAction<void> => (dispatch: Dispatch, getState: GetState) => {
    const standardEntityDesignations: StandardEntityDesignationResponse =
      getState().fieldMapping?.localStandardEntityDesignation;
    dispatch(confirmMigrateFieldMappingLoading());

    const url = 'entity_attributes/field_mapping/confirm_migration';
    // $FlowIssue object values
    return dispatch(
      reduxApi.post(url, {standard_designations: standardEntityDesignations}),
    ).then(
      // $FlowIssue object values
      (response) => {
        dispatch(confirmMigrateFieldMappingSuccess());
        return response;
      },
      (error) => {
        // $FlowIssue object values
        dispatch(
          confirmMigrateFiledMappingError(
            error?.response?.errors || DEFAULT_ERROR,
          ),
        );
      },
    );
  };
