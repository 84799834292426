// @flow strict
import type {ReferralProgramActions} from 'src/action-creators/referral-v2/programs';

import type {
  Programs,
  GenericError,
  ProgramJobCount,
  ReferralSetup,
  ReferralBonusAndPayoutSettings,
} from 'src/types/referral-v2';
import {
  INITIAL_SETUP_STATE,
  INITIAL_REFERRAL_SETTINGS,
} from 'src/utils/referral-v2';
import {
  PROGRAM_DETAILS_FORM_DATA,
  ACTIVE_PROGRAMS,
  INACTIVE_PROGRAMS,
  PROGRAMS_ERROR,
  PROGRAMS_LOADING,
  PROGRAM_JOBS_COUNT,
  PROGRAM_JOBS_COUNT_ERROR,
} from 'src/action-creators/referral-v2/programs';


export const loadingState = {
  IDLE: 'idle',
  FETCHING_PROGRAMS: 'fetchingPrograms',
};

const initialState = {
  active_programs: null,
  inactive_programs: null,
  programs_error: null,
  current_state: loadingState.IDLE,
  program_job_count: null,
  program_job_count_error: null,
  program_details_form_data: {
    setup: INITIAL_SETUP_STATE,
    bonus_and_payout_settings:
      INITIAL_REFERRAL_SETTINGS.bonus_and_payout_settings,
  },
};

type ReferralProgramState = {
  current_state?: ?string,
  active_programs?: ?Programs,
  inactive_programs?: ?Programs,
  programs_error?: ?GenericError,
  program_details_form_data: {
    setup: ReferralSetup,
    bonus_and_payout_settings: ReferralBonusAndPayoutSettings,
  },
  program_job_count?: ?ProgramJobCount,
  program_job_count_error?: ?GenericError,
};

export default (
  state: ReferralProgramState = initialState,
  action: ReferralProgramActions,
): ReferralProgramState => {
  switch (action.type) {
    case ACTIVE_PROGRAMS:
      return {
        ...state,
        active_programs: action.payload,
        programs_error: null,
        current_state: loadingState.IDLE,
      };
    case INACTIVE_PROGRAMS:
      return {
        ...state,
        inactive_programs: action.payload,
        programs_error: null,
        current_state: loadingState.IDLE,
      };
    case PROGRAMS_ERROR:
      return {
        ...state,
        programs_error: action.payload,
        current_state: loadingState.IDLE,
      };
    case PROGRAMS_LOADING:
      return {...state, current_state: loadingState.FETCHING_PROGRAMS};

    case PROGRAM_JOBS_COUNT:
      return {
        ...state,
        program_job_count: action.payload,
        program_job_count_error: null,
        current_state: loadingState.IDLE,
      };

    case PROGRAM_JOBS_COUNT_ERROR:
      return {
        ...state,
        program_job_count_error: action.payload,
        current_state: loadingState.IDLE,
      };
    case PROGRAM_DETAILS_FORM_DATA:
      return {
        ...state,
        program_details_form_data: action.payload,
        current_state: loadingState.IDLE,
      };

    default:
      return {...state};
  }
};
