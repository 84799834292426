var React = require('react');

function ConfirmIcon (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M8.997 14.595L5.84 11.438a.3.3 0 00-.424 0l-.99.99a.3.3 0 000 .424l4.368 4.35a.3.3 0 00.425 0l9.35-9.35a.3.3 0 000-.424l-.99-.99a.3.3 0 00-.425 0l-8.157 8.157z"}));
}

ConfirmIcon.defaultProps = {"width":"22","height":"22","viewBox":"0 0 22 22"};

module.exports = ConfirmIcon;

ConfirmIcon.default = ConfirmIcon;
