// @flow

import flow from 'lodash/flow';

/**
 * This is a utility method that allows us to correctly type or thunks.
 * It simply delegates to _.flow
 *
 * Use it when decorating thunks with things like `cached` and `fetching`
 * so that the decorated thunk has the exactly same signature of the
 * thunk that's passed in.
 *
 * For context, we need this because our decorators are essentially un-typable
 * in Flow (because of Flow's issues with generic argument tuples). If we can
 * find a way to correctly type them, we can get rid of this method entirely.
 */
export function thunkify(...decorators: any[]): <F>(F) => F {
  // $FlowFixMe this is a hack to make our thunks work
  return flow(...decorators);
}
