// @flow strict
import type {FilterRule, FilterBlock} from 'src/types/filter';
import type {FilterAction} from 'src/action-creators/filter';

import {
  createFilterBlockIdentifier,
  getDefaultFilterBlock,
  cloneRuleWithoutValues,
} from 'src/utils/filter';
import {
  RECEIVE_FILTER,
  RESET,
  SET_RULES,
  ADD_BLOCK,
  REMOVE_BLOCK,
  CLONE_BLOCK,
  SET_SCOPE,
} from 'src/action-creators/filter';


export type FilterState = {
  blocks: Map<string, FilterBlock>,
};

const initialState = {
  blocks: new Map(getDefaultFilterBlock()),
};

export default (
  state: FilterState = initialState,
  action: FilterAction,
): FilterState => {
  switch (action.type) {
    case RECEIVE_FILTER: {
      const {blocks} = action.payload;
      return {
        ...state,
        blocks,
      };
    }
    case RESET: {
      return {...initialState};
    }
    case SET_RULES: {
      const {blockId, rules} = action.payload;
      const updatedMap = new Map(state.blocks);
      const currentEntry = updatedMap.get(blockId);
      if (currentEntry) {
        const updatedEntry = {
          rules,
          scope: currentEntry.scope,
        };
        updatedMap.set(blockId, updatedEntry);
      }
      return {
        ...state,
        blocks: updatedMap,
      };
    }
    case SET_SCOPE: {
      const {blockId, scope} = action.payload;
      const updatedMap = new Map(state.blocks);
      const currentEntry = updatedMap.get(blockId);
      if (currentEntry) {
        const updatedEntry = {
          rules: currentEntry.rules,
          scope,
        };
        updatedMap.set(blockId, updatedEntry);
      }
      return {
        ...state,
        blocks: updatedMap,
      };
    }
    case ADD_BLOCK: {
      const updatedMap = new Map(state.blocks);
      updatedMap.set(createFilterBlockIdentifier(), {rules: [], scope: null});
      return {
        ...state,
        blocks: updatedMap,
      };
    }
    case REMOVE_BLOCK: {
      return {
        ...state,
        blocks: new Map(
          [...state.blocks.entries()].filter(
            ([key, _]) => key !== action.payload,
          ),
        ),
      };
    }
    case CLONE_BLOCK: {
      return {
        ...state,
        blocks: [...state.blocks.entries()].reduce(
          (accumulator, [key, value]) => {
            accumulator.set(key, value);
            if (key === action.payload) {
              accumulator.set(
                createFilterBlockIdentifier(),
                //$FlowFixMe todo(vish): fix this while integrating with backend.
                cloneRuleWithoutValues(value),
              );
            }
            return accumulator;
          },
          new Map(),
        ),
      };
    }
  }
  return state;
};
