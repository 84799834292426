// @noflow

import type {
  AudienceMember,
  AudienceMemberPlacement,
  NewAudienceMember,
  AudienceMembersPage,
} from 'src/api-parsers/index';
import type {PlacementFieldType} from 'src/components/users/placement-field.jsx';

import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import omit from 'lodash/omit';

import Store from './base';


export type AudienceFieldsToTypes = {
  [fieldName: string]: string,
};
type OnFieldOptions = {
  value: string,
  label: string,
}[];
export type MemberFieldsToTypes = {
  [fieldName: string]: PlacementFieldType,
};

type PlacementRow = {
  name: string,
  type: string,
  value: any,
  label: string,
};

export type ResolvedPlacement = {
  id: string,
  data: {
    [key: string]: PlacementRow,
  },
};

export default class AudienceMemberStore extends Store {
  state: {
    editing: ?AudienceMember,
    updating: ?Promise<boolean>,
    audienceMembers: {
      [key: string]: AudienceMember,
    },
    audienceMembersPage: {
      [key: string]: Object,
    },
    audienceMembersPlacements: {
      [key: string]: Object[],
    },
    fieldsToTypes: AudienceFieldsToTypes,
    ON_FIELD_OPTIONS: OnFieldOptions,
    memberFieldsToTypes: MemberFieldsToTypes, // Candidate only fields
    placementFieldsToTypes: Object, // Placement only fields
    memberFields?: Object,
    placementFields?: Object,
  };

  constructor() {
    super('audience-member');

    this.state = {
      editing: null,
      updating: null,
      audienceMembers: {},
      audienceMembersPage: {},
      audienceMembersPlacements: {},
      fieldsToTypes: {},
      ON_FIELD_OPTIONS: [],
      memberFieldsToTypes: {},
      placementFieldsToTypes: {},
    };
  }

  get(audienceMemberId: string): AudienceMember {
    return this.state.audienceMembers[audienceMemberId];
  }

  getPlacements(audienceMemberId: string): AudienceMemberPlacement[] {
    const placements = this.state.audienceMembersPlacements[audienceMemberId];
    return placements || [];
  }

  getPage(page: number, workflowId: ?string): ?AudienceMembersPage {
    let key = `${page}`;
    if (workflowId) {
      key = `${page}:${workflowId}`;
    }
    const pageData = this.state.audienceMembersPage[key];
    if (pageData === undefined) {
      return pageData;
    }

    let memberList = [];
    memberList = map(pageData.memberIds, id => this.state.audienceMembers[id]);

    return {
      page: pageData.page,
      numPages: pageData.numPages,
      numTotalMembers: pageData.numTotalMembers,
      workflowId: pageData.workflowId,
      memberList,
    };
  }

  getCurrentEditing(): AudienceMember {
    return this.state.editing;
  }

  getSet(audienceMemberIds: string[] = []): AudienceMember[] {
    return audienceMemberIds.map(id => this.state.audienceMembers[id]);
  }

  getNew(): NewAudienceMember {
    return {
      id: 'new',
      externalSourceType: 'api',
      tags: {},
    };
  }

  clearPages() {
    this.updateState({
      audienceMembersPage: {
        $set: [],
      },
    });
  }

  receiveCreated(audienceMember: AudienceMember) {
    // Clear pages
    this.clearPages();

    this.updateState({
      updating: {$set: null},
      audienceMembers: {
        [audienceMember.id]: {
          $set: audienceMember,
        },
      },
    });
  }

  clearEditing() {
    this.updateState({
      editing: {$set: null},
    });
  }

  receivePage(audienceMembersPage: AudienceMembersPage) {
    const memberIds = map(audienceMembersPage.memberList, 'id');
    const pageData = {
      page: audienceMembersPage.page,
      numTotalMembers: audienceMembersPage.numTotalMembers,
      workflowId: audienceMembersPage.workflowId,
      numPages: audienceMembersPage.numPages,
      memberIds,
    };

    const audienceMembers = audienceMembersPage.memberList;

    let key = `${audienceMembersPage.page}`;
    if (audienceMembersPage.workflowId) {
      key = `${audienceMembersPage.page}:${audienceMembersPage.workflowId}`;
    }

    let memberChangeSpec = map(audienceMembers, audienceMember => ({
      $set: audienceMember,
    }));
    memberChangeSpec = keyBy(
      memberChangeSpec,
      changeSpec => changeSpec.$set.id,
    );

    this.updateState({
      audienceMembersPage: {
        [key]: {
          $set: pageData,
        },
      },
      audienceMembers: memberChangeSpec,
    });
  }

  receiveAudienceMember(audienceMember: AudienceMember) {
    this.updateState({
      audienceMembers: {
        [audienceMember.id]: {
          $set: audienceMember,
        },
      },
    });
  }

  receiveAudienceMemberPlacements(
    audienceMemberId: string,
    audienceMemberPlacements: AudienceMemberPlacement[],
  ) {
    this.updateState({
      audienceMembersPlacements: {
        [audienceMemberId]: {
          $set: audienceMemberPlacements,
        },
      },
    });
  }

  receiveAudienceMemberPlacement(
    audienceMemberId: string,
    newPlacement: AudienceMemberPlacement,
  ) {
    this.updateState({
      audienceMembersPlacements: {
        [audienceMemberId]: {
          $push: newPlacement,
        },
      },
    });
  }

  updatePlacement(
    audienceMemberId: string,
    placementId: string,
    newPlacement: AudienceMemberPlacement,
  ) {
    const placements = this.state.audienceMembersPlacements[audienceMemberId];
    const placementIndex = placements.findIndex(p => p.id === placementId);

    this.updateState({
      audienceMembersPlacements: {
        [audienceMemberId]: {
          [placementIndex]: {$set: newPlacement},
        },
      },
    });
  }

  deletePlacement(audienceMemberId: string, placementId: string) {
    // TODO(marcos): dupe placementid finding?
    // this feels like a spot to break out placements into their own store
    const placements = this.state.audienceMembersPlacements[audienceMemberId];
    const placementIndex = placements.findIndex(p => p.id === placementId);

    this.updateState({
      audienceMembersPlacements: {
        [audienceMemberId]: {
          $splice: [[placementIndex, 1]],
        },
      },
    });
  }

  updateAll(audienceMembers: AudienceMember[]) {
    const newAudienceMembers = keyBy(
      audienceMembers,
      audienceMember => audienceMember.id,
    );
    this.updateState({
      audienceMembers: {
        $merge: newAudienceMembers,
      },
    });
  }

  edit(audienceMember: ?AudienceMember) {
    this.setState({
      editing: audienceMember,
    });
  }

  change(field: string, value: mixed) {
    this.updateState({
      editing: fieldToAudienceMemberSpec(field, value),
    });
  }

  startUpdating(promise: Promise<boolean>) {
    this.setState({
      updating: promise,
    });
  }

  receiveUpdated(audienceMember: AudienceMember) {
    this.updateState({
      updating: {$set: null},
      audienceMembers: {
        [audienceMember.id]: {
          $set: audienceMember,
        },
      },
    });
  }

  errorUpdating() {
    this.setState({
      updating: null,
    });
  }

  remove(audienceMember: AudienceMember) {
    // Clear pages
    this.clearPages();

    const audienceMembers = omit(this.state.audienceMembers, audienceMember.id);
    this.setState({audienceMembers});
  }

  // TODO (kyle): this violates normalization of store data. should probably just be generated by a common mapper
  // TODO(marcos): move this into api-parsers?
  setFieldsToTypes(fieldsToTypes: {
    [key: string]: 'date' | 'boolean' | 'number' | 'string' | 'currency',
  }) {
    fieldsToTypes = omit(fieldsToTypes, fieldsToHide);

    const ON_FIELD_OPTIONS = [];
    Object.getOwnPropertyNames(fieldsToTypes).map(field => {
      if (fieldsToTypes[field] === 'date') {
        ON_FIELD_OPTIONS.push({
          value: field,
          label: field,
        });
      }
    });
    this.setState({
      fieldsToTypes,
      ON_FIELD_OPTIONS,
    });
  }

  getFieldsToTypes(): AudienceFieldsToTypes {
    return this.state.fieldsToTypes;
  }

  getOnFieldOptions(): OnFieldOptions {
    return this.state.ON_FIELD_OPTIONS;
  }

  setMemberFieldsToTypes(memberFieldsToTypes: MemberFieldsToTypes) {
    this.setState({
      memberFieldsToTypes,
    });
  }

  setPlacementFieldsToTypes(placementFieldsToTypes: Object) {
    this.setState({
      placementFieldsToTypes,
    });
  }

  getMemberFieldsToTypes(): Object {
    return this.state.memberFieldsToTypes;
  }

  getPlacementFieldsToTypes() {
    return this.state.placementFieldsToTypes;
  }

  setMemberFields(memberFields) {
    this.setState({
      memberFields,
    });
  }

  getMemberFields() {
    return this.state.memberFields;
  }

  setPlacementFields(placementFields) {
    this.setState({
      placementFields,
    });
  }

  getPlacementFields() {
    return this.state.placementFields;
  }
}

const fieldToAudienceMemberSpec = (field, value) =>
  FIELD_TO_FIRST_LEVEL_PROPERTIES.hasOwnProperty(field)
    ? {[FIELD_TO_FIRST_LEVEL_PROPERTIES[field]]: {$set: value}}
    : {tags: {[field]: {$set: value}}};

// 'start date' and 'end date' exist on the server for backwards compat.
// Always use the newer 'start_date'/'end_date' instead.
export const deprecatedFields = ['start date', 'end date'];
// SMS Compliance fields we will custom handle when appropriate
export const smsComplianceFields = [
  'help_text_sent',
  'opt_out_sms',
  'opt_out_global',
];
export const fieldsToHide = deprecatedFields.concat(smsComplianceFields);

export const FIELD_TO_FIRST_LEVEL_PROPERTIES = {
  'first name': 'firstName',
  'last name': 'lastName',
  email: 'email',
  'full name': 'fullName',
  opt_out_sms: 'optOutSms',
  opt_out_global: 'optOutGlobal',
  help_text_sent: 'helpTextSent',
};

export const PLACEMENT_FIELD_TO_FIRST_LEVEL_PROPERTIES = {
  'start date': 'startDate',
  start_date: 'startDate',
  'end date': 'endDate',
  end_date: 'endDate',
  client: 'client',
};

export const AUDIENCE_MEMBER_FIELD_TO_LABEL = {
  'first name': 'First Name',
  'last name': 'Last Name',
  email: 'Email',
  'phone number': 'Phone Number',
  'full name': 'Full Name',
};

export const PLACEMENT_FIELD_TO_LABEL = {
  client: 'Client',
  start_date: 'Start Date',
  end_date: 'End Date',
};
