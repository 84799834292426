// @flow

import type {ThunkAction} from 'src/reducers';
import type {FieldMap} from 'src/types/audience-member';

import {thunkify as flow} from 'src/utils/thunks';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api';


export const RECEIVE_ALL = 'audienceMembers/fields/receiveAll';

type ReceiveAllAction = {
  type: 'audienceMembers/fields/receiveAll',
  payload: FieldMap,
};
export type FieldsAction = ReceiveAllAction;

export const receiveAll = (payload: FieldMap): ReceiveAllAction => ({
  type: RECEIVE_ALL,
  payload,
});

export const getAllFields: () => ThunkAction<mixed> = flow(
  key(() => 'audienceMember fields'),
  cached((fields) => receiveAll(fields), {ttl: 60000}),
  fetching(),
)(() => reduxApi.get('audience-members/fields'));
