// @flow strict

import * as React from 'react';

import throttleAnimation from 'src/utils/throttle-animation';


export const WindowWidthContext: React.Context<number> = React.createContext<number>(
  0,
);

export function WindowWidthProvider({
  defaultWidth = 0,
  children,
}: {
  defaultWidth?: number,
  children: React.Node,
}): React.Node {
  const [width, setWidth] = React.useState(defaultWidth);

  React.useLayoutEffect(() => {
    const handleResize = throttleAnimation(() => {
      setWidth(window.innerWidth);
    });
    window.addEventListener('resize', handleResize);
    setWidth(window.innerWidth);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <WindowWidthContext.Provider value={width}>
      {children}
    </WindowWidthContext.Provider>
  );
}

export function useWindowWidth(): number {
  return React.useContext(WindowWidthContext);
}
