// @flow strict
import * as React from 'react';
import {useSelector} from 'react-redux';
import ContactIcon from 'src/images/icons/contact-nofill.svg';
import classify from 'src/utils/classify';
import {
  Clickable,
  Smallest,
  SubHeader,
} from 'src/designSystem2021Components/text-v2.jsx';
// $FlowFixMe
import {useLoadingApi} from 'src/hooks/useApi';
import {Loading} from 'src/components/lib/loading';
//$FlowFixMe[nonstrict-import]
import {selectAgencyData} from 'src/selectors/agency';
import ManagerIcon from 'src/components/agency-onboard/images/implementation-manager.svg';
import {AnalyticsService} from 'src/analytics';
import {AMPLITUDE_EVENTS} from 'src/components/agency-onboard/amplitude_event.js';

import maincss from 'src/components/agency-onboard/agency-onboard.css';
import css from './implementation-manager.css';


export const ImplementationManager = (): React.Node => {
  const [showToolTip, setShowToolTip] = React.useState(false);
  const agencyData = useSelector(selectAgencyData);

  const handleTrackingEvent = (event) => {
    AnalyticsService.track(event, {
      agencyId: agencyData.id,
      agencyName: agencyData.name,
    });
  };

  const {response: formData, loaded: dataLoaded} = useLoadingApi(
    () => `onboard/implementation-manager`,
    [],
  );

  return (
    <>
      {showToolTip && (
        <div className={css.infoCard}>
          <SubHeader className={maincss.textPrimary}>
            Your Implementation Manager
          </SubHeader>
          <div className={css.infoCardDetail}>
            <div className={css.infoCardImage}>
              <ManagerIcon />
            </div>
            <div className={css.infoCardPerson}>
              <Clickable
                className={classify(css.infoCardName, maincss.textPrimary)}
              >
                {formData?.name || 'Implementation Manager'}
              </Clickable>
              <Smallest className={css.infoCardEmail}>
                {formData?.email ? (
                  <>
                    <a href={'mailto:' + formData.email}>{formData.email}</a>
                  </>
                ) : (
                  'manager email'
                )}
              </Smallest>
            </div>
          </div>
        </div>
      )}

      <div
        className={css.iconContainer}
        onClick={() => {
          if (!showToolTip) {
            handleTrackingEvent(AMPLITUDE_EVENTS.SUPPORT_BUTTON_CLICKED);
          }
          setShowToolTip(!showToolTip);
        }}
      >
        <ContactIcon className={css.icon} />
      </div>
    </>
  );
};
