// @flow strict
import * as React from 'react';
import OnboardTaskList from 'src/components/agency-onboard/task-list/task-list.jsx';
//$FlowFixMe
import {useLoadingApi} from 'src/hooks/useApi';

import TaskListHeader from 'src/components/agency-onboard/task-list-header/task-list-header.jsx';
import {Loading} from 'src/components/lib/loading';
import css from './task-container.css';
import maincss from 'src/components/agency-onboard/agency-onboard.css';


export default function OnboardTasksContainer(): React.Node {
  const [tasks, setTasks] = React.useState(null);
  const {response: tasksList, loaded} = useLoadingApi(
    () => 'onboard/tasks',
    [],
  );

  React.useEffect(() => {
    if (tasksList && tasksList.length > 0) {
      const tasksDict = Object.assign(
        {},
        ...tasksList.map((each) => ({[each.id]: each})),
      );
      setTasks(tasksDict);
    }
  }, [tasksList]);

  if (!loaded || !tasksList) {
    return (
      <div>
        <Loading className={maincss.loaderDiv} />
      </div>
    );
  }

  if (tasks) {
    return (
      <div className={css.onboardTasksContainer}>
        <div className={css.taskSection}>
          <TaskListHeader tasks={tasks} />
          <OnboardTaskList tasks={tasks} setTasks={setTasks} />
        </div>
      </div>
    );
  }

  return <></>;
}
