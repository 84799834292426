//@flow strict

export type BooleanSelectOption = {value: boolean, label: string};

export type NumericSelectOption = {value: number, label: string};

export type StringSelectOption = {value: string, label: string};
export const multipleChoiceOptionMaxLength = 300;
export const INCLUDE_JOB_BLOCK_OPTIONS: BooleanSelectOption[] = [
  {value: true, label: 'Yes'},
  {value: false, label: 'No'},
];

export const ALT_MESSAGE_OPTIONS: BooleanSelectOption[] = [
  {value: true, label: 'Use this alternate text block'},
  {value: false, label: `Don't send the email`},
];
export const SMS_ALT_MESSAGE_OPTIONS: BooleanSelectOption[] = [
  {value: true, label: 'Use this alternate text block'},
  {value: false, label: `Don't send the SMS`},
];
export const SMS_MAX_CHARACTER_LIMIT = 1600;
export const JOB_MATCH_PARAMS_RADIUS_OPTIONS: NumericSelectOption[] = [
  {value: 0, label: `Won't be used`},
  {value: 10, label: '10 miles max'},
  {value: 20, label: '20 miles max'},
  {value: 50, label: '50 miles max'},
  {value: 100, label: '100 miles max'},
  {value: 200, label: '200 miles max'},
];
