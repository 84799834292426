// @noflow

import type {RouteLocation} from 'src/types/router';

import * as React from 'react';
import {Link} from 'src/rerouter';

import {prettifyNumber} from 'src/utils/index.js';

import ArrowRight from 'src/images/arrow-right.svg?noAttrs';

import css from './tile.css';


type Props = {
  icon: React.Node,
  label: string,
  number?: number | string,
  to?: string | RouteLocation,
};

const Tile = (props: Props) => {
  const {icon, label, number, to} = props;

  const inner = [
    <div key="figure" className={css.statsFigure}>
      {icon}
      <div className={css.statsNumber}>
        {number && <span>{prettifyNumber(number)}</span>}
        {to && <ArrowRight className={css.statsArrow} />}
      </div>
    </div>,
    <div key="text" className={css.statsText}>
      {label}
    </div>,
  ];

  return to ? (
    <Link className={css.statsLink} to={to}>
      {inner}
    </Link>
  ) : (
    <div className={css.statsLinkOff}>{inner}</div>
  );
};

export default Tile;
