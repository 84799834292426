// @flow strict

import * as React from 'react';
import {UnstyledButton} from '@spaced-out/ui-lib/lib/button';

import classify from 'src/utils/classify';

import css from './walkme-button.css';


export default function WalkmeButton({
  className,
}: {
  className?: string,
}): React.Node {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  React.useEffect(() => {
    if (window.WalkMePlayerAPI) {
      setIsLoaded(true);
    } else {
      window.walkme_ready = () => {
        setIsLoaded(true);
      };

      return () => {
        window.walkme_ready = null;
      };
    }
  }, []);

  React.useEffect(() => {
    if (isLoaded) {
      const callback = () => {
        // NOTE (kyle): we don't care which player event this actually is.
        setIsMenuOpen(window.WalkMePlayerAPI.isMenuOpen());
      };
      callback();
      window.walkme_player_event = callback;
      return () => {
        window.walkme_player_event = null;
      };
    }
  }, [isLoaded]);

  return (
    isLoaded &&
    !isMenuOpen && (
      <UnstyledButton
        className={classify(className, css.button)}
        onClick={() => {
          window.WalkMePlayerAPI.toggleMenu();
        }}
      >
        Sense Support
      </UnstyledButton>
    )
  );
}
