// @flow strict

export function fromObject<T>(object: {[string]: T, ...}): Map<string, T> {
  // $FlowIssue Object.entries
  return new Map((Object.entries(object): Array<[string, T]>));
}

export function fromArray<I, T: {id: I, ...}>(array: Iterable<T>): Map<I, T> {
  // $FlowIssue iterator helpers
  return new Map(Iterator.from(array).map((t) => [t.id, t]));
}

export function setMap<K, V>(map: Map<K, V>, key: K, value: V): Map<K, V> {
  const newMap = new Map(map);
  newMap.set(key, value);
  return newMap;
}

export function keyBy<K, T: {...}>(
  array: Array<T>,
  propName: string,
): Map<K, T> {
  return new Map(array.map((t) => [t[propName], t]));
}

export function bucketBy<K, T: {...}>(
  iterable: Iterable<T>,
  propName: string,
): Map<K, T[]> {
  const map = new Map();
  for (const item of iterable) {
    let bucket = map.get(item[propName]);
    if (!bucket) {
      bucket = [];
      map.set(item[propName], bucket);
    }
    bucket.push(item);
  }
  return map;
}

export function set<K, V>(map: Map<K, V>, key: K, value: V): Map<K, V> {
  const newMap = new Map(map);
  newMap.set(key, value);
  return newMap;
}

export function del<K, V>(map: Map<K, V>, key: K): Map<K, V> {
  const newMap = new Map(map);
  newMap.delete(key);
  return newMap;
}
