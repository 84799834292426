// @flow

import type {TypeaheadType} from 'src/components/typeahead/with-typeahead.jsx';
import type {TokenListInputClassNames} from 'src/components/lib/token-list-input/new-stuff.jsx';

import * as React from 'react';

import {StringSelectList} from 'src/components/lib/token-list-input';
import {useTypeahead} from 'src/components/typeahead/with-typeahead.jsx';

import {classify} from 'src/utils';

import css from './select-list.css';


type Props = {
  className?: string,
  optionClassName?: string,
  containerClassName?: string,
  classNames?: TokenListInputClassNames,
  onChange: (values: string[]) => mixed,
  values: string[],
  showValuesOutside?: mixed,
  name?: string,
  allowArbitraryValues?: boolean,
  isExpandable?: boolean,

  typeaheadType: TypeaheadType | 'eventResources',
  // TODO (kyle): for ME should these be called `entityType` and `attributeName`?
  typeaheadName?: string,
  typeaheadSubname?: string,
  placeholder?: string,
};

const TypeaheadSelectList = ({
  onChange,
  values = [],
  typeaheadType,
  typeaheadName,
  typeaheadSubname,
  className,
  classNames,
  containerClassName,
  optionClassName,
  ...props
}: Props): React.Node => {
  const [searchResults, handleSearch, _handleClear] = useTypeahead(
    typeaheadType,
    typeaheadName,
    typeaheadSubname,
  );

  const search = (searchString: string = '') =>
    handleSearch(searchString, console.error);

  return (
    <StringSelectList
      options={searchResults || []}
      onInputChange={search}
      onInputFocus={() => search()}
      onChange={onChange}
      values={values}
      {...props}
      classNames={{
        ...classNames,
        outerContainer: classify(
          containerClassName,
          classNames?.outerContainer,
        ),
        container: classify(className, classNames?.container),
        option: classify(css.option, optionClassName, classNames?.option),
      }}
    />
  );
};

export default TypeaheadSelectList;
