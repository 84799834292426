// @flow strict-local

import type {InsightCounts} from 'src/types/report';
import type {InsightCountsAction} from 'src/action-creators/insight-counts';

import {
  RECEIVE_EVENT,
  RECEIVE_AGENCY,
} from 'src/action-creators/insight-counts';


export type InsightCountsState = {
  agency: ?InsightCounts,
  events: {
    [eventId: string]: InsightCounts,
  },
};

const initialState = {
  agency: null,
  events: Object.freeze({}),
};

export default (
  state: InsightCountsState = initialState,
  action: InsightCountsAction,
): InsightCountsState => {
  switch (action.type) {
    case RECEIVE_EVENT:
      return {
        ...state,
        events: {
          ...state.events,
          [action.payload.eventId]: action.payload.counts,
        },
      };

    case RECEIVE_AGENCY:
      return {
        ...state,
        agency: action.payload,
      };
  }
  return state;
};
