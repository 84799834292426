// @flow strict

// $FlowFixMe[nonstrict-import]
import type {AttributeMetaData} from 'src/types/writeback';

// $FlowFixMe[nonstrict-import]
import {useNewAllowedAttributes} from 'src/hooks/product-flags';
import {getFWBKey} from './utils';

/**
 * a hook that returns properties/accessors for working with writeback attributes
 *
 * in chatbot this value always defaults to the useNewAllowedAttributes
 * hook/flag/config value, but some parts of the codebase do not yet
 * understand this field's value, so despite it being set and available,
 * using it in journeys will cause errors. To force the use of the old
 * field key, pass in `false` and likewise to override the agency config
 * value here, pass in `true`. leaving the parameter blank will
 * @param  {[boolean]} forceOWBAttrs      an optional override for useNewAllowedAttributes
 * @return {[Object]}                     returns a simple object with {
 *                                          wbFieldIdName: string,
 *                                          getWbFieldId: (?AttributeMetaData) => string,
 *                                        }
 */
export function useOWBFields(forceOWBAttrs?: boolean): {
  wbFieldIdName: string,
  getWbFieldId: (?AttributeMetaData) => ?string,
} {
  const usesOWB = useNewAllowedAttributes();
  return getFWBKey(forceOWBAttrs != null ? forceOWBAttrs : usesOWB);
}
