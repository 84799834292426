// @flow strict

//$FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';

import type {
  JobMatchCountParam,
  JobMatchQueryParam,
  JobMatchSortParam,
} from 'src/types/job-variables.js';

import {createSelector} from 'reselect';

/*
 * Base Selectors
 */

export const selectJobMetaFilterParams = (state: State): JobMatchQueryParam[] =>
  state.jobMatchMeta.queryParams?.filter(
    (param) => param.visibility === 'ENABLED',
  );

export const selectJobMatchCountParam = (state: State): JobMatchCountParam =>
  state.jobMatchMeta.countParam;

export const selectJobMatchSortParam = (state: State): JobMatchSortParam =>
  state.jobMatchMeta.sortParam;

export const selectJobMatchParamDependants = (
  state: State,
): Map<string, string[]> => {
  const dependants = new Map<string, string[]>();
  const {sortOptions} = state.jobMatchMeta.sortParam || {};

  state.jobMatchMeta.queryParams?.concat(sortOptions).forEach((param) => {
    const {depends_on_either, id} = param || {};
    if (Array.isArray(depends_on_either) && depends_on_either.length) {
      depends_on_either.map((filter) => {
        if (Array.isArray(dependants.get(filter))) {
          const arr = dependants.get(filter) || [];
          arr.push(id);
          dependants.set(filter, arr);
        } else {
          dependants.set(filter, [id]);
        }
      });
    }
  });

  return dependants;
};
