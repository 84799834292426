// @flow

import type {State} from 'src/reducers';
import type {
  PhoneNumberSet,
  GenericOptionsType,
} from 'src/types/chat/phone-number-sets';

import {createSelector} from 'reselect';

import {values} from 'src/utils/object';
import {first} from 'src/utils/array';
import {getCountryFromPhone, formatPhone} from 'src/utils/phone';
import {resolveDisplayPhoneNumber} from 'src/utils/phone-number-sets';
import {selectLATEnabled} from 'src/hooks/product-flags';

import {selectDefaultPhoneCountry} from 'src/selectors/agency';


export const selectPhoneNumberSetsArray: (State) => PhoneNumberSet[] =
  createSelector(
    (state: State) => state.chat.phoneNumberSets,
    (phoneNumberSets): PhoneNumberSet[] => values(phoneNumberSets),
  );

export const selectPhoneNumberSetFromId: State = (phoneNumberSetId: string) =>
  createSelector(selectPhoneNumberSetsArray, (phoneNumberSetsArray) =>
    phoneNumberSetsArray.find(
      (phoneNumberSet) => phoneNumberSet.sms.id === phoneNumberSetId,
    ),
  );

export const selectOrderedPhoneNumberSetsArray: (State) => PhoneNumberSet[] =
  createSelector(
    selectPhoneNumberSetsArray,
    (phoneNumberSets): PhoneNumberSet[] => {
      const primarySet = phoneNumberSets?.find(
        (phoneNumberSet) => phoneNumberSet.is_primary,
      );

      if (!primarySet) {
        return phoneNumberSets;
      }

      return [
        primarySet,
        ...phoneNumberSets.filter(
          (phoneNumberSet) =>
            phoneNumberSet.phone_numbers[0] !== primarySet.phone_numbers[0],
        ),
      ];
    },
  );

export const selectDefaultPhoneNumberSet: (State) => PhoneNumberSet = (state) =>
  state.chat.phoneNumberSets[
    state.chat.multiNumberInbox.selectedPhoneNumberSetId
  ] ||
  selectPrimaryPhoneNumberSet(state) ||
  first(selectPhoneNumberSetsArray(state));

export const selectPhoneNumberSetFromDefaultPhoneNumberSet: (State) => string =
  createSelector(
    selectDefaultPhoneNumberSet,
    selectLATEnabled,
    (phoneNumberSet): string => {
      const channel = phoneNumberSet?.['selected_channel'];
      const idKey = 'id';

      return phoneNumberSet?.[channel]?.[idKey];
    },
  );
export const selectChannelFromDefaultPhoneNumberSet: (State) => string =
  createSelector(
    selectDefaultPhoneNumberSet,
    (phoneNumberSet): string => phoneNumberSet?.['selected_channel'],
  );

export const selectAutoReplyStatusFromPhoneNumberSet: (State) => string =
  createSelector(selectDefaultPhoneNumberSet, (phoneNumberSet): string =>
    phoneNumberSet?.autoreply?.active ? 'on' : 'off',
  );

export const selectPrimaryPhoneNumberSet: (State) => ?PhoneNumberSet =
  createSelector(
    selectPhoneNumberSetsArray,
    (phoneNumberSets): ?PhoneNumberSet =>
      phoneNumberSets?.find((phoneNumberSet) => phoneNumberSet.is_primary),
  );

export const selectFirstPhoneNumberSet = (state: State): ?PhoneNumberSet =>
  first(selectPhoneNumberSetsArray(state));

export const selectDisplayPhoneNumber = (state: State): ?string =>
  resolveDisplayPhoneNumber(selectDefaultPhoneNumberSet(state));

export const selectCurrentPhoneCountry: (State) => string = createSelector(
  selectDisplayPhoneNumber,
  selectDefaultPhoneCountry,
  (phoneNumber, defaultPhoneCountry) =>
    (phoneNumber && getCountryFromPhone(phoneNumber)) || defaultPhoneCountry,
);

export const selectInboxesAsOptionsForHVBroadcast = (
  state: State,
): GenericOptionsType[] => {
  const countryCode = selectCurrentPhoneCountry(state);
  const inbox = selectPhoneNumberSetsArray(state);
  // remove this condition when whatsapp is supported in HVB
  const smsInboxes = inbox.filter((inboxObj) => inboxObj.sms?.id);
  const inboxValuesToOptions = smsInboxes.map((smsInbox) => {
    const phoneNumber = smsInbox.phone_numbers[0];
    const formattedPhone = formatPhone(phoneNumber, countryCode) || phoneNumber;
    const label = smsInbox.name || formattedPhone;
    return {key: smsInbox.sms.id, label};
  });
  return inboxValuesToOptions;
};
