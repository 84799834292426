// @flow

// $FlowFixMe[untyped-type-import]
import type {Workflow} from 'src/api-parsers/index';
import type {Router} from 'src/types/router';
import type {ModalType} from 'src/components/modals/modal-root.jsx';
import * as React from 'react';

import FluxComponent from 'src/flux/component.jsx';
import {TitleBarModal} from 'src/components/modals/base-modal-design-system-2021.jsx';
import {Paragraph} from 'src/designSystem2021Components/text-v2.jsx';

import {deleteWorkflow} from 'src/actions/workflow';

/**(diwakersurya) copied from delete-modal to suit the needs of organizer */
const mapper = ({store, workflow: {id}}) => ({
  deletingWorkflow: Boolean(store.request.inProgress(`deleteWorkflow/${id}`)),
});

class WorkflowDeleteModal extends React.Component<{
  store: any,
  router: Router,
  // $FlowFixMe[value-as-type] [v1.32.0]
  workflow: Workflow,
  removeModal: () => void,
  deletingWorkflow: boolean,
  type: ModalType,
}> {
  constructor(props) {
    super(props);

    // $FlowFixMe[method-unbinding]
    (this: any).handleDelete = this.handleDelete.bind(this);
  }

  render() {
    const {deletingWorkflow, removeModal, type} = this.props;
    return (
      <TitleBarModal
        title={'Really delete this journey?'}
        confirmText={deletingWorkflow ? 'Deleting...' : 'Yes, delete it'}
        // $FlowFixMe[method-unbinding]
        handleConfirm={this.handleDelete}
        abortText={'Keep it'}
        handleAbort={removeModal}
        {...this.props}
        type={'GENERIC_INFO_DS_2021'}
      >
        <Paragraph style={{padding: '20px 20px 40px 20px'}}>
          Once you delete it, all the journey information will be lost. Any data
          collected so far will remain available in Analytics.
        </Paragraph>
      </TitleBarModal>
    );
  }

  handleDelete(event: SyntheticEvent<>) {
    const {store, workflow, removeModal} = this.props;
    event.preventDefault();
    deleteWorkflow(store, workflow).then(() => {
      removeModal();
    });
  }
}

export default (FluxComponent(
  WorkflowDeleteModal,
  mapper,
): React.ComponentType<{
  // $FlowFixMe[value-as-type] [v1.32.0]
  workflow: Workflow,
  removeModal: () => void,
  type: ModalType,
}>);
