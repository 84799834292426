// @flow

import type {Agency} from 'src/api-parsers/index.types';
import type {Dispatch} from 'src/reducers';
import type {RouteLocation} from 'src/types/router';
import IndexStore from 'src/stores/index';
import {setAIPanelOpenEvent} from 'src/action-creators/sense-ai-panel.js';

import * as React from 'react';

import * as reduxApi from 'src/utils/redux-api-v2';

import {connect, useSelector, useDispatch} from 'react-redux';
import {Link, useHistory} from 'src/rerouter';
import flow from 'lodash/flow';
import {provideHooks} from 'src/flux/provideHooks.jsx';
import {withWindowWidth} from 'src/flux/responsive.jsx';
import {fluxify} from 'src/flux/component.jsx';
import {setAuthPath} from 'src/actions';
import {getAgency} from 'src/action-creators/agency';
import {getAllEntities} from 'src/action-creators/ats-entities';
import {classify, addQueryParamsToUrl} from 'src/utils';
import {isChatAppLocation, isMobile} from 'src/utils/responsive';
import {getFromAuthedUser, hasPrivilege} from 'src/utils/accounts';
import {selectDisplayPhoneNumber, agentHasPhone} from 'src/selectors/chat';
import {selectCurrentPhoneCountry} from 'src/selectors/phone-number-sets';
import {selectFullscreen} from 'src/selectors/app-config.js';
import {selectUrlSuffix} from 'src/selectors/sense-ai-panel.js';

import useDisplayConfig from 'src/hooks/useDisplayConfig';
import {useMessagingInboxDrawer, useTRMEnabled} from 'src/hooks/product-flags';

import WalkmeButton from 'src/components/navigation/walkme-button.jsx';
import {RouterErrorBoundary} from 'src/components/500/boundary.jsx';
import Loader from 'src/components/navigation/loader.jsx';
import Notification from 'src/components/navigation/notification.jsx';
import BadBrowserNotification from 'src/components/navigation/bad-browser-notification.jsx';
import MessagesSocket from 'src/components/messaging/socket.jsx';
import ContextMenu from 'src/components/lib/context-menu';

import Logo from 'src/images/sense-logo.svg';
import PlacementsIcon from 'src/images/placements-icon.svg';

import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {selectTotalUnreadThreadCount} from 'src/selectors/messages';
import {selectBroadcastRecipientLimit} from 'src/selectors/broadcasts';
import {
  Panel,
  PanelBody,
} from '@spaced-out/ui-design-system/lib/components/Panel';
import {Badge} from '@spaced-out/ui-design-system/lib/components/Badge';
import {
  Modal,
  ModalBody,
} from '@spaced-out/ui-design-system/lib/components/Modal';
import BulkMessageWrarning from 'src/components/navigation/bulk-message-warning.jsx';

import AppShellSideNavContainer from 'src/components/app-shell/app-shell-side-nav-container.jsx';

import css from 'src/components/navigation/top.css';
import styles from 'src/components/app.css';

import appShellCss from './app-shell-container.css';

import {
  USE_MESSAGING,
  USE_JD_GENERATOR,
  USE_PRESCREENING_GENERATOR,
  USE_RESUME_GENERATOR,
} from 'src/action-creators/roles';


const peopleSecondaryNavConfig = {
  title: 'People',
  tabs: [
    {key: '/people', label: 'All People'},
    {key: '/people/leads', label: 'Leads'},
    {key: '/people/lists', label: 'Lists'},
    {key: '/people/leads/detail', label: 'Lead Detail'},
  ],
};

type OuterProps = {
  children: React.Node,
};

type TopNavigationProps = {
  ...OuterProps,
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  authed: boolean,
  agency: Agency,
  name: ?string,
  dispatch: Dispatch,
  location: RouteLocation,
  params: Object,
  groupName: ?string,
  authedUserPhone: ?string,
  isMobileWindow: boolean,
  isMobile: boolean,
  context: string,
  currentPhoneCountry: string,
  audienceFilterSeparation?: boolean,
  totalUnreadCount: number,
  canViewChat: boolean,
  setIsAiPanelOpen: (value: boolean) => void,
  showAIPanel: boolean,
  hasSenseAIPermission: boolean,
  handleSenseUniversityOnClick: () => void,
};

const dependencies = {
  required: ({dispatch}) => dispatch(getAgency()),
  // NOTE (kyle): ats entities are needed almost everywhere in our application.
  deferred: ({dispatch}) => dispatch(getAllEntities()),
};

const mapStateToProps = (state) => {
  return {
    agency: state.agency.agency,
    authed: state.accounts.authedUserId !== null,
    name: getFromAuthedUser(state, 'name'),
    groupName: getFromAuthedUser(state, ['securityGroup', 'name']),
    authedUserPhone: selectDisplayPhoneNumber(state),
    isMobile: isMobile(state.requestHeaders.headers['user-agent']),
    context: state.env.query.context,
    currentPhoneCountry: selectCurrentPhoneCountry(state),
    totalUnreadCount: selectTotalUnreadThreadCount(state),
    canViewChat: agentHasPhone(state) && hasPrivilege(state, USE_MESSAGING),
    showAIPanel: state.senseAIPanel.isAiPanelOpen,
    hasSenseAIPermission:
      hasPrivilege(state, USE_JD_GENERATOR) ||
      hasPrivilege(state, USE_PRESCREENING_GENERATOR) ||
      hasPrivilege(state, USE_RESUME_GENERATOR),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setIsAiPanelOpen(value) {
    dispatch(setAIPanelOpenEvent(value));
  },
  async handleSenseUniversityOnClick() {
    await dispatch(reduxApi.get('sense-university/login')).then((url) => {
      window.open(url, '_blank');
    });
  },
});

function AppShellContainer(props: TopNavigationProps): React.Node {
  const {
    store,
    dispatch,
    location,
    authed,
    children,
    name,
    agency: {
      activeEventProcessor,
      brandingSettings: {logo},
      slug,
    },
    groupName,
    authedUserPhone,
    isMobile,
    isMobileWindow,
    context,
    currentPhoneCountry,
    canViewChat,
    setIsAiPanelOpen,
    showAIPanel,
    hasSenseAIPermission,
    handleSenseUniversityOnClick,
  } = props;

  const {pathname} = location;

  const [stateExpand, setExpand] = React.useState(true);

  const serverRuntimeEnvironment = useSelector(
    (state) => state.env.serverRuntimeEnvironment,
  );
  const isFullscreenExperience = useSelector(selectFullscreen);
  const urlSuffix = useSelector(selectUrlSuffix);
  const userPhoneBroadcastLimit = useSelector(selectBroadcastRecipientLimit);

  const [showMenu, setShowMenu] = React.useState(false);

  const messageRouteMatches = Boolean(pathname.match(/^\/messages/));

  const totalUnreadCount: number = useSelector(selectTotalUnreadThreadCount);
  const showMessagingInboxDrawer = useMessagingInboxDrawer();
  const landingPageView = useDisplayConfig('landing_page_view');
  const trmEnabled = useTRMEnabled();

  React.useEffect(() => {
    if (showMenu) {
      const handleBodyClick = () => {
        setShowMenu(false);
      };

      document.addEventListener('click', handleBodyClick);

      return () => {
        document.removeEventListener('click', handleBodyClick);
      };
    }
  }, [showMenu]);

  React.useEffect(() => {
    setAuthPath(store, '/signin');
    const handlePostMessage = (event) => {
      if (event?.data?.type === 'CLOSE_AI_PANEL') {
        setIsAiPanelOpen(event.data.showAIPanel);
      }
    };
    window.addEventListener('message', handlePostMessage);
    return () => {
      setAuthPath(store, null);
      window.removeEventListener('message', handlePostMessage);
    };
  }, []);

  const fullyHidden =
    context === 'chrome-extension' ||
    context === 'pwa' ||
    context === 'trm-iframe' ||
    context === 'client-iframe' ||
    context === 'sense-ai' ||
    isMobile ||
    (isChatAppLocation(location) && isMobileWindow) ||
    isFullscreenExperience;

  return (
    <div className={css.container}>
      <div
        className={classify(appShellCss.appShellContainer, {
          [appShellCss.withoutHeader]: fullyHidden,
        })}
      >
        <div className={appShellCss.sideNavigationBar}>
          <AppShellSideNavContainer
            expanded={stateExpand}
            setExpand={setExpand}
            name={name}
            handleSenseUniversityOnClick={handleSenseUniversityOnClick}
          />
        </div>
        {!fullyHidden && (
          <div className={appShellCss.topNavigationBar}>
            <TopNavBar
              store={store}
              pinned={false}
              authed={authed}
              name={name}
              logo={logo}
              showMenu={showMenu}
              groupName={groupName}
              authedUserPhone={authedUserPhone}
              currentPhoneCountry={currentPhoneCountry}
              totalUnreadCount={totalUnreadCount}
              canViewChat={canViewChat}
              messageRouteMatches={messageRouteMatches}
              showMessagingInboxDrawer={showMessagingInboxDrawer}
              setIsAiPanelOpen={setIsAiPanelOpen}
              showAIPanel={showAIPanel}
              hasSenseAIPermission={hasSenseAIPermission}
              urlSuffix={urlSuffix}
              trmEnabled={trmEnabled}
              landingPageView={landingPageView}
              userLimit={userPhoneBroadcastLimit}
              handleSenseUniversityOnClick={handleSenseUniversityOnClick}
            />
          </div>
        )}
        <div
          className={classify(appShellCss.contentArea, {
            [appShellCss.contentAreaWithoutHeader]: fullyHidden,
          })}
        >
          {authed &&
            !activeEventProcessor &&
            slug !== 'demo' &&
            process.env.NODE_ENV ===
              'production' /* aka "is production or staging" */ && (
              /* $FlowIssue - children prop as JSX not recognized by FlowType */
              <Notification>
                <strong>Your account is in setup mode,</strong>
                <span>
                  so no automated messages will be sent. To enable your account
                  and go live, please contact Sense.
                </span>
              </Notification>
            )}
          <BadBrowserNotification />
          <div className={appShellCss.child}>
            <RouterErrorBoundary>{children}</RouterErrorBoundary>
          </div>
        </div>
      </div>
      <Loader />

      {/*
          Open WebSocket even if TopNavBar is fully hidden by the chrome-extension.
          TODO (gab): Eventually move socket into main app component (must first decouple
          surveys from main app)
        */}
      <MessagesSocket />

      {!fullyHidden && (
        <div className={css.walkmeContainer}>
          <WalkmeButton className={css.walkme} />
        </div>
      )}
    </div>
  );
}

export default (flow(
  withWindowWidth,
  // $FlowFixMe[prop-missing] this component works so editing it would just be a risk
  fluxify({}, {pure: true}),
  connect(mapStateToProps, mapDispatchToProps),
  provideHooks(dependencies),
)(AppShellContainer): React.ComponentType<OuterProps>);

type TopNavBarProps = {
  // $FlowFixMe[value-as-type]
  store: IndexStore,
  authed: boolean,
  name: ?string,
  pinned?: boolean, // at some point all pages will be pinned
  logo?: string,
  showMenu?: boolean,
  groupName: ?string,
  authedUserPhone: ?string,
  currentPhoneCountry: string,
  totalUnreadCount: number,
  canViewChat: boolean,
  messageRouteMatches: boolean,
  showMessagingInboxDrawer: boolean,
  setIsAiPanelOpen: (value: boolean) => void,
  showAIPanel: boolean,
  landingPageView: boolean,
  trmEnabled: boolean,
  hasSenseAIPermission: boolean,
  userLimit: number,
  handleSenseUniversityOnClick: () => void,
  urlSuffix: string,
};

type IframePayload = {
  phoneNumbers?: string[],
  type?: null,
};

type MessagingDetails = {
  type?: 'MULTIPLE_RECIPIENT' | 'CLICKED_NUMBER',
  payload?: IframePayload,
};

type State = {
  showMessagingFrame: boolean,
  messagingDetails?: MessagingDetails,
  showUserLimitModal: boolean,
};
class TopNavBar extends React.PureComponent<TopNavBarProps, State> {
  senseMessagingRef: {current: null | HTMLIFrameElement};

  constructor(props) {
    super(props);
    this.state = {
      showMessagingFrame: false,
      showUserLimitModal: false,
    };
    this.senseMessagingRef = React.createRef();
  }

  handleOpenMessagingPanel = (data) => {
    const {detail} = data;
    if (
      detail?.type === 'MULTIPLE_RECIPIENT' &&
      detail?.payload?.phoneNumbers?.length > this.props.userLimit
    ) {
      this.setState({showUserLimitModal: true});
    } else {
      this.setState({showMessagingFrame: true, messagingDetails: detail});
    }
  };

  componentDidMount() {
    window.addEventListener(
      'openMessagingPanel',
      this.handleOpenMessagingPanel,
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      'openMessagingPanel',
      this.handleOpenMessagingPanel,
    );
  }

  handleIframeLoad = () => {
    if (this.senseMessagingRef.current !== null) {
      this.senseMessagingRef.current.contentWindow.postMessage(
        this.state.messagingDetails,
        window.origin,
      );
    }
  };

  render(): React.Node {
    const {
      store,
      pinned,
      authed,
      name,
      showMenu,
      logo,
      groupName,
      authedUserPhone,
      totalUnreadCount,
      canViewChat,
      messageRouteMatches,
      showMessagingInboxDrawer,
      setIsAiPanelOpen,
      showAIPanel,
      landingPageView,
      trmEnabled,
      hasSenseAIPermission,
      handleSenseUniversityOnClick,
      urlSuffix,
    } = this.props;

    const {showMessagingFrame} = this.state;

    let senseAiPanelUrl = window.location.origin;
    if (urlSuffix) {
      senseAiPanelUrl += `/sense-ai-panel${urlSuffix}`;
    } else {
      senseAiPanelUrl += `/sense-ai-panel`;
    }

    senseAiPanelUrl = addQueryParamsToUrl(senseAiPanelUrl, {
      context: 'sense-ai',
    });

    const showMessagingDrawerIcon =
      canViewChat &&
      showMessagingInboxDrawer &&
      !messageRouteMatches &&
      (landingPageView || trmEnabled);

    return (
      <div className={css.navPlaceholder}>
        <nav className={pinned ? css.navFixed : css.nav}>
          {authed && (
            <>
              <div className={css.authed}>
                <div className={appShellCss.chatButtonContainer}>
                  {hasSenseAIPermission && (
                    <div className={appShellCss.senseAIPanelBtn}>
                      <Button
                        iconLeftName="sparkles"
                        onClick={() => {
                          setIsAiPanelOpen(true);
                        }}
                        size="small"
                        type={'tertiary'}
                        iconLeftType="solid"
                      >
                        AI Copilot
                      </Button>
                      {showAIPanel && (
                        <Panel
                          anchor="right"
                          initialFocus={2}
                          size="large"
                          tapOutsideToClose
                          onClose={() => {
                            setIsAiPanelOpen(false);
                          }}
                          isOpen={showAIPanel}
                        >
                          <iframe
                            width={'100%'}
                            src={senseAiPanelUrl}
                            title="AI Copilot"
                            style={{border: 0, height: '100vh'}}
                          />
                        </Panel>
                      )}
                    </div>
                  )}

                  {showMessagingDrawerIcon && (
                    <div>
                      <Button
                        iconLeftName="inbox"
                        onClick={() =>
                          this.setState((prevState) => ({
                            showMessagingFrame: !prevState.showMessagingFrame,
                          }))
                        }
                        size="small"
                        type={'tertiary'}
                        iconLeftType="solid"
                      >
                        Inbox
                      </Button>

                      {totalUnreadCount > 0 ? (
                        <Badge
                          fill={'blue'}
                          text={`${totalUnreadCount}`}
                          classNames={{wrapper: css.messageCountBadge}}
                        />
                      ) : null}
                      <Panel
                        anchor="right"
                        initialFocus={2}
                        onClose={() =>
                          this.setState({
                            showMessagingFrame: false,
                            messagingDetails: null,
                          })
                        }
                        size="medium"
                        tapOutsideToClose
                        isOpen={showMessagingFrame}
                      >
                        <PanelBody className={css.panelPaddingNull}>
                          <iframe
                            width={'100%'}
                            src={`${window.location.origin}/messages?context=trm-iframe`}
                            title="messaging"
                            style={{border: 0, height: '100vh'}}
                            ref={this.senseMessagingRef}
                            onLoad={this.handleIframeLoad}
                          />
                        </PanelBody>
                      </Panel>
                    </div>
                  )}
                  <Modal
                    isOpen={this.state.showUserLimitModal}
                    onClose={() => this.setState({showUserLimitModal: false})}
                  >
                    <ModalBody>
                      <BulkMessageWrarning
                        userLimit={this.props.userLimit}
                        onClose={() =>
                          this.setState({showUserLimitModal: false})
                        }
                      />
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </>
          )}
        </nav>
      </div>
    );
  }
}
