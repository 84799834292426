// @noflow

import type {PopulatedThreadList} from 'src/types/thread-lists';
import type {PopulatedThread} from 'src/types/messages';
import type {EditorDispatch} from 'src/reducers/thread-list-editor';

import * as React from 'react';

import {getPhoneNumber} from 'src/utils/audience-members';
import {getFullName} from 'src/utils';

import useAgencyConfig from 'src/hooks/useAgencyConfig';

import FormattedPhone from 'src/components/lib/formatted-phone.jsx';
import ContactSearch from 'src/components/contacts/search.jsx';
import ContactAdder from './contact-adder.jsx';
import CloseIcon from 'src/images/close-icon.svg';

import css from './editor.css';


type Props = {
  threads: PopulatedThread[],
  members: {[string]: {contact?: Contact, audienceMembers: AudienceMembers[]}},
  dispatch: EditorDispatch,
  onCancel: () => mixed,
  onSubmit: () => mixed,
};

export default function Editor({
  dispatch,
  threads,
  members,
  adding,
  onCancel,
  onSubmit,
}: Props) {
  const typeaheadInputRef = React.useRef(null);

  React.useEffect(() => {
    typeaheadInputRef.current?.focus();
  }, []);

  const [membersArray, audienceMembers, contacts] = React.useMemo(() => {
    const membersArray = Object.values(members);
    return [
      membersArray,
      membersArray.flatMap(({audienceMembers}) => audienceMembers),
      membersArray.map(({contact}) => contact).filter(Boolean),
    ];
  }, [members]);

  const onSelect = React.useCallback(
    (option) => {
      const externalPhone =
        option.contact?.phone ||
        option.audienceMember?.phone ||
        (option.audienceMember && getPhoneNumber(option.audienceMember));
      dispatch({
        type: 'add',
        payload: {
          contact: option.contact,
          audienceMembers: option.audienceMember ? [option.audienceMember] : [],
          externalPhone,
        },
      });
    },
    [dispatch],
  );

  const onCreate = React.useCallback(
    (name) => {
      const names = name.split(' ');
      dispatch({
        type: 'new',
        payload: {
          firstName: names.slice(0, -1).join(' '),
          lastName: names[names.length - 1],
        },
      });
    },
    [dispatch],
  );

  const onRemove = React.useCallback(
    (externalPhone) => {
      dispatch({type: 'remove', payload: externalPhone});
    },
    [dispatch],
  );

  const contactListLimit = useAgencyConfig('maxContactsCsvUpload');

  return (
    <div className={css.root}>
      {membersArray.length + threads.length < contactListLimit ? (
        adding ? (
          <ContactAdder
            dispatch={dispatch}
            contact={adding.contact}
            errors={adding.errors}
          />
        ) : (
          <ContactSearch
            onSelect={onSelect}
            onCreate={onCreate}
            disabledContacts={contacts}
            disabledAudienceMembers={audienceMembers}
            showAudienceMembers={true}
            childRef={typeaheadInputRef}
            clearOnSelect={true}
          />
        )
      ) : (
        <div>
          Lists are limited to {contactListLimit} contacts. Please remove some
          if you would like to add more.
        </div>
      )}

      {membersArray.map(({externalPhone, contact, audienceMembers}) => {
        const audienceMember = audienceMembers?.[0];
        return (
          <div className={css.contact} key={externalPhone}>
            <span className={css.contactName}>
              {getFullName(contact || audienceMember)}
            </span>
            <span className={css.contactPhone}>
              <FormattedPhone phone={externalPhone} />
            </span>
            <button
              className={css.remove}
              onClick={() => onRemove(externalPhone)}
            >
              <CloseIcon />
            </button>
          </div>
        );
      })}

      <div className={css.actions}>
        <button className={css.cancel} onClick={onCancel}>
          Cancel
        </button>
        <button
          className={css.submit}
          onClick={onSubmit}
          disabled={Boolean(adding)}
        >
          Save
        </button>
      </div>
    </div>
  );
}
