// @flow strict

import type {SuggestionType} from 'src/types/contacts';
// $FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import type {SetOfDiscoverCandidateDetails} from 'src/types/draft-messages';
import type {
  BroadcastSuppressionPhoneDetails,
  TimeFrame,
} from 'src/components/messaging/broadcast-suppression/types';
import {createSelector} from 'reselect';
import {CARD_TYPES} from 'src/components/messaging/broadcast-suppression/constants';


export const selectRecipients = (
  state: State,
): Array<SuggestionType | string> => state.draftMessages.recipients;

export const selectRecipientsCount = (state: State): number =>
  state.draftMessages.recipients.length;

export const selectFilesForUpload = (state: State, draftKey: string): File[] =>
  state.draftMessages.drafts[draftKey]?.files || [];

export const selectFileUploadErrors = (
  state: State,
  draftKey: string,
): File[] => state.draftMessages.drafts[draftKey]?.fileErrors || [];

export const getBroadcastSuppressionTimeFrame = (state: State): TimeFrame => {
  return state.draftMessages.timeFrame;
};

const isBroadcast = (state) => {
  const recipients = state.draftMessages.recipients;
  const firstRecipient = recipients[0];
  const isThreadList =
    typeof firstRecipient === 'object' &&
    firstRecipient.threadListSuggestion &&
    true;

  return isThreadList || recipients.length > 1;
};
export const getAllRestoredPhoneNumbers = (state: State): string[] => {
  return state.draftMessages.restoredPhoneNumbers;
};

export const getAllRestoredLandlineNumbers = (state: State): string[] => {
  return state.draftMessages.restoredLandlineNumbers;
};

export const getRecentlyMessage = (
  state: State,
): BroadcastSuppressionPhoneDetails[] => {
  // $FlowIssue Object.values
  const phoneTypes: BroadcastSuppressionPhoneDetails[] = Object.values(
    state.draftMessages.phoneTypes,
  );
  const isBroadcastMsg = isBroadcast(state);
  const agent = state.accounts.authedUserId
    ? state.accounts.data[state.accounts.authedUserId]
    : null;
  const broadcastConfig =
    agent?.securityRole.configurations.broadcastSuppression.type || '';
  const restoredPhoneNumbers = getAllRestoredPhoneNumbers(state);
  const result = phoneTypes.filter(
    (phone) =>
      phone.broadcastSuppression === true &&
      isBroadcastMsg &&
      !restoredPhoneNumbers.includes(phone.phoneNumber),
  );

  return broadcastConfig === 'off' ? [] : result;
};

export const getAllRecentlyMessaged = (
  state: State,
): BroadcastSuppressionPhoneDetails[] => {
  // $FlowIssue Object.values
  const phoneTypes: BroadcastSuppressionPhoneDetails[] = Object.values(
    state.draftMessages.phoneTypes,
  );
  const agent = state.accounts.authedUserId
    ? state.accounts.data[state.accounts.authedUserId]
    : null;
  const broadcastConfig =
    agent?.securityRole.configurations.broadcastSuppression.type || '';
  const result = phoneTypes.filter(
    (phone) => phone.broadcastSuppression === true,
  );

  return broadcastConfig === 'off' ? [] : result;
};

export const getLandlineNumbers = (
  state: State,
): BroadcastSuppressionPhoneDetails[] => {
  // $FlowIssue Object.values
  const phoneTypes: BroadcastSuppressionPhoneDetails[] = Object.values(
    state.draftMessages.phoneTypes,
  );
  const restoredPhoneNumbers = getAllRestoredPhoneNumbers(state);
  const recentlyMessagedPhoneNumbers = getRecentlyMessage(state).map(
    (phone) => phone.phoneNumber,
  );
  const result = phoneTypes.filter(
    (phone) =>
      !restoredPhoneNumbers.includes(phone.phoneNumber) &&
      phone.type === CARD_TYPES.LANDLINE &&
      !recentlyMessagedPhoneNumbers.includes(phone.phoneNumber),
  );
  return result;
};

export const getInvalidNumbers = (
  state: State,
): BroadcastSuppressionPhoneDetails[] => {
  // $FlowIssue Object.values
  const phoneTypes: BroadcastSuppressionPhoneDetails[] = Object.values(
    state.draftMessages.phoneTypes,
  );
  const recentlyMessagedPhoneNumbers = getRecentlyMessage(state).map(
    (phone) => phone.phoneNumber,
  );
  const result = phoneTypes.filter(
    (phone) =>
      phone.type === CARD_TYPES.INVALID &&
      !recentlyMessagedPhoneNumbers.includes(phone.phoneNumber),
  );
  return result;
};
export const getBroadcastSuppressionCount: (state: State) => number =
  createSelector(
    selectRecipientsCount,
    getRecentlyMessage,
    getLandlineNumbers,
    getInvalidNumbers,
    (recipientsCount, recentlyMessaged, landlineNumbers, invalidNumbers) => {
      return recipientsCount > 0
        ? recentlyMessaged.length +
            landlineNumbers.length +
            invalidNumbers.length
        : 0;
    },
  );

export const getTypesOfBroadcastWarnings: (state: State) => string[] =
  createSelector(
    getRecentlyMessage,
    getLandlineNumbers,
    getInvalidNumbers,
    (recentlyMessaged, landlineNumbers, invalidNumbers): string[] => {
      const allWarnings = [];
      if (recentlyMessaged.length > 0) {
        allWarnings.push(CARD_TYPES.RECENTLY_MESSAGED);
      }
      if (landlineNumbers.length > 0) {
        allWarnings.push(CARD_TYPES.LANDLINE);
      }
      if (invalidNumbers.length > 0) {
        allWarnings.push(CARD_TYPES.INVALID);
      }
      return allWarnings;
    },
  );

export const getAllSuppressedPhoneNumbers: (state: State) => string[] =
  createSelector(
    getRecentlyMessage,
    getLandlineNumbers,
    getInvalidNumbers,
    (recentlyMessaged, landlineNumbers, invalidNumbers) => {
      const recentlyMessagedPhones = recentlyMessaged.map(
        (value) => value.phoneNumber,
      );
      const landlinePhoneNumbers = landlineNumbers.map(
        (value) => value.phoneNumber,
      );
      const invalidPhoneNumbers = invalidNumbers.map(
        (value) => value.phoneNumber,
      );
      return [
        ...recentlyMessagedPhones,
        ...landlinePhoneNumbers,
        ...invalidPhoneNumbers,
      ];
    },
  );

export const selectIsBroadcastSuppressionConfigOff = (
  state: State,
): boolean => {
  const agent = state.accounts.authedUserId
    ? state.accounts.data[state.accounts.authedUserId]
    : null;
  const broadcastConfig =
    agent?.securityRole.configurations.broadcastSuppression.type || '';

  return broadcastConfig === 'off';
};

export const selectMessageOrigin = (state: State): string => {
  return state.draftMessages.origin;
};

export const selectDiscoverCandidateDetails = (
  state: State,
): SetOfDiscoverCandidateDetails => {
  return state.draftMessages.candidateDetails;
};

export const selectIsDiscoverIframeContext = (state: State): boolean => {
  return state.draftMessages.isDiscoverIframeContext;
};
