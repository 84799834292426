// @noflow
import keyBy from 'lodash/keyBy';
import type {EventTemplate} from 'src/api-parsers/events';

import Store from './base';


type Error = {
  errors: string[],
  status: number,
};

export default class EventTemplateStore extends Store {
  state: {
    editing: boolean,
    templates: {[key: string]: EventTemplate},
    saving: boolean,
    error?: Error,
  };

  constructor() {
    super('event templates');

    this.state = {
      editing: false,
      templates: {},
      events: {},
      saving: false,
    };
  }

  get(templateId: string): EventTemplate {
    return this.state.templates[templateId];
  }

  all(): EventTemplate[] {
    return Object.keys(this.state.templates)
      .filter(id => this.state.templates[id] !== null)
      .map(id => this.state.templates[id]);
  }

  // NOTE (kyle): this method assumes we're getting the full list
  receiveAll(templates: EventTemplate[]) {
    this.setState({
      templates: keyBy(templates, template => template.id),
    });
  }

  receive(template: EventTemplate) {
    this.updateState({
      templates: {
        [template.id]: {
          $set: template,
        },
      },
    });
  }

  remove(templateId: string) {
    this.updateState({
      templates: {
        [templateId]: {
          $set: null,
        },
      },
    });
  }

  setEventTemplate(template: EventTemplate) {
    this.receive(template);
  }

  isEditing(editing: boolean) {
    if (!arguments.length) {
      return this.state.editing;
    } else {
      this.setState({editing});
    }
  }

  setError(error: Error) {
    this.updateState({
      error: {
        $set: error,
      },
    });
  }

  getError(): ?Error {
    return this.state.error;
  }

  processedError() {
    this.updateState({
      error: {
        $set: undefined,
      },
    });
  }
}
