// @flow strict
import * as React from 'react';
import type {MenuOption} from '@spaced-out/ui-design-system/lib/components/Menu/Menu';
import {getCountryCodes} from 'src/utils/phone';
import {FormLabelSmall} from '@spaced-out/ui-design-system/lib/components/index';
import {
  colorBackgroundTertiary,
  colorButtonFillPrimaryEnabled,
} from '@spaced-out/ui-design-system/lib/styles/variables/_color.js';


export const REFERRAL_URL_SEGMENT = '/r/';
export const REFERRAL_STAGES = ['Referred', 'Contacted', 'Hired', 'Paid'];

export const getReferralUrlSegment = (referralLinkId: string): string =>
  `${REFERRAL_URL_SEGMENT}${referralLinkId}`;

export const QUESTIONNAIRE_TYPES = {
  job: 'job_id',
  referralEmail: 'candidate_email',
  referralPhone: 'candidate_contact',
  referralName: 'candidate_name',
  referrerName: 'referrer_name',
  reachOutToReferral: 'communication_mode',
  referrerEmail: 'referred_by',
  candidateResume: 'candidate_resume',
};

export const noOp = () => {};
export const DEFAULT_BUTTON_COLOR = colorButtonFillPrimaryEnabled;
export const DEFAULT_TEXT_COLOR = colorBackgroundTertiary;
export const COUNTRY_CODES: Array<MenuOption> = getCountryCodes();

export const showLabelWithAsterisk = (
  label: string,
  required: boolean,
): React.Node => {
  return (
    <div style={{display: 'flex'}}>
      <FormLabelSmall color="secondary">{label ?? ''}</FormLabelSmall>
      &nbsp;
      {required && <FormLabelSmall color="danger">{'*'}</FormLabelSmall>}
    </div>
  );
};

export const referrerPortalLink = (): string => {
  const hostName = window.location.origin;
  const splitDomain = hostName.split('.');
  splitDomain[0] = 'referrals';
  return `https://${splitDomain.join('.')}`;
};
