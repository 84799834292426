// @flow

import type {Query} from 'src/types/router';
import type {InsightCounts} from 'src/types/report';
import type {ThunkAction} from 'src/reducers';

import {thunkify as flow} from 'src/utils/thunks';
import {resolveAnalyticsQuery} from 'src/utils/analytics-query';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api';
import {ANALYTICS_API_V3} from 'src/utils/analytics-api-migration';


const {stringify} = JSON;

export const RECEIVE_AGENCY = 'insightCounts/receiveAgency';
export const RECEIVE_EVENT = 'insightCounts/receiveEvent';

export type AgencyInsightCountsAction = {
  type: 'insightCounts/receiveAgency',
  payload: InsightCounts,
};
export type EventInsightCountsAction = {
  type: 'insightCounts/receiveEvent',
  payload: {
    counts: InsightCounts,
    eventId: string,
  },
};
export type InsightCountsAction =
  | AgencyInsightCountsAction
  | EventInsightCountsAction;

export const receiveAgency = (
  counts: InsightCounts,
): AgencyInsightCountsAction => ({
  type: RECEIVE_AGENCY,
  payload: counts,
});
export const receiveEvent = (
  counts: InsightCounts,
  eventId: string,
): EventInsightCountsAction => ({
  type: RECEIVE_EVENT,
  payload: {
    counts,
    eventId,
  },
});

const querying =
  () =>
  (func) =>
  (...args) => {
    const lastIndex = args.length - 1;
    args[lastIndex] = resolveAnalyticsQuery(args[lastIndex], [
      'client',
      'recipients',
      'nps_only',
    ]);
    return func(...args);
  };

const insightCountsQuerying = querying();
export const getAgencyInsightCounts: (query: Query) => ThunkAction<mixed> =
  flow(
    insightCountsQuerying,
    key(() => `insightCounts/agency`),
    cached((json) => receiveAgency(json), {
      hash: insightCountsQuerying(
        (query) => `insightCounts/agency:${stringify(query)}`,
      ),
    }),
  )((query: Query) =>
    reduxApi.post(
      '/responses/insight/counts',
      query,
      {},
      {
        apiPath: ANALYTICS_API_V3,
      },
    ),
  );

export const getEventInsightCounts: (
  workflowId: string,
  eventId: string,
  query: Query,
) => ThunkAction<mixed> = flow(
  insightCountsQuerying,
  key(
    (workflowId, eventId) =>
      `insightCounts/workflows:${workflowId}/event:${eventId}`,
  ),
  cached((json, workflowId, eventId) => receiveEvent(json, eventId), {
    hash: insightCountsQuerying(
      (workflowId, eventId, query) =>
        `insightCounts/workflow:${workflowId}/event:${eventId}:${stringify(
          query,
        )}`,
    ),
  }),
  fetching(),
)((workflowId: string, eventId: string, query: Query) =>
  reduxApi.post(
    `/workflows/${workflowId}/events/${eventId}/responses/insight/counts`,
    query,
    {},
    {
      apiPath: ANALYTICS_API_V3,
    },
  ),
);
