// @flow

import type {SenseAction} from 'src/action-creators/types';

import {
  SET_INBOX,
  HIDE_SIDE_BAR,
  SET_SELECTED_THREAD,
  SET_INBOX_TYPE,
} from 'src/action-creators/chat/multi-number-inbox';


export type State = {
  selectedPhoneNumberSetId?: string,
  hideSideBar: boolean,
  selectedThreads: {[phoneNumberSetId: number]: string},
  inboxType: string,
};

const initialState = {
  hideSideBar: true,
  selectedThreads: {},
  inboxType: '',
};

export default function reduce(
  state: State = initialState,
  action: SenseAction,
): State {
  switch (action.type) {
    case SET_INBOX: {
      return {
        ...state,
        selectedPhoneNumberSetId: action.payload,
      };
    }
    case HIDE_SIDE_BAR: {
      return {
        ...state,
        hideSideBar: action.payload,
      };
    }
    case SET_SELECTED_THREAD: {
      return {
        ...state,
        selectedThreads: {...state.selectedThreads, ...action.payload},
      };
    }
    case SET_INBOX_TYPE: {
      return {
        ...state,
        inboxType: action.payload,
      };
    }
  }

  return state;
}
