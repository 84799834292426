// @flow strict
import type {
  GenericError,
  Programs,
  ProgramJobCount,
  ProgramJobsPayload,
  ReferralSetup,
  ReferralBonusAndPayoutSettings,
  ReferralDetails,
  SaveReferralStepApiRequest,
  ReferralBrand,
  ReferralQuestionnaire,
  ReferralProgramsApiResponse,
  ReferralLink,
} from 'src/types/referral-v2';
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction, GetState} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api.js';
// $FlowFixMe[untyped-import]
import {key, fetching} from 'src/utils/redux';
import {
  INITIAL_SETUP_STATE,
  INITIAL_REFERRAL_SETTINGS,
} from 'src/utils/referral-v2.js';
import {
  getReferralJobs,
  DEFAULT_ERROR,
} from 'src/action-creators/referral-v2/referral-jobs.js';
// $FlowFixMe[nonstrict-import]
import {showApiError} from 'src/action-creators/modal';
// @flow strict
import {toastMessage} from 'src/components/referral-v2/tracking/referral-confirm-modal.jsx';
import {toastApi} from '@spaced-out/ui-design-system/lib/components/index';


const {stringify} = JSON;

export const PROGRAM_DETAILS_FORM_DATA = 'referral/program-details-form-data';
export const REFERRAL_PROGRAM_JOBS_COUNT_LOADING =
  'referral/program-jobs-count-loading';
export const ACTIVE_PROGRAMS = 'referral/active-programs';
export const INACTIVE_PROGRAMS = 'referral/inactive-programs';
export const PROGRAMS = 'referral/referral-programs';
export const PROGRAMS_ERROR = 'referral/programs-error';
export const PROGRAMS_LOADING = 'referral/programs-loading';

export const PROGRAM_JOBS_COUNT = 'referral/program-jobs-count';
export const PROGRAM_JOBS_COUNT_LOADING = 'referral/program-jobs-count-loading';

export const PROGRAM_JOBS_COUNT_ERROR = 'referral/program-jobs-count-error';

export const PROGRAM_AGENCY_LINKS = 'referral/agency-links';
export const PROGRAM_AGENCY_LINKS_ERROR = 'referral/agency-links-error';

export const BRANDS_LOADING = 'referral/receiveBrandsLoading';
export const BRANDS = 'referral/receiveBrands';
export const BRANDS_ERROR = 'referral/receiveBrandsError';

export const QUESTIONNAIRE_LOADING = 'referral/receiveQuestionnaireLoading';
export const QUESTIONNAIRE = 'referral/receiveQuestionnaire';
export const QUESTIONNAIRE_ERROR = 'referral/receiveQuestionnaireError';

export const PROGRAM_DETAILS_LOADING = 'referral/receiveReferralDetailsLoading';
export const PROGRAM_DETAILS = 'referral/receiveReferralDetails';
export const PROGRAM_DETAILS_ERROR = 'referral/receiveReferralDetailsError';

export const SAVE_PROGRAM_DETAILS_LOADING =
  'referral/saveReferralDetailsLoading';
export const SAVE_PROGRAM_DETAILS = 'referral/saveReferralDetails';
export const SAVE_PROGRAM_DETAILS_ERROR = 'referral/saveReferralDetailsError';

type ActivelProgramsAction = {
  type: typeof ACTIVE_PROGRAMS,
  payload?: ?Programs,
};

type InactivelProgramsAction = {
  type: typeof INACTIVE_PROGRAMS,
  payload?: ?Programs,
};

type ProgramsLoadingAction = {
  type: typeof PROGRAMS_LOADING,
  payload?: ?{},
};

type ProgramErrorAction = {
  type: typeof PROGRAMS_ERROR,
  payload?: ?GenericError,
};

type ReceiveProgramDetailsFormData = {
  type: typeof PROGRAM_DETAILS_FORM_DATA,
  payload: {
    setup: ReferralSetup,
    bonus_and_payout_settings: ReferralBonusAndPayoutSettings,
  },
};

type ProgramAgencyLinksAction = {
  type: typeof PROGRAM_AGENCY_LINKS,
  payload: ReferralLink,
};

type ProgramAgencyLinksErrorAction = {
  type: typeof PROGRAM_AGENCY_LINKS_ERROR,
  payload: ?GenericError,
};

type BrandsLoadingAction = {
  type: typeof BRANDS_LOADING,
};

type BrandsAction = {
  type: typeof BRANDS,
  payload: ?(ReferralBrand[]),
};

type BrandsErrorAction = {
  type: typeof BRANDS_ERROR,
  payload: ?GenericError,
};

type QuestionnaireLoadingAction = {
  type: typeof QUESTIONNAIRE_LOADING,
};

type QuestionnaireAction = {
  type: typeof QUESTIONNAIRE,
  payload: ?(ReferralQuestionnaire[]),
};

type QuestionnaireErrorAction = {
  type: typeof QUESTIONNAIRE_ERROR,
  payload: ?GenericError,
};

type ProgramsAction = {
  type: typeof PROGRAMS,
  payload: ReferralProgramsApiResponse,
};

type ProgramsErrorAction = {
  type: typeof PROGRAMS_ERROR,
  payload: ?GenericError,
};

type ProgramDetailsLoadingAction = {
  type: typeof PROGRAM_DETAILS_LOADING,
};

type ProgramDetailsAction = {
  type: typeof PROGRAM_DETAILS,
  payload: ?ReferralDetails,
  isReferralsJobListEnabled: boolean,
};

type ProgramDetailsErrorAction = {
  type: typeof PROGRAM_DETAILS_ERROR,
  payload: ?GenericError,
};

type SaveProgramDetailsLoadingAction = {
  type: typeof SAVE_PROGRAM_DETAILS_LOADING,
};

type SaveProgramDetailsAction = {
  type: typeof SAVE_PROGRAM_DETAILS,
  payload: ?string,
};

type SaveProgramDetailsErrorAction = {
  type: typeof SAVE_PROGRAM_DETAILS_ERROR,
  payload: ?GenericError,
};

export const getProgramError = (
  payload: ?GenericError,
): ProgramErrorAction => ({
  type: PROGRAMS_ERROR,
  payload,
});

export const getProgramJobsCountError = (
  payload: ?GenericError,
): ProgramErrorAction => ({
  type: PROGRAM_JOBS_COUNT_ERROR,
  payload,
});

export const getProgramLinks = (
  payload: ReferralLink,
): ProgramAgencyLinksAction => ({
  type: PROGRAM_AGENCY_LINKS,
  payload,
});

export const getProgramLinksError = (
  payload: ?GenericError,
): ProgramAgencyLinksErrorAction => ({
  type: PROGRAM_AGENCY_LINKS_ERROR,
  payload,
});

export const receiveBrandsLoading = (): BrandsLoadingAction => ({
  type: BRANDS_LOADING,
});

export const receiveBrands = (response: ?(ReferralBrand[])): BrandsAction => ({
  type: BRANDS,
  payload: response,
});

export const receiveBrandsError = (
  payload: ?GenericError,
): BrandsErrorAction => ({
  type: BRANDS_ERROR,
  payload,
});

export const receiveQuestionnaireLoading = (): QuestionnaireLoadingAction => ({
  type: QUESTIONNAIRE_LOADING,
});

export const receiveQuestionnaire = (
  response: ?(ReferralQuestionnaire[]),
): QuestionnaireAction => ({
  type: QUESTIONNAIRE,
  payload: response,
});

export const receiveQuestionnaireError = (
  payload: ?GenericError,
): QuestionnaireErrorAction => ({
  type: QUESTIONNAIRE_ERROR,
  payload,
});

export const getPrograms = (
  payload: ReferralProgramsApiResponse,
): ProgramsAction => ({
  type: PROGRAMS,
  payload,
});

export const getProgramsLoading = (): ProgramsLoadingAction => ({
  type: PROGRAMS_LOADING,
});

export const getProgramsError = (
  payload: ?GenericError,
): ProgramsErrorAction => ({
  type: PROGRAMS_ERROR,
  payload,
});

export const receiveReferralDetailsLoading =
  (): ProgramDetailsLoadingAction => ({
    type: PROGRAM_DETAILS_LOADING,
  });

export const receiveReferralDetails = (
  response: ?ReferralDetails,
  isReferralsJobListEnabled: boolean,
): ProgramDetailsAction => ({
  type: PROGRAM_DETAILS,
  payload: response,
  isReferralsJobListEnabled,
});

export const receiveReferralDetailsError = (
  payload: ?GenericError,
): ProgramDetailsErrorAction => ({
  type: PROGRAM_DETAILS_ERROR,
  payload,
});

export const saveReferralDetailsLoading =
  (): SaveProgramDetailsLoadingAction => ({
    type: SAVE_PROGRAM_DETAILS_LOADING,
  });

export const saveReferralDetails = (
  response: ?string,
): SaveProgramDetailsAction => ({
  type: SAVE_PROGRAM_DETAILS,
  payload: response,
});

export const saveReferralDetailsError = (
  payload: ?GenericError,
): SaveProgramDetailsErrorAction => ({
  type: SAVE_PROGRAM_DETAILS_ERROR,
  payload,
});

export const updateProgramDetailsFormData = (payload: {
  setup: ReferralSetup,
  bonus_and_payout_settings: ReferralBonusAndPayoutSettings,
}): ReceiveProgramDetailsFormData => ({
  type: PROGRAM_DETAILS_FORM_DATA,
  payload,
});

export type ReferralProgramActions =
  | ActivelProgramsAction
  | ProgramsLoadingAction
  | ProgramErrorAction
  | InactivelProgramsAction
  | ProgramAgencyLinksAction
  | ProgramAgencyLinksErrorAction
  | BrandsLoadingAction
  | BrandsAction
  | BrandsErrorAction
  | QuestionnaireLoadingAction
  | QuestionnaireAction
  | QuestionnaireErrorAction
  | ProgramsAction
  | ProgramsLoadingAction
  | ProgramsErrorAction
  | ProgramDetailsLoadingAction
  | ProgramDetailsAction
  | ProgramDetailsErrorAction
  | SaveProgramDetailsLoadingAction
  | SaveProgramDetailsAction
  | SaveProgramDetailsErrorAction
  | ReceiveProgramDetailsFormData;

export const getProgramsData: (
  programType: ?'active' | 'inactive',
) => ThunkAction<void> = flow()(
  (programType: ?'active' | 'inactive') => (dispatch: Dispatch) => {
    dispatch({type: PROGRAMS_LOADING});
    const url = `referral/programs${programType ? `?type=${programType}` : ``}`;
    return dispatch(reduxApi.get(url)).then(
      (response: Programs) => {
        if (programType === 'active') {
          dispatch({type: ACTIVE_PROGRAMS, payload: response});
        } else {
          dispatch({type: INACTIVE_PROGRAMS, payload: response});
        }
      },
      (error) => {
        dispatch(getProgramError(error?.response?.errors || DEFAULT_ERROR));
      },
    );
  },
);

export const getProgramJobsCount: (
  programType: ?'active' | 'inactive',
) => ThunkAction<void> = flow(
  key((programType) => `referral/programs/job-count?type=${programType}`),
  fetching(),
)((programType: ?'active' | 'inactive') => (dispatch: Dispatch) => {
  dispatch({type: PROGRAM_JOBS_COUNT_LOADING});
  const url = `referral/programs/job-count${
    programType ? `?type=${programType}` : ``
  }`;
  return dispatch(reduxApi.get(url)).then(
    (response: ProgramJobCount) => {
      dispatch({type: PROGRAM_JOBS_COUNT, payload: response});
    },
    (error) => {
      dispatch(
        getProgramJobsCountError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const removeJobListFromProgram: (
  payload: {program_id: string, job_list_ids: Array<string>},
  jobsPayload: ProgramJobsPayload,
) => ThunkAction<void> = flow()(
  (payload, jobsPayload) => (dispatch: Dispatch) => {
    return dispatch(reduxApi.del('referral/configure/job-list', payload)).then(
      () => {
        dispatch(getReferralJobs(payload.program_id, jobsPayload));
      },
      (error) => {
        dispatch(showApiError(error, error.response.message));
      },
    );
  },
);

export const addJobListToProgram: (
  payload: {program_id: string, job_list_ids: Array<string>},
  jobsPayload?: ProgramJobsPayload,
) => ThunkAction<void> = flow()(
  (payload, jobsPayload) => (dispatch: Dispatch) => {
    return dispatch(reduxApi.post('referral/configure/job-list', payload)).then(
      () => {
        if (jobsPayload) {
          dispatch(getReferralJobs(payload.program_id, jobsPayload));
        }
      },
      (error) => {
        dispatch(showApiError(error, error.response.message));
      },
    );
  },
);

export const fetchProgramLinks: () => ThunkAction<void> = flow(
  key(() => 'referral/default-program-links'),
  fetching(),
)(() => (dispatch: Dispatch) => {
  return dispatch(reduxApi.get('referral/default-program-links')).then(
    (response: ReferralLink) => {
      dispatch(getProgramLinks(response));
    },
    (error) => {
      dispatch(getProgramLinksError(error?.response?.errors || DEFAULT_ERROR));
    },
  );
});

export const updateProgram =
  (
    programId: number,
    programFilter: string | 'active' | 'inactive',
  ): ThunkAction<mixed> =>
  (dispatch: Dispatch) => {
    const actionType = programFilter === 'active' ? 'archive' : 'unarchive';
    return dispatch(
      reduxApi.post(`referral/program/${programId}/${actionType}`),
    ).then(
      () => {
        dispatch(getProgramsData('active'));
        dispatch(getProgramsData('inactive'));
        toastApi.show(
          toastMessage({
            message: `Program has been successfully ${
              programFilter === 'active' ? 'deactivated' : 'activated'
            }.`,
          }),
        );
      },
      (error) => {
        dispatch(showApiError(error, error?.response?.message));
      },
    );
  };

export const getReferralQuestionnaire: () => ThunkAction<void> = flow(
  key(() => `referral/configure/questionnaire`),
  fetching(),
)(() => (dispatch: Dispatch) => {
  dispatch(receiveQuestionnaireLoading());

  return dispatch(reduxApi.get(`referral/configure/questionnaire`)).then(
    (response: Array<ReferralQuestionnaire>) => {
      dispatch(receiveQuestionnaire(response));
    },
    (error) => {
      dispatch(
        receiveQuestionnaireError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const getProgramsList: () => ThunkAction<void> = flow(
  key(() => `referral/referral-programs}`),
  fetching(),
)(() => (dispatch: Dispatch) => {
  dispatch(getProgramsLoading());

  return dispatch(reduxApi.get(`referral/referral-programs`)).then(
    (response: ReferralProgramsApiResponse) => {
      dispatch(getPrograms(response));
    },
    (error) => {
      dispatch(getProgramsError(error?.response?.errors || DEFAULT_ERROR));
    },
  );
});

export const getProgramDetails: (programId: string) => ThunkAction<void> = flow(
  key((programId) => `referral/configurations/${stringify(programId)}`),
  fetching(),
)((programId: string) => (dispatch: Dispatch, getState: GetState) => {
  const isReferralsJobListEnabled =
    getState().productFlags.releaseFlags.referralsJobListEnabled;

  dispatch(receiveReferralDetailsLoading());

  return dispatch(reduxApi.get(`referral/configurations/${programId}`)).then(
    (response: ReferralDetails) => {
      dispatch(receiveReferralDetails(response, isReferralsJobListEnabled));
      dispatch(
        updateProgramDetailsFormData({
          setup: response.setup ? response.setup : INITIAL_SETUP_STATE,
          bonus_and_payout_settings: response.settings
            ? response.settings.bonus_and_payout_settings
            : INITIAL_REFERRAL_SETTINGS.bonus_and_payout_settings,
        }),
      );
    },
    (error) => {
      dispatch(
        receiveReferralDetailsError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const saveProgramStepDetails: (
  payload: SaveReferralStepApiRequest,
) => ThunkAction<void> = flow(
  key((payload) => `referral/configure/${stringify(payload)}`),
  fetching(),
)((payload: SaveReferralStepApiRequest) => (dispatch: Dispatch) => {
  dispatch(saveReferralDetailsLoading());

  const url = payload.id
    ? `referral/configure/${payload.id}`
    : 'referral/configure';

  return dispatch(reduxApi.post(url, payload.data)).then(
    (response: {programId: string}) => {
      dispatch(saveReferralDetailsError(DEFAULT_ERROR));
      // $FlowFixMe[incompatible-type-arg]
      return response;
    },
    (error) => {
      dispatch(
        saveReferralDetailsError(error?.response?.errors || DEFAULT_ERROR),
      );
    },
  );
});

export const getReferralBrands: () => ThunkAction<void> = flow(
  key(() => `referral/configure/brands`),
  fetching(),
)(() => (dispatch: Dispatch) => {
  dispatch(receiveBrandsLoading());

  return dispatch(reduxApi.get(`referral/configure/brands`)).then(
    (response: Array<ReferralBrand>) => {
      dispatch(receiveBrands(response));
    },
    (error) => {
      dispatch(receiveBrandsError(error?.response?.errors || DEFAULT_ERROR));
    },
  );
});
