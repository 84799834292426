// @flow

import type {AudienceEntity} from 'src/types/multientity-audience';
import type {TypeaheadProps} from 'src/components/typeahead/with-typeahead.jsx';
import type {Option} from 'src/components/lib/token-list-input/token-list-input.jsx';
import type {EntityType, EntitySenseName} from 'src/types/ats-entities.js';

import * as React from 'react';

import {useTypeahead} from 'src/components/typeahead/with-typeahead.jsx';

import {emptyArray, classify} from 'src/utils/index';
import {nonPersonEntityDisplay} from 'src/utils/entities';

import Highlighter from 'src/components/lib/highlighter';
import TokenListInput from 'src/components/lib/token-list-input';

import css from './user-token-search.css';


type UserTokenSearchProps = {|
  ...TypeaheadProps<
    AudienceEntity | {id: string, email?: string, emailaddress: string},
  >,
  className: string,
  entityType: EntityType,
  onSearchResultClick: (
    audienceMemberId: string,
    meta?: {
      value: string,
      label: string,
      sourceObject: {[key: string]: ?string, ...},
    },
  ) => mixed,
  placeholder?: string,
  showsValue?: boolean,
  onClearSelection: () => mixed,
  workflowEntityType: EntitySenseName,
  isPersonEntity: boolean,
  label?: React.Node,
  errors: string[],
  defaultValues?: {[key: string]: ?string, ...}[],
|};

const UserTokenSearch = ({
  className,
  entityType,
  onSearchResultClick,
  placeholder,
  showsValue,
  onClearSelection,
  label,
  workflowEntityType,
  isPersonEntity = true,
  defaultValues,
  errors,
}: UserTokenSearchProps): React.Node => {
  const [searchResults, onSearch] = useTypeahead('entity', entityType);
  const [searchQuery, setSearchQuery] = React.useState('');

  const formatHit = (hit) => {
    let label, secondaryLabel;
    if (isPersonEntity) {
      label = hit.fullName || hit.primaryEmail || ''; //ENGAGE-7021 (both can be null or undefined)
      secondaryLabel = hit.fullName ? hit.primaryEmail : '';
    } else {
      const entityDisplay = nonPersonEntityDisplay[workflowEntityType];

      if (!entityDisplay) {
        throw new Error(
          'Invalid entity type. Check nonPersonEntityDisplay constant in utils/entities',
        );
      }

      label = hit[entityDisplay.typeAheadPrimaryLabel];
      secondaryLabel =
        hit[entityDisplay.typeAheadSecondaryLabel] &&
        `ID: ${hit[entityDisplay.typeAheadSecondaryLabel]}`;
    }

    return {
      value: hit.id,
      label,
      sourceObject: {...hit},
      extras: {
        secondaryLabel,
        isPersonEntity,
      },
    };
  };

  const memoResults = React.useMemo(() => {
    if (searchQuery.trim() === '') {
      return [];
    }
    return searchResults ? searchResults.map(formatHit) : [];
  }, [searchQuery, searchResults]);

  const [tokenValue, setTokenValue] = React.useState(
    defaultValues?.map((val) => formatHit(val)) || [],
  );

  React.useEffect(() => {
    if (defaultValues?.length) {
      setTokenValue(defaultValues.map((val) => formatHit(val)));
    }
  }, [defaultValues?.length]); //changes to defaultValues does not trigger input onChange

  const setSearch = React.useMemo(
    () => (value: string) => {
      setSearchQuery(value);
      onSearch(value);
    },
    [setSearchQuery, onSearch],
  );

  return (
    <TokenListInput
      label={label}
      containerClassName={className}
      placeholder={placeholder}
      allowArbitraryValues={false}
      options={memoResults ? memoResults : emptyArray}
      onInputChange={setSearch}
      Suggestion={PersonSuggestion}
      suggestionExtras={{searchQuery}}
      limit={1}
      showValuesInInput={false}
      onChange={(options) => {
        if (options?.length > 0) {
          onSearchResultClick(options[0].value, options[0]);
          if (showsValue) {
            setTokenValue([options[0]]);
          } else {
            setSearch('');
          }
        } else {
          setTokenValue([]);
          if (onClearSelection) {
            onClearSelection();
          }
        }
      }}
      showValuesInInput={true}
      values={tokenValue}
      unfiltered={true}
      errors={errors}
      allowBlurOnItemSelection={true}
    />
  );
};

export default UserTokenSearch;

const PersonSuggestion = ({
  option,
  searchString,
  className,
  onClick,
  onMouseDown,
}: {
  option: Option<
    {
      secondaryLabel: string,
    },
    {email: string},
  >,
  extras: {searchQuery: string},
  searchString: string,
  className: string,
  onClick: (evt: SyntheticMouseEvent<>) => mixed,
  onMouseDown: (evt: SyntheticKeyboardEvent<>) => mixed,
}) => (
  <div
    className={classify(css.searchResult, className)}
    onMouseDown={onClick}
    onClick={onClick}
  >
    <Highlighter className={css.ellipsis} search={searchString}>
      {option?.label}
    </Highlighter>
    <Highlighter className={css.ellipsis} search={searchString}>
      {option?.extras?.secondaryLabel}
    </Highlighter>
  </div>
);
