// @flow

// $FlowFixMe[untyped-type-import]
import type {AgencyOverview} from 'src/api-parsers/index';
import type {PaginatedResponses} from 'src/types/report';

import * as React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'src/rerouter';
import moment from 'moment';
import {Helmet} from 'react-helmet';

import {useTrackedAction} from 'src/hooks/useTracking';
import useSnippet from 'src/hooks/useSnippet';
import {selectEntityBySenseName} from 'src/selectors/ats-entities';
import {isInternalUser} from 'src/utils/check-internal-user';
import {selectReleaseFlags} from 'src/selectors/product-flags';
import {AnalyticsService} from 'src/analytics';
//$FlowFixMe[nonstrict-import]
import {getCurrentAgent} from 'src/selectors/accounts';
import {selectAgencyConfig, selectAgencyData} from 'src/selectors/agency';
import {SURVEY_SUMMARY} from 'src/components/sense-ai-panel/tracking-events';
import {hasPrivilege} from 'src/utils/accounts';

import Tile from 'src/components/lib/tile';
import UserSearch from 'src/components/lib/user-token-search';
import DashboardRoi from 'src/components/roi/dashboard-roi.jsx';
import DashboardResponses from './dashboard-responses.jsx';
import EventsTable from './recent-events.jsx';
import OnboardPageNavigator from 'src/components/agency-onboard/onboard-page-navigator/onboard-page-navigator.jsx';

import PeopleIcon from 'src/images/nav/people-icon.svg';
import ScheduleIcon from 'src/images/nav/schedule-icon.svg';
import WorkflowIcon from 'src/images/nav/workflow-icon.svg';
import NpsIcon from 'src/images/event-types/nps.svg';
import useAgencyConfig from 'src/hooks/useAgencyConfig';
import {useAudienceFilterSeparation} from 'src/hooks/product-flags';
import {setUrlSuffixAndOpenPanel} from 'src/action-creators/sense-ai-panel';
import {USE_SURVEY_SUMMARY_GENERATOR} from 'src/action-creators/roles';

import css from './dashboard.css';

import useI18n from 'src/hooks/useI18n';


export default function Dashboard({
  agencyOverview,
  responseData,
  flaggedResponseData,
  name,
  flaggedWords,
  multiEntityEnabled,
}: {
  // $FlowFixMe[value-as-type] [v1.32.0]
  agencyOverview: AgencyOverview,
  responseData: PaginatedResponses,
  flaggedResponseData: PaginatedResponses,
  name: string,
  flaggedWords: string[],
  multiEntityEnabled: boolean,
}): React.Element<'div'> {
  const {
    sentEvents,
    queuedEvents,
    totalCandidates,
    activeAudienceMembers,
    nps,
    recentlyVisitedEvents,
  } = agencyOverview;
  useSnippet();

  const router = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const {id: agencyId, name: agencyName} = useSelector(selectAgencyData);
  const {id: agentId} = useSelector((state) => getCurrentAgent(state));

  React.useEffect(() => {
    handleAnalyticsEvents();
  }, []);

  // NOTE (kyle): impurity
  const date = moment();

  // NOTE (kyle): this seems like a common pattern?
  const entityType = useSelector(
    (state) => selectEntityBySenseName(state, 'candidate')?.name,
  );

  const roiV2Enabled = useSelector(selectReleaseFlags).roiV2Enabled;
  const {labelI18nInstance} = useI18n();
  const isJourneyV2 = useAudienceFilterSeparation();
  const customerBusinessType =
    useSelector(selectAgencyConfig).customerBusinessType;
  const isCorporate = customerBusinessType === 'corporate';
  const isViewSummaryVisible = hasPrivilege(
    state,
    USE_SURVEY_SUMMARY_GENERATOR,
  );

  const handleAnalyticsEvents = () => {
    try {
      const {accounts, agency, productFlags} = state;
      const userId = accounts?.authedUserId;
      const data = {
        roles: accounts.data[userId].securityRole.privileges,
        release_flags: productFlags.releaseFlags,
        agencyName: agency.agency.name,
        agency_id: agency.agency.id,
        ats_kind: agency.agency.atsSystem,
      };

      AnalyticsService.identify(userId);
      AnalyticsService.register({...data});
    } catch (error) {}
  };

  const viewSurveySummaries = () => {
    dispatch(
      setUrlSuffixAndOpenPanel(
        `/survey-summary/saved-summaries?source=dashboard`,
      ),
    );
  };

  const trackedViewSummaryClick = useTrackedAction(
    SURVEY_SUMMARY.SURVEY_SUMMARY_VISITED,
    viewSurveySummaries,
    {
      agentId,
      agencyId,
      agencyName,
      internalUser: isInternalUser(),
      pageName: 'Admin Landing Page (Dashboard)',
      pageUrl: router.location.pathname,
    },
  );

  return (
    <div className={css.bg}>
      <Helmet title="Dashboard" />
      <div className={css.container} data-it-dashboard data-qa-id="dashboard">
        {useAgencyConfig('onboardRequired') ? <OnboardPageNavigator /> : <></>}
        {roiV2Enabled ? (
          <>
            {!isCorporate && (
              <div
                className={css.dashboardSection}
                data-qa-id="dashboard-roi-section"
              >
                <div className={css.subtitle}>
                  Return on investment in the previous quarter
                </div>
                <DashboardRoi />
              </div>
            )}
          </>
        ) : (
          <>
            <div className={css.header} data-qa-id="dashboard-header-section">
              <h1 className={css.title}>Welcome, {name}</h1>
              <div>
                <UserSearch
                  className={css.userSearch}
                  entityType={entityType}
                  onSearchResultClick={(audienceMemberID) =>
                    router.push(`/people/candidates/${audienceMemberID}`)
                  }
                  placeholder="Search for a person"
                  isPersonEntity
                />
                <span className={css.date}>
                  <span className={css.month}>{date.format('MMMM')} </span>
                  <span className={css.day}>{date.format('D')}</span>
                </span>
              </div>
            </div>

            <div className={css.stats} data-qa-id="dashboard-tile-section">
              <Tile
                number={sentEvents}
                label="Touchpoints in Last 7 Days"
                icon={<WorkflowIcon className={css.statsWorkflowIcon} />}
                to="/schedule"
              />

              <Tile
                number={queuedEvents}
                label="Upcoming Touchpoints"
                icon={<ScheduleIcon className={css.statsScheduleIcon} />}
                to="/schedule"
              />

              {multiEntityEnabled ? (
                <Tile
                  number={totalCandidates}
                  label={
                    'Total ' +
                    labelI18nInstance.t('sense_candidates', 'Candidates')
                  }
                  icon={<PeopleIcon className={css.statsPeopleIcon} />}
                  to="/people"
                />
              ) : (
                <Tile
                  number={activeAudienceMembers || '-'}
                  label="Active People"
                  icon={<PeopleIcon className={css.statsPeopleIcon} />}
                  to="/people"
                />
              )}

              {nps && (
                <Tile
                  number={nps.score.toLocaleString(undefined, {
                    maximumFractionDigits: 1,
                  })}
                  label="NPS"
                  icon={<NpsIcon className={css.statsWorkflowIcon} />}
                />
              )}
            </div>
          </>
        )}

        <div
          className={css.dashboardSection}
          data-qa-id="dashboard-events-section"
        >
          <div className={css.subtitle}>Recently Viewed</div>
          <EventsTable fetching={!recentlyVisitedEvents} />
        </div>
        <DashboardResponses
          recentResponseData={responseData}
          flaggedResponseData={flaggedResponseData}
          flaggedWords={flaggedWords}
          multiEntityEnabled={multiEntityEnabled}
          isJourneyV2={isJourneyV2}
          handleViewSummaryClick={trackedViewSummaryClick}
          isViewSummaryVisible={isViewSummaryVisible}
        />
      </div>
    </div>
  );
}

// NOTE (kyle): these can be used for testing
/*
const TestLinks = () => (
  <div>
    <Link to="/">Home Redirect</Link>
    <Link to="/fun-times">A Bad Link</Link>
    <Link to="/workflow/whatisthis">A Bad Workflow</Link>
    <Link to="/analytics/oldworkflow">A Bad Analytics Workflow Redirect</Link>
  </div>
);
*/
