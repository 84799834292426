// @flow strict
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useEnvironmentContext} from 'src/hooks/useEnvironmentContext';
import {ERROR_CODES} from 'src/components/authentication/errors.js';
import {CONSTANTS} from 'src/components/authentication/contants.js';
import {Input} from '@spaced-out/ui-design-system/lib/components/Input';
import {
  TitleMedium,
  BodyLarge,
  BodyMedium,
  ButtonTextMedium,
  SubTitleMedium,
  ButtonTextSmall,
  BodySmall,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {AuthFlow} from 'src/components/authentication/auth-flow.js';
import {InContextAlert} from '@spaced-out/ui-design-system/lib/components/InContextAlert';
import classify from 'src/utils/classify';
import {
  verifyOtpForUser,
  performSignIn,
  resendOtp,
  resetPhone,
} from 'src/action-creators/authentication.js';

import {
  selectUserLoginId,
  selectUserEmail,
  selectUserNewPhoneNumber,
  selectCurrentAgency,
  selectOtpVerificationError,
  selectUserPhoneNumber,
  selectResendOtpError,
  selectOtpLimitExceeded,
  selectMfaAuthChannel,
} from 'src/selectors/authentication';

import type {AuthSideBarComp} from 'src/types/authentication.js';
import css from './otp-page.css';
import maincss from 'src/components/authentication/main.css';


const OtpPage = (props: AuthSideBarComp): React.Node => {
  const dispatch = useDispatch();
  const {goToPage} = props;
  const [otpString, setOtpString] = React.useState<string>('');
  const [isVerifying, setIsVerifying] = React.useState<boolean>(false);
  const [isResendingOtp, setIsResendingOtp] = React.useState<boolean>(false);
  const [isResendSuccess, setIsResendSuccess] = React.useState<boolean>(false);

  const userEmail = useSelector(selectUserEmail);
  const loginId = useSelector(selectUserLoginId);
  const userPhone = useSelector(selectUserPhoneNumber);
  const newPhoneNumber = useSelector(selectUserNewPhoneNumber);
  const agency = useSelector(selectCurrentAgency);
  const otpError = useSelector(selectOtpVerificationError);
  const apiError = useSelector(selectResendOtpError);
  const otpLimitExceeded = useSelector(selectOtpLimitExceeded);
  const authChannel = useSelector(selectMfaAuthChannel);

  const showResetPhoneNumber = authChannel === 'SMS' && userPhone;
  const validateOtp = async () => {
    setIsVerifying(true);
    setIsResendSuccess(false);
    const apiData = await dispatch(
      verifyOtpForUser(otpString, userEmail, loginId, newPhoneNumber),
    );
    if (apiData) {
      dispatch(performSignIn(agency));
    }
    setIsVerifying(false);
  };

  const handleResentOtp = async () => {
    if (otpLimitExceeded) {
      return;
    }

    if (isResendingOtp) {
      return;
    }

    setIsResendingOtp(true);
    const response = await dispatch(
      resendOtp(userEmail, loginId, newPhoneNumber),
    );

    if (response) {
      setIsResendSuccess(true);
    } else {
      setIsResendSuccess(false);
    }

    setIsResendingOtp(false);
  };

  const handleResetPhone = async () => {
    await dispatch(resetPhone(userEmail));
    goToPage(AuthFlow.SMS_REGISTRATION.RESET_PHONE);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      validateOtp();
    }
  };
  const {isExtension} = useEnvironmentContext();
  const hashedPhone =
    newPhoneNumber || userPhone
      ? ' ******' + (newPhoneNumber || userPhone)?.slice(-4)
      : '';
  const smsOtpPageSubtext = [
    CONSTANTS.OTP_PAGE_SUBTEXT,
    hashedPhone,
    'Enter the code below.',
  ].join(' ');
  return (
    <div className={css.otpPage}>
      <div className={css.titleSection}>
        {isExtension ? (
          <SubTitleMedium
            className={classify(css.contentTitle, maincss.alignSelfCenter)}
          >
            {CONSTANTS.OTP_PAGE_TITLE}
          </SubTitleMedium>
        ) : (
          <TitleMedium className={css.contentTitle}>
            {CONSTANTS.OTP_PAGE_TITLE}
          </TitleMedium>
        )}
        {authChannel === 'SMS' &&
          (isExtension ? (
            <BodyMedium
              className={classify(maincss.subText, maincss.textAlignCenter)}
            >
              {smsOtpPageSubtext}
            </BodyMedium>
          ) : (
            <BodyLarge className={maincss.subText}>
              {smsOtpPageSubtext}
            </BodyLarge>
          ))}
        {authChannel === 'EMAIL' &&
          (isExtension ? (
            <BodyMedium
              className={classify(maincss.subText, maincss.textAlignCenter)}
            >
              {CONSTANTS.EMAIL_OTP_PAGE_SUBTEXT}
            </BodyMedium>
          ) : (
            <BodyLarge className={maincss.subText}>
              {CONSTANTS.EMAIL_OTP_PAGE_SUBTEXT}
            </BodyLarge>
          ))}
      </div>
      <div
        className={classify(css.otpSection, {
          [css.otpSectionExtension]: isExtension,
        })}
      >
        <div className={css.otpInput}>
          <Input
            label="Enter verification code"
            size="medium"
            type="tel"
            value={otpString}
            error={!!otpError}
            errorText={ERROR_CODES[otpError] || otpError}
            onChange={(e) => setOtpString(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
          />
        </div>
        <Button
          size="medium"
          type="primary"
          classNames={{
            wrapper: isExtension ? css.doneBtnExtension : css.doneBtn,
          }}
          onClick={validateOtp}
          disabled={isVerifying || !otpString.length || otpLimitExceeded}
        >
          {isVerifying ? 'Verifying' : 'Verify'}
        </Button>
      </div>
      <div
        className={classify(css.resetSection, {
          [css.alignElements]: isExtension,
        })}
      >
        {isExtension ? (
          <BodySmall className={css.helpText}>
            Didn't get the code?
            <ButtonTextSmall
              color={
                isResendingOtp || otpLimitExceeded ? 'disabled' : 'clickable'
              }
              className={css.resendLink}
              onClick={handleResentOtp}
            >
              {isResendingOtp ? 'Resending' : 'Resend'}
            </ButtonTextSmall>
          </BodySmall>
        ) : (
          <BodyMedium className={css.helpText}>
            Didn't get the code?
            <ButtonTextMedium
              color={
                isResendingOtp || otpLimitExceeded ? 'disabled' : 'clickable'
              }
              className={css.resendLink}
              onClick={handleResentOtp}
            >
              {isResendingOtp ? 'Resending' : 'Resend'}
            </ButtonTextMedium>
          </BodyMedium>
        )}
        {showResetPhoneNumber &&
          (isExtension ? (
            <ButtonTextSmall
              color="clickable"
              className={css.resetPhoneLink}
              onClick={handleResetPhone}
            >
              Reset phone number
            </ButtonTextSmall>
          ) : (
            <ButtonTextMedium
              color="clickable"
              className={css.resetPhoneLink}
              onClick={handleResetPhone}
            >
              Reset phone number
            </ButtonTextMedium>
          ))}
      </div>
      {!isResendSuccess && !!apiError && (
        <InContextAlert
          semantic="danger"
          classNames={{wrapper: maincss.apiError}}
        >
          {ERROR_CODES[apiError] || apiError}
        </InContextAlert>
      )}
      {isResendSuccess && (
        <InContextAlert
          semantic="success"
          classNames={{wrapper: maincss.apiError}}
        >
          {CONSTANTS['RESEND_OTP_SUCCESS']}
        </InContextAlert>
      )}
    </div>
  );
};

export default OtpPage;
