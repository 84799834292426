var React = require('react');

function Plus2 (props) {
    return React.createElement("svg",props,React.createElement("path",{"fillRule":"evenodd","clipRule":"evenodd","d":"M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"}));
}

Plus2.defaultProps = {"width":"24","height":"24","viewBox":"0 0 24 24"};

module.exports = Plus2;

Plus2.default = Plus2;
