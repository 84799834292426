// @flow strict

/*
 *  Formats a decimal point number to a string percentage to numPlaces after the decimal point
 *  i.e. (0.8754, 1) -> "87.5%"
 */
const percentFormatter = new Intl.NumberFormat(undefined, {
  style: 'percent',
  maximumSignificantDigits: 2,
});

export function formatPercentage(val: number, numPlaces?: number): string {
  return numPlaces != null
    ? val.toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: numPlaces,
      })
    : percentFormatter.format(val);
}
/**
 *  if the percent is ≥ 10.0%, display as “XX%” (round to integer)
    if the percent is < 10.0% and ≥ 0.5%, display as “X.X%” (round to 1 decimal)
    if the percent is <0.05%, display as “0%” (so it rounds to 0.0%, but we don’t show the “.0")
 */
export function formatHumanPercentage(val: number): string {
  if (val === 0) {
    return percentFormatter.format(val);
  } else if (val >= 0.0005) {
    // need to round to 4 decimal places due to problem with percentage formatter
    // https://github.com/tc39/proposal-intl-numberformat-v3/issues/8
    // TODO (nilarnab): keep an eye open for better work around
    const refinedValue = Math.round(val * 10000) / 10000;
    return refinedValue * 100 < 1 && refinedValue > 0
      ? percentFormatter.format(Math.round(refinedValue * 1000) / 1000)
      : percentFormatter.format(refinedValue);
  } else {
    return `~${percentFormatter.format(0)}`;
  }
}

/**
 * The preferred number formatter.
 *
 * If we run into performance issues, we may want to instantiate an Intl.NumberFormat.
 */
const compactFormatter = new Intl.NumberFormat(undefined, {
  notation: 'compact',
});
export function formatCompactNumber(val: number): string {
  return compactFormatter.format(val);
}

export function formatFractionNumber(val: number): string {
  return val.toLocaleString(undefined, {
    notation: 'compact',
    maximumFractionDigits: 1,
  });
}

/**
 * This formats number in form of:
 * XXX,XXX for number less than a million
 * XXXm for more than millions
 *
 * Lossless because the formatCompact will convert xyzabc to xyzK
 * and we lose information about abc
 */
export function formatCompactNumberLossless(val: number): string {
  return prettifyNumber(val, 1, 1000000);
}

/**
 * NOTE (kyle): The following two number formatters are legacy functions that
 * are used essentially as polyfills for older versions of IE. We should avoid
 * using them going forward.
 */

/**
 * Formats a number as a string attempting to reduce the character count to
 * a human readable precision.
 */
export function prettifyNumber(
  valParam: number,
  maximumFractionDigits: number = 1,
  shrinkThousandPosition: number = 10_000,
): string {
  let localeSymbol;
  let val = valParam;

  if (val >= 1_000_000) {
    val = val / 1_000_000;
    localeSymbol = 'm';
  } else if (val >= shrinkThousandPosition) {
    val = val / 1_000;
    localeSymbol = 'k';
  } else {
    localeSymbol = '';
  }

  return val.toLocaleString(undefined, {maximumFractionDigits}) + localeSymbol;
}

export function formatCurrency(
  val: number,
  currency: string = 'USD',
  options: {
    minimumFractionDigits?: number,
    maximumFractionDigits?: number,
  },
): string {
  return val.toLocaleString(undefined, {
    style: 'currency',
    currency,
    ...(options || {}),
  });
}

/**
 * Turns an integer into a written English word.
 * Adapted from https://stackoverflow.com/a/20426113
 *
 * @param {number} n - Input number
 * @return {string} The number written out in english
 *
 * @example
 *
 * stringifyNumber(22) // Twenty-two
 */
export function stringifyNumber(n: number): string {
  const special = [
    'zeroth',
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
    'eleventh',
    'twelfth',
    'thirteenth',
    'fourteenth',
    'fifteenth',
    'sixteenth',
    'seventeenth',
    'eighteenth',
    'nineteenth',
  ];
  const deca = [
    'twent',
    'thirt',
    'fort',
    'fift',
    'sixt',
    'sevent',
    'eight',
    'ninet',
  ];
  if (n < 20) {
    return special[n];
  }
  if (n % 10 === 0) {
    return deca[Math.floor(n / 10) - 2] + 'ieth';
  }
  return deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10];
}

export function getRatingMood(val: number, max?: number = 10): string {
  const percent = val / max;

  let mood;
  if (percent < 0.35) {
    mood = 'negative';
  } else if (percent < 0.7) {
    mood = 'neutral';
  } else {
    mood = 'positive';
  }

  return mood;
}

export function getNpsRatingMood(val: number, max?: number = 10): string {
  const percent = val / max;

  if (percent <= 0.6) {
    return 'negative';
  }
  if (percent <= 0.8) {
    return 'neutral';
  }
  return 'positive';
}
export function withOrdinalSuffix(n: number): string {
  const [j, k] = [n % 10, n % 100];

  if (j === 1 && k !== 11) {
    return n + 'st';
  }
  if (j === 2 && k !== 12) {
    return n + 'nd';
  }
  if (j === 3 && k !== 13) {
    return n + 'rd';
  }
  return n + 'th';
}

export function getNumberIntlFormat(
  value: number,
  locales?: string,
  options?: {
    localeMatcher?: 'lookup' | 'best fit',
    style?: 'decimal' | 'currency' | 'percent',
    currency?: string,
    currencyDisplay?: 'symbol' | 'code' | 'name',
    useGrouping?: boolean,
    minimumIntegerDigits?: number,
    minimumFractionDigits?: number,
    maximumFractionDigits?: number,
    minimumSignificantDigits?: number,
    maximumSignificantDigits?: number,
  },
): string {
  return !locales
    ? new Intl.NumberFormat(undefined, options).format(value)
    : new Intl.NumberFormat(locales, options).format(value);
}

export function formatNumberWithCommas(number: number): string {
  return number.toLocaleString('en-US');
}

/**
 * Converts a number to a localized string representation.
 *
 * This function takes a number as input and returns its string representation
 * formatted according to the locale's number formatting conventions. If the
 * input is `null` or `undefined`, an empty string is returned. If the input
 * is not a valid number or is `NaN`, an empty string is also returned. A
 * number value of `0` will return the string "0".
 *
 * @param {number | null} [num] - The number to be formatted. Can be `null` or `undefined`.
 * @returns {string} The localized string representation of the number, or an empty string for invalid inputs.
 *
 * @example
 * numberToIntlString(1000); // "1,000"
 * numberToIntlString(1000000); // "1,000,000"
 * numberToIntlString(null); // ""
 * numberToIntlString(NaN); // ""
 * numberToIntlString(0); // "0"
 */
export const numberToIntlString = (num?: number | null): string => {
  if (num == null) {
    return '';
  }

  if (typeof num !== 'number' || isNaN(num)) {
    return '';
  }

  if (num === 0) {
    return '0';
  }

  return new Intl.NumberFormat().format(num);
};
