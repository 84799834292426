// @flow

import type {Router, Params} from 'src/types/router';
import type {Tearsheet, TearsheetSync} from 'src/types/ats-settings';
// $FlowFixMe[untyped-type-import]
import type {TableHeader} from 'src/components/lib/table';

import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Helmet} from 'react-helmet';
import capitalize from 'lodash/capitalize';
import pickBy from 'lodash/pickBy';

import {emptyArray} from 'src/utils';
import * as api from 'src/utils/api-no-store';
import * as reduxApi from 'src/utils/redux-api-v2.js';

import {pushModal, popModal} from 'src/action-creators/modal';

import MouseTip from 'src/components/lib/mouse-tip';
import {TitleBarModal} from 'src/components/modals/base-modal.jsx';
import {selectEntityMappingsByName} from 'src/selectors/ats-entities';
import {selectAllowedTearsheetTypes} from 'src/selectors/ats-settings';

import Table, {
  TablePage,
  TableContainer,
  TablePageHeader,
  Cells,
  // Cell,
} from 'src/components/lib/table';
import PaginationFooter from 'src/components/lib/table/pagination.jsx';
import Loading from 'src/components/lib/loading';
import Warning from 'src/components/lib/warning';
import Tabs from 'src/components/lib/tabs';
import Checkbox from 'src/components/lib/checkbox';
import {
  PageTitle,
  PageName,
} from '@spaced-out/ui-design-system/lib/components/PageTitle';
import {TitleMedium} from '@spaced-out/ui-design-system/lib/components/Text';

import css from 'src/components/settings/ats/ats.css';


const tabs = [
  {
    url: '',
    name: 'All',
    onlyActiveOnIndex: true,
    nameOnServer: [''],
  },
  {
    url: '/scheduled',
    name: 'In Progress',
    nameOnServer: ['not_synced', 'processing'],
  },
  {
    url: '/synced',
    name: 'Synced',
    nameOnServer: ['synced'],
  },
];

const initialState = {
  isLoading: false,
  rows: null,
  total: -1,
  searchText: '',
  warningMessage: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'startFetching':
      return {
        ...state,
        isLoading: true,
        warningMessage: null,
      };
    case 'receiveTearsheets':
      return {
        ...state,
        ...action.payload,
        warningMessage: null,
        isLoading: false,
      };
    case 'receiveBullhornError':
      return {
        ...state,
        warningMessage:
          'Something went wrong requesting your data from Bullhorn. Please try again later.',
        isLoading: false,
      };
    case 'updateSearchText':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'updateOneTearsheet':
      return {
        ...state,
        warningMessage:
          'Tearsheet is syncing. Come back later to check on its progress.',

        // $FlowFixMe optional chain call
        rows: state.rows?.map((sheet) => {
          if (sheet.external_source_id === action.payload.external_source_id) {
            return {...sheet, tearsheet_sync: action.payload};
          }
          return sheet;
        }),
      };
    default:
      throw new Error();
  }
}

const Tearsheets = ({
  router,
  router: {
    location: {
      query: {page: queryPage},
    },
  },
  params: {syncStatus: querySyncStatus},
}: {
  router: Router,
  params: Params,
}): React.Element<'div'> => {
  const dispatch = useDispatch();
  const [searchInputValue, setSearchInputValue] = React.useState('');
  const [{rows, total, warningMessage, searchText, isLoading}, localDispatch] =
    React.useReducer(reducer, initialState);

  const syncStatus = querySyncStatus ?? null;
  const page = parseInt(queryPage) || 1;
  const limit = 10;
  const query = {
    limit,
    offset: (page - 1) * limit,
    id_or_name: searchText.length ? searchText : null,
    sync_status: syncStatus
      ? tabs
          .find(({url}) => url.includes(String(syncStatus)))
          ?.nameOnServer.join(',')
      : null,
  };
  let maxPages = 0;
  if (total > 0) {
    maxPages = Math.ceil(total / query.limit);
  }
  const queryVals = React.useMemo(() => Object.values(query), [query]);

  React.useEffect(() => {
    localDispatch({
      type: 'startFetching',
    });
    api
      .get(
        `tearsheets${syncStatus ? '/syncs' : ''}`,
        // $FlowFixMe pickBy
        pickBy(query, (val) => val !== null) || {},
      )
      .then(({tearsheets, tearsheet_syncs, total}) => {
        const rows: (Tearsheet | TearsheetSync)[] =
          tearsheets || tearsheet_syncs;
        if (rows) {
          localDispatch({
            type: 'receiveTearsheets',
            payload: {
              rows,
              total,
            },
          });
        }
      })
      .catch(() => {
        localDispatch({
          type: 'receiveBullhornError',
        });
      });
  }, queryVals);

  return (
    <div className={css.tearsheetsContainer}>
      <PageTitle
        showBackButton
        onBack={() => {
          router.replace('/settings');
        }}
        classNames={{wrapper: css.pageTitleContainer}}
      >
        <PageName>
          <TitleMedium>Tearsheets</TitleMedium>
        </PageName>
      </PageTitle>

      <Tabs tabs={tabs} baseUrl="/settings/ats/tearsheets" />
      <div className={css.contentContainer}>
        <TablePage>
          <Helmet title="ATS Tearsheets" />
          <TablePageHeader className={css.tearsheetsHeader}>
            <form
              className={css.tearsheetFilters}
              onSubmit={(event) => {
                event.preventDefault();
                localDispatch({
                  type: 'updateSearchText',
                  payload: searchInputValue,
                });
                router.replace({...router.location, query: {}});
              }}
            >
              <input
                className={css.tearsheetSearchInput}
                type="text"
                value={searchInputValue}
                placeholder="Filter by name or ID"
                onChange={(event) => {
                  setSearchInputValue(event.target.value);
                }}
              />
              <button
                className={css.tearsheetFilterButton}
                disabled={searchInputValue === searchText}
              >
                Filter
              </button>
              <button
                className={css.tearsheetFilterButton}
                disabled={
                  !searchInputValue.length || !searchInputValue === searchText
                }
                onClick={() => {
                  setSearchInputValue('');
                  localDispatch({
                    type: 'updateSearchText',
                    payload: '',
                  });
                }}
              >
                Clear
              </button>
            </form>
          </TablePageHeader>
          {warningMessage && (
            <Warning className={css.postUpdateMessage}>
              {warningMessage}
            </Warning>
          )}
          <TableContainer>
            <Table
              className={css.table}
              header={syncStatus ? tearsheetSyncHeader : tearsheetHeader}
              entries={rows || emptyArray}
              Row={syncStatus ? TearsheetSyncRow : TearsheetRow}
              extras={{
                openModal: (tearsheetData) =>
                  dispatch(
                    pushModal({
                      type: 'TEARSHEET_TYPE_SELECT',
                      tearsheetData,
                      updateOneTearsheet: (rows) =>
                        localDispatch({
                          type: 'updateOneTearsheet',
                          payload: rows,
                        }),
                    }),
                  ),
              }}
              isLoading={isLoading}
            />
            {maxPages > 1 && (
              <PaginationFooter
                page={page}
                maxPages={maxPages}
                url={
                  syncStatus
                    ? `/settings/ats/tearsheets/${syncStatus}`
                    : '/settings/ats/tearsheets'
                }
                router={router}
              />
            )}
          </TableContainer>
        </TablePage>
      </div>
    </div>
  );
};

export default Tearsheets;

const TearsheetRow = ({
  data,
  data: {
    name,
    external_source_id,
    date_added,
    date_modified,
    tearsheet_sync,
    is_in_config,
  },
  extras: {openModal},
}: {
  data: Tearsheet,
  extras: {openModal: (Tearsheet) => void},
}) => {
  const getLastColumnContent = () => {
    let status = tearsheet_sync?.sync_status;
    if (is_in_config) {
      status = 'legacy';
    }
    switch (status) {
      case 'legacy':
      case 'synced':
        return 'Synced';
      case 'not_synced':
      case 'processing':
        return 'In Progress';
      case 'error':
        return <span className={css.syncError}>Error</span>;
      case null:
      default:
        const MAX_MEMBERS = 10000;
        let error_reason = null;

        if (
          data.total_candidates > MAX_MEMBERS ||
          data.total_contacts > MAX_MEMBERS
        ) {
          error_reason = `Tearsheets with more than ${MAX_MEMBERS} members cannot be synced. Consider breaking this up into multiple tearsheets.`;
        }

        return error_reason ? (
          <MouseTip content={error_reason}>
            <div className={css.noSyncAllowed} disabled>
              Cannot sync
            </div>
          </MouseTip>
        ) : (
          <button className={css.syncButton} onClick={() => openModal(data)}>
            Sync
          </button>
        );
    }
  };

  return (
    <Cells key={external_source_id}>
      <div className={css.tearsheetCell}>{external_source_id}</div>
      <div className={css.tearsheetCell}>{name}</div>
      <div className={css.tearsheetCell}>{date_added}</div>
      <div className={css.tearsheetCell}>{date_modified}</div>
      <div className={css.tearsheetCell}>{getLastColumnContent()}</div>
    </Cells>
  );
};

// $FlowFixMe[value-as-type] [v1.32.0]
const tearsheetHeader: TableHeader = [
  {
    key: 'external_source_id',
    label: 'ID',
    className: css.tearsheetCell,
  },
  {
    key: 'name',
    label: 'Name',
    className: css.tearsheetCell,
  },
  {
    key: 'date_added',
    label: 'Date Added',
    className: css.tearsheetCell,
  },
  {
    key: 'date_modified',
    label: 'Date Modified',
    className: css.tearsheetCell,
  },
  {
    key: 'tearsheet_sync',
    label: 'Status / Actions',
    className: css.tearsheetCell,
  },
];

export const TearsheetTypeSelectModal = ({
  tearsheetData,
  updateOneTearsheet,
}: {
  tearsheetData: Tearsheet,
  updateOneTearsheet: (TearsheetSync) => void,
}): React.Node => {
  // Selectors
  const allowedTearsheetTypes = useSelector(selectAllowedTearsheetTypes);
  const entityMappings = useSelector(selectEntityMappingsByName);

  // Dispatch
  const dispatch = useDispatch();
  const closeModal = () => dispatch(popModal());

  const options = allowedTearsheetTypes.map((tearsheetType) => {
    const targetEntityMapping = entityMappings[tearsheetType];
    return {
      value: targetEntityMapping.name,
      label: capitalize(targetEntityMapping.display_name),
    };
  });

  const [syncEntityTypes, setEntityTypeOptions] = React.useState(
    options.map((option) => ({...option, checked: false})),
  );
  const checkEntityType = (entityType: string, checked: boolean) => {
    setEntityTypeOptions((options) =>
      options.map((opt) => {
        if (opt.value === entityType) {
          return {...opt, checked};
        } else {
          return opt;
        }
      }),
    );
  };
  const handleEntityChecked = React.useCallback(
    (entityType: string) => (evt: SyntheticEvent<HTMLInputElement>) =>
      checkEntityType(entityType, evt.currentTarget.checked),
    [syncEntityTypes],
  );

  const handleSync = React.useCallback(async () => {
    const response = await Promise.all(
      syncEntityTypes
        .filter(({checked}) => checked)
        .map(async ({value}) =>
          dispatch(
            reduxApi.post('tearsheets', {
              name: tearsheetData.name,
              external_source_id: tearsheetData.external_source_id,
              entity_type: value,
            }),
          ),
        ),
    );
    updateOneTearsheet(response);
  }, [syncEntityTypes]);

  if (allowedTearsheetTypes === null) {
    return <Loading />;
  }

  return (
    <TitleBarModal
      type="TEARSHEET_TYPE_SELECT"
      title="Choose entity types for this tearsheet"
      handleAbort={closeModal}
      abortText="Cancel"
      handleConfirm={() => handleSync()}
      confirmText="Sync"
      removeModal={closeModal}
    >
      <p>
        This tearsheet may contain records for both candidate and contact entity
        types. Choose if you want to use only one or both of these entity types
        within your journeys. For example, if you only check mark “candidates”,
        then any records for “contacts” in this tearsheet will be ignored and
        won’t be available to your journeys.
      </p>
      <form>
        <div className={css.syncEntityChoiceBox}>
          <h5>Check one or more entity types</h5>
          {syncEntityTypes.map(({label, value, checked}) => (
            <label>
              <Checkbox
                checked={checked}
                name={value}
                onChange={handleEntityChecked(value)}
                className={css.entityCheckbox}
              />{' '}
              {label}
            </label>
          ))}
        </div>
      </form>
    </TitleBarModal>
  );
};

const TearsheetSyncRow = ({
  data: {name, external_source_id, ats_entity_type},
}: {
  data: TearsheetSync,
}) => (
  <Cells key={external_source_id}>
    <div className={css.tearsheetCell}>{external_source_id}</div>
    <div className={css.tearsheetCell}>{name}</div>
    <div className={css.tearsheetCell}>{ats_entity_type}</div>
  </Cells>
);

// $FlowFixMe[value-as-type] [v1.32.0]
const tearsheetSyncHeader: TableHeader = [
  {
    key: 'external_source_id',
    label: 'ID',
    className: css.tearsheetCell,
  },
  {
    key: 'name',
    label: 'Name',
    className: css.tearsheetCell,
  },
  {
    key: 'ats_entity_type',
    label: 'Entity Type',
    className: css.tearsheetCell,
  },
];
