// @flow

import type {SenseAction} from 'src/action-creators/types';

import {RECEIVE_ALLOWED_MEDIA_EXTENSIONS} from 'src/action-creators/chat/file-upload';


export type State = {
  extensions: string[],
};

const initialState = {
  extensions: [],
};

export default function reduce(
  state: State = initialState,
  action: SenseAction,
): State {
  switch (action.type) {
    case RECEIVE_ALLOWED_MEDIA_EXTENSIONS: {
      return {
        ...state,
        extensions: action.payload,
      };
    }

    default:
      return state;
  }
}
