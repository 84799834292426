// @noflow

import type {
  CsvImportAction,
  Step,
  FieldType,
  DateFormat,
} from 'src/action-creators/csv-import';

import omit from 'lodash/omit';

import {
  SET_ENTITY_TYPE,
  SET_ID_FIELD,
  SKIP_COLUMN,
  INCLUDE_COLUMN,
  SET_FIELD_NAME,
  SET_FIELD_TYPE,
  SET_DATE_FORMAT,
  ENABLE_CLIENT_VALIDATION,
  SET_MAPPING_ERRORS,
  PROCEED_TO_REVIEW,
  RECEIVE_CSV,
  RECEIVE_CSV_ERRORS,
  CLEAR_STATE,
  RECEIVE_IMPORT_HISTORY,
  PAGINATION_LIMIT,
  STEP_CHOOSE_FILE,
  STEP_MAP_FIELDS,
  STEP_REVIEW,
  ALLOWED_ID_TYPES,
} from 'src/action-creators/csv-import';


export type State = {
  entityType: string,
  csv: {file?: File, results?: any},
  active: Array<Step>,
  complete: Array<Step>,
  fieldMappings: Array<FieldMappingState>,
  errors?: ?Map,
  history: {
    page: number,
    imports: Array<any>,
    hasMore: boolean,
  },
  idFieldDisplayName?: string,
  idFieldAttributeName?: string,
  clientValidationEnabled?: boolean,
};

type FieldMappingState =
  | {
      displayName: ?string,
      attributeName: ?string,
      type: ?FieldType,
      format?: ?DateFormat,
      errors?: Array<string>,
    }
  | {
      skip: true,
    };

export const initialState = {
  entityType: null,
  csv: {},
  active: [STEP_CHOOSE_FILE],
  complete: [],
  fieldMappings: [],
  history: {
    page: 0,
    imports: [],
    hasMore: true,
  },
};

export const initialFieldMappingState = () => ({
  attributeName: null,
  displayName: null,
  type: null,
});

export default (state: State = initialState, action: CsvImportAction) => {
  let newState;

  switch (action.type) {
    case SET_ENTITY_TYPE:
      return {
        ...omit(state, 'idFieldDisplayName', 'idFieldAttributeName'),
        entityType: action.entityType,
        fieldMappings: state.fieldMappings.map(() =>
          initialFieldMappingState(),
        ),
        clientValidationEnabled: false,
        errors: null,
      };

    case SET_ID_FIELD:
      return {
        ...state,
        idFieldAttributeName: action.idFieldAttributeName,
        idFieldDisplayName: action.idFieldDisplayName,
      };

    case SKIP_COLUMN:
      newState = {
        ...state,
        fieldMappings: state.fieldMappings.map((fm, i) =>
          i === action.index ? {...fm, skip: true} : fm,
        ),
      };

      const fieldSkipped = state.fieldMappings[action.index].attributeName;
      if (fieldSkipped === state.idFieldAttributeName) {
        delete newState.idFieldAttributeName;
      }
      return newState;

    case INCLUDE_COLUMN:
      return {
        ...state,
        fieldMappings: state.fieldMappings.map((fm, i) =>
          i === action.index && fm.skip ? omit(fm, 'skip') : fm,
        ),
      };

    case SET_FIELD_NAME:
      newState = {
        ...state,
        fieldMappings: state.fieldMappings.map((fm, i) =>
          i === action.index
            ? {
                ...fm,
                displayName: action.displayName,
                attributeName: action.attributeName,
              }
            : {...fm},
        ),
      };

      const oldFieldDisplayName = state.fieldMappings[action.index].displayName;
      if (oldFieldDisplayName === state.idFieldDisplayName) {
        delete newState.idFieldDisplayName;
      }
      return newState;

    case SET_FIELD_TYPE:
      newState = {
        ...state,
        fieldMappings: state.fieldMappings.map((fm, i) => {
          if (i === action.index) {
            if (action.fieldType === 'date') {
              return {...fm, type: action.fieldType, format: 'YYYY-MM-DD'};
            }
            return {...omit(fm, 'format'), type: action.fieldType};
          }
          return {...fm};
        }),
      };

      if (!ALLOWED_ID_TYPES.includes(action.fieldType)) {
        const field = state.fieldMappings[action.index].displayName;
        if (field === state.idFieldDisplayName) {
          delete newState.idFieldDisplayName;
        }
      }
      return newState;

    case SET_DATE_FORMAT:
      return {
        ...state,
        fieldMappings: state.fieldMappings.map((fm, i) =>
          i === action.index ? {...fm, format: action.format} : {...fm},
        ),
      };

    case SET_MAPPING_ERRORS:
      return {
        ...state,
        errors: action.errors,
        fieldMappings: state.fieldMappings.map((fm, i) => {
          if (action.errors.has(i)) {
            return {...fm, errors: action.errors.get(i)};
          }
          return omit(fm, 'errors');
        }),
      };

    case ENABLE_CLIENT_VALIDATION:
      return {
        ...state,
        clientValidationEnabled: true,
      };

    case PROCEED_TO_REVIEW:
      newState = {
        ...state,
        active: [STEP_REVIEW],
        complete: [STEP_CHOOSE_FILE, STEP_MAP_FIELDS],
      };
      return newState;

    case RECEIVE_CSV:
      return {
        ...omit(state, 'idFieldDisplayName', 'idFieldAttributeName'),
        csv: action.payload,
        fieldMappings: action.payload.results.meta.fields.map(() =>
          initialFieldMappingState(),
        ),
        active: [STEP_MAP_FIELDS],
        complete: [STEP_CHOOSE_FILE],
        errors: null,
        clientValidationEnabled: false,
      };

    case RECEIVE_CSV_ERRORS:
      return {
        ...initialState,
        entityType: state.entityType,
        csv: {errors: action.payload},
      };

    case CLEAR_STATE:
      return {...initialState};

    case RECEIVE_IMPORT_HISTORY:
      return {
        ...state,
        history: {
          page: action.page,
          imports:
            action.page === 0
              ? action.imports
              : [...state.history.imports, ...action.imports],
          hasMore: action.imports.length >= PAGINATION_LIMIT,
        },
      };
  }
  return state;
};
