// @flow
import type {Reducer} from 'redux';
import type {CsrfAction} from 'src/action-creators/csrf';

import {RECEIVE_CSRF_TOKEN} from 'src/action-creators/csrf';


type State = {
  token: ?string,
};

const initialState = {
  token: null,
};

const reducer: Reducer<State, CsrfAction> = (
  state: State = initialState,
  action: CsrfAction,
) => {
  switch (action.type) {
    case RECEIVE_CSRF_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };
  }

  return state;
};

export default reducer;
