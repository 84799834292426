// @flow strict

import * as React from 'react';
import css from './style.css';
import classify from 'src/utils/classify';
import ArrowDownIcon from 'src/images/designSystems2021/chevron-down.svg';
import ArrowUpIcon from 'src/images/designSystems2021/chevron-up.svg';


type CollapsibleProps = {
  title: React.Node,
  children: React.Node,
  disabled?: boolean,
  isOpen?: boolean,
  containerClassName?: string,
  headerClassName?: string,
  className?: string,
  isOpenClassName?: string,
};

export const Collapsible = (props: CollapsibleProps): React.Node => {
  const [isOpen, setIsOpen] = React.useState(Boolean(props.isOpen));
  const {
    title,
    children,
    disabled,
    containerClassName,
    headerClassName,
    className,
    isOpenClassName,
  } = props;

  return (
    <div className={classify(css.collapsibleContainer, containerClassName)}>
      <div
        className={classify(
          css.collapsibleHeader,
          {
            [css.disabled]: disabled,
            [css.isOpen]: isOpen,
          },
          isOpen ? isOpenClassName : '',
          headerClassName,
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        {isOpen ? (
          <ArrowUpIcon className={css.arrowIcon} />
        ) : (
          <ArrowDownIcon className={css.arrowIcon} />
        )}
      </div>
      {isOpen && (
        <div className={classify(css.collapsibleContent, className)}>
          {children}
        </div>
      )}
    </div>
  );
};
