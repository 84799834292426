//@flow strict
import {
  SET_QUESTIONS,
  RECENT_QUESTIONS,
  ALL_DRAFT_QUESTIONS,
  MULTIPLE_QUESTION_VERSION,
  CLEAR_MULTIPLE_VERSIONS,
  TITLE,
  RESUME_QUESTIONS,
  CANDIDATE_DETAILS,
  ALL_CANDIDATES,
  JOB_TITLE,
  SET_AI_PANEL_OPEN,
  GENERATE_JD,
  RECEIVE_ALL_JDS,
  DELETE_JD,
  DELETE_PRESCREENING_QUESTIONS,
  RECEIVE_COMPANY_LIST,
  RECEIVE_ALL_PRESCREENING_QUESTIONS,
  SET_URL_SUFFIX_AND_OPEN_PANEL,
} from 'src/action-creators/sense-ai-panel.js';

import type {SenseAIPanelActions} from 'src/action-creators/sense-ai-panel.js';

import type {
  RecentQuestions,
  QuestionsMultipleVersion,
  CandidateDetails,
  JdGenerate,
  JdListing,
  PrescreeningListing,
} from 'src/types/sense-ai-panel';


type State = {
  questions?: string[],
  jdList?: JdListing,
  companies?: mixed,
  prescreeningList?: PrescreeningListing,
  showMenu?: boolean,
  recentQuestions?: ?RecentQuestions,
  multipleVersionQuestions?: ?QuestionsMultipleVersion,
  title?: string,
  resumeQuestions?: string[],
  candidateDetails?: ?CandidateDetails,
  allCandidates?: [],
  jobTitle?: string,
  isAiPanelOpen?: boolean,
  jdGenerateDetails?: JdGenerate,
  urlSuffix: string,
};

// TODO: (ashwini/vishal) Fix the types for initial state
const initialState: State = {
  questions: [],
  showMenu: false,
  companies: {},
  // $FlowFixMe[incompatible-exact]
  jdList: {},
  // $FlowFixMe[incompatible-exact]
  prescreeningList: {},
  // $FlowFixMe[incompatible-exact]
  recentQuestions: {},
  // $FlowFixMe[incompatible-exact]
  multipleVersionQuestions: {},
  title: '',
  resumeQuestions: [],
  // $FlowFixMe[incompatible-exact]
  candidateDetails: {},
  allCandidates: [],
  jobTitle: '',
  isAiPanelOpen: false,
  jdGenerateDetails: {
    input_jd: '',
    job_title: '',
    prompts: '',
    company_id: '',
    company_name: '',
    company_intro: '',
    company_benefits: '',
  },
  urlSuffix: '',
};

export default (
  state: State = initialState,
  action: SenseAIPanelActions,
): State => {
  switch (action.type) {
    case SET_AI_PANEL_OPEN: {
      return {
        ...state,
        isAiPanelOpen: action.payload,
        urlSuffix: '',
      };
    }
    case SET_QUESTIONS: {
      return {
        ...state,
        questions: action.payload,
      };
    }
    case TITLE: {
      return {
        ...state,
        title: action.payload,
      };
    }
    case ALL_DRAFT_QUESTIONS: {
      if (action.payload) {
        const questionsSet = {};
        action.payload.map((setValue) => {
          questionsSet[setValue.id] = {
            id: setValue.id,
            title: setValue.title,
            questions: setValue.questions,
          };
        });
        return {
          ...state,
          recentQuestions: {
            ...questionsSet,
          },
        };
      }
      return state;
    }
    case RECENT_QUESTIONS: {
      if (action.payload.id) {
        return {
          ...state,
          recentQuestions: {
            ...state.recentQuestions,
            [action.payload.id]: {
              id: action.payload.id,
              title: action.payload.title,
              questions: action.payload.questions,
            },
          },
        };
      }
      return state;
    }
    case MULTIPLE_QUESTION_VERSION: {
      const arr = state.multipleVersionQuestions;
      if (arr) {
        arr[parseInt(action.payload.index)] = action.payload.questions;
      }
      return {
        ...state,
        multipleVersionQuestions: arr,
      };
    }
    case CLEAR_MULTIPLE_VERSIONS: {
      return {
        ...state,
        // $FlowFixMe[incompatible-exact]
        multipleVersionQuestions: {},
      };
    }
    case RESUME_QUESTIONS: {
      return {
        ...state,
        resumeQuestions: action.payload,
      };
    }
    case CANDIDATE_DETAILS: {
      return {
        ...state,
        candidateDetails: action.payload,
      };
    }
    case ALL_CANDIDATES: {
      return {
        ...state,
        allCandidates: action.payload,
      };
    }
    case JOB_TITLE: {
      return {
        ...state,
        jobTitle: action.payload,
      };
    }
    case GENERATE_JD: {
      return {
        ...state,
        jdGenerateDetails: action.payload,
      };
    }
    case RECEIVE_ALL_JDS: {
      return {
        ...state,
        jdList: action.payload,
      };
    }
    case RECEIVE_ALL_PRESCREENING_QUESTIONS: {
      return {
        ...state,
        prescreeningList: action.payload,
      };
    }
    case DELETE_JD: {
      const updatedJdList = {
        ...state.jdList,
        rows: state.jdList?.rows?.filter(
          (item) => item.jd_id !== action.payload,
        ),
      };
      return {
        ...state,
        jdList: updatedJdList,
      };
    }
    case DELETE_PRESCREENING_QUESTIONS: {
      const updatedPrescreeningList = {
        ...state.prescreeningList,
        rows: state.prescreeningList?.rows?.filter(
          (item) => item.id !== action.payload,
        ),
      };
      return {
        ...state,
        prescreeningList: updatedPrescreeningList,
      };
    }
    case RECEIVE_COMPANY_LIST: {
      return {
        ...state,
        companies: action.payload,
      };
    }
    case SET_URL_SUFFIX_AND_OPEN_PANEL: {
      return {
        ...state,
        isAiPanelOpen: true,
        urlSuffix: action.payload,
      };
    }
  }

  return state;
};
