// @flow strict

import makeClassNameComponent, {
  type ClassNameComponent,
} from 'src/utils/makeClassNameComponent';

import css from './ui.css';


export const ButtonList: ClassNameComponent<> = makeClassNameComponent(
  css.list,
);
