// @flow strict

import {
  RECEIVE_ATTRIBUTES,
  type AttributesAction,
} from 'src/action-creators/entity-attributes';
import type {EntityAttributeSchema} from 'src/types/entity-attributes';


export type AttributesState = {
  simpleAttributes: Map<string, EntityAttributeSchema>,
  allAttributes: Map<string, EntityAttributeSchema>,
};

const initialState = {
  simpleAttributes: new Map(),
  allAttributes: new Map(),
};

export default (
  state: AttributesState = initialState,
  action: AttributesAction,
): AttributesState => {
  switch (action.type) {
    case RECEIVE_ATTRIBUTES: {
      return {
        ...state,
        simpleAttributes: action.payload.reduce(
          (map, attr) => (attr.hidden ? map : map.set(attr.id, attr)),
          new Map(),
        ),
        allAttributes: action.payload.reduce(
          (map, attr) => map.set(attr.id, attr),
          new Map(),
        ),
      };
    }
  }
  return state;
};
