// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
import type {Category} from 'src/types/journey-categories';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';
// $FlowFixMe[untyped-import]
import {key, cached, fetching} from 'src/utils/redux';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';


export const RECEIVE_CUSTOM_CATEGORIES = 'journey-categories/receiveAll';
export const ADD_CUSTOM_CATEGORY = 'journey-categories/add';
export const DELETE_CUSTOM_CATEGORY = 'journey-categories/delete';
export const UPDATE_CUSTOM_CATEGORY = 'journey-categories/update';

type ReceiveCustomCategoriesAction = {
  type: 'journey-categories/receiveAll',
  payload: Category[],
};
type AddCustomCategoryAction = {
  type: 'journey-categories/add',
  payload: {id: string, name: string, time_created: string},
};
type DeleteCustomCategoryAction = {
  type: 'journey-categories/delete',
  payload: {id: string},
};
type UpdateCustomCategoryAction = {
  type: 'journey-categories/update',
  payload: {id: string, name: string},
};

export type JourneyCategoriesAction =
  | ReceiveCustomCategoriesAction
  | AddCustomCategoryAction
  | DeleteCustomCategoryAction
  | UpdateCustomCategoryAction;

const receiveCustomCategories = (
  customCategories: Category[],
): ReceiveCustomCategoriesAction => ({
  type: 'journey-categories/receiveAll',
  payload: customCategories,
});

const doAddCustomCategory = ({
  id,
  name,
  time_created,
}: {
  id: string,
  name: string,
  time_created: string,
}): AddCustomCategoryAction => ({
  type: ADD_CUSTOM_CATEGORY,
  payload: {id, name, time_created},
});
const doRemoveCustomCategory = (id: string): DeleteCustomCategoryAction => ({
  type: DELETE_CUSTOM_CATEGORY,
  payload: {id},
});

const doUpdateCustomCategory = ({
  id,
  name,
}: {
  id: string,
  name: string,
}): UpdateCustomCategoryAction => ({
  type: UPDATE_CUSTOM_CATEGORY,
  payload: {id, name},
});

export const addCustomCategory =
  (name: string): ThunkAction<mixed> =>
  (dispatch: Dispatch) =>
    dispatch(reduxApi.post(`workflow-category`, {name})).then((response) => {
      const {id, name, time_created} = response;
      dispatch(doAddCustomCategory({id, name, time_created}));
    });

export const removeCustomCategory =
  (id: string): ThunkAction<mixed> =>
  (dispatch: Dispatch) =>
    dispatch(reduxApi.del(`workflow-category/${id}`)).then((_) => {
      dispatch(doRemoveCustomCategory(id));
    });

export const updateCustomCategory =
  ({id, name}: {id: string, name: string}): ThunkAction<mixed> =>
  (dispatch: Dispatch) =>
    dispatch(reduxApi.put(`workflow-category/${id}`, {name})).then(
      (response) => {
        const {id, name} = response;
        dispatch(doUpdateCustomCategory({id, name}));
      },
    );

export const getCustomCategories: () => ThunkAction<mixed> = flow(
  key(() => 'journey-categories/custom-all'),
  fetching(),
)(
  () => (dispatch: Dispatch) =>
    dispatch(reduxApi.get('workflow-category')).then((payload) => {
      dispatch(receiveCustomCategories(payload));
    }),
);
