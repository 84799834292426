// @flow strict

// $FlowFixMe[untyped-import]
import i18n from 'i18next';
// $FlowFixMe[untyped-import]
import {initReactI18next} from 'react-i18next';
// $FlowFixMe[untyped-import]
import LanguageDetector from 'i18next-browser-languagedetector';
// $FlowFixMe[untyped-import]
import Backend from 'i18next-chained-backend';
// $FlowFixMe[untyped-import]
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
// $FlowFixMe[untyped-import]
import HttpApi from 'i18next-http-backend';


const cacheOptions = {
  enabled: true,

  // prefix for stored languages
  prefix: 'sense-label-i18next-',

  expirationTime: 6 * 60 * 60 * 1000, // 6 hours

  // Version applied to all languages, can be overriden using the option `versions`
  defaultVersion: 'v1.1',

  // language versions
  versions: {en: 'v1.1'},

  // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
  store: typeof window !== 'undefined' ? window.localStorage : null,
};

const getLoadPath = (lng, ns) =>
  '/api/v1/configurations/label?lng=' + lng + '&ns=' + ns;

const httpApiOptions = {
  loadPath: getLoadPath('en', 'translations'),
  parse(data) {
    return JSON.parse(data);
  },
  requestOptions: {
    mode: 'cors',
    credentials: 'include',
    cache: 'default',
  },
};

// $FlowFixMe[signature-verification-failure]
const labelI18nInstance = i18n.createInstance();

labelI18nInstance
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  // .use(initReactI18next)
  .init({
    debug: false,
    backend: {
      backends: [
        LocalStorageBackend, // primary backend
        HttpApi, // fallback backend
      ],
      backendOptions: [cacheOptions, httpApiOptions],
    },
    defaultNS: ['translation'],
    ns: ['translation'],
    fallbackLng: 'en',
    lng: 'en',
    ignoreJSONStructure: false,
    keySeparator: '.',
    namespaceSeparator: ':',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
      wait: false,
    },
  });

export default labelI18nInstance;
