// @flow strict

/**
 * NOTE (kyle): this file uses some `date-fns` to parse and display python ISO
 * dates, but for the most part JS Date provides a good API for handling dates.
 * In the future we should be able to remove `date-fns` entirely in favor of
 * the ES Temporal API.
 */

import moment from 'moment';
// $FlowFixMe - strict types for datefns
import parseISO from 'date-fns/parseISO';
// $FlowFixMe - strict types for datefns
import formatISO from 'date-fns/formatISO';
// $FlowFixMe - strict types for datefns
import startOfDay from 'date-fns/startOfDay';
import momentTZ from 'moment-timezone';
// $FlowFixMe - strict types for datefns
import {parse, format} from 'date-fns';


export const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export function compareTimestamps(
  timestampA: string,
  timestampB: string,
): number {
  return timestampA === timestampB ? 0 : timestampA < timestampB ? 1 : -1;
}

const numericDateFormatter = new Intl.DateTimeFormat();
export function displayNumericDate(date?: string | Date): string {
  return numericDateFormatter.format(wrangleMoment(date));
}

const shortDateFormatter = new Intl.DateTimeFormat(undefined, {
  month: 'short',
  day: 'numeric',
});
// ex Dec 9
export function displayShortDate(date?: string | Date | moment$Moment): string {
  return shortDateFormatter.format(wrangleMoment(date));
}
const dateFormatter = new Intl.DateTimeFormat(undefined, {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});
// ex Dec 9, 1995
export function displayDate(date?: string | Date | moment$Moment): string {
  return dateFormatter.format(wrangleMoment(date));
}

const dateTimeFormatter = new Intl.DateTimeFormat(undefined, {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
});
// ex Oct 22, 2021, 6:24 AM
export function displayDateTime(date?: string | Date | moment$Moment): string {
  return dateTimeFormatter.format(wrangleMoment(date));
}

const fullDateTimeFormatter = new Intl.DateTimeFormat(undefined, {
  weekday: 'long',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
  timeZoneName: 'short',
});

export function displayFullDateTime(
  date?: string | Date | moment$Moment,
): string {
  return fullDateTimeFormatter.format(wrangleMoment(date));
}

const timeFormatter = new Intl.DateTimeFormat(undefined, {
  hour: 'numeric',
  minute: '2-digit',
});
export function displayTime(date?: string | Date | moment$Moment): string {
  return timeFormatter.format(wrangleMoment(date));
}

export function displayTerseDateTime(timestamp: string): string {
  const date = parseISO(timestamp);
  const today = startOfDay(new Date());
  // If the timestamp is more than a day old,
  // display its date. Otherwise display its time.
  if (date < today) {
    return displayDate(date);
  } else {
    return displayTime(date);
  }
}

export function formatIsoDate(date?: string | moment$Moment | Date): string {
  return formatISO(wrangleMoment(date), {
    representation: 'date',
  });
}

export function wrangleMoment(date?: string | Date | moment$Moment): Date {
  if (date instanceof Date) {
    return date;
  }
  if (!date) {
    return new Date();
  }
  return date instanceof moment
    ? date.toDate()
    : // $FlowIssue this is a good check
      parseISO(date);
}

export function getISODate(date?: Date): string {
  return formatIsoDate(date);
}

export const getUTCDate = (date: string | Date): string =>
  moment(date).utc().toISOString();

export function formatDaysAgo(
  value: string,
  locales: string = 'en-US',
): string {
  const date = wrangleMoment(value);

  const diff = (date.getTime() - Date.now()) / (1000 * 3600 * 24);
  //$FlowFixMe[prop-missing]
  const formatter = new Intl.RelativeTimeFormat(locales, {numeric: 'auto'});
  const absDiff = Math.abs(diff);

  if (absDiff >= 365) {
    return formatter.format(Math.round(diff / 365), 'year');
  }
  if (absDiff >= 30) {
    return formatter.format(Math.round(diff / 30), 'month');
  }
  if (absDiff >= 7) {
    return formatter.format(Math.round(diff / 7), 'week');
  }
  if (absDiff >= 1) {
    return formatter.format(Math.round(diff), 'day');
  }
  //Time formatting
  let timeDiff = diff * 24;
  let truncatedTimeDiff = Math.trunc(timeDiff);
  let absoluteTimeDiff = Math.abs(timeDiff);
  if (absoluteTimeDiff > 1) {
    return formatter.format(truncatedTimeDiff, 'hour');
  }
  timeDiff = timeDiff * 60;
  truncatedTimeDiff = Math.trunc(timeDiff);
  absoluteTimeDiff = Math.abs(timeDiff);
  if (absoluteTimeDiff > 1) {
    return formatter.format(truncatedTimeDiff, 'minute');
  }
  return 'just now';
}

export function formateTimeAgo(value: string): string {
  const date = wrangleMoment(value);
  return moment(date).fromNow();
}

export const compareISODates = (
  dateStr1: string,
  dateStr2: string,
): ?number => {
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);
  return isNaN(date1) || isNaN(date2) ? null : date1 - date2;
};

// This function takes a Date object as input and returns a formatted date string.
// Format the Date object as "YYYY-MM-DD" , this date format is accepted by HTML Date Picker for min max
export const formatDate = (date: Date): string => {
  return date.toISOString().split('T')[0];
};

// This function takes a date string and subtracts given numberOfDays from that Date and returns a formatted date string.
export const getSubtractedDays = (
  date: string,
  numberOfDays: number,
): string => {
  return moment(date).subtract(numberOfDays, 'd').format('YYYY-MM-DD');
};

export const getUTCStartOfDay = (
  date: string,
  timezone: string = momentTZ.tz.guess(),
): string => momentTZ.tz(date, timezone).startOf('d').utc().toISOString();

export const getUTCEndOfDay = (
  date: string,
  timezone: string = momentTZ.tz.guess(),
): string => momentTZ.tz(date, timezone).endOf('d').utc().toISOString();

const dateTime12HrsFormatter = new Intl.DateTimeFormat(undefined, {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
  hour12: true,
});

export function displayDate12HrsTime(
  date?: string | Date | moment$Moment,
): string {
  return dateTime12HrsFormatter.format(wrangleMoment(date));
}

export function formatMonthDay(monthDay: string): string {
  const date = parse(monthDay, 'MM-dd', new Date());
  return format(date, 'd MMMM');
}

export function convertToMonthDay(dateString: string): string {
  const date = parseISO(dateString);
  return format(date, 'dd MMM');
}

export const getTimein12HrsFormat = (time: string): string => {
  const format = 'HH:mm:ss';
  const momentTime = moment(time, format);
  return momentTime.format('h:mm A');
};

export const checkTime1LessThanTime2 = (
  time1: string,
  time2: string,
): boolean => {
  const format = 'HH:mm:ss';
  const t1 = moment(time1, format);
  const t2 = moment(time2, format);
  return t1.isBefore(t2);
};
