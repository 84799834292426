// @flow

import type {RouteLocation} from 'src/types/router';

import css from 'src/styles/common.css';


export const mobileWidthPx: number = parseInt(css.mobileWidth);
export const mobilePanelWidth: number = parseInt(css.mobilePanelWidth);

export const isMobile = (userAgent: string): boolean => /mobi/i.test(userAgent);
export const isAndroid = (userAgent: string): boolean =>
  /android/i.test(userAgent);

export const isMobileDevice = (userAgent: string): boolean => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => userAgent.match(toMatchItem));
};

export const getMobilePanelSize = (windowWidth: number): number =>
  Math.floor(windowWidth / mobilePanelWidth);
export const isMobileWindowWidth = (windowWidth: number): boolean =>
  windowWidth > 0 && windowWidth <= mobileWidthPx;

export const isChatAppLocation = (location: RouteLocation): boolean =>
  location.pathname.startsWith('/messages');

export const isChatAppOnMobile = (
  location: RouteLocation,
  userAgent: string,
): boolean => isChatAppLocation(location) && isMobile(userAgent);

// $FlowFixMe[value-as-type] [v1.32.0]
export const isStandalone = (windowObject: window): boolean =>
  windowObject
    ? Boolean(windowObject.matchMedia('(display-mode: standalone)').matches)
    : false;
