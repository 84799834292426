// @noflow

import typeof IndexStore from 'src/stores';
import type {Event} from 'src/api-parsers';

import React, {PureComponent} from 'react';
import {Link} from 'src/rerouter';

import {updateBranchEvent} from 'src/actions/event-creation';
import {pushModal} from 'src/action-creators/modal';
import * as urlFor from 'src/utils/url-for';

import Select from 'src/components/lib/select/select.jsx';

import css from './event.css';


const BranchEvent = ({
  store,
  eventId,
  workflowId,
  workflowEvents,
}: {
  store: IndexStore,
  eventId?: string,
  workflowId: string,
  workflowEvents: Event[],
}) => {
  const event = eventId && workflowEvents.find(({id}) => id === eventId);
  return (
    <div className={css.event}>
      {event ? (
        <div className={css.text}>
          <div>{event.title}</div>
          <Link className={css.link} to={urlFor.event(workflowId, event)}>
            Edit Touchpoint
          </Link>
        </div>
      ) : (
        <BranchEventNew store={store} workflowEvents={workflowEvents} />
      )}
    </div>
  );
};

export default BranchEvent;

class BranchEventNew extends PureComponent<{
  // TODO(marcos): cleanup to pass handleChange to BranchEvent from container.jsx
  store: IndexStore,
  workflowEvents: Event[],
}> {
  render() {
    const options = this.props.workflowEvents.map(({title, id}) => ({
      value: id,
      label: title,
    }));
    options.push({value: 'new', label: 'Create new +'});
    return (
      <Select
        onChange={this.handleChange}
        options={options}
        clearable={false}
      />
    );
  }

  handleChange = ({value}) => {
    const {store} = this.props;
    const {dispatch} = store.reduxStore;
    if (value === 'new') {
      dispatch(pushModal({type: 'CREATE_EVENT'}));
    } else if (value) {
      updateBranchEvent(store, value);
    }
  };
}
