// @flow strict-local

import type {Dispatch, ThunkAction} from 'src/reducers';

import * as reduxApi from 'src/utils/redux-api-v2';


export const getValidAgencies = (
  email: string,
  password: string,
): ThunkAction<mixed> => (dispatch: Dispatch) =>
  dispatch(reduxApi.post('auth/valid-agencies', {email, password}));
