// @flow
import type {Modal, TitleBarDetailsModal} from 'src/action-creators/modal';
import type {ModalType} from 'src/components/modals/modal-root.jsx';

import * as React from 'react';

import {classify} from 'src/utils/index';
import useAutofocus from 'src/hooks/useAutofocus';

import Button from 'src/components/lib/new-button';
import HoverIconButton from 'src/components/lib/hover-icon-button';

import CancelIcon from 'src/images/icons/cancel-icon.svg?noAttrs';
import {GenericBaseDialog} from 'src/components/common/reports/genesis-base-dialog.jsx';
import css from './base-modal.css';

/**
 * NOTE(iris): this is the barest bone of a modal
 * also, removing the modal will be handled at these base levels instead of by
 * specific components using BaseModal or TitleBarModal except when removing
 * the modal by clicking the background via allowClickAway
 */
const BaseModal = (
  props: Modal | TitleBarDetailsModal,
): React.Element<'div'> | React.Node => {
  const {
    className,
    children,
    showError,
    errorText,
    abortText,
    confirmText,
    confirmStyle,
    handleAbort,
    handleConfirm,
    removeModal,
    modalVersion,
  } = props;
  const confirmButtonRef = useAutofocus();

  if (modalVersion === 'genesis') {
    return <GenericBaseDialog {...props} />;
  }

  return (
    <div className={classify(className, css.container)}>
      {children}
      <div className={css.modalFooter}>
        {showError && <span className={css.errorText}>{errorText}</span>}
        <div className={css.buttonMenu}>
          {abortText && (
            <Button
              type="default"
              className={css.abortButton}
              onClick={(event) => {
                handleAbort && handleAbort(event);
                removeModal && removeModal();
              }}
              data-qa-id="modal-abort-button"
            >
              {abortText}
            </Button>
          )}
          {confirmText && (
            <Button
              type={confirmStyle || 'primary'}
              onClick={(event) => {
                handleConfirm && handleConfirm(event);
                removeModal && removeModal();
              }}
              ref={confirmButtonRef}
              data-qa-id="modal-confirm-button"
            >
              {confirmText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const modalColors: {[type: ModalType]: string, ...} = {
  GENERIC_ERROR: css.modalRed,
  GENERIC_SUCCESS: css.modalGreen,
  GENERIC_MODAL: css.modalOrange,
  WORKFLOW_DELETE: css.modalOrange,
  EVENT_DELETE: css.modalOrange,
};

/**
 * NOTE(iris): this modal has click overlay to dismiss on by default
 */
export const TitleBarModal = (
  props: TitleBarDetailsModal,
): React.Element<'div'> => {
  const {
    className,
    type,
    title = 'Important Information',
    titleIcon,
    hasTitleXButton = true,
    text,
    details,
    handleAbort,
    removeModal,
    children,
  } = props;

  const baseModalClassName = className
    ? classify(css.titleBarModal, className)
    : css.titleBarModal;

  const titleBarColor =
    (typeof type === 'string' && modalColors[type]) || css.colorGray6;

  return (
    <div className={css.titleBarContainer}>
      {title && (
        <div className={css.titleBar} style={{backgroundColor: titleBarColor}}>
          <div className={css.titleAndIcon}>
            <span className={css.titleIcon}>{titleIcon}</span>
            <div className={css.title}>{title}</div>
          </div>
          {hasTitleXButton && (
            <HoverIconButton className={css.closeXHoverButton}>
              <CancelIcon
                className={css.closeXIcon}
                onClick={(event) => {
                  handleAbort && handleAbort(event);
                  removeModal && removeModal();
                }}
              />
            </HoverIconButton>
          )}
        </div>
      )}
      <BaseModal {...props} className={baseModalClassName}>
        {
          // $FlowFixMe[sketchy-number-and]
          text && <div className={css.text}>{text}</div>
        }
        {children}
        {
          // $FlowFixMe[sketchy-number-and]
          details && <div className={css.details}>{details}</div>
        }
      </BaseModal>
    </div>
  );
};

export default BaseModal;
