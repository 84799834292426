// @noflow
import Store from './base';


export default class AudienceMemberSetStore extends Store {
  state: {
    [key: string]: string[],
  };

  constructor() {
    super('audience-member-sets');

    this.state = {};
  }

  get(key: string): string[] {
    return this.state[key];
  }

  set(key: string, audienceMemberIds: string[]) {
    this.updateState({
      [key]: {
        $set: audienceMemberIds,
      },
    });
  }
}
