// @flow

import type {
  AudienceEntity,
  AudienceResponse,
} from 'src/types/multientity-audience';
import type {EntityType} from 'src/types/ats-entities';
import type {GetState, Dispatch, ThunkAction} from 'src/reducers';
import type {EntitySummary} from 'src/types/entity-summaries';

import {camel} from 'src/utils';
import {thunkify as flow} from 'src/utils/thunks';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api-v2';

import {selectRelatedEntityIds} from 'src/selectors/multientity-audience';


export const RECEIVE_ME_AUDIENCE_PAGE = 'audienceMembers/me/receivePage';
export const RECEIVE_ME_AUDIENCE_ENTITY = 'audienceMembers/me/receiveEntity';
export const RECEIVE_ME_WORKFLOW_AUDIENCE_PAGE =
  'audienceMembers/me/receiveWorkflowPage';
export const RECEIVE_ME_RELATED_ENTITIES = 'audienceMembers/me/receiveRelated';
export const RECEIVE_ME_VARIABLES = 'audienceMembers/me/receiveEntityVariables';
export const RECEIVE_PERSON_ENTITY_SUMMARY =
  'audienceMembers/me/receivePersonEntitySummary';
export const RECEIVE_ENTITY_SUMMARY = 'audienceMembers/me/receiveEntitySummary';

export type ReceiveMEAudiencePageAction = {
  type: 'audienceMembers/me/receivePage',
  payload: {
    entity: string,
    page: number,
    data: AudienceResponse,
  },
};

export type ReceiveEntitySummaryAction = {
  type: 'audienceMembers/me/receiveEntitySummary',
  payload: {
    entityType: EntityType,
    data: EntitySummary,
  },
};

type IncomingEntity = *;

export type ReceiveMEAudienceEntityAction = {
  type: 'audienceMembers/me/receiveEntity',
  payload: {
    entity: AudienceEntity,
    incoming_related_entity_ids: IncomingEntity[],
  },
};

export type ReceiveMEWorkflowAudienceEntityAction = {
  type: 'audienceMembers/me/receiveWorkflowPage',
  payload: {
    page: number,
    workflowId: string,
    data: AudienceResponse,
  },
};

export type RelatedEntity = {
  primary_entity: {
    agency_id: string,
    id: string,
    external_source_id: string,
    external_source_type: 'bullhorn',
    data: {},
  },
  related_entities: *,
};

export type ReceiveMERelatedEntitiesAction = {
  type: 'audienceMembers/me/receiveRelated',
  payload: {
    entity_groups: {[entityId: string]: RelatedEntity},
    type: string,
  },
};

export type ReceiveEntityVariableValuesAction = {
  type: 'audienceMembers/me/receiveEntityVariables',
  payload: {
    entityVariables: {[variable: string]: ?string | ?number | ?boolean},
    type: string,
    personEntityId: ?string,
  },
};

export type MultiEntityAudienceActions =
  | ReceiveMEAudiencePageAction
  | ReceiveMEAudienceEntityAction
  | ReceiveMEWorkflowAudienceEntityAction
  | ReceiveMERelatedEntitiesAction
  | ReceiveEntityVariableValuesAction
  | ReceiveEntitySummaryAction;

export const receiveMEAudiencePage = (
  page: number,
  entity: string,
  response: AudienceResponse,
): ReceiveMEAudiencePageAction => ({
  type: RECEIVE_ME_AUDIENCE_PAGE,
  payload: {
    entity,
    page,
    data: response,
  },
});

export const receiveEntitySummary = (
  data: EntitySummary,
  entityType: EntityType,
): ReceiveEntitySummaryAction => ({
  type: RECEIVE_ENTITY_SUMMARY,
  payload: {
    entityType,
    data,
  },
});

export const receiveMEAudienceEntity = (
  payload: *,
): ReceiveMEAudienceEntityAction => ({
  type: RECEIVE_ME_AUDIENCE_ENTITY,
  payload: {
    entity: camel(payload, false),
    incoming_related_entity_ids: payload.incoming_related_entity_ids,
  },
});

export const receiveMEWorkflowAudiencePage = (
  page: number,
  workflowId: string,
  response: AudienceResponse,
): ReceiveMEWorkflowAudienceEntityAction => ({
  type: RECEIVE_ME_WORKFLOW_AUDIENCE_PAGE,
  payload: {
    page,
    workflowId,
    data: response,
  },
});

const receiveBatchEntities = (
  entity_groups,
  type: string,
): ReceiveMERelatedEntitiesAction => ({
  type: RECEIVE_ME_RELATED_ENTITIES,
  payload: {
    type,
    entity_groups,
  },
});

const receivePersonEntitySummary = (json) => ({
  type: RECEIVE_PERSON_ENTITY_SUMMARY,
  payload: json,
});

const receiveEntityVariableValues = (
  entityVariables,
  type: string,
  personEntityId: string,
): ReceiveEntityVariableValuesAction => ({
  type: RECEIVE_ME_VARIABLES,
  payload: {
    type,
    entityVariables,
    personEntityId,
  },
});

// TODO(marcos): change this to use query params for cached once
// this relies on more than "page"
export const getMultiEntityAudienceMemberPage: (
  page: number,
  type: EntityType,
  count?: number,
) => ThunkAction<mixed> = flow(
  key((page, type, _count) => `meAudienceMemberPage/${type}/${page}`),
  cached((json, page, type) =>
    receiveMEAudiencePage(page, type, camel(json, true, ['data'])),
  ),
  fetching(),
)((page: number, type: EntityType, count: number = 25) =>
  reduxApi.get(`entities/${type}/summaries`, {load_data: true, page, count}),
);

export const getMultiEntityAudienceMember: (
  id: string,
  type: EntityType,
) => ThunkAction<mixed> = flow(
  key((id) => `meAudienceMember/${id}`),
  cached((json) => receiveMEAudienceEntity(json)),
  fetching(),
)((id: string, type: EntityType) => reduxApi.get(`entities/${type}/${id}`));

export const getBatchEntities: (
  ids: string[],
  type: string,
) => ThunkAction<mixed> = flow(
  key((ids, type) => `meBatchEntity:${type}`),
  cached((json, ids, type) => receiveBatchEntities(json, type), {
    hash: (ids: string[], type: string) =>
      `meBatchEntity:${type}:${ids.join(',')}`,
  }),
  fetching(),
)((entity_ids, type) =>
  reduxApi.post(`entities/${type}/batch-details/summaries`, {
    entity_ids,
  }),
);

export const getPersonEntitySummary: (
  type: string,
  id: string,
) => ThunkAction<mixed> = flow(
  key((type: string, id: string) => `personEntitySummary/${type}/${id}`),
  cached(receivePersonEntitySummary),
  fetching(),
)((type: string, id: string) =>
  reduxApi.get(`entities/${type}/${id}/person-entity-summary`),
);

export const getEntitySummary: (
  EntityType,
  string,
) => ThunkAction<mixed> = flow(
  key(
    (entityType: EntityType, externalSourceId: string) =>
      `entitySummary/${entityType}/${externalSourceId}`,
  ),
  cached(receiveEntitySummary),
  fetching(),
)((entityType: EntityType, externalSourceId: string) =>
  reduxApi.get(
    `entities/${entityType}/external-source-id/${externalSourceId}/summary`,
  ),
);

export const getEntityVariableValues: (
  string,
  string,
) => ThunkAction<mixed> = flow(
  key((personEntityId, type) => `meEntityVariables:${type}`),
  cached(
    (json, personEntityId, type) =>
      receiveEntityVariableValues(json, type, personEntityId),
    {
      hash: (personEntityId: string, type: string) =>
        `meEntityVariables:${type}:${personEntityId}`,
    },
  ),
  fetching(),
)((personEntityId: string, type: string) =>
  reduxApi.get(`entities/${type}/${personEntityId}/data`),
);

export const updateAudienceOptOut = (
  entityType: EntityType,
  entityId: string,
  variable: string,
  params: {
    opt_out: boolean,
    value: string,
  },
): ThunkAction<mixed> => (dispatch: Dispatch) =>
  dispatch(
    reduxApi.put(
      `entities/${entityType}/${entityId}/opt-out/${variable}`,
      params,
    ),
  );

export const fetchPersonRelatedEntities = (
  personEntityId: string,
  relatedEntityType: EntityType,
  personEntityType: EntityType,
): ThunkAction<> => async (dispatch: Dispatch, getState: GetState) => {
  await dispatch(
    getMultiEntityAudienceMember(personEntityId, personEntityType),
  );
  const ids = selectRelatedEntityIds(
    getState(),
    relatedEntityType,
    personEntityId,
  );
  await dispatch(getBatchEntities(ids, relatedEntityType));
  if (personEntityId && personEntityType) {
    await dispatch(getEntityVariableValues(personEntityId, personEntityType));
  }
};

export const getMultiEntityAudienceMembersForWorkflow: (
  workflowId: string,
  page: string,
) => ThunkAction<> = flow(
  key((workflowId, page) => `meWorkflowAudience/${workflowId}/${page}`),
  cached(
    (json, workflowId, page) =>
      receiveMEWorkflowAudiencePage(
        page,
        workflowId,
        camel(json, true, ['data']),
      ),
    {ttl: 500},
  ),
  fetching(),
)((workflowId, page) =>
  reduxApi.get(`workflows/${workflowId}/entities/summaries`, {page}),
);
