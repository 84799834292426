// @noflow
import type {Branch, BranchType} from 'src/types/survey';
import type {ScheduleDef} from 'src/stores/scheduling';
import typeof IndexStore from 'src/stores/index';

import sculpt from 'sculpt';
import uniqueId from 'lodash/uniqueId';
import indexOf from 'lodash/indexOf';

import logger from 'src/utils/logger';

import {updateEvent, questionPrototypes} from 'src/actions/event';
import {saveBranch} from 'src/actions/event-creation';


export function updateBranchEventSchedule(
  store: IndexStore,
  eventId: string,
  schedule: ScheduleDef,
) {
  const branch = store.branches.getBranchByEventId(eventId);
  const apiSchedule = store.scheduling.normalizeBranchedEventSchedule(schedule);

  if (branch) {
    store.branches.receiveBranch(
      sculpt(branch, {
        event: {
          schedule: {
            $set: apiSchedule,
          },
        },
      }),
    );

    if (!store.branches.isNew(branch.id)) {
      const parentEvent = store.events.getEvent(branch.parentEventId);
      updateEvent(store, parentEvent)
        .then(() => {
          const error = store.events.getError();
          if (
            error &&
            indexOf(error.response.body.errors, 'InvalidEvent') > -1
          ) {
            alert(
              'Oops, there was an error saving your touchpoint, Please check the settings.',
            );
            store.events.clearError();
          }
        })
        .catch((error) => {
          logger.error('event getEvent error: ', error.stack || error);
        });
    }
  }
}

const moduleBranch = {
  id: '',
  branchType: 'module_branch',
  name: 'Module Branch',
  questions: [],
  responseIn: [''],
};

const eventBranch = {
  id: '',
  branchType: 'event_branch',
  name: 'Event Branch',
  responseIn: [''],
  event: {},
};

const BranchPrototype = {
  for: (branchType, branchIndex) => {
    switch (branchType) {
      case 'module_branch':
        return {
          ...moduleBranch,
          id: uniqueId('new_branch_'),
          name: moduleBranch.name + ` ${branchIndex}`,
        };
      case 'event_branch':
        return {
          ...eventBranch,
          id: uniqueId('new_branch_'),
          name: eventBranch.name + ` ${branchIndex}`,
        };
      default:
        throw 'prototype not found!';
    }
  },
};

export function newBranch(
  store: IndexStore,
  questionId: string,
  branchType: BranchType,
) {
  const {eventCreation} = store;
  const branch = BranchPrototype.for(branchType, 1);

  // TODO (kyle): find out if necessary
  //store.branches.receiveBranch(branch);

  eventCreation.updateState({
    branchInfo: {
      $set: {
        branch,
        questionId,
        editorBranch: branch,
        isNew: true,
      },
    },
  });
}

export function openBranch(
  store: IndexStore,
  questionId: string,
  branch: Branch,
) {
  store.eventCreation.setState({
    branchInfo: {
      questionId,
      editorBranch: null,
      branch,
    },
  });
}

export function closeBranch(store: IndexStore) {
  store.eventCreation.setState({
    branchInfo: null,
  });
}

export function editBranch(store: IndexStore) {
  store.eventCreation.updateState({
    branchInfo: {
      editorBranch: {
        $set: store.eventCreation.state.branchInfo.branch,
      },
    },
  });
}

export function updateBranchSettings(store: IndexStore, spec: Object) {
  const newBranch = sculpt(
    store.eventCreation.state.branchInfo.editorBranch,
    spec,
  );

  newBranch.name = resolveBranchName(newBranch);

  store.eventCreation.updateState({
    branchInfo: {
      editorBranch: {
        $set: newBranch,
      },
    },
  });
}

export function resolveBranchName(branch: Branch): string {
  let name;
  if (branch.responseLte && branch.responseGte) {
    const sorted = [branch.responseLte, branch.responseGte].sort(
      (a, b) => parseInt(a) - parseInt(b),
    );
    branch.responseGte = sorted[0];
    branch.responseLte = sorted[1];
    name = `${branch.responseGte} <=> ${branch.responseLte}`;
  } else if (branch.responseGte) {
    name = `>= ${branch.responseGte}`;
  } else if (branch.responseLte) {
    name = `<= ${branch.responseLte}`;
  } else if (branch.responseIn) {
    name = branch.responseIn.join(', ');
  } else {
    name = 'New Module';
  }
  return name;
}

export function cancelBranch(store: IndexStore) {
  const {eventCreation} = store;
  const {isNew} = eventCreation.state.branchInfo;

  let spec;
  if (isNew) {
    spec = {
      branchInfo: {
        $set: null,
      },
    };
  } else {
    spec = {
      branchInfo: {
        editorBranch: {
          $set: null,
        },
      },
    };
  }

  store.eventCreation.updateState(spec);
}

type QuestionType = string;
export function addBranchModule(store: IndexStore, type: QuestionType) {
  const {eventCreation} = store;
  const {branch} = eventCreation.state.branchInfo;
  const newQuestion = {
    ...questionPrototypes(store)[type],
    id: uniqueId('new:'),
    isNew: true,
  };
  if (
    branch.responseIn &&
    branch.responseIn.length === 1 &&
    branch.responseIn[0] === ''
  ) {
    saveBranch(store);
  }
  store.eventCreation.updateState({
    branchInfo: {
      branch: {
        questions: {
          $push: newQuestion,
        },
      },
    },
    question: {
      $set: newQuestion,
    },
    questionError: {
      $set: null,
    },
  });
}

export function duplicateBranchModule(store: IndexStore) {
  const {branch} = store.eventCreation.state.branchInfo;

  const lastModule = branch.questions[branch.questions.length - 1];

  if (!lastModule) {
    return;
  }

  const newQuestion = {
    ...lastModule,
    id: uniqueId('new:'),
    isNew: true,
  };

  store.eventCreation.updateState({
    branchInfo: {
      branch: {
        questions: {
          $push: newQuestion,
        },
      },
    },
    question: {
      $set: newQuestion,
    },
    questionError: {
      $set: null,
    },
  });
}
