// @flow strict
import * as React from 'react';
import {
  SubTitleLarge,
  BodyLarge,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {CONSTANTS} from 'src/components/authentication/contants.js';

import ResetImage from 'src/images/reset-phone-icon.svg';
import css from './phone-reset-confirmation.css';


const PhoneResetConfirmation = (): React.Node => {
  return (
    <div className={css.PhoneResetConfirmationPage}>
      <ResetImage />
      <SubTitleLarge className={css.titleText}>
        {CONSTANTS.PHONE_RESET_TITLE}
      </SubTitleLarge>
      <BodyLarge className={css.infoText}>
        {CONSTANTS.PHONE_RESET_SUBTEXT}
      </BodyLarge>
    </div>
  );
};

export default PhoneResetConfirmation;
