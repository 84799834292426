// @noflow

import type {Dimensions} from 'src/components/lib/charts/with-chart-dimensions.jsx';

import * as React from 'react';
import {classify} from 'src/utils';
import {MouseTip} from 'src/components/lib/mouse-tip/mouse-tip.jsx';

import css from './axis.css';


type Props = {
  axisLabels?: string[],
  className?: string,
  notPercent?: boolean,
  // @TODO(elliot): Add more orientation options.
  orientation: 'bottom' | 'left',
  scale: Function,
  showAllLabels?: boolean,
  showAxisLine?: boolean,
  showTicks?: boolean,
  dimensions: Dimensions,
  ticks: Array<string | number>,
  title?: ?string,
};

const tickPosition = {
  bottom: 'left',
  left: 'top',
};

class Axis extends React.PureComponent<Props> {
  render() {
    const {
      axisLabels,
      className,
      notPercent,
      orientation,
      scale,
      showAllLabels,
      showTicks,
      ticks,
      title,
      customTick,
      getTooltipText,
    } = this.props;
    return (
      <div className={classify(css[`${orientation}Axis`], className)}>
        {title && <div className={css[`${orientation}Label`]}>{title}</div>}
        {ticks.map((tick, i) => {
          if (!showAllLabels && i === 0) {
            return null;
          }
          // NOTE(elliot): If band scale, the tick should be in the center of the band.
          const position = scale.bandwidth
            ? centerTick(scale, tick)
            : scale(tick);
          const content = (
            <div
              className={css[`${orientation}Tick`]}
              key={tick}
              style={{[tickPosition[orientation]]: position}}
            >
              {showTicks && <div className={css[`${orientation}TickLine`]} />}
              <div className={css[`${orientation}TickValue`]}>
                {customTick
                  ? customTick(tick)
                  : getTickValue(notPercent, axisLabels, tick)}
              </div>
            </div>
          );
          return getTooltipText ? (
            <MouseTip key={tick} content={getTooltipText(tick)}>
              {content}
            </MouseTip>
          ) : (
            content
          );
        })}
      </div>
    );
  }
}

export default Axis;

const getTickValue = (
  notPercent?: boolean,
  axisLabels?: string[],
  tick: any,
) => {
  if (axisLabels) {
    return axisLabels[tick];
  }
  if (!notPercent) {
    return Math.round(tick * 100);
  }
  return tick;
};

const centerTick = (scale: Function, tick: any) => {
  let offset = Math.max(0, scale.bandwidth() - 1) / 2;
  if (scale.round()) {
    offset = Math.round(offset);
  }
  return scale(tick) + offset;
};

const labelPosition = {
  top: {
    textAnchor: 'middle',
    dominantBaseline: 'text-after-edge',
  },
  bottom: {
    textAnchor: 'middle',
    dominantBaseline: 'text-before-edge',
  },
  left: {
    textAnchor: 'end',
    dominantBaseline: 'middle',
  },
  right: {
    textAnchor: 'start',
    dominantBaseline: 'middle',
  },
};

type TickTextContentProps = {
  x: number,
  y: number,
  y: number,
  tickLabelFontSize: number,
  orientation: 'top' | 'bottom' | 'left' | 'right',
  customTick: () => string,
  axisLabels: string[] | {[number]: string},
};

const TICK_LABEL_GAP = 20;

const TickTextContent = ({
  x,
  y,
  tickLabelFontSize,
  orientation,
  tickLabelProps,
  customTick,
  tick,
  axisLabels,
  notPercent,
}: TickTextContentProps) => {
  const tickValue = customTick
    ? customTick(tick)
    : getTickValue(notPercent, axisLabels, tick);

  let tickValueTop = tickValue,
    tickValueBottom;

  if (axisLabels && Array.isArray(tickValue)) {
    [tickValueTop, tickValueBottom] = tickValue;
  }

  const tickTextCommonProps = {
    fill: css.colorGray5,
    fontSize: tickLabelFontSize,
    ...labelPosition[orientation],
    ...tickLabelProps,
  };

  return (
    <>
      <text
        x={x}
        y={y}
        style={{
          transformOrigin: `${x}px ${y}px`,
        }}
        key={`${tick}-top`}
        {...tickTextCommonProps}
      >
        {tickValueTop}
      </text>
      {tickValueBottom && (
        <text
          x={x}
          y={y + TICK_LABEL_GAP}
          style={{
            transformOrigin: `${x}px ${y + TICK_LABEL_GAP}px`,
          }}
          key={`${tick}-bottom`}
          {...tickTextCommonProps}
        >
          {tickValueBottom}
        </text>
      )}
    </>
  );
};
type SvgProps = Props & {
  orientation: 'top' | 'bottom' | 'left' | 'right',
  tickLabelFontSize: number,
  tickLabelProps?: Object,
  tickLabelPadding: number,
  tickLength: number,
  tickProps?: Object,
  tickTransform?: string,
  titleAlign: 'center' | 'start' | 'end',
  titlePadding: number,
  titleProps?: Object,
};

export class SvgAxis extends React.PureComponent<SvgProps> {
  axis: ?Element;

  static defaultProps = {
    tickLabelFontSize: 12,
    tickLength: 5,
    tickLabelPadding: 5,
    titleAlign: 'center',
    titlePadding: 36,
  };

  getSize() {
    return this.axis ? this.axis.getBBox() : null;
  }

  getAxisTransform(): string {
    const {orientation, dimensions} = this.props;
    const orientationToTransform = {
      bottom: `translate(${dimensions.left}, ${dimensions.bottom})`,
      top: `translate(${dimensions.left}, ${dimensions.top})`,
      left: `translate(${dimensions.left}, ${dimensions.top})`,
      right: `translate(${dimensions.right}, ${dimensions.top})`,
    };
    return orientationToTransform[orientation];
  }

  render() {
    const {
      axisLabels,
      notPercent,
      orientation,
      scale,
      showAllLabels,
      showAxisLine,
      showTicks,
      tickLabelFontSize,
      tickLabelProps,
      tickLabelPadding,
      tickProps,
      ticks,
      title,
      titleProps,
      customTick,
      getTooltipText,
    } = this.props;
    const isHorizontal = orientation === 'bottom' || orientation === 'top';
    const isLeft = orientation === 'left';
    const isTop = orientation === 'top';
    const tickLength = showTicks ? this.props.tickLength : 0;
    const tickDirection = isLeft || isTop ? -1 : 1;

    const transform = this.getAxisTransform();
    const range = scale.range();
    const axisStart = {
      x: isHorizontal ? range[0] : 0,
      y: isHorizontal ? 0 : range[0],
    };
    const axisEnd = {
      x: isHorizontal ? range[1] : 0,
      y: isHorizontal ? 0 : range[1],
    };
    const titlePosition = getAxisTitlePosition({
      ...this.props,
      tickDirection,
      axisStart,
      axisEnd,
      isHorizontal,
    });

    return (
      <g
        className={css.axis}
        transform={transform}
        ref={(ref: Element) => (this.axis = ref)}
      >
        <line
          // NOTE(elliot): Line is drawn when `showAxisLine` is false so
          // it is included when the size of the axis is calculated.
          x1={axisStart.x}
          y1={axisStart.y}
          x2={axisEnd.x}
          y2={axisEnd.y}
          stroke={showAxisLine ? css.colorGray9 : null}
        />
        {ticks.map((tick, i) => {
          if (!showAllLabels && i === 0) {
            return null;
          }
          const position = scale.bandwidth
            ? centerTick(scale, tick)
            : scale(tick);
          const tickStart = {
            x: isHorizontal ? position : 0,
            y: isHorizontal ? 0 : position,
          };
          const tickEnd = {
            x: isHorizontal ? position : tickDirection * tickLength,
            y: isHorizontal ? tickLength * tickDirection : position,
          };
          const tickLabelPosition = {
            x:
              tickEnd.x +
              (!isHorizontal ? tickDirection * tickLabelPadding : 0),
            y:
              tickEnd.y + (isHorizontal ? tickDirection * tickLabelPadding : 0),
          };
          const content = (
            <g key={tick} {...tickProps}>
              {showTicks && (
                <line
                  x1={tickStart.x}
                  y2={tickEnd.y}
                  y1={tickStart.y}
                  x2={tickEnd.x}
                  stroke={css.colorGray9}
                />
              )}
              <TickTextContent
                x={tickLabelPosition.x}
                y={tickLabelPosition.y}
                style={{
                  transformOrigin: `${tickLabelPosition.x}px ${tickLabelPosition.y}px`,
                }}
                fill={css.colorGray5}
                fontSize={tickLabelFontSize}
                orientation={orientation}
                tickLabelProps={tickLabelProps}
                customTick={customTick}
                tick={tick}
                notPercent={notPercent}
                axisLabels={axisLabels}
              />
            </g>
          );

          return getTooltipText ? (
            <MouseTip key={tick} content={getTooltipText(tick)}>
              {content}
            </MouseTip>
          ) : (
            content
          );
        })}
        {title && (
          <text
            style={{
              // NOTE(elliot): Need to rotate the origin of transformations
              // to center of the text.
              transformOrigin: `${titlePosition.x}px ${titlePosition.y}px`,
            }}
            transform={!isHorizontal ? `rotate(${tickDirection * 90})` : null}
            fill={css.colorGray5}
            fontSize={14}
            textAnchor="middle"
            dominantBaseline="middle"
            {...titlePosition}
            {...titleProps}
          >
            {title}
          </text>
        )}
      </g>
    );
  }
}

const getAxisTitlePosition = ({
  tickDirection,
  titleAlign,
  titlePadding,
  axisStart,
  axisEnd,
  isHorizontal,
}: SvgProps & {
  tickDirection: number,
  axisStart: {x: number, y: number},
  axisEnd: {x: number, y: number},
  isHorizontal: boolean,
}): {x: number, y: number} => {
  const position = {
    x: 0,
    y: 0,
  };
  const mainAxis = isHorizontal ? 'x' : 'y';
  const crossAxis = isHorizontal ? 'y' : 'x';
  const start = Math.min(axisStart[mainAxis], axisEnd[mainAxis]);
  const end = Math.max(axisStart[mainAxis], axisEnd[mainAxis]);
  if (titleAlign === 'center') {
    position[mainAxis] = end / 2;
  }
  if (titleAlign === 'start') {
    position[mainAxis] = start;
  }
  if (titleAlign === 'end') {
    position[mainAxis] = end;
  }
  position[crossAxis] = axisEnd[crossAxis] + tickDirection * titlePadding;

  return position;
};
