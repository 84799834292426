// @flow strict

import type {DelayNodeState} from 'src/types/automation-workflow-scheduling';
import type {DelayAction} from 'src/action-creators/delay.js';

import {
  UPADTE_DELAY_NODE_SCHEDULING,
  RECEIVE_DELAY_NODE_SCHEDULING,
} from 'src/action-creators/delay.js';


const initialState = {
  nodeId: null,
  days: 0,
  hours: 0,
  minutes: 0,
};

export default (
  state: DelayNodeState = initialState,
  action: DelayAction,
): DelayNodeState => {
  switch (action.type) {
    case UPADTE_DELAY_NODE_SCHEDULING:
      return {
        ...state,
        ...action.payload,
      };
    case RECEIVE_DELAY_NODE_SCHEDULING:
      return {
        ...state,
        ...action.payload,
      };
  }
  return state;
};
