// @noflow

import type {ContactMap} from 'src/types/contacts';
import type {AudienceMemberMap} from 'src/types/audience-member';
import type {
  Thread,
  InboxThread,
  Message,
  MessagePending,
  InboxFilter,
} from 'src/types/messages';
import type {Broadcast} from 'src/types/broadcasts';
import type {State} from 'src/reducers';

import {createSelector} from 'reselect';
import _get from 'lodash/get';
import values from 'lodash/values';

import {emptyArray, shallowEqual} from 'src/utils';
import {compareTimestamps} from 'src/utils/date-time';
import {MSG_DIRECTION_INCOMING} from 'src/types/messages';
import {getThreads} from 'src/selectors/threads';
import {selectPhoneNumberSetFromDefaultPhoneNumberSet} from 'src/selectors/chat';
import {selectCurrentQueue} from 'src/selectors/messaging-queues';
import {selectLATEnabled} from 'src/hooks/product-flags';
import {getCurrentAgent} from 'src/selectors/accounts';
import {
  META_INCOMING,
  META_OUTGOING,
  META_SENT_BROADCAST,
  META_SCHEDULED_BROADCAST,
  META_CORE_MESSAGES,
} from 'src/components/messaging/thread-metadata-utils.js';


const SAMPLING_CONSTANT = 5n;

export const isAgentSelectedForAmplitudeTracking = (state: State) => {
  const {id} = getCurrentAgent(state);
  const parsedId = id && BigInt(id);
  return parsedId && parsedId % SAMPLING_CONSTANT === 0n;
};

export const getMessages = (state: State): {[string]: Message} =>
  state.messages.messages;

export const selectMessagesAsArray = createSelector(
  getMessages,
  // $FlowIssue
  (messages): Message[] =>
    Object.values(messages)
      .sort(compareMessagesByTime)
      .filter(
        (message) =>
          (message.media && message.media.length > 0) ||
          (message.body && message.body.length > 0),
      ),
);

export const selectInboxFilter = (state: State) => state.messages.inboxFilter;
export const selectInboxOwnerFilter = (state: State) =>
  state.messages.inboxFilterOwnedBy;

export const getThreadById = (state: State, threadId: string) =>
  getThreads(state)[threadId];

export const selectThreadByPhone = createSelector(
  getThreads,
  selectPhoneNumberSetFromDefaultPhoneNumberSet,
  (state, phone) => phone,
  (threads, phoneNumberSetIdVal, phone): ?Thread =>
    // $FlowIssue
    Object.values(threads).find(
      (thread) =>
        thread.externalPhone === phone &&
        thread.phoneNumberSetId === phoneNumberSetIdVal,
    ),
);

export const selectPopulatedThreadById = createSelector(
  getThreadById,
  (state) => state.contacts.contacts,
  (state) => state.audienceMembers.audienceMembers,
  (thread, contacts, audienceMembers) => ({
    thread,
    contact: getContactForThread(thread, contacts),
    audienceMembers: getAudienceMembersForThread(thread, audienceMembers),
  }),
);

export const selectContextThread = (state, contextEvent, externalPhone) => {
  const threads = values(state.messages.threads);
  const agentsById = state.accounts?.data;
  const contextThread = contextEvent
    ? threads.find(
        (thread) =>
          thread.phoneNumberSetId ===
            contextEvent?.metadata?.phoneNumberSetId &&
          thread.externalPhone === externalPhone,
      )
    : false;
  return contextThread
    ? {
        ...contextThread,
        agents: contextThread.agentIds
          .map((id) => agentsById[id])
          .filter((agent) => Boolean(agent)),
      }
    : false;
};

export const selectThreadsByIds = createSelector(
  getThreads,
  (_, threadIds) => threadIds,
  (threads, threadIds) => threadIds.map((threadId) => threads[threadId]),
);

// TODO (kyle): i no longer like this pattern of augmenting these core objects
// like Thread.  we should instead create a new object containing `thread` and
// `contact` and `audienceMembers`.
export const selectPopulatedThreadsByIds = createSelector(
  (state) => state.contacts.contacts,
  (state) => state.audienceMembers.audienceMembers,
  selectThreadsByIds,
  (contacts, audienceMembers, threads) =>
    threads.map((thread) => ({
      ...thread,
      contact: getContactForThread(thread, contacts),
      audienceMembers: getAudienceMembersForThread(thread, audienceMembers),
    })),
);

const getPendingMessages = (state) => state.messages.pendingMessages;
const selectPendingMessagesArray = createSelector(
  getPendingMessages,
  (pendingMessages): MessagePending[] =>
    // $FlowIssue
    Object.values(pendingMessages).sort(compareMessagesByTime),
);
export const selectPendingMessagesForThread = createSelector(
  selectPendingMessagesArray,
  (state, threadId) => threadId,
  (pendingMessages, threadId) =>
    pendingMessages.filter(
      (pendingMessage) => pendingMessage.threadId === threadId,
    ),
);

const resultsMap = new Map();
const getMessagesForThread = (
  messages: Message[],
  threadId: string,
): Message[] => {
  if (!threadId) {
    return [];
  }

  const oldMessages = resultsMap.get(threadId);
  const threadMessages = messages.filter(
    (message) => !message.isKeywordSearch && message.threadId === threadId,
  );
  if (oldMessages && shallowEqual(oldMessages, threadMessages)) {
    return oldMessages;
  }
  // TODO (kyle): cull this map?
  resultsMap.set(threadId, threadMessages);
  return threadMessages;
};

export const selectMessagesForThread = createSelector(
  selectMessagesAsArray,
  (state, threadId: string) => threadId,
  getMessagesForThread,
);

export const selectMostRecentMessageInThread = createSelector(
  selectMessagesForThread,
  (messages) => messages[0],
);

const getScheduledMessages = (state) => state.broadcasts.map;
export const selectScheduledMessagesArray = createSelector(
  getScheduledMessages,
  // $FlowIssue object.values
  (scheduledMessages): Broadcast[] => Object.values(scheduledMessages),
);

export const selectScheduledMessagesForThread = createSelector(
  selectScheduledMessagesArray,
  (state, threadId: string) => threadId,
  (scheduledMessages, threadId) =>
    scheduledMessages.filter(
      (scheduledMessage) =>
        scheduledMessage.threadIds &&
        scheduledMessage.threadIds.includes(threadId),
    ),
);

export const selectUpcomingScheduledMessagesForThread = (
  state: State,
  threadId: string,
) =>
  state.messages.scheduledMessages[threadId]?.filter(
    (msg) => msg.status === 'scheduled',
  );
export const selectSentScheduledMessagesForThread = createSelector(
  selectScheduledMessagesForThread,
  (scheduledMessages) =>
    scheduledMessages.filter(({status}) => status === 'processed'),
);

export const selectContextEventForThread = createSelector(
  getThreadById,
  (thread) =>
    (thread.contextEvents || []).map((contextEvent) => ({
      ...contextEvent,
      timeCreated: contextEvent.eventTimeCreated,
      isContextEvent: true,
    })),
);

export const selectThreadMessageHistory = createSelector(
  selectMessagesForThread,
  selectPendingMessagesForThread,
  selectContextEventForThread,
  (messages, pendingMessages, contextEvents) =>
    // TODO (kyle): maybe insert the pending messages more efficiently?
    messages.concat(pendingMessages, contextEvents).sort(compareMessagesByTime),
);

const isValidPreviewMessage = (message) =>
  ((message.origin === 'messaging' ||
    message.origin === 'business_api' ||
    message.origin === 'trm' ||
    message.origin === 'discover') &&
    message.status !== 'pending') ||
  message.origin === 'compliance_code' ||
  message.origin === 'chatbot' ||
  (message.origin === 'core' && message.direction === 'incoming');

export const selectUnFilteredInboxThreads = createSelector(
  selectMessagesAsArray,
  getThreads,
  selectPhoneNumberSetFromDefaultPhoneNumberSet,
  (
    messages: Message[],
    threads: {[string]: Thread},
    phoneNumberSetIdVal,
  ): InboxThread[] =>
    // $FlowIssue object values
    (Object.values(threads): Thread[])
      .filter(({archived, blocked, hidden, phoneNumberSetId}) => {
        return phoneNumberSetId === phoneNumberSetIdVal && !blocked && !hidden;
      })
      .map((thread) => ({
        thread,
        newestMessage: getMessagesForThread(messages, thread.id).find(
          isValidPreviewMessage,
        ),
      }))
      .filter(({newestMessage}) => newestMessage)
      .sort((a, b) => {
        //NOTE (Iris): a valuable message is a 1-1 non-core message
        const timeValuableMessageA = a.thread.displayTime || '0';
        const timeValuableMessageB = b.thread.displayTime || '0';
        return compareTimestamps(timeValuableMessageA, timeValuableMessageB);
      }),
);

export const selectUnFilteredQueueThreads = createSelector(
  selectMessagesAsArray,
  getThreads,
  selectCurrentQueue,
  (messages: Message[], threads: {[string]: Thread}, inbox): InboxThread[] =>
    // $FlowIssue object values
    (Object.values(threads): Thread[])
      .filter(
        ({archived, blocked, hidden, queueId}) =>
          inbox.queue_id === queueId && !blocked && !hidden,
      )
      .map((thread) => ({
        thread,
        newestMessage: getMessagesForThread(messages, thread.id).find(
          isValidPreviewMessage,
        ),
      }))
      .filter(({newestMessage}) => newestMessage)
      .sort((a, b) => {
        //NOTE (Iris): a valuable message is a 1-1 non-core message
        const timeValuableMessageA = a.thread.displayTime || '0';
        const timeValuableMessageB = b.thread.displayTime || '0';
        return compareTimestamps(timeValuableMessageA, timeValuableMessageB);
      }),
);

export const selectQueueThreads = createSelector(
  selectUnFilteredQueueThreads,
  selectInboxFilter,
  (queueThreads, inboxFilter) => {
    if (inboxFilter === 'resolved_chat') {
      return queueThreads.filter((envelope) => {
        return envelope.thread.threadStatus === 'resolved';
      });
    } else {
      return queueThreads.filter(
        (envelope) => envelope.thread.threadStatus === 'active',
      );
    }
  },
);

export const selectInboxThreads = createSelector(
  selectUnFilteredInboxThreads,
  selectInboxFilter,
  selectInboxOwnerFilter,
  (
    inboxThreads: InboxThread[],
    inboxFilter: InboxFilter,
    owned_by: string,
  ): Thread[] => {
    if (inboxFilter === 'archived') {
      return inboxThreads.filter(
        (inboxThread) =>
          inboxThread.thread.archived && !inboxThread.thread.blocked,
      );
    } else if (inboxFilter === 'starred') {
      return inboxThreads.filter(
        (inboxThread) =>
          inboxThread.thread.starred && !inboxThread.thread.blocked,
      );
    } else if (inboxFilter === 'unread') {
      return inboxThreads.filter(
        (inboxThread) =>
          inboxThread.thread.unreadCount > 0 && !inboxThread.thread.archived,
      );
    } else if (inboxFilter === 'not_replied') {
      return inboxThreads.filter(
        (inboxThread) =>
          inboxThread.newestMessage.direction === MSG_DIRECTION_INCOMING &&
          !inboxThread.thread.archived,
      );
    } else if (inboxFilter === 'closed_session') {
      return inboxThreads.filter(
        (inboxThread) => inboxThread.thread.sessionType === 'closed',
      );
    } else if (inboxFilter === 'open_session') {
      return inboxThreads.filter(
        (inboxThread) => inboxThread.thread.sessionType === 'open',
      );
    } else if (inboxFilter === 'covid-19') {
      return inboxThreads.filter(
        (inboxThread) =>
          inboxThread.thread.threadFilters.includes('covid-19') &&
          !inboxThread.thread.archived,
      );
    } else if (inboxFilter === 'owned') {
      return inboxThreads.filter(
        (inboxThread) =>
          (Array.isArray(inboxThread.thread.owners)
            ? inboxThread.thread.owners
            : []
          ).some(({agentId}) => owned_by === agentId) &&
          !inboxThread.thread.archived,
      );
    } else {
      return inboxThreads.filter(
        (inboxThread) =>
          !inboxThread.thread.archived && !inboxThread.thread.blocked,
      );
    }
  },
);

export const selectLatestReadThread = createSelector(
  selectInboxThreads,
  (state) => {
    const selectedThreads = state.chat.multiNumberInbox.selectedThreads;
    const selectedPhoneNumber =
      selectPhoneNumberSetFromDefaultPhoneNumberSet(state);

    return selectThreadByPhone(state, selectedPhoneNumber);
  },
  (threads, selectedThread) => {
    if (
      selectedThread &&
      selectedThread.lastMessageRead === selectedThread.count
    ) {
      return selectedThread;
    } else {
      return threads.find((t) => t.thread.lastMessageRead === t.thread.count)
        ?.thread;
    }
  },
);

export const selectLatestReadQueueThread = createSelector(
  selectQueueThreads,
  (threads) => {
    return threads.find((t) => t.thread.lastMessageRead === t.thread.count)
      ?.thread;
  },
);

export const getAudienceMembersForThread = (
  thread: Thread,
  audienceMembers: AudienceMemberMap,
) =>
  thread
    ? thread.audienceMemberIds.map((id) => {
        const data = audienceMembers[id];
        return data && data.audienceMember;
      })
    : emptyArray;

export const selectAudienceMembersForThread = createSelector(
  getThreadById,
  (state) => state.audienceMembers.audienceMembers,
  getAudienceMembersForThread,
);

export const getContactForThread = (thread: Thread, contacts: ContactMap) =>
  thread && contacts[thread.contactId];
export const selectContactForThread = createSelector(
  getThreadById,
  (state) => state.contacts.contacts,
  getContactForThread,
);

export const selectDefaultRecipientForThread = (
  state: State,
  threadId: string,
) => {
  const defaultAudienceMember = selectAudienceMembersForThread(
    state,
    threadId,
  )[0];
  return defaultAudienceMember || selectContactForThread(state, threadId);
};

const compareMessagesByTime = (a, b) =>
  compareTimestamps(a.timeCreated, b.timeCreated);

export const selectThreadMarkedUnreadFlag = (state: State) =>
  state.messages.threadMarkedUnread;

const resolveAgent = (threadId, agentId, agents, sensePhoneMap) => {
  let agent = agents.find((a) => a.id === agentId);
  if (!agent) {
    // resolve agent's phone from thread and put it in name
    const phone = sensePhoneMap[threadId]?.sensePhone;
    agent = {
      name: phone ?? 'Unknown',
    };
  }
  return agent;
};

const transformMetadata = (
  metadata,
  agents,
  type,
  sensePhoneMap,
  audienceMembers,
  phone,
) =>
  metadata.map((meta) => ({
    ...meta,
    type,
    agent: resolveAgent(meta.threadId, meta.agentId, agents, sensePhoneMap),
    candidate: audienceMembers[0] ?? {fullName: phone},
    isMetadata: true,
  }));
export const selectThreadMetadata = createSelector(
  getThreadById,
  selectAudienceMembersForThread,
  (state, threadId, phone) => phone,
  (thread, audienceMembers, phone) => {
    if (thread && thread.threadMetadata) {
      const metaData = thread.threadMetadata;

      const incoming =
        transformMetadata(
          metaData.firstIncoming,
          metaData.agents,
          META_INCOMING,
          metaData.sensePhoneOnThread,
          audienceMembers,
          phone,
        ) || [];
      const outgoing =
        transformMetadata(
          metaData.firstOutgoing,
          metaData.agents,
          META_OUTGOING,
          metaData.sensePhoneOnThread,
          audienceMembers,
          phone,
        ) || [];
      const coreMessages =
        transformMetadata(
          metaData.coreMessages,
          metaData.agents,
          META_CORE_MESSAGES,
          metaData.sensePhoneOnThread,
          audienceMembers,
          phone,
        ) || [];
      const broadcasts =
        transformMetadata(
          metaData.sentBroadcasts,
          metaData.agents,
          META_SENT_BROADCAST,
          metaData.sensePhoneOnThread,
          audienceMembers,
          phone,
        ) || [];
      const scheduledBroadcasts =
        transformMetadata(
          metaData.scheduledBroadcast,
          metaData.agents,
          META_SCHEDULED_BROADCAST,
          metaData.sensePhoneOnThread,
          audienceMembers,
          phone,
        ) || [];

      return [
        ...incoming,
        ...outgoing,
        ...coreMessages,
        ...broadcasts,
        ...scheduledBroadcasts,
      ];
    }

    return [];
  },
);

export const selectMessageHistoryWindow = (state) =>
  state.messages.messageHistoryWindow;

export const selectFullMessageHistory = (state, threadId, selectedPhone) => {
  const messageHistory = threadId
    ? selectThreadMessageHistory(state, threadId)
    : [];
  const threadMetadata = selectThreadMetadata(state, threadId, selectedPhone);

  const fullHistory = messageHistory
    .concat(threadMetadata)
    .sort(compareMessagesByTime);

  const historyWindow = selectMessageHistoryWindow(state);

  if (historyWindow?.mostRecentMessage) {
    const windowedHistory = fullHistory.filter(
      (item) => item.timeCreated <= historyWindow.mostRecentMessage.timeCreated,
    );

    return windowedHistory;
  }

  return fullHistory;
};

export const selectExternalEventDetails = (state) =>
  state.messages.externalEventDetails;
