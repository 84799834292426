// @flow strict

// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction, GetState} from 'src/reducers';
import type {
  Entity,
  KPIData,
  FilterByID,
  MetricsType,
  J2FiltersOptions,
  J2SelectedFilters,
  WorkflowOverviewDataTable,
  WorkflowOverviewDataTableQuery,
  SelectedWorkflowFilterColumnsData,
  CurrentWorkflowAndNodeBreadCrumbData,
  SavedDashboardFiltersList,
  SavedDashboardFiltersListQuery,
  SavedDashboardFiltersListData,
} from 'src/types/analytics-genesis';
// $FlowFixMe[nonstrict-import]
import type {ApiOptions, ApiPostOptions} from 'src/utils/redux-api';
import type {LineSeriesItem} from '@spaced-out/ui-design-system/lib/components/Charts';
import type {DateRangeWithTimezone} from '@spaced-out/ui-design-system/lib/types';

// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';
// $FlowFixMe[nonstrict-import]
import * as reduxApiBff from 'src/utils/redux-api-bff';
// $FlowFixMe[nonstrict-import]
import {thunkify as flow} from 'src/utils/thunks';
// $FlowFixMe[nonstrict-import]
import {ANALYTICS_API_V3} from 'src/utils/analytics-api-migration';
// $FlowFixMe[untyped-import]
import {fetching, key} from 'src/utils/redux';
import {
  transformWorkflowTableData,
  workflowTableColumnsQuery,
  nodeTableColumnsQuery,
  peopleWorkflowTableColumnsQuery,
} from 'src/utils/analytics-genesis';


export const RECEIVE_KPI_DATA = 'analytics-workflows-overview/receive-kpi-data';
export const RECEIVE_FILTERS_OPTIONS =
  'analytics-workflows-overview/receive-filters-options';
export const RECEIVE_WORKFLOW_OPTIONS =
  'analytics-workflows-overview/receive-workflow-options';
export const RECEIVE_WORKFLOW_PLOT_DATA =
  'analytics-workflows-overview/receive-workflow-plot-data';
export const RECEIVE_WORKFLOW_TABLE_DATA =
  'analytics-workflows-overview/receive-workflow-table-data';
export const RECEIVE_NODE_TABLE_DATA =
  'analytics-workflows-overview/receive-node-table-data';
export const RECEIVE_PEOPLE_TABLE_DATA =
  'analytics-workflows-overview/receive-people-table-data';
export const RECEIVE_SAVED_DASHBOARDS =
  'analytics-workflows-overview/receive-saved-dashboards';
export const SET_CHART_FREQUENCY =
  'analytics-workflows-overview/set-chart-frequency';
export const SET_SELECTED_FILTERS =
  'analytics-workflows-overview/set-selected-filters';
export const SET_SELECTED_ENTITIES =
  'analytics-workflows-overview/set-selected-entities';
export const SET_SELECTED_WORKFLOWS =
  'analytics-workflows-overview/set-selected-workflows';
export const SET_SELECTED_METRICS_TYPE =
  'analytics-workflows-overview/set-selected-metrics-type';
export const SET_SELECTED_DATE_RANGE_AND_FREQUENCY =
  'analytics-workflows-overview/set-selected-date-range-and-frequency';
export const CLEAR_SELECTED_FILTERS =
  'analytics-workflows-overview/clear-filters';

export const RECEIVE_WORKFLOW_TABLE_COLUMNS =
  'analytics-workflows-overview/receive-workflow-table-columns';
export const RECEIVE_NODE_TABLE_COLUMNS =
  'analytics-workflows-overview/receive-node-table-columns';
export const RECEIVE_PEOPLE_TABLE_COLUMNS =
  'analytics-workflows-overview/receive-people-table-columns';

export const SET_CURRENT_WORKFLOW_AND_NODE_DATA =
  'analytics-workflows-overview/set-current-workflow-and-node-data';
export const SET_CURRENT_DASHBOARD =
  'analytics-workflows-overview/set-current-dashboard';
export const SET_LOADING_DASHBOARD_FILTERS =
  'analytics-workflows-overview/loading-dashboard-filters';

type ReceiveKPIDataAction = {
  type: typeof RECEIVE_KPI_DATA,
  payload: KPIData | null,
};

type ReceiveWorkflowPlotDataAction = {
  type: typeof RECEIVE_WORKFLOW_PLOT_DATA,
  payload: Array<LineSeriesItem>,
};

type SetChartFrequencyAction = {
  type: typeof SET_CHART_FREQUENCY,
  payload: string,
};

type SetSelectedFiltersAction = {
  type: typeof SET_SELECTED_FILTERS,
  payload: J2SelectedFilters,
};

type SetSelectedEntitiesAction = {
  type: typeof SET_SELECTED_ENTITIES,
  payload: Array<Entity>,
};

type SetSelectedWorkflowsAction = {
  type: typeof SET_SELECTED_WORKFLOWS,
  payload: Array<FilterByID>,
};

type SetSelectedMetricsTypeAction = {
  type: typeof SET_SELECTED_METRICS_TYPE,
  payload: MetricsType,
};

type SetSelectedDateRangeAndFrequencyAction = {
  type: typeof SET_SELECTED_DATE_RANGE_AND_FREQUENCY,
  payload: {|
    ...DateRangeWithTimezone,
    chartFrequency: string,
  |},
};

type ReceiveFiltersOptionsAction = {
  type: typeof RECEIVE_FILTERS_OPTIONS,
  payload: J2FiltersOptions,
};

type ReceiveWorkflowOptionsAction = {
  type: typeof RECEIVE_WORKFLOW_OPTIONS,
  payload: Array<FilterByID>,
};

type ReceiveWorkflowOverviewAction = {
  type: typeof RECEIVE_WORKFLOW_TABLE_DATA,
  payload: WorkflowOverviewDataTable,
};

type ReceiveNodeOverviewAction = {
  type: typeof RECEIVE_NODE_TABLE_DATA,
  payload: WorkflowOverviewDataTable,
};

type ReceivePeopleOverviewAction = {
  type: typeof RECEIVE_PEOPLE_TABLE_DATA,
  payload: WorkflowOverviewDataTable,
};

type ClearSelectedFiltersAction = {
  type: typeof CLEAR_SELECTED_FILTERS,
};

type ReceiveWorkflowTableColumnsAction = {
  type: typeof RECEIVE_WORKFLOW_TABLE_COLUMNS,
  payload: SelectedWorkflowFilterColumnsData,
};

type ReceiveNodeTableColumnsAction = {
  type: typeof RECEIVE_NODE_TABLE_COLUMNS,
  payload: SelectedWorkflowFilterColumnsData,
};

type ReceivePeopleTableColumnsAction = {
  type: typeof RECEIVE_PEOPLE_TABLE_COLUMNS,
  payload: SelectedWorkflowFilterColumnsData,
};

type ReceiveSavedDashboardFilterListAction = {
  type: typeof RECEIVE_SAVED_DASHBOARDS,
  payload: {
    filters: SavedDashboardFiltersListData,
    dashboard: string,
    product: string,
  },
};

type SetCurrentWorkflowAndNodeDataAction = {
  type: typeof SET_CURRENT_WORKFLOW_AND_NODE_DATA,
  payload: CurrentWorkflowAndNodeBreadCrumbData,
};

type SetCurrentDashboardAction = {
  type: typeof SET_CURRENT_DASHBOARD,
  payload: SavedDashboardFiltersList | null,
};

type SetLoadingDashboardFiltersAction = {
  type: typeof SET_LOADING_DASHBOARD_FILTERS,
  payload: boolean,
};

export type AnalyticsWorkflowsOverviewActions =
  | SetChartFrequencyAction
  | SetSelectedFiltersAction
  | SetSelectedEntitiesAction
  | SetSelectedWorkflowsAction
  | SetSelectedMetricsTypeAction
  | SetSelectedDateRangeAndFrequencyAction
  | ReceiveWorkflowPlotDataAction
  | ReceiveWorkflowOptionsAction
  | ReceiveFiltersOptionsAction
  | ReceiveKPIDataAction
  | ClearSelectedFiltersAction
  | ReceiveWorkflowOverviewAction
  | ReceiveNodeOverviewAction
  | ReceivePeopleOverviewAction
  | ReceiveWorkflowTableColumnsAction
  | ReceiveNodeTableColumnsAction
  | ReceivePeopleTableColumnsAction
  | SetCurrentWorkflowAndNodeDataAction
  | ReceiveSavedDashboardFilterListAction
  | SetCurrentDashboardAction
  | SetLoadingDashboardFiltersAction;

export const setChartFrequency = (
  payload: string,
): SetChartFrequencyAction => ({
  type: SET_CHART_FREQUENCY,
  payload,
});

export const setSelectedFilters = (
  payload: J2SelectedFilters,
): SetSelectedFiltersAction => ({
  type: SET_SELECTED_FILTERS,
  payload,
});

export const setSelectedEntities = (
  payload: Array<Entity>,
): SetSelectedEntitiesAction => ({
  type: SET_SELECTED_ENTITIES,
  payload,
});

export const setSelectedWorkflows = (
  payload: Array<FilterByID>,
): SetSelectedWorkflowsAction => ({
  type: SET_SELECTED_WORKFLOWS,
  payload,
});

export const setSelectedMetricsType = (
  payload: MetricsType,
): SetSelectedMetricsTypeAction => ({
  type: SET_SELECTED_METRICS_TYPE,
  payload,
});

export const setSelectedDateRangeAndFrequency = (payload: {|
  ...DateRangeWithTimezone,
  chartFrequency: string,
|}): SetSelectedDateRangeAndFrequencyAction => ({
  type: SET_SELECTED_DATE_RANGE_AND_FREQUENCY,
  payload,
});

const receiveKPIData = (payload: KPIData | null): ReceiveKPIDataAction => ({
  type: RECEIVE_KPI_DATA,
  payload,
});

const receiveWorkflowPlotData = (
  payload: Array<LineSeriesItem>,
): ReceiveWorkflowPlotDataAction => ({
  type: RECEIVE_WORKFLOW_PLOT_DATA,
  payload,
});

const receiveFiltersOptions = (
  payload: J2FiltersOptions,
): ReceiveFiltersOptionsAction => ({
  type: RECEIVE_FILTERS_OPTIONS,
  payload,
});

const receiveWorkflowOptions = (
  payload: Array<FilterByID>,
): ReceiveWorkflowOptionsAction => ({
  type: RECEIVE_WORKFLOW_OPTIONS,
  payload,
});

const receiveWorkflowOverviewTableData = (
  payload: WorkflowOverviewDataTable,
): ReceiveWorkflowOverviewAction => ({
  type: RECEIVE_WORKFLOW_TABLE_DATA,
  payload,
});

const receiveNodeOverviewTableData = (
  payload: WorkflowOverviewDataTable,
): ReceiveNodeOverviewAction => ({
  type: RECEIVE_NODE_TABLE_DATA,
  payload,
});

const receivePeopleOverviewTableData = (
  payload: WorkflowOverviewDataTable,
): ReceivePeopleOverviewAction => ({
  type: RECEIVE_PEOPLE_TABLE_DATA,
  payload,
});

const receiveWorkflowTableColumnSelection = (
  payload: SelectedWorkflowFilterColumnsData,
): ReceiveWorkflowTableColumnsAction => ({
  type: RECEIVE_WORKFLOW_TABLE_COLUMNS,
  payload,
});

const receiveNodeTableColumnSelection = (
  payload: SelectedWorkflowFilterColumnsData,
): ReceiveNodeTableColumnsAction => ({
  type: RECEIVE_NODE_TABLE_COLUMNS,
  payload,
});

const receivePeopleTableColumnSelection = (
  payload: SelectedWorkflowFilterColumnsData,
): ReceivePeopleTableColumnsAction => ({
  type: RECEIVE_PEOPLE_TABLE_COLUMNS,
  payload,
});

const receiveSavedDashboardFilterListData = (payload: {
  filters: SavedDashboardFiltersListData,
  dashboard: string,
  product: string,
}): ReceiveSavedDashboardFilterListAction => ({
  type: RECEIVE_SAVED_DASHBOARDS,
  payload,
});

export const setCurrentWorkflowAndNodeData = (
  payload: CurrentWorkflowAndNodeBreadCrumbData,
): SetCurrentWorkflowAndNodeDataAction => ({
  type: SET_CURRENT_WORKFLOW_AND_NODE_DATA,
  payload,
});

export const clearSelectedFilters = (): ClearSelectedFiltersAction => ({
  type: CLEAR_SELECTED_FILTERS,
});

export const setCurrentDashboard = (
  payload: SavedDashboardFiltersList | null,
): SetCurrentDashboardAction => ({
  type: SET_CURRENT_DASHBOARD,
  payload,
});

export const setLoadingDashboardFilters = (
  payload: boolean,
): SetLoadingDashboardFiltersAction => ({
  type: SET_LOADING_DASHBOARD_FILTERS,
  payload,
});

const analyticsOptions: ApiOptions = {apiPath: ANALYTICS_API_V3};
const analyticsApiOptions: ApiPostOptions = {apiPath: ANALYTICS_API_V3};

export const getFiltersOptions: () => ThunkAction<mixed> = flow(
  key(() => `filtersOptions`),
  fetching(),
)(() => async (dispatch: Dispatch) => {
  const allFilters = {
    filter_list: [
      'node_type',
      'workflow_name',
      'recipient_type',
      'workflow_status',
      'workflow_category',
    ],
  };
  try {
    const response = await dispatch(
      reduxApi.post('/filters', allFilters, {}, analyticsApiOptions),
    );
    dispatch(receiveFiltersOptions(response));
  } catch (error) {
    dispatch(
      receiveFiltersOptions({
        node_type: [],
        workflow_name: [],
        recipient_type: [],
        workflow_status: [],
        workflow_category: [],
      }),
    );
    console.error('Error Fetching Filters Options:', error);
  }
});

export const getWorkflowOptions: (
  query: string,
  limit: number,
) => ThunkAction<mixed> = flow(
  key((query, limit) => `workflowOptions-${query}-${limit}`),
  fetching(),
)((query, limit) => async (dispatch: Dispatch) => {
  const workflowFilter = {filter_list: ['workflow_name'], query, limit};
  try {
    const {workflow_name} = await dispatch(
      reduxApi.post('/filters', workflowFilter, {}, analyticsApiOptions),
    );
    dispatch(receiveWorkflowOptions(workflow_name));
  } catch (error) {
    dispatch(receiveWorkflowOptions([]));
    console.error('Error Fetching Workflow Options:', error);
  }
});

export const getKPIData: (data: {...}) => ThunkAction<mixed> = flow(
  key(() => `KPIData`),
  fetching(),
)((data) => async (dispatch: Dispatch) => {
  try {
    const KPIData = await dispatch(
      reduxApi.post(
        `/workflows-overview/overview`,
        data,
        {},
        analyticsApiOptions,
      ),
    );
    dispatch(receiveKPIData(KPIData));
  } catch (error) {
    dispatch(receiveKPIData(null));
    console.error('Error Fetching KPI Data:', error);
  }
});

export const getWorkflowPlotData: (data: {...}) => ThunkAction<mixed> = flow(
  key(() => `workflowPlotData`),
  fetching(),
)((data) => async (dispatch: Dispatch) => {
  try {
    const workflowPlotData = await dispatch(
      reduxApi.post(`/workflows-overview/plot`, data, {}, analyticsApiOptions),
    );
    dispatch(receiveWorkflowPlotData(workflowPlotData.series));
  } catch (error) {
    dispatch(receiveWorkflowPlotData([]));
    console.error('Error Fetching Plot Data:', error);
  }
});

export const getWorkflowOverviewTableData: (
  query: WorkflowOverviewDataTableQuery,
) => ThunkAction<mixed> = flow(
  key((query) => `workflowDataTable-${JSON.stringify(query)}`),
  fetching(),
)((query) => async (dispatch: Dispatch) => {
  try {
    const payload: WorkflowOverviewDataTable = await dispatch(
      reduxApi.post(
        '/workflows-overview/data-table',
        query,
        {},
        analyticsApiOptions,
      ),
    );
    dispatch(
      receiveWorkflowOverviewTableData(transformWorkflowTableData(payload)),
    );
  } catch (error) {
    dispatch(
      receiveWorkflowOverviewTableData({
        data: [],
        total_count: 0,
      }),
    );
    console.error('Error fetching workflow overview data:', error);
  }
});

export const getWorkflowTableFilterColumns: () => ThunkAction<mixed> = flow(
  key(() => 'getWorkflowTableFilterColumns'),
  fetching(),
)(() => async (dispatch: Dispatch) => {
  try {
    const payload: SelectedWorkflowFilterColumnsData = await dispatch(
      reduxApi.get(
        '/workflows-overview/column-selection',
        workflowTableColumnsQuery,
        analyticsOptions,
      ),
    );
    dispatch(receiveWorkflowTableColumnSelection(payload));
  } catch (error) {
    console.error('Error fetching workflow table column filter:', error);
  }
});

export const getNodeTableFilterColumns: () => ThunkAction<mixed> = flow(
  key(() => 'getNodeTableFilterColumns'),
  fetching(),
)(() => async (dispatch: Dispatch) => {
  try {
    const payload: SelectedWorkflowFilterColumnsData = await dispatch(
      reduxApi.get(
        '/workflows-overview/column-selection',
        nodeTableColumnsQuery,
        analyticsOptions,
      ),
    );
    dispatch(receiveNodeTableColumnSelection(payload));
  } catch (error) {
    console.error('Error fetching node table column filter:', error);
  }
});

export const getPeopleTableFilterColumns: () => ThunkAction<mixed> = flow(
  key(() => 'getPeopleTableFilterColumns'),
  fetching(),
)(() => async (dispatch: Dispatch) => {
  try {
    const payload: SelectedWorkflowFilterColumnsData = await dispatch(
      reduxApi.get(
        '/workflows-overview/column-selection',
        peopleWorkflowTableColumnsQuery,
        analyticsOptions,
      ),
    );
    dispatch(receivePeopleTableColumnSelection(payload));
  } catch (error) {
    console.error('Error fetching node table column filter:', error);
  }
});

export const saveWorkflowSelectedFilterColumns: (
  columnData: SelectedWorkflowFilterColumnsData,
) => ThunkAction<mixed> = flow(
  key(
    (columnData) =>
      `saveWorkflowSelectedFiltersColumns-${JSON.stringify(columnData)}`,
  ),
  fetching(),
)(
  (columnData) => async (dispatch: Dispatch) =>
    dispatch(
      reduxApi.post(
        '/workflows-overview/column-selection',
        columnData,
        {},
        analyticsApiOptions,
      ),
    ),
);

export const getNodesOverviewTableData: (
  query: WorkflowOverviewDataTableQuery,
) => ThunkAction<mixed> = flow(
  key((query) => `nodeDataTable-${JSON.stringify(query)}`),
  fetching(),
)((query) => async (dispatch: Dispatch) => {
  try {
    const payload: WorkflowOverviewDataTable = await dispatch(
      reduxApi.post(
        '/workflows-overview/data-table',
        query,
        {},
        analyticsApiOptions,
      ),
    );
    dispatch(receiveNodeOverviewTableData(transformWorkflowTableData(payload)));
  } catch (error) {
    dispatch(
      receiveNodeOverviewTableData({
        data: [],
        total_count: 0,
      }),
    );
    console.error('Error fetching workflow overview data:', error);
  }
});

export const getPeopleOverviewTableData: (
  query: WorkflowOverviewDataTableQuery,
) => ThunkAction<mixed> = flow(
  key((query) => `nodeDataTable-${JSON.stringify(query)}`),
  fetching(),
)((query) => async (dispatch: Dispatch) => {
  try {
    const payload: WorkflowOverviewDataTable = await dispatch(
      reduxApi.post(
        '/workflows-overview/data-table',
        query,
        {},
        analyticsApiOptions,
      ),
    );
    dispatch(
      receivePeopleOverviewTableData(transformWorkflowTableData(payload)),
    );
  } catch (error) {
    dispatch(
      receivePeopleOverviewTableData({
        data: [],
        total_count: 0,
      }),
    );
    console.error('Error fetching workflow overview data:', error);
  }
});

export const getWorkflowNodeDetails: (
  workflowId: string,
  nodeId?: string,
) => ThunkAction<mixed> = flow(
  key((workflowId) => `getWorkflowNodeDetails-${workflowId}`),
  fetching(),
)((workflowId, nodeId) => async (dispatch: Dispatch) => {
  try {
    const currentWorkflowAndNode: CurrentWorkflowAndNodeBreadCrumbData = {
      workflow: {},
      node: {},
    };
    if (workflowId) {
      const workflowResponse = await dispatch(
        reduxApiBff.get(`workflow/${workflowId}`),
      );
      currentWorkflowAndNode.workflow = {
        id: workflowId,
        name: workflowResponse?.name,
      };

      if (nodeId) {
        const {node_name, node_type, body} = await dispatch(
          reduxApiBff.get('node', {
            node_id: nodeId,
          }),
        );

        currentWorkflowAndNode.node = {
          id: nodeId,
          name: `${node_name} ${node_type}`,
          sendType: body.type,
        };
      } else {
        currentWorkflowAndNode.node = {};
      }
    }
    dispatch(setCurrentWorkflowAndNodeData(currentWorkflowAndNode));
  } catch (error) {
    console.error('Error saving overview column filter:', error);
  }
});

export const getSavedWorkflowDashboards: (
  query: SavedDashboardFiltersListQuery,
) => ThunkAction<mixed> = flow(
  key(() => 'j2-analytics-deliverability-engagement-saved-dashboards'),
  fetching(),
)((query) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoadingDashboardFilters(true));
    const {filters}: {filters: SavedDashboardFiltersListData} = await dispatch(
      reduxApi.get('/workflows-overview/save-filter', query, analyticsOptions),
    );
    const defaultDashboard = filters.find(({is_default}) => is_default);
    dispatch(receiveSavedDashboardFilterListData({filters, ...query}));
    dispatch(setCurrentDashboard(defaultDashboard ?? null));
  } catch (error) {
    console.error('Error fetching saved dashboard filter:', error);
  } finally {
    dispatch(setLoadingDashboardFilters(false));
  }
});

export const saveWorkflowDashboard: (
  dashboardData: SavedDashboardFiltersList,
) => ThunkAction<mixed> = flow(
  key(
    (dashboardData) => `saveWorkflowDashboard-${JSON.stringify(dashboardData)}`,
  ),
  fetching(),
)((dashboardData) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const {filters}: {filters: SavedDashboardFiltersListData} = await dispatch(
      reduxApi.post(
        '/workflows-overview/save-filter',
        dashboardData,
        {},
        analyticsApiOptions,
      ),
    );
    const {name: dashboardName, product, dashboard, id} = dashboardData;
    dispatch(
      receiveSavedDashboardFilterListData({filters, product, dashboard}),
    );

    const {id: currentDashboardID} =
      getState().analyticsWorkflowsOverview.currentDashboard ?? {};
    if (!(Boolean(id) && currentDashboardID !== id)) {
      const newDashboard = filters.find(({name}) => name === dashboardName);
      dispatch(setCurrentDashboard(newDashboard ?? null));
    }
  } catch (error) {
    console.error('Error fetching saved dashboard filter:', error);
    throw error;
  }
});
