// @flow strict

/**
 * NOTE (kyle): this file is just a way of stubbing nanoid without
 * flow-typed.
 */

/*
// $FlowIssue unclear how to solve this with flow-typed
import {customAlphabet} from 'nanoid';

// NOTE (kyle): we can safely generate 1000 of these ids per hour
// without any chance of collision.
//
// See https://zelark.github.io/nano-id-cc/ for more info.
//
// If this isn't enough entropy we should consider using this to
// gennerate a prefix per session and then incrementing a suffix
// to generate ids.
const idGen: () => string = customAlphabet('1234567890', 16);
const intIdGen = (): number => parseInt(idGen());
export default intIdGen;
*/

/**
 * generates a random 32-bit integer.
 */
export default function generateId(): number {
  const randomArray = new Uint32Array(1);
  return window.crypto.getRandomValues(randomArray)[0];
}
