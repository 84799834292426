// @noflow

import type {Range} from 'src/utils/date-time';

import 'react-datepicker/dist/react-datepicker.css';
import 'src/components/lib/date-picker/date-picker-override.css';
import css from './date-range-picker.css';

import React, {PureComponent} from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import {classify} from 'src/utils';

import CalendarIcon from 'src/images/calendar-icon.svg';


type Props = {
  start: moment$Moment,
  end: moment$Moment,
  onStartChange: (value: moment$Moment) => {},
  onEndChange: (value: moment$Moment) => {},
  minDate?: moment$Moment,
  maxDate?: moment$Moment,
  className?: string,
};

const DateRangePicker = ({
  start,
  end,
  onStartChange,
  onEndChange,
  minDate,
  maxDate,
  className = 'simple-date-range-picker',
}: Props) => (
  <div className={classify(css.wrapper, className)}>
    <DatePicker
      dateFormat="yyyy-MM-dd"
      className={css.picker}
      selected={start?.toDate()}
      onChange={onStartChange}
      minDate={minDate?.toDate()}
      maxDate={maxDate?.toDate()}
      popperPlacement="bottom-start"
      popperModifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: 'viewport',
          padding: 8,
        },
      }}
    />
    <span className={css.dash}>-</span>
    <DatePicker
      dateFormat="yyyy-MM-dd"
      className={css.picker}
      selected={end?.toDate()}
      onChange={onEndChange}
      minDate={minDate?.toDate()}
      maxDate={maxDate?.toDate()}
      popperPlacement="bottom-end"
      popperModifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: 'viewport',
          padding: 8,
        },
      }}
    />
    <CalendarIcon className={css.icon} />
  </div>
);

export default DateRangePicker;

type SimpleProps = {
  value: Range,
  onChange: (range: Range) => void,
  minDate?: string,
  maxDate?: string,
};

export class SimpleDateRangePicker extends PureComponent<
  SimpleProps,
  {mounted: boolean},
> {
  state = {mounted: false};

  componentDidMount() {
    this.setState({mounted: true});
  }

  render() {
    if (!this.state.mounted) {
      return null;
    }

    const {
      value: {startDate, endDate},
      minDate,
      maxDate,
    } = this.props;

    return (
      <DateRangePicker
        {...this.props}
        onStartChange={this.handleStartChange}
        onEndChange={this.handleEndChange}
        start={moment(startDate)}
        end={moment(endDate)}
        minDate={minDate ? moment(minDate) : undefined}
        maxDate={moment(maxDate)}
      />
    );
  }

  handleStartChange(value: moment$Moment) {
    this.handleChange({
      startDate: moment(value)
        .startOf('day')
        .toISOString(),
    });
  }
  handleStartChange = this.handleStartChange.bind(this);

  handleEndChange(value: moment$Moment) {
    this.handleChange({endDate: value.toISOString()});
  }
  handleEndChange = this.handleEndChange.bind(this);

  handleChange(semiRange: Object) {
    const {onChange, value} = this.props;
    const newRange = {...value, ...semiRange};

    if (newRange.endDate < newRange.startDate) {
      newRange.startDate = newRange.endDate;
    }

    onChange(newRange);
  }
}
