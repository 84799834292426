// @flow strict

import type {
  AutomationWorkflowApi,
  AutomationNode,
  AutomationNodeApi,
  NextNodeApi,
  Edge,
  Group,
  Swimlane,
  SwimlaneRecurNodeMap,
  WorkflowStatus,
  AutomationNodeData,
  FrequencyLimit,
} from 'src/types/automation-workflow';
// $FlowFixMe[nonstrict-import]
import type {Group as WorkflowGroup} from 'src/action-creators/groups';
import type {AutomationWorkflowState} from 'src/reducers/automation-workflow';
import type {Brand} from 'src/types/brands';
import type {PropsWithIconURL} from 'src/components/automation/common/panel-options';

import {getBrandDisplayName} from 'src/utils/settings';
import capitalize from 'lodash/capitalize';


export const workflowStatusToText: {[WorkflowStatus]: string} = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  PAUSE: 'Inactive', //paused status is not shown on ui
  ARCHIVE: 'Archived',
};
export const AUTOMATION_WORKFLOW_NAME_MAX_LENGTH = 40;
export const TEMP_NODE_IDENTIFIER = 'temp:temp';

export const TEMP_END_NODE_IDENTIFIER = 'end:null';

export const DEFAULT_BEEFREE_LOGO_URL =
  'https://s3.us-west-2.amazonaws.com/media.sense/email/logo/default_company_logo.png';

export const FREQUENCY_LIMIT = {
  NO_RESTRICTION: 'no-restrict',
  RESTRICTION: 'restrict',
  RESTRICT_REPEAT: 'restrict-repeat',
};
export const createSwimlaneNode = ({
  id,
  is_end = false,
  next_nodes,
  related_to,
}: {
  id: string,
  is_end?: boolean,
  next_nodes: Array<NextNodeApi>,
  related_to?: string,
}): AutomationNodeApi => {
  return {
    id,
    ...(is_end && {is_end: true}),
    next_nodes,
    // TODO[Diwaker] : fix below error introduced in 0.177.0
    // $FlowFixMe[not-an-object]
    metadata: {...(related_to && {related_to})},
  };
};

export const createTransition = (): NextNodeApi => {
  return {
    condition: {blocks: []},
    next_node: TEMP_END_NODE_IDENTIFIER,
    transition_id: null,
  };
};

export const createSplitTransition = (nextNodeId: string): NextNodeApi => {
  return {
    condition: {blocks: []},
    next_node: nextNodeId,
    transition_id: null,
  };
};

export const createEmailTaskNode = ({
  taskNodeId,
  scheduleNodeId,
  next_nodes,
}: {
  taskNodeId: string,
  scheduleNodeId: string,
  next_nodes: Array<NextNodeApi>,
}): {[string]: AutomationNodeApi} => {
  const taskNode = createSwimlaneNode({id: taskNodeId, next_nodes});
  const scheduleNode = createSwimlaneNode({
    id: scheduleNodeId,
    next_nodes: [
      {
        condition: {blocks: []},
        next_node: taskNodeId,
        transition_id: null,
      },
    ],
    related_to: taskNodeId,
  });
  return {
    [scheduleNodeId]: scheduleNode,
    [taskNodeId]: taskNode,
  };
};

export const DUMMY_SWIMLANE: Swimlane = {
  id: '1',
  initial_node: 'trigger:null',
  nodes: {
    'trigger:null': createSwimlaneNode({
      id: 'trigger:null',
      next_nodes: [
        {
          condition: {
            blocks: [
              {
                rules: [
                  {
                    id: -1, //dummy id
                    conditions: [],
                  },
                ],
              },
            ],
          },
          next_node: 'end:null',
          transition_id: null,
        },
      ],
    }),
    'end:null': createSwimlaneNode({
      id: 'end:null',
      is_end: true,
      next_nodes: [],
    }),
  },
};

/** a function to convert journey provided by backend to frontend store schema */
export const parseAutomationWorkflow = (
  automationWorkflow: AutomationWorkflowApi,
): AutomationWorkflowState => {
  return {
    id: automationWorkflow.id,
    name: automationWorkflow.name,
    entity_type: automationWorkflow.entity_type,
    standard_entity_type: automationWorkflow.standard_entity_type,
    starred: automationWorkflow.starred,
    time_updated: automationWorkflow.time_updated,
    status: automationWorkflow.status,
    swimlanes: automationWorkflow.swimlanes,
    filters: automationWorkflow.filters,
    workflow_category: automationWorkflow.workflow_category,
    created_by_agent_name: automationWorkflow.created_by_agent_details?.name,
    security_group: automationWorkflow.security_group_details,
    time_created: automationWorkflow.time_created,
    brand_id: automationWorkflow.brand_id,
    shared_security_groups: automationWorkflow.shared_security_groups,
    frequencyLimit: getFrequencyLimit(automationWorkflow.frequency_limit),
    frequencyType: getFrequencyType(automationWorkflow.frequency_limit),
    blackout_window_id: automationWorkflow.blackout_window_id,
  };
};

export const swimlanesToGraph = (
  swimlanes: Array<Swimlane>,
): {
  nodes: Array<AutomationNode>,
  edges: Array<Edge>,
  groups: Array<Group>,
  swimlaneRecurNodeMap: SwimlaneRecurNodeMap,
} => {
  return swimlanes.reduce(
    (acc, swimlane) => {
      const {nodes, edges, groups, swimlaneRecurNodeMap} = swimlane.initial_node
        ? traverseSwimlane(swimlane)
        : {nodes: [], edges: [], groups: [], swimlaneRecurNodeMap: new Map()};
      return {
        nodes: [...acc.nodes, ...nodes],
        edges: [...acc.edges, ...edges],
        groups: [...acc.groups, ...groups],
        swimlaneRecurNodeMap: new Map([
          ...acc.swimlaneRecurNodeMap,
          ...swimlaneRecurNodeMap,
        ]),
      };
    },
    {nodes: [], edges: [], groups: [], swimlaneRecurNodeMap: new Map()},
  );
};

const traverseSwimlane = (
  swimlane: Swimlane,
): {
  nodes: Array<AutomationNode>,
  edges: Array<Edge>,
  groups: Array<Group>,
  swimlaneRecurNodeMap: SwimlaneRecurNodeMap,
} => {
  const {initial_node} = swimlane;
  return traverseNode(swimlane, initial_node, true, 0, new Set<string>());
};
const traverseNode = (
  swimlane,
  nodeId,
  isRoot,
  scheduleSeenCount,
  relatedNodes,
): {
  nodes: Array<AutomationNode>,
  edges: Array<Edge>,
  groups: Array<Group>,
  swimlaneRecurNodeMap: SwimlaneRecurNodeMap,
} => {
  const {id} = swimlane;
  let nodes = [];
  let edges = [];
  let groups = [];
  let swimlaneRecurNodeMap = new Map<string, string>();

  if (nodeId) {
    const node = swimlane.nodes[nodeId];

    const relatedNodeId = node.metadata.related_to;
    const relatedNode = relatedNodeId ? swimlane.nodes[relatedNodeId] : null;
    const hasRecurrence = !!node.body?.recurring_rule;
    if (hasRecurrence && !swimlaneRecurNodeMap.get(id)) {
      swimlaneRecurNodeMap.set(id, nodeId);
    }
    let nextNodes = node.next_nodes ?? [];
    //if there is a related node, next_nodes of this node should be ignored and point to the related node's
    //next nodes
    if (relatedNode) {
      nextNodes = relatedNode.next_nodes ?? [];
    }

    /** if schedule has already occured once in any node, the next node shows different screen for
     * scheduling. This flag gives information to the next node that schedule has already occured once
     * in the swimlane so that it can show different screen for scheduling
     */
    const newScheduleSeenCount = nodeId.includes('schedule')
      ? scheduleSeenCount + 1
      : scheduleSeenCount;

    if (!relatedNodes.has(nodeId)) {
      nodes = [
        ...nodes,
        {
          id: nodeId,
          data: {
            ...node,
            swimlaneId: id,
            scheduleSeenOnce: newScheduleSeenCount > 1,
            //relatedNodesMeta contains information about which node contains this node as metadata.related_to
            //hence we attach this information to the data part of this node.
            related_node: relatedNode ?? null,
            //if there is a related node,next_nodes of this node should be ignored and point to the related node's
            //next node instead
            next_nodes: nextNodes,
          },
          isRoot,
        },
      ];
    }

    if (nextNodes.length > 0) {
      edges = [
        ...edges,
        ...nextNodes.reduce((acc, nextNode) => {
          if (nextNode.next_node) {
            acc.push({
              id: `edge_${nodeId}_${nextNode.next_node}`,
              from: nodeId,
              to: nextNode.next_node,
              //only edges connecting end nodes are actionable for phase 1
              actionable: nextNode.next_node.includes('end'),
            });
          }
          return acc;
        }, []),
      ];

      nextNodes.forEach((nextNode) => {
        const {
          nodes: newNodes,
          edges: newEdges,
          groups: newGroups,
          swimlaneRecurNodeMap: newSwimlaneRecurNodeMap,
        } = traverseNode(
          swimlane,
          nextNode.next_node,
          false,
          newScheduleSeenCount,
          relatedNodeId ? relatedNodes.add(relatedNodeId) : relatedNodes,
        );
        nodes = [...nodes, ...newNodes];
        edges = [...edges, ...newEdges];
        groups = [...groups, ...newGroups];
        swimlaneRecurNodeMap = new Map([
          ...swimlaneRecurNodeMap,
          ...newSwimlaneRecurNodeMap,
        ]);
      });
    }
  }
  /*
   * Note(Vish): Flow is facing issues mapping body object correctly in this recursive function.
   * This is happening because body is different for Scheduling node vs Task node.
   * Fixing it will require rewriting node types or making changes in the recursive function, both of which will take significant effort.
   * Supressing for now.
   * */
  //$FlowFixMe
  return {nodes, edges, groups, swimlaneRecurNodeMap};
};

export const normalizeAutomationWorkflow = (
  automationWorkflowState: AutomationWorkflowState,
): $Shape<AutomationWorkflowApi> => {
  const {name, entity_type} = automationWorkflowState;
  if (!name || !entity_type) {
    throw new Error('Automation workflow name, and entity type are required');
  }
  return {
    name,
    entity_type,
  };
};

export const checkWorkflowContainsTempNode = (
  swimlanes: Array<Swimlane>,
): boolean => {
  return swimlanes.some((swimlane) => {
    return Object.keys(swimlane.nodes).some(
      (nodeId) => nodeId === TEMP_NODE_IDENTIFIER,
    );
  });
};

export const checkWorkflowInvalidActivation = (
  swimlanes: Array<Swimlane>,
): boolean => {
  return swimlanes.some((swimlane) => {
    // return false if any swimlane has no task node
    const taskNodeCount = Object.keys(swimlane.nodes).filter((nodeId) =>
      nodeId.includes('task'),
    ).length;
    return taskNodeCount === 0;
  });
};

export const getFormattedBrandsList = (
  brands: ?Array<Brand>,
  selectedBrandId: string,
): PropsWithIconURL[] => {
  const isSelected = (brand) => {
    return brand.id === selectedBrandId;
  };
  brands?.sort((a, b) => {
    const isSelectedA = isSelected(a);
    const isSelectedB = isSelected(b);
    if (isSelectedA !== isSelectedB) {
      return isSelectedA ? -1 : 1;
    }
    if (a.is_primary !== b.is_primary) {
      return a.is_primary ? -1 : 1;
    }
    return 0;
  });
  return (
    brands?.map(({id, display_name, logo, is_primary}) => ({
      title: getBrandDisplayName(display_name, is_primary),
      value: id,
      iconUrl: logo || DEFAULT_BEEFREE_LOGO_URL,
    })) ?? []
  );
};

export function createQueryObjectFromNodeInfo(nodeInfo: {
  tid?: ?string,
  sid?: ?string,
  swid?: ?string,
  ae?: ?string,
  recurid?: ?string,
  an?: ?string,
  bpos?: ?string,
}): {[string]: string} {
  return ['tid', 'sid', 'swid', 'ae', 'recurid', 'an', 'bpos'].reduce(
    (acc, field) => {
      if (nodeInfo[field] != null) {
        acc[field] = nodeInfo[field];
      }
      return acc;
    },
    {},
  );
}

export function extractVariableText(text: string): ?string {
  const regex = /{{\s*(.*?)\s*}}/;
  const match = text.match(regex);
  if (match && match[1]) {
    return match[1];
  } else {
    return null;
  }
}
function findDescendants(key, descendants) {
  const result = new Set();
  if (descendants.hasOwnProperty(key)) {
    for (const child of descendants[key]) {
      result.add(child);
      for (const descendant of findDescendants(child, descendants)) {
        result.add(descendant);
      }
    }
  }
  return result;
}

// Helper function to recursively find ancestors
function findAncestors(key, ancestors) {
  const result = new Set();
  if (ancestors.hasOwnProperty(key)) {
    for (const parent of ancestors[key]) {
      result.add(parent);
      for (const ancestor of findAncestors(parent, ancestors)) {
        result.add(ancestor);
      }
    }
  }
  return result;
}

//Note(Vish): This group related logic can be moved to group selector. But that file is absent atm. Refactor later.
export const generateAncestorAndDescendantArray = (
  groups: WorkflowGroup,
): {[string]: {parents: Set<string>, children: Set<string>}} => {
  const ancestors = {};
  const descendants = {};

  // Construct the ancestor and descendant relationships
  for (const key in groups) {
    const parent = groups[key].parentSecurityGroupId;
    if (parent !== null) {
      if (!descendants.hasOwnProperty(parent)) {
        descendants[parent] = new Set();
      }
      descendants[parent].add(key);

      if (!ancestors.hasOwnProperty(key)) {
        ancestors[key] = new Set();
      }
      ancestors[key].add(parent);
    }
  }

  // Generate relations for each individual
  const relations = {};
  for (const key in groups) {
    const individual = key;
    const ancestorsSet = findAncestors(individual, ancestors);
    const descendantsSet = findDescendants(individual, descendants);
    relations[individual] = {
      parents: ancestorsSet,
      children: descendantsSet,
    };
  }

  return relations;
};

export const removeSwimlaneTempNodes = (
  swimlane: Swimlane,
  nodeId: string,
): mixed => {
  if (!nodeId || !swimlane.nodes[nodeId]) {
    return; // Base condition: return if nodeId is falsy or node doesn't exist
  }
  const node = swimlane.nodes[nodeId];
  const nextNodes = node.next_nodes ?? [];

  if (nextNodes.length > 0) {
    const nextNodesWithoutTempNodes = nextNodes.reduce((acc, nextNode) => {
      // Adjusting the pointers/next nodes
      const nextNodeId = nextNode.next_node;
      if (
        nextNodeId.includes('temp:') ||
        nextNodeId.startsWith(TEMP_END_NODE_IDENTIFIER)
      ) {
        const nextNodeArray = swimlane.nodes[nextNodeId].next_nodes;
        const nextOfNextNodeId =
          nextNodeArray.length > 0 ? nextNodeArray[0].next_node : null;
        if (nextOfNextNodeId) {
          // Also remove tempNode from swimlane nodes
          nextNodeArray.forEach((nextNode) => {
            if (nextNode.next_node.startsWith(TEMP_END_NODE_IDENTIFIER)) {
              delete swimlane.nodes[nextNode.next_node];
            }
          });
          delete swimlane.nodes[nextNodeId];
          return [...acc, {...nextNode, next_node: nextOfNextNodeId}];
        } else {
          // This means that the temp node is end node. Which implies that the current node has a temp transition node, which needs to be removed
          delete swimlane.nodes[nextNodeId];
          return acc;
        }
      }
      return [...acc, nextNode];
    }, []);

    const newNode = {...node, next_nodes: nextNodesWithoutTempNodes};
    swimlane.nodes[nodeId] = newNode;

    if (nextNodesWithoutTempNodes.length > 0) {
      nextNodesWithoutTempNodes.forEach((nextNode) => {
        removeSwimlaneTempNodes(swimlane, nextNode.next_node);
      });
    }
  }
};

export const getNodeInfo = (
  data: AutomationNodeData,
  transitionId?: ?string,
): {
  sid: string,
  tid: ?string,
  swid: string,
} => {
  const effectiveTransitionId = transitionId ? Number(transitionId) : 0;
  const nodeInfo = {
    sid: data.related_node ? data.related_node.id : data.id,
    tid: data.next_nodes[effectiveTransitionId]
      ? data.next_nodes[effectiveTransitionId].next_node
      : null,
    swid: String(data.swimlaneId),
  };
  return nodeInfo;
};

export const getTransitionId = (
  swimlanes: Array<Swimlane>,
  swid: string,
  sid: string,
  tid: string,
): number => {
  const nextNodes =
    swimlanes.find(({id}) => id === Number(swid))?.nodes[sid].next_nodes ?? [];
  //Note(vish): we are send the array index as transition id since we don't have any sort of serialization atm.
  const transitionId = nextNodes.findIndex(({next_node}) => next_node === tid);
  return transitionId >= 0 ? transitionId : 0;
};

export const getAllChildNodes = (
  recurNode: AutomationNode,
  nodes: Array<AutomationNode>,
): Array<string> => {
  const relatedNodes = [];
  function process(rec) {
    relatedNodes.push(rec?.id ?? '');
    if (rec && rec.data.next_nodes.length > 0) {
      rec.data.next_nodes.forEach((nextNode) => {
        const recNode = nodes.find((node) => node.id === nextNode.next_node);
        process(recNode);
      });
    }
  }
  process(recurNode);
  return relatedNodes;
};

const getFrequencyLimit = (frequencyLimit: FrequencyLimit): ?string => {
  if (!frequencyLimit) {
    return null;
  }
  const {count, duration} = frequencyLimit;
  if (count < 0) {
    return FREQUENCY_LIMIT.NO_RESTRICTION;
  }
  if (duration.type === 'FOREVER') {
    return FREQUENCY_LIMIT.RESTRICTION;
  }
  return FREQUENCY_LIMIT.RESTRICT_REPEAT;
};

const getFrequencyType = (frequencyLimit: FrequencyLimit): string => {
  if (!frequencyLimit) {
    return 'DAY';
  }
  const {duration} = frequencyLimit;
  if (duration.type === 'FOREVER') {
    return 'DAY';
  }
  return duration.type;
};

export const generateFrequencySummary = (
  frequencyLimit: ?string,
  frequencyType: string,
): string => {
  if (!frequencyLimit) {
    return '';
  }
  let summaryText = '';
  if (frequencyLimit === FREQUENCY_LIMIT.NO_RESTRICTION) {
    summaryText = 'don’t restrict and send multiple times';
  } else if (frequencyLimit === FREQUENCY_LIMIT.RESTRICTION) {
    summaryText = 'restrict sending multiple times';
  } else {
    summaryText = `restrict and repeat only after 1 ${capitalize(
      frequencyType,
    )}`;
  }
  return `Summary: If communication is getting repeated ${summaryText}`;
};

export const parseFrequencyLimit = (
  frequencyLimit: string,
  frequencyType: string,
): FrequencyLimit => {
  const freqLimit = {
    count: -1,
    duration: {type: 'FOREVER', value: 0},
  };
  if (frequencyLimit === FREQUENCY_LIMIT.RESTRICTION) {
    freqLimit.count = 1;
  } else if (frequencyLimit === FREQUENCY_LIMIT.RESTRICT_REPEAT) {
    freqLimit.count = 1;
    freqLimit.duration.type = frequencyType;
    freqLimit.duration.value = 1;
  }
  return freqLimit;
};
