import {partsMatch} from 'src/rerouter/utils.js';


export const startsWithSelectedKeyMap = {
  '/people/leads': 'people.leads',
  '/people/contacts': 'people.contacts',
  '/people/lists': 'people.lists',
  '/people': 'people.candidates',
  '/jobs': 'jobs.all',
  '/reports': 'analytics',
  '/embedded-landing': 'analytics',
  '/pages/sites': 'pages.sites',
  '/referralv2': 'referral',
  '/settings': 'configuration',
  '/journey': 'journeys',
};

export const sideNavRoutesMap = {
  dashboard: '/dashboard',
  journeys: '/journey',
  'automation-workflows': '/automation-workflows',
  'people.candidates': '/people/candidates',
  'people.lists': '/people/lists',
};

export function getFirstPathComponent(url) {
  // Remove leading and trailing slashes and then split the URL path by '/'
  const parts = url.replace(/^\/|\/$/g, '').split('/');

  // The first part after splitting is the first component of the path
  if (parts[0] === '') {
    // If the URL had a leading '/', the first part after split will be an empty string
    // We want the first significant part, so let's check the next part
    if (parts.length > 1) {
      return parts[1];
    } else {
      return null; // In case of a single '/', return null or handle as needed
    }
  } else {
    return parts[0];
  }
}

export const getSelectedKey = (pathname) => {
  for (const key of Object.keys(startsWithSelectedKeyMap)) {
    if (pathname.startsWith(key)) {
      return startsWithSelectedKeyMap[key];
    }
  }
  return getFirstPathComponent(pathname);
};
