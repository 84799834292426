// @noflow

import type {EventTypeCounts} from 'src/types/report';
import type {EventTypeCountsAction} from 'src/action-creators/event-type-counts';

import {
  RECEIVE_AGENCY,
  RECEIVE_WORKFLOW,
} from 'src/action-creators/event-type-counts';


export type EventTypeCountsState = {
  // todo marcos: does this have a more defined type?
  agency: {
    [eventType: string]: number,
  },
  workflows: {
    [workflowId: string]: EventTypeCounts,
  },
};

const initialState = {
  agency: {},
  workflows: {},
};

export default (state: EventTypeCountsState = initialState, action: EventTypeCountsAction): EventTypeCountsState => {
  switch (action.type) {
    case RECEIVE_AGENCY:
      return {...state, agency: action.payload};

    case RECEIVE_WORKFLOW:
      return {...state, workflows: {...state.workflows, [action.payload.workflowId]: action.payload.counts}};
  }

  return state;
};
