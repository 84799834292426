// @flow

import type {ReduxStore} from 'src/reducers';
// $FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores';

import * as React from 'react';

import App from './app.jsx';
import AppRoutes from 'src/reroutes/app.jsx';


export default function SenseApp(props: {
  reduxStore: ReduxStore,
  // $FlowFixMe[value-as-type]
  senseStore: IndexStore,
}): React.Node {
  return (
    <App {...props}>
      <AppRoutes {...props} />
    </App>
  );
}
