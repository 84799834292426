// @noflow

import type {PaginatedResponses} from 'src/types/report';

import * as React from 'react';

import {Responses} from 'src/components/analytics/responses.jsx';
import Zero from 'src/components/lib/zero';
import SegmentedButton from 'src/components/lib/segmented-button';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';

import Phone from 'src/images/dashboard/phone.svg';

import css from './dashboard.css';


type DashboardResponsesProps = {
  recentResponseData: PaginatedResponses,
  flaggedResponseData: PaginatedResponses,
  flaggedWords: string[],
  multiEntityEnabled: boolean,
  isJourneyV2: boolean,
  handleViewSummaryClick: () => void,
  isViewSummaryVisible: boolean,
};

type TabOption = 'recent' | 'flagged';

export default class DashboardResponses extends React.Component<
  DashboardResponsesProps,
  {
    currentTab: TabOption,
  },
> {
  tabOptions: {
    label: string,
    value: TabOption,
  }[] = [
    {
      label: 'Flagged',
      value: 'flagged',
    },
    {
      label: 'Most Recent',
      value: 'recent',
    },
  ];

  state = {
    currentTab: this.tabOptions[0].value,
  };

  _handleChange(evt: *) {
    const {value} = evt.currentTarget;
    this.setState({
      currentTab: value,
    });
  }
  handleChange = this._handleChange.bind(this);

  render() {
    const {
      recentResponseData,
      flaggedResponseData,
      flaggedWords,
      multiEntityEnabled,
      isJourneyV2,
      handleViewSummaryClick,
      isViewSummaryVisible,
    } = this.props;
    const {currentTab} = this.state;
    const journeyPageRoute = isJourneyV2
      ? '/journeys/goal-selection'
      : '/journey/new';

    return (
      <div className={css.responseTabs}>
        <div className={css.subtitle}>Recent Responses</div>
        <div className={css.segmentedAndViewSummaryBlock}>
          <SegmentedButton
            className={css.responseSelect}
            options={this.tabOptions}
            defaultValue="flagged"
            value={currentTab}
            onChange={this.handleChange}
          />
          {isViewSummaryVisible && (
            <Button
              iconRightName="chevron-right"
              onClick={handleViewSummaryClick}
              size="medium"
              type="tertiary"
            >
              View Summary
            </Button>
          )}
        </div>
        {currentTab === 'flagged' && (
          <div className={css.responsesSection}>
            {flaggedResponseData.responses.length > 0 ? (
              <Responses
                responseData={flaggedResponseData}
                flagged={true}
                flaggedWords={flaggedWords}
                multiEntityEnabled={multiEntityEnabled}
              />
            ) : (
              <Zero
                className={css.zero}
                Icon={Phone}
                iconClassName={css.phone}
                title="No Flagged Responses Yet!"
                text="This is where you will see your flagged survey responses. Start by adding touchpoints and activating your journey."
                linkText="Create a journey"
                linkUrl={journeyPageRoute}
              />
            )}
          </div>
        )}
        {currentTab === 'recent' && (
          <div className={css.responsesSection}>
            {recentResponseData.responses.length > 0 ? (
              <Responses
                responseData={recentResponseData}
                multiEntityEnabled={multiEntityEnabled}
              />
            ) : (
              <Zero
                className={css.zero}
                Icon={Phone}
                iconClassName={css.phone}
                title="No Recent Responses Yet!"
                text="This is where you will see your recent survey responses. Start by adding touchpoints and activating your journey."
                linkText="Create a journey"
                linkUrl={journeyPageRoute}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
