// @noflow

import typeof IndexStore from 'src/stores/index';
import type {CompositeDecorator} from 'draft-js';
import type {DynamicLabels} from 'src/components/lib/markdown-editor';

import React, {Component} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';
import {EditorState, ContentState} from 'draft-js';
import {getSenseAiQuickTags} from 'src/utils/events';
import {classify} from 'src/utils/classify';

import {selectEntityTypeBySenseName} from 'src/selectors/ats-entities';
import {getNewEditorStateAfterReplacingPlaceholder} from './utils';
import {SMS_MAX_CHARACTER_LIMIT} from './constants';

import {updateModule} from 'src/actions/event-creation';
import {useJobMatchEnabledForEngage} from 'src/hooks/useAgencyConfig';
import Editor, {buildDecorator} from 'src/components/lib/markdown-editor/index';
import JobMatchSMSWrapper, {
  getAttachmentLinkMeta,
} from './job-match-sms-notification.jsx';
import {EngageEmailSenseAiWrapper} from 'src/components/workflow/event/sense-ai-wrapper.jsx';
import {EventTypeEnum} from 'src/stores/event';
import {pushModal} from 'src/action-creators/modal';

import {
  getPlainText,
  getLabels,
  normalizeDynamicLabelsInString,
} from 'src/components/lib/markdown-editor/dynamic-text.jsx';

import css from './module.css';


type NotificationProps = {
  parentEvent: ?Event,
  moduleData: {
    text: string,
    draftEditorState?: EditorState,
  },
  dynamicLabels: DynamicLabels,
  showErrors: boolean,
  eventId: string,
  index: string,
  fluxStore: IndexStore,
  error?: {
    errors: string[],
  },
};

class SMSWrapper extends Component<NotificationProps> {
  decorator: CompositeDecorator;
  initialEditorState: EditorState;

  constructor(props: NotificationProps) {
    super(props);
    this.decorator = this.getDecorator({
      showAddAttachmentLink: this.getAttachmentLinkMeta(),
    });

    this.initialEditorState = this.deserializeRTEContents(props);

    (this: any).handleEditorChange = this.handleEditorChange.bind(this);
  }

  getAttachmentLinkMeta() {
    return (
      this.props.parentEvent && getAttachmentLinkMeta(this.props.parentEvent)
    );
  }

  getDecorator = ({showAddAttachmentLink}) =>
    buildDecorator({
      dynamicLabels: this.props.dynamicLabels,
      showAddAttachmentLink,
      onPlaceholderReplace: () => {
        const reduxDispatch = this.props.fluxStore.reduxStore.dispatch;
        reduxDispatch(
          pushModal({
            type: 'VARIABLE_PICKER',
            insertResult: this.handlePlaceholderInsert,
            labels: this.props.dynamicLabels,
          }),
        );
      },
    });

  componentDidUpdate(prevProps) {
    if (prevProps.dynamicLabels !== this.props.dynamicLabels) {
      this.decorator = this.getDecorator({
        showAddAttachmentLink: this.getAttachmentLinkMeta(),
      });

      this.updateEditorDecorator();
    }
  }

  updateEditorDecorator() {
    const {draftEditorState} = this.props.moduleData;
    if (draftEditorState) {
      // update the current editorstate's decorators to include {survey_link} support
      const editorWithNewDecorator = EditorState.set(draftEditorState, {
        decorator: this.decorator,
      });
      this.handleEditorChange(editorWithNewDecorator);
    } else {
      // if no editor state is available, haven't called handelEditorChange once
      // so initialize it with the correct decorator list
      this.initialEditorState = this.deserializeRTEContents(this.props);
    }
  }

  deserializeRTEContents = (props: NotificationProps) => {
    const content = ContentState.createFromText(props.moduleData.text);
    return EditorState.createWithContent(content, this.decorator);
  };

  render() {
    const {
      error,
      moduleData: {draftEditorState, text},
    } = this.props;
    const editorState = draftEditorState || this.initialEditorState;

    const charCount = text ? text.length : 0;
    const textHasLabels =
      editorState &&
      getLabels(editorState.getCurrentContent()).filter((arr) => arr.length > 0)
        .size > 0;
    const warning = charCount > SMS_MAX_CHARACTER_LIMIT - 100;
    const errors = _get(error, 'text.errors', []);

    return (
      <NotificationModule
        editorState={editorState}
        text={text}
        warning={warning}
        errors={errors}
        charCount={charCount}
        dynamicLabels={this.props.dynamicLabels}
        textHasLabels={textHasLabels}
        onChange={this.handleEditorChange}
        attachmentLinkMeta={this.getAttachmentLinkMeta()}
      />
    );
  }

  handleEditorChange(draftEditorState: EditorState) {
    let text = getPlainText(draftEditorState);

    text = normalizeDynamicLabelsInString(this.props.dynamicLabels, text);

    updateModule(this.props.fluxStore, {
      text,
      draftEditorState,
    });
  }
  handlePlaceholderInsert = (dynamicLabel) => {
    const oldState =
      this.props.moduleData.draftEditorState ?? this.initialEditorState;
    getNewEditorStateAfterReplacingPlaceholder(
      dynamicLabel,
      oldState,
      (value) => {
        this.handleEditorChange(value);
      },
    );
  };
}

type NotificationModuleProps = {
  editorState: EditorState,
  warning: boolean,
  textHasLabels: boolean,
  errors: string[],
  dynamicLabels: DynamicLabels,
  charCount: number,
  onChange: (editorState: EditorState) => void,
  attachmentLinkMeta: ?AttachmentLinkMeta,
};

const NotificationModule = ({
  editorState,
  warning,
  textHasLabels,
  errors,
  dynamicLabels,
  charCount,
  onChange,
  attachmentLinkMeta,
}: NotificationModuleProps) => (
  <div className={css.generalModule_content}>
    <label className={css.smsNotification_label}>
      <span className={css.smsNotification_type}>Message</span>

      <span className={css.smsNotification_message}>
        {charCount > SMS_MAX_CHARACTER_LIMIT ? (
          <span className={css.smsNotification_error_message}>
            <strong>
              ({SMS_MAX_CHARACTER_LIMIT - charCount} characters),{' '}
            </strong>
            <strong>Max char limit {SMS_MAX_CHARACTER_LIMIT} reached!!</strong>
          </span>
        ) : (
          <span
            className={warning ? css.smsNotification_warning_message : null}
          >
            {textHasLabels ? 'Approx. ' : ''}
            {warning ? (
              <span>
                <strong>{SMS_MAX_CHARACTER_LIMIT - charCount}</strong>{' '}
                characters remaining*, Max char limit is{' '}
                {SMS_MAX_CHARACTER_LIMIT}
              </span>
            ) : (
              <span>
                <strong>{charCount}</strong> characters*
              </span>
            )}
          </span>
        )}
      </span>
    </label>

    <Editor
      value={editorState}
      hasFormatting={false}
      showErrors={errors.length > 0 || charCount > SMS_MAX_CHARACTER_LIMIT}
      dynamicLabels={dynamicLabels}
      showAddAttachmentLink={attachmentLinkMeta}
      onChange={onChange}
      maxCharacterLimit={SMS_MAX_CHARACTER_LIMIT}
    />
    <p className={css.smsNotificationNotice}>
      *Carriers may split messages into multiple parts. Variables may expand to
      various widths.
    </p>
  </div>
);
const FeatureFlagWrapperV1 = (props) => {
  const jobMatchEnabled = useJobMatchEnabledForEngage();
  //if parent event clientEvent type is sms_job
  const isJobMatchSMSMessageModule =
    props.parentEvent?.eventType === EventTypeEnum.SMS_Job || false;
  if (jobMatchEnabled && isJobMatchSMSMessageModule) {
    return <JobMatchSMSWrapper {...props} supportsJobMatch />;
  }
  return <SMSWrapper {...props} />;
};

const FeatureFlagWrapperV2 = (props) => {
  const {
    workflowId: journeyId,
    id: touchpointId,
    entityType,
  } = props.parentEvent;
  const jobMatchEnabled = useJobMatchEnabledForEngage();
  //if parent event clientEvent type is sms_job
  const isJobMatchSMSMessageModule =
    props.parentEvent?.eventType === EventTypeEnum.SMS_Job || false;
  const leadAtsEntityType = useSelector((state) =>
    selectEntityTypeBySenseName(state, 'lead'),
  );
  const internalEmployeeEntityType = useSelector((state) =>
    selectEntityTypeBySenseName(state, 'internal_employee'),
  );
  const [count, setCount] = React.useState(0);
  const key = `remount-control-key-${count}`;
  const initialSuggestion = props.moduleData?.text ?? '';
  return (
    <div
      className={classify(css.senseAiModuleContainerSms, {
        [css.jobMatch]: jobMatchEnabled === true,
      })}
    >
      <FeatureFlagWrapperV1 key={key} {...props} />
      <EngageEmailSenseAiWrapper
        defaultIntroOpen={
          initialSuggestion.length === 0 || initialSuggestion.length > 40
        }
        initialSuggestion={initialSuggestion}
        journeyId={journeyId}
        touchpointId={touchpointId}
        header="Auto-Generate SMS"
        allTags={getSenseAiQuickTags(props.parentEvent, {
          isCandidateEntity: leadAtsEntityType === entityType,
          isInternalEmployee: internalEmployeeEntityType === entityType,
        })}
        onConfirm={(content) => {
          const text = normalizeDynamicLabelsInString(
            props.dynamicLabels,
            content,
          );
          updateModule(props.fluxStore, {
            text,
            draftEditorState: undefined,
          });
          //invalidate existing editor so that it starts fresh
          setCount((count) => count + 1);
        }}
        type={
          jobMatchEnabled && isJobMatchSMSMessageModule ? 'sms-jobmatch' : 'sms'
        }
        showIntro={initialSuggestion === '' ? true : false}
      />
    </div>
  );
};

const FeatureFlagWrapper = (props) => {
  return <FeatureFlagWrapperV2 {...props} />;
};

export default FeatureFlagWrapper;
