// @noflow

import type {
  AnalyticsAudience,
  AtsAnalyticsFieldValues,
  AtsAnalyticsFieldMapping,
  AllowedTearsheetTypes,
} from 'src/types/ats-settings';

import type {AtsSettingsAction} from 'src/action-creators/ats-settings';

import {
  RECEIVE_ATS_MAPPINGS,
  RECEIVE_AUDIENCES,
  RECEIVE_FIELD_VALUES,
  RECEIVE_ALLOWED_TEARSHEET_TYPES,
  CUSTOM_MAPPING_SAVED,
  SET_MAPPING,
  EDIT_MAPPING,
  REMOVE_MAPPING,
} from 'src/action-creators/ats-settings';

import sculpt from 'sculpt';
import {uniqBy, findIndex, isArray} from 'lodash';


export type State = {
  mappings: AtsAnalyticsFieldMapping[],
  analyticsAudiences: AnalyticsAudience[],
  fieldValues: AtsAnalyticsFieldValues,
  allowedTearsheetTypes: AllowedTearsheetTypes,
};

const analyticsAudiences: AnalyticsAudience[] = [
  {
    name: 'active_candidates',
    entityType: 'candidate',
    description:
      'Active candidates are new candidates in your system who you consider actively engaged with your company. A common way to track this is by a candidate status.',
  },
  {
    name: 'passive_candidates',
    entityType: 'candidate',
    description:
      'Passive candidates, often referred to as database candidates, are candidates who you are not actively engaged with but want to keep in touch with. A common way to track this is by a candidate status.',
  },
  {
    name: 'current_placements',
    entityType: 'placement',
    description:
      'Active placements are all placements that are billing and valid. This is used to calculate performance metrics such as re-deployment, so you should filter it down only to contractors you want to track in that way. Common ways to track this include a placement status. This audience must include a placement field in the rules ',
  },
  {
    name: 'completed_placements',
    entityType: 'placement',
    description:
      'Completed placements are placements that have ended. Common ways to track these are by a placement status or end date. This audience must include a placement field in the rules.',
  },
].map(({name, entityType, description}) => ({
  name,
  entityType,
  description,
  id: null,
  rule: {
    and: [],
  },
}));

const initialState = {
  mappings: [],
  fieldValues: {},
  analyticsAudiences,
  allowedTearsheetTypes: null,
};

export default (
  state: State = initialState,
  action: AtsSettingsAction,
): State => {
  let targetIndex;
  switch (action.type) {
    case RECEIVE_ATS_MAPPINGS: {
      const {mappingType} = action.payload;
      let {mappings} = action.payload;
      if (mappingType === 'available') {
        mappings = mappings.reverse().map(mapping => ({...mapping, id: null}));
      }
      return {
        ...state,
        mappings: uniqBy(
          state.mappings.concat(mappings),
          mapping => mapping.analyticsField,
        ),
      };
    }

    case RECEIVE_FIELD_VALUES:
      return sculpt(state, {
        fieldValues: {
          $merge: action.payload,
        },
      });

    case CUSTOM_MAPPING_SAVED:
      return sculpt(state, {
        mappings: {$push: action.payload},
      });

    case SET_MAPPING: {
      const {payload} = action;
      const {analyticsField} = payload;
      targetIndex = findIndex(state.mappings, {analyticsField});
      return sculpt(state, {
        mappings: {
          [targetIndex]: {
            $set: action.payload,
          },
        },
      });
    }

    case EDIT_MAPPING: {
      const {id, field, value} = action.payload;
      targetIndex = findIndex(state.mappings, {id});
      return sculpt(state, {
        mappings: {
          [targetIndex]: {
            $merge: {[field]: value},
          },
        },
      });
    }

    case REMOVE_MAPPING: {
      const targetIndex = findIndex(state.mappings, {id: action.payload});
      return sculpt(state, {
        mappings: {
          $splice: [[targetIndex, 1]],
        },
      });
    }

    case RECEIVE_AUDIENCES: {
      let audiences = state.analyticsAudiences;
      let newAudiences = action.payload;

      if (!isArray(newAudiences)) {
        newAudiences = [newAudiences];
      }

      newAudiences.forEach(audience => {
        const {name} = audience;
        const targetIndex = findIndex(audiences, {name});
        if (targetIndex === -1) {
          return false;
        }
        audiences = sculpt(audiences, {
          [targetIndex]: {
            $merge: audience,
          },
        });
      });

      return {
        ...state,
        analyticsAudiences: audiences,
      };
    }

    case RECEIVE_ALLOWED_TEARSHEET_TYPES: {
      return {
        ...state,
        allowedTearsheetTypes: action.payload,
      };
    }
  }

  return state;
};
