// @flow strict

// $FlowFixMe[nonstrict-import]
import type {State} from 'src/reducers';
import type {
  Pages,
  Leads,
  Action,
  GenericError,
  Forms,
  SubDomainDetail,
  LeadsFilter,
  LeadsCreateFilterResponse,
  LeadForm,
  FilterViewCount,
  LeadFilterActionResponse,
  BeFreeJson,
} from 'src/types/landing-page';
import {LandingPageCurrentState} from 'src/reducers/landing-page';
import orderBy from 'lodash/orderBy';

import {createSelector} from 'reselect';


export const selectLandingPages = (state: State): ?(Pages[]) =>
  state.landingPage.pages
    ? orderBy(state.landingPage.pages, ['updated_at'], ['desc'])
    : [];

export const selectLandingPagesDetail = (state: State): Pages =>
  state.landingPage.pages_detail;

export const selectIfFormsLoading = (state: State): ?boolean =>
  state?.landingPage?.current_state ===
  LandingPageCurrentState.LOADING_PAGES_FORMS;

export const selectLandingPagesLeads = (state: State): ?(Leads[]) =>
  state.landingPage.leads;

export const selectLandingPagesLeadsLoading = (state: State): ?boolean =>
  state?.landingPage?.current_state ===
  LandingPageCurrentState.LOADING_PAGES_LEADS;

export const selectLandingPagesForms = (state: State): ?(Forms[]) =>
  state.landingPage.forms
    ? orderBy(state.landingPage.forms, ['time_updated'], ['desc'])
    : [];

export const selectLandingPagesFormsLoading = (state: State): ?boolean => {
  return (
    state?.landingPage?.current_state ===
    LandingPageCurrentState.LOADING_PAGES_FORMS
  );
};

export const selectLandingPagesFormsDetail = (state: State): ?(Pages[]) =>
  state.landingPage.formsDetail;

export const selectLandingPagesFormsDetailLoading = (state: State): ?boolean =>
  state?.landingPage?.current_state ===
  LandingPageCurrentState.FETCHING_PAGES_FORMS_DETAIL;

export const selectPagesAction = (state: State): Action =>
  state.landingPage.page_action;

export const selectPagesActionLoading = (state: State): ?boolean =>
  state?.landingPage?.current_state ===
  LandingPageCurrentState.LOADING_PAGES_ACTION_DETAIL;

export const selectIfUrlCheckLoading = (state: State): ?boolean =>
  state?.landingPage?.current_state ===
  LandingPageCurrentState.CHECKING_URL_AVAILABILITY;

export const selectPagesActionError = (state: State): ?GenericError =>
  state?.landingPage?.page_action_error;

export const selectFormError = (state: State): ?GenericError =>
  state?.landingPage?.forms_error;
/*
 * Base Selectors
 */

export const selectLandingPage = (state: State): {[string]: string} =>
  state.landingPage.page;

export const selectIfLandingPageLoading = (state: State): ?boolean =>
  state?.landingPage?.current_state ===
  LandingPageCurrentState.LOADING_PAGE_DETAIL;

export const selectLandingPageStep = (state: State): {[string]: string} =>
  state.landingPage.step;

export const selectFormUpdate = (state: State): {[string]: string} =>
  state.landingPage.update_form;

export const selectFormUpdateLoading = (state: State): ?boolean =>
  state?.landingPage?.current_state ===
  LandingPageCurrentState.LOADING_PAGE_UPDATE_FORM_ACTION;

export const selectFormUpdateError = (state: State): {[string]: string} =>
  state.landingPage.update_form_error;

export const selectAttributesLoading = (state: State): ?boolean =>
  state?.landingPage?.current_state ===
  LandingPageCurrentState.LOADING_ATTRIBUTE_ACTION;

export const selectAttributes = (state: State): {[string]: string} =>
  state?.landingPage?.attributes;

export const selectAttributesError = (state: State): {[string]: string} =>
  state.landingPage.attributes_error;

export const selectLeadUpdateLoading = (state: State): ?boolean =>
  state?.landingPage?.current_state ===
  LandingPageCurrentState.LOADING_PAGE_UPDATE_LEAD_ACTION;

export const selectLeadUpdateResponse = (state: State): ?boolean =>
  state.landingPage.update_lead;

export const selectLeadUpdateError = (state: State): {[string]: string} =>
  state.landingPage.update_lead_error;

export const selectEntityGroups = (state: State): {[string]: string} =>
  state?.landingPage?.entity_groups;

export const selectEntityGroupsLoading = (state: State): ?boolean =>
  state?.landingPage?.current_state ===
  LandingPageCurrentState.LOADING_ENTITY_GROUP_ACTION;

export const selectEntityGroupsError = (state: State): {[string]: string} =>
  state.landingPage.entity_groups_error;

export const selectCreateLandingPageSubDomain = (
  state: State,
): SubDomainDetail => state?.landingPage?.create_landing_page_subdomain;

export const selectCreateLandingPageSubDomainError = (
  state: State,
): {[string]: string} =>
  state?.landingPage?.create_landing_page_subdomain_error;

export const selectVerifyLandingPageSubDomain = (
  state: State,
): SubDomainDetail => state?.landingPage?.verify_subdomain;

export const selectVerifyLandingPageSubDomainError = (
  state: State,
): {[string]: string} => state?.landingPage?.verify_subdomain_error;
export const selectCandidateSyncStatus = (state: State): ?string =>
  state?.landingPage?.candidate_sync_status;

export const selectCandidateSyncStatusError = (
  state: State,
): {[string]: string} => state.landingPage.candidate_sync_status_error;

export const selectSelectedFilterView = (state: State): LeadsFilter => {
  return state.landingPage.leads_filter?.selected_view;
};

export const selectLeadFiltersViewList = (
  state: State,
): LeadsCreateFilterResponse[] => {
  return state.landingPage?.leads_filter_list?.filters;
};

export const selectLeadFiltersViewSaveApply = (state: State): LeadForm[] => {
  return state.landingPage?.leads_filter_save_apply;
};

export const selectLeadFiltersViewCount = (state: State): FilterViewCount => {
  return state.landingPage?.leads_filter_count;
};

export const selectLeadViewFilterActionResponse = (
  state: State,
): LeadFilterActionResponse => {
  return state.landingPage?.leads_filter_action_response;
};

export const selectDefaultPageTemplate = (state: State): BeFreeJson => {
  return state.landingPage?.pages_default_template?.beefree_json;
};
