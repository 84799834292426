// @flow strict
import * as React from 'react';
import {useSelector} from 'react-redux';
import css from './onboard-page-navigator.css';
//$FlowFixMe
import {useLoadingApi} from 'src/hooks/useApi';
import onboardMaincss from 'src/components/agency-onboard/agency-onboard.css';
import {Loading} from 'src/components/lib/loading';
import {
  Clickable,
  Header,
  Smallest,
} from 'src/designSystem2021Components/text-v2.jsx';
//$FlowFixMe[nonstrict-import]
import {selectAgencyData} from 'src/selectors/agency';
import {AnalyticsService} from 'src/analytics';
import {AMPLITUDE_EVENTS} from 'src/components/agency-onboard/amplitude_event.js';

import {useHistory} from 'src/rerouter';
import ArrowRightIcon from 'src/images/icons/arrow-right-no-fill.svg';
import classify from 'src/utils/classify';
import onboardheadercss from 'src/components/agency-onboard/onboard-header/onboard-header.css';
import SShapeLogo from 'src/components/agency-onboard/images/s-shape-logo.svg';


export default function OnboardPageNavigator(): React.Node {
  const router = useHistory();
  const agencyData = useSelector(selectAgencyData);

  const handleTrackingEvent = (event) => {
    AnalyticsService.track(event, {
      agencyId: agencyData.id,
      agencyName: agencyData.name,
    });
  };

  const goToOnboard = () => {
    handleTrackingEvent(AMPLITUDE_EVENTS.GO_TO_TASK_CHECKLIST_CLICKED);
    router.replace('/onboard');
  };

  const numToWordMap = {
    [1]: 'One',
    [2]: 'Two',
    [3]: 'Three',
    [4]: 'Four',
    [5]: 'Five',
    [6]: 'Six',
    [7]: 'Seven',
    [8]: 'Eight',
    [9]: 'Nine',
    [10]: 'Ten',
  };
  const {response: tasksStatus, loaded} = useLoadingApi(
    () => 'onboard/status-stats',
    [],
  );

  if (!loaded || !tasksStatus) {
    return (
      <div>
        <Loading className={onboardMaincss.loaderDiv} />
      </div>
    );
  }

  if (tasksStatus['user_action_pending_for']) {
    return (
      <div className={css.pendingActionsContainer}>
        <div className={css.textContent}>
          <Header className={css.headerText}>
            {numToWordMap[tasksStatus['user_action_pending_for']] || ''}{' '}
            Incomplete Tasks!
          </Header>
          <div>
            <Smallest>
              {' '}
              Please return to task checklist to complete them{' '}
            </Smallest>
          </div>
        </div>
        <div
          className={classify(css.dashboardBtnDiv, onboardMaincss.text)}
          onClick={() => goToOnboard()}
        >
          <Clickable> Go to Task Checklist </Clickable>
          <ArrowRightIcon className={css.icon} />
        </div>

        <div className={css.bottomQuarter3}>
          <div className={css.bottomQuarter2}>
            <div className={css.bottomQuarter1}></div>
          </div>
        </div>

        <div className={css.sShapeLogo}>
          <SShapeLogo />
        </div>
      </div>
    );
  }
  return <></>;
}
