// @noflow

import * as React from 'react';
import {findDOMNode} from 'react-dom';
import noop from 'lodash/noop';
import {getScrollTop} from 'src/utils';
import throttleAnimation from 'src/utils/throttle-animation';

/**
 * ScrollView
 * Passes "in view" information to a child render prop function.
 * Useful for changing the style of an element when the user scrolls
 * past it.
 */

type Handler = () => void;
type ScrollState = {
  inView: boolean,
  above: boolean,
  below: boolean,
  scrollTop?: number,
  scrollLeft?: number,
  width?: number,
  offsetTop?: number,
  offsetLeft?: number,
};

type Props = {
  onOut: Handler,
  onIn: Handler,
  onOutUp: Handler,
  onInUp: Handler,
  children: (obj: ScrollState) => ?React.Node<any>,
};

export default class ScrollView extends React.Component<Props, ScrollState> {
  static defaultProps = {
    onOut: noop,
    onIn: noop,
    onOutUp: noop,
    onInUp: noop,
  };

  appEl: ?window;
  handleScroll: Handler;
  update: Handler;

  // `top` represents the vertical offset from origin where
  // the ScrollView was rendered when it initially mounted
  top = 0;
  // similarly, `left` represents the horizontal offset
  left = 0;
  lastAppTop = 0;
  lastAppLeft = 0;
  lastWindowWidth = 0;
  ticking = false;
  inView = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      inView: false,
      below: false,
      above: true,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.update = throttleAnimation(this.update.bind(this));
  }

  componentDidMount() {
    // TODO(marcos): allow to pass in id or default to body/etc
    const appElement = window;
    if (appElement) {
      appElement.addEventListener('scroll', this.handleScroll);
      appElement.addEventListener('resize', this.handleScroll);
      this.appEl = appElement;
      this.setBox();
    }
  }

  componentWillUnmount() {
    const appElement = this.appEl;
    if (appElement) {
      appElement.removeEventListener('scroll', this.handleScroll);
      appElement.removeEventListener('resize', this.handleScroll);
    }
    this.appEl = null;
  }

  setBox() {
    // calculate position of scroll container on mount:
    // based on the scrollY
    if (this.appEl) {
      const scrollElt = findDOMNode(this);
      const top = getScrollTop();
      const box = findDOMNode(this).getBoundingClientRect();
      this.top = box.top + top;
      this.left = scrollElt.offsetLeft;
      this.lastAppTop = top;

      this.setState({offsetTop: this.top, offsetLeft: this.left});
    }
  }

  handleScroll() {
    if (this.appEl) {
      const appEl = this.appEl;
      this.lastAppTop = getScrollTop();
      this.lastAppLeft = appEl.scrollX;
      this.lastWindowWidth = appEl.clientWidth;
      this.left = findDOMNode(this).offsetLeft;
    }
    this.update();
  }

  update() {
    if (!this.appEl) {
      return;
    }

    const y = this.lastAppTop;
    const {inView} = this.state;

    const dimensions = {
      scrollTop: this.lastAppTop,
      scrollLeft: this.lastAppLeft,
      width: this.lastWindowWidth,
      offsetLeft: this.left,
    };

    if (inView) {
      if (y < this.top) {
        this.setState({inView: false, above: true});
        this.props.onOut();
        this.props.onOutUp();
      }
    } else {
      if (y >= this.top) {
        this.setState({inView: true, above: false});
        this.props.onIn();
        this.props.onInUp();
      }
    }
    this.setState({...dimensions});
  }

  render() {
    const {children} = this.props;
    return children && children(this.state);
  }
}
