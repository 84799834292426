// @flow

import type {Dispatch, GetState, ThunkAction} from 'src/reducers';
import type {Action} from 'src/types/redux';
import type {Thread} from 'src/types/messages';
import type {Contact} from 'src/types/contacts';
import type {AudienceMember} from 'src/types/audience-member';

import {thunkify as flow} from 'src/utils/thunks';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api-v2';
import {camel} from 'src/utils';

import {batch, type BatchAction} from 'src/action-creators/batch';
import {receiveManyContacts} from 'src/action-creators/contacts';
import {receiveAudienceMembers} from 'src/action-creators/audience-members/audience-members';
import {selectOrderedThreads} from 'src/selectors/threads';
import {selectPhoneNumberSetFromDefaultPhoneNumberSet} from 'src/selectors/chat';


type ReceivePageAction = Action<
  'threads/receivePage',
  {
    threads: Thread[],
    offset?: number,
  },
>;

export type ThreadAction = ReceivePageAction;

export const RECEIVE_THREADS = 'threads/receivePage';
const receiveThreads = (
  threads: Thread[],
  offset?: number,
): ReceivePageAction => ({
  type: RECEIVE_THREADS,
  payload: {
    threads,
    offset,
  },
});

export const receivePopulatedThreads = (
  threads: Thread[],
  contacts: Contact[],
  audienceMembers: AudienceMember[],
  offset?: number,
): BatchAction =>
  batch(
    receiveThreads(threads, offset),
    receiveManyContacts(contacts),
    receiveAudienceMembers(audienceMembers),
  );

export const PAGE_LIMIT = 20;

//TODO:Aditya merge with actioncreator messages.js
export const getThreads: (
  phoneNumberSetId: string,
  offset?: number,
) => ThunkAction<mixed> = flow(
  key(
    (phoneNumberSetId, offset = 0) =>
      `threads/getPage:${offset}/${phoneNumberSetId}`,
  ),
  cached((json, phoneNumberSetId, offset = 0) => {
    json = camel(json);
    return receivePopulatedThreads(
      json.threads,
      json.contacts,
      json.audienceMembers,
      offset,
    );
  }),
  fetching(),
)((phoneNumberSetId: string, offset: number = 0) =>
  reduxApi.get('messages_v2/inbox', {
    offset,
    phone_number_set_id: phoneNumberSetId,
    threads: PAGE_LIMIT,
  }),
);

export const getMoreThreads =
  (): ThunkAction<mixed> => (dispatch: Dispatch, getState: GetState) => {
    const offset = selectOrderedThreads(getState()).length;
    const phoneNumberSetId = selectPhoneNumberSetFromDefaultPhoneNumberSet(
      getState(),
    );

    return dispatch(getThreads(phoneNumberSetId, offset));
  };
