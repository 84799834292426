// @noflow

import * as React from 'react';

import css from './filter-button.css';
import {classify} from 'src/utils';


type FilterButtonProps = {
  active: boolean,
  children: React.Node<*>,
  activeFilters?: number,
  className?: string,
  onClick?: (evt: Event) => any,
};

export default class FilterButton extends React.Component<FilterButtonProps> {
  static defaultProps = {
    active: false,
  };

  render() {
    const {active, activeFilters, className, children, onClick} = this.props;
    return (
      <button
        onClick={onClick}
        className={classify(
          active ? css.activeButton : css.inactiveButton,
          className,
        )}
      >
        {children}
        {activeFilters !== undefined &&
          activeFilters > 0 && <span> • {activeFilters}</span>}
      </button>
    );
  }
}
