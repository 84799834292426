// @noflow

import * as React from 'react';

import InfoIcon from 'src/images/i.svg?noAttrs';

import css from './warning.css';


type Props = {
  className: string,
  children: any,
};

const Warning = ({className = '', children}: Props) => (
  <div className={css.audienceNotice + ' ' + className}>
    <InfoIcon className={css.audienceNoticeIcon} />
    <div className={css.content}>{children}</div>
  </div>
);

export default Warning;
