var React = require('react');

function DoneAll (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M12 4.667l-.94-.94-4.227 4.226.94.94L12 4.667zm2.826-.94L7.773 10.78 4.987 8l-.94.94 3.726 3.727 8-8-.947-.94zM.273 8.94L4 12.667l.94-.94L1.22 8l-.947.94z"}));
}

DoneAll.defaultProps = {"width":"16","height":"16","viewBox":"0 0 16 16"};

module.exports = DoneAll;

DoneAll.default = DoneAll;
