// @noflow

import type {FieldMap} from 'src/types/audience-member';
import type {FieldsAction} from 'src/action-creators/audience-members/fields';

import {RECEIVE_ALL} from 'src/action-creators/audience-members/fields';


export type State = FieldMap;

export default (state: State = {}, action: FieldsAction): State => {
  switch (action.type) {
    case RECEIVE_ALL: {
      return action.payload;
    }
  }

  return state;
};
