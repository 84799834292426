var React = require('react');

function ReportsIcon (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M13.223 3.695L10.305.742A1.736 1.736 0 009.109.25H1.938C.988.25.25 1.023.25 1.938v14.624c0 .95.738 1.688 1.688 1.688h10.124c.915 0 1.688-.738 1.688-1.688V4.892c0-.422-.21-.88-.527-1.196zm-.774.809c.07.07.106.176.14.246H9.25V1.41c.07.035.176.07.246.14l2.953 2.954zm-.386 12.621H1.937a.555.555 0 01-.562-.563V1.938c0-.28.246-.562.563-.562h6.187v3.656c0 .492.352.844.844.844h3.656v10.688a.578.578 0 01-.563.562zm-1.688-8.578c0-.211-.21-.422-.422-.422H4.047a.427.427 0 00-.422.422v.281c0 .246.176.422.422.422h5.906a.427.427 0 00.422-.422v-.281zm0 2.25a.454.454 0 00-.422-.422H4.047a.427.427 0 00-.422.422v.281c0 .246.176.422.422.422h5.906a.427.427 0 00.422-.422v-.281zm0 2.25a.454.454 0 00-.422-.422H4.047a.427.427 0 00-.422.422v.281c0 .246.176.422.422.422h5.906a.427.427 0 00.422-.422v-.281z"}));
}

ReportsIcon.defaultProps = {"width":"14","height":"19","viewBox":"0 0 14 19"};

module.exports = ReportsIcon;

ReportsIcon.default = ReportsIcon;
