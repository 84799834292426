var React = require('react');

function SchedulingIcon (props) {
    return React.createElement("svg",props,[React.createElement("path",{"d":"M8.378 3.546a3.5 3.5 0 00-4.97 4.593l.89-.472a2.493 2.493 0 013.54-3.27l.54-.85zM10.591 8a.3.3 0 00-.3.3v3.74a.82.82 0 00.206.568l2.266 2.426a.3.3 0 00.424.015l.334-.312a.3.3 0 00.015-.424l-2.167-2.32V8.3a.3.3 0 00-.3-.3h-.478z","key":0}),React.createElement("path",{"fillRule":"evenodd","clipRule":"evenodd","d":"M15.548 17.321A6.972 6.972 0 0111 19a6.973 6.973 0 01-4.556-1.685L4.86 18.898a.3.3 0 01-.424 0l-.354-.354a.3.3 0 010-.424l1.585-1.585a7 7 0 1110.658.008l1.578 1.577a.3.3 0 010 .424l-.354.354a.3.3 0 01-.424 0l-1.577-1.577zM4.995 12a6.005 6.005 0 1012.01 0 6.005 6.005 0 00-12.01 0z","key":1}),React.createElement("path",{"d":"M13.622 3.546a3.5 3.5 0 014.97 4.593l-.89-.472a2.492 2.492 0 00-3.54-3.27l-.54-.85z","key":2})]);
}

SchedulingIcon.defaultProps = {"width":"22","height":"22","viewBox":"0 0 22 22"};

module.exports = SchedulingIcon;

SchedulingIcon.default = SchedulingIcon;
