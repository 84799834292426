// @flow strict

import type {SMSChatbotState} from 'src/types/sms-chatbot';
// $FlowFixMe[nonstrict-import]
import type {Dispatch, ThunkAction} from 'src/reducers';
// $FlowFixMe[nonstrict-import]
import * as reduxApiBff from 'src/utils/redux-api-bff';
// $FlowFixMe[nonstrict-import]
import * as reduxApi from 'src/utils/redux-api-v2';
import {parseSMSChatbot} from 'src/utils/sms-chatbot';


export const RECEIVE_SMS_CHATBOT_INFO = 'sms-chatbot/receive';
export const UPDATE_SMS_CHATBOT_INFO = 'sms-chatbot/update';

export const RESET = 'sms-chatbot/reset';

type UpdateSMSChatbotInfoPayload = $Shape<SMSChatbotState>;

type ReceiveSMSChatbotInfoAction = {
  type: 'sms-chatbot/receive',
  payload: SMSChatbotState,
};

type UpdateSMSChatbotInfoAction = {
  type: 'sms-chatbot/update',
  payload: UpdateSMSChatbotInfoPayload,
};

type ResetStateAction = {
  type: 'sms-chatbot/reset',
};

export type SMSChatbotAction =
  | ReceiveSMSChatbotInfoAction
  | UpdateSMSChatbotInfoAction
  | ResetStateAction;

export const reset = (): ResetStateAction => ({type: RESET});

export const receiveSMSChatbotNode = (
  payload: SMSChatbotState,
): ReceiveSMSChatbotInfoAction => ({
  type: RECEIVE_SMS_CHATBOT_INFO,
  payload,
});

export const updateSMSChatbotInfo = (
  payload: UpdateSMSChatbotInfoPayload,
): UpdateSMSChatbotInfoAction => ({
  type: UPDATE_SMS_CHATBOT_INFO,
  payload,
});

export const getSMSChatbotTaskNode: (id: string) => ThunkAction<mixed> =
  (id) => async (dispatch: Dispatch) => {
    const fetchedNodeResponse = await dispatch(
      reduxApiBff.get(`node`, {
        node_id: `task:${id}`,
      }),
    );
    const parsedSmsNode = parseSMSChatbot(fetchedNodeResponse);
    return dispatch(updateSMSChatbotInfo(parsedSmsNode));
  };

export const getProvisionedPhoneNumbers: (
  id: string | number,
) => ThunkAction<mixed> = (id) => (dispatch: Dispatch) => {
  return dispatch(
    reduxApi.get(`provisioned-phone`, {
      search_query: '',
      associated_with_category: String(true),
      category_id: String(id),
    }),
  );
};
