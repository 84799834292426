// @flow
// $FlowFixMe[untyped-type-import]
import typeof IndexStore from 'src/stores/index';
import type {
  ApiQuestion,
  MultipleChoiceOption,
  BranchId,
} from 'src/types/survey';

import * as React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import keyBy from 'lodash/keyBy';
import findIndex from 'lodash/findIndex';
import uniqueId from 'lodash/uniqueId';

import {selectEntityTypeMappingsByName} from 'src/selectors/ats-entities';
import {createDynamicLabelSelector} from 'src/selectors/dynamic-labels';
import {
  WritebackContext,
  UPDATE_QUESTION,
  UPDATE_QUESTION_TYPE,
  type QuestionType,
} from 'src/components/writeback/writeback-wrapper.jsx';
import {questionPrototypes} from 'src/actions/event';
import {showGenericConfirm} from 'src/action-creators/modal';
import {
  getMaxAllowedChoiceNumber,
  getMultiSelectOptions,
} from 'src/utils/sense-event';
import {values} from 'src/utils/object';
import {formatUnsyncFieldForVariablePicker} from 'src/utils/writeback';
import {useNewAllowedAttributes} from 'src/hooks/product-flags.js';
import {useOWBFields} from 'src/components/conversation-builder/hooks';

import BasicDropdown from 'src/components/lib/basic-dropdown';
import Checkbox from 'src/components/lib/checkbox';
import Toggle from 'src/components/lib/toggle/toggle.jsx';
import Loading from 'src/components/lib/loading/loading.jsx';
import {VariableModalInput} from 'src/components/lib/variable-picker';
import Select from 'src/components/lib/select/select.jsx';

import moduleCss from 'src/components/workflow/event/content/module/editors/module.css';
import css from './survey-module-options.css';


export const questionTypeDisplay = {
  calendar_date_survey_question: 'Calendar Date',
  file_upload_survey_question: 'File Upload',
  multiple_choice_survey_question: 'Multiple Choice',
  nps_survey_question: 'NPS Scale',
  rating_scale_survey_question: 'Rating Scale',
  text_survey_question: 'Text Response',
};

export const supportedQuestionTypes = [
  'text_survey_question',
  'rating_scale_survey_question',
  'nps_survey_question',
  'multiple_choice_survey_question',
  'calendar_date_survey_question',
];

//note: question types in this array will have answers prefileld for audience when they open the survey
export const supportedPrefillTypes = [
  'text_survey_question',
  'multiple_choice_survey_question',
  'calendar_date_survey_question',
];

const selectDynamicLabels = createDynamicLabelSelector();

function getConfirmModalText(
  choices: MultipleChoiceOption[],
  branchIds: BranchId[],
): string {
  const branchText = `You have created branching based on the potential response to this multiple choice question. 
  By confirming, you will permanently delete the branch and all modules within that branch. 
  You will need to recreate them.`;

  const choicesText = `You have already created options (potential responses) for this multiple choice question. 
  By confirming, you will permanently delete those options, and you will need to recreate them.`;

  if (choices.length > 0 && branchIds.length > 0) {
    return `${branchText} ${choicesText}`;
  } else if (choices.length > 0) {
    return choicesText;
  } else if (branchIds.length > 0) {
    return branchText;
  }

  return ``;
}

export const SurveyModuleOptions = ({
  fluxStore,
  error,
}: {
  // $FlowFixMe[value-as-type] [v1.32.0]
  fluxStore: IndexStore,
  error?: {
    writeback?: {
      attribute_name?: {
        errors: string[],
      },
    },
    ...
  },
}): React.Element<'div'> => {
  const {state, dispatch} = React.useContext(WritebackContext);
  const {module_attributes, question, entity_type, attribute_meta_data} = state;
  const atsEntityMappings = useSelector(selectEntityTypeMappingsByName);

  const currentQuestionType = question.selectedType;
  const currentQuestion = question[currentQuestionType];
  const dynamicLabels = useSelector((state) =>
    entity_type ? selectDynamicLabels(state, entity_type) : [],
  );
  const reduxStoreDispatcher = useDispatch();
  const usesOWB = useNewAllowedAttributes();

  const unsyncedFields =
    attribute_meta_data &&
    entity_type &&
    values(attribute_meta_data)
      ?.filter(({synced_attribute, attribute_name}) => {
        const questionTypeLabels = module_attributes?.[currentQuestionType];
        return (
          !synced_attribute && questionTypeLabels?.includes(attribute_name)
        );
      })
      .map((field) =>
        formatUnsyncFieldForVariablePicker(
          field,
          entity_type,
          atsEntityMappings,
          usesOWB,
        ),
      );
  const writebackDynamicLabels =
    dynamicLabels &&
    dynamicLabels.filter((label) => {
      const questionTypeLabels = module_attributes?.[currentQuestionType];
      return questionTypeLabels && questionTypeLabels.includes(label.value);
    });
  const fieldsIncludingUnsyncedFields =
    writebackDynamicLabels &&
    unsyncedFields &&
    writebackDynamicLabels.concat(unsyncedFields);

  const writeback = currentQuestion.writeback && currentQuestion.writeback;
  const writebackField = writeback?.attribute_name;

  const writebackFieldValue =
    fieldsIncludingUnsyncedFields && fieldsIncludingUnsyncedFields?.length > 0
      ? writebackField
      : undefined;

  const questionTypes = React.useMemo(
    () =>
      module_attributes &&
      Object.keys(module_attributes)
        .filter((questionType) => supportedQuestionTypes.includes(questionType))
        .map((questionType) => ({
          label: questionTypeDisplay[questionType],
          value: questionType,
          disabled:
            writeback?.enabled &&
            writebackField &&
            !module_attributes[questionType].includes(writebackField),
        })),
    [module_attributes, writebackField, writeback?.enabled],
  );

  const includesDisabledQuestionTypes =
    findIndex(questionTypes, (type) => type.disabled) >= 0;

  // $FlowFixMe[incompatible-type] types/surveys
  const questionTypesByValue: {[key: QuestionType]: ApiQuestion} = keyBy(
    questionTypes,
    'value',
  );

  //We cannot support prefill when a multi select variable is selected but multi select is disabled
  const isMultiSelectDisabled =
    // $FlowFixMe[incompatible-type] types/surveys
    attribute_meta_data?.[writebackField]?.allow_select_multiple &&
    // $FlowFixMe[prop-missing] types/surveys
    !currentQuestion.allow_select_multiple;

  const prefillSupported =
    supportedPrefillTypes.includes(currentQuestionType) &&
    writeback?.enabled &&
    !isMultiSelectDisabled;

  // TODO(marcos): or somebody else. this is an override which forces the pre-owb field behavior
  // when engage/journeys add support for the new owb attributes, REMOVE THIS BOOLEAN PARAMETER
  const {getWbFieldId} = useOWBFields(false);

  const updateQuestion = (attrs) => {
    dispatch({
      type: UPDATE_QUESTION,
      payload: {
        questionType: currentQuestionType,
        // $FlowFixMe[incompatible-call] types/surveys
        attrs,
      },
    });
  };

  const handleSelectVariable = (variable) => {
    const field = variable?.value;
    const choices_text =
      currentQuestionType === 'multiple_choice_survey_question'
        ? // $FlowFixMe optional chaining
          attribute_meta_data[field]?.possible_values?.reduce(
            (result, choice) => {
              result[choice] = choice;
              return result;
            },
            {},
          )
        : undefined;

    updateQuestion({
      choices: [],
      allow_select_multiple: false,
      writeback: {
        enabled: true,
        attribute_name: field,
        show_current_value: prefillSupported,
        data_type: attribute_meta_data?.[field]?.data_type,
        ats_field_mapping_id: getWbFieldId(attribute_meta_data?.[field]),
        choices_text, //setting default choices text mapping
      },
      customMultipleChoiceOptions: [],
    });
  };

  const handleToggleWriteback = () => {
    const writebackEnabled = writeback?.enabled;
    const updatedWriteback = {
      // $FlowFixMe[prop-missing] types/surveys
      ...writeback,
      enabled: !writebackEnabled,
      show_current_value: !writebackEnabled,
    };

    if (currentQuestion.type === 'multiple_choice_survey_question') {
      const {choices, branchIds} = currentQuestion;
      const writebackField = writeback?.attribute_name;
      const fieldPossibleValues =
        writebackField &&
        state.attribute_meta_data?.[writebackField]?.possible_values;

      const confirmModalText = getConfirmModalText(choices, branchIds);

      const showModal = branchIds.length > 0 || choices.length > 0;

      const doToggle = () => {
        if (!writeback?.enabled) {
          updateQuestion({
            writeback: updatedWriteback,
            choices: writebackField
              ? choices.filter(({value}) =>
                  // $FlowFixMe optional chaining
                  fieldPossibleValues?.includes(value),
                )
              : [],
            customMultipleChoiceOptions: [],
          });
        } else {
          updateQuestion({
            writeback: null,
            choices: currentQuestion.choices.length
              ? currentQuestion.choices
              : [
                  {value: '', id: uniqueId('choice_')},
                  {value: '', id: uniqueId('choice_')},
                ],
            customMultipleChoiceOptions: [],
          });
        }
      };

      if (showModal) {
        reduxStoreDispatcher(
          showGenericConfirm({
            title: 'Really save responses to an ATS field?',
            text: confirmModalText,
            confirmText: 'Confirm',
          }),
        ).then((yes) => {
          if (yes) {
            doToggle();
          }
        });
      } else {
        doToggle();
      }
    } else {
      updateQuestion({writeback: updatedWriteback});
    }
  };

  const metaData = writebackField
    ? attribute_meta_data?.[writebackField]
    : null;
  const dataType = metaData?.data_type;
  const maxFieldLength = writeback?.enabled && metaData?.max_field_length;

  const maxAllowedChoiceNumber = React.useMemo(
    () =>
      currentQuestion.choices &&
      // $FlowFixMe[incompatible-call] types/surveys
      // $FlowFixMe[prop-missing] types/surveys
      getMaxAllowedChoiceNumber(currentQuestion.choices, maxFieldLength),
    // $FlowFixMe[prop-missing] types/surveys
    [currentQuestion.choices, currentQuestion.writeback?.enabled],
  );

  const enableAllowMultiple =
    (!writeback?.enabled &&
      currentQuestionType === 'multiple_choice_survey_question') ||
    // $FlowFixMe[incompatible-type] types/surveys
    (attribute_meta_data?.[writebackField]?.allow_select_multiple &&
      maxAllowedChoiceNumber != null && //check existance for flow
      maxAllowedChoiceNumber > 1);

  const multiSelectOptions = React.useMemo(
    () =>
      (enableAllowMultiple &&
        maxAllowedChoiceNumber != null &&
        maxAllowedChoiceNumber > 0 &&
        getMultiSelectOptions(
          maxAllowedChoiceNumber,
          // $FlowFixMe[prop-missing] types/surveys
          // $FlowFixMe[incompatible-use]
          currentQuestion.choices.length,
        )) ||
      [],
    [
      enableAllowMultiple,
      // $FlowFixMe[prop-missing] types/surveys
      currentQuestion.choices?.length,
      // $FlowFixMe[prop-missing] types/surveys
      currentQuestion.writeback?.enabled,
      maxAllowedChoiceNumber,
    ],
  );

  const selectedMultiSelectLimit =
    // $FlowFixMe[prop-missing] types/surveys
    currentQuestion.multi_select_limit &&
    multiSelectOptions?.find(
      ({value}) => value === currentQuestion.multi_select_limit,
    );
  const multiSelectLimitDefaultValue = React.useMemo(
    () =>
      selectedMultiSelectLimit ??
      multiSelectOptions[multiSelectOptions.length - 1],
    [multiSelectOptions],
  );
  React.useEffect(() => {
    if (enableAllowMultiple && !selectedMultiSelectLimit) {
      // $FlowFixMe[prop-missing] types/surveys
      const updatedLimit = currentQuestion.writeback?.enabled
        ? multiSelectOptions.length
        : 1;
      updateQuestion({
        multi_select_limit: updatedLimit,
        allow_select_multiple: updatedLimit > 1,
      });
    }
  }, [multiSelectOptions]);

  const writebackErrors = error?.writeback?.attribute_name?.errors;

  const isNumberField = dataType === 'number' || dataType === 'currency';
  const textString = maxFieldLength
    ? `Up to ${maxFieldLength?.toLocaleString()} characters`
    : 'Any characters';

  const answerFormat = {
    text_survey_question: isNumberField ? 'Numbers only' : textString,
    rating_scale_survey_question: 'A number from 1 to 10',
    nps_survey_question: 'A number from 1 to 10',
    multiple_choice_survey_question: 'one choice',
    calendar_date_survey_question: 'A date (date picker widget)',
  };

  return (
    <div className={css.container}>
      <div className={css.row}>
        <div className={css.questionTypeDropdownContainer}>
          <div className={css.labelText}>Question Type</div>
          <BasicDropdown
            hintText={
              includesDisabledQuestionTypes &&
              'Some options are incompatible with the selected ATS field'
            }
            options={questionTypes}
            selected={questionTypesByValue[currentQuestionType]}
            onChange={(selected) => {
              let newQuestionAttrs;
              if (question[selected.value]) {
                newQuestionAttrs = question[selected.value];
              } else {
                if (selected.value === 'multiple_choice_survey_question') {
                  newQuestionAttrs = {
                    ...questionPrototypes(fluxStore)[selected.value],
                    writeback: {enabled: false},
                    choices: [],
                  };
                } else {
                  newQuestionAttrs = {
                    ...questionPrototypes(fluxStore)[selected.value],
                    writeback: {enabled: false},
                  };
                }
              }

              dispatch({
                type: UPDATE_QUESTION_TYPE,
                payload: {
                  questionType: selected.value,
                  newQuestionAttrs,
                },
              });
            }}
            qaId="question-type"
          />
        </div>

        <div className={css.fieldPickerContainer}>
          <div className={css.labelText}>Save response to an ATS field?</div>
          <div>
            <Toggle
              className={css.atsToggle}
              // $FlowFixMe[prop-missing] types/surveys
              checked={currentQuestion.writeback?.enabled}
              onChange={() => handleToggleWriteback()}
            />
            <div className={css.groupedInputs}>
              {
                // $FlowFixMe[prop-missing] types/surveys
                currentQuestion?.writeback?.enabled ? (
                  <VariableModalInput
                    placeholder="select variable"
                    value={writebackFieldValue}
                    errors={writebackErrors}
                    options={fieldsIncludingUnsyncedFields || []}
                    onChange={(variable) => handleSelectVariable(variable)}
                    data-qa-id="writeback-field-input"
                    entityType={entity_type}
                    allowUnsyncedFields
                  />
                ) : (
                  <input
                    type="text"
                    disabled
                    placeholder="No, save to Sense account only"
                    className={css.disabledInput}
                  />
                )
              }
            </div>
          </div>
        </div>
      </div>

      {error?.writeback?.attribute_name && (
        <div className={moduleCss.errors}>
          {error?.writeback?.attribute_name?.errors?.map((error) => (
            <div className={moduleCss.error}>{error}</div>
          ))}
        </div>
      )}

      <div className={css.row}>
        <div className={css.answerFormat}>
          <div className={css.labelText}>{`Recipient may ${
            currentQuestionType === 'multiple_choice_survey_question'
              ? 'select'
              : 'enter'
          }`}</div>
          <div>
            {enableAllowMultiple ? (
              <Select
                className={css.multiSelectDropdown}
                options={multiSelectOptions}
                value={selectedMultiSelectLimit || multiSelectLimitDefaultValue}
                onChange={(choiceLimit) =>
                  updateQuestion({
                    multi_select_limit: choiceLimit?.value,
                    allow_select_multiple: choiceLimit && choiceLimit.value > 1,
                  })
                }
              />
            ) : (
              <input
                className={css.disabledInput}
                disabled
                // $FlowFixMe[prop-missing] types/surveys
                value={answerFormat[currentQuestionType]}
              />
            )}
          </div>
        </div>

        <label className={css.showCurrentValue}>
          <div className={css.labelText}>
            Show recipient this field's current value?
          </div>
          <div className={css.groupedInputs}>
            <Checkbox
              // $FlowFixMe[incompatible-type] types/surveys
              // $FlowFixMe[prop-missing] types/surveys
              checked={currentQuestion.writeback?.show_current_value}
              disabled={!currentQuestion.writeback || !prefillSupported}
              onChange={(event) => {
                updateQuestion({
                  writeback: {
                    ...(currentQuestion.writeback || {}),
                    show_current_value: event.target.checked,
                  },
                });
              }}
            />
            <span>
              {prefillSupported
                ? 'Pre-select the matching option (if any)'
                : 'Not available for this question type'}
            </span>
          </div>
        </label>

        <label className={css.requireAnswer}>
          <div className={css.labelText}>Require a response</div>
          <div className={css.groupedInputs}>
            <Checkbox
              checked={currentQuestion.required}
              onChange={(event) => {
                updateQuestion({required: event.target.checked});
              }}
            />
            <span>Yes</span>
          </div>
        </label>
      </div>
    </div>
  );
};

// $FlowFixMe[value-as-type] [v1.32.0]
export default (props: {fluxStore: IndexStore}): React.Node => {
  const {state} = React.useContext(WritebackContext);

  if (!state.module_attributes) {
    return <Loading />;
  }

  return <SurveyModuleOptions {...props} />;
};
