// @flow

import type {WebsocketMetaMessage} from 'src/types/websocket';

import EventEmitter from 'events';
import exenv from 'exenv';
import debounce from 'lodash/debounce';
import {captureSentryException, captureSentryMessage} from 'src/utils/sentry';

import logger, {type DefaultLogger} from 'src/utils/logger';
import * as messageTypes from 'src/types/websocket';


const messageTypeValues = Object.values(messageTypes);

let client;

type Options = {
  connectImmediately: boolean,
  host?: string,
};

export const getWebSocketClient = (
  options: Options = {connectImmediately: true},
): ?WebSocketClient => {
  if (!exenv.canUseDOM) {
    return;
  }

  if (!client) {
    const url = makeUrl(options.host);
    client = new WebSocketClient(url);
    if (options.connectImmediately) {
      client.connect();
    } else {
      client.suspend();
    }
  }
  return client;
};

export const removeAgencyFromHostname = (hostname: string): string =>
  hostname.replace(/^[^.]+\./, '');

const getHostnameWithoutAgency = (host?: string) =>
  removeAgencyFromHostname(host || document.location.hostname);

const makeUrl = (host?: string) => {
  const isDev = process.env.NODE_ENV !== 'production';
  let protocol = 'wss:';
  // In dev, protocol can be insecure (but in production and staging it must be secure)
  // (in Chrome extension document.location.protocol === 'chrome-extension:')
  if (isDev && document.location.protocol !== 'https:') {
    protocol = 'ws:';
  }
  return `${protocol}//ws.${getHostnameWithoutAgency(
    host,
  )}/api/v1/messaging/ws/connect`;
};

const WEB_SOCKET_CONNECTING = 0;
const WEB_SOCKET_OPEN = 1;
const BASE_RECONNECT_DELAY = 500;
const MAX_RECONNECT_DELAY = 30_000;
// Amount of time we allow for the connection to open before we conclude
// that the WebSocket is unavailable
const OPEN_TIMEOUT = 10_000;

// Often, an error will trigger a close, which will try to report several
// things to the server at once. Just report the last thing
const REPORT_DEBOUNCE_WAIT = 300;

// Note (gab): eventually we should only be logging unexpected errors to Sentry.
// This does NOT include things like the WebSocket connection closing because
// we expect that to happen in the client from time to time.
const USE_SENTRY = true;

export class WebSocketClient extends EventEmitter {
  connection: ?WebSocket;
  openTimeoutId: ?TimeoutID;
  reconnectTimeoutId: ?TimeoutID;
  connectionAttempts: number;
  url: string;
  suspended: boolean;

  // Just keeps track of whether the connection ever opened
  connectionOpened: boolean;

  logger: DefaultLogger = logger.createChild({context: 'WebSocket Client'});

  constructor(url: string) {
    super();
    this.url = url;
    this.openTimeoutId = null;
    this.reconnectTimeoutId = null;
    // Number of attempts made before connection opens. Resets to 0 when connection opens
    this.connectionAttempts = 0;
    this.connectionOpened = false;
    this.suspended = false;
  }

  setHost(host: string) {
    this.url = makeUrl(host);
    // NOTE (kyle): this will force the WS to connect
    // to the new host and url.
    this.connection && this.connection.close();
  }

  connect() {
    if (
      this.connection &&
      (this.connection.readyState === WEB_SOCKET_CONNECTING ||
        this.connection.readyState === WEB_SOCKET_OPEN)
    ) {
      this.logger.warn('Tried to connect to already open or connecting socket');
      return;
    }

    this.connectionAttempts++;
    this.connection = new WebSocket(this.url);
    this.addWSListeners();
    this.setOpenTimeout();
  }

  setOpenTimeout() {
    if (this.openTimeoutId !== null) {
      return;
    }

    // If connection does not open before a specified timeout, close the connection
    // and try to re-connect
    this.openTimeoutId = setTimeout(() => {
      this.logger.warn('WebSocket failed to open before timeout', {
        timeout: OPEN_TIMEOUT,
      });
      this.connection?.close();
    }, OPEN_TIMEOUT);
  }

  clearOpenTimeout() {
    if (this.openTimeoutId !== null) {
      clearTimeout(this.openTimeoutId);
      this.openTimeoutId = null;
    }
  }

  addWSListeners() {
    const {connection} = this;
    if (!connection) {
      return;
    }
    connection.addEventListener('message', this.handleMessage);
    connection.addEventListener('close', this.handleClose);
    connection.addEventListener('open', this.handleOpen);
  }

  removeWSListeners() {
    const {connection} = this;
    if (!connection) {
      return;
    }
    connection.removeEventListener('message', this.handleMessage);
    connection.removeEventListener('close', this.handleClose);
    connection.removeEventListener('open', this.handleOpen);
  }

  getReconnectDelay(): number {
    const unjitteredDelay = Math.min(
      MAX_RECONNECT_DELAY,
      BASE_RECONNECT_DELAY * Math.pow(2, this.connectionAttempts),
    );
    const jitteredDelay = Math.random() * unjitteredDelay;
    return jitteredDelay;
  }

  isOffline(): boolean {
    return window && window.navigator && window.navigator.onLine === false;
  }

  shouldAttemptReconnect(): Promise<boolean> {
    if (this.suspended) {
      return Promise.resolve(false);
    }

    if (this.isOffline()) {
      return new Promise((resolve) => {
        const handleOnline = () => {
          window.removeEventListener('online', handleOnline);
          resolve(true);
        };
        window.addEventListener('online', handleOnline);
      });
    }

    // In dev, don't attempt re-connect if WebSocket server was never running
    // i.e., if the connection was never opened
    return Promise.resolve(
      process.env.NODE_ENV === 'production' || this.connectionOpened,
    );
  }

  reconnect(reconnectDelay?: number) {
    if (this.reconnectTimeoutId !== null) {
      return;
    }

    if (reconnectDelay === undefined) {
      reconnectDelay = this.getReconnectDelay();
    }
    this.reconnectTimeoutId = setTimeout(() => {
      this.reconnectTimeoutId = null;
      this.connect();
    }, reconnectDelay);
  }

  handleMessage: (MessageEvent) => void = (event: MessageEvent) => {
    let message: ?WebsocketMetaMessage = null;

    try {
      if (typeof event.data === 'string') {
        message = JSON.parse(event.data);
      }
    } catch (error) {
      this.logger.error(error);
      return;
    }

    if (!message) {
      this.logger.warn('WebSocket received falsy message', {message});
      return;
    }

    if (!message.payload || !Array.isArray(message.payload)) {
      this.logger.warn('WebSocket received message without a payload.', {
        message,
      });
      return;
    }

    for (const subMessage of message.payload) {
      if (messageTypeValues.includes(subMessage.type)) {
        this.emit(subMessage.type, subMessage);
      } else {
        this.logger.warn('WebSocket received unknown message type', {
          type: subMessage.type,
        });
      }
    }
  };

  handleOpen: () => void = () => {
    this.connectionOpened = true;
    this.connectionAttempts = 0;
    this.clearOpenTimeout();
    this.emit('connection:open');
  };

  handleClose: () => void = () => {
    this.removeWSListeners();
    this.emit('connection:close');

    const reconnectDelay = this.getReconnectDelay();
    this.logger.info(`WebSocket closed`, {
      timeToNextConnectAttempt: reconnectDelay,
    });
    this.shouldAttemptReconnect().then((yes) => {
      if (yes) {
        this.reconnect(reconnectDelay);
      }
    });
  };

  suspend() {
    if (this.suspended) {
      return;
    }

    this.log('Suspending');

    this.suspended = true;
    if (this.connection && this.connection.readyState === WEB_SOCKET_OPEN) {
      this.connection.close();
    }
  }

  resume() {
    if (!this.suspended) {
      return;
    }

    this.log('Resuming');

    this.suspended = false;
    this.connect();
  }

  /**
   * Note that 'debug' and 'info' will not be logged to Sentry
   * or any server but will show up as breadcrumbs in Sentry.
   */
  log(
    info: string,
    {
      level = 'debug',
      extra,
    }: {
      level: 'debug' | 'info' | 'warning' | 'error' | 'fatal',
      extra?: {},
    } = {},
  ) {
    logger.log(`[Sense WebSocket client] (${level})`, info);

    if (
      ['warning', 'error', 'fatal'].includes(level) &&
      this.shouldLogToServer()
    ) {
      if (USE_SENTRY) {
        captureSentryMessage(info, {
          level,
          tags: {
            wsUrl: this.url,
          },
          extra: {
            wsConnectionAttempts: this.connectionAttempts,
            ...extra,
          },
        });
      } else {
        this.logToServer({
          info,
          ...extra,
        });
      }
    }
  }

  logError(error: Error) {
    logger.error('[Sense WebSocket client] (error)', error);

    if (this.shouldLogToServer()) {
      if (USE_SENTRY) {
        captureSentryException(error, {
          level: 'error',
          tags: {
            wsUrl: this.url,
          },
          extra: {
            wsConnectionAttempts: this.connectionAttempts,
          },
        });
      } else {
        this.logToServer({
          error: error.toString(),
        });
      }
    }
  }

  shouldLogToServer(): boolean {
    return (
      // Don't spam the error endpoint with stuff from dev
      process.env.NODE_ENV === 'production' &&
      !this.isOffline() &&
      // We only test chat app and web socket on stage-3
      !/stage-[12]/.test(window.location.hostname)
    );
  }

  _logToServer(data: {...}) {
    // NOTE (gab): the following if-clause has been carefully crafted to be
    // removed by the minifier if USE_SENTRY = true
    if (!USE_SENTRY) {
      const url = /\.stage-3\.sensehq\.co$/.test(window.location.hostname)
        ? 'https://98b6ie0728.execute-api.us-west-2.amazonaws.com/stage/'
        : 'https://98b6ie0728.execute-api.us-west-2.amazonaws.com/prod/';
      // NOTE (gab): dev endpoint for reference:
      // https://98b6ie0728.execute-api.us-west-2.amazonaws.com/dev/

      const combinedData = {
        ...data,
        connectionUrl: this.url,
        connectionAttempts: this.connectionAttempts,
        // TODO (gab): add agent id and agency id to data payload
      };

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(combinedData),
        headers: {
          'content-type': 'application/json',
        },
        mode: 'no-cors',
      });
    }
  }
  logToServer: ({...}) => void = debounce(
    // $FlowFixMe[method-unbinding]
    this._logToServer,
    REPORT_DEBOUNCE_WAIT,
  );
}
