var React = require('react');

function CloseIcon (props) {
    return React.createElement("svg",props,React.createElement("g",{"fillRule":"evenodd","strokeLinecap":"round","strokeLinejoin":"round"},React.createElement("path",{"d":"M2.075.5l9.828 9.828M11.328.5L1.5 10.328"})));
}

CloseIcon.defaultProps = {"width":"13","height":"11","viewBox":"0 0 13 11"};

module.exports = CloseIcon;

CloseIcon.default = CloseIcon;
