// @flow

import type {Dispatch, GetState, ThunkAction} from 'src/reducers';
import type {AudienceFilter} from 'src/types/audience-filter';
import type {DynamicLabels} from 'src/action-creators/dynamic-labels';

import exenv from 'exenv';
import pick from 'lodash/pick';
import sculpt from 'sculpt';
import invariant from 'invariant';

import {thunkify as flow} from 'src/utils/thunks';
import otherParsers from 'src/api-parsers';
import {camel, snake} from 'src/utils';
import {key, cached, fetching} from 'src/utils/redux';
import * as reduxApi from 'src/utils/redux-api-v2';
import {rootGroupId} from 'src/utils/groups';
import {ActiveStatus} from 'src/reducers/groups';
import {
  startBackgroundTask,
  getBackgroundTaskStatus,
} from 'src/action-creators/background-task.js';


export const RECEIVE: 'groups/receive' = 'groups/receive';
export const RECEIVE_GROUP: 'groups/receiveGroup' = 'groups/receiveGroup';
export const REMOVE_GROUP: 'groups/removeGroup' = 'groups/removeGroup';
export const RECEIVE_SHAREABLE_GROUPS: 'groups/receiveShareableGroups' =
  'groups/receiveShareableGroups';
export const TOGGLE_GROUP_ALL_FILTER: 'groups/toggleGroupAllFilter' =
  'groups/toggleGroupAllFilter';
export const ADD_GROUP_FILTER_RULE: 'groups/addGroupFilterRule' =
  'groups/addGroupFilterRule';
export const EDIT_GROUP_FILTER_RULE: 'groups/editGroupFilterRule' =
  'groups/editGroupFilterRule';
export const DELETE_GROUP_FILTER_RULE: 'groups/deleteGroupFilterRule' =
  'groups/deleteGroupFilterRule';
export const CLEAR_EDITING: 'groups/clearEditing' = 'groups/clearEditing';
export const START_EDITING: 'groups/startEditing' = 'groups/startEditing';
export const START_EDITING_ERROR: 'groups/startEditingError' =
  'groups/startEditingError';
export const SHOW_ALL_EDITING_ERRORS: 'groups/showAllEditingErrors' =
  'groups/showAllEditingErrors';
export const EDIT_GROUP_NAME: 'groups/editGroupName' = 'groups/editGroupName';
export const EDIT_GROUP_PARENT: 'groups/editGroupParent' =
  'groups/editGroupParent';
export const RECEIVE_GROUP_FILTER_STATUS: 'groups/receiveFilterStatus' =
  'groups/receiveFilterStatus';

export type Group = {
  id: string,
  name: string,
  numAgents: number,
  parentSecurityGroupId: string,
  filter: {
    pattern: AudienceFilter,
    totalMembers: number,
  },
};

export type ApiGroup = {
  id: string,
  name: string,
  num_agents: number,
  parent_security_group_id: ?string,
  filter: {
    pattern: AudienceFilter,
    total_members: number,
  },
};

// returned by workflows/:workflowId/groups/shareable
export type ApiShareableGroup = {
  audience_filter_id: string,
  id: string,
  name: string,
  num_audience_members: ?number,
  parent_security_group_id: string,
};

export type ShareableGroup = {
  audienceFilterId: string,
  id: string,
  name: string,
  numAudienceMembers: ?number,
  parentSecurityGroupId: string,
};

// Data structure for PUT, POST
type ApiUpdateGroup = {
  name: string,
  parent_security_group_id: ?string,
  filter?: {
    pattern: AudienceFilter,
  },
};

export const normalize = (
  group: Group,
  fieldsToTypes: {[field: string]: string} | void,
): ApiUpdateGroup => {
  const writableFieldsOnly = pick(group, ['name', 'parentSecurityGroupId']);

  if (fieldsToTypes) {
    writableFieldsOnly.filter = {
      pattern: otherParsers.normalize.audienceFilter(
        fieldsToTypes,
        group.filter.pattern,
      ),
    };
  }

  return snake(writableFieldsOnly);
};

export const parse = (group: ApiGroup): Group =>
  camel(
    sculpt(group, {
      filter: {
        pattern: {$apply: otherParsers.parse.audienceFilter},
      },
    }),
  );

export type GroupsAction =
  | ReceiveGroupsAction
  | ReceiveSingleGroupAction
  | RemoveGroupAction
  | ReceiveShareableGroupsAction
  | ClearEditingAction
  | StartEditingAction
  | StartEditingErrorAction
  | ShowAllEditingErrorsAction
  | EditGroupNameAction
  | EditGroupParentAction
  | ToggleGroupAllFilterAction
  | AddGroupFilterRuleAction
  | EditGroupFilterRuleAction
  | DeleteGroupFilterRuleAction
  | ReceiveGroupFilterStatusAction;

export type EditGroup = {
  id: ?string,
  parentSecurityGroupId: ?string,
  name: string,
  filter: {
    pattern: AudienceFilter,
  },
};

const emptyGroup: EditGroup = Object.freeze({
  id: null,
  parentSecurityGroupId: null,
  name: '',
  filter: {
    pattern: {and: []},
  },
});

type ReceiveGroupsAction = {
  type: typeof RECEIVE,
  payload: Group[],
};

export const receive = (payload: Group[]): ReceiveGroupsAction => ({
  type: RECEIVE,
  payload,
});

type ReceiveSingleGroupAction = {
  type: typeof RECEIVE_GROUP,
  payload: Group,
};

const receiveGroup = (payload: Group): ReceiveSingleGroupAction => ({
  type: RECEIVE_GROUP,
  payload,
});

type RemoveGroupAction = {
  type: typeof REMOVE_GROUP,
  payload: string,
};

const removeGroup = (payload: string): RemoveGroupAction => ({
  type: REMOVE_GROUP,
  payload,
});

type ReceiveShareableGroupsAction = {
  type: typeof RECEIVE_SHAREABLE_GROUPS,
  payload: ShareableGroup[],
};
const receiveShareableGroups = (
  payload: ShareableGroup[],
): ReceiveShareableGroupsAction => ({
  type: RECEIVE_SHAREABLE_GROUPS,
  payload,
});

type ClearEditingAction = {
  type: typeof CLEAR_EDITING,
};

const clearEditing = (): ClearEditingAction => ({
  type: CLEAR_EDITING,
});

type StartEditingAction = {
  type: typeof START_EDITING,
  payload: EditGroup,
};

export const startEditingGroup = (group: EditGroup): StartEditingAction => ({
  type: START_EDITING,
  payload: group,
});

export type ErrorMessagesByType = {
  [errorName: string]: string[],
  ...
};

type StartEditingErrorAction = {
  type: typeof START_EDITING_ERROR,
  payload: ErrorMessagesByType,
  error: true,
};

const startEditingError = (
  errorMessagesByType: ErrorMessagesByType,
): StartEditingErrorAction => ({
  type: START_EDITING_ERROR,
  payload: errorMessagesByType,
  error: true,
});

export const startEditing =
  (id: string | void): ThunkAction<void> =>
  (dispatch: Function, getState: Function) => {
    dispatch(clearEditing());
    if (id) {
      return dispatch(getGroups()).then(() => {
        const group = getState().groups.data[id];

        if (!group) {
          return dispatch(
            startEditingError({
              notFound: [`Could not get group with id ${String(id)}`],
            }),
          );
        }

        return dispatch(startEditingGroup(group));
      });
    } else {
      const rootId = rootGroupId(getState().groups.data);
      const emptyGroupTemplate = {
        ...emptyGroup,
        parentSecurityGroupId: rootId,
      };
      return dispatch(startEditingGroup(emptyGroupTemplate));
    }
  };

type ShowAllEditingErrorsAction = {
  type: typeof SHOW_ALL_EDITING_ERRORS,
};

export const showAllEditingErrors = (): ShowAllEditingErrorsAction => ({
  type: SHOW_ALL_EDITING_ERRORS,
});

export const getGroups: () => ThunkAction<mixed> = flow(
  key(() => 'getGroups'),
  cached((response: ApiGroup[]) => receive(response.map(parse)), {ttl: 2000}),
  fetching(),
)(() => reduxApi.get('security/groups'));

export const getShareableGroups: () => ThunkAction<mixed> = flow(
  key(() => 'getShareableGroups'),
  cached(
    (response: ApiShareableGroup[]) =>
      receiveShareableGroups(response.map(camel)),
    {ttl: 2000},
  ),
  fetching(),
)(() => reduxApi.get('workflows/groups/shareable'));

export const createGroup =
  (
    group: Group,
    fieldsToTypes: {[key: string]: string} | void,
  ): ThunkAction<ReceiveSingleGroupAction> =>
  (dispatch: Function) =>
    dispatch(
      reduxApi.post('security/groups', normalize(group, fieldsToTypes)),
    ).then((updatedGroup) => dispatch(receiveGroup(parse(updatedGroup))));

export const updateGroup =
  (
    group: Group,
    fieldsToTypes: {[key: string]: string} | void,
  ): ThunkAction<void> =>
  (dispatch: Function) => {
    // eslint-disable-next-line no-unused-vars
    const {parent_security_group_id, ...groupToUpdate} = normalize(
      group,
      fieldsToTypes,
    );

    return dispatch(
      reduxApi.put(`security/groups/${group.id}`, groupToUpdate),
    ).then((updatedGroup) => dispatch(receiveGroup(parse(updatedGroup))));
  };

export const updateGroupAudienceFilter =
  (
    group: Group,
    fieldsToTypes: {[key: string]: string} | void,
  ): ThunkAction<void> =>
  (dispatch: Function) => {
    const {filter} = normalize(group, fieldsToTypes);

    invariant(
      filter,
      'Attempting to update group audience filter that\'s undefined.',
    );

    return dispatch(
      startBackgroundTask(
        `group-${group.id}-audience-filter-create`,
        () =>
          dispatch(
            reduxApi.post(
              `security/groups/${group.id}/audience-filter`,
              filter.pattern,
            ),
          ),
        `security/groups/${group.id}/audience-filter/status`,
      ),
    );
  };

export const getGroupFilterStatus =
  (groupId: string): ThunkAction<void> =>
  (dispatch: Function) =>
    dispatch(
      getBackgroundTaskStatus(
        `group-${groupId}-audience-filter-create`,
        `security/groups/${groupId}/audience-filter/status`,
      ),
    );
// TODO (rng) - Handle error result on API status request

export const cancelGroupAudienceFilter =
  (group: Group): ThunkAction<mixed> =>
  (dispatch: Function) =>
    dispatch(
      reduxApi.post(`security/groups/${group.id}/audience-filter/cancel`),
    );

export const deleteGroup =
  (groupId: string): ThunkAction<void> =>
  (dispatch: Function) =>
    dispatch(reduxApi.del(`security/groups/${groupId}`)).then(
      () => dispatch(removeGroup(groupId)),
      // NOTE:(diwakersurya) clear editing state after deleting group
      dispatch(clearEditing()),
    );

export const handleSave =
  (
    group: Group,
    router: Object,
    nextUrl: string,
    fieldsToTypes: {[field: string]: string} | void,
  ): ThunkAction<mixed> =>
  (dispatch: Dispatch) =>
    group.id
      ? dispatch(updateGroup(group, fieldsToTypes)).then(() =>
          router.push(nextUrl),
        )
      : dispatch(createGroup(group, fieldsToTypes)).then((action) =>
          router.push(`/settings/groups/${action.payload.id}/audience/edit`),
        );

type EditGroupNameAction = {
  type: typeof EDIT_GROUP_NAME,
  payload: {
    name: string,
  },
};

export const editGroupName = (name: string): EditGroupNameAction => ({
  type: EDIT_GROUP_NAME,
  payload: {
    name,
  },
});

type EditGroupParentAction = {
  type: typeof EDIT_GROUP_PARENT,
  payload: {
    parentId: string,
  },
};

export const editGroupParent = (parentId: string): EditGroupParentAction => ({
  type: EDIT_GROUP_PARENT,
  payload: {
    parentId,
  },
});

type ToggleGroupAllFilterAction = {
  type: typeof TOGGLE_GROUP_ALL_FILTER,
  payload: {},
};

export const toggleGroupAllFilter = (): ToggleGroupAllFilterAction => ({
  type: TOGGLE_GROUP_ALL_FILTER,
  payload: Object.freeze({}),
});

type AddGroupFilterRuleAction = {
  type: typeof ADD_GROUP_FILTER_RULE,
  payload: {},
};

export const addGroupFilterRule = (): AddGroupFilterRuleAction => ({
  type: ADD_GROUP_FILTER_RULE,
  payload: Object.freeze({}),
});

type EditGroupFilterRuleAction = {
  type: typeof EDIT_GROUP_FILTER_RULE,
  payload: {
    ruleIndex: number,
    change: {[key: string]: any},
    labels: DynamicLabels,
  },
};

export const editGroupFilterRule = (
  ruleIndex: number,
  change: {[key: string]: any},
  labels: DynamicLabels,
): EditGroupFilterRuleAction => ({
  type: EDIT_GROUP_FILTER_RULE,
  payload: {
    ruleIndex,
    change,
    labels,
  },
});

type DeleteGroupFilterRuleAction = {
  type: typeof DELETE_GROUP_FILTER_RULE,
  payload: {
    ruleIndex: number,
  },
};

export const deleteGroupFilterRule = (
  ruleIndex: number,
): DeleteGroupFilterRuleAction => ({
  type: DELETE_GROUP_FILTER_RULE,
  payload: {
    ruleIndex,
  },
});

type ReceiveGroupFilterStatusAction = {
  type: typeof RECEIVE_GROUP_FILTER_STATUS,
  payload: {
    groupId: string,
    status: string,
  },
};

const receiveGroupFilterStatus = (
  // $FlowFixMe[value-as-type] [v1.32.0]
  payload: ActiveStatus,
): ReceiveGroupFilterStatusAction => ({
  type: RECEIVE_GROUP_FILTER_STATUS,
  payload,
});
