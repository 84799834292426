// @flow strict

import type {JobMatchMetaActions} from 'src/action-creators/job-match-meta.js';
import type {
  JobMatchQueryParam,
  JobMatchSortOption,
  JobMatchSortParam,
  JobMatchCountParam,
} from 'src/types/job-variables.js';
import {RECEIVE_JOB_MATCH_QUERY_PARAMS} from 'src/action-creators/job-match-meta.js';


export type JobMatchMetaState = {
  queryParams: JobMatchQueryParam[],
  sortParam: JobMatchSortParam,
  countParam: JobMatchCountParam,
};

const initialState = {
  queryParams: [],
  sortParam: {
    id: '',
    name: '',
    options: [],
  },
  countParam: {
    id: '',
    name: '',
    options: [],
  },
};

export default (
  state: JobMatchMetaState = initialState,
  action: JobMatchMetaActions,
): JobMatchMetaState => {
  switch (action.type) {
    case RECEIVE_JOB_MATCH_QUERY_PARAMS: {
      return {
        ...state,
        countParam: action.payload.countParam,
        queryParams: action.payload.queryParams,
        sortParam: action.payload.sortParam,
      };
    }
  }
  return state;
};
