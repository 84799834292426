// @flow

import type {State} from 'src/reducers';
import type {Agency} from 'src/api-parsers/index.types';
import type {AgencyConfig} from 'src/types/agency';
import type {BrandingSettings} from 'src/types/events';
import {createSelector} from 'reselect';
import capitalize from 'lodash/capitalize';


export const selectAgencyData = (state: State): Agency => state.agency.agency;

export const selectBrandingSettings = (state: State): BrandingSettings =>
  selectAgencyData(state).brandingSettings;

export const selectAgencyBrandingSettingsLogo = (state: State): string =>
  selectAgencyData(state).brandingSettings.logo || '';

export const selectAgencyConfig = (state: State): AgencyConfig =>
  state.agency.config;

export const selectAgencyConfigFlag = (
  state: State,
  flag: string,
): boolean | number => selectAgencyConfig(state)[flag];

export const selectMultiEntityEnabled = (_state: State): boolean => true;

export const selectMessagingDisableMms = (state: State): boolean =>
  selectAgencyConfig(state)['messagingDisableMms'];

export const selectDNSV2Enabled = (state: State): boolean =>
  selectAgencyConfig(state)['sendgridDnsV2'];

export const selectMaxDailySmsTouchpointSendsAtActivation = (
  state: State,
): number =>
  selectAgencyConfig(state)['maxDailySmsTouchpointSendsAtActivation'];

export const selectMaxContactsCsvUpload = (state: State): number =>
  selectAgencyConfig(state)['maxContactsCsvUpload'];

export const selectMaxTouchpointsPerJourney = (state: State): number =>
  selectAgencyConfig(state)['maxTouchpointsPerJourney'];

export const selectCampaignSchedulingEmailMaxSendsPerHour = (
  state: State,
): number =>
  selectAgencyConfig(state)['campaignSchedulingEmailMaxSendsPerHour'];

export const selectCampaignSchedulingEmailMaxSendsPerDay = (
  state: State,
): number => selectAgencyConfig(state)['campaignSchedulingEmailMaxSendsPerDay'];

export const selectCampaignSchedulingEnabled = (state: State): boolean =>
  selectAgencyConfig(state)['campaignSchedulingEnabled'];

export const selectHidePlacementsInMessagingTimeline = (
  state: State,
): boolean => selectAgencyConfig(state)['hidePlacementsInMessagingTimeline'];

export const selectMessagingRequireAtsSyncedCandidates = (
  state: State,
): boolean => selectAgencyConfig(state)['messagingRequireAtsSyncedCandidates'];

export const selectEnableChatbot = (state: State): boolean =>
  selectAgencyConfig(state)['enableChatbot'];

export const selectDefaultPhoneCountry = (state: State): string =>
  selectAgencyConfig(state)['defaultPhoneCountry'];

export const selectAtsKind = (state: State): string =>
  state.agency?.agency?.atsSystem;

export const selectAtsNameLabel: (state: State) => string = createSelector(
  selectAtsKind,
  (atsSystem) => capitalize(atsSystem),
);

export const selectEnableDiscoverForEngage = (state: State): boolean =>
  selectAgencyConfig(state)['enableJobMatchForEngage'];

export const selectAgencyMaintenanceMode = (state: State): boolean =>
  state.agency['maintenanceMode'];

export const selectShowConversationContextEvents = (state: State): boolean =>
  state.agency.config.showConversationContextEvents;

export const selectAtsWritebackToManyFieldsHandlingMode = (
  state: State,
): string => state.agency?.agency?.atsWritebackToManyFieldsHandlingMode;

export const selectMaxLatQueueAssignmentsPerAgent = (state: State): number =>
  state.agency.config?.maxLatQueueAssignmentsPerAgent;
