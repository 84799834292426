// @flow strict
//$FlowFixMe
import type {Dispatch, ThunkAction} from 'src/reducers';

//$FlowFixMe
import * as reduxApi from 'src/utils/redux-api-v2';


export const verifyCreds =
  (creds: mixed): ThunkAction<mixed> =>
  (dispatch: Dispatch) =>
    dispatch(
      reduxApi.post('onboard/validate-credentials', {
        credentials: JSON.stringify(creds),
      }),
    );
