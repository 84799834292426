// @noflow

import * as React from 'react';
import {RadioGroup} from 'react-radio-group';

import {RadioPair} from './common.jsx';


type RadioButtonsProps<T> = {
  name: string,
  selectedValue: T,
  onChange: (value: T) => void,
  options: {
    value: T,
    label: string,
  }[],
  className?: string,
  labelClassName?: string,
  bodyClassName?: string,
};

const RadioButtons = <T>({
  name,
  selectedValue,
  onChange,
  options,
  className,
  labelClassName,
  bodyClassName,
}: RadioButtonsProps<T>): React.Node => (
  <RadioGroup
    className={className}
    name={name}
    selectedValue={selectedValue}
    onChange={onChange}
  >
    <div className={bodyClassName}>
      {options.map((choice) => (
        <RadioPair
          className={labelClassName}
          key={String(choice.value)}
          value={choice.value}
          label={choice.label}
          isHorizontal
        />
      ))}
    </div>
  </RadioGroup>
);

export default RadioButtons;
