// @flow

import type {Action} from 'src/action-creators/product-flags';
import {RECEIVE_RELEASE_FLAGS} from 'src/action-creators/product-flags';


export type ProductFlagState = {
  releaseFlags: {
    [key: string]: boolean,
  },
};

const initialState: ProductFlagState = {
  releaseFlags: Object.freeze({}),
};

export default (
  state: ProductFlagState = initialState,
  action: Action,
): ProductFlagState => {
  switch (action.type) {
    case RECEIVE_RELEASE_FLAGS:
      return {
        ...state,
        releaseFlags: {
          ...state.releaseFlags,
          ...action.payload,
        },
      };
  }

  return state;
};
