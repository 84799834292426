// @flow strict

import type {JobListActions} from 'src/action-creators/job-widget';
import type {ConfigData, Layout} from 'src/types/job-widget';

import {
  RECEIVE_CONFIG_DATA,
  UPDATE_CONFIG_DATA,
  RESET_DATA,
  REMOVE_CONFIG_DATA_FILTER_INCLUDED_ID,
  UPDATE_CONFIG_DATA_ATTRIBUTES,
  UPDATE_LAYOUT,
} from 'src/action-creators/job-widget';


export type State = {
  configData: ConfigData | null,
  layout: Layout | null,
};

const initialState = {
  configData: null,
  layout: null,
};

export default (state: State = initialState, action: JobListActions): State => {
  switch (action.type) {
    case RECEIVE_CONFIG_DATA: {
      return {
        ...state,
        configData: action.payload.configData,
      };
    }
    case UPDATE_CONFIG_DATA: {
      return {
        ...state,
        configData: action.payload.configData,
      };
    }
    case UPDATE_LAYOUT: {
      return {
        ...state,
        configData: {
          ...state.configData,
          layout: action.payload,
        },
      };
    }
    case RESET_DATA: {
      return {
        ...initialState,
      };
    }
    case REMOVE_CONFIG_DATA_FILTER_INCLUDED_ID: {
      const currentConfigData = state.configData;
      if (currentConfigData && currentConfigData.filter_ids?.included_ids) {
        const updatedIncludedIds =
          currentConfigData.filter_ids.included_ids.filter(
            (existingId) => existingId !== action.payload.id,
          );
        const updatedConfigData = {
          ...currentConfigData,
          attributes: [],
          filter_ids: {
            ...currentConfigData.filter_ids,
            included_ids: updatedIncludedIds,
          },
        };

        return {
          ...state,
          configData: updatedConfigData,
        };
      }
      return state;
    }
    case UPDATE_CONFIG_DATA_ATTRIBUTES: {
      const currentConfigData = state.configData;
      if (currentConfigData && currentConfigData.attributes) {
        const updatedAttributes = currentConfigData.attributes.filter(
          (existingAttr) => existingAttr !== action.payload.attribute,
        );
        const updatedConfigData = {
          ...currentConfigData,
          attributes: updatedAttributes,
        };

        return {
          ...state,
          configData: updatedConfigData,
        };
      }
      return state;
    }
  }

  return state;
};
